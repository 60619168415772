import { useState, useEffect } from "react";
import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Mint } from "../../components/mint";
import { Ooze } from "../../components/ooze";
import { Merge } from "../../components/merge";
import axios from 'axios';
import env from "react-dotenv";
import { Roadmap } from "../../components/roadmap";
// import { Team } from "../../components/Team";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import "./index.css";
import { useMediaQuery } from 'react-responsive'

// import { HeaderMobile } from "../../components/mobile/header";
// import { MintMobile } from "../../components/mobile/mint";
// import { RoadmapMobile } from "../../components/mobile/roadmap";
// import { OozeMobile } from "../../components/mobile/ooze";
// import { MergeMobile } from "../../components/mobile/merge";

import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}



const NewToNFT = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    setLandingPageData(JsonData);
    // getAlert()
  }, []);

  function trans(val) {
    i18n.changeLanguage(val)
  }
  const { t, i18n } = useTranslation(['common']);

  const getAlert = () => {
    axios.get(env.API_URL + "metadata/getalert").then((res) => {
      try {
        let d = new Date(res.data.date);
        setMessage(res.data.message)
        setDate(d.toLocaleString())
      } catch (e) { }
    }).catch(() => {
    })
  }

  function toggleMenu() {
    let d;
    let c;
    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }



  return (
    <div className='intro'>

      <div className="main-page-wrapper">
        {/* ===================================================
				Loading Transition
		  	==================================================== */}
        <div id="loader-wrapper">
          <div id="loader">
            <ul>
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
        {/* 
        =============================================
          Theme Header
        ============================================== 
        */}
        {/* <div className="background-alert" style={{ height: 30, width: '100%' }}>
          <p className=" fadeInUp animated" data-wow-delay="0.2s" style={{ "-webkit-text-transform": "uppercase", "text-transform": "uppercase", 'text-align': 'center', 'color': 'white' }}>
            {t('alert')}</p>
        </div> */}
         <header className="theme-menu-wrapper full-width-menu">
          <div className="header-wrapper">
            <div className="clearfix" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Logo */}
              <div className="float-left tran4s" style={{ "display": "flex", maxWidth: '150px', margin: 15, transform: 'translateY(-5px)' }}>
                <a href="/"><img style={{
                  minWidth: '5rem',
                  maxWidth: '5rem'
                }} src="images/elements/alogo.png" alt="Logo" /></a>
              </div>

              {/* ============================ Theme Menu ========================= */}
              <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper" style={{ justifyContent: 'flex-end' }}>
                {/* Brand and toggle get grouped for better mobile display */}
                <div className="navbar-header">
                  <button onClick={() => toggleMenu()} type="button" className="navbar-toggle collapsed" >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                {!openNav &&

                  <div className="collapse navbar-collapse" id="navbar-collapse-1">
                    <ul className="nav">
                      <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                      </li>
                      <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                      </li>
                      <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                      </li>
                      <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                      </li>
                      <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                      </li>
                      <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s" style={{ "font-size": "15px", color: 'white', fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                      </li>

                    </ul>
                  </div>
                }
              </nav> {/* /.theme-main-menu */}
              <div className="float-left tran4s" style={{ "display": "flex" }}>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/ig.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/twitter.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/discord.png'} /></a></li>
              </div>

            </div> {/* /.clearfix */}
          </div>


          {openNav &&

            <div className="navbar-collapse" id="navbar-collapse-1" style={{
              width: '100%',
              position: 'absolute',
              zIndex: '200',
              backgroundColor: 'white',
            }}>
              <ul className="nav" style={{
                flexDirection: 'column',
                display: 'flex'
              }}>
                <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                </li>
                <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                </li>
                <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                </li>

                <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                </li>
                <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                </li>
                <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                </li>
              </ul>
            </div>
          }

        </header> {/* /.theme-menu-wrapper */}





        <div className="new-to-nfts-bg" id="mint">
          <div className="container">
            <div className="row" style={{ display: 'flex', flexDirection: 'row', marginTop: 70 }}>
              <div className="container" >
                <div className="row" style={{
                  'align-items': 'center',
                  'display': 'flex',
                  'flex-direction': 'column',
                  padding: 10
                }}>
                  <h2 style={{textAlign: 'center'}}>NEW TO CARDANO NFTS?</h2>

                  <h5 className="wow fadeInUp animated" style={{ padding: 10, textAlign: 'center', "max-width": "900px", "margin-bottom": "50px", "margin-top": "50px", color: 'white' }}>
                    Hi Stargazer! Welcome to the exciting world of Cardano NFTs! For a newcomer, getting involved in NFTs can seem like a daunting endeavor. But we assure you that it's much simpler than it seems. <br /><br />

                    The guide below is intended for newcomers to the CNFT space and outlines the general steps and requirements to be able to mint your very own Astrid Star! <br /><br />

                    Please note that this is not a fully comprehensive guide. One of the golden rules of crypto is to D.Y.O.R. (Do Your Own Research). For which, there is no shortage of guides for everything you will need out there. <br /><br />

                  </h5>


                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">
                        <h2 style={{ padding: 10, width: '100%', textAlign: 'left', fontSize: 25, color: '#FAA652', marginTop: 10, marginBottom: 0, fontFamily: 'azonix' }}>STEP 1</h2>
                        <p style={{ textAlign: 'left', color: '#b3b3b3', marginTop: 20, marginBottom: 20, padding: 10 }}>
                          <font style={{ fontWeight: 'bold', color: 'white' }}>Create and fund your own

                            Cardano Wallet.</font> <br /><br />

                          At this time, we recommend creating an <font style={{ fontWeight: 'bold', color: 'white' }}>Eternl Wallet</font> or a <font style={{ fontWeight: 'bold', color: 'white' }}>Nami Wallet</font>. There are other options and many step-by-step tutorials on how to create a Cardano Wallet. And you will need to decide what the best option is for you.<br /><br />

                          The most likely route that you will take to fund your Cardano wallet is by sending yourself $ADA from a crypto exchange platform of your choice. DYOR and decide what the best exchange is for you and your geographic situation, if you don’t already have one.</p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}
                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">
                        <h2 style={{ padding: 10, width: '100%', textAlign: 'left', fontSize: 25, color: '#FAA652', marginTop: 10, marginBottom: 0, fontFamily: 'azonix' }}>STEP 2</h2>
                        <p style={{ textAlign: 'left', color: '#b3b3b3', marginTop: 20, marginBottom: 20, padding: 10 }}>
                          <font style={{ fontWeight: 'bold', color: 'white' }}>Become a part of the Ad Astrium community and stay up-to-date </font> by signing up with Discord and joining the Ad Astrium Discord Server. <br /><br />

                          Generally, Minting NFTs is an activity for community members who closely follow NFT projects. <br /><br />

                          Mint details and mechanics vary among projects. And ours will be announced and finalized in the Ad Astrium Discord Server and Twitter very soon. So turn on your Discord notifications and stay tuned!
                        </p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}
                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">
                        <h2 style={{padding: 10,  width: '100%', textAlign: 'left', fontSize: 25, color: '#FAA652', marginTop: 10, marginBottom: 0, fontFamily: 'azonix' }}>STEP 3</h2>
                        <p style={{ textAlign: 'left', color: '#b3b3b3', marginTop: 20, marginBottom: 20, padding: 10 }}>
                          <font style={{ fontWeight: 'bold', color: 'white' }}>Participate in the Ad Astrium community and follow our socials </font><br /><br />

                          Don't hesitate to get involved with our  <font style={{ fontWeight: 'bold', color: 'white' }}>community chat</font>  and hang out with us! Approach other community members or our friendly team to learn more about the project, the CNFT space, or just crypto in general.

                        </p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}
                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">

                        <h2 style={{padding: 10,  width: '100%', textAlign: 'left', fontSize: 25, color: '#FAA652', marginTop: 10, marginBottom: 0, fontFamily: 'azonix' }}>STEP 4</h2>
                        <p style={{ textAlign: 'left', color: '#b3b3b3', marginTop: 20, marginBottom: 20, padding: 10 }}>
                          <font style={{ fontWeight: 'bold', color: 'white' }}>Follow the Ad Astrium Twitter and Instagram </font><br /><br />

                          Not only will we be giving updates and uploading content, we will also be doing raffles exclusive to our Twitter and Instagram!
                        </p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}

                    <div className="container">
                      <div className="row" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 50, padding: 15 }}>
                        <a href="https://discord.gg/adastrium" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>JOIN OUR DISCORD</a>
                        {/* <a href="#details" className="tran3s filledButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ margin: 5, width: 300, fontWeight: 'bold' }}>JOIN OUR DISCORD </a> */}
                      </div>
                    </div>


                  </div>
                </div>
              </div> {/* /.container */}
            </div>
          </div>
          <img
            className="mx-auto d-block"
            src="images/home/astrid-characters-2.png"
          />

        </div> {/* /.our-portfolio */}



        {/* 
			=============================================
				Footer
			============================================== 
			*/}
  <footer className="bg-one">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="footer-logo">
                  <a href="index.html"><img src="images/logo/gs-logo.png" style={{ "max-width": "200px", "margin-bottom": "30px", }} alt="Logo" /></a>
                  {/* <h4 style={{ color: 'white' }}>{t('more_links')}</h4> */}
                  <ul style={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
                    <a href="#astridstars" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ASTRIDS </h5>
                    </a>
                    <a href="#starmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR MAP </h5>
                    </a>
                    <a href="#starregistry" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR REGISTRY </h5>
                    </a>
                    <a href="#roadmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ROAD MAP</h5> </a>
                    <a href="#team" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        TEAM </h5></a>
                    <a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        WHITEPAPER  </h5></a>
                    {/* <li><a style={{ color: 'white' }} href="/astrids" className="tran3s">ASTRIDS</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR REGISTRY</a></li>
                    <li><a style={{ color: 'white' }} href="/roadmap" className="tran3s">ROAD MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">TEAM</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="float-right tran4s" style={{ "display": "flex" }}>
                  {/* <a><img src="images/logo/logo.png" alt="Logo" /></a> */}
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/ig.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /></a></li>
                </div>
              </div>
            </div> {/* /.row */}
            {/* <div className="bottom-footer clearfix">
              <p className="text-center">© 2022 <a href="#" className="tran3s p-color">Ad Astrium</a>. All rights
                reserved</p>
            </div> */}
          </div> {/* /.container */}
        </footer>
        {/* Sign-Up Modal */}
        <div className="modal fade signUpModal theme-modal-box" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <h3 style={{ color: 'white' }}>Login with Social Networks</h3>
                <ul style={{ color: 'white' }} className="clearfix">
                  <li className="float-left"><a href="#"><i className="fa fa-facebook" aria-hidden="true" />
                    facebook</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-google-plus" aria-hidden="true" />
                    Google</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-twitter" aria-hidden="true" />
                    Twitter</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-linkedin" aria-hidden="true" />
                    Linkedin</a></li>
                </ul>
                <form action="#">
                  <h6><span>or</span></h6>
                  <div className="wrapper">
                    <input type="text" placeholder="Username or Email" />
                    <input type="password" placeholder="Password" />
                    <ul className="clearfix">
                      <li className="float-left">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember Me</label>
                      </li>
                      <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                    </ul>
                    <button className="p-bg-color hvr-trim-two">Login</button>
                  </div>
                </form>
              </div> {/* /.modal-body */}
            </div> {/* /.modal-content */}
          </div> {/* /.modal-dialog */}
        </div> {/* /.signUpModal */}
        {/* Scroll Top Button */}
        <button className="scroll-top tran3s">
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
        <div id="svag-shape">
          <svg height={0} width={0}>
            <defs>
              <clipPath id="shape-one">
                <path fillRule="evenodd" fill="rgb(168, 168, 168)" d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* Js File_________________________________ */}
        {/* j Query */}
        {/* Bootstrap Select JS */}
        {/* Bootstrap JS */}
        {/* Vendor js _________ */}
        {/* Camera Slider */}
        {/* Mega menu  */}
        {/* WOW js */}
        {/* owl.carousel */}
        {/* js count to */}
        {/* Fancybox */}
        {/* Theme js */}

      </div>

    </div>
  );
};

export default NewToNFT;
