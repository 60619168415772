import { useState, useEffect } from "react";
import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Mint } from "../../components/mint";
import { Ooze } from "../../components/ooze";
import { Merge } from "../../components/merge";
import axios from 'axios';
import env from "react-dotenv";
import { Roadmap } from "../../components/roadmap";
// import { Team } from "../../components/Team";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import "./index.css";
import { useMediaQuery } from 'react-responsive'

// import { HeaderMobile } from "../../components/mobile/header";
// import { MintMobile } from "../../components/mobile/mint";
// import { RoadmapMobile } from "../../components/mobile/roadmap";
// import { OozeMobile } from "../../components/mobile/ooze";
// import { MergeMobile } from "../../components/mobile/merge";

import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}



const Whitepaper = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    setLandingPageData(JsonData);
    // getAlert()
  }, []);

  function trans(val) {
    i18n.changeLanguage(val)
  }
  const { t, i18n } = useTranslation(['common']);

  const getAlert = () => {
    axios.get(env.API_URL + "metadata/getalert").then((res) => {
      try {
        let d = new Date(res.data.date);
        setMessage(res.data.message)
        setDate(d.toLocaleString())
      } catch (e) { }
    }).catch(() => {
    })
  }

  function toggleMenu() {
    let d;
    let c;
    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }



  return (
    <div className='intro'>

      <div className="main-page-wrapper">
        {/* ===================================================
				Loading Transition
		  	==================================================== */}
        <div id="loader-wrapper">
          <div id="loader">
            <ul>
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
        {/* 
        =============================================
          Theme Header
        ============================================== 
        */}
        {/* <div className="background-alert" style={{ height: 30, width: '100%' }}>
          <p className=" fadeInUp animated" data-wow-delay="0.2s" style={{ "-webkit-text-transform": "uppercase", "text-transform": "uppercase", 'text-align': 'center', 'color': 'white' }}>
            {t('alert')}</p>
        </div> */}
        <header className="theme-menu-wrapper full-width-menu">
          <div className="header-wrapper">
            <div className="clearfix" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Logo */}
              <div className="float-left tran4s" style={{ "display": "flex", maxWidth: '150px', margin: 15, transform: 'translateY(-5px)' }}>
                <a href="/"><img style={{
                  minWidth: '5rem',
                  maxWidth: '5rem'
                }} src="images/elements/alogo.png" alt="Logo" /></a>
              </div>

              {/* ============================ Theme Menu ========================= */}
              <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper" style={{ justifyContent: 'flex-end' }}>
                {/* Brand and toggle get grouped for better mobile display */}
                <div className="navbar-header">
                  <button onClick={() => toggleMenu()} type="button" className="navbar-toggle collapsed" >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                {!openNav &&

                  <div className="collapse navbar-collapse" id="navbar-collapse-1">
                    <ul className="nav">
                      <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                      </li>
                      <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                      </li>
                      <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                      </li>
                      <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                      </li>
                      <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                      </li>
                      <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s" style={{ "font-size": "15px", color: 'white', fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                      </li>

                    </ul>
                  </div>
                }
              </nav> {/* /.theme-main-menu */}
              <div className="float-left tran4s" style={{ "display": "flex" }}>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/ig.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/twitter.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/discord.png'} /></a></li>
              </div>

            </div> {/* /.clearfix */}
          </div>


          {openNav &&

            <div className="navbar-collapse" id="navbar-collapse-1" style={{
              width: '100%',
              position: 'absolute',
              zIndex: '200',
              backgroundColor: 'white',
            }}>
              <ul className="nav" style={{
                flexDirection: 'column',
                display: 'flex'
              }}>
                <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                </li>
                <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                </li>
                <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                </li>

                <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                </li>
                <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                </li>
                <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                </li>
              </ul>
            </div>
          }

        </header> {/* /.theme-menu-wrapper */}



        <div className="whitepaper-bg" id="mint">
          <div className="container">
            <div className="row" style={{ display: 'flex', flexDirection: 'row', marginTop: 100, marginBottom: 50 }}>
              <div className="container" >
                <div className="row" style={{
                  'align-items': 'center',
                  'display': 'flex',
                  'flex-direction': 'column',
                  padding: 20
                }}>
                  <img
                    className="mx-auto d-block"
                    src="images/logo/gs-logo.png"
                  />
                  <h2 className="wow fadeInUp animated" style={{ padding: 10, textAlign: 'center', "max-width": "700px", "margin-bottom": "20px", "margin-top": "50px", color: 'white' }}>
                    STARPAPER V0.5<br /><br />
                  </h2>

                </div>
              </div> {/* /.container */}
            </div>
          </div>
        </div> {/* /.our-portfolio */}

        <div className="whitepaper-color-bg" id="mint">
          <div className="container">
            <div className="row" style={{ display: 'flex', flexDirection: 'row', marginTop: 70, marginBottom: 50 }}>
              <div className="container" >
                <div className="row" style={{
                  // 'align-items': 'center',
                  'display': 'flex',
                  'flex-direction': 'column',
                  padding: 20
                }}>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>CONTENTS</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: '#b9b9b9' }}>
                    I. Introduction <br></br>
                    II. Vision<br></br>
                    III. Innovating in the Star Registry Industry<br></br>
                    IV. Foundational Product Components<br></br>
                    V. Community the True USP<br></br>
                    VI. Business Plans<br></br>
                    A. The Establishing Phases<br></br>
                    B. The Scaling Phases<br></br>
                    C. The Endgame and Moneymaking Phases<br></br>
                    VI. The Ad Astrium Star Map<br></br>
                    VII. The Ad Astrium Story and Lore<br></br>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>I. INTRODUCTION</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>
                    <font style={{ fontWeight: 'bold', color: 'white' }}>This Starpaper</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight' }}> is an ever-evolving document that contains high-level business initiatives and strategies that Team Ad Astrium have or will implement to attain their goals.
                      Relative to the time of reading, some of these initiatives will have already been implemented, will come to be implemented, or are currentily ongoing.
                      The contents of this Starpaper are subject to change without notice. Team Ad Astrium employs swift adaptation to market conditions, community sentiment, and unforeseeable circumstances. The Team is ever-analyzing present and future circumstances.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>This project, this product, this platform, Ad Astrium,</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> is an ever-evolving multimedia art and tech project that unites an original intellectual property with several novel NFT use cases, all of which will be geared towards establishing Ad Astrium as the quintessential star registry of the world.
                    </font>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>II. VISION</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>
                    <font style={{ fontWeight: 'bold', color: 'white' }}>Becoming the Quintessential Star Registry</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The endgame of Ad Astrium is to become the global standard for Star Ownership. This requires more than simply being the number 1 star registry of Cardano, nor of just Crypto. This requires the expansion into and takeover of, at the very least, a majority market share of the traditional Star Registry industry.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Ad Astrium will take many a variety of large scale measures simultaneous working to achieve this goal. Using the mediums of art, storytelling and technology, and fleshed out marketing plans. Akin to a hydra, each head will move independently but will all be working towards becoming the Quintessential Star Registry.
                    </font>
                    <br></br>
                    <br></br>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>III. INNOVATING THE STAR REGISTRY INDUSTRY</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>
                    <font style={{ fontWeight: 'bold', color: 'white' }}>Old. Tired. Centralized. Opportunity.</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> There are several traditional Star Registries, all of which are centralized and sell the same registration allocations of stars drawn from similar catalogues. For these registries, most often, the only indicator of ownership issued for their customers is a line of code in their systems and a star certificate official only to their centralized issuer.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Building a star registry in the NFT space revolutionizes this dated system and concept in two major fronts.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The first being the simple virtue of integrating NFTs as the providence for the stars. This results in a star providence with all the intrinsic qualities that make NFTs such a revolutionary piece of technology, indestructibility, non-fungibility, and full custodianship to asset owners.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The second is the community-centric nature of Crypto and NFT projects. If the value systems of the world, such as the baseless FIAT and more revolutionary cryptocurrencies are legitimized through the agreement of communities. It stands to reason that establishing an accepted form of star ownership is possible through the same means.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> If ten thousand, a hundred thousand, or a million people believe that Ad Astrium is the official way to own a star, then that becomes their truth.
                    </font>
                    <br></br>
                    <br></br>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>IV. FOUNDATIONAL PRODUCT COMPONENTS</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>
                    <font style={{ fontFamily: 'montserratlight' }}> The following are the core components that make up Ad Astrium. The bases upon which we will be seeking to improve and evolve over time.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>1. The Ad Astrium Star Registry</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The Ad Astrium Star Registry is based on the the HYG Database, a publicly available star catalogue constructed by David Nash. The HYG Database contains key star data of over 118,000 real stars. One dataset unique to the HYG Database is the cartesian plane coordinates for each star.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The Ad Astrium Star Registry will not be limited to the current set of stars in said database, but will continue to grow as more stars are catalogued, drawing from different publicly available catalogues if necessary. In addition to this, to accommodate our plans for scale, we are not limiting our Database to the roughly 118,000 catalogued stars, instead we have the intention of algorithmically generating stars, which will be fictitious in a sense but will be based on sound mathematical probability.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 2. The Ad Astrium Star Map</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> A departure from the generally flat Star Maps featured in traditional Star Registries which simulate a view of the starry night sky from Earth, The Ad Astrium Star Map is an immersive artistic and coordinate-accurate 3D recreation of our nearby cosmos which aims to give users and star owners the impression of being in the middle of space, among the stars.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> It will also host a wide range of features to make using the platform enjoyable such as smooth navigation features, artistic animations and music. Ever-evolving to reflect the project’s needs and goals, the Star Map will, in its infancy, be built and hosted on our servers to enable us to deliver fast and constant updates while simultaneously maintaining constant uptime and useability for the community.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Each coordinate-accurate star will be digital real-estate akin to plots commonly found in Metaverses concepts, for the most part, at least in the earliest iterations, hosted centrally while referencing the NFTs as providence.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> A large portion of the attention and reason for believing in Ad Astrium as the legitimate form of Star Ownership hinges on our execution of the Star Map concept. See section VI for more information on the Star Map.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  3. The Ad Astrium Star NFT Collections</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The Founder’s Collection, the Astrid Stars, will account for the 6,000 - 10,000 stars closest to the Sun. Another 10,000 - 20,000 are currently planned to be follow up collections, collaborative in nature. We hope to partner up with notable CNFT projects to have sections of the Star Map themed to their concept.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The remaining 98,000 real stars in the database will also be mintable as NFTs but will not be primarily marketed to the Cardano NFT community. But will instead be marketed outward into the Web2 market or into other NFT spacess using conventional Digital Advertising Methods.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  4. The Ad Astrium Story and Lore</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> In the beginning, via the concept of the Astrids, the story of Ad Astrium will function as an aid in resonating our vision and in a sense, our heart. It will serve to widen the scope of our reach and catch as many eyes that we hope to look deeper into the inner workings of what we are trying to build.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>  it will  The art and story will permeate throughout through themes in every product
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>  See section VII for more information about the Ad Astrium Story.
                    </font>
                    <br></br>
                    <br></br>
                  </h5>


                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>V. COMMUNITY, THE TRUE USP</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>
                    <font style={{ fontWeight: 'bold', color: 'white' }}>Ad Astrium seeks to build a product that is the first of its kind and achieve a goal that’s never been done: the establishing of a globally accepted form of owning celestial bodies lightyears away. There is no establishing of such an ethereal socially driven construct without an educated group of believers of said construct.</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> For Ad Astrium to progress towards its vision, the Ad Astrium community will need to simultaneously be investors, consumers, and ambassadors. And it is their collective belief in the vision that will be the true Unique Selling Proposition of Ad Astrium.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Given the integrality of such a deep relationship with community is to reaching our vision, even in the project’s infancy, it is the team’s absolute imperative to keep you engaged and incentivized. And to foster a relationship built on completely aligned motivations and trustlessness. Anything less and the project and vision dies.
                    </font>
                    <br></br>
                    <br></br>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>VI. BUSINESS PLANS</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>
                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Segmented into three distinct phase groups each with a key priority but non-mutually exclusive goals: Establishing, Sustaining, Dominating and Moneymaking.
                    </font>
                    <br></br>
                    <br></br>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>A. THE ESTABLISHING PHASES</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>
                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The establishing Phases will consist of one or two phases, the main goals of which will be to (1) establish our project and product in the space (2) establish a community of star owners and believers of the vision, the importance of which has been highlighted above. And (3) establish adequate funding to make our scaling plans feasible.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 1. Establishing the Foundational Components of the Product</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Build and launch foundational product components outlined in section IV of this paper and present it to the Cardano NFT space. These will be the
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  2. Establishing the Community</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Besides the obvious goal attaining a certain level of exposure that any blue-chip aspirant NFT project wishes to achieve, we also seek and need to establish a deep level of education and belief as stated in section V.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> We plan to achieve the deep level of belief by constantly exhibiting three “proofs” to our community.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  a. Proof of and Education of The Ad Astrium Concept</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Proof that reaching the vision is feasible if we stick to the outlines and path laid out in this evolving paper. But such an esoteric pitch will require us to partake in all avenues of exposure as a platform for education of our product.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Such methods we are currently or are planning to do are as follows: <br></br>
                      1. Slow drip of information via Twitter posts <br></br>
                      2. More detailed information via Website <br></br>
                      3. Exhaustive and comprehensive of readily available information such as this paper <br></br>
                      4. Twitter Spaces and Youtube Reviews and Interviews for a more personal and deeper reach <br></br>
                      5. And the fostering of ambassadorship in our community <br></br>
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> b. Proof of Capability and Competency</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>We will exhibit this proof (1) through the timely and quality execution of our committed roadmap deliverables. (2) Through stellar a Marketing Performance by garnering a sizeable twitter and discord following with little to no promotional budget and absolutely no connections to influencial members of the CNFT community prior to launch, at the time of this writing this has already been partly achieved. (3) And by engaging the community in unique and original ways, as seen with the Nova Missions, which was not originally planned out but is received overwhelmingly positively.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> c. Proof of Good Faith</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>We will exhibit this proof (1) by doxxing. (2) By making the Star Map Demo publicly available to try out from its alpha stage all throughout its lifetime. (3) Through transparency and open dialogue. (4) By always considering secondary market value of our collections over profiteering.
                    </font>
                    <br></br>
                    <br></br>


                    <font style={{ fontWeight: 'bold', color: 'white' }}>  3. Establishing Adequate Funding to Scale</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>Team Ad Astrium will require roughly 250,000 USD to maintain operational for 1 year. In other words, the “sale” or minting of roughly 6,000 NFTs with a mint cost of 150 ADA each, not accounting for royalties.  If less or merely this minimum amount is raised during phase 1, we are prepared to launch an additional establishing phase to build the budget needed to sustain and scale.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>During the probable additional establishing phase, we will seek to double the size of the core community in addition to building the scaling phase budget through the use of either another Star NFT collection and/or some other form of supplementary collection such as a PFP collection. More phases beyond a second establishing phase will be added if necessary, but we assume they will be geared towards transitioning to the Scaling phases.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>We estimate at least another 250,000 USD will be required to (1) launch our large scale Web2 advertising efforts, (2) expand the team’s art and tech departments, and (3) advance the project into a state wherein it will be able to generate enough cashflow from outside of the Cardano NFT space to be able to start sustaining itself.
                    </font>
                    <br></br>
                    <br></br>
                  </h5>


                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>B. THE SCALING PHASES</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>


                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> This section highlights the tools we will have at our disposal to execute a successful scaling plan and the broad outline of said plan. More information and specificity will be added to this section, in later versions of the paper.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 1. Scaling Tools and Advantages</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> a. Team Ad Astrium’s unique core skills and talents</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Leverage on founder’s storied experience in digital and social media advertising and a key member’s local influencer network. See section IX for more details
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> b. The Repackagability of Ad Astrium’s Unique Selling Proposition </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> As highlighted in sections II, III, and IV, we are building a unique product with not only with multiple parts. But also components that can be framed and packaged in different ways to fit certain types of consumers.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>  1. The digital star will be presented as a scarce piece of digital real estate for speculators and investors<br></br>
                      2. The digital star will be presented as a compelling promotional tool, see section VI for more information.<br></br>
                      3, The connection with the real star will be presented as a prestige item that cannot be replicated<br></br>
                      4. The NFT art will be packaged as a part of the Ad Astrium story and lore, see section VII for more information.<br></br>
                      5. The combination of the three may be packaged as an ideal and novel gifts for significant others, friends, and family.<br></br>
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>c. Team Ad Astrium’s Unique Geographical advantage </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>The Philippines has the densest CNFT Community by geography. Ranking third according to a traffic analysis conducted on the jpg.store site. Accounting for 7% of the entire Cardano NFT space. Taking into consideration the small land area of the nation and how most technologically leaning individuals reside in Metro Manila, we estimate that the country is roughly 20x more CNFT consumer dense than any other geographical location in the world. And even moreso for Metro Manila.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>In addition to this note, Filipino consumers are largely credited as accounting for a large portion of Axie Infinity’s total market cap during its peak, we are certain that there is an ocean of NFT enthusaists that we can potentially tap into for Cardano right in our own backyard.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>The Philippines also has an excess of cost-efficient talent ranking 2nd, globally, in financial attractiveness for business process outsourcing, closely following India who currently leads. The Philippines is an outsourcing hotspot for numerous animation studios including Disney, Paramount, and top Japanese Animation studios for cheap world-class quality art output. The same statement applies for software and game development.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> d. Feasibility </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>In relation to the geographical advantage that comes with being based in the Philippines. As a third-world country, our team is accustomed to a lower standard and cost of living than our first-world nation contemporaries. That said, the overhead we require to continue providing our current quality of output will be miniscule in relation.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>To put this into contect, the average monthly salary of Filipinos is roughly 1,000.00 USD per month, which at most, is 1/6th of those in most developed nations. The minimum monthly salaries of Ad Astrium employees will range from 700.00 USD - 2,800.00 USD per month, from the least paticipative member all the way up to the Founders.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>As mentioned in section V-A-3 of this paper, The entirety of Team Ad Astrium only requires 250,000.00 USD to maintain the current level of productivity and operations for 1 year. The majority of the funds raised in excess of this will be allocated to (1) increasing efficiency by expanding the team, (2) securing a longer guaranteed operational timeframe, and (3) executing our marketing and advertising campaigns necessary to scale.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  2. High-level Outline of Scaling Plans</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>Given that these plans portion of our plans are still quite journey away, at the time of this writing. What we are currently able to provide below are high-level outlines of our plans and an overview of their justifications. We will provide more depth and actual actionable plans in later versions of this litepaper.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> a. Star Registry Expansion</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>As mentioned in section IV-1, the Ad Astrium registry is a database containing over 118,000 real stars. That, coupled with our full intention to add any real stars that Astronomers discover, catalogue and publish to update reputable star catalogues. And our intention to infinitely expand the database with fictitious stars using mathematically estimated star coordinates. Will result in enabling the Ad Astrium Star Registry to accomodate millions of users if and when necessary.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> b. Star Map Utility Evolution</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>Mentioned in section IV-2, a large component of our plans to scale is the progressive and unintrusive evolution of the Ad Astrium Star Map into a platform which can attract and accommodates users numbering in the millions.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>To achieve this, we plan to gradually and consistently rollout new and improved iterations of the Star Map. While the first, versions will simply be a fun and entertaining way for Star Owners to interact with and show off their stars, future iterations will have features catered to providing real-world promotional utility.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>Current and future star map features and early concepts are outlined in more detail in section VI of this paper.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> c. Aggressive Educative Advertising </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>Leveraging on Founder Parsec’s specialty of digital advertising and network in said industry, Andromeda’s connections to the local celebrity scene, and the unique NFT consumer density of our base country, Team Ad Astrium plans to execute countrywide digital and possibly physical advertising campaigns. And do so more cost-efficiently than normal businesses. The theory is that through agressive educative advertising in such a condensed area, we may, in a financially self-sustaining manner, trigger a snowball effect that helps encourage the rest of the world’s market to follow suit with adopting the product and idea of star ownership.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>In tandem with running our local full scale advertising campaigns, we will also be running a global advertising advertising campaign targetted to our target niches.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  d. Ecosystem Building and Supplementing Products </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>We will continue to develop the supplementing components of Ad Astrium such as the story and lore. See section VII for more information. And explore other novel applications we can develop to build and bolster an ecosystem, see section VII for more information regarding such early concepts.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> e. Team Expansion and Partnerships with Business Consultancies</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>While we have ridiculously idealistic and lofty goals, we are realistic. To progress and achieve this monumental task, we have full intention to source better and specialized minds by both expanding the team and through consultancy basis. Founder Epoch, was a part of one of the Philippines’ leading Corporate Real Estate and Business Consultancy Firms and has maintained a healthy relationship with them. Making it relatively easy to find, at the very least, majority of the talent needed to run a global brand.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> f. Establishing of Various Monetization Structures</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>Make moolah. Ads. stuff. Subscription services. Cosmetics. Non-utility NFTs
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> g. Grow, Earn, Reinvest Everything, Repeat</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>We have no intention of cashing out prematurely. We keep going. Reinvest near everything into growing. And repeat. For however many Scaling Phases it may take. We keep going until we reach the endgame.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> C. THE ENDGAME AND TRUE MONEYMAKING PHASES</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>Once we reach a state wherein we believe we have established ourselves as a global standard for star ownership, have built a Star Registry and Star Map userbase in the millions. Have developed numerous supporting apps and products. Have built a globally recognized IP. And have built an ecosystem that cohesively connects all of these. We will begin allocating less of our profits into reinvesting and more towards ourselves and the holders of our Founder’s Collection, the Astrid Stars Collection.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}>This is the investment pitch we are making to our core community, the Astrid Holders, the Founding Few Thousand that will stand atop a community of millions.
                    </font>
                    <br></br>
                    <br></br>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>VI. AD ASTRIUM STAR MAP</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>


                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> What the Star Map will become, the routes we will take, and the duration of time it will take to get there, will highly depend on what our current tech team can achieve, the talent we are able to find and work with along the way, and of course, the needs and wants of the community.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>A. STAR MAP </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Built using three.js, the star map is a 3D space that hosts 20,000 star assets, at the current time of writing. Each star on the star map is coordinate-accurate to the degree that the star catalogue it was based off of is accurate.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>B. STAR MAP DEFINED BY CATEGORIZATION OF FUNCTIONALITIES </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The following are a mix of features and functionalities that range from upcoming and promised to very early concept.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 1. The Star Map is a Unique Way to Interact With Your Star</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Star Map Version 1 which includes features such as (1) intuitive navigation features, (2) search functions, (3) clickable stars with popup windows, (4) a star link share feature, (5) NFT metadata referencing, and (6) a custom soundtrack is intended to be a fully functional, interactive, and fresh avenue for owners to interact with their star. While simultaneously forming the baseline and being the foundation for a wide range of additional features in both the “already feasible” category to the very early concept.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 2. The Star Map is a Social Platform</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Already feasible features such as Star Tethering will allow users to form star groups with other users of the Star Map. Indicated by a visible beams of light that connectsfrom one star to the closest one in their group which imitates the visuals of constellations. And Tiles and Tile Mode, which can be toggled on/off to transform every star in the star map into a tile containing each user’s custom jpg image. Both add a layer of social activity and interest to the star map.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  3. The Star Map is a Promotional Tool</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> It is planned for each star popup window to be able to function, similar to a linktree page, being able to hold and display a star owner’s preferred links. It will have a function be shared in link format and can function as free replacement to linktree, having most if not all of the core functions and the added feature of being in a vast 3D visualization.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  4. The Star Map is an Every Metaverse Access Point</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Another early concept is to take advantage of the cosmic layout of the Star Map and have it host links or some form of access to other metaverses. This can be done thematically by dedicating sections of the Star Map containing a few hundred or thousand special stars, whose planets in turn can represent and be the access point to real estate based metaverse concepts.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  5. The Star Map as an RPG Style Metaverse</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Third person view and “in-game” avatars, in space activities such as gambling, planets that can be entered, are not out of the realm of possibility. This just takes time, meeting the right people, and having adequate funding to realize.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  6. The Star Map is a Revenue Stream</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Being able to monetize the Star Map hinges on the scope and quality of attention currency it can provide. If or when we determine that the features that make the Star Map a social platform and promotional tool have reached an ad quality and usage, several possible ways to monetize come into play. 
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Such as featured stars, pay or subscription gating of full features to free users, Ad spot partnerships
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> B. STARS MAP EVOLUTION THROUGH VERSIONS
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The Star Map will be available to experience from its very alpha stage. Updates will be constantly rolled out as development progresses. This is to be able to constantly exhibit our proof of competence and good faith. Version 1 will be a marker of the Star Map's minimum viable product stage, which is when we deem it to be fit for the use of our core star community
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Functionalities and visual improvements will be continuously developed and uploaded making the transition to succeeding versions mostly a cosmetic milestone marker for marketing purposes. 
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> D. STARS AS REAL ESTATE TO OWN AND RENT
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> As a whole, star collections will be made available to mint by order of distance to “spawn”, the coordinates of the Sun at 0,0,0,  
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Very early concept stuff so take these with a massive grain of salt<br></br>
                    Third person view and “in-game” avatars <br></br>
                    Planets  to function as metaverse portals which can effectively make Ad Astrium the metaverse to host access of all metaverses.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> E. MONETIZING THE STAR MAP
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Being able to monetize the Star Map hinges on the attention currency it can provide. 
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Through once we reach a state that’s good enough, we can monetize by gating the star map’s full features to free registrants and users of fictitious mathematically generated stars via a subscription or one-time payment.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Prior to that, we may also sell ad space in the form of visual banners within the star map located in the far distances of the 3D space, which we can boost by incentivizing the star community to interact with it. 
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> F. FULL INTENT TO TRULY DECENTRALIZE THE PLATFORM
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The tech isn’t here yet to constatly update and stuff. When it comes we will do it. 
                    </font>
                    <br></br>
                    <br></br>
                  </h5>

                  <h2 style={{ fontFamily: 'montserrat', textDecoration: 'underline' }}>VII. AD ASTRIUM STORY</h2>
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'left', "margin-bottom": "40px", "margin-top": "10px", color: 'white' }}>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>A. FUNCTION OF THE ASTRIDS </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The Astrids, the mascots of the Ad Astrium Story. Their concept serves to lay the foundation for the eventual Ad Astrium story which will be explored more beyond phase 1. The Astrids will function as cute ‘guardian angel-esque’ side-kick tropes to many main characters of several anthological stories written by fans, by the team, or by artist and writer partnerships. Their concept is intended to make it easy for anyone to create self-contained stories of any genre and artworks of any style with the simple addition of a helpful Astrid. 
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>B. ANTHOLOGICAL NATURE OF THE AD ASTRIUM UNIVERSE </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The concept of the Astrids ties in to the ability of anybody to write their own self contained stories bound by the rules set of the Astrid abilities. 
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>C. OTHER CHARACTER GROUPS </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 1. The Stargazers (Male and Female)</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The actual heroes of the story are you, the Stargazers. A possible PFP collection may be made from this concept.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 2. Astrid Supernova</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> When Astrid. The basis of the OG Pass of Ad Astrium
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> 3. Astrid Singularities</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Black holes personified.
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  4. The Embodiment of Heat Death</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> The antithesis to life, energy. A sort of dark side of the force concept. 
                    </font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}>  5. Others</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontWeight: 'bold', color: 'white' }}> D. STORY DEVELOPMENT PLANS</font>
                    <br></br>
                    <br></br>

                    <font style={{ fontFamily: 'montserratlight', color: '#b9b9b9' }}> Hire and partner with professional writers and artists to help develop the lore and. 
                    </font>
                    <br></br>
                    <br></br>

                  </h5>

                </div>
              </div> {/* /.container */}
            </div>
          </div>
        </div> {/* /.our-portfolio */}


        {/* 
			=============================================
				Footer
			============================================== 
			*/}
  <footer className="bg-one">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="footer-logo">
                  <a href="index.html"><img src="images/logo/gs-logo.png" style={{ "max-width": "200px", "margin-bottom": "30px", }} alt="Logo" /></a>
                  {/* <h4 style={{ color: 'white' }}>{t('more_links')}</h4> */}
                  <ul style={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
                    <a href="#astridstars" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ASTRIDS </h5>
                    </a>
                    <a href="#starmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR MAP </h5>
                    </a>
                    <a href="#starregistry" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR REGISTRY </h5>
                    </a>
                    <a href="#roadmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ROAD MAP</h5> </a>
                    <a href="#team" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        TEAM </h5></a>
                    <a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        WHITEPAPER  </h5></a>
                    {/* <li><a style={{ color: 'white' }} href="/astrids" className="tran3s">ASTRIDS</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR REGISTRY</a></li>
                    <li><a style={{ color: 'white' }} href="/roadmap" className="tran3s">ROAD MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">TEAM</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="float-right tran4s" style={{ "display": "flex" }}>
                  {/* <a><img src="images/logo/logo.png" alt="Logo" /></a> */}
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/ig.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /></a></li>
                </div>
              </div>
            </div> {/* /.row */}
            {/* <div className="bottom-footer clearfix">
              <p className="text-center">© 2022 <a href="#" className="tran3s p-color">Ad Astrium</a>. All rights
                reserved</p>
            </div> */}
          </div> {/* /.container */}
        </footer>
        {/* Sign-Up Modal */}
        <div className="modal fade signUpModal theme-modal-box" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <h3 style={{ color: 'white' }}>Login with Social Networks</h3>
                <ul style={{ color: 'white' }} className="clearfix">
                  <li className="float-left"><a href="#"><i className="fa fa-facebook" aria-hidden="true" />
                    facebook</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-google-plus" aria-hidden="true" />
                    Google</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-twitter" aria-hidden="true" />
                    Twitter</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-linkedin" aria-hidden="true" />
                    Linkedin</a></li>
                </ul>
                <form action="#">
                  <h6><span>or</span></h6>
                  <div className="wrapper">
                    <input type="text" placeholder="Username or Email" />
                    <input type="password" placeholder="Password" />
                    <ul className="clearfix">
                      <li className="float-left">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember Me</label>
                      </li>
                      <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                    </ul>
                    <button className="p-bg-color hvr-trim-two">Login</button>
                  </div>
                </form>
              </div> {/* /.modal-body */}
            </div> {/* /.modal-content */}
          </div> {/* /.modal-dialog */}
        </div> {/* /.signUpModal */}
        {/* Scroll Top Button */}
        <button className="scroll-top tran3s">
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
        <div id="svag-shape">
          <svg height={0} width={0}>
            <defs>
              <clipPath id="shape-one">
                <path fillRule="evenodd" fill="rgb(168, 168, 168)" d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* Js File_________________________________ */}
        {/* j Query */}
        {/* Bootstrap Select JS */}
        {/* Bootstrap JS */}
        {/* Vendor js _________ */}
        {/* Camera Slider */}
        {/* Mega menu  */}
        {/* WOW js */}
        {/* owl.carousel */}
        {/* js count to */}
        {/* Fancybox */}
        {/* Theme js */}

      </div>

    </div>
  );
};

export default Whitepaper;
