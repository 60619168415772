import { useState, useEffect } from "react";
import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Mint } from "../../components/mint";
import { Ooze } from "../../components/ooze";
import { Merge } from "../../components/merge";
import axios from 'axios';
import env from "react-dotenv";
// import { Roadmap } from "../../components/roadmap";
import { Team } from "../../components/Team";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import "./index.css";
import { useMediaQuery } from 'react-responsive'

// import { HeaderMobile } from "../../components/mobile/header";
// import { MintMobile } from "../../components/mobile/mint";
// import { RoadmapMobile } from "../../components/mobile/roadmap";
// import { OozeMobile } from "../../components/mobile/ooze";
// import { MergeMobile } from "../../components/mobile/merge";

import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}



const Roadmap = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    setLandingPageData(JsonData);
    // getAlert()
  }, []);

  function trans(val) {
    i18n.changeLanguage(val)
  }
  const { t, i18n } = useTranslation(['common']);

  const getAlert = () => {
    axios.get(env.API_URL + "metadata/getalert").then((res) => {
      try {
        let d = new Date(res.data.date);
        setMessage(res.data.message)
        setDate(d.toLocaleString())
      } catch (e) { }
    }).catch(() => {
    })
  }

  function toggleMenu() {
    let d;
    let c;
    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }



  return (
    <div className='intro'>

      <div className="main-page-wrapper">
        {/* ===================================================
				Loading Transition
		  	==================================================== */}
        <div id="loader-wrapper">
          <div id="loader">
            <ul>
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
        {/* 
        =============================================
          Theme Header
        ============================================== 
        */}
        {/* <div className="background-alert" style={{ height: 30, width: '100%' }}>
          <p className=" fadeInUp animated" data-wow-delay="0.2s" style={{ "-webkit-text-transform": "uppercase", "text-transform": "uppercase", 'text-align': 'center', 'color': 'white' }}>
            {t('alert')}</p>
        </div> */}
           <header className="theme-menu-wrapper full-width-menu">
          <div className="header-wrapper">
            <div className="clearfix" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Logo */}
              <div className="float-left tran4s" style={{ "display": "flex", maxWidth: '150px', margin: 15, transform: 'translateY(-5px)' }}>
                <a href="/"><img style={{
                  minWidth: '5rem',
                  maxWidth: '5rem'
                }} src="images/elements/alogo.png" alt="Logo" /></a>
              </div>

              {/* ============================ Theme Menu ========================= */}
              <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper" style={{ justifyContent: 'flex-end' }}>
                {/* Brand and toggle get grouped for better mobile display */}
                <div className="navbar-header">
                  <button onClick={() => toggleMenu()} type="button" className="navbar-toggle collapsed" >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                {!openNav &&

                  <div className="collapse navbar-collapse" id="navbar-collapse-1">
                    <ul className="nav">
                      <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                      </li>
                      <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                      </li>
                      <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                      </li>
                      <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                      </li>
                      <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                      </li>
                      <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s" style={{ "font-size": "15px", color: 'white', fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                      </li>

                    </ul>
                  </div>
                }
              </nav> {/* /.theme-main-menu */}
              <div className="float-left tran4s" style={{ "display": "flex" }}>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/ig.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/twitter.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/discord.png'} /></a></li>
              </div>

            </div> {/* /.clearfix */}
          </div>


          {openNav &&

            <div className="navbar-collapse" id="navbar-collapse-1" style={{
              width: '100%',
              position: 'absolute',
              zIndex: '200',
              backgroundColor: 'white',
            }}>
              <ul className="nav" style={{
                flexDirection: 'column',
                display: 'flex'
              }}>
                <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                </li>
                <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                </li>
                <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                </li>

                <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                </li>
                <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                </li>
                <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                </li>
              </ul>
            </div>
          }

        </header> {/* /.theme-menu-wrapper */}

        {/* 
			=============================================
				What We Do
			============================================== 
			*/}
        <div className="what-we-do" style={{ "padding-top": "20rem", "padding-bottom": "10rem" }}>
          <div className="container">
            {/* <h3>The thing that motivates me is a very common form of motivation. And that is, with other folk counting on me, it's so easy to be motivated.</h3>
					<h6>we work with business &amp; provide solution to client with their business problem</h6> */}
            <div className="row">

              <h2 style={{ color: '#2F1649' }}>Roadmap
              </h2>

              <section id="conference-timeline">

                <div className="timeline-start">
                  <h2 style={{ color: 'white' }}>Phase 1 - Short Term
                  </h2>
                  <h5 style={{ maxWidth: '800px !important', color: 'lightgray', maxWidth: 'initial' }}>
                    The following portion of the roadmap are already under development
                    and will adhere to the established timelines.
                  </h5>
                </div>
                <div className="conference-center-line"></div>
                <div className="conference-timeline-content">
                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div className="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>October 2022 <br /> Ad Astrium Project Launch </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>The beginning of our journey will focus on building the OG community and establishing friendships with existing CNFT projects. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium Website
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium Discord, Twitter and IG
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map Teaser
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>October 2022 <br /> Good Morning, Cosmos </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Release of the Ad Astrium Star Map Demo Version and the Star Map V1 Whitepaper. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map Demo Version
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map V1 Whitepaper
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">

                    </div>
                  </div>

                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div className="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022  <br /> Hello Astrids </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Launch of our founder’s collection, The Astrid Stars collection.</p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Astrid Stars NFT drop
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Astrid Rarity Rankings
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>December 2022 <br /> As the Stardust Settles </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>A major milestone of our blockchain and software development. The Ad Astrium Star Map V1, will be our first fully functioning product. Yet, this is only the beginning. This will be the foundation for so much more. </p>
                        <p style={{ textAlign: 'left', color: '#2F1649', fontWeight: 'bold', }}>Star Map V1</p>

                        <ul className="ulroadmap">
                          <li>
                            Star Renaming
                          </li>
                          <li>
                            Star Dedication
                          </li>
                          <li>
                            Tiles
                          </li>
                          <li>
                            Star Dashboard
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">
                    </div>
                  </div>

                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div class="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022/ Q1 2023 <br /> Welcome to Cardano NFTs</p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Our team seeks to do our part in growing the CNFT community. Leveraging on our resources and connections, one focus of our marketing strategy is the introduction and education of Web 3.0 and CNFTs to the uninitiated, gaining new members and talent alike. We will grow together. To the stars, together. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Web 2.0 and Traditional Marketing Push
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Artist Partnership Program Begins
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium IG and Tiktok Content
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022/Q1 2023 <br /> Time for Business </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Our Business Litepaper will reveal our future product concepts and strategies. Which includes details of our planned revenue streams that aims to treat holders as investors and not cash cows. </p>
                        <p style={{ textAlign: 'left', color: '#2F1649', fontWeight: 'bold' }}>Project and Business Litepaper</p>
                        <ul className="ulroadmap">
                          <li>
                            Product Development and Strategies
                          </li>
                          <li>
                            Profit-sharing and Revenue Streams
                          </li>
                          <li>
                            The Ad Astrium Metaverse Concept
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">

                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <a href="#details" className="tran3s outlineButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ margin: 5, border: '#2F1649 solid 2px', color: '#2F1649' }}>VIEW FULL ROAD MAP </a>
                </div> */}
              </section>

              <section id="conference-timeline">

                <div className="timeline-start">
                  <h2 style={{ color: 'white' }}>Medium Term
                  </h2>
                  <h5 style={{ maxWidth: '800px !important', color: 'lightgray', maxWidth: 'initial' }}>
                    The following portion of the roadmap are already under development
                    and will adhere to the established timelines.
                  </h5>
                </div>
                <div className="conference-center-line"></div>
                <div className="conference-timeline-content">
                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div className="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>October 2022 <br /> Ad Astrium Project Launch </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>The beginning of our journey will focus on building the OG community and establishing friendships with existing CNFT projects. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium Website
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium Discord, Twitter and IG
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map Teaser
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>October 2022 <br /> Good Morning, Cosmos </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Release of the Ad Astrium Star Map Demo Version and the Star Map V1 Whitepaper. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map Demo Version
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map V1 Whitepaper
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">

                    </div>
                  </div>

                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div className="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022  <br /> Hello Astrids </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Launch of our founder’s collection, The Astrid Stars collection.</p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Astrid Stars NFT drop
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Astrid Rarity Rankings
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>December 2022 <br /> As the Stardust Settles </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>A major milestone of our blockchain and software development. The Ad Astrium Star Map V1, will be our first fully functioning product. Yet, this is only the beginning. This will be the foundation for so much more. </p>
                        <p style={{ textAlign: 'left', color: '#2F1649', fontWeight: 'bold', }}>Star Map V1</p>

                        <ul className="ulroadmap">
                          <li>
                            Star Renaming
                          </li>
                          <li>
                            Star Dedication
                          </li>
                          <li>
                            Tiles
                          </li>
                          <li>
                            Star Dashboard
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">
                    </div>
                  </div>

                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div class="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022/ Q1 2023 <br /> Welcome to Cardano NFTs</p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Our team seeks to do our part in growing the CNFT community. Leveraging on our resources and connections, one focus of our marketing strategy is the introduction and education of Web 3.0 and CNFTs to the uninitiated, gaining new members and talent alike. We will grow together. To the stars, together. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Web 2.0 and Traditional Marketing Push
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Artist Partnership Program Begins
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium IG and Tiktok Content
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022/Q1 2023 <br /> Time for Business </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Our Business Litepaper will reveal our future product concepts and strategies. Which includes details of our planned revenue streams that aims to treat holders as investors and not cash cows. </p>
                        <p style={{ textAlign: 'left', color: '#2F1649', fontWeight: 'bold' }}>Project and Business Litepaper</p>
                        <ul className="ulroadmap">
                          <li>
                            Product Development and Strategies
                          </li>
                          <li>
                            Profit-sharing and Revenue Streams
                          </li>
                          <li>
                            The Ad Astrium Metaverse Concept
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">

                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <a href="#details" className="tran3s outlineButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ margin: 5, border: '#2F1649 solid 2px', color: '#2F1649' }}>VIEW FULL ROAD MAP </a>
                </div> */}
              </section>

              <section id="conference-timeline">

                <div className="timeline-start">
                  <h2 style={{ color: 'white' }}>Ad Astrium Et Ultra
                  </h2>
                  <h5 style={{ maxWidth: '800px !important', color: 'lightgray', maxWidth: 'initial' }}>
                    The following portion of the roadmap are already under development
                    and will adhere to the established timelines.
                  </h5>
                </div>
                <div className="conference-center-line"></div>
                <div className="conference-timeline-content">
                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div className="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>October 2022 <br /> Ad Astrium Project Launch </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>The beginning of our journey will focus on building the OG community and establishing friendships with existing CNFT projects. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium Website
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium Discord, Twitter and IG
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map Teaser
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>October 2022 <br /> Good Morning, Cosmos </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Release of the Ad Astrium Star Map Demo Version and the Star Map V1 Whitepaper. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map Demo Version
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Star Map V1 Whitepaper
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">

                    </div>
                  </div>

                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div className="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022  <br /> Hello Astrids </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Launch of our founder’s collection, The Astrid Stars collection.</p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Astrid Stars NFT drop
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Astrid Rarity Rankings
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>December 2022 <br /> As the Stardust Settles </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>A major milestone of our blockchain and software development. The Ad Astrium Star Map V1, will be our first fully functioning product. Yet, this is only the beginning. This will be the foundation for so much more. </p>
                        <p style={{ textAlign: 'left', color: '#2F1649', fontWeight: 'bold', }}>Star Map V1</p>

                        <ul className="ulroadmap">
                          <li>
                            Star Renaming
                          </li>
                          <li>
                            Star Dedication
                          </li>
                          <li>
                            Tiles
                          </li>
                          <li>
                            Star Dashboard
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">
                    </div>
                  </div>

                  <div className="timeline-article">
                    <div className="content-left-container">
                      <div class="content-left">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022/ Q1 2023 <br /> Welcome to Cardano NFTs</p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Our team seeks to do our part in growing the CNFT community. Leveraging on our resources and connections, one focus of our marketing strategy is the introduction and education of Web 3.0 and CNFTs to the uninitiated, gaining new members and talent alike. We will grow together. To the stars, together. </p>
                        <ul className="ulroadmap">
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Web 2.0 and Traditional Marketing Push
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Artist Partnership Program Begins
                          </li>
                          <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                            Ad Astrium IG and Tiktok Content
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content-right-container">
                      <div className="content-right">
                        <p style={{ textAlign: 'left', color: '#2F1649', textDecoration: 'underline', fontWeight: 'bold', marginBottom: 10 }}>Q4 2022/Q1 2023 <br /> Time for Business </p>
                        <p style={{ textAlign: 'left', color: '#2F1649' }}>Our Business Litepaper will reveal our future product concepts and strategies. Which includes details of our planned revenue streams that aims to treat holders as investors and not cash cows. </p>
                        <p style={{ textAlign: 'left', color: '#2F1649', fontWeight: 'bold' }}>Project and Business Litepaper</p>
                        <ul className="ulroadmap">
                          <li>
                            Product Development and Strategies
                          </li>
                          <li>
                            Profit-sharing and Revenue Streams
                          </li>
                          <li>
                            The Ad Astrium Metaverse Concept
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="meta-date">

                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <a href="#details" className="tran3s outlineButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ margin: 5, border: '#2F1649 solid 2px', color: '#2F1649' }}>VIEW FULL ROAD MAP </a>
                </div> */}
              </section>

            </div> {/* /.row */}
          </div> {/* /.container */}
        </div> {/* /.what-we-do */}



        {/* 
			=============================================
				Footer
			============================================== 
			*/}
  <footer className="bg-one">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="footer-logo">
                  <a href="index.html"><img src="images/logo/gs-logo.png" style={{ "max-width": "200px", "margin-bottom": "30px", }} alt="Logo" /></a>
                  {/* <h4 style={{ color: 'white' }}>{t('more_links')}</h4> */}
                  <ul style={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
                    <a href="#astridstars" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ASTRIDS </h5>
                    </a>
                    <a href="#starmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR MAP </h5>
                    </a>
                    <a href="#starregistry" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR REGISTRY </h5>
                    </a>
                    <a href="#roadmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ROAD MAP</h5> </a>
                    <a href="#team" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        TEAM </h5></a>
                    <a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        WHITEPAPER  </h5></a>
                    {/* <li><a style={{ color: 'white' }} href="/astrids" className="tran3s">ASTRIDS</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR REGISTRY</a></li>
                    <li><a style={{ color: 'white' }} href="/roadmap" className="tran3s">ROAD MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">TEAM</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="float-right tran4s" style={{ "display": "flex" }}>
                  {/* <a><img src="images/logo/logo.png" alt="Logo" /></a> */}
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/ig.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /></a></li>
                </div>
              </div>
            </div> {/* /.row */}
            {/* <div className="bottom-footer clearfix">
              <p className="text-center">© 2022 <a href="#" className="tran3s p-color">Ad Astrium</a>. All rights
                reserved</p>
            </div> */}
          </div> {/* /.container */}
        </footer>
        {/* Sign-Up Modal */}
        <div className="modal fade signUpModal theme-modal-box" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <h3 style={{ color: 'white' }}>Login with Social Networks</h3>
                <ul style={{ color: 'white' }} className="clearfix">
                  <li className="float-left"><a href="#"><i className="fa fa-facebook" aria-hidden="true" />
                    facebook</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-google-plus" aria-hidden="true" />
                    Google</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-twitter" aria-hidden="true" />
                    Twitter</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-linkedin" aria-hidden="true" />
                    Linkedin</a></li>
                </ul>
                <form action="#">
                  <h6><span>or</span></h6>
                  <div className="wrapper">
                    <input type="text" placeholder="Username or Email" />
                    <input type="password" placeholder="Password" />
                    <ul className="clearfix">
                      <li className="float-left">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember Me</label>
                      </li>
                      <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                    </ul>
                    <button className="p-bg-color hvr-trim-two">Login</button>
                  </div>
                </form>
              </div> {/* /.modal-body */}
            </div> {/* /.modal-content */}
          </div> {/* /.modal-dialog */}
        </div> {/* /.signUpModal */}
        {/* Scroll Top Button */}
        <button className="scroll-top tran3s">
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
        <div id="svag-shape">
          <svg height={0} width={0}>
            <defs>
              <clipPath id="shape-one">
                <path fillRule="evenodd" fill="rgb(168, 168, 168)" d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* Js File_________________________________ */}
        {/* j Query */}
        {/* Bootstrap Select JS */}
        {/* Bootstrap JS */}
        {/* Vendor js _________ */}
        {/* Camera Slider */}
        {/* Mega menu  */}
        {/* WOW js */}
        {/* owl.carousel */}
        {/* js count to */}
        {/* Fancybox */}
        {/* Theme js */}

      </div>

    </div>
  );
};

export default Roadmap;
