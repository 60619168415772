import { useState, useEffect, useRef } from "react";
import React, { Component } from "react";

import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Mint } from "../../components/mint";
import { Ooze } from "../../components/ooze";
import { Merge } from "../../components/merge";
import axios from 'axios';
import env from "react-dotenv";
import { Roadmap } from "../../components/roadmap";
import { Team } from "../../components/Team";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import "./index.css";
import { useMediaQuery } from 'react-responsive'
import { starData } from '../starregistry/registry';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FlyControls } from 'three/examples/jsm/controls/FlyControls';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare';
// import Flyout from '@aneves/react-flyout'
import * as TWEEN from "@tweenjs/tween.js";
import { Slider } from '@mui/material';
import { useCardano } from '@cardano-foundation/cardano-connect-with-wallet';
import { ConnectWalletList, ConnectWalletButton } from '@cardano-foundation/cardano-connect-with-wallet';
import { Modal } from 'react-responsive-modal';


// import { HeaderMobile } from "../../components/mobile/header";
// import { MintMobile } from "../../components/mobile/mint";
// import { RoadmapMobile } from "../../components/mobile/roadmap";
// import { OozeMobile } from "../../components/mobile/ooze";
// import { MergeMobile } from "../../components/mobile/merge";

import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const style = {
  height: '100%', // we can control scene size by setting container dimensions
  width: '100%'
};

let cameraPos = null
let movementSpeed = 2500

class ThreeView extends Component {
  componentDidMount() {

    this.sceneSetup();
    // this.addCustomSceneObjects();
    this.startAnimationLoop();
    // 
    // this.animate();

    window.addEventListener('resize', this.handleWindowResize);
    document.addEventListener('pointermove', this.onPointerMove);
    document.addEventListener('click', this.onPointerClick);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.cancelAnimationFrame(this.requestID);
    this.controls.dispose();
  }

  //  animate = () => {

  //   requestAnimationFrame( this.animate() );
  //   this.render();
  // }

  setupLights = () => {
    // lights
    // const dirLight = new THREE.DirectionalLight(0xffffff, 0.05);
    // dirLight.position.set(0, - 1, 0).normalize();
    // dirLight.color.setHSL(0.1, 0.7, 0.5);
    // this.scene.add(dirLight);

    const light = new THREE.AmbientLight(0xffffff);
    this.scene.add(light);


    // this.addLight(0.55, 0.9, 0.5, 5000, 0, - 1000);
    // this.addLight(0.08, 0.8, 0.5, 0, 0, - 1000);
    // this.addLight(0.995, 0.5, 0.9, 5000, 5000, - 1000);


  }

  addLight = (h, s, l, x, y, z) => {
    // lensflares
    const textureLoader = new THREE.TextureLoader();

    const textureFlare0 = textureLoader.load('images/elements/lensflare/lensflare0.png');
    const textureFlare3 = textureLoader.load('images/elements/lensflare/hexangle.png');

    const light = new THREE.PointLight(0xffffff, 0.2, 2000, 500);
    light.color.setHSL(h, s, l);
    light.position.set(x, y, z);
    this.scene.add(light);

    const lensflare = new Lensflare();
    lensflare.addElement(new LensflareElement(textureFlare0, 700 / 5, 0, light.color));
    lensflare.addElement(new LensflareElement(textureFlare3, 60 / 2, 0.6));
    lensflare.addElement(new LensflareElement(textureFlare3, 70 / 2, 0.7));
    // lensflare.addElement(new LensflareElement(textureFlare3, 120 / 2, 0.9));
    // lensflare.addElement(new LensflareElement(textureFlare3, 70 / 2, 1));
    light.add(lensflare);

  }

  onPointerMove = (event) => {
    if (!this.props.starModalOpen) {
      this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }
  }

  fitCameraToCenteredObject = (camera, object) => {

    // const val = starModalData ? JSON.parse(starModalData) : null
    // const str = val['Astrid #'];
    const num = object.name.match(/\d+/)[0];
    axios.get("https://api.adastrium.online/api/v1/astrids/getstar/" + num).then((res) => {
      try {
        this.props.setStarData(res.data)
      } catch (e) { }
    }).catch(() => {
    })


    const coords = { x: camera.position.x, y: camera.position.y, z: camera.position.z };
    this.props.setStarModalOpen(true)

    var targetPosition = new THREE.Vector3(object.scale.x + .1, object.scale.y + .1, object.scale.z + .1);
    // create animation
    // new TWEEN.Tween(object.scale)
    //   .to(targetPosition)
    //   .repeat(Infinity)
    //   .yoyo(true)
    //   .easing(TWEEN.Easing.Cubic.InOut)
    //   .start();

    this.pointer = new THREE.Vector2();

    this.camtween = new TWEEN.Tween(coords)
      .to({ x: object.position.x, y: object.position.y, z: object.position.z + 1000 }, 1500)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate((i, a) => {
        camera.position.set(coords.x, coords.y, coords.z)
        var startRotation = camera.quaternion.clone();
        const ve = new THREE.Vector3(object.position.x - 700, object.position.y, object.position.z)

        camera.lookAt(ve);
        var endRotation = camera.quaternion.clone();
        camera.quaternion.copy(this.getTween(startRotation, endRotation, a));

      })
      .onComplete(function () {

        var startRotation = camera.quaternion.clone();
        const ve = new THREE.Vector3(object.position.x - 600, object.position.y, object.position.z)

        // final rotation (with lookAt)
        camera.lookAt(ve);
        var endRotation = camera.quaternion.clone();

        // revert to original rotation
        camera.quaternion.copy(startRotation);

        // Tween
        new TWEEN.Tween(camera.quaternion)
          .easing(TWEEN.Easing.Quadratic.Out)
          .to(endRotation, 600)
          .start();

        this.INTERSECTED = null
      })
      .start();

  };

  rotateCameraToObject = () => {
    var startRotation = this.camera.quaternion.clone();
    this.camera.lookAt(this.selectedObject.position);
    var endRotation = this.camera.quaternion.clone();

    // revert to original rotation
    this.camera.quaternion.copy(startRotation);
    TWEEN.removeAll()
    function setModalClose(props) {
      props.setStarModalOpen(false)
    }
    // Tween
    new TWEEN.Tween(this.camera.quaternion)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(setModalClose(this.props))
      .to(endRotation, 600)
      .onComplete(function () {
      })
      .start();
  }

  getTween(b, e, i) {
    const w = b.w + ((i / 99) * (e.w - b.w))
    const x = b.x + ((i / 99) * (e.x - b.x))
    const y = b.y + ((i / 99) * (e.y - b.y))
    const z = b.z + ((i / 99) * (e.z - b.z))
    const quaternion = new THREE.Quaternion(x, y, z, w);
    return quaternion;
  }

  onPointerClick = (event) => {
    if (!this.props.starModalOpen) {
      if (this.INTERSECTED) {
        // console.log(this.INTERSECTED, selStar)
        this.selectedObject = this.INTERSECTED
        this.props.setStarModalData(this.INTERSECTED.userData)
        this.fitCameraToCenteredObject(this.camera, this.INTERSECTED)
      }
    }
  }

  // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
  // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
  sceneSetup = () => {


    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();
    this.PARTICLE_SIZE = 20;
    this.selectedObject = null;

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    // camera
    this.camera = new THREE.PerspectiveCamera(
      75, // fov = field of view
      width / height, // aspect ratio
      0.1, // near plane
      50000 // far plane
    );
    this.camera.position.z = 250;

    this.clock = new THREE.Clock();

    // scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color().setHSL(0.51, 0.4, 0.01);
    this.scene.fog = new THREE.FogExp2(0x000000, 0.0000000001);

    // world
    const s = 250;
    // const geometry = new THREE.BoxGeometry(s, s, s);
    // const material = new THREE.MeshPhongMaterial({ color: 0xffffff, specular: 0xffffff, shininess: 50 });
    const geometry = new THREE.BufferGeometry();
    const geometry2 = new THREE.BufferGeometry();

    const vertices = [];
    const vertices2 = [];
    const sizes = [];

    //Green
    const green_blade = new THREE.TextureLoader().load('images/elements/stars/green/blade.png');
    const green_fivepointed = new THREE.TextureLoader().load('images/elements/stars/green/fivepointed.png');
    const green_glass = new THREE.TextureLoader().load('images/elements/stars/green/glass.png');
    const green_icicle = new THREE.TextureLoader().load('images/elements/stars/green/icicle.png');
    const green_leaf = new THREE.TextureLoader().load('images/elements/stars/green/leaf.png');
    const green_ring = new THREE.TextureLoader().load('images/elements/stars/green/ring.png');
    const green_shuriken = new THREE.TextureLoader().load('images/elements/stars/green/shuriken.png');
    const green_snowflake = new THREE.TextureLoader().load('images/elements/stars/green/snowflake.png');
    const green_spike = new THREE.TextureLoader().load('images/elements/stars/green/spike.png');

    const red_blade = new THREE.TextureLoader().load('images/elements/stars/red/blade.png');
    const red_fivepointed = new THREE.TextureLoader().load('images/elements/stars/red/fivepointed.png');
    const red_glass = new THREE.TextureLoader().load('images/elements/stars/red/glass.png');
    const red_icicle = new THREE.TextureLoader().load('images/elements/stars/red/icicle.png');
    const red_leaf = new THREE.TextureLoader().load('images/elements/stars/red/leaf.png');
    const red_ring = new THREE.TextureLoader().load('images/elements/stars/red/ring.png');
    const red_shuriken = new THREE.TextureLoader().load('images/elements/stars/red/shuriken.png');
    const red_snowflake = new THREE.TextureLoader().load('images/elements/stars/red/snowflake.png');
    const red_spike = new THREE.TextureLoader().load('images/elements/stars/red/spike.png');

    const teal_blade = new THREE.TextureLoader().load('images/elements/stars/teal/blade.png');
    const teal_fivepointed = new THREE.TextureLoader().load('images/elements/stars/teal/fivepointed.png');
    const teal_glass = new THREE.TextureLoader().load('images/elements/stars/teal/glass.png');
    const teal_icicle = new THREE.TextureLoader().load('images/elements/stars/teal/icicle.png');
    const teal_leaf = new THREE.TextureLoader().load('images/elements/stars/teal/leaf.png');
    const teal_ring = new THREE.TextureLoader().load('images/elements/stars/teal/ring.png');
    const teal_shuriken = new THREE.TextureLoader().load('images/elements/stars/teal/shuriken.png');
    const teal_snowflake = new THREE.TextureLoader().load('images/elements/stars/teal/snowflake.png');
    const teal_spike = new THREE.TextureLoader().load('images/elements/stars/teal/spike.png');

    const white_blade = new THREE.TextureLoader().load('images/elements/stars/white/blade.png');
    const white_fivepointed = new THREE.TextureLoader().load('images/elements/stars/white/fivepointed.png');
    const white_glass = new THREE.TextureLoader().load('images/elements/stars/white/glass.png');
    const white_icicle = new THREE.TextureLoader().load('images/elements/stars/white/icicle.png');
    const white_leaf = new THREE.TextureLoader().load('images/elements/stars/white/leaf.png');
    const white_ring = new THREE.TextureLoader().load('images/elements/stars/white/ring.png');
    const white_shuriken = new THREE.TextureLoader().load('images/elements/stars/white/shuriken.png');
    const white_snowflake = new THREE.TextureLoader().load('images/elements/stars/white/snowflake.png');
    const white_spike = new THREE.TextureLoader().load('images/elements/stars/white/spike.png');

    const yellow_blade = new THREE.TextureLoader().load('images/elements/stars/yellow/blade.png');
    const yellow_fivepointed = new THREE.TextureLoader().load('images/elements/stars/yellow/fivepointed.png');
    const yellow_glass = new THREE.TextureLoader().load('images/elements/stars/yellow/glass.png');
    const yellow_icicle = new THREE.TextureLoader().load('images/elements/stars/yellow/icicle.png');
    const yellow_leaf = new THREE.TextureLoader().load('images/elements/stars/yellow/leaf.png');
    const yellow_ring = new THREE.TextureLoader().load('images/elements/stars/yellow/ring.png');
    const yellow_shuriken = new THREE.TextureLoader().load('images/elements/stars/yellow/shuriken.png');
    const yellow_snowflake = new THREE.TextureLoader().load('images/elements/stars/yellow/snowflake.png');
    const yellow_spike = new THREE.TextureLoader().load('images/elements/stars/yellow/spike.png');



    const sprite = new THREE.TextureLoader().load('images/elements/whitestar.png');
    const sprite2 = new THREE.TextureLoader().load('images/elements/starmapmain.png');

    // this.light = new THREE.DirectionalLight(0xffffff, 1);
    // this.light.position.set(1, 1, 1).normalize();
    // this.scene.add(this.light);

    this.geometry5 = new THREE.BoxGeometry(200, 200, 200);

    this.plane = new THREE.PlaneGeometry(500, 500);
    this.planeMaterial = new THREE.MeshBasicMaterial({ map: sprite, side: THREE.DoubleSide, transparent: true });

    this.group = new THREE.Group();
    this.scene.add(this.group);

    // console.log(this.planeMaterial)
    starData.map((star) => {
      // const mesh = new THREE.Mesh(this.plane, new THREE.MeshBasicMaterial({ map: sprite }));

      // const sprite1 = new THREE.Sprite(new THREE.SpriteMaterial({ map: sprite }));
      // sprite1.position.set(8000 * star.x, 8000 * star.y, 8000 * star.z);
      // // sprite1.center.set(0.5, 0);
      // sprite1.scale.set(500, 500, 500);
      // group.add(sprite1);
      let mesh = null
      if (star['Color In Star Map'] === "Green") {
        switch (star['Astral Crystal']) {
          case "Blade":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_blade, transparent: true, ambient: 0xffffff }));
            break;
          case "Five Pointed":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_fivepointed, transparent: true, ambient: 0xffffff }));
            break;
          case "Glass":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_glass, transparent: true, ambient: 0xffffff }));
            break;
          case "Icicle":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_icicle, transparent: true, ambient: 0xffffff }));
            break;
          case "Leaf":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_leaf, transparent: true, ambient: 0xffffff }));
            break;
          case "Ring":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_ring, transparent: true, ambient: 0xffffff }));
            break;
          case "Shuriken":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_shuriken, transparent: true, ambient: 0xffffff }));
            break;
          case "Snowflake":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_snowflake, transparent: true, ambient: 0xffffff }));
            break;
          case "Spike":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_spike, transparent: true, ambient: 0xffffff }));
            break;
          default:
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: green_spike, transparent: true, ambient: 0xffffff }));
        }
      } else if (star['Color In Star Map'] === "Red") {
        switch (star['Astral Crystal']) {
          case "Blade":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_blade, transparent: true, ambient: 0xffffff }));
            break;
          case "Five Pointed":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_fivepointed, transparent: true, ambient: 0xffffff }));
            break;
          case "Glass":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_glass, transparent: true, ambient: 0xffffff }));
            break;
          case "Icicle":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_icicle, transparent: true, ambient: 0xffffff }));
            break;
          case "Leaf":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_leaf, transparent: true, ambient: 0xffffff }));
            break;
          case "Ring":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_ring, transparent: true, ambient: 0xffffff }));
            break;
          case "Shuriken":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_shuriken, transparent: true, ambient: 0xffffff }));
            break;
          case "Snowflake":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_snowflake, transparent: true, ambient: 0xffffff }));
            break;
          case "Spike":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_spike, transparent: true, ambient: 0xffffff }));
            break;
          default:
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: red_spike, transparent: true, ambient: 0xffffff }));
        }
      } else if (star['Color In Star Map'] === "Teal") {
        switch (star['Astral Crystal']) {
          case "Blade":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_blade, transparent: true, ambient: 0xffffff }));
            break;
          case "Five Pointed":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_fivepointed, transparent: true, ambient: 0xffffff }));
            break;
          case "Glass":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_glass, transparent: true, ambient: 0xffffff }));
            break;
          case "Icicle":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_icicle, transparent: true, ambient: 0xffffff }));
            break;
          case "Leaf":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_leaf, transparent: true, ambient: 0xffffff }));
            break;
          case "Ring":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_ring, transparent: true, ambient: 0xffffff }));
            break;
          case "Shuriken":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_shuriken, transparent: true, ambient: 0xffffff }));
            break;
          case "Snowflake":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_snowflake, transparent: true, ambient: 0xffffff }));
            break;
          case "Spike":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_spike, transparent: true, ambient: 0xffffff }));
            break;
          default:
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: teal_spike, transparent: true, ambient: 0xffffff }));
        }
      } else if (star['Color In Star Map'] === "White") {
        switch (star['Astral Crystal']) {
          case "Blade":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_blade, transparent: true, ambient: 0xffffff }));
            break;
          case "Five Pointed":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_fivepointed, transparent: true, ambient: 0xffffff }));
            break;
          case "Glass":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_glass, transparent: true, ambient: 0xffffff }));
            break;
          case "Icicle":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_icicle, transparent: true, ambient: 0xffffff }));
            break;
          case "Leaf":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_leaf, transparent: true, ambient: 0xffffff }));
            break;
          case "Ring":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_ring, transparent: true, ambient: 0xffffff }));
            break;
          case "Shuriken":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_shuriken, transparent: true, ambient: 0xffffff }));
            break;
          case "Snowflake":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_snowflake, transparent: true, ambient: 0xffffff }));
            break;
          case "Spike":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_spike, transparent: true, ambient: 0xffffff }));
            break;
          default:
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: white_spike, transparent: true, ambient: 0xffffff }));
        }
      } else if (star['Color In Star Map'] === "Yellow") {
        switch (star['Astral Crystal']) {
          case "Blade":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_blade, transparent: true, ambient: 0xffffff }));
            break;
          case "Five Pointed":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_fivepointed, transparent: true, ambient: 0xffffff }));
            break;
          case "Glass":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_glass, transparent: true, ambient: 0xffffff }));
            break;
          case "Icicle":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_icicle, transparent: true, ambient: 0xffffff }));
            break;
          case "Leaf":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_leaf, transparent: true, ambient: 0xffffff }));
            break;
          case "Ring":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_ring, transparent: true, ambient: 0xffffff }));
            break;
          case "Shuriken":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_shuriken, transparent: true, ambient: 0xffffff }));
            break;
          case "Snowflake":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_snowflake, transparent: true, ambient: 0xffffff }));
            break;
          case "Spike":
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_spike, transparent: true, ambient: 0xffffff }));
            break;
          default:
            mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_spike, transparent: true, ambient: 0xffffff }));
        }
      } else {
        mesh = new THREE.Mesh(this.plane, new THREE.MeshLambertMaterial({ map: yellow_spike, transparent: true, ambient: 0xffffff }));
      }

      mesh.position.x = 8000 * star.x;
      mesh.position.y = 8000 * star.y;
      mesh.position.z = 8000 * star.z;
      mesh.doubleSided = true;
      const result = star['Astrid #'].replace(/\D/g, "");
      mesh.name = result
      mesh.userData = JSON.stringify(star)
      this.group.add(mesh);
      // mesh.lookAt( this.camera.position );
      mesh.quaternion.copy(this.camera.quaternion);

      if (star.mag > 15) {
        const h = Math.random() * (0.9 - 0.01) + 0.01;
        const s = Math.random() * (0.9 - 0.01) + 0.01;
        const l = Math.random() * (0.9 - 0.01) + 0.01;

        this.addLight(h, s, l, 8000 * star.x, 8000 * star.y, 8000 * star.z);
      }
      // if (star['Color Index'] < 1) {
      //   vertices.push(8000 * star.x, 8000 * star.y, 8000 * star.z);
      // } else {
      //   vertices2.push(8000 * star.x, 8000 * star.y, 8000 * star.z);
      // }
      // sizes.push(this.PARTICLE_SIZE * 0.5);


      const size = star['Color Index']

      mesh.scale.x = size;
      mesh.scale.y = size;
      mesh.scale.z = size;


      // mesh.rotation.x = Math.random() * Math.PI;
      // mesh.rotation.y = Math.random() * Math.PI;
      // mesh.rotation.z = Math.random() * Math.PI;

      mesh.matrixAutoUpdate = false;
      mesh.updateMatrix();

      // this.scene.add(mesh);
    })

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));

    const material = new THREE.PointsMaterial({ size: 1000, sizeAttenuation: true, map: sprite, alphaTest: 0.5, transparent: true });
    material.color.setHSL(1.0, 1.0, 1.0);
    this.particles = new THREE.Points(geometry, material);

    geometry2.setAttribute('position', new THREE.Float32BufferAttribute(vertices2, 3));
    const material2 = new THREE.PointsMaterial({ size: 1000, sizeAttenuation: true, map: sprite2, alphaTest: 0.5, transparent: true });
    material2.color.setHSL(1.0, 1.0, 1.0);
    this.particles2 = new THREE.Points(geometry2, material2);

    this.scene.add(this.particles, this.particles2);


    // const geometry3 = new THREE.SphereGeometry(1000,1000,1000);
    // const skytex = new THREE.TextureLoader().load("images/elements/sky.jpg");
    // const skymaterial = new THREE.MeshBasicMaterial( { map: skytex, side: THREE.DoubleSide } );
    // const backdrop = new THREE.Mesh( geometry3, skymaterial );

    // backdrop.scale.set (80, 80, 80) ;
    // backdrop.position.set (0,0,0);

    // this.scene.add( backdrop);


    // lights
    this.setupLights();

    // renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.container.appendChild( renderer.domElement );
    this.mount.appendChild(this.renderer.domElement); // mount using React ref

    const loader = new THREE.TextureLoader();
    const texture = loader.load(
      'https://res.cloudinary.com/duuldyiu5/image/upload/v1676039987/Adastra/loc00180_1_Space_Sky_4k_ycclf2.jpg',
      () => {
        const rt = new THREE.WebGLCubeRenderTarget(texture.image.height);
        rt.fromEquirectangularTexture(this.renderer, texture);
        this.scene.background = rt.texture;
      });

    this.controls = new FlyControls(this.camera, this.mount);
    // this.controls.keys = {
    //   LEFT: 'KeyA', //left arrow
    //   UP: 'KeyW', // up arrow
    //   RIGHT: 'KeyD', // right arrow
    //   BOTTOM: 'KeyS' // down arrow
    // }
    this.controls.movementSpeed = movementSpeed;
    this.controls.domElement = this.renderer.domElement;
    this.controls.rollSpeed = Math.PI / 3;
    this.controls.autoForward = false;
    this.controls.dragToLook = true;



    setTimeout(() => {
      let astridId = this.props.astridId
      if (astridId) {
        let selStar = this.scene.getObjectByName(astridId, true);
        if (selStar) {
          this.selectedObject = selStar
          this.props.setStarModalData(selStar.userData)
          this.fitCameraToCenteredObject(this.camera, selStar)
        }
        return
      }
    }, 3000);

  };

  toScreenPosition(obj, camera) {
    var vector = new THREE.Vector3();

    var widthHalf = 0.5 * window.innerWidth;
    var heightHalf = 0.5 * window.innerHeight;

    obj.updateMatrixWorld();
    vector.setFromMatrixPosition(obj.matrixWorld);
    vector.project(camera);

    vector.x = (vector.x * widthHalf) + widthHalf;
    vector.y = - (vector.y * heightHalf) + heightHalf;

    return {
      x: vector.x,
      y: vector.y
    };

  };

  startAnimationLoop = (time) => {

    TWEEN.update(time);

    this.group.children.map((mesh, index) => {
      mesh.lookAt(this.camera.position);
      mesh.updateMatrix();
    })
    const geometry = this.particles.geometry;
    const attributes = geometry.attributes;


    this.raycaster.setFromCamera(this.pointer, this.camera);

    this.intersects = !this.props.starModalOpen ? this.raycaster.intersectObject(this.group, true) : []

    if (this.intersects.length > 0) {

      if (this.INTERSECTED != this.intersects[0].object) {

        if (this.INTERSECTED) {
          // this.INTERSECTED.material.emissive.setHex(this.INTERSECTED.currentHex)
          // this.INTERSECTED.scale.x = this.INTERSECTED.currentScale
          // this.INTERSECTED.scale.y = this.INTERSECTED.currentScale
          // this.INTERSECTED.scale.z = this.IN

          var targetPosition = new THREE.Vector3(this.INTERSECTED.currentScale, this.INTERSECTED.currentScale, this.INTERSECTED.currentScale);
          new TWEEN.Tween(this.INTERSECTED.scale)
            .to(targetPosition, 200)
            // .repeat(Infinity)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
        };

        this.INTERSECTED = this.intersects[0].object;
        // this.INTERSECTED.currentHex = this.INTERSECTED.material.emissive.getHex();
        // this.INTERSECTED.material.emissive.setHex(0xff0000);
        this.INTERSECTED.currentScale = this.INTERSECTED.scale.x
        // this.INTERSECTED.scale.x = this.INTERSECTED.scale.x + 0.4
        // this.INTERSECTED.scale.y = this.INTERSECTED.scale.y + 0.4
        // this.INTERSECTED.scale.z = this.INTERSECTED.scale.z + 0.4

        var targetPosition = new THREE.Vector3(this.INTERSECTED.scale.x + 0.4, this.INTERSECTED.scale.y + 0.4, this.INTERSECTED.scale.z + 0.4);

        // create animation

        new TWEEN.Tween(this.INTERSECTED.scale)
          .to(targetPosition, 200)
          // .repeat(Infinity)
          .easing(TWEEN.Easing.Quadratic.Out)
          .start();

      }
    } else {
      if (this.INTERSECTED) {
        // this.INTERSECTED.material.emissive.setHex(this.INTERSECTED.currentHex)

        // this.INTERSECTED.scale.x = this.INTERSECTED.currentScale
        // this.INTERSECTED.scale.y = this.INTERSECTED.currentScale
        // this.INTERSECTED.scale.z = this.INTERSECTED.currentScale

        var targetPosition = new THREE.Vector3(this.INTERSECTED.currentScale, this.INTERSECTED.currentScale, this.INTERSECTED.currentScale);
        new TWEEN.Tween(this.INTERSECTED.scale)
          .to(targetPosition, 200)
          // .repeat(Infinity)
          .easing(TWEEN.Easing.Quadratic.Out)
          .start();
      };
      this.INTERSECTED = null;
    }

    if (!this.props.starModalOpen) {
      if (this.INTERSECTED) {
        this.props.setStarPopUpOpen(true)
        const val = this.toScreenPosition(this.INTERSECTED, this.camera)
        let circle = document.getElementById('circle');
        let left = val.x;
        let top = val.y - 10;
        circle.style.left = left + 'px';
        circle.style.top = top + 'px'
        this.props.setStarPopUpData(this.INTERSECTED.userData)

      } else {
        this.props.setStarPopUpOpen(false)
        this.props.setStarPopUpData(null)
      }
    }

    const delta = this.clock.getDelta();
    if (!this.props.starModalOpen) {
      this.controls.update(delta);
    }
    this.renderer.render(this.scene, this.camera);
    cameraPos = [this.camera.position.x, this.camera.position.y, this.camera.position.z]

    // The window.requestAnimationFrame() method tells the browser that you wish to perform
    // an animation and requests that the browser call a specified function
    // to update an animation before the next repaint
    this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
  };

  handleWindowResize = () => {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;

    // Note that after making changes to most of camera properties you have to call
    // .updateProjectionMatrix for the changes to take effect.
    this.camera.updateProjectionMatrix();

  };

  render() {
    return <div style={style} ref={ref => (this.mount = ref)} />;
  }
}


const Demo = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [openNav, setOpenNav] = useState(false)
  const [modalId, setModalId] = useState(0)

  const [starModalOpen, setStarModalOpen] = useState(false)
  const [starModalData, setStarModalData] = useState(null)

  const [starPopUpOpen, setStarPopUpOpen] = useState(false)
  const [starPopUpData, setStarPopUpData] = useState(null)

  const [posArray, setPosArray] = useState(null)
  const [astridId, setAstridId] = useState(null)

  const [starData, setStarData] = useState(null);
  const [open, setOpen] = useState(false);

  const [movementSpeed, setMovementSpeed] = useState(2500)

  const {
    isEnabled,
    isConnected,
    enabledWallet,
    accountBalance,
    stakeAddress,
    signMessage,
    connect,
    disconnect
  } = useCardano();

  const onConnect = async () => {
    // alert('Successfully connected!')
    // const cardano = await CardanoSerializationLib();

    // const walletContent = await cardano.wallet.getContent('stake1uyyqy78c6lpa0fdzj2wnar6vz3322fnzr3t8e6z0eza7aush6p3su');

    // console.log('walletContent', walletContent);
    NotificationManager.success('Wallet connected!', '', 5000);
    setTimeout(localStorage.setItem('userId', stakeAddress), 1000);
  };

  const threeRef = useRef();

  useEffect(async () => {
    setLandingPageData(JsonData);

    // get the URL string
    const url = window.location.href;

    // extract the query string from the URL
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    const astridId = params.get("astridId");
    setAstridId(astridId)

  }, []);

  const { t, i18n } = useTranslation(['common']);

  function toggleMenu() {
    let d;
    let c;
    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }

  const ProfileFlyOut = () => {
    return (
      <div className="w-100 row" style={{ position: 'absolute', pointerEvents: 'none' }}>
        <div className="col-md-4 wow fadeInUp animated" style={{ marginLeft: 80, marginTop: 80 }}>
          <div style={{ border: '3px solid white', borderRadius: 10, backgroundColor: '#00000091' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', padding: 10 }}>
              <img style={{
                minWidth: '5rem',
                maxWidth: '5rem'
              }} src="images/elements/alogo.png" alt="Logo" />
              <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                Old Man Hands </h5>
            </div>
            <div style={{
              width: '100%',
              height: '3px',
              backgroundColor: 'white'
            }}></div>
            <div style={{ padding: 10, color: 'white' }}>
              <div style={{ padding: 10 }}>
                Role: Star Whale <br></br>
                Stars: 23
              </div>
              <a href="/dashboard" className=" hvr-trim-two p-bg-color button-one-outline-isolated" style={{ margin: 5, textTransform: 'uppercase' }}>DASHBOARD</a>

            </div>
            <div style={{
              width: '100%',
              height: '3px',
              backgroundColor: 'white'
            }}></div>
            <div style={{ padding: 10, color: 'white' }}>
              <div style={{ padding: 10 }}>
                Favorite <br></br>
                Star: 1 <br></br>
                Star: 2 <br></br>
                Star: 3 <br></br>
                Star: 4 <br></br>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  const StarIDFlyOut = () => {
    return (
      <div className="w-100 row" style={{zIndex: 200,  position: 'absolute', pointerEvents: 'none' }}>
        <div className="col-md-4 wow fadeInUp animated" style={{ marginLeft: 80, marginTop: 80 }}>
          <div style={{ border: '3px solid white', borderRadius: 10, backgroundColor: '#00000091' }}>
            <div style={{ padding: 10, color: 'white' }}>
              <div style={{ padding: 10 }}>
                Star ID <br></br>
                <input
                  style={{
                    zIndex: 200,
                    'font-size': '20px',
                    'border-radius': '1rem',
                    'border-color': 'white',
                    color: 'white',
                    'border-width': '1px',
                    'padding': '1rem',
                    'background-color': 'transparent',
                    'border-style': 'solid',
                    width: '100%',
                    // marginBottom: 10
                  }}
                  placeholder="Input name"
                  // value={name}
                  onChange={(e) => {
                    // setName(e.target.value)
                    // refreshData(e.target.value)
                  }}

                  type="text"
                  name="Sort Store"
                />

              </div>
            </div>
            <div style={{
              width: '100%',
              height: '3px',
              backgroundColor: 'white'
            }}></div>
            <div style={{ padding: 10, color: 'white' }}>
              <div style={{ padding: 10 }}>
                Coordinates <br></br>
                x: 00000 <br></br>
                y: 00000 <br></br>
                z: 00000 <br></br>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  const SettingsFlyOut = () => {
    return (
      <div className="w-100 row" style={{ position: 'absolute' }}>
        <div className="col-md-4" style={{ marginLeft: 80, marginTop: 80 }}>
          <div style={{ border: '3px solid white', borderRadius: 10, backgroundColor: '#00000091' }}>
            <div style={{ padding: 10, color: 'white' }}>
              <div style={{ padding: 10 }}>
                Revolution Speed <br></br>
                <Slider
                  size="medium"
                  defaultValue={70}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  color="primary"
                />
                Rotation Speed <br></br>
                <Slider
                  size="medium"
                  defaultValue={70}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  color="primary"
                />
                Glow Strength <br></br>
                <Slider
                  size="medium"
                  min={2500}
                  max={10000}
                  // value={movementSpeed}
                  onChange={(val) => {
                    console.log(val.target.value)
                    setMovementSpeed(val.target.value)
                    // movementSpeed = val.target.value
                  }}
                  defaultValue={2500}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  color="primary"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  const ButtomStats = () => {
    // console.log(cameraPos && cameraPos.toString())
    return (
      <>
        <div className="w-100 row" style={{ position: 'absolute', bottom: 0, left: 0, pointerEvents: 'none' }}>
          <div className="col-md-12 w-100" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="row w-100" style={{ backgroundColor: '#00000091', display: 'flex' }}>
              <div className="col-lg-4" style={{ padding: 10, color: 'white', fontSize: 12, userSelect: 'none' }}>
                x: {cameraPos && cameraPos[0]} y: {cameraPos && cameraPos[1]} z: {cameraPos && cameraPos[2]}
              </div>
              <div className="col-lg-4" style={{ padding: 10, color: 'white', fontSize: 12, userSelect: 'none', textAlign: 'center' }}>
                Use the W,A,S,D keys to move. Click and drag the mouse to look around.
              </div>
              <div className="col-lg-4">

              </div>
            </div>

          </div>
        </div>
      </>

    )
  }


  const handleFlyOutClose = () => {
    setModalId(0)
    setStarModalOpen(false)
    threeRef.current.rotateCameraToObject()

  }

  const StarFlyOut = () => {
    const val = starModalData ? JSON.parse(starModalData) : null
    const str = val['Astrid #'];
    const num = str.match(/\d+/)[0];
    const link = "https://api.adastrium.online/api/v1/astrids/" + num

    let showDashboard = false
    if (starData) {
      if (stakeAddress === starData.owner) {
        showDashboard = true
      }
    }
    return (
      <div className="w-100 h-100 row" style={{ position: 'absolute' }}>
        <div className="col-md-6 wow fadeIn animated" style={{ justifyContent: 'center', marginLeft: 80, display: 'flex', alignItems: 'center' }}>
          <div className="scrollable-div" style={{ maxWidth: 1200, border: '3px solid white', borderRadius: 10, backgroundColor: '#00000091' }}>
            <div style={{ padding: 5 }}>
              <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', padding: 10 }}>
                {starData ?
                  <h5 className="wow fadeInUp animated" style={{ fontSize: 30, fontWeight: 'bold', "margin-bottom": "5px", color: 'white' }}>
                    {starData.name !== "" ? starData.name : val['Astrid #']}</h5>
                  :
                  <h5 className="wow fadeInUp animated" style={{ fontSize: 30, fontWeight: 'bold', "margin-bottom": "5px", color: 'white' }}>
                    {val && val['Astrid #']}</h5>
                }

                <a onClick={() => handleFlyOutClose()}><img style={{
                  minWidth: '3rem',
                  maxWidth: '3rem',
                  cursor: 'pointer'
                }} src="images/elements/52-521935_close-button-png.png" alt="Logo" /></a>

              </div>
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-6 wow fadeInUp animated" style={{ marginLeft: 0, marginTop: 5, marginBottom: 5 }}>
                  <div style={{ padding: 10, color: 'white' }}>
                    Astrid Star {val && val['ASTRID #']} <br></br>
                    HYG {val && val['HYG Star ID']}<br></br>
                    Gliese Catalog {val && val['Hipparcos Catalogue']}<br></br>
                    Proper Name {val && val['Proper Name']}<br></br>
                  </div>
                </div>
                <div className="col-md-12  col-lg-12 col-xl-6 wow fadeInUp animated" style={{ marginLeft: 0, marginTop: 5, marginBottom: 5 }}>
                  <div style={{ padding: 10, color: 'white' }}>
                    Coordinates <br></br>
                    x: {val && val['x']}<br></br>
                    y: {val && val['y']}<br></br>
                    z: {val && val['z']}<br></br>
                  </div>
                </div>
              </div>
              {starData &&
                <>
                  {starData.description !== "" &&
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-xl-12 wow fadeInUp animated" style={{ marginLeft: 0, marginTop: 5, marginBottom: 5 }}>

                        <div style={{ padding: 10, color: 'white' }}>
                          {starData.description}
                        </div>
                      </div>
                    </div>
                  }
                </>
              }
            </div>

            <div style={{ padding: 5 }}>
              <div className="row">
                <div className="col-lg-12 wow fadeInUp animated" style={{ paddingRight: 0, paddingLeft: 0, marginTop: 0, marginBottom: 5 }}>
                  <div className="image astridCard" style={{ padding: 3 }}>
                    <img src={link} alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                {showDashboard &&
                  <div style={{ paddingLeft: 20, paddingRight: 20, color: 'white', width: '100%' }}>
                    <a href="/dashboard" className="hvr-trim-two p-bg-color button-one-outline-isolated" style={{ textTransform: 'uppercase', marginBottom: 15 }}>VIEW IN DASHBOARD</a>
                  </div>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  const StarPopOut = () => {
    const val = JSON.parse(starPopUpData)
    return (
      <div className="timeline-article" id="circle" style={{ pointerEvents: 'none', position: 'absolute', margin: 0 }}>
        <div className="content-left-container fadeIn animated" style={{ maxWidth: 'none', width: 'auto' }} >
          <div className="content-left" style={{ padding: 0, marginTop: 0, backgroundColor: 'transparent', border: 'none' }}>
            <div>
              <div>
                <div style={{ marginLeft: -10, marginTop: 10, background: 'white' }} className="meta-date-left"></div>
                <div style={{ marginTop: 0, background: 'white', height: 2, width: 40, transform: 'translateY(10px)' }} className="conference-center-line-horizontal-left"></div>

              </div>
            </div>
            <div style={{ border: '3px solid white', borderRadius: 10, backgroundColor: '#00000091', padding: 10, paddingLeft: 20, paddingRight: 20, transform: 'translateX(40px)' }}>
              <h5 style={{ color: 'white', marginBottom: 0 }}>{val && val['CUSTOM NAME']}</h5>
              <p style={{ color: 'white' }}>{val && val['Astrid #']}</p>
            </div>
          </div>
        </div>
      </div>

    )
  }
  const processAddressId = () => {
    if (!stakeAddress) {
      return "Click here to connect wallet"
    }
    let str = stakeAddress ? stakeAddress : "";
    let newStr = str.replace(/^stake1/, "");
    let firstThree = newStr.substring(0, 3);
    let lastThree = newStr.substring(newStr.length - 4);
    let masked = "****";
    return firstThree + masked + lastThree;

  }
  return (
    <>
      <div className='intro'>
        <NotificationContainer />

        <div className="main-page-wrapper">
          {/* ===================================================
				Loading Transition
		  	==================================================== */}
          <div id="loader-wrapper">
            <div id="loader">
              <ul>
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
          {/* 
        =============================================
          Theme Header
        ============================================== 
        */}
          {/* <div className="background-alert" style={{ height: 30, width: '100%' }}>
          <p className=" fadeInUp animated" data-wow-delay="0.2s" style={{ "-webkit-text-transform": "uppercase", "text-transform": "uppercase", 'text-align': 'center', 'color': 'white' }}>
            {t('alert')}</p>
        </div> */}
          <header style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }} className="theme-menu-wrapper full-width-menu">

            {/* Logo */}
            <div className="float-left tran4s" style={{ "display": "flex", maxWidth: '150px', margin: 15, transform: 'translateY(-5px)' }}>
              <div >
                <a href="/"><img style={{
                  minWidth: '5rem',
                  maxWidth: '5rem'
                }} src="images/elements/alogo.png" alt="Logo" /></a>
                <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', gap: '1rem', marginTop: 20, marginLeft: 10 }}>
                  <a onClick={() => {

                    if (modalId === 0) {
                      setModalId(1)
                      if (starModalOpen) {
                        setStarModalOpen(false)
                        threeRef.current.rotateCameraToObject()
                      }
                    } else {
                      setModalId(0)
                    }
                  }} data-flyout-id="flyout-example"><img style={{
                    minWidth: '3rem',
                    maxWidth: '3rem',
                    cursor: 'pointer',

                  }} src="images/elements/searchIcon.png" alt="Logo" /></a>
                  <a onClick={() => {
                    if (modalId === 0) {
                      setModalId(2)
                      if (starModalOpen) {
                        setStarModalOpen(false)
                        threeRef.current.rotateCameraToObject()
                      }
                    } else {
                      setModalId(0)
                    }
                  }}><img style={{
                    minWidth: '3rem',
                    maxWidth: '3rem',
                    cursor: 'pointer'
                  }} src="images/elements/profileIcon.png" alt="Logo" /></a>
                  <a onClick={() => {
                    if (modalId === 0) {
                      setModalId(3)
                      if (starModalOpen) {
                        setStarModalOpen(false)
                        threeRef.current.rotateCameraToObject()
                      }
                    } else {
                      setModalId(0)
                    }
                  }} ><img style={{
                    minWidth: '3rem',
                    maxWidth: '3rem',
                    cursor: 'pointer'
                  }} src="images/elements/gearIcon.png" alt="Logo" /></a>
                </div>

              </div>

            </div>


            <div className="float-left tran4s" style={{ "display": "flex", alignItems: 'center' }}>
              {/* <ConnectWalletList
              borderRadius={15}
              gap={12}
              primaryColor="#0538AF"
              onConnect={() => alert('seseg')}
                      customCSS={`
                font-family: Helvetica Light,sans-serif;
                font-size: 0.875rem;
                font-weight: 700;
                width: 164px;
                & > span { padding: 5px 16px; }
            `}
            /> */}


              <ConnectWalletButton
                // onSignMessage={() => alert('segseg')}
                onConnect={onConnect}
                customCSS={`
              font-family: Helvetica Light,sans-serif;
              font-size: 0.875rem;
              font-weight: 700;
              width: 164px;
              & > span { padding: 5px 16px; }
          `}
              />
              { }
              {/* <h5 onClick={() => {
              console.log('segsege')
              connect('wallet_name_with_cip30_support', () => {
                alert('sienihseiseg')
              })
            }} className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", marginRight: 10, color: 'white' }}>
              {processAddressId()}</h5> */}
              <li className="menu-list" style={{ marginLeft: 10, listStyle: 'none', marginRight: '9px' }}><a className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '4rem', maxWidth: '4rem' }} src={'images/elements/accountIcon.png'} /></a></li>
              {/* <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/twitter.png'} /></a></li>
            <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/discord.png'} /></a></li> */}
            </div>
          </header> {/* /.theme-menu-wrapper */}




          <div className="about-bg-demo" id="mint">
            {starModalOpen ?
              <StarFlyOut />
              :
              <>
                {starPopUpOpen &&
                  <StarPopOut />
                }
              </>
            }
            {modalId === 1 &&
              <StarIDFlyOut />
            }
            {modalId === 2 &&
              <ProfileFlyOut />
            }
            {modalId === 3 &&
              <SettingsFlyOut />
            }
            <ButtomStats />
            <audio id="myaudio" controls loop={true} autostart={true} style={{ position: 'absolute', right: 0, bottom: 0, margin: 0 }} >
              <source src="https://res.cloudinary.com/duuldyiu5/video/upload/v1676044226/Adastra/AD_ASTRIUM_SOUNDSCAPE_BY_RYU_TEMPLES_vxszdi.mp3" type="audio/mp3" />
              <p></p>
            </audio>
            {/* <div style={{ pointerEvents: starModalOpen ? 'none' : 'all' }}> */}
            <ThreeView
              ref={threeRef}
              posArray={posArray}
              astridId={astridId}
              starData={starData}
              setStarData={setStarData}
              setStarPopUpOpen={setStarPopUpOpen}
              starModalOpen={starModalOpen}
              setStarModalOpen={setStarModalOpen}
              setStarPopUpData={setStarPopUpData}
              setStarModalData={setStarModalData}
              movementSpeed={movementSpeed}
            />
            {/* </div> */}



          </div>


          {/* 
			=============================================
				Footer
			============================================== 
			*/}

          {/* Sign-Up Modal */}
          <div className="modal fade signUpModal theme-modal-box" role="dialog">
            <div className="modal-dialog">
              {/* Modal content*/}
              <div className="modal-content">
                <div className="modal-body">
                  <h3 style={{ color: 'white' }}>Login with Social Networks</h3>
                  <ul style={{ color: 'white' }} className="clearfix">
                    <li className="float-left"><a href="#"><i className="fa fa-facebook" aria-hidden="true" />
                      facebook</a></li>
                    <li className="float-left"><a href="#"><i className="fa fa-google-plus" aria-hidden="true" />
                      Google</a></li>
                    <li className="float-left"><a href="#"><i className="fa fa-twitter" aria-hidden="true" />
                      Twitter</a></li>
                    <li className="float-left"><a href="#"><i className="fa fa-linkedin" aria-hidden="true" />
                      Linkedin</a></li>
                  </ul>
                  <form action="#">
                    <h6><span>or</span></h6>
                    <div className="wrapper">
                      <input type="text" placeholder="Username or Email" />
                      <input type="password" placeholder="Password" />
                      <ul className="clearfix">
                        <li className="float-left">
                          <input type="checkbox" id="remember" />
                          <label htmlFor="remember">Remember Me</label>
                        </li>
                        <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                      </ul>
                      <button className="p-bg-color hvr-trim-two">Login</button>
                    </div>
                  </form>
                </div> {/* /.modal-body */}
              </div> {/* /.modal-content */}
            </div> {/* /.modal-dialog */}
          </div> {/* /.signUpModal */}
          {/* Scroll Top Button */}
          <button className="scroll-top tran3s">
            <i className="fa fa-angle-up" aria-hidden="true" />
          </button>
          <div id="svag-shape">
            <svg height={0} width={0}>
              <defs>
                <clipPath id="shape-one">
                  <path fillRule="evenodd" fill="rgb(168, 168, 168)" d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
          {/* Js File_________________________________ */}
          {/* j Query */}
          {/* Bootstrap Select JS */}
          {/* Bootstrap JS */}
          {/* Vendor js _________ */}
          {/* Camera Slider */}
          {/* Mega menu  */}
          {/* WOW js */}
          {/* owl.carousel */}
          {/* js count to */}
          {/* Fancybox */}
          {/* Theme js */}

        </div>

      </div>
    </>

  );
};

export default Demo;
