export const nfts = [

      {
        "image": "https://res.cloudinary.com/duuldyiu5/image/upload/v1675059355/Adastra/astrid_1_v4qaqq.png",
        "Astrid #": "Astrid ##",
        "Custom Name": "THIS IS THE CUSTOM NA",
        "Spectral Type": "PLACEHOLDER",
        "HYG Star ID": 119507,
        "favored": "",
      },
      {
        "image": "https://res.cloudinary.com/duuldyiu5/image/upload/v1675059351/Adastra/astrid_7_icnjfb.png",
        "Astrid #": "Astrid ##",
        "Custom Name": "THIS IS THE CUSTOM NA",
        "Spectral Type": "PLACEHOLDER",
        "HYG Star ID": 119507,
        "favored": "",
      },
      {
        "image": "https://res.cloudinary.com/duuldyiu5/image/upload/v1675059347/Adastra/astrid_9_fdr87v.png",
        "Astrid #": "Astrid ##",
        "Custom Name": "THIS IS THE CUSTOM NA",
        "Spectral Type": "PLACEHOLDER",
        "HYG Star ID": 119507,
        "favored": "",
      },
      {
        "image": "https://res.cloudinary.com/duuldyiu5/image/upload/v1675059348/Adastra/astrid_5_phdmph.png",
        "Astrid #": "Astrid ##",
        "Custom Name": "THIS IS THE CUSTOM NA",
        "Spectral Type": "PLACEHOLDER",
        "HYG Star ID": 119507,
        "favored": "",
      },
]