import { useState, useEffect } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useTranslation } from 'react-i18next';
import env from "react-dotenv";

const Web3 = require('web3')


export const Navigation = (props) => {
  const [metamaskId, setMetamaskId] = useState(null)
  const [id, setId] = useState(null)
  const [wrongNetwork, setWrongNetwork] = useState(false)
  const [showMetamask, setShowMetamask] = useState(false)
  const [openNav, setOpenNav] = useState(false)

  function trans(val) {
    i18n.changeLanguage(val)
  }
  const { t, i18n } = useTranslation(['common']);

  useEffect(async () => {
    try {
      if (window.location.pathname == '/ooze') {
        setShowMetamask(true)

        checkChain(window.ethereum.chainId)

        window.ethereum.on('chainChanged', chainId => {
          checkChain(chainId)
        })
        window.ethereum.on('disconnect', (code, reason) => {
          setId(null)
        })

        window.ethereum.on('accountsChanged', function (accounts) {
          const account = accounts[0];
          setId(account)
        })

      }
    } catch (e) { }
  }, [])

  const checkChain = async (chainId) => {
    if (chainId.toString() != env.SMARTCHAINID) {
      setWrongNetwork(true)
      NotificationManager.error(`Please select ` + env.SMARTCHAINNAME + ` chain.`, '', 5000);
    } else {
      setWrongNetwork(false)
      let walletAddress = window.ethereum.selectedAddress
      if (walletAddress) {
        setId(walletAddress)
      }
    }
  }

  const connectWallet = async () => {
    // Check if MetaMask is installed on user's browser
    if (window.ethereum) {

      window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
        const account = accounts[0];
        setId(account)

      }).catch((e) => {
        NotificationManager.error('please connect your metamask or wallet provider to continue.', '', 5000);
      });

      await window.ethereum.enable()

    } else if (window.web3) {
      // Show alert if Ethereum provider is not detected
      NotificationManager.error('You do not have ethereum wallet installed, please install metamask and refresh this page.', '', 5000);
    }
  }


  function toggleMenu() {
    let d;
    let c;

    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }


  function truncate(str, n) {
    if (str.length <= n) return str; // Nothing to do
    if (n <= 1) return "…"; // Well... not much else we can return here!
    let dot = str.lastIndexOf("."); // Where the extension starts
    // How many characters from the end should remain:
    let after = dot < 0 ? 1 : Math.max(1, Math.min(n - 2, str.length - dot + 2));
    // How many characters from the start should remain:
    let before = n - after - 1; // Account for the ellipsis
    return str.slice(0, before) + "…" + str.slice(-after);
  }

  return (

    <header className="theme-menu-wrapper full-width-menu">
      <div className="header-wrapper">
        <div className="clearfix">
          {/* Logo */}
          <div className="logo float-left tran4s" style={{ "display": "flex", "-webkit-transform": "translateY(-14px)", "-ms-transform": "translateY(-14px)", "transform": "translateY(-14px)" }}>
            <a href='/'><img src="images/logo/logo.png" alt="Logo" /></a>
            {/* <ul className="nav" style={{ "display": "flex" }}>
                  <li className="menu-list"><a target="_blank" href="https://discord.gg/cFrHCEEvFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0px -10px 0px 20px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /></a></li>
                  <li className="menu-list"><a target="_blank" href="https://opensea.io/collection/goopyslimes-genesis" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}><img style={{ height: '30px' }} src={'img/icons/opensea.png'} /></a></li>
                  <li className="menu-list"><a target="_blank" href="https://twitter.com/GoopySlimes" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} /></a></li>
                </ul> */}
          </div>

          {/* ============================ Theme Menu ========================= */}
          <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper">
            {/* Brand and toggle get grouped for better mobile display */}
            <div className="navbar-header">
              <button onClick={() => toggleMenu()} type="button" className="navbar-toggle collapsed" >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbar-collapse-1">
              <ul className="nav">
                <li className=" menu-list active"><a href="#mint" className="tran3s" style={{ "font-size": "15px" }}>{t('mint')}</a>
                </li>
                <li className=" menu-list"><a href="#ooze" className="tran3s" style={{ "font-size": "15px" }}>{t('ooze')}</a>
                </li>
                <li className=" menu-list"><a href="#merge" className="tran3s" style={{ "font-size": "15px" }}>{t('merge')}</a>
                </li>
                <li className=" menu-list"><a href="#roadmap" className="tran3s" style={{ "font-size": "15px" }}>{t('roadmap')}</a>
                </li>
                <li className="menu-list"><a href="#team" className="tran3s" style={{ "font-size": "15px" }}>{t('team')}</a></li>

                <ul className="nav" style={{ "display": "flex", "margin-top": "5" }}>
                  <li className="menu-list"><a onClick={() => trans('en')} className="tran3s" style={{ "cursor": "pointer", "font-size": "15px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}>ENG</a></li>
                  <li className="menu-list"><a onClick={() => trans('ch')} className="tran3s" style={{ "cursor": "pointer", "font-size": "15px", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}>简</a></li>
                  <li className="menu-list"><a onClick={() => trans('ch_trad')} className="tran3s" style={{ "cursor": "pointer", "font-size": "15px", "margin-left": "15", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}>繁</a></li>

                </ul>

                {/* <li className="menu-list"><a href="https://discord.gg/GoopySlimes" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 14px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /> DISCORD</a></li>
                    <li className="menu-list"><a href="https://twitter.com/GoopySlimes" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 14px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "green" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} />TWITTER</a></li> */}
              </ul>

            </div>{/* /.navbar-collapse */}
          </nav> {/* /.theme-main-menu */}
        </div> {/* /.clearfix */}
      </div>
    </header>

  )
}
