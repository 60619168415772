import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Mint } from "./components/mint";
// import { Ooze } from "./components/ooze";
import { Merge } from "./components/merge";

import { Services } from "./components/services";
// import { Roadmap } from "./components/roadmap";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
// import routes from "./router/route-list";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import history from "./helpers/history/history";

import Home from "./containers/home/App"
// import Dashboard from "./containers/dashboard/dashboard"
// import Ooze from "./containers/ooze/dashboard"
// import Staging from "./containers/staging/App"
import About from "./containers/about"
import Team from "./containers/team"
import NewToNFT from "./containers/newtonft"
import Whitepaper from "./containers/whitepaper"

import Astrids from "./containers/astrids"
import Roadmap from "./containers/roadmap"
import StarRegistry from "./containers/starregistry"
import Demo from "./containers/demo"
import Dashboard from "./containers/dashboard"

import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  
  // function selectLanguage  () {
    const { t, i18n } = useTranslation(['common']);
  // }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/team",
      element: <Team />,
    },
    {
      path: "/new-to-nfts",
      element: <NewToNFT />,
    },
    {
      path: "/whitepaper",
      element: <Whitepaper />,
    },
    {
      path: "/starregistry",
      element: <StarRegistry />,
    },
    {
      path: "/roadmap",
      element: <Roadmap />,
    },
    {
      path: "/demo",
      element: <Demo />,
    },
    {
      path: "/astrids",
      element: <Astrids />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    }
  ]);

  return (
    <RouterProvider router={router} />

    // <Router history={history}>
    //   <Switch>
    //     <Route exact path="/">
    //       <Home translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/about">
    //       <About translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/about">
    //       <About translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/team">
    //       <Team translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/new-to-nfts">
    //       <NewToNFT translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/whitepaper">
    //       <Whitepaper translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/starregistry">
    //       <StarRegistry translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/roadmap">
    //       <Roadmap translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/demo">
    //       <Demo translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/astrids">
    //       <Astrids translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/dashboard">
    //       <Dashboard translation={t} i18n={i18n}/>
    //     </Route>
    //     <Route exact path="/mint">
    //       <Dashboard />
    //     </Route>
    //     <Route exact path="/ooze">
    //       <Ooze />
    //     </Route>
    //     <Route exact path="/staging">
    //       <Staging />
    //     </Route>
    //   </Switch>
    // </Router>
  );
};

export default App;
