import { useState, useEffect } from "react";
import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Mint } from "../../components/mint";
import { Ooze } from "../../components/ooze";
import { Merge } from "../../components/merge";
import axios from 'axios';
import env from "react-dotenv";
import { Roadmap } from "../../components/roadmap";
import { Team } from "../../components/Team";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import "./index.css";
import { useMediaQuery } from 'react-responsive'

// import { HeaderMobile } from "../../components/mobile/header";
// import { MintMobile } from "../../components/mobile/mint";
// import { RoadmapMobile } from "../../components/mobile/roadmap";
// import { OozeMobile } from "../../components/mobile/ooze";
// import { MergeMobile } from "../../components/mobile/merge";

import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}



const Astrids = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    setLandingPageData(JsonData);
    // getAlert()
  }, []);

  function trans(val) {
    i18n.changeLanguage(val)
  }
  const { t, i18n } = useTranslation(['common']);

  const getAlert = () => {
    axios.get(env.API_URL + "metadata/getalert").then((res) => {
      try {
        let d = new Date(res.data.date);
        setMessage(res.data.message)
        setDate(d.toLocaleString())
      } catch (e) { }
    }).catch(() => {
    })
  }

  function toggleMenu() {
    let d;
    let c;
    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }



  return (
    <div className='intro'>

      <div className="main-page-wrapper">
        {/* ===================================================
				Loading Transition
		  	==================================================== */}
        <div id="loader-wrapper">
          <div id="loader">
            <ul>
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
        {/* 
        =============================================
          Theme Header
        ============================================== 
        */}
        {/* <div className="background-alert" style={{ height: 30, width: '100%' }}>
          <p className=" fadeInUp animated" data-wow-delay="0.2s" style={{ "-webkit-text-transform": "uppercase", "text-transform": "uppercase", 'text-align': 'center', 'color': 'white' }}>
            {t('alert')}</p>
        </div> */}
           <header className="theme-menu-wrapper full-width-menu">
          <div className="header-wrapper">
            <div className="clearfix" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Logo */}
              <div className="float-left tran4s" style={{ "display": "flex", maxWidth: '150px', margin: 15, transform: 'translateY(-5px)' }}>
                <a href="/"><img style={{
                  minWidth: '5rem',
                  maxWidth: '5rem'
                }} src="images/elements/alogo.png" alt="Logo" /></a>
              </div>

              {/* ============================ Theme Menu ========================= */}
              <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper" style={{ justifyContent: 'flex-end' }}>
                {/* Brand and toggle get grouped for better mobile display */}
                <div className="navbar-header">
                  <button onClick={() => toggleMenu()} type="button" className="navbar-toggle collapsed" >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                {!openNav &&

                  <div className="collapse navbar-collapse" id="navbar-collapse-1">
                    <ul className="nav">
                      <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                      </li>
                      <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                      </li>
                      <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                      </li>
                      <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                      </li>
                      <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                      </li>
                      <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s" style={{ "font-size": "15px", color: 'white', fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                      </li>

                    </ul>
                  </div>
                }
              </nav> {/* /.theme-main-menu */}
              <div className="float-left tran4s" style={{ "display": "flex" }}>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/ig.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/twitter.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/discord.png'} /></a></li>
              </div>

            </div> {/* /.clearfix */}
          </div>


          {openNav &&

            <div className="navbar-collapse" id="navbar-collapse-1" style={{
              width: '100%',
              position: 'absolute',
              zIndex: '200',
              backgroundColor: 'white',
            }}>
              <ul className="nav" style={{
                flexDirection: 'column',
                display: 'flex'
              }}>
                <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                </li>
                <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                </li>
                <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                </li>

                <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                </li>
                <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                </li>
                <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                </li>
              </ul>
            </div>
          }

        </header> {/* /.theme-menu-wrapper */}



        {/* <div className="inner-page-banner" id="details">
          <div className="opacity" style={{ "display": "flex", "justify-content": "center", "align-items": "center" }}>
            <h1 style={{ "color": "white", "max-width": "800px" }}>

              {t('counter1')} <span className="timer" data-from={0} data-to={6666} data-speed={1000} data-refresh-interval={5}>0</span> <br /> {t('counter2')}
            </h1>
          </div>
        </div> */}


        <div className="astrid-bg" id="mint">
          <div className="container">
            <div className="container">
              <div className="row" style={{ display: 'flex', flexDirection: 'row', marginTop: 50, marginBottom: 50 }}>
                <div className="container">
                  <div className="row" style={{ marginTop: 200, marginBottom: 50 }}>
                    <div className="col-md-7 col-xs-12">
                      <img style={{ transform: 'scale(1.3)' }} src="images/home/astrid-stars.png" className="img-fluid" />

                    </div>
                    <div className="col-md-5 col-xs-12" style={{ height: '50%', display: 'flex', alignItems: 'center' }}>
                      <div className="theme-title" style={{ padding: 10 }}>
                        <h2 style={{ textAlign: 'right' }}>THE STARS</h2>
                        <h5 style={{ textAlign: 'right' }}>Each NFT in the Astrid Stars Collection serves as the providence for an Ad Astrium Star.</h5>
                        <h5 style={{ textAlign: 'right' }}>Each Ad Astrium Star is an artistic representation of a real star, reflected in our <font style={{ color: '#FDA750', fontWeight: 'bold', textDecoration: 'underline' }}>Star Registry</font>, our <font style={{ color: '#FDA750', fontWeight: 'bold', textDecoration: 'underline' }}>Star Map</font>, and their future iterations and derivatives.</h5>
                      </div> {/* /.theme-title */}
                    </div>

                  </div>


                </div> {/* /.container */}
              </div>
            </div> {/* /.container */}
            <div className="container">

              <div className="row" style={{ marginTop: 50, marginBottom: 50 }}>
                <div className="col-md-4 col-xs-12">
                  <div className="theme-title">
                    {/* <h6>{t('mint')} </h6> */}
                    <h2>The Astrids</h2>
                    <h5>Living among us and taking the form of children, hair ablaze with astral energy. The Astrids are mysterious but fun-loving beings that have rarely been sighted and never recorded.</h5>
                    <h5>Due to having existed long before the rebirth of the universe, they have observed human history several times over and have grown to love dressing up in historical and pop culture attires.</h5>
                    <h5>Their hobbies include: long walks in nature, fishing for space guppies, and conducting the grand destiny of humans and the universe.</h5>

                    {/* <div href="#details" className="outlineButton" data-wow-delay="0.3s">{t('hero_button')}</div> */}
                  </div> {/* /.theme-title */}
                </div>
                <div className="col-md-8 col-xs-12">
                  <img className="img-fluid" src="images/home/astrid-characters.png" style={{ transform: "scale(1.5)" }} />

                </div>
              </div>
            </div>


          </div> {/* /.container */}

          <div className="container">
            <div className="row" style={{ padding: 40, display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 50 }}>
              <h2 style={{ textAlign: 'center' }}>FOUNDER’S COLLECTION</h2>
              <h5 className="wow fadeInUp animated" style={{ textAlign: 'center', "margin-bottom": "20px", "margin-top": "20px", color: 'white' }}>
                NFT projects should not be a reskin of crowdfunding, they should be a revolutionary form of crowd ownership. As a tenet of crypto, decentralization is a principle that we not only wish to embody but also help make standard practice in the Cardano NFT space.</h5>
              <h5 className="wow fadeInUp animated" style={{ textAlign: 'center', "margin-bottom": "20px", "margin-top": "20px", color: 'white' }}>
                While Team Ad Astrium steers the ship, develops the products, the art and tech. Holders of the Astrid Stars collection will be project owners, collectively benefitting from an allocation of the project’s Net Profits earned from the project’s revenue streams and having access to exclusive community raffles and giveaways. </h5>
              {/* <a href="#details" className="tran3s filledButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ margin: 5 }}>View Collection </a> */}
            </div>
          </div>


          <div className="wrapper" style={{ maxWidth: '100%', overflow: 'hidden', marginBottom: 10 }}>
            <div className="row">
              <div className="portfolio-slider" style={{ overflow: 'hidden' }}>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Orange.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Purple.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Teal2.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/White4.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Yellow 2.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Yellow 7.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper" style={{ maxWidth: '100%', overflow: 'hidden', marginBottom: 50 }}>
            <div className="row">
              <div className="portfolio-slider" style={{ overflow: 'hidden' }}>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_3.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6>
                    <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_2.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6>
                    <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>

                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_1.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6>
                    <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>

                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_4.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6>
                    <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_5.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6>
                    <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="container">
            <div className="row" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 50, padding: 15 }}>
              <h5 className="wow fadeInUp animated" style={{ textAlign: 'center', "margin-bottom": "20px", "margin-top": "20px", color: 'white' }}>
                Do you want to own a Star, an Astrid, and a piece of Ad Astrium? <br></br>
                Join the Ad Astrium Discord to learn how to get your Astrid Star. </h5>
              <a href="https://discord.gg/adastrium" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>JOIN OUR DISCORD</a>

              {/* <a href="#details" className="tran3s filledButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ margin: 5, width: 300, fontWeight: 'bold' }}>JOIN OUR DISCORD </a> */}
            </div>
          </div>


        </div> {/* /.our-portfolio */}


        {/* 
			=============================================
				Footer
			============================================== 
			*/}
  <footer className="bg-one">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="footer-logo">
                  <a href="index.html"><img src="images/logo/gs-logo.png" style={{ "max-width": "200px", "margin-bottom": "30px", }} alt="Logo" /></a>
                  {/* <h4 style={{ color: 'white' }}>{t('more_links')}</h4> */}
                  <ul style={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
                    <a href="#astridstars" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ASTRIDS </h5>
                    </a>
                    <a href="#starmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR MAP </h5>
                    </a>
                    <a href="#starregistry" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR REGISTRY </h5>
                    </a>
                    <a href="#roadmap"  className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                      ROAD MAP </a>
                    <a href="#team"  className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                      TEAM </a>
                      <a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/"  className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                      WHITEPAPER </a>
                    {/* <li><a style={{ color: 'white' }} href="/astrids" className="tran3s">ASTRIDS</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR REGISTRY</a></li>
                    <li><a style={{ color: 'white' }} href="/roadmap" className="tran3s">ROAD MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">TEAM</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="float-right tran4s" style={{ "display": "flex" }}>
                  {/* <a><img src="images/logo/logo.png" alt="Logo" /></a> */}
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/ig.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /></a></li>
                </div>
              </div>
            </div> {/* /.row */}
            {/* <div className="bottom-footer clearfix">
              <p className="text-center">© 2022 <a href="#" className="tran3s p-color">Ad Astrium</a>. All rights
                reserved</p>
            </div> */}
          </div> {/* /.container */}
        </footer>
        {/* Sign-Up Modal */}
        <div className="modal fade signUpModal theme-modal-box" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <h3 style={{ color: 'white' }}>Login with Social Networks</h3>
                <ul style={{ color: 'white' }} className="clearfix">
                  <li className="float-left"><a href="#"><i className="fa fa-facebook" aria-hidden="true" />
                    facebook</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-google-plus" aria-hidden="true" />
                    Google</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-twitter" aria-hidden="true" />
                    Twitter</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-linkedin" aria-hidden="true" />
                    Linkedin</a></li>
                </ul>
                <form action="#">
                  <h6><span>or</span></h6>
                  <div className="wrapper">
                    <input type="text" placeholder="Username or Email" />
                    <input type="password" placeholder="Password" />
                    <ul className="clearfix">
                      <li className="float-left">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember Me</label>
                      </li>
                      <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                    </ul>
                    <button className="p-bg-color hvr-trim-two">Login</button>
                  </div>
                </form>
              </div> {/* /.modal-body */}
            </div> {/* /.modal-content */}
          </div> {/* /.modal-dialog */}
        </div> {/* /.signUpModal */}
        {/* Scroll Top Button */}
        <button className="scroll-top tran3s">
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
        <div id="svag-shape">
          <svg height={0} width={0}>
            <defs>
              <clipPath id="shape-one">
                <path fillRule="evenodd" fill="rgb(168, 168, 168)" d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* Js File_________________________________ */}
        {/* j Query */}
        {/* Bootstrap Select JS */}
        {/* Bootstrap JS */}
        {/* Vendor js _________ */}
        {/* Camera Slider */}
        {/* Mega menu  */}
        {/* WOW js */}
        {/* owl.carousel */}
        {/* js count to */}
        {/* Fancybox */}
        {/* Theme js */}

      </div>

    </div>
  );
};

export default Astrids;
