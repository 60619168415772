import { useState, useEffect, useRef } from "react";
import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Mint } from "../../components/mint";
import { Ooze } from "../../components/ooze";
import { Merge } from "../../components/merge";
import axios from 'axios';
import env from "react-dotenv";
import { Roadmap } from "../../components/roadmap";
import { Team } from "../../components/Team";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { useMediaQuery } from 'react-responsive'

// import { HeaderMobile } from "../../components/mobile/header";
// import { MintMobile } from "../../components/mobile/mint";
// import { RoadmapMobile } from "../../components/mobile/roadmap";
// import { OozeMobile } from "../../components/mobile/ooze";
// import { MergeMobile } from "../../components/mobile/merge";

import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}



const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [openNav, setOpenNav] = useState(false)

  const hiddenRef = useRef();

  useEffect(() => {
    setLandingPageData(JsonData);

    // alert('lolo')
    // console.log(document.querySelector('#building'))
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);

  }, []);

  const scrollHandler = () => {
    // console.log(window.pageYOffset, hiddenRef.current.offsetTop, hiddenRef.current.offsetHeight);
    const data = [
      {
        ul: 'astridstarsli',
        div: 'astridstars'
      },
      {
        ul: 'starmapli',
        div: 'starmap'
      },
      {
        ul: 'starregistryli',
        div: 'starregistry'
      },
      {
        ul: 'theteamli',
        div: 'team'
      },
      {
        ul: 'roadmapli',
        div: 'roadmap'
      }
    ]
    data.map((item) => {
      let element = document.getElementById(item.ul)
      let elementDiv = document.getElementById(item.div)

      if (window.pageYOffset > elementDiv.offsetTop - 200) {
        if ((elementDiv.offsetTop - 200) + elementDiv.offsetHeight > window.pageYOffset) {
          element.classList.add('active');

        } else {
          element.classList.remove('active');
        }
      } else {
        element.classList.remove('active');
      }
    })
  }

  function trans(val) {
    i18n.changeLanguage(val)
  }
  const { t, i18n } = useTranslation(['common']);

  const getAlert = () => {
    axios.get(env.API_URL + "metadata/getalert").then((res) => {
      try {
        let d = new Date(res.data.date);
        setMessage(res.data.message)
        setDate(d.toLocaleString())
      } catch (e) { }
    }).catch(() => {
    })
  }

  function toggleMenu() {
    let d;
    let c;
    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }



  return (
    <div className='intro'>

      <div className="main-page-wrapper" id="building">
        {/* ===================================================
				Loading Transition
		  	==================================================== */}
        <div id="loader-wrapper">
          <div id="loader">
            <ul>
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
        {/* 
        =============================================
          Theme Header
        ============================================== 
        */}
        {/* <div className="background-alert" style={{ height: 30, width: '100%' }}>
          <p className=" fadeInUp animated" data-wow-delay="0.2s" style={{ "-webkit-text-transform": "uppercase", "text-transform": "uppercase", 'text-align': 'center', 'color': 'white' }}>
            {t('alert')}</p>
        </div> */}
        <header className="theme-menu-wrapper full-width-menu">
          <div className="header-wrapper">
            <div className="clearfix" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Logo */}
              <div className="float-left tran4s" style={{ "display": "flex", maxWidth: '150px', margin: 15, transform: 'translateY(-5px)' }}>
                <a href="/"><img style={{
                  minWidth: '5rem',
                  maxWidth: '5rem'
                }} src="images/elements/alogo.png" alt="Logo" /></a>
              </div>

              {/* ============================ Theme Menu ========================= */}
              <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper" style={{ justifyContent: 'flex-end' }}>
                {/* Brand and toggle get grouped for better mobile display */}
                <div className="navbar-header">
                  <button onClick={() => toggleMenu()} type="button" className="navbar-toggle collapsed" >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                {!openNav &&

                  <div className="collapse navbar-collapse" id="navbar-collapse-1">
                    <ul className="nav">
                      <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                      </li>
                      <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                      </li>
                      <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                      </li>
                      <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                      </li>
                      <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                      </li>
                      <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s" style={{ "font-size": "15px", color: 'white', fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                      </li>

                    </ul>
                  </div>
                }
              </nav> {/* /.theme-main-menu */}
              <div className="float-left tran4s" style={{ "display": "flex" }}>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/ig.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/twitter.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/discord.png'} /></a></li>
              </div>

            </div> {/* /.clearfix */}
          </div>


          {openNav &&

            <div className="navbar-collapse" id="navbar-collapse-1" style={{
              width: '100%',
              position: 'absolute',
              zIndex: '200',
              backgroundColor: 'white',
            }}>
              <ul className="nav" style={{
                flexDirection: 'column',
                display: 'flex'
              }}>
                <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                </li>
                <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                </li>
                <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                </li>

                <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                </li>
                <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                </li>
                <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                </li>
              </ul>
            </div>
          }

        </header> {/* /.theme-menu-wrapper */}


        {/* 
			=============================================
				Theme Main Banner
			============================================== 
			*/}
        <div className="banner-one">
          <div className="bannerMain">
            <div className="camera_captions">
              <div className="container" >
                <div className="row" style={{
                  'align-items': 'center',
                  'display': 'flex',
                  'flex-direction': 'column',
                  "padding-left": "5rem", "padding-right": "5rem"
                }}>
                  <img
                    className="mx-auto d-block"
                    src="images/logo/gs-logo.png"
                  />
                  <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "50px", "margin-top": "30px", color: 'white' }}>
                    A story, a star registry, <font style={{ fontWeight: 'bold', fontStyle: 'italic' }}>the cosmos</font> . </h5>
                  <div className="row">
                    {/* <div className="col-6"> */}
                    <a href="/about" className="tran3s hvr-trim-two wow fadeInUp animated p-bg-color button-one-outline" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>{t('hero_button')}</a>
                    {/* </div>
                    <div className="col-6"> */}
                    <a href="https://discord.gg/adastrium" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>Join our community</a>
                    {/* </div> */}

                  </div>
                </div>

              </div> {/* /.container */}
            </div> {/* /.camera_caption */}
          </div>
        </div> {/* /#theme-main-banner */}

        {/* 
			=============================================
				Theme Inner Banner 
			============================================== 
			*/}


        <div className="super-nova-banner" id="supernova" style={{ "padding-left": "2rem", "padding-right": "2rem" }}>
          <div className="container">
            <div className="row align-items-center d-flex flex-row" style={{
              marginTop: 50, marginBottom: 50
            }}>
              <div className="col-lg-7 col-xs-12 order-2 order-lg-1">
                <div className="theme-title" style={{ padding: 10 }}>
                  {/* <h6>{t('mint')} </h6> */}
                  <h2>SUPERNOVA</h2>
                  <h5> When stars grow up and grow old, do they die? <br /> Or do they become something more? </h5>
                  <h5> The Pass equivalent of Ad Astrium. A collection for true Ad Astrium OGs that grant a wide array of benefits and exclusive access.</h5>

                  <h5> <font style={{ fontWeight: 'bold', color: 'white', fontFamily: 'montserrat', fontSize: 20 }}>Supernova Pass NFT Utility: </font><br />
                    <ul className="ulcustom">
                      <li>
                        Various Free Mints and automatic whitelists for future Ad Astrium NFT Collections.
                      </li>
                      <li>
                        Special Star Map Utilities
                      </li>
                      <li>
                        A piece of digital real estate in the form of a digital star and solar system
                      </li>
                      <li>
                        Governance, voting, and community benefits:
                      </li>
                      <li>
                        Profit Allocation Utilities
                      </li>
                    </ul>
                    <br />
                  </h5>
                  <h5> More information is available in our Discord.</h5>

                  <div className="row" style={{ flexDirection: 'row', display: 'flex' }}>
                    <a href="https://discord.gg/adastrium" className="tran3s hvr-trim-two wow fadeInUp animated p-bg-color button-one-outline" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>LEARN MORE</a>
                    <a href="https://www.jpg.store/collection/adastriumsupernova" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>BUY NOW</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xs-12 order-1 order-lg-2">
                <img style={{ transform: 'scale(1.0)' }} src="images/home/supernova.jpg" />
              </div>
            </div>
          </div> {/* /.container */}
        </div> {/* /.our-portfolio */}





        <div className="astrid-banner" id="astridstars" style={{ "padding-left": "2rem", "padding-right": "2rem" }}>
          <div className="container">
            <div className="row align-items-center d-flex flex-row" style={{
              marginTop: 50, marginBottom: 50
            }}>
              <div className="col-lg-7 col-xs-12 order-2 order-lg-1">
                <div className="theme-title" style={{ padding: 10 }}>
                  {/* <h6>{t('mint')} </h6> */}
                  <h2>Astrid Stars</h2>
                  <h5> <font style={{ fontWeight: 'bold', color: 'white' }}>Own a Star and an Astrid in one NFT.</font>  The Founder's collection of Ad Astrium, the Astrid Stars, is an NFT collection that merges the artistry of algorithmically generated collections with concepts of digital real estate and property.</h5>
                  <h5> <font style={{ fontWeight: 'bold', color: 'white', fontFamily: 'montserrat', fontSize: 20 }}>NFT UTILITY</font><br /><br />
                    Each NFT in the <font style={{ fontWeight: 'bold', color: 'white' }}>Astrid Stars Collection</font> comes with: <br />
                    <ul className="ulcustom">
                      <li>
                        A unique piece of artwork rooted in an ever-expanding lore
                      </li>
                      <li>
                        An immutable link to a real-life star
                      </li>
                      <li>
                        A piece of digital real estate in the form of a digital star and solar system
                      </li>
                      <li>
                        And the Ad Astrium Founder’s Collection benefits, once live:
                        <ul className="ulcustom">
                          <li>
                            Access to exclusive NFT drops and airdrops
                          </li>
                          <li>
                            Governance and voting rights
                          </li>
                          <li>
                            and a share of future profits.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <br />


                  </h5>

                  <div className="row" style={{ flexDirection: 'row', display: 'flex' }}>
                    <a href="/astrids" className="tran3s hvr-trim-two wow fadeInUp animated p-bg-color button-one-outline" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>ABOUT ASTRID STARS</a>
                    <a className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one-construction" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>HOW TO MINT <br /><font style={{ fontSize: 10 }}>(Under Construction)</font></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xs-12 order-1 order-lg-2">
                <img className="astridSize" src="images/astrids/Main Astrid.png" />
              </div>
            </div>


          </div> {/* /.container */}
          <div className="wrapper" style={{ maxWidth: '100%', overflow: 'hidden', marginBottom: 50 }}>
            <div className="row">
              <div className="portfolio-slider" style={{ overflow: 'hidden' }}>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Orange.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Purple.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Teal2.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/White4.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Yellow 2.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/Yellow 7.png" alt style={{ borderRadius: 10 }} />
                  </div>
                </div>


                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_1.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6> */}
                    {/* <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_2.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6> */}
                    {/* <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_3.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6> */}
                    {/* <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_4.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6> */}
                    {/* <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
                <div className="item">
                  <div className="image astridCard">
                    <img src="images/astrids/astrid_5.png" alt style={{ borderRadius: 10 }} />
                    {/* <h6 className="traitBubble">
                      Trait 1</h6> */}
                    {/* <div className="opacity tran4s"><a data-fancybox="project" href="images/slimes/mint/BigSlime24_Xray_Body copy.png" className="tran3s" title="Big Slime: XRay Body" /></div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <p style={{ "-webkit-text-align": "center", "text-align": "center" }}>Click on a slime to view</p> */}
          </div>

          <div className="container">
            <div className="row" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 50, padding: 10 }}>
              <h5 className="wow fadeInUp animated" style={{ textAlign: 'center', "margin-bottom": "20px", "margin-top": "20px", color: 'white' }}>
                <font style={{ fontWeight: 'bold' }}>Astrid Stars</font>  is the founder’s collection of Ad Astrium. Each Star NFT in the collection is accompanied by a unique Astrid, and is inextricably linked to a real-life star and a digital star in the immersive and interactive Ad Astrium Star Map. </h5>
              {/* <a href="#" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one-construction" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase', width: '300px' }}>VIEW COLLECTION</a> */}
              <a className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one-construction" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>VIEW COLLECTION <br /><font style={{ fontSize: 10 }}>(Under Construction)</font></a>

            </div>
          </div>


        </div> {/* /.our-portfolio */}





        <div className="star-banner" id="starmap">
          <div className="container">
            <div className="row" style={{ display: 'flex', flexDirection: 'row', marginTop: 50, marginBottom: 50, "padding-left": "2rem", "padding-right": "2rem" }}>
              <div className="container" >
                <div className="row" style={{
                  'align-items': 'center',
                  'display': 'flex',
                  'flex-direction': 'column'
                }}>
                  <img
                    className="mx-auto d-block"
                    src="images/logo/starmaplogo.png"
                  />
                  <h5 className="wow fadeInUp animated" style={{ textAlign: 'center', "max-width": "600px", "margin-bottom": "20px", "margin-top": "20px", color: 'white', padding: '10px' }}>

                    An immersive and interactive artistic 3D recreation of the cosmos on the blockchain. Based on scientifically accurate astronomical data.
                    <br /><br />
                    Revolutionizing the way star ownership is perceived and experienced.
                    Purposefully designed to have social and promotional functionalities, to scale, and to be able to eventually accommodate millions of users.

                  </h5>
                  {/* <div className="row"> */}
                  {/* <a href="#" className="tran3s hvr-trim-two wow fadeInUp animated p-bg-color button-one-outline" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase', width: '300px', zIndex: 20 }}>EXPLORE THE COSMOS</a> */}
                  {/* <a href="/demo" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one-construction-outline" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>EXPLORE THE COSMOS <br /><font style={{ fontSize: 10 }}>(Under Construction)</font></a>
                  </div> */}


                  {/* <div className="row" style={{ flexDirection: 'row', display: 'flex' }}> */}
                  <a href="/demo" className="tran3s hvr-trim-two wow fadeInUp animated p-bg-color button-one-outline" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>EXPLORE THE COSMOS</a>
                  {/* <a className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one-construction" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>HOW TO MINT <br /><font style={{ fontSize: 10 }}>(Under Construction)</font></a> */}
                  {/* </div> */}

                </div>
              </div> {/* /.container */}
            </div>
          </div> {/* /.container */}
        </div> {/* /.our-portfolio */}






        {/* 
			=============================================
				OOZE
			============================================== 
			*/}
        <div className="more-about-us" id="starregistry" style={{ backgroundColor: 'black', "padding-left": "2rem", "padding-right": "2rem" }}>
          <div className="image-box" style={{ top: '-259px' }}>
            <img style={{ "margin-left": "144px", "padding-bottom": "140px" }} height={1000} src="images/elements/starmapmain.png" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-offset-5">
                <div className="main-content" style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end'
                }}>
                  {/* <img className="bounce mobile-image" src="images/slimes/ooze.png" /> */}
                  <div className="theme-title" style={{ marginTop: 50 }}>
                    <h2 style={{ color: '#FDA750', textAlign: 'right', lineHeight: 1 }}>Star <br />  Registry
                    </h2>
                  </div> {/* /.theme-title */}
                  <h5 style={{ maxWidth: '800px !important', textAlign: 'right' }}>Through the integration of a fully-functioning star registry with NFT Technology and through the building and recognition of a community...<br />
                    <br />
                    We seek to challenge the idea of star ownership, disrupt the outdated star registry industry, and lay the foundation for the project to last for decades, or even centuries to come.</h5>
                  {/* <a href="#" className="tran3s outlineButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ width: '300px', fontWeight: 'bold' }}>FIND YOUR STAR </a> */}
                  {/* <a href="#" className="tran3s hvr-trim-two wow fadeInUp animated p-bg-color button-one-outline" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase', width: '300px', zIndex: 20 }}>FIND YOUR STAR</a> */}
                  <a className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one-construction" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase', zIndex: 20 }}>FIND YOUR STAR <br /><font style={{ fontSize: 10 }}>(Under Construction)</font></a>

                </div> {/* /.main-content */}
              </div> {/* /.col- */}
            </div> {/* /.row */}
          </div> {/* /.container */}
        </div> {/* /.more-about-us */}


        {/* 
			=============================================
				What We Do
			============================================== 
			*/}
        <div id="roadmap" className="what-we-do" style={{ "padding-top": "10rem", "padding-bottom": "10rem", "padding-left": "2rem", "padding-right": "2rem" }}>
          <div className="container">
            <div className="row">
              {/* <div style={{ width: '100%' }}>

              </div> */}


              <section id="conference-timeline">
                <div className="timeline-start">
                  <h2 style={{ color: '#2F1649' }}>Roadmap
                  </h2>
                  <h2 style={{ width: '100%', paddingTop: 10 }}> <font style={{ fontWeight: 'bold', color: '#2F1649', fontFamily: 'montserrat', fontSize: 30, textAlign: 'center' }}>BECOMING THE QUINTESSENTIAL <br></br> STAR OWNERSHIP PLATFORM</font> </h2>

                  <div style={{ width: '100%', color: '#2F1649', textTransform: 'none', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <font style={{ letterSpacing: '0.1rem', color: '#2F1649', fontFamily: 'montserratlight', maxWidth: 1000, textAlign: 'center' }}>
                      The Goal of Ad Astrium is to build a fun-centric community-driven Star “Ownership” platform that can one day snowball into becoming the global people’s choice for Star “Ownership”.
                      <br></br><br></br>
                      We can achieve this by building a Star Ownership platform better than any other currently in the market. Which is, in turn, validated by an ever-growing community.
                      <br></br><br></br>
                      We build the product. You make it truth. And the journey will be fun and full of stars!
                    </font>
                  </div>
                  <br></br>
                  <h2 style={{ color: '#2F1649', paddingTop: 50 }}>Phase 1 </h2>
                  <h2 style={{ width: '100%' }}> <font style={{ fontWeight: 'bold', color: '#2F1649', fontFamily: 'montserrat', fontSize: 30, textAlign: 'center' }}>ESTABLISHING PHASE</font> </h2>
                  <div style={{ width: '100%', color: '#2F1649', textTransform: 'none', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <font style={{ letterSpacing: '0.1rem', color: '#2F1649', fontFamily: 'montserratlight', maxWidth: 900, textAlign: 'center' }}>
                      The following portion of the roadmap is already under development <br></br>
                      and is geared towards establishing a fully functioning proof-of-concept which will serve as the foundations upon which we will build the world’s first community-centric platform for Star Ownership.

                    </font>
                  </div>

                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="conference-center-line-horizontal"></div>
                </div>
                <div>
                  <div className="conference-center-line"></div>

                  <div className="conference-timeline-content">
                    <div className="timeline-article">
                      <div className="content-left-container wow fadeInUp animated">
                        <div style={{ position: 'relative' }}>
                          <img style={{
                            position: 'absolute',
                            top: '0px',
                            right: '11px',
                            transform: 'translateY(-7px)',
                            zIndex: 100
                          }} src="images/done.svg" alt />
                          <div className="content-left">
                            <div>
                              <div className="conference-center-line-horizontal-left">
                                <div className="meta-date-left"></div>
                              </div>
                            </div>

                            <p style={{ fontFamily: 'montserrat', textAlign: 'left', color: '#2F1649', fontWeight: 'bold', marginBottom: 10, fontSize: 20, marginBottom: 20 }}>November 2022 <br /> Ad Astrium Project Launch  </p>
                            <ul className="ulroadmap">
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Ad Astrium MVP Website
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Ad Astrium Discord, Twitter and IG
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Star Map Teaser
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Founder’s Doxxing
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="content-right-container wow fadeInUp animated">
                        <div style={{ position: 'relative' }}>
                          <img style={{
                            position: 'absolute',
                            top: '0px',
                            right: '11px',
                            transform: 'translateY(-7px)',
                            zIndex: 100
                          }} src="images/done.svg" alt />
                          <div className="content-right">

                            <div>
                              <div className="conference-center-line-horizontal-right">
                                <div className="meta-date-right"></div>
                              </div>
                            </div>

                            <p style={{ fontFamily: 'montserrat', textAlign: 'left', color: '#2F1649', fontWeight: 'bold', marginBottom: 10, fontSize: 20, marginBottom: 20 }}>November 2022 <br /> Star Map Alpha Demo  </p>
                            <ul className="ulroadmap">
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Interactive Star Map
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Coordinate-accurate stars
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Placeholder star assets
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="timeline-article">

                      <div className="content-left-container wow fadeInUp animated">
                        <div style={{ position: 'relative' }}>
                          <img style={{
                            position: 'absolute',
                            top: '0px',
                            right: '11px',
                            transform: 'translateY(-7px)',
                            zIndex: 100
                          }} src="images/done.svg" alt />
                          <div className="content-left">
                            <div>
                              <div className="conference-center-line-horizontal-left">
                                <div className="meta-date-left"></div>
                              </div>
                            </div>
                            <p style={{ fontFamily: 'montserrat', textAlign: 'left', color: '#2F1649', fontWeight: 'bold', marginBottom: 10, fontSize: 20, marginBottom: 20 }}>Q4 2022 <br /> Supernova & The Nova Missions  </p>
                            <ul className="ulroadmap">
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                OG Collection
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Massive community-wide event
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                300/333 supply allocated for community airdrops
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="content-right-container wow fadeInUp animated">
                        <div style={{ position: 'relative' }}>
                          <div className="content-right">
                            <div>
                              <div className="conference-center-line-horizontal-right">
                                <div className="meta-date-right"></div>
                              </div>
                            </div>
                            <p style={{ fontFamily: 'montserrat', textAlign: 'left', color: '#2F1649', fontWeight: 'bold', marginBottom: 10, fontSize: 20, marginBottom: 20 }}>December 2022 / Q1 2023 <br /> Astrid Stars  </p>
                            <ul className="ulroadmap">
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Main Collection of Ad Astrium
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Founder’s Collection of Ad Astrium
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                A unity of generative art and metaverse property concepts
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Providence for Digital Stars in the Ad Astrium Star Registry
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="timeline-article">
                      <div className="content-left-container wow fadeInUp animated">
                        <div style={{ position: 'relative' }}>
                          <div className="content-left">
                            <div>
                              <div className="conference-center-line-horizontal-left">
                                <div className="meta-date-left"></div>
                              </div>
                            </div>
                            <p style={{ fontFamily: 'montserrat', textAlign: 'left', color: '#2F1649', fontWeight: 'bold', marginBottom: 10, fontSize: 20, marginBottom: 20 }}>December 2022 / Q1 2023 <br /> Star Map Version 1  </p>
                            <ul className="ulroadmap">
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Fully Functioning Star Map
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Stars Data will be integrated with NFT Metadata Referencing
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Customizable star information
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Star Dashboard
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="content-right-container wow fadeInUp animated">
                        <div style={{ position: 'relative' }}>
                          <div className="content-right">
                            <div>
                              <div className="conference-center-line-horizontal-right">
                                <div className="meta-date-right"></div>
                              </div>
                            </div>
                            <p style={{ fontFamily: 'montserrat', textAlign: 'left', color: '#2F1649', fontWeight: 'bold', marginBottom: 10, fontSize: 20, marginBottom: 20 }}>December 2022 / Q1 2023 <br /> Project and Business Litepaper  </p>
                            <ul className="ulroadmap">
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Outline of short to long-term goals
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Actionable plans in the near future
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Early concepts
                              </li>
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Feasibility of goals
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>


                    <div className="timeline-article wow fadeInUp animated">
                      <div className="content-left-container">
                        <div style={{ position: 'relative' }}>
                          <div className="content-left">
                            <div>
                              <div className="conference-center-line-horizontal-left">
                                <div className="meta-date-left"></div>
                              </div>
                            </div>
                            <p style={{ fontFamily: 'montserrat', textAlign: 'left', color: '#2F1649', fontWeight: 'bold', marginBottom: 10, fontSize: 20, marginBottom: 20 }}>Q1 2023 <br /> Incorporation of Ad Astrium  </p>
                            <ul className="ulroadmap">
                              <li style={{ color: '#2F1649', fontWeight: 'bold', marginBottom: 10 }}>
                                Ad Astrium, is currently owned by a duly registered sole proprietorship but shortly after the mint of the Main Collection we will establish a new corporation to drive this project forward
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row" style={{ display: "block", transform: `translateY(86px)` }}>
                    <a className="tran3s outlineButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ backgroundColor: 'white', margin: 5, border: '#2F1649 solid 2px', color: '#2F1649', width: '300px', fontWeight: 'bold' }}>PHASE 2 ONWARDS <br></br><font style={{ fontSize: 10 }}>(Under Construction)</font> </a>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>

        {/* 
			=============================================
				Our Team Style One 
			============================================== 
			*/}

        <div style={{ backgroundColor: 'black' }} id="team">
          <div className="container">
            <div className="row" style={{ display: 'flex', flexDirection: 'row', marginTop: 50, marginBottom: 80 }}>
              <div className="container" >
                <div className="row" style={{
                  'align-items': 'center',
                  'display': 'flex',
                  'flex-direction': 'column',
                }}>
                  <br></br>
                  <br></br>
                  <div className="p-10">

                    <img
                      className="d-block m-10"
                      src="images/logo/adastrium_team.png"
                    />
                  </div>

                  <br></br>
                  <br></br>

                  <div className="col-md-12 col-xs-12 row">

                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">
                        <div className="image" style={{ padding: 20 }}>
                          <img src="images/astrids/epoch.png" alt />
                        </div> {/* /.image */}
                        <h2 style={{ fontSize: 25, color: 'white', marginTop: 10, marginBottom: 0, fontFamily: 'montserrat' }}>EPOCH</h2>
                        <p style={{ color: 'white' }}>Founder & CEO</p>
                        <p style={{ textAlign: 'center', color: 'white', marginTop: 20, marginBottom: 20, padding: 10 }}>The spearhead of Ad Astrium, Epoch formulated the product and business plan. Is the focal point of all the project's moving parts and is directly involved in every aspect of the project.</p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}
                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">
                        <div className="image" style={{ padding: 20 }}>
                          <img src="images/astrids/parsec.png" alt />
                        </div> {/* /.image */}
                        <h2 style={{ fontSize: 25, color: 'white', marginTop: 10, marginBottom: 0, fontFamily: 'montserrat' }}>PARSEC</h2>
                        <p style={{ color: 'white' }}>Founder & CCO</p>
                        <p style={{ textAlign: 'center', color: 'white', marginTop: 20, marginBottom: 20, padding: 10 }}>Directly handles the Creative and Art Direction for Ad Astrium. Whose expertise will be instumental to the scaling plans of Ad Astrium.</p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}
                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">
                        <div className="image" style={{ padding: 20 }}>
                          <img src="images/astrids/ahtan.png" alt />
                        </div> {/* /.image */}
                        <h2 style={{ fontSize: 25, color: 'white', marginTop: 10, marginBottom: 0, fontFamily: 'montserrat' }}>AH-TAN</h2>
                        <p style={{ color: 'white' }}>Lead Artist</p>
                        <p style={{ textAlign: 'center', color: 'white', marginTop: 20, marginBottom: 20, padding: 10 }}>Kimchi / MathRock / Mech. The talent behind the Supernova and Astrid Stars you know and love. </p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}
                    <div className="col-md-3 col-xs-12">
                      <div className="single-team-member center-elements">
                        <div className="image" style={{ padding: 20 }}>
                          <img src="images/astrids/equinox.png" alt />
                        </div> {/* /.image */}
                        <h2 style={{ fontSize: 25, color: 'white', marginTop: 10, marginBottom: 0, fontFamily: 'montserrat' }}>EQUINOX</h2>

                        <p style={{ color: 'white' }}>Lead Developer</p>
                        <p style={{ textAlign: 'center', color: 'white', marginTop: 20, marginBottom: 20, padding: 10 }}>The President of a duly registered Blockchain and Software Development Firm. Leads a team of five additional experienced developers under his guidance and direction</p>

                      </div> {/* /.single-team-member */}
                    </div> {/* /.col- */}
                  </div>

                  <div className="row" style={{ display: "block" }}>
                    <a href="/team" className="tran3s outlineButton wow fadeInUp animated" data-wow-delay="0.3s" style={{ margin: 5, border: 'white solid 2px', color: 'white', width: '300px', fontWeight: 'bold' }}>SEE THE FULL TEAM </a>
                  </div>

                  {/* <h5 className="wow fadeInUp animated" style={{ textAlign: 'center', "max-width": "600px", "margin-bottom": "20px", "margin-top": "20px", color: 'white', padding: '10px' }}>
                    An immersive and interactive artistic 3D recreation of the cosmos on the blockchain. Based on scientifically accurate astronomical data. <br /><br />

                    Revolutionizing the way star ownership is perceived and experienced.
                    Will become the foundation for Ad Astrium’s unique Metaverse concept.</h5>
                  <div className="row">
                    <a className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one-construction" data-wow-delay="0.3s" style={{ margin: 5, textTransform: 'uppercase' }}>EXPLORE THE COSMOS <br /><font style={{ fontSize: 10 }}>(Under Construction)</font></a>
                  </div> */}
                </div>
              </div> {/* /.container */}
            </div>
          </div> {/* /.container */}
        </div> {/* /.our-portfolio */}

        {/* <div className="our-team-styleOne inner-page" id="team" style={{ "padding-top": "10rem", "padding-bottom": "10rem" }}>
          <div className="container">
            <div className="row">

              <div className="col-md-3 col-xs-12">
                <img src="images/elements/ateam.png" alt />
                <p style={{ marginTop: 20, marginBottom: 20, padding: 10 }}>The Ad Astrium Team consists of dreamers and professionals bonded by a lifetime of friendship; each with over a decade’s worth of experience in their respective fields including marketing, advertising, art and illustration, game design, and software and blockchain development.</p>
              </div>

              <div className="col-md-9 col-xs-12">

                <div className="col-md-4 col-xs-6">
                  <div className="single-team-member">
                    <div className="image">
                      <img src="images/astrids/epoch.png" alt />
                    </div> 
                    <h2 style={{ color: 'black', marginTop: 10, marginBottom: 0 }}>EPOCH</h2>
                    <p>Founder & CEO</p>
                  </div>
                </div> 
                <div className="col-md-4 col-xs-6">
                  <div className="single-team-member">
                    <div className="image">
                      <img src="images/astrids/parsec.png" alt />
                    </div> 
                    <h2 style={{ color: 'black', marginTop: 10, marginBottom: 0 }}>PARSEC</h2>
                    <p>Founder & CCO</p>
                  </div> 
                </div> 
                <div className="col-md-4 col-xs-6">
                  <div className="single-team-member">
                    <div className="image">
                      <img src="images/astrids/ahtan.png" alt />
                    </div> 
                    <h2 style={{ color: 'black', marginTop: 10, marginBottom: 0 }}>AH-TAN</h2>
                    <p>Lead Artist</p>
                  </div> 
                </div> 
                <div className='col-md-12 col-xs-12' style={{ marginTop: 20 }}>

                  <div className="col-md-3 col-xs-6">
                    <div className="single-team-member">
                      <div className="image">
                        <img src="images/astrids/sirius.png" alt />
                      </div>
                      <h3 style={{ color: 'black', marginTop: 10, marginBottom: 0, fontWeight: 'bold' }}>SIRIUS</h3>
                      <p>COO</p>
                    </div> 
                  </div> 
                  <div className="col-md-3 col-xs-6">
                    <div className="single-team-member">
                      <div className="image">
                        <img src="images/astrids/equinox.png" alt />
                      </div>
                      <h3 style={{ color: 'black', marginTop: 10, marginBottom: 0, fontWeight: 'bold' }}>EQUINOX</h3>
                      <p>Lead Developer</p>
                    </div> 
                  </div> 
                  <div className="col-md-3 col-xs-6">
                    <div className="single-team-member">
                      <div className="image">
                        <img src="images/astrids/andromeda.png" alt />
                      </div>
                      <h3 style={{ color: 'black', marginTop: 10, marginBottom: 0, fontWeight: 'bold' }}>ANDROMEDA</h3>
                      <p>Marketing Guru</p>
                    </div> 
                  </div> 
                  <div className="col-md-3 col-xs-6">
                    <div className="single-team-member">
                      <div className="image">
                        <img src="images/astrids/cassiopeia.png" alt />
                      </div>
                      <h3 style={{ color: 'black', marginTop: 10, marginBottom: 0, fontWeight: 'bold' }}>CASSIOPEIA</h3>
                      <p>Business Consultant</p>
                    </div> 
                  </div> 
                </div>

              </div> 
            </div>
          </div> 
        </div>  */}




        {/* 
			=============================================
				OOZE
			============================================== 
			*/}
        <div className="cardano-banner" id="ooze" style={{ backgroundColor: 'black' }}>
          {/* <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="image-box" >
              <img height={1000} src="images/elements/cardano.png" />
            </div> */}
          <div className="container">
            <div className="row">
              <div className="col-lg-5 ">
                {/* <img src="images/elements/cardano.png" style={{ transform: 'scale(1.2)' }} /> */}
                <img src="images/elements/cardano.png" />

              </div>
              <div className="col-lg-7 " style={{ marginTop: 20 }}>
                <div className="main-content" style={{ marginLeft: 10 }}>
                  <a href="https://cardano.org/">
                    <img class='img-fluid' src="images/logo/cardano.png" />
                  </a>

                  <h5 style={{ maxWidth: '800px !important', fontWeight: 'bold', marginTop: 10, color: 'white' }}>Why Cardano? Cardano doesn’t take shortcuts and is the most reliable and most stable Smart Contract platform option for us.</h5>
                  <h5 style={{ maxWidth: '800px !important' }}>Unwavering in their altruistic principles even when it goes against the hype. Their quest for decentralization, their measure-twice-and-cut-once approach, the eco-friendly proof-of-stake mechanism, the real talent behind their team, and their ability to rally the best of the crypto community all add up to the inevitability of Cardano’s mass adoption.</h5>
                  <h5 style={{ maxWidth: '800px !important' }}>We at Team Ad Astrium are here to build a <font style={{ fontWeight: 'bold', color: 'white' }}>sustainable</font> and <font style={{ fontWeight: 'bold', color: 'white' }}>decentralized</font> ecosystem of art and novel NFT use cases that will flourish for decades to come.  We strive to emulate the principles of the Cardano Team in our pursuits. </h5>
                  <h5 style={{ maxWidth: '800px !important' }}>The only platform to build on is Cardano. The only community to build Ad Astrium with is the Cardano Community.</h5>
                </div> {/* /.main-content */}
              </div> {/* /.col- */}
            </div> {/* /.row */}
          </div> {/* /.container */}
          {/* </div> */}

        </div> {/* /.more-about-us */}




        {/* 
			=============================================
				Footer
			============================================== 
			*/}
        <footer className="bg-one">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="footer-logo">
                  <a href="index.html"><img src="images/logo/gs-logo.png" style={{ "max-width": "200px", "margin-bottom": "30px", }} alt="Logo" /></a>
                  {/* <h4 style={{ color: 'white' }}>{t('more_links')}</h4> */}
                  <ul style={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
                    <a href="#astridstars" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ASTRIDS </h5>
                    </a>
                    <a href="#starmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR MAP </h5>
                    </a>
                    <a href="#starregistry" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR REGISTRY </h5>
                    </a>
                    <a href="#roadmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ROAD MAP</h5> </a>
                    <a href="#team" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        TEAM </h5></a>
                    <a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        WHITEPAPER  </h5></a>
                    {/* <li><a style={{ color: 'white' }} href="/astrids" className="tran3s">ASTRIDS</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR REGISTRY</a></li>
                    <li><a style={{ color: 'white' }} href="/roadmap" className="tran3s">ROAD MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">TEAM</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="float-right tran4s" style={{ "display": "flex" }}>
                  {/* <a><img src="images/logo/logo.png" alt="Logo" /></a> */}
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/ig.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /></a></li>
                </div>
              </div>
            </div> {/* /.row */}
            {/* <div className="bottom-footer clearfix">
              <p className="text-center">© 2022 <a href="#" className="tran3s p-color">Ad Astrium</a>. All rights
                reserved</p>
            </div> */}
          </div> {/* /.container */}
        </footer>
        {/* Sign-Up Modal */}
        <div className="modal fade signUpModal theme-modal-box" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <h3 style={{ color: 'white' }}>Login with Social Networks</h3>
                <ul style={{ color: 'white' }} className="clearfix">
                  <li className="float-left"><a href="#"><i className="fa fa-facebook" aria-hidden="true" />
                    facebook</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-google-plus" aria-hidden="true" />
                    Google</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-twitter" aria-hidden="true" />
                    Twitter</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-linkedin" aria-hidden="true" />
                    Linkedin</a></li>
                </ul>
                <form action="#">
                  <h6><span>or</span></h6>
                  <div className="wrapper">
                    <input type="text" placeholder="Username or Email" />
                    <input type="password" placeholder="Password" />
                    <ul className="clearfix">
                      <li className="float-left">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember Me</label>
                      </li>
                      <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                    </ul>
                    <button className="p-bg-color hvr-trim-two">Login</button>
                  </div>
                </form>
              </div> {/* /.modal-body */}
            </div> {/* /.modal-content */}
          </div> {/* /.modal-dialog */}
        </div> {/* /.signUpModal */}
        {/* Scroll Top Button */}
        <button className="scroll-top tran3s">
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
        <div id="svag-shape">
          <svg height={0} width={0}>
            <defs>
              <clipPath id="shape-one">
                <path fillRule="evenodd" fill="rgb(168, 168, 168)" d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* Js File_________________________________ */}
        {/* j Query */}
        {/* Bootstrap Select JS */}
        {/* Bootstrap JS */}
        {/* Vendor js _________ */}
        {/* Camera Slider */}
        {/* Mega menu  */}
        {/* WOW js */}
        {/* owl.carousel */}
        {/* js count to */}
        {/* Fancybox */}
        {/* Theme js */}

      </div>

    </div>
  );
};

export default Home;
