export const starData = [
  {
    "Astrid #": "ASTRID 000001",
    "HYG Star ID": 70666,
    "Hipparcos Catalogue": 70890,
    "Gliese Catalog": "Gl 551",
    "Proper Name": "Proxima Centauri",
    "Distance in Parsecs": 1.2959,
    "mag": 11.01,
    "Absolute Visual Magnitude": 15.447,
    "Spectral Type": "M5Ve",
    "Color Index": 1.807,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.472264,
    "y": -0.361451,
    "z": -1.151219,
    "vx": -0.00001186,
    "vy": 0.00002079,
    "vz": 0.00001675,
    "rarad": 3.79503991683157,
    "decrad": -1.09396338473328,
    "pmrarad": -0.0000183048192485277,
    "pmdecrad": 0.000003724144768,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 70666,
    "lum": 0.0000577032135332929,
    "var": "V645",
    "var_min": 11.079,
    "var_max": 10.939,
    "ra": 14.495985,
    "dec": -62.679485,
    "pmra": -3775.64,
    "pmdec": 768.16,
    "rv": -16,
    "Asset Name": "ASTRID 000001",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Mischievous",
    "Hair": "Mech Head",
    "Outfit": "Mecha",
    "Special": "Energy Mech",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Proxima Centauri",
    "Constellation": "Centaurus",
    "x_1": -0.00001186,
    "y_1": 0.00002079,
    "z_1": 0.00001675,
    "Distance in Parsecs_1": 1.2959,
    "HYG Star ID_1": 70666,
    "Hipparcos Catalogue_1": 70890,
    "Gliese Catalog_1": "Gl 551"
  },
  {
    "Astrid #": "ASTRID 000002",
    "HYG Star ID": 71453,
    "Hipparcos Catalogue": 71681,
    "Henry Draper Catalogue": 128621,
    "Harvard Revised Catalogue": 5460,
    "Gliese Catalog": "Gl 559B",
    "Distance in Parsecs": 1.3248,
    "mag": 1.35,
    "Absolute Visual Magnitude": 5.739,
    "Spectral Type": "K1V",
    "Color Index": 0.9,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.495181,
    "y": -0.483973,
    "z": -1.156674,
    "vx": -0.00000722,
    "vy": 0.00002476,
    "vz": 0.00002491,
    "rarad": 3.83806957077722,
    "decrad": -1.06182864877041,
    "pmrarad": -0.0000178323683168263,
    "pmdecrad": 0.000002336026238,
    "bayer": "Alp-2",
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 2,
    "comp_primary": 71456,
    "base": "Gl 559",
    "lum": 0.440960816450637,
    "bf": "Alp2Cen",
    "ra": 14.660346,
    "dec": -60.8383,
    "pmra": -3678.19,
    "pmdec": 481.84,
    "rv": -26.2,
    "Asset Name": "ASTRID 000002",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "High Fade",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000722,
    "y_1": 0.00002476,
    "z_1": 0.00002491,
    "Distance in Parsecs_1": 1.3248,
    "HYG Star ID_1": 71453,
    "Hipparcos Catalogue_1": 71681,
    "Henry Draper Catalogue_1": 128621,
    "Harvard Revised Catalogue_1": 5460,
    "Gliese Catalog_1": "Gl 559B"
  },
  {
    "Astrid #": "ASTRID 000003",
    "HYG Star ID": 71456,
    "Hipparcos Catalogue": 71683,
    "Henry Draper Catalogue": 128620,
    "Harvard Revised Catalogue": 5459,
    "Gliese Catalog": "Gl 559A",
    "Proper Name": "Rigil Kentaurus",
    "Distance in Parsecs": 1.3248,
    "mag": -0.01,
    "Absolute Visual Magnitude": 4.379,
    "Spectral Type": "G2V",
    "Color Index": 0.71,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.425203,
    "y": -0.414084,
    "z": -1.156625,
    "vx": -0.00000722,
    "vy": 0.00002476,
    "vz": 0.00002491,
    "rarad": 3.83817921177722,
    "decrad": -1.06175317577041,
    "pmrarad": -0.0000178323683168263,
    "pmdecrad": 0.000002336026238,
    "bayer": "Alp-1",
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 71456,
    "base": "Gl 559",
    "lum": 1.54312106565779,
    "var_min": 0.113,
    "var_max": -0.087,
    "bf": "Alp1Cen",
    "ra": 14.660765,
    "dec": -60.833976,
    "pmra": -3678.19,
    "pmdec": 481.84,
    "rv": -26.2,
    "Asset Name": "ASTRID 000003",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Chuckle",
    "Hair": "Ram Horns",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Rigil Kentaurus",
    "Constellation": "Centaurus",
    "x_1": -0.00000722,
    "y_1": 0.00002476,
    "z_1": 0.00002491,
    "Distance in Parsecs_1": 1.3248,
    "HYG Star ID_1": 71456,
    "Hipparcos Catalogue_1": 71683,
    "Henry Draper Catalogue_1": 128620,
    "Harvard Revised Catalogue_1": 5459,
    "Gliese Catalog_1": "Gl 559A"
  },
  {
    "Astrid #": "ASTRID 000004",
    "HYG Star ID": 87665,
    "Hipparcos Catalogue": 87937,
    "Gliese Catalog": "Gl 699",
    "Proper Name": "Barnard's Star",
    "Distance in Parsecs": 1.8238,
    "mag": 9.54,
    "Absolute Visual Magnitude": 13.235,
    "Spectral Type": "sdM4",
    "Color Index": 1.57,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": -0.017373,
    "y": -1.816613,
    "z": 0.149123,
    "vx": -0.00000591,
    "vy": 0.0001204,
    "vz": 0.00007888,
    "rarad": 4.70282603167214,
    "decrad": 0.0819150787324337,
    "pmrarad": -0.00000386803746894444,
    "pmdecrad": 0.000050066369421,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 87665,
    "lum": 0.000442588372362626,
    "var": "V2500",
    "var_min": 9.59,
    "var_max": 9.49,
    "ra": 17.963472,
    "dec": 4.693388,
    "pmra": -797.84,
    "pmdec": 9999.99,
    "rv": -111,
    "Asset Name": "ASTRID 000004",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Sweet Nap",
    "Hair": "Audiophile",
    "Outfit": "Sharky",
    "Special": "None",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "Barnard's Star",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000591,
    "y_1": 0.0001204,
    "z_1": 0.00007888,
    "Distance in Parsecs_1": 1.8238,
    "HYG Star ID_1": 87665,
    "Hipparcos Catalogue_1": 87937,
    "Gliese Catalog_1": "Gl 699"
  },
  {
    "Astrid #": "ASTRID 000005",
    "HYG Star ID": 118720,
    "Gliese Catalog": "Gl 406",
    "Distance in Parsecs": 2.3906,
    "mag": 13.45,
    "Absolute Visual Magnitude": 16.557,
    "Spectral Type": "M6",
    "Color Index": 2,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.282811,
    "y": 0.649334,
    "z": 0.292057,
    "vx": -0.00000433,
    "vy": 0.00004728,
    "vz": -0.00002961,
    "rarad": 2.86447368676996,
    "decrad": 0.12244043730895,
    "pmrarad": -0.000018534184600798598,
    "pmdecrad": -0.000013171553821,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118720,
    "lum": 0.000020758692704952,
    "ra": 10.941484,
    "dec": 7.01532,
    "pmra": -3822.95,
    "pmdec": -2716.83,
    "rv": 13,
    "Asset Name": "ASTRID 000005",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Undercut",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000433,
    "y_1": 0.00004728,
    "z_1": -0.00002961,
    "Distance in Parsecs_1": 2.3906,
    "HYG Star ID_1": 118720,
    "Gliese Catalog_1": "Gl 406"
  },
  {
    "Astrid #": "ASTRID 000006",
    "HYG Star ID": 53879,
    "Hipparcos Catalogue": 54035,
    "Henry Draper Catalogue": 95735,
    "Gliese Catalog": "Gl 411",
    "Proper Name": "Lalande 21185",
    "Distance in Parsecs": 2.5469,
    "mag": 7.49,
    "Absolute Visual Magnitude": 10.46,
    "Spectral Type": "M2V",
    "Color Index": 1.502,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.998,
    "y": 0.504305,
    "z": 1.495504,
    "vx": 0.00003587,
    "vy": -0.00000166,
    "vz": -0.00009828,
    "rarad": 2.89435757722147,
    "decrad": 0.627792793335765,
    "pmrarad": -0.00000281288897458333,
    "pmdecrad": -0.000023111504484,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 53879,
    "lum": 0.00570164272280746,
    "ra": 11.055632,
    "dec": 35.969877,
    "pmra": -580.2,
    "pmdec": -4767.09,
    "rv": -84.3,
    "Asset Name": "ASTRID 000006",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Upset",
    "Hair": "Spartan Helmet",
    "Outfit": "Spartan",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Lalande 21185",
    "Constellation": "Ursa Major",
    "x_1": 0.00003587,
    "y_1": -0.00000166,
    "z_1": -0.00009828,
    "Distance in Parsecs_1": 2.5469,
    "HYG Star ID_1": 53879,
    "Hipparcos Catalogue_1": 54035,
    "Henry Draper Catalogue_1": 95735,
    "Gliese Catalog_1": "Gl 411"
  },
  {
    "Astrid #": "ASTRID 000007",
    "HYG Star ID": 118079,
    "Gliese Catalog": "Gl 65A",
    "Distance in Parsecs": 2.6267,
    "mag": 12.57,
    "Absolute Visual Magnitude": 15.473,
    "Spectral Type": "dM5.5e",
    "Color Index": 1.85,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.270499,
    "y": 1.04697,
    "z": -0.809963,
    "vx": 0.00001,
    "vy": 0.00005104,
    "vz": -0.00000236,
    "rarad": 0.432021068741498,
    "decrad": -0.31328279826635,
    "pmrarad": 0.0000160616348299652,
    "pmdecrad": 0.000002716614673,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118079,
    "base": "Gl  65",
    "lum": 0.0000563378150850937,
    "ra": 1.650199,
    "dec": -17.949782,
    "pmra": 3312.95,
    "pmdec": 560.34,
    "rv": 29,
    "Asset Name": "ASTRID 000007",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Goddess",
    "Outfit": "Toga",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001,
    "y_1": 0.00005104,
    "z_1": -0.00000236,
    "Distance in Parsecs_1": 2.6267,
    "HYG Star ID_1": 118079,
    "Gliese Catalog_1": "Gl 65A"
  },
  {
    "Astrid #": "ASTRID 000008",
    "HYG Star ID": 118080,
    "Gliese Catalog": "Gl 65B",
    "Distance in Parsecs": 2.6267,
    "mag": 12.7,
    "Absolute Visual Magnitude": 15.603,
    "Spectral Type": "dM5.5e",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.270631,
    "y": 1.047031,
    "z": -0.81001,
    "vx": 0.00001265,
    "vy": 0.00005226,
    "vz": -0.0000033,
    "rarad": 0.432021068741498,
    "decrad": -0.31328279826635,
    "pmrarad": 0.0000160616348299652,
    "pmdecrad": 0.000002716614673,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118079,
    "base": "Gl  65",
    "lum": 0.0000499804313578238,
    "ra": 1.650199,
    "dec": -17.949782,
    "pmra": 3312.95,
    "pmdec": 560.34,
    "rv": 32,
    "Asset Name": "ASTRID 000008",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Infatuated",
    "Hair": "Manbun",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001265,
    "y_1": 0.00005226,
    "z_1": -0.0000033,
    "Distance in Parsecs_1": 2.6267,
    "HYG Star ID_1": 118080,
    "Gliese Catalog_1": "Gl 65B"
  },
  {
    "Astrid #": "ASTRID 000009",
    "HYG Star ID": 32263,
    "Hipparcos Catalogue": 32349,
    "Henry Draper Catalogue": 48915,
    "Harvard Revised Catalogue": 2491,
    "Gliese Catalog": "Gl 244A",
    "Proper Name": "Sirius",
    "Distance in Parsecs": 2.6371,
    "mag": -1.44,
    "Absolute Visual Magnitude": 1.454,
    "Spectral Type": "A0m...",
    "Color Index": 0.009,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -0.494323,
    "y": 2.476731,
    "z": -0.758485,
    "vx": 0.00000953,
    "vy": -0.00001207,
    "vz": -0.00001221,
    "rarad": 1.76779536960219,
    "decrad": -0.291751258517685,
    "pmrarad": -0.00000264713117720138,
    "pmdecrad": -0.000005929659164,
    "bayer": "Alp",
    "flam": 9,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 32263,
    "base": "Gl 244",
    "lum": 22.824433121735,
    "var_min": -1.333,
    "var_max": -1.523,
    "bf": "9Alp CMa",
    "ra": 6.752481,
    "dec": -16.716116,
    "pmra": -546.01,
    "pmdec": -1223.08,
    "rv": -9.4,
    "Asset Name": "ASTRID 000009",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Serious Mad",
    "Hair": "Astral Band",
    "Outfit": "Star Knight",
    "Special": "Ascendant Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Sirius",
    "Constellation": "Canis Major",
    "x_1": 0.00000953,
    "y_1": -0.00001207,
    "z_1": -0.00001221,
    "Distance in Parsecs_1": 2.6371,
    "HYG Star ID_1": 32263,
    "Hipparcos Catalogue_1": 32349,
    "Henry Draper Catalogue_1": 48915,
    "Harvard Revised Catalogue_1": 2491,
    "Gliese Catalog_1": "Gl 244A"
  },
  {
    "Astrid #": "ASTRID 000010",
    "HYG Star ID": 118441,
    "Gliese Catalog": "Gl 244B",
    "Distance in Parsecs": 2.6371,
    "mag": 8.44,
    "Absolute Visual Magnitude": 11.334,
    "Spectral Type": "DA2",
    "Color Index": -0.03,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -0.494379,
    "y": 2.476814,
    "z": -0.75847,
    "vx": 0.00000768,
    "vy": -0.00000297,
    "vz": -0.00001473,
    "rarad": 1.76781584560436,
    "decrad": -0.291735967034,
    "pmrarad": -0.00000263350791277777,
    "pmdecrad": -0.00000583256068,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 2,
    "comp_primary": 32263,
    "base": "Gl 244",
    "lum": 0.00254917705050911,
    "ra": 6.752559,
    "dec": -16.71524,
    "pmra": -543.2,
    "pmdec": -1203.05,
    "rv": 0,
    "Asset Name": "ASTRID 000010",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Ninja",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00000768,
    "y_1": -0.00000297,
    "z_1": -0.00001473,
    "Distance in Parsecs_1": 2.6371,
    "HYG Star ID_1": 118441,
    "Gliese Catalog_1": "Gl 244B"
  },
  {
    "Astrid #": "ASTRID 000011",
    "HYG Star ID": 92115,
    "Hipparcos Catalogue": 92403,
    "Gliese Catalog": "Gl 729",
    "Distance in Parsecs": 2.9698,
    "mag": 10.37,
    "Absolute Visual Magnitude": 13.006,
    "Spectral Type": "M3.5Ve",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.585883,
    "y": -2.652454,
    "z": -1.200123,
    "vx": 0.00000628,
    "vy": 0.00001413,
    "vz": 0.00000247,
    "rarad": 4.92977902963417,
    "decrad": -0.416020735807787,
    "pmrarad": 0.00000309092962038194,
    "pmdecrad": -9.3312089e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 92115,
    "lum": 0.000546512374519871,
    "var": "V1216",
    "var_min": 10.408,
    "var_max": 10.338,
    "ra": 18.830369,
    "dec": -23.836232,
    "pmra": 637.55,
    "pmdec": -192.47,
    "rv": -12.1,
    "Asset Name": "ASTRID 000011",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Curly",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00000628,
    "y_1": 0.00001413,
    "z_1": 0.00000247,
    "Distance in Parsecs_1": 2.9698,
    "HYG Star ID_1": 92115,
    "Hipparcos Catalogue_1": 92403,
    "Gliese Catalog_1": "Gl 729"
  },
  {
    "Astrid #": "ASTRID 000012",
    "HYG Star ID": 119585,
    "Gliese Catalog": "Gl 905",
    "Distance in Parsecs": 3.1686,
    "mag": 12.29,
    "Absolute Visual Magnitude": 14.786,
    "Spectral Type": "dM6  e",
    "Color Index": 1.9,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.262649,
    "y": -0.179022,
    "z": 2.205274,
    "vx": -0.00003953,
    "vy": 0.00000443,
    "vz": -0.0000731,
    "rarad": 6.20422115120036,
    "decrad": 0.77099787597905,
    "pmrarad": 4.08504007236111e-7,
    "pmdecrad": -0.000007794800419,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119585,
    "lum": 0.000106071814963531,
    "ra": 23.698379,
    "dec": 44.174924,
    "pmra": 84.26,
    "pmdec": -1607.79,
    "rv": -77.7,
    "Asset Name": "ASTRID 000012",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Unkempt",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003953,
    "y_1": 0.00000443,
    "z_1": -0.0000731,
    "Distance in Parsecs_1": 3.1686,
    "HYG Star ID_1": 119585,
    "Gliese Catalog_1": "Gl 905"
  },
  {
    "Astrid #": "ASTRID 000013",
    "HYG Star ID": 16496,
    "Hipparcos Catalogue": 16537,
    "Henry Draper Catalogue": 22049,
    "Harvard Revised Catalogue": 1084,
    "Gliese Catalog": "Gl 144",
    "Distance in Parsecs": 3.2161,
    "mag": 3.72,
    "Absolute Visual Magnitude": 6.183,
    "Spectral Type": "K2V",
    "Color Index": 0.881,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.89897,
    "y": 2.541426,
    "z": -0.528523,
    "vx": 0.00002243,
    "vy": 0.00000458,
    "vz": -0.00000256,
    "rarad": 0.929086175246353,
    "decrad": -0.165077815691092,
    "pmrarad": -0.00000473391470241666,
    "pmdecrad": 8.7121018e-8,
    "bayer": "Eps",
    "flam": 18,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 16496,
    "lum": 0.292954382976943,
    "var": "Eps",
    "var_min": 3.735,
    "var_max": 3.715,
    "bf": "18Eps Eri",
    "ra": 3.548848,
    "dec": -9.458262,
    "pmra": -976.44,
    "pmdec": 17.97,
    "rv": 16.9,
    "Asset Name": "ASTRID 000013",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Charmer",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00002243,
    "y_1": 0.00000458,
    "z_1": -0.00000256,
    "Distance in Parsecs_1": 3.2161,
    "HYG Star ID_1": 16496,
    "Hipparcos Catalogue_1": 16537,
    "Henry Draper Catalogue_1": 22049,
    "Harvard Revised Catalogue_1": 1084,
    "Gliese Catalog_1": "Gl 144"
  },
  {
    "Astrid #": "ASTRID 000014",
    "HYG Star ID": 113687,
    "Hipparcos Catalogue": 114046,
    "Henry Draper Catalogue": 217987,
    "Gliese Catalog": "Gl 887",
    "Proper Name": "Lacaille 9352",
    "Distance in Parsecs": 3.2759,
    "mag": 7.35,
    "Absolute Visual Magnitude": 9.773,
    "Spectral Type": "M2/M3V",
    "Color Index": 1.483,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.581532,
    "y": -0.621353,
    "z": -1.918773,
    "vx": 0.00004484,
    "vy": 0.00009974,
    "vz": 0.00001138,
    "rarad": 6.04691953826993,
    "decrad": -0.625754162494727,
    "pmrarad": 0.0000328086022787638,
    "pmdecrad": 0.000006431829174,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 113687,
    "lum": 0.0107349493580523,
    "ra": 23.097531,
    "dec": -35.853073,
    "pmra": 6767.26,
    "pmdec": 1326.66,
    "rv": 9.5,
    "Asset Name": "ASTRID 000014",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Anxious Laugh",
    "Hair": "Astro Helmet",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Lacaille 9352",
    "Constellation": "None",
    "x_1": 0.00004484,
    "y_1": 0.00009974,
    "z_1": 0.00001138,
    "Distance in Parsecs_1": 3.2759,
    "HYG Star ID_1": 113687,
    "Hipparcos Catalogue_1": 114046,
    "Henry Draper Catalogue_1": 217987,
    "Gliese Catalog_1": "Gl 887"
  },
  {
    "Astrid #": "ASTRID 000015",
    "HYG Star ID": 57375,
    "Hipparcos Catalogue": 57548,
    "Gliese Catalog": "Gl 447",
    "Distance in Parsecs": 3.3553,
    "mag": 11.12,
    "Absolute Visual Magnitude": 13.491,
    "Spectral Type": "M4.5V",
    "Color Index": 1.746,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.349892,
    "y": 0.179372,
    "z": 0.047111,
    "vx": 0.00003095,
    "vy": -0.00001152,
    "vz": -0.00002028,
    "rarad": 3.08809804212535,
    "decrad": 0.0140422688039262,
    "pmrarad": 0.00000293612861218055,
    "pmdecrad": -0.000005910993837,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 57375,
    "lum": 0.000349623004002701,
    "var_min": 11.171,
    "var_max": 11.041,
    "ra": 11.795666,
    "dec": 0.804563,
    "pmra": 605.62,
    "pmdec": -1219.23,
    "rv": -31.1,
    "Asset Name": "ASTRID 000015",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Honest Lad",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00003095,
    "y_1": -0.00001152,
    "z_1": -0.00002028,
    "Distance in Parsecs_1": 3.3553,
    "HYG Star ID_1": 57375,
    "Hipparcos Catalogue_1": 57548,
    "Gliese Catalog_1": "Gl 447"
  },
  {
    "Astrid #": "ASTRID 000016",
    "HYG Star ID": 119518,
    "Gliese Catalog": "Gl 866A",
    "Distance in Parsecs": 3.3979,
    "mag": 12.66,
    "Absolute Visual Magnitude": 15.004,
    "Spectral Type": "M5  e",
    "Color Index": 1.98,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.069893,
    "y": -1.139264,
    "z": -0.895949,
    "vx": -0.0000328,
    "vy": 0.00005369,
    "vz": 0.0000517,
    "rarad": 5.92780878124042,
    "decrad": -0.26707974928385,
    "pmrarad": 0.0000114482448085347,
    "pmdecrad": 0.000010826054323,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119518,
    "base": "Gl 866",
    "lum": 0.0000867760744991145,
    "ra": 22.642562,
    "dec": -15.302542,
    "pmra": 2361.37,
    "pmdec": 2233.03,
    "rv": -60,
    "Asset Name": "ASTRID 000016",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Hachimaki",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000328,
    "y_1": 0.00005369,
    "z_1": 0.0000517,
    "Distance in Parsecs_1": 3.3979,
    "HYG Star ID_1": 119518,
    "Gliese Catalog_1": "Gl 866A"
  },
  {
    "Astrid #": "ASTRID 000017",
    "HYG Star ID": 91487,
    "Hipparcos Catalogue": 91772,
    "Henry Draper Catalogue": 173740,
    "Gliese Catalog": "Gl 725B",
    "Distance in Parsecs": 3.4545,
    "mag": 9.7,
    "Absolute Visual Magnitude": 12.008,
    "Spectral Type": "K5",
    "Color Index": 1.561,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.324167,
    "y": -1.716358,
    "z": 2.980381,
    "vx": -0.00002776,
    "vy": 0.00002126,
    "vz": 0.00001669,
    "rarad": 4.89911717078827,
    "decrad": 1.04068479707602,
    "pmrarad": -0.0000067544241975,
    "pmdecrad": 0.000008948351546,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 91484,
    "base": "Gl 725",
    "lum": 0.00137025059712424,
    "ra": 18.713249,
    "dec": 59.626847,
    "pmra": -1393.2,
    "pmdec": 1845.73,
    "rv": 1.2,
    "Asset Name": "ASTRID 000017",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Innocent",
    "Outfit": "River Spirit",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00002776,
    "y_1": 0.00002126,
    "z_1": 0.00001669,
    "Distance in Parsecs_1": 3.4545,
    "HYG Star ID_1": 91487,
    "Hipparcos Catalogue_1": 91772,
    "Henry Draper Catalogue_1": 173740,
    "Gliese Catalog_1": "Gl 725B"
  },
  {
    "Astrid #": "ASTRID 000018",
    "HYG Star ID": 103879,
    "Hipparcos Catalogue": 104214,
    "Henry Draper Catalogue": 201091,
    "Harvard Revised Catalogue": 8085,
    "Gliese Catalog": "Gl 820A",
    "Distance in Parsecs": 3.4865,
    "mag": 5.2,
    "Absolute Visual Magnitude": 7.488,
    "Spectral Type": "K5V",
    "Color Index": 1.069,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.979356,
    "y": -1.863636,
    "z": 2.181892,
    "vx": -0.00001456,
    "vy": 0.0001102,
    "vz": 0.00000149,
    "rarad": 5.52784016431585,
    "decrad": 0.676304880439056,
    "pmrarad": 0.0000201444932407638,
    "pmdecrad": 0.000015799593035,
    "flam": 61,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 103879,
    "base": "Gl 820",
    "lum": 0.0880643228418064,
    "var": "V1803",
    "var_min": 5.682,
    "var_max": 5.002,
    "bf": "61    Cyg",
    "ra": 21.114794,
    "dec": 38.749415,
    "pmra": 4155.1,
    "pmdec": 3258.9,
    "rv": -64.8,
    "Asset Name": "ASTRID 000018",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Fire",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00001456,
    "y_1": 0.0001102,
    "z_1": 0.00000149,
    "Distance in Parsecs_1": 3.4865,
    "HYG Star ID_1": 103879,
    "Hipparcos Catalogue_1": 104214,
    "Henry Draper Catalogue_1": 201091,
    "Harvard Revised Catalogue_1": 8085,
    "Gliese Catalog_1": "Gl 820A"
  },
  {
    "Astrid #": "ASTRID 000019",
    "HYG Star ID": 118493,
    "Gliese Catalog": "Gl 280B",
    "Distance in Parsecs": 3.4974,
    "mag": 10.7,
    "Absolute Visual Magnitude": 12.981,
    "Spectral Type": "DA",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.462274,
    "y": 3.161021,
    "z": 0.31861,
    "vx": 0.00001014,
    "vy": 0.00000644,
    "vz": -0.00001725,
    "rarad": 2.00407592997979,
    "decrad": 0.09122528157145,
    "pmrarad": -0.00000340504040401388,
    "pmdecrad": -0.000004954388571,
    "con": "CMi",
    "Full Constellation Name Formula 1": "Canis Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Minor",
    "comp": 2,
    "comp_primary": 37173,
    "base": "Gl 280",
    "lum": 0.000559242283040899,
    "ra": 7.655006,
    "dec": 5.226824,
    "pmra": -702.34,
    "pmdec": -1021.92,
    "rv": 0,
    "Asset Name": "ASTRID 000019",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Scared",
    "Hair": "Bangs",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Minor",
    "x_1": 0.00001014,
    "y_1": 0.00000644,
    "z_1": -0.00001725,
    "Distance in Parsecs_1": 3.4974,
    "HYG Star ID_1": 118493,
    "Gliese Catalog_1": "Gl 280B"
  },
  {
    "Astrid #": "ASTRID 000020",
    "HYG Star ID": 103883,
    "Hipparcos Catalogue": 104217,
    "Henry Draper Catalogue": 201092,
    "Harvard Revised Catalogue": 8086,
    "Gliese Catalog": "Gl 820B",
    "Distance in Parsecs": 3.498,
    "mag": 6.05,
    "Absolute Visual Magnitude": 8.331,
    "Spectral Type": "K7V",
    "Color Index": 1.309,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.986273,
    "y": -1.869789,
    "z": 2.188742,
    "vx": -0.00001387,
    "vy": 0.00010874,
    "vz": 4.4e-7,
    "rarad": 5.52793685482475,
    "decrad": 0.676176415365582,
    "pmrarad": 0.0000199132371151388,
    "pmdecrad": 0.000015241184638,
    "flam": 61,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 103879,
    "base": "Gl 820",
    "lum": 0.0405135220229263,
    "bf": "61    Cyg",
    "ra": 21.115163,
    "dec": 38.742055,
    "pmra": 4107.4,
    "pmdec": 3143.72,
    "rv": -64.3,
    "Asset Name": "ASTRID 000020",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Maniacal",
    "Hair": "Innocent",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00001387,
    "y_1": 0.00010874,
    "z_1": 4.4e-7,
    "Distance in Parsecs_1": 3.498,
    "HYG Star ID_1": 103883,
    "Hipparcos Catalogue_1": 104217,
    "Henry Draper Catalogue_1": 201092,
    "Harvard Revised Catalogue_1": 8086,
    "Gliese Catalog_1": "Gl 820B"
  },
  {
    "Astrid #": "ASTRID 000021",
    "HYG Star ID": 37173,
    "Hipparcos Catalogue": 37279,
    "Henry Draper Catalogue": 61421,
    "Harvard Revised Catalogue": 2943,
    "Gliese Catalog": "Gl 280A",
    "Proper Name": "Procyon",
    "Distance in Parsecs": 3.5142,
    "mag": 0.4,
    "Absolute Visual Magnitude": 2.671,
    "Spectral Type": "F5IV-V",
    "Color Index": 0.432,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.469312,
    "y": 3.17617,
    "z": 0.320024,
    "vx": 0.00001212,
    "vy": 0.00000289,
    "vz": -0.00001792,
    "rarad": 2.00408304124283,
    "decrad": 0.09119333187796,
    "pmrarad": -0.00000347402939075694,
    "pmdecrad": -0.000005015785376,
    "bayer": "Alp",
    "flam": 10,
    "con": "CMi",
    "Full Constellation Name Formula 1": "Canis Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Minor",
    "comp": 1,
    "comp_primary": 37173,
    "base": "Gl 280",
    "lum": 7.44046366191919,
    "bf": "10Alp CMi",
    "ra": 7.655033,
    "dec": 5.224993,
    "pmra": -716.57,
    "pmdec": -1034.58,
    "rv": -4,
    "Asset Name": "ASTRID 000021",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Laughing",
    "Hair": "Mech Head",
    "Outfit": "Mecha",
    "Special": "Mech Wings",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Procyon",
    "Constellation": "Canis Minor",
    "x_1": 0.00001212,
    "y_1": 0.00000289,
    "z_1": -0.00001792,
    "Distance in Parsecs_1": 3.5142,
    "HYG Star ID_1": 37173,
    "Hipparcos Catalogue_1": 37279,
    "Henry Draper Catalogue_1": 61421,
    "Harvard Revised Catalogue_1": 2943,
    "Gliese Catalog_1": "Gl 280A"
  },
  {
    "Astrid #": "ASTRID 000022",
    "HYG Star ID": 117979,
    "Gliese Catalog": "Gl 15B",
    "Distance in Parsecs": 3.568,
    "mag": 11.06,
    "Absolute Visual Magnitude": 13.298,
    "Spectral Type": "M6 Ve",
    "Color Index": 1.79,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.557764,
    "y": 0.20548,
    "z": 2.479994,
    "vx": -6.3e-7,
    "vy": 0.00004993,
    "vz": 0.00001319,
    "rarad": 0.07989071130171,
    "decrad": 0.76835505395905,
    "pmrarad": 0.0000139638460342013,
    "pmdecrad": 0.000002012219181,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 2,
    "comp_primary": 1472,
    "base": "Gl  15",
    "lum": 0.000417637992950973,
    "ra": 0.30516,
    "dec": 44.023502,
    "pmra": 2880.25,
    "pmdec": 415.05,
    "rv": 11.3,
    "Asset Name": "ASTRID 000022",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Naughty Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -6.3e-7,
    "y_1": 0.00004993,
    "z_1": 0.00001319,
    "Distance in Parsecs_1": 3.568,
    "HYG Star ID_1": 117979,
    "Gliese Catalog_1": "Gl 15B"
  },
  {
    "Astrid #": "ASTRID 000023",
    "HYG Star ID": 91484,
    "Hipparcos Catalogue": 91768,
    "Henry Draper Catalogue": 173739,
    "Gliese Catalog": "Gl 725A",
    "Distance in Parsecs": 3.5691,
    "mag": 8.94,
    "Absolute Visual Magnitude": 11.177,
    "Spectral Type": "K5",
    "Color Index": 1.504,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.334856,
    "y": -1.773105,
    "z": 3.079349,
    "vx": -0.00002763,
    "vy": 0.00002258,
    "vz": 0.00001506,
    "rarad": 4.89909872516206,
    "decrad": 1.04074674408762,
    "pmrarad": -0.00000643289576455555,
    "pmdecrad": 0.0000087369243,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 91484,
    "base": "Gl 725",
    "lum": 0.00294577790233945,
    "var_min": 9.149,
    "var_max": 8.809,
    "ra": 18.713179,
    "dec": 59.630396,
    "pmra": -1326.88,
    "pmdec": 1802.12,
    "rv": -0.8,
    "Asset Name": "ASTRID 000023",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Braid",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00002763,
    "y_1": 0.00002258,
    "z_1": 0.00001506,
    "Distance in Parsecs_1": 3.5691,
    "HYG Star ID_1": 91484,
    "Hipparcos Catalogue_1": 91768,
    "Henry Draper Catalogue_1": 173739,
    "Gliese Catalog_1": "Gl 725A"
  },
  {
    "Astrid #": "ASTRID 000024",
    "HYG Star ID": 1472,
    "Hipparcos Catalogue": 1475,
    "Henry Draper Catalogue": 1326,
    "Gliese Catalog": "Gl 15A",
    "Distance in Parsecs": 3.5873,
    "mag": 8.09,
    "Absolute Visual Magnitude": 10.316,
    "Spectral Type": "M1V",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.571275,
    "y": 0.20667,
    "z": 2.493057,
    "vx": -1.7e-7,
    "vy": 0.00005039,
    "vz": 0.00001366,
    "rarad": 0.0801562054445776,
    "decrad": 0.768345490295078,
    "pmrarad": 0.0000140058793803055,
    "pmdecrad": 0.000001990548009,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 1472,
    "base": "Gl  15",
    "lum": 0.0065102849843486,
    "var": "GX",
    "var_min": 8.125,
    "var_max": 8.065,
    "ra": 0.306174,
    "dec": 44.022954,
    "pmra": 2888.92,
    "pmdec": 410.58,
    "rv": 12,
    "Asset Name": "ASTRID 000024",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Bob",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -1.7e-7,
    "y_1": 0.00005039,
    "z_1": 0.00001366,
    "Distance in Parsecs_1": 3.5873,
    "HYG Star ID_1": 1472,
    "Hipparcos Catalogue_1": 1475,
    "Henry Draper Catalogue_1": 1326,
    "Gliese Catalog_1": "Gl 15A"
  },
  {
    "Astrid #": "ASTRID 000025",
    "HYG Star ID": 108526,
    "Hipparcos Catalogue": 108870,
    "Henry Draper Catalogue": 209100,
    "Harvard Revised Catalogue": 8387,
    "Gliese Catalog": "Gl 845",
    "Distance in Parsecs": 3.6224,
    "mag": 4.69,
    "Absolute Visual Magnitude": 6.895,
    "Spectral Type": "K5V",
    "Color Index": 1.056,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.732589,
    "y": -0.966715,
    "z": -3.030307,
    "vx": -0.00001843,
    "vy": 0.00008993,
    "vz": 0.00001014,
    "rarad": 5.77411272366767,
    "decrad": -0.991102262631911,
    "pmrarad": 0.0000191984763058958,
    "pmdecrad": -0.000012308643647,
    "bayer": "Eps",
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 108526,
    "lum": 0.152054752973249,
    "bf": "Eps Ind",
    "ra": 22.055486,
    "dec": -56.785977,
    "pmra": 3959.97,
    "pmdec": -2538.84,
    "rv": -40.4,
    "Asset Name": "ASTRID 000025",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "High Fade",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00001843,
    "y_1": 0.00008993,
    "z_1": 0.00001014,
    "Distance in Parsecs_1": 3.6224,
    "HYG Star ID_1": 108526,
    "Hipparcos Catalogue_1": 108870,
    "Henry Draper Catalogue_1": 209100,
    "Harvard Revised Catalogue_1": 8387,
    "Gliese Catalog_1": "Gl 845"
  },
  {
    "Astrid #": "ASTRID 000026",
    "HYG Star ID": 118541,
    "Gliese Catalog": "GJ 1111",
    "Distance in Parsecs": 3.6258,
    "mag": 14.81,
    "Absolute Visual Magnitude": 17.013,
    "Spectral Type": "M6.5",
    "Color Index": 2.06,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.968452,
    "y": 2.569351,
    "z": 1.633464,
    "vx": 0.0000158,
    "vy": 0.00001236,
    "vz": -0.00001174,
    "rarad": 2.22457321013049,
    "decrad": 0.46737355483345,
    "pmrarad": -0.00000553225739018749,
    "pmdecrad": -0.000002916823331,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118541,
    "lum": 0.0000136395486750663,
    "ra": 8.497244,
    "dec": 26.778532,
    "pmra": -1141.11,
    "pmdec": -601.64,
    "rv": -5,
    "Asset Name": "ASTRID 000026",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Headband",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000158,
    "y_1": 0.00001236,
    "z_1": -0.00001174,
    "Distance in Parsecs_1": 3.6258,
    "HYG Star ID_1": 118541,
    "Gliese Catalog_1": "GJ 1111"
  },
  {
    "Astrid #": "ASTRID 000027",
    "HYG Star ID": 8087,
    "Hipparcos Catalogue": 8102,
    "Henry Draper Catalogue": 10700,
    "Harvard Revised Catalogue": 509,
    "Gliese Catalog": "Gl 71",
    "Distance in Parsecs": 3.6502,
    "mag": 3.49,
    "Absolute Visual Magnitude": 5.678,
    "Spectral Type": "G8V",
    "Color Index": 0.727,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.154083,
    "y": 1.539508,
    "z": -1.00226,
    "vx": 0.00000207,
    "vy": -0.00003289,
    "vz": 0.00001931,
    "rarad": 0.454085440334497,
    "decrad": -0.278161501328241,
    "pmrarad": -0.00000834761891454166,
    "pmdecrad": 0.000004140648201,
    "bayer": "Tau",
    "flam": 52,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 8087,
    "lum": 0.466444525203779,
    "bf": "52Tau Cet",
    "ra": 1.734479,
    "dec": -15.93748,
    "pmra": -1721.82,
    "pmdec": 854.07,
    "rv": -17,
    "Asset Name": "ASTRID 000027",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Innocent",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000207,
    "y_1": -0.00003289,
    "z_1": 0.00001931,
    "Distance in Parsecs_1": 3.6502,
    "HYG Star ID_1": 8087,
    "Hipparcos Catalogue_1": 8102,
    "Henry Draper Catalogue_1": 10700,
    "Harvard Revised Catalogue_1": 509,
    "Gliese Catalog_1": "Gl 71"
  },
  {
    "Astrid #": "ASTRID 000028",
    "HYG Star ID": 5632,
    "Hipparcos Catalogue": 5643,
    "Gliese Catalog": "Gl 54.1",
    "Distance in Parsecs": 3.6899,
    "mag": 12.1,
    "Absolute Visual Magnitude": 14.265,
    "Spectral Type": "M5.5Ve",
    "Color Index": 1.85,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.353772,
    "y": 1.097975,
    "z": -1.078832,
    "vx": 0.00002251,
    "vy": 0.00003014,
    "vz": 0.00000269,
    "rarad": 0.316385148466336,
    "decrad": -0.296687945606572,
    "pmrarad": 0.00000586668186703472,
    "pmdecrad": 0.000003136502106,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 5632,
    "lum": 0.000171395730750842,
    "var": "YZ",
    "var_min": 12.201,
    "var_max": 12.001,
    "ra": 1.208502,
    "dec": -16.998967,
    "pmra": 1210.09,
    "pmdec": 646.95,
    "rv": 28,
    "Asset Name": "ASTRID 000028",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Straw Hat",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00002251,
    "y_1": 0.00003014,
    "z_1": 0.00000269,
    "Distance in Parsecs_1": 3.6899,
    "HYG Star ID_1": 5632,
    "Hipparcos Catalogue_1": 5643,
    "Gliese Catalog_1": "Gl 54.1"
  },
  {
    "Astrid #": "ASTRID 000029",
    "HYG Star ID": 82472,
    "Hipparcos Catalogue": 82724,
    "Distance in Parsecs": 3.6964,
    "mag": 11.85,
    "Absolute Visual Magnitude": 14.011,
    "Spectral Type": "Mp",
    "Color Index": 1.729,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.482554,
    "y": -1.64315,
    "z": -3.275755,
    "vx": 0.00000414,
    "vy": -0.00000462,
    "vz": 0.00000171,
    "rarad": 4.42672995676989,
    "decrad": -1.08908081229243,
    "pmrarad": 0.00000142763084513194,
    "pmdecrad": 9.9619515e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 82472,
    "lum": 0.000216570849471007,
    "ra": 16.908863,
    "dec": -62.399734,
    "pmra": 294.47,
    "pmdec": 205.48,
    "rv": 0,
    "Asset Name": "ASTRID 000029",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Afro",
    "Outfit": "Astro",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00000414,
    "y_1": -0.00000462,
    "z_1": 0.00000171,
    "Distance in Parsecs_1": 3.6964,
    "HYG Star ID_1": 82472,
    "Hipparcos Catalogue_1": 82724
  },
  {
    "Astrid #": "ASTRID 000030",
    "HYG Star ID": 36107,
    "Hipparcos Catalogue": 36208,
    "Gliese Catalog": "Gl 273",
    "Proper Name": "Luyten's Star",
    "Distance in Parsecs": 3.8026,
    "mag": 9.84,
    "Absolute Visual Magnitude": 11.94,
    "Spectral Type": "M5",
    "Color Index": 1.573,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.409549,
    "y": 3.514856,
    "z": 0.346358,
    "vx": -0.00001899,
    "vy": 0.00001905,
    "vz": -0.00006612,
    "rarad": 1.95218696195723,
    "decrad": 0.0912071574928112,
    "pmrarad": 0.00000276959511290972,
    "pmdecrad": -0.000017910229393,
    "con": "CMi",
    "Full Constellation Name Formula 1": "Canis Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Minor",
    "comp": 1,
    "comp_primary": 36107,
    "lum": 0.00145881426027534,
    "ra": 7.456805,
    "dec": 5.225785,
    "pmra": 571.27,
    "pmdec": -3694.25,
    "rv": 18.2,
    "Asset Name": "ASTRID 000030",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Upset",
    "Hair": "Nemes",
    "Outfit": "Pharaoh",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Luyten's Star",
    "Constellation": "Canis Minor",
    "x_1": -0.00001899,
    "y_1": 0.00001905,
    "z_1": -0.00006612,
    "Distance in Parsecs_1": 3.8026,
    "HYG Star ID_1": 36107,
    "Hipparcos Catalogue_1": 36208,
    "Gliese Catalog_1": "Gl 273"
  },
  {
    "Astrid #": "ASTRID 000031",
    "HYG Star ID": 24129,
    "Hipparcos Catalogue": 24186,
    "Henry Draper Catalogue": 33793,
    "Gliese Catalog": "Gl 191",
    "Proper Name": "Kapteyn's Star",
    "Distance in Parsecs": 3.9114,
    "mag": 8.86,
    "Absolute Visual Magnitude": 10.898,
    "Spectral Type": "M0V",
    "Color Index": 1.543,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.578997,
    "y": 2.705174,
    "z": -2.76822,
    "vx": -0.00009951,
    "vy": 0.0001243,
    "vz": -0.00025439,
    "rarad": 1.35983023942905,
    "decrad": -0.785719603424543,
    "pmrarad": 0.0000315422204637847,
    "pmdecrad": -0.000027786562805,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 24129,
    "lum": 0.00380890376498135,
    "var": "VZ",
    "var_min": 9.148,
    "var_max": 8.828,
    "ra": 5.194169,
    "dec": -45.018417,
    "pmra": 6506.05,
    "pmdec": -5731.39,
    "rv": 245.5,
    "Asset Name": "ASTRID 000031",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Big Laugh",
    "Hair": "Straw Hat",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Kapteyn's Star",
    "Constellation": "Pictor",
    "x_1": -0.00009951,
    "y_1": 0.0001243,
    "z_1": -0.00025439,
    "Distance in Parsecs_1": 3.9114,
    "HYG Star ID_1": 24129,
    "Hipparcos Catalogue_1": 24186,
    "Henry Draper Catalogue_1": 33793,
    "Gliese Catalog_1": "Gl 191"
  },
  {
    "Astrid #": "ASTRID 000032",
    "HYG Star ID": 104751,
    "Hipparcos Catalogue": 105090,
    "Henry Draper Catalogue": 202560,
    "Gliese Catalog": "Gl 825",
    "Proper Name": "Lacaille 8760",
    "Distance in Parsecs": 3.9462,
    "mag": 6.69,
    "Absolute Visual Magnitude": 8.709,
    "Spectral Type": "M1/M2V",
    "Color Index": 1.397,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.330004,
    "y": -2.003156,
    "z": -2.476477,
    "vx": -0.00003405,
    "vy": -0.00005295,
    "vz": -0.00003518,
    "rarad": 5.57311337431881,
    "decrad": -0.678363442688797,
    "pmrarad": -0.0000158000778493055,
    "pmdecrad": -0.000005560764434,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 104751,
    "lum": 0.0286022369395428,
    "var": "AX",
    "var_min": 6.707,
    "var_max": 6.667,
    "ra": 21.287725,
    "dec": -38.867362,
    "pmra": -3259,
    "pmdec": -1146.99,
    "rv": 28.2,
    "Asset Name": "ASTRID 000032",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Scared",
    "Hair": "Antlers",
    "Outfit": "Wizard",
    "Special": "Faerie",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Lacaille 8760",
    "Constellation": "Microscopium",
    "x_1": -0.00003405,
    "y_1": -0.00005295,
    "z_1": -0.00003518,
    "Distance in Parsecs_1": 3.9462,
    "HYG Star ID_1": 104751,
    "Hipparcos Catalogue_1": 105090,
    "Henry Draper Catalogue_1": 202560,
    "Gliese Catalog_1": "Gl 825"
  },
  {
    "Astrid #": "ASTRID 000033",
    "HYG Star ID": 110547,
    "Hipparcos Catalogue": 110893,
    "Henry Draper Catalogue": 239960,
    "Gliese Catalog": "Gl 860A",
    "Proper Name": "Kruger 60",
    "Distance in Parsecs": 4.001,
    "mag": 9.59,
    "Absolute Visual Magnitude": 11.579,
    "Spectral Type": "M2V",
    "Color Index": 1.613,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.968033,
    "y": -0.83547,
    "z": 3.381486,
    "vx": -0.00001624,
    "vy": -0.00001144,
    "vz": -0.00003367,
    "rarad": 5.88175302280169,
    "decrad": 1.00698298237407,
    "pmrarad": -0.00000421899409229861,
    "pmdecrad": -0.000002283957249,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 110547,
    "base": "Gl 860",
    "lum": 0.00203422974321172,
    "var": "DO",
    "var_min": 9.628,
    "var_max": 9.548,
    "ra": 22.466642,
    "dec": 57.695875,
    "pmra": -870.23,
    "pmdec": -471.1,
    "rv": -33.3,
    "Asset Name": "ASTRID 000033",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Oh no",
    "Hair": "King's Crown",
    "Outfit": "Emperor",
    "Special": "None",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Kruger 60",
    "Constellation": "Cepheus",
    "x_1": -0.00001624,
    "y_1": -0.00001144,
    "z_1": -0.00003367,
    "Distance in Parsecs_1": 4.001,
    "HYG Star ID_1": 110547,
    "Hipparcos Catalogue_1": 110893,
    "Henry Draper Catalogue_1": 239960,
    "Gliese Catalog_1": "Gl 860A"
  },
  {
    "Astrid #": "ASTRID 000034",
    "HYG Star ID": 119505,
    "Gliese Catalog": "Gl 860B",
    "Distance in Parsecs": 4.0077,
    "mag": 11.3,
    "Absolute Visual Magnitude": 13.286,
    "Spectral Type": "M6 V",
    "Color Index": 1.8,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.97065,
    "y": -0.836546,
    "z": 3.386059,
    "vx": -0.00001651,
    "vy": -0.00001103,
    "vz": -0.00003147,
    "rarad": 5.88191668672175,
    "decrad": 1.0069960424762,
    "pmrarad": -0.00000414360556497222,
    "pmdecrad": -0.000001897090476,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 2,
    "comp_primary": 110547,
    "base": "Gl 860",
    "lum": 0.000422279501306056,
    "ra": 22.467267,
    "dec": 57.696623,
    "pmra": -854.68,
    "pmdec": -391.3,
    "rv": -31.7,
    "Asset Name": "ASTRID 000034",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Wavy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001651,
    "y_1": -0.00001103,
    "z_1": -0.00003147,
    "Distance in Parsecs_1": 4.0077,
    "HYG Star ID_1": 119505,
    "Gliese Catalog_1": "Gl 860B"
  },
  {
    "Astrid #": "ASTRID 000035",
    "HYG Star ID": 72284,
    "Hipparcos Catalogue": 72511,
    "Gliese Catalog": "Gl 563.2B",
    "Distance in Parsecs": 4.034,
    "mag": 11.72,
    "Absolute Visual Magnitude": 13.691,
    "Spectral Type": "M",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.675893,
    "y": -2.442217,
    "z": -1.775248,
    "vx": -0.00004903,
    "vy": -0.00000796,
    "vz": -0.00001742,
    "rarad": 3.88137245926246,
    "decrad": -0.455630482898295,
    "pmrarad": -0.00000673745571868055,
    "pmdecrad": 6.58183052e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 2,
    "comp_primary": 72283,
    "base": "Gl 563.2",
    "lum": 0.000290803748273531,
    "var_min": 11.84,
    "var_max": 11.53,
    "ra": 14.825751,
    "dec": -26.105704,
    "pmra": -1389.7,
    "pmdec": 135.76,
    "rv": 44,
    "Asset Name": "ASTRID 000035",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Malevolent",
    "Hair": "Grecian",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00004903,
    "y_1": -0.00000796,
    "z_1": -0.00001742,
    "Distance in Parsecs_1": 4.034,
    "HYG Star ID_1": 72284,
    "Hipparcos Catalogue_1": 72511,
    "Gliese Catalog_1": "Gl 563.2B"
  },
  {
    "Astrid #": "ASTRID 000036",
    "HYG Star ID": 118428,
    "Gliese Catalog": "Gl 234B",
    "Distance in Parsecs": 4.1171,
    "mag": 14.6,
    "Absolute Visual Magnitude": 16.527,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.526003,
    "y": 4.078362,
    "z": -0.201997,
    "vx": -0.00001382,
    "vy": -0.00000247,
    "vz": -0.00001391,
    "rarad": 1.69906211795715,
    "decrad": -0.04908262386045,
    "pmrarad": 0.00000340571914316666,
    "pmdecrad": -0.000003381997209,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 30847,
    "base": "Gl 234",
    "lum": 0.0000213402744284897,
    "ra": 6.489939,
    "dec": -2.812227,
    "pmra": 702.48,
    "pmdec": -697.59,
    "rv": 0,
    "Asset Name": "ASTRID 000036",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Innocent",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001382,
    "y_1": -0.00000247,
    "z_1": -0.00001391,
    "Distance in Parsecs_1": 4.1171,
    "HYG Star ID_1": 118428,
    "Gliese Catalog_1": "Gl 234B"
  },
  {
    "Astrid #": "ASTRID 000037",
    "HYG Star ID": 30847,
    "Hipparcos Catalogue": 30920,
    "Gliese Catalog": "Gl 234A",
    "Distance in Parsecs": 4.1268,
    "mag": 11.12,
    "Absolute Visual Magnitude": 13.042,
    "Spectral Type": "M4.5Ve",
    "Color Index": 1.69,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.527146,
    "y": 4.088126,
    "z": -0.202607,
    "vx": -0.00001589,
    "vy": 0.00001454,
    "vz": -0.0000132,
    "rarad": 1.69903441727822,
    "decrad": -0.0491131976009125,
    "pmrarad": 0.00000336814608292361,
    "pmdecrad": -0.00000299915439,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 30847,
    "base": "Gl 234",
    "lum": 0.00052868866585088,
    "var": "V577",
    "var_min": 11.176,
    "var_max": 11.066,
    "ra": 6.489833,
    "dec": -2.813979,
    "pmra": 694.73,
    "pmdec": -618.62,
    "rv": 16.7,
    "Asset Name": "ASTRID 000037",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Braid",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00001589,
    "y_1": 0.00001454,
    "z_1": -0.0000132,
    "Distance in Parsecs_1": 4.1268,
    "HYG Star ID_1": 30847,
    "Hipparcos Catalogue_1": 30920,
    "Gliese Catalog_1": "Gl 234A"
  },
  {
    "Astrid #": "ASTRID 000038",
    "HYG Star ID": 82473,
    "Hipparcos Catalogue": 82725,
    "Distance in Parsecs": 4.1465,
    "mag": 11.72,
    "Absolute Visual Magnitude": 13.632,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.541199,
    "y": -1.843027,
    "z": -3.674755,
    "vx": 0.00000404,
    "vy": -0.00000441,
    "vz": 0.00000162,
    "rarad": 4.42675828349679,
    "decrad": -1.08914338837704,
    "pmrarad": 0.0000012352567766875,
    "pmdecrad": 8.41442623e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 82473,
    "lum": 0.000307043565073006,
    "var_min": 12.005,
    "var_max": 11.545,
    "ra": 16.908971,
    "dec": -62.403319,
    "pmra": 254.79,
    "pmdec": 173.56,
    "rv": 0,
    "Asset Name": "ASTRID 000038",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Ram Horns",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00000404,
    "y_1": -0.00000441,
    "z_1": 0.00000162,
    "Distance in Parsecs_1": 4.1465,
    "HYG Star ID_1": 82473,
    "Hipparcos Catalogue_1": 82725
  },
  {
    "Astrid #": "ASTRID 000039",
    "HYG Star ID": 3820,
    "Hipparcos Catalogue": 3829,
    "Gliese Catalog": "Gl 35",
    "Proper Name": "Van Maanen's Star",
    "Distance in Parsecs": 4.2626,
    "mag": 12.37,
    "Absolute Visual Magnitude": 14.222,
    "Spectral Type": "DG",
    "Color Index": 0.554,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.146957,
    "y": 0.903519,
    "z": 0.400348,
    "vx": 0.00005345,
    "vy": 0.00003772,
    "vz": -0.00005057,
    "rarad": 0.21452251445702,
    "decrad": 0.0940489788001337,
    "pmrarad": 0.00000597799508809027,
    "pmdecrad": -0.000013141165699,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 3820,
    "lum": 0.000178319977223291,
    "var_min": 12.621,
    "var_max": 12.091,
    "ra": 0.819416,
    "dec": 5.38861,
    "pmra": 1233.05,
    "pmdec": -2710.56,
    "rv": 54,
    "Asset Name": "ASTRID 000039",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Maniacal",
    "Hair": "Mech Head",
    "Outfit": "Mecha",
    "Special": "Mech Wings",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Van Maanen's Star",
    "Constellation": "None",
    "x_1": 0.00005345,
    "y_1": 0.00003772,
    "z_1": -0.00005057,
    "Distance in Parsecs_1": 4.2626,
    "HYG Star ID_1": 3820,
    "Hipparcos Catalogue_1": 3829,
    "Gliese Catalog_1": "Gl 35"
  },
  {
    "Astrid #": "ASTRID 000040",
    "HYG Star ID": 118236,
    "Gliese Catalog": "GJ 1061",
    "Distance in Parsecs": 4.2918,
    "mag": 13.03,
    "Absolute Visual Magnitude": 14.867,
    "Spectral Type": "M4.5",
    "Color Index": 1.9,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.798507,
    "y": 2.475398,
    "z": -3.00808,
    "vx": -0.00002425,
    "vy": -0.00000762,
    "vz": 0.00000841,
    "rarad": 0.942400352767139,
    "decrad": -0.7768791555871,
    "pmrarad": 0.00000352619534278472,
    "pmdecrad": -0.000001938551742,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118236,
    "lum": 0.0000984464363980376,
    "ra": 3.599704,
    "dec": -44.511897,
    "pmra": 727.33,
    "pmdec": -399.86,
    "rv": -20,
    "Asset Name": "ASTRID 000040",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Heartbreaker",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002425,
    "y_1": -0.00000762,
    "z_1": 0.00000841,
    "Distance in Parsecs_1": 4.2918,
    "HYG Star ID_1": 118236,
    "Gliese Catalog_1": "GJ 1061"
  },
  {
    "Astrid #": "ASTRID 000041",
    "HYG Star ID": 80579,
    "Hipparcos Catalogue": 80824,
    "Gliese Catalog": "Gl 628",
    "Distance in Parsecs": 4.2922,
    "mag": 10.1,
    "Absolute Visual Magnitude": 11.937,
    "Spectral Type": "M4",
    "Color Index": 1.604,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.597477,
    "y": -3.871023,
    "z": -0.940865,
    "vx": 0.00000521,
    "vy": 0.00001773,
    "vz": -0.00002114,
    "rarad": 4.32100326495174,
    "decrad": -0.221003949876375,
    "pmrarad": -4.53834086368055e-7,
    "pmdecrad": -0.0000057445573,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 80579,
    "lum": 0.00146285068699985,
    "var": "V2306",
    "var_min": 10.143,
    "var_max": 10.063,
    "ra": 16.505017,
    "dec": -12.662594,
    "pmra": -93.61,
    "pmdec": -1184.9,
    "rv": -13,
    "Asset Name": "ASTRID 000041",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Good Boy",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00000521,
    "y_1": 0.00001773,
    "z_1": -0.00002114,
    "Distance in Parsecs_1": 4.2922,
    "HYG Star ID_1": 80579,
    "Hipparcos Catalogue_1": 80824,
    "Gliese Catalog_1": "Gl 628"
  },
  {
    "Astrid #": "ASTRID 000042",
    "HYG Star ID": 118830,
    "Gliese Catalog": "Gl 473A",
    "Distance in Parsecs": 4.3066,
    "mag": 13.04,
    "Absolute Visual Magnitude": 14.869,
    "Spectral Type": "dM5.5eJ",
    "Color Index": 1.83,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.208555,
    "y": -0.615564,
    "z": 0.675238,
    "vx": -0.00000468,
    "vy": 0.00003719,
    "vz": 0.00000481,
    "rarad": 3.28683311211424,
    "decrad": 0.1574411648826,
    "pmrarad": -0.00000870201771502777,
    "pmdecrad": 0.000001130197652,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118830,
    "base": "Gl 473",
    "lum": 0.0000982652582829531,
    "ra": 12.554778,
    "dec": 9.020714,
    "pmra": -1794.92,
    "pmdec": 233.12,
    "rv": 0,
    "Asset Name": "ASTRID 000042",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Windy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000468,
    "y_1": 0.00003719,
    "z_1": 0.00000481,
    "Distance in Parsecs_1": 4.3066,
    "HYG Star ID_1": 118830,
    "Gliese Catalog_1": "Gl 473A"
  },
  {
    "Astrid #": "ASTRID 000043",
    "HYG Star ID": 118831,
    "Gliese Catalog": "Gl 473B",
    "Distance in Parsecs": 4.3066,
    "mag": 13.3,
    "Absolute Visual Magnitude": 15.129,
    "Spectral Type": "M7",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.208555,
    "y": -0.615564,
    "z": 0.675238,
    "vx": -0.00000468,
    "vy": 0.00003719,
    "vz": 0.00000481,
    "rarad": 3.28683311211424,
    "decrad": 0.1574411648826,
    "pmrarad": -0.00000870201771502777,
    "pmdecrad": 0.000001130197652,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118830,
    "base": "Gl 473",
    "lum": 0.0000773392578048996,
    "ra": 12.554778,
    "dec": 9.020714,
    "pmra": -1794.92,
    "pmdec": 233.12,
    "rv": 0,
    "Asset Name": "ASTRID 000043",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Audiophile",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000468,
    "y_1": 0.00003719,
    "z_1": 0.00000481,
    "Distance in Parsecs_1": 4.3066,
    "HYG Star ID_1": 118831,
    "Gliese Catalog_1": "Gl 473B"
  },
  {
    "Astrid #": "ASTRID 000044",
    "HYG Star ID": 442,
    "Hipparcos Catalogue": 439,
    "Henry Draper Catalogue": 225213,
    "Gliese Catalog": "Gl 1",
    "Distance in Parsecs": 4.3399,
    "mag": 8.56,
    "Absolute Visual Magnitude": 10.373,
    "Spectral Type": "M2V",
    "Color Index": 1.462,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.448844,
    "y": 0.081384,
    "z": -2.633508,
    "vx": -0.00001398,
    "vy": 0.00011824,
    "vz": -0.00005331,
    "rarad": 0.0235313919936556,
    "decrad": -0.652008993213426,
    "pmrarad": 0.0000273147421320763,
    "pmdecrad": -0.000011334652963,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 442,
    "lum": 0.0061773185859152,
    "ra": 0.089883,
    "dec": -37.357364,
    "pmra": 5634.07,
    "pmdec": -2337.94,
    "rv": 22.9,
    "Asset Name": "ASTRID 000044",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Ninja",
    "Hair": "Round Brush",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00001398,
    "y_1": 0.00011824,
    "z_1": -0.00005331,
    "Distance in Parsecs_1": 4.3399,
    "HYG Star ID_1": 442,
    "Hipparcos Catalogue_1": 439,
    "Henry Draper Catalogue_1": 225213,
    "Gliese Catalog_1": "Gl 1"
  },
  {
    "Astrid #": "ASTRID 000045",
    "HYG Star ID": 118583,
    "Gliese Catalog": "NN 3522",
    "Distance in Parsecs": 4.4643,
    "mag": 10.89,
    "Absolute Visual Magnitude": 12.641,
    "Spectral Type": "k",
    "Color Index": 1.67,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.107711,
    "y": 3.136747,
    "z": 0.657969,
    "vx": -0.00000566,
    "vy": -0.0000042,
    "vz": -0.00000672,
    "rarad": 2.35154386287074,
    "decrad": 0.14792357815645,
    "pmrarad": 0.00000156405741484027,
    "pmdecrad": -0.000001520976871,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 118583,
    "lum": 0.00076489179100707,
    "ra": 8.982236,
    "dec": 8.475397,
    "pmra": 322.61,
    "pmdec": -313.72,
    "rv": 0,
    "Asset Name": "ASTRID 000045",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Braided Ponytail",
    "Outfit": "Super",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00000566,
    "y_1": -0.0000042,
    "z_1": -0.00000672,
    "Distance in Parsecs_1": 4.4643,
    "HYG Star ID_1": 118583,
    "Gliese Catalog_1": "NN 3522"
  },
  {
    "Astrid #": "ASTRID 000046",
    "HYG Star ID": 118100,
    "Gliese Catalog": "Gl 83.1",
    "Distance in Parsecs": 4.4683,
    "mag": 12.28,
    "Absolute Visual Magnitude": 14.029,
    "Spectral Type": "dM8  e",
    "Color Index": 1.8,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.76629,
    "y": 2.179192,
    "z": 1.008821,
    "vx": -0.00003131,
    "vy": 0.00000976,
    "vz": -0.00004449,
    "rarad": 0.524531583922085,
    "decrad": 0.227818533079499,
    "pmrarad": 0.00000539941844171527,
    "pmdecrad": -0.00000857413841,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118100,
    "lum": 0.000213010003741125,
    "ra": 2.003563,
    "dec": 13.05304,
    "pmra": 1113.71,
    "pmdec": -1768.54,
    "rv": -31,
    "Asset Name": "ASTRID 000046",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "High Bun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003131,
    "y_1": 0.00000976,
    "z_1": -0.00004449,
    "Distance in Parsecs_1": 4.4683,
    "HYG Star ID_1": 118100,
    "Gliese Catalog_1": "Gl 83.1"
  },
  {
    "Astrid #": "ASTRID 000047",
    "HYG Star ID": 118704,
    "Gliese Catalog": "NN 3618",
    "Distance in Parsecs": 4.4883,
    "mag": 13.92,
    "Absolute Visual Magnitude": 15.66,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.046475,
    "y": 0.699423,
    "z": -3.932885,
    "vx": -0.00002674,
    "vy": 0.00001696,
    "vz": 0.00001692,
    "rarad": 2.81235736449666,
    "decrad": -1.06802955870255,
    "pmrarad": -0.00000164953006672222,
    "pmdecrad": 0.000007827505949,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118704,
    "lum": 0.0000474241985260243,
    "ra": 10.742414,
    "dec": -61.193586,
    "pmra": -340.24,
    "pmdec": 1614.54,
    "rv": 0,
    "Asset Name": "ASTRID 000047",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Smoulder",
    "Hair": "Spiky",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002674,
    "y_1": 0.00001696,
    "z_1": 0.00001692,
    "Distance in Parsecs_1": 4.4883,
    "HYG Star ID_1": 118704,
    "Gliese Catalog_1": "NN 3618"
  },
  {
    "Astrid #": "ASTRID 000048",
    "HYG Star ID": 118707,
    "Gliese Catalog": "NN 3622",
    "Distance in Parsecs": 4.5249,
    "mag": 15.6,
    "Absolute Visual Magnitude": 17.322,
    "Spectral Type": "M6.5",
    "Color Index": 2.1,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.220385,
    "y": 1.367034,
    "z": -0.889411,
    "vx": 0.00000886,
    "vy": -0.00001673,
    "vz": -0.00003142,
    "rarad": 2.82834211005541,
    "decrad": -0.19786320771905,
    "pmrarad": 0.00000291402110834722,
    "pmdecrad": -0.000007397694381,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118707,
    "lum": 0.0000102612436519334,
    "ra": 10.803471,
    "dec": -11.336727,
    "pmra": 601.06,
    "pmdec": -1525.88,
    "rv": -7,
    "Asset Name": "ASTRID 000048",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Charmer",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000886,
    "y_1": -0.00001673,
    "z_1": -0.00003142,
    "Distance in Parsecs_1": 4.5249,
    "HYG Star ID_1": 118707,
    "Gliese Catalog_1": "NN 3622"
  },
  {
    "Astrid #": "ASTRID 000049",
    "HYG Star ID": 85899,
    "Hipparcos Catalogue": 86162,
    "Gliese Catalog": "Gl 687",
    "Distance in Parsecs": 4.5282,
    "mag": 9.15,
    "Absolute Visual Magnitude": 10.87,
    "Spectral Type": "M3.5Vvar",
    "Color Index": 1.505,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.171572,
    "y": -1.662506,
    "z": 4.208248,
    "vx": -0.00000876,
    "vy": -0.00001633,
    "vz": -0.00003234,
    "rarad": 4.60957594927898,
    "decrad": 1.19274302416929,
    "pmrarad": -0.00000155368240207638,
    "pmdecrad": -0.000006154952081,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 85899,
    "lum": 0.00390840895792402,
    "ra": 17.607283,
    "dec": 68.339141,
    "pmra": -320.47,
    "pmdec": -1269.55,
    "rv": -23.2,
    "Asset Name": "ASTRID 000049",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Middle Part",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000876,
    "y_1": -0.00001633,
    "z_1": -0.00003234,
    "Distance in Parsecs_1": 4.5282,
    "HYG Star ID_1": 85899,
    "Hipparcos Catalogue_1": 86162,
    "Gliese Catalog_1": "Gl 687"
  },
  {
    "Astrid #": "ASTRID 000050",
    "HYG Star ID": 85264,
    "Hipparcos Catalogue": 85523,
    "Gliese Catalog": "Gl 674",
    "Distance in Parsecs": 4.5405,
    "mag": 9.38,
    "Absolute Visual Magnitude": 11.094,
    "Spectral Type": "K5",
    "Color Index": 1.553,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.422874,
    "y": -3.073668,
    "z": -3.314975,
    "vx": 0.0000154,
    "vy": 0.00001935,
    "vz": -0.00000562,
    "rarad": 4.57565633992069,
    "decrad": -0.818475471866011,
    "pmrarad": 0.00000277953379336111,
    "pmdecrad": -0.000004265584687,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 85264,
    "lum": 0.00317980142769152,
    "ra": 17.47772,
    "dec": -46.89519,
    "pmra": 573.32,
    "pmdec": -879.84,
    "rv": -10.2,
    "Asset Name": "ASTRID 000050",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Neko Ninja",
    "Hair": "Ponytail",
    "Outfit": "Warlock",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.0000154,
    "y_1": 0.00001935,
    "z_1": -0.00000562,
    "Distance in Parsecs_1": 4.5405,
    "HYG Star ID_1": 85264,
    "Hipparcos Catalogue_1": 85523,
    "Gliese Catalog_1": "Gl 674"
  },
  {
    "Astrid #": "ASTRID 000051",
    "HYG Star ID": 57194,
    "Hipparcos Catalogue": 57367,
    "Gliese Catalog": "Gl 440",
    "Distance in Parsecs": 4.6081,
    "mag": 11.5,
    "Absolute Visual Magnitude": 13.182,
    "Spectral Type": "DC:",
    "Color Index": 0.196,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.955207,
    "y": 0.122023,
    "z": -4.170954,
    "vx": 0.00000327,
    "vy": -0.00005986,
    "vz": -0.00000329,
    "rarad": 3.07911118270173,
    "decrad": -1.13169796494377,
    "pmrarad": 0.0000129211087700625,
    "pmdecrad": -0.000001681479288,
    "con": "Mus",
    "Full Constellation Name Formula 1": "Musca",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Musca",
    "comp": 1,
    "comp_primary": 57194,
    "lum": 0.000464729241676203,
    "var_min": 11.575,
    "var_max": 11.405,
    "ra": 11.761338,
    "dec": -64.841517,
    "pmra": 2665.17,
    "pmdec": -346.83,
    "rv": 0,
    "Asset Name": "ASTRID 000051",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Curly",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Musca",
    "x_1": 0.00000327,
    "y_1": -0.00005986,
    "z_1": -0.00000329,
    "Distance in Parsecs_1": 4.6081,
    "HYG Star ID_1": 57194,
    "Hipparcos Catalogue_1": 57367,
    "Gliese Catalog_1": "Gl 440"
  },
  {
    "Astrid #": "ASTRID 000052",
    "HYG Star ID": 63518,
    "Hipparcos Catalogue": 63721,
    "Henry Draper Catalogue": 113447,
    "Distance in Parsecs": 4.6164,
    "mag": 8.75,
    "Absolute Visual Magnitude": 10.428,
    "Spectral Type": "F3V",
    "Color Index": 0.455,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.997663,
    "y": -1.136452,
    "z": 2.008857,
    "vx": 0.00002969,
    "vy": 0.00000946,
    "vz": -0.00001546,
    "rarad": 3.4185651692242,
    "decrad": 0.450244052747103,
    "pmrarad": -2.12202947979166e-7,
    "pmdecrad": -7.9800331e-8,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 63518,
    "lum": 0.00587218865971302,
    "ra": 13.057957,
    "dec": 25.797084,
    "pmra": -43.77,
    "pmdec": -16.46,
    "rv": -34,
    "Asset Name": "ASTRID 000052",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Oof",
    "Hair": "Undercut",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00002969,
    "y_1": 0.00000946,
    "z_1": -0.00001546,
    "Distance in Parsecs_1": 4.6164,
    "HYG Star ID_1": 63518,
    "Hipparcos Catalogue_1": 63721,
    "Henry Draper Catalogue_1": 113447
  },
  {
    "Astrid #": "ASTRID 000053",
    "HYG Star ID": 72283,
    "Hipparcos Catalogue": 72509,
    "Gliese Catalog": "Gl 563.2A",
    "Distance in Parsecs": 4.6583,
    "mag": 12.07,
    "Absolute Visual Magnitude": 13.729,
    "Spectral Type": "M",
    "Color Index": 1.524,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.089858,
    "y": -2.819683,
    "z": -2.050308,
    "vx": -0.00003507,
    "vy": 0.00001146,
    "vz": -0.00001403,
    "rarad": 3.88131088702627,
    "decrad": -0.455734656955713,
    "pmrarad": -0.00000689211128277777,
    "pmdecrad": -9.87080653e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 72283,
    "base": "Gl 563.2",
    "lum": 0.000280801872811663,
    "ra": 14.825516,
    "dec": -26.111672,
    "pmra": -1421.6,
    "pmdec": -203.6,
    "rv": 22,
    "Asset Name": "ASTRID 000053",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined Shinobi",
    "Hair": "Innocent",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003507,
    "y_1": 0.00001146,
    "z_1": -0.00001403,
    "Distance in Parsecs_1": 4.6583,
    "HYG Star ID_1": 72283,
    "Hipparcos Catalogue_1": 72509,
    "Gliese Catalog_1": "Gl 563.2A"
  },
  {
    "Astrid #": "ASTRID 000054",
    "HYG Star ID": 112660,
    "Hipparcos Catalogue": 113020,
    "Gliese Catalog": "Gl 876",
    "Distance in Parsecs": 4.6887,
    "mag": 10.16,
    "Absolute Visual Magnitude": 11.805,
    "Spectral Type": "M5",
    "Color Index": 1.597,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.352933,
    "y": -1.304309,
    "z": -1.155222,
    "vx": 0.00000122,
    "vy": 0.00002242,
    "vz": -0.00001451,
    "rarad": 5.99205827734816,
    "decrad": -0.248948538457571,
    "pmrarad": 0.00000465581121847916,
    "pmdecrad": -0.000003275595151,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 112660,
    "lum": 0.00165196179822901,
    "var": "IL",
    "var_min": 10.202,
    "var_max": 10.102,
    "ra": 22.887977,
    "dec": -14.263701,
    "pmra": 960.33,
    "pmdec": -675.64,
    "rv": -1.5,
    "Asset Name": "ASTRID 000054",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Mohawk",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000122,
    "y_1": 0.00002242,
    "z_1": -0.00001451,
    "Distance in Parsecs_1": 4.6887,
    "HYG Star ID_1": 112660,
    "Hipparcos Catalogue_1": 113020,
    "Gliese Catalog_1": "Gl 876"
  },
  {
    "Astrid #": "ASTRID 000055",
    "HYG Star ID": 117960,
    "Gliese Catalog": "GJ 1002",
    "Distance in Parsecs": 4.6992,
    "mag": 13.75,
    "Absolute Visual Magnitude": 15.39,
    "Spectral Type": "M5-5.5",
    "Color Index": 1.98,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.65444,
    "y": 0.136696,
    "z": -0.616283,
    "vx": -0.00004758,
    "vy": -0.00002002,
    "vz": -0.0000366,
    "rarad": 0.0293622129094767,
    "decrad": -0.13158564925665,
    "pmrarad": -0.00000395952181046527,
    "pmdecrad": -0.000009063005133,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117960,
    "lum": 0.0000608135001278717,
    "ra": 0.112155,
    "dec": -7.539302,
    "pmra": -816.71,
    "pmdec": -1869.38,
    "rv": -42,
    "Asset Name": "ASTRID 000055",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Charmer",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004758,
    "y_1": -0.00002002,
    "z_1": -0.0000366,
    "Distance in Parsecs_1": 4.6992,
    "HYG Star ID_1": 117960,
    "Gliese Catalog_1": "GJ 1002"
  },
  {
    "Astrid #": "ASTRID 000056",
    "HYG Star ID": 119312,
    "Gliese Catalog": "GJ 1245A",
    "Distance in Parsecs": 4.717,
    "mag": 13.41,
    "Absolute Visual Magnitude": 15.042,
    "Spectral Type": "M5.5 V e",
    "Color Index": 1.9,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.606452,
    "y": -2.961705,
    "z": 3.301167,
    "vx": 0.00001326,
    "vy": -0.00000344,
    "vz": -0.00000953,
    "rarad": 5.20934222634216,
    "decrad": 0.7751798494013,
    "pmrarad": 0.00000212498684324305,
    "pmdecrad": -0.000002830191974,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119312,
    "base": "GJ 1245",
    "lum": 0.0000837915067438408,
    "ra": 19.898222,
    "dec": 44.414534,
    "pmra": 438.31,
    "pmdec": -583.77,
    "rv": 0,
    "Asset Name": "ASTRID 000056",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Shocked",
    "Hair": "High Fade",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001326,
    "y_1": -0.00000344,
    "z_1": -0.00000953,
    "Distance in Parsecs_1": 4.717,
    "HYG Star ID_1": 119312,
    "Gliese Catalog_1": "GJ 1245A"
  },
  {
    "Astrid #": "ASTRID 000057",
    "HYG Star ID": 119313,
    "Gliese Catalog": "GJ 1245B",
    "Distance in Parsecs": 4.717,
    "mag": 14.01,
    "Absolute Visual Magnitude": 15.642,
    "Spectral Type": "m",
    "Color Index": 1.98,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.606668,
    "y": -2.961587,
    "z": 3.301168,
    "vx": 0.00001326,
    "vy": -0.00000344,
    "vz": -0.00000953,
    "rarad": 5.20941510834216,
    "decrad": 0.7751801614013,
    "pmrarad": 0.00000212498684324305,
    "pmdecrad": -0.000002830191974,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119312,
    "base": "GJ 1245",
    "lum": 0.0000482169793900618,
    "ra": 19.8985,
    "dec": 44.414552,
    "pmra": 438.31,
    "pmdec": -583.77,
    "rv": 0,
    "Asset Name": "ASTRID 000057",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Braided Ponytail",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001326,
    "y_1": -0.00000344,
    "z_1": -0.00000953,
    "Distance in Parsecs_1": 4.717,
    "HYG Star ID_1": 119313,
    "Gliese Catalog_1": "GJ 1245B"
  },
  {
    "Astrid #": "ASTRID 000058",
    "HYG Star ID": 113750,
    "Hipparcos Catalogue": 114110,
    "Distance in Parsecs": 4.804,
    "mag": 12.24,
    "Absolute Visual Magnitude": 13.832,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.517834,
    "y": -1.071109,
    "z": -1.233013,
    "vx": 0.00000109,
    "vy": 0.00000243,
    "vz": 0.00000187,
    "rarad": 6.05039860662779,
    "decrad": -0.259568755859563,
    "pmrarad": 5.44300319159722e-7,
    "pmdecrad": 4.03558907e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 113750,
    "lum": 0.000255387713577931,
    "var_min": 12.955,
    "var_max": 10.405,
    "ra": 23.11082,
    "dec": -14.872194,
    "pmra": 112.27,
    "pmdec": 83.24,
    "rv": 0,
    "Asset Name": "ASTRID 000058",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Star of Culture",
    "Hair": "Braided Ponytail",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000109,
    "y_1": 0.00000243,
    "z_1": 0.00000187,
    "Distance in Parsecs_1": 4.804,
    "HYG Star ID_1": 113750,
    "Hipparcos Catalogue_1": 114110
  },
  {
    "Astrid #": "ASTRID 000059",
    "HYG Star ID": 118727,
    "Gliese Catalog": "Gl 412B",
    "Distance in Parsecs": 4.8323,
    "mag": 14.4,
    "Absolute Visual Magnitude": 15.979,
    "Spectral Type": "M6  e",
    "Color Index": 2.09,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.405436,
    "y": 0.825166,
    "z": 3.32765,
    "vx": 0.00003895,
    "vy": 0.00009743,
    "vz": 0.00001587,
    "rarad": 2.90427326431944,
    "decrad": 0.7595932491991,
    "pmrarad": -0.0000214900936111458,
    "pmdecrad": 0.000004528663982,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 54052,
    "base": "Gl 412",
    "lum": 0.0000353508613361997,
    "ra": 11.093507,
    "dec": 43.521487,
    "pmra": -4432.65,
    "pmdec": 934.1,
    "rv": 0,
    "Asset Name": "ASTRID 000059",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Sweet Nap",
    "Hair": "Curly",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003895,
    "y_1": 0.00009743,
    "z_1": 0.00001587,
    "Distance in Parsecs_1": 4.8323,
    "HYG Star ID_1": 118727,
    "Gliese Catalog_1": "Gl 412B"
  },
  {
    "Astrid #": "ASTRID 000060",
    "HYG Star ID": 54052,
    "Hipparcos Catalogue": 54211,
    "Gliese Catalog": "Gl 412A",
    "Distance in Parsecs": 4.848,
    "mag": 8.82,
    "Absolute Visual Magnitude": 10.392,
    "Spectral Type": "M2Vvar",
    "Color Index": 1.491,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.416483,
    "y": 0.828486,
    "z": 3.33921,
    "vx": -0.00001034,
    "vy": 0.00010916,
    "vz": 0.00006453,
    "rarad": 2.90375903200827,
    "decrad": 0.759685574763596,
    "pmrarad": -0.0000213841133405763,
    "pmdecrad": 0.000004573344411,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 54052,
    "base": "Gl 412",
    "lum": 0.00607015806341044,
    "var": "WX",
    "var_min": 8.858,
    "var_max": 8.788,
    "ra": 11.091542,
    "dec": 43.526777,
    "pmra": -4410.79,
    "pmdec": 943.32,
    "rv": 68.8,
    "Asset Name": "ASTRID 000060",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Skater Helmet",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001034,
    "y_1": 0.00010916,
    "z_1": 0.00006453,
    "Distance in Parsecs_1": 4.848,
    "HYG Star ID_1": 54052,
    "Hipparcos Catalogue_1": 54211,
    "Gliese Catalog_1": "Gl 412A"
  },
  {
    "Astrid #": "ASTRID 000061",
    "HYG Star ID": 49767,
    "Hipparcos Catalogue": 49908,
    "Henry Draper Catalogue": 88230,
    "Gliese Catalog": "Gl 380",
    "Distance in Parsecs": 4.8731,
    "mag": 6.6,
    "Absolute Visual Magnitude": 8.161,
    "Spectral Type": "K8V",
    "Color Index": 1.326,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.818415,
    "y": 1.445842,
    "z": 3.702832,
    "vx": 0.00002164,
    "vy": 0.00002505,
    "vz": -0.00002749,
    "rarad": 2.66763167441915,
    "decrad": 0.863139260295497,
    "pmrarad": -0.00000660098066760416,
    "pmdecrad": -0.000002448309086,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 49767,
    "lum": 0.0473805393338825,
    "ra": 10.189602,
    "dec": 49.454237,
    "pmra": -1361.55,
    "pmdec": -505,
    "rv": -25.4,
    "Asset Name": "ASTRID 000061",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Mohawk",
    "Outfit": "Wizard",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00002164,
    "y_1": 0.00002505,
    "z_1": -0.00002749,
    "Distance in Parsecs_1": 4.8731,
    "HYG Star ID_1": 49767,
    "Hipparcos Catalogue_1": 49908,
    "Henry Draper Catalogue_1": 88230,
    "Gliese Catalog_1": "Gl 380"
  },
  {
    "Astrid #": "ASTRID 000062",
    "HYG Star ID": 118676,
    "Gliese Catalog": "Gl 388",
    "Distance in Parsecs": 4.9044,
    "mag": 9.4,
    "Absolute Visual Magnitude": 10.947,
    "Spectral Type": "M4.5Ve",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.17747,
    "y": 1.956673,
    "z": 1.667178,
    "vx": -0.00000608,
    "vy": 0.0000159,
    "vz": 0.00000311,
    "rarad": 2.7035612777746,
    "decrad": 0.3468014888489,
    "pmrarad": -0.00000241078450793055,
    "pmdecrad": -2.53383022e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 118676,
    "lum": 0.003640826637894,
    "ra": 10.326843,
    "dec": 19.870262,
    "pmra": -497.26,
    "pmdec": -52.26,
    "rv": 12.3,
    "Asset Name": "ASTRID 000062",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Little Crown",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000608,
    "y_1": 0.0000159,
    "z_1": 0.00000311,
    "Distance in Parsecs_1": 4.9044,
    "HYG Star ID_1": 118676,
    "Gliese Catalog_1": "Gl 388"
  },
  {
    "Astrid #": "ASTRID 000063",
    "HYG Star ID": 106098,
    "Hipparcos Catalogue": 106440,
    "Henry Draper Catalogue": 204961,
    "Gliese Catalog": "Gl 832",
    "Distance in Parsecs": 4.9537,
    "mag": 8.66,
    "Absolute Visual Magnitude": 10.185,
    "Spectral Type": "M1V",
    "Color Index": 1.521,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.608354,
    "y": -1.937731,
    "z": -3.739143,
    "vx": -0.00001036,
    "vy": 0.00000631,
    "vz": -0.00001605,
    "rarad": 5.64424857298912,
    "decrad": -0.855368519963395,
    "pmrarad": -2.23886957680555e-7,
    "pmdecrad": -0.000003965824388,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 106098,
    "lum": 0.00734513868157114,
    "ra": 21.559441,
    "dec": -49.009006,
    "pmra": -46.18,
    "pmdec": -818.01,
    "rv": 4.1,
    "Asset Name": "ASTRID 000063",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Halo Boy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00001036,
    "y_1": 0.00000631,
    "z_1": -0.00001605,
    "Distance in Parsecs_1": 4.9537,
    "HYG Star ID_1": 106098,
    "Hipparcos Catalogue_1": 106440,
    "Henry Draper Catalogue_1": 204961,
    "Gliese Catalog_1": "Gl 832"
  },
  {
    "Astrid #": "ASTRID 000064",
    "HYG Star ID": 19799,
    "Hipparcos Catalogue": 19849,
    "Henry Draper Catalogue": 26965,
    "Harvard Revised Catalogue": 1325,
    "Gliese Catalog": "Gl 166A",
    "Distance in Parsecs": 4.9845,
    "mag": 4.43,
    "Absolute Visual Magnitude": 5.942,
    "Spectral Type": "K1V",
    "Color Index": 0.82,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.179524,
    "y": 4.432894,
    "z": -0.66374,
    "vx": 0.00002462,
    "vy": -0.00007258,
    "vz": -0.00007609,
    "rarad": 1.11383508194081,
    "decrad": -0.133567788554931,
    "pmrarad": -0.0000108565781927847,
    "pmdecrad": -0.000016579949135,
    "bayer": "Omi-2",
    "flam": 40,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 19799,
    "base": "Gl 166",
    "lum": 0.365763193009451,
    "bf": "40Omi2Eri",
    "ra": 4.254537,
    "dec": -7.652871,
    "pmra": -2239.33,
    "pmdec": -3419.86,
    "rv": -42.7,
    "Asset Name": "ASTRID 000064",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Sad",
    "Hair": "Ram Horns",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00002462,
    "y_1": -0.00007258,
    "z_1": -0.00007609,
    "Distance in Parsecs_1": 4.9845,
    "HYG Star ID_1": 19799,
    "Hipparcos Catalogue_1": 19849,
    "Henry Draper Catalogue_1": 26965,
    "Harvard Revised Catalogue_1": 1325,
    "Gliese Catalog_1": "Gl 166A"
  },
  {
    "Astrid #": "ASTRID 000065",
    "HYG Star ID": 1240,
    "Hipparcos Catalogue": 1242,
    "Gliese Catalog": "GJ 1005A",
    "Distance in Parsecs": 4.9868,
    "mag": 11.49,
    "Absolute Visual Magnitude": 13.001,
    "Spectral Type": "M:",
    "Color Index": 1.75,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.779241,
    "y": 0.32306,
    "z": -1.385667,
    "vx": -0.00003375,
    "vy": 0.00001536,
    "vz": -0.0000061,
    "rarad": 0.0674927592672668,
    "decrad": -0.281588059240747,
    "pmrarad": 0.00000353031625906944,
    "pmdecrad": -0.000002993627514,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 1240,
    "base": "GJ 1005",
    "lum": 0.000549034961025203,
    "var_min": 11.558,
    "var_max": 11.378,
    "ra": 0.257803,
    "dec": -16.133807,
    "pmra": 728.18,
    "pmdec": -617.48,
    "rv": -29,
    "Asset Name": "ASTRID 000065",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Warm Smile",
    "Hair": "Shinobi",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00003375,
    "y_1": 0.00001536,
    "z_1": -0.0000061,
    "Distance in Parsecs_1": 4.9868,
    "HYG Star ID_1": 1240,
    "Hipparcos Catalogue_1": 1242,
    "Gliese Catalog_1": "GJ 1005A"
  },
  {
    "Astrid #": "ASTRID 000066",
    "HYG Star ID": 118281,
    "Henry Draper Catalogue": 26976,
    "Gliese Catalog": "Gl 166B",
    "Distance in Parsecs": 5.0444,
    "mag": 9.52,
    "Absolute Visual Magnitude": 11.006,
    "Spectral Type": "DA4",
    "Color Index": 0.03,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.203473,
    "y": 4.486451,
    "z": -0.67203,
    "vx": 0.00003361,
    "vy": -0.00005259,
    "vz": -0.00008045,
    "rarad": 1.11426734831458,
    "decrad": -0.1336486592596,
    "pmrarad": -0.0000105729137082916,
    "pmdecrad": -0.000016660205192,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 2,
    "comp_primary": 19799,
    "base": "Gl 166",
    "lum": 0.00344825996552622,
    "ra": 4.256188,
    "dec": -7.657504,
    "pmra": -2180.82,
    "pmdec": -3436.41,
    "rv": -21,
    "Asset Name": "ASTRID 000066",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Afro",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00003361,
    "y_1": -0.00005259,
    "z_1": -0.00008045,
    "Distance in Parsecs_1": 5.0444,
    "HYG Star ID_1": 118281,
    "Henry Draper Catalogue_1": 26976,
    "Gliese Catalog_1": "Gl 166B"
  },
  {
    "Astrid #": "ASTRID 000067",
    "HYG Star ID": 118282,
    "Gliese Catalog": "Gl 166C",
    "Distance in Parsecs": 5.0444,
    "mag": 11.17,
    "Absolute Visual Magnitude": 12.656,
    "Spectral Type": "dM4.5e",
    "Color Index": 1.67,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.202917,
    "y": 4.485319,
    "z": -0.671861,
    "vx": 0.00002249,
    "vy": -0.00007525,
    "vz": -0.00007708,
    "rarad": 1.11426734831458,
    "decrad": -0.1336486592596,
    "pmrarad": -0.0000105729137082916,
    "pmdecrad": -0.000016660205192,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 3,
    "comp_primary": 19799,
    "base": "Gl 166",
    "lum": 0.000754397082197988,
    "ra": 4.256188,
    "dec": -7.657504,
    "pmra": -2180.82,
    "pmdec": -3436.41,
    "rv": -45.9,
    "Asset Name": "ASTRID 000067",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Medium Bob",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00002249,
    "y_1": -0.00007525,
    "z_1": -0.00007708,
    "Distance in Parsecs_1": 5.0444,
    "HYG Star ID_1": 118282,
    "Gliese Catalog_1": "Gl 166C"
  },
  {
    "Astrid #": "ASTRID 000068",
    "HYG Star ID": 85951,
    "Hipparcos Catalogue": 86214,
    "Gliese Catalog": "Gl 682",
    "Distance in Parsecs": 5.0787,
    "mag": 10.94,
    "Absolute Visual Magnitude": 12.411,
    "Spectral Type": "M5",
    "Color Index": 1.655,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.363042,
    "y": -3.615031,
    "z": -3.547885,
    "vx": -0.0000114,
    "vy": 0.00006148,
    "vz": 0.00002635,
    "rarad": 4.61231078528376,
    "decrad": -0.773516226658617,
    "pmrarad": -0.00000344271042699305,
    "pmdecrad": -0.000004547503842,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 85951,
    "lum": 0.000945366045957965,
    "var_min": 11.004,
    "var_max": 10.884,
    "ra": 17.617729,
    "dec": -44.319215,
    "pmra": -710.11,
    "pmdec": -937.99,
    "rv": -60,
    "Asset Name": "ASTRID 000068",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Manbun",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.0000114,
    "y_1": 0.00006148,
    "z_1": 0.00002635,
    "Distance in Parsecs_1": 5.0787,
    "HYG Star ID_1": 85951,
    "Hipparcos Catalogue_1": 86214,
    "Gliese Catalog_1": "Gl 682"
  },
  {
    "Astrid #": "ASTRID 000069",
    "HYG Star ID": 88327,
    "Hipparcos Catalogue": 88601,
    "Henry Draper Catalogue": 165341,
    "Harvard Revised Catalogue": 6752,
    "Gliese Catalog": "Gl 702A",
    "Distance in Parsecs": 5.0834,
    "mag": 4.03,
    "Absolute Visual Magnitude": 5.499,
    "Spectral Type": "K0V SB",
    "Color Index": 0.86,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.120862,
    "y": -5.077062,
    "z": 0.221741,
    "vx": 0.00000293,
    "vy": 0.00000609,
    "vz": -0.00002401,
    "rarad": 4.7361898459843,
    "decrad": 0.0436349668104512,
    "pmrarad": 6.038839205e-7,
    "pmdecrad": -0.000004667107377,
    "flam": 70,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 88327,
    "base": "Gl 702",
    "lum": 0.550047252867633,
    "var": "V2391",
    "var_min": 4.05,
    "var_max": 4.02,
    "bf": "70    Oph",
    "ra": 18.090913,
    "dec": 2.500099,
    "pmra": 124.56,
    "pmdec": -962.66,
    "rv": -6.9,
    "Asset Name": "ASTRID 000069",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Undercut",
    "Outfit": "Spartan",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00000293,
    "y_1": 0.00000609,
    "z_1": -0.00002401,
    "Distance in Parsecs_1": 5.0834,
    "HYG Star ID_1": 88327,
    "Hipparcos Catalogue_1": 88601,
    "Henry Draper Catalogue_1": 165341,
    "Harvard Revised Catalogue_1": 6752,
    "Gliese Catalog_1": "Gl 702A"
  },
  {
    "Astrid #": "ASTRID 000070",
    "HYG Star ID": 119198,
    "Gliese Catalog": "Gl 702B",
    "Distance in Parsecs": 5.086,
    "mag": 6,
    "Absolute Visual Magnitude": 7.468,
    "Spectral Type": "K5 Ve",
    "Color Index": 1.15,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.121015,
    "y": -5.079214,
    "z": 0.221672,
    "vx": 0.000006,
    "vy": 0.00000917,
    "vz": -0.00002758,
    "rarad": 4.73620998697359,
    "decrad": 0.0436029407136,
    "pmrarad": 0.0000012230394719375,
    "pmdecrad": -0.000005340125728,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 2,
    "comp_primary": 88327,
    "base": "Gl 702",
    "lum": 0.0897015608414814,
    "ra": 18.09099,
    "dec": 2.498264,
    "pmra": 252.27,
    "pmdec": -1101.48,
    "rv": -10,
    "Asset Name": "ASTRID 000070",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Bangs",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.000006,
    "y_1": 0.00000917,
    "z_1": -0.00002758,
    "Distance in Parsecs_1": 5.086,
    "HYG Star ID_1": 119198,
    "Gliese Catalog_1": "Gl 702B"
  },
  {
    "Astrid #": "ASTRID 000071",
    "HYG Star ID": 112106,
    "Hipparcos Catalogue": 112460,
    "Gliese Catalog": "Gl 873",
    "Distance in Parsecs": 5.1224,
    "mag": 10.29,
    "Absolute Visual Magnitude": 11.743,
    "Spectral Type": "M4.5Ve",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.478781,
    "y": -1.15001,
    "z": 3.579733,
    "vx": 0.00000173,
    "vy": -0.000019,
    "vz": -0.00000852,
    "rarad": 5.96392791747928,
    "decrad": 0.773774115126288,
    "pmrarad": -0.00000341628808140277,
    "pmdecrad": -0.000002227185567,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 112106,
    "lum": 0.0017490410399663,
    "var": "EV",
    "var_min": 10.386,
    "var_max": 10.206,
    "ra": 22.780527,
    "dec": 44.333991,
    "pmra": -704.66,
    "pmdec": -459.39,
    "rv": -0.5,
    "Asset Name": "ASTRID 000071",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Round Brush",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": 0.00000173,
    "y_1": -0.000019,
    "z_1": -0.00000852,
    "Distance in Parsecs_1": 5.1224,
    "HYG Star ID_1": 112106,
    "Hipparcos Catalogue_1": 112460,
    "Gliese Catalog_1": "Gl 873"
  },
  {
    "Astrid #": "ASTRID 000072",
    "HYG Star ID": 97338,
    "Hipparcos Catalogue": 97649,
    "Henry Draper Catalogue": 187642,
    "Harvard Revised Catalogue": 7557,
    "Gliese Catalog": "Gl 768",
    "Proper Name": "Altair",
    "Distance in Parsecs": 5.1295,
    "mag": 0.76,
    "Absolute Visual Magnitude": 2.21,
    "Spectral Type": "A7IV-V",
    "Color Index": 0.221,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 2.355468,
    "y": -4.4873,
    "z": 0.790749,
    "vx": -0.00000112,
    "vy": 0.00003087,
    "vz": 0.00000536,
    "rarad": 5.19577210554699,
    "decrad": 0.15478141706831,
    "pmrarad": 0.00000260257679995833,
    "pmdecrad": 0.000001869150664,
    "bayer": "Alp",
    "flam": 53,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 97338,
    "lum": 11.3762728582343,
    "var": "Alp",
    "var_min": 0.803,
    "var_max": 0.753,
    "bf": "53Alp Aql",
    "ra": 19.846388,
    "dec": 8.868322,
    "pmra": 536.82,
    "pmdec": 385.54,
    "rv": -26.1,
    "Asset Name": "ASTRID 000072",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Smoulder",
    "Hair": "King's Crown",
    "Outfit": "Emperor",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Altair",
    "Constellation": "Aquila",
    "x_1": -0.00000112,
    "y_1": 0.00003087,
    "z_1": 0.00000536,
    "Distance in Parsecs_1": 5.1295,
    "HYG Star ID_1": 97338,
    "Hipparcos Catalogue_1": 97649,
    "Henry Draper Catalogue_1": 187642,
    "Harvard Revised Catalogue_1": 7557,
    "Gliese Catalog_1": "Gl 768"
  },
  {
    "Astrid #": "ASTRID 000073",
    "HYG Star ID": 118578,
    "Gliese Catalog": "GJ 1116A",
    "Distance in Parsecs": 5.2274,
    "mag": 14.06,
    "Absolute Visual Magnitude": 15.469,
    "Spectral Type": "m",
    "Color Index": 1.84,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.450811,
    "y": 3.503886,
    "z": 1.766979,
    "vx": 0.00003845,
    "vy": -0.00000764,
    "vz": -0.00001259,
    "rarad": 2.34857638873374,
    "decrad": 0.3449344336076,
    "pmrarad": -0.00000421448532506944,
    "pmdecrad": -1.69267848e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118578,
    "base": "GJ 1116",
    "lum": 0.000056545754068762,
    "ra": 8.970901,
    "dec": 19.763287,
    "pmra": -869.3,
    "pmdec": -34.91,
    "rv": -34,
    "Asset Name": "ASTRID 000073",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Frizzy",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003845,
    "y_1": -0.00000764,
    "z_1": -0.00001259,
    "Distance in Parsecs_1": 5.2274,
    "HYG Star ID_1": 118578,
    "Gliese Catalog_1": "GJ 1116A"
  },
  {
    "Astrid #": "ASTRID 000074",
    "HYG Star ID": 118579,
    "Gliese Catalog": "GJ 1116B",
    "Distance in Parsecs": 5.2274,
    "mag": 14.92,
    "Absolute Visual Magnitude": 16.329,
    "Spectral Type": "m",
    "Color Index": 1.93,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.451925,
    "y": 3.505014,
    "z": 1.76771,
    "vx": 0.00001548,
    "vy": 0.00001567,
    "vz": -8.3e-7,
    "rarad": 2.34857650373374,
    "decrad": 0.3449635226076,
    "pmrarad": -0.00000421448532506944,
    "pmdecrad": -1.69267848e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118578,
    "base": "GJ 1116",
    "lum": 0.0000256094351715676,
    "ra": 8.970901,
    "dec": 19.764954,
    "pmra": -869.3,
    "pmdec": -34.91,
    "rv": 0,
    "Asset Name": "ASTRID 000074",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Lovestruck",
    "Hair": "Heartbreaker",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001548,
    "y_1": 0.00001567,
    "z_1": -8.3e-7,
    "Distance in Parsecs_1": 5.2274,
    "HYG Star ID_1": 118579,
    "Gliese Catalog_1": "GJ 1116B"
  },
  {
    "Astrid #": "ASTRID 000075",
    "HYG Star ID": 67380,
    "Hipparcos Catalogue": 67593,
    "Distance in Parsecs": 5.3259,
    "mag": 13.31,
    "Absolute Visual Magnitude": 14.678,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -4.312826,
    "y": -2.270248,
    "z": 2.147258,
    "vx": 0.00007687,
    "vy": -0.00002612,
    "vz": 0.00012688,
    "rarad": 3.62612321877796,
    "decrad": 0.414981292648061,
    "pmrarad": 0.0000110630603393333,
    "pmdecrad": 0.000026031731207,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 67380,
    "lum": 0.00011716556739113,
    "ra": 13.85077,
    "dec": 23.776677,
    "pmra": 2281.92,
    "pmdec": 5369.43,
    "rv": 0,
    "Asset Name": "ASTRID 000075",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Lovestruck",
    "Hair": "Spiky",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00007687,
    "y_1": -0.00002612,
    "z_1": 0.00012688,
    "Distance in Parsecs_1": 5.3259,
    "HYG Star ID_1": 67380,
    "Hipparcos Catalogue_1": 67593
  },
  {
    "Astrid #": "ASTRID 000076",
    "HYG Star ID": 56975,
    "Hipparcos Catalogue": 57146,
    "Distance in Parsecs": 5.3419,
    "mag": 9.64,
    "Absolute Visual Magnitude": 11.002,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -4.114851,
    "y": 0.302206,
    "z": -3.393018,
    "vx": -6.5e-7,
    "vy": 0.0000048,
    "vz": 0.00000122,
    "rarad": 3.06828381161778,
    "decrad": -0.688229489700677,
    "pmrarad": -8.87015109944444e-7,
    "pmdecrad": 2.95348494e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 56975,
    "lum": 0.00346098725485352,
    "var_min": 9.808,
    "var_max": 8.448,
    "ra": 11.719981,
    "dec": -39.432645,
    "pmra": -182.96,
    "pmdec": 60.92,
    "rv": 0,
    "Asset Name": "ASTRID 000076",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Innocent",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -6.5e-7,
    "y_1": 0.0000048,
    "z_1": 0.00000122,
    "Distance in Parsecs_1": 5.3419,
    "HYG Star ID_1": 56975,
    "Hipparcos Catalogue_1": 57146
  },
  {
    "Astrid #": "ASTRID 000077",
    "HYG Star ID": 57371,
    "Hipparcos Catalogue": 57544,
    "Gliese Catalog": "Gl 445",
    "Distance in Parsecs": 5.3516,
    "mag": 10.8,
    "Absolute Visual Magnitude": 12.158,
    "Spectral Type": "M4",
    "Color Index": 1.572,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.047725,
    "y": 0.056332,
    "z": 5.246711,
    "vx": 0.00003364,
    "vy": -0.00002112,
    "vz": -0.00011008,
    "rarad": 3.08774919585785,
    "decrad": 1.37341983914305,
    "pmrarad": 0.00000360318375525694,
    "pmdecrad": 0.000002329044921,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 57371,
    "lum": 0.00119343837921079,
    "var_min": 10.866,
    "var_max": 10.736,
    "ra": 11.794333,
    "dec": 78.69116,
    "pmra": 743.21,
    "pmdec": 480.4,
    "rv": -112.2,
    "Asset Name": "ASTRID 000077",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Afro",
    "Outfit": "Warlock",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00003364,
    "y_1": -0.00002112,
    "z_1": -0.00011008,
    "Distance in Parsecs_1": 5.3516,
    "HYG Star ID_1": 57371,
    "Hipparcos Catalogue_1": 57544,
    "Gliese Catalog_1": "Gl 445"
  },
  {
    "Astrid #": "ASTRID 000078",
    "HYG Star ID": 118406,
    "Gliese Catalog": "NN 3379",
    "Distance in Parsecs": 5.3677,
    "mag": 11.33,
    "Absolute Visual Magnitude": 12.681,
    "Spectral Type": "M4",
    "Color Index": 1.68,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.001391,
    "y": 5.361709,
    "z": 0.253516,
    "vx": -0.00000594,
    "vy": 9e-8,
    "vz": -0.00000193,
    "rarad": 1.57105573536129,
    "decrad": 0.0472475891391,
    "pmrarad": 0.00000110658722586805,
    "pmdecrad": -3.59557218e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118406,
    "lum": 0.000737224908104335,
    "ra": 6.000991,
    "dec": 2.707087,
    "pmra": 228.25,
    "pmdec": -74.16,
    "rv": 0,
    "Asset Name": "ASTRID 000078",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Malevolent",
    "Hair": "Good Boy",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000594,
    "y_1": 9e-8,
    "z_1": -0.00000193,
    "Distance in Parsecs_1": 5.3677,
    "HYG Star ID_1": 118406,
    "Gliese Catalog_1": "NN 3379"
  },
  {
    "Astrid #": "ASTRID 000079",
    "HYG Star ID": 66943,
    "Hipparcos Catalogue": 67155,
    "Henry Draper Catalogue": 119850,
    "Gliese Catalog": "Gl 526",
    "Distance in Parsecs": 5.3911,
    "mag": 8.46,
    "Absolute Visual Magnitude": 9.802,
    "Spectral Type": "M3V",
    "Color Index": 1.435,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.665503,
    "y": -2.319265,
    "z": 1.385494,
    "vx": -0.00000232,
    "vy": -0.00005306,
    "vz": -0.00003253,
    "rarad": 3.6029224814021,
    "decrad": 0.259906049099273,
    "pmrarad": 0.00000862221738320833,
    "pmdecrad": -0.000007056560083,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 66943,
    "lum": 0.0104520144145843,
    "ra": 13.76215,
    "dec": 14.89152,
    "pmra": 1778.46,
    "pmdec": -1455.52,
    "rv": 16.1,
    "Asset Name": "ASTRID 000079",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Braid",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000232,
    "y_1": -0.00005306,
    "z_1": -0.00003253,
    "Distance in Parsecs_1": 5.3911,
    "HYG Star ID_1": 66943,
    "Hipparcos Catalogue_1": 67155,
    "Henry Draper Catalogue_1": 119850,
    "Gliese Catalog_1": "Gl 526"
  },
  {
    "Astrid #": "ASTRID 000080",
    "HYG Star ID": 118311,
    "Gliese Catalog": "Gl 169.1B",
    "Distance in Parsecs": 5.5139,
    "mag": 12.44,
    "Absolute Visual Magnitude": 13.733,
    "Spectral Type": "DC5",
    "Color Index": 0.31,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 1.073743,
    "y": 2.630708,
    "z": 4.725416,
    "vx": -0.00001708,
    "vy": 0.00005513,
    "vz": -0.00002677,
    "rarad": 1.18296475436824,
    "decrad": 1.02942051120745,
    "pmrarad": 0.00000665120736490972,
    "pmdecrad": -0.000009428675851,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 21036,
    "base": "Gl 169.1",
    "lum": 0.000279769263785464,
    "ra": 4.518593,
    "dec": 58.981451,
    "pmra": 1371.91,
    "pmdec": -1944.8,
    "rv": 0,
    "Asset Name": "ASTRID 000080",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Nervous Grin",
    "Hair": "Bob",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001708,
    "y_1": 0.00005513,
    "z_1": -0.00002677,
    "Distance in Parsecs_1": 5.5139,
    "HYG Star ID_1": 118311,
    "Gliese Catalog_1": "Gl 169.1B"
  },
  {
    "Astrid #": "ASTRID 000081",
    "HYG Star ID": 58735,
    "Hipparcos Catalogue": 58910,
    "Henry Draper Catalogue": 104901,
    "Distance in Parsecs": 5.5169,
    "mag": 7.57,
    "Absolute Visual Magnitude": 8.862,
    "Spectral Type": "F0Ib-II",
    "Color Index": 0.626,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.589262,
    "y": -0.054392,
    "z": -4.871237,
    "vx": -1e-8,
    "vy": 1.6e-7,
    "vz": 0,
    "rarad": 3.16259634495806,
    "decrad": -1.08214413721261,
    "pmrarad": -2.97190786180555e-8,
    "pmdecrad": 7.75701e-10,
    "con": "Cru",
    "Full Constellation Name Formula 1": "Crux",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crux",
    "comp": 1,
    "comp_primary": 58735,
    "lum": 0.024842768936968,
    "var": "BY",
    "var_min": 7.833,
    "var_max": 7.043,
    "ra": 12.080228,
    "dec": -62.002292,
    "pmra": -6.13,
    "pmdec": 0.16,
    "rv": 0,
    "Asset Name": "ASTRID 000081",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Good Boy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crux",
    "x_1": -1e-8,
    "y_1": 1.6e-7,
    "z_1": 0,
    "Distance in Parsecs_1": 5.5169,
    "HYG Star ID_1": 58735,
    "Hipparcos Catalogue_1": 58910,
    "Henry Draper Catalogue_1": 104901
  },
  {
    "Astrid #": "ASTRID 000082",
    "HYG Star ID": 21036,
    "Hipparcos Catalogue": 21088,
    "Gliese Catalog": "Gl 169.1A",
    "Distance in Parsecs": 5.5782,
    "mag": 10.82,
    "Absolute Visual Magnitude": 12.088,
    "Color Index": 1.17,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 1.086377,
    "y": 2.661819,
    "z": 4.780431,
    "vx": -0.00001122,
    "vy": 0.00006555,
    "vz": -0.00001365,
    "rarad": 1.18324629846461,
    "decrad": 1.02934409339146,
    "pmrarad": 0.00000630359595595138,
    "pmdecrad": -0.000009933783833,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 21036,
    "base": "Gl 169.1",
    "lum": 0.00127291674619356,
    "var_min": 10.877,
    "var_max": 10.767,
    "ra": 4.519668,
    "dec": 58.977072,
    "pmra": 1300.21,
    "pmdec": -2048.99,
    "rv": 17,
    "Asset Name": "ASTRID 000082",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Nervous",
    "Hair": "Braid",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001122,
    "y_1": 0.00006555,
    "z_1": -0.00001365,
    "Distance in Parsecs_1": 5.5782,
    "HYG Star ID_1": 21036,
    "Hipparcos Catalogue_1": 21088,
    "Gliese Catalog_1": "Gl 169.1A"
  },
  {
    "Astrid #": "ASTRID 000083",
    "HYG Star ID": 33139,
    "Hipparcos Catalogue": 33226,
    "Henry Draper Catalogue": 265866,
    "Distance in Parsecs": 5.5863,
    "mag": 9.89,
    "Absolute Visual Magnitude": 11.154,
    "Spectral Type": "K:...",
    "Color Index": 1.58,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.10658,
    "y": 4.538001,
    "z": 3.064541,
    "vx": 0.00001243,
    "vy": 0.00003237,
    "vz": 0.00000577,
    "rarad": 1.80998203894835,
    "decrad": 0.580639190791813,
    "pmrarad": -0.00000353589161639583,
    "pmdecrad": -0.000001935909507,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 33139,
    "lum": 0.00300884627664572,
    "ra": 6.913622,
    "dec": 33.268175,
    "pmra": -729.33,
    "pmdec": -399.31,
    "rv": 26.4,
    "Asset Name": "ASTRID 000083",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Little Crown",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00001243,
    "y_1": 0.00003237,
    "z_1": 0.00000577,
    "Distance in Parsecs_1": 5.5863,
    "HYG Star ID_1": 33139,
    "Hipparcos Catalogue_1": 33226,
    "Henry Draper Catalogue_1": 265866
  },
  {
    "Astrid #": "ASTRID 000084",
    "HYG Star ID": 83352,
    "Hipparcos Catalogue": 83609,
    "Distance in Parsecs": 5.6155,
    "mag": 9.25,
    "Absolute Visual Magnitude": 10.503,
    "Spectral Type": "G0",
    "Color Index": 0.607,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.102705,
    "y": -4.536064,
    "z": -3.121219,
    "vx": 0.00000412,
    "vy": 0.0000012,
    "vz": -0.0000032,
    "rarad": 4.47391628912921,
    "decrad": -0.589351576448806,
    "pmrarad": 6.62643338583333e-7,
    "pmdecrad": -6.84508435e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 83352,
    "lum": 0.0054802453217685,
    "ra": 17.089101,
    "dec": -33.767358,
    "pmra": 136.68,
    "pmdec": -141.19,
    "rv": 0,
    "Asset Name": "ASTRID 000084",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Slick",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000412,
    "y_1": 0.0000012,
    "z_1": -0.0000032,
    "Distance in Parsecs_1": 5.6155,
    "HYG Star ID_1": 83352,
    "Hipparcos Catalogue_1": 83609
  },
  {
    "Astrid #": "ASTRID 000085",
    "HYG Star ID": 25813,
    "Hipparcos Catalogue": 25878,
    "Henry Draper Catalogue": 36395,
    "Gliese Catalog": "Gl 205",
    "Distance in Parsecs": 5.6571,
    "mag": 7.97,
    "Absolute Visual Magnitude": 9.207,
    "Spectral Type": "M1V",
    "Color Index": 1.474,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.701301,
    "y": 5.601801,
    "z": -0.362827,
    "vx": -0.00002014,
    "vy": 0.00000756,
    "vz": -0.00005783,
    "rarad": 1.4462522224944,
    "decrad": -0.0641797528990825,
    "pmrarad": 0.00000369937078947916,
    "pmdecrad": -0.000010146617038,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 25813,
    "lum": 0.0180800655211,
    "ra": 5.524277,
    "dec": -3.677229,
    "pmra": 763.05,
    "pmdec": -2092.89,
    "rv": 8.5,
    "Asset Name": "ASTRID 000085",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Little Crown",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00002014,
    "y_1": 0.00000756,
    "z_1": -0.00005783,
    "Distance in Parsecs_1": 5.6571,
    "HYG Star ID_1": 25813,
    "Hipparcos Catalogue_1": 25878,
    "Henry Draper Catalogue_1": 36395,
    "Gliese Catalog_1": "Gl 205"
  },
  {
    "Astrid #": "ASTRID 000086",
    "HYG Star ID": 119276,
    "Gliese Catalog": "Gl 754",
    "Distance in Parsecs": 5.6915,
    "mag": 12.23,
    "Absolute Visual Magnitude": 13.454,
    "Spectral Type": "M4.5",
    "Color Index": 1.68,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.376254,
    "y": -3.74042,
    "z": -4.064196,
    "vx": 0.00000121,
    "vy": 0.00004838,
    "vz": -0.00006712,
    "rarad": 5.06488940666121,
    "decrad": -0.7951648430288,
    "pmrarad": 0.00000313359322427083,
    "pmdecrad": -0.000013904800576,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119276,
    "lum": 0.000361742886764217,
    "ra": 19.346452,
    "dec": -45.55959,
    "pmra": 646.35,
    "pmdec": -2868.07,
    "rv": 16,
    "Asset Name": "ASTRID 000086",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Middle Part",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000121,
    "y_1": 0.00004838,
    "z_1": -0.00006712,
    "Distance in Parsecs_1": 5.6915,
    "HYG Star ID_1": 119276,
    "Gliese Catalog_1": "Gl 754"
  },
  {
    "Astrid #": "ASTRID 000087",
    "HYG Star ID": 102708,
    "Hipparcos Catalogue": 103039,
    "Distance in Parsecs": 5.7133,
    "mag": 11.41,
    "Absolute Visual Magnitude": 12.626,
    "Color Index": 1.653,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.736291,
    "y": -3.987443,
    "z": -1.667998,
    "vx": -0.00000603,
    "vy": -0.00000599,
    "vz": 8.2e-7,
    "rarad": 5.46528224141886,
    "decrad": -0.296265066275562,
    "pmrarad": -0.00000148692355826388,
    "pmdecrad": 1.4922565e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 102708,
    "lum": 0.00077553249575859,
    "var_min": 11.471,
    "var_max": 11.341,
    "ra": 20.87584,
    "dec": -16.974738,
    "pmra": -306.7,
    "pmdec": 30.78,
    "rv": 0,
    "Asset Name": "ASTRID 000087",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Pompadour",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00000603,
    "y_1": -0.00000599,
    "z_1": 8.2e-7,
    "Distance in Parsecs_1": 5.7133,
    "HYG Star ID_1": 102708,
    "Hipparcos Catalogue_1": 103039
  },
  {
    "Astrid #": "ASTRID 000088",
    "HYG Star ID": 119112,
    "Gliese Catalog": "Gl 644B",
    "Distance in Parsecs": 5.7395,
    "mag": 9.9,
    "Absolute Visual Magnitude": 11.106,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.5779,
    "y": -5.45522,
    "z": -0.83226,
    "vx": -0.00002021,
    "vy": 0.00000951,
    "vz": -0.00002407,
    "rarad": 4.43082924106619,
    "decrad": -0.145518573697,
    "pmrarad": -0.00000384277867618749,
    "pmdecrad": -0.00000423799394,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 2,
    "comp_primary": 82565,
    "base": "Gl 644",
    "lum": 0.00314485046547695,
    "ra": 16.924521,
    "dec": -8.3376,
    "pmra": -792.63,
    "pmdec": -874.15,
    "rv": 0,
    "Asset Name": "ASTRID 000088",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Round Brush",
    "Outfit": "Emperor",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002021,
    "y_1": 0.00000951,
    "z_1": -0.00002407,
    "Distance in Parsecs_1": 5.7395,
    "HYG Star ID_1": 119112,
    "Gliese Catalog_1": "Gl 644B"
  },
  {
    "Astrid #": "ASTRID 000089",
    "HYG Star ID": 119113,
    "Gliese Catalog": "Gl 644C",
    "Distance in Parsecs": 5.7395,
    "mag": 16.78,
    "Absolute Visual Magnitude": 17.986,
    "Spectral Type": "M7",
    "Color Index": 1.99,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.575156,
    "y": -5.456183,
    "z": -0.83818,
    "vx": -0.00002611,
    "vy": -0.000009820000000000001,
    "vz": -0.00002713,
    "rarad": 4.43134049002075,
    "decrad": -0.14653509306535,
    "pmrarad": -0.00000389765958482638,
    "pmdecrad": -0.000004253561307,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 82565,
    "base": "Gl 644",
    "lum": 0.00000556672798150947,
    "ra": 16.926474,
    "dec": -8.395842,
    "pmra": -803.95,
    "pmdec": -877.36,
    "rv": 20,
    "Asset Name": "ASTRID 000089",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Sharky",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002611,
    "y_1": -0.000009820000000000001,
    "z_1": -0.00002713,
    "Distance in Parsecs_1": 5.7395,
    "HYG Star ID_1": 119113,
    "Gliese Catalog_1": "Gl 644C"
  },
  {
    "Astrid #": "ASTRID 000090",
    "HYG Star ID": 29222,
    "Hipparcos Catalogue": 29295,
    "Henry Draper Catalogue": 42581,
    "Gliese Catalog": "Gl 229",
    "Distance in Parsecs": 5.7534,
    "mag": 8.15,
    "Absolute Visual Magnitude": 9.35,
    "Spectral Type": "M1/M2V",
    "Color Index": 1.487,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.246337,
    "y": 5.333894,
    "z": -2.142671,
    "vx": 0.00000393,
    "vy": -0.0000023,
    "vz": -0.00002046,
    "rarad": 1.61694730687179,
    "decrad": -0.381610010279961,
    "pmrarad": -6.64243223729166e-7,
    "pmdecrad": -0.000003461860567,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 29222,
    "lum": 0.0158489319246111,
    "ra": 6.176284,
    "dec": -21.864643,
    "pmra": -137.01,
    "pmdec": -714.06,
    "rv": 5.2,
    "Asset Name": "ASTRID 000090",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Heartbreaker",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000393,
    "y_1": -0.0000023,
    "z_1": -0.00002046,
    "Distance in Parsecs_1": 5.7534,
    "HYG Star ID_1": 29222,
    "Hipparcos Catalogue_1": 29295,
    "Henry Draper Catalogue_1": 42581,
    "Gliese Catalog_1": "Gl 229"
  },
  {
    "Astrid #": "ASTRID 000091",
    "HYG Star ID": 95799,
    "Hipparcos Catalogue": 96100,
    "Henry Draper Catalogue": 185144,
    "Harvard Revised Catalogue": 7462,
    "Gliese Catalog": "Gl 764",
    "Distance in Parsecs": 5.7547,
    "mag": 4.67,
    "Absolute Visual Magnitude": 5.87,
    "Spectral Type": "K0V",
    "Color Index": 0.786,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.784452,
    "y": -1.840016,
    "z": 5.396152,
    "vx": 0.00003712,
    "vy": -0.00004452,
    "vz": 0.00001019,
    "rarad": 5.11533717611695,
    "decrad": 1.21581687059836,
    "pmrarad": 0.00000290127050854861,
    "pmdecrad": -0.000008429988758,
    "bayer": "Sig",
    "flam": 61,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 95799,
    "lum": 0.390840895792401,
    "bf": "61Sig Dra",
    "ra": 19.539149,
    "dec": 69.661175,
    "pmra": 598.43,
    "pmdec": -1738.81,
    "rv": 28.2,
    "Asset Name": "ASTRID 000091",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Sweetheart",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00003712,
    "y_1": -0.00004452,
    "z_1": 0.00001019,
    "Distance in Parsecs_1": 5.7547,
    "HYG Star ID_1": 95799,
    "Hipparcos Catalogue_1": 96100,
    "Henry Draper Catalogue_1": 185144,
    "Harvard Revised Catalogue_1": 7462,
    "Gliese Catalog_1": "Gl 764"
  },
  {
    "Astrid #": "ASTRID 000092",
    "HYG Star ID": 118447,
    "Gliese Catalog": "Gl 251",
    "Distance in Parsecs": 5.7604,
    "mag": 10.01,
    "Absolute Visual Magnitude": 11.208,
    "Spectral Type": "dM4",
    "Color Index": 1.57,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.141059,
    "y": 4.680085,
    "z": 3.160773,
    "vx": 0.00001434,
    "vy": 0.00002989,
    "vz": 0.00000363,
    "rarad": 1.80997790646014,
    "decrad": 0.58068357442135,
    "pmrarad": -0.000003648610797125,
    "pmdecrad": -0.000001915571573,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 118447,
    "lum": 0.00286285927087211,
    "ra": 6.913606,
    "dec": 33.270718,
    "pmra": -752.58,
    "pmdec": -395.12,
    "rv": 22.9,
    "Asset Name": "ASTRID 000092",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Braided Ponytail",
    "Outfit": "School Uniform",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00001434,
    "y_1": 0.00002989,
    "z_1": 0.00000363,
    "Distance in Parsecs_1": 5.7604,
    "HYG Star ID_1": 118447,
    "Gliese Catalog_1": "Gl 251"
  },
  {
    "Astrid #": "ASTRID 000093",
    "HYG Star ID": 45211,
    "Hipparcos Catalogue": 45343,
    "Henry Draper Catalogue": 79210,
    "Gliese Catalog": "Gl 338A",
    "Distance in Parsecs": 5.8113,
    "mag": 7.64,
    "Absolute Visual Magnitude": 8.819,
    "Spectral Type": "M0V",
    "Color Index": 1.41,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.642238,
    "y": 2.329858,
    "z": 4.62201,
    "vx": 0.00001274,
    "vy": 0.00004636,
    "vz": 0.00000153,
    "rarad": 2.41898086870063,
    "decrad": 0.919555072350302,
    "pmrarad": -0.00000743500564226388,
    "pmdecrad": -0.000002728531394,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 45211,
    "base": "Gl 338",
    "lum": 0.0258463963576984,
    "var_min": 7.921,
    "var_max": 7.291,
    "ra": 9.239826,
    "dec": 52.686625,
    "pmra": -1533.58,
    "pmdec": -562.8,
    "rv": 13.7,
    "Asset Name": "ASTRID 000093",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Determined",
    "Hair": "Side Part",
    "Outfit": "Wizard",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001274,
    "y_1": 0.00004636,
    "z_1": 0.00000153,
    "Distance in Parsecs_1": 5.8113,
    "HYG Star ID_1": 45211,
    "Hipparcos Catalogue_1": 45343,
    "Henry Draper Catalogue_1": 79210,
    "Gliese Catalog_1": "Gl 338A"
  },
  {
    "Astrid #": "ASTRID 000094",
    "HYG Star ID": 26792,
    "Hipparcos Catalogue": 26857,
    "Gliese Catalog": "Gl 213",
    "Distance in Parsecs": 5.8292,
    "mag": 11.56,
    "Absolute Visual Magnitude": 12.732,
    "Spectral Type": "M5",
    "Color Index": 1.675,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.44278,
    "y": 5.674935,
    "z": 1.260815,
    "vx": -0.00004736,
    "vy": 0.00011909,
    "vz": -0.00001997,
    "rarad": 1.49292836109396,
    "decrad": 0.21798005957107,
    "pmrarad": 0.00000969166788114583,
    "pmdecrad": -0.000007614677592,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 26792,
    "lum": 0.000703396171216247,
    "var": "V1352",
    "var_min": 11.635,
    "var_max": 11.475,
    "ra": 5.702566,
    "dec": 12.489337,
    "pmra": 1999.05,
    "pmdec": -1570.64,
    "rv": 105.6,
    "Asset Name": "ASTRID 000094",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Charmer",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00004736,
    "y_1": 0.00011909,
    "z_1": -0.00001997,
    "Distance in Parsecs_1": 5.8292,
    "HYG Star ID_1": 26792,
    "Hipparcos Catalogue_1": 26857,
    "Gliese Catalog_1": "Gl 213"
  },
  {
    "Astrid #": "ASTRID 000095",
    "HYG Star ID": 86722,
    "Hipparcos Catalogue": 86990,
    "Gliese Catalog": "Gl 693",
    "Distance in Parsecs": 5.8316,
    "mag": 10.75,
    "Absolute Visual Magnitude": 11.921,
    "Spectral Type": "M5",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.184376,
    "y": -3.142875,
    "z": -4.907535,
    "vx": -0.00002601,
    "vy": 0.00009738,
    "vz": 0.00007834,
    "rarad": 4.65383212477406,
    "decrad": -1.00040601351743,
    "pmrarad": -0.0000054292829644375,
    "pmdecrad": -0.000006557201992,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 86722,
    "lum": 0.00148456767505227,
    "var_min": 10.803,
    "var_max": 10.673,
    "ra": 17.776329,
    "dec": -57.319042,
    "pmra": -1119.87,
    "pmdec": -1352.52,
    "rv": -115,
    "Asset Name": "ASTRID 000095",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Fedora",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00002601,
    "y_1": 0.00009738,
    "z_1": 0.00007834,
    "Distance in Parsecs_1": 5.8316,
    "HYG Star ID_1": 86722,
    "Hipparcos Catalogue_1": 86990,
    "Gliese Catalog_1": "Gl 693"
  },
  {
    "Astrid #": "ASTRID 000096",
    "HYG Star ID": 72957,
    "Hipparcos Catalogue": 73184,
    "Henry Draper Catalogue": 131977,
    "Harvard Revised Catalogue": 5568,
    "Gliese Catalog": "Gl 570A",
    "Distance in Parsecs": 5.8404,
    "mag": 5.72,
    "Absolute Visual Magnitude": 6.888,
    "Spectral Type": "K4V",
    "Color Index": 1.024,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.887091,
    "y": -3.802093,
    "z": -2.13259,
    "vx": 0.00001342,
    "vy": -0.00002784,
    "vz": -0.00005635,
    "rarad": 3.91593377615356,
    "decrad": -0.373770550017621,
    "pmrarad": 0.00000501384612156944,
    "pmdecrad": -0.000008365944871,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 72957,
    "base": "Gl 570",
    "lum": 0.153038253217743,
    "var": "KX",
    "var_min": 5.733,
    "var_max": 5.683,
    "ra": 14.957765,
    "dec": -21.415475,
    "pmra": 1034.18,
    "pmdec": -1725.6,
    "rv": 29.1,
    "Asset Name": "ASTRID 000096",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Fire",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00001342,
    "y_1": -0.00002784,
    "z_1": -0.00005635,
    "Distance in Parsecs_1": 5.8404,
    "HYG Star ID_1": 72957,
    "Hipparcos Catalogue_1": 73184,
    "Henry Draper Catalogue_1": 131977,
    "Harvard Revised Catalogue_1": 5568,
    "Gliese Catalog_1": "Gl 570A"
  },
  {
    "Astrid #": "ASTRID 000097",
    "HYG Star ID": 94466,
    "Hipparcos Catalogue": 94761,
    "Henry Draper Catalogue": 180617,
    "Gliese Catalog": "Gl 752A",
    "Distance in Parsecs": 5.8699,
    "mag": 9.12,
    "Absolute Visual Magnitude": 10.277,
    "Spectral Type": "M3.5V",
    "Color Index": 1.464,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.925584,
    "y": -5.520142,
    "z": 0.528861,
    "vx": -0.00000225,
    "vy": -0.00004356,
    "vz": -0.00003439,
    "rarad": 5.04802002606587,
    "decrad": 0.0902143906918987,
    "pmrarad": -0.00000280639247126388,
    "pmdecrad": -0.000006456263783,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 94466,
    "base": "Gl 752",
    "lum": 0.00674838730870757,
    "var": "V1428",
    "var_min": 9.154,
    "var_max": 9.084,
    "ra": 19.282016,
    "dec": 5.168904,
    "pmra": -578.86,
    "pmdec": -1331.7,
    "rv": 36.3,
    "Asset Name": "ASTRID 000097",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Honest Lad",
    "Outfit": "Boxer",
    "Special": "Astral Burst",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00000225,
    "y_1": -0.00004356,
    "z_1": -0.00003439,
    "Distance in Parsecs_1": 5.8699,
    "HYG Star ID_1": 94466,
    "Hipparcos Catalogue_1": 94761,
    "Henry Draper Catalogue_1": 180617,
    "Gliese Catalog_1": "Gl 752A"
  },
  {
    "Astrid #": "ASTRID 000098",
    "HYG Star ID": 119275,
    "Gliese Catalog": "Gl 752B",
    "Distance in Parsecs": 5.8734,
    "mag": 17.52,
    "Absolute Visual Magnitude": 18.676,
    "Spectral Type": "dM5  e",
    "Color Index": 2.2,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 1.927832,
    "y": -5.522905,
    "z": 0.527077,
    "vx": -0.00001426,
    "vy": -0.00000863,
    "vz": -0.00003808,
    "rarad": 5.04822774993782,
    "decrad": 0.08986046040035,
    "pmrarad": -0.00000277706124359027,
    "pmdecrad": -0.000006510751993,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 94466,
    "base": "Gl 752",
    "lum": 0.00000294849231389436,
    "ra": 19.28281,
    "dec": 5.148625,
    "pmra": -572.81,
    "pmdec": -1342.94,
    "rv": 0,
    "Asset Name": "ASTRID 000098",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Knocked Out",
    "Hair": "Headband",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001426,
    "y_1": -0.00000863,
    "z_1": -0.00003808,
    "Distance in Parsecs_1": 5.8734,
    "HYG Star ID_1": 119275,
    "Gliese Catalog_1": "Gl 752B"
  },
  {
    "Astrid #": "ASTRID 000099",
    "HYG Star ID": 118532,
    "Gliese Catalog": "Gl 300",
    "Distance in Parsecs": 5.8824,
    "mag": 12.1,
    "Absolute Visual Magnitude": 13.252,
    "Spectral Type": "M4",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.993375,
    "y": 4.579649,
    "z": -2.16082,
    "vx": 0.00000328,
    "vy": -0.00000661,
    "vz": -0.00001855,
    "rarad": 2.14971540268083,
    "decrad": -0.3761436761028,
    "pmrarad": 1.48013616673611e-7,
    "pmdecrad": -0.000003390462056,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118532,
    "lum": 0.000435712480503083,
    "ra": 8.211308,
    "dec": -21.551445,
    "pmra": 30.53,
    "pmdec": -699.33,
    "rv": 0,
    "Asset Name": "ASTRID 000099",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Honest Lad",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000328,
    "y_1": -0.00000661,
    "z_1": -0.00001855,
    "Distance in Parsecs_1": 5.8824,
    "HYG Star ID_1": 118532,
    "Gliese Catalog_1": "Gl 300"
  },
  {
    "Astrid #": "ASTRID 000100",
    "HYG Star ID": 72955,
    "Hipparcos Catalogue": 73182,
    "Henry Draper Catalogue": 131976,
    "Gliese Catalog": "Gl 570B",
    "Distance in Parsecs": 5.9252,
    "mag": 8.01,
    "Absolute Visual Magnitude": 9.146,
    "Spectral Type": "K5V",
    "Color Index": 1.519,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.944034,
    "y": -3.856974,
    "z": -2.163168,
    "vx": 0.00001332,
    "vy": -0.00002663,
    "vz": -0.00005499,
    "rarad": 3.91582798984254,
    "decrad": -0.373701518075445,
    "pmrarad": 0.00000478535343392361,
    "pmdecrad": -0.000008080922908,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 2,
    "comp_primary": 72957,
    "base": "Gl 570",
    "lum": 0.019124936418442,
    "ra": 14.957361,
    "dec": -21.41152,
    "pmra": 987.05,
    "pmdec": -1666.81,
    "rv": 27.9,
    "Asset Name": "ASTRID 000100",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Naughty Dreams",
    "Hair": "Undercut",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00001332,
    "y_1": -0.00002663,
    "z_1": -0.00005499,
    "Distance in Parsecs_1": 5.9252,
    "HYG Star ID_1": 72955,
    "Hipparcos Catalogue_1": 73182,
    "Henry Draper Catalogue_1": 131976,
    "Gliese Catalog_1": "Gl 570B"
  },
  {
    "Astrid #": "ASTRID 000101",
    "HYG Star ID": 75841,
    "Hipparcos Catalogue": 76074,
    "Gliese Catalog": "Gl 588",
    "Distance in Parsecs": 5.9291,
    "mag": 9.31,
    "Absolute Visual Magnitude": 10.445,
    "Spectral Type": "M0",
    "Color Index": 1.524,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.678394,
    "y": -3.561312,
    "z": -3.911408,
    "vx": -0.00002244,
    "vy": 0.00002647,
    "vz": -0.00003262,
    "rarad": 4.06757407431311,
    "decrad": -0.720394881472788,
    "pmrarad": -0.0000057085356444375,
    "pmdecrad": -0.000004988054033,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 75841,
    "lum": 0.00578096047405717,
    "ra": 15.536988,
    "dec": -41.275586,
    "pmra": -1177.47,
    "pmdec": -1028.86,
    "rv": 15.4,
    "Asset Name": "ASTRID 000101",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Braided Bun",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00002244,
    "y_1": 0.00002647,
    "z_1": -0.00003262,
    "Distance in Parsecs_1": 5.9291,
    "HYG Star ID_1": 75841,
    "Hipparcos Catalogue_1": 76074,
    "Gliese Catalog_1": "Gl 588"
  },
  {
    "Astrid #": "ASTRID 000102",
    "HYG Star ID": 84147,
    "Hipparcos Catalogue": 84405,
    "Henry Draper Catalogue": 155885,
    "Harvard Revised Catalogue": 6401,
    "Gliese Catalog": "Gl 663A",
    "Distance in Parsecs": 5.9333,
    "mag": 4.33,
    "Absolute Visual Magnitude": 5.464,
    "Spectral Type": "K2:III:",
    "Color Index": 0.855,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.027046,
    "y": -5.204798,
    "z": -2.656955,
    "vx": -0.00001067,
    "vy": 0.00001628,
    "vz": -0.00002983,
    "rarad": 4.51756739350317,
    "decrad": -0.464306961879931,
    "pmrarad": -0.00000229651392342361,
    "pmdecrad": -0.000005545929135,
    "flam": 36,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84147,
    "base": "Gl 663",
    "lum": 0.568067574106771,
    "bf": "36    Oph",
    "ra": 17.255836,
    "dec": -26.602829,
    "pmra": -473.69,
    "pmdec": -1143.93,
    "rv": 0.9,
    "Asset Name": "ASTRID 000102",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Medium Bob",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001067,
    "y_1": 0.00001628,
    "z_1": -0.00002983,
    "Distance in Parsecs_1": 5.9333,
    "HYG Star ID_1": 84147,
    "Hipparcos Catalogue_1": 84405,
    "Henry Draper Catalogue_1": 155885,
    "Harvard Revised Catalogue_1": 6401,
    "Gliese Catalog_1": "Gl 663A"
  },
  {
    "Astrid #": "ASTRID 000103",
    "HYG Star ID": 118021,
    "Gliese Catalog": "Gl 34B",
    "Distance in Parsecs": 5.9527,
    "mag": 7.51,
    "Absolute Visual Magnitude": 8.636,
    "Spectral Type": "K7 V",
    "Color Index": 1.39,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.098396,
    "y": 0.673787,
    "z": 5.03855,
    "vx": 0.00001103,
    "vy": 0.0000348,
    "vz": 0.00000126,
    "rarad": 0.213895944355341,
    "decrad": 1.009103388108,
    "pmrarad": 0.00000532092710683333,
    "pmdecrad": -0.00000246989784,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 2,
    "comp_primary": 3814,
    "base": "Gl  34",
    "lum": 0.0305914455948641,
    "ra": 0.817022,
    "dec": 57.817365,
    "pmra": 1097.52,
    "pmdec": -509.45,
    "rv": 10.5,
    "Asset Name": "ASTRID 000103",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Windy",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001103,
    "y_1": 0.0000348,
    "z_1": 0.00000126,
    "Distance in Parsecs_1": 5.9527,
    "HYG Star ID_1": 118021,
    "Gliese Catalog_1": "Gl 34B"
  },
  {
    "Astrid #": "ASTRID 000104",
    "HYG Star ID": 3814,
    "Hipparcos Catalogue": 3821,
    "Henry Draper Catalogue": 4614,
    "Harvard Revised Catalogue": 219,
    "Gliese Catalog": "Gl 34A",
    "Distance in Parsecs": 5.9531,
    "mag": 3.46,
    "Absolute Visual Magnitude": 4.586,
    "Spectral Type": "G0V SB",
    "Color Index": 0.587,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.098462,
    "y": 0.674227,
    "z": 5.038376,
    "vx": 0.00001105,
    "vy": 0.00003451,
    "vz": -0.0000015,
    "rarad": 0.214219875507523,
    "decrad": 1.00906537697707,
    "pmrarad": 0.00000527045800268749,
    "pmdecrad": -0.000002713259763,
    "bayer": "Eta",
    "flam": 24,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 3814,
    "base": "Gl  34",
    "lum": 1.27526370645972,
    "var": "Eta",
    "var_min": 3.507,
    "var_max": 3.457,
    "bf": "24Eta Cas",
    "ra": 0.81826,
    "dec": 57.815187,
    "pmra": 1087.11,
    "pmdec": -559.65,
    "rv": 8.2,
    "Asset Name": "ASTRID 000104",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Upset",
    "Hair": "Heartbreaker",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001105,
    "y_1": 0.00003451,
    "z_1": -0.0000015,
    "Distance in Parsecs_1": 5.9531,
    "HYG Star ID_1": 3814,
    "Hipparcos Catalogue_1": 3821,
    "Henry Draper Catalogue_1": 4614,
    "Harvard Revised Catalogue_1": 219,
    "Gliese Catalog_1": "Gl 34A"
  },
  {
    "Astrid #": "ASTRID 000105",
    "HYG Star ID": 37659,
    "Hipparcos Catalogue": 37766,
    "Gliese Catalog": "Gl 285",
    "Distance in Parsecs": 5.9566,
    "mag": 11.19,
    "Absolute Visual Magnitude": 12.315,
    "Spectral Type": "M4.5Ve",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.621889,
    "y": 5.336048,
    "z": 0.369099,
    "vx": -0.00000348,
    "vy": 0.00002967,
    "vz": -0.00001129,
    "rarad": 2.02750351220177,
    "decrad": 0.0620020118275262,
    "pmrarad": -0.00000167197694013194,
    "pmdecrad": -0.000002185733997,
    "con": "CMi",
    "Full Constellation Name Formula 1": "Canis Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Minor",
    "comp": 1,
    "comp_primary": 37659,
    "lum": 0.00103276140576139,
    "var": "YZ",
    "var_min": 11.284,
    "var_max": 11.084,
    "ra": 7.744493,
    "dec": 3.552454,
    "pmra": -344.87,
    "pmdec": -450.84,
    "rv": 26.8,
    "Asset Name": "ASTRID 000105",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Oh No",
    "Hair": "Horns",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Minor",
    "x_1": -0.00000348,
    "y_1": 0.00002967,
    "z_1": -0.00001129,
    "Distance in Parsecs_1": 5.9566,
    "HYG Star ID_1": 37659,
    "Hipparcos Catalogue_1": 37766,
    "Gliese Catalog_1": "Gl 285"
  },
  {
    "Astrid #": "ASTRID 000106",
    "HYG Star ID": 84220,
    "Hipparcos Catalogue": 84478,
    "Henry Draper Catalogue": 156026,
    "Gliese Catalog": "Gl 664",
    "Distance in Parsecs": 5.9705,
    "mag": 6.33,
    "Absolute Visual Magnitude": 7.45,
    "Spectral Type": "K5V",
    "Color Index": 1.144,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.014027,
    "y": -5.243915,
    "z": -2.668326,
    "vx": -0.00001086,
    "vy": 0.00001699,
    "vz": -0.00002904,
    "rarad": 4.52137691012756,
    "decrad": -0.463317661700126,
    "pmrarad": -0.00000232569970699305,
    "pmdecrad": -0.000005446251443,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84220,
    "lum": 0.0912010839355909,
    "var": "V2215",
    "var_min": 6.35,
    "var_max": 6.32,
    "ra": 17.270388,
    "dec": -26.546147,
    "pmra": -479.71,
    "pmdec": -1123.37,
    "rv": -0.1,
    "Asset Name": "ASTRID 000106",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Innocent",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001086,
    "y_1": 0.00001699,
    "z_1": -0.00002904,
    "Distance in Parsecs_1": 5.9705,
    "HYG Star ID_1": 84220,
    "Hipparcos Catalogue_1": 84478,
    "Henry Draper Catalogue_1": 156026,
    "Gliese Catalog_1": "Gl 664"
  },
  {
    "Astrid #": "ASTRID 000107",
    "HYG Star ID": 83882,
    "Hipparcos Catalogue": 84140,
    "Henry Draper Catalogue": 155876,
    "Gliese Catalog": "Gl 661A",
    "Distance in Parsecs": 5.9776,
    "mag": 9.31,
    "Absolute Visual Magnitude": 10.427,
    "Spectral Type": "K5",
    "Color Index": 1.485,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.866144,
    "y": -4.086415,
    "z": 4.275453,
    "vx": 0.00000684,
    "vy": -0.00001326,
    "vz": -0.00005418,
    "rarad": 4.50351736961339,
    "decrad": 0.797021595908026,
    "pmrarad": 0.00000158029867313888,
    "pmdecrad": -0.000007716924797,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83882,
    "base": "Gl 661",
    "lum": 0.005877599636806,
    "ra": 17.202169,
    "dec": 45.665974,
    "pmra": 325.96,
    "pmdec": -1591.73,
    "rv": -30,
    "Asset Name": "ASTRID 000107",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Undercut",
    "Outfit": "River Spirit",
    "Special": "Astral Burst",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000684,
    "y_1": -0.00001326,
    "z_1": -0.00005418,
    "Distance in Parsecs_1": 5.9776,
    "HYG Star ID_1": 83882,
    "Hipparcos Catalogue_1": 84140,
    "Henry Draper Catalogue_1": 155876,
    "Gliese Catalog_1": "Gl 661A"
  },
  {
    "Astrid #": "ASTRID 000108",
    "HYG Star ID": 117106,
    "Hipparcos Catalogue": 117473,
    "Gliese Catalog": "Gl 908",
    "Distance in Parsecs": 5.9776,
    "mag": 8.98,
    "Absolute Visual Magnitude": 10.097,
    "Spectral Type": "M2V",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.965095,
    "y": -0.281077,
    "z": 0.250417,
    "vx": -0.00007024,
    "vy": 0.00003218,
    "vz": -0.00003109,
    "rarad": 6.2360996841816,
    "decrad": 0.0419092486759662,
    "pmrarad": 0.00000482447789794444,
    "pmdecrad": -0.000004694208461,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 117106,
    "lum": 0.00796526079783778,
    "var": "BR",
    "var_min": 9.01,
    "var_max": 8.96,
    "ra": 23.820146,
    "dec": 2.401223,
    "pmra": 995.12,
    "pmdec": -968.25,
    "rv": -71.3,
    "Asset Name": "ASTRID 000108",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Frizzy",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00007024,
    "y_1": 0.00003218,
    "z_1": -0.00003109,
    "Distance in Parsecs_1": 5.9776,
    "HYG Star ID_1": 117106,
    "Hipparcos Catalogue_1": 117473,
    "Gliese Catalog_1": "Gl 908"
  },
  {
    "Astrid #": "ASTRID 000109",
    "HYG Star ID": 119135,
    "Henry Draper Catalogue": 155886,
    "Harvard Revised Catalogue": 6402,
    "Gliese Catalog": "Gl 663B",
    "Distance in Parsecs": 5.9852,
    "mag": 5.07,
    "Absolute Visual Magnitude": 6.185,
    "Spectral Type": "K1 Ve",
    "Color Index": 0.85,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.036233,
    "y": -5.250282,
    "z": -2.680239,
    "vx": -0.00001127,
    "vy": 0.00001668,
    "vz": -0.00002947,
    "rarad": 4.51754053759964,
    "decrad": -0.46431369405225,
    "pmrarad": -0.00000238736800715972,
    "pmdecrad": -0.000005464461045,
    "flam": 36,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 2,
    "comp_primary": 84147,
    "base": "Gl 663",
    "lum": 0.292415237784333,
    "bf": "36    Oph",
    "ra": 17.255734,
    "dec": -26.603215,
    "pmra": -492.43,
    "pmdec": -1127.13,
    "rv": 0.5,
    "Asset Name": "ASTRID 000109",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Bucket Hat",
    "Outfit": "School Uniform",
    "Special": "V1 Flight System",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001127,
    "y_1": 0.00001668,
    "z_1": -0.00002947,
    "Distance in Parsecs_1": 5.9852,
    "HYG Star ID_1": 119135,
    "Henry Draper Catalogue_1": 155886,
    "Harvard Revised Catalogue_1": 6402,
    "Gliese Catalog_1": "Gl 663B"
  },
  {
    "Astrid #": "ASTRID 000110",
    "HYG Star ID": 99143,
    "Hipparcos Catalogue": 99461,
    "Henry Draper Catalogue": 191408,
    "Harvard Revised Catalogue": 7703,
    "Gliese Catalog": "Gl 783A",
    "Distance in Parsecs": 6.015,
    "mag": 5.32,
    "Absolute Visual Magnitude": 6.424,
    "Spectral Type": "K2V",
    "Color Index": 0.868,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.632166,
    "y": -4.084363,
    "z": -3.543434,
    "vx": -0.00006156,
    "vy": 0.00012012,
    "vz": 0.0000411,
    "rarad": 5.28484785982072,
    "decrad": -0.63008501456744,
    "pmrarad": 0.00000221506522509027,
    "pmdecrad": -0.000007635379136,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 99143,
    "base": "Gl 783",
    "lum": 0.234638892446412,
    "ra": 20.186632,
    "dec": -36.101212,
    "pmra": 456.89,
    "pmdec": -1574.91,
    "rv": -129.8,
    "Asset Name": "ASTRID 000110",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Uwu",
    "Hair": "Grecian",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00006156,
    "y_1": 0.00012012,
    "z_1": 0.0000411,
    "Distance in Parsecs_1": 6.015,
    "HYG Star ID_1": 99143,
    "Hipparcos Catalogue_1": 99461,
    "Henry Draper Catalogue_1": 191408,
    "Harvard Revised Catalogue_1": 7703,
    "Gliese Catalog_1": "Gl 783A"
  },
  {
    "Astrid #": "ASTRID 000111",
    "HYG Star ID": 15471,
    "Hipparcos Catalogue": 15510,
    "Henry Draper Catalogue": 20794,
    "Harvard Revised Catalogue": 1008,
    "Gliese Catalog": "Gl 139",
    "Proper Name": "82 G. Eri",
    "Distance in Parsecs": 6.0434,
    "mag": 4.26,
    "Absolute Visual Magnitude": 5.354,
    "Spectral Type": "G8V",
    "Color Index": 0.711,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.839238,
    "y": 3.381499,
    "z": -4.127499,
    "vx": -0.00001715,
    "vy": 0.00011797,
    "vz": -0.00004501,
    "rarad": 0.872300798990329,
    "decrad": -0.751709535787738,
    "pmrarad": 0.0000147290274662222,
    "pmdecrad": 0.000003521395687,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 15471,
    "lum": 0.628637088413049,
    "ra": 3.331944,
    "dec": -43.069784,
    "pmra": 3038.08,
    "pmdec": 726.34,
    "rv": 86.7,
    "Asset Name": "ASTRID 000111",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Worried Vamp",
    "Hair": "Fedora",
    "Outfit": "Suit",
    "Special": "Tiny Demon Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "82 G. Eri",
    "Constellation": "Eridanus",
    "x_1": -0.00001715,
    "y_1": 0.00011797,
    "z_1": -0.00004501,
    "Distance in Parsecs_1": 6.0434,
    "HYG Star ID_1": 15471,
    "Hipparcos Catalogue_1": 15510,
    "Henry Draper Catalogue_1": 20794,
    "Harvard Revised Catalogue_1": 1008,
    "Gliese Catalog_1": "Gl 139"
  },
  {
    "Astrid #": "ASTRID 000112",
    "HYG Star ID": 119334,
    "Gliese Catalog": "Gl 783B",
    "Distance in Parsecs": 6.0518,
    "mag": 11.5,
    "Absolute Visual Magnitude": 12.591,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.648766,
    "y": -4.11005,
    "z": -3.565924,
    "vx": -0.00000343,
    "vy": 0.00002996,
    "vz": -0.00003711,
    "rarad": 5.28483411551061,
    "decrad": -0.6301099860941,
    "pmrarad": 0.00000220469021232638,
    "pmdecrad": -0.000007588681882,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 2,
    "comp_primary": 99143,
    "base": "Gl 783",
    "lum": 0.000800940030533287,
    "ra": 20.186579,
    "dec": -36.102643,
    "pmra": 454.75,
    "pmdec": -1565.28,
    "rv": 0,
    "Asset Name": "ASTRID 000112",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Innocent",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00000343,
    "y_1": 0.00002996,
    "z_1": -0.00003711,
    "Distance in Parsecs_1": 6.0518,
    "HYG Star ID_1": 119334,
    "Gliese Catalog_1": "Gl 783B"
  },
  {
    "Astrid #": "ASTRID 000113",
    "HYG Star ID": 71027,
    "Hipparcos Catalogue": 71253,
    "Gliese Catalog": "Gl 555",
    "Distance in Parsecs": 6.061,
    "mag": 11.32,
    "Absolute Visual Magnitude": 12.407,
    "Spectral Type": "M4",
    "Color Index": 1.633,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.626156,
    "y": -3.689053,
    "z": -1.313877,
    "vx": -0.00001654,
    "vy": 2.5e-7,
    "vz": 0.00001507,
    "rarad": 3.81476724342205,
    "decrad": -0.218507454297218,
    "pmrarad": -0.00000173320890798611,
    "pmdecrad": 0.000002885223175,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 71027,
    "lum": 0.0009488553267278,
    "var": "HN",
    "var_min": 11.397,
    "var_max": 11.177,
    "ra": 14.571338,
    "dec": -12.519555,
    "pmra": -357.5,
    "pmdec": 595.12,
    "rv": 9,
    "Asset Name": "ASTRID 000113",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Curly",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00001654,
    "y_1": 2.5e-7,
    "z_1": 0.00001507,
    "Distance in Parsecs_1": 6.061,
    "HYG Star ID_1": 71027,
    "Hipparcos Catalogue_1": 71253,
    "Gliese Catalog_1": "Gl 555"
  },
  {
    "Astrid #": "ASTRID 000114",
    "HYG Star ID": 98924,
    "Hipparcos Catalogue": 99240,
    "Henry Draper Catalogue": 190248,
    "Harvard Revised Catalogue": 7665,
    "Gliese Catalog": "Gl 780",
    "Distance in Parsecs": 6.1084,
    "mag": 3.55,
    "Absolute Visual Magnitude": 4.62,
    "Spectral Type": "G5IV-Vvar",
    "Color Index": 0.751,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.313772,
    "y": -2.087713,
    "z": -5.587991,
    "vx": 0.00000926,
    "vy": 0.00005259,
    "vz": 0.00000678,
    "rarad": 5.27398982895057,
    "decrad": -1.15509499737586,
    "pmrarad": 0.00000586765149439583,
    "pmdecrad": -0.000005480042956,
    "bayer": "Del",
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 98924,
    "lum": 1.23594743344451,
    "bf": "Del Pav",
    "ra": 20.145157,
    "dec": -66.182068,
    "pmra": 1210.29,
    "pmdec": -1130.34,
    "rv": -21.7,
    "Asset Name": "ASTRID 000114",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Fire",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00000926,
    "y_1": 0.00005259,
    "z_1": 0.00000678,
    "Distance in Parsecs_1": 6.1084,
    "HYG Star ID_1": 98924,
    "Hipparcos Catalogue_1": 99240,
    "Henry Draper Catalogue_1": 190248,
    "Harvard Revised Catalogue_1": 7665,
    "Gliese Catalog_1": "Gl 780"
  },
  {
    "Astrid #": "ASTRID 000115",
    "HYG Star ID": 118344,
    "Gliese Catalog": "NN 3323",
    "Distance in Parsecs": 6.135,
    "mag": 12.1,
    "Absolute Visual Magnitude": 13.161,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.526136,
    "y": 5.895661,
    "z": -0.741827,
    "vx": 0.00001505,
    "vy": -0.00000593,
    "vz": -0.00001621,
    "rarad": 1.3174996020283,
    "decrad": -0.12121401032145,
    "pmrarad": -0.00000261653943395833,
    "pmdecrad": -0.000002662606429,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118344,
    "lum": 0.000473805393338825,
    "ra": 5.032478,
    "dec": -6.945051,
    "pmra": -539.7,
    "pmdec": -549.2,
    "rv": 0,
    "Asset Name": "ASTRID 000115",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Middle Part",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001505,
    "y_1": -0.00000593,
    "z_1": -0.00001621,
    "Distance in Parsecs_1": 6.135,
    "HYG Star ID_1": 118344,
    "Gliese Catalog_1": "NN 3323"
  },
  {
    "Astrid #": "ASTRID 000116",
    "HYG Star ID": 119184,
    "Gliese Catalog": "GJ 1221",
    "Distance in Parsecs": 6.1425,
    "mag": 14.15,
    "Absolute Visual Magnitude": 15.208,
    "Spectral Type": "DXP9",
    "Color Index": 0.4,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -0.104366,
    "y": -2.009764,
    "z": 5.80347,
    "vx": -0.00003548,
    "vy": 0.0000323,
    "vz": 0.00001057,
    "rarad": 4.661125495034,
    "decrad": 1.23700161067735,
    "pmrarad": -0.00000603723931985416,
    "pmdecrad": 0.000005248093547,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119184,
    "lum": 0.0000719117735782509,
    "ra": 17.804188,
    "dec": 70.874972,
    "pmra": -1245.27,
    "pmdec": 1082.5,
    "rv": 0,
    "Asset Name": "ASTRID 000116",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Oof",
    "Hair": "Straw Hat",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003548,
    "y_1": 0.0000323,
    "z_1": 0.00001057,
    "Distance in Parsecs_1": 6.1425,
    "HYG Star ID_1": 119184,
    "Gliese Catalog_1": "GJ 1221"
  },
  {
    "Astrid #": "ASTRID 000117",
    "HYG Star ID": 118488,
    "Gliese Catalog": "NN 3454",
    "Distance in Parsecs": 6.1728,
    "mag": 13.22,
    "Absolute Visual Magnitude": 14.268,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.501754,
    "y": 5.59158,
    "z": 0.760864,
    "vx": -0.00000592,
    "vy": -0.00000149,
    "vz": -0.00000851,
    "rarad": 1.99149760817318,
    "decrad": 0.1235751032317,
    "pmrarad": 9.73263463715277e-7,
    "pmdecrad": -0.000001389975366,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118488,
    "lum": 0.000170922800523432,
    "ra": 7.606961,
    "dec": 7.080332,
    "pmra": 200.75,
    "pmdec": -286.7,
    "rv": 0,
    "Asset Name": "ASTRID 000117",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Worried Vamp",
    "Hair": "Bucket Hat",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000592,
    "y_1": -0.00000149,
    "z_1": -0.00000851,
    "Distance in Parsecs_1": 6.1728,
    "HYG Star ID_1": 118488,
    "Gliese Catalog_1": "NN 3454"
  },
  {
    "Astrid #": "ASTRID 000118",
    "HYG Star ID": 115766,
    "Hipparcos Catalogue": 116132,
    "Gliese Catalog": "Gl 896A",
    "Distance in Parsecs": 6.182,
    "mag": 10.05,
    "Absolute Visual Magnitude": 11.094,
    "Spectral Type": "M6Ve",
    "Color Index": 1.19,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.76778,
    "y": -0.711525,
    "z": 2.108009,
    "vx": 0.00000372,
    "vy": 0.00001628,
    "vz": -0.00000138,
    "rarad": 6.16044199231164,
    "decrad": 0.347970810008856,
    "pmrarad": 0.000002687807045,
    "pmdecrad": -3.03541845e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 115766,
    "base": "Gl 896",
    "lum": 0.00317980142769152,
    "var": "EQ",
    "var_min": 10.105,
    "var_max": 9.995,
    "ra": 23.531155,
    "dec": 19.937259,
    "pmra": 554.4,
    "pmdec": -62.61,
    "rv": 1.1,
    "Asset Name": "ASTRID 000118",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Blep",
    "Hair": "Round Brush",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000372,
    "y_1": 0.00001628,
    "z_1": -0.00000138,
    "Distance in Parsecs_1": 6.182,
    "HYG Star ID_1": 115766,
    "Hipparcos Catalogue_1": 116132,
    "Gliese Catalog_1": "Gl 896A"
  },
  {
    "Astrid #": "ASTRID 000119",
    "HYG Star ID": 82565,
    "Hipparcos Catalogue": 82817,
    "Henry Draper Catalogue": 152751,
    "Gliese Catalog": "Gl 644A",
    "Distance in Parsecs": 6.1954,
    "mag": 9.02,
    "Absolute Visual Magnitude": 10.06,
    "Spectral Type": "M3Ve",
    "Color Index": 1.553,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.703055,
    "y": -5.888724,
    "z": -0.898248,
    "vx": -0.00002627,
    "vy": -0.00000117,
    "vz": -0.00002791,
    "rarad": 4.43086446840443,
    "decrad": -0.145496647172177,
    "pmrarad": -0.00000402075377831944,
    "pmdecrad": -0.000004260591106,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82565,
    "base": "Gl 644",
    "lum": 0.00824138115013001,
    "var": "V1054",
    "var_min": 9.057,
    "var_max": 8.977,
    "ra": 16.924656,
    "dec": -8.336344,
    "pmra": -829.34,
    "pmdec": -878.81,
    "rv": 12.1,
    "Asset Name": "ASTRID 000119",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Heartbreaker",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002627,
    "y_1": -0.00000117,
    "z_1": -0.00002791,
    "Distance in Parsecs_1": 6.1954,
    "HYG Star ID_1": 82565,
    "Hipparcos Catalogue_1": 82817,
    "Henry Draper Catalogue_1": 152751,
    "Gliese Catalog_1": "Gl 644A"
  },
  {
    "Astrid #": "ASTRID 000120",
    "HYG Star ID": 99382,
    "Hipparcos Catalogue": 99701,
    "Henry Draper Catalogue": 191849,
    "Gliese Catalog": "Gl 784",
    "Distance in Parsecs": 6.1981,
    "mag": 7.97,
    "Absolute Visual Magnitude": 9.009,
    "Spectral Type": "M0V",
    "Color Index": 1.431,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.410191,
    "y": -3.645204,
    "z": -4.395049,
    "vx": 0.00000526,
    "vy": 0.00003444,
    "vz": 0.00001917,
    "rarad": 5.29658022932711,
    "decrad": -0.788260839301986,
    "pmrarad": 0.00000377272309934722,
    "pmdecrad": -7.73520227e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 99382,
    "lum": 0.0216970155380085,
    "ra": 20.231446,
    "dec": -45.164019,
    "pmra": 778.18,
    "pmdec": -159.55,
    "rv": -31.1,
    "Asset Name": "ASTRID 000120",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Bangs",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00000526,
    "y_1": 0.00003444,
    "z_1": 0.00001917,
    "Distance in Parsecs_1": 6.1981,
    "HYG Star ID_1": 99382,
    "Hipparcos Catalogue_1": 99701,
    "Henry Draper Catalogue_1": 191849,
    "Gliese Catalog_1": "Gl 784"
  },
  {
    "Astrid #": "ASTRID 000121",
    "HYG Star ID": 118644,
    "Gliese Catalog": "GJ 1128",
    "Distance in Parsecs": 6.2112,
    "mag": 12.78,
    "Absolute Visual Magnitude": 13.814,
    "Spectral Type": "m",
    "Color Index": 1.73,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.848526,
    "y": 1.259969,
    "z": -5.793194,
    "vx": -0.00001851,
    "vy": 0.00001489,
    "vz": 0.00003216,
    "rarad": 2.54334423472434,
    "decrad": -1.20227907782365,
    "pmrarad": -3.03105513083333e-7,
    "pmdecrad": 0.000005421443527,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118644,
    "lum": 0.000259656979027378,
    "ra": 9.714859,
    "dec": -68.885517,
    "pmra": -62.52,
    "pmdec": 1118.25,
    "rv": -21,
    "Asset Name": "ASTRID 000121",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Worried Vamp",
    "Hair": "Lion Mane",
    "Outfit": "Star Knight",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001851,
    "y_1": 0.00001489,
    "z_1": 0.00003216,
    "Distance in Parsecs_1": 6.2112,
    "HYG Star ID_1": 118644,
    "Gliese Catalog_1": "GJ 1128"
  },
  {
    "Astrid #": "ASTRID 000122",
    "HYG Star ID": 118995,
    "Gliese Catalog": "NN 3877",
    "Distance in Parsecs": 6.2112,
    "mag": 17.05,
    "Absolute Visual Magnitude": 18.084,
    "Spectral Type": "dM8",
    "Color Index": 1.34,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.928325,
    "y": -3.814685,
    "z": -2.931799,
    "vx": -0.00000164,
    "vy": 0.00001862,
    "vz": -0.00002205,
    "rarad": 3.91233110122674,
    "decrad": -0.49157859378915,
    "pmrarad": -0.00000233413546503472,
    "pmdecrad": -0.000004026595783,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118995,
    "lum": 0.00000508627690306436,
    "ra": 14.944004,
    "dec": -28.165379,
    "pmra": -481.45,
    "pmdec": -830.54,
    "rv": 0,
    "Asset Name": "ASTRID 000122",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Prince",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000164,
    "y_1": 0.00001862,
    "z_1": -0.00002205,
    "Distance in Parsecs_1": 6.2112,
    "HYG Star ID_1": 118995,
    "Gliese Catalog_1": "NN 3877"
  },
  {
    "Astrid #": "ASTRID 000123",
    "HYG Star ID": 74766,
    "Hipparcos Catalogue": 74995,
    "Gliese Catalog": "Gl 581",
    "Distance in Parsecs": 6.2147,
    "mag": 10.57,
    "Absolute Visual Magnitude": 11.603,
    "Spectral Type": "M5",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.96982,
    "y": -4.707934,
    "z": -0.835068,
    "vx": -0.0000218,
    "vy": 0.00003137,
    "vz": -0.00000168,
    "rarad": 4.01184519512315,
    "decrad": -0.134779214757522,
    "pmrarad": -0.00000593678592524305,
    "pmdecrad": -4.82486574e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 74766,
    "lum": 0.00198975681109065,
    "var": "HO",
    "var_min": 10.595,
    "var_max": 10.485,
    "ra": 15.32412,
    "dec": -7.72228,
    "pmra": -1224.55,
    "pmdec": -99.52,
    "rv": -9.4,
    "Asset Name": "ASTRID 000123",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Greasy",
    "Outfit": "Wizard",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.0000218,
    "y_1": 0.00003137,
    "z_1": -0.00000168,
    "Distance in Parsecs_1": 6.2147,
    "HYG Star ID_1": 74766,
    "Hipparcos Catalogue_1": 74995,
    "Gliese Catalog_1": "Gl 581"
  },
  {
    "Astrid #": "ASTRID 000124",
    "HYG Star ID": 119568,
    "Gliese Catalog": "Gl 896B",
    "Distance in Parsecs": 6.2477,
    "mag": 12.4,
    "Absolute Visual Magnitude": 13.421,
    "Spectral Type": "dM6  e",
    "Color Index": 1.65,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.829022,
    "y": -0.718963,
    "z": 2.130394,
    "vx": 0.0000011,
    "vy": 0.00001695,
    "vz": -8.9e-7,
    "rarad": 6.16045455922884,
    "decrad": 0.347971920895199,
    "pmrarad": 0.00000271374457690972,
    "pmdecrad": -8.0542096e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 115766,
    "base": "Gl 896",
    "lum": 0.000372906539962152,
    "ra": 23.531203,
    "dec": 19.937322,
    "pmra": 559.75,
    "pmdec": -16.61,
    "rv": -1.2,
    "Asset Name": "ASTRID 000124",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Determined Neko",
    "Hair": "Innocent",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000011,
    "y_1": 0.00001695,
    "z_1": -8.9e-7,
    "Distance in Parsecs_1": 6.2477,
    "HYG Star ID_1": 119568,
    "Gliese Catalog_1": "Gl 896B"
  },
  {
    "Astrid #": "ASTRID 000125",
    "HYG Star ID": 34511,
    "Hipparcos Catalogue": 34603,
    "Gliese Catalog": "Gl 268",
    "Distance in Parsecs": 6.2945,
    "mag": 11.65,
    "Absolute Visual Magnitude": 12.655,
    "Spectral Type": "M5Ve",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.481414,
    "y": 4.696265,
    "z": 3.921162,
    "vx": -0.00000175,
    "vy": 0.00005014,
    "vz": 0.00000151,
    "rarad": 1.87636745324808,
    "decrad": 0.672465941433575,
    "pmrarad": -0.00000213162879066666,
    "pmdecrad": -0.00000459777902,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 34511,
    "lum": 0.000755092227665434,
    "var": "QY",
    "var_min": 11.712,
    "var_max": 11.562,
    "ra": 7.167196,
    "dec": 38.52946,
    "pmra": -439.68,
    "pmdec": -948.36,
    "rv": 37.9,
    "Asset Name": "ASTRID 000125",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Honest Lad",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00000175,
    "y_1": 0.00005014,
    "z_1": 0.00000151,
    "Distance in Parsecs_1": 6.2945,
    "HYG Star ID_1": 34511,
    "Hipparcos Catalogue_1": 34603,
    "Gliese Catalog_1": "Gl 268"
  },
  {
    "Astrid #": "ASTRID 000126",
    "HYG Star ID": 119134,
    "Gliese Catalog": "Gl 661B",
    "Distance in Parsecs": 6.3223,
    "mag": 10.4,
    "Absolute Visual Magnitude": 11.396,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.916168,
    "y": -4.322284,
    "z": 4.522166,
    "vx": 6e-8,
    "vy": -0.00003503,
    "vz": -0.00003344,
    "rarad": 4.50349159281075,
    "decrad": 0.79701357514875,
    "pmrarad": 0.00000115865621515972,
    "pmdecrad": -0.000007571917025,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 83882,
    "base": "Gl 661",
    "lum": 0.00240768684561244,
    "ra": 17.202071,
    "dec": 45.665514,
    "pmra": 238.99,
    "pmdec": -1561.82,
    "rv": 0,
    "Asset Name": "ASTRID 000126",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Naughty Dreams",
    "Hair": "Ram Horns",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 6e-8,
    "y_1": -0.00003503,
    "z_1": -0.00003344,
    "Distance in Parsecs_1": 6.3223,
    "HYG Star ID_1": 119134,
    "Gliese Catalog_1": "Gl 661B"
  },
  {
    "Astrid #": "ASTRID 000127",
    "HYG Star ID": 45214,
    "Hipparcos Catalogue": 120005,
    "Henry Draper Catalogue": 79211,
    "Gliese Catalog": "Gl 338B",
    "Distance in Parsecs": 6.3918,
    "mag": 7.7,
    "Absolute Visual Magnitude": 8.672,
    "Spectral Type": "K2",
    "Color Index": 1.42,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.906545,
    "y": 2.562201,
    "z": 5.083691,
    "vx": 0.00001281,
    "vy": 0.00005278,
    "vz": -3.7e-7,
    "rarad": 2.41911988099701,
    "decrad": 0.919550733089442,
    "pmrarad": -0.00000752091462645833,
    "pmdecrad": -0.000003181589778,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 45211,
    "base": "Gl 338",
    "lum": 0.0295937499534338,
    "ra": 9.240357,
    "dec": 52.686376,
    "pmra": -1551.3,
    "pmdec": -656.25,
    "rv": 14.7,
    "Asset Name": "ASTRID 000127",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Round Brush",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001281,
    "y_1": 0.00005278,
    "z_1": -3.7e-7,
    "Distance in Parsecs_1": 6.3918,
    "HYG Star ID_1": 45214,
    "Hipparcos Catalogue_1": 120005,
    "Henry Draper Catalogue_1": 79211,
    "Gliese Catalog_1": "Gl 338B"
  },
  {
    "Astrid #": "ASTRID 000128",
    "HYG Star ID": 118865,
    "Gliese Catalog": "GJ 2097",
    "Distance in Parsecs": 6.4103,
    "mag": 12.58,
    "Absolute Visual Magnitude": 13.546,
    "Spectral Type": "m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.737071,
    "y": -1.727679,
    "z": 2.277826,
    "vx": 0.00000537,
    "vy": 0.00000389,
    "vz": -0.00000367,
    "rarad": 3.43409908479338,
    "decrad": 0.363297355493149,
    "pmrarad": -3.39224132284722e-7,
    "pmdecrad": -1.88030137e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118865,
    "lum": 0.000332353303577477,
    "ra": 13.117292,
    "dec": 20.815405,
    "pmra": -69.97,
    "pmdec": -38.78,
    "rv": -7,
    "Asset Name": "ASTRID 000128",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Stunned",
    "Hair": "Elegant",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000537,
    "y_1": 0.00000389,
    "z_1": -0.00000367,
    "Distance in Parsecs_1": 6.4103,
    "HYG Star ID_1": 118865,
    "Gliese Catalog_1": "GJ 2097"
  },
  {
    "Astrid #": "ASTRID 000129",
    "HYG Star ID": 118399,
    "Gliese Catalog": "Gl 223.2",
    "Distance in Parsecs": 6.4599,
    "mag": 14.45,
    "Absolute Visual Magnitude": 15.399,
    "Spectral Type": "DZ9",
    "Color Index": 1.05,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 0.136031,
    "y": 6.441366,
    "z": -0.469711,
    "vx": -0.00001731,
    "vy": -0.00000484,
    "vz": -0.00007201,
    "rarad": 1.54968071953295,
    "decrad": -0.0727760596373,
    "pmrarad": 0.00000266279065908333,
    "pmdecrad": -0.000011177272746,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118399,
    "lum": 0.0000603114819617816,
    "ra": 5.919344,
    "dec": -4.169761,
    "pmra": 549.24,
    "pmdec": -2305.48,
    "rv": 0,
    "Asset Name": "ASTRID 000129",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Charmer",
    "Hair": "Afro",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001731,
    "y_1": -0.00000484,
    "z_1": -0.00007201,
    "Distance in Parsecs_1": 6.4599,
    "HYG Star ID_1": 118399,
    "Gliese Catalog_1": "Gl 223.2"
  },
  {
    "Astrid #": "ASTRID 000130",
    "HYG Star ID": 80218,
    "Hipparcos Catalogue": 80459,
    "Gliese Catalog": "Gl 625",
    "Distance in Parsecs": 6.5164,
    "mag": 10.13,
    "Absolute Visual Magnitude": 11.06,
    "Spectral Type": "M2",
    "Color Index": 1.591,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.525067,
    "y": -3.482882,
    "z": 5.292042,
    "vx": 0.00001377,
    "vy": -0.0000026,
    "vz": -0.00001361,
    "rarad": 4.29965083825927,
    "decrad": 0.947785391277452,
    "pmrarad": 0.00000209580105967361,
    "pmdecrad": -8.27625434e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 80218,
    "lum": 0.00328095293113118,
    "ra": 16.423456,
    "dec": 54.304103,
    "pmra": 432.29,
    "pmdec": -170.71,
    "rv": -12.6,
    "Asset Name": "ASTRID 000130",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mortified",
    "Hair": "Elegant",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001377,
    "y_1": -0.0000026,
    "z_1": -0.00001361,
    "Distance in Parsecs_1": 6.5164,
    "HYG Star ID_1": 80218,
    "Hipparcos Catalogue_1": 80459,
    "Gliese Catalog_1": "Gl 625"
  },
  {
    "Astrid #": "ASTRID 000131",
    "HYG Star ID": 118812,
    "Gliese Catalog": "GJ 1156",
    "Distance in Parsecs": 6.5402,
    "mag": 13.81,
    "Absolute Visual Magnitude": 14.732,
    "Spectral Type": "dM   e",
    "Color Index": 1.88,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -6.395244,
    "y": -0.531553,
    "z": 1.261955,
    "vx": -0.00000213,
    "vy": 0.00004066,
    "vz": 0.0000064,
    "rarad": 3.22452489288354,
    "decrad": 0.19417145105835,
    "pmrarad": -0.00000622326232908333,
    "pmdecrad": 9.96801167e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118812,
    "lum": 0.000111480780336384,
    "ra": 12.316778,
    "dec": 11.125205,
    "pmra": -1283.64,
    "pmdec": 205.6,
    "rv": 0,
    "Asset Name": "ASTRID 000131",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Ponytail",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000213,
    "y_1": 0.00004066,
    "z_1": 0.0000064,
    "Distance in Parsecs_1": 6.5402,
    "HYG Star ID_1": 118812,
    "Gliese Catalog_1": "GJ 1156"
  },
  {
    "Astrid #": "ASTRID 000132",
    "HYG Star ID": 114260,
    "Hipparcos Catalogue": 114622,
    "Henry Draper Catalogue": 219134,
    "Harvard Revised Catalogue": 8832,
    "Gliese Catalog": "Gl 892",
    "Distance in Parsecs": 6.5462,
    "mag": 5.57,
    "Absolute Visual Magnitude": 6.49,
    "Spectral Type": "K3Vvar",
    "Color Index": 1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.475601,
    "y": -0.718451,
    "z": 5.500421,
    "vx": -0.00000425,
    "vy": 0.0000681,
    "vz": -0.00001057,
    "rarad": 6.07926928625852,
    "decrad": 0.997776027980488,
    "pmrarad": 0.0000100568295453402,
    "pmdecrad": 0.000001430054913,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 114260,
    "lum": 0.220800473301889,
    "ra": 23.221098,
    "dec": 57.168355,
    "pmra": 2074.37,
    "pmdec": 294.97,
    "rv": -18.2,
    "Asset Name": "ASTRID 000132",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Bandana",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000425,
    "y_1": 0.0000681,
    "z_1": -0.00001057,
    "Distance in Parsecs_1": 6.5462,
    "HYG Star ID_1": 114260,
    "Hipparcos Catalogue_1": 114622,
    "Henry Draper Catalogue_1": 219134,
    "Harvard Revised Catalogue_1": 8832,
    "Gliese Catalog_1": "Gl 892"
  },
  {
    "Astrid #": "ASTRID 000133",
    "HYG Star ID": 53612,
    "Hipparcos Catalogue": 53767,
    "Gliese Catalog": "Gl 408",
    "Distance in Parsecs": 6.6622,
    "mag": 10.03,
    "Absolute Visual Magnitude": 10.912,
    "Spectral Type": "M3",
    "Color Index": 1.525,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.931445,
    "y": 1.587358,
    "z": 2.58525,
    "vx": -0.00000256,
    "vy": 0.00001494,
    "vz": -0.00000714,
    "rarad": 2.88010439541948,
    "decrad": 0.398510371610101,
    "pmrarad": -0.00000206680920157638,
    "pmdecrad": -0.000001357187417,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 53612,
    "lum": 0.00376010523668495,
    "ra": 11.001188,
    "dec": 22.832962,
    "pmra": -426.31,
    "pmdec": -279.94,
    "rv": 3,
    "Asset Name": "ASTRID 000133",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Little Crown",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000256,
    "y_1": 0.00001494,
    "z_1": -0.00000714,
    "Distance in Parsecs_1": 6.6622,
    "HYG Star ID_1": 53612,
    "Hipparcos Catalogue_1": 53767,
    "Gliese Catalog_1": "Gl 408"
  },
  {
    "Astrid #": "ASTRID 000134",
    "HYG Star ID": 118988,
    "Gliese Catalog": "Gl 566B",
    "Distance in Parsecs": 6.6997,
    "mag": 6.97,
    "Absolute Visual Magnitude": 7.84,
    "Spectral Type": "K4 Ve",
    "Color Index": 1.16,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.64172,
    "y": -4.305423,
    "z": 2.192231,
    "vx": -1e-8,
    "vy": -0.000006,
    "vz": -0.00000212,
    "rarad": 3.88941955531024,
    "decrad": 0.33334477918255,
    "pmrarad": 6.56486204840277e-7,
    "pmdecrad": -4.98296349e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 72432,
    "base": "Gl 566",
    "lum": 0.0636795520907915,
    "ra": 14.856488,
    "dec": 19.099249,
    "pmra": 135.41,
    "pmdec": -102.78,
    "rv": 3.1,
    "Asset Name": "ASTRID 000134",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Angelic",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -1e-8,
    "y_1": -0.000006,
    "z_1": -0.00000212,
    "Distance in Parsecs_1": 6.6997,
    "HYG Star ID_1": 118988,
    "Gliese Catalog_1": "Gl 566B"
  },
  {
    "Astrid #": "ASTRID 000135",
    "HYG Star ID": 105765,
    "Hipparcos Catalogue": 106106,
    "Gliese Catalog": "Gl 829",
    "Distance in Parsecs": 6.7047,
    "mag": 10.33,
    "Absolute Visual Magnitude": 11.198,
    "Spectral Type": "M4",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.062257,
    "y": -3.897986,
    "z": 2.03206,
    "vx": -0.00000217,
    "vy": 0.00004303,
    "vz": 0.00000393,
    "rarad": 5.62699849638453,
    "decrad": 0.307933507278536,
    "pmrarad": 0.0000048873582323125,
    "pmdecrad": 0.000001823917547,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 105765,
    "lum": 0.00288934898155907,
    "var_min": 10.413,
    "var_max": 10.283,
    "ra": 21.493551,
    "dec": 17.64329,
    "pmra": 1008.09,
    "pmdec": 376.21,
    "rv": -24.9,
    "Asset Name": "ASTRID 000135",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Curly",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000217,
    "y_1": 0.00004303,
    "z_1": 0.00000393,
    "Distance in Parsecs_1": 6.7047,
    "HYG Star ID_1": 105765,
    "Hipparcos Catalogue_1": 106106,
    "Gliese Catalog_1": "Gl 829"
  },
  {
    "Astrid #": "ASTRID 000136",
    "HYG Star ID": 72432,
    "Hipparcos Catalogue": 72659,
    "Henry Draper Catalogue": 131156,
    "Harvard Revised Catalogue": 5544,
    "Gliese Catalog": "Gl 566A",
    "Distance in Parsecs": 6.7123,
    "mag": 4.54,
    "Absolute Visual Magnitude": 5.406,
    "Spectral Type": "G8V + K4V",
    "Color Index": 0.72,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.650321,
    "y": -4.313397,
    "z": 2.196442,
    "vx": 0.00000127,
    "vy": -0.00000561,
    "vz": -0.00000146,
    "rarad": 3.88942066038312,
    "decrad": 0.333365915217078,
    "pmrarad": 7.40843785256944e-7,
    "pmdecrad": -3.45575191e-7,
    "bayer": "Xi",
    "flam": 37,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72432,
    "base": "Gl 566",
    "lum": 0.599238902583478,
    "var": "Xi",
    "var_min": 4.57,
    "var_max": 4.52,
    "bf": "37Xi  Boo",
    "ra": 14.856493,
    "dec": 19.10046,
    "pmra": 152.81,
    "pmdec": -71.28,
    "rv": 2.2,
    "Asset Name": "ASTRID 000136",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Middle Part",
    "Outfit": "Tats",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000127,
    "y_1": -0.00000561,
    "z_1": -0.00000146,
    "Distance in Parsecs_1": 6.7123,
    "HYG Star ID_1": 72432,
    "Hipparcos Catalogue_1": 72659,
    "Henry Draper Catalogue_1": 131156,
    "Harvard Revised Catalogue_1": 5544,
    "Gliese Catalog_1": "Gl 566A"
  },
  {
    "Astrid #": "ASTRID 000137",
    "HYG Star ID": 82557,
    "Hipparcos Catalogue": 82809,
    "Gliese Catalog": "Gl 643",
    "Distance in Parsecs": 6.715,
    "mag": 11.73,
    "Absolute Visual Magnitude": 12.595,
    "Spectral Type": "M4",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.847524,
    "y": -6.382123,
    "z": -0.971952,
    "vx": -0.0000203,
    "vy": 0.00002512,
    "vz": -0.00002675,
    "rarad": 4.43060775562883,
    "decrad": -0.14525635072823,
    "pmrarad": -0.00000394381384721527,
    "pmdecrad": -0.000004340197512,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82557,
    "lum": 0.000797994687267975,
    "var_min": 11.852,
    "var_max": 11.622,
    "ra": 16.923675,
    "dec": -8.322576,
    "pmra": -813.47,
    "pmdec": -895.23,
    "rv": -14.1,
    "Asset Name": "ASTRID 000137",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Undercut",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.0000203,
    "y_1": 0.00002512,
    "z_1": -0.00002675,
    "Distance in Parsecs_1": 6.715,
    "HYG Star ID_1": 82557,
    "Hipparcos Catalogue_1": 82809,
    "Gliese Catalog_1": "Gl 643"
  },
  {
    "Astrid #": "ASTRID 000138",
    "HYG Star ID": 118530,
    "Gliese Catalog": "Gl 299",
    "Distance in Parsecs": 6.7568,
    "mag": 12.83,
    "Absolute Visual Magnitude": 13.681,
    "Spectral Type": "dM5",
    "Color Index": 1.77,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.634963,
    "y": 5.599567,
    "z": 1.030592,
    "vx": -0.00002663,
    "vy": -0.00002895,
    "vz": -0.00016987,
    "rarad": 2.14657075166813,
    "decrad": 0.15316541664965,
    "pmrarad": 0.00000563901336264583,
    "pmdecrad": -0.000024621287007,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118530,
    "lum": 0.000293494522226978,
    "ra": 8.199296,
    "dec": 8.775732,
    "pmra": 1163.13,
    "pmdec": -5078.51,
    "rv": -35,
    "Asset Name": "ASTRID 000138",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Braid",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002663,
    "y_1": -0.00002895,
    "z_1": -0.00016987,
    "Distance in Parsecs_1": 6.7568,
    "HYG Star ID_1": 118530,
    "Gliese Catalog_1": "Gl 299"
  },
  {
    "Astrid #": "ASTRID 000139",
    "HYG Star ID": 52866,
    "Hipparcos Catalogue": 53020,
    "Gliese Catalog": "Gl 402",
    "Distance in Parsecs": 6.7604,
    "mag": 11.64,
    "Absolute Visual Magnitude": 12.49,
    "Spectral Type": "M4:",
    "Color Index": 1.679,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.409634,
    "y": 1.9943,
    "z": 0.801412,
    "vx": 0.0000057,
    "vy": 0.00002584,
    "vz": -0.00002645,
    "rarad": 2.83994651538909,
    "decrad": 0.118824614798876,
    "pmrarad": -0.00000389984124638888,
    "pmdecrad": -0.000003925051557,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 52866,
    "lum": 0.000879022516830883,
    "var_min": 11.742,
    "var_max": 11.482,
    "ra": 10.847797,
    "dec": 6.808149,
    "pmra": -804.4,
    "pmdec": -809.6,
    "rv": -0.9,
    "Asset Name": "ASTRID 000139",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Mischievous",
    "Hair": "Round Brush",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.0000057,
    "y_1": 0.00002584,
    "z_1": -0.00002645,
    "Distance in Parsecs_1": 6.7604,
    "HYG Star ID_1": 52866,
    "Hipparcos Catalogue_1": 53020,
    "Gliese Catalog_1": "Gl 402"
  },
  {
    "Astrid #": "ASTRID 000140",
    "HYG Star ID": 119513,
    "Gliese Catalog": "NN 4285",
    "Distance in Parsecs": 6.8027,
    "mag": 11.45,
    "Absolute Visual Magnitude": 12.287,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.609768,
    "y": -0.975622,
    "z": -6.205965,
    "vx": -0.00000271,
    "vy": 0.00002277,
    "vz": -0.00000472,
    "rarad": 5.92522042030695,
    "decrad": -1.14881616327945,
    "pmrarad": 0.00000299590613899305,
    "pmdecrad": -0.000001695005589,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 119513,
    "lum": 0.00105974164188309,
    "ra": 22.632675,
    "dec": -65.822318,
    "pmra": 617.95,
    "pmdec": -349.62,
    "rv": 0,
    "Asset Name": "ASTRID 000140",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Undercut",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000271,
    "y_1": 0.00002277,
    "z_1": -0.00000472,
    "Distance in Parsecs_1": 6.8027,
    "HYG Star ID_1": 119513,
    "Gliese Catalog_1": "NN 4285"
  },
  {
    "Astrid #": "ASTRID 000141",
    "HYG Star ID": 85345,
    "Hipparcos Catalogue": 85605,
    "Distance in Parsecs": 6.8101,
    "mag": 11.39,
    "Absolute Visual Magnitude": 12.224,
    "Color Index": 1.101,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.818464,
    "y": -6.134956,
    "z": 2.840756,
    "vx": 0.00000382,
    "vy": 0.00000434,
    "vz": 0.00001047,
    "rarad": 4.5797618078551,
    "decrad": 0.430294685604072,
    "pmrarad": 4.71869155284722e-7,
    "pmdecrad": 0.000001691611894,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 85345,
    "lum": 0.00112305234754108,
    "ra": 17.493402,
    "dec": 24.654069,
    "pmra": 97.33,
    "pmdec": 348.92,
    "rv": 0,
    "Asset Name": "ASTRID 000141",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Bucket Hat",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000382,
    "y_1": 0.00000434,
    "z_1": 0.00001047,
    "Distance in Parsecs_1": 6.8101,
    "HYG Star ID_1": 85345,
    "Hipparcos Catalogue_1": 85605
  },
  {
    "Astrid #": "ASTRID 000142",
    "HYG Star ID": 84451,
    "Hipparcos Catalogue": 84709,
    "Henry Draper Catalogue": 156384,
    "Harvard Revised Catalogue": 6426,
    "Gliese Catalog": "Gl 667A",
    "Distance in Parsecs": 6.8357,
    "mag": 5.91,
    "Absolute Visual Magnitude": 6.736,
    "Spectral Type": "K4V",
    "Color Index": 1.082,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.997648,
    "y": -5.510603,
    "z": -3.919803,
    "vx": 0.00003765,
    "vy": -0.00000583,
    "vz": -0.00000299,
    "rarad": 4.53327678413649,
    "decrad": -0.610687078844683,
    "pmrarad": 0.00000557167274241666,
    "pmdecrad": -4.40210821e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 84451,
    "base": "Gl 667",
    "lum": 0.176035395361616,
    "ra": 17.315842,
    "dec": -34.989792,
    "pmra": 1149.24,
    "pmdec": -90.8,
    "rv": 0.9,
    "Asset Name": "ASTRID 000142",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Pixie",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00003765,
    "y_1": -0.00000583,
    "z_1": -0.00000299,
    "Distance in Parsecs_1": 6.8357,
    "HYG Star ID_1": 84451,
    "Hipparcos Catalogue_1": 84709,
    "Henry Draper Catalogue_1": 156384,
    "Harvard Revised Catalogue_1": 6426,
    "Gliese Catalog_1": "Gl 667A"
  },
  {
    "Astrid #": "ASTRID 000143",
    "HYG Star ID": 112936,
    "Hipparcos Catalogue": 113296,
    "Henry Draper Catalogue": 216899,
    "Gliese Catalog": "Gl 880",
    "Distance in Parsecs": 6.8451,
    "mag": 8.68,
    "Absolute Visual Magnitude": 9.503,
    "Spectral Type": "M2:",
    "Color Index": 1.507,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.31155,
    "y": -1.792532,
    "z": 1.950162,
    "vx": -0.0000331,
    "vy": -0.00002624,
    "vz": -0.00001714,
    "rarad": 6.00646563499499,
    "decrad": 0.288911914802241,
    "pmrarad": -0.00000500914342886805,
    "pmdecrad": -0.000001373622601,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112936,
    "lum": 0.0137657538650941,
    "ra": 22.943009,
    "dec": 16.553433,
    "pmra": -1033.21,
    "pmdec": -283.33,
    "rv": -27.9,
    "Asset Name": "ASTRID 000143",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Bob",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.0000331,
    "y_1": -0.00002624,
    "z_1": -0.00001714,
    "Distance in Parsecs_1": 6.8451,
    "HYG Star ID_1": 112936,
    "Hipparcos Catalogue_1": 113296,
    "Henry Draper Catalogue_1": 216899,
    "Gliese Catalog_1": "Gl 880"
  },
  {
    "Astrid #": "ASTRID 000144",
    "HYG Star ID": 118510,
    "Gliese Catalog": "Gl 293",
    "Distance in Parsecs": 6.9109,
    "mag": 14.08,
    "Absolute Visual Magnitude": 14.882,
    "Spectral Type": "DQ9",
    "Color Index": 0.66,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.238902,
    "y": 2.29964,
    "z": -6.398229,
    "vx": -0.00002072,
    "vy": -0.00006245,
    "vz": -0.00001847,
    "rarad": 2.06438221170719,
    "decrad": -1.18319117978745,
    "pmrarad": 0.00000691979414393749,
    "pmdecrad": -0.000007066275749,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118510,
    "lum": 0.0000970957006528823,
    "ra": 7.885359,
    "dec": -67.791861,
    "pmra": 1427.31,
    "pmdec": -1457.52,
    "rv": 0,
    "Asset Name": "ASTRID 000144",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Bucket Hat",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002072,
    "y_1": -0.00006245,
    "z_1": -0.00001847,
    "Distance in Parsecs_1": 6.9109,
    "HYG Star ID_1": 118510,
    "Gliese Catalog_1": "Gl 293"
  },
  {
    "Astrid #": "ASTRID 000145",
    "HYG Star ID": 119143,
    "Gliese Catalog": "Gl 667B",
    "Distance in Parsecs": 6.9711,
    "mag": 7.2,
    "Absolute Visual Magnitude": 7.983,
    "Spectral Type": "K5 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.017406,
    "y": -5.619572,
    "z": -3.99778,
    "vx": 0.00003857,
    "vy": -0.00000419,
    "vz": -0.00000572,
    "rarad": 4.53322094622466,
    "decrad": -0.610741238257399,
    "pmrarad": 0.00000555150449330555,
    "pmdecrad": -8.99145148e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 2,
    "comp_primary": 84451,
    "base": "Gl 667",
    "lum": 0.0558213068917726,
    "ra": 17.315629,
    "dec": -34.992895,
    "pmra": 1145.08,
    "pmdec": -185.46,
    "rv": 1,
    "Asset Name": "ASTRID 000145",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Bucket Hat",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00003857,
    "y_1": -0.00000419,
    "z_1": -0.00000572,
    "Distance in Parsecs_1": 6.9711,
    "HYG Star ID_1": 119143,
    "Gliese Catalog_1": "Gl 667B"
  },
  {
    "Astrid #": "ASTRID 000146",
    "HYG Star ID": 119144,
    "Gliese Catalog": "Gl 667C",
    "Distance in Parsecs": 6.9711,
    "mag": 10.24,
    "Absolute Visual Magnitude": 11.023,
    "Spectral Type": "M2.5",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.016894,
    "y": -5.61931,
    "z": -3.998244,
    "vx": 0.00003933,
    "vy": -0.00000429,
    "vz": -0.00000507,
    "rarad": 4.53329980392298,
    "decrad": -0.61082449258115,
    "pmrarad": 0.00000566087845963888,
    "pmdecrad": -8.25831623e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 3,
    "comp_primary": 84451,
    "base": "Gl 667",
    "lum": 0.00339468905380078,
    "ra": 17.31593,
    "dec": -34.997665,
    "pmra": 1167.64,
    "pmdec": -170.34,
    "rv": 0.6,
    "Asset Name": "ASTRID 000146",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Charmer",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00003933,
    "y_1": -0.00000429,
    "z_1": -0.00000507,
    "Distance in Parsecs_1": 6.9711,
    "HYG Star ID_1": 119144,
    "Gliese Catalog_1": "Gl 667C"
  },
  {
    "Astrid #": "ASTRID 000147",
    "HYG Star ID": 102765,
    "Hipparcos Catalogue": 103096,
    "Henry Draper Catalogue": 199305,
    "Gliese Catalog": "Gl 809",
    "Distance in Parsecs": 7.0487,
    "mag": 8.55,
    "Absolute Visual Magnitude": 9.309,
    "Spectral Type": "M2V",
    "Color Index": 1.483,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.259284,
    "y": -2.394775,
    "z": 6.232392,
    "vx": 0.00001051,
    "vy": -0.00001108,
    "vz": -0.00002773,
    "rarad": 5.46868298981489,
    "decrad": 1.0847987558124,
    "pmrarad": 5.23598775e-9,
    "pmdecrad": -0.00000375362144,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 102765,
    "lum": 0.016458869432193,
    "ra": 20.88883,
    "dec": 62.15439,
    "pmra": 1.08,
    "pmdec": -774.24,
    "rv": -17,
    "Asset Name": "ASTRID 000147",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Manbun",
    "Outfit": "Mecha",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001051,
    "y_1": -0.00001108,
    "z_1": -0.00002773,
    "Distance in Parsecs_1": 7.0487,
    "HYG Star ID_1": 102765,
    "Hipparcos Catalogue_1": 103096,
    "Henry Draper Catalogue_1": 199305,
    "Gliese Catalog_1": "Gl 809"
  },
  {
    "Astrid #": "ASTRID 000148",
    "HYG Star ID": 51172,
    "Hipparcos Catalogue": 51317,
    "Gliese Catalog": "Gl 393",
    "Distance in Parsecs": 7.0671,
    "mag": 9.65,
    "Absolute Visual Magnitude": 10.404,
    "Spectral Type": "M2",
    "Color Index": 1.507,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.515769,
    "y": 2.734767,
    "z": 0.10373,
    "vx": -1.8e-7,
    "vy": 0.00002245,
    "vz": -0.00002495,
    "rarad": 2.74420675186459,
    "decrad": 0.0146782111985237,
    "pmrarad": -0.00000292012976072222,
    "pmdecrad": -0.000003548205883,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 51172,
    "lum": 0.00600343758732564,
    "ra": 10.482098,
    "dec": 0.841,
    "pmra": -602.32,
    "pmdec": -731.87,
    "rv": 8.3,
    "Asset Name": "ASTRID 000148",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Charmer",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": -1.8e-7,
    "y_1": 0.00002245,
    "z_1": -0.00002495,
    "Distance in Parsecs_1": 7.0671,
    "HYG Star ID_1": 51172,
    "Hipparcos Catalogue_1": 51317,
    "Gliese Catalog_1": "Gl 393"
  },
  {
    "Astrid #": "ASTRID 000149",
    "HYG Star ID": 12082,
    "Hipparcos Catalogue": 12114,
    "Henry Draper Catalogue": 16160,
    "Harvard Revised Catalogue": 753,
    "Gliese Catalog": "Gl 105A",
    "Proper Name": "268 G. Cet",
    "Distance in Parsecs": 7.1803,
    "mag": 5.79,
    "Absolute Visual Magnitude": 6.509,
    "Spectral Type": "K3V",
    "Color Index": 0.918,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.538462,
    "y": 4.488223,
    "z": 0.861013,
    "vx": -0.00002374,
    "vy": 0.00006168,
    "vz": 0.00005304,
    "rarad": 0.681033735135593,
    "decrad": 0.120198561576742,
    "pmrarad": 0.00000875704406777083,
    "pmdecrad": 0.000006993437342,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12082,
    "base": "Gl 105",
    "lum": 0.216970155380085,
    "ra": 2.601357,
    "dec": 6.88687,
    "pmra": 1806.27,
    "pmdec": 1442.5,
    "rv": 26,
    "Asset Name": "ASTRID 000149",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Sad",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Spec Ops",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "268 G. Cet",
    "Constellation": "Cetus",
    "x_1": -0.00002374,
    "y_1": 0.00006168,
    "z_1": 0.00005304,
    "Distance in Parsecs_1": 7.1803,
    "HYG Star ID_1": 12082,
    "Hipparcos Catalogue_1": 12114,
    "Henry Draper Catalogue_1": 16160,
    "Harvard Revised Catalogue_1": 753,
    "Gliese Catalog_1": "Gl 105A"
  },
  {
    "Astrid #": "ASTRID 000150",
    "HYG Star ID": 118835,
    "Gliese Catalog": "NN 3737",
    "Distance in Parsecs": 7.1942,
    "mag": 12.74,
    "Absolute Visual Magnitude": 13.455,
    "Spectral Type": "M4",
    "Color Index": 1.69,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.559306,
    "y": -0.950686,
    "z": -4.466188,
    "vx": 0.0000241,
    "vy": 0.00002806,
    "vz": -0.000036,
    "rarad": 3.31100754977255,
    "decrad": -0.6697677448505,
    "pmrarad": -0.00000327976454895833,
    "pmdecrad": -0.00000638177701,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118835,
    "lum": 0.000361409862639613,
    "ra": 12.647117,
    "dec": -38.374865,
    "pmra": -676.5,
    "pmdec": -1316.34,
    "rv": 0,
    "Asset Name": "ASTRID 000150",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Oof",
    "Hair": "Good Boy",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000241,
    "y_1": 0.00002806,
    "z_1": -0.000036,
    "Distance in Parsecs_1": 7.1942,
    "HYG Star ID_1": 118835,
    "Gliese Catalog_1": "NN 3737"
  },
  {
    "Astrid #": "ASTRID 000151",
    "HYG Star ID": 118157,
    "Gliese Catalog": "Gl 105B",
    "Distance in Parsecs": 7.2088,
    "mag": 11.66,
    "Absolute Visual Magnitude": 12.371,
    "Spectral Type": "dM4.5",
    "Color Index": 1.6,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.55796,
    "y": 4.511171,
    "z": 0.862659,
    "vx": -0.00002433,
    "vy": 0.00006185,
    "vz": 0.00005336,
    "rarad": 0.681806879114312,
    "decrad": 0.1199330846606,
    "pmrarad": 0.00000879030228625694,
    "pmdecrad": 0.000007017193212,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 12082,
    "base": "Gl 105",
    "lum": 0.000980844136030905,
    "ra": 2.60431,
    "dec": 6.87166,
    "pmra": 1813.13,
    "pmdec": 1447.4,
    "rv": 25.7,
    "Asset Name": "ASTRID 000151",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Tiny Crown",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002433,
    "y_1": 0.00006185,
    "z_1": 0.00005336,
    "Distance in Parsecs_1": 7.2088,
    "HYG Star ID_1": 118157,
    "Gliese Catalog_1": "Gl 105B"
  },
  {
    "Astrid #": "ASTRID 000152",
    "HYG Star ID": 119571,
    "Gliese Catalog": "GJ 1286",
    "Distance in Parsecs": 7.215,
    "mag": 14.69,
    "Absolute Visual Magnitude": 15.399,
    "Spectral Type": "M5",
    "Color Index": 1.96,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.16648,
    "y": -0.779258,
    "z": -0.300922,
    "vx": 0.00000173,
    "vy": 0.0000272,
    "vz": -0.00002958,
    "rarad": 6.17487413890854,
    "decrad": -0.04171989605675,
    "pmrarad": 0.00000377383817081249,
    "pmdecrad": -0.000004104001135,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119571,
    "lum": 0.0000603114819617816,
    "ra": 23.586282,
    "dec": -2.390374,
    "pmra": 778.41,
    "pmdec": -846.51,
    "rv": 0,
    "Asset Name": "ASTRID 000152",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Pompadour",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000173,
    "y_1": 0.0000272,
    "z_1": -0.00002958,
    "Distance in Parsecs_1": 7.215,
    "HYG Star ID_1": 119571,
    "Gliese Catalog_1": "GJ 1286"
  },
  {
    "Astrid #": "ASTRID 000153",
    "HYG Star ID": 119212,
    "Gliese Catalog": "NN 4053",
    "Distance in Parsecs": 7.2833,
    "mag": 13.48,
    "Absolute Visual Magnitude": 14.168,
    "Spectral Type": "m",
    "Color Index": 1.83,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.242972,
    "y": -2.929993,
    "z": 6.663525,
    "vx": 0.00001748,
    "vy": -0.00001182,
    "vz": -0.00000583,
    "rarad": 4.79495863425806,
    "decrad": 1.1552715968786,
    "pmrarad": 0.00000225816516129166,
    "pmdecrad": -0.000001983862428,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119212,
    "lum": 0.000187413123997675,
    "ra": 18.315393,
    "dec": 66.192187,
    "pmra": 465.78,
    "pmdec": -409.2,
    "rv": 0,
    "Asset Name": "ASTRID 000153",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Sweetheart",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001748,
    "y_1": -0.00001182,
    "z_1": -0.00000583,
    "Distance in Parsecs_1": 7.2833,
    "HYG Star ID_1": 119212,
    "Gliese Catalog_1": "NN 4053"
  },
  {
    "Astrid #": "ASTRID 000154",
    "HYG Star ID": 119229,
    "Gliese Catalog": "GJ 1230A",
    "Distance in Parsecs": 7.3529,
    "mag": 12.4,
    "Absolute Visual Magnitude": 13.068,
    "Spectral Type": "k-m",
    "Color Index": 1.71,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.192254,
    "y": -6.568303,
    "z": 3.082378,
    "vx": 0.00001737,
    "vy": 0.00000377,
    "vz": 0.00000133,
    "rarad": 4.89193799017664,
    "decrad": 0.4325703971658,
    "pmrarad": 0.00000241592353294444,
    "pmdecrad": 1.98623316e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119229,
    "base": "GJ 1230",
    "lum": 0.000516178605496672,
    "ra": 18.685827,
    "dec": 24.784458,
    "pmra": 498.32,
    "pmdec": 40.97,
    "rv": 0,
    "Asset Name": "ASTRID 000154",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Laughing",
    "Hair": "Curly",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001737,
    "y_1": 0.00000377,
    "z_1": 0.00000133,
    "Distance in Parsecs_1": 7.3529,
    "HYG Star ID_1": 119229,
    "Gliese Catalog_1": "GJ 1230A"
  },
  {
    "Astrid #": "ASTRID 000155",
    "HYG Star ID": 119230,
    "Gliese Catalog": "GJ 1230B",
    "Distance in Parsecs": 7.3529,
    "mag": 14,
    "Absolute Visual Magnitude": 14.668,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.192237,
    "y": -6.568216,
    "z": 3.082572,
    "vx": 0.00001737,
    "vy": 0.00000377,
    "vz": 0.00000133,
    "rarad": 4.89193782117664,
    "decrad": 0.4325994861658,
    "pmrarad": 0.00000241592353294444,
    "pmdecrad": 1.98623316e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119229,
    "base": "GJ 1230",
    "lum": 0.00011824968703831,
    "ra": 18.685826,
    "dec": 24.786125,
    "pmra": 498.32,
    "pmdec": 40.97,
    "rv": 0,
    "Asset Name": "ASTRID 000155",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Slick",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001737,
    "y_1": 0.00000377,
    "z_1": 0.00000133,
    "Distance in Parsecs_1": 7.3529,
    "HYG Star ID_1": 119230,
    "Gliese Catalog_1": "GJ 1230B"
  },
  {
    "Astrid #": "ASTRID 000156",
    "HYG Star ID": 83688,
    "Hipparcos Catalogue": 83945,
    "Gliese Catalog": "NN 3991",
    "Distance in Parsecs": 7.4455,
    "mag": 11.77,
    "Absolute Visual Magnitude": 12.411,
    "Spectral Type": "M3",
    "Color Index": 1.696,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.176301,
    "y": -5.254468,
    "z": 5.142212,
    "vx": 0.00001025,
    "vy": -0.0000094,
    "vz": -0.00000726,
    "rarad": 4.49214796428611,
    "decrad": 0.762383390058802,
    "pmrarad": 0.00000161888984211111,
    "pmdecrad": -0.000001347878994,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83688,
    "lum": 0.000945366045957965,
    "var_min": 11.863,
    "var_max": 11.693,
    "ra": 17.158741,
    "dec": 43.681351,
    "pmra": 333.92,
    "pmdec": -278.02,
    "rv": 0,
    "Asset Name": "ASTRID 000156",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Spiky",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001025,
    "y_1": -0.0000094,
    "z_1": -0.00000726,
    "Distance in Parsecs_1": 7.4455,
    "HYG Star ID_1": 83688,
    "Hipparcos Catalogue_1": 83945,
    "Gliese Catalog_1": "NN 3991"
  },
  {
    "Astrid #": "ASTRID 000157",
    "HYG Star ID": 3759,
    "Hipparcos Catalogue": 3765,
    "Henry Draper Catalogue": 4628,
    "Harvard Revised Catalogue": 222,
    "Gliese Catalog": "Gl 33",
    "Proper Name": "96 G. Psc",
    "Distance in Parsecs": 7.4549,
    "mag": 5.74,
    "Absolute Visual Magnitude": 6.378,
    "Spectral Type": "K2V",
    "Color Index": 0.89,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.258368,
    "y": 1.555495,
    "z": 0.686093,
    "vx": -0.00001198,
    "vy": 0.00002545,
    "vz": -0.00004201,
    "rarad": 0.21111031896421,
    "decrad": 0.0921641160815062,
    "pmrarad": 0.00000367508162408333,
    "pmdecrad": -0.000005532790685,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 3759,
    "lum": 0.244793566619606,
    "ra": 0.806382,
    "dec": 5.280615,
    "pmra": 758.04,
    "pmdec": -1141.22,
    "rv": -10,
    "Asset Name": "ASTRID 000157",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Mortified",
    "Hair": "Audiohead",
    "Outfit": "Sharky",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "96 G. Psc",
    "Constellation": "Pisces",
    "x_1": -0.00001198,
    "y_1": 0.00002545,
    "z_1": -0.00004201,
    "Distance in Parsecs_1": 7.4549,
    "HYG Star ID_1": 3759,
    "Hipparcos Catalogue_1": 3765,
    "Henry Draper Catalogue_1": 4628,
    "Harvard Revised Catalogue_1": 222,
    "Gliese Catalog_1": "Gl 33"
  },
  {
    "Astrid #": "ASTRID 000158",
    "HYG Star ID": 119492,
    "Gliese Catalog": "NN 4274",
    "Distance in Parsecs": 7.4571,
    "mag": 13.25,
    "Absolute Visual Magnitude": 13.887,
    "Spectral Type": "M4",
    "Color Index": 1.84,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.481995,
    "y": -2.916125,
    "z": -2.255726,
    "vx": -0.00000291,
    "vy": 0.00001259,
    "vz": -0.00002465,
    "rarad": 5.86042720222742,
    "decrad": -0.30730791661055,
    "pmrarad": 0.00000138026454854166,
    "pmdecrad": -0.000003468512211,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119492,
    "lum": 0.000242772784768099,
    "ra": 22.385183,
    "dec": -17.607447,
    "pmra": 284.7,
    "pmdec": -715.43,
    "rv": 0,
    "Asset Name": "ASTRID 000158",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Hachimaki",
    "Outfit": "Boxer",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000291,
    "y_1": 0.00001259,
    "z_1": -0.00002465,
    "Distance in Parsecs_1": 7.4571,
    "HYG Star ID_1": 119492,
    "Gliese Catalog_1": "NN 4274"
  },
  {
    "Astrid #": "ASTRID 000159",
    "HYG Star ID": 2016,
    "Hipparcos Catalogue": 2021,
    "Henry Draper Catalogue": 2151,
    "Harvard Revised Catalogue": 98,
    "Gliese Catalog": "Gl 19",
    "Distance in Parsecs": 7.4588,
    "mag": 2.82,
    "Absolute Visual Magnitude": 3.457,
    "Spectral Type": "G2IV",
    "Color Index": 0.618,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.635267,
    "y": 0.184517,
    "z": -7.275205,
    "vx": 0.00000754,
    "vy": 0.00008163,
    "vz": -0.00002026,
    "rarad": 0.112028110147991,
    "decrad": -1.34834096383631,
    "pmrarad": 0.0000107634454847499,
    "pmdecrad": 0.000001572590135,
    "bayer": "Bet",
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 2016,
    "lum": 3.60744733864191,
    "bf": "Bet Hyi",
    "ra": 0.427916,
    "dec": -77.254247,
    "pmra": 2220.12,
    "pmdec": 324.37,
    "rv": 22.9,
    "Asset Name": "ASTRID 000159",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Straw Hat",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": 0.00000754,
    "y_1": 0.00008163,
    "z_1": -0.00002026,
    "Distance in Parsecs_1": 7.4588,
    "HYG Star ID_1": 2016,
    "Hipparcos Catalogue_1": 2021,
    "Henry Draper Catalogue_1": 2151,
    "Harvard Revised Catalogue_1": 98,
    "Gliese Catalog_1": "Gl 19"
  },
  {
    "Astrid #": "ASTRID 000160",
    "HYG Star ID": 12748,
    "Hipparcos Catalogue": 12781,
    "Gliese Catalog": "Gl 109",
    "Distance in Parsecs": 7.5098,
    "mag": 10.55,
    "Absolute Visual Magnitude": 11.172,
    "Spectral Type": "M3.5Ve",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.109768,
    "y": 4.451981,
    "z": 3.235931,
    "vx": 0.00000447,
    "vy": 0.00004565,
    "vz": 0.00000111,
    "rarad": 0.716708920578571,
    "decrad": 0.445466607209087,
    "pmrarad": 0.00000419252326534027,
    "pmdecrad": -0.00000178009039,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 12748,
    "lum": 0.00295937499534338,
    "var": "VX",
    "var_min": 10.612,
    "var_max": 10.502,
    "ra": 2.737626,
    "dec": 25.523357,
    "pmra": 864.77,
    "pmdec": -367.17,
    "rv": 29.9,
    "Asset Name": "ASTRID 000160",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Angry",
    "Hair": "Fedora",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00000447,
    "y_1": 0.00004565,
    "z_1": 0.00000111,
    "Distance in Parsecs_1": 7.5098,
    "HYG Star ID_1": 12748,
    "Hipparcos Catalogue_1": 12781,
    "Gliese Catalog_1": "Gl 109"
  },
  {
    "Astrid #": "ASTRID 000161",
    "HYG Star ID": 62752,
    "Hipparcos Catalogue": 62951,
    "Distance in Parsecs": 7.5284,
    "mag": 8.51,
    "Absolute Visual Magnitude": 9.126,
    "Spectral Type": "A2",
    "Color Index": 0.497,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -6.960892,
    "y": -1.67045,
    "z": -2.330747,
    "vx": -0.00000193,
    "vy": 0.00000333,
    "vz": 0.00000338,
    "rarad": 3.37711555324749,
    "decrad": -0.314766008175415,
    "pmrarad": -4.90486000618055e-7,
    "pmdecrad": 4.72208524e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 62752,
    "lum": 0.0194804955329076,
    "ra": 12.899631,
    "dec": -18.034764,
    "pmra": -101.17,
    "pmdec": 97.4,
    "rv": 0,
    "Asset Name": "ASTRID 000161",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Wavy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000193,
    "y_1": 0.00000333,
    "z_1": 0.00000338,
    "Distance in Parsecs_1": 7.5284,
    "HYG Star ID_1": 62752,
    "Hipparcos Catalogue_1": 62951
  },
  {
    "Astrid #": "ASTRID 000162",
    "HYG Star ID": 117987,
    "Gliese Catalog": "GJ 2005",
    "Distance in Parsecs": 7.5301,
    "mag": 15.42,
    "Absolute Visual Magnitude": 16.036,
    "Spectral Type": "M5.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.651693,
    "y": 0.719714,
    "z": -3.430758,
    "vx": -0.00018876,
    "vy": -0.00002242,
    "vz": 0.00012243,
    "rarad": 0.107782454277051,
    "decrad": -0.47381892323995,
    "pmrarad": -2.62914458965277e-7,
    "pmdecrad": 0.000002945655201,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117987,
    "lum": 0.0000335428530832688,
    "ra": 0.411699,
    "dec": -27.147825,
    "pmra": -54.23,
    "pmdec": 607.59,
    "rv": -220,
    "Asset Name": "ASTRID 000162",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Little Crown",
    "Outfit": "Unit 01",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00018876,
    "y_1": -0.00002242,
    "z_1": 0.00012243,
    "Distance in Parsecs_1": 7.5301,
    "HYG Star ID_1": 117987,
    "Gliese Catalog_1": "GJ 2005"
  },
  {
    "Astrid #": "ASTRID 000163",
    "HYG Star ID": 7966,
    "Hipparcos Catalogue": 7981,
    "Henry Draper Catalogue": 10476,
    "Harvard Revised Catalogue": 493,
    "Gliese Catalog": "Gl 68",
    "Distance in Parsecs": 7.5324,
    "mag": 5.24,
    "Absolute Visual Magnitude": 5.855,
    "Spectral Type": "K1V",
    "Color Index": 0.836,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.370804,
    "y": 3.055661,
    "z": 2.60927,
    "vx": -0.00001665,
    "vy": -0.00002022,
    "vz": -0.00003514,
    "rarad": 0.447224150707947,
    "decrad": 0.35375213588112,
    "pmrarad": -0.00000146471909169444,
    "pmdecrad": -0.000003284127872,
    "flam": 107,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 7966,
    "lum": 0.396278034255439,
    "bf": "107    Psc",
    "ra": 1.70827,
    "dec": 20.268504,
    "pmra": -302.12,
    "pmdec": -677.4,
    "rv": -33.7,
    "Asset Name": "ASTRID 000163",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Pixie",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001665,
    "y_1": -0.00002022,
    "z_1": -0.00003514,
    "Distance in Parsecs_1": 7.5324,
    "HYG Star ID_1": 7966,
    "Hipparcos Catalogue_1": 7981,
    "Henry Draper Catalogue_1": 10476,
    "Harvard Revised Catalogue_1": 493,
    "Gliese Catalog_1": "Gl 68"
  },
  {
    "Astrid #": "ASTRID 000164",
    "HYG Star ID": 119199,
    "Gliese Catalog": "GJ 1224",
    "Distance in Parsecs": 7.5358,
    "mag": 13.63,
    "Absolute Visual Magnitude": 14.244,
    "Spectral Type": "m",
    "Color Index": 1.79,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.238537,
    "y": -7.241188,
    "z": -2.072822,
    "vx": -0.0000206,
    "vy": 0.00000281,
    "vz": -0.00001222,
    "rarad": 4.74532418173108,
    "decrad": -0.27865557867495,
    "pmrarad": -0.00000271946537834027,
    "pmdecrad": -0.000001686133499,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119199,
    "lum": 0.000174743085526732,
    "ra": 18.125803,
    "dec": -15.965789,
    "pmra": -560.93,
    "pmdec": -347.79,
    "rv": 0,
    "Asset Name": "ASTRID 000164",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Undercut",
    "Outfit": "Boxer",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000206,
    "y_1": 0.00000281,
    "z_1": -0.00001222,
    "Distance in Parsecs_1": 7.5358,
    "HYG Star ID_1": 119199,
    "Gliese Catalog_1": "GJ 1224"
  },
  {
    "Astrid #": "ASTRID 000165",
    "HYG Star ID": 118043,
    "Gliese Catalog": "Gl 53B",
    "Distance in Parsecs": 7.5529,
    "mag": 11,
    "Absolute Visual Magnitude": 11.609,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.149624,
    "y": 1.273905,
    "z": 6.180949,
    "vx": 0.00000853,
    "vy": 0.00013373,
    "vz": -0.00003313,
    "rarad": 0.29724561139643,
    "decrad": 0.95854174444495,
    "pmrarad": 0.0000166052079286041,
    "pmdecrad": -0.000007637551101,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 2,
    "comp_primary": 5325,
    "base": "Gl  53",
    "lum": 0.00197879133523373,
    "ra": 1.135395,
    "dec": 54.920396,
    "pmra": 3425.07,
    "pmdec": -1575.36,
    "rv": 0,
    "Asset Name": "ASTRID 000165",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Serious Shinobi",
    "Hair": "Braided Bun",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000853,
    "y_1": 0.00013373,
    "z_1": -0.00003313,
    "Distance in Parsecs_1": 7.5529,
    "HYG Star ID_1": 118043,
    "Gliese Catalog_1": "Gl 53B"
  },
  {
    "Astrid #": "ASTRID 000166",
    "HYG Star ID": 5325,
    "Hipparcos Catalogue": 5336,
    "Henry Draper Catalogue": 6582,
    "Harvard Revised Catalogue": 321,
    "Gliese Catalog": "Gl 53A",
    "Distance in Parsecs": 7.554,
    "mag": 5.17,
    "Absolute Visual Magnitude": 5.779,
    "Spectral Type": "G5Vp",
    "Color Index": 0.704,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.149699,
    "y": 1.274102,
    "z": 6.181126,
    "vx": -0.00004604,
    "vy": 0.00011693,
    "vz": -0.00011576,
    "rarad": 0.29779075758043,
    "decrad": 0.958540767052196,
    "pmrarad": 0.000016587609192,
    "pmdecrad": -0.000007753479749,
    "bayer": "Mu",
    "flam": 30,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 5325,
    "base": "Gl  53",
    "lum": 0.425010833176046,
    "bf": "30Mu  Cas",
    "ra": 1.137477,
    "dec": 54.92034,
    "pmra": 3421.44,
    "pmdec": -1599.27,
    "rv": -98.1,
    "Asset Name": "ASTRID 000166",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Bucket Hat",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00004604,
    "y_1": 0.00011693,
    "z_1": -0.00011576,
    "Distance in Parsecs_1": 7.554,
    "HYG Star ID_1": 5325,
    "Hipparcos Catalogue_1": 5336,
    "Henry Draper Catalogue_1": 6582,
    "Harvard Revised Catalogue_1": 321,
    "Gliese Catalog_1": "Gl 53A"
  },
  {
    "Astrid #": "ASTRID 000167",
    "HYG Star ID": 118405,
    "Gliese Catalog": "NN 3378",
    "Distance in Parsecs": 7.5643,
    "mag": 11.71,
    "Absolute Visual Magnitude": 12.316,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.019372,
    "y": 3.827631,
    "z": 6.524377,
    "vx": 0.0000052,
    "vy": 0.00002587,
    "vz": -0.00001516,
    "rarad": 1.57589166468439,
    "decrad": 1.0402331994018,
    "pmrarad": -7.05646312048611e-7,
    "pmdecrad": -0.000003961591964,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 118405,
    "lum": 0.00103181063526759,
    "ra": 6.019463,
    "dec": 59.600972,
    "pmra": -145.55,
    "pmdec": -817.14,
    "rv": 0,
    "Asset Name": "ASTRID 000167",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Pompadour",
    "Outfit": "Warlock",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.0000052,
    "y_1": 0.00002587,
    "z_1": -0.00001516,
    "Distance in Parsecs_1": 7.5643,
    "HYG Star ID_1": 118405,
    "Gliese Catalog_1": "NN 3378"
  },
  {
    "Astrid #": "ASTRID 000168",
    "HYG Star ID": 112923,
    "Hipparcos Catalogue": 113283,
    "Henry Draper Catalogue": 216803,
    "Harvard Revised Catalogue": 8721,
    "Gliese Catalog": "Gl 879",
    "Distance in Parsecs": 7.6092,
    "mag": 6.48,
    "Absolute Visual Magnitude": 7.073,
    "Spectral Type": "K4Vp",
    "Color Index": 1.094,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.235383,
    "y": -1.77617,
    "z": -3.98326,
    "vx": 0.00000791,
    "vy": 0.00001042,
    "vz": -0.00000984,
    "rarad": 6.00567936147826,
    "decrad": -0.550923077452553,
    "pmrarad": 0.00000160245465834027,
    "pmdecrad": -7.75023149e-7,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 112923,
    "lum": 0.129062478206053,
    "var": "TW",
    "var_min": 6.498,
    "var_max": 6.458,
    "ra": 22.940005,
    "dec": -31.565567,
    "pmra": 330.53,
    "pmdec": -159.86,
    "rv": 9,
    "Asset Name": "ASTRID 000168",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Ninja",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000791,
    "y_1": 0.00001042,
    "z_1": -0.00000984,
    "Distance in Parsecs_1": 7.6092,
    "HYG Star ID_1": 112923,
    "Hipparcos Catalogue_1": 113283,
    "Henry Draper Catalogue_1": 216803,
    "Harvard Revised Catalogue_1": 8721,
    "Gliese Catalog_1": "Gl 879"
  },
  {
    "Astrid #": "ASTRID 000169",
    "HYG Star ID": 118187,
    "Gliese Catalog": "NN 3193B",
    "Distance in Parsecs": 7.6336,
    "mag": 11.8,
    "Absolute Visual Magnitude": 12.386,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.131438,
    "y": 5.21439,
    "z": -2.179524,
    "vx": 0.00000698,
    "vy": -0.00001079,
    "vz": -0.00000939,
    "rarad": 0.79341950786624,
    "decrad": -0.28954608933245,
    "pmrarad": -0.00000164274267519444,
    "pmdecrad": -0.000001283466649,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 2,
    "comp_primary": 118187,
    "base": "NN 3193",
    "lum": 0.00096738644996895,
    "ra": 3.030639,
    "dec": -16.589769,
    "pmra": -338.84,
    "pmdec": -264.73,
    "rv": 0,
    "Asset Name": "ASTRID 000169",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Audiohead",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000698,
    "y_1": -0.00001079,
    "z_1": -0.00000939,
    "Distance in Parsecs_1": 7.6336,
    "HYG Star ID_1": 118187,
    "Gliese Catalog_1": "NN 3193B"
  },
  {
    "Astrid #": "ASTRID 000170",
    "HYG Star ID": 65653,
    "Hipparcos Catalogue": 65859,
    "Gliese Catalog": "Gl 514",
    "Distance in Parsecs": 7.6558,
    "mag": 9.05,
    "Absolute Visual Magnitude": 9.63,
    "Spectral Type": "M1V",
    "Color Index": 1.493,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.957507,
    "y": -2.881767,
    "z": 1.37904,
    "vx": -0.00000391,
    "vy": -0.00004693,
    "vz": -0.00003658,
    "rarad": 3.53427537311027,
    "decrad": 0.181115645908012,
    "pmrarad": 0.00000546869831666666,
    "pmdecrad": -0.00000520835337,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 65653,
    "lum": 0.0122461619926504,
    "ra": 13.499938,
    "dec": 10.377162,
    "pmra": 1128,
    "pmdec": -1074.3,
    "rv": 14.3,
    "Asset Name": "ASTRID 000170",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Round Brush",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000391,
    "y_1": -0.00004693,
    "z_1": -0.00003658,
    "Distance in Parsecs_1": 7.6558,
    "HYG Star ID_1": 65653,
    "Hipparcos Catalogue_1": 65859,
    "Gliese Catalog_1": "Gl 514"
  },
  {
    "Astrid #": "ASTRID 000171",
    "HYG Star ID": 90979,
    "Hipparcos Catalogue": 91262,
    "Henry Draper Catalogue": 172167,
    "Harvard Revised Catalogue": 7001,
    "Gliese Catalog": "Gl 721",
    "Proper Name": "Vega",
    "Distance in Parsecs": 7.6787,
    "mag": 0.03,
    "Absolute Visual Magnitude": 0.604,
    "Spectral Type": "A0Vvar",
    "Color Index": -0.001,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 0.960565,
    "y": -5.908009,
    "z": 4.809731,
    "vx": 0.00000476,
    "vy": 0.00001734,
    "vz": 5.9e-7,
    "rarad": 4.87356309550903,
    "decrad": 0.676903116397302,
    "pmrarad": 9.74572460652777e-7,
    "pmdecrad": 0.000001393645406,
    "bayer": "Alp",
    "flam": 3,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 90979,
    "lum": 49.9344188721349,
    "bf": "3Alp Lyr",
    "ra": 18.61564,
    "dec": 38.783692,
    "pmra": 201.02,
    "pmdec": 287.46,
    "rv": -12.1,
    "Asset Name": "ASTRID 000171",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Oni Mask",
    "Hair": "Mohawk",
    "Outfit": "Samurai",
    "Special": "Overburner",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Vega",
    "Constellation": "Lyra",
    "x_1": 0.00000476,
    "y_1": 0.00001734,
    "z_1": 5.9e-7,
    "Distance in Parsecs_1": 7.6787,
    "HYG Star ID_1": 90979,
    "Hipparcos Catalogue_1": 91262,
    "Henry Draper Catalogue_1": 172167,
    "Harvard Revised Catalogue_1": 7001,
    "Gliese Catalog_1": "Gl 721"
  },
  {
    "Astrid #": "ASTRID 000172",
    "HYG Star ID": 85036,
    "Hipparcos Catalogue": 85295,
    "Henry Draper Catalogue": 157881,
    "Gliese Catalog": "Gl 673",
    "Distance in Parsecs": 7.7006,
    "mag": 7.54,
    "Absolute Visual Magnitude": 8.107,
    "Spectral Type": "K7V",
    "Color Index": 1.359,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.145591,
    "y": -7.609413,
    "z": 0.283705,
    "vx": -0.00001813,
    "vy": 0.00002516,
    "vz": -0.00004508,
    "rarad": 4.56296204976775,
    "decrad": 0.03685127194153,
    "pmrarad": -0.00000281419797152083,
    "pmdecrad": -0.000005744120968,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 85036,
    "lum": 0.0497966354164485,
    "ra": 17.429231,
    "dec": 2.111422,
    "pmra": -580.47,
    "pmdec": -1184.81,
    "rv": -23.3,
    "Asset Name": "ASTRID 000172",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Greasy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001813,
    "y_1": 0.00002516,
    "z_1": -0.00004508,
    "Distance in Parsecs_1": 7.7006,
    "HYG Star ID_1": 85036,
    "Hipparcos Catalogue_1": 85295,
    "Henry Draper Catalogue_1": 157881,
    "Gliese Catalog_1": "Gl 673"
  },
  {
    "Astrid #": "ASTRID 000173",
    "HYG Star ID": 113008,
    "Hipparcos Catalogue": 113368,
    "Henry Draper Catalogue": 216956,
    "Harvard Revised Catalogue": 8728,
    "Gliese Catalog": "Gl 881",
    "Proper Name": "Fomalhaut",
    "Distance in Parsecs": 7.7036,
    "mag": 1.17,
    "Absolute Visual Magnitude": 1.737,
    "Spectral Type": "A3V",
    "Color Index": 0.145,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 6.450517,
    "y": -1.799476,
    "z": -3.807757,
    "vx": 0.00000561,
    "vy": 0.0000112,
    "vz": -0.00000841,
    "rarad": 6.01113338390649,
    "decrad": -0.517005551408977,
    "pmrarad": 0.000001596103599125,
    "pmdecrad": -7.96161026e-7,
    "bayer": "Alp",
    "flam": 24,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 113008,
    "lum": 17.5873335413557,
    "bf": "24Alp PsA",
    "ra": 22.960838,
    "dec": -29.622236,
    "pmra": 329.22,
    "pmdec": -164.22,
    "rv": 6.1,
    "Asset Name": "ASTRID 000173",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Happy Vamp",
    "Hair": "Horns",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Fomalhaut",
    "Constellation": "None",
    "x_1": 0.00000561,
    "y_1": 0.0000112,
    "z_1": -0.00000841,
    "Distance in Parsecs_1": 7.7036,
    "HYG Star ID_1": 113008,
    "Hipparcos Catalogue_1": 113368,
    "Henry Draper Catalogue_1": 216956,
    "Harvard Revised Catalogue_1": 8728,
    "Gliese Catalog_1": "Gl 881"
  },
  {
    "Astrid #": "ASTRID 000174",
    "HYG Star ID": 118451,
    "Gliese Catalog": "NN 3417",
    "Distance in Parsecs": 7.7519,
    "mag": 13.65,
    "Absolute Visual Magnitude": 14.203,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.900784,
    "y": 3.485855,
    "z": 6.865082,
    "vx": -0.0000152,
    "vy": 0.00000948,
    "vz": -0.00000681,
    "rarad": 1.82358370264703,
    "decrad": 1.08778418990995,
    "pmrarad": 0.000001592612940625,
    "pmdecrad": -0.000001891301801,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118451,
    "lum": 0.000181467977939692,
    "ra": 6.965577,
    "dec": 62.325443,
    "pmra": 328.5,
    "pmdec": -390.11,
    "rv": 0,
    "Asset Name": "ASTRID 000174",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Scared",
    "Hair": "Sweetheart",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000152,
    "y_1": 0.00000948,
    "z_1": -0.00000681,
    "Distance in Parsecs_1": 7.7519,
    "HYG Star ID_1": 118451,
    "Gliese Catalog_1": "NN 3417"
  },
  {
    "Astrid #": "ASTRID 000175",
    "HYG Star ID": 118455,
    "Gliese Catalog": "GJ 1093",
    "Distance in Parsecs": 7.758,
    "mag": 14.83,
    "Absolute Visual Magnitude": 15.381,
    "Spectral Type": "m",
    "Color Index": 1.93,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.878261,
    "y": 7.074699,
    "z": 2.570473,
    "vx": -0.00003294,
    "vy": 0.00000283,
    "vz": -0.00003181,
    "rarad": 1.8302876982993,
    "decrad": 0.33771480023905,
    "pmrarad": 0.00000401110598607638,
    "pmdecrad": -0.000004346815219,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118455,
    "lum": 0.0000613196969715681,
    "ra": 6.991184,
    "dec": 19.349633,
    "pmra": 827.35,
    "pmdec": -896.6,
    "rv": 0,
    "Asset Name": "ASTRID 000175",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Good Boy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003294,
    "y_1": 0.00000283,
    "z_1": -0.00003181,
    "Distance in Parsecs_1": 7.758,
    "HYG Star ID_1": 118455,
    "Gliese Catalog_1": "GJ 1093"
  },
  {
    "Astrid #": "ASTRID 000176",
    "HYG Star ID": 88300,
    "Hipparcos Catalogue": 88574,
    "Henry Draper Catalogue": 165222,
    "Gliese Catalog": "Gl 701",
    "Distance in Parsecs": 7.7586,
    "mag": 9.37,
    "Absolute Visual Magnitude": 9.921,
    "Spectral Type": "M2V",
    "Color Index": 1.508,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.173292,
    "y": -7.746096,
    "z": -0.410305,
    "vx": 0.00002217,
    "vy": -0.00003204,
    "vz": -0.00001425,
    "rarad": 4.73475670702128,
    "decrad": -0.052906503865925,
    "pmrarad": 0.00000276411671831944,
    "pmdecrad": -0.00000161244182,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 88300,
    "lum": 0.00936698877952116,
    "ra": 18.085438,
    "dec": -3.031319,
    "pmra": 570.14,
    "pmdec": -332.59,
    "rv": 32.5,
    "Asset Name": "ASTRID 000176",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Prince",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00002217,
    "y_1": -0.00003204,
    "z_1": -0.00001425,
    "Distance in Parsecs_1": 7.7586,
    "HYG Star ID_1": 88300,
    "Hipparcos Catalogue_1": 88574,
    "Henry Draper Catalogue_1": 165222,
    "Gliese Catalog_1": "Gl 701"
  },
  {
    "Astrid #": "ASTRID 000177",
    "HYG Star ID": 61681,
    "Hipparcos Catalogue": 61874,
    "Gliese Catalog": "Gl 480.1",
    "Distance in Parsecs": 7.7809,
    "mag": 12.24,
    "Absolute Visual Magnitude": 12.785,
    "Spectral Type": "M4",
    "Color Index": 1.75,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.548597,
    "y": -0.997637,
    "z": -5.362282,
    "vx": 0.00000912,
    "vy": 0.00003161,
    "vz": 0.00004997,
    "rarad": 3.31950440077548,
    "decrad": -0.760376712475635,
    "pmrarad": -0.00000379133994468055,
    "pmdecrad": 0.000003362231356,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 61681,
    "lum": 0.000669884609416525,
    "var_min": 12.373,
    "var_max": 12.103,
    "ra": 12.679573,
    "dec": -43.566376,
    "pmra": -782.02,
    "pmdec": 693.51,
    "rv": -44,
    "Asset Name": "ASTRID 000177",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Spiky",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000912,
    "y_1": 0.00003161,
    "z_1": 0.00004997,
    "Distance in Parsecs_1": 7.7809,
    "HYG Star ID_1": 61681,
    "Hipparcos Catalogue_1": 61874,
    "Gliese Catalog_1": "Gl 480.1"
  },
  {
    "Astrid #": "ASTRID 000178",
    "HYG Star ID": 119615,
    "Gliese Catalog": "Gl 915",
    "Distance in Parsecs": 7.8003,
    "mag": 13.05,
    "Absolute Visual Magnitude": 13.589,
    "Spectral Type": "DA5",
    "Color Index": 0.07,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.689088,
    "y": 0.054021,
    "z": -5.336296,
    "vx": -0.00001762,
    "vy": 0.000023,
    "vz": -0.00001857,
    "rarad": 0.00944012464525972,
    "decrad": -0.7533883633175,
    "pmrarad": 0.00000296919290519444,
    "pmdecrad": -0.00000326310635,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119615,
    "lum": 0.000319447872420868,
    "ra": 0.036059,
    "dec": -43.165974,
    "pmra": 612.44,
    "pmdec": -673.06,
    "rv": 0,
    "Asset Name": "ASTRID 000178",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Worried Vamp",
    "Hair": "Angelic",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001762,
    "y_1": 0.000023,
    "z_1": -0.00001857,
    "Distance in Parsecs_1": 7.8003,
    "HYG Star ID_1": 119615,
    "Gliese Catalog_1": "Gl 915"
  },
  {
    "Astrid #": "ASTRID 000179",
    "HYG Star ID": 7736,
    "Hipparcos Catalogue": 7751,
    "Henry Draper Catalogue": 10360,
    "Harvard Revised Catalogue": 486,
    "Gliese Catalog": "Gl 66B",
    "Distance in Parsecs": 7.8223,
    "mag": 5.76,
    "Absolute Visual Magnitude": 6.293,
    "Spectral Type": "K0V",
    "Color Index": 0.88,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.945945,
    "y": 1.835674,
    "z": -6.500103,
    "vx": 0.00000751,
    "vy": 0.00001546,
    "vz": -0.00001877,
    "rarad": 0.435416734704475,
    "decrad": -0.980812209262858,
    "pmrarad": 0.00000138705194006944,
    "pmdecrad": 8.0769959e-8,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 2,
    "comp_primary": 118084,
    "base": "Gl 66",
    "lum": 0.264728074001756,
    "ra": 1.663169,
    "dec": -56.1964,
    "pmra": 286.1,
    "pmdec": 16.66,
    "rv": 22.5,
    "Asset Name": "ASTRID 000179",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Mohawk",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000751,
    "y_1": 0.00001546,
    "z_1": -0.00001877,
    "Distance in Parsecs_1": 7.8223,
    "HYG Star ID_1": 7736,
    "Hipparcos Catalogue_1": 7751,
    "Henry Draper Catalogue_1": 10360,
    "Harvard Revised Catalogue_1": 486,
    "Gliese Catalog_1": "Gl 66B"
  },
  {
    "Astrid #": "ASTRID 000180",
    "HYG Star ID": 49845,
    "Hipparcos Catalogue": 49986,
    "Gliese Catalog": "Gl 382",
    "Distance in Parsecs": 7.8691,
    "mag": 9.26,
    "Absolute Visual Magnitude": 9.78,
    "Spectral Type": "M3",
    "Color Index": 1.487,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.001078,
    "y": 3.555904,
    "z": -0.514073,
    "vx": -0.00000382,
    "vy": 0.00000849,
    "vz": -0.00000976,
    "rarad": 2.67163862452633,
    "decrad": -0.0653741371087625,
    "pmrarad": -7.41425561673611e-7,
    "pmdecrad": -0.00000117761243,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 49845,
    "lum": 0.0106659612123025,
    "var": "AN",
    "var_min": 9.292,
    "var_max": 9.232,
    "ra": 10.204908,
    "dec": -3.745662,
    "pmra": -152.93,
    "pmdec": -242.9,
    "rv": 7.7,
    "Asset Name": "ASTRID 000180",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "High Fade",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": -0.00000382,
    "y_1": 0.00000849,
    "z_1": -0.00000976,
    "Distance in Parsecs_1": 7.8691,
    "HYG Star ID_1": 49845,
    "Hipparcos Catalogue_1": 49986,
    "Gliese Catalog_1": "Gl 382"
  },
  {
    "Astrid #": "ASTRID 000181",
    "HYG Star ID": 118894,
    "Gliese Catalog": "NN 3789",
    "Distance in Parsecs": 7.9365,
    "mag": 11.95,
    "Absolute Visual Magnitude": 12.452,
    "Spectral Type": "M4  e",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.375287,
    "y": -2.698711,
    "z": 3.880812,
    "vx": -0.000006,
    "vy": 0.0000067,
    "vz": -0.0000052,
    "rarad": 3.54203731187913,
    "decrad": 0.5109233256647,
    "pmrarad": -0.00000107226241728472,
    "pmdecrad": -7.50806706e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 118894,
    "lum": 0.000910332399679908,
    "ra": 13.529586,
    "dec": 29.27375,
    "pmra": -221.17,
    "pmdec": -154.87,
    "rv": 0,
    "Asset Name": "ASTRID 000181",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Undercut",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.000006,
    "y_1": 0.0000067,
    "z_1": -0.0000052,
    "Distance in Parsecs_1": 7.9365,
    "HYG Star ID_1": 118894,
    "Gliese Catalog_1": "NN 3789"
  },
  {
    "Astrid #": "ASTRID 000182",
    "HYG Star ID": 118400,
    "Gliese Catalog": "GJ 1087",
    "Distance in Parsecs": 7.9936,
    "mag": 14.1,
    "Absolute Visual Magnitude": 14.586,
    "Spectral Type": "DAP9",
    "Color Index": 0.6,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 0.124237,
    "y": 7.957618,
    "z": 0.747341,
    "vx": 0.00001852,
    "vy": 0.00000311,
    "vz": -0.00003609,
    "rarad": 1.5551857220833,
    "decrad": 0.09362916882615,
    "pmrarad": -0.00000231105833384027,
    "pmdecrad": -0.000004535703477,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118400,
    "lum": 0.000127526370645972,
    "ra": 5.940372,
    "dec": 5.364556,
    "pmra": -476.69,
    "pmdec": -935.56,
    "rv": 0,
    "Asset Name": "ASTRID 000182",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Wavy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001852,
    "y_1": 0.00000311,
    "z_1": -0.00003609,
    "Distance in Parsecs_1": 7.9936,
    "HYG Star ID_1": 118400,
    "Gliese Catalog_1": "GJ 1087"
  },
  {
    "Astrid #": "ASTRID 000183",
    "HYG Star ID": 100854,
    "Hipparcos Catalogue": 101180,
    "Gliese Catalog": "Gl 793",
    "Distance in Parsecs": 7.9955,
    "mag": 10.54,
    "Absolute Visual Magnitude": 11.026,
    "Spectral Type": "M3",
    "Color Index": 1.542,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.028515,
    "y": -2.630896,
    "z": 7.272756,
    "vx": 0.00001011,
    "vy": 0.00001502,
    "vz": 0.00001397,
    "rarad": 5.36919138319559,
    "decrad": 1.14231031316522,
    "pmrarad": 0.0000021489366390625,
    "pmdecrad": 0.00000137716174,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 100854,
    "lum": 0.00338532212815354,
    "var_min": 10.602,
    "var_max": 10.502,
    "ra": 20.5088,
    "dec": 65.44956,
    "pmra": 443.25,
    "pmdec": 284.06,
    "rv": 10.1,
    "Asset Name": "ASTRID 000183",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Nervous Grin",
    "Hair": "Greasy",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001011,
    "y_1": 0.00001502,
    "z_1": 0.00001397,
    "Distance in Parsecs_1": 7.9955,
    "HYG Star ID_1": 100854,
    "Hipparcos Catalogue_1": 101180,
    "Gliese Catalog_1": "Gl 793"
  },
  {
    "Astrid #": "ASTRID 000184",
    "HYG Star ID": 33412,
    "Hipparcos Catalogue": 33499,
    "Gliese Catalog": "Gl 257A",
    "Distance in Parsecs": 8.0096,
    "mag": 10.81,
    "Absolute Visual Magnitude": 11.292,
    "Spectral Type": "M4+...",
    "Color Index": 1.636,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.430089,
    "y": 5.55205,
    "z": -5.593146,
    "vx": 0.00004173,
    "vy": 0.00000956,
    "vz": -0.00000118,
    "rarad": 1.82291342723555,
    "decrad": -0.773027272870351,
    "pmrarad": -0.00000534385879392361,
    "pmdecrad": -2.06385183e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 33412,
    "base": "Gl 257",
    "lum": 0.00264972009887896,
    "var_min": 10.887,
    "var_max": 10.727,
    "ra": 6.963016,
    "dec": -44.2912,
    "pmra": -1102.25,
    "pmdec": -42.57,
    "rv": 0,
    "Asset Name": "ASTRID 000184",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Unkempt",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004173,
    "y_1": 0.00000956,
    "z_1": -0.00000118,
    "Distance in Parsecs_1": 8.0096,
    "HYG Star ID_1": 33412,
    "Hipparcos Catalogue_1": 33499,
    "Gliese Catalog_1": "Gl 257A"
  },
  {
    "Astrid #": "ASTRID 000185",
    "HYG Star ID": 119418,
    "Gliese Catalog": "Gl 831B",
    "Distance in Parsecs": 8.0115,
    "mag": 14.9,
    "Absolute Visual Magnitude": 15.381,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 6.290861,
    "y": -4.770042,
    "z": -1.362317,
    "vx": 0.00002784,
    "vy": 0.00003688,
    "vz": -6.4e-7,
    "rarad": 5.63442121205111,
    "decrad": -0.1708755023472,
    "pmrarad": 0.00000576870102219444,
    "pmdecrad": -8.0546944e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 105913,
    "base": "Gl 831",
    "lum": 0.0000613196969715681,
    "ra": 21.521904,
    "dec": -9.790445,
    "pmra": 1189.88,
    "pmdec": -16.61,
    "rv": 0,
    "Asset Name": "ASTRID 000185",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Manbun",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002784,
    "y_1": 0.00003688,
    "z_1": -6.4e-7,
    "Distance in Parsecs_1": 8.0115,
    "HYG Star ID_1": 119418,
    "Gliese Catalog_1": "Gl 831B"
  },
  {
    "Astrid #": "ASTRID 000186",
    "HYG Star ID": 118454,
    "Gliese Catalog": "Gl 257B",
    "Distance in Parsecs": 8.0244,
    "mag": 11.7,
    "Absolute Visual Magnitude": 12.178,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.432637,
    "y": 5.562374,
    "z": -5.60344,
    "vx": 0.00004307,
    "vy": 0.00000835,
    "vz": -0.00000274,
    "rarad": 1.82298433061989,
    "decrad": -0.773020146437649,
    "pmrarad": -0.00000545754760201389,
    "pmdecrad": -4.77468753e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 33412,
    "base": "Gl 257",
    "lum": 0.0011716556739113,
    "ra": 6.963287,
    "dec": -44.290792,
    "pmra": -1125.7,
    "pmdec": -98.48,
    "rv": 0,
    "Asset Name": "ASTRID 000186",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Ninja",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004307,
    "y_1": 0.00000835,
    "z_1": -0.00000274,
    "Distance in Parsecs_1": 8.0244,
    "HYG Star ID_1": 118454,
    "Gliese Catalog_1": "Gl 257B"
  },
  {
    "Astrid #": "ASTRID 000187",
    "HYG Star ID": 119536,
    "Gliese Catalog": "GJ 1276",
    "Distance in Parsecs": 8.0515,
    "mag": 15.65,
    "Absolute Visual Magnitude": 16.121,
    "Spectral Type": "DZ9+",
    "Color Index": 1.9,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 7.664822,
    "y": -2.274437,
    "z": -0.950855,
    "vx": 0.00002436,
    "vy": 0.00009335,
    "vz": -0.00002746,
    "rarad": 5.99472060455449,
    "decrad": -0.11837284385435,
    "pmrarad": 0.0000119770310899166,
    "pmdecrad": -0.000003434357087,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119536,
    "lum": 0.0000310170149947768,
    "ra": 22.898146,
    "dec": -6.782264,
    "pmra": 2470.44,
    "pmdec": -708.39,
    "rv": 0,
    "Asset Name": "ASTRID 000187",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Heartbreaker",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002436,
    "y_1": 0.00009335,
    "z_1": -0.00002746,
    "Distance in Parsecs_1": 8.0515,
    "HYG Star ID_1": 119536,
    "Gliese Catalog_1": "GJ 1276"
  },
  {
    "Astrid #": "ASTRID 000188",
    "HYG Star ID": 80105,
    "Hipparcos Catalogue": 80346,
    "Gliese Catalog": "Gl 623",
    "Distance in Parsecs": 8.0567,
    "mag": 10.27,
    "Absolute Visual Magnitude": 10.739,
    "Spectral Type": "M3",
    "Color Index": 1.475,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.174286,
    "y": -4.892451,
    "z": 6.0202,
    "vx": 0.00004319,
    "vy": -0.00001297,
    "vz": -0.00003298,
    "rarad": 4.29416349926961,
    "decrad": 0.843917389741002,
    "pmrarad": 0.00000555271652750694,
    "pmdecrad": -0.000002192715314,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 80105,
    "lum": 0.00440960816450637,
    "var_min": 10.317,
    "var_max": 10.207,
    "ra": 16.402496,
    "dec": 48.352905,
    "pmra": 1145.33,
    "pmdec": -452.28,
    "rv": -27.8,
    "Asset Name": "ASTRID 000188",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Windy",
    "Outfit": "Viking",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00004319,
    "y_1": -0.00001297,
    "z_1": -0.00003298,
    "Distance in Parsecs_1": 8.0567,
    "HYG Star ID_1": 80105,
    "Hipparcos Catalogue_1": 80346,
    "Gliese Catalog_1": "Gl 623"
  },
  {
    "Astrid #": "ASTRID 000189",
    "HYG Star ID": 89660,
    "Hipparcos Catalogue": 89937,
    "Henry Draper Catalogue": 170153,
    "Harvard Revised Catalogue": 6927,
    "Gliese Catalog": "Gl 713A",
    "Distance in Parsecs": 8.0574,
    "mag": 3.55,
    "Absolute Visual Magnitude": 4.019,
    "Spectral Type": "F7Vvar",
    "Color Index": 0.489,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.219434,
    "y": -2.381656,
    "z": 7.694536,
    "vx": 0.00002275,
    "vy": -0.00002083,
    "vz": 0.00002718,
    "rarad": 4.80421138304932,
    "decrad": 1.26942758413133,
    "pmrarad": 0.00000257474849469444,
    "pmdecrad": -0.000001704556419,
    "bayer": "Chi",
    "flam": 44,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 89660,
    "base": "Gl 713",
    "lum": 2.14980961038935,
    "bf": "44Chi Dra",
    "ra": 18.350736,
    "dec": 72.732843,
    "pmra": 531.08,
    "pmdec": -351.59,
    "rv": 32,
    "Asset Name": "ASTRID 000189",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Afro",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00002275,
    "y_1": -0.00002083,
    "z_1": 0.00002718,
    "Distance in Parsecs_1": 8.0574,
    "HYG Star ID_1": 89660,
    "Hipparcos Catalogue_1": 89937,
    "Henry Draper Catalogue_1": 170153,
    "Harvard Revised Catalogue_1": 6927,
    "Gliese Catalog_1": "Gl 713A"
  },
  {
    "Astrid #": "ASTRID 000190",
    "HYG Star ID": 119224,
    "Gliese Catalog": "NN 4063",
    "Distance in Parsecs": 8.0645,
    "mag": 11.42,
    "Absolute Visual Magnitude": 11.887,
    "Spectral Type": "M3  p",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.927552,
    "y": -6.096565,
    "z": 5.196894,
    "vx": 0.00000258,
    "vy": -0.00000509,
    "vz": -0.00000643,
    "rarad": 4.86337114068887,
    "decrad": 0.70025953398725,
    "pmrarad": 2.21753777486111e-7,
    "pmdecrad": -0.000001043280255,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 119224,
    "lum": 0.00153179271589341,
    "ra": 18.576709,
    "dec": 40.121916,
    "pmra": 45.74,
    "pmdec": -215.19,
    "rv": 0,
    "Asset Name": "ASTRID 000190",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Wavy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00000258,
    "y_1": -0.00000509,
    "z_1": -0.00000643,
    "Distance in Parsecs_1": 8.0645,
    "HYG Star ID_1": 119224,
    "Gliese Catalog_1": "NN 4063"
  },
  {
    "Astrid #": "ASTRID 000191",
    "HYG Star ID": 22396,
    "Hipparcos Catalogue": 22449,
    "Henry Draper Catalogue": 30652,
    "Harvard Revised Catalogue": 1543,
    "Gliese Catalog": "Gl 178",
    "Distance in Parsecs": 8.0684,
    "mag": 3.19,
    "Absolute Visual Magnitude": 3.656,
    "Spectral Type": "F6V",
    "Color Index": 0.484,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.413721,
    "y": 7.63677,
    "z": 0.977904,
    "vx": -0.00000983,
    "vy": 0.00002903,
    "vz": 0.00000348,
    "rarad": 1.26466619667956,
    "decrad": 0.121497179934303,
    "pmrarad": 0.00000224682052116666,
    "pmdecrad": 5.6335349e-8,
    "bayer": "Pi-3",
    "flam": 1,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 22396,
    "lum": 3.00330887867655,
    "bf": "1Pi 3Ori",
    "ra": 4.830669,
    "dec": 6.961276,
    "pmra": 463.44,
    "pmdec": 11.62,
    "rv": 24.4,
    "Asset Name": "ASTRID 000191",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Big Laugh",
    "Hair": "Greasy",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000983,
    "y_1": 0.00002903,
    "z_1": 0.00000348,
    "Distance in Parsecs_1": 8.0684,
    "HYG Star ID_1": 22396,
    "Hipparcos Catalogue_1": 22449,
    "Henry Draper Catalogue_1": 30652,
    "Harvard Revised Catalogue_1": 1543,
    "Gliese Catalog_1": "Gl 178"
  },
  {
    "Astrid #": "ASTRID 000192",
    "HYG Star ID": 86022,
    "Hipparcos Catalogue": 86287,
    "Gliese Catalog": "Gl 686",
    "Distance in Parsecs": 8.086,
    "mag": 9.62,
    "Absolute Visual Magnitude": 10.081,
    "Spectral Type": "M1",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.738247,
    "y": -7.628308,
    "z": 2.577969,
    "vx": 0.00003823,
    "vy": 0.00001789,
    "vz": 0.00003343,
    "rarad": 4.61590988123806,
    "decrad": 0.324486532096718,
    "pmrarad": 0.00000449339863549305,
    "pmdecrad": 0.000004766639625,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 86022,
    "lum": 0.00808351037390067,
    "ra": 17.631477,
    "dec": 18.591709,
    "pmra": 926.83,
    "pmdec": 983.19,
    "rv": -9.5,
    "Asset Name": "ASTRID 000192",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Manbun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00003823,
    "y_1": 0.00001789,
    "z_1": 0.00003343,
    "Distance in Parsecs_1": 8.086,
    "HYG Star ID_1": 86022,
    "Hipparcos Catalogue_1": 86287,
    "Gliese Catalog_1": "Gl 686"
  },
  {
    "Astrid #": "ASTRID 000193",
    "HYG Star ID": 118293,
    "Gliese Catalog": "GJ 2034",
    "Distance in Parsecs": 8.1301,
    "mag": 14.36,
    "Absolute Visual Magnitude": 14.81,
    "Spectral Type": "DA8",
    "Color Index": 0.52,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.248063,
    "y": 4.877201,
    "z": -6.103905,
    "vx": -0.0000083,
    "vy": -0.00001404,
    "vz": -0.00001428,
    "rarad": 1.13888244856865,
    "decrad": -0.84923991782065,
    "pmrarad": 2.04591373194444e-7,
    "pmdecrad": -0.000002658616413,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118293,
    "lum": 0.000103752841581801,
    "ra": 4.350211,
    "dec": -48.657863,
    "pmra": 42.2,
    "pmdec": -548.38,
    "rv": 0,
    "Asset Name": "ASTRID 000193",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Lion Mane",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000083,
    "y_1": -0.00001404,
    "z_1": -0.00001428,
    "Distance in Parsecs_1": 8.1301,
    "HYG Star ID_1": 118293,
    "Gliese Catalog_1": "GJ 2034"
  },
  {
    "Astrid #": "ASTRID 000194",
    "HYG Star ID": 118621,
    "Gliese Catalog": "GJ 1123",
    "Distance in Parsecs": 8.1301,
    "mag": 13.1,
    "Absolute Visual Magnitude": 13.55,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.297343,
    "y": 1.118177,
    "z": -7.947648,
    "vx": 0.00000542,
    "vy": -0.00003931,
    "vz": -0.00000643,
    "rarad": 2.42962739548574,
    "decrad": -1.35854188348235,
    "pmrarad": 0.00000322468971484722,
    "pmdecrad": -0.000003749049647,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118621,
    "lum": 0.00033113112148259,
    "ra": 9.280493,
    "dec": -77.838716,
    "pmra": 665.14,
    "pmdec": -773.3,
    "rv": 0,
    "Asset Name": "ASTRID 000194",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Nervous Grin",
    "Hair": "Medium Bob",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000542,
    "y_1": -0.00003931,
    "z_1": -0.00000643,
    "Distance in Parsecs_1": 8.1301,
    "HYG Star ID_1": 118621,
    "Gliese Catalog_1": "GJ 1123"
  },
  {
    "Astrid #": "ASTRID 000195",
    "HYG Star ID": 119588,
    "Gliese Catalog": "GJ 1289",
    "Distance in Parsecs": 8.1301,
    "mag": 12.57,
    "Absolute Visual Magnitude": 13.02,
    "Spectral Type": "k",
    "Color Index": 1.6,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.514311,
    "y": -0.48109,
    "z": 4.840026,
    "vx": 9.1e-7,
    "vy": 0.0000363,
    "vz": -0.0000079,
    "rarad": 6.20941338531883,
    "decrad": 0.6376940056393,
    "pmrarad": 0.00000446154637668055,
    "pmdecrad": -6.50867214e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119588,
    "lum": 0.000539510622515127,
    "ra": 23.718212,
    "dec": 36.537175,
    "pmra": 920.26,
    "pmdec": -134.25,
    "rv": -6,
    "Asset Name": "ASTRID 000195",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Frizzy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 9.1e-7,
    "y_1": 0.0000363,
    "z_1": -0.0000079,
    "Distance in Parsecs_1": 8.1301,
    "HYG Star ID_1": 119588,
    "Gliese Catalog_1": "GJ 1289"
  },
  {
    "Astrid #": "ASTRID 000196",
    "HYG Star ID": 118861,
    "Gliese Catalog": "Gl 493.1",
    "Distance in Parsecs": 8.1433,
    "mag": 13.4,
    "Absolute Visual Magnitude": 13.846,
    "Spectral Type": "dM5  e",
    "Color Index": 1.75,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -7.819993,
    "y": -2.115948,
    "z": 0.806542,
    "vx": 0.00003051,
    "vy": 0.00004669,
    "vz": 0.00000543,
    "rarad": 3.40584799792542,
    "decrad": 0.0992312935596499,
    "pmrarad": -0.00000455492149155555,
    "pmdecrad": 0.000001169511193,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118861,
    "lum": 0.00025211576308074,
    "ra": 13.009381,
    "dec": 5.685534,
    "pmra": -939.52,
    "pmdec": 241.23,
    "rv": -40,
    "Asset Name": "ASTRID 000196",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Braided Bun",
    "Outfit": "Samurai",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003051,
    "y_1": 0.00004669,
    "z_1": 0.00000543,
    "Distance in Parsecs_1": 8.1433,
    "HYG Star ID_1": 118861,
    "Gliese Catalog_1": "Gl 493.1"
  },
  {
    "Astrid #": "ASTRID 000197",
    "HYG Star ID": 118084,
    "Hipparcos Catalogue": 7751,
    "Henry Draper Catalogue": 10361,
    "Harvard Revised Catalogue": 487,
    "Gliese Catalog": "Gl 66A",
    "Proper Name": "p Eridani",
    "Distance in Parsecs": 8.1466,
    "mag": 5.8,
    "Absolute Visual Magnitude": 6.245,
    "Spectral Type": "K2 V",
    "Color Index": 0.86,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.110128,
    "y": 1.912046,
    "z": -6.7701,
    "vx": 0.00000634,
    "vy": 0.00001466,
    "vz": -0.00001601,
    "rarad": 0.435373049958666,
    "decrad": -0.9807811326846,
    "pmrarad": 0.00000130317917333333,
    "pmdecrad": 1.23186308e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118084,
    "base": "Gl 66",
    "lum": 0.276694164541151,
    "ra": 1.663003,
    "dec": -56.19462,
    "pmra": 268.8,
    "pmdec": 25.41,
    "rv": 19.5,
    "Asset Name": "ASTRID 000197",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Neko Ninja",
    "Hair": "Ninja",
    "Outfit": "Sage Mode",
    "Special": "Astral Burst",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "p Eridani",
    "Constellation": "Eridanus",
    "x_1": 0.00000634,
    "y_1": 0.00001466,
    "z_1": -0.00001601,
    "Distance in Parsecs_1": 8.1466,
    "HYG Star ID_1": 118084,
    "Hipparcos Catalogue_1": 7751,
    "Henry Draper Catalogue_1": 10361,
    "Harvard Revised Catalogue_1": 487,
    "Gliese Catalog_1": "Gl 66A"
  },
  {
    "Astrid #": "ASTRID 000198",
    "HYG Star ID": 119260,
    "Gliese Catalog": "Gl 747A",
    "Distance in Parsecs": 8.1566,
    "mag": 11.86,
    "Absolute Visual Magnitude": 12.302,
    "Spectral Type": "M3.5 J",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.001475,
    "y": -6.576,
    "z": 4.386486,
    "vx": 0.00002806,
    "vy": 0.0000747,
    "vz": 0.00000987,
    "rarad": 5.00778593874598,
    "decrad": 0.5679939080199,
    "pmrarad": 0.00000595501491963194,
    "pmdecrad": 0.000005194880398,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 119260,
    "base": "Gl 747",
    "lum": 0.00104520144145843,
    "ra": 19.128333,
    "dec": 32.543654,
    "pmra": 1228.31,
    "pmdec": 1071.52,
    "rv": -47,
    "Asset Name": "ASTRID 000198",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "High Bun",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00002806,
    "y_1": 0.0000747,
    "z_1": 0.00000987,
    "Distance in Parsecs_1": 8.1566,
    "HYG Star ID_1": 119260,
    "Gliese Catalog_1": "Gl 747A"
  },
  {
    "Astrid #": "ASTRID 000199",
    "HYG Star ID": 119261,
    "Gliese Catalog": "Gl 747B",
    "Distance in Parsecs": 8.1566,
    "mag": 12.16,
    "Absolute Visual Magnitude": 12.602,
    "Spectral Type": "M5   K",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.002064,
    "y": -6.577939,
    "z": 4.387778,
    "vx": 0.00003984,
    "vy": 0.00003592,
    "vz": 0.00003572,
    "rarad": 5.00778593874598,
    "decrad": 0.5679939080199,
    "pmrarad": 0.00000595501491963194,
    "pmdecrad": 0.000005194880398,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119260,
    "base": "Gl 747",
    "lum": 0.000792866374878546,
    "ra": 19.128333,
    "dec": 32.543654,
    "pmra": 1228.31,
    "pmdec": 1071.52,
    "rv": 0,
    "Asset Name": "ASTRID 000199",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Pixie",
    "Outfit": "Toga",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003984,
    "y_1": 0.00003592,
    "z_1": 0.00003572,
    "Distance in Parsecs_1": 8.1566,
    "HYG Star ID_1": 119261,
    "Gliese Catalog_1": "Gl 747B"
  },
  {
    "Astrid #": "ASTRID 000200",
    "HYG Star ID": 118902,
    "Gliese Catalog": "Gl 518",
    "Distance in Parsecs": 8.1833,
    "mag": 14.65,
    "Absolute Visual Magnitude": 15.085,
    "Spectral Type": "DZ9",
    "Color Index": 0.95,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -7.45256,
    "y": -3.339161,
    "z": 0.525186,
    "vx": -0.00006289,
    "vy": 0.0001332,
    "vz": -0.00004326,
    "rarad": 3.56283013948512,
    "decrad": 0.0642218784994999,
    "pmrarad": -0.0000179989502974652,
    "pmdecrad": -0.00000529737001,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118902,
    "lum": 0.0000805378441199066,
    "ra": 13.609009,
    "dec": 3.679643,
    "pmra": -3712.55,
    "pmdec": -1092.66,
    "rv": 0,
    "Asset Name": "ASTRID 000200",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Serious Shinobi",
    "Hair": "Elegant",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006289,
    "y_1": 0.0001332,
    "z_1": -0.00004326,
    "Distance in Parsecs_1": 8.1833,
    "HYG Star ID_1": 118902,
    "Gliese Catalog_1": "Gl 518"
  },
  {
    "Astrid #": "ASTRID 000201",
    "HYG Star ID": 5485,
    "Hipparcos Catalogue": 5496,
    "Gliese Catalog": "Gl 54",
    "Distance in Parsecs": 8.194,
    "mag": 9.8,
    "Absolute Visual Magnitude": 10.233,
    "Spectral Type": "K",
    "Color Index": 1.568,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.996021,
    "y": 0.950129,
    "z": -7.567468,
    "vx": 0.00002509,
    "vy": 0.00002417,
    "vz": -0.00001594,
    "rarad": 0.307071204329891,
    "decrad": -1.17713654092371,
    "pmrarad": 0.00000188689484472222,
    "pmdecrad": 0.00000277303729,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 5485,
    "lum": 0.00702748617656133,
    "ra": 1.172926,
    "dec": -67.444956,
    "pmra": 389.2,
    "pmdec": 571.98,
    "rv": 26.1,
    "Asset Name": "ASTRID 000201",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Oh No",
    "Hair": "High Bun",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": 0.00002509,
    "y_1": 0.00002417,
    "z_1": -0.00001594,
    "Distance in Parsecs_1": 8.194,
    "HYG Star ID_1": 5485,
    "Hipparcos Catalogue_1": 5496,
    "Gliese Catalog_1": "Gl 54"
  },
  {
    "Astrid #": "ASTRID 000202",
    "HYG Star ID": 118777,
    "Gliese Catalog": "GJ 1151",
    "Distance in Parsecs": 8.2102,
    "mag": 13.25,
    "Absolute Visual Magnitude": 13.678,
    "Spectral Type": "m",
    "Color Index": 1.84,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.448084,
    "y": 0.214547,
    "z": 6.136058,
    "vx": -0.00000363,
    "vy": 0.00006128,
    "vz": -0.00005184,
    "rarad": 3.10242084677237,
    "decrad": 0.844333182987,
    "pmrarad": -0.00000744174455242361,
    "pmdecrad": -0.00000474092026,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118777,
    "lum": 0.000294306598969901,
    "ra": 11.850375,
    "dec": 48.376728,
    "pmra": -1534.97,
    "pmdec": -977.88,
    "rv": -34,
    "Asset Name": "ASTRID 000202",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Good Boy",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000363,
    "y_1": 0.00006128,
    "z_1": -0.00005184,
    "Distance in Parsecs_1": 8.2102,
    "HYG Star ID_1": 118777,
    "Gliese Catalog_1": "GJ 1151"
  },
  {
    "Astrid #": "ASTRID 000203",
    "HYG Star ID": 113216,
    "Hipparcos Catalogue": 113576,
    "Henry Draper Catalogue": 217357,
    "Gliese Catalog": "Gl 884",
    "Distance in Parsecs": 8.2176,
    "mag": 7.88,
    "Absolute Visual Magnitude": 8.306,
    "Spectral Type": "K5/M0V",
    "Color Index": 1.379,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.334508,
    "y": -1.956062,
    "z": -3.148018,
    "vx": 0.00000563,
    "vy": -0.00003878,
    "vz": -0.0000039,
    "rarad": 6.02256142132941,
    "decrad": -0.393124012752663,
    "pmrarad": -0.00000438373378095138,
    "pmdecrad": 2.81628267e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113216,
    "lum": 0.0414572031786725,
    "ra": 23.00449,
    "dec": -22.524347,
    "pmra": -904.21,
    "pmdec": 58.09,
    "rv": 15.4,
    "Asset Name": "ASTRID 000203",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Battle Ready",
    "Hair": "Windy",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000563,
    "y_1": -0.00003878,
    "z_1": -0.0000039,
    "Distance in Parsecs_1": 8.2176,
    "HYG Star ID_1": 113216,
    "Hipparcos Catalogue_1": 113576,
    "Henry Draper Catalogue_1": 217357,
    "Gliese Catalog_1": "Gl 884"
  },
  {
    "Astrid #": "ASTRID 000204",
    "HYG Star ID": 4847,
    "Hipparcos Catalogue": 4856,
    "Gliese Catalog": "Gl 48",
    "Distance in Parsecs": 8.2366,
    "mag": 9.98,
    "Absolute Visual Magnitude": 10.401,
    "Spectral Type": "M3.5e",
    "Color Index": 1.501,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.493198,
    "y": 0.697723,
    "z": 7.819133,
    "vx": -0.00000463,
    "vy": 0.00007108,
    "vz": -0.000004,
    "rarad": 0.272708509259475,
    "decrad": 1.25104880470401,
    "pmrarad": 0.00000846334394009027,
    "pmdecrad": -0.000001844376684,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 4847,
    "lum": 0.00602004863684458,
    "ra": 1.04167,
    "dec": 71.679816,
    "pmra": 1745.69,
    "pmdec": -380.43,
    "rv": 0.8,
    "Asset Name": "ASTRID 000204",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Manbun",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000463,
    "y_1": 0.00007108,
    "z_1": -0.000004,
    "Distance in Parsecs_1": 8.2366,
    "HYG Star ID_1": 4847,
    "Hipparcos Catalogue_1": 4856,
    "Gliese Catalog_1": "Gl 48"
  },
  {
    "Astrid #": "ASTRID 000205",
    "HYG Star ID": 119218,
    "Gliese Catalog": "GJ 1227",
    "Distance in Parsecs": 8.244,
    "mag": 13.41,
    "Absolute Visual Magnitude": 13.829,
    "Spectral Type": "m",
    "Color Index": 1.76,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.377857,
    "y": -3.845699,
    "z": 7.282264,
    "vx": -0.00003268,
    "vy": -0.00004681,
    "vz": -0.000023,
    "rarad": 4.81058431958979,
    "decrad": 1.08294286476865,
    "pmrarad": -0.00000450362820415277,
    "pmdecrad": -0.000005954864627,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119218,
    "lum": 0.000256094351715676,
    "ra": 18.375079,
    "dec": 62.048056,
    "pmra": -928.94,
    "pmdec": -1228.28,
    "rv": 0,
    "Asset Name": "ASTRID 000205",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Grecian",
    "Outfit": "Boxer",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003268,
    "y_1": -0.00004681,
    "z_1": -0.000023,
    "Distance in Parsecs_1": 8.244,
    "HYG Star ID_1": 119218,
    "Gliese Catalog_1": "GJ 1227"
  },
  {
    "Astrid #": "ASTRID 000206",
    "HYG Star ID": 105913,
    "Hipparcos Catalogue": 106255,
    "Gliese Catalog": "Gl 831A",
    "Distance in Parsecs": 8.2974,
    "mag": 11.96,
    "Absolute Visual Magnitude": 12.365,
    "Spectral Type": "M4e...",
    "Color Index": 1.703,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.514846,
    "y": -4.940104,
    "z": -1.410878,
    "vx": -0.00001759,
    "vy": 0.00007199,
    "vz": 0.00000771,
    "rarad": 5.63440269294673,
    "decrad": -0.170879312804663,
    "pmrarad": 0.00000563256534069444,
    "pmdecrad": -2.62720533e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 105913,
    "base": "Gl 831",
    "lum": 0.00098627948563121,
    "var": "BB",
    "var_min": 12.053,
    "var_max": 11.823,
    "ra": 21.521833,
    "dec": -9.790663,
    "pmra": 1161.8,
    "pmdec": -54.19,
    "rv": -56.7,
    "Asset Name": "ASTRID 000206",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Happy Vamp",
    "Hair": "Elegant",
    "Outfit": "Toga",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00001759,
    "y_1": 0.00007199,
    "z_1": 0.00000771,
    "Distance in Parsecs_1": 8.2974,
    "HYG Star ID_1": 105913,
    "Hipparcos Catalogue_1": 106255,
    "Gliese Catalog_1": "Gl 831A"
  },
  {
    "Astrid #": "ASTRID 000207",
    "HYG Star ID": 86705,
    "Hipparcos Catalogue": 86974,
    "Henry Draper Catalogue": 161797,
    "Harvard Revised Catalogue": 6623,
    "Gliese Catalog": "Gl 695A",
    "Distance in Parsecs": 8.3105,
    "mag": 3.42,
    "Absolute Visual Magnitude": 3.822,
    "Spectral Type": "G5IV",
    "Color Index": 0.75,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.434406,
    "y": -7.3437,
    "z": 3.865655,
    "vx": -0.00001166,
    "vy": 0.00000175,
    "vz": -0.00003469,
    "rarad": 4.65330586361475,
    "decrad": 0.483817070102215,
    "pmrarad": -0.000001412844027875,
    "pmdecrad": -0.000003636102604,
    "bayer": "Mu",
    "flam": 86,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 86705,
    "base": "Gl 695",
    "lum": 2.57750786997053,
    "bf": "86Mu  Her",
    "ra": 17.774319,
    "dec": 27.720676,
    "pmra": -291.42,
    "pmdec": -750,
    "rv": -16.7,
    "Asset Name": "ASTRID 000207",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Lion Mane",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001166,
    "y_1": 0.00000175,
    "z_1": -0.00003469,
    "Distance in Parsecs_1": 8.3105,
    "HYG Star ID_1": 86705,
    "Hipparcos Catalogue_1": 86974,
    "Henry Draper Catalogue_1": 161797,
    "Harvard Revised Catalogue_1": 6623,
    "Gliese Catalog_1": "Gl 695A"
  },
  {
    "Astrid #": "ASTRID 000208",
    "HYG Star ID": 118425,
    "Gliese Catalog": "Gl 232",
    "Distance in Parsecs": 8.3333,
    "mag": 13.06,
    "Absolute Visual Magnitude": 13.456,
    "Spectral Type": "M4.5",
    "Color Index": 1.76,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.822196,
    "y": 7.601565,
    "z": 3.314223,
    "vx": -0.00002343,
    "vy": 0.00000575,
    "vz": -0.00001899,
    "rarad": 1.67852660699096,
    "decrad": 0.409017877009,
    "pmrarad": 0.00000272091981938194,
    "pmdecrad": -0.00000248451982,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118425,
    "lum": 0.000361077145100352,
    "ra": 6.411499,
    "dec": 23.434998,
    "pmra": 561.23,
    "pmdec": -512.47,
    "rv": 0,
    "Asset Name": "ASTRID 000208",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Ponytail",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002343,
    "y_1": 0.00000575,
    "z_1": -0.00001899,
    "Distance in Parsecs_1": 8.3333,
    "HYG Star ID_1": 118425,
    "Gliese Catalog_1": "Gl 232"
  },
  {
    "Astrid #": "ASTRID 000209",
    "HYG Star ID": 79777,
    "Hipparcos Catalogue": 80018,
    "Gliese Catalog": "Gl 618A",
    "Distance in Parsecs": 8.3438,
    "mag": 10.56,
    "Absolute Visual Magnitude": 10.953,
    "Spectral Type": "M4",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.794995,
    "y": -5.99789,
    "z": -5.082844,
    "vx": -0.00004391,
    "vy": -0.00002438,
    "vz": 0.00001933,
    "rarad": 4.27632036567572,
    "decrad": -0.655004808806335,
    "pmrarad": -0.0000035360370605,
    "pmdecrad": 0.000004805279276,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 79777,
    "base": "Gl 618",
    "lum": 0.00362076217756672,
    "var_min": 10.674,
    "var_max": 10.524,
    "ra": 16.334341,
    "dec": -37.529011,
    "pmra": -729.36,
    "pmdec": 991.16,
    "rv": 20,
    "Asset Name": "ASTRID 000209",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Heartbreaker",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00004391,
    "y_1": -0.00002438,
    "z_1": 0.00001933,
    "Distance in Parsecs_1": 8.3438,
    "HYG Star ID_1": 79777,
    "Hipparcos Catalogue_1": 80018,
    "Gliese Catalog_1": "Gl 618A"
  },
  {
    "Astrid #": "ASTRID 000210",
    "HYG Star ID": 62257,
    "Hipparcos Catalogue": 62452,
    "Gliese Catalog": "Gl 486",
    "Distance in Parsecs": 8.3703,
    "mag": 11.39,
    "Absolute Visual Magnitude": 11.776,
    "Spectral Type": "M4",
    "Color Index": 1.563,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.06968,
    "y": -1.713193,
    "z": 1.417736,
    "vx": -0.00003013,
    "vy": 0.0000354,
    "vz": -0.00001518,
    "rarad": 3.35078734131131,
    "decrad": 0.170193989403955,
    "pmrarad": -0.00000488556442169444,
    "pmdecrad": -0.000002235039548,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 62257,
    "lum": 0.00169668023234646,
    "var_min": 11.461,
    "var_max": 11.301,
    "ra": 12.799065,
    "dec": 9.751397,
    "pmra": -1007.72,
    "pmdec": -461.01,
    "rv": 18.8,
    "Asset Name": "ASTRID 000210",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "High Fade",
    "Outfit": "Tats",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00003013,
    "y_1": 0.0000354,
    "z_1": -0.00001518,
    "Distance in Parsecs_1": 8.3703,
    "HYG Star ID_1": 62257,
    "Hipparcos Catalogue_1": 62452,
    "Gliese Catalog_1": "Gl 486"
  },
  {
    "Astrid #": "ASTRID 000211",
    "HYG Star ID": 119179,
    "Gliese Catalog": "Gl 695B",
    "Distance in Parsecs": 8.3998,
    "mag": 10.35,
    "Absolute Visual Magnitude": 10.729,
    "Spectral Type": "M3   J",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.440255,
    "y": -7.42238,
    "z": 3.906295,
    "vx": -0.00001376,
    "vy": -7.7e-7,
    "vz": -0.00003357,
    "rarad": 4.6531544793647,
    "decrad": 0.4837312487127,
    "pmrarad": -0.00000162965270581944,
    "pmdecrad": -0.000003626105746,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 86705,
    "base": "Gl 695",
    "lum": 0.00445040976649537,
    "ra": 17.773741,
    "dec": 27.715759,
    "pmra": -336.14,
    "pmdec": -747.94,
    "rv": -13.9,
    "Asset Name": "ASTRID 000211",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Braid",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001376,
    "y_1": -7.7e-7,
    "z_1": -0.00003357,
    "Distance in Parsecs_1": 8.3998,
    "HYG Star ID_1": 119179,
    "Gliese Catalog_1": "Gl 695B"
  },
  {
    "Astrid #": "ASTRID 000212",
    "HYG Star ID": 119180,
    "Gliese Catalog": "Gl 695C",
    "Distance in Parsecs": 8.3998,
    "mag": 10.8,
    "Absolute Visual Magnitude": 11.179,
    "Spectral Type": "M4   K",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.440292,
    "y": -7.423009,
    "z": 3.906626,
    "vx": -0.0000145,
    "vy": -0.00001334,
    "vz": -0.00002696,
    "rarad": 4.6531544793647,
    "decrad": 0.4837312487127,
    "pmrarad": -0.00000162965270581944,
    "pmdecrad": -0.000003626105746,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 3,
    "comp_primary": 86705,
    "base": "Gl 695",
    "lum": 0.0029403565736725,
    "ra": 17.773741,
    "dec": 27.715759,
    "pmra": -336.14,
    "pmdec": -747.94,
    "rv": 0,
    "Asset Name": "ASTRID 000212",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Charmer",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.0000145,
    "y_1": -0.00001334,
    "z_1": -0.00002696,
    "Distance in Parsecs_1": 8.3998,
    "HYG Star ID_1": 119180,
    "Gliese Catalog_1": "Gl 695C"
  },
  {
    "Astrid #": "ASTRID 000213",
    "HYG Star ID": 118769,
    "Gliese Catalog": "Gl 438",
    "Distance in Parsecs": 8.4034,
    "mag": 10.36,
    "Absolute Visual Magnitude": 10.738,
    "Spectral Type": "K0",
    "Color Index": 1.53,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.17841,
    "y": 0.377538,
    "z": -6.607502,
    "vx": 0.00001483,
    "vy": -0.00002854,
    "vz": -0.00001391,
    "rarad": 3.06871456006141,
    "decrad": -0.90477610499645,
    "pmrarad": 0.00000325848122838194,
    "pmdecrad": -0.000002601199929,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 118769,
    "lum": 0.00441367143463439,
    "ra": 11.721626,
    "dec": -51.839852,
    "pmra": 672.11,
    "pmdec": -536.54,
    "rv": 0.5,
    "Asset Name": "ASTRID 000213",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Pompadour",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001483,
    "y_1": -0.00002854,
    "z_1": -0.00001391,
    "Distance in Parsecs_1": 8.4034,
    "HYG Star ID_1": 118769,
    "Gliese Catalog_1": "Gl 438"
  },
  {
    "Astrid #": "ASTRID 000214",
    "HYG Star ID": 61124,
    "Hipparcos Catalogue": 61317,
    "Henry Draper Catalogue": 109358,
    "Harvard Revised Catalogue": 4785,
    "Gliese Catalog": "Gl 475",
    "Distance in Parsecs": 8.4395,
    "mag": 4.24,
    "Absolute Visual Magnitude": 4.608,
    "Spectral Type": "G0V",
    "Color Index": 0.588,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.266213,
    "y": -0.929293,
    "z": 5.576481,
    "vx": -0.00000141,
    "vy": 0.00002895,
    "vz": 0.00001346,
    "rarad": 3.2888316235108,
    "decrad": 0.721824191441247,
    "pmrarad": -0.00000341822733612499,
    "pmdecrad": 0.000001420164714,
    "bayer": "Bet",
    "flam": 8,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 61124,
    "lum": 1.24968339721121,
    "bf": "8Bet CVn",
    "ra": 12.562411,
    "dec": 41.35748,
    "pmra": -705.06,
    "pmdec": 292.93,
    "rv": 6.6,
    "Asset Name": "ASTRID 000214",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Straw Hat",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000141,
    "y_1": 0.00002895,
    "z_1": 0.00001346,
    "Distance in Parsecs_1": 8.4395,
    "HYG Star ID_1": 61124,
    "Hipparcos Catalogue_1": 61317,
    "Henry Draper Catalogue_1": 109358,
    "Harvard Revised Catalogue_1": 4785,
    "Gliese Catalog_1": "Gl 475"
  },
  {
    "Astrid #": "ASTRID 000215",
    "HYG Star ID": 118128,
    "Gliese Catalog": "NN 3146",
    "Distance in Parsecs": 8.4602,
    "mag": 15.79,
    "Absolute Visual Magnitude": 16.153,
    "Spectral Type": "m",
    "Color Index": 1.98,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.806865,
    "y": 4.613779,
    "z": 1.988623,
    "vx": -0.00000867,
    "vy": 0.00001982,
    "vz": -0.00001627,
    "rarad": 0.595675756731778,
    "decrad": 0.23727651089055,
    "pmrarad": 0.00000251453463556944,
    "pmdecrad": -0.000001978762189,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118128,
    "lum": 0.0000301161880307882,
    "ra": 2.275314,
    "dec": 13.594943,
    "pmra": 518.66,
    "pmdec": -408.15,
    "rv": 0,
    "Asset Name": "ASTRID 000215",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Scared",
    "Hair": "Fire",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000867,
    "y_1": 0.00001982,
    "z_1": -0.00001627,
    "Distance in Parsecs_1": 8.4602,
    "HYG Star ID_1": 118128,
    "Gliese Catalog_1": "NN 3146"
  },
  {
    "Astrid #": "ASTRID 000216",
    "HYG Star ID": 118805,
    "Gliese Catalog": "GJ 1154A",
    "Distance in Parsecs": 8.4602,
    "mag": 13.73,
    "Absolute Visual Magnitude": 14.093,
    "Spectral Type": "m",
    "Color Index": 1.77,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.443299,
    "y": -0.526567,
    "z": 0.091746,
    "vx": -0.00000253,
    "vy": 0.00003835,
    "vz": -0.00001175,
    "rarad": 3.20387705115742,
    "decrad": 0.0108445266225,
    "pmrarad": -0.00000454357685143055,
    "pmdecrad": -0.00000138910755,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118805,
    "base": "GJ 1154",
    "lum": 0.000200816780418398,
    "ra": 12.237909,
    "dec": 0.621346,
    "pmra": -937.18,
    "pmdec": -286.52,
    "rv": 0,
    "Asset Name": "ASTRID 000216",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Manbun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000253,
    "y_1": 0.00003835,
    "z_1": -0.00001175,
    "Distance in Parsecs_1": 8.4602,
    "HYG Star ID_1": 118805,
    "Gliese Catalog_1": "GJ 1154A"
  },
  {
    "Astrid #": "ASTRID 000217",
    "HYG Star ID": 118201,
    "Gliese Catalog": "GJ 1057",
    "Distance in Parsecs": 8.4674,
    "mag": 13.8,
    "Absolute Visual Magnitude": 14.161,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.608502,
    "y": 6.304334,
    "z": 0.704946,
    "vx": -0.00005226,
    "vy": 0.00004604,
    "vz": 0.00000437,
    "rarad": 0.843740690141488,
    "decrad": 0.08335053223335,
    "pmrarad": 0.00000822554282977777,
    "pmdecrad": 5.17504667e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118201,
    "lum": 0.000188625324535106,
    "ra": 3.222852,
    "dec": 4.775634,
    "pmra": 1696.64,
    "pmdec": 106.74,
    "rv": 0,
    "Asset Name": "ASTRID 000217",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Lion Mane",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005226,
    "y_1": 0.00004604,
    "z_1": 0.00000437,
    "Distance in Parsecs_1": 8.4674,
    "HYG Star ID_1": 118201,
    "Gliese Catalog_1": "GJ 1057"
  },
  {
    "Astrid #": "ASTRID 000218",
    "HYG Star ID": 119077,
    "Gliese Catalog": "Gl 618B",
    "Distance in Parsecs": 8.4724,
    "mag": 14.15,
    "Absolute Visual Magnitude": 14.51,
    "Spectral Type": "M5  :",
    "Color Index": 1.79,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.837867,
    "y": -6.090101,
    "z": -5.161273,
    "vx": -0.00003687,
    "vy": -0.00001025,
    "vz": 0.00003234,
    "rarad": 4.27637038844993,
    "decrad": -0.655034533678099,
    "pmrarad": -0.00000343471100126388,
    "pmdecrad": 0.000004815266438,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 79777,
    "base": "Gl 618",
    "lum": 0.000136772882559584,
    "ra": 16.334532,
    "dec": -37.530714,
    "pmra": -708.46,
    "pmdec": 993.22,
    "rv": 0,
    "Asset Name": "ASTRID 000218",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Fire",
    "Outfit": "Elven",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003687,
    "y_1": -0.00001025,
    "z_1": 0.00003234,
    "Distance in Parsecs_1": 8.4724,
    "HYG Star ID_1": 119077,
    "Gliese Catalog_1": "Gl 618B"
  },
  {
    "Astrid #": "ASTRID 000219",
    "HYG Star ID": 118048,
    "Gliese Catalog": "NN 3076",
    "Distance in Parsecs": 8.4746,
    "mag": 14.36,
    "Absolute Visual Magnitude": 14.719,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.77526,
    "y": 2.504849,
    "z": 2.256081,
    "vx": -9.1e-7,
    "vy": 0.00000713,
    "vz": -0.00000477,
    "rarad": 0.311656118824664,
    "decrad": 0.2694660018537,
    "pmrarad": 8.33976493291666e-7,
    "pmdecrad": -5.83962926e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118048,
    "lum": 0.000112823612164899,
    "ra": 1.190439,
    "dec": 15.439265,
    "pmra": 172.02,
    "pmdec": -120.45,
    "rv": 0,
    "Asset Name": "ASTRID 000219",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "High Fade",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -9.1e-7,
    "y_1": 0.00000713,
    "z_1": -0.00000477,
    "Distance in Parsecs_1": 8.4746,
    "HYG Star ID_1": 118048,
    "Gliese Catalog_1": "NN 3076"
  },
  {
    "Astrid #": "ASTRID 000220",
    "HYG Star ID": 93578,
    "Hipparcos Catalogue": 93873,
    "Gliese Catalog": "Gl 745A",
    "Distance in Parsecs": 8.5143,
    "mag": 10.77,
    "Absolute Visual Magnitude": 11.119,
    "Spectral Type": "M2",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.295683,
    "y": -7.616551,
    "z": 3.035818,
    "vx": -0.00000868,
    "vy": -0.00003996,
    "vz": -0.00000162,
    "rarad": 5.00513762804003,
    "decrad": 0.3645652152343,
    "pmrarad": -0.00000233108113884722,
    "pmdecrad": -0.00000167551608,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 1,
    "comp_primary": 93578,
    "base": "Gl 745",
    "lum": 0.00310742031038858,
    "var_min": 10.841,
    "var_max": 10.671,
    "ra": 19.118217,
    "dec": 20.888048,
    "pmra": -480.82,
    "pmdec": -345.6,
    "rv": 32.1,
    "Asset Name": "ASTRID 000220",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Medium Bob",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": -0.00000868,
    "y_1": -0.00003996,
    "z_1": -0.00000162,
    "Distance in Parsecs_1": 8.5143,
    "HYG Star ID_1": 93578,
    "Hipparcos Catalogue_1": 93873,
    "Gliese Catalog_1": "Gl 745A"
  },
  {
    "Astrid #": "ASTRID 000221",
    "HYG Star ID": 118345,
    "Gliese Catalog": "Gl 185B",
    "Distance in Parsecs": 8.5193,
    "mag": 10.5,
    "Absolute Visual Magnitude": 10.848,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 1.972362,
    "y": 7.690888,
    "z": -3.088445,
    "vx": 0.00000524,
    "vy": -0.00000531,
    "vz": -0.00000989,
    "rarad": 1.31975491463603,
    "decrad": -0.3709739814691,
    "pmrarad": -7.51267279388888e-7,
    "pmdecrad": -0.000001245389382,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 2,
    "comp_primary": 23397,
    "base": "Gl 185",
    "lum": 0.00398841186909052,
    "ra": 5.041092,
    "dec": -21.255243,
    "pmra": -154.96,
    "pmdec": -256.88,
    "rv": 0,
    "Asset Name": "ASTRID 000221",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Neko Ninja",
    "Hair": "Horns",
    "Outfit": "Sharky",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000524,
    "y_1": -0.00000531,
    "z_1": -0.00000989,
    "Distance in Parsecs_1": 8.5193,
    "HYG Star ID_1": 118345,
    "Gliese Catalog_1": "Gl 185B"
  },
  {
    "Astrid #": "ASTRID 000222",
    "HYG Star ID": 18852,
    "Hipparcos Catalogue": 18899,
    "Distance in Parsecs": 8.5529,
    "mag": 10.41,
    "Absolute Visual Magnitude": 10.749,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.031777,
    "y": 7.204078,
    "z": -2.235649,
    "vx": 0,
    "vy": -0.00000259,
    "vz": -0.00000835,
    "rarad": 1.06057298533558,
    "decrad": -0.264462829455395,
    "pmrarad": -1.48304504881944e-7,
    "pmdecrad": -0.000001011709188,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 18852,
    "lum": 0.00436918063385287,
    "ra": 4.05109,
    "dec": -15.152604,
    "pmra": -30.59,
    "pmdec": -208.68,
    "rv": 0,
    "Asset Name": "ASTRID 000222",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Round Brush",
    "Outfit": "Boxer",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0,
    "y_1": -0.00000259,
    "z_1": -0.00000835,
    "Distance in Parsecs_1": 8.5529,
    "HYG Star ID_1": 18852,
    "Hipparcos Catalogue_1": 18899
  },
  {
    "Astrid #": "ASTRID 000223",
    "HYG Star ID": 64720,
    "Hipparcos Catalogue": 64924,
    "Henry Draper Catalogue": 115617,
    "Harvard Revised Catalogue": 5019,
    "Gliese Catalog": "Gl 506",
    "Distance in Parsecs": 8.5551,
    "mag": 4.74,
    "Absolute Visual Magnitude": 5.079,
    "Spectral Type": "G5V",
    "Color Index": 0.709,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.651172,
    "y": -2.724657,
    "z": -2.6878,
    "vx": 0.00000585,
    "vy": 0.00004919,
    "vz": -0.00003919,
    "rarad": 3.48370248056127,
    "decrad": -0.319590662820996,
    "pmrarad": -0.00000518702156826389,
    "pmdecrad": -0.000005157350971,
    "flam": 61,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 64720,
    "lum": 0.809841447325788,
    "bf": "61    Vir",
    "ra": 13.306763,
    "dec": -18.311196,
    "pmra": -1069.9,
    "pmdec": -1063.78,
    "rv": -8.4,
    "Asset Name": "ASTRID 000223",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Side Part",
    "Outfit": "River Spirit",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000585,
    "y_1": 0.00004919,
    "z_1": -0.00003919,
    "Distance in Parsecs_1": 8.5551,
    "HYG Star ID_1": 64720,
    "Hipparcos Catalogue_1": 64924,
    "Henry Draper Catalogue_1": 115617,
    "Harvard Revised Catalogue_1": 5019,
    "Gliese Catalog_1": "Gl 506"
  },
  {
    "Astrid #": "ASTRID 000224",
    "HYG Star ID": 118572,
    "Gliese Catalog": "NN 3517",
    "Distance in Parsecs": 8.5616,
    "mag": 18.8,
    "Absolute Visual Magnitude": 19.137,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.871673,
    "y": 6.20909,
    "z": -0.521194,
    "vx": 0.00001651,
    "vy": 0.00001494,
    "vz": -0.000008,
    "rarad": 2.32827185821155,
    "decrad": -0.0609133899127,
    "pmrarad": -0.00000260005576881944,
    "pmdecrad": -9.36078254e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118572,
    "lum": 0.00000192841277563942,
    "ra": 8.893343,
    "dec": -3.49008,
    "pmra": -536.3,
    "pmdec": -193.08,
    "rv": 0,
    "Asset Name": "ASTRID 000224",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Bad Dreams",
    "Hair": "Pixie",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001651,
    "y_1": 0.00001494,
    "z_1": -0.000008,
    "Distance in Parsecs_1": 8.5616,
    "HYG Star ID_1": 118572,
    "Gliese Catalog_1": "NN 3517"
  },
  {
    "Astrid #": "ASTRID 000225",
    "HYG Star ID": 23397,
    "Hipparcos Catalogue": 23452,
    "Henry Draper Catalogue": 32450,
    "Gliese Catalog": "Gl 185A",
    "Distance in Parsecs": 8.5771,
    "mag": 8.31,
    "Absolute Visual Magnitude": 8.643,
    "Spectral Type": "M0V",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.985393,
    "y": 7.742932,
    "z": -3.109538,
    "vx": 8e-7,
    "vy": -0.00002058,
    "vz": -0.00000222,
    "rarad": 1.31979089227955,
    "decrad": -0.370998437476231,
    "pmrarad": -6.86253764826388e-7,
    "pmdecrad": -0.000001075025855,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 23397,
    "base": "Gl 185",
    "lum": 0.030394849687701,
    "ra": 5.04123,
    "dec": -21.256645,
    "pmra": -141.55,
    "pmdec": -221.74,
    "rv": -17.2,
    "Asset Name": "ASTRID 000225",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Curly",
    "Outfit": "Samurai",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 8e-7,
    "y_1": -0.00002058,
    "z_1": -0.00000222,
    "Distance in Parsecs_1": 8.5771,
    "HYG Star ID_1": 23397,
    "Hipparcos Catalogue_1": 23452,
    "Henry Draper Catalogue_1": 32450,
    "Gliese Catalog_1": "Gl 185A"
  },
  {
    "Astrid #": "ASTRID 000226",
    "HYG Star ID": 57629,
    "Hipparcos Catalogue": 57802,
    "Gliese Catalog": "Gl 450",
    "Distance in Parsecs": 8.5852,
    "mag": 9.76,
    "Absolute Visual Magnitude": 10.091,
    "Spectral Type": "M1V",
    "Color Index": 1.477,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.003872,
    "y": 0.27144,
    "z": 4.957603,
    "vx": 0.00000614,
    "vy": 0.00001109,
    "vz": 0.00000896,
    "rarad": 3.10285892270703,
    "decrad": 0.615612833435756,
    "pmrarad": -0.00000131854776700694,
    "pmdecrad": 0.000001235935515,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 57629,
    "lum": 0.00800940030533287,
    "ra": 11.852048,
    "dec": 35.272017,
    "pmra": -271.97,
    "pmdec": 254.93,
    "rv": 0.5,
    "Asset Name": "ASTRID 000226",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Straw Hat",
    "Outfit": "Astro",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000614,
    "y_1": 0.00001109,
    "z_1": 0.00000896,
    "Distance in Parsecs_1": 8.5852,
    "HYG Star ID_1": 57629,
    "Hipparcos Catalogue_1": 57802,
    "Gliese Catalog_1": "Gl 450"
  },
  {
    "Astrid #": "ASTRID 000227",
    "HYG Star ID": 1595,
    "Hipparcos Catalogue": 1599,
    "Henry Draper Catalogue": 1581,
    "Harvard Revised Catalogue": 77,
    "Gliese Catalog": "Gl 17",
    "Distance in Parsecs": 8.5866,
    "mag": 4.23,
    "Absolute Visual Magnitude": 4.561,
    "Spectral Type": "F9V",
    "Color Index": 0.576,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.631914,
    "y": 0.318885,
    "z": -7.774227,
    "vx": 0.00004144,
    "vy": 0.00007498,
    "vz": 0.00001226,
    "rarad": 0.0874781017392323,
    "decrad": -1.13227869803381,
    "pmrarad": 0.00000827848448369444,
    "pmdecrad": 0.000005649824707,
    "bayer": "Zet",
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 1595,
    "lum": 1.30496841413022,
    "bf": "Zet Tuc",
    "ra": 0.334142,
    "dec": -64.874791,
    "pmra": 1707.56,
    "pmdec": 1165.36,
    "rv": 9,
    "Asset Name": "ASTRID 000227",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Innocent",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": 0.00004144,
    "y_1": 0.00007498,
    "z_1": 0.00001226,
    "Distance in Parsecs_1": 8.5866,
    "HYG Star ID_1": 1595,
    "Hipparcos Catalogue_1": 1599,
    "Henry Draper Catalogue_1": 1581,
    "Harvard Revised Catalogue_1": 77,
    "Gliese Catalog_1": "Gl 17"
  },
  {
    "Astrid #": "ASTRID 000228",
    "HYG Star ID": 112869,
    "Hipparcos Catalogue": 113229,
    "Gliese Catalog": "Gl 877",
    "Distance in Parsecs": 8.6155,
    "mag": 10.42,
    "Absolute Visual Magnitude": 10.744,
    "Spectral Type": "K",
    "Color Index": 1.485,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.078553,
    "y": -0.598387,
    "z": -8.338773,
    "vx": -0.00007433,
    "vy": -0.00002326,
    "vz": 0.000074,
    "rarad": 6.00300888655269,
    "decrad": -1.31700225334278,
    "pmrarad": -0.00000498039397761111,
    "pmdecrad": -0.000005146782032,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 112869,
    "lum": 0.00438934785534716,
    "var_min": 10.487,
    "var_max": 10.347,
    "ra": 22.929805,
    "dec": -75.458671,
    "pmra": -1027.28,
    "pmdec": -1061.6,
    "rv": -86,
    "Asset Name": "ASTRID 000228",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Manbun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": -0.00007433,
    "y_1": -0.00002326,
    "z_1": 0.000074,
    "Distance in Parsecs_1": 8.6155,
    "HYG Star ID_1": 112869,
    "Hipparcos Catalogue_1": 113229,
    "Gliese Catalog_1": "Gl 877"
  },
  {
    "Astrid #": "ASTRID 000229",
    "HYG Star ID": 38846,
    "Hipparcos Catalogue": 38956,
    "Gliese Catalog": "GJ 1105",
    "Distance in Parsecs": 8.6162,
    "mag": 12.02,
    "Absolute Visual Magnitude": 12.343,
    "Spectral Type": "M4V",
    "Color Index": 1.618,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.192462,
    "y": 5.630519,
    "z": 5.68706,
    "vx": -0.00001286,
    "vy": 0.00000471,
    "vz": -0.00002892,
    "rarad": 2.08658893514119,
    "decrad": 0.720885659237906,
    "pmrarad": 0.00000102950185065972,
    "pmdecrad": -0.000003321458525,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 38846,
    "lum": 0.00100646806643832,
    "var_min": 12.106,
    "var_max": 11.916,
    "ra": 7.970183,
    "dec": 41.303706,
    "pmra": 212.35,
    "pmdec": -685.1,
    "rv": -11,
    "Asset Name": "ASTRID 000229",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Antlers",
    "Outfit": "River Spirit",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001286,
    "y_1": 0.00000471,
    "z_1": -0.00002892,
    "Distance in Parsecs_1": 8.6162,
    "HYG Star ID_1": 38846,
    "Hipparcos Catalogue_1": 38956,
    "Gliese Catalog_1": "GJ 1105"
  },
  {
    "Astrid #": "ASTRID 000230",
    "HYG Star ID": 119519,
    "Gliese Catalog": "Gl 867B",
    "Distance in Parsecs": 8.6423,
    "mag": 11.45,
    "Absolute Visual Magnitude": 11.767,
    "Spectral Type": "dM4  e",
    "Color Index": 1.6,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 7.585671,
    "y": -2.807674,
    "z": -3.043668,
    "vx": 0.00000574,
    "vy": 0.00001783,
    "vz": -0.00000215,
    "rarad": 5.92868494859114,
    "decrad": -0.3599020578335,
    "pmrarad": 0.00000216537182283333,
    "pmdecrad": -2.6587667e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 2,
    "comp_primary": 111450,
    "base": "Gl 867",
    "lum": 0.00171080298759932,
    "ra": 22.645908,
    "dec": -20.620869,
    "pmra": 446.64,
    "pmdec": -54.84,
    "rv": 0,
    "Asset Name": "ASTRID 000230",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Smoulder",
    "Hair": "Goddess",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000574,
    "y_1": 0.00001783,
    "z_1": -0.00000215,
    "Distance in Parsecs_1": 8.6423,
    "HYG Star ID_1": 119519,
    "Gliese Catalog_1": "Gl 867B"
  },
  {
    "Astrid #": "ASTRID 000231",
    "HYG Star ID": 27844,
    "Hipparcos Catalogue": 27913,
    "Henry Draper Catalogue": 39587,
    "Harvard Revised Catalogue": 2047,
    "Gliese Catalog": "Gl 222A",
    "Distance in Parsecs": 8.6633,
    "mag": 4.39,
    "Absolute Visual Magnitude": 4.702,
    "Spectral Type": "G0V",
    "Color Index": 0.594,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.199145,
    "y": 8.12391,
    "z": 3.002187,
    "vx": 0.00000657,
    "vy": -0.00001158,
    "vz": -0.00000865,
    "rarad": 1.54628817413327,
    "decrad": 0.353886004695195,
    "pmrarad": -7.910704825625e-7,
    "pmdecrad": -4.79577692e-7,
    "bayer": "Chi-1",
    "flam": 54,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 27844,
    "base": "Gl 222",
    "lum": 1.1460405911366,
    "var": "Chi-1",
    "var_min": 4.409,
    "var_max": 4.379,
    "bf": "54Chi1Ori",
    "ra": 5.906386,
    "dec": 20.276174,
    "pmra": -163.17,
    "pmdec": -98.92,
    "rv": -13.4,
    "Asset Name": "ASTRID 000231",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Elegant",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000657,
    "y_1": -0.00001158,
    "z_1": -0.00000865,
    "Distance in Parsecs_1": 8.6633,
    "HYG Star ID_1": 27844,
    "Hipparcos Catalogue_1": 27913,
    "Henry Draper Catalogue_1": 39587,
    "Harvard Revised Catalogue_1": 2047,
    "Gliese Catalog_1": "Gl 222A"
  },
  {
    "Astrid #": "ASTRID 000232",
    "HYG Star ID": 31220,
    "Hipparcos Catalogue": 31292,
    "Distance in Parsecs": 8.6813,
    "mag": 11.41,
    "Absolute Visual Magnitude": 11.717,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.316527,
    "y": 2.131914,
    "z": -8.409502,
    "vx": 0.00001132,
    "vy": 0.00001225,
    "vz": 0.00000268,
    "rarad": 1.71823014575856,
    "decrad": -1.31990508126611,
    "pmrarad": -0.0000014970561641875,
    "pmdecrad": 0.000001243741016,
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 31220,
    "lum": 0.00179143064840602,
    "ra": 6.563156,
    "dec": -75.624991,
    "pmra": -308.79,
    "pmdec": 256.54,
    "rv": 0,
    "Asset Name": "ASTRID 000232",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Wavy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": 0.00001132,
    "y_1": 0.00001225,
    "z_1": 0.00000268,
    "Distance in Parsecs_1": 8.6813,
    "HYG Star ID_1": 31220,
    "Hipparcos Catalogue_1": 31292
  },
  {
    "Astrid #": "ASTRID 000233",
    "HYG Star ID": 111450,
    "Hipparcos Catalogue": 111802,
    "Henry Draper Catalogue": 214479,
    "Gliese Catalog": "Gl 867A",
    "Distance in Parsecs": 8.6949,
    "mag": 9.06,
    "Absolute Visual Magnitude": 9.364,
    "Spectral Type": "M0Vpe",
    "Color Index": 1.466,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.631801,
    "y": -2.824618,
    "z": -3.062202,
    "vx": -0.00000233,
    "vy": 0.00002111,
    "vz": -2e-8,
    "rarad": 5.92870584014296,
    "decrad": -0.359906685756793,
    "pmrarad": 0.00000218447348184722,
    "pmdecrad": -3.87172205e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 111450,
    "base": "Gl 867",
    "lum": 0.0156458801804055,
    "var": "FK",
    "var_min": 9.099,
    "var_max": 9.009,
    "ra": 22.645988,
    "dec": -20.621134,
    "pmra": 450.58,
    "pmdec": -79.86,
    "rv": -8.7,
    "Asset Name": "ASTRID 000233",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Ponytail",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000233,
    "y_1": 0.00002111,
    "z_1": -2e-8,
    "Distance in Parsecs_1": 8.6949,
    "HYG Star ID_1": 111450,
    "Hipparcos Catalogue_1": 111802,
    "Henry Draper Catalogue_1": 214479,
    "Gliese Catalog_1": "Gl 867A"
  },
  {
    "Astrid #": "ASTRID 000234",
    "HYG Star ID": 118955,
    "Gliese Catalog": "NN 3839",
    "Distance in Parsecs": 8.6957,
    "mag": 13.1,
    "Absolute Visual Magnitude": 13.403,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.113698,
    "y": -4.16477,
    "z": 4.570841,
    "vx": -0.00001636,
    "vy": 0.00001866,
    "vz": -0.00000488,
    "rarad": 3.73962757129067,
    "decrad": 0.5534717370938,
    "pmrarad": -0.00000283315418643055,
    "pmdecrad": -6.59298124e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118955,
    "lum": 0.000379140344183305,
    "ra": 14.284325,
    "dec": 31.711595,
    "pmra": -584.38,
    "pmdec": -135.99,
    "rv": 0,
    "Asset Name": "ASTRID 000234",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Arrogant",
    "Hair": "Little Crown",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001636,
    "y_1": 0.00001866,
    "z_1": -0.00000488,
    "Distance in Parsecs_1": 8.6957,
    "HYG Star ID_1": 118955,
    "Gliese Catalog_1": "NN 3839"
  },
  {
    "Astrid #": "ASTRID 000235",
    "HYG Star ID": 118446,
    "Gliese Catalog": "Gl 250B",
    "Distance in Parsecs": 8.7002,
    "mag": 10.09,
    "Absolute Visual Magnitude": 10.392,
    "Spectral Type": "M2",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.960063,
    "y": 8.439581,
    "z": -0.786739,
    "vx": 0.00002377,
    "vy": -0.00000169,
    "vz": 6.4e-7,
    "rarad": 1.79899779230625,
    "decrad": -0.090555126,
    "pmrarad": -0.000002617993875,
    "pmdecrad": 0,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 2,
    "comp_primary": 32897,
    "base": "Gl 250",
    "lum": 0.00607015806341044,
    "ra": 6.871665,
    "dec": -5.188427,
    "pmra": -540,
    "pmdec": 0,
    "rv": -6.9,
    "Asset Name": "ASTRID 000235",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Nervous Grin",
    "Hair": "Heartbreaker",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00002377,
    "y_1": -0.00000169,
    "z_1": 6.4e-7,
    "Distance in Parsecs_1": 8.7002,
    "HYG Star ID_1": 118446,
    "Gliese Catalog_1": "Gl 250B"
  },
  {
    "Astrid #": "ASTRID 000236",
    "HYG Star ID": 23256,
    "Hipparcos Catalogue": 23311,
    "Henry Draper Catalogue": 32147,
    "Harvard Revised Catalogue": 1614,
    "Gliese Catalog": "Gl 183",
    "Distance in Parsecs": 8.7078,
    "mag": 6.22,
    "Absolute Visual Magnitude": 6.52,
    "Spectral Type": "K3V",
    "Color Index": 1.049,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.212605,
    "y": 8.376843,
    "z": -0.872994,
    "vx": -0.00001791,
    "vy": 0.00002324,
    "vz": -0.00004887,
    "rarad": 1.31256020505006,
    "decrad": -0.100420579833882,
    "pmrarad": 0.00000267006286429166,
    "pmdecrad": -0.000005378038158,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 23256,
    "lum": 0.214783047413053,
    "ra": 5.013611,
    "dec": -5.753675,
    "pmra": 550.74,
    "pmdec": -1109.3,
    "rv": 22.2,
    "Asset Name": "ASTRID 000236",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Mohawk",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001791,
    "y_1": 0.00002324,
    "z_1": -0.00004887,
    "Distance in Parsecs_1": 8.7078,
    "HYG Star ID_1": 23256,
    "Hipparcos Catalogue_1": 23311,
    "Henry Draper Catalogue_1": 32147,
    "Harvard Revised Catalogue_1": 1614,
    "Gliese Catalog_1": "Gl 183"
  },
  {
    "Astrid #": "ASTRID 000237",
    "HYG Star ID": 32897,
    "Hipparcos Catalogue": 32984,
    "Henry Draper Catalogue": 50281,
    "Gliese Catalog": "Gl 250A",
    "Distance in Parsecs": 8.71,
    "mag": 6.58,
    "Absolute Visual Magnitude": 6.88,
    "Spectral Type": "K3V",
    "Color Index": 1.071,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.962415,
    "y": 8.449535,
    "z": -0.785422,
    "vx": 0.00002461,
    "vy": -0.00000434,
    "vz": 7.5e-7,
    "rarad": 1.79900185947611,
    "decrad": -0.0902983279899462,
    "pmrarad": -0.00000263937415831249,
    "pmdecrad": -1.5998851e-8,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 32897,
    "base": "Gl 250",
    "lum": 0.154170045294955,
    "ra": 6.871681,
    "dec": -5.173713,
    "pmra": -544.41,
    "pmdec": -3.3,
    "rv": -9.6,
    "Asset Name": "ASTRID 000237",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Pirate Hat",
    "Outfit": "River Spirit",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00002461,
    "y_1": -0.00000434,
    "z_1": 7.5e-7,
    "Distance in Parsecs_1": 8.71,
    "HYG Star ID_1": 32897,
    "Hipparcos Catalogue_1": 32984,
    "Henry Draper Catalogue_1": 50281,
    "Gliese Catalog_1": "Gl 250A"
  },
  {
    "Astrid #": "ASTRID 000238",
    "HYG Star ID": 93604,
    "Hipparcos Catalogue": 93899,
    "Henry Draper Catalogue": 349726,
    "Gliese Catalog": "Gl 745B",
    "Distance in Parsecs": 8.7527,
    "mag": 10.76,
    "Absolute Visual Magnitude": 11.049,
    "Spectral Type": "M2",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.364482,
    "y": -7.829067,
    "z": 3.119241,
    "vx": -0.00000932,
    "vy": -0.00003973,
    "vz": -0.00000166,
    "rarad": 5.00569327428316,
    "decrad": 0.36437265162133,
    "pmrarad": -0.0000023321962103125,
    "pmdecrad": -0.000001616271848,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 2,
    "comp_primary": 93578,
    "base": "Gl 745",
    "lum": 0.00331436245009259,
    "var_min": 10.847,
    "var_max": 10.667,
    "ra": 19.12034,
    "dec": 20.877015,
    "pmra": -481.05,
    "pmdec": -333.38,
    "rv": 31.7,
    "Asset Name": "ASTRID 000238",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Worried Vamp",
    "Hair": "Bob",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": -0.00000932,
    "y_1": -0.00003973,
    "z_1": -0.00000166,
    "Distance in Parsecs_1": 8.7527,
    "HYG Star ID_1": 93604,
    "Hipparcos Catalogue_1": 93899,
    "Henry Draper Catalogue_1": 349726,
    "Gliese Catalog_1": "Gl 745B"
  },
  {
    "Astrid #": "ASTRID 000239",
    "HYG Star ID": 119346,
    "Gliese Catalog": "Gl 791.2",
    "Distance in Parsecs": 8.7566,
    "mag": 13.05,
    "Absolute Visual Magnitude": 13.338,
    "Spectral Type": "dM6  e",
    "Color Index": 1.65,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.248184,
    "y": -6.851996,
    "z": 1.473543,
    "vx": 0.00001284,
    "vy": 0.00002996,
    "vz": 0.00000246,
    "rarad": 5.36600850377785,
    "decrad": 0.16909747190445,
    "pmrarad": 0.00000324461555711805,
    "pmdecrad": 5.83798089e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119346,
    "lum": 0.000402531618974211,
    "ra": 20.496643,
    "dec": 9.688571,
    "pmra": 669.25,
    "pmdec": 120.42,
    "rv": -15,
    "Asset Name": "ASTRID 000239",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Ponytail",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001284,
    "y_1": 0.00002996,
    "z_1": 0.00000246,
    "Distance in Parsecs_1": 8.7566,
    "HYG Star ID_1": 119346,
    "Gliese Catalog_1": "Gl 791.2"
  },
  {
    "Astrid #": "ASTRID 000240",
    "HYG Star ID": 118543,
    "Gliese Catalog": "GJ 2069B",
    "Distance in Parsecs": 8.7719,
    "mag": 13.32,
    "Absolute Visual Magnitude": 13.605,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -5.083298,
    "y": 6.528145,
    "z": 2.913697,
    "vx": 0.00000749,
    "vy": 0.00000795,
    "vz": -0.00000475,
    "rarad": 2.23240149841614,
    "decrad": 0.3385953302714,
    "pmrarad": -0.00000123031167714583,
    "pmdecrad": -5.73694572e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 41706,
    "base": "GJ 2069",
    "lum": 0.000314774831410131,
    "ra": 8.527146,
    "dec": 19.400083,
    "pmra": -253.77,
    "pmdec": -118.33,
    "rv": 0,
    "Asset Name": "ASTRID 000240",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Halo Boy",
    "Outfit": "Sharky",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000749,
    "y_1": 0.00000795,
    "z_1": -0.00000475,
    "Distance in Parsecs_1": 8.7719,
    "HYG Star ID_1": 118543,
    "Gliese Catalog_1": "GJ 2069B"
  },
  {
    "Astrid #": "ASTRID 000241",
    "HYG Star ID": 118756,
    "Gliese Catalog": "NN 3667",
    "Distance in Parsecs": 8.7719,
    "mag": 13.79,
    "Absolute Visual Magnitude": 14.075,
    "Spectral Type": "DC8",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -8.254211,
    "y": 1.113384,
    "z": 2.752202,
    "vx": 0.00001143,
    "vy": 0.00003019,
    "vz": 0.0000221,
    "rarad": 3.00752456402127,
    "decrad": 0.319142042749599,
    "pmrarad": -0.00000358543957454861,
    "pmdecrad": 0.000002653074992,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118756,
    "lum": 0.000204173794466952,
    "ra": 11.487898,
    "dec": 18.285492,
    "pmra": -739.55,
    "pmdec": 547.24,
    "rv": 0,
    "Asset Name": "ASTRID 000241",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Honest Lad",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001143,
    "y_1": 0.00003019,
    "z_1": 0.0000221,
    "Distance in Parsecs_1": 8.7719,
    "HYG Star ID_1": 118756,
    "Gliese Catalog_1": "NN 3667"
  },
  {
    "Astrid #": "ASTRID 000242",
    "HYG Star ID": 119141,
    "Gliese Catalog": "Gl 666B",
    "Distance in Parsecs": 8.7866,
    "mag": 8.69,
    "Absolute Visual Magnitude": 8.971,
    "Spectral Type": "M0 V",
    "Color Index": 1.41,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.072432,
    "y": -5.937897,
    "z": -6.38891,
    "vx": 0.00003931,
    "vy": -0.00003048,
    "vz": -0.0000136,
    "rarad": 4.53359255352379,
    "decrad": -0.813962281137,
    "pmrarad": 0.00000502073047583333,
    "pmdecrad": 8.4017726e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 2,
    "comp_primary": 84462,
    "base": "Gl 666",
    "lum": 0.0224698410364015,
    "ra": 17.317048,
    "dec": -46.636603,
    "pmra": 1035.6,
    "pmdec": 173.3,
    "rv": 25.1,
    "Asset Name": "ASTRID 000242",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Headband",
    "Outfit": "Elven",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00003931,
    "y_1": -0.00003048,
    "z_1": -0.0000136,
    "Distance in Parsecs_1": 8.7866,
    "HYG Star ID_1": 119141,
    "Gliese Catalog_1": "Gl 666B"
  },
  {
    "Astrid #": "ASTRID 000243",
    "HYG Star ID": 118503,
    "Gliese Catalog": "GJ 1103A",
    "Distance in Parsecs": 8.7873,
    "mag": 13.5,
    "Absolute Visual Magnitude": 13.781,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.123605,
    "y": 7.762685,
    "z": -0.000425,
    "vx": -0.00003444,
    "vy": 0.00004193,
    "vz": -0.00003053,
    "rarad": 2.05909809118275,
    "decrad": -0.000048463101,
    "pmrarad": 0.00000122386365519444,
    "pmdecrad": -0.00000347538202,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118503,
    "base": "GJ 1103",
    "lum": 0.000267670185562588,
    "ra": 7.865175,
    "dec": -0.002777,
    "pmra": 252.44,
    "pmdec": -716.85,
    "rv": 52,
    "Asset Name": "ASTRID 000243",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Owie",
    "Hair": "Bucket Hat",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003444,
    "y_1": 0.00004193,
    "z_1": -0.00003053,
    "Distance in Parsecs_1": 8.7873,
    "HYG Star ID_1": 118503,
    "Gliese Catalog_1": "GJ 1103A"
  },
  {
    "Astrid #": "ASTRID 000244",
    "HYG Star ID": 118504,
    "Gliese Catalog": "GJ 1103B",
    "Distance in Parsecs": 8.7873,
    "mag": 15,
    "Absolute Visual Magnitude": 15.281,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.122923,
    "y": 7.760035,
    "z": -0.000173,
    "vx": -0.0000095,
    "vy": -0.00000504,
    "vz": -0.00003054,
    "rarad": 2.05917093918275,
    "decrad": -0.0000196881009999999,
    "pmrarad": 0.00000122386365519444,
    "pmdecrad": -0.00000347538202,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118503,
    "base": "GJ 1103",
    "lum": 0.0000672357107234318,
    "ra": 7.865454,
    "dec": -0.001128,
    "pmra": 252.44,
    "pmdec": -716.85,
    "rv": 0,
    "Asset Name": "ASTRID 000244",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Braided Bun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000095,
    "y_1": -0.00000504,
    "z_1": -0.00003054,
    "Distance in Parsecs_1": 8.7873,
    "HYG Star ID_1": 118504,
    "Gliese Catalog_1": "GJ 1103B"
  },
  {
    "Astrid #": "ASTRID 000245",
    "HYG Star ID": 84462,
    "Hipparcos Catalogue": 84720,
    "Henry Draper Catalogue": 156274,
    "Harvard Revised Catalogue": 6416,
    "Gliese Catalog": "Gl 666A",
    "Distance in Parsecs": 8.802,
    "mag": 5.47,
    "Absolute Visual Magnitude": 5.747,
    "Spectral Type": "M0V",
    "Color Index": 0.764,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.073804,
    "y": -5.947684,
    "z": -6.399284,
    "vx": 0.00004,
    "vy": -0.00002708,
    "vz": -0.0000139,
    "rarad": 4.53375148154424,
    "decrad": -0.813955843756866,
    "pmrarad": 0.00000501903362795138,
    "pmdecrad": 5.29513501e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 84462,
    "base": "Gl 666",
    "lum": 0.437723637934765,
    "ra": 17.317655,
    "dec": -46.636235,
    "pmra": 1035.25,
    "pmdec": 109.22,
    "rv": 23,
    "Asset Name": "ASTRID 000245",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Halo Boy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00004,
    "y_1": -0.00002708,
    "z_1": -0.0000139,
    "Distance in Parsecs_1": 8.802,
    "HYG Star ID_1": 84462,
    "Hipparcos Catalogue_1": 84720,
    "Henry Draper Catalogue_1": 156274,
    "Harvard Revised Catalogue_1": 6416,
    "Gliese Catalog_1": "Gl 666A"
  },
  {
    "Astrid #": "ASTRID 000246",
    "HYG Star ID": 25514,
    "Hipparcos Catalogue": 25578,
    "Gliese Catalog": "Gl 203",
    "Distance in Parsecs": 8.8106,
    "mag": 12.48,
    "Absolute Visual Magnitude": 12.755,
    "Spectral Type": "M5",
    "Color Index": 1.653,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.208775,
    "y": 8.601568,
    "z": 1.475993,
    "vx": 0.00000896,
    "vy": 0.00000423,
    "vz": -0.00003198,
    "rarad": 1.4311810557425,
    "decrad": 0.168318300252422,
    "pmrarad": -9.40829428486111e-7,
    "pmdecrad": -0.000003682256866,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 25514,
    "lum": 0.000688652296344274,
    "var_min": 12.624,
    "var_max": 12.254,
    "ra": 5.466709,
    "dec": 9.643928,
    "pmra": -194.06,
    "pmdec": -759.52,
    "rv": 0,
    "Asset Name": "ASTRID 000246",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Battle Ready",
    "Hair": "Pompadour",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000896,
    "y_1": 0.00000423,
    "z_1": -0.00003198,
    "Distance in Parsecs_1": 8.8106,
    "HYG Star ID_1": 25514,
    "Hipparcos Catalogue_1": 25578,
    "Gliese Catalog_1": "Gl 203"
  },
  {
    "Astrid #": "ASTRID 000247",
    "HYG Star ID": 60373,
    "Hipparcos Catalogue": 60559,
    "Gliese Catalog": "Gl 465",
    "Distance in Parsecs": 8.8511,
    "mag": 11.28,
    "Absolute Visual Magnitude": 11.545,
    "Spectral Type": "M4",
    "Color Index": 1.574,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.357279,
    "y": -0.91066,
    "z": -2.770875,
    "vx": -0.00002011,
    "vy": -0.00004949,
    "vz": -0.0001126,
    "rarad": 3.25012807238984,
    "decrad": -0.318387983063765,
    "pmrarad": 0.00000531307312520833,
    "pmdecrad": -0.000011186978716,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60373,
    "lum": 0.00209893988362352,
    "var_min": 11.377,
    "var_max": 11.207,
    "ra": 12.414575,
    "dec": -18.242288,
    "pmra": 1095.9,
    "pmdec": -2307.48,
    "rv": 58,
    "Asset Name": "ASTRID 000247",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Fire",
    "Outfit": "Mugiwara",
    "Special": "Astral Burst",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002011,
    "y_1": -0.00004949,
    "z_1": -0.0001126,
    "Distance in Parsecs_1": 8.8511,
    "HYG Star ID_1": 60373,
    "Hipparcos Catalogue_1": 60559,
    "Gliese Catalog_1": "Gl 465"
  },
  {
    "Astrid #": "ASTRID 000248",
    "HYG Star ID": 56359,
    "Hipparcos Catalogue": 56528,
    "Gliese Catalog": "Gl 433",
    "Distance in Parsecs": 8.8826,
    "mag": 9.81,
    "Absolute Visual Magnitude": 10.067,
    "Spectral Type": "M2V",
    "Color Index": 1.489,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.445463,
    "y": 0.800648,
    "z": -4.777978,
    "vx": -0.00000405,
    "vy": 0.00000346,
    "vz": -0.00004635,
    "rarad": 3.03446987287938,
    "decrad": -0.56792965176733,
    "pmrarad": -3.38642355868055e-7,
    "pmdecrad": -0.000004133230552,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 56359,
    "lum": 0.00818841791900511,
    "ra": 11.590821,
    "dec": -32.539972,
    "pmra": -69.85,
    "pmdec": -852.54,
    "rv": 28,
    "Asset Name": "ASTRID 000248",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Bucket Hat",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000405,
    "y_1": 0.00000346,
    "z_1": -0.00004635,
    "Distance in Parsecs_1": 8.8826,
    "HYG Star ID_1": 56359,
    "Hipparcos Catalogue_1": 56528,
    "Gliese Catalog_1": "Gl 433"
  },
  {
    "Astrid #": "ASTRID 000249",
    "HYG Star ID": 108361,
    "Hipparcos Catalogue": 108706,
    "Gliese Catalog": "NN 4247",
    "Distance in Parsecs": 8.9023,
    "mag": 11.99,
    "Absolute Visual Magnitude": 12.242,
    "Color Index": 1.718,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 6.808447,
    "y": -3.882732,
    "z": 4.22142,
    "vx": 0.00000731,
    "vy": 0.00001432,
    "vz": 0.00000139,
    "rarad": 5.76490212735163,
    "decrad": 0.494048711525262,
    "pmrarad": 0.00000180404018671527,
    "pmdecrad": 1.7686003e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 108361,
    "lum": 0.00110458718398862,
    "var": "V374",
    "var_min": 12.096,
    "var_max": 11.856,
    "ra": 22.020304,
    "dec": 28.306906,
    "pmra": 372.11,
    "pmdec": 36.48,
    "rv": 0,
    "Asset Name": "ASTRID 000249",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Manbun",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000731,
    "y_1": 0.00001432,
    "z_1": 0.00000139,
    "Distance in Parsecs_1": 8.9023,
    "HYG Star ID_1": 108361,
    "Hipparcos Catalogue_1": 108706,
    "Gliese Catalog_1": "NN 4247"
  },
  {
    "Astrid #": "ASTRID 000250",
    "HYG Star ID": 118557,
    "Gliese Catalog": "Gl 318",
    "Distance in Parsecs": 8.9047,
    "mag": 11.88,
    "Absolute Visual Magnitude": 12.132,
    "Spectral Type": "DA6",
    "Color Index": 0.22,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.84359,
    "y": 5.694145,
    "z": -4.843849,
    "vx": -0.00001825,
    "vy": 0.00009114,
    "vz": 0.00001625,
    "rarad": 2.27570232615282,
    "decrad": -0.57493980543345,
    "pmrarad": -0.00000507415694343055,
    "pmdecrad": 0.000006494651331,
    "con": "Pyx",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pyxis",
    "FULL CONSTELLATION NAME": "Pyxis",
    "comp": 1,
    "comp_primary": 118557,
    "lum": 0.00122236244927873,
    "ra": 8.692543,
    "dec": -32.941624,
    "pmra": -1046.62,
    "pmdec": 1339.62,
    "rv": 58,
    "Asset Name": "ASTRID 000250",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Braided Bun",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pyxis",
    "x_1": -0.00001825,
    "y_1": 0.00009114,
    "z_1": 0.00001625,
    "Distance in Parsecs_1": 8.9047,
    "HYG Star ID_1": 118557,
    "Gliese Catalog_1": "Gl 318"
  },
  {
    "Astrid #": "ASTRID 000251",
    "HYG Star ID": 99507,
    "Hipparcos Catalogue": 99825,
    "Henry Draper Catalogue": 192310,
    "Harvard Revised Catalogue": 7722,
    "Gliese Catalog": "Gl 785",
    "Distance in Parsecs": 8.9111,
    "mag": 5.73,
    "Absolute Visual Magnitude": 5.98,
    "Spectral Type": "K3V",
    "Color Index": 0.878,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.417951,
    "y": -6.593863,
    "z": -4.049903,
    "vx": 0.00001499,
    "vy": 0.00007398,
    "vz": 0.0000183,
    "rarad": 5.30269583655295,
    "decrad": -0.47181445075041,
    "pmrarad": 0.00000601823462357638,
    "pmdecrad": -8.79742904e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 99507,
    "lum": 0.353183169791956,
    "ra": 20.254806,
    "dec": -27.032977,
    "pmra": 1241.35,
    "pmdec": -181.46,
    "rv": -54.4,
    "Asset Name": "ASTRID 000251",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Smitten",
    "Hair": "Horns",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00001499,
    "y_1": 0.00007398,
    "z_1": 0.0000183,
    "Distance in Parsecs_1": 8.9111,
    "HYG Star ID_1": 99507,
    "Hipparcos Catalogue_1": 99825,
    "Henry Draper Catalogue_1": 192310,
    "Harvard Revised Catalogue_1": 7722,
    "Gliese Catalog_1": "Gl 785"
  },
  {
    "Astrid #": "ASTRID 000252",
    "HYG Star ID": 55195,
    "Hipparcos Catalogue": 55360,
    "Gliese Catalog": "Gl 424",
    "Distance in Parsecs": 8.9182,
    "mag": 9.31,
    "Absolute Visual Magnitude": 9.559,
    "Spectral Type": "M1Vvar",
    "Color Index": 1.412,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.594174,
    "y": 0.632449,
    "z": 8.137924,
    "vx": 0.00000448,
    "vy": 0.00012856,
    "vz": 0.00005926,
    "rarad": 2.96759134845865,
    "decrad": 1.14923800455927,
    "pmrarad": -0.0000142860047249305,
    "pmdecrad": 8.94578203e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 55195,
    "lum": 0.0130737447016313,
    "var": "SZ",
    "var_min": 9.359,
    "var_max": 9.259,
    "ra": 11.335364,
    "dec": 65.846487,
    "pmra": -2946.7,
    "pmdec": 184.52,
    "rv": 60,
    "Asset Name": "ASTRID 000252",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Spiky",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000448,
    "y_1": 0.00012856,
    "z_1": 0.00005926,
    "Distance in Parsecs_1": 8.9182,
    "HYG Star ID_1": 55195,
    "Hipparcos Catalogue_1": 55360,
    "Gliese Catalog_1": "Gl 424"
  },
  {
    "Astrid #": "ASTRID 000253",
    "HYG Star ID": 93930,
    "Hipparcos Catalogue": 94223,
    "Distance in Parsecs": 8.919,
    "mag": 10.28,
    "Absolute Visual Magnitude": 10.528,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 1.81341,
    "y": -5.676232,
    "z": -6.636301,
    "vx": -0.0000239,
    "vy": 0.00000103,
    "vz": -0.00000741,
    "rarad": 5.021625842945,
    "decrad": -0.839131830612828,
    "pmrarad": -0.00000251749199902083,
    "pmdecrad": -0.000001243498609,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 93930,
    "lum": 0.00535549970840113,
    "var_min": 10.544,
    "var_max": 9.904,
    "ra": 19.181198,
    "dec": -48.078712,
    "pmra": -519.27,
    "pmdec": -256.49,
    "rv": 0,
    "Asset Name": "ASTRID 000253",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Bandana",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.0000239,
    "y_1": 0.00000103,
    "z_1": -0.00000741,
    "Distance in Parsecs_1": 8.919,
    "HYG Star ID_1": 93930,
    "Hipparcos Catalogue_1": 94223
  },
  {
    "Astrid #": "ASTRID 000254",
    "HYG Star ID": 27006,
    "Hipparcos Catalogue": 27072,
    "Henry Draper Catalogue": 38393,
    "Harvard Revised Catalogue": 1983,
    "Gliese Catalog": "Gl 216A",
    "Distance in Parsecs": 8.927,
    "mag": 3.59,
    "Absolute Visual Magnitude": 3.836,
    "Spectral Type": "F7V",
    "Color Index": 0.481,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.558889,
    "y": 8.231515,
    "z": -3.40875,
    "vx": 0.00001159,
    "vy": -0.00001608,
    "vz": -0.00001095,
    "rarad": 1.50300514719355,
    "decrad": -0.391798180090052,
    "pmrarad": -0.00000141769216468055,
    "pmdecrad": -0.000001786296006,
    "bayer": "Gam",
    "flam": 13,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 27006,
    "base": "Gl 216",
    "lum": 2.54448561514663,
    "bf": "13Gam Lep",
    "ra": 5.741057,
    "dec": -22.448382,
    "pmra": -292.42,
    "pmdec": -368.45,
    "rv": -9.7,
    "Asset Name": "ASTRID 000254",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Happy Vamp",
    "Hair": "Elegant",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00001159,
    "y_1": -0.00001608,
    "z_1": -0.00001095,
    "Distance in Parsecs_1": 8.927,
    "HYG Star ID_1": 27006,
    "Hipparcos Catalogue_1": 27072,
    "Henry Draper Catalogue_1": 38393,
    "Harvard Revised Catalogue_1": 1983,
    "Gliese Catalog_1": "Gl 216A"
  },
  {
    "Astrid #": "ASTRID 000255",
    "HYG Star ID": 118104,
    "Gliese Catalog": "NN 3128",
    "Distance in Parsecs": 8.9286,
    "mag": 15.61,
    "Absolute Visual Magnitude": 15.856,
    "Spectral Type": "m",
    "Color Index": 2.02,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.563603,
    "y": 4.465963,
    "z": 1.602182,
    "vx": 0.00001622,
    "vy": -0.00002312,
    "vz": -0.0000121,
    "rarad": 0.533373662261578,
    "decrad": 0.1804210445829,
    "pmrarad": -0.00000315429476843055,
    "pmdecrad": -0.000001378068342,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118104,
    "lum": 0.0000395913216728166,
    "ra": 2.037337,
    "dec": 10.337364,
    "pmra": -650.62,
    "pmdec": -284.25,
    "rv": 0,
    "Asset Name": "ASTRID 000255",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Ponytail",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001622,
    "y_1": -0.00002312,
    "z_1": -0.0000121,
    "Distance in Parsecs_1": 8.9286,
    "HYG Star ID_1": 118104,
    "Gliese Catalog_1": "NN 3128"
  },
  {
    "Astrid #": "ASTRID 000256",
    "HYG Star ID": 118495,
    "Gliese Catalog": "Gl 283A",
    "Distance in Parsecs": 8.9286,
    "mag": 13,
    "Absolute Visual Magnitude": 13.246,
    "Spectral Type": "DZQ6",
    "Color Index": 0.24,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.612268,
    "y": 7.716286,
    "z": -2.672154,
    "vx": -0.0000453,
    "vy": -0.00001734,
    "vz": -0.00002648,
    "rarad": 2.00861602970131,
    "decrad": -0.303918556623649,
    "pmrarad": 0.00000541871402620138,
    "pmdecrad": -0.000002713492473,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118495,
    "base": "Gl 283",
    "lum": 0.000438126982022492,
    "ra": 7.672348,
    "dec": -17.413251,
    "pmra": 1117.69,
    "pmdec": -559.7,
    "rv": 11,
    "Asset Name": "ASTRID 000256",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "Fedora",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000453,
    "y_1": -0.00001734,
    "z_1": -0.00002648,
    "Distance in Parsecs_1": 8.9286,
    "HYG Star ID_1": 118495,
    "Gliese Catalog_1": "Gl 283A"
  },
  {
    "Astrid #": "ASTRID 000257",
    "HYG Star ID": 118496,
    "Gliese Catalog": "Gl 283B",
    "Distance in Parsecs": 8.9286,
    "mag": 16.42,
    "Absolute Visual Magnitude": 16.666,
    "Spectral Type": "m",
    "Color Index": 1.83,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.612041,
    "y": 7.7158,
    "z": -2.671986,
    "vx": -0.00004075,
    "vy": -0.00002706,
    "vz": -0.00002312,
    "rarad": 2.00861602970131,
    "decrad": -0.303918556623649,
    "pmrarad": 0.00000541871402620138,
    "pmdecrad": -0.000002713492473,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118495,
    "base": "Gl 283",
    "lum": 0.0000187758669891934,
    "ra": 7.672348,
    "dec": -17.413251,
    "pmra": 1117.69,
    "pmdec": -559.7,
    "rv": 0,
    "Asset Name": "ASTRID 000257",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "High Bun",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004075,
    "y_1": -0.00002706,
    "z_1": -0.00002312,
    "Distance in Parsecs_1": 8.9286,
    "HYG Star ID_1": 118496,
    "Gliese Catalog_1": "Gl 283B"
  },
  {
    "Astrid #": "ASTRID 000258",
    "HYG Star ID": 118800,
    "Gliese Catalog": "NN 3707",
    "Distance in Parsecs": 8.9286,
    "mag": 12.06,
    "Absolute Visual Magnitude": 12.306,
    "Spectral Type": "m",
    "Color Index": 1.58,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.613129,
    "y": -0.37923,
    "z": -2.321657,
    "vx": 0.00000798,
    "vy": 0.00000253,
    "vz": -0.00003002,
    "rarad": 3.18559390732848,
    "decrad": -0.2630478957434,
    "pmrarad": -2.43473430375e-7,
    "pmdecrad": -0.000003482154868,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118800,
    "lum": 0.00104135786152843,
    "ra": 12.168072,
    "dec": -15.071534,
    "pmra": -50.22,
    "pmdec": -718.25,
    "rv": 0,
    "Asset Name": "ASTRID 000258",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Knocked Out",
    "Hair": "Wavy",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000798,
    "y_1": 0.00000253,
    "z_1": -0.00003002,
    "Distance in Parsecs_1": 8.9286,
    "HYG Star ID_1": 118800,
    "Gliese Catalog_1": "NN 3707"
  },
  {
    "Astrid #": "ASTRID 000259",
    "HYG Star ID": 118938,
    "Gliese Catalog": "NN 3820",
    "Distance in Parsecs": 8.9286,
    "mag": 13,
    "Absolute Visual Magnitude": 13.246,
    "Spectral Type": "K2 :",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.288764,
    "y": -4.173097,
    "z": -3.029698,
    "vx": -0.00000949,
    "vy": 0.000022,
    "vz": -0.00000749,
    "rarad": 3.66158329096564,
    "decrad": -0.346199370585249,
    "pmrarad": -0.00000266662068715972,
    "pmdecrad": -8.92231705e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118938,
    "lum": 0.000438126982022492,
    "ra": 13.986218,
    "dec": -19.835763,
    "pmra": -550.03,
    "pmdec": -184.04,
    "rv": 0,
    "Asset Name": "ASTRID 000259",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Undercut",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000949,
    "y_1": 0.000022,
    "z_1": -0.00000749,
    "Distance in Parsecs_1": 8.9286,
    "HYG Star ID_1": 118938,
    "Gliese Catalog_1": "NN 3820"
  },
  {
    "Astrid #": "ASTRID 000260",
    "HYG Star ID": 118392,
    "Henry Draper Catalogue": 38392,
    "Harvard Revised Catalogue": 1982,
    "Gliese Catalog": "Gl 216B",
    "Distance in Parsecs": 8.9694,
    "mag": 6.13,
    "Absolute Visual Magnitude": 6.366,
    "Spectral Type": "K2 V",
    "Color Index": 0.94,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.562689,
    "y": 8.271905,
    "z": -3.420733,
    "vx": 0.00001236,
    "vy": -0.00001472,
    "vz": -0.00001105,
    "rarad": 1.50288287272752,
    "decrad": -0.391306280132499,
    "pmrarad": -0.00000148590544953472,
    "pmdecrad": -0.00000172750265,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 2,
    "comp_primary": 27006,
    "base": "Gl 216",
    "lum": 0.247514131807431,
    "ra": 5.74059,
    "dec": -22.420198,
    "pmra": -306.49,
    "pmdec": -356.32,
    "rv": -8.4,
    "Asset Name": "ASTRID 000260",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Braided Bun",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00001236,
    "y_1": -0.00001472,
    "z_1": -0.00001105,
    "Distance in Parsecs_1": 8.9694,
    "HYG Star ID_1": 118392,
    "Henry Draper Catalogue_1": 38392,
    "Harvard Revised Catalogue_1": 1982,
    "Gliese Catalog_1": "Gl 216B"
  },
  {
    "Astrid #": "ASTRID 000261",
    "HYG Star ID": 119591,
    "Gliese Catalog": "NN 4360",
    "Distance in Parsecs": 9.009,
    "mag": 14.5,
    "Absolute Visual Magnitude": 14.727,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.635281,
    "y": -0.545965,
    "z": -2.509168,
    "vx": -0.00000782,
    "vy": -0.0000167,
    "vz": -0.00002328,
    "rarad": 6.22004831765618,
    "decrad": -0.28225059825135,
    "pmrarad": -0.000001905026876375,
    "pmdecrad": -0.000002690585027,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119591,
    "lum": 0.000111995352238488,
    "ra": 23.758834,
    "dec": -16.171768,
    "pmra": -392.94,
    "pmdec": -554.97,
    "rv": 0,
    "Asset Name": "ASTRID 000261",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Innocent",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000782,
    "y_1": -0.0000167,
    "z_1": -0.00002328,
    "Distance in Parsecs_1": 9.009,
    "HYG Star ID_1": 119591,
    "Gliese Catalog_1": "NN 4360"
  },
  {
    "Astrid #": "ASTRID 000262",
    "HYG Star ID": 118037,
    "Gliese Catalog": "Gl 51",
    "Distance in Parsecs": 9.0171,
    "mag": 13.66,
    "Absolute Visual Magnitude": 13.885,
    "Spectral Type": "M5",
    "Color Index": 1.68,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.02358,
    "y": 1.14093,
    "z": 7.988565,
    "vx": -0.000012,
    "vy": 0.00002903,
    "vz": 0.00000191,
    "rarad": 0.276107748698863,
    "decrad": 1.08850634291,
    "pmrarad": 0.00000346079397727777,
    "pmdecrad": 4.556182e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118037,
    "lum": 0.000243220400907381,
    "ra": 1.054654,
    "dec": 62.366819,
    "pmra": 713.84,
    "pmdec": 93.98,
    "rv": 0,
    "Asset Name": "ASTRID 000262",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Pompadour",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.000012,
    "y_1": 0.00002903,
    "z_1": 0.00000191,
    "Distance in Parsecs_1": 9.0171,
    "HYG Star ID_1": 118037,
    "Gliese Catalog_1": "Gl 51"
  },
  {
    "Astrid #": "ASTRID 000263",
    "HYG Star ID": 31215,
    "Hipparcos Catalogue": 31293,
    "Distance in Parsecs": 9.0188,
    "mag": 10.35,
    "Absolute Visual Magnitude": 10.574,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.328154,
    "y": 2.214126,
    "z": -8.736631,
    "vx": 0.00001083,
    "vy": 0.00001353,
    "vz": 0.00000302,
    "rarad": 1.71797204703595,
    "decrad": -1.31999234684819,
    "pmrarad": -0.00000140799589106944,
    "pmdecrad": 0.000001350303063,
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 31215,
    "lum": 0.00513333964380289,
    "var_min": 10.432,
    "var_max": 10.162,
    "ra": 6.56217,
    "dec": -75.62999,
    "pmra": -290.42,
    "pmdec": 278.52,
    "rv": 0,
    "Asset Name": "ASTRID 000263",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Smitten",
    "Hair": "Undercut",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": 0.00001083,
    "y_1": 0.00001353,
    "z_1": 0.00000302,
    "Distance in Parsecs_1": 9.0188,
    "HYG Star ID_1": 31215,
    "Hipparcos Catalogue_1": 31293
  },
  {
    "Astrid #": "ASTRID 000264",
    "HYG Star ID": 46970,
    "Hipparcos Catalogue": 47103,
    "Gliese Catalog": "Gl 357",
    "Distance in Parsecs": 9.0236,
    "mag": 10.91,
    "Absolute Visual Magnitude": 11.133,
    "Spectral Type": "K",
    "Color Index": 1.572,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.784803,
    "y": 4.928214,
    "z": -3.330451,
    "vx": 0.00006939,
    "vy": -0.00005782,
    "vz": -0.00001079,
    "rarad": 2.51339266691559,
    "decrad": -0.378052233471322,
    "pmrarad": 6.65018925618055e-7,
    "pmdecrad": -0.000004797425294,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46970,
    "lum": 0.00306760897691793,
    "var_min": 10.976,
    "var_max": 10.846,
    "ra": 9.600453,
    "dec": -21.660797,
    "pmra": 137.17,
    "pmdec": -989.54,
    "rv": -78,
    "Asset Name": "ASTRID 000264",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Frizzy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00006939,
    "y_1": -0.00005782,
    "z_1": -0.00001079,
    "Distance in Parsecs_1": 9.0236,
    "HYG Star ID_1": 46970,
    "Hipparcos Catalogue_1": 47103,
    "Gliese Catalog_1": "Gl 357"
  },
  {
    "Astrid #": "ASTRID 000265",
    "HYG Star ID": 113815,
    "Hipparcos Catalogue": 114176,
    "Distance in Parsecs": 9.0318,
    "mag": 12.28,
    "Absolute Visual Magnitude": 12.501,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.436065,
    "y": -1.739907,
    "z": -4.821936,
    "vx": -0.00000292,
    "vy": -0.00000937,
    "vz": -0.00000113,
    "rarad": 6.05333978646876,
    "decrad": -0.563187605725443,
    "pmrarad": -0.00000108394642698611,
    "pmdecrad": -1.47625765e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 113815,
    "lum": 0.000870161772151997,
    "var_min": 12.925,
    "var_max": 10.625,
    "ra": 23.122055,
    "dec": -32.268273,
    "pmra": -223.58,
    "pmdec": -30.45,
    "rv": 0,
    "Asset Name": "ASTRID 000265",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Ninja",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000292,
    "y_1": -0.00000937,
    "z_1": -0.00000113,
    "Distance in Parsecs_1": 9.0318,
    "HYG Star ID_1": 113815,
    "Hipparcos Catalogue_1": 114176
  },
  {
    "Astrid #": "ASTRID 000266",
    "HYG Star ID": 17336,
    "Hipparcos Catalogue": 17378,
    "Henry Draper Catalogue": 23249,
    "Harvard Revised Catalogue": 1136,
    "Gliese Catalog": "Gl 150",
    "Distance in Parsecs": 9.0408,
    "mag": 3.52,
    "Absolute Visual Magnitude": 3.739,
    "Spectral Type": "K0IV",
    "Color Index": 0.915,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.006502,
    "y": 7.370189,
    "z": -1.533131,
    "vx": 0.00000354,
    "vy": -0.00000195,
    "vz": 0.00003294,
    "rarad": 0.974104801552018,
    "decrad": -0.170403381714916,
    "pmrarad": -4.446226264375e-7,
    "pmdecrad": 0.000003598432581,
    "bayer": "Del",
    "flam": 23,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17336,
    "lum": 2.78227465767512,
    "var": "Del",
    "var_min": 3.531,
    "var_max": 3.511,
    "bf": "23Del Eri",
    "ra": 3.720806,
    "dec": -9.763395,
    "pmra": -91.71,
    "pmdec": 742.23,
    "rv": -5.1,
    "Asset Name": "ASTRID 000266",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "Honest Lad",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000354,
    "y_1": -0.00000195,
    "z_1": 0.00003294,
    "Distance in Parsecs_1": 9.0408,
    "HYG Star ID_1": 17336,
    "Hipparcos Catalogue_1": 17378,
    "Henry Draper Catalogue_1": 23249,
    "Harvard Revised Catalogue_1": 1136,
    "Gliese Catalog_1": "Gl 150"
  },
  {
    "Astrid #": "ASTRID 000267",
    "HYG Star ID": 57767,
    "Hipparcos Catalogue": 57939,
    "Henry Draper Catalogue": 103095,
    "Harvard Revised Catalogue": 4550,
    "Gliese Catalog": "Gl 451A",
    "Proper Name": "Groombridge 1830",
    "Distance in Parsecs": 9.0917,
    "mag": 6.42,
    "Absolute Visual Magnitude": 6.627,
    "Spectral Type": "G8Vp",
    "Color Index": 0.754,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.187679,
    "y": 0.220247,
    "z": 5.561623,
    "vx": -0.00008204,
    "vy": -0.00017401,
    "vz": -0.00026465,
    "rarad": 3.11091498332241,
    "decrad": 0.658315144581562,
    "pmrarad": 0.0000194104368470347,
    "pmdecrad": -0.00002818221925,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 57767,
    "base": "Gl 451",
    "lum": 0.194625615936124,
    "var": "CF",
    "var_min": 6.546,
    "var_max": 6.406,
    "ra": 11.88282,
    "dec": 37.718679,
    "pmra": 4003.69,
    "pmdec": -5813,
    "rv": -99.1,
    "Asset Name": "ASTRID 000267",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Neko Dreams",
    "Hair": "Neko",
    "Outfit": "Sailor",
    "Special": "Neko Tail",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Groombridge 1830",
    "Constellation": "Ursa Major",
    "x_1": -0.00008204,
    "y_1": -0.00017401,
    "z_1": -0.00026465,
    "Distance in Parsecs_1": 9.0917,
    "HYG Star ID_1": 57767,
    "Hipparcos Catalogue_1": 57939,
    "Henry Draper Catalogue_1": 103095,
    "Harvard Revised Catalogue_1": 4550,
    "Gliese Catalog_1": "Gl 451A"
  },
  {
    "Astrid #": "ASTRID 000268",
    "HYG Star ID": 109042,
    "Hipparcos Catalogue": 109388,
    "Gliese Catalog": "Gl 849",
    "Distance in Parsecs": 9.0959,
    "mag": 10.41,
    "Absolute Visual Magnitude": 10.616,
    "Spectral Type": "M3",
    "Color Index": 1.531,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.035603,
    "y": -4.197679,
    "z": -0.735916,
    "vx": 0.00000974,
    "vy": 0.00005137,
    "vz": 3.6e-7,
    "rarad": 5.80179031481653,
    "decrad": -0.08099599912179,
    "pmrarad": 0.000005502150460625,
    "pmdecrad": -9.5556776e-8,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 109042,
    "lum": 0.00493855619617607,
    "ra": 22.161207,
    "dec": -4.640729,
    "pmra": 1134.9,
    "pmdec": -19.71,
    "rv": -14.8,
    "Asset Name": "ASTRID 000268",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Fedora",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000974,
    "y_1": 0.00005137,
    "z_1": 3.6e-7,
    "Distance in Parsecs_1": 9.0959,
    "HYG Star ID_1": 109042,
    "Hipparcos Catalogue_1": 109388,
    "Gliese Catalog_1": "Gl 849"
  },
  {
    "Astrid #": "ASTRID 000269",
    "HYG Star ID": 40386,
    "Hipparcos Catalogue": 40501,
    "Gliese Catalog": "GJ 2066",
    "Distance in Parsecs": 9.1224,
    "mag": 10.08,
    "Absolute Visual Magnitude": 10.279,
    "Color Index": 1.51,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -5.104251,
    "y": 7.557897,
    "z": 0.207372,
    "vx": 0.00001378,
    "vy": 0.00000923,
    "vz": 0.00000266,
    "rarad": 2.16478873862689,
    "decrad": 0.0227341889381512,
    "pmrarad": -0.00000181809978345138,
    "pmdecrad": 2.91421503e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 40386,
    "lum": 0.00673596776222942,
    "ra": 8.268884,
    "dec": 1.302573,
    "pmra": -375.01,
    "pmdec": 60.11,
    "rv": 0,
    "Asset Name": "ASTRID 000269",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Mohawk",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001378,
    "y_1": 0.00000923,
    "z_1": 0.00000266,
    "Distance in Parsecs_1": 9.1224,
    "HYG Star ID_1": 40386,
    "Hipparcos Catalogue_1": 40501,
    "Gliese Catalog_1": "GJ 2066"
  },
  {
    "Astrid #": "ASTRID 000270",
    "HYG Star ID": 64189,
    "Hipparcos Catalogue": 64394,
    "Henry Draper Catalogue": 114710,
    "Harvard Revised Catalogue": 4983,
    "Gliese Catalog": "Gl 502",
    "Distance in Parsecs": 9.1291,
    "mag": 4.23,
    "Absolute Visual Magnitude": 4.428,
    "Spectral Type": "G0V",
    "Color Index": 0.572,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.676072,
    "y": -2.489414,
    "z": 4.268726,
    "vx": 0.00000204,
    "vy": 0.00003797,
    "vz": 0.00003697,
    "rarad": 3.45520321574523,
    "decrad": 0.486566084190632,
    "pmrarad": -0.00000388791482984722,
    "pmdecrad": 0.000004279450358,
    "bayer": "Bet",
    "flam": 43,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 64189,
    "lum": 1.47502710175975,
    "bf": "43Bet Com",
    "ra": 13.197904,
    "dec": 27.878183,
    "pmra": -801.94,
    "pmdec": 882.7,
    "rv": 5.1,
    "Asset Name": "ASTRID 000270",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Manbun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00000204,
    "y_1": 0.00003797,
    "z_1": 0.00003697,
    "Distance in Parsecs_1": 9.1291,
    "HYG Star ID_1": 64189,
    "Hipparcos Catalogue_1": 64394,
    "Henry Draper Catalogue_1": 114710,
    "Harvard Revised Catalogue_1": 4983,
    "Gliese Catalog_1": "Gl 502"
  },
  {
    "Astrid #": "ASTRID 000271",
    "HYG Star ID": 15419,
    "Hipparcos Catalogue": 15457,
    "Henry Draper Catalogue": 20630,
    "Harvard Revised Catalogue": 996,
    "Gliese Catalog": "Gl 137",
    "Distance in Parsecs": 9.1399,
    "mag": 4.84,
    "Absolute Visual Magnitude": 5.035,
    "Spectral Type": "G5Vvar",
    "Color Index": 0.681,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.88441,
    "y": 6.973226,
    "z": 0.537318,
    "vx": 0.00000318,
    "vy": 0.00002224,
    "vz": 0.00000527,
    "rarad": 0.869879059211462,
    "decrad": 0.0588210486545562,
    "pmrarad": 0.00000130356702427777,
    "pmdecrad": 4.53446235e-7,
    "bayer": "Kap-1",
    "flam": 96,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 15419,
    "lum": 0.843334757764275,
    "var": "Kap-1",
    "var_min": 4.864,
    "var_max": 4.824,
    "bf": "96Kap1Cet",
    "ra": 3.322693,
    "dec": 3.370198,
    "pmra": 268.88,
    "pmdec": 93.53,
    "rv": 18.9,
    "Asset Name": "ASTRID 000271",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Wavy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000318,
    "y_1": 0.00002224,
    "z_1": 0.00000527,
    "Distance in Parsecs_1": 9.1399,
    "HYG Star ID_1": 15419,
    "Hipparcos Catalogue_1": 15457,
    "Henry Draper Catalogue_1": 20630,
    "Harvard Revised Catalogue_1": 996,
    "Gliese Catalog_1": "Gl 137"
  },
  {
    "Astrid #": "ASTRID 000272",
    "HYG Star ID": 9702,
    "Hipparcos Catalogue": 9724,
    "Gliese Catalog": "Gl 84",
    "Distance in Parsecs": 9.1424,
    "mag": 10.19,
    "Absolute Visual Magnitude": 10.385,
    "Spectral Type": "M3",
    "Color Index": 1.514,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.447634,
    "y": 4.522928,
    "z": -2.766518,
    "vx": -0.00006146,
    "vy": 0.00003099,
    "vz": 0.00000348,
    "rarad": 0.545765113199747,
    "decrad": -0.307433320743006,
    "pmrarad": 0.00000638756568542361,
    "pmdecrad": -8.43284915e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 9702,
    "lum": 0.00610942024905572,
    "ra": 2.084669,
    "dec": -17.614632,
    "pmra": 1317.53,
    "pmdec": -173.94,
    "rv": -35,
    "Asset Name": "ASTRID 000272",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Curly",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00006146,
    "y_1": 0.00003099,
    "z_1": 0.00000348,
    "Distance in Parsecs_1": 9.1424,
    "HYG Star ID_1": 9702,
    "Hipparcos Catalogue_1": 9724,
    "Gliese Catalog_1": "Gl 84"
  },
  {
    "Astrid #": "ASTRID 000273",
    "HYG Star ID": 118779,
    "Gliese Catalog": "Gl 451B",
    "Distance in Parsecs": 9.1567,
    "mag": 12,
    "Absolute Visual Magnitude": 12.191,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.239693,
    "y": 0.221836,
    "z": 5.602057,
    "vx": -0.00016346,
    "vy": -0.00017258,
    "vz": -0.00020354,
    "rarad": 3.11070430430368,
    "decrad": 0.65833154719875,
    "pmrarad": 0.0000194085460736805,
    "pmdecrad": -0.000028134276025,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 57767,
    "base": "Gl 451",
    "lum": 0.00115771057411526,
    "ra": 11.882015,
    "dec": 37.719619,
    "pmra": 4003.3,
    "pmdec": -5803.11,
    "rv": 0,
    "Asset Name": "ASTRID 000273",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00016346,
    "y_1": -0.00017258,
    "z_1": -0.00020354,
    "Distance in Parsecs_1": 9.1567,
    "HYG Star ID_1": 118779,
    "Gliese Catalog_1": "Gl 451B"
  },
  {
    "Astrid #": "ASTRID 000274",
    "HYG Star ID": 118459,
    "Gliese Catalog": "NN 3421",
    "Distance in Parsecs": 9.1659,
    "mag": 13.3,
    "Absolute Visual Magnitude": 13.489,
    "Spectral Type": "M4.5",
    "Color Index": 1.84,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.529443,
    "y": 5.338769,
    "z": 7.291918,
    "vx": -0.00003957,
    "vy": 0.00002215,
    "vz": -0.0000245,
    "rarad": 1.84968906397374,
    "decrad": 0.91991228635705,
    "pmrarad": 0.00000348551947498611,
    "pmdecrad": -0.000004413452859,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118459,
    "lum": 0.000350267626913812,
    "ra": 7.065292,
    "dec": 52.707092,
    "pmra": 718.94,
    "pmdec": -910.34,
    "rv": 0,
    "Asset Name": "ASTRID 000274",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Sweetheart",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003957,
    "y_1": 0.00002215,
    "z_1": -0.0000245,
    "Distance in Parsecs_1": 9.1659,
    "HYG Star ID_1": 118459,
    "Gliese Catalog_1": "NN 3421"
  },
  {
    "Astrid #": "ASTRID 000275",
    "HYG Star ID": 23457,
    "Hipparcos Catalogue": 23512,
    "Gliese Catalog": "NN 3325",
    "Distance in Parsecs": 9.2073,
    "mag": 11.71,
    "Absolute Visual Magnitude": 11.889,
    "Spectral Type": "K:",
    "Color Index": 1.661,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.150567,
    "y": 8.520023,
    "z": -2.749301,
    "vx": 0.00000833,
    "vy": -0.00000826,
    "vz": -0.00001908,
    "rarad": 1.32354803520416,
    "decrad": -0.30322550060573,
    "pmrarad": -0.00000109689095225694,
    "pmdecrad": -0.000002171383512,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 23457,
    "lum": 0.00152897364669071,
    "var_min": 11.798,
    "var_max": 11.628,
    "ra": 5.055581,
    "dec": -17.373541,
    "pmra": -226.25,
    "pmdec": -447.88,
    "rv": 0,
    "Asset Name": "ASTRID 000275",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Unkempt",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000833,
    "y_1": -0.00000826,
    "z_1": -0.00001908,
    "Distance in Parsecs_1": 9.2073,
    "HYG Star ID_1": 23457,
    "Hipparcos Catalogue_1": 23512,
    "Gliese Catalog_1": "NN 3325"
  },
  {
    "Astrid #": "ASTRID 000276",
    "HYG Star ID": 57270,
    "Hipparcos Catalogue": 57443,
    "Henry Draper Catalogue": 102365,
    "Harvard Revised Catalogue": 4523,
    "Gliese Catalog": "Gl 442A",
    "Distance in Parsecs": 9.2208,
    "mag": 4.89,
    "Absolute Visual Magnitude": 5.066,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.664,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.99949,
    "y": 0.412234,
    "z": -5.988563,
    "vx": -0.00001955,
    "vy": 0.00006973,
    "vz": 0.00000355,
    "rarad": 3.08278621587669,
    "decrad": -0.706864524864905,
    "pmrarad": -0.00000742584266370138,
    "pmdecrad": 0.000001955786868,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 57270,
    "base": "Gl 442",
    "lum": 0.819596320481845,
    "ra": 11.775376,
    "dec": -40.500354,
    "pmra": -1531.69,
    "pmdec": 403.41,
    "rv": 15.3,
    "Asset Name": "ASTRID 000276",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Lion Mane",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00001955,
    "y_1": 0.00006973,
    "z_1": 0.00000355,
    "Distance in Parsecs_1": 9.2208,
    "HYG Star ID_1": 57270,
    "Hipparcos Catalogue_1": 57443,
    "Henry Draper Catalogue_1": 102365,
    "Harvard Revised Catalogue_1": 4523,
    "Gliese Catalog_1": "Gl 442A"
  },
  {
    "Astrid #": "ASTRID 000277",
    "HYG Star ID": 118771,
    "Gliese Catalog": "Gl 442B",
    "Distance in Parsecs": 9.2396,
    "mag": 15,
    "Absolute Visual Magnitude": 15.172,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.014188,
    "y": 0.412626,
    "z": -6.000094,
    "vx": -0.00000747,
    "vy": 0.00006953,
    "vz": 0.00001347,
    "rarad": 3.08295044134563,
    "decrad": -0.70678069545685,
    "pmrarad": -0.00000746637308739583,
    "pmdecrad": 0.000001917030863,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 57270,
    "base": "Gl 442",
    "lum": 0.0000743361389655177,
    "ra": 11.776003,
    "dec": -40.495551,
    "pmra": -1540.05,
    "pmdec": 395.42,
    "rv": 0,
    "Asset Name": "ASTRID 000277",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Undercut",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000747,
    "y_1": 0.00006953,
    "z_1": 0.00001347,
    "Distance in Parsecs_1": 9.2396,
    "HYG Star ID_1": 118771,
    "Gliese Catalog_1": "Gl 442B"
  },
  {
    "Astrid #": "ASTRID 000278",
    "HYG Star ID": 105519,
    "Hipparcos Catalogue": 105858,
    "Henry Draper Catalogue": 203608,
    "Harvard Revised Catalogue": 8181,
    "Gliese Catalog": "Gl 827",
    "Distance in Parsecs": 9.2618,
    "mag": 4.21,
    "Absolute Visual Magnitude": 4.377,
    "Spectral Type": "F6V",
    "Color Index": 0.494,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.025799,
    "y": -2.397286,
    "z": -8.418648,
    "vx": 0.00001805,
    "vy": -0.00000966,
    "vz": 0.00004232,
    "rarad": 5.61316353751065,
    "decrad": -1.14085537819595,
    "pmrarad": 3.93086932194444e-7,
    "pmdecrad": 0.000003881806177,
    "bayer": "Gam",
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 105519,
    "lum": 1.54596621938667,
    "bf": "Gam Pav",
    "ra": 21.440705,
    "dec": -65.366198,
    "pmra": 81.08,
    "pmdec": 800.68,
    "rv": -29.4,
    "Asset Name": "ASTRID 000278",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Lion Mane",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00001805,
    "y_1": -0.00000966,
    "z_1": 0.00004232,
    "Distance in Parsecs_1": 9.2618,
    "HYG Star ID_1": 105519,
    "Hipparcos Catalogue_1": 105858,
    "Henry Draper Catalogue_1": 203608,
    "Harvard Revised Catalogue_1": 8181,
    "Gliese Catalog_1": "Gl 827"
  },
  {
    "Astrid #": "ASTRID 000279",
    "HYG Star ID": 23877,
    "Hipparcos Catalogue": 23932,
    "Gliese Catalog": "Gl 190",
    "Distance in Parsecs": 9.2721,
    "mag": 10.28,
    "Absolute Visual Magnitude": 10.444,
    "Spectral Type": "M5",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.95987,
    "y": 8.588938,
    "z": -2.891723,
    "vx": -0.00002493,
    "vy": -0.00000746,
    "vz": -0.00006203,
    "rarad": 1.34645097167801,
    "decrad": -0.317162073887471,
    "pmrarad": 0.00000244224891579861,
    "pmdecrad": -0.000006788215711,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 23877,
    "lum": 0.00578628738817951,
    "var_min": 10.338,
    "var_max": 10.228,
    "ra": 5.143064,
    "dec": -18.172048,
    "pmra": 503.75,
    "pmdec": -1400.17,
    "rv": 7,
    "Asset Name": "ASTRID 000279",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Big Laugh",
    "Hair": "Fedora",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.00002493,
    "y_1": -0.00000746,
    "z_1": -0.00006203,
    "Distance in Parsecs_1": 9.2721,
    "HYG Star ID_1": 23877,
    "Hipparcos Catalogue_1": 23932,
    "Gliese Catalog_1": "Gl 190"
  },
  {
    "Astrid #": "ASTRID 000280",
    "HYG Star ID": 21879,
    "Hipparcos Catalogue": 21932,
    "Henry Draper Catalogue": 285968,
    "Gliese Catalog": "Gl 176",
    "Distance in Parsecs": 9.2739,
    "mag": 9.95,
    "Absolute Visual Magnitude": 10.114,
    "Spectral Type": "M2",
    "Color Index": 1.523,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.894283,
    "y": 8.279782,
    "z": 3.012961,
    "vx": -0.0000142,
    "vy": 0.00004927,
    "vz": -0.00003862,
    "rarad": 1.23451171677844,
    "decrad": 0.330882510243233,
    "pmrarad": 0.00000319894610840972,
    "pmdecrad": -0.000005404315059,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 21879,
    "lum": 0.00784151540528121,
    "ra": 4.715487,
    "dec": 18.958171,
    "pmra": 659.83,
    "pmdec": -1114.72,
    "rv": 26.4,
    "Asset Name": "ASTRID 000280",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Pixie",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.0000142,
    "y_1": 0.00004927,
    "z_1": -0.00003862,
    "Distance in Parsecs_1": 9.2739,
    "HYG Star ID_1": 21879,
    "Hipparcos Catalogue_1": 21932,
    "Henry Draper Catalogue_1": 285968,
    "Gliese Catalog_1": "Gl 176"
  },
  {
    "Astrid #": "ASTRID 000281",
    "HYG Star ID": 9764,
    "Hipparcos Catalogue": 9786,
    "Gliese Catalog": "NN 3135",
    "Distance in Parsecs": 9.2756,
    "mag": 12.15,
    "Absolute Visual Magnitude": 12.313,
    "Spectral Type": "K:...",
    "Color Index": 1.719,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.840438,
    "y": 4.183997,
    "z": -4.662544,
    "vx": 0.00001431,
    "vy": -0.00001906,
    "vz": 0.00000389,
    "rarad": 0.548949774213257,
    "decrad": -0.526681891486603,
    "pmrarad": -0.00000255860419913194,
    "pmdecrad": 4.85201531e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 9764,
    "lum": 0.00103466557584243,
    "var_min": 12.25,
    "var_max": 12.04,
    "ra": 2.096834,
    "dec": -30.17665,
    "pmra": -527.75,
    "pmdec": 100.08,
    "rv": 0,
    "Asset Name": "ASTRID 000281",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Star of Culture",
    "Hair": "Tiny Crown",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001431,
    "y_1": -0.00001906,
    "z_1": 0.00000389,
    "Distance in Parsecs_1": 9.2756,
    "HYG Star ID_1": 9764,
    "Hipparcos Catalogue_1": 9786,
    "Gliese Catalog_1": "NN 3135"
  },
  {
    "Astrid #": "ASTRID 000282",
    "HYG Star ID": 118409,
    "Gliese Catalog": "NN 3380",
    "Distance in Parsecs": 9.2764,
    "mag": 14.47,
    "Absolute Visual Magnitude": 14.633,
    "Spectral Type": "m",
    "Color Index": 1.87,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.064926,
    "y": 5.979256,
    "z": 7.091959,
    "vx": -0.00000374,
    "vy": 0.00002734,
    "vz": -0.00002308,
    "rarad": 1.58164417275651,
    "decrad": 0.8702905205546,
    "pmrarad": 3.7175513025e-7,
    "pmdecrad": -0.000003860648908,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118409,
    "lum": 0.00012212371301653,
    "ra": 6.041436,
    "dec": 49.863974,
    "pmra": 76.68,
    "pmdec": -796.32,
    "rv": 0,
    "Asset Name": "ASTRID 000282",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Greasy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000374,
    "y_1": 0.00002734,
    "z_1": -0.00002308,
    "Distance in Parsecs_1": 9.2764,
    "HYG Star ID_1": 118409,
    "Gliese Catalog_1": "NN 3380"
  },
  {
    "Astrid #": "ASTRID 000283",
    "HYG Star ID": 66694,
    "Hipparcos Catalogue": 66906,
    "Gliese Catalog": "NN 3801",
    "Distance in Parsecs": 9.279,
    "mag": 12.03,
    "Absolute Visual Magnitude": 12.192,
    "Spectral Type": "M:",
    "Color Index": 2.4,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.990214,
    "y": -3.361201,
    "z": 5.093044,
    "vx": -0.0000179,
    "vy": -0.00000304,
    "vz": -0.00002657,
    "rarad": 3.58979915330916,
    "decrad": 0.581021919091623,
    "pmrarad": -5.40421809715277e-7,
    "pmdecrad": -0.000003426275243,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 66694,
    "lum": 0.00115664477416564,
    "var_min": 12.14,
    "var_max": 11.94,
    "ra": 13.712023,
    "dec": 33.290104,
    "pmra": -111.47,
    "pmdec": -706.72,
    "rv": 0,
    "Asset Name": "ASTRID 000283",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Grecian",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000179,
    "y_1": -0.00000304,
    "z_1": -0.00002657,
    "Distance in Parsecs_1": 9.279,
    "HYG Star ID_1": 66694,
    "Hipparcos Catalogue_1": 66906,
    "Gliese Catalog_1": "NN 3801"
  },
  {
    "Astrid #": "ASTRID 000284",
    "HYG Star ID": 119344,
    "Gliese Catalog": "GJ 1253",
    "Distance in Parsecs": 9.2937,
    "mag": 14.04,
    "Absolute Visual Magnitude": 14.199,
    "Spectral Type": "M5",
    "Color Index": 1.79,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.884081,
    "y": -3.894277,
    "z": 7.930293,
    "vx": -0.00000366,
    "vy": 0.00002349,
    "vz": 0.00001287,
    "rarad": 5.34978895967586,
    "decrad": 1.02227739122639,
    "pmrarad": 0.00000118779351736111,
    "pmdecrad": 0.000002655324528,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119344,
    "lum": 0.000182137763710477,
    "ra": 20.434689,
    "dec": 58.57218,
    "pmra": 245,
    "pmdec": 547.7,
    "rv": 0,
    "Asset Name": "ASTRID 000284",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined Neko",
    "Hair": "Ponytail",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000366,
    "y_1": 0.00002349,
    "z_1": 0.00001287,
    "Distance in Parsecs_1": 9.2937,
    "HYG Star ID_1": 119344,
    "Gliese Catalog_1": "GJ 1253"
  },
  {
    "Astrid #": "ASTRID 000285",
    "HYG Star ID": 29204,
    "Hipparcos Catalogue": 29277,
    "Gliese Catalog": "Gl 226",
    "Distance in Parsecs": 9.3729,
    "mag": 10.48,
    "Absolute Visual Magnitude": 10.621,
    "Spectral Type": "M3",
    "Color Index": 1.514,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.058,
    "y": 1.285826,
    "z": 9.283912,
    "vx": -0.00000486,
    "vy": 0.00005706,
    "vz": -0.00002961,
    "rarad": 1.61585947208936,
    "decrad": 1.43303318979852,
    "pmrarad": 2.43667355847222e-7,
    "pmdecrad": -0.000006481280169,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 29204,
    "lum": 0.00491586559156861,
    "var_min": 10.534,
    "var_max": 10.424,
    "ra": 6.172129,
    "dec": 82.106754,
    "pmra": 50.26,
    "pmdec": -1336.86,
    "rv": -21,
    "Asset Name": "ASTRID 000285",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Maniacal",
    "Hair": "Bob",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000486,
    "y_1": 0.00005706,
    "z_1": -0.00002961,
    "Distance in Parsecs_1": 9.3729,
    "HYG Star ID_1": 29204,
    "Hipparcos Catalogue_1": 29277,
    "Gliese Catalog_1": "Gl 226"
  },
  {
    "Astrid #": "ASTRID 000286",
    "HYG Star ID": 27535,
    "Hipparcos Catalogue": 27604,
    "Henry Draper Catalogue": 39170,
    "Distance in Parsecs": 9.402,
    "mag": 9.38,
    "Absolute Visual Magnitude": 9.514,
    "Spectral Type": "F0",
    "Color Index": 0.24,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.382999,
    "y": 9.391268,
    "z": -0.234532,
    "vx": 1.7e-7,
    "vy": 7e-8,
    "vz": 0.00000298,
    "rarad": 1.53003645698053,
    "decrad": -0.024947533684235,
    "pmrarad": -1.72593670277777e-8,
    "pmdecrad": 3.17407516e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 27535,
    "lum": 0.0136269919699486,
    "var_min": 9.528,
    "var_max": 8.638,
    "ra": 5.844309,
    "dec": -1.429388,
    "pmra": -3.56,
    "pmdec": 65.47,
    "rv": 0,
    "Asset Name": "ASTRID 000286",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Ponytail",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 1.7e-7,
    "y_1": 7e-8,
    "z_1": 0.00000298,
    "Distance in Parsecs_1": 9.402,
    "HYG Star ID_1": 27535,
    "Hipparcos Catalogue_1": 27604,
    "Henry Draper Catalogue_1": 39170
  },
  {
    "Astrid #": "ASTRID 000287",
    "HYG Star ID": 14067,
    "Hipparcos Catalogue": 14101,
    "Gliese Catalog": "NN 3192A",
    "Distance in Parsecs": 9.4197,
    "mag": 10.4,
    "Absolute Visual Magnitude": 10.53,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 6.331435,
    "y": 6.434849,
    "z": -2.690055,
    "vx": 0.00000897,
    "vy": -0.0000144,
    "vz": -0.00001332,
    "rarad": 0.793499113736261,
    "decrad": -0.289608986012376,
    "pmrarad": -0.0000017510015700625,
    "pmdecrad": -0.000001475772843,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 14067,
    "base": "NN 3192",
    "lum": 0.00534564359396971,
    "ra": 3.030943,
    "dec": -16.593373,
    "pmra": -361.17,
    "pmdec": -304.4,
    "rv": 0,
    "Asset Name": "ASTRID 000287",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Frizzy",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000897,
    "y_1": -0.0000144,
    "z_1": -0.00001332,
    "Distance in Parsecs_1": 9.4197,
    "HYG Star ID_1": 14067,
    "Hipparcos Catalogue_1": 14101,
    "Gliese Catalog_1": "NN 3192A"
  },
  {
    "Astrid #": "ASTRID 000288",
    "HYG Star ID": 47291,
    "Hipparcos Catalogue": 47425,
    "Gliese Catalog": "Gl 358",
    "Distance in Parsecs": 9.467,
    "mag": 10.72,
    "Absolute Visual Magnitude": 10.839,
    "Spectral Type": "M:",
    "Color Index": 1.521,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.843427,
    "y": 4.100249,
    "z": -6.220166,
    "vx": -0.00008457,
    "vy": 0.00008883,
    "vz": -0.00008306,
    "rarad": 2.52974347005665,
    "decrad": -0.716764081450563,
    "pmrarad": -0.00000255050781066666,
    "pmdecrad": 0.000001730348507,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 47291,
    "lum": 0.00402161044334213,
    "var_min": 10.804,
    "var_max": 10.664,
    "ra": 9.662908,
    "dec": -41.067557,
    "pmra": -526.08,
    "pmdec": 356.91,
    "rv": 142,
    "Asset Name": "ASTRID 000288",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Laughing",
    "Hair": "Braided Ponytail",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00008457,
    "y_1": 0.00008883,
    "z_1": -0.00008306,
    "Distance in Parsecs_1": 9.467,
    "HYG Star ID_1": 47291,
    "Hipparcos Catalogue_1": 47425,
    "Gliese Catalog_1": "Gl 358"
  },
  {
    "Astrid #": "ASTRID 000289",
    "HYG Star ID": 86509,
    "Hipparcos Catalogue": 86776,
    "Gliese Catalog": "Gl 694",
    "Distance in Parsecs": 9.4787,
    "mag": 10.49,
    "Absolute Visual Magnitude": 10.606,
    "Spectral Type": "M3",
    "Color Index": 1.528,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.482592,
    "y": -6.872396,
    "z": 6.510036,
    "vx": -1.4e-7,
    "vy": -0.00000825,
    "vz": -0.00003031,
    "rarad": 4.64228202215294,
    "decrad": 0.757099608138241,
    "pmrarad": 4.59603369166666e-8,
    "pmdecrad": -0.000002921584201,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 86509,
    "lum": 0.00498425209404259,
    "ra": 17.732211,
    "dec": 43.378612,
    "pmra": 9.48,
    "pmdec": -602.62,
    "rv": -14.5,
    "Asset Name": "ASTRID 000289",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Ninja",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -1.4e-7,
    "y_1": -0.00000825,
    "z_1": -0.00003031,
    "Distance in Parsecs_1": 9.4787,
    "HYG Star ID_1": 86509,
    "Hipparcos Catalogue_1": 86776,
    "Gliese Catalog_1": "Gl 694"
  },
  {
    "Astrid #": "ASTRID 000290",
    "HYG Star ID": 119126,
    "Gliese Catalog": "NN 3988",
    "Distance in Parsecs": 9.5057,
    "mag": 13.56,
    "Absolute Visual Magnitude": 13.67,
    "Spectral Type": "k-m",
    "Color Index": 1.75,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.449495,
    "y": -5.749763,
    "z": 7.429504,
    "vx": 0.00001192,
    "vy": 0.00002015,
    "vz": 0.00001793,
    "rarad": 4.46541716131279,
    "decrad": 0.89720122693595,
    "pmrarad": 6.97986255895833e-7,
    "pmdecrad": 0.000003023278719,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119126,
    "lum": 0.000296483138952433,
    "ra": 17.056637,
    "dec": 51.405844,
    "pmra": 143.97,
    "pmdec": 623.6,
    "rv": 0,
    "Asset Name": "ASTRID 000290",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "Mohawk",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001192,
    "y_1": 0.00002015,
    "z_1": 0.00001793,
    "Distance in Parsecs_1": 9.5057,
    "HYG Star ID_1": 119126,
    "Gliese Catalog_1": "NN 3988"
  },
  {
    "Astrid #": "ASTRID 000291",
    "HYG Star ID": 118323,
    "Gliese Catalog": "NN 3306",
    "Distance in Parsecs": 9.5147,
    "mag": 13.77,
    "Absolute Visual Magnitude": 13.878,
    "Spectral Type": "DQ7",
    "Color Index": 0.33,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.299977,
    "y": 8.804084,
    "z": -1.458691,
    "vx": -0.00001376,
    "vy": -0.00000616,
    "vz": -0.00006847,
    "rarad": 1.21217985528663,
    "decrad": -0.1539163140116,
    "pmrarad": 0.00000112738573276388,
    "pmdecrad": -0.000007282420232,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118323,
    "lum": 0.000244793566619606,
    "ra": 4.630186,
    "dec": -8.818755,
    "pmra": 232.54,
    "pmdec": -1502.11,
    "rv": 0,
    "Asset Name": "ASTRID 000291",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Lion Mane",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001376,
    "y_1": -0.00000616,
    "z_1": -0.00006847,
    "Distance in Parsecs_1": 9.5147,
    "HYG Star ID_1": 118323,
    "Gliese Catalog_1": "NN 3306"
  },
  {
    "Astrid #": "ASTRID 000292",
    "HYG Star ID": 118263,
    "Gliese Catalog": "GJ 1065",
    "Distance in Parsecs": 9.5329,
    "mag": 12.79,
    "Absolute Visual Magnitude": 12.894,
    "Spectral Type": "dM4",
    "Color Index": 1.7,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.06738,
    "y": 8.010843,
    "z": -1.012052,
    "vx": 0.00001248,
    "vy": -0.00001576,
    "vz": -0.00006247,
    "rarad": 1.00677596696054,
    "decrad": -0.1063645341618,
    "pmrarad": -0.00000198982078910416,
    "pmdecrad": -0.000006590523236,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118263,
    "lum": 0.000605898671138964,
    "ra": 3.845601,
    "dec": -6.094239,
    "pmra": -410.43,
    "pmdec": -1359.39,
    "rv": 0,
    "Asset Name": "ASTRID 000292",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Naughty Dreams",
    "Hair": "Windy",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001248,
    "y_1": -0.00001576,
    "z_1": -0.00006247,
    "Distance in Parsecs_1": 9.5329,
    "HYG Star ID_1": 118263,
    "Gliese Catalog_1": "GJ 1065"
  },
  {
    "Astrid #": "ASTRID 000293",
    "HYG Star ID": 118760,
    "Gliese Catalog": "Gl 432B",
    "Distance in Parsecs": 9.5383,
    "mag": 15,
    "Absolute Visual Magnitude": 15.103,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.965007,
    "y": 0.890014,
    "z": -5.171626,
    "vx": -0.00001704,
    "vy": 0.00003307,
    "vz": 0.00003191,
    "rarad": 3.03034451853735,
    "decrad": -0.57304804208275,
    "pmrarad": -0.00000324800925288194,
    "pmdecrad": 0.000003982458345,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 56283,
    "base": "Gl 432",
    "lum": 0.000079213645415285,
    "ra": 11.575063,
    "dec": -32.833234,
    "pmra": -669.95,
    "pmdec": 821.44,
    "rv": 0,
    "Asset Name": "ASTRID 000293",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Braided Ponytail",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001704,
    "y_1": 0.00003307,
    "z_1": 0.00003191,
    "Distance in Parsecs_1": 9.5383,
    "HYG Star ID_1": 118760,
    "Gliese Catalog_1": "Gl 432B"
  },
  {
    "Astrid #": "ASTRID 000294",
    "HYG Star ID": 56283,
    "Hipparcos Catalogue": 56452,
    "Henry Draper Catalogue": 100623,
    "Harvard Revised Catalogue": 4458,
    "Gliese Catalog": "Gl 432A",
    "Distance in Parsecs": 9.5593,
    "mag": 5.96,
    "Absolute Visual Magnitude": 6.058,
    "Spectral Type": "K0V",
    "Color Index": 0.811,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.982524,
    "y": 0.892159,
    "z": -5.182636,
    "vx": 0.00000218,
    "vy": 0.00003104,
    "vz": 0.00004461,
    "rarad": 3.0302960162252,
    "decrad": -0.573014973721031,
    "pmrarad": -0.00000325300283379166,
    "pmdecrad": 0.000003994089025,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 56283,
    "base": "Gl 432",
    "lum": 0.328700223968775,
    "ra": 11.574878,
    "dec": -32.83134,
    "pmra": -670.98,
    "pmdec": 823.84,
    "rv": -22.6,
    "Asset Name": "ASTRID 000294",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Wavy",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000218,
    "y_1": 0.00003104,
    "z_1": 0.00004461,
    "Distance in Parsecs_1": 9.5593,
    "HYG Star ID_1": 56283,
    "Hipparcos Catalogue_1": 56452,
    "Henry Draper Catalogue_1": 100623,
    "Harvard Revised Catalogue_1": 4458,
    "Gliese Catalog_1": "Gl 432A"
  },
  {
    "Astrid #": "ASTRID 000295",
    "HYG Star ID": 119102,
    "Gliese Catalog": "Gl 633",
    "Distance in Parsecs": 9.5602,
    "mag": 12.68,
    "Absolute Visual Magnitude": 12.778,
    "Spectral Type": "M3",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.249263,
    "y": -6.248781,
    "z": -6.878976,
    "vx": 0.00001272,
    "vy": -0.0000133,
    "vz": -0.00003473,
    "rarad": 4.36683767342255,
    "decrad": -0.8029779987627,
    "pmrarad": 0.00000172268845111805,
    "pmdecrad": -0.000001906515254,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119102,
    "lum": 0.000674217467576243,
    "ra": 16.680091,
    "dec": -46.00725,
    "pmra": 355.33,
    "pmdec": -393.25,
    "rv": 30,
    "Asset Name": "ASTRID 000295",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Lion Mane",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001272,
    "y_1": -0.0000133,
    "z_1": -0.00003473,
    "Distance in Parsecs_1": 9.5602,
    "HYG Star ID_1": 119102,
    "Gliese Catalog_1": "Gl 633"
  },
  {
    "Astrid #": "ASTRID 000296",
    "HYG Star ID": 119115,
    "Gliese Catalog": "GJ 1207",
    "Distance in Parsecs": 9.5785,
    "mag": 12.28,
    "Absolute Visual Magnitude": 12.374,
    "Spectral Type": "dM3.5",
    "Color Index": 1.6,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.588404,
    "y": -9.193471,
    "z": -0.726586,
    "vx": 0.00002305,
    "vy": -0.00000513,
    "vz": -0.00001727,
    "rarad": 4.43794498322501,
    "decrad": -0.0759288859944,
    "pmrarad": 0.00000246154450028472,
    "pmdecrad": -0.000001808199888,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119115,
    "lum": 0.000978137704322984,
    "ra": 16.951701,
    "dec": -4.350405,
    "pmra": 507.73,
    "pmdec": -372.97,
    "rv": 0,
    "Asset Name": "ASTRID 000296",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Grecian",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002305,
    "y_1": -0.00000513,
    "z_1": -0.00001727,
    "Distance in Parsecs_1": 9.5785,
    "HYG Star ID_1": 119115,
    "Gliese Catalog_1": "GJ 1207"
  },
  {
    "Astrid #": "ASTRID 000297",
    "HYG Star ID": 117956,
    "Gliese Catalog": "GJ 1001",
    "Distance in Parsecs": 9.5969,
    "mag": 12.84,
    "Absolute Visual Magnitude": 12.929,
    "Spectral Type": "M3.5",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.27354,
    "y": 0.148745,
    "z": -6.258677,
    "vx": -0.00004704,
    "vy": 0.0000313,
    "vz": -0.00004927,
    "rarad": 0.0203936730291333,
    "decrad": -0.7104389344088,
    "pmrarad": 0.00000336034058266666,
    "pmdecrad": -0.000007045128176,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117956,
    "lum": 0.000586678266401976,
    "ra": 0.077898,
    "dec": -40.705153,
    "pmra": 693.12,
    "pmdec": -1453.16,
    "rv": -3,
    "Asset Name": "ASTRID 000297",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "High Fade",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004704,
    "y_1": 0.0000313,
    "z_1": -0.00004927,
    "Distance in Parsecs_1": 9.5969,
    "HYG Star ID_1": 117956,
    "Gliese Catalog_1": "GJ 1001"
  },
  {
    "Astrid #": "ASTRID 000298",
    "HYG Star ID": 56828,
    "Hipparcos Catalogue": 56997,
    "Henry Draper Catalogue": 101501,
    "Harvard Revised Catalogue": 4496,
    "Gliese Catalog": "Gl 434",
    "Distance in Parsecs": 9.6117,
    "mag": 5.31,
    "Absolute Visual Magnitude": 5.396,
    "Spectral Type": "G8Vvar",
    "Color Index": 0.723,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.922294,
    "y": 0.656542,
    "z": 5.402774,
    "vx": -0.0000049,
    "vy": 0.00000106,
    "vz": -0.00001805,
    "rarad": 3.0589089132243,
    "decrad": 0.596931147431371,
    "pmrarad": -6.76315084374999e-8,
    "pmdecrad": -0.000001844522129,
    "flam": 61,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 56828,
    "lum": 0.6047835918818,
    "bf": "61    UMa",
    "ra": 11.684171,
    "dec": 34.201635,
    "pmra": -13.95,
    "pmdec": -380.46,
    "rv": -5.9,
    "Asset Name": "ASTRID 000298",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Serious Shinobi",
    "Hair": "Middle Part",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.0000049,
    "y_1": 0.00000106,
    "z_1": -0.00001805,
    "Distance in Parsecs_1": 9.6117,
    "HYG Star ID_1": 56828,
    "Hipparcos Catalogue_1": 56997,
    "Henry Draper Catalogue_1": 101501,
    "Harvard Revised Catalogue_1": 4496,
    "Gliese Catalog_1": "Gl 434"
  },
  {
    "Astrid #": "ASTRID 000299",
    "HYG Star ID": 118265,
    "Gliese Catalog": "NN 3253",
    "Distance in Parsecs": 9.6154,
    "mag": 13.7,
    "Absolute Visual Magnitude": 13.785,
    "Spectral Type": "M4.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.848881,
    "y": 7.811766,
    "z": 2.81435,
    "vx": -0.00001156,
    "vy": 0.00001747,
    "vz": -0.00002857,
    "rarad": 1.01527974383322,
    "decrad": 0.297040890790749,
    "pmrarad": 0.00000197973666454861,
    "pmdecrad": -0.000003107544185,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118265,
    "lum": 0.000266685866452147,
    "ra": 3.878083,
    "dec": 17.019189,
    "pmra": 408.35,
    "pmdec": -640.98,
    "rv": 0,
    "Asset Name": "ASTRID 000299",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Grecian",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001156,
    "y_1": 0.00001747,
    "z_1": -0.00002857,
    "Distance in Parsecs_1": 9.6154,
    "HYG Star ID_1": 118265,
    "Gliese Catalog_1": "NN 3253"
  },
  {
    "Astrid #": "ASTRID 000300",
    "HYG Star ID": 118289,
    "Gliese Catalog": "NN 3275",
    "Distance in Parsecs": 9.6154,
    "mag": 12.9,
    "Absolute Visual Magnitude": 12.985,
    "Spectral Type": "dM4.5e",
    "Color Index": 1.7,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.759917,
    "y": 8.261925,
    "z": 3.171677,
    "vx": 0.00000472,
    "vy": -0.00000182,
    "vz": -8.4e-7,
    "rarad": 1.14371949606699,
    "decrad": 0.336148830786949,
    "pmrarad": -5.25198660145833e-7,
    "pmdecrad": -9.2604261e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118289,
    "lum": 0.000557185748931929,
    "ra": 4.368687,
    "dec": 19.259909,
    "pmra": -108.33,
    "pmdec": -19.1,
    "rv": 0,
    "Asset Name": "ASTRID 000300",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Audiohead",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000472,
    "y_1": -0.00000182,
    "z_1": -8.4e-7,
    "Distance in Parsecs_1": 9.6154,
    "HYG Star ID_1": 118289,
    "Gliese Catalog_1": "NN 3275"
  },
  {
    "Astrid #": "ASTRID 000301",
    "HYG Star ID": 72717,
    "Hipparcos Catalogue": 72944,
    "Gliese Catalog": "Gl 569A",
    "Distance in Parsecs": 9.6534,
    "mag": 10.11,
    "Absolute Visual Magnitude": 10.187,
    "Spectral Type": "M2Ve",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.714014,
    "y": -6.398547,
    "z": 2.67718,
    "vx": 0.00001374,
    "vy": -0.00000475,
    "vz": -0.00000787,
    "rarad": 3.90293657012932,
    "decrad": 0.281016543947631,
    "pmrarad": 0.00000133784335149305,
    "pmdecrad": -5.92005985e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72717,
    "base": "Gl 569",
    "lum": 0.00733162089027211,
    "var": "CE",
    "var_min": 10.15,
    "var_max": 10.02,
    "ra": 14.90812,
    "dec": 16.101062,
    "pmra": 275.95,
    "pmdec": -122.11,
    "rv": -8.4,
    "Asset Name": "ASTRID 000301",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Oh Snap",
    "Hair": "Hachimaki",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001374,
    "y_1": -0.00000475,
    "z_1": -0.00000787,
    "Distance in Parsecs_1": 9.6534,
    "HYG Star ID_1": 72717,
    "Hipparcos Catalogue_1": 72944,
    "Gliese Catalog_1": "Gl 569A"
  },
  {
    "Astrid #": "ASTRID 000302",
    "HYG Star ID": 46522,
    "Hipparcos Catalogue": 46655,
    "Gliese Catalog": "GJ 1125",
    "Distance in Parsecs": 9.6656,
    "mag": 11.71,
    "Absolute Visual Magnitude": 11.784,
    "Spectral Type": "K:...",
    "Color Index": 1.588,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.686867,
    "y": 5.858842,
    "z": 0.05443,
    "vx": 0.00004861,
    "vy": -0.00000347,
    "vz": -0.00002611,
    "rarad": 2.49033644373559,
    "decrad": 0.005631491742355,
    "pmrarad": -0.00000276300164685416,
    "pmdecrad": -0.000002677286588,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46522,
    "lum": 0.0016842245750827,
    "var_min": 11.833,
    "var_max": 11.593,
    "ra": 9.512385,
    "dec": 0.322661,
    "pmra": -569.91,
    "pmdec": -552.23,
    "rv": -40,
    "Asset Name": "ASTRID 000302",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Pixie",
    "Outfit": "School Uniform",
    "Special": "Neko Tail",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00004861,
    "y_1": -0.00000347,
    "z_1": -0.00002611,
    "Distance in Parsecs_1": 9.6656,
    "HYG Star ID_1": 46522,
    "Hipparcos Catalogue_1": 46655,
    "Gliese Catalog_1": "GJ 1125"
  },
  {
    "Astrid #": "ASTRID 000303",
    "HYG Star ID": 118976,
    "Gliese Catalog": "NN 3855",
    "Distance in Parsecs": 9.6805,
    "mag": 17.88,
    "Absolute Visual Magnitude": 17.951,
    "Spectral Type": "M6.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.864652,
    "y": -2.983062,
    "z": 8.360544,
    "vx": -0.0000259,
    "vy": 0.00002748,
    "vz": 0.00002271,
    "rarad": 3.79914362963382,
    "decrad": 1.0422719294812,
    "pmrarad": -0.00000388272732346527,
    "pmdecrad": 8.53669624e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118976,
    "lum": 0.00000574910181567502,
    "ra": 14.51166,
    "dec": 59.717783,
    "pmra": -800.87,
    "pmdec": 176.08,
    "rv": 21,
    "Asset Name": "ASTRID 000303",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Greasy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000259,
    "y_1": 0.00002748,
    "z_1": 0.00002271,
    "Distance in Parsecs_1": 9.6805,
    "HYG Star ID_1": 118976,
    "Gliese Catalog_1": "NN 3855"
  },
  {
    "Astrid #": "ASTRID 000304",
    "HYG Star ID": 84325,
    "Hipparcos Catalogue": 84581,
    "Distance in Parsecs": 9.6824,
    "mag": 11.09,
    "Absolute Visual Magnitude": 11.16,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.772845,
    "y": -9.425782,
    "z": -1.326847,
    "vx": 0.0000046,
    "vy": -0.00000197,
    "vz": 0.00000783,
    "rarad": 4.52647624959188,
    "decrad": -0.137469581725147,
    "pmrarad": 5.04981929666666e-7,
    "pmdecrad": 8.16717126e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84325,
    "lum": 0.00299226463660818,
    "ra": 17.289866,
    "dec": -7.876427,
    "pmra": 104.16,
    "pmdec": 168.46,
    "rv": 0,
    "Asset Name": "ASTRID 000304",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Angry",
    "Hair": "Bangs",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.0000046,
    "y_1": -0.00000197,
    "z_1": 0.00000783,
    "Distance in Parsecs_1": 9.6824,
    "HYG Star ID_1": 84325,
    "Hipparcos Catalogue_1": 84581
  },
  {
    "Astrid #": "ASTRID 000305",
    "HYG Star ID": 61436,
    "Hipparcos Catalogue": 61629,
    "Gliese Catalog": "Gl 479",
    "Distance in Parsecs": 9.6918,
    "mag": 10.65,
    "Absolute Visual Magnitude": 10.718,
    "Spectral Type": "M3",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.885415,
    "y": -0.981456,
    "z": -7.637418,
    "vx": -0.00001096,
    "vy": 0.00004743,
    "vz": -0.00000155,
    "rarad": 3.30686029814958,
    "decrad": -0.907596986546185,
    "pmrarad": -0.00000501404004704166,
    "pmdecrad": 1.45880436e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 61436,
    "lum": 0.00449572771301905,
    "var_min": 10.714,
    "var_max": 10.584,
    "ra": 12.631276,
    "dec": -52.001477,
    "pmra": -1034.22,
    "pmdec": 30.09,
    "rv": 3,
    "Asset Name": "ASTRID 000305",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Fedora",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00001096,
    "y_1": 0.00004743,
    "z_1": -0.00000155,
    "Distance in Parsecs_1": 9.6918,
    "HYG Star ID_1": 61436,
    "Hipparcos Catalogue_1": 61629,
    "Gliese Catalog_1": "Gl 479"
  },
  {
    "Astrid #": "ASTRID 000306",
    "HYG Star ID": 85794,
    "Hipparcos Catalogue": 86057,
    "Gliese Catalog": "Gl 680",
    "Distance in Parsecs": 9.7248,
    "mag": 10.13,
    "Absolute Visual Magnitude": 10.191,
    "Spectral Type": "K5",
    "Color Index": 1.543,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.692694,
    "y": -6.383349,
    "z": -7.30375,
    "vx": 0.00000221,
    "vy": -0.00001648,
    "vz": 0.00001419,
    "rarad": 4.60429432877324,
    "decrad": -0.849641388085722,
    "pmrarad": 4.09231227756944e-7,
    "pmdecrad": 0.000002210847346,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 85794,
    "lum": 0.0073046598952041,
    "ra": 17.587109,
    "dec": -48.680866,
    "pmra": 84.41,
    "pmdec": 456.02,
    "rv": 0,
    "Asset Name": "ASTRID 000306",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Bandana",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00000221,
    "y_1": -0.00001648,
    "z_1": 0.00001419,
    "Distance in Parsecs_1": 9.7248,
    "HYG Star ID_1": 85794,
    "Hipparcos Catalogue_1": 86057,
    "Gliese Catalog_1": "Gl 680"
  },
  {
    "Astrid #": "ASTRID 000307",
    "HYG Star ID": 69962,
    "Hipparcos Catalogue": 70185,
    "Distance in Parsecs": 9.7418,
    "mag": 13.45,
    "Absolute Visual Magnitude": 13.507,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.623809,
    "y": -5.416765,
    "z": -2.727426,
    "vx": -0.00000268,
    "vy": 0.00000404,
    "vz": -5.4e-7,
    "rarad": 3.75933528955466,
    "decrad": -0.283764372205932,
    "pmrarad": -4.97079466673611e-7,
    "pmdecrad": -5.8080678e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 69962,
    "lum": 0.000344508546286578,
    "ra": 14.359603,
    "dec": -16.258501,
    "pmra": -102.53,
    "pmdec": -11.98,
    "rv": 0,
    "Asset Name": "ASTRID 000307",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Lovestruck",
    "Hair": "Windy",
    "Outfit": "River Spirit",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000268,
    "y_1": 0.00000404,
    "z_1": -5.4e-7,
    "Distance in Parsecs_1": 9.7418,
    "HYG Star ID_1": 69962,
    "Hipparcos Catalogue_1": 70185
  },
  {
    "Astrid #": "ASTRID 000308",
    "HYG Star ID": 31555,
    "Hipparcos Catalogue": 31635,
    "Henry Draper Catalogue": 260655,
    "Gliese Catalog": "Gl 239",
    "Distance in Parsecs": 9.7466,
    "mag": 9.63,
    "Absolute Visual Magnitude": 9.686,
    "Spectral Type": "M1:",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.500769,
    "y": 9.169698,
    "z": 2.941219,
    "vx": 0.00004576,
    "vy": -0.00005273,
    "vz": -0.00000237,
    "rarad": 1.73302572008905,
    "decrad": 0.306563614353179,
    "pmrarad": -0.0000037598755368125,
    "pmdecrad": 0.000001608611792,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 31555,
    "lum": 0.0116305432332846,
    "ra": 6.619671,
    "dec": 17.564801,
    "pmra": -775.53,
    "pmdec": 331.8,
    "rv": -56.1,
    "Asset Name": "ASTRID 000308",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Bangs",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00004576,
    "y_1": -0.00005273,
    "z_1": -0.00000237,
    "Distance in Parsecs_1": 9.7466,
    "HYG Star ID_1": 31555,
    "Hipparcos Catalogue_1": 31635,
    "Henry Draper Catalogue_1": 260655,
    "Gliese Catalog_1": "Gl 239"
  },
  {
    "Astrid #": "ASTRID 000309",
    "HYG Star ID": 81054,
    "Hipparcos Catalogue": 81300,
    "Henry Draper Catalogue": 149661,
    "Harvard Revised Catalogue": 6171,
    "Gliese Catalog": "Gl 631",
    "Distance in Parsecs": 9.7513,
    "mag": 5.77,
    "Absolute Visual Magnitude": 5.825,
    "Spectral Type": "K2V",
    "Color Index": 0.827,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.477447,
    "y": -9.101465,
    "z": -0.395514,
    "vx": 0.00002473,
    "vy": 0.00000442,
    "vz": -0.00001403,
    "rarad": 4.34742964897732,
    "decrad": -0.0405716380328437,
    "pmrarad": 0.000002206968836625,
    "pmdecrad": -0.000001491432325,
    "flam": 12,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 81054,
    "lum": 0.407380277804112,
    "var": "V2133",
    "var_min": 5.778,
    "var_max": 5.758,
    "bf": "12    Oph",
    "ra": 16.605958,
    "dec": -2.324584,
    "pmra": 455.22,
    "pmdec": -307.63,
    "rv": -12.1,
    "Asset Name": "ASTRID 000309",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Elegant",
    "Outfit": "Star Knight",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00002473,
    "y_1": 0.00000442,
    "z_1": -0.00001403,
    "Distance in Parsecs_1": 9.7513,
    "HYG Star ID_1": 81054,
    "Hipparcos Catalogue_1": 81300,
    "Henry Draper Catalogue_1": 149661,
    "Harvard Revised Catalogue_1": 6171,
    "Gliese Catalog_1": "Gl 631"
  },
  {
    "Astrid #": "ASTRID 000310",
    "HYG Star ID": 118152,
    "Gliese Catalog": "Gl 102",
    "Distance in Parsecs": 9.7656,
    "mag": 12.96,
    "Absolute Visual Magnitude": 13.012,
    "Spectral Type": "M4",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.940132,
    "y": 5.501163,
    "z": 4.115667,
    "vx": 0.0000095,
    "vy": 0.00000983,
    "vz": -0.00002915,
    "rarad": 0.670248182763656,
    "decrad": 0.4350386155423,
    "pmrarad": 1.84035273138888e-7,
    "pmdecrad": -0.000003291589154,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118152,
    "lum": 0.000543500565129446,
    "ra": 2.560159,
    "dec": 24.925877,
    "pmra": 37.96,
    "pmdec": -678.94,
    "rv": 0,
    "Asset Name": "ASTRID 000310",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "Prince",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000095,
    "y_1": 0.00000983,
    "z_1": -0.00002915,
    "Distance in Parsecs_1": 9.7656,
    "HYG Star ID_1": 118152,
    "Gliese Catalog_1": "Gl 102"
  },
  {
    "Astrid #": "ASTRID 000311",
    "HYG Star ID": 118710,
    "Gliese Catalog": "GJ 1138",
    "Distance in Parsecs": 9.7656,
    "mag": 13.02,
    "Absolute Visual Magnitude": 13.072,
    "Spectral Type": "m",
    "Color Index": 1.66,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.575548,
    "y": 2.397046,
    "z": 5.67734,
    "vx": -0.00001741,
    "vy": 0.00003894,
    "vz": -0.00003964,
    "rarad": 2.83517891099307,
    "decrad": 0.62040054303185,
    "pmrarad": -0.00000326522013854166,
    "pmdecrad": -0.000004989799363,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118710,
    "lum": 0.000514280431948791,
    "ra": 10.829586,
    "dec": 35.546333,
    "pmra": -673.5,
    "pmdec": -1029.22,
    "rv": 0,
    "Asset Name": "ASTRID 000311",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Antlers",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001741,
    "y_1": 0.00003894,
    "z_1": -0.00003964,
    "Distance in Parsecs_1": 9.7656,
    "HYG Star ID_1": 118710,
    "Gliese Catalog_1": "GJ 1138"
  },
  {
    "Astrid #": "ASTRID 000312",
    "HYG Star ID": 81753,
    "Hipparcos Catalogue": 82003,
    "Henry Draper Catalogue": 151288,
    "Gliese Catalog": "Gl 638",
    "Distance in Parsecs": 9.8078,
    "mag": 8.1,
    "Absolute Visual Magnitude": 8.142,
    "Spectral Type": "K7V",
    "Color Index": 1.31,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.62499,
    "y": -7.74472,
    "z": 5.414454,
    "vx": 0.00000984,
    "vy": 0.00003484,
    "vz": -0.00000208,
    "rarad": 4.3856019369375,
    "decrad": 0.584846381711155,
    "pmrarad": -1.89950000041666e-7,
    "pmdecrad": 0.000001858824132,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81753,
    "lum": 0.0482169793900618,
    "ra": 16.751765,
    "dec": 33.509229,
    "pmra": -39.18,
    "pmdec": 383.41,
    "rv": -30.6,
    "Asset Name": "ASTRID 000312",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Battle Ready",
    "Hair": "Bob",
    "Outfit": "Toga",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000984,
    "y_1": 0.00003484,
    "z_1": -0.00000208,
    "Distance in Parsecs_1": 9.8078,
    "HYG Star ID_1": 81753,
    "Hipparcos Catalogue_1": 82003,
    "Henry Draper Catalogue_1": 151288,
    "Gliese Catalog_1": "Gl 638"
  },
  {
    "Astrid #": "ASTRID 000313",
    "HYG Star ID": 119360,
    "Gliese Catalog": "GJ 1256",
    "Distance in Parsecs": 9.8425,
    "mag": 13.43,
    "Absolute Visual Magnitude": 13.464,
    "Spectral Type": "dM4-5",
    "Color Index": 1.72,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 6.11219,
    "y": -7.247628,
    "z": 2.628946,
    "vx": -0.00000549,
    "vy": 0.00010441,
    "vz": 0.000009820000000000001,
    "rarad": 5.41298968890014,
    "decrad": 0.2704933446095,
    "pmrarad": 0.00000641015800293749,
    "pmdecrad": 0.00000322397219,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119360,
    "lum": 0.000358426408043713,
    "ra": 20.676098,
    "dec": 15.498127,
    "pmra": 1322.19,
    "pmdec": 664.99,
    "rv": -76,
    "Asset Name": "ASTRID 000313",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Greasy",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000549,
    "y_1": 0.00010441,
    "z_1": 0.000009820000000000001,
    "Distance in Parsecs_1": 9.8425,
    "HYG Star ID_1": 119360,
    "Gliese Catalog_1": "GJ 1256"
  },
  {
    "Astrid #": "ASTRID 000314",
    "HYG Star ID": 47645,
    "Hipparcos Catalogue": 47780,
    "Gliese Catalog": "Gl 367",
    "Distance in Parsecs": 9.8707,
    "mag": 10.22,
    "Absolute Visual Magnitude": 10.248,
    "Spectral Type": "M1:",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.716082,
    "y": 3.837529,
    "z": -7.073973,
    "vx": -0.00000641,
    "vy": 0.0000312,
    "vz": -0.00006353,
    "rarad": 2.55035883626945,
    "decrad": -0.798950864034077,
    "pmrarad": -0.00000226214063347222,
    "pmdecrad": -0.000002840232466,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 47645,
    "lum": 0.00693106545149936,
    "var_min": 10.279,
    "var_max": 10.169,
    "ra": 9.741653,
    "dec": -45.776513,
    "pmra": -466.6,
    "pmdec": -585.84,
    "rv": 60,
    "Asset Name": "ASTRID 000314",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Hurt",
    "Hair": "High Fade",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000641,
    "y_1": 0.0000312,
    "z_1": -0.00006353,
    "Distance in Parsecs_1": 9.8707,
    "HYG Star ID_1": 47645,
    "Hipparcos Catalogue_1": 47780,
    "Gliese Catalog_1": "Gl 367"
  },
  {
    "Astrid #": "ASTRID 000315",
    "HYG Star ID": 118012,
    "Gliese Catalog": "NN 3049",
    "Distance in Parsecs": 9.901,
    "mag": 13.1,
    "Absolute Visual Magnitude": 13.122,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.305815,
    "y": 1.401298,
    "z": -6.533854,
    "vx": -0.00001244,
    "vy": -0.00002682,
    "vz": -0.00001968,
    "rarad": 0.189544366124271,
    "decrad": -0.72071038253595,
    "pmrarad": -0.00000242377751456944,
    "pmdecrad": -0.000002645070719,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118012,
    "lum": 0.000491133999646997,
    "ra": 0.724006,
    "dec": -41.293663,
    "pmra": -499.94,
    "pmdec": -545.59,
    "rv": 0,
    "Asset Name": "ASTRID 000315",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Good Boy",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001244,
    "y_1": -0.00002682,
    "z_1": -0.00001968,
    "Distance in Parsecs_1": 9.901,
    "HYG Star ID_1": 118012,
    "Gliese Catalog_1": "NN 3049"
  },
  {
    "Astrid #": "ASTRID 000316",
    "HYG Star ID": 102078,
    "Hipparcos Catalogue": 102409,
    "Henry Draper Catalogue": 197481,
    "Gliese Catalog": "Gl 803",
    "Distance in Parsecs": 9.9098,
    "mag": 8.81,
    "Absolute Visual Magnitude": 8.83,
    "Spectral Type": "M1Ve",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.584971,
    "y": -6.35954,
    "z": -5.15434,
    "vx": 9e-8,
    "vy": 0.00002031,
    "vz": -0.000011,
    "rarad": 5.43302842763101,
    "decrad": -0.547001882448842,
    "pmrarad": 0.00000135927211617361,
    "pmdecrad": -0.000001745765582,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 102078,
    "lum": 0.0255858588690564,
    "var": "AU",
    "var_min": 8.868,
    "var_max": 8.758,
    "ra": 20.75264,
    "dec": -31.340899,
    "pmra": 280.37,
    "pmdec": -360.09,
    "rv": -7.1,
    "Asset Name": "ASTRID 000316",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Fire",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": 9e-8,
    "y_1": 0.00002031,
    "z_1": -0.000011,
    "Distance in Parsecs_1": 9.9098,
    "HYG Star ID_1": 102078,
    "Hipparcos Catalogue_1": 102409,
    "Henry Draper Catalogue_1": 197481,
    "Gliese Catalog_1": "Gl 803"
  },
  {
    "Astrid #": "ASTRID 000317",
    "HYG Star ID": 118009,
    "Gliese Catalog": "GJ 2012",
    "Distance in Parsecs": 9.9206,
    "mag": 14.53,
    "Absolute Visual Magnitude": 14.547,
    "Spectral Type": "DQ9",
    "Color Index": 0.62,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.025834,
    "y": 1.649371,
    "z": -3.77256,
    "vx": -0.00000295,
    "vy": -0.0000212,
    "vz": -0.00001634,
    "rarad": 0.180752905173025,
    "decrad": -0.39009403157625,
    "pmrarad": -0.0000020490165395,
    "pmdecrad": -0.000001781171525,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118009,
    "lum": 0.000132190425344172,
    "ra": 0.690425,
    "dec": -22.350742,
    "pmra": -422.64,
    "pmdec": -367.39,
    "rv": 0,
    "Asset Name": "ASTRID 000317",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Antlers",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000295,
    "y_1": -0.0000212,
    "z_1": -0.00001634,
    "Distance in Parsecs_1": 9.9206,
    "HYG Star ID_1": 118009,
    "Gliese Catalog_1": "GJ 2012"
  },
  {
    "Astrid #": "ASTRID 000318",
    "HYG Star ID": 4862,
    "Hipparcos Catalogue": 4872,
    "Gliese Catalog": "Gl 49",
    "Distance in Parsecs": 9.9602,
    "mag": 9.56,
    "Absolute Visual Magnitude": 9.569,
    "Spectral Type": "K5V...",
    "Color Index": 1.463,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.451317,
    "y": 1.24802,
    "z": 8.82229,
    "vx": -0.00001575,
    "vy": 0.0000322,
    "vz": -0.00000307,
    "rarad": 0.273316728715965,
    "decrad": 1.08812630194025,
    "pmrarad": 0.00000353962468173611,
    "pmdecrad": 4.32793172e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 4862,
    "lum": 0.0129538838897488,
    "ra": 1.043993,
    "dec": 62.345045,
    "pmra": 730.1,
    "pmdec": 89.27,
    "rv": -5.6,
    "Asset Name": "ASTRID 000318",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Goddess",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00001575,
    "y_1": 0.0000322,
    "z_1": -0.00000307,
    "Distance in Parsecs_1": 9.9602,
    "HYG Star ID_1": 4862,
    "Hipparcos Catalogue_1": 4872,
    "Gliese Catalog_1": "Gl 49"
  },
  {
    "Astrid #": "ASTRID 000319",
    "HYG Star ID": 12671,
    "Hipparcos Catalogue": 12703,
    "Distance in Parsecs": 9.9671,
    "mag": 12.16,
    "Absolute Visual Magnitude": 12.167,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.421565,
    "y": 3.818177,
    "z": -8.075542,
    "vx": -8.2e-7,
    "vy": 0.00000153,
    "vz": 2.7e-7,
    "rarad": 0.712298779862956,
    "decrad": -0.944526959725998,
    "pmrarad": 1.69927195034722e-7,
    "pmdecrad": 4.6202743e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 12671,
    "lum": 0.00118358649196186,
    "ra": 2.720781,
    "dec": -54.117408,
    "pmra": 35.05,
    "pmdec": 9.53,
    "rv": 0,
    "Asset Name": "ASTRID 000319",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Sweet Nap",
    "Hair": "Pixie",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -8.2e-7,
    "y_1": 0.00000153,
    "z_1": 2.7e-7,
    "Distance in Parsecs_1": 9.9671,
    "HYG Star ID_1": 12671,
    "Hipparcos Catalogue_1": 12703
  },
  {
    "Astrid #": "ASTRID 000320",
    "HYG Star ID": 85406,
    "Hipparcos Catalogue": 85665,
    "Gliese Catalog": "Gl 678.1A",
    "Distance in Parsecs": 9.9771,
    "mag": 9.33,
    "Absolute Visual Magnitude": 9.335,
    "Spectral Type": "M1V...",
    "Color Index": 1.461,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.279893,
    "y": -9.847494,
    "z": 0.964669,
    "vx": 0.00000171,
    "vy": 0.00000251,
    "vz": -0.00001232,
    "rarad": 4.58314205979251,
    "decrad": 0.0968399473315287,
    "pmrarad": 1.37347715701388e-7,
    "pmdecrad": -0.000001203162111,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 85406,
    "base": "Gl 678.1",
    "lum": 0.0160694125301287,
    "ra": 17.506313,
    "dec": 5.54852,
    "pmra": 28.33,
    "pmdec": -248.17,
    "rv": -3.8,
    "Asset Name": "ASTRID 000320",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Ram Horns",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00000171,
    "y_1": 0.00000251,
    "z_1": -0.00001232,
    "Distance in Parsecs_1": 9.9771,
    "HYG Star ID_1": 85406,
    "Hipparcos Catalogue_1": 85665,
    "Gliese Catalog_1": "Gl 678.1A"
  },
  {
    "Astrid #": "ASTRID 000321",
    "HYG Star ID": 118554,
    "Gliese Catalog": "NN 3512",
    "Distance in Parsecs": 9.98,
    "mag": 15.05,
    "Absolute Visual Magnitude": 15.054,
    "Spectral Type": "m",
    "Color Index": 1.93,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.27887,
    "y": 3.860968,
    "z": 8.598973,
    "vx": -0.0000253,
    "vy": 0.0000488,
    "vz": -0.00003154,
    "rarad": 2.27490615557949,
    "decrad": 1.0384539218475,
    "pmrarad": -0.00000123360841017361,
    "pmdecrad": -0.00000623010305,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118554,
    "lum": 0.0000828705077906981,
    "ra": 8.689501,
    "dec": 59.499027,
    "pmra": -254.45,
    "pmdec": -1285.05,
    "rv": 0,
    "Asset Name": "ASTRID 000321",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Warlock",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000253,
    "y_1": 0.0000488,
    "z_1": -0.00003154,
    "Distance in Parsecs_1": 9.98,
    "HYG Star ID_1": 118554,
    "Gliese Catalog_1": "NN 3512"
  },
  {
    "Astrid #": "ASTRID 000322",
    "HYG Star ID": 117460,
    "Hipparcos Catalogue": 117828,
    "Henry Draper Catalogue": 223889,
    "Distance in Parsecs": 9.993,
    "mag": 9.99,
    "Absolute Visual Magnitude": 9.992,
    "Spectral Type": "M...",
    "Color Index": 1.466,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.478766,
    "y": -0.066693,
    "z": -9.680461,
    "vx": -0.00001742,
    "vy": 0.00001224,
    "vz": -0.00000455,
    "rarad": 6.25625489965403,
    "decrad": -1.32003665784829,
    "pmrarad": 0.00000117790331827777,
    "pmdecrad": -0.000001833468377,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 117460,
    "lum": 0.00877404787956752,
    "var_min": 10.034,
    "var_max": 9.924,
    "ra": 23.897133,
    "dec": -75.632529,
    "pmra": 242.96,
    "pmdec": -378.18,
    "rv": 0,
    "Asset Name": "ASTRID 000322",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Frizzy",
    "Outfit": "Wizard",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": -0.00001742,
    "y_1": 0.00001224,
    "z_1": -0.00000455,
    "Distance in Parsecs_1": 9.993,
    "HYG Star ID_1": 117460,
    "Hipparcos Catalogue_1": 117828,
    "Henry Draper Catalogue_1": 223889
  },
  {
    "Astrid #": "ASTRID 000323",
    "HYG Star ID": 118041,
    "Gliese Catalog": "GJ 1028",
    "Distance in Parsecs": 10,
    "mag": 14.46,
    "Absolute Visual Magnitude": 14.46,
    "Spectral Type": "m",
    "Color Index": 1.87,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.125486,
    "y": 2.654896,
    "z": -3.110793,
    "vx": -0.00001103,
    "vy": 0.00006095,
    "vz": 0.00001959,
    "rarad": 0.283100722492283,
    "decrad": -0.3163285555204,
    "pmrarad": 0.00000616076984565972,
    "pmdecrad": 0.000002061369592,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118041,
    "lum": 0.000143218789927354,
    "ra": 1.081365,
    "dec": -18.124291,
    "pmra": 1270.75,
    "pmdec": 425.19,
    "rv": 0,
    "Asset Name": "ASTRID 000323",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Mischievous",
    "Hair": "Grecian",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001103,
    "y_1": 0.00006095,
    "z_1": 0.00001959,
    "Distance in Parsecs_1": 10,
    "HYG Star ID_1": 118041,
    "Gliese Catalog_1": "GJ 1028"
  },
  {
    "Astrid #": "ASTRID 000324",
    "HYG Star ID": 118092,
    "Gliese Catalog": "NN 3119",
    "Distance in Parsecs": 10,
    "mag": 14.6,
    "Absolute Visual Magnitude": 14.6,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.7981,
    "y": 4.632201,
    "z": -1.065904,
    "vx": -0.00001307,
    "vy": 0.00002226,
    "vz": -0.00001115,
    "rarad": 0.484621570774275,
    "decrad": -0.10679331539955,
    "pmrarad": 0.00000257867548550694,
    "pmdecrad": -0.000001121247991,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118092,
    "lum": 0.000125892541179416,
    "ra": 1.851118,
    "dec": -6.118806,
    "pmra": 531.89,
    "pmdec": -231.27,
    "rv": 0,
    "Asset Name": "ASTRID 000324",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Battle Ready",
    "Hair": "Windy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001307,
    "y_1": 0.00002226,
    "z_1": -0.00001115,
    "Distance in Parsecs_1": 10,
    "HYG Star ID_1": 118092,
    "Gliese Catalog_1": "NN 3119"
  },
  {
    "Astrid #": "ASTRID 000325",
    "HYG Star ID": 118101,
    "Gliese Catalog": "NN 3126",
    "Distance in Parsecs": 10,
    "mag": 11.03,
    "Absolute Visual Magnitude": 11.03,
    "Spectral Type": "M4",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.812383,
    "y": 2.235851,
    "z": 8.970324,
    "vx": 0.00001092,
    "vy": -0.00000848,
    "vz": -0.00000253,
    "rarad": 0.530493140155805,
    "decrad": 1.113008450386,
    "pmrarad": -0.00000128441688389583,
    "pmdecrad": -5.7185228e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 118101,
    "lum": 0.00337287308658868,
    "ra": 2.026335,
    "dec": 63.770687,
    "pmra": -264.93,
    "pmdec": -117.95,
    "rv": 0,
    "Asset Name": "ASTRID 000325",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Innocent",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001092,
    "y_1": -0.00000848,
    "z_1": -0.00000253,
    "Distance in Parsecs_1": 10,
    "HYG Star ID_1": 118101,
    "Gliese Catalog_1": "NN 3126"
  },
  {
    "Astrid #": "ASTRID 000326",
    "HYG Star ID": 118321,
    "Gliese Catalog": "NN 3304",
    "Distance in Parsecs": 10,
    "mag": 12.53,
    "Absolute Visual Magnitude": 12.53,
    "Spectral Type": "m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.078488,
    "y": 8.25625,
    "z": 4.728346,
    "vx": -0.00001579,
    "vy": 0.00000712,
    "vz": -0.00000214,
    "rarad": 1.21388488603855,
    "decrad": 0.492504960932499,
    "pmrarad": 0.00000172836077118055,
    "pmdecrad": -2.4290135e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118321,
    "lum": 0.000847227414140596,
    "ra": 4.636699,
    "dec": 28.218456,
    "pmra": 356.5,
    "pmdec": -50.1,
    "rv": 0,
    "Asset Name": "ASTRID 000326",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Angelic",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001579,
    "y_1": 0.00000712,
    "z_1": -0.00000214,
    "Distance in Parsecs_1": 10,
    "HYG Star ID_1": 118321,
    "Gliese Catalog_1": "NN 3304"
  },
  {
    "Astrid #": "ASTRID 000327",
    "HYG Star ID": 119090,
    "Gliese Catalog": "NN 3959",
    "Distance in Parsecs": 10,
    "mag": 14.79,
    "Absolute Visual Magnitude": 14.79,
    "Spectral Type": "m+",
    "Color Index": 1.72,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.854261,
    "y": -7.00335,
    "z": 6.542651,
    "vx": -0.00000423,
    "vy": 0.0000121,
    "vz": 0.00001111,
    "rarad": 4.32539911480295,
    "decrad": 0.7132105474525,
    "pmrarad": -8.48423940972222e-7,
    "pmdecrad": 0.00000146950905,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119090,
    "lum": 0.000105681750921365,
    "ra": 16.521808,
    "dec": 40.863954,
    "pmra": -175,
    "pmdec": 303.11,
    "rv": 0,
    "Asset Name": "ASTRID 000327",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Charmer",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000423,
    "y_1": 0.0000121,
    "z_1": 0.00001111,
    "Distance in Parsecs_1": 10,
    "HYG Star ID_1": 119090,
    "Gliese Catalog_1": "NN 3959"
  },
  {
    "Astrid #": "ASTRID 000328",
    "HYG Star ID": 119110,
    "Gliese Catalog": "NN 3976",
    "Distance in Parsecs": 10,
    "mag": 14.08,
    "Absolute Visual Magnitude": 14.08,
    "Spectral Type": "m",
    "Color Index": 1.75,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.742372,
    "y": -8.82585,
    "z": 3.818871,
    "vx": 0.00000324,
    "vy": 0.00000693,
    "vz": 0.00001834,
    "rarad": 4.41112568405079,
    "decrad": 0.39183736357185,
    "pmrarad": 1.04041015847222e-7,
    "pmdecrad": 0.000001985011437,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119110,
    "lum": 0.000203235701093622,
    "ra": 16.849259,
    "dec": 22.450627,
    "pmra": 21.46,
    "pmdec": 409.44,
    "rv": 0,
    "Asset Name": "ASTRID 000328",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "High Bun",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000324,
    "y_1": 0.00000693,
    "z_1": 0.00001834,
    "Distance in Parsecs_1": 10,
    "HYG Star ID_1": 119110,
    "Gliese Catalog_1": "NN 3976"
  },
  {
    "Astrid #": "ASTRID 000329",
    "HYG Star ID": 119464,
    "Gliese Catalog": "NN 4248",
    "Distance in Parsecs": 10,
    "mag": 11.8,
    "Absolute Visual Magnitude": 11.8,
    "Spectral Type": "M3.5",
    "Color Index": 1.65,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.951863,
    "y": -3.91347,
    "z": -6.029623,
    "vx": 0.00001333,
    "vy": 0.00003697,
    "vz": -0.00000863,
    "rarad": 5.77041387287625,
    "decrad": -0.64720918693395,
    "pmrarad": 0.00000387569752509722,
    "pmdecrad": -0.000001082118679,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 119464,
    "lum": 0.00165958690743755,
    "ra": 22.041357,
    "dec": -37.082355,
    "pmra": 799.42,
    "pmdec": -223.2,
    "rv": 0,
    "Asset Name": "ASTRID 000329",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Nervous",
    "Hair": "Ponytail",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00001333,
    "y_1": 0.00003697,
    "z_1": -0.00000863,
    "Distance in Parsecs_1": 10,
    "HYG Star ID_1": 119464,
    "Gliese Catalog_1": "NN 4248"
  },
  {
    "Astrid #": "ASTRID 000330",
    "HYG Star ID": 46576,
    "Hipparcos Catalogue": 46706,
    "Gliese Catalog": "Gl 352A",
    "Distance in Parsecs": 10.012,
    "mag": 10.06,
    "Absolute Visual Magnitude": 10.057,
    "Spectral Type": "M2",
    "Color Index": 1.545,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.758109,
    "y": 5.882119,
    "z": -2.335351,
    "vx": -0.00002747,
    "vy": -0.00002321,
    "vz": 7.8e-7,
    "rarad": 2.49286977955598,
    "decrad": -0.235422288617541,
    "pmrarad": 0.00000350486354084027,
    "pmdecrad": 2.59472281e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46576,
    "base": "Gl 352",
    "lum": 0.00826418441715795,
    "var_min": 10.106,
    "var_max": 9.996,
    "ra": 9.522061,
    "dec": -13.488704,
    "pmra": 722.93,
    "pmdec": 53.52,
    "rv": 7.3,
    "Asset Name": "ASTRID 000330",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Braided Bun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00002747,
    "y_1": -0.00002321,
    "z_1": 7.8e-7,
    "Distance in Parsecs_1": 10.012,
    "HYG Star ID_1": 46576,
    "Hipparcos Catalogue_1": 46706,
    "Gliese Catalog_1": "Gl 352A"
  },
  {
    "Astrid #": "ASTRID 000331",
    "HYG Star ID": 68250,
    "Hipparcos Catalogue": 68469,
    "Henry Draper Catalogue": 122303,
    "Gliese Catalog": "Gl 536",
    "Distance in Parsecs": 10.0281,
    "mag": 9.71,
    "Absolute Visual Magnitude": 9.704,
    "Spectral Type": "K5",
    "Color Index": 1.461,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.652161,
    "y": -5.04848,
    "z": -0.464497,
    "vx": -0.00002137,
    "vy": 0.00003395,
    "vz": 0.00002911,
    "rarad": 3.66978664684509,
    "decrad": -0.0463361116439512,
    "pmrarad": -0.00000399864627448611,
    "pmdecrad": 0.000002906554977,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 68250,
    "lum": 0.011439314495393,
    "ra": 14.017552,
    "dec": -2.654864,
    "pmra": -824.78,
    "pmdec": 599.52,
    "rv": 0,
    "Asset Name": "ASTRID 000331",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Loopy",
    "Hair": "Ponytail",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002137,
    "y_1": 0.00003395,
    "z_1": 0.00002911,
    "Distance in Parsecs_1": 10.0281,
    "HYG Star ID_1": 68250,
    "Hipparcos Catalogue_1": 68469,
    "Henry Draper Catalogue_1": 122303,
    "Gliese Catalog_1": "Gl 536"
  },
  {
    "Astrid #": "ASTRID 000332",
    "HYG Star ID": 119057,
    "Gliese Catalog": "Gl 609",
    "Distance in Parsecs": 10.0604,
    "mag": 12.56,
    "Absolute Visual Magnitude": 12.547,
    "Spectral Type": "M3",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.607576,
    "y": -8.215205,
    "z": 3.538084,
    "vx": -0.00006289,
    "vy": -0.00001666,
    "vz": -0.00004779,
    "rarad": 4.20126234121549,
    "decrad": 0.35932182458585,
    "pmrarad": -0.00000464417569014583,
    "pmdecrad": -0.000006030568283,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119057,
    "lum": 0.000834065197408661,
    "ra": 16.04764,
    "dec": 20.587624,
    "pmra": -957.93,
    "pmdec": -1243.89,
    "rv": 25,
    "Asset Name": "ASTRID 000332",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Ninja",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006289,
    "y_1": -0.00001666,
    "z_1": -0.00004779,
    "Distance in Parsecs_1": 10.0604,
    "HYG Star ID_1": 119057,
    "Gliese Catalog_1": "Gl 609"
  },
  {
    "Astrid #": "ASTRID 000333",
    "HYG Star ID": 67966,
    "Hipparcos Catalogue": 68184,
    "Henry Draper Catalogue": 122064,
    "Harvard Revised Catalogue": 5256,
    "Distance in Parsecs": 10.0644,
    "mag": 6.49,
    "Absolute Visual Magnitude": 6.476,
    "Spectral Type": "K3V",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.185388,
    "y": -2.356764,
    "z": 8.843971,
    "vx": 0.00001795,
    "vy": 0.00001192,
    "vz": -0.00001743,
    "rarad": 3.65443423994691,
    "decrad": 1.07325289734073,
    "pmrarad": -1.56691781555555e-7,
    "pmdecrad": 0.000001049524655,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 67966,
    "lum": 0.223666014946617,
    "ra": 13.958911,
    "dec": 61.492861,
    "pmra": -32.32,
    "pmdec": 216.48,
    "rv": -25,
    "Asset Name": "ASTRID 000333",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Owie",
    "Hair": "Greasy",
    "Outfit": "Star Knight",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001795,
    "y_1": 0.00001192,
    "z_1": -0.00001743,
    "Distance in Parsecs_1": 10.0644,
    "HYG Star ID_1": 67966,
    "Hipparcos Catalogue_1": 68184,
    "Henry Draper Catalogue_1": 122064,
    "Harvard Revised Catalogue_1": 5256
  },
  {
    "Astrid #": "ASTRID 000334",
    "HYG Star ID": 2547,
    "Hipparcos Catalogue": 2552,
    "Gliese Catalog": "Gl 22A",
    "Distance in Parsecs": 10.0654,
    "mag": 10.27,
    "Absolute Visual Magnitude": 10.256,
    "Spectral Type": "M2.5Ve",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.855636,
    "y": 0.550293,
    "z": 9.281315,
    "vx": -0.00000353,
    "vy": 0.00008521,
    "vz": -0.00000801,
    "rarad": 0.141649925431151,
    "decrad": 1.17348380492651,
    "pmrarad": 0.0000084312492744375,
    "pmdecrad": -0.00000108903697,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 2547,
    "base": "Gl  22",
    "lum": 0.00688018316143037,
    "var": "V547",
    "var_min": 10.434,
    "var_max": 10.204,
    "ra": 0.541063,
    "dec": 67.235669,
    "pmra": 1739.07,
    "pmdec": -224.63,
    "rv": -4,
    "Asset Name": "ASTRID 000334",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Unkempt",
    "Outfit": "Super",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000353,
    "y_1": 0.00008521,
    "z_1": -0.00000801,
    "Distance in Parsecs_1": 10.0654,
    "HYG Star ID_1": 2547,
    "Hipparcos Catalogue_1": 2552,
    "Gliese Catalog_1": "Gl 22A"
  },
  {
    "Astrid #": "ASTRID 000335",
    "HYG Star ID": 8347,
    "Hipparcos Catalogue": 8362,
    "Henry Draper Catalogue": 10780,
    "Harvard Revised Catalogue": 511,
    "Gliese Catalog": "Gl 75",
    "Distance in Parsecs": 10.0675,
    "mag": 5.63,
    "Absolute Visual Magnitude": 5.615,
    "Spectral Type": "K0V",
    "Color Index": 0.804,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.955256,
    "y": 2.009812,
    "z": 9.03725,
    "vx": -0.00000162,
    "vy": 0.00003104,
    "vz": -0.00000164,
    "rarad": 0.470104707257742,
    "decrad": 1.11443637917593,
    "pmrarad": 0.00000282185802767361,
    "pmdecrad": -0.000001196665607,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 8347,
    "lum": 0.494310686986835,
    "var": "V987",
    "var_min": 5.643,
    "var_max": 5.613,
    "ra": 1.795668,
    "dec": 63.852501,
    "pmra": 582.05,
    "pmdec": -246.83,
    "rv": 4,
    "Asset Name": "ASTRID 000335",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Braided Bun",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000162,
    "y_1": 0.00003104,
    "z_1": -0.00000164,
    "Distance in Parsecs_1": 10.0675,
    "HYG Star ID_1": 8347,
    "Hipparcos Catalogue_1": 8362,
    "Henry Draper Catalogue_1": 10780,
    "Harvard Revised Catalogue_1": 511,
    "Gliese Catalog_1": "Gl 75"
  },
  {
    "Astrid #": "ASTRID 000336",
    "HYG Star ID": 118051,
    "Gliese Catalog": "NN 3079",
    "Distance in Parsecs": 10.101,
    "mag": 14.09,
    "Absolute Visual Magnitude": 14.068,
    "Spectral Type": "DAwk",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.595226,
    "y": 3.139221,
    "z": -0.327295,
    "vx": -8e-8,
    "vy": -2e-8,
    "vz": -0.00000245,
    "rarad": 0.316188686,
    "decrad": -0.032407966342,
    "pmrarad": 0,
    "pmdecrad": -2.4240684e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118051,
    "lum": 0.00020549440413453,
    "ra": 1.207752,
    "dec": -1.85684,
    "pmra": 0,
    "pmdec": -50,
    "rv": 0,
    "Asset Name": "ASTRID 000336",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Side Part",
    "Outfit": "Tats",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -8e-8,
    "y_1": -2e-8,
    "z_1": -0.00000245,
    "Distance in Parsecs_1": 10.101,
    "HYG Star ID_1": 118051,
    "Gliese Catalog_1": "NN 3079"
  },
  {
    "Astrid #": "ASTRID 000337",
    "HYG Star ID": 21500,
    "Hipparcos Catalogue": 21553,
    "Henry Draper Catalogue": 232979,
    "Gliese Catalog": "Gl 172",
    "Distance in Parsecs": 10.1102,
    "mag": 8.62,
    "Absolute Visual Magnitude": 8.596,
    "Spectral Type": "K8V",
    "Color Index": 1.423,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.144056,
    "y": 5.710386,
    "z": 8.063288,
    "vx": -3.3e-7,
    "vy": 0.00004167,
    "vz": 0.00001277,
    "rarad": 1.21160860294933,
    "decrad": 0.923167714902898,
    "pmrarad": 0.00000147965135305555,
    "pmdecrad": -0.000002305725383,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 21500,
    "lum": 0.0317394940856222,
    "ra": 4.628004,
    "dec": 52.893614,
    "pmra": 305.2,
    "pmdec": -475.59,
    "rv": 32.9,
    "Asset Name": "ASTRID 000337",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Undercut",
    "Outfit": "Emperor",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -3.3e-7,
    "y_1": 0.00004167,
    "z_1": 0.00001277,
    "Distance in Parsecs_1": 10.1102,
    "HYG Star ID_1": 21500,
    "Hipparcos Catalogue_1": 21553,
    "Henry Draper Catalogue_1": 232979,
    "Gliese Catalog_1": "Gl 172"
  },
  {
    "Astrid #": "ASTRID 000338",
    "HYG Star ID": 117993,
    "Gliese Catalog": "Gl 22B",
    "Distance in Parsecs": 10.1276,
    "mag": 12.4,
    "Absolute Visual Magnitude": 12.372,
    "Spectral Type": "dM3.5",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.879436,
    "y": 0.553436,
    "z": 9.338735,
    "vx": -0.00000128,
    "vy": 0.0000853,
    "vz": -0.00000449,
    "rarad": 0.141040834151954,
    "decrad": 1.17349221883675,
    "pmrarad": 0.00000835770303909722,
    "pmdecrad": -0.000001144863265,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 2547,
    "base": "Gl  22",
    "lum": 0.000979941161095593,
    "ra": 0.538736,
    "dec": 67.236151,
    "pmra": 1723.9,
    "pmdec": -236.15,
    "rv": 0,
    "Asset Name": "ASTRID 000338",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Einstein Tongue",
    "Hair": "Heartbreaker",
    "Outfit": "Tats",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000128,
    "y_1": 0.0000853,
    "z_1": -0.00000449,
    "Distance in Parsecs_1": 10.1276,
    "HYG Star ID_1": 117993,
    "Gliese Catalog_1": "Gl 22B"
  },
  {
    "Astrid #": "ASTRID 000339",
    "HYG Star ID": 56916,
    "Hipparcos Catalogue": 57087,
    "Gliese Catalog": "Gl 436",
    "Distance in Parsecs": 10.141,
    "mag": 10.67,
    "Absolute Visual Magnitude": 10.64,
    "Spectral Type": "M3",
    "Color Index": 1.493,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.031878,
    "y": 0.703493,
    "z": 4.557621,
    "vx": -0.00003018,
    "vy": -0.00004185,
    "vz": -0.00003128,
    "rarad": 3.06385507908839,
    "decrad": 0.466117575871967,
    "pmrarad": 0.00000434572438839583,
    "pmdecrad": -0.000003944928918,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 56916,
    "lum": 0.00483058802039772,
    "var_min": 10.716,
    "var_max": 10.596,
    "ra": 11.703064,
    "dec": 26.70657,
    "pmra": 896.37,
    "pmdec": -813.7,
    "rv": 9.7,
    "Asset Name": "ASTRID 000339",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Pixie",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00003018,
    "y_1": -0.00004185,
    "z_1": -0.00003128,
    "Distance in Parsecs_1": 10.141,
    "HYG Star ID_1": 56916,
    "Hipparcos Catalogue_1": 57087,
    "Gliese Catalog_1": "Gl 436"
  },
  {
    "Astrid #": "ASTRID 000340",
    "HYG Star ID": 118198,
    "Gliese Catalog": "Gl 127.1B",
    "Distance in Parsecs": 10.1523,
    "mag": 14.73,
    "Absolute Visual Magnitude": 14.697,
    "Color Index": 0.62,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 2.496467,
    "y": 2.736885,
    "z": -9.452317,
    "vx": -0.00000382,
    "vy": 2.9e-7,
    "vz": -9.2e-7,
    "rarad": 0.831279566691172,
    "decrad": -1.1972849242869,
    "pmrarad": 2.97093823444444e-7,
    "pmdecrad": -2.49305738e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 14718,
    "base": "Gl 127.1",
    "lum": 0.000115133047415581,
    "ra": 3.175254,
    "dec": -68.599373,
    "pmra": 61.28,
    "pmdec": -51.42,
    "rv": 0,
    "Asset Name": "ASTRID 000340",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Fire",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000382,
    "y_1": 2.9e-7,
    "z_1": -9.2e-7,
    "Distance in Parsecs_1": 10.1523,
    "HYG Star ID_1": 118198,
    "Gliese Catalog_1": "Gl 127.1B"
  },
  {
    "Astrid #": "ASTRID 000341",
    "HYG Star ID": 58170,
    "Hipparcos Catalogue": 58345,
    "Henry Draper Catalogue": 103932,
    "Gliese Catalog": "Gl 453",
    "Distance in Parsecs": 10.1574,
    "mag": 6.99,
    "Absolute Visual Magnitude": 6.956,
    "Spectral Type": "K4V",
    "Color Index": 1.128,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.992748,
    "y": 0.080959,
    "z": -4.7229,
    "vx": -0.00003051,
    "vy": 0.00005344,
    "vz": -0.00005076,
    "rarad": 3.13259612737881,
    "decrad": -0.483579129717957,
    "pmrarad": -0.00000523424242075,
    "pmdecrad": -0.000003005796338,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 58170,
    "lum": 0.143747400587436,
    "ra": 11.965636,
    "dec": -27.707043,
    "pmra": -1079.64,
    "pmdec": -619.99,
    "rv": 49.9,
    "Asset Name": "ASTRID 000341",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Serious Shinobi",
    "Hair": "Prince",
    "Outfit": "Wizard",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00003051,
    "y_1": 0.00005344,
    "z_1": -0.00005076,
    "Distance in Parsecs_1": 10.1574,
    "HYG Star ID_1": 58170,
    "Hipparcos Catalogue_1": 58345,
    "Henry Draper Catalogue_1": 103932,
    "Gliese Catalog_1": "Gl 453"
  },
  {
    "Astrid #": "ASTRID 000342",
    "HYG Star ID": 72665,
    "Hipparcos Catalogue": 72896,
    "Gliese Catalog": "Gl 568A",
    "Distance in Parsecs": 10.1626,
    "mag": 11.53,
    "Absolute Visual Magnitude": 11.495,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.761181,
    "y": -6.408157,
    "z": 4.061269,
    "vx": 0.00000272,
    "vy": 0.00004871,
    "vz": -0.00000996,
    "rarad": 3.90019330285763,
    "decrad": 0.411125675475848,
    "pmrarad": -0.00000329498769852777,
    "pmdecrad": 4.96740097e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72665,
    "base": "Gl 568",
    "lum": 0.00219785987278482,
    "var_min": 11.631,
    "var_max": 10.961,
    "ra": 14.897641,
    "dec": 23.555766,
    "pmra": -679.64,
    "pmdec": 102.46,
    "rv": -35.7,
    "Asset Name": "ASTRID 000342",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "High Fade",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000272,
    "y_1": 0.00004871,
    "z_1": -0.00000996,
    "Distance in Parsecs_1": 10.1626,
    "HYG Star ID_1": 72665,
    "Hipparcos Catalogue_1": 72896,
    "Gliese Catalog_1": "Gl 568A"
  },
  {
    "Astrid #": "ASTRID 000343",
    "HYG Star ID": 119280,
    "Gliese Catalog": "GJ 1235",
    "Distance in Parsecs": 10.1626,
    "mag": 13.38,
    "Absolute Visual Magnitude": 13.345,
    "Spectral Type": "k-m",
    "Color Index": 1.71,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.313201,
    "y": -8.904106,
    "z": 3.621426,
    "vx": -0.00000348,
    "vy": -0.00012375,
    "vz": -0.00003395,
    "rarad": 5.06862898371064,
    "decrad": 0.36417808677705,
    "pmrarad": -0.00000457106578711805,
    "pmdecrad": -0.000007147564459,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119280,
    "lum": 0.000399944749761096,
    "ra": 19.360737,
    "dec": 20.865867,
    "pmra": -942.85,
    "pmdec": -1474.29,
    "rv": 93,
    "Asset Name": "ASTRID 000343",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Little Crown",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000348,
    "y_1": -0.00012375,
    "z_1": -0.00003395,
    "Distance in Parsecs_1": 10.1626,
    "HYG Star ID_1": 119280,
    "Gliese Catalog_1": "GJ 1235"
  },
  {
    "Astrid #": "ASTRID 000344",
    "HYG Star ID": 118590,
    "Gliese Catalog": "NN 3526",
    "Distance in Parsecs": 10.1729,
    "mag": 12.65,
    "Absolute Visual Magnitude": 12.613,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.721109,
    "y": 2.653167,
    "z": 9.436322,
    "vx": 0.00000424,
    "vy": -0.00001868,
    "vz": 0.00000648,
    "rarad": 2.36875017162839,
    "decrad": 1.1879203859751,
    "pmrarad": 0.00000102373256786111,
    "pmdecrad": 0.000001703819502,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118590,
    "lum": 0.00078487410348873,
    "ra": 9.047959,
    "dec": 68.062825,
    "pmra": 211.16,
    "pmdec": 351.44,
    "rv": 0,
    "Asset Name": "ASTRID 000344",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Worried Vamp",
    "Hair": "Shinobi",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000424,
    "y_1": -0.00001868,
    "z_1": 0.00000648,
    "Distance in Parsecs_1": 10.1729,
    "HYG Star ID_1": 118590,
    "Gliese Catalog_1": "NN 3526"
  },
  {
    "Astrid #": "ASTRID 000345",
    "HYG Star ID": 83865,
    "Hipparcos Catalogue": 84123,
    "Gliese Catalog": "Gl 660A",
    "Distance in Parsecs": 10.1843,
    "mag": 11.45,
    "Absolute Visual Magnitude": 11.41,
    "Spectral Type": "G",
    "Color Index": 1.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.121931,
    "y": -9.955354,
    "z": -0.329093,
    "vx": -0.00002571,
    "vy": 0.00000601,
    "vz": -0.00001611,
    "rarad": 4.50238679108278,
    "decrad": -0.0323194007029225,
    "pmrarad": -0.00000259210482445833,
    "pmdecrad": -0.000001582480334,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83865,
    "base": "Gl 660",
    "lum": 0.00237684028662487,
    "var_min": 11.547,
    "var_max": 11.377,
    "ra": 17.197851,
    "dec": -1.851765,
    "pmra": -534.66,
    "pmdec": -326.41,
    "rv": 0,
    "Asset Name": "ASTRID 000345",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Bob",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002571,
    "y_1": 0.00000601,
    "z_1": -0.00001611,
    "Distance in Parsecs_1": 10.1843,
    "HYG Star ID_1": 83865,
    "Hipparcos Catalogue_1": 84123,
    "Gliese Catalog_1": "Gl 660A"
  },
  {
    "Astrid #": "ASTRID 000346",
    "HYG Star ID": 119484,
    "Gliese Catalog": "Gl 852A",
    "Distance in Parsecs": 10.1937,
    "mag": 13.4,
    "Absolute Visual Magnitude": 13.358,
    "Spectral Type": "dM4.5e",
    "Color Index": 1.7,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.081708,
    "y": -4.365172,
    "z": -1.560666,
    "vx": 0.0000376,
    "vy": -0.000043,
    "vz": -0.00002174,
    "rarad": 5.83513405964124,
    "decrad": -0.15366358032415,
    "pmrarad": -0.0000022047871750625,
    "pmdecrad": -0.000001319546483,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119484,
    "base": "Gl 852",
    "lum": 0.000395184588928436,
    "ra": 22.28857,
    "dec": -8.804275,
    "pmra": -454.77,
    "pmdec": -272.18,
    "rv": 54,
    "Asset Name": "ASTRID 000346",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Prince",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000376,
    "y_1": -0.000043,
    "z_1": -0.00002174,
    "Distance in Parsecs_1": 10.1937,
    "HYG Star ID_1": 119484,
    "Gliese Catalog_1": "Gl 852A"
  },
  {
    "Astrid #": "ASTRID 000347",
    "HYG Star ID": 119485,
    "Gliese Catalog": "Gl 852B",
    "Distance in Parsecs": 10.1937,
    "mag": 14.4,
    "Absolute Visual Magnitude": 14.358,
    "Spectral Type": "dM5  e",
    "Color Index": 1.9,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.079249,
    "y": -4.36399,
    "z": -1.560244,
    "vx": -0.00001159,
    "vy": -0.00001936,
    "vz": -0.00001329,
    "rarad": 5.83513405964124,
    "decrad": -0.15366358032415,
    "pmrarad": -0.0000022047871750625,
    "pmdecrad": -0.000001319546483,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119484,
    "base": "Gl 852",
    "lum": 0.000157325818544646,
    "ra": 22.28857,
    "dec": -8.804275,
    "pmra": -454.77,
    "pmdec": -272.18,
    "rv": 0,
    "Asset Name": "ASTRID 000347",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Little Crown",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001159,
    "y_1": -0.00001936,
    "z_1": -0.00001329,
    "Distance in Parsecs_1": 10.1937,
    "HYG Star ID_1": 119485,
    "Gliese Catalog_1": "Gl 852B"
  },
  {
    "Astrid #": "ASTRID 000348",
    "HYG Star ID": 29198,
    "Hipparcos Catalogue": 29271,
    "Henry Draper Catalogue": 43834,
    "Harvard Revised Catalogue": 2261,
    "Gliese Catalog": "Gl 231",
    "Distance in Parsecs": 10.1978,
    "mag": 5.08,
    "Absolute Visual Magnitude": 5.037,
    "Spectral Type": "G5V",
    "Color Index": 0.714,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.119802,
    "y": 2.679222,
    "z": -9.839152,
    "vx": -0.00000598,
    "vy": -0.00000103,
    "vz": -0.0000372,
    "rarad": 1.61546761759864,
    "decrad": -1.30468676207914,
    "pmrarad": 5.90696988388888e-7,
    "pmdecrad": -0.000001031780474,
    "bayer": "Alp",
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 29198,
    "lum": 0.841782707660812,
    "bf": "Alp Men",
    "ra": 6.170632,
    "dec": -74.753045,
    "pmra": 121.84,
    "pmdec": -212.82,
    "rv": 34.9,
    "Asset Name": "ASTRID 000348",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Round Brush",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": -0.00000598,
    "y_1": -0.00000103,
    "z_1": -0.0000372,
    "Distance in Parsecs_1": 10.1978,
    "HYG Star ID_1": 29198,
    "Hipparcos Catalogue_1": 29271,
    "Henry Draper Catalogue_1": 43834,
    "Harvard Revised Catalogue_1": 2261,
    "Gliese Catalog_1": "Gl 231"
  },
  {
    "Astrid #": "ASTRID 000349",
    "HYG Star ID": 118646,
    "Gliese Catalog": "GJ 1129",
    "Distance in Parsecs": 10.2041,
    "mag": 12.6,
    "Absolute Visual Magnitude": 12.556,
    "Spectral Type": "m",
    "Color Index": 1.59,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.054933,
    "y": 5.392542,
    "z": -3.189296,
    "vx": 0.0000395,
    "vy": 0.00007005,
    "vz": -0.00001088,
    "rarad": 2.55167188254343,
    "decrad": -0.31786196051985,
    "pmrarad": -0.00000785916913138194,
    "pmdecrad": -8.26030397e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118646,
    "lum": 0.000827179951864132,
    "ra": 9.746669,
    "dec": -18.212149,
    "pmra": -1621.07,
    "pmdec": -170.38,
    "rv": 9,
    "Asset Name": "ASTRID 000349",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Naughty Dreams",
    "Hair": "Side Part",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000395,
    "y_1": 0.00007005,
    "z_1": -0.00001088,
    "Distance in Parsecs_1": 10.2041,
    "HYG Star ID_1": 118646,
    "Gliese Catalog_1": "GJ 1129"
  },
  {
    "Astrid #": "ASTRID 000350",
    "HYG Star ID": 118652,
    "Gliese Catalog": "NN 3571",
    "Distance in Parsecs": 10.2041,
    "mag": 14.05,
    "Absolute Visual Magnitude": 14.006,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.123758,
    "y": 4.982052,
    "z": 3.647928,
    "vx": 0.00001402,
    "vy": 0.00000978,
    "vz": 0.00001788,
    "rarad": 2.59147878417775,
    "decrad": 0.365585633054599,
    "pmrarad": -0.00000153535644495138,
    "pmdecrad": 0.000001875841092,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118652,
    "lum": 0.000217570495092579,
    "ra": 9.89872,
    "dec": 20.946514,
    "pmra": -316.69,
    "pmdec": 386.92,
    "rv": 0,
    "Asset Name": "ASTRID 000350",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Slick",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001402,
    "y_1": 0.00000978,
    "z_1": 0.00001788,
    "Distance in Parsecs_1": 10.2041,
    "HYG Star ID_1": 118652,
    "Gliese Catalog_1": "NN 3571"
  },
  {
    "Astrid #": "ASTRID 000351",
    "HYG Star ID": 62361,
    "Hipparcos Catalogue": 62556,
    "Gliese Catalog": "Gl 487",
    "Distance in Parsecs": 10.2135,
    "mag": 10.94,
    "Absolute Visual Magnitude": 10.894,
    "Spectral Type": "M4",
    "Color Index": 1.626,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.04189,
    "y": -0.878428,
    "z": 9.338443,
    "vx": -0.00000806,
    "vy": 0.00002039,
    "vz": -0.00000504,
    "rarad": 3.35562297063373,
    "decrad": 1.15384033265827,
    "pmrarad": -0.00000211844185855555,
    "pmdecrad": -5.17296197e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 62361,
    "lum": 0.00382296216565919,
    "var": "DP",
    "var_min": 11.007,
    "var_max": 10.857,
    "ra": 12.817536,
    "dec": 66.110181,
    "pmra": -436.96,
    "pmdec": -106.7,
    "rv": -3.1,
    "Asset Name": "ASTRID 000351",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Windy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000806,
    "y_1": 0.00002039,
    "z_1": -0.00000504,
    "Distance in Parsecs_1": 10.2135,
    "HYG Star ID_1": 62361,
    "Hipparcos Catalogue_1": 62556,
    "Gliese Catalog_1": "Gl 487"
  },
  {
    "Astrid #": "ASTRID 000352",
    "HYG Star ID": 94054,
    "Hipparcos Catalogue": 94349,
    "Gliese Catalog": "Gl 748",
    "Distance in Parsecs": 10.2208,
    "mag": 11.09,
    "Absolute Visual Magnitude": 11.043,
    "Spectral Type": "M4",
    "Color Index": 1.542,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.16458,
    "y": -9.70451,
    "z": 0.514659,
    "vx": 0.0000712,
    "vy": 0.00006761,
    "vz": -0.00002793,
    "rarad": 5.0276096918847,
    "decrad": 0.050377254008055,
    "pmrarad": 0.00000867404396565972,
    "pmdecrad": -0.000002521370508,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 94054,
    "lum": 0.00333272899576137,
    "var_min": 11.148,
    "var_max": 11.028,
    "ra": 19.204054,
    "dec": 2.886404,
    "pmra": 1789.15,
    "pmdec": -520.07,
    "rv": -42.6,
    "Asset Name": "ASTRID 000352",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Fedora",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.0000712,
    "y_1": 0.00006761,
    "z_1": -0.00002793,
    "Distance in Parsecs_1": 10.2208,
    "HYG Star ID_1": 94054,
    "Hipparcos Catalogue_1": 94349,
    "Gliese Catalog_1": "Gl 748"
  },
  {
    "Astrid #": "ASTRID 000353",
    "HYG Star ID": 118992,
    "Gliese Catalog": "Gl 568B",
    "Distance in Parsecs": 10.2218,
    "mag": 12.7,
    "Absolute Visual Magnitude": 12.652,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.800458,
    "y": -6.446013,
    "z": 4.085082,
    "vx": -0.00002176,
    "vy": 0.00002595,
    "vz": 0.00000474,
    "rarad": 3.90025451199052,
    "decrad": 0.4111285387595,
    "pmrarad": -0.00000330672018959722,
    "pmdecrad": 5.0599519e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 72665,
    "base": "Gl 568",
    "lum": 0.000757181509714705,
    "ra": 14.897875,
    "dec": 23.55593,
    "pmra": -682.06,
    "pmdec": 104.37,
    "rv": 0,
    "Asset Name": "ASTRID 000353",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Ponytail",
    "Outfit": "Astro",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002176,
    "y_1": 0.00002595,
    "z_1": 0.00000474,
    "Distance in Parsecs_1": 10.2218,
    "HYG Star ID_1": 118992,
    "Gliese Catalog_1": "Gl 568B"
  },
  {
    "Astrid #": "ASTRID 000354",
    "HYG Star ID": 119363,
    "Gliese Catalog": "Gl 799B",
    "Distance in Parsecs": 10.2249,
    "mag": 11,
    "Absolute Visual Magnitude": 10.952,
    "Spectral Type": "dM4.5e",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.600417,
    "y": -6.565364,
    "z": -5.484229,
    "vx": 0.0000028,
    "vy": 0.00001712,
    "vz": -0.00001191,
    "rarad": 5.41862445798738,
    "decrad": -0.5661280236936,
    "pmrarad": 0.00000129517974760416,
    "pmdecrad": -0.000001571193872,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 2,
    "comp_primary": 101810,
    "base": "Gl 799",
    "lum": 0.00362409855899743,
    "ra": 20.697621,
    "dec": -32.436746,
    "pmra": 267.15,
    "pmdec": -324.08,
    "rv": -3,
    "Asset Name": "ASTRID 000354",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Elegant",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": 0.0000028,
    "y_1": 0.00001712,
    "z_1": -0.00001191,
    "Distance in Parsecs_1": 10.2249,
    "HYG Star ID_1": 119363,
    "Gliese Catalog_1": "Gl 799B"
  },
  {
    "Astrid #": "ASTRID 000355",
    "HYG Star ID": 14718,
    "Hipparcos Catalogue": 14754,
    "Gliese Catalog": "Gl 127.1A",
    "Distance in Parsecs": 10.2396,
    "mag": 11.39,
    "Absolute Visual Magnitude": 11.339,
    "Spectral Type": "DA",
    "Color Index": 0.025,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.517808,
    "y": 2.760182,
    "z": -9.533701,
    "vx": -0.00000478,
    "vy": -0.00000216,
    "vz": -0.00000189,
    "rarad": 0.83128434917552,
    "decrad": -1.19731229674345,
    "pmrarad": 2.02991488048611e-7,
    "pmdecrad": -5.04884966e-7,
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 14718,
    "base": "Gl 127.1",
    "lum": 0.00253746464586496,
    "var_min": 11.456,
    "var_max": 11.326,
    "ra": 3.175272,
    "dec": -68.600941,
    "pmra": 41.87,
    "pmdec": -104.14,
    "rv": 0,
    "Asset Name": "ASTRID 000355",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Stunned",
    "Hair": "Audiohead",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": -0.00000478,
    "y_1": -0.00000216,
    "z_1": -0.00000189,
    "Distance in Parsecs_1": 10.2396,
    "HYG Star ID_1": 14718,
    "Hipparcos Catalogue_1": 14754,
    "Gliese Catalog_1": "Gl 127.1A"
  },
  {
    "Astrid #": "ASTRID 000356",
    "HYG Star ID": 66953,
    "Hipparcos Catalogue": 67164,
    "Gliese Catalog": "NN 3804",
    "Distance in Parsecs": 10.2438,
    "mag": 11.81,
    "Absolute Visual Magnitude": 11.758,
    "Spectral Type": "M:",
    "Color Index": 1.542,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.723342,
    "y": -4.341944,
    "z": -3.160104,
    "vx": 6.4e-7,
    "vy": 0.0000176,
    "vz": -0.00002595,
    "rarad": 3.60343013755499,
    "decrad": -0.313604572084597,
    "pmrarad": -0.00000151067942861111,
    "pmdecrad": -0.000002663323954,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66953,
    "lum": 0.00172504329724584,
    "var_min": 11.933,
    "var_max": 11.563,
    "ra": 13.764089,
    "dec": -17.968218,
    "pmra": -311.6,
    "pmdec": -549.35,
    "rv": 0,
    "Asset Name": "ASTRID 000356",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Braided Bun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 6.4e-7,
    "y_1": 0.0000176,
    "z_1": -0.00002595,
    "Distance in Parsecs_1": 10.2438,
    "HYG Star ID_1": 66953,
    "Hipparcos Catalogue_1": 67164,
    "Gliese Catalog_1": "NN 3804"
  },
  {
    "Astrid #": "ASTRID 000357",
    "HYG Star ID": 108437,
    "Hipparcos Catalogue": 108782,
    "Henry Draper Catalogue": 209290,
    "Gliese Catalog": "Gl 846",
    "Distance in Parsecs": 10.2449,
    "mag": 9.17,
    "Absolute Visual Magnitude": 9.117,
    "Spectral Type": "M0",
    "Color Index": 1.453,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.917954,
    "y": -5.036744,
    "z": 0.25035,
    "vx": 0.00000565,
    "vy": -0.00002915,
    "vz": -0.00001346,
    "rarad": 5.76906028796164,
    "decrad": 0.024438575368985,
    "pmrarad": -0.00000220629009747222,
    "pmdecrad": -0.000001359272116,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 108437,
    "lum": 0.0196426464587985,
    "ra": 22.036187,
    "dec": 1.400227,
    "pmra": -455.08,
    "pmdec": -280.37,
    "rv": 18.5,
    "Asset Name": "ASTRID 000357",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Heartbreaker",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000565,
    "y_1": -0.00002915,
    "z_1": -0.00001346,
    "Distance in Parsecs_1": 10.2449,
    "HYG Star ID_1": 108437,
    "Hipparcos Catalogue_1": 108782,
    "Henry Draper Catalogue_1": 209290,
    "Gliese Catalog_1": "Gl 846"
  },
  {
    "Astrid #": "ASTRID 000358",
    "HYG Star ID": 118174,
    "Gliese Catalog": "NN 3182",
    "Distance in Parsecs": 10.3093,
    "mag": 15.32,
    "Absolute Visual Magnitude": 15.254,
    "Spectral Type": "DC9",
    "Color Index": 0.93,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.450787,
    "y": 4.028231,
    "z": 8.38126,
    "vx": 0.00002743,
    "vy": -0.00000378,
    "vz": -0.00001275,
    "rarad": 0.735677695872233,
    "decrad": 0.9492526259868,
    "pmrarad": -0.00000205808255532638,
    "pmdecrad": -0.000002123760264,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118174,
    "lum": 0.0000689286860731844,
    "ra": 2.810082,
    "dec": 54.388169,
    "pmra": -424.51,
    "pmdec": -438.06,
    "rv": 0,
    "Asset Name": "ASTRID 000358",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Pompadour",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002743,
    "y_1": -0.00000378,
    "z_1": -0.00001275,
    "Distance in Parsecs_1": 10.3093,
    "HYG Star ID_1": 118174,
    "Gliese Catalog_1": "NN 3182"
  },
  {
    "Astrid #": "ASTRID 000359",
    "HYG Star ID": 118387,
    "Gliese Catalog": "GJ 1083A",
    "Distance in Parsecs": 10.3413,
    "mag": 14.85,
    "Absolute Visual Magnitude": 14.777,
    "Spectral Type": "m",
    "Color Index": 1.88,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.800655,
    "y": 9.353063,
    "z": 4.338392,
    "vx": -0.00000464,
    "vy": 0.00000767,
    "vz": -0.00001567,
    "rarad": 1.48539845031993,
    "decrad": 0.43291760983955,
    "pmrarad": 5.10266398784722e-7,
    "pmdecrad": -0.000001669063209,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118387,
    "base": "GJ 1083",
    "lum": 0.000106954731056616,
    "ra": 5.673804,
    "dec": 24.804352,
    "pmra": 105.25,
    "pmdec": -344.27,
    "rv": 0,
    "Asset Name": "ASTRID 000359",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Cute Neko",
    "Hair": "Bangs",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000464,
    "y_1": 0.00000767,
    "z_1": -0.00001567,
    "Distance in Parsecs_1": 10.3413,
    "HYG Star ID_1": 118387,
    "Gliese Catalog_1": "GJ 1083A"
  },
  {
    "Astrid #": "ASTRID 000360",
    "HYG Star ID": 118608,
    "Gliese Catalog": "Gl 339.1",
    "Distance in Parsecs": 10.3413,
    "mag": 13.85,
    "Absolute Visual Magnitude": 13.777,
    "Spectral Type": "DXP7",
    "Color Index": 0.34,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.649761,
    "y": 4.043589,
    "z": 8.304915,
    "vx": 9.1e-7,
    "vy": 0.00007007,
    "vz": -0.00003357,
    "rarad": 2.42598541502176,
    "decrad": 0.9324500457173,
    "pmrarad": -0.0000051727195646875,
    "pmdecrad": -0.000005450905654,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118608,
    "lum": 0.00026865813772687,
    "ra": 9.266582,
    "dec": 53.425452,
    "pmra": -1066.95,
    "pmdec": -1124.33,
    "rv": 0,
    "Asset Name": "ASTRID 000360",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Serious Slumber",
    "Hair": "Grecian",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 9.1e-7,
    "y_1": 0.00007007,
    "z_1": -0.00003357,
    "Distance in Parsecs_1": 10.3413,
    "HYG Star ID_1": 118608,
    "Gliese Catalog_1": "Gl 339.1"
  },
  {
    "Astrid #": "ASTRID 000361",
    "HYG Star ID": 82788,
    "Hipparcos Catalogue": 83043,
    "Gliese Catalog": "Gl 649",
    "Distance in Parsecs": 10.3445,
    "mag": 9.7,
    "Absolute Visual Magnitude": 9.626,
    "Spectral Type": "M2",
    "Color Index": 1.522,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.484285,
    "y": -8.980483,
    "z": 4.493186,
    "vx": -0.00000948,
    "vy": -0.00001296,
    "vz": -0.00002103,
    "rarad": 4.44250712279943,
    "decrad": 0.449320358788781,
    "pmrarad": -5.49051493229166e-7,
    "pmdecrad": -0.000002461738425,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 82788,
    "lum": 0.0122913617306016,
    "var_min": 9.764,
    "var_max": 9.634,
    "ra": 16.969127,
    "dec": 25.74416,
    "pmra": -113.25,
    "pmdec": -507.77,
    "rv": 4.3,
    "Asset Name": "ASTRID 000361",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Horns",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000948,
    "y_1": -0.00001296,
    "z_1": -0.00002103,
    "Distance in Parsecs_1": 10.3445,
    "HYG Star ID_1": 82788,
    "Hipparcos Catalogue_1": 83043,
    "Gliese Catalog_1": "Gl 649"
  },
  {
    "Astrid #": "ASTRID 000362",
    "HYG Star ID": 13368,
    "Hipparcos Catalogue": 13402,
    "Henry Draper Catalogue": 17925,
    "Harvard Revised Catalogue": 857,
    "Gliese Catalog": "Gl 117",
    "Distance in Parsecs": 10.352,
    "mag": 6.05,
    "Absolute Visual Magnitude": 5.975,
    "Spectral Type": "K1V",
    "Color Index": 0.862,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.367728,
    "y": 6.902771,
    "z": -2.288171,
    "vx": -0.00000151,
    "vy": 0.00002596,
    "vz": -0.00001353,
    "rarad": 0.752827613302757,
    "decrad": -0.222873559937896,
    "pmrarad": 0.00000193009174365972,
    "pmdecrad": -9.18964331e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 13368,
    "lum": 0.354813389233575,
    "var": "EP",
    "var_min": 6.08,
    "var_max": 6.04,
    "ra": 2.87559,
    "dec": -12.769714,
    "pmra": 398.11,
    "pmdec": -189.55,
    "rv": 18.8,
    "Asset Name": "ASTRID 000362",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Bandana",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000151,
    "y_1": 0.00002596,
    "z_1": -0.00001353,
    "Distance in Parsecs_1": 10.352,
    "HYG Star ID_1": 13368,
    "Hipparcos Catalogue_1": 13402,
    "Henry Draper Catalogue_1": 17925,
    "Harvard Revised Catalogue_1": 857,
    "Gliese Catalog_1": "Gl 117"
  },
  {
    "Astrid #": "ASTRID 000363",
    "HYG Star ID": 118129,
    "Gliese Catalog": "NN 3147",
    "Distance in Parsecs": 10.352,
    "mag": 15.99,
    "Absolute Visual Magnitude": 15.915,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.96777,
    "y": 4.751502,
    "z": 6.003109,
    "vx": -0.00000863,
    "vy": 0.00002621,
    "vz": -0.00001072,
    "rarad": 0.598463310742881,
    "decrad": 0.618604027444,
    "pmrarad": 0.00000256209485763194,
    "pmdecrad": -0.00000127183112,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118129,
    "lum": 0.0000374973002245483,
    "ra": 2.285961,
    "dec": 35.4434,
    "pmra": 528.47,
    "pmdec": -262.33,
    "rv": 0,
    "Asset Name": "ASTRID 000363",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Curly",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000863,
    "y_1": 0.00002621,
    "z_1": -0.00001072,
    "Distance in Parsecs_1": 10.352,
    "HYG Star ID_1": 118129,
    "Gliese Catalog_1": "NN 3147"
  },
  {
    "Astrid #": "ASTRID 000364",
    "HYG Star ID": 118585,
    "Gliese Catalog": "GJ 1119",
    "Distance in Parsecs": 10.352,
    "mag": 13.32,
    "Absolute Visual Magnitude": 13.245,
    "Spectral Type": "m",
    "Color Index": 1.72,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.042879,
    "y": 5.01852,
    "z": 7.519823,
    "vx": 0.00000264,
    "vy": 0.00003035,
    "vz": -0.00001848,
    "rarad": 2.35866692189379,
    "decrad": 0.8130875349915,
    "pmrarad": -0.00000225826212402777,
    "pmdecrad": -0.00000259784017,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118585,
    "lum": 0.000438530697774985,
    "ra": 9.009444,
    "dec": 46.586484,
    "pmra": -465.8,
    "pmdec": -535.84,
    "rv": 0,
    "Asset Name": "ASTRID 000364",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Unkempt",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000264,
    "y_1": 0.00003035,
    "z_1": -0.00001848,
    "Distance in Parsecs_1": 10.352,
    "HYG Star ID_1": 118585,
    "Gliese Catalog_1": "GJ 1119"
  },
  {
    "Astrid #": "ASTRID 000365",
    "HYG Star ID": 56077,
    "Hipparcos Catalogue": 56244,
    "Gliese Catalog": "Gl 431",
    "Distance in Parsecs": 10.3563,
    "mag": 11.55,
    "Absolute Visual Magnitude": 11.474,
    "Spectral Type": "M",
    "Color Index": 1.635,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.750812,
    "y": 0.959396,
    "z": -6.800218,
    "vx": 0.00005853,
    "vy": 0.00002897,
    "vz": 0.00005885,
    "rarad": 3.0184488172103,
    "decrad": -0.716395505429035,
    "pmrarad": -0.00000347049025088888,
    "pmdecrad": 8.27722396e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 56077,
    "lum": 0.00224078402678605,
    "var": "V857",
    "var_min": 11.649,
    "var_max": 11.479,
    "ra": 11.529625,
    "dec": -41.046439,
    "pmra": -715.84,
    "pmdec": 170.73,
    "rv": -78,
    "Asset Name": "ASTRID 000365",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Bob",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00005853,
    "y_1": 0.00002897,
    "z_1": 0.00005885,
    "Distance in Parsecs_1": 10.3563,
    "HYG Star ID_1": 56077,
    "Hipparcos Catalogue_1": 56244,
    "Gliese Catalog_1": "Gl 431"
  },
  {
    "Astrid #": "ASTRID 000366",
    "HYG Star ID": 37718,
    "Hipparcos Catalogue": 37826,
    "Henry Draper Catalogue": 62509,
    "Harvard Revised Catalogue": 2990,
    "Gliese Catalog": "Gl 286",
    "Proper Name": "Pollux",
    "Distance in Parsecs": 10.3584,
    "mag": 1.16,
    "Absolute Visual Magnitude": 1.084,
    "Spectral Type": "K0IIIvar",
    "Color Index": 0.991,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.055465,
    "y": 8.19518,
    "z": 4.867171,
    "vx": 0.00002624,
    "vy": 0.00001782,
    "vz": -3.1e-7,
    "rarad": 2.03032688748121,
    "decrad": 0.489149441745997,
    "pmrarad": -0.00000303343071786805,
    "pmdecrad": -2.22771886e-7,
    "bayer": "Bet",
    "flam": 78,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 37718,
    "lum": 32.0922376804843,
    "bf": "78Bet Gem",
    "ra": 7.755277,
    "dec": 28.026199,
    "pmra": -625.69,
    "pmdec": -45.95,
    "rv": 3.6,
    "Asset Name": "ASTRID 000366",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Guilty Laugh",
    "Hair": "Grecian",
    "Outfit": "Toga",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Pollux",
    "Constellation": "Gemini",
    "x_1": 0.00002624,
    "y_1": 0.00001782,
    "z_1": -3.1e-7,
    "Distance in Parsecs_1": 10.3584,
    "HYG Star ID_1": 37718,
    "Hipparcos Catalogue_1": 37826,
    "Henry Draper Catalogue_1": 62509,
    "Harvard Revised Catalogue_1": 2990,
    "Gliese Catalog_1": "Gl 286"
  },
  {
    "Astrid #": "ASTRID 000367",
    "HYG Star ID": 118882,
    "Gliese Catalog": "Gl 508B",
    "Distance in Parsecs": 10.3724,
    "mag": 9.8,
    "Absolute Visual Magnitude": 9.721,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.552547,
    "y": -2.377192,
    "z": 7.681131,
    "vx": 0.00000142,
    "vy": -0.00000684,
    "vz": -9e-7,
    "rarad": 3.48959872797205,
    "decrad": 0.8338669915861,
    "pmrarad": 6.662794411875e-7,
    "pmdecrad": -1.29508278e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 2,
    "comp_primary": 64821,
    "base": "Gl 508",
    "lum": 0.0112615974690786,
    "ra": 13.329285,
    "dec": 47.777059,
    "pmra": 137.43,
    "pmdec": -26.71,
    "rv": 0,
    "Asset Name": "ASTRID 000367",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Determined Shinobi",
    "Hair": "Bandana",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00000142,
    "y_1": -0.00000684,
    "z_1": -9e-7,
    "Distance in Parsecs_1": 10.3724,
    "HYG Star ID_1": 118882,
    "Gliese Catalog_1": "Gl 508B"
  },
  {
    "Astrid #": "ASTRID 000368",
    "HYG Star ID": 118699,
    "Gliese Catalog": "GJ 1134",
    "Distance in Parsecs": 10.395,
    "mag": 12.98,
    "Absolute Visual Magnitude": 12.896,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.758437,
    "y": 2.761043,
    "z": 6.344286,
    "vx": 0.00000649,
    "vy": 0.00007556,
    "vz": -0.00000814,
    "rarad": 2.79978502167207,
    "decrad": 0.6564277896344,
    "pmrarad": -0.00000705922655846527,
    "pmdecrad": -0.000001746987312,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118699,
    "lum": 0.000604783591881799,
    "ra": 10.694391,
    "dec": 37.610542,
    "pmra": -1456.07,
    "pmdec": -360.34,
    "rv": 10,
    "Asset Name": "ASTRID 000368",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Honest Lad",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000649,
    "y_1": 0.00007556,
    "z_1": -0.00000814,
    "Distance in Parsecs_1": 10.395,
    "HYG Star ID_1": 118699,
    "Gliese Catalog_1": "GJ 1134"
  },
  {
    "Astrid #": "ASTRID 000369",
    "HYG Star ID": 118784,
    "Gliese Catalog": "Gl 452.1",
    "Distance in Parsecs": 10.4058,
    "mag": 12.77,
    "Absolute Visual Magnitude": 12.684,
    "Spectral Type": "M3.5",
    "Color Index": 1.69,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.250419,
    "y": 0.262174,
    "z": 1.772248,
    "vx": -0.00000678,
    "vy": -0.00000401,
    "vz": -0.00003855,
    "rarad": 3.11602094910299,
    "decrad": 0.1711477093756,
    "pmrarad": 4.018620598125e-7,
    "pmdecrad": -0.000003760132488,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118784,
    "lum": 0.000735190692071566,
    "ra": 11.902323,
    "dec": 9.806041,
    "pmra": 82.89,
    "pmdec": -775.58,
    "rv": 0,
    "Asset Name": "ASTRID 000369",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Fired Up",
    "Hair": "Slick",
    "Outfit": "Turtle School",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000678,
    "y_1": -0.00000401,
    "z_1": -0.00003855,
    "Distance in Parsecs_1": 10.4058,
    "HYG Star ID_1": 118784,
    "Gliese Catalog_1": "Gl 452.1"
  },
  {
    "Astrid #": "ASTRID 000370",
    "HYG Star ID": 10255,
    "Hipparcos Catalogue": 10279,
    "Gliese Catalog": "Gl 87",
    "Distance in Parsecs": 10.4145,
    "mag": 10.04,
    "Absolute Visual Magnitude": 9.952,
    "Spectral Type": "M3V:",
    "Color Index": 1.431,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.708661,
    "y": 5.674387,
    "z": 0.649508,
    "vx": 0.00005113,
    "vy": -0.0000728,
    "vz": -0.00009353,
    "rarad": 0.577485102302513,
    "decrad": 0.0624063381576849,
    "pmrarad": -0.00000853790828415972,
    "pmdecrad": -0.000008982724836,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10255,
    "lum": 0.00910332399679908,
    "ra": 2.205831,
    "dec": 3.57562,
    "pmra": -1761.07,
    "pmdec": -1852.82,
    "rv": -2.7,
    "Asset Name": "ASTRID 000370",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Spiky",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00005113,
    "y_1": -0.0000728,
    "z_1": -0.00009353,
    "Distance in Parsecs_1": 10.4145,
    "HYG Star ID_1": 10255,
    "Hipparcos Catalogue_1": 10279,
    "Gliese Catalog_1": "Gl 87"
  },
  {
    "Astrid #": "ASTRID 000371",
    "HYG Star ID": 118742,
    "Hipparcos Catalogue": 55203,
    "Henry Draper Catalogue": 98231,
    "Harvard Revised Catalogue": 4375,
    "Gliese Catalog": "Gl 423A",
    "Distance in Parsecs": 10.4167,
    "mag": 4.33,
    "Absolute Visual Magnitude": 4.241,
    "Spectral Type": "G0 Ve",
    "Color Index": 0.59,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.73089,
    "y": 1.610966,
    "z": 5.446747,
    "vx": 0.00000243,
    "vy": 0.00002139,
    "vz": -0.00003351,
    "rarad": 2.95914941591524,
    "decrad": 0.55028106395935,
    "pmrarad": -0.00000206162169519444,
    "pmdecrad": -0.000002816820813,
    "bayer": "Xi",
    "flam": 53,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 118742,
    "base": "Gl 423",
    "lum": 1.7522658618857,
    "bf": "53Xi  UMa",
    "ra": 11.303118,
    "dec": 31.528783,
    "pmra": -425.24,
    "pmdec": -581.01,
    "rv": -15.9,
    "Asset Name": "ASTRID 000371",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Neko Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000243,
    "y_1": 0.00002139,
    "z_1": -0.00003351,
    "Distance in Parsecs_1": 10.4167,
    "HYG Star ID_1": 118742,
    "Hipparcos Catalogue_1": 55203,
    "Henry Draper Catalogue_1": 98231,
    "Harvard Revised Catalogue_1": 4375,
    "Gliese Catalog_1": "Gl 423A"
  },
  {
    "Astrid #": "ASTRID 000372",
    "HYG Star ID": 118743,
    "Henry Draper Catalogue": 98230,
    "Harvard Revised Catalogue": 4374,
    "Gliese Catalog": "Gl 423B",
    "Distance in Parsecs": 10.4167,
    "mag": 4.8,
    "Absolute Visual Magnitude": 4.711,
    "Spectral Type": "G0 Ve",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.730989,
    "y": 1.610983,
    "z": 5.446808,
    "vx": 4.6e-7,
    "vy": 0.00002175,
    "vz": -0.00003228,
    "rarad": 2.95914941591524,
    "decrad": 0.55028106395935,
    "pmrarad": -0.00000206162169519444,
    "pmdecrad": -0.000002816820813,
    "bayer": "Xi",
    "flam": 53,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 118742,
    "base": "Gl 423",
    "lum": 1.13657997475015,
    "bf": "53Xi  UMa",
    "ra": 11.303118,
    "dec": 31.528783,
    "pmra": -425.24,
    "pmdec": -581.01,
    "rv": -13.6,
    "Asset Name": "ASTRID 000372",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Prince",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 4.6e-7,
    "y_1": 0.00002175,
    "z_1": -0.00003228,
    "Distance in Parsecs_1": 10.4167,
    "HYG Star ID_1": 118743,
    "Henry Draper Catalogue_1": 98230,
    "Harvard Revised Catalogue_1": 4374,
    "Gliese Catalog_1": "Gl 423B"
  },
  {
    "Astrid #": "ASTRID 000373",
    "HYG Star ID": 119234,
    "Gliese Catalog": "NN 4071",
    "Distance in Parsecs": 10.4167,
    "mag": 12.81,
    "Absolute Visual Magnitude": 12.721,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.874688,
    "y": -9.936221,
    "z": 2.502937,
    "vx": -0.00000283,
    "vy": 0.00000326,
    "vz": 0.00001505,
    "rarad": 4.89886941456891,
    "decrad": 0.242655588779,
    "pmrarad": -2.09148621791666e-7,
    "pmdecrad": 0.00000148829558,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119234,
    "lum": 0.000710558763369472,
    "ra": 18.712303,
    "dec": 13.903141,
    "pmra": -43.14,
    "pmdec": 306.98,
    "rv": 0,
    "Asset Name": "ASTRID 000373",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Innocent",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000283,
    "y_1": 0.00000326,
    "z_1": 0.00001505,
    "Distance in Parsecs_1": 10.4167,
    "HYG Star ID_1": 119234,
    "Gliese Catalog_1": "NN 4071"
  },
  {
    "Astrid #": "ASTRID 000374",
    "HYG Star ID": 76665,
    "Hipparcos Catalogue": 76901,
    "Gliese Catalog": "Gl 595",
    "Distance in Parsecs": 10.4275,
    "mag": 11.83,
    "Absolute Visual Magnitude": 11.739,
    "Spectral Type": "M5",
    "Color Index": 1.591,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.564952,
    "y": -8.105292,
    "z": -3.476175,
    "vx": -0.00012008,
    "vy": 0.00000659,
    "vz": -0.00007784,
    "rarad": 4.11073011579493,
    "decrad": -0.339846530531172,
    "pmrarad": -0.00000985248057898611,
    "pmdecrad": -0.000005039832134,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 76665,
    "lum": 0.00175549662962123,
    "var_min": 11.936,
    "var_max": 11.616,
    "ra": 15.701832,
    "dec": -19.471772,
    "pmra": -2032.22,
    "pmdec": -1039.54,
    "rv": 83,
    "Asset Name": "ASTRID 000374",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Sweetheart",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00012008,
    "y_1": 0.00000659,
    "z_1": -0.00007784,
    "Distance in Parsecs_1": 10.4275,
    "HYG Star ID_1": 76665,
    "Hipparcos Catalogue_1": 76901,
    "Gliese Catalog_1": "Gl 595"
  },
  {
    "Astrid #": "ASTRID 000375",
    "HYG Star ID": 118312,
    "Gliese Catalog": "Gl 170",
    "Distance in Parsecs": 10.4275,
    "mag": 13.91,
    "Absolute Visual Magnitude": 13.819,
    "Spectral Type": "M4.5",
    "Color Index": 1.73,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.050171,
    "y": 7.401475,
    "z": 6.68187,
    "vx": -0.0000053,
    "vy": 0.0000205,
    "vz": -0.00002028,
    "rarad": 1.17988160116846,
    "decrad": 0.695530811367549,
    "pmrarad": 0.00000121940336933333,
    "pmdecrad": -0.000002533752649,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118312,
    "lum": 0.000258463963576984,
    "ra": 4.506816,
    "dec": 39.85098,
    "pmra": 251.52,
    "pmdec": -522.62,
    "rv": 0,
    "Asset Name": "ASTRID 000375",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Tiny Crown",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000053,
    "y_1": 0.0000205,
    "z_1": -0.00002028,
    "Distance in Parsecs_1": 10.4275,
    "HYG Star ID_1": 118312,
    "Gliese Catalog_1": "Gl 170"
  },
  {
    "Astrid #": "ASTRID 000376",
    "HYG Star ID": 119480,
    "Gliese Catalog": "GJ 1265",
    "Distance in Parsecs": 10.4384,
    "mag": 13.57,
    "Absolute Visual Magnitude": 13.477,
    "Spectral Type": "m",
    "Color Index": 1.73,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.895466,
    "y": -4.44933,
    "z": -3.171748,
    "vx": 0.00003496,
    "vy": 0.00002877,
    "vz": -0.00002314,
    "rarad": 5.81938435652888,
    "decrad": -0.30869771161045,
    "pmrarad": 0.00000396403057769444,
    "pmdecrad": -0.000001577472209,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119480,
    "lum": 0.000354160400426461,
    "ra": 22.228411,
    "dec": -17.687076,
    "pmra": 817.64,
    "pmdec": -325.38,
    "rv": 24,
    "Asset Name": "ASTRID 000376",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Elegant",
    "Outfit": "Viking",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003496,
    "y_1": 0.00002877,
    "z_1": -0.00002314,
    "Distance in Parsecs_1": 10.4384,
    "HYG Star ID_1": 119480,
    "Gliese Catalog_1": "GJ 1265"
  },
  {
    "Astrid #": "ASTRID 000377",
    "HYG Star ID": 45776,
    "Hipparcos Catalogue": 45908,
    "Henry Draper Catalogue": 304636,
    "Gliese Catalog": "Gl 341",
    "Distance in Parsecs": 10.4624,
    "mag": 9.49,
    "Absolute Visual Magnitude": 9.392,
    "Spectral Type": "M0V",
    "Color Index": 1.472,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.996814,
    "y": 3.305667,
    "z": -9.086618,
    "vx": 0.00000678,
    "vy": 0.00004958,
    "vz": -0.00002758,
    "rarad": 2.45059508267469,
    "decrad": -1.05211855488741,
    "pmrarad": -0.00000406506574872222,
    "pmdecrad": 8.8207001e-7,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 45776,
    "lum": 0.0152475476765991,
    "ra": 9.360584,
    "dec": -60.281953,
    "pmra": -838.48,
    "pmdec": 181.94,
    "rv": 36.2,
    "Asset Name": "ASTRID 000377",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Mortified",
    "Hair": "Wavy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00000678,
    "y_1": 0.00004958,
    "z_1": -0.00002758,
    "Distance in Parsecs_1": 10.4624,
    "HYG Star ID_1": 45776,
    "Hipparcos Catalogue_1": 45908,
    "Henry Draper Catalogue_1": 304636,
    "Gliese Catalog_1": "Gl 341"
  },
  {
    "Astrid #": "ASTRID 000378",
    "HYG Star ID": 33056,
    "Hipparcos Catalogue": 33142,
    "Gliese Catalog": "NN 3412A",
    "Distance in Parsecs": 10.4789,
    "mag": 11.01,
    "Absolute Visual Magnitude": 10.908,
    "Spectral Type": "M:",
    "Color Index": 1.552,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.192279,
    "y": 4.95947,
    "z": 9.15366,
    "vx": -0.00003469,
    "vy": 0.00003691,
    "vz": -0.00002451,
    "rarad": 1.80670166567664,
    "decrad": 1.06241270132209,
    "pmrarad": 0.00000239619161614583,
    "pmdecrad": -0.000004806248903,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 33056,
    "base": "NN 3412",
    "lum": 0.00377398352536585,
    "var_min": 11.064,
    "var_max": 10.944,
    "ra": 6.901092,
    "dec": 60.871764,
    "pmra": 494.25,
    "pmdec": -991.36,
    "rv": 0,
    "Asset Name": "ASTRID 000378",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Battle Ready",
    "Hair": "Goddess",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00003469,
    "y_1": 0.00003691,
    "z_1": -0.00002451,
    "Distance in Parsecs_1": 10.4789,
    "HYG Star ID_1": 33056,
    "Hipparcos Catalogue_1": 33142,
    "Gliese Catalog_1": "NN 3412A"
  },
  {
    "Astrid #": "ASTRID 000379",
    "HYG Star ID": 118099,
    "Gliese Catalog": "NN 3125",
    "Distance in Parsecs": 10.5263,
    "mag": 14.12,
    "Absolute Visual Magnitude": 14.009,
    "Spectral Type": "m",
    "Color Index": 1.9,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.570337,
    "y": 1.51187,
    "z": 10.095079,
    "vx": -0.00000461,
    "vy": 0.00001509,
    "vz": -0.00000108,
    "rarad": 0.531512966553487,
    "decrad": 1.28357200069905,
    "pmrarad": 0.00000145827106974305,
    "pmdecrad": -3.63586019e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118099,
    "lum": 0.000216970155380084,
    "ra": 2.03023,
    "dec": 73.543258,
    "pmra": 300.79,
    "pmdec": -75,
    "rv": 0,
    "Asset Name": "ASTRID 000379",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Curly",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000461,
    "y_1": 0.00001509,
    "z_1": -0.00000108,
    "Distance in Parsecs_1": 10.5263,
    "HYG Star ID_1": 118099,
    "Gliese Catalog_1": "NN 3125"
  },
  {
    "Astrid #": "ASTRID 000380",
    "HYG Star ID": 118283,
    "Gliese Catalog": "NN 3270",
    "Distance in Parsecs": 10.5263,
    "mag": 13.82,
    "Absolute Visual Magnitude": 13.709,
    "Spectral Type": "dM4  e",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.50653,
    "y": 9.374804,
    "z": 1.614692,
    "vx": -0.00000493,
    "vy": 0.00000537,
    "vz": -0.00001742,
    "rarad": 1.12270181314702,
    "decrad": 0.15400398285965,
    "pmrarad": 6.42862940416666e-7,
    "pmdecrad": -0.000001674662807,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118283,
    "lum": 0.000286022369395428,
    "ra": 4.288405,
    "dec": 8.823778,
    "pmra": 132.6,
    "pmdec": -345.42,
    "rv": 0,
    "Asset Name": "ASTRID 000380",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Neko Dreams",
    "Hair": "Halo Boy",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000493,
    "y_1": 0.00000537,
    "z_1": -0.00001742,
    "Distance in Parsecs_1": 10.5263,
    "HYG Star ID_1": 118283,
    "Gliese Catalog_1": "NN 3270"
  },
  {
    "Astrid #": "ASTRID 000381",
    "HYG Star ID": 118339,
    "Gliese Catalog": "GJ 2036B",
    "Distance in Parsecs": 10.5263,
    "mag": 12.15,
    "Absolute Visual Magnitude": 12.039,
    "Color Index": 1.6,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 1.690648,
    "y": 5.661786,
    "z": -8.711423,
    "vx": -0.00000464,
    "vy": 0.00000434,
    "vz": 0.00000192,
    "rarad": 1.28059709039421,
    "decrad": -0.97479423089245,
    "pmrarad": 5.40227884243055e-7,
    "pmdecrad": 3.24602151e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 22686,
    "base": "GJ 2036",
    "lum": 0.00133168037626997,
    "ra": 4.891521,
    "dec": -55.851595,
    "pmra": 111.43,
    "pmdec": 66.95,
    "rv": 0,
    "Asset Name": "ASTRID 000381",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Antlers",
    "Outfit": "Toga",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000464,
    "y_1": 0.00000434,
    "z_1": 0.00000192,
    "Distance in Parsecs_1": 10.5263,
    "HYG Star ID_1": 118339,
    "Gliese Catalog_1": "GJ 2036B"
  },
  {
    "Astrid #": "ASTRID 000382",
    "HYG Star ID": 118972,
    "Gliese Catalog": "NN 3849",
    "Distance in Parsecs": 10.5263,
    "mag": 19.74,
    "Absolute Visual Magnitude": 19.629,
    "Spectral Type": "dM9",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -7.018974,
    "y": -5.323617,
    "z": 5.759645,
    "vx": -0.00001255,
    "vy": 0.0000131,
    "vz": -0.00004241,
    "rarad": 3.79050342590401,
    "decrad": 0.57904270391565,
    "pmrarad": -0.00000171236191972222,
    "pmdecrad": -0.000003480021687,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118972,
    "lum": 0.0000012257446320498,
    "ra": 14.478657,
    "dec": 33.176703,
    "pmra": -353.2,
    "pmdec": -717.81,
    "rv": -21,
    "Asset Name": "ASTRID 000382",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Greasy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001255,
    "y_1": 0.0000131,
    "z_1": -0.00004241,
    "Distance in Parsecs_1": 10.5263,
    "HYG Star ID_1": 118972,
    "Gliese Catalog_1": "NN 3849"
  },
  {
    "Astrid #": "ASTRID 000383",
    "HYG Star ID": 118633,
    "Gliese Catalog": "Gl 352B",
    "Distance in Parsecs": 10.5319,
    "mag": 10.8,
    "Absolute Visual Magnitude": 10.687,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -8.160985,
    "y": 6.187518,
    "z": -2.456396,
    "vx": -0.00002301,
    "vy": -0.00002918,
    "vz": 0.00000291,
    "rarad": 2.49287006533732,
    "decrad": -0.23540200829155,
    "pmrarad": 0.0000035277467465625,
    "pmdecrad": 2.83834169e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 2,
    "comp_primary": 46576,
    "base": "Gl 352",
    "lum": 0.0046259400476616,
    "ra": 9.522062,
    "dec": -13.487542,
    "pmra": 727.65,
    "pmdec": 58.55,
    "rv": 0,
    "Asset Name": "ASTRID 000383",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Ram Horns",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00002301,
    "y_1": -0.00002918,
    "z_1": 0.00000291,
    "Distance in Parsecs_1": 10.5319,
    "HYG Star ID_1": 118633,
    "Gliese Catalog_1": "Gl 352B"
  },
  {
    "Astrid #": "ASTRID 000384",
    "HYG Star ID": 14596,
    "Hipparcos Catalogue": 14632,
    "Henry Draper Catalogue": 19373,
    "Harvard Revised Catalogue": 937,
    "Gliese Catalog": "Gl 124",
    "Distance in Parsecs": 10.5407,
    "mag": 4.05,
    "Absolute Visual Magnitude": 3.936,
    "Spectral Type": "G0V",
    "Color Index": 0.595,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.634786,
    "y": 5.016825,
    "z": 8.029067,
    "vx": -0.0000227,
    "vy": 0.00007048,
    "vz": 0.00003553,
    "rarad": 0.824931292852822,
    "decrad": 0.865915046187841,
    "pmrarad": 0.00000611975460828472,
    "pmdecrad": -4.43749961e-7,
    "bayer": "Iot",
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 14596,
    "lum": 2.32059846159892,
    "bf": "Iot Per",
    "ra": 3.151005,
    "dec": 49.613278,
    "pmra": 1262.29,
    "pmdec": -91.53,
    "rv": 49.5,
    "Asset Name": "ASTRID 000384",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Side Part",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.0000227,
    "y_1": 0.00007048,
    "z_1": 0.00003553,
    "Distance in Parsecs_1": 10.5407,
    "HYG Star ID_1": 14596,
    "Hipparcos Catalogue_1": 14632,
    "Henry Draper Catalogue_1": 19373,
    "Harvard Revised Catalogue_1": 937,
    "Gliese Catalog_1": "Gl 124"
  },
  {
    "Astrid #": "ASTRID 000385",
    "HYG Star ID": 48575,
    "Hipparcos Catalogue": 48714,
    "Gliese Catalog": "Gl 373",
    "Distance in Parsecs": 10.5619,
    "mag": 8.99,
    "Absolute Visual Magnitude": 8.871,
    "Spectral Type": "M2",
    "Color Index": 1.438,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.141492,
    "y": 2.484907,
    "z": 9.393073,
    "vx": -0.00002072,
    "vy": 0.00003062,
    "vz": -1e-7,
    "rarad": 2.60118642220022,
    "decrad": 1.09586541250316,
    "pmrarad": -0.0000014765485455,
    "pmdecrad": -0.000002827142496,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 48575,
    "lum": 0.0246376907672176,
    "ra": 9.9358,
    "dec": 62.788463,
    "pmra": -304.56,
    "pmdec": -583.14,
    "rv": 14.9,
    "Asset Name": "ASTRID 000385",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Charmer",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00002072,
    "y_1": 0.00003062,
    "z_1": -1e-7,
    "Distance in Parsecs_1": 10.5619,
    "HYG Star ID_1": 48575,
    "Hipparcos Catalogue_1": 48714,
    "Gliese Catalog_1": "Gl 373"
  },
  {
    "Astrid #": "ASTRID 000386",
    "HYG Star ID": 62491,
    "Hipparcos Catalogue": 62687,
    "Henry Draper Catalogue": 111631,
    "Gliese Catalog": "Gl 488",
    "Distance in Parsecs": 10.5708,
    "mag": 8.49,
    "Absolute Visual Magnitude": 8.369,
    "Spectral Type": "M0.5V",
    "Color Index": 1.409,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.312056,
    "y": -2.32043,
    "z": -0.141713,
    "vx": -0.00000614,
    "vy": 1.3e-7,
    "vz": -0.00002047,
    "rarad": 3.36292711354216,
    "decrad": -0.013406424273245,
    "pmrarad": -1.39480895895833e-7,
    "pmdecrad": -0.000001928831228,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 62491,
    "lum": 0.039120103938735,
    "ra": 12.845435,
    "dec": -0.768132,
    "pmra": -28.77,
    "pmdec": -397.85,
    "rv": 6.1,
    "Asset Name": "ASTRID 000386",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Infatuated",
    "Hair": "Fire",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000614,
    "y_1": 1.3e-7,
    "z_1": -0.00002047,
    "Distance in Parsecs_1": 10.5708,
    "HYG Star ID_1": 62491,
    "Hipparcos Catalogue_1": 62687,
    "Henry Draper Catalogue_1": 111631,
    "Gliese Catalog_1": "Gl 488"
  },
  {
    "Astrid #": "ASTRID 000387",
    "HYG Star ID": 83342,
    "Hipparcos Catalogue": 83599,
    "Gliese Catalog": "Gl 654",
    "Distance in Parsecs": 10.5719,
    "mag": 10.08,
    "Absolute Visual Magnitude": 9.959,
    "Spectral Type": "M0V",
    "Color Index": 1.476,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.492689,
    "y": -10.231171,
    "z": -0.938748,
    "vx": -0.000053,
    "vy": -0.00001809,
    "vz": -0.00006073,
    "rarad": 4.47340833401749,
    "decrad": -0.088911044867145,
    "pmrarad": -0.00000446605514390972,
    "pmdecrad": -0.000005469813388,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83342,
    "lum": 0.0090448214904759,
    "ra": 17.087161,
    "dec": -5.094228,
    "pmra": -921.19,
    "pmdec": -1128.23,
    "rv": 34.6,
    "Asset Name": "ASTRID 000387",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Heartbreaker",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.000053,
    "y_1": -0.00001809,
    "z_1": -0.00006073,
    "Distance in Parsecs_1": 10.5719,
    "HYG Star ID_1": 83342,
    "Hipparcos Catalogue_1": 83599,
    "Gliese Catalog_1": "Gl 654"
  },
  {
    "Astrid #": "ASTRID 000388",
    "HYG Star ID": 37111,
    "Hipparcos Catalogue": 37217,
    "Gliese Catalog": "NN 3459",
    "Distance in Parsecs": 10.6033,
    "mag": 11.68,
    "Absolute Visual Magnitude": 11.553,
    "Spectral Type": "M2",
    "Color Index": 1.588,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.125626,
    "y": 8.981866,
    "z": -3.838654,
    "vx": -0.00001753,
    "vy": -0.00001777,
    "vz": -0.00002274,
    "rarad": 2.00137920740454,
    "decrad": -0.37043875243378,
    "pmrarad": 0.00000220221766255555,
    "pmdecrad": -0.000002300392432,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 37111,
    "lum": 0.00208353116057174,
    "var_min": 11.768,
    "var_max": 11.578,
    "ra": 7.644705,
    "dec": -21.224577,
    "pmra": 454.24,
    "pmdec": -474.49,
    "rv": 0,
    "Asset Name": "ASTRID 000388",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Angry",
    "Hair": "Charmer",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001753,
    "y_1": -0.00001777,
    "z_1": -0.00002274,
    "Distance in Parsecs_1": 10.6033,
    "HYG Star ID_1": 37111,
    "Hipparcos Catalogue_1": 37217,
    "Gliese Catalog_1": "NN 3459"
  },
  {
    "Astrid #": "ASTRID 000389",
    "HYG Star ID": 82006,
    "Hipparcos Catalogue": 82257,
    "Gliese Catalog": "GJ 1206",
    "Distance in Parsecs": 10.6338,
    "mag": 12.24,
    "Absolute Visual Magnitude": 12.107,
    "Spectral Type": "DA",
    "Color Index": 0.16,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.679959,
    "y": -5.203377,
    "z": 9.120323,
    "vx": 0.00000287,
    "vy": -0.0000145,
    "vz": -0.00000774,
    "rarad": 4.40008881654538,
    "decrad": 1.03072702919255,
    "pmrarad": 6.75490901118055e-7,
    "pmdecrad": -0.000001416092279,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 82006,
    "lum": 0.00125083492837406,
    "var": "DN",
    "var_min": 12.414,
    "var_max": 12.104,
    "ra": 16.807101,
    "dec": 59.056309,
    "pmra": 139.33,
    "pmdec": -292.09,
    "rv": 0,
    "Asset Name": "ASTRID 000389",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Ninja",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000287,
    "y_1": -0.0000145,
    "z_1": -0.00000774,
    "Distance in Parsecs_1": 10.6338,
    "HYG Star ID_1": 82006,
    "Hipparcos Catalogue_1": 82257,
    "Gliese Catalog_1": "GJ 1206"
  },
  {
    "Astrid #": "ASTRID 000390",
    "HYG Star ID": 117964,
    "Gliese Catalog": "NN 3010",
    "Distance in Parsecs": 10.6383,
    "mag": 13.54,
    "Absolute Visual Magnitude": 13.406,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.934814,
    "y": 0.386096,
    "z": 3.784683,
    "vx": 0.00000439,
    "vy": -0.00000283,
    "vz": -0.00001122,
    "rarad": 0.0388444128588534,
    "decrad": 0.363727215719149,
    "pmrarad": -2.81482822930555e-7,
    "pmdecrad": -0.000001128985617,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117964,
    "lum": 0.000378094186683296,
    "ra": 0.148375,
    "dec": 20.840034,
    "pmra": -58.06,
    "pmdec": -232.87,
    "rv": 0,
    "Asset Name": "ASTRID 000390",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Charmer",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000439,
    "y_1": -0.00000283,
    "z_1": -0.00001122,
    "Distance in Parsecs_1": 10.6383,
    "HYG Star ID_1": 117964,
    "Gliese Catalog_1": "NN 3010"
  },
  {
    "Astrid #": "ASTRID 000391",
    "HYG Star ID": 20948,
    "Hipparcos Catalogue": 21000,
    "Distance in Parsecs": 10.6758,
    "mag": 9.83,
    "Absolute Visual Magnitude": 9.688,
    "Spectral Type": "F8+...",
    "Color Index": 0.6,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.059005,
    "y": 9.824722,
    "z": 0.985911,
    "vx": -0.00000533,
    "vy": 0.00000158,
    "vz": 0.00000618,
    "rarad": 1.17901224282596,
    "decrad": 0.0924818206046737,
    "pmrarad": 5.18265824513888e-7,
    "pmdecrad": 5.81097677e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 20948,
    "lum": 0.0116091387012618,
    "ra": 4.503495,
    "dec": 5.298818,
    "pmra": 106.9,
    "pmdec": 119.86,
    "rv": 0,
    "Asset Name": "ASTRID 000391",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Charmer",
    "Hair": "Side Part",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00000533,
    "y_1": 0.00000158,
    "z_1": 0.00000618,
    "Distance in Parsecs_1": 10.6758,
    "HYG Star ID_1": 20948,
    "Hipparcos Catalogue_1": 21000
  },
  {
    "Astrid #": "ASTRID 000392",
    "HYG Star ID": 119266,
    "Gliese Catalog": "GJ 1232",
    "Distance in Parsecs": 10.6838,
    "mag": 13.52,
    "Absolute Visual Magnitude": 13.376,
    "Spectral Type": "k-m",
    "Color Index": 1.85,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.054997,
    "y": -9.710163,
    "z": 3.241942,
    "vx": -0.00002787,
    "vy": -0.00000483,
    "vz": -0.00002863,
    "rarad": 5.01720926541804,
    "decrad": 0.308323912600699,
    "pmrarad": -0.00000262381163916666,
    "pmdecrad": -0.000002446747986,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119266,
    "lum": 0.000388686986137302,
    "ra": 19.164328,
    "dec": 17.665659,
    "pmra": -541.2,
    "pmdec": -504.68,
    "rv": -12,
    "Asset Name": "ASTRID 000392",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Elegant",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002787,
    "y_1": -0.00000483,
    "z_1": -0.00002863,
    "Distance in Parsecs_1": 10.6838,
    "HYG Star ID_1": 119266,
    "Gliese Catalog_1": "GJ 1232"
  },
  {
    "Astrid #": "ASTRID 000393",
    "HYG Star ID": 79513,
    "Hipparcos Catalogue": 79755,
    "Henry Draper Catalogue": 147379,
    "Gliese Catalog": "Gl 617A",
    "Distance in Parsecs": 10.6872,
    "mag": 8.61,
    "Absolute Visual Magnitude": 8.466,
    "Spectral Type": "M0Vvar",
    "Color Index": 1.409,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.800975,
    "y": -3.721866,
    "z": 9.854777,
    "vx": -0.000018,
    "vy": 0.00002202,
    "vz": -0.00001705,
    "rarad": 4.2617454249102,
    "decrad": 1.17353925313239,
    "pmrarad": -0.00000241383883411805,
    "pmdecrad": 4.16357988e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 79513,
    "base": "Gl 617",
    "lum": 0.035776676993611,
    "ra": 16.278668,
    "dec": 67.238846,
    "pmra": -497.89,
    "pmdec": 85.88,
    "rv": -19.9,
    "Asset Name": "ASTRID 000393",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Angry",
    "Hair": "Medium Bob",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.000018,
    "y_1": 0.00002202,
    "z_1": -0.00001705,
    "Distance in Parsecs_1": 10.6872,
    "HYG Star ID_1": 79513,
    "Hipparcos Catalogue_1": 79755,
    "Henry Draper Catalogue_1": 147379,
    "Gliese Catalog_1": "Gl 617A"
  },
  {
    "Astrid #": "ASTRID 000394",
    "HYG Star ID": 101810,
    "Hipparcos Catalogue": 102141,
    "Henry Draper Catalogue": 196982,
    "Gliese Catalog": "Gl 799A",
    "Distance in Parsecs": 10.6952,
    "mag": 10.27,
    "Absolute Visual Magnitude": 10.124,
    "Spectral Type": "Mpe",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.857959,
    "y": -6.867725,
    "z": -5.736307,
    "vx": 0.00000178,
    "vy": 0.00001943,
    "vz": -0.00001381,
    "rarad": 5.41860224487679,
    "decrad": -0.566101564007545,
    "pmrarad": 0.00000130570020447222,
    "pmdecrad": -0.000001772915148,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 101810,
    "base": "Gl 799",
    "lum": 0.00776962395992138,
    "var": "AT",
    "var_min": 10.315,
    "var_max": 10.235,
    "ra": 20.697536,
    "dec": -32.43523,
    "pmra": 269.32,
    "pmdec": -365.69,
    "rv": -4,
    "Asset Name": "ASTRID 000394",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Gimme Blood",
    "Hair": "Audiophile",
    "Outfit": "Toga",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": 0.00000178,
    "y_1": 0.00001943,
    "z_1": -0.00001381,
    "Distance in Parsecs_1": 10.6952,
    "HYG Star ID_1": 101810,
    "Hipparcos Catalogue_1": 102141,
    "Henry Draper Catalogue_1": 196982,
    "Gliese Catalog_1": "Gl 799A"
  },
  {
    "Astrid #": "ASTRID 000395",
    "HYG Star ID": 64821,
    "Hipparcos Catalogue": 65026,
    "Henry Draper Catalogue": 115953,
    "Gliese Catalog": "Gl 508A",
    "Distance in Parsecs": 10.7066,
    "mag": 8.48,
    "Absolute Visual Magnitude": 8.332,
    "Spectral Type": "K0",
    "Color Index": 1.469,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.763524,
    "y": -2.453736,
    "z": 7.928718,
    "vx": 0.00000315,
    "vy": -0.00000403,
    "vz": -0.00000311,
    "rarad": 3.4896135957451,
    "decrad": 0.833883914235356,
    "pmrarad": 4.54028011840277e-7,
    "pmdecrad": -8.5230245e-8,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 64821,
    "base": "Gl 508",
    "lum": 0.0404762248687873,
    "ra": 13.329342,
    "dec": 47.778029,
    "pmra": 93.65,
    "pmdec": -17.58,
    "rv": -3.3,
    "Asset Name": "ASTRID 000395",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Fire",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00000315,
    "y_1": -0.00000403,
    "z_1": -0.00000311,
    "Distance in Parsecs_1": 10.7066,
    "HYG Star ID_1": 64821,
    "Hipparcos Catalogue_1": 65026,
    "Henry Draper Catalogue_1": 115953,
    "Gliese Catalog_1": "Gl 508A"
  },
  {
    "Astrid #": "ASTRID 000396",
    "HYG Star ID": 83334,
    "Hipparcos Catalogue": 83591,
    "Henry Draper Catalogue": 154363,
    "Gliese Catalog": "Gl 653",
    "Distance in Parsecs": 10.7066,
    "mag": 7.7,
    "Absolute Visual Magnitude": 7.552,
    "Spectral Type": "K5V",
    "Color Index": 1.1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.53238,
    "y": -10.360059,
    "z": -0.945549,
    "vx": -0.00005326,
    "vy": -0.00001748,
    "vz": -0.00006192,
    "rarad": 4.47265317970127,
    "decrad": -0.08842723950433,
    "pmrarad": -0.00000444506271154166,
    "pmdecrad": -0.000005516743352,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83334,
    "lum": 0.0830233015924954,
    "ra": 17.084277,
    "dec": -5.066508,
    "pmra": -916.86,
    "pmdec": -1137.91,
    "rv": 34.2,
    "Asset Name": "ASTRID 000396",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Wavy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00005326,
    "y_1": -0.00001748,
    "z_1": -0.00006192,
    "Distance in Parsecs_1": 10.7066,
    "HYG Star ID_1": 83334,
    "Hipparcos Catalogue_1": 83591,
    "Henry Draper Catalogue_1": 154363,
    "Gliese Catalog_1": "Gl 653"
  },
  {
    "Astrid #": "ASTRID 000397",
    "HYG Star ID": 81444,
    "Hipparcos Catalogue": 81693,
    "Henry Draper Catalogue": 150680,
    "Harvard Revised Catalogue": 6212,
    "Gliese Catalog": "Gl 635A",
    "Distance in Parsecs": 10.7158,
    "mag": 2.81,
    "Absolute Visual Magnitude": 2.66,
    "Spectral Type": "F9IV",
    "Color Index": 0.65,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.073152,
    "y": -8.593139,
    "z": 5.615032,
    "vx": 0.00000118,
    "vy": 0.00007468,
    "vz": -0.00002246,
    "rarad": 4.36893779380266,
    "decrad": 0.551571613434034,
    "pmrarad": -0.00000224265112351388,
    "pmdecrad": 0.000001672849604,
    "bayer": "Zet",
    "flam": 40,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81444,
    "base": "Gl 635",
    "lum": 7.51622894018205,
    "bf": "40Zet Her",
    "ra": 16.688113,
    "dec": 31.602726,
    "pmra": -462.58,
    "pmdec": 345.05,
    "rv": -70.4,
    "Asset Name": "ASTRID 000397",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Battle Ready",
    "Hair": "Middle Part",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000118,
    "y_1": 0.00007468,
    "z_1": -0.00002246,
    "Distance in Parsecs_1": 10.7158,
    "HYG Star ID_1": 81444,
    "Hipparcos Catalogue_1": 81693,
    "Henry Draper Catalogue_1": 150680,
    "Harvard Revised Catalogue_1": 6212,
    "Gliese Catalog_1": "Gl 635A"
  },
  {
    "Astrid #": "ASTRID 000398",
    "HYG Star ID": 71672,
    "Hipparcos Catalogue": 71898,
    "Gliese Catalog": "Wo 9492",
    "Distance in Parsecs": 10.7331,
    "mag": 10.88,
    "Absolute Visual Magnitude": 10.726,
    "Spectral Type": "M3",
    "Color Index": 1.615,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.30789,
    "y": -2.83419,
    "z": 9.809417,
    "vx": -0.00001265,
    "vy": 0.00001044,
    "vz": -0.00000125,
    "rarad": 3.85003876412777,
    "decrad": 1.15289018881671,
    "pmrarad": -0.00000150592825454166,
    "pmdecrad": -2.87106661e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 71672,
    "lum": 0.00446272370762252,
    "var_min": 10.931,
    "var_max": 10.811,
    "ra": 14.706065,
    "dec": 66.055742,
    "pmra": -310.62,
    "pmdec": -59.22,
    "rv": 0,
    "Asset Name": "ASTRID 000398",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Braided Ponytail",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": -0.00001265,
    "y_1": 0.00001044,
    "z_1": -0.00000125,
    "Distance in Parsecs_1": 10.7331,
    "HYG Star ID_1": 71672,
    "Hipparcos Catalogue_1": 71898,
    "Gliese Catalog_1": "Wo 9492"
  },
  {
    "Astrid #": "ASTRID 000399",
    "HYG Star ID": 16495,
    "Hipparcos Catalogue": 16536,
    "Gliese Catalog": "Gl 145",
    "Distance in Parsecs": 10.74,
    "mag": 11.47,
    "Absolute Visual Magnitude": 11.315,
    "Color Index": 1.56,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.569153,
    "y": 6.114994,
    "z": -7.554493,
    "vx": 2.2e-7,
    "vy": -0.00002683,
    "vz": 0.00003075,
    "rarad": 0.929091929798124,
    "decrad": -0.780196234914737,
    "pmrarad": -0.00000151179450007638,
    "pmdecrad": 6.3612403e-7,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 16495,
    "lum": 0.00259417936211881,
    "var_min": 11.573,
    "var_max": 11.393,
    "ra": 3.54887,
    "dec": -44.701951,
    "pmra": -311.83,
    "pmdec": 131.21,
    "rv": -36,
    "Asset Name": "ASTRID 000399",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Fedora",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": 2.2e-7,
    "y_1": -0.00002683,
    "z_1": 0.00003075,
    "Distance in Parsecs_1": 10.74,
    "HYG Star ID_1": 16495,
    "Hipparcos Catalogue_1": 16536,
    "Gliese Catalog_1": "Gl 145"
  },
  {
    "Astrid #": "ASTRID 000400",
    "HYG Star ID": 119300,
    "Gliese Catalog": "Gl 766A",
    "Distance in Parsecs": 10.7411,
    "mag": 12.91,
    "Absolute Visual Magnitude": 12.755,
    "Spectral Type": "dM4.5 J",
    "Color Index": 1.72,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.256757,
    "y": -8.559719,
    "z": 4.89719,
    "vx": 0.00001141,
    "vy": -0.00002668,
    "vz": -0.00005651,
    "rarad": 5.17387602694959,
    "decrad": 0.47341678022335,
    "pmrarad": -1.54801008201388e-7,
    "pmdecrad": -0.000005912695533,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119300,
    "base": "Gl 766",
    "lum": 0.000688652296344274,
    "ra": 19.762751,
    "dec": 27.124783,
    "pmra": -31.93,
    "pmdec": -1219.58,
    "rv": 0,
    "Asset Name": "ASTRID 000400",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Bandana",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001141,
    "y_1": -0.00002668,
    "z_1": -0.00005651,
    "Distance in Parsecs_1": 10.7411,
    "HYG Star ID_1": 119300,
    "Gliese Catalog_1": "Gl 766A"
  },
  {
    "Astrid #": "ASTRID 000401",
    "HYG Star ID": 119301,
    "Gliese Catalog": "Gl 766B",
    "Distance in Parsecs": 10.7411,
    "mag": 13.4,
    "Absolute Visual Magnitude": 13.245,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.256757,
    "y": -8.559719,
    "z": 4.89719,
    "vx": 0.00001141,
    "vy": -0.00002668,
    "vz": -0.00005651,
    "rarad": 5.17387602694959,
    "decrad": 0.47341678022335,
    "pmrarad": -1.54801008201388e-7,
    "pmdecrad": -0.000005912695533,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119300,
    "base": "Gl 766",
    "lum": 0.000438530697774985,
    "ra": 19.762751,
    "dec": 27.124783,
    "pmra": -31.93,
    "pmdec": -1219.58,
    "rv": 0,
    "Asset Name": "ASTRID 000401",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Antlers",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001141,
    "y_1": -0.00002668,
    "z_1": -0.00005651,
    "Distance in Parsecs_1": 10.7411,
    "HYG Star ID_1": 119301,
    "Gliese Catalog_1": "Gl 766B"
  },
  {
    "Astrid #": "ASTRID 000402",
    "HYG Star ID": 79521,
    "Hipparcos Catalogue": 79762,
    "Gliese Catalog": "Gl 617B",
    "Distance in Parsecs": 10.7423,
    "mag": 10.69,
    "Absolute Visual Magnitude": 10.535,
    "Spectral Type": "M3",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.808253,
    "y": -3.738687,
    "z": 9.906851,
    "vx": -0.00001751,
    "vy": 0.00002187,
    "vz": -0.00001657,
    "rarad": 4.26193116669092,
    "decrad": 1.1738429548752,
    "pmrarad": -0.00000235420675140972,
    "pmdecrad": 4.39871452e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 79513,
    "base": "Gl 617",
    "lum": 0.00532108259266793,
    "var": "EW",
    "var_min": 10.745,
    "var_max": 10.635,
    "ra": 16.279378,
    "dec": 67.256247,
    "pmra": -485.59,
    "pmdec": 90.73,
    "rv": -19.5,
    "Asset Name": "ASTRID 000402",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Mohawk",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00001751,
    "y_1": 0.00002187,
    "z_1": -0.00001657,
    "Distance in Parsecs_1": 10.7423,
    "HYG Star ID_1": 79521,
    "Hipparcos Catalogue_1": 79762,
    "Gliese Catalog_1": "Gl 617B"
  },
  {
    "Astrid #": "ASTRID 000403",
    "HYG Star ID": 54373,
    "Hipparcos Catalogue": 54532,
    "Gliese Catalog": "Gl 413.1",
    "Distance in Parsecs": 10.7527,
    "mag": 10.44,
    "Absolute Visual Magnitude": 10.282,
    "Spectral Type": "M:",
    "Color Index": 1.529,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.540629,
    "y": 2.135974,
    "z": -4.475893,
    "vx": 0.00002307,
    "vy": 0.00003741,
    "vz": -0.00001904,
    "rarad": 2.92134601785122,
    "decrad": -0.429327319306598,
    "pmrarad": -0.00000386367414581944,
    "pmdecrad": -0.000002165177897,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 54373,
    "lum": 0.0067173812854738,
    "var_min": 10.497,
    "var_max": 10.377,
    "ra": 11.15872,
    "dec": -24.598643,
    "pmra": -796.94,
    "pmdec": -446.6,
    "rv": -5,
    "Asset Name": "ASTRID 000403",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Gimme Blood",
    "Hair": "Frizzy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00002307,
    "y_1": 0.00003741,
    "z_1": -0.00001904,
    "Distance in Parsecs_1": 10.7527,
    "HYG Star ID_1": 54373,
    "Hipparcos Catalogue_1": 54532,
    "Gliese Catalog_1": "Gl 413.1"
  },
  {
    "Astrid #": "ASTRID 000404",
    "HYG Star ID": 118096,
    "Gliese Catalog": "GJ 1041B",
    "Distance in Parsecs": 10.7527,
    "mag": 14,
    "Absolute Visual Magnitude": 13.842,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.313051,
    "y": 5.334067,
    "z": 0.65983,
    "vx": -0.00000751,
    "vy": 0.00001312,
    "vz": 0,
    "rarad": 0.52014293698368,
    "decrad": 0.061402744,
    "pmrarad": 0.00000140595967361111,
    "pmdecrad": 0,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 9256,
    "base": "GJ 1041",
    "lum": 0.000253046304946139,
    "ra": 1.9868,
    "dec": 3.518118,
    "pmra": 290,
    "pmdec": 0,
    "rv": 0,
    "Asset Name": "ASTRID 000404",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Lovestruck",
    "Hair": "Unkempt",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000751,
    "y_1": 0.00001312,
    "z_1": 0,
    "Distance in Parsecs_1": 10.7527,
    "HYG Star ID_1": 118096,
    "Gliese Catalog_1": "GJ 1041B"
  },
  {
    "Astrid #": "ASTRID 000405",
    "HYG Star ID": 118417,
    "Gliese Catalog": "Gl 228B",
    "Distance in Parsecs": 10.7793,
    "mag": 12.5,
    "Absolute Visual Magnitude": 12.337,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.505011,
    "y": 10.592888,
    "z": 1.931065,
    "vx": -0.00000414,
    "vy": 0.00000888,
    "vz": -0.00004973,
    "rarad": 1.61843455658193,
    "decrad": 0.18011797883475,
    "pmrarad": 3.44411638666666e-7,
    "pmdecrad": -0.000004690063305,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 29243,
    "base": "Gl 228",
    "lum": 0.00101204541109654,
    "ra": 6.181965,
    "dec": 10.32,
    "pmra": 71.04,
    "pmdec": -967.39,
    "rv": 0,
    "Asset Name": "ASTRID 000405",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Bob",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000414,
    "y_1": 0.00000888,
    "z_1": -0.00004973,
    "Distance in Parsecs_1": 10.7793,
    "HYG Star ID_1": 118417,
    "Gliese Catalog_1": "Gl 228B"
  },
  {
    "Astrid #": "ASTRID 000406",
    "HYG Star ID": 10116,
    "Hipparcos Catalogue": 10138,
    "Henry Draper Catalogue": 13445,
    "Harvard Revised Catalogue": 637,
    "Gliese Catalog": "Gl 86",
    "Distance in Parsecs": 10.7828,
    "mag": 6.12,
    "Absolute Visual Magnitude": 5.956,
    "Spectral Type": "K0V",
    "Color Index": 0.812,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.738173,
    "y": 3.670852,
    "z": -8.359261,
    "vx": -0.0000077,
    "vy": 0.00012493,
    "vz": -0.00002049,
    "rarad": 0.569066240778031,
    "decrad": -0.887041332962352,
    "pmrarad": 0.0000101463746321388,
    "pmdecrad": 0.000003172232874,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 10116,
    "lum": 0.361077145100353,
    "ra": 2.173673,
    "dec": -50.823725,
    "pmra": 2092.84,
    "pmdec": 654.32,
    "rv": 53.1,
    "Asset Name": "ASTRID 000406",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Shy Smile",
    "Hair": "Elegant",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.0000077,
    "y_1": 0.00012493,
    "z_1": -0.00002049,
    "Distance in Parsecs_1": 10.7828,
    "HYG Star ID_1": 10116,
    "Hipparcos Catalogue_1": 10138,
    "Henry Draper Catalogue_1": 13445,
    "Harvard Revised Catalogue_1": 637,
    "Gliese Catalog_1": "Gl 86"
  },
  {
    "Astrid #": "ASTRID 000407",
    "HYG Star ID": 10620,
    "Hipparcos Catalogue": 10644,
    "Henry Draper Catalogue": 13974,
    "Harvard Revised Catalogue": 660,
    "Gliese Catalog": "Gl 92",
    "Distance in Parsecs": 10.784,
    "mag": 4.84,
    "Absolute Visual Magnitude": 4.676,
    "Spectral Type": "G0V",
    "Color Index": 0.607,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.36921,
    "y": 5.020051,
    "z": 6.065246,
    "vx": -0.00003252,
    "vy": 0.00005069,
    "vz": -0.00001444,
    "rarad": 0.597999933674733,
    "decrad": 0.597325516810751,
    "pmrarad": 0.00000558316282664583,
    "pmdecrad": -0.000001194193057,
    "bayer": "Del",
    "flam": 8,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 10620,
    "lum": 1.17381593248322,
    "bf": "8Del Tri",
    "ra": 2.284191,
    "dec": 34.224231,
    "pmra": 1151.61,
    "pmdec": -246.32,
    "rv": -6.6,
    "Asset Name": "ASTRID 000407",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Prince",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.00003252,
    "y_1": 0.00005069,
    "z_1": -0.00001444,
    "Distance in Parsecs_1": 10.784,
    "HYG Star ID_1": 10620,
    "Hipparcos Catalogue_1": 10644,
    "Henry Draper Catalogue_1": 13974,
    "Harvard Revised Catalogue_1": 660,
    "Gliese Catalog_1": "Gl 92"
  },
  {
    "Astrid #": "ASTRID 000408",
    "HYG Star ID": 119602,
    "Gliese Catalog": "Gl 909B",
    "Distance in Parsecs": 10.7898,
    "mag": 11.7,
    "Absolute Visual Magnitude": 11.535,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.692069,
    "y": -0.089412,
    "z": 10.448184,
    "vx": -0.00000222,
    "vy": 0.00001552,
    "vz": 7.1e-7,
    "rarad": 6.24976933198636,
    "decrad": 1.3184890765424,
    "pmrarad": 0.00000143053972721527,
    "pmdecrad": 2.62550848e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 2,
    "comp_primary": 117345,
    "base": "Gl 909",
    "lum": 0.0021183611352485,
    "ra": 23.87236,
    "dec": 75.543859,
    "pmra": 295.07,
    "pmdec": 54.16,
    "rv": 0,
    "Asset Name": "ASTRID 000408",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Round Brush",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000222,
    "y_1": 0.00001552,
    "z_1": 7.1e-7,
    "Distance in Parsecs_1": 10.7898,
    "HYG Star ID_1": 119602,
    "Gliese Catalog_1": "Gl 909B"
  },
  {
    "Astrid #": "ASTRID 000409",
    "HYG Star ID": 119106,
    "Gliese Catalog": "Gl 635B",
    "Distance in Parsecs": 10.7956,
    "mag": 5.4,
    "Absolute Visual Magnitude": 5.234,
    "Spectral Type": "K0 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.096312,
    "y": -8.657766,
    "z": 5.656936,
    "vx": -0.00001945,
    "vy": 0.00001835,
    "vz": 0.00001744,
    "rarad": 4.36894930287095,
    "decrad": 0.55154510491805,
    "pmrarad": -0.00000226878258089583,
    "pmdecrad": 0.000001896998361,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 81444,
    "base": "Gl 635",
    "lum": 0.702101660240314,
    "ra": 16.688157,
    "dec": 31.601207,
    "pmra": -467.97,
    "pmdec": 391.28,
    "rv": 0,
    "Asset Name": "ASTRID 000409",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "Middle Part",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001945,
    "y_1": 0.00001835,
    "z_1": 0.00001744,
    "Distance in Parsecs_1": 10.7956,
    "HYG Star ID_1": 119106,
    "Gliese Catalog_1": "Gl 635B"
  },
  {
    "Astrid #": "ASTRID 000410",
    "HYG Star ID": 119133,
    "Gliese Catalog": "Gl 660B",
    "Distance in Parsecs": 10.8015,
    "mag": 12.2,
    "Absolute Visual Magnitude": 12.033,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -2.250561,
    "y": -10.55866,
    "z": -0.349411,
    "vx": -0.00002246,
    "vy": 0.00000532,
    "vz": -0.00001633,
    "rarad": 4.50238340088142,
    "decrad": -0.03235401335765,
    "pmrarad": -0.00000213662237157638,
    "pmdecrad": -0.000001512827153,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 83865,
    "base": "Gl 660",
    "lum": 0.00133905988554673,
    "ra": 17.197838,
    "dec": -1.853748,
    "pmra": -440.71,
    "pmdec": -312.04,
    "rv": 0,
    "Asset Name": "ASTRID 000410",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Warm Smile",
    "Hair": "Pirate Hat",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002246,
    "y_1": 0.00000532,
    "z_1": -0.00001633,
    "Distance in Parsecs_1": 10.8015,
    "HYG Star ID_1": 119133,
    "Gliese Catalog_1": "Gl 660B"
  },
  {
    "Astrid #": "ASTRID 000411",
    "HYG Star ID": 70750,
    "Hipparcos Catalogue": 70975,
    "Gliese Catalog": "Gl 553.1",
    "Distance in Parsecs": 10.8178,
    "mag": 11.92,
    "Absolute Visual Magnitude": 11.749,
    "Spectral Type": "M4",
    "Color Index": 1.591,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.356758,
    "y": -6.471623,
    "z": -2.3038,
    "vx": -0.00000948,
    "vy": 0.00001954,
    "vz": -0.0000205,
    "rarad": 3.80053938308253,
    "decrad": -0.21460743626621,
    "pmrarad": -0.00000196456199634722,
    "pmdecrad": -0.000001940030424,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 70750,
    "lum": 0.0017394021397803,
    "var_min": 12.014,
    "var_max": 11.744,
    "ra": 14.516991,
    "dec": -12.2961,
    "pmra": -405.22,
    "pmdec": -400.16,
    "rv": 0,
    "Asset Name": "ASTRID 000411",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Sweetheart",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00000948,
    "y_1": 0.00001954,
    "z_1": -0.0000205,
    "Distance in Parsecs_1": 10.8178,
    "HYG Star ID_1": 70750,
    "Hipparcos Catalogue_1": 70975,
    "Gliese Catalog_1": "Gl 553.1"
  },
  {
    "Astrid #": "ASTRID 000412",
    "HYG Star ID": 118033,
    "Gliese Catalog": "Gl 47",
    "Distance in Parsecs": 10.8225,
    "mag": 10.83,
    "Absolute Visual Magnitude": 10.658,
    "Spectral Type": "dM2.5e",
    "Color Index": 1.57,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.001794,
    "y": 1.371175,
    "z": 9.499372,
    "vx": 0.00003434,
    "vy": 0.00002653,
    "vz": -0.00001176,
    "rarad": 0.26748062316043,
    "decrad": 1.071055472311,
    "pmrarad": 0.00000152556320860416,
    "pmdecrad": -0.00000377589378,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 118033,
    "lum": 0.00475116376377561,
    "ra": 1.021701,
    "dec": 61.366958,
    "pmra": 314.67,
    "pmdec": -778.83,
    "rv": 8.7,
    "Asset Name": "ASTRID 000412",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Charmer",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00003434,
    "y_1": 0.00002653,
    "z_1": -0.00001176,
    "Distance in Parsecs_1": 10.8225,
    "HYG Star ID_1": 118033,
    "Gliese Catalog_1": "Gl 47"
  },
  {
    "Astrid #": "ASTRID 000413",
    "HYG Star ID": 119048,
    "Gliese Catalog": "NN 3928",
    "Distance in Parsecs": 10.8696,
    "mag": 13.68,
    "Absolute Visual Magnitude": 13.499,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.590406,
    "y": -7.603905,
    "z": 6.265541,
    "vx": -0.00000691,
    "vy": 0.00000972,
    "vz": 0.00000674,
    "rarad": 4.16927142321961,
    "decrad": 0.6143505496407,
    "pmrarad": -0.00000100671560767361,
    "pmdecrad": 7.58592814e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119048,
    "lum": 0.000347056354007987,
    "ra": 15.925444,
    "dec": 35.199694,
    "pmra": -207.65,
    "pmdec": 156.47,
    "rv": 0,
    "Asset Name": "ASTRID 000413",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Lovestruck",
    "Hair": "Headband",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000691,
    "y_1": 0.00000972,
    "z_1": 0.00000674,
    "Distance in Parsecs_1": 10.8696,
    "HYG Star ID_1": 119048,
    "Gliese Catalog_1": "NN 3928"
  },
  {
    "Astrid #": "ASTRID 000414",
    "HYG Star ID": 119506,
    "Gliese Catalog": "NN 4281",
    "Distance in Parsecs": 10.8814,
    "mag": 17.14,
    "Absolute Visual Magnitude": 16.957,
    "Spectral Type": "M6.5",
    "Color Index": 2.16,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.758009,
    "y": -4.096832,
    "z": -2.525614,
    "vx": -0.00003721,
    "vy": -0.00000193,
    "vz": -0.00004816,
    "rarad": 5.88569292312581,
    "decrad": -0.23426346834325,
    "pmrarad": -0.00000148711748373611,
    "pmdecrad": -0.000005020366865,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119506,
    "lum": 0.0000143615065290747,
    "ra": 22.481691,
    "dec": -13.422308,
    "pmra": -306.74,
    "pmdec": -1035.53,
    "rv": -21,
    "Asset Name": "ASTRID 000414",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Little Crown",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003721,
    "y_1": -0.00000193,
    "z_1": -0.00004816,
    "Distance in Parsecs_1": 10.8814,
    "HYG Star ID_1": 119506,
    "Gliese Catalog_1": "NN 4281"
  },
  {
    "Astrid #": "ASTRID 000415",
    "HYG Star ID": 117345,
    "Hipparcos Catalogue": 117712,
    "Henry Draper Catalogue": 223778,
    "Harvard Revised Catalogue": 9038,
    "Gliese Catalog": "Gl 909A",
    "Distance in Parsecs": 10.8909,
    "mag": 6.36,
    "Absolute Visual Magnitude": 6.175,
    "Spectral Type": "K3V",
    "Color Index": 0.977,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.717175,
    "y": -0.089877,
    "z": 10.546133,
    "vx": -0.00000111,
    "vy": 0.00001809,
    "vz": 0.00000224,
    "rarad": 6.25007643041357,
    "decrad": 1.31850179459972,
    "pmrarad": 0.000001657190122875,
    "pmdecrad": 2.03039969e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 117345,
    "base": "Gl 909",
    "lum": 0.295120922666638,
    "ra": 23.873533,
    "dec": 75.544588,
    "pmra": 341.82,
    "pmdec": 41.88,
    "rv": 1.7,
    "Asset Name": "ASTRID 000415",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Innocent",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000111,
    "y_1": 0.00001809,
    "z_1": 0.00000224,
    "Distance in Parsecs_1": 10.8909,
    "HYG Star ID_1": 117345,
    "Hipparcos Catalogue_1": 117712,
    "Henry Draper Catalogue_1": 223778,
    "Harvard Revised Catalogue_1": 9038,
    "Gliese Catalog_1": "Gl 909A"
  },
  {
    "Astrid #": "ASTRID 000416",
    "HYG Star ID": 92808,
    "Hipparcos Catalogue": 93101,
    "Henry Draper Catalogue": 176029,
    "Gliese Catalog": "Gl 740",
    "Distance in Parsecs": 10.9075,
    "mag": 9.22,
    "Absolute Visual Magnitude": 9.031,
    "Spectral Type": "M2V",
    "Color Index": 1.444,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.716642,
    "y": -10.504041,
    "z": 1.122757,
    "vx": -0.00000564,
    "vy": -0.00001926,
    "vz": -0.00006316,
    "rarad": 4.96547175334981,
    "decrad": 0.103116147629872,
    "pmrarad": -9.42817164576389e-7,
    "pmdecrad": -0.000005923356586,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 92808,
    "lum": 0.0212617985986251,
    "ra": 18.966705,
    "dec": 5.90812,
    "pmra": -194.47,
    "pmdec": -1221.78,
    "rv": 10.4,
    "Asset Name": "ASTRID 000416",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Einstein Tongue",
    "Hair": "Braid",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000564,
    "y_1": -0.00001926,
    "z_1": -0.00006316,
    "Distance in Parsecs_1": 10.9075,
    "HYG Star ID_1": 92808,
    "Hipparcos Catalogue_1": 93101,
    "Henry Draper Catalogue_1": 176029,
    "Gliese Catalog_1": "Gl 740"
  },
  {
    "Astrid #": "ASTRID 000417",
    "HYG Star ID": 29243,
    "Hipparcos Catalogue": 29316,
    "Gliese Catalog": "Gl 228A",
    "Distance in Parsecs": 10.9111,
    "mag": 10.41,
    "Absolute Visual Magnitude": 10.221,
    "Spectral Type": "M3",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.510998,
    "y": 10.722973,
    "z": 1.954403,
    "vx": -0.00000612,
    "vy": 0.00006441,
    "vz": -0.00003805,
    "rarad": 1.61841477330937,
    "decrad": 0.180084223276701,
    "pmrarad": 2.79349642736111e-7,
    "pmdecrad": -0.000004492768377,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29243,
    "base": "Gl 228",
    "lum": 0.00710558763369473,
    "ra": 6.181889,
    "dec": 10.318066,
    "pmra": 57.62,
    "pmdec": -926.7,
    "rv": 55.5,
    "Asset Name": "ASTRID 000417",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Greasy",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000612,
    "y_1": 0.00006441,
    "z_1": -0.00003805,
    "Distance in Parsecs_1": 10.9111,
    "HYG Star ID_1": 29243,
    "Hipparcos Catalogue_1": 29316,
    "Gliese Catalog_1": "Gl 228A"
  },
  {
    "Astrid #": "ASTRID 000418",
    "HYG Star ID": 57584,
    "Hipparcos Catalogue": 57757,
    "Henry Draper Catalogue": 102870,
    "Harvard Revised Catalogue": 4540,
    "Gliese Catalog": "Gl 449",
    "Distance in Parsecs": 10.929,
    "mag": 3.59,
    "Absolute Visual Magnitude": 3.397,
    "Spectral Type": "F8V",
    "Color Index": 0.518,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.914853,
    "y": 0.443379,
    "z": 0.336562,
    "vx": -0.00000643,
    "vy": -0.00003903,
    "vz": -0.00001423,
    "rarad": 3.10099326241016,
    "decrad": 0.0308001382197925,
    "pmrarad": 0.00000359227544744444,
    "pmdecrad": -0.000001314717738,
    "bayer": "Bet",
    "flam": 5,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 57584,
    "lum": 3.81241351104298,
    "bf": "5Bet Vir",
    "ra": 11.844922,
    "dec": 1.764718,
    "pmra": 740.96,
    "pmdec": -271.18,
    "rv": 4.3,
    "Asset Name": "ASTRID 000418",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Neko Dreams",
    "Hair": "Manbun",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000643,
    "y_1": -0.00003903,
    "z_1": -0.00001423,
    "Distance in Parsecs_1": 10.929,
    "HYG Star ID_1": 57584,
    "Hipparcos Catalogue_1": 57757,
    "Henry Draper Catalogue_1": 102870,
    "Harvard Revised Catalogue_1": 4540,
    "Gliese Catalog_1": "Gl 449"
  },
  {
    "Astrid #": "ASTRID 000419",
    "HYG Star ID": 119281,
    "Gliese Catalog": "GJ 1236",
    "Distance in Parsecs": 10.929,
    "mag": 12.35,
    "Absolute Visual Magnitude": 12.157,
    "Spectral Type": "m",
    "Color Index": 1.69,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.80097,
    "y": -10.160476,
    "z": 1.34021,
    "vx": -0.0000242,
    "vy": -0.00004642,
    "vz": -0.00001627,
    "rarad": 5.07036615106121,
    "decrad": 0.1229197860777,
    "pmrarad": -0.00000356279877566666,
    "pmdecrad": -0.000001870498446,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119281,
    "lum": 0.00119453808293388,
    "ra": 19.367372,
    "dec": 7.042785,
    "pmra": -734.88,
    "pmdec": -385.82,
    "rv": 32,
    "Asset Name": "ASTRID 000419",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "Bandana",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000242,
    "y_1": -0.00004642,
    "z_1": -0.00001627,
    "Distance in Parsecs_1": 10.929,
    "HYG Star ID_1": 119281,
    "Gliese Catalog_1": "GJ 1236"
  },
  {
    "Astrid #": "ASTRID 000420",
    "HYG Star ID": 66250,
    "Hipparcos Catalogue": 66459,
    "Gliese Catalog": "Gl 519",
    "Distance in Parsecs": 10.9361,
    "mag": 9.06,
    "Absolute Visual Magnitude": 8.866,
    "Spectral Type": "M9",
    "Color Index": 1.391,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.087832,
    "y": -3.663676,
    "z": 6.38434,
    "vx": 0.00001668,
    "vy": -0.00001104,
    "vz": -0.00001148,
    "rarad": 3.56692394484509,
    "decrad": 0.623392563814703,
    "pmrarad": 0.00000154912515347916,
    "pmdecrad": -2.83906891e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 66250,
    "lum": 0.0247514131807431,
    "ra": 13.624646,
    "dec": 35.717763,
    "pmra": 319.53,
    "pmdec": -58.56,
    "rv": -15,
    "Asset Name": "ASTRID 000420",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Slumber",
    "Hair": "Unkempt",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00001668,
    "y_1": -0.00001104,
    "z_1": -0.00001148,
    "Distance in Parsecs_1": 10.9361,
    "HYG Star ID_1": 66250,
    "Hipparcos Catalogue_1": 66459,
    "Gliese Catalog_1": "Gl 519"
  },
  {
    "Astrid #": "ASTRID 000421",
    "HYG Star ID": 94776,
    "Hipparcos Catalogue": 95071,
    "Gliese Catalog": "Gl 754.1A",
    "Distance in Parsecs": 10.9517,
    "mag": 12.28,
    "Absolute Visual Magnitude": 12.083,
    "Spectral Type": "DAw...",
    "Color Index": 0.07,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.738113,
    "y": -10.189776,
    "z": -1.461065,
    "vx": -0.00000312,
    "vy": 6e-8,
    "vz": -0.00000843,
    "rarad": 5.06399480089419,
    "decrad": -0.133808817148399,
    "pmrarad": -2.657263783125e-7,
    "pmdecrad": -7.7681696e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 94776,
    "base": "Gl 754.1",
    "lum": 0.00127879226293584,
    "var_min": 12.421,
    "var_max": 12.051,
    "ra": 19.343035,
    "dec": -7.66668,
    "pmra": -54.81,
    "pmdec": -160.23,
    "rv": 0,
    "Asset Name": "ASTRID 000421",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Stunned",
    "Hair": "High Bun",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000312,
    "y_1": 6e-8,
    "z_1": -0.00000843,
    "Distance in Parsecs_1": 10.9517,
    "HYG Star ID_1": 94776,
    "Hipparcos Catalogue_1": 95071,
    "Gliese Catalog_1": "Gl 754.1A"
  },
  {
    "Astrid #": "ASTRID 000422",
    "HYG Star ID": 118960,
    "Gliese Catalog": "Gl 545",
    "Distance in Parsecs": 10.9769,
    "mag": 12.9,
    "Absolute Visual Magnitude": 12.698,
    "Spectral Type": "M4",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.865278,
    "y": -6.214085,
    "z": -1.835363,
    "vx": -0.00007429,
    "vy": -0.00001238,
    "vz": -0.00005825,
    "rarad": 3.75295558057245,
    "decrad": -0.167932110260049,
    "pmrarad": -0.00000296230855093055,
    "pmdecrad": -0.000004199325201,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118960,
    "lum": 0.000725771650267427,
    "ra": 14.335234,
    "dec": -9.621801,
    "pmra": -611.02,
    "pmdec": -866.17,
    "rv": 75,
    "Asset Name": "ASTRID 000422",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Fire",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00007429,
    "y_1": -0.00001238,
    "z_1": -0.00005825,
    "Distance in Parsecs_1": 10.9769,
    "HYG Star ID_1": 118960,
    "Gliese Catalog_1": "Gl 545"
  },
  {
    "Astrid #": "ASTRID 000423",
    "HYG Star ID": 114968,
    "Hipparcos Catalogue": 115332,
    "Gliese Catalog": "NN 4333",
    "Distance in Parsecs": 10.989,
    "mag": 11.7,
    "Absolute Visual Magnitude": 11.495,
    "Spectral Type": "M:",
    "Color Index": 1.524,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.345664,
    "y": -1.748718,
    "z": 3.26609,
    "vx": 0.00001687,
    "vy": -0.00003184,
    "vz": -0.00007046,
    "rarad": 6.11574011375514,
    "decrad": 0.301774058036796,
    "pmrarad": -0.00000260117084028472,
    "pmdecrad": -0.000006715881509,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 114968,
    "lum": 0.00219785987278482,
    "var_min": 11.774,
    "var_max": 11.604,
    "ra": 23.360406,
    "dec": 17.29038,
    "pmra": -536.53,
    "pmdec": -1385.25,
    "rv": 0,
    "Asset Name": "ASTRID 000423",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Headband",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001687,
    "y_1": -0.00003184,
    "z_1": -0.00007046,
    "Distance in Parsecs_1": 10.989,
    "HYG Star ID_1": 114968,
    "Hipparcos Catalogue_1": 115332,
    "Gliese Catalog_1": "NN 4333"
  },
  {
    "Astrid #": "ASTRID 000424",
    "HYG Star ID": 118204,
    "Gliese Catalog": "NN 3210",
    "Distance in Parsecs": 10.989,
    "mag": 14.75,
    "Absolute Visual Magnitude": 14.545,
    "Spectral Type": "DZ7",
    "Color Index": 0.52,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.295221,
    "y": 4.800344,
    "z": -8.903141,
    "vx": -0.00000313,
    "vy": -0.00000163,
    "vz": -0.00000239,
    "rarad": 0.840872266895994,
    "decrad": -0.9444703030929,
    "pmrarad": 1.13397919881944e-7,
    "pmdecrad": -3.70901858e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118204,
    "lum": 0.000132434153519466,
    "ra": 3.211895,
    "dec": -54.114162,
    "pmra": 23.39,
    "pmdec": -76.5,
    "rv": 0,
    "Asset Name": "ASTRID 000424",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Bangs",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000313,
    "y_1": -0.00000163,
    "z_1": -0.00000239,
    "Distance in Parsecs_1": 10.989,
    "HYG Star ID_1": 118204,
    "Gliese Catalog_1": "NN 3210"
  },
  {
    "Astrid #": "ASTRID 000425",
    "HYG Star ID": 57459,
    "Hipparcos Catalogue": 57632,
    "Henry Draper Catalogue": 102647,
    "Harvard Revised Catalogue": 4534,
    "Gliese Catalog": "Gl 448",
    "Proper Name": "Denebola",
    "Distance in Parsecs": 10.9999,
    "mag": 2.14,
    "Absolute Visual Magnitude": 1.933,
    "Spectral Type": "A3Vvar",
    "Color Index": 0.09,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -10.633926,
    "y": 0.508011,
    "z": 2.767546,
    "vx": -6e-8,
    "vy": 0.00002664,
    "vz": -0.00000592,
    "rarad": 3.09385696597424,
    "decrad": 0.254330430316206,
    "pmrarad": -0.00000241931722870833,
    "pmdecrad": -5.51621005e-7,
    "bayer": "Bet",
    "flam": 94,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 57459,
    "lum": 14.6824996783198,
    "bf": "94Bet Leo",
    "ra": 11.817663,
    "dec": 14.57206,
    "pmra": -499.02,
    "pmdec": -113.78,
    "rv": -0.2,
    "Asset Name": "ASTRID 000425",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Cute Neko",
    "Hair": "Neko",
    "Outfit": "Gas Punk",
    "Special": "Neko Tail",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Denebola",
    "Constellation": "Leo",
    "x_1": -6e-8,
    "y_1": 0.00002664,
    "z_1": -0.00000592,
    "Distance in Parsecs_1": 10.9999,
    "HYG Star ID_1": 57459,
    "Hipparcos Catalogue_1": 57632,
    "Henry Draper Catalogue_1": 102647,
    "Harvard Revised Catalogue_1": 4534,
    "Gliese Catalog_1": "Gl 448"
  },
  {
    "Astrid #": "ASTRID 000426",
    "HYG Star ID": 86134,
    "Hipparcos Catalogue": 86400,
    "Henry Draper Catalogue": 160346,
    "Gliese Catalog": "Gl 688",
    "Distance in Parsecs": 10.9999,
    "mag": 6.53,
    "Absolute Visual Magnitude": 6.323,
    "Spectral Type": "K3V",
    "Color Index": 0.959,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.99114,
    "y": -10.934101,
    "z": 0.682125,
    "vx": -0.00001163,
    "vy": -0.00002223,
    "vz": -0.00000381,
    "rarad": 4.62198936518352,
    "decrad": 0.0620506225416087,
    "pmrarad": -8.71064739854166e-7,
    "pmdecrad": -4.76280959e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 86134,
    "lum": 0.257513499041031,
    "ra": 17.654699,
    "dec": 3.555239,
    "pmra": -179.67,
    "pmdec": -98.24,
    "rv": 22.4,
    "Asset Name": "ASTRID 000426",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Little Crown",
    "Outfit": "Sharky",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001163,
    "y_1": -0.00002223,
    "z_1": -0.00000381,
    "Distance in Parsecs_1": 10.9999,
    "HYG Star ID_1": 86134,
    "Hipparcos Catalogue_1": 86400,
    "Henry Draper Catalogue_1": 160346,
    "Gliese Catalog_1": "Gl 688"
  },
  {
    "Astrid #": "ASTRID 000427",
    "HYG Star ID": 8750,
    "Hipparcos Catalogue": 8768,
    "Henry Draper Catalogue": 11507,
    "Gliese Catalog": "Gl 79",
    "Distance in Parsecs": 11.0059,
    "mag": 8.89,
    "Absolute Visual Magnitude": 8.682,
    "Spectral Type": "K5/M0V",
    "Color Index": 1.424,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.965111,
    "y": 4.808034,
    "z": -4.20026,
    "vx": -0.00000955,
    "vy": 0.00004598,
    "vz": -0.00000554,
    "rarad": 0.492265126914521,
    "decrad": -0.391562114361162,
    "pmrarad": 0.00000409187594525694,
    "pmdecrad": -3.92699e-9,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 8750,
    "lum": 0.0293224328230311,
    "ra": 1.880314,
    "dec": -22.434857,
    "pmra": 844.01,
    "pmdec": -0.81,
    "rv": 14.1,
    "Asset Name": "ASTRID 000427",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Bucket Hat",
    "Outfit": "Spartan",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000955,
    "y_1": 0.00004598,
    "z_1": -0.00000554,
    "Distance in Parsecs_1": 11.0059,
    "HYG Star ID_1": 8750,
    "Hipparcos Catalogue_1": 8768,
    "Henry Draper Catalogue_1": 11507,
    "Gliese Catalog_1": "Gl 79"
  },
  {
    "Astrid #": "ASTRID 000428",
    "HYG Star ID": 88694,
    "Hipparcos Catalogue": 88972,
    "Henry Draper Catalogue": 166620,
    "Harvard Revised Catalogue": 6806,
    "Gliese Catalog": "Gl 706",
    "Distance in Parsecs": 11.0241,
    "mag": 6.38,
    "Absolute Visual Magnitude": 6.168,
    "Spectral Type": "K2V",
    "Color Index": 0.876,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.362378,
    "y": -8.624863,
    "z": 6.856197,
    "vx": -0.00001687,
    "vy": -9.8e-7,
    "vz": -0.00003175,
    "rarad": 4.75438359869012,
    "decrad": 0.671214830880787,
    "pmrarad": -0.0000015328354138125,
    "pmdecrad": -0.00000227052791,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 88694,
    "lum": 0.297029784401786,
    "ra": 18.160408,
    "dec": 38.457777,
    "pmra": -316.17,
    "pmdec": -468.33,
    "rv": -19.1,
    "Asset Name": "ASTRID 000428",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Medium Bob",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001687,
    "y_1": -9.8e-7,
    "z_1": -0.00003175,
    "Distance in Parsecs_1": 11.0241,
    "HYG Star ID_1": 88694,
    "Hipparcos Catalogue_1": 88972,
    "Henry Draper Catalogue_1": 166620,
    "Harvard Revised Catalogue_1": 6806,
    "Gliese Catalog_1": "Gl 706"
  },
  {
    "Astrid #": "ASTRID 000429",
    "HYG Star ID": 117965,
    "Gliese Catalog": "GJ 1004",
    "Distance in Parsecs": 11.0254,
    "mag": 14.36,
    "Absolute Visual Magnitude": 14.148,
    "Spectral Type": "DA8",
    "Color Index": 0.42,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 7.014344,
    "y": 0.375199,
    "z": 8.498096,
    "vx": 0.00002483,
    "vy": -0.00002101,
    "vz": -0.00001956,
    "rarad": 0.0534968513533468,
    "decrad": 0.880055796026149,
    "pmrarad": -0.0000020232729330625,
    "pmdecrad": -0.000002784779477,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117965,
    "lum": 0.00019089739411235,
    "ra": 0.204343,
    "dec": 50.423483,
    "pmra": -417.33,
    "pmdec": -574.4,
    "rv": 0,
    "Asset Name": "ASTRID 000429",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Angelic",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002483,
    "y_1": -0.00002101,
    "z_1": -0.00001956,
    "Distance in Parsecs_1": 11.0254,
    "HYG Star ID_1": 117965,
    "Gliese Catalog_1": "GJ 1004"
  },
  {
    "Astrid #": "ASTRID 000430",
    "HYG Star ID": 119285,
    "Gliese Catalog": "GJ 1238",
    "Distance in Parsecs": 11.0254,
    "mag": 15.37,
    "Absolute Visual Magnitude": 15.158,
    "Spectral Type": "m+",
    "Color Index": 1.94,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.989226,
    "y": -2.566331,
    "z": 10.676836,
    "vx": 0.00000836,
    "vy": 0.00003456,
    "vz": 0.00000754,
    "rarad": 5.08002533065954,
    "decrad": 1.31867472059079,
    "pmrarad": 0.00000183395319080555,
    "pmdecrad": 0.000002739531816,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119285,
    "lum": 0.0000753008710547589,
    "ra": 19.404267,
    "dec": 75.554496,
    "pmra": 378.28,
    "pmdec": 565.07,
    "rv": 0,
    "Asset Name": "ASTRID 000430",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Sad",
    "Hair": "Shinobi",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000836,
    "y_1": 0.00003456,
    "z_1": 0.00000754,
    "Distance in Parsecs_1": 11.0254,
    "HYG Star ID_1": 119285,
    "Gliese Catalog_1": "GJ 1238"
  },
  {
    "Astrid #": "ASTRID 000431",
    "HYG Star ID": 118397,
    "Gliese Catalog": "GJ 1086",
    "Distance in Parsecs": 11.0497,
    "mag": 14.55,
    "Absolute Visual Magnitude": 14.333,
    "Spectral Type": "DQP8",
    "Color Index": 0.48,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 0.417995,
    "y": 11.041742,
    "z": -0.033168,
    "vx": -0.00000421,
    "vy": 1.9e-7,
    "vz": 0.00001043,
    "rarad": 1.53295852214612,
    "decrad": -0.00300179244895,
    "pmrarad": 3.81402922493055e-7,
    "pmdecrad": 9.43971021e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118397,
    "lum": 0.00016099040762157,
    "ra": 5.85547,
    "dec": -0.17199,
    "pmra": 78.67,
    "pmdec": 194.71,
    "rv": 0,
    "Asset Name": "ASTRID 000431",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Greasy",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000421,
    "y_1": 1.9e-7,
    "z_1": 0.00001043,
    "Distance in Parsecs_1": 11.0497,
    "HYG Star ID_1": 118397,
    "Gliese Catalog_1": "GJ 1086"
  },
  {
    "Astrid #": "ASTRID 000432",
    "HYG Star ID": 3087,
    "Hipparcos Catalogue": 3093,
    "Henry Draper Catalogue": 3651,
    "Harvard Revised Catalogue": 166,
    "Gliese Catalog": "Gl 27",
    "Distance in Parsecs": 11.0595,
    "mag": 5.88,
    "Absolute Visual Magnitude": 5.661,
    "Spectral Type": "K0V",
    "Color Index": 0.85,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.155576,
    "y": 1.761632,
    "z": 4.008362,
    "vx": -0.00001957,
    "vy": -0.00002848,
    "vz": -0.00003073,
    "rarad": 0.171756809858676,
    "decrad": 0.370890710826442,
    "pmrarad": -0.00000223533043693749,
    "pmdecrad": -0.000001798076978,
    "flam": 54,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 3087,
    "lum": 0.473805393338825,
    "bf": "54    Psc",
    "ra": 0.656063,
    "dec": 21.250472,
    "pmra": -461.07,
    "pmdec": -370.88,
    "rv": -32.9,
    "Asset Name": "ASTRID 000432",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Infatuated",
    "Hair": "Bandana",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001957,
    "y_1": -0.00002848,
    "z_1": -0.00003073,
    "Distance in Parsecs_1": 11.0595,
    "HYG Star ID_1": 3087,
    "Hipparcos Catalogue_1": 3093,
    "Henry Draper Catalogue_1": 3651,
    "Harvard Revised Catalogue_1": 166,
    "Gliese Catalog_1": "Gl 27"
  },
  {
    "Astrid #": "ASTRID 000433",
    "HYG Star ID": 41706,
    "Hipparcos Catalogue": 41824,
    "Gliese Catalog": "GJ 2069A",
    "Distance in Parsecs": 11.0656,
    "mag": 11.9,
    "Absolute Visual Magnitude": 11.68,
    "Spectral Type": "M5Ve",
    "Color Index": 1.85,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.412664,
    "y": 8.235479,
    "z": 3.674523,
    "vx": 0.00000876,
    "vy": 0.00000879,
    "vz": -0.00000442,
    "rarad": 2.2323915081837,
    "decrad": 0.338494358508446,
    "pmrarad": -0.00000111255043413888,
    "pmdecrad": -4.23484749e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 41706,
    "base": "GJ 2069",
    "lum": 0.00185353162341481,
    "var": "CU",
    "var_min": 12.03,
    "var_max": 11.19,
    "ra": 8.527107,
    "dec": 19.394298,
    "pmra": -229.48,
    "pmdec": -87.35,
    "rv": 0,
    "Asset Name": "ASTRID 000433",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Windy",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00000876,
    "y_1": 0.00000879,
    "z_1": -0.00000442,
    "Distance in Parsecs_1": 11.0656,
    "HYG Star ID_1": 41706,
    "Hipparcos Catalogue_1": 41824,
    "Gliese Catalog_1": "GJ 2069A"
  },
  {
    "Astrid #": "ASTRID 000434",
    "HYG Star ID": 64592,
    "Hipparcos Catalogue": 64797,
    "Henry Draper Catalogue": 115404,
    "Gliese Catalog": "Gl 505A",
    "Distance in Parsecs": 11.0717,
    "mag": 6.49,
    "Absolute Visual Magnitude": 6.269,
    "Spectral Type": "K2V",
    "Color Index": 0.926,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.997344,
    "y": -3.483932,
    "z": 3.240244,
    "vx": 0.00000109,
    "vy": -0.0000355,
    "vz": -0.00001138,
    "rarad": 3.47691660668378,
    "decrad": 0.297005861854964,
    "pmrarad": 0.00000306019243303472,
    "pmdecrad": -0.000001264588004,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 64592,
    "base": "Gl 505",
    "lum": 0.270644994903848,
    "ra": 13.280843,
    "dec": 17.017182,
    "pmra": 631.21,
    "pmdec": -260.84,
    "rv": 6.7,
    "Asset Name": "ASTRID 000434",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Bucket Hat",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00000109,
    "y_1": -0.0000355,
    "z_1": -0.00001138,
    "Distance in Parsecs_1": 11.0717,
    "HYG Star ID_1": 64592,
    "Hipparcos Catalogue_1": 64797,
    "Henry Draper Catalogue_1": 115404,
    "Gliese Catalog_1": "Gl 505A"
  },
  {
    "Astrid #": "ASTRID 000435",
    "HYG Star ID": 118077,
    "Gliese Catalog": "Gl 63",
    "Distance in Parsecs": 11.0742,
    "mag": 11.19,
    "Absolute Visual Magnitude": 10.968,
    "Spectral Type": "dM3",
    "Color Index": 1.39,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.449928,
    "y": 2.493787,
    "z": 9.311144,
    "vx": 0.00000937,
    "vy": -0.00000522,
    "vz": -0.00002537,
    "rarad": 0.429173971833962,
    "decrad": 0.9988776993322,
    "pmrarad": -7.81083320743055e-7,
    "pmdecrad": -0.000001721893356,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 118077,
    "lum": 0.00357108345808345,
    "ra": 1.639324,
    "dec": 57.231476,
    "pmra": -161.11,
    "pmdec": -355.17,
    "rv": -17.5,
    "Asset Name": "ASTRID 000435",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "High Bun",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000937,
    "y_1": -0.00000522,
    "z_1": -0.00002537,
    "Distance in Parsecs_1": 11.0742,
    "HYG Star ID_1": 118077,
    "Gliese Catalog_1": "Gl 63"
  },
  {
    "Astrid #": "ASTRID 000436",
    "HYG Star ID": 21503,
    "Hipparcos Catalogue": 21556,
    "Gliese Catalog": "Gl 173",
    "Distance in Parsecs": 11.0988,
    "mag": 10.34,
    "Absolute Visual Magnitude": 10.114,
    "Spectral Type": "M1",
    "Color Index": 1.505,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.828396,
    "y": 10.198489,
    "z": -2.12513,
    "vx": 0.0000082,
    "vy": -0.00001272,
    "vz": -0.00000885,
    "rarad": 1.21168529886949,
    "decrad": -0.192664712743743,
    "pmrarad": -0.0000010947577720625,
    "pmdecrad": -9.38599285e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 21503,
    "lum": 0.00784151540528121,
    "ra": 4.628297,
    "dec": -11.038875,
    "pmra": -225.81,
    "pmdec": -193.6,
    "rv": -7,
    "Asset Name": "ASTRID 000436",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "High Fade",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.0000082,
    "y_1": -0.00001272,
    "z_1": -0.00000885,
    "Distance in Parsecs_1": 11.0988,
    "HYG Star ID_1": 21503,
    "Hipparcos Catalogue_1": 21556,
    "Gliese Catalog_1": "Gl 173"
  },
  {
    "Astrid #": "ASTRID 000437",
    "HYG Star ID": 56879,
    "Hipparcos Catalogue": 57050,
    "Gliese Catalog": "GJ 1148",
    "Distance in Parsecs": 11.1037,
    "mag": 11.86,
    "Absolute Visual Magnitude": 11.633,
    "Spectral Type": "M5",
    "Color Index": 1.503,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.127583,
    "y": 0.648794,
    "z": 7.537481,
    "vx": -7.8e-7,
    "vy": 0.00003136,
    "vz": -0.00000354,
    "rarad": 3.0619442107047,
    "decrad": 0.746162702606021,
    "pmrarad": -0.00000281031946207638,
    "pmdecrad": -4.34102169e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 56879,
    "lum": 0.00193553041397974,
    "var_min": 11.937,
    "var_max": 11.767,
    "ra": 11.695765,
    "dec": 42.751974,
    "pmra": -579.67,
    "pmdec": -89.54,
    "rv": 0,
    "Asset Name": "ASTRID 000437",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Angelic",
    "Outfit": "Rider",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -7.8e-7,
    "y_1": 0.00003136,
    "z_1": -0.00000354,
    "Distance in Parsecs_1": 11.1037,
    "HYG Star ID_1": 56879,
    "Hipparcos Catalogue_1": 57050,
    "Gliese Catalog_1": "GJ 1148"
  },
  {
    "Astrid #": "ASTRID 000438",
    "HYG Star ID": 22686,
    "Hipparcos Catalogue": 22738,
    "Gliese Catalog": "GJ 2036A",
    "Distance in Parsecs": 11.1086,
    "mag": 10.73,
    "Absolute Visual Magnitude": 10.502,
    "Spectral Type": "M2Ve",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.783145,
    "y": 5.973835,
    "z": -9.194274,
    "vx": -0.00000591,
    "vy": 0.0000052,
    "vz": 0.00000223,
    "rarad": 1.28071865208023,
    "decrad": -0.974946286800902,
    "pmrarad": 6.44123455986111e-7,
    "pmdecrad": 3.58422754e-7,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 22686,
    "base": "GJ 2036",
    "lum": 0.00548529513941202,
    "var_min": 10.808,
    "var_max": 10.668,
    "ra": 4.891985,
    "dec": -55.860307,
    "pmra": 132.86,
    "pmdec": 73.93,
    "rv": 0,
    "Asset Name": "ASTRID 000438",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Side Part",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.00000591,
    "y_1": 0.0000052,
    "z_1": 0.00000223,
    "Distance in Parsecs_1": 11.1086,
    "HYG Star ID_1": 22686,
    "Hipparcos Catalogue_1": 22738,
    "Gliese Catalog_1": "GJ 2036A"
  },
  {
    "Astrid #": "ASTRID 000439",
    "HYG Star ID": 118118,
    "Gliese Catalog": "NN 3140",
    "Distance in Parsecs": 11.1111,
    "mag": 12.72,
    "Absolute Visual Magnitude": 12.491,
    "Spectral Type": "dM4",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.175921,
    "y": 5.858512,
    "z": 2.221901,
    "vx": 0.00000123,
    "vy": -3.7e-7,
    "vz": -0.00000411,
    "rarad": 0.568224090676875,
    "decrad": 0.201328649629249,
    "pmrarad": -8.72664624999999e-8,
    "pmdecrad": -3.77907415e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118118,
    "lum": 0.000878213279898006,
    "ra": 2.170456,
    "dec": 11.535282,
    "pmra": -18,
    "pmdec": -77.95,
    "rv": 0,
    "Asset Name": "ASTRID 000439",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Horns",
    "Outfit": "Tats",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000123,
    "y_1": -3.7e-7,
    "z_1": -0.00000411,
    "Distance in Parsecs_1": 11.1111,
    "HYG Star ID_1": 118118,
    "Gliese Catalog_1": "NN 3140"
  },
  {
    "Astrid #": "ASTRID 000440",
    "HYG Star ID": 118430,
    "Gliese Catalog": "NN 3396",
    "Distance in Parsecs": 11.1111,
    "mag": 14.83,
    "Absolute Visual Magnitude": 14.601,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.152402,
    "y": 8.241792,
    "z": 7.362158,
    "vx": -4.1e-7,
    "vy": 0.00000678,
    "vz": -0.00000765,
    "rarad": 1.709720715476,
    "decrad": 0.72427808796855,
    "pmrarad": -4.81904798472222e-8,
    "pmdecrad": -9.19880629e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118430,
    "lum": 0.000125776643245125,
    "ra": 6.530652,
    "dec": 41.498078,
    "pmra": -9.94,
    "pmdec": -189.74,
    "rv": 0,
    "Asset Name": "ASTRID 000440",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Ninja",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -4.1e-7,
    "y_1": 0.00000678,
    "z_1": -0.00000765,
    "Distance in Parsecs_1": 11.1111,
    "HYG Star ID_1": 118430,
    "Gliese Catalog_1": "NN 3396"
  },
  {
    "Astrid #": "ASTRID 000441",
    "HYG Star ID": 118674,
    "Gliese Catalog": "NN 3592",
    "Distance in Parsecs": 11.1111,
    "mag": 15.1,
    "Absolute Visual Magnitude": 14.871,
    "Spectral Type": "DZ9",
    "Color Index": 0.68,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -5.549149,
    "y": 2.736238,
    "z": -9.229788,
    "vx": -0.00000533,
    "vy": 0.00000952,
    "vz": -0.00000751,
    "rarad": 2.68350929654287,
    "decrad": -0.98025564266815,
    "pmrarad": -5.56469142541666e-7,
    "pmdecrad": 2.95886637e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118674,
    "lum": 0.0000980844136030904,
    "ra": 10.25025,
    "dec": -56.164511,
    "pmra": -114.78,
    "pmdec": 61.03,
    "rv": 11,
    "Asset Name": "ASTRID 000441",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Blep",
    "Hair": "Lion Mane",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000533,
    "y_1": 0.00000952,
    "z_1": -0.00000751,
    "Distance in Parsecs_1": 11.1111,
    "HYG Star ID_1": 118674,
    "Gliese Catalog_1": "NN 3592"
  },
  {
    "Astrid #": "ASTRID 000442",
    "HYG Star ID": 119241,
    "Gliese Catalog": "NN 4078",
    "Distance in Parsecs": 11.1111,
    "mag": 12.7,
    "Absolute Visual Magnitude": 12.471,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.283731,
    "y": -5.841749,
    "z": -9.363896,
    "vx": -0.00003532,
    "vy": 0.00000303,
    "vz": -0.00000674,
    "rarad": 4.9288325043897,
    "decrad": -1.00237429550035,
    "pmrarad": -0.000003046472205875,
    "pmdecrad": -0.000001126930007,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119241,
    "lum": 0.000894540483778868,
    "ra": 18.826753,
    "dec": -57.431817,
    "pmra": -628.38,
    "pmdec": -232.45,
    "rv": 0,
    "Asset Name": "ASTRID 000442",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Serious Slumber",
    "Hair": "Braid",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003532,
    "y_1": 0.00000303,
    "z_1": -0.00000674,
    "Distance in Parsecs_1": 11.1111,
    "HYG Star ID_1": 119241,
    "Gliese Catalog_1": "NN 4078"
  },
  {
    "Astrid #": "ASTRID 000443",
    "HYG Star ID": 119540,
    "Gliese Catalog": "GJ 1277",
    "Distance in Parsecs": 11.1111,
    "mag": 14.08,
    "Absolute Visual Magnitude": 13.851,
    "Spectral Type": "M4",
    "Color Index": 1.79,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.336935,
    "y": -1.518261,
    "z": -9.630464,
    "vx": -0.00001577,
    "vy": -0.00002429,
    "vz": -0.00008519,
    "rarad": 6.00615037712478,
    "decrad": -1.04809058241195,
    "pmrarad": -0.000002491457504375,
    "pmdecrad": -0.000004494688239,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119540,
    "lum": 0.000250957396370283,
    "ra": 22.941805,
    "dec": -60.051167,
    "pmra": -513.9,
    "pmdec": -927.1,
    "rv": 68,
    "Asset Name": "ASTRID 000443",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Hachimaki",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001577,
    "y_1": -0.00002429,
    "z_1": -0.00008519,
    "Distance in Parsecs_1": 11.1111,
    "HYG Star ID_1": 119540,
    "Gliese Catalog_1": "GJ 1277"
  },
  {
    "Astrid #": "ASTRID 000444",
    "HYG Star ID": 12744,
    "Hipparcos Catalogue": 12777,
    "Henry Draper Catalogue": 16895,
    "Harvard Revised Catalogue": 799,
    "Gliese Catalog": "Gl 107A",
    "Distance in Parsecs": 11.1272,
    "mag": 4.1,
    "Absolute Visual Magnitude": 3.868,
    "Spectral Type": "F7V",
    "Color Index": 0.514,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.480091,
    "y": 4.772164,
    "z": 8.42701,
    "vx": 0.00000323,
    "vy": 0.0000267,
    "vz": 0.00001573,
    "rarad": 0.71644913495245,
    "decrad": 0.859198496789113,
    "pmrarad": 0.00000161942313715972,
    "pmdecrad": -4.36138387e-7,
    "bayer": "The",
    "flam": 13,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 12744,
    "base": "Gl 107",
    "lum": 2.47058613603843,
    "bf": "13The Per",
    "ra": 2.736634,
    "dec": 49.228448,
    "pmra": 334.03,
    "pmdec": -89.96,
    "rv": 24.4,
    "Asset Name": "ASTRID 000444",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Pompadour",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00000323,
    "y_1": 0.0000267,
    "z_1": 0.00001573,
    "Distance in Parsecs_1": 11.1272,
    "HYG Star ID_1": 12744,
    "Hipparcos Catalogue_1": 12777,
    "Henry Draper Catalogue_1": 16895,
    "Harvard Revised Catalogue_1": 799,
    "Gliese Catalog_1": "Gl 107A"
  },
  {
    "Astrid #": "ASTRID 000445",
    "HYG Star ID": 42688,
    "Hipparcos Catalogue": 42808,
    "Henry Draper Catalogue": 74576,
    "Gliese Catalog": "Gl 320",
    "Distance in Parsecs": 11.1408,
    "mag": 6.58,
    "Absolute Visual Magnitude": 6.345,
    "Spectral Type": "K2V",
    "Color Index": 0.917,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.66967,
    "y": 6.562556,
    "z": -6.993424,
    "vx": -0.00000269,
    "vy": 0.00002798,
    "vz": 0.00000515,
    "rarad": 2.28333283337126,
    "decrad": -0.678625540552901,
    "pmrarad": -0.00000145938614120833,
    "pmdecrad": 0.000001652051097,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 42688,
    "lum": 0.252348077248057,
    "ra": 8.721689,
    "dec": -38.882379,
    "pmra": -301.02,
    "pmdec": 340.76,
    "rv": 14.3,
    "Asset Name": "ASTRID 000445",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Innocent",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000269,
    "y_1": 0.00002798,
    "z_1": 0.00000515,
    "Distance in Parsecs_1": 11.1408,
    "HYG Star ID_1": 42688,
    "Hipparcos Catalogue_1": 42808,
    "Henry Draper Catalogue_1": 74576,
    "Gliese Catalog_1": "Gl 320"
  },
  {
    "Astrid #": "ASTRID 000446",
    "HYG Star ID": 48194,
    "Hipparcos Catalogue": 48331,
    "Henry Draper Catalogue": 85512,
    "Gliese Catalog": "Gl 370",
    "Distance in Parsecs": 11.1582,
    "mag": 7.67,
    "Absolute Visual Magnitude": 7.432,
    "Spectral Type": "K5V",
    "Color Index": 1.156,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.847058,
    "y": 4.315265,
    "z": -7.681132,
    "vx": 0.00000762,
    "vy": -0.00003429,
    "vz": -0.00001179,
    "rarad": 2.57922935504977,
    "decrad": -0.759266869711446,
    "pmrarad": 0.00000223634854566666,
    "pmdecrad": -0.000002292538451,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 48194,
    "lum": 0.0927256742580593,
    "ra": 9.85193,
    "dec": -43.502787,
    "pmra": 461.28,
    "pmdec": -472.87,
    "rv": -9.6,
    "Asset Name": "ASTRID 000446",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Shinobi",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000762,
    "y_1": -0.00003429,
    "z_1": -0.00001179,
    "Distance in Parsecs_1": 11.1582,
    "HYG Star ID_1": 48194,
    "Hipparcos Catalogue_1": 48331,
    "Henry Draper Catalogue_1": 85512,
    "Gliese Catalog_1": "Gl 370"
  },
  {
    "Astrid #": "ASTRID 000447",
    "HYG Star ID": 118637,
    "Gliese Catalog": "Gl 356B",
    "Distance in Parsecs": 11.1794,
    "mag": 13,
    "Absolute Visual Magnitude": 12.758,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.32672,
    "y": 5.339644,
    "z": 6.541129,
    "vx": 0.00001699,
    "vy": 0.00003655,
    "vz": -0.00001079,
    "rarad": 2.5118455643361,
    "decrad": 0.62501024136485,
    "pmrarad": -0.00000353807327795833,
    "pmdecrad": -0.000001190692703,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 46945,
    "base": "Gl 356",
    "lum": 0.000686752106148818,
    "ra": 9.594543,
    "dec": 35.810449,
    "pmra": -729.78,
    "pmdec": -245.6,
    "rv": 0,
    "Asset Name": "ASTRID 000447",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Lion Mane",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001699,
    "y_1": 0.00003655,
    "z_1": -0.00001079,
    "Distance in Parsecs_1": 11.1794,
    "HYG Star ID_1": 118637,
    "Gliese Catalog_1": "Gl 356B"
  },
  {
    "Astrid #": "ASTRID 000448",
    "HYG Star ID": 118999,
    "Gliese Catalog": "GJ 1187",
    "Distance in Parsecs": 11.1857,
    "mag": 15.53,
    "Absolute Visual Magnitude": 15.287,
    "Spectral Type": "m",
    "Color Index": 1.95,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.387858,
    "y": -4.307567,
    "z": 9.344673,
    "vx": -0.00001399,
    "vy": -0.00003416,
    "vz": -0.00001007,
    "rarad": 3.91770405616782,
    "decrad": 0.9888131645048,
    "pmrarad": 0.00000130400335659027,
    "pmdecrad": -0.000003027869904,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118999,
    "lum": 0.0000668651772197638,
    "ra": 14.964527,
    "dec": 56.654821,
    "pmra": 268.97,
    "pmdec": -624.54,
    "rv": 10,
    "Asset Name": "ASTRID 000448",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Angelic",
    "Outfit": "Wizard",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001399,
    "y_1": -0.00003416,
    "z_1": -0.00001007,
    "Distance in Parsecs_1": 11.1857,
    "HYG Star ID_1": 118999,
    "Gliese Catalog_1": "GJ 1187"
  },
  {
    "Astrid #": "ASTRID 000449",
    "HYG Star ID": 119103,
    "Gliese Catalog": "NN 3967",
    "Distance in Parsecs": 11.1982,
    "mag": 13.69,
    "Absolute Visual Magnitude": 13.444,
    "Spectral Type": "m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.82569,
    "y": -10.523536,
    "z": 0.13777,
    "vx": 0.00000754,
    "vy": -0.00000285,
    "vz": -0.00000806,
    "rarad": 4.36370610299171,
    "decrad": 0.0123031375235,
    "pmrarad": 7.19899834256944e-7,
    "pmdecrad": -7.1990953e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119103,
    "lum": 0.000365090052484497,
    "ra": 16.66813,
    "dec": 0.704918,
    "pmra": 148.49,
    "pmdec": -148.49,
    "rv": 0,
    "Asset Name": "ASTRID 000449",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Braided Bun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000754,
    "y_1": -0.00000285,
    "z_1": -0.00000806,
    "Distance in Parsecs_1": 11.1982,
    "HYG Star ID_1": 119103,
    "Gliese Catalog_1": "NN 3967"
  },
  {
    "Astrid #": "ASTRID 000450",
    "HYG Star ID": 118942,
    "Gliese Catalog": "Gl 537A",
    "Distance in Parsecs": 11.2233,
    "mag": 9.85,
    "Absolute Visual Magnitude": 9.599,
    "Spectral Type": "dM3  e",
    "Color Index": 1.48,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -6.665435,
    "y": -3.947816,
    "z": 8.118006,
    "vx": 0.00003946,
    "vy": -0.0000132,
    "vz": -0.00003212,
    "rarad": 3.67625484705911,
    "decrad": 0.80879045003435,
    "pmrarad": 0.00000280324118234027,
    "pmdecrad": -2.20619313e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 118942,
    "base": "Gl 537",
    "lum": 0.0126008545908823,
    "ra": 14.042259,
    "dec": 46.340279,
    "pmra": 578.21,
    "pmdec": -45.51,
    "rv": -41.1,
    "Asset Name": "ASTRID 000450",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Bucket Hat",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00003946,
    "y_1": -0.0000132,
    "z_1": -0.00003212,
    "Distance in Parsecs_1": 11.2233,
    "HYG Star ID_1": 118942,
    "Gliese Catalog_1": "Gl 537A"
  },
  {
    "Astrid #": "ASTRID 000451",
    "HYG Star ID": 118943,
    "Gliese Catalog": "Gl 537B",
    "Distance in Parsecs": 11.2233,
    "mag": 9.95,
    "Absolute Visual Magnitude": 9.699,
    "Spectral Type": "dM3  e",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -6.665833,
    "y": -3.948051,
    "z": 8.118491,
    "vx": 0.0000315,
    "vy": -0.00001791,
    "vz": -0.00002243,
    "rarad": 3.67625484705911,
    "decrad": 0.80879045003435,
    "pmrarad": 0.00000280324118234027,
    "pmdecrad": -2.20619313e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 2,
    "comp_primary": 118942,
    "base": "Gl 537",
    "lum": 0.0114921159720324,
    "ra": 14.042259,
    "dec": 46.340279,
    "pmra": 578.21,
    "pmdec": -45.51,
    "rv": -28,
    "Asset Name": "ASTRID 000451",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Mortified",
    "Hair": "Audiophile",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.0000315,
    "y_1": -0.00001791,
    "z_1": -0.00002243,
    "Distance in Parsecs_1": 11.2233,
    "HYG Star ID_1": 118943,
    "Gliese Catalog_1": "Gl 537B"
  },
  {
    "Astrid #": "ASTRID 000452",
    "HYG Star ID": 119278,
    "Gliese Catalog": "Gl 754.1B",
    "Distance in Parsecs": 11.2259,
    "mag": 12.12,
    "Absolute Visual Magnitude": 11.869,
    "Spectral Type": "dM5",
    "Color Index": 1.63,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.830456,
    "y": -10.445951,
    "z": -1.49734,
    "vx": 4e-8,
    "vy": -0.00000939,
    "vz": -0.00001111,
    "rarad": 5.0638570372944,
    "decrad": -0.133775262916,
    "pmrarad": -2.84634111854166e-7,
    "pmdecrad": -8.7605832e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 94776,
    "base": "Gl 754.1",
    "lum": 0.00155739938908085,
    "ra": 19.342509,
    "dec": -7.664758,
    "pmra": -58.71,
    "pmdec": -180.7,
    "rv": 10,
    "Asset Name": "ASTRID 000452",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Audiophile",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 4e-8,
    "y_1": -0.00000939,
    "z_1": -0.00001111,
    "Distance in Parsecs_1": 11.2259,
    "HYG Star ID_1": 119278,
    "Gliese Catalog_1": "Gl 754.1B"
  },
  {
    "Astrid #": "ASTRID 000453",
    "HYG Star ID": 118878,
    "Gliese Catalog": "Gl 505B",
    "Distance in Parsecs": 11.2271,
    "mag": 9.6,
    "Absolute Visual Magnitude": 9.349,
    "Spectral Type": "M1 V",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.138082,
    "y": -3.533019,
    "z": 3.285603,
    "vx": -0.00000149,
    "vy": -0.00003493,
    "vz": -0.00001069,
    "rarad": 3.47691493245957,
    "decrad": 0.29698373223175,
    "pmrarad": 0.00000289428919154861,
    "pmdecrad": -0.000001246475365,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 2,
    "comp_primary": 64592,
    "base": "Gl 505",
    "lum": 0.015863536054785,
    "ra": 13.280837,
    "dec": 17.015914,
    "pmra": 596.99,
    "pmdec": -257.1,
    "rv": 9,
    "Asset Name": "ASTRID 000453",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Bandana",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00000149,
    "y_1": -0.00003493,
    "z_1": -0.00001069,
    "Distance in Parsecs_1": 11.2271,
    "HYG Star ID_1": 118878,
    "Gliese Catalog_1": "Gl 505B"
  },
  {
    "Astrid #": "ASTRID 000454",
    "HYG Star ID": 118168,
    "Gliese Catalog": "Gl 107B",
    "Distance in Parsecs": 11.2322,
    "mag": 10.06,
    "Absolute Visual Magnitude": 9.808,
    "Spectral Type": "M1 V",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.532529,
    "y": 4.817104,
    "z": 8.507513,
    "vx": 0.00000341,
    "vy": 0.00002678,
    "vz": 0.00001652,
    "rarad": 0.716339887016607,
    "decrad": 0.8592227753102,
    "pmrarad": 0.00000159940033215277,
    "pmdecrad": -3.98773796e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 2,
    "comp_primary": 12744,
    "base": "Gl 107",
    "lum": 0.010394413751488,
    "ra": 2.736217,
    "dec": 49.229839,
    "pmra": 329.9,
    "pmdec": -82.25,
    "rv": 25.1,
    "Asset Name": "ASTRID 000454",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Manbun",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00000341,
    "y_1": 0.00002678,
    "z_1": 0.00001652,
    "Distance in Parsecs_1": 11.2322,
    "HYG Star ID_1": 118168,
    "Gliese Catalog_1": "Gl 107B"
  },
  {
    "Astrid #": "ASTRID 000455",
    "HYG Star ID": 119227,
    "Gliese Catalog": "NN 4065",
    "Distance in Parsecs": 11.236,
    "mag": 12.46,
    "Absolute Visual Magnitude": 12.207,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.723552,
    "y": -10.783775,
    "z": 2.643343,
    "vx": 0.00000966,
    "vy": 0.0000052,
    "vz": 0.00001492,
    "rarad": 4.87087542738847,
    "decrad": 0.23748255270085,
    "pmrarad": 9.21727769472222e-7,
    "pmdecrad": 0.000001366554017,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119227,
    "lum": 0.00114077501292211,
    "ra": 18.605374,
    "dec": 13.606748,
    "pmra": 190.12,
    "pmdec": 281.87,
    "rv": 0,
    "Asset Name": "ASTRID 000455",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Maniacal",
    "Hair": "Goddess",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000966,
    "y_1": 0.0000052,
    "z_1": 0.00001492,
    "Distance in Parsecs_1": 11.236,
    "HYG Star ID_1": 119227,
    "Gliese Catalog_1": "NN 4065"
  },
  {
    "Astrid #": "ASTRID 000456",
    "HYG Star ID": 26270,
    "Hipparcos Catalogue": 26335,
    "Henry Draper Catalogue": 245409,
    "Gliese Catalog": "Gl 208",
    "Distance in Parsecs": 11.2397,
    "mag": 8.78,
    "Absolute Visual Magnitude": 8.526,
    "Spectral Type": "K7",
    "Color Index": 1.415,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.127296,
    "y": 10.963123,
    "z": 2.207773,
    "vx": 0.00000238,
    "vy": 0.00002175,
    "vz": 0.00000118,
    "rarad": 1.46833027040173,
    "decrad": 0.19770855853905,
    "pmrarad": -1.24112302222222e-8,
    "pmdecrad": -2.7925268e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 26270,
    "lum": 0.0338532212815354,
    "var": "V2689",
    "var_min": 8.824,
    "var_max": 8.754,
    "ra": 5.608608,
    "dec": 11.327866,
    "pmra": -2.56,
    "pmdec": -57.6,
    "rv": 21.2,
    "Asset Name": "ASTRID 000456",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Worried Vamp",
    "Hair": "Goddess",
    "Outfit": "Wizard",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000238,
    "y_1": 0.00002175,
    "z_1": 0.00000118,
    "Distance in Parsecs_1": 11.2397,
    "HYG Star ID_1": 26270,
    "Hipparcos Catalogue_1": 26335,
    "Henry Draper Catalogue_1": 245409,
    "Gliese Catalog_1": "Gl 208"
  },
  {
    "Astrid #": "ASTRID 000457",
    "HYG Star ID": 428,
    "Hipparcos Catalogue": 428,
    "Gliese Catalog": "Gl 2",
    "Distance in Parsecs": 11.2511,
    "mag": 9.95,
    "Absolute Visual Magnitude": 9.694,
    "Spectral Type": "M2",
    "Color Index": 1.472,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.843761,
    "y": 0.177365,
    "z": 8.064194,
    "vx": 0.0000049100000000000004,
    "vy": 0.00004756,
    "vz": -0.00000567,
    "rarad": 0.0225924178666492,
    "decrad": 0.799126346884493,
    "pmrarad": 0.00000421632761705555,
    "pmdecrad": -7.32698915e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 428,
    "lum": 0.0115451611692143,
    "var_min": 10.005,
    "var_max": 9.895,
    "ra": 0.086297,
    "dec": 45.786567,
    "pmra": 869.68,
    "pmdec": -151.13,
    "rv": 0.1,
    "Asset Name": "ASTRID 000457",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Middle Part",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.0000049100000000000004,
    "y_1": 0.00004756,
    "z_1": -0.00000567,
    "Distance in Parsecs_1": 11.2511,
    "HYG Star ID_1": 428,
    "Hipparcos Catalogue_1": 428,
    "Gliese Catalog_1": "Gl 2"
  },
  {
    "Astrid #": "ASTRID 000458",
    "HYG Star ID": 77836,
    "Hipparcos Catalogue": 78072,
    "Henry Draper Catalogue": 142860,
    "Harvard Revised Catalogue": 5933,
    "Gliese Catalog": "Gl 603",
    "Distance in Parsecs": 11.2537,
    "mag": 3.85,
    "Absolute Visual Magnitude": 3.594,
    "Spectral Type": "F6V",
    "Color Index": 0.478,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.56255,
    "y": -9.299226,
    "z": 3.038014,
    "vx": 0.00000154,
    "vy": -0.0000305,
    "vz": -0.00006553,
    "rarad": 4.17331318447652,
    "decrad": 0.273346779813788,
    "pmrarad": 0.00000150874017388888,
    "pmdecrad": -0.000006216135567,
    "bayer": "Gam",
    "flam": 41,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 77836,
    "lum": 3.17980142769152,
    "bf": "41Gam Ser",
    "ra": 15.940882,
    "dec": 15.661617,
    "pmra": 311.2,
    "pmdec": -1282.17,
    "rv": 6.6,
    "Asset Name": "ASTRID 000458",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Medium Bob",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00000154,
    "y_1": -0.0000305,
    "z_1": -0.00006553,
    "Distance in Parsecs_1": 11.2537,
    "HYG Star ID_1": 77836,
    "Hipparcos Catalogue_1": 78072,
    "Henry Draper Catalogue_1": 142860,
    "Harvard Revised Catalogue_1": 5933,
    "Gliese Catalog_1": "Gl 603"
  },
  {
    "Astrid #": "ASTRID 000459",
    "HYG Star ID": 69451,
    "Hipparcos Catalogue": 69673,
    "Henry Draper Catalogue": 124897,
    "Harvard Revised Catalogue": 5340,
    "Gliese Catalog": "Gl 541",
    "Proper Name": "Arcturus",
    "Distance in Parsecs": 11.2575,
    "mag": -0.05,
    "Absolute Visual Magnitude": -0.307,
    "Spectral Type": "K2IIIp",
    "Color Index": 1.239,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.823448,
    "y": -5.932531,
    "z": 3.698938,
    "vx": -0.00005905,
    "vy": 0.0000322,
    "vz": -0.00010473,
    "rarad": 3.73352902854208,
    "decrad": 0.334796218058621,
    "pmrarad": -0.00000530119519003472,
    "pmdecrad": -0.000009693364729,
    "bayer": "Alp",
    "flam": 16,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 69451,
    "lum": 115.557995540212,
    "bf": "16Alp Boo",
    "ra": 14.26103,
    "dec": 19.18241,
    "pmra": -1093.45,
    "pmdec": -1999.4,
    "rv": -5,
    "Asset Name": "ASTRID 000459",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Chubby Cheeks",
    "Hair": "Halo Boy",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Arcturus",
    "Constellation": "Boötes",
    "x_1": -0.00005905,
    "y_1": 0.0000322,
    "z_1": -0.00010473,
    "Distance in Parsecs_1": 11.2575,
    "HYG Star ID_1": 69451,
    "Hipparcos Catalogue_1": 69673,
    "Henry Draper Catalogue_1": 124897,
    "Harvard Revised Catalogue_1": 5340,
    "Gliese Catalog_1": "Gl 541"
  },
  {
    "Astrid #": "ASTRID 000460",
    "HYG Star ID": 47378,
    "Hipparcos Catalogue": 47513,
    "Gliese Catalog": "Gl 361",
    "Distance in Parsecs": 11.26,
    "mag": 10.38,
    "Absolute Visual Magnitude": 10.122,
    "Spectral Type": "M2",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.011736,
    "y": 6.241614,
    "z": 2.573084,
    "vx": 0.00000986,
    "vy": 0.00003728,
    "vz": -0.00000486,
    "rarad": 2.53584475229564,
    "decrad": 0.230550418703773,
    "pmrarad": -0.00000322042335445833,
    "pmdecrad": -6.86205283e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 47378,
    "lum": 0.00778394932626726,
    "ra": 9.686213,
    "dec": 13.209566,
    "pmra": -664.26,
    "pmdec": -141.54,
    "rv": 11.4,
    "Asset Name": "ASTRID 000460",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Frizzy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000986,
    "y_1": 0.00003728,
    "z_1": -0.00000486,
    "Distance in Parsecs_1": 11.26,
    "HYG Star ID_1": 47378,
    "Hipparcos Catalogue_1": 47513,
    "Gliese Catalog_1": "Gl 361"
  },
  {
    "Astrid #": "ASTRID 000461",
    "HYG Star ID": 472,
    "Hipparcos Catalogue": 473,
    "Henry Draper Catalogue": 38,
    "Gliese Catalog": "Gl 4A",
    "Distance in Parsecs": 11.3071,
    "mag": 8.2,
    "Absolute Visual Magnitude": 7.933,
    "Spectral Type": "K2",
    "Color Index": 1.41,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.878784,
    "y": 0.195428,
    "z": 8.107843,
    "vx": 0.000005,
    "vy": 0.0000483,
    "vz": -0.00000573,
    "rarad": 0.0247829497784825,
    "decrad": 0.799571648650412,
    "pmrarad": 0.00000426020325514583,
    "pmdecrad": -7.4598281e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 472,
    "base": "Gl   4",
    "lum": 0.0584520840358855,
    "var_min": 8.311,
    "var_max": 8.171,
    "ra": 0.094664,
    "dec": 45.812081,
    "pmra": 878.73,
    "pmdec": -153.87,
    "rv": 0.2,
    "Asset Name": "ASTRID 000461",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Bandana",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.000005,
    "y_1": 0.0000483,
    "z_1": -0.00000573,
    "Distance in Parsecs_1": 11.3071,
    "HYG Star ID_1": 472,
    "Hipparcos Catalogue_1": 473,
    "Henry Draper Catalogue_1": 38,
    "Gliese Catalog_1": "Gl 4A"
  },
  {
    "Astrid #": "ASTRID 000462",
    "HYG Star ID": 118570,
    "Henry Draper Catalogue": 75632,
    "Gliese Catalog": "Gl 325A",
    "Distance in Parsecs": 11.3122,
    "mag": 8.7,
    "Absolute Visual Magnitude": 8.432,
    "Spectral Type": "K5 V",
    "Color Index": 1.39,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.578766,
    "y": 2.68337,
    "z": 10.684878,
    "vx": 0.00002971,
    "vy": 0.00007463,
    "vz": 0.00003643,
    "rarad": 2.33697810194556,
    "decrad": 1.2356299409527,
    "pmrarad": -0.00000646848108870833,
    "pmdecrad": -0.000001709040946,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 118570,
    "base": "Gl 325",
    "lum": 0.0369147558165412,
    "ra": 8.926599,
    "dec": 70.796381,
    "pmra": -1334.22,
    "pmdec": -352.51,
    "rv": 44.3,
    "Asset Name": "ASTRID 000462",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Fired Up",
    "Hair": "Grecian",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00002971,
    "y_1": 0.00007463,
    "z_1": 0.00003643,
    "Distance in Parsecs_1": 11.3122,
    "HYG Star ID_1": 118570,
    "Henry Draper Catalogue_1": 75632,
    "Gliese Catalog_1": "Gl 325A"
  },
  {
    "Astrid #": "ASTRID 000463",
    "HYG Star ID": 118571,
    "Gliese Catalog": "Gl 325B",
    "Distance in Parsecs": 11.3122,
    "mag": 8.9,
    "Absolute Visual Magnitude": 8.632,
    "Spectral Type": "K6 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.578797,
    "y": 2.68345,
    "z": 10.68485,
    "vx": 0.00002908,
    "vy": 0.00007625,
    "vz": 0.00003587,
    "rarad": 2.33697513973397,
    "decrad": 1.2356224197957,
    "pmrarad": -0.00000652772532047222,
    "pmdecrad": -0.000001859464086,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 118570,
    "base": "Gl 325",
    "lum": 0.0307043565073006,
    "ra": 8.926587,
    "dec": 70.79595,
    "pmra": -1346.44,
    "pmdec": -383.54,
    "rv": 44.3,
    "Asset Name": "ASTRID 000463",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Grecian",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00002908,
    "y_1": 0.00007625,
    "z_1": 0.00003587,
    "Distance in Parsecs_1": 11.3122,
    "HYG Star ID_1": 118571,
    "Gliese Catalog_1": "Gl 325B"
  },
  {
    "Astrid #": "ASTRID 000464",
    "HYG Star ID": 48521,
    "Hipparcos Catalogue": 48659,
    "Distance in Parsecs": 11.3225,
    "mag": 12.04,
    "Absolute Visual Magnitude": 11.77,
    "Color Index": 1.635,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -8.613627,
    "y": 5.206448,
    "z": -5.186264,
    "vx": 0.00000626,
    "vy": 0.00000286,
    "vz": -0.00000753,
    "rarad": 2.5979130971945,
    "decrad": -0.475799551045425,
    "pmrarad": -5.02606342631944e-7,
    "pmdecrad": -7.4777662e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 48521,
    "lum": 0.00170608238900312,
    "var_min": 12.157,
    "var_max": 11.927,
    "ra": 9.923297,
    "dec": -27.261306,
    "pmra": -103.67,
    "pmdec": -154.24,
    "rv": 0,
    "Asset Name": "ASTRID 000464",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Pixie",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000626,
    "y_1": 0.00000286,
    "z_1": -0.00000753,
    "Distance in Parsecs_1": 11.3225,
    "HYG Star ID_1": 48521,
    "Hipparcos Catalogue_1": 48659
  },
  {
    "Astrid #": "ASTRID 000465",
    "HYG Star ID": 28980,
    "Hipparcos Catalogue": 29052,
    "Distance in Parsecs": 11.3456,
    "mag": 11.87,
    "Absolute Visual Magnitude": 11.596,
    "Color Index": 1.6,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.344573,
    "y": 10.213593,
    "z": -4.928126,
    "vx": 0.00001029,
    "vy": -0.00000453,
    "vz": -0.0000101,
    "rarad": 1.60452108613656,
    "decrad": -0.44933264465156,
    "pmrarad": -8.92784392743055e-7,
    "pmdecrad": -9.88874464e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 28980,
    "lum": 0.0020026266903409,
    "var_min": 11.971,
    "var_max": 11.761,
    "ra": 6.128819,
    "dec": -25.744864,
    "pmra": -184.15,
    "pmdec": -203.97,
    "rv": 0,
    "Asset Name": "ASTRID 000465",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Windy",
    "Outfit": "Astro",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00001029,
    "y_1": -0.00000453,
    "z_1": -0.0000101,
    "Distance in Parsecs_1": 11.3456,
    "HYG Star ID_1": 28980,
    "Hipparcos Catalogue_1": 29052
  },
  {
    "Astrid #": "ASTRID 000466",
    "HYG Star ID": 47515,
    "Hipparcos Catalogue": 47650,
    "Gliese Catalog": "Gl 362",
    "Distance in Parsecs": 11.3546,
    "mag": 11.19,
    "Absolute Visual Magnitude": 10.914,
    "Spectral Type": "M4",
    "Color Index": 1.488,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.202697,
    "y": 2.183459,
    "z": 10.672553,
    "vx": 0.00000772,
    "vy": 0.000039280000000000003,
    "vz": 8.1e-7,
    "rarad": 2.54327080694553,
    "decrad": 1.22241839213664,
    "pmrarad": -0.000003241949081875,
    "pmdecrad": -0.000001278841526,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 47515,
    "lum": 0.00375318524237913,
    "var_min": 11.27,
    "var_max": 11.11,
    "ra": 9.714579,
    "dec": 70.039415,
    "pmra": -668.7,
    "pmdec": -263.78,
    "rv": 6,
    "Asset Name": "ASTRID 000466",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Skater Helmet",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000772,
    "y_1": 0.000039280000000000003,
    "z_1": 8.1e-7,
    "Distance in Parsecs_1": 11.3546,
    "HYG Star ID_1": 47515,
    "Hipparcos Catalogue_1": 47650,
    "Gliese Catalog_1": "Gl 362"
  },
  {
    "Astrid #": "ASTRID 000467",
    "HYG Star ID": 118130,
    "Gliese Catalog": "NN 3149B",
    "Distance in Parsecs": 11.3636,
    "mag": 13,
    "Absolute Visual Magnitude": 12.722,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.064134,
    "y": 5.469104,
    "z": -5.847226,
    "vx": -0.00001448,
    "vy": 0.00003427,
    "vz": 0.00001207,
    "rarad": 0.595918768291,
    "decrad": -0.54049156053885,
    "pmrarad": 0.00000321140582,
    "pmdecrad": 0.000001239169223,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118130,
    "base": "NN 3149",
    "lum": 0.000709904615855287,
    "ra": 2.276242,
    "dec": -30.967885,
    "pmra": 662.4,
    "pmdec": 255.6,
    "rv": 0,
    "Asset Name": "ASTRID 000467",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Bandana",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001448,
    "y_1": 0.00003427,
    "z_1": 0.00001207,
    "Distance in Parsecs_1": 11.3636,
    "HYG Star ID_1": 118130,
    "Gliese Catalog_1": "NN 3149B"
  },
  {
    "Astrid #": "ASTRID 000468",
    "HYG Star ID": 119207,
    "Gliese Catalog": "NN 4048A",
    "Distance in Parsecs": 11.3636,
    "mag": 11.87,
    "Absolute Visual Magnitude": 11.592,
    "Spectral Type": "m",
    "Color Index": 1.58,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.697458,
    "y": -8.831631,
    "z": 7.116689,
    "vx": -0.00001548,
    "vy": -0.00003715,
    "vz": -0.00004456,
    "rarad": 4.79122097761675,
    "decrad": 0.67676030209065,
    "pmrarad": -0.00000161544766497916,
    "pmdecrad": -0.000005031498187,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 119207,
    "base": "NN 4048",
    "lum": 0.00201001824711369,
    "ra": 18.301116,
    "dec": 38.775509,
    "pmra": -333.21,
    "pmdec": -1037.82,
    "rv": 0,
    "Asset Name": "ASTRID 000468",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Ninja",
    "Hair": "Pixie",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00001548,
    "y_1": -0.00003715,
    "z_1": -0.00004456,
    "Distance in Parsecs_1": 11.3636,
    "HYG Star ID_1": 119207,
    "Gliese Catalog_1": "NN 4048A"
  },
  {
    "Astrid #": "ASTRID 000469",
    "HYG Star ID": 119208,
    "Gliese Catalog": "NN 4049B",
    "Distance in Parsecs": 11.3636,
    "mag": 13.53,
    "Absolute Visual Magnitude": 13.252,
    "Spectral Type": "m",
    "Color Index": 1.77,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.696816,
    "y": -8.831684,
    "z": 7.116686,
    "vx": -0.00001548,
    "vy": -0.00003715,
    "vz": -0.00004456,
    "rarad": 4.79114823461675,
    "decrad": 0.67675994909065,
    "pmrarad": -0.00000161544766497916,
    "pmdecrad": -0.000005031498187,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119208,
    "base": "NN 4049",
    "lum": 0.000435712480503083,
    "ra": 18.300838,
    "dec": 38.775489,
    "pmra": -333.21,
    "pmdec": -1037.82,
    "rv": 0,
    "Asset Name": "ASTRID 000469",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Shocked",
    "Hair": "Unkempt",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001548,
    "y_1": -0.00003715,
    "z_1": -0.00004456,
    "Distance in Parsecs_1": 11.3636,
    "HYG Star ID_1": 119208,
    "Gliese Catalog_1": "NN 4049B"
  },
  {
    "Astrid #": "ASTRID 000470",
    "HYG Star ID": 46945,
    "Hipparcos Catalogue": 47080,
    "Henry Draper Catalogue": 82885,
    "Harvard Revised Catalogue": 3815,
    "Gliese Catalog": "Gl 356A",
    "Distance in Parsecs": 11.3688,
    "mag": 5.4,
    "Absolute Visual Magnitude": 5.121,
    "Spectral Type": "G8IV-V",
    "Color Index": 0.77,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.450851,
    "y": 5.430348,
    "z": 6.651975,
    "vx": 0.00000692,
    "vy": 0.00004474,
    "vz": -0.00000273,
    "rarad": 2.51179070240509,
    "decrad": 0.625004734187627,
    "pmrarad": -0.00000353938227489583,
    "pmdecrad": -0.000001263521414,
    "flam": 11,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 46945,
    "base": "Gl 356",
    "lum": 0.779112191113092,
    "var": "SV",
    "var_min": 5.407,
    "var_max": 5.387,
    "bf": "11    LMi",
    "ra": 9.594334,
    "dec": 35.810133,
    "pmra": -730.05,
    "pmdec": -260.62,
    "rv": 14.9,
    "Asset Name": "ASTRID 000470",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Oh Snap",
    "Hair": "Round Brush",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": 0.00000692,
    "y_1": 0.00004474,
    "z_1": -0.00000273,
    "Distance in Parsecs_1": 11.3688,
    "HYG Star ID_1": 46945,
    "Hipparcos Catalogue_1": 47080,
    "Henry Draper Catalogue_1": 82885,
    "Harvard Revised Catalogue_1": 3815,
    "Gliese Catalog_1": "Gl 356A"
  },
  {
    "Astrid #": "ASTRID 000471",
    "HYG Star ID": 20865,
    "Hipparcos Catalogue": 20917,
    "Henry Draper Catalogue": 28343,
    "Gliese Catalog": "Gl 169",
    "Distance in Parsecs": 11.3921,
    "mag": 8.3,
    "Absolute Visual Magnitude": 8.017,
    "Spectral Type": "K7V",
    "Color Index": 1.363,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.086695,
    "y": 9.745911,
    "z": 4.253197,
    "vx": -0.0000096,
    "vy": -0.0000322,
    "vz": -0.000003,
    "rarad": 1.17374322468322,
    "decrad": 0.38262332704114,
    "pmrarad": -3.15807631513888e-7,
    "pmdecrad": 8.51090416e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 20865,
    "lum": 0.0541003406863467,
    "ra": 4.483369,
    "dec": 21.922702,
    "pmra": -65.14,
    "pmdec": 175.55,
    "rv": -31.4,
    "Asset Name": "ASTRID 000471",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Pompadour",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.0000096,
    "y_1": -0.0000322,
    "z_1": -0.000003,
    "Distance in Parsecs_1": 11.3921,
    "HYG Star ID_1": 20865,
    "Hipparcos Catalogue_1": 20917,
    "Henry Draper Catalogue_1": 28343,
    "Gliese Catalog_1": "Gl 169"
  },
  {
    "Astrid #": "ASTRID 000472",
    "HYG Star ID": 67711,
    "Hipparcos Catalogue": 67927,
    "Henry Draper Catalogue": 121370,
    "Harvard Revised Catalogue": 5235,
    "Gliese Catalog": "Gl 534",
    "Proper Name": "Mufrid",
    "Distance in Parsecs": 11.396,
    "mag": 2.68,
    "Absolute Visual Magnitude": 2.396,
    "Spectral Type": "G0IV",
    "Color Index": 0.58,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.487671,
    "y": -5.188142,
    "z": 3.596709,
    "vx": -0.00000795,
    "vy": -5.1e-7,
    "vz": -0.00001845,
    "rarad": 3.64199895242804,
    "decrad": 0.321100735969337,
    "pmrarad": -2.95493938298611e-7,
    "pmdecrad": -0.00000173611779,
    "bayer": "Eta",
    "flam": 8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 67711,
    "lum": 9.58517397685645,
    "bf": "8Eta Boo",
    "ra": 13.911411,
    "dec": 18.397717,
    "pmra": -60.95,
    "pmdec": -358.1,
    "rv": 1,
    "Asset Name": "ASTRID 000472",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Oh no",
    "Hair": "Tiny Crown",
    "Outfit": "Emperor",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "Mufrid",
    "Constellation": "Boötes",
    "x_1": -0.00000795,
    "y_1": -5.1e-7,
    "z_1": -0.00001845,
    "Distance in Parsecs_1": 11.396,
    "HYG Star ID_1": 67711,
    "Hipparcos Catalogue_1": 67927,
    "Henry Draper Catalogue_1": 121370,
    "Harvard Revised Catalogue_1": 5235,
    "Gliese Catalog_1": "Gl 534"
  },
  {
    "Astrid #": "ASTRID 000473",
    "HYG Star ID": 74958,
    "Hipparcos Catalogue": 75187,
    "Gliese Catalog": "Wo 9520",
    "Distance in Parsecs": 11.4116,
    "mag": 10.66,
    "Absolute Visual Magnitude": 10.373,
    "Spectral Type": "M9",
    "Color Index": 2.1,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.781787,
    "y": -8.218354,
    "z": 4.085415,
    "vx": 0.00000498,
    "vy": -7.9e-7,
    "vz": 0.00000668,
    "rarad": 4.02246957079133,
    "decrad": 0.366130916606048,
    "pmrarad": 3.80433295131944e-7,
    "pmdecrad": 6.27154977e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 74958,
    "lum": 0.0061773185859152,
    "var": "OT",
    "var_min": 10.728,
    "var_max": 10.598,
    "ra": 15.364702,
    "dec": 20.977756,
    "pmra": 78.47,
    "pmdec": 129.36,
    "rv": 0,
    "Asset Name": "ASTRID 000473",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Honest Lad",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00000498,
    "y_1": -7.9e-7,
    "z_1": 0.00000668,
    "Distance in Parsecs_1": 11.4116,
    "HYG Star ID_1": 74958,
    "Hipparcos Catalogue_1": 75187,
    "Gliese Catalog_1": "Wo 9520"
  },
  {
    "Astrid #": "ASTRID 000474",
    "HYG Star ID": 8036,
    "Hipparcos Catalogue": 8051,
    "Gliese Catalog": "Gl 70",
    "Distance in Parsecs": 11.4129,
    "mag": 10.93,
    "Absolute Visual Magnitude": 10.643,
    "Spectral Type": "M2V:",
    "Color Index": 1.525,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.242877,
    "y": 4.95912,
    "z": 0.860009,
    "vx": -0.00001069,
    "vy": -0.000031,
    "vz": -0.0000441,
    "rarad": 0.450889891158272,
    "decrad": 0.0754271903550287,
    "pmrarad": -0.00000203689619748611,
    "pmdecrad": -0.000003700873711,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 8036,
    "lum": 0.00481725903559162,
    "var_min": 10.995,
    "var_max": 10.875,
    "ra": 1.722273,
    "dec": 4.32166,
    "pmra": -420.14,
    "pmdec": -763.36,
    "rv": -25.8,
    "Asset Name": "ASTRID 000474",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Innocent",
    "Outfit": "Emperor",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001069,
    "y_1": -0.000031,
    "z_1": -0.0000441,
    "Distance in Parsecs_1": 11.4129,
    "HYG Star ID_1": 8036,
    "Hipparcos Catalogue_1": 8051,
    "Gliese Catalog_1": "Gl 70"
  },
  {
    "Astrid #": "ASTRID 000475",
    "HYG Star ID": 116379,
    "Hipparcos Catalogue": 116745,
    "Henry Draper Catalogue": 222237,
    "Gliese Catalog": "Gl 902",
    "Distance in Parsecs": 11.4207,
    "mag": 7.09,
    "Absolute Visual Magnitude": 6.802,
    "Spectral Type": "K3V",
    "Color Index": 0.989,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.378804,
    "y": -0.301207,
    "z": -10.905962,
    "vx": -0.00001641,
    "vy": 0.0000093,
    "vz": -0.00008214,
    "rarad": 6.19426021535826,
    "decrad": -1.26924104148325,
    "pmrarad": 6.83926659159722e-7,
    "pmdecrad": -0.000003572398086,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 116379,
    "lum": 0.165653264931801,
    "ra": 23.660331,
    "dec": -72.722155,
    "pmra": 141.07,
    "pmdec": -736.86,
    "rv": 71.7,
    "Asset Name": "ASTRID 000475",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Bob",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00001641,
    "y_1": 0.0000093,
    "z_1": -0.00008214,
    "Distance in Parsecs_1": 11.4207,
    "HYG Star ID_1": 116379,
    "Hipparcos Catalogue_1": 116745,
    "Henry Draper Catalogue_1": 222237,
    "Gliese Catalog_1": "Gl 902"
  },
  {
    "Astrid #": "ASTRID 000476",
    "HYG Star ID": 91416,
    "Hipparcos Catalogue": 91699,
    "Gliese Catalog": "NN 4070",
    "Distance in Parsecs": 11.4469,
    "mag": 11.27,
    "Absolute Visual Magnitude": 10.977,
    "Spectral Type": "M4",
    "Color Index": 1.579,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.771646,
    "y": -9.562697,
    "z": 6.037186,
    "vx": -0.00001659,
    "vy": -0.00000282,
    "vz": 4e-7,
    "rarad": 4.89558053165093,
    "decrad": 0.555546851398081,
    "pmrarad": -0.000001470439893125,
    "pmdecrad": 4.1645495e-8,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 91416,
    "lum": 0.00354160400426461,
    "var_min": 11.343,
    "var_max": 11.193,
    "ra": 18.69974,
    "dec": 31.83049,
    "pmra": -303.3,
    "pmdec": 8.59,
    "rv": 0,
    "Asset Name": "ASTRID 000476",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Undercut",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00001659,
    "y_1": -0.00000282,
    "z_1": 4e-7,
    "Distance in Parsecs_1": 11.4469,
    "HYG Star ID_1": 91416,
    "Hipparcos Catalogue_1": 91699,
    "Gliese Catalog_1": "NN 4070"
  },
  {
    "Astrid #": "ASTRID 000477",
    "HYG Star ID": 36727,
    "Hipparcos Catalogue": 36834,
    "Gliese Catalog": "Gl 277.1",
    "Distance in Parsecs": 11.4745,
    "mag": 10.4,
    "Absolute Visual Magnitude": 10.101,
    "Spectral Type": "M0",
    "Color Index": 0.942,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.090916,
    "y": 4.782662,
    "z": 10.218531,
    "vx": 0.0000232,
    "vy": 0.00001582,
    "vz": -0.00000265,
    "rarad": 1.98296883338941,
    "decrad": 1.09853642416801,
    "pmrarad": -0.00000240486978102777,
    "pmdecrad": -5.08666513e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 36727,
    "lum": 0.00793596968195768,
    "var_min": 10.463,
    "var_max": 10.303,
    "ra": 7.574383,
    "dec": 62.941501,
    "pmra": -496.04,
    "pmdec": -104.92,
    "rv": 0,
    "Asset Name": "ASTRID 000477",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Side Part",
    "Outfit": "Samurai",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.0000232,
    "y_1": 0.00001582,
    "z_1": -0.00000265,
    "Distance in Parsecs_1": 11.4745,
    "HYG Star ID_1": 36727,
    "Hipparcos Catalogue_1": 36834,
    "Gliese Catalog_1": "Gl 277.1"
  },
  {
    "Astrid #": "ASTRID 000478",
    "HYG Star ID": 118540,
    "Gliese Catalog": "NN 3500",
    "Distance in Parsecs": 11.4943,
    "mag": 11.85,
    "Absolute Visual Magnitude": 11.548,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.873376,
    "y": 6.507064,
    "z": -8.125716,
    "vx": -0.00000529,
    "vy": 0.00002282,
    "vz": 0.00002144,
    "rarad": 2.21363328504057,
    "decrad": -0.78515439104625,
    "pmrarad": -8.21759188541666e-7,
    "pmdecrad": 0.000002638419075,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 118540,
    "lum": 0.00209314830344744,
    "ra": 8.455456,
    "dec": -44.986033,
    "pmra": -169.5,
    "pmdec": 544.21,
    "rv": 0,
    "Asset Name": "ASTRID 000478",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Headband",
    "Outfit": "Tats",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000529,
    "y_1": 0.00002282,
    "z_1": 0.00002144,
    "Distance in Parsecs_1": 11.4943,
    "HYG Star ID_1": 118540,
    "Gliese Catalog_1": "NN 3500"
  },
  {
    "Astrid #": "ASTRID 000479",
    "HYG Star ID": 118556,
    "Gliese Catalog": "Gl 317",
    "Distance in Parsecs": 11.4943,
    "mag": 11.98,
    "Absolute Visual Magnitude": 11.678,
    "Spectral Type": "m",
    "Color Index": 1.52,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.810779,
    "y": 8.046646,
    "z": -4.573996,
    "vx": 0.00004093,
    "vy": -0.00000727,
    "vz": 0.00006241,
    "rarad": 2.27321415386266,
    "decrad": -0.409368214659,
    "pmrarad": -0.00000230868274680555,
    "pmdecrad": 0.00000387284682,
    "con": "Pyx",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pyxis",
    "FULL CONSTELLATION NAME": "Pyxis",
    "comp": 1,
    "comp_primary": 118556,
    "lum": 0.00185694910149045,
    "ra": 8.683038,
    "dec": -23.455071,
    "pmra": -476.2,
    "pmdec": 798.83,
    "rv": -53,
    "Asset Name": "ASTRID 000479",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Mischievous",
    "Hair": "Curly",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pyxis",
    "x_1": 0.00004093,
    "y_1": -0.00000727,
    "z_1": 0.00006241,
    "Distance in Parsecs_1": 11.4943,
    "HYG Star ID_1": 118556,
    "Gliese Catalog_1": "Gl 317"
  },
  {
    "Astrid #": "ASTRID 000480",
    "HYG Star ID": 118618,
    "Gliese Catalog": "NN 3550",
    "Distance in Parsecs": 11.4943,
    "mag": 15.02,
    "Absolute Visual Magnitude": 14.718,
    "Spectral Type": "m",
    "Color Index": 1.9,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.570256,
    "y": 2.130725,
    "z": 10.99876,
    "vx": 0.00001897,
    "vy": 0.0000515,
    "vz": -0.00000553,
    "rarad": 2.44997133238063,
    "decrad": 1.27609263243369,
    "pmrarad": -0.00000450445238740972,
    "pmdecrad": -0.000001657311326,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118618,
    "lum": 0.000112927574420941,
    "ra": 9.358201,
    "dec": 73.114722,
    "pmra": -929.11,
    "pmdec": -341.85,
    "rv": 0,
    "Asset Name": "ASTRID 000480",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Pixie",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001897,
    "y_1": 0.0000515,
    "z_1": -0.00000553,
    "Distance in Parsecs_1": 11.4943,
    "HYG Star ID_1": 118618,
    "Gliese Catalog_1": "NN 3550"
  },
  {
    "Astrid #": "ASTRID 000481",
    "HYG Star ID": 119099,
    "Gliese Catalog": "NN 3966",
    "Distance in Parsecs": 11.4943,
    "mag": 12.95,
    "Absolute Visual Magnitude": 12.648,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.394369,
    "y": -8.780522,
    "z": 6.595425,
    "vx": 0.00000513,
    "vy": -0.00000675,
    "vz": -0.00000634,
    "rarad": 4.34349841907836,
    "decrad": 0.6111377208727,
    "pmrarad": 6.28221567263888e-7,
    "pmdecrad": -6.73682546e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119099,
    "lum": 0.000759976214361036,
    "ra": 16.590942,
    "dec": 35.015612,
    "pmra": 129.58,
    "pmdec": -138.96,
    "rv": 0,
    "Asset Name": "ASTRID 000481",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "High Fade",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000513,
    "y_1": -0.00000675,
    "z_1": -0.00000634,
    "Distance in Parsecs_1": 11.4943,
    "HYG Star ID_1": 119099,
    "Gliese Catalog_1": "NN 3966"
  },
  {
    "Astrid #": "ASTRID 000482",
    "HYG Star ID": 96932,
    "Hipparcos Catalogue": 97241,
    "Gliese Catalog": "NN 4122",
    "Distance in Parsecs": 11.4956,
    "mag": 10.61,
    "Absolute Visual Magnitude": 10.307,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.324957,
    "y": -8.69075,
    "z": 6.157469,
    "vx": 0.00001676,
    "vy": 0.00001481,
    "vz": 0.00000912,
    "rarad": 5.17415225900124,
    "decrad": 0.565261914197467,
    "pmrarad": 0.00000187942871404166,
    "pmdecrad": 9.39908282e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96932,
    "lum": 0.00656447501951699,
    "var_min": 10.811,
    "var_max": 10.481,
    "ra": 19.763806,
    "dec": 32.387122,
    "pmra": 387.66,
    "pmdec": 193.87,
    "rv": 0,
    "Asset Name": "ASTRID 000482",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Infatuated",
    "Hair": "Neko",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001676,
    "y_1": 0.00001481,
    "z_1": 0.00000912,
    "Distance in Parsecs_1": 11.4956,
    "HYG Star ID_1": 96932,
    "Hipparcos Catalogue_1": 97241,
    "Gliese Catalog_1": "NN 4122"
  },
  {
    "Astrid #": "ASTRID 000483",
    "HYG Star ID": 72622,
    "Hipparcos Catalogue": 72848,
    "Henry Draper Catalogue": 131511,
    "Harvard Revised Catalogue": 5553,
    "Gliese Catalog": "Gl 567",
    "Distance in Parsecs": 11.5101,
    "mag": 6,
    "Absolute Visual Magnitude": 5.695,
    "Spectral Type": "K2V",
    "Color Index": 0.841,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.906486,
    "y": -7.463478,
    "z": 3.776241,
    "vx": 0.00000771,
    "vy": 0.00004125,
    "vz": 0.00000103,
    "rarad": 3.898176990015,
    "decrad": 0.33427939361236,
    "pmrarad": -0.00000214651257065972,
    "pmdecrad": 0.000001051269984,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72622,
    "lum": 0.459198012836868,
    "var": "DE",
    "var_min": 6.047,
    "var_max": 5.977,
    "ra": 14.889939,
    "dec": 19.152798,
    "pmra": -442.75,
    "pmdec": 216.84,
    "rv": -31,
    "Asset Name": "ASTRID 000483",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Horns",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000771,
    "y_1": 0.00004125,
    "z_1": 0.00000103,
    "Distance in Parsecs_1": 11.5101,
    "HYG Star ID_1": 72622,
    "Hipparcos Catalogue_1": 72848,
    "Henry Draper Catalogue_1": 131511,
    "Harvard Revised Catalogue_1": 5553,
    "Gliese Catalog_1": "Gl 567"
  },
  {
    "Astrid #": "ASTRID 000484",
    "HYG Star ID": 118477,
    "Gliese Catalog": "Gl 275.2A",
    "Distance in Parsecs": 11.5607,
    "mag": 13.56,
    "Absolute Visual Magnitude": 13.245,
    "Spectral Type": "sdM5",
    "Color Index": 1.71,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": -2.971549,
    "y": 7.109158,
    "z": 8.618559,
    "vx": -0.00000914,
    "vy": 0.00005376,
    "vz": -0.00004747,
    "rarad": 1.96674017573008,
    "decrad": 0.84129209724245,
    "pmrarad": -0.00000106450539839583,
    "pmdecrad": -0.000006162835151,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118477,
    "base": "Gl 275.2",
    "lum": 0.000438530697774985,
    "ra": 7.512394,
    "dec": 48.202487,
    "pmra": -219.57,
    "pmdec": -1271.18,
    "rv": 0,
    "Asset Name": "ASTRID 000484",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oof",
    "Hair": "Hachimaki",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000914,
    "y_1": 0.00005376,
    "z_1": -0.00004747,
    "Distance in Parsecs_1": 11.5607,
    "HYG Star ID_1": 118477,
    "Gliese Catalog_1": "Gl 275.2A"
  },
  {
    "Astrid #": "ASTRID 000485",
    "HYG Star ID": 118478,
    "Gliese Catalog": "Gl 275.2B",
    "Distance in Parsecs": 11.5607,
    "mag": 14.63,
    "Absolute Visual Magnitude": 14.315,
    "Spectral Type": "DC",
    "Color Index": 0.99,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.975132,
    "y": 7.112019,
    "z": 8.614962,
    "vx": -0.00000915,
    "vy": 0.00005374,
    "vz": -0.00004749,
    "rarad": 1.96702572773008,
    "decrad": 0.840825363242449,
    "pmrarad": -0.00000106450539839583,
    "pmdecrad": -0.000006162835151,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118477,
    "base": "Gl 275.2",
    "lum": 0.00016368165214278,
    "ra": 7.513485,
    "dec": 48.175745,
    "pmra": -219.57,
    "pmdec": -1271.18,
    "rv": 0,
    "Asset Name": "ASTRID 000485",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Lion Mane",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000915,
    "y_1": 0.00005374,
    "z_1": -0.00004749,
    "Distance in Parsecs_1": 11.5607,
    "HYG Star ID_1": 118478,
    "Gliese Catalog_1": "Gl 275.2B"
  },
  {
    "Astrid #": "ASTRID 000486",
    "HYG Star ID": 117970,
    "Gliese Catalog": "Gl 12",
    "Distance in Parsecs": 11.5875,
    "mag": 12.58,
    "Absolute Visual Magnitude": 12.26,
    "Spectral Type": "dM3.5",
    "Color Index": 1.66,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.237909,
    "y": 0.776518,
    "z": 2.715989,
    "vx": -0.00000652,
    "vy": 0.00003407,
    "vz": 0.00001728,
    "rarad": 0.0689842133300479,
    "decrad": 0.2365905860079,
    "pmrarad": 0.00000297258660095833,
    "pmdecrad": 0.000001534280158,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117970,
    "lum": 0.00108642562361706,
    "ra": 0.2635,
    "dec": 13.555642,
    "pmra": 613.14,
    "pmdec": 316.47,
    "rv": 0,
    "Asset Name": "ASTRID 000486",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Afro",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000652,
    "y_1": 0.00003407,
    "z_1": 0.00001728,
    "Distance in Parsecs_1": 11.5875,
    "HYG Star ID_1": 117970,
    "Gliese Catalog_1": "Gl 12"
  },
  {
    "Astrid #": "ASTRID 000487",
    "HYG Star ID": 84536,
    "Hipparcos Catalogue": 84794,
    "Gliese Catalog": "Gl 669A",
    "Distance in Parsecs": 11.5915,
    "mag": 11.26,
    "Absolute Visual Magnitude": 10.939,
    "Spectral Type": "M4",
    "Color Index": 1.628,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.805608,
    "y": -10.214932,
    "z": 5.172121,
    "vx": -0.00000535,
    "vy": 0.00004115,
    "vz": 0.00000217,
    "rarad": 4.53743564670384,
    "decrad": 0.462527052725133,
    "pmrarad": -0.00000107226241728472,
    "pmdecrad": 0.000001691854301,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 84536,
    "base": "Gl 669",
    "lum": 0.00366775231599494,
    "var": "V647",
    "var_min": 11.397,
    "var_max": 11.147,
    "ra": 17.331727,
    "dec": 26.500848,
    "pmra": -221.17,
    "pmdec": 348.97,
    "rv": -33.7,
    "Asset Name": "ASTRID 000487",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oh No",
    "Hair": "Bangs",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000535,
    "y_1": 0.00004115,
    "z_1": 0.00000217,
    "Distance in Parsecs_1": 11.5915,
    "HYG Star ID_1": 84536,
    "Hipparcos Catalogue_1": 84794,
    "Gliese Catalog_1": "Gl 669A"
  },
  {
    "Astrid #": "ASTRID 000488",
    "HYG Star ID": 11933,
    "Hipparcos Catalogue": 11964,
    "Henry Draper Catalogue": 16157,
    "Gliese Catalog": "Gl 103",
    "Distance in Parsecs": 11.6036,
    "mag": 8.89,
    "Absolute Visual Magnitude": 8.567,
    "Spectral Type": "K7V comp SB",
    "Color Index": 1.39,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.546404,
    "y": 5.224808,
    "z": -8.031079,
    "vx": 0.00001321,
    "vy": 0.00001471,
    "vz": -0.00004158,
    "rarad": 0.673591869763097,
    "decrad": -0.764390547984505,
    "pmrarad": 2.80658639673611e-7,
    "pmdecrad": -0.000001423655372,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 11933,
    "lum": 0.0325986788904468,
    "var": "CC",
    "var_min": 8.983,
    "var_max": 8.813,
    "ra": 2.572931,
    "dec": -43.796352,
    "pmra": 57.89,
    "pmdec": -293.65,
    "rv": 41.9,
    "Asset Name": "ASTRID 000488",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "High Fade",
    "Outfit": "Spartan",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00001321,
    "y_1": 0.00001471,
    "z_1": -0.00004158,
    "Distance in Parsecs_1": 11.6036,
    "HYG Star ID_1": 11933,
    "Hipparcos Catalogue_1": 11964,
    "Henry Draper Catalogue_1": 16157,
    "Gliese Catalog_1": "Gl 103"
  },
  {
    "Astrid #": "ASTRID 000489",
    "HYG Star ID": 119315,
    "Gliese Catalog": "Gl 772",
    "Distance in Parsecs": 11.6144,
    "mag": 13.7,
    "Absolute Visual Magnitude": 13.375,
    "Spectral Type": "DA6",
    "Color Index": 0.28,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.653474,
    "y": -10.147812,
    "z": -0.21153,
    "vx": 0.00001697,
    "vy": -0.00007873,
    "vz": -0.00003916,
    "rarad": 5.22067643341638,
    "decrad": -0.0182076911164499,
    "pmrarad": -0.00000202395167221527,
    "pmdecrad": -0.000003251582329,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119315,
    "lum": 0.00038904514499428,
    "ra": 19.941515,
    "dec": -1.043224,
    "pmra": -417.47,
    "pmdec": -670.69,
    "rv": 76,
    "Asset Name": "ASTRID 000489",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Einstein Tongue",
    "Hair": "Bucket Hat",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001697,
    "y_1": -0.00007873,
    "z_1": -0.00003916,
    "Distance in Parsecs_1": 11.6144,
    "HYG Star ID_1": 119315,
    "Gliese Catalog_1": "Gl 772"
  },
  {
    "Astrid #": "ASTRID 000490",
    "HYG Star ID": 109209,
    "Hipparcos Catalogue": 109555,
    "Gliese Catalog": "Gl 851",
    "Distance in Parsecs": 11.6171,
    "mag": 10.25,
    "Absolute Visual Magnitude": 9.925,
    "Spectral Type": "M2",
    "Color Index": 1.465,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.808944,
    "y": -5.024807,
    "z": 3.671819,
    "vx": -0.00003876,
    "vy": 0.00004069,
    "vz": -0.00000708,
    "rarad": 5.80977083748228,
    "decrad": 0.321597698638396,
    "pmrarad": 0.00000159605511775694,
    "pmdecrad": 8.65101531e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 109209,
    "lum": 0.00933254300796989,
    "ra": 22.19169,
    "dec": 18.426191,
    "pmra": 329.21,
    "pmdec": 178.44,
    "rv": -51.4,
    "Asset Name": "ASTRID 000490",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Medium Bob",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00003876,
    "y_1": 0.00004069,
    "z_1": -0.00000708,
    "Distance in Parsecs_1": 11.6171,
    "HYG Star ID_1": 109209,
    "Hipparcos Catalogue_1": 109555,
    "Gliese Catalog_1": "Gl 851"
  },
  {
    "Astrid #": "ASTRID 000491",
    "HYG Star ID": 117995,
    "Gliese Catalog": "NN 3039",
    "Distance in Parsecs": 11.6279,
    "mag": 12.7,
    "Absolute Visual Magnitude": 12.372,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.412285,
    "y": 1.633794,
    "z": 1.516088,
    "vx": -8.7e-7,
    "vy": 0.00000944,
    "vz": -0.00000361,
    "rarad": 0.14219447204544,
    "decrad": 0.1307559513044,
    "pmrarad": 8.14680908805555e-7,
    "pmdecrad": -3.12733912e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117995,
    "lum": 0.000979941161095593,
    "ra": 0.543143,
    "dec": 7.491764,
    "pmra": 168.04,
    "pmdec": -64.51,
    "rv": 0,
    "Asset Name": "ASTRID 000491",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Determined Shinobi",
    "Hair": "Tiny Crown",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -8.7e-7,
    "y_1": 0.00000944,
    "z_1": -0.00000361,
    "Distance in Parsecs_1": 11.6279,
    "HYG Star ID_1": 117995,
    "Gliese Catalog_1": "NN 3039"
  },
  {
    "Astrid #": "ASTRID 000492",
    "HYG Star ID": 118476,
    "Gliese Catalog": "NN 3442",
    "Distance in Parsecs": 11.6279,
    "mag": 13.65,
    "Absolute Visual Magnitude": 13.322,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.133107,
    "y": 10.202554,
    "z": -3.746118,
    "vx": -0.00000601,
    "vy": 0.00000891,
    "vz": 0.00003088,
    "rarad": 1.95569531694984,
    "decrad": -0.32801696782745,
    "pmrarad": 1.91258996979166e-7,
    "pmdecrad": 0.000002805403451,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118476,
    "lum": 0.000408507467663125,
    "ra": 7.470206,
    "dec": -18.793988,
    "pmra": 39.45,
    "pmdec": 578.66,
    "rv": 0,
    "Asset Name": "ASTRID 000492",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Determined",
    "Hair": "Wavy",
    "Outfit": "Toga",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000601,
    "y_1": 0.00000891,
    "z_1": 0.00003088,
    "Distance in Parsecs_1": 11.6279,
    "HYG Star ID_1": 118476,
    "Gliese Catalog_1": "NN 3442"
  },
  {
    "Astrid #": "ASTRID 000493",
    "HYG Star ID": 118948,
    "Gliese Catalog": "Gl 540.2",
    "Distance in Parsecs": 11.6279,
    "mag": 13.86,
    "Absolute Visual Magnitude": 13.532,
    "Spectral Type": "dM5.5e",
    "Color Index": 1.53,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -9.50853,
    "y": -6.239028,
    "z": -2.422903,
    "vx": -0.00001545,
    "vy": 0.00003227,
    "vz": -0.00002252,
    "rarad": 3.72228304009746,
    "decrad": -0.2099078831037,
    "pmrarad": -0.00000304947805069444,
    "pmdecrad": -0.000001980362074,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118948,
    "lum": 0.000336666574368622,
    "ra": 14.218074,
    "dec": -12.026836,
    "pmra": -629,
    "pmdec": -408.48,
    "rv": 0,
    "Asset Name": "ASTRID 000493",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001545,
    "y_1": 0.00003227,
    "z_1": -0.00002252,
    "Distance in Parsecs_1": 11.6279,
    "HYG Star ID_1": 118948,
    "Gliese Catalog_1": "Gl 540.2"
  },
  {
    "Astrid #": "ASTRID 000494",
    "HYG Star ID": 118993,
    "Gliese Catalog": "NN 3873",
    "Distance in Parsecs": 11.6279,
    "mag": 12.31,
    "Absolute Visual Magnitude": 11.982,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.849563,
    "y": -6.525978,
    "z": 6.760412,
    "vx": -0.00001337,
    "vy": -0.00002473,
    "vz": -0.0000374,
    "rarad": 3.90279468989408,
    "decrad": 0.6204431627381,
    "pmrarad": 7.47097881736111e-7,
    "pmdecrad": -0.000003953985238,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118993,
    "lum": 0.00140345987292117,
    "ra": 14.907578,
    "dec": 35.548775,
    "pmra": 154.1,
    "pmdec": -815.57,
    "rv": 0,
    "Asset Name": "ASTRID 000494",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Pirate Hat",
    "Outfit": "Wizard",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001337,
    "y_1": -0.00002473,
    "z_1": -0.0000374,
    "Distance in Parsecs_1": 11.6279,
    "HYG Star ID_1": 118993,
    "Gliese Catalog_1": "NN 3873"
  },
  {
    "Astrid #": "ASTRID 000495",
    "HYG Star ID": 13356,
    "Hipparcos Catalogue": 13389,
    "Gliese Catalog": "Gl 118",
    "Distance in Parsecs": 11.6455,
    "mag": 11.36,
    "Absolute Visual Magnitude": 11.029,
    "Spectral Type": "M",
    "Color Index": 1.549,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.770908,
    "y": 3.527811,
    "z": -10.438969,
    "vx": 0.00001667,
    "vy": 0.00009128,
    "vz": -0.00007039,
    "rarad": 0.75205153859403,
    "decrad": -1.11142252804157,
    "pmrarad": 0.00000474681074631944,
    "pmdecrad": 0.000003057138106,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 13356,
    "lum": 0.00337598104855425,
    "var_min": 11.443,
    "var_max": 11.253,
    "ra": 2.872625,
    "dec": -63.67982,
    "pmra": 979.1,
    "pmdec": 630.58,
    "rv": 94,
    "Asset Name": "ASTRID 000495",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "High Bun",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": 0.00001667,
    "y_1": 0.00009128,
    "z_1": -0.00007039,
    "Distance in Parsecs_1": 11.6455,
    "HYG Star ID_1": 13356,
    "Hipparcos Catalogue_1": 13389,
    "Gliese Catalog_1": "Gl 118"
  },
  {
    "Astrid #": "ASTRID 000496",
    "HYG Star ID": 23638,
    "Hipparcos Catalogue": 23693,
    "Henry Draper Catalogue": 33262,
    "Harvard Revised Catalogue": 1674,
    "Gliese Catalog": "Gl 189",
    "Distance in Parsecs": 11.6455,
    "mag": 4.71,
    "Absolute Visual Magnitude": 4.379,
    "Spectral Type": "F7V",
    "Color Index": 0.526,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.474776,
    "y": 6.085643,
    "z": -9.818715,
    "vx": 0.00000275,
    "vy": 0.00000373,
    "vz": 0.00000563,
    "rarad": 1.33304406603645,
    "decrad": -1.00308791090304,
    "pmrarad": -1.54510119993055e-7,
    "pmdecrad": 5.69268223e-7,
    "bayer": "Zet",
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 23638,
    "lum": 1.54312106565779,
    "bf": "Zet Dor",
    "ra": 5.091853,
    "dec": -57.472704,
    "pmra": -31.87,
    "pmdec": 117.42,
    "rv": -2.4,
    "Asset Name": "ASTRID 000496",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Frizzy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 0.00000275,
    "y_1": 0.00000373,
    "z_1": 0.00000563,
    "Distance in Parsecs_1": 11.6455,
    "HYG Star ID_1": 23638,
    "Hipparcos Catalogue_1": 23693,
    "Henry Draper Catalogue_1": 33262,
    "Harvard Revised Catalogue_1": 1674,
    "Gliese Catalog_1": "Gl 189"
  },
  {
    "Astrid #": "ASTRID 000497",
    "HYG Star ID": 23652,
    "Hipparcos Catalogue": 23708,
    "Gliese Catalog": "GJ 1075",
    "Distance in Parsecs": 11.6591,
    "mag": 8.98,
    "Absolute Visual Magnitude": 8.647,
    "Spectral Type": "K7V",
    "Color Index": 1.386,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.465829,
    "y": 6.081014,
    "z": -9.839066,
    "vx": 0.00000309,
    "vy": 0.00000509,
    "vz": 0.0000036,
    "rarad": 1.33425977967327,
    "decrad": -1.00450389408916,
    "pmrarad": -1.55237340513888e-7,
    "pmdecrad": 5.76104096e-7,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 23652,
    "lum": 0.0302830769426577,
    "ra": 5.096497,
    "dec": -57.553834,
    "pmra": -32.02,
    "pmdec": 118.83,
    "rv": 0,
    "Asset Name": "ASTRID 000497",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Innocent",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 0.00000309,
    "y_1": 0.00000509,
    "z_1": 0.0000036,
    "Distance in Parsecs_1": 11.6591,
    "HYG Star ID_1": 23652,
    "Hipparcos Catalogue_1": 23708,
    "Gliese Catalog_1": "GJ 1075"
  },
  {
    "Astrid #": "ASTRID 000498",
    "HYG Star ID": 69232,
    "Hipparcos Catalogue": 69454,
    "Distance in Parsecs": 11.6795,
    "mag": 10.2,
    "Absolute Visual Magnitude": 9.863,
    "Spectral Type": "M2V:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.353243,
    "y": -3.516911,
    "z": -9.766517,
    "vx": 0.00000401,
    "vy": -0.00002146,
    "vz": 0.00000553,
    "rarad": 3.72283848421979,
    "decrad": -0.990335786754293,
    "pmrarad": 0.00000172448226173611,
    "pmdecrad": 8.63113795e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 69232,
    "lum": 0.00988097953941127,
    "ra": 14.220196,
    "dec": -56.742061,
    "pmra": 355.7,
    "pmdec": 178.03,
    "rv": 0,
    "Asset Name": "ASTRID 000498",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Sweetheart",
    "Outfit": "Super",
    "Special": "Astral Burst",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000401,
    "y_1": -0.00002146,
    "z_1": 0.00000553,
    "Distance in Parsecs_1": 11.6795,
    "HYG Star ID_1": 69232,
    "Hipparcos Catalogue_1": 69454
  },
  {
    "Astrid #": "ASTRID 000499",
    "HYG Star ID": 119277,
    "Gliese Catalog": "GJ 1234",
    "Distance in Parsecs": 11.6822,
    "mag": 14.57,
    "Absolute Visual Magnitude": 14.232,
    "Spectral Type": "DC7",
    "Color Index": 0.44,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.078815,
    "y": -8.578592,
    "z": 7.307698,
    "vx": 0.00000322,
    "vy": -0.00000825,
    "vz": -0.00001104,
    "rarad": 5.05696582889382,
    "decrad": 0.675825098743799,
    "pmrarad": 2.11378764722222e-8,
    "pmdecrad": -0.000001211845124,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119277,
    "lum": 0.000176685129845388,
    "ra": 19.316187,
    "dec": 38.721926,
    "pmra": 4.36,
    "pmdec": -249.96,
    "rv": 0,
    "Asset Name": "ASTRID 000499",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Bangs",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000322,
    "y_1": -0.00000825,
    "z_1": -0.00001104,
    "Distance in Parsecs_1": 11.6822,
    "HYG Star ID_1": 119277,
    "Gliese Catalog_1": "GJ 1234"
  },
  {
    "Astrid #": "ASTRID 000500",
    "HYG Star ID": 61748,
    "Hipparcos Catalogue": 61941,
    "Henry Draper Catalogue": 110379,
    "Harvard Revised Catalogue": 4825,
    "Gliese Catalog": "Gl 482A",
    "Proper Name": "Porrima",
    "Distance in Parsecs": 11.685,
    "mag": 2.74,
    "Absolute Visual Magnitude": 2.402,
    "Spectral Type": "F0V+...",
    "Color Index": 0.368,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.488622,
    "y": -2.111702,
    "z": -0.295552,
    "vx": 0.00001361,
    "vy": 0.00003802,
    "vz": 0.00000395,
    "rarad": 3.32337177354462,
    "decrad": -0.0252963627301925,
    "pmrarad": -0.00000298965204251388,
    "pmdecrad": 2.94087978e-7,
    "bayer": "Gam",
    "flam": 29,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 61748,
    "base": "Gl 482",
    "lum": 9.53235043920514,
    "bf": "29Gam Vir",
    "ra": 12.694345,
    "dec": -1.449375,
    "pmra": -616.66,
    "pmdec": 60.66,
    "rv": -19.9,
    "Asset Name": "ASTRID 000500",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Battle Ready",
    "Hair": "Astro Helmet",
    "Outfit": "Astro",
    "Special": "Neko Tail",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Porrima",
    "Constellation": "Virgo",
    "x_1": 0.00001361,
    "y_1": 0.00003802,
    "z_1": 0.00000395,
    "Distance in Parsecs_1": 11.685,
    "HYG Star ID_1": 61748,
    "Hipparcos Catalogue_1": 61941,
    "Henry Draper Catalogue_1": 110379,
    "Harvard Revised Catalogue_1": 4825,
    "Gliese Catalog_1": "Gl 482A"
  },
  {
    "Astrid #": "ASTRID 000501",
    "HYG Star ID": 63307,
    "Hipparcos Catalogue": 63510,
    "Gliese Catalog": "Gl 494",
    "Distance in Parsecs": 11.6904,
    "mag": 9.76,
    "Absolute Visual Magnitude": 9.421,
    "Spectral Type": "M2",
    "Color Index": 1.448,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.0195,
    "y": -2.992712,
    "z": 2.505483,
    "vx": 0.00000131,
    "vy": 0.0000367,
    "vz": -0.00000335,
    "rarad": 3.40678019818747,
    "decrad": 0.215997120535493,
    "pmrarad": -0.00000299997857390972,
    "pmdecrad": -8.0624515e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 63307,
    "lum": 0.0148456767505227,
    "var": "DT",
    "var_min": 9.802,
    "var_max": 9.692,
    "ra": 13.012942,
    "dec": 12.375723,
    "pmra": -618.79,
    "pmdec": -16.63,
    "rv": -11.1,
    "Asset Name": "ASTRID 000501",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Neko",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000131,
    "y_1": 0.0000367,
    "z_1": -0.00000335,
    "Distance in Parsecs_1": 11.6904,
    "HYG Star ID_1": 63307,
    "Hipparcos Catalogue_1": 63510,
    "Gliese Catalog_1": "Gl 494"
  },
  {
    "Astrid #": "ASTRID 000502",
    "HYG Star ID": 108830,
    "Hipparcos Catalogue": 109176,
    "Henry Draper Catalogue": 210027,
    "Harvard Revised Catalogue": 8430,
    "Gliese Catalog": "Gl 848",
    "Distance in Parsecs": 11.7261,
    "mag": 3.77,
    "Absolute Visual Magnitude": 3.424,
    "Spectral Type": "F5V",
    "Color Index": 0.435,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.335368,
    "y": -5.015491,
    "z": 5.019565,
    "vx": 0.00000293,
    "vy": 0.00001757,
    "vz": -0.00000102,
    "rarad": 5.7901768826418,
    "decrad": 0.442355645402835,
    "pmrarad": 0.0000014385876343125,
    "pmdecrad": 1.30560324e-7,
    "bayer": "Iot",
    "flam": 24,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 108830,
    "lum": 3.71877583324935,
    "bf": "24Iot Peg",
    "ra": 22.116847,
    "dec": 25.345112,
    "pmra": 296.73,
    "pmdec": 26.93,
    "rv": -5.5,
    "Asset Name": "ASTRID 000502",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Fire",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000293,
    "y_1": 0.00001757,
    "z_1": -0.00000102,
    "Distance in Parsecs_1": 11.7261,
    "HYG Star ID_1": 108830,
    "Hipparcos Catalogue_1": 109176,
    "Henry Draper Catalogue_1": 210027,
    "Harvard Revised Catalogue_1": 8430,
    "Gliese Catalog_1": "Gl 848"
  },
  {
    "Astrid #": "ASTRID 000503",
    "HYG Star ID": 117957,
    "Gliese Catalog": "Gl 4B",
    "Distance in Parsecs": 11.7509,
    "mag": 9.02,
    "Absolute Visual Magnitude": 8.67,
    "Spectral Type": "M0.5 V",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.188103,
    "y": 0.202922,
    "z": 8.426006,
    "vx": 0.00000404,
    "vy": 0.00004972,
    "vz": -0.00000497,
    "rarad": 0.024685753028377,
    "decrad": 0.7995630143319,
    "pmrarad": 0.00000422166056754166,
    "pmdecrad": -6.15873362e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 2,
    "comp_primary": 472,
    "base": "Gl   4",
    "lum": 0.0296483138952434,
    "ra": 0.094293,
    "dec": 45.811586,
    "pmra": 870.78,
    "pmdec": -127.03,
    "rv": 0.1,
    "Asset Name": "ASTRID 000503",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "High Fade",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000404,
    "y_1": 0.00004972,
    "z_1": -0.00000497,
    "Distance in Parsecs_1": 11.7509,
    "HYG Star ID_1": 117957,
    "Gliese Catalog_1": "Gl 4B"
  },
  {
    "Astrid #": "ASTRID 000504",
    "HYG Star ID": 119021,
    "Gliese Catalog": "Gl 585",
    "Distance in Parsecs": 11.7509,
    "mag": 13.68,
    "Absolute Visual Magnitude": 13.33,
    "Spectral Type": "M4",
    "Color Index": 1.78,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.062474,
    "y": -8.709702,
    "z": 3.528058,
    "vx": -0.00008164,
    "vy": -0.00006672,
    "vz": -0.00004181,
    "rarad": 4.03105675105383,
    "decrad": 0.3048262310512,
    "pmrarad": -0.00000182163892331944,
    "pmdecrad": -0.000006033578976,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119021,
    "lum": 0.000405508535448383,
    "ra": 15.397503,
    "dec": 17.465257,
    "pmra": -375.74,
    "pmdec": -1244.52,
    "rv": 84,
    "Asset Name": "ASTRID 000504",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Nervous",
    "Hair": "Braided Ponytail",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00008164,
    "y_1": -0.00006672,
    "z_1": -0.00004181,
    "Distance in Parsecs_1": 11.7509,
    "HYG Star ID_1": 119021,
    "Gliese Catalog_1": "Gl 585"
  },
  {
    "Astrid #": "ASTRID 000505",
    "HYG Star ID": 53829,
    "Hipparcos Catalogue": 53985,
    "Henry Draper Catalogue": 95650,
    "Gliese Catalog": "Gl 410",
    "Distance in Parsecs": 11.7716,
    "mag": 9.57,
    "Absolute Visual Magnitude": 9.216,
    "Spectral Type": "K",
    "Color Index": 1.437,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.576702,
    "y": 2.703881,
    "z": 4.403412,
    "vx": 0.00000972,
    "vy": -0.00001081,
    "vz": -0.00000802,
    "rarad": 2.89130768762989,
    "decrad": 0.383398923004202,
    "pmrarad": 6.85671988409722e-7,
    "pmdecrad": -2.47885234e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 53829,
    "lum": 0.0179308137708044,
    "ra": 11.043982,
    "dec": 21.96714,
    "pmra": 141.43,
    "pmdec": -51.13,
    "rv": -13.9,
    "Asset Name": "ASTRID 000505",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Braided Bun",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000972,
    "y_1": -0.00001081,
    "z_1": -0.00000802,
    "Distance in Parsecs_1": 11.7716,
    "HYG Star ID_1": 53829,
    "Hipparcos Catalogue_1": 53985,
    "Henry Draper Catalogue_1": 95650,
    "Gliese Catalog_1": "Gl 410"
  },
  {
    "Astrid #": "ASTRID 000506",
    "HYG Star ID": 92580,
    "Hipparcos Catalogue": 92871,
    "Gliese Catalog": "Gl 735",
    "Distance in Parsecs": 11.773,
    "mag": 10.1,
    "Absolute Visual Magnitude": 9.746,
    "Spectral Type": "M2Ve",
    "Color Index": 1.539,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.790755,
    "y": -11.307203,
    "z": 1.720327,
    "vx": 0.00000197,
    "vy": 0.00001396,
    "vz": -0.00000595,
    "rarad": 4.95436506502276,
    "decrad": 0.146651407333117,
    "pmrarad": 4.46174030215277e-7,
    "pmdecrad": -3.37333358e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 92580,
    "lum": 0.0110052522142054,
    "var": "V1285",
    "var_min": 10.143,
    "var_max": 10.043,
    "ra": 18.924281,
    "dec": 8.402507,
    "pmra": 92.03,
    "pmdec": -69.58,
    "rv": -13.5,
    "Asset Name": "ASTRID 000506",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Sweetheart",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000197,
    "y_1": 0.00001396,
    "z_1": -0.00000595,
    "Distance in Parsecs_1": 11.773,
    "HYG Star ID_1": 92580,
    "Hipparcos Catalogue_1": 92871,
    "Gliese Catalog_1": "Gl 735"
  },
  {
    "Astrid #": "ASTRID 000507",
    "HYG Star ID": 73243,
    "Hipparcos Catalogue": 73470,
    "Gliese Catalog": "Gl 572",
    "Distance in Parsecs": 11.7938,
    "mag": 9.15,
    "Absolute Visual Magnitude": 8.792,
    "Spectral Type": "M0",
    "Color Index": 1.427,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.829123,
    "y": -5.876436,
    "z": 8.401216,
    "vx": 0.00002547,
    "vy": 0.0000061,
    "vz": 0.00000758,
    "rarad": 3.93102834259903,
    "decrad": 0.792836905477711,
    "pmrarad": 0.00000116898274655555,
    "pmdecrad": 0.000001796283167,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 73243,
    "lum": 0.0264972009887896,
    "ra": 15.015422,
    "dec": 45.426209,
    "pmra": 241.12,
    "pmdec": 370.51,
    "rv": -10,
    "Asset Name": "ASTRID 000507",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Innocent",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00002547,
    "y_1": 0.0000061,
    "z_1": 0.00000758,
    "Distance in Parsecs_1": 11.7938,
    "HYG Star ID_1": 73243,
    "Hipparcos Catalogue_1": 73470,
    "Gliese Catalog_1": "Gl 572"
  },
  {
    "Astrid #": "ASTRID 000508",
    "HYG Star ID": 69749,
    "Hipparcos Catalogue": 69972,
    "Henry Draper Catalogue": 125072,
    "Gliese Catalog": "Gl 542",
    "Distance in Parsecs": 11.798,
    "mag": 6.66,
    "Absolute Visual Magnitude": 6.301,
    "Spectral Type": "K3V",
    "Color Index": 1.017,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.936333,
    "y": -3.427027,
    "z": -10.152722,
    "vx": 0.0000243,
    "vy": 0.00004852,
    "vz": -0.00001048,
    "rarad": 3.74846472999015,
    "decrad": -1.0363596991696,
    "pmrarad": -0.00000220342969675694,
    "pmdecrad": -0.000003926990812,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 69749,
    "lum": 0.262784654083849,
    "ra": 14.318081,
    "dec": -59.379037,
    "pmra": -454.49,
    "pmdec": -810,
    "rv": -14.9,
    "Asset Name": "ASTRID 000508",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Charmer",
    "Outfit": "Elven",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.0000243,
    "y_1": 0.00004852,
    "z_1": -0.00001048,
    "Distance in Parsecs_1": 11.798,
    "HYG Star ID_1": 69749,
    "Hipparcos Catalogue_1": 69972,
    "Henry Draper Catalogue_1": 125072,
    "Gliese Catalog_1": "Gl 542"
  },
  {
    "Astrid #": "ASTRID 000509",
    "HYG Star ID": 118838,
    "Henry Draper Catalogue": 110380,
    "Harvard Revised Catalogue": 4826,
    "Gliese Catalog": "Gl 482B",
    "Distance in Parsecs": 11.8301,
    "mag": 3.52,
    "Absolute Visual Magnitude": 3.155,
    "Spectral Type": "F0 V",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.630574,
    "y": -2.137246,
    "z": -0.299172,
    "vx": 0.00001389,
    "vy": 0.00003521,
    "vz": 9e-7,
    "rarad": 3.32332623586571,
    "decrad": -0.02529392369485,
    "pmrarad": -0.00000271476268563888,
    "pmdecrad": 3.3166103e-8,
    "bayer": "Gam",
    "flam": 29,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 2,
    "comp_primary": 61748,
    "base": "Gl 482",
    "lum": 4.76430986805415,
    "bf": "29Gam Vir",
    "ra": 12.694171,
    "dec": -1.449235,
    "pmra": -559.96,
    "pmdec": 6.84,
    "rv": -19.6,
    "Asset Name": "ASTRID 000509",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Undercut",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00001389,
    "y_1": 0.00003521,
    "z_1": 9e-7,
    "Distance in Parsecs_1": 11.8301,
    "HYG Star ID_1": 118838,
    "Henry Draper Catalogue_1": 110380,
    "Harvard Revised Catalogue_1": 4826,
    "Gliese Catalog_1": "Gl 482B"
  },
  {
    "Astrid #": "ASTRID 000510",
    "HYG Star ID": 118276,
    "Gliese Catalog": "NN 3266",
    "Distance in Parsecs": 11.8343,
    "mag": 13.71,
    "Absolute Visual Magnitude": 13.344,
    "Spectral Type": "m",
    "Color Index": 1.69,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.287923,
    "y": 4.502957,
    "z": 10.702311,
    "vx": -0.0000154,
    "vy": 0.00003177,
    "vz": -0.00001007,
    "rarad": 1.10053532880158,
    "decrad": 1.12984656870444,
    "pmrarad": 0.00000237689603165972,
    "pmdecrad": -0.000001994445911,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118276,
    "lum": 0.000400313282178023,
    "ra": 4.203735,
    "dec": 64.73544,
    "pmra": 490.27,
    "pmdec": -411.38,
    "rv": 0,
    "Asset Name": "ASTRID 000510",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Ninja",
    "Outfit": "Astro",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000154,
    "y_1": 0.00003177,
    "z_1": -0.00001007,
    "Distance in Parsecs_1": 11.8343,
    "HYG Star ID_1": 118276,
    "Gliese Catalog_1": "NN 3266"
  },
  {
    "Astrid #": "ASTRID 000511",
    "HYG Star ID": 3927,
    "Hipparcos Catalogue": 3937,
    "Gliese Catalog": "NN 3060A",
    "Distance in Parsecs": 11.8455,
    "mag": 12.01,
    "Absolute Visual Magnitude": 11.642,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.491115,
    "y": 2.352447,
    "z": 4.971757,
    "vx": -0.00000151,
    "vy": 0.00001134,
    "vz": -0.00000218,
    "rarad": 0.220582776910766,
    "decrad": 0.433133474339237,
    "pmrarad": 9.62161230430555e-7,
    "pmdecrad": -2.0236123e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 3927,
    "base": "NN 3060",
    "lum": 0.00191955252376137,
    "var": "FT",
    "var_min": 12.172,
    "var_max": 11.822,
    "ra": 0.842564,
    "dec": 24.81672,
    "pmra": 198.46,
    "pmdec": -41.74,
    "rv": 0,
    "Asset Name": "ASTRID 000511",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Worried Vamp",
    "Hair": "Hachimaki",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000151,
    "y_1": 0.00001134,
    "z_1": -0.00000218,
    "Distance in Parsecs_1": 11.8455,
    "HYG Star ID_1": 3927,
    "Hipparcos Catalogue_1": 3937,
    "Gliese Catalog_1": "NN 3060A"
  },
  {
    "Astrid #": "ASTRID 000512",
    "HYG Star ID": 107213,
    "Hipparcos Catalogue": 107556,
    "Henry Draper Catalogue": 207098,
    "Harvard Revised Catalogue": 8322,
    "Gliese Catalog": "Gl 837",
    "Distance in Parsecs": 11.8666,
    "mag": 2.85,
    "Absolute Visual Magnitude": 2.478,
    "Spectral Type": "A5mF2 (IV)",
    "Color Index": 0.18,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 9.534405,
    "y": -6.248606,
    "z": -3.296196,
    "vx": -8.3e-7,
    "vy": 0.00001865,
    "vz": -0.00001458,
    "rarad": 5.70304068080433,
    "decrad": -0.281474238431193,
    "pmrarad": 0.00000127632049543055,
    "pmdecrad": -0.000001436163565,
    "bayer": "Del",
    "flam": 49,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 107213,
    "lum": 8.8879171988482,
    "var": "Del",
    "var_min": 2.93,
    "var_max": 2.82,
    "bf": "49Del Cap",
    "ra": 21.784011,
    "dec": -16.127286,
    "pmra": 263.26,
    "pmdec": -296.23,
    "rv": -6.3,
    "Asset Name": "ASTRID 000512",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Braided Ponytail",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -8.3e-7,
    "y_1": 0.00001865,
    "z_1": -0.00001458,
    "Distance in Parsecs_1": 11.8666,
    "HYG Star ID_1": 107213,
    "Hipparcos Catalogue_1": 107556,
    "Henry Draper Catalogue_1": 207098,
    "Harvard Revised Catalogue_1": 8322,
    "Gliese Catalog_1": "Gl 837"
  },
  {
    "Astrid #": "ASTRID 000513",
    "HYG Star ID": 36524,
    "Hipparcos Catalogue": 36626,
    "Gliese Catalog": "Gl 277B",
    "Distance in Parsecs": 11.868,
    "mag": 10.52,
    "Absolute Visual Magnitude": 10.148,
    "Spectral Type": "M3.5Ve",
    "Color Index": 1.439,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.739646,
    "y": 8.814115,
    "z": 7.01255,
    "vx": 0.00000902,
    "vy": 0.00001179,
    "vz": -0.00000984,
    "rarad": 1.97205854625917,
    "decrad": 0.632147724558675,
    "pmrarad": -0.00000108797038053472,
    "pmdecrad": -0.00000103410758,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 2,
    "comp_primary": 36522,
    "base": "Gl 277",
    "lum": 0.00759976214361037,
    "var": "VV",
    "var_min": 10.743,
    "var_max": 10.453,
    "ra": 7.532709,
    "dec": 36.219397,
    "pmra": -224.41,
    "pmdec": -213.3,
    "rv": 0.1,
    "Asset Name": "ASTRID 000513",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Side Part",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000902,
    "y_1": 0.00001179,
    "z_1": -0.00000984,
    "Distance in Parsecs_1": 11.868,
    "HYG Star ID_1": 36524,
    "Hipparcos Catalogue_1": 36626,
    "Gliese Catalog_1": "Gl 277B"
  },
  {
    "Astrid #": "ASTRID 000514",
    "HYG Star ID": 54487,
    "Hipparcos Catalogue": 54646,
    "Henry Draper Catalogue": 97101,
    "Gliese Catalog": "Gl 414A",
    "Distance in Parsecs": 11.8723,
    "mag": 8.31,
    "Absolute Visual Magnitude": 7.937,
    "Spectral Type": "K8V",
    "Color Index": 1.255,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.002848,
    "y": 2.167892,
    "z": 6.015938,
    "vx": 4.3e-7,
    "vy": -0.00003496,
    "vz": -0.00001776,
    "rarad": 2.92816194143919,
    "decrad": 0.531383245782065,
    "pmrarad": 0.00000287067876530555,
    "pmdecrad": -9.55567764e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 54487,
    "base": "Gl 414",
    "lum": 0.058237134794375,
    "ra": 11.184755,
    "dec": 30.446017,
    "pmra": 592.12,
    "pmdec": -197.1,
    "rv": -15.4,
    "Asset Name": "ASTRID 000514",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Hachimaki",
    "Outfit": "Spartan",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 4.3e-7,
    "y_1": -0.00003496,
    "z_1": -0.00001776,
    "Distance in Parsecs_1": 11.8723,
    "HYG Star ID_1": 54487,
    "Hipparcos Catalogue_1": 54646,
    "Henry Draper Catalogue_1": 97101,
    "Gliese Catalog_1": "Gl 414A"
  },
  {
    "Astrid #": "ASTRID 000515",
    "HYG Star ID": 118194,
    "Gliese Catalog": "GJ 1055",
    "Distance in Parsecs": 11.8765,
    "mag": 14.85,
    "Absolute Visual Magnitude": 14.477,
    "Spectral Type": "m",
    "Color Index": 1.72,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.938658,
    "y": 8.58805,
    "z": 2.067458,
    "vx": -0.00000835,
    "vy": 0.00001552,
    "vz": -0.00003236,
    "rarad": 0.82467041432467,
    "decrad": 0.17497118529595,
    "pmrarad": 0.00000140382649341666,
    "pmdecrad": -0.000002767074081,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118194,
    "lum": 0.000140993794935872,
    "ra": 3.150009,
    "dec": 10.02511,
    "pmra": 289.56,
    "pmdec": -570.75,
    "rv": 0,
    "Asset Name": "ASTRID 000515",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Undercut",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000835,
    "y_1": 0.00001552,
    "z_1": -0.00003236,
    "Distance in Parsecs_1": 11.8765,
    "HYG Star ID_1": 118194,
    "Gliese Catalog_1": "GJ 1055"
  },
  {
    "Astrid #": "ASTRID 000516",
    "HYG Star ID": 119082,
    "Gliese Catalog": "NN 3954",
    "Distance in Parsecs": 11.9048,
    "mag": 14.3,
    "Absolute Visual Magnitude": 13.921,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.493949,
    "y": -10.433779,
    "z": -3.558784,
    "vx": -0.00001451,
    "vy": 0.00001364,
    "vz": -0.00002167,
    "rarad": 4.30569431122101,
    "decrad": -0.303578466879,
    "pmrarad": -0.00000157273557972222,
    "pmdecrad": -0.00000190787758,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119082,
    "lum": 0.000235288120193816,
    "ra": 16.446541,
    "dec": -17.393765,
    "pmra": -324.4,
    "pmdec": -393.53,
    "rv": 0,
    "Asset Name": "ASTRID 000516",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Cute Neko",
    "Hair": "Braided Ponytail",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001451,
    "y_1": 0.00001364,
    "z_1": -0.00002167,
    "Distance in Parsecs_1": 11.9048,
    "HYG Star ID_1": 119082,
    "Gliese Catalog_1": "NN 3954"
  },
  {
    "Astrid #": "ASTRID 000517",
    "HYG Star ID": 119495,
    "Gliese Catalog": "Wo 9780",
    "Distance in Parsecs": 11.9048,
    "mag": 12.51,
    "Absolute Visual Magnitude": 12.131,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.311156,
    "y": -3.213265,
    "z": -8.828715,
    "vx": -0.00001367,
    "vy": 0.0000354,
    "vz": -0.00002422,
    "rarad": 5.86904060494859,
    "decrad": -0.835466721601799,
    "pmrarad": 0.00000225995897190972,
    "pmdecrad": -0.000003031952036,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119495,
    "lum": 0.00122348880532681,
    "ra": 22.418084,
    "dec": -47.868717,
    "pmra": 466.15,
    "pmdec": -625.38,
    "rv": 0,
    "Asset Name": "ASTRID 000517",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Frizzy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001367,
    "y_1": 0.0000354,
    "z_1": -0.00002422,
    "Distance in Parsecs_1": 11.9048,
    "HYG Star ID_1": 119495,
    "Gliese Catalog_1": "Wo 9780"
  },
  {
    "Astrid #": "ASTRID 000518",
    "HYG Star ID": 119308,
    "Gliese Catalog": "GJ 1243",
    "Distance in Parsecs": 11.919,
    "mag": 12.83,
    "Absolute Visual Magnitude": 12.449,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.826298,
    "y": -7.260781,
    "z": 8.643094,
    "vx": 0.00000305,
    "vy": 0.00001326,
    "vz": 0.00000979,
    "rarad": 5.19737135808321,
    "decrad": 0.81125594484145,
    "pmrarad": 7.45061664277777e-7,
    "pmdecrad": 0.000001192316829,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119308,
    "lum": 0.000912851219331122,
    "ra": 19.852496,
    "dec": 46.481542,
    "pmra": 153.68,
    "pmdec": 245.93,
    "rv": 0,
    "Asset Name": "ASTRID 000518",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Round Brush",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000305,
    "y_1": 0.00001326,
    "z_1": 0.00000979,
    "Distance in Parsecs_1": 11.919,
    "HYG Star ID_1": 119308,
    "Gliese Catalog_1": "GJ 1243"
  },
  {
    "Astrid #": "ASTRID 000519",
    "HYG Star ID": 118731,
    "Gliese Catalog": "Gl 414B",
    "Distance in Parsecs": 11.9261,
    "mag": 9.98,
    "Absolute Visual Magnitude": 9.598,
    "Spectral Type": "M2 V",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.04729,
    "y": 2.179382,
    "z": 6.042833,
    "vx": -1.3e-7,
    "vy": -0.00003384,
    "vz": -0.00001827,
    "rarad": 2.92796713206792,
    "decrad": 0.53137862460485,
    "pmrarad": 0.00000277546135844444,
    "pmdecrad": -0.000001021167903,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 54487,
    "base": "Gl 414",
    "lum": 0.0126124657531753,
    "ra": 11.184011,
    "dec": 30.445753,
    "pmra": 572.48,
    "pmdec": -210.63,
    "rv": -15,
    "Asset Name": "ASTRID 000519",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Braided Bun",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -1.3e-7,
    "y_1": -0.00003384,
    "z_1": -0.00001827,
    "Distance in Parsecs_1": 11.9261,
    "HYG Star ID_1": 118731,
    "Gliese Catalog_1": "Gl 414B"
  },
  {
    "Astrid #": "ASTRID 000520",
    "HYG Star ID": 11022,
    "Hipparcos Catalogue": 11048,
    "Gliese Catalog": "Gl 96",
    "Distance in Parsecs": 11.9403,
    "mag": 9.4,
    "Absolute Visual Magnitude": 9.015,
    "Spectral Type": "M2",
    "Color Index": 1.466,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.514448,
    "y": 4.657185,
    "z": 8.856375,
    "vx": -0.00002953,
    "vy": -0.0000057,
    "vz": -0.00002671,
    "rarad": 0.620652319976552,
    "decrad": 0.83566414417853,
    "pmrarad": 0.00000105039732029166,
    "pmdecrad": 1.98191832e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 11022,
    "lum": 0.0215774440915266,
    "ra": 2.370717,
    "dec": 47.880029,
    "pmra": 216.66,
    "pmdec": 40.88,
    "rv": -37.3,
    "Asset Name": "ASTRID 000520",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Hachimaki",
    "Outfit": "Floral Polo",
    "Special": "Faerie",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00002953,
    "y_1": -0.0000057,
    "z_1": -0.00002671,
    "Distance in Parsecs_1": 11.9403,
    "HYG Star ID_1": 11022,
    "Hipparcos Catalogue_1": 11048,
    "Gliese Catalog_1": "Gl 96"
  },
  {
    "Astrid #": "ASTRID 000521",
    "HYG Star ID": 118715,
    "Gliese Catalog": "Gl 403",
    "Distance in Parsecs": 11.9474,
    "mag": 12.67,
    "Absolute Visual Magnitude": 12.284,
    "Spectral Type": "dM3",
    "Color Index": 1.65,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -11.087256,
    "y": 3.385307,
    "z": 2.890126,
    "vx": 0.00002116,
    "vy": 0.00006041,
    "vz": 0.0000105,
    "rarad": 2.84525834927814,
    "decrad": 0.2443278227496,
    "pmrarad": -0.00000535389443711111,
    "pmdecrad": 9.05534992e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118715,
    "lum": 0.00106267386540246,
    "ra": 10.868086,
    "dec": 13.998953,
    "pmra": -1104.32,
    "pmdec": 186.78,
    "rv": 0,
    "Asset Name": "ASTRID 000521",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Charmer",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002116,
    "y_1": 0.00006041,
    "z_1": 0.0000105,
    "Distance in Parsecs_1": 11.9474,
    "HYG Star ID_1": 118715,
    "Gliese Catalog_1": "Gl 403"
  },
  {
    "Astrid #": "ASTRID 000522",
    "HYG Star ID": 119197,
    "Gliese Catalog": "GJ 1223",
    "Distance in Parsecs": 11.976,
    "mag": 14.85,
    "Absolute Visual Magnitude": 14.458,
    "Spectral Type": "m",
    "Color Index": 1.77,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.114678,
    "y": -9.49854,
    "z": 7.293091,
    "vx": 0.00000989,
    "vy": -0.00004015,
    "vz": -0.00005242,
    "rarad": 4.72445137560439,
    "decrad": 0.6547683392674,
    "pmrarad": 7.85592087972222e-7,
    "pmdecrad": -0.000005519734652,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119197,
    "lum": 0.000143482851823258,
    "ra": 18.046075,
    "dec": 37.515462,
    "pmra": 162.04,
    "pmdec": -1138.53,
    "rv": 0,
    "Asset Name": "ASTRID 000522",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Bob",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000989,
    "y_1": -0.00004015,
    "z_1": -0.00005242,
    "Distance in Parsecs_1": 11.976,
    "HYG Star ID_1": 119197,
    "Gliese Catalog_1": "GJ 1223"
  },
  {
    "Astrid #": "ASTRID 000523",
    "HYG Star ID": 36522,
    "Hipparcos Catalogue": 36627,
    "Gliese Catalog": "Gl 277A",
    "Distance in Parsecs": 11.9818,
    "mag": 11.82,
    "Absolute Visual Magnitude": 11.427,
    "Spectral Type": "M4",
    "Color Index": 1.567,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.774746,
    "y": 8.897552,
    "z": 7.081553,
    "vx": 0.00001036,
    "vy": 0.00001397,
    "vz": -0.00001185,
    "rarad": 1.97203037996798,
    "decrad": 0.632329942562231,
    "pmrarad": -0.00000125154651635416,
    "pmdecrad": -0.000001232735745,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 36522,
    "base": "Gl 277",
    "lum": 0.00233991456105509,
    "var": "BL",
    "var_min": 11.92,
    "var_max": 11.73,
    "ra": 7.532601,
    "dec": 36.229837,
    "pmra": -258.15,
    "pmdec": -254.27,
    "rv": 0.1,
    "Asset Name": "ASTRID 000523",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Naughty Dreams",
    "Hair": "Pixie",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00001036,
    "y_1": 0.00001397,
    "z_1": -0.00001185,
    "Distance in Parsecs_1": 11.9818,
    "HYG Star ID_1": 36522,
    "Hipparcos Catalogue_1": 36627,
    "Gliese Catalog_1": "Gl 277A"
  },
  {
    "Astrid #": "ASTRID 000524",
    "HYG Star ID": 108224,
    "Hipparcos Catalogue": 108569,
    "Gliese Catalog": "Gl 842",
    "Distance in Parsecs": 11.9861,
    "mag": 9.74,
    "Absolute Visual Magnitude": 9.347,
    "Spectral Type": "M2",
    "Color Index": 1.479,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.223357,
    "y": -3.028532,
    "z": -10.354419,
    "vx": 0.00002634,
    "vy": 0.0000443,
    "vz": -0.00001554,
    "rarad": 5.75770988420808,
    "decrad": -1.04288338076667,
    "pmrarad": 0.00000429990949558333,
    "pmdecrad": -6.12659048e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 108224,
    "lum": 0.0158927846988246,
    "ra": 21.992832,
    "dec": -59.752816,
    "pmra": 886.92,
    "pmdec": -126.37,
    "rv": 13.4,
    "Asset Name": "ASTRID 000524",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Side Part",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00002634,
    "y_1": 0.0000443,
    "z_1": -0.00001554,
    "Distance in Parsecs_1": 11.9861,
    "HYG Star ID_1": 108224,
    "Hipparcos Catalogue_1": 108569,
    "Gliese Catalog_1": "Gl 842"
  },
  {
    "Astrid #": "ASTRID 000525",
    "HYG Star ID": 118921,
    "Gliese Catalog": "GJ 1179A",
    "Distance in Parsecs": 11.9904,
    "mag": 15.32,
    "Absolute Visual Magnitude": 14.926,
    "Spectral Type": "dM4  :",
    "Color Index": 1.96,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -9.784265,
    "y": -4.997088,
    "z": 4.802811,
    "vx": -0.00003598,
    "vy": 0.00007776,
    "vz": 0.00000769,
    "rarad": 3.61381756726868,
    "decrad": 0.41212215988065,
    "pmrarad": -0.000007141014626375,
    "pmdecrad": 7.00177613e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118921,
    "base": "GJ 1179",
    "lum": 0.0000932395137540632,
    "ra": 13.803766,
    "dec": 23.61286,
    "pmra": -1472.94,
    "pmdec": 144.42,
    "rv": 0,
    "Asset Name": "ASTRID 000525",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Spiky",
    "Outfit": "Astro",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003598,
    "y_1": 0.00007776,
    "z_1": 0.00000769,
    "Distance in Parsecs_1": 11.9904,
    "HYG Star ID_1": 118921,
    "Gliese Catalog_1": "GJ 1179A"
  },
  {
    "Astrid #": "ASTRID 000526",
    "HYG Star ID": 118922,
    "Gliese Catalog": "GJ 1179B",
    "Distance in Parsecs": 11.9904,
    "mag": 15.65,
    "Absolute Visual Magnitude": 15.256,
    "Spectral Type": "DC9",
    "Color Index": 1.1,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -9.790376,
    "y": -4.991272,
    "z": 4.796402,
    "vx": -0.00003593,
    "vy": 0.00007779,
    "vz": 0.00000769,
    "rarad": 3.61309325026868,
    "decrad": 0.41153880788065,
    "pmrarad": -0.000007141014626375,
    "pmdecrad": 7.00177613e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118921,
    "base": "GJ 1179",
    "lum": 0.0000688018316143036,
    "ra": 13.801,
    "dec": 23.579437,
    "pmra": -1472.94,
    "pmdec": 144.42,
    "rv": 0,
    "Asset Name": "ASTRID 000526",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Firm",
    "Hair": "Afro",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003593,
    "y_1": 0.00007779,
    "z_1": 0.00000769,
    "Distance in Parsecs_1": 11.9904,
    "HYG Star ID_1": 118922,
    "Gliese Catalog_1": "GJ 1179B"
  },
  {
    "Astrid #": "ASTRID 000527",
    "HYG Star ID": 50494,
    "Hipparcos Catalogue": 50636,
    "Henry Draper Catalogue": 89694,
    "Distance in Parsecs": 12.0019,
    "mag": 10.65,
    "Absolute Visual Magnitude": 10.254,
    "Spectral Type": "K1III",
    "Color Index": 0.678,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.975212,
    "y": 4.62288,
    "z": -4.813494,
    "vx": 0.00000242,
    "vy": 0.00000208,
    "vz": -0.00000302,
    "rarad": 2.70762127272753,
    "decrad": -0.412674781191325,
    "pmrarad": -2.41631138388888e-7,
    "pmdecrad": -2.7430758e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 50494,
    "lum": 0.00689286860731844,
    "ra": 10.342351,
    "dec": -23.644523,
    "pmra": -49.84,
    "pmdec": -56.58,
    "rv": 0,
    "Asset Name": "ASTRID 000527",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Goddess",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000242,
    "y_1": 0.00000208,
    "z_1": -0.00000302,
    "Distance in Parsecs_1": 12.0019,
    "HYG Star ID_1": 50494,
    "Hipparcos Catalogue_1": 50636,
    "Henry Draper Catalogue_1": 89694
  },
  {
    "Astrid #": "ASTRID 000528",
    "HYG Star ID": 83841,
    "Hipparcos Catalogue": 84099,
    "Gliese Catalog": "NN 3992",
    "Distance in Parsecs": 12.0034,
    "mag": 11.54,
    "Absolute Visual Magnitude": 11.143,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.97155,
    "y": -9.19237,
    "z": 7.462904,
    "vx": 0.00001171,
    "vy": -0.00000417,
    "vz": -0.00000204,
    "rarad": 4.50111053953689,
    "decrad": 0.670952736409318,
    "pmrarad": 0.0000010266899313125,
    "pmdecrad": -2.17438935e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83841,
    "lum": 0.0030394849687701,
    "var_min": 11.627,
    "var_max": 11.427,
    "ra": 17.192976,
    "dec": 38.44276,
    "pmra": 211.77,
    "pmdec": -44.85,
    "rv": 0,
    "Asset Name": "ASTRID 000528",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Laughing",
    "Hair": "Innocent",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001171,
    "y_1": -0.00000417,
    "z_1": -0.00000204,
    "Distance in Parsecs_1": 12.0034,
    "HYG Star ID_1": 83841,
    "Hipparcos Catalogue_1": 84099,
    "Gliese Catalog_1": "NN 3992"
  },
  {
    "Astrid #": "ASTRID 000529",
    "HYG Star ID": 118721,
    "Gliese Catalog": "GJ 1140",
    "Distance in Parsecs": 12.0048,
    "mag": 14.31,
    "Absolute Visual Magnitude": 13.913,
    "Spectral Type": "DA6",
    "Color Index": 0.32,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -11.46294,
    "y": 3.200908,
    "z": -1.571755,
    "vx": 0.00001222,
    "vy": 0.00004541,
    "vz": 0.00000326,
    "rarad": 2.86929075500085,
    "decrad": -0.13130418052875,
    "pmrarad": -0.00000391743998299305,
    "pmdecrad": 2.73929425e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118721,
    "lum": 0.000237028189912977,
    "ra": 10.959883,
    "dec": -7.523175,
    "pmra": -808.03,
    "pmdec": 56.5,
    "rv": 0,
    "Asset Name": "ASTRID 000529",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Good Boy",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001222,
    "y_1": 0.00004541,
    "z_1": 0.00000326,
    "Distance in Parsecs_1": 12.0048,
    "HYG Star ID_1": 118721,
    "Gliese Catalog_1": "GJ 1140"
  },
  {
    "Astrid #": "ASTRID 000530",
    "HYG Star ID": 15293,
    "Hipparcos Catalogue": 15330,
    "Henry Draper Catalogue": 20766,
    "Harvard Revised Catalogue": 1006,
    "Gliese Catalog": "Gl 136",
    "Distance in Parsecs": 12.0077,
    "mag": 5.53,
    "Absolute Visual Magnitude": 5.133,
    "Spectral Type": "G2V",
    "Color Index": 0.641,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.596056,
    "y": 4.201871,
    "z": -10.658337,
    "vx": -0.00003363,
    "vy": 0.00008047,
    "vz": 0.00000632,
    "rarad": 0.862865291350047,
    "decrad": -1.09214540094526,
    "pmrarad": 0.00000648598286257638,
    "pmdecrad": 0.000003145034827,
    "bayer": "Zet-1",
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 15293,
    "lum": 0.770548536629714,
    "bf": "Zet1Ret",
    "ra": 3.295903,
    "dec": -62.575322,
    "pmra": 1337.83,
    "pmdec": 648.71,
    "rv": 12.2,
    "Asset Name": "ASTRID 000530",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Braided Ponytail",
    "Outfit": "Toga",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": -0.00003363,
    "y_1": 0.00008047,
    "z_1": 0.00000632,
    "Distance in Parsecs_1": 12.0077,
    "HYG Star ID_1": 15293,
    "Hipparcos Catalogue_1": 15330,
    "Henry Draper Catalogue_1": 20766,
    "Harvard Revised Catalogue_1": 1006,
    "Gliese Catalog_1": "Gl 136"
  },
  {
    "Astrid #": "ASTRID 000531",
    "HYG Star ID": 114702,
    "Hipparcos Catalogue": 115064,
    "Distance in Parsecs": 12.0178,
    "mag": 11.57,
    "Absolute Visual Magnitude": 11.171,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.712884,
    "y": -1.966966,
    "z": -5.078648,
    "vx": 5e-7,
    "vy": 0.0000012,
    "vz": 5.9e-7,
    "rarad": 6.10160022678209,
    "decrad": -0.436305337427865,
    "pmrarad": 1.05689382361111e-7,
    "pmdecrad": 5.4008244e-8,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 114702,
    "lum": 0.00296210193605276,
    "ra": 23.306396,
    "dec": -24.998454,
    "pmra": 21.8,
    "pmdec": 11.14,
    "rv": 0,
    "Asset Name": "ASTRID 000531",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Ram Horns",
    "Outfit": "Warlock",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 5e-7,
    "y_1": 0.0000012,
    "z_1": 5.9e-7,
    "Distance in Parsecs_1": 12.0178,
    "HYG Star ID_1": 114702,
    "Hipparcos Catalogue_1": 115064
  },
  {
    "Astrid #": "ASTRID 000532",
    "HYG Star ID": 15333,
    "Hipparcos Catalogue": 15371,
    "Henry Draper Catalogue": 20807,
    "Harvard Revised Catalogue": 1010,
    "Gliese Catalog": "Gl 138",
    "Distance in Parsecs": 12.0322,
    "mag": 5.24,
    "Absolute Visual Magnitude": 4.838,
    "Spectral Type": "G1V",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.603559,
    "y": 4.227199,
    "z": -10.6734,
    "vx": -0.00003384,
    "vy": 0.00007997,
    "vz": 0.00000698,
    "rarad": 0.864804347855391,
    "decrad": -1.0909418392729,
    "pmrarad": 0.00000645335490187499,
    "pmdecrad": 0.000003135968811,
    "bayer": "Zet-2",
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 15333,
    "lum": 1.0111137119549,
    "bf": "Zet2Ret",
    "ra": 3.303309,
    "dec": -62.506363,
    "pmra": 1331.1,
    "pmdec": 646.84,
    "rv": 11.5,
    "Asset Name": "ASTRID 000532",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Round Brush",
    "Outfit": "River Spirit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": -0.00003384,
    "y_1": 0.00007997,
    "z_1": 0.00000698,
    "Distance in Parsecs_1": 12.0322,
    "HYG Star ID_1": 15333,
    "Hipparcos Catalogue_1": 15371,
    "Henry Draper Catalogue_1": 20807,
    "Harvard Revised Catalogue_1": 1010,
    "Gliese Catalog_1": "Gl 138"
  },
  {
    "Astrid #": "ASTRID 000533",
    "HYG Star ID": 35096,
    "Hipparcos Catalogue": 35191,
    "Gliese Catalog": "Gl 268.3",
    "Distance in Parsecs": 12.0351,
    "mag": 10.83,
    "Absolute Visual Magnitude": 10.428,
    "Color Index": 1.52,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -3.501308,
    "y": 10.121224,
    "z": 5.490474,
    "vx": 3.8e-7,
    "vy": 0.00000564,
    "vz": -0.00001016,
    "rarad": 1.90384729902402,
    "decrad": 0.473725967626466,
    "pmrarad": -1.82968683041666e-7,
    "pmdecrad": -9.49071261e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 35096,
    "lum": 0.00587218865971302,
    "var_min": 10.886,
    "var_max": 10.736,
    "ra": 7.272161,
    "dec": 27.142499,
    "pmra": -37.74,
    "pmdec": -195.76,
    "rv": 0,
    "Asset Name": "ASTRID 000533",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Braided Bun",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 3.8e-7,
    "y_1": 0.00000564,
    "z_1": -0.00001016,
    "Distance in Parsecs_1": 12.0351,
    "HYG Star ID_1": 35096,
    "Hipparcos Catalogue_1": 35191,
    "Gliese Catalog_1": "Gl 268.3"
  },
  {
    "Astrid #": "ASTRID 000534",
    "HYG Star ID": 119146,
    "Gliese Catalog": "Gl 669B",
    "Distance in Parsecs": 12.0467,
    "mag": 12.97,
    "Absolute Visual Magnitude": 12.566,
    "Spectral Type": "dM5  e",
    "Color Index": 1.64,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.877194,
    "y": -10.614647,
    "z": 5.374617,
    "vx": -0.00000526,
    "vy": 0.00004238,
    "vz": 0.00000395,
    "rarad": 4.53735551252934,
    "decrad": 0.46252917388325,
    "pmrarad": -0.00000104234941319444,
    "pmdecrad": 0.000001805397665,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 84536,
    "base": "Gl 669",
    "lum": 0.000819596320481844,
    "ra": 17.331421,
    "dec": 26.50097,
    "pmra": -215,
    "pmdec": 372.39,
    "rv": -34,
    "Asset Name": "ASTRID 000534",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Pompadour",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000526,
    "y_1": 0.00004238,
    "z_1": 0.00000395,
    "Distance in Parsecs_1": 12.0467,
    "HYG Star ID_1": 119146,
    "Gliese Catalog_1": "Gl 669B"
  },
  {
    "Astrid #": "ASTRID 000535",
    "HYG Star ID": 118352,
    "Gliese Catalog": "NN 3331A",
    "Distance in Parsecs": 12.0482,
    "mag": 10.29,
    "Absolute Visual Magnitude": 9.885,
    "Spectral Type": "M2",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.575958,
    "y": 10.903377,
    "z": -4.431695,
    "vx": 0.00000245,
    "vy": 0.00000197,
    "vz": 0.00000626,
    "rarad": 1.33879760318172,
    "decrad": -0.3766748321286,
    "pmrarad": -1.60376365527777e-7,
    "pmdecrad": 5.59237428e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 118352,
    "base": "NN 3331",
    "lum": 0.00968277856261248,
    "ra": 5.11383,
    "dec": -21.581878,
    "pmra": -33.08,
    "pmdec": 115.35,
    "rv": 0,
    "Asset Name": "ASTRID 000535",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Wavy",
    "Outfit": "River Spirit",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000245,
    "y_1": 0.00000197,
    "z_1": 0.00000626,
    "Distance in Parsecs_1": 12.0482,
    "HYG Star ID_1": 118352,
    "Gliese Catalog_1": "NN 3331A"
  },
  {
    "Astrid #": "ASTRID 000536",
    "HYG Star ID": 118353,
    "Gliese Catalog": "NN 3332B",
    "Distance in Parsecs": 12.0482,
    "mag": 11.66,
    "Absolute Visual Magnitude": 11.255,
    "Spectral Type": "M3:",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.576751,
    "y": 10.903192,
    "z": -4.431691,
    "vx": 0.00000245,
    "vy": 0.00000197,
    "vz": 0.00000626,
    "rarad": 1.33872491318172,
    "decrad": -0.3766744891286,
    "pmrarad": -1.60376365527777e-7,
    "pmdecrad": 5.59237428e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 2,
    "comp_primary": 118353,
    "base": "NN 3332",
    "lum": 0.00274157417192787,
    "ra": 5.113552,
    "dec": -21.581858,
    "pmra": -33.08,
    "pmdec": 115.35,
    "rv": 0,
    "Asset Name": "ASTRID 000536",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Middle Part",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000245,
    "y_1": 0.00000197,
    "z_1": 0.00000626,
    "Distance in Parsecs_1": 12.0482,
    "HYG Star ID_1": 118353,
    "Gliese Catalog_1": "NN 3332B"
  },
  {
    "Astrid #": "ASTRID 000537",
    "HYG Star ID": 118816,
    "Gliese Catalog": "GJ 2092",
    "Distance in Parsecs": 12.0482,
    "mag": 13.97,
    "Absolute Visual Magnitude": 13.565,
    "Spectral Type": "DA/F",
    "Color Index": 0.4,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.827936,
    "y": -0.563275,
    "z": -11.027269,
    "vx": -0.00001303,
    "vy": -0.00000115,
    "vz": -0.00005571,
    "rarad": 3.25773962268504,
    "decrad": -1.15562974456645,
    "pmrarad": -3.04462991388888e-8,
    "pmdecrad": -8.72131329e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118816,
    "lum": 0.000326587832172335,
    "ra": 12.443649,
    "dec": -66.212707,
    "pmra": -6.28,
    "pmdec": -179.89,
    "rv": 55,
    "Asset Name": "ASTRID 000537",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Skater Helmet",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001303,
    "y_1": -0.00000115,
    "z_1": -0.00005571,
    "Distance in Parsecs_1": 12.0482,
    "HYG Star ID_1": 118816,
    "Gliese Catalog_1": "GJ 2092"
  },
  {
    "Astrid #": "ASTRID 000538",
    "HYG Star ID": 118818,
    "Gliese Catalog": "NN 3727A",
    "Distance in Parsecs": 12.0482,
    "mag": 10.96,
    "Absolute Visual Magnitude": 10.555,
    "Spectral Type": "M2",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.746178,
    "y": -1.488318,
    "z": -2.229648,
    "vx": -0.00000221,
    "vy": 0.00001618,
    "vz": 8.4e-7,
    "rarad": 3.26762878219932,
    "decrad": -0.1861336317702,
    "pmrarad": -0.00000135563601356944,
    "pmdecrad": 7.1044596e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 118818,
    "base": "NN 3727",
    "lum": 0.00522396188999119,
    "ra": 12.481423,
    "dec": -10.664672,
    "pmra": -279.62,
    "pmdec": 14.65,
    "rv": 0,
    "Asset Name": "ASTRID 000538",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "High Fade",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000221,
    "y_1": 0.00001618,
    "z_1": 8.4e-7,
    "Distance in Parsecs_1": 12.0482,
    "HYG Star ID_1": 118818,
    "Gliese Catalog_1": "NN 3727A"
  },
  {
    "Astrid #": "ASTRID 000539",
    "HYG Star ID": 118819,
    "Gliese Catalog": "NN 3728B",
    "Distance in Parsecs": 12.0482,
    "mag": 11,
    "Absolute Visual Magnitude": 10.595,
    "Spectral Type": "g",
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.746287,
    "y": -1.487463,
    "z": -2.229649,
    "vx": -0.00000221,
    "vy": 0.00001618,
    "vz": 8.4e-7,
    "rarad": 3.26755599519932,
    "decrad": -0.1861336717702,
    "pmrarad": -0.00000135563601356944,
    "pmdecrad": 7.1044596e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 2,
    "comp_primary": 118819,
    "base": "NN 3728",
    "lum": 0.00503500608787904,
    "ra": 12.481145,
    "dec": -10.664674,
    "pmra": -279.62,
    "pmdec": 14.65,
    "rv": 0,
    "Asset Name": "ASTRID 000539",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Chubby Cheeks",
    "Hair": "Braid",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000221,
    "y_1": 0.00001618,
    "z_1": 8.4e-7,
    "Distance in Parsecs_1": 12.0482,
    "HYG Star ID_1": 118819,
    "Gliese Catalog_1": "NN 3728B"
  },
  {
    "Astrid #": "ASTRID 000540",
    "HYG Star ID": 118193,
    "Gliese Catalog": "GJ 1053",
    "Distance in Parsecs": 12.0627,
    "mag": 14.64,
    "Absolute Visual Magnitude": 14.233,
    "Spectral Type": "sdM6",
    "Color Index": 1.79,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": 2.267085,
    "y": 2.494214,
    "z": 11.582227,
    "vx": -0.00003886,
    "vy": 0.00011522,
    "vz": -0.00001714,
    "rarad": 0.831928399200071,
    "decrad": 1.28760529506015,
    "pmrarad": 0.00000881706400142361,
    "pmdecrad": -0.000005090538797,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118193,
    "lum": 0.000176522471745255,
    "ra": 3.177732,
    "dec": 73.774349,
    "pmra": 1818.65,
    "pmdec": -1050,
    "rv": 0,
    "Asset Name": "ASTRID 000540",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Ninja",
    "Hair": "Lion Mane",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003886,
    "y_1": 0.00011522,
    "z_1": -0.00001714,
    "Distance in Parsecs_1": 12.0627,
    "HYG Star ID_1": 118193,
    "Gliese Catalog_1": "GJ 1053"
  },
  {
    "Astrid #": "ASTRID 000541",
    "HYG Star ID": 80442,
    "Hipparcos Catalogue": 80686,
    "Henry Draper Catalogue": 147584,
    "Harvard Revised Catalogue": 6098,
    "Gliese Catalog": "Gl 624",
    "Distance in Parsecs": 12.1168,
    "mag": 4.9,
    "Absolute Visual Magnitude": 4.483,
    "Spectral Type": "F9V",
    "Color Index": 0.555,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.604938,
    "y": -3.802618,
    "z": -11.392224,
    "vx": 0.00000741,
    "vy": -0.00001264,
    "vz": -0.00000509,
    "rarad": 4.31299348857307,
    "decrad": -1.22320354800401,
    "pmrarad": 9.69094066062499e-7,
    "pmdecrad": 5.37028113e-7,
    "bayer": "Zet",
    "con": "TrA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum Australe",
    "FULL CONSTELLATION NAME": "Triangulum Australe",
    "comp": 1,
    "comp_primary": 80442,
    "lum": 1.40216783370575,
    "bf": "Zet TrA",
    "ra": 16.474422,
    "dec": -70.084401,
    "pmra": 199.89,
    "pmdec": 110.77,
    "rv": 7.6,
    "Asset Name": "ASTRID 000541",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Ponytail",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum Australe",
    "x_1": 0.00000741,
    "y_1": -0.00001264,
    "z_1": -0.00000509,
    "Distance in Parsecs_1": 12.1168,
    "HYG Star ID_1": 80442,
    "Hipparcos Catalogue_1": 80686,
    "Henry Draper Catalogue_1": 147584,
    "Harvard Revised Catalogue_1": 6098,
    "Gliese Catalog_1": "Gl 624"
  },
  {
    "Astrid #": "ASTRID 000542",
    "HYG Star ID": 22709,
    "Hipparcos Catalogue": 22762,
    "Gliese Catalog": "Gl 180",
    "Distance in Parsecs": 12.1183,
    "mag": 10.9,
    "Absolute Visual Magnitude": 10.483,
    "Spectral Type": "M3",
    "Color Index": 1.547,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.285536,
    "y": 11.062177,
    "z": -3.699113,
    "vx": -0.00003047,
    "vy": -0.00001824,
    "vz": -0.00003136,
    "rarad": 1.2820874560885,
    "decrad": -0.310204675098312,
    "pmrarad": 0.00000198172440063888,
    "pmdecrad": -0.00000312389695,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 22709,
    "lum": 0.00558213068917725,
    "var_min": 10.97,
    "var_max": 10.83,
    "ra": 4.897213,
    "dec": -17.773419,
    "pmra": 408.76,
    "pmdec": -644.35,
    "rv": -15,
    "Asset Name": "ASTRID 000542",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Nervous",
    "Hair": "Wavy",
    "Outfit": "Toga",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00003047,
    "y_1": -0.00001824,
    "z_1": -0.00003136,
    "Distance in Parsecs_1": 12.1183,
    "HYG Star ID_1": 22709,
    "Hipparcos Catalogue_1": 22762,
    "Gliese Catalog_1": "Gl 180"
  },
  {
    "Astrid #": "ASTRID 000543",
    "HYG Star ID": 77022,
    "Hipparcos Catalogue": 77257,
    "Henry Draper Catalogue": 141004,
    "Harvard Revised Catalogue": 5868,
    "Gliese Catalog": "Gl 598",
    "Distance in Parsecs": 12.1242,
    "mag": 4.42,
    "Absolute Visual Magnitude": 4.002,
    "Spectral Type": "G0Vvar",
    "Color Index": 0.604,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.61701,
    "y": -10.039373,
    "z": 1.551618,
    "vx": 0.00002689,
    "vy": 0.00006488,
    "vz": -0.00001296,
    "rarad": 4.12963912559585,
    "decrad": 0.128335336299586,
    "pmrarad": -0.00000109330333102083,
    "pmdecrad": -3.32194333e-7,
    "bayer": "Lam",
    "flam": 27,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 77022,
    "lum": 2.18373532760217,
    "bf": "27Lam Ser",
    "ra": 15.77406,
    "dec": 7.353073,
    "pmra": -225.51,
    "pmdec": -68.52,
    "rv": -68.5,
    "Asset Name": "ASTRID 000543",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Undercut",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00002689,
    "y_1": 0.00006488,
    "z_1": -0.00001296,
    "Distance in Parsecs_1": 12.1242,
    "HYG Star ID_1": 77022,
    "Hipparcos Catalogue_1": 77257,
    "Henry Draper Catalogue_1": 141004,
    "Harvard Revised Catalogue_1": 5868,
    "Gliese Catalog_1": "Gl 598"
  },
  {
    "Astrid #": "ASTRID 000544",
    "HYG Star ID": 101855,
    "Hipparcos Catalogue": 102186,
    "Henry Draper Catalogue": 196877,
    "Gliese Catalog": "Gl 798",
    "Distance in Parsecs": 12.1492,
    "mag": 8.83,
    "Absolute Visual Magnitude": 8.407,
    "Spectral Type": "K7V",
    "Color Index": 1.324,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.788986,
    "y": -5.591699,
    "z": -9.663966,
    "vx": -0.00004613,
    "vy": 0.00006124,
    "vz": -0.00000314,
    "rarad": 5.4206109190244,
    "decrad": -0.919775645208655,
    "pmrarad": 3.95317075125e-7,
    "pmdecrad": -0.000005167338132,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 101855,
    "lum": 0.0377746109388218,
    "ra": 20.705209,
    "dec": -52.699263,
    "pmra": 81.54,
    "pmdec": -1065.84,
    "rv": -42.9,
    "Asset Name": "ASTRID 000544",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Owie",
    "Hair": "Bangs",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00004613,
    "y_1": 0.00006124,
    "z_1": -0.00000314,
    "Distance in Parsecs_1": 12.1492,
    "HYG Star ID_1": 101855,
    "Hipparcos Catalogue_1": 102186,
    "Henry Draper Catalogue_1": 196877,
    "Gliese Catalog_1": "Gl 798"
  },
  {
    "Astrid #": "ASTRID 000545",
    "HYG Star ID": 118640,
    "Gliese Catalog": "Gl 359",
    "Distance in Parsecs": 12.1655,
    "mag": 14.23,
    "Absolute Visual Magnitude": 13.804,
    "Spectral Type": "M4",
    "Color Index": 1.78,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.267294,
    "y": 6.426743,
    "z": 4.562194,
    "vx": -0.00002452,
    "vy": -0.00001693,
    "vz": -0.00002595,
    "rarad": 2.53524253146697,
    "decrad": 0.384408473864899,
    "pmrarad": 0.00000229282933945138,
    "pmdecrad": -0.000002300862702,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118640,
    "lum": 0.000262059555473334,
    "ra": 9.683913,
    "dec": 22.024983,
    "pmra": 472.93,
    "pmdec": -474.59,
    "rv": 0,
    "Asset Name": "ASTRID 000545",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "High Bun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002452,
    "y_1": -0.00001693,
    "z_1": -0.00002595,
    "Distance in Parsecs_1": 12.1655,
    "HYG Star ID_1": 118640,
    "Gliese Catalog_1": "Gl 359"
  },
  {
    "Astrid #": "ASTRID 000546",
    "HYG Star ID": 104095,
    "Hipparcos Catalogue": 104432,
    "Gliese Catalog": "Gl 821",
    "Distance in Parsecs": 12.1684,
    "mag": 10.87,
    "Absolute Visual Magnitude": 10.444,
    "Spectral Type": "M3",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.705568,
    "y": -8.02691,
    "z": -2.79973,
    "vx": -0.00003613,
    "vy": 0.00009032,
    "vz": -0.00010019,
    "rarad": 5.53832337371523,
    "decrad": -0.232172504499712,
    "pmrarad": 0.00000344431031213888,
    "pmdecrad": -0.00000967285711,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 104095,
    "lum": 0.00578628738817951,
    "var_min": 10.934,
    "var_max": 10.804,
    "ra": 21.154837,
    "dec": -13.302505,
    "pmra": 710.44,
    "pmdec": -1995.17,
    "rv": -61,
    "Asset Name": "ASTRID 000546",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "Medium Bob",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00003613,
    "y_1": 0.00009032,
    "z_1": -0.00010019,
    "Distance in Parsecs_1": 12.1684,
    "HYG Star ID_1": 104095,
    "Hipparcos Catalogue_1": 104432,
    "Gliese Catalog_1": "Gl 821"
  },
  {
    "Astrid #": "ASTRID 000547",
    "HYG Star ID": 171,
    "Hipparcos Catalogue": 171,
    "Henry Draper Catalogue": 224930,
    "Harvard Revised Catalogue": 9088,
    "Gliese Catalog": "Gl 914A",
    "Distance in Parsecs": 12.1699,
    "mag": 5.8,
    "Absolute Visual Magnitude": 5.374,
    "Spectral Type": "G3V",
    "Color Index": 0.69,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.834733,
    "y": 0.102555,
    "z": 5.540428,
    "vx": -0.00000999,
    "vy": 0.00004584,
    "vz": -0.00006577,
    "rarad": 0.00946108171981008,
    "decrad": 0.472674535797847,
    "pmrarad": 0.0000037747108354375,
    "pmdecrad": -0.000004454080246,
    "flam": 85,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 171,
    "base": "Gl 914",
    "lum": 0.6171631684555829,
    "bf": "85    Peg",
    "ra": 0.036139,
    "dec": 27.082256,
    "pmra": 778.59,
    "pmdec": -918.72,
    "rv": -37.6,
    "Asset Name": "ASTRID 000547",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Lion Mane",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000999,
    "y_1": 0.00004584,
    "z_1": -0.00006577,
    "Distance in Parsecs_1": 12.1699,
    "HYG Star ID_1": 171,
    "Hipparcos Catalogue_1": 171,
    "Henry Draper Catalogue_1": 224930,
    "Harvard Revised Catalogue_1": 9088,
    "Gliese Catalog_1": "Gl 914A"
  },
  {
    "Astrid #": "ASTRID 000548",
    "HYG Star ID": 118575,
    "Gliese Catalog": "NN 3518",
    "Distance in Parsecs": 12.1951,
    "mag": 14,
    "Absolute Visual Magnitude": 13.569,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.723554,
    "y": 8.044465,
    "z": -4.934953,
    "vx": 0.00002433,
    "vy": 0.00002487,
    "vz": 0.00000245,
    "rarad": 2.33585868575213,
    "decrad": -0.416614568696799,
    "pmrarad": -0.00000285196495723611,
    "pmdecrad": 2.19446064e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118575,
    "lum": 0.000325386851780107,
    "ra": 8.922323,
    "dec": -23.870256,
    "pmra": -588.26,
    "pmdec": 45.26,
    "rv": 0,
    "Asset Name": "ASTRID 000548",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Neko Dreams",
    "Hair": "Braided Bun",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002433,
    "y_1": 0.00002487,
    "z_1": 0.00000245,
    "Distance in Parsecs_1": 12.1951,
    "HYG Star ID_1": 118575,
    "Gliese Catalog_1": "NN 3518"
  },
  {
    "Astrid #": "ASTRID 000549",
    "HYG Star ID": 118597,
    "Gliese Catalog": "NN 3533",
    "Distance in Parsecs": 12.1951,
    "mag": 14.3,
    "Absolute Visual Magnitude": 13.869,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.228877,
    "y": 7.73763,
    "z": -4.597295,
    "vx": 0.00001882,
    "vy": 0.00000658,
    "vz": -0.00002262,
    "rarad": 2.38695793923441,
    "decrad": -0.38653234035625,
    "pmrarad": -0.00000144983531170138,
    "pmdecrad": -0.000002002867125,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118597,
    "lum": 0.000246831168969734,
    "ra": 9.117508,
    "dec": -22.146672,
    "pmra": -299.05,
    "pmdec": -413.12,
    "rv": 0,
    "Asset Name": "ASTRID 000549",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Innocent",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001882,
    "y_1": 0.00000658,
    "z_1": -0.00002262,
    "Distance in Parsecs_1": 12.1951,
    "HYG Star ID_1": 118597,
    "Gliese Catalog_1": "NN 3533"
  },
  {
    "Astrid #": "ASTRID 000550",
    "HYG Star ID": 118733,
    "Gliese Catalog": "NN 3647",
    "Distance in Parsecs": 12.1951,
    "mag": 12.38,
    "Absolute Visual Magnitude": 11.949,
    "Spectral Type": "dM4",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -9.941643,
    "y": 2.119361,
    "z": 6.737397,
    "vx": 0.00000525,
    "vy": 0.00000945,
    "vz": 0.00000478,
    "rarad": 2.93156493719549,
    "decrad": 0.5853216850096,
    "pmrarad": -8.48036090027777e-7,
    "pmdecrad": 4.70080192e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118733,
    "lum": 0.00144677168324772,
    "ra": 11.197753,
    "dec": 33.536462,
    "pmra": -174.92,
    "pmdec": 96.96,
    "rv": 0,
    "Asset Name": "ASTRID 000550",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Unkempt",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000525,
    "y_1": 0.00000945,
    "z_1": 0.00000478,
    "Distance in Parsecs_1": 12.1951,
    "HYG Star ID_1": 118733,
    "Gliese Catalog_1": "NN 3647"
  },
  {
    "Astrid #": "ASTRID 000551",
    "HYG Star ID": 118913,
    "Gliese Catalog": "NN 3800",
    "Distance in Parsecs": 12.1951,
    "mag": 13.5,
    "Absolute Visual Magnitude": 13.069,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.576796,
    "y": -5.054138,
    "z": -3.362667,
    "vx": -0.0000128,
    "vy": 0.00002729,
    "vz": -7.6e-7,
    "rarad": 3.58736997214468,
    "decrad": -0.2793585901313,
    "pmrarad": -0.00000247167710620833,
    "pmdecrad": -6.4722626e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118913,
    "lum": 0.000515703406302645,
    "ra": 13.702744,
    "dec": -16.006068,
    "pmra": -509.82,
    "pmdec": -13.35,
    "rv": 0,
    "Asset Name": "ASTRID 000551",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Straw Hat",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000128,
    "y_1": 0.00002729,
    "z_1": -7.6e-7,
    "Distance in Parsecs_1": 12.1951,
    "HYG Star ID_1": 118913,
    "Gliese Catalog_1": "NN 3800"
  },
  {
    "Astrid #": "ASTRID 000552",
    "HYG Star ID": 7750,
    "Hipparcos Catalogue": 7765,
    "Henry Draper Catalogue": 10182,
    "Distance in Parsecs": 12.1981,
    "mag": 8.68,
    "Absolute Visual Magnitude": 8.249,
    "Spectral Type": "K0",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.666898,
    "y": 4.970337,
    "z": 3.210403,
    "vx": -0.00000264,
    "vy": 0.00000699,
    "vz": -0.00000205,
    "rarad": 0.436045685222404,
    "decrad": 0.266326037230602,
    "pmrarad": 6.10768274763889e-7,
    "pmdecrad": -1.74145074e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 7750,
    "lum": 0.0436918063385287,
    "var_min": 8.998,
    "var_max": 8.238,
    "ra": 1.665572,
    "dec": 15.259358,
    "pmra": 125.98,
    "pmdec": -35.92,
    "rv": 0,
    "Asset Name": "ASTRID 000552",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Headband",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000264,
    "y_1": 0.00000699,
    "z_1": -0.00000205,
    "Distance in Parsecs_1": 12.1981,
    "HYG Star ID_1": 7750,
    "Hipparcos Catalogue_1": 7765,
    "Henry Draper Catalogue_1": 10182
  },
  {
    "Astrid #": "ASTRID 000553",
    "HYG Star ID": 41807,
    "Hipparcos Catalogue": 41926,
    "Henry Draper Catalogue": 72673,
    "Harvard Revised Catalogue": 3384,
    "Gliese Catalog": "Gl 309",
    "Distance in Parsecs": 12.2085,
    "mag": 6.38,
    "Absolute Visual Magnitude": 5.947,
    "Spectral Type": "K0V",
    "Color Index": 0.78,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.439391,
    "y": 8.178727,
    "z": -6.379152,
    "vx": 0.00002874,
    "vy": 0.00007004,
    "vz": 0.00003005,
    "rarad": 2.23777449005811,
    "decrad": -0.54979359750158,
    "pmrarad": -0.00000539907907213889,
    "pmdecrad": 0.000003692971248,
    "con": "Pyx",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pyxis",
    "FULL CONSTELLATION NAME": "Pyxis",
    "comp": 1,
    "comp_primary": 41807,
    "lum": 0.3640826637894,
    "ra": 8.547669,
    "dec": -31.500853,
    "pmra": -1113.64,
    "pmdec": 761.73,
    "rv": 15.7,
    "Asset Name": "ASTRID 000553",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Good Boy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pyxis",
    "x_1": 0.00002874,
    "y_1": 0.00007004,
    "z_1": 0.00003005,
    "Distance in Parsecs_1": 12.2085,
    "HYG Star ID_1": 41807,
    "Hipparcos Catalogue_1": 41926,
    "Henry Draper Catalogue_1": 72673,
    "Harvard Revised Catalogue_1": 3384,
    "Gliese Catalog_1": "Gl 309"
  },
  {
    "Astrid #": "ASTRID 000554",
    "HYG Star ID": 119587,
    "Gliese Catalog": "GJ 1288",
    "Distance in Parsecs": 12.21,
    "mag": 14.36,
    "Absolute Visual Magnitude": 13.926,
    "Spectral Type": "m",
    "Color Index": 1.77,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.456214,
    "y": -0.783167,
    "z": 6.256063,
    "vx": 0.00000663,
    "vy": -0.00002044,
    "vz": -0.00001364,
    "rarad": 6.20843859018132,
    "decrad": 0.5379447400018,
    "pmrarad": -0.00000162921637350694,
    "pmdecrad": -0.000001300599964,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119587,
    "lum": 0.000234207069479382,
    "ra": 23.714489,
    "dec": 30.821963,
    "pmra": -336.05,
    "pmdec": -268.27,
    "rv": 0,
    "Asset Name": "ASTRID 000554",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous",
    "Hair": "Bob",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000663,
    "y_1": -0.00002044,
    "z_1": -0.00001364,
    "Distance in Parsecs_1": 12.21,
    "HYG Star ID_1": 119587,
    "Gliese Catalog_1": "GJ 1288"
  },
  {
    "Astrid #": "ASTRID 000555",
    "HYG Star ID": 119438,
    "Gliese Catalog": "GJ 1263",
    "Distance in Parsecs": 12.2249,
    "mag": 12.65,
    "Absolute Visual Magnitude": 12.214,
    "Spectral Type": "M3.5",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.215578,
    "y": -6.718446,
    "z": -0.037617,
    "vx": 0.00005776,
    "vy": 0.00001539,
    "vz": -0.00003043,
    "rarad": 5.70143938908426,
    "decrad": -0.00307658688675,
    "pmrarad": 0.00000364890168533333,
    "pmdecrad": -0.000002479797735,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119438,
    "lum": 0.00113344382297103,
    "ra": 21.777894,
    "dec": -0.176275,
    "pmra": 752.64,
    "pmdec": -511.5,
    "rv": 39,
    "Asset Name": "ASTRID 000555",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Charmer",
    "Hair": "Manbun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005776,
    "y_1": 0.00001539,
    "z_1": -0.00003043,
    "Distance in Parsecs_1": 12.2249,
    "HYG Star ID_1": 119438,
    "Gliese Catalog_1": "GJ 1263"
  },
  {
    "Astrid #": "ASTRID 000556",
    "HYG Star ID": 9272,
    "Hipparcos Catalogue": 9291,
    "Gliese Catalog": "Gl 82",
    "Distance in Parsecs": 12.2474,
    "mag": 12.21,
    "Absolute Visual Magnitude": 11.77,
    "Spectral Type": "M4Ve",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.547005,
    "y": 3.182972,
    "z": 10.444918,
    "vx": -0.00000501,
    "vy": 0.00001916,
    "vz": -0.00001373,
    "rarad": 0.520932995565422,
    "decrad": 1.02138645086758,
    "pmrarad": 0.00000156017890539583,
    "pmdecrad": -9.46986562e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 9272,
    "lum": 0.00170608238900312,
    "var": "V596",
    "var_min": 12.348,
    "var_max": 12.058,
    "ra": 1.989817,
    "dec": 58.521133,
    "pmra": 321.81,
    "pmdec": -195.33,
    "rv": -8.8,
    "Asset Name": "ASTRID 000556",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Round Brush",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000501,
    "y_1": 0.00001916,
    "z_1": -0.00001373,
    "Distance in Parsecs_1": 12.2474,
    "HYG Star ID_1": 9272,
    "Hipparcos Catalogue_1": 9291,
    "Gliese Catalog_1": "Gl 82"
  },
  {
    "Astrid #": "ASTRID 000557",
    "HYG Star ID": 119030,
    "Gliese Catalog": "Gl 590",
    "Distance in Parsecs": 12.2549,
    "mag": 12.8,
    "Absolute Visual Magnitude": 12.358,
    "Spectral Type": "M4",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.664121,
    "y": -7.837528,
    "z": -7.528178,
    "vx": 0.00000182,
    "vy": 0.0000359,
    "vz": -0.00003877,
    "rarad": 4.08661638118107,
    "decrad": -0.661497870761199,
    "pmrarad": -0.00000159537637860416,
    "pmdecrad": -0.000004009055224,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119030,
    "lum": 0.000992658806871029,
    "ra": 15.609725,
    "dec": -37.901036,
    "pmra": -329.07,
    "pmdec": -826.93,
    "rv": 0,
    "Asset Name": "ASTRID 000557",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Curly",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000182,
    "y_1": 0.0000359,
    "z_1": -0.00003877,
    "Distance in Parsecs_1": 12.2549,
    "HYG Star ID_1": 119030,
    "Gliese Catalog_1": "Gl 590"
  },
  {
    "Astrid #": "ASTRID 000558",
    "HYG Star ID": 26714,
    "Hipparcos Catalogue": 26779,
    "Henry Draper Catalogue": 37394,
    "Harvard Revised Catalogue": 1925,
    "Gliese Catalog": "Gl 211",
    "Distance in Parsecs": 12.2775,
    "mag": 6.21,
    "Absolute Visual Magnitude": 5.764,
    "Spectral Type": "K1V",
    "Color Index": 0.84,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.594246,
    "y": 7.282002,
    "z": 9.866951,
    "vx": 0.00000197,
    "vy": 0.00002607,
    "vz": -0.00001707,
    "rarad": 1.48937195753723,
    "decrad": 0.93342054383702,
    "pmrarad": 1.3089969375e-8,
    "pmdecrad": -0.000002538532912,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 26714,
    "lum": 0.430923322818626,
    "var": "V538",
    "var_min": 6.236,
    "var_max": 6.196,
    "ra": 5.688982,
    "dec": 53.481058,
    "pmra": 2.7,
    "pmdec": -523.61,
    "rv": 1.8,
    "Asset Name": "ASTRID 000558",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Charmer",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000197,
    "y_1": 0.00002607,
    "z_1": -0.00001707,
    "Distance in Parsecs_1": 12.2775,
    "HYG Star ID_1": 26714,
    "Hipparcos Catalogue_1": 26779,
    "Henry Draper Catalogue_1": 37394,
    "Harvard Revised Catalogue_1": 1925,
    "Gliese Catalog_1": "Gl 211"
  },
  {
    "Astrid #": "ASTRID 000559",
    "HYG Star ID": 92776,
    "Hipparcos Catalogue": 93069,
    "Henry Draper Catalogue": 175224,
    "Gliese Catalog": "Gl 737A",
    "Distance in Parsecs": 12.285,
    "mag": 8.86,
    "Absolute Visual Magnitude": 8.413,
    "Spectral Type": "M2V",
    "Color Index": 1.433,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.706149,
    "y": -6.655863,
    "z": -10.183654,
    "vx": -0.00000727,
    "vy": 0.00002862,
    "vz": -0.00000327,
    "rarad": 4.9633236862391,
    "decrad": -0.977242828291153,
    "pmrarad": 5.28446911805555e-9,
    "pmdecrad": -0.000002141518989,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 92776,
    "base": "Gl 737",
    "lum": 0.0375664364614458,
    "ra": 18.9585,
    "dec": -55.99189,
    "pmra": 1.09,
    "pmdec": -441.72,
    "rv": -13.5,
    "Asset Name": "ASTRID 000559",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Middle Part",
    "Outfit": "Flame Soul",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.00000727,
    "y_1": 0.00002862,
    "z_1": -0.00000327,
    "Distance in Parsecs_1": 12.285,
    "HYG Star ID_1": 92776,
    "Hipparcos Catalogue_1": 93069,
    "Henry Draper Catalogue_1": 175224,
    "Gliese Catalog_1": "Gl 737A"
  },
  {
    "Astrid #": "ASTRID 000560",
    "HYG Star ID": 118078,
    "Gliese Catalog": "Gl 64",
    "Distance in Parsecs": 12.285,
    "mag": 12.84,
    "Absolute Visual Magnitude": 12.393,
    "Spectral Type": "DA7",
    "Color Index": 0.35,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.136753,
    "y": 5.074421,
    "z": -1.069684,
    "vx": -0.0000158,
    "vy": 0.00003038,
    "vz": -0.00002047,
    "rarad": 0.427539024248371,
    "decrad": -0.0871827785403999,
    "pmrarad": 0.00000278428496743055,
    "pmdecrad": -0.000001672970808,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118078,
    "lum": 0.000961169541515929,
    "ra": 1.633079,
    "dec": -4.995205,
    "pmra": 574.3,
    "pmdec": -345.07,
    "rv": 0,
    "Asset Name": "ASTRID 000560",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "High Bun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000158,
    "y_1": 0.00003038,
    "z_1": -0.00002047,
    "Distance in Parsecs_1": 12.285,
    "HYG Star ID_1": 118078,
    "Gliese Catalog_1": "Gl 64"
  },
  {
    "Astrid #": "ASTRID 000561",
    "HYG Star ID": 22574,
    "Hipparcos Catalogue": 22627,
    "Gliese Catalog": "Gl 179",
    "Distance in Parsecs": 12.288,
    "mag": 11.94,
    "Absolute Visual Magnitude": 11.493,
    "Spectral Type": "M4",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.564852,
    "y": 11.677498,
    "z": 1.386033,
    "vx": -0.00001003,
    "vy": -0.00000305,
    "vz": -0.00001926,
    "rarad": 1.27450681331267,
    "decrad": 0.113036935489955,
    "pmrarad": 7.08264305923611e-7,
    "pmdecrad": -0.000001503601148,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 22574,
    "lum": 0.00220191221148447,
    "var_min": 12.079,
    "var_max": 11.849,
    "ra": 4.868257,
    "dec": 6.476539,
    "pmra": 146.09,
    "pmdec": -310.14,
    "rv": -7.8,
    "Asset Name": "ASTRID 000561",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Mohawk",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00001003,
    "y_1": -0.00000305,
    "z_1": -0.00001926,
    "Distance in Parsecs_1": 12.288,
    "HYG Star ID_1": 22574,
    "Hipparcos Catalogue_1": 22627,
    "Gliese Catalog_1": "Gl 179"
  },
  {
    "Astrid #": "ASTRID 000562",
    "HYG Star ID": 36236,
    "Hipparcos Catalogue": 36338,
    "Gliese Catalog": "GJ 1097",
    "Distance in Parsecs": 12.288,
    "mag": 11.47,
    "Absolute Visual Magnitude": 11.023,
    "Color Index": 1.486,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -4.6331,
    "y": 11.359117,
    "z": -0.706952,
    "vx": -0.000023,
    "vy": -0.00001232,
    "vz": -0.00004724,
    "rarad": 1.95807299409764,
    "decrad": -0.0575637087020799,
    "pmrarad": 0.00000211189687386805,
    "pmdecrad": -0.000003851165952,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 36236,
    "lum": 0.00339468905380078,
    "var_min": 11.532,
    "var_max": 11.392,
    "ra": 7.479288,
    "dec": -3.298158,
    "pmra": 435.61,
    "pmdec": -794.36,
    "rv": 0,
    "Asset Name": "ASTRID 000562",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Ninja",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.000023,
    "y_1": -0.00001232,
    "z_1": -0.00004724,
    "Distance in Parsecs_1": 12.288,
    "HYG Star ID_1": 36236,
    "Hipparcos Catalogue_1": 36338,
    "Gliese Catalog_1": "GJ 1097"
  },
  {
    "Astrid #": "ASTRID 000563",
    "HYG Star ID": 47485,
    "Hipparcos Catalogue": 47620,
    "Gliese Catalog": "Gl 360",
    "Distance in Parsecs": 12.2895,
    "mag": 10.56,
    "Absolute Visual Magnitude": 10.112,
    "Spectral Type": "M3",
    "Color Index": 1.463,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.464414,
    "y": 2.368123,
    "z": 11.550894,
    "vx": 0.00000814,
    "vy": 0.00004284,
    "vz": 0.00000117,
    "rarad": 2.54204277095156,
    "decrad": 1.2223218971212,
    "pmrarad": -0.00000325154839274999,
    "pmdecrad": -0.000001302500434,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 47485,
    "lum": 0.00785597332260988,
    "var_min": 10.647,
    "var_max": 10.497,
    "ra": 9.709888,
    "dec": 70.033886,
    "pmra": -670.68,
    "pmdec": -268.66,
    "rv": 6.9,
    "Asset Name": "ASTRID 000563",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Round Brush",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000814,
    "y_1": 0.00004284,
    "z_1": 0.00000117,
    "Distance in Parsecs_1": 12.2895,
    "HYG Star ID_1": 47485,
    "Hipparcos Catalogue_1": 47620,
    "Gliese Catalog_1": "Gl 360"
  },
  {
    "Astrid #": "ASTRID 000564",
    "HYG Star ID": 24227,
    "Hipparcos Catalogue": 24284,
    "Gliese Catalog": "Gl 192",
    "Distance in Parsecs": 12.2926,
    "mag": 10.82,
    "Absolute Visual Magnitude": 10.372,
    "Spectral Type": "M5",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.371876,
    "y": 11.329749,
    "z": 4.136752,
    "vx": -0.00002267,
    "vy": -0.00002828,
    "vz": 0.00000354,
    "rarad": 1.36442666264709,
    "decrad": 0.343230336061798,
    "pmrarad": 0.00000133367395384027,
    "pmdecrad": 0.000001155892777,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 24227,
    "lum": 0.00618301072751713,
    "var_min": 10.885,
    "var_max": 10.765,
    "ra": 5.211726,
    "dec": 19.66565,
    "pmra": 275.09,
    "pmdec": 238.42,
    "rv": -28.6,
    "Asset Name": "ASTRID 000564",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Smoulder",
    "Hair": "Horns",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00002267,
    "y_1": -0.00002828,
    "z_1": 0.00000354,
    "Distance in Parsecs_1": 12.2926,
    "HYG Star ID_1": 24227,
    "Hipparcos Catalogue_1": 24284,
    "Gliese Catalog_1": "Gl 192"
  },
  {
    "Astrid #": "ASTRID 000565",
    "HYG Star ID": 102070,
    "Hipparcos Catalogue": 102401,
    "Gliese Catalog": "Gl 806",
    "Distance in Parsecs": 12.3198,
    "mag": 10.79,
    "Absolute Visual Magnitude": 10.337,
    "Spectral Type": "M3",
    "Color Index": 1.491,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.795698,
    "y": -6.604754,
    "z": 8.634767,
    "vx": 1.9e-7,
    "vy": 0.00003904,
    "vz": -0.00000586,
    "rarad": 5.43262797543734,
    "decrad": 0.776655342138368,
    "pmrarad": 0.00000210176426794444,
    "pmdecrad": 0.000001323444385,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 102070,
    "lum": 0.00638557485078839,
    "var_min": 10.841,
    "var_max": 10.721,
    "ra": 20.75111,
    "dec": 44.499073,
    "pmra": 433.52,
    "pmdec": 272.98,
    "rv": -24.4,
    "Asset Name": "ASTRID 000565",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Neko",
    "Outfit": "Warlock",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 1.9e-7,
    "y_1": 0.00003904,
    "z_1": -0.00000586,
    "Distance in Parsecs_1": 12.3198,
    "HYG Star ID_1": 102070,
    "Hipparcos Catalogue_1": 102401,
    "Gliese Catalog_1": "Gl 806"
  },
  {
    "Astrid #": "ASTRID 000566",
    "HYG Star ID": 49828,
    "Hipparcos Catalogue": 49969,
    "Gliese Catalog": "Gl 381",
    "Distance in Parsecs": 12.3335,
    "mag": 10.64,
    "Absolute Visual Magnitude": 10.185,
    "Spectral Type": "M0",
    "Color Index": 1.566,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.979393,
    "y": 5.589562,
    "z": -0.577721,
    "vx": -0.00004906,
    "vy": -0.00001001,
    "vz": -0.00003783,
    "rarad": 2.67069497962374,
    "decrad": -0.046857415986845,
    "pmrarad": 0.00000252864271367361,
    "pmdecrad": -0.000002915427068,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 49828,
    "lum": 0.00734513868157114,
    "var_min": 10.702,
    "var_max": 10.522,
    "ra": 10.201303,
    "dec": -2.684732,
    "pmra": 521.57,
    "pmdec": -601.35,
    "rv": 40,
    "Asset Name": "ASTRID 000566",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Afro",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": -0.00004906,
    "y_1": -0.00001001,
    "z_1": -0.00003783,
    "Distance in Parsecs_1": 12.3335,
    "HYG Star ID_1": 49828,
    "Hipparcos Catalogue_1": 49969,
    "Gliese Catalog_1": "Gl 381"
  },
  {
    "Astrid #": "ASTRID 000567",
    "HYG Star ID": 43464,
    "Hipparcos Catalogue": 43587,
    "Henry Draper Catalogue": 75732,
    "Harvard Revised Catalogue": 3522,
    "Gliese Catalog": "Gl 324A",
    "Distance in Parsecs": 12.3411,
    "mag": 5.96,
    "Absolute Visual Magnitude": 5.503,
    "Spectral Type": "G8V",
    "Color Index": 0.869,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.429298,
    "y": 7.925466,
    "z": 5.85673,
    "vx": -1.1e-7,
    "vy": 0.00004258,
    "vz": 8.6e-7,
    "rarad": 2.32389496535725,
    "decrad": 0.494466065471413,
    "pmrarad": -0.00000235367345636111,
    "pmdecrad": -0.000001136403267,
    "bayer": "Rho-1",
    "flam": 55,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 43464,
    "base": "Gl 324",
    "lum": 0.54802453217685,
    "bf": "55Rho1Cnc",
    "ra": 8.876625,
    "dec": 28.330819,
    "pmra": -485.48,
    "pmdec": -234.4,
    "rv": 27.2,
    "Asset Name": "ASTRID 000567",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Greasy",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -1.1e-7,
    "y_1": 0.00004258,
    "z_1": 8.6e-7,
    "Distance in Parsecs_1": 12.3411,
    "HYG Star ID_1": 43464,
    "Hipparcos Catalogue_1": 43587,
    "Henry Draper Catalogue_1": 75732,
    "Harvard Revised Catalogue_1": 3522,
    "Gliese Catalog_1": "Gl 324A"
  },
  {
    "Astrid #": "ASTRID 000568",
    "HYG Star ID": 50862,
    "Hipparcos Catalogue": 51007,
    "Gliese Catalog": "Gl 390",
    "Distance in Parsecs": 12.3457,
    "mag": 10.15,
    "Absolute Visual Magnitude": 9.692,
    "Spectral Type": "M0",
    "Color Index": 1.459,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.124476,
    "y": 4.884423,
    "z": -2.192331,
    "vx": 0.00000895,
    "vy": 0.00004111,
    "vz": 0.00000584,
    "rarad": 2.72786570170469,
    "decrad": -0.178524386609946,
    "pmrarad": -0.00000334094803544444,
    "pmdecrad": 5.85073149e-7,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 50862,
    "lum": 0.0115664477416564,
    "ra": 10.419679,
    "dec": -10.228694,
    "pmra": -689.12,
    "pmdec": 120.68,
    "rv": 7,
    "Asset Name": "ASTRID 000568",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Neko Dreams",
    "Hair": "Charmer",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": 0.00000895,
    "y_1": 0.00004111,
    "z_1": 0.00000584,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 50862,
    "Hipparcos Catalogue_1": 51007,
    "Gliese Catalog_1": "Gl 390"
  },
  {
    "Astrid #": "ASTRID 000569",
    "HYG Star ID": 118014,
    "Gliese Catalog": "NN 3052",
    "Distance in Parsecs": 12.3457,
    "mag": 13.8,
    "Absolute Visual Magnitude": 13.342,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.962011,
    "y": 2.34345,
    "z": 1.958278,
    "vx": -0.000009,
    "vy": 0.00004701,
    "vz": -0.00000124,
    "rarad": 0.193455115022375,
    "decrad": 0.159293097758399,
    "pmrarad": 0.00000387720044750694,
    "pmdecrad": -1.01524832e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118014,
    "lum": 0.000401051366086575,
    "ra": 0.738944,
    "dec": 9.126822,
    "pmra": 799.73,
    "pmdec": -20.94,
    "rv": 0,
    "Asset Name": "ASTRID 000569",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Headband",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.000009,
    "y_1": 0.00004701,
    "z_1": -0.00000124,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 118014,
    "Gliese Catalog_1": "NN 3052"
  },
  {
    "Astrid #": "ASTRID 000570",
    "HYG Star ID": 118273,
    "Gliese Catalog": "NN 3263",
    "Distance in Parsecs": 12.3457,
    "mag": 12.4,
    "Absolute Visual Magnitude": 11.942,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.303193,
    "y": 9.905127,
    "z": -5.116729,
    "vx": -0.00001741,
    "vy": -0.00000814,
    "vz": -0.00003382,
    "rarad": 1.07922776601877,
    "decrad": -0.4273431606793,
    "pmrarad": 9.31860375395833e-7,
    "pmdecrad": -0.000003010353586,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118273,
    "lum": 0.00145612949861605,
    "ra": 4.122346,
    "dec": -24.48496,
    "pmra": 192.21,
    "pmdec": -620.93,
    "rv": 0,
    "Asset Name": "ASTRID 000570",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Battle Ready",
    "Hair": "Braid",
    "Outfit": "River Spirit",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001741,
    "y_1": -0.00000814,
    "z_1": -0.00003382,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 118273,
    "Gliese Catalog_1": "NN 3263"
  },
  {
    "Astrid #": "ASTRID 000571",
    "HYG Star ID": 118301,
    "Gliese Catalog": "NN 3285",
    "Distance in Parsecs": 12.3457,
    "mag": 15.5,
    "Absolute Visual Magnitude": 15.042,
    "Spectral Type": "DC8",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.817127,
    "y": 11.06379,
    "z": 2.608477,
    "vx": 0.00000827,
    "vy": -4.9e-7,
    "vz": -0.00001317,
    "rarad": 1.16015408111076,
    "decrad": 0.21289079322145,
    "pmrarad": -6.30257784722222e-7,
    "pmdecrad": -0.000001091635571,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118301,
    "lum": 0.0000837915067438408,
    "ra": 4.431462,
    "dec": 12.197744,
    "pmra": -130,
    "pmdec": -225.17,
    "rv": 0,
    "Asset Name": "ASTRID 000571",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Guilty Grin",
    "Hair": "Pompadour",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000827,
    "y_1": -4.9e-7,
    "z_1": -0.00001317,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 118301,
    "Gliese Catalog_1": "NN 3285"
  },
  {
    "Astrid #": "ASTRID 000572",
    "HYG Star ID": 118354,
    "Gliese Catalog": "NN 3333",
    "Distance in Parsecs": 12.3457,
    "mag": 11.78,
    "Absolute Visual Magnitude": 11.322,
    "Spectral Type": "k",
    "Color Index": 1.66,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.650664,
    "y": 11.439422,
    "z": 3.811813,
    "vx": -0.00000449,
    "vy": 0.00000603,
    "vz": -0.00001498,
    "rarad": 1.34310028530309,
    "decrad": 0.31388517960905,
    "pmrarad": 4.64306061868055e-7,
    "pmdecrad": -0.000001275607819,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118354,
    "lum": 0.00257750786997053,
    "ra": 5.130265,
    "dec": 17.984296,
    "pmra": 95.77,
    "pmdec": -263.11,
    "rv": 0,
    "Asset Name": "ASTRID 000572",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "High Fade",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00000449,
    "y_1": 0.00000603,
    "z_1": -0.00001498,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 118354,
    "Gliese Catalog_1": "NN 3333"
  },
  {
    "Astrid #": "ASTRID 000573",
    "HYG Star ID": 118648,
    "Gliese Catalog": "NN 3567",
    "Distance in Parsecs": 12.3457,
    "mag": 12.73,
    "Absolute Visual Magnitude": 12.272,
    "Spectral Type": "M2",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.137473,
    "y": 3.33436,
    "z": 10.719362,
    "vx": 0.00001952,
    "vy": 0.00004858,
    "vz": -0.00000574,
    "rarad": 2.56611289699982,
    "decrad": 1.0516980080031,
    "pmrarad": -0.00000416198000346527,
    "pmdecrad": -9.37939938e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118648,
    "lum": 0.00107448411664455,
    "ra": 9.801829,
    "dec": 60.257857,
    "pmra": -858.47,
    "pmdec": -193.46,
    "rv": 0,
    "Asset Name": "ASTRID 000573",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Smitten",
    "Hair": "Horns",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001952,
    "y_1": 0.00004858,
    "z_1": -0.00000574,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 118648,
    "Gliese Catalog_1": "NN 3567"
  },
  {
    "Astrid #": "ASTRID 000574",
    "HYG Star ID": 119150,
    "Gliese Catalog": "NN 4004",
    "Distance in Parsecs": 12.3457,
    "mag": 12.1,
    "Absolute Visual Magnitude": 11.642,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.280605,
    "y": -2.087699,
    "z": -12.164665,
    "vx": -0.00003241,
    "vy": -0.00002412,
    "vz": 0.00000488,
    "rarad": 4.57935010007782,
    "decrad": -1.39933340686905,
    "pmrarad": -0.00000234329844359722,
    "pmdecrad": 0.000002318902619,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119150,
    "lum": 0.00191955252376137,
    "ra": 17.491829,
    "dec": -80.175898,
    "pmra": -483.34,
    "pmdec": 478.31,
    "rv": 0,
    "Asset Name": "ASTRID 000574",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Skater Helmet",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003241,
    "y_1": -0.00002412,
    "z_1": 0.00000488,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 119150,
    "Gliese Catalog_1": "NN 4004"
  },
  {
    "Astrid #": "ASTRID 000575",
    "HYG Star ID": 119264,
    "Gliese Catalog": "NN 4098",
    "Distance in Parsecs": 12.3457,
    "mag": 11.8,
    "Absolute Visual Magnitude": 11.342,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.073117,
    "y": -9.975187,
    "z": 6.59302,
    "vx": -0.00001047,
    "vy": -0.00001056,
    "vz": -0.00001109,
    "rarad": 5.01124817842061,
    "decrad": 0.5633644056934,
    "pmrarad": -0.00000106271158777777,
    "pmdecrad": -0.000001062726132,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 119264,
    "lum": 0.00253046304946139,
    "ra": 19.141558,
    "dec": 32.278403,
    "pmra": -219.2,
    "pmdec": -219.2,
    "rv": 0,
    "Asset Name": "ASTRID 000575",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Unkempt",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00001047,
    "y_1": -0.00001056,
    "z_1": -0.00001109,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 119264,
    "Gliese Catalog_1": "NN 4098"
  },
  {
    "Astrid #": "ASTRID 000576",
    "HYG Star ID": 119419,
    "Gliese Catalog": "NN 4201",
    "Distance in Parsecs": 12.3457,
    "mag": 12.66,
    "Absolute Visual Magnitude": 12.202,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.978476,
    "y": -6.743168,
    "z": 5.131566,
    "vx": 0.00000863,
    "vy": 0.00001066,
    "vz": -0.00000109,
    "rarad": 5.63901526425524,
    "decrad": 0.428664124954899,
    "pmrarad": 0.00000111080510488888,
    "pmdecrad": -9.7180902e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119419,
    "lum": 0.0011460405911366,
    "ra": 21.539452,
    "dec": 24.560645,
    "pmra": 229.12,
    "pmdec": -20.05,
    "rv": 0,
    "Asset Name": "ASTRID 000576",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Bangs",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000863,
    "y_1": 0.00001066,
    "z_1": -0.00000109,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 119419,
    "Gliese Catalog_1": "NN 4201"
  },
  {
    "Astrid #": "ASTRID 000577",
    "HYG Star ID": 119576,
    "Gliese Catalog": "NN 4350",
    "Distance in Parsecs": 12.3457,
    "mag": 13.72,
    "Absolute Visual Magnitude": 13.262,
    "Spectral Type": "M4",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.87607,
    "y": -1.000247,
    "z": -7.340237,
    "vx": 0.00000845,
    "vy": 0.00006828,
    "vz": 0.00000203,
    "rarad": 6.18218173121305,
    "decrad": -0.6367160799758,
    "pmrarad": 0.00000557162426104861,
    "pmdecrad": 2.04300484e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119576,
    "lum": 0.000431717844470922,
    "ra": 23.614195,
    "dec": -36.481144,
    "pmra": 1149.23,
    "pmdec": 42.14,
    "rv": 0,
    "Asset Name": "ASTRID 000577",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Wavy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000845,
    "y_1": 0.00006828,
    "z_1": 0.00000203,
    "Distance in Parsecs_1": 12.3457,
    "HYG Star ID_1": 119576,
    "Gliese Catalog_1": "NN 4350"
  },
  {
    "Astrid #": "ASTRID 000578",
    "HYG Star ID": 4559,
    "Hipparcos Catalogue": 4569,
    "Gliese Catalog": "Gl 46",
    "Distance in Parsecs": 12.3533,
    "mag": 11.77,
    "Absolute Visual Magnitude": 11.311,
    "Color Index": 1.558,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.568623,
    "y": 2.756147,
    "z": -5.772468,
    "vx": 0.00000999,
    "vy": 0.00008265,
    "vz": -0.00003638,
    "rarad": 0.255096808303626,
    "decrad": -0.486196921968431,
    "pmrarad": 0.00000627043470020138,
    "pmdecrad": -0.000001449253535,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 4559,
    "lum": 0.00260375429893873,
    "var_min": 11.864,
    "var_max": 11.694,
    "ra": 0.974398,
    "dec": -27.857032,
    "pmra": 1293.37,
    "pmdec": -298.93,
    "rv": 43,
    "Asset Name": "ASTRID 000578",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Audiophile",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00000999,
    "y_1": 0.00008265,
    "z_1": -0.00003638,
    "Distance in Parsecs_1": 12.3533,
    "HYG Star ID_1": 4559,
    "Hipparcos Catalogue_1": 4569,
    "Gliese Catalog_1": "Gl 46"
  },
  {
    "Astrid #": "ASTRID 000579",
    "HYG Star ID": 77716,
    "Hipparcos Catalogue": 77952,
    "Henry Draper Catalogue": 141891,
    "Harvard Revised Catalogue": 5897,
    "Gliese Catalog": "Gl 601A",
    "Distance in Parsecs": 12.3778,
    "mag": 2.83,
    "Absolute Visual Magnitude": 2.367,
    "Spectral Type": "F2III",
    "Color Index": 0.315,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.869157,
    "y": -4.734869,
    "z": -11.070637,
    "vx": 0.00000141,
    "vy": 0.00002415,
    "vz": -0.00001115,
    "rarad": 4.16760612672541,
    "decrad": -1.10707502492751,
    "pmrarad": -9.13631381006944e-7,
    "pmdecrad": -0.000001948563144,
    "bayer": "Bet",
    "con": "TrA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum Australe",
    "FULL CONSTELLATION NAME": "Triangulum Australe",
    "comp": 1,
    "comp_primary": 77716,
    "base": "Gl 601",
    "lum": 9.84464363980378,
    "bf": "Bet TrA",
    "ra": 15.919083,
    "dec": -63.430727,
    "pmra": -188.45,
    "pmdec": -401.92,
    "rv": 0.4,
    "Asset Name": "ASTRID 000579",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Shinobi",
    "Outfit": "Wizard",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum Australe",
    "x_1": 0.00000141,
    "y_1": 0.00002415,
    "z_1": -0.00001115,
    "Distance in Parsecs_1": 12.3778,
    "HYG Star ID_1": 77716,
    "Hipparcos Catalogue_1": 77952,
    "Henry Draper Catalogue_1": 141891,
    "Harvard Revised Catalogue_1": 5897,
    "Gliese Catalog_1": "Gl 601A"
  },
  {
    "Astrid #": "ASTRID 000580",
    "HYG Star ID": 84532,
    "Hipparcos Catalogue": 84790,
    "Gliese Catalog": "Gl 671",
    "Distance in Parsecs": 12.3808,
    "mag": 11.37,
    "Absolute Visual Magnitude": 10.906,
    "Spectral Type": "M4",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.609655,
    "y": -9.100608,
    "z": 8.238201,
    "vx": 0.00001367,
    "vy": -0.00002112,
    "vz": -0.00004971,
    "rarad": 4.53732206425175,
    "decrad": 0.728043543110702,
    "pmrarad": 0.00000138414305798611,
    "pmdecrad": -0.000003987301634,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 84532,
    "lum": 0.00378094186683296,
    "var_min": 11.447,
    "var_max": 11.287,
    "ra": 17.331294,
    "dec": 41.713822,
    "pmra": 285.5,
    "pmdec": -822.44,
    "rv": -18.9,
    "Asset Name": "ASTRID 000580",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Elegant",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001367,
    "y_1": -0.00002112,
    "z_1": -0.00004971,
    "Distance in Parsecs_1": 12.3808,
    "HYG Star ID_1": 84532,
    "Hipparcos Catalogue_1": 84790,
    "Gliese Catalog_1": "Gl 671"
  },
  {
    "Astrid #": "ASTRID 000581",
    "HYG Star ID": 118383,
    "Gliese Catalog": "NN 3356",
    "Distance in Parsecs": 12.3916,
    "mag": 11.81,
    "Absolute Visual Magnitude": 11.344,
    "Spectral Type": "M3.5",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.316956,
    "y": 11.957459,
    "z": 2.972633,
    "vx": 0.00000778,
    "vy": 0.00000483,
    "vz": -0.00002286,
    "rarad": 1.46110251969205,
    "decrad": 0.24225355415645,
    "pmrarad": -5.81146158881944e-7,
    "pmdecrad": -0.000001900876871,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118383,
    "lum": 0.00252580605483196,
    "ra": 5.581001,
    "dec": 13.880106,
    "pmra": -119.87,
    "pmdec": -392.08,
    "rv": 0,
    "Asset Name": "ASTRID 000581",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Fired Up",
    "Hair": "Unkempt",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000778,
    "y_1": 0.00000483,
    "z_1": -0.00002286,
    "Distance in Parsecs_1": 12.3916,
    "HYG Star ID_1": 118383,
    "Gliese Catalog_1": "NN 3356"
  },
  {
    "Astrid #": "ASTRID 000582",
    "HYG Star ID": 56827,
    "Hipparcos Catalogue": 56998,
    "Henry Draper Catalogue": 101581,
    "Gliese Catalog": "Gl 435",
    "Distance in Parsecs": 12.4008,
    "mag": 7.77,
    "Absolute Visual Magnitude": 7.303,
    "Spectral Type": "K5V",
    "Color Index": 1.064,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.829036,
    "y": 0.732039,
    "z": -8.677271,
    "vx": -0.0000166,
    "vy": 0.00004107,
    "vz": 9.1e-7,
    "rarad": 3.05888033685234,
    "decrad": -0.77501675413336,
    "pmrarad": -0.00000319007401805555,
    "pmdecrad": 0.000001177127616,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 56827,
    "lum": 0.104423921854317,
    "ra": 11.684062,
    "dec": -44.405189,
    "pmra": -658,
    "pmdec": 242.8,
    "rv": 13.3,
    "Asset Name": "ASTRID 000582",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Star of Culture",
    "Hair": "Lion Mane",
    "Outfit": "Iron Star",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.0000166,
    "y_1": 0.00004107,
    "z_1": 9.1e-7,
    "Distance in Parsecs_1": 12.4008,
    "HYG Star ID_1": 56827,
    "Hipparcos Catalogue_1": 56998,
    "Henry Draper Catalogue_1": 101581,
    "Gliese Catalog_1": "Gl 435"
  },
  {
    "Astrid #": "ASTRID 000583",
    "HYG Star ID": 26736,
    "Hipparcos Catalogue": 26801,
    "Henry Draper Catalogue": 233153,
    "Gliese Catalog": "Gl 212",
    "Distance in Parsecs": 12.4378,
    "mag": 9.78,
    "Absolute Visual Magnitude": 9.306,
    "Spectral Type": "M1",
    "Color Index": 1.473,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.596305,
    "y": 7.376012,
    "z": 9.996908,
    "vx": 0.00000193,
    "vy": 0.00002621,
    "vz": -0.00001691,
    "rarad": 1.49012783105098,
    "decrad": 0.933573138211615,
    "pmrarad": 1.53201123055555e-8,
    "pmdecrad": -0.000002507747244,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 26736,
    "lum": 0.0165044098565227,
    "ra": 5.691869,
    "dec": 53.489801,
    "pmra": 3.16,
    "pmdec": -517.26,
    "rv": 2,
    "Asset Name": "ASTRID 000583",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Headband",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000193,
    "y_1": 0.00002621,
    "z_1": -0.00001691,
    "Distance in Parsecs_1": 12.4378,
    "HYG Star ID_1": 26736,
    "Hipparcos Catalogue_1": 26801,
    "Henry Draper Catalogue_1": 233153,
    "Gliese Catalog_1": "Gl 212"
  },
  {
    "Astrid #": "ASTRID 000584",
    "HYG Star ID": 83793,
    "Hipparcos Catalogue": 84051,
    "Distance in Parsecs": 12.4735,
    "mag": 10.05,
    "Absolute Visual Magnitude": 9.57,
    "Color Index": 1.457,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.611037,
    "y": -7.417776,
    "z": -9.897948,
    "vx": -0.00001644,
    "vy": -0.00000419,
    "vz": 0.00000582,
    "rarad": 4.4985312404006,
    "decrad": -0.916568515360328,
    "pmrarad": -0.00000121663993135416,
    "pmdecrad": 7.66587391e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 83793,
    "lum": 0.0129419584144998,
    "var_min": 10.1,
    "var_max": 9.99,
    "ra": 17.183124,
    "dec": -52.515508,
    "pmra": -250.95,
    "pmdec": 158.12,
    "rv": 0,
    "Asset Name": "ASTRID 000584",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Hachimaki",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -0.00001644,
    "y_1": -0.00000419,
    "z_1": 0.00000582,
    "Distance in Parsecs_1": 12.4735,
    "HYG Star ID_1": 83793,
    "Hipparcos Catalogue_1": 84051
  },
  {
    "Astrid #": "ASTRID 000585",
    "HYG Star ID": 118499,
    "Gliese Catalog": "GJ 1101",
    "Distance in Parsecs": 12.4844,
    "mag": 13.09,
    "Absolute Visual Magnitude": 12.608,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.697523,
    "y": 1.256312,
    "z": 12.401426,
    "vx": -0.0000025,
    "vy": 0.00003844,
    "vz": -0.00000403,
    "rarad": 2.07814194213602,
    "decrad": 1.4554400838653,
    "pmrarad": -0.00000132111727951388,
    "pmdecrad": -0.000002807502694,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118499,
    "lum": 0.000788496917745376,
    "ra": 7.937918,
    "dec": 83.390574,
    "pmra": -272.5,
    "pmdec": -579.09,
    "rv": 0,
    "Asset Name": "ASTRID 000585",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Middle Part",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000025,
    "y_1": 0.00003844,
    "z_1": -0.00000403,
    "Distance in Parsecs_1": 12.4844,
    "HYG Star ID_1": 118499,
    "Gliese Catalog_1": "GJ 1101"
  },
  {
    "Astrid #": "ASTRID 000586",
    "HYG Star ID": 119463,
    "Gliese Catalog": "Gl 843",
    "Distance in Parsecs": 12.4844,
    "mag": 12.03,
    "Absolute Visual Magnitude": 11.548,
    "Spectral Type": "M3",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.244071,
    "y": -5.794892,
    "z": -4.163952,
    "vx": 0.00002871,
    "vy": 0.0000475,
    "vz": 0.00000448,
    "rarad": 5.76836807280607,
    "decrad": -0.3400480139433,
    "pmrarad": 0.00000444399612144444,
    "pmdecrad": 3.80981134e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119463,
    "lum": 0.00209314830344744,
    "ra": 22.033543,
    "dec": -19.483316,
    "pmra": 916.64,
    "pmdec": 78.58,
    "rv": 0,
    "Asset Name": "ASTRID 000586",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Pompadour",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002871,
    "y_1": 0.0000475,
    "z_1": 0.00000448,
    "Distance in Parsecs_1": 12.4844,
    "HYG Star ID_1": 119463,
    "Gliese Catalog_1": "Gl 843"
  },
  {
    "Astrid #": "ASTRID 000587",
    "HYG Star ID": 16095,
    "Hipparcos Catalogue": 16134,
    "Henry Draper Catalogue": 21531,
    "Gliese Catalog": "Gl 142",
    "Distance in Parsecs": 12.4938,
    "mag": 8.39,
    "Absolute Visual Magnitude": 7.907,
    "Spectral Type": "K5V",
    "Color Index": 1.337,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.242316,
    "y": 9.259198,
    "z": -4.233158,
    "vx": 6.8e-7,
    "vy": 0.00005368,
    "vz": 0.00000418,
    "rarad": 0.907017536394418,
    "decrad": -0.345653425470853,
    "pmrarad": 0.00000260427364784027,
    "pmdecrad": 0.000001473203331,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 16095,
    "lum": 0.0598687237248059,
    "ra": 3.464552,
    "dec": -19.804482,
    "pmra": 537.17,
    "pmdec": 303.87,
    "rv": 37.9,
    "Asset Name": "ASTRID 000587",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Determined Neko",
    "Hair": "Bangs",
    "Outfit": "Viking",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 6.8e-7,
    "y_1": 0.00005368,
    "z_1": 0.00000418,
    "Distance in Parsecs_1": 12.4938,
    "HYG Star ID_1": 16095,
    "Hipparcos Catalogue_1": 16134,
    "Henry Draper Catalogue_1": 21531,
    "Gliese Catalog_1": "Gl 142"
  },
  {
    "Astrid #": "ASTRID 000588",
    "HYG Star ID": 40578,
    "Hipparcos Catalogue": 40693,
    "Henry Draper Catalogue": 69830,
    "Harvard Revised Catalogue": 3259,
    "Gliese Catalog": "Gl 302",
    "Distance in Parsecs": 12.4938,
    "mag": 5.95,
    "Absolute Visual Magnitude": 5.467,
    "Spectral Type": "K0V",
    "Color Index": 0.754,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.92291,
    "y": 10.035411,
    "z": -2.732344,
    "vx": -0.00002377,
    "vy": 0.00000467,
    "vz": -0.00006527,
    "rarad": 2.17467611424033,
    "decrad": -0.22047301783651,
    "pmrarad": 0.00000135408460979166,
    "pmdecrad": -0.000004794952744,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 40578,
    "lum": 0.566500111919533,
    "ra": 8.306651,
    "dec": -12.632173,
    "pmra": 279.3,
    "pmdec": -989.03,
    "rv": 30.5,
    "Asset Name": "ASTRID 000588",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined Neko",
    "Hair": "Braided Ponytail",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002377,
    "y_1": 0.00000467,
    "z_1": -0.00006527,
    "Distance in Parsecs_1": 12.4938,
    "HYG Star ID_1": 40578,
    "Hipparcos Catalogue_1": 40693,
    "Henry Draper Catalogue_1": 69830,
    "Harvard Revised Catalogue_1": 3259,
    "Gliese Catalog_1": "Gl 302"
  },
  {
    "Astrid #": "ASTRID 000589",
    "HYG Star ID": 118278,
    "Gliese Catalog": "GJ 1068",
    "Distance in Parsecs": 12.5,
    "mag": 13.58,
    "Absolute Visual Magnitude": 13.095,
    "Spectral Type": "pec",
    "Color Index": 1.93,
    "Spectral Abbreviation": "p",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": 3.412879,
    "y": 6.586117,
    "z": -10.062796,
    "vx": -0.00000403,
    "vy": -0.00011096,
    "vz": -0.00010841,
    "rarad": 1.09283047068474,
    "decrad": -0.9355655448621,
    "pmrarad": -0.00000379560630506944,
    "pmdecrad": -0.000011612737242,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118278,
    "lum": 0.000503500608787904,
    "ra": 4.174305,
    "dec": -53.603957,
    "pmra": -782.9,
    "pmdec": -2395.3,
    "rv": 27,
    "Asset Name": "ASTRID 000589",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Fire",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000403,
    "y_1": -0.00011096,
    "z_1": -0.00010841,
    "Distance in Parsecs_1": 12.5,
    "HYG Star ID_1": 118278,
    "Gliese Catalog_1": "GJ 1068"
  },
  {
    "Astrid #": "ASTRID 000590",
    "HYG Star ID": 118509,
    "Gliese Catalog": "NN 3466A",
    "Distance in Parsecs": 12.5,
    "mag": 13.38,
    "Absolute Visual Magnitude": 12.895,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.236433,
    "y": 9.554939,
    "z": -6.126411,
    "vx": -0.00000852,
    "vy": -0.00002023,
    "vz": -0.00002427,
    "rarad": 2.07211892594092,
    "decrad": -0.51221920567145,
    "pmrarad": 0.00000137565881857638,
    "pmdecrad": -0.000002227413429,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118509,
    "base": "NN 3466",
    "lum": 0.000605340874753913,
    "ra": 7.914911,
    "dec": -29.347999,
    "pmra": 283.75,
    "pmdec": -459.44,
    "rv": 0,
    "Asset Name": "ASTRID 000590",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Curly",
    "Outfit": "River Spirit",
    "Special": "Faerie",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000852,
    "y_1": -0.00002023,
    "z_1": -0.00002427,
    "Distance in Parsecs_1": 12.5,
    "HYG Star ID_1": 118509,
    "Gliese Catalog_1": "NN 3466A"
  },
  {
    "Astrid #": "ASTRID 000591",
    "HYG Star ID": 118610,
    "Gliese Catalog": "NN 3543",
    "Distance in Parsecs": 12.5,
    "mag": 10.75,
    "Absolute Visual Magnitude": 10.265,
    "Spectral Type": "M2",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.952827,
    "y": 7.756458,
    "z": -3.991777,
    "vx": 0.00000947,
    "vy": 0.00001537,
    "vz": 0.0000086,
    "rarad": 2.42767554411675,
    "decrad": -0.32503516957685,
    "pmrarad": -0.00000142549766493749,
    "pmdecrad": 7.26328463e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 118610,
    "lum": 0.00682338694141669,
    "ra": 9.273038,
    "dec": -18.623143,
    "pmra": -294.03,
    "pmdec": 149.82,
    "rv": 0,
    "Asset Name": "ASTRID 000591",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Blep",
    "Hair": "Fire",
    "Outfit": "Super",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000947,
    "y_1": 0.00001537,
    "z_1": 0.0000086,
    "Distance in Parsecs_1": 12.5,
    "HYG Star ID_1": 118610,
    "Gliese Catalog_1": "NN 3543"
  },
  {
    "Astrid #": "ASTRID 000592",
    "HYG Star ID": 73467,
    "Hipparcos Catalogue": 73695,
    "Henry Draper Catalogue": 133640,
    "Harvard Revised Catalogue": 5618,
    "Gliese Catalog": "Gl 575A",
    "Distance in Parsecs": 12.5078,
    "mag": 4.83,
    "Absolute Visual Magnitude": 4.344,
    "Spectral Type": "G2V + G2V",
    "Color Index": 0.647,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.858189,
    "y": -6.055134,
    "z": 9.244209,
    "vx": -0.00000419,
    "vy": 0.00003371,
    "vz": -0.00002168,
    "rarad": 3.94352979317699,
    "decrad": 0.831720249457471,
    "pmrarad": -0.00000211495120005555,
    "pmdecrad": 9.1823711e-8,
    "flam": 44,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 73467,
    "base": "Gl 575",
    "lum": 1.59367588102876,
    "var": "I",
    "var_min": 4.943,
    "var_max": 4.793,
    "bf": "44    Boo",
    "ra": 15.063174,
    "dec": 47.65406,
    "pmra": -436.24,
    "pmdec": 18.94,
    "rv": -29.7,
    "Asset Name": "ASTRID 000592",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Oh Snap",
    "Hair": "Honest Lad",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000419,
    "y_1": 0.00003371,
    "z_1": -0.00002168,
    "Distance in Parsecs_1": 12.5078,
    "HYG Star ID_1": 73467,
    "Hipparcos Catalogue_1": 73695,
    "Henry Draper Catalogue_1": 133640,
    "Harvard Revised Catalogue_1": 5618,
    "Gliese Catalog_1": "Gl 575A"
  },
  {
    "Astrid #": "ASTRID 000593",
    "HYG Star ID": 117981,
    "Gliese Catalog": "NN 3028",
    "Distance in Parsecs": 12.5156,
    "mag": 16.05,
    "Absolute Visual Magnitude": 15.563,
    "Spectral Type": "M6",
    "Color Index": 1.98,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.442926,
    "y": 0.935977,
    "z": 6.831626,
    "vx": -0.00000292,
    "vy": 0.00006394,
    "vz": -0.00006044,
    "rarad": 0.0893395134171055,
    "decrad": 0.5774816990188,
    "pmrarad": 0.00000510954834211111,
    "pmdecrad": -0.000004167259624,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117981,
    "lum": 0.0000518561177685839,
    "ra": 0.341252,
    "dec": 33.087264,
    "pmra": 1053.92,
    "pmdec": -859.56,
    "rv": -30,
    "Asset Name": "ASTRID 000593",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Spiky",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000292,
    "y_1": 0.00006394,
    "z_1": -0.00006044,
    "Distance in Parsecs_1": 12.5156,
    "HYG Star ID_1": 117981,
    "Gliese Catalog_1": "NN 3028"
  },
  {
    "Astrid #": "ASTRID 000594",
    "HYG Star ID": 118537,
    "Gliese Catalog": "NN 3497",
    "Distance in Parsecs": 12.5156,
    "mag": 15.67,
    "Absolute Visual Magnitude": 15.183,
    "Spectral Type": "dM5.2",
    "Color Index": 2.09,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.661072,
    "y": 3.600649,
    "z": 11.687356,
    "vx": -0.00001304,
    "vy": 0.00007758,
    "vz": -0.00002684,
    "rarad": 2.20751039004437,
    "decrad": 1.2049546388091,
    "pmrarad": -0.00000284895911241666,
    "pmdecrad": -0.000005999903818,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118537,
    "lum": 0.0000735868139651044,
    "ra": 8.432069,
    "dec": 69.038815,
    "pmra": -587.64,
    "pmdec": -1237.57,
    "rv": 0,
    "Asset Name": "ASTRID 000594",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Ponytail",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001304,
    "y_1": 0.00007758,
    "z_1": -0.00002684,
    "Distance in Parsecs_1": 12.5156,
    "HYG Star ID_1": 118537,
    "Gliese Catalog_1": "NN 3497"
  },
  {
    "Astrid #": "ASTRID 000595",
    "HYG Star ID": 118569,
    "Gliese Catalog": "Gl 324B",
    "Distance in Parsecs": 12.5313,
    "mag": 13.14,
    "Absolute Visual Magnitude": 12.65,
    "Spectral Type": "M3.5",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.548212,
    "y": 8.046658,
    "z": 5.94488,
    "vx": 8.9e-7,
    "vy": 0.00004123,
    "vz": -3.3e-7,
    "rarad": 2.32425875581391,
    "decrad": 0.49422965232895,
    "pmrarad": -0.00000230354372179166,
    "pmdecrad": -0.000001138473421,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 43464,
    "base": "Gl 324",
    "lum": 0.000758577575029182,
    "ra": 8.878014,
    "dec": 28.317273,
    "pmra": -475.14,
    "pmdec": -234.83,
    "rv": 25.2,
    "Asset Name": "ASTRID 000595",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "High Bun",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 8.9e-7,
    "y_1": 0.00004123,
    "z_1": -3.3e-7,
    "Distance in Parsecs_1": 12.5313,
    "HYG Star ID_1": 118569,
    "Gliese Catalog_1": "Gl 324B"
  },
  {
    "Astrid #": "ASTRID 000596",
    "HYG Star ID": 111969,
    "Hipparcos Catalogue": 112325,
    "Henry Draper Catalogue": 215415,
    "Distance in Parsecs": 12.5345,
    "mag": 9.44,
    "Absolute Visual Magnitude": 8.949,
    "Spectral Type": "F8",
    "Color Index": 0.66,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.857838,
    "y": -4.020926,
    "z": -0.580981,
    "vx": -4e-8,
    "vy": 6e-7,
    "vz": -0.00000503,
    "rarad": 5.95625882472974,
    "decrad": -0.0463671638993199,
    "pmrarad": 4.43119704027777e-8,
    "pmdecrad": -4.01474208e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 111969,
    "lum": 0.0229297859182482,
    "var_min": 9.598,
    "var_max": 9.138,
    "ra": 22.751233,
    "dec": -2.656643,
    "pmra": 9.14,
    "pmdec": -82.81,
    "rv": 0,
    "Asset Name": "ASTRID 000596",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Charmer",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -4e-8,
    "y_1": 6e-7,
    "z_1": -0.00000503,
    "Distance in Parsecs_1": 12.5345,
    "HYG Star ID_1": 111969,
    "Hipparcos Catalogue_1": 112325,
    "Henry Draper Catalogue_1": 215415
  },
  {
    "Astrid #": "ASTRID 000597",
    "HYG Star ID": 118261,
    "Gliese Catalog": "NN 3250",
    "Distance in Parsecs": 12.5471,
    "mag": 14.51,
    "Absolute Visual Magnitude": 14.017,
    "Spectral Type": "k-m",
    "Color Index": 1.87,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.784871,
    "y": 10.382436,
    "z": 1.89744,
    "vx": -0.00002016,
    "vy": 0.00002012,
    "vz": -0.00003794,
    "rarad": 0.991966763264062,
    "decrad": 0.1518077972844,
    "pmrarad": 0.00000222272528124305,
    "pmdecrad": -0.000003059334312,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118261,
    "lum": 0.000215377335566217,
    "ra": 3.789034,
    "dec": 8.697946,
    "pmra": 458.47,
    "pmdec": -631.03,
    "rv": 0,
    "Asset Name": "ASTRID 000597",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Pixie",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002016,
    "y_1": 0.00002012,
    "z_1": -0.00003794,
    "Distance in Parsecs_1": 12.5471,
    "HYG Star ID_1": 118261,
    "Gliese Catalog_1": "NN 3250"
  },
  {
    "Astrid #": "ASTRID 000598",
    "HYG Star ID": 10371,
    "Hipparcos Catalogue": 10395,
    "Gliese Catalog": "Gl 91",
    "Distance in Parsecs": 12.5502,
    "mag": 10.31,
    "Absolute Visual Magnitude": 9.817,
    "Color Index": 1.479,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.874205,
    "y": 5.867794,
    "z": -6.658469,
    "vx": -0.00000696,
    "vy": 0.00005076,
    "vz": -0.00005226,
    "rarad": 0.584216005203315,
    "decrad": -0.559225283341418,
    "pmrarad": 0.00000368036609320138,
    "pmdecrad": -0.000002592153305,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 10371,
    "lum": 0.0103086074006262,
    "ra": 2.231541,
    "dec": -32.041249,
    "pmra": 759.13,
    "pmdec": -534.67,
    "rv": 45.5,
    "Asset Name": "ASTRID 000598",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Star of Culture",
    "Hair": "Charmer",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000696,
    "y_1": 0.00005076,
    "z_1": -0.00005226,
    "Distance in Parsecs_1": 12.5502,
    "HYG Star ID_1": 10371,
    "Hipparcos Catalogue_1": 10395,
    "Gliese Catalog_1": "Gl 91"
  },
  {
    "Astrid #": "ASTRID 000599",
    "HYG Star ID": 118042,
    "Gliese Catalog": "GJ 1029",
    "Distance in Parsecs": 12.5628,
    "mag": 14.8,
    "Absolute Visual Magnitude": 14.305,
    "Spectral Type": "m",
    "Color Index": 1.89,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.591635,
    "y": 3.118568,
    "z": 5.992978,
    "vx": -0.00002788,
    "vy": 0.00011195,
    "vz": -0.00000886,
    "rarad": 0.286282087395072,
    "decrad": 0.497285519639299,
    "pmrarad": 0.00000917640790145139,
    "pmdecrad": -8.02827214e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118042,
    "lum": 0.000165196179822901,
    "ra": 1.093517,
    "dec": 28.492361,
    "pmra": 1892.77,
    "pmdec": -165.59,
    "rv": 0,
    "Asset Name": "ASTRID 000599",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Heartbreaker",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002788,
    "y_1": 0.00011195,
    "z_1": -0.00000886,
    "Distance in Parsecs_1": 12.5628,
    "HYG Star ID_1": 118042,
    "Gliese Catalog_1": "GJ 1029"
  },
  {
    "Astrid #": "ASTRID 000600",
    "HYG Star ID": 119430,
    "Gliese Catalog": "Gl 836.5",
    "Distance in Parsecs": 12.5628,
    "mag": 13.24,
    "Absolute Visual Magnitude": 12.745,
    "Spectral Type": "DQ6",
    "Color Index": 0.17,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.685246,
    "y": -6.614751,
    "z": 4.501671,
    "vx": 0.00000348,
    "vy": -0.00001928,
    "vz": -0.00003579,
    "rarad": 5.68398317447255,
    "decrad": 0.36648206410785,
    "pmrarad": -0.00000111095054899305,
    "pmdecrad": -0.000003052357843,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119430,
    "lum": 0.000695024317588796,
    "ra": 21.711216,
    "dec": 20.997876,
    "pmra": -229.15,
    "pmdec": -629.59,
    "rv": 0,
    "Asset Name": "ASTRID 000600",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Headband",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000348,
    "y_1": -0.00001928,
    "z_1": -0.00003579,
    "Distance in Parsecs_1": 12.5628,
    "HYG Star ID_1": 119430,
    "Gliese Catalog_1": "Gl 836.5"
  },
  {
    "Astrid #": "ASTRID 000601",
    "HYG Star ID": 118004,
    "Gliese Catalog": "Gl 26",
    "Distance in Parsecs": 12.5786,
    "mag": 11.06,
    "Absolute Visual Magnitude": 10.562,
    "Spectral Type": "dM4",
    "Color Index": 1.53,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.668796,
    "y": 1.83231,
    "z": 6.406276,
    "vx": -0.00001764,
    "vy": 0.00009345,
    "vz": 0.00000232,
    "rarad": 0.170024281410113,
    "decrad": 0.53437122334615,
    "pmrarad": 0.00000755974820227083,
    "pmdecrad": 2.24366923e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 118004,
    "lum": 0.00519039010197082,
    "ra": 0.649445,
    "dec": 30.617216,
    "pmra": 1559.31,
    "pmdec": 46.28,
    "rv": -0.2,
    "Asset Name": "ASTRID 000601",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Ram Horns",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001764,
    "y_1": 0.00009345,
    "z_1": 0.00000232,
    "Distance in Parsecs_1": 12.5786,
    "HYG Star ID_1": 118004,
    "Gliese Catalog_1": "Gl 26"
  },
  {
    "Astrid #": "ASTRID 000602",
    "HYG Star ID": 59224,
    "Hipparcos Catalogue": 59406,
    "Gliese Catalog": "NN 3708A",
    "Distance in Parsecs": 12.5897,
    "mag": 11.67,
    "Absolute Visual Magnitude": 11.17,
    "Color Index": 1.558,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.819291,
    "y": -0.577852,
    "z": -4.297789,
    "vx": 0.00000318,
    "vy": 0.00001328,
    "vz": -0.00001052,
    "rarad": 3.1904448693816,
    "decrad": -0.348377722744381,
    "pmrarad": -0.00000104167067404166,
    "pmdecrad": -8.89342215e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 59224,
    "base": "NN 3708",
    "lum": 0.00296483138952433,
    "var_min": 11.755,
    "var_max": 11.595,
    "ra": 12.186602,
    "dec": -19.960573,
    "pmra": -214.86,
    "pmdec": -183.44,
    "rv": 0,
    "Asset Name": "ASTRID 000602",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Ram Horns",
    "Outfit": "Basketball",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000318,
    "y_1": 0.00001328,
    "z_1": -0.00001052,
    "Distance in Parsecs_1": 12.5897,
    "HYG Star ID_1": 59224,
    "Hipparcos Catalogue_1": 59406,
    "Gliese Catalog_1": "NN 3708A"
  },
  {
    "Astrid #": "ASTRID 000603",
    "HYG Star ID": 24753,
    "Hipparcos Catalogue": 24813,
    "Henry Draper Catalogue": 34411,
    "Harvard Revised Catalogue": 1729,
    "Gliese Catalog": "Gl 197",
    "Distance in Parsecs": 12.631,
    "mag": 4.69,
    "Absolute Visual Magnitude": 4.183,
    "Spectral Type": "G0V",
    "Color Index": 0.63,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.713487,
    "y": 9.509166,
    "z": 8.136149,
    "vx": -0.00001742,
    "vy": 0.00008264,
    "vz": 0.00001267,
    "rarad": 1.39250958514553,
    "decrad": 0.699860474812422,
    "pmrarad": 0.0000025180737754375,
    "pmdecrad": -0.000003222992866,
    "bayer": "Lam",
    "flam": 15,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 24753,
    "lum": 1.84841719536965,
    "bf": "15Lam Aur",
    "ra": 5.318995,
    "dec": 40.099051,
    "pmra": 519.39,
    "pmdec": -664.79,
    "rv": 66.5,
    "Asset Name": "ASTRID 000603",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Round Brush",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00001742,
    "y_1": 0.00008264,
    "z_1": 0.00001267,
    "Distance in Parsecs_1": 12.631,
    "HYG Star ID_1": 24753,
    "Hipparcos Catalogue_1": 24813,
    "Henry Draper Catalogue_1": 34411,
    "Harvard Revised Catalogue_1": 1729,
    "Gliese Catalog_1": "Gl 197"
  },
  {
    "Astrid #": "ASTRID 000604",
    "HYG Star ID": 119250,
    "Gliese Catalog": "Gl 737B",
    "Distance in Parsecs": 12.6358,
    "mag": 10,
    "Absolute Visual Magnitude": 9.492,
    "Spectral Type": "K5 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.754893,
    "y": -6.84529,
    "z": -10.474033,
    "vx": -0.00001001,
    "vy": 0.00002813,
    "vz": -5.7e-7,
    "rarad": 4.96335775222511,
    "decrad": -0.9772641332705,
    "pmrarad": -2.1467549775e-7,
    "pmdecrad": -0.00000197610541,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 2,
    "comp_primary": 92776,
    "base": "Gl 737",
    "lum": 0.0139059287546544,
    "ra": 18.95863,
    "dec": -55.99311,
    "pmra": -44.28,
    "pmdec": -407.6,
    "rv": -15.8,
    "Asset Name": "ASTRID 000604",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Ram Horns",
    "Outfit": "Basketball",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.00001001,
    "y_1": 0.00002813,
    "z_1": -5.7e-7,
    "Distance in Parsecs_1": 12.6358,
    "HYG Star ID_1": 119250,
    "Gliese Catalog_1": "Gl 737B"
  },
  {
    "Astrid #": "ASTRID 000605",
    "HYG Star ID": 118086,
    "Gliese Catalog": "NN 3112",
    "Distance in Parsecs": 12.6582,
    "mag": 13.88,
    "Absolute Visual Magnitude": 13.368,
    "Spectral Type": "DA7",
    "Color Index": 0.44,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.401269,
    "y": 2.121971,
    "z": -11.679988,
    "vx": -0.00002387,
    "vy": -0.00003317,
    "vz": -0.00007158,
    "rarad": 0.449369946017338,
    "decrad": -1.1745883226469,
    "pmrarad": -0.00000154131965322222,
    "pmdecrad": -0.000004800692938,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118086,
    "lum": 0.000391561514839611,
    "ra": 1.716467,
    "dec": -67.298954,
    "pmra": -317.92,
    "pmdec": -990.21,
    "rv": 51,
    "Asset Name": "ASTRID 000605",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Fire",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002387,
    "y_1": -0.00003317,
    "z_1": -0.00007158,
    "Distance in Parsecs_1": 12.6582,
    "HYG Star ID_1": 118086,
    "Gliese Catalog_1": "NN 3112"
  },
  {
    "Astrid #": "ASTRID 000606",
    "HYG Star ID": 118309,
    "Gliese Catalog": "NN 3292",
    "Distance in Parsecs": 12.6582,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.988,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.73009,
    "y": 11.321245,
    "z": 3.112185,
    "vx": -0.00001427,
    "vy": 0.00000318,
    "vz": 0.00001014,
    "rarad": 1.17503259306879,
    "decrad": 0.24841007894275,
    "pmrarad": 0.00000113742137595138,
    "pmdecrad": 8.26398855e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118309,
    "lum": 0.000555648312837125,
    "ra": 4.488294,
    "dec": 14.232849,
    "pmra": 234.61,
    "pmdec": 170.46,
    "rv": 0,
    "Asset Name": "ASTRID 000606",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Afro",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001427,
    "y_1": 0.00000318,
    "z_1": 0.00001014,
    "Distance in Parsecs_1": 12.6582,
    "HYG Star ID_1": 118309,
    "Gliese Catalog_1": "NN 3292"
  },
  {
    "Astrid #": "ASTRID 000607",
    "HYG Star ID": 118385,
    "Gliese Catalog": "NN 3357",
    "Distance in Parsecs": 12.6582,
    "mag": 12.8,
    "Absolute Visual Magnitude": 12.288,
    "Spectral Type": "M4",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.315746,
    "y": 12.476504,
    "z": -1.683954,
    "vx": -0.00000856,
    "vy": 0.00000466,
    "vz": 0.00002779,
    "rarad": 1.46572648535567,
    "decrad": -0.13342814374105,
    "pmrarad": 7.11367113479166e-7,
    "pmdecrad": 0.000002215705179,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118385,
    "lum": 0.00105876603311369,
    "ra": 5.598663,
    "dec": -7.64487,
    "pmra": 146.73,
    "pmdec": 457.02,
    "rv": 0,
    "Asset Name": "ASTRID 000607",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Braided Bun",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000856,
    "y_1": 0.00000466,
    "z_1": 0.00002779,
    "Distance in Parsecs_1": 12.6582,
    "HYG Star ID_1": 118385,
    "Gliese Catalog_1": "NN 3357"
  },
  {
    "Astrid #": "ASTRID 000608",
    "HYG Star ID": 118458,
    "Gliese Catalog": "Gl 261",
    "Distance in Parsecs": 12.6582,
    "mag": 15.27,
    "Absolute Visual Magnitude": 14.758,
    "Spectral Type": "DA",
    "Color Index": 0.88,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.355322,
    "y": 12.122235,
    "z": -1.422423,
    "vx": 0.00000622,
    "vy": -0.00000461,
    "vz": -0.00005406,
    "rarad": 1.84082670850139,
    "decrad": -0.112609527055849,
    "pmrarad": -3.76069972006944e-7,
    "pmdecrad": -0.000004298421117,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118458,
    "lum": 0.000108842873794359,
    "ra": 7.03144,
    "dec": -6.452051,
    "pmra": -77.57,
    "pmdec": -886.61,
    "rv": 0,
    "Asset Name": "ASTRID 000608",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Bangs",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000622,
    "y_1": -0.00000461,
    "z_1": -0.00005406,
    "Distance in Parsecs_1": 12.6582,
    "HYG Star ID_1": 118458,
    "Gliese Catalog_1": "Gl 261"
  },
  {
    "Astrid #": "ASTRID 000609",
    "HYG Star ID": 118885,
    "Gliese Catalog": "NN 3780",
    "Distance in Parsecs": 12.6582,
    "mag": 12.9,
    "Absolute Visual Magnitude": 12.388,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.636021,
    "y": -4.062776,
    "z": -5.531631,
    "vx": -0.00000808,
    "vy": 0.00003776,
    "vz": -0.00001222,
    "rarad": 3.50648788812298,
    "decrad": -0.4522604023896,
    "pmrarad": -0.00000301437754022222,
    "pmdecrad": -0.000001073367792,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118885,
    "lum": 0.000965606098541549,
    "ra": 13.393797,
    "dec": -25.912612,
    "pmra": -621.76,
    "pmdec": -221.4,
    "rv": 0,
    "Asset Name": "ASTRID 000609",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Nervous Grin",
    "Hair": "Round Brush",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000808,
    "y_1": 0.00003776,
    "z_1": -0.00001222,
    "Distance in Parsecs_1": 12.6582,
    "HYG Star ID_1": 118885,
    "Gliese Catalog_1": "NN 3780"
  },
  {
    "Astrid #": "ASTRID 000610",
    "HYG Star ID": 119223,
    "Gliese Catalog": "NN 4062",
    "Distance in Parsecs": 12.6582,
    "mag": 11.99,
    "Absolute Visual Magnitude": 11.478,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.334826,
    "y": -9.505655,
    "z": 8.251714,
    "vx": -0.00000741,
    "vy": 0.00001516,
    "vz": 0.00001866,
    "rarad": 4.85190776624093,
    "decrad": 0.7100699858392,
    "pmrarad": -4.13255181305555e-7,
    "pmdecrad": 0.000001944296784,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 119223,
    "lum": 0.00223254386161674,
    "ra": 18.532923,
    "dec": 40.684013,
    "pmra": -85.24,
    "pmdec": 401.04,
    "rv": 0,
    "Asset Name": "ASTRID 000610",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Ponytail",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000741,
    "y_1": 0.00001516,
    "z_1": 0.00001866,
    "Distance in Parsecs_1": 12.6582,
    "HYG Star ID_1": 119223,
    "Gliese Catalog_1": "NN 4062"
  },
  {
    "Astrid #": "ASTRID 000611",
    "HYG Star ID": 119359,
    "Gliese Catalog": "NN 4154",
    "Distance in Parsecs": 12.6582,
    "mag": 11.5,
    "Absolute Visual Magnitude": 10.988,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.212276,
    "y": -1.359366,
    "z": -12.526474,
    "vx": 0.00000159,
    "vy": 0.00004513,
    "vz": -0.00000475,
    "rarad": 5.43991977733387,
    "decrad": -1.42640447867375,
    "pmrarad": 0.00000246498667741666,
    "pmdecrad": -0.000002606673475,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 119359,
    "lum": 0.00350590383932612,
    "ra": 20.778963,
    "dec": -81.726957,
    "pmra": 508.44,
    "pmdec": -537.66,
    "rv": 0,
    "Asset Name": "ASTRID 000611",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Heartbreaker",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": 0.00000159,
    "y_1": 0.00004513,
    "z_1": -0.00000475,
    "Distance in Parsecs_1": 12.6582,
    "HYG Star ID_1": 119359,
    "Gliese Catalog_1": "NN 4154"
  },
  {
    "Astrid #": "ASTRID 000612",
    "HYG Star ID": 10772,
    "Hipparcos Catalogue": 10798,
    "Henry Draper Catalogue": 14412,
    "Harvard Revised Catalogue": 683,
    "Gliese Catalog": "Gl 95",
    "Distance in Parsecs": 12.6695,
    "mag": 6.33,
    "Absolute Visual Magnitude": 5.816,
    "Spectral Type": "G8V",
    "Color Index": 0.724,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.361391,
    "y": 6.492719,
    "z": -5.543174,
    "vx": 0.00002274,
    "vy": -5.3e-7,
    "vz": 0.00002142,
    "rarad": 0.606394196209559,
    "decrad": -0.452837669353703,
    "pmrarad": -0.00000105723319318749,
    "pmdecrad": 0.000002155045291,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 10772,
    "lum": 0.410771212553387,
    "ra": 2.316255,
    "dec": -25.945687,
    "pmra": -218.07,
    "pmdec": 444.51,
    "rv": 7,
    "Asset Name": "ASTRID 000612",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Audiohead",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00002274,
    "y_1": -5.3e-7,
    "z_1": 0.00002142,
    "Distance in Parsecs_1": 12.6695,
    "HYG Star ID_1": 10772,
    "Hipparcos Catalogue_1": 10798,
    "Henry Draper Catalogue_1": 14412,
    "Harvard Revised Catalogue_1": 683,
    "Gliese Catalog_1": "Gl 95"
  },
  {
    "Astrid #": "ASTRID 000613",
    "HYG Star ID": 54878,
    "Hipparcos Catalogue": 55042,
    "Henry Draper Catalogue": 304043,
    "Distance in Parsecs": 12.6727,
    "mag": 11.66,
    "Absolute Visual Magnitude": 11.146,
    "Spectral Type": "M",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.675224,
    "y": 1.297428,
    "z": -10.693708,
    "vx": -0.00003114,
    "vy": 0.00016031,
    "vz": 0.00003886,
    "rarad": 2.94971185047663,
    "decrad": -1.00439629686341,
    "pmrarad": -0.0000119508026697986,
    "pmdecrad": 0.000005716244181,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 54878,
    "lum": 0.00303109815358404,
    "var_min": 11.783,
    "var_max": 11.573,
    "ra": 11.267069,
    "dec": -57.547669,
    "pmra": -2465.03,
    "pmdec": 1179.06,
    "rv": 0,
    "Asset Name": "ASTRID 000613",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Middle Part",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00003114,
    "y_1": 0.00016031,
    "z_1": 0.00003886,
    "Distance in Parsecs_1": 12.6727,
    "HYG Star ID_1": 54878,
    "Hipparcos Catalogue_1": 55042,
    "Henry Draper Catalogue_1": 304043
  },
  {
    "Astrid #": "ASTRID 000614",
    "HYG Star ID": 119324,
    "Gliese Catalog": "GJ 1248",
    "Distance in Parsecs": 12.6743,
    "mag": 12.09,
    "Absolute Visual Magnitude": 11.575,
    "Spectral Type": "k-m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.484264,
    "y": -10.809259,
    "z": 1.323686,
    "vx": -0.0000231,
    "vy": -0.00001971,
    "vz": -0.00004767,
    "rarad": 5.25272127881722,
    "decrad": 0.1046293712136,
    "pmrarad": -0.00000236356365544444,
    "pmdecrad": -0.000003782535728,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119324,
    "lum": 0.00204173794466952,
    "ra": 20.063917,
    "dec": 5.994821,
    "pmra": -487.52,
    "pmdec": -780.2,
    "rv": 0,
    "Asset Name": "ASTRID 000614",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Ponytail",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000231,
    "y_1": -0.00001971,
    "z_1": -0.00004767,
    "Distance in Parsecs_1": 12.6743,
    "HYG Star ID_1": 119324,
    "Gliese Catalog_1": "GJ 1248"
  },
  {
    "Astrid #": "ASTRID 000615",
    "HYG Star ID": 119378,
    "Gliese Catalog": "Gl 810A",
    "Distance in Parsecs": 12.6743,
    "mag": 12.45,
    "Absolute Visual Magnitude": 11.935,
    "Spectral Type": "M3.5 J",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.527838,
    "y": -8.858071,
    "z": -3.073835,
    "vx": 0.0000579,
    "vy": 0.0000649,
    "vz": -0.00002653,
    "rarad": 5.47878462425505,
    "decrad": -0.2449677867098,
    "pmrarad": 0.00000684314510104166,
    "pmdecrad": -0.000002157634196,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119378,
    "base": "Gl 810",
    "lum": 0.0014655478409559,
    "ra": 20.927416,
    "dec": -14.03562,
    "pmra": 1411.5,
    "pmdec": -445.04,
    "rv": 0,
    "Asset Name": "ASTRID 000615",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Elegant",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000579,
    "y_1": 0.0000649,
    "z_1": -0.00002653,
    "Distance in Parsecs_1": 12.6743,
    "HYG Star ID_1": 119378,
    "Gliese Catalog_1": "Gl 810A"
  },
  {
    "Astrid #": "ASTRID 000616",
    "HYG Star ID": 119379,
    "Gliese Catalog": "Gl 810B",
    "Distance in Parsecs": 12.6743,
    "mag": 14.55,
    "Absolute Visual Magnitude": 14.035,
    "Color Index": 1.72,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.526094,
    "y": -8.857511,
    "z": -3.080276,
    "vx": 0.0000579,
    "vy": 0.00006491,
    "vz": -0.00002652,
    "rarad": 5.47871393625505,
    "decrad": -0.2454916437098,
    "pmrarad": 0.00000684314510104166,
    "pmdecrad": -0.000002157634196,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119378,
    "base": "Gl 810",
    "lum": 0.00021183611352485,
    "ra": 20.927146,
    "dec": -14.065635,
    "pmra": 1411.5,
    "pmdec": -445.04,
    "rv": 0,
    "Asset Name": "ASTRID 000616",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Round Brush",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000579,
    "y_1": 0.00006491,
    "z_1": -0.00002652,
    "Distance in Parsecs_1": 12.6743,
    "HYG Star ID_1": 119379,
    "Gliese Catalog_1": "Gl 810B"
  },
  {
    "Astrid #": "ASTRID 000617",
    "HYG Star ID": 119142,
    "Gliese Catalog": "GJ 1215",
    "Distance in Parsecs": 12.6904,
    "mag": 15.1,
    "Absolute Visual Magnitude": 14.583,
    "Spectral Type": "m",
    "Color Index": 1.88,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.27914,
    "y": -12.217362,
    "z": 2.56668,
    "vx": -0.00002098,
    "vy": -0.00000132,
    "vz": -0.00002492,
    "rarad": 4.52796125822126,
    "decrad": 0.2036585717435,
    "pmrarad": -0.000001606575574625,
    "pmdecrad": -0.00000200532513,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119142,
    "lum": 0.000127879226293584,
    "ra": 17.295538,
    "dec": 11.668777,
    "pmra": -331.38,
    "pmdec": -413.63,
    "rv": 0,
    "Asset Name": "ASTRID 000617",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Infatuated",
    "Hair": "Audiohead",
    "Outfit": "Warlock",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002098,
    "y_1": -0.00000132,
    "z_1": -0.00002492,
    "Distance in Parsecs_1": 12.6904,
    "HYG Star ID_1": 119142,
    "Gliese Catalog_1": "GJ 1215"
  },
  {
    "Astrid #": "ASTRID 000618",
    "HYG Star ID": 110964,
    "Hipparcos Catalogue": 111313,
    "Gliese Catalog": "Gl 863",
    "Distance in Parsecs": 12.7097,
    "mag": 10.36,
    "Absolute Visual Magnitude": 9.839,
    "Spectral Type": "M0V:",
    "Color Index": 1.501,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.647816,
    "y": -4.644822,
    "z": 2.070994,
    "vx": 0.00000488,
    "vy": 0.00003374,
    "vz": 0.00000743,
    "rarad": 5.90373775563452,
    "decrad": 0.163676538088641,
    "pmrarad": 0.0000026079582318125,
    "pmdecrad": 6.78981559e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 110964,
    "lum": 0.0101018287054483,
    "ra": 22.550617,
    "dec": 9.377975,
    "pmra": 537.93,
    "pmdec": 140.05,
    "rv": -6.5,
    "Asset Name": "ASTRID 000618",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Smoulder",
    "Hair": "Side Part",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000488,
    "y_1": 0.00003374,
    "z_1": 0.00000743,
    "Distance in Parsecs_1": 12.7097,
    "HYG Star ID_1": 110964,
    "Hipparcos Catalogue_1": 111313,
    "Gliese Catalog_1": "Gl 863"
  },
  {
    "Astrid #": "ASTRID 000619",
    "HYG Star ID": 103578,
    "Hipparcos Catalogue": 103910,
    "Gliese Catalog": "Wo 9716",
    "Distance in Parsecs": 12.721,
    "mag": 12.88,
    "Absolute Visual Magnitude": 12.357,
    "Spectral Type": "M4",
    "Color Index": 1.653,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.972575,
    "y": -4.834249,
    "z": -10.664304,
    "vx": -0.00000229,
    "vy": -0.00002856,
    "vz": 0.00001187,
    "rarad": 5.51190392648409,
    "decrad": -0.994199437984782,
    "pmrarad": -0.00000173548753228472,
    "pmdecrad": 0.000001712458882,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 103578,
    "lum": 0.000993573500586844,
    "var_min": 13.098,
    "var_max": 12.558,
    "ra": 21.053922,
    "dec": -56.963432,
    "pmra": -357.97,
    "pmdec": 353.22,
    "rv": 0,
    "Asset Name": "ASTRID 000619",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Lion Mane",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000229,
    "y_1": -0.00002856,
    "z_1": 0.00001187,
    "Distance in Parsecs_1": 12.721,
    "HYG Star ID_1": 103578,
    "Hipparcos Catalogue_1": 103910,
    "Gliese Catalog_1": "Wo 9716"
  },
  {
    "Astrid #": "ASTRID 000620",
    "HYG Star ID": 7903,
    "Hipparcos Catalogue": 7918,
    "Henry Draper Catalogue": 10307,
    "Harvard Revised Catalogue": 483,
    "Gliese Catalog": "Gl 67",
    "Distance in Parsecs": 12.7389,
    "mag": 4.96,
    "Absolute Visual Magnitude": 4.434,
    "Spectral Type": "G2V",
    "Color Index": 0.618,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.465575,
    "y": 4.02816,
    "z": 8.624862,
    "vx": -0.00001209,
    "vy": 0.00004837,
    "vz": -0.00000604,
    "rarad": 0.444111922014284,
    "decrad": 0.743743593397147,
    "pmrarad": 0.00000383657306107638,
    "pmdecrad": -8.73440326e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 7903,
    "lum": 1.46689828220628,
    "ra": 1.696383,
    "dec": 42.613369,
    "pmra": 791.35,
    "pmdec": -180.16,
    "rv": 3.1,
    "Asset Name": "ASTRID 000620",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Pixie",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001209,
    "y_1": 0.00004837,
    "z_1": -0.00000604,
    "Distance in Parsecs_1": 12.7389,
    "HYG Star ID_1": 7903,
    "Hipparcos Catalogue_1": 7918,
    "Henry Draper Catalogue_1": 10307,
    "Harvard Revised Catalogue_1": 483,
    "Gliese Catalog_1": "Gl 67"
  },
  {
    "Astrid #": "ASTRID 000621",
    "HYG Star ID": 119003,
    "Gliese Catalog": "Gl 575B",
    "Distance in Parsecs": 12.7567,
    "mag": 5.96,
    "Absolute Visual Magnitude": 5.431,
    "Spectral Type": "dG2",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -5.975127,
    "y": -6.175778,
    "z": 9.428438,
    "vx": -0.0000181,
    "vy": 0.0000159,
    "vz": 0.00000365,
    "rarad": 3.94354820280217,
    "decrad": 0.83171382824715,
    "pmrarad": -0.00000188660395651388,
    "pmdecrad": 1.25304943e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 73467,
    "base": "Gl 575",
    "lum": 0.585598559849287,
    "ra": 15.063245,
    "dec": 47.653692,
    "pmra": -389.14,
    "pmdec": 25.85,
    "rv": 3.4,
    "Asset Name": "ASTRID 000621",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Shocked",
    "Hair": "Hachimaki",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.0000181,
    "y_1": 0.0000159,
    "z_1": 0.00000365,
    "Distance in Parsecs_1": 12.7567,
    "HYG Star ID_1": 119003,
    "Gliese Catalog_1": "Gl 575B"
  },
  {
    "Astrid #": "ASTRID 000622",
    "HYG Star ID": 65869,
    "Hipparcos Catalogue": 66077,
    "Gliese Catalog": "Gl 516A",
    "Distance in Parsecs": 12.76,
    "mag": 11.38,
    "Absolute Visual Magnitude": 10.851,
    "Spectral Type": "M4Ve",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.228139,
    "y": -4.809091,
    "z": 3.690353,
    "vx": 0.00000422,
    "vy": -0.00001521,
    "vz": -0.0000137,
    "rarad": 3.54626061944179,
    "decrad": 0.293404637705863,
    "pmrarad": 0.00000122633620496527,
    "pmdecrad": -0.000001075462187,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 65869,
    "base": "Gl 516",
    "lum": 0.00397740669105028,
    "var": "VW",
    "var_min": 11.456,
    "var_max": 11.276,
    "ra": 13.545718,
    "dec": 16.810847,
    "pmra": 252.95,
    "pmdec": -221.83,
    "rv": -1.9,
    "Asset Name": "ASTRID 000622",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Horns",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00000422,
    "y_1": -0.00001521,
    "z_1": -0.0000137,
    "Distance in Parsecs_1": 12.76,
    "HYG Star ID_1": 65869,
    "Hipparcos Catalogue_1": 66077,
    "Gliese Catalog_1": "Gl 516A"
  },
  {
    "Astrid #": "ASTRID 000623",
    "HYG Star ID": 58401,
    "Hipparcos Catalogue": 58576,
    "Henry Draper Catalogue": 104304,
    "Harvard Revised Catalogue": 4587,
    "Gliese Catalog": "Gl 454",
    "Distance in Parsecs": 12.7632,
    "mag": 5.54,
    "Absolute Visual Magnitude": 5.01,
    "Spectral Type": "K0IV",
    "Color Index": 0.76,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.5516,
    "y": -0.040575,
    "z": -2.314084,
    "vx": 0.00000535,
    "vy": -0.00000877,
    "vz": -0.00002943,
    "rarad": 3.14482514796155,
    "decrad": -0.182317336861376,
    "pmrarad": 6.88338463652777e-7,
    "pmdecrad": -0.000002343298443,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 58401,
    "lum": 0.86297854776697,
    "ra": 12.012347,
    "dec": -10.446014,
    "pmra": 141.98,
    "pmdec": -483.34,
    "rv": 0.1,
    "Asset Name": "ASTRID 000623",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Angry",
    "Hair": "Goddess",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000535,
    "y_1": -0.00000877,
    "z_1": -0.00002943,
    "Distance in Parsecs_1": 12.7632,
    "HYG Star ID_1": 58401,
    "Hipparcos Catalogue_1": 58576,
    "Henry Draper Catalogue_1": 104304,
    "Harvard Revised Catalogue_1": 4587,
    "Gliese Catalog_1": "Gl 454"
  },
  {
    "Astrid #": "ASTRID 000624",
    "HYG Star ID": 80095,
    "Hipparcos Catalogue": 80337,
    "Henry Draper Catalogue": 147513,
    "Harvard Revised Catalogue": 6094,
    "Gliese Catalog": "Gl 620.1A",
    "Distance in Parsecs": 12.7779,
    "mag": 5.37,
    "Absolute Visual Magnitude": 4.838,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.625,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.027174,
    "y": -9.047466,
    "z": -8.07489,
    "vx": -0.00000142,
    "vy": -0.00001426,
    "vz": -0.00001082,
    "rarad": 4.29360285947575,
    "decrad": -0.684046548343722,
    "pmrarad": 3.52168657555555e-7,
    "pmdecrad": 1.6532146e-8,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 80095,
    "base": "Gl 620.1",
    "lum": 1.0111137119549,
    "ra": 16.400355,
    "dec": -39.19298,
    "pmra": 72.64,
    "pmdec": 3.41,
    "rv": 17,
    "Asset Name": "ASTRID 000624",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Unkempt",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000142,
    "y_1": -0.00001426,
    "z_1": -0.00001082,
    "Distance in Parsecs_1": 12.7779,
    "HYG Star ID_1": 80095,
    "Hipparcos Catalogue_1": 80337,
    "Henry Draper Catalogue_1": 147513,
    "Harvard Revised Catalogue_1": 6094,
    "Gliese Catalog_1": "Gl 620.1A"
  },
  {
    "Astrid #": "ASTRID 000625",
    "HYG Star ID": 51312,
    "Hipparcos Catalogue": 51459,
    "Henry Draper Catalogue": 90839,
    "Harvard Revised Catalogue": 4112,
    "Gliese Catalog": "Gl 395",
    "Distance in Parsecs": 12.7796,
    "mag": 4.82,
    "Absolute Visual Magnitude": 4.287,
    "Spectral Type": "F8V",
    "Color Index": 0.541,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.613102,
    "y": 2.718087,
    "z": 10.592404,
    "vx": -0.00000197,
    "vy": 0.00001267,
    "vz": 0.00000613,
    "rarad": 2.75163235759969,
    "decrad": 0.97704467401096,
    "pmrarad": -8.58217177319444e-7,
    "pmdecrad": -1.62170176e-7,
    "flam": 36,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 51312,
    "lum": 1.67957731398808,
    "bf": "36    UMa",
    "ra": 10.510461,
    "dec": 55.980536,
    "pmra": -177.02,
    "pmdec": -33.45,
    "rv": 8.6,
    "Asset Name": "ASTRID 000625",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Laughing",
    "Hair": "Tiny Crown",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000197,
    "y_1": 0.00001267,
    "z_1": 0.00000613,
    "Distance in Parsecs_1": 12.7796,
    "HYG Star ID_1": 51312,
    "Hipparcos Catalogue_1": 51459,
    "Henry Draper Catalogue_1": 90839,
    "Harvard Revised Catalogue_1": 4112,
    "Gliese Catalog_1": "Gl 395"
  },
  {
    "Astrid #": "ASTRID 000626",
    "HYG Star ID": 25888,
    "Hipparcos Catalogue": 25953,
    "Gliese Catalog": "Gl 206",
    "Distance in Parsecs": 12.7943,
    "mag": 11.55,
    "Absolute Visual Magnitude": 11.015,
    "Spectral Type": "M4Ve",
    "Color Index": 1.637,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.523068,
    "y": 12.514663,
    "z": 2.182323,
    "vx": 0.00001388,
    "vy": 0.00002268,
    "vz": -0.00000963,
    "rarad": 1.44968930457219,
    "decrad": 0.171405424092525,
    "pmrarad": -8.62677463180555e-7,
    "pmdecrad": -0.00000106387514,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 25888,
    "lum": 0.00341979442513708,
    "var": "V998",
    "var_min": 11.618,
    "var_max": 11.448,
    "ra": 5.537405,
    "dec": 9.820807,
    "pmra": -177.94,
    "pmdec": -219.44,
    "rv": 21.7,
    "Asset Name": "ASTRID 000626",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Bob",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00001388,
    "y_1": 0.00002268,
    "z_1": -0.00000963,
    "Distance in Parsecs_1": 12.7943,
    "HYG Star ID_1": 25888,
    "Hipparcos Catalogue_1": 25953,
    "Gliese Catalog_1": "Gl 206"
  },
  {
    "Astrid #": "ASTRID 000627",
    "HYG Star ID": 36809,
    "Hipparcos Catalogue": 36915,
    "Gliese Catalog": "NN 3452",
    "Distance in Parsecs": 12.7975,
    "mag": 11.36,
    "Absolute Visual Magnitude": 10.824,
    "Spectral Type": "M0",
    "Color Index": 1.591,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.978085,
    "y": 6.739117,
    "z": 10.463809,
    "vx": 0.00000723,
    "vy": 0.00000228,
    "vz": 5.9e-7,
    "rarad": 1.98690629994561,
    "decrad": 0.957308230677113,
    "pmrarad": -5.88806215034722e-7,
    "pmdecrad": 7.9848813e-8,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 36809,
    "lum": 0.00407755661750212,
    "var_min": 11.44,
    "var_max": 11.27,
    "ra": 7.589423,
    "dec": 54.849721,
    "pmra": -121.45,
    "pmdec": 16.47,
    "rv": 0,
    "Asset Name": "ASTRID 000627",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Heartbreaker",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000723,
    "y_1": 0.00000228,
    "z_1": 5.9e-7,
    "Distance in Parsecs_1": 12.7975,
    "HYG Star ID_1": 36809,
    "Hipparcos Catalogue_1": 36915,
    "Gliese Catalog_1": "NN 3452"
  },
  {
    "Astrid #": "ASTRID 000628",
    "HYG Star ID": 84976,
    "Hipparcos Catalogue": 85235,
    "Henry Draper Catalogue": 158633,
    "Harvard Revised Catalogue": 6518,
    "Gliese Catalog": "Gl 675",
    "Distance in Parsecs": 12.8025,
    "mag": 6.44,
    "Absolute Visual Magnitude": 5.904,
    "Spectral Type": "K0V",
    "Color Index": 0.759,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.75131,
    "y": -4.88157,
    "z": 11.811056,
    "vx": -0.00003024,
    "vy": 0.00002015,
    "vz": -0.00003605,
    "rarad": 4.55971569534436,
    "decrad": 1.17472360683736,
    "pmrarad": -0.00000257436064375,
    "pmdecrad": 1.7695699e-8,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 84976,
    "lum": 0.378791303785156,
    "ra": 17.416831,
    "dec": 67.306705,
    "pmra": -531,
    "pmdec": 3.65,
    "rv": -38.3,
    "Asset Name": "ASTRID 000628",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Undercut",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00003024,
    "y_1": 0.00002015,
    "z_1": -0.00003605,
    "Distance in Parsecs_1": 12.8025,
    "HYG Star ID_1": 84976,
    "Hipparcos Catalogue_1": 85235,
    "Henry Draper Catalogue_1": 158633,
    "Harvard Revised Catalogue_1": 6518,
    "Gliese Catalog_1": "Gl 675"
  },
  {
    "Astrid #": "ASTRID 000629",
    "HYG Star ID": 118389,
    "Gliese Catalog": "GJ 2045",
    "Distance in Parsecs": 12.8041,
    "mag": 15.28,
    "Absolute Visual Magnitude": 14.743,
    "Spectral Type": "m",
    "Color Index": 1.86,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.988201,
    "y": 12.70756,
    "z": -1.21916,
    "vx": 0.00000848,
    "vy": 0.00000505,
    "vz": 0.00005939,
    "rarad": 1.49318784238296,
    "decrad": -0.0953608036976,
    "pmrarad": -6.30112340618055e-7,
    "pmdecrad": 0.000004660286048,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118389,
    "lum": 0.000110357028996401,
    "ra": 5.703557,
    "dec": -5.463772,
    "pmra": -129.97,
    "pmdec": 961.25,
    "rv": 0,
    "Asset Name": "ASTRID 000629",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Elegant",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000848,
    "y_1": 0.00000505,
    "z_1": 0.00005939,
    "Distance in Parsecs_1": 12.8041,
    "HYG Star ID_1": 118389,
    "Gliese Catalog_1": "GJ 2045"
  },
  {
    "Astrid #": "ASTRID 000630",
    "HYG Star ID": 119214,
    "Gliese Catalog": "NN 4054",
    "Distance in Parsecs": 12.8041,
    "mag": 15.65,
    "Absolute Visual Magnitude": 15.113,
    "Spectral Type": "DC:9",
    "Color Index": 0.97,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 0.576401,
    "y": -6.177955,
    "z": 11.200251,
    "vx": 0.0000091,
    "vy": -0.00003589,
    "vz": -0.00002026,
    "rarad": 4.80539546772729,
    "decrad": 1.0648977295725,
    "pmrarad": 4.47434545784722e-7,
    "pmdecrad": -0.00000326622855,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119214,
    "lum": 0.000078487410348873,
    "ra": 18.355259,
    "dec": 61.014146,
    "pmra": 92.29,
    "pmdec": -673.71,
    "rv": 0,
    "Asset Name": "ASTRID 000630",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Worried Vamp",
    "Hair": "Bucket Hat",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000091,
    "y_1": -0.00003589,
    "z_1": -0.00002026,
    "Distance in Parsecs_1": 12.8041,
    "HYG Star ID_1": 119214,
    "Gliese Catalog_1": "NN 4054"
  },
  {
    "Astrid #": "ASTRID 000631",
    "HYG Star ID": 118757,
    "Gliese Catalog": "NN 3668",
    "Distance in Parsecs": 12.8205,
    "mag": 14.29,
    "Absolute Visual Magnitude": 13.75,
    "Spectral Type": "m",
    "Color Index": 1.81,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.288125,
    "y": 1.556362,
    "z": -3.308316,
    "vx": 0.00001824,
    "vy": -0.00002631,
    "vz": -0.00008024,
    "rarad": 3.01560451133271,
    "decrad": -0.26100214262685,
    "pmrarad": 0.0000018574666543125,
    "pmdecrad": -0.000006477852537,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118757,
    "lum": 0.000275422870333816,
    "ra": 11.518761,
    "dec": -14.954321,
    "pmra": 383.13,
    "pmdec": -1336.15,
    "rv": 0,
    "Asset Name": "ASTRID 000631",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Gimme Blood",
    "Hair": "Honest Lad",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001824,
    "y_1": -0.00002631,
    "z_1": -0.00008024,
    "Distance in Parsecs_1": 12.8205,
    "HYG Star ID_1": 118757,
    "Gliese Catalog_1": "NN 3668"
  },
  {
    "Astrid #": "ASTRID 000632",
    "HYG Star ID": 119129,
    "Gliese Catalog": "GJ 1210",
    "Distance in Parsecs": 12.8205,
    "mag": 14.01,
    "Absolute Visual Magnitude": 13.47,
    "Spectral Type": "m",
    "Color Index": 1.88,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.87781,
    "y": -12.384707,
    "z": 1.643922,
    "vx": -0.00003221,
    "vy": 0.00000429,
    "vz": -0.000024,
    "rarad": 4.48407372108777,
    "decrad": 0.12858004626065,
    "pmrarad": -0.00000252335824455555,
    "pmdecrad": -0.000001887694787,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119129,
    "lum": 0.000356451133426243,
    "ra": 17.1279,
    "dec": 7.367094,
    "pmra": -520.48,
    "pmdec": -389.37,
    "rv": 0,
    "Asset Name": "ASTRID 000632",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Bob",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003221,
    "y_1": 0.00000429,
    "z_1": -0.000024,
    "Distance in Parsecs_1": 12.8205,
    "HYG Star ID_1": 119129,
    "Gliese Catalog_1": "GJ 1210"
  },
  {
    "Astrid #": "ASTRID 000633",
    "HYG Star ID": 119152,
    "Gliese Catalog": "GJ 1218",
    "Distance in Parsecs": 12.8205,
    "mag": 12.68,
    "Absolute Visual Magnitude": 12.14,
    "Spectral Type": "m",
    "Color Index": 1.48,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.820535,
    "y": -5.871614,
    "z": -11.368941,
    "vx": -0.00001275,
    "vy": 0.00004099,
    "vz": -0.00005256,
    "rarad": 4.57362515042622,
    "decrad": -1.0901131330967,
    "pmrarad": -0.00000142729147555555,
    "pmdecrad": -0.000004582521934,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119152,
    "lum": 0.00121338885046497,
    "ra": 17.469961,
    "dec": -62.458882,
    "pmra": -294.4,
    "pmdec": -945.21,
    "rv": 28,
    "Asset Name": "ASTRID 000633",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Curly",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001275,
    "y_1": 0.00004099,
    "z_1": -0.00005256,
    "Distance in Parsecs_1": 12.8205,
    "HYG Star ID_1": 119152,
    "Gliese Catalog_1": "GJ 1218"
  },
  {
    "Astrid #": "ASTRID 000634",
    "HYG Star ID": 119559,
    "Gliese Catalog": "NN 4326",
    "Distance in Parsecs": 12.8205,
    "mag": 12.1,
    "Absolute Visual Magnitude": 11.56,
    "Spectral Type": "M4",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.869231,
    "y": -2.22845,
    "z": 4.303555,
    "vx": 0.00000426,
    "vy": 0.00001508,
    "vz": -0.00000394,
    "rarad": 6.09759198205386,
    "decrad": 0.342324519958949,
    "pmrarad": 0.00000121756107734722,
    "pmdecrad": -3.26240821e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119559,
    "lum": 0.00207014134879103,
    "ra": 23.291086,
    "dec": 19.61375,
    "pmra": 251.14,
    "pmdec": -67.29,
    "rv": 0,
    "Asset Name": "ASTRID 000634",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Good Boy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000426,
    "y_1": 0.00001508,
    "z_1": -0.00000394,
    "Distance in Parsecs_1": 12.8205,
    "HYG Star ID_1": 119559,
    "Gliese Catalog_1": "NN 4326"
  },
  {
    "Astrid #": "ASTRID 000635",
    "HYG Star ID": 119614,
    "Gliese Catalog": "NN 4387",
    "Distance in Parsecs": 12.8205,
    "mag": 12.8,
    "Absolute Visual Magnitude": 12.26,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.614642,
    "y": -0.006882,
    "z": -7.189893,
    "vx": -0.00002172,
    "vy": 0.00004451,
    "vz": -0.00003214,
    "rarad": 6.28250084520881,
    "decrad": -0.59536646252615,
    "pmrarad": 0.00000347068417636111,
    "pmdecrad": -0.000003027690523,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119614,
    "lum": 0.00108642562361706,
    "ra": 23.997386,
    "dec": -34.111986,
    "pmra": 715.88,
    "pmdec": -624.51,
    "rv": 0,
    "Asset Name": "ASTRID 000635",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Wavy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002172,
    "y_1": 0.00004451,
    "z_1": -0.00003214,
    "Distance in Parsecs_1": 12.8205,
    "HYG Star ID_1": 119614,
    "Gliese Catalog_1": "NN 4387"
  },
  {
    "Astrid #": "ASTRID 000636",
    "HYG Star ID": 98385,
    "Hipparcos Catalogue": 98698,
    "Henry Draper Catalogue": 190007,
    "Gliese Catalog": "Gl 775",
    "Distance in Parsecs": 12.8601,
    "mag": 7.46,
    "Absolute Visual Magnitude": 6.914,
    "Spectral Type": "K4V",
    "Color Index": 1.128,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.55367,
    "y": -11.039385,
    "z": 0.746132,
    "vx": -0.00002065,
    "vy": 0.00002351,
    "vz": 0.00000575,
    "rarad": 5.24813558637193,
    "decrad": 0.05805293778375,
    "pmrarad": -4.47386064416666e-7,
    "pmdecrad": 5.85461e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 98385,
    "lum": 0.14941699574067,
    "var": "V1654",
    "var_min": 7.476,
    "var_max": 7.446,
    "ra": 20.046401,
    "dec": 3.326188,
    "pmra": -92.28,
    "pmdec": 120.76,
    "rv": -29.7,
    "Asset Name": "ASTRID 000636",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Hachimaki",
    "Outfit": "School Uniform",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00002065,
    "y_1": 0.00002351,
    "z_1": 0.00000575,
    "Distance in Parsecs_1": 12.8601,
    "HYG Star ID_1": 98385,
    "Hipparcos Catalogue_1": 98698,
    "Henry Draper Catalogue_1": 190007,
    "Gliese Catalog_1": "Gl 775"
  },
  {
    "Astrid #": "ASTRID 000637",
    "HYG Star ID": 91147,
    "Hipparcos Catalogue": 91430,
    "Gliese Catalog": "GJ 2138",
    "Distance in Parsecs": 12.8866,
    "mag": 11.28,
    "Absolute Visual Magnitude": 10.729,
    "Spectral Type": "M1V:",
    "Color Index": 1.556,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.099274,
    "y": -12.298787,
    "z": -3.224492,
    "vx": 0.00000532,
    "vy": 0.00000993,
    "vz": -0.00003443,
    "rarad": 4.88144905405702,
    "decrad": -0.252908036296927,
    "pmrarad": 5.36349374798611e-7,
    "pmdecrad": -0.000002759462506,
    "con": "Sct",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scutum",
    "FULL CONSTELLATION NAME": "Scutum",
    "comp": 1,
    "comp_primary": 91147,
    "lum": 0.00445040976649537,
    "var_min": 11.366,
    "var_max": 11.186,
    "ra": 18.645762,
    "dec": -14.490563,
    "pmra": 110.63,
    "pmdec": -569.18,
    "rv": 0,
    "Asset Name": "ASTRID 000637",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Braided Bun",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scutum",
    "x_1": 0.00000532,
    "y_1": 0.00000993,
    "z_1": -0.00003443,
    "Distance in Parsecs_1": 12.8866,
    "HYG Star ID_1": 91147,
    "Hipparcos Catalogue_1": 91430,
    "Gliese Catalog_1": "GJ 2138"
  },
  {
    "Astrid #": "ASTRID 000638",
    "HYG Star ID": 118754,
    "Gliese Catalog": "Gl 428B",
    "Distance in Parsecs": 12.8899,
    "mag": 8.3,
    "Absolute Visual Magnitude": 7.749,
    "Spectral Type": "M0 Ve",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.048871,
    "y": 0.940339,
    "z": -11.343825,
    "vx": -7.9e-7,
    "vy": 0.00003087,
    "vz": -0.00000225,
    "rarad": 2.98750055309798,
    "decrad": -1.07594566487215,
    "pmrarad": -0.00000235735804033333,
    "pmdecrad": 2.93622557e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 2,
    "comp_primary": 55525,
    "base": "Gl 428",
    "lum": 0.0692468464322636,
    "ra": 11.411412,
    "dec": -61.647146,
    "pmra": -486.24,
    "pmdec": 60.56,
    "rv": 4.5,
    "Asset Name": "ASTRID 000638",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Glad",
    "Hair": "Ram Horns",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -7.9e-7,
    "y_1": 0.00003087,
    "z_1": -0.00000225,
    "Distance in Parsecs_1": 12.8899,
    "HYG Star ID_1": 118754,
    "Gliese Catalog_1": "Gl 428B"
  },
  {
    "Astrid #": "ASTRID 000639",
    "HYG Star ID": 60259,
    "Hipparcos Catalogue": 60444,
    "Gliese Catalog": "NN 3722",
    "Distance in Parsecs": 12.8966,
    "mag": 11.19,
    "Absolute Visual Magnitude": 10.638,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -4.973677,
    "y": -0.512951,
    "z": 11.887881,
    "vx": -0.00000546,
    "vy": -0.00001546,
    "vz": -0.00000295,
    "rarad": 3.24434670412685,
    "decrad": 1.17265730106768,
    "pmrarad": 0.00000114881449744444,
    "pmdecrad": -5.90163693e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 60259,
    "lum": 0.00483949449301662,
    "var_min": 11.251,
    "var_max": 11.121,
    "ra": 12.392492,
    "dec": 67.188314,
    "pmra": 236.96,
    "pmdec": -121.73,
    "rv": 0,
    "Asset Name": "ASTRID 000639",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Goddess",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000546,
    "y_1": -0.00001546,
    "z_1": -0.00000295,
    "Distance in Parsecs_1": 12.8966,
    "HYG Star ID_1": 60259,
    "Hipparcos Catalogue_1": 60444,
    "Gliese Catalog_1": "NN 3722"
  },
  {
    "Astrid #": "ASTRID 000640",
    "HYG Star ID": 119569,
    "Gliese Catalog": "Gl 897B",
    "Distance in Parsecs": 12.9032,
    "mag": 11.4,
    "Absolute Visual Magnitude": 10.847,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.268507,
    "y": -1.464349,
    "z": -3.719139,
    "vx": -0.00000181,
    "vy": 0.0000206,
    "vz": -0.00001408,
    "rarad": 6.16438551527758,
    "decrad": -0.292381888241799,
    "pmrarad": 0.00000156890555164583,
    "pmdecrad": -0.000001139864836,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 2,
    "comp_primary": 115842,
    "base": "Gl 897",
    "lum": 0.00399208702438807,
    "ra": 23.546218,
    "dec": -16.752248,
    "pmra": 323.61,
    "pmdec": -235.11,
    "rv": 0,
    "Asset Name": "ASTRID 000640",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Innocent",
    "Outfit": "Unit 01",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000181,
    "y_1": 0.0000206,
    "z_1": -0.00001408,
    "Distance in Parsecs_1": 12.9032,
    "HYG Star ID_1": 119569,
    "Gliese Catalog_1": "Gl 897B"
  },
  {
    "Astrid #": "ASTRID 000641",
    "HYG Star ID": 46447,
    "Hipparcos Catalogue": 46580,
    "Henry Draper Catalogue": 82106,
    "Gliese Catalog": "Gl 349",
    "Distance in Parsecs": 12.9066,
    "mag": 7.2,
    "Absolute Visual Magnitude": 6.646,
    "Spectral Type": "K3V",
    "Color Index": 1.002,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.18691,
    "y": 7.822785,
    "z": 1.271847,
    "vx": -0.00000253,
    "vy": 0.00004171,
    "vz": 0.00000963,
    "rarad": 2.48671791147456,
    "decrad": 0.0987007133316699,
    "pmrarad": -0.00000244394576368055,
    "pmdecrad": 5.33295048e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46447,
    "lum": 0.19124936418442,
    "ra": 9.498563,
    "dec": 5.655134,
    "pmra": -504.1,
    "pmdec": 110,
    "rv": 27.6,
    "Asset Name": "ASTRID 000641",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Honest Lad",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000253,
    "y_1": 0.00004171,
    "z_1": 0.00000963,
    "Distance in Parsecs_1": 12.9066,
    "HYG Star ID_1": 46447,
    "Hipparcos Catalogue_1": 46580,
    "Henry Draper Catalogue_1": 82106,
    "Gliese Catalog_1": "Gl 349"
  },
  {
    "Astrid #": "ASTRID 000642",
    "HYG Star ID": 118338,
    "Gliese Catalog": "GJ 1073",
    "Distance in Parsecs": 12.9199,
    "mag": 13.43,
    "Absolute Visual Magnitude": 12.874,
    "Spectral Type": "k",
    "Color Index": 1.61,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.839277,
    "y": 9.373306,
    "z": 8.426357,
    "vx": -0.00005798,
    "vy": 0.00006523,
    "vz": -0.00005296,
    "rarad": 1.27657921265575,
    "decrad": 0.71048276578115,
    "pmrarad": 0.00000576065311509722,
    "pmdecrad": -0.000005409604377,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118338,
    "lum": 0.000617163168455583,
    "ra": 4.876173,
    "dec": 40.707664,
    "pmra": 1188.22,
    "pmdec": -1115.81,
    "rv": 0,
    "Asset Name": "ASTRID 000642",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Charmer",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005798,
    "y_1": 0.00006523,
    "z_1": -0.00005296,
    "Distance in Parsecs_1": 12.9199,
    "HYG Star ID_1": 118338,
    "Gliese Catalog_1": "GJ 1073"
  },
  {
    "Astrid #": "ASTRID 000643",
    "HYG Star ID": 119137,
    "Gliese Catalog": "GJ 1214",
    "Distance in Parsecs": 12.9199,
    "mag": 14.67,
    "Absolute Visual Magnitude": 14.114,
    "Spectral Type": "m",
    "Color Index": 1.74,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.493928,
    "y": -12.627569,
    "z": 1.117419,
    "vx": 0.00003247,
    "vy": -0.00001033,
    "vz": -0.00004412,
    "rarad": 4.51739916144158,
    "decrad": 0.08659637233415,
    "pmrarad": 0.00000262070883161111,
    "pmdecrad": -0.000003427773317,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119137,
    "lum": 0.000196969961489992,
    "ra": 17.255194,
    "dec": 4.961607,
    "pmra": 540.56,
    "pmdec": -707.03,
    "rv": 0,
    "Asset Name": "ASTRID 000643",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Ram Horns",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003247,
    "y_1": -0.00001033,
    "z_1": -0.00004412,
    "Distance in Parsecs_1": 12.9199,
    "HYG Star ID_1": 119137,
    "Gliese Catalog_1": "GJ 1214"
  },
  {
    "Astrid #": "ASTRID 000644",
    "HYG Star ID": 118705,
    "Gliese Catalog": "Gl 401B",
    "Distance in Parsecs": 12.9366,
    "mag": 16.5,
    "Absolute Visual Magnitude": 15.941,
    "Spectral Type": "DQ9",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -11.585692,
    "y": 3.896597,
    "z": -4.236027,
    "vx": 0.00005012,
    "vy": 0.00010664,
    "vz": -0.00003917,
    "rarad": 2.8171753526011,
    "decrad": -0.33359845664975,
    "pmrarad": -0.00000904870797799305,
    "pmdecrad": -0.000003204312995,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 52469,
    "base": "Gl 401",
    "lum": 0.0000366100228546542,
    "ra": 10.760817,
    "dec": -19.113784,
    "pmra": -1866.43,
    "pmdec": -660.94,
    "rv": 0,
    "Asset Name": "ASTRID 000644",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005012,
    "y_1": 0.00010664,
    "z_1": -0.00003917,
    "Distance in Parsecs_1": 12.9366,
    "HYG Star ID_1": 118705,
    "Gliese Catalog_1": "Gl 401B"
  },
  {
    "Astrid #": "ASTRID 000645",
    "HYG Star ID": 118360,
    "Gliese Catalog": "Gl 194B",
    "Distance in Parsecs": 12.9383,
    "mag": 0.96,
    "Absolute Visual Magnitude": 0.401,
    "Spectral Type": "G0 III",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.689045,
    "y": 8.828648,
    "z": 9.308413,
    "vx": 0.00000314,
    "vy": 0.00004332,
    "vz": 0.00000654,
    "rarad": 1.38175777871912,
    "decrad": 0.8028661534716,
    "pmrarad": 3.90614382423611e-7,
    "pmdecrad": -0.000002047770568,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 2,
    "comp_primary": 24549,
    "base": "Gl 194",
    "lum": 60.2004863684459,
    "ra": 5.277926,
    "dec": 46.000842,
    "pmra": 80.57,
    "pmdec": -422.38,
    "rv": 33.9,
    "Asset Name": "ASTRID 000645",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Side Part",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000314,
    "y_1": 0.00004332,
    "z_1": 0.00000654,
    "Distance in Parsecs_1": 12.9383,
    "HYG Star ID_1": 118360,
    "Gliese Catalog_1": "Gl 194B"
  },
  {
    "Astrid #": "ASTRID 000646",
    "HYG Star ID": 13185,
    "Hipparcos Catalogue": 13218,
    "Distance in Parsecs": 12.955,
    "mag": 10.72,
    "Absolute Visual Magnitude": 10.158,
    "Spectral Type": "M",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.725956,
    "y": 5.254333,
    "z": -10.365203,
    "vx": 0.0000236,
    "vy": 0.00001165,
    "vz": 0.00001894,
    "rarad": 0.742475966175678,
    "decrad": -0.927449995228435,
    "pmrarad": -5.69122779604166e-7,
    "pmdecrad": 0.000002438031036,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 13185,
    "lum": 0.00753008710547589,
    "var_min": 10.769,
    "var_max": 10.639,
    "ra": 2.836049,
    "dec": -53.13897,
    "pmra": -117.39,
    "pmdec": 502.88,
    "rv": 0,
    "Asset Name": "ASTRID 000646",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Manbun",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": 0.0000236,
    "y_1": 0.00001165,
    "z_1": 0.00001894,
    "Distance in Parsecs_1": 12.955,
    "HYG Star ID_1": 13185,
    "Hipparcos Catalogue_1": 13218
  },
  {
    "Astrid #": "ASTRID 000647",
    "HYG Star ID": 115197,
    "Hipparcos Catalogue": 115562,
    "Gliese Catalog": "Gl 895",
    "Distance in Parsecs": 12.9618,
    "mag": 10.03,
    "Absolute Visual Magnitude": 9.467,
    "Spectral Type": "M2",
    "Color Index": 1.465,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.813946,
    "y": -1.063728,
    "z": 10.974472,
    "vx": -0.00000392,
    "vy": -0.00000336,
    "vz": -0.00003872,
    "rarad": 6.12832700625097,
    "decrad": 1.00974816440654,
    "pmrarad": -3.03057031715277e-7,
    "pmdecrad": -0.000001370810681,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 115197,
    "lum": 0.0142298394457933,
    "ra": 23.408485,
    "dec": 57.854308,
    "pmra": -62.51,
    "pmdec": -282.75,
    "rv": -33.8,
    "Asset Name": "ASTRID 000647",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Charmer",
    "Outfit": "Astro",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000392,
    "y_1": -0.00000336,
    "z_1": -0.00003872,
    "Distance in Parsecs_1": 12.9618,
    "HYG Star ID_1": 115197,
    "Hipparcos Catalogue_1": 115562,
    "Gliese Catalog_1": "Gl 895"
  },
  {
    "Astrid #": "ASTRID 000648",
    "HYG Star ID": 118106,
    "Gliese Catalog": "NN 3131",
    "Distance in Parsecs": 12.987,
    "mag": 11.21,
    "Absolute Visual Magnitude": 10.642,
    "Spectral Type": "M3",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.394482,
    "y": 6.20517,
    "z": -4.702425,
    "vx": -4.6e-7,
    "vy": -0.00001699,
    "vz": -0.00002345,
    "rarad": 0.538190862057032,
    "decrad": -0.37050587350115,
    "pmrarad": -0.00000110493885935416,
    "pmdecrad": -0.000001937170023,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 118106,
    "lum": 0.00482169793900617,
    "ra": 2.055738,
    "dec": -21.228423,
    "pmra": -227.91,
    "pmdec": -399.57,
    "rv": 0,
    "Asset Name": "ASTRID 000648",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Bob",
    "Outfit": "Sharky",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -4.6e-7,
    "y_1": -0.00001699,
    "z_1": -0.00002345,
    "Distance in Parsecs_1": 12.987,
    "HYG Star ID_1": 118106,
    "Gliese Catalog_1": "NN 3131"
  },
  {
    "Astrid #": "ASTRID 000649",
    "HYG Star ID": 118220,
    "Gliese Catalog": "NN 3224",
    "Distance in Parsecs": 12.987,
    "mag": 14.7,
    "Absolute Visual Magnitude": 14.132,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.055286,
    "y": 10.100146,
    "z": 1.327251,
    "vx": 0.00000825,
    "vy": -0.00000542,
    "vz": -0.0000088,
    "rarad": 0.897556470988506,
    "decrad": 0.10237720271805,
    "pmrarad": -7.56600229875e-7,
    "pmdecrad": -6.81245639e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118220,
    "lum": 0.000193731392458195,
    "ra": 3.428413,
    "dec": 5.865782,
    "pmra": -156.06,
    "pmdec": -140.52,
    "rv": 0,
    "Asset Name": "ASTRID 000649",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Bob",
    "Outfit": "Wizard",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000825,
    "y_1": -0.00000542,
    "z_1": -0.0000088,
    "Distance in Parsecs_1": 12.987,
    "HYG Star ID_1": 118220,
    "Gliese Catalog_1": "NN 3224"
  },
  {
    "Astrid #": "ASTRID 000650",
    "HYG Star ID": 118331,
    "Gliese Catalog": "NN 3309",
    "Distance in Parsecs": 12.987,
    "mag": 11.26,
    "Absolute Visual Magnitude": 10.692,
    "Spectral Type": "M2.5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.717307,
    "y": 10.862953,
    "z": 6.069601,
    "vx": -0.00000217,
    "vy": 0.00001342,
    "vz": -0.00002268,
    "rarad": 1.24108347695904,
    "decrad": 0.48630188139485,
    "pmrarad": 4.926191808125e-7,
    "pmdecrad": -0.000001975732103,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118331,
    "lum": 0.00460468578378573,
    "ra": 4.74059,
    "dec": 27.863045,
    "pmra": 101.61,
    "pmdec": -407.52,
    "rv": 0,
    "Asset Name": "ASTRID 000650",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Neko Ninja",
    "Hair": "Good Boy",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00000217,
    "y_1": 0.00001342,
    "z_1": -0.00002268,
    "Distance in Parsecs_1": 12.987,
    "HYG Star ID_1": 118331,
    "Gliese Catalog_1": "NN 3309"
  },
  {
    "Astrid #": "ASTRID 000651",
    "HYG Star ID": 119119,
    "Gliese Catalog": "NN 3981",
    "Distance in Parsecs": 12.987,
    "mag": 13.13,
    "Absolute Visual Magnitude": 12.562,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.345687,
    "y": -12.150849,
    "z": 3.134553,
    "vx": -0.00002304,
    "vy": 0.00000644,
    "vz": 4.1e-7,
    "rarad": 4.4437040149451,
    "decrad": 0.24376786739975,
    "pmrarad": -0.00000184200109790277,
    "pmdecrad": 3.2147995e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119119,
    "lum": 0.00082262139388311,
    "ra": 16.973699,
    "dec": 13.96687,
    "pmra": -379.94,
    "pmdec": 6.63,
    "rv": 0,
    "Asset Name": "ASTRID 000651",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Audiohead",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002304,
    "y_1": 0.00000644,
    "z_1": 4.1e-7,
    "Distance in Parsecs_1": 12.987,
    "HYG Star ID_1": 119119,
    "Gliese Catalog_1": "NN 3981"
  },
  {
    "Astrid #": "ASTRID 000652",
    "HYG Star ID": 119598,
    "Gliese Catalog": "NN 4368",
    "Distance in Parsecs": 12.987,
    "mag": 11.5,
    "Absolute Visual Magnitude": 10.932,
    "Spectral Type": "M1",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.780938,
    "y": -0.524501,
    "z": 2.24381,
    "vx": -0.00000199,
    "vy": 0.00003642,
    "vz": 0.0000199,
    "rarad": 6.24216842660072,
    "decrad": 0.17364488898995,
    "pmrarad": 0.00000279587201439583,
    "pmdecrad": 0.000001556159799,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119598,
    "lum": 0.00369147558165412,
    "ra": 23.843327,
    "dec": 9.949119,
    "pmra": 576.69,
    "pmdec": 320.98,
    "rv": 0,
    "Asset Name": "ASTRID 000652",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Manbun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000199,
    "y_1": 0.00003642,
    "z_1": 0.0000199,
    "Distance in Parsecs_1": 12.987,
    "HYG Star ID_1": 119598,
    "Gliese Catalog_1": "NN 4368"
  },
  {
    "Astrid #": "ASTRID 000653",
    "HYG Star ID": 27818,
    "Hipparcos Catalogue": 27887,
    "Henry Draper Catalogue": 40307,
    "Distance in Parsecs": 12.9955,
    "mag": 7.17,
    "Absolute Visual Magnitude": 6.601,
    "Spectral Type": "K3V",
    "Color Index": 0.935,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.167969,
    "y": 6.490966,
    "z": -11.257094,
    "vx": 0.00000317,
    "vy": -0.00000338,
    "vz": -0.0000019,
    "rarad": 1.54492699627884,
    "decrad": -1.04760720593714,
    "pmrarad": -2.50939561055555e-7,
    "pmdecrad": -2.93021388e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 27818,
    "lum": 0.199342545649809,
    "ra": 5.901186,
    "dec": -60.023471,
    "pmra": -51.76,
    "pmdec": -60.44,
    "rv": 0,
    "Asset Name": "ASTRID 000653",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Greasy",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00000317,
    "y_1": -0.00000338,
    "z_1": -0.0000019,
    "Distance in Parsecs_1": 12.9955,
    "HYG Star ID_1": 27818,
    "Hipparcos Catalogue_1": 27887,
    "Henry Draper Catalogue_1": 40307
  },
  {
    "Astrid #": "ASTRID 000654",
    "HYG Star ID": 66415,
    "Hipparcos Catalogue": 66625,
    "Gliese Catalog": "Gl 521",
    "Distance in Parsecs": 13.009,
    "mag": 10.24,
    "Absolute Visual Magnitude": 9.669,
    "Spectral Type": "M2",
    "Color Index": 1.368,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.172025,
    "y": -3.784736,
    "z": 9.386835,
    "vx": 0.00005695,
    "vy": 0.00002938,
    "vz": -0.00003113,
    "rarad": 3.57531515967692,
    "decrad": 0.806106381476436,
    "pmrarad": -2.09294065895833e-7,
    "pmdecrad": 0.000001894797307,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 66415,
    "lum": 0.0118140825192088,
    "var_min": 10.28,
    "var_max": 10.17,
    "ra": 13.656698,
    "dec": 46.186493,
    "pmra": -43.17,
    "pmdec": 390.83,
    "rv": -65.3,
    "Asset Name": "ASTRID 000654",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Grecian",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00005695,
    "y_1": 0.00002938,
    "z_1": -0.00003113,
    "Distance in Parsecs_1": 13.009,
    "HYG Star ID_1": 66415,
    "Hipparcos Catalogue_1": 66625,
    "Gliese Catalog_1": "Gl 521"
  },
  {
    "Astrid #": "ASTRID 000655",
    "HYG Star ID": 25559,
    "Hipparcos Catalogue": 25623,
    "Henry Draper Catalogue": 36003,
    "Gliese Catalog": "Gl 204",
    "Distance in Parsecs": 13.0208,
    "mag": 7.65,
    "Absolute Visual Magnitude": 7.077,
    "Spectral Type": "K5V",
    "Color Index": 1.113,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.784269,
    "y": 12.872953,
    "z": -0.794769,
    "vx": 0.0000109,
    "vy": -0.00006232,
    "vz": -0.00004673,
    "rarad": 1.43306778296413,
    "decrad": -0.0610787612087962,
    "pmrarad": -0.00000148663267005555,
    "pmdecrad": -0.000003864595291,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 25559,
    "lum": 0.128587869263439,
    "ra": 5.473916,
    "dec": -3.499555,
    "pmra": -306.64,
    "pmdec": -797.13,
    "rv": -56,
    "Asset Name": "ASTRID 000655",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Blep",
    "Hair": "Bandana",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.0000109,
    "y_1": -0.00006232,
    "z_1": -0.00004673,
    "Distance in Parsecs_1": 13.0208,
    "HYG Star ID_1": 25559,
    "Hipparcos Catalogue_1": 25623,
    "Henry Draper Catalogue_1": 36003,
    "Gliese Catalog_1": "Gl 204"
  },
  {
    "Astrid #": "ASTRID 000656",
    "HYG Star ID": 118371,
    "Gliese Catalog": "GJ 1077",
    "Distance in Parsecs": 13.0208,
    "mag": 11.91,
    "Absolute Visual Magnitude": 11.337,
    "Spectral Type": "M1.5 V",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.493796,
    "y": 2.595246,
    "z": -12.749097,
    "vx": -0.00001798,
    "vy": -0.00006922,
    "vz": 0.00000295,
    "rarad": 1.38270300887978,
    "decrad": -1.36647291887165,
    "pmrarad": 3.62737595791666e-7,
    "pmdecrad": -0.000005320597433,
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 118371,
    "lum": 0.00254214313620493,
    "ra": 5.281536,
    "dec": -78.293131,
    "pmra": 74.82,
    "pmdec": -1097.45,
    "rv": -17,
    "Asset Name": "ASTRID 000656",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "High Fade",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": -0.00001798,
    "y_1": -0.00006922,
    "z_1": 0.00000295,
    "Distance in Parsecs_1": 13.0208,
    "HYG Star ID_1": 118371,
    "Gliese Catalog_1": "GJ 1077"
  },
  {
    "Astrid #": "ASTRID 000657",
    "HYG Star ID": 118083,
    "Gliese Catalog": "NN 3106",
    "Distance in Parsecs": 13.0378,
    "mag": 10.07,
    "Absolute Visual Magnitude": 9.494,
    "Spectral Type": "B5",
    "Color Index": 0.47,
    "Spectral Abbreviation": "B",
    "Star Type": "Teal",
    "Color In Star Map": "Teal",
    "x": 5.202182,
    "y": 2.457266,
    "z": 11.699318,
    "vx": -0.000003,
    "vy": -0.00000194,
    "vz": -0.00000624,
    "rarad": 0.441288568493108,
    "decrad": 1.11375706279285,
    "pmrarad": -3.60701378333333e-8,
    "pmdecrad": 3.2375857e-8,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 118083,
    "lum": 0.0138803366656723,
    "ra": 1.685598,
    "dec": 63.813579,
    "pmra": -7.44,
    "pmdec": 6.68,
    "rv": -7,
    "Asset Name": "ASTRID 000657",
    "Background": "Volatile Expanse",
    "Expression": "Oh Snap",
    "Hair": "Hachimaki",
    "Outfit": "Elven",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.000003,
    "y_1": -0.00000194,
    "z_1": -0.00000624,
    "Distance in Parsecs_1": 13.0378,
    "HYG Star ID_1": 118083,
    "Gliese Catalog_1": "NN 3106"
  },
  {
    "Astrid #": "ASTRID 000658",
    "HYG Star ID": 118740,
    "Gliese Catalog": "Gl 422",
    "Distance in Parsecs": 13.0378,
    "mag": 11.65,
    "Absolute Visual Magnitude": 11.074,
    "Spectral Type": "m",
    "Color Index": 1.47,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.865479,
    "y": 1.334231,
    "z": -10.998789,
    "vx": 0.00000826,
    "vy": 0.0001578,
    "vz": 0.00010111,
    "rarad": 2.95016472490989,
    "decrad": -1.00441082103285,
    "pmrarad": -0.0000120051018020208,
    "pmdecrad": 0.000005572579343,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 118740,
    "lum": 0.00323891834996877,
    "ra": 11.268799,
    "dec": -57.548501,
    "pmra": -2476.23,
    "pmdec": 1149.43,
    "rv": -72,
    "Asset Name": "ASTRID 000658",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "High Bun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00000826,
    "y_1": 0.0001578,
    "z_1": 0.00010111,
    "Distance in Parsecs_1": 13.0378,
    "HYG Star ID_1": 118740,
    "Gliese Catalog_1": "Gl 422"
  },
  {
    "Astrid #": "ASTRID 000659",
    "HYG Star ID": 19834,
    "Hipparcos Catalogue": 19884,
    "Henry Draper Catalogue": 27274,
    "Gliese Catalog": "Gl 167",
    "Distance in Parsecs": 13.0446,
    "mag": 7.64,
    "Absolute Visual Magnitude": 7.063,
    "Spectral Type": "K5V",
    "Color Index": 1.115,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.418184,
    "y": 7.004311,
    "z": -10.460011,
    "vx": -0.00004201,
    "vy": 0.00002708,
    "vz": 0.00003399,
    "rarad": 1.11676299682177,
    "decrad": -0.930431680388063,
    "pmrarad": 0.00000380535106004861,
    "pmdecrad": 0.000001920298507,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 19834,
    "lum": 0.130256678613668,
    "ra": 4.26572,
    "dec": -53.309808,
    "pmra": 784.91,
    "pmdec": 396.09,
    "rv": -23.2,
    "Asset Name": "ASTRID 000659",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Stunned",
    "Hair": "Grecian",
    "Outfit": "Spartan",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.00004201,
    "y_1": 0.00002708,
    "z_1": 0.00003399,
    "Distance in Parsecs_1": 13.0446,
    "HYG Star ID_1": 19834,
    "Hipparcos Catalogue_1": 19884,
    "Henry Draper Catalogue_1": 27274,
    "Gliese Catalog_1": "Gl 167"
  },
  {
    "Astrid #": "ASTRID 000660",
    "HYG Star ID": 91156,
    "Hipparcos Catalogue": 91438,
    "Henry Draper Catalogue": 172051,
    "Harvard Revised Catalogue": 6998,
    "Gliese Catalog": "Gl 722",
    "Distance in Parsecs": 13.0839,
    "mag": 5.85,
    "Absolute Visual Magnitude": 5.266,
    "Spectral Type": "G5V",
    "Color Index": 0.673,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.062141,
    "y": -12.035562,
    "z": -4.700031,
    "vx": 9.6e-7,
    "vy": -0.0000337,
    "vz": -0.00002317,
    "rarad": 4.88207887250076,
    "decrad": -0.36742446350719,
    "pmrarad": -3.63028484e-7,
    "pmdecrad": -7.36286536e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 91156,
    "lum": 0.681710526305853,
    "ra": 18.648168,
    "dec": -21.051871,
    "pmra": -74.88,
    "pmdec": -151.87,
    "rv": 38.6,
    "Asset Name": "ASTRID 000660",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "High Bun",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 9.6e-7,
    "y_1": -0.0000337,
    "z_1": -0.00002317,
    "Distance in Parsecs_1": 13.0839,
    "HYG Star ID_1": 91156,
    "Hipparcos Catalogue_1": 91438,
    "Henry Draper Catalogue_1": 172051,
    "Harvard Revised Catalogue_1": 6998,
    "Gliese Catalog_1": "Gl 722"
  },
  {
    "Astrid #": "ASTRID 000661",
    "HYG Star ID": 76130,
    "Hipparcos Catalogue": 76362,
    "Distance in Parsecs": 13.0873,
    "mag": 11.65,
    "Absolute Visual Magnitude": 11.066,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.310709,
    "y": -1.800542,
    "z": -12.896414,
    "vx": -0.00000626,
    "vy": 0.00000161,
    "vz": 4.1e-7,
    "rarad": 4.08316763571263,
    "decrad": -1.39979264287345,
    "pmrarad": -4.59118555486111e-7,
    "pmdecrad": 1.84471605e-7,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 76130,
    "lum": 0.00326287172143084,
    "ra": 15.596552,
    "dec": -80.202211,
    "pmra": -94.7,
    "pmdec": 38.05,
    "rv": 0,
    "Asset Name": "ASTRID 000661",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Pixie",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": -0.00000626,
    "y_1": 0.00000161,
    "z_1": 4.1e-7,
    "Distance in Parsecs_1": 13.0873,
    "HYG Star ID_1": 76130,
    "Hipparcos Catalogue_1": 76362
  },
  {
    "Astrid #": "ASTRID 000662",
    "HYG Star ID": 119032,
    "Gliese Catalog": "Gl 592",
    "Distance in Parsecs": 13.089,
    "mag": 12.7,
    "Absolute Visual Magnitude": 12.115,
    "Spectral Type": "M4",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.41696,
    "y": -10.300351,
    "z": -3.195841,
    "vx": -0.0000178,
    "vy": 0.00002467,
    "vz": -0.00003823,
    "rarad": 4.08832412164124,
    "decrad": -0.24665578467185,
    "pmrarad": -0.0000022047871750625,
    "pmdecrad": -0.000003012433437,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119032,
    "lum": 0.0012416523075924,
    "ra": 15.616248,
    "dec": -14.132335,
    "pmra": -454.77,
    "pmdec": -621.36,
    "rv": 0,
    "Asset Name": "ASTRID 000662",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Bring It",
    "Hair": "Round Brush",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000178,
    "y_1": 0.00002467,
    "z_1": -0.00003823,
    "Distance in Parsecs_1": 13.089,
    "HYG Star ID_1": 119032,
    "Gliese Catalog_1": "Gl 592"
  },
  {
    "Astrid #": "ASTRID 000663",
    "HYG Star ID": 88934,
    "Hipparcos Catalogue": 89211,
    "Henry Draper Catalogue": 166348,
    "Gliese Catalog": "Gl 707",
    "Distance in Parsecs": 13.1044,
    "mag": 8.38,
    "Absolute Visual Magnitude": 7.793,
    "Spectral Type": "K7V",
    "Color Index": 1.297,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.512718,
    "y": -9.500436,
    "z": -9.011307,
    "vx": 0.00000736,
    "vy": 0.00002018,
    "vz": -0.00001788,
    "rarad": 4.76630238623181,
    "decrad": -0.758255669150908,
    "pmrarad": 6.4402649325e-7,
    "pmdecrad": -0.000002027102961,
    "con": "CrA",
    "Full Constellation Name Formula 1": "Corona Australis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Australis",
    "comp": 1,
    "comp_primary": 88934,
    "lum": 0.0664966857124675,
    "ra": 18.205934,
    "dec": -43.44485,
    "pmra": 132.84,
    "pmdec": -418.12,
    "rv": -2,
    "Asset Name": "ASTRID 000663",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Grecian",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Australis",
    "x_1": 0.00000736,
    "y_1": 0.00002018,
    "z_1": -0.00001788,
    "Distance in Parsecs_1": 13.1044,
    "HYG Star ID_1": 88934,
    "Hipparcos Catalogue_1": 89211,
    "Henry Draper Catalogue_1": 166348,
    "Gliese Catalog_1": "Gl 707"
  },
  {
    "Astrid #": "ASTRID 000664",
    "HYG Star ID": 118361,
    "Gliese Catalog": "Gl 195A",
    "Distance in Parsecs": 13.1062,
    "mag": 10.2,
    "Absolute Visual Magnitude": 9.613,
    "Spectral Type": "dM2",
    "Color Index": 1.5,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 1.688048,
    "y": 8.974037,
    "z": 9.403799,
    "vx": 0.0000032,
    "vy": 0.00004076,
    "vz": 0.00000501,
    "rarad": 1.38485808746452,
    "decrad": 0.8000922581844,
    "pmrarad": 3.34909290527777e-7,
    "pmdecrad": -0.000001959316312,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 118361,
    "base": "Gl 195",
    "lum": 0.012439416235583,
    "ra": 5.289768,
    "dec": 45.84191,
    "pmra": 69.08,
    "pmdec": -404.14,
    "rv": 31.2,
    "Asset Name": "ASTRID 000664",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Middle Part",
    "Outfit": "Unit 01",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.0000032,
    "y_1": 0.00004076,
    "z_1": 0.00000501,
    "Distance in Parsecs_1": 13.1062,
    "HYG Star ID_1": 118361,
    "Gliese Catalog_1": "Gl 195A"
  },
  {
    "Astrid #": "ASTRID 000665",
    "HYG Star ID": 118362,
    "Gliese Catalog": "Gl 195B",
    "Distance in Parsecs": 13.1062,
    "mag": 13.7,
    "Absolute Visual Magnitude": 13.113,
    "Spectral Type": "M4  :",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.687842,
    "y": 8.972945,
    "z": 9.402655,
    "vx": -9.1e-7,
    "vy": 0.00001892,
    "vz": -0.00001789,
    "rarad": 1.38485808746452,
    "decrad": 0.8000922581844,
    "pmrarad": 3.34909290527777e-7,
    "pmdecrad": -0.000001959316312,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118361,
    "base": "Gl 195",
    "lum": 0.000495222080088521,
    "ra": 5.289768,
    "dec": 45.84191,
    "pmra": 69.08,
    "pmdec": -404.14,
    "rv": 0,
    "Asset Name": "ASTRID 000665",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Lovestruck",
    "Hair": "Pompadour",
    "Outfit": "Sharky",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -9.1e-7,
    "y_1": 0.00001892,
    "z_1": -0.00001789,
    "Distance in Parsecs_1": 13.1062,
    "HYG Star ID_1": 118362,
    "Gliese Catalog_1": "Gl 195B"
  },
  {
    "Astrid #": "ASTRID 000666",
    "HYG Star ID": 24549,
    "Hipparcos Catalogue": 24608,
    "Henry Draper Catalogue": 34029,
    "Harvard Revised Catalogue": 1708,
    "Gliese Catalog": "Gl 194A",
    "Proper Name": "Capella",
    "Distance in Parsecs": 13.1234,
    "mag": 0.08,
    "Absolute Visual Magnitude": -0.51,
    "Spectral Type": "M1: comp",
    "Color Index": 0.795,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.712633,
    "y": 8.954439,
    "z": 9.440007,
    "vx": 0.00000192,
    "vy": 0.00003559,
    "vz": -0.00000254,
    "rarad": 1.3818164126488,
    "decrad": 0.802816392634111,
    "pmrarad": 3.66131291555555e-7,
    "pmdecrad": -0.000002070784673,
    "bayer": "Alp",
    "flam": 13,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 24549,
    "base": "Gl 194",
    "lum": 139.31568029453,
    "bf": "13Alp Aur",
    "ra": 5.27815,
    "dec": 45.997991,
    "pmra": 75.52,
    "pmdec": -427.13,
    "rv": 22.2,
    "Asset Name": "ASTRID 000666",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Seeing Red",
    "Hair": "Horns",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "Capella",
    "Constellation": "Auriga",
    "x_1": 0.00000192,
    "y_1": 0.00003559,
    "z_1": -0.00000254,
    "Distance in Parsecs_1": 13.1234,
    "HYG Star ID_1": 24549,
    "Hipparcos Catalogue_1": 24608,
    "Henry Draper Catalogue_1": 34029,
    "Harvard Revised Catalogue_1": 1708,
    "Gliese Catalog_1": "Gl 194A"
  },
  {
    "Astrid #": "ASTRID 000667",
    "HYG Star ID": 118548,
    "Gliese Catalog": "NN 3506",
    "Distance in Parsecs": 13.1234,
    "mag": 11.67,
    "Absolute Visual Magnitude": 11.08,
    "Spectral Type": "M3",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.081984,
    "y": 3.811172,
    "z": 12.173742,
    "vx": 0.00001742,
    "vy": 0.00006015,
    "vz": -0.0000144,
    "rarad": 2.25113049284324,
    "decrad": 1.1880332766743,
    "pmrarad": -0.00000391574313511111,
    "pmdecrad": -0.000002940026514,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 118548,
    "lum": 0.00322106879128343,
    "ra": 8.598685,
    "dec": 68.069293,
    "pmra": -807.68,
    "pmdec": -606.42,
    "rv": 0,
    "Asset Name": "ASTRID 000667",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Side Part",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001742,
    "y_1": 0.00006015,
    "z_1": -0.0000144,
    "Distance in Parsecs_1": 13.1234,
    "HYG Star ID_1": 118548,
    "Gliese Catalog_1": "NN 3506"
  },
  {
    "Astrid #": "ASTRID 000668",
    "HYG Star ID": 86695,
    "Hipparcos Catalogue": 86963,
    "Distance in Parsecs": 13.1337,
    "mag": 11.39,
    "Absolute Visual Magnitude": 10.798,
    "Spectral Type": "M2V:",
    "Color Index": 1.439,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.667558,
    "y": -11.105518,
    "z": -6.979678,
    "vx": -0.00000438,
    "vy": 0.00000942,
    "vz": -0.00001457,
    "rarad": 4.65235128326668,
    "decrad": -0.560291064813738,
    "pmrarad": -3.76312378847222e-7,
    "pmdecrad": -0.000001309578713,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 86695,
    "lum": 0.00417637992950973,
    "var_min": 11.65,
    "var_max": 10.73,
    "ra": 17.770673,
    "dec": -32.102313,
    "pmra": -77.62,
    "pmdec": -270.12,
    "rv": 0,
    "Asset Name": "ASTRID 000668",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Audiohead",
    "Outfit": "Tats",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000438,
    "y_1": 0.00000942,
    "z_1": -0.00001457,
    "Distance in Parsecs_1": 13.1337,
    "HYG Star ID_1": 86695,
    "Hipparcos Catalogue_1": 86963
  },
  {
    "Astrid #": "ASTRID 000669",
    "HYG Star ID": 80058,
    "Hipparcos Catalogue": 80300,
    "Gliese Catalog": "Gl 620.1B",
    "Distance in Parsecs": 13.1579,
    "mag": 11.01,
    "Absolute Visual Magnitude": 10.414,
    "Spectral Type": "DA",
    "Color Index": -0.165,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.163462,
    "y": -9.303609,
    "z": -8.321719,
    "vx": -0.00001243,
    "vy": -0.00003957,
    "vz": -0.00003363,
    "rarad": 4.2916063766488,
    "decrad": -0.684683759878947,
    "pmrarad": 3.66131291555555e-7,
    "pmdecrad": 2.42406e-10,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 2,
    "comp_primary": 80095,
    "base": "Gl 620.1",
    "lum": 0.00594839774069221,
    "var_min": 11.086,
    "var_max": 10.926,
    "ra": 16.392729,
    "dec": -39.22949,
    "pmra": 75.52,
    "pmdec": 0.05,
    "rv": 52,
    "Asset Name": "ASTRID 000669",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Honest Lad",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00001243,
    "y_1": -0.00003957,
    "z_1": -0.00003363,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 80058,
    "Hipparcos Catalogue_1": 80300,
    "Gliese Catalog_1": "Gl 620.1B"
  },
  {
    "Astrid #": "ASTRID 000670",
    "HYG Star ID": 117991,
    "Gliese Catalog": "NN 3036",
    "Distance in Parsecs": 13.1579,
    "mag": 13.15,
    "Absolute Visual Magnitude": 12.554,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.32468,
    "y": 1.055316,
    "z": 10.134907,
    "vx": -0.0000093,
    "vy": 0.00002602,
    "vz": 0.00000493,
    "rarad": 0.126038704973095,
    "decrad": 0.87923723210155,
    "pmrarad": 0.00000205051946190972,
    "pmdecrad": 5.87982031e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117991,
    "lum": 0.000828705077906982,
    "ra": 0.481432,
    "dec": 50.376583,
    "pmra": 422.95,
    "pmdec": 121.28,
    "rv": 0,
    "Asset Name": "ASTRID 000670",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Goddess",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000093,
    "y_1": 0.00002602,
    "z_1": 0.00000493,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 117991,
    "Gliese Catalog_1": "NN 3036"
  },
  {
    "Astrid #": "ASTRID 000671",
    "HYG Star ID": 118046,
    "Gliese Catalog": "GJ 1031",
    "Distance in Parsecs": 13.1579,
    "mag": 13.42,
    "Absolute Visual Magnitude": 12.824,
    "Spectral Type": "dM5",
    "Color Index": 1.7,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.02148,
    "y": 3.385191,
    "z": -6.340172,
    "vx": -0.00001684,
    "vy": 0.00004143,
    "vz": -0.00000716,
    "rarad": 0.297974927392839,
    "decrad": -0.5027680847999,
    "pmrarad": 0.00000338564785679166,
    "pmdecrad": -6.21375998e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118046,
    "lum": 0.000646249172495387,
    "ra": 1.13818,
    "dec": -28.806489,
    "pmra": 698.34,
    "pmdec": -128.17,
    "rv": 0,
    "Asset Name": "ASTRID 000671",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Undercut",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001684,
    "y_1": 0.00004143,
    "z_1": -0.00000716,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 118046,
    "Gliese Catalog_1": "GJ 1031"
  },
  {
    "Astrid #": "ASTRID 000672",
    "HYG Star ID": 118401,
    "Gliese Catalog": "NN 3372B",
    "Distance in Parsecs": 13.1579,
    "mag": 13.56,
    "Absolute Visual Magnitude": 12.964,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.00257,
    "y": 6.860561,
    "z": 11.227779,
    "vx": 0.00000112,
    "vy": 0.00001357,
    "vz": -0.00000829,
    "rarad": 1.5704255744247,
    "decrad": 1.0223026609157,
    "pmrarad": -8.45515058888888e-8,
    "pmdecrad": -0.000001209081686,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118401,
    "base": "NN 3372",
    "lum": 0.000568067574106771,
    "ra": 5.998584,
    "dec": 58.573628,
    "pmra": -17.44,
    "pmdec": -249.39,
    "rv": 0,
    "Asset Name": "ASTRID 000672",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Mohawk",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000112,
    "y_1": 0.00001357,
    "z_1": -0.00000829,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 118401,
    "Gliese Catalog_1": "NN 3372B"
  },
  {
    "Astrid #": "ASTRID 000673",
    "HYG Star ID": 118744,
    "Gliese Catalog": "Gl 425B",
    "Distance in Parsecs": 13.1579,
    "mag": 11,
    "Absolute Visual Magnitude": 10.404,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.154318,
    "y": 2.063994,
    "z": -4.598132,
    "vx": 0.00000104,
    "vy": -0.00001212,
    "vz": -0.0000082,
    "rarad": 2.97337863342305,
    "decrad": -0.3569924274924,
    "pmrarad": 8.95208461145833e-7,
    "pmdecrad": -6.64829848e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 2,
    "comp_primary": 55289,
    "base": "Gl 425",
    "lum": 0.00600343758732564,
    "ra": 11.35747,
    "dec": -20.454159,
    "pmra": 184.65,
    "pmdec": -137.13,
    "rv": 0,
    "Asset Name": "ASTRID 000673",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Happy Vamp",
    "Hair": "Bangs",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00000104,
    "y_1": -0.00001212,
    "z_1": -0.0000082,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 118744,
    "Gliese Catalog_1": "Gl 425B"
  },
  {
    "Astrid #": "ASTRID 000674",
    "HYG Star ID": 118796,
    "Gliese Catalog": "NN 3704",
    "Distance in Parsecs": 13.1579,
    "mag": 13.07,
    "Absolute Visual Magnitude": 12.474,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.598264,
    "y": -0.110303,
    "z": 12.327779,
    "vx": -0.00000463,
    "vy": 0.00002958,
    "vz": -0.00000146,
    "rarad": 3.16578579958432,
    "decrad": 1.2136858069262,
    "pmrarad": -0.00000225646831340972,
    "pmdecrad": -3.17121476e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118796,
    "lum": 0.000892072188725268,
    "ra": 12.092411,
    "dec": 69.539074,
    "pmra": -465.43,
    "pmdec": -65.41,
    "rv": 0,
    "Asset Name": "ASTRID 000674",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Medium Bob",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000463,
    "y_1": 0.00002958,
    "z_1": -0.00000146,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 118796,
    "Gliese Catalog_1": "NN 3704"
  },
  {
    "Astrid #": "ASTRID 000675",
    "HYG Star ID": 118935,
    "Gliese Catalog": "NN 3817",
    "Distance in Parsecs": 13.1579,
    "mag": 12.26,
    "Absolute Visual Magnitude": 11.664,
    "Spectral Type": "M3",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.170511,
    "y": -6.335541,
    "z": 2.865474,
    "vx": 0.00000178,
    "vy": 0.00001783,
    "vz": 0.00004641,
    "rarad": 3.65752145199118,
    "decrad": 0.21953520866275,
    "pmrarad": -0.00000111192017635416,
    "pmdecrad": 0.000003614373255,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118935,
    "lum": 0.0018810485289187,
    "ra": 13.970703,
    "dec": 12.578441,
    "pmra": -229.35,
    "pmdec": 745.52,
    "rv": 0,
    "Asset Name": "ASTRID 000675",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Neko Ninja",
    "Hair": "Braid",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000178,
    "y_1": 0.00001783,
    "z_1": 0.00004641,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 118935,
    "Gliese Catalog_1": "NN 3817"
  },
  {
    "Astrid #": "ASTRID 000676",
    "HYG Star ID": 119203,
    "Gliese Catalog": "NN 4044",
    "Distance in Parsecs": 13.1579,
    "mag": 13.32,
    "Absolute Visual Magnitude": 12.724,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.67617,
    "y": -11.803909,
    "z": 5.774157,
    "vx": 0.00001323,
    "vy": -2.7e-7,
    "vz": -0.00000209,
    "rarad": 4.76960435815863,
    "decrad": 0.4543025223922,
    "pmrarad": 0.00000100264317275694,
    "pmdecrad": -1.76792156e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119203,
    "lum": 0.000708598126916768,
    "ra": 18.218547,
    "dec": 26.029617,
    "pmra": 206.81,
    "pmdec": -36.47,
    "rv": 0,
    "Asset Name": "ASTRID 000676",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Side Part",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001323,
    "y_1": -2.7e-7,
    "z_1": -0.00000209,
    "Distance in Parsecs_1": 13.1579,
    "HYG Star ID_1": 119203,
    "Gliese Catalog_1": "NN 4044"
  },
  {
    "Astrid #": "ASTRID 000677",
    "HYG Star ID": 55289,
    "Hipparcos Catalogue": 55454,
    "Henry Draper Catalogue": 98712,
    "Gliese Catalog": "Gl 425A",
    "Distance in Parsecs": 13.1631,
    "mag": 8.57,
    "Absolute Visual Magnitude": 7.973,
    "Spectral Type": "K4/K5V",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.159172,
    "y": 2.065062,
    "z": -4.599891,
    "vx": -0.00000637,
    "vy": -0.00001047,
    "vz": -0.00000953,
    "rarad": 2.97336163533524,
    "decrad": -0.356985860225047,
    "pmrarad": 8.65295457055555e-7,
    "pmdecrad": -5.58311434e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 55289,
    "base": "Gl 425",
    "lum": 0.0563378150850938,
    "var": "SZ",
    "var_min": 8.621,
    "var_max": 8.541,
    "ra": 11.357405,
    "dec": -20.453783,
    "pmra": 178.48,
    "pmdec": -115.16,
    "rv": 7.4,
    "Asset Name": "ASTRID 000677",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Antlers",
    "Outfit": "River Spirit",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": -0.00000637,
    "y_1": -0.00001047,
    "z_1": -0.00000953,
    "Distance in Parsecs_1": 13.1631,
    "HYG Star ID_1": 55289,
    "Hipparcos Catalogue_1": 55454,
    "Henry Draper Catalogue_1": 98712,
    "Gliese Catalog_1": "Gl 425A"
  },
  {
    "Astrid #": "ASTRID 000678",
    "HYG Star ID": 111421,
    "Hipparcos Catalogue": 111766,
    "Gliese Catalog": "Gl 865",
    "Distance in Parsecs": 13.1804,
    "mag": 11.5,
    "Absolute Visual Magnitude": 10.9,
    "Color Index": 1.593,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 5.147553,
    "y": -1.911906,
    "z": -11.981825,
    "vx": -5.7e-7,
    "vy": 0.00005701,
    "vz": 0.00001991,
    "rarad": 5.92750710273507,
    "decrad": -1.14107017442103,
    "pmrarad": 0.00000403985543733333,
    "pmdecrad": -7.76962404e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 111421,
    "lum": 0.0038018939632056,
    "var_min": 11.596,
    "var_max": 11.396,
    "ra": 22.641409,
    "dec": -65.378505,
    "pmra": 833.28,
    "pmdec": -160.26,
    "rv": -26,
    "Asset Name": "ASTRID 000678",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Worried Vamp",
    "Hair": "Bangs",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -5.7e-7,
    "y_1": 0.00005701,
    "z_1": 0.00001991,
    "Distance in Parsecs_1": 13.1804,
    "HYG Star ID_1": 111421,
    "Hipparcos Catalogue_1": 111766,
    "Gliese Catalog_1": "Gl 865"
  },
  {
    "Astrid #": "ASTRID 000679",
    "HYG Star ID": 60720,
    "Hipparcos Catalogue": 60910,
    "Gliese Catalog": "Gl 469",
    "Distance in Parsecs": 13.1839,
    "mag": 11.99,
    "Absolute Visual Magnitude": 11.39,
    "Spectral Type": "M:",
    "Color Index": 1.668,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.937629,
    "y": -1.643566,
    "z": 1.931752,
    "vx": -0.00000751,
    "vy": 0.00003989,
    "vz": -0.00001633,
    "rarad": 3.26795372853685,
    "decrad": 0.14705303015008,
    "pmrarad": -0.00000307376721609027,
    "pmdecrad": -0.000001251982848,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 60720,
    "lum": 0.00242102904673617,
    "var_min": 12.093,
    "var_max": 11.883,
    "ra": 12.482664,
    "dec": 8.425518,
    "pmra": -634.01,
    "pmdec": -258.24,
    "rv": 0,
    "Asset Name": "ASTRID 000679",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Heartbreaker",
    "Outfit": "Emperor",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000751,
    "y_1": 0.00003989,
    "z_1": -0.00001633,
    "Distance in Parsecs_1": 13.1839,
    "HYG Star ID_1": 60720,
    "Hipparcos Catalogue_1": 60910,
    "Gliese Catalog_1": "Gl 469"
  },
  {
    "Astrid #": "ASTRID 000680",
    "HYG Star ID": 21764,
    "Hipparcos Catalogue": 21818,
    "Henry Draper Catalogue": 29697,
    "Gliese Catalog": "Gl 174",
    "Distance in Parsecs": 13.1891,
    "mag": 8.09,
    "Absolute Visual Magnitude": 7.489,
    "Spectral Type": "K3V",
    "Color Index": 1.092,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.147652,
    "y": 11.602164,
    "z": 4.7054,
    "vx": 0.00001827,
    "vy": 0.00000674,
    "vz": -0.00001265,
    "rarad": 1.22746570598415,
    "decrad": 0.364800205684298,
    "pmrarad": -0.00000113257323914583,
    "pmdecrad": -0.000001234093223,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 21764,
    "lum": 0.0879832499441866,
    "var": "V834",
    "var_min": 8.167,
    "var_max": 7.997,
    "ra": 4.688574,
    "dec": 20.901512,
    "pmra": -233.61,
    "pmdec": -254.55,
    "rv": 7,
    "Asset Name": "ASTRID 000680",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Honest Lad",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00001827,
    "y_1": 0.00000674,
    "z_1": -0.00001265,
    "Distance in Parsecs_1": 13.1891,
    "HYG Star ID_1": 21764,
    "Hipparcos Catalogue_1": 21818,
    "Henry Draper Catalogue_1": 29697,
    "Gliese Catalog_1": "Gl 174"
  },
  {
    "Astrid #": "ASTRID 000681",
    "HYG Star ID": 118444,
    "Gliese Catalog": "GJ 1092",
    "Distance in Parsecs": 13.1926,
    "mag": 13.76,
    "Absolute Visual Magnitude": 13.158,
    "Spectral Type": "m",
    "Color Index": 1.66,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.235979,
    "y": 10.279369,
    "z": 7.961136,
    "vx": -0.00002657,
    "vy": 0.00005727,
    "vz": -0.00008134,
    "rarad": 1.78496767300358,
    "decrad": 0.6478263962497,
    "pmrarad": 0.00000104584007169444,
    "pmdecrad": -0.000007735115006,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118444,
    "lum": 0.000475116376377561,
    "ra": 6.818074,
    "dec": 37.117718,
    "pmra": 215.72,
    "pmdec": -1595.48,
    "rv": 0,
    "Asset Name": "ASTRID 000681",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous",
    "Hair": "Fedora",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002657,
    "y_1": 0.00005727,
    "z_1": -0.00008134,
    "Distance in Parsecs_1": 13.1926,
    "HYG Star ID_1": 118444,
    "Gliese Catalog_1": "GJ 1092"
  },
  {
    "Astrid #": "ASTRID 000682",
    "HYG Star ID": 55990,
    "Hipparcos Catalogue": 56157,
    "Distance in Parsecs": 13.2013,
    "mag": 11.98,
    "Absolute Visual Magnitude": 11.377,
    "Color Index": 1.548,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -12.963068,
    "y": 1.666525,
    "z": -1.858998,
    "vx": 5.5e-7,
    "vy": 0.00002282,
    "vz": 0.00001663,
    "rarad": 3.01373468908034,
    "decrad": -0.141288980284205,
    "pmrarad": -0.00000171987653177083,
    "pmdecrad": 0.000001272538948,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 55990,
    "lum": 0.00245019133688078,
    "var_min": 12.098,
    "var_max": 11.758,
    "ra": 11.511619,
    "dec": -8.095262,
    "pmra": -354.75,
    "pmdec": 262.48,
    "rv": 0,
    "Asset Name": "ASTRID 000682",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Wavy",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 5.5e-7,
    "y_1": 0.00002282,
    "z_1": 0.00001663,
    "Distance in Parsecs_1": 13.2013,
    "HYG Star ID_1": 55990,
    "Hipparcos Catalogue_1": 56157
  },
  {
    "Astrid #": "ASTRID 000683",
    "HYG Star ID": 70956,
    "Hipparcos Catalogue": 71181,
    "Henry Draper Catalogue": 128165,
    "Gliese Catalog": "Gl 556",
    "Distance in Parsecs": 13.2188,
    "mag": 7.24,
    "Absolute Visual Magnitude": 6.634,
    "Spectral Type": "K3V",
    "Color Index": 0.997,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.250274,
    "y": -4.948629,
    "z": 10.544408,
    "vx": -0.00000329,
    "vy": 0.00001311,
    "vz": 0.00001895,
    "rarad": 3.81128572407013,
    "decrad": 0.923432588518901,
    "pmrarad": -9.31763412659722e-7,
    "pmdecrad": 0.000001200059303,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 70956,
    "lum": 0.193374854529525,
    "ra": 14.558039,
    "dec": 52.90879,
    "pmra": -192.19,
    "pmdec": 247.53,
    "rv": 11.5,
    "Asset Name": "ASTRID 000683",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Hachimaki",
    "Outfit": "Astro",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000329,
    "y_1": 0.00001311,
    "z_1": 0.00001895,
    "Distance in Parsecs_1": 13.2188,
    "HYG Star ID_1": 70956,
    "Hipparcos Catalogue_1": 71181,
    "Henry Draper Catalogue_1": 128165,
    "Gliese Catalog_1": "Gl 556"
  },
  {
    "Astrid #": "ASTRID 000684",
    "HYG Star ID": 118695,
    "Gliese Catalog": "NN 3612",
    "Distance in Parsecs": 13.2275,
    "mag": 11.93,
    "Absolute Visual Magnitude": 11.323,
    "Spectral Type": "k-m",
    "Color Index": 1.55,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.331017,
    "y": 1.673496,
    "z": 12.385818,
    "vx": 0.00000528,
    "vy": 0.00011132,
    "vz": -0.00001314,
    "rarad": 2.77360605282814,
    "decrad": 1.2121384621354,
    "pmrarad": -0.00000799758343718055,
    "pmdecrad": -0.000002832097292,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 118695,
    "lum": 0.00257513499041031,
    "ra": 10.594395,
    "dec": 69.450418,
    "pmra": -1649.62,
    "pmdec": -584.16,
    "rv": 0,
    "Asset Name": "ASTRID 000684",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Unimpressed",
    "Hair": "Goddess",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000528,
    "y_1": 0.00011132,
    "z_1": -0.00001314,
    "Distance in Parsecs_1": 13.2275,
    "HYG Star ID_1": 118695,
    "Gliese Catalog_1": "NN 3612"
  },
  {
    "Astrid #": "ASTRID 000685",
    "HYG Star ID": 66878,
    "Hipparcos Catalogue": 67090,
    "Gliese Catalog": "Gl 525",
    "Distance in Parsecs": 13.2398,
    "mag": 9.79,
    "Absolute Visual Magnitude": 9.181,
    "Color Index": 1.42,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.304998,
    "y": -5.580201,
    "z": 4.044197,
    "vx": -0.00003721,
    "vy": -0.00005065,
    "vz": -0.00010556,
    "rarad": 3.6001101032325,
    "decrad": 0.310414491759703,
    "pmrarad": 0.00000218665514340972,
    "pmdecrad": -0.000008879798891,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 66878,
    "lum": 0.0185182524363817,
    "ra": 13.751408,
    "dec": 17.78544,
    "pmra": 451.03,
    "pmdec": -1831.59,
    "rv": 20.4,
    "Asset Name": "ASTRID 000685",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Spec Ops Goggles",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00003721,
    "y_1": -0.00005065,
    "z_1": -0.00010556,
    "Distance in Parsecs_1": 13.2398,
    "HYG Star ID_1": 66878,
    "Hipparcos Catalogue_1": 67090,
    "Gliese Catalog_1": "Gl 525"
  },
  {
    "Astrid #": "ASTRID 000686",
    "HYG Star ID": 90508,
    "Hipparcos Catalogue": 90790,
    "Henry Draper Catalogue": 170657,
    "Gliese Catalog": "Gl 716",
    "Distance in Parsecs": 13.2521,
    "mag": 6.81,
    "Absolute Visual Magnitude": 6.199,
    "Spectral Type": "K1V",
    "Color Index": 0.861,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.707699,
    "y": -12.419752,
    "z": -4.294394,
    "vx": -0.00001495,
    "vy": 0.00004266,
    "vz": 0.00000196,
    "rarad": 4.84903112051765,
    "decrad": -0.330021019740725,
    "pmrarad": -6.793694105625e-7,
    "pmdecrad": -9.4339894e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 90508,
    "lum": 0.288668901794827,
    "ra": 18.521935,
    "dec": -18.908812,
    "pmra": -140.13,
    "pmdec": -194.59,
    "rv": -41.6,
    "Asset Name": "ASTRID 000686",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Audiophile",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00001495,
    "y_1": 0.00004266,
    "z_1": 0.00000196,
    "Distance in Parsecs_1": 13.2521,
    "HYG Star ID_1": 90508,
    "Hipparcos Catalogue_1": 90790,
    "Henry Draper Catalogue_1": 170657,
    "Gliese Catalog_1": "Gl 716"
  },
  {
    "Astrid #": "ASTRID 000687",
    "HYG Star ID": 64806,
    "Hipparcos Catalogue": 65011,
    "Gliese Catalog": "Gl 507A",
    "Distance in Parsecs": 13.2661,
    "mag": 9.48,
    "Absolute Visual Magnitude": 8.866,
    "Spectral Type": "M0",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.204905,
    "y": -3.692103,
    "z": 7.629975,
    "vx": -0.00001475,
    "vy": -0.00003185,
    "vz": -0.00004385,
    "rarad": 3.48873439754651,
    "decrad": 0.612787799765436,
    "pmrarad": 0.00000187971960225,
    "pmdecrad": -0.000003775341093,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 64806,
    "base": "Gl 507",
    "lum": 0.0247514131807431,
    "var_min": 9.532,
    "var_max": 9.412,
    "ra": 13.325984,
    "dec": 35.110155,
    "pmra": 387.72,
    "pmdec": -778.72,
    "rv": -4.9,
    "Asset Name": "ASTRID 000687",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Happy Vamp",
    "Hair": "Horns",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00001475,
    "y_1": -0.00003185,
    "z_1": -0.00004385,
    "Distance in Parsecs_1": 13.2661,
    "HYG Star ID_1": 64806,
    "Hipparcos Catalogue_1": 65011,
    "Gliese Catalog_1": "Gl 507A"
  },
  {
    "Astrid #": "ASTRID 000688",
    "HYG Star ID": 22210,
    "Hipparcos Catalogue": 22263,
    "Henry Draper Catalogue": 30495,
    "Harvard Revised Catalogue": 1532,
    "Gliese Catalog": "Gl 177",
    "Distance in Parsecs": 13.2767,
    "mag": 5.49,
    "Absolute Visual Magnitude": 4.875,
    "Spectral Type": "G3V",
    "Color Index": 0.632,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.945704,
    "y": 12.072764,
    "z": -3.867261,
    "vx": -4e-7,
    "vy": 0.0000258,
    "vz": 0.00000395,
    "rarad": 1.2549126971483,
    "decrad": -0.295562014333463,
    "pmrarad": 6.322455208125e-7,
    "pmdecrad": 8.20304747e-7,
    "flam": 58,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 22210,
    "lum": 0.97723722095581,
    "var": "IX",
    "var_min": 5.502,
    "var_max": 5.472,
    "bf": "58    Eri",
    "ra": 4.793413,
    "dec": -16.934456,
    "pmra": 130.41,
    "pmdec": 169.2,
    "rv": 21.7,
    "Asset Name": "ASTRID 000688",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Frizzy",
    "Outfit": "Iron Star",
    "Special": "Astral Burst",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -4e-7,
    "y_1": 0.0000258,
    "z_1": 0.00000395,
    "Distance in Parsecs_1": 13.2767,
    "HYG Star ID_1": 22210,
    "Hipparcos Catalogue_1": 22263,
    "Henry Draper Catalogue_1": 30495,
    "Harvard Revised Catalogue_1": 1532,
    "Gliese Catalog_1": "Gl 177"
  },
  {
    "Astrid #": "ASTRID 000689",
    "HYG Star ID": 118277,
    "Gliese Catalog": "Gl 164",
    "Distance in Parsecs": 13.2802,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.884,
    "Spectral Type": "M3.5",
    "Color Index": 1.67,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.630659,
    "y": 7.200111,
    "z": 10.551799,
    "vx": 0.00004022,
    "vy": 0.00002747,
    "vz": -0.00003256,
    "rarad": 1.10381151385003,
    "decrad": 0.91826800501555,
    "pmrarad": -0.00000177330299936805,
    "pmdecrad": -0.000004039719689,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118277,
    "lum": 0.000611504982517028,
    "ra": 4.216249,
    "dec": 52.612881,
    "pmra": -365.77,
    "pmdec": -833.25,
    "rv": 0,
    "Asset Name": "ASTRID 000689",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Braided Bun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004022,
    "y_1": 0.00002747,
    "z_1": -0.00003256,
    "Distance in Parsecs_1": 13.2802,
    "HYG Star ID_1": 118277,
    "Gliese Catalog_1": "Gl 164"
  },
  {
    "Astrid #": "ASTRID 000690",
    "HYG Star ID": 117955,
    "Gliese Catalog": "NN 3001",
    "Distance in Parsecs": 13.2979,
    "mag": 14.9,
    "Absolute Visual Magnitude": 14.281,
    "Spectral Type": "DC9",
    "Color Index": 0.46,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.994187,
    "y": 0.128112,
    "z": -7.479679,
    "vx": -0.00002676,
    "vy": 0.00000925,
    "vz": -0.00003919,
    "rarad": 0.0116445513900027,
    "decrad": -0.59737098223615,
    "pmrarad": 7.18687800055555e-7,
    "pmdecrad": -0.000003564364723,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117955,
    "lum": 0.000168888469479091,
    "ra": 0.044479,
    "dec": -34.226836,
    "pmra": 148.24,
    "pmdec": -735.2,
    "rv": 0,
    "Asset Name": "ASTRID 000690",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Determined",
    "Hair": "Hachimaki",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002676,
    "y_1": 0.00000925,
    "z_1": -0.00003919,
    "Distance in Parsecs_1": 13.2979,
    "HYG Star ID_1": 117955,
    "Gliese Catalog_1": "NN 3001"
  },
  {
    "Astrid #": "ASTRID 000691",
    "HYG Star ID": 119108,
    "Gliese Catalog": "NN 3971",
    "Distance in Parsecs": 13.2979,
    "mag": 15.65,
    "Absolute Visual Magnitude": 15.031,
    "Spectral Type": "m",
    "Color Index": 1.95,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.725977,
    "y": -4.763978,
    "z": 12.2947,
    "vx": -0.00000788,
    "vy": 0.00002639,
    "vz": 0.00000912,
    "rarad": 4.36490129748492,
    "decrad": 1.1798780439022,
    "pmrarad": -0.00000123259030144444,
    "pmdecrad": 0.000001800118044,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119108,
    "lum": 0.0000846447448097698,
    "ra": 16.672695,
    "dec": 67.602032,
    "pmra": -254.24,
    "pmdec": 371.3,
    "rv": 0,
    "Asset Name": "ASTRID 000691",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Tiny Crown",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000788,
    "y_1": 0.00002639,
    "z_1": 0.00000912,
    "Distance in Parsecs_1": 13.2979,
    "HYG Star ID_1": 119108,
    "Gliese Catalog_1": "NN 3971"
  },
  {
    "Astrid #": "ASTRID 000692",
    "HYG Star ID": 119246,
    "Gliese Catalog": "Gl 732A",
    "Distance in Parsecs": 13.2979,
    "mag": 12.7,
    "Absolute Visual Magnitude": 12.081,
    "Spectral Type": "sdG0",
    "Color Index": 1.53,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": 2.412492,
    "y": -10.1063,
    "z": -8.299201,
    "vx": 0.00001159,
    "vy": 0.00004228,
    "vz": -0.00004814,
    "rarad": 4.94669328082186,
    "decrad": -0.6739773378866,
    "pmrarad": 0.00000158611643730555,
    "pmdecrad": -0.000004632617732,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119246,
    "base": "Gl 732",
    "lum": 0.00128115005627839,
    "ra": 18.894977,
    "dec": -38.616057,
    "pmra": 327.16,
    "pmdec": -955.55,
    "rv": 0,
    "Asset Name": "ASTRID 000692",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Fedora",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001159,
    "y_1": 0.00004228,
    "z_1": -0.00004814,
    "Distance in Parsecs_1": 13.2979,
    "HYG Star ID_1": 119246,
    "Gliese Catalog_1": "Gl 732A"
  },
  {
    "Astrid #": "ASTRID 000693",
    "HYG Star ID": 119247,
    "Gliese Catalog": "Gl 732B",
    "Distance in Parsecs": 13.2979,
    "mag": 16,
    "Absolute Visual Magnitude": 15.381,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 2.412442,
    "y": -10.106301,
    "z": -8.299214,
    "vx": 0.0000106,
    "vy": 0.00004225,
    "vz": -0.00004839,
    "rarad": 4.94668963259891,
    "decrad": -0.6739785550113,
    "pmrarad": 0.00000151315197838194,
    "pmdecrad": -0.000004656960226,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119246,
    "base": "Gl 732",
    "lum": 0.0000613196969715681,
    "ra": 18.894963,
    "dec": -38.616127,
    "pmra": 312.11,
    "pmdec": -960.57,
    "rv": 0,
    "Asset Name": "ASTRID 000693",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Unkempt",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000106,
    "y_1": 0.00004225,
    "z_1": -0.00004839,
    "Distance in Parsecs_1": 13.2979,
    "HYG Star ID_1": 119247,
    "Gliese Catalog_1": "Gl 732B"
  },
  {
    "Astrid #": "ASTRID 000694",
    "HYG Star ID": 31780,
    "Hipparcos Catalogue": 31862,
    "Gliese Catalog": "NN 3403",
    "Distance in Parsecs": 13.2996,
    "mag": 9.8,
    "Absolute Visual Magnitude": 9.181,
    "Spectral Type": "K7V",
    "Color Index": 1.377,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.292411,
    "y": 7.39997,
    "z": -10.974948,
    "vx": 0.00002493,
    "vy": 0.00000504,
    "vz": 4.6e-7,
    "rarad": 1.74371612580724,
    "decrad": -0.970571886402375,
    "pmrarad": -0.00000191225060021527,
    "pmdecrad": 6.16683e-8,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 31780,
    "lum": 0.0185182524363817,
    "ra": 6.660505,
    "dec": -55.609673,
    "pmra": -394.43,
    "pmdec": 12.72,
    "rv": 0,
    "Asset Name": "ASTRID 000694",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Bangs",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00002493,
    "y_1": 0.00000504,
    "z_1": 4.6e-7,
    "Distance in Parsecs_1": 13.2996,
    "HYG Star ID_1": 31780,
    "Hipparcos Catalogue_1": 31862,
    "Gliese Catalog_1": "NN 3403"
  },
  {
    "Astrid #": "ASTRID 000695",
    "HYG Star ID": 76597,
    "Hipparcos Catalogue": 76832,
    "Gliese Catalog": "Gl 597",
    "Distance in Parsecs": 13.3103,
    "mag": 12.22,
    "Absolute Visual Magnitude": 11.599,
    "Spectral Type": "M4",
    "Color Index": 1.65,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.833098,
    "y": -2.649211,
    "z": 12.914122,
    "vx": 0.00002068,
    "vy": -0.00005829,
    "vz": -0.00005761,
    "rarad": 4.1069890256923,
    "decrad": 1.32632437010185,
    "pmrarad": 0.00000377025054957638,
    "pmdecrad": -0.000003683759788,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 76597,
    "lum": 0.00199710086602818,
    "var": "UU",
    "var_min": 12.348,
    "var_max": 11.988,
    "ra": 15.687543,
    "dec": 75.992789,
    "pmra": 777.67,
    "pmdec": -759.83,
    "rv": -46.1,
    "Asset Name": "ASTRID 000695",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Neko Dreams",
    "Hair": "Bob",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00002068,
    "y_1": -0.00005829,
    "z_1": -0.00005761,
    "Distance in Parsecs_1": 13.3103,
    "HYG Star ID_1": 76597,
    "Hipparcos Catalogue_1": 76832,
    "Gliese Catalog_1": "Gl 597"
  },
  {
    "Astrid #": "ASTRID 000696",
    "HYG Star ID": 106470,
    "Hipparcos Catalogue": 106811,
    "Gliese Catalog": "Gl 835",
    "Distance in Parsecs": 13.3174,
    "mag": 9.83,
    "Absolute Visual Magnitude": 9.208,
    "Color Index": 1.495,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.597367,
    "y": -6.84533,
    "z": 6.195318,
    "vx": 0.00000974,
    "vy": 0.00003049,
    "vz": -0.00001063,
    "rarad": 5.66361879350185,
    "decrad": 0.483870588683866,
    "pmrarad": 0.00000228851449769444,
    "pmdecrad": -3.65064701e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 106470,
    "lum": 0.0180634208316997,
    "var_min": 9.887,
    "var_max": 9.777,
    "ra": 21.63343,
    "dec": 27.723743,
    "pmra": 472.04,
    "pmdec": -75.3,
    "rv": -13.3,
    "Asset Name": "ASTRID 000696",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Bangs",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000974,
    "y_1": 0.00003049,
    "z_1": -0.00001063,
    "Distance in Parsecs_1": 13.3174,
    "HYG Star ID_1": 106470,
    "Hipparcos Catalogue_1": 106811,
    "Gliese Catalog_1": "Gl 835"
  },
  {
    "Astrid #": "ASTRID 000697",
    "HYG Star ID": 118418,
    "Gliese Catalog": "GJ 1088",
    "Distance in Parsecs": 13.3333,
    "mag": 12.32,
    "Absolute Visual Magnitude": 11.695,
    "Spectral Type": "M3.5",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.462003,
    "y": 9.676115,
    "z": -9.163757,
    "vx": -0.00001042,
    "vy": 0.00005279,
    "vz": 0.00001458,
    "rarad": 1.61849566157147,
    "decrad": -0.75764098912525,
    "pmrarad": 5.92151429430555e-7,
    "pmdecrad": 0.000003538417495,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118418,
    "lum": 0.00182810021614274,
    "ra": 6.182198,
    "dec": -43.409631,
    "pmra": 122.14,
    "pmdec": 729.85,
    "rv": 28,
    "Asset Name": "ASTRID 000697",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Undercut",
    "Outfit": "Unit 01",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001042,
    "y_1": 0.00005279,
    "z_1": 0.00001458,
    "Distance in Parsecs_1": 13.3333,
    "HYG Star ID_1": 118418,
    "Gliese Catalog_1": "GJ 1088"
  },
  {
    "Astrid #": "ASTRID 000698",
    "HYG Star ID": 118460,
    "Gliese Catalog": "NN 3423",
    "Distance in Parsecs": 13.3333,
    "mag": 13.17,
    "Absolute Visual Magnitude": 12.545,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.993213,
    "y": 10.545314,
    "z": 7.590383,
    "vx": 0.00000701,
    "vy": -0.00000403,
    "vz": 0.00000837,
    "rarad": 1.84736967415504,
    "decrad": 0.60562994735125,
    "pmrarad": -4.23096899020833e-7,
    "pmdecrad": 7.63247025e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118460,
    "lum": 0.000835603018231247,
    "ra": 7.056432,
    "dec": 34.70004,
    "pmra": -87.27,
    "pmdec": 157.43,
    "rv": 0,
    "Asset Name": "ASTRID 000698",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Uwu",
    "Hair": "Ram Horns",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000701,
    "y_1": -0.00000403,
    "z_1": 0.00000837,
    "Distance in Parsecs_1": 13.3333,
    "HYG Star ID_1": 118460,
    "Gliese Catalog_1": "NN 3423"
  },
  {
    "Astrid #": "ASTRID 000699",
    "HYG Star ID": 118666,
    "Gliese Catalog": "NN 3586",
    "Distance in Parsecs": 13.3333,
    "mag": 13.47,
    "Absolute Visual Magnitude": 12.845,
    "Spectral Type": "k-m",
    "Color Index": 1.66,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.388842,
    "y": 3.866705,
    "z": 10.40339,
    "vx": -0.00002237,
    "vy": 0.00004454,
    "vz": -0.00003243,
    "rarad": 2.6595313931683,
    "decrad": 0.89507522626485,
    "pmrarad": -0.00000218277663396527,
    "pmdecrad": -0.000003889674703,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118666,
    "lum": 0.000633869711256926,
    "ra": 10.158662,
    "dec": 51.284033,
    "pmra": -450.23,
    "pmdec": -802.3,
    "rv": 0,
    "Asset Name": "ASTRID 000699",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Slick",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002237,
    "y_1": 0.00004454,
    "z_1": -0.00003243,
    "Distance in Parsecs_1": 13.3333,
    "HYG Star ID_1": 118666,
    "Gliese Catalog_1": "NN 3586"
  },
  {
    "Astrid #": "ASTRID 000700",
    "HYG Star ID": 119016,
    "Gliese Catalog": "NN 3900",
    "Distance in Parsecs": 13.3333,
    "mag": 12.58,
    "Absolute Visual Magnitude": 11.955,
    "Spectral Type": "M3",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.394087,
    "y": -9.932405,
    "z": -2.943388,
    "vx": -0.0000322,
    "vy": 0.00003102,
    "vz": -0.00001289,
    "rarad": 4.01073831543781,
    "decrad": -0.22258813001715,
    "pmrarad": -0.00000334691124371527,
    "pmdecrad": -9.91400343e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119016,
    "lum": 0.00143879857825584,
    "ra": 15.319892,
    "dec": -12.75336,
    "pmra": -690.35,
    "pmdec": -204.49,
    "rv": 0,
    "Asset Name": "ASTRID 000700",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "High Bun",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000322,
    "y_1": 0.00003102,
    "z_1": -0.00001289,
    "Distance in Parsecs_1": 13.3333,
    "HYG Star ID_1": 119016,
    "Gliese Catalog_1": "NN 3900"
  },
  {
    "Astrid #": "ASTRID 000701",
    "HYG Star ID": 119457,
    "Gliese Catalog": "NN 4239",
    "Distance in Parsecs": 13.3333,
    "mag": 14.64,
    "Absolute Visual Magnitude": 14.015,
    "Spectral Type": "dM5",
    "Color Index": 1.76,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.450041,
    "y": -6.817448,
    "z": -0.442586,
    "vx": 0.00004344,
    "vy": 0.00007031,
    "vz": 0.00003992,
    "rarad": 5.74614883502495,
    "decrad": -0.03320012078535,
    "pmrarad": 0.0000061981004990625,
    "pmdecrad": 0.000002996284293,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119457,
    "lum": 0.000215774440915266,
    "ra": 21.948672,
    "dec": -1.902227,
    "pmra": 1278.45,
    "pmdec": 618.03,
    "rv": 0,
    "Asset Name": "ASTRID 000701",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Fedora",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004344,
    "y_1": 0.00007031,
    "z_1": 0.00003992,
    "Distance in Parsecs_1": 13.3333,
    "HYG Star ID_1": 119457,
    "Gliese Catalog_1": "NN 4239"
  },
  {
    "Astrid #": "ASTRID 000702",
    "HYG Star ID": 118559,
    "Gliese Catalog": "Gl 319C",
    "Distance in Parsecs": 13.3422,
    "mag": 11.79,
    "Absolute Visual Magnitude": 11.164,
    "Spectral Type": "M3.5",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.583478,
    "y": 9.973174,
    "z": 2.214625,
    "vx": -0.00002914,
    "vy": 0.00001378,
    "vz": -0.00003693,
    "rarad": 2.28144400020177,
    "decrad": 0.16674389002275,
    "pmrarad": 9.821840354375e-7,
    "pmdecrad": -0.000003096199545,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 3,
    "comp_primary": 42629,
    "base": "Gl 319",
    "lum": 0.00298126100817224,
    "ra": 8.714474,
    "dec": 9.553721,
    "pmra": 202.59,
    "pmdec": -638.64,
    "rv": 22.4,
    "Asset Name": "ASTRID 000702",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Greasy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00002914,
    "y_1": 0.00001378,
    "z_1": -0.00003693,
    "Distance in Parsecs_1": 13.3422,
    "HYG Star ID_1": 118559,
    "Gliese Catalog_1": "Gl 319C"
  },
  {
    "Astrid #": "ASTRID 000703",
    "HYG Star ID": 119302,
    "Gliese Catalog": "Gl 767B",
    "Distance in Parsecs": 13.3511,
    "mag": 11.1,
    "Absolute Visual Magnitude": 10.472,
    "Spectral Type": "M2  :",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.068899,
    "y": -10.122082,
    "z": 7.077911,
    "vx": 0.00003197,
    "vy": 0.00000379,
    "vz": -0.00002422,
    "rarad": 5.1766370880087,
    "decrad": 0.5587704465633,
    "pmrarad": 0.00000226854017405555,
    "pmdecrad": -0.000001972008734,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 96983,
    "base": "Gl 767",
    "lum": 0.00563897280334949,
    "ra": 19.773297,
    "dec": 32.015188,
    "pmra": 467.92,
    "pmdec": -406.76,
    "rv": -3.5,
    "Asset Name": "ASTRID 000703",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Elegant",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00003197,
    "y_1": 0.00000379,
    "z_1": -0.00002422,
    "Distance in Parsecs_1": 13.3511,
    "HYG Star ID_1": 119302,
    "Gliese Catalog_1": "Gl 767B"
  },
  {
    "Astrid #": "ASTRID 000704",
    "HYG Star ID": 83505,
    "Hipparcos Catalogue": 83762,
    "Gliese Catalog": "Gl 655",
    "Distance in Parsecs": 13.3618,
    "mag": 11.61,
    "Absolute Visual Magnitude": 10.981,
    "Spectral Type": "M3",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.841689,
    "y": -12.097749,
    "z": 4.908669,
    "vx": -0.00001867,
    "vy": 0.00005242,
    "vz": -0.00002052,
    "rarad": 4.48167887314538,
    "decrad": 0.37618846451366,
    "pmrarad": -0.000002257583384875,
    "pmdecrad": -1.42341296e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83505,
    "lum": 0.00352858025828526,
    "var_min": 11.681,
    "var_max": 11.491,
    "ra": 17.118752,
    "dec": 21.554011,
    "pmra": -465.66,
    "pmdec": -29.36,
    "rv": -49.9,
    "Asset Name": "ASTRID 000704",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Shinobi",
    "Hair": "Bangs",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001867,
    "y_1": 0.00005242,
    "z_1": -0.00002052,
    "Distance in Parsecs_1": 13.3618,
    "HYG Star ID_1": 83505,
    "Hipparcos Catalogue_1": 83762,
    "Gliese Catalog_1": "Gl 655"
  },
  {
    "Astrid #": "ASTRID 000705",
    "HYG Star ID": 117990,
    "Gliese Catalog": "GJ 1012",
    "Distance in Parsecs": 13.369,
    "mag": 12.19,
    "Absolute Visual Magnitude": 11.56,
    "Spectral Type": "M4",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.177536,
    "y": 1.65655,
    "z": -1.551872,
    "vx": 0.00004808,
    "vy": -0.00001552,
    "vz": -0.00005786,
    "rarad": 0.125054702229819,
    "decrad": -0.11631943002275,
    "pmrarad": -0.00000160051540361805,
    "pmdecrad": -0.000003902420455,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117990,
    "lum": 0.00207014134879103,
    "ra": 0.477674,
    "dec": -6.664612,
    "pmra": -330.13,
    "pmdec": -804.93,
    "rv": 51,
    "Asset Name": "ASTRID 000705",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Medium Bob",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004808,
    "y_1": -0.00001552,
    "z_1": -0.00005786,
    "Distance in Parsecs_1": 13.369,
    "HYG Star ID_1": 117990,
    "Gliese Catalog_1": "GJ 1012"
  },
  {
    "Astrid #": "ASTRID 000706",
    "HYG Star ID": 119256,
    "Gliese Catalog": "Gl 742",
    "Distance in Parsecs": 13.369,
    "mag": 13.2,
    "Absolute Visual Magnitude": 12.57,
    "Spectral Type": "DXP5",
    "Color Index": 0.05,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 1.14891,
    "y": -4.274632,
    "z": 12.614979,
    "vx": -0.00000276,
    "vy": 0.00003235,
    "vz": 0.00001122,
    "rarad": 4.97491447034061,
    "decrad": 1.23333811923935,
    "pmrarad": 4.28526812243055e-7,
    "pmdecrad": 0.000002533524787,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119256,
    "lum": 0.000816582371358591,
    "ra": 19.002774,
    "dec": 70.665069,
    "pmra": 88.39,
    "pmdec": 522.58,
    "rv": 0,
    "Asset Name": "ASTRID 000706",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Braided Bun",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000276,
    "y_1": 0.00003235,
    "z_1": 0.00001122,
    "Distance in Parsecs_1": 13.369,
    "HYG Star ID_1": 119256,
    "Gliese Catalog_1": "Gl 742"
  },
  {
    "Astrid #": "ASTRID 000707",
    "HYG Star ID": 119319,
    "Gliese Catalog": "Gl 774A",
    "Distance in Parsecs": 13.4048,
    "mag": 11.35,
    "Absolute Visual Magnitude": 10.714,
    "Spectral Type": "m",
    "Color Index": 1.48,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.853729,
    "y": -4.746991,
    "z": -12.211049,
    "vx": -0.00000406,
    "vy": 0.00002092,
    "vz": -0.00008995,
    "rarad": 5.25362663316782,
    "decrad": -1.1449654822531,
    "pmrarad": 5.44203356423611e-7,
    "pmdecrad": -0.000004133725062,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 119319,
    "base": "Gl 774",
    "lum": 0.00451232113344336,
    "ra": 20.067376,
    "dec": -65.60169,
    "pmra": 112.25,
    "pmdec": -852.64,
    "rv": 72,
    "Asset Name": "ASTRID 000707",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Determined",
    "Hair": "Braided Bun",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00000406,
    "y_1": 0.00002092,
    "z_1": -0.00008995,
    "Distance in Parsecs_1": 13.4048,
    "HYG Star ID_1": 119319,
    "Gliese Catalog_1": "Gl 774A"
  },
  {
    "Astrid #": "ASTRID 000708",
    "HYG Star ID": 119320,
    "Gliese Catalog": "Gl 774B",
    "Distance in Parsecs": 13.4048,
    "mag": 12.82,
    "Absolute Visual Magnitude": 12.184,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.853304,
    "y": -4.746284,
    "z": -12.209233,
    "vx": -0.00001256,
    "vy": 0.00003505,
    "vz": -0.00005363,
    "rarad": 5.25362663316782,
    "decrad": -1.1449654822531,
    "pmrarad": 5.44203356423611e-7,
    "pmdecrad": -0.000004133725062,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119319,
    "base": "Gl 774",
    "lum": 0.001165198723025,
    "ra": 20.067376,
    "dec": -65.60169,
    "pmra": 112.25,
    "pmdec": -852.64,
    "rv": 33,
    "Asset Name": "ASTRID 000708",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Side Part",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001256,
    "y_1": 0.00003505,
    "z_1": -0.00005363,
    "Distance in Parsecs_1": 13.4048,
    "HYG Star ID_1": 119320,
    "Gliese Catalog_1": "Gl 774B"
  },
  {
    "Astrid #": "ASTRID 000709",
    "HYG Star ID": 67209,
    "Hipparcos Catalogue": 67422,
    "Henry Draper Catalogue": 120476,
    "Gliese Catalog": "Gl 528A",
    "Distance in Parsecs": 13.4084,
    "mag": 7.05,
    "Absolute Visual Magnitude": 6.413,
    "Spectral Type": "K2",
    "Color Index": 1.11,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.621222,
    "y": -5.474195,
    "z": 6.083013,
    "vx": 0.00000168,
    "vy": 0.00003212,
    "vz": -0.00001481,
    "rarad": 3.6174876620288,
    "decrad": 0.470888272637539,
    "pmrarad": -0.00000207199670795833,
    "pmdecrad": -4.36041424e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 67209,
    "base": "Gl 528",
    "lum": 0.237028189912977,
    "ra": 13.817785,
    "dec": 26.979911,
    "pmra": -427.38,
    "pmdec": -89.94,
    "rv": -20.7,
    "Asset Name": "ASTRID 000709",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Undercut",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000168,
    "y_1": 0.00003212,
    "z_1": -0.00001481,
    "Distance in Parsecs_1": 13.4084,
    "HYG Star ID_1": 67209,
    "Hipparcos Catalogue_1": 67422,
    "Henry Draper Catalogue_1": 120476,
    "Gliese Catalog_1": "Gl 528A"
  },
  {
    "Astrid #": "ASTRID 000710",
    "HYG Star ID": 19289,
    "Hipparcos Catalogue": 19337,
    "Henry Draper Catalogue": 281621,
    "Distance in Parsecs": 13.4463,
    "mag": 10.18,
    "Absolute Visual Magnitude": 9.537,
    "Spectral Type": "M1",
    "Color Index": 1.495,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.228784,
    "y": 9.898763,
    "z": 7.448307,
    "vx": -0.00003237,
    "vy": 0.00001194,
    "vz": 0.00000686,
    "rarad": 1.08481926585681,
    "decrad": 0.587077053008783,
    "pmrarad": 0.00000254352649366666,
    "pmdecrad": 6.13143861e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 19289,
    "lum": 0.013341356829031,
    "ra": 4.143704,
    "dec": 33.637037,
    "pmra": 524.64,
    "pmdec": 126.47,
    "rv": 0,
    "Asset Name": "ASTRID 000710",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Slick",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00003237,
    "y_1": 0.00001194,
    "z_1": 0.00000686,
    "Distance in Parsecs_1": 13.4463,
    "HYG Star ID_1": 19289,
    "Hipparcos Catalogue_1": 19337,
    "Henry Draper Catalogue_1": 281621
  },
  {
    "Astrid #": "ASTRID 000711",
    "HYG Star ID": 118207,
    "Gliese Catalog": "Gl 130.1B",
    "Distance in Parsecs": 13.4608,
    "mag": 11.2,
    "Absolute Visual Magnitude": 10.555,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.652856,
    "y": 5.362845,
    "z": 11.437412,
    "vx": -0.00000268,
    "vy": 0.000044,
    "vz": 0.00000707,
    "rarad": 0.856064725555793,
    "decrad": 1.01524841583295,
    "pmrarad": 0.00000229341111586805,
    "pmdecrad": -0.000001709443341,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 2,
    "comp_primary": 15182,
    "base": "Gl 130.1",
    "lum": 0.00522396188999119,
    "ra": 3.269926,
    "dec": 58.169449,
    "pmra": 473.05,
    "pmdec": -352.6,
    "rv": 22.1,
    "Asset Name": "ASTRID 000711",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Middle Part",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000268,
    "y_1": 0.000044,
    "z_1": 0.00000707,
    "Distance in Parsecs_1": 13.4608,
    "HYG Star ID_1": 118207,
    "Gliese Catalog_1": "Gl 130.1B"
  },
  {
    "Astrid #": "ASTRID 000712",
    "HYG Star ID": 118751,
    "Gliese Catalog": "Gl 427",
    "Distance in Parsecs": 13.4771,
    "mag": 14.24,
    "Absolute Visual Magnitude": 13.592,
    "Spectral Type": "DA7",
    "Color Index": 0.28,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -12.40108,
    "y": 1.951838,
    "z": 4.909793,
    "vx": -0.00003725,
    "vy": 0.00007529,
    "vz": 0.00001922,
    "rarad": 2.98549938624177,
    "decrad": 0.37281217342105,
    "pmrarad": -0.00000509049516446527,
    "pmdecrad": 1.7768421e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118751,
    "lum": 0.000318566423657311,
    "ra": 11.403768,
    "dec": 21.360564,
    "pmra": -1049.99,
    "pmdec": 3.66,
    "rv": 51,
    "Asset Name": "ASTRID 000712",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Shinobi",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003725,
    "y_1": 0.00007529,
    "z_1": 0.00001922,
    "Distance in Parsecs_1": 13.4771,
    "HYG Star ID_1": 118751,
    "Gliese Catalog_1": "Gl 427"
  },
  {
    "Astrid #": "ASTRID 000713",
    "HYG Star ID": 46720,
    "Hipparcos Catalogue": 46853,
    "Henry Draper Catalogue": 82328,
    "Harvard Revised Catalogue": 3775,
    "Gliese Catalog": "Gl 354A",
    "Distance in Parsecs": 13.4789,
    "mag": 3.17,
    "Absolute Visual Magnitude": 2.522,
    "Spectral Type": "F6IV",
    "Color Index": 0.475,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.693935,
    "y": 5.005096,
    "z": 10.57472,
    "vx": 0.00000729,
    "vy": 0.00007181,
    "vz": -0.00000942,
    "rarad": 2.49958584958112,
    "decrad": 0.901939037206861,
    "pmrarad": -0.00000459147644306944,
    "pmdecrad": -0.000002596662073,
    "bayer": "The",
    "flam": 25,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 46720,
    "base": "Gl 354",
    "lum": 8.53493071613548,
    "bf": "25The UMa",
    "ra": 9.547715,
    "dec": 51.6773,
    "pmra": -947.06,
    "pmdec": -535.6,
    "rv": 15.3,
    "Asset Name": "ASTRID 000713",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Middle Part",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000729,
    "y_1": 0.00007181,
    "z_1": -0.00000942,
    "Distance in Parsecs_1": 13.4789,
    "HYG Star ID_1": 46720,
    "Hipparcos Catalogue_1": 46853,
    "Henry Draper Catalogue_1": 82328,
    "Harvard Revised Catalogue_1": 3775,
    "Gliese Catalog_1": "Gl 354A"
  },
  {
    "Astrid #": "ASTRID 000714",
    "HYG Star ID": 118634,
    "Gliese Catalog": "Gl 354B",
    "Distance in Parsecs": 13.4862,
    "mag": 13.8,
    "Absolute Visual Magnitude": 13.151,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.697339,
    "y": 5.007531,
    "z": 10.580543,
    "vx": 0.00001496,
    "vy": 0.00006617,
    "vz": -0.00002182,
    "rarad": 2.49971260791712,
    "decrad": 0.90196341612685,
    "pmrarad": -0.00000459482165746527,
    "pmdecrad": -0.000002610217463,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 46720,
    "base": "Gl 354",
    "lum": 0.000478189460665907,
    "ra": 9.548199,
    "dec": 51.678697,
    "pmra": -947.75,
    "pmdec": -538.4,
    "rv": 0,
    "Asset Name": "ASTRID 000714",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "High Bun",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001496,
    "y_1": 0.00006617,
    "z_1": -0.00002182,
    "Distance in Parsecs_1": 13.4862,
    "HYG Star ID_1": 118634,
    "Gliese Catalog_1": "Gl 354B"
  },
  {
    "Astrid #": "ASTRID 000715",
    "HYG Star ID": 7499,
    "Hipparcos Catalogue": 7513,
    "Henry Draper Catalogue": 9826,
    "Harvard Revised Catalogue": 458,
    "Gliese Catalog": "Gl 61",
    "Distance in Parsecs": 13.4916,
    "mag": 4.1,
    "Absolute Visual Magnitude": 3.45,
    "Spectral Type": "F8V",
    "Color Index": 0.536,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.229933,
    "y": 4.147969,
    "z": 8.92295,
    "vx": -4.2e-7,
    "vy": -0.00001256,
    "vz": -0.0000381,
    "rarad": 0.422360683374025,
    "decrad": 0.722661584099714,
    "pmrarad": -8.36642968534722e-7,
    "pmdecrad": -0.000001847188604,
    "bayer": "Ups",
    "flam": 50,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 7499,
    "lum": 3.63078054770101,
    "bf": "50Ups And",
    "ra": 1.613299,
    "dec": 41.405459,
    "pmra": -172.57,
    "pmdec": -381.01,
    "rv": -28.7,
    "Asset Name": "ASTRID 000715",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Bangs",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -4.2e-7,
    "y_1": -0.00001256,
    "z_1": -0.0000381,
    "Distance in Parsecs_1": 13.4916,
    "HYG Star ID_1": 7499,
    "Hipparcos Catalogue_1": 7513,
    "Henry Draper Catalogue_1": 9826,
    "Harvard Revised Catalogue_1": 458,
    "Gliese Catalog_1": "Gl 61"
  },
  {
    "Astrid #": "ASTRID 000716",
    "HYG Star ID": 117963,
    "Gliese Catalog": "NN 3009",
    "Distance in Parsecs": 13.5135,
    "mag": 12.07,
    "Absolute Visual Magnitude": 11.416,
    "Spectral Type": "M4",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.337478,
    "y": 0.268395,
    "z": -11.344782,
    "vx": -0.00000121,
    "vy": -0.00002351,
    "vz": -0.00000134,
    "rarad": 0.0366352540789753,
    "decrad": -0.996389383769349,
    "pmrarad": -0.00000173577842049305,
    "pmdecrad": -1.82435387e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117963,
    "lum": 0.00236374160811629,
    "ra": 0.139936,
    "dec": -57.088906,
    "pmra": -358.03,
    "pmdec": -37.63,
    "rv": 0,
    "Asset Name": "ASTRID 000716",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Lion Mane",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000121,
    "y_1": -0.00002351,
    "z_1": -0.00000134,
    "Distance in Parsecs_1": 13.5135,
    "HYG Star ID_1": 117963,
    "Gliese Catalog_1": "NN 3009"
  },
  {
    "Astrid #": "ASTRID 000717",
    "HYG Star ID": 118801,
    "Gliese Catalog": "NN 3709B",
    "Distance in Parsecs": 13.5135,
    "mag": 12.62,
    "Absolute Visual Magnitude": 11.966,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.685408,
    "y": -0.624923,
    "z": -4.615688,
    "vx": 0.00000289,
    "vy": 0.00001289,
    "vz": -0.00000969,
    "rarad": 3.190819044775,
    "decrad": -0.34857760186815,
    "pmrarad": -9.41944499951388e-7,
    "pmdecrad": -7.62757363e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118801,
    "base": "NN 3709",
    "lum": 0.00142429516497329,
    "ra": 12.188031,
    "dec": -19.972025,
    "pmra": -194.29,
    "pmdec": -157.33,
    "rv": 0,
    "Asset Name": "ASTRID 000717",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Heartbreaker",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000289,
    "y_1": 0.00001289,
    "z_1": -0.00000969,
    "Distance in Parsecs_1": 13.5135,
    "HYG Star ID_1": 118801,
    "Gliese Catalog_1": "NN 3709B"
  },
  {
    "Astrid #": "ASTRID 000718",
    "HYG Star ID": 110097,
    "Hipparcos Catalogue": 110443,
    "Henry Draper Catalogue": 211970,
    "Gliese Catalog": "GJ 1267",
    "Distance in Parsecs": 13.5318,
    "mag": 8.98,
    "Absolute Visual Magnitude": 8.323,
    "Spectral Type": "K7V",
    "Color Index": 1.329,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.143656,
    "y": -3.24539,
    "z": -11.024756,
    "vx": 0.00000629,
    "vy": -0.00001621,
    "vz": 0.00000885,
    "rarad": 5.85676378907955,
    "decrad": -0.952262419860087,
    "pmrarad": -8.98505194173611e-7,
    "pmdecrad": 0.00000112801599,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 110097,
    "lum": 0.0408131391596971,
    "ra": 22.37119,
    "dec": -54.560618,
    "pmra": -185.33,
    "pmdec": 232.67,
    "rv": 0,
    "Asset Name": "ASTRID 000718",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Braid",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00000629,
    "y_1": -0.00001621,
    "z_1": 0.00000885,
    "Distance in Parsecs_1": 13.5318,
    "HYG Star ID_1": 110097,
    "Hipparcos Catalogue_1": 110443,
    "Henry Draper Catalogue_1": 211970,
    "Gliese Catalog_1": "GJ 1267"
  },
  {
    "Astrid #": "ASTRID 000719",
    "HYG Star ID": 28298,
    "Hipparcos Catalogue": 28368,
    "Gliese Catalog": "NN 3371A",
    "Distance in Parsecs": 13.5336,
    "mag": 10.25,
    "Absolute Visual Magnitude": 9.593,
    "Spectral Type": "M1",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.01141,
    "y": 7.052533,
    "z": 11.550757,
    "vx": -5.4e-7,
    "vy": 0.00001393,
    "vz": -0.0000085,
    "rarad": 1.5691781592465,
    "decrad": 1.02264118299987,
    "pmrarad": 4.17424578958333e-8,
    "pmdecrad": -0.000001205828586,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 28298,
    "base": "NN 3371",
    "lum": 0.0126706822499854,
    "var": "EG",
    "var_min": 10.445,
    "var_max": 10.205,
    "ra": 5.993819,
    "dec": 58.593024,
    "pmra": 8.61,
    "pmdec": -248.72,
    "rv": 0,
    "Asset Name": "ASTRID 000719",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Determined",
    "Hair": "Bob",
    "Outfit": "Astro",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -5.4e-7,
    "y_1": 0.00001393,
    "z_1": -0.0000085,
    "Distance in Parsecs_1": 13.5336,
    "HYG Star ID_1": 28298,
    "Hipparcos Catalogue_1": 28368,
    "Gliese Catalog_1": "NN 3371A"
  },
  {
    "Astrid #": "ASTRID 000720",
    "HYG Star ID": 111606,
    "Hipparcos Catalogue": 111960,
    "Henry Draper Catalogue": 214749,
    "Gliese Catalog": "Gl 868",
    "Distance in Parsecs": 13.5501,
    "mag": 7.83,
    "Absolute Visual Magnitude": 7.17,
    "Spectral Type": "K4/K5V",
    "Color Index": 1.143,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.075688,
    "y": -3.991693,
    "z": -6.708276,
    "vx": 0.00000877,
    "vy": 0.00002339,
    "vz": -0.0000015,
    "rarad": 5.93727196696333,
    "decrad": -0.517917379977251,
    "pmrarad": 0.00000184331009484027,
    "pmdecrad": -8.4454543e-8,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 111606,
    "lum": 0.118032063565172,
    "ra": 22.678708,
    "dec": -29.67448,
    "pmra": 380.21,
    "pmdec": -17.42,
    "rv": 1,
    "Asset Name": "ASTRID 000720",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Einstein Tongue",
    "Hair": "Middle Part",
    "Outfit": "Samurai",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000877,
    "y_1": 0.00002339,
    "z_1": -0.0000015,
    "Distance in Parsecs_1": 13.5501,
    "HYG Star ID_1": 111606,
    "Hipparcos Catalogue_1": 111960,
    "Henry Draper Catalogue_1": 214749,
    "Gliese Catalog_1": "Gl 868"
  },
  {
    "Astrid #": "ASTRID 000721",
    "HYG Star ID": 119153,
    "Gliese Catalog": "NN 4006",
    "Distance in Parsecs": 13.5685,
    "mag": 10.9,
    "Absolute Visual Magnitude": 10.237,
    "Color Index": 0.67,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.994294,
    "y": -13.414334,
    "z": 0.427362,
    "vx": 1e-8,
    "vy": 8e-8,
    "vz": 0.00000263,
    "rarad": 4.564801099,
    "decrad": 0.0315018242736,
    "pmrarad": 0,
    "pmdecrad": 1.93925472e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 119153,
    "lum": 0.00700164359372974,
    "ra": 17.436256,
    "dec": 1.804922,
    "pmra": 0,
    "pmdec": 40,
    "rv": 0,
    "Asset Name": "ASTRID 000721",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Arrogant",
    "Hair": "High Bun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 1e-8,
    "y_1": 8e-8,
    "z_1": 0.00000263,
    "Distance in Parsecs_1": 13.5685,
    "HYG Star ID_1": 119153,
    "Gliese Catalog_1": "NN 4006"
  },
  {
    "Astrid #": "ASTRID 000722",
    "HYG Star ID": 8055,
    "Hipparcos Catalogue": 8070,
    "Henry Draper Catalogue": 10436,
    "Gliese Catalog": "Gl 69",
    "Distance in Parsecs": 13.5777,
    "mag": 8.42,
    "Absolute Visual Magnitude": 7.756,
    "Spectral Type": "K5V",
    "Color Index": 1.202,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.386964,
    "y": 2.618057,
    "z": 12.184745,
    "vx": 0.00002168,
    "vy": -0.00001831,
    "vz": -0.00006343,
    "rarad": 0.452405090412547,
    "decrad": 1.11392848046862,
    "pmrarad": -0.00000191103856601388,
    "pmdecrad": -0.000002820403586,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 8055,
    "lum": 0.0688018316143037,
    "ra": 1.72806,
    "dec": 63.823401,
    "pmra": -394.18,
    "pmdec": -581.75,
    "rv": -50.7,
    "Asset Name": "ASTRID 000722",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Manbun",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00002168,
    "y_1": -0.00001831,
    "z_1": -0.00006343,
    "Distance in Parsecs_1": 13.5777,
    "HYG Star ID_1": 8055,
    "Hipparcos Catalogue_1": 8070,
    "Henry Draper Catalogue_1": 10436,
    "Gliese Catalog_1": "Gl 69"
  },
  {
    "Astrid #": "ASTRID 000723",
    "HYG Star ID": 12066,
    "Hipparcos Catalogue": 12097,
    "Gliese Catalog": "Gl 104",
    "Distance in Parsecs": 13.5943,
    "mag": 10.68,
    "Absolute Visual Magnitude": 10.013,
    "Spectral Type": "M2",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.917443,
    "y": 8.022999,
    "z": 4.698446,
    "vx": -0.00002459,
    "vy": 0.00000146,
    "vz": -0.00001656,
    "rarad": 0.68019106974796,
    "decrad": 0.352903552184999,
    "pmrarad": 0.00000122134262405555,
    "pmdecrad": -6.80436e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 12066,
    "lum": 0.00860597341192821,
    "ra": 2.598139,
    "dec": 20.219884,
    "pmra": 251.92,
    "pmdec": -140.35,
    "rv": -22.3,
    "Asset Name": "ASTRID 000723",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "Headband",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00002459,
    "y_1": 0.00000146,
    "z_1": -0.00001656,
    "Distance in Parsecs_1": 13.5943,
    "HYG Star ID_1": 12066,
    "Hipparcos Catalogue_1": 12097,
    "Gliese Catalog_1": "Gl 104"
  },
  {
    "Astrid #": "ASTRID 000724",
    "HYG Star ID": 16670,
    "Hipparcos Catalogue": 16711,
    "Henry Draper Catalogue": 22496,
    "Gliese Catalog": "Gl 146",
    "Distance in Parsecs": 13.6073,
    "mag": 8.57,
    "Absolute Visual Magnitude": 7.901,
    "Spectral Type": "K7V",
    "Color Index": 1.31,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.339596,
    "y": 7.283334,
    "z": -10.178661,
    "vx": -0.00000472,
    "vy": 0.00003869,
    "vz": -0.00000146,
    "rarad": 0.938174114071054,
    "decrad": -0.845073439602975,
    "pmrarad": 0.00000196126526331944,
    "pmdecrad": 0.00000149055966,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 16670,
    "lum": 0.0602004863684458,
    "ra": 3.583561,
    "dec": -48.419141,
    "pmra": 404.54,
    "pmdec": 307.45,
    "rv": 19.5,
    "Asset Name": "ASTRID 000724",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Elegant",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": -0.00000472,
    "y_1": 0.00003869,
    "z_1": -0.00000146,
    "Distance in Parsecs_1": 13.6073,
    "HYG Star ID_1": 16670,
    "Hipparcos Catalogue_1": 16711,
    "Henry Draper Catalogue_1": 22496,
    "Gliese Catalog_1": "Gl 146"
  },
  {
    "Astrid #": "ASTRID 000725",
    "HYG Star ID": 119488,
    "Gliese Catalog": "Gl 853B",
    "Distance in Parsecs": 13.611,
    "mag": 9.9,
    "Absolute Visual Magnitude": 9.231,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.289631,
    "y": -3.466212,
    "z": -10.959288,
    "vx": -0.00001835,
    "vy": 0.00004027,
    "vz": -0.00002495,
    "rarad": 5.83925819960888,
    "decrad": -0.9359768270558,
    "pmrarad": 0.00000209289217759027,
    "pmdecrad": -0.000003091201116,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 2,
    "comp_primary": 109763,
    "base": "Gl 853",
    "lum": 0.0176847937828179,
    "ra": 22.304323,
    "dec": -53.627522,
    "pmra": 431.69,
    "pmdec": -637.61,
    "rv": 0,
    "Asset Name": "ASTRID 000725",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Antlers",
    "Outfit": "Sharky",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00001835,
    "y_1": 0.00004027,
    "z_1": -0.00002495,
    "Distance in Parsecs_1": 13.611,
    "HYG Star ID_1": 119488,
    "Gliese Catalog_1": "Gl 853B"
  },
  {
    "Astrid #": "ASTRID 000726",
    "HYG Star ID": 96983,
    "Hipparcos Catalogue": 97292,
    "Henry Draper Catalogue": 331161,
    "Gliese Catalog": "Gl 767A",
    "Distance in Parsecs": 13.6129,
    "mag": 9.74,
    "Absolute Visual Magnitude": 9.07,
    "Spectral Type": "M1+...",
    "Color Index": 1.517,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.168078,
    "y": -10.320545,
    "z": 7.217152,
    "vx": 0.00003187,
    "vy": 0.00000487,
    "vz": -0.00002435,
    "rarad": 5.17663788234187,
    "decrad": 0.55880296368016,
    "pmrarad": 0.00000225375335679861,
    "pmdecrad": -0.000001902893696,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96983,
    "base": "Gl 767",
    "lum": 0.0205116217882556,
    "var_min": 9.807,
    "var_max": 9.677,
    "ra": 19.7733,
    "dec": 32.017051,
    "pmra": 464.87,
    "pmdec": -392.5,
    "rv": -4.4,
    "Asset Name": "ASTRID 000726",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Pixie",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00003187,
    "y_1": 0.00000487,
    "z_1": -0.00002435,
    "Distance in Parsecs_1": 13.6129,
    "HYG Star ID_1": 96983,
    "Hipparcos Catalogue_1": 97292,
    "Henry Draper Catalogue_1": 331161,
    "Gliese Catalog_1": "Gl 767A"
  },
  {
    "Astrid #": "ASTRID 000727",
    "HYG Star ID": 23463,
    "Hipparcos Catalogue": 23518,
    "Gliese Catalog": "Gl 184",
    "Distance in Parsecs": 13.6221,
    "mag": 9.96,
    "Absolute Visual Magnitude": 9.289,
    "Spectral Type": "M0",
    "Color Index": 1.389,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.998265,
    "y": 7.925906,
    "z": 10.89792,
    "vx": -0.00005374,
    "vy": 0.00013908,
    "vz": -0.00000691,
    "rarad": 1.3237881907557,
    "decrad": 0.927266725317996,
    "pmrarad": 0.00000632260065222916,
    "pmdecrad": -0.000007452992229,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 23463,
    "lum": 0.0167648626607389,
    "var_min": 10.012,
    "var_max": 9.902,
    "ra": 5.056498,
    "dec": 53.12847,
    "pmra": 1304.13,
    "pmdec": -1537.29,
    "rv": 66,
    "Asset Name": "ASTRID 000727",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Ponytail",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00005374,
    "y_1": 0.00013908,
    "z_1": -0.00000691,
    "Distance in Parsecs_1": 13.6221,
    "HYG Star ID_1": 23463,
    "Hipparcos Catalogue_1": 23518,
    "Gliese Catalog_1": "Gl 184"
  },
  {
    "Astrid #": "ASTRID 000728",
    "HYG Star ID": 83731,
    "Hipparcos Catalogue": 83990,
    "Henry Draper Catalogue": 154577,
    "Gliese Catalog": "Gl 656",
    "Distance in Parsecs": 13.6221,
    "mag": 7.38,
    "Absolute Visual Magnitude": 6.709,
    "Spectral Type": "K0V",
    "Color Index": 0.889,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.436698,
    "y": -6.503692,
    "z": -11.88283,
    "vx": -0.00000369,
    "vy": -0.00003849,
    "vz": 0.00001119,
    "rarad": 4.49497241784395,
    "decrad": -1.05991700512956,
    "pmrarad": 3.44896452347222e-7,
    "pmdecrad": 0.00000285952805,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 83731,
    "lum": 0.180467914655672,
    "ra": 17.16953,
    "dec": -60.728771,
    "pmra": 71.14,
    "pmdec": 589.82,
    "rv": 8.8,
    "Asset Name": "ASTRID 000728",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Elegant",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -0.00000369,
    "y_1": -0.00003849,
    "z_1": 0.00001119,
    "Distance in Parsecs_1": 13.6221,
    "HYG Star ID_1": 83731,
    "Hipparcos Catalogue_1": 83990,
    "Henry Draper Catalogue_1": 154577,
    "Gliese Catalog_1": "Gl 656"
  },
  {
    "Astrid #": "ASTRID 000729",
    "HYG Star ID": 50199,
    "Hipparcos Catalogue": 50341,
    "Gliese Catalog": "Gl 386",
    "Distance in Parsecs": 13.6426,
    "mag": 10.99,
    "Absolute Visual Magnitude": 10.316,
    "Spectral Type": "M0",
    "Color Index": 1.506,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.014959,
    "y": 5.81047,
    "z": -2.827516,
    "vx": 0.00003748,
    "vy": 0.00001253,
    "vz": -0.00003485,
    "rarad": 2.6911489299071,
    "decrad": -0.208772420614613,
    "pmrarad": -0.00000202298204485416,
    "pmdecrad": -0.000002929098813,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 50199,
    "lum": 0.0065102849843486,
    "var_min": 11.048,
    "var_max": 10.938,
    "ra": 10.279432,
    "dec": -11.961779,
    "pmra": -417.27,
    "pmdec": -604.17,
    "rv": -20,
    "Asset Name": "ASTRID 000729",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Unkempt",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00003748,
    "y_1": 0.00001253,
    "z_1": -0.00003485,
    "Distance in Parsecs_1": 13.6426,
    "HYG Star ID_1": 50199,
    "Hipparcos Catalogue_1": 50341,
    "Gliese Catalog_1": "Gl 386"
  },
  {
    "Astrid #": "ASTRID 000730",
    "HYG Star ID": 119034,
    "Gliese Catalog": "GJ 1194A",
    "Distance in Parsecs": 13.6426,
    "mag": 12.48,
    "Absolute Visual Magnitude": 11.806,
    "Spectral Type": "m",
    "Color Index": 1.57,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.672725,
    "y": -8.105364,
    "z": 9.385795,
    "vx": 0.00010046,
    "vy": 0.00000843,
    "vz": -0.00009245,
    "rarad": 4.10164071271369,
    "decrad": 0.7590818817466,
    "pmrarad": 0.00000567697427383333,
    "pmdecrad": -0.000001660065068,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119034,
    "base": "GJ 1194",
    "lum": 0.00165044098565227,
    "ra": 15.667113,
    "dec": 43.492188,
    "pmra": 1170.96,
    "pmdec": -342.41,
    "rv": -108,
    "Asset Name": "ASTRID 000730",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Ponytail",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00010046,
    "y_1": 0.00000843,
    "z_1": -0.00009245,
    "Distance in Parsecs_1": 13.6426,
    "HYG Star ID_1": 119034,
    "Gliese Catalog_1": "GJ 1194A"
  },
  {
    "Astrid #": "ASTRID 000731",
    "HYG Star ID": 119035,
    "Gliese Catalog": "GJ 1194B",
    "Distance in Parsecs": 13.6426,
    "mag": 13.8,
    "Absolute Visual Magnitude": 13.126,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.674589,
    "y": -8.10928,
    "z": 9.389312,
    "vx": 0.00005449,
    "vy": -0.00005719,
    "vz": -0.00001643,
    "rarad": 4.10171345971369,
    "decrad": 0.759053080746599,
    "pmrarad": 0.00000567697427383333,
    "pmdecrad": -0.000001660065068,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119034,
    "base": "GJ 1194",
    "lum": 0.000489327924081937,
    "ra": 15.667391,
    "dec": 43.490538,
    "pmra": 1170.96,
    "pmdec": -342.41,
    "rv": 0,
    "Asset Name": "ASTRID 000731",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Cute Neko",
    "Hair": "Honest Lad",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005449,
    "y_1": -0.00005719,
    "z_1": -0.00001643,
    "Distance in Parsecs_1": 13.6426,
    "HYG Star ID_1": 119035,
    "Gliese Catalog_1": "GJ 1194B"
  },
  {
    "Astrid #": "ASTRID 000732",
    "HYG Star ID": 60903,
    "Hipparcos Catalogue": 61094,
    "Gliese Catalog": "Gl 471",
    "Distance in Parsecs": 13.6463,
    "mag": 9.74,
    "Absolute Visual Magnitude": 9.065,
    "Spectral Type": "M0:",
    "Color Index": 1.441,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.360166,
    "y": -1.833881,
    "z": 2.090212,
    "vx": -0.00002998,
    "vy": 0.00003837,
    "vz": -0.00003111,
    "rarad": 3.27800535763443,
    "decrad": 0.153774029860497,
    "pmrarad": -0.00000308438463569444,
    "pmdecrad": -0.000002527673086,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 60903,
    "lum": 0.0206062991327,
    "ra": 12.521058,
    "dec": 8.810603,
    "pmra": -636.2,
    "pmdec": -521.37,
    "rv": 19,
    "Asset Name": "ASTRID 000732",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined Neko",
    "Hair": "Angelic",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002998,
    "y_1": 0.00003837,
    "z_1": -0.00003111,
    "Distance in Parsecs_1": 13.6463,
    "HYG Star ID_1": 60903,
    "Hipparcos Catalogue_1": 61094,
    "Gliese Catalog_1": "Gl 471"
  },
  {
    "Astrid #": "ASTRID 000733",
    "HYG Star ID": 118926,
    "Gliese Catalog": "Gl 528B",
    "Distance in Parsecs": 13.6519,
    "mag": 8.03,
    "Absolute Visual Magnitude": 7.354,
    "Spectral Type": "dK6",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -10.813751,
    "y": -5.572951,
    "z": 6.192753,
    "vx": 8.1e-7,
    "vy": 0.00003398,
    "vz": -0.00001511,
    "rarad": 3.61746417769267,
    "decrad": 0.4708611771111,
    "pmrarad": -0.00000218534614647222,
    "pmdecrad": -4.44617778e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 67209,
    "base": "Gl 528",
    "lum": 0.0996322641954417,
    "ra": 13.817695,
    "dec": 26.978358,
    "pmra": -450.76,
    "pmdec": -91.71,
    "rv": -20.9,
    "Asset Name": "ASTRID 000733",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Slick",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 8.1e-7,
    "y_1": 0.00003398,
    "z_1": -0.00001511,
    "Distance in Parsecs_1": 13.6519,
    "HYG Star ID_1": 118926,
    "Gliese Catalog_1": "Gl 528B"
  },
  {
    "Astrid #": "ASTRID 000734",
    "HYG Star ID": 118058,
    "Gliese Catalog": "GJ 1035",
    "Distance in Parsecs": 13.6612,
    "mag": 14.77,
    "Absolute Visual Magnitude": 14.093,
    "Spectral Type": "m",
    "Color Index": 1.79,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.307277,
    "y": 0.473484,
    "z": 13.590263,
    "vx": -0.00000616,
    "vy": -0.00007119,
    "vz": 0.00000309,
    "rarad": 0.349585665686139,
    "decrad": 1.46884413905775,
    "pmrarad": -0.00000474152627720138,
    "pmdecrad": 0.000002221101155,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118058,
    "lum": 0.000200816780418398,
    "ra": 1.335319,
    "dec": 84.15857,
    "pmra": -978.01,
    "pmdec": 458.13,
    "rv": 0,
    "Asset Name": "ASTRID 000734",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Medium Bob",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000616,
    "y_1": -0.00007119,
    "z_1": 0.00000309,
    "Distance in Parsecs_1": 13.6612,
    "HYG Star ID_1": 118058,
    "Gliese Catalog_1": "GJ 1035"
  },
  {
    "Astrid #": "ASTRID 000735",
    "HYG Star ID": 543,
    "Hipparcos Catalogue": 544,
    "Henry Draper Catalogue": 166,
    "Harvard Revised Catalogue": 8,
    "Gliese Catalog": "Gl 5",
    "Distance in Parsecs": 13.6705,
    "mag": 6.07,
    "Absolute Visual Magnitude": 5.391,
    "Spectral Type": "K0V",
    "Color Index": 0.752,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.948982,
    "y": 0.344882,
    "z": 6.632053,
    "vx": 9e-8,
    "vy": 0.00002519,
    "vz": -0.00001306,
    "rarad": 0.0288526285096066,
    "decrad": 0.506520757603726,
    "pmrarad": 0.00000184200109790277,
    "pmdecrad": -8.64616717e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 543,
    "lum": 0.607575146105741,
    "var": "V439",
    "var_min": 6.091,
    "var_max": 6.051,
    "ra": 0.110209,
    "dec": 29.021502,
    "pmra": 379.94,
    "pmdec": -178.34,
    "rv": -5.5,
    "Asset Name": "ASTRID 000735",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Afro",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 9e-8,
    "y_1": 0.00002519,
    "z_1": -0.00001306,
    "Distance in Parsecs_1": 13.6705,
    "HYG Star ID_1": 543,
    "Hipparcos Catalogue_1": 544,
    "Henry Draper Catalogue_1": 166,
    "Harvard Revised Catalogue_1": 8,
    "Gliese Catalog_1": "Gl 5"
  },
  {
    "Astrid #": "ASTRID 000736",
    "HYG Star ID": 97725,
    "Hipparcos Catalogue": 98036,
    "Henry Draper Catalogue": 188512,
    "Harvard Revised Catalogue": 7602,
    "Gliese Catalog": "Gl 771A",
    "Proper Name": "Alshain",
    "Distance in Parsecs": 13.6986,
    "mag": 3.71,
    "Absolute Visual Magnitude": 3.027,
    "Spectral Type": "G8IVvar",
    "Color Index": 0.855,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.563854,
    "y": -11.925647,
    "z": 1.528534,
    "vx": -0.00001553,
    "vy": 0.0000346,
    "vz": -0.00003641,
    "rarad": 5.21553781122248,
    "decrad": 0.111819115829438,
    "pmrarad": 2.247111409375e-7,
    "pmdecrad": -0.000002333505207,
    "bayer": "Bet",
    "flam": 60,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 97725,
    "base": "Gl 771",
    "lum": 5.36043457816142,
    "bf": "60Bet Aql",
    "ra": 19.921887,
    "dec": 6.406763,
    "pmra": 46.35,
    "pmdec": -481.32,
    "rv": -40.7,
    "Asset Name": "ASTRID 000736",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Glad",
    "Hair": "Tiny Crown",
    "Outfit": "Wizard",
    "Special": "Ascendant Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "Alshain",
    "Constellation": "Aquila",
    "x_1": -0.00001553,
    "y_1": 0.0000346,
    "z_1": -0.00003641,
    "Distance in Parsecs_1": 13.6986,
    "HYG Star ID_1": 97725,
    "Hipparcos Catalogue_1": 98036,
    "Henry Draper Catalogue_1": 188512,
    "Harvard Revised Catalogue_1": 7602,
    "Gliese Catalog_1": "Gl 771A"
  },
  {
    "Astrid #": "ASTRID 000737",
    "HYG Star ID": 118347,
    "Gliese Catalog": "NN 3326",
    "Distance in Parsecs": 13.6986,
    "mag": 13.75,
    "Absolute Visual Magnitude": 13.067,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.238262,
    "y": 13.048443,
    "z": 2.627442,
    "vx": 0.0000038,
    "vy": -0.00000355,
    "vz": 0.00001294,
    "rarad": 1.32753888364433,
    "decrad": 0.19299963681735,
    "pmrarad": -3.31467113395833e-7,
    "pmdecrad": 9.62636347e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118347,
    "lum": 0.000516654242566949,
    "ra": 5.070825,
    "dec": 11.058065,
    "pmra": -68.37,
    "pmdec": 198.56,
    "rv": 0,
    "Asset Name": "ASTRID 000737",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Pixie",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000038,
    "y_1": -0.00000355,
    "z_1": 0.00001294,
    "Distance in Parsecs_1": 13.6986,
    "HYG Star ID_1": 118347,
    "Gliese Catalog_1": "NN 3326"
  },
  {
    "Astrid #": "ASTRID 000738",
    "HYG Star ID": 118696,
    "Gliese Catalog": "Gl 398",
    "Distance in Parsecs": 13.6986,
    "mag": 12.6,
    "Absolute Visual Magnitude": 11.917,
    "Spectral Type": "dM4  e",
    "Color Index": 1.6,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -12.739357,
    "y": 4.888216,
    "z": 1.222781,
    "vx": -0.00000441,
    "vy": 0.00004676,
    "vz": 0.00000788,
    "rarad": 2.77520853617786,
    "decrad": 0.0893751492792,
    "pmrarad": -0.00000307187644273611,
    "pmdecrad": 4.37185584e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118696,
    "lum": 0.00149004711178709,
    "ra": 10.600516,
    "dec": 5.120819,
    "pmra": -633.62,
    "pmdec": 90.18,
    "rv": 21,
    "Asset Name": "ASTRID 000738",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Spiky",
    "Outfit": "Spartan",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000441,
    "y_1": 0.00004676,
    "z_1": 0.00000788,
    "Distance in Parsecs_1": 13.6986,
    "HYG Star ID_1": 118696,
    "Gliese Catalog_1": "Gl 398"
  },
  {
    "Astrid #": "ASTRID 000739",
    "HYG Star ID": 119314,
    "Gliese Catalog": "Gl 771B",
    "Distance in Parsecs": 13.708,
    "mag": 11.4,
    "Absolute Visual Magnitude": 10.715,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.568143,
    "y": -11.934418,
    "z": 1.529196,
    "vx": 0.00000424,
    "vy": -0.00000171,
    "vz": -0.00003157,
    "rarad": 5.21550312169755,
    "decrad": 0.1117876507126,
    "pmrarad": 2.10893951041666e-7,
    "pmdecrad": -0.000002317525748,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 2,
    "comp_primary": 97725,
    "base": "Gl 771",
    "lum": 0.0045081670454146,
    "ra": 19.921754,
    "dec": 6.404961,
    "pmra": 43.5,
    "pmdec": -478.02,
    "rv": 0,
    "Asset Name": "ASTRID 000739",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Heartbreaker",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000424,
    "y_1": -0.00000171,
    "z_1": -0.00003157,
    "Distance in Parsecs_1": 13.708,
    "HYG Star ID_1": 119314,
    "Gliese Catalog_1": "Gl 771B"
  },
  {
    "Astrid #": "ASTRID 000740",
    "HYG Star ID": 48199,
    "Hipparcos Catalogue": 48336,
    "Distance in Parsecs": 13.7137,
    "mag": 10.04,
    "Absolute Visual Magnitude": 9.354,
    "Spectral Type": "M0",
    "Color Index": 1.446,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.335551,
    "y": 7.141095,
    "z": -2.928416,
    "vx": -0.00002281,
    "vy": -0.000075,
    "vz": -0.00009466,
    "rarad": 2.57942385917267,
    "decrad": -0.215196579076783,
    "pmrarad": 0.00000551407687716666,
    "pmdecrad": -0.000007065723061,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 48199,
    "lum": 0.0157906497272842,
    "ra": 9.852673,
    "dec": -12.329856,
    "pmra": 1137.36,
    "pmdec": -1457.41,
    "rv": 0,
    "Asset Name": "ASTRID 000740",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Prince",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00002281,
    "y_1": -0.000075,
    "z_1": -0.00009466,
    "Distance in Parsecs_1": 13.7137,
    "HYG Star ID_1": 48199,
    "Hipparcos Catalogue_1": 48336
  },
  {
    "Astrid #": "ASTRID 000741",
    "HYG Star ID": 55525,
    "Hipparcos Catalogue": 55691,
    "Henry Draper Catalogue": 99279,
    "Gliese Catalog": "Gl 428A",
    "Distance in Parsecs": 13.7137,
    "mag": 7.22,
    "Absolute Visual Magnitude": 6.534,
    "Spectral Type": "K7V+...",
    "Color Index": 1.271,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.43535,
    "y": 0.999927,
    "z": -12.068684,
    "vx": -0.00000155,
    "vy": 0.00003462,
    "vz": -0.00000177,
    "rarad": 2.98746890231358,
    "decrad": -1.07595300484348,
    "pmrarad": -0.00000247710701943055,
    "pmdecrad": 3.77960745e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 55525,
    "base": "Gl 428",
    "lum": 0.212031311673984,
    "ra": 11.411291,
    "dec": -61.647566,
    "pmra": -510.94,
    "pmdec": 77.96,
    "rv": 4.7,
    "Asset Name": "ASTRID 000741",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Sweetheart",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000155,
    "y_1": 0.00003462,
    "z_1": -0.00000177,
    "Distance in Parsecs_1": 13.7137,
    "HYG Star ID_1": 55525,
    "Hipparcos Catalogue_1": 55691,
    "Henry Draper Catalogue_1": 99279,
    "Gliese Catalog_1": "Gl 428A"
  },
  {
    "Astrid #": "ASTRID 000742",
    "HYG Star ID": 116405,
    "Hipparcos Catalogue": 116771,
    "Henry Draper Catalogue": 222368,
    "Harvard Revised Catalogue": 8969,
    "Gliese Catalog": "Gl 904",
    "Distance in Parsecs": 13.7137,
    "mag": 4.13,
    "Absolute Visual Magnitude": 3.444,
    "Spectral Type": "F7V",
    "Color Index": 0.507,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.595494,
    "y": -1.192399,
    "z": 1.34449,
    "vx": 0.0000106,
    "vy": 0.00002418,
    "vz": -0.00002836,
    "rarad": 6.19570356794487,
    "decrad": 0.0981973119368899,
    "pmrarad": 0.00000182445084266666,
    "pmdecrad": -0.000002118635784,
    "bayer": "Iot",
    "flam": 17,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 116405,
    "lum": 3.65090052484498,
    "bf": "17Iot Psc",
    "ra": 23.665844,
    "dec": 5.626292,
    "pmra": 376.32,
    "pmdec": -437,
    "rv": 5.5,
    "Asset Name": "ASTRID 000742",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Shocked",
    "Hair": "Braided Bun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.0000106,
    "y_1": 0.00002418,
    "z_1": -0.00002836,
    "Distance in Parsecs_1": 13.7137,
    "HYG Star ID_1": 116405,
    "Hipparcos Catalogue_1": 116771,
    "Henry Draper Catalogue_1": 222368,
    "Harvard Revised Catalogue_1": 8969,
    "Gliese Catalog_1": "Gl 904"
  },
  {
    "Astrid #": "ASTRID 000743",
    "HYG Star ID": 27123,
    "Hipparcos Catalogue": 27188,
    "Gliese Catalog": "Gl 215",
    "Distance in Parsecs": 13.7287,
    "mag": 9.02,
    "Absolute Visual Magnitude": 8.332,
    "Spectral Type": "M0",
    "Color Index": 1.379,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.395848,
    "y": 6.382741,
    "z": 12.148099,
    "vx": -0.00001749,
    "vy": 0.00003807,
    "vz": -0.00004208,
    "rarad": 1.50884271633563,
    "decrad": 1.08623667590659,
    "pmrarad": 0.00000144358121522222,
    "pmdecrad": -0.000003807096389,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 27123,
    "lum": 0.0404762248687873,
    "ra": 5.763355,
    "dec": 62.236777,
    "pmra": 297.76,
    "pmdec": -785.27,
    "rv": -19.6,
    "Asset Name": "ASTRID 000743",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Angry",
    "Hair": "Sweetheart",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001749,
    "y_1": 0.00003807,
    "z_1": -0.00004208,
    "Distance in Parsecs_1": 13.7287,
    "HYG Star ID_1": 27123,
    "Hipparcos Catalogue_1": 27188,
    "Gliese Catalog_1": "Gl 215"
  },
  {
    "Astrid #": "ASTRID 000744",
    "HYG Star ID": 118576,
    "Gliese Catalog": "NN 3520",
    "Distance in Parsecs": 13.7363,
    "mag": 14.32,
    "Absolute Visual Magnitude": 13.631,
    "Spectral Type": "m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.84609,
    "y": 2.845088,
    "z": 13.13359,
    "vx": -0.00004221,
    "vy": -0.0000416,
    "vz": -1.2e-7,
    "rarad": 2.35584994523971,
    "decrad": 1.27346880092635,
    "pmrarad": 0.00000431474479420833,
    "pmdecrad": -3.0121473e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118576,
    "lum": 0.000307326492920428,
    "ra": 8.998684,
    "dec": 72.964388,
    "pmra": 889.98,
    "pmdec": -6.21,
    "rv": 0,
    "Asset Name": "ASTRID 000744",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Ponytail",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004221,
    "y_1": -0.0000416,
    "z_1": -1.2e-7,
    "Distance in Parsecs_1": 13.7363,
    "HYG Star ID_1": 118576,
    "Gliese Catalog_1": "NN 3520"
  },
  {
    "Astrid #": "ASTRID 000745",
    "HYG Star ID": 118588,
    "Gliese Catalog": "Gl 333",
    "Distance in Parsecs": 13.7363,
    "mag": 12.19,
    "Absolute Visual Magnitude": 11.501,
    "Spectral Type": "M3",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.557034,
    "y": 6.58416,
    "z": -10.117857,
    "vx": -0.00001063,
    "vy": 0.0000577,
    "vz": 0.00001387,
    "rarad": 2.35418801446232,
    "decrad": -0.82790756931125,
    "pmrarad": -0.00000241665075346527,
    "pmdecrad": 0.000003277873775,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118588,
    "lum": 0.00218574754868693,
    "ra": 8.992336,
    "dec": -47.43561,
    "pmra": -498.47,
    "pmdec": 676.11,
    "rv": 22,
    "Asset Name": "ASTRID 000745",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Bob",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001063,
    "y_1": 0.0000577,
    "z_1": 0.00001387,
    "Distance in Parsecs_1": 13.7363,
    "HYG Star ID_1": 118588,
    "Gliese Catalog_1": "Gl 333"
  },
  {
    "Astrid #": "ASTRID 000746",
    "HYG Star ID": 118274,
    "Gliese Catalog": "Gl 162",
    "Distance in Parsecs": 13.7552,
    "mag": 10.18,
    "Absolute Visual Magnitude": 9.488,
    "Spectral Type": "M1",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.349329,
    "y": 10.127479,
    "z": 7.620771,
    "vx": -0.00002032,
    "vy": 0.00004082,
    "vz": 0.00002797,
    "rarad": 1.08481747233502,
    "decrad": 0.58710434288985,
    "pmrarad": 0.00000269260670043749,
    "pmdecrad": 6.21637797e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 118274,
    "lum": 0.0139572545770676,
    "ra": 4.143698,
    "dec": 33.638601,
    "pmra": 555.39,
    "pmdec": 128.22,
    "rv": 36.8,
    "Asset Name": "ASTRID 000746",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Curly",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00002032,
    "y_1": 0.00004082,
    "z_1": 0.00002797,
    "Distance in Parsecs_1": 13.7552,
    "HYG Star ID_1": 118274,
    "Gliese Catalog_1": "Gl 162"
  },
  {
    "Astrid #": "ASTRID 000747",
    "HYG Star ID": 119565,
    "Gliese Catalog": "Gl 895.2",
    "Distance in Parsecs": 13.7552,
    "mag": 13.04,
    "Absolute Visual Magnitude": 12.348,
    "Spectral Type": "DAV4",
    "Color Index": 0.16,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 13.57247,
    "y": -1.859795,
    "z": 1.258009,
    "vx": 0.00003211,
    "vy": -0.00003239,
    "vz": -0.00001401,
    "rarad": 6.14700673975444,
    "decrad": 0.09157338762725,
    "pmrarad": -0.00000201682491111111,
    "pmdecrad": -0.000001255347455,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119565,
    "lum": 0.00100184376572402,
    "ra": 23.479836,
    "dec": 5.246769,
    "pmra": -416,
    "pmdec": -258.93,
    "rv": 34,
    "Asset Name": "ASTRID 000747",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Battle Ready",
    "Hair": "Lion Mane",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003211,
    "y_1": -0.00003239,
    "z_1": -0.00001401,
    "Distance in Parsecs_1": 13.7552,
    "HYG Star ID_1": 119565,
    "Gliese Catalog_1": "Gl 895.2"
  },
  {
    "Astrid #": "ASTRID 000748",
    "HYG Star ID": 118897,
    "Gliese Catalog": "Gl 516B",
    "Distance in Parsecs": 13.7627,
    "mag": 12.3,
    "Absolute Visual Magnitude": 11.606,
    "Spectral Type": "dM3.5e",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -12.110414,
    "y": -5.187082,
    "z": 3.98018,
    "vx": 0.00000475,
    "vy": -0.00001709,
    "vz": -0.00001313,
    "rarad": 3.54626562632275,
    "decrad": 0.29339327448635,
    "pmrarad": 0.00000127772645510416,
    "pmdecrad": -9.62830273e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 65869,
    "base": "Gl 516",
    "lum": 0.00198426649848464,
    "ra": 13.545737,
    "dec": 16.810196,
    "pmra": 263.55,
    "pmdec": -198.6,
    "rv": -1.5,
    "Asset Name": "ASTRID 000748",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "Bandana",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000475,
    "y_1": -0.00001709,
    "z_1": -0.00001313,
    "Distance in Parsecs_1": 13.7627,
    "HYG Star ID_1": 118897,
    "Gliese Catalog_1": "Gl 516B"
  },
  {
    "Astrid #": "ASTRID 000749",
    "HYG Star ID": 42101,
    "Hipparcos Catalogue": 42220,
    "Gliese Catalog": "Gl 310",
    "Distance in Parsecs": 13.776,
    "mag": 9.28,
    "Absolute Visual Magnitude": 8.584,
    "Spectral Type": "M2",
    "Color Index": 1.417,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.35397,
    "y": 4.126168,
    "z": 12.708527,
    "vx": 0.0000549,
    "vy": 0.00004523,
    "vz": 0.00001267,
    "rarad": 2.25339753587987,
    "decrad": 1.17452132400859,
    "pmrarad": -0.00000516355658612499,
    "pmdecrad": 3.24000982e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 42101,
    "lum": 0.0320922376804843,
    "var_min": 9.334,
    "var_max": 9.224,
    "ra": 8.607345,
    "dec": 67.295115,
    "pmra": -1065.06,
    "pmdec": 66.83,
    "rv": 11.6,
    "Asset Name": "ASTRID 000749",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Pirate Hat",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.0000549,
    "y_1": 0.00004523,
    "z_1": 0.00001267,
    "Distance in Parsecs_1": 13.776,
    "HYG Star ID_1": 42101,
    "Hipparcos Catalogue_1": 42220,
    "Gliese Catalog_1": "Gl 310"
  },
  {
    "Astrid #": "ASTRID 000750",
    "HYG Star ID": 103109,
    "Hipparcos Catalogue": 103441,
    "Gliese Catalog": "Gl 813",
    "Distance in Parsecs": 13.7779,
    "mag": 11.98,
    "Absolute Visual Magnitude": 11.284,
    "Spectral Type": "M3",
    "Color Index": 1.533,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.907683,
    "y": -9.110321,
    "z": 5.241957,
    "vx": 0.00002012,
    "vy": 0.00005328,
    "vz": -0.00002519,
    "rarad": 5.4865385795548,
    "decrad": 0.390303245796772,
    "pmrarad": 0.00000374794912027083,
    "pmdecrad": -0.000001027223226,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 103109,
    "lum": 0.00266931606362428,
    "var_min": 12.068,
    "var_max": 11.848,
    "ra": 20.957034,
    "dec": 22.362729,
    "pmra": 773.07,
    "pmdec": -211.88,
    "rv": -31.1,
    "Asset Name": "ASTRID 000750",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined Neko",
    "Hair": "Neko",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00002012,
    "y_1": 0.00005328,
    "z_1": -0.00002519,
    "Distance in Parsecs_1": 13.7779,
    "HYG Star ID_1": 103109,
    "Hipparcos Catalogue_1": 103441,
    "Gliese Catalog_1": "Gl 813"
  },
  {
    "Astrid #": "ASTRID 000751",
    "HYG Star ID": 107084,
    "Hipparcos Catalogue": 107427,
    "Distance in Parsecs": 13.7798,
    "mag": 10.95,
    "Absolute Visual Magnitude": 10.254,
    "Color Index": 0.448,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.671331,
    "y": -7.097006,
    "z": -5.063408,
    "vx": 0.00000261,
    "vy": 0.00000812,
    "vz": -0.00000589,
    "rarad": 5.69629999001984,
    "decrad": -0.376267251529478,
    "pmrarad": 5.95545125194444e-7,
    "pmdecrad": -4.59603369e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 107084,
    "lum": 0.00689286860731844,
    "var_min": 11.015,
    "var_max": 10.885,
    "ra": 21.758263,
    "dec": -21.558525,
    "pmra": 122.84,
    "pmdec": -94.8,
    "rv": 0,
    "Asset Name": "ASTRID 000751",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000261,
    "y_1": 0.00000812,
    "z_1": -0.00000589,
    "Distance in Parsecs_1": 13.7798,
    "HYG Star ID_1": 107084,
    "Hipparcos Catalogue_1": 107427
  },
  {
    "Astrid #": "ASTRID 000752",
    "HYG Star ID": 109763,
    "Hipparcos Catalogue": 110109,
    "Henry Draper Catalogue": 211415,
    "Harvard Revised Catalogue": 8501,
    "Gliese Catalog": "Gl 853A",
    "Distance in Parsecs": 13.7855,
    "mag": 5.36,
    "Absolute Visual Magnitude": 4.663,
    "Spectral Type": "G1V",
    "Color Index": 0.614,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.382862,
    "y": -3.511159,
    "z": -11.09963,
    "vx": -0.00002551,
    "vy": 0.00004468,
    "vz": -0.00001396,
    "rarad": 5.83924910538773,
    "decrad": -0.935968999649251,
    "pmrarad": 0.00000213250145529166,
    "pmdecrad": -0.000003066931343,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 109763,
    "base": "Gl 853",
    "lum": 1.18795502794165,
    "ra": 22.304289,
    "dec": -53.627073,
    "pmra": 439.86,
    "pmdec": -632.6,
    "rv": -13.5,
    "Asset Name": "ASTRID 000752",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Middle Part",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00002551,
    "y_1": 0.00004468,
    "z_1": -0.00001396,
    "Distance in Parsecs_1": 13.7855,
    "HYG Star ID_1": 109763,
    "Hipparcos Catalogue_1": 110109,
    "Henry Draper Catalogue_1": 211415,
    "Harvard Revised Catalogue_1": 8501,
    "Gliese Catalog_1": "Gl 853A"
  },
  {
    "Astrid #": "ASTRID 000753",
    "HYG Star ID": 118884,
    "Gliese Catalog": "NN 3779",
    "Distance in Parsecs": 13.7931,
    "mag": 12.93,
    "Absolute Visual Magnitude": 12.232,
    "Spectral Type": "M3.5",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.741394,
    "y": -4.445238,
    "z": 5.712194,
    "vx": -0.00003764,
    "vy": 0.00003069,
    "vz": -0.00005344,
    "rarad": 3.50352667624358,
    "decrad": 0.42699131091805,
    "pmrarad": -0.00000304797512828472,
    "pmdecrad": -0.000004257381639,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118884,
    "lum": 0.00111480780336384,
    "ra": 13.382486,
    "dec": 24.4648,
    "pmra": -628.69,
    "pmdec": -878.15,
    "rv": 0,
    "Asset Name": "ASTRID 000753",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Upset",
    "Hair": "Undercut",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003764,
    "y_1": 0.00003069,
    "z_1": -0.00005344,
    "Distance in Parsecs_1": 13.7931,
    "HYG Star ID_1": 118884,
    "Gliese Catalog_1": "NN 3779"
  },
  {
    "Astrid #": "ASTRID 000754",
    "HYG Star ID": 119507,
    "Gliese Catalog": "GJ 1270",
    "Distance in Parsecs": 13.8313,
    "mag": 13.25,
    "Absolute Visual Magnitude": 12.546,
    "Spectral Type": "m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.569856,
    "y": -3.973742,
    "z": 9.161447,
    "vx": 0.0000115,
    "vy": 0.00008237,
    "vz": 0.00002375,
    "rarad": 5.88951707938597,
    "decrad": 0.72397868756035,
    "pmrarad": 0.0000058189277195,
    "pmdecrad": 0.000002292131207,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119507,
    "lum": 0.000834833753723649,
    "ra": 22.496298,
    "dec": 41.480923,
    "pmra": 1200.24,
    "pmdec": 472.79,
    "rv": 0,
    "Asset Name": "ASTRID 000754",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Tiny Crown",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000115,
    "y_1": 0.00008237,
    "z_1": 0.00002375,
    "Distance in Parsecs_1": 13.8313,
    "HYG Star ID_1": 119507,
    "Gliese Catalog_1": "GJ 1270"
  },
  {
    "Astrid #": "ASTRID 000755",
    "HYG Star ID": 118915,
    "Gliese Catalog": "Gl 524",
    "Distance in Parsecs": 13.8504,
    "mag": 12.5,
    "Absolute Visual Magnitude": 11.793,
    "Spectral Type": "k",
    "Color Index": 1.54,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.286065,
    "y": -3.584845,
    "z": -11.220327,
    "vx": 0.00001051,
    "vy": 0.00002358,
    "vz": -0.00001436,
    "rarad": 3.59887303422101,
    "decrad": -0.944337134197549,
    "pmrarad": -0.00000119283557963888,
    "pmdecrad": -0.000001768483951,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118915,
    "lum": 0.00167032122581504,
    "ra": 13.746682,
    "dec": -54.106532,
    "pmra": -246.04,
    "pmdec": -364.78,
    "rv": 0,
    "Asset Name": "ASTRID 000755",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Nervous",
    "Hair": "Medium Bob",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001051,
    "y_1": 0.00002358,
    "z_1": -0.00001436,
    "Distance in Parsecs_1": 13.8504,
    "HYG Star ID_1": 118915,
    "Gliese Catalog_1": "Gl 524"
  },
  {
    "Astrid #": "ASTRID 000756",
    "HYG Star ID": 119376,
    "Gliese Catalog": "NN 4169",
    "Distance in Parsecs": 13.8504,
    "mag": 13.97,
    "Absolute Visual Magnitude": 13.263,
    "Spectral Type": "m",
    "Color Index": 1.73,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.351397,
    "y": -9.893137,
    "z": 2.551625,
    "vx": -0.00001936,
    "vy": -0.00002608,
    "vz": -0.00003015,
    "rarad": 5.46964620907756,
    "decrad": 0.1852858711617,
    "pmrarad": -0.00000230945844869444,
    "pmdecrad": -0.000002214696766,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119376,
    "lum": 0.000431320400699416,
    "ra": 20.89251,
    "dec": 10.616098,
    "pmra": -476.36,
    "pmdec": -456.81,
    "rv": 0,
    "Asset Name": "ASTRID 000756",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "High Fade",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001936,
    "y_1": -0.00002608,
    "z_1": -0.00003015,
    "Distance in Parsecs_1": 13.8504,
    "HYG Star ID_1": 119376,
    "Gliese Catalog_1": "NN 4169"
  },
  {
    "Astrid #": "ASTRID 000757",
    "HYG Star ID": 118688,
    "Henry Draper Catalogue": 237903,
    "Gliese Catalog": "Gl 394",
    "Distance in Parsecs": 13.8696,
    "mag": 8.69,
    "Absolute Visual Magnitude": 7.98,
    "Spectral Type": "K7 Ve",
    "Color Index": 1.33,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.171195,
    "y": 2.954541,
    "z": 11.498842,
    "vx": -0.00000221,
    "vy": 0.00001372,
    "vz": 0.00000632,
    "rarad": 2.75081699188322,
    "decrad": 0.97738910782775,
    "pmrarad": -8.539023355625e-7,
    "pmdecrad": -1.79943445e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 118688,
    "lum": 0.0559757601495109,
    "ra": 10.507347,
    "dec": 56.000271,
    "pmra": -176.13,
    "pmdec": -37.12,
    "rv": 9.1,
    "Asset Name": "ASTRID 000757",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Sweetheart",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000221,
    "y_1": 0.00001372,
    "z_1": 0.00000632,
    "Distance in Parsecs_1": 13.8696,
    "HYG Star ID_1": 118688,
    "Henry Draper Catalogue_1": 237903,
    "Gliese Catalog_1": "Gl 394"
  },
  {
    "Astrid #": "ASTRID 000758",
    "HYG Star ID": 119609,
    "Gliese Catalog": "GJ 1292",
    "Distance in Parsecs": 13.8696,
    "mag": 11.72,
    "Absolute Visual Magnitude": 11.01,
    "Spectral Type": "k-m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.737479,
    "y": -0.126174,
    "z": 5.486942,
    "vx": 0.00002818,
    "vy": 0.00006913,
    "vz": -0.00006374,
    "rarad": 6.27325771197376,
    "decrad": 0.4067310612383,
    "pmrarad": 0.00000500511947531944,
    "pmdecrad": -0.000005005095234,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119609,
    "lum": 0.00343557947899874,
    "ra": 23.962079,
    "dec": 23.303973,
    "pmra": 1032.38,
    "pmdec": -1032.38,
    "rv": 0,
    "Asset Name": "ASTRID 000758",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Bandana",
    "Outfit": "Toga",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00002818,
    "y_1": 0.00006913,
    "z_1": -0.00006374,
    "Distance in Parsecs_1": 13.8696,
    "HYG Star ID_1": 119609,
    "Gliese Catalog_1": "GJ 1292"
  },
  {
    "Astrid #": "ASTRID 000759",
    "HYG Star ID": 79297,
    "Hipparcos Catalogue": 79537,
    "Henry Draper Catalogue": 145417,
    "Gliese Catalog": "Gl 615",
    "Distance in Parsecs": 13.887,
    "mag": 7.53,
    "Absolute Visual Magnitude": 6.817,
    "Spectral Type": "K0V",
    "Color Index": 0.815,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.328428,
    "y": -6.661907,
    "z": -11.721426,
    "vx": -0.00001806,
    "vy": 0.0000925,
    "vz": -0.00005957,
    "rarad": 4.24907599872193,
    "decrad": -1.00479495340057,
    "pmrarad": -0.00000414026035057638,
    "pmdecrad": -0.000006839702923,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 79297,
    "lum": 0.163380416930068,
    "ra": 16.230275,
    "dec": -57.57051,
    "pmra": -853.99,
    "pmdec": -1410.79,
    "rv": 10,
    "Asset Name": "ASTRID 000759",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Curly",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00001806,
    "y_1": 0.0000925,
    "z_1": -0.00005957,
    "Distance in Parsecs_1": 13.887,
    "HYG Star ID_1": 79297,
    "Hipparcos Catalogue_1": 79537,
    "Henry Draper Catalogue_1": 145417,
    "Gliese Catalog_1": "Gl 615"
  },
  {
    "Astrid #": "ASTRID 000760",
    "HYG Star ID": 65508,
    "Hipparcos Catalogue": 65714,
    "Gliese Catalog": "Gl 512A",
    "Distance in Parsecs": 13.8889,
    "mag": 11.23,
    "Absolute Visual Magnitude": 10.517,
    "Spectral Type": "M4+...",
    "Color Index": 1.539,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.85831,
    "y": -5.218036,
    "z": -0.571972,
    "vx": 0.00004446,
    "vy": 0.00000707,
    "vz": -0.00003098,
    "rarad": 3.52709795799445,
    "decrad": -0.0411947155786875,
    "pmrarad": 7.32456508583333e-7,
    "pmdecrad": -0.00000235915185,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 65508,
    "base": "Gl 512",
    "lum": 0.00541003406863467,
    "var_min": 11.319,
    "var_max": 11.159,
    "ra": 13.472522,
    "dec": -2.360283,
    "pmra": 151.08,
    "pmdec": -486.61,
    "rv": -41.6,
    "Asset Name": "ASTRID 000760",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Spiky",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00004446,
    "y_1": 0.00000707,
    "z_1": -0.00003098,
    "Distance in Parsecs_1": 13.8889,
    "HYG Star ID_1": 65508,
    "Hipparcos Catalogue_1": 65714,
    "Gliese Catalog_1": "Gl 512A"
  },
  {
    "Astrid #": "ASTRID 000761",
    "HYG Star ID": 118016,
    "Gliese Catalog": "NN 3053",
    "Distance in Parsecs": 13.8889,
    "mag": 14.2,
    "Absolute Visual Magnitude": 13.487,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.140875,
    "y": 2.614184,
    "z": -3.658547,
    "vx": -0.00001421,
    "vy": 0.00001858,
    "vz": -0.00003778,
    "rarad": 0.196368871845345,
    "decrad": -0.266560749362699,
    "pmrarad": 0.00000151203690691666,
    "pmdecrad": -0.000002819967254,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118016,
    "lum": 0.000350913438358552,
    "ra": 0.750074,
    "dec": -15.272806,
    "pmra": 311.88,
    "pmdec": -581.66,
    "rv": 0,
    "Asset Name": "ASTRID 000761",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Bangs",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001421,
    "y_1": 0.00001858,
    "z_1": -0.00003778,
    "Distance in Parsecs_1": 13.8889,
    "HYG Star ID_1": 118016,
    "Gliese Catalog_1": "NN 3053"
  },
  {
    "Astrid #": "ASTRID 000762",
    "HYG Star ID": 118356,
    "Gliese Catalog": "NN 3336",
    "Distance in Parsecs": 13.8889,
    "mag": 11.39,
    "Absolute Visual Magnitude": 10.677,
    "Spectral Type": "M2",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.964199,
    "y": 8.926966,
    "z": 10.457186,
    "vx": -0.00000811,
    "vy": 0.00002294,
    "vz": -0.00001805,
    "rarad": 1.35419350504174,
    "decrad": 0.8524829600006,
    "pmrarad": 9.25460834812499e-7,
    "pmdecrad": -0.000001975639988,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 118356,
    "lum": 0.00466874334392018,
    "ra": 5.172638,
    "dec": 48.843676,
    "pmra": 190.89,
    "pmdec": -407.5,
    "rv": 0,
    "Asset Name": "ASTRID 000762",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Sweetheart",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00000811,
    "y_1": 0.00002294,
    "z_1": -0.00001805,
    "Distance in Parsecs_1": 13.8889,
    "HYG Star ID_1": 118356,
    "Gliese Catalog_1": "NN 3336"
  },
  {
    "Astrid #": "ASTRID 000763",
    "HYG Star ID": 119013,
    "Gliese Catalog": "NN 3898",
    "Distance in Parsecs": 13.8889,
    "mag": 13.35,
    "Absolute Visual Magnitude": 12.637,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.467731,
    "y": -7.222867,
    "z": 9.944859,
    "vx": -0.00000889,
    "vy": 0.00003045,
    "vz": 0.00001634,
    "rarad": 3.98213422926388,
    "decrad": 0.7980974265248,
    "pmrarad": -0.00000193925472222222,
    "pmdecrad": 0.000001685750496,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119013,
    "lum": 0.000767714953769026,
    "ra": 15.210632,
    "dec": 45.727614,
    "pmra": -400,
    "pmdec": 347.71,
    "rv": 0,
    "Asset Name": "ASTRID 000763",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Arrogant",
    "Hair": "Bob",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000889,
    "y_1": 0.00003045,
    "z_1": 0.00001634,
    "Distance in Parsecs_1": 13.8889,
    "HYG Star ID_1": 119013,
    "Gliese Catalog_1": "NN 3898"
  },
  {
    "Astrid #": "ASTRID 000764",
    "HYG Star ID": 119216,
    "Gliese Catalog": "NN 4055",
    "Distance in Parsecs": 13.8889,
    "mag": 12.49,
    "Absolute Visual Magnitude": 11.777,
    "Spectral Type": "M3",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.252237,
    "y": -12.179915,
    "z": 6.556153,
    "vx": -0.00000398,
    "vy": -0.00000608,
    "vz": -0.00001053,
    "rarad": 4.81484287351824,
    "decrad": 0.4916063018747,
    "pmrarad": -3.30109635090277e-7,
    "pmdecrad": -8.59962506e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119216,
    "lum": 0.0016951182515314,
    "ra": 18.391345,
    "dec": 28.166966,
    "pmra": -68.09,
    "pmdec": -177.38,
    "rv": 0,
    "Asset Name": "ASTRID 000764",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Audiohead",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000398,
    "y_1": -0.00000608,
    "z_1": -0.00001053,
    "Distance in Parsecs_1": 13.8889,
    "HYG Star ID_1": 119216,
    "Gliese Catalog_1": "NN 4055"
  },
  {
    "Astrid #": "ASTRID 000765",
    "HYG Star ID": 119217,
    "Gliese Catalog": "NN 4056",
    "Distance in Parsecs": 13.8889,
    "mag": 11.84,
    "Absolute Visual Magnitude": 11.127,
    "Spectral Type": "g",
    "Color Index": 1.56,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.456471,
    "y": -13.806294,
    "z": 0.408012,
    "vx": 0.00001146,
    "vy": 8.2e-7,
    "vz": -0.00001321,
    "rarad": 4.81749349533474,
    "decrad": 0.0293810749602,
    "pmrarad": 8.26946694923611e-7,
    "pmdecrad": -9.51320796e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 119217,
    "lum": 0.00308460813775713,
    "ra": 18.40147,
    "dec": 1.683412,
    "pmra": 170.57,
    "pmdec": -196.22,
    "rv": 0,
    "Asset Name": "ASTRID 000765",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "High Bun",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00001146,
    "y_1": 8.2e-7,
    "z_1": -0.00001321,
    "Distance in Parsecs_1": 13.8889,
    "HYG Star ID_1": 119217,
    "Gliese Catalog_1": "NN 4056"
  },
  {
    "Astrid #": "ASTRID 000766",
    "HYG Star ID": 119503,
    "Gliese Catalog": "NN 4279",
    "Distance in Parsecs": 13.8889,
    "mag": 13.22,
    "Absolute Visual Magnitude": 12.507,
    "Spectral Type": "dM3   :",
    "Color Index": 1.66,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.671824,
    "y": -5.440709,
    "z": 1.650791,
    "vx": 0.00000563,
    "vy": 0.00001296,
    "vz": -4.9e-7,
    "rarad": 5.87763153192356,
    "decrad": 0.1191385456427,
    "pmrarad": 0.00000101747847138194,
    "pmdecrad": -3.5527146e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119503,
    "lum": 0.000865366342956347,
    "ra": 22.450899,
    "dec": 6.826136,
    "pmra": 209.87,
    "pmdec": -7.33,
    "rv": 0,
    "Asset Name": "ASTRID 000766",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "High Bun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000563,
    "y_1": 0.00001296,
    "z_1": -4.9e-7,
    "Distance in Parsecs_1": 13.8889,
    "HYG Star ID_1": 119503,
    "Gliese Catalog_1": "NN 4279"
  },
  {
    "Astrid #": "ASTRID 000767",
    "HYG Star ID": 78117,
    "Hipparcos Catalogue": 78353,
    "Gliese Catalog": "Gl 606",
    "Distance in Parsecs": 13.8985,
    "mag": 10.49,
    "Absolute Visual Magnitude": 9.775,
    "Spectral Type": "M0",
    "Color Index": 1.497,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.883011,
    "y": -11.908487,
    "z": -1.9951,
    "vx": 0.00001203,
    "vy": -0.00000669,
    "vz": -0.00000154,
    "rarad": 4.18830888765055,
    "decrad": -0.144045505050711,
    "pmrarad": 9.90474349375e-7,
    "pmdecrad": -1.12234367e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 78117,
    "lum": 0.010715193052376,
    "var_min": 10.546,
    "var_max": 10.436,
    "ra": 15.998162,
    "dec": -8.253199,
    "pmra": 204.3,
    "pmdec": -23.15,
    "rv": 0,
    "Asset Name": "ASTRID 000767",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Neko Ninja",
    "Hair": "Unkempt",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00001203,
    "y_1": -0.00000669,
    "z_1": -0.00000154,
    "Distance in Parsecs_1": 13.8985,
    "HYG Star ID_1": 78117,
    "Hipparcos Catalogue_1": 78353,
    "Gliese Catalog_1": "Gl 606"
  },
  {
    "Astrid #": "ASTRID 000768",
    "HYG Star ID": 79431,
    "Hipparcos Catalogue": 79672,
    "Henry Draper Catalogue": 146233,
    "Harvard Revised Catalogue": 6060,
    "Gliese Catalog": "Gl 616",
    "Distance in Parsecs": 13.9005,
    "mag": 5.49,
    "Absolute Visual Magnitude": 4.775,
    "Spectral Type": "G1V",
    "Color Index": 0.652,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.049152,
    "y": -12.350743,
    "z": -2.023308,
    "vx": 0.00001085,
    "vy": -0.00001342,
    "vz": -0.00003484,
    "rarad": 4.2569503085051,
    "decrad": -0.146074313126338,
    "pmrarad": 0.00000112554344077777,
    "pmdecrad": -0.000002403900153,
    "flam": 18,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 79431,
    "lum": 1.0715193052376,
    "bf": "18    Sco",
    "ra": 16.260352,
    "dec": -8.369442,
    "pmra": 232.16,
    "pmdec": -495.84,
    "rv": 12,
    "Asset Name": "ASTRID 000768",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Greasy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00001085,
    "y_1": -0.00001342,
    "z_1": -0.00003484,
    "Distance in Parsecs_1": 13.9005,
    "HYG Star ID_1": 79431,
    "Hipparcos Catalogue_1": 79672,
    "Henry Draper Catalogue_1": 146233,
    "Harvard Revised Catalogue_1": 6060,
    "Gliese Catalog_1": "Gl 616"
  },
  {
    "Astrid #": "ASTRID 000769",
    "HYG Star ID": 46636,
    "Hipparcos Catalogue": 46769,
    "Gliese Catalog": "Gl 353",
    "Distance in Parsecs": 13.9063,
    "mag": 10.19,
    "Absolute Visual Magnitude": 9.474,
    "Spectral Type": "M0",
    "Color Index": 1.475,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.946689,
    "y": 6.745552,
    "z": 8.236775,
    "vx": -0.00002155,
    "vy": 0.00003374,
    "vz": -0.00001633,
    "rarad": 2.49555640232797,
    "decrad": 0.633907553810523,
    "pmrarad": -0.00000100453394611111,
    "pmdecrad": -0.000002544593083,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 46636,
    "lum": 0.0141383913909456,
    "ra": 9.532323,
    "dec": 36.320227,
    "pmra": -207.2,
    "pmdec": -524.86,
    "rv": 20.1,
    "Asset Name": "ASTRID 000769",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Frizzy",
    "Outfit": "Tats",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": -0.00002155,
    "y_1": 0.00003374,
    "z_1": -0.00001633,
    "Distance in Parsecs_1": 13.9063,
    "HYG Star ID_1": 46636,
    "Hipparcos Catalogue_1": 46769,
    "Gliese Catalog_1": "Gl 353"
  },
  {
    "Astrid #": "ASTRID 000770",
    "HYG Star ID": 118725,
    "Gliese Catalog": "NN 3637",
    "Distance in Parsecs": 13.9082,
    "mag": 14.07,
    "Absolute Visual Magnitude": 13.354,
    "Spectral Type": "m",
    "Color Index": 1.73,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.436255,
    "y": 3.517564,
    "z": 0.729289,
    "vx": -0.00001883,
    "vy": -0.000066,
    "vz": -0.0000281,
    "rarad": 2.88554227671355,
    "decrad": 0.0524599111424999,
    "pmrarad": 0.00000493409427111805,
    "pmdecrad": -0.00000202361715,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118725,
    "lum": 0.000396643187946858,
    "ra": 11.02196,
    "dec": 3.005732,
    "pmra": 1017.73,
    "pmdec": -417.4,
    "rv": 0,
    "Asset Name": "ASTRID 000770",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Elegant",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001883,
    "y_1": -0.000066,
    "z_1": -0.0000281,
    "Distance in Parsecs_1": 13.9082,
    "HYG Star ID_1": 118725,
    "Gliese Catalog_1": "NN 3637"
  },
  {
    "Astrid #": "ASTRID 000771",
    "HYG Star ID": 52448,
    "Hipparcos Catalogue": 52600,
    "Gliese Catalog": "Gl 400A",
    "Distance in Parsecs": 13.9159,
    "mag": 9.16,
    "Absolute Visual Magnitude": 8.442,
    "Spectral Type": "M2",
    "Color Index": 1.416,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.316481,
    "y": 3.484004,
    "z": 8.665069,
    "vx": 0.00000905,
    "vy": -5.7e-7,
    "vz": 0.00000575,
    "rarad": 2.81590649750022,
    "decrad": 0.672157046792888,
    "pmrarad": -1.69199974513888e-7,
    "pmdecrad": 7.15633473e-7,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 52448,
    "base": "Gl 400",
    "lum": 0.0365763193009451,
    "ra": 10.755971,
    "dec": 38.511762,
    "pmra": -34.9,
    "pmdec": 147.61,
    "rv": -3.2,
    "Asset Name": "ASTRID 000771",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Windy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": 0.00000905,
    "y_1": -5.7e-7,
    "z_1": 0.00000575,
    "Distance in Parsecs_1": 13.9159,
    "HYG Star ID_1": 52448,
    "Hipparcos Catalogue_1": 52600,
    "Gliese Catalog_1": "Gl 400A"
  },
  {
    "Astrid #": "ASTRID 000772",
    "HYG Star ID": 118952,
    "Gliese Catalog": "GJ 1182",
    "Distance in Parsecs": 13.9276,
    "mag": 14.3,
    "Absolute Visual Magnitude": 13.581,
    "Spectral Type": "m",
    "Color Index": 1.72,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.524344,
    "y": -7.739159,
    "z": 1.130779,
    "vx": -0.00003515,
    "vy": 0.00003767,
    "vz": -0.00004984,
    "rarad": 3.73296813356619,
    "decrad": 0.08127802294475,
    "pmrarad": -0.00000365282867614583,
    "pmdecrad": -0.000003614761105,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118952,
    "lum": 0.000321810344309344,
    "ra": 14.258888,
    "dec": 4.656888,
    "pmra": -753.45,
    "pmdec": -745.6,
    "rv": 4,
    "Asset Name": "ASTRID 000772",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Neko Ninja",
    "Hair": "Greasy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003515,
    "y_1": 0.00003767,
    "z_1": -0.00004984,
    "Distance in Parsecs_1": 13.9276,
    "HYG Star ID_1": 118952,
    "Gliese Catalog_1": "GJ 1182"
  },
  {
    "Astrid #": "ASTRID 000773",
    "HYG Star ID": 119425,
    "Gliese Catalog": "Gl 836",
    "Distance in Parsecs": 13.947,
    "mag": 13.43,
    "Absolute Visual Magnitude": 12.708,
    "Spectral Type": "M3.5",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.394035,
    "y": -7.344588,
    "z": -5.708874,
    "vx": 0.00005077,
    "vy": 0.00004609,
    "vz": -0.00005689,
    "rarad": 5.66801002470883,
    "decrad": -0.4216564431414,
    "pmrarad": 0.00000480033417665277,
    "pmdecrad": -0.000003286842828,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119425,
    "lum": 0.000719117735782509,
    "ra": 21.650204,
    "dec": -24.159135,
    "pmra": 990.14,
    "pmdec": -677.96,
    "rv": 36,
    "Asset Name": "ASTRID 000773",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Good Boy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005077,
    "y_1": 0.00004609,
    "z_1": -0.00005689,
    "Distance in Parsecs_1": 13.947,
    "HYG Star ID_1": 119425,
    "Gliese Catalog_1": "Gl 836"
  },
  {
    "Astrid #": "ASTRID 000774",
    "HYG Star ID": 17378,
    "Hipparcos Catalogue": 17420,
    "Henry Draper Catalogue": 23356,
    "Distance in Parsecs": 13.9489,
    "mag": 7.1,
    "Absolute Visual Magnitude": 6.377,
    "Spectral Type": "K2V",
    "Color Index": 0.927,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.373935,
    "y": 10.924326,
    "z": -4.566838,
    "vx": -0.00001543,
    "vy": 0.00001459,
    "vz": 0.00001,
    "rarad": 0.977044934632117,
    "decrad": -0.333548118234245,
    "pmrarad": 0.00000150224367056944,
    "pmdecrad": 7.58830372e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17378,
    "lum": 0.245019133688078,
    "ra": 3.732037,
    "dec": -19.110899,
    "pmra": 309.86,
    "pmdec": 156.52,
    "rv": 0,
    "Asset Name": "ASTRID 000774",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Pixie",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001543,
    "y_1": 0.00001459,
    "z_1": 0.00001,
    "Distance in Parsecs_1": 13.9489,
    "HYG Star ID_1": 17378,
    "Hipparcos Catalogue_1": 17420,
    "Henry Draper Catalogue_1": 23356
  },
  {
    "Astrid #": "ASTRID 000775",
    "HYG Star ID": 16811,
    "Hipparcos Catalogue": 16852,
    "Henry Draper Catalogue": 22484,
    "Harvard Revised Catalogue": 1101,
    "Gliese Catalog": "Gl 147",
    "Distance in Parsecs": 13.9626,
    "mag": 4.29,
    "Absolute Visual Magnitude": 3.565,
    "Spectral Type": "F9V",
    "Color Index": 0.575,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.163865,
    "y": 11.327087,
    "z": 0.097883,
    "vx": 0.00002959,
    "vy": 0.00001413,
    "vz": -0.00003242,
    "rarad": 0.946287207011658,
    "decrad": 0.007010316983025,
    "pmrarad": -0.00000112811295328472,
    "pmdecrad": -0.00000233680194,
    "flam": 10,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 16811,
    "lum": 3.26587832172335,
    "bf": "10    Tau",
    "ra": 3.614551,
    "dec": 0.401662,
    "pmra": -232.69,
    "pmdec": -482,
    "rv": 27.9,
    "Asset Name": "ASTRID 000775",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Greasy",
    "Outfit": "River Spirit",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00002959,
    "y_1": 0.00001413,
    "z_1": -0.00003242,
    "Distance in Parsecs_1": 13.9626,
    "HYG Star ID_1": 16811,
    "Hipparcos Catalogue_1": 16852,
    "Henry Draper Catalogue_1": 22484,
    "Harvard Revised Catalogue_1": 1101,
    "Gliese Catalog_1": "Gl 147"
  },
  {
    "Astrid #": "ASTRID 000776",
    "HYG Star ID": 115951,
    "Hipparcos Catalogue": 116317,
    "Gliese Catalog": "Gl 899",
    "Distance in Parsecs": 13.9782,
    "mag": 11.16,
    "Absolute Visual Magnitude": 10.433,
    "Spectral Type": "M3",
    "Color Index": 1.454,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.888839,
    "y": -1.579024,
    "z": 0.043771,
    "vx": 0.00001289,
    "vy": -0.00006946,
    "vz": -0.00006338,
    "rarad": 6.16998122221811,
    "decrad": 0.0031313495784825,
    "pmrarad": -0.000004833689357875,
    "pmdecrad": -0.000004539019602,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 115951,
    "lum": 0.00584520840358855,
    "var_min": 11.239,
    "var_max": 10.889,
    "ra": 23.567592,
    "dec": 0.179413,
    "pmra": -997.02,
    "pmdec": -936.24,
    "rv": 20,
    "Asset Name": "ASTRID 000776",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Pixie",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00001289,
    "y_1": -0.00006946,
    "z_1": -0.00006338,
    "Distance in Parsecs_1": 13.9782,
    "HYG Star ID_1": 115951,
    "Hipparcos Catalogue_1": 116317,
    "Gliese Catalog_1": "Gl 899"
  },
  {
    "Astrid #": "ASTRID 000777",
    "HYG Star ID": 52039,
    "Hipparcos Catalogue": 52190,
    "Distance in Parsecs": 13.9841,
    "mag": 11.02,
    "Absolute Visual Magnitude": 10.292,
    "Color Index": 1.529,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -10.361981,
    "y": 3.784815,
    "z": -8.59416,
    "vx": 6.6e-7,
    "vy": -0.00001392,
    "vz": -0.00000692,
    "rarad": 2.79138641596841,
    "decrad": -0.661836322575527,
    "pmrarad": 9.18624961916666e-7,
    "pmdecrad": -6.27494346e-7,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 52039,
    "lum": 0.00665579596367229,
    "var_min": 11.099,
    "var_max": 10.939,
    "ra": 10.662311,
    "dec": -37.920428,
    "pmra": 189.48,
    "pmdec": -129.43,
    "rv": 0,
    "Asset Name": "ASTRID 000777",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Curly",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": 6.6e-7,
    "y_1": -0.00001392,
    "z_1": -0.00000692,
    "Distance in Parsecs_1": 13.9841,
    "HYG Star ID_1": 52039,
    "Hipparcos Catalogue_1": 52190
  },
  {
    "Astrid #": "ASTRID 000778",
    "HYG Star ID": 52444,
    "Hipparcos Catalogue": 52596,
    "Distance in Parsecs": 14.0036,
    "mag": 11.22,
    "Absolute Visual Magnitude": 10.489,
    "Color Index": 1.503,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.393975,
    "y": 3.852314,
    "z": -7.172016,
    "vx": 0.00000834,
    "vy": -6.8e-7,
    "vz": -0.00001361,
    "rarad": 2.81555758618199,
    "decrad": -0.537692166410535,
    "pmrarad": -1.45007771854166e-7,
    "pmdecrad": -0.000001131361204,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 52444,
    "lum": 0.0055513677743521,
    "var_min": 11.297,
    "var_max": 11.147,
    "ra": 10.754638,
    "dec": -30.807492,
    "pmra": -29.91,
    "pmdec": -233.36,
    "rv": 0,
    "Asset Name": "ASTRID 000778",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Bucket Hat",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000834,
    "y_1": -6.8e-7,
    "z_1": -0.00001361,
    "Distance in Parsecs_1": 14.0036,
    "HYG Star ID_1": 52444,
    "Hipparcos Catalogue_1": 52596
  },
  {
    "Astrid #": "ASTRID 000779",
    "HYG Star ID": 118337,
    "Gliese Catalog": "GJ 1072",
    "Distance in Parsecs": 14.0056,
    "mag": 15.21,
    "Absolute Visual Magnitude": 14.478,
    "Spectral Type": "m",
    "Color Index": 1.95,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.855879,
    "y": 12.388021,
    "z": 5.275032,
    "vx": -0.00003684,
    "vy": 0.00002244,
    "vz": -0.00002576,
    "rarad": 1.26903091488007,
    "decrad": 0.38616363821725,
    "pmrarad": 0.00000298819760147222,
    "pmdecrad": -0.000001985355655,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118337,
    "lum": 0.000140863994636125,
    "ra": 4.847341,
    "dec": 22.125547,
    "pmra": 616.36,
    "pmdec": -409.51,
    "rv": 0,
    "Asset Name": "ASTRID 000779",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Shinobi",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003684,
    "y_1": 0.00002244,
    "z_1": -0.00002576,
    "Distance in Parsecs_1": 14.0056,
    "HYG Star ID_1": 118337,
    "Gliese Catalog_1": "GJ 1072"
  },
  {
    "Astrid #": "ASTRID 000780",
    "HYG Star ID": 70642,
    "Hipparcos Catalogue": 70865,
    "Gliese Catalog": "Gl 552",
    "Distance in Parsecs": 14.0076,
    "mag": 10.67,
    "Absolute Visual Magnitude": 9.938,
    "Spectral Type": "M3",
    "Color Index": 1.471,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.725239,
    "y": -8.192278,
    "z": 3.751075,
    "vx": -0.00003051,
    "vy": 0.0000667,
    "vz": 0.00008711,
    "rarad": 3.79388921178366,
    "decrad": 0.271095721980369,
    "pmrarad": -0.00000510688186686805,
    "pmdecrad": 0.000006302626328,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 70642,
    "lum": 0.00922146665124263,
    "var_min": 10.739,
    "var_max": 10.609,
    "ra": 14.491589,
    "dec": 15.532641,
    "pmra": -1053.37,
    "pmdec": 1300.01,
    "rv": 7.5,
    "Asset Name": "ASTRID 000780",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Goddess",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00003051,
    "y_1": 0.0000667,
    "z_1": 0.00008711,
    "Distance in Parsecs_1": 14.0076,
    "HYG Star ID_1": 70642,
    "Hipparcos Catalogue_1": 70865,
    "Gliese Catalog_1": "Gl 552"
  },
  {
    "Astrid #": "ASTRID 000781",
    "HYG Star ID": 47606,
    "Hipparcos Catalogue": 47741,
    "Gliese Catalog": "NN 3564",
    "Distance in Parsecs": 14.0272,
    "mag": 12.08,
    "Absolute Visual Magnitude": 11.345,
    "Spectral Type": "M:",
    "Color Index": 1.542,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.362206,
    "y": 6.994208,
    "z": 6.361454,
    "vx": 0.00001957,
    "vy": 0.0000345,
    "vz": -0.00000606,
    "rarad": 2.54786459968022,
    "decrad": 0.470697955304508,
    "pmrarad": -0.000002819579403375,
    "pmdecrad": -4.84765199e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 47606,
    "lum": 0.00252348077248057,
    "var_min": 12.193,
    "var_max": 11.973,
    "ra": 9.732126,
    "dec": 26.969006,
    "pmra": -581.58,
    "pmdec": -99.99,
    "rv": 0,
    "Asset Name": "ASTRID 000781",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Windy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001957,
    "y_1": 0.0000345,
    "z_1": -0.00000606,
    "Distance in Parsecs_1": 14.0272,
    "HYG Star ID_1": 47606,
    "Hipparcos Catalogue_1": 47741,
    "Gliese Catalog_1": "NN 3564"
  },
  {
    "Astrid #": "ASTRID 000782",
    "HYG Star ID": 15599,
    "Hipparcos Catalogue": 15638,
    "Gliese Catalog": "Gl 133",
    "Distance in Parsecs": 14.043,
    "mag": 11.21,
    "Absolute Visual Magnitude": 10.473,
    "Spectral Type": "M2:",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.56136,
    "y": 1.883392,
    "z": 13.82817,
    "vx": -0.00003485,
    "vy": 0.00000174,
    "vz": -0.00000689,
    "rarad": 0.878529412538208,
    "decrad": 1.3956919281327,
    "pmrarad": 0.00000198943293815972,
    "pmdecrad": 0.00000138070088,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 15599,
    "lum": 0.00563378150850937,
    "var_min": 11.285,
    "var_max": 11.145,
    "ra": 3.355735,
    "dec": 79.967257,
    "pmra": 410.35,
    "pmdec": 284.79,
    "rv": -10.2,
    "Asset Name": "ASTRID 000782",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Serious Slumber",
    "Hair": "Ponytail",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00003485,
    "y_1": 0.00000174,
    "z_1": -0.00000689,
    "Distance in Parsecs_1": 14.043,
    "HYG Star ID_1": 15599,
    "Hipparcos Catalogue_1": 15638,
    "Gliese Catalog_1": "Gl 133"
  },
  {
    "Astrid #": "ASTRID 000783",
    "HYG Star ID": 97633,
    "Hipparcos Catalogue": 97944,
    "Henry Draper Catalogue": 188088,
    "Harvard Revised Catalogue": 7578,
    "Gliese Catalog": "Gl 770",
    "Distance in Parsecs": 14.0489,
    "mag": 6.22,
    "Absolute Visual Magnitude": 5.482,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.017,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.141341,
    "y": -11.276216,
    "z": -5.700981,
    "vx": -0.00001503,
    "vy": 0.00001014,
    "vz": -0.0000234,
    "rarad": 5.21109879419175,
    "decrad": -0.417850508751821,
    "pmrarad": -5.947209419375e-7,
    "pmdecrad": -0.000001987057351,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 97633,
    "lum": 0.558727438994019,
    "var": "V4200",
    "var_min": 6.24,
    "var_max": 6.2,
    "ra": 19.904931,
    "dec": -23.941071,
    "pmra": -122.67,
    "pmdec": -409.86,
    "rv": -5.1,
    "Asset Name": "ASTRID 000783",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Lovestruck",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00001503,
    "y_1": 0.00001014,
    "z_1": -0.0000234,
    "Distance in Parsecs_1": 14.0489,
    "HYG Star ID_1": 97633,
    "Hipparcos Catalogue_1": 97944,
    "Henry Draper Catalogue_1": 188088,
    "Harvard Revised Catalogue_1": 7578,
    "Gliese Catalog_1": "Gl 770"
  },
  {
    "Astrid #": "ASTRID 000784",
    "HYG Star ID": 53565,
    "Hipparcos Catalogue": 53721,
    "Henry Draper Catalogue": 95128,
    "Harvard Revised Catalogue": 4277,
    "Gliese Catalog": "Gl 407",
    "Distance in Parsecs": 14.0627,
    "mag": 5.03,
    "Absolute Visual Magnitude": 4.29,
    "Spectral Type": "G0V",
    "Color Index": 0.624,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.333319,
    "y": 2.794615,
    "z": 9.120037,
    "vx": -7.4e-7,
    "vy": 0.00002251,
    "vz": 0.00001056,
    "rarad": 2.87746844629542,
    "decrad": 0.705641098529009,
    "pmrarad": -0.00000153162337961111,
    "pmdecrad": 2.67374744e-7,
    "flam": 47,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 53565,
    "lum": 1.67494287602643,
    "bf": "47    UMa",
    "ra": 10.99112,
    "dec": 40.430257,
    "pmra": -315.92,
    "pmdec": 55.15,
    "rv": 11.6,
    "Asset Name": "ASTRID 000784",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Ram Horns",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -7.4e-7,
    "y_1": 0.00002251,
    "z_1": 0.00001056,
    "Distance in Parsecs_1": 14.0627,
    "HYG Star ID_1": 53565,
    "Hipparcos Catalogue_1": 53721,
    "Henry Draper Catalogue_1": 95128,
    "Harvard Revised Catalogue_1": 4277,
    "Gliese Catalog_1": "Gl 407"
  },
  {
    "Astrid #": "ASTRID 000785",
    "HYG Star ID": 10786,
    "Hipparcos Catalogue": 10812,
    "Gliese Catalog": "GJ 1046",
    "Distance in Parsecs": 14.0726,
    "mag": 11.59,
    "Absolute Visual Magnitude": 10.848,
    "Color Index": 1.51,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.256838,
    "y": 6.431751,
    "z": -8.425761,
    "vx": -0.00000419,
    "vy": 0.00011289,
    "vz": 0.00000127,
    "rarad": 0.607220124239229,
    "decrad": -0.641898648765841,
    "pmrarad": 0.00000675888448336111,
    "pmdecrad": 0.000002666426761,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 10786,
    "lum": 0.00398841186909052,
    "var_min": 11.687,
    "var_max": 11.497,
    "ra": 2.31941,
    "dec": -36.778083,
    "pmra": 1394.12,
    "pmdec": 549.99,
    "rv": 47,
    "Asset Name": "ASTRID 000785",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Charmer",
    "Outfit": "Tats",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000419,
    "y_1": 0.00011289,
    "z_1": 0.00000127,
    "Distance in Parsecs_1": 14.0726,
    "HYG Star ID_1": 10786,
    "Hipparcos Catalogue_1": 10812,
    "Gliese Catalog_1": "GJ 1046"
  },
  {
    "Astrid #": "ASTRID 000786",
    "HYG Star ID": 25157,
    "Hipparcos Catalogue": 25220,
    "Henry Draper Catalogue": 35171,
    "Gliese Catalog": "Gl 201",
    "Distance in Parsecs": 14.0845,
    "mag": 7.93,
    "Absolute Visual Magnitude": 7.186,
    "Spectral Type": "K2",
    "Color Index": 1.104,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.124277,
    "y": 13.277023,
    "z": 4.194138,
    "vx": -0.00001123,
    "vy": 0.00003927,
    "vz": 0.00001121,
    "rarad": 1.41214400341027,
    "decrad": 0.302362923317226,
    "pmrarad": 0.0000012282754596875,
    "pmdecrad": -2.2592317e-8,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 25157,
    "lum": 0.116305432332846,
    "ra": 5.393993,
    "dec": 17.324119,
    "pmra": 253.35,
    "pmdec": -4.66,
    "rv": 37.8,
    "Asset Name": "ASTRID 000786",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Uwu",
    "Hair": "Sweetheart",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00001123,
    "y_1": 0.00003927,
    "z_1": 0.00001121,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 25157,
    "Hipparcos Catalogue_1": 25220,
    "Henry Draper Catalogue_1": 35171,
    "Gliese Catalog_1": "Gl 201"
  },
  {
    "Astrid #": "ASTRID 000787",
    "HYG Star ID": 118025,
    "Gliese Catalog": "NN 3061B",
    "Distance in Parsecs": 14.0845,
    "mag": 13.3,
    "Absolute Visual Magnitude": 12.556,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.474213,
    "y": 2.796668,
    "z": 5.911497,
    "vx": -0.00000193,
    "vy": 0.00001462,
    "vz": -0.00000283,
    "rarad": 0.220543686527959,
    "decrad": 0.43313295231115,
    "pmrarad": 0.0000010432705591875,
    "pmdecrad": -2.21753777e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118025,
    "base": "NN 3061",
    "lum": 0.000827179951864132,
    "ra": 0.842415,
    "dec": 24.81669,
    "pmra": 215.19,
    "pmdec": -45.74,
    "rv": 0,
    "Asset Name": "ASTRID 000787",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Manbun",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000193,
    "y_1": 0.00001462,
    "z_1": -0.00000283,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 118025,
    "Gliese Catalog_1": "NN 3061B"
  },
  {
    "Astrid #": "ASTRID 000788",
    "HYG Star ID": 118534,
    "Gliese Catalog": "NN 3490B",
    "Distance in Parsecs": 14.0845,
    "mag": 11,
    "Absolute Visual Magnitude": 10.256,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.389985,
    "y": 9.454797,
    "z": -8.255182,
    "vx": -7e-7,
    "vy": 4.6e-7,
    "vz": 0.00000108,
    "rarad": 2.16512656631214,
    "decrad": -0.62625955385345,
    "pmrarad": 2.27862429861111e-8,
    "pmdecrad": 9.4242931e-8,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118534,
    "base": "NN 3490",
    "lum": 0.00688018316143037,
    "ra": 8.270174,
    "dec": -35.882029,
    "pmra": 4.7,
    "pmdec": 19.44,
    "rv": 0,
    "Asset Name": "ASTRID 000788",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Oh Snap",
    "Hair": "Fedora",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -7e-7,
    "y_1": 4.6e-7,
    "z_1": 0.00000108,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 118534,
    "Gliese Catalog_1": "NN 3490B"
  },
  {
    "Astrid #": "ASTRID 000789",
    "HYG Star ID": 118979,
    "Gliese Catalog": "NN 3861",
    "Distance in Parsecs": 14.0845,
    "mag": 11.74,
    "Absolute Visual Magnitude": 10.996,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.725999,
    "y": -4.673453,
    "z": 11.989367,
    "vx": -0.00000316,
    "vy": -0.00005666,
    "vz": -0.00002358,
    "rarad": 3.82598789031856,
    "decrad": 1.01835415945444,
    "pmrarad": 0.00000297578637124999,
    "pmdecrad": -0.000003191130911,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 118979,
    "lum": 0.00348016632215003,
    "ra": 14.614197,
    "dec": 58.347395,
    "pmra": 613.8,
    "pmdec": -658.22,
    "rv": 0,
    "Asset Name": "ASTRID 000789",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Ponytail",
    "Outfit": "Iron Star",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000316,
    "y_1": -0.00005666,
    "z_1": -0.00002358,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 118979,
    "Gliese Catalog_1": "NN 3861"
  },
  {
    "Astrid #": "ASTRID 000790",
    "HYG Star ID": 119192,
    "Gliese Catalog": "NN 4038",
    "Distance in Parsecs": 14.0845,
    "mag": 12.21,
    "Absolute Visual Magnitude": 11.466,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.174307,
    "y": -13.552741,
    "z": 3.829618,
    "vx": -0.00001214,
    "vy": -0.00000363,
    "vz": -0.00001339,
    "rarad": 4.69952996047638,
    "decrad": 0.27537004051575,
    "pmrarad": -8.58750472368055e-7,
    "pmdecrad": -9.87909685e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119192,
    "lum": 0.00225735571102144,
    "ra": 17.950882,
    "dec": 15.777541,
    "pmra": -177.13,
    "pmdec": -203.77,
    "rv": 0,
    "Asset Name": "ASTRID 000790",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Pompadour",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001214,
    "y_1": -0.00000363,
    "z_1": -0.00001339,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 119192,
    "Gliese Catalog_1": "NN 4038"
  },
  {
    "Astrid #": "ASTRID 000791",
    "HYG Star ID": 119516,
    "Gliese Catalog": "NN 4288A",
    "Distance in Parsecs": 14.0845,
    "mag": 10.92,
    "Absolute Visual Magnitude": 10.176,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.506381,
    "y": -4.279885,
    "z": -6.903544,
    "vx": -0.00000847,
    "vy": 0.00000216,
    "vz": -0.00001545,
    "rarad": 5.9270851478429,
    "decrad": -0.512263941239199,
    "pmrarad": -6.59831419236111e-8,
    "pmdecrad": -0.000001258784784,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119516,
    "base": "NN 4288",
    "lum": 0.00740627783668154,
    "ra": 22.639798,
    "dec": -29.350562,
    "pmra": -13.61,
    "pmdec": -259.64,
    "rv": 0,
    "Asset Name": "ASTRID 000791",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Charmer",
    "Outfit": "Emperor",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000847,
    "y_1": 0.00000216,
    "z_1": -0.00001545,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 119516,
    "Gliese Catalog_1": "NN 4288A"
  },
  {
    "Astrid #": "ASTRID 000792",
    "HYG Star ID": 119517,
    "Gliese Catalog": "NN 4289B",
    "Distance in Parsecs": 14.0845,
    "mag": 12.64,
    "Absolute Visual Magnitude": 11.896,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.506504,
    "y": -4.27898,
    "z": -6.903899,
    "vx": -0.00000847,
    "vy": 0.00000216,
    "vz": -0.00001545,
    "rarad": 5.9271577508429,
    "decrad": -0.5122929022392,
    "pmrarad": -6.59831419236111e-8,
    "pmdecrad": -0.000001258784784,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119517,
    "base": "NN 4289",
    "lum": 0.00151914769844752,
    "ra": 22.640075,
    "dec": -29.352221,
    "pmra": -13.61,
    "pmdec": -259.64,
    "rv": 0,
    "Asset Name": "ASTRID 000792",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Neko",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000847,
    "y_1": 0.00000216,
    "z_1": -0.00001545,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 119517,
    "Gliese Catalog_1": "NN 4289B"
  },
  {
    "Astrid #": "ASTRID 000793",
    "HYG Star ID": 119538,
    "Gliese Catalog": "Gl 878",
    "Distance in Parsecs": 14.0845,
    "mag": 12.76,
    "Absolute Visual Magnitude": 12.016,
    "Spectral Type": "M3",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.551417,
    "y": -1.930077,
    "z": 12.317746,
    "vx": -0.00000609,
    "vy": -0.00004654,
    "vz": -0.00000405,
    "rarad": 5.99686065593883,
    "decrad": 1.06452868679305,
    "pmrarad": -0.00000329232122328472,
    "pmdecrad": -5.92384139e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119538,
    "lum": 0.00136019132285566,
    "ra": 22.90632,
    "dec": 60.993001,
    "pmra": -679.09,
    "pmdec": -122.19,
    "rv": 0,
    "Asset Name": "ASTRID 000793",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Determined Neko",
    "Hair": "Hachimaki",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000609,
    "y_1": -0.00004654,
    "z_1": -0.00000405,
    "Distance in Parsecs_1": 14.0845,
    "HYG Star ID_1": 119538,
    "Gliese Catalog_1": "Gl 878"
  },
  {
    "Astrid #": "ASTRID 000794",
    "HYG Star ID": 87666,
    "Hipparcos Catalogue": 87938,
    "Gliese Catalog": "NN 4040",
    "Distance in Parsecs": 14.0865,
    "mag": 11.79,
    "Absolute Visual Magnitude": 11.046,
    "Spectral Type": "M:",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.09084,
    "y": -9.680261,
    "z": 10.232975,
    "vx": -9.4e-7,
    "vy": 0.00002857,
    "vz": 0.00002702,
    "rarad": 4.70300552344796,
    "decrad": 0.813125177004193,
    "pmrarad": -8.60059469305555e-8,
    "pmdecrad": 0.000002791314765,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 87666,
    "lum": 0.00332353303577477,
    "var_min": 11.859,
    "var_max": 11.689,
    "ra": 17.964158,
    "dec": 46.588641,
    "pmra": -17.74,
    "pmdec": 575.75,
    "rv": 0,
    "Asset Name": "ASTRID 000794",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Straw Hat",
    "Outfit": "Sharky",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -9.4e-7,
    "y_1": 0.00002857,
    "z_1": 0.00002702,
    "Distance in Parsecs_1": 14.0865,
    "HYG Star ID_1": 87666,
    "Hipparcos Catalogue_1": 87938,
    "Gliese Catalog_1": "NN 4040"
  },
  {
    "Astrid #": "ASTRID 000795",
    "HYG Star ID": 119164,
    "Gliese Catalog": "Gl 684B",
    "Distance in Parsecs": 14.0885,
    "mag": 8.06,
    "Absolute Visual Magnitude": 7.316,
    "Spectral Type": "K3 V",
    "Color Index": 1.1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.723504,
    "y": -6.601661,
    "z": 12.424993,
    "vx": 0.0000133,
    "vy": -0.00003199,
    "vz": -0.00001621,
    "rarad": 4.6031638991372,
    "decrad": 1.0799292290694,
    "pmrarad": 0.00000118590274400694,
    "pmdecrad": -0.000002442258612,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 85773,
    "base": "Gl 684",
    "lum": 0.103181063526759,
    "ra": 17.582791,
    "dec": 61.875387,
    "pmra": 244.61,
    "pmdec": -503.75,
    "rv": 0,
    "Asset Name": "ASTRID 000795",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Afro",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.0000133,
    "y_1": -0.00003199,
    "z_1": -0.00001621,
    "Distance in Parsecs_1": 14.0885,
    "HYG Star ID_1": 119164,
    "Gliese Catalog_1": "Gl 684B"
  },
  {
    "Astrid #": "ASTRID 000796",
    "HYG Star ID": 92913,
    "Hipparcos Catalogue": 93206,
    "Gliese Catalog": "Gl 739",
    "Distance in Parsecs": 14.0944,
    "mag": 11.13,
    "Absolute Visual Magnitude": 10.385,
    "Spectral Type": "M4",
    "Color Index": 1.446,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.393269,
    "y": -9.070282,
    "z": -10.519238,
    "vx": 0.00000379,
    "vy": 0.00002706,
    "vz": -0.00002247,
    "rarad": 4.97036430871076,
    "decrad": -0.842548378889247,
    "pmrarad": 7.49909801083333e-7,
    "pmdecrad": -0.000002395415914,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 92913,
    "lum": 0.00610942024905572,
    "var_min": 11.213,
    "var_max": 10.983,
    "ra": 18.985393,
    "dec": -48.274466,
    "pmra": 154.68,
    "pmdec": -494.09,
    "rv": 0,
    "Asset Name": "ASTRID 000796",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Charmer",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00000379,
    "y_1": 0.00002706,
    "z_1": -0.00002247,
    "Distance in Parsecs_1": 14.0944,
    "HYG Star ID_1": 92913,
    "Hipparcos Catalogue_1": 93206,
    "Gliese Catalog_1": "Gl 739"
  },
  {
    "Astrid #": "ASTRID 000797",
    "HYG Star ID": 85824,
    "Hipparcos Catalogue": 86087,
    "Gliese Catalog": "Gl 685",
    "Distance in Parsecs": 14.0984,
    "mag": 9.98,
    "Absolute Visual Magnitude": 9.234,
    "Spectral Type": "M1",
    "Color Index": 1.456,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.711409,
    "y": -6.649864,
    "z": 12.411043,
    "vx": 0.00001547,
    "vy": -0.00002481,
    "vz": -0.00003134,
    "rarad": 4.6058010261665,
    "decrad": 1.07654638556575,
    "pmrarad": 0.00000127864760109722,
    "pmdecrad": -0.000002492621057,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 85824,
    "lum": 0.0176359963389799,
    "ra": 17.592864,
    "dec": 61.681564,
    "pmra": 263.74,
    "pmdec": -514.14,
    "rv": -16.3,
    "Asset Name": "ASTRID 000797",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Audiohead",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001547,
    "y_1": -0.00002481,
    "z_1": -0.00003134,
    "Distance in Parsecs_1": 14.0984,
    "HYG Star ID_1": 85824,
    "Hipparcos Catalogue_1": 86087,
    "Gliese Catalog_1": "Gl 685"
  },
  {
    "Astrid #": "ASTRID 000798",
    "HYG Star ID": 116361,
    "Hipparcos Catalogue": 116727,
    "Henry Draper Catalogue": 222404,
    "Harvard Revised Catalogue": 8974,
    "Gliese Catalog": "Gl 903",
    "Distance in Parsecs": 14.1024,
    "mag": 3.21,
    "Absolute Visual Magnitude": 2.464,
    "Spectral Type": "K1IV",
    "Color Index": 1.031,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.008186,
    "y": -0.271815,
    "z": 13.774751,
    "vx": -0.00001816,
    "vy": -0.00000171,
    "vz": -0.00004119,
    "rarad": 6.19307929772452,
    "decrad": 1.35493881912783,
    "pmrarad": -2.36831482951388e-7,
    "pmdecrad": 6.16586038e-7,
    "bayer": "Gam",
    "flam": 35,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 116361,
    "lum": 9.00326431059722,
    "bf": "35Gam Cep",
    "ra": 23.65582,
    "dec": 77.632276,
    "pmra": -48.85,
    "pmdec": 127.18,
    "rv": -43.1,
    "Asset Name": "ASTRID 000798",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Braided Bun",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001816,
    "y_1": -0.00000171,
    "z_1": -0.00004119,
    "Distance in Parsecs_1": 14.1024,
    "HYG Star ID_1": 116361,
    "Hipparcos Catalogue_1": 116727,
    "Henry Draper Catalogue_1": 222404,
    "Harvard Revised Catalogue_1": 8974,
    "Gliese Catalog_1": "Gl 903"
  },
  {
    "Astrid #": "ASTRID 000799",
    "HYG Star ID": 119169,
    "Gliese Catalog": "GJ 1220",
    "Distance in Parsecs": 14.1044,
    "mag": 14.18,
    "Absolute Visual Magnitude": 13.433,
    "Spectral Type": "m",
    "Color Index": 1.76,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.242511,
    "y": -1.925318,
    "z": 13.97027,
    "vx": -0.00001497,
    "vy": 0.00003495,
    "vz": 0.00000456,
    "rarad": 4.5875163130389,
    "decrad": 1.4327751812356,
    "pmrarad": -0.00000136159922184027,
    "pmdecrad": 0.000002348844712,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119169,
    "lum": 0.000368807717226154,
    "ra": 17.523022,
    "dec": 82.091971,
    "pmra": -280.85,
    "pmdec": 484.48,
    "rv": 0,
    "Asset Name": "ASTRID 000799",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "High Fade",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001497,
    "y_1": 0.00003495,
    "z_1": 0.00000456,
    "Distance in Parsecs_1": 14.1044,
    "HYG Star ID_1": 119169,
    "Gliese Catalog_1": "GJ 1220"
  },
  {
    "Astrid #": "ASTRID 000800",
    "HYG Star ID": 118703,
    "Gliese Catalog": "Gl 400B",
    "Distance in Parsecs": 14.1103,
    "mag": 12.2,
    "Absolute Visual Magnitude": 11.452,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -10.460251,
    "y": 3.532722,
    "z": 8.786221,
    "vx": 0.0000102,
    "vy": -7.5e-7,
    "vz": 0.00000572,
    "rarad": 2.81589450210451,
    "decrad": 0.6721766324138,
    "pmrarad": -1.810779096875e-7,
    "pmdecrad": 7.54268276e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 52448,
    "base": "Gl 400",
    "lum": 0.00228665160291951,
    "ra": 10.755925,
    "dec": 38.512884,
    "pmra": -37.35,
    "pmdec": 155.58,
    "rv": -4.1,
    "Asset Name": "ASTRID 000800",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Neko Ninja",
    "Hair": "Shinobi",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000102,
    "y_1": -7.5e-7,
    "z_1": 0.00000572,
    "Distance in Parsecs_1": 14.1103,
    "HYG Star ID_1": 118703,
    "Gliese Catalog_1": "Gl 400B"
  },
  {
    "Astrid #": "ASTRID 000801",
    "HYG Star ID": 118197,
    "Gliese Catalog": "Gl 127B",
    "Distance in Parsecs": 14.1123,
    "mag": 6.7,
    "Absolute Visual Magnitude": 5.952,
    "Spectral Type": "G7 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.257153,
    "y": 9.176422,
    "z": -6.83884,
    "vx": -0.00000281,
    "vy": 0.00003099,
    "vz": 0.00003818,
    "rarad": 0.838067624649711,
    "decrad": -0.5059074034371,
    "pmrarad": 0.00000161719299422916,
    "pmdecrad": 0.000003093431258,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 2,
    "comp_primary": 14843,
    "base": "Gl 127",
    "lum": 0.362409855899744,
    "ra": 3.201183,
    "dec": -28.986359,
    "pmra": 333.57,
    "pmdec": 638.07,
    "rv": 0,
    "Asset Name": "ASTRID 000801",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Nervous Grin",
    "Hair": "Slick",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000281,
    "y_1": 0.00003099,
    "z_1": 0.00003818,
    "Distance in Parsecs_1": 14.1123,
    "HYG Star ID_1": 118197,
    "Gliese Catalog_1": "Gl 127B"
  },
  {
    "Astrid #": "ASTRID 000802",
    "HYG Star ID": 119236,
    "Gliese Catalog": "NN 4073",
    "Distance in Parsecs": 14.1243,
    "mag": 18.23,
    "Absolute Visual Magnitude": 17.48,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.014827,
    "y": -10.521425,
    "z": 9.205213,
    "vx": -0.00001306,
    "vy": 0.00002467,
    "vz": 0.00003107,
    "rarad": 4.90160516911038,
    "decrad": 0.70986157746925,
    "pmrarad": -5.79497792368055e-7,
    "pmdecrad": 0.000002900029385,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119236,
    "lum": 0.0000088715601203796,
    "ra": 18.722753,
    "dec": 40.672072,
    "pmra": -119.53,
    "pmdec": 598.17,
    "rv": 0,
    "Asset Name": "ASTRID 000802",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Elegant",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001306,
    "y_1": 0.00002467,
    "z_1": 0.00003107,
    "Distance in Parsecs_1": 14.1243,
    "HYG Star ID_1": 119236,
    "Gliese Catalog_1": "NN 4073"
  },
  {
    "Astrid #": "ASTRID 000803",
    "HYG Star ID": 112415,
    "Hipparcos Catalogue": 112774,
    "Henry Draper Catalogue": 216133,
    "Gliese Catalog": "Gl 875",
    "Distance in Parsecs": 14.1303,
    "mag": 9.86,
    "Absolute Visual Magnitude": 9.109,
    "Spectral Type": "K5",
    "Color Index": 1.452,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.379153,
    "y": -4.19766,
    "z": -1.744098,
    "vx": -0.0000068,
    "vy": -0.00000526,
    "vz": 0.00000773,
    "rarad": 5.97916543734406,
    "decrad": -0.123745711223016,
    "pmrarad": -4.99503535076388e-7,
    "pmdecrad": 4.99745941e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 112415,
    "lum": 0.0197879133523373,
    "ra": 22.83873,
    "dec": -7.090107,
    "pmra": -103.03,
    "pmdec": 103.08,
    "rv": -5.7,
    "Asset Name": "ASTRID 000803",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Innocent",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.0000068,
    "y_1": -0.00000526,
    "z_1": 0.00000773,
    "Distance in Parsecs_1": 14.1303,
    "HYG Star ID_1": 112415,
    "Hipparcos Catalogue_1": 112774,
    "Henry Draper Catalogue_1": 216133,
    "Gliese Catalog_1": "Gl 875"
  },
  {
    "Astrid #": "ASTRID 000804",
    "HYG Star ID": 118889,
    "Gliese Catalog": "Gl 512B",
    "Distance in Parsecs": 14.1603,
    "mag": 13.69,
    "Absolute Visual Magnitude": 12.935,
    "Spectral Type": "M4",
    "Color Index": 1.68,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.109922,
    "y": -5.320189,
    "z": -0.582763,
    "vx": 0.00000563,
    "vy": -0.00001052,
    "vz": -0.00003071,
    "rarad": 3.527100877902,
    "decrad": -0.04116641290395,
    "pmrarad": 8.3775804e-7,
    "pmdecrad": -0.000002171078079,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 65508,
    "base": "Gl 512",
    "lum": 0.000583445104273744,
    "ra": 13.472533,
    "dec": -2.358662,
    "pmra": 172.8,
    "pmdec": -447.82,
    "rv": 0,
    "Asset Name": "ASTRID 000804",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Arrogant",
    "Hair": "Middle Part",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000563,
    "y_1": -0.00001052,
    "z_1": -0.00003071,
    "Distance in Parsecs_1": 14.1603,
    "HYG Star ID_1": 118889,
    "Gliese Catalog_1": "Gl 512B"
  },
  {
    "Astrid #": "ASTRID 000805",
    "HYG Star ID": 118783,
    "Gliese Catalog": "NN 3693",
    "Distance in Parsecs": 14.1643,
    "mag": 17.89,
    "Absolute Visual Magnitude": 17.134,
    "Spectral Type": "M6  :",
    "Color Index": 1.72,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.05074,
    "y": 0.375012,
    "z": 1.725275,
    "vx": 0.00005281,
    "vy": -0.00002395,
    "vz": -0.00006825,
    "rarad": 3.11490858071581,
    "decrad": 0.122134230818249,
    "pmrarad": 0.00000159033431632638,
    "pmdecrad": -0.000004322443635,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118783,
    "lum": 0.0000122011284703192,
    "ra": 11.898074,
    "dec": 6.997776,
    "pmra": 328.03,
    "pmdec": -891.57,
    "rv": -60,
    "Asset Name": "ASTRID 000805",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Braided Ponytail",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005281,
    "y_1": -0.00002395,
    "z_1": -0.00006825,
    "Distance in Parsecs_1": 14.1643,
    "HYG Star ID_1": 118783,
    "Gliese Catalog_1": "NN 3693"
  },
  {
    "Astrid #": "ASTRID 000806",
    "HYG Star ID": 4139,
    "Hipparcos Catalogue": 4148,
    "Henry Draper Catalogue": 5133,
    "Gliese Catalog": "Gl 42",
    "Distance in Parsecs": 14.1723,
    "mag": 7.15,
    "Absolute Visual Magnitude": 6.393,
    "Spectral Type": "K2V",
    "Color Index": 0.936,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.903358,
    "y": 2.803906,
    "z": -7.162437,
    "vx": -0.00001473,
    "vy": 0.00004033,
    "vz": 0.00000547,
    "rarad": 0.231334484352768,
    "decrad": -0.529828120792323,
    "pmrarad": 0.00000300826888784722,
    "pmdecrad": 1.51795163e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 4139,
    "lum": 0.241434872971415,
    "ra": 0.883633,
    "dec": -30.356915,
    "pmra": 620.5,
    "pmdec": 31.31,
    "rv": -7,
    "Asset Name": "ASTRID 000806",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "High Fade",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00001473,
    "y_1": 0.00004033,
    "z_1": 0.00000547,
    "Distance in Parsecs_1": 14.1723,
    "HYG Star ID_1": 4139,
    "Hipparcos Catalogue_1": 4148,
    "Henry Draper Catalogue_1": 5133,
    "Gliese Catalog_1": "Gl 42"
  },
  {
    "Astrid #": "ASTRID 000807",
    "HYG Star ID": 36879,
    "Hipparcos Catalogue": 36985,
    "Distance in Parsecs": 14.1743,
    "mag": 9.87,
    "Absolute Visual Magnitude": 9.112,
    "Color Index": 1.455,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -5.763367,
    "y": 12.926823,
    "z": -0.769192,
    "vx": -0.00000189,
    "vy": -0.00000188,
    "vz": -0.00001739,
    "rarad": 1.99019003773787,
    "decrad": -0.0542933436523887,
    "pmrarad": 1.76084328777777e-7,
    "pmdecrad": -0.000001228760273,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 36879,
    "lum": 0.019733312795365,
    "ra": 7.601966,
    "dec": -3.110779,
    "pmra": 36.32,
    "pmdec": -253.45,
    "rv": 0,
    "Asset Name": "ASTRID 000807",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Curly",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00000189,
    "y_1": -0.00000188,
    "z_1": -0.00001739,
    "Distance in Parsecs_1": 14.1743,
    "HYG Star ID_1": 36879,
    "Hipparcos Catalogue_1": 36985
  },
  {
    "Astrid #": "ASTRID 000808",
    "HYG Star ID": 61708,
    "Hipparcos Catalogue": 61901,
    "Henry Draper Catalogue": 110315,
    "Gliese Catalog": "Gl 481",
    "Distance in Parsecs": 14.1784,
    "mag": 7.91,
    "Absolute Visual Magnitude": 7.152,
    "Spectral Type": "K2",
    "Color Index": 1.109,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.451746,
    "y": -2.439019,
    "z": 3.759651,
    "vx": -0.000029,
    "vy": -0.00001355,
    "vz": -0.00001804,
    "rarad": 3.32095998536557,
    "decrad": 0.268373417952526,
    "pmrarad": 5.75813208395833e-7,
    "pmdecrad": -0.000001805833997,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 61708,
    "lum": 0.120005182020426,
    "ra": 12.685133,
    "dec": 15.376664,
    "pmra": 118.77,
    "pmdec": -372.48,
    "rv": 24.5,
    "Asset Name": "ASTRID 000808",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Side Part",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.000029,
    "y_1": -0.00001355,
    "z_1": -0.00001804,
    "Distance in Parsecs_1": 14.1784,
    "HYG Star ID_1": 61708,
    "Hipparcos Catalogue_1": 61901,
    "Henry Draper Catalogue_1": 110315,
    "Gliese Catalog_1": "Gl 481"
  },
  {
    "Astrid #": "ASTRID 000809",
    "HYG Star ID": 85773,
    "Hipparcos Catalogue": 86036,
    "Henry Draper Catalogue": 160269,
    "Harvard Revised Catalogue": 6573,
    "Gliese Catalog": "Gl 684A",
    "Distance in Parsecs": 14.1904,
    "mag": 5.23,
    "Absolute Visual Magnitude": 4.47,
    "Spectral Type": "G0V",
    "Color Index": 0.602,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.728444,
    "y": -6.649556,
    "z": 12.514635,
    "vx": 0.00001635,
    "vy": -0.00002597,
    "vz": -0.00003175,
    "rarad": 4.60326317679163,
    "decrad": 1.07991468757041,
    "pmrarad": 0.000001344776187125,
    "pmdecrad": -0.000002548277667,
    "flam": 26,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 85773,
    "base": "Gl 684",
    "lum": 1.41905752168909,
    "bf": "26    Dra",
    "ra": 17.58317,
    "dec": 61.874554,
    "pmra": 277.38,
    "pmdec": -525.62,
    "rv": -16.3,
    "Asset Name": "ASTRID 000809",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Side Part",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001635,
    "y_1": -0.00002597,
    "z_1": -0.00003175,
    "Distance in Parsecs_1": 14.1904,
    "HYG Star ID_1": 85773,
    "Hipparcos Catalogue_1": 86036,
    "Henry Draper Catalogue_1": 160269,
    "Harvard Revised Catalogue_1": 6573,
    "Gliese Catalog_1": "Gl 684A"
  },
  {
    "Astrid #": "ASTRID 000810",
    "HYG Star ID": 118494,
    "Gliese Catalog": "Gl 282B",
    "Distance in Parsecs": 14.1965,
    "mag": 8.94,
    "Absolute Visual Magnitude": 8.179,
    "Spectral Type": "K5",
    "Color Index": 1.33,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.990527,
    "y": 12.838979,
    "z": -0.891832,
    "vx": 0.00000193,
    "vy": -0.00001544,
    "vz": -0.00001706,
    "rarad": 2.00735970988285,
    "decrad": -0.0628650110351499,
    "pmrarad": 3.36557657041666e-7,
    "pmdecrad": -0.000001264980703,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 2,
    "comp_primary": 37243,
    "base": "Gl 282",
    "lum": 0.0466015111702183,
    "ra": 7.667549,
    "dec": -3.6019,
    "pmra": 69.42,
    "pmdec": -260.92,
    "rv": -13.4,
    "Asset Name": "ASTRID 000810",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Smitten",
    "Hair": "Spiky",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00000193,
    "y_1": -0.00001544,
    "z_1": -0.00001706,
    "Distance in Parsecs_1": 14.1965,
    "HYG Star ID_1": 118494,
    "Gliese Catalog_1": "Gl 282B"
  },
  {
    "Astrid #": "ASTRID 000811",
    "HYG Star ID": 67477,
    "Hipparcos Catalogue": 67691,
    "Henry Draper Catalogue": 234078,
    "Gliese Catalog": "Gl 532",
    "Distance in Parsecs": 14.2045,
    "mag": 8.82,
    "Absolute Visual Magnitude": 8.058,
    "Spectral Type": "K5",
    "Color Index": 1.317,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.069732,
    "y": -4.290756,
    "z": 10.873144,
    "vx": 0.00003321,
    "vy": -0.00001533,
    "vz": -0.00004126,
    "rarad": 3.63027527674213,
    "decrad": 0.871807800152475,
    "pmrarad": 0.00000205071338738194,
    "pmdecrad": -6.7689686e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 67477,
    "lum": 0.0520954747328555,
    "ra": 13.86663,
    "dec": 49.950907,
    "pmra": 422.99,
    "pmdec": -139.62,
    "rv": -44.8,
    "Asset Name": "ASTRID 000811",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "High Bun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00003321,
    "y_1": -0.00001533,
    "z_1": -0.00004126,
    "Distance in Parsecs_1": 14.2045,
    "HYG Star ID_1": 67477,
    "Hipparcos Catalogue_1": 67691,
    "Henry Draper Catalogue_1": 234078,
    "Gliese Catalog_1": "Gl 532"
  },
  {
    "Astrid #": "ASTRID 000812",
    "HYG Star ID": 37243,
    "Hipparcos Catalogue": 37349,
    "Henry Draper Catalogue": 61606,
    "Gliese Catalog": "Gl 282A",
    "Distance in Parsecs": 14.2106,
    "mag": 7.18,
    "Absolute Visual Magnitude": 6.417,
    "Spectral Type": "K2V",
    "Color Index": 0.891,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.993117,
    "y": 12.853921,
    "z": -0.891662,
    "vx": 0.00000526,
    "vy": -0.00002264,
    "vz": -0.00001776,
    "rarad": 2.00707977806058,
    "decrad": -0.0627883435475012,
    "pmrarad": 3.37721209875e-7,
    "pmdecrad": -0.000001347491143,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 37243,
    "base": "Gl 282",
    "lum": 0.236156552391771,
    "var": "V869",
    "var_min": 7.204,
    "var_max": 7.154,
    "ra": 7.66648,
    "dec": -3.597507,
    "pmra": 69.66,
    "pmdec": -277.94,
    "rv": -21.1,
    "Asset Name": "ASTRID 000812",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Little Crown",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00000526,
    "y_1": -0.00002264,
    "z_1": -0.00001776,
    "Distance in Parsecs_1": 14.2106,
    "HYG Star ID_1": 37243,
    "Hipparcos Catalogue_1": 37349,
    "Henry Draper Catalogue_1": 61606,
    "Gliese Catalog_1": "Gl 282A"
  },
  {
    "Astrid #": "ASTRID 000813",
    "HYG Star ID": 12810,
    "Hipparcos Catalogue": 12843,
    "Henry Draper Catalogue": 17206,
    "Harvard Revised Catalogue": 818,
    "Gliese Catalog": "Gl 111",
    "Distance in Parsecs": 14.2207,
    "mag": 4.47,
    "Absolute Visual Magnitude": 3.705,
    "Spectral Type": "F5/F6V",
    "Color Index": 0.481,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.131041,
    "y": 8.892749,
    "z": -4.529443,
    "vx": 0.0000042,
    "vy": 0.00003404,
    "vz": -0.00000597,
    "rarad": 0.720397335446739,
    "decrad": -0.324152366958973,
    "pmrarad": 0.0000016043939130625,
    "pmdecrad": 1.75890403e-7,
    "bayer": "Tau-1",
    "flam": 1,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 12810,
    "lum": 2.87078058202468,
    "bf": "1Tau1Eri",
    "ra": 2.751715,
    "dec": -18.572563,
    "pmra": 330.93,
    "pmdec": 36.28,
    "rv": 25.6,
    "Asset Name": "ASTRID 000813",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Slick",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.0000042,
    "y_1": 0.00003404,
    "z_1": -0.00000597,
    "Distance in Parsecs_1": 14.2207,
    "HYG Star ID_1": 12810,
    "Hipparcos Catalogue_1": 12843,
    "Henry Draper Catalogue_1": 17206,
    "Harvard Revised Catalogue_1": 818,
    "Gliese Catalog_1": "Gl 111"
  },
  {
    "Astrid #": "ASTRID 000814",
    "HYG Star ID": 14843,
    "Hipparcos Catalogue": 14879,
    "Henry Draper Catalogue": 20010,
    "Harvard Revised Catalogue": 963,
    "Gliese Catalog": "Gl 127A",
    "Distance in Parsecs": 14.2369,
    "mag": 3.8,
    "Absolute Visual Magnitude": 3.033,
    "Spectral Type": "F8V",
    "Color Index": 0.543,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.329775,
    "y": 9.25728,
    "z": -6.899408,
    "vx": -0.00001733,
    "vy": 0.00001907,
    "vz": 0.00004687,
    "rarad": 0.838085047050491,
    "decrad": -0.5059293771979,
    "pmrarad": 0.00000180103434189583,
    "pmdecrad": 0.00000296832024,
    "bayer": "Alp",
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 14843,
    "base": "Gl 127",
    "lum": 5.33089342236701,
    "bf": "Alp For",
    "ra": 3.201249,
    "dec": -28.987618,
    "pmra": 371.49,
    "pmdec": 612.26,
    "rv": -20,
    "Asset Name": "ASTRID 000814",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "High Bun",
    "Outfit": "Toga",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00001733,
    "y_1": 0.00001907,
    "z_1": 0.00004687,
    "Distance in Parsecs_1": 14.2369,
    "HYG Star ID_1": 14843,
    "Hipparcos Catalogue_1": 14879,
    "Henry Draper Catalogue_1": 20010,
    "Harvard Revised Catalogue_1": 963,
    "Gliese Catalog_1": "Gl 127A"
  },
  {
    "Astrid #": "ASTRID 000815",
    "HYG Star ID": 118747,
    "Gliese Catalog": "NN 3655",
    "Distance in Parsecs": 14.245,
    "mag": 19.57,
    "Absolute Visual Magnitude": 18.802,
    "Spectral Type": "M8",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.677072,
    "y": 2.299785,
    "z": -3.257674,
    "vx": -0.00002245,
    "vy": 0.00003904,
    "vz": -0.00001238,
    "rarad": 2.97500538182641,
    "decrad": -0.2307053944601,
    "pmrarad": -0.00000244210347169444,
    "pmdecrad": -3.86789202e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118747,
    "lum": 0.0000026254273189937,
    "ra": 11.363683,
    "dec": -13.218445,
    "pmra": -503.72,
    "pmdec": -79.78,
    "rv": 30,
    "Asset Name": "ASTRID 000815",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Hachimaki",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002245,
    "y_1": 0.00003904,
    "z_1": -0.00001238,
    "Distance in Parsecs_1": 14.245,
    "HYG Star ID_1": 118747,
    "Gliese Catalog_1": "NN 3655"
  },
  {
    "Astrid #": "ASTRID 000816",
    "HYG Star ID": 119028,
    "Gliese Catalog": "Gl 589A",
    "Distance in Parsecs": 14.245,
    "mag": 12.41,
    "Absolute Visual Magnitude": 11.642,
    "Spectral Type": "M4.5:",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.006574,
    "y": -10.953242,
    "z": 4.333403,
    "vx": -0.00004397,
    "vy": 0.0000804,
    "vz": -0.00002314,
    "rarad": 4.08118831323296,
    "decrad": 0.30915348144495,
    "pmrarad": -0.00000582251534073611,
    "pmdecrad": -7.14911101e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119028,
    "base": "Gl 589",
    "lum": 0.00191955252376137,
    "ra": 15.588991,
    "dec": 17.71319,
    "pmra": -1200.98,
    "pmdec": -147.46,
    "rv": -43.2,
    "Asset Name": "ASTRID 000816",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Curly",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004397,
    "y_1": 0.0000804,
    "z_1": -0.00002314,
    "Distance in Parsecs_1": 14.245,
    "HYG Star ID_1": 119028,
    "Gliese Catalog_1": "Gl 589A"
  },
  {
    "Astrid #": "ASTRID 000817",
    "HYG Star ID": 119029,
    "Gliese Catalog": "Gl 589B",
    "Distance in Parsecs": 14.245,
    "mag": 14.99,
    "Absolute Visual Magnitude": 14.222,
    "Spectral Type": "M6",
    "Color Index": 1.81,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.007815,
    "y": -10.954941,
    "z": 4.334075,
    "vx": -0.0000688,
    "vy": 0.00004642,
    "vz": -0.0000097,
    "rarad": 4.08118831323296,
    "decrad": 0.30915348144495,
    "pmrarad": -0.00000582251534073611,
    "pmdecrad": -7.14911101e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119028,
    "base": "Gl 589",
    "lum": 0.000178319977223291,
    "ra": 15.588991,
    "dec": 17.71319,
    "pmra": -1200.98,
    "pmdec": -147.46,
    "rv": 0,
    "Asset Name": "ASTRID 000817",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Goddess",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000688,
    "y_1": 0.00004642,
    "z_1": -0.0000097,
    "Distance in Parsecs_1": 14.245,
    "HYG Star ID_1": 119029,
    "Gliese Catalog_1": "Gl 589B"
  },
  {
    "Astrid #": "ASTRID 000818",
    "HYG Star ID": 54804,
    "Hipparcos Catalogue": 54966,
    "Gliese Catalog": "Gl 421B",
    "Distance in Parsecs": 14.2633,
    "mag": 9.97,
    "Absolute Visual Magnitude": 9.199,
    "Spectral Type": "K7V",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.297719,
    "y": 2.624087,
    "z": -4.441978,
    "vx": -0.00000648,
    "vy": -0.00002122,
    "vz": -0.00005226,
    "rarad": 2.94676151023979,
    "decrad": -0.316690730904351,
    "pmrarad": 0.00000154791311927777,
    "pmdecrad": -0.000003432674783,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 2,
    "comp_primary": 54801,
    "base": "Gl 421",
    "lum": 0.0182137763710477,
    "ra": 11.2558,
    "dec": -18.145042,
    "pmra": 319.28,
    "pmdec": -708.04,
    "rv": 18,
    "Asset Name": "ASTRID 000818",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Innocent",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": -0.00000648,
    "y_1": -0.00002122,
    "z_1": -0.00005226,
    "Distance in Parsecs_1": 14.2633,
    "HYG Star ID_1": 54804,
    "Hipparcos Catalogue_1": 54966,
    "Gliese Catalog_1": "Gl 421B"
  },
  {
    "Astrid #": "ASTRID 000819",
    "HYG Star ID": 81685,
    "Hipparcos Catalogue": 81935,
    "Henry Draper Catalogue": 150689,
    "Distance in Parsecs": 14.2633,
    "mag": 7.52,
    "Absolute Visual Magnitude": 6.749,
    "Spectral Type": "K2V",
    "Color Index": 1.024,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.600231,
    "y": -10.49304,
    "z": -8.965276,
    "vx": -4.1e-7,
    "vy": 0.00000289,
    "vz": -0.00000322,
    "rarad": 4.38186869836144,
    "decrad": -0.679694635363366,
    "pmrarad": -9.29872639305555e-8,
    "pmdecrad": -2.89870099e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 81685,
    "lum": 0.17394021397803,
    "ra": 16.737506,
    "dec": -38.943634,
    "pmra": -19.18,
    "pmdec": -59.79,
    "rv": 0,
    "Asset Name": "ASTRID 000819",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Round Brush",
    "Outfit": "Tats",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -4.1e-7,
    "y_1": 0.00000289,
    "z_1": -0.00000322,
    "Distance in Parsecs_1": 14.2633,
    "HYG Star ID_1": 81685,
    "Hipparcos Catalogue_1": 81935,
    "Henry Draper Catalogue_1": 150689
  },
  {
    "Astrid #": "ASTRID 000820",
    "HYG Star ID": 102091,
    "Hipparcos Catalogue": 102422,
    "Henry Draper Catalogue": 198149,
    "Harvard Revised Catalogue": 7957,
    "Gliese Catalog": "Gl 807",
    "Distance in Parsecs": 14.2653,
    "mag": 3.41,
    "Absolute Visual Magnitude": 2.639,
    "Spectral Type": "K0IV",
    "Color Index": 0.912,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.44524,
    "y": -5.055924,
    "z": 12.575933,
    "vx": -0.00005612,
    "vy": 0.00007285,
    "vz": -0.00005157,
    "rarad": 5.43359675161664,
    "decrad": 1.07929034887284,
    "pmrarad": 4.17327616222222e-7,
    "pmdecrad": 0.000003965242611,
    "bayer": "Eta",
    "flam": 3,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 102091,
    "lum": 7.66302072273919,
    "bf": "3Eta Cep",
    "ra": 20.754811,
    "dec": 61.838782,
    "pmra": 86.08,
    "pmdec": 817.89,
    "rv": -86.8,
    "Asset Name": "ASTRID 000820",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Angelic",
    "Outfit": "Toga",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00005612,
    "y_1": 0.00007285,
    "z_1": -0.00005157,
    "Distance in Parsecs_1": 14.2653,
    "HYG Star ID_1": 102091,
    "Hipparcos Catalogue_1": 102422,
    "Henry Draper Catalogue_1": 198149,
    "Harvard Revised Catalogue_1": 7957,
    "Gliese Catalog_1": "Gl 807"
  },
  {
    "Astrid #": "ASTRID 000821",
    "HYG Star ID": 118205,
    "Gliese Catalog": "NN 3211",
    "Distance in Parsecs": 14.2653,
    "mag": 10.9,
    "Absolute Visual Magnitude": 10.129,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.435625,
    "y": 10.697717,
    "z": 0.16312,
    "vx": 0.00000276,
    "vy": -0.00000246,
    "vz": 0.00000188,
    "rarad": 0.848002922930318,
    "decrad": 0.0114349839199,
    "pmrarad": -2.59181393625e-7,
    "pmdecrad": 1.32058398e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 118205,
    "lum": 0.00773392578048996,
    "ra": 3.239133,
    "dec": 0.655176,
    "pmra": -53.46,
    "pmdec": 27.24,
    "rv": 0,
    "Asset Name": "ASTRID 000821",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Frizzy",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000276,
    "y_1": -0.00000246,
    "z_1": 0.00000188,
    "Distance in Parsecs_1": 14.2653,
    "HYG Star ID_1": 118205,
    "Gliese Catalog_1": "NN 3211"
  },
  {
    "Astrid #": "ASTRID 000822",
    "HYG Star ID": 118290,
    "Gliese Catalog": "Gl 168.1",
    "Distance in Parsecs": 14.2653,
    "mag": 15.04,
    "Absolute Visual Magnitude": 14.269,
    "Color Index": 1,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 5.568165,
    "y": 12.241568,
    "z": 4.757976,
    "vx": 0,
    "vy": 0,
    "vz": 0,
    "rarad": 1.143910534,
    "decrad": 0.340050724,
    "pmrarad": 0,
    "pmdecrad": 0,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118290,
    "lum": 0.000170765447281387,
    "ra": 4.369416,
    "dec": 19.483471,
    "pmra": 0,
    "pmdec": 0,
    "rv": 0,
    "Asset Name": "ASTRID 000822",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Goddess",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0,
    "y_1": 0,
    "z_1": 0,
    "Distance in Parsecs_1": 14.2653,
    "HYG Star ID_1": 118290,
    "Gliese Catalog_1": "Gl 168.1"
  },
  {
    "Astrid #": "ASTRID 000823",
    "HYG Star ID": 91913,
    "Hipparcos Catalogue": 92200,
    "Henry Draper Catalogue": 173818,
    "Gliese Catalog": "Gl 726",
    "Distance in Parsecs": 14.2776,
    "mag": 8.81,
    "Absolute Visual Magnitude": 8.037,
    "Spectral Type": "K5",
    "Color Index": 1.295,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.92932,
    "y": -13.944585,
    "z": -0.906412,
    "vx": -0.00000587,
    "vy": -0.00001669,
    "vz": -0.00001993,
    "rarad": 4.91944687252505,
    "decrad": -0.0635270315005375,
    "pmrarad": -6.42911421784722e-7,
    "pmdecrad": -0.00000132577149,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 91913,
    "lum": 0.0531128981855012,
    "ra": 18.790903,
    "dec": -3.639831,
    "pmra": -132.61,
    "pmdec": -273.46,
    "rv": 16,
    "Asset Name": "ASTRID 000823",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Sad",
    "Hair": "Elegant",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00000587,
    "y_1": -0.00001669,
    "z_1": -0.00001993,
    "Distance in Parsecs_1": 14.2776,
    "HYG Star ID_1": 91913,
    "Hipparcos Catalogue_1": 92200,
    "Henry Draper Catalogue_1": 173818,
    "Gliese Catalog_1": "Gl 726"
  },
  {
    "Astrid #": "ASTRID 000824",
    "HYG Star ID": 70252,
    "Hipparcos Catalogue": 70475,
    "Gliese Catalog": "NN 3846",
    "Distance in Parsecs": 14.2796,
    "mag": 12.23,
    "Absolute Visual Magnitude": 11.456,
    "Spectral Type": "M:",
    "Color Index": 1.613,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.379876,
    "y": -8.338961,
    "z": 2.206154,
    "vx": 0.00002372,
    "vy": -0.00002951,
    "vz": 0.00001082,
    "rarad": 3.77398212412415,
    "decrad": 0.155118214336523,
    "pmrarad": 0.00000264858561824305,
    "pmdecrad": 7.66781317e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 70252,
    "lum": 0.00227824276625007,
    "var_min": 12.461,
    "var_max": 12.011,
    "ra": 14.41555,
    "dec": 8.887619,
    "pmra": 546.31,
    "pmdec": 158.16,
    "rv": 0,
    "Asset Name": "ASTRID 000824",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Worried Vamp",
    "Hair": "Mohawk",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002372,
    "y_1": -0.00002951,
    "z_1": 0.00001082,
    "Distance in Parsecs_1": 14.2796,
    "HYG Star ID_1": 70252,
    "Hipparcos Catalogue_1": 70475,
    "Gliese Catalog_1": "NN 3846"
  },
  {
    "Astrid #": "ASTRID 000825",
    "HYG Star ID": 118114,
    "Gliese Catalog": "NN 3136",
    "Distance in Parsecs": 14.2857,
    "mag": 12.47,
    "Absolute Visual Magnitude": 11.695,
    "Spectral Type": "M5  :",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.856824,
    "y": 4.952006,
    "z": 10.854915,
    "vx": 0.00000204,
    "vy": 0.00002328,
    "vz": -0.0000121,
    "rarad": 0.562352732717982,
    "decrad": 0.86307440328205,
    "pmrarad": 0.00000130269435965277,
    "pmdecrad": -0.000001302694359,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118114,
    "lum": 0.00182810021614274,
    "ra": 2.148029,
    "dec": 49.450521,
    "pmra": 268.7,
    "pmdec": -268.7,
    "rv": 0,
    "Asset Name": "ASTRID 000825",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Uwu",
    "Hair": "Charmer",
    "Outfit": "Spartan",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000204,
    "y_1": 0.00002328,
    "z_1": -0.0000121,
    "Distance in Parsecs_1": 14.2857,
    "HYG Star ID_1": 118114,
    "Gliese Catalog_1": "NN 3136"
  },
  {
    "Astrid #": "ASTRID 000826",
    "HYG Star ID": 118235,
    "Gliese Catalog": "NN 3237",
    "Distance in Parsecs": 14.2857,
    "mag": 13.86,
    "Absolute Visual Magnitude": 13.085,
    "Spectral Type": "dM5  :",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.346749,
    "y": 11.560952,
    "z": 0.870279,
    "vx": -0.0000065,
    "vy": 0.00000517,
    "vz": -0.00000624,
    "rarad": 0.945471999339465,
    "decrad": 0.06095733844975,
    "pmrarad": 5.80806789305555e-7,
    "pmdecrad": -4.37651005e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118235,
    "lum": 0.000508159442560559,
    "ra": 3.611437,
    "dec": 3.492598,
    "pmra": 119.8,
    "pmdec": -90.27,
    "rv": 0,
    "Asset Name": "ASTRID 000826",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Owie",
    "Hair": "Slick",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000065,
    "y_1": 0.00000517,
    "z_1": -0.00000624,
    "Distance in Parsecs_1": 14.2857,
    "HYG Star ID_1": 118235,
    "Gliese Catalog_1": "NN 3237"
  },
  {
    "Astrid #": "ASTRID 000827",
    "HYG Star ID": 118512,
    "Gliese Catalog": "NN 3467",
    "Distance in Parsecs": 14.2857,
    "mag": 13.77,
    "Absolute Visual Magnitude": 12.995,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.986042,
    "y": 12.328612,
    "z": 1.811564,
    "vx": 0.00001973,
    "vy": 0.00001142,
    "vz": -0.00000158,
    "rarad": 2.08633546366818,
    "decrad": 0.12715195903695,
    "pmrarad": -0.00000159600663638888,
    "pmdecrad": -1.11599261e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118512,
    "lum": 0.000552077439280757,
    "ra": 7.969214,
    "dec": 7.285271,
    "pmra": -329.2,
    "pmdec": -23.02,
    "rv": 0,
    "Asset Name": "ASTRID 000827",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Pixie",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001973,
    "y_1": 0.00001142,
    "z_1": -0.00000158,
    "Distance in Parsecs_1": 14.2857,
    "HYG Star ID_1": 118512,
    "Gliese Catalog_1": "NN 3467"
  },
  {
    "Astrid #": "ASTRID 000828",
    "HYG Star ID": 118820,
    "Gliese Catalog": "NN 3729",
    "Distance in Parsecs": 14.2857,
    "mag": 12.9,
    "Absolute Visual Magnitude": 12.125,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.575662,
    "y": -1.347627,
    "z": 9.50897,
    "vx": -0.00001127,
    "vy": 0.00001139,
    "vz": -0.00001092,
    "rarad": 3.26835188186344,
    "decrad": 0.72833576107135,
    "pmrarad": -8.90602731180555e-7,
    "pmdecrad": -0.000001024498573,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118820,
    "lum": 0.00123026877081238,
    "ra": 12.484185,
    "dec": 41.730565,
    "pmra": -183.7,
    "pmdec": -211.32,
    "rv": 0,
    "Asset Name": "ASTRID 000828",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Good Boy",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001127,
    "y_1": 0.00001139,
    "z_1": -0.00001092,
    "Distance in Parsecs_1": 14.2857,
    "HYG Star ID_1": 118820,
    "Gliese Catalog_1": "NN 3729"
  },
  {
    "Astrid #": "ASTRID 000829",
    "HYG Star ID": 119069,
    "Gliese Catalog": "NN 3943",
    "Distance in Parsecs": 14.2857,
    "mag": 13.75,
    "Absolute Visual Magnitude": 12.975,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.179455,
    "y": -4.331315,
    "z": -13.437668,
    "vx": 0.00000154,
    "vy": 0.00004208,
    "vz": -0.00001382,
    "rarad": 4.24631553870838,
    "decrad": -1.2245042319527,
    "pmrarad": -0.00000122730583232638,
    "pmdecrad": -0.000002849759054,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119069,
    "lum": 0.000562341325190349,
    "ra": 16.219731,
    "dec": -70.158924,
    "pmra": -253.15,
    "pmdec": -587.8,
    "rv": 0,
    "Asset Name": "ASTRID 000829",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Mohawk",
    "Outfit": "Tats",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000154,
    "y_1": 0.00004208,
    "z_1": -0.00001382,
    "Distance in Parsecs_1": 14.2857,
    "HYG Star ID_1": 119069,
    "Gliese Catalog_1": "NN 3943"
  },
  {
    "Astrid #": "ASTRID 000830",
    "HYG Star ID": 67274,
    "Hipparcos Catalogue": 67487,
    "Henry Draper Catalogue": 120467,
    "Gliese Catalog": "Gl 529",
    "Distance in Parsecs": 14.3,
    "mag": 8.16,
    "Absolute Visual Magnitude": 7.383,
    "Spectral Type": "K4/K5V",
    "Color Index": 1.257,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.757882,
    "y": -6.104264,
    "z": -5.382448,
    "vx": -0.00001347,
    "vy": 0.0001296,
    "vz": -0.0000176,
    "rarad": 3.6204592020222,
    "decrad": -0.385911368827207,
    "pmrarad": -0.00000847759746229861,
    "pmdecrad": -0.000002397694538,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 67274,
    "lum": 0.0970063133783956,
    "ra": 13.829135,
    "dec": -22.111093,
    "pmra": -1748.63,
    "pmdec": -494.56,
    "rv": -36.8,
    "Asset Name": "ASTRID 000830",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Sweet Nap",
    "Hair": "Elegant",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001347,
    "y_1": 0.0001296,
    "z_1": -0.0000176,
    "Distance in Parsecs_1": 14.3,
    "HYG Star ID_1": 67274,
    "Hipparcos Catalogue_1": 67487,
    "Henry Draper Catalogue_1": 120467,
    "Gliese Catalog_1": "Gl 529"
  },
  {
    "Astrid #": "ASTRID 000831",
    "HYG Star ID": 10593,
    "Hipparcos Catalogue": 10617,
    "Gliese Catalog": "NN 3148A",
    "Distance in Parsecs": 14.3246,
    "mag": 12.02,
    "Absolute Visual Magnitude": 11.24,
    "Color Index": 1.583,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.160024,
    "y": 6.897399,
    "z": -7.375226,
    "vx": -0.0000192,
    "vy": 0.0000443,
    "vz": 0.00001497,
    "rarad": 0.596403006192339,
    "decrad": -0.540849423493156,
    "pmrarad": 0.00000331190769597916,
    "pmdecrad": 0.000001219257925,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 10593,
    "base": "NN 3148",
    "lum": 0.00277971326775928,
    "var_min": 12.119,
    "var_max": 11.889,
    "ra": 2.278092,
    "dec": -30.988389,
    "pmra": 683.13,
    "pmdec": 251.49,
    "rv": 0,
    "Asset Name": "ASTRID 000831",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000192,
    "y_1": 0.0000443,
    "z_1": 0.00001497,
    "Distance in Parsecs_1": 14.3246,
    "HYG Star ID_1": 10593,
    "Hipparcos Catalogue_1": 10617,
    "Gliese Catalog_1": "NN 3148A"
  },
  {
    "Astrid #": "ASTRID 000832",
    "HYG Star ID": 118869,
    "Henry Draper Catalogue": 114379,
    "Harvard Revised Catalogue": 4969,
    "Gliese Catalog": "Gl 501B",
    "Distance in Parsecs": 14.3246,
    "mag": 5.17,
    "Absolute Visual Magnitude": 4.39,
    "Spectral Type": "F5 V",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.026264,
    "y": -4.106054,
    "z": 4.313973,
    "vx": 0.00001976,
    "vy": 0.00003725,
    "vz": 0.00000107,
    "rarad": 3.44695264311011,
    "decrad": 0.30593835959755,
    "pmrarad": -0.00000206525779779861,
    "pmdecrad": 7.03071951e-7,
    "bayer": "Alp",
    "flam": 42,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 2,
    "comp_primary": 64037,
    "base": "Gl 501",
    "lum": 1.52756605823807,
    "bf": "42Alp Com",
    "ra": 13.166389,
    "dec": 17.528977,
    "pmra": -425.99,
    "pmdec": 145.02,
    "rv": -27.7,
    "Asset Name": "ASTRID 000832",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Windy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00001976,
    "y_1": 0.00003725,
    "z_1": 0.00000107,
    "Distance in Parsecs_1": 14.3246,
    "HYG Star ID_1": 118869,
    "Henry Draper Catalogue_1": 114379,
    "Harvard Revised Catalogue_1": 4969,
    "Gliese Catalog_1": "Gl 501B"
  },
  {
    "Astrid #": "ASTRID 000833",
    "HYG Star ID": 84604,
    "Hipparcos Catalogue": 84862,
    "Henry Draper Catalogue": 157214,
    "Harvard Revised Catalogue": 6458,
    "Gliese Catalog": "Gl 672",
    "Distance in Parsecs": 14.3266,
    "mag": 5.38,
    "Absolute Visual Magnitude": 4.599,
    "Spectral Type": "G0V",
    "Color Index": 0.619,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.064569,
    "y": -11.909033,
    "z": 7.690492,
    "vx": 0.0000143,
    "vy": 0.00002741,
    "vz": -0.0001044,
    "rarad": 4.54073238883008,
    "decrad": 0.566669020623411,
    "pmrarad": 6.56437723472222e-7,
    "pmdecrad": -0.000005044728753,
    "flam": 72,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 84604,
    "lum": 1.26008545908823,
    "bf": "72    Her",
    "ra": 17.34432,
    "dec": 32.467743,
    "pmra": 135.4,
    "pmdec": -1040.55,
    "rv": -79.1,
    "Asset Name": "ASTRID 000833",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Wavy",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.0000143,
    "y_1": 0.00002741,
    "z_1": -0.0001044,
    "Distance in Parsecs_1": 14.3266,
    "HYG Star ID_1": 84604,
    "Hipparcos Catalogue_1": 84862,
    "Henry Draper Catalogue_1": 157214,
    "Harvard Revised Catalogue_1": 6458,
    "Gliese Catalog_1": "Gl 672"
  },
  {
    "Astrid #": "ASTRID 000834",
    "HYG Star ID": 69995,
    "Hipparcos Catalogue": 70218,
    "Gliese Catalog": "Gl 546",
    "Distance in Parsecs": 14.3472,
    "mag": 8.56,
    "Absolute Visual Magnitude": 7.776,
    "Spectral Type": "K5V",
    "Color Index": 1.275,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.154189,
    "y": -7.239812,
    "z": 7.092966,
    "vx": -0.00000717,
    "vy": 0.00004883,
    "vz": -0.00003738,
    "rarad": 3.76098613513512,
    "decrad": 0.517134396329333,
    "pmrarad": -0.00000306135598586805,
    "pmdecrad": -0.000001489105219,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 69995,
    "lum": 0.06754605666335,
    "ra": 14.365909,
    "dec": 29.629618,
    "pmra": -631.45,
    "pmdec": -307.15,
    "rv": -37.2,
    "Asset Name": "ASTRID 000834",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Worried Vamp",
    "Hair": "Undercut",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000717,
    "y_1": 0.00004883,
    "z_1": -0.00003738,
    "Distance in Parsecs_1": 14.3472,
    "HYG Star ID_1": 69995,
    "Hipparcos Catalogue_1": 70218,
    "Gliese Catalog_1": "Gl 546"
  },
  {
    "Astrid #": "ASTRID 000835",
    "HYG Star ID": 42319,
    "Hipparcos Catalogue": 42438,
    "Henry Draper Catalogue": 72905,
    "Harvard Revised Catalogue": 3391,
    "Gliese Catalog": "Gl 311",
    "Distance in Parsecs": 14.3554,
    "mag": 5.63,
    "Absolute Visual Magnitude": 4.845,
    "Spectral Type": "G1.5Vb",
    "Color Index": 0.618,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.880282,
    "y": 4.657464,
    "z": 13.012521,
    "vx": 0.00000854,
    "vy": -0.00000724,
    "vz": -0.00000919,
    "rarad": 2.2654174466602,
    "decrad": 1.13482890182321,
    "pmrarad": -1.34438833618055e-7,
    "pmdecrad": 4.26151225e-7,
    "bayer": "Pi-1",
    "flam": 3,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 42319,
    "lum": 1.00461579027839,
    "var": "Pi-1",
    "var_min": 5.651,
    "var_max": 5.611,
    "bf": "3Pi 1UMa",
    "ra": 8.653257,
    "dec": 65.020907,
    "pmra": -27.73,
    "pmdec": 87.9,
    "rv": -12.7,
    "Asset Name": "ASTRID 000835",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Einstein Tongue",
    "Hair": "Bangs",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000854,
    "y_1": -0.00000724,
    "z_1": -0.00000919,
    "Distance in Parsecs_1": 14.3554,
    "HYG Star ID_1": 42319,
    "Hipparcos Catalogue_1": 42438,
    "Henry Draper Catalogue_1": 72905,
    "Harvard Revised Catalogue_1": 3391,
    "Gliese Catalog_1": "Gl 311"
  },
  {
    "Astrid #": "ASTRID 000836",
    "HYG Star ID": 119064,
    "Gliese Catalog": "Gl 611B",
    "Distance in Parsecs": 14.3658,
    "mag": 14.23,
    "Absolute Visual Magnitude": 13.443,
    "Spectral Type": "m",
    "Color Index": 1.72,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.364796,
    "y": -9.762361,
    "z": 9.071466,
    "vx": -0.0000367,
    "vy": 0.0000226,
    "vz": 0.00000263,
    "rarad": 4.20994332875609,
    "decrad": 0.6834379936383,
    "pmrarad": -0.00000299658487814583,
    "pmdecrad": 2.35832766e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 78536,
    "base": "Gl 611",
    "lum": 0.000365426467750652,
    "ra": 16.080799,
    "dec": 39.158113,
    "pmra": -618.09,
    "pmdec": 48.64,
    "rv": 0,
    "Asset Name": "ASTRID 000836",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Neko",
    "Outfit": "Viking",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000367,
    "y_1": 0.0000226,
    "z_1": 0.00000263,
    "Distance in Parsecs_1": 14.3658,
    "HYG Star ID_1": 119064,
    "Gliese Catalog_1": "Gl 611B"
  },
  {
    "Astrid #": "ASTRID 000837",
    "HYG Star ID": 61513,
    "Hipparcos Catalogue": 61706,
    "Gliese Catalog": "Gl 480",
    "Distance in Parsecs": 14.3699,
    "mag": 11.49,
    "Absolute Visual Magnitude": 10.703,
    "Spectral Type": "M4",
    "Color Index": 1.475,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.869551,
    "y": -2.375376,
    "z": 2.913084,
    "vx": -0.00001267,
    "vy": 0.00007954,
    "vz": -0.00001762,
    "rarad": 3.31121357946039,
    "decrad": 0.204136596321483,
    "pmrarad": -0.00000560531881184722,
    "pmdecrad": -0.000001187163259,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 61513,
    "lum": 0.00455826951540194,
    "var_min": 11.559,
    "var_max": 11.409,
    "ra": 12.647904,
    "dec": 11.696165,
    "pmra": -1156.18,
    "pmdec": -244.87,
    "rv": -4.4,
    "Asset Name": "ASTRID 000837",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Pixie",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001267,
    "y_1": 0.00007954,
    "z_1": -0.00001762,
    "Distance in Parsecs_1": 14.3699,
    "HYG Star ID_1": 61513,
    "Hipparcos Catalogue_1": 61706,
    "Gliese Catalog_1": "Gl 480"
  },
  {
    "Astrid #": "ASTRID 000838",
    "HYG Star ID": 101668,
    "Hipparcos Catalogue": 101997,
    "Henry Draper Catalogue": 196761,
    "Harvard Revised Catalogue": 7898,
    "Gliese Catalog": "Gl 796",
    "Distance in Parsecs": 14.3823,
    "mag": 6.36,
    "Absolute Visual Magnitude": 5.571,
    "Spectral Type": "G8/K0V",
    "Color Index": 0.719,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.468665,
    "y": -10.075057,
    "z": -5.797743,
    "vx": 0.00000778,
    "vy": 0.00004501,
    "vz": 0.00004808,
    "rarad": 5.41137360580811,
    "decrad": -0.414932262009252,
    "pmrarad": 0.00000242809235632638,
    "pmdecrad": 0.000002233827514,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 101668,
    "lum": 0.514754319931262,
    "ra": 20.669925,
    "dec": -23.773867,
    "pmra": 500.83,
    "pmdec": 460.76,
    "rv": -45.3,
    "Asset Name": "ASTRID 000838",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Afro",
    "Outfit": "River Spirit",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000778,
    "y_1": 0.00004501,
    "z_1": 0.00004808,
    "Distance in Parsecs_1": 14.3823,
    "HYG Star ID_1": 101668,
    "Hipparcos Catalogue_1": 101997,
    "Henry Draper Catalogue_1": 196761,
    "Harvard Revised Catalogue_1": 7898,
    "Gliese Catalog_1": "Gl 796"
  },
  {
    "Astrid #": "ASTRID 000839",
    "HYG Star ID": 103468,
    "Hipparcos Catalogue": 103800,
    "Gliese Catalog": "Gl 816",
    "Distance in Parsecs": 14.3823,
    "mag": 11.23,
    "Absolute Visual Magnitude": 10.441,
    "Spectral Type": "M3",
    "Color Index": 1.527,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.194874,
    "y": -10.020454,
    "z": -1.58291,
    "vx": -0.00001364,
    "vy": -0.000009,
    "vz": -0.00003085,
    "rarad": 5.50641507063742,
    "decrad": -0.110282969189553,
    "pmrarad": -0.00000111124143720138,
    "pmdecrad": -0.000002158535949,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 103468,
    "lum": 0.00580229759981872,
    "var_min": 11.33,
    "var_max": 11.15,
    "ra": 21.032956,
    "dec": -6.318749,
    "pmra": -229.21,
    "pmdec": -445.23,
    "rv": 0,
    "Asset Name": "ASTRID 000839",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Braid",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00001364,
    "y_1": -0.000009,
    "z_1": -0.00003085,
    "Distance in Parsecs_1": 14.3823,
    "HYG Star ID_1": 103468,
    "Hipparcos Catalogue_1": 103800,
    "Gliese Catalog_1": "Gl 816"
  },
  {
    "Astrid #": "ASTRID 000840",
    "HYG Star ID": 15182,
    "Hipparcos Catalogue": 15220,
    "Gliese Catalog": "Gl 130.1A",
    "Distance in Parsecs": 14.3864,
    "mag": 10.53,
    "Absolute Visual Magnitude": 9.74,
    "Spectral Type": "M2",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.972459,
    "y": 5.731781,
    "z": 12.222746,
    "vx": -0.00000232,
    "vy": 0.00004321,
    "vz": 0.00000716,
    "rarad": 0.856199699020167,
    "decrad": 1.01521229823202,
    "pmrarad": 0.00000209051659055555,
    "pmdecrad": -0.000001575402054,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 15182,
    "base": "Gl 130.1",
    "lum": 0.0110662378397766,
    "var_min": 10.656,
    "var_max": 10.446,
    "ra": 3.270442,
    "dec": 58.16738,
    "pmra": 431.2,
    "pmdec": -324.95,
    "rv": 22,
    "Asset Name": "ASTRID 000840",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Guilty Laugh",
    "Hair": "Elegant",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000232,
    "y_1": 0.00004321,
    "z_1": 0.00000716,
    "Distance in Parsecs_1": 14.3864,
    "HYG Star ID_1": 15182,
    "Hipparcos Catalogue_1": 15220,
    "Gliese Catalog_1": "Gl 130.1A"
  },
  {
    "Astrid #": "ASTRID 000841",
    "HYG Star ID": 25215,
    "Hipparcos Catalogue": 25278,
    "Henry Draper Catalogue": 35296,
    "Harvard Revised Catalogue": 1780,
    "Gliese Catalog": "Gl 202",
    "Distance in Parsecs": 14.3864,
    "mag": 5,
    "Absolute Visual Magnitude": 4.21,
    "Spectral Type": "F8V SB",
    "Color Index": 0.544,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.122676,
    "y": 13.564563,
    "z": 4.298277,
    "vx": -0.00001148,
    "vy": 0.00003959,
    "vz": 0.00001115,
    "rarad": 1.41556793126774,
    "decrad": 0.303399903234718,
    "pmrarad": 0.00000121397345611111,
    "pmdecrad": -3.5973175e-8,
    "flam": 111,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 25215,
    "lum": 1.80301774085956,
    "var": "V1119",
    "var_min": 5.026,
    "var_max": 4.986,
    "bf": "111    Tau",
    "ra": 5.407071,
    "dec": 17.383534,
    "pmra": 250.4,
    "pmdec": -7.42,
    "rv": 38.1,
    "Asset Name": "ASTRID 000841",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Windy",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00001148,
    "y_1": 0.00003959,
    "z_1": 0.00001115,
    "Distance in Parsecs_1": 14.3864,
    "HYG Star ID_1": 25215,
    "Hipparcos Catalogue_1": 25278,
    "Henry Draper Catalogue_1": 35296,
    "Harvard Revised Catalogue_1": 1780,
    "Gliese Catalog_1": "Gl 202"
  },
  {
    "Astrid #": "ASTRID 000842",
    "HYG Star ID": 79191,
    "Hipparcos Catalogue": 79431,
    "Distance in Parsecs": 14.3968,
    "mag": 11.34,
    "Absolute Visual Magnitude": 10.549,
    "Color Index": 1.486,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.147626,
    "y": -12.156552,
    "z": -4.657551,
    "vx": 0.00000365,
    "vy": 0.00000349,
    "vz": -0.00001393,
    "rarad": 4.24418794119927,
    "decrad": -0.329439681206237,
    "pmrarad": 1.1693705975e-7,
    "pmdecrad": -0.00000102242357,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 79191,
    "lum": 0.00525291048450048,
    "var_min": 11.396,
    "var_max": 11.286,
    "ra": 16.211604,
    "dec": -18.875503,
    "pmra": 24.12,
    "pmdec": -210.89,
    "rv": 0,
    "Asset Name": "ASTRID 000842",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Happy Vamp",
    "Hair": "Ninja",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000365,
    "y_1": 0.00000349,
    "z_1": -0.00001393,
    "Distance in Parsecs_1": 14.3968,
    "HYG Star ID_1": 79191,
    "Hipparcos Catalogue_1": 79431
  },
  {
    "Astrid #": "ASTRID 000843",
    "HYG Star ID": 119351,
    "Gliese Catalog": "Gl 794",
    "Distance in Parsecs": 14.4092,
    "mag": 11.52,
    "Absolute Visual Magnitude": 10.727,
    "Spectral Type": "DA3",
    "Color Index": -0.07,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.143568,
    "y": -10.204518,
    "z": 6.10572,
    "vx": 0.00003086,
    "vy": -0.0000802,
    "vz": -0.00000377,
    "rarad": 5.38594025191039,
    "decrad": 0.4374497846509,
    "pmrarad": -0.000001798561792125,
    "pmdecrad": -0.000002646466982,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 119351,
    "lum": 0.0044586152794807,
    "ra": 20.572776,
    "dec": 25.064026,
    "pmra": -370.98,
    "pmdec": -545.87,
    "rv": 71,
    "Asset Name": "ASTRID 000843",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Pompadour",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00003086,
    "y_1": -0.0000802,
    "z_1": -0.00000377,
    "Distance in Parsecs_1": 14.4092,
    "HYG Star ID_1": 119351,
    "Gliese Catalog_1": "Gl 794"
  },
  {
    "Astrid #": "ASTRID 000844",
    "HYG Star ID": 95983,
    "Hipparcos Catalogue": 96285,
    "Henry Draper Catalogue": 184489,
    "Gliese Catalog": "Gl 763",
    "Distance in Parsecs": 14.4259,
    "mag": 9.35,
    "Absolute Visual Magnitude": 8.554,
    "Spectral Type": "K5",
    "Color Index": 1.39,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.771903,
    "y": -13.16999,
    "z": 1.152512,
    "vx": 0.00000943,
    "vy": 0.00006993,
    "vz": 0.00001707,
    "rarad": 5.12543852646211,
    "decrad": 0.0799799982313812,
    "pmrarad": 0.00000254473852786805,
    "pmdecrad": 0.000001513055015,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 95983,
    "lum": 0.0329913433788864,
    "ra": 19.577733,
    "dec": 4.582516,
    "pmra": 524.89,
    "pmdec": 312.09,
    "rv": -57.4,
    "Asset Name": "ASTRID 000844",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Mohawk",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000943,
    "y_1": 0.00006993,
    "z_1": 0.00001707,
    "Distance in Parsecs_1": 14.4259,
    "HYG Star ID_1": 95983,
    "Hipparcos Catalogue_1": 96285,
    "Henry Draper Catalogue_1": 184489,
    "Gliese Catalog_1": "Gl 763"
  },
  {
    "Astrid #": "ASTRID 000845",
    "HYG Star ID": 118641,
    "Gliese Catalog": "Gl 363",
    "Distance in Parsecs": 14.43,
    "mag": 12.5,
    "Absolute Visual Magnitude": 11.704,
    "Spectral Type": "M3.5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.660402,
    "y": 4.560523,
    "z": 11.961003,
    "vx": 0.00000504,
    "vy": 0.00005887,
    "vz": -0.00001962,
    "rarad": 2.54133460421882,
    "decrad": 0.97713543675815,
    "pmrarad": -0.00000356449562354861,
    "pmdecrad": -0.000002431524837,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118641,
    "lum": 0.00181300916701701,
    "ra": 9.707183,
    "dec": 55.985737,
    "pmra": -735.23,
    "pmdec": -501.54,
    "rv": 0,
    "Asset Name": "ASTRID 000845",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Afro",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000504,
    "y_1": 0.00005887,
    "z_1": -0.00001962,
    "Distance in Parsecs_1": 14.43,
    "HYG Star ID_1": 118641,
    "Gliese Catalog_1": "Gl 363"
  },
  {
    "Astrid #": "ASTRID 000846",
    "HYG Star ID": 118066,
    "Gliese Catalog": "NN 3093A",
    "Distance in Parsecs": 14.4509,
    "mag": 13.97,
    "Absolute Visual Magnitude": 13.171,
    "Spectral Type": "m",
    "Color Index": 1.76,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.51852,
    "y": 5.104909,
    "z": 0.13434,
    "vx": 0.0000056,
    "vy": -0.00001386,
    "vz": -0.00003627,
    "rarad": 0.361068617380284,
    "decrad": 0.0092964290705,
    "pmrarad": -0.00000103459239430555,
    "pmdecrad": -0.00000251009859,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118066,
    "base": "NN 3093",
    "lum": 0.00046946151938259,
    "ra": 1.379181,
    "dec": 0.532646,
    "pmra": -213.4,
    "pmdec": -517.75,
    "rv": 0,
    "Asset Name": "ASTRID 000846",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Fire",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000056,
    "y_1": -0.00001386,
    "z_1": -0.00003627,
    "Distance in Parsecs_1": 14.4509,
    "HYG Star ID_1": 118066,
    "Gliese Catalog_1": "NN 3093A"
  },
  {
    "Astrid #": "ASTRID 000847",
    "HYG Star ID": 118067,
    "Gliese Catalog": "NN 3094B",
    "Distance in Parsecs": 14.4509,
    "mag": 15,
    "Absolute Visual Magnitude": 14.201,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.51852,
    "y": 5.104909,
    "z": 0.13434,
    "vx": 0.0000056,
    "vy": -0.00001386,
    "vz": -0.00003627,
    "rarad": 0.361068617380284,
    "decrad": 0.0092964290705,
    "pmrarad": -0.00000103459239430555,
    "pmdecrad": -0.00000251009859,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118067,
    "base": "NN 3094",
    "lum": 0.000181802562377426,
    "ra": 1.379181,
    "dec": 0.532646,
    "pmra": -213.4,
    "pmdec": -517.75,
    "rv": 0,
    "Asset Name": "ASTRID 000847",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Side Part",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000056,
    "y_1": -0.00001386,
    "z_1": -0.00003627,
    "Distance in Parsecs_1": 14.4509,
    "HYG Star ID_1": 118067,
    "Gliese Catalog_1": "NN 3094B"
  },
  {
    "Astrid #": "ASTRID 000848",
    "HYG Star ID": 119178,
    "Gliese Catalog": "NN 4026",
    "Distance in Parsecs": 14.4509,
    "mag": 12.67,
    "Absolute Visual Magnitude": 11.871,
    "Spectral Type": "m",
    "Color Index": 1.51,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.796246,
    "y": -13.109941,
    "z": 6.026936,
    "vx": -0.00002209,
    "vy": 0.00001603,
    "vz": 0.00003197,
    "rarad": 4.65173543265669,
    "decrad": 0.4302114772934,
    "pmrarad": -0.00000159280686609722,
    "pmdecrad": 0.000002434045868,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119178,
    "lum": 0.00155453319406078,
    "ra": 17.768321,
    "dec": 24.649302,
    "pmra": -328.54,
    "pmdec": 502.06,
    "rv": 0,
    "Asset Name": "ASTRID 000848",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Shinobi",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002209,
    "y_1": 0.00001603,
    "z_1": 0.00003197,
    "Distance in Parsecs_1": 14.4509,
    "HYG Star ID_1": 119178,
    "Gliese Catalog_1": "NN 4026"
  },
  {
    "Astrid #": "ASTRID 000849",
    "HYG Star ID": 119221,
    "Gliese Catalog": "Gl 714",
    "Distance in Parsecs": 14.4509,
    "mag": 9.85,
    "Absolute Visual Magnitude": 9.051,
    "Spectral Type": "M1",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.998356,
    "y": -7.532662,
    "z": -12.291695,
    "vx": -0.00000568,
    "vy": 0.00002687,
    "vz": -0.00001285,
    "rarad": 4.84416454879479,
    "decrad": -1.01711317689895,
    "pmrarad": -1.45444104166666e-7,
    "pmdecrad": -0.000002079617979,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 119221,
    "lum": 0.0208737270391054,
    "ra": 18.503346,
    "dec": -58.276292,
    "pmra": -30,
    "pmdec": -428.95,
    "rv": -3.4,
    "Asset Name": "ASTRID 000849",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Pompadour",
    "Outfit": "Mecha",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00000568,
    "y_1": 0.00002687,
    "z_1": -0.00001285,
    "Distance in Parsecs_1": 14.4509,
    "HYG Star ID_1": 119221,
    "Gliese Catalog_1": "Gl 714"
  },
  {
    "Astrid #": "ASTRID 000850",
    "HYG Star ID": 73962,
    "Hipparcos Catalogue": 74190,
    "Gliese Catalog": "NN 3892",
    "Distance in Parsecs": 14.453,
    "mag": 11.46,
    "Absolute Visual Magnitude": 10.66,
    "Spectral Type": "M:",
    "Color Index": 1.511,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.768264,
    "y": -10.622276,
    "z": 0.798432,
    "vx": -0.00002974,
    "vy": 0.00002988,
    "vz": 0.00003376,
    "rarad": 3.9688491293153,
    "decrad": 0.0552714820759962,
    "pmrarad": -0.00000291402110834722,
    "pmdecrad": 0.000002339322971,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 73962,
    "lum": 0.00474241985260244,
    "var_min": 11.537,
    "var_max": 11.367,
    "ra": 15.159887,
    "dec": 3.166823,
    "pmra": -601.06,
    "pmdec": 482.52,
    "rv": 0,
    "Asset Name": "ASTRID 000850",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Guilty Laugh",
    "Hair": "Ponytail",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002974,
    "y_1": 0.00002988,
    "z_1": 0.00003376,
    "Distance in Parsecs_1": 14.453,
    "HYG Star ID_1": 73962,
    "Hipparcos Catalogue_1": 74190,
    "Gliese Catalog_1": "NN 3892"
  },
  {
    "Astrid #": "ASTRID 000851",
    "HYG Star ID": 118893,
    "Gliese Catalog": "GJ 1171",
    "Distance in Parsecs": 14.4718,
    "mag": 14.73,
    "Absolute Visual Magnitude": 13.927,
    "Color Index": 1.8,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -12.617921,
    "y": -5.259969,
    "z": 4.749084,
    "vx": -0.00004083,
    "vy": 0.00001997,
    "vz": -0.00008627,
    "rarad": 3.53655954577422,
    "decrad": 0.33435632359655,
    "pmrarad": -0.00000236002451557638,
    "pmdecrad": -0.000006312148069,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118893,
    "lum": 0.000233991456105509,
    "ra": 13.508662,
    "dec": 19.157206,
    "pmra": -486.79,
    "pmdec": -1301.97,
    "rv": 0,
    "Asset Name": "ASTRID 000851",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Ponytail",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004083,
    "y_1": 0.00001997,
    "z_1": -0.00008627,
    "Distance in Parsecs_1": 14.4718,
    "HYG Star ID_1": 118893,
    "Gliese Catalog_1": "GJ 1171"
  },
  {
    "Astrid #": "ASTRID 000852",
    "HYG Star ID": 119213,
    "Gliese Catalog": "Gl 712",
    "Distance in Parsecs": 14.4718,
    "mag": 12.58,
    "Absolute Visual Magnitude": 11.777,
    "Spectral Type": "M3",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.385039,
    "y": -14.314009,
    "z": 1.597747,
    "vx": -0.00008497,
    "vy": 0.0000412,
    "vz": -0.00000164,
    "rarad": 4.80885148141443,
    "decrad": 0.11064849266335,
    "pmrarad": -0.00000556915171127777,
    "pmdecrad": 2.62633267e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119213,
    "lum": 0.0016951182515314,
    "ra": 18.36846,
    "dec": 6.339692,
    "pmra": -1148.72,
    "pmdec": 54.17,
    "rv": -48,
    "Asset Name": "ASTRID 000852",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Fire",
    "Outfit": "Mecha",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00008497,
    "y_1": 0.0000412,
    "z_1": -0.00000164,
    "Distance in Parsecs_1": 14.4718,
    "HYG Star ID_1": 119213,
    "Gliese Catalog_1": "Gl 712"
  },
  {
    "Astrid #": "ASTRID 000853",
    "HYG Star ID": 35256,
    "Hipparcos Catalogue": 35353,
    "Gliese Catalog": "NN 3438",
    "Distance in Parsecs": 14.4907,
    "mag": 10.3,
    "Absolute Visual Magnitude": 9.495,
    "Spectral Type": "M1",
    "Color Index": 1.508,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.750777,
    "y": 10.57187,
    "z": 9.173201,
    "vx": 0.00001234,
    "vy": 0.00000984,
    "vz": -0.0000063,
    "rarad": 1.91173321343459,
    "decrad": 0.685474751352747,
    "pmrarad": -0.00000102955033202777,
    "pmdecrad": -5.61559686e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 35256,
    "lum": 0.0138675582887188,
    "ra": 7.302283,
    "dec": 39.27481,
    "pmra": -212.36,
    "pmdec": -115.83,
    "rv": 0,
    "Asset Name": "ASTRID 000853",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Heartbreaker",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00001234,
    "y_1": 0.00000984,
    "z_1": -0.0000063,
    "Distance in Parsecs_1": 14.4907,
    "HYG Star ID_1": 35256,
    "Hipparcos Catalogue_1": 35353,
    "Gliese Catalog_1": "NN 3438"
  },
  {
    "Astrid #": "ASTRID 000854",
    "HYG Star ID": 67095,
    "Hipparcos Catalogue": 67308,
    "Henry Draper Catalogue": 120036,
    "Gliese Catalog": "GJ 1177A",
    "Distance in Parsecs": 14.4907,
    "mag": 8.13,
    "Absolute Visual Magnitude": 7.325,
    "Spectral Type": "K5/M1V+K5",
    "Color Index": 1.322,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.904945,
    "y": -5.538541,
    "z": -7.770915,
    "vx": 0.0000034,
    "vy": -0.00000514,
    "vz": -0.0000011,
    "rarad": 3.61153368827995,
    "decrad": -0.566010643882686,
    "pmrarad": 4.22660566708333e-7,
    "pmdecrad": -9.0272307e-8,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 67095,
    "base": "GJ 1177",
    "lum": 0.102329299228075,
    "ra": 13.795043,
    "dec": -32.430021,
    "pmra": 87.18,
    "pmdec": -18.62,
    "rv": 0,
    "Asset Name": "ASTRID 000854",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "High Bun",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.0000034,
    "y_1": -0.00000514,
    "z_1": -0.0000011,
    "Distance in Parsecs_1": 14.4907,
    "HYG Star ID_1": 67095,
    "Hipparcos Catalogue_1": 67308,
    "Henry Draper Catalogue_1": 120036,
    "Gliese Catalog_1": "GJ 1177A"
  },
  {
    "Astrid #": "ASTRID 000855",
    "HYG Star ID": 118698,
    "Gliese Catalog": "NN 3614",
    "Distance in Parsecs": 14.4928,
    "mag": 16.46,
    "Absolute Visual Magnitude": 15.654,
    "Spectral Type": "DQP9",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -12.719236,
    "y": 4.696902,
    "z": -5.118732,
    "vx": -0.00000507,
    "vy": 0.00002488,
    "vz": 0.0000354,
    "rarad": 2.7878552584668,
    "decrad": -0.3609801135538,
    "pmrarad": -0.00000148925066393055,
    "pmdecrad": 0.000002610968924,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118698,
    "lum": 0.0000476869998101777,
    "ra": 10.648823,
    "dec": -20.682637,
    "pmra": -307.18,
    "pmdec": 538.55,
    "rv": 0,
    "Asset Name": "ASTRID 000855",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Halo Boy",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000507,
    "y_1": 0.00002488,
    "z_1": 0.0000354,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 118698,
    "Gliese Catalog_1": "NN 3614"
  },
  {
    "Astrid #": "ASTRID 000856",
    "HYG Star ID": 118814,
    "Gliese Catalog": "GJ 1157",
    "Distance in Parsecs": 14.4928,
    "mag": 13.62,
    "Absolute Visual Magnitude": 12.814,
    "Spectral Type": "k",
    "Color Index": 1.61,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.904109,
    "y": -0.999035,
    "z": -10.536322,
    "vx": -0.00001779,
    "vy": 0.00004896,
    "vz": -0.00004703,
    "rarad": 3.24220277299472,
    "decrad": -0.813791517177699,
    "pmrarad": -0.00000348518010540972,
    "pmdecrad": -0.000001588283554,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118814,
    "lum": 0.000652228842465638,
    "ra": 12.384302,
    "dec": -46.626819,
    "pmra": -718.87,
    "pmdec": -327.61,
    "rv": 42,
    "Asset Name": "ASTRID 000856",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Arrogant",
    "Hair": "Middle Part",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001779,
    "y_1": 0.00004896,
    "z_1": -0.00004703,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 118814,
    "Gliese Catalog_1": "GJ 1157"
  },
  {
    "Astrid #": "ASTRID 000857",
    "HYG Star ID": 118918,
    "Gliese Catalog": "GJ 1177B",
    "Distance in Parsecs": 14.4928,
    "mag": 9.12,
    "Absolute Visual Magnitude": 8.314,
    "Color Index": 1.36,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -10.906067,
    "y": -5.539571,
    "z": -7.772522,
    "vx": 0.00000534,
    "vy": -0.00000541,
    "vz": -0.00000364,
    "rarad": 3.61156335829709,
    "decrad": -0.566050007688299,
    "pmrarad": 4.99745941916666e-7,
    "pmdecrad": -2.97893766e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 2,
    "comp_primary": 67095,
    "base": "GJ 1177",
    "lum": 0.041152857843551,
    "ra": 13.795156,
    "dec": -32.432276,
    "pmra": 103.08,
    "pmdec": -61.45,
    "rv": 0,
    "Asset Name": "ASTRID 000857",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Goddess",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000534,
    "y_1": -0.00000541,
    "z_1": -0.00000364,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 118918,
    "Gliese Catalog_1": "GJ 1177B"
  },
  {
    "Astrid #": "ASTRID 000858",
    "HYG Star ID": 118985,
    "Gliese Catalog": "NN 3866",
    "Distance in Parsecs": 14.4928,
    "mag": 16.48,
    "Absolute Visual Magnitude": 15.674,
    "Spectral Type": "DC9",
    "Color Index": 0.94,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -10.2838,
    "y": -9.211754,
    "z": -4.407754,
    "vx": -0.00004679,
    "vy": 0.0000634,
    "vz": -0.00002342,
    "rarad": 3.8720702984591,
    "decrad": -0.309029268578049,
    "pmrarad": -0.00000541275081793055,
    "pmdecrad": -0.000001696251561,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118985,
    "lum": 0.0000468166139724364,
    "ra": 14.790219,
    "dec": -17.706073,
    "pmra": -1116.46,
    "pmdec": -349.88,
    "rv": 0,
    "Asset Name": "ASTRID 000858",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Awkward",
    "Hair": "Bangs",
    "Outfit": "River Spirit",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004679,
    "y_1": 0.0000634,
    "z_1": -0.00002342,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 118985,
    "Gliese Catalog_1": "NN 3866"
  },
  {
    "Astrid #": "ASTRID 000859",
    "HYG Star ID": 119009,
    "Gliese Catalog": "NN 3893",
    "Distance in Parsecs": 14.4928,
    "mag": 13.35,
    "Absolute Visual Magnitude": 12.544,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.23455,
    "y": -10.084104,
    "z": 4.803665,
    "vx": -0.00000707,
    "vy": -0.00000805,
    "vz": -0.00003049,
    "rarad": 3.97093825912595,
    "decrad": 0.3378419117624,
    "pmrarad": 1.55625191458333e-8,
    "pmdecrad": -0.000002230084752,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119009,
    "lum": 0.000836372991584025,
    "ra": 15.167867,
    "dec": 19.356916,
    "pmra": 3.21,
    "pmdec": -459.99,
    "rv": 0,
    "Asset Name": "ASTRID 000859",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Manbun",
    "Outfit": "Elven",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000707,
    "y_1": -0.00000805,
    "z_1": -0.00003049,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 119009,
    "Gliese Catalog_1": "NN 3893"
  },
  {
    "Astrid #": "ASTRID 000860",
    "HYG Star ID": 119097,
    "Gliese Catalog": "Gl 630.1A",
    "Distance in Parsecs": 14.4928,
    "mag": 12.9,
    "Absolute Visual Magnitude": 12.094,
    "Spectral Type": "dM4  e",
    "Color Index": 1.6,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.869043,
    "y": -7.313399,
    "z": 12.17162,
    "vx": -0.00002453,
    "vy": 0.00015415,
    "vz": -0.00005747,
    "rarad": 4.33876901946229,
    "decrad": 0.99763444696315,
    "pmrarad": -0.00000545585075413194,
    "pmdecrad": 0.000005649679263,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119097,
    "base": "Gl 630.1",
    "lum": 0.00126590174930024,
    "ra": 16.572877,
    "dec": 57.160243,
    "pmra": -1125.35,
    "pmdec": 1165.33,
    "rv": -118.6,
    "Asset Name": "ASTRID 000860",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Tiny Crown",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002453,
    "y_1": 0.00015415,
    "z_1": -0.00005747,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 119097,
    "Gliese Catalog_1": "Gl 630.1A"
  },
  {
    "Astrid #": "ASTRID 000861",
    "HYG Star ID": 119098,
    "Gliese Catalog": "Gl 630.1B",
    "Distance in Parsecs": 14.4928,
    "mag": 15,
    "Absolute Visual Magnitude": 14.194,
    "Spectral Type": "DQ8",
    "Color Index": 0.49,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.868675,
    "y": -7.315549,
    "z": 12.177632,
    "vx": -0.00004854,
    "vy": 0.00009287,
    "vz": 0.00004441,
    "rarad": 4.33891226446229,
    "decrad": 0.99775145896315,
    "pmrarad": -0.00000545585075413194,
    "pmdecrad": 0.000005649679263,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119097,
    "base": "Gl 630.1",
    "lum": 0.000182978473429541,
    "ra": 16.573424,
    "dec": 57.166948,
    "pmra": -1125.35,
    "pmdec": 1165.33,
    "rv": 0,
    "Asset Name": "ASTRID 000861",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Middle Part",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004854,
    "y_1": 0.00009287,
    "z_1": 0.00004441,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 119098,
    "Gliese Catalog_1": "Gl 630.1B"
  },
  {
    "Astrid #": "ASTRID 000862",
    "HYG Star ID": 119138,
    "Gliese Catalog": "NN 3997",
    "Distance in Parsecs": 14.4928,
    "mag": 10.36,
    "Absolute Visual Magnitude": 9.554,
    "Spectral Type": "M2",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.62387,
    "y": -13.449755,
    "z": 4.718116,
    "vx": 0.00001158,
    "vy": -0.00000267,
    "vz": -0.00000118,
    "rarad": 4.51971972172448,
    "decrad": 0.3315922696729,
    "pmrarad": 8.19674489715277e-7,
    "pmdecrad": -8.6146542e-8,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 119138,
    "lum": 0.0131340903653273,
    "ra": 17.264058,
    "dec": 18.998838,
    "pmra": 169.07,
    "pmdec": -17.77,
    "rv": 0,
    "Asset Name": "ASTRID 000862",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Audiophile",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001158,
    "y_1": -0.00000267,
    "z_1": -0.00000118,
    "Distance in Parsecs_1": 14.4928,
    "HYG Star ID_1": 119138,
    "Gliese Catalog_1": "NN 3997"
  },
  {
    "Astrid #": "ASTRID 000863",
    "HYG Star ID": 14409,
    "Hipparcos Catalogue": 14445,
    "Henry Draper Catalogue": 19305,
    "Gliese Catalog": "Gl 123",
    "Distance in Parsecs": 14.5054,
    "mag": 9.07,
    "Absolute Visual Magnitude": 8.262,
    "Spectral Type": "M0V",
    "Color Index": 1.358,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.958335,
    "y": 10.534844,
    "z": 0.497411,
    "vx": -0.00003958,
    "vy": -0.00000199,
    "vz": -0.0000661,
    "rarad": 0.813522473874158,
    "decrad": 0.03429875387898,
    "pmrarad": 0.00000188878561807638,
    "pmdecrad": -0.000004486756688,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 14409,
    "lum": 0.0431717844470922,
    "ra": 3.107427,
    "dec": 1.965174,
    "pmra": 389.59,
    "pmdec": -925.46,
    "rv": -30.2,
    "Asset Name": "ASTRID 000863",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Shinobi",
    "Hair": "Bob",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00003958,
    "y_1": -0.00000199,
    "z_1": -0.0000661,
    "Distance in Parsecs_1": 14.5054,
    "HYG Star ID_1": 14409,
    "Hipparcos Catalogue_1": 14445,
    "Henry Draper Catalogue_1": 19305,
    "Gliese Catalog_1": "Gl 123"
  },
  {
    "Astrid #": "ASTRID 000864",
    "HYG Star ID": 44000,
    "Hipparcos Catalogue": 44127,
    "Henry Draper Catalogue": 76644,
    "Harvard Revised Catalogue": 3569,
    "Gliese Catalog": "Gl 331A",
    "Distance in Parsecs": 14.5096,
    "mag": 3.12,
    "Absolute Visual Magnitude": 2.312,
    "Spectral Type": "A7IV",
    "Color Index": 0.223,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -6.835882,
    "y": 6.883318,
    "z": 10.789878,
    "vx": 0.00000975,
    "vy": 0.00003422,
    "vz": -0.00000328,
    "rarad": 2.35274611016155,
    "decrad": 0.838488049534191,
    "pmrarad": -0.00000213861010766666,
    "pmdecrad": -0.000001043367521,
    "bayer": "Iot",
    "flam": 9,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 44000,
    "base": "Gl 331",
    "lum": 10.3561897704498,
    "bf": "9Iot UMa",
    "ra": 8.986828,
    "dec": 48.041826,
    "pmra": -441.12,
    "pmdec": -215.21,
    "rv": 9,
    "Asset Name": "ASTRID 000864",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Afro",
    "Outfit": "Emperor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000975,
    "y_1": 0.00003422,
    "z_1": -0.00000328,
    "Distance in Parsecs_1": 14.5096,
    "HYG Star ID_1": 44000,
    "Hipparcos Catalogue_1": 44127,
    "Henry Draper Catalogue_1": 76644,
    "Harvard Revised Catalogue_1": 3569,
    "Gliese Catalog_1": "Gl 331A"
  },
  {
    "Astrid #": "ASTRID 000865",
    "HYG Star ID": 78536,
    "Hipparcos Catalogue": 78775,
    "Henry Draper Catalogue": 144579,
    "Gliese Catalog": "Gl 611A",
    "Distance in Parsecs": 14.5201,
    "mag": 6.66,
    "Absolute Visual Magnitude": 5.85,
    "Spectral Type": "G8V",
    "Color Index": 0.734,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.417667,
    "y": -9.869644,
    "z": 9.168246,
    "vx": -0.00001123,
    "vy": 0.00006311,
    "vz": -0.00003588,
    "rarad": 4.21038062731539,
    "decrad": 0.683410005930747,
    "pmrarad": -0.00000276944966880555,
    "pmdecrad": 2.54963514e-7,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 78536,
    "base": "Gl 611",
    "lum": 0.398107170553497,
    "ra": 16.082469,
    "dec": 39.156509,
    "pmra": -571.24,
    "pmdec": 52.59,
    "rv": -60,
    "Asset Name": "ASTRID 000865",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Ponytail",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": -0.00001123,
    "y_1": 0.00006311,
    "z_1": -0.00003588,
    "Distance in Parsecs_1": 14.5201,
    "HYG Star ID_1": 78536,
    "Hipparcos Catalogue_1": 78775,
    "Henry Draper Catalogue_1": 144579,
    "Gliese Catalog_1": "Gl 611A"
  },
  {
    "Astrid #": "ASTRID 000866",
    "HYG Star ID": 70274,
    "Hipparcos Catalogue": 70497,
    "Henry Draper Catalogue": 126660,
    "Harvard Revised Catalogue": 5404,
    "Gliese Catalog": "Gl 549A",
    "Distance in Parsecs": 14.5307,
    "mag": 4.04,
    "Absolute Visual Magnitude": 3.229,
    "Spectral Type": "F7V",
    "Color Index": 0.497,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.233871,
    "y": -5.313642,
    "z": 11.426925,
    "vx": -0.00002186,
    "vy": 0.00000458,
    "vz": -0.00002653,
    "rarad": 3.77513855905222,
    "decrad": 0.904966193972902,
    "pmrarad": -0.00000114445117431944,
    "pmdecrad": -0.000001934745954,
    "bayer": "The",
    "flam": 23,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 70274,
    "base": "Gl 549",
    "lum": 4.45040976649537,
    "bf": "23The Boo",
    "ra": 14.419967,
    "dec": 51.850744,
    "pmra": -236.06,
    "pmdec": -399.07,
    "rv": -11.4,
    "Asset Name": "ASTRID 000866",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Oh Snap",
    "Hair": "High Fade",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00002186,
    "y_1": 0.00000458,
    "z_1": -0.00002653,
    "Distance in Parsecs_1": 14.5307,
    "HYG Star ID_1": 70274,
    "Hipparcos Catalogue_1": 70497,
    "Henry Draper Catalogue_1": 126660,
    "Harvard Revised Catalogue_1": 5404,
    "Gliese Catalog_1": "Gl 549A"
  },
  {
    "Astrid #": "ASTRID 000867",
    "HYG Star ID": 112549,
    "Hipparcos Catalogue": 112909,
    "Gliese Catalog": "Gl 875.1",
    "Distance in Parsecs": 14.5391,
    "mag": 11.66,
    "Absolute Visual Magnitude": 10.847,
    "Spectral Type": "M3.5Ve",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.820871,
    "y": -3.620079,
    "z": 7.651569,
    "vx": 0.0000096,
    "vy": 0.00003591,
    "vz": -0.00000502,
    "rarad": 5.98600540139505,
    "decrad": 0.55421559791165,
    "pmrarad": 0.00000255501657789583,
    "pmdecrad": -2.4526724e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112549,
    "lum": 0.00399208702438807,
    "var": "GT",
    "var_min": 11.755,
    "var_max": 11.585,
    "ra": 22.864856,
    "dec": 31.754215,
    "pmra": 527.01,
    "pmdec": -50.59,
    "rv": -3.7,
    "Asset Name": "ASTRID 000867",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Oh No",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.0000096,
    "y_1": 0.00003591,
    "z_1": -0.00000502,
    "Distance in Parsecs_1": 14.5391,
    "HYG Star ID_1": 112549,
    "Hipparcos Catalogue_1": 112909,
    "Gliese Catalog_1": "Gl 875.1"
  },
  {
    "Astrid #": "ASTRID 000868",
    "HYG Star ID": 118573,
    "Gliese Catalog": "Gl 326A",
    "Distance in Parsecs": 14.556,
    "mag": 12.52,
    "Absolute Visual Magnitude": 11.705,
    "Spectral Type": "M6   J",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.762143,
    "y": 10.278856,
    "z": -3.304967,
    "vx": -0.00001091,
    "vy": -0.00002199,
    "vz": -0.00003621,
    "rarad": 2.33041528773878,
    "decrad": -0.22904950216355,
    "pmrarad": 0.00000158393477574305,
    "pmdecrad": -0.000002554643271,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118573,
    "base": "Gl 326",
    "lum": 0.0018113400926196,
    "ra": 8.901531,
    "dec": -13.12357,
    "pmra": 326.71,
    "pmdec": -526.93,
    "rv": 0,
    "Asset Name": "ASTRID 000868",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Prince",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001091,
    "y_1": -0.00002199,
    "z_1": -0.00003621,
    "Distance in Parsecs_1": 14.556,
    "HYG Star ID_1": 118573,
    "Gliese Catalog_1": "Gl 326A"
  },
  {
    "Astrid #": "ASTRID 000869",
    "HYG Star ID": 118574,
    "Gliese Catalog": "Gl 326B",
    "Distance in Parsecs": 14.556,
    "mag": 12.8,
    "Absolute Visual Magnitude": 11.985,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.762143,
    "y": 10.278856,
    "z": -3.304967,
    "vx": -0.00001091,
    "vy": -0.00002199,
    "vz": -0.00003621,
    "rarad": 2.33041528773878,
    "decrad": -0.22904950216355,
    "pmrarad": 0.00000158393477574305,
    "pmdecrad": -0.000002554643271,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118573,
    "base": "Gl 326",
    "lum": 0.00139958732257261,
    "ra": 8.901531,
    "dec": -13.12357,
    "pmra": 326.71,
    "pmdec": -526.93,
    "rv": 0,
    "Asset Name": "ASTRID 000869",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Bangs",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001091,
    "y_1": -0.00002199,
    "z_1": -0.00003621,
    "Distance in Parsecs_1": 14.556,
    "HYG Star ID_1": 118574,
    "Gliese Catalog_1": "Gl 326B"
  },
  {
    "Astrid #": "ASTRID 000870",
    "HYG Star ID": 44594,
    "Hipparcos Catalogue": 44722,
    "Gliese Catalog": "Gl 334",
    "Distance in Parsecs": 14.5582,
    "mag": 9.5,
    "Absolute Visual Magnitude": 8.684,
    "Spectral Type": "M0",
    "Color Index": 1.418,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.468481,
    "y": 9.868812,
    "z": -2.228966,
    "vx": -0.00001399,
    "vy": 0.00004314,
    "vz": 0.00000885,
    "rarad": 2.38567229321329,
    "decrad": -0.153708271064385,
    "pmrarad": -0.00000149734705239583,
    "pmdecrad": 0.000001019078356,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 44594,
    "lum": 0.0292684686237878,
    "ra": 9.112597,
    "dec": -8.806835,
    "pmra": -308.85,
    "pmdec": 210.2,
    "rv": 37.1,
    "Asset Name": "ASTRID 000870",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Round Brush",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001399,
    "y_1": 0.00004314,
    "z_1": 0.00000885,
    "Distance in Parsecs_1": 14.5582,
    "HYG Star ID_1": 44594,
    "Hipparcos Catalogue_1": 44722,
    "Gliese Catalog_1": "Gl 334"
  },
  {
    "Astrid #": "ASTRID 000871",
    "HYG Star ID": 75578,
    "Hipparcos Catalogue": 75807,
    "Distance in Parsecs": 14.5582,
    "mag": 12.47,
    "Absolute Visual Magnitude": 11.654,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -8.838383,
    "y": -11.432415,
    "z": -1.767498,
    "vx": -9.1e-7,
    "vy": 6.4e-7,
    "vz": 3.9e-7,
    "rarad": 4.05426802556299,
    "decrad": -0.121709362562358,
    "pmrarad": -7.61642292152777e-8,
    "pmdecrad": 2.6907159e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 75578,
    "lum": 0.0018984536566615,
    "ra": 15.486163,
    "dec": -6.973433,
    "pmra": -15.71,
    "pmdec": 5.55,
    "rv": 0,
    "Asset Name": "ASTRID 000871",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Pixie",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -9.1e-7,
    "y_1": 6.4e-7,
    "z_1": 3.9e-7,
    "Distance in Parsecs_1": 14.5582,
    "HYG Star ID_1": 75578,
    "Hipparcos Catalogue_1": 75807
  },
  {
    "Astrid #": "ASTRID 000872",
    "HYG Star ID": 118966,
    "Gliese Catalog": "Gl 549B",
    "Distance in Parsecs": 14.5709,
    "mag": 11.5,
    "Absolute Visual Magnitude": 10.683,
    "Spectral Type": "M3",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.25717,
    "y": -5.330217,
    "z": 11.45498,
    "vx": -0.00002238,
    "vy": 0.00000465,
    "vz": -0.00002671,
    "rarad": 3.77512021036981,
    "decrad": 0.904612547692149,
    "pmrarad": -0.00000116675260362499,
    "pmdecrad": -0.000001957246157,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 70274,
    "base": "Gl 549",
    "lum": 0.00464301407281803,
    "ra": 14.419897,
    "dec": 51.830481,
    "pmra": -240.66,
    "pmdec": -403.71,
    "rv": -11.3,
    "Asset Name": "ASTRID 000872",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Side Part",
    "Outfit": "Tats",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00002238,
    "y_1": 0.00000465,
    "z_1": -0.00002671,
    "Distance in Parsecs_1": 14.5709,
    "HYG Star ID_1": 118966,
    "Gliese Catalog_1": "Gl 549B"
  },
  {
    "Astrid #": "ASTRID 000873",
    "HYG Star ID": 119421,
    "Gliese Catalog": "NN 4203A",
    "Distance in Parsecs": 14.5773,
    "mag": 13.35,
    "Absolute Visual Magnitude": 12.532,
    "Spectral Type": "dM",
    "Color Index": 1.68,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.705144,
    "y": -8.676479,
    "z": 0.453888,
    "vx": 9.3e-7,
    "vy": -0.0000012,
    "vz": -0.00004661,
    "rarad": 5.6453054867366,
    "decrad": 0.031141617788,
    "pmrarad": -2.7925268e-8,
    "pmdecrad": -0.00000319964424,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119421,
    "base": "NN 4203",
    "lum": 0.000845668200099353,
    "ra": 21.563479,
    "dec": 1.784283,
    "pmra": -5.76,
    "pmdec": -659.97,
    "rv": 0,
    "Asset Name": "ASTRID 000873",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Greasy",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 9.3e-7,
    "y_1": -0.0000012,
    "z_1": -0.00004661,
    "Distance in Parsecs_1": 14.5773,
    "HYG Star ID_1": 119421,
    "Gliese Catalog_1": "NN 4203A"
  },
  {
    "Astrid #": "ASTRID 000874",
    "HYG Star ID": 119422,
    "Gliese Catalog": "NN 4204B",
    "Distance in Parsecs": 14.5773,
    "mag": 14.5,
    "Absolute Visual Magnitude": 13.682,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.705144,
    "y": -8.676479,
    "z": 0.453888,
    "vx": 9.3e-7,
    "vy": -0.0000012,
    "vz": -0.00004661,
    "rarad": 5.6453054867366,
    "decrad": 0.031141617788,
    "pmrarad": -2.7925268e-8,
    "pmdecrad": -0.00000319964424,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119422,
    "base": "NN 4204",
    "lum": 0.000293224328230311,
    "ra": 21.563479,
    "dec": 1.784283,
    "pmra": -5.76,
    "pmdec": -659.97,
    "rv": 0,
    "Asset Name": "ASTRID 000874",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oh No",
    "Hair": "Round Brush",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 9.3e-7,
    "y_1": -0.0000012,
    "z_1": -0.00004661,
    "Distance in Parsecs_1": 14.5773,
    "HYG Star ID_1": 119422,
    "Gliese Catalog_1": "NN 4204B"
  },
  {
    "Astrid #": "ASTRID 000875",
    "HYG Star ID": 37182,
    "Hipparcos Catalogue": 37288,
    "Gliese Catalog": "Gl 281",
    "Distance in Parsecs": 14.5815,
    "mag": 9.66,
    "Absolute Visual Magnitude": 8.841,
    "Spectral Type": "M0",
    "Color Index": 1.379,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.122453,
    "y": 13.222316,
    "z": 0.555601,
    "vx": 0.00000492,
    "vy": 0.00001427,
    "vz": -0.00001703,
    "rarad": 2.0044407322081,
    "decrad": 0.038112105660175,
    "pmrarad": -7.17233359013888e-7,
    "pmdecrad": -0.00000119569598,
    "con": "CMi",
    "Full Constellation Name Formula 1": "Canis Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Minor",
    "comp": 1,
    "comp_primary": 37182,
    "lum": 0.0253279476568999,
    "ra": 7.6564,
    "dec": 2.183663,
    "pmra": -147.94,
    "pmdec": -246.63,
    "rv": 10,
    "Asset Name": "ASTRID 000875",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Shinobi",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Minor",
    "x_1": 0.00000492,
    "y_1": 0.00001427,
    "z_1": -0.00001703,
    "Distance in Parsecs_1": 14.5815,
    "HYG Star ID_1": 37182,
    "Hipparcos Catalogue_1": 37288,
    "Gliese Catalog_1": "Gl 281"
  },
  {
    "Astrid #": "ASTRID 000876",
    "HYG Star ID": 27965,
    "Hipparcos Catalogue": 28035,
    "Distance in Parsecs": 14.5836,
    "mag": 10.7,
    "Absolute Visual Magnitude": 9.881,
    "Color Index": 1.054,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 0.242947,
    "y": 13.008355,
    "z": -6.588253,
    "vx": -0.00002225,
    "vy": 9e-8,
    "vz": -6.4e-7,
    "rarad": 1.55212066864755,
    "decrad": -0.468734455544632,
    "pmrarad": 0.00000152590257818055,
    "pmdecrad": -4.9547958e-8,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 27965,
    "lum": 0.00971851702940168,
    "var_min": 10.774,
    "var_max": 10.634,
    "ra": 5.928664,
    "dec": -26.856506,
    "pmra": 314.74,
    "pmdec": -10.22,
    "rv": 0,
    "Asset Name": "ASTRID 000876",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Halo Boy",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.00002225,
    "y_1": 9e-8,
    "z_1": -6.4e-7,
    "Distance in Parsecs_1": 14.5836,
    "HYG Star ID_1": 27965,
    "Hipparcos Catalogue_1": 28035
  },
  {
    "Astrid #": "ASTRID 000877",
    "HYG Star ID": 42406,
    "Hipparcos Catalogue": 42525,
    "Distance in Parsecs": 14.59,
    "mag": 9.95,
    "Absolute Visual Magnitude": 9.13,
    "Spectral Type": "F8",
    "Color Index": 0.47,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.054428,
    "y": 8.392324,
    "z": 9.626632,
    "vx": 1.4e-7,
    "vy": 0.0000029,
    "vz": -0.00000242,
    "rarad": 2.26979700960017,
    "decrad": 0.720566246866052,
    "pmrarad": -1.35359979611111e-7,
    "pmdecrad": -2.20929594e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 42406,
    "lum": 0.0194088587759277,
    "ra": 8.669986,
    "dec": 41.285405,
    "pmra": -27.92,
    "pmdec": -45.57,
    "rv": 0,
    "Asset Name": "ASTRID 000877",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Pirate Hat",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 1.4e-7,
    "y_1": 0.0000029,
    "z_1": -0.00000242,
    "Distance in Parsecs_1": 14.59,
    "HYG Star ID_1": 42406,
    "Hipparcos Catalogue_1": 42525
  },
  {
    "Astrid #": "ASTRID 000878",
    "HYG Star ID": 35200,
    "Hipparcos Catalogue": 35296,
    "Henry Draper Catalogue": 57095,
    "Gliese Catalog": "Gl 269A",
    "Distance in Parsecs": 14.5943,
    "mag": 6.7,
    "Absolute Visual Magnitude": 5.879,
    "Spectral Type": "K2V",
    "Color Index": 0.975,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.303102,
    "y": 9.393701,
    "z": -10.670375,
    "vx": -0.00002246,
    "vy": 0.00006782,
    "vz": -0.00001588,
    "rarad": 1.9089223549047,
    "decrad": -0.819942459327093,
    "pmrarad": -8.94966054305555e-8,
    "pmdecrad": 0.000002836305475,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 35200,
    "base": "Gl 269",
    "lum": 0.387614486700241,
    "ra": 7.291546,
    "dec": -46.979242,
    "pmra": -18.46,
    "pmdec": 585.03,
    "rv": 59,
    "Asset Name": "ASTRID 000878",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Frizzy",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002246,
    "y_1": 0.00006782,
    "z_1": -0.00001588,
    "Distance in Parsecs_1": 14.5943,
    "HYG Star ID_1": 35200,
    "Hipparcos Catalogue_1": 35296,
    "Henry Draper Catalogue_1": 57095,
    "Gliese Catalog_1": "Gl 269A"
  },
  {
    "Astrid #": "ASTRID 000879",
    "HYG Star ID": 119329,
    "Gliese Catalog": "Gl 781.1B",
    "Distance in Parsecs": 14.6007,
    "mag": 12.5,
    "Absolute Visual Magnitude": 11.678,
    "Spectral Type": "M3.5",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.567789,
    "y": -10.535628,
    "z": -7.684085,
    "vx": 0.00000265,
    "vy": 0.00003397,
    "vz": -0.00004433,
    "rarad": 5.26981639278613,
    "decrad": -0.5542220141221,
    "pmrarad": 0.00000138501572261111,
    "pmdecrad": -0.000003570822442,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 98834,
    "base": "Gl 781.1",
    "lum": 0.00185694910149045,
    "ra": 20.129216,
    "dec": -31.754582,
    "pmra": 285.68,
    "pmdec": -736.53,
    "rv": 0,
    "Asset Name": "ASTRID 000879",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Nervous Grin",
    "Hair": "Frizzy",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000265,
    "y_1": 0.00003397,
    "z_1": -0.00004433,
    "Distance in Parsecs_1": 14.6007,
    "HYG Star ID_1": 119329,
    "Gliese Catalog_1": "Gl 781.1B"
  },
  {
    "Astrid #": "ASTRID 000880",
    "HYG Star ID": 13341,
    "Hipparcos Catalogue": 13375,
    "Gliese Catalog": "Gl 116",
    "Distance in Parsecs": 14.6049,
    "mag": 9.6,
    "Absolute Visual Magnitude": 8.778,
    "Spectral Type": "K6:",
    "Color Index": 1.315,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.809932,
    "y": 8.223946,
    "z": 8.248817,
    "vx": -0.00004734,
    "vy": 0.00005215,
    "vz": -0.00008417,
    "rarad": 0.751001618667856,
    "decrad": 0.600207479526665,
    "pmrarad": 0.00000482224775501388,
    "pmdecrad": -0.000004794225524,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 13341,
    "lum": 0.0268410808354522,
    "ra": 2.868615,
    "dec": 34.389355,
    "pmra": 994.66,
    "pmdec": -988.88,
    "rv": -45.7,
    "Asset Name": "ASTRID 000880",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Wavy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00004734,
    "y_1": 0.00005215,
    "z_1": -0.00008417,
    "Distance in Parsecs_1": 14.6049,
    "HYG Star ID_1": 13341,
    "Hipparcos Catalogue_1": 13375,
    "Gliese Catalog_1": "Gl 116"
  },
  {
    "Astrid #": "ASTRID 000881",
    "HYG Star ID": 28864,
    "Hipparcos Catalogue": 28936,
    "Distance in Parsecs": 14.6071,
    "mag": 9.73,
    "Absolute Visual Magnitude": 8.907,
    "Spectral Type": "F0",
    "Color Index": 0.377,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.403523,
    "y": 14.345151,
    "z": 2.724181,
    "vx": -3.4e-7,
    "vy": 2.7e-7,
    "vz": -0.00000146,
    "rarad": 1.59891849210699,
    "decrad": 0.187595453882226,
    "pmrarad": 2.26407988819444e-8,
    "pmdecrad": -1.01956317e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 28864,
    "lum": 0.0238341682067315,
    "ra": 6.107419,
    "dec": 10.748428,
    "pmra": 4.67,
    "pmdec": -21.03,
    "rv": 0,
    "Asset Name": "ASTRID 000881",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Determined",
    "Hair": "Prince",
    "Outfit": "River Spirit",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -3.4e-7,
    "y_1": 2.7e-7,
    "z_1": -0.00000146,
    "Distance in Parsecs_1": 14.6071,
    "HYG Star ID_1": 28864,
    "Hipparcos Catalogue_1": 28936
  },
  {
    "Astrid #": "ASTRID 000882",
    "HYG Star ID": 106354,
    "Hipparcos Catalogue": 106696,
    "Henry Draper Catalogue": 205390,
    "Gliese Catalog": "Gl 833",
    "Distance in Parsecs": 14.6199,
    "mag": 7.14,
    "Absolute Visual Magnitude": 6.315,
    "Spectral Type": "K2V",
    "Color Index": 0.879,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.48459,
    "y": -5.403649,
    "z": -11.337122,
    "vx": 0.00002343,
    "vy": 0.00002016,
    "vz": -0.0000312,
    "rarad": 5.65785561155703,
    "decrad": -0.887419371271933,
    "pmrarad": 0.00000205594937513194,
    "pmdecrad": -9.66088221e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 106354,
    "lum": 0.259417936211881,
    "ra": 21.611417,
    "dec": -50.845385,
    "pmra": 424.07,
    "pmdec": -199.27,
    "rv": 28.1,
    "Asset Name": "ASTRID 000882",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Wavy",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00002343,
    "y_1": 0.00002016,
    "z_1": -0.0000312,
    "Distance in Parsecs_1": 14.6199,
    "HYG Star ID_1": 106354,
    "Hipparcos Catalogue_1": 106696,
    "Henry Draper Catalogue_1": 205390,
    "Gliese Catalog_1": "Gl 833"
  },
  {
    "Astrid #": "ASTRID 000883",
    "HYG Star ID": 107815,
    "Hipparcos Catalogue": 108159,
    "Gliese Catalog": "Gl 838.6",
    "Distance in Parsecs": 14.6349,
    "mag": 11.99,
    "Absolute Visual Magnitude": 11.163,
    "Spectral Type": "M3",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.528789,
    "y": -5.187865,
    "z": -10.702409,
    "vx": 0.00000747,
    "vy": -0.00003043,
    "vz": -0.00006182,
    "rarad": 5.73670824073709,
    "decrad": -0.820177940341213,
    "pmrarad": -0.00000151111576092361,
    "pmdecrad": -0.000001772381853,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 107815,
    "lum": 0.00298400811593021,
    "var_min": 12.091,
    "var_max": 11.871,
    "ra": 21.912611,
    "dec": -46.992734,
    "pmra": -311.69,
    "pmdec": -365.58,
    "rv": 59,
    "Asset Name": "ASTRID 000883",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Heartbreaker",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00000747,
    "y_1": -0.00003043,
    "z_1": -0.00006182,
    "Distance in Parsecs_1": 14.6349,
    "HYG Star ID_1": 107815,
    "Hipparcos Catalogue_1": 108159,
    "Gliese Catalog_1": "Gl 838.6"
  },
  {
    "Astrid #": "ASTRID 000884",
    "HYG Star ID": 118580,
    "Gliese Catalog": "Gl 331B",
    "Distance in Parsecs": 14.637,
    "mag": 10.8,
    "Absolute Visual Magnitude": 9.973,
    "Spectral Type": "dM1   J",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -6.896425,
    "y": 6.943415,
    "z": 10.885428,
    "vx": 0.00000646,
    "vy": 0.00003811,
    "vz": 4.2e-7,
    "rarad": 2.35285255152907,
    "decrad": 0.8385186879403,
    "pmrarad": -0.00000214820941854166,
    "pmdecrad": -0.000001123061194,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 44000,
    "base": "Gl 331",
    "lum": 0.00892894196064981,
    "ra": 8.987235,
    "dec": 48.043582,
    "pmra": -443.1,
    "pmdec": -231.65,
    "rv": 15,
    "Asset Name": "ASTRID 000884",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Skater Helmet",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000646,
    "y_1": 0.00003811,
    "z_1": 4.2e-7,
    "Distance in Parsecs_1": 14.637,
    "HYG Star ID_1": 118580,
    "Gliese Catalog_1": "Gl 331B"
  },
  {
    "Astrid #": "ASTRID 000885",
    "HYG Star ID": 118581,
    "Gliese Catalog": "Gl 331C",
    "Distance in Parsecs": 14.637,
    "mag": 11,
    "Absolute Visual Magnitude": 10.173,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.896063,
    "y": 6.94305,
    "z": 10.884857,
    "vx": 0.00001369,
    "vy": 0.00003083,
    "vz": -0.00001099,
    "rarad": 2.35285255152907,
    "decrad": 0.8385186879403,
    "pmrarad": -0.00000214820941854166,
    "pmdecrad": -0.000001123061194,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 3,
    "comp_primary": 44000,
    "base": "Gl 331",
    "lum": 0.00742677043714696,
    "ra": 8.987235,
    "dec": 48.043582,
    "pmra": -443.1,
    "pmdec": -231.65,
    "rv": 0,
    "Asset Name": "ASTRID 000885",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Sweet Nap",
    "Hair": "Pompadour",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001369,
    "y_1": 0.00003083,
    "z_1": -0.00001099,
    "Distance in Parsecs_1": 14.637,
    "HYG Star ID_1": 118581,
    "Gliese Catalog_1": "Gl 331C"
  },
  {
    "Astrid #": "ASTRID 000886",
    "HYG Star ID": 86693,
    "Hipparcos Catalogue": 86961,
    "Distance in Parsecs": 14.6434,
    "mag": 10.49,
    "Absolute Visual Magnitude": 9.662,
    "Spectral Type": "M2V:",
    "Color Index": 1.463,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.745891,
    "y": -12.381817,
    "z": -7.78225,
    "vx": -0.0000028,
    "vy": 0.00001226,
    "vz": -0.00001923,
    "rarad": 4.65222123057596,
    "decrad": -0.560312659147238,
    "pmrarad": -2.41534175652777e-7,
    "pmdecrad": -0.000001550531113,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 86693,
    "lum": 0.0118904967898509,
    "ra": 17.770176,
    "dec": -32.103551,
    "pmra": -49.82,
    "pmdec": -319.82,
    "rv": 0,
    "Asset Name": "ASTRID 000886",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Side Part",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.0000028,
    "y_1": 0.00001226,
    "z_1": -0.00001923,
    "Distance in Parsecs_1": 14.6434,
    "HYG Star ID_1": 86693,
    "Hipparcos Catalogue_1": 86961
  },
  {
    "Astrid #": "ASTRID 000887",
    "HYG Star ID": 54790,
    "Hipparcos Catalogue": 54952,
    "Henry Draper Catalogue": 97584,
    "Gliese Catalog": "Gl 420A",
    "Distance in Parsecs": 14.6456,
    "mag": 7.68,
    "Absolute Visual Magnitude": 6.851,
    "Spectral Type": "K5",
    "Color Index": 1.043,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.086332,
    "y": 0.809146,
    "z": 14.040758,
    "vx": 0.00001074,
    "vy": 0.0000271,
    "vz": 0.0000102,
    "rarad": 2.94615173462378,
    "decrad": 1.28238425629512,
    "pmrarad": -0.00000195787156755555,
    "pmdecrad": 5.43233729e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 54790,
    "base": "Gl 420",
    "lum": 0.158343412391457,
    "ra": 11.253471,
    "dec": 73.475206,
    "pmra": -403.84,
    "pmdec": 112.05,
    "rv": 8.1,
    "Asset Name": "ASTRID 000887",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Undercut",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001074,
    "y_1": 0.0000271,
    "z_1": 0.0000102,
    "Distance in Parsecs_1": 14.6456,
    "HYG Star ID_1": 54790,
    "Hipparcos Catalogue_1": 54952,
    "Henry Draper Catalogue_1": 97584,
    "Gliese Catalog_1": "Gl 420A"
  },
  {
    "Astrid #": "ASTRID 000888",
    "HYG Star ID": 33729,
    "Hipparcos Catalogue": 33817,
    "Henry Draper Catalogue": 52698,
    "Gliese Catalog": "Gl 259",
    "Distance in Parsecs": 14.6477,
    "mag": 6.71,
    "Absolute Visual Magnitude": 5.881,
    "Spectral Type": "K1V",
    "Color Index": 0.882,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.477108,
    "y": 12.703851,
    "z": -6.409393,
    "vx": -0.00001754,
    "vy": 0.00000853,
    "vz": -0.00000303,
    "rarad": 1.83795728537088,
    "decrad": -0.452890536397261,
    "pmrarad": 0.00000100152810129166,
    "pmdecrad": 1.98240313e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 33729,
    "lum": 0.386901131655108,
    "ra": 7.02048,
    "dec": -25.948716,
    "pmra": 206.58,
    "pmdec": 40.89,
    "rv": 12.6,
    "Asset Name": "ASTRID 000888",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Bangs",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00001754,
    "y_1": 0.00000853,
    "z_1": -0.00000303,
    "Distance in Parsecs_1": 14.6477,
    "HYG Star ID_1": 33729,
    "Hipparcos Catalogue_1": 33817,
    "Henry Draper Catalogue_1": 52698,
    "Gliese Catalog_1": "Gl 259"
  },
  {
    "Astrid #": "ASTRID 000889",
    "HYG Star ID": 76816,
    "Hipparcos Catalogue": 77052,
    "Henry Draper Catalogue": 140538,
    "Harvard Revised Catalogue": 5853,
    "Gliese Catalog": "Gl 596.1A",
    "Distance in Parsecs": 14.6585,
    "mag": 5.86,
    "Absolute Visual Magnitude": 5.03,
    "Spectral Type": "G5V",
    "Color Index": 0.684,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.187523,
    "y": -12.141967,
    "z": 0.643281,
    "vx": -0.00001398,
    "vy": -0.00001502,
    "vz": -0.0000094,
    "rarad": 4.11910935874298,
    "decrad": 0.0438980543801587,
    "pmrarad": -2.17972230777777e-7,
    "pmdecrad": -7.01670839e-7,
    "bayer": "Psi",
    "flam": 23,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 76816,
    "base": "Gl 596.1",
    "lum": 0.847227414140596,
    "bf": "23Psi Ser",
    "ra": 15.733839,
    "dec": 2.515173,
    "pmra": -44.96,
    "pmdec": -144.73,
    "rv": 19.4,
    "Asset Name": "ASTRID 000889",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Innocent",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00001398,
    "y_1": -0.00001502,
    "z_1": -0.0000094,
    "Distance in Parsecs_1": 14.6585,
    "HYG Star ID_1": 76816,
    "Hipparcos Catalogue_1": 77052,
    "Henry Draper Catalogue_1": 140538,
    "Harvard Revised Catalogue_1": 5853,
    "Gliese Catalog_1": "Gl 596.1A"
  },
  {
    "Astrid #": "ASTRID 000890",
    "HYG Star ID": 118264,
    "Gliese Catalog": "NN 3252",
    "Distance in Parsecs": 14.6628,
    "mag": 18.02,
    "Absolute Visual Magnitude": 17.189,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.823561,
    "y": 12.399159,
    "z": -0.22461,
    "vx": -0.0000027,
    "vy": 0.00000104,
    "vz": -0.00003685,
    "rarad": 1.00791181960885,
    "decrad": -0.01531897094725,
    "pmrarad": 1.93392177173611e-7,
    "pmdecrad": -0.000002513598945,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118264,
    "lum": 0.0000115984512119016,
    "ra": 3.84994,
    "dec": -0.877712,
    "pmra": 39.89,
    "pmdec": -518.47,
    "rv": 0,
    "Asset Name": "ASTRID 000890",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Spiky",
    "Outfit": "Astro",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000027,
    "y_1": 0.00000104,
    "z_1": -0.00003685,
    "Distance in Parsecs_1": 14.6628,
    "HYG Star ID_1": 118264,
    "Gliese Catalog_1": "NN 3252"
  },
  {
    "Astrid #": "ASTRID 000891",
    "HYG Star ID": 118487,
    "Gliese Catalog": "GJ 1099",
    "Distance in Parsecs": 14.6628,
    "mag": 11.93,
    "Absolute Visual Magnitude": 11.099,
    "Spectral Type": "m",
    "Color Index": 1.47,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.863499,
    "y": 13.437015,
    "z": 0.252394,
    "vx": -0.0000034,
    "vy": -6.5e-7,
    "vz": -0.00004393,
    "rarad": 1.98225722786932,
    "decrad": 0.0172140898787499,
    "pmrarad": 2.30577386472222e-7,
    "pmdecrad": -0.000002996982425,
    "con": "CMi",
    "Full Constellation Name Formula 1": "Canis Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Minor",
    "comp": 1,
    "comp_primary": 118487,
    "lum": 0.00316519156722626,
    "ra": 7.571665,
    "dec": 0.986295,
    "pmra": 47.56,
    "pmdec": -618.17,
    "rv": 0,
    "Asset Name": "ASTRID 000891",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Straw Hat",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Minor",
    "x_1": -0.0000034,
    "y_1": -6.5e-7,
    "z_1": -0.00004393,
    "Distance in Parsecs_1": 14.6628,
    "HYG Star ID_1": 118487,
    "Gliese Catalog_1": "GJ 1099"
  },
  {
    "Astrid #": "ASTRID 000892",
    "HYG Star ID": 78950,
    "Hipparcos Catalogue": 79190,
    "Henry Draper Catalogue": 144628,
    "Gliese Catalog": "Gl 613",
    "Distance in Parsecs": 14.6692,
    "mag": 7.11,
    "Absolute Visual Magnitude": 6.278,
    "Spectral Type": "K3V",
    "Color Index": 0.856,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.753024,
    "y": -7.18752,
    "z": -12.224964,
    "vx": -0.00002767,
    "vy": -0.00003208,
    "vz": -0.00001916,
    "rarad": 4.23117680577823,
    "decrad": -0.985153691958017,
    "pmrarad": -6.60025344708333e-7,
    "pmdecrad": 0.000001621604798,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 78950,
    "lum": 0.268410808354522,
    "ra": 16.161905,
    "dec": -56.445149,
    "pmra": -136.14,
    "pmdec": 334.48,
    "rv": 37.9,
    "Asset Name": "ASTRID 000892",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Shy Smile",
    "Hair": "Fedora",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00002767,
    "y_1": -0.00003208,
    "z_1": -0.00001916,
    "Distance in Parsecs_1": 14.6692,
    "HYG Star ID_1": 78950,
    "Hipparcos Catalogue_1": 79190,
    "Henry Draper Catalogue_1": 144628,
    "Gliese Catalog_1": "Gl 613"
  },
  {
    "Astrid #": "ASTRID 000893",
    "HYG Star ID": 119039,
    "Gliese Catalog": "Gl 596.1B",
    "Distance in Parsecs": 14.6714,
    "mag": 12,
    "Absolute Visual Magnitude": 11.168,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -8.194576,
    "y": -12.152574,
    "z": 0.643307,
    "vx": -0.00000355,
    "vy": 0.00000183,
    "vz": -0.00001066,
    "rarad": 4.11911497897051,
    "decrad": 0.0438617584582,
    "pmrarad": -2.70380589645833e-7,
    "pmdecrad": -7.27050836e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 2,
    "comp_primary": 76816,
    "base": "Gl 596.1",
    "lum": 0.00297029784401786,
    "ra": 15.73386,
    "dec": 2.513094,
    "pmra": -55.77,
    "pmdec": -149.97,
    "rv": 0,
    "Asset Name": "ASTRID 000893",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Heartbreaker",
    "Outfit": "Astro",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000355,
    "y_1": 0.00000183,
    "z_1": -0.00001066,
    "Distance in Parsecs_1": 14.6714,
    "HYG Star ID_1": 119039,
    "Gliese Catalog_1": "Gl 596.1B"
  },
  {
    "Astrid #": "ASTRID 000894",
    "HYG Star ID": 102154,
    "Hipparcos Catalogue": 102485,
    "Henry Draper Catalogue": 197692,
    "Harvard Revised Catalogue": 7936,
    "Gliese Catalog": "Gl 805",
    "Distance in Parsecs": 14.6778,
    "mag": 4.13,
    "Absolute Visual Magnitude": 3.297,
    "Spectral Type": "F5V",
    "Color Index": 0.426,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.799322,
    "y": -9.937473,
    "z": -6.266031,
    "vx": 0.00000993,
    "vy": -0.00001673,
    "vz": -0.00002134,
    "rarad": 5.43711775639889,
    "decrad": -0.441060366704721,
    "pmrarad": -2.49097269069444e-7,
    "pmdecrad": -7.59509111e-7,
    "bayer": "Psi",
    "flam": 16,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 102154,
    "lum": 4.18022828954032,
    "bf": "16Psi Cap",
    "ra": 20.76826,
    "dec": -25.270898,
    "pmra": -51.38,
    "pmdec": -156.66,
    "rv": 25.8,
    "Asset Name": "ASTRID 000894",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Straw Hat",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000993,
    "y_1": -0.00001673,
    "z_1": -0.00002134,
    "Distance in Parsecs_1": 14.6778,
    "HYG Star ID_1": 102154,
    "Hipparcos Catalogue_1": 102485,
    "Henry Draper Catalogue_1": 197692,
    "Harvard Revised Catalogue_1": 7936,
    "Gliese Catalog_1": "Gl 805"
  },
  {
    "Astrid #": "ASTRID 000895",
    "HYG Star ID": 118735,
    "Gliese Catalog": "Gl 420B",
    "Distance in Parsecs": 14.6778,
    "mag": 11.4,
    "Absolute Visual Magnitude": 10.567,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.095369,
    "y": 0.810378,
    "z": 14.072027,
    "vx": 0.00001006,
    "vy": 0.00002675,
    "vz": 0.00001057,
    "rarad": 2.94648114998581,
    "decrad": 1.2823955304987,
    "pmrarad": -0.00000192088028372916,
    "pmdecrad": 5.11109974e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 54790,
    "base": "Gl 420",
    "lum": 0.00516654242566949,
    "ra": 11.254729,
    "dec": 73.475852,
    "pmra": -396.21,
    "pmdec": 105.42,
    "rv": 8.6,
    "Asset Name": "ASTRID 000895",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Bucket Hat",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001006,
    "y_1": 0.00002675,
    "z_1": 0.00001057,
    "Distance in Parsecs_1": 14.6778,
    "HYG Star ID_1": 118735,
    "Gliese Catalog_1": "Gl 420B"
  },
  {
    "Astrid #": "ASTRID 000896",
    "HYG Star ID": 118322,
    "Gliese Catalog": "NN 3305",
    "Distance in Parsecs": 14.7059,
    "mag": 10.59,
    "Absolute Visual Magnitude": 9.753,
    "Spectral Type": "M1",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.167235,
    "y": 13.753344,
    "z": -0.639303,
    "vx": -0.00002385,
    "vy": 0.0000088,
    "vz": -0.00000357,
    "rarad": 1.21140535303855,
    "decrad": -0.0434863100675,
    "pmrarad": 0.00000172836077118055,
    "pmdecrad": -2.4290135e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118322,
    "lum": 0.0109345269673091,
    "ra": 4.627228,
    "dec": -2.491582,
    "pmra": 356.5,
    "pmdec": -50.1,
    "rv": 0,
    "Asset Name": "ASTRID 000896",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Smitten",
    "Hair": "Tiny Crown",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00002385,
    "y_1": 0.0000088,
    "z_1": -0.00000357,
    "Distance in Parsecs_1": 14.7059,
    "HYG Star ID_1": 118322,
    "Gliese Catalog_1": "NN 3305"
  },
  {
    "Astrid #": "ASTRID 000897",
    "HYG Star ID": 118734,
    "Gliese Catalog": "NN 3649",
    "Distance in Parsecs": 14.7059,
    "mag": 10.77,
    "Absolute Visual Magnitude": 9.933,
    "Spectral Type": "M1",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.61425,
    "y": 2.854701,
    "z": 4.771412,
    "vx": -8e-8,
    "vy": 0.00001826,
    "vz": -0.00001114,
    "rarad": 2.93490586220685,
    "decrad": 0.3304361459648,
    "pmrarad": -0.00000121421586295138,
    "pmdecrad": -8.00640704e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 118734,
    "lum": 0.00926403100736398,
    "ra": 11.210515,
    "dec": 18.932597,
    "pmra": -250.45,
    "pmdec": -165.14,
    "rv": 0,
    "Asset Name": "ASTRID 000897",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Stunned",
    "Hair": "Horns",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -8e-8,
    "y_1": 0.00001826,
    "z_1": -0.00001114,
    "Distance in Parsecs_1": 14.7059,
    "HYG Star ID_1": 118734,
    "Gliese Catalog_1": "NN 3649"
  },
  {
    "Astrid #": "ASTRID 000898",
    "HYG Star ID": 118880,
    "Gliese Catalog": "NN 3775",
    "Distance in Parsecs": 14.7059,
    "mag": 12.97,
    "Absolute Visual Magnitude": 12.133,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.851312,
    "y": -4.906725,
    "z": 0.573329,
    "vx": -0.00000862,
    "vy": 0.00002397,
    "vz": -0.00000313,
    "rarad": 3.48204209218783,
    "decrad": 0.0389962081271499,
    "pmrarad": -0.00000173233624336111,
    "pmdecrad": -2.12697457e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118880,
    "lum": 0.0012212371301653,
    "ra": 13.300421,
    "dec": 2.234318,
    "pmra": -357.32,
    "pmdec": -43.87,
    "rv": 0,
    "Asset Name": "ASTRID 000898",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Loopy",
    "Hair": "Braided Ponytail",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000862,
    "y_1": 0.00002397,
    "z_1": -0.00000313,
    "Distance in Parsecs_1": 14.7059,
    "HYG Star ID_1": 118880,
    "Gliese Catalog_1": "NN 3775"
  },
  {
    "Astrid #": "ASTRID 000899",
    "HYG Star ID": 118925,
    "Gliese Catalog": "NN 3809A",
    "Distance in Parsecs": 14.7059,
    "mag": 11.19,
    "Absolute Visual Magnitude": 10.353,
    "Spectral Type": "M2:",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.057987,
    "y": -6.726043,
    "z": 0.716113,
    "vx": 0.0000047,
    "vy": -0.00001092,
    "vz": -0.00001691,
    "rarad": 3.61723945395381,
    "decrad": 0.0487149322855,
    "pmrarad": 8.06439076236111e-7,
    "pmdecrad": -0.00000115169429,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 118925,
    "base": "NN 3809",
    "lum": 0.00629216351287985,
    "ra": 13.816837,
    "dec": 2.79116,
    "pmra": 166.34,
    "pmdec": -237.55,
    "rv": 0,
    "Asset Name": "ASTRID 000899",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Fire",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.0000047,
    "y_1": -0.00001092,
    "z_1": -0.00001691,
    "Distance in Parsecs_1": 14.7059,
    "HYG Star ID_1": 118925,
    "Gliese Catalog_1": "NN 3809A"
  },
  {
    "Astrid #": "ASTRID 000900",
    "HYG Star ID": 119011,
    "Gliese Catalog": "NN 3894",
    "Distance in Parsecs": 14.7059,
    "mag": 14.26,
    "Absolute Visual Magnitude": 13.423,
    "Spectral Type": "M4",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.690432,
    "y": -10.748226,
    "z": -2.61432,
    "vx": -0.0000499,
    "vy": 0.00004863,
    "vz": -0.00001507,
    "rarad": 3.9787030795113,
    "decrad": -0.1787234332328,
    "pmrarad": -0.00000473502977388194,
    "pmdecrad": -0.000001041064656,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119011,
    "lum": 0.000372220253017849,
    "ra": 15.197526,
    "dec": -10.240098,
    "pmra": -976.67,
    "pmdec": -214.74,
    "rv": 0,
    "Asset Name": "ASTRID 000900",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Fedora",
    "Outfit": "Boxer",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000499,
    "y_1": 0.00004863,
    "z_1": -0.00001507,
    "Distance in Parsecs_1": 14.7059,
    "HYG Star ID_1": 119011,
    "Gliese Catalog_1": "NN 3894"
  },
  {
    "Astrid #": "ASTRID 000901",
    "HYG Star ID": 119448,
    "Gliese Catalog": "NN 4228",
    "Distance in Parsecs": 14.7059,
    "mag": 13.93,
    "Absolute Visual Magnitude": 13.093,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.115182,
    "y": -7.58424,
    "z": 3.459069,
    "vx": 0.00000868,
    "vy": 0.00001008,
    "vz": -0.0000083,
    "rarad": 5.7238578814861,
    "decrad": 0.2374411928124,
    "pmrarad": 8.94529721993055e-7,
    "pmdecrad": -5.80903752e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119448,
    "lum": 0.000504428945952414,
    "ra": 21.863527,
    "dec": 13.604378,
    "pmra": 184.51,
    "pmdec": -119.82,
    "rv": 0,
    "Asset Name": "ASTRID 000901",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Loopy",
    "Hair": "Ram Horns",
    "Outfit": "Viking",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000868,
    "y_1": 0.00001008,
    "z_1": -0.0000083,
    "Distance in Parsecs_1": 14.7059,
    "HYG Star ID_1": 119448,
    "Gliese Catalog_1": "NN 4228"
  },
  {
    "Astrid #": "ASTRID 000902",
    "HYG Star ID": 30557,
    "Hipparcos Catalogue": 30630,
    "Henry Draper Catalogue": 45088,
    "Gliese Catalog": "Gl 233A",
    "Distance in Parsecs": 14.7297,
    "mag": 6.78,
    "Absolute Visual Magnitude": 5.939,
    "Spectral Type": "K0",
    "Color Index": 0.938,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.589214,
    "y": 13.856519,
    "z": 4.736364,
    "vx": 0.00000896,
    "vy": -0.00000337,
    "vz": -0.00001386,
    "rarad": 1.68498836530276,
    "decrad": 0.327369738378415,
    "pmrarad": -5.78479683638888e-7,
    "pmdecrad": -7.95385324e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 30557,
    "base": "Gl 233",
    "lum": 0.366775231599494,
    "var": "OU",
    "var_min": 6.81,
    "var_max": 6.75,
    "ra": 6.436181,
    "dec": 18.756904,
    "pmra": -119.32,
    "pmdec": -164.06,
    "rv": -8.4,
    "Asset Name": "ASTRID 000902",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Fire",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00000896,
    "y_1": -0.00000337,
    "z_1": -0.00001386,
    "Distance in Parsecs_1": 14.7297,
    "HYG Star ID_1": 30557,
    "Hipparcos Catalogue_1": 30630,
    "Henry Draper Catalogue_1": 45088,
    "Gliese Catalog_1": "Gl 233A"
  },
  {
    "Astrid #": "ASTRID 000903",
    "HYG Star ID": 77934,
    "Hipparcos Catalogue": 78170,
    "Henry Draper Catalogue": 142709,
    "Gliese Catalog": "Gl 604",
    "Distance in Parsecs": 14.7319,
    "mag": 8.06,
    "Absolute Visual Magnitude": 7.219,
    "Spectral Type": "K5V",
    "Color Index": 1.118,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.514853,
    "y": -9.332453,
    "z": -9.976471,
    "vx": -0.00002471,
    "vy": -0.00000576,
    "vz": -0.00003471,
    "rarad": 4.1786671468429,
    "decrad": -0.743933245992901,
    "pmrarad": -0.00000124538938261111,
    "pmdecrad": -9.27884903e-7,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 77934,
    "lum": 0.112823612164899,
    "ra": 15.961333,
    "dec": -42.624235,
    "pmra": -256.88,
    "pmdec": -191.39,
    "rv": 35.6,
    "Asset Name": "ASTRID 000903",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Charmer",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00002471,
    "y_1": -0.00000576,
    "z_1": -0.00003471,
    "Distance in Parsecs_1": 14.7319,
    "HYG Star ID_1": 77934,
    "Hipparcos Catalogue_1": 78170,
    "Henry Draper Catalogue_1": 142709,
    "Gliese Catalog_1": "Gl 604"
  },
  {
    "Astrid #": "ASTRID 000904",
    "HYG Star ID": 17567,
    "Hipparcos Catalogue": 17609,
    "Henry Draper Catalogue": 23453,
    "Gliese Catalog": "Gl 154",
    "Distance in Parsecs": 14.7493,
    "mag": 9.61,
    "Absolute Visual Magnitude": 8.766,
    "Spectral Type": "K5",
    "Color Index": 1.437,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.287317,
    "y": 11.045047,
    "z": 6.515626,
    "vx": -0.0000013,
    "vy": 0.00004826,
    "vz": 0.00000369,
    "rarad": 0.987573772457985,
    "decrad": 0.457546809815596,
    "pmrarad": 0.00000187593805554166,
    "pmdecrad": -9.60706789e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 17567,
    "lum": 0.0271393848764158,
    "ra": 3.772254,
    "dec": 26.215501,
    "pmra": 386.94,
    "pmdec": -198.16,
    "rv": 36.3,
    "Asset Name": "ASTRID 000904",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Little Crown",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.0000013,
    "y_1": 0.00004826,
    "z_1": 0.00000369,
    "Distance in Parsecs_1": 14.7493,
    "HYG Star ID_1": 17567,
    "Hipparcos Catalogue_1": 17609,
    "Henry Draper Catalogue_1": 23453,
    "Gliese Catalog_1": "Gl 154"
  },
  {
    "Astrid #": "ASTRID 000905",
    "HYG Star ID": 37974,
    "Hipparcos Catalogue": 38082,
    "Gliese Catalog": "Gl 289",
    "Distance in Parsecs": 14.7732,
    "mag": 11.46,
    "Absolute Visual Magnitude": 10.613,
    "Spectral Type": "M2",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.302434,
    "y": 12.33287,
    "z": 5.141973,
    "vx": -0.00012489,
    "vy": 0.00001601,
    "vz": -0.00004922,
    "rarad": 2.04322275448958,
    "decrad": 0.355490950226527,
    "pmrarad": 0.00000703571309495833,
    "pmdecrad": -0.000004798831254,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 37974,
    "lum": 0.00495222080088521,
    "var_min": 11.536,
    "var_max": 11.366,
    "ra": 7.804536,
    "dec": 20.368131,
    "pmra": 1451.22,
    "pmdec": -989.83,
    "rv": 48.4,
    "Asset Name": "ASTRID 000905",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Scared",
    "Hair": "Frizzy",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00012489,
    "y_1": 0.00001601,
    "z_1": -0.00004922,
    "Distance in Parsecs_1": 14.7732,
    "HYG Star ID_1": 37974,
    "Hipparcos Catalogue_1": 38082,
    "Gliese Catalog_1": "Gl 289"
  },
  {
    "Astrid #": "ASTRID 000906",
    "HYG Star ID": 118471,
    "Gliese Catalog": "Gl 269B",
    "Distance in Parsecs": 14.7732,
    "mag": 7.9,
    "Absolute Visual Magnitude": 7.053,
    "Spectral Type": "K4 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.343222,
    "y": 9.508888,
    "z": -10.800525,
    "vx": -0.00000862,
    "vy": 0.00002859,
    "vz": 0.00002783,
    "rarad": 1.90888833251071,
    "decrad": -0.819916850216799,
    "pmrarad": -9.1629785625e-8,
    "pmdecrad": 0.000002761915664,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 35200,
    "base": "Gl 269",
    "lum": 0.131461928821608,
    "ra": 7.291416,
    "dec": -46.977775,
    "pmra": -18.9,
    "pmdec": 569.69,
    "rv": 0,
    "Asset Name": "ASTRID 000906",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Worried Vamp",
    "Hair": "Ponytail",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000862,
    "y_1": 0.00002859,
    "z_1": 0.00002783,
    "Distance in Parsecs_1": 14.7732,
    "HYG Star ID_1": 118471,
    "Gliese Catalog_1": "Gl 269B"
  },
  {
    "Astrid #": "ASTRID 000907",
    "HYG Star ID": 118422,
    "Gliese Catalog": "Gl 231.3",
    "Distance in Parsecs": 14.7929,
    "mag": 13.06,
    "Absolute Visual Magnitude": 12.21,
    "Spectral Type": "M4",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.238692,
    "y": 14.64088,
    "z": -1.714691,
    "vx": 0.00000621,
    "vy": -0.00000493,
    "vz": -0.00004666,
    "rarad": 1.65520054321783,
    "decrad": -0.1161742458729,
    "pmrarad": -3.89935643270833e-7,
    "pmdecrad": -0.000003175917458,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118422,
    "lum": 0.00113762728582342,
    "ra": 6.3224,
    "dec": -6.656294,
    "pmra": -80.43,
    "pmdec": -655.08,
    "rv": 0,
    "Asset Name": "ASTRID 000907",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Gimme Blood",
    "Hair": "Braided Bun",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000621,
    "y_1": -0.00000493,
    "z_1": -0.00004666,
    "Distance in Parsecs_1": 14.7929,
    "HYG Star ID_1": 118422,
    "Gliese Catalog_1": "Gl 231.3"
  },
  {
    "Astrid #": "ASTRID 000908",
    "HYG Star ID": 118873,
    "Gliese Catalog": "NN 3770",
    "Distance in Parsecs": 14.7929,
    "mag": 17.05,
    "Absolute Visual Magnitude": 16.2,
    "Spectral Type": "DC9+",
    "Color Index": 1.4,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -9.497656,
    "y": -3.128759,
    "z": -10.901162,
    "vx": -0.00004469,
    "vy": 0.00014528,
    "vz": -0.00000287,
    "rarad": 3.46006717269628,
    "decrad": -0.82850045278805,
    "pmrarad": -0.0000102740260742291,
    "pmdecrad": -2.86975761e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118873,
    "lum": 0.000028840315031266,
    "ra": 13.216483,
    "dec": -47.469579,
    "pmra": -2119.17,
    "pmdec": -59.19,
    "rv": 0,
    "Asset Name": "ASTRID 000908",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Shy Smile",
    "Hair": "Shinobi",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004469,
    "y_1": 0.00014528,
    "z_1": -0.00000287,
    "Distance in Parsecs_1": 14.7929,
    "HYG Star ID_1": 118873,
    "Gliese Catalog_1": "NN 3770"
  },
  {
    "Astrid #": "ASTRID 000909",
    "HYG Star ID": 66465,
    "Hipparcos Catalogue": 66675,
    "Henry Draper Catalogue": 118926,
    "Gliese Catalog": "Gl 521.1",
    "Distance in Parsecs": 14.7973,
    "mag": 9.61,
    "Absolute Visual Magnitude": 8.759,
    "Spectral Type": "M0V",
    "Color Index": 1.376,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.371935,
    "y": -6.24388,
    "z": -1.080151,
    "vx": -0.00001862,
    "vy": 0.00002185,
    "vz": 0.00003413,
    "rarad": 3.5784435977506,
    "decrad": -0.0730612942261537,
    "pmrarad": -0.00000187031421684722,
    "pmdecrad": 0.000002337917011,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66465,
    "lum": 0.0273149242208918,
    "ra": 13.668648,
    "dec": -4.186104,
    "pmra": -385.78,
    "pmdec": 482.23,
    "rv": 5,
    "Asset Name": "ASTRID 000909",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Braided Ponytail",
    "Outfit": "Emperor",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001862,
    "y_1": 0.00002185,
    "z_1": 0.00003413,
    "Distance in Parsecs_1": 14.7973,
    "HYG Star ID_1": 66465,
    "Hipparcos Catalogue_1": 66675,
    "Henry Draper Catalogue_1": 118926,
    "Gliese Catalog_1": "Gl 521.1"
  },
  {
    "Astrid #": "ASTRID 000910",
    "HYG Star ID": 104307,
    "Hipparcos Catalogue": 104644,
    "Gliese Catalog": "Wo 9724",
    "Distance in Parsecs": 14.8038,
    "mag": 11.98,
    "Absolute Visual Magnitude": 11.128,
    "Spectral Type": "M1:",
    "Color Index": 1.586,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.959631,
    "y": -7.177825,
    "z": -10.211543,
    "vx": -0.00001492,
    "vy": 0.00003433,
    "vz": -0.00003576,
    "rarad": 5.54938495970592,
    "decrad": -0.761201721358352,
    "pmrarad": 0.00000104705210589583,
    "pmdecrad": -0.000003337069526,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 104307,
    "lum": 0.0030817684166115,
    "var_min": 12.11,
    "var_max": 11.87,
    "ra": 21.197089,
    "dec": -43.613646,
    "pmra": 215.97,
    "pmdec": -688.32,
    "rv": 0,
    "Asset Name": "ASTRID 000910",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Bucket Hat",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": -0.00001492,
    "y_1": 0.00003433,
    "z_1": -0.00003576,
    "Distance in Parsecs_1": 14.8038,
    "HYG Star ID_1": 104307,
    "Hipparcos Catalogue_1": 104644,
    "Gliese Catalog_1": "Wo 9724"
  },
  {
    "Astrid #": "ASTRID 000911",
    "HYG Star ID": 74952,
    "Hipparcos Catalogue": 75181,
    "Henry Draper Catalogue": 136352,
    "Harvard Revised Catalogue": 5699,
    "Gliese Catalog": "Gl 582",
    "Distance in Parsecs": 14.8126,
    "mag": 5.65,
    "Absolute Visual Magnitude": 4.797,
    "Spectral Type": "G2V",
    "Color Index": 0.639,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.271902,
    "y": -7.595081,
    "z": -11.06222,
    "vx": -0.00005041,
    "vy": 0.00012196,
    "vz": 0.00003987,
    "rarad": 4.02215683969933,
    "decrad": -0.843301690071306,
    "pmrarad": -0.00000786712007574305,
    "pmdecrad": -0.000001333722435,
    "bayer": "Nu-2",
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 74952,
    "lum": 1.05002587211088,
    "bf": "Nu 2Lup",
    "ra": 15.363507,
    "dec": -48.317628,
    "pmra": -1622.71,
    "pmdec": -275.1,
    "rv": -69.4,
    "Asset Name": "ASTRID 000911",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Honest Lad",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00005041,
    "y_1": 0.00012196,
    "z_1": 0.00003987,
    "Distance in Parsecs_1": 14.8126,
    "HYG Star ID_1": 74952,
    "Hipparcos Catalogue_1": 75181,
    "Henry Draper Catalogue_1": 136352,
    "Harvard Revised Catalogue_1": 5699,
    "Gliese Catalog_1": "Gl 582"
  },
  {
    "Astrid #": "ASTRID 000912",
    "HYG Star ID": 119567,
    "Gliese Catalog": "NN 4338B",
    "Distance in Parsecs": 14.8148,
    "mag": 12.44,
    "Absolute Visual Magnitude": 11.587,
    "Spectral Type": "M3 :",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.001755,
    "y": -1.479341,
    "z": 9.810771,
    "vx": 0.00000546,
    "vy": 0.00002814,
    "vz": -0.00000188,
    "rarad": 6.14949061627771,
    "decrad": 0.72378796021275,
    "pmrarad": 0.00000193188555427777,
    "pmdecrad": -1.69015745e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119567,
    "base": "NN 4338",
    "lum": 0.00201929606979811,
    "ra": 23.489324,
    "dec": 41.469995,
    "pmra": 398.48,
    "pmdec": -34.86,
    "rv": 0,
    "Asset Name": "ASTRID 000912",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Medium Bob",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000546,
    "y_1": 0.00002814,
    "z_1": -0.00000188,
    "Distance in Parsecs_1": 14.8148,
    "HYG Star ID_1": 119567,
    "Gliese Catalog_1": "NN 4338B"
  },
  {
    "Astrid #": "ASTRID 000913",
    "HYG Star ID": 118892,
    "Gliese Catalog": "Gl 514.1",
    "Distance in Parsecs": 14.8368,
    "mag": 14.25,
    "Absolute Visual Magnitude": 13.393,
    "Spectral Type": "M4  :",
    "Color Index": 1.65,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.548315,
    "y": -5.614946,
    "z": -2.246377,
    "vx": -0.00002539,
    "vy": 0.00007496,
    "vz": -0.00003443,
    "rarad": 3.53448695240971,
    "decrad": -0.151990222482,
    "pmrarad": -0.00000532301180565972,
    "pmdecrad": -0.00000234772964,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118892,
    "lum": 0.000382648486595108,
    "ra": 13.500746,
    "dec": -8.708398,
    "pmra": -1097.95,
    "pmdec": -484.25,
    "rv": 0,
    "Asset Name": "ASTRID 000913",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "High Bun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002539,
    "y_1": 0.00007496,
    "z_1": -0.00003443,
    "Distance in Parsecs_1": 14.8368,
    "HYG Star ID_1": 118892,
    "Gliese Catalog_1": "Gl 514.1"
  },
  {
    "Astrid #": "ASTRID 000914",
    "HYG Star ID": 100597,
    "Hipparcos Catalogue": 100923,
    "Gliese Catalog": "Gl 791",
    "Distance in Parsecs": 14.8412,
    "mag": 11.41,
    "Absolute Visual Magnitude": 10.553,
    "Spectral Type": "M3",
    "Color Index": 1.504,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.890496,
    "y": -10.500156,
    "z": -6.909686,
    "vx": -0.00003801,
    "vy": 0.0000263,
    "vz": -0.00004822,
    "rarad": 5.35682797537493,
    "decrad": -0.484288842844537,
    "pmrarad": -9.82814293222222e-7,
    "pmdecrad": -0.00000425152509,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 100597,
    "lum": 0.00523359365186757,
    "var_min": 11.471,
    "var_max": 11.311,
    "ra": 20.461576,
    "dec": -27.747707,
    "pmra": -202.72,
    "pmdec": -876.94,
    "rv": -16,
    "Asset Name": "ASTRID 000914",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Nervous Grin",
    "Hair": "Bangs",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": -0.00003801,
    "y_1": 0.0000263,
    "z_1": -0.00004822,
    "Distance in Parsecs_1": 14.8412,
    "HYG Star ID_1": 100597,
    "Hipparcos Catalogue_1": 100923,
    "Gliese Catalog_1": "Gl 791"
  },
  {
    "Astrid #": "ASTRID 000915",
    "HYG Star ID": 92725,
    "Hipparcos Catalogue": 93017,
    "Henry Draper Catalogue": 176051,
    "Harvard Revised Catalogue": 7162,
    "Gliese Catalog": "Gl 738A",
    "Distance in Parsecs": 14.8721,
    "mag": 5.2,
    "Absolute Visual Magnitude": 4.338,
    "Spectral Type": "G0V",
    "Color Index": 0.594,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.074982,
    "y": -12.101832,
    "z": 8.0782,
    "vx": 0.00000588,
    "vy": 0.00003625,
    "vz": -0.00003416,
    "rarad": 4.96121386413982,
    "decrad": 0.574235550620268,
    "pmrarad": 9.83444551006944e-7,
    "pmdecrad": -6.97986255e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 92725,
    "base": "Gl 738",
    "lum": 1.60250723888141,
    "ra": 18.950441,
    "dec": 32.901273,
    "pmra": 202.85,
    "pmdec": -143.97,
    "rv": -45.8,
    "Asset Name": "ASTRID 000915",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Heartbreaker",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00000588,
    "y_1": 0.00003625,
    "z_1": -0.00003416,
    "Distance in Parsecs_1": 14.8721,
    "HYG Star ID_1": 92725,
    "Hipparcos Catalogue_1": 93017,
    "Henry Draper Catalogue_1": 176051,
    "Harvard Revised Catalogue_1": 7162,
    "Gliese Catalog_1": "Gl 738A"
  },
  {
    "Astrid #": "ASTRID 000916",
    "HYG Star ID": 28032,
    "Hipparcos Catalogue": 28103,
    "Henry Draper Catalogue": 40136,
    "Harvard Revised Catalogue": 2085,
    "Gliese Catalog": "Gl 225",
    "Distance in Parsecs": 14.8787,
    "mag": 3.71,
    "Absolute Visual Magnitude": 2.847,
    "Spectral Type": "F1V",
    "Color Index": 0.337,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.226289,
    "y": 14.424355,
    "z": -3.64172,
    "vx": 0.00000306,
    "vy": 0.00000102,
    "vz": 0.00001007,
    "rarad": 1.55510971555284,
    "decrad": -0.247273014605192,
    "pmrarad": -2.04736817298611e-7,
    "pmdecrad": 6.73987978e-7,
    "bayer": "Eta",
    "flam": 16,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 28032,
    "lum": 6.327031548665,
    "bf": "16Eta Lep",
    "ra": 5.940082,
    "dec": -14.1677,
    "pmra": -42.23,
    "pmdec": 139.02,
    "rv": -1.4,
    "Asset Name": "ASTRID 000916",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Worried Vamp",
    "Hair": "Fire",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000306,
    "y_1": 0.00000102,
    "z_1": 0.00001007,
    "Distance in Parsecs_1": 14.8787,
    "HYG Star ID_1": 28032,
    "Hipparcos Catalogue_1": 28103,
    "Henry Draper Catalogue_1": 40136,
    "Harvard Revised Catalogue_1": 2085,
    "Gliese Catalog_1": "Gl 225"
  },
  {
    "Astrid #": "ASTRID 000917",
    "HYG Star ID": 61952,
    "Hipparcos Catalogue": 62145,
    "Henry Draper Catalogue": 110833,
    "Gliese Catalog": "Gl 483",
    "Distance in Parsecs": 14.881,
    "mag": 7.01,
    "Absolute Visual Magnitude": 6.147,
    "Spectral Type": "K3V",
    "Color Index": 0.936,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.039804,
    "y": -1.767085,
    "z": 11.687872,
    "vx": -0.00002205,
    "vy": 0.00002353,
    "vz": 3.1e-7,
    "rarad": 3.3346465325474,
    "decrad": 0.903370234438711,
    "pmrarad": -0.00000183628029647222,
    "pmdecrad": -8.91378433e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 61952,
    "lum": 0.302830769426577,
    "ra": 12.737411,
    "dec": 51.759302,
    "pmra": -378.76,
    "pmdec": -183.86,
    "rv": 10.6,
    "Asset Name": "ASTRID 000917",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Neko",
    "Outfit": "Toga",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00002205,
    "y_1": 0.00002353,
    "z_1": 3.1e-7,
    "Distance in Parsecs_1": 14.881,
    "HYG Star ID_1": 61952,
    "Hipparcos Catalogue_1": 62145,
    "Henry Draper Catalogue_1": 110833,
    "Gliese Catalog_1": "Gl 483"
  },
  {
    "Astrid #": "ASTRID 000918",
    "HYG Star ID": 119182,
    "Gliese Catalog": "NN 4029",
    "Distance in Parsecs": 14.881,
    "mag": 20.1,
    "Absolute Visual Magnitude": 19.237,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.599918,
    "y": -13.716844,
    "z": 5.738679,
    "vx": -0.00005543,
    "vy": -0.00000158,
    "vz": -0.00000955,
    "rarad": 4.66869653509962,
    "decrad": 0.39589926898175,
    "pmrarad": -0.00000371701800745138,
    "pmdecrad": -6.95620365e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119182,
    "lum": 0.00000175873335413557,
    "ra": 17.833107,
    "dec": 22.683357,
    "pmra": -766.69,
    "pmdec": -143.48,
    "rv": 0,
    "Asset Name": "ASTRID 000918",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Bangs",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005543,
    "y_1": -0.00000158,
    "z_1": -0.00000955,
    "Distance in Parsecs_1": 14.881,
    "HYG Star ID_1": 119182,
    "Gliese Catalog_1": "NN 4029"
  },
  {
    "Astrid #": "ASTRID 000919",
    "HYG Star ID": 119222,
    "Gliese Catalog": "GJ 1228",
    "Distance in Parsecs": 14.881,
    "mag": 15.5,
    "Absolute Visual Magnitude": 14.637,
    "Spectral Type": "DXP8",
    "Color Index": 0.49,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 1.132589,
    "y": -8.505061,
    "z": 12.158345,
    "vx": -0.00001984,
    "vy": 0.0000137,
    "vz": 0.00001144,
    "rarad": 4.84482061391448,
    "decrad": 0.956255271912,
    "pmrarad": -0.00000120030171031944,
    "pmdecrad": 0.00000133305824,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119222,
    "lum": 0.000121674620397912,
    "ra": 18.505852,
    "dec": 54.789391,
    "pmra": -247.58,
    "pmdec": 274.96,
    "rv": 0,
    "Asset Name": "ASTRID 000919",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "High Bun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001984,
    "y_1": 0.0000137,
    "z_1": 0.00001144,
    "Distance in Parsecs_1": 14.881,
    "HYG Star ID_1": 119222,
    "Gliese Catalog_1": "GJ 1228"
  },
  {
    "Astrid #": "ASTRID 000920",
    "HYG Star ID": 119566,
    "Gliese Catalog": "NN 4337A",
    "Distance in Parsecs": 14.881,
    "mag": 11.87,
    "Absolute Visual Magnitude": 11.007,
    "Spectral Type": "M2",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.050456,
    "y": -1.485069,
    "z": 9.855261,
    "vx": 0.00000548,
    "vy": 0.00002827,
    "vz": -0.00000188,
    "rarad": 6.14956357127771,
    "decrad": 0.72384618921275,
    "pmrarad": 0.00000193188555427777,
    "pmdecrad": -1.69015745e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 119566,
    "base": "NN 4337",
    "lum": 0.00344508546286578,
    "ra": 23.489603,
    "dec": 41.473332,
    "pmra": 398.48,
    "pmdec": -34.86,
    "rv": 0,
    "Asset Name": "ASTRID 000920",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Shinobi",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000548,
    "y_1": 0.00002827,
    "z_1": -0.00000188,
    "Distance in Parsecs_1": 14.881,
    "HYG Star ID_1": 119566,
    "Gliese Catalog_1": "NN 4337A"
  },
  {
    "Astrid #": "ASTRID 000921",
    "HYG Star ID": 85769,
    "Hipparcos Catalogue": 86032,
    "Henry Draper Catalogue": 159561,
    "Harvard Revised Catalogue": 6556,
    "Gliese Catalog": "Gl 681",
    "Proper Name": "Rasalhague",
    "Distance in Parsecs": 14.8965,
    "mag": 2.08,
    "Absolute Visual Magnitude": 1.215,
    "Spectral Type": "A5III",
    "Color Index": 0.155,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -1.587067,
    "y": -14.453242,
    "z": 3.239454,
    "vx": 0.00000615,
    "vy": -0.00001685,
    "vz": -0.00001289,
    "rarad": 4.60302002272537,
    "decrad": 0.219213960617327,
    "pmrarad": 5.33682899555555e-7,
    "pmdecrad": -0.000001079243734,
    "bayer": "Alp",
    "flam": 55,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 85769,
    "lum": 28.4446110744791,
    "bf": "55Alp Oph",
    "ra": 17.582241,
    "dec": 12.560035,
    "pmra": 110.08,
    "pmdec": -222.61,
    "rv": 12.6,
    "Asset Name": "ASTRID 000921",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Laughing",
    "Hair": "Angelic",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Rasalhague",
    "Constellation": "Ophiuchus",
    "x_1": 0.00000615,
    "y_1": -0.00001685,
    "z_1": -0.00001289,
    "Distance in Parsecs_1": 14.8965,
    "HYG Star ID_1": 85769,
    "Hipparcos Catalogue_1": 86032,
    "Henry Draper Catalogue_1": 159561,
    "Harvard Revised Catalogue_1": 6556,
    "Gliese Catalog_1": "Gl 681"
  },
  {
    "Astrid #": "ASTRID 000922",
    "HYG Star ID": 118467,
    "Gliese Catalog": "GJ 1096",
    "Distance in Parsecs": 14.9031,
    "mag": 14.48,
    "Absolute Visual Magnitude": 13.614,
    "Spectral Type": "m+",
    "Color Index": 1.75,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.077299,
    "y": 11.791939,
    "z": 8.15035,
    "vx": 0.0000037,
    "vy": 0.00001798,
    "vz": -0.00002416,
    "rarad": 1.9037039410162,
    "decrad": 0.5786444382845,
    "pmrarad": -6.29239675993055e-7,
    "pmdecrad": -0.00000193655431,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118467,
    "lum": 0.000312176351084816,
    "ra": 7.271613,
    "dec": 33.153884,
    "pmra": -129.79,
    "pmdec": -399.44,
    "rv": 0,
    "Asset Name": "ASTRID 000922",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Honest Lad",
    "Outfit": "Astro",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000037,
    "y_1": 0.00001798,
    "z_1": -0.00002416,
    "Distance in Parsecs_1": 14.9031,
    "HYG Star ID_1": 118467,
    "Gliese Catalog_1": "GJ 1096"
  },
  {
    "Astrid #": "ASTRID 000923",
    "HYG Star ID": 84263,
    "Hipparcos Catalogue": 84521,
    "Gliese Catalog": "GJ 2128",
    "Distance in Parsecs": 14.9076,
    "mag": 11.49,
    "Absolute Visual Magnitude": 10.623,
    "Spectral Type": "M3.5",
    "Color Index": 1.517,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.773224,
    "y": -14.497538,
    "z": 2.089781,
    "vx": -0.00002005,
    "vy": 0.00000314,
    "vz": -0.0000048,
    "rarad": 4.52338301167314,
    "decrad": 0.140645465961892,
    "pmrarad": -0.00000136106592679166,
    "pmdecrad": -3.25261498e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84263,
    "lum": 0.00490681856767951,
    "var_min": 11.55,
    "var_max": 11.43,
    "ra": 17.27805,
    "dec": 8.058392,
    "pmra": -280.74,
    "pmdec": -67.09,
    "rv": 0,
    "Asset Name": "ASTRID 000923",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Bucket Hat",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002005,
    "y_1": 0.00000314,
    "z_1": -0.0000048,
    "Distance in Parsecs_1": 14.9076,
    "HYG Star ID_1": 84263,
    "Hipparcos Catalogue_1": 84521,
    "Gliese Catalog_1": "GJ 2128"
  },
  {
    "Astrid #": "ASTRID 000924",
    "HYG Star ID": 98834,
    "Hipparcos Catalogue": 99150,
    "Gliese Catalog": "Gl 781.1A",
    "Distance in Parsecs": 14.9076,
    "mag": 12.19,
    "Absolute Visual Magnitude": 11.323,
    "Spectral Type": "M",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.705597,
    "y": -10.757328,
    "z": -7.845469,
    "vx": 0.00000263,
    "vy": 0.0000354,
    "vz": -0.00004629,
    "rarad": 5.26979984041978,
    "decrad": -0.554211687584583,
    "pmrarad": 0.00000140610511771527,
    "pmdecrad": -0.000003652295381,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 98834,
    "base": "Gl 781.1",
    "lum": 0.00257513499041031,
    "var_min": 12.327,
    "var_max": 11.967,
    "ra": 20.129153,
    "dec": -31.753991,
    "pmra": 290.03,
    "pmdec": -753.34,
    "rv": 0,
    "Asset Name": "ASTRID 000924",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Honest Lad",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000263,
    "y_1": 0.0000354,
    "z_1": -0.00004629,
    "Distance in Parsecs_1": 14.9076,
    "HYG Star ID_1": 98834,
    "Hipparcos Catalogue_1": 99150,
    "Gliese Catalog_1": "Gl 781.1A"
  },
  {
    "Astrid #": "ASTRID 000925",
    "HYG Star ID": 118071,
    "Gliese Catalog": "NN 3097",
    "Distance in Parsecs": 14.9254,
    "mag": 13.13,
    "Absolute Visual Magnitude": 12.26,
    "Spectral Type": "dM3.5",
    "Color Index": 1.68,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 13.695193,
    "y": 5.367509,
    "z": 2.529644,
    "vx": -0.00000118,
    "vy": 0.00001691,
    "vz": -0.00002948,
    "rarad": 0.373526350625075,
    "decrad": 0.1703078977704,
    "pmrarad": 0.00000108375250151388,
    "pmdecrad": -0.000002004384592,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118071,
    "lum": 0.00108642562361706,
    "ra": 1.426766,
    "dec": 9.757924,
    "pmra": 223.54,
    "pmdec": -413.43,
    "rv": 0,
    "Asset Name": "ASTRID 000925",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Middle Part",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000118,
    "y_1": 0.00001691,
    "z_1": -0.00002948,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 118071,
    "Gliese Catalog_1": "NN 3097"
  },
  {
    "Astrid #": "ASTRID 000926",
    "HYG Star ID": 118090,
    "Gliese Catalog": "NN 3117A",
    "Distance in Parsecs": 14.9254,
    "mag": 11.37,
    "Absolute Visual Magnitude": 10.5,
    "Spectral Type": "dM2",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.688831,
    "y": 3.019458,
    "z": 13.46346,
    "vx": -2e-7,
    "vy": 0.00001524,
    "vz": -0.00002034,
    "rarad": 0.487899292345594,
    "decrad": 1.1246034869847,
    "pmrarad": 9.08346911888889e-7,
    "pmdecrad": -0.000001008800306,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 118090,
    "base": "NN 3117",
    "lum": 0.00549540873857624,
    "ra": 1.863638,
    "dec": 64.435033,
    "pmra": 187.36,
    "pmdec": -208.08,
    "rv": -15,
    "Asset Name": "ASTRID 000926",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Middle Part",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -2e-7,
    "y_1": 0.00001524,
    "z_1": -0.00002034,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 118090,
    "Gliese Catalog_1": "NN 3117A"
  },
  {
    "Astrid #": "ASTRID 000927",
    "HYG Star ID": 118091,
    "Gliese Catalog": "NN 3118B",
    "Distance in Parsecs": 14.9254,
    "mag": 14.5,
    "Absolute Visual Magnitude": 13.63,
    "Spectral Type": "DA6",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.689817,
    "y": 3.019976,
    "z": 13.463777,
    "vx": 0.00000564,
    "vy": 0.00001834,
    "vz": -0.0000065,
    "rarad": 0.487898614345594,
    "decrad": 1.12454530998469,
    "pmrarad": 9.08346911888889e-7,
    "pmdecrad": -0.000001008800306,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118091,
    "base": "NN 3118",
    "lum": 0.00030760968147407,
    "ra": 1.863635,
    "dec": 64.4317,
    "pmra": 187.36,
    "pmdec": -208.08,
    "rv": 0,
    "Asset Name": "ASTRID 000927",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Braid",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000564,
    "y_1": 0.00001834,
    "z_1": -0.0000065,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 118091,
    "Gliese Catalog_1": "NN 3118B"
  },
  {
    "Astrid #": "ASTRID 000928",
    "HYG Star ID": 118202,
    "Gliese Catalog": "NN 3208",
    "Distance in Parsecs": 14.9254,
    "mag": 16.77,
    "Absolute Visual Magnitude": 15.9,
    "Spectral Type": "m",
    "Color Index": 2.15,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.6682,
    "y": 9.81443,
    "z": 7.16288,
    "vx": 9.2e-7,
    "vy": 0.00003488,
    "vz": -0.00004888,
    "rarad": 0.847324967309613,
    "decrad": 0.5005544535227,
    "pmrarad": 0.00000150088619226388,
    "pmdecrad": -0.000003733569546,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118202,
    "lum": 0.000038018939632056,
    "ra": 3.236543,
    "dec": 28.679658,
    "pmra": 309.58,
    "pmdec": -770.1,
    "rv": 0,
    "Asset Name": "ASTRID 000928",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Einstein Tongue",
    "Hair": "Prince",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 9.2e-7,
    "y_1": 0.00003488,
    "z_1": -0.00004888,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 118202,
    "Gliese Catalog_1": "NN 3208"
  },
  {
    "Astrid #": "ASTRID 000929",
    "HYG Star ID": 118266,
    "Gliese Catalog": "NN 3256",
    "Distance in Parsecs": 14.9254,
    "mag": 11.22,
    "Absolute Visual Magnitude": 10.35,
    "Spectral Type": "M3",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.675213,
    "y": 12.578295,
    "z": -2.375961,
    "vx": -0.00001407,
    "vy": 0.00000633,
    "vz": -0.00001198,
    "rarad": 1.0229135982874,
    "decrad": -0.15986919480875,
    "pmrarad": 0.00000102586574805555,
    "pmdecrad": -8.13076175e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118266,
    "lum": 0.00630957344480193,
    "ra": 3.907242,
    "dec": -9.15983,
    "pmra": 211.6,
    "pmdec": -167.71,
    "rv": 0,
    "Asset Name": "ASTRID 000929",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Goddess",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001407,
    "y_1": 0.00000633,
    "z_1": -0.00001198,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 118266,
    "Gliese Catalog_1": "NN 3256"
  },
  {
    "Astrid #": "ASTRID 000930",
    "HYG Star ID": 118977,
    "Gliese Catalog": "NN 3856",
    "Distance in Parsecs": 14.9254,
    "mag": 13.61,
    "Absolute Visual Magnitude": 12.74,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.298319,
    "y": -8.841002,
    "z": 4.11731,
    "vx": 0.00000676,
    "vy": -0.00001044,
    "vz": -0.00000387,
    "rarad": 3.80557342062698,
    "decrad": 0.27948358560435,
    "pmrarad": 8.29952539743055e-7,
    "pmdecrad": -2.69667913e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118977,
    "lum": 0.00069823240407717,
    "ra": 14.53622,
    "dec": 16.01323,
    "pmra": 171.19,
    "pmdec": -55.62,
    "rv": 0,
    "Asset Name": "ASTRID 000930",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Fedora",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000676,
    "y_1": -0.00001044,
    "z_1": -0.00000387,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 118977,
    "Gliese Catalog_1": "NN 3856"
  },
  {
    "Astrid #": "ASTRID 000931",
    "HYG Star ID": 119283,
    "Gliese Catalog": "NN 4109",
    "Distance in Parsecs": 14.9254,
    "mag": 12.48,
    "Absolute Visual Magnitude": 11.61,
    "Spectral Type": "M3.5",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.768335,
    "y": -12.238717,
    "z": 7.088325,
    "vx": 0.000007,
    "vy": 0.00001644,
    "vz": 0.00002368,
    "rarad": 5.08390143226876,
    "decrad": 0.494869648194999,
    "pmrarad": 8.36885375375e-7,
    "pmdecrad": 0.0000018029639,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119283,
    "lum": 0.00197696964011186,
    "ra": 19.419073,
    "dec": 28.353942,
    "pmra": 172.62,
    "pmdec": 371.89,
    "rv": 0,
    "Asset Name": "ASTRID 000931",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Pixie",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.000007,
    "y_1": 0.00001644,
    "z_1": 0.00002368,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 119283,
    "Gliese Catalog_1": "NN 4109"
  },
  {
    "Astrid #": "ASTRID 000932",
    "HYG Star ID": 119498,
    "Gliese Catalog": "NN 4276",
    "Distance in Parsecs": 14.9254,
    "mag": 12.91,
    "Absolute Visual Magnitude": 12.04,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.955262,
    "y": -3.050273,
    "z": 12.848647,
    "vx": 0.00002171,
    "vy": 9.7e-7,
    "vz": -0.00001152,
    "rarad": 5.86985761574873,
    "decrad": 1.0369529996888,
    "pmrarad": 6.44074974618055e-7,
    "pmdecrad": -0.000001517326224,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119498,
    "lum": 0.00133045441797809,
    "ra": 22.421205,
    "dec": 59.41303,
    "pmra": 132.85,
    "pmdec": -312.97,
    "rv": 0,
    "Asset Name": "ASTRID 000932",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Straw Hat",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002171,
    "y_1": 9.7e-7,
    "z_1": -0.00001152,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 119498,
    "Gliese Catalog_1": "NN 4276"
  },
  {
    "Astrid #": "ASTRID 000933",
    "HYG Star ID": 119578,
    "Gliese Catalog": "NN 4352",
    "Distance in Parsecs": 14.9254,
    "mag": 11.34,
    "Absolute Visual Magnitude": 10.47,
    "Spectral Type": "M2",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.264856,
    "y": -1.365336,
    "z": -4.173405,
    "vx": -0.00000304,
    "vy": -0.00001883,
    "vz": -0.00000422,
    "rarad": 6.18776539260846,
    "decrad": -0.28339582082185,
    "pmrarad": -0.00000127544783080555,
    "pmdecrad": -2.94456437e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 119578,
    "lum": 0.00564936974812301,
    "ra": 23.635523,
    "dec": -16.237384,
    "pmra": -263.08,
    "pmdec": -60.74,
    "rv": 0,
    "Asset Name": "ASTRID 000933",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Angelic",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000304,
    "y_1": -0.00001883,
    "z_1": -0.00000422,
    "Distance in Parsecs_1": 14.9254,
    "HYG Star ID_1": 119578,
    "Gliese Catalog_1": "NN 4352"
  },
  {
    "Astrid #": "ASTRID 000934",
    "HYG Star ID": 34027,
    "Hipparcos Catalogue": 34115,
    "Gliese Catalog": "Gl 258",
    "Distance in Parsecs": 14.9298,
    "mag": 11.95,
    "Absolute Visual Magnitude": 11.08,
    "Spectral Type": "M5",
    "Color Index": 1.527,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.532346,
    "y": 5.306124,
    "z": 13.870685,
    "vx": -0.00002311,
    "vy": -0.00001048,
    "vz": 0.00000145,
    "rarad": 1.85191055176168,
    "decrad": 1.19186443116784,
    "pmrarad": 0.00000168191562058333,
    "pmdecrad": 2.63447754e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 34027,
    "lum": 0.00322106879128343,
    "var_min": 12.037,
    "var_max": 11.817,
    "ra": 7.073777,
    "dec": 68.288802,
    "pmra": 346.92,
    "pmdec": 54.34,
    "rv": 0,
    "Asset Name": "ASTRID 000934",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Maniacal",
    "Hair": "Pompadour",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00002311,
    "y_1": -0.00001048,
    "z_1": 0.00000145,
    "Distance in Parsecs_1": 14.9298,
    "HYG Star ID_1": 34027,
    "Hipparcos Catalogue_1": 34115,
    "Gliese Catalog_1": "Gl 258"
  },
  {
    "Astrid #": "ASTRID 000935",
    "HYG Star ID": 5236,
    "Hipparcos Catalogue": 5247,
    "Gliese Catalog": "Gl 52",
    "Distance in Parsecs": 14.9321,
    "mag": 9,
    "Absolute Visual Magnitude": 8.129,
    "Spectral Type": "K7V",
    "Color Index": 1.289,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.280112,
    "y": 1.894184,
    "z": 13.414196,
    "vx": -0.00005098,
    "vy": 0.00010168,
    "vz": 0.00001282,
    "rarad": 0.292855139921482,
    "decrad": 1.11598667723979,
    "pmrarad": 0.00000750593388372916,
    "pmdecrad": 0.000001547913119,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 5236,
    "lum": 0.0487977727286485,
    "ra": 1.118624,
    "dec": 63.941327,
    "pmra": 1548.21,
    "pmdec": 319.28,
    "rv": 2.9,
    "Asset Name": "ASTRID 000935",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Angry",
    "Hair": "Braid",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00005098,
    "y_1": 0.00010168,
    "z_1": 0.00001282,
    "Distance in Parsecs_1": 14.9321,
    "HYG Star ID_1": 5236,
    "Hipparcos Catalogue_1": 5247,
    "Gliese Catalog_1": "Gl 52"
  },
  {
    "Astrid #": "ASTRID 000936",
    "HYG Star ID": 42644,
    "Hipparcos Catalogue": 42762,
    "Gliese Catalog": "Gl 319B",
    "Distance in Parsecs": 14.9343,
    "mag": 11.83,
    "Absolute Visual Magnitude": 10.959,
    "Spectral Type": "M5",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.607013,
    "y": 11.162238,
    "z": 2.47852,
    "vx": -0.0000171,
    "vy": -0.00000495,
    "vz": -0.00004399,
    "rarad": 2.28145271919756,
    "decrad": 0.166733035027862,
    "pmrarad": 0.00000108447972203472,
    "pmdecrad": -0.00000298708253,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 2,
    "comp_primary": 42629,
    "base": "Gl 319",
    "lum": 0.00360080829173482,
    "var_min": 11.964,
    "var_max": 11.684,
    "ra": 8.714507,
    "dec": 9.553099,
    "pmra": 223.69,
    "pmdec": -616.13,
    "rv": 0,
    "Asset Name": "ASTRID 000936",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Smoulder",
    "Hair": "Headband",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.0000171,
    "y_1": -0.00000495,
    "z_1": -0.00004399,
    "Distance in Parsecs_1": 14.9343,
    "HYG Star ID_1": 42644,
    "Hipparcos Catalogue_1": 42762,
    "Gliese Catalog_1": "Gl 319B"
  },
  {
    "Astrid #": "ASTRID 000937",
    "HYG Star ID": 59020,
    "Hipparcos Catalogue": 59199,
    "Henry Draper Catalogue": 105452,
    "Harvard Revised Catalogue": 4623,
    "Gliese Catalog": "Gl 455.3",
    "Distance in Parsecs": 14.9365,
    "mag": 4.02,
    "Absolute Visual Magnitude": 3.149,
    "Spectral Type": "F0IV/V",
    "Color Index": 0.334,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.557668,
    "y": -0.497944,
    "z": -6.248321,
    "vx": -0.00000124,
    "vy": -0.0000073,
    "vz": -0.00000383,
    "rarad": 3.17830354775552,
    "decrad": -0.431600297425675,
    "pmrarad": 4.85686345180555e-7,
    "pmdecrad": -1.9067722e-7,
    "bayer": "Alp",
    "flam": 1,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 59020,
    "lum": 4.79071130003074,
    "bf": "1Alp Crv",
    "ra": 12.140225,
    "dec": -24.728875,
    "pmra": 100.18,
    "pmdec": -39.33,
    "rv": 2.9,
    "Asset Name": "ASTRID 000937",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Scared",
    "Hair": "Ponytail",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000124,
    "y_1": -0.0000073,
    "z_1": -0.00000383,
    "Distance in Parsecs_1": 14.9365,
    "HYG Star ID_1": 59020,
    "Hipparcos Catalogue_1": 59199,
    "Henry Draper Catalogue_1": 105452,
    "Harvard Revised Catalogue_1": 4623,
    "Gliese Catalog_1": "Gl 455.3"
  },
  {
    "Astrid #": "ASTRID 000938",
    "HYG Star ID": 109292,
    "Hipparcos Catalogue": 109638,
    "Gliese Catalog": "Wo 9773",
    "Distance in Parsecs": 14.9611,
    "mag": 11.99,
    "Absolute Visual Magnitude": 11.115,
    "Spectral Type": "M3",
    "Color Index": 1.553,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.199692,
    "y": -6.682168,
    "z": 2.225142,
    "vx": 0.00001017,
    "vy": 0.00000429,
    "vz": -0.00004744,
    "rarad": 5.81456074997991,
    "decrad": 0.149282367499212,
    "pmrarad": 5.62626276284722e-7,
    "pmdecrad": -0.00000320680009,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 109292,
    "lum": 0.00311888958409393,
    "var_min": 12.097,
    "var_max": 11.857,
    "ra": 22.209986,
    "dec": 8.55325,
    "pmra": 116.05,
    "pmdec": -661.45,
    "rv": 0,
    "Asset Name": "ASTRID 000938",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Fire",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001017,
    "y_1": 0.00000429,
    "z_1": -0.00004744,
    "Distance in Parsecs_1": 14.9611,
    "HYG Star ID_1": 109292,
    "Hipparcos Catalogue_1": 109638,
    "Gliese Catalog_1": "Wo 9773"
  },
  {
    "Astrid #": "ASTRID 000939",
    "HYG Star ID": 118407,
    "Gliese Catalog": "Gl 225.2B",
    "Distance in Parsecs": 14.9656,
    "mag": 9.1,
    "Absolute Visual Magnitude": 8.225,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.018552,
    "y": 12.824058,
    "z": -7.714427,
    "vx": 0.000029,
    "vy": 0.0000147,
    "vz": 0.00002436,
    "rarad": 1.5722591650232,
    "decrad": -0.54156453733355,
    "pmrarad": -0.00000193973953590277,
    "pmdecrad": 0.000001899553329,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 2,
    "comp_primary": 28371,
    "base": "Gl 225.2",
    "lum": 0.0446683592150963,
    "ra": 6.005588,
    "dec": -31.029362,
    "pmra": -400.1,
    "pmdec": 391.81,
    "rv": 0,
    "Asset Name": "ASTRID 000939",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Little Crown",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": 0.000029,
    "y_1": 0.0000147,
    "z_1": 0.00002436,
    "Distance in Parsecs_1": 14.9656,
    "HYG Star ID_1": 118407,
    "Gliese Catalog_1": "Gl 225.2B"
  },
  {
    "Astrid #": "ASTRID 000940",
    "HYG Star ID": 118408,
    "Gliese Catalog": "Gl 225.2C",
    "Distance in Parsecs": 14.9656,
    "mag": 8.3,
    "Absolute Visual Magnitude": 7.425,
    "Spectral Type": "K5 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.018559,
    "y": 12.828658,
    "z": -7.717196,
    "vx": 0.00002886,
    "vy": 0.00010671,
    "vz": -0.00003101,
    "rarad": 1.5722591650232,
    "decrad": -0.54156453733355,
    "pmrarad": -0.00000193973953590277,
    "pmdecrad": 0.000001899553329,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 3,
    "comp_primary": 28371,
    "base": "Gl 225.2",
    "lum": 0.0933254300796991,
    "ra": 6.005588,
    "dec": -31.029362,
    "pmra": -400.1,
    "pmdec": 391.81,
    "rv": 105,
    "Asset Name": "ASTRID 000940",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Wavy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": 0.00002886,
    "y_1": 0.00010671,
    "z_1": -0.00003101,
    "Distance in Parsecs_1": 14.9656,
    "HYG Star ID_1": 118408,
    "Gliese Catalog_1": "Gl 225.2C"
  },
  {
    "Astrid #": "ASTRID 000941",
    "HYG Star ID": 119253,
    "Gliese Catalog": "Gl 738B",
    "Distance in Parsecs": 14.979,
    "mag": 7.7,
    "Absolute Visual Magnitude": 6.823,
    "Spectral Type": "K1 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.09699,
    "y": -12.189266,
    "z": 8.136394,
    "vx": 0.00001241,
    "vy": -0.00000321,
    "vz": -0.00000953,
    "rarad": 4.96119231445853,
    "decrad": 0.5742279406304,
    "pmrarad": 7.50249170659722e-7,
    "pmdecrad": -7.58127392e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 2,
    "comp_primary": 92725,
    "base": "Gl 738",
    "lum": 0.162480033522731,
    "ra": 18.950359,
    "dec": 32.900837,
    "pmra": 154.75,
    "pmdec": -156.38,
    "rv": 0,
    "Asset Name": "ASTRID 000941",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Round Brush",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00001241,
    "y_1": -0.00000321,
    "z_1": -0.00000953,
    "Distance in Parsecs_1": 14.979,
    "HYG Star ID_1": 119253,
    "Gliese Catalog_1": "Gl 738B"
  },
  {
    "Astrid #": "ASTRID 000942",
    "HYG Star ID": 113999,
    "Hipparcos Catalogue": 114361,
    "Henry Draper Catalogue": 218511,
    "Gliese Catalog": "GJ 1279",
    "Distance in Parsecs": 14.9835,
    "mag": 8.29,
    "Absolute Visual Magnitude": 7.412,
    "Spectral Type": "K5V",
    "Color Index": 1.201,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.541349,
    "y": -1.236543,
    "z": -13.866135,
    "vx": -0.00001913,
    "vy": -0.00001932,
    "vz": -0.00000592,
    "rarad": 6.06365601432895,
    "decrad": -1.18216079376896,
    "pmrarad": -0.000001536762404625,
    "pmdecrad": -0.000001043173596,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 113999,
    "lum": 0.0944495732507706,
    "ra": 23.16146,
    "dec": -67.732824,
    "pmra": -316.98,
    "pmdec": -215.17,
    "rv": 0,
    "Asset Name": "ASTRID 000942",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Frizzy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00001913,
    "y_1": -0.00001932,
    "z_1": -0.00000592,
    "Distance in Parsecs_1": 14.9835,
    "HYG Star ID_1": 113999,
    "Hipparcos Catalogue_1": 114361,
    "Henry Draper Catalogue_1": 218511,
    "Gliese Catalog_1": "GJ 1279"
  },
  {
    "Astrid #": "ASTRID 000943",
    "HYG Star ID": 118172,
    "Gliese Catalog": "NN 3181",
    "Distance in Parsecs": 14.9925,
    "mag": 16.86,
    "Absolute Visual Magnitude": 15.981,
    "Spectral Type": "M6",
    "Color Index": 2.02,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.74547,
    "y": 9.555065,
    "z": 4.237752,
    "vx": 0.00002267,
    "vy": -0.00005859,
    "vz": -0.00004833,
    "rarad": 0.726834451270247,
    "decrad": 0.28659833161045,
    "pmrarad": -0.00000392495459504166,
    "pmdecrad": -0.000002677407791,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118172,
    "lum": 0.0000352858025828527,
    "ra": 2.776303,
    "dec": 16.420875,
    "pmra": -809.58,
    "pmdec": -552.25,
    "rv": -34,
    "Asset Name": "ASTRID 000943",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Pompadour",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002267,
    "y_1": -0.00005859,
    "z_1": -0.00004833,
    "Distance in Parsecs_1": 14.9925,
    "HYG Star ID_1": 118172,
    "Gliese Catalog_1": "NN 3181"
  },
  {
    "Astrid #": "ASTRID 000944",
    "HYG Star ID": 19346,
    "Hipparcos Catalogue": 19394,
    "Gliese Catalog": "Gl 163",
    "Distance in Parsecs": 14.9948,
    "mag": 11.79,
    "Absolute Visual Magnitude": 10.91,
    "Spectral Type": "M",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.156435,
    "y": 7.921972,
    "z": -12.034456,
    "vx": -0.00003269,
    "vy": 0.00010097,
    "vz": -0.00002894,
    "rarad": 1.08757674042757,
    "decrad": -0.931546763984386,
    "pmrarad": 0.00000506004886532638,
    "pmdecrad": 0.000002821373213,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 19346,
    "lum": 0.00376703798983908,
    "var_min": 11.882,
    "var_max": 11.682,
    "ra": 4.154237,
    "dec": -53.373698,
    "pmra": 1043.71,
    "pmdec": 581.95,
    "rv": 66,
    "Asset Name": "ASTRID 000944",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Bangs",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.00003269,
    "y_1": 0.00010097,
    "z_1": -0.00002894,
    "Distance in Parsecs_1": 14.9948,
    "HYG Star ID_1": 19346,
    "Hipparcos Catalogue_1": 19394,
    "Gliese Catalog_1": "Gl 163"
  },
  {
    "Astrid #": "ASTRID 000945",
    "HYG Star ID": 1365,
    "Hipparcos Catalogue": 1368,
    "Gliese Catalog": "Gl 14",
    "Distance in Parsecs": 15.0038,
    "mag": 8.99,
    "Absolute Visual Magnitude": 8.109,
    "Spectral Type": "M0",
    "Color Index": 1.37,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.300858,
    "y": 0.845066,
    "z": 9.833173,
    "vx": -0.0000047,
    "vy": 0.00004107,
    "vz": 0.00000656,
    "rarad": 0.0746322602704398,
    "decrad": 0.714682574626684,
    "pmrarad": 0.00000275340233597916,
    "pmdecrad": 4.01328764e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 1365,
    "lum": 0.0497049910576234,
    "ra": 0.285074,
    "dec": 40.948295,
    "pmra": 567.93,
    "pmdec": 82.78,
    "rv": 3,
    "Asset Name": "ASTRID 000945",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Pixie",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.0000047,
    "y_1": 0.00004107,
    "z_1": 0.00000656,
    "Distance in Parsecs_1": 15.0038,
    "HYG Star ID_1": 1365,
    "Hipparcos Catalogue_1": 1368,
    "Gliese Catalog_1": "Gl 14"
  },
  {
    "Astrid #": "ASTRID 000946",
    "HYG Star ID": 12229,
    "Hipparcos Catalogue": 12261,
    "Distance in Parsecs": 15.0105,
    "mag": 12.36,
    "Absolute Visual Magnitude": 11.478,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 6.010741,
    "y": 4.94958,
    "z": -12.833073,
    "vx": 0.00000627,
    "vy": 0.0000126,
    "vz": 0.0000078,
    "rarad": 0.68887747600193,
    "decrad": -1.02543468305333,
    "pmrarad": 3.82857363534722e-7,
    "pmdecrad": 0.000001001479619,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 12229,
    "lum": 0.00223254386161674,
    "var_min": 12.502,
    "var_max": 12.102,
    "ra": 2.631318,
    "dec": -58.75308,
    "pmra": 78.97,
    "pmdec": 206.57,
    "rv": 0,
    "Asset Name": "ASTRID 000946",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Frizzy",
    "Outfit": "Warlock",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000627,
    "y_1": 0.0000126,
    "z_1": 0.0000078,
    "Distance in Parsecs_1": 15.0105,
    "HYG Star ID_1": 12229,
    "Hipparcos Catalogue_1": 12261
  },
  {
    "Astrid #": "ASTRID 000947",
    "HYG Star ID": 47457,
    "Hipparcos Catalogue": 47592,
    "Henry Draper Catalogue": 84117,
    "Harvard Revised Catalogue": 3862,
    "Gliese Catalog": "Gl 364",
    "Distance in Parsecs": 15.0128,
    "mag": 4.93,
    "Absolute Visual Magnitude": 4.048,
    "Spectral Type": "G0V",
    "Color Index": 0.534,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.318572,
    "y": 7.76158,
    "z": -6.086167,
    "vx": -0.00001711,
    "vy": 0.00004696,
    "vz": 0.00000291,
    "rarad": 2.5405040651855,
    "decrad": -0.417405420589365,
    "pmrarad": -0.00000193552165688194,
    "pmdecrad": 0.000001275932644,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 47457,
    "lum": 2.09314830344744,
    "ra": 9.704011,
    "dec": -23.915569,
    "pmra": -399.23,
    "pmdec": 263.18,
    "rv": 35.2,
    "Asset Name": "ASTRID 000947",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Braided Ponytail",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001711,
    "y_1": 0.00004696,
    "z_1": 0.00000291,
    "Distance in Parsecs_1": 15.0128,
    "HYG Star ID_1": 47457,
    "Hipparcos Catalogue_1": 47592,
    "Henry Draper Catalogue_1": 84117,
    "Harvard Revised Catalogue_1": 3862,
    "Gliese Catalog_1": "Gl 364"
  },
  {
    "Astrid #": "ASTRID 000948",
    "HYG Star ID": 27292,
    "Hipparcos Catalogue": 27359,
    "Gliese Catalog": "Gl 218",
    "Distance in Parsecs": 15.0286,
    "mag": 10.72,
    "Absolute Visual Magnitude": 9.835,
    "Spectral Type": "M2",
    "Color Index": 1.476,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.650756,
    "y": 12.09044,
    "z": -8.903463,
    "vx": -0.00005379,
    "vy": 0.00004378,
    "vz": -0.00004116,
    "rarad": 1.51701631138376,
    "decrad": -0.63405307859462,
    "pmrarad": 0.00000373078671597916,
    "pmdecrad": -5.97096528e-7,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 27292,
    "lum": 0.0101391138573667,
    "var_min": 10.8,
    "var_max": 10.66,
    "ra": 5.794575,
    "dec": -36.328565,
    "pmra": 769.53,
    "pmdec": -123.16,
    "rv": 56,
    "Asset Name": "ASTRID 000948",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Neko Ninja",
    "Hair": "Innocent",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": -0.00005379,
    "y_1": 0.00004378,
    "z_1": -0.00004116,
    "Distance in Parsecs_1": 15.0286,
    "HYG Star ID_1": 27292,
    "Hipparcos Catalogue_1": 27359,
    "Gliese Catalog_1": "Gl 218"
  },
  {
    "Astrid #": "ASTRID 000949",
    "HYG Star ID": 104861,
    "Hipparcos Catalogue": 105199,
    "Henry Draper Catalogue": 203280,
    "Harvard Revised Catalogue": 8162,
    "Gliese Catalog": "Gl 826",
    "Proper Name": "Alderamin",
    "Distance in Parsecs": 15.0376,
    "mag": 2.45,
    "Absolute Visual Magnitude": 1.564,
    "Spectral Type": "A7IV-V",
    "Color Index": 0.257,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 5.276111,
    "y": -4.483201,
    "z": 13.348778,
    "vx": 5.7e-7,
    "vy": 0.00001386,
    "vz": -0.00000882,
    "rarad": 5.57884815236164,
    "decrad": 1.09232430467117,
    "pmrarad": 7.26784188520833e-7,
    "pmdecrad": 2.34019563e-7,
    "bayer": "Alp",
    "flam": 5,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 104861,
    "lum": 20.6252869784657,
    "bf": "5Alp Cep",
    "ra": 21.30963,
    "dec": 62.585573,
    "pmra": 149.91,
    "pmdec": 48.27,
    "rv": -11.5,
    "Asset Name": "ASTRID 000949",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Bad Dreams",
    "Hair": "Skater Helmet",
    "Outfit": "Skater",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "Alderamin",
    "Constellation": "Cepheus",
    "x_1": 5.7e-7,
    "y_1": 0.00001386,
    "z_1": -0.00000882,
    "Distance in Parsecs_1": 15.0376,
    "HYG Star ID_1": 104861,
    "Hipparcos Catalogue_1": 105199,
    "Henry Draper Catalogue_1": 203280,
    "Harvard Revised Catalogue_1": 8162,
    "Gliese Catalog_1": "Gl 826"
  },
  {
    "Astrid #": "ASTRID 000950",
    "HYG Star ID": 119096,
    "Gliese Catalog": "NN 3965",
    "Distance in Parsecs": 15.0376,
    "mag": 14.82,
    "Absolute Visual Magnitude": 13.934,
    "Spectral Type": "DA8",
    "Color Index": 0.42,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.966286,
    "y": -10.201213,
    "z": 10.311802,
    "vx": 0.00000901,
    "vy": -0.00001861,
    "vz": -0.00001495,
    "rarad": 4.34155148890072,
    "decrad": 0.75561243708425,
    "pmrarad": 0.00000100695801451388,
    "pmdecrad": -0.000001365778315,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119096,
    "lum": 0.000232487710150164,
    "ra": 16.583505,
    "dec": 43.293404,
    "pmra": 207.7,
    "pmdec": -281.71,
    "rv": 0,
    "Asset Name": "ASTRID 000950",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Hachimaki",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000901,
    "y_1": -0.00001861,
    "z_1": -0.00001495,
    "Distance in Parsecs_1": 15.0376,
    "HYG Star ID_1": 119096,
    "Gliese Catalog_1": "NN 3965"
  },
  {
    "Astrid #": "ASTRID 000951",
    "HYG Star ID": 48941,
    "Hipparcos Catalogue": 49081,
    "Henry Draper Catalogue": 86728,
    "Harvard Revised Catalogue": 3951,
    "Gliese Catalog": "Gl 376",
    "Distance in Parsecs": 15.0466,
    "mag": 5.37,
    "Absolute Visual Magnitude": 4.483,
    "Spectral Type": "G1V",
    "Color Index": 0.676,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.088308,
    "y": 6.336788,
    "z": 7.956742,
    "vx": -0.00003727,
    "vy": 0.00006576,
    "vz": 0.00000362,
    "rarad": 2.62240894485409,
    "decrad": 0.55717318527595,
    "pmrarad": -0.00000256573096023611,
    "pmdecrad": -0.00000207951132,
    "flam": 20,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 48941,
    "lum": 1.40216783370575,
    "bf": "20    LMi",
    "ra": 10.016864,
    "dec": 31.923672,
    "pmra": -529.22,
    "pmdec": -428.93,
    "rv": 55.8,
    "Asset Name": "ASTRID 000951",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Angelic",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": -0.00003727,
    "y_1": 0.00006576,
    "z_1": 0.00000362,
    "Distance in Parsecs_1": 15.0466,
    "HYG Star ID_1": 48941,
    "Hipparcos Catalogue_1": 49081,
    "Henry Draper Catalogue_1": 86728,
    "Harvard Revised Catalogue_1": 3951,
    "Gliese Catalog_1": "Gl 376"
  },
  {
    "Astrid #": "ASTRID 000952",
    "HYG Star ID": 103757,
    "Hipparcos Catalogue": 104092,
    "Henry Draper Catalogue": 200779,
    "Gliese Catalog": "Gl 818",
    "Distance in Parsecs": 15.058,
    "mag": 8.27,
    "Absolute Visual Magnitude": 7.381,
    "Spectral Type": "K5",
    "Color Index": 1.119,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.809072,
    "y": -10.317732,
    "z": 1.853111,
    "vx": -0.00004066,
    "vy": 0.00004665,
    "vz": -0.00004909,
    "rarad": 5.52103965270279,
    "decrad": 0.123382532928106,
    "pmrarad": 3.76651748423611e-7,
    "pmdecrad": -0.000002731779645,
    "con": "Equ",
    "Full Constellation Name Formula 1": "Equuleus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Equuleus",
    "comp": 1,
    "comp_primary": 103757,
    "lum": 0.0971851702940168,
    "ra": 21.088818,
    "dec": 7.069298,
    "pmra": 77.69,
    "pmdec": -563.47,
    "rv": -65.7,
    "Asset Name": "ASTRID 000952",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Neko Ninja",
    "Hair": "Innocent",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Equuleus",
    "x_1": -0.00004066,
    "y_1": 0.00004665,
    "z_1": -0.00004909,
    "Distance in Parsecs_1": 15.058,
    "HYG Star ID_1": 103757,
    "Hipparcos Catalogue_1": 104092,
    "Henry Draper Catalogue_1": 200779,
    "Gliese Catalog_1": "Gl 818"
  },
  {
    "Astrid #": "ASTRID 000953",
    "HYG Star ID": 119349,
    "Gliese Catalog": "Gl 792",
    "Distance in Parsecs": 15.0602,
    "mag": 13.48,
    "Absolute Visual Magnitude": 12.591,
    "Spectral Type": "M4",
    "Color Index": 1.76,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.22761,
    "y": -9.297493,
    "z": 9.387646,
    "vx": -0.00000745,
    "vy": 0.00003503,
    "vz": 0.00004045,
    "rarad": 5.37316475797499,
    "decrad": 0.673008589884149,
    "pmrarad": 0.00000103691949997222,
    "pmdecrad": 0.000003434497683,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119349,
    "lum": 0.000800940030533287,
    "ra": 20.523978,
    "dec": 38.560552,
    "pmra": 213.88,
    "pmdec": 708.42,
    "rv": 0,
    "Asset Name": "ASTRID 000953",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Windy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000745,
    "y_1": 0.00003503,
    "z_1": 0.00004045,
    "Distance in Parsecs_1": 15.0602,
    "HYG Star ID_1": 119349,
    "Gliese Catalog_1": "Gl 792"
  },
  {
    "Astrid #": "ASTRID 000954",
    "HYG Star ID": 49050,
    "Hipparcos Catalogue": 49189,
    "Gliese Catalog": "Gl 378",
    "Distance in Parsecs": 15.0966,
    "mag": 10.03,
    "Absolute Visual Magnitude": 9.136,
    "Spectral Type": "M2",
    "Color Index": 1.359,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.784629,
    "y": 4.951779,
    "z": 11.234539,
    "vx": -0.00004116,
    "vy": 0.00007674,
    "vz": -0.00007946,
    "rarad": 2.62831552512269,
    "decrad": 0.839307990304117,
    "pmrarad": -0.00000308981454891666,
    "pmdecrad": -0.000007141450958,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 49050,
    "lum": 0.0193018972763457,
    "var_min": 10.086,
    "var_max": 9.966,
    "ra": 10.039426,
    "dec": 48.088806,
    "pmra": -637.32,
    "pmdec": -1473.03,
    "rv": -9.8,
    "Asset Name": "ASTRID 000954",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Afro",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00004116,
    "y_1": 0.00007674,
    "z_1": -0.00007946,
    "Distance in Parsecs_1": 15.0966,
    "HYG Star ID_1": 49050,
    "Hipparcos Catalogue_1": 49189,
    "Gliese Catalog_1": "Gl 378"
  },
  {
    "Astrid #": "ASTRID 000955",
    "HYG Star ID": 77113,
    "Hipparcos Catalogue": 77349,
    "Gliese Catalog": "NN 3916",
    "Distance in Parsecs": 15.1035,
    "mag": 11.27,
    "Absolute Visual Magnitude": 10.375,
    "Spectral Type": "K:...",
    "Color Index": 1.565,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.109596,
    "y": -12.417672,
    "z": -2.855097,
    "vx": -0.0000173,
    "vy": 0.00001718,
    "vz": -0.0000256,
    "rarad": 4.13385959459921,
    "decrad": -0.190179826491412,
    "pmrarad": -0.00000158107437502777,
    "pmdecrad": -0.00000172622759,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 77113,
    "lum": 0.00616595001861481,
    "var_min": 11.34,
    "var_max": 11.19,
    "ra": 15.790181,
    "dec": -10.896501,
    "pmra": -326.12,
    "pmdec": -356.06,
    "rv": 0,
    "Asset Name": "ASTRID 000955",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Prince",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.0000173,
    "y_1": 0.00001718,
    "z_1": -0.0000256,
    "Distance in Parsecs_1": 15.1035,
    "HYG Star ID_1": 77113,
    "Hipparcos Catalogue_1": 77349,
    "Gliese Catalog_1": "NN 3916"
  },
  {
    "Astrid #": "ASTRID 000956",
    "HYG Star ID": 119382,
    "Gliese Catalog": "Gl 815B",
    "Distance in Parsecs": 15.1035,
    "mag": 11.9,
    "Absolute Visual Magnitude": 11.005,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.176081,
    "y": -8.169572,
    "z": 9.722424,
    "vx": 0.00004022,
    "vy": 0.00002192,
    "vz": -0.00001539,
    "rarad": 5.49814078834485,
    "decrad": 0.6993492929327,
    "pmrarad": 0.00000290936689701388,
    "pmdecrad": -0.000001332001346,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 103322,
    "base": "Gl 815",
    "lum": 0.00345143739335855,
    "ra": 21.001351,
    "dec": 40.069763,
    "pmra": 600.1,
    "pmdec": -274.75,
    "rv": 0,
    "Asset Name": "ASTRID 000956",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Halo Boy",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00004022,
    "y_1": 0.00002192,
    "z_1": -0.00001539,
    "Distance in Parsecs_1": 15.1035,
    "HYG Star ID_1": 119382,
    "Gliese Catalog_1": "Gl 815B"
  },
  {
    "Astrid #": "ASTRID 000957",
    "HYG Star ID": 5849,
    "Hipparcos Catalogue": 5862,
    "Henry Draper Catalogue": 7570,
    "Harvard Revised Catalogue": 370,
    "Gliese Catalog": "Gl 55",
    "Distance in Parsecs": 15.1149,
    "mag": 4.97,
    "Absolute Visual Magnitude": 4.073,
    "Spectral Type": "F8V",
    "Color Index": 0.571,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.023612,
    "y": 3.411601,
    "z": -10.786636,
    "vx": 0.000001,
    "vy": 0.00005177,
    "vz": 6.8e-7,
    "rarad": 0.328045925612802,
    "decrad": -0.794677453936388,
    "pmrarad": 0.0000032214414631875,
    "pmdecrad": 8.63550127e-7,
    "bayer": "Nu",
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 5849,
    "lum": 2.04550243110935,
    "bf": "Nu  Phe",
    "ra": 1.253043,
    "dec": -45.531664,
    "pmra": 664.47,
    "pmdec": 178.12,
    "rv": 11.6,
    "Asset Name": "ASTRID 000957",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Fedora",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": 0.000001,
    "y_1": 0.00005177,
    "z_1": 6.8e-7,
    "Distance in Parsecs_1": 15.1149,
    "HYG Star ID_1": 5849,
    "Hipparcos Catalogue_1": 5862,
    "Henry Draper Catalogue_1": 7570,
    "Harvard Revised Catalogue_1": 370,
    "Gliese Catalog_1": "Gl 55"
  },
  {
    "Astrid #": "ASTRID 000958",
    "HYG Star ID": 118035,
    "Gliese Catalog": "NN 3069",
    "Distance in Parsecs": 15.1515,
    "mag": 15.12,
    "Absolute Visual Magnitude": 14.218,
    "Spectral Type": "m",
    "Color Index": 1.84,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.544805,
    "y": 2.369002,
    "z": 12.285849,
    "vx": 0.00001447,
    "vy": -0.0000232,
    "vz": -0.00000559,
    "rarad": 0.270513189365473,
    "decrad": 0.9456317621102,
    "pmrarad": -0.00000173117269052777,
    "pmdecrad": -6.30097796e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118035,
    "lum": 0.000178978143940895,
    "ra": 1.033284,
    "dec": 54.180709,
    "pmra": -357.08,
    "pmdec": -129.97,
    "rv": 0,
    "Asset Name": "ASTRID 000958",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Braided Ponytail",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001447,
    "y_1": -0.0000232,
    "z_1": -0.00000559,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 118035,
    "Gliese Catalog_1": "NN 3069"
  },
  {
    "Astrid #": "ASTRID 000959",
    "HYG Star ID": 118165,
    "Gliese Catalog": "GJ 1050",
    "Distance in Parsecs": 15.1515,
    "mag": 11.75,
    "Absolute Visual Magnitude": 10.848,
    "Spectral Type": "m",
    "Color Index": 1.52,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.610413,
    "y": 8.053202,
    "z": -8.499639,
    "vx": -0.00012312,
    "vy": -0.00005139,
    "vz": -0.00005859,
    "rarad": 0.697439016662228,
    "decrad": -0.5957275400366,
    "pmrarad": 0.00000261833324457638,
    "pmdecrad": -0.000007917060732,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 118165,
    "lum": 0.00398841186909052,
    "ra": 2.664021,
    "dec": -34.132674,
    "pmra": 540.07,
    "pmdec": -1633.01,
    "rv": -71,
    "Asset Name": "ASTRID 000959",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Halo Boy",
    "Outfit": "Force Sensitive",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00012312,
    "y_1": -0.00005139,
    "z_1": -0.00005859,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 118165,
    "Gliese Catalog_1": "GJ 1050"
  },
  {
    "Astrid #": "ASTRID 000960",
    "HYG Star ID": 118208,
    "Gliese Catalog": "NN 3213",
    "Distance in Parsecs": 15.1515,
    "mag": 12.39,
    "Absolute Visual Magnitude": 11.488,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.941399,
    "y": 8.068685,
    "z": 10.783378,
    "vx": 0.00001628,
    "vy": -0.00000936,
    "vz": -0.00000347,
    "rarad": 0.860385117946132,
    "decrad": 0.791920528958949,
    "pmrarad": -0.00000121756107734722,
    "pmdecrad": -3.26240821e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118208,
    "lum": 0.00221207577646412,
    "ra": 3.286429,
    "dec": 45.373704,
    "pmra": -251.14,
    "pmdec": -67.29,
    "rv": 0,
    "Asset Name": "ASTRID 000960",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Arrogant",
    "Hair": "Manbun",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001628,
    "y_1": -0.00000936,
    "z_1": -0.00000347,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 118208,
    "Gliese Catalog_1": "NN 3213"
  },
  {
    "Astrid #": "ASTRID 000961",
    "HYG Star ID": 118233,
    "Gliese Catalog": "NN 3235",
    "Distance in Parsecs": 15.1515,
    "mag": 13.1,
    "Absolute Visual Magnitude": 12.198,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.94552,
    "y": 12.161827,
    "z": -1.278908,
    "vx": -0.00002566,
    "vy": 0.00001646,
    "vz": -0.00002301,
    "rarad": 0.936613161489174,
    "decrad": -0.0845085245289,
    "pmrarad": 0.00000200804978349305,
    "pmdecrad": -0.000001524210578,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118233,
    "lum": 0.00115027054779011,
    "ra": 3.577599,
    "dec": -4.841982,
    "pmra": 414.19,
    "pmdec": -314.39,
    "rv": 0,
    "Asset Name": "ASTRID 000961",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Guilty Grin",
    "Hair": "Frizzy",
    "Outfit": "River Spirit",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002566,
    "y_1": 0.00001646,
    "z_1": -0.00002301,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 118233,
    "Gliese Catalog_1": "NN 3235"
  },
  {
    "Astrid #": "ASTRID 000962",
    "HYG Star ID": 118248,
    "Gliese Catalog": "NN 3247",
    "Distance in Parsecs": 15.1515,
    "mag": 14.7,
    "Absolute Visual Magnitude": 13.798,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.388446,
    "y": 12.363855,
    "z": -2.517345,
    "vx": -0.00002366,
    "vy": 0.00002051,
    "vz": 0.00002187,
    "rarad": 0.974668427511763,
    "decrad": -0.1669189246185,
    "pmrarad": 0.00000205241023526388,
    "pmdecrad": 0.00000146396763,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118248,
    "lum": 0.000263511758986384,
    "ra": 3.722959,
    "dec": -9.56375,
    "pmra": 423.34,
    "pmdec": 301.96,
    "rv": 0,
    "Asset Name": "ASTRID 000962",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Serious Slumber",
    "Hair": "Elegant",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002366,
    "y_1": 0.00002051,
    "z_1": 0.00002187,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 118248,
    "Gliese Catalog_1": "NN 3247"
  },
  {
    "Astrid #": "ASTRID 000963",
    "HYG Star ID": 118549,
    "Gliese Catalog": "GJ 2070",
    "Distance in Parsecs": 15.1515,
    "mag": 12.73,
    "Absolute Visual Magnitude": 11.828,
    "Spectral Type": "M3.5",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.452895,
    "y": 11.837198,
    "z": -0.302477,
    "vx": -0.00001062,
    "vy": -0.00000928,
    "vz": -0.00003151,
    "rarad": 2.24466802029671,
    "decrad": -0.01996479429145,
    "pmrarad": 9.30405934354166e-7,
    "pmdecrad": -0.000002080005829,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118549,
    "lum": 0.00161733505579985,
    "ra": 8.574,
    "dec": -1.143898,
    "pmra": 191.91,
    "pmdec": -429.03,
    "rv": 0,
    "Asset Name": "ASTRID 000963",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Braided Ponytail",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001062,
    "y_1": -0.00000928,
    "z_1": -0.00003151,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 118549,
    "Gliese Catalog_1": "GJ 2070"
  },
  {
    "Astrid #": "ASTRID 000964",
    "HYG Star ID": 118849,
    "Gliese Catalog": "NN 3753",
    "Distance in Parsecs": 15.1515,
    "mag": 12.31,
    "Absolute Visual Magnitude": 11.408,
    "Spectral Type": "DA4",
    "Color Index": 0.03,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -8.462794,
    "y": -1.879616,
    "z": 12.426427,
    "vx": -7.4e-7,
    "vy": 0.00000284,
    "vz": -7e-8,
    "rarad": 3.36015569142266,
    "decrad": 0.9616644952425,
    "pmrarad": -1.9373154675e-7,
    "pmdecrad": -8.45515e-9,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118849,
    "lum": 0.00238122262327683,
    "ra": 12.834849,
    "dec": 55.099317,
    "pmra": -39.96,
    "pmdec": -1.74,
    "rv": 0,
    "Asset Name": "ASTRID 000964",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Sweet Nap",
    "Hair": "Charmer",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -7.4e-7,
    "y_1": 0.00000284,
    "z_1": -7e-8,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 118849,
    "Gliese Catalog_1": "NN 3753"
  },
  {
    "Astrid #": "ASTRID 000965",
    "HYG Star ID": 119081,
    "Gliese Catalog": "NN 3953",
    "Distance in Parsecs": 15.1515,
    "mag": 12.24,
    "Absolute Visual Magnitude": 11.338,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.454341,
    "y": -12.474747,
    "z": 6.648218,
    "vx": -0.00001207,
    "vy": 0.00000539,
    "vz": 2.1e-7,
    "rarad": 4.30020962804095,
    "decrad": 0.45424367139985,
    "pmrarad": -8.72519180895833e-7,
    "pmdecrad": 1.5227997e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119081,
    "lum": 0.00253980281377281,
    "ra": 16.425591,
    "dec": 26.026245,
    "pmra": -179.97,
    "pmdec": 3.14,
    "rv": 0,
    "Asset Name": "ASTRID 000965",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Innocent",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001207,
    "y_1": 0.00000539,
    "z_1": 2.1e-7,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 119081,
    "Gliese Catalog_1": "NN 3953"
  },
  {
    "Astrid #": "ASTRID 000966",
    "HYG Star ID": 119434,
    "Gliese Catalog": "NN 4216",
    "Distance in Parsecs": 15.1515,
    "mag": 12.81,
    "Absolute Visual Magnitude": 11.908,
    "Spectral Type": "M3.5",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.53443,
    "y": -8.37387,
    "z": -1.527848,
    "vx": -0.00000955,
    "vy": -0.00000907,
    "vz": -0.00002871,
    "rarad": 5.69421245104363,
    "decrad": -0.1010097352246,
    "pmrarad": -8.47939127291666e-7,
    "pmdecrad": -0.000001904464492,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119434,
    "lum": 0.00150244990299891,
    "ra": 21.750289,
    "dec": -5.787432,
    "pmra": -174.9,
    "pmdec": -392.82,
    "rv": 0,
    "Asset Name": "ASTRID 000966",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Round Brush",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000955,
    "y_1": -0.00000907,
    "z_1": -0.00002871,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 119434,
    "Gliese Catalog_1": "NN 4216"
  },
  {
    "Astrid #": "ASTRID 000967",
    "HYG Star ID": 119597,
    "Gliese Catalog": "NN 4367",
    "Distance in Parsecs": 15.1515,
    "mag": 13.45,
    "Absolute Visual Magnitude": 12.548,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.928328,
    "y": -0.617677,
    "z": -2.516237,
    "vx": -0.00000321,
    "vy": 0.00004584,
    "vz": -0.00003038,
    "rarad": 6.24183065072514,
    "decrad": -0.16684480797435,
    "pmrarad": 0.00000301447450295833,
    "pmdecrad": -0.000002033279487,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119597,
    "lum": 0.000833297348634316,
    "ra": 23.842037,
    "dec": -9.559503,
    "pmra": 621.78,
    "pmdec": -419.39,
    "rv": 0,
    "Asset Name": "ASTRID 000967",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Fired Up",
    "Hair": "Innocent",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000321,
    "y_1": 0.00004584,
    "z_1": -0.00003038,
    "Distance in Parsecs_1": 15.1515,
    "HYG Star ID_1": 119597,
    "Gliese Catalog_1": "NN 4367"
  },
  {
    "Astrid #": "ASTRID 000968",
    "HYG Star ID": 115849,
    "Hipparcos Catalogue": 116215,
    "Henry Draper Catalogue": 221503,
    "Gliese Catalog": "Gl 898",
    "Distance in Parsecs": 15.1561,
    "mag": 8.59,
    "Absolute Visual Magnitude": 7.687,
    "Spectral Type": "K5/M0V",
    "Color Index": 1.29,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.403874,
    "y": -1.716071,
    "z": -4.392152,
    "vx": -0.00000113,
    "vy": 0.00002555,
    "vz": -0.00001545,
    "rarad": 6.16460405680224,
    "decrad": -0.294011897973531,
    "pmrarad": 0.00000166557739954861,
    "pmdecrad": -0.000001055196975,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 115849,
    "lum": 0.073316208902721,
    "ra": 23.547053,
    "dec": -16.845641,
    "pmra": 343.55,
    "pmdec": -217.65,
    "rv": 0.5,
    "Asset Name": "ASTRID 000968",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Braided Ponytail",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000113,
    "y_1": 0.00002555,
    "z_1": -0.00001545,
    "Distance in Parsecs_1": 15.1561,
    "HYG Star ID_1": 115849,
    "Hipparcos Catalogue_1": 116215,
    "Henry Draper Catalogue_1": 221503,
    "Gliese Catalog_1": "Gl 898"
  },
  {
    "Astrid #": "ASTRID 000969",
    "HYG Star ID": 3575,
    "Hipparcos Catalogue": 3583,
    "Henry Draper Catalogue": 4391,
    "Harvard Revised Catalogue": 209,
    "Gliese Catalog": "GJ 1021",
    "Distance in Parsecs": 15.1584,
    "mag": 5.8,
    "Absolute Visual Magnitude": 4.897,
    "Spectral Type": "G5IV",
    "Color Index": 0.635,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.027401,
    "y": 2.02916,
    "z": -11.185157,
    "vx": -0.00000623,
    "vy": 0.00001253,
    "vz": 0.00001249,
    "rarad": 0.199661395803409,
    "decrad": -0.829938707875,
    "pmrarad": 8.9186324675e-7,
    "pmdecrad": 3.799e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 3575,
    "lum": 0.957634976969268,
    "ra": 0.76265,
    "dec": -47.551985,
    "pmra": 183.96,
    "pmdec": 78.36,
    "rv": -11.4,
    "Asset Name": "ASTRID 000969",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Good Boy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000623,
    "y_1": 0.00001253,
    "z_1": 0.00001249,
    "Distance in Parsecs_1": 15.1584,
    "HYG Star ID_1": 3575,
    "Hipparcos Catalogue_1": 3583,
    "Henry Draper Catalogue_1": 4391,
    "Harvard Revised Catalogue_1": 209,
    "Gliese Catalog_1": "GJ 1021"
  },
  {
    "Astrid #": "ASTRID 000970",
    "HYG Star ID": 25583,
    "Hipparcos Catalogue": 25647,
    "Henry Draper Catalogue": 36705,
    "Distance in Parsecs": 15.1676,
    "mag": 6.88,
    "Absolute Visual Magnitude": 5.975,
    "Spectral Type": "K1III(p)",
    "Color Index": 0.83,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.856775,
    "y": 6.243901,
    "z": -13.796544,
    "vx": 9.3e-7,
    "vy": 0.00002413,
    "vz": -0.0000259,
    "rarad": 1.43442804941727,
    "decrad": -1.14229304267188,
    "pmrarad": 1.55819116930555e-7,
    "pmdecrad": 7.31923213e-7,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 25583,
    "lum": 0.354813389233575,
    "var": "AB",
    "var_min": 6.954,
    "var_max": 6.824,
    "ra": 5.479112,
    "dec": -65.44857,
    "pmra": 32.14,
    "pmdec": 150.97,
    "rv": 32.8,
    "Asset Name": "ASTRID 000970",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Headband",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 9.3e-7,
    "y_1": 0.00002413,
    "z_1": -0.0000259,
    "Distance in Parsecs_1": 15.1676,
    "HYG Star ID_1": 25583,
    "Hipparcos Catalogue_1": 25647,
    "Henry Draper Catalogue_1": 36705
  },
  {
    "Astrid #": "ASTRID 000971",
    "HYG Star ID": 118380,
    "Gliese Catalog": "GJ 1081",
    "Distance in Parsecs": 15.1745,
    "mag": 12.21,
    "Absolute Visual Magnitude": 11.304,
    "Spectral Type": "k-m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.251467,
    "y": 10.690931,
    "z": 10.695947,
    "vx": -0.00000151,
    "vy": 0.00001879,
    "vz": -0.0000186,
    "rarad": 1.45426287458269,
    "decrad": 0.7822302509311,
    "pmrarad": 2.42891653958333e-7,
    "pmdecrad": -0.000001728341378,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118380,
    "lum": 0.00262059555473334,
    "ra": 5.554875,
    "dec": 44.818492,
    "pmra": 50.1,
    "pmdec": -356.5,
    "rv": 0,
    "Asset Name": "ASTRID 000971",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Unkempt",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000151,
    "y_1": 0.00001879,
    "z_1": -0.0000186,
    "Distance in Parsecs_1": 15.1745,
    "HYG Star ID_1": 118380,
    "Gliese Catalog_1": "GJ 1081"
  },
  {
    "Astrid #": "ASTRID 000972",
    "HYG Star ID": 27367,
    "Hipparcos Catalogue": 27435,
    "Henry Draper Catalogue": 38858,
    "Harvard Revised Catalogue": 2007,
    "Gliese Catalog": "GJ 1085",
    "Distance in Parsecs": 15.1768,
    "mag": 5.97,
    "Absolute Visual Magnitude": 5.064,
    "Spectral Type": "G4V",
    "Color Index": 0.639,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.753864,
    "y": 15.119563,
    "z": -1.083709,
    "vx": -0.00000302,
    "vy": 0.00003152,
    "vz": -0.00001926,
    "rarad": 1.52097740365848,
    "decrad": -0.0714650864490974,
    "pmrarad": 3.02475255298611e-7,
    "pmdecrad": -0.000001118222754,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 27367,
    "lum": 0.821107464085087,
    "ra": 5.809706,
    "dec": -4.094648,
    "pmra": 62.39,
    "pmdec": -230.65,
    "rv": 31.9,
    "Asset Name": "ASTRID 000972",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Battle Ready",
    "Hair": "Angelic",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000302,
    "y_1": 0.00003152,
    "z_1": -0.00001926,
    "Distance in Parsecs_1": 15.1768,
    "HYG Star ID_1": 27367,
    "Hipparcos Catalogue_1": 27435,
    "Henry Draper Catalogue_1": 38858,
    "Harvard Revised Catalogue_1": 2007,
    "Gliese Catalog_1": "GJ 1085"
  },
  {
    "Astrid #": "ASTRID 000973",
    "HYG Star ID": 95152,
    "Hipparcos Catalogue": 95447,
    "Henry Draper Catalogue": 182572,
    "Harvard Revised Catalogue": 7373,
    "Gliese Catalog": "Gl 759",
    "Distance in Parsecs": 15.1768,
    "mag": 5.17,
    "Absolute Visual Magnitude": 4.264,
    "Spectral Type": "G8IVvar",
    "Color Index": 0.761,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.379424,
    "y": -13.838527,
    "z": 3.140845,
    "vx": 0.00000944,
    "vy": 0.00012218,
    "vz": 0.00002504,
    "rarad": 5.08313988713679,
    "decrad": 0.20846937728242,
    "pmrarad": 0.00000349681563374305,
    "pmdecrad": 0.000003120260848,
    "flam": 31,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 95152,
    "lum": 1.71553664772834,
    "bf": "31    Aql",
    "ra": 19.416164,
    "dec": 11.944415,
    "pmra": 721.27,
    "pmdec": 643.6,
    "rv": -100.6,
    "Asset Name": "ASTRID 000973",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Nervous Grin",
    "Hair": "Horns",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000944,
    "y_1": 0.00012218,
    "z_1": 0.00002504,
    "Distance in Parsecs_1": 15.1768,
    "HYG Star ID_1": 95152,
    "Hipparcos Catalogue_1": 95447,
    "Henry Draper Catalogue_1": 182572,
    "Harvard Revised Catalogue_1": 7373,
    "Gliese Catalog_1": "Gl 759"
  },
  {
    "Astrid #": "ASTRID 000974",
    "HYG Star ID": 1391,
    "Hipparcos Catalogue": 1392,
    "Henry Draper Catalogue": 1317,
    "Distance in Parsecs": 15.186,
    "mag": 6.66,
    "Absolute Visual Magnitude": 5.753,
    "Spectral Type": "F5",
    "Color Index": 0.589,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.961169,
    "y": 1.138666,
    "z": 2.343333,
    "vx": 0.0000349,
    "vy": 0.00000822,
    "vz": 0.00001116,
    "rarad": 0.0759616241645616,
    "decrad": 0.154924455073005,
    "pmrarad": 3.65161664194444e-7,
    "pmdecrad": 3.66179772e-7,
    "flam": 38,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 1391,
    "lum": 0.435311359229637,
    "ra": 0.290152,
    "dec": 8.876517,
    "pmra": 75.32,
    "pmdec": 75.53,
    "rv": 35.9,
    "Asset Name": "ASTRID 000974",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Headband",
    "Outfit": "Wizard",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.0000349,
    "y_1": 0.00000822,
    "z_1": 0.00001116,
    "Distance in Parsecs_1": 15.186,
    "HYG Star ID_1": 1391,
    "Hipparcos Catalogue_1": 1392,
    "Henry Draper Catalogue_1": 1317
  },
  {
    "Astrid #": "ASTRID 000975",
    "HYG Star ID": 118026,
    "Gliese Catalog": "Gl 40B",
    "Distance in Parsecs": 15.1953,
    "mag": 17.3,
    "Absolute Visual Magnitude": 16.391,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.643442,
    "y": 3.124548,
    "z": -5.915306,
    "vx": -0.00001756,
    "vy": 0.00004241,
    "vz": -0.00001813,
    "rarad": 0.225119589034871,
    "decrad": -0.39985550665385,
    "pmrarad": 0.00000297884069743749,
    "pmdecrad": -0.000001295233077,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 4012,
    "base": "Gl  40",
    "lum": 0.0000241880022314784,
    "ra": 0.859893,
    "dec": -22.910033,
    "pmra": 614.43,
    "pmdec": -267.16,
    "rv": 0,
    "Asset Name": "ASTRID 000975",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Grecian",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001756,
    "y_1": 0.00004241,
    "z_1": -0.00001813,
    "Distance in Parsecs_1": 15.1953,
    "HYG Star ID_1": 118026,
    "Gliese Catalog_1": "Gl 40B"
  },
  {
    "Astrid #": "ASTRID 000976",
    "HYG Star ID": 118498,
    "Gliese Catalog": "Gl 288B",
    "Distance in Parsecs": 15.1999,
    "mag": 16.59,
    "Absolute Visual Magnitude": 15.681,
    "Spectral Type": "m",
    "Color Index": 1.2,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.614055,
    "y": 11.294903,
    "z": -8.482014,
    "vx": -0.00000684,
    "vy": 0.00006985,
    "vz": 0.00009746,
    "rarad": 2.03209253232183,
    "decrad": -0.592010857382,
    "pmrarad": -0.00000164303356340277,
    "pmdecrad": 0.00000772977236,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 37745,
    "base": "Gl 288",
    "lum": 0.0000465157470302165,
    "ra": 7.762022,
    "dec": -33.919724,
    "pmra": -338.9,
    "pmdec": 1594.38,
    "rv": 0,
    "Asset Name": "ASTRID 000976",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Sweet Nap",
    "Hair": "Bucket Hat",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000684,
    "y_1": 0.00006985,
    "z_1": 0.00009746,
    "Distance in Parsecs_1": 15.1999,
    "HYG Star ID_1": 118498,
    "Gliese Catalog_1": "Gl 288B"
  },
  {
    "Astrid #": "ASTRID 000977",
    "HYG Star ID": 37745,
    "Hipparcos Catalogue": 37853,
    "Henry Draper Catalogue": 63077,
    "Harvard Revised Catalogue": 3018,
    "Gliese Catalog": "Gl 288A",
    "Distance in Parsecs": 15.2091,
    "mag": 5.36,
    "Absolute Visual Magnitude": 4.449,
    "Spectral Type": "G0V",
    "Color Index": 0.589,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.594502,
    "y": 11.272064,
    "z": -8.543229,
    "vx": -0.00005573,
    "vy": 0.00014892,
    "vz": 0.00004615,
    "rarad": 2.03149409412705,
    "decrad": -0.596420251943825,
    "pmrarad": -0.00000107061405077083,
    "pmdecrad": 0.00000835280642,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 37745,
    "base": "Gl 288",
    "lum": 1.44677168324772,
    "ra": 7.759736,
    "dec": -34.172363,
    "pmra": -220.83,
    "pmdec": 1722.89,
    "rv": 102.6,
    "Asset Name": "ASTRID 000977",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Round Brush",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005573,
    "y_1": 0.00014892,
    "z_1": 0.00004615,
    "Distance in Parsecs_1": 15.2091,
    "HYG Star ID_1": 37745,
    "Hipparcos Catalogue_1": 37853,
    "Henry Draper Catalogue_1": 63077,
    "Harvard Revised Catalogue_1": 3018,
    "Gliese Catalog_1": "Gl 288A"
  },
  {
    "Astrid #": "ASTRID 000978",
    "HYG Star ID": 119041,
    "Gliese Catalog": "Gl 599B",
    "Distance in Parsecs": 15.2439,
    "mag": 12.78,
    "Absolute Visual Magnitude": 11.865,
    "Spectral Type": "DA7",
    "Color Index": 0.33,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -6.572508,
    "y": -10.070783,
    "z": -9.368004,
    "vx": -0.00002018,
    "vy": 0.00002395,
    "vz": -0.00001326,
    "rarad": 4.13419253815235,
    "decrad": -0.661801615078,
    "pmrarad": -0.00000196727695295833,
    "pmdecrad": -0.00000105042156,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 77121,
    "base": "Gl 599",
    "lum": 0.00156314764264095,
    "ra": 15.791452,
    "dec": -37.918439,
    "pmra": -405.78,
    "pmdec": -216.66,
    "rv": 1,
    "Asset Name": "ASTRID 000978",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Ponytail",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002018,
    "y_1": 0.00002395,
    "z_1": -0.00001326,
    "Distance in Parsecs_1": 15.2439,
    "HYG Star ID_1": 119041,
    "Gliese Catalog_1": "Gl 599B"
  },
  {
    "Astrid #": "ASTRID 000979",
    "HYG Star ID": 82607,
    "Hipparcos Catalogue": 82860,
    "Henry Draper Catalogue": 153597,
    "Harvard Revised Catalogue": 6315,
    "Gliese Catalog": "Gl 648",
    "Distance in Parsecs": 15.2579,
    "mag": 4.88,
    "Absolute Visual Magnitude": 3.963,
    "Spectral Type": "F6Vvar",
    "Color Index": 0.481,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.767632,
    "y": -6.167324,
    "z": 13.843316,
    "vx": 0.00002034,
    "vy": 0.00000707,
    "vz": -0.00001781,
    "rarad": 4.43324526036595,
    "decrad": 1.13681663969365,
    "pmrarad": 0.0000011540989665625,
    "pmdecrad": 2.46479275e-7,
    "flam": 19,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 82607,
    "lum": 2.26360164044974,
    "bf": "19    Dra",
    "ra": 16.93375,
    "dec": 65.134796,
    "pmra": 238.05,
    "pmdec": 50.84,
    "rv": -20.9,
    "Asset Name": "ASTRID 000979",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Bad Dreams",
    "Hair": "Heartbreaker",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00002034,
    "y_1": 0.00000707,
    "z_1": -0.00001781,
    "Distance in Parsecs_1": 15.2579,
    "HYG Star ID_1": 82607,
    "Hipparcos Catalogue_1": 82860,
    "Henry Draper Catalogue_1": 153597,
    "Harvard Revised Catalogue_1": 6315,
    "Gliese Catalog_1": "Gl 648"
  },
  {
    "Astrid #": "ASTRID 000980",
    "HYG Star ID": 117973,
    "Gliese Catalog": "GJ 1006B",
    "Distance in Parsecs": 15.2672,
    "mag": 13.22,
    "Absolute Visual Magnitude": 12.301,
    "Spectral Type": "M4.5",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.322712,
    "y": 1.017798,
    "z": 5.187622,
    "vx": 0.00001542,
    "vy": 0.00005272,
    "vz": -0.00005286,
    "rarad": 0.0709318972238757,
    "decrad": 0.34669223845755,
    "pmrarad": 0.00000337362447751388,
    "pmdecrad": -0.000003681650849,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 1294,
    "base": "GJ 1006",
    "lum": 0.001046164551022,
    "ra": 0.27094,
    "dec": 19.864002,
    "pmra": 695.86,
    "pmdec": -759.39,
    "rv": 0,
    "Asset Name": "ASTRID 000980",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Audiohead",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001542,
    "y_1": 0.00005272,
    "z_1": -0.00005286,
    "Distance in Parsecs_1": 15.2672,
    "HYG Star ID_1": 117973,
    "Gliese Catalog_1": "GJ 1006B"
  },
  {
    "Astrid #": "ASTRID 000981",
    "HYG Star ID": 28882,
    "Hipparcos Catalogue": 28954,
    "Henry Draper Catalogue": 41593,
    "Gliese Catalog": "Gl 227",
    "Distance in Parsecs": 15.2718,
    "mag": 6.76,
    "Absolute Visual Magnitude": 5.841,
    "Spectral Type": "K0",
    "Color Index": 0.814,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.428441,
    "y": 14.707028,
    "z": 4.091997,
    "vx": 0.00000933,
    "vy": -0.00000921,
    "vz": -0.00001057,
    "rarad": 1.5999200408876,
    "decrad": 0.271260919314562,
    "pmrarad": -5.92927131319444e-7,
    "pmdecrad": -5.0076405e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 28882,
    "lum": 0.40142091820432,
    "ra": 6.111244,
    "dec": 15.542106,
    "pmra": -122.3,
    "pmdec": -103.29,
    "rv": -11.7,
    "Asset Name": "ASTRID 000981",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Good Boy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000933,
    "y_1": -0.00000921,
    "z_1": -0.00001057,
    "Distance in Parsecs_1": 15.2718,
    "HYG Star ID_1": 28882,
    "Hipparcos Catalogue_1": 28954,
    "Henry Draper Catalogue_1": 41593,
    "Gliese Catalog_1": "Gl 227"
  },
  {
    "Astrid #": "ASTRID 000982",
    "HYG Star ID": 103322,
    "Hipparcos Catalogue": 103655,
    "Gliese Catalog": "Gl 815A",
    "Distance in Parsecs": 15.2905,
    "mag": 10.12,
    "Absolute Visual Magnitude": 9.198,
    "Spectral Type": "M3Ve",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.277007,
    "y": -8.270574,
    "z": 9.84271,
    "vx": 0.00002188,
    "vy": 0.00004252,
    "vz": -0.00003621,
    "rarad": 5.49816794831083,
    "decrad": 0.699358289176945,
    "pmrarad": 0.00000297854980922916,
    "pmdecrad": -0.000001198265492,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 103322,
    "base": "Gl 815",
    "lum": 0.0182305596068106,
    "var": "V1396",
    "var_min": 10.197,
    "var_max": 10.057,
    "ra": 21.001455,
    "dec": 40.070278,
    "pmra": 614.37,
    "pmdec": -247.16,
    "rv": -33.7,
    "Asset Name": "ASTRID 000982",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Sweetheart",
    "Outfit": "Emperor",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00002188,
    "y_1": 0.00004252,
    "z_1": -0.00003621,
    "Distance in Parsecs_1": 15.2905,
    "HYG Star ID_1": 103322,
    "Hipparcos Catalogue_1": 103655,
    "Gliese Catalog_1": "Gl 815A"
  },
  {
    "Astrid #": "ASTRID 000983",
    "HYG Star ID": 118214,
    "Gliese Catalog": "GJ 1059",
    "Distance in Parsecs": 15.2905,
    "mag": 15.33,
    "Absolute Visual Magnitude": 14.408,
    "Spectral Type": "m",
    "Color Index": 1.89,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.186893,
    "y": 8.799344,
    "z": 10.233255,
    "vx": -0.00000641,
    "vy": 0.00004175,
    "vz": -0.00003139,
    "rarad": 0.885887618663626,
    "decrad": 0.73320665910105,
    "pmrarad": 0.00000205231327252777,
    "pmdecrad": -0.000002763437979,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118214,
    "lum": 0.000150244990299891,
    "ra": 3.383841,
    "dec": 42.009647,
    "pmra": 423.32,
    "pmdec": -570,
    "rv": 0,
    "Asset Name": "ASTRID 000983",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Bangs",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000641,
    "y_1": 0.00004175,
    "z_1": -0.00003139,
    "Distance in Parsecs_1": 15.2905,
    "HYG Star ID_1": 118214,
    "Gliese Catalog_1": "GJ 1059"
  },
  {
    "Astrid #": "ASTRID 000984",
    "HYG Star ID": 103056,
    "Hipparcos Catalogue": 103388,
    "Gliese Catalog": "Gl 811.1",
    "Distance in Parsecs": 15.3163,
    "mag": 11.38,
    "Absolute Visual Magnitude": 10.454,
    "Spectral Type": "M4",
    "Color Index": 1.563,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.500147,
    "y": -10.799731,
    "z": -2.77772,
    "vx": 0.00002404,
    "vy": -0.00002733,
    "vz": -0.00009049,
    "rarad": 5.48372305922594,
    "decrad": -0.182360802771842,
    "pmrarad": -1.18488463527777e-7,
    "pmdecrad": -0.000005380316782,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 103056,
    "lum": 0.0057332383865384,
    "var_min": 11.495,
    "var_max": 11.245,
    "ra": 20.946279,
    "dec": -10.448504,
    "pmra": -24.44,
    "pmdec": -1109.77,
    "rv": 51,
    "Asset Name": "ASTRID 000984",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "Angelic",
    "Outfit": "Garb",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00002404,
    "y_1": -0.00002733,
    "z_1": -0.00009049,
    "Distance in Parsecs_1": 15.3163,
    "HYG Star ID_1": 103056,
    "Hipparcos Catalogue_1": 103388,
    "Gliese Catalog_1": "Gl 811.1"
  },
  {
    "Astrid #": "ASTRID 000985",
    "HYG Star ID": 118804,
    "Gliese Catalog": "Gl 458B",
    "Distance in Parsecs": 15.3163,
    "mag": 13.33,
    "Absolute Visual Magnitude": 12.404,
    "Spectral Type": "m",
    "Color Index": 1.61,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.883886,
    "y": -0.479763,
    "z": 12.467375,
    "vx": 0.00000579,
    "vy": -0.00001649,
    "vz": 0.00000349,
    "rarad": 3.19550435682556,
    "decrad": 0.95099510119815,
    "pmrarad": 0.00000109543651121527,
    "pmdecrad": 3.92223963e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 59332,
    "base": "Gl 458",
    "lum": 0.000951480736351758,
    "ra": 12.205928,
    "dec": 54.488006,
    "pmra": 225.95,
    "pmdec": 80.9,
    "rv": 0,
    "Asset Name": "ASTRID 000985",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Prince",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000579,
    "y_1": -0.00001649,
    "z_1": 0.00000349,
    "Distance in Parsecs_1": 15.3163,
    "HYG Star ID_1": 118804,
    "Gliese Catalog_1": "Gl 458B"
  },
  {
    "Astrid #": "ASTRID 000986",
    "HYG Star ID": 169,
    "Hipparcos Catalogue": 169,
    "Henry Draper Catalogue": 224953,
    "Gliese Catalog": "GJ 1294A",
    "Distance in Parsecs": 15.328,
    "mag": 9.24,
    "Absolute Visual Magnitude": 8.313,
    "Spectral Type": "M0V",
    "Color Index": 1.39,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.67201,
    "y": 0.053101,
    "z": -14.239841,
    "vx": -0.00001614,
    "vy": 0.00001526,
    "vz": -0.00000637,
    "rarad": 0.00934667627732773,
    "decrad": -1.19172423923347,
    "pmrarad": 0.0000010057459803125,
    "pmdecrad": -0.000001123798111,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 169,
    "base": "GJ 1294",
    "lum": 0.0411907784867737,
    "ra": 0.035702,
    "dec": -68.280769,
    "pmra": 207.45,
    "pmdec": -231.8,
    "rv": 0,
    "Asset Name": "ASTRID 000986",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Shocked",
    "Hair": "Braided Ponytail",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00001614,
    "y_1": 0.00001526,
    "z_1": -0.00000637,
    "Distance in Parsecs_1": 15.328,
    "HYG Star ID_1": 169,
    "Hipparcos Catalogue_1": 169,
    "Henry Draper Catalogue_1": 224953,
    "Gliese Catalog_1": "GJ 1294A"
  },
  {
    "Astrid #": "ASTRID 000987",
    "HYG Star ID": 119100,
    "Gliese Catalog": "GJ 1204",
    "Distance in Parsecs": 15.3374,
    "mag": 13.8,
    "Absolute Visual Magnitude": 12.871,
    "Spectral Type": "m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.426307,
    "y": -14.15176,
    "z": 2.349199,
    "vx": -0.00003734,
    "vy": 0.00001247,
    "vz": -0.00001107,
    "rarad": 4.34624367347776,
    "decrad": 0.15377332086455,
    "pmrarad": -0.00000256447044466666,
    "pmdecrad": -7.30502709e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119100,
    "lum": 0.000618870811419029,
    "ra": 16.601428,
    "dec": 8.810562,
    "pmra": -528.96,
    "pmdec": -150.68,
    "rv": 0,
    "Asset Name": "ASTRID 000987",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Afro",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003734,
    "y_1": 0.00001247,
    "z_1": -0.00001107,
    "Distance in Parsecs_1": 15.3374,
    "HYG Star ID_1": 119100,
    "Gliese Catalog_1": "GJ 1204"
  },
  {
    "Astrid #": "ASTRID 000988",
    "HYG Star ID": 77121,
    "Hipparcos Catalogue": 77358,
    "Henry Draper Catalogue": 140901,
    "Harvard Revised Catalogue": 5864,
    "Gliese Catalog": "Gl 599A",
    "Distance in Parsecs": 15.3539,
    "mag": 6.01,
    "Absolute Visual Magnitude": 5.079,
    "Spectral Type": "G6IV",
    "Color Index": 0.715,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.619912,
    "y": -10.143795,
    "z": -9.435094,
    "vx": -0.00001834,
    "vy": 0.00002866,
    "vz": -0.00000929,
    "rarad": 4.13418797642085,
    "decrad": -0.661764485197198,
    "pmrarad": -0.00000202075190192361,
    "pmdecrad": -0.000001036822537,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 77121,
    "base": "Gl 599",
    "lum": 0.809841447325788,
    "ra": 15.791435,
    "dec": -37.916312,
    "pmra": -416.81,
    "pmdec": -213.86,
    "rv": -5.2,
    "Asset Name": "ASTRID 000988",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Medium Bob",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00001834,
    "y_1": 0.00002866,
    "z_1": -0.00000929,
    "Distance in Parsecs_1": 15.3539,
    "HYG Star ID_1": 77121,
    "Hipparcos Catalogue_1": 77358,
    "Henry Draper Catalogue_1": 140901,
    "Harvard Revised Catalogue_1": 5864,
    "Gliese Catalog_1": "Gl 599A"
  },
  {
    "Astrid #": "ASTRID 000989",
    "HYG Star ID": 31554,
    "Hipparcos Catalogue": 31634,
    "Gliese Catalog": "Gl 240",
    "Distance in Parsecs": 15.3563,
    "mag": 9.61,
    "Absolute Visual Magnitude": 8.679,
    "Spectral Type": "K0",
    "Color Index": 1.456,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.593362,
    "y": 9.73352,
    "z": -11.770307,
    "vx": -0.00001351,
    "vy": 0.00000922,
    "vz": -0.00001189,
    "rarad": 1.73305183566717,
    "decrad": -0.873332457920151,
    "pmrarad": 7.71047677555555e-7,
    "pmdecrad": 6.5837697e-8,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 31554,
    "lum": 0.029403565736725,
    "ra": 6.61977,
    "dec": -50.038264,
    "pmra": 159.04,
    "pmdec": 13.58,
    "rv": 16,
    "Asset Name": "ASTRID 000989",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Innocent",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001351,
    "y_1": 0.00000922,
    "z_1": -0.00001189,
    "Distance in Parsecs_1": 15.3563,
    "HYG Star ID_1": 31554,
    "Hipparcos Catalogue_1": 31634,
    "Gliese Catalog_1": "Gl 240"
  },
  {
    "Astrid #": "ASTRID 000990",
    "HYG Star ID": 118047,
    "Gliese Catalog": "GJ 2021",
    "Distance in Parsecs": 15.3846,
    "mag": 14.52,
    "Absolute Visual Magnitude": 13.585,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.363309,
    "y": 4.169124,
    "z": -6.381716,
    "vx": -0.00000943,
    "vy": 0.00001215,
    "vz": -0.00001181,
    "rarad": 0.302409115849678,
    "decrad": -0.427736185245199,
    "pmrarad": 9.36756993569444e-7,
    "pmdecrad": -8.43444904e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118047,
    "lum": 0.000320626932450546,
    "ra": 1.155118,
    "dec": -24.507478,
    "pmra": 193.22,
    "pmdec": -173.97,
    "rv": 0,
    "Asset Name": "ASTRID 000990",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Lion Mane",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000943,
    "y_1": 0.00001215,
    "z_1": -0.00001181,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118047,
    "Gliese Catalog_1": "GJ 2021"
  },
  {
    "Astrid #": "ASTRID 000991",
    "HYG Star ID": 118191,
    "Gliese Catalog": "NN 3198",
    "Distance in Parsecs": 15.3846,
    "mag": 12.84,
    "Absolute Visual Magnitude": 11.905,
    "Spectral Type": "M3",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.014926,
    "y": 10.37751,
    "z": -5.356721,
    "vx": -0.0000342,
    "vy": 0.00001539,
    "vz": -0.00003414,
    "rarad": 0.803168952764956,
    "decrad": -0.35563664832025,
    "pmrarad": 0.000002294235299125,
    "pmdecrad": -0.000002367466405,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118191,
    "lum": 0.00150660706618674,
    "ra": 3.067879,
    "dec": -20.376479,
    "pmra": 473.22,
    "pmdec": -488.32,
    "rv": 0,
    "Asset Name": "ASTRID 000991",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Greasy",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000342,
    "y_1": 0.00001539,
    "z_1": -0.00003414,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118191,
    "Gliese Catalog_1": "NN 3198"
  },
  {
    "Astrid #": "ASTRID 000992",
    "HYG Star ID": 118231,
    "Henry Draper Catalogue": 21809,
    "Gliese Catalog": "NN 3233",
    "Distance in Parsecs": 15.3846,
    "mag": 7.28,
    "Absolute Visual Magnitude": 6.345,
    "Spectral Type": "G5",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.275942,
    "y": 9.684042,
    "z": 9.485038,
    "vx": 0.00000517,
    "vy": -0.00000121,
    "vz": -0.00000273,
    "rarad": 0.926446835042492,
    "decrad": 0.66432526574785,
    "pmrarad": -3.15759150145833e-7,
    "pmdecrad": -2.25225043e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 118231,
    "lum": 0.252348077248057,
    "ra": 3.538766,
    "dec": 38.063034,
    "pmra": -65.13,
    "pmdec": -46.46,
    "rv": 0,
    "Asset Name": "ASTRID 000992",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Determined Shinobi",
    "Hair": "Ponytail",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00000517,
    "y_1": -0.00000121,
    "z_1": -0.00000273,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118231,
    "Henry Draper Catalogue_1": 21809,
    "Gliese Catalog_1": "NN 3233"
  },
  {
    "Astrid #": "ASTRID 000993",
    "HYG Star ID": 118295,
    "Gliese Catalog": "NN 3279",
    "Distance in Parsecs": 15.3846,
    "mag": 11.8,
    "Absolute Visual Magnitude": 10.865,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.426308,
    "y": 7.536638,
    "z": -12.96709,
    "vx": 0.00000506,
    "vy": -0.00003446,
    "vz": -0.0000187,
    "rarad": 1.14415948873416,
    "decrad": -1.0025791185612,
    "pmrarad": -0.00000122604531675694,
    "pmdecrad": -0.000002258131224,
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 118295,
    "lum": 0.00392644935399599,
    "ra": 4.370367,
    "dec": -57.443552,
    "pmra": -252.89,
    "pmdec": -465.77,
    "rv": 0,
    "Asset Name": "ASTRID 000993",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Ponytail",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": 0.00000506,
    "y_1": -0.00003446,
    "z_1": -0.0000187,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118295,
    "Gliese Catalog_1": "NN 3279"
  },
  {
    "Astrid #": "ASTRID 000994",
    "HYG Star ID": 118462,
    "Gliese Catalog": "NN 3426",
    "Distance in Parsecs": 15.3846,
    "mag": 13.4,
    "Absolute Visual Magnitude": 12.465,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.95366,
    "y": 9.71703,
    "z": 11.556001,
    "vx": -0.000003,
    "vy": 0.00001659,
    "vz": -0.00001471,
    "rarad": 1.86589217006112,
    "decrad": 0.84978848577655,
    "pmrarad": -1.26778777465277e-7,
    "pmdecrad": -0.000001448904469,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118462,
    "lum": 0.000899497581530034,
    "ra": 7.127183,
    "dec": 48.689294,
    "pmra": -26.15,
    "pmdec": -298.86,
    "rv": 0,
    "Asset Name": "ASTRID 000994",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Spiky",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.000003,
    "y_1": 0.00001659,
    "z_1": -0.00001471,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118462,
    "Gliese Catalog_1": "NN 3426"
  },
  {
    "Astrid #": "ASTRID 000995",
    "HYG Star ID": 118545,
    "Gliese Catalog": "NN 3503",
    "Distance in Parsecs": 15.3846,
    "mag": 15,
    "Absolute Visual Magnitude": 14.065,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.281054,
    "y": 11.945324,
    "z": -2.803066,
    "vx": 0.00003676,
    "vy": 0.00002313,
    "vz": -0.00002317,
    "rarad": 2.23133293350826,
    "decrad": -0.18322296804745,
    "pmrarad": -0.00000280934983471527,
    "pmdecrad": -0.000001531700949,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118545,
    "lum": 0.000206062991327,
    "ra": 8.523064,
    "dec": -10.497903,
    "pmra": -579.47,
    "pmdec": -315.94,
    "rv": 0,
    "Asset Name": "ASTRID 000995",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Determined Shinobi",
    "Hair": "Ram Horns",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003676,
    "y_1": 0.00002313,
    "z_1": -0.00002317,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118545,
    "Gliese Catalog_1": "NN 3503"
  },
  {
    "Astrid #": "ASTRID 000996",
    "HYG Star ID": 118811,
    "Gliese Catalog": "NN 3720",
    "Distance in Parsecs": 15.3846,
    "mag": 16.32,
    "Absolute Visual Magnitude": 15.385,
    "Spectral Type": "m",
    "Color Index": 1.98,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.31097,
    "y": -1.091512,
    "z": 7.636268,
    "vx": -0.00001371,
    "vy": 0.00001518,
    "vz": -0.00002172,
    "rarad": 3.22341852466984,
    "decrad": 0.51939832226215,
    "pmrarad": -0.00000105616660309027,
    "pmdecrad": -0.000001626394757,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118811,
    "lum": 0.0000610942024905571,
    "ra": 12.312552,
    "dec": 29.759332,
    "pmra": -217.85,
    "pmdec": -335.47,
    "rv": 0,
    "Asset Name": "ASTRID 000996",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Bob",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001371,
    "y_1": 0.00001518,
    "z_1": -0.00002172,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118811,
    "Gliese Catalog_1": "NN 3720"
  },
  {
    "Astrid #": "ASTRID 000997",
    "HYG Star ID": 118821,
    "Gliese Catalog": "GJ 1158",
    "Distance in Parsecs": 15.3846,
    "mag": 13.27,
    "Absolute Visual Magnitude": 12.335,
    "Spectral Type": "M4",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.531052,
    "y": -1.103086,
    "z": -12.753344,
    "vx": 0.00005317,
    "vy": 0.00008087,
    "vz": -0.00000928,
    "rarad": 3.27036962866342,
    "decrad": -0.97739340794145,
    "pmrarad": -0.00000476896673152083,
    "pmdecrad": -0.000003739338829,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118821,
    "lum": 0.00101391138573667,
    "ra": 12.491892,
    "dec": -56.000517,
    "pmra": -983.67,
    "pmdec": -771.29,
    "rv": -27,
    "Asset Name": "ASTRID 000997",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Slick",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005317,
    "y_1": 0.00008087,
    "z_1": -0.00000928,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 118821,
    "Gliese Catalog_1": "GJ 1158"
  },
  {
    "Astrid #": "ASTRID 000998",
    "HYG Star ID": 119401,
    "Gliese Catalog": "NN 4185A",
    "Distance in Parsecs": 15.3846,
    "mag": 12.68,
    "Absolute Visual Magnitude": 11.745,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.072405,
    "y": -8.749094,
    "z": 7.660675,
    "vx": 0.00000868,
    "vy": 0.00001267,
    "vz": 0.00000306,
    "rarad": 5.56797256228765,
    "decrad": 0.5212267490567,
    "pmrarad": 9.92025753152777e-7,
    "pmdecrad": 2.29021134e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119401,
    "base": "NN 4185",
    "lum": 0.0017458221529205,
    "ra": 21.268089,
    "dec": 29.864093,
    "pmra": 204.62,
    "pmdec": 47.24,
    "rv": 0,
    "Asset Name": "ASTRID 000998",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Neko Ninja",
    "Hair": "Prince",
    "Outfit": "Elven",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000868,
    "y_1": 0.00001267,
    "z_1": 0.00000306,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 119401,
    "Gliese Catalog_1": "NN 4185A"
  },
  {
    "Astrid #": "ASTRID 000999",
    "HYG Star ID": 119402,
    "Gliese Catalog": "NN 4186B",
    "Distance in Parsecs": 15.3846,
    "mag": 13.49,
    "Absolute Visual Magnitude": 12.555,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.071303,
    "y": -8.750708,
    "z": 7.660281,
    "vx": 0.00000869,
    "vy": 0.00001267,
    "vz": 0.00000306,
    "rarad": 5.56782693928765,
    "decrad": 0.5211971920567,
    "pmrarad": 9.92025753152777e-7,
    "pmdecrad": 2.29021134e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119402,
    "base": "NN 4186",
    "lum": 0.000827942163712333,
    "ra": 21.267532,
    "dec": 29.862399,
    "pmra": 204.62,
    "pmdec": 47.24,
    "rv": 0,
    "Asset Name": "ASTRID 000999",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Unkempt",
    "Outfit": "Tats",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000869,
    "y_1": 0.00001267,
    "z_1": 0.00000306,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 119402,
    "Gliese Catalog_1": "NN 4186B"
  },
  {
    "Astrid #": "ASTRID 001000",
    "HYG Star ID": 119541,
    "Gliese Catalog": "NN 4306",
    "Distance in Parsecs": 15.3846,
    "mag": 10.5,
    "Absolute Visual Magnitude": 9.565,
    "Spectral Type": "M0",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.080132,
    "y": -4.03984,
    "z": 4.702712,
    "vx": 0.00000977,
    "vy": -0.00004267,
    "vz": -0.00006583,
    "rarad": 6.00377965312478,
    "decrad": 0.31064894558805,
    "pmrarad": -0.000002491457504375,
    "pmdecrad": -0.000004494688239,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119541,
    "lum": 0.0130016957803329,
    "ra": 22.932749,
    "dec": 17.798873,
    "pmra": -513.9,
    "pmdec": -927.1,
    "rv": 0,
    "Asset Name": "ASTRID 001000",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Mohawk",
    "Outfit": "Elven",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000977,
    "y_1": -0.00004267,
    "z_1": -0.00006583,
    "Distance in Parsecs_1": 15.3846,
    "HYG Star ID_1": 119541,
    "Gliese Catalog_1": "NN 4306"
  },
  {
    "Astrid #": "ASTRID 001001",
    "HYG Star ID": 15880,
    "Hipparcos Catalogue": 15919,
    "Henry Draper Catalogue": 21197,
    "Gliese Catalog": "Gl 141",
    "Distance in Parsecs": 15.3917,
    "mag": 7.86,
    "Absolute Visual Magnitude": 6.924,
    "Spectral Type": "K5V",
    "Color Index": 1.153,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.592,
    "y": 11.950905,
    "z": -1.438782,
    "vx": 0.00000138,
    "vy": -0.00002574,
    "vz": -0.0000558,
    "rarad": 0.894461709731988,
    "decrad": -0.0936151954773149,
    "pmrarad": -0.00000111705920136805,
    "pmdecrad": -0.000003726568836,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 15880,
    "lum": 0.148047132489762,
    "ra": 3.416592,
    "dec": -5.363756,
    "pmra": -230.41,
    "pmdec": -768.66,
    "rv": -13.6,
    "Asset Name": "ASTRID 001001",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Smoulder",
    "Hair": "Curly",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000138,
    "y_1": -0.00002574,
    "z_1": -0.0000558,
    "Distance in Parsecs_1": 15.3917,
    "HYG Star ID_1": 15880,
    "Hipparcos Catalogue_1": 15919,
    "Henry Draper Catalogue_1": 21197,
    "Gliese Catalog_1": "Gl 141"
  },
  {
    "Astrid #": "ASTRID 001002",
    "HYG Star ID": 2935,
    "Hipparcos Catalogue": 2941,
    "Henry Draper Catalogue": 3443,
    "Harvard Revised Catalogue": 159,
    "Gliese Catalog": "Gl 25A",
    "Distance in Parsecs": 15.4012,
    "mag": 5.57,
    "Absolute Visual Magnitude": 4.632,
    "Spectral Type": "K1V+...",
    "Color Index": 0.715,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.799451,
    "y": 2.268712,
    "z": -6.452147,
    "vx": -7e-7,
    "vy": 0.00010748,
    "vz": -0.00000913,
    "rarad": 0.16294223676011,
    "decrad": -0.432270357523527,
    "pmrarad": 0.0000068944868698125,
    "pmdecrad": -8.3145546e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 2935,
    "base": "Gl  25",
    "lum": 1.22236244927873,
    "ra": 0.622393,
    "dec": -24.767267,
    "pmra": 1422.09,
    "pmdec": -17.15,
    "rv": 18.6,
    "Asset Name": "ASTRID 001002",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Pixie",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -7e-7,
    "y_1": 0.00010748,
    "z_1": -0.00000913,
    "Distance in Parsecs_1": 15.4012,
    "HYG Star ID_1": 2935,
    "Hipparcos Catalogue_1": 2941,
    "Henry Draper Catalogue_1": 3443,
    "Harvard Revised Catalogue_1": 159,
    "Gliese Catalog_1": "Gl 25A"
  },
  {
    "Astrid #": "ASTRID 001003",
    "HYG Star ID": 42629,
    "Hipparcos Catalogue": 42748,
    "Gliese Catalog": "Gl 319A",
    "Distance in Parsecs": 15.4321,
    "mag": 9.62,
    "Absolute Visual Magnitude": 8.678,
    "Spectral Type": "M0",
    "Color Index": 1.4,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.920795,
    "y": 11.539875,
    "z": 2.562122,
    "vx": -0.00003082,
    "vy": 0.00001103,
    "vz": -0.00004332,
    "rarad": 2.28089296543228,
    "decrad": 0.166795855294746,
    "pmrarad": 0.0000010485065469375,
    "pmdecrad": -0.000003074252029,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 42629,
    "base": "Gl 319",
    "lum": 0.0294306598969901,
    "ra": 8.712369,
    "dec": 9.556699,
    "pmra": 216.27,
    "pmdec": -634.11,
    "rv": 20.4,
    "Asset Name": "ASTRID 001003",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Knocked Out",
    "Hair": "Honest Lad",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00003082,
    "y_1": 0.00001103,
    "z_1": -0.00004332,
    "Distance in Parsecs_1": 15.4321,
    "HYG Star ID_1": 42629,
    "Hipparcos Catalogue_1": 42748,
    "Gliese Catalog_1": "Gl 319A"
  },
  {
    "Astrid #": "ASTRID 001004",
    "HYG Star ID": 118240,
    "Gliese Catalog": "GJ 1062",
    "Distance in Parsecs": 15.4321,
    "mag": 13.01,
    "Absolute Visual Magnitude": 12.068,
    "Spectral Type": "M2",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.764191,
    "y": 12.316048,
    "z": -3.071295,
    "vx": -0.00019087,
    "vy": -0.00008517,
    "vz": -0.00016792,
    "rarad": 0.952315393690351,
    "decrad": -0.20045171421855,
    "pmrarad": 0.00000687383380702083,
    "pmdecrad": -0.000012982384371,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118240,
    "lum": 0.00129658203538262,
    "ra": 3.637577,
    "dec": -11.485037,
    "pmra": 1417.83,
    "pmdec": -2677.81,
    "rv": -140,
    "Asset Name": "ASTRID 001004",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Lion Mane",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00019087,
    "y_1": -0.00008517,
    "z_1": -0.00016792,
    "Distance in Parsecs_1": 15.4321,
    "HYG Star ID_1": 118240,
    "Gliese Catalog_1": "GJ 1062"
  },
  {
    "Astrid #": "ASTRID 001005",
    "HYG Star ID": 118609,
    "Gliese Catalog": "NN 3542",
    "Distance in Parsecs": 15.4321,
    "mag": 15.11,
    "Absolute Visual Magnitude": 14.168,
    "Spectral Type": "m",
    "Color Index": 1.85,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.140093,
    "y": 5.253234,
    "z": 13.147339,
    "vx": -0.00005416,
    "vy": 0.0000475,
    "vz": -0.00004424,
    "rarad": 2.43388066832707,
    "decrad": 1.01969329629975,
    "pmrarad": -5.73534584097222e-8,
    "pmdecrad": -0.000005478094005,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118609,
    "lum": 0.000187413123997675,
    "ra": 9.296739,
    "dec": 58.424122,
    "pmra": -11.83,
    "pmdec": -1129.94,
    "rv": 0,
    "Asset Name": "ASTRID 001005",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Honest Lad",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005416,
    "y_1": 0.0000475,
    "z_1": -0.00004424,
    "Distance in Parsecs_1": 15.4321,
    "HYG Star ID_1": 118609,
    "Gliese Catalog_1": "NN 3542"
  },
  {
    "Astrid #": "ASTRID 001006",
    "HYG Star ID": 23780,
    "Hipparcos Catalogue": 23835,
    "Henry Draper Catalogue": 32923,
    "Harvard Revised Catalogue": 1656,
    "Gliese Catalog": "Gl 188A",
    "Distance in Parsecs": 15.4345,
    "mag": 4.91,
    "Absolute Visual Magnitude": 3.968,
    "Spectral Type": "G4V",
    "Color Index": 0.657,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.324007,
    "y": 14.241868,
    "z": 4.934537,
    "vx": -0.00003462,
    "vy": 0.00002813,
    "vz": 0.00000805,
    "rarad": 1.34150287588267,
    "decrad": 0.325417547216355,
    "pmrarad": 0.00000259884373461805,
    "pmdecrad": 8.9739012e-8,
    "flam": 104,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 23780,
    "base": "Gl 188",
    "lum": 2.25320133562948,
    "bf": "104    Tau",
    "ra": 5.124164,
    "dec": 18.645052,
    "pmra": 536.05,
    "pmdec": 18.51,
    "rv": 20.6,
    "Asset Name": "ASTRID 001006",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Star of Culture",
    "Hair": "Audiohead",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00003462,
    "y_1": 0.00002813,
    "z_1": 0.00000805,
    "Distance in Parsecs_1": 15.4345,
    "HYG Star ID_1": 23780,
    "Hipparcos Catalogue_1": 23835,
    "Henry Draper Catalogue_1": 32923,
    "Harvard Revised Catalogue_1": 1656,
    "Gliese Catalog_1": "Gl 188A"
  },
  {
    "Astrid #": "ASTRID 001007",
    "HYG Star ID": 24759,
    "Hipparcos Catalogue": 24819,
    "Henry Draper Catalogue": 34673,
    "Gliese Catalog": "Gl 200A",
    "Distance in Parsecs": 15.4488,
    "mag": 7.77,
    "Absolute Visual Magnitude": 6.826,
    "Spectral Type": "K3V",
    "Color Index": 1.051,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.73122,
    "y": 15.183658,
    "z": -0.828442,
    "vx": -0.00003601,
    "vy": 0.00009679,
    "vz": 0.00000545,
    "rarad": 1.39282063261929,
    "decrad": -0.0536480972634825,
    "pmrarad": 0.00000340407077665277,
    "pmdecrad": 6.60655602e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 24759,
    "base": "Gl 200",
    "lum": 0.162031704274,
    "ra": 5.320183,
    "dec": -3.07381,
    "pmra": 702.14,
    "pmdec": 136.27,
    "rv": 86.5,
    "Asset Name": "ASTRID 001007",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Spiky",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00003601,
    "y_1": 0.00009679,
    "z_1": 0.00000545,
    "Distance in Parsecs_1": 15.4488,
    "HYG Star ID_1": 24759,
    "Hipparcos Catalogue_1": 24819,
    "Henry Draper Catalogue_1": 34673,
    "Gliese Catalog_1": "Gl 200A"
  },
  {
    "Astrid #": "ASTRID 001008",
    "HYG Star ID": 65147,
    "Hipparcos Catalogue": 65352,
    "Henry Draper Catalogue": 116442,
    "Gliese Catalog": "NN 3781A",
    "Distance in Parsecs": 15.4488,
    "mag": 7.06,
    "Absolute Visual Magnitude": 6.116,
    "Spectral Type": "G5",
    "Color Index": 0.78,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.415009,
    "y": -5.508346,
    "z": 0.734032,
    "vx": -0.0000257,
    "vy": -0.0000109,
    "vz": 0.00001622,
    "rarad": 3.50659585844404,
    "decrad": 0.0475309876981549,
    "pmrarad": 6.49650331944444e-8,
    "pmdecrad": 9.62936932e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 65147,
    "base": "NN 3781",
    "lum": 0.311601830310546,
    "var_min": 7.078,
    "var_max": 6.918,
    "ra": 13.39421,
    "dec": 2.723325,
    "pmra": 13.4,
    "pmdec": 198.62,
    "rv": 28,
    "Asset Name": "ASTRID 001008",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Round Brush",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.0000257,
    "y_1": -0.0000109,
    "z_1": 0.00001622,
    "Distance in Parsecs_1": 15.4488,
    "HYG Star ID_1": 65147,
    "Hipparcos Catalogue_1": 65352,
    "Henry Draper Catalogue_1": 116442,
    "Gliese Catalog_1": "NN 3781A"
  },
  {
    "Astrid #": "ASTRID 001009",
    "HYG Star ID": 48764,
    "Hipparcos Catalogue": 48904,
    "Gliese Catalog": "Gl 375",
    "Distance in Parsecs": 15.456,
    "mag": 11.27,
    "Absolute Visual Magnitude": 10.325,
    "Spectral Type": "M5",
    "Color Index": 1.555,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.193371,
    "y": 5.384446,
    "z": -11.197758,
    "vx": -0.00002227,
    "vy": -0.00002858,
    "vz": -0.00005758,
    "rarad": 2.61175409232816,
    "decrad": -0.810271211739308,
    "pmrarad": 0.00000232380893363888,
    "pmdecrad": -0.000002344655921,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 48764,
    "lum": 0.00645654229034655,
    "var": "LU",
    "var_min": 11.351,
    "var_max": 11.201,
    "ra": 9.976166,
    "dec": -46.425121,
    "pmra": 479.32,
    "pmdec": -483.62,
    "rv": 44,
    "Asset Name": "ASTRID 001009",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Battle Ready",
    "Hair": "Windy",
    "Outfit": "Wizard",
    "Special": "Astral Burst",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00002227,
    "y_1": -0.00002858,
    "z_1": -0.00005758,
    "Distance in Parsecs_1": 15.456,
    "HYG Star ID_1": 48764,
    "Hipparcos Catalogue_1": 48904,
    "Gliese Catalog_1": "Gl 375"
  },
  {
    "Astrid #": "ASTRID 001010",
    "HYG Star ID": 92284,
    "Hipparcos Catalogue": 92573,
    "Henry Draper Catalogue": 229793,
    "Gliese Catalog": "Gl 731",
    "Distance in Parsecs": 15.4775,
    "mag": 10.16,
    "Absolute Visual Magnitude": 9.211,
    "Spectral Type": "M2",
    "Color Index": 1.447,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.327566,
    "y": -14.455552,
    "z": 4.417375,
    "vx": -0.0000175,
    "vy": 3.5e-7,
    "vz": -0.00003906,
    "rarad": 4.93864101777995,
    "decrad": 0.289432843136611,
    "pmrarad": -0.000001096939433625,
    "pmdecrad": -0.000002340098673,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 92284,
    "lum": 0.0180135786466913,
    "var_min": 10.256,
    "var_max": 10.096,
    "ra": 18.864219,
    "dec": 16.58328,
    "pmra": -226.26,
    "pmdec": -482.68,
    "rv": -14.9,
    "Asset Name": "ASTRID 001010",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Round Brush",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.0000175,
    "y_1": 3.5e-7,
    "z_1": -0.00003906,
    "Distance in Parsecs_1": 15.4775,
    "HYG Star ID_1": 92284,
    "Hipparcos Catalogue_1": 92573,
    "Henry Draper Catalogue_1": 229793,
    "Gliese Catalog_1": "Gl 731"
  },
  {
    "Astrid #": "ASTRID 001011",
    "HYG Star ID": 119226,
    "Gliese Catalog": "Gl 720B",
    "Distance in Parsecs": 15.4775,
    "mag": 13.02,
    "Absolute Visual Magnitude": 12.071,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.663604,
    "y": -10.669038,
    "z": 11.0886,
    "vx": 0.00003037,
    "vy": 0.00002306,
    "vz": 0.00001764,
    "rarad": 4.86702469603524,
    "decrad": 0.798676752983649,
    "pmrarad": 0.00000216828070491666,
    "pmdecrad": 0.000001633899673,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 90846,
    "base": "Gl 720",
    "lum": 0.00129300439179627,
    "ra": 18.590665,
    "dec": 45.760807,
    "pmra": 447.24,
    "pmdec": 337.02,
    "rv": 0,
    "Asset Name": "ASTRID 001011",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Afro",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003037,
    "y_1": 0.00002306,
    "z_1": 0.00001764,
    "Distance in Parsecs_1": 15.4775,
    "HYG Star ID_1": 119226,
    "Gliese Catalog_1": "Gl 720B"
  },
  {
    "Astrid #": "ASTRID 001012",
    "HYG Star ID": 118049,
    "Gliese Catalog": "NN 3077A",
    "Distance in Parsecs": 15.4799,
    "mag": 12.89,
    "Absolute Visual Magnitude": 11.941,
    "Spectral Type": "m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.669633,
    "y": 4.761051,
    "z": 1.327243,
    "vx": -0.00000478,
    "vy": 0.00002552,
    "vz": -0.00003861,
    "rarad": 0.313825887786899,
    "decrad": 0.08584518744125,
    "pmrarad": 0.00000166339573798611,
    "pmdecrad": -0.000002503631175,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118049,
    "base": "NN 3077",
    "lum": 0.00145747126125652,
    "ra": 1.198727,
    "dec": 4.918567,
    "pmra": 343.1,
    "pmdec": -516.41,
    "rv": 0,
    "Asset Name": "ASTRID 001012",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Blep",
    "Hair": "High Bun",
    "Outfit": "River Spirit",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000478,
    "y_1": 0.00002552,
    "z_1": -0.00003861,
    "Distance in Parsecs_1": 15.4799,
    "HYG Star ID_1": 118049,
    "Gliese Catalog_1": "NN 3077A"
  },
  {
    "Astrid #": "ASTRID 001013",
    "HYG Star ID": 118050,
    "Gliese Catalog": "NN 3078B",
    "Distance in Parsecs": 15.4799,
    "mag": 13.91,
    "Absolute Visual Magnitude": 12.961,
    "Spectral Type": "m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.669309,
    "y": 4.763299,
    "z": 1.322753,
    "vx": -0.0000048,
    "vy": 0.00002551,
    "vz": -0.00003861,
    "rarad": 0.313970963786899,
    "decrad": 0.08555408944125,
    "pmrarad": 0.00000166339573798611,
    "pmdecrad": -0.000002503631175,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118050,
    "base": "NN 3078",
    "lum": 0.000569639373341183,
    "ra": 1.199281,
    "dec": 4.901888,
    "pmra": 343.1,
    "pmdec": -516.41,
    "rv": 0,
    "Asset Name": "ASTRID 001013",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Infatuated",
    "Hair": "Bucket Hat",
    "Outfit": "Astro",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000048,
    "y_1": 0.00002551,
    "z_1": -0.00003861,
    "Distance in Parsecs_1": 15.4799,
    "HYG Star ID_1": 118050,
    "Gliese Catalog_1": "NN 3078B"
  },
  {
    "Astrid #": "ASTRID 001014",
    "HYG Star ID": 118150,
    "Gliese Catalog": "NN 3162",
    "Distance in Parsecs": 15.4799,
    "mag": 15.76,
    "Absolute Visual Magnitude": 14.811,
    "Spectral Type": "DC",
    "Color Index": 0.75,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.799904,
    "y": 9.27229,
    "z": -3.796603,
    "vx": -0.00001295,
    "vy": -0.00000398,
    "vz": -0.00004998,
    "rarad": 0.666017832476139,
    "decrad": -0.2477880472276,
    "pmrarad": 3.14789522784722e-7,
    "pmdecrad": -0.000003330364552,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118150,
    "lum": 0.0001036573256767,
    "ra": 2.544001,
    "dec": -14.197209,
    "pmra": 64.93,
    "pmdec": -686.94,
    "rv": 0,
    "Asset Name": "ASTRID 001014",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Afro",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001295,
    "y_1": -0.00000398,
    "z_1": -0.00004998,
    "Distance in Parsecs_1": 15.4799,
    "HYG Star ID_1": 118150,
    "Gliese Catalog_1": "NN 3162"
  },
  {
    "Astrid #": "ASTRID 001015",
    "HYG Star ID": 8692,
    "Hipparcos Catalogue": 8709,
    "Gliese Catalog": "NN 3121",
    "Distance in Parsecs": 15.4967,
    "mag": 12.44,
    "Absolute Visual Magnitude": 11.489,
    "Spectral Type": "DAs",
    "Color Index": 0.17,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.330583,
    "y": 4.963735,
    "z": 11.33433,
    "vx": 0.00003335,
    "vy": 0.00001784,
    "vz": 0.00005423,
    "rarad": 0.488907360178072,
    "decrad": 0.820337074894757,
    "pmrarad": 5.96320827083333e-9,
    "pmdecrad": 6.01702258e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 8692,
    "lum": 0.00221003931734918,
    "var_min": 12.608,
    "var_max": 12.308,
    "ra": 1.867489,
    "dec": 47.001852,
    "pmra": 1.23,
    "pmdec": 124.11,
    "rv": 64,
    "Asset Name": "ASTRID 001015",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Neko Ninja",
    "Hair": "Sweetheart",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003335,
    "y_1": 0.00001784,
    "z_1": 0.00005423,
    "Distance in Parsecs_1": 15.4967,
    "HYG Star ID_1": 8692,
    "Hipparcos Catalogue_1": 8709,
    "Gliese Catalog_1": "NN 3121"
  },
  {
    "Astrid #": "ASTRID 001016",
    "HYG Star ID": 118555,
    "Gliese Catalog": "Gl 316.1",
    "Distance in Parsecs": 15.5039,
    "mag": 17.68,
    "Absolute Visual Magnitude": 16.728,
    "Spectral Type": "m",
    "Color Index": 2.02,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.480991,
    "y": 11.248636,
    "z": 4.893867,
    "vx": 0.0000379,
    "vy": 0.00004593,
    "vz": -0.00003209,
    "rarad": 2.27113900526055,
    "decrad": 0.3211457539219,
    "pmrarad": -0.00000377873478898611,
    "pmdecrad": -0.000002181661562,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118555,
    "lum": 0.0000177337262454255,
    "ra": 8.675112,
    "dec": 18.400296,
    "pmra": -779.42,
    "pmdec": -450,
    "rv": 0,
    "Asset Name": "ASTRID 001016",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Halo Boy",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000379,
    "y_1": 0.00004593,
    "z_1": -0.00003209,
    "Distance in Parsecs_1": 15.5039,
    "HYG Star ID_1": 118555,
    "Gliese Catalog_1": "Gl 316.1"
  },
  {
    "Astrid #": "ASTRID 001017",
    "HYG Star ID": 86529,
    "Hipparcos Catalogue": 86796,
    "Henry Draper Catalogue": 160691,
    "Harvard Revised Catalogue": 6585,
    "Gliese Catalog": "Gl 691",
    "Distance in Parsecs": 15.5111,
    "mag": 5.12,
    "Absolute Visual Magnitude": 4.167,
    "Spectral Type": "G5V",
    "Color Index": 0.694,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.662558,
    "y": -9.561971,
    "z": -12.19514,
    "vx": 4e-8,
    "vy": 0.00001703,
    "vz": -0.00000165,
    "rarad": 4.64320908813677,
    "decrad": -0.904674887660238,
    "pmrarad": -7.30129402916666e-8,
    "pmdecrad": -9.26818313e-7,
    "bayer": "Mu",
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 86529,
    "lum": 1.87585817379928,
    "bf": "Mu  Ara",
    "ra": 17.735752,
    "dec": -51.834053,
    "pmra": -15.06,
    "pmdec": -191.17,
    "rv": -9,
    "Asset Name": "ASTRID 001017",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Charmer",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 4e-8,
    "y_1": 0.00001703,
    "z_1": -0.00000165,
    "Distance in Parsecs_1": 15.5111,
    "HYG Star ID_1": 86529,
    "Hipparcos Catalogue_1": 86796,
    "Henry Draper Catalogue_1": 160691,
    "Harvard Revised Catalogue_1": 6585,
    "Gliese Catalog_1": "Gl 691"
  },
  {
    "Astrid #": "ASTRID 001018",
    "HYG Star ID": 110180,
    "Hipparcos Catalogue": 110526,
    "Gliese Catalog": "Gl 856A",
    "Distance in Parsecs": 15.5111,
    "mag": 10.7,
    "Absolute Visual Magnitude": 9.747,
    "Spectral Type": "M0...",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.944331,
    "y": -5.350155,
    "z": 8.324843,
    "vx": 0.00001537,
    "vy": 0.00001382,
    "vz": -0.00001317,
    "rarad": 5.86205701274997,
    "decrad": 0.566523955632135,
    "pmrarad": 0.00000121814285376388,
    "pmdecrad": -0.000001006327756,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 110180,
    "base": "Gl 856",
    "lum": 0.0109951206687934,
    "var_min": 10.754,
    "var_max": 10.634,
    "ra": 22.391408,
    "dec": 32.459432,
    "pmra": 251.26,
    "pmdec": -207.57,
    "rv": 0,
    "Asset Name": "ASTRID 001018",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Lion Mane",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001537,
    "y_1": 0.00001382,
    "z_1": -0.00001317,
    "Distance in Parsecs_1": 15.5111,
    "HYG Star ID_1": 110180,
    "Hipparcos Catalogue_1": 110526,
    "Gliese Catalog_1": "Gl 856A"
  },
  {
    "Astrid #": "ASTRID 001019",
    "HYG Star ID": 59332,
    "Hipparcos Catalogue": 59514,
    "Henry Draper Catalogue": 238090,
    "Gliese Catalog": "Gl 458A",
    "Distance in Parsecs": 15.5207,
    "mag": 9.71,
    "Absolute Visual Magnitude": 8.755,
    "Spectral Type": "M2",
    "Color Index": 1.38,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.002884,
    "y": -0.485516,
    "z": 12.633273,
    "vx": 0.00001692,
    "vy": -0.00001655,
    "vz": -0.00001069,
    "rarad": 3.19546233465768,
    "decrad": 0.950955788663603,
    "pmrarad": 0.00000112384659289583,
    "pmdecrad": 4.39047269e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 59332,
    "base": "Gl 458",
    "lum": 0.0274157417192787,
    "var_min": 9.764,
    "var_max": 9.654,
    "ra": 12.205767,
    "dec": 54.485753,
    "pmra": 231.81,
    "pmdec": 90.56,
    "rv": -17.6,
    "Asset Name": "ASTRID 001019",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Ninja",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001692,
    "y_1": -0.00001655,
    "z_1": -0.00001069,
    "Distance in Parsecs_1": 15.5207,
    "HYG Star ID_1": 59332,
    "Hipparcos Catalogue_1": 59514,
    "Henry Draper Catalogue_1": 238090,
    "Gliese Catalog_1": "Gl 458A"
  },
  {
    "Astrid #": "ASTRID 001020",
    "HYG Star ID": 95207,
    "Hipparcos Catalogue": 95501,
    "Henry Draper Catalogue": 182640,
    "Harvard Revised Catalogue": 7377,
    "Gliese Catalog": "Gl 760",
    "Distance in Parsecs": 15.5255,
    "mag": 3.36,
    "Absolute Visual Magnitude": 2.405,
    "Spectral Type": "F0IV",
    "Color Index": 0.319,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.650026,
    "y": -14.436012,
    "z": 0.843584,
    "vx": 0.00000649,
    "vy": 0.00003568,
    "vz": 0.0000044,
    "rarad": 5.08544584810812,
    "decrad": 0.054363084117965,
    "pmrarad": 0.00000122686950001388,
    "pmdecrad": 3.91099196e-7,
    "bayer": "Del",
    "flam": 30,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 95207,
    "lum": 9.50604793656281,
    "bf": "30Del Aql",
    "ra": 19.424972,
    "dec": 3.114775,
    "pmra": 253.06,
    "pmdec": 80.67,
    "rv": -29.9,
    "Asset Name": "ASTRID 001020",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Spiky",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000649,
    "y_1": 0.00003568,
    "z_1": 0.0000044,
    "Distance in Parsecs_1": 15.5255,
    "HYG Star ID_1": 95207,
    "Hipparcos Catalogue_1": 95501,
    "Henry Draper Catalogue_1": 182640,
    "Harvard Revised Catalogue_1": 7377,
    "Gliese Catalog_1": "Gl 760"
  },
  {
    "Astrid #": "ASTRID 001021",
    "HYG Star ID": 18465,
    "Hipparcos Catalogue": 18512,
    "Henry Draper Catalogue": 24916,
    "Gliese Catalog": "Gl 157A",
    "Distance in Parsecs": 15.528,
    "mag": 8.07,
    "Absolute Visual Magnitude": 7.114,
    "Spectral Type": "K4V",
    "Color Index": 1.115,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.909894,
    "y": 13.358713,
    "z": -0.314208,
    "vx": 0.00001468,
    "vy": -0.00000216,
    "vz": -0.00001059,
    "rarad": 1.03619477810102,
    "decrad": -0.0202362554853562,
    "pmrarad": -8.844455974375e-7,
    "pmdecrad": -6.74569755e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 18465,
    "base": "Gl 157",
    "lum": 0.124279643844091,
    "ra": 3.957973,
    "dec": -1.159452,
    "pmra": -182.43,
    "pmdec": -139.14,
    "rv": 5.7,
    "Asset Name": "ASTRID 001021",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Medium Bob",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00001468,
    "y_1": -0.00000216,
    "z_1": -0.00001059,
    "Distance in Parsecs_1": 15.528,
    "HYG Star ID_1": 18465,
    "Hipparcos Catalogue_1": 18512,
    "Henry Draper Catalogue_1": 24916,
    "Gliese Catalog_1": "Gl 157A"
  },
  {
    "Astrid #": "ASTRID 001022",
    "HYG Star ID": 118001,
    "Gliese Catalog": "Gl 25B",
    "Distance in Parsecs": 15.5328,
    "mag": 6.4,
    "Absolute Visual Magnitude": 5.444,
    "Spectral Type": "G8 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.91838,
    "y": 2.288611,
    "z": -6.507579,
    "vx": 0.00000574,
    "vy": 0.000107,
    "vz": -0.00001121,
    "rarad": 0.162938423659849,
    "decrad": -0.432258585994549,
    "pmrarad": 0.00000673881319698611,
    "pmdecrad": -3.5279891e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 2935,
    "base": "Gl  25",
    "lum": 0.578628738817952,
    "ra": 0.622379,
    "dec": -24.766593,
    "pmra": 1389.98,
    "pmdec": -7.28,
    "rv": 25,
    "Asset Name": "ASTRID 001022",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Round Brush",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000574,
    "y_1": 0.000107,
    "z_1": -0.00001121,
    "Distance in Parsecs_1": 15.5328,
    "HYG Star ID_1": 118001,
    "Gliese Catalog_1": "Gl 25B"
  },
  {
    "Astrid #": "ASTRID 001023",
    "HYG Star ID": 90846,
    "Hipparcos Catalogue": 91128,
    "Gliese Catalog": "Gl 720A",
    "Distance in Parsecs": 15.5473,
    "mag": 9.84,
    "Absolute Visual Magnitude": 8.882,
    "Spectral Type": "M2",
    "Color Index": 1.415,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.664836,
    "y": -10.72123,
    "z": 11.135233,
    "vx": 0.00002721,
    "vy": 0.00004668,
    "vz": -0.00000368,
    "rarad": 4.86643434689682,
    "decrad": 0.798384135920015,
    "pmrarad": 0.00000219038820875,
    "pmdecrad": 0.000001767533716,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 90846,
    "base": "Gl 720",
    "lum": 0.0243893373027891,
    "ra": 18.58841,
    "dec": 45.744041,
    "pmra": 451.8,
    "pmdec": 364.58,
    "rv": -31.2,
    "Asset Name": "ASTRID 001023",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Shinobi",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00002721,
    "y_1": 0.00004668,
    "z_1": -0.00000368,
    "Distance in Parsecs_1": 15.5473,
    "HYG Star ID_1": 90846,
    "Hipparcos Catalogue_1": 91128,
    "Gliese Catalog_1": "Gl 720A"
  },
  {
    "Astrid #": "ASTRID 001024",
    "HYG Star ID": 101190,
    "Hipparcos Catalogue": 101516,
    "Henry Draper Catalogue": 340611,
    "Distance in Parsecs": 15.5473,
    "mag": 11.53,
    "Absolute Visual Magnitude": 10.572,
    "Spectral Type": "DA",
    "Color Index": -0.097,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.784956,
    "y": -11.008198,
    "z": 6.586533,
    "vx": 0.00002844,
    "vy": -0.0000845,
    "vz": -0.00000775,
    "rarad": 5.38593395801949,
    "decrad": 0.437446153657474,
    "pmrarad": -0.00000196068348690277,
    "pmdecrad": -0.00000273512486,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 101190,
    "lum": 0.00514280431948792,
    "var_min": 11.63,
    "var_max": 11.37,
    "ra": 20.572752,
    "dec": 25.063818,
    "pmra": -404.42,
    "pmdec": -564.16,
    "rv": 71,
    "Asset Name": "ASTRID 001024",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "Audiophile",
    "Outfit": "Toga",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00002844,
    "y_1": -0.0000845,
    "z_1": -0.00000775,
    "Distance in Parsecs_1": 15.5473,
    "HYG Star ID_1": 101190,
    "Hipparcos Catalogue_1": 101516,
    "Henry Draper Catalogue_1": 340611
  },
  {
    "Astrid #": "ASTRID 001025",
    "HYG Star ID": 119371,
    "Gliese Catalog": "Gl 808",
    "Distance in Parsecs": 15.5521,
    "mag": 11.85,
    "Absolute Visual Magnitude": 10.891,
    "Spectral Type": "M2.5",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.965023,
    "y": -2.111121,
    "z": -15.284154,
    "vx": -0.0000065,
    "vy": 0.00008463,
    "vz": -0.00004898,
    "rarad": 5.46121399922655,
    "decrad": -1.38428945783615,
    "pmrarad": 0.00000339820453111805,
    "pmdecrad": -0.000004781736723,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 119371,
    "lum": 0.00383354000759043,
    "ra": 20.860301,
    "dec": -79.313944,
    "pmra": 700.93,
    "pmdec": -986.3,
    "rv": 35,
    "Asset Name": "ASTRID 001025",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Blep",
    "Hair": "Braid",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": -0.0000065,
    "y_1": 0.00008463,
    "z_1": -0.00004898,
    "Distance in Parsecs_1": 15.5521,
    "HYG Star ID_1": 119371,
    "Gliese Catalog_1": "Gl 808"
  },
  {
    "Astrid #": "ASTRID 001026",
    "HYG Star ID": 76543,
    "Hipparcos Catalogue": 76779,
    "Henry Draper Catalogue": 139763,
    "Distance in Parsecs": 15.5569,
    "mag": 8.92,
    "Absolute Visual Magnitude": 7.96,
    "Spectral Type": "K5V",
    "Color Index": 1.296,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.453496,
    "y": -12.137673,
    "z": -4.820005,
    "vx": 0.00000871,
    "vy": -0.00000863,
    "vz": 0.00000645,
    "rarad": 4.10403733802734,
    "decrad": -0.315014951910175,
    "pmrarad": 7.76574553513889e-7,
    "pmdecrad": 4.3589598e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 76543,
    "lum": 0.0570164272280747,
    "ra": 15.676268,
    "dec": -18.049027,
    "pmra": 160.18,
    "pmdec": 89.91,
    "rv": 0,
    "Asset Name": "ASTRID 001026",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Fedora",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000871,
    "y_1": -0.00000863,
    "z_1": 0.00000645,
    "Distance in Parsecs_1": 15.5569,
    "HYG Star ID_1": 76543,
    "Hipparcos Catalogue_1": 76779,
    "Henry Draper Catalogue_1": 139763
  },
  {
    "Astrid #": "ASTRID 001027",
    "HYG Star ID": 98369,
    "Hipparcos Catalogue": 98681,
    "Distance in Parsecs": 15.5618,
    "mag": 9.92,
    "Absolute Visual Magnitude": 8.96,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 5.094198,
    "y": -8.596462,
    "z": -11.929778,
    "vx": 0.00001253,
    "vy": 0.00002836,
    "vz": -0.00001509,
    "rarad": 5.24733597204198,
    "decrad": -0.873539630384967,
    "pmrarad": 0.00000162160479872222,
    "pmdecrad": -0.000001509758282,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 98369,
    "lum": 0.0226986485188381,
    "ra": 20.043347,
    "dec": -50.050134,
    "pmra": 334.48,
    "pmdec": -311.41,
    "rv": 0,
    "Asset Name": "ASTRID 001027",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Grecian",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00001253,
    "y_1": 0.00002836,
    "z_1": -0.00001509,
    "Distance in Parsecs_1": 15.5618,
    "HYG Star ID_1": 98369,
    "Hipparcos Catalogue_1": 98681
  },
  {
    "Astrid #": "ASTRID 001028",
    "HYG Star ID": 18235,
    "Hipparcos Catalogue": 18280,
    "Gliese Catalog": "Gl 156",
    "Distance in Parsecs": 15.5666,
    "mag": 9.02,
    "Absolute Visual Magnitude": 8.059,
    "Spectral Type": "M0V",
    "Color Index": 1.366,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.042154,
    "y": 13.199902,
    "z": -1.850232,
    "vx": 0.00003682,
    "vy": 0.00005983,
    "vz": 0.00003185,
    "rarad": 1.02359682898254,
    "decrad": -0.119136334491931,
    "pmrarad": -2.04591373194444e-8,
    "pmdecrad": 0.000002568058065,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 18235,
    "lum": 0.0520475151170522,
    "ra": 3.909852,
    "dec": -6.826009,
    "pmra": -4.22,
    "pmdec": 529.7,
    "rv": 64.5,
    "Asset Name": "ASTRID 001028",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Shinobi",
    "Outfit": "River Spirit",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00003682,
    "y_1": 0.00005983,
    "z_1": 0.00003185,
    "Distance in Parsecs_1": 15.5666,
    "HYG Star ID_1": 18235,
    "Hipparcos Catalogue_1": 18280,
    "Gliese Catalog_1": "Gl 156"
  },
  {
    "Astrid #": "ASTRID 001029",
    "HYG Star ID": 36744,
    "Hipparcos Catalogue": 36850,
    "Henry Draper Catalogue": 60179,
    "Harvard Revised Catalogue": 2891,
    "Gliese Catalog": "Gl 278A",
    "Proper Name": "Castor",
    "Distance in Parsecs": 15.5958,
    "mag": 1.58,
    "Absolute Visual Magnitude": 0.615,
    "Spectral Type": "A2Vm",
    "Color Index": 0.034,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -5.311938,
    "y": 12.130011,
    "z": 8.238737,
    "vx": 0.00000983,
    "vy": 0.00001645,
    "vz": -0.00000627,
    "rarad": 1.98355827023441,
    "decrad": 0.556555413027028,
    "pmrarad": -0.00000100031606709027,
    "pmdecrad": -7.18396911e-7,
    "bayer": "Alp",
    "flam": 66,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 36744,
    "base": "Gl 278",
    "lum": 49.4310686986835,
    "bf": "66Alp Gem",
    "ra": 7.576634,
    "dec": 31.888276,
    "pmra": -206.33,
    "pmdec": -148.18,
    "rv": 6,
    "Asset Name": "ASTRID 001029",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Naughty Dreams",
    "Hair": "Antlers",
    "Outfit": "Warlock",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "Castor",
    "Constellation": "Gemini",
    "x_1": 0.00000983,
    "y_1": 0.00001645,
    "z_1": -0.00000627,
    "Distance in Parsecs_1": 15.5958,
    "HYG Star ID_1": 36744,
    "Hipparcos Catalogue_1": 36850,
    "Henry Draper Catalogue_1": 60179,
    "Harvard Revised Catalogue_1": 2891,
    "Gliese Catalog_1": "Gl 278A"
  },
  {
    "Astrid #": "ASTRID 001030",
    "HYG Star ID": 118919,
    "Gliese Catalog": "Gl 527B",
    "Distance in Parsecs": 15.5958,
    "mag": 11,
    "Absolute Visual Magnitude": 10.035,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.277795,
    "y": -6.711405,
    "z": 4.678267,
    "vx": -0.00001558,
    "vy": 0.00003267,
    "vz": 0.00000266,
    "rarad": 3.60960495893438,
    "decrad": 0.304660865631849,
    "pmrarad": -0.00000232022131240277,
    "pmdecrad": 1.78532637e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 67063,
    "base": "Gl 527",
    "lum": 0.00843334757764274,
    "ra": 13.787675,
    "dec": 17.455782,
    "pmra": -478.58,
    "pmdec": 36.83,
    "rv": 0,
    "Asset Name": "ASTRID 001030",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Heartbreaker",
    "Outfit": "Sharky",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00001558,
    "y_1": 0.00003267,
    "z_1": 0.00000266,
    "Distance in Parsecs_1": 15.5958,
    "HYG Star ID_1": 118919,
    "Gliese Catalog_1": "Gl 527B"
  },
  {
    "Astrid #": "ASTRID 001031",
    "HYG Star ID": 112997,
    "Hipparcos Catalogue": 113357,
    "Henry Draper Catalogue": 217014,
    "Harvard Revised Catalogue": 8729,
    "Gliese Catalog": "Gl 882",
    "Distance in Parsecs": 15.6079,
    "mag": 5.45,
    "Absolute Visual Magnitude": 4.483,
    "Spectral Type": "G5V",
    "Color Index": 0.666,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.053522,
    "y": -3.93265,
    "z": 5.534429,
    "vx": -0.00002837,
    "vy": 0.00002429,
    "vz": -0.00000791,
    "rarad": 6.01033012515425,
    "decrad": 0.362484503996166,
    "pmrarad": 0.00000100870334376388,
    "pmdecrad": 2.95542419e-7,
    "flam": 51,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112997,
    "lum": 1.40216783370575,
    "bf": "51    Peg",
    "ra": 22.95777,
    "dec": 20.768832,
    "pmra": 208.06,
    "pmdec": 60.96,
    "rv": -33.7,
    "Asset Name": "ASTRID 001031",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Lion Mane",
    "Outfit": "Unit 01",
    "Special": "Neko Tail",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00002837,
    "y_1": 0.00002429,
    "z_1": -0.00000791,
    "Distance in Parsecs_1": 15.6079,
    "HYG Star ID_1": 112997,
    "Hipparcos Catalogue_1": 113357,
    "Henry Draper Catalogue_1": 217014,
    "Harvard Revised Catalogue_1": 8729,
    "Gliese Catalog_1": "Gl 882"
  },
  {
    "Astrid #": "ASTRID 001032",
    "HYG Star ID": 64807,
    "Hipparcos Catalogue": 65012,
    "Gliese Catalog": "Gl 507B",
    "Distance in Parsecs": 15.6104,
    "mag": 12.03,
    "Absolute Visual Magnitude": 11.063,
    "Spectral Type": "M3",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.00834,
    "y": -4.345675,
    "z": 8.977588,
    "vx": -0.00001558,
    "vy": -0.00003625,
    "vz": -0.0000535,
    "rarad": 3.48881511275072,
    "decrad": 0.612733503840539,
    "pmrarad": 0.00000184408579672916,
    "pmdecrad": -0.000003798418224,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 64806,
    "base": "Gl 507",
    "lum": 0.00327189983623178,
    "ra": 13.326292,
    "dec": 35.107044,
    "pmra": 380.37,
    "pmdec": -783.48,
    "rv": -8.5,
    "Asset Name": "ASTRID 001032",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Slick",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001558,
    "y_1": -0.00003625,
    "z_1": -0.0000535,
    "Distance in Parsecs_1": 15.6104,
    "HYG Star ID_1": 64807,
    "Hipparcos Catalogue_1": 65012,
    "Gliese Catalog_1": "Gl 507B"
  },
  {
    "Astrid #": "ASTRID 001033",
    "HYG Star ID": 99068,
    "Hipparcos Catalogue": 99385,
    "Henry Draper Catalogue": 191391,
    "Gliese Catalog": "Gl 782",
    "Distance in Parsecs": 15.6152,
    "mag": 8.91,
    "Absolute Visual Magnitude": 7.942,
    "Spectral Type": "K4Vp",
    "Color Index": 1.296,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.876695,
    "y": -12.325182,
    "z": -5.466942,
    "vx": -0.00002454,
    "vy": -0.0000259,
    "vz": -0.00003364,
    "rarad": 5.28104584454447,
    "decrad": -0.357677839853321,
    "pmrarad": -0.00000221753777486111,
    "pmdecrad": -0.000001825468951,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 99068,
    "lum": 0.057969559465352,
    "ra": 20.172109,
    "dec": -20.493431,
    "pmra": -457.4,
    "pmdec": -376.53,
    "rv": 19.4,
    "Asset Name": "ASTRID 001033",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "High Bun",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00002454,
    "y_1": -0.0000259,
    "z_1": -0.00003364,
    "Distance in Parsecs_1": 15.6152,
    "HYG Star ID_1": 99068,
    "Hipparcos Catalogue_1": 99385,
    "Henry Draper Catalogue_1": 191391,
    "Gliese Catalog_1": "Gl 782"
  },
  {
    "Astrid #": "ASTRID 001034",
    "HYG Star ID": 67063,
    "Hipparcos Catalogue": 67275,
    "Henry Draper Catalogue": 120136,
    "Harvard Revised Catalogue": 5185,
    "Gliese Catalog": "Gl 527A",
    "Distance in Parsecs": 15.6177,
    "mag": 4.5,
    "Absolute Visual Magnitude": 3.532,
    "Spectral Type": "F7V",
    "Color Index": 0.508,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.296143,
    "y": -6.720908,
    "z": 4.685085,
    "vx": -0.00000129,
    "vy": 0.0000401,
    "vz": -0.00000103,
    "rarad": 3.60961406382642,
    "decrad": 0.304680487380455,
    "pmrarad": -0.0000023287055518125,
    "pmdecrad": 2.62672052e-7,
    "bayer": "Tau",
    "flam": 4,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 67063,
    "base": "Gl 527",
    "lum": 3.36666574368622,
    "bf": "4Tau Boo",
    "ra": 13.78771,
    "dec": 17.456906,
    "pmra": -480.33,
    "pmdec": 54.18,
    "rv": -16.1,
    "Asset Name": "ASTRID 001034",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Stunned",
    "Hair": "Unkempt",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000129,
    "y_1": 0.0000401,
    "z_1": -0.00000103,
    "Distance in Parsecs_1": 15.6177,
    "HYG Star ID_1": 67063,
    "Hipparcos Catalogue_1": 67275,
    "Henry Draper Catalogue_1": 120136,
    "Harvard Revised Catalogue_1": 5185,
    "Gliese Catalog_1": "Gl 527A"
  },
  {
    "Astrid #": "ASTRID 001035",
    "HYG Star ID": 117999,
    "Gliese Catalog": "GJ 1014",
    "Distance in Parsecs": 15.625,
    "mag": 15.32,
    "Absolute Visual Magnitude": 14.351,
    "Color Index": 1.89,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 15.176218,
    "y": 2.398174,
    "z": 2.841095,
    "vx": -0.00000742,
    "vy": 0.00008358,
    "vz": -0.00003078,
    "rarad": 0.156721414008353,
    "decrad": 0.1828472318277,
    "pmrarad": 0.00000535850016707638,
    "pmdecrad": -0.000002003463446,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117999,
    "lum": 0.000158343412391457,
    "ra": 0.598632,
    "dec": 10.476375,
    "pmra": 1105.27,
    "pmdec": -413.24,
    "rv": 0,
    "Asset Name": "ASTRID 001035",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Mortified",
    "Hair": "Skater Helmet",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000742,
    "y_1": 0.00008358,
    "z_1": -0.00003078,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 117999,
    "Gliese Catalog_1": "GJ 1014"
  },
  {
    "Astrid #": "ASTRID 001036",
    "HYG Star ID": 118314,
    "Gliese Catalog": "NN 3296",
    "Distance in Parsecs": 15.625,
    "mag": 14.6,
    "Absolute Visual Magnitude": 13.631,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.380482,
    "y": 13.585147,
    "z": 5.534875,
    "vx": -0.00002735,
    "vy": 0.00002013,
    "vz": -0.00002281,
    "rarad": 1.19368712221339,
    "decrad": 0.3620927104513,
    "pmrarad": 0.00000210176426794444,
    "pmdecrad": -0.000001560910974,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118314,
    "lum": 0.000307326492920428,
    "ra": 4.559549,
    "dec": 20.746384,
    "pmra": 433.52,
    "pmdec": -321.96,
    "rv": 0,
    "Asset Name": "ASTRID 001036",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Round Brush",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002735,
    "y_1": 0.00002013,
    "z_1": -0.00002281,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 118314,
    "Gliese Catalog_1": "NN 3296"
  },
  {
    "Astrid #": "ASTRID 001037",
    "HYG Star ID": 118436,
    "Gliese Catalog": "NN 3404A",
    "Distance in Parsecs": 15.625,
    "mag": 12.06,
    "Absolute Visual Magnitude": 11.091,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.854073,
    "y": 15.331111,
    "z": 0.975661,
    "vx": -0.00000278,
    "vy": 8.2e-7,
    "vz": -0.00002101,
    "rarad": 1.75485155692139,
    "decrad": 0.06248288823025,
    "pmrarad": 1.65418427805555e-7,
    "pmdecrad": -0.000001347355395,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118436,
    "base": "NN 3404",
    "lum": 0.00318859969338638,
    "ra": 6.703039,
    "dec": 3.580006,
    "pmra": 34.12,
    "pmdec": -277.91,
    "rv": 0,
    "Asset Name": "ASTRID 001037",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Unkempt",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000278,
    "y_1": 8.2e-7,
    "z_1": -0.00002101,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 118436,
    "Gliese Catalog_1": "NN 3404A"
  },
  {
    "Astrid #": "ASTRID 001038",
    "HYG Star ID": 118437,
    "Gliese Catalog": "NN 3405B",
    "Distance in Parsecs": 15.625,
    "mag": 13.33,
    "Absolute Visual Magnitude": 12.361,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.857396,
    "y": 15.330291,
    "z": 0.97882,
    "vx": -0.00000278,
    "vy": 8.2e-7,
    "vz": -0.00002101,
    "rarad": 1.75507069192139,
    "decrad": 0.06268546623025,
    "pmrarad": 1.65418427805555e-7,
    "pmdecrad": -0.000001347355395,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118437,
    "base": "NN 3405",
    "lum": 0.000989919775080583,
    "ra": 6.703876,
    "dec": 3.591613,
    "pmra": 34.12,
    "pmdec": -277.91,
    "rv": 0,
    "Asset Name": "ASTRID 001038",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Chuckle",
    "Hair": "Heartbreaker",
    "Outfit": "Astro",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000278,
    "y_1": 8.2e-7,
    "z_1": -0.00002101,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 118437,
    "Gliese Catalog_1": "NN 3405B"
  },
  {
    "Astrid #": "ASTRID 001039",
    "HYG Star ID": 118741,
    "Gliese Catalog": "NN 3654",
    "Distance in Parsecs": 15.625,
    "mag": 13.7,
    "Absolute Visual Magnitude": 12.731,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.555335,
    "y": 2.596226,
    "z": -7.324831,
    "vx": 0.00003529,
    "vy": 0.00003175,
    "vz": -0.00005408,
    "rarad": 2.95237232850533,
    "decrad": -0.48791955917675,
    "pmrarad": -0.00000242018989333333,
    "pmdecrad": -0.000003918603535,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118741,
    "lum": 0.000704044321469975,
    "ra": 11.277232,
    "dec": -27.955731,
    "pmra": -499.2,
    "pmdec": -808.27,
    "rv": 0,
    "Asset Name": "ASTRID 001039",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Curly",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003529,
    "y_1": 0.00003175,
    "z_1": -0.00005408,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 118741,
    "Gliese Catalog_1": "NN 3654"
  },
  {
    "Astrid #": "ASTRID 001040",
    "HYG Star ID": 118774,
    "Gliese Catalog": "NN 3685A",
    "Distance in Parsecs": 15.625,
    "mag": 13.25,
    "Absolute Visual Magnitude": 12.281,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.602272,
    "y": 0.839602,
    "z": 0.069357,
    "vx": 0.00000114,
    "vy": 0.0000217,
    "vz": -0.00000664,
    "rarad": 3.08783170890159,
    "decrad": 0.00443881825365,
    "pmrarad": -0.00000139088196814583,
    "pmdecrad": -4.25234927e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118774,
    "base": "NN 3685",
    "lum": 0.00106561420215851,
    "ra": 11.794648,
    "dec": 0.254326,
    "pmra": -286.89,
    "pmdec": -87.71,
    "rv": 0,
    "Asset Name": "ASTRID 001040",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Fedora",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000114,
    "y_1": 0.0000217,
    "z_1": -0.00000664,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 118774,
    "Gliese Catalog_1": "NN 3685A"
  },
  {
    "Astrid #": "ASTRID 001041",
    "HYG Star ID": 118775,
    "Gliese Catalog": "NN 3686B",
    "Distance in Parsecs": 15.625,
    "mag": 17.6,
    "Absolute Visual Magnitude": 16.631,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.602337,
    "y": 0.838468,
    "z": 0.068447,
    "vx": 0.00000114,
    "vy": 0.0000217,
    "vz": -0.00000664,
    "rarad": 3.08790441090159,
    "decrad": 0.00438061825365,
    "pmrarad": -0.00000139088196814583,
    "pmdecrad": -4.25234927e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118775,
    "base": "NN 3686",
    "lum": 0.0000193909907861484,
    "ra": 11.794926,
    "dec": 0.250991,
    "pmra": -286.89,
    "pmdec": -87.71,
    "rv": 0,
    "Asset Name": "ASTRID 001041",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Afro",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000114,
    "y_1": 0.0000217,
    "z_1": -0.00000664,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 118775,
    "Gliese Catalog_1": "NN 3686B"
  },
  {
    "Astrid #": "ASTRID 001042",
    "HYG Star ID": 118932,
    "Gliese Catalog": "NN 3815",
    "Distance in Parsecs": 15.625,
    "mag": 15.69,
    "Absolute Visual Magnitude": 14.721,
    "Spectral Type": "m",
    "Color Index": 1.9,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.42054,
    "y": -2.907698,
    "z": 14.363276,
    "vx": -0.00004659,
    "vy": 0.00000898,
    "vz": -0.00001575,
    "rarad": 3.63409925303162,
    "decrad": 1.16617006034824,
    "pmrarad": -0.00000191675936744444,
    "pmdecrad": -0.000002562153035,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118932,
    "lum": 0.000112615974690786,
    "ra": 13.881237,
    "dec": 66.816623,
    "pmra": -395.36,
    "pmdec": -528.48,
    "rv": 0,
    "Asset Name": "ASTRID 001042",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "Unkempt",
    "Outfit": "Emperor",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004659,
    "y_1": 0.00000898,
    "z_1": -0.00001575,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 118932,
    "Gliese Catalog_1": "NN 3815"
  },
  {
    "Astrid #": "ASTRID 001043",
    "HYG Star ID": 119085,
    "Gliese Catalog": "Gl 626.2",
    "Distance in Parsecs": 15.625,
    "mag": 13.85,
    "Absolute Visual Magnitude": 12.881,
    "Spectral Type": "DZA6",
    "Color Index": 0.17,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.869412,
    "y": -11.531398,
    "z": 9.353858,
    "vx": -0.00002823,
    "vy": 0.00002582,
    "vz": 0.00005816,
    "rarad": 4.31285793973657,
    "decrad": 0.64175215492855,
    "pmrarad": -0.0000023073252685,
    "pmdecrad": 0.000003472778571,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119085,
    "lum": 0.000613196969715681,
    "ra": 16.473904,
    "dec": 36.76969,
    "pmra": -475.92,
    "pmdec": 716.31,
    "rv": 24,
    "Asset Name": "ASTRID 001043",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Determined Shinobi",
    "Hair": "High Bun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002823,
    "y_1": 0.00002582,
    "z_1": 0.00005816,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 119085,
    "Gliese Catalog_1": "Gl 626.2"
  },
  {
    "Astrid #": "ASTRID 001044",
    "HYG Star ID": 119232,
    "Gliese Catalog": "NN 4069",
    "Distance in Parsecs": 15.625,
    "mag": 13.42,
    "Absolute Visual Magnitude": 12.451,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.170981,
    "y": -11.823748,
    "z": 9.981304,
    "vx": -0.00001706,
    "vy": -0.00001139,
    "vz": -0.00000978,
    "rarad": 4.89399631399475,
    "decrad": 0.6929420685557,
    "pmrarad": -0.00000120578010490972,
    "pmdecrad": -8.13308886e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119232,
    "lum": 0.000911171233042918,
    "ra": 18.693689,
    "dec": 39.702656,
    "pmra": -248.71,
    "pmdec": -167.76,
    "rv": 0,
    "Asset Name": "ASTRID 001044",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Hachimaki",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001706,
    "y_1": -0.00001139,
    "z_1": -0.00000978,
    "Distance in Parsecs_1": 15.625,
    "HYG Star ID_1": 119232,
    "Gliese Catalog_1": "NN 4069"
  },
  {
    "Astrid #": "ASTRID 001045",
    "HYG Star ID": 4012,
    "Hipparcos Catalogue": 4022,
    "Henry Draper Catalogue": 4967,
    "Gliese Catalog": "Gl 40A",
    "Distance in Parsecs": 15.6348,
    "mag": 8.95,
    "Absolute Visual Magnitude": 7.98,
    "Spectral Type": "K5V",
    "Color Index": 1.29,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.038591,
    "y": 3.213138,
    "z": -6.086459,
    "vx": -0.00000413,
    "vy": 0.00004665,
    "vz": -0.00002536,
    "rarad": 0.225001199742077,
    "decrad": -0.399856063918726,
    "pmrarad": 0.00000296739909457638,
    "pmdecrad": -0.000001335419283,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 4012,
    "base": "Gl  40",
    "lum": 0.0559757601495109,
    "ra": 0.859441,
    "dec": -22.910065,
    "pmra": 612.07,
    "pmdec": -275.45,
    "rv": 15.4,
    "Asset Name": "ASTRID 001045",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Sweet Nap",
    "Hair": "Pompadour",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000413,
    "y_1": 0.00004665,
    "z_1": -0.00002536,
    "Distance in Parsecs_1": 15.6348,
    "HYG Star ID_1": 4012,
    "Hipparcos Catalogue_1": 4022,
    "Henry Draper Catalogue_1": 4967,
    "Gliese Catalog_1": "Gl 40A"
  },
  {
    "Astrid #": "ASTRID 001046",
    "HYG Star ID": 88469,
    "Hipparcos Catalogue": 88745,
    "Henry Draper Catalogue": 165908,
    "Harvard Revised Catalogue": 6775,
    "Gliese Catalog": "Gl 704A",
    "Distance in Parsecs": 15.6421,
    "mag": 5.05,
    "Absolute Visual Magnitude": 4.079,
    "Spectral Type": "F7V",
    "Color Index": 0.528,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.412832,
    "y": -13.462756,
    "z": 7.953585,
    "vx": -0.00000774,
    "vy": 0.00000266,
    "vz": 0.00000813,
    "rarad": 4.74304485942858,
    "decrad": 0.533409961447708,
    "pmrarad": -4.89322447784722e-7,
    "pmdecrad": 5.41536881e-7,
    "flam": 99,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 88469,
    "base": "Gl 704",
    "lum": 2.03422974321172,
    "bf": "99    Her",
    "ra": 18.117097,
    "dec": 30.56214,
    "pmra": -100.93,
    "pmdec": 111.7,
    "rv": 1.6,
    "Asset Name": "ASTRID 001046",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Ponytail",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000774,
    "y_1": 0.00000266,
    "z_1": 0.00000813,
    "Distance in Parsecs_1": 15.6421,
    "HYG Star ID_1": 88469,
    "Hipparcos Catalogue_1": 88745,
    "Henry Draper Catalogue_1": 165908,
    "Harvard Revised Catalogue_1": 6775,
    "Gliese Catalog_1": "Gl 704A"
  },
  {
    "Astrid #": "ASTRID 001047",
    "HYG Star ID": 118860,
    "Gliese Catalog": "Gl 492",
    "Distance in Parsecs": 15.6495,
    "mag": 15.79,
    "Absolute Visual Magnitude": 14.817,
    "Spectral Type": "DC9",
    "Color Index": 0.64,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -15.085761,
    "y": -4.052893,
    "z": 0.949078,
    "vx": -0.00001253,
    "vy": 0.00003146,
    "vz": -0.00006448,
    "rarad": 3.40405237689584,
    "decrad": 0.0606831303033,
    "pmrarad": -0.00000214908208316666,
    "pmdecrad": -0.000004128333934,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118860,
    "lum": 0.000103086074006262,
    "ra": 13.002522,
    "dec": 3.476887,
    "pmra": -443.28,
    "pmdec": -851.53,
    "rv": 0,
    "Asset Name": "ASTRID 001047",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Stunned",
    "Hair": "Elegant",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001253,
    "y_1": 0.00003146,
    "z_1": -0.00006448,
    "Distance in Parsecs_1": 15.6495,
    "HYG Star ID_1": 118860,
    "Gliese Catalog_1": "Gl 492"
  },
  {
    "Astrid #": "ASTRID 001048",
    "HYG Star ID": 66553,
    "Hipparcos Catalogue": 66765,
    "Henry Draper Catalogue": 118972,
    "Gliese Catalog": "GJ 1175",
    "Distance in Parsecs": 15.6544,
    "mag": 6.92,
    "Absolute Visual Magnitude": 5.947,
    "Spectral Type": "K1V",
    "Color Index": 0.855,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.671902,
    "y": -5.509155,
    "z": -8.858677,
    "vx": 0.00001312,
    "vy": -0.00001109,
    "vz": -0.00001039,
    "rarad": 3.58258976337289,
    "decrad": -0.601512990191338,
    "pmrarad": 9.98328331e-7,
    "pmdecrad": -8.04936153e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 66553,
    "lum": 0.3640826637894,
    "ra": 13.684485,
    "dec": -34.464156,
    "pmra": 205.92,
    "pmdec": -166.03,
    "rv": 0,
    "Asset Name": "ASTRID 001048",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Frizzy",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001312,
    "y_1": -0.00001109,
    "z_1": -0.00001039,
    "Distance in Parsecs_1": 15.6544,
    "HYG Star ID_1": 66553,
    "Hipparcos Catalogue_1": 66765,
    "Henry Draper Catalogue_1": 118972,
    "Gliese Catalog_1": "GJ 1175"
  },
  {
    "Astrid #": "ASTRID 001049",
    "HYG Star ID": 119201,
    "Gliese Catalog": "Gl 704B",
    "Distance in Parsecs": 15.6544,
    "mag": 8.45,
    "Absolute Visual Magnitude": 7.477,
    "Spectral Type": "K5 V",
    "Color Index": 1.1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.413521,
    "y": -13.473534,
    "z": 7.959466,
    "vx": -0.00000632,
    "vy": 0.00000263,
    "vz": 0.00000478,
    "rarad": 4.7430739131262,
    "decrad": 0.5333828161205,
    "pmrarad": -3.98177475840277e-7,
    "pmdecrad": 3.5476241e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 88469,
    "base": "Gl 704",
    "lum": 0.0889610704409228,
    "ra": 18.117208,
    "dec": 30.560584,
    "pmra": -82.13,
    "pmdec": 73.17,
    "rv": 0,
    "Asset Name": "ASTRID 001049",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Braided Ponytail",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000632,
    "y_1": 0.00000263,
    "z_1": 0.00000478,
    "Distance in Parsecs_1": 15.6544,
    "HYG Star ID_1": 119201,
    "Gliese Catalog_1": "Gl 704B"
  },
  {
    "Astrid #": "ASTRID 001050",
    "HYG Star ID": 118060,
    "Gliese Catalog": "GJ 1037",
    "Distance in Parsecs": 15.674,
    "mag": 13.82,
    "Absolute Visual Magnitude": 12.844,
    "Spectral Type": "DQ6",
    "Color Index": 0.12,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 14.190357,
    "y": 5.024761,
    "z": 4.365756,
    "vx": 0.00001346,
    "vy": 0.00000321,
    "vz": -0.00004741,
    "rarad": 0.340320062396119,
    "decrad": 0.2822682731246,
    "pmrarad": -9.34720776111111e-8,
    "pmdecrad": -0.000003149897508,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118060,
    "lum": 0.000634453795775714,
    "ra": 1.299927,
    "dec": 16.172781,
    "pmra": -19.28,
    "pmdec": -649.71,
    "rv": 0,
    "Asset Name": "ASTRID 001050",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Braided Bun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001346,
    "y_1": 0.00000321,
    "z_1": -0.00004741,
    "Distance in Parsecs_1": 15.674,
    "HYG Star ID_1": 118060,
    "Gliese Catalog_1": "GJ 1037"
  },
  {
    "Astrid #": "ASTRID 001051",
    "HYG Star ID": 118755,
    "Gliese Catalog": "NN 3666",
    "Distance in Parsecs": 15.674,
    "mag": 12.56,
    "Absolute Visual Magnitude": 11.584,
    "Spectral Type": "M3",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.285776,
    "y": 2.084893,
    "z": 2.769941,
    "vx": 0.00001486,
    "vy": 0.00005469,
    "vz": 0.00004093,
    "rarad": 3.00603766702127,
    "decrad": 0.1776549947496,
    "pmrarad": -0.00000358543957454861,
    "pmdecrad": 0.000002653074992,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118755,
    "lum": 0.00202488330653056,
    "ra": 11.482218,
    "dec": 10.178881,
    "pmra": -739.55,
    "pmdec": 547.24,
    "rv": 0,
    "Asset Name": "ASTRID 001051",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Guilty Laugh",
    "Hair": "Undercut",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001486,
    "y_1": 0.00005469,
    "z_1": 0.00004093,
    "Distance in Parsecs_1": 15.674,
    "HYG Star ID_1": 118755,
    "Gliese Catalog_1": "NN 3666"
  },
  {
    "Astrid #": "ASTRID 001052",
    "HYG Star ID": 36246,
    "Hipparcos Catalogue": 36349,
    "Distance in Parsecs": 15.6937,
    "mag": 9.96,
    "Absolute Visual Magnitude": 8.981,
    "Spectral Type": "M1V:e...",
    "Color Index": 1.442,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.125546,
    "y": 12.55097,
    "z": -7.905325,
    "vx": 0.00001113,
    "vy": -9e-7,
    "vz": -0.00000864,
    "rarad": 1.95850542936845,
    "decrad": -0.527906552993707,
    "pmrarad": -6.34815033319444e-7,
    "pmdecrad": -6.37142138e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 36246,
    "lum": 0.022263836295529,
    "var": "V372",
    "var_min": 10.041,
    "var_max": 9.881,
    "ra": 7.48094,
    "dec": -30.246817,
    "pmra": -130.94,
    "pmdec": -131.42,
    "rv": 0,
    "Asset Name": "ASTRID 001052",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Wavy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001113,
    "y_1": -9e-7,
    "z_1": -0.00000864,
    "Distance in Parsecs_1": 15.6937,
    "HYG Star ID_1": 36246,
    "Hipparcos Catalogue_1": 36349
  },
  {
    "Astrid #": "ASTRID 001053",
    "HYG Star ID": 117984,
    "Gliese Catalog": "GJ 1010B",
    "Distance in Parsecs": 15.6986,
    "mag": 14,
    "Absolute Visual Magnitude": 13.021,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.462071,
    "y": 0.356392,
    "z": 15.307942,
    "vx": 0.00000319,
    "vy": -0.00006079,
    "vz": 7.1e-7,
    "rarad": 0.103260286175302,
    "decrad": 1.34723977608955,
    "pmrarad": -0.00000387317649395833,
    "pmdecrad": 2.02981791e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 1856,
    "base": "GJ 1010",
    "lum": 0.00053901394363255,
    "ra": 0.394425,
    "dec": 77.191153,
    "pmra": -798.9,
    "pmdec": 41.87,
    "rv": 0,
    "Asset Name": "ASTRID 001053",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Greasy",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000319,
    "y_1": -0.00006079,
    "z_1": 7.1e-7,
    "Distance in Parsecs_1": 15.6986,
    "HYG Star ID_1": 117984,
    "Gliese Catalog_1": "GJ 1010B"
  },
  {
    "Astrid #": "ASTRID 001054",
    "HYG Star ID": 79851,
    "Hipparcos Catalogue": 80093,
    "Gliese Catalog": "Gl 619",
    "Distance in Parsecs": 15.7183,
    "mag": 8.99,
    "Absolute Visual Magnitude": 8.008,
    "Spectral Type": "M0",
    "Color Index": 1.286,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.975073,
    "y": -10.776797,
    "z": 10.304192,
    "vx": -0.000001,
    "vy": 2.5e-7,
    "vz": 0.00001148,
    "rarad": 4.27989216182859,
    "decrad": 0.714912136113033,
    "pmrarad": -6.48195890902777e-8,
    "pmdecrad": 5.43670061e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 79851,
    "lum": 0.0545506588170373,
    "ra": 16.347984,
    "dec": 40.961448,
    "pmra": -13.37,
    "pmdec": 112.14,
    "rv": 7.5,
    "Asset Name": "ASTRID 001054",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bad Dreams",
    "Hair": "Manbun",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.000001,
    "y_1": 2.5e-7,
    "z_1": 0.00001148,
    "Distance in Parsecs_1": 15.7183,
    "HYG Star ID_1": 79851,
    "Hipparcos Catalogue_1": 80093,
    "Gliese Catalog_1": "Gl 619"
  },
  {
    "Astrid #": "ASTRID 001055",
    "HYG Star ID": 118738,
    "Gliese Catalog": "Gl 421C",
    "Distance in Parsecs": 15.7233,
    "mag": 13.64,
    "Absolute Visual Magnitude": 12.657,
    "Spectral Type": "M3.5",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.66008,
    "y": 2.898633,
    "z": -4.89194,
    "vx": -0.00000209,
    "vy": -0.00001041,
    "vz": -0.00005911,
    "rarad": 2.9463858021525,
    "decrad": -0.316359300902599,
    "pmrarad": 6.75103050173611e-7,
    "pmdecrad": -0.000003572878052,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 54801,
    "base": "Gl 421",
    "lum": 0.000753702576688425,
    "ra": 11.254365,
    "dec": -18.126053,
    "pmra": 139.25,
    "pmdec": -736.96,
    "rv": 18,
    "Asset Name": "ASTRID 001055",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Skater Helmet",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000209,
    "y_1": -0.00001041,
    "z_1": -0.00005911,
    "Distance in Parsecs_1": 15.7233,
    "HYG Star ID_1": 118738,
    "Gliese Catalog_1": "Gl 421C"
  },
  {
    "Astrid #": "ASTRID 001056",
    "HYG Star ID": 116714,
    "Hipparcos Catalogue": 117081,
    "Henry Draper Catalogue": 222834,
    "Distance in Parsecs": 15.7332,
    "mag": 9.01,
    "Absolute Visual Magnitude": 8.026,
    "Spectral Type": "G:+...",
    "Color Index": 0.535,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.960292,
    "y": -0.968687,
    "z": -7.190652,
    "vx": 0.00000395,
    "vy": 0.00000544,
    "vz": 0.00000694,
    "rarad": 6.21390718440869,
    "decrad": -0.474660920190482,
    "pmrarad": 3.62446707583333e-7,
    "pmdecrad": 4.96206802e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 116714,
    "lum": 0.0536537399519851,
    "ra": 23.735377,
    "dec": -27.196067,
    "pmra": 74.76,
    "pmdec": 102.35,
    "rv": 0,
    "Asset Name": "ASTRID 001056",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Halo Boy",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00000395,
    "y_1": 0.00000544,
    "z_1": 0.00000694,
    "Distance in Parsecs_1": 15.7332,
    "HYG Star ID_1": 116714,
    "Hipparcos Catalogue_1": 117081,
    "Henry Draper Catalogue_1": 222834
  },
  {
    "Astrid #": "ASTRID 001057",
    "HYG Star ID": 51377,
    "Hipparcos Catalogue": 51525,
    "Gliese Catalog": "Gl 397",
    "Distance in Parsecs": 15.7356,
    "mag": 8.85,
    "Absolute Visual Magnitude": 7.866,
    "Spectral Type": "K7V",
    "Color Index": 1.33,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.210747,
    "y": 4.15635,
    "z": 11.22859,
    "vx": -0.00002952,
    "vy": 0.00005873,
    "vz": -0.00001432,
    "rarad": 2.75502830869703,
    "decrad": 0.794579479143632,
    "pmrarad": -0.00000274986319611111,
    "pmdecrad": -0.000002881926442,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 51377,
    "lum": 0.062172738929396,
    "ra": 10.523433,
    "dec": 45.526051,
    "pmra": -567.2,
    "pmdec": -594.44,
    "rv": 23.9,
    "Asset Name": "ASTRID 001057",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Braided Bun",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00002952,
    "y_1": 0.00005873,
    "z_1": -0.00001432,
    "Distance in Parsecs_1": 15.7356,
    "HYG Star ID_1": 51377,
    "Hipparcos Catalogue_1": 51525,
    "Gliese Catalog_1": "Gl 397"
  },
  {
    "Astrid #": "ASTRID 001058",
    "HYG Star ID": 32473,
    "Hipparcos Catalogue": 32560,
    "Gliese Catalog": "Gl 246",
    "Distance in Parsecs": 15.7406,
    "mag": 12.06,
    "Absolute Visual Magnitude": 11.075,
    "Spectral Type": "DAn",
    "Color Index": -0.098,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.576389,
    "y": 12.217068,
    "z": 9.586163,
    "vx": -0.00000551,
    "vy": 0.00010959,
    "vz": -0.00000679,
    "rarad": 1.77863789323267,
    "decrad": 0.654775256273313,
    "pmrarad": -0.00000109403055154166,
    "pmdecrad": -0.000004535625907,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 32473,
    "lum": 0.00323593656929628,
    "var_min": 12.159,
    "var_max": 11.929,
    "ra": 6.793896,
    "dec": 37.515859,
    "pmra": -225.66,
    "pmdec": -935.54,
    "rv": 80,
    "Asset Name": "ASTRID 001058",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Pompadour",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000551,
    "y_1": 0.00010959,
    "z_1": -0.00000679,
    "Distance in Parsecs_1": 15.7406,
    "HYG Star ID_1": 32473,
    "Hipparcos Catalogue_1": 32560,
    "Gliese Catalog_1": "Gl 246"
  },
  {
    "Astrid #": "ASTRID 001059",
    "HYG Star ID": 119546,
    "Gliese Catalog": "NN 4312",
    "Distance in Parsecs": 15.748,
    "mag": 12.45,
    "Absolute Visual Magnitude": 11.464,
    "Spectral Type": "m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.579042,
    "y": -1.301233,
    "z": 14.669036,
    "vx": 0.00001745,
    "vy": 0.0000837,
    "vz": 8.1e-7,
    "rarad": 6.05357117054651,
    "decrad": 1.1984755508837,
    "pmrarad": 0.00000542807093023611,
    "pmdecrad": 1.42137674e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119546,
    "lum": 0.00226151774610836,
    "ra": 23.122939,
    "dec": 68.667591,
    "pmra": 1119.62,
    "pmdec": 29.32,
    "rv": 0,
    "Asset Name": "ASTRID 001059",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Prince",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001745,
    "y_1": 0.0000837,
    "z_1": 8.1e-7,
    "Distance in Parsecs_1": 15.748,
    "HYG Star ID_1": 119546,
    "Gliese Catalog_1": "NN 4312"
  },
  {
    "Astrid #": "ASTRID 001060",
    "HYG Star ID": 3899,
    "Hipparcos Catalogue": 3909,
    "Henry Draper Catalogue": 4813,
    "Harvard Revised Catalogue": 235,
    "Gliese Catalog": "Gl 37",
    "Distance in Parsecs": 15.753,
    "mag": 5.17,
    "Absolute Visual Magnitude": 4.183,
    "Spectral Type": "F7IV-V",
    "Color Index": 0.514,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.113168,
    "y": 3.359263,
    "z": -2.909778,
    "vx": 0.00000862,
    "vy": -0.00001566,
    "vz": -0.00001869,
    "rarad": 0.218718356229659,
    "decrad": -0.185778528698246,
    "pmrarad": -0.00000108923089610416,
    "pmdecrad": -0.000001107508371,
    "bayer": "Phi-2",
    "flam": 19,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3899,
    "lum": 1.84841719536965,
    "bf": "19Phi2Cet",
    "ra": 0.835443,
    "dec": -10.644326,
    "pmra": -224.67,
    "pmdec": -228.44,
    "rv": 8.2,
    "Asset Name": "ASTRID 001060",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Charmer",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000862,
    "y_1": -0.00001566,
    "z_1": -0.00001869,
    "Distance in Parsecs_1": 15.753,
    "HYG Star ID_1": 3899,
    "Hipparcos Catalogue_1": 3909,
    "Henry Draper Catalogue_1": 4813,
    "Harvard Revised Catalogue_1": 235,
    "Gliese Catalog_1": "Gl 37"
  },
  {
    "Astrid #": "ASTRID 001061",
    "HYG Star ID": 47484,
    "Hipparcos Catalogue": 47619,
    "Gliese Catalog": "NN 3563",
    "Distance in Parsecs": 15.7555,
    "mag": 11.94,
    "Absolute Visual Magnitude": 10.953,
    "Color Index": 1.525,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -12.2815,
    "y": 8.394011,
    "z": -5.190482,
    "vx": 0.00002478,
    "vy": 0.00002671,
    "vz": -0.00001544,
    "rarad": 2.54204964555817,
    "decrad": -0.33570974995407,
    "pmrarad": -0.00000228759335170138,
    "pmdecrad": -0.000001037937608,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 47484,
    "lum": 0.00362076217756672,
    "var_min": 12.062,
    "var_max": 11.852,
    "ra": 9.709914,
    "dec": -19.234752,
    "pmra": -471.85,
    "pmdec": -214.09,
    "rv": 0,
    "Asset Name": "ASTRID 001061",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Little Crown",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00002478,
    "y_1": 0.00002671,
    "z_1": -0.00001544,
    "Distance in Parsecs_1": 15.7555,
    "HYG Star ID_1": 47484,
    "Hipparcos Catalogue_1": 47619,
    "Gliese Catalog_1": "NN 3563"
  },
  {
    "Astrid #": "ASTRID 001062",
    "HYG Star ID": 14633,
    "Hipparcos Catalogue": 14669,
    "Gliese Catalog": "Gl 125",
    "Distance in Parsecs": 15.7604,
    "mag": 10.11,
    "Absolute Visual Magnitude": 9.122,
    "Spectral Type": "M2",
    "Color Index": 1.473,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.449241,
    "y": 8.094836,
    "z": 11.285833,
    "vx": 0.00003388,
    "vy": -0.0000116,
    "vz": -0.00002732,
    "rarad": 0.826915304488558,
    "decrad": 0.798186936534983,
    "pmrarad": -0.00000208028702189583,
    "pmdecrad": -0.000001864738859,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 14633,
    "lum": 0.0195523966962088,
    "ra": 3.158584,
    "dec": 45.732743,
    "pmra": -429.09,
    "pmdec": -384.63,
    "rv": -9.3,
    "Asset Name": "ASTRID 001062",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Gimme Blood",
    "Hair": "Medium Bob",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00003388,
    "y_1": -0.0000116,
    "z_1": -0.00002732,
    "Distance in Parsecs_1": 15.7604,
    "HYG Star ID_1": 14633,
    "Hipparcos Catalogue_1": 14669,
    "Gliese Catalog_1": "Gl 125"
  },
  {
    "Astrid #": "ASTRID 001063",
    "HYG Star ID": 95024,
    "Hipparcos Catalogue": 95319,
    "Henry Draper Catalogue": 182488,
    "Harvard Revised Catalogue": 7368,
    "Gliese Catalog": "Gl 758",
    "Distance in Parsecs": 15.7604,
    "mag": 6.37,
    "Absolute Visual Magnitude": 5.382,
    "Spectral Type": "G8V",
    "Color Index": 0.804,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.701552,
    "y": -12.31749,
    "z": 8.63477,
    "vx": -0.00000286,
    "vy": 0.00002507,
    "vz": -0.00000113,
    "rarad": 5.077017578235,
    "decrad": 0.57983269126142,
    "pmrarad": 3.97741143527777e-7,
    "pmdecrad": 7.89858448e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 95024,
    "lum": 0.612632454443864,
    "ra": 19.392779,
    "dec": 33.221966,
    "pmra": 82.04,
    "pmdec": 162.92,
    "rv": -20.6,
    "Asset Name": "ASTRID 001063",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Round Brush",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000286,
    "y_1": 0.00002507,
    "z_1": -0.00000113,
    "Distance in Parsecs_1": 15.7604,
    "HYG Star ID_1": 95024,
    "Hipparcos Catalogue_1": 95319,
    "Henry Draper Catalogue_1": 182488,
    "Harvard Revised Catalogue_1": 7368,
    "Gliese Catalog_1": "Gl 758"
  },
  {
    "Astrid #": "ASTRID 001064",
    "HYG Star ID": 98478,
    "Hipparcos Catalogue": 98792,
    "Henry Draper Catalogue": 190404,
    "Gliese Catalog": "Gl 778",
    "Distance in Parsecs": 15.7654,
    "mag": 7.28,
    "Absolute Visual Magnitude": 6.291,
    "Spectral Type": "K1V",
    "Color Index": 0.815,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.448189,
    "y": -12.411958,
    "z": 6.246207,
    "vx": -0.00005237,
    "vy": -0.00006169,
    "vz": -0.00006476,
    "rarad": 5.25287234732675,
    "decrad": 0.407371824536641,
    "pmrarad": -0.00000486190551408333,
    "pmdecrad": -0.000004424167241,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 98478,
    "lum": 0.265216170550787,
    "ra": 20.064494,
    "dec": 23.340686,
    "pmra": -1002.84,
    "pmdec": -912.55,
    "rv": -1.8,
    "Asset Name": "ASTRID 001064",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Wavy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": -0.00005237,
    "y_1": -0.00006169,
    "z_1": -0.00006476,
    "Distance in Parsecs_1": 15.7654,
    "HYG Star ID_1": 98478,
    "Hipparcos Catalogue_1": 98792,
    "Henry Draper Catalogue_1": 190404,
    "Gliese Catalog_1": "Gl 778"
  },
  {
    "Astrid #": "ASTRID 001065",
    "HYG Star ID": 119274,
    "Gliese Catalog": "Gl 750B",
    "Distance in Parsecs": 15.7654,
    "mag": 10.1,
    "Absolute Visual Magnitude": 9.111,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.605054,
    "y": -10.363874,
    "z": -11.319962,
    "vx": 0.00000695,
    "vy": 0.00002591,
    "vz": -0.00002152,
    "rarad": 5.04712461059738,
    "decrad": -0.80096154414335,
    "pmrarad": 9.56391947631944e-7,
    "pmdecrad": -0.000001960862867,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 2,
    "comp_primary": 94444,
    "base": "Gl 750",
    "lum": 0.0197514962206075,
    "ra": 19.278596,
    "dec": -45.891716,
    "pmra": 197.27,
    "pmdec": -404.46,
    "rv": 0,
    "Asset Name": "ASTRID 001065",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Greasy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00000695,
    "y_1": 0.00002591,
    "z_1": -0.00002152,
    "Distance in Parsecs_1": 15.7654,
    "HYG Star ID_1": 119274,
    "Gliese Catalog_1": "Gl 750B"
  },
  {
    "Astrid #": "ASTRID 001066",
    "HYG Star ID": 118267,
    "Gliese Catalog": "Gl 157B",
    "Distance in Parsecs": 15.7704,
    "mag": 11.61,
    "Absolute Visual Magnitude": 10.621,
    "Spectral Type": "dM3  e",
    "Color Index": 1.47,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.033704,
    "y": 13.567837,
    "z": -0.318598,
    "vx": 0.00001982,
    "vy": 0.00000464,
    "vz": -0.00001128,
    "rarad": 1.03619567725343,
    "decrad": -0.02020273161935,
    "pmrarad": -9.31714931291666e-7,
    "pmdecrad": -6.96992387e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 2,
    "comp_primary": 18465,
    "base": "Gl 157",
    "lum": 0.00491586559156861,
    "ra": 3.957976,
    "dec": -1.157531,
    "pmra": -192.18,
    "pmdec": -143.76,
    "rv": 14,
    "Asset Name": "ASTRID 001066",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Bandana",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00001982,
    "y_1": 0.00000464,
    "z_1": -0.00001128,
    "Distance in Parsecs_1": 15.7704,
    "HYG Star ID_1": 118267,
    "Gliese Catalog_1": "Gl 157B"
  },
  {
    "Astrid #": "ASTRID 001067",
    "HYG Star ID": 82670,
    "Hipparcos Catalogue": 82926,
    "Distance in Parsecs": 15.7828,
    "mag": 11.18,
    "Absolute Visual Magnitude": 10.189,
    "Spectral Type": "M3Ve+...",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.334739,
    "y": -11.786578,
    "z": -9.952532,
    "vx": 0.00000628,
    "vy": 0.00000371,
    "vz": -0.0000065,
    "rarad": 4.43666745689055,
    "decrad": -0.682317715030526,
    "pmrarad": 3.189589204375e-7,
    "pmdecrad": -5.30289203e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 82670,
    "lum": 0.00731812797674455,
    "var": "V914",
    "var_min": 11.283,
    "var_max": 11.083,
    "ra": 16.946821,
    "dec": -39.093925,
    "pmra": 65.79,
    "pmdec": -109.38,
    "rv": 0,
    "Asset Name": "ASTRID 001067",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Frizzy",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000628,
    "y_1": 0.00000371,
    "z_1": -0.0000065,
    "Distance in Parsecs_1": 15.7828,
    "HYG Star ID_1": 82670,
    "Hipparcos Catalogue_1": 82926
  },
  {
    "Astrid #": "ASTRID 001068",
    "HYG Star ID": 119366,
    "Gliese Catalog": "Gl 802",
    "Distance in Parsecs": 15.7978,
    "mag": 14.68,
    "Absolute Visual Magnitude": 13.687,
    "Spectral Type": "dM5  e",
    "Color Index": 1.79,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.871867,
    "y": -6.795771,
    "z": 12.995078,
    "vx": -0.00002712,
    "vy": 0.00013511,
    "vz": 0.0000544,
    "rarad": 5.42481909238889,
    "decrad": 0.966054713453349,
    "pmrarad": 0.00000429006777786805,
    "pmdecrad": 0.000008206189067,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119366,
    "lum": 0.000291877084819714,
    "ra": 20.721283,
    "dec": 55.350858,
    "pmra": 884.89,
    "pmdec": 1692.65,
    "rv": -23,
    "Asset Name": "ASTRID 001068",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Bob",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002712,
    "y_1": 0.00013511,
    "z_1": 0.0000544,
    "Distance in Parsecs_1": 15.7978,
    "HYG Star ID_1": 119366,
    "Gliese Catalog_1": "Gl 802"
  },
  {
    "Astrid #": "ASTRID 001069",
    "HYG Star ID": 118485,
    "Henry Draper Catalogue": 60178,
    "Harvard Revised Catalogue": 2890,
    "Gliese Catalog": "Gl 278B",
    "Distance in Parsecs": 15.8053,
    "mag": 2.85,
    "Absolute Visual Magnitude": 1.856,
    "Spectral Type": "A m",
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -5.383676,
    "y": 12.292323,
    "z": 8.349873,
    "vx": 0.00001024,
    "vy": 0.00000769,
    "vz": -0.00000703,
    "rarad": 1.98360871683115,
    "decrad": 0.5565945679503,
    "pmrarad": -7.88743376895833e-7,
    "pmdecrad": -4.75800994e-7,
    "bayer": "Alp",
    "flam": 66,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 2,
    "comp_primary": 36744,
    "base": "Gl 278",
    "lum": 15.7615890496383,
    "bf": "66Alp Gem",
    "ra": 7.576827,
    "dec": 31.89052,
    "pmra": -162.69,
    "pmdec": -98.14,
    "rv": -1.2,
    "Asset Name": "ASTRID 001069",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Unkempt",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00001024,
    "y_1": 0.00000769,
    "z_1": -0.00000703,
    "Distance in Parsecs_1": 15.8053,
    "HYG Star ID_1": 118485,
    "Henry Draper Catalogue_1": 60178,
    "Harvard Revised Catalogue_1": 2890,
    "Gliese Catalog_1": "Gl 278B"
  },
  {
    "Astrid #": "ASTRID 001070",
    "HYG Star ID": 118486,
    "Gliese Catalog": "Gl 278C",
    "Distance in Parsecs": 15.8053,
    "mag": 9.07,
    "Absolute Visual Magnitude": 8.076,
    "Spectral Type": "M0.5Ve",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.385572,
    "y": 12.29467,
    "z": 8.345127,
    "vx": 0.00001305,
    "vy": 0.00000878,
    "vz": -0.00000819,
    "rarad": 1.98366966436267,
    "decrad": 0.5562422782493,
    "pmrarad": -9.79032746513888e-7,
    "pmdecrad": -5.33775014e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 3,
    "comp_primary": 36744,
    "base": "Gl 278",
    "lum": 0.0512389238664679,
    "ra": 7.57706,
    "dec": 31.870335,
    "pmra": -201.94,
    "pmdec": -110.1,
    "rv": -1.9,
    "Asset Name": "ASTRID 001070",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Curly",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00001305,
    "y_1": 0.00000878,
    "z_1": -0.00000819,
    "Distance in Parsecs_1": 15.8053,
    "HYG Star ID_1": 118486,
    "Gliese Catalog_1": "Gl 278C"
  },
  {
    "Astrid #": "ASTRID 001071",
    "HYG Star ID": 56493,
    "Hipparcos Catalogue": 56662,
    "Gliese Catalog": "Gl 433.1",
    "Distance in Parsecs": 15.8078,
    "mag": 12.49,
    "Absolute Visual Magnitude": 11.496,
    "Spectral Type": "DA",
    "Color Index": -0.072,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -13.649016,
    "y": 1.369265,
    "z": 7.855955,
    "vx": 9e-7,
    "vy": 0.00001122,
    "vz": -3.9e-7,
    "rarad": 3.04160840432615,
    "decrad": 0.520100065290729,
    "pmrarad": -7.12191296736111e-7,
    "pmdecrad": -2.8652488e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 56493,
    "lum": 0.00219583650097318,
    "var_min": 12.665,
    "var_max": 12.355,
    "ra": 11.618088,
    "dec": 29.799539,
    "pmra": -146.9,
    "pmdec": -5.91,
    "rv": 0,
    "Asset Name": "ASTRID 001071",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Naughty Dreams",
    "Hair": "Goddess",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 9e-7,
    "y_1": 0.00001122,
    "z_1": -3.9e-7,
    "Distance in Parsecs_1": 15.8078,
    "HYG Star ID_1": 56493,
    "Hipparcos Catalogue_1": 56662,
    "Gliese Catalog_1": "Gl 433.1"
  },
  {
    "Astrid #": "ASTRID 001072",
    "HYG Star ID": 3580,
    "Hipparcos Catalogue": 3588,
    "Henry Draper Catalogue": 4378,
    "Gliese Catalog": "Gl 32A",
    "Distance in Parsecs": 15.8178,
    "mag": 7.89,
    "Absolute Visual Magnitude": 6.894,
    "Spectral Type": "K5V comp SB",
    "Color Index": 1.251,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.537178,
    "y": 2.33703,
    "z": -10.565376,
    "vx": -0.00002826,
    "vy": 0.00001787,
    "vz": 0.00001336,
    "rarad": 0.199856444808817,
    "decrad": -0.731453610693747,
    "pmrarad": 0.00000146214957918749,
    "pmdecrad": -3.91050714e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 3580,
    "base": "Gl  32",
    "lum": 0.152194865090365,
    "ra": 0.763395,
    "dec": -41.909205,
    "pmra": 301.59,
    "pmdec": -80.66,
    "rv": -26.3,
    "Asset Name": "ASTRID 001072",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Afro",
    "Outfit": "Astro",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00002826,
    "y_1": 0.00001787,
    "z_1": 0.00001336,
    "Distance in Parsecs_1": 15.8178,
    "HYG Star ID_1": 3580,
    "Hipparcos Catalogue_1": 3588,
    "Henry Draper Catalogue_1": 4378,
    "Gliese Catalog_1": "Gl 32A"
  },
  {
    "Astrid #": "ASTRID 001073",
    "HYG Star ID": 98590,
    "Hipparcos Catalogue": 98906,
    "Gliese Catalog": "Gl 781",
    "Distance in Parsecs": 15.8303,
    "mag": 11.98,
    "Absolute Visual Magnitude": 10.983,
    "Spectral Type": "M3",
    "Color Index": 1.524,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.777451,
    "y": -7.870172,
    "z": 12.876056,
    "vx": -0.00009297,
    "vy": -0.00001918,
    "vz": -0.0001633,
    "rarad": 5.25799900945801,
    "decrad": 0.950056740043717,
    "pmrarad": -0.00000564977622635416,
    "pmdecrad": -0.000004363080718,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 98590,
    "lum": 0.00352208635618467,
    "var": "V1513",
    "var_min": 12.09,
    "var_max": 11.86,
    "ra": 20.084077,
    "dec": 54.434242,
    "pmra": -1165.35,
    "pmdec": -899.95,
    "rv": -148,
    "Asset Name": "ASTRID 001073",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Battle Ready",
    "Hair": "Prince",
    "Outfit": "Tats",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00009297,
    "y_1": -0.00001918,
    "z_1": -0.0001633,
    "Distance in Parsecs_1": 15.8303,
    "HYG Star ID_1": 98590,
    "Hipparcos Catalogue_1": 98906,
    "Gliese Catalog_1": "Gl 781"
  },
  {
    "Astrid #": "ASTRID 001074",
    "HYG Star ID": 71058,
    "Hipparcos Catalogue": 71284,
    "Henry Draper Catalogue": 128167,
    "Harvard Revised Catalogue": 5447,
    "Gliese Catalog": "Gl 557",
    "Distance in Parsecs": 15.8328,
    "mag": 4.47,
    "Absolute Visual Magnitude": 3.472,
    "Spectral Type": "F3Vwvar",
    "Color Index": 0.364,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.732826,
    "y": -8.589414,
    "z": 7.855332,
    "vx": 0.00001235,
    "vy": -0.00000863,
    "vz": 0.0000093,
    "rarad": 3.81651149575982,
    "decrad": 0.519150453141265,
    "pmrarad": 9.13001123222222e-7,
    "pmdecrad": 6.43444716e-7,
    "bayer": "Sig",
    "flam": 28,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 71058,
    "lum": 3.55795130559742,
    "bf": "28Sig Boo",
    "ra": 14.578,
    "dec": 29.74513,
    "pmra": 188.32,
    "pmdec": 132.72,
    "rv": 0.9,
    "Asset Name": "ASTRID 001074",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Medium Bob",
    "Outfit": "Toga",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001235,
    "y_1": -0.00000863,
    "z_1": 0.0000093,
    "Distance in Parsecs_1": 15.8328,
    "HYG Star ID_1": 71058,
    "Hipparcos Catalogue_1": 71284,
    "Henry Draper Catalogue_1": 128167,
    "Harvard Revised Catalogue_1": 5447,
    "Gliese Catalog_1": "Gl 557"
  },
  {
    "Astrid #": "ASTRID 001075",
    "HYG Star ID": 74474,
    "Hipparcos Catalogue": 74702,
    "Henry Draper Catalogue": 135599,
    "Distance in Parsecs": 15.8453,
    "mag": 6.92,
    "Absolute Visual Magnitude": 5.92,
    "Spectral Type": "K0",
    "Color Index": 0.83,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.395173,
    "y": -11.956814,
    "z": 0.220229,
    "vx": 0.00001022,
    "vy": -0.00000908,
    "vz": -0.00001049,
    "rarad": 3.99674338582149,
    "decrad": 0.013899145658185,
    "pmrarad": 8.63065314125e-7,
    "pmdecrad": -6.61867636e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 74474,
    "lum": 0.37325015779572,
    "var": "V379",
    "var_min": 6.937,
    "var_max": 6.907,
    "ra": 15.266435,
    "dec": 0.796362,
    "pmra": 178.02,
    "pmdec": -136.52,
    "rv": 0,
    "Asset Name": "ASTRID 001075",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Bad Dreams",
    "Hair": "Audiophile",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00001022,
    "y_1": -0.00000908,
    "z_1": -0.00001049,
    "Distance in Parsecs_1": 15.8453,
    "HYG Star ID_1": 74474,
    "Hipparcos Catalogue_1": 74702,
    "Henry Draper Catalogue_1": 135599
  },
  {
    "Astrid #": "ASTRID 001076",
    "HYG Star ID": 119318,
    "Gliese Catalog": "NN 4129",
    "Distance in Parsecs": 15.8479,
    "mag": 11.95,
    "Absolute Visual Magnitude": 10.95,
    "Spectral Type": "k",
    "Color Index": 1.55,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.814657,
    "y": -13.775734,
    "z": 0.562362,
    "vx": -0.00001979,
    "vy": -0.00001375,
    "vz": -0.00006152,
    "rarad": 5.22839984510309,
    "decrad": 0.03549239854965,
    "pmrarad": -0.00000151455793805555,
    "pmdecrad": -0.000003884589007,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 119318,
    "lum": 0.00363078054770101,
    "ra": 19.971016,
    "dec": 2.033565,
    "pmra": -312.4,
    "pmdec": -801.25,
    "rv": 0,
    "Asset Name": "ASTRID 001076",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Curly",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001979,
    "y_1": -0.00001375,
    "z_1": -0.00006152,
    "Distance in Parsecs_1": 15.8479,
    "HYG Star ID_1": 119318,
    "Gliese Catalog_1": "NN 4129"
  },
  {
    "Astrid #": "ASTRID 001077",
    "HYG Star ID": 60118,
    "Hipparcos Catalogue": 60303,
    "Henry Draper Catalogue": 107596,
    "Gliese Catalog": "Gl 462",
    "Distance in Parsecs": 15.8529,
    "mag": 9.36,
    "Absolute Visual Magnitude": 8.359,
    "Spectral Type": "M0V",
    "Color Index": 1.354,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.702644,
    "y": -1.121747,
    "z": 10.635254,
    "vx": -0.00003685,
    "vy": -0.00001708,
    "vz": -0.00001888,
    "rarad": 3.23715225407165,
    "decrad": 0.735375040361185,
    "pmrarad": 8.50751046638888e-7,
    "pmdecrad": -0.000002504644436,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 60118,
    "lum": 0.0394820778037882,
    "ra": 12.365011,
    "dec": 42.133886,
    "pmra": 175.48,
    "pmdec": -516.62,
    "rv": 15.4,
    "Asset Name": "ASTRID 001077",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Battle Ready",
    "Hair": "High Bun",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00003685,
    "y_1": -0.00001708,
    "z_1": -0.00001888,
    "Distance in Parsecs_1": 15.8529,
    "HYG Star ID_1": 60118,
    "Hipparcos Catalogue_1": 60303,
    "Henry Draper Catalogue_1": 107596,
    "Gliese Catalog_1": "Gl 462"
  },
  {
    "Astrid #": "ASTRID 001078",
    "HYG Star ID": 98454,
    "Hipparcos Catalogue": 98767,
    "Henry Draper Catalogue": 190360,
    "Harvard Revised Catalogue": 7670,
    "Gliese Catalog": "Gl 777A",
    "Distance in Parsecs": 15.8579,
    "mag": 5.73,
    "Absolute Visual Magnitude": 4.729,
    "Spectral Type": "G6IV+...",
    "Color Index": 0.749,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.060984,
    "y": -11.795308,
    "z": 7.903998,
    "vx": 0.00003448,
    "vy": 0.00004467,
    "vz": -0.00005832,
    "rarad": 5.25179347425236,
    "decrad": 0.521797691747477,
    "pmrarad": 0.00000331282884197222,
    "pmdecrad": -0.000002540714574,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 98454,
    "base": "Gl 777",
    "lum": 1.11789239071174,
    "ra": 20.060373,
    "dec": 29.896805,
    "pmra": 683.32,
    "pmdec": -524.06,
    "rv": -45.9,
    "Asset Name": "ASTRID 001078",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Neko Dreams",
    "Hair": "Afro",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00003448,
    "y_1": 0.00004467,
    "z_1": -0.00005832,
    "Distance in Parsecs_1": 15.8579,
    "HYG Star ID_1": 98454,
    "Hipparcos Catalogue_1": 98767,
    "Henry Draper Catalogue_1": 190360,
    "Harvard Revised Catalogue_1": 7670,
    "Gliese Catalog_1": "Gl 777A"
  },
  {
    "Astrid #": "ASTRID 001079",
    "HYG Star ID": 113872,
    "Hipparcos Catalogue": 114233,
    "Gliese Catalog": "Gl 889.1",
    "Distance in Parsecs": 15.8579,
    "mag": 10.9,
    "Absolute Visual Magnitude": 9.899,
    "Spectral Type": "M1V:",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.427469,
    "y": -3.553485,
    "z": 0.920871,
    "vx": 0.00003923,
    "vy": 0.00002982,
    "vz": 0.00002145,
    "rarad": 6.05679882028797,
    "decrad": 0.0581017860725812,
    "pmrarad": 0.00000238780433947222,
    "pmdecrad": 0.000001235208295,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 113872,
    "lum": 0.0095587257188469,
    "var_min": 10.957,
    "var_max": 10.847,
    "ra": 23.135267,
    "dec": 3.328987,
    "pmra": 492.52,
    "pmdec": 254.78,
    "rv": 32,
    "Asset Name": "ASTRID 001079",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Wavy",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00003923,
    "y_1": 0.00002982,
    "z_1": 0.00002145,
    "Distance in Parsecs_1": 15.8579,
    "HYG Star ID_1": 113872,
    "Hipparcos Catalogue_1": 114233,
    "Gliese Catalog_1": "Gl 889.1"
  },
  {
    "Astrid #": "ASTRID 001080",
    "HYG Star ID": 63630,
    "Hipparcos Catalogue": 63833,
    "Henry Draper Catalogue": 113538,
    "Gliese Catalog": "Gl 496.1",
    "Distance in Parsecs": 15.8655,
    "mag": 9.05,
    "Absolute Visual Magnitude": 8.048,
    "Spectral Type": "K8V",
    "Color Index": 1.362,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.285181,
    "y": -2.704535,
    "z": -12.577587,
    "vx": 0.00000704,
    "vy": 0.00006499,
    "vz": -0.00006794,
    "rarad": 3.42504650066547,
    "decrad": -0.915301682213827,
    "pmrarad": -0.000003809181088125,
    "pmdecrad": -0.000003856595866,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 63630,
    "lum": 0.0525775082256693,
    "ra": 13.082714,
    "dec": -52.442923,
    "pmra": -785.7,
    "pmdec": -795.48,
    "rv": 37.8,
    "Asset Name": "ASTRID 001080",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Lion Mane",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000704,
    "y_1": 0.00006499,
    "z_1": -0.00006794,
    "Distance in Parsecs_1": 15.8655,
    "HYG Star ID_1": 63630,
    "Hipparcos Catalogue_1": 63833,
    "Henry Draper Catalogue_1": 113538,
    "Gliese Catalog_1": "Gl 496.1"
  },
  {
    "Astrid #": "ASTRID 001081",
    "HYG Star ID": 118351,
    "Gliese Catalog": "Gl 188B",
    "Distance in Parsecs": 15.868,
    "mag": 5.7,
    "Absolute Visual Magnitude": 4.697,
    "Spectral Type": "G4 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.416956,
    "y": 14.641592,
    "z": 5.073618,
    "vx": -0.0000398,
    "vy": 0.00000859,
    "vz": 0.00000202,
    "rarad": 1.34151947666835,
    "decrad": 0.3254540018809,
    "pmrarad": 0.00000256597336707638,
    "pmdecrad": 1.34477618e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 2,
    "comp_primary": 23780,
    "base": "Gl 188",
    "lum": 1.15133047415581,
    "ra": 5.124227,
    "dec": 18.647141,
    "pmra": 529.27,
    "pmdec": 27.74,
    "rv": 0,
    "Asset Name": "ASTRID 001081",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Lion Mane",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.0000398,
    "y_1": 0.00000859,
    "z_1": 0.00000202,
    "Distance in Parsecs_1": 15.868,
    "HYG Star ID_1": 118351,
    "Gliese Catalog_1": "Gl 188B"
  },
  {
    "Astrid #": "ASTRID 001082",
    "HYG Star ID": 118211,
    "Gliese Catalog": "NN 3218",
    "Distance in Parsecs": 15.873,
    "mag": 11.37,
    "Absolute Visual Magnitude": 10.367,
    "Spectral Type": "M2",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.039098,
    "y": 12.155922,
    "z": -1.844504,
    "vx": -0.00001832,
    "vy": 0.00001432,
    "vz": -0.00000533,
    "rarad": 0.880483891986105,
    "decrad": -0.11646693999855,
    "pmrarad": 0.00000146437972211805,
    "pmdecrad": -3.38079971e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118211,
    "lum": 0.0062115502083244,
    "ra": 3.363201,
    "dec": -6.673064,
    "pmra": 302.05,
    "pmdec": -69.73,
    "rv": 0,
    "Asset Name": "ASTRID 001082",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Knocked Out",
    "Hair": "Frizzy",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001832,
    "y_1": 0.00001432,
    "z_1": -0.00000533,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118211,
    "Gliese Catalog_1": "NN 3218"
  },
  {
    "Astrid #": "ASTRID 001083",
    "HYG Star ID": 118272,
    "Gliese Catalog": "NN 3261",
    "Distance in Parsecs": 15.873,
    "mag": 12.89,
    "Absolute Visual Magnitude": 11.887,
    "Spectral Type": "dM5  e",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 7.557442,
    "y": 13.867702,
    "z": 1.588717,
    "vx": -0.00000521,
    "vy": 0.00000393,
    "vz": -0.00000952,
    "rarad": 1.07182524708576,
    "decrad": 0.1002571294974,
    "pmrarad": 4.0666171525e-7,
    "pmdecrad": -6.02890052e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118272,
    "lum": 0.00153179271589341,
    "ra": 4.094071,
    "dec": 5.74431,
    "pmra": 83.88,
    "pmdec": -124.36,
    "rv": 0,
    "Asset Name": "ASTRID 001083",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Bandana",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000521,
    "y_1": 0.00000393,
    "z_1": -0.00000952,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118272,
    "Gliese Catalog_1": "NN 3261"
  },
  {
    "Astrid #": "ASTRID 001084",
    "HYG Star ID": 118313,
    "Gliese Catalog": "NN 3294",
    "Distance in Parsecs": 15.873,
    "mag": 14.57,
    "Absolute Visual Magnitude": 13.567,
    "Spectral Type": "k-m",
    "Color Index": 1.75,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.768745,
    "y": 13.996361,
    "z": 4.772379,
    "vx": -0.00000434,
    "vy": 0.00000247,
    "vz": -0.00000201,
    "rarad": 1.17985030669678,
    "decrad": 0.30538485593055,
    "pmrarad": 3.1241393575e-7,
    "pmdecrad": -1.32601389e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118313,
    "lum": 0.000325986788904468,
    "ra": 4.506696,
    "dec": 17.497263,
    "pmra": 64.44,
    "pmdec": -27.35,
    "rv": 0,
    "Asset Name": "ASTRID 001084",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Curly",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000434,
    "y_1": 0.00000247,
    "z_1": -0.00000201,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118313,
    "Gliese Catalog_1": "NN 3294"
  },
  {
    "Astrid #": "ASTRID 001085",
    "HYG Star ID": 118342,
    "Gliese Catalog": "NN 3320",
    "Distance in Parsecs": 15.873,
    "mag": 11.51,
    "Absolute Visual Magnitude": 10.507,
    "Spectral Type": "M2.5",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.650927,
    "y": 13.929877,
    "z": 6.676928,
    "vx": -0.00001355,
    "vy": 0.00001659,
    "vz": -0.0000272,
    "rarad": 1.31446297953906,
    "decrad": 0.4341581883879,
    "pmrarad": 0.00000109083078125,
    "pmdecrad": -0.000001889372242,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118342,
    "lum": 0.00546009249754273,
    "ra": 5.020879,
    "dec": 24.875432,
    "pmra": 225,
    "pmdec": -389.71,
    "rv": 0,
    "Asset Name": "ASTRID 001085",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Sweetheart",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00001355,
    "y_1": 0.00001659,
    "z_1": -0.0000272,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118342,
    "Gliese Catalog_1": "NN 3320"
  },
  {
    "Astrid #": "ASTRID 001086",
    "HYG Star ID": 118535,
    "Gliese Catalog": "NN 3492",
    "Distance in Parsecs": 15.873,
    "mag": 11.85,
    "Absolute Visual Magnitude": 10.847,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.092422,
    "y": 3.172723,
    "z": -15.411286,
    "vx": -0.00000212,
    "vy": 0.00004889,
    "vz": 0.00001034,
    "rarad": 2.1540753156595,
    "decrad": -1.3290106771701,
    "pmrarad": -0.00000158514680994444,
    "pmdecrad": 0.000002723576598,
    "con": "Cha",
    "Full Constellation Name Formula 1": "Chamaeleon",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Chamaeleon",
    "comp": 1,
    "comp_primary": 118535,
    "lum": 0.00399208702438807,
    "ra": 8.227962,
    "dec": -76.146703,
    "pmra": -326.96,
    "pmdec": 561.78,
    "rv": 0,
    "Asset Name": "ASTRID 001086",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Worried Vamp",
    "Hair": "Spiky",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Chamaeleon",
    "x_1": -0.00000212,
    "y_1": 0.00004889,
    "z_1": 0.00001034,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118535,
    "Gliese Catalog_1": "NN 3492"
  },
  {
    "Astrid #": "ASTRID 001087",
    "HYG Star ID": 118711,
    "Gliese Catalog": "NN 3625",
    "Distance in Parsecs": 15.873,
    "mag": 13.45,
    "Absolute Visual Magnitude": 12.447,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.75988,
    "y": 0.895306,
    "z": -15.605564,
    "vx": 0.00002614,
    "vy": 0.00002807,
    "vz": -0.00000302,
    "rarad": 2.82839265210245,
    "decrad": -1.3869702322678,
    "pmrarad": -0.00000218975795096527,
    "pmdecrad": -0.000001039765356,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118711,
    "lum": 0.000914534303120459,
    "ra": 10.803664,
    "dec": -79.467541,
    "pmra": -451.67,
    "pmdec": -214.47,
    "rv": 0,
    "Asset Name": "ASTRID 001087",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Elegant",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002614,
    "y_1": 0.00002807,
    "z_1": -0.00000302,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118711,
    "Gliese Catalog_1": "NN 3625"
  },
  {
    "Astrid #": "ASTRID 001088",
    "HYG Star ID": 118778,
    "Gliese Catalog": "NN 3690B",
    "Distance in Parsecs": 15.873,
    "mag": 15,
    "Absolute Visual Magnitude": 13.997,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.622229,
    "y": 0.524948,
    "z": 2.760893,
    "vx": 0.00000231,
    "vy": 0.00002467,
    "vz": 0.00000839,
    "rarad": 3.10800385004898,
    "decrad": 0.17482568774035,
    "pmrarad": -0.00000155857902025,
    "pmdecrad": 5.36654807e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118778,
    "base": "NN 3690",
    "lum": 0.00021938149918979,
    "ra": 11.8717,
    "dec": 10.016774,
    "pmra": -321.48,
    "pmdec": 110.69,
    "rv": 0,
    "Asset Name": "ASTRID 001088",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Neko",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000231,
    "y_1": 0.00002467,
    "z_1": 0.00000839,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118778,
    "Gliese Catalog_1": "NN 3690B"
  },
  {
    "Astrid #": "ASTRID 001089",
    "HYG Star ID": 118813,
    "Gliese Catalog": "Gl 461A",
    "Distance in Parsecs": 15.873,
    "mag": 10.1,
    "Absolute Visual Magnitude": 9.097,
    "Spectral Type": "M0 V",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.809357,
    "y": -1.413108,
    "z": 0.161748,
    "vx": -0.00000359,
    "vy": -0.0000071,
    "vz": -0.00000152,
    "rarad": 3.23073999665582,
    "decrad": 0.01019020847355,
    "pmrarad": 4.25133116479166e-7,
    "pmdecrad": -9.8150529e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 118813,
    "base": "Gl 461",
    "lum": 0.0200078305215239,
    "ra": 12.340518,
    "dec": 0.583856,
    "pmra": 87.69,
    "pmdec": -20.25,
    "rv": 4.1,
    "Asset Name": "ASTRID 001089",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Ninja",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000359,
    "y_1": -0.0000071,
    "z_1": -0.00000152,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118813,
    "Gliese Catalog_1": "Gl 461A"
  },
  {
    "Astrid #": "ASTRID 001090",
    "HYG Star ID": 118868,
    "Gliese Catalog": "GJ 1167A",
    "Distance in Parsecs": 15.873,
    "mag": 14.18,
    "Absolute Visual Magnitude": 13.177,
    "Spectral Type": "dM5",
    "Color Index": 1.72,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -13.249999,
    "y": -4.150827,
    "z": 7.691572,
    "vx": -0.00001396,
    "vy": 0.00002147,
    "vz": -0.00001245,
    "rarad": 3.44519002157617,
    "decrad": 0.50587104473455,
    "pmrarad": -0.00000155348847660416,
    "pmdecrad": -8.96905309e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118868,
    "base": "GJ 1167",
    "lum": 0.000466874334392018,
    "ra": 13.159657,
    "dec": 28.984276,
    "pmra": -320.43,
    "pmdec": -185,
    "rv": 0,
    "Asset Name": "ASTRID 001090",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Bandana",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001396,
    "y_1": 0.00002147,
    "z_1": -0.00001245,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 118868,
    "Gliese Catalog_1": "GJ 1167A"
  },
  {
    "Astrid #": "ASTRID 001091",
    "HYG Star ID": 119206,
    "Gliese Catalog": "Gl 708.3",
    "Distance in Parsecs": 15.873,
    "mag": 12.52,
    "Absolute Visual Magnitude": 11.517,
    "Spectral Type": "M5",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.128216,
    "y": -15.827248,
    "z": 0.421305,
    "vx": -0.00003052,
    "vy": -0.00000354,
    "vz": -0.00005066,
    "rarad": 4.78355175875955,
    "decrad": 0.0265453685828,
    "pmrarad": -0.000001933824809,
    "pmdecrad": -0.000003193128344,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119206,
    "lum": 0.00215377335566217,
    "ra": 18.271822,
    "dec": 1.520938,
    "pmra": -398.88,
    "pmdec": -658.63,
    "rv": 0,
    "Asset Name": "ASTRID 001091",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Angelic",
    "Outfit": "Toga",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003052,
    "y_1": -0.00000354,
    "z_1": -0.00005066,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 119206,
    "Gliese Catalog_1": "Gl 708.3"
  },
  {
    "Astrid #": "ASTRID 001092",
    "HYG Star ID": 119268,
    "Gliese Catalog": "NN 4102",
    "Distance in Parsecs": 15.873,
    "mag": 12.7,
    "Absolute Visual Magnitude": 11.697,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.70374,
    "y": -1.92993,
    "z": -15.739513,
    "vx": -0.00000818,
    "vy": 0.00009584,
    "vz": -0.00001215,
    "rarad": 5.06151914629468,
    "decrad": -1.44101543312345,
    "pmrarad": 0.00000158102589365972,
    "pmdecrad": -0.000005900502469,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119268,
    "lum": 0.00182473583076258,
    "ra": 19.333579,
    "dec": -82.564103,
    "pmra": 326.11,
    "pmdec": -1217.07,
    "rv": 0,
    "Asset Name": "ASTRID 001092",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Cute Neko",
    "Hair": "Greasy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000818,
    "y_1": 0.00009584,
    "z_1": -0.00001215,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 119268,
    "Gliese Catalog_1": "NN 4102"
  },
  {
    "Astrid #": "ASTRID 001093",
    "HYG Star ID": 119478,
    "Gliese Catalog": "NN 4262",
    "Distance in Parsecs": 15.873,
    "mag": 12.14,
    "Absolute Visual Magnitude": 11.137,
    "Spectral Type": "k-m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.104563,
    "y": -7.246841,
    "z": -0.704802,
    "vx": 0.00001358,
    "vy": 0.00002699,
    "vz": -0.00000587,
    "rarad": 5.80856334504583,
    "decrad": -0.04441721121725,
    "pmrarad": 0.00000190362091670138,
    "pmdecrad": -3.70024345e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119478,
    "lum": 0.00305632828036596,
    "ra": 22.187078,
    "dec": -2.544919,
    "pmra": 392.65,
    "pmdec": -76.32,
    "rv": 0,
    "Asset Name": "ASTRID 001093",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Side Part",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001358,
    "y_1": 0.00002699,
    "z_1": -0.00000587,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 119478,
    "Gliese Catalog_1": "NN 4262"
  },
  {
    "Astrid #": "ASTRID 001094",
    "HYG Star ID": 119497,
    "Gliese Catalog": "GJ 1268",
    "Distance in Parsecs": 15.873,
    "mag": 14.94,
    "Absolute Visual Magnitude": 13.937,
    "Spectral Type": "m+",
    "Color Index": 1.81,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.942449,
    "y": -3.937717,
    "z": 12.509162,
    "vx": -0.00001529,
    "vy": 0.0000282,
    "vz": 0.00001981,
    "rarad": 5.86835508104225,
    "decrad": 0.90768036299445,
    "pmrarad": 0.00000123768084509027,
    "pmdecrad": 0.000002027679889,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119497,
    "lum": 0.000231846209541312,
    "ra": 22.415465,
    "dec": 52.006254,
    "pmra": 255.29,
    "pmdec": 418.24,
    "rv": 0,
    "Asset Name": "ASTRID 001094",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Fire",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001529,
    "y_1": 0.0000282,
    "z_1": 0.00001981,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 119497,
    "Gliese Catalog_1": "GJ 1268"
  },
  {
    "Astrid #": "ASTRID 001095",
    "HYG Star ID": 119524,
    "Gliese Catalog": "NN 4292",
    "Distance in Parsecs": 15.873,
    "mag": 15,
    "Absolute Visual Magnitude": 13.997,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.888832,
    "y": -4.82413,
    "z": 5.981659,
    "vx": 0.00001061,
    "vy": 0.00002886,
    "vz": -0.00000134,
    "rarad": 5.9488755870771,
    "decrad": 0.38638769657035,
    "pmrarad": 0.00000193712154202777,
    "pmdecrad": -9.1348593e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119524,
    "lum": 0.00021938149918979,
    "ra": 22.723031,
    "dec": 22.138384,
    "pmra": 399.56,
    "pmdec": -18.84,
    "rv": 0,
    "Asset Name": "ASTRID 001095",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Slick",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001061,
    "y_1": 0.00002886,
    "z_1": -0.00000134,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 119524,
    "Gliese Catalog_1": "NN 4292"
  },
  {
    "Astrid #": "ASTRID 001096",
    "HYG Star ID": 119555,
    "Gliese Catalog": "NN 4319",
    "Distance in Parsecs": 15.873,
    "mag": 13.14,
    "Absolute Visual Magnitude": 12.137,
    "Spectral Type": "k",
    "Color Index": 1.63,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.475483,
    "y": -2.998042,
    "z": 1.86368,
    "vx": 0.00000432,
    "vy": 0.00001112,
    "vz": -0.00001794,
    "rarad": 6.09182696604314,
    "decrad": 0.11768338132895,
    "pmrarad": 7.39340862847222e-7,
    "pmdecrad": -0.000001138473421,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119555,
    "lum": 0.00121674620397912,
    "ra": 23.269065,
    "dec": 6.742761,
    "pmra": 152.5,
    "pmdec": -234.83,
    "rv": 0,
    "Asset Name": "ASTRID 001096",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Oh Snap",
    "Hair": "Bandana",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000432,
    "y_1": 0.00001112,
    "z_1": -0.00001794,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 119555,
    "Gliese Catalog_1": "NN 4319"
  },
  {
    "Astrid #": "ASTRID 001097",
    "HYG Star ID": 119580,
    "Gliese Catalog": "NN 4355",
    "Distance in Parsecs": 15.873,
    "mag": 13.26,
    "Absolute Visual Magnitude": 12.257,
    "Spectral Type": "DA",
    "Color Index": 0.18,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 15.66321,
    "y": -1.449902,
    "z": -2.124561,
    "vx": -1.6e-7,
    "vy": 0.0000025,
    "vz": -0.00000292,
    "rarad": 6.19088093195584,
    "decrad": -0.134250404666749,
    "pmrarad": 1.55819116930555e-7,
    "pmdecrad": -1.85693335e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119580,
    "lum": 0.00108943167965913,
    "ra": 23.647423,
    "dec": -7.691982,
    "pmra": 32.14,
    "pmdec": -38.3,
    "rv": 0,
    "Asset Name": "ASTRID 001097",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Neko",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -1.6e-7,
    "y_1": 0.0000025,
    "z_1": -0.00000292,
    "Distance in Parsecs_1": 15.873,
    "HYG Star ID_1": 119580,
    "Gliese Catalog_1": "NN 4355"
  },
  {
    "Astrid #": "ASTRID 001098",
    "HYG Star ID": 82005,
    "Hipparcos Catalogue": 82256,
    "Gliese Catalog": "Gl 637",
    "Distance in Parsecs": 15.8806,
    "mag": 11.36,
    "Absolute Visual Magnitude": 10.356,
    "Spectral Type": "M",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.428845,
    "y": -4.424302,
    "z": -15.184523,
    "vx": -0.00002072,
    "vy": 0.00005629,
    "vz": 0.00001443,
    "rarad": 4.40005820004003,
    "decrad": -1.27367293107883,
    "pmrarad": -0.00000233108113884722,
    "pmdecrad": -0.00000257600901,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 82005,
    "lum": 0.00627480161997851,
    "var_min": 11.44,
    "var_max": 11.25,
    "ra": 16.806984,
    "dec": -72.976083,
    "pmra": -480.82,
    "pmdec": -531.34,
    "rv": -27,
    "Asset Name": "ASTRID 001098",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Mohawk",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": -0.00002072,
    "y_1": 0.00005629,
    "z_1": 0.00001443,
    "Distance in Parsecs_1": 15.8806,
    "HYG Star ID_1": 82005,
    "Hipparcos Catalogue_1": 82256,
    "Gliese Catalog_1": "Gl 637"
  },
  {
    "Astrid #": "ASTRID 001099",
    "HYG Star ID": 435,
    "Hipparcos Catalogue": 436,
    "Henry Draper Catalogue": 55,
    "Gliese Catalog": "Gl 3",
    "Distance in Parsecs": 15.8881,
    "mag": 8.49,
    "Absolute Visual Magnitude": 7.485,
    "Spectral Type": "K5V",
    "Color Index": 1.076,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.993342,
    "y": 0.138488,
    "z": -14.714076,
    "vx": -0.00002396,
    "vy": -0.00000958,
    "vz": -0.00005513,
    "rarad": 0.0231110996599178,
    "decrad": -1.18390202853466,
    "pmrarad": -5.68153152243055e-7,
    "pmdecrad": -0.000002718689676,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 435,
    "lum": 0.0883079900418562,
    "ra": 0.088278,
    "dec": -67.83259,
    "pmra": -117.19,
    "pmdec": -560.77,
    "rv": 41,
    "Asset Name": "ASTRID 001099",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Pixie",
    "Outfit": "Viking",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00002396,
    "y_1": -0.00000958,
    "z_1": -0.00005513,
    "Distance in Parsecs_1": 15.8881,
    "HYG Star ID_1": 435,
    "Hipparcos Catalogue_1": 436,
    "Henry Draper Catalogue_1": 55,
    "Gliese Catalog_1": "Gl 3"
  },
  {
    "Astrid #": "ASTRID 001100",
    "HYG Star ID": 56071,
    "Hipparcos Catalogue": 56238,
    "Gliese Catalog": "Gl 430.1",
    "Distance in Parsecs": 15.8932,
    "mag": 10.31,
    "Absolute Visual Magnitude": 9.304,
    "Color Index": 1.439,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.554072,
    "y": 1.804859,
    "z": 6.124854,
    "vx": 0.00001122,
    "vy": 0.0000439,
    "vz": 2e-7,
    "rarad": 3.01821403832206,
    "decrad": 0.39561615772344,
    "pmrarad": -0.000002828306049625,
    "pmdecrad": 1.50825536e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 56071,
    "lum": 0.0165348401217115,
    "ra": 11.528728,
    "dec": 22.667136,
    "pmra": -583.38,
    "pmdec": 31.11,
    "rv": -5.1,
    "Asset Name": "ASTRID 001100",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Sweetheart",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00001122,
    "y_1": 0.0000439,
    "z_1": 2e-7,
    "Distance in Parsecs_1": 15.8932,
    "HYG Star ID_1": 56071,
    "Hipparcos Catalogue_1": 56238,
    "Gliese Catalog_1": "Gl 430.1"
  },
  {
    "Astrid #": "ASTRID 001101",
    "HYG Star ID": 77524,
    "Hipparcos Catalogue": 77760,
    "Henry Draper Catalogue": 142373,
    "Harvard Revised Catalogue": 5914,
    "Gliese Catalog": "Gl 602",
    "Distance in Parsecs": 15.8932,
    "mag": 4.6,
    "Absolute Visual Magnitude": 3.594,
    "Spectral Type": "F9V",
    "Color Index": 0.563,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.184702,
    "y": -9.962833,
    "z": 10.727031,
    "vx": 0.00006845,
    "vy": 0.00004613,
    "vz": -0.00000314,
    "rarad": 4.15682525563286,
    "decrad": 0.740918760991958,
    "pmrarad": 0.00000212818661353472,
    "pmdecrad": 0.000003051999081,
    "bayer": "Chi",
    "flam": 1,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 77524,
    "lum": 3.17980142769152,
    "bf": "1Chi Her",
    "ra": 15.877903,
    "dec": 42.451518,
    "pmra": 438.97,
    "pmdec": 629.52,
    "rv": -56.4,
    "Asset Name": "ASTRID 001101",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Shinobi",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00006845,
    "y_1": 0.00004613,
    "z_1": -0.00000314,
    "Distance in Parsecs_1": 15.8932,
    "HYG Star ID_1": 77524,
    "Hipparcos Catalogue_1": 77760,
    "Henry Draper Catalogue_1": 142373,
    "Harvard Revised Catalogue_1": 5914,
    "Gliese Catalog_1": "Gl 602"
  },
  {
    "Astrid #": "ASTRID 001102",
    "HYG Star ID": 119325,
    "Gliese Catalog": "Gl 777B",
    "Distance in Parsecs": 15.8932,
    "mag": 14.37,
    "Absolute Visual Magnitude": 13.364,
    "Spectral Type": "M4  :",
    "Color Index": 1.67,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.069471,
    "y": -11.83116,
    "z": 7.914034,
    "vx": 0.00005237,
    "vy": 0.0000131,
    "vz": -0.00003743,
    "rarad": 5.25096535011591,
    "decrad": 0.52124353620765,
    "pmrarad": 0.00000325174231822222,
    "pmdecrad": -0.000002531415847,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 98454,
    "base": "Gl 777",
    "lum": 0.000393006741341852,
    "ra": 20.05721,
    "dec": 29.865055,
    "pmra": 670.72,
    "pmdec": -522.14,
    "rv": -5,
    "Asset Name": "ASTRID 001102",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Round Brush",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005237,
    "y_1": 0.0000131,
    "z_1": -0.00003743,
    "Distance in Parsecs_1": 15.8932,
    "HYG Star ID_1": 119325,
    "Gliese Catalog_1": "Gl 777B"
  },
  {
    "Astrid #": "ASTRID 001103",
    "HYG Star ID": 118502,
    "Gliese Catalog": "NN 3463",
    "Distance in Parsecs": 15.9236,
    "mag": 14.75,
    "Absolute Visual Magnitude": 13.74,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.432398,
    "y": 13.998168,
    "z": 1.540066,
    "vx": -0.00002894,
    "vy": -0.00001196,
    "vz": -0.00003091,
    "rarad": 2.05889978376176,
    "decrad": 0.0968672845774499,
    "pmrarad": 0.00000195743523524305,
    "pmdecrad": -0.000001950628451,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118502,
    "lum": 0.000277971326775928,
    "ra": 7.864418,
    "dec": 5.550087,
    "pmra": 403.75,
    "pmdec": -402.35,
    "rv": 0,
    "Asset Name": "ASTRID 001103",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Headband",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002894,
    "y_1": -0.00001196,
    "z_1": -0.00003091,
    "Distance in Parsecs_1": 15.9236,
    "HYG Star ID_1": 118502,
    "Gliese Catalog_1": "NN 3463"
  },
  {
    "Astrid #": "ASTRID 001104",
    "HYG Star ID": 118877,
    "Gliese Catalog": "GJ 1169",
    "Distance in Parsecs": 15.9236,
    "mag": 13.26,
    "Absolute Visual Magnitude": 12.25,
    "Spectral Type": "m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.297522,
    "y": -4.615074,
    "z": 7.443924,
    "vx": 0.0000025,
    "vy": 0.00005905,
    "vz": 0.0000061,
    "rarad": 3.47567098663344,
    "decrad": 0.486462220826999,
    "pmrarad": -0.00000345206733102777,
    "pmdecrad": 9.7683654e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118877,
    "lum": 0.00109647819614318,
    "ra": 13.276085,
    "dec": 27.872232,
    "pmra": -712.04,
    "pmdec": 201.49,
    "rv": -16,
    "Asset Name": "ASTRID 001104",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Battle Ready",
    "Hair": "Little Crown",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000025,
    "y_1": 0.00005905,
    "z_1": 0.0000061,
    "Distance in Parsecs_1": 15.9236,
    "HYG Star ID_1": 118877,
    "Gliese Catalog_1": "GJ 1169"
  },
  {
    "Astrid #": "ASTRID 001105",
    "HYG Star ID": 26016,
    "Hipparcos Catalogue": 26081,
    "Gliese Catalog": "Gl 207.1",
    "Distance in Parsecs": 15.9363,
    "mag": 11.53,
    "Absolute Visual Magnitude": 10.518,
    "Spectral Type": "M2.5Ve",
    "Color Index": 1.576,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.82052,
    "y": 15.822925,
    "z": 0.540998,
    "vx": 0.00002114,
    "vy": 0.00002062,
    "vz": -0.00001133,
    "rarad": 1.4562441810683,
    "decrad": 0.0339536044163312,
    "pmrarad": -0.00000117004933665277,
    "pmdecrad": -7.59266705e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 26016,
    "lum": 0.00540505353708668,
    "var": "V371",
    "var_min": 11.613,
    "var_max": 11.443,
    "ra": 5.562443,
    "dec": 1.945398,
    "pmra": -241.34,
    "pmdec": -156.61,
    "rv": 22,
    "Asset Name": "ASTRID 001105",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Ponytail",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00002114,
    "y_1": 0.00002062,
    "z_1": -0.00001133,
    "Distance in Parsecs_1": 15.9363,
    "HYG Star ID_1": 26016,
    "Hipparcos Catalogue_1": 26081,
    "Gliese Catalog_1": "Gl 207.1"
  },
  {
    "Astrid #": "ASTRID 001106",
    "HYG Star ID": 108060,
    "Hipparcos Catalogue": 108405,
    "Gliese Catalog": "Gl 841A",
    "Distance in Parsecs": 15.9719,
    "mag": 10.36,
    "Absolute Visual Magnitude": 9.343,
    "Spectral Type": "M0",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.652443,
    "y": -5.112619,
    "z": -12.413516,
    "vx": -0.00002554,
    "vy": 0.00001231,
    "vz": -0.00001103,
    "rarad": 5.74949399748816,
    "decrad": -0.890225475984427,
    "pmrarad": -1.50001352763888e-7,
    "pmdecrad": -0.000001809712506,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 108060,
    "base": "Gl 841",
    "lum": 0.015951443869276,
    "var_min": 10.413,
    "var_max": 10.293,
    "ra": 21.961449,
    "dec": -51.006163,
    "pmra": -30.94,
    "pmdec": -373.28,
    "rv": -9,
    "Asset Name": "ASTRID 001106",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "High Bun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00002554,
    "y_1": 0.00001231,
    "z_1": -0.00001103,
    "Distance in Parsecs_1": 15.9719,
    "HYG Star ID_1": 108060,
    "Hipparcos Catalogue_1": 108405,
    "Gliese Catalog_1": "Gl 841A"
  },
  {
    "Astrid #": "ASTRID 001107",
    "HYG Star ID": 44167,
    "Hipparcos Catalogue": 44295,
    "Henry Draper Catalogue": 77175,
    "Gliese Catalog": "GJ 1120A",
    "Distance in Parsecs": 15.9744,
    "mag": 8.67,
    "Absolute Visual Magnitude": 7.653,
    "Spectral Type": "M0V:p...",
    "Color Index": 1.285,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.95819,
    "y": 10.835398,
    "z": 4.205979,
    "vx": 0.00001068,
    "vy": 0.00000318,
    "vz": -0.00002697,
    "rarad": 2.36182892514178,
    "decrad": 0.266437911392002,
    "pmrarad": -6.11640939388888e-7,
    "pmdecrad": -0.000001540640914,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 44167,
    "base": "GJ 1120",
    "lum": 0.0756484440833281,
    "ra": 9.021522,
    "dec": 15.265768,
    "pmra": -126.16,
    "pmdec": -317.78,
    "rv": -12,
    "Asset Name": "ASTRID 001107",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Audiohead",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00001068,
    "y_1": 0.00000318,
    "z_1": -0.00002697,
    "Distance in Parsecs_1": 15.9744,
    "HYG Star ID_1": 44167,
    "Hipparcos Catalogue_1": 44295,
    "Henry Draper Catalogue_1": 77175,
    "Gliese Catalog_1": "GJ 1120A"
  },
  {
    "Astrid #": "ASTRID 001108",
    "HYG Star ID": 54801,
    "Hipparcos Catalogue": 54963,
    "Gliese Catalog": "Gl 421A",
    "Distance in Parsecs": 15.9923,
    "mag": 10.02,
    "Absolute Visual Magnitude": 9,
    "Spectral Type": "K7V",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.909199,
    "y": 2.943565,
    "z": -4.980594,
    "vx": 0.0000078,
    "vy": -0.00002481,
    "vz": -0.00005443,
    "rarad": 2.94666612207304,
    "decrad": -0.316703944482515,
    "pmrarad": 0.00000142743691965972,
    "pmdecrad": -0.000003477083716,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 54801,
    "base": "Gl 421",
    "lum": 0.0218776162394955,
    "var_min": 10.285,
    "var_max": 9.665,
    "ra": 11.255435,
    "dec": -18.145799,
    "pmra": 294.43,
    "pmdec": -717.2,
    "rv": 5,
    "Asset Name": "ASTRID 001108",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Halo Boy",
    "Outfit": "Boxer",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.0000078,
    "y_1": -0.00002481,
    "z_1": -0.00005443,
    "Distance in Parsecs_1": 15.9923,
    "HYG Star ID_1": 54801,
    "Hipparcos Catalogue_1": 54963,
    "Gliese Catalog_1": "Gl 421A"
  },
  {
    "Astrid #": "ASTRID 001109",
    "HYG Star ID": 107306,
    "Hipparcos Catalogue": 107649,
    "Henry Draper Catalogue": 207129,
    "Harvard Revised Catalogue": 8323,
    "Gliese Catalog": "Gl 838",
    "Distance in Parsecs": 15.9949,
    "mag": 5.57,
    "Absolute Visual Magnitude": 4.55,
    "Spectral Type": "G2V",
    "Color Index": 0.601,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.103246,
    "y": -5.896903,
    "z": -11.755527,
    "vx": -0.00001091,
    "vy": 0.00002237,
    "vz": -0.00001063,
    "rarad": 5.70836878564707,
    "decrad": -0.825603827253123,
    "pmrarad": 8.03045380472222e-7,
    "pmdecrad": -0.000001430200357,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 107306,
    "lum": 1.3182567385564,
    "ra": 21.804363,
    "dec": -47.303615,
    "pmra": 165.64,
    "pmdec": -295,
    "rv": -6.5,
    "Asset Name": "ASTRID 001109",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Wavy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00001091,
    "y_1": 0.00002237,
    "z_1": -0.00001063,
    "Distance in Parsecs_1": 15.9949,
    "HYG Star ID_1": 107306,
    "Hipparcos Catalogue_1": 107649,
    "Henry Draper Catalogue_1": 207129,
    "Harvard Revised Catalogue_1": 8323,
    "Gliese Catalog_1": "Gl 838"
  },
  {
    "Astrid #": "ASTRID 001110",
    "HYG Star ID": 118394,
    "Gliese Catalog": "NN 3366",
    "Distance in Parsecs": 16,
    "mag": 14.54,
    "Absolute Visual Magnitude": 13.519,
    "Spectral Type": "m",
    "Color Index": 1.78,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.893593,
    "y": 15.908964,
    "z": -1.451331,
    "vx": -0.00004334,
    "vy": -0.0000016,
    "vz": -0.00004438,
    "rarad": 1.51468555916018,
    "decrad": -0.0908330139721,
    "pmrarad": 0.00000269910320375694,
    "pmdecrad": -0.000002785259442,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118394,
    "lum": 0.000340721861659357,
    "ra": 5.785673,
    "dec": -5.204348,
    "pmra": 556.73,
    "pmdec": -574.5,
    "rv": 0,
    "Asset Name": "ASTRID 001110",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Lovestruck",
    "Hair": "Headband",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004334,
    "y_1": -0.0000016,
    "z_1": -0.00004438,
    "Distance in Parsecs_1": 16,
    "HYG Star ID_1": 118394,
    "Gliese Catalog_1": "NN 3366"
  },
  {
    "Astrid #": "ASTRID 001111",
    "HYG Star ID": 119352,
    "Gliese Catalog": "GJ 1254",
    "Distance in Parsecs": 16,
    "mag": 12.52,
    "Absolute Visual Magnitude": 11.499,
    "Spectral Type": "m",
    "Color Index": 1.52,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.705291,
    "y": -5.932707,
    "z": 14.093111,
    "vx": -0.00001473,
    "vy": 0.00008512,
    "vz": 0.0000071,
    "rarad": 5.38277098702925,
    "decrad": 1.07776270189245,
    "pmrarad": 0.00000258362058504861,
    "pmdecrad": 0.000004386157849,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119352,
    "lum": 0.00218977755509857,
    "ra": 20.560671,
    "dec": 61.751254,
    "pmra": 532.91,
    "pmdec": 904.71,
    "rv": -29,
    "Asset Name": "ASTRID 001111",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Ram Horns",
    "Outfit": "Pharaoh",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001473,
    "y_1": 0.00008512,
    "z_1": 0.0000071,
    "Distance in Parsecs_1": 16,
    "HYG Star ID_1": 119352,
    "Gliese Catalog_1": "GJ 1254"
  },
  {
    "Astrid #": "ASTRID 001112",
    "HYG Star ID": 12896,
    "Hipparcos Catalogue": 12929,
    "Henry Draper Catalogue": 17230,
    "Gliese Catalog": "Gl 112.1",
    "Distance in Parsecs": 16.0102,
    "mag": 8.59,
    "Absolute Visual Magnitude": 7.568,
    "Spectral Type": "K5",
    "Color Index": 1.269,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.725605,
    "y": 10.400234,
    "z": 3.267271,
    "vx": -0.00000272,
    "vy": 0.00002513,
    "vz": -0.0000136,
    "rarad": 0.725567862403604,
    "decrad": 0.205516707306507,
    "pmrarad": 0.00000128713184050694,
    "pmdecrad": -0.000001018593542,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 12896,
    "lum": 0.0818087957945747,
    "ra": 2.771465,
    "dec": 11.77524,
    "pmra": 265.49,
    "pmdec": -210.1,
    "rv": 11.3,
    "Asset Name": "ASTRID 001112",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Sweetheart",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000272,
    "y_1": 0.00002513,
    "z_1": -0.0000136,
    "Distance in Parsecs_1": 16.0102,
    "HYG Star ID_1": 12896,
    "Hipparcos Catalogue_1": 12929,
    "Henry Draper Catalogue_1": 17230,
    "Gliese Catalog_1": "Gl 112.1"
  },
  {
    "Astrid #": "ASTRID 001113",
    "HYG Star ID": 34016,
    "Hipparcos Catalogue": 34104,
    "Gliese Catalog": "Gl 263",
    "Distance in Parsecs": 16.0231,
    "mag": 10.95,
    "Absolute Visual Magnitude": 9.926,
    "Spectral Type": "M5",
    "Color Index": 1.535,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.361774,
    "y": 15.137705,
    "z": -2.922259,
    "vx": 0.00003627,
    "vy": -0.00009264,
    "vz": -0.0000462,
    "rarad": 1.85133696033026,
    "decrad": -0.183413401541189,
    "pmrarad": -5.74019397777777e-7,
    "pmdecrad": -0.000003962576136,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 34016,
    "lum": 0.00932395137540632,
    "var_min": 11.007,
    "var_max": 10.887,
    "ra": 7.071586,
    "dec": -10.508814,
    "pmra": -118.4,
    "pmdec": -817.34,
    "rv": -87,
    "Asset Name": "ASTRID 001113",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Warm Smile",
    "Hair": "Ninja",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00003627,
    "y_1": -0.00009264,
    "z_1": -0.0000462,
    "Distance in Parsecs_1": 16.0231,
    "HYG Star ID_1": 34016,
    "Hipparcos Catalogue_1": 34104,
    "Gliese Catalog_1": "Gl 263"
  },
  {
    "Astrid #": "ASTRID 001114",
    "HYG Star ID": 118017,
    "Gliese Catalog": "Gl 32B",
    "Distance in Parsecs": 16.0231,
    "mag": 9.06,
    "Absolute Visual Magnitude": 8.036,
    "Spectral Type": "K7 V",
    "Color Index": 1.27,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.686146,
    "y": 2.367232,
    "z": -10.702004,
    "vx": -0.00002592,
    "vy": 0.00001356,
    "vz": 0.0000093,
    "rarad": 0.199843159839471,
    "decrad": -0.73146306212215,
    "pmrarad": 0.00000115065678943055,
    "pmdecrad": -5.14702443e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 2,
    "comp_primary": 3580,
    "base": "Gl  32",
    "lum": 0.0531618395073962,
    "ra": 0.763345,
    "dec": -41.909746,
    "pmra": 237.34,
    "pmdec": -106.17,
    "rv": -22.6,
    "Asset Name": "ASTRID 001114",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Pompadour",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00002592,
    "y_1": 0.00001356,
    "z_1": 0.0000093,
    "Distance in Parsecs_1": 16.0231,
    "HYG Star ID_1": 118017,
    "Gliese Catalog_1": "Gl 32B"
  },
  {
    "Astrid #": "ASTRID 001115",
    "HYG Star ID": 118229,
    "Gliese Catalog": "NN 3232",
    "Distance in Parsecs": 16.0256,
    "mag": 10.79,
    "Absolute Visual Magnitude": 9.766,
    "Spectral Type": "K7 V",
    "Color Index": 1.39,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.135909,
    "y": 11.960551,
    "z": 5.507571,
    "vx": 0.00000298,
    "vy": 0.0000236,
    "vz": 0.00000392,
    "rarad": 0.91849592153306,
    "decrad": 0.35080209778845,
    "pmrarad": 7.46370661215277e-7,
    "pmdecrad": -2.11204231e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118229,
    "lum": 0.0108043837237122,
    "ra": 3.508396,
    "dec": 20.09948,
    "pmra": 153.95,
    "pmdec": -43.56,
    "rv": 20.2,
    "Asset Name": "ASTRID 001115",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Afro",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000298,
    "y_1": 0.0000236,
    "z_1": 0.00000392,
    "Distance in Parsecs_1": 16.0256,
    "HYG Star ID_1": 118229,
    "Gliese Catalog_1": "NN 3232"
  },
  {
    "Astrid #": "ASTRID 001116",
    "HYG Star ID": 17397,
    "Hipparcos Catalogue": 17439,
    "Henry Draper Catalogue": 23484,
    "Gliese Catalog": "Gl 152",
    "Distance in Parsecs": 16.0282,
    "mag": 6.99,
    "Absolute Visual Magnitude": 5.966,
    "Spectral Type": "K1V",
    "Color Index": 0.87,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.028771,
    "y": 10.43559,
    "z": -9.930118,
    "vx": 0.00000863,
    "vy": 0.00004189,
    "vz": -0.0000027,
    "rarad": 0.978049029423879,
    "decrad": -0.668142971635231,
    "pmrarad": 0.00000101364844330555,
    "pmdecrad": 0.000001397184545,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17397,
    "lum": 0.35776676993611,
    "ra": 3.735872,
    "dec": -38.281772,
    "pmra": 209.08,
    "pmdec": 288.19,
    "rv": 32,
    "Asset Name": "ASTRID 001116",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Loopy",
    "Hair": "Curly",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000863,
    "y_1": 0.00004189,
    "z_1": -0.0000027,
    "Distance in Parsecs_1": 16.0282,
    "HYG Star ID_1": 17397,
    "Hipparcos Catalogue_1": 17439,
    "Henry Draper Catalogue_1": 23484,
    "Gliese Catalog_1": "Gl 152"
  },
  {
    "Astrid #": "ASTRID 001117",
    "HYG Star ID": 48340,
    "Hipparcos Catalogue": 48477,
    "Gliese Catalog": "Gl 372",
    "Distance in Parsecs": 16.0436,
    "mag": 10.53,
    "Absolute Visual Magnitude": 9.503,
    "Spectral Type": "M0",
    "Color Index": 1.503,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.621639,
    "y": 8.413195,
    "z": -1.032567,
    "vx": 0.00000618,
    "vy": 0.00000563,
    "vz": -0.0000357,
    "rarad": 2.58830737219349,
    "decrad": -0.0644045871748462,
    "pmrarad": -5.01006457486111e-7,
    "pmdecrad": -0.000002230191411,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 48340,
    "lum": 0.0137657538650941,
    "ra": 9.886606,
    "dec": -3.690111,
    "pmra": -103.34,
    "pmdec": -460.01,
    "rv": 0,
    "Asset Name": "ASTRID 001117",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Infatuated",
    "Hair": "Wavy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": 0.00000618,
    "y_1": 0.00000563,
    "z_1": -0.0000357,
    "Distance in Parsecs_1": 16.0436,
    "HYG Star ID_1": 48340,
    "Hipparcos Catalogue_1": 48477,
    "Gliese Catalog_1": "Gl 372"
  },
  {
    "Astrid #": "ASTRID 001118",
    "HYG Star ID": 64938,
    "Hipparcos Catalogue": 65143,
    "Gliese Catalog": "Gl 508.2",
    "Distance in Parsecs": 16.0436,
    "mag": 10.62,
    "Absolute Visual Magnitude": 9.593,
    "Spectral Type": "M0",
    "Color Index": 1.512,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.438011,
    "y": -4.586621,
    "z": 9.036,
    "vx": 0.00001909,
    "vy": -0.00003325,
    "vz": -0.00003236,
    "rarad": 3.49487571482282,
    "decrad": 0.598280437936006,
    "pmrarad": 0.00000235643689434027,
    "pmdecrad": -0.000001441835885,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 64938,
    "lum": 0.0126706822499854,
    "ra": 13.349442,
    "dec": 34.278944,
    "pmra": 486.05,
    "pmdec": -297.4,
    "rv": -23,
    "Asset Name": "ASTRID 001118",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Headband",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00001909,
    "y_1": -0.00003325,
    "z_1": -0.00003236,
    "Distance in Parsecs_1": 16.0436,
    "HYG Star ID_1": 64938,
    "Hipparcos Catalogue_1": 65143,
    "Gliese Catalog_1": "Gl 508.2"
  },
  {
    "Astrid #": "ASTRID 001119",
    "HYG Star ID": 70313,
    "Hipparcos Catalogue": 70536,
    "Gliese Catalog": "Gl 548B",
    "Distance in Parsecs": 16.0488,
    "mag": 9.98,
    "Absolute Visual Magnitude": 8.953,
    "Spectral Type": "M2",
    "Color Index": 1.452,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.828627,
    "y": -8.734969,
    "z": 6.430405,
    "vx": 0.00000288,
    "vy": -0.00007478,
    "vz": -0.00007662,
    "rarad": 3.77766413460852,
    "decrad": 0.412255049317331,
    "pmrarad": 0.00000385538383188194,
    "pmdecrad": -0.000005425792305,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 70306,
    "base": "Gl 548",
    "lum": 0.0228454648855182,
    "ra": 14.429614,
    "dec": 23.620474,
    "pmra": 795.23,
    "pmdec": -1119.15,
    "rv": 7.7,
    "Asset Name": "ASTRID 001119",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Pompadour",
    "Outfit": "River Spirit",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000288,
    "y_1": -0.00007478,
    "z_1": -0.00007662,
    "Distance in Parsecs_1": 16.0488,
    "HYG Star ID_1": 70313,
    "Hipparcos Catalogue_1": 70536,
    "Gliese Catalog_1": "Gl 548B"
  },
  {
    "Astrid #": "ASTRID 001120",
    "HYG Star ID": 110649,
    "Hipparcos Catalogue": 110996,
    "Henry Draper Catalogue": 213042,
    "Gliese Catalog": "Gl 862",
    "Distance in Parsecs": 16.0617,
    "mag": 7.65,
    "Absolute Visual Magnitude": 6.621,
    "Spectral Type": "K4V",
    "Color Index": 1.08,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.831293,
    "y": -5.363897,
    "z": -8.035352,
    "vx": -0.00001651,
    "vy": 0.00002562,
    "vz": -0.0000586,
    "rarad": 5.88722950486992,
    "decrad": -0.523920057433192,
    "pmrarad": 0.00000107541370620833,
    "pmdecrad": -0.000003941535222,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 110649,
    "lum": 0.195704134148067,
    "ra": 22.48756,
    "dec": -30.018408,
    "pmra": 221.82,
    "pmdec": -813,
    "rv": 7.4,
    "Asset Name": "ASTRID 001120",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Infatuated",
    "Hair": "Goddess",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001651,
    "y_1": 0.00002562,
    "z_1": -0.0000586,
    "Distance in Parsecs_1": 16.0617,
    "HYG Star ID_1": 110649,
    "Hipparcos Catalogue_1": 110996,
    "Henry Draper Catalogue_1": 213042,
    "Gliese Catalog_1": "Gl 862"
  },
  {
    "Astrid #": "ASTRID 001121",
    "HYG Star ID": 44120,
    "Hipparcos Catalogue": 44248,
    "Henry Draper Catalogue": 76943,
    "Harvard Revised Catalogue": 3579,
    "Gliese Catalog": "Gl 332A",
    "Distance in Parsecs": 16.0694,
    "mag": 3.96,
    "Absolute Visual Magnitude": 2.93,
    "Spectral Type": "F5V",
    "Color Index": 0.463,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.496677,
    "y": 8.449391,
    "z": 10.707365,
    "vx": 0.00000406,
    "vy": 0.00004954,
    "vz": 0.00000573,
    "rarad": 2.35899194145483,
    "decrad": 0.7292493664557,
    "pmrarad": -0.00000236429087596527,
    "pmdecrad": -0.00000106314792,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 44120,
    "base": "Gl 332",
    "lum": 5.86138164514028,
    "ra": 9.010685,
    "dec": 41.782911,
    "pmra": -487.67,
    "pmdec": -219.29,
    "rv": 27.1,
    "Asset Name": "ASTRID 001121",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Fire",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000406,
    "y_1": 0.00004954,
    "z_1": 0.00000573,
    "Distance in Parsecs_1": 16.0694,
    "HYG Star ID_1": 44120,
    "Hipparcos Catalogue_1": 44248,
    "Henry Draper Catalogue_1": 76943,
    "Harvard Revised Catalogue_1": 3579,
    "Gliese Catalog_1": "Gl 332A"
  },
  {
    "Astrid #": "ASTRID 001122",
    "HYG Star ID": 111042,
    "Hipparcos Catalogue": 111391,
    "Distance in Parsecs": 16.0772,
    "mag": 11.25,
    "Absolute Visual Magnitude": 10.219,
    "Color Index": 1.494,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 13.530328,
    "y": -5.329198,
    "z": -6.856109,
    "vx": -0.00001286,
    "vy": -0.00000519,
    "vz": -0.00002135,
    "rarad": 5.90797458206969,
    "decrad": -0.440563458107947,
    "pmrarad": -5.93363463631944e-7,
    "pmdecrad": -0.000001468355194,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 111042,
    "lum": 0.0071186886726228,
    "var_min": 11.329,
    "var_max": 11.169,
    "ra": 22.566801,
    "dec": -25.242427,
    "pmra": -122.39,
    "pmdec": -302.87,
    "rv": 0,
    "Asset Name": "ASTRID 001122",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Bandana",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001286,
    "y_1": -0.00000519,
    "z_1": -0.00002135,
    "Distance in Parsecs_1": 16.0772,
    "HYG Star ID_1": 111042,
    "Hipparcos Catalogue_1": 111391
  },
  {
    "Astrid #": "ASTRID 001123",
    "HYG Star ID": 119008,
    "Gliese Catalog": "Gl 579",
    "Distance in Parsecs": 16.0772,
    "mag": 10.09,
    "Absolute Visual Magnitude": 9.059,
    "Spectral Type": "K7 V",
    "Color Index": 1.41,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.968438,
    "y": -10.633576,
    "z": 6.776375,
    "vx": 0.0000057,
    "vy": 0.00010529,
    "vz": 0.0000048,
    "rarad": 3.95928637478249,
    "decrad": 0.4351904017035,
    "pmrarad": -0.00000421962435008333,
    "pmdecrad": 0.00000238733407,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 119008,
    "lum": 0.02072048897759,
    "ra": 15.12336,
    "dec": 24.934573,
    "pmra": -870.36,
    "pmdec": 492.42,
    "rv": -69.6,
    "Asset Name": "ASTRID 001123",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Braided Ponytail",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.0000057,
    "y_1": 0.00010529,
    "z_1": 0.0000048,
    "Distance in Parsecs_1": 16.0772,
    "HYG Star ID_1": 119008,
    "Gliese Catalog_1": "Gl 579"
  },
  {
    "Astrid #": "ASTRID 001124",
    "HYG Star ID": 81848,
    "Hipparcos Catalogue": 82099,
    "Gliese Catalog": "NN 3972",
    "Distance in Parsecs": 16.0823,
    "mag": 11.74,
    "Absolute Visual Magnitude": 10.708,
    "Spectral Type": "M:",
    "Color Index": 2.4,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.878777,
    "y": -14.629722,
    "z": 4.561703,
    "vx": -0.00002872,
    "vy": -0.00000126,
    "vz": -0.00003476,
    "rarad": 4.39050353838185,
    "decrad": 0.287595621688647,
    "pmrarad": -0.00000166921350215277,
    "pmdecrad": -0.000002254092726,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81848,
    "lum": 0.0045373261693794,
    "var_min": 11.855,
    "var_max": 11.655,
    "ra": 16.770488,
    "dec": 16.478015,
    "pmra": -344.3,
    "pmdec": -464.94,
    "rv": 0,
    "Asset Name": "ASTRID 001124",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Greasy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00002872,
    "y_1": -0.00000126,
    "z_1": -0.00003476,
    "Distance in Parsecs_1": 16.0823,
    "HYG Star ID_1": 81848,
    "Hipparcos Catalogue_1": 82099,
    "Gliese Catalog_1": "NN 3972"
  },
  {
    "Astrid #": "ASTRID 001125",
    "HYG Star ID": 119494,
    "Gliese Catalog": "Gl 856B",
    "Distance in Parsecs": 16.0823,
    "mag": 11.6,
    "Absolute Visual Magnitude": 10.568,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.384308,
    "y": -5.547349,
    "z": 8.631118,
    "vx": 0.00001579,
    "vy": 0.00001292,
    "vz": -0.00001436,
    "rarad": 5.86204052947283,
    "decrad": 0.5665026013736,
    "pmrarad": 0.00000113460945660416,
    "pmdecrad": -0.000001058052528,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 2,
    "comp_primary": 110180,
    "base": "Gl 856",
    "lum": 0.00516178605496672,
    "ra": 22.391345,
    "dec": 32.458208,
    "pmra": 234.03,
    "pmdec": -218.24,
    "rv": 0,
    "Asset Name": "ASTRID 001125",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Ponytail",
    "Outfit": "Emperor",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001579,
    "y_1": 0.00001292,
    "z_1": -0.00001436,
    "Distance in Parsecs_1": 16.0823,
    "HYG Star ID_1": 119494,
    "Gliese Catalog_1": "Gl 856B"
  },
  {
    "Astrid #": "ASTRID 001126",
    "HYG Star ID": 114049,
    "Hipparcos Catalogue": 114411,
    "Gliese Catalog": "Gl 891",
    "Distance in Parsecs": 16.0849,
    "mag": 11.27,
    "Absolute Visual Magnitude": 10.238,
    "Color Index": 1.566,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.126141,
    "y": -3.114725,
    "z": -7.033821,
    "vx": 0.00001268,
    "vy": 0.00005444,
    "vz": 0.00000136,
    "rarad": 6.06616073893825,
    "decrad": -0.452586890756142,
    "pmrarad": 0.00000347519294359027,
    "pmdecrad": 9.4199298e-8,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 114049,
    "lum": 0.00699519781051212,
    "var_min": 11.347,
    "var_max": 11.177,
    "ra": 23.171027,
    "dec": -25.931319,
    "pmra": 716.81,
    "pmdec": 19.43,
    "rv": 0,
    "Asset Name": "ASTRID 001126",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Sweet Nap",
    "Hair": "Halo Boy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00001268,
    "y_1": 0.00005444,
    "z_1": 0.00000136,
    "Distance in Parsecs_1": 16.0849,
    "HYG Star ID_1": 114049,
    "Hipparcos Catalogue_1": 114411,
    "Gliese Catalog_1": "Gl 891"
  },
  {
    "Astrid #": "ASTRID 001127",
    "HYG Star ID": 94444,
    "Hipparcos Catalogue": 94739,
    "Henry Draper Catalogue": 179930,
    "Gliese Catalog": "Gl 750A",
    "Distance in Parsecs": 16.0979,
    "mag": 9.38,
    "Absolute Visual Magnitude": 8.346,
    "Spectral Type": "K9V...",
    "Color Index": 1.432,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.680929,
    "y": -10.582772,
    "z": -11.558006,
    "vx": -3.1e-7,
    "vy": 0.00005212,
    "vz": 0.0000046,
    "rarad": 5.04711889282799,
    "decrad": -0.800920163015408,
    "pmrarad": 0.00000104569462759027,
    "pmdecrad": -0.000002001601761,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 94444,
    "base": "Gl 750",
    "lum": 0.0399576556618805,
    "ra": 19.278574,
    "dec": -45.889345,
    "pmra": 215.69,
    "pmdec": -412.86,
    "rv": -36.8,
    "Asset Name": "ASTRID 001127",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oof",
    "Hair": "Little Crown",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -3.1e-7,
    "y_1": 0.00005212,
    "z_1": 0.0000046,
    "Distance in Parsecs_1": 16.0979,
    "HYG Star ID_1": 94444,
    "Hipparcos Catalogue_1": 94739,
    "Henry Draper Catalogue_1": 179930,
    "Gliese Catalog_1": "Gl 750A"
  },
  {
    "Astrid #": "ASTRID 001128",
    "HYG Star ID": 118908,
    "Gliese Catalog": "GJ 1174",
    "Distance in Parsecs": 16.1031,
    "mag": 12.76,
    "Absolute Visual Magnitude": 11.725,
    "Spectral Type": "M3",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.533571,
    "y": -4.920992,
    "z": 11.138601,
    "vx": 0.00000634,
    "vy": 0.00009777,
    "vz": -0.00001286,
    "rarad": 3.57873751738687,
    "decrad": 0.7639755491925,
    "pmrarad": -0.00000533353226252777,
    "pmdecrad": 0.00000144908385,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118908,
    "lum": 0.00177827941003892,
    "ra": 13.66977,
    "dec": 43.772575,
    "pmra": -1100.12,
    "pmdec": 298.89,
    "rv": -42,
    "Asset Name": "ASTRID 001128",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "High Fade",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000634,
    "y_1": 0.00009777,
    "z_1": -0.00001286,
    "Distance in Parsecs_1": 16.1031,
    "HYG Star ID_1": 118908,
    "Gliese Catalog_1": "GJ 1174"
  },
  {
    "Astrid #": "ASTRID 001129",
    "HYG Star ID": 119248,
    "Henry Draper Catalogue": 230017,
    "Gliese Catalog": "Gl 734A",
    "Distance in Parsecs": 16.1031,
    "mag": 9.44,
    "Absolute Visual Magnitude": 8.405,
    "Spectral Type": "dM0",
    "Color Index": 1.36,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.751056,
    "y": -15.355827,
    "z": 3.064941,
    "vx": 0.00000272,
    "vy": 0.0000282,
    "vz": -0.00000602,
    "rarad": 4.95197234431368,
    "decrad": 0.1915171819093,
    "pmrarad": 5.79546273736111e-7,
    "pmdecrad": -5.0701814e-8,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 119248,
    "base": "Gl 734",
    "lum": 0.0378442584717093,
    "ra": 18.915141,
    "dec": 10.973126,
    "pmra": 119.54,
    "pmdec": -10.46,
    "rv": -26.8,
    "Asset Name": "ASTRID 001129",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "High Bun",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000272,
    "y_1": 0.0000282,
    "z_1": -0.00000602,
    "Distance in Parsecs_1": 16.1031,
    "HYG Star ID_1": 119248,
    "Henry Draper Catalogue_1": 230017,
    "Gliese Catalog_1": "Gl 734A"
  },
  {
    "Astrid #": "ASTRID 001130",
    "HYG Star ID": 119249,
    "Gliese Catalog": "Gl 734B",
    "Distance in Parsecs": 16.1031,
    "mag": 12.3,
    "Absolute Visual Magnitude": 11.265,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.751375,
    "y": -15.357134,
    "z": 3.065202,
    "vx": 0.0000091,
    "vy": 0.00000206,
    "vz": -8e-7,
    "rarad": 4.95197234431368,
    "decrad": 0.1915171819093,
    "pmrarad": 5.79546273736111e-7,
    "pmdecrad": -5.0701814e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119248,
    "base": "Gl 734",
    "lum": 0.00271643926883907,
    "ra": 18.915141,
    "dec": 10.973126,
    "pmra": 119.54,
    "pmdec": -10.46,
    "rv": 0,
    "Asset Name": "ASTRID 001130",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Halo Boy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000091,
    "y_1": 0.00000206,
    "z_1": -8e-7,
    "Distance in Parsecs_1": 16.1031,
    "HYG Star ID_1": 119249,
    "Gliese Catalog_1": "Gl 734B"
  },
  {
    "Astrid #": "ASTRID 001131",
    "HYG Star ID": 17653,
    "Hipparcos Catalogue": 17695,
    "Distance in Parsecs": 16.129,
    "mag": 11.59,
    "Absolute Visual Magnitude": 10.552,
    "Color Index": 1.511,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.815285,
    "y": 13.495454,
    "z": -0.555074,
    "vx": -0.00001262,
    "vy": 0.00000737,
    "vz": -0.00002124,
    "rarad": 0.992172043734336,
    "decrad": -0.0344214188087162,
    "pmrarad": 9.05341067069444e-7,
    "pmdecrad": -0.000001317578139,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17653,
    "lum": 0.0052384161902779,
    "var_min": 11.692,
    "var_max": 11.492,
    "ra": 3.789818,
    "dec": -1.972202,
    "pmra": 186.74,
    "pmdec": -271.77,
    "rv": 0,
    "Asset Name": "ASTRID 001131",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Pompadour",
    "Outfit": "Emperor",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001262,
    "y_1": 0.00000737,
    "z_1": -0.00002124,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 17653,
    "Hipparcos Catalogue_1": 17695
  },
  {
    "Astrid #": "ASTRID 001132",
    "HYG Star ID": 118002,
    "Gliese Catalog": "GJ 2010",
    "Distance in Parsecs": 16.129,
    "mag": 14.53,
    "Absolute Visual Magnitude": 13.492,
    "Spectral Type": "DA7",
    "Color Index": 0.45,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 14.867836,
    "y": 2.449012,
    "z": -5.752778,
    "vx": -0.00000262,
    "vy": -0.00001897,
    "vz": -0.00001486,
    "rarad": 0.163256782495648,
    "decrad": -0.36470418421835,
    "pmrarad": -0.00000113427008702777,
    "pmdecrad": -9.86004367e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118002,
    "lum": 0.000349301137563553,
    "ra": 0.623595,
    "dec": -20.896011,
    "pmra": -233.96,
    "pmdec": -203.38,
    "rv": 0,
    "Asset Name": "ASTRID 001132",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Fire",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000262,
    "y_1": -0.00001897,
    "z_1": -0.00001486,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118002,
    "Gliese Catalog_1": "GJ 2010"
  },
  {
    "Astrid #": "ASTRID 001133",
    "HYG Star ID": 118052,
    "Gliese Catalog": "GJ 1033",
    "Distance in Parsecs": 16.129,
    "mag": 14.16,
    "Absolute Visual Magnitude": 13.122,
    "Color Index": 1.58,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.101712,
    "y": 4.678785,
    "z": -6.276568,
    "vx": -0.00000212,
    "vy": 0.0000067,
    "vz": 2.3e-7,
    "rarad": 0.320357662071214,
    "decrad": -0.39970657584255,
    "pmrarad": 4.36041424291666e-7,
    "pmdecrad": 1.5223149e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118052,
    "lum": 0.000491133999646997,
    "ra": 1.223676,
    "dec": -22.9015,
    "pmra": 89.94,
    "pmdec": 3.14,
    "rv": 0,
    "Asset Name": "ASTRID 001133",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Bandana",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000212,
    "y_1": 0.0000067,
    "z_1": 2.3e-7,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118052,
    "Gliese Catalog_1": "GJ 1033"
  },
  {
    "Astrid #": "ASTRID 001134",
    "HYG Star ID": 118176,
    "Gliese Catalog": "NN 3183",
    "Distance in Parsecs": 16.129,
    "mag": 13.96,
    "Absolute Visual Magnitude": 12.922,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.275121,
    "y": 9.56711,
    "z": 7.939579,
    "vx": 0.00000853,
    "vy": 1.2e-7,
    "vz": -0.00001119,
    "rarad": 0.749733865395223,
    "decrad": 0.514678341103999,
    "pmrarad": -3.54932095534722e-7,
    "pmdecrad": -7.9721792e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118176,
    "lum": 0.000590472940406986,
    "ra": 2.863772,
    "dec": 29.488897,
    "pmra": -73.21,
    "pmdec": -164.44,
    "rv": 0,
    "Asset Name": "ASTRID 001134",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Oh Snap",
    "Hair": "Curly",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000853,
    "y_1": 1.2e-7,
    "z_1": -0.00001119,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118176,
    "Gliese Catalog_1": "NN 3183"
  },
  {
    "Astrid #": "ASTRID 001135",
    "HYG Star ID": 118219,
    "Gliese Catalog": "NN 3223",
    "Distance in Parsecs": 16.129,
    "mag": 16.12,
    "Absolute Visual Magnitude": 15.082,
    "Spectral Type": "DZ9",
    "Color Index": 0.8,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.08065,
    "y": 12.580265,
    "z": -0.511931,
    "vx": -0.00001665,
    "vy": 0.00001061,
    "vz": -0.00006754,
    "rarad": 0.895259196933525,
    "decrad": -0.0317451475054,
    "pmrarad": 0.00000121731867050694,
    "pmdecrad": -0.000004190070108,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118219,
    "lum": 0.0000807606861328975,
    "ra": 3.419638,
    "dec": -1.818863,
    "pmra": 251.09,
    "pmdec": -864.26,
    "rv": 0,
    "Asset Name": "ASTRID 001135",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Serious Shinobi",
    "Hair": "Braided Bun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001665,
    "y_1": 0.00001061,
    "z_1": -0.00006754,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118219,
    "Gliese Catalog_1": "NN 3223"
  },
  {
    "Astrid #": "ASTRID 001136",
    "HYG Star ID": 118490,
    "Gliese Catalog": "NN 3455",
    "Distance in Parsecs": 16.129,
    "mag": 12.45,
    "Absolute Visual Magnitude": 11.412,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.053308,
    "y": 9.085985,
    "z": -12.69489,
    "vx": -0.00004012,
    "vy": 0.00001313,
    "vz": 0.0000222,
    "rarad": 1.99034661021747,
    "decrad": -0.90606857967815,
    "pmrarad": 0.00000194022434958333,
    "pmdecrad": 0.000002231946437,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118490,
    "lum": 0.0023724660151048,
    "ra": 7.602564,
    "dec": -51.913906,
    "pmra": 400.2,
    "pmdec": 460.37,
    "rv": 0,
    "Asset Name": "ASTRID 001136",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Elegant",
    "Outfit": "Warlock",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004012,
    "y_1": 0.00001313,
    "z_1": 0.0000222,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118490,
    "Gliese Catalog_1": "NN 3455"
  },
  {
    "Astrid #": "ASTRID 001137",
    "HYG Star ID": 118584,
    "Gliese Catalog": "GJ 1118",
    "Distance in Parsecs": 16.129,
    "mag": 13.8,
    "Absolute Visual Magnitude": 12.762,
    "Spectral Type": "k-m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.716045,
    "y": 9.793298,
    "z": -8.361633,
    "vx": -0.00004821,
    "vy": -0.00002768,
    "vz": -0.00007509,
    "rarad": 2.35220658331588,
    "decrad": -0.54490883250755,
    "pmrarad": 0.00000333110631772916,
    "pmdecrad": -0.000003522530151,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118584,
    "lum": 0.00068422667276595,
    "ra": 8.984767,
    "dec": -31.220976,
    "pmra": 687.09,
    "pmdec": -726.57,
    "rv": 50,
    "Asset Name": "ASTRID 001137",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Elegant",
    "Outfit": "Emperor",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004821,
    "y_1": -0.00002768,
    "z_1": -0.00007509,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118584,
    "Gliese Catalog_1": "GJ 1118"
  },
  {
    "Astrid #": "ASTRID 001138",
    "HYG Star ID": 118605,
    "Gliese Catalog": "NN 3540",
    "Distance in Parsecs": 16.129,
    "mag": 12.29,
    "Absolute Visual Magnitude": 11.252,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.594398,
    "y": 9.534857,
    "z": 7.549164,
    "vx": -0.00000182,
    "vy": 0.00002201,
    "vz": -0.00003034,
    "rarad": 2.40878900379503,
    "decrad": 0.4870818467639,
    "pmrarad": -9.39084099236111e-7,
    "pmdecrad": -0.000002129204722,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118605,
    "lum": 0.00274915989655103,
    "ra": 9.200896,
    "dec": 27.907734,
    "pmra": -193.7,
    "pmdec": -439.18,
    "rv": 0,
    "Asset Name": "ASTRID 001138",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Braided Bun",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000182,
    "y_1": 0.00002201,
    "z_1": -0.00003034,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118605,
    "Gliese Catalog_1": "NN 3540"
  },
  {
    "Astrid #": "ASTRID 001139",
    "HYG Star ID": 118655,
    "Gliese Catalog": "NN 3573",
    "Distance in Parsecs": 16.129,
    "mag": 14.2,
    "Absolute Visual Magnitude": 13.162,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.773795,
    "y": 7.641466,
    "z": 6.211508,
    "vx": 0.00001153,
    "vy": 0.00003488,
    "vz": -0.00001918,
    "rarad": 2.60249653619152,
    "decrad": 0.3953316142619,
    "pmrarad": -0.00000222301616945138,
    "pmdecrad": -0.000001288634762,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118655,
    "lum": 0.0004733692033483,
    "ra": 9.940805,
    "dec": 22.650833,
    "pmra": -458.53,
    "pmdec": -265.8,
    "rv": 0,
    "Asset Name": "ASTRID 001139",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Windy",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001153,
    "y_1": 0.00003488,
    "z_1": -0.00001918,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118655,
    "Gliese Catalog_1": "NN 3573"
  },
  {
    "Astrid #": "ASTRID 001140",
    "HYG Star ID": 118795,
    "Gliese Catalog": "NN 3702",
    "Distance in Parsecs": 16.129,
    "mag": 14,
    "Absolute Visual Magnitude": 12.962,
    "Spectral Type": "M4.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.521206,
    "y": -0.233962,
    "z": -8.790159,
    "vx": -0.00000408,
    "vy": 0.0000591,
    "vz": 0.00000469,
    "rarad": 3.15892956671244,
    "decrad": -0.576378276577999,
    "pmrarad": -0.0000036682457511875,
    "pmdecrad": 3.4674844e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118795,
    "lum": 0.00056911495762884,
    "ra": 12.066222,
    "dec": -33.024043,
    "pmra": -756.63,
    "pmdec": 71.52,
    "rv": 0,
    "Asset Name": "ASTRID 001140",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Spiky",
    "Outfit": "Emperor",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000408,
    "y_1": 0.0000591,
    "z_1": 0.00000469,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118795,
    "Gliese Catalog_1": "NN 3702"
  },
  {
    "Astrid #": "ASTRID 001141",
    "HYG Star ID": 118832,
    "Gliese Catalog": "GJ 1161B",
    "Distance in Parsecs": 16.129,
    "mag": 11.91,
    "Absolute Visual Magnitude": 10.872,
    "Color Index": 1.6,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -13.067981,
    "y": -2.047772,
    "z": -9.229255,
    "vx": 6.5e-7,
    "vy": 0.00001656,
    "vz": -0.00000459,
    "rarad": 3.29704115695315,
    "decrad": -0.6092041741475,
    "pmrarad": -0.00000100846093692361,
    "pmdecrad": -3.4724295e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 2,
    "comp_primary": 61258,
    "base": "GJ 1161",
    "lum": 0.00390121602952675,
    "ra": 12.59377,
    "dec": -34.904828,
    "pmra": -208.01,
    "pmdec": -71.62,
    "rv": 0,
    "Asset Name": "ASTRID 001141",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Bandana",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 6.5e-7,
    "y_1": 0.00001656,
    "z_1": -0.00000459,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118832,
    "Gliese Catalog_1": "GJ 1161B"
  },
  {
    "Astrid #": "ASTRID 001142",
    "HYG Star ID": 118867,
    "Gliese Catalog": "NN 3764",
    "Distance in Parsecs": 16.129,
    "mag": 12.86,
    "Absolute Visual Magnitude": 11.822,
    "Spectral Type": "M3.5",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.768287,
    "y": -3.672709,
    "z": -10.400159,
    "vx": 0.00006227,
    "vy": -0.00004005,
    "vz": -0.00005636,
    "rarad": 3.44404547833452,
    "decrad": -0.70077617198675,
    "pmrarad": 0.00000352008669040972,
    "pmdecrad": -0.000004570939735,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118867,
    "lum": 0.0016262975210134,
    "ra": 13.155285,
    "dec": -40.151517,
    "pmra": 726.07,
    "pmdec": -942.82,
    "rv": 0,
    "Asset Name": "ASTRID 001142",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Stunned",
    "Hair": "Undercut",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00006227,
    "y_1": -0.00004005,
    "z_1": -0.00005636,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118867,
    "Gliese Catalog_1": "NN 3764"
  },
  {
    "Astrid #": "ASTRID 001143",
    "HYG Star ID": 118930,
    "Gliese Catalog": "NN 3813",
    "Distance in Parsecs": 16.129,
    "mag": 11.63,
    "Absolute Visual Magnitude": 10.592,
    "Spectral Type": "M2",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.772845,
    "y": -7.370406,
    "z": 4.01628,
    "vx": -0.00000347,
    "vy": -0.00000616,
    "vz": -0.00002319,
    "rarad": 3.63295543030768,
    "decrad": 0.251657780146449,
    "pmrarad": 2.35086153701388e-7,
    "pmdecrad": -0.000001484417071,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 118930,
    "lum": 0.00504893756201154,
    "ra": 13.876868,
    "dec": 14.418929,
    "pmra": 48.49,
    "pmdec": -306.18,
    "rv": 0,
    "Asset Name": "ASTRID 001143",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Charmer",
    "Hair": "High Fade",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000347,
    "y_1": -0.00000616,
    "z_1": -0.00002319,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 118930,
    "Gliese Catalog_1": "NN 3813"
  },
  {
    "Astrid #": "ASTRID 001144",
    "HYG Star ID": 119000,
    "Gliese Catalog": "NN 3884",
    "Distance in Parsecs": 16.129,
    "mag": 12.73,
    "Absolute Visual Magnitude": 11.692,
    "Spectral Type": "M3.5",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.257409,
    "y": -11.374144,
    "z": 2.011027,
    "vx": -0.00002795,
    "vy": 0.00002642,
    "vz": -0.000007,
    "rarad": 3.93214975474001,
    "decrad": 0.12500923360105,
    "pmrarad": -0.00000238426519960416,
    "pmdecrad": -4.37587979e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119000,
    "lum": 0.00183315842867085,
    "ra": 15.019706,
    "dec": 7.162501,
    "pmra": -491.79,
    "pmdec": -90.26,
    "rv": 0,
    "Asset Name": "ASTRID 001144",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Prince",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002795,
    "y_1": 0.00002642,
    "z_1": -0.000007,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 119000,
    "Gliese Catalog_1": "NN 3884"
  },
  {
    "Astrid #": "ASTRID 001145",
    "HYG Star ID": 119025,
    "Gliese Catalog": "NN 3910",
    "Distance in Parsecs": 16.129,
    "mag": 14.3,
    "Absolute Visual Magnitude": 13.262,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.506697,
    "y": -11.27869,
    "z": 7.782797,
    "vx": -0.00003441,
    "vy": 0.00002815,
    "vz": 0.0000032,
    "rarad": 4.06620734552217,
    "decrad": 0.50354592336905,
    "pmrarad": -0.0000027541295565,
    "pmdecrad": 2.26427381e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119025,
    "lum": 0.000431717844470922,
    "ra": 15.531768,
    "dec": 28.851056,
    "pmra": -568.08,
    "pmdec": 46.7,
    "rv": 0,
    "Asset Name": "ASTRID 001145",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "High Bun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003441,
    "y_1": 0.00002815,
    "z_1": 0.0000032,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 119025,
    "Gliese Catalog_1": "NN 3910"
  },
  {
    "Astrid #": "ASTRID 001146",
    "HYG Star ID": 119072,
    "Gliese Catalog": "NN 3945",
    "Distance in Parsecs": 16.129,
    "mag": 12.96,
    "Absolute Visual Magnitude": 11.922,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.304351,
    "y": -12.693929,
    "z": -7.698309,
    "vx": -0.00001948,
    "vy": 0.00002507,
    "vz": -0.0000254,
    "rarad": 4.2514427792741,
    "decrad": -0.49757512620425,
    "pmrarad": -0.00000177325451799999,
    "pmdecrad": -0.000001791944085,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119072,
    "lum": 0.00148320096718187,
    "ra": 16.239315,
    "dec": -28.508955,
    "pmra": -365.76,
    "pmdec": -369.62,
    "rv": 0,
    "Asset Name": "ASTRID 001146",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Afro",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001948,
    "y_1": 0.00002507,
    "z_1": -0.0000254,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 119072,
    "Gliese Catalog_1": "NN 3945"
  },
  {
    "Astrid #": "ASTRID 001147",
    "HYG Star ID": 119211,
    "Gliese Catalog": "GJ 1226",
    "Distance in Parsecs": 16.129,
    "mag": 12.71,
    "Absolute Visual Magnitude": 11.672,
    "Spectral Type": "M3.5",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.472545,
    "y": -16.061465,
    "z": -0.29681,
    "vx": -0.0000348,
    "vy": -0.00005318,
    "vz": -0.00007555,
    "rarad": 4.80381524727919,
    "decrad": -0.0184003850872,
    "pmrarad": -0.00000245005441605555,
    "pmdecrad": -0.000004627381744,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119211,
    "lum": 0.00186723938838294,
    "ra": 18.349223,
    "dec": -1.054264,
    "pmra": -505.36,
    "pmdec": -954.47,
    "rv": 50,
    "Asset Name": "ASTRID 001147",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Ram Horns",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000348,
    "y_1": -0.00005318,
    "z_1": -0.00007555,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 119211,
    "Gliese Catalog_1": "GJ 1226"
  },
  {
    "Astrid #": "ASTRID 001148",
    "HYG Star ID": 119417,
    "Gliese Catalog": "NN 4198",
    "Distance in Parsecs": 16.129,
    "mag": 13.19,
    "Absolute Visual Magnitude": 12.152,
    "Spectral Type": "M3",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.724578,
    "y": -7.406028,
    "z": -10.522738,
    "vx": -0.00000801,
    "vy": 0.00010679,
    "vz": -0.00008264,
    "rarad": 5.63223587223767,
    "decrad": -0.71076151194905,
    "pmrarad": 0.00000496536475351388,
    "pmdecrad": -0.000006759538981,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119417,
    "lum": 0.00120005182020426,
    "ra": 21.513556,
    "dec": -40.723635,
    "pmra": 1024.18,
    "pmdec": -1394.26,
    "rv": 0,
    "Asset Name": "ASTRID 001148",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Braided Ponytail",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000801,
    "y_1": 0.00010679,
    "z_1": -0.00008264,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 119417,
    "Gliese Catalog_1": "NN 4198"
  },
  {
    "Astrid #": "ASTRID 001149",
    "HYG Star ID": 119504,
    "Gliese Catalog": "GJ 1269B",
    "Distance in Parsecs": 16.129,
    "mag": 10.1,
    "Absolute Visual Magnitude": 9.062,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.512292,
    "y": -6.150574,
    "z": 3.421761,
    "vx": 0.00000621,
    "vy": 0.00001434,
    "vz": -5.3e-7,
    "rarad": 5.88231495327923,
    "decrad": 0.2137740902427,
    "pmrarad": 9.69045584694444e-7,
    "pmdecrad": -3.3835146e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 2,
    "comp_primary": 110554,
    "base": "GJ 1269",
    "lum": 0.0206633151756448,
    "ra": 22.468788,
    "dec": 12.248353,
    "pmra": 199.88,
    "pmdec": -6.98,
    "rv": 0,
    "Asset Name": "ASTRID 001149",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Angelic",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000621,
    "y_1": 0.00001434,
    "z_1": -5.3e-7,
    "Distance in Parsecs_1": 16.129,
    "HYG Star ID_1": 119504,
    "Gliese Catalog_1": "GJ 1269B"
  },
  {
    "Astrid #": "ASTRID 001150",
    "HYG Star ID": 119156,
    "Gliese Catalog": "Gl 676B",
    "Distance in Parsecs": 16.1342,
    "mag": 13.31,
    "Absolute Visual Magnitude": 12.271,
    "Spectral Type": "m",
    "Color Index": 1.51,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.295745,
    "y": -9.929569,
    "z": -12.650578,
    "vx": -0.00001833,
    "vy": 0.00001136,
    "vz": -0.00000704,
    "rarad": 4.58266579652206,
    "decrad": -0.9012198379918,
    "pmrarad": -0.00000121760955871527,
    "pmdecrad": -7.02979836e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 85387,
    "base": "Gl 676",
    "lum": 0.00107547420897278,
    "ra": 17.504494,
    "dec": -51.636093,
    "pmra": -251.15,
    "pmdec": -145,
    "rv": 0,
    "Asset Name": "ASTRID 001150",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Fired Up",
    "Hair": "Pompadour",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001833,
    "y_1": 0.00001136,
    "z_1": -0.00000704,
    "Distance in Parsecs_1": 16.1342,
    "HYG Star ID_1": 119156,
    "Gliese Catalog_1": "Gl 676B"
  },
  {
    "Astrid #": "ASTRID 001151",
    "HYG Star ID": 65150,
    "Hipparcos Catalogue": 65355,
    "Henry Draper Catalogue": 116443,
    "Gliese Catalog": "NN 3782B",
    "Distance in Parsecs": 16.1421,
    "mag": 7.35,
    "Absolute Visual Magnitude": 6.31,
    "Spectral Type": "G5",
    "Color Index": 0.869,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.060939,
    "y": -5.757294,
    "z": 0.767511,
    "vx": 8.3e-7,
    "vy": -7e-8,
    "vz": 0.00001586,
    "rarad": 3.50671875528908,
    "decrad": 0.04756508156403,
    "pmrarad": 2.22044665694444e-8,
    "pmdecrad": 9.83493032e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 2,
    "comp_primary": 65150,
    "base": "NN 3782",
    "lum": 0.260615354999889,
    "ra": 13.394679,
    "dec": 2.725278,
    "pmra": 4.58,
    "pmdec": 202.86,
    "rv": 0,
    "Asset Name": "ASTRID 001151",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Manbun",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 8.3e-7,
    "y_1": -7e-8,
    "z_1": 0.00001586,
    "Distance in Parsecs_1": 16.1421,
    "HYG Star ID_1": 65150,
    "Hipparcos Catalogue_1": 65355,
    "Henry Draper Catalogue_1": 116443,
    "Gliese Catalog_1": "NN 3782B"
  },
  {
    "Astrid #": "ASTRID 001152",
    "HYG Star ID": 118279,
    "Gliese Catalog": "Gl 165A",
    "Distance in Parsecs": 16.1551,
    "mag": 13.67,
    "Absolute Visual Magnitude": 12.628,
    "Spectral Type": "M3.5",
    "Color Index": 1.75,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.61553,
    "y": 9.173809,
    "z": 12.470981,
    "vx": 0.00003359,
    "vy": -0.00000297,
    "vz": -0.00001024,
    "rarad": 1.10471117508624,
    "decrad": 0.8819080263674,
    "pmrarad": -0.00000194041827505555,
    "pmdecrad": -9.97232652e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118279,
    "base": "Gl 165",
    "lum": 0.000774105227074121,
    "ra": 4.219686,
    "dec": 50.529608,
    "pmra": -400.24,
    "pmdec": -205.69,
    "rv": 0,
    "Asset Name": "ASTRID 001152",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Audiophile",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003359,
    "y_1": -0.00000297,
    "z_1": -0.00001024,
    "Distance in Parsecs_1": 16.1551,
    "HYG Star ID_1": 118279,
    "Gliese Catalog_1": "Gl 165A"
  },
  {
    "Astrid #": "ASTRID 001153",
    "HYG Star ID": 61098,
    "Hipparcos Catalogue": 61291,
    "Henry Draper Catalogue": 109200,
    "Gliese Catalog": "Gl 472",
    "Distance in Parsecs": 16.176,
    "mag": 7.13,
    "Absolute Visual Magnitude": 6.086,
    "Spectral Type": "K0V",
    "Color Index": 0.836,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.79868,
    "y": -0.854286,
    "z": -15.076756,
    "vx": 0.00001619,
    "vy": 0.00004561,
    "vz": -0.00000914,
    "rarad": 3.28790531575176,
    "decrad": -1.20001520795975,
    "pmrarad": -0.00000264402836964583,
    "pmdecrad": -0.000001511309686,
    "con": "Mus",
    "Full Constellation Name Formula 1": "Musca",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Musca",
    "comp": 1,
    "comp_primary": 61098,
    "lum": 0.320331760085281,
    "ra": 12.558873,
    "dec": -68.755807,
    "pmra": -545.37,
    "pmdec": -311.73,
    "rv": 0.3,
    "Asset Name": "ASTRID 001153",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Headband",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Musca",
    "x_1": 0.00001619,
    "y_1": 0.00004561,
    "z_1": -0.00000914,
    "Distance in Parsecs_1": 16.176,
    "HYG Star ID_1": 61098,
    "Hipparcos Catalogue_1": 61291,
    "Henry Draper Catalogue_1": 109200,
    "Gliese Catalog_1": "Gl 472"
  },
  {
    "Astrid #": "ASTRID 001154",
    "HYG Star ID": 118968,
    "Gliese Catalog": "GJ 1183A",
    "Distance in Parsecs": 16.1812,
    "mag": 13.95,
    "Absolute Visual Magnitude": 12.905,
    "Spectral Type": "m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.925841,
    "y": -9.733579,
    "z": -0.106264,
    "vx": -0.00001889,
    "vy": 0.000025,
    "vz": 0.00000723,
    "rarad": 3.78703171889137,
    "decrad": -0.00656716305954999,
    "pmrarad": -0.00000193678217245138,
    "pmdecrad": 4.47138809e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118968,
    "base": "GJ 1183",
    "lum": 0.000599791076255509,
    "ra": 14.465396,
    "dec": -0.376271,
    "pmra": -399.49,
    "pmdec": 92.23,
    "rv": 0,
    "Asset Name": "ASTRID 001154",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Unkempt",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001889,
    "y_1": 0.000025,
    "z_1": 0.00000723,
    "Distance in Parsecs_1": 16.1812,
    "HYG Star ID_1": 118968,
    "Gliese Catalog_1": "GJ 1183A"
  },
  {
    "Astrid #": "ASTRID 001155",
    "HYG Star ID": 118969,
    "Gliese Catalog": "GJ 1183B",
    "Distance in Parsecs": 16.1812,
    "mag": 14.03,
    "Absolute Visual Magnitude": 12.985,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.925137,
    "y": -9.73452,
    "z": -0.10579,
    "vx": -0.00001889,
    "vy": 0.000025,
    "vz": 0.00000723,
    "rarad": 3.78710435789137,
    "decrad": -0.00653786505955,
    "pmrarad": -0.00000193678217245138,
    "pmdecrad": 4.47138809e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118968,
    "base": "GJ 1183",
    "lum": 0.000557185748931929,
    "ra": 14.465673,
    "dec": -0.374592,
    "pmra": -399.49,
    "pmdec": 92.23,
    "rv": 0,
    "Asset Name": "ASTRID 001155",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Unkempt",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001889,
    "y_1": 0.000025,
    "z_1": 0.00000723,
    "Distance in Parsecs_1": 16.1812,
    "HYG Star ID_1": 118969,
    "Gliese Catalog_1": "GJ 1183B"
  },
  {
    "Astrid #": "ASTRID 001156",
    "HYG Star ID": 52190,
    "Hipparcos Catalogue": 52341,
    "Gliese Catalog": "GJ 1136A",
    "Distance in Parsecs": 16.1838,
    "mag": 10.19,
    "Absolute Visual Magnitude": 9.145,
    "Spectral Type": "K7V",
    "Color Index": 1.46,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.239524,
    "y": 4.342422,
    "z": -9.65675,
    "vx": -6.4e-7,
    "vy": 0.00000772,
    "vz": 0.00000429,
    "rarad": 2.80066073939541,
    "decrad": -0.639372952964911,
    "pmrarad": -4.36526237972222e-7,
    "pmdecrad": 3.30061153e-7,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 52190,
    "base": "GJ 1136",
    "lum": 0.0191425592502108,
    "var_min": 10.355,
    "var_max": 10.095,
    "ra": 10.697736,
    "dec": -36.633372,
    "pmra": -90.04,
    "pmdec": 68.08,
    "rv": 0,
    "Asset Name": "ASTRID 001156",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Oof",
    "Hair": "Slick",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": -6.4e-7,
    "y_1": 0.00000772,
    "z_1": 0.00000429,
    "Distance in Parsecs_1": 16.1838,
    "HYG Star ID_1": 52190,
    "Hipparcos Catalogue_1": 52341,
    "Gliese Catalog_1": "GJ 1136A"
  },
  {
    "Astrid #": "ASTRID 001157",
    "HYG Star ID": 108407,
    "Hipparcos Catalogue": 108752,
    "Gliese Catalog": "Gl 844",
    "Distance in Parsecs": 16.1838,
    "mag": 10.65,
    "Absolute Visual Magnitude": 9.605,
    "Spectral Type": "M2",
    "Color Index": 1.498,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.501678,
    "y": -7.653087,
    "z": 4.587594,
    "vx": -4.3e-7,
    "vy": 0.00003562,
    "vz": 0.0000073,
    "rarad": 5.76751583698717,
    "decrad": 0.287411257864345,
    "pmrarad": 0.00000190182710608333,
    "pmdecrad": 7.46613068e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 108407,
    "lum": 0.0125314117494141,
    "var_min": 10.692,
    "var_max": 10.562,
    "ra": 22.030288,
    "dec": 16.467452,
    "pmra": 392.28,
    "pmdec": 154,
    "rv": -14.8,
    "Asset Name": "ASTRID 001157",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Side Part",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -4.3e-7,
    "y_1": 0.00003562,
    "z_1": 0.0000073,
    "Distance in Parsecs_1": 16.1838,
    "HYG Star ID_1": 108407,
    "Hipparcos Catalogue_1": 108752,
    "Gliese Catalog_1": "Gl 844"
  },
  {
    "Astrid #": "ASTRID 001158",
    "HYG Star ID": 118516,
    "Gliese Catalog": "Gl 294B",
    "Distance in Parsecs": 16.1917,
    "mag": 9.88,
    "Absolute Visual Magnitude": 8.834,
    "Spectral Type": "k",
    "Color Index": 1.34,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.948324,
    "y": 6.984045,
    "z": -14.064317,
    "vx": -0.00003842,
    "vy": -0.0000131,
    "vz": 0.00000427,
    "rarad": 2.08521120970063,
    "decrad": -1.05239561239715,
    "pmrarad": 0.00000246411401279166,
    "pmdecrad": 5.32752057e-7,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 2,
    "comp_primary": 38795,
    "base": "Gl 294",
    "lum": 0.0254917705050911,
    "ra": 7.96492,
    "dec": -60.297827,
    "pmra": 508.26,
    "pmdec": 109.89,
    "rv": 0,
    "Asset Name": "ASTRID 001158",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Headband",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00003842,
    "y_1": -0.0000131,
    "z_1": 0.00000427,
    "Distance in Parsecs_1": 16.1917,
    "HYG Star ID_1": 118516,
    "Gliese Catalog_1": "Gl 294B"
  },
  {
    "Astrid #": "ASTRID 001159",
    "HYG Star ID": 118517,
    "Gliese Catalog": "Gl 294C",
    "Distance in Parsecs": 16.1917,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.454,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -3.948324,
    "y": 6.984045,
    "z": -14.064317,
    "vx": -0.00003842,
    "vy": -0.0000131,
    "vz": 0.00000427,
    "rarad": 2.08521120970063,
    "decrad": -1.05239561239715,
    "pmrarad": 0.00000246411401279166,
    "pmdecrad": 5.32752057e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 38795,
    "base": "Gl 294",
    "lum": 0.000908657048958144,
    "ra": 7.96492,
    "dec": -60.297827,
    "pmra": 508.26,
    "pmdec": 109.89,
    "rv": 0,
    "Asset Name": "ASTRID 001159",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Naughty Dreams",
    "Hair": "Round Brush",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003842,
    "y_1": -0.0000131,
    "z_1": 0.00000427,
    "Distance in Parsecs_1": 16.1917,
    "HYG Star ID_1": 118517,
    "Gliese Catalog_1": "Gl 294C"
  },
  {
    "Astrid #": "ASTRID 001160",
    "HYG Star ID": 92163,
    "Hipparcos Catalogue": 92451,
    "Gliese Catalog": "NN 4079",
    "Distance in Parsecs": 16.1943,
    "mag": 10.74,
    "Absolute Visual Magnitude": 9.693,
    "Spectral Type": "M3",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.657138,
    "y": -7.406914,
    "z": -14.305485,
    "vx": 0.00000783,
    "vy": -0.00000249,
    "vz": 0.0000022,
    "rarad": 4.932488475452,
    "decrad": -1.08299484303033,
    "pmrarad": 4.38223085854166e-7,
    "pmdecrad": 2.89482248e-7,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 92163,
    "lum": 0.0115557995540212,
    "var_min": 10.818,
    "var_max": 10.678,
    "ra": 18.840718,
    "dec": -62.051034,
    "pmra": 90.39,
    "pmdec": 59.71,
    "rv": 0,
    "Asset Name": "ASTRID 001160",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Horns",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00000783,
    "y_1": -0.00000249,
    "z_1": 0.0000022,
    "Distance in Parsecs_1": 16.1943,
    "HYG Star ID_1": 92163,
    "Hipparcos Catalogue_1": 92451,
    "Gliese Catalog_1": "NN 4079"
  },
  {
    "Astrid #": "ASTRID 001161",
    "HYG Star ID": 115638,
    "Hipparcos Catalogue": 116003,
    "Gliese Catalog": "GJ 1284",
    "Distance in Parsecs": 16.2022,
    "mag": 11.09,
    "Absolute Visual Magnitude": 10.042,
    "Spectral Type": "M2Ve",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.058924,
    "y": -1.967576,
    "z": -5.64524,
    "vx": -0.00000245,
    "vy": 0.00002523,
    "vz": -0.00001532,
    "rarad": 6.15326195289068,
    "decrad": -0.355889562123738,
    "pmrarad": 0.00000152478750671527,
    "pmdecrad": -0.000001009042713,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 115638,
    "lum": 0.00837915067438409,
    "var_min": 11.179,
    "var_max": 11.029,
    "ra": 23.503729,
    "dec": -20.39097,
    "pmra": 314.51,
    "pmdec": -208.13,
    "rv": 0,
    "Asset Name": "ASTRID 001161",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Sweet Nap",
    "Hair": "Fire",
    "Outfit": "Star Knight",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000245,
    "y_1": 0.00002523,
    "z_1": -0.00001532,
    "Distance in Parsecs_1": 16.2022,
    "HYG Star ID_1": 115638,
    "Hipparcos Catalogue_1": 116003,
    "Gliese Catalog_1": "GJ 1284"
  },
  {
    "Astrid #": "ASTRID 001162",
    "HYG Star ID": 38795,
    "Hipparcos Catalogue": 38908,
    "Henry Draper Catalogue": 65907,
    "Harvard Revised Catalogue": 3138,
    "Gliese Catalog": "Gl 294A",
    "Distance in Parsecs": 16.2048,
    "mag": 5.59,
    "Absolute Visual Magnitude": 4.542,
    "Spectral Type": "G2V...",
    "Color Index": 0.573,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.946583,
    "y": 6.991075,
    "z": -14.07654,
    "vx": -0.00004296,
    "vy": -0.00000673,
    "vz": -0.0000079,
    "rarad": 2.08469448330012,
    "decrad": -1.05248714981652,
    "pmrarad": 0.00000251317715726388,
    "pmdecrad": 5.7624954e-7,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 38795,
    "base": "Gl 294",
    "lum": 1.32800588624373,
    "ra": 7.962946,
    "dec": -60.303072,
    "pmra": 518.38,
    "pmdec": 118.86,
    "rv": 14.1,
    "Asset Name": "ASTRID 001162",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Guilty Laugh",
    "Hair": "Windy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00004296,
    "y_1": -0.00000673,
    "z_1": -0.0000079,
    "Distance in Parsecs_1": 16.2048,
    "HYG Star ID_1": 38795,
    "Hipparcos Catalogue_1": 38908,
    "Henry Draper Catalogue_1": 65907,
    "Harvard Revised Catalogue_1": 3138,
    "Gliese Catalog_1": "Gl 294A"
  },
  {
    "Astrid #": "ASTRID 001163",
    "HYG Star ID": 5993,
    "Hipparcos Catalogue": 6005,
    "Gliese Catalog": "GJ 1036",
    "Distance in Parsecs": 16.2127,
    "mag": 11.31,
    "Absolute Visual Magnitude": 10.261,
    "Color Index": 1.51,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.422589,
    "y": 4.353786,
    "z": -9.464432,
    "vx": -0.00000998,
    "vy": 0.00000408,
    "vz": -0.00001122,
    "rarad": 0.337095502783659,
    "decrad": -0.623360070767498,
    "pmrarad": 4.40889561097222e-7,
    "pmdecrad": -8.52544857e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 5993,
    "lum": 0.00684857159125855,
    "var_min": 11.443,
    "var_max": 11.113,
    "ra": 1.28761,
    "dec": -35.715901,
    "pmra": 90.94,
    "pmdec": -175.85,
    "rv": 0,
    "Asset Name": "ASTRID 001163",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Braided Ponytail",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00000998,
    "y_1": 0.00000408,
    "z_1": -0.00001122,
    "Distance in Parsecs_1": 16.2127,
    "HYG Star ID_1": 5993,
    "Hipparcos Catalogue_1": 6005,
    "Gliese Catalog_1": "GJ 1036"
  },
  {
    "Astrid #": "ASTRID 001164",
    "HYG Star ID": 47845,
    "Hipparcos Catalogue": 47981,
    "Gliese Catalog": "Gl 366",
    "Distance in Parsecs": 16.2127,
    "mag": 10.64,
    "Absolute Visual Magnitude": 9.591,
    "Spectral Type": "M2",
    "Color Index": 1.382,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.268155,
    "y": 2.146611,
    "z": 15.733874,
    "vx": -0.00006166,
    "vy": 0.0000322,
    "vz": -0.00004649,
    "rarad": 2.56042177267063,
    "decrad": 1.32721685861282,
    "pmrarad": 4.27848073090277e-7,
    "pmdecrad": -0.000004834901392,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 47845,
    "lum": 0.012694044019618,
    "var_min": 10.69,
    "var_max": 10.56,
    "ra": 9.780091,
    "dec": 76.043924,
    "pmra": 88.25,
    "pmdec": -997.27,
    "rv": -27.8,
    "Asset Name": "ASTRID 001164",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Good Boy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00006166,
    "y_1": 0.0000322,
    "z_1": -0.00004649,
    "Distance in Parsecs_1": 16.2127,
    "HYG Star ID_1": 47845,
    "Hipparcos Catalogue_1": 47981,
    "Gliese Catalog_1": "Gl 366"
  },
  {
    "Astrid #": "ASTRID 001165",
    "HYG Star ID": 105001,
    "Hipparcos Catalogue": 105341,
    "Henry Draper Catalogue": 203040,
    "Gliese Catalog": "Gl 826.1",
    "Distance in Parsecs": 16.2206,
    "mag": 9.09,
    "Absolute Visual Magnitude": 8.04,
    "Spectral Type": "K5V",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.697208,
    "y": -9.795105,
    "z": -5.508505,
    "vx": -0.00001202,
    "vy": -0.00000771,
    "vz": -0.0000588,
    "rarad": 5.58605916528549,
    "decrad": -0.346487754498966,
    "pmrarad": -8.40424515243055e-7,
    "pmdecrad": -0.000003499142739,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 105001,
    "lum": 0.0529663443891658,
    "ra": 21.337174,
    "dec": -19.852286,
    "pmra": -173.35,
    "pmdec": -721.75,
    "rv": 15.6,
    "Asset Name": "ASTRID 001165",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Innocent",
    "Outfit": "Warlock",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00001202,
    "y_1": -0.00000771,
    "z_1": -0.0000588,
    "Distance in Parsecs_1": 16.2206,
    "HYG Star ID_1": 105001,
    "Hipparcos Catalogue_1": 105341,
    "Henry Draper Catalogue_1": 203040,
    "Gliese Catalog_1": "Gl 826.1"
  },
  {
    "Astrid #": "ASTRID 001166",
    "HYG Star ID": 50809,
    "Hipparcos Catalogue": 50954,
    "Henry Draper Catalogue": 90589,
    "Harvard Revised Catalogue": 4102,
    "Gliese Catalog": "Gl 391",
    "Distance in Parsecs": 16.2232,
    "mag": 3.99,
    "Absolute Visual Magnitude": 2.939,
    "Spectral Type": "F2IV",
    "Color Index": 0.369,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.080371,
    "y": 1.808276,
    "z": -15.597165,
    "vx": 0.00000363,
    "vy": -2.2e-7,
    "vz": 0.00000402,
    "rarad": 2.7244394031703,
    "decrad": -1.29209537609766,
    "pmrarad": -7.81519653055555e-8,
    "pmdecrad": -1.33954019e-7,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 50809,
    "lum": 5.81299567725387,
    "ra": 10.406592,
    "dec": -74.031612,
    "pmra": -16.12,
    "pmdec": -27.63,
    "rv": -4.7,
    "Asset Name": "ASTRID 001166",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Round Brush",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00000363,
    "y_1": -2.2e-7,
    "z_1": 0.00000402,
    "Distance in Parsecs_1": 16.2232,
    "HYG Star ID_1": 50809,
    "Hipparcos Catalogue_1": 50954,
    "Henry Draper Catalogue_1": 90589,
    "Harvard Revised Catalogue_1": 4102,
    "Gliese Catalog_1": "Gl 391"
  },
  {
    "Astrid #": "ASTRID 001167",
    "HYG Star ID": 119456,
    "Gliese Catalog": "Gl 841B",
    "Distance in Parsecs": 16.2259,
    "mag": 14.68,
    "Absolute Visual Magnitude": 13.629,
    "Spectral Type": "DQ7",
    "Color Index": 0.16,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.771023,
    "y": -5.225178,
    "z": -12.611365,
    "vx": -0.00002269,
    "vy": 0.00000793,
    "vz": -0.00001907,
    "rarad": 5.74591875075867,
    "decrad": -0.89026210063005,
    "pmrarad": -2.95784826506944e-7,
    "pmdecrad": -0.000001867492601,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 108060,
    "base": "Gl 841",
    "lum": 0.000307893130974161,
    "ra": 21.947793,
    "dec": -51.008261,
    "pmra": -61.01,
    "pmdec": -385.2,
    "rv": 0,
    "Asset Name": "ASTRID 001167",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Elegant",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002269,
    "y_1": 0.00000793,
    "z_1": -0.00001907,
    "Distance in Parsecs_1": 16.2259,
    "HYG Star ID_1": 119456,
    "Gliese Catalog_1": "Gl 841B"
  },
  {
    "Astrid #": "ASTRID 001168",
    "HYG Star ID": 4918,
    "Hipparcos Catalogue": 4927,
    "Gliese Catalog": "GJ 1026A",
    "Distance in Parsecs": 16.2285,
    "mag": 11.36,
    "Absolute Visual Magnitude": 10.309,
    "Spectral Type": "M",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.663841,
    "y": 4.151931,
    "z": 5.576508,
    "vx": -0.00001555,
    "vy": 0.00005052,
    "vz": 0.00000328,
    "rarad": 0.275916924711753,
    "decrad": 0.350773548046417,
    "pmrarad": 0.00000325688134323611,
    "pmdecrad": 2.15548162e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 4918,
    "base": "GJ 1026",
    "lum": 0.00655239394015981,
    "var_min": 11.428,
    "var_max": 11.288,
    "ra": 1.053925,
    "dec": 20.097844,
    "pmra": 671.78,
    "pmdec": 44.46,
    "rv": 0,
    "Asset Name": "ASTRID 001168",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Tiny Crown",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001555,
    "y_1": 0.00005052,
    "z_1": 0.00000328,
    "Distance in Parsecs_1": 16.2285,
    "HYG Star ID_1": 4918,
    "Hipparcos Catalogue_1": 4927,
    "Gliese Catalog_1": "GJ 1026A"
  },
  {
    "Astrid #": "ASTRID 001169",
    "HYG Star ID": 117992,
    "Gliese Catalog": "GJ 1013",
    "Distance in Parsecs": 16.2338,
    "mag": 12.75,
    "Absolute Visual Magnitude": 11.698,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.99545,
    "y": 2.21932,
    "z": -1.660261,
    "vx": -0.00001172,
    "vy": 0.00002355,
    "vz": -0.00008165,
    "rarad": 0.137866422455478,
    "decrad": -0.1024509573963,
    "pmrarad": 0.00000153622910957638,
    "pmdecrad": -0.000005056247926,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117992,
    "lum": 0.00182305596068106,
    "ra": 0.526611,
    "dec": -5.870007,
    "pmra": 316.87,
    "pmdec": -1042.93,
    "rv": 0,
    "Asset Name": "ASTRID 001169",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Bandana",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001172,
    "y_1": 0.00002355,
    "z_1": -0.00008165,
    "Distance in Parsecs_1": 16.2338,
    "HYG Star ID_1": 117992,
    "Gliese Catalog_1": "GJ 1013"
  },
  {
    "Astrid #": "ASTRID 001170",
    "HYG Star ID": 46356,
    "Hipparcos Catalogue": 46488,
    "Gliese Catalog": "Gl 347A",
    "Distance in Parsecs": 16.239,
    "mag": 12.1,
    "Absolute Visual Magnitude": 11.047,
    "Spectral Type": "M5",
    "Color Index": 1.512,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.729171,
    "y": 9.865865,
    "z": -2.083385,
    "vx": 0.00000686,
    "vy": 0.00001119,
    "vz": -0.00005669,
    "rarad": 2.48224547483816,
    "decrad": -0.12864911298384,
    "pmrarad": -8.03675638256944e-7,
    "pmdecrad": -0.000003450855296,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 46356,
    "base": "Gl 347",
    "lum": 0.00332047335797506,
    "var_min": 12.236,
    "var_max": 11.976,
    "ra": 9.481479,
    "dec": -7.371051,
    "pmra": -165.77,
    "pmdec": -711.79,
    "rv": 8.5,
    "Asset Name": "ASTRID 001170",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Unkempt",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000686,
    "y_1": 0.00001119,
    "z_1": -0.00005669,
    "Distance in Parsecs_1": 16.239,
    "HYG Star ID_1": 46356,
    "Hipparcos Catalogue_1": 46488,
    "Gliese Catalog_1": "Gl 347A"
  },
  {
    "Astrid #": "ASTRID 001171",
    "HYG Star ID": 18727,
    "Hipparcos Catalogue": 18774,
    "Henry Draper Catalogue": 24451,
    "Gliese Catalog": "Gl 156.2",
    "Distance in Parsecs": 16.2496,
    "mag": 8.2,
    "Absolute Visual Magnitude": 7.146,
    "Spectral Type": "K4V",
    "Color Index": 1.132,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.924127,
    "y": 3.377703,
    "z": 15.777964,
    "vx": 4.3e-7,
    "vy": 0.00005358,
    "vz": 0.00000954,
    "rarad": 1.05294313895848,
    "decrad": 1.32923175939461,
    "pmrarad": 0.00000160982382628472,
    "pmdecrad": -0.000002654354901,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 18727,
    "lum": 0.120670190959327,
    "ra": 4.021947,
    "dec": 76.15937,
    "pmra": 332.05,
    "pmdec": -547.5,
    "rv": 20,
    "Asset Name": "ASTRID 001171",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Good Boy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 4.3e-7,
    "y_1": 0.00005358,
    "z_1": 0.00000954,
    "Distance in Parsecs_1": 16.2496,
    "HYG Star ID_1": 18727,
    "Hipparcos Catalogue_1": 18774,
    "Henry Draper Catalogue_1": 24451,
    "Gliese Catalog_1": "Gl 156.2"
  },
  {
    "Astrid #": "ASTRID 001172",
    "HYG Star ID": 119527,
    "Gliese Catalog": "Gl 872B",
    "Distance in Parsecs": 16.2496,
    "mag": 11.7,
    "Absolute Visual Magnitude": 10.646,
    "Spectral Type": "M1",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.0783,
    "y": -4.994172,
    "z": 3.426459,
    "vx": 0.00000629,
    "vy": 0.0000165,
    "vz": -0.00003854,
    "rarad": 5.96334047470815,
    "decrad": 0.21246386757115,
    "pmrarad": 0.00000108593416307638,
    "pmdecrad": -0.000002328768577,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 2,
    "comp_primary": 112090,
    "base": "Gl 872",
    "lum": 0.0048039668292968,
    "ra": 22.778283,
    "dec": 12.173283,
    "pmra": 223.99,
    "pmdec": -480.34,
    "rv": -7.2,
    "Asset Name": "ASTRID 001172",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "High Bun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000629,
    "y_1": 0.0000165,
    "z_1": -0.00003854,
    "Distance in Parsecs_1": 16.2496,
    "HYG Star ID_1": 119527,
    "Gliese Catalog_1": "Gl 872B"
  },
  {
    "Astrid #": "ASTRID 001173",
    "HYG Star ID": 119194,
    "Gliese Catalog": "Gl 699.1",
    "Distance in Parsecs": 16.2602,
    "mag": 14.3,
    "Absolute Visual Magnitude": 13.244,
    "Spectral Type": "DA7",
    "Color Index": 0.35,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -0.090176,
    "y": -2.041492,
    "z": 16.123351,
    "vx": -0.00009727,
    "vy": 0.00028245,
    "vz": -0.00012329,
    "rarad": 4.67057574125388,
    "decrad": 1.44472808567765,
    "pmrarad": -0.00000669789492234722,
    "pmdecrad": 0.000016011873553,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119194,
    "lum": 0.000438934785534716,
    "ra": 17.840285,
    "dec": 82.776822,
    "pmra": -1381.54,
    "pmdec": 3302.69,
    "rv": -154,
    "Asset Name": "ASTRID 001173",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Bucket Hat",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00009727,
    "y_1": 0.00028245,
    "z_1": -0.00012329,
    "Distance in Parsecs_1": 16.2602,
    "HYG Star ID_1": 119194,
    "Gliese Catalog_1": "Gl 699.1"
  },
  {
    "Astrid #": "ASTRID 001174",
    "HYG Star ID": 118678,
    "Gliese Catalog": "GJ 1133",
    "Distance in Parsecs": 16.2866,
    "mag": 14.71,
    "Absolute Visual Magnitude": 13.651,
    "Spectral Type": "DA7",
    "Color Index": 0.38,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -6.636996,
    "y": 2.984289,
    "z": 14.570438,
    "vx": 0.00000644,
    "vy": -0.00001913,
    "vz": 0.00000685,
    "rarad": 2.71897823682696,
    "decrad": 1.1075964344989,
    "pmrarad": 9.0931653925e-7,
    "pmdecrad": 9.41609978e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118678,
    "lum": 0.000301717152260176,
    "ra": 10.385732,
    "dec": 63.460601,
    "pmra": 187.56,
    "pmdec": 194.22,
    "rv": 0,
    "Asset Name": "ASTRID 001174",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Middle Part",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000644,
    "y_1": -0.00001913,
    "z_1": 0.00000685,
    "Distance in Parsecs_1": 16.2866,
    "HYG Star ID_1": 118678,
    "Gliese Catalog_1": "GJ 1133"
  },
  {
    "Astrid #": "ASTRID 001175",
    "HYG Star ID": 118718,
    "Gliese Catalog": "Gl 405",
    "Distance in Parsecs": 16.2866,
    "mag": 12.72,
    "Absolute Visual Magnitude": 11.661,
    "Spectral Type": "M1.5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.745196,
    "y": 2.512854,
    "z": 13.507792,
    "vx": 0.00001916,
    "vy": 0.0000377,
    "vz": 0.0000054,
    "rarad": 2.86189028312235,
    "decrad": 0.97799833041555,
    "pmrarad": -0.00000254987755288194,
    "pmdecrad": 5.93368311e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118718,
    "lum": 0.00188625324535106,
    "ra": 10.931616,
    "dec": 56.035177,
    "pmra": -525.95,
    "pmdec": 122.39,
    "rv": 0,
    "Asset Name": "ASTRID 001175",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Shocked",
    "Hair": "Innocent",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001916,
    "y_1": 0.0000377,
    "z_1": 0.0000054,
    "Distance in Parsecs_1": 16.2866,
    "HYG Star ID_1": 118718,
    "Gliese Catalog_1": "Gl 405"
  },
  {
    "Astrid #": "ASTRID 001176",
    "HYG Star ID": 48951,
    "Hipparcos Catalogue": 49091,
    "Gliese Catalog": "Gl 377",
    "Distance in Parsecs": 16.2893,
    "mag": 11.43,
    "Absolute Visual Magnitude": 10.37,
    "Spectral Type": "M4",
    "Color Index": 1.485,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.205047,
    "y": 6.963117,
    "z": -8.240791,
    "vx": -0.00002543,
    "vy": 0.00011434,
    "vz": 0.00001297,
    "rarad": 2.62314570501088,
    "decrad": -0.530408067846682,
    "pmrarad": -0.00000532388447028472,
    "pmdecrad": 0.000003132817522,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 48951,
    "lum": 0.00619441075076781,
    "var_min": 11.509,
    "var_max": 11.329,
    "ra": 10.019679,
    "dec": -30.390144,
    "pmra": -1098.13,
    "pmdec": 646.19,
    "rv": 60,
    "Asset Name": "ASTRID 001176",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Pirate Hat",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": -0.00002543,
    "y_1": 0.00011434,
    "z_1": 0.00001297,
    "Distance in Parsecs_1": 16.2893,
    "HYG Star ID_1": 48951,
    "Hipparcos Catalogue_1": 49091,
    "Gliese Catalog_1": "Gl 377"
  },
  {
    "Astrid #": "ASTRID 001177",
    "HYG Star ID": 112090,
    "Hipparcos Catalogue": 112447,
    "Henry Draper Catalogue": 215648,
    "Harvard Revised Catalogue": 8665,
    "Gliese Catalog": "Gl 872A",
    "Distance in Parsecs": 16.2973,
    "mag": 4.2,
    "Absolute Visual Magnitude": 3.139,
    "Spectral Type": "F7V",
    "Color Index": 0.502,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.122785,
    "y": -5.009216,
    "z": 3.436474,
    "vx": 0.00000788,
    "vy": 0.00001679,
    "vz": -0.00003929,
    "rarad": 5.96332298468418,
    "decrad": 0.212456972074211,
    "pmrarad": 0.00000112990676390277,
    "pmdecrad": -0.000002385477233,
    "bayer": "Xi",
    "flam": 46,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112090,
    "base": "Gl 872",
    "lum": 4.83503920591621,
    "bf": "46Xi  Peg",
    "ra": 22.778216,
    "dec": 12.172888,
    "pmra": 233.06,
    "pmdec": -492.04,
    "rv": -6,
    "Asset Name": "ASTRID 001177",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Afro",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000788,
    "y_1": 0.00001679,
    "z_1": -0.00003929,
    "Distance in Parsecs_1": 16.2973,
    "HYG Star ID_1": 112090,
    "Hipparcos Catalogue_1": 112447,
    "Henry Draper Catalogue_1": 215648,
    "Harvard Revised Catalogue_1": 8665,
    "Gliese Catalog_1": "Gl 872A"
  },
  {
    "Astrid #": "ASTRID 001178",
    "HYG Star ID": 1294,
    "Hipparcos Catalogue": 1295,
    "Gliese Catalog": "GJ 1006A",
    "Distance in Parsecs": 16.3079,
    "mag": 11.79,
    "Absolute Visual Magnitude": 10.728,
    "Spectral Type": "M:",
    "Color Index": 1.485,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.299442,
    "y": 1.086202,
    "z": 5.540292,
    "vx": 0.00001614,
    "vy": 0.00005831,
    "vz": -0.00005599,
    "rarad": 0.0708753671367952,
    "decrad": 0.346630404293357,
    "pmrarad": 0.00000349681563374305,
    "pmdecrad": -0.000003650937902,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 1294,
    "base": "GJ 1006",
    "lum": 0.00445451063359898,
    "var": "EZ",
    "var_min": 11.919,
    "var_max": 11.639,
    "ra": 0.270724,
    "dec": 19.860459,
    "pmra": 721.27,
    "pmdec": -753.06,
    "rv": 0,
    "Asset Name": "ASTRID 001178",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Undercut",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00001614,
    "y_1": 0.00005831,
    "z_1": -0.00005599,
    "Distance in Parsecs_1": 16.3079,
    "HYG Star ID_1": 1294,
    "Hipparcos Catalogue_1": 1295,
    "Gliese Catalog_1": "GJ 1006A"
  },
  {
    "Astrid #": "ASTRID 001179",
    "HYG Star ID": 23093,
    "Hipparcos Catalogue": 23147,
    "Gliese Catalog": "Gl 181",
    "Distance in Parsecs": 16.3106,
    "mag": 9.8,
    "Absolute Visual Magnitude": 8.738,
    "Spectral Type": "M2",
    "Color Index": 1.413,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.773224,
    "y": 10.14466,
    "z": 12.466791,
    "vx": -0.00001259,
    "vy": -0.0000126,
    "vz": -0.0000323,
    "rarad": 1.30394554635716,
    "decrad": 0.870034160346718,
    "pmrarad": 5.40955104763888e-7,
    "pmdecrad": -5.51960375e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 23093,
    "lum": 0.02784838407805,
    "ra": 4.980705,
    "dec": 49.849285,
    "pmra": 111.58,
    "pmdec": -113.85,
    "rv": -33.9,
    "Asset Name": "ASTRID 001179",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Charmer",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00001259,
    "y_1": -0.0000126,
    "z_1": -0.0000323,
    "Distance in Parsecs_1": 16.3106,
    "HYG Star ID_1": 23093,
    "Hipparcos Catalogue_1": 23147,
    "Gliese Catalog_1": "Gl 181"
  },
  {
    "Astrid #": "ASTRID 001180",
    "HYG Star ID": 118953,
    "Gliese Catalog": "NN 3836",
    "Distance in Parsecs": 16.3132,
    "mag": 11.6,
    "Absolute Visual Magnitude": 10.537,
    "Spectral Type": "M2.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.580919,
    "y": -6.418224,
    "z": 11.538322,
    "vx": -0.0000423,
    "vy": 0.00003793,
    "vz": -0.00001401,
    "rarad": 3.73189792523745,
    "decrad": 0.785671124847,
    "pmrarad": -0.00000337551525086805,
    "pmdecrad": -0.00000121526306,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 118953,
    "lum": 0.00531128981855012,
    "ra": 14.2548,
    "dec": 45.01564,
    "pmra": -696.25,
    "pmdec": -250.67,
    "rv": 0,
    "Asset Name": "ASTRID 001180",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Slick",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.0000423,
    "y_1": 0.00003793,
    "z_1": -0.00001401,
    "Distance in Parsecs_1": 16.3132,
    "HYG Star ID_1": 118953,
    "Gliese Catalog_1": "NN 3836"
  },
  {
    "Astrid #": "ASTRID 001181",
    "HYG Star ID": 107362,
    "Hipparcos Catalogue": 107705,
    "Gliese Catalog": "GJ 1264",
    "Distance in Parsecs": 16.3159,
    "mag": 9.53,
    "Absolute Visual Magnitude": 8.467,
    "Spectral Type": "M2Ve",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.218226,
    "y": -2.710722,
    "z": -15.526337,
    "vx": -0.00000557,
    "vy": 0.00003186,
    "vz": -0.00000708,
    "rarad": 5.71198603002343,
    "decrad": -1.25842587481046,
    "pmrarad": 0.00000145817410700694,
    "pmdecrad": -0.000001411292624,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 107362,
    "lum": 0.035743740626448,
    "var": "AY",
    "var_min": 9.58,
    "var_max": 9.49,
    "ra": 21.818179,
    "dec": -72.102491,
    "pmra": 300.77,
    "pmdec": -291.1,
    "rv": 0,
    "Asset Name": "ASTRID 001181",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Unkempt",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00000557,
    "y_1": 0.00003186,
    "z_1": -0.00000708,
    "Distance in Parsecs_1": 16.3159,
    "HYG Star ID_1": 107362,
    "Hipparcos Catalogue_1": 107705,
    "Gliese Catalog_1": "GJ 1264"
  },
  {
    "Astrid #": "ASTRID 001182",
    "HYG Star ID": 1459,
    "Hipparcos Catalogue": 1463,
    "Gliese Catalog": "Gl 16",
    "Distance in Parsecs": 16.3372,
    "mag": 10.88,
    "Absolute Visual Magnitude": 9.814,
    "Spectral Type": "M0",
    "Color Index": 1.479,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.027761,
    "y": 1.280869,
    "z": 2.89385,
    "vx": 4.6e-7,
    "vy": -2e-8,
    "vz": -0.00000252,
    "rarad": 0.0797461724262219,
    "decrad": 0.178072145795342,
    "pmrarad": -3.15128892361111e-9,
    "pmdecrad": -1.56594818e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 1459,
    "lum": 0.0103371305235058,
    "var_min": 10.944,
    "var_max": 10.814,
    "ra": 0.304608,
    "dec": 10.202782,
    "pmra": -0.65,
    "pmdec": -32.3,
    "rv": 0,
    "Asset Name": "ASTRID 001182",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Cute Neko",
    "Hair": "Bob",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 4.6e-7,
    "y_1": -2e-8,
    "z_1": -0.00000252,
    "Distance in Parsecs_1": 16.3372,
    "HYG Star ID_1": 1459,
    "Hipparcos Catalogue_1": 1463,
    "Gliese Catalog_1": "Gl 16"
  },
  {
    "Astrid #": "ASTRID 001183",
    "HYG Star ID": 27045,
    "Hipparcos Catalogue": 27111,
    "Henry Draper Catalogue": 247168,
    "Distance in Parsecs": 16.3372,
    "mag": 11.35,
    "Absolute Visual Magnitude": 10.284,
    "Spectral Type": "F8:",
    "Color Index": 0.699,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.058387,
    "y": 16.090298,
    "z": 2.623846,
    "vx": -0.00000286,
    "vy": 0.00000339,
    "vz": -0.00005595,
    "rarad": 1.50511294560877,
    "decrad": 0.161304735658367,
    "pmrarad": 1.88641003104166e-7,
    "pmdecrad": -0.000003411924758,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 27045,
    "lum": 0.0067050188016284,
    "var_min": 11.398,
    "var_max": 11.278,
    "ra": 5.749108,
    "dec": 9.242081,
    "pmra": 38.91,
    "pmdec": -703.76,
    "rv": -5.7,
    "Asset Name": "ASTRID 001183",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Curly",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000286,
    "y_1": 0.00000339,
    "z_1": -0.00005595,
    "Distance in Parsecs_1": 16.3372,
    "HYG Star ID_1": 27045,
    "Hipparcos Catalogue_1": 27111,
    "Henry Draper Catalogue_1": 247168
  },
  {
    "Astrid #": "ASTRID 001184",
    "HYG Star ID": 52146,
    "Hipparcos Catalogue": 52296,
    "Gliese Catalog": "GJ 1135",
    "Distance in Parsecs": 16.3372,
    "mag": 9.97,
    "Absolute Visual Magnitude": 8.904,
    "Spectral Type": "M0V",
    "Color Index": 1.464,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.299822,
    "y": 4.406673,
    "z": -9.808145,
    "vx": 0.0000042,
    "vy": -0.00001556,
    "vz": -0.00001226,
    "rarad": 2.79756656861254,
    "decrad": -0.643946874743743,
    "pmrarad": 8.09784290631944e-7,
    "pmdecrad": -9.38599285e-7,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 52146,
    "lum": 0.0239001155148472,
    "ra": 10.685917,
    "dec": -36.895438,
    "pmra": 167.03,
    "pmdec": -193.6,
    "rv": 0,
    "Asset Name": "ASTRID 001184",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Wavy",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": 0.0000042,
    "y_1": -0.00001556,
    "z_1": -0.00001226,
    "Distance in Parsecs_1": 16.3372,
    "HYG Star ID_1": 52146,
    "Hipparcos Catalogue_1": 52296,
    "Gliese Catalog_1": "GJ 1135"
  },
  {
    "Astrid #": "ASTRID 001185",
    "HYG Star ID": 118087,
    "Gliese Catalog": "Gl 73",
    "Distance in Parsecs": 16.3399,
    "mag": 14.11,
    "Absolute Visual Magnitude": 13.044,
    "Spectral Type": "M3",
    "Color Index": 1.67,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.063738,
    "y": 6.932347,
    "z": 4.598498,
    "vx": 0.00003267,
    "vy": -0.00004565,
    "vz": -0.00003106,
    "rarad": 0.457977552776251,
    "decrad": 0.28528146832385,
    "pmrarad": -0.00000339054447496527,
    "pmdecrad": -0.000001981273523,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118087,
    "lum": 0.000527715681764811,
    "ra": 1.749345,
    "dec": 16.345424,
    "pmra": -699.35,
    "pmdec": -408.67,
    "rv": 0,
    "Asset Name": "ASTRID 001185",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Loopy",
    "Hair": "Good Boy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003267,
    "y_1": -0.00004565,
    "z_1": -0.00003106,
    "Distance in Parsecs_1": 16.3399,
    "HYG Star ID_1": 118087,
    "Gliese Catalog_1": "Gl 73"
  },
  {
    "Astrid #": "ASTRID 001186",
    "HYG Star ID": 119054,
    "Gliese Catalog": "Gl 607",
    "Distance in Parsecs": 16.3399,
    "mag": 12.51,
    "Absolute Visual Magnitude": 11.444,
    "Spectral Type": "M3",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.970442,
    "y": -12.285995,
    "z": 8.214407,
    "vx": -0.00002392,
    "vy": 0.00000956,
    "vz": 0.00001456,
    "rarad": 4.19633617736419,
    "decrad": 0.5267249323474,
    "pmrarad": -0.00000156197271601388,
    "pmdecrad": 6.63006948e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119054,
    "lum": 0.00230356250011753,
    "ra": 16.028823,
    "dec": 30.179116,
    "pmra": -322.18,
    "pmdec": 136.75,
    "rv": 10.1,
    "Asset Name": "ASTRID 001186",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Braid",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002392,
    "y_1": 0.00000956,
    "z_1": 0.00001456,
    "Distance in Parsecs_1": 16.3399,
    "HYG Star ID_1": 119054,
    "Gliese Catalog_1": "Gl 607"
  },
  {
    "Astrid #": "ASTRID 001187",
    "HYG Star ID": 85407,
    "Hipparcos Catalogue": 85667,
    "Henry Draper Catalogue": 158614,
    "Harvard Revised Catalogue": 6516,
    "Gliese Catalog": "Gl 678A",
    "Distance in Parsecs": 16.3425,
    "mag": 5.31,
    "Absolute Visual Magnitude": 4.243,
    "Spectral Type": "G8IV-V",
    "Color Index": 0.715,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.10463,
    "y": -16.202881,
    "z": -0.303147,
    "vx": 2.6e-7,
    "vy": 0.00007993,
    "vz": -0.00001216,
    "rarad": 4.5832198977796,
    "decrad": -0.0185514824458875,
    "pmrarad": -6.13968045055555e-7,
    "pmdecrad": -8.3387953e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 85407,
    "base": "Gl 678",
    "lum": 1.7490410399663,
    "ra": 17.50661,
    "dec": -1.062922,
    "pmra": -126.64,
    "pmdec": -172,
    "rv": -77.3,
    "Asset Name": "ASTRID 001187",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Bob",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 2.6e-7,
    "y_1": 0.00007993,
    "z_1": -0.00001216,
    "Distance in Parsecs_1": 16.3425,
    "HYG Star ID_1": 85407,
    "Hipparcos Catalogue_1": 85667,
    "Henry Draper Catalogue_1": 158614,
    "Harvard Revised Catalogue_1": 6516,
    "Gliese Catalog_1": "Gl 678A"
  },
  {
    "Astrid #": "ASTRID 001188",
    "HYG Star ID": 105591,
    "Hipparcos Catalogue": 105932,
    "Gliese Catalog": "Gl 828.2",
    "Distance in Parsecs": 16.3452,
    "mag": 11.09,
    "Absolute Visual Magnitude": 10.023,
    "Spectral Type": "M0",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.756994,
    "y": -10.031474,
    "z": -1.94788,
    "vx": -0.00000177,
    "vy": 0.00000391,
    "vz": -0.00003173,
    "rarad": 5.6168225506815,
    "decrad": -0.119455259741398,
    "pmrarad": 1.21106457402777e-7,
    "pmdecrad": -0.000001955399017,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 105591,
    "lum": 0.00852707337343628,
    "var_min": 11.141,
    "var_max": 11.021,
    "ra": 21.454682,
    "dec": -6.844282,
    "pmra": 24.98,
    "pmdec": -403.33,
    "rv": 0,
    "Asset Name": "ASTRID 001188",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Antlers",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000177,
    "y_1": 0.00000391,
    "z_1": -0.00003173,
    "Distance in Parsecs_1": 16.3452,
    "HYG Star ID_1": 105591,
    "Hipparcos Catalogue_1": 105932,
    "Gliese Catalog_1": "Gl 828.2"
  },
  {
    "Astrid #": "ASTRID 001189",
    "HYG Star ID": 90727,
    "Hipparcos Catalogue": 91009,
    "Henry Draper Catalogue": 234677,
    "Gliese Catalog": "Gl 719",
    "Distance in Parsecs": 16.3532,
    "mag": 8.2,
    "Absolute Visual Magnitude": 7.132,
    "Spectral Type": "K7Vvar",
    "Color Index": 1.265,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.494369,
    "y": -10.020127,
    "z": 12.836818,
    "vx": 0.00001533,
    "vy": -0.00000246,
    "vz": -0.00003563,
    "rarad": 4.86042968464379,
    "decrad": 0.902669282777297,
    "pmrarad": 9.04759290652777e-7,
    "pmdecrad": -0.000001575111166,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 90727,
    "lum": 0.122236244927873,
    "var": "BY",
    "var_min": 8.284,
    "var_max": 8.064,
    "ra": 18.565474,
    "dec": 51.71914,
    "pmra": 186.62,
    "pmdec": -324.89,
    "rv": -24.5,
    "Asset Name": "ASTRID 001189",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Ninja",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001533,
    "y_1": -0.00000246,
    "z_1": -0.00003563,
    "Distance in Parsecs_1": 16.3532,
    "HYG Star ID_1": 90727,
    "Hipparcos Catalogue_1": 91009,
    "Henry Draper Catalogue_1": 234677,
    "Gliese Catalog_1": "Gl 719"
  },
  {
    "Astrid #": "ASTRID 001190",
    "HYG Star ID": 91325,
    "Hipparcos Catalogue": 91608,
    "Gliese Catalog": "Gl 724",
    "Distance in Parsecs": 16.3532,
    "mag": 10.63,
    "Absolute Visual Magnitude": 9.562,
    "Spectral Type": "M",
    "Color Index": 1.505,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.827902,
    "y": -15.655999,
    "z": -3.784157,
    "vx": -0.00000934,
    "vy": 0.0000108,
    "vz": -0.00005165,
    "rarad": 4.89108967227938,
    "decrad": -0.233518166475657,
    "pmrarad": -4.44768070541666e-7,
    "pmdecrad": -0.000003246797218,
    "con": "Sct",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scutum",
    "FULL CONSTELLATION NAME": "Scutum",
    "comp": 1,
    "comp_primary": 91325,
    "lum": 0.0130376704713821,
    "var_min": 10.7,
    "var_max": 10.56,
    "ra": 18.682586,
    "dec": -13.379605,
    "pmra": -91.74,
    "pmdec": -669.7,
    "rv": 0,
    "Asset Name": "ASTRID 001190",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Undercut",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scutum",
    "x_1": -0.00000934,
    "y_1": 0.0000108,
    "z_1": -0.00005165,
    "Distance in Parsecs_1": 16.3532,
    "HYG Star ID_1": 91325,
    "Hipparcos Catalogue_1": 91608,
    "Gliese Catalog_1": "Gl 724"
  },
  {
    "Astrid #": "ASTRID 001191",
    "HYG Star ID": 70306,
    "Hipparcos Catalogue": 70529,
    "Gliese Catalog": "Gl 548A",
    "Distance in Parsecs": 16.3639,
    "mag": 9.77,
    "Absolute Visual Magnitude": 8.701,
    "Spectral Type": "M1",
    "Color Index": 1.419,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.063243,
    "y": -8.903918,
    "z": 6.555773,
    "vx": 0.00000241,
    "vy": -0.00007655,
    "vz": -0.00007755,
    "rarad": 3.77743309121684,
    "decrad": 0.412195852882062,
    "pmrarad": 0.00000385179621064583,
    "pmdecrad": -0.00000540824205,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 70306,
    "base": "Gl 548",
    "lum": 0.0288137643483969,
    "ra": 14.428732,
    "dec": 23.617083,
    "pmra": 794.49,
    "pmdec": -1115.53,
    "rv": 8.6,
    "Asset Name": "ASTRID 001191",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Infatuated",
    "Hair": "Middle Part",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000241,
    "y_1": -0.00007655,
    "z_1": -0.00007755,
    "Distance in Parsecs_1": 16.3639,
    "HYG Star ID_1": 70306,
    "Hipparcos Catalogue_1": 70529,
    "Gliese Catalog_1": "Gl 548A"
  },
  {
    "Astrid #": "ASTRID 001192",
    "HYG Star ID": 117985,
    "Gliese Catalog": "GJ 1011",
    "Distance in Parsecs": 16.3666,
    "mag": 14.27,
    "Absolute Visual Magnitude": 13.2,
    "Spectral Type": "k-m",
    "Color Index": 1.59,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.838046,
    "y": 1.524148,
    "z": 6.736094,
    "vx": -0.00000202,
    "vy": -0.00001781,
    "vz": 0.00000849,
    "rarad": 0.102365152114077,
    "decrad": 0.4241822385636,
    "pmrarad": -0.00000107017771845833,
    "pmdecrad": 5.69011272e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117985,
    "lum": 0.000457088189614875,
    "ra": 0.391006,
    "dec": 24.303852,
    "pmra": -220.74,
    "pmdec": 117.37,
    "rv": 0,
    "Asset Name": "ASTRID 001192",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Braid",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000202,
    "y_1": -0.00001781,
    "z_1": 0.00000849,
    "Distance in Parsecs_1": 16.3666,
    "HYG Star ID_1": 117985,
    "Gliese Catalog_1": "GJ 1011"
  },
  {
    "Astrid #": "ASTRID 001193",
    "HYG Star ID": 99120,
    "Hipparcos Catalogue": 99438,
    "Gliese Catalog": "GJ 2147",
    "Distance in Parsecs": 16.3693,
    "mag": 12.18,
    "Absolute Visual Magnitude": 11.11,
    "Color Index": 0.07,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.649307,
    "y": -11.898178,
    "z": -8.238654,
    "vx": -0.00002848,
    "vy": -0.00000642,
    "vz": -0.00001718,
    "rarad": 5.2837571942343,
    "decrad": -0.527412508085645,
    "pmrarad": -0.00000167551608,
    "pmdecrad": -0.000001214409788,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 99120,
    "lum": 0.00313328572431558,
    "var_min": 12.287,
    "var_max": 12.057,
    "ra": 20.182466,
    "dec": -30.218511,
    "pmra": -345.6,
    "pmdec": -250.49,
    "rv": 0,
    "Asset Name": "ASTRID 001193",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Stunned",
    "Hair": "Unkempt",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002848,
    "y_1": -0.00000642,
    "z_1": -0.00001718,
    "Distance in Parsecs_1": 16.3693,
    "HYG Star ID_1": 99120,
    "Hipparcos Catalogue_1": 99438,
    "Gliese Catalog_1": "GJ 2147"
  },
  {
    "Astrid #": "ASTRID 001194",
    "HYG Star ID": 104700,
    "Hipparcos Catalogue": 105038,
    "Henry Draper Catalogue": 202575,
    "Gliese Catalog": "Gl 824",
    "Distance in Parsecs": 16.3881,
    "mag": 7.88,
    "Absolute Visual Magnitude": 6.807,
    "Spectral Type": "K2",
    "Color Index": 1.02,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.227284,
    "y": -10.578434,
    "z": 2.674834,
    "vx": -0.00000518,
    "vy": 0.00001987,
    "vz": -0.00001239,
    "rarad": 5.56996131585694,
    "decrad": 0.163953184211227,
    "pmrarad": 7.10155079277777e-7,
    "pmdecrad": -5.77461574e-7,
    "con": "Equ",
    "Full Constellation Name Formula 1": "Equuleus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Equuleus",
    "comp": 1,
    "comp_primary": 104700,
    "lum": 0.164892157315083,
    "ra": 21.275685,
    "dec": 9.393825,
    "pmra": 146.48,
    "pmdec": -119.11,
    "rv": -18.3,
    "Asset Name": "ASTRID 001194",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Fired Up",
    "Hair": "Innocent",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Equuleus",
    "x_1": -0.00000518,
    "y_1": 0.00001987,
    "z_1": -0.00001239,
    "Distance in Parsecs_1": 16.3881,
    "HYG Star ID_1": 104700,
    "Hipparcos Catalogue_1": 105038,
    "Henry Draper Catalogue_1": 202575,
    "Gliese Catalog_1": "Gl 824"
  },
  {
    "Astrid #": "ASTRID 001195",
    "HYG Star ID": 28371,
    "Hipparcos Catalogue": 28442,
    "Henry Draper Catalogue": 40887,
    "Gliese Catalog": "Gl 225.2A",
    "Distance in Parsecs": 16.3934,
    "mag": 7.85,
    "Absolute Visual Magnitude": 6.777,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.137,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.019968,
    "y": 14.048419,
    "z": -8.450735,
    "vx": 0.0000299,
    "vy": 0.00013076,
    "vz": 0.00001551,
    "rarad": 1.57222039506216,
    "decrad": -0.541553203424973,
    "pmrarad": -0.0000018356500386875,
    "pmdecrad": 0.000004921780003,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 28371,
    "base": "Gl 225.2",
    "lum": 0.169511825153139,
    "ra": 6.00544,
    "dec": -31.028713,
    "pmra": -378.63,
    "pmdec": 1015.19,
    "rv": 101.7,
    "Asset Name": "ASTRID 001195",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Bangs",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": 0.0000299,
    "y_1": 0.00013076,
    "z_1": 0.00001551,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 28371,
    "Hipparcos Catalogue_1": 28442,
    "Henry Draper Catalogue_1": 40887,
    "Gliese Catalog_1": "Gl 225.2A"
  },
  {
    "Astrid #": "ASTRID 001196",
    "HYG Star ID": 117967,
    "Gliese Catalog": "NN 3015B",
    "Distance in Parsecs": 16.3934,
    "mag": 17.4,
    "Absolute Visual Magnitude": 16.327,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.654243,
    "y": 0.159466,
    "z": 16.176313,
    "vx": -0.00001383,
    "vy": 0.00001831,
    "vz": 0.00000209,
    "rarad": 0.0597069184525395,
    "decrad": 1.40787539390525,
    "pmrarad": 0.00000116558905079166,
    "pmdecrad": 7.86198105e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 117967,
    "base": "NN 3015",
    "lum": 0.0000256566529703486,
    "ra": 0.228064,
    "dec": 80.665318,
    "pmra": 240.42,
    "pmdec": 162.16,
    "rv": 0,
    "Asset Name": "ASTRID 001196",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Bob",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001383,
    "y_1": 0.00001831,
    "z_1": 0.00000209,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 117967,
    "Gliese Catalog_1": "NN 3015B"
  },
  {
    "Astrid #": "ASTRID 001197",
    "HYG Star ID": 118082,
    "Gliese Catalog": "NN 3105",
    "Distance in Parsecs": 16.3934,
    "mag": 13.91,
    "Absolute Visual Magnitude": 12.837,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.621315,
    "y": 5.903795,
    "z": 8.636618,
    "vx": -0.00001593,
    "vy": 0.00003466,
    "vz": -4e-7,
    "rarad": 0.437507185587059,
    "decrad": 0.5548726955263,
    "pmrarad": 0.00000232691174119444,
    "pmdecrad": -2.8429474e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118082,
    "lum": 0.000638557485078839,
    "ra": 1.671154,
    "dec": 31.791864,
    "pmra": 479.96,
    "pmdec": -5.86,
    "rv": 0,
    "Asset Name": "ASTRID 001197",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Angry",
    "Hair": "Frizzy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001593,
    "y_1": 0.00003466,
    "z_1": -4e-7,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118082,
    "Gliese Catalog_1": "NN 3105"
  },
  {
    "Astrid #": "ASTRID 001198",
    "HYG Star ID": 118171,
    "Gliese Catalog": "NN 3180",
    "Distance in Parsecs": 16.3934,
    "mag": 15.86,
    "Absolute Visual Magnitude": 14.787,
    "Spectral Type": "m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.220171,
    "y": 10.834203,
    "z": -1.425172,
    "vx": -0.00009833,
    "vy": 0.00009156,
    "vz": -0.0001485,
    "rarad": 0.725351539167777,
    "decrad": -0.08704561902605,
    "pmrarad": 0.00000815912335554166,
    "pmdecrad": -0.000009093480521,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118171,
    "lum": 0.000105974164188309,
    "ra": 2.770639,
    "dec": -4.987347,
    "pmra": 1682.94,
    "pmdec": -1875.66,
    "rv": 0,
    "Asset Name": "ASTRID 001198",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Pirate Hat",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00009833,
    "y_1": 0.00009156,
    "z_1": -0.0001485,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118171,
    "Gliese Catalog_1": "NN 3180"
  },
  {
    "Astrid #": "ASTRID 001199",
    "HYG Star ID": 118245,
    "Gliese Catalog": "NN 3243",
    "Distance in Parsecs": 16.3934,
    "mag": 11.95,
    "Absolute Visual Magnitude": 10.877,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.41444,
    "y": 4.797266,
    "z": -15.299392,
    "vx": 5.8e-7,
    "vy": 0.00004546,
    "vz": 0.00001438,
    "rarad": 0.952088984389246,
    "decrad": -1.2033989904637,
    "pmrarad": 0.00000158000778493055,
    "pmdecrad": 0.000002442350726,
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 118245,
    "lum": 0.00388329157004954,
    "ra": 3.636712,
    "dec": -68.949683,
    "pmra": 325.9,
    "pmdec": 503.77,
    "rv": 0,
    "Asset Name": "ASTRID 001199",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Honest Lad",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": 5.8e-7,
    "y_1": 0.00004546,
    "z_1": 0.00001438,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118245,
    "Gliese Catalog_1": "NN 3243"
  },
  {
    "Astrid #": "ASTRID 001200",
    "HYG Star ID": 118647,
    "Gliese Catalog": "NN 3566",
    "Distance in Parsecs": 16.3934,
    "mag": 14.15,
    "Absolute Visual Magnitude": 13.077,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.635435,
    "y": 9.012012,
    "z": -1.265756,
    "vx": 0.00002171,
    "vy": 0.00003468,
    "vz": 0.00001294,
    "rarad": 2.55757528835117,
    "decrad": -0.07728822105355,
    "pmrarad": -0.00000249543297655555,
    "pmdecrad": 7.91598929e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118647,
    "lum": 0.00051191752799971,
    "ra": 9.769218,
    "dec": -4.428289,
    "pmra": -514.72,
    "pmdec": 163.28,
    "rv": 0,
    "Asset Name": "ASTRID 001200",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Goddess",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002171,
    "y_1": 0.00003468,
    "z_1": 0.00001294,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118647,
    "Gliese Catalog_1": "NN 3566"
  },
  {
    "Astrid #": "ASTRID 001201",
    "HYG Star ID": 118859,
    "Gliese Catalog": "NN 3758",
    "Distance in Parsecs": 16.3934,
    "mag": 12.54,
    "Absolute Visual Magnitude": 11.467,
    "Spectral Type": "M3.5",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.757132,
    "y": -4.227206,
    "z": -1.608437,
    "vx": -0.00000689,
    "vy": 0.00002416,
    "vz": 0.00000396,
    "rarad": 3.40369406389406,
    "decrad": -0.098273047356,
    "pmrarad": -0.00000153230211876388,
    "pmdecrad": 2.4269288e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118859,
    "lum": 0.00225527756674524,
    "ra": 13.001154,
    "dec": -5.630631,
    "pmra": -316.06,
    "pmdec": 50.06,
    "rv": 0,
    "Asset Name": "ASTRID 001201",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Malevolent",
    "Hair": "Side Part",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000689,
    "y_1": 0.00002416,
    "z_1": 0.00000396,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118859,
    "Gliese Catalog_1": "NN 3758"
  },
  {
    "Astrid #": "ASTRID 001202",
    "HYG Star ID": 118924,
    "Gliese Catalog": "NN 3808",
    "Distance in Parsecs": 16.3934,
    "mag": 14.34,
    "Absolute Visual Magnitude": 13.267,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.54303,
    "y": -7.474619,
    "z": 1.172142,
    "vx": -8e-7,
    "vy": -6.9e-7,
    "vz": -0.00001427,
    "rarad": 3.61635029915747,
    "decrad": 0.07156187455615,
    "pmrarad": 1.52231495694444e-8,
    "pmdecrad": -8.72528877e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118924,
    "lum": 0.00042973428115368,
    "ra": 13.813441,
    "dec": 4.100193,
    "pmra": 3.14,
    "pmdec": -179.97,
    "rv": 0,
    "Asset Name": "ASTRID 001202",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Sweetheart",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -8e-7,
    "y_1": -6.9e-7,
    "z_1": -0.00001427,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118924,
    "Gliese Catalog_1": "NN 3808"
  },
  {
    "Astrid #": "ASTRID 001203",
    "HYG Star ID": 118974,
    "Gliese Catalog": "Gl 552.1",
    "Distance in Parsecs": 16.3934,
    "mag": 11.63,
    "Absolute Visual Magnitude": 10.557,
    "Spectral Type": "k-m",
    "Color Index": 1.51,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.601156,
    "y": -5.883222,
    "z": -13.279823,
    "vx": 0.00000364,
    "vy": 0.00002217,
    "vz": -0.00001191,
    "rarad": 3.80030457629069,
    "decrad": -0.9442737602193,
    "pmrarad": -9.33654186013888e-7,
    "pmdecrad": -0.000001239004386,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 118974,
    "lum": 0.00521434785414457,
    "ra": 14.516094,
    "dec": -54.102901,
    "pmra": -192.58,
    "pmdec": -255.56,
    "rv": 0,
    "Asset Name": "ASTRID 001203",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Pixie",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": 0.00000364,
    "y_1": 0.00002217,
    "z_1": -0.00001191,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118974,
    "Gliese Catalog_1": "Gl 552.1"
  },
  {
    "Astrid #": "ASTRID 001204",
    "HYG Star ID": 118990,
    "Gliese Catalog": "NN 3871",
    "Distance in Parsecs": 16.3934,
    "mag": 11.61,
    "Absolute Visual Magnitude": 10.537,
    "Spectral Type": "k",
    "Color Index": 1.52,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.687509,
    "y": -10.943941,
    "z": 3.517931,
    "vx": -0.0000058,
    "vy": 6.3e-7,
    "vz": -0.00001732,
    "rarad": 3.8941474524834,
    "decrad": 0.2162764745503,
    "pmrarad": -2.69750331861111e-7,
    "pmdecrad": -0.000001081948994,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 118990,
    "lum": 0.00531128981855012,
    "ra": 14.874548,
    "dec": 12.391729,
    "pmra": -55.64,
    "pmdec": -223.17,
    "rv": 0,
    "Asset Name": "ASTRID 001204",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Neko Ninja",
    "Hair": "Halo Boy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.0000058,
    "y_1": 6.3e-7,
    "z_1": -0.00001732,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 118990,
    "Gliese Catalog_1": "NN 3871"
  },
  {
    "Astrid #": "ASTRID 001205",
    "HYG Star ID": 119049,
    "Gliese Catalog": "NN 3929",
    "Distance in Parsecs": 16.3934,
    "mag": 12.69,
    "Absolute Visual Magnitude": 11.617,
    "Spectral Type": "M6  :",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.765607,
    "y": -11.522165,
    "z": 9.497887,
    "vx": -0.00000362,
    "vy": 0.00001877,
    "vz": 0.0000202,
    "rarad": 4.18145660455308,
    "decrad": 0.61795871505795,
    "pmrarad": -7.70368938402777e-7,
    "pmdecrad": 0.000001511901159,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119049,
    "lum": 0.00196426464587985,
    "ra": 15.971988,
    "dec": 35.406426,
    "pmra": -158.9,
    "pmdec": 311.85,
    "rv": 0,
    "Asset Name": "ASTRID 001205",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Bob",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000362,
    "y_1": 0.00001877,
    "z_1": 0.0000202,
    "Distance in Parsecs_1": 16.3934,
    "HYG Star ID_1": 119049,
    "Gliese Catalog_1": "NN 3929"
  },
  {
    "Astrid #": "ASTRID 001206",
    "HYG Star ID": 118980,
    "Gliese Catalog": "Gl 560B",
    "Distance in Parsecs": 16.4015,
    "mag": 8.47,
    "Absolute Visual Magnitude": 7.396,
    "Spectral Type": "K5 V",
    "Color Index": 1.15,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.266282,
    "y": -4.516764,
    "z": -14.862237,
    "vx": 0.00000103,
    "vy": 0.00002027,
    "vz": -0.00001443,
    "rarad": 3.85058747806979,
    "decrad": -1.13404649540685,
    "pmrarad": -8.97438604076389e-7,
    "pmdecrad": -0.000001144548137,
    "con": "Cir",
    "Full Constellation Name Formula 1": "Circinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Circinus",
    "comp": 2,
    "comp_primary": 71682,
    "base": "Gl 560",
    "lum": 0.0958517397685645,
    "ra": 14.708161,
    "dec": -64.976078,
    "pmra": -185.11,
    "pmdec": -236.08,
    "rv": 7,
    "Asset Name": "ASTRID 001206",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "High Bun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Circinus",
    "x_1": 0.00000103,
    "y_1": 0.00002027,
    "z_1": -0.00001443,
    "Distance in Parsecs_1": 16.4015,
    "HYG Star ID_1": 118980,
    "Gliese Catalog_1": "Gl 560B"
  },
  {
    "Astrid #": "ASTRID 001207",
    "HYG Star ID": 35723,
    "Hipparcos Catalogue": 35821,
    "Gliese Catalog": "Gl 272",
    "Distance in Parsecs": 16.4096,
    "mag": 10.56,
    "Absolute Visual Magnitude": 9.485,
    "Spectral Type": "M2",
    "Color Index": 1.442,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.043664,
    "y": 10.638265,
    "z": 11.821259,
    "vx": 0.00001146,
    "vy": -0.00000395,
    "vz": -0.00003554,
    "rarad": 1.93403777059888,
    "decrad": 0.804377017002253,
    "pmrarad": -5.67474413090277e-7,
    "pmdecrad": -0.000001161371171,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 35723,
    "lum": 0.0139958732257261,
    "var_min": 10.614,
    "var_max": 10.514,
    "ra": 7.38748,
    "dec": 46.087408,
    "pmra": -117.05,
    "pmdec": -239.55,
    "rv": -30.3,
    "Asset Name": "ASTRID 001207",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Charmer",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00001146,
    "y_1": -0.00000395,
    "z_1": -0.00003554,
    "Distance in Parsecs_1": 16.4096,
    "HYG Star ID_1": 35723,
    "Hipparcos Catalogue_1": 35821,
    "Gliese Catalog_1": "Gl 272"
  },
  {
    "Astrid #": "ASTRID 001208",
    "HYG Star ID": 118586,
    "Gliese Catalog": "Gl 332B",
    "Distance in Parsecs": 16.4312,
    "mag": 6.18,
    "Absolute Visual Magnitude": 5.102,
    "Spectral Type": "G5 V",
    "Color Index": 0.65,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.687958,
    "y": 8.639195,
    "z": 10.948426,
    "vx": 0.00001501,
    "vy": 0.00003381,
    "vz": -0.00001476,
    "rarad": 2.35904465052014,
    "decrad": 0.7292617462939,
    "pmrarad": -0.00000210350959719444,
    "pmdecrad": -0.000001204694122,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 2,
    "comp_primary": 44120,
    "base": "Gl 332",
    "lum": 0.792866374878547,
    "ra": 9.010887,
    "dec": 41.78362,
    "pmra": -433.88,
    "pmdec": -248.49,
    "rv": 0,
    "Asset Name": "ASTRID 001208",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Lion Mane",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00001501,
    "y_1": 0.00003381,
    "z_1": -0.00001476,
    "Distance in Parsecs_1": 16.4312,
    "HYG Star ID_1": 118586,
    "Gliese Catalog_1": "Gl 332B"
  },
  {
    "Astrid #": "ASTRID 001209",
    "HYG Star ID": 80026,
    "Hipparcos Catalogue": 80268,
    "Gliese Catalog": "Gl 620",
    "Distance in Parsecs": 16.4393,
    "mag": 10.23,
    "Absolute Visual Magnitude": 9.151,
    "Spectral Type": "M2",
    "Color Index": 1.458,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.126134,
    "y": -13.61972,
    "z": -6.871993,
    "vx": -0.00001594,
    "vy": 0.00003188,
    "vz": -0.00004898,
    "rarad": 4.28970380935837,
    "decrad": -0.431267093333491,
    "pmrarad": -0.00000168007332859722,
    "pmdecrad": -0.000003280152399,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 80026,
    "lum": 0.0190370653174207,
    "ra": 16.385462,
    "dec": -24.709784,
    "pmra": -346.54,
    "pmdec": -676.58,
    "rv": 0,
    "Asset Name": "ASTRID 001209",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Innocent",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00001594,
    "y_1": 0.00003188,
    "z_1": -0.00004898,
    "Distance in Parsecs_1": 16.4393,
    "HYG Star ID_1": 80026,
    "Hipparcos Catalogue_1": 80268,
    "Gliese Catalog_1": "Gl 620"
  },
  {
    "Astrid #": "ASTRID 001210",
    "HYG Star ID": 52035,
    "Hipparcos Catalogue": 52186,
    "Gliese Catalog": "Gl 399",
    "Distance in Parsecs": 16.4474,
    "mag": 11.28,
    "Absolute Visual Magnitude": 10.2,
    "Spectral Type": "M",
    "Color Index": 1.503,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.334882,
    "y": 5.605998,
    "z": -1.982709,
    "vx": 0.00002057,
    "vy": 0.00005325,
    "vz": -0.00000853,
    "rarad": 2.79111368736188,
    "decrad": -0.120842374277671,
    "pmrarad": -0.000003470587213625,
    "pmdecrad": -5.22774591e-7,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 52035,
    "lum": 0.00724435960074989,
    "var_min": 11.378,
    "var_max": 11.178,
    "ra": 10.661269,
    "dec": -6.923758,
    "pmra": -715.86,
    "pmdec": -107.83,
    "rv": 0,
    "Asset Name": "ASTRID 001210",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Ponytail",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": 0.00002057,
    "y_1": 0.00005325,
    "z_1": -0.00000853,
    "Distance in Parsecs_1": 16.4474,
    "HYG Star ID_1": 52035,
    "Hipparcos Catalogue_1": 52186,
    "Gliese Catalog_1": "Gl 399"
  },
  {
    "Astrid #": "ASTRID 001211",
    "HYG Star ID": 118622,
    "Gliese Catalog": "NN 3553",
    "Distance in Parsecs": 16.4474,
    "mag": 13.33,
    "Absolute Visual Magnitude": 12.25,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.622449,
    "y": 10.500307,
    "z": 0.966598,
    "vx": -0.0000213,
    "vy": -0.00001735,
    "vz": -0.00008915,
    "rarad": 2.44771516372117,
    "decrad": 0.0588028710673,
    "pmrarad": 0.00000163949442353472,
    "pmdecrad": -0.000005430218654,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118622,
    "lum": 0.00109647819614318,
    "ra": 9.349583,
    "dec": 3.369156,
    "pmra": 338.17,
    "pmdec": -1120.06,
    "rv": 0,
    "Asset Name": "ASTRID 001211",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Oof",
    "Hair": "Undercut",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000213,
    "y_1": -0.00001735,
    "z_1": -0.00008915,
    "Distance in Parsecs_1": 16.4474,
    "HYG Star ID_1": 118622,
    "Gliese Catalog_1": "NN 3553"
  },
  {
    "Astrid #": "ASTRID 001212",
    "HYG Star ID": 119159,
    "Gliese Catalog": "Gl 678B",
    "Distance in Parsecs": 16.4474,
    "mag": 6.2,
    "Absolute Visual Magnitude": 5.12,
    "Spectral Type": "G8 IV-V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.118168,
    "y": -16.303767,
    "z": -0.305548,
    "vx": 4e-7,
    "vy": 0.00007631,
    "vz": -0.00001243,
    "rarad": 4.58319367957452,
    "decrad": -0.0185826177386499,
    "pmrarad": -5.73728509569444e-7,
    "pmdecrad": -8.41054773e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 2,
    "comp_primary": 85407,
    "base": "Gl 678",
    "lum": 0.779830110523258,
    "ra": 17.50651,
    "dec": -1.064706,
    "pmra": -118.34,
    "pmdec": -173.48,
    "rv": -73.8,
    "Asset Name": "ASTRID 001212",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Mischievous",
    "Hair": "Windy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 4e-7,
    "y_1": 0.00007631,
    "z_1": -0.00001243,
    "Distance in Parsecs_1": 16.4474,
    "HYG Star ID_1": 119159,
    "Gliese Catalog_1": "Gl 678B"
  },
  {
    "Astrid #": "ASTRID 001213",
    "HYG Star ID": 85387,
    "Hipparcos Catalogue": 85647,
    "Gliese Catalog": "Gl 676A",
    "Distance in Parsecs": 16.4501,
    "mag": 9.58,
    "Absolute Visual Magnitude": 8.499,
    "Spectral Type": "M0",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.324356,
    "y": -10.123209,
    "z": -12.898237,
    "vx": -0.00001675,
    "vy": 0.00003131,
    "vz": 0.00001262,
    "rarad": 4.58231079315308,
    "decrad": -0.901235256192076,
    "pmrarad": -0.00000125678250410416,
    "pmdecrad": -9.00250523e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 85387,
    "base": "Gl 676",
    "lum": 0.0347056354007987,
    "ra": 17.503138,
    "dec": -51.636977,
    "pmra": -259.23,
    "pmdec": -185.69,
    "rv": -27.2,
    "Asset Name": "ASTRID 001213",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Neko Dreams",
    "Hair": "Frizzy",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -0.00001675,
    "y_1": 0.00003131,
    "z_1": 0.00001262,
    "Distance in Parsecs_1": 16.4501,
    "HYG Star ID_1": 85387,
    "Hipparcos Catalogue_1": 85647,
    "Gliese Catalog_1": "Gl 676A"
  },
  {
    "Astrid #": "ASTRID 001214",
    "HYG Star ID": 31223,
    "Hipparcos Catalogue": 31300,
    "Gliese Catalog": "Gl 238",
    "Distance in Parsecs": 16.4555,
    "mag": 11.62,
    "Absolute Visual Magnitude": 10.538,
    "Spectral Type": "M",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.263627,
    "y": 8.49749,
    "z": -14.034767,
    "vx": 0.00002485,
    "vy": 0.0000498,
    "vz": 0.0000471,
    "rarad": 1.71843585952118,
    "decrad": -1.02151431266611,
    "pmrarad": -0.00000193925472222222,
    "pmdecrad": 0.000003858438158,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 31223,
    "lum": 0.00530640019194774,
    "var_min": 11.7,
    "var_max": 11.51,
    "ra": 6.563941,
    "dec": -58.528459,
    "pmra": -400,
    "pmdec": 795.86,
    "rv": -16,
    "Asset Name": "ASTRID 001214",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "High Fade",
    "Outfit": "Astro",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00002485,
    "y_1": 0.0000498,
    "z_1": 0.0000471,
    "Distance in Parsecs_1": 16.4555,
    "HYG Star ID_1": 31223,
    "Hipparcos Catalogue_1": 31300,
    "Gliese Catalog_1": "Gl 238"
  },
  {
    "Astrid #": "ASTRID 001215",
    "HYG Star ID": 2114,
    "Hipparcos Catalogue": 2120,
    "Gliese Catalog": "Gl 21",
    "Distance in Parsecs": 16.4772,
    "mag": 10.52,
    "Absolute Visual Magnitude": 9.436,
    "Spectral Type": "M0",
    "Color Index": 1.459,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.558583,
    "y": 0.654911,
    "z": 15.497463,
    "vx": 0.00001231,
    "vy": -0.00000945,
    "vz": -0.00000402,
    "rarad": 0.117290392200435,
    "decrad": 1.2242161213902,
    "pmrarad": -6.56777093048611e-7,
    "pmdecrad": -7.17669691e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 2114,
    "lum": 0.0146419864293527,
    "var_min": 10.586,
    "var_max": 10.476,
    "ra": 0.448016,
    "dec": 70.142417,
    "pmra": -135.47,
    "pmdec": -148.03,
    "rv": 0,
    "Asset Name": "ASTRID 001215",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Afro",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001231,
    "y_1": -0.00000945,
    "z_1": -0.00000402,
    "Distance in Parsecs_1": 16.4772,
    "HYG Star ID_1": 2114,
    "Hipparcos Catalogue_1": 2120,
    "Gliese Catalog_1": "Gl 21"
  },
  {
    "Astrid #": "ASTRID 001216",
    "HYG Star ID": 39874,
    "Hipparcos Catalogue": 39987,
    "Gliese Catalog": "Gl 298",
    "Distance in Parsecs": 16.4799,
    "mag": 11.74,
    "Absolute Visual Magnitude": 10.655,
    "Spectral Type": "M",
    "Color Index": 1.523,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.331844,
    "y": 8.371938,
    "z": -13.156529,
    "vx": -0.00001628,
    "vy": 0.0001023,
    "vz": -0.0000372,
    "rarad": 2.13790101181389,
    "decrad": -0.924469004159155,
    "pmrarad": -0.00000250173555440277,
    "pmdecrad": 0.000003244324668,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 39874,
    "lum": 0.00476430986805415,
    "var_min": 11.845,
    "var_max": 11.625,
    "ra": 8.16618,
    "dec": -52.968172,
    "pmra": -516.02,
    "pmdec": 669.19,
    "rv": 85,
    "Asset Name": "ASTRID 001216",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Stunned",
    "Hair": "Audiohead",
    "Outfit": "Astro",
    "Special": "V1 Flight System",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00001628,
    "y_1": 0.0001023,
    "z_1": -0.0000372,
    "Distance in Parsecs_1": 16.4799,
    "HYG Star ID_1": 39874,
    "Hipparcos Catalogue_1": 39987,
    "Gliese Catalog_1": "Gl 298"
  },
  {
    "Astrid #": "ASTRID 001217",
    "HYG Star ID": 71169,
    "Hipparcos Catalogue": 71395,
    "Henry Draper Catalogue": 128311,
    "Gliese Catalog": "NN 3860",
    "Distance in Parsecs": 16.5017,
    "mag": 7.48,
    "Absolute Visual Magnitude": 6.392,
    "Spectral Type": "K0",
    "Color Index": 0.973,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.638609,
    "y": -10.235397,
    "z": 2.793544,
    "vx": 0.00001947,
    "vy": -0.00000539,
    "vz": -0.00002228,
    "rarad": 3.82231170685914,
    "decrad": 0.170108835275526,
    "pmrarad": 9.96098188069444e-7,
    "pmdecrad": -0.000001210482797,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 71169,
    "lum": 0.241657345143682,
    "var": "HN",
    "var_min": 7.497,
    "var_max": 7.457,
    "ra": 14.600155,
    "dec": 9.746518,
    "pmra": 205.46,
    "pmdec": -249.68,
    "rv": -15,
    "Asset Name": "ASTRID 001217",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001947,
    "y_1": -0.00000539,
    "z_1": -0.00002228,
    "Distance in Parsecs_1": 16.5017,
    "HYG Star ID_1": 71169,
    "Hipparcos Catalogue_1": 71395,
    "Henry Draper Catalogue_1": 128311,
    "Gliese Catalog_1": "NN 3860"
  },
  {
    "Astrid #": "ASTRID 001218",
    "HYG Star ID": 38273,
    "Hipparcos Catalogue": 38382,
    "Henry Draper Catalogue": 64096,
    "Harvard Revised Catalogue": 3064,
    "Gliese Catalog": "Gl 291A",
    "Distance in Parsecs": 16.5044,
    "mag": 5.16,
    "Absolute Visual Magnitude": 4.072,
    "Spectral Type": "G2V",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.50733,
    "y": 14.153226,
    "z": -3.964222,
    "vx": 0.00001795,
    "vy": -0.00002214,
    "vz": -0.0000215,
    "rarad": 2.05849249884484,
    "decrad": -0.242566359101372,
    "pmrarad": -3.31903445708333e-7,
    "pmdecrad": -0.000001671783014,
    "flam": 9,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 38273,
    "base": "Gl 291",
    "lum": 2.04738727634163,
    "bf": "9    Pup",
    "ra": 7.862862,
    "dec": -13.898029,
    "pmra": -68.46,
    "pmdec": -344.83,
    "rv": -21.5,
    "Asset Name": "ASTRID 001218",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Prince",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001795,
    "y_1": -0.00002214,
    "z_1": -0.0000215,
    "Distance in Parsecs_1": 16.5044,
    "HYG Star ID_1": 38273,
    "Hipparcos Catalogue_1": 38382,
    "Henry Draper Catalogue_1": 64096,
    "Harvard Revised Catalogue_1": 3064,
    "Gliese Catalog_1": "Gl 291A"
  },
  {
    "Astrid #": "ASTRID 001219",
    "HYG Star ID": 33977,
    "Hipparcos Catalogue": 34065,
    "Henry Draper Catalogue": 53705,
    "Harvard Revised Catalogue": 2667,
    "Gliese Catalog": "Gl 264.1A",
    "Distance in Parsecs": 16.5153,
    "mag": 5.56,
    "Absolute Visual Magnitude": 4.471,
    "Spectral Type": "G3V...",
    "Color Index": 0.624,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.294071,
    "y": 11.496254,
    "z": -11.391485,
    "vx": -0.00001564,
    "vy": 0.00008442,
    "vz": -0.0000384,
    "rarad": 1.84985559534306,
    "decrad": -0.761103852275412,
    "pmrarad": -4.97903649930555e-7,
    "pmdecrad": 0.00000188568281,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 33977,
    "base": "Gl 264.1",
    "lum": 1.417751123122,
    "var_min": 5.607,
    "var_max": 5.447,
    "ra": 7.065928,
    "dec": -43.608039,
    "pmra": -102.7,
    "pmdec": 388.95,
    "rv": 86.4,
    "Asset Name": "ASTRID 001219",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Afro",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001564,
    "y_1": 0.00008442,
    "z_1": -0.0000384,
    "Distance in Parsecs_1": 16.5153,
    "HYG Star ID_1": 33977,
    "Hipparcos Catalogue_1": 34065,
    "Henry Draper Catalogue_1": 53705,
    "Harvard Revised Catalogue_1": 2667,
    "Gliese Catalog_1": "Gl 264.1A"
  },
  {
    "Astrid #": "ASTRID 001220",
    "HYG Star ID": 15328,
    "Hipparcos Catalogue": 15366,
    "Henry Draper Catalogue": 275122,
    "Gliese Catalog": "Gl 134",
    "Distance in Parsecs": 16.5289,
    "mag": 10.29,
    "Absolute Visual Magnitude": 9.199,
    "Spectral Type": "M1",
    "Color Index": 1.449,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.424485,
    "y": 9.874675,
    "z": 10.23339,
    "vx": -0.00001061,
    "vy": 0.00004235,
    "vz": -0.00003823,
    "rarad": 0.864476708254614,
    "decrad": 0.667624594524993,
    "pmrarad": 0.00000215145767020138,
    "pmdecrad": -0.000002765425715,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 15328,
    "lum": 0.0182137763710477,
    "ra": 3.302058,
    "dec": 38.252072,
    "pmra": 443.77,
    "pmdec": -570.41,
    "rv": -3.7,
    "Asset Name": "ASTRID 001220",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "High Fade",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00001061,
    "y_1": 0.00004235,
    "z_1": -0.00003823,
    "Distance in Parsecs_1": 16.5289,
    "HYG Star ID_1": 15328,
    "Hipparcos Catalogue_1": 15366,
    "Henry Draper Catalogue_1": 275122,
    "Gliese Catalog_1": "Gl 134"
  },
  {
    "Astrid #": "ASTRID 001221",
    "HYG Star ID": 43411,
    "Hipparcos Catalogue": 43534,
    "Gliese Catalog": "Gl 322",
    "Distance in Parsecs": 16.5399,
    "mag": 9.26,
    "Absolute Visual Magnitude": 8.167,
    "Spectral Type": "K5",
    "Color Index": 1.319,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.564474,
    "y": 4.894553,
    "z": 15.125179,
    "vx": 0.00000539,
    "vy": -0.00001658,
    "vz": -0.00001638,
    "rarad": 2.32130717353852,
    "decrad": 1.15421227561197,
    "pmrarad": 4.45204402854166e-7,
    "pmdecrad": 4.72984226e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 43411,
    "lum": 0.0471194269420275,
    "ra": 8.86674,
    "dec": 66.131492,
    "pmra": 91.83,
    "pmdec": 97.56,
    "rv": -20.9,
    "Asset Name": "ASTRID 001221",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Shocked",
    "Hair": "Headband",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000539,
    "y_1": -0.00001658,
    "z_1": -0.00001638,
    "Distance in Parsecs_1": 16.5399,
    "HYG Star ID_1": 43411,
    "Hipparcos Catalogue_1": 43534,
    "Gliese Catalog_1": "Gl 322"
  },
  {
    "Astrid #": "ASTRID 001222",
    "HYG Star ID": 118834,
    "Gliese Catalog": "GJ 2095",
    "Distance in Parsecs": 16.5563,
    "mag": 13.96,
    "Absolute Visual Magnitude": 12.865,
    "Spectral Type": "DA6",
    "Color Index": 0.18,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -10.529608,
    "y": -1.797532,
    "z": -12.648596,
    "vx": 0.00000805,
    "vy": 0.0000466,
    "vz": 0.00000274,
    "rarad": 3.31074886766497,
    "decrad": -0.86949491288985,
    "pmrarad": -0.00000269260670043749,
    "pmdecrad": -6.21637797e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118834,
    "lum": 0.000622300285169158,
    "ra": 12.646129,
    "dec": -49.818389,
    "pmra": -555.39,
    "pmdec": -128.22,
    "rv": -12,
    "Asset Name": "ASTRID 001222",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Side Part",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000805,
    "y_1": 0.0000466,
    "z_1": 0.00000274,
    "Distance in Parsecs_1": 16.5563,
    "HYG Star ID_1": 118834,
    "Gliese Catalog_1": "GJ 2095"
  },
  {
    "Astrid #": "ASTRID 001223",
    "HYG Star ID": 119061,
    "Gliese Catalog": "NN 3937",
    "Distance in Parsecs": 16.5563,
    "mag": 15.51,
    "Absolute Visual Magnitude": 14.415,
    "Spectral Type": "dM4.5-5",
    "Color Index": 1.89,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -7.957852,
    "y": -14.40496,
    "z": -1.8113,
    "vx": -5.7e-7,
    "vy": 0.00000881,
    "vz": -0.00006765,
    "rarad": 4.20767567869844,
    "decrad": -0.10962193278965,
    "pmrarad": -2.87446031201388e-7,
    "pmdecrad": -0.000004110875793,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119061,
    "lum": 0.000149279440957899,
    "ra": 16.072137,
    "dec": -6.280874,
    "pmra": -59.29,
    "pmdec": -847.93,
    "rv": 0,
    "Asset Name": "ASTRID 001223",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Loopy",
    "Hair": "Windy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -5.7e-7,
    "y_1": 0.00000881,
    "z_1": -0.00006765,
    "Distance in Parsecs_1": 16.5563,
    "HYG Star ID_1": 119061,
    "Gliese Catalog_1": "NN 3937"
  },
  {
    "Astrid #": "ASTRID 001224",
    "HYG Star ID": 5799,
    "Hipparcos Catalogue": 5812,
    "Gliese Catalog": "NN 3082",
    "Distance in Parsecs": 16.5618,
    "mag": 11.16,
    "Absolute Visual Magnitude": 10.064,
    "Spectral Type": "M4",
    "Color Index": 1.637,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.236835,
    "y": 3.116166,
    "z": -13.388935,
    "vx": 0.00001644,
    "vy": 0.00001438,
    "vz": 0.00001469,
    "rarad": 0.325369738258216,
    "decrad": -0.941467498249845,
    "pmrarad": 5.05515224715277e-7,
    "pmdecrad": 0.000001507285732,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 5799,
    "lum": 0.00821107464085087,
    "var_min": 11.226,
    "var_max": 11.086,
    "ra": 1.242821,
    "dec": -53.942114,
    "pmra": 104.27,
    "pmdec": 310.9,
    "rv": 0,
    "Asset Name": "ASTRID 001224",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Guilty Grin",
    "Hair": "High Bun",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": 0.00001644,
    "y_1": 0.00001438,
    "z_1": 0.00001469,
    "Distance in Parsecs_1": 16.5618,
    "HYG Star ID_1": 5799,
    "Hipparcos Catalogue_1": 5812,
    "Gliese Catalog_1": "NN 3082"
  },
  {
    "Astrid #": "ASTRID 001225",
    "HYG Star ID": 8674,
    "Hipparcos Catalogue": 8691,
    "Gliese Catalog": "Gl 78",
    "Distance in Parsecs": 16.5673,
    "mag": 11.8,
    "Absolute Visual Magnitude": 10.704,
    "Spectral Type": "M4",
    "Color Index": 1.475,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.375069,
    "y": 7.628197,
    "z": -3.105387,
    "vx": -0.00002854,
    "vy": 0.00003653,
    "vz": -0.00004243,
    "rarad": 0.487869016814706,
    "decrad": -0.188556060271346,
    "pmrarad": 0.00000275529310933333,
    "pmdecrad": -0.000002607231011,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 8674,
    "lum": 0.00455407312683253,
    "var_min": 11.908,
    "var_max": 11.708,
    "ra": 1.863522,
    "dec": -10.803466,
    "pmra": 568.32,
    "pmdec": -537.78,
    "rv": 0,
    "Asset Name": "ASTRID 001225",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Lion Mane",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002854,
    "y_1": 0.00003653,
    "z_1": -0.00004243,
    "Distance in Parsecs_1": 16.5673,
    "HYG Star ID_1": 8674,
    "Hipparcos Catalogue_1": 8691,
    "Gliese Catalog_1": "Gl 78"
  },
  {
    "Astrid #": "ASTRID 001226",
    "HYG Star ID": 71682,
    "Hipparcos Catalogue": 71908,
    "Henry Draper Catalogue": 128898,
    "Harvard Revised Catalogue": 5463,
    "Gliese Catalog": "Gl 560A",
    "Distance in Parsecs": 16.57,
    "mag": 3.18,
    "Absolute Visual Magnitude": 2.083,
    "Spectral Type": "F1Vp",
    "Color Index": 0.256,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.319852,
    "y": -4.563974,
    "z": -15.014538,
    "vx": 4.9e-7,
    "vy": 0.00002081,
    "vz": -0.00001463,
    "rarad": 3.8506743019806,
    "decrad": -1.13403009652959,
    "pmrarad": -9.33945074222222e-7,
    "pmdecrad": -0.000001134803382,
    "bayer": "Alp",
    "con": "Cir",
    "Full Constellation Name Formula 1": "Circinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Circinus",
    "comp": 1,
    "comp_primary": 71682,
    "base": "Gl 560",
    "lum": 12.7879226293584,
    "var": "Alp",
    "var_min": 3.184,
    "var_max": 3.174,
    "bf": "Alp Cir",
    "ra": 14.708492,
    "dec": -64.975138,
    "pmra": -192.64,
    "pmdec": -234.07,
    "rv": 7.2,
    "Asset Name": "ASTRID 001226",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Wavy",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Circinus",
    "x_1": 4.9e-7,
    "y_1": 0.00002081,
    "z_1": -0.00001463,
    "Distance in Parsecs_1": 16.57,
    "HYG Star ID_1": 71682,
    "Hipparcos Catalogue_1": 71908,
    "Henry Draper Catalogue_1": 128898,
    "Harvard Revised Catalogue_1": 5463,
    "Gliese Catalog_1": "Gl 560A"
  },
  {
    "Astrid #": "ASTRID 001227",
    "HYG Star ID": 118143,
    "Gliese Catalog": "Gl 98B",
    "Distance in Parsecs": 16.6058,
    "mag": 9.5,
    "Absolute Visual Magnitude": 8.399,
    "Spectral Type": "K7 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.232437,
    "y": 9.950292,
    "z": 1.283302,
    "vx": -0.00000783,
    "vy": 0.00000827,
    "vz": 0.0000166,
    "rarad": 0.644755570009195,
    "decrad": 0.07735749546235,
    "pmrarad": 6.81260183916666e-7,
    "pmdecrad": 0.000001002449247,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 11425,
    "base": "Gl  98",
    "lum": 0.0380539725002708,
    "ra": 2.462785,
    "dec": 4.432258,
    "pmra": 140.52,
    "pmdec": 206.77,
    "rv": 0,
    "Asset Name": "ASTRID 001227",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Sweetheart",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000783,
    "y_1": 0.00000827,
    "z_1": 0.0000166,
    "Distance in Parsecs_1": 16.6058,
    "HYG Star ID_1": 118143,
    "Gliese Catalog_1": "Gl 98B"
  },
  {
    "Astrid #": "ASTRID 001228",
    "HYG Star ID": 117016,
    "Hipparcos Catalogue": 117383,
    "Gliese Catalog": "Gl 907",
    "Distance in Parsecs": 16.6113,
    "mag": 12.06,
    "Absolute Visual Magnitude": 10.958,
    "Spectral Type": "M1",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.879867,
    "y": -0.5677,
    "z": 12.539917,
    "vx": 0.00001649,
    "vy": 0.00004969,
    "vz": 0.00002452,
    "rarad": 6.23103971112106,
    "decrad": 0.855489151484796,
    "pmrarad": 0.00000303944240750694,
    "pmdecrad": 3.37769691e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117016,
    "lum": 0.00360412628649143,
    "var_min": 12.174,
    "var_max": 11.944,
    "ra": 23.800818,
    "dec": 49.015918,
    "pmra": 626.93,
    "pmdec": 69.67,
    "rv": 27,
    "Asset Name": "ASTRID 001228",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Middle Part",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001649,
    "y_1": 0.00004969,
    "z_1": 0.00002452,
    "Distance in Parsecs_1": 16.6113,
    "HYG Star ID_1": 117016,
    "Hipparcos Catalogue_1": 117383,
    "Gliese Catalog_1": "Gl 907"
  },
  {
    "Astrid #": "ASTRID 001229",
    "HYG Star ID": 119289,
    "Gliese Catalog": "Gl 762",
    "Distance in Parsecs": 16.6113,
    "mag": 12.17,
    "Absolute Visual Magnitude": 11.068,
    "Spectral Type": "m+",
    "Color Index": 1.48,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.044066,
    "y": -6.943022,
    "z": -14.780507,
    "vx": -0.00003663,
    "vy": 0.00001091,
    "vz": -0.00001267,
    "rarad": 5.12568861033127,
    "decrad": -1.09687670306015,
    "pmrarad": -0.00000175541337455555,
    "pmdecrad": -0.000001671681203,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119289,
    "lum": 0.00325686682201669,
    "ra": 19.578688,
    "dec": -62.846406,
    "pmra": -362.08,
    "pmdec": -344.81,
    "rv": 0,
    "Asset Name": "ASTRID 001229",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Side Part",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003663,
    "y_1": 0.00001091,
    "z_1": -0.00001267,
    "Distance in Parsecs_1": 16.6113,
    "HYG Star ID_1": 119289,
    "Gliese Catalog_1": "Gl 762"
  },
  {
    "Astrid #": "ASTRID 001230",
    "HYG Star ID": 32683,
    "Hipparcos Catalogue": 32769,
    "Henry Draper Catalogue": 48948,
    "Gliese Catalog": "Gl 247",
    "Distance in Parsecs": 16.6279,
    "mag": 8.59,
    "Absolute Visual Magnitude": 7.486,
    "Spectral Type": "M0p",
    "Color Index": 1.209,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.779394,
    "y": 8.03456,
    "z": 14.448215,
    "vx": -0.00000782,
    "vy": -0.00005858,
    "vz": -0.00003065,
    "rarad": 1.78873513405111,
    "decrad": 1.05305388362975,
    "pmrarad": 0.00000122114869858333,
    "pmdecrad": 0.000001988414829,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 32683,
    "lum": 0.0882266928217716,
    "ra": 6.832465,
    "dec": 60.335543,
    "pmra": 251.88,
    "pmdec": 410.14,
    "rv": -52.9,
    "Asset Name": "ASTRID 001230",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Worried Vamp",
    "Hair": "Honest Lad",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00000782,
    "y_1": -0.00005858,
    "z_1": -0.00003065,
    "Distance in Parsecs_1": 16.6279,
    "HYG Star ID_1": 32683,
    "Hipparcos Catalogue_1": 32769,
    "Henry Draper Catalogue_1": 48948,
    "Gliese Catalog_1": "Gl 247"
  },
  {
    "Astrid #": "ASTRID 001231",
    "HYG Star ID": 119589,
    "Gliese Catalog": "Gl 905.2B",
    "Distance in Parsecs": 16.6362,
    "mag": 12.9,
    "Absolute Visual Magnitude": 11.795,
    "Spectral Type": "DA4",
    "Color Index": 0.15,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 13.989421,
    "y": -0.988114,
    "z": 8.95052,
    "vx": 0.00001576,
    "vy": -0.00001916,
    "vz": 0.00000557,
    "rarad": 6.21267958906545,
    "decrad": 0.5680467429986,
    "pmrarad": -0.00000108195869089583,
    "pmdecrad": -2.69760028e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 116690,
    "base": "Gl 905.2",
    "lum": 0.00166724721255106,
    "ra": 23.730688,
    "dec": 32.546681,
    "pmra": -223.17,
    "pmdec": -55.64,
    "rv": 17,
    "Asset Name": "ASTRID 001231",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Glad",
    "Hair": "Pixie",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001576,
    "y_1": -0.00001916,
    "z_1": 0.00000557,
    "Distance in Parsecs_1": 16.6362,
    "HYG Star ID_1": 119589,
    "Gliese Catalog_1": "Gl 905.2B"
  },
  {
    "Astrid #": "ASTRID 001232",
    "HYG Star ID": 39713,
    "Hipparcos Catalogue": 39826,
    "Gliese Catalog": "NN 3481A",
    "Distance in Parsecs": 16.6417,
    "mag": 9.41,
    "Absolute Visual Magnitude": 8.304,
    "Spectral Type": "M:",
    "Color Index": 1.34,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.239831,
    "y": 13.158393,
    "z": 5.992333,
    "vx": 0.00001502,
    "vy": 0.00002154,
    "vz": -0.00002665,
    "rarad": 2.13026143227569,
    "decrad": 0.368352961199402,
    "pmrarad": -0.00000145196849189583,
    "pmdecrad": -0.000001716434354,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 39713,
    "base": "NN 3481",
    "lum": 0.0415336405489073,
    "ra": 8.136999,
    "dec": 21.10507,
    "pmra": -299.49,
    "pmdec": -354.04,
    "rv": 0,
    "Asset Name": "ASTRID 001232",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Braid",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00001502,
    "y_1": 0.00002154,
    "z_1": -0.00002665,
    "Distance in Parsecs_1": 16.6417,
    "HYG Star ID_1": 39713,
    "Hipparcos Catalogue_1": 39826,
    "Gliese Catalog_1": "NN 3481A"
  },
  {
    "Astrid #": "ASTRID 001233",
    "HYG Star ID": 118034,
    "Gliese Catalog": "GJ 1025",
    "Distance in Parsecs": 16.6667,
    "mag": 13.32,
    "Absolute Visual Magnitude": 12.211,
    "Color Index": 1.73,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.03289,
    "y": 4.366583,
    "z": -1.293033,
    "vx": -0.00001585,
    "vy": 0.00009987,
    "vz": 0.00003486,
    "rarad": 0.265901435407289,
    "decrad": -0.0776579664860499,
    "pmrarad": 0.00000603248814578472,
    "pmdecrad": 0.000002136210279,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118034,
    "lum": 0.00113657997475015,
    "ra": 1.015669,
    "dec": -4.449474,
    "pmra": 1244.29,
    "pmdec": 440.62,
    "rv": 8,
    "Asset Name": "ASTRID 001233",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Lion Mane",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001585,
    "y_1": 0.00009987,
    "z_1": 0.00003486,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118034,
    "Gliese Catalog_1": "GJ 1025"
  },
  {
    "Astrid #": "ASTRID 001234",
    "HYG Star ID": 118121,
    "Gliese Catalog": "NN 3142",
    "Distance in Parsecs": 16.6667,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.391,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.941914,
    "y": 9.132466,
    "z": 0.00136,
    "vx": -0.0000239,
    "vy": 0.0000365,
    "vz": 6.1e-7,
    "rarad": 0.579907852573407,
    "decrad": 0.00008162750515,
    "pmrarad": 0.00000261775146815972,
    "pmdecrad": 3.6550103e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118121,
    "lum": 0.000962941712971555,
    "ra": 2.215085,
    "dec": 0.004677,
    "pmra": 539.95,
    "pmdec": 7.54,
    "rv": 0,
    "Asset Name": "ASTRID 001234",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Nervous Grin",
    "Hair": "Innocent",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000239,
    "y_1": 0.0000365,
    "z_1": 6.1e-7,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118121,
    "Gliese Catalog_1": "NN 3142"
  },
  {
    "Astrid #": "ASTRID 001235",
    "HYG Star ID": 118149,
    "Gliese Catalog": "Gl 101",
    "Distance in Parsecs": 16.6667,
    "mag": 13.21,
    "Absolute Visual Magnitude": 12.101,
    "Spectral Type": "M3.5",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.093138,
    "y": 5.513803,
    "z": 14.037959,
    "vx": -0.00005298,
    "vy": 0.00006521,
    "vz": 0.00000119,
    "rarad": 0.660556881847426,
    "decrad": 1.0014913272838,
    "pmrarad": 0.00000504031694852777,
    "pmdecrad": 1.31985676e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118149,
    "lum": 0.00125776643245125,
    "ra": 2.523141,
    "dec": 57.381226,
    "pmra": 1039.64,
    "pmdec": 27.22,
    "rv": 0,
    "Asset Name": "ASTRID 001235",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Fedora",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005298,
    "y_1": 0.00006521,
    "z_1": 0.00000119,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118149,
    "Gliese Catalog_1": "Gl 101"
  },
  {
    "Astrid #": "ASTRID 001236",
    "HYG Star ID": 118200,
    "Gliese Catalog": "Gl 130",
    "Distance in Parsecs": 16.6667,
    "mag": 11.46,
    "Absolute Visual Magnitude": 10.351,
    "Spectral Type": "M3",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.758778,
    "y": 9.770271,
    "z": -10.284532,
    "vx": 0.00000244,
    "vy": 0.0001521,
    "vz": -0.00001597,
    "rarad": 0.839852815605349,
    "decrad": -0.664778928767349,
    "pmrarad": 0.00000598575210697916,
    "pmdecrad": 0.000003497804653,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 118200,
    "lum": 0.00630376478829487,
    "ra": 3.208001,
    "dec": -38.089027,
    "pmra": 1234.65,
    "pmdec": 721.47,
    "rv": 98,
    "Asset Name": "ASTRID 001236",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Sweetheart",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00000244,
    "y_1": 0.0001521,
    "z_1": -0.00001597,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118200,
    "Gliese Catalog_1": "Gl 130"
  },
  {
    "Astrid #": "ASTRID 001237",
    "HYG Star ID": 118221,
    "Gliese Catalog": "NN 3225",
    "Distance in Parsecs": 16.6667,
    "mag": 14.96,
    "Absolute Visual Magnitude": 13.851,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.755489,
    "y": 12.346393,
    "z": 5.49326,
    "vx": 0.00000241,
    "vy": 0.00000487,
    "vz": -0.00001522,
    "rarad": 0.902090848575421,
    "decrad": 0.3358745259265,
    "pmrarad": 6.76315084374999e-8,
    "pmdecrad": -9.6726147e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118221,
    "lum": 0.000250957396370283,
    "ra": 3.445733,
    "dec": 19.244193,
    "pmra": 13.95,
    "pmdec": -199.51,
    "rv": 0,
    "Asset Name": "ASTRID 001237",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Pixie",
    "Outfit": "River Spirit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000241,
    "y_1": 0.00000487,
    "z_1": -0.00001522,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118221,
    "Gliese Catalog_1": "NN 3225"
  },
  {
    "Astrid #": "ASTRID 001238",
    "HYG Star ID": 118303,
    "Gliese Catalog": "NN 3286",
    "Distance in Parsecs": 16.6667,
    "mag": 14.1,
    "Absolute Visual Magnitude": 12.991,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.137334,
    "y": 11.677967,
    "z": -10.724355,
    "vx": -0.00001159,
    "vy": -0.0000329,
    "vz": -0.00004139,
    "rarad": 1.15636163202411,
    "decrad": -0.6990097986575,
    "pmrarad": -1.58679517645833e-7,
    "pmdecrad": -0.00000324437315,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118303,
    "lum": 0.000554115118971686,
    "ra": 4.416976,
    "dec": -40.050311,
    "pmra": -32.73,
    "pmdec": -669.2,
    "rv": 0,
    "Asset Name": "ASTRID 001238",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Afro",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001159,
    "y_1": -0.0000329,
    "z_1": -0.00004139,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118303,
    "Gliese Catalog_1": "NN 3286"
  },
  {
    "Astrid #": "ASTRID 001239",
    "HYG Star ID": 118565,
    "Gliese Catalog": "NN 3514",
    "Distance in Parsecs": 16.6667,
    "mag": 15.17,
    "Absolute Visual Magnitude": 14.061,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.393907,
    "y": 5.963871,
    "z": 14.598523,
    "vx": -0.00004385,
    "vy": 7.6e-7,
    "vz": -0.00001651,
    "rarad": 2.3059507998308,
    "decrad": 1.0673180271576,
    "pmrarad": 0.00000192131661604166,
    "pmdecrad": -0.000002053156848,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118565,
    "lum": 0.000206823555593176,
    "ra": 8.808083,
    "dec": 61.152818,
    "pmra": 396.3,
    "pmdec": -423.49,
    "rv": 0,
    "Asset Name": "ASTRID 001239",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Shinobi",
    "Hair": "Heartbreaker",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004385,
    "y_1": 7.6e-7,
    "z_1": -0.00001651,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118565,
    "Gliese Catalog_1": "NN 3514"
  },
  {
    "Astrid #": "ASTRID 001240",
    "HYG Star ID": 118716,
    "Gliese Catalog": "NN 3630",
    "Distance in Parsecs": 16.6667,
    "mag": 13.85,
    "Absolute Visual Magnitude": 12.741,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.939141,
    "y": 4.86801,
    "z": 0.1585,
    "vx": 0.00000661,
    "vy": 0.0000216,
    "vz": 0.00000118,
    "rarad": 2.84517784419932,
    "decrad": 0.0095101192298,
    "pmrarad": -0.00000135563601356944,
    "pmdecrad": 7.1044596e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118716,
    "lum": 0.000697589604332804,
    "ra": 10.867779,
    "dec": 0.54489,
    "pmra": -279.62,
    "pmdec": 14.65,
    "rv": 0,
    "Asset Name": "ASTRID 001240",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Fired Up",
    "Hair": "Round Brush",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000661,
    "y_1": 0.0000216,
    "z_1": 0.00000118,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118716,
    "Gliese Catalog_1": "NN 3630"
  },
  {
    "Astrid #": "ASTRID 001241",
    "HYG Star ID": 118750,
    "Gliese Catalog": "NN 3659",
    "Distance in Parsecs": 16.6667,
    "mag": 13.05,
    "Absolute Visual Magnitude": 11.941,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.62269,
    "y": 2.478047,
    "z": -5.250689,
    "vx": 0.00000858,
    "vy": 0.00004846,
    "vz": -0.00000269,
    "rarad": 2.98429265965833,
    "decrad": -0.32049959187375,
    "pmrarad": -0.00000295246683321527,
    "pmdecrad": -1.70237475e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118750,
    "lum": 0.00145747126125652,
    "ra": 11.399158,
    "dec": -18.363274,
    "pmra": -608.99,
    "pmdec": -35.11,
    "rv": 0,
    "Asset Name": "ASTRID 001241",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Windy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000858,
    "y_1": 0.00004846,
    "z_1": -0.00000269,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118750,
    "Gliese Catalog_1": "NN 3659"
  },
  {
    "Astrid #": "ASTRID 001242",
    "HYG Star ID": 118761,
    "Gliese Catalog": "NN 3672",
    "Distance in Parsecs": 16.6667,
    "mag": 15.1,
    "Absolute Visual Magnitude": 13.991,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.487888,
    "y": 1.797289,
    "z": -1.642624,
    "vx": 0.00000468,
    "vy": -0.00002591,
    "vz": -0.00007553,
    "rarad": 3.03301438671351,
    "decrad": -0.09871746883445,
    "pmrarad": 0.00000151499427036805,
    "pmdecrad": -0.000004554276689,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118761,
    "lum": 0.000220597202174732,
    "ra": 11.585262,
    "dec": -5.656094,
    "pmra": 312.49,
    "pmdec": -939.39,
    "rv": 0,
    "Asset Name": "ASTRID 001242",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Hachimaki",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000468,
    "y_1": -0.00002591,
    "z_1": -0.00007553,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118761,
    "Gliese Catalog_1": "NN 3672"
  },
  {
    "Astrid #": "ASTRID 001243",
    "HYG Star ID": 118767,
    "Gliese Catalog": "NN 3681",
    "Distance in Parsecs": 16.6667,
    "mag": 12.58,
    "Absolute Visual Magnitude": 11.471,
    "Spectral Type": "M3.5",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.066058,
    "y": 1.261711,
    "z": 4.250738,
    "vx": 0.00000682,
    "vy": 0.00002175,
    "vz": 0.00001934,
    "rarad": 3.06322305781519,
    "decrad": 0.2578929926007,
    "pmrarad": -0.00000133304369605555,
    "pmdecrad": 0.000001200292014,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118767,
    "lum": 0.00224698410364015,
    "ra": 11.70065,
    "dec": 14.77618,
    "pmra": -274.96,
    "pmdec": 247.58,
    "rv": 0,
    "Asset Name": "ASTRID 001243",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Battle Ready",
    "Hair": "Good Boy",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000682,
    "y_1": 0.00002175,
    "z_1": 0.00001934,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118767,
    "Gliese Catalog_1": "NN 3681"
  },
  {
    "Astrid #": "ASTRID 001244",
    "HYG Star ID": 118782,
    "Gliese Catalog": "NN 3692",
    "Distance in Parsecs": 16.6667,
    "mag": 13.61,
    "Absolute Visual Magnitude": 12.501,
    "Spectral Type": "M4",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.219878,
    "y": 0.421412,
    "z": -8.68311,
    "vx": 0.00000844,
    "vy": 0.00009123,
    "vz": -0.00000945,
    "rarad": 3.11201296548258,
    "decrad": -0.5480051760712,
    "pmrarad": -0.00000548683034831944,
    "pmdecrad": -6.63981424e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118782,
    "lum": 0.000870161772151997,
    "ra": 11.887014,
    "dec": -31.398384,
    "pmra": -1131.74,
    "pmdec": -136.96,
    "rv": 0,
    "Asset Name": "ASTRID 001244",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Angelic",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000844,
    "y_1": 0.00009123,
    "z_1": -0.00000945,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118782,
    "Gliese Catalog_1": "NN 3692"
  },
  {
    "Astrid #": "ASTRID 001245",
    "HYG Star ID": 118825,
    "Gliese Catalog": "NN 3731A",
    "Distance in Parsecs": 16.6667,
    "mag": 13,
    "Absolute Visual Magnitude": 11.891,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.450057,
    "y": -2.159175,
    "z": -1.585076,
    "vx": -0.00000309,
    "vy": 0.00004159,
    "vz": -0.00002471,
    "rarad": 3.27210350636265,
    "decrad": -0.0952483134414999,
    "pmrarad": -0.00000249863274684722,
    "pmdecrad": -0.00000148946883,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118825,
    "base": "NN 3731",
    "lum": 0.00152615976562546,
    "ra": 12.498515,
    "dec": -5.457326,
    "pmra": -515.38,
    "pmdec": -307.23,
    "rv": 0,
    "Asset Name": "ASTRID 001245",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Goddess",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000309,
    "y_1": 0.00004159,
    "z_1": -0.00002471,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118825,
    "Gliese Catalog_1": "NN 3731A"
  },
  {
    "Astrid #": "ASTRID 001246",
    "HYG Star ID": 118826,
    "Gliese Catalog": "NN 3732B",
    "Distance in Parsecs": 16.6667,
    "mag": 14.25,
    "Absolute Visual Magnitude": 13.141,
    "Spectral Type": "M3.5:",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.450057,
    "y": -2.159175,
    "z": -1.585076,
    "vx": -0.00000309,
    "vy": 0.00004159,
    "vz": -0.00002471,
    "rarad": 3.27210350636265,
    "decrad": -0.0952483134414999,
    "pmrarad": -0.00000249863274684722,
    "pmdecrad": -0.00000148946883,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118826,
    "base": "NN 3732",
    "lum": 0.00048261409326852,
    "ra": 12.498515,
    "dec": -5.457326,
    "pmra": -515.38,
    "pmdec": -307.23,
    "rv": 0,
    "Asset Name": "ASTRID 001246",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Naughty Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000309,
    "y_1": 0.00004159,
    "z_1": -0.00002471,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118826,
    "Gliese Catalog_1": "NN 3732B"
  },
  {
    "Astrid #": "ASTRID 001247",
    "HYG Star ID": 118864,
    "Gliese Catalog": "NN 3762",
    "Distance in Parsecs": 16.6667,
    "mag": 15.48,
    "Absolute Visual Magnitude": 14.371,
    "Spectral Type": "m",
    "Color Index": 1.86,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.703998,
    "y": -4.110447,
    "z": 8.548891,
    "vx": -0.00001465,
    "vy": -0.00001889,
    "vz": -0.00003256,
    "rarad": 3.43299221076736,
    "decrad": 0.5385973475954,
    "pmrarad": 8.33055347298611e-7,
    "pmdecrad": -0.000002276428092,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118864,
    "lum": 0.000155453319406078,
    "ra": 13.113064,
    "dec": 30.859355,
    "pmra": 171.83,
    "pmdec": -469.55,
    "rv": 0,
    "Asset Name": "ASTRID 001247",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Knocked Out",
    "Hair": "Braided Ponytail",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001465,
    "y_1": -0.00001889,
    "z_1": -0.00003256,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118864,
    "Gliese Catalog_1": "NN 3762"
  },
  {
    "Astrid #": "ASTRID 001248",
    "HYG Star ID": 118888,
    "Gliese Catalog": "NN 3786",
    "Distance in Parsecs": 16.6667,
    "mag": 13.6,
    "Absolute Visual Magnitude": 12.491,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.237018,
    "y": -5.301987,
    "z": -8.628395,
    "vx": -0.00001235,
    "vy": 0.0000467,
    "vz": -0.00000977,
    "rarad": 3.52259078120504,
    "decrad": -0.54416355908535,
    "pmrarad": -0.00000287683589904861,
    "pmdecrad": -6.85361707e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118888,
    "lum": 0.000878213279898006,
    "ra": 13.455306,
    "dec": -31.178275,
    "pmra": -593.39,
    "pmdec": -141.37,
    "rv": 0,
    "Asset Name": "ASTRID 001248",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Lovestruck",
    "Hair": "Sweetheart",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001235,
    "y_1": 0.0000467,
    "z_1": -0.00000977,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118888,
    "Gliese Catalog_1": "NN 3786"
  },
  {
    "Astrid #": "ASTRID 001249",
    "HYG Star ID": 118996,
    "Gliese Catalog": "NN 3878A",
    "Distance in Parsecs": 16.6667,
    "mag": 15.5,
    "Absolute Visual Magnitude": 14.391,
    "Spectral Type": "M5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.386686,
    "y": -11.038607,
    "z": 5.126898,
    "vx": -0.00004536,
    "vy": 0.00005299,
    "vz": 0.00004,
    "rarad": 3.91148099814607,
    "decrad": 0.3126757010922,
    "pmrarad": -0.00000417739707850694,
    "pmdecrad": 0.000002363461844,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118996,
    "base": "NN 3878",
    "lum": 0.000152615976562546,
    "ra": 14.940757,
    "dec": 17.914998,
    "pmra": -861.65,
    "pmdec": 487.5,
    "rv": 8,
    "Asset Name": "ASTRID 001249",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Audiohead",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004536,
    "y_1": 0.00005299,
    "z_1": 0.00004,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118996,
    "Gliese Catalog_1": "NN 3878A"
  },
  {
    "Astrid #": "ASTRID 001250",
    "HYG Star ID": 118997,
    "Gliese Catalog": "NN 3879B",
    "Distance in Parsecs": 16.6667,
    "mag": 18.6,
    "Absolute Visual Magnitude": 17.491,
    "Spectral Type": "M7  :",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.385706,
    "y": -11.039258,
    "z": 5.127839,
    "vx": -0.00004606,
    "vy": 0.00005231,
    "vz": 0.00004031,
    "rarad": 3.91155342114607,
    "decrad": 0.3127341220922,
    "pmrarad": -0.00000417739707850694,
    "pmdecrad": 0.000002363461844,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118997,
    "base": "NN 3879",
    "lum": 0.00000878213279898006,
    "ra": 14.941033,
    "dec": 17.918345,
    "pmra": -861.65,
    "pmdec": 487.5,
    "rv": 9,
    "Asset Name": "ASTRID 001250",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Gimme Blood",
    "Hair": "Heartbreaker",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004606,
    "y_1": 0.00005231,
    "z_1": 0.00004031,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 118997,
    "Gliese Catalog_1": "NN 3879B"
  },
  {
    "Astrid #": "ASTRID 001251",
    "HYG Star ID": 119019,
    "Gliese Catalog": "GJ 2112A",
    "Distance in Parsecs": 16.6667,
    "mag": 13.28,
    "Absolute Visual Magnitude": 12.171,
    "Spectral Type": "M3 :",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.364408,
    "y": -11.381854,
    "z": -7.780756,
    "vx": 0.00001228,
    "vy": -0.00003958,
    "vz": 0.00004309,
    "rarad": 4.02391628054022,
    "decrad": -0.485719027648399,
    "pmrarad": 0.0000020782508044375,
    "pmdecrad": 0.000002924367032,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119019,
    "base": "GJ 2112",
    "lum": 0.001179234020653,
    "ra": 15.370228,
    "dec": -27.82965,
    "pmra": 428.67,
    "pmdec": 603.19,
    "rv": 0,
    "Asset Name": "ASTRID 001251",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Tiny Crown",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001228,
    "y_1": -0.00003958,
    "z_1": 0.00004309,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119019,
    "Gliese Catalog_1": "GJ 2112A"
  },
  {
    "Astrid #": "ASTRID 001252",
    "HYG Star ID": 119091,
    "Gliese Catalog": "NN 3960",
    "Distance in Parsecs": 16.6667,
    "mag": 13.05,
    "Absolute Visual Magnitude": 11.941,
    "Spectral Type": "m",
    "Color Index": 1.66,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.093315,
    "y": -15.249213,
    "z": 2.848142,
    "vx": 0.0000184,
    "vy": -0.00000509,
    "vz": 0.00001214,
    "rarad": 4.3322419063983,
    "decrad": 0.171731051558299,
    "pmrarad": 0.00000113848796604861,
    "pmdecrad": 7.39331166e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119091,
    "lum": 0.00145747126125652,
    "ra": 16.547945,
    "dec": 9.839464,
    "pmra": 234.83,
    "pmdec": 152.5,
    "rv": 0,
    "Asset Name": "ASTRID 001252",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Smitten",
    "Hair": "Braided Bun",
    "Outfit": "Toga",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000184,
    "y_1": -0.00000509,
    "z_1": 0.00001214,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119091,
    "Gliese Catalog_1": "NN 3960"
  },
  {
    "Astrid #": "ASTRID 001253",
    "HYG Star ID": 119149,
    "Gliese Catalog": "NN 4003",
    "Distance in Parsecs": 16.6667,
    "mag": 13.84,
    "Absolute Visual Magnitude": 12.731,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.56712,
    "y": -15.300772,
    "z": 6.088936,
    "vx": -0.00001179,
    "vy": 0.00000951,
    "vz": 0.00001892,
    "rarad": 4.54616292314636,
    "decrad": 0.3739931538034,
    "pmrarad": -7.92137072659722e-7,
    "pmdecrad": 0.000001219796068,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119149,
    "lum": 0.000704044321469975,
    "ra": 17.365063,
    "dec": 21.428229,
    "pmra": -163.39,
    "pmdec": 251.6,
    "rv": 0,
    "Asset Name": "ASTRID 001253",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001179,
    "y_1": 0.00000951,
    "z_1": 0.00001892,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119149,
    "Gliese Catalog_1": "NN 4003"
  },
  {
    "Astrid #": "ASTRID 001254",
    "HYG Star ID": 119165,
    "Gliese Catalog": "NN 4013",
    "Distance in Parsecs": 16.6667,
    "mag": 10.94,
    "Absolute Visual Magnitude": 9.831,
    "Spectral Type": "k-m",
    "Color Index": 1.47,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.37983,
    "y": -14.365147,
    "z": -8.337715,
    "vx": -0.00001548,
    "vy": 0.00000694,
    "vz": -0.00000939,
    "rarad": 4.61663618631099,
    "decrad": -0.5239012534524,
    "pmrarad": -9.64633780201388e-7,
    "pmdecrad": -6.50649048e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 119165,
    "lum": 0.0101765366262053,
    "ra": 17.634251,
    "dec": -30.017331,
    "pmra": -198.97,
    "pmdec": -134.21,
    "rv": 0,
    "Asset Name": "ASTRID 001254",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Unkempt",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001548,
    "y_1": 0.00000694,
    "z_1": -0.00000939,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119165,
    "Gliese Catalog_1": "NN 4013"
  },
  {
    "Astrid #": "ASTRID 001255",
    "HYG Star ID": 119290,
    "Gliese Catalog": "NN 4113",
    "Distance in Parsecs": 16.6667,
    "mag": 11.7,
    "Absolute Visual Magnitude": 10.591,
    "Spectral Type": "M2.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.109151,
    "y": -11.797012,
    "z": 10.606885,
    "vx": 0.00002476,
    "vy": -0.00001183,
    "vz": -0.00002507,
    "rarad": 5.12107471714945,
    "decrad": 0.689837441668,
    "pmrarad": 0.00000108118298900694,
    "pmdecrad": -0.00000195052664,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 119290,
    "lum": 0.00505358994753168,
    "ra": 19.561065,
    "dec": 39.524774,
    "pmra": 223.01,
    "pmdec": -402.32,
    "rv": 0,
    "Asset Name": "ASTRID 001255",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Charmer",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00002476,
    "y_1": -0.00001183,
    "z_1": -0.00002507,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119290,
    "Gliese Catalog_1": "NN 4113"
  },
  {
    "Astrid #": "ASTRID 001256",
    "HYG Star ID": 119323,
    "Gliese Catalog": "NN 4132",
    "Distance in Parsecs": 16.6667,
    "mag": 13.4,
    "Absolute Visual Magnitude": 12.291,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.496982,
    "y": -14.142948,
    "z": -2.357378,
    "vx": -0.00003677,
    "vy": -0.00001869,
    "vz": -0.00002048,
    "rarad": 5.25339251058879,
    "decrad": -0.14191831005825,
    "pmrarad": -0.000002468768224125,
    "pmdecrad": -0.000001241661165,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119323,
    "lum": 0.00105584459243007,
    "ra": 20.066481,
    "dec": -8.13132,
    "pmra": -509.22,
    "pmdec": -256.11,
    "rv": 0,
    "Asset Name": "ASTRID 001256",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Audiophile",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003677,
    "y_1": -0.00001869,
    "z_1": -0.00002048,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119323,
    "Gliese Catalog_1": "NN 4132"
  },
  {
    "Astrid #": "ASTRID 001257",
    "HYG Star ID": 119420,
    "Gliese Catalog": "NN 4202",
    "Distance in Parsecs": 16.6667,
    "mag": 14.01,
    "Absolute Visual Magnitude": 12.901,
    "Spectral Type": "M3.5",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.294001,
    "y": -9.853473,
    "z": -1.98935,
    "vx": 0.00000137,
    "vy": 0.00000991,
    "vz": -0.00003997,
    "rarad": 5.64534184207912,
    "decrad": -0.11964600563165,
    "pmrarad": 5.26701582555555e-7,
    "pmdecrad": -0.000002415792633,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119420,
    "lum": 0.000602004863684458,
    "ra": 21.563617,
    "dec": -6.855211,
    "pmra": 108.64,
    "pmdec": -498.29,
    "rv": 0,
    "Asset Name": "ASTRID 001257",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Fire",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000137,
    "y_1": 0.00000991,
    "z_1": -0.00003997,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119420,
    "Gliese Catalog_1": "NN 4202"
  },
  {
    "Astrid #": "ASTRID 001258",
    "HYG Star ID": 119482,
    "Gliese Catalog": "NN 4267",
    "Distance in Parsecs": 16.6667,
    "mag": 12.9,
    "Absolute Visual Magnitude": 11.791,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.032034,
    "y": -2.96061,
    "z": 15.252156,
    "vx": -0.00001446,
    "vy": 0.00000744,
    "vz": 0.00000717,
    "rarad": 5.82691162684226,
    "decrad": 1.155824050263,
    "pmrarad": 1.86168453333333e-8,
    "pmdecrad": 0.00000106642526,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119482,
    "lum": 0.00167340090683931,
    "ra": 22.257163,
    "dec": 66.22384,
    "pmra": 3.84,
    "pmdec": 219.97,
    "rv": 0,
    "Asset Name": "ASTRID 001258",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Innocent",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001446,
    "y_1": 0.00000744,
    "z_1": 0.00000717,
    "Distance in Parsecs_1": 16.6667,
    "HYG Star ID_1": 119482,
    "Gliese Catalog_1": "NN 4267"
  },
  {
    "Astrid #": "ASTRID 001259",
    "HYG Star ID": 118505,
    "Gliese Catalog": "Gl 291B",
    "Distance in Parsecs": 16.6722,
    "mag": 6.17,
    "Absolute Visual Magnitude": 5.06,
    "Spectral Type": "G4 V",
    "Color Index": 0.65,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.583378,
    "y": 14.29764,
    "z": -4.004015,
    "vx": 0.00000789,
    "vy": -0.00000336,
    "vz": -0.00002696,
    "rarad": 2.0584626640712,
    "decrad": -0.2425318504757,
    "pmrarad": -3.23758575875e-7,
    "pmdecrad": -0.000001665669514,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 38273,
    "base": "Gl 291",
    "lum": 0.824138115013002,
    "ra": 7.862748,
    "dec": -13.896051,
    "pmra": -66.78,
    "pmdec": -343.57,
    "rv": 0,
    "Asset Name": "ASTRID 001259",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Medium Bob",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000789,
    "y_1": -0.00000336,
    "z_1": -0.00002696,
    "Distance in Parsecs_1": 16.6722,
    "HYG Star ID_1": 118505,
    "Gliese Catalog_1": "Gl 291B"
  },
  {
    "Astrid #": "ASTRID 001260",
    "HYG Star ID": 17363,
    "Hipparcos Catalogue": 17405,
    "Gliese Catalog": "Gl 150.1A",
    "Distance in Parsecs": 16.6889,
    "mag": 10.83,
    "Absolute Visual Magnitude": 9.718,
    "Spectral Type": "M1",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.954618,
    "y": 13.245097,
    "z": 4.786689,
    "vx": 0.00001322,
    "vy": 0.00004253,
    "vz": -0.00001369,
    "rarad": 0.976311360546118,
    "decrad": 0.29089857625688,
    "pmrarad": 7.70805270715277e-7,
    "pmdecrad": -0.000001518484928,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 17363,
    "base": "Gl 150.1",
    "lum": 0.0112927574420941,
    "var_min": 10.908,
    "var_max": 10.758,
    "ra": 3.729235,
    "dec": 16.667261,
    "pmra": 158.99,
    "pmdec": -313.21,
    "rv": 36.1,
    "Asset Name": "ASTRID 001260",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Audiophile",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00001322,
    "y_1": 0.00004253,
    "z_1": -0.00001369,
    "Distance in Parsecs_1": 16.6889,
    "HYG Star ID_1": 17363,
    "Hipparcos Catalogue_1": 17405,
    "Gliese Catalog_1": "Gl 150.1A"
  },
  {
    "Astrid #": "ASTRID 001261",
    "HYG Star ID": 6337,
    "Hipparcos Catalogue": 6351,
    "Gliese Catalog": "Gl 57",
    "Distance in Parsecs": 16.7056,
    "mag": 10.15,
    "Absolute Visual Magnitude": 9.036,
    "Spectral Type": "K5",
    "Color Index": 1.383,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.699304,
    "y": 4.34957,
    "z": -11.103751,
    "vx": -0.00003719,
    "vy": 0.00009322,
    "vz": -0.00004729,
    "rarad": 0.355927089590507,
    "decrad": -0.727041380278458,
    "pmrarad": 0.00000600669605797916,
    "pmdecrad": -0.000002209974681,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 6337,
    "lum": 0.0211641095077086,
    "var_min": 10.211,
    "var_max": 10.091,
    "ra": 1.359541,
    "dec": -41.656403,
    "pmra": 1238.97,
    "pmdec": -455.84,
    "rv": 29,
    "Asset Name": "ASTRID 001261",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Shinobi",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00003719,
    "y_1": 0.00009322,
    "z_1": -0.00004729,
    "Distance in Parsecs_1": 16.7056,
    "HYG Star ID_1": 6337,
    "Hipparcos Catalogue_1": 6351,
    "Gliese Catalog_1": "Gl 57"
  },
  {
    "Astrid #": "ASTRID 001262",
    "HYG Star ID": 522,
    "Hipparcos Catalogue": 523,
    "Gliese Catalog": "NN 3006",
    "Distance in Parsecs": 16.7084,
    "mag": 12.17,
    "Absolute Visual Magnitude": 11.055,
    "Spectral Type": "M4",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.835774,
    "y": 0.188543,
    "z": -15.24491,
    "vx": -0.00004125,
    "vy": 0.00001473,
    "vz": -0.00001832,
    "rarad": 0.0275628690703821,
    "decrad": -1.14913417371136,
    "pmrarad": 9.49265186527777e-7,
    "pmdecrad": -0.000002678595585,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 522,
    "lum": 0.00329609712177457,
    "var": "CQ",
    "var_min": 12.294,
    "var_max": 11.974,
    "ra": 0.105282,
    "dec": -65.840538,
    "pmra": 195.8,
    "pmdec": -552.5,
    "rv": 0,
    "Asset Name": "ASTRID 001262",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Arrogant",
    "Hair": "Sweetheart",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00004125,
    "y_1": 0.00001473,
    "z_1": -0.00001832,
    "Distance in Parsecs_1": 16.7084,
    "HYG Star ID_1": 522,
    "Hipparcos Catalogue_1": 523,
    "Gliese Catalog_1": "NN 3006"
  },
  {
    "Astrid #": "ASTRID 001263",
    "HYG Star ID": 32394,
    "Hipparcos Catalogue": 32480,
    "Henry Draper Catalogue": 48682,
    "Harvard Revised Catalogue": 2483,
    "Gliese Catalog": "Gl 245",
    "Distance in Parsecs": 16.7168,
    "mag": 5.24,
    "Absolute Visual Magnitude": 4.124,
    "Spectral Type": "G0V",
    "Color Index": 0.575,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.452642,
    "y": 11.859259,
    "z": 11.523315,
    "vx": 0.00000548,
    "vy": -0.00002639,
    "vz": -0.00000713,
    "rarad": 1.77473360012206,
    "decrad": 0.760569580283891,
    "pmrarad": -1.35747830555555e-9,
    "pmdecrad": 8.02318159e-7,
    "bayer": "Psi-5",
    "flam": 56,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 32394,
    "lum": 1.95164130028582,
    "bf": "56Psi5Aur",
    "ra": 6.778983,
    "dec": 43.577427,
    "pmra": -0.28,
    "pmdec": 165.49,
    "rv": -23.9,
    "Asset Name": "ASTRID 001263",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Neko Ninja",
    "Hair": "Lion Mane",
    "Outfit": "Astro",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000548,
    "y_1": -0.00002639,
    "z_1": -0.00000713,
    "Distance in Parsecs_1": 16.7168,
    "HYG Star ID_1": 32394,
    "Hipparcos Catalogue_1": 32480,
    "Henry Draper Catalogue_1": 48682,
    "Harvard Revised Catalogue_1": 2483,
    "Gliese Catalog_1": "Gl 245"
  },
  {
    "Astrid #": "ASTRID 001264",
    "HYG Star ID": 29495,
    "Hipparcos Catalogue": 29568,
    "Henry Draper Catalogue": 43162,
    "Harvard Revised Catalogue": 2225,
    "Gliese Catalog": "NN 3389",
    "Distance in Parsecs": 16.7224,
    "mag": 6.37,
    "Absolute Visual Magnitude": 5.254,
    "Spectral Type": "G5V",
    "Color Index": 0.713,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.917305,
    "y": 15.265666,
    "z": -6.764863,
    "vx": 0.00000233,
    "vy": 0.00002477,
    "vz": -0.00000105,
    "rarad": 1.63081371265846,
    "decrad": -0.416469382489253,
    "pmrarad": -2.28153318069444e-7,
    "pmdecrad": 5.37658371e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 29495,
    "lum": 0.689286860731844,
    "var": "V352",
    "var_min": 6.397,
    "var_max": 6.347,
    "ra": 6.22925,
    "dec": -23.861938,
    "pmra": -47.06,
    "pmdec": 110.9,
    "rv": 22.4,
    "Asset Name": "ASTRID 001264",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Charmer",
    "Hair": "Pompadour",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00000233,
    "y_1": 0.00002477,
    "z_1": -0.00000105,
    "Distance in Parsecs_1": 16.7224,
    "HYG Star ID_1": 29495,
    "Hipparcos Catalogue_1": 29568,
    "Henry Draper Catalogue_1": 43162,
    "Harvard Revised Catalogue_1": 2225,
    "Gliese Catalog_1": "NN 3389"
  },
  {
    "Astrid #": "ASTRID 001265",
    "HYG Star ID": 101626,
    "Hipparcos Catalogue": 101955,
    "Henry Draper Catalogue": 196795,
    "Gliese Catalog": "Gl 795A",
    "Distance in Parsecs": 16.7224,
    "mag": 7.88,
    "Absolute Visual Magnitude": 6.764,
    "Spectral Type": "K5V",
    "Color Index": 1.241,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.687579,
    "y": -12.778973,
    "z": 1.449287,
    "vx": 0.0000277,
    "vy": 0.00007585,
    "vz": 0.00000198,
    "rarad": 5.40889950191927,
    "decrad": 0.0867780854002762,
    "pmrarad": 0.00000418079077427083,
    "pmdecrad": 3.27588603e-7,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 101626,
    "base": "Gl 795",
    "lum": 0.171553664772834,
    "var": "OQ",
    "var_min": 7.909,
    "var_max": 7.859,
    "ra": 20.660474,
    "dec": 4.972018,
    "pmra": 862.35,
    "pmdec": 67.57,
    "rv": -39.2,
    "Asset Name": "ASTRID 001265",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Sweetheart",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.0000277,
    "y_1": 0.00007585,
    "z_1": 0.00000198,
    "Distance in Parsecs_1": 16.7224,
    "HYG Star ID_1": 101626,
    "Hipparcos Catalogue_1": 101955,
    "Henry Draper Catalogue_1": 196795,
    "Gliese Catalog_1": "Gl 795A"
  },
  {
    "Astrid #": "ASTRID 001266",
    "HYG Star ID": 119188,
    "Gliese Catalog": "GJ 1222",
    "Distance in Parsecs": 16.7224,
    "mag": 13.11,
    "Absolute Visual Magnitude": 11.994,
    "Spectral Type": "m",
    "Color Index": 1.55,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.412349,
    "y": -16.577825,
    "z": 2.147129,
    "vx": -0.00004667,
    "vy": 0.00001819,
    "vz": -0.00002778,
    "rarad": 4.68752174653697,
    "decrad": 0.1287617443845,
    "pmrarad": -0.00000281734926044444,
    "pmdecrad": -0.00000151697231,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119188,
    "lum": 0.00138803366656723,
    "ra": 17.905014,
    "dec": 7.377505,
    "pmra": -581.12,
    "pmdec": -312.9,
    "rv": -20,
    "Asset Name": "ASTRID 001266",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Braided Ponytail",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004667,
    "y_1": 0.00001819,
    "z_1": -0.00002778,
    "Distance in Parsecs_1": 16.7224,
    "HYG Star ID_1": 119188,
    "Gliese Catalog_1": "GJ 1222"
  },
  {
    "Astrid #": "ASTRID 001267",
    "HYG Star ID": 119612,
    "Gliese Catalog": "NN 4385",
    "Distance in Parsecs": 16.7224,
    "mag": 16.1,
    "Absolute Visual Magnitude": 14.984,
    "Spectral Type": "m",
    "Color Index": 1.87,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.240971,
    "y": -0.008954,
    "z": 12.380596,
    "vx": 0.00001273,
    "vy": 0.00007008,
    "vz": -0.00001149,
    "rarad": 6.28228659534665,
    "decrad": 0.8336057842742,
    "pmrarad": 0.00000419208693302777,
    "pmdecrad": -0.000001021914516,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119612,
    "lum": 0.0000883893621739405,
    "ra": 23.996567,
    "dec": 47.762093,
    "pmra": 864.68,
    "pmdec": -210.78,
    "rv": 0,
    "Asset Name": "ASTRID 001267",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Ponytail",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001273,
    "y_1": 0.00007008,
    "z_1": -0.00001149,
    "Distance in Parsecs_1": 16.7224,
    "HYG Star ID_1": 119612,
    "Gliese Catalog_1": "NN 4385"
  },
  {
    "Astrid #": "ASTRID 001268",
    "HYG Star ID": 65315,
    "Hipparcos Catalogue": 65520,
    "Gliese Catalog": "Gl 510",
    "Distance in Parsecs": 16.7448,
    "mag": 11.04,
    "Absolute Visual Magnitude": 9.921,
    "Spectral Type": "M",
    "Color Index": 1.522,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.712258,
    "y": -5.388558,
    "z": -7.957485,
    "vx": 0.00000209,
    "vy": 0.0000402,
    "vz": -7e-7,
    "rarad": 3.5160299579811,
    "decrad": -0.495219529316233,
    "pmrarad": -0.00000218903073044444,
    "pmdecrad": -5.09636141e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 65315,
    "lum": 0.00936698877952116,
    "var_min": 11.109,
    "var_max": 10.969,
    "ra": 13.430245,
    "dec": -28.373989,
    "pmra": -451.52,
    "pmdec": -105.12,
    "rv": -14,
    "Asset Name": "ASTRID 001268",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Fire",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000209,
    "y_1": 0.0000402,
    "z_1": -7e-7,
    "Distance in Parsecs_1": 16.7448,
    "HYG Star ID_1": 65315,
    "Hipparcos Catalogue_1": 65520,
    "Gliese Catalog_1": "Gl 510"
  },
  {
    "Astrid #": "ASTRID 001269",
    "HYG Star ID": 62276,
    "Hipparcos Catalogue": 62471,
    "Gliese Catalog": "GJ 1164B",
    "Distance in Parsecs": 16.7616,
    "mag": 9.9,
    "Absolute Visual Magnitude": 8.778,
    "Spectral Type": "K0",
    "Color Index": 1.38,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.88045,
    "y": -3.174242,
    "z": -7.031901,
    "vx": -0.00001116,
    "vy": 0.00002378,
    "vz": 0.00001288,
    "rarad": 3.35176027871662,
    "decrad": -0.432921403107423,
    "pmrarad": -0.00000152677524280555,
    "pmdecrad": 8.46387723e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 2,
    "comp_primary": 62278,
    "base": "GJ 1164",
    "lum": 0.0268410808354522,
    "ra": 12.802781,
    "dec": -24.804569,
    "pmra": -314.92,
    "pmdec": 174.58,
    "rv": 0,
    "Asset Name": "ASTRID 001269",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Blep",
    "Hair": "Mohawk",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001116,
    "y_1": 0.00002378,
    "z_1": 0.00001288,
    "Distance in Parsecs_1": 16.7616,
    "HYG Star ID_1": 62276,
    "Hipparcos Catalogue_1": 62471,
    "Gliese Catalog_1": "GJ 1164B"
  },
  {
    "Astrid #": "ASTRID 001270",
    "HYG Star ID": 39046,
    "Hipparcos Catalogue": 39157,
    "Henry Draper Catalogue": 65583,
    "Gliese Catalog": "Gl 295",
    "Distance in Parsecs": 16.7673,
    "mag": 6.97,
    "Absolute Visual Magnitude": 5.848,
    "Spectral Type": "G8V",
    "Color Index": 0.716,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.347041,
    "y": 12.657061,
    "z": 8.183309,
    "vx": -0.00001774,
    "vy": 0.00005828,
    "vz": -0.00007526,
    "rarad": 2.09673264261212,
    "decrad": 0.509851789545552,
    "pmrarad": -8.29758614270833e-7,
    "pmdecrad": -0.000005644394794,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 39046,
    "lum": 0.398841186909052,
    "ra": 8.008929,
    "dec": 29.212356,
    "pmra": -171.15,
    "pmdec": -1164.24,
    "rv": 14.7,
    "Asset Name": "ASTRID 001270",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Wavy",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00001774,
    "y_1": 0.00005828,
    "z_1": -0.00007526,
    "Distance in Parsecs_1": 16.7673,
    "HYG Star ID_1": 39046,
    "Hipparcos Catalogue_1": 39157,
    "Henry Draper Catalogue_1": 65583,
    "Gliese Catalog_1": "Gl 295"
  },
  {
    "Astrid #": "ASTRID 001271",
    "HYG Star ID": 80772,
    "Hipparcos Catalogue": 81018,
    "Gliese Catalog": "GJ 1203",
    "Distance in Parsecs": 16.7701,
    "mag": 12.13,
    "Absolute Visual Magnitude": 11.007,
    "Spectral Type": "K:",
    "Color Index": 1.47,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.080691,
    "y": -15.193805,
    "z": 3.661931,
    "vx": -0.0000588,
    "vy": 0.00002087,
    "vz": -0.00001104,
    "rarad": 4.33170368257767,
    "decrad": 0.220134337969373,
    "pmrarad": -0.00000371764826523611,
    "pmdecrad": -6.74860643e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 80772,
    "lum": 0.00344508546286578,
    "var_min": 12.243,
    "var_max": 11.963,
    "ra": 16.545889,
    "dec": 12.612768,
    "pmra": -766.82,
    "pmdec": -139.2,
    "rv": 0,
    "Asset Name": "ASTRID 001271",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh Snap",
    "Hair": "Pixie",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000588,
    "y_1": 0.00002087,
    "z_1": -0.00001104,
    "Distance in Parsecs_1": 16.7701,
    "HYG Star ID_1": 80772,
    "Hipparcos Catalogue_1": 81018,
    "Gliese Catalog_1": "GJ 1203"
  },
  {
    "Astrid #": "ASTRID 001272",
    "HYG Star ID": 119114,
    "Gliese Catalog": "Gl 645",
    "Distance in Parsecs": 16.7785,
    "mag": 11.44,
    "Absolute Visual Magnitude": 10.316,
    "Spectral Type": "M1",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.648022,
    "y": -12.882012,
    "z": -10.112555,
    "vx": -0.00000528,
    "vy": 0.00002378,
    "vz": -0.0000284,
    "rarad": 4.43643586486765,
    "decrad": -0.6468918133565,
    "pmrarad": -6.89162646909722e-7,
    "pmdecrad": -0.00000212098713,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 119114,
    "lum": 0.0065102849843486,
    "ra": 16.945937,
    "dec": -37.064171,
    "pmra": -142.15,
    "pmdec": -437.49,
    "rv": 0,
    "Asset Name": "ASTRID 001272",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Ponytail",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000528,
    "y_1": 0.00002378,
    "z_1": -0.0000284,
    "Distance in Parsecs_1": 16.7785,
    "HYG Star ID_1": 119114,
    "Gliese Catalog_1": "Gl 645"
  },
  {
    "Astrid #": "ASTRID 001273",
    "HYG Star ID": 744,
    "Hipparcos Catalogue": 746,
    "Henry Draper Catalogue": 432,
    "Harvard Revised Catalogue": 21,
    "Gliese Catalog": "Gl 8",
    "Proper Name": "Caph",
    "Distance in Parsecs": 16.7842,
    "mag": 2.28,
    "Absolute Visual Magnitude": 1.155,
    "Spectral Type": "F2III-IV",
    "Color Index": 0.38,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.600014,
    "y": 0.344589,
    "z": 14.409503,
    "vx": 0.00001681,
    "vy": 0.00004329,
    "vz": 0.00000239,
    "rarad": 0.0400258528303232,
    "decrad": 1.03235840436763,
    "pmrarad": 0.00000253746632265972,
    "pmdecrad": -8.74700842e-7,
    "bayer": "Bet",
    "flam": 11,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 744,
    "lum": 30.0607630262822,
    "var": "Bet",
    "var_min": 2.303,
    "var_max": 2.263,
    "bf": "11Bet Cas",
    "ra": 0.152887,
    "dec": 59.14978,
    "pmra": 523.39,
    "pmdec": -180.42,
    "rv": 11.3,
    "Asset Name": "ASTRID 001273",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Sad",
    "Hair": "Angelic",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Caph",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001681,
    "y_1": 0.00004329,
    "z_1": 0.00000239,
    "Distance in Parsecs_1": 16.7842,
    "HYG Star ID_1": 744,
    "Hipparcos Catalogue_1": 746,
    "Henry Draper Catalogue_1": 432,
    "Harvard Revised Catalogue_1": 21,
    "Gliese Catalog_1": "Gl 8"
  },
  {
    "Astrid #": "ASTRID 001274",
    "HYG Star ID": 69063,
    "Hipparcos Catalogue": 69285,
    "Distance in Parsecs": 16.7842,
    "mag": 10.77,
    "Absolute Visual Magnitude": 9.645,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -12.064354,
    "y": -7.756904,
    "z": -8.717291,
    "vx": -0.00001862,
    "vy": 0.00001905,
    "vz": 0.00000881,
    "rarad": 3.7130056205221,
    "decrad": -0.546119213810127,
    "pmrarad": -0.00000155479747354166,
    "pmdecrad": 6.14307414e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 69063,
    "lum": 0.0120781383510677,
    "var_min": 10.856,
    "var_max": 10.676,
    "ra": 14.182637,
    "dec": -31.290326,
    "pmra": -320.7,
    "pmdec": 126.71,
    "rv": 0,
    "Asset Name": "ASTRID 001274",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Mohawk",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00001862,
    "y_1": 0.00001905,
    "z_1": 0.00000881,
    "Distance in Parsecs_1": 16.7842,
    "HYG Star ID_1": 69063,
    "Hipparcos Catalogue_1": 69285
  },
  {
    "Astrid #": "ASTRID 001275",
    "HYG Star ID": 16030,
    "Hipparcos Catalogue": 16069,
    "Henry Draper Catalogue": 21749,
    "Gliese Catalog": "Gl 143",
    "Distance in Parsecs": 16.8067,
    "mag": 8.08,
    "Absolute Visual Magnitude": 6.953,
    "Spectral Type": "K5V",
    "Color Index": 1.13,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.643275,
    "y": 5.889283,
    "z": -15.041235,
    "vx": -0.00001783,
    "vy": 0.00002436,
    "vz": -0.00006145,
    "rarad": 0.903132714943134,
    "decrad": -1.10826838869999,
    "pmrarad": 0.00000173073635821527,
    "pmdecrad": -0.000001201222856,
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 16030,
    "lum": 0.14414513857582,
    "var_min": 8.109,
    "var_max": 7.979,
    "ra": 3.449713,
    "dec": -63.499101,
    "pmra": 356.99,
    "pmdec": -247.77,
    "rv": 57.3,
    "Asset Name": "ASTRID 001275",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Fedora",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": -0.00001783,
    "y_1": 0.00002436,
    "z_1": -0.00006145,
    "Distance in Parsecs_1": 16.8067,
    "HYG Star ID_1": 16030,
    "Hipparcos Catalogue_1": 16069,
    "Henry Draper Catalogue_1": 21749,
    "Gliese Catalog_1": "Gl 143"
  },
  {
    "Astrid #": "ASTRID 001276",
    "HYG Star ID": 6365,
    "Hipparcos Catalogue": 6379,
    "Henry Draper Catalogue": 7924,
    "Gliese Catalog": "Gl 56.5",
    "Distance in Parsecs": 16.8095,
    "mag": 7.17,
    "Absolute Visual Magnitude": 6.042,
    "Spectral Type": "K0",
    "Color Index": 0.826,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.619426,
    "y": 1.352983,
    "z": 16.359155,
    "vx": -0.00000127,
    "vy": -0.00000344,
    "vz": -0.00002234,
    "rarad": 0.357733008588756,
    "decrad": 1.33884703058977,
    "pmrarad": -1.65418427805555e-7,
    "pmdecrad": -1.67018312e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 6365,
    "lum": 0.333579996662048,
    "ra": 1.366439,
    "dec": 76.710284,
    "pmra": -34.12,
    "pmdec": -34.45,
    "rv": -21.8,
    "Asset Name": "ASTRID 001276",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Bob",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000127,
    "y_1": -0.00000344,
    "z_1": -0.00002234,
    "Distance in Parsecs_1": 16.8095,
    "HYG Star ID_1": 6365,
    "Hipparcos Catalogue_1": 6379,
    "Henry Draper Catalogue_1": 7924,
    "Gliese Catalog_1": "Gl 56.5"
  },
  {
    "Astrid #": "ASTRID 001277",
    "HYG Star ID": 117832,
    "Hipparcos Catalogue": 118200,
    "Gliese Catalog": "NN 4383",
    "Distance in Parsecs": 16.8152,
    "mag": 11.78,
    "Absolute Visual Magnitude": 10.651,
    "Spectral Type": "M2V:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.664866,
    "y": -0.105866,
    "z": 2.240977,
    "vx": 0.00000344,
    "vy": 0.00000641,
    "vz": -0.00002526,
    "rarad": 6.27683273891138,
    "decrad": 0.133668583740109,
    "pmrarad": 3.82275587118055e-7,
    "pmdecrad": -0.000001515915416,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 117832,
    "lum": 0.00478189460665907,
    "var_min": 11.871,
    "var_max": 11.691,
    "ra": 23.975735,
    "dec": 7.658646,
    "pmra": 78.85,
    "pmdec": -312.68,
    "rv": 0,
    "Asset Name": "ASTRID 001277",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Afro",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00000344,
    "y_1": 0.00000641,
    "z_1": -0.00002526,
    "Distance in Parsecs_1": 16.8152,
    "HYG Star ID_1": 117832,
    "Hipparcos Catalogue_1": 118200,
    "Gliese Catalog_1": "NN 4383"
  },
  {
    "Astrid #": "ASTRID 001278",
    "HYG Star ID": 118213,
    "Gliese Catalog": "GJ 1058",
    "Distance in Parsecs": 16.835,
    "mag": 14.78,
    "Absolute Visual Magnitude": 13.649,
    "Spectral Type": "m",
    "Color Index": 1.76,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.690281,
    "y": 12.976448,
    "z": 0.864244,
    "vx": -0.00001862,
    "vy": 0.00001932,
    "vz": -0.00005952,
    "rarad": 0.881697652618445,
    "decrad": 0.051358721284,
    "pmrarad": 0.00000158369236890277,
    "pmdecrad": -0.00000354043432,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118213,
    "lum": 0.000302273448003864,
    "ra": 3.367837,
    "dec": 2.942638,
    "pmra": 326.66,
    "pmdec": -730.27,
    "rv": 0,
    "Asset Name": "ASTRID 001278",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Oh Snap",
    "Hair": "Manbun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001862,
    "y_1": 0.00001932,
    "z_1": -0.00005952,
    "Distance in Parsecs_1": 16.835,
    "HYG Star ID_1": 118213,
    "Gliese Catalog_1": "GJ 1058"
  },
  {
    "Astrid #": "ASTRID 001279",
    "HYG Star ID": 12319,
    "Hipparcos Catalogue": 12351,
    "Gliese Catalog": "GJ 1049",
    "Distance in Parsecs": 16.8435,
    "mag": 9.48,
    "Absolute Visual Magnitude": 8.348,
    "Spectral Type": "M0Ve",
    "Color Index": 1.396,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.826055,
    "y": 5.678155,
    "z": -14.313176,
    "vx": 0.00000261,
    "vy": 1.6e-7,
    "vz": 0.00000131,
    "rarad": 0.693854444694104,
    "decrad": -1.01555728636509,
    "pmrarad": -9.19206738333333e-8,
    "pmdecrad": 1.47043989e-7,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 12319,
    "lum": 0.0398841186909052,
    "ra": 2.650329,
    "dec": -58.187146,
    "pmra": -18.96,
    "pmdec": 30.33,
    "rv": 0,
    "Asset Name": "ASTRID 001279",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Unkempt",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": 0.00000261,
    "y_1": 1.6e-7,
    "z_1": 0.00000131,
    "Distance in Parsecs_1": 16.8435,
    "HYG Star ID_1": 12319,
    "Hipparcos Catalogue_1": 12351,
    "Gliese Catalog_1": "GJ 1049"
  },
  {
    "Astrid #": "ASTRID 001280",
    "HYG Star ID": 17701,
    "Hipparcos Catalogue": 17743,
    "Gliese Catalog": "Gl 155.1",
    "Distance in Parsecs": 16.8464,
    "mag": 11.05,
    "Absolute Visual Magnitude": 9.917,
    "Spectral Type": "M0V:",
    "Color Index": 1.495,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.166296,
    "y": 14.110594,
    "z": 0.819361,
    "vx": 0.00002743,
    "vy": -0.00001577,
    "vz": -0.00003518,
    "rarad": 0.994699696511497,
    "decrad": 0.0486563883135062,
    "pmrarad": -0.00000187559868596527,
    "pmdecrad": -0.000002091049885,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 17701,
    "lum": 0.00940156168803569,
    "var_min": 11.099,
    "var_max": 10.969,
    "ra": 3.799473,
    "dec": 2.787806,
    "pmra": -386.87,
    "pmdec": -431.31,
    "rv": 0,
    "Asset Name": "ASTRID 001280",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "High Fade",
    "Outfit": "Emperor",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00002743,
    "y_1": -0.00001577,
    "z_1": -0.00003518,
    "Distance in Parsecs_1": 16.8464,
    "HYG Star ID_1": 17701,
    "Hipparcos Catalogue_1": 17743,
    "Gliese Catalog_1": "Gl 155.1"
  },
  {
    "Astrid #": "ASTRID 001281",
    "HYG Star ID": 91996,
    "Hipparcos Catalogue": 92283,
    "Henry Draper Catalogue": 174080,
    "Gliese Catalog": "Gl 727",
    "Distance in Parsecs": 16.8606,
    "mag": 7.97,
    "Absolute Visual Magnitude": 6.836,
    "Spectral Type": "K0",
    "Color Index": 1.07,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.478461,
    "y": -16.19554,
    "z": 3.143578,
    "vx": 0.00000992,
    "vy": 0.00000395,
    "vz": -0.00003668,
    "rarad": 4.92395373115333,
    "decrad": 0.187543482300073,
    "pmrarad": 6.24246095083333e-7,
    "pmdecrad": -0.000002117908563,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 91996,
    "lum": 0.160546188680097,
    "var_min": 8.076,
    "var_max": 7.946,
    "ra": 18.808118,
    "dec": 10.74545,
    "pmra": 128.76,
    "pmdec": -436.85,
    "rv": -8.4,
    "Asset Name": "ASTRID 001281",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Little Crown",
    "Outfit": "Unit 01",
    "Special": "Astral Burst",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000992,
    "y_1": 0.00000395,
    "z_1": -0.00003668,
    "Distance in Parsecs_1": 16.8606,
    "HYG Star ID_1": 91996,
    "Hipparcos Catalogue_1": 92283,
    "Henry Draper Catalogue_1": 174080,
    "Gliese Catalog_1": "Gl 727"
  },
  {
    "Astrid #": "ASTRID 001282",
    "HYG Star ID": 118366,
    "Gliese Catalog": "Gl 200B",
    "Distance in Parsecs": 16.8663,
    "mag": 11.7,
    "Absolute Visual Magnitude": 10.565,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.981911,
    "y": 16.575976,
    "z": -0.904051,
    "vx": -0.00005775,
    "vy": 0.00001096,
    "vz": 0.00001035,
    "rarad": 1.39280633070154,
    "decrad": -0.0536267187817499,
    "pmrarad": 0.00000348537403088194,
    "pmdecrad": 6.14564365e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 2,
    "comp_primary": 24759,
    "base": "Gl 200",
    "lum": 0.00517606831950567,
    "ra": 5.320128,
    "dec": -3.072585,
    "pmra": 718.91,
    "pmdec": 126.76,
    "rv": 0,
    "Asset Name": "ASTRID 001282",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "High Fade",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00005775,
    "y_1": 0.00001096,
    "z_1": 0.00001035,
    "Distance in Parsecs_1": 16.8663,
    "HYG Star ID_1": 118366,
    "Gliese Catalog_1": "Gl 200B"
  },
  {
    "Astrid #": "ASTRID 001283",
    "HYG Star ID": 35043,
    "Hipparcos Catalogue": 35136,
    "Henry Draper Catalogue": 55575,
    "Harvard Revised Catalogue": 2721,
    "Gliese Catalog": "GJ 1095",
    "Distance in Parsecs": 16.8919,
    "mag": 5.54,
    "Absolute Visual Magnitude": 4.402,
    "Spectral Type": "G0V",
    "Color Index": 0.576,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.726139,
    "y": 10.846756,
    "z": 12.402653,
    "vx": -0.00002514,
    "vy": 0.00006575,
    "vz": 0.00005363,
    "rarad": 1.90169114388004,
    "decrad": 0.824492905566807,
    "pmrarad": 1.42729147555555e-7,
    "pmdecrad": -9.02335222e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 35043,
    "lum": 1.51077573192499,
    "ra": 7.263925,
    "dec": 47.239964,
    "pmra": 29.44,
    "pmdec": -186.12,
    "rv": 85.2,
    "Asset Name": "ASTRID 001283",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Undercut",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00002514,
    "y_1": 0.00006575,
    "z_1": 0.00005363,
    "Distance in Parsecs_1": 16.8919,
    "HYG Star ID_1": 35043,
    "Hipparcos Catalogue_1": 35136,
    "Henry Draper Catalogue_1": 55575,
    "Harvard Revised Catalogue_1": 2721,
    "Gliese Catalog_1": "GJ 1095"
  },
  {
    "Astrid #": "ASTRID 001284",
    "HYG Star ID": 118119,
    "Gliese Catalog": "GJ 1042",
    "Distance in Parsecs": 16.8919,
    "mag": 14.52,
    "Absolute Visual Magnitude": 13.382,
    "Spectral Type": "DZ7",
    "Color Index": 0.33,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.885831,
    "y": 7.025016,
    "z": 10.841322,
    "vx": -0.00000464,
    "vy": 0.0000971,
    "vz": -0.00001201,
    "rarad": 0.573013733578538,
    "decrad": 0.696777374088,
    "pmrarad": 0.00000498015157077083,
    "pmdecrad": -0.00000239677824,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118119,
    "lum": 0.000386544946598283,
    "ra": 2.188751,
    "dec": 39.922403,
    "pmra": 1027.23,
    "pmdec": -494.37,
    "rv": 29,
    "Asset Name": "ASTRID 001284",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Braided Ponytail",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000464,
    "y_1": 0.0000971,
    "z_1": -0.00001201,
    "Distance in Parsecs_1": 16.8919,
    "HYG Star ID_1": 118119,
    "Gliese Catalog_1": "GJ 1042"
  },
  {
    "Astrid #": "ASTRID 001285",
    "HYG Star ID": 54498,
    "Hipparcos Catalogue": 54658,
    "Gliese Catalog": "Gl 414.1A",
    "Distance in Parsecs": 16.9033,
    "mag": 10.92,
    "Absolute Visual Magnitude": 9.78,
    "Spectral Type": "M4",
    "Color Index": 1.632,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.002049,
    "y": 2.588107,
    "z": 11.617697,
    "vx": -0.00000586,
    "vy": 0.0000544,
    "vz": -0.00003379,
    "rarad": 2.92921618908213,
    "decrad": 0.757775487745387,
    "pmrarad": -0.00000307347632788194,
    "pmdecrad": -0.00000215145767,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 54498,
    "base": "Gl 414.1",
    "lum": 0.0106659612123025,
    "var_min": 10.989,
    "var_max": 10.809,
    "ra": 11.188782,
    "dec": 43.417337,
    "pmra": -633.95,
    "pmdec": -443.77,
    "rv": -10.5,
    "Asset Name": "ASTRID 001285",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Goddess",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000586,
    "y_1": 0.0000544,
    "z_1": -0.00003379,
    "Distance in Parsecs_1": 16.9033,
    "HYG Star ID_1": 54498,
    "Hipparcos Catalogue_1": 54658,
    "Gliese Catalog_1": "Gl 414.1A"
  },
  {
    "Astrid #": "ASTRID 001286",
    "HYG Star ID": 14695,
    "Hipparcos Catalogue": 14731,
    "Gliese Catalog": "NN 3205",
    "Distance in Parsecs": 16.9147,
    "mag": 11.82,
    "Absolute Visual Magnitude": 10.679,
    "Spectral Type": "M4V:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.352731,
    "y": 12.417347,
    "z": 1.741281,
    "vx": 0.0000109,
    "vy": -0.00000366,
    "vz": -0.00004497,
    "rarad": 0.830156332057266,
    "decrad": 0.103127506921431,
    "pmrarad": -6.21822026680555e-7,
    "pmdecrad": -0.000002672923265,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 14695,
    "lum": 0.00466015111702183,
    "var": "EK",
    "var_min": 11.934,
    "var_max": 11.534,
    "ra": 3.170964,
    "dec": 5.908771,
    "pmra": -128.26,
    "pmdec": -551.33,
    "rv": 0,
    "Asset Name": "ASTRID 001286",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Prince",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.0000109,
    "y_1": -0.00000366,
    "z_1": -0.00004497,
    "Distance in Parsecs_1": 16.9147,
    "HYG Star ID_1": 14695,
    "Hipparcos Catalogue_1": 14731,
    "Gliese Catalog_1": "NN 3205"
  },
  {
    "Astrid #": "ASTRID 001287",
    "HYG Star ID": 68843,
    "Hipparcos Catalogue": 69064,
    "Gliese Catalog": "Gl 540",
    "Distance in Parsecs": 16.9205,
    "mag": 10.31,
    "Absolute Visual Magnitude": 9.168,
    "Spectral Type": "M2",
    "Color Index": 1.379,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.342929,
    "y": -1.467099,
    "z": 16.693229,
    "vx": -0.00002925,
    "vy": -0.0000385,
    "vz": 1.8e-7,
    "rarad": 3.70099574963749,
    "decrad": 1.40668783426149,
    "pmrarad": 0.00000101127285627083,
    "pmdecrad": -0.000002635398686,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 68843,
    "lum": 0.0187413123997675,
    "var_min": 10.376,
    "var_max": 10.246,
    "ra": 14.136762,
    "dec": 80.597276,
    "pmra": 208.59,
    "pmdec": -543.59,
    "rv": 7.4,
    "Asset Name": "ASTRID 001287",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Smoulder",
    "Hair": "Spiky",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00002925,
    "y_1": -0.0000385,
    "z_1": 1.8e-7,
    "Distance in Parsecs_1": 16.9205,
    "HYG Star ID_1": 68843,
    "Hipparcos Catalogue_1": 69064,
    "Gliese Catalog_1": "Gl 540"
  },
  {
    "Astrid #": "ASTRID 001288",
    "HYG Star ID": 118291,
    "Gliese Catalog": "Gl 168.2",
    "Distance in Parsecs": 16.9205,
    "mag": 15.77,
    "Absolute Visual Magnitude": 14.628,
    "Spectral Type": "m",
    "Color Index": 1,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.6007,
    "y": 14.521491,
    "z": 5.6445,
    "vx": -0.00000839,
    "vy": 0.00001095,
    "vz": -0.00001836,
    "rarad": 1.14416540132696,
    "decrad": 0.3401085597686,
    "pmrarad": 7.19366539208333e-7,
    "pmdecrad": -0.000001151204628,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118291,
    "lum": 0.000122687410463833,
    "ra": 4.37039,
    "dec": 19.486785,
    "pmra": 148.38,
    "pmdec": -237.45,
    "rv": 0,
    "Asset Name": "ASTRID 001288",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "High Fade",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000839,
    "y_1": 0.00001095,
    "z_1": -0.00001836,
    "Distance in Parsecs_1": 16.9205,
    "HYG Star ID_1": 118291,
    "Gliese Catalog_1": "Gl 168.2"
  },
  {
    "Astrid #": "ASTRID 001289",
    "HYG Star ID": 99109,
    "Hipparcos Catalogue": 99427,
    "Henry Draper Catalogue": 193202,
    "Gliese Catalog": "Gl 786",
    "Distance in Parsecs": 16.9262,
    "mag": 8.88,
    "Absolute Visual Magnitude": 7.737,
    "Spectral Type": "K5",
    "Color Index": 1.32,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.02083,
    "y": -3.145545,
    "z": 16.508111,
    "vx": -0.00001836,
    "vy": 0.0000372,
    "vz": 0.00000818,
    "rarad": 5.2834242709482,
    "decrad": 1.34807434707709,
    "pmrarad": 2.75422651923611e-7,
    "pmdecrad": 0.000002482294525,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 99109,
    "lum": 0.0700164359372974,
    "ra": 20.181194,
    "dec": 77.238971,
    "pmra": 56.81,
    "pmdec": 512.01,
    "rv": -1.1,
    "Asset Name": "ASTRID 001289",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "High Bun",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001836,
    "y_1": 0.0000372,
    "z_1": 0.00000818,
    "Distance in Parsecs_1": 16.9262,
    "HYG Star ID_1": 99109,
    "Hipparcos Catalogue_1": 99427,
    "Henry Draper Catalogue_1": 193202,
    "Gliese Catalog_1": "Gl 786"
  },
  {
    "Astrid #": "ASTRID 001290",
    "HYG Star ID": 78886,
    "Hipparcos Catalogue": 79126,
    "Gliese Catalog": "NN 3942",
    "Distance in Parsecs": 16.9291,
    "mag": 10.24,
    "Absolute Visual Magnitude": 9.097,
    "Spectral Type": "M0",
    "Color Index": 1.585,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.74787,
    "y": -9.02922,
    "z": 13.510193,
    "vx": 0.00001667,
    "vy": -0.00000427,
    "vz": 0.00000301,
    "rarad": 4.22828279749998,
    "decrad": 0.924044918784565,
    "pmrarad": 9.88971426965277e-7,
    "pmdecrad": 2.94718236e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 78886,
    "lum": 0.0200078305215239,
    "ra": 16.150851,
    "dec": 52.943874,
    "pmra": 203.99,
    "pmdec": 60.79,
    "rv": 0,
    "Asset Name": "ASTRID 001290",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Curly",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001667,
    "y_1": -0.00000427,
    "z_1": 0.00000301,
    "Distance in Parsecs_1": 16.9291,
    "HYG Star ID_1": 78886,
    "Hipparcos Catalogue_1": 79126,
    "Gliese Catalog_1": "NN 3942"
  },
  {
    "Astrid #": "ASTRID 001291",
    "HYG Star ID": 92155,
    "Hipparcos Catalogue": 92444,
    "Distance in Parsecs": 16.9291,
    "mag": 9.65,
    "Absolute Visual Magnitude": 8.507,
    "Spectral Type": "Kp",
    "Color Index": 1.459,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.289652,
    "y": -14.731332,
    "z": -7.66554,
    "vx": -0.0000114,
    "vy": -0.00000167,
    "vz": -0.00000168,
    "rarad": 4.93209488345662,
    "decrad": -0.469906060566466,
    "pmrarad": -6.78690671409722e-7,
    "pmdecrad": -1.11264739e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 92155,
    "lum": 0.0344508546286579,
    "ra": 18.839215,
    "dec": -26.923634,
    "pmra": -139.99,
    "pmdec": -22.95,
    "rv": 0,
    "Asset Name": "ASTRID 001291",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Warm Smile",
    "Hair": "Prince",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.0000114,
    "y_1": -0.00000167,
    "z_1": -0.00000168,
    "Distance in Parsecs_1": 16.9291,
    "HYG Star ID_1": 92155,
    "Hipparcos Catalogue_1": 92444
  },
  {
    "Astrid #": "ASTRID 001292",
    "HYG Star ID": 62328,
    "Hipparcos Catalogue": 62523,
    "Henry Draper Catalogue": 111395,
    "Harvard Revised Catalogue": 4864,
    "Gliese Catalog": "Gl 486.1",
    "Distance in Parsecs": 16.9319,
    "mag": 6.29,
    "Absolute Visual Magnitude": 5.146,
    "Spectral Type": "G7V",
    "Color Index": 0.703,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.018549,
    "y": -3.246037,
    "z": 7.112877,
    "vx": -0.00000148,
    "vy": 0.00002777,
    "vz": -0.00001164,
    "rarad": 3.35445506098852,
    "decrad": 0.433543727807846,
    "pmrarad": -0.00000162194416829861,
    "pmdecrad": -5.14193389e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 62328,
    "lum": 0.761377432456151,
    "var": "LW",
    "var_min": 6.323,
    "var_max": 6.283,
    "ra": 12.813075,
    "dec": 24.840226,
    "pmra": -334.55,
    "pmdec": -106.06,
    "rv": -8.7,
    "Asset Name": "ASTRID 001292",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Afro",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00000148,
    "y_1": 0.00002777,
    "z_1": -0.00001164,
    "Distance in Parsecs_1": 16.9319,
    "HYG Star ID_1": 62328,
    "Hipparcos Catalogue_1": 62523,
    "Henry Draper Catalogue_1": 111395,
    "Harvard Revised Catalogue_1": 4864,
    "Gliese Catalog_1": "Gl 486.1"
  },
  {
    "Astrid #": "ASTRID 001293",
    "HYG Star ID": 115720,
    "Hipparcos Catalogue": 116085,
    "Henry Draper Catalogue": 221354,
    "Gliese Catalog": "Gl 895.4",
    "Distance in Parsecs": 16.9319,
    "mag": 6.76,
    "Absolute Visual Magnitude": 5.616,
    "Spectral Type": "K2V",
    "Color Index": 0.839,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.610871,
    "y": -1.081312,
    "z": 14.538416,
    "vx": -0.00000922,
    "vy": 0.00009266,
    "vz": -0.00001659,
    "rarad": 6.15821937208605,
    "decrad": 1.03263307332262,
    "pmrarad": 0.00000536252412062499,
    "pmdecrad": 5.47694014e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 115720,
    "lum": 0.493855619617607,
    "ra": 23.522665,
    "dec": 59.165517,
    "pmra": 1106.1,
    "pmdec": 112.97,
    "rv": -24.3,
    "Asset Name": "ASTRID 001293",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Charmer",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000922,
    "y_1": 0.00009266,
    "z_1": -0.00001659,
    "Distance in Parsecs_1": 16.9319,
    "HYG Star ID_1": 115720,
    "Hipparcos Catalogue_1": 116085,
    "Henry Draper Catalogue_1": 221354,
    "Gliese Catalog_1": "Gl 895.4"
  },
  {
    "Astrid #": "ASTRID 001294",
    "HYG Star ID": 19028,
    "Hipparcos Catalogue": 19076,
    "Henry Draper Catalogue": 25680,
    "Harvard Revised Catalogue": 1262,
    "Gliese Catalog": "Gl 160",
    "Distance in Parsecs": 16.9377,
    "mag": 5.9,
    "Absolute Visual Magnitude": 4.756,
    "Spectral Type": "G5V",
    "Color Index": 0.62,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.532952,
    "y": 13.778856,
    "z": 6.347496,
    "vx": 3.6e-7,
    "vy": 0.00003019,
    "vz": -7.4e-7,
    "rarad": 1.07048680132282,
    "decrad": 0.384127861850693,
    "pmrarad": 8.36836894006944e-7,
    "pmdecrad": -6.30645635e-7,
    "flam": 39,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 19028,
    "lum": 1.09043554554365,
    "bf": "39    Tau",
    "ra": 4.088958,
    "dec": 22.008905,
    "pmra": 172.61,
    "pmdec": -130.08,
    "rv": 23.9,
    "Asset Name": "ASTRID 001294",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Prince",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 3.6e-7,
    "y_1": 0.00003019,
    "z_1": -7.4e-7,
    "Distance in Parsecs_1": 16.9377,
    "HYG Star ID_1": 19028,
    "Hipparcos Catalogue_1": 19076,
    "Henry Draper Catalogue_1": 25680,
    "Harvard Revised Catalogue_1": 1262,
    "Gliese Catalog_1": "Gl 160"
  },
  {
    "Astrid #": "ASTRID 001295",
    "HYG Star ID": 118732,
    "Gliese Catalog": "Gl 414.1B",
    "Distance in Parsecs": 16.9463,
    "mag": 11.82,
    "Absolute Visual Magnitude": 10.675,
    "Spectral Type": "M3",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.031945,
    "y": 2.594136,
    "z": 11.646756,
    "vx": 0.0000019,
    "vy": 0.00005309,
    "vz": -0.00003812,
    "rarad": 2.929297263837,
    "decrad": 0.75778420519225,
    "pmrarad": -0.00000308666325999305,
    "pmdecrad": -0.000002012156155,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 54498,
    "base": "Gl 414.1",
    "lum": 0.00467735141287197,
    "ra": 11.189091,
    "dec": 43.417837,
    "pmra": -636.67,
    "pmdec": -415.04,
    "rv": -19,
    "Asset Name": "ASTRID 001295",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Good Boy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.0000019,
    "y_1": 0.00005309,
    "z_1": -0.00003812,
    "Distance in Parsecs_1": 16.9463,
    "HYG Star ID_1": 118732,
    "Gliese Catalog_1": "Gl 414.1B"
  },
  {
    "Astrid #": "ASTRID 001296",
    "HYG Star ID": 118162,
    "Gliese Catalog": "NN 3173",
    "Distance in Parsecs": 16.9492,
    "mag": 15.09,
    "Absolute Visual Magnitude": 13.944,
    "Spectral Type": "DA8",
    "Color Index": 0.44,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.968689,
    "y": 10.736175,
    "z": 1.955264,
    "vx": 0.00002822,
    "vy": -0.00002832,
    "vz": -0.00003157,
    "rarad": 0.691496353189611,
    "decrad": 0.11561760682215,
    "pmrarad": -0.00000234911620776388,
    "pmdecrad": -0.000001875283557,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118162,
    "lum": 0.000230356250011753,
    "ra": 2.641322,
    "dec": 6.624401,
    "pmra": -484.54,
    "pmdec": -386.81,
    "rv": 0,
    "Asset Name": "ASTRID 001296",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Ponytail",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002822,
    "y_1": -0.00002832,
    "z_1": -0.00003157,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118162,
    "Gliese Catalog_1": "NN 3173"
  },
  {
    "Astrid #": "ASTRID 001297",
    "HYG Star ID": 118241,
    "Gliese Catalog": "NN 3241",
    "Distance in Parsecs": 16.9492,
    "mag": 12.79,
    "Absolute Visual Magnitude": 11.644,
    "Spectral Type": "dM3.1",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.841322,
    "y": 12.566426,
    "z": 7.154813,
    "vx": -0.00001111,
    "vy": -8.8e-7,
    "vz": 0.00001526,
    "rarad": 0.957677882122261,
    "decrad": 0.43579671567615,
    "pmrarad": 5.06242445236111e-7,
    "pmdecrad": 9.93533523e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118241,
    "lum": 0.00191601983206845,
    "ra": 3.65806,
    "dec": 24.969313,
    "pmra": 104.42,
    "pmdec": 204.93,
    "rv": 0,
    "Asset Name": "ASTRID 001297",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Curly",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001111,
    "y_1": -8.8e-7,
    "z_1": 0.00001526,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118241,
    "Gliese Catalog_1": "NN 3241"
  },
  {
    "Astrid #": "ASTRID 001298",
    "HYG Star ID": 118349,
    "Gliese Catalog": "NN 3328",
    "Distance in Parsecs": 16.9492,
    "mag": 11.54,
    "Absolute Visual Magnitude": 10.394,
    "Spectral Type": "g",
    "Color Index": 1.51,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.940965,
    "y": 16.434707,
    "z": 1.28242,
    "vx": -0.00002881,
    "vy": 0.00000634,
    "vz": 0.00000733,
    "rarad": 1.33544475647972,
    "decrad": 0.07573498607865,
    "pmrarad": 0.0000017405295945625,
    "pmdecrad": 4.33961573e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118349,
    "lum": 0.00605898671138964,
    "ra": 5.101023,
    "dec": 4.339295,
    "pmra": 359.01,
    "pmdec": 89.51,
    "rv": 0,
    "Asset Name": "ASTRID 001298",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Fire",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00002881,
    "y_1": 0.00000634,
    "z_1": 0.00000733,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118349,
    "Gliese Catalog_1": "NN 3328"
  },
  {
    "Astrid #": "ASTRID 001299",
    "HYG Star ID": 118525,
    "Gliese Catalog": "NN 3482B",
    "Distance in Parsecs": 16.9492,
    "mag": 11,
    "Absolute Visual Magnitude": 9.854,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.391559,
    "y": 13.401983,
    "z": 6.102784,
    "vx": 0.0000194,
    "vy": 0.00002354,
    "vz": -0.000025,
    "rarad": 2.13022333773492,
    "decrad": 0.3683356606148,
    "pmrarad": -0.00000170746530154861,
    "pmdecrad": -0.000001581127704,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 2,
    "comp_primary": 118525,
    "base": "NN 3482",
    "lum": 0.00996322641954418,
    "ra": 8.136854,
    "dec": 21.104079,
    "pmra": -352.19,
    "pmdec": -326.13,
    "rv": 0,
    "Asset Name": "ASTRID 001299",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Gimme Blood",
    "Hair": "Bandana",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.0000194,
    "y_1": 0.00002354,
    "z_1": -0.000025,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118525,
    "Gliese Catalog_1": "NN 3482B"
  },
  {
    "Astrid #": "ASTRID 001300",
    "HYG Star ID": 118612,
    "Gliese Catalog": "NN 3545",
    "Distance in Parsecs": 16.9492,
    "mag": 12.7,
    "Absolute Visual Magnitude": 11.554,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.001956,
    "y": 5.195452,
    "z": -14.975287,
    "vx": -1e-8,
    "vy": 0.00007142,
    "vz": 0.00002476,
    "rarad": 2.42827713055981,
    "decrad": -1.083366240141,
    "pmrarad": -0.00000318672880365972,
    "pmdecrad": 0.00000312069718,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118612,
    "lum": 0.00208161304091763,
    "ra": 9.275335,
    "dec": -62.072313,
    "pmra": -657.31,
    "pmdec": 643.69,
    "rv": 0,
    "Asset Name": "ASTRID 001300",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Goddess",
    "Outfit": "Viking",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -1e-8,
    "y_1": 0.00007142,
    "z_1": 0.00002476,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118612,
    "Gliese Catalog_1": "NN 3545"
  },
  {
    "Astrid #": "ASTRID 001301",
    "HYG Star ID": 118681,
    "Gliese Catalog": "NN 3599",
    "Distance in Parsecs": 16.9492,
    "mag": 13.19,
    "Absolute Visual Magnitude": 12.044,
    "Spectral Type": "k-m",
    "Color Index": 1.57,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.910566,
    "y": 6.084125,
    "z": 7.533589,
    "vx": -0.00000563,
    "vy": 0.00002793,
    "vz": -0.00003293,
    "rarad": 2.72929569188142,
    "decrad": 0.46059424363585,
    "pmrarad": -0.00000137682237140972,
    "pmdecrad": -0.000002169507283,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118681,
    "lum": 0.00132556186071985,
    "ra": 10.425142,
    "dec": 26.390106,
    "pmra": -283.99,
    "pmdec": -447.49,
    "rv": 0,
    "Asset Name": "ASTRID 001301",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Pixie",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000563,
    "y_1": 0.00002793,
    "z_1": -0.00003293,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118681,
    "Gliese Catalog_1": "NN 3599"
  },
  {
    "Astrid #": "ASTRID 001302",
    "HYG Star ID": 118762,
    "Gliese Catalog": "NN 3673",
    "Distance in Parsecs": 16.9492,
    "mag": 13.11,
    "Absolute Visual Magnitude": 11.964,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.110795,
    "y": 1.404786,
    "z": 10.649367,
    "vx": -0.0000051,
    "vy": 0.00005565,
    "vz": -0.0000136,
    "rarad": 3.03489866757449,
    "decrad": 0.67938006724905,
    "pmrarad": -0.00000323302851015277,
    "pmdecrad": -0.000001031795019,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118762,
    "lum": 0.00142692123157936,
    "ra": 11.592459,
    "dec": 38.925611,
    "pmra": -666.86,
    "pmdec": -212.82,
    "rv": 0,
    "Asset Name": "ASTRID 001302",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Firm",
    "Hair": "Spiky",
    "Outfit": "River Spirit",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000051,
    "y_1": 0.00005565,
    "z_1": -0.0000136,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118762,
    "Gliese Catalog_1": "NN 3673"
  },
  {
    "Astrid #": "ASTRID 001303",
    "HYG Star ID": 118772,
    "Gliese Catalog": "NN 3684",
    "Distance in Parsecs": 16.9492,
    "mag": 13.6,
    "Absolute Visual Magnitude": 12.454,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.778534,
    "y": 0.325652,
    "z": 15.930408,
    "vx": -0.00000174,
    "vy": 0.0000295,
    "vz": -0.00000123,
    "rarad": 3.08546371918783,
    "decrad": 1.22231216412715,
    "pmrarad": -0.00000173233624336111,
    "pmdecrad": -2.12697457e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118772,
    "lum": 0.000908657048958144,
    "ra": 11.785603,
    "dec": 70.033328,
    "pmra": -357.32,
    "pmdec": -43.87,
    "rv": 0,
    "Asset Name": "ASTRID 001303",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Curly",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000174,
    "y_1": 0.0000295,
    "z_1": -0.00000123,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118772,
    "Gliese Catalog_1": "NN 3684"
  },
  {
    "Astrid #": "ASTRID 001304",
    "HYG Star ID": 118962,
    "Gliese Catalog": "NN 3843",
    "Distance in Parsecs": 16.9492,
    "mag": 13.15,
    "Absolute Visual Magnitude": 12.004,
    "Spectral Type": "M3",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.828217,
    "y": -9.795183,
    "z": -0.331952,
    "vx": 0.00000909,
    "vy": -0.00001119,
    "vz": -0.00004886,
    "rarad": 3.75789898931739,
    "decrad": -0.019586385286,
    "pmrarad": 8.486663478125e-7,
    "pmdecrad": -0.00000288354572,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118962,
    "lum": 0.00137530809742145,
    "ra": 14.354117,
    "dec": -1.122217,
    "pmra": 175.05,
    "pmdec": -594.77,
    "rv": 0,
    "Asset Name": "ASTRID 001304",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Halo Boy",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000909,
    "y_1": -0.00001119,
    "z_1": -0.00004886,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 118962,
    "Gliese Catalog_1": "NN 3843"
  },
  {
    "Astrid #": "ASTRID 001305",
    "HYG Star ID": 119332,
    "Gliese Catalog": "Gl 781.3",
    "Distance in Parsecs": 16.9492,
    "mag": 14.44,
    "Absolute Visual Magnitude": 13.294,
    "Spectral Type": "DA6",
    "Color Index": 0.19,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.474862,
    "y": -13.264712,
    "z": -6.289689,
    "vx": 0.00002122,
    "vy": -0.00001549,
    "vz": -0.00003521,
    "rarad": 5.28092129470636,
    "decrad": -0.38014107402845,
    "pmrarad": 5.63014127229166e-7,
    "pmdecrad": -0.000001393480569,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119332,
    "lum": 0.000419179465932563,
    "ra": 20.171633,
    "dec": -21.780479,
    "pmra": 116.13,
    "pmdec": -287.43,
    "rv": 35,
    "Asset Name": "ASTRID 001305",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Goddess",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002122,
    "y_1": -0.00001549,
    "z_1": -0.00003521,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119332,
    "Gliese Catalog_1": "Gl 781.3"
  },
  {
    "Astrid #": "ASTRID 001306",
    "HYG Star ID": 119341,
    "Gliese Catalog": "GJ 1251",
    "Distance in Parsecs": 16.9492,
    "mag": 13.82,
    "Absolute Visual Magnitude": 12.674,
    "Spectral Type": "M4.5",
    "Color Index": 1.74,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.351507,
    "y": -3.116366,
    "z": -16.499867,
    "vx": -0.00000119,
    "vy": 0.00009052,
    "vz": -0.00014553,
    "rarad": 5.35827874308796,
    "decrad": -1.33846052787729,
    "pmrarad": 0.00000315822175924305,
    "pmdecrad": -0.000006171697546,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119341,
    "lum": 0.000741993327789943,
    "ra": 20.467117,
    "dec": -76.688139,
    "pmra": 651.43,
    "pmdec": -1273,
    "rv": 122,
    "Asset Name": "ASTRID 001306",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Curly",
    "Outfit": "Tats",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000119,
    "y_1": 0.00009052,
    "z_1": -0.00014553,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119341,
    "Gliese Catalog_1": "GJ 1251"
  },
  {
    "Astrid #": "ASTRID 001307",
    "HYG Star ID": 119384,
    "Gliese Catalog": "NN 4176A",
    "Distance in Parsecs": 16.9492,
    "mag": 12.29,
    "Absolute Visual Magnitude": 11.144,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.079882,
    "y": -9.967484,
    "z": 9.290889,
    "vx": 0.00002299,
    "vy": 0.00001449,
    "vz": -0.0000094,
    "rarad": 5.5033783816358,
    "decrad": 0.5801637466526,
    "pmrarad": 0.00000156197271601388,
    "pmdecrad": -6.63006948e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119384,
    "base": "NN 4176",
    "lum": 0.00303668678846578,
    "ra": 21.021357,
    "dec": 33.240934,
    "pmra": 322.18,
    "pmdec": -136.75,
    "rv": 0,
    "Asset Name": "ASTRID 001307",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Windy",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002299,
    "y_1": 0.00001449,
    "z_1": -0.0000094,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119384,
    "Gliese Catalog_1": "NN 4176A"
  },
  {
    "Astrid #": "ASTRID 001308",
    "HYG Star ID": 119385,
    "Gliese Catalog": "NN 4177B",
    "Distance in Parsecs": 16.9492,
    "mag": 13.12,
    "Absolute Visual Magnitude": 11.974,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.082975,
    "y": -9.964728,
    "z": 9.29049,
    "vx": 0.00002299,
    "vy": 0.0000145,
    "vz": -0.0000094,
    "rarad": 5.5036700596358,
    "decrad": 0.580135660652599,
    "pmrarad": 0.00000156197271601388,
    "pmdecrad": -6.63006948e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119385,
    "base": "NN 4177",
    "lum": 0.00141383913909456,
    "ra": 21.022471,
    "dec": 33.239325,
    "pmra": 322.18,
    "pmdec": -136.75,
    "rv": 0,
    "Asset Name": "ASTRID 001308",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Pompadour",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002299,
    "y_1": 0.0000145,
    "z_1": -0.0000094,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119385,
    "Gliese Catalog_1": "NN 4177B"
  },
  {
    "Astrid #": "ASTRID 001309",
    "HYG Star ID": 119400,
    "Gliese Catalog": "NN 4184",
    "Distance in Parsecs": 16.9492,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.984,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.482895,
    "y": -10.051086,
    "z": 7.37524,
    "vx": 0.00001613,
    "vy": 0.00000431,
    "vz": -0.00001923,
    "rarad": 5.56417564097091,
    "decrad": 0.45019149637285,
    "pmrarad": 8.18559418249999e-7,
    "pmdecrad": -0.000001260452543,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119400,
    "lum": 0.00351884388820958,
    "ra": 21.253585,
    "dec": 25.794073,
    "pmra": 168.84,
    "pmdec": -259.99,
    "rv": 0,
    "Asset Name": "ASTRID 001309",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Awkward",
    "Hair": "Slick",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001613,
    "y_1": 0.00000431,
    "z_1": -0.00001923,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119400,
    "Gliese Catalog_1": "NN 4184"
  },
  {
    "Astrid #": "ASTRID 001310",
    "HYG Star ID": 119439,
    "Gliese Catalog": "NN 4219",
    "Distance in Parsecs": 16.9492,
    "mag": 13.36,
    "Absolute Visual Magnitude": 12.214,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.730952,
    "y": -6.383863,
    "z": 12.321942,
    "vx": 0.00001284,
    "vy": 0.00002007,
    "vz": 2.6e-7,
    "rarad": 5.7025458642874,
    "decrad": 0.81393179816315,
    "pmrarad": 0.00000140576574813888,
    "pmdecrad": 2.2083263e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119439,
    "lum": 0.00113344382297103,
    "ra": 21.782121,
    "dec": 46.634857,
    "pmra": 289.96,
    "pmdec": 4.55,
    "rv": 0,
    "Asset Name": "ASTRID 001310",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Skater Helmet",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001284,
    "y_1": 0.00002007,
    "z_1": 2.6e-7,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119439,
    "Gliese Catalog_1": "NN 4219"
  },
  {
    "Astrid #": "ASTRID 001311",
    "HYG Star ID": 119508,
    "Gliese Catalog": "NN 4282",
    "Distance in Parsecs": 16.9492,
    "mag": 12.41,
    "Absolute Visual Magnitude": 11.264,
    "Spectral Type": "M3",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.531802,
    "y": -6.166248,
    "z": -2.831234,
    "vx": 0.00000828,
    "vy": 0.00002556,
    "vz": -0.00001025,
    "rarad": 5.90525988077605,
    "decrad": -0.16782911494895,
    "pmrarad": 0.00000158199552102083,
    "pmdecrad": -6.13618979e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119508,
    "lum": 0.00271894235440221,
    "ra": 22.556431,
    "dec": -9.6159,
    "pmra": 326.31,
    "pmdec": -126.57,
    "rv": 0,
    "Asset Name": "ASTRID 001311",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Heartbreaker",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000828,
    "y_1": 0.00002556,
    "z_1": -0.00001025,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119508,
    "Gliese Catalog_1": "NN 4282"
  },
  {
    "Astrid #": "ASTRID 001312",
    "HYG Star ID": 119611,
    "Gliese Catalog": "NN 4381",
    "Distance in Parsecs": 16.9492,
    "mag": 12.64,
    "Absolute Visual Magnitude": 11.494,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.240128,
    "y": -0.125297,
    "z": 10.581054,
    "vx": 0.00000822,
    "vy": -0.00001344,
    "vz": -0.00001044,
    "rarad": 6.27373316537556,
    "decrad": 0.67421005940595,
    "pmrarad": -7.884524886875e-7,
    "pmdecrad": -7.88471881e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119611,
    "lum": 0.00219988510904924,
    "ra": 23.963895,
    "dec": 38.629391,
    "pmra": -162.63,
    "pmdec": -162.63,
    "rv": 0,
    "Asset Name": "ASTRID 001312",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Braided Ponytail",
    "Outfit": "River Spirit",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000822,
    "y_1": -0.00001344,
    "z_1": -0.00001044,
    "Distance in Parsecs_1": 16.9492,
    "HYG Star ID_1": 119611,
    "Gliese Catalog_1": "NN 4381"
  },
  {
    "Astrid #": "ASTRID 001313",
    "HYG Star ID": 89282,
    "Hipparcos Catalogue": 89560,
    "Gliese Catalog": "Gl 709",
    "Distance in Parsecs": 16.9549,
    "mag": 10.31,
    "Absolute Visual Magnitude": 9.164,
    "Spectral Type": "M0",
    "Color Index": 1.428,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.854877,
    "y": -11.840578,
    "z": 12.104848,
    "vx": -0.00000433,
    "vy": 0.000046,
    "vz": -0.00000741,
    "rarad": 4.78446306021927,
    "decrad": 0.795134573030931,
    "pmrarad": -5.96320827083333e-8,
    "pmdecrad": 0.000001639203535,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 89282,
    "lum": 0.018810485289187,
    "ra": 18.275303,
    "dec": 45.557855,
    "pmra": -12.3,
    "pmdec": 338.11,
    "rv": -36.8,
    "Asset Name": "ASTRID 001313",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Wavy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000433,
    "y_1": 0.000046,
    "z_1": -0.00000741,
    "Distance in Parsecs_1": 16.9549,
    "HYG Star ID_1": 89282,
    "Hipparcos Catalogue_1": 89560,
    "Gliese Catalog_1": "Gl 709"
  },
  {
    "Astrid #": "ASTRID 001314",
    "HYG Star ID": 93563,
    "Hipparcos Catalogue": 93858,
    "Henry Draper Catalogue": 177565,
    "Harvard Revised Catalogue": 7232,
    "Gliese Catalog": "Gl 744",
    "Distance in Parsecs": 16.9549,
    "mag": 6.15,
    "Absolute Visual Magnitude": 5.004,
    "Spectral Type": "G8V",
    "Color Index": 0.705,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.853497,
    "y": -12.829241,
    "z": -10.39459,
    "vx": -0.00000649,
    "vy": -0.00003201,
    "vz": -0.00006049,
    "rarad": 5.00418575823816,
    "decrad": -0.659920480822439,
    "pmrarad": -9.09801352930555e-7,
    "pmdecrad": -0.000001778151136,
    "con": "CrA",
    "Full Constellation Name Formula 1": "Corona Australis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Australis",
    "comp": 1,
    "comp_primary": 93563,
    "lum": 0.867760744991146,
    "ra": 19.114582,
    "dec": -37.810658,
    "pmra": -187.66,
    "pmdec": -366.77,
    "rv": 58.5,
    "Asset Name": "ASTRID 001314",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Serious Mad",
    "Hair": "Spiky",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Australis",
    "x_1": -0.00000649,
    "y_1": -0.00003201,
    "z_1": -0.00006049,
    "Distance in Parsecs_1": 16.9549,
    "HYG Star ID_1": 93563,
    "Hipparcos Catalogue_1": 93858,
    "Henry Draper Catalogue_1": 177565,
    "Harvard Revised Catalogue_1": 7232,
    "Gliese Catalog_1": "Gl 744"
  },
  {
    "Astrid #": "ASTRID 001315",
    "HYG Star ID": 95696,
    "Hipparcos Catalogue": 95995,
    "Henry Draper Catalogue": 184467,
    "Gliese Catalog": "Gl 762.1",
    "Distance in Parsecs": 16.9607,
    "mag": 6.6,
    "Absolute Visual Magnitude": 5.453,
    "Spectral Type": "K1V",
    "Color Index": 0.859,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.423358,
    "y": -8.150527,
    "z": 14.474748,
    "vx": -0.00002551,
    "vy": -0.00004756,
    "vz": -0.00000709,
    "rarad": 5.11005110849265,
    "decrad": 1.02251877587733,
    "pmrarad": -0.00000247274369630555,
    "pmdecrad": -0.000001927328305,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 95696,
    "lum": 0.573852132674618,
    "ra": 19.518957,
    "dec": 58.58601,
    "pmra": -510.04,
    "pmdec": -397.54,
    "rv": 11.4,
    "Asset Name": "ASTRID 001315",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Bangs",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00002551,
    "y_1": -0.00004756,
    "z_1": -0.00000709,
    "Distance in Parsecs_1": 16.9607,
    "HYG Star ID_1": 95696,
    "Hipparcos Catalogue_1": 95995,
    "Henry Draper Catalogue_1": 184467,
    "Gliese Catalog_1": "Gl 762.1"
  },
  {
    "Astrid #": "ASTRID 001316",
    "HYG Star ID": 63347,
    "Hipparcos Catalogue": 63550,
    "Gliese Catalog": "NN 3759",
    "Distance in Parsecs": 16.9664,
    "mag": 10.92,
    "Absolute Visual Magnitude": 9.772,
    "Color Index": 1.49,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.378779,
    "y": -2.023016,
    "z": -15.143307,
    "vx": 0.00002054,
    "vy": 0.00003906,
    "vz": -0.00001523,
    "rarad": 3.40920427646654,
    "decrad": -1.1029616863256,
    "pmrarad": -0.00000190051810914583,
    "pmdecrad": -0.00000199020864,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 63347,
    "lum": 0.0107448411664455,
    "var_min": 11.022,
    "var_max": 10.822,
    "ra": 13.022201,
    "dec": -63.19505,
    "pmra": -392.01,
    "pmdec": -410.51,
    "rv": 0,
    "Asset Name": "ASTRID 001316",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Medium Bob",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00002054,
    "y_1": 0.00003906,
    "z_1": -0.00001523,
    "Distance in Parsecs_1": 16.9664,
    "HYG Star ID_1": 63347,
    "Hipparcos Catalogue_1": 63550,
    "Gliese Catalog_1": "NN 3759"
  },
  {
    "Astrid #": "ASTRID 001317",
    "HYG Star ID": 68463,
    "Hipparcos Catalogue": 68682,
    "Henry Draper Catalogue": 122742,
    "Harvard Revised Catalogue": 5273,
    "Gliese Catalog": "Gl 538",
    "Distance in Parsecs": 16.9837,
    "mag": 6.27,
    "Absolute Visual Magnitude": 5.12,
    "Spectral Type": "G8V",
    "Color Index": 0.733,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.317802,
    "y": -8.563868,
    "z": 3.178559,
    "vx": 0.00000958,
    "vy": -0.00000245,
    "vz": -0.00002681,
    "rarad": 3.68063399283126,
    "decrad": 0.188264808259252,
    "pmrarad": 4.13352144041666e-7,
    "pmdecrad": -0.000001474027514,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 68463,
    "lum": 0.779830110523258,
    "ra": 14.058986,
    "dec": 10.786779,
    "pmra": 85.26,
    "pmdec": -304.04,
    "rv": -11.6,
    "Asset Name": "ASTRID 001317",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Oh Snap",
    "Hair": "Middle Part",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000958,
    "y_1": -0.00000245,
    "z_1": -0.00002681,
    "Distance in Parsecs_1": 16.9837,
    "HYG Star ID_1": 68463,
    "Hipparcos Catalogue_1": 68682,
    "Henry Draper Catalogue_1": 122742,
    "Harvard Revised Catalogue_1": 5273,
    "Gliese Catalog_1": "Gl 538"
  },
  {
    "Astrid #": "ASTRID 001318",
    "HYG Star ID": 70732,
    "Hipparcos Catalogue": 70956,
    "Henry Draper Catalogue": 127339,
    "Gliese Catalog": "Gl 553",
    "Distance in Parsecs": 16.9866,
    "mag": 9.4,
    "Absolute Visual Magnitude": 8.249,
    "Spectral Type": "K7V",
    "Color Index": 1.403,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.287475,
    "y": -10.269291,
    "z": -2.553644,
    "vx": -0.00004012,
    "vy": 0.0001011,
    "vz": -0.00001552,
    "rarad": 3.7995618648051,
    "decrad": -0.150907044568515,
    "pmrarad": -0.00000615407941686805,
    "pmdecrad": -0.000001169322116,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 70732,
    "lum": 0.0436918063385287,
    "ra": 14.513257,
    "dec": -8.646337,
    "pmra": -1269.37,
    "pmdec": -241.19,
    "rv": -26.8,
    "Asset Name": "ASTRID 001318",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Mohawk",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00004012,
    "y_1": 0.0001011,
    "z_1": -0.00001552,
    "Distance in Parsecs_1": 16.9866,
    "HYG Star ID_1": 70732,
    "Hipparcos Catalogue_1": 70956,
    "Henry Draper Catalogue_1": 127339,
    "Gliese Catalog_1": "Gl 553"
  },
  {
    "Astrid #": "ASTRID 001319",
    "HYG Star ID": 64811,
    "Hipparcos Catalogue": 65016,
    "Gliese Catalog": "Gl 507.1",
    "Distance in Parsecs": 16.9895,
    "mag": 10.62,
    "Absolute Visual Magnitude": 9.469,
    "Spectral Type": "M2",
    "Color Index": 1.469,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.343384,
    "y": -4.834766,
    "z": 9.339093,
    "vx": -0.00000503,
    "vy": 0.00002435,
    "vz": -0.00001654,
    "rarad": 3.48921568439474,
    "decrad": 0.582006674317112,
    "pmrarad": -0.00000144852631476388,
    "pmdecrad": -6.9779233e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 64811,
    "lum": 0.0142036512404801,
    "ra": 13.327822,
    "dec": 33.346526,
    "pmra": -298.78,
    "pmdec": -143.93,
    "rv": -11.8,
    "Asset Name": "ASTRID 001319",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Honest Lad",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000503,
    "y_1": 0.00002435,
    "z_1": -0.00001654,
    "Distance in Parsecs_1": 16.9895,
    "HYG Star ID_1": 64811,
    "Hipparcos Catalogue_1": 65016,
    "Gliese Catalog_1": "Gl 507.1"
  },
  {
    "Astrid #": "ASTRID 001320",
    "HYG Star ID": 92024,
    "Hipparcos Catalogue": 92311,
    "Henry Draper Catalogue": 229590,
    "Gliese Catalog": "Gl 728",
    "Distance in Parsecs": 17.001,
    "mag": 9.17,
    "Absolute Visual Magnitude": 8.018,
    "Spectral Type": "M0",
    "Color Index": 1.284,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.432024,
    "y": -15.852132,
    "z": 5.094962,
    "vx": -0.00003512,
    "vy": 0.00000298,
    "vz": -0.00003975,
    "rarad": 4.92560151582095,
    "decrad": 0.304367056316885,
    "pmrarad": -0.00000198230617705555,
    "pmdecrad": -0.000002048192356,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 92024,
    "lum": 0.0540505353708668,
    "ra": 18.814412,
    "dec": 17.438948,
    "pmra": -408.88,
    "pmdec": -422.47,
    "rv": -21.3,
    "Asset Name": "ASTRID 001320",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Star of Culture",
    "Hair": "Curly",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00003512,
    "y_1": 0.00000298,
    "z_1": -0.00003975,
    "Distance in Parsecs_1": 17.001,
    "HYG Star ID_1": 92024,
    "Hipparcos Catalogue_1": 92311,
    "Henry Draper Catalogue_1": 229590,
    "Gliese Catalog_1": "Gl 728"
  },
  {
    "Astrid #": "ASTRID 001321",
    "HYG Star ID": 43822,
    "Hipparcos Catalogue": 43948,
    "Gliese Catalog": "Gl 330",
    "Distance in Parsecs": 17.0097,
    "mag": 10.6,
    "Absolute Visual Magnitude": 9.447,
    "Spectral Type": "M5",
    "Color Index": 1.511,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.628812,
    "y": 11.929173,
    "z": 3.433915,
    "vx": 0.00000684,
    "vy": -0.00000286,
    "vz": -0.00002818,
    "rarad": 2.34344536834965,
    "decrad": 0.203278209483557,
    "pmrarad": -1.70702896923611e-7,
    "pmdecrad": -0.000001541659022,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 43822,
    "lum": 0.0144943919128778,
    "var_min": 10.669,
    "var_max": 10.559,
    "ra": 8.951302,
    "dec": 11.646983,
    "pmra": -35.21,
    "pmdec": -317.99,
    "rv": -12.1,
    "Asset Name": "ASTRID 001321",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Charmer",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00000684,
    "y_1": -0.00000286,
    "z_1": -0.00002818,
    "Distance in Parsecs_1": 17.0097,
    "HYG Star ID_1": 43822,
    "Hipparcos Catalogue_1": 43948,
    "Gliese Catalog_1": "Gl 330"
  },
  {
    "Astrid #": "ASTRID 001322",
    "HYG Star ID": 72461,
    "Hipparcos Catalogue": 72688,
    "Henry Draper Catalogue": 130992,
    "Gliese Catalog": "Gl 565",
    "Distance in Parsecs": 17.0271,
    "mag": 7.81,
    "Absolute Visual Magnitude": 6.654,
    "Spectral Type": "K3V",
    "Color Index": 1.036,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.363815,
    "y": -10.566787,
    "z": -7.007774,
    "vx": 0.00000236,
    "vy": 0.00010852,
    "vz": -0.00000495,
    "rarad": 3.89066755178167,
    "decrad": -0.42418714092761,
    "pmrarad": -0.00000457305352320833,
    "pmdecrad": -0.000002093134584,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 72461,
    "lum": 0.18984536566615,
    "ra": 14.861255,
    "dec": -24.304133,
    "pmra": -943.26,
    "pmdec": -431.74,
    "rv": -65.4,
    "Asset Name": "ASTRID 001322",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Manbun",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000236,
    "y_1": 0.00010852,
    "z_1": -0.00000495,
    "Distance in Parsecs_1": 17.0271,
    "HYG Star ID_1": 72461,
    "Hipparcos Catalogue_1": 72688,
    "Henry Draper Catalogue_1": 130992,
    "Gliese Catalog_1": "Gl 565"
  },
  {
    "Astrid #": "ASTRID 001323",
    "HYG Star ID": 113357,
    "Hipparcos Catalogue": 113718,
    "Henry Draper Catalogue": 217580,
    "Gliese Catalog": "Gl 886",
    "Distance in Parsecs": 17.0358,
    "mag": 7.48,
    "Absolute Visual Magnitude": 6.323,
    "Spectral Type": "K4V",
    "Color Index": 0.943,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.45298,
    "y": -4.265825,
    "z": -1.143461,
    "vx": -0.00003549,
    "vy": 0.00004294,
    "vz": -0.00001411,
    "rarad": 6.02949761058343,
    "decrad": -0.0671730868541137,
    "pmrarad": 0.00000191743810659722,
    "pmdecrad": -0.000001004097613,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113357,
    "lum": 0.257513499041031,
    "ra": 23.030984,
    "dec": -3.848734,
    "pmra": 395.5,
    "pmdec": -207.11,
    "rv": -43.1,
    "Asset Name": "ASTRID 001323",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Blep",
    "Hair": "Heartbreaker",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00003549,
    "y_1": 0.00004294,
    "z_1": -0.00001411,
    "Distance in Parsecs_1": 17.0358,
    "HYG Star ID_1": 113357,
    "Hipparcos Catalogue_1": 113718,
    "Henry Draper Catalogue_1": 217580,
    "Gliese Catalog_1": "Gl 886"
  },
  {
    "Astrid #": "ASTRID 001324",
    "HYG Star ID": 118306,
    "Gliese Catalog": "NN 3289",
    "Distance in Parsecs": 17.0358,
    "mag": 18.32,
    "Absolute Visual Magnitude": 17.163,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.756827,
    "y": 15.601685,
    "z": 1.072948,
    "vx": 0.00001082,
    "vy": 0.00000114,
    "vz": -0.00008436,
    "rarad": 1.16209916827066,
    "decrad": 0.0630236622064499,
    "pmrarad": -5.56614586645833e-7,
    "pmdecrad": -0.000004962455871,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118306,
    "lum": 0.0000118795502794165,
    "ra": 4.438892,
    "dec": 3.61099,
    "pmra": -114.81,
    "pmdec": -1023.58,
    "rv": 0,
    "Asset Name": "ASTRID 001324",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Fedora",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001082,
    "y_1": 0.00000114,
    "z_1": -0.00008436,
    "Distance in Parsecs_1": 17.0358,
    "HYG Star ID_1": 118306,
    "Gliese Catalog_1": "NN 3289"
  },
  {
    "Astrid #": "ASTRID 001325",
    "HYG Star ID": 119148,
    "Gliese Catalog": "GJ 1216",
    "Distance in Parsecs": 17.0358,
    "mag": 14.48,
    "Absolute Visual Magnitude": 13.323,
    "Spectral Type": "m",
    "Color Index": 1.66,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.894286,
    "y": -10.956715,
    "z": 12.90661,
    "vx": 0.00003124,
    "vy": -0.00008054,
    "vz": -0.00006374,
    "rarad": 4.54112365452759,
    "decrad": 0.85965412207075,
    "pmrarad": 0.000002613630551875,
    "pmdecrad": -0.000005735098585,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119148,
    "lum": 0.000408131391596971,
    "ra": 17.345815,
    "dec": 49.254553,
    "pmra": 539.1,
    "pmdec": -1182.95,
    "rv": 0,
    "Asset Name": "ASTRID 001325",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Braided Bun",
    "Outfit": "Emperor",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003124,
    "y_1": -0.00008054,
    "z_1": -0.00006374,
    "Distance in Parsecs_1": 17.0358,
    "HYG Star ID_1": 119148,
    "Gliese Catalog_1": "GJ 1216"
  },
  {
    "Astrid #": "ASTRID 001326",
    "HYG Star ID": 80141,
    "Hipparcos Catalogue": 80383,
    "Distance in Parsecs": 17.059,
    "mag": 11.06,
    "Absolute Visual Magnitude": 9.9,
    "Color Index": 0.983,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -4.996343,
    "y": -11.285048,
    "z": -11.77683,
    "vx": -0.00000636,
    "vy": 0.00000684,
    "vz": -0.00000385,
    "rarad": 4.29559048010395,
    "decrad": -0.761984813076661,
    "pmrarad": -5.03188119048611e-7,
    "pmdecrad": -3.12123047e-7,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 80141,
    "lum": 0.00954992586021435,
    "ra": 16.407947,
    "dec": -43.658514,
    "pmra": -103.79,
    "pmdec": -64.38,
    "rv": 0,
    "Asset Name": "ASTRID 001326",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Uwu",
    "Hair": "Sweetheart",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00000636,
    "y_1": 0.00000684,
    "z_1": -0.00000385,
    "Distance in Parsecs_1": 17.059,
    "HYG Star ID_1": 80141,
    "Hipparcos Catalogue_1": 80383
  },
  {
    "Astrid #": "ASTRID 001327",
    "HYG Star ID": 118449,
    "Gliese Catalog": "NN 3414",
    "Distance in Parsecs": 17.0648,
    "mag": 11.8,
    "Absolute Visual Magnitude": 10.639,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.385511,
    "y": 9.498812,
    "z": 13.974595,
    "vx": 0.00000756,
    "vy": 0.00004808,
    "vz": -0.00003138,
    "rarad": 1.81688706552129,
    "decrad": 0.95951528280695,
    "pmrarad": -0.00000111608957400694,
    "pmdecrad": -0.000003204923861,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 118449,
    "lum": 0.00483503920591621,
    "ra": 6.939997,
    "dec": 54.976176,
    "pmra": -230.21,
    "pmdec": -661.06,
    "rv": 0,
    "Asset Name": "ASTRID 001327",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Unkempt",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000756,
    "y_1": 0.00004808,
    "z_1": -0.00003138,
    "Distance in Parsecs_1": 17.0648,
    "HYG Star ID_1": 118449,
    "Gliese Catalog_1": "NN 3414"
  },
  {
    "Astrid #": "ASTRID 001328",
    "HYG Star ID": 118500,
    "Gliese Catalog": "GJ 1102A",
    "Distance in Parsecs": 17.0648,
    "mag": 16.69,
    "Absolute Visual Magnitude": 15.529,
    "Spectral Type": "DC9",
    "Color Index": 1.1,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -7.834021,
    "y": 15.008926,
    "z": 2.137221,
    "vx": -0.00002382,
    "vy": 0.00000818,
    "vz": -0.00014424,
    "rarad": 2.05185484320151,
    "decrad": 0.1255711553427,
    "pmrarad": 0.00000101602403034027,
    "pmdecrad": -0.000008520833146,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118500,
    "base": "GJ 1102",
    "lum": 0.0000535056938173136,
    "ra": 7.837508,
    "dec": 7.194697,
    "pmra": 209.57,
    "pmdec": -1757.55,
    "rv": 0,
    "Asset Name": "ASTRID 001328",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Hachimaki",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002382,
    "y_1": 0.00000818,
    "z_1": -0.00014424,
    "Distance in Parsecs_1": 17.0648,
    "HYG Star ID_1": 118500,
    "Gliese Catalog_1": "GJ 1102A"
  },
  {
    "Astrid #": "ASTRID 001329",
    "HYG Star ID": 118501,
    "Gliese Catalog": "GJ 1102B",
    "Distance in Parsecs": 17.0648,
    "mag": 16.98,
    "Absolute Visual Magnitude": 15.819,
    "Spectral Type": "DC9",
    "Color Index": 1.3,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -7.832876,
    "y": 15.009382,
    "z": 2.138211,
    "vx": -0.00002383,
    "vy": 0.00000819,
    "vz": -0.00014424,
    "rarad": 2.05178239720151,
    "decrad": 0.1256296483427,
    "pmrarad": 0.00000101602403034027,
    "pmdecrad": -0.000008520833146,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118500,
    "base": "GJ 1102",
    "lum": 0.0000409637776369679,
    "ra": 7.837231,
    "dec": 7.198049,
    "pmra": 209.57,
    "pmdec": -1757.55,
    "rv": 0,
    "Asset Name": "ASTRID 001329",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Sweet Nap",
    "Hair": "Ram Horns",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002383,
    "y_1": 0.00000819,
    "z_1": -0.00014424,
    "Distance in Parsecs_1": 17.0648,
    "HYG Star ID_1": 118501,
    "Gliese Catalog_1": "GJ 1102B"
  },
  {
    "Astrid #": "ASTRID 001330",
    "HYG Star ID": 67528,
    "Hipparcos Catalogue": 67742,
    "Henry Draper Catalogue": 120780,
    "Gliese Catalog": "Gl 531",
    "Distance in Parsecs": 17.0794,
    "mag": 7.37,
    "Absolute Visual Magnitude": 6.208,
    "Spectral Type": "K1V",
    "Color Index": 0.891,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.492941,
    "y": -5.079295,
    "z": -13.258322,
    "vx": -0.00000516,
    "vy": 0.00005201,
    "vz": 0.0000167,
    "rarad": 3.63290792898839,
    "decrad": -0.888752201725543,
    "pmrarad": -0.00000282777275457638,
    "pmdecrad": -2.92197205e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 67528,
    "lum": 0.286285927087212,
    "ra": 13.876686,
    "dec": -50.92175,
    "pmra": -583.27,
    "pmdec": -60.27,
    "rv": -25,
    "Asset Name": "ASTRID 001330",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000516,
    "y_1": 0.00005201,
    "z_1": 0.0000167,
    "Distance in Parsecs_1": 17.0794,
    "HYG Star ID_1": 67528,
    "Hipparcos Catalogue_1": 67742,
    "Henry Draper Catalogue_1": 120780,
    "Gliese Catalog_1": "Gl 531"
  },
  {
    "Astrid #": "ASTRID 001331",
    "HYG Star ID": 118039,
    "Gliese Catalog": "GJ 1026B",
    "Distance in Parsecs": 17.094,
    "mag": 12.4,
    "Absolute Visual Magnitude": 11.236,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.445735,
    "y": 4.373378,
    "z": 5.874326,
    "vx": -0.00001557,
    "vy": 0.00005059,
    "vz": 0.0000033,
    "rarad": 0.275912436584133,
    "decrad": 0.3507991406861,
    "pmrarad": 0.00000309597168265972,
    "pmdecrad": 2.05633722e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 4918,
    "base": "GJ 1026",
    "lum": 0.00278997299740826,
    "ra": 1.053908,
    "dec": 20.09931,
    "pmra": 638.59,
    "pmdec": 42.41,
    "rv": 0,
    "Asset Name": "ASTRID 001331",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Braided Ponytail",
    "Outfit": "Samurai",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001557,
    "y_1": 0.00005059,
    "z_1": 0.0000033,
    "Distance in Parsecs_1": 17.094,
    "HYG Star ID_1": 118039,
    "Gliese Catalog_1": "GJ 1026B"
  },
  {
    "Astrid #": "ASTRID 001332",
    "HYG Star ID": 119305,
    "Gliese Catalog": "NN 4124",
    "Distance in Parsecs": 17.094,
    "mag": 12.41,
    "Absolute Visual Magnitude": 11.246,
    "Spectral Type": "M3",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.653259,
    "y": -12.774865,
    "z": 9.205531,
    "vx": 0.00002857,
    "vy": 0.00002728,
    "vz": 0.00001721,
    "rarad": 5.19252379097182,
    "decrad": 0.56868459234625,
    "pmrarad": 0.00000221971943642361,
    "pmdecrad": 0.000001195186925,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119305,
    "lum": 0.00276439437122033,
    "ra": 19.83398,
    "dec": 32.583227,
    "pmra": 457.85,
    "pmdec": 246.53,
    "rv": 0,
    "Asset Name": "ASTRID 001332",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Side Part",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002857,
    "y_1": 0.00002728,
    "z_1": 0.00001721,
    "Distance in Parsecs_1": 17.094,
    "HYG Star ID_1": 119305,
    "Gliese Catalog_1": "NN 4124"
  },
  {
    "Astrid #": "ASTRID 001333",
    "HYG Star ID": 118628,
    "Gliese Catalog": "Gl 348B",
    "Distance in Parsecs": 17.0999,
    "mag": 7.18,
    "Absolute Visual Magnitude": 6.015,
    "Spectral Type": "K0",
    "Color Index": 0.87,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.51265,
    "y": 10.448045,
    "z": -0.820578,
    "vx": -0.00001611,
    "vy": -0.00000183,
    "vz": -0.00000319,
    "rarad": 2.48340512659674,
    "decrad": -0.0480040838114,
    "pmrarad": 6.61091934805555e-7,
    "pmdecrad": -1.53836228e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 2,
    "comp_primary": 46376,
    "base": "Gl 348",
    "lum": 0.341979442513708,
    "ra": 9.485909,
    "dec": -2.750431,
    "pmra": 136.36,
    "pmdec": -31.73,
    "rv": 11.5,
    "Asset Name": "ASTRID 001333",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Pompadour",
    "Outfit": "Star Knight",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001611,
    "y_1": -0.00000183,
    "z_1": -0.00000319,
    "Distance in Parsecs_1": 17.0999,
    "HYG Star ID_1": 118628,
    "Gliese Catalog_1": "Gl 348B"
  },
  {
    "Astrid #": "ASTRID 001334",
    "HYG Star ID": 111221,
    "Hipparcos Catalogue": 111571,
    "Henry Draper Catalogue": 214100,
    "Gliese Catalog": "Gl 864",
    "Distance in Parsecs": 17.1174,
    "mag": 9.98,
    "Absolute Visual Magnitude": 8.813,
    "Spectral Type": "K5",
    "Color Index": 1.411,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.98315,
    "y": -6.122451,
    "z": -0.251449,
    "vx": 0.00001114,
    "vy": 7e-8,
    "vz": -0.00005244,
    "rarad": 5.91737007545756,
    "decrad": -0.0146901253541362,
    "pmrarad": 2.36395150638888e-7,
    "pmdecrad": -0.000003054326187,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 111221,
    "lum": 0.0259896242110866,
    "var_min": 10.038,
    "var_max": 9.928,
    "ra": 22.602689,
    "dec": -0.841682,
    "pmra": 48.76,
    "pmdec": -630,
    "rv": 10.9,
    "Asset Name": "ASTRID 001334",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Unkempt",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001114,
    "y_1": 7e-8,
    "z_1": -0.00005244,
    "Distance in Parsecs_1": 17.1174,
    "HYG Star ID_1": 111221,
    "Hipparcos Catalogue_1": 111571,
    "Henry Draper Catalogue_1": 214100,
    "Gliese Catalog_1": "Gl 864"
  },
  {
    "Astrid #": "ASTRID 001335",
    "HYG Star ID": 116690,
    "Hipparcos Catalogue": 117059,
    "Gliese Catalog": "Gl 905.2A",
    "Distance in Parsecs": 17.1262,
    "mag": 13.11,
    "Absolute Visual Magnitude": 11.942,
    "Spectral Type": "M5",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.400618,
    "y": -1.016755,
    "z": 9.21345,
    "vx": -0.00001904,
    "vy": -0.0000168,
    "vz": -0.00001829,
    "rarad": 6.21269905133061,
    "decrad": 0.568039929759338,
    "pmrarad": -0.00000105699078634722,
    "pmdecrad": -3.40484647e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 116690,
    "base": "Gl 905.2",
    "lum": 0.00145612949861605,
    "var_min": 13.432,
    "var_max": 12.612,
    "ra": 23.730762,
    "dec": 32.546291,
    "pmra": -218.02,
    "pmdec": -70.23,
    "rv": -24.3,
    "Asset Name": "ASTRID 001335",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Awkward",
    "Hair": "Hachimaki",
    "Outfit": "Astro",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001904,
    "y_1": -0.0000168,
    "z_1": -0.00001829,
    "Distance in Parsecs_1": 17.1262,
    "HYG Star ID_1": 116690,
    "Hipparcos Catalogue_1": 117059,
    "Gliese Catalog_1": "Gl 905.2A"
  },
  {
    "Astrid #": "ASTRID 001336",
    "HYG Star ID": 1692,
    "Hipparcos Catalogue": 1696,
    "Gliese Catalog": "Gl 17.1",
    "Distance in Parsecs": 17.1321,
    "mag": 10.41,
    "Absolute Visual Magnitude": 9.241,
    "Spectral Type": "M0",
    "Color Index": 1.475,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.903672,
    "y": 1.110924,
    "z": -12.271026,
    "vx": -0.00004706,
    "vy": 0.00000147,
    "vz": -0.00004909,
    "rarad": 0.0930553037859405,
    "decrad": -0.798425635940915,
    "pmrarad": 3.407755360625e-7,
    "pmdecrad": -0.000003953364676,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 1692,
    "lum": 0.017522658618857,
    "var_min": 10.461,
    "var_max": 10.321,
    "ra": 0.355445,
    "dec": -45.746419,
    "pmra": 70.29,
    "pmdec": -815.44,
    "rv": 2.5,
    "Asset Name": "ASTRID 001336",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Ponytail",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00004706,
    "y_1": 0.00000147,
    "z_1": -0.00004909,
    "Distance in Parsecs_1": 17.1321,
    "HYG Star ID_1": 1692,
    "Hipparcos Catalogue_1": 1696,
    "Gliese Catalog_1": "Gl 17.1"
  },
  {
    "Astrid #": "ASTRID 001337",
    "HYG Star ID": 11425,
    "Hipparcos Catalogue": 11452,
    "Henry Draper Catalogue": 15285,
    "Gliese Catalog": "Gl 98A",
    "Distance in Parsecs": 17.1438,
    "mag": 8.69,
    "Absolute Visual Magnitude": 7.519,
    "Spectral Type": "K7V:",
    "Color Index": 1.397,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.661346,
    "y": 10.272558,
    "z": 1.324855,
    "vx": 0.00000288,
    "vy": 0.00001125,
    "vz": 0.00002071,
    "rarad": 0.64474366694629,
    "decrad": 0.0773556550872887,
    "pmrarad": 4.23193861756944e-7,
    "pmdecrad": 0.000001163552833,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 11425,
    "base": "Gl  98",
    "lum": 0.0855854621220823,
    "ra": 2.462739,
    "dec": 4.432153,
    "pmra": 87.29,
    "pmdec": 240,
    "rv": 10.4,
    "Asset Name": "ASTRID 001337",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Heartbreaker",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000288,
    "y_1": 0.00001125,
    "z_1": 0.00002071,
    "Distance in Parsecs_1": 17.1438,
    "HYG Star ID_1": 11425,
    "Hipparcos Catalogue_1": 11452,
    "Henry Draper Catalogue_1": 15285,
    "Gliese Catalog_1": "Gl 98A"
  },
  {
    "Astrid #": "ASTRID 001338",
    "HYG Star ID": 12620,
    "Hipparcos Catalogue": 12653,
    "Henry Draper Catalogue": 17051,
    "Harvard Revised Catalogue": 810,
    "Gliese Catalog": "Gl 108",
    "Distance in Parsecs": 17.1674,
    "mag": 5.4,
    "Absolute Visual Magnitude": 4.226,
    "Spectral Type": "G3IV",
    "Color Index": 0.561,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.233473,
    "y": 7.066778,
    "z": -13.303946,
    "vx": 2.4e-7,
    "vy": 0.00003681,
    "vz": -7.5e-7,
    "rarad": 0.709283853226091,
    "decrad": -0.886632386849396,
    "pmrarad": 0.00000161796869611805,
    "pmdecrad": 0.000001062760069,
    "bayer": "Iot",
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 12620,
    "lum": 1.77664230820361,
    "bf": "Iot Hor",
    "ra": 2.709265,
    "dec": -50.800294,
    "pmra": 333.73,
    "pmdec": 219.21,
    "rv": 15.5,
    "Asset Name": "ASTRID 001338",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Unkempt",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": 2.4e-7,
    "y_1": 0.00003681,
    "z_1": -7.5e-7,
    "Distance in Parsecs_1": 17.1674,
    "HYG Star ID_1": 12620,
    "Hipparcos Catalogue_1": 12653,
    "Henry Draper Catalogue_1": 17051,
    "Harvard Revised Catalogue_1": 810,
    "Gliese Catalog_1": "Gl 108"
  },
  {
    "Astrid #": "ASTRID 001339",
    "HYG Star ID": 49832,
    "Hipparcos Catalogue": 49973,
    "Gliese Catalog": "Gl 383",
    "Distance in Parsecs": 17.1821,
    "mag": 9.93,
    "Absolute Visual Magnitude": 8.755,
    "Spectral Type": "K7V",
    "Color Index": 1.413,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.512588,
    "y": 7.383679,
    "z": -5.485444,
    "vx": 0.000021,
    "vy": 0.00003593,
    "vz": -0.00000175,
    "rarad": 2.67094885530691,
    "decrad": -0.324941885002103,
    "pmrarad": -0.00000241825063861111,
    "pmdecrad": -1.41371669e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 49832,
    "lum": 0.0274157417192787,
    "ra": 10.202273,
    "dec": -18.617799,
    "pmra": -498.8,
    "pmdec": -29.16,
    "rv": -1.7,
    "Asset Name": "ASTRID 001339",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Maniacal",
    "Hair": "Curly",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.000021,
    "y_1": 0.00003593,
    "z_1": -0.00000175,
    "Distance in Parsecs_1": 17.1821,
    "HYG Star ID_1": 49832,
    "Hipparcos Catalogue_1": 49973,
    "Gliese Catalog_1": "Gl 383"
  },
  {
    "Astrid #": "ASTRID 001340",
    "HYG Star ID": 38671,
    "Hipparcos Catalogue": 38784,
    "Henry Draper Catalogue": 62613,
    "Harvard Revised Catalogue": 2997,
    "Gliese Catalog": "Gl 290",
    "Distance in Parsecs": 17.191,
    "mag": 6.55,
    "Absolute Visual Magnitude": 5.373,
    "Spectral Type": "G8V",
    "Color Index": 0.719,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.412371,
    "y": 2.540472,
    "z": 16.94342,
    "vx": 0.00003882,
    "vy": 0.00001177,
    "vz": -0.000006,
    "rarad": 2.07829399923656,
    "decrad": 1.40089798933395,
    "pmrarad": -0.00000230625867840277,
    "pmdecrad": 4.33181023e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 38671,
    "lum": 0.617731858591519,
    "ra": 7.938498,
    "dec": 80.265542,
    "pmra": -475.7,
    "pmdec": 89.35,
    "rv": -7.2,
    "Asset Name": "ASTRID 001340",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Grecian",
    "Outfit": "Tats",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00003882,
    "y_1": 0.00001177,
    "z_1": -0.000006,
    "Distance in Parsecs_1": 17.191,
    "HYG Star ID_1": 38671,
    "Hipparcos Catalogue_1": 38784,
    "Henry Draper Catalogue_1": 62613,
    "Harvard Revised Catalogue_1": 2997,
    "Gliese Catalog_1": "Gl 290"
  },
  {
    "Astrid #": "ASTRID 001341",
    "HYG Star ID": 70096,
    "Hipparcos Catalogue": 70319,
    "Henry Draper Catalogue": 126053,
    "Harvard Revised Catalogue": 5384,
    "Gliese Catalog": "Gl 547",
    "Distance in Parsecs": 17.191,
    "mag": 6.25,
    "Absolute Visual Magnitude": 5.073,
    "Spectral Type": "G1V",
    "Color Index": 0.639,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.937186,
    "y": -10.056877,
    "z": 0.372487,
    "vx": 0.00002588,
    "vy": -0.00000431,
    "vz": -0.0000402,
    "rarad": 3.76665939092493,
    "decrad": 0.0216694657268412,
    "pmrarad": 0.00000108433427793055,
    "pmdecrad": -0.000002314888361,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 70096,
    "lum": 0.814329185209244,
    "ra": 14.387579,
    "dec": 1.241569,
    "pmra": 223.66,
    "pmdec": -477.48,
    "rv": -18.9,
    "Asset Name": "ASTRID 001341",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Tiny Crown",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00002588,
    "y_1": -0.00000431,
    "z_1": -0.0000402,
    "Distance in Parsecs_1": 17.191,
    "HYG Star ID_1": 70096,
    "Hipparcos Catalogue_1": 70319,
    "Henry Draper Catalogue_1": 126053,
    "Harvard Revised Catalogue_1": 5384,
    "Gliese Catalog_1": "Gl 547"
  },
  {
    "Astrid #": "ASTRID 001342",
    "HYG Star ID": 118627,
    "Gliese Catalog": "Gl 347B",
    "Distance in Parsecs": 17.191,
    "mag": 15,
    "Absolute Visual Magnitude": 13.823,
    "Spectral Type": "m",
    "Color Index": 1.87,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.477359,
    "y": 10.441717,
    "z": -2.204956,
    "vx": 0.00001406,
    "vy": 0.00000646,
    "vz": -0.00005538,
    "rarad": 2.48243296325815,
    "decrad": -0.1286165284603,
    "pmrarad": -7.97954836826388e-7,
    "pmdecrad": -0.000003248649206,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 46356,
    "base": "Gl 347",
    "lum": 0.000257513499041031,
    "ra": 9.482195,
    "dec": -7.369184,
    "pmra": -164.59,
    "pmdec": -670.08,
    "rv": 0,
    "Asset Name": "ASTRID 001342",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "High Bun",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001406,
    "y_1": 0.00000646,
    "z_1": -0.00005538,
    "Distance in Parsecs_1": 17.191,
    "HYG Star ID_1": 118627,
    "Gliese Catalog_1": "Gl 347B"
  },
  {
    "Astrid #": "ASTRID 001343",
    "HYG Star ID": 118254,
    "Gliese Catalog": "NN 3248",
    "Distance in Parsecs": 17.2117,
    "mag": 11.31,
    "Absolute Visual Magnitude": 10.131,
    "Spectral Type": "m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.644602,
    "y": 4.095501,
    "z": 16.506834,
    "vx": -0.00001583,
    "vy": 0.00003886,
    "vz": -0.0000071,
    "rarad": 0.997173376586673,
    "decrad": 1.2836192301686,
    "pmrarad": 0.00000199777173346527,
    "pmdecrad": -0.000001456816628,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 118254,
    "lum": 0.0077196924761025,
    "ra": 3.808922,
    "dec": 73.545964,
    "pmra": 412.07,
    "pmdec": -300.49,
    "rv": 0,
    "Asset Name": "ASTRID 001343",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Ninja",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001583,
    "y_1": 0.00003886,
    "z_1": -0.0000071,
    "Distance in Parsecs_1": 17.2117,
    "HYG Star ID_1": 118254,
    "Gliese Catalog_1": "NN 3248"
  },
  {
    "Astrid #": "ASTRID 001344",
    "HYG Star ID": 78223,
    "Hipparcos Catalogue": 78459,
    "Henry Draper Catalogue": 143761,
    "Harvard Revised Catalogue": 5968,
    "Gliese Catalog": "Gl 606.2",
    "Distance in Parsecs": 17.2354,
    "mag": 5.39,
    "Absolute Visual Magnitude": 4.208,
    "Spectral Type": "G2V",
    "Color Index": 0.612,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.145594,
    "y": -12.507816,
    "z": 9.463601,
    "vx": -0.00003968,
    "vy": -0.00003629,
    "vz": -0.00004365,
    "rarad": 4.19334871769051,
    "decrad": 0.58125588899047,
    "pmrarad": -9.54549655645833e-7,
    "pmdecrad": -0.000003747658232,
    "bayer": "Rho",
    "flam": 15,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 78223,
    "lum": 1.80634208316997,
    "bf": "15Rho CrB",
    "ra": 16.017412,
    "dec": 33.303509,
    "pmra": -196.89,
    "pmdec": -773.01,
    "rv": 18.4,
    "Asset Name": "ASTRID 001344",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Elegant",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": -0.00003968,
    "y_1": -0.00003629,
    "z_1": -0.00004365,
    "Distance in Parsecs_1": 17.2354,
    "HYG Star ID_1": 78223,
    "Hipparcos Catalogue_1": 78459,
    "Henry Draper Catalogue_1": 143761,
    "Harvard Revised Catalogue_1": 5968,
    "Gliese Catalog_1": "Gl 606.2"
  },
  {
    "Astrid #": "ASTRID 001345",
    "HYG Star ID": 33190,
    "Hipparcos Catalogue": 33277,
    "Henry Draper Catalogue": 50692,
    "Harvard Revised Catalogue": 2569,
    "Gliese Catalog": "Gl 252",
    "Distance in Parsecs": 17.2414,
    "mag": 5.74,
    "Absolute Visual Magnitude": 4.557,
    "Spectral Type": "G0V",
    "Color Index": 0.573,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.723157,
    "y": 15.126311,
    "z": 7.388778,
    "vx": 0.00000645,
    "vy": -0.00001371,
    "vz": -0.00000472,
    "rarad": 1.8121368064422,
    "decrad": 0.442889470468891,
    "pmrarad": -1.73320890798611e-7,
    "pmdecrad": 1.21882159e-7,
    "flam": 37,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 33190,
    "lum": 1.30978496239968,
    "bf": "37    Gem",
    "ra": 6.921853,
    "dec": 25.375697,
    "pmra": -35.75,
    "pmdec": 25.14,
    "rv": -15.1,
    "Asset Name": "ASTRID 001345",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Sweet Nap",
    "Hair": "Innocent",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00000645,
    "y_1": -0.00001371,
    "z_1": -0.00000472,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 33190,
    "Hipparcos Catalogue_1": 33277,
    "Henry Draper Catalogue_1": 50692,
    "Harvard Revised Catalogue_1": 2569,
    "Gliese Catalog_1": "Gl 252"
  },
  {
    "Astrid #": "ASTRID 001346",
    "HYG Star ID": 72011,
    "Hipparcos Catalogue": 72237,
    "Gliese Catalog": "Gl 562",
    "Distance in Parsecs": 17.2414,
    "mag": 9.23,
    "Absolute Visual Magnitude": 8.047,
    "Spectral Type": "K5V",
    "Color Index": 1.261,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.363218,
    "y": -10.975422,
    "z": 4.895985,
    "vx": -0.0000554,
    "vy": -0.0000368,
    "vz": -0.00006065,
    "rarad": 3.86759752657979,
    "decrad": 0.287921800739832,
    "pmrarad": -5.37076595319444e-7,
    "pmdecrad": -0.000004458201162,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72011,
    "lum": 0.0526259562080317,
    "ra": 14.773134,
    "dec": 16.496704,
    "pmra": -110.78,
    "pmdec": -919.57,
    "rv": 44.9,
    "Asset Name": "ASTRID 001346",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Greasy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.0000554,
    "y_1": -0.0000368,
    "z_1": -0.00006065,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 72011,
    "Hipparcos Catalogue_1": 72237,
    "Gliese Catalog_1": "Gl 562"
  },
  {
    "Astrid #": "ASTRID 001347",
    "HYG Star ID": 118000,
    "Gliese Catalog": "NN 3042",
    "Distance in Parsecs": 17.2414,
    "mag": 11.71,
    "Absolute Visual Magnitude": 10.527,
    "Spectral Type": "M2",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.931521,
    "y": 1.897085,
    "z": 12.300641,
    "vx": 0.00001138,
    "vy": -0.00001986,
    "vz": -0.00000797,
    "rarad": 0.157704350728546,
    "decrad": 0.79438999227835,
    "pmrarad": -0.0000012413654290625,
    "pmdecrad": -6.60054433e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 118000,
    "lum": 0.00536043457816143,
    "ra": 0.602386,
    "dec": 45.515194,
    "pmra": -256.05,
    "pmdec": -136.15,
    "rv": 0,
    "Asset Name": "ASTRID 001347",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Infatuated",
    "Hair": "Honest Lad",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00001138,
    "y_1": -0.00001986,
    "z_1": -0.00000797,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118000,
    "Gliese Catalog_1": "NN 3042"
  },
  {
    "Astrid #": "ASTRID 001348",
    "HYG Star ID": 118105,
    "Gliese Catalog": "NN 3129",
    "Distance in Parsecs": 17.2414,
    "mag": 14.27,
    "Absolute Visual Magnitude": 13.087,
    "Spectral Type": "dM5  :",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 14.413119,
    "y": 8.552415,
    "z": 4.047726,
    "vx": -0.00001774,
    "vy": 0.00003398,
    "vz": -0.00000859,
    "rarad": 0.535532294212505,
    "decrad": 0.23697966429445,
    "pmrarad": 0.00000222020425010416,
    "pmdecrad": -5.12574111e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118105,
    "lum": 0.00050722423989265,
    "ra": 2.045583,
    "dec": 13.577935,
    "pmra": 457.95,
    "pmdec": -105.73,
    "rv": 0,
    "Asset Name": "ASTRID 001348",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Lovestruck",
    "Hair": "Charmer",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001774,
    "y_1": 0.00003398,
    "z_1": -0.00000859,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118105,
    "Gliese Catalog_1": "NN 3129"
  },
  {
    "Astrid #": "ASTRID 001349",
    "HYG Star ID": 118123,
    "Gliese Catalog": "NN 3143",
    "Distance in Parsecs": 17.2414,
    "mag": 13.58,
    "Absolute Visual Magnitude": 12.397,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.870531,
    "y": 7.973806,
    "z": 9.631968,
    "vx": 0.00000713,
    "vy": 0.00002165,
    "vz": -0.0000267,
    "rarad": 0.591492289873541,
    "decrad": 0.5927614383694,
    "pmrarad": 8.11917470826389e-7,
    "pmdecrad": -0.000001867332612,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118123,
    "lum": 0.000957634976969267,
    "ra": 2.259334,
    "dec": 33.962729,
    "pmra": 167.47,
    "pmdec": -385.17,
    "rv": 0,
    "Asset Name": "ASTRID 001349",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Pompadour",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000713,
    "y_1": 0.00002165,
    "z_1": -0.0000267,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118123,
    "Gliese Catalog_1": "NN 3143"
  },
  {
    "Astrid #": "ASTRID 001350",
    "HYG Star ID": 118521,
    "Gliese Catalog": "NN 3478",
    "Distance in Parsecs": 17.2414,
    "mag": 10.21,
    "Absolute Visual Magnitude": 9.027,
    "Spectral Type": "M0",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.077131,
    "y": 13.181293,
    "z": 7.634091,
    "vx": -0.00001225,
    "vy": -0.0000027,
    "vz": -0.0000083,
    "rarad": 2.12055029298622,
    "decrad": 0.458693153716399,
    "pmrarad": 6.876597245e-7,
    "pmdecrad": -5.37265672e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 118521,
    "lum": 0.0213402744284897,
    "ra": 8.099905,
    "dec": 26.281182,
    "pmra": 141.84,
    "pmdec": -110.82,
    "rv": 0,
    "Asset Name": "ASTRID 001350",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Malevolent",
    "Hair": "Heartbreaker",
    "Outfit": "Boxer",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00001225,
    "y_1": -0.0000027,
    "z_1": -0.0000083,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118521,
    "Gliese Catalog_1": "NN 3478"
  },
  {
    "Astrid #": "ASTRID 001351",
    "HYG Star ID": 118526,
    "Gliese Catalog": "GJ 1108B",
    "Distance in Parsecs": 17.2414,
    "mag": 12.12,
    "Absolute Visual Magnitude": 10.937,
    "Spectral Type": "dM3  e",
    "Color Index": 1.53,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -7.727866,
    "y": 12.256985,
    "z": 9.344104,
    "vx": -0.00000125,
    "vy": 0.00001011,
    "vz": -0.00001429,
    "rarad": 2.13333381235719,
    "decrad": 0.572764387904899,
    "pmrarad": -2.51472856104166e-7,
    "pmdecrad": -9.86561902e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 39783,
    "base": "GJ 1108",
    "lum": 0.00367451479199939,
    "ra": 8.148735,
    "dec": 32.816982,
    "pmra": -51.87,
    "pmdec": -203.49,
    "rv": 0,
    "Asset Name": "ASTRID 001351",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Elegant",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000125,
    "y_1": 0.00001011,
    "z_1": -0.00001429,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118526,
    "Gliese Catalog_1": "GJ 1108B"
  },
  {
    "Astrid #": "ASTRID 001352",
    "HYG Star ID": 118623,
    "Gliese Catalog": "NN 3554",
    "Distance in Parsecs": 17.2414,
    "mag": 14.02,
    "Absolute Visual Magnitude": 12.837,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.643058,
    "y": 7.961236,
    "z": 11.869963,
    "vx": 0.00001045,
    "vy": 0.00002193,
    "vz": -0.00000622,
    "rarad": 2.45146607608765,
    "decrad": 0.759359437603749,
    "pmrarad": -0.00000136673824685416,
    "pmdecrad": -4.97447925e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118623,
    "lum": 0.000638557485078839,
    "ra": 9.363911,
    "dec": 43.508091,
    "pmra": -281.91,
    "pmdec": -102.61,
    "rv": 0,
    "Asset Name": "ASTRID 001352",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Spiky",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001045,
    "y_1": 0.00002193,
    "z_1": -0.00000622,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118623,
    "Gliese Catalog_1": "NN 3554"
  },
  {
    "Astrid #": "ASTRID 001353",
    "HYG Star ID": 118642,
    "Gliese Catalog": "GJ 2074",
    "Distance in Parsecs": 17.2414,
    "mag": 14.9,
    "Absolute Visual Magnitude": 13.717,
    "Spectral Type": "DA7",
    "Color Index": 0.3,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -14.104139,
    "y": 9.694335,
    "z": 2.087828,
    "vx": 3.7e-7,
    "vy": 0.0000012,
    "vz": -0.0000031,
    "rarad": 2.53941416730997,
    "decrad": 0.1213917848014,
    "pmrarad": -6.94738004236111e-8,
    "pmdecrad": -1.81043972e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118642,
    "lum": 0.00028392262394249,
    "ra": 9.699848,
    "dec": 6.955237,
    "pmra": -14.33,
    "pmdec": -37.34,
    "rv": 0,
    "Asset Name": "ASTRID 001353",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Round Brush",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 3.7e-7,
    "y_1": 0.0000012,
    "z_1": -0.0000031,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118642,
    "Gliese Catalog_1": "GJ 2074"
  },
  {
    "Astrid #": "ASTRID 001354",
    "HYG Star ID": 118673,
    "Gliese Catalog": "GJ 1132",
    "Distance in Parsecs": 17.2414,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.317,
    "Spectral Type": "k",
    "Color Index": 1.72,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.511813,
    "y": 5.19303,
    "z": -12.641139,
    "vx": 0.00001586,
    "vy": 0.00008969,
    "vz": 0.00002501,
    "rarad": 2.68286099769149,
    "decrad": -0.8229971789755,
    "pmrarad": -0.00000507226617007638,
    "pmdecrad": 0.00000206996049,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118673,
    "lum": 0.00103086074006262,
    "ra": 10.247774,
    "dec": -47.154265,
    "pmra": -1046.23,
    "pmdec": 426.96,
    "rv": -1,
    "Asset Name": "ASTRID 001354",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Antlers",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001586,
    "y_1": 0.00008969,
    "z_1": 0.00002501,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118673,
    "Gliese Catalog_1": "GJ 1132"
  },
  {
    "Astrid #": "ASTRID 001355",
    "HYG Star ID": 118790,
    "Gliese Catalog": "NN 3698A",
    "Distance in Parsecs": 17.2414,
    "mag": 12.74,
    "Absolute Visual Magnitude": 11.557,
    "Spectral Type": "M3",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.742192,
    "y": -0.049764,
    "z": -4.118546,
    "vx": 8e-8,
    "vy": 0.00004011,
    "vz": -8.2e-7,
    "rarad": 3.14456846938141,
    "decrad": -0.241207507673549,
    "pmrarad": -0.00000232657237161805,
    "pmdecrad": -4.8733471e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118790,
    "base": "NN 3698",
    "lum": 0.00207586927049519,
    "ra": 12.011367,
    "dec": -13.820172,
    "pmra": -479.89,
    "pmdec": -10.05,
    "rv": 0,
    "Asset Name": "ASTRID 001355",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Prince",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 8e-8,
    "y_1": 0.00004011,
    "z_1": -8.2e-7,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118790,
    "Gliese Catalog_1": "NN 3698A"
  },
  {
    "Astrid #": "ASTRID 001356",
    "HYG Star ID": 118791,
    "Gliese Catalog": "NN 3699B",
    "Distance in Parsecs": 17.2414,
    "mag": 15,
    "Absolute Visual Magnitude": 13.817,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.742195,
    "y": -0.048544,
    "z": -4.118546,
    "vx": 8e-8,
    "vy": 0.00004011,
    "vz": -8.2e-7,
    "rarad": 3.14449566138141,
    "decrad": -0.241207504673549,
    "pmrarad": -0.00000232657237161805,
    "pmdecrad": -4.8733471e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118791,
    "base": "NN 3699",
    "lum": 0.000258940510573923,
    "ra": 12.011089,
    "dec": -13.820172,
    "pmra": -479.89,
    "pmdec": -10.05,
    "rv": 0,
    "Asset Name": "ASTRID 001356",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "High Bun",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 8e-8,
    "y_1": 0.00004011,
    "z_1": -8.2e-7,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118791,
    "Gliese Catalog_1": "NN 3699B"
  },
  {
    "Astrid #": "ASTRID 001357",
    "HYG Star ID": 118956,
    "Gliese Catalog": "NN 3840",
    "Distance in Parsecs": 17.2414,
    "mag": 12.76,
    "Absolute Visual Magnitude": 11.577,
    "Spectral Type": "k-m",
    "Color Index": 1.59,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.20925,
    "y": -9.764117,
    "z": -0.158432,
    "vx": -0.00001277,
    "vy": 0.00001894,
    "vz": -0.00002206,
    "rarad": 3.74365330509021,
    "decrad": -0.0091891651336,
    "pmrarad": -0.00000132523819579861,
    "pmdecrad": -0.000001279762672,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118956,
    "lum": 0.00203798038628705,
    "ra": 14.299702,
    "dec": -0.5265,
    "pmra": -273.35,
    "pmdec": -263.97,
    "rv": 0,
    "Asset Name": "ASTRID 001357",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Fedora",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001277,
    "y_1": 0.00001894,
    "z_1": -0.00002206,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118956,
    "Gliese Catalog_1": "NN 3840"
  },
  {
    "Astrid #": "ASTRID 001358",
    "HYG Star ID": 118967,
    "Gliese Catalog": "NN 3848",
    "Distance in Parsecs": 17.2414,
    "mag": 15.41,
    "Absolute Visual Magnitude": 14.227,
    "Spectral Type": "DC8",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -12.640864,
    "y": -9.437931,
    "z": 6.957004,
    "vx": -0.00001382,
    "vy": 0.00002071,
    "vz": 0.00000299,
    "rarad": 3.78293422093349,
    "decrad": 0.415345165924599,
    "pmrarad": -0.00000144198133007638,
    "pmdecrad": 1.89838492e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118967,
    "lum": 0.000177500671350065,
    "ra": 14.449744,
    "dec": 23.797525,
    "pmra": -297.43,
    "pmdec": 39.16,
    "rv": 0,
    "Asset Name": "ASTRID 001358",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Fired Up",
    "Hair": "Goddess",
    "Outfit": "Viking",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001382,
    "y_1": 0.00002071,
    "z_1": 0.00000299,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 118967,
    "Gliese Catalog_1": "NN 3848"
  },
  {
    "Astrid #": "ASTRID 001359",
    "HYG Star ID": 119044,
    "Gliese Catalog": "NN 3923",
    "Distance in Parsecs": 17.2414,
    "mag": 13.01,
    "Absolute Visual Magnitude": 11.827,
    "Spectral Type": "M7",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.986084,
    "y": -12.701653,
    "z": 8.494491,
    "vx": -0.00002555,
    "vy": -0.00000573,
    "vz": -0.00003257,
    "rarad": 4.15111014611127,
    "decrad": 0.51516668888455,
    "pmrarad": -0.00000107783777461111,
    "pmdecrad": -0.000002171262309,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119044,
    "lum": 0.0016188253626421,
    "ra": 15.856073,
    "dec": 29.516877,
    "pmra": -222.32,
    "pmdec": -447.86,
    "rv": 0,
    "Asset Name": "ASTRID 001359",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Manbun",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002555,
    "y_1": -0.00000573,
    "z_1": -0.00003257,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 119044,
    "Gliese Catalog_1": "NN 3923"
  },
  {
    "Astrid #": "ASTRID 001360",
    "HYG Star ID": 119243,
    "Gliese Catalog": "NN 4083",
    "Distance in Parsecs": 17.2414,
    "mag": 12.53,
    "Absolute Visual Magnitude": 11.347,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.53576,
    "y": -11.261205,
    "z": 12.80707,
    "vx": 0.00002151,
    "vy": -0.00000242,
    "vz": -0.00000639,
    "rarad": 4.93384228595381,
    "decrad": 0.8372565789251,
    "pmrarad": 0.00000118633907631944,
    "pmdecrad": -5.53201498e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119243,
    "lum": 0.00251883662784173,
    "ra": 18.845889,
    "dec": 47.971268,
    "pmra": 244.7,
    "pmdec": -114.11,
    "rv": 0,
    "Asset Name": "ASTRID 001360",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Manbun",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002151,
    "y_1": -0.00000242,
    "z_1": -0.00000639,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 119243,
    "Gliese Catalog_1": "NN 4083"
  },
  {
    "Astrid #": "ASTRID 001361",
    "HYG Star ID": 119262,
    "Gliese Catalog": "GJ 1231",
    "Distance in Parsecs": 17.2414,
    "mag": 15.16,
    "Absolute Visual Magnitude": 13.977,
    "Spectral Type": "m",
    "Color Index": 1.91,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.525125,
    "y": -14.739853,
    "z": 7.7153,
    "vx": -0.0000109,
    "vy": -0.0000225,
    "vz": -0.00003657,
    "rarad": 5.01026060257477,
    "decrad": 0.46395316009065,
    "pmrarad": -9.87468504555555e-7,
    "pmdecrad": -0.000002372198187,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119262,
    "lum": 0.000223460105773122,
    "ra": 19.137786,
    "dec": 26.582558,
    "pmra": -203.68,
    "pmdec": -489.3,
    "rv": 0,
    "Asset Name": "ASTRID 001361",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Upset",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000109,
    "y_1": -0.0000225,
    "z_1": -0.00003657,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 119262,
    "Gliese Catalog_1": "GJ 1231"
  },
  {
    "Astrid #": "ASTRID 001362",
    "HYG Star ID": 119270,
    "Gliese Catalog": "NN 4105",
    "Distance in Parsecs": 17.2414,
    "mag": 12.01,
    "Absolute Visual Magnitude": 10.827,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.362378,
    "y": -13.329886,
    "z": 10.027446,
    "vx": 0.00001858,
    "vy": -0.00000107,
    "vz": -0.00000951,
    "rarad": 5.02865476124171,
    "decrad": 0.62068339900855,
    "pmrarad": 0.00000100482483431944,
    "pmdecrad": -6.77759829e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119270,
    "lum": 0.00406630546340428,
    "ra": 19.208046,
    "dec": 35.562539,
    "pmra": 207.26,
    "pmdec": -139.8,
    "rv": 0,
    "Asset Name": "ASTRID 001362",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Bandana",
    "Outfit": "Viking",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001858,
    "y_1": -0.00000107,
    "z_1": -0.00000951,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 119270,
    "Gliese Catalog_1": "NN 4105"
  },
  {
    "Astrid #": "ASTRID 001363",
    "HYG Star ID": 119339,
    "Gliese Catalog": "NN 4143",
    "Distance in Parsecs": 17.2414,
    "mag": 11.79,
    "Absolute Visual Magnitude": 10.607,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.443308,
    "y": -13.636215,
    "z": 4.705681,
    "vx": 0.00001387,
    "vy": 0.00000789,
    "vz": -0.00000497,
    "rarad": 5.31807316120508,
    "decrad": 0.2764364755301,
    "pmrarad": 9.22164101784722e-7,
    "pmdecrad": -2.99629398e-7,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 119339,
    "lum": 0.00497966354164485,
    "ra": 20.313543,
    "dec": 15.838643,
    "pmra": 190.21,
    "pmdec": -61.8,
    "rv": 0,
    "Asset Name": "ASTRID 001363",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00001387,
    "y_1": 0.00000789,
    "z_1": -0.00000497,
    "Distance in Parsecs_1": 17.2414,
    "HYG Star ID_1": 119339,
    "Gliese Catalog_1": "NN 4143"
  },
  {
    "Astrid #": "ASTRID 001364",
    "HYG Star ID": 82337,
    "Hipparcos Catalogue": 82588,
    "Henry Draper Catalogue": 152391,
    "Gliese Catalog": "Gl 641",
    "Distance in Parsecs": 17.2503,
    "mag": 6.65,
    "Absolute Visual Magnitude": 5.466,
    "Spectral Type": "G8V",
    "Color Index": 0.749,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.973027,
    "y": -16.518348,
    "z": -0.007955,
    "vx": -0.00007036,
    "vy": -0.00002726,
    "vz": -0.00012409,
    "rarad": 4.419959246713,
    "decrad": -0.00046113520899625,
    "pmrarad": -0.00000345066137135416,
    "pmdecrad": -0.000007192938171,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82337,
    "lum": 0.567022118160543,
    "var": "V2292",
    "var_min": 6.677,
    "var_max": 6.617,
    "ra": 16.883001,
    "dec": -0.026421,
    "pmra": -711.75,
    "pmdec": -1483.65,
    "rv": 45.4,
    "Asset Name": "ASTRID 001364",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Frizzy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00007036,
    "y_1": -0.00002726,
    "z_1": -0.00012409,
    "Distance in Parsecs_1": 17.2503,
    "HYG Star ID_1": 82337,
    "Hipparcos Catalogue_1": 82588,
    "Henry Draper Catalogue_1": 152391,
    "Gliese Catalog_1": "Gl 641"
  },
  {
    "Astrid #": "ASTRID 001365",
    "HYG Star ID": 25652,
    "Hipparcos Catalogue": 25716,
    "Gliese Catalog": "GJ 2043A",
    "Distance in Parsecs": 17.2563,
    "mag": 10.55,
    "Absolute Visual Magnitude": 9.365,
    "Spectral Type": "M2V:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.209188,
    "y": 16.475002,
    "z": 4.633973,
    "vx": 0.00000724,
    "vy": 0.00000191,
    "vz": -0.00001023,
    "rarad": 1.43749824307015,
    "decrad": 0.271875045901673,
    "pmrarad": -4.01134839291666e-7,
    "pmdecrad": -6.15325523e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 25652,
    "base": "GJ 2043",
    "lum": 0.0156314764264095,
    "ra": 5.490839,
    "dec": 15.577293,
    "pmra": -82.74,
    "pmdec": -126.92,
    "rv": 0,
    "Asset Name": "ASTRID 001365",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Middle Part",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000724,
    "y_1": 0.00000191,
    "z_1": -0.00001023,
    "Distance in Parsecs_1": 17.2563,
    "HYG Star ID_1": 25652,
    "Hipparcos Catalogue_1": 25716,
    "Gliese Catalog_1": "GJ 2043A"
  },
  {
    "Astrid #": "ASTRID 001366",
    "HYG Star ID": 67744,
    "Hipparcos Catalogue": 67960,
    "Henry Draper Catalogue": 121271,
    "Gliese Catalog": "GJ 1181A",
    "Distance in Parsecs": 17.2622,
    "mag": 9.53,
    "Absolute Visual Magnitude": 8.345,
    "Spectral Type": "K5/M0V",
    "Color Index": 1.414,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.223739,
    "y": -7.25799,
    "z": -8.392726,
    "vx": -0.00000753,
    "vy": 0.00002227,
    "vz": -0.0000074,
    "rarad": 3.64356240941795,
    "decrad": -0.507725563873561,
    "pmrarad": -0.00000134075223357638,
    "pmdecrad": -4.90728407e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 67744,
    "base": "GJ 1181",
    "lum": 0.0399944749761096,
    "ra": 13.917383,
    "dec": -29.090532,
    "pmra": -276.55,
    "pmdec": -101.22,
    "rv": 0,
    "Asset Name": "ASTRID 001366",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Afro",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000753,
    "y_1": 0.00002227,
    "z_1": -0.0000074,
    "Distance in Parsecs_1": 17.2622,
    "HYG Star ID_1": 67744,
    "Hipparcos Catalogue_1": 67960,
    "Henry Draper Catalogue_1": 121271,
    "Gliese Catalog_1": "GJ 1181A"
  },
  {
    "Astrid #": "ASTRID 001367",
    "HYG Star ID": 45486,
    "Hipparcos Catalogue": 45617,
    "Henry Draper Catalogue": 79969,
    "Gliese Catalog": "Gl 340A",
    "Distance in Parsecs": 17.2652,
    "mag": 7.2,
    "Absolute Visual Magnitude": 6.014,
    "Spectral Type": "K3V",
    "Color Index": 0.992,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.526179,
    "y": 9.853777,
    "z": 8.254181,
    "vx": -0.00000511,
    "vy": -0.00000112,
    "vz": -0.00004666,
    "rarad": 2.43425812572718,
    "decrad": 0.498475056151956,
    "pmrarad": 2.41340250180555e-7,
    "pmdecrad": -0.000002461011205,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 45486,
    "base": "Gl 340",
    "lum": 0.342294562316041,
    "var": "IP",
    "var_min": 7.213,
    "var_max": 7.183,
    "ra": 9.298181,
    "dec": 28.560517,
    "pmra": 49.78,
    "pmdec": -507.62,
    "rv": -19.1,
    "Asset Name": "ASTRID 001367",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Straw Hat",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00000511,
    "y_1": -0.00000112,
    "z_1": -0.00004666,
    "Distance in Parsecs_1": 17.2652,
    "HYG Star ID_1": 45486,
    "Hipparcos Catalogue_1": 45617,
    "Henry Draper Catalogue_1": 79969,
    "Gliese Catalog_1": "Gl 340A"
  },
  {
    "Astrid #": "ASTRID 001368",
    "HYG Star ID": 119125,
    "Gliese Catalog": "GJ 1209",
    "Distance in Parsecs": 17.2712,
    "mag": 12.28,
    "Absolute Visual Magnitude": 11.093,
    "Spectral Type": "m",
    "Color Index": 1.55,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.972163,
    "y": -16.041911,
    "z": 5.030498,
    "vx": -0.00001543,
    "vy": -0.00010005,
    "vz": -0.00006773,
    "rarad": 4.46965834608487,
    "decrad": 0.2954813635133,
    "pmrarad": 5.25101697409722e-7,
    "pmdecrad": -0.000005453169734,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119125,
    "lum": 0.00318273148217077,
    "ra": 17.072837,
    "dec": 16.929835,
    "pmra": 108.31,
    "pmdec": -1124.8,
    "rv": 75,
    "Asset Name": "ASTRID 001368",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Medium Bob",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001543,
    "y_1": -0.00010005,
    "z_1": -0.00006773,
    "Distance in Parsecs_1": 17.2712,
    "HYG Star ID_1": 119125,
    "Gliese Catalog_1": "GJ 1209"
  },
  {
    "Astrid #": "ASTRID 001369",
    "HYG Star ID": 119393,
    "Gliese Catalog": "Gl 820.1",
    "Distance in Parsecs": 17.2712,
    "mag": 13.56,
    "Absolute Visual Magnitude": 12.373,
    "Spectral Type": "DA6",
    "Color Index": 0.25,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 1.834951,
    "y": -1.633752,
    "z": -17.092615,
    "vx": -0.00002387,
    "vy": 0.00003059,
    "vz": 0.0000534,
    "rarad": 5.55560496752131,
    "decrad": -1.4280356845444,
    "pmrarad": 4.03510426326388e-7,
    "pmdecrad": -0.000001747830888,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119393,
    "lum": 0.000979039017448051,
    "ra": 21.220848,
    "dec": -81.820418,
    "pmra": 83.23,
    "pmdec": -360.52,
    "rv": -57,
    "Asset Name": "ASTRID 001369",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Audiophile",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002387,
    "y_1": 0.00003059,
    "z_1": 0.0000534,
    "Distance in Parsecs_1": 17.2712,
    "HYG Star ID_1": 119393,
    "Gliese Catalog_1": "Gl 820.1"
  },
  {
    "Astrid #": "ASTRID 001370",
    "HYG Star ID": 65063,
    "Hipparcos Catalogue": 65269,
    "Henry Draper Catalogue": 116220,
    "Distance in Parsecs": 17.2801,
    "mag": 8.34,
    "Absolute Visual Magnitude": 7.152,
    "Spectral Type": "G1/G2V",
    "Color Index": 0.667,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.89052,
    "y": -5.609453,
    "z": -6.73857,
    "vx": -6.5e-7,
    "vy": 0.00000333,
    "vz": -0.00000134,
    "rarad": 3.50186445724266,
    "decrad": -0.400589505431972,
    "pmrarad": -1.93343695805555e-7,
    "pmdecrad": -8.4163654e-8,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 65063,
    "lum": 0.120005182020426,
    "ra": 13.376137,
    "dec": -22.952088,
    "pmra": -39.88,
    "pmdec": -17.36,
    "rv": 0,
    "Asset Name": "ASTRID 001370",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Afro",
    "Outfit": "Sharky",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -6.5e-7,
    "y_1": 0.00000333,
    "z_1": -0.00000134,
    "Distance in Parsecs_1": 17.2801,
    "HYG Star ID_1": 65063,
    "Hipparcos Catalogue_1": 65269,
    "Henry Draper Catalogue_1": 116220
  },
  {
    "Astrid #": "ASTRID 001371",
    "HYG Star ID": 34271,
    "Hipparcos Catalogue": 34361,
    "Distance in Parsecs": 17.298,
    "mag": 11.11,
    "Absolute Visual Magnitude": 9.92,
    "Spectral Type": "M2V:",
    "Color Index": 1.44,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.665377,
    "y": 15.408235,
    "z": -6.327823,
    "vx": -0.00001379,
    "vy": -0.00001094,
    "vz": -0.00001647,
    "rarad": 1.86480522861766,
    "decrad": -0.37450554644836,
    "pmrarad": 9.46356304444444e-7,
    "pmdecrad": -0.000001022908384,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 34271,
    "lum": 0.0093756200692588,
    "var_min": 11.215,
    "var_max": 11.025,
    "ra": 7.123031,
    "dec": -21.457587,
    "pmra": 195.2,
    "pmdec": -210.99,
    "rv": 0,
    "Asset Name": "ASTRID 001371",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Headband",
    "Outfit": "Warlock",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00001379,
    "y_1": -0.00001094,
    "z_1": -0.00001647,
    "Distance in Parsecs_1": 17.298,
    "HYG Star ID_1": 34271,
    "Hipparcos Catalogue_1": 34361
  },
  {
    "Astrid #": "ASTRID 001372",
    "HYG Star ID": 119014,
    "Gliese Catalog": "Gl 580B",
    "Distance in Parsecs": 17.301,
    "mag": 7.35,
    "Absolute Visual Magnitude": 6.16,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.469851,
    "y": -12.946061,
    "z": -0.408219,
    "vx": -0.00007967,
    "vy": 0.00007183,
    "vz": -0.00004117,
    "rarad": 3.98737832122721,
    "decrad": -0.0235972430319,
    "pmrarad": -0.00000620081545567361,
    "pmdecrad": -0.000002380260638,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 2,
    "comp_primary": 74309,
    "base": "Gl 580",
    "lum": 0.299226463660818,
    "ra": 15.230663,
    "dec": -1.352022,
    "pmra": -1279.01,
    "pmdec": -490.96,
    "rv": 0,
    "Asset Name": "ASTRID 001372",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Mohawk",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00007967,
    "y_1": 0.00007183,
    "z_1": -0.00004117,
    "Distance in Parsecs_1": 17.301,
    "HYG Star ID_1": 119014,
    "Gliese Catalog_1": "Gl 580B"
  },
  {
    "Astrid #": "ASTRID 001373",
    "HYG Star ID": 53330,
    "Hipparcos Catalogue": 53486,
    "Henry Draper Catalogue": 94765,
    "Gliese Catalog": "NN 3633",
    "Distance in Parsecs": 17.304,
    "mag": 7.37,
    "Absolute Visual Magnitude": 6.179,
    "Spectral Type": "K0",
    "Color Index": 0.92,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.506116,
    "y": 4.69307,
    "z": 2.225227,
    "vx": 0.00000513,
    "vy": 0.00002109,
    "vz": -0.00000644,
    "rarad": 2.86457977142371,
    "decrad": 0.128953195750928,
    "pmrarad": -0.00000125343728970833,
    "pmdecrad": -3.75342751e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 53330,
    "lum": 0.29403565736725,
    "var": "GY",
    "var_min": 7.393,
    "var_max": 7.353,
    "ra": 10.941889,
    "dec": 7.388474,
    "pmra": -258.54,
    "pmdec": -77.42,
    "rv": 0,
    "Asset Name": "ASTRID 001373",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Chuckle",
    "Hair": "Pixie",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000513,
    "y_1": 0.00002109,
    "z_1": -0.00000644,
    "Distance in Parsecs_1": 17.304,
    "HYG Star ID_1": 53330,
    "Hipparcos Catalogue_1": 53486,
    "Henry Draper Catalogue_1": 94765,
    "Gliese Catalog_1": "NN 3633"
  },
  {
    "Astrid #": "ASTRID 001374",
    "HYG Star ID": 93530,
    "Hipparcos Catalogue": 93825,
    "Henry Draper Catalogue": 177474,
    "Harvard Revised Catalogue": 7226,
    "Gliese Catalog": "Gl 743.1A",
    "Distance in Parsecs": 17.304,
    "mag": 4.23,
    "Absolute Visual Magnitude": 3.039,
    "Spectral Type": "F7IV-V",
    "Color Index": 0.523,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.945759,
    "y": -13.231884,
    "z": -10.428815,
    "vx": -0.00000866,
    "vy": 0.00005748,
    "vz": 0.00001407,
    "rarad": 5.00219333988663,
    "decrad": -0.646879004936175,
    "pmrarad": 4.699299005625e-7,
    "pmdecrad": -0.00000135587842,
    "bayer": "Gam",
    "con": "CrA",
    "Full Constellation Name Formula 1": "Corona Australis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Australis",
    "comp": 1,
    "comp_primary": 93530,
    "base": "Gl 743.1",
    "lum": 5.30151506678458,
    "bf": "Gam CrA",
    "ra": 19.106971,
    "dec": -37.063437,
    "pmra": 96.93,
    "pmdec": -279.67,
    "rv": -53.2,
    "Asset Name": "ASTRID 001374",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Bandana",
    "Outfit": "Wizard",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Australis",
    "x_1": -0.00000866,
    "y_1": 0.00005748,
    "z_1": 0.00001407,
    "Distance in Parsecs_1": 17.304,
    "HYG Star ID_1": 93530,
    "Hipparcos Catalogue_1": 93825,
    "Henry Draper Catalogue_1": 177474,
    "Harvard Revised Catalogue_1": 7226,
    "Gliese Catalog_1": "Gl 743.1A"
  },
  {
    "Astrid #": "ASTRID 001375",
    "HYG Star ID": 39231,
    "Hipparcos Catalogue": 39342,
    "Henry Draper Catalogue": 67199,
    "Gliese Catalog": "NN 3476",
    "Distance in Parsecs": 17.313,
    "mag": 7.18,
    "Absolute Visual Magnitude": 5.988,
    "Spectral Type": "K1V",
    "Color Index": 0.872,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.584807,
    "y": 6.054352,
    "z": -15.818785,
    "vx": 0.00001646,
    "vy": -0.00000188,
    "vz": -0.00000445,
    "rarad": 2.10539957644885,
    "decrad": -1.15228276281463,
    "pmrarad": -7.62805844986111e-7,
    "pmdecrad": -6.32778815e-7,
    "con": "Vol",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Volans",
    "FULL CONSTELLATION NAME": "Volans",
    "comp": 1,
    "comp_primary": 39231,
    "lum": 0.350590383932612,
    "ra": 8.042034,
    "dec": -66.020939,
    "pmra": -157.34,
    "pmdec": -130.52,
    "rv": 0,
    "Asset Name": "ASTRID 001375",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Pixie",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Volans",
    "x_1": 0.00001646,
    "y_1": -0.00000188,
    "z_1": -0.00000445,
    "Distance in Parsecs_1": 17.313,
    "HYG Star ID_1": 39231,
    "Hipparcos Catalogue_1": 39342,
    "Henry Draper Catalogue_1": 67199,
    "Gliese Catalog_1": "NN 3476"
  },
  {
    "Astrid #": "ASTRID 001376",
    "HYG Star ID": 3198,
    "Hipparcos Catalogue": 3206,
    "Henry Draper Catalogue": 3765,
    "Gliese Catalog": "Gl 28",
    "Distance in Parsecs": 17.328,
    "mag": 7.36,
    "Absolute Visual Magnitude": 6.166,
    "Spectral Type": "K2V",
    "Color Index": 0.937,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.027701,
    "y": 2.345295,
    "z": 11.181157,
    "vx": -0.00001897,
    "vy": 0.00002694,
    "vz": -0.00008529,
    "rarad": 0.178111244795578,
    "decrad": 0.701398492037087,
    "pmrarad": 0.00000172443378036805,
    "pmdecrad": -0.00000324296719,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 3198,
    "lum": 0.29757743773728,
    "ra": 0.680335,
    "dec": 40.187173,
    "pmra": 355.69,
    "pmdec": -668.91,
    "rv": -64.2,
    "Asset Name": "ASTRID 001376",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Bob",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001897,
    "y_1": 0.00002694,
    "z_1": -0.00008529,
    "Distance in Parsecs_1": 17.328,
    "HYG Star ID_1": 3198,
    "Hipparcos Catalogue_1": 3206,
    "Henry Draper Catalogue_1": 3765,
    "Gliese Catalog_1": "Gl 28"
  },
  {
    "Astrid #": "ASTRID 001377",
    "HYG Star ID": 118748,
    "Gliese Catalog": "NN 3657",
    "Distance in Parsecs": 17.331,
    "mag": 15.14,
    "Absolute Visual Magnitude": 13.946,
    "Spectral Type": "m",
    "Color Index": 1.84,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.389775,
    "y": 2.496534,
    "z": 7.568733,
    "vx": 0.00000166,
    "vy": 0.00008472,
    "vz": -0.00002452,
    "rarad": 2.98080023510523,
    "decrad": 0.4519454299798,
    "pmrarad": -0.00000484139789539583,
    "pmdecrad": -0.000001573060404,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118748,
    "lum": 0.000229932308702122,
    "ra": 11.385818,
    "dec": 25.894566,
    "pmra": -998.61,
    "pmdec": -324.47,
    "rv": 0,
    "Asset Name": "ASTRID 001377",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Scared",
    "Hair": "Skater Helmet",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000166,
    "y_1": 0.00008472,
    "z_1": -0.00002452,
    "Distance in Parsecs_1": 17.331,
    "HYG Star ID_1": 118748,
    "Gliese Catalog_1": "NN 3657"
  },
  {
    "Astrid #": "ASTRID 001378",
    "HYG Star ID": 46376,
    "Hipparcos Catalogue": 46509,
    "Henry Draper Catalogue": 81997,
    "Harvard Revised Catalogue": 3759,
    "Gliese Catalog": "Gl 348A",
    "Distance in Parsecs": 17.334,
    "mag": 4.59,
    "Absolute Visual Magnitude": 3.396,
    "Spectral Type": "F6V",
    "Color Index": 0.411,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.696743,
    "y": 10.590983,
    "z": -0.837388,
    "vx": -0.00001391,
    "vy": 3e-8,
    "vz": -8e-7,
    "rarad": 2.48337791057141,
    "decrad": -0.0483275366267625,
    "pmrarad": 4.89322447784722e-7,
    "pmdecrad": -1.527163e-8,
    "bayer": "Tau-1",
    "flam": 31,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46376,
    "base": "Gl 348",
    "lum": 3.81592649118933,
    "bf": "31Tau1Hya",
    "ra": 9.485805,
    "dec": -2.768964,
    "pmra": 100.93,
    "pmdec": -3.15,
    "rv": 10.8,
    "Asset Name": "ASTRID 001378",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Good Boy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001391,
    "y_1": 3e-8,
    "z_1": -8e-7,
    "Distance in Parsecs_1": 17.334,
    "HYG Star ID_1": 46376,
    "Hipparcos Catalogue_1": 46509,
    "Henry Draper Catalogue_1": 81997,
    "Harvard Revised Catalogue_1": 3759,
    "Gliese Catalog_1": "Gl 348A"
  },
  {
    "Astrid #": "ASTRID 001379",
    "HYG Star ID": 17707,
    "Hipparcos Catalogue": 17749,
    "Henry Draper Catalogue": 23189,
    "Gliese Catalog": "Gl 153A",
    "Distance in Parsecs": 17.337,
    "mag": 9.17,
    "Absolute Visual Magnitude": 7.975,
    "Spectral Type": "K0",
    "Color Index": 1.314,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.43374,
    "y": 5.28837,
    "z": 16.149783,
    "vx": -0.00001826,
    "vy": -0.00000861,
    "vz": 0.00001142,
    "rarad": 0.994903719039306,
    "decrad": 1.19856767993671,
    "pmrarad": 6.12804492222222e-7,
    "pmdecrad": 0.00000116204991,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 17707,
    "base": "Gl 153",
    "lum": 0.0562341325190349,
    "var_min": 9.296,
    "var_max": 9.036,
    "ra": 3.800252,
    "dec": 68.67287,
    "pmra": 126.4,
    "pmdec": 239.69,
    "rv": 4.3,
    "Asset Name": "ASTRID 001379",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Bad Dreams",
    "Hair": "Goddess",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001826,
    "y_1": -0.00000861,
    "z_1": 0.00001142,
    "Distance in Parsecs_1": 17.337,
    "HYG Star ID_1": 17707,
    "Hipparcos Catalogue_1": 17749,
    "Henry Draper Catalogue_1": 23189,
    "Gliese Catalog_1": "Gl 153A"
  },
  {
    "Astrid #": "ASTRID 001380",
    "HYG Star ID": 77565,
    "Hipparcos Catalogue": 77801,
    "Henry Draper Catalogue": 142267,
    "Harvard Revised Catalogue": 5911,
    "Gliese Catalog": "NN 3924",
    "Distance in Parsecs": 17.3491,
    "mag": 6.07,
    "Absolute Visual Magnitude": 4.874,
    "Spectral Type": "G0IV",
    "Color Index": 0.598,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.875775,
    "y": -14.371331,
    "z": 3.960759,
    "vx": -0.00003482,
    "vy": -0.00003227,
    "vz": -0.00003788,
    "rarad": 4.15912685106457,
    "decrad": 0.230324520016633,
    "pmrarad": -7.29935477444444e-7,
    "pmdecrad": -0.000002727998099,
    "flam": 39,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 77565,
    "lum": 0.978137704322986,
    "bf": "39    Ser",
    "ra": 15.886694,
    "dec": 13.196623,
    "pmra": -150.56,
    "pmdec": -562.69,
    "rv": 35.1,
    "Asset Name": "ASTRID 001380",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Spiky",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00003482,
    "y_1": -0.00003227,
    "z_1": -0.00003788,
    "Distance in Parsecs_1": 17.3491,
    "HYG Star ID_1": 77565,
    "Hipparcos Catalogue_1": 77801,
    "Henry Draper Catalogue_1": 142267,
    "Harvard Revised Catalogue_1": 5911,
    "Gliese Catalog_1": "NN 3924"
  },
  {
    "Astrid #": "ASTRID 001381",
    "HYG Star ID": 84636,
    "Hipparcos Catalogue": 84893,
    "Henry Draper Catalogue": 156897,
    "Harvard Revised Catalogue": 6445,
    "Gliese Catalog": "Gl 670A",
    "Distance in Parsecs": 17.3551,
    "mag": 4.39,
    "Absolute Visual Magnitude": 3.193,
    "Spectral Type": "F2/F3V",
    "Color Index": 0.394,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.741337,
    "y": -15.956245,
    "z": -6.251407,
    "vx": 0.0000242,
    "vy": 0.00001066,
    "vz": -0.00001283,
    "rarad": 4.54224577877783,
    "decrad": -0.368490188315878,
    "pmrarad": 0.00000127026032442361,
    "pmdecrad": -9.92607529e-7,
    "bayer": "Xi",
    "flam": 40,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84636,
    "base": "Gl 670",
    "lum": 4.60044666393477,
    "bf": "40Xi  Oph",
    "ra": 17.350101,
    "dec": -21.112933,
    "pmra": 262.01,
    "pmdec": -204.74,
    "rv": -8.8,
    "Asset Name": "ASTRID 001381",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Maniacal",
    "Hair": "Lion Mane",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.0000242,
    "y_1": 0.00001066,
    "z_1": -0.00001283,
    "Distance in Parsecs_1": 17.3551,
    "HYG Star ID_1": 84636,
    "Hipparcos Catalogue_1": 84893,
    "Henry Draper Catalogue_1": 156897,
    "Harvard Revised Catalogue_1": 6445,
    "Gliese Catalog_1": "Gl 670A"
  },
  {
    "Astrid #": "ASTRID 001382",
    "HYG Star ID": 118226,
    "Gliese Catalog": "GJ 1060A",
    "Distance in Parsecs": 17.3611,
    "mag": 14,
    "Absolute Visual Magnitude": 12.802,
    "Spectral Type": "DA5",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.449172,
    "y": 12.193084,
    "z": -7.965528,
    "vx": -0.00004079,
    "vy": 0.00004959,
    "vz": 0.0000275,
    "rarad": 0.911487635081713,
    "decrad": -0.4766607111269,
    "pmrarad": 0.00000360740163427777,
    "pmdecrad": 0.000001782897462,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118226,
    "base": "GJ 1060",
    "lum": 0.000659477525949484,
    "ra": 3.481626,
    "dec": -27.310647,
    "pmra": 744.08,
    "pmdec": 367.75,
    "rv": 0,
    "Asset Name": "ASTRID 001382",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Sweet Nap",
    "Hair": "Ponytail",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004079,
    "y_1": 0.00004959,
    "z_1": 0.0000275,
    "Distance in Parsecs_1": 17.3611,
    "HYG Star ID_1": 118226,
    "Gliese Catalog_1": "GJ 1060A"
  },
  {
    "Astrid #": "ASTRID 001383",
    "HYG Star ID": 118227,
    "Gliese Catalog": "GJ 1060B",
    "Distance in Parsecs": 17.3611,
    "mag": 13.8,
    "Absolute Visual Magnitude": 12.602,
    "Spectral Type": "sdM3",
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": 9.449032,
    "y": 12.1929,
    "z": -7.965976,
    "vx": -0.00004079,
    "vy": 0.0000496,
    "vz": 0.0000275,
    "rarad": 0.911487494081713,
    "decrad": -0.4766898001269,
    "pmrarad": 0.00000360740163427777,
    "pmdecrad": 0.000001782897462,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118226,
    "base": "GJ 1060",
    "lum": 0.000792866374878546,
    "ra": 3.481626,
    "dec": -27.312314,
    "pmra": 744.08,
    "pmdec": 367.75,
    "rv": 0,
    "Asset Name": "ASTRID 001383",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Charmer",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004079,
    "y_1": 0.0000496,
    "z_1": 0.0000275,
    "Distance in Parsecs_1": 17.3611,
    "HYG Star ID_1": 118227,
    "Gliese Catalog_1": "GJ 1060B"
  },
  {
    "Astrid #": "ASTRID 001384",
    "HYG Star ID": 62014,
    "Hipparcos Catalogue": 62207,
    "Henry Draper Catalogue": 110897,
    "Harvard Revised Catalogue": 4845,
    "Gliese Catalog": "Gl 484",
    "Distance in Parsecs": 17.3762,
    "mag": 5.95,
    "Absolute Visual Magnitude": 4.75,
    "Spectral Type": "G0V",
    "Color Index": 0.557,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.192666,
    "y": -2.623591,
    "z": 11.001261,
    "vx": -0.00006128,
    "vy": 0.00001871,
    "vz": 0.00006134,
    "rarad": 3.33790338297436,
    "decrad": 0.685546414121166,
    "pmrarad": -0.00000174411721579861,
    "pmdecrad": 6.75442419e-7,
    "flam": 10,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 62014,
    "lum": 1.09647819614318,
    "bf": "10    CVn",
    "ra": 12.749852,
    "dec": 39.278916,
    "pmra": -359.75,
    "pmdec": 139.32,
    "rv": 80.7,
    "Asset Name": "ASTRID 001384",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Frizzy",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00006128,
    "y_1": 0.00001871,
    "z_1": 0.00006134,
    "Distance in Parsecs_1": 17.3762,
    "HYG Star ID_1": 62014,
    "Hipparcos Catalogue_1": 62207,
    "Henry Draper Catalogue_1": 110897,
    "Harvard Revised Catalogue_1": 4845,
    "Gliese Catalog_1": "Gl 484"
  },
  {
    "Astrid #": "ASTRID 001385",
    "HYG Star ID": 65670,
    "Hipparcos Catalogue": 65877,
    "Gliese Catalog": "Gl 515",
    "Distance in Parsecs": 17.3762,
    "mag": 12.31,
    "Absolute Visual Magnitude": 11.11,
    "Spectral Type": "DA",
    "Color Index": 0.026,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -15.86784,
    "y": -6.591122,
    "z": -2.590866,
    "vx": -0.00006482,
    "vy": 0.00007406,
    "vz": -0.00004524,
    "rarad": 3.53528417752326,
    "decrad": -0.149659515309708,
    "pmrarad": -0.00000536771162700694,
    "pmdecrad": -0.000002304949681,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 65670,
    "lum": 0.00313328572431558,
    "var_min": 12.42,
    "var_max": 11.91,
    "ra": 13.503791,
    "dec": -8.574859,
    "pmra": -1107.17,
    "pmdec": -475.43,
    "rv": 37,
    "Asset Name": "ASTRID 001385",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Serious Shinobi",
    "Hair": "Audiohead",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006482,
    "y_1": 0.00007406,
    "z_1": -0.00004524,
    "Distance in Parsecs_1": 17.3762,
    "HYG Star ID_1": 65670,
    "Hipparcos Catalogue_1": 65877,
    "Gliese Catalog_1": "Gl 515"
  },
  {
    "Astrid #": "ASTRID 001386",
    "HYG Star ID": 118255,
    "Gliese Catalog": "Gl 153B",
    "Distance in Parsecs": 17.3822,
    "mag": 11.3,
    "Absolute Visual Magnitude": 10.099,
    "Spectral Type": "M2.5 J",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.441942,
    "y": 5.300133,
    "z": 16.192839,
    "vx": -0.00001894,
    "vy": -0.00001254,
    "vz": 0.00001154,
    "rarad": 0.994792743159398,
    "decrad": 1.19870045485335,
    "pmrarad": 5.21223187965277e-7,
    "pmdecrad": 0.000001357837067,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 2,
    "comp_primary": 17707,
    "base": "Gl 153",
    "lum": 0.00795060175084419,
    "ra": 3.799828,
    "dec": 68.680477,
    "pmra": 107.51,
    "pmdec": 280.07,
    "rv": 3.1,
    "Asset Name": "ASTRID 001386",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Fedora",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001894,
    "y_1": -0.00001254,
    "z_1": 0.00001154,
    "Distance in Parsecs_1": 17.3822,
    "HYG Star ID_1": 118255,
    "Gliese Catalog_1": "Gl 153B"
  },
  {
    "Astrid #": "ASTRID 001387",
    "HYG Star ID": 118256,
    "Gliese Catalog": "Gl 153C",
    "Distance in Parsecs": 17.3822,
    "mag": 11.6,
    "Absolute Visual Magnitude": 10.399,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.441911,
    "y": 5.300085,
    "z": 16.192691,
    "vx": -0.00001957,
    "vy": -0.0000135,
    "vz": 0.00000858,
    "rarad": 0.994792743159398,
    "decrad": 1.19870045485335,
    "pmrarad": 5.21223187965277e-7,
    "pmdecrad": 0.000001357837067,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 3,
    "comp_primary": 17707,
    "base": "Gl 153",
    "lum": 0.00603114819617816,
    "ra": 3.799828,
    "dec": 68.680477,
    "pmra": 107.51,
    "pmdec": 280.07,
    "rv": 0,
    "Asset Name": "ASTRID 001387",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Pirate Hat",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001957,
    "y_1": -0.0000135,
    "z_1": 0.00000858,
    "Distance in Parsecs_1": 17.3822,
    "HYG Star ID_1": 118256,
    "Gliese Catalog_1": "Gl 153C"
  },
  {
    "Astrid #": "ASTRID 001388",
    "HYG Star ID": 43602,
    "Hipparcos Catalogue": 43726,
    "Henry Draper Catalogue": 76151,
    "Harvard Revised Catalogue": 3538,
    "Gliese Catalog": "Gl 327",
    "Distance in Parsecs": 17.3853,
    "mag": 6.01,
    "Absolute Visual Magnitude": 4.809,
    "Spectral Type": "G3V",
    "Color Index": 0.661,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.930031,
    "y": 12.538813,
    "z": -1.646538,
    "vx": 0.00000274,
    "vy": 0.00004763,
    "vz": -5.3e-7,
    "rarad": 2.33131980810665,
    "decrad": -0.094849210698765,
    "pmrarad": -0.00000200295923984722,
    "pmdecrad": 1.47577284e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 43602,
    "lum": 1.03848445500827,
    "ra": 8.904986,
    "dec": -5.434459,
    "pmra": -413.14,
    "pmdec": 30.44,
    "rv": 31.8,
    "Asset Name": "ASTRID 001388",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Bob",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000274,
    "y_1": 0.00004763,
    "z_1": -5.3e-7,
    "Distance in Parsecs_1": 17.3853,
    "HYG Star ID_1": 43602,
    "Hipparcos Catalogue_1": 43726,
    "Henry Draper Catalogue_1": 76151,
    "Harvard Revised Catalogue_1": 3538,
    "Gliese Catalog_1": "Gl 327"
  },
  {
    "Astrid #": "ASTRID 001389",
    "HYG Star ID": 96788,
    "Hipparcos Catalogue": 97096,
    "Distance in Parsecs": 17.3974,
    "mag": 9.29,
    "Absolute Visual Magnitude": 8.088,
    "Spectral Type": "G",
    "Color Index": 1.025,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.064827,
    "y": -6.28727,
    "z": -15.929426,
    "vx": -0.0000087,
    "vy": 0.00001415,
    "vz": -0.00000726,
    "rarad": 5.16596000996774,
    "decrad": -1.15704993280249,
    "pmrarad": -9.3375114875e-8,
    "pmdecrad": -0.000001038034571,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 96788,
    "lum": 0.0506757284177282,
    "ra": 19.732514,
    "dec": -66.294078,
    "pmra": -19.26,
    "pmdec": -214.11,
    "rv": 0,
    "Asset Name": "ASTRID 001389",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Owie",
    "Hair": "Bob",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.0000087,
    "y_1": 0.00001415,
    "z_1": -0.00000726,
    "Distance in Parsecs_1": 17.3974,
    "HYG Star ID_1": 96788,
    "Hipparcos Catalogue_1": 97096
  },
  {
    "Astrid #": "ASTRID 001390",
    "HYG Star ID": 119147,
    "Gliese Catalog": "Gl 670B",
    "Distance in Parsecs": 17.3974,
    "mag": 8.9,
    "Absolute Visual Magnitude": 7.698,
    "Spectral Type": "K3",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.74869,
    "y": -15.994834,
    "z": -6.267336,
    "vx": 0.00002022,
    "vy": 0.00000292,
    "vz": -0.00001632,
    "rarad": 4.54219868826379,
    "decrad": -0.36853111345085,
    "pmrarad": 0.00000111686527589583,
    "pmdecrad": -0.000001005649017,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 2,
    "comp_primary": 84636,
    "base": "Gl 670",
    "lum": 0.0725771650267427,
    "ra": 17.349921,
    "dec": -21.115277,
    "pmra": 230.37,
    "pmdec": -207.43,
    "rv": 0,
    "Asset Name": "ASTRID 001390",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Shinobi",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00002022,
    "y_1": 0.00000292,
    "z_1": -0.00001632,
    "Distance in Parsecs_1": 17.3974,
    "HYG Star ID_1": 119147,
    "Gliese Catalog_1": "Gl 670B"
  },
  {
    "Astrid #": "ASTRID 001391",
    "HYG Star ID": 33963,
    "Hipparcos Catalogue": 34052,
    "Henry Draper Catalogue": 53680,
    "Gliese Catalog": "Gl 264",
    "Distance in Parsecs": 17.4155,
    "mag": 8.67,
    "Absolute Visual Magnitude": 7.465,
    "Spectral Type": "K5V...",
    "Color Index": 1.18,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.470064,
    "y": 12.134061,
    "z": -12.002095,
    "vx": -0.00001823,
    "vy": 0.0000869,
    "vz": -0.00003747,
    "rarad": 1.8493401511691,
    "decrad": -0.760288415525207,
    "pmrarad": -3.65694959243055e-7,
    "pmdecrad": 0.000001945654262,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 33963,
    "lum": 0.0899497581530035,
    "ra": 7.063959,
    "dec": -43.561317,
    "pmra": -75.43,
    "pmdec": 401.32,
    "rv": 88,
    "Asset Name": "ASTRID 001391",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Braid",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001823,
    "y_1": 0.0000869,
    "z_1": -0.00003747,
    "Distance in Parsecs_1": 17.4155,
    "HYG Star ID_1": 33963,
    "Hipparcos Catalogue_1": 34052,
    "Henry Draper Catalogue_1": 53680,
    "Gliese Catalog_1": "Gl 264"
  },
  {
    "Astrid #": "ASTRID 001392",
    "HYG Star ID": 15760,
    "Hipparcos Catalogue": 15799,
    "Henry Draper Catalogue": 21175,
    "Gliese Catalog": "NN 3222A",
    "Distance in Parsecs": 17.4216,
    "mag": 6.9,
    "Absolute Visual Magnitude": 5.695,
    "Spectral Type": "K0V",
    "Color Index": 0.84,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.407955,
    "y": 10.344851,
    "z": -11.216172,
    "vx": -0.00000122,
    "vy": 0.00000395,
    "vz": 0.00000273,
    "rarad": 0.88831833342166,
    "decrad": -0.699464943401277,
    "pmrarad": 1.97076761145833e-7,
    "pmdecrad": 2.04639854e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 15760,
    "base": "NN 3222",
    "lum": 0.459198012836868,
    "ra": 3.393126,
    "dec": -40.076389,
    "pmra": 40.65,
    "pmdec": 42.21,
    "rv": 0,
    "Asset Name": "ASTRID 001392",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Bring It",
    "Hair": "Little Crown",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000122,
    "y_1": 0.00000395,
    "z_1": 0.00000273,
    "Distance in Parsecs_1": 17.4216,
    "HYG Star ID_1": 15760,
    "Hipparcos Catalogue_1": 15799,
    "Henry Draper Catalogue_1": 21175,
    "Gliese Catalog_1": "NN 3222A"
  },
  {
    "Astrid #": "ASTRID 001393",
    "HYG Star ID": 118031,
    "Gliese Catalog": "GJ 1024",
    "Distance in Parsecs": 17.4216,
    "mag": 13.71,
    "Absolute Visual Magnitude": 12.505,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.113878,
    "y": 4.065934,
    "z": 5.227168,
    "vx": -0.00000642,
    "vy": 0.00005571,
    "vz": -0.0000235,
    "rarad": 0.247158051726681,
    "decrad": 0.3047340476823,
    "pmrarad": 0.000003191334533625,
    "pmdecrad": -0.000001414206354,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118031,
    "lum": 0.000866961875758215,
    "ra": 0.944074,
    "dec": 17.459975,
    "pmra": 658.26,
    "pmdec": -291.7,
    "rv": 0,
    "Asset Name": "ASTRID 001393",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Spiky",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000642,
    "y_1": 0.00005571,
    "z_1": -0.0000235,
    "Distance in Parsecs_1": 17.4216,
    "HYG Star ID_1": 118031,
    "Gliese Catalog_1": "GJ 1024"
  },
  {
    "Astrid #": "ASTRID 001394",
    "HYG Star ID": 69304,
    "Hipparcos Catalogue": 69526,
    "Henry Draper Catalogue": 124642,
    "Gliese Catalog": "NN 3833",
    "Distance in Parsecs": 17.4277,
    "mag": 8.03,
    "Absolute Visual Magnitude": 6.824,
    "Spectral Type": "K5",
    "Color Index": 1.064,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.559829,
    "y": -8.309332,
    "z": 8.770999,
    "vx": -0.00001233,
    "vy": 0.00003216,
    "vz": 0.0000128,
    "rarad": 3.72606811451515,
    "decrad": 0.527389406253747,
    "pmrarad": -0.00000192965541134722,
    "pmdecrad": 8.50314714e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 69304,
    "lum": 0.162330452776545,
    "ra": 14.232532,
    "dec": 30.217187,
    "pmra": -398.02,
    "pmdec": 175.39,
    "rv": 0,
    "Asset Name": "ASTRID 001394",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Pompadour",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00001233,
    "y_1": 0.00003216,
    "z_1": 0.0000128,
    "Distance in Parsecs_1": 17.4277,
    "HYG Star ID_1": 69304,
    "Hipparcos Catalogue_1": 69526,
    "Henry Draper Catalogue_1": 124642,
    "Gliese Catalog_1": "NN 3833"
  },
  {
    "Astrid #": "ASTRID 001395",
    "HYG Star ID": 7963,
    "Hipparcos Catalogue": 7978,
    "Henry Draper Catalogue": 10647,
    "Harvard Revised Catalogue": 506,
    "Gliese Catalog": "NN 3109",
    "Distance in Parsecs": 17.4338,
    "mag": 5.52,
    "Absolute Visual Magnitude": 4.313,
    "Spectral Type": "F8V",
    "Color Index": 0.551,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.297155,
    "y": 4.45887,
    "z": -14.057838,
    "vx": -0.00000562,
    "vy": 0.00001295,
    "vz": -0.00001597,
    "rarad": 0.447185962067271,
    "decrad": -0.937954495765932,
    "pmrarad": 8.09493402423611e-7,
    "pmdecrad": -5.17344678e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 7963,
    "lum": 1.63983442762653,
    "ra": 1.708125,
    "dec": -53.740834,
    "pmra": 166.97,
    "pmdec": -106.71,
    "rv": 12.9,
    "Asset Name": "ASTRID 001395",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "Ponytail",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000562,
    "y_1": 0.00001295,
    "z_1": -0.00001597,
    "Distance in Parsecs_1": 17.4338,
    "HYG Star ID_1": 7963,
    "Hipparcos Catalogue_1": 7978,
    "Henry Draper Catalogue_1": 10647,
    "Harvard Revised Catalogue_1": 506,
    "Gliese Catalog_1": "NN 3109"
  },
  {
    "Astrid #": "ASTRID 001396",
    "HYG Star ID": 76593,
    "Hipparcos Catalogue": 76829,
    "Henry Draper Catalogue": 139664,
    "Harvard Revised Catalogue": 5825,
    "Gliese Catalog": "Gl 594",
    "Distance in Parsecs": 17.4368,
    "mag": 4.64,
    "Absolute Visual Magnitude": 3.433,
    "Spectral Type": "F5IV-V",
    "Color Index": 0.413,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.060857,
    "y": -10.196187,
    "z": -12.256524,
    "vx": -5e-7,
    "vy": 0.00002433,
    "vz": -0.00001209,
    "rarad": 4.10671735154405,
    "decrad": -0.779485073887836,
    "pmrarad": -8.17880679097222e-7,
    "pmdecrad": -0.000001288101467,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 76593,
    "lum": 3.68807717226154,
    "ra": 15.686505,
    "dec": -44.661205,
    "pmra": -168.7,
    "pmdec": -265.69,
    "rv": -5.4,
    "Asset Name": "ASTRID 001396",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "High Bun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -5e-7,
    "y_1": 0.00002433,
    "z_1": -0.00001209,
    "Distance in Parsecs_1": 17.4368,
    "HYG Star ID_1": 76593,
    "Hipparcos Catalogue_1": 76829,
    "Henry Draper Catalogue_1": 139664,
    "Harvard Revised Catalogue_1": 5825,
    "Gliese Catalog_1": "Gl 594"
  },
  {
    "Astrid #": "ASTRID 001397",
    "HYG Star ID": 31925,
    "Hipparcos Catalogue": 32010,
    "Henry Draper Catalogue": 47752,
    "Gliese Catalog": "Gl 241",
    "Distance in Parsecs": 17.4459,
    "mag": 8.08,
    "Absolute Visual Magnitude": 6.872,
    "Spectral Type": "K2",
    "Color Index": 1.021,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.854786,
    "y": 15.684808,
    "z": 7.083961,
    "vx": -0.00001138,
    "vy": -0.00003478,
    "vz": -0.00003992,
    "rarad": 1.7508343101908,
    "decrad": 0.418141008115811,
    "pmrarad": 9.987646633125e-7,
    "pmdecrad": -0.000001342158193,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 31925,
    "lum": 0.155310207523284,
    "ra": 6.687694,
    "dec": 23.957715,
    "pmra": 206.01,
    "pmdec": -276.84,
    "rv": -44.6,
    "Asset Name": "ASTRID 001397",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Pirate Hat",
    "Outfit": "Astro",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00001138,
    "y_1": -0.00003478,
    "z_1": -0.00003992,
    "Distance in Parsecs_1": 17.4459,
    "HYG Star ID_1": 31925,
    "Hipparcos Catalogue_1": 32010,
    "Henry Draper Catalogue_1": 47752,
    "Gliese Catalog_1": "Gl 241"
  },
  {
    "Astrid #": "ASTRID 001398",
    "HYG Star ID": 119130,
    "Gliese Catalog": "GJ 1211",
    "Distance in Parsecs": 17.452,
    "mag": 15.19,
    "Absolute Visual Magnitude": 13.981,
    "Spectral Type": "DZ7",
    "Color Index": 0.46,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.911609,
    "y": -16.984121,
    "z": 0.90069,
    "vx": 0.00000157,
    "vy": -0.00000184,
    "vz": -0.00002781,
    "rarad": 4.48602640920546,
    "decrad": 0.05163247863785,
    "pmrarad": 1.11604109263888e-7,
    "pmdecrad": -0.000001595987243,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119130,
    "lum": 0.00022263836295529,
    "ra": 17.135359,
    "dec": 2.958323,
    "pmra": 23.02,
    "pmdec": -329.2,
    "rv": 0,
    "Asset Name": "ASTRID 001398",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Lion Mane",
    "Outfit": "Boxer",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000157,
    "y_1": -0.00000184,
    "z_1": -0.00002781,
    "Distance in Parsecs_1": 17.452,
    "HYG Star ID_1": 119130,
    "Gliese Catalog_1": "GJ 1211"
  },
  {
    "Astrid #": "ASTRID 001399",
    "HYG Star ID": 118511,
    "Henry Draper Catalogue": 65277,
    "Gliese Catalog": "Gl 293.1A",
    "Distance in Parsecs": 17.4642,
    "mag": 8.06,
    "Absolute Visual Magnitude": 6.849,
    "Spectral Type": "K5 V",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.595897,
    "y": 15.200011,
    "z": -0.247697,
    "vx": 0.00001528,
    "vy": 0.00000394,
    "vz": 6e-8,
    "rarad": 2.08547626276875,
    "decrad": -0.014183803,
    "pmrarad": -8.72664624999999e-7,
    "pmdecrad": 0,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 118511,
    "base": "Gl 293.1",
    "lum": 0.15863536054785,
    "ra": 7.965933,
    "dec": -0.812672,
    "pmra": -180,
    "pmdec": 0,
    "rv": -4,
    "Asset Name": "ASTRID 001399",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Serious Slumber",
    "Hair": "Braid",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00001528,
    "y_1": 0.00000394,
    "z_1": 6e-8,
    "Distance in Parsecs_1": 17.4642,
    "HYG Star ID_1": 118511,
    "Henry Draper Catalogue_1": 65277,
    "Gliese Catalog_1": "Gl 293.1A"
  },
  {
    "Astrid #": "ASTRID 001400",
    "HYG Star ID": 57334,
    "Hipparcos Catalogue": 57507,
    "Henry Draper Catalogue": 102438,
    "Harvard Revised Catalogue": 4525,
    "Gliese Catalog": "Gl 446",
    "Distance in Parsecs": 17.4734,
    "mag": 6.48,
    "Absolute Visual Magnitude": 5.268,
    "Spectral Type": "G5V",
    "Color Index": 0.681,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.065328,
    "y": 0.838098,
    "z": -8.812314,
    "vx": 5.5e-7,
    "vy": 0.00002248,
    "vz": -0.00002273,
    "rarad": 3.08602082908063,
    "decrad": -0.528599320398108,
    "pmrarad": -0.00000128616221314583,
    "pmdecrad": -0.000001102902641,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 57334,
    "lum": 0.680455924994637,
    "ra": 11.787731,
    "dec": -30.28651,
    "pmra": -265.29,
    "pmdec": -227.49,
    "rv": 11.8,
    "Asset Name": "ASTRID 001400",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Sweet Nap",
    "Hair": "Round Brush",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 5.5e-7,
    "y_1": 0.00002248,
    "z_1": -0.00002273,
    "Distance in Parsecs_1": 17.4734,
    "HYG Star ID_1": 57334,
    "Hipparcos Catalogue_1": 57507,
    "Henry Draper Catalogue_1": 102438,
    "Harvard Revised Catalogue_1": 4525,
    "Gliese Catalog_1": "Gl 446"
  },
  {
    "Astrid #": "ASTRID 001401",
    "HYG Star ID": 119326,
    "Gliese Catalog": "NN 4133",
    "Distance in Parsecs": 17.4825,
    "mag": 16.87,
    "Absolute Visual Magnitude": 15.657,
    "Spectral Type": "DC9",
    "Color Index": 1.04,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.941209,
    "y": -14.651493,
    "z": -3.3206,
    "vx": 0.00007708,
    "vy": 0.00004875,
    "vz": -0.00000768,
    "rarad": 5.26031312988048,
    "decrad": -0.191099479031,
    "pmrarad": 0.00000521683760961805,
    "pmdecrad": -4.4724062e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119326,
    "lum": 0.0000475554176315208,
    "ra": 20.092916,
    "dec": -10.949194,
    "pmra": 1076.05,
    "pmdec": -92.25,
    "rv": 0,
    "Asset Name": "ASTRID 001401",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Pixie",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00007708,
    "y_1": 0.00004875,
    "z_1": -0.00000768,
    "Distance in Parsecs_1": 17.4825,
    "HYG Star ID_1": 119326,
    "Gliese Catalog_1": "NN 4133"
  },
  {
    "Astrid #": "ASTRID 001402",
    "HYG Star ID": 118094,
    "Gliese Catalog": "Gl 81B",
    "Distance in Parsecs": 17.4856,
    "mag": 10.7,
    "Absolute Visual Magnitude": 9.487,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.498609,
    "y": 5.263072,
    "z": -13.704842,
    "vx": -0.00001036,
    "vy": 0.00005982,
    "vz": 0.00001578,
    "rarad": 0.50587686134779,
    "decrad": -0.9007272784597,
    "pmrarad": 0.00000328000695579861,
    "pmdecrad": 0.000001453490806,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 2,
    "comp_primary": 8989,
    "base": "Gl  81",
    "lum": 0.0139701156054122,
    "ra": 1.932307,
    "dec": -51.607872,
    "pmra": 676.55,
    "pmdec": 299.8,
    "rv": 0,
    "Asset Name": "ASTRID 001402",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Prince",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001036,
    "y_1": 0.00005982,
    "z_1": 0.00001578,
    "Distance in Parsecs_1": 17.4856,
    "HYG Star ID_1": 118094,
    "Gliese Catalog_1": "Gl 81B"
  },
  {
    "Astrid #": "ASTRID 001403",
    "HYG Star ID": 118690,
    "Gliese Catalog": "Gl 397.1B",
    "Distance in Parsecs": 17.4886,
    "mag": 16.2,
    "Absolute Visual Magnitude": 14.986,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.802786,
    "y": 3.578632,
    "z": 14.681807,
    "vx": 0.00001808,
    "vy": 0.00000577,
    "vz": 0.00000943,
    "rarad": 2.75549533428317,
    "decrad": 0.996375850012,
    "pmrarad": -6.95174336548611e-7,
    "pmdecrad": 9.9284024e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 51399,
    "base": "Gl 397.1",
    "lum": 0.0000882266928217716,
    "ra": 10.525217,
    "dec": 57.088131,
    "pmra": -143.39,
    "pmdec": 204.79,
    "rv": 0,
    "Asset Name": "ASTRID 001403",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Middle Part",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001808,
    "y_1": 0.00000577,
    "z_1": 0.00000943,
    "Distance in Parsecs_1": 17.4886,
    "HYG Star ID_1": 118690,
    "Gliese Catalog_1": "Gl 397.1B"
  },
  {
    "Astrid #": "ASTRID 001404",
    "HYG Star ID": 1291,
    "Hipparcos Catalogue": 1292,
    "Henry Draper Catalogue": 1237,
    "Gliese Catalog": "NN 3021",
    "Distance in Parsecs": 17.4978,
    "mag": 6.59,
    "Absolute Visual Magnitude": 5.375,
    "Spectral Type": "G6V",
    "Color Index": 0.749,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.075498,
    "y": 0.217909,
    "z": -17.224019,
    "vx": -0.00000742,
    "vy": 0.00003637,
    "vz": -8.7e-7,
    "rarad": 0.0706490542847634,
    "decrad": -1.39366602930836,
    "pmrarad": 0.00000210346111582638,
    "pmdecrad": -2.80949527e-7,
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 1291,
    "lum": 0.616595001861482,
    "ra": 0.26986,
    "dec": -79.851182,
    "pmra": 433.87,
    "pmdec": -57.95,
    "rv": 0,
    "Asset Name": "ASTRID 001404",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Goddess",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": -0.00000742,
    "y_1": 0.00003637,
    "z_1": -8.7e-7,
    "Distance in Parsecs_1": 17.4978,
    "HYG Star ID_1": 1291,
    "Hipparcos Catalogue_1": 1292,
    "Henry Draper Catalogue_1": 1237,
    "Gliese Catalog_1": "NN 3021"
  },
  {
    "Astrid #": "ASTRID 001405",
    "HYG Star ID": 80400,
    "Hipparcos Catalogue": 80644,
    "Henry Draper Catalogue": 148467,
    "Gliese Catalog": "Gl 626",
    "Distance in Parsecs": 17.5193,
    "mag": 8.83,
    "Absolute Visual Magnitude": 7.612,
    "Spectral Type": "K5",
    "Color Index": 1.253,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.793243,
    "y": -15.993878,
    "z": 2.227696,
    "vx": -0.00000654,
    "vy": 0.00003828,
    "vz": -0.00002665,
    "rarad": 4.3107384877222,
    "decrad": 0.127504145950536,
    "pmrarad": -0.00000119797460465277,
    "pmdecrad": -0.000001272005653,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 80400,
    "lum": 0.0785597332260989,
    "ra": 16.465808,
    "dec": 7.305449,
    "pmra": -247.1,
    "pmdec": -262.37,
    "rv": -35,
    "Asset Name": "ASTRID 001405",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Bandana",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000654,
    "y_1": 0.00003828,
    "z_1": -0.00002665,
    "Distance in Parsecs_1": 17.5193,
    "HYG Star ID_1": 80400,
    "Hipparcos Catalogue_1": 80644,
    "Henry Draper Catalogue_1": 148467,
    "Gliese Catalog_1": "Gl 626"
  },
  {
    "Astrid #": "ASTRID 001406",
    "HYG Star ID": 112441,
    "Hipparcos Catalogue": 112800,
    "Gliese Catalog": "GJ 1274",
    "Distance in Parsecs": 17.5193,
    "mag": 11.72,
    "Absolute Visual Magnitude": 10.502,
    "Color Index": 1.523,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 13.722599,
    "y": -4.285387,
    "z": 10.012572,
    "vx": 0.00000676,
    "vy": 0.00007296,
    "vz": 0.00002196,
    "rarad": 5.98048686661237,
    "decrad": 0.608352959464456,
    "pmrarad": 0.00000409046998558333,
    "pmdecrad": 0.000001527938795,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112441,
    "lum": 0.00548529513941202,
    "var_min": 11.799,
    "var_max": 11.639,
    "ra": 22.843777,
    "dec": 34.856057,
    "pmra": 843.72,
    "pmdec": 315.16,
    "rv": 0,
    "Asset Name": "ASTRID 001406",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Charmer",
    "Outfit": "Astro",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000676,
    "y_1": 0.00007296,
    "z_1": 0.00002196,
    "Distance in Parsecs_1": 17.5193,
    "HYG Star ID_1": 112441,
    "Hipparcos Catalogue_1": 112800,
    "Gliese Catalog_1": "GJ 1274"
  },
  {
    "Astrid #": "ASTRID 001407",
    "HYG Star ID": 118611,
    "Gliese Catalog": "Gl 340B",
    "Distance in Parsecs": 17.5285,
    "mag": 8.1,
    "Absolute Visual Magnitude": 6.881,
    "Spectral Type": "K3 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.702339,
    "y": 10.003493,
    "z": 8.380554,
    "vx": -0.00001861,
    "vy": 0.00000995,
    "vz": -0.00003785,
    "rarad": 2.43430023870865,
    "decrad": 0.4985017917962,
    "pmrarad": 2.58454173104166e-7,
    "pmdecrad": -0.000002459004076,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 2,
    "comp_primary": 45486,
    "base": "Gl 340",
    "lum": 0.154028114807161,
    "ra": 9.298342,
    "dec": 28.562049,
    "pmra": 53.31,
    "pmdec": -507.21,
    "rv": 0,
    "Asset Name": "ASTRID 001407",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Prince",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00001861,
    "y_1": 0.00000995,
    "z_1": -0.00003785,
    "Distance in Parsecs_1": 17.5285,
    "HYG Star ID_1": 118611,
    "Gliese Catalog_1": "Gl 340B"
  },
  {
    "Astrid #": "ASTRID 001408",
    "HYG Star ID": 117844,
    "Hipparcos Catalogue": 118212,
    "Gliese Catalog": "Gl 913",
    "Distance in Parsecs": 17.5408,
    "mag": 9.57,
    "Absolute Visual Magnitude": 8.35,
    "Spectral Type": "M0",
    "Color Index": 1.355,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.023141,
    "y": -0.066879,
    "z": 12.77186,
    "vx": 0.00000332,
    "vy": 0.00005686,
    "vz": 0.00000224,
    "rarad": 6.27760978599343,
    "decrad": 0.815577606902056,
    "pmrarad": 0.00000324262782102777,
    "pmdecrad": -3.7039765e-8,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 117844,
    "lum": 0.0398107170553497,
    "var_min": 9.619,
    "var_max": 9.509,
    "ra": 23.978703,
    "dec": 46.729155,
    "pmra": 668.84,
    "pmdec": -7.64,
    "rv": 3.6,
    "Asset Name": "ASTRID 001408",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Grecian",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000332,
    "y_1": 0.00005686,
    "z_1": 0.00000224,
    "Distance in Parsecs_1": 17.5408,
    "HYG Star ID_1": 117844,
    "Hipparcos Catalogue_1": 118212,
    "Gliese Catalog_1": "Gl 913"
  },
  {
    "Astrid #": "ASTRID 001409",
    "HYG Star ID": 118088,
    "Gliese Catalog": "NN 3113",
    "Distance in Parsecs": 17.5439,
    "mag": 12.99,
    "Absolute Visual Magnitude": 11.769,
    "Spectral Type": "M3",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.501564,
    "y": 7.780076,
    "z": -2.638254,
    "vx": -0.00001698,
    "vy": 0.00002883,
    "vz": -0.00001476,
    "rarad": 0.465157132532945,
    "decrad": -0.1509527722778,
    "pmrarad": 0.00000190299065891666,
    "pmdecrad": -8.51245556e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118088,
    "lum": 0.00170765447281387,
    "ra": 1.776769,
    "dec": -8.648957,
    "pmra": 392.52,
    "pmdec": -175.58,
    "rv": 0,
    "Asset Name": "ASTRID 001409",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Firm",
    "Hair": "Braid",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001698,
    "y_1": 0.00002883,
    "z_1": -0.00001476,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 118088,
    "Gliese Catalog_1": "NN 3113"
  },
  {
    "Astrid #": "ASTRID 001410",
    "HYG Star ID": 118196,
    "Gliese Catalog": "NN 3207",
    "Distance in Parsecs": 17.5439,
    "mag": 11.51,
    "Absolute Visual Magnitude": 10.289,
    "Spectral Type": "M3.5",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.16853,
    "y": 10.146121,
    "z": -10.990118,
    "vx": -0.00005911,
    "vy": 0.00003961,
    "vz": -0.00001277,
    "rarad": 0.835912212774929,
    "decrad": -0.676971563405,
    "pmrarad": 0.00000401367549858333,
    "pmdecrad": -9.340081e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 118196,
    "lum": 0.00667421203858476,
    "ra": 3.192949,
    "dec": -38.787613,
    "pmra": 827.88,
    "pmdec": -192.65,
    "rv": 0,
    "Asset Name": "ASTRID 001410",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Ninja",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00005911,
    "y_1": 0.00003961,
    "z_1": -0.00001277,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 118196,
    "Gliese Catalog_1": "NN 3207"
  },
  {
    "Astrid #": "ASTRID 001411",
    "HYG Star ID": 118654,
    "Gliese Catalog": "NN 3572",
    "Distance in Parsecs": 17.5439,
    "mag": 12.73,
    "Absolute Visual Magnitude": 11.509,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.254977,
    "y": 7.383316,
    "z": 10.153355,
    "vx": -0.0000078,
    "vy": 0.00000866,
    "vz": -0.00001571,
    "rarad": 2.59935755527079,
    "decrad": 0.61718269863865,
    "pmrarad": -1.93634584013888e-7,
    "pmdecrad": -0.000001098127227,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118654,
    "lum": 0.00216970155380084,
    "ra": 9.928814,
    "dec": 35.361964,
    "pmra": -39.94,
    "pmdec": -226.5,
    "rv": 0,
    "Asset Name": "ASTRID 001411",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Goddess",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000078,
    "y_1": 0.00000866,
    "z_1": -0.00001571,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 118654,
    "Gliese Catalog_1": "NN 3572"
  },
  {
    "Astrid #": "ASTRID 001412",
    "HYG Star ID": 118737,
    "Gliese Catalog": "NN 3652",
    "Distance in Parsecs": 17.5439,
    "mag": 12.89,
    "Absolute Visual Magnitude": 11.669,
    "Spectral Type": "m",
    "Color Index": 1.58,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.227209,
    "y": 3.212984,
    "z": 5.84319,
    "vx": -0.00001479,
    "vy": -0.00000926,
    "vz": -0.00003596,
    "rarad": 2.9461190362814,
    "decrad": 0.3395481214714,
    "pmrarad": 6.81405628020833e-7,
    "pmdecrad": -0.000002174350572,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118737,
    "lum": 0.00187240589598679,
    "ra": 11.253346,
    "dec": 19.454674,
    "pmra": 140.55,
    "pmdec": -448.49,
    "rv": 0,
    "Asset Name": "ASTRID 001412",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Bucket Hat",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001479,
    "y_1": -0.00000926,
    "z_1": -0.00003596,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 118737,
    "Gliese Catalog_1": "NN 3652"
  },
  {
    "Astrid #": "ASTRID 001413",
    "HYG Star ID": 118839,
    "Gliese Catalog": "NN 3740",
    "Distance in Parsecs": 17.5439,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.279,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.434998,
    "y": -1.013449,
    "z": -16.64999,
    "vx": -0.00001017,
    "vy": 0.00005866,
    "vz": -2.6e-7,
    "rarad": 3.32631141818028,
    "decrad": -1.25019862704205,
    "pmrarad": -0.0000033933563943125,
    "pmdecrad": -4.7380841e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118839,
    "lum": 0.00106757894509949,
    "ra": 12.705574,
    "dec": -71.631105,
    "pmra": -699.93,
    "pmdec": -9.77,
    "rv": 0,
    "Asset Name": "ASTRID 001413",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Owie",
    "Hair": "Sweetheart",
    "Outfit": "Astro",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001017,
    "y_1": 0.00005866,
    "z_1": -2.6e-7,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 118839,
    "Gliese Catalog_1": "NN 3740"
  },
  {
    "Astrid #": "ASTRID 001414",
    "HYG Star ID": 118881,
    "Gliese Catalog": "NN 3777",
    "Distance in Parsecs": 17.5439,
    "mag": 12.8,
    "Absolute Visual Magnitude": 11.579,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.436734,
    "y": -4.893605,
    "z": -10.163428,
    "vx": -0.00000374,
    "vy": 0.0000753,
    "vz": -0.00003135,
    "rarad": 3.49090442819137,
    "decrad": -0.61788688134335,
    "pmrarad": -0.00000410559617241666,
    "pmdecrad": -0.000002192186867,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118881,
    "lum": 0.00203422974321172,
    "ra": 13.334273,
    "dec": -35.402311,
    "pmra": -846.84,
    "pmdec": -452.17,
    "rv": 0,
    "Asset Name": "ASTRID 001414",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Mohawk",
    "Outfit": "Spartan",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000374,
    "y_1": 0.0000753,
    "z_1": -0.00003135,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 118881,
    "Gliese Catalog_1": "NN 3777"
  },
  {
    "Astrid #": "ASTRID 001415",
    "HYG Star ID": 118929,
    "Gliese Catalog": "NN 3812",
    "Distance in Parsecs": 17.5439,
    "mag": 13.65,
    "Absolute Visual Magnitude": 12.429,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.446341,
    "y": -6.538957,
    "z": 10.493764,
    "vx": -0.00001267,
    "vy": 0.0000346,
    "vz": 0.00000654,
    "rarad": 3.62535692250278,
    "decrad": 0.64118210993575,
    "pmrarad": -0.00000208178994430555,
    "pmdecrad": 4.65338715e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118929,
    "lum": 0.000929822390585379,
    "ra": 13.847843,
    "dec": 36.737029,
    "pmra": -429.4,
    "pmdec": 95.98,
    "rv": 0,
    "Asset Name": "ASTRID 001415",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Grecian",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001267,
    "y_1": 0.0000346,
    "z_1": 0.00000654,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 118929,
    "Gliese Catalog_1": "NN 3812"
  },
  {
    "Astrid #": "ASTRID 001416",
    "HYG Star ID": 119093,
    "Gliese Catalog": "NN 3963",
    "Distance in Parsecs": 17.5439,
    "mag": 14.2,
    "Absolute Visual Magnitude": 12.979,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.624184,
    "y": -14.539214,
    "z": -8.047871,
    "vx": 0.00001333,
    "vy": 0.00003331,
    "vz": -0.00006953,
    "rarad": 4.34328844140096,
    "decrad": -0.47656276714175,
    "pmrarad": 2.33680194027777e-8,
    "pmdecrad": -0.000004460222835,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119093,
    "lum": 0.000560273394793797,
    "ra": 16.59014,
    "dec": -27.305035,
    "pmra": 4.82,
    "pmdec": -919.99,
    "rv": 0,
    "Asset Name": "ASTRID 001416",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Bad Dreams",
    "Hair": "Greasy",
    "Outfit": "River Spirit",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001333,
    "y_1": 0.00003331,
    "z_1": -0.00006953,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119093,
    "Gliese Catalog_1": "NN 3963"
  },
  {
    "Astrid #": "ASTRID 001417",
    "HYG Star ID": 119186,
    "Gliese Catalog": "NN 4032",
    "Distance in Parsecs": 17.5439,
    "mag": 12.69,
    "Absolute Visual Magnitude": 11.469,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.512099,
    "y": -16.77701,
    "z": 5.104715,
    "vx": -0.00002293,
    "vy": -0.00000448,
    "vz": -0.00001703,
    "rarad": 4.68187746635935,
    "decrad": 0.2952385174306,
    "pmrarad": -0.00000129891281294444,
    "pmdecrad": -0.000001014831388,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119186,
    "lum": 0.00225112701591287,
    "ra": 17.883455,
    "dec": 16.915921,
    "pmra": -267.92,
    "pmdec": -209.32,
    "rv": 0,
    "Asset Name": "ASTRID 001417",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Afro",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002293,
    "y_1": -0.00000448,
    "z_1": -0.00001703,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119186,
    "Gliese Catalog_1": "NN 4032"
  },
  {
    "Astrid #": "ASTRID 001418",
    "HYG Star ID": 119284,
    "Gliese Catalog": "NN 4110",
    "Distance in Parsecs": 17.5439,
    "mag": 13.09,
    "Absolute Visual Magnitude": 11.869,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.216448,
    "y": -15.610442,
    "z": 5.045623,
    "vx": 0.00000667,
    "vy": -0.00000263,
    "vz": -0.00001636,
    "rarad": 5.09136246877999,
    "decrad": 0.29171988313115,
    "pmrarad": 2.97675599861111e-7,
    "pmdecrad": -9.73617377e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119284,
    "lum": 0.00155739938908085,
    "ra": 19.447572,
    "dec": 16.714318,
    "pmra": 61.4,
    "pmdec": -200.82,
    "rv": 0,
    "Asset Name": "ASTRID 001418",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Good Boy",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000667,
    "y_1": -0.00000263,
    "z_1": -0.00001636,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119284,
    "Gliese Catalog_1": "NN 4110"
  },
  {
    "Astrid #": "ASTRID 001419",
    "HYG Star ID": 119292,
    "Gliese Catalog": "NN 4117",
    "Distance in Parsecs": 17.5439,
    "mag": 12.2,
    "Absolute Visual Magnitude": 10.979,
    "Spectral Type": "M3",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.223132,
    "y": -9.608363,
    "z": 14.058198,
    "vx": 0.00000785,
    "vy": 0.00004056,
    "vz": 0.00002537,
    "rarad": 5.12645422470624,
    "decrad": 0.9294909098093,
    "pmrarad": 0.00000133973412484722,
    "pmdecrad": 0.000002416956186,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119292,
    "lum": 0.00353508613361997,
    "ra": 19.581613,
    "dec": 53.255906,
    "pmra": 276.34,
    "pmdec": 498.53,
    "rv": 0,
    "Asset Name": "ASTRID 001419",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Bob",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000785,
    "y_1": 0.00004056,
    "z_1": 0.00002537,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119292,
    "Gliese Catalog_1": "NN 4117"
  },
  {
    "Astrid #": "ASTRID 001420",
    "HYG Star ID": 119386,
    "Gliese Catalog": "NN 4178",
    "Distance in Parsecs": 17.5439,
    "mag": 13.1,
    "Absolute Visual Magnitude": 11.879,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.022312,
    "y": -7.797042,
    "z": -13.514328,
    "vx": 1e-8,
    "vy": 0.00003683,
    "vz": -0.00002125,
    "rarad": 5.51198355529238,
    "decrad": -0.8793348941507,
    "pmrarad": 0.00000150568584770138,
    "pmdecrad": -0.000001899723014,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119386,
    "lum": 0.00154312106565779,
    "ra": 21.054226,
    "dec": -50.382178,
    "pmra": 310.57,
    "pmdec": -391.85,
    "rv": 0,
    "Asset Name": "ASTRID 001420",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "Windy",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 1e-8,
    "y_1": 0.00003683,
    "z_1": -0.00002125,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119386,
    "Gliese Catalog_1": "NN 4178"
  },
  {
    "Astrid #": "ASTRID 001421",
    "HYG Star ID": 119424,
    "Gliese Catalog": "NN 4207",
    "Distance in Parsecs": 17.5439,
    "mag": 12.57,
    "Absolute Visual Magnitude": 11.349,
    "Spectral Type": "M2",
    "Color Index": 1.62,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.91385,
    "y": -8.442839,
    "z": -9.724562,
    "vx": 0.00003198,
    "vy": 0.00008836,
    "vz": -0.00003759,
    "rarad": 5.66662056601269,
    "decrad": -0.587520177972,
    "pmrarad": 0.0000051631202538125,
    "pmdecrad": -0.00000257423944,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119424,
    "lum": 0.00251420103015901,
    "ra": 21.644896,
    "dec": -33.662427,
    "pmra": 1064.97,
    "pmdec": -530.98,
    "rv": 0,
    "Asset Name": "ASTRID 001421",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Round Brush",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003198,
    "y_1": 0.00008836,
    "z_1": -0.00003759,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119424,
    "Gliese Catalog_1": "NN 4207"
  },
  {
    "Astrid #": "ASTRID 001422",
    "HYG Star ID": 119431,
    "Gliese Catalog": "NN 4214A",
    "Distance in Parsecs": 17.5439,
    "mag": 13.65,
    "Absolute Visual Magnitude": 12.429,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.910919,
    "y": -9.369706,
    "z": 5.1462,
    "vx": 0.00001188,
    "vy": 0.00001763,
    "vz": 0,
    "rarad": 5.69042937371007,
    "decrad": 0.297711086,
    "pmrarad": 0.00000121203420138888,
    "pmdecrad": 0,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119431,
    "base": "NN 4214",
    "lum": 0.000929822390585379,
    "ra": 21.735839,
    "dec": 17.057589,
    "pmra": 250,
    "pmdec": 0,
    "rv": 0,
    "Asset Name": "ASTRID 001422",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Angry",
    "Hair": "Braid",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001188,
    "y_1": 0.00001763,
    "z_1": 0,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119431,
    "Gliese Catalog_1": "NN 4214A"
  },
  {
    "Astrid #": "ASTRID 001423",
    "HYG Star ID": 119432,
    "Gliese Catalog": "NN 4215B",
    "Distance in Parsecs": 17.5439,
    "mag": 14.81,
    "Absolute Visual Magnitude": 13.589,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.908862,
    "y": -9.369811,
    "z": 5.151564,
    "vx": 0.00001188,
    "vy": 0.00001763,
    "vz": 0,
    "rarad": 5.69035559971006,
    "decrad": 0.298030863,
    "pmrarad": 0.00000121203420138888,
    "pmdecrad": 0,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119432,
    "base": "NN 4215",
    "lum": 0.000319447872420868,
    "ra": 21.735557,
    "dec": 17.075911,
    "pmra": 250,
    "pmdec": 0,
    "rv": 0,
    "Asset Name": "ASTRID 001423",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Round Brush",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001188,
    "y_1": 0.00001763,
    "z_1": 0,
    "Distance in Parsecs_1": 17.5439,
    "HYG Star ID_1": 119432,
    "Gliese Catalog_1": "NN 4215B"
  },
  {
    "Astrid #": "ASTRID 001424",
    "HYG Star ID": 88419,
    "Hipparcos Catalogue": 88694,
    "Henry Draper Catalogue": 165185,
    "Harvard Revised Catalogue": 6748,
    "Gliese Catalog": "Gl 702.1",
    "Distance in Parsecs": 17.5531,
    "mag": 5.94,
    "Absolute Visual Magnitude": 4.718,
    "Spectral Type": "G3V",
    "Color Index": 0.615,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.396121,
    "y": -14.19176,
    "z": -10.322427,
    "vx": 0.00000928,
    "vy": -0.00001106,
    "vz": -0.00000739,
    "rarad": 4.74029276889101,
    "decrad": -0.628663905054636,
    "pmrarad": 5.11187544777777e-7,
    "pmdecrad": 3.8736613e-8,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 88419,
    "lum": 1.12927574420941,
    "ra": 18.106585,
    "dec": -36.019788,
    "pmra": 105.44,
    "pmdec": 7.99,
    "rv": 13.2,
    "Asset Name": "ASTRID 001424",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Greasy",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00000928,
    "y_1": -0.00001106,
    "z_1": -0.00000739,
    "Distance in Parsecs_1": 17.5531,
    "HYG Star ID_1": 88419,
    "Hipparcos Catalogue_1": 88694,
    "Henry Draper Catalogue_1": 165185,
    "Harvard Revised Catalogue_1": 6748,
    "Gliese Catalog_1": "Gl 702.1"
  },
  {
    "Astrid #": "ASTRID 001425",
    "HYG Star ID": 64587,
    "Hipparcos Catalogue": 64792,
    "Henry Draper Catalogue": 115383,
    "Harvard Revised Catalogue": 5011,
    "Gliese Catalog": "Gl 504",
    "Distance in Parsecs": 17.5593,
    "mag": 5.19,
    "Absolute Visual Magnitude": 3.967,
    "Spectral Type": "G0Vs",
    "Color Index": 0.585,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.359162,
    "y": -5.694884,
    "z": 2.875153,
    "vx": 0.00001926,
    "vy": 0.00003685,
    "vz": 0.00001143,
    "rarad": 3.47658810768537,
    "decrad": 0.16448255972227,
    "pmrarad": -0.00000162175024282638,
    "pmdecrad": 9.24539688e-7,
    "flam": 59,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 64587,
    "lum": 2.25527756674524,
    "bf": "59    Vir",
    "ra": 13.279588,
    "dec": 9.424156,
    "pmra": -334.51,
    "pmdec": 190.7,
    "rv": -27.4,
    "Asset Name": "ASTRID 001425",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Prince",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00001926,
    "y_1": 0.00003685,
    "z_1": 0.00001143,
    "Distance in Parsecs_1": 17.5593,
    "HYG Star ID_1": 64587,
    "Hipparcos Catalogue_1": 64792,
    "Henry Draper Catalogue_1": 115383,
    "Harvard Revised Catalogue_1": 5011,
    "Gliese Catalog_1": "Gl 504"
  },
  {
    "Astrid #": "ASTRID 001426",
    "HYG Star ID": 99696,
    "Hipparcos Catalogue": 100017,
    "Henry Draper Catalogue": 193664,
    "Harvard Revised Catalogue": 7783,
    "Gliese Catalog": "Gl 788",
    "Distance in Parsecs": 17.5685,
    "mag": 5.91,
    "Absolute Visual Magnitude": 4.686,
    "Spectral Type": "G3V",
    "Color Index": 0.602,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.899623,
    "y": -5.69941,
    "z": 16.154269,
    "vx": 0.00001876,
    "vy": 0.00004324,
    "vz": 0.00000561,
    "rarad": 5.31241177617924,
    "decrad": 1.16681695803549,
    "pmrarad": 0.00000227144905613888,
    "pmdecrad": 0.000001438975485,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 99696,
    "lum": 1.16305432332846,
    "ra": 20.291918,
    "dec": 66.853687,
    "pmra": 468.52,
    "pmdec": 296.81,
    "rv": -4.6,
    "Asset Name": "ASTRID 001426",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Braided Bun",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001876,
    "y_1": 0.00004324,
    "z_1": 0.00000561,
    "Distance in Parsecs_1": 17.5685,
    "HYG Star ID_1": 99696,
    "Hipparcos Catalogue_1": 100017,
    "Henry Draper Catalogue_1": 193664,
    "Harvard Revised Catalogue_1": 7783,
    "Gliese Catalog_1": "Gl 788"
  },
  {
    "Astrid #": "ASTRID 001427",
    "HYG Star ID": 79009,
    "Hipparcos Catalogue": 79248,
    "Henry Draper Catalogue": 145675,
    "Gliese Catalog": "Gl 614",
    "Distance in Parsecs": 17.5716,
    "mag": 6.61,
    "Absolute Visual Magnitude": 5.386,
    "Spectral Type": "K0V",
    "Color Index": 0.877,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.834455,
    "y": -11.256434,
    "z": 12.165886,
    "vx": 0.00000644,
    "vy": -0.0000121,
    "vz": -0.00002776,
    "rarad": 4.23418944765703,
    "decrad": 0.764762168363225,
    "pmrarad": 6.42475089472222e-7,
    "pmdecrad": -0.00000144658706,
    "flam": 14,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 79009,
    "lum": 0.61037958555853,
    "ra": 16.173412,
    "dec": 43.817645,
    "pmra": 132.52,
    "pmdec": -298.38,
    "rv": -13.3,
    "Asset Name": "ASTRID 001427",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Windy",
    "Outfit": "Elven",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000644,
    "y_1": -0.0000121,
    "z_1": -0.00002776,
    "Distance in Parsecs_1": 17.5716,
    "HYG Star ID_1": 79009,
    "Hipparcos Catalogue_1": 79248,
    "Henry Draper Catalogue_1": 145675,
    "Gliese Catalog_1": "Gl 614"
  },
  {
    "Astrid #": "ASTRID 001428",
    "HYG Star ID": 103904,
    "Hipparcos Catalogue": 104239,
    "Henry Draper Catalogue": 200968,
    "Gliese Catalog": "Gl 819A",
    "Distance in Parsecs": 17.5747,
    "mag": 7.12,
    "Absolute Visual Magnitude": 5.896,
    "Spectral Type": "K1V",
    "Color Index": 0.901,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.433419,
    "y": -11.678513,
    "z": -4.228689,
    "vx": -0.000003,
    "vy": 0.0000475,
    "vz": 0.00000452,
    "rarad": 5.52908480847205,
    "decrad": -0.24300096270672,
    "pmrarad": 0.00000185353966349999,
    "pmdecrad": -2.25680768e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 103904,
    "base": "Gl 819",
    "lum": 0.381592649118933,
    "ra": 21.119548,
    "dec": -13.92293,
    "pmra": 382.32,
    "pmdec": -46.55,
    "rv": -34,
    "Asset Name": "ASTRID 001428",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Audiohead",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.000003,
    "y_1": 0.0000475,
    "z_1": 0.00000452,
    "Distance in Parsecs_1": 17.5747,
    "HYG Star ID_1": 103904,
    "Hipparcos Catalogue_1": 104239,
    "Henry Draper Catalogue_1": 200968,
    "Gliese Catalog_1": "Gl 819A"
  },
  {
    "Astrid #": "ASTRID 001429",
    "HYG Star ID": 34301,
    "Hipparcos Catalogue": 34392,
    "Gliese Catalog": "NN 3425",
    "Distance in Parsecs": 17.6056,
    "mag": 11.17,
    "Absolute Visual Magnitude": 9.942,
    "Spectral Type": "M2",
    "Color Index": 1.499,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.990042,
    "y": 6.525367,
    "z": 16.23011,
    "vx": 0.00002267,
    "vy": 0.0000105,
    "vz": -0.00000144,
    "rarad": 1.86682601585285,
    "decrad": 1.17288412986002,
    "pmrarad": -0.00000140595967361111,
    "pmdecrad": -2.11330283e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 34301,
    "lum": 0.00918755601666061,
    "var_min": 11.25,
    "var_max": 11.07,
    "ra": 7.13075,
    "dec": 67.20131,
    "pmra": -290,
    "pmdec": -43.59,
    "rv": 0,
    "Asset Name": "ASTRID 001429",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined Neko",
    "Hair": "Afro",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00002267,
    "y_1": 0.0000105,
    "z_1": -0.00000144,
    "Distance in Parsecs_1": 17.6056,
    "HYG Star ID_1": 34301,
    "Hipparcos Catalogue_1": 34392,
    "Gliese Catalog_1": "NN 3425"
  },
  {
    "Astrid #": "ASTRID 001430",
    "HYG Star ID": 88765,
    "Hipparcos Catalogue": 89042,
    "Henry Draper Catalogue": 165499,
    "Harvard Revised Catalogue": 6761,
    "Gliese Catalog": "Gl 705.1",
    "Distance in Parsecs": 17.6118,
    "mag": 5.47,
    "Absolute Visual Magnitude": 4.241,
    "Spectral Type": "G0V",
    "Color Index": 0.592,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.37635,
    "y": -8.259198,
    "z": -15.550848,
    "vx": -0.00000516,
    "vy": -0.00003222,
    "vz": -0.00001741,
    "rarad": 4.7579285551151,
    "decrad": -1.08214248759348,
    "pmrarad": -3.76215416111111e-7,
    "pmdecrad": 0.000001137760745,
    "bayer": "Iot",
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 88765,
    "lum": 1.7522658618857,
    "bf": "Iot Pav",
    "ra": 18.173948,
    "dec": -62.002197,
    "pmra": -77.6,
    "pmdec": 234.68,
    "rv": 29.7,
    "Asset Name": "ASTRID 001430",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Medium Bob",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00000516,
    "y_1": -0.00003222,
    "z_1": -0.00001741,
    "Distance in Parsecs_1": 17.6118,
    "HYG Star ID_1": 88765,
    "Hipparcos Catalogue_1": 89042,
    "Henry Draper Catalogue_1": 165499,
    "Harvard Revised Catalogue_1": 6761,
    "Gliese Catalog_1": "Gl 705.1"
  },
  {
    "Astrid #": "ASTRID 001431",
    "HYG Star ID": 17609,
    "Hipparcos Catalogue": 17651,
    "Henry Draper Catalogue": 23754,
    "Harvard Revised Catalogue": 1173,
    "Gliese Catalog": "Gl 155",
    "Distance in Parsecs": 17.6274,
    "mag": 4.22,
    "Absolute Visual Magnitude": 2.989,
    "Spectral Type": "F3/F5V",
    "Color Index": 0.434,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.889125,
    "y": 13.538595,
    "z": -6.958253,
    "vx": 0.00000519,
    "vy": -0.00001698,
    "vz": -0.00004428,
    "rarad": 0.989812269547439,
    "decrad": -0.405784209299486,
    "pmrarad": -7.75023149736111e-7,
    "pmdecrad": -0.000002562434227,
    "bayer": "Tau-6",
    "flam": 27,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17609,
    "lum": 5.55136777435211,
    "bf": "27Tau6Eri",
    "ra": 3.780804,
    "dec": -23.249723,
    "pmra": -159.86,
    "pmdec": -528.54,
    "rv": 6.9,
    "Asset Name": "ASTRID 001431",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Upset",
    "Hair": "High Fade",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000519,
    "y_1": -0.00001698,
    "z_1": -0.00004428,
    "Distance in Parsecs_1": 17.6274,
    "HYG Star ID_1": 17609,
    "Hipparcos Catalogue_1": 17651,
    "Henry Draper Catalogue_1": 23754,
    "Harvard Revised Catalogue_1": 1173,
    "Gliese Catalog_1": "Gl 155"
  },
  {
    "Astrid #": "ASTRID 001432",
    "HYG Star ID": 95784,
    "Hipparcos Catalogue": 96085,
    "Henry Draper Catalogue": 183870,
    "Gliese Catalog": "GJ 1240",
    "Distance in Parsecs": 17.6274,
    "mag": 7.53,
    "Absolute Visual Magnitude": 6.299,
    "Spectral Type": "K2V",
    "Color Index": 0.922,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.762238,
    "y": -15.909264,
    "z": -3.446377,
    "vx": -2.4e-7,
    "vy": 0.00005199,
    "vz": 0.00001113,
    "rarad": 5.11430208764663,
    "decrad": -0.196784863145822,
    "pmrarad": 0.0000011414453295,
    "pmdecrad": 8.7411906e-8,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 95784,
    "lum": 0.263269167421848,
    "ra": 19.535195,
    "dec": -11.274942,
    "pmra": 235.44,
    "pmdec": 18.03,
    "rv": -48.1,
    "Asset Name": "ASTRID 001432",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Prince",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -2.4e-7,
    "y_1": 0.00005199,
    "z_1": 0.00001113,
    "Distance in Parsecs_1": 17.6274,
    "HYG Star ID_1": 95784,
    "Hipparcos Catalogue_1": 96085,
    "Henry Draper Catalogue_1": 183870,
    "Gliese Catalog_1": "GJ 1240"
  },
  {
    "Astrid #": "ASTRID 001433",
    "HYG Star ID": 30292,
    "Hipparcos Catalogue": 30365,
    "Distance in Parsecs": 17.6305,
    "mag": 11.21,
    "Absolute Visual Magnitude": 9.979,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.758641,
    "y": 17.328863,
    "z": 2.729877,
    "vx": -0.00001743,
    "vy": -0.00000294,
    "vz": 0.00000746,
    "rarad": 1.67193612458349,
    "decrad": 0.155463787912752,
    "pmrarad": 0.00000100075239940277,
    "pmdecrad": 4.28332886e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 30292,
    "lum": 0.00887973489325766,
    "var_min": 11.859,
    "var_max": 9.849,
    "ra": 6.386326,
    "dec": 8.907419,
    "pmra": 206.42,
    "pmdec": 88.35,
    "rv": 0,
    "Asset Name": "ASTRID 001433",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Nervous",
    "Hair": "Audiophile",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00001743,
    "y_1": -0.00000294,
    "z_1": 0.00000746,
    "Distance in Parsecs_1": 17.6305,
    "HYG Star ID_1": 30292,
    "Hipparcos Catalogue_1": 30365
  },
  {
    "Astrid #": "ASTRID 001434",
    "HYG Star ID": 118270,
    "Gliese Catalog": "NN 3259",
    "Distance in Parsecs": 17.6367,
    "mag": 15.87,
    "Absolute Visual Magnitude": 14.638,
    "Spectral Type": "DC9",
    "Color Index": 0.69,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.698043,
    "y": 15.133201,
    "z": 2.526555,
    "vx": 0.00002766,
    "vy": -0.00001054,
    "vz": -0.00003202,
    "rarad": 1.04913909025317,
    "decrad": 0.1437501065548,
    "pmrarad": -0.00000165767493655555,
    "pmdecrad": -0.000001834568904,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118270,
    "lum": 0.000121562605523737,
    "ra": 4.007416,
    "dec": 8.236274,
    "pmra": -341.92,
    "pmdec": -378.41,
    "rv": 0,
    "Asset Name": "ASTRID 001434",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Good Boy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002766,
    "y_1": -0.00001054,
    "z_1": -0.00003202,
    "Distance in Parsecs_1": 17.6367,
    "HYG Star ID_1": 118270,
    "Gliese Catalog_1": "NN 3259"
  },
  {
    "Astrid #": "ASTRID 001435",
    "HYG Star ID": 119392,
    "Gliese Catalog": "Gl 819B",
    "Distance in Parsecs": 17.646,
    "mag": 10.2,
    "Absolute Visual Magnitude": 8.967,
    "Spectral Type": "M0",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.484289,
    "y": -11.725849,
    "z": -4.245975,
    "vx": 0.00002239,
    "vy": 0.00002463,
    "vz": -0.0000022,
    "rarad": 5.52910149850155,
    "decrad": -0.2430042558414,
    "pmrarad": 0.00000188641003104166,
    "pmdecrad": -1.28596828e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 2,
    "comp_primary": 103904,
    "base": "Gl 819",
    "lum": 0.0225527756674524,
    "ra": 21.119612,
    "dec": -13.923118,
    "pmra": 389.1,
    "pmdec": -26.52,
    "rv": 0,
    "Asset Name": "ASTRID 001435",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Star of Culture",
    "Hair": "Ninja",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00002239,
    "y_1": 0.00002463,
    "z_1": -0.0000022,
    "Distance in Parsecs_1": 17.646,
    "HYG Star ID_1": 119392,
    "Gliese Catalog_1": "Gl 819B"
  },
  {
    "Astrid #": "ASTRID 001436",
    "HYG Star ID": 86469,
    "Hipparcos Catalogue": 86736,
    "Henry Draper Catalogue": 160915,
    "Harvard Revised Catalogue": 6595,
    "Gliese Catalog": "Gl 692",
    "Distance in Parsecs": 17.6523,
    "mag": 4.86,
    "Absolute Visual Magnitude": 3.626,
    "Spectral Type": "F6/F7V",
    "Color Index": 0.469,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.184939,
    "y": -16.360471,
    "z": -6.522104,
    "vx": -0.00000896,
    "vy": -0.00000766,
    "vz": -0.0000074,
    "rarad": 4.64008855069217,
    "decrad": -0.378443126712748,
    "pmrarad": -4.74778037368055e-7,
    "pmdecrad": -2.16081457e-7,
    "flam": 58,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 86469,
    "lum": 3.08745047558744,
    "bf": "58    Oph",
    "ra": 17.723833,
    "dec": -21.683194,
    "pmra": -97.93,
    "pmdec": -44.57,
    "rv": 10.2,
    "Asset Name": "ASTRID 001436",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Undercut",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000896,
    "y_1": -0.00000766,
    "z_1": -0.0000074,
    "Distance in Parsecs_1": 17.6523,
    "HYG Star ID_1": 86469,
    "Hipparcos Catalogue_1": 86736,
    "Henry Draper Catalogue_1": 160915,
    "Harvard Revised Catalogue_1": 6595,
    "Gliese Catalog_1": "Gl 692"
  },
  {
    "Astrid #": "ASTRID 001437",
    "HYG Star ID": 36254,
    "Hipparcos Catalogue": 36357,
    "Distance in Parsecs": 17.6585,
    "mag": 7.73,
    "Absolute Visual Magnitude": 6.495,
    "Spectral Type": "K2V",
    "Color Index": 0.923,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.672526,
    "y": 13.860408,
    "z": 9.355969,
    "vx": -0.00000971,
    "vy": -0.00001253,
    "vz": 0.00001268,
    "rarad": 1.95925962036,
    "decrad": 0.558397845134698,
    "pmrarad": 7.77641143611111e-7,
    "pmdecrad": 8.46872537e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 36254,
    "lum": 0.219785987278482,
    "var": "V376",
    "var_min": 7.758,
    "var_max": 7.718,
    "ra": 7.48382,
    "dec": 31.99384,
    "pmra": 160.4,
    "pmdec": 174.68,
    "rv": 0,
    "Asset Name": "ASTRID 001437",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Elegant",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000971,
    "y_1": -0.00001253,
    "z_1": 0.00001268,
    "Distance in Parsecs_1": 17.6585,
    "HYG Star ID_1": 36254,
    "Hipparcos Catalogue_1": 36357
  },
  {
    "Astrid #": "ASTRID 001438",
    "HYG Star ID": 117976,
    "Gliese Catalog": "GJ 1007",
    "Distance in Parsecs": 17.6678,
    "mag": 13.79,
    "Absolute Visual Magnitude": 12.554,
    "Spectral Type": "m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.549198,
    "y": 1.298769,
    "z": 1.577972,
    "vx": 0.00000514,
    "vy": -0.00000434,
    "vz": -0.00005354,
    "rarad": 0.0738726924404003,
    "decrad": 0.08943261698225,
    "pmrarad": -2.66211191993055e-7,
    "pmdecrad": -0.000003042700355,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117976,
    "lum": 0.000828705077906982,
    "ra": 0.282173,
    "dec": 5.124112,
    "pmra": -54.91,
    "pmdec": -627.6,
    "rv": 0,
    "Asset Name": "ASTRID 001438",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Ninja",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000514,
    "y_1": -0.00000434,
    "z_1": -0.00005354,
    "Distance in Parsecs_1": 17.6678,
    "HYG Star ID_1": 117976,
    "Gliese Catalog_1": "GJ 1007"
  },
  {
    "Astrid #": "ASTRID 001439",
    "HYG Star ID": 119043,
    "Gliese Catalog": "NN 3919",
    "Distance in Parsecs": 17.6678,
    "mag": 13.16,
    "Absolute Visual Magnitude": 11.924,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.813186,
    "y": -12.220952,
    "z": 10.087301,
    "vx": -0.00002741,
    "vy": 0.00005723,
    "vz": 0.00004814,
    "rarad": 4.14358650431811,
    "decrad": 0.6076536524506,
    "pmrarad": -0.00000305185363772916,
    "pmdecrad": 0.000003318889012,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119043,
    "lum": 0.00148047132489762,
    "ra": 15.827335,
    "dec": 34.81599,
    "pmra": -629.49,
    "pmdec": 684.57,
    "rv": 0,
    "Asset Name": "ASTRID 001439",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Slick",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002741,
    "y_1": 0.00005723,
    "z_1": 0.00004814,
    "Distance in Parsecs_1": 17.6678,
    "HYG Star ID_1": 119043,
    "Gliese Catalog_1": "NN 3919"
  },
  {
    "Astrid #": "ASTRID 001440",
    "HYG Star ID": 74309,
    "Hipparcos Catalogue": 74537,
    "Henry Draper Catalogue": 135204,
    "Gliese Catalog": "Gl 580A",
    "Distance in Parsecs": 17.671,
    "mag": 6.58,
    "Absolute Visual Magnitude": 5.344,
    "Spectral Type": "K0V",
    "Color Index": 0.763,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.71425,
    "y": -13.222898,
    "z": -0.416737,
    "vx": -0.00003383,
    "vy": 0.00012584,
    "vz": -0.00004142,
    "rarad": 3.98741520492716,
    "decrad": -0.0235861592252887,
    "pmrarad": -0.00000615543689517361,
    "pmdecrad": -0.000002439340033,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 74309,
    "base": "Gl 580",
    "lum": 0.634453795775713,
    "ra": 15.230804,
    "dec": -1.351387,
    "pmra": -1269.65,
    "pmdec": -503.15,
    "rv": -69.2,
    "Asset Name": "ASTRID 001440",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Undercut",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00003383,
    "y_1": 0.00012584,
    "z_1": -0.00004142,
    "Distance in Parsecs_1": 17.671,
    "HYG Star ID_1": 74309,
    "Hipparcos Catalogue_1": 74537,
    "Henry Draper Catalogue_1": 135204,
    "Gliese Catalog_1": "Gl 580A"
  },
  {
    "Astrid #": "ASTRID 001441",
    "HYG Star ID": 119380,
    "Gliese Catalog": "Gl 812B",
    "Distance in Parsecs": 17.6803,
    "mag": 16.6,
    "Absolute Visual Magnitude": 15.363,
    "Spectral Type": "DC9+",
    "Color Index": 1.16,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.28069,
    "y": -12.631232,
    "z": -1.493207,
    "vx": 0.0000467,
    "vy": 0.00004765,
    "vz": -0.00001918,
    "rarad": 5.48371619227531,
    "decrad": -0.0845567393940499,
    "pmrarad": 0.00000377296550618749,
    "pmdecrad": -0.000001089007881,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 103061,
    "base": "Gl 812",
    "lum": 0.0000623447661105005,
    "ra": 20.946253,
    "dec": -4.844744,
    "pmra": 778.23,
    "pmdec": -224.62,
    "rv": 0,
    "Asset Name": "ASTRID 001441",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Wavy",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000467,
    "y_1": 0.00004765,
    "z_1": -0.00001918,
    "Distance in Parsecs_1": 17.6803,
    "HYG Star ID_1": 119380,
    "Gliese Catalog_1": "Gl 812B"
  },
  {
    "Astrid #": "ASTRID 001442",
    "HYG Star ID": 103061,
    "Hipparcos Catalogue": 103393,
    "Gliese Catalog": "Gl 812A",
    "Distance in Parsecs": 17.6866,
    "mag": 11.87,
    "Absolute Visual Magnitude": 10.632,
    "Spectral Type": "M4",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.286597,
    "y": -12.633608,
    "z": -1.494391,
    "vx": 0.00001565,
    "vy": 0.00008069,
    "vz": -0.00001491,
    "rarad": 5.48386303063365,
    "decrad": -0.0845956383011712,
    "pmrarad": 0.00000381538670323611,
    "pmdecrad": -0.000001064262991,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 103061,
    "base": "Gl 812",
    "lum": 0.00486631256073198,
    "var": "FR",
    "var_min": 11.994,
    "var_max": 11.784,
    "ra": 20.946814,
    "dec": -4.846973,
    "pmra": 786.98,
    "pmdec": -219.52,
    "rv": -44.5,
    "Asset Name": "ASTRID 001442",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Serious Shinobi",
    "Hair": "Heartbreaker",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001565,
    "y_1": 0.00008069,
    "z_1": -0.00001491,
    "Distance in Parsecs_1": 17.6866,
    "HYG Star ID_1": 103061,
    "Hipparcos Catalogue_1": 103393,
    "Gliese Catalog_1": "Gl 812A"
  },
  {
    "Astrid #": "ASTRID 001443",
    "HYG Star ID": 54957,
    "Hipparcos Catalogue": 55119,
    "Gliese Catalog": "GJ 1145",
    "Distance in Parsecs": 17.6929,
    "mag": 9.78,
    "Absolute Visual Magnitude": 8.541,
    "Spectral Type": "M0V",
    "Color Index": 1.383,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.375814,
    "y": 2.910494,
    "z": -8.255428,
    "vx": -3.3e-7,
    "vy": -0.00001786,
    "vz": -0.00000569,
    "rarad": 2.9545145597686,
    "decrad": -0.485437658589775,
    "pmrarad": 9.95516411652777e-7,
    "pmdecrad": -3.6361026e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 54957,
    "lum": 0.0333887376725122,
    "ra": 11.285414,
    "dec": -27.813529,
    "pmra": 205.34,
    "pmdec": -75,
    "rv": 0,
    "Asset Name": "ASTRID 001443",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Bucket Hat",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -3.3e-7,
    "y_1": -0.00001786,
    "z_1": -0.00000569,
    "Distance in Parsecs_1": 17.6929,
    "HYG Star ID_1": 54957,
    "Hipparcos Catalogue_1": 55119,
    "Gliese Catalog_1": "GJ 1145"
  },
  {
    "Astrid #": "ASTRID 001444",
    "HYG Star ID": 119074,
    "Gliese Catalog": "GJ 1200",
    "Distance in Parsecs": 17.6991,
    "mag": 12.92,
    "Absolute Visual Magnitude": 11.68,
    "Spectral Type": "m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.42501,
    "y": -14.981709,
    "z": 5.790286,
    "vx": -0.00011418,
    "vy": 0.00015598,
    "vz": 0.00000114,
    "rarad": 4.25229184194119,
    "decrad": 0.3333695836232,
    "pmrarad": -0.00000969220117619444,
    "pmdecrad": 0.000001708992464,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119074,
    "lum": 0.00185353162341481,
    "ra": 16.242558,
    "dec": 19.10067,
    "pmra": -1999.16,
    "pmdec": 352.5,
    "rv": -82,
    "Asset Name": "ASTRID 001444",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Sweetheart",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00011418,
    "y_1": 0.00015598,
    "z_1": 0.00000114,
    "Distance in Parsecs_1": 17.6991,
    "HYG Star ID_1": 119074,
    "Gliese Catalog_1": "GJ 1200"
  },
  {
    "Astrid #": "ASTRID 001445",
    "HYG Star ID": 17372,
    "Hipparcos Catalogue": 17414,
    "Gliese Catalog": "Gl 150.1B",
    "Distance in Parsecs": 17.7117,
    "mag": 9.99,
    "Absolute Visual Magnitude": 8.749,
    "Spectral Type": "M2",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.49558,
    "y": 14.061364,
    "z": 5.081392,
    "vx": 6.6e-7,
    "vy": 0.00002479,
    "vz": -0.00002241,
    "rarad": 0.976843213921358,
    "decrad": 0.290981644731708,
    "pmrarad": 7.52333869486111e-7,
    "pmdecrad": -0.000001550773519,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 2,
    "comp_primary": 17363,
    "base": "Gl 150.1",
    "lum": 0.0275676661029009,
    "ra": 3.731266,
    "dec": 16.67202,
    "pmra": 155.18,
    "pmdec": -319.87,
    "rv": 13.3,
    "Asset Name": "ASTRID 001445",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Audiophile",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 6.6e-7,
    "y_1": 0.00002479,
    "z_1": -0.00002241,
    "Distance in Parsecs_1": 17.7117,
    "HYG Star ID_1": 17372,
    "Hipparcos Catalogue_1": 17414,
    "Gliese Catalog_1": "Gl 150.1B"
  },
  {
    "Astrid #": "ASTRID 001446",
    "HYG Star ID": 98643,
    "Hipparcos Catalogue": 98959,
    "Henry Draper Catalogue": 189567,
    "Harvard Revised Catalogue": 7644,
    "Gliese Catalog": "Gl 776",
    "Distance in Parsecs": 17.7274,
    "mag": 6.07,
    "Absolute Visual Magnitude": 4.827,
    "Spectral Type": "G2V",
    "Color Index": 0.648,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.559782,
    "y": -5.834946,
    "z": -16.356595,
    "vx": 0.0000316,
    "vy": 0.00008757,
    "vz": -0.00001084,
    "rarad": 5.26012997782196,
    "decrad": -1.17497130815743,
    "pmrarad": 0.00000409449393913194,
    "pmdecrad": -0.000003270989421,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 98643,
    "lum": 1.02140975198984,
    "ra": 20.092216,
    "dec": -67.320897,
    "pmra": 844.55,
    "pmdec": -674.69,
    "rv": -12.2,
    "Asset Name": "ASTRID 001446",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Little Crown",
    "Outfit": "Elven",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.0000316,
    "y_1": 0.00008757,
    "z_1": -0.00001084,
    "Distance in Parsecs_1": 17.7274,
    "HYG Star ID_1": 98643,
    "Hipparcos Catalogue_1": 98959,
    "Henry Draper Catalogue_1": 189567,
    "Harvard Revised Catalogue_1": 7644,
    "Gliese Catalog_1": "Gl 776"
  },
  {
    "Astrid #": "ASTRID 001447",
    "HYG Star ID": 56297,
    "Hipparcos Catalogue": 56466,
    "Gliese Catalog": "NN 3671",
    "Distance in Parsecs": 17.7368,
    "mag": 11.16,
    "Absolute Visual Magnitude": 9.916,
    "Color Index": 1.505,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -16.120335,
    "y": 1.791617,
    "z": -7.177672,
    "vx": 0.00001599,
    "vy": 0.00005063,
    "vz": -0.00002327,
    "rarad": 3.03090911478409,
    "decrad": -0.416625407250113,
    "pmrarad": -0.00000293671038859722,
    "pmdecrad": -0.000001435000013,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 56297,
    "lum": 0.0094102248352675,
    "var_min": 11.234,
    "var_max": 11.094,
    "ra": 11.57722,
    "dec": -23.870877,
    "pmra": -605.74,
    "pmdec": -295.99,
    "rv": 0,
    "Asset Name": "ASTRID 001447",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Fired Up",
    "Hair": "Braid",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00001599,
    "y_1": 0.00005063,
    "z_1": -0.00002327,
    "Distance in Parsecs_1": 17.7368,
    "HYG Star ID_1": 56297,
    "Hipparcos Catalogue_1": 56466,
    "Gliese Catalog_1": "NN 3671"
  },
  {
    "Astrid #": "ASTRID 001448",
    "HYG Star ID": 22398,
    "Hipparcos Catalogue": 22451,
    "Henry Draper Catalogue": 30876,
    "Gliese Catalog": "NN 3317",
    "Distance in Parsecs": 17.7462,
    "mag": 7.49,
    "Absolute Visual Magnitude": 6.244,
    "Spectral Type": "K2V",
    "Color Index": 0.901,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.373274,
    "y": 13.84333,
    "z": -10.206091,
    "vx": 4.6e-7,
    "vy": -0.00000605,
    "vz": -0.00000802,
    "rarad": 1.26480629601936,
    "decrad": -0.612743738198551,
    "pmrarad": -1.27312072513888e-7,
    "pmdecrad": -5.52251263e-7,
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 22398,
    "lum": 0.276949126680968,
    "ra": 4.831204,
    "dec": -35.10763,
    "pmra": -26.26,
    "pmdec": -113.91,
    "rv": 0,
    "Asset Name": "ASTRID 001448",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Charmer",
    "Hair": "Little Crown",
    "Outfit": "Tats",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": 4.6e-7,
    "y_1": -0.00000605,
    "z_1": -0.00000802,
    "Distance in Parsecs_1": 17.7462,
    "HYG Star ID_1": 22398,
    "Hipparcos Catalogue_1": 22451,
    "Henry Draper Catalogue_1": 30876,
    "Gliese Catalog_1": "NN 3317"
  },
  {
    "Astrid #": "ASTRID 001449",
    "HYG Star ID": 55679,
    "Hipparcos Catalogue": 55846,
    "Henry Draper Catalogue": 99491,
    "Harvard Revised Catalogue": 4414,
    "Gliese Catalog": "Gl 429A",
    "Distance in Parsecs": 17.7462,
    "mag": 6.49,
    "Absolute Visual Magnitude": 5.244,
    "Spectral Type": "K0IV",
    "Color Index": 0.778,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.535582,
    "y": 2.561653,
    "z": 0.932819,
    "vx": 0.000006,
    "vy": 0.00006227,
    "vz": 0.00001583,
    "rarad": 2.996535576333,
    "decrad": 0.0525885508399525,
    "pmrarad": -0.00000352110479913888,
    "pmdecrad": 8.81924566e-7,
    "flam": 83,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 55679,
    "base": "Gl 429",
    "lum": 0.695664753528351,
    "bf": "83    Leo",
    "ra": 11.445923,
    "dec": 3.013102,
    "pmra": -726.28,
    "pmdec": 181.91,
    "rv": 3.8,
    "Asset Name": "ASTRID 001449",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "High Bun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.000006,
    "y_1": 0.00006227,
    "z_1": 0.00001583,
    "Distance in Parsecs_1": 17.7462,
    "HYG Star ID_1": 55679,
    "Hipparcos Catalogue_1": 55846,
    "Henry Draper Catalogue_1": 99491,
    "Harvard Revised Catalogue_1": 4414,
    "Gliese Catalog_1": "Gl 429A"
  },
  {
    "Astrid #": "ASTRID 001450",
    "HYG Star ID": 118635,
    "Gliese Catalog": "Gl 354.1B",
    "Distance in Parsecs": 17.7462,
    "mag": 16.5,
    "Absolute Visual Magnitude": 15.254,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.661655,
    "y": 9.472017,
    "z": 8.055496,
    "vx": -3.6e-7,
    "vy": 0.00001477,
    "vz": -0.00001792,
    "rarad": 2.49932109007656,
    "decrad": 0.4711686759782,
    "pmrarad": -6.5449846875e-7,
    "pmdecrad": -0.000001133620436,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 46710,
    "base": "Gl 354.1",
    "lum": 0.0000689286860731844,
    "ra": 9.546703,
    "dec": 26.995977,
    "pmra": -135,
    "pmdec": -233.83,
    "rv": 0,
    "Asset Name": "ASTRID 001450",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Halo Boy",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -3.6e-7,
    "y_1": 0.00001477,
    "z_1": -0.00001792,
    "Distance in Parsecs_1": 17.7462,
    "HYG Star ID_1": 118635,
    "Gliese Catalog_1": "Gl 354.1B"
  },
  {
    "Astrid #": "ASTRID 001451",
    "HYG Star ID": 18404,
    "Hipparcos Catalogue": 18450,
    "Henry Draper Catalogue": 25004,
    "Gliese Catalog": "GJ 1066",
    "Distance in Parsecs": 17.7588,
    "mag": 8.85,
    "Absolute Visual Magnitude": 7.603,
    "Spectral Type": "K5V",
    "Color Index": 1.214,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.836553,
    "y": 11.446424,
    "z": -11.73098,
    "vx": -0.00000197,
    "vy": 0.00000723,
    "vz": 0.00000591,
    "rarad": 1.03238909053641,
    "decrad": -0.721581460581537,
    "pmrarad": 3.03832733604166e-7,
    "pmdecrad": 4.4336211e-7,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 18404,
    "lum": 0.079213645415285,
    "ra": 3.943436,
    "dec": -41.343572,
    "pmra": 62.67,
    "pmdec": 91.45,
    "rv": 0,
    "Asset Name": "ASTRID 001451",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Lovestruck",
    "Hair": "Braid",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": -0.00000197,
    "y_1": 0.00000723,
    "z_1": 0.00000591,
    "Distance in Parsecs_1": 17.7588,
    "HYG Star ID_1": 18404,
    "Hipparcos Catalogue_1": 18450,
    "Henry Draper Catalogue_1": 25004,
    "Gliese Catalog_1": "GJ 1066"
  },
  {
    "Astrid #": "ASTRID 001452",
    "HYG Star ID": 118253,
    "Gliese Catalog": "Gl 151",
    "Distance in Parsecs": 17.762,
    "mag": 15.19,
    "Absolute Visual Magnitude": 13.943,
    "Spectral Type": "DQ8",
    "Color Index": 0.31,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.387658,
    "y": 13.992984,
    "z": 5.617556,
    "vx": -0.00001313,
    "vy": 0.00004568,
    "vz": -0.00009175,
    "rarad": 0.979874675592033,
    "decrad": 0.32179314642615,
    "pmrarad": 0.00000204693184067361,
    "pmdecrad": -0.000005445771477,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118253,
    "lum": 0.000230568513694728,
    "ra": 3.742846,
    "dec": 18.437389,
    "pmra": 422.21,
    "pmdec": -1123.27,
    "rv": 0,
    "Asset Name": "ASTRID 001452",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Anxious Laugh",
    "Hair": "High Fade",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001313,
    "y_1": 0.00004568,
    "z_1": -0.00009175,
    "Distance in Parsecs_1": 17.762,
    "HYG Star ID_1": 118253,
    "Gliese Catalog_1": "Gl 151"
  },
  {
    "Astrid #": "ASTRID 001453",
    "HYG Star ID": 118665,
    "Gliese Catalog": "GJ 1131",
    "Distance in Parsecs": 17.762,
    "mag": 14.34,
    "Absolute Visual Magnitude": 13.093,
    "Spectral Type": "m",
    "Color Index": 1.75,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.557454,
    "y": 2.954458,
    "z": 16.609471,
    "vx": 0.00003469,
    "vy": 0.00006639,
    "vz": -1.9e-7,
    "rarad": 2.65335373989709,
    "decrad": 1.20857688986329,
    "pmrarad": -0.00000421778205809722,
    "pmdecrad": -2.9442734e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118665,
    "lum": 0.000504428945952414,
    "ra": 10.135065,
    "dec": 69.246355,
    "pmra": -869.98,
    "pmdec": -6.07,
    "rv": 0,
    "Asset Name": "ASTRID 001453",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Lion Mane",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003469,
    "y_1": 0.00006639,
    "z_1": -1.9e-7,
    "Distance in Parsecs_1": 17.762,
    "HYG Star ID_1": 118665,
    "Gliese Catalog_1": "GJ 1131"
  },
  {
    "Astrid #": "ASTRID 001454",
    "HYG Star ID": 98504,
    "Hipparcos Catalogue": 98819,
    "Henry Draper Catalogue": 190406,
    "Harvard Revised Catalogue": 7672,
    "Gliese Catalog": "Gl 779",
    "Distance in Parsecs": 17.7683,
    "mag": 5.8,
    "Absolute Visual Magnitude": 4.552,
    "Spectral Type": "G1V",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.754808,
    "y": -14.555518,
    "z": 5.215768,
    "vx": -0.00002137,
    "vy": -0.00003032,
    "vz": -0.00003202,
    "rarad": 5.25389448107887,
    "decrad": 0.2979307261771,
    "pmrarad": -0.00000191050527096527,
    "pmdecrad": -0.00000197037976,
    "flam": 15,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 1,
    "comp_primary": 98504,
    "lum": 1.31583065509591,
    "bf": "15    Sge",
    "ra": 20.068399,
    "dec": 17.070173,
    "pmra": -394.07,
    "pmdec": -406.42,
    "rv": 4.8,
    "Asset Name": "ASTRID 001454",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Manbun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": -0.00002137,
    "y_1": -0.00003032,
    "z_1": -0.00003202,
    "Distance in Parsecs_1": 17.7683,
    "HYG Star ID_1": 98504,
    "Hipparcos Catalogue_1": 98819,
    "Henry Draper Catalogue_1": 190406,
    "Harvard Revised Catalogue_1": 7672,
    "Gliese Catalog_1": "Gl 779"
  },
  {
    "Astrid #": "ASTRID 001455",
    "HYG Star ID": 25357,
    "Hipparcos Catalogue": 25421,
    "Henry Draper Catalogue": 35854,
    "Gliese Catalog": "GJ 1079",
    "Distance in Parsecs": 17.7715,
    "mag": 7.7,
    "Absolute Visual Magnitude": 6.451,
    "Spectral Type": "K2/K3V",
    "Color Index": 0.946,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.19941,
    "y": 14.825278,
    "z": -9.549867,
    "vx": -0.00002164,
    "vy": -0.00000103,
    "vz": -0.00000659,
    "rarad": 1.42351323558194,
    "decrad": -0.567315160450996,
    "pmrarad": 0.00000119618079403472,
    "pmdecrad": -4.39822971e-7,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 25357,
    "lum": 0.228875865706236,
    "ra": 5.43742,
    "dec": -32.504764,
    "pmra": 246.73,
    "pmdec": -90.72,
    "rv": 0,
    "Asset Name": "ASTRID 001455",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Pompadour",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": -0.00002164,
    "y_1": -0.00000103,
    "z_1": -0.00000659,
    "Distance in Parsecs_1": 17.7715,
    "HYG Star ID_1": 25357,
    "Hipparcos Catalogue_1": 25421,
    "Henry Draper Catalogue_1": 35854,
    "Gliese Catalog_1": "GJ 1079"
  },
  {
    "Astrid #": "ASTRID 001456",
    "HYG Star ID": 118957,
    "Gliese Catalog": "Gl 542.1B",
    "Distance in Parsecs": 17.7841,
    "mag": 13.3,
    "Absolute Visual Magnitude": 12.05,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -13.153776,
    "y": -9.1254,
    "z": -7.744641,
    "vx": -0.00002847,
    "vy": 0.00001849,
    "vz": 0.00002656,
    "rarad": 3.74811973155485,
    "decrad": -0.4505727376836,
    "pmrarad": -0.00000176704890288888,
    "pmdecrad": 0.000001659386328,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 69742,
    "base": "Gl 542.1",
    "lum": 0.0013182567385564,
    "ra": 14.316763,
    "dec": -25.815916,
    "pmra": -364.48,
    "pmdec": 342.27,
    "rv": 0,
    "Asset Name": "ASTRID 001456",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Ponytail",
    "Outfit": "River Spirit",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002847,
    "y_1": 0.00001849,
    "z_1": 0.00002656,
    "Distance in Parsecs_1": 17.7841,
    "HYG Star ID_1": 118957,
    "Gliese Catalog_1": "Gl 542.1B"
  },
  {
    "Astrid #": "ASTRID 001457",
    "HYG Star ID": 46710,
    "Hipparcos Catalogue": 46843,
    "Henry Draper Catalogue": 82443,
    "Gliese Catalog": "Gl 354.1A",
    "Distance in Parsecs": 17.7936,
    "mag": 7.05,
    "Absolute Visual Magnitude": 5.799,
    "Spectral Type": "K0",
    "Color Index": 0.779,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.69339,
    "y": 9.501976,
    "z": 8.074989,
    "vx": -0.00000688,
    "vy": 0.00002104,
    "vz": -0.00001461,
    "rarad": 2.49900385044922,
    "decrad": 0.471038712507595,
    "pmrarad": -7.151486601875e-7,
    "pmdecrad": -0.000001193999132,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 46710,
    "base": "Gl 354.1",
    "lum": 0.417253511231793,
    "ra": 9.545492,
    "dec": 26.98853,
    "pmra": -147.51,
    "pmdec": -246.28,
    "rv": 9.3,
    "Asset Name": "ASTRID 001457",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Ponytail",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000688,
    "y_1": 0.00002104,
    "z_1": -0.00001461,
    "Distance in Parsecs_1": 17.7936,
    "HYG Star ID_1": 46710,
    "Hipparcos Catalogue_1": 46843,
    "Henry Draper Catalogue_1": 82443,
    "Gliese Catalog_1": "Gl 354.1A"
  },
  {
    "Astrid #": "ASTRID 001458",
    "HYG Star ID": 84202,
    "Hipparcos Catalogue": 84460,
    "Gliese Catalog": "NN 3998",
    "Distance in Parsecs": 17.7936,
    "mag": 11.01,
    "Absolute Visual Magnitude": 9.759,
    "Spectral Type": "M2.5",
    "Color Index": 1.773,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.331353,
    "y": -17.142561,
    "z": 3.412756,
    "vx": -0.00001276,
    "vy": -0.0000034,
    "vz": -0.00002955,
    "rarad": 4.5204491778738,
    "decrad": 0.192992679305387,
    "pmrarad": -6.67442994020833e-7,
    "pmdecrad": -0.00000169219367,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84202,
    "lum": 0.0108742671954624,
    "var_min": 11.091,
    "var_max": 10.951,
    "ra": 17.266844,
    "dec": 11.057666,
    "pmra": -137.67,
    "pmdec": -349.04,
    "rv": 0,
    "Asset Name": "ASTRID 001458",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Oh Snap",
    "Hair": "High Bun",
    "Outfit": "Toga",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001276,
    "y_1": -0.0000034,
    "z_1": -0.00002955,
    "Distance in Parsecs_1": 17.7936,
    "HYG Star ID_1": 84202,
    "Hipparcos Catalogue_1": 84460,
    "Gliese Catalog_1": "NN 3998"
  },
  {
    "Astrid #": "ASTRID 001459",
    "HYG Star ID": 82752,
    "Hipparcos Catalogue": 83006,
    "Henry Draper Catalogue": 153525,
    "Gliese Catalog": "Gl 649.1C",
    "Distance in Parsecs": 17.7999,
    "mag": 7.93,
    "Absolute Visual Magnitude": 6.678,
    "Spectral Type": "K0",
    "Color Index": 1.004,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.23709,
    "y": -11.614258,
    "z": 13.094445,
    "vx": -0.00000579,
    "vy": 0.00002394,
    "vz": 0.00001008,
    "rarad": 4.4405733496357,
    "decrad": 0.826625345140012,
    "pmrarad": -6.74327348284722e-7,
    "pmdecrad": 0.00000127258743,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 3,
    "comp_primary": 82765,
    "base": "Gl 649.1",
    "lum": 0.185694910149046,
    "var": "V1089",
    "var_min": 7.951,
    "var_max": 7.911,
    "ra": 16.961741,
    "dec": 47.362144,
    "pmra": -139.09,
    "pmdec": 262.49,
    "rv": -7,
    "Asset Name": "ASTRID 001459",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Frizzy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000579,
    "y_1": 0.00002394,
    "z_1": 0.00001008,
    "Distance in Parsecs_1": 17.7999,
    "HYG Star ID_1": 82752,
    "Hipparcos Catalogue_1": 83006,
    "Henry Draper Catalogue_1": 153525,
    "Gliese Catalog_1": "Gl 649.1C"
  },
  {
    "Astrid #": "ASTRID 001460",
    "HYG Star ID": 63415,
    "Hipparcos Catalogue": 63618,
    "Henry Draper Catalogue": 113194,
    "Distance in Parsecs": 17.8063,
    "mag": 8.35,
    "Absolute Visual Magnitude": 7.097,
    "Spectral Type": "K4V",
    "Color Index": 1.216,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.310937,
    "y": -4.270922,
    "z": -8.024884,
    "vx": 0.00000401,
    "vy": 0.00001486,
    "vz": -0.00001557,
    "rarad": 3.41362433070943,
    "decrad": -0.467523253202858,
    "pmrarad": -7.43461779131944e-7,
    "pmdecrad": -9.79566041e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 63415,
    "lum": 0.126240876146704,
    "ra": 13.039084,
    "dec": -26.787109,
    "pmra": -153.35,
    "pmdec": -202.05,
    "rv": 0,
    "Asset Name": "ASTRID 001460",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Neko Dreams",
    "Hair": "Bandana",
    "Outfit": "Elven",
    "Special": "Leaves of Flight",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000401,
    "y_1": 0.00001486,
    "z_1": -0.00001557,
    "Distance in Parsecs_1": 17.8063,
    "HYG Star ID_1": 63415,
    "Hipparcos Catalogue_1": 63618,
    "Henry Draper Catalogue_1": 113194
  },
  {
    "Astrid #": "ASTRID 001461",
    "HYG Star ID": 38820,
    "Hipparcos Catalogue": 38931,
    "Henry Draper Catalogue": 65277,
    "Gliese Catalog": "Gl 293.1B",
    "Distance in Parsecs": 17.8126,
    "mag": 8.05,
    "Absolute Visual Magnitude": 6.796,
    "Spectral Type": "K5V",
    "Color Index": 1.065,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.767945,
    "y": 15.503112,
    "z": -0.253184,
    "vx": 0.00001377,
    "vy": 0.00000309,
    "vz": 3.2e-7,
    "rarad": 2.08550745605214,
    "decrad": -0.0142142657364125,
    "pmrarad": -7.58394040493055e-7,
    "pmdecrad": 1.454441e-8,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 2,
    "comp_primary": 118511,
    "base": "Gl 293.1",
    "lum": 0.16657123280688,
    "ra": 7.966052,
    "dec": -0.814417,
    "pmra": -156.43,
    "pmdec": 3,
    "rv": -4,
    "Asset Name": "ASTRID 001461",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Frizzy",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00001377,
    "y_1": 0.00000309,
    "z_1": 3.2e-7,
    "Distance in Parsecs_1": 17.8126,
    "HYG Star ID_1": 38820,
    "Hipparcos Catalogue_1": 38931,
    "Henry Draper Catalogue_1": 65277,
    "Gliese Catalog_1": "Gl 293.1B"
  },
  {
    "Astrid #": "ASTRID 001462",
    "HYG Star ID": 64037,
    "Hipparcos Catalogue": 64241,
    "Henry Draper Catalogue": 114378,
    "Harvard Revised Catalogue": 4968,
    "Gliese Catalog": "Gl 501A",
    "Distance in Parsecs": 17.8253,
    "mag": 4.32,
    "Absolute Visual Magnitude": 3.065,
    "Spectral Type": "F5V",
    "Color Index": 0.455,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.211012,
    "y": -5.110375,
    "z": 5.368871,
    "vx": 0.00000242,
    "vy": 0.00004117,
    "vz": 0.00000711,
    "rarad": 3.44697358226717,
    "decrad": 0.305946293605042,
    "pmrarad": -0.0000021620266084375,
    "pmdecrad": 6.28754862e-7,
    "bayer": "Alp",
    "flam": 42,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 64037,
    "base": "Gl 501",
    "lum": 5.17606831950567,
    "bf": "42Alp Com",
    "ra": 13.166469,
    "dec": 17.529431,
    "pmra": -445.95,
    "pmdec": 129.69,
    "rv": -11.6,
    "Asset Name": "ASTRID 001462",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Spiky",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00000242,
    "y_1": 0.00004117,
    "z_1": 0.00000711,
    "Distance in Parsecs_1": 17.8253,
    "HYG Star ID_1": 64037,
    "Hipparcos Catalogue_1": 64241,
    "Henry Draper Catalogue_1": 114378,
    "Harvard Revised Catalogue_1": 4968,
    "Gliese Catalog_1": "Gl 501A"
  },
  {
    "Astrid #": "ASTRID 001463",
    "HYG Star ID": 83284,
    "Hipparcos Catalogue": 83541,
    "Henry Draper Catalogue": 154088,
    "Gliese Catalog": "Gl 652",
    "Distance in Parsecs": 17.838,
    "mag": 6.59,
    "Absolute Visual Magnitude": 5.333,
    "Spectral Type": "K1V",
    "Color Index": 0.814,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.758721,
    "y": -15.20651,
    "z": -8.534235,
    "vx": 0.00000636,
    "vy": -0.00000426,
    "vz": -0.00002792,
    "rarad": 4.47006719656465,
    "decrad": -0.498861846240966,
    "pmrarad": 4.03607389062499e-7,
    "pmdecrad": -0.000001304827539,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83284,
    "lum": 0.640914356644776,
    "ra": 17.074399,
    "dec": -28.582678,
    "pmra": 83.25,
    "pmdec": -269.14,
    "rv": 15.3,
    "Asset Name": "ASTRID 001463",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Manbun",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00000636,
    "y_1": -0.00000426,
    "z_1": -0.00002792,
    "Distance in Parsecs_1": 17.838,
    "HYG Star ID_1": 83284,
    "Hipparcos Catalogue_1": 83541,
    "Henry Draper Catalogue_1": 154088,
    "Gliese Catalog_1": "Gl 652"
  },
  {
    "Astrid #": "ASTRID 001464",
    "HYG Star ID": 8989,
    "Hipparcos Catalogue": 9007,
    "Henry Draper Catalogue": 11937,
    "Harvard Revised Catalogue": 566,
    "Gliese Catalog": "Gl 81A",
    "Distance in Parsecs": 17.8508,
    "mag": 3.69,
    "Absolute Visual Magnitude": 2.432,
    "Spectral Type": "G5IV",
    "Color Index": 0.844,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.696827,
    "y": 5.372709,
    "z": -13.991232,
    "vx": -0.00001521,
    "vy": 0.00005897,
    "vz": 0.00002032,
    "rarad": 0.505944033016277,
    "decrad": -0.900745155623165,
    "pmrarad": 0.00000330274471741666,
    "pmdecrad": 0.000001377985924,
    "bayer": "Chi",
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 8989,
    "base": "Gl  81",
    "lum": 9.27256742580594,
    "bf": "Chi Eri",
    "ra": 1.932564,
    "dec": -51.608896,
    "pmra": 681.24,
    "pmdec": 284.23,
    "rv": -6.3,
    "Asset Name": "ASTRID 001464",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Afro",
    "Outfit": "Boxer",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001521,
    "y_1": 0.00005897,
    "z_1": 0.00002032,
    "Distance in Parsecs_1": 17.8508,
    "HYG Star ID_1": 8989,
    "Hipparcos Catalogue_1": 9007,
    "Henry Draper Catalogue_1": 11937,
    "Harvard Revised Catalogue_1": 566,
    "Gliese Catalog_1": "Gl 81A"
  },
  {
    "Astrid #": "ASTRID 001465",
    "HYG Star ID": 118318,
    "Gliese Catalog": "Gl 171.2B",
    "Distance in Parsecs": 17.8508,
    "mag": 15.8,
    "Absolute Visual Magnitude": 14.542,
    "Spectral Type": "DC8",
    "Color Index": 0.65,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.642626,
    "y": 14.845489,
    "z": 8.150046,
    "vx": -0.0000175,
    "vy": 0.00001201,
    "vz": -0.00000976,
    "rarad": 1.20756328079072,
    "decrad": 0.47413020423615,
    "pmrarad": 0.00000115579581444444,
    "pmdecrad": -6.14535277e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 21429,
    "base": "Gl 171.2",
    "lum": 0.000132800588624372,
    "ra": 4.612552,
    "dec": 27.16566,
    "pmra": 238.4,
    "pmdec": -126.76,
    "rv": 0,
    "Asset Name": "ASTRID 001465",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Bob",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000175,
    "y_1": 0.00001201,
    "z_1": -0.00000976,
    "Distance in Parsecs_1": 17.8508,
    "HYG Star ID_1": 118318,
    "Gliese Catalog_1": "Gl 171.2B"
  },
  {
    "Astrid #": "ASTRID 001466",
    "HYG Star ID": 73506,
    "Hipparcos Catalogue": 73734,
    "Gliese Catalog": "NN 3890",
    "Distance in Parsecs": 17.854,
    "mag": 11,
    "Absolute Visual Magnitude": 9.741,
    "Spectral Type": "M:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.120433,
    "y": -6.354988,
    "z": 15.521589,
    "vx": -0.00003157,
    "vy": 0.00004894,
    "vz": 0.00000759,
    "rarad": 3.94581836223699,
    "decrad": 1.05390984288352,
    "pmrarad": -0.00000317528720079861,
    "pmdecrad": 8.60786689e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 73506,
    "lum": 0.011056050150387,
    "var_min": 11.045,
    "var_max": 10.925,
    "ra": 15.071916,
    "dec": 60.384586,
    "pmra": -654.95,
    "pmdec": 177.55,
    "rv": 0,
    "Asset Name": "ASTRID 001466",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Elegant",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00003157,
    "y_1": 0.00004894,
    "z_1": 0.00000759,
    "Distance in Parsecs_1": 17.854,
    "HYG Star ID_1": 73506,
    "Hipparcos Catalogue_1": 73734,
    "Gliese Catalog_1": "NN 3890"
  },
  {
    "Astrid #": "ASTRID 001467",
    "HYG Star ID": 117969,
    "Gliese Catalog": "NN 3017",
    "Distance in Parsecs": 17.8571,
    "mag": 14.31,
    "Absolute Visual Magnitude": 13.051,
    "Spectral Type": "dM3.4",
    "Color Index": 1.57,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 15.464641,
    "y": 1.056302,
    "z": -8.865954,
    "vx": -0.00000448,
    "vy": 0.00000767,
    "vz": -0.00000691,
    "rarad": 0.0681950160367583,
    "decrad": -0.51955582976395,
    "pmrarad": 4.45640735166666e-7,
    "pmdecrad": -4.45655279e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117969,
    "lum": 0.000524324317245637,
    "ra": 0.260486,
    "dec": -29.768356,
    "pmra": 91.92,
    "pmdec": -91.92,
    "rv": 0,
    "Asset Name": "ASTRID 001467",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Charmer",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000448,
    "y_1": 0.00000767,
    "z_1": -0.00000691,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 117969,
    "Gliese Catalog_1": "NN 3017"
  },
  {
    "Astrid #": "ASTRID 001468",
    "HYG Star ID": 118093,
    "Gliese Catalog": "NN 3120",
    "Distance in Parsecs": 17.8571,
    "mag": 13.9,
    "Absolute Visual Magnitude": 12.641,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.701111,
    "y": 7.766667,
    "z": 6.514002,
    "vx": 0.00001005,
    "vy": 0.00000531,
    "vz": -0.00002902,
    "rarad": 0.486034112,
    "decrad": 0.3734018555375,
    "pmrarad": 0,
    "pmdecrad": -0.00000174532925,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118093,
    "lum": 0.00076489179100707,
    "ra": 1.856514,
    "dec": 21.39435,
    "pmra": 0,
    "pmdec": -360,
    "rv": 0,
    "Asset Name": "ASTRID 001468",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Windy",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001005,
    "y_1": 0.00000531,
    "z_1": -0.00002902,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118093,
    "Gliese Catalog_1": "NN 3120"
  },
  {
    "Astrid #": "ASTRID 001469",
    "HYG Star ID": 118120,
    "Gliese Catalog": "NN 3141",
    "Distance in Parsecs": 17.8571,
    "mag": 12.25,
    "Absolute Visual Magnitude": 10.991,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.756994,
    "y": 4.359223,
    "z": -15.94416,
    "vx": 0.00000941,
    "vy": -0.00006456,
    "vz": -0.00001368,
    "rarad": 0.573155599807263,
    "decrad": -1.10369003680495,
    "pmrarad": -0.00000332320385473611,
    "pmdecrad": -0.000001700576099,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118120,
    "lum": 0.00349623004002701,
    "ra": 2.189293,
    "dec": -63.236781,
    "pmra": -685.46,
    "pmdec": -350.77,
    "rv": 0,
    "Asset Name": "ASTRID 001469",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Horns",
    "Outfit": "Mugiwara",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000941,
    "y_1": -0.00006456,
    "z_1": -0.00001368,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118120,
    "Gliese Catalog_1": "NN 3141"
  },
  {
    "Astrid #": "ASTRID 001470",
    "HYG Star ID": 118662,
    "Gliese Catalog": "NN 3582",
    "Distance in Parsecs": 17.8571,
    "mag": 14.23,
    "Absolute Visual Magnitude": 12.971,
    "Spectral Type": "m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.039339,
    "y": 8.448009,
    "z": 4.61795,
    "vx": -0.00001029,
    "vy": -0.00000762,
    "vz": -0.00001955,
    "rarad": 2.62978916592343,
    "decrad": 0.2615785919782,
    "pmrarad": 6.5449846875e-7,
    "pmdecrad": -0.000001133620436,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118662,
    "lum": 0.000564416888175142,
    "ra": 10.045055,
    "dec": 14.987349,
    "pmra": 135,
    "pmdec": -233.83,
    "rv": 0,
    "Asset Name": "ASTRID 001470",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Bob",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001029,
    "y_1": -0.00000762,
    "z_1": -0.00001955,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118662,
    "Gliese Catalog_1": "NN 3582"
  },
  {
    "Astrid #": "ASTRID 001471",
    "HYG Star ID": 118700,
    "Gliese Catalog": "GJ 1136B",
    "Distance in Parsecs": 17.8571,
    "mag": 11.67,
    "Absolute Visual Magnitude": 10.411,
    "Color Index": 1.52,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -13.50525,
    "y": 4.792276,
    "z": -10.654499,
    "vx": 0.00000139,
    "vy": 0.00001314,
    "vz": 0.00000415,
    "rarad": 2.80061660770456,
    "decrad": -0.6393243056446,
    "pmrarad": -7.19705908784722e-7,
    "pmdecrad": 2.89327108e-7,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 2,
    "comp_primary": 52190,
    "base": "GJ 1136",
    "lum": 0.00596485649919377,
    "ra": 10.697567,
    "dec": -36.630584,
    "pmra": -148.45,
    "pmdec": 59.68,
    "rv": 0,
    "Asset Name": "ASTRID 001471",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Shinobi",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": 0.00000139,
    "y_1": 0.00001314,
    "z_1": 0.00000415,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118700,
    "Gliese Catalog_1": "GJ 1136B"
  },
  {
    "Astrid #": "ASTRID 001472",
    "HYG Star ID": 118722,
    "Gliese Catalog": "NN 3634",
    "Distance in Parsecs": 17.8571,
    "mag": 11.95,
    "Absolute Visual Magnitude": 10.691,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.737787,
    "y": 4.0472,
    "z": -9.235465,
    "vx": 0.00001543,
    "vy": 0.00004281,
    "vz": -0.00000587,
    "rarad": 2.87360693211942,
    "decrad": -0.543561325469299,
    "pmrarad": -0.00000254061761158333,
    "pmdecrad": -3.84229386e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 118722,
    "lum": 0.00460892880980894,
    "ra": 10.97637,
    "dec": -31.14377,
    "pmra": -524.04,
    "pmdec": -79.25,
    "rv": 0,
    "Asset Name": "ASTRID 001472",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Greasy",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001543,
    "y_1": 0.00004281,
    "z_1": -0.00000587,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118722,
    "Gliese Catalog_1": "NN 3634"
  },
  {
    "Astrid #": "ASTRID 001473",
    "HYG Star ID": 118788,
    "Gliese Catalog": "NN 3696",
    "Distance in Parsecs": 17.8571,
    "mag": 14.08,
    "Absolute Visual Magnitude": 12.821,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.149525,
    "y": 0.09716,
    "z": 12.081248,
    "vx": -0.00002224,
    "vy": -0.00001111,
    "vz": -0.00002411,
    "rarad": 3.1341926466428,
    "decrad": 0.7430696350362,
    "pmrarad": 6.313728561875e-7,
    "pmdecrad": -0.000001833599276,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118788,
    "lum": 0.00064803729419229,
    "ra": 11.971734,
    "dec": 42.574754,
    "pmra": 130.23,
    "pmdec": -378.21,
    "rv": 0,
    "Asset Name": "ASTRID 001473",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Warm Smile",
    "Hair": "Spiky",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002224,
    "y_1": -0.00001111,
    "z_1": -0.00002411,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118788,
    "Gliese Catalog_1": "NN 3696"
  },
  {
    "Astrid #": "ASTRID 001474",
    "HYG Star ID": 118874,
    "Gliese Catalog": "GJ 1168",
    "Distance in Parsecs": 17.8571,
    "mag": 13.02,
    "Absolute Visual Magnitude": 11.761,
    "Spectral Type": "dM",
    "Color Index": 1.58,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -15.914889,
    "y": -5.254338,
    "z": 6.163138,
    "vx": -0.00001192,
    "vy": 0.00005005,
    "vz": 0.00001191,
    "rarad": 3.46048703621359,
    "decrad": 0.35238421605595,
    "pmrarad": -0.00000287077572804166,
    "pmdecrad": 7.10441119e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118874,
    "lum": 0.00172028340553043,
    "ra": 13.218087,
    "dec": 20.190128,
    "pmra": -592.14,
    "pmdec": 146.54,
    "rv": 0,
    "Asset Name": "ASTRID 001474",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Serious Slumber",
    "Hair": "Bucket Hat",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001192,
    "y_1": 0.00005005,
    "z_1": 0.00001191,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118874,
    "Gliese Catalog_1": "GJ 1168"
  },
  {
    "Astrid #": "ASTRID 001475",
    "HYG Star ID": 118898,
    "Gliese Catalog": "NN 3791",
    "Distance in Parsecs": 17.8571,
    "mag": 14,
    "Absolute Visual Magnitude": 12.741,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.660775,
    "y": -6.404903,
    "z": -7.931893,
    "vx": -0.00001156,
    "vy": 0.00006055,
    "vz": -0.00002756,
    "rarad": 3.55349659096231,
    "decrad": -0.4602666842105,
    "pmrarad": -0.00000336640075367361,
    "pmdecrad": -0.00000172266421,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118898,
    "lum": 0.000697589604332804,
    "ra": 13.573357,
    "dec": -26.371338,
    "pmra": -694.37,
    "pmdec": -355.32,
    "rv": 0,
    "Asset Name": "ASTRID 001475",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Sweetheart",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001156,
    "y_1": 0.00006055,
    "z_1": -0.00002756,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 118898,
    "Gliese Catalog_1": "NN 3791"
  },
  {
    "Astrid #": "ASTRID 001476",
    "HYG Star ID": 119042,
    "Gliese Catalog": "NN 3918",
    "Distance in Parsecs": 17.8571,
    "mag": 12.45,
    "Absolute Visual Magnitude": 11.191,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.581046,
    "y": -15.066204,
    "z": 0.298484,
    "vx": 0.00001306,
    "vy": -0.00000834,
    "vz": -0.00000163,
    "rarad": 4.14596870324847,
    "decrad": 0.01671591611535,
    "pmrarad": 8.678649695625e-7,
    "pmdecrad": -9.1217693e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119042,
    "lum": 0.00290803748273531,
    "ra": 15.836434,
    "dec": 0.957751,
    "pmra": 179.01,
    "pmdec": -18.82,
    "rv": 0,
    "Asset Name": "ASTRID 001476",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Halo Boy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001306,
    "y_1": -0.00000834,
    "z_1": -0.00000163,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 119042,
    "Gliese Catalog_1": "NN 3918"
  },
  {
    "Astrid #": "ASTRID 001477",
    "HYG Star ID": 119092,
    "Gliese Catalog": "NN 3961",
    "Distance in Parsecs": 17.8571,
    "mag": 12,
    "Absolute Visual Magnitude": 10.741,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.851272,
    "y": -9.874025,
    "z": -14.371756,
    "vx": 0.000002,
    "vy": 0.00004752,
    "vz": -0.00003319,
    "rarad": 4.34052706497497,
    "decrad": -0.9353725310016,
    "pmrarad": -8.62580500444444e-7,
    "pmdecrad": -0.000003131620032,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 119092,
    "lum": 0.00440149284286814,
    "ra": 16.579592,
    "dec": -53.592898,
    "pmra": -177.92,
    "pmdec": -645.94,
    "rv": 0,
    "Asset Name": "ASTRID 001477",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "High Bun",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": 0.000002,
    "y_1": 0.00004752,
    "z_1": -0.00003319,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 119092,
    "Gliese Catalog_1": "NN 3961"
  },
  {
    "Astrid #": "ASTRID 001478",
    "HYG Star ID": 119187,
    "Gliese Catalog": "GJ 2133",
    "Distance in Parsecs": 17.8571,
    "mag": 13.52,
    "Absolute Visual Magnitude": 12.261,
    "Spectral Type": "k",
    "Color Index": 1.64,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.377575,
    "y": -14.682575,
    "z": -10.156547,
    "vx": -0.00002807,
    "vy": 0.00001202,
    "vz": -0.00001634,
    "rarad": 4.68669596897471,
    "decrad": -0.60500718240135,
    "pmrarad": -0.00000158854050570833,
    "pmdecrad": -0.000001112308027,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119187,
    "lum": 0.00108542544930682,
    "ra": 17.90186,
    "dec": -34.664358,
    "pmra": -327.66,
    "pmdec": -229.43,
    "rv": 0,
    "Asset Name": "ASTRID 001478",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Sweetheart",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002807,
    "y_1": 0.00001202,
    "z_1": -0.00001634,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 119187,
    "Gliese Catalog_1": "GJ 2133"
  },
  {
    "Astrid #": "ASTRID 001479",
    "HYG Star ID": 119528,
    "Henry Draper Catalogue": 215857,
    "Gliese Catalog": "NN 4296",
    "Distance in Parsecs": 17.8571,
    "mag": 9.6,
    "Absolute Visual Magnitude": 8.341,
    "Spectral Type": "F8",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.094137,
    "y": -3.957354,
    "z": 12.528019,
    "vx": 0.00000371,
    "vy": -0.00000676,
    "vz": -0.00000251,
    "rarad": 5.96695946157529,
    "decrad": 0.77759313603165,
    "pmrarad": -2.95348494194444e-7,
    "pmdecrad": -3.21179367e-7,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 119528,
    "lum": 0.0401420918204321,
    "ra": 22.792106,
    "dec": 44.552805,
    "pmra": -60.92,
    "pmdec": -66.25,
    "rv": 2.2,
    "Asset Name": "ASTRID 001479",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Chubby Cheeks",
    "Hair": "Elegant",
    "Outfit": "River Spirit",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": 0.00000371,
    "y_1": -0.00000676,
    "z_1": -0.00000251,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 119528,
    "Henry Draper Catalogue_1": 215857,
    "Gliese Catalog_1": "NN 4296"
  },
  {
    "Astrid #": "ASTRID 001480",
    "HYG Star ID": 119607,
    "Gliese Catalog": "NN 4378A",
    "Distance in Parsecs": 17.8571,
    "mag": 12.93,
    "Absolute Visual Magnitude": 11.671,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.399674,
    "y": -0.201634,
    "z": -4.010825,
    "vx": 5.8e-7,
    "vy": 0.00001635,
    "vz": 0.00000168,
    "rarad": 6.27159626019653,
    "decrad": -0.2265394618002,
    "pmrarad": 9.16103930777777e-7,
    "pmdecrad": 9.6283996e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119607,
    "base": "NN 4378",
    "lum": 0.00186895997165149,
    "ra": 23.955733,
    "dec": -12.979755,
    "pmra": 188.96,
    "pmdec": 19.86,
    "rv": 0,
    "Asset Name": "ASTRID 001480",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oh No",
    "Hair": "Ninja",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 5.8e-7,
    "y_1": 0.00001635,
    "z_1": 0.00000168,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 119607,
    "Gliese Catalog_1": "NN 4378A"
  },
  {
    "Astrid #": "ASTRID 001481",
    "HYG Star ID": 119608,
    "Gliese Catalog": "NN 4379B",
    "Distance in Parsecs": 17.8571,
    "mag": 12.98,
    "Absolute Visual Magnitude": 11.721,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.399776,
    "y": -0.2029,
    "z": -4.010319,
    "vx": 5.8e-7,
    "vy": 0.00001635,
    "vz": 0.00000168,
    "rarad": 6.27152362019653,
    "decrad": -0.2265103808002,
    "pmrarad": 9.16103930777777e-7,
    "pmdecrad": 9.6283996e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119608,
    "base": "NN 4379",
    "lum": 0.001784842916498,
    "ra": 23.955456,
    "dec": -12.978089,
    "pmra": 188.96,
    "pmdec": 19.86,
    "rv": 0,
    "Asset Name": "ASTRID 001481",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Braid",
    "Outfit": "Astro",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 5.8e-7,
    "y_1": 0.00001635,
    "z_1": 0.00000168,
    "Distance in Parsecs_1": 17.8571,
    "HYG Star ID_1": 119608,
    "Gliese Catalog_1": "NN 4379B"
  },
  {
    "Astrid #": "ASTRID 001482",
    "HYG Star ID": 7156,
    "Hipparcos Catalogue": 7170,
    "Gliese Catalog": "NN 3098",
    "Distance in Parsecs": 17.8635,
    "mag": 11.16,
    "Absolute Visual Magnitude": 9.9,
    "Color Index": 1.492,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 15.243902,
    "y": 6.504961,
    "z": -6.66435,
    "vx": -0.00000639,
    "vy": -0.00005813,
    "vz": -0.00007137,
    "rarad": 0.403333168943444,
    "decrad": -0.382316570628273,
    "pmrarad": -0.00000285269217775694,
    "pmdecrad": -0.000004306357517,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 7156,
    "lum": 0.00954992586021435,
    "var_min": 11.215,
    "var_max": 11.055,
    "ra": 1.540619,
    "dec": -21.905126,
    "pmra": -588.41,
    "pmdec": -888.25,
    "rv": 0,
    "Asset Name": "ASTRID 001482",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Little Crown",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000639,
    "y_1": -0.00005813,
    "z_1": -0.00007137,
    "Distance in Parsecs_1": 17.8635,
    "HYG Star ID_1": 7156,
    "Hipparcos Catalogue_1": 7170,
    "Gliese Catalog_1": "NN 3098"
  },
  {
    "Astrid #": "ASTRID 001483",
    "HYG Star ID": 75083,
    "Hipparcos Catalogue": 75312,
    "Henry Draper Catalogue": 137107,
    "Harvard Revised Catalogue": 5727,
    "Gliese Catalog": "Gl 584A",
    "Distance in Parsecs": 17.8635,
    "mag": 4.99,
    "Absolute Visual Magnitude": 3.73,
    "Spectral Type": "G2V",
    "Color Index": 0.577,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.748862,
    "y": -11.953838,
    "z": 9.009316,
    "vx": 0.00000764,
    "vy": -0.00000786,
    "vz": -0.00001696,
    "rarad": 4.02824169831395,
    "decrad": 0.528622044507148,
    "pmrarad": 6.09750166034722e-7,
    "pmdecrad": -8.55599183e-7,
    "bayer": "Eta",
    "flam": 2,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 75083,
    "base": "Gl 584",
    "lum": 2.80543363795171,
    "bf": "2Eta CrB",
    "ra": 15.38675,
    "dec": 30.287812,
    "pmra": 125.77,
    "pmdec": -176.48,
    "rv": -7.3,
    "Asset Name": "ASTRID 001483",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Middle Part",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": 0.00000764,
    "y_1": -0.00000786,
    "z_1": -0.00001696,
    "Distance in Parsecs_1": 17.8635,
    "HYG Star ID_1": 75083,
    "Hipparcos Catalogue_1": 75312,
    "Henry Draper Catalogue_1": 137107,
    "Harvard Revised Catalogue_1": 5727,
    "Gliese Catalog_1": "Gl 584A"
  },
  {
    "Astrid #": "ASTRID 001484",
    "HYG Star ID": 32353,
    "Hipparcos Catalogue": 32439,
    "Henry Draper Catalogue": 46588,
    "Harvard Revised Catalogue": 2401,
    "Gliese Catalog": "Gl 240.1",
    "Distance in Parsecs": 17.8731,
    "mag": 5.44,
    "Absolute Visual Magnitude": 4.179,
    "Spectral Type": "F8V",
    "Color Index": 0.525,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.64867,
    "y": 3.171604,
    "z": 17.577624,
    "vx": -0.00000248,
    "vy": 0.00005496,
    "vz": 0.00000632,
    "rarad": 1.77255724102885,
    "decrad": 1.38866791814125,
    "pmrarad": -4.80110987854166e-7,
    "pmdecrad": -0.000002926383857,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 32353,
    "lum": 1.85523957554928,
    "ra": 6.77067,
    "dec": 79.564811,
    "pmra": -99.03,
    "pmdec": -603.61,
    "rv": 15.7,
    "Asset Name": "ASTRID 001484",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Shinobi",
    "Hair": "Charmer",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000248,
    "y_1": 0.00005496,
    "z_1": 0.00000632,
    "Distance in Parsecs_1": 17.8731,
    "HYG Star ID_1": 32353,
    "Hipparcos Catalogue_1": 32439,
    "Henry Draper Catalogue_1": 46588,
    "Harvard Revised Catalogue_1": 2401,
    "Gliese Catalog_1": "Gl 240.1"
  },
  {
    "Astrid #": "ASTRID 001485",
    "HYG Star ID": 22854,
    "Hipparcos Catalogue": 22907,
    "Henry Draper Catalogue": 31560,
    "Gliese Catalog": "GJ 2037",
    "Distance in Parsecs": 17.8859,
    "mag": 8.13,
    "Absolute Visual Magnitude": 6.867,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.072,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.349849,
    "y": 15.09475,
    "z": -8.551994,
    "vx": -0.00001523,
    "vy": 0.00000588,
    "vz": -0.00002325,
    "rarad": 1.29022702094393,
    "decrad": -0.498534349943387,
    "pmrarad": 9.09365020618055e-7,
    "pmdecrad": -0.00000110319353,
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 22854,
    "lum": 0.156027086869306,
    "ra": 4.928304,
    "dec": -28.563914,
    "pmra": 187.57,
    "pmdec": -227.55,
    "rv": 12.1,
    "Asset Name": "ASTRID 001485",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Neko Ninja",
    "Hair": "Fire",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": -0.00001523,
    "y_1": 0.00000588,
    "z_1": -0.00002325,
    "Distance in Parsecs_1": 17.8859,
    "HYG Star ID_1": 22854,
    "Hipparcos Catalogue_1": 22907,
    "Henry Draper Catalogue_1": 31560,
    "Gliese Catalog_1": "GJ 2037"
  },
  {
    "Astrid #": "ASTRID 001486",
    "HYG Star ID": 107007,
    "Hipparcos Catalogue": 107350,
    "Henry Draper Catalogue": 206860,
    "Harvard Revised Catalogue": 8314,
    "Gliese Catalog": "Gl 836.7",
    "Distance in Parsecs": 17.8859,
    "mag": 5.96,
    "Absolute Visual Magnitude": 4.697,
    "Spectral Type": "G0V",
    "Color Index": 0.587,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.359866,
    "y": -9.638323,
    "z": 4.560369,
    "vx": -7.1e-7,
    "vy": 0.00002461,
    "vz": -0.00001394,
    "rarad": 5.69205138869021,
    "decrad": 0.2578189743152,
    "pmrarad": 0.00000112030745302777,
    "pmdecrad": -5.5002112e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 107007,
    "lum": 1.15133047415581,
    "var": "HN",
    "var_min": 5.976,
    "var_max": 5.946,
    "ra": 21.742035,
    "dec": 14.771939,
    "pmra": 231.08,
    "pmdec": -113.45,
    "rv": -17,
    "Asset Name": "ASTRID 001486",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Ninja",
    "Outfit": "Spartan",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -7.1e-7,
    "y_1": 0.00002461,
    "z_1": -0.00001394,
    "Distance in Parsecs_1": 17.8859,
    "HYG Star ID_1": 107007,
    "Hipparcos Catalogue_1": 107350,
    "Henry Draper Catalogue_1": 206860,
    "Harvard Revised Catalogue_1": 8314,
    "Gliese Catalog_1": "Gl 836.7"
  },
  {
    "Astrid #": "ASTRID 001487",
    "HYG Star ID": 119259,
    "Henry Draper Catalogue": 177475,
    "Harvard Revised Catalogue": 7227,
    "Gliese Catalog": "Gl 743.1B",
    "Distance in Parsecs": 17.8923,
    "mag": 5,
    "Absolute Visual Magnitude": 3.737,
    "Spectral Type": "F8 V",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.078969,
    "y": -13.679989,
    "z": -10.782412,
    "vx": -0.0000083,
    "vy": 0.00005565,
    "vz": 0.00001331,
    "rarad": 5.0021608303147,
    "decrad": -0.6469017522138,
    "pmrarad": 4.44186294125e-7,
    "pmdecrad": -0.000001282744276,
    "bayer": "Gam",
    "con": "CrA",
    "Full Constellation Name Formula 1": "Corona Australis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Australis",
    "comp": 2,
    "comp_primary": 93530,
    "base": "Gl 743.1",
    "lum": 2.78740452032376,
    "bf": "Gam CrA",
    "ra": 19.106847,
    "dec": -37.06474,
    "pmra": 91.62,
    "pmdec": -264.58,
    "rv": -51.3,
    "Asset Name": "ASTRID 001487",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Determined Neko",
    "Hair": "Neko",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Australis",
    "x_1": -0.0000083,
    "y_1": 0.00005565,
    "z_1": 0.00001331,
    "Distance in Parsecs_1": 17.8923,
    "HYG Star ID_1": 119259,
    "Henry Draper Catalogue_1": 177475,
    "Harvard Revised Catalogue_1": 7227,
    "Gliese Catalog_1": "Gl 743.1B"
  },
  {
    "Astrid #": "ASTRID 001488",
    "HYG Star ID": 106214,
    "Hipparcos Catalogue": 106557,
    "Gliese Catalog": "NN 4205",
    "Distance in Parsecs": 17.9019,
    "mag": 12.01,
    "Absolute Visual Magnitude": 10.746,
    "Spectral Type": "M:",
    "Color Index": 1.8,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.975228,
    "y": -6.590613,
    "z": 14.017387,
    "vx": 0.00000641,
    "vy": 0.00004441,
    "vz": 0.00001678,
    "rarad": 5.64978892415639,
    "decrad": 0.89949213493225,
    "pmrarad": 0.00000221133215975,
    "pmdecrad": 0.0000015068494,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 106214,
    "lum": 0.00438126982022492,
    "var_min": 12.109,
    "var_max": 11.909,
    "ra": 21.580604,
    "dec": 51.537103,
    "pmra": 456.12,
    "pmdec": 310.81,
    "rv": 0,
    "Asset Name": "ASTRID 001488",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Firm",
    "Hair": "Horns",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000641,
    "y_1": 0.00004441,
    "z_1": 0.00001678,
    "Distance in Parsecs_1": 17.9019,
    "HYG Star ID_1": 106214,
    "Hipparcos Catalogue_1": 106557,
    "Gliese Catalog_1": "NN 4205"
  },
  {
    "Astrid #": "ASTRID 001489",
    "HYG Star ID": 31080,
    "Hipparcos Catalogue": 31156,
    "Distance in Parsecs": 17.9051,
    "mag": 6.91,
    "Absolute Visual Magnitude": 5.645,
    "Spectral Type": "G8",
    "Color Index": 0.401,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.394779,
    "y": 16.880898,
    "z": 5.467441,
    "vx": -0.00000308,
    "vy": -0.00000126,
    "vz": 0.00000255,
    "rarad": 1.71171912379589,
    "decrad": 0.310312927269716,
    "pmrarad": 1.802052450625e-7,
    "pmdecrad": 1.49516539e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 31080,
    "lum": 0.480839348449728,
    "ra": 6.538285,
    "dec": 17.779621,
    "pmra": 37.17,
    "pmdec": 30.84,
    "rv": 0,
    "Asset Name": "ASTRID 001489",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Elegant",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000308,
    "y_1": -0.00000126,
    "z_1": 0.00000255,
    "Distance in Parsecs_1": 17.9051,
    "HYG Star ID_1": 31080,
    "Hipparcos Catalogue_1": 31156
  },
  {
    "Astrid #": "ASTRID 001490",
    "HYG Star ID": 51399,
    "Hipparcos Catalogue": 51547,
    "Gliese Catalog": "Gl 397.1A",
    "Distance in Parsecs": 17.9115,
    "mag": 9.63,
    "Absolute Visual Magnitude": 8.364,
    "Spectral Type": "M0",
    "Color Index": 1.361,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.012315,
    "y": 3.654007,
    "z": 15.041529,
    "vx": 0.00001485,
    "vy": 2.1e-7,
    "vz": 0.00000641,
    "rarad": 2.75640266114197,
    "decrad": 0.996859848990151,
    "pmrarad": -3.22498060305555e-7,
    "pmdecrad": 8.35770303e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 51399,
    "base": "Gl 397.1",
    "lum": 0.0393006741341852,
    "ra": 10.528683,
    "dec": 57.115862,
    "pmra": -66.52,
    "pmdec": 172.39,
    "rv": -2,
    "Asset Name": "ASTRID 001490",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Middle Part",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001485,
    "y_1": 2.1e-7,
    "z_1": 0.00000641,
    "Distance in Parsecs_1": 17.9115,
    "HYG Star ID_1": 51399,
    "Hipparcos Catalogue_1": 51547,
    "Gliese Catalog_1": "Gl 397.1A"
  },
  {
    "Astrid #": "ASTRID 001491",
    "HYG Star ID": 54712,
    "Hipparcos Catalogue": 54872,
    "Henry Draper Catalogue": 97603,
    "Harvard Revised Catalogue": 4357,
    "Gliese Catalog": "Gl 419",
    "Proper Name": "Zosma",
    "Distance in Parsecs": 17.9147,
    "mag": 2.56,
    "Absolute Visual Magnitude": 1.294,
    "Spectral Type": "A4V",
    "Color Index": 0.128,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -16.442201,
    "y": 3.337108,
    "z": 6.280742,
    "vx": 0.00001259,
    "vy": -0.00001526,
    "vz": -0.00001785,
    "rarad": 2.94135216538174,
    "decrad": 0.358206432003273,
    "pmrarad": 6.94786485604166e-7,
    "pmdecrad": -6.32342483e-7,
    "bayer": "Del",
    "flam": 68,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54712,
    "lum": 26.4484362685436,
    "bf": "68Del Leo",
    "ra": 11.235138,
    "dec": 20.523717,
    "pmra": 143.31,
    "pmdec": -130.43,
    "rv": -20.2,
    "Asset Name": "ASTRID 001491",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Fired Up",
    "Hair": "Viking Helm",
    "Outfit": "Viking",
    "Special": "Faerie",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Zosma",
    "Constellation": "Leo",
    "x_1": 0.00001259,
    "y_1": -0.00001526,
    "z_1": -0.00001785,
    "Distance in Parsecs_1": 17.9147,
    "HYG Star ID_1": 54712,
    "Hipparcos Catalogue_1": 54872,
    "Henry Draper Catalogue_1": 97603,
    "Harvard Revised Catalogue_1": 4357,
    "Gliese Catalog_1": "Gl 419"
  },
  {
    "Astrid #": "ASTRID 001492",
    "HYG Star ID": 54903,
    "Hipparcos Catalogue": 55066,
    "Gliese Catalog": "GJ 1144",
    "Distance in Parsecs": 17.9147,
    "mag": 9.98,
    "Absolute Visual Magnitude": 8.714,
    "Spectral Type": "K7V",
    "Color Index": 1.401,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.015762,
    "y": 3.279096,
    "z": -4.543992,
    "vx": 0.00000539,
    "vy": 0.00001422,
    "vz": -0.00000992,
    "rarad": 2.95121727719193,
    "decrad": -0.256447683519156,
    "pmrarad": -8.36206636222222e-7,
    "pmdecrad": -5.72516475e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 54903,
    "lum": 0.0284708215979928,
    "ra": 11.27282,
    "dec": -14.69337,
    "pmra": -172.48,
    "pmdec": -118.09,
    "rv": 0,
    "Asset Name": "ASTRID 001492",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Middle Part",
    "Outfit": "Warlock",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00000539,
    "y_1": 0.00001422,
    "z_1": -0.00000992,
    "Distance in Parsecs_1": 17.9147,
    "HYG Star ID_1": 54903,
    "Hipparcos Catalogue_1": 55066,
    "Gliese Catalog_1": "GJ 1144"
  },
  {
    "Astrid #": "ASTRID 001493",
    "HYG Star ID": 99899,
    "Hipparcos Catalogue": 100223,
    "Henry Draper Catalogue": 192961,
    "Gliese Catalog": "Gl 787",
    "Distance in Parsecs": 17.9147,
    "mag": 8.73,
    "Absolute Visual Magnitude": 7.464,
    "Spectral Type": "K5V",
    "Color Index": 1.167,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.065626,
    "y": -10.126874,
    "z": -12.979577,
    "vx": -0.00001869,
    "vy": -0.0000308,
    "vz": -0.00002835,
    "rarad": 5.32158398109468,
    "decrad": -0.810323528290735,
    "pmrarad": -0.00000183918917855555,
    "pmdecrad": -5.01976084e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 99899,
    "lum": 0.0900326431059722,
    "ra": 20.326953,
    "dec": -46.428118,
    "pmra": -379.36,
    "pmdec": -103.54,
    "rv": 29.9,
    "Asset Name": "ASTRID 001493",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Gimme Blood",
    "Hair": "Ninja",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.00001869,
    "y_1": -0.0000308,
    "z_1": -0.00002835,
    "Distance in Parsecs_1": 17.9147,
    "HYG Star ID_1": 99899,
    "Hipparcos Catalogue_1": 100223,
    "Henry Draper Catalogue_1": 192961,
    "Gliese Catalog_1": "Gl 787"
  },
  {
    "Astrid #": "ASTRID 001494",
    "HYG Star ID": 65916,
    "Hipparcos Catalogue": 66125,
    "Distance in Parsecs": 17.9276,
    "mag": 9.31,
    "Absolute Visual Magnitude": 8.042,
    "Spectral Type": "G",
    "Color Index": 0.914,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.542425,
    "y": -1.527659,
    "z": -17.507607,
    "vx": -0.0000029,
    "vy": 0.00003192,
    "vz": -0.0000022,
    "rarad": 3.54879643179464,
    "decrad": -1.3539130046117,
    "pmrarad": -0.00000169922346897916,
    "pmdecrad": -5.69898481e-7,
    "con": "Cha",
    "Full Constellation Name Formula 1": "Chamaeleon",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Chamaeleon",
    "comp": 1,
    "comp_primary": 65916,
    "lum": 0.052868866585088,
    "ra": 13.555404,
    "dec": -77.573501,
    "pmra": -350.49,
    "pmdec": -117.55,
    "rv": 0,
    "Asset Name": "ASTRID 001494",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Worried Vamp",
    "Hair": "Slick",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Chamaeleon",
    "x_1": -0.0000029,
    "y_1": 0.00003192,
    "z_1": -0.0000022,
    "Distance in Parsecs_1": 17.9276,
    "HYG Star ID_1": 65916,
    "Hipparcos Catalogue_1": 66125
  },
  {
    "Astrid #": "ASTRID 001495",
    "HYG Star ID": 38313,
    "Hipparcos Catalogue": 38423,
    "Henry Draper Catalogue": 64379,
    "Harvard Revised Catalogue": 3079,
    "Gliese Catalog": "Gl 292A",
    "Distance in Parsecs": 17.9437,
    "mag": 5.01,
    "Absolute Visual Magnitude": 3.74,
    "Spectral Type": "F3/F5V",
    "Color Index": 0.47,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.940256,
    "y": 13.016933,
    "z": -10.216518,
    "vx": -8.9e-7,
    "vy": 0.00003833,
    "vz": 0.00000148,
    "rarad": 2.06062933942189,
    "decrad": -0.605724237240351,
    "pmrarad": -9.61094640333333e-7,
    "pmdecrad": 0.000001154486817,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 38313,
    "base": "Gl 292",
    "lum": 2.77971326775928,
    "ra": 7.871024,
    "dec": -34.705442,
    "pmra": -198.24,
    "pmdec": 238.13,
    "rv": 26.7,
    "Asset Name": "ASTRID 001495",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Frizzy",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -8.9e-7,
    "y_1": 0.00003833,
    "z_1": 0.00000148,
    "Distance in Parsecs_1": 17.9437,
    "HYG Star ID_1": 38313,
    "Hipparcos Catalogue_1": 38423,
    "Henry Draper Catalogue_1": 64379,
    "Harvard Revised Catalogue_1": 3079,
    "Gliese Catalog_1": "Gl 292A"
  },
  {
    "Astrid #": "ASTRID 001496",
    "HYG Star ID": 101904,
    "Hipparcos Catalogue": 102235,
    "Gliese Catalog": "Gl 800A",
    "Distance in Parsecs": 17.9469,
    "mag": 10.8,
    "Absolute Visual Magnitude": 9.53,
    "Spectral Type": "M1:",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.079552,
    "y": -12.863836,
    "z": -5.818767,
    "vx": 0.00002729,
    "vy": 0.00004909,
    "vz": -0.00007234,
    "rarad": 5.42340218988223,
    "decrad": -0.33018759832402,
    "pmrarad": 0.00000293724368364583,
    "pmdecrad": -0.000004163579888,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 101904,
    "base": "Gl 800",
    "lum": 0.0134276496113786,
    "var_min": 10.853,
    "var_max": 10.733,
    "ra": 20.71587,
    "dec": -18.918356,
    "pmra": 605.85,
    "pmdec": -858.8,
    "rv": 5,
    "Asset Name": "ASTRID 001496",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Braid",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00002729,
    "y_1": 0.00004909,
    "z_1": -0.00007234,
    "Distance in Parsecs_1": 17.9469,
    "HYG Star ID_1": 101904,
    "Hipparcos Catalogue_1": 102235,
    "Gliese Catalog_1": "Gl 800A"
  },
  {
    "Astrid #": "ASTRID 001497",
    "HYG Star ID": 29452,
    "Hipparcos Catalogue": 29525,
    "Henry Draper Catalogue": 42807,
    "Harvard Revised Catalogue": 2208,
    "Gliese Catalog": "Gl 230",
    "Distance in Parsecs": 17.9501,
    "mag": 6.43,
    "Absolute Visual Magnitude": 5.16,
    "Spectral Type": "G8V",
    "Color Index": 0.663,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.016205,
    "y": 17.612983,
    "z": 3.310314,
    "vx": -0.00000741,
    "vy": 0.00001039,
    "vz": -0.00002428,
    "rarad": 1.6284286965197,
    "decrad": 0.185478647662365,
    "pmrarad": 3.78687965881944e-7,
    "pmdecrad": -0.000001440381444,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29452,
    "lum": 0.751622894018205,
    "var": "V1357",
    "var_min": 6.448,
    "var_max": 6.418,
    "ra": 6.220139,
    "dec": 10.627144,
    "pmra": 78.11,
    "pmdec": -297.1,
    "rv": 6,
    "Asset Name": "ASTRID 001497",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "High Fade",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000741,
    "y_1": 0.00001039,
    "z_1": -0.00002428,
    "Distance in Parsecs_1": 17.9501,
    "HYG Star ID_1": 29452,
    "Hipparcos Catalogue_1": 29525,
    "Henry Draper Catalogue_1": 42807,
    "Harvard Revised Catalogue_1": 2208,
    "Gliese Catalog_1": "Gl 230"
  },
  {
    "Astrid #": "ASTRID 001498",
    "HYG Star ID": 119120,
    "Gliese Catalog": "Gl 649.1B",
    "Distance in Parsecs": 17.9501,
    "mag": 11.19,
    "Absolute Visual Magnitude": 9.92,
    "Color Index": 1.47,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -3.255082,
    "y": -11.713998,
    "z": 13.205787,
    "vx": -0.00000835,
    "vy": 0.00002017,
    "vz": 0.00001584,
    "rarad": 4.44136527887097,
    "decrad": 0.826691241686999,
    "pmrarad": -7.49182580562499e-7,
    "pmdecrad": 0.00000130287374,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 82765,
    "base": "Gl 649.1",
    "lum": 0.0093756200692588,
    "ra": 16.964766,
    "dec": 47.365919,
    "pmra": -154.53,
    "pmdec": 268.74,
    "rv": 0,
    "Asset Name": "ASTRID 001498",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Sweetheart",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000835,
    "y_1": 0.00002017,
    "z_1": 0.00001584,
    "Distance in Parsecs_1": 17.9501,
    "HYG Star ID_1": 119120,
    "Gliese Catalog_1": "Gl 649.1B"
  },
  {
    "Astrid #": "ASTRID 001499",
    "HYG Star ID": 55681,
    "Hipparcos Catalogue": 55848,
    "Henry Draper Catalogue": 99492,
    "Gliese Catalog": "Gl 429B",
    "Distance in Parsecs": 17.9565,
    "mag": 7.58,
    "Absolute Visual Magnitude": 6.309,
    "Spectral Type": "K2V",
    "Color Index": 1.002,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.743656,
    "y": 2.59079,
    "z": 0.941752,
    "vx": 0.00000833,
    "vy": 0.00006302,
    "vz": 0.00001671,
    "rarad": 2.99660506716297,
    "decrad": 0.0524703246602387,
    "pmrarad": -0.00000353986708857638,
    "pmdecrad": 9.26818313e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 2,
    "comp_primary": 55679,
    "base": "Gl 429",
    "lum": 0.26085550118689,
    "ra": 11.446188,
    "dec": 3.006328,
    "pmra": -730.15,
    "pmdec": 191.17,
    "rv": 1.7,
    "Asset Name": "ASTRID 001499",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Braided Ponytail",
    "Outfit": "Warlock",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000833,
    "y_1": 0.00006302,
    "z_1": 0.00001671,
    "Distance in Parsecs_1": 17.9565,
    "HYG Star ID_1": 55681,
    "Hipparcos Catalogue_1": 55848,
    "Henry Draper Catalogue_1": 99492,
    "Gliese Catalog_1": "Gl 429B"
  },
  {
    "Astrid #": "ASTRID 001500",
    "HYG Star ID": 45252,
    "Hipparcos Catalogue": 45383,
    "Henry Draper Catalogue": 79555,
    "Gliese Catalog": "Gl 339A",
    "Distance in Parsecs": 17.9598,
    "mag": 7.91,
    "Absolute Visual Magnitude": 6.638,
    "Spectral Type": "K0",
    "Color Index": 1.035,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.456982,
    "y": 11.812435,
    "z": 1.391277,
    "vx": -0.0000062,
    "vy": 0.00001793,
    "vz": 0.00000163,
    "rarad": 2.42118339699495,
    "decrad": 0.07754323138163,
    "pmrarad": -5.22629147638888e-7,
    "pmdecrad": 1.9586472e-8,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 45252,
    "base": "Gl 339",
    "lum": 0.192663745952408,
    "ra": 9.248239,
    "dec": 4.4429,
    "pmra": -107.8,
    "pmdec": 4.04,
    "rv": 16.2,
    "Asset Name": "ASTRID 001500",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined Neko",
    "Hair": "Ram Horns",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.0000062,
    "y_1": 0.00001793,
    "z_1": 0.00000163,
    "Distance in Parsecs_1": 17.9598,
    "HYG Star ID_1": 45252,
    "Hipparcos Catalogue_1": 45383,
    "Henry Draper Catalogue_1": 79555,
    "Gliese Catalog_1": "Gl 339A"
  },
  {
    "Astrid #": "ASTRID 001501",
    "HYG Star ID": 21429,
    "Hipparcos Catalogue": 21482,
    "Henry Draper Catalogue": 283750,
    "Gliese Catalog": "Gl 171.2A",
    "Distance in Parsecs": 17.9662,
    "mag": 8.1,
    "Absolute Visual Magnitude": 6.828,
    "Spectral Type": "K2",
    "Color Index": 1.104,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.677691,
    "y": 14.947421,
    "z": 8.19354,
    "vx": -0.00000547,
    "vy": 0.0000426,
    "vz": 0.00000502,
    "rarad": 1.20778425998934,
    "decrad": 0.473546113417706,
    "pmrarad": 0.00000112651306813888,
    "pmdecrad": -7.13209405e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 21429,
    "base": "Gl 171.2",
    "lum": 0.161733505579984,
    "var": "V833",
    "var_min": 8.168,
    "var_max": 8.058,
    "ra": 4.613396,
    "dec": 27.132194,
    "pmra": 232.36,
    "pmdec": -147.11,
    "rv": 35.2,
    "Asset Name": "ASTRID 001501",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Sweetheart",
    "Outfit": "Samurai",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00000547,
    "y_1": 0.0000426,
    "z_1": 0.00000502,
    "Distance in Parsecs_1": 17.9662,
    "HYG Star ID_1": 21429,
    "Hipparcos Catalogue_1": 21482,
    "Henry Draper Catalogue_1": 283750,
    "Gliese Catalog_1": "Gl 171.2A"
  },
  {
    "Astrid #": "ASTRID 001502",
    "HYG Star ID": 104972,
    "Hipparcos Catalogue": 105312,
    "Henry Draper Catalogue": 202940,
    "Harvard Revised Catalogue": 8148,
    "Gliese Catalog": "Gl 825.4A",
    "Distance in Parsecs": 17.9695,
    "mag": 6.56,
    "Absolute Visual Magnitude": 5.287,
    "Spectral Type": "G5V",
    "Color Index": 0.737,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.32396,
    "y": -10.363004,
    "z": -7.976564,
    "vx": -0.0000553,
    "vy": -0.00001978,
    "vz": -0.00002011,
    "rarad": 5.58401107901589,
    "decrad": -0.459944527789546,
    "pmrarad": -0.00000282331246871527,
    "pmdecrad": -0.000001734033091,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 104972,
    "base": "Gl 825.4",
    "lum": 0.668651772197639,
    "ra": 21.329351,
    "dec": -26.35288,
    "pmra": -582.35,
    "pmdec": -357.67,
    "rv": -17.2,
    "Asset Name": "ASTRID 001502",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "High Bun",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.0000553,
    "y_1": -0.00001978,
    "z_1": -0.00002011,
    "Distance in Parsecs_1": 17.9695,
    "HYG Star ID_1": 104972,
    "Hipparcos Catalogue_1": 105312,
    "Henry Draper Catalogue_1": 202940,
    "Harvard Revised Catalogue_1": 8148,
    "Gliese Catalog_1": "Gl 825.4A"
  },
  {
    "Astrid #": "ASTRID 001503",
    "HYG Star ID": 1730,
    "Hipparcos Catalogue": 1734,
    "Gliese Catalog": "GJ 1009",
    "Distance in Parsecs": 17.9791,
    "mag": 11.16,
    "Absolute Visual Magnitude": 9.886,
    "Spectral Type": "M2V:",
    "Color Index": 1.485,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.274859,
    "y": 1.466366,
    "z": -9.36891,
    "vx": -0.00000956,
    "vy": 0.0000045,
    "vz": -0.00001488,
    "rarad": 0.0957049454784015,
    "decrad": -0.548139372208887,
    "pmrarad": 2.99711817319444e-7,
    "pmdecrad": -9.6996673e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 1730,
    "lum": 0.00967386449968951,
    "ra": 0.365566,
    "dec": -31.406073,
    "pmra": 61.82,
    "pmdec": -200.07,
    "rv": 0,
    "Asset Name": "ASTRID 001503",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Afro",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00000956,
    "y_1": 0.0000045,
    "z_1": -0.00001488,
    "Distance in Parsecs_1": 17.9791,
    "HYG Star ID_1": 1730,
    "Hipparcos Catalogue_1": 1734,
    "Gliese Catalog_1": "GJ 1009"
  },
  {
    "Astrid #": "ASTRID 001504",
    "HYG Star ID": 8884,
    "Hipparcos Catalogue": 8903,
    "Henry Draper Catalogue": 11636,
    "Harvard Revised Catalogue": 553,
    "Gliese Catalog": "Gl 80",
    "Proper Name": "Sheratan",
    "Distance in Parsecs": 17.9856,
    "mag": 2.64,
    "Absolute Visual Magnitude": 1.365,
    "Spectral Type": "A5V...",
    "Color Index": 0.165,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 14.752622,
    "y": 8.063457,
    "z": 6.389157,
    "vx": -0.00000443,
    "vy": 0.00000715,
    "vz": -0.00001032,
    "rarad": 0.500211742009699,
    "decrad": 0.363168721573765,
    "pmrarad": 4.66972537111111e-7,
    "pmdecrad": -5.27477284e-7,
    "bayer": "Bet",
    "flam": 6,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 8884,
    "lum": 24.7742205763328,
    "bf": "6Bet Ari",
    "ra": 1.910668,
    "dec": 20.808035,
    "pmra": 96.32,
    "pmdec": -108.8,
    "rv": -4,
    "Asset Name": "ASTRID 001504",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Arrogant",
    "Hair": "Pirate Hat",
    "Outfit": "Pirate",
    "Special": "Faerie",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Sheratan",
    "Constellation": "Aries",
    "x_1": -0.00000443,
    "y_1": 0.00000715,
    "z_1": -0.00001032,
    "Distance in Parsecs_1": 17.9856,
    "HYG Star ID_1": 8884,
    "Hipparcos Catalogue_1": 8903,
    "Henry Draper Catalogue_1": 11636,
    "Harvard Revised Catalogue_1": 553,
    "Gliese Catalog_1": "Gl 80"
  },
  {
    "Astrid #": "ASTRID 001505",
    "HYG Star ID": 65515,
    "Hipparcos Catalogue": 65721,
    "Henry Draper Catalogue": 117176,
    "Harvard Revised Catalogue": 5072,
    "Gliese Catalog": "Gl 512.1",
    "Distance in Parsecs": 17.9856,
    "mag": 4.97,
    "Absolute Visual Magnitude": 3.695,
    "Spectral Type": "G5V",
    "Color Index": 0.714,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.183798,
    "y": -6.57404,
    "z": 4.283711,
    "vx": -0.00002367,
    "vy": 0.00001248,
    "vz": -0.0000475,
    "rarad": 3.52744229007872,
    "decrad": 0.240485204330481,
    "pmrarad": -0.0000011383910033125,
    "pmdecrad": -0.000002793447945,
    "flam": 70,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 65515,
    "lum": 2.89734358770132,
    "bf": "70    Vir",
    "ra": 13.473837,
    "dec": 13.778787,
    "pmra": -234.81,
    "pmdec": -576.19,
    "rv": 5.3,
    "Asset Name": "ASTRID 001505",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Grecian",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002367,
    "y_1": 0.00001248,
    "z_1": -0.0000475,
    "Distance in Parsecs_1": 17.9856,
    "HYG Star ID_1": 65515,
    "Hipparcos Catalogue_1": 65721,
    "Henry Draper Catalogue_1": 117176,
    "Harvard Revised Catalogue_1": 5072,
    "Gliese Catalog_1": "Gl 512.1"
  },
  {
    "Astrid #": "ASTRID 001506",
    "HYG Star ID": 119295,
    "Gliese Catalog": "GJ 1241",
    "Distance in Parsecs": 17.9856,
    "mag": 12.98,
    "Absolute Visual Magnitude": 11.705,
    "Spectral Type": "DAV5",
    "Color Index": 0.17,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 6.553172,
    "y": -14.51034,
    "z": 8.365868,
    "vx": 0.00003108,
    "vy": 0.00001559,
    "vz": 0.00000269,
    "rarad": 5.13657742927771,
    "decrad": 0.48379568078725,
    "pmrarad": 0.00000193188555427777,
    "pmdecrad": 1.69015745e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119295,
    "lum": 0.0018113400926196,
    "ra": 19.620281,
    "dec": 27.719451,
    "pmra": 398.48,
    "pmdec": 34.86,
    "rv": 0,
    "Asset Name": "ASTRID 001506",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Medium Bob",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003108,
    "y_1": 0.00001559,
    "z_1": 0.00000269,
    "Distance in Parsecs_1": 17.9856,
    "HYG Star ID_1": 119295,
    "Gliese Catalog_1": "GJ 1241"
  },
  {
    "Astrid #": "ASTRID 001507",
    "HYG Star ID": 32276,
    "Hipparcos Catalogue": 32362,
    "Henry Draper Catalogue": 48737,
    "Harvard Revised Catalogue": 2484,
    "Gliese Catalog": "Gl 242",
    "Distance in Parsecs": 17.9986,
    "mag": 3.35,
    "Absolute Visual Magnitude": 2.074,
    "Spectral Type": "F5IV",
    "Color Index": 0.443,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.444569,
    "y": 17.203451,
    "z": 4.0169,
    "vx": 0.00000325,
    "vy": 0.00003494,
    "vz": -0.00000939,
    "rarad": 1.76840878277494,
    "decrad": 0.225070523369276,
    "pmrarad": -5.58311434527777e-7,
    "pmdecrad": -9.25557797e-7,
    "bayer": "Xi",
    "flam": 31,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 32276,
    "lum": 12.8943661995987,
    "bf": "31Xi  Gem",
    "ra": 6.754824,
    "dec": 12.895591,
    "pmra": -115.16,
    "pmdec": -190.91,
    "rv": 30,
    "Asset Name": "ASTRID 001507",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Determined Shinobi",
    "Hair": "Greasy",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00000325,
    "y_1": 0.00003494,
    "z_1": -0.00000939,
    "Distance in Parsecs_1": 17.9986,
    "HYG Star ID_1": 32276,
    "Hipparcos Catalogue_1": 32362,
    "Henry Draper Catalogue_1": 48737,
    "Harvard Revised Catalogue_1": 2484,
    "Gliese Catalog_1": "Gl 242"
  },
  {
    "Astrid #": "ASTRID 001508",
    "HYG Star ID": 25220,
    "Hipparcos Catalogue": 25283,
    "Henry Draper Catalogue": 35650,
    "Distance in Parsecs": 18.0018,
    "mag": 9.08,
    "Absolute Visual Magnitude": 7.803,
    "Spectral Type": "K2",
    "Color Index": 1.248,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.159125,
    "y": 13.828481,
    "z": -11.321489,
    "vx": -0.00000429,
    "vy": -0.00000257,
    "vz": -0.00000396,
    "rarad": 1.41591019653267,
    "decrad": -0.680148781125268,
    "pmrarad": 2.13318019444444e-7,
    "pmdecrad": -2.82985745e-7,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 25220,
    "lum": 0.0658870404334073,
    "var_min": 9.125,
    "var_max": 9.015,
    "ra": 5.408379,
    "dec": -38.969655,
    "pmra": 44,
    "pmdec": -58.37,
    "rv": 0,
    "Asset Name": "ASTRID 001508",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Mortified",
    "Hair": "Audiophile",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": -0.00000429,
    "y_1": -0.00000257,
    "z_1": -0.00000396,
    "Distance in Parsecs_1": 18.0018,
    "HYG Star ID_1": 25220,
    "Hipparcos Catalogue_1": 25283,
    "Henry Draper Catalogue_1": 35650
  },
  {
    "Astrid #": "ASTRID 001509",
    "HYG Star ID": 64904,
    "Hipparcos Catalogue": 65109,
    "Henry Draper Catalogue": 115892,
    "Harvard Revised Catalogue": 5028,
    "Gliese Catalog": "Gl 508.1",
    "Distance in Parsecs": 18.0213,
    "mag": 2.75,
    "Absolute Visual Magnitude": 1.471,
    "Spectral Type": "A2V",
    "Color Index": 0.068,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -13.562569,
    "y": -4.976416,
    "z": -10.773082,
    "vx": -0.00000602,
    "vy": 0.0000295,
    "vz": -0.00000622,
    "rarad": 3.49326680455289,
    "decrad": -0.640750421216915,
    "pmrarad": -0.00000165205109786111,
    "pmdecrad": -4.26539076e-7,
    "bayer": "Iot",
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 64904,
    "lum": 22.4698410364015,
    "bf": "Iot Cen",
    "ra": 13.343296,
    "dec": -36.712295,
    "pmra": -340.76,
    "pmdec": -87.98,
    "rv": 0.1,
    "Asset Name": "ASTRID 001509",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Braided Ponytail",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000602,
    "y_1": 0.0000295,
    "z_1": -0.00000622,
    "Distance in Parsecs_1": 18.0213,
    "HYG Star ID_1": 64904,
    "Hipparcos Catalogue_1": 65109,
    "Henry Draper Catalogue_1": 115892,
    "Harvard Revised Catalogue_1": 5028,
    "Gliese Catalog_1": "Gl 508.1"
  },
  {
    "Astrid #": "ASTRID 001510",
    "HYG Star ID": 91772,
    "Hipparcos Catalogue": 92059,
    "Distance in Parsecs": 18.0213,
    "mag": 13.16,
    "Absolute Visual Magnitude": 11.881,
    "Spectral Type": "Fp",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.954247,
    "y": -14.585095,
    "z": -10.164384,
    "vx": -0.00000188,
    "vy": -0.00000135,
    "vz": 0.00000139,
    "rarad": 4.91223779683124,
    "decrad": -0.599246652543535,
    "pmrarad": -1.17276429326388e-7,
    "pmdecrad": 9.3423596e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 91772,
    "lum": 0.00154028114807161,
    "var_min": 13.767,
    "var_max": 12.297,
    "ra": 18.763366,
    "dec": -34.334304,
    "pmra": -24.19,
    "pmdec": 19.27,
    "rv": 0,
    "Asset Name": "ASTRID 001510",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Serious Shinobi",
    "Hair": "Grecian",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000188,
    "y_1": -0.00000135,
    "z_1": 0.00000139,
    "Distance in Parsecs_1": 18.0213,
    "HYG Star ID_1": 91772,
    "Hipparcos Catalogue_1": 92059
  },
  {
    "Astrid #": "ASTRID 001511",
    "HYG Star ID": 118508,
    "Gliese Catalog": "Gl 292B",
    "Distance in Parsecs": 18.0245,
    "mag": 8.6,
    "Absolute Visual Magnitude": 7.321,
    "Spectral Type": "K3",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.971882,
    "y": 13.075415,
    "z": -10.262017,
    "vx": 0.00000988,
    "vy": 0.00001862,
    "vz": 0.00001701,
    "rarad": 2.06066455481532,
    "decrad": -0.605699320878,
    "pmrarad": -9.70063693423611e-7,
    "pmdecrad": 0.00000114792244,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 38313,
    "base": "Gl 292",
    "lum": 0.102706989602628,
    "ra": 7.871159,
    "dec": -34.704015,
    "pmra": -200.09,
    "pmdec": 236.78,
    "rv": 0,
    "Asset Name": "ASTRID 001511",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Bangs",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000988,
    "y_1": 0.00001862,
    "z_1": 0.00001701,
    "Distance in Parsecs_1": 18.0245,
    "HYG Star ID_1": 118508,
    "Gliese Catalog_1": "Gl 292B"
  },
  {
    "Astrid #": "ASTRID 001512",
    "HYG Star ID": 68714,
    "Hipparcos Catalogue": 68933,
    "Henry Draper Catalogue": 123139,
    "Harvard Revised Catalogue": 5288,
    "Gliese Catalog": "Gl 539",
    "Proper Name": "Menkent",
    "Distance in Parsecs": 18.0343,
    "mag": 2.06,
    "Absolute Visual Magnitude": 0.78,
    "Spectral Type": "K0IIIb",
    "Color Index": 1.011,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.358808,
    "y": -7.624204,
    "z": -10.694288,
    "vx": -0.0000019,
    "vy": 0.00005217,
    "vz": -0.00003724,
    "rarad": 3.69435454809658,
    "decrad": -0.634775452665311,
    "pmrarad": -0.00000251758896175694,
    "pmdecrad": -0.000002510704607,
    "bayer": "The",
    "flam": 5,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 68714,
    "lum": 42.4619563946312,
    "bf": "5The Cen",
    "ra": 14.111395,
    "dec": -36.369954,
    "pmra": -519.29,
    "pmdec": -517.87,
    "rv": 1.3,
    "Asset Name": "ASTRID 001512",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Anxious Laugh",
    "Hair": "Ram Horns",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Menkent",
    "Constellation": "Centaurus",
    "x_1": -0.0000019,
    "y_1": 0.00005217,
    "z_1": -0.00003724,
    "Distance in Parsecs_1": 18.0343,
    "HYG Star ID_1": 68714,
    "Hipparcos Catalogue_1": 68933,
    "Henry Draper Catalogue_1": 123139,
    "Harvard Revised Catalogue_1": 5288,
    "Gliese Catalog_1": "Gl 539"
  },
  {
    "Astrid #": "ASTRID 001513",
    "HYG Star ID": 69742,
    "Hipparcos Catalogue": 69965,
    "Henry Draper Catalogue": 125276,
    "Harvard Revised Catalogue": 5356,
    "Gliese Catalog": "Gl 542.1A",
    "Distance in Parsecs": 18.0343,
    "mag": 5.87,
    "Absolute Visual Magnitude": 4.59,
    "Spectral Type": "F7Vw",
    "Color Index": 0.518,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.33829,
    "y": -9.254387,
    "z": -7.853378,
    "vx": -0.00001335,
    "vy": 0.00002866,
    "vz": 0.00003822,
    "rarad": 3.74816133150958,
    "decrad": -0.450564177601777,
    "pmrarad": -0.00000172782747613194,
    "pmdecrad": 0.000001778102654,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 69742,
    "base": "Gl 542.1",
    "lum": 1.27057410520854,
    "ra": 14.316922,
    "dec": -25.815426,
    "pmra": -356.39,
    "pmdec": 366.76,
    "rv": -21,
    "Asset Name": "ASTRID 001513",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Pompadour",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001335,
    "y_1": 0.00002866,
    "z_1": 0.00003822,
    "Distance in Parsecs_1": 18.0343,
    "HYG Star ID_1": 69742,
    "Hipparcos Catalogue_1": 69965,
    "Henry Draper Catalogue_1": 125276,
    "Harvard Revised Catalogue_1": 5356,
    "Gliese Catalog_1": "Gl 542.1A"
  },
  {
    "Astrid #": "ASTRID 001514",
    "HYG Star ID": 118472,
    "Gliese Catalog": "Gl 271B",
    "Distance in Parsecs": 18.0343,
    "mag": 8.2,
    "Absolute Visual Magnitude": 6.92,
    "Spectral Type": "K3 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.727801,
    "y": 15.711527,
    "z": 6.751313,
    "vx": 5.8e-7,
    "vy": 0.00000279,
    "vz": 1e-8,
    "rarad": 1.92038356229862,
    "decrad": 0.3837035404818,
    "pmrarad": -8.31940275833333e-8,
    "pmdecrad": -4.9790364e-8,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 2,
    "comp_primary": 35453,
    "base": "Gl 271",
    "lum": 0.1485935642287,
    "ra": 7.335325,
    "dec": 21.984593,
    "pmra": -17.16,
    "pmdec": -10.27,
    "rv": 2.2,
    "Asset Name": "ASTRID 001514",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Oh No",
    "Hair": "Braided Ponytail",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 5.8e-7,
    "y_1": 0.00000279,
    "z_1": 1e-8,
    "Distance in Parsecs_1": 18.0343,
    "HYG Star ID_1": 118472,
    "Gliese Catalog_1": "Gl 271B"
  },
  {
    "Astrid #": "ASTRID 001515",
    "HYG Star ID": 36263,
    "Hipparcos Catalogue": 36366,
    "Henry Draper Catalogue": 58946,
    "Harvard Revised Catalogue": 2852,
    "Gliese Catalog": "Gl 274A",
    "Distance in Parsecs": 18.0473,
    "mag": 4.16,
    "Absolute Visual Magnitude": 2.878,
    "Spectral Type": "F0V...",
    "Color Index": 0.32,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.815731,
    "y": 14.195743,
    "z": 9.506008,
    "vx": -0.00001053,
    "vy": -0.00001106,
    "vz": 0.00001608,
    "rarad": 1.95961951196932,
    "decrad": 0.554745055076406,
    "pmrarad": 7.72453637229166e-7,
    "pmdecrad": 9.39665875e-7,
    "bayer": "Rho",
    "flam": 62,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 36263,
    "base": "Gl 274",
    "lum": 6.14893638512626,
    "bf": "62Rho Gem",
    "ra": 7.485195,
    "dec": 31.78455,
    "pmra": 159.33,
    "pmdec": 193.82,
    "rv": 3.1,
    "Asset Name": "ASTRID 001515",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Hachimaki",
    "Outfit": "Tats",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00001053,
    "y_1": -0.00001106,
    "z_1": 0.00001608,
    "Distance in Parsecs_1": 18.0473,
    "HYG Star ID_1": 36263,
    "Hipparcos Catalogue_1": 36366,
    "Henry Draper Catalogue_1": 58946,
    "Harvard Revised Catalogue_1": 2852,
    "Gliese Catalog_1": "Gl 274A"
  },
  {
    "Astrid #": "ASTRID 001516",
    "HYG Star ID": 118111,
    "Gliese Catalog": "Gl 84.2A",
    "Distance in Parsecs": 18.0832,
    "mag": 10.28,
    "Absolute Visual Magnitude": 8.994,
    "Spectral Type": "dM0",
    "Color Index": 1.49,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.841124,
    "y": 6.705677,
    "z": 12.830461,
    "vx": 0.00004836,
    "vy": 0.00005784,
    "vz": 0.0000183,
    "rarad": 0.553913480677783,
    "decrad": 0.788645728488,
    "pmrarad": 0.00000131389355567361,
    "pmdecrad": -0.00000209455024,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 118111,
    "base": "Gl  84.2",
    "lum": 0.0219988510904924,
    "ra": 2.115794,
    "dec": 45.186072,
    "pmra": 271.01,
    "pmdec": -432.03,
    "rv": 62,
    "Asset Name": "ASTRID 001516",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Maniacal",
    "Hair": "Prince",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00004836,
    "y_1": 0.00005784,
    "z_1": 0.0000183,
    "Distance in Parsecs_1": 18.0832,
    "HYG Star ID_1": 118111,
    "Gliese Catalog_1": "Gl 84.2A"
  },
  {
    "Astrid #": "ASTRID 001517",
    "HYG Star ID": 118112,
    "Gliese Catalog": "Gl 84.2B",
    "Distance in Parsecs": 18.0832,
    "mag": 14.2,
    "Absolute Visual Magnitude": 12.914,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.839224,
    "y": 6.704502,
    "z": 12.828212,
    "vx": 0.00001036,
    "vy": 0.00003434,
    "vz": -0.00002669,
    "rarad": 0.553913480677783,
    "decrad": 0.788645728488,
    "pmrarad": 0.00000131389355567361,
    "pmdecrad": -0.00000209455024,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118111,
    "base": "Gl  84.2",
    "lum": 0.000594839774069221,
    "ra": 2.115794,
    "dec": 45.186072,
    "pmra": 271.01,
    "pmdec": -432.03,
    "rv": 0,
    "Asset Name": "ASTRID 001517",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Spiky",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001036,
    "y_1": 0.00003434,
    "z_1": -0.00002669,
    "Distance in Parsecs_1": 18.0832,
    "HYG Star ID_1": 118112,
    "Gliese Catalog_1": "Gl 84.2B"
  },
  {
    "Astrid #": "ASTRID 001518",
    "HYG Star ID": 118856,
    "Gliese Catalog": "Gl 490B",
    "Distance in Parsecs": 18.093,
    "mag": 13.2,
    "Absolute Visual Magnitude": 11.912,
    "Spectral Type": "dM4  e",
    "Color Index": 1.64,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -14.315337,
    "y": -3.679229,
    "z": 10.434839,
    "vx": -0.00000842,
    "vy": 0.000019,
    "vz": -0.000009820000000000001,
    "rarad": 3.39317359094549,
    "decrad": 0.614730091438,
    "pmrarad": -0.00000113296109009027,
    "pmdecrad": -5.5257124e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 63051,
    "base": "Gl 490",
    "lum": 0.00149692485676003,
    "ra": 12.960968,
    "dec": 35.22144,
    "pmra": -233.69,
    "pmdec": -113.98,
    "rv": -2.8,
    "Asset Name": "ASTRID 001518",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Medium Bob",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000842,
    "y_1": 0.000019,
    "z_1": -0.000009820000000000001,
    "Distance in Parsecs_1": 18.093,
    "HYG Star ID_1": 118856,
    "Gliese Catalog_1": "Gl 490B"
  },
  {
    "Astrid #": "ASTRID 001519",
    "HYG Star ID": 42380,
    "Hipparcos Catalogue": 42499,
    "Henry Draper Catalogue": 73667,
    "Gliese Catalog": "Gl 315",
    "Distance in Parsecs": 18.1389,
    "mag": 7.61,
    "Absolute Visual Magnitude": 6.317,
    "Spectral Type": "K1V",
    "Color Index": 0.832,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.415277,
    "y": 13.622713,
    "z": 3.623324,
    "vx": 0.00001035,
    "vy": 0.00000261,
    "vz": -0.00004581,
    "rarad": 2.2682584762421,
    "decrad": 0.201108573067813,
    "pmrarad": -5.30143759687499e-7,
    "pmdecrad": -0.000002423535107,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 42380,
    "lum": 0.258940510573923,
    "ra": 8.664109,
    "dec": 11.522672,
    "pmra": -109.35,
    "pmdec": -499.89,
    "rv": -13.4,
    "Asset Name": "ASTRID 001519",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Antlers",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00001035,
    "y_1": 0.00000261,
    "z_1": -0.00004581,
    "Distance in Parsecs_1": 18.1389,
    "HYG Star ID_1": 42380,
    "Hipparcos Catalogue_1": 42499,
    "Henry Draper Catalogue_1": 73667,
    "Gliese Catalog_1": "Gl 315"
  },
  {
    "Astrid #": "ASTRID 001520",
    "HYG Star ID": 118432,
    "Gliese Catalog": "NN 3398",
    "Distance in Parsecs": 18.1488,
    "mag": 14.22,
    "Absolute Visual Magnitude": 12.926,
    "Spectral Type": "k-m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.789584,
    "y": 17.55688,
    "z": 3.654191,
    "vx": 0.00001138,
    "vy": 0.00001771,
    "vz": -0.00007633,
    "rarad": 1.72836830119779,
    "decrad": 0.20273195583865,
    "pmrarad": -7.72696044069444e-7,
    "pmdecrad": -0.000004294363227,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118432,
    "lum": 0.000588301559988881,
    "ra": 6.601881,
    "dec": 11.615685,
    "pmra": -159.38,
    "pmdec": -885.78,
    "rv": 0,
    "Asset Name": "ASTRID 001520",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Elegant",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001138,
    "y_1": 0.00001771,
    "z_1": -0.00007633,
    "Distance in Parsecs_1": 18.1488,
    "HYG Star ID_1": 118432,
    "Gliese Catalog_1": "NN 3398"
  },
  {
    "Astrid #": "ASTRID 001521",
    "HYG Star ID": 116859,
    "Hipparcos Catalogue": 117226,
    "Distance in Parsecs": 18.162,
    "mag": 9.75,
    "Absolute Visual Magnitude": 8.454,
    "Color Index": 2.108,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.936549,
    "y": -0.541651,
    "z": 15.801992,
    "vx": 2.4e-7,
    "vy": 0.0000012,
    "vz": -1e-7,
    "rarad": 6.22264793301882,
    "decrad": 1.05531980397652,
    "pmrarad": 6.65164369722222e-8,
    "pmdecrad": -1.0859826e-8,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 116859,
    "lum": 0.0361742886764217,
    "var_min": 9.935,
    "var_max": 9.485,
    "ra": 23.768764,
    "dec": 60.465371,
    "pmra": 13.72,
    "pmdec": -2.24,
    "rv": 0,
    "Asset Name": "ASTRID 001521",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Upset",
    "Hair": "Tiny Crown",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 2.4e-7,
    "y_1": 0.0000012,
    "z_1": -1e-7,
    "Distance in Parsecs_1": 18.162,
    "HYG Star ID_1": 116859,
    "Hipparcos Catalogue_1": 117226
  },
  {
    "Astrid #": "ASTRID 001522",
    "HYG Star ID": 72341,
    "Hipparcos Catalogue": 72567,
    "Henry Draper Catalogue": 130948,
    "Harvard Revised Catalogue": 5534,
    "Gliese Catalog": "Gl 564",
    "Distance in Parsecs": 18.1719,
    "mag": 5.86,
    "Absolute Visual Magnitude": 4.563,
    "Spectral Type": "G2V",
    "Color Index": 0.576,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.234877,
    "y": -11.237106,
    "z": 7.365617,
    "vx": 0.00001134,
    "vy": -0.0000069,
    "vz": 0.00000149,
    "rarad": 3.88450682461984,
    "decrad": 0.417340410295206,
    "pmrarad": 7.01670839868055e-7,
    "pmdecrad": 1.57176595e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72341,
    "lum": 1.30256678613668,
    "var": "HP",
    "var_min": 5.876,
    "var_max": 5.846,
    "ra": 14.837723,
    "dec": 23.911844,
    "pmra": 144.73,
    "pmdec": 32.42,
    "rv": -2.7,
    "Asset Name": "ASTRID 001522",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Frizzy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001134,
    "y_1": -0.0000069,
    "z_1": 0.00000149,
    "Distance in Parsecs_1": 18.1719,
    "HYG Star ID_1": 72341,
    "Hipparcos Catalogue_1": 72567,
    "Henry Draper Catalogue_1": 130948,
    "Harvard Revised Catalogue_1": 5534,
    "Gliese Catalog_1": "Gl 564"
  },
  {
    "Astrid #": "ASTRID 001523",
    "HYG Star ID": 89239,
    "Hipparcos Catalogue": 89517,
    "Gliese Catalog": "Gl 708.2",
    "Distance in Parsecs": 18.1785,
    "mag": 10.18,
    "Absolute Visual Magnitude": 8.882,
    "Spectral Type": "M0",
    "Color Index": 1.404,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.233753,
    "y": -17.60206,
    "z": 4.371133,
    "vx": 0.00001035,
    "vy": -0.00002086,
    "vz": -0.00004015,
    "rarad": 4.78236576084505,
    "decrad": 0.242834470252918,
    "pmrarad": 4.87868006743055e-7,
    "pmdecrad": -0.000002428771095,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 89239,
    "lum": 0.0243893373027891,
    "var_min": 10.239,
    "var_max": 10.109,
    "ra": 18.267292,
    "dec": 13.91339,
    "pmra": 100.63,
    "pmdec": -500.97,
    "rv": 11,
    "Asset Name": "ASTRID 001523",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Charmer",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00001035,
    "y_1": -0.00002086,
    "z_1": -0.00004015,
    "Distance in Parsecs_1": 18.1785,
    "HYG Star ID_1": 89239,
    "Hipparcos Catalogue_1": 89517,
    "Gliese Catalog_1": "Gl 708.2"
  },
  {
    "Astrid #": "ASTRID 001524",
    "HYG Star ID": 118013,
    "Gliese Catalog": "NN 3051",
    "Distance in Parsecs": 18.1818,
    "mag": 12.79,
    "Absolute Visual Magnitude": 11.492,
    "Spectral Type": "m",
    "Color Index": 1.49,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.411971,
    "y": 3.409776,
    "z": 3.971715,
    "vx": -7.1e-7,
    "vy": 0.00002353,
    "vz": -0.00001708,
    "rarad": 0.193380639322755,
    "decrad": 0.22022020048635,
    "pmrarad": 0.00000127772645510416,
    "pmdecrad": -9.62830273e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118013,
    "lum": 0.0022039411818101,
    "ra": 0.73866,
    "dec": 12.617688,
    "pmra": 263.55,
    "pmdec": -198.6,
    "rv": 0,
    "Asset Name": "ASTRID 001524",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Heartbreaker",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -7.1e-7,
    "y_1": 0.00002353,
    "z_1": -0.00001708,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118013,
    "Gliese Catalog_1": "NN 3051"
  },
  {
    "Astrid #": "ASTRID 001525",
    "HYG Star ID": 118216,
    "Gliese Catalog": "GJ 2030B",
    "Distance in Parsecs": 18.1818,
    "mag": 12.3,
    "Absolute Visual Magnitude": 11.002,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 11.379753,
    "y": 13.964273,
    "z": -2.465386,
    "vx": -0.00000151,
    "vy": -0.00000201,
    "vz": -0.00001834,
    "rarad": 0.887024330352475,
    "decrad": -0.1360153597092,
    "pmrarad": -5.33295048611111e-9,
    "pmdecrad": -0.000001018094184,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 15737,
    "base": "GJ 2030",
    "lum": 0.00346098725485352,
    "ra": 3.388183,
    "dec": -7.793106,
    "pmra": -1.1,
    "pmdec": -210,
    "rv": 0,
    "Asset Name": "ASTRID 001525",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Braided Bun",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000151,
    "y_1": -0.00000201,
    "z_1": -0.00001834,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118216,
    "Gliese Catalog_1": "GJ 2030B"
  },
  {
    "Astrid #": "ASTRID 001526",
    "HYG Star ID": 118223,
    "Gliese Catalog": "NN 3227",
    "Distance in Parsecs": 18.1818,
    "mag": 13.4,
    "Absolute Visual Magnitude": 12.102,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.972713,
    "y": 12.859197,
    "z": 8.109493,
    "vx": -0.00001218,
    "vy": 0.00001428,
    "vz": -0.00000767,
    "rarad": 0.911148308705856,
    "decrad": 0.46231648357035,
    "pmrarad": 0.00000101059411711805,
    "pmdecrad": -4.71248593e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118223,
    "lum": 0.00125660852007633,
    "ra": 3.48033,
    "dec": 26.488783,
    "pmra": 208.45,
    "pmdec": -97.2,
    "rv": 0,
    "Asset Name": "ASTRID 001526",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Lovestruck",
    "Hair": "Ponytail",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001218,
    "y_1": 0.00001428,
    "z_1": -0.00000767,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118223,
    "Gliese Catalog_1": "NN 3227"
  },
  {
    "Astrid #": "ASTRID 001527",
    "HYG Star ID": 118310,
    "Gliese Catalog": "NN 3293",
    "Distance in Parsecs": 18.1818,
    "mag": 12,
    "Absolute Visual Magnitude": 10.702,
    "Spectral Type": "M2.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.390574,
    "y": 15.163825,
    "z": -7.73284,
    "vx": -0.00000214,
    "vy": -0.00002007,
    "vz": -0.00004115,
    "rarad": 1.17195039218006,
    "decrad": -0.4393006118121,
    "pmrarad": -3.20316398743055e-7,
    "pmdecrad": -0.000002500596242,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118310,
    "lum": 0.0045624697707685,
    "ra": 4.476521,
    "dec": -25.170071,
    "pmra": -66.07,
    "pmdec": -515.78,
    "rv": 0,
    "Asset Name": "ASTRID 001527",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Elegant",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000214,
    "y_1": -0.00002007,
    "z_1": -0.00004115,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118310,
    "Gliese Catalog_1": "NN 3293"
  },
  {
    "Astrid #": "ASTRID 001528",
    "HYG Star ID": 118326,
    "Gliese Catalog": "NN 3307",
    "Distance in Parsecs": 18.1818,
    "mag": 11.24,
    "Absolute Visual Magnitude": 9.942,
    "Spectral Type": "M0",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.161655,
    "y": 17.091276,
    "z": 0.707213,
    "vx": -1e-7,
    "vy": 0.00000131,
    "vz": -0.00003082,
    "rarad": 1.22478520210579,
    "decrad": 0.0389065566959,
    "pmrarad": 2.96221158819444e-8,
    "pmdecrad": -0.000001696586082,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118326,
    "lum": 0.00918755601666061,
    "ra": 4.678335,
    "dec": 2.229181,
    "pmra": 6.11,
    "pmdec": -349.95,
    "rv": 0,
    "Asset Name": "ASTRID 001528",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Braided Bun",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -1e-7,
    "y_1": 0.00000131,
    "z_1": -0.00003082,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118326,
    "Gliese Catalog_1": "NN 3307"
  },
  {
    "Astrid #": "ASTRID 001529",
    "HYG Star ID": 118419,
    "Gliese Catalog": "NN 3388",
    "Distance in Parsecs": 18.1818,
    "mag": 12.86,
    "Absolute Visual Magnitude": 11.562,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.690148,
    "y": 11.254613,
    "z": 14.263073,
    "vx": 0.00003151,
    "vy": 0.00000727,
    "vz": -0.00000421,
    "rarad": 1.63209462554043,
    "decrad": 0.901840729369,
    "pmrarad": -0.00000175458919129861,
    "pmdecrad": -3.7295262e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118419,
    "lum": 0.00206633151756448,
    "ra": 6.234142,
    "dec": 51.671668,
    "pmra": -361.91,
    "pmdec": -76.93,
    "rv": 0,
    "Asset Name": "ASTRID 001529",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Antlers",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003151,
    "y_1": 0.00000727,
    "z_1": -0.00000421,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118419,
    "Gliese Catalog_1": "NN 3388"
  },
  {
    "Astrid #": "ASTRID 001530",
    "HYG Star ID": 118479,
    "Gliese Catalog": "NN 3444",
    "Distance in Parsecs": 18.1818,
    "mag": 17.4,
    "Absolute Visual Magnitude": 16.102,
    "Spectral Type": "M6  :",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.774298,
    "y": 8.889735,
    "z": 15.405677,
    "vx": -0.00003206,
    "vy": 0.00007045,
    "vz": -0.00002918,
    "rarad": 1.97229632330576,
    "decrad": 1.01083367636885,
    "pmrarad": 1.08986115388888e-7,
    "pmdecrad": -0.000004458952623,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118479,
    "lum": 0.0000315645789129906,
    "ra": 7.533617,
    "dec": 57.916503,
    "pmra": 22.48,
    "pmdec": -919.73,
    "rv": 16,
    "Asset Name": "ASTRID 001530",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Pompadour",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003206,
    "y_1": 0.00007045,
    "z_1": -0.00002918,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118479,
    "Gliese Catalog_1": "NN 3444"
  },
  {
    "Astrid #": "ASTRID 001531",
    "HYG Star ID": 118481,
    "Gliese Catalog": "GJ 1098",
    "Distance in Parsecs": 18.1818,
    "mag": 16.38,
    "Absolute Visual Magnitude": 15.082,
    "Spectral Type": "DC9",
    "Color Index": 0.91,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.144937,
    "y": 7.274019,
    "z": 16.36386,
    "vx": -0.00001314,
    "vy": 0.00001509,
    "vz": -0.00000923,
    "rarad": 1.97885608425536,
    "decrad": 1.1197996760002,
    "pmrarad": 3.34085107270833e-7,
    "pmdecrad": -0.000001165079996,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118481,
    "lum": 0.0000807606861328975,
    "ra": 7.558673,
    "dec": 64.159795,
    "pmra": 68.91,
    "pmdec": -240.31,
    "rv": 0,
    "Asset Name": "ASTRID 001531",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Little Crown",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001314,
    "y_1": 0.00001509,
    "z_1": -0.00000923,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118481,
    "Gliese Catalog_1": "GJ 1098"
  },
  {
    "Astrid #": "ASTRID 001532",
    "HYG Star ID": 118528,
    "Gliese Catalog": "NN 3485",
    "Distance in Parsecs": 18.1818,
    "mag": 13.51,
    "Absolute Visual Magnitude": 12.212,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.805609,
    "y": 15.259008,
    "z": 1.261187,
    "vx": -0.00000827,
    "vy": -0.00000286,
    "vz": -0.00002958,
    "rarad": 2.14195966198788,
    "decrad": 0.0694211044003,
    "pmrarad": 4.67699757631944e-7,
    "pmdecrad": -0.000001631111994,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118528,
    "lum": 0.00113553362784193,
    "ra": 8.181683,
    "dec": 3.977536,
    "pmra": 96.47,
    "pmdec": -336.44,
    "rv": 0,
    "Asset Name": "ASTRID 001532",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Pixie",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000827,
    "y_1": -0.00000286,
    "z_1": -0.00002958,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118528,
    "Gliese Catalog_1": "NN 3485"
  },
  {
    "Astrid #": "ASTRID 001533",
    "HYG Star ID": 118591,
    "Gliese Catalog": "GJ 1120B",
    "Distance in Parsecs": 18.1818,
    "mag": 9.49,
    "Absolute Visual Magnitude": 8.192,
    "Spectral Type": "K5",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.472109,
    "y": 12.332441,
    "z": 4.786614,
    "vx": 0.00000967,
    "vy": 0.00000397,
    "vz": -0.00003437,
    "rarad": 2.36182612117304,
    "decrad": 0.26641372469755,
    "pmrarad": -5.29416539166666e-7,
    "pmdecrad": -0.000001764586049,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 2,
    "comp_primary": 44167,
    "base": "GJ 1120",
    "lum": 0.0460468578378573,
    "ra": 9.021511,
    "dec": 15.264382,
    "pmra": -109.2,
    "pmdec": -363.97,
    "rv": -12.7,
    "Asset Name": "ASTRID 001533",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Audiohead",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00000967,
    "y_1": 0.00000397,
    "z_1": -0.00003437,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118591,
    "Gliese Catalog_1": "GJ 1120B"
  },
  {
    "Astrid #": "ASTRID 001534",
    "HYG Star ID": 118672,
    "Gliese Catalog": "NN 3590",
    "Distance in Parsecs": 18.1818,
    "mag": 13.6,
    "Absolute Visual Magnitude": 12.302,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.919512,
    "y": 6.855192,
    "z": 9.477941,
    "vx": -0.000009,
    "vy": 0.00000561,
    "vz": -0.00001728,
    "rarad": 2.68397318942164,
    "decrad": 0.548358684027299,
    "pmrarad": -5.83715671388888e-8,
    "pmdecrad": -0.000001113539454,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118672,
    "lum": 0.00104520144145843,
    "ra": 10.252022,
    "dec": 31.418638,
    "pmra": -12.04,
    "pmdec": -229.68,
    "rv": 0,
    "Asset Name": "ASTRID 001534",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Star of Culture",
    "Hair": "High Fade",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.000009,
    "y_1": 0.00000561,
    "z_1": -0.00001728,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118672,
    "Gliese Catalog_1": "NN 3590"
  },
  {
    "Astrid #": "ASTRID 001535",
    "HYG Star ID": 118687,
    "Gliese Catalog": "NN 3605",
    "Distance in Parsecs": 18.1818,
    "mag": 13.83,
    "Absolute Visual Magnitude": 12.532,
    "Spectral Type": "DA",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -16.753025,
    "y": 7.064986,
    "z": -0.001332,
    "vx": 0.00001608,
    "vy": 0.00003816,
    "vz": 0.00001749,
    "rarad": 2.74250853226643,
    "decrad": -0.0000732506353499999,
    "pmrarad": -0.00000227765467125,
    "pmdecrad": 9.62127293e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118687,
    "lum": 0.000845668200099353,
    "ra": 10.475611,
    "dec": -0.004197,
    "pmra": -469.8,
    "pmdec": 198.45,
    "rv": 0,
    "Asset Name": "ASTRID 001535",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Bangs",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001608,
    "y_1": 0.00003816,
    "z_1": 0.00001749,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 118687,
    "Gliese Catalog_1": "NN 3605"
  },
  {
    "Astrid #": "ASTRID 001536",
    "HYG Star ID": 119231,
    "Gliese Catalog": "NN 4068",
    "Distance in Parsecs": 18.1818,
    "mag": 13.22,
    "Absolute Visual Magnitude": 11.922,
    "Spectral Type": "M4  e",
    "Color Index": 1.72,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.4888,
    "y": -3.090015,
    "z": 17.910632,
    "vx": 0.00000893,
    "vy": 0.00001797,
    "vz": 0.00000286,
    "rarad": 4.86912242131037,
    "decrad": 1.3978716184183,
    "pmrarad": 6.39566207388888e-7,
    "pmdecrad": 9.13408366e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119231,
    "lum": 0.00148320096718187,
    "ra": 18.598678,
    "dec": 80.092144,
    "pmra": 131.92,
    "pmdec": 188.4,
    "rv": 0,
    "Asset Name": "ASTRID 001536",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Smoulder",
    "Hair": "Mohawk",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000893,
    "y_1": 0.00001797,
    "z_1": 0.00000286,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 119231,
    "Gliese Catalog_1": "NN 4068"
  },
  {
    "Astrid #": "ASTRID 001537",
    "HYG Star ID": 119265,
    "Gliese Catalog": "NN 4099",
    "Distance in Parsecs": 18.1818,
    "mag": 11.33,
    "Absolute Visual Magnitude": 10.032,
    "Spectral Type": "M0",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.198431,
    "y": -13.450412,
    "z": 11.490754,
    "vx": 0.00000683,
    "vy": -0.00003455,
    "vz": -0.00004293,
    "rarad": 5.01495009586689,
    "decrad": 0.68412111436225,
    "pmrarad": -2.07742662118055e-7,
    "pmdecrad": -0.000003047252755,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 119265,
    "lum": 0.00845668200099353,
    "ra": 19.155698,
    "dec": 39.197253,
    "pmra": -42.85,
    "pmdec": -628.54,
    "rv": 0,
    "Asset Name": "ASTRID 001537",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Undercut",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00000683,
    "y_1": -0.00003455,
    "z_1": -0.00004293,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 119265,
    "Gliese Catalog_1": "NN 4099"
  },
  {
    "Astrid #": "ASTRID 001538",
    "HYG Star ID": 119328,
    "Gliese Catalog": "NN 4135",
    "Distance in Parsecs": 18.1818,
    "mag": 13.16,
    "Absolute Visual Magnitude": 11.862,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.710511,
    "y": -9.342754,
    "z": 14.51485,
    "vx": 0.00000672,
    "vy": 0.00001953,
    "vz": 0.00000993,
    "rarad": 5.26099132609942,
    "decrad": 0.9244963348665,
    "pmrarad": 8.75621988451389e-7,
    "pmdecrad": 9.0673733e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119328,
    "lum": 0.00156747275180043,
    "ra": 20.095507,
    "dec": 52.969738,
    "pmra": 180.61,
    "pmdec": 187.03,
    "rv": 0,
    "Asset Name": "ASTRID 001538",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Pixie",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000672,
    "y_1": 0.00001953,
    "z_1": 0.00000993,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 119328,
    "Gliese Catalog_1": "NN 4135"
  },
  {
    "Astrid #": "ASTRID 001539",
    "HYG Star ID": 119374,
    "Gliese Catalog": "NN 4165",
    "Distance in Parsecs": 18.1818,
    "mag": 12.93,
    "Absolute Visual Magnitude": 11.632,
    "Spectral Type": "DA4",
    "Color Index": 0.14,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.709533,
    "y": -10.679347,
    "z": 11.062838,
    "vx": 0.00004412,
    "vy": -0.00002711,
    "vz": 0.00005447,
    "rarad": 5.45024682409431,
    "decrad": 0.6539613782261,
    "pmrarad": 7.92621886340277e-7,
    "pmdecrad": 7.13684522e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119374,
    "lum": 0.00193731392458195,
    "ra": 20.818409,
    "dec": 37.469227,
    "pmra": 163.49,
    "pmdec": 147.21,
    "rv": 71,
    "Asset Name": "ASTRID 001539",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Undercut",
    "Outfit": "Astro",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004412,
    "y_1": -0.00002711,
    "z_1": 0.00005447,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 119374,
    "Gliese Catalog_1": "NN 4165"
  },
  {
    "Astrid #": "ASTRID 001540",
    "HYG Star ID": 119610,
    "Gliese Catalog": "NN 4380",
    "Distance in Parsecs": 18.1818,
    "mag": 13.01,
    "Absolute Visual Magnitude": 11.712,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.109274,
    "y": -0.168446,
    "z": 6.149978,
    "vx": 0.00001387,
    "vy": 0.00001153,
    "vz": -0.00003827,
    "rarad": 6.27333828884903,
    "decrad": 0.3450557542719,
    "pmrarad": 6.41456980743055e-7,
    "pmdecrad": -0.000002236954562,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119610,
    "lum": 0.00179969951657735,
    "ra": 23.962387,
    "dec": 19.770238,
    "pmra": 132.31,
    "pmdec": -461.4,
    "rv": 0,
    "Asset Name": "ASTRID 001540",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Unkempt",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001387,
    "y_1": 0.00001153,
    "z_1": -0.00003827,
    "Distance in Parsecs_1": 18.1818,
    "HYG Star ID_1": 119610,
    "Gliese Catalog_1": "NN 4380"
  },
  {
    "Astrid #": "ASTRID 001541",
    "HYG Star ID": 64378,
    "Hipparcos Catalogue": 64583,
    "Henry Draper Catalogue": 114837,
    "Harvard Revised Catalogue": 4989,
    "Gliese Catalog": "Gl 503A",
    "Distance in Parsecs": 18.1984,
    "mag": 4.9,
    "Absolute Visual Magnitude": 3.6,
    "Spectral Type": "F7IV",
    "Color Index": 0.489,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.858292,
    "y": -2.974795,
    "z": -15.615439,
    "vx": 0.00003634,
    "vy": 0.00003544,
    "vz": 0.00004986,
    "rarad": 3.46558993282079,
    "decrad": -1.03154605131706,
    "pmrarad": -0.00000121019190940277,
    "pmdecrad": -7.4952195e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 64378,
    "base": "Gl 503",
    "lum": 3.16227766016837,
    "ra": 13.237578,
    "dec": -59.103235,
    "pmra": -249.62,
    "pmdec": -154.6,
    "rv": -64.8,
    "Asset Name": "ASTRID 001541",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Wavy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00003634,
    "y_1": 0.00003544,
    "z_1": 0.00004986,
    "Distance in Parsecs_1": 18.1984,
    "HYG Star ID_1": 64378,
    "Hipparcos Catalogue_1": 64583,
    "Henry Draper Catalogue_1": 114837,
    "Harvard Revised Catalogue_1": 4989,
    "Gliese Catalog_1": "Gl 503A"
  },
  {
    "Astrid #": "ASTRID 001542",
    "HYG Star ID": 49558,
    "Hipparcos Catalogue": 49699,
    "Henry Draper Catalogue": 87883,
    "Distance in Parsecs": 18.205,
    "mag": 7.56,
    "Absolute Visual Magnitude": 6.259,
    "Spectral Type": "K0",
    "Color Index": 0.965,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.309976,
    "y": 7.023568,
    "z": 10.24383,
    "vx": 1e-8,
    "vy": 0.00000643,
    "vz": -0.0000044,
    "rarad": 2.65603822168121,
    "decrad": 0.597640175850247,
    "pmrarad": -3.12607861222222e-7,
    "pmdecrad": -2.92245686e-7,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 49558,
    "lum": 0.273149242208918,
    "ra": 10.145319,
    "dec": 34.24226,
    "pmra": -64.48,
    "pmdec": -60.28,
    "rv": 0,
    "Asset Name": "ASTRID 001542",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Windy",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": 1e-8,
    "y_1": 0.00000643,
    "z_1": -0.0000044,
    "Distance in Parsecs_1": 18.205,
    "HYG Star ID_1": 49558,
    "Hipparcos Catalogue_1": 49699,
    "Henry Draper Catalogue_1": 87883
  },
  {
    "Astrid #": "ASTRID 001543",
    "HYG Star ID": 119070,
    "Gliese Catalog": "GJ 1199",
    "Distance in Parsecs": 18.2149,
    "mag": 15.09,
    "Absolute Visual Magnitude": 13.788,
    "Spectral Type": "DA6",
    "Color Index": 0.23,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -8.087946,
    "y": -15.775259,
    "z": 4.213432,
    "vx": -0.00006531,
    "vy": -0.00012538,
    "vz": -0.00001538,
    "rarad": 4.23863461924106,
    "decrad": 0.23334458854695,
    "pmrarad": -5.02751786736111e-8,
    "pmdecrad": -0.000002617509061,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119070,
    "lum": 0.000265950003272151,
    "ra": 16.190392,
    "dec": 13.36966,
    "pmra": -10.37,
    "pmdec": -539.9,
    "rv": 131,
    "Asset Name": "ASTRID 001543",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Manbun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006531,
    "y_1": -0.00012538,
    "z_1": -0.00001538,
    "Distance in Parsecs_1": 18.2149,
    "HYG Star ID_1": 119070,
    "Gliese Catalog_1": "GJ 1199"
  },
  {
    "Astrid #": "ASTRID 001544",
    "HYG Star ID": 47556,
    "Hipparcos Catalogue": 47690,
    "Henry Draper Catalogue": 84035,
    "Gliese Catalog": "Gl 365",
    "Distance in Parsecs": 18.2183,
    "mag": 8.13,
    "Absolute Visual Magnitude": 6.827,
    "Spectral Type": "K5V",
    "Color Index": 1.133,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.082604,
    "y": 7.515648,
    "z": 12.35287,
    "vx": -0.00003513,
    "vy": 0.00002016,
    "vz": -0.00006203,
    "rarad": 2.54568283144075,
    "decrad": 0.745108271395394,
    "pmrarad": 1.66678943375e-7,
    "pmdecrad": -0.000004006354812,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 47556,
    "lum": 0.16188253626421,
    "ra": 9.723792,
    "dec": 42.691559,
    "pmra": 34.38,
    "pmdec": -826.37,
    "rv": -12.1,
    "Asset Name": "ASTRID 001544",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Medium Bob",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00003513,
    "y_1": 0.00002016,
    "z_1": -0.00006203,
    "Distance in Parsecs_1": 18.2183,
    "HYG Star ID_1": 47556,
    "Hipparcos Catalogue_1": 47690,
    "Henry Draper Catalogue_1": 84035,
    "Gliese Catalog_1": "Gl 365"
  },
  {
    "Astrid #": "ASTRID 001545",
    "HYG Star ID": 1931,
    "Hipparcos Catalogue": 1936,
    "Henry Draper Catalogue": 2025,
    "Gliese Catalog": "Gl 18",
    "Distance in Parsecs": 18.2249,
    "mag": 7.92,
    "Absolute Visual Magnitude": 6.617,
    "Spectral Type": "K2V",
    "Color Index": 0.94,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.142502,
    "y": 1.727432,
    "z": -8.281533,
    "vx": 4.3e-7,
    "vy": 0.00005935,
    "vz": 0.00000489,
    "rarad": 0.106602278482698,
    "decrad": -0.47170615801315,
    "pmrarad": 0.00000323559802265972,
    "pmdecrad": 4.0685564e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 1931,
    "lum": 0.196426464587985,
    "ra": 0.407191,
    "dec": -27.026772,
    "pmra": 667.39,
    "pmdec": 83.92,
    "rv": 3.7,
    "Asset Name": "ASTRID 001545",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Cute Neko",
    "Hair": "Fedora",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 4.3e-7,
    "y_1": 0.00005935,
    "z_1": 0.00000489,
    "Distance in Parsecs_1": 18.2249,
    "HYG Star ID_1": 1931,
    "Hipparcos Catalogue_1": 1936,
    "Henry Draper Catalogue_1": 2025,
    "Gliese Catalog_1": "Gl 18"
  },
  {
    "Astrid #": "ASTRID 001546",
    "HYG Star ID": 82032,
    "Hipparcos Catalogue": 82283,
    "Gliese Catalog": "NN 3973",
    "Distance in Parsecs": 18.2282,
    "mag": 10.89,
    "Absolute Visual Magnitude": 9.586,
    "Color Index": 1.468,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -5.365836,
    "y": -16.704106,
    "z": -4.944484,
    "vx": 9.1e-7,
    "vy": 0.00000532,
    "vz": -0.00001897,
    "rarad": 4.40157199448085,
    "decrad": -0.274696312623788,
    "pmrarad": -4.1887902e-8,
    "pmdecrad": -0.000001081328433,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82032,
    "lum": 0.0127526370645972,
    "var_min": 10.952,
    "var_max": 10.832,
    "ra": 16.812767,
    "dec": -15.738939,
    "pmra": -8.64,
    "pmdec": -223.04,
    "rv": 0,
    "Asset Name": "ASTRID 001546",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "High Fade",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 9.1e-7,
    "y_1": 0.00000532,
    "z_1": -0.00001897,
    "Distance in Parsecs_1": 18.2282,
    "HYG Star ID_1": 82032,
    "Hipparcos Catalogue_1": 82283,
    "Gliese Catalog_1": "NN 3973"
  },
  {
    "Astrid #": "ASTRID 001547",
    "HYG Star ID": 19871,
    "Hipparcos Catalogue": 19921,
    "Henry Draper Catalogue": 27442,
    "Harvard Revised Catalogue": 1355,
    "Gliese Catalog": "Gl 167.3",
    "Distance in Parsecs": 18.2382,
    "mag": 4.44,
    "Absolute Visual Magnitude": 3.135,
    "Spectral Type": "K2IV",
    "Color Index": 1.078,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.063966,
    "y": 8.377204,
    "z": -15.682734,
    "vx": 0.00000493,
    "vy": 4.3e-7,
    "vz": -0.00003334,
    "rarad": 1.11912369620675,
    "decrad": -1.03501787870107,
    "pmrarad": -2.32662085298611e-7,
    "pmdecrad": -8.13565837e-7,
    "bayer": "Eps",
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 19871,
    "lum": 4.8528850016212,
    "bf": "Eps Ret",
    "ra": 4.274738,
    "dec": -59.302156,
    "pmra": -47.99,
    "pmdec": -167.81,
    "rv": 29.3,
    "Asset Name": "ASTRID 001547",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Little Crown",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": 0.00000493,
    "y_1": 4.3e-7,
    "z_1": -0.00003334,
    "Distance in Parsecs_1": 18.2382,
    "HYG Star ID_1": 19871,
    "Hipparcos Catalogue_1": 19921,
    "Henry Draper Catalogue_1": 27442,
    "Harvard Revised Catalogue_1": 1355,
    "Gliese Catalog_1": "Gl 167.3"
  },
  {
    "Astrid #": "ASTRID 001548",
    "HYG Star ID": 118215,
    "Gliese Catalog": "NN 3221",
    "Distance in Parsecs": 18.2482,
    "mag": 12.19,
    "Absolute Visual Magnitude": 10.884,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.283878,
    "y": 13.856568,
    "z": 3.696816,
    "vx": 0.00001768,
    "vy": -0.00001137,
    "vz": -0.00001134,
    "rarad": 0.887377095624668,
    "decrad": 0.20399720842945,
    "pmrarad": -0.00000114488750663194,
    "pmdecrad": -6.34611411e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118215,
    "lum": 0.0038583355990535,
    "ra": 3.389531,
    "dec": 11.688179,
    "pmra": -236.15,
    "pmdec": -130.9,
    "rv": 0,
    "Asset Name": "ASTRID 001548",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Side Part",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001768,
    "y_1": -0.00001137,
    "z_1": -0.00001134,
    "Distance in Parsecs_1": 18.2482,
    "HYG Star ID_1": 118215,
    "Gliese Catalog_1": "NN 3221"
  },
  {
    "Astrid #": "ASTRID 001549",
    "HYG Star ID": 119104,
    "Gliese Catalog": "Gl 634",
    "Distance in Parsecs": 18.2482,
    "mag": 11.57,
    "Absolute Visual Magnitude": 10.264,
    "Spectral Type": "M3",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.410932,
    "y": -12.364672,
    "z": -12.671897,
    "vx": -0.00001215,
    "vy": 0.00006789,
    "vz": -1.8e-7,
    "rarad": 4.36976185849551,
    "decrad": -0.76772574158505,
    "pmrarad": -0.00000187715008974305,
    "pmdecrad": -0.000002285251701,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 119104,
    "lum": 0.00682967440807963,
    "ra": 16.691261,
    "dec": -43.987445,
    "pmra": -387.19,
    "pmdec": -471.37,
    "rv": -42,
    "Asset Name": "ASTRID 001549",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Glad",
    "Hair": "Audiophile",
    "Outfit": "Viking",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00001215,
    "y_1": 0.00006789,
    "z_1": -1.8e-7,
    "Distance in Parsecs_1": 18.2482,
    "HYG Star ID_1": 119104,
    "Gliese Catalog_1": "Gl 634"
  },
  {
    "Astrid #": "ASTRID 001550",
    "HYG Star ID": 82582,
    "Hipparcos Catalogue": 82834,
    "Henry Draper Catalogue": 152606,
    "Distance in Parsecs": 18.2548,
    "mag": 9.58,
    "Absolute Visual Magnitude": 8.273,
    "Spectral Type": "K4/K5V",
    "Color Index": 1.378,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.287748,
    "y": -14.863408,
    "z": -9.691855,
    "vx": 0.0000124,
    "vy": 0.0000125,
    "vz": -0.00002465,
    "rarad": 4.43153647607274,
    "decrad": -0.55968692939274,
    "pmrarad": 4.63094027666666e-7,
    "pmdecrad": -0.000001593873456,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 82582,
    "lum": 0.042736603149693,
    "ra": 16.927222,
    "dec": -32.067699,
    "pmra": 95.52,
    "pmdec": -328.76,
    "rv": 0,
    "Asset Name": "ASTRID 001550",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Headband",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.0000124,
    "y_1": 0.0000125,
    "z_1": -0.00002465,
    "Distance in Parsecs_1": 18.2548,
    "HYG Star ID_1": 82582,
    "Hipparcos Catalogue_1": 82834,
    "Henry Draper Catalogue_1": 152606
  },
  {
    "Astrid #": "ASTRID 001551",
    "HYG Star ID": 40728,
    "Hipparcos Catalogue": 40843,
    "Henry Draper Catalogue": 69897,
    "Harvard Revised Catalogue": 3262,
    "Gliese Catalog": "Gl 303",
    "Distance in Parsecs": 18.2715,
    "mag": 5.13,
    "Absolute Visual Magnitude": 3.821,
    "Spectral Type": "F6V",
    "Color Index": 0.487,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.32358,
    "y": 13.3075,
    "z": 8.357022,
    "vx": -0.00002463,
    "vy": 0.000038,
    "vz": -0.0000142,
    "rarad": 2.18194238329839,
    "decrad": 0.475038598600402,
    "pmrarad": -8.91087544861111e-8,
    "pmdecrad": -0.000001824159954,
    "bayer": "Chi",
    "flam": 18,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 40728,
    "lum": 2.57988293604037,
    "bf": "18Chi Cnc",
    "ra": 8.334406,
    "dec": 27.217707,
    "pmra": -18.38,
    "pmdec": -376.26,
    "rv": 33,
    "Asset Name": "ASTRID 001551",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Fire",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00002463,
    "y_1": 0.000038,
    "z_1": -0.0000142,
    "Distance in Parsecs_1": 18.2715,
    "HYG Star ID_1": 40728,
    "Hipparcos Catalogue_1": 40843,
    "Henry Draper Catalogue_1": 69897,
    "Harvard Revised Catalogue_1": 3262,
    "Gliese Catalog_1": "Gl 303"
  },
  {
    "Astrid #": "ASTRID 001552",
    "HYG Star ID": 71731,
    "Hipparcos Catalogue": 71957,
    "Henry Draper Catalogue": 129502,
    "Harvard Revised Catalogue": 5487,
    "Gliese Catalog": "Wo 9491",
    "Distance in Parsecs": 18.2715,
    "mag": 3.87,
    "Absolute Visual Magnitude": 2.561,
    "Spectral Type": "F2III",
    "Color Index": 0.385,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.771311,
    "y": -11.872446,
    "z": -1.801463,
    "vx": 0.00000452,
    "vy": -0.00000829,
    "vz": -0.00002867,
    "rarad": 3.85307779456136,
    "decrad": -0.0987543345409349,
    "pmrarad": 5.05321299243055e-7,
    "pmdecrad": -0.000001550918964,
    "bayer": "Mu",
    "flam": 107,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 71731,
    "lum": 8.23379405210134,
    "bf": "107Mu  Vir",
    "ra": 14.717673,
    "dec": -5.658207,
    "pmra": 104.23,
    "pmdec": -319.9,
    "rv": 4.7,
    "Asset Name": "ASTRID 001552",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Laughing",
    "Hair": "Bucket Hat",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000452,
    "y_1": -0.00000829,
    "z_1": -0.00002867,
    "Distance in Parsecs_1": 18.2715,
    "HYG Star ID_1": 71731,
    "Hipparcos Catalogue_1": 71957,
    "Henry Draper Catalogue_1": 129502,
    "Harvard Revised Catalogue_1": 5487,
    "Gliese Catalog_1": "Wo 9491"
  },
  {
    "Astrid #": "ASTRID 001553",
    "HYG Star ID": 80198,
    "Hipparcos Catalogue": 80440,
    "Gliese Catalog": "Gl 622",
    "Distance in Parsecs": 18.2715,
    "mag": 10.4,
    "Absolute Visual Magnitude": 9.091,
    "Spectral Type": "K5",
    "Color Index": 1.444,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.811284,
    "y": -15.519616,
    "z": -6.826102,
    "vx": -0.00004203,
    "vy": 0.00003077,
    "vz": -0.00002802,
    "rarad": 4.29882086468762,
    "decrad": -0.382879342021883,
    "pmrarad": -0.00000278312141459722,
    "pmdecrad": -0.000001653602501,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 80198,
    "lum": 0.0201187039514943,
    "ra": 16.420286,
    "dec": -21.93737,
    "pmra": -574.06,
    "pmdec": -341.08,
    "rv": 0,
    "Asset Name": "ASTRID 001553",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Heartbreaker",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00004203,
    "y_1": 0.00003077,
    "z_1": -0.00002802,
    "Distance in Parsecs_1": 18.2715,
    "HYG Star ID_1": 80198,
    "Hipparcos Catalogue_1": 80440,
    "Gliese Catalog_1": "Gl 622"
  },
  {
    "Astrid #": "ASTRID 001554",
    "HYG Star ID": 109076,
    "Hipparcos Catalogue": 109422,
    "Henry Draper Catalogue": 210302,
    "Harvard Revised Catalogue": 8447,
    "Gliese Catalog": "Gl 849.1",
    "Distance in Parsecs": 18.2782,
    "mag": 4.94,
    "Absolute Visual Magnitude": 3.63,
    "Spectral Type": "F6V",
    "Color Index": 0.489,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.670951,
    "y": -7.105556,
    "z": -9.83382,
    "vx": 0.00000693,
    "vy": 0.0000392,
    "vz": 0.00000907,
    "rarad": 5.80385487757347,
    "decrad": -0.568076864314844,
    "pmrarad": 0.00000207800839759722,
    "pmdecrad": 6.7049732e-8,
    "bayer": "Tau",
    "flam": 15,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 109076,
    "lum": 3.0760968147407,
    "bf": "15Tau PsA",
    "ra": 22.169093,
    "dec": -32.548407,
    "pmra": 428.62,
    "pmdec": 13.83,
    "rv": -14.6,
    "Asset Name": "ASTRID 001554",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Bangs",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000693,
    "y_1": 0.0000392,
    "z_1": 0.00000907,
    "Distance in Parsecs_1": 18.2782,
    "HYG Star ID_1": 109076,
    "Hipparcos Catalogue_1": 109422,
    "Henry Draper Catalogue_1": 210302,
    "Harvard Revised Catalogue_1": 8447,
    "Gliese Catalog_1": "Gl 849.1"
  },
  {
    "Astrid #": "ASTRID 001555",
    "HYG Star ID": 54650,
    "Hipparcos Catalogue": 54810,
    "Henry Draper Catalogue": 97503,
    "Gliese Catalog": "Gl 418",
    "Distance in Parsecs": 18.2815,
    "mag": 8.7,
    "Absolute Visual Magnitude": 7.39,
    "Spectral Type": "K5V",
    "Color Index": 1.179,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.847422,
    "y": 3.694354,
    "z": 1.428748,
    "vx": -0.00001528,
    "vy": 0.00003192,
    "vz": -0.00000122,
    "rarad": 2.93747884836148,
    "decrad": 0.0782316809174012,
    "pmrarad": -0.00000154030154449305,
    "pmdecrad": -1.57952297e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54650,
    "lum": 0.096382902362397,
    "ra": 11.220343,
    "dec": 4.482345,
    "pmra": -317.71,
    "pmdec": -32.58,
    "rv": 20.8,
    "Asset Name": "ASTRID 001555",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Innocent",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00001528,
    "y_1": 0.00003192,
    "z_1": -0.00000122,
    "Distance in Parsecs_1": 18.2815,
    "HYG Star ID_1": 54650,
    "Hipparcos Catalogue_1": 54810,
    "Henry Draper Catalogue_1": 97503,
    "Gliese Catalog_1": "Gl 418"
  },
  {
    "Astrid #": "ASTRID 001556",
    "HYG Star ID": 60983,
    "Hipparcos Catalogue": 61174,
    "Henry Draper Catalogue": 109085,
    "Harvard Revised Catalogue": 4775,
    "Gliese Catalog": "Gl 471.2",
    "Distance in Parsecs": 18.2815,
    "mag": 4.3,
    "Absolute Visual Magnitude": 2.99,
    "Spectral Type": "F2V",
    "Color Index": 0.388,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.384353,
    "y": -2.448661,
    "z": -5.09915,
    "vx": -0.00000304,
    "vy": 0.00003755,
    "vz": -0.00000474,
    "rarad": 3.2815271257166,
    "decrad": -0.282673655822112,
    "pmrarad": -0.00000205740381617361,
    "pmdecrad": -2.8317967e-7,
    "bayer": "Eta",
    "flam": 8,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60983,
    "lum": 5.5462571295791,
    "bf": "8Eta Crv",
    "ra": 12.53451,
    "dec": -16.196007,
    "pmra": -424.37,
    "pmdec": -58.41,
    "rv": -0.8,
    "Asset Name": "ASTRID 001556",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Frizzy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000304,
    "y_1": 0.00003755,
    "z_1": -0.00000474,
    "Distance in Parsecs_1": 18.2815,
    "HYG Star ID_1": 60983,
    "Hipparcos Catalogue_1": 61174,
    "Henry Draper Catalogue_1": 109085,
    "Harvard Revised Catalogue_1": 4775,
    "Gliese Catalog_1": "Gl 471.2"
  },
  {
    "Astrid #": "ASTRID 001557",
    "HYG Star ID": 61220,
    "Hipparcos Catalogue": 61413,
    "Gliese Catalog": "Gl 476",
    "Distance in Parsecs": 18.2849,
    "mag": 11.4,
    "Absolute Visual Magnitude": 10.09,
    "Spectral Type": "M4",
    "Color Index": 1.429,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.806993,
    "y": -2.741694,
    "z": 3.121273,
    "vx": -0.00004331,
    "vy": 0.00003362,
    "vz": -0.00002221,
    "rarad": 3.29436054054986,
    "decrad": 0.171539503245451,
    "pmrarad": -0.00000217788001579166,
    "pmdecrad": -0.000001548543377,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 61220,
    "lum": 0.00801678063387678,
    "var_min": 11.471,
    "var_max": 11.321,
    "ra": 12.58353,
    "dec": 9.82849,
    "pmra": -449.22,
    "pmdec": -319.41,
    "rv": 32.6,
    "Asset Name": "ASTRID 001557",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Slick",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00004331,
    "y_1": 0.00003362,
    "z_1": -0.00002221,
    "Distance in Parsecs_1": 18.2849,
    "HYG Star ID_1": 61220,
    "Hipparcos Catalogue_1": 61413,
    "Gliese Catalog_1": "Gl 476"
  },
  {
    "Astrid #": "ASTRID 001558",
    "HYG Star ID": 110373,
    "Hipparcos Catalogue": 110719,
    "Henry Draper Catalogue": 212168,
    "Harvard Revised Catalogue": 8526,
    "Distance in Parsecs": 18.2849,
    "mag": 9.35,
    "Absolute Visual Magnitude": 8.04,
    "Color Index": 1.12,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.335376,
    "y": -1.886467,
    "z": -17.66318,
    "vx": 0.00000875,
    "vy": -0.00000234,
    "vz": -0.00001243,
    "rarad": 5.87276093895377,
    "decrad": -1.3092508887322,
    "pmrarad": 7.35947167083333e-8,
    "pmdecrad": 2.97287748e-7,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 110373,
    "lum": 0.0529663443891658,
    "ra": 22.432294,
    "dec": -75.01455,
    "pmra": 15.18,
    "pmdec": 61.32,
    "rv": 14,
    "Asset Name": "ASTRID 001558",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Bandana",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": 0.00000875,
    "y_1": -0.00000234,
    "z_1": -0.00001243,
    "Distance in Parsecs_1": 18.2849,
    "HYG Star ID_1": 110373,
    "Hipparcos Catalogue_1": 110719,
    "Henry Draper Catalogue_1": 212168,
    "Harvard Revised Catalogue_1": 8526
  },
  {
    "Astrid #": "ASTRID 001559",
    "HYG Star ID": 18868,
    "Hipparcos Catalogue": 18915,
    "Henry Draper Catalogue": 25329,
    "Gliese Catalog": "Gl 158",
    "Distance in Parsecs": 18.2882,
    "mag": 8.51,
    "Absolute Visual Magnitude": 7.199,
    "Spectral Type": "K1V...",
    "Color Index": 0.863,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.281111,
    "y": 13.034688,
    "z": 10.560881,
    "vx": -0.00011041,
    "vy": 0.00011729,
    "vz": -0.00011395,
    "rarad": 1.06136174129967,
    "decrad": 0.615634797855438,
    "pmrarad": 0.00000839934853425694,
    "pmdecrad": -0.000006620130807,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 18868,
    "lum": 0.114921159720324,
    "ra": 4.054103,
    "dec": 35.273276,
    "pmra": 1732.49,
    "pmdec": -1365.5,
    "rv": -25.6,
    "Asset Name": "ASTRID 001559",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Medium Bob",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00011041,
    "y_1": 0.00011729,
    "z_1": -0.00011395,
    "Distance in Parsecs_1": 18.2882,
    "HYG Star ID_1": 18868,
    "Hipparcos Catalogue_1": 18915,
    "Henry Draper Catalogue_1": 25329,
    "Gliese Catalog_1": "Gl 158"
  },
  {
    "Astrid #": "ASTRID 001560",
    "HYG Star ID": 82765,
    "Hipparcos Catalogue": 83020,
    "Henry Draper Catalogue": 153557,
    "Gliese Catalog": "Gl 649.1A",
    "Distance in Parsecs": 18.305,
    "mag": 7.76,
    "Absolute Visual Magnitude": 6.447,
    "Spectral Type": "K0",
    "Color Index": 0.98,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.319148,
    "y": -11.945452,
    "z": 13.467008,
    "vx": -0.00000658,
    "vy": 0.00002502,
    "vz": 0.00001139,
    "rarad": 4.44136997032615,
    "decrad": 0.826704663474041,
    "pmrarad": -7.12191296736111e-7,
    "pmdecrad": 0.000001319711319,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 82765,
    "base": "Gl 649.1",
    "lum": 0.229720630715835,
    "var": "V1090",
    "var_min": 7.782,
    "var_max": 7.732,
    "ra": 16.964784,
    "dec": 47.366688,
    "pmra": -146.9,
    "pmdec": 272.21,
    "rv": -6.6,
    "Asset Name": "ASTRID 001560",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Glad",
    "Hair": "Braided Bun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000658,
    "y_1": 0.00002502,
    "z_1": 0.00001139,
    "Distance in Parsecs_1": 18.305,
    "HYG Star ID_1": 82765,
    "Hipparcos Catalogue_1": 83020,
    "Henry Draper Catalogue_1": 153557,
    "Gliese Catalog_1": "Gl 649.1A"
  },
  {
    "Astrid #": "ASTRID 001561",
    "HYG Star ID": 26330,
    "Hipparcos Catalogue": 26394,
    "Henry Draper Catalogue": 39091,
    "Harvard Revised Catalogue": 2022,
    "Gliese Catalog": "Wo 9189",
    "Distance in Parsecs": 18.315,
    "mag": 5.65,
    "Absolute Visual Magnitude": 4.336,
    "Spectral Type": "G3IV",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.301659,
    "y": 3.017555,
    "z": -18.062272,
    "vx": -0.00001825,
    "vy": 0.00009584,
    "vz": 0.00000595,
    "rarad": 1.47109254414084,
    "decrad": -1.40445112268307,
    "pmrarad": 0.00000151247323922916,
    "pmdecrad": 0.000005091464791,
    "bayer": "Pi",
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 26330,
    "lum": 1.60546188680097,
    "bf": "Pi  Men",
    "ra": 5.61916,
    "dec": -80.469122,
    "pmra": 311.97,
    "pmdec": 1050.19,
    "rv": 9.4,
    "Asset Name": "ASTRID 001561",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Windy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": -0.00001825,
    "y_1": 0.00009584,
    "z_1": 0.00000595,
    "Distance in Parsecs_1": 18.315,
    "HYG Star ID_1": 26330,
    "Hipparcos Catalogue_1": 26394,
    "Henry Draper Catalogue_1": 39091,
    "Harvard Revised Catalogue_1": 2022,
    "Gliese Catalog_1": "Wo 9189"
  },
  {
    "Astrid #": "ASTRID 001562",
    "HYG Star ID": 118442,
    "Gliese Catalog": "NN 3410",
    "Distance in Parsecs": 18.315,
    "mag": 15.68,
    "Absolute Visual Magnitude": 14.366,
    "Spectral Type": "DA8",
    "Color Index": 0.32,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -3.754964,
    "y": 17.907851,
    "z": 0.805213,
    "vx": 0.00003725,
    "vy": 0.00000767,
    "vz": 0.00000332,
    "rarad": 1.77748458660589,
    "decrad": 0.0439788704386999,
    "pmrarad": -0.00000207674788202777,
    "pmdecrad": 1.81688774e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118442,
    "lum": 0.000156170859326537,
    "ra": 6.789491,
    "dec": 2.519804,
    "pmra": -428.36,
    "pmdec": 37.48,
    "rv": 0,
    "Asset Name": "ASTRID 001562",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Determined Neko",
    "Hair": "Innocent",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003725,
    "y_1": 0.00000767,
    "z_1": 0.00000332,
    "Distance in Parsecs_1": 18.315,
    "HYG Star ID_1": 118442,
    "Gliese Catalog_1": "NN 3410"
  },
  {
    "Astrid #": "ASTRID 001563",
    "HYG Star ID": 33603,
    "Hipparcos Catalogue": 33690,
    "Henry Draper Catalogue": 53143,
    "Gliese Catalog": "Gl 260",
    "Distance in Parsecs": 18.3251,
    "mag": 6.81,
    "Absolute Visual Magnitude": 5.495,
    "Spectral Type": "K0IV-V",
    "Color Index": 0.786,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.274828,
    "y": 8.490625,
    "z": -16.079513,
    "vx": 0.00000579,
    "vy": 0.00003372,
    "vz": -0.00000784,
    "rarad": 1.83257810612724,
    "decrad": -1.07051832389653,
    "pmrarad": -7.814711716875e-7,
    "pmdecrad": 0.00000128252611,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 33603,
    "lum": 0.552077439280757,
    "ra": 6.999933,
    "dec": -61.336182,
    "pmra": -161.19,
    "pmdec": 264.54,
    "rv": 21.3,
    "Asset Name": "ASTRID 001563",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Windy",
    "Outfit": "Toga",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00000579,
    "y_1": 0.00003372,
    "z_1": -0.00000784,
    "Distance in Parsecs_1": 18.3251,
    "HYG Star ID_1": 33603,
    "Hipparcos Catalogue_1": 33690,
    "Henry Draper Catalogue_1": 53143,
    "Gliese Catalog_1": "Gl 260"
  },
  {
    "Astrid #": "ASTRID 001564",
    "HYG Star ID": 84358,
    "Hipparcos Catalogue": 84616,
    "Henry Draper Catalogue": 156985,
    "Distance in Parsecs": 18.3251,
    "mag": 7.93,
    "Absolute Visual Magnitude": 6.615,
    "Spectral Type": "K2",
    "Color Index": 1.019,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.043041,
    "y": -10.980582,
    "z": 14.527977,
    "vx": -0.00000101,
    "vy": -0.00001379,
    "vz": -0.00001057,
    "rarad": 4.52843253261407,
    "decrad": 0.915374818079192,
    "pmrarad": 8.37273226319444e-8,
    "pmdecrad": -9.46162378e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 84358,
    "lum": 0.196788628970684,
    "ra": 17.297338,
    "dec": 52.447114,
    "pmra": 17.27,
    "pmdec": -195.16,
    "rv": 0,
    "Asset Name": "ASTRID 001564",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Pixie",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000101,
    "y_1": -0.00001379,
    "z_1": -0.00001057,
    "Distance in Parsecs_1": 18.3251,
    "HYG Star ID_1": 84358,
    "Hipparcos Catalogue_1": 84616,
    "Henry Draper Catalogue_1": 156985
  },
  {
    "Astrid #": "ASTRID 001565",
    "HYG Star ID": 60213,
    "Hipparcos Catalogue": 60398,
    "Gliese Catalog": "Gl 463",
    "Distance in Parsecs": 18.3352,
    "mag": 11.63,
    "Absolute Visual Magnitude": 10.314,
    "Spectral Type": "M4",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.988378,
    "y": -0.804484,
    "z": 16.483953,
    "vx": 0.00002048,
    "vy": 0.00006167,
    "vz": 0.0000217,
    "rarad": 3.24199747115311,
    "decrad": 1.11754882100791,
    "pmrarad": -0.00000323472535803472,
    "pmdecrad": 0.000001820572333,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 60213,
    "lum": 0.00652228842465638,
    "var_min": 11.697,
    "var_max": 11.537,
    "ra": 12.383518,
    "dec": 64.030831,
    "pmra": -667.21,
    "pmdec": 375.52,
    "rv": 7.7,
    "Asset Name": "ASTRID 001565",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Ram Horns",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00002048,
    "y_1": 0.00006167,
    "z_1": 0.0000217,
    "Distance in Parsecs_1": 18.3352,
    "HYG Star ID_1": 60213,
    "Hipparcos Catalogue_1": 60398,
    "Gliese Catalog_1": "Gl 463"
  },
  {
    "Astrid #": "ASTRID 001566",
    "HYG Star ID": 96138,
    "Hipparcos Catalogue": 96441,
    "Henry Draper Catalogue": 185395,
    "Harvard Revised Catalogue": 7469,
    "Gliese Catalog": "Gl 765A",
    "Distance in Parsecs": 18.3352,
    "mag": 4.49,
    "Absolute Visual Magnitude": 3.174,
    "Spectral Type": "F4V",
    "Color Index": 0.395,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.792178,
    "y": -10.707751,
    "z": 14.090766,
    "vx": -0.0000153,
    "vy": 0.00003241,
    "vz": -0.0000065,
    "rarad": 5.13319786726724,
    "decrad": 0.876523593926398,
    "pmrarad": -3.95123149652777e-8,
    "pmdecrad": 0.000001274963017,
    "bayer": "The",
    "flam": 13,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96138,
    "base": "Gl 765",
    "lum": 4.68166139724364,
    "bf": "13The Cyg",
    "ra": 19.607372,
    "dec": 50.221103,
    "pmra": -8.15,
    "pmdec": 262.98,
    "rv": -27.3,
    "Asset Name": "ASTRID 001566",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Windy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.0000153,
    "y_1": 0.00003241,
    "z_1": -0.0000065,
    "Distance in Parsecs_1": 18.3352,
    "HYG Star ID_1": 96138,
    "Hipparcos Catalogue_1": 96441,
    "Henry Draper Catalogue_1": 185395,
    "Harvard Revised Catalogue_1": 7469,
    "Gliese Catalog_1": "Gl 765A"
  },
  {
    "Astrid #": "ASTRID 001567",
    "HYG Star ID": 43387,
    "Hipparcos Catalogue": 43510,
    "Gliese Catalog": "GJ 1114",
    "Distance in Parsecs": 18.3385,
    "mag": 11.54,
    "Absolute Visual Magnitude": 10.223,
    "Color Index": 1.481,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.87121,
    "y": 12.760433,
    "z": 5.704936,
    "vx": 0.00005738,
    "vy": 0.00005547,
    "vz": -0.00000466,
    "rarad": 2.32011132273352,
    "decrad": 0.316340426622563,
    "pmrarad": -0.00000435168759666666,
    "pmdecrad": -2.67471707e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 43387,
    "lum": 0.00709251070555853,
    "var_min": 11.635,
    "var_max": 11.425,
    "ra": 8.862172,
    "dec": 18.124971,
    "pmra": -897.6,
    "pmdec": -55.17,
    "rv": 0,
    "Asset Name": "ASTRID 001567",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Gimme Blood",
    "Hair": "Hachimaki",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00005738,
    "y_1": 0.00005547,
    "z_1": -0.00000466,
    "Distance in Parsecs_1": 18.3385,
    "HYG Star ID_1": 43387,
    "Hipparcos Catalogue_1": 43510,
    "Gliese Catalog_1": "GJ 1114"
  },
  {
    "Astrid #": "ASTRID 001568",
    "HYG Star ID": 118872,
    "Gliese Catalog": "NN 3768",
    "Distance in Parsecs": 18.3486,
    "mag": 16,
    "Absolute Visual Magnitude": 14.682,
    "Spectral Type": "DC9",
    "Color Index": 0.78,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.515518,
    "y": -0.467742,
    "z": 18.279923,
    "vx": -0.00001455,
    "vy": -0.00002543,
    "vz": -0.00000185,
    "rarad": 3.44037643708347,
    "decrad": 1.48424808107875,
    "pmrarad": 0.00000109112166945833,
    "pmdecrad": -0.000001170078425,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118872,
    "lum": 0.000116734707649219,
    "ra": 13.14127,
    "dec": 85.041151,
    "pmra": 225.06,
    "pmdec": -241.35,
    "rv": 0,
    "Asset Name": "ASTRID 001568",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Bob",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001455,
    "y_1": -0.00002543,
    "z_1": -0.00000185,
    "Distance in Parsecs_1": 18.3486,
    "HYG Star ID_1": 118872,
    "Gliese Catalog_1": "NN 3768"
  },
  {
    "Astrid #": "ASTRID 001569",
    "HYG Star ID": 33341,
    "Hipparcos Catalogue": 33428,
    "Henry Draper Catalogue": 266611,
    "Gliese Catalog": "Gl 254",
    "Distance in Parsecs": 18.3587,
    "mag": 9.68,
    "Absolute Visual Magnitude": 8.361,
    "Spectral Type": "M0",
    "Color Index": 1.359,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.888639,
    "y": 15.28971,
    "z": 9.388427,
    "vx": -0.00000794,
    "vy": 0.00000145,
    "vz": -0.00002125,
    "rarad": 1.81984602365821,
    "decrad": 0.536801995471782,
    "pmrarad": 3.99389510041666e-7,
    "pmdecrad": -0.000001088746082,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 33341,
    "lum": 0.0394094160732285,
    "ra": 6.9513,
    "dec": 30.756489,
    "pmra": 82.38,
    "pmdec": -224.57,
    "rv": -7.8,
    "Asset Name": "ASTRID 001569",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Tiny Crown",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000794,
    "y_1": 0.00000145,
    "z_1": -0.00002125,
    "Distance in Parsecs_1": 18.3587,
    "HYG Star ID_1": 33341,
    "Hipparcos Catalogue_1": 33428,
    "Henry Draper Catalogue_1": 266611,
    "Gliese Catalog_1": "Gl 254"
  },
  {
    "Astrid #": "ASTRID 001570",
    "HYG Star ID": 47977,
    "Hipparcos Catalogue": 48113,
    "Henry Draper Catalogue": 84737,
    "Harvard Revised Catalogue": 3881,
    "Gliese Catalog": "Gl 368",
    "Distance in Parsecs": 18.3688,
    "mag": 5.08,
    "Absolute Visual Magnitude": 3.76,
    "Spectral Type": "G2V",
    "Color Index": 0.619,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.715267,
    "y": 6.919455,
    "z": 13.218119,
    "vx": -0.0000187,
    "vy": -0.00001147,
    "vz": -0.00000205,
    "rarad": 2.56820212947522,
    "decrad": 0.803218114948737,
    "pmrarad": 0.00000107662574040972,
    "pmdecrad": -4.4903443e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 47977,
    "lum": 2.72897778280804,
    "ra": 9.80981,
    "dec": 46.021008,
    "pmra": 222.07,
    "pmdec": -92.62,
    "rv": 5,
    "Asset Name": "ASTRID 001570",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Pirate Hat",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.0000187,
    "y_1": -0.00001147,
    "z_1": -0.00000205,
    "Distance in Parsecs_1": 18.3688,
    "HYG Star ID_1": 47977,
    "Hipparcos Catalogue_1": 48113,
    "Henry Draper Catalogue_1": 84737,
    "Harvard Revised Catalogue_1": 3881,
    "Gliese Catalog_1": "Gl 368"
  },
  {
    "Astrid #": "ASTRID 001571",
    "HYG Star ID": 105805,
    "Hipparcos Catalogue": 106147,
    "Henry Draper Catalogue": 204587,
    "Gliese Catalog": "Gl 830",
    "Distance in Parsecs": 18.406,
    "mag": 9.11,
    "Absolute Visual Magnitude": 7.785,
    "Spectral Type": "K4/K5V",
    "Color Index": 1.261,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.257376,
    "y": -10.935535,
    "z": -3.986783,
    "vx": -0.00001549,
    "vy": 0.00012604,
    "vz": -0.00000406,
    "rarad": 5.6288860147266,
    "decrad": -0.218341909146041,
    "pmrarad": 0.00000492168304089583,
    "pmdecrad": -0.000001262988119,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 105805,
    "lum": 0.0669884609416526,
    "ra": 21.500761,
    "dec": -12.51007,
    "pmra": 1015.17,
    "pmdec": -260.51,
    "rv": -84.1,
    "Asset Name": "ASTRID 001571",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Grecian",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00001549,
    "y_1": 0.00012604,
    "z_1": -0.00000406,
    "Distance in Parsecs_1": 18.406,
    "HYG Star ID_1": 105805,
    "Hipparcos Catalogue_1": 106147,
    "Henry Draper Catalogue_1": 204587,
    "Gliese Catalog_1": "Gl 830"
  },
  {
    "Astrid #": "ASTRID 001572",
    "HYG Star ID": 119210,
    "Gliese Catalog": "GJ 1225",
    "Distance in Parsecs": 18.4162,
    "mag": 15.39,
    "Absolute Visual Magnitude": 14.064,
    "Spectral Type": "m",
    "Color Index": 1.88,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.504466,
    "y": -6.716945,
    "z": 17.140148,
    "vx": -0.00005177,
    "vy": -0.0001369,
    "vz": -0.00005205,
    "rarad": 4.78764338828607,
    "decrad": 1.19634990528349,
    "pmrarad": -0.00000336373427843055,
    "pmdecrad": -0.00000773609433,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119210,
    "lum": 0.000206252869784657,
    "ra": 18.287451,
    "dec": 68.5458,
    "pmra": -693.82,
    "pmdec": -1595.68,
    "rv": 0,
    "Asset Name": "ASTRID 001572",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Audiophile",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005177,
    "y_1": -0.0001369,
    "z_1": -0.00005205,
    "Distance in Parsecs_1": 18.4162,
    "HYG Star ID_1": 119210,
    "Gliese Catalog_1": "GJ 1225"
  },
  {
    "Astrid #": "ASTRID 001573",
    "HYG Star ID": 90663,
    "Hipparcos Catalogue": 90945,
    "Henry Draper Catalogue": 170573,
    "Distance in Parsecs": 18.4366,
    "mag": 8.5,
    "Absolute Visual Magnitude": 7.172,
    "Spectral Type": "K4Vp...",
    "Color Index": 1.115,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.549083,
    "y": -10.656126,
    "z": -14.965143,
    "vx": -0.00001241,
    "vy": 0.00000854,
    "vz": -0.00000736,
    "rarad": 4.85675166734634,
    "decrad": -0.94707128550984,
    "pmrarad": -5.99617560111111e-7,
    "pmdecrad": -6.83829696e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 90663,
    "lum": 0.117814840600292,
    "ra": 18.551425,
    "dec": -54.263188,
    "pmra": -123.68,
    "pmdec": -141.05,
    "rv": 0,
    "Asset Name": "ASTRID 001573",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Spiky",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.00001241,
    "y_1": 0.00000854,
    "z_1": -0.00000736,
    "Distance in Parsecs_1": 18.4366,
    "HYG Star ID_1": 90663,
    "Hipparcos Catalogue_1": 90945,
    "Henry Draper Catalogue_1": 170573
  },
  {
    "Astrid #": "ASTRID 001574",
    "HYG Star ID": 33866,
    "Hipparcos Catalogue": 33955,
    "Henry Draper Catalogue": 52919,
    "Gliese Catalog": "GJ 1094",
    "Distance in Parsecs": 18.4434,
    "mag": 8.38,
    "Absolute Visual Magnitude": 7.051,
    "Spectral Type": "K5V",
    "Color Index": 1.078,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.949176,
    "y": 17.632269,
    "z": -2.183524,
    "vx": 0.00001818,
    "vy": 0.00000166,
    "vz": -0.00002782,
    "rarad": 1.84444347112678,
    "decrad": -0.118668873682095,
    "pmrarad": -9.73699796027777e-7,
    "pmdecrad": -0.000001519163668,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 33866,
    "lum": 0.131704313819984,
    "ra": 7.045255,
    "dec": -6.799226,
    "pmra": -200.84,
    "pmdec": -313.35,
    "rv": 0,
    "Asset Name": "ASTRID 001574",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Lovestruck",
    "Hair": "Windy",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00001818,
    "y_1": 0.00000166,
    "z_1": -0.00002782,
    "Distance in Parsecs_1": 18.4434,
    "HYG Star ID_1": 33866,
    "Hipparcos Catalogue_1": 33955,
    "Henry Draper Catalogue_1": 52919,
    "Gliese Catalog_1": "GJ 1094"
  },
  {
    "Astrid #": "ASTRID 001575",
    "HYG Star ID": 119444,
    "Gliese Catalog": "NN 4225",
    "Distance in Parsecs": 18.4502,
    "mag": 11.99,
    "Absolute Visual Magnitude": 10.66,
    "Spectral Type": "m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.681588,
    "y": -8.862942,
    "z": 8.641315,
    "vx": 0.00001415,
    "vy": -0.00002997,
    "vz": -0.00005312,
    "rarad": 5.70836397736816,
    "decrad": 0.48743239590995,
    "pmrarad": -9.46792636756944e-7,
    "pmdecrad": -0.000003258941801,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119444,
    "lum": 0.00474241985260244,
    "ra": 21.804344,
    "dec": 27.927819,
    "pmra": -195.29,
    "pmdec": -672.21,
    "rv": 0,
    "Asset Name": "ASTRID 001575",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Ponytail",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001415,
    "y_1": -0.00002997,
    "z_1": -0.00005312,
    "Distance in Parsecs_1": 18.4502,
    "HYG Star ID_1": 119444,
    "Gliese Catalog_1": "NN 4225"
  },
  {
    "Astrid #": "ASTRID 001576",
    "HYG Star ID": 119397,
    "Gliese Catalog": "Gl 822B",
    "Distance in Parsecs": 18.4809,
    "mag": 5.4,
    "Absolute Visual Magnitude": 4.066,
    "Spectral Type": "F7 V",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.656251,
    "y": -12.030763,
    "z": 3.21111,
    "vx": 0.00000495,
    "vy": -0.00000143,
    "vz": -0.00002638,
    "rarad": 5.56098327536013,
    "decrad": 0.17463928953645,
    "pmrarad": 1.19167202680555e-7,
    "pmdecrad": -0.000001449549271,
    "con": "Equ",
    "Full Constellation Name Formula 1": "Equuleus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Equuleus",
    "comp": 2,
    "comp_primary": 104520,
    "base": "Gl 822",
    "lum": 2.05873287673352,
    "ra": 21.241391,
    "dec": 10.006094,
    "pmra": 24.58,
    "pmdec": -298.99,
    "rv": 0,
    "Asset Name": "ASTRID 001576",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Guilty Grin",
    "Hair": "Wavy",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Equuleus",
    "x_1": 0.00000495,
    "y_1": -0.00000143,
    "z_1": -0.00002638,
    "Distance in Parsecs_1": 18.4809,
    "HYG Star ID_1": 119397,
    "Gliese Catalog_1": "Gl 822B"
  },
  {
    "Astrid #": "ASTRID 001577",
    "HYG Star ID": 118958,
    "Gliese Catalog": "Gl 543",
    "Distance in Parsecs": 18.4843,
    "mag": 13.4,
    "Absolute Visual Magnitude": 12.066,
    "Spectral Type": "M3",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.052252,
    "y": -10.459245,
    "z": -2.349456,
    "vx": 0.00003102,
    "vy": 0.00014299,
    "vz": -0.00005537,
    "rarad": 3.74886457942171,
    "decrad": -0.1274845809056,
    "pmrarad": -0.00000539389156575694,
    "pmdecrad": -0.000003707118112,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118958,
    "lum": 0.00129897262889785,
    "ra": 14.319608,
    "dec": -7.304328,
    "pmra": -1112.57,
    "pmdec": -764.65,
    "rv": -97,
    "Asset Name": "ASTRID 001577",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Stunned",
    "Hair": "Manbun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003102,
    "y_1": 0.00014299,
    "z_1": -0.00005537,
    "Distance in Parsecs_1": 18.4843,
    "HYG Star ID_1": 118958,
    "Gliese Catalog_1": "Gl 543"
  },
  {
    "Astrid #": "ASTRID 001578",
    "HYG Star ID": 104520,
    "Hipparcos Catalogue": 104858,
    "Henry Draper Catalogue": 202275,
    "Harvard Revised Catalogue": 8123,
    "Gliese Catalog": "Gl 822A",
    "Distance in Parsecs": 18.4877,
    "mag": 4.47,
    "Absolute Visual Magnitude": 3.136,
    "Spectral Type": "F5V+...",
    "Color Index": 0.529,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.660962,
    "y": -12.035259,
    "z": 3.212549,
    "vx": -0.00000589,
    "vy": 0.00001024,
    "vz": -0.00002959,
    "rarad": 5.56096913026505,
    "decrad": 0.174654767136187,
    "pmrarad": 2.05173149611111e-7,
    "pmdecrad": -0.00000147107015,
    "bayer": "Del",
    "flam": 7,
    "con": "Equ",
    "Full Constellation Name Formula 1": "Equuleus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Equuleus",
    "comp": 1,
    "comp_primary": 104520,
    "base": "Gl 822",
    "lum": 4.84841738708446,
    "bf": "7Del Equ",
    "ra": 21.241337,
    "dec": 10.006981,
    "pmra": 42.32,
    "pmdec": -303.43,
    "rv": -15.8,
    "Asset Name": "ASTRID 001578",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Knocked Out",
    "Hair": "Little Crown",
    "Outfit": "Wizard",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Equuleus",
    "x_1": -0.00000589,
    "y_1": 0.00001024,
    "z_1": -0.00002959,
    "Distance in Parsecs_1": 18.4877,
    "HYG Star ID_1": 104520,
    "Hipparcos Catalogue_1": 104858,
    "Henry Draper Catalogue_1": 202275,
    "Harvard Revised Catalogue_1": 8123,
    "Gliese Catalog_1": "Gl 822A"
  },
  {
    "Astrid #": "ASTRID 001579",
    "HYG Star ID": 118475,
    "Gliese Catalog": "Gl 274B",
    "Distance in Parsecs": 18.498,
    "mag": 12.5,
    "Absolute Visual Magnitude": 11.164,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -5.961118,
    "y": 14.55,
    "z": 9.743642,
    "vx": -0.00000959,
    "vy": -0.00001271,
    "vz": 0.00001311,
    "rarad": 1.95962913652451,
    "decrad": 0.55476122727965,
    "pmrarad": 7.40310490208333e-7,
    "pmdecrad": 8.33845593e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 36263,
    "base": "Gl 274",
    "lum": 0.00298126100817224,
    "ra": 7.485232,
    "dec": 31.785477,
    "pmra": 152.7,
    "pmdec": 171.99,
    "rv": 0,
    "Asset Name": "ASTRID 001579",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Bob",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000959,
    "y_1": -0.00001271,
    "z_1": 0.00001311,
    "Distance in Parsecs_1": 18.498,
    "HYG Star ID_1": 118475,
    "Gliese Catalog_1": "Gl 274B"
  },
  {
    "Astrid #": "ASTRID 001580",
    "HYG Star ID": 38798,
    "Hipparcos Catalogue": 38910,
    "Gliese Catalog": "GJ 1106",
    "Distance in Parsecs": 18.5014,
    "mag": 8.83,
    "Absolute Visual Magnitude": 7.494,
    "Spectral Type": "K5V",
    "Color Index": 1.136,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.546039,
    "y": 13.363654,
    "z": -10.333048,
    "vx": -0.00001817,
    "vy": 0.00000726,
    "vz": 0.00002266,
    "rarad": 2.08483061700815,
    "decrad": -0.592577429200231,
    "pmrarad": 6.62400931743055e-7,
    "pmdecrad": 0.000001476548545,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 38798,
    "lum": 0.0875790036306368,
    "ra": 7.963466,
    "dec": -33.952186,
    "pmra": 136.63,
    "pmdec": 304.56,
    "rv": 0,
    "Asset Name": "ASTRID 001580",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Sweet Nap",
    "Hair": "Honest Lad",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001817,
    "y_1": 0.00000726,
    "z_1": 0.00002266,
    "Distance in Parsecs_1": 18.5014,
    "HYG Star ID_1": 38798,
    "Hipparcos Catalogue_1": 38910,
    "Gliese Catalog_1": "GJ 1106"
  },
  {
    "Astrid #": "ASTRID 001581",
    "HYG Star ID": 4002,
    "Hipparcos Catalogue": 4012,
    "Gliese Catalog": "Gl 38",
    "Distance in Parsecs": 18.5117,
    "mag": 10.66,
    "Absolute Visual Magnitude": 9.323,
    "Spectral Type": "M2",
    "Color Index": 1.363,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.48222,
    "y": 2.167246,
    "z": 15.750178,
    "vx": -0.00007017,
    "vy": 0.0001282,
    "vz": 0.00000466,
    "rarad": 0.224639212228383,
    "decrad": 1.01756233064482,
    "pmrarad": 0.00000759625467241666,
    "pmdecrad": 0.000001964416552,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 4002,
    "lum": 0.0162480033522731,
    "var_min": 10.732,
    "var_max": 10.562,
    "ra": 0.858059,
    "dec": 58.302027,
    "pmra": 1566.84,
    "pmdec": 405.19,
    "rv": -16.6,
    "Asset Name": "ASTRID 001581",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Lion Mane",
    "Outfit": "Elven",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00007017,
    "y_1": 0.0001282,
    "z_1": 0.00000466,
    "Distance in Parsecs_1": 18.5117,
    "HYG Star ID_1": 4002,
    "Hipparcos Catalogue_1": 4012,
    "Gliese Catalog_1": "Gl 38"
  },
  {
    "Astrid #": "ASTRID 001582",
    "HYG Star ID": 38829,
    "Hipparcos Catalogue": 38939,
    "Henry Draper Catalogue": 65486,
    "Gliese Catalog": "Gl 293.2",
    "Distance in Parsecs": 18.5117,
    "mag": 8.42,
    "Absolute Visual Magnitude": 7.083,
    "Spectral Type": "K3V",
    "Color Index": 1.043,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.223507,
    "y": 14.524226,
    "z": -8.00638,
    "vx": -0.00002131,
    "vy": -0.00002844,
    "vz": -0.00001764,
    "rarad": 2.08598460956186,
    "decrad": -0.447269052948141,
    "pmrarad": 0.00000175880707031944,
    "pmdecrad": -0.000001192108359,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 38829,
    "lum": 0.127879226293584,
    "ra": 7.967874,
    "dec": -25.626629,
    "pmra": 362.78,
    "pmdec": -245.89,
    "rv": -5.1,
    "Asset Name": "ASTRID 001582",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Manbun",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002131,
    "y_1": -0.00002844,
    "z_1": -0.00001764,
    "Distance in Parsecs_1": 18.5117,
    "HYG Star ID_1": 38829,
    "Hipparcos Catalogue_1": 38939,
    "Henry Draper Catalogue_1": 65486,
    "Gliese Catalog_1": "Gl 293.2"
  },
  {
    "Astrid #": "ASTRID 001583",
    "HYG Star ID": 118010,
    "Gliese Catalog": "GJ 1018",
    "Distance in Parsecs": 18.5185,
    "mag": 12.65,
    "Absolute Visual Magnitude": 11.312,
    "Spectral Type": "m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.592514,
    "y": 2.723497,
    "z": -11.071402,
    "vx": 0.00000644,
    "vy": -0.00001393,
    "vz": 0.00000506,
    "rarad": 0.184523717610634,
    "decrad": -0.640824152284549,
    "pmrarad": -8.032877873125e-7,
    "pmdecrad": 3.40974309e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118010,
    "lum": 0.00260135725665313,
    "ra": 0.704829,
    "dec": -36.716519,
    "pmra": -165.69,
    "pmdec": 70.33,
    "rv": 0,
    "Asset Name": "ASTRID 001583",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Battle Ready",
    "Hair": "Manbun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000644,
    "y_1": -0.00001393,
    "z_1": 0.00000506,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118010,
    "Gliese Catalog_1": "GJ 1018"
  },
  {
    "Astrid #": "ASTRID 001584",
    "HYG Star ID": 118019,
    "Gliese Catalog": "NN 3056",
    "Distance in Parsecs": 18.5185,
    "mag": 14.4,
    "Absolute Visual Magnitude": 13.062,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.623455,
    "y": 3.468287,
    "z": -7.386918,
    "vx": -0.00000776,
    "vy": 0.00000992,
    "vz": -0.0000128,
    "rarad": 0.205684745324917,
    "decrad": -0.41031039405275,
    "pmrarad": 6.10186498347222e-7,
    "pmdecrad": -7.53541055e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118019,
    "lum": 0.000519039010197082,
    "ra": 0.785658,
    "dec": -23.509054,
    "pmra": 125.86,
    "pmdec": -155.43,
    "rv": 0,
    "Asset Name": "ASTRID 001584",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Curly",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000776,
    "y_1": 0.00000992,
    "z_1": -0.0000128,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118019,
    "Gliese Catalog_1": "NN 3056"
  },
  {
    "Astrid #": "ASTRID 001585",
    "HYG Star ID": 118023,
    "Gliese Catalog": "NN 3058",
    "Distance in Parsecs": 18.5185,
    "mag": 13.06,
    "Absolute Visual Magnitude": 11.722,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.890496,
    "y": 2.796221,
    "z": 12.99812,
    "vx": 0.00000394,
    "vy": 0.00001406,
    "vz": -0.00000693,
    "rarad": 0.213597583983289,
    "decrad": 0.77806036003665,
    "pmrarad": 6.96919665798611e-7,
    "pmdecrad": -5.25179267e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118023,
    "lum": 0.00178319977223291,
    "ra": 0.815883,
    "dec": 44.579575,
    "pmra": 143.75,
    "pmdec": -108.33,
    "rv": 0,
    "Asset Name": "ASTRID 001585",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Honest Lad",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000394,
    "y_1": 0.00001406,
    "z_1": -0.00000693,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118023,
    "Gliese Catalog_1": "NN 3058"
  },
  {
    "Astrid #": "ASTRID 001586",
    "HYG Star ID": 118072,
    "Gliese Catalog": "GJ 1039",
    "Distance in Parsecs": 18.5185,
    "mag": 14.95,
    "Absolute Visual Magnitude": 13.612,
    "Spectral Type": "DC7",
    "Color Index": 0.4,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 15.499978,
    "y": 6.059774,
    "z": -8.121863,
    "vx": -0.00002561,
    "vy": 0.00001087,
    "vz": -0.00004079,
    "rarad": 0.372677704290083,
    "decrad": -0.454019197475499,
    "pmrarad": 0.00000105044580165972,
    "pmdecrad": -0.00000245084951,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118072,
    "lum": 0.000312751931140798,
    "ra": 1.423524,
    "dec": -26.013384,
    "pmra": 216.67,
    "pmdec": -505.52,
    "rv": 0,
    "Asset Name": "ASTRID 001586",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Neko",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002561,
    "y_1": 0.00001087,
    "z_1": -0.00004079,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118072,
    "Gliese Catalog_1": "GJ 1039"
  },
  {
    "Astrid #": "ASTRID 001587",
    "HYG Star ID": 118203,
    "Gliese Catalog": "NN 3209",
    "Distance in Parsecs": 18.5185,
    "mag": 11.43,
    "Absolute Visual Magnitude": 10.092,
    "Spectral Type": "M1",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.098185,
    "y": 9.21223,
    "z": 13.874042,
    "vx": 0.00000728,
    "vy": 0.000021,
    "vz": -0.00001819,
    "rarad": 0.849654929583976,
    "decrad": 0.846851961386,
    "pmrarad": 4.53591679527777e-7,
    "pmdecrad": -0.00000148361228,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 118203,
    "lum": 0.00800202677119331,
    "ra": 3.245443,
    "dec": 48.521043,
    "pmra": 93.56,
    "pmdec": -306.02,
    "rv": 0,
    "Asset Name": "ASTRID 001587",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Maniacal",
    "Hair": "Audiohead",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00000728,
    "y_1": 0.000021,
    "z_1": -0.00001819,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118203,
    "Gliese Catalog_1": "NN 3209"
  },
  {
    "Astrid #": "ASTRID 001588",
    "HYG Star ID": 118242,
    "Gliese Catalog": "NN 3242",
    "Distance in Parsecs": 18.5185,
    "mag": 12.7,
    "Absolute Visual Magnitude": 11.362,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.613495,
    "y": 13.676852,
    "z": 7.966133,
    "vx": -0.00000503,
    "vy": 0.0000305,
    "vz": -0.00004628,
    "rarad": 0.958118698074285,
    "decrad": 0.4446828722117,
    "pmrarad": 0.00000116966148570833,
    "pmdecrad": -0.000002768935766,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118242,
    "lum": 0.0024842768936968,
    "ra": 3.659744,
    "dec": 25.478452,
    "pmra": 241.26,
    "pmdec": -571.13,
    "rv": 0,
    "Asset Name": "ASTRID 001588",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Awkward",
    "Hair": "Charmer",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000503,
    "y_1": 0.0000305,
    "z_1": -0.00004628,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118242,
    "Gliese Catalog_1": "NN 3242"
  },
  {
    "Astrid #": "ASTRID 001589",
    "HYG Star ID": 118285,
    "Gliese Catalog": "NN 3271",
    "Distance in Parsecs": 18.5185,
    "mag": 12.16,
    "Absolute Visual Magnitude": 10.822,
    "Spectral Type": "M3",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.969934,
    "y": 4.319577,
    "z": 17.899594,
    "vx": -0.00002728,
    "vy": 0.00006237,
    "vz": -0.00001203,
    "rarad": 1.14252712859255,
    "decrad": 1.3115327732558,
    "pmrarad": 0.00000273905185103472,
    "pmdecrad": -0.000002536394884,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118285,
    "lum": 0.00408507467663125,
    "ra": 4.364132,
    "dec": 75.145293,
    "pmra": 564.97,
    "pmdec": -523.17,
    "rv": 0,
    "Asset Name": "ASTRID 001589",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Heartbreaker",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002728,
    "y_1": 0.00006237,
    "z_1": -0.00001203,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118285,
    "Gliese Catalog_1": "NN 3271"
  },
  {
    "Astrid #": "ASTRID 001590",
    "HYG Star ID": 118348,
    "Gliese Catalog": "NN 3327",
    "Distance in Parsecs": 18.5185,
    "mag": 12.97,
    "Absolute Visual Magnitude": 11.632,
    "Spectral Type": "M4  e",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.289738,
    "y": 17.598399,
    "z": -3.851403,
    "vx": 0.00001537,
    "vy": -0.00000254,
    "vz": -0.0000048,
    "rarad": 1.33170267934442,
    "decrad": -0.20950400777295,
    "pmrarad": -8.38873111465277e-7,
    "pmdecrad": -2.40535459e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118348,
    "lum": 0.00193731392458195,
    "ra": 5.08673,
    "dec": -12.003695,
    "pmra": -173.03,
    "pmdec": -49.61,
    "rv": 2.1,
    "Asset Name": "ASTRID 001590",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Manbun",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001537,
    "y_1": -0.00000254,
    "z_1": -0.0000048,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118348,
    "Gliese Catalog_1": "NN 3327"
  },
  {
    "Astrid #": "ASTRID 001591",
    "HYG Star ID": 118522,
    "Gliese Catalog": "NN 3479",
    "Distance in Parsecs": 18.5185,
    "mag": 12.95,
    "Absolute Visual Magnitude": 11.612,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.79683,
    "y": 12.621709,
    "z": 11.083175,
    "vx": -7.2e-7,
    "vy": 0.00002285,
    "vz": -0.00002652,
    "rarad": 2.12415955060349,
    "decrad": 0.64161747599285,
    "pmrarad": -6.15567930201389e-7,
    "pmdecrad": -0.000001787760143,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118522,
    "lum": 0.0019733312795365,
    "ra": 8.113692,
    "dec": 36.761973,
    "pmra": -126.97,
    "pmdec": -368.75,
    "rv": 0,
    "Asset Name": "ASTRID 001591",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Medium Bob",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -7.2e-7,
    "y_1": 0.00002285,
    "z_1": -0.00002652,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118522,
    "Gliese Catalog_1": "NN 3479"
  },
  {
    "Astrid #": "ASTRID 001592",
    "HYG Star ID": 118594,
    "Gliese Catalog": "NN 3530",
    "Distance in Parsecs": 18.5185,
    "mag": 11.61,
    "Absolute Visual Magnitude": 10.272,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.36457,
    "y": 12.786094,
    "z": 0.915907,
    "vx": 0.00001857,
    "vy": 0.00001914,
    "vz": 0.00000374,
    "rarad": 2.37831186877589,
    "decrad": 0.04947922972795,
    "pmrarad": -0.00000144028448219444,
    "pmdecrad": 2.02414559e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 118594,
    "lum": 0.00677953644924194,
    "ra": 9.084482,
    "dec": 2.834951,
    "pmra": -297.08,
    "pmdec": 41.75,
    "rv": 0,
    "Asset Name": "ASTRID 001592",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Horns",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001857,
    "y_1": 0.00001914,
    "z_1": 0.00000374,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118594,
    "Gliese Catalog_1": "NN 3530"
  },
  {
    "Astrid #": "ASTRID 001593",
    "HYG Star ID": 118629,
    "Gliese Catalog": "NN 3558",
    "Distance in Parsecs": 18.5185,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.792,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.317404,
    "y": 8.681818,
    "z": 11.810048,
    "vx": -0.00001645,
    "vy": -0.00000295,
    "vz": -0.00001359,
    "rarad": 2.48721398672563,
    "decrad": 0.691564804784849,
    "pmrarad": 6.67394512652777e-7,
    "pmdecrad": -9.53124303e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118629,
    "lum": 0.00419952334664065,
    "ra": 9.500458,
    "dec": 39.623745,
    "pmra": 137.66,
    "pmdec": -196.6,
    "rv": 0,
    "Asset Name": "ASTRID 001593",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Arrogant",
    "Hair": "Sweetheart",
    "Outfit": "Astro",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001645,
    "y_1": -0.00000295,
    "z_1": -0.00001359,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118629,
    "Gliese Catalog_1": "NN 3558"
  },
  {
    "Astrid #": "ASTRID 001594",
    "HYG Star ID": 118649,
    "Gliese Catalog": "NN 3568",
    "Distance in Parsecs": 18.5185,
    "mag": 13.38,
    "Absolute Visual Magnitude": 12.042,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.118356,
    "y": 9.857,
    "z": 4.148459,
    "vx": 0.00000782,
    "vy": 0.0000037,
    "vz": 0.00001973,
    "rarad": 2.56382108224655,
    "decrad": 0.22593433804505,
    "pmrarad": -3.97935069e-7,
    "pmdecrad": 0.000001093380901,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118649,
    "lum": 0.00132800588624372,
    "ra": 9.793075,
    "dec": 12.945084,
    "pmra": -82.08,
    "pmdec": 225.53,
    "rv": 0,
    "Asset Name": "ASTRID 001594",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Malevolent",
    "Hair": "Curly",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000782,
    "y_1": 0.0000037,
    "z_1": 0.00001973,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118649,
    "Gliese Catalog_1": "NN 3568"
  },
  {
    "Astrid #": "ASTRID 001595",
    "HYG Star ID": 118745,
    "Gliese Catalog": "GJ 1146",
    "Distance in Parsecs": 18.5185,
    "mag": 13.58,
    "Absolute Visual Magnitude": 12.242,
    "Spectral Type": "k-m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.154991,
    "y": 3.067387,
    "z": 1.98049,
    "vx": -0.00000346,
    "vy": 0.00007003,
    "vz": -0.00013958,
    "rarad": 2.9742188517585,
    "decrad": 0.1071514387001,
    "pmrarad": -0.00000369796482980555,
    "pmdecrad": -0.000007582005998,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118745,
    "lum": 0.00110458718398862,
    "ra": 11.360679,
    "dec": 6.139325,
    "pmra": -762.76,
    "pmdec": -1563.9,
    "rv": 0,
    "Asset Name": "ASTRID 001595",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Little Crown",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000346,
    "y_1": 0.00007003,
    "z_1": -0.00013958,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118745,
    "Gliese Catalog_1": "GJ 1146"
  },
  {
    "Astrid #": "ASTRID 001596",
    "HYG Star ID": 118780,
    "Gliese Catalog": "NN 3691",
    "Distance in Parsecs": 18.5185,
    "mag": 14.6,
    "Absolute Visual Magnitude": 13.262,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.846165,
    "y": 0.51727,
    "z": 7.67294,
    "vx": 0.00000302,
    "vy": 0.00002538,
    "vz": 0.00000493,
    "rarad": 3.11090354587568,
    "decrad": 0.427216572678,
    "pmrarad": -0.00000137522248626388,
    "pmdecrad": 2.9231356e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118780,
    "lum": 0.000431717844470922,
    "ra": 11.882776,
    "dec": 24.477707,
    "pmra": -283.66,
    "pmdec": 60.29,
    "rv": 0,
    "Asset Name": "ASTRID 001596",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Neko Dreams",
    "Hair": "Headband",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000302,
    "y_1": 0.00002538,
    "z_1": 0.00000493,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118780,
    "Gliese Catalog_1": "NN 3691"
  },
  {
    "Astrid #": "ASTRID 001597",
    "HYG Star ID": 118843,
    "Gliese Catalog": "GJ 1163",
    "Distance in Parsecs": 18.5185,
    "mag": 12.96,
    "Absolute Visual Magnitude": 11.622,
    "Spectral Type": "dM4",
    "Color Index": 1.63,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -16.466552,
    "y": -3.170648,
    "z": 7.857129,
    "vx": -0.00000749,
    "vy": 0.00002934,
    "vz": -0.00000385,
    "rarad": 3.33182389004094,
    "decrad": 0.43817263879315,
    "pmrarad": -0.00000163231918106249,
    "pmdecrad": -2.29404137e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118843,
    "lum": 0.00195523966962088,
    "ra": 12.72663,
    "dec": 25.105443,
    "pmra": -336.69,
    "pmdec": -47.32,
    "rv": 0,
    "Asset Name": "ASTRID 001597",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Tiny Crown",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000749,
    "y_1": 0.00002934,
    "z_1": -0.00000385,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118843,
    "Gliese Catalog_1": "GJ 1163"
  },
  {
    "Astrid #": "ASTRID 001598",
    "HYG Star ID": 118847,
    "Gliese Catalog": "NN 3750",
    "Distance in Parsecs": 18.5185,
    "mag": 15.56,
    "Absolute Visual Magnitude": 14.222,
    "Spectral Type": "DC8 :",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -9.504677,
    "y": -2.032767,
    "z": 15.762734,
    "vx": 0.00000475,
    "vy": -0.0000062,
    "vz": 0.00000206,
    "rarad": 3.35227137087391,
    "decrad": 1.01824559014735,
    "pmrarad": 3.81257478388888e-7,
    "pmdecrad": 2.12202947e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118847,
    "lum": 0.000178319977223291,
    "ra": 12.804733,
    "dec": 58.341175,
    "pmra": 78.64,
    "pmdec": 43.77,
    "rv": 0,
    "Asset Name": "ASTRID 001598",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Curly",
    "Outfit": "Warlock",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000475,
    "y_1": -0.0000062,
    "z_1": 0.00000206,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 118847,
    "Gliese Catalog_1": "NN 3750"
  },
  {
    "Astrid #": "ASTRID 001599",
    "HYG Star ID": 119007,
    "Gliese Catalog": "NN 3891",
    "Distance in Parsecs": 18.5185,
    "mag": 13.35,
    "Absolute Visual Magnitude": 12.012,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.303279,
    "y": -7.684329,
    "z": 15.183809,
    "vx": -0.00002213,
    "vy": 0.00003617,
    "vz": 0.00000766,
    "rarad": 3.95249216187912,
    "decrad": 0.9612826196776,
    "pmrarad": -0.00000221196241753472,
    "pmdecrad": 7.22973552e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119007,
    "lum": 0.00136521169506644,
    "ra": 15.097408,
    "dec": 55.077437,
    "pmra": -456.25,
    "pmdec": 149.12,
    "rv": 0,
    "Asset Name": "ASTRID 001599",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "High Fade",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002213,
    "y_1": 0.00003617,
    "z_1": 0.00000766,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 119007,
    "Gliese Catalog_1": "NN 3891"
  },
  {
    "Astrid #": "ASTRID 001600",
    "HYG Star ID": 119173,
    "Gliese Catalog": "NN 4022",
    "Distance in Parsecs": 18.5185,
    "mag": 12.55,
    "Absolute Visual Magnitude": 11.212,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.051572,
    "y": -13.929949,
    "z": -12.156709,
    "vx": -0.00000952,
    "vy": 0.00003105,
    "vz": -0.00003477,
    "rarad": 4.63705224735438,
    "decrad": -0.716120334309,
    "pmrarad": -6.39032912340277e-7,
    "pmdecrad": -0.00000248878618,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119173,
    "lum": 0.002852331512299,
    "ra": 17.712235,
    "dec": -41.030673,
    "pmra": -131.81,
    "pmdec": -513.35,
    "rv": 0,
    "Asset Name": "ASTRID 001600",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Neko Dreams",
    "Hair": "Curly",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000952,
    "y_1": 0.00003105,
    "z_1": -0.00003477,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 119173,
    "Gliese Catalog_1": "NN 4022"
  },
  {
    "Astrid #": "ASTRID 001601",
    "HYG Star ID": 119327,
    "Henry Draper Catalogue": 190780,
    "Gliese Catalog": "NN 4134",
    "Distance in Parsecs": 18.5185,
    "mag": 7.72,
    "Absolute Visual Magnitude": 6.382,
    "Spectral Type": "dK0",
    "Color Index": 0.94,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.547739,
    "y": -9.222487,
    "z": 15.069377,
    "vx": 0.00000493,
    "vy": -2.6e-7,
    "vz": -0.00001328,
    "rarad": 5.25393553678343,
    "decrad": 0.9506052237806,
    "pmrarad": 2.20735668756944e-7,
    "pmdecrad": -5.38264388e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 119327,
    "lum": 0.243893373027891,
    "ra": 20.068555,
    "dec": 54.465667,
    "pmra": 45.53,
    "pmdec": -111.03,
    "rv": -9,
    "Asset Name": "ASTRID 001601",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Halo Boy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00000493,
    "y_1": -2.6e-7,
    "z_1": -0.00001328,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 119327,
    "Henry Draper Catalogue_1": 190780,
    "Gliese Catalog_1": "NN 4134"
  },
  {
    "Astrid #": "ASTRID 001602",
    "HYG Star ID": 119583,
    "Gliese Catalog": "NN 4358",
    "Distance in Parsecs": 18.5185,
    "mag": 11.41,
    "Absolute Visual Magnitude": 10.072,
    "Spectral Type": "M2",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.031648,
    "y": -0.785707,
    "z": 16.147658,
    "vx": 0.00001838,
    "vy": 0.00000508,
    "vz": -0.00001003,
    "rarad": 6.19639021289127,
    "decrad": 1.0592209829115,
    "pmrarad": 3.59537825499999e-7,
    "pmdecrad": -0.00000110660177,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 119583,
    "lum": 0.00815079555611601,
    "ra": 23.668467,
    "dec": 60.688892,
    "pmra": 74.16,
    "pmdec": -228.25,
    "rv": 0,
    "Asset Name": "ASTRID 001602",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Bangs",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001838,
    "y_1": 0.00000508,
    "z_1": -0.00001003,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 119583,
    "Gliese Catalog_1": "NN 4358"
  },
  {
    "Astrid #": "ASTRID 001603",
    "HYG Star ID": 119600,
    "Gliese Catalog": "NN 4370",
    "Distance in Parsecs": 18.5185,
    "mag": 12.73,
    "Absolute Visual Magnitude": 11.392,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.369714,
    "y": -0.661377,
    "z": 2.247455,
    "vx": 0.00000297,
    "vy": -0.00000418,
    "vz": -0.00002552,
    "rarad": 6.24719728300165,
    "decrad": 0.12166261565115,
    "pmrarad": -2.19959966868055e-7,
    "pmdecrad": -0.000001388646977,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119600,
    "lum": 0.00241657345143682,
    "ra": 23.862536,
    "dec": 6.970754,
    "pmra": -45.37,
    "pmdec": -286.43,
    "rv": 0,
    "Asset Name": "ASTRID 001603",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Halo Boy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000297,
    "y_1": -0.00000418,
    "z_1": -0.00002552,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 119600,
    "Gliese Catalog_1": "NN 4370"
  },
  {
    "Astrid #": "ASTRID 001604",
    "HYG Star ID": 119613,
    "Gliese Catalog": "NN 4386",
    "Distance in Parsecs": 18.5185,
    "mag": 12.82,
    "Absolute Visual Magnitude": 11.482,
    "Spectral Type": "M7",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.305832,
    "y": -0.013199,
    "z": -12.87981,
    "vx": 0.00001682,
    "vy": -0.00000171,
    "vz": 0.00001737,
    "rarad": 6.28219519847919,
    "decrad": -0.76913003365685,
    "pmrarad": -9.12904160486111e-8,
    "pmdecrad": 0.000001305806863,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119613,
    "lum": 0.00222433399848511,
    "ra": 23.996218,
    "dec": -44.067905,
    "pmra": -18.83,
    "pmdec": 269.34,
    "rv": 0,
    "Asset Name": "ASTRID 001604",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Awkward",
    "Hair": "Elegant",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001682,
    "y_1": -0.00000171,
    "z_1": 0.00001737,
    "Distance in Parsecs_1": 18.5185,
    "HYG Star ID_1": 119613,
    "Gliese Catalog_1": "NN 4386"
  },
  {
    "Astrid #": "ASTRID 001605",
    "HYG Star ID": 118886,
    "Gliese Catalog": "Gl 509B",
    "Distance in Parsecs": 18.5288,
    "mag": 9.8,
    "Absolute Visual Magnitude": 8.461,
    "Spectral Type": "dK6",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -15.105914,
    "y": -5.764246,
    "z": 9.049931,
    "vx": -0.00000588,
    "vy": 0.00004367,
    "vz": 0.00001802,
    "rarad": 3.50614405476528,
    "decrad": 0.51028403154575,
    "pmrarad": -0.00000231532469422916,
    "pmdecrad": 0.000001114290915,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 2,
    "comp_primary": 65138,
    "base": "Gl 509",
    "lum": 0.0359418146314714,
    "ra": 13.392484,
    "dec": 29.237121,
    "pmra": -477.57,
    "pmdec": 229.84,
    "rv": 0,
    "Asset Name": "ASTRID 001605",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Laughing",
    "Hair": "Grecian",
    "Outfit": "Wizard",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000588,
    "y_1": 0.00004367,
    "z_1": 0.00001802,
    "Distance in Parsecs_1": 18.5288,
    "HYG Star ID_1": 118886,
    "Gliese Catalog_1": "Gl 509B"
  },
  {
    "Astrid #": "ASTRID 001606",
    "HYG Star ID": 35453,
    "Hipparcos Catalogue": 35550,
    "Henry Draper Catalogue": 56986,
    "Harvard Revised Catalogue": 2777,
    "Gliese Catalog": "Gl 271A",
    "Distance in Parsecs": 18.5391,
    "mag": 3.5,
    "Absolute Visual Magnitude": 2.16,
    "Spectral Type": "F0IV...",
    "Color Index": 0.374,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.888392,
    "y": 16.151258,
    "z": 6.939513,
    "vx": 0.00000646,
    "vy": -0.00001281,
    "vz": -0.0000065,
    "rarad": 1.92039874587519,
    "decrad": 0.383663869811516,
    "pmrarad": -9.0757121e-8,
    "pmdecrad": -3.7621541e-8,
    "bayer": "Del",
    "flam": 55,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 35453,
    "base": "Gl 271",
    "lum": 11.9124200802737,
    "bf": "55Del Gem",
    "ra": 7.335383,
    "dec": 21.98232,
    "pmra": -18.72,
    "pmdec": -7.76,
    "rv": -15.3,
    "Asset Name": "ASTRID 001606",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Windy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00000646,
    "y_1": -0.00001281,
    "z_1": -0.0000065,
    "Distance in Parsecs_1": 18.5391,
    "HYG Star ID_1": 35453,
    "Hipparcos Catalogue_1": 35550,
    "Henry Draper Catalogue_1": 56986,
    "Harvard Revised Catalogue_1": 2777,
    "Gliese Catalog_1": "Gl 271A"
  },
  {
    "Astrid #": "ASTRID 001607",
    "HYG Star ID": 89684,
    "Hipparcos Catalogue": 89962,
    "Henry Draper Catalogue": 168723,
    "Harvard Revised Catalogue": 6869,
    "Gliese Catalog": "Gl 711",
    "Distance in Parsecs": 18.5426,
    "mag": 3.23,
    "Absolute Visual Magnitude": 1.889,
    "Spectral Type": "K0III-IV",
    "Color Index": 0.941,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.719456,
    "y": -18.438938,
    "z": -0.937748,
    "vx": -0.00004865,
    "vy": -0.00000842,
    "vz": -0.00006326,
    "rarad": 4.80537147342513,
    "decrad": -0.0505940423811925,
    "pmrarad": -0.00000265469427061805,
    "pmdecrad": -0.000003397186422,
    "bayer": "Eta",
    "flam": 58,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 89684,
    "lum": 15.289736466907,
    "bf": "58Eta Ser",
    "ra": 18.355167,
    "dec": -2.898825,
    "pmra": -547.57,
    "pmdec": -700.72,
    "rv": 6.9,
    "Asset Name": "ASTRID 001607",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Wavy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00004865,
    "y_1": -0.00000842,
    "z_1": -0.00006326,
    "Distance in Parsecs_1": 18.5426,
    "HYG Star ID_1": 89684,
    "Hipparcos Catalogue_1": 89962,
    "Henry Draper Catalogue_1": 168723,
    "Harvard Revised Catalogue_1": 6869,
    "Gliese Catalog_1": "Gl 711"
  },
  {
    "Astrid #": "ASTRID 001608",
    "HYG Star ID": 118991,
    "Gliese Catalog": "GJ 1186",
    "Distance in Parsecs": 18.5529,
    "mag": 15.29,
    "Absolute Visual Magnitude": 13.948,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.204917,
    "y": -12.489849,
    "z": 3.720746,
    "vx": -0.00000521,
    "vy": -0.00001359,
    "vz": -0.00006402,
    "rarad": 3.89916830620661,
    "decrad": 0.201917189735999,
    "pmrarad": 3.39224132284722e-7,
    "pmdecrad": -0.00000352284528,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118991,
    "lum": 0.000229509147602422,
    "ra": 14.893726,
    "dec": 11.569003,
    "pmra": 69.97,
    "pmdec": -726.64,
    "rv": 0,
    "Asset Name": "ASTRID 001608",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Charmer",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000521,
    "y_1": -0.00001359,
    "z_1": -0.00006402,
    "Distance in Parsecs_1": 18.5529,
    "HYG Star ID_1": 118991,
    "Gliese Catalog_1": "GJ 1186"
  },
  {
    "Astrid #": "ASTRID 001609",
    "HYG Star ID": 32832,
    "Hipparcos Catalogue": 32919,
    "Henry Draper Catalogue": 49601,
    "Gliese Catalog": "Gl 249",
    "Distance in Parsecs": 18.5563,
    "mag": 8.95,
    "Absolute Visual Magnitude": 7.608,
    "Spectral Type": "K2",
    "Color Index": 1.232,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.80262,
    "y": 12.25165,
    "z": 13.652328,
    "vx": 0.00000801,
    "vy": 0.00006464,
    "vz": -0.0000255,
    "rarad": 1.7956864227222,
    "decrad": 0.826724404867327,
    "pmrarad": -0.00000119797460465277,
    "pmdecrad": -0.000003358643734,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 32832,
    "lum": 0.0788496917745377,
    "ra": 6.859017,
    "dec": 47.367819,
    "pmra": -247.1,
    "pmdec": -692.77,
    "rv": 22.2,
    "Asset Name": "ASTRID 001609",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Shinobi",
    "Hair": "Curly",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000801,
    "y_1": 0.00006464,
    "z_1": -0.0000255,
    "Distance in Parsecs_1": 18.5563,
    "HYG Star ID_1": 32832,
    "Hipparcos Catalogue_1": 32919,
    "Henry Draper Catalogue_1": 49601,
    "Gliese Catalog_1": "Gl 249"
  },
  {
    "Astrid #": "ASTRID 001610",
    "HYG Star ID": 42763,
    "Hipparcos Catalogue": 42881,
    "Distance in Parsecs": 18.5563,
    "mag": 10.65,
    "Absolute Visual Magnitude": 9.308,
    "Spectral Type": "M2V:",
    "Color Index": 1.432,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.704004,
    "y": 9.97889,
    "z": -12.999936,
    "vx": -0.00001367,
    "vy": -0.00000319,
    "vz": 0.0000067,
    "rarad": 2.28805961391358,
    "decrad": -0.77619212819652,
    "pmrarad": 6.68218695909722e-7,
    "pmdecrad": 5.05806112e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 42763,
    "lum": 0.0164740355943575,
    "var_min": 10.732,
    "var_max": 10.592,
    "ra": 8.739744,
    "dec": -44.472533,
    "pmra": 137.83,
    "pmdec": 104.33,
    "rv": 0,
    "Asset Name": "ASTRID 001610",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Shinobi",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00001367,
    "y_1": -0.00000319,
    "z_1": 0.0000067,
    "Distance in Parsecs_1": 18.5563,
    "HYG Star ID_1": 42763,
    "Hipparcos Catalogue_1": 42881
  },
  {
    "Astrid #": "ASTRID 001611",
    "HYG Star ID": 118632,
    "Gliese Catalog": "Gl 351B",
    "Distance in Parsecs": 18.5563,
    "mag": 4.65,
    "Absolute Visual Magnitude": 3.308,
    "Spectral Type": "F0 IV",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.226498,
    "y": 8.559845,
    "z": -12.042884,
    "vx": 0.00000705,
    "vy": 0.00001587,
    "vz": 0.0000047,
    "rarad": 2.49017393614725,
    "decrad": -0.7062583331355,
    "pmrarad": -9.10577054819444e-7,
    "pmdecrad": 3.3321729e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 2,
    "comp_primary": 46518,
    "base": "Gl 351",
    "lum": 4.13809064816725,
    "ra": 9.511764,
    "dec": -40.465622,
    "pmra": -187.82,
    "pmdec": 68.73,
    "rv": 0,
    "Asset Name": "ASTRID 001611",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Middle Part",
    "Outfit": "Boxer",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000705,
    "y_1": 0.00001587,
    "z_1": 0.0000047,
    "Distance in Parsecs_1": 18.5563,
    "HYG Star ID_1": 118632,
    "Gliese Catalog_1": "Gl 351B"
  },
  {
    "Astrid #": "ASTRID 001612",
    "HYG Star ID": 116397,
    "Hipparcos Catalogue": 116763,
    "Henry Draper Catalogue": 222335,
    "Gliese Catalog": "Gl 902.1",
    "Distance in Parsecs": 18.5701,
    "mag": 7.18,
    "Absolute Visual Magnitude": 5.836,
    "Spectral Type": "K1V",
    "Color Index": 0.802,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.558975,
    "y": -1.371145,
    "z": -10.044114,
    "vx": -0.00002056,
    "vy": 0.00001405,
    "vz": -0.00001864,
    "rarad": 6.19528559779956,
    "decrad": -0.571480245072403,
    "pmrarad": 6.56777093048611e-7,
    "pmdecrad": -0.000001476693989,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 116397,
    "lum": 0.403273792976112,
    "ra": 23.664248,
    "dec": -32.743406,
    "pmra": 135.47,
    "pmdec": -304.59,
    "rv": -8,
    "Asset Name": "ASTRID 001612",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Fire",
    "Outfit": "Wizard",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00002056,
    "y_1": 0.00001405,
    "z_1": -0.00001864,
    "Distance in Parsecs_1": 18.5701,
    "HYG Star ID_1": 116397,
    "Hipparcos Catalogue_1": 116763,
    "Henry Draper Catalogue_1": 222335,
    "Gliese Catalog_1": "Gl 902.1"
  },
  {
    "Astrid #": "ASTRID 001613",
    "HYG Star ID": 10508,
    "Hipparcos Catalogue": 10531,
    "Henry Draper Catalogue": 13579,
    "Gliese Catalog": "Gl 90",
    "Distance in Parsecs": 18.5805,
    "mag": 7.13,
    "Absolute Visual Magnitude": 5.785,
    "Spectral Type": "K2V",
    "Color Index": 0.92,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.856981,
    "y": 3.939779,
    "z": 17.187347,
    "vx": -0.00000781,
    "vy": 0.00005067,
    "vz": -0.00002233,
    "rarad": 0.592107375855447,
    "decrad": 1.18110415751407,
    "pmrarad": 0.00000249746919401388,
    "pmdecrad": -0.000001541998392,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 10508,
    "lum": 0.422668614265602,
    "var": "V989",
    "var_min": 7.201,
    "var_max": 7.101,
    "ra": 2.261684,
    "dec": 67.672283,
    "pmra": 515.14,
    "pmdec": -318.06,
    "rv": -12.1,
    "Asset Name": "ASTRID 001613",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Warm Smile",
    "Hair": "Side Part",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000781,
    "y_1": 0.00005067,
    "z_1": -0.00002233,
    "Distance in Parsecs_1": 18.5805,
    "HYG Star ID_1": 10508,
    "Hipparcos Catalogue_1": 10531,
    "Henry Draper Catalogue_1": 13579,
    "Gliese Catalog_1": "Gl 90"
  },
  {
    "Astrid #": "ASTRID 001614",
    "HYG Star ID": 119357,
    "Gliese Catalog": "Gl 795B",
    "Distance in Parsecs": 18.5805,
    "mag": 9.4,
    "Absolute Visual Magnitude": 8.055,
    "Spectral Type": "K8 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.874912,
    "y": -14.19963,
    "z": 1.609963,
    "vx": 0.00006004,
    "vy": 0.00005111,
    "vz": 0.00000812,
    "rarad": 5.40886483940356,
    "decrad": 0.08675678039555,
    "pmrarad": 0.000004243768071375,
    "pmdecrad": 4.38547911e-7,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 2,
    "comp_primary": 101626,
    "base": "Gl 795",
    "lum": 0.0522396188999119,
    "ra": 20.660342,
    "dec": 4.970797,
    "pmra": 875.34,
    "pmdec": 90.46,
    "rv": 0,
    "Asset Name": "ASTRID 001614",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Bucket Hat",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00006004,
    "y_1": 0.00005111,
    "z_1": 0.00000812,
    "Distance in Parsecs_1": 18.5805,
    "HYG Star ID_1": 119357,
    "Gliese Catalog_1": "Gl 795B"
  },
  {
    "Astrid #": "ASTRID 001615",
    "HYG Star ID": 73558,
    "Hipparcos Catalogue": 73786,
    "Gliese Catalog": "Gl 576",
    "Distance in Parsecs": 18.5874,
    "mag": 9.83,
    "Absolute Visual Magnitude": 8.484,
    "Spectral Type": "K5",
    "Color Index": 1.3,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.797119,
    "y": -13.355452,
    "z": 1.826052,
    "vx": 0.00000529,
    "vy": 0.00008455,
    "vz": -0.00005235,
    "rarad": 3.94833680109026,
    "decrad": 0.0984033625456525,
    "pmrarad": -0.00000294170396950694,
    "pmdecrad": -0.000002460623354,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 73558,
    "lum": 0.0351884388820958,
    "var_min": 9.888,
    "var_max": 9.788,
    "ra": 15.081536,
    "dec": 5.638097,
    "pmra": -606.77,
    "pmdec": -507.54,
    "rv": -68,
    "Asset Name": "ASTRID 001615",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Angelic",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000529,
    "y_1": 0.00008455,
    "z_1": -0.00005235,
    "Distance in Parsecs_1": 18.5874,
    "HYG Star ID_1": 73558,
    "Hipparcos Catalogue_1": 73786,
    "Gliese Catalog_1": "Gl 576"
  },
  {
    "Astrid #": "ASTRID 001616",
    "HYG Star ID": 83134,
    "Hipparcos Catalogue": 83389,
    "Henry Draper Catalogue": 154345,
    "Gliese Catalog": "Gl 651",
    "Distance in Parsecs": 18.5874,
    "mag": 6.76,
    "Absolute Visual Magnitude": 5.414,
    "Spectral Type": "G8V",
    "Color Index": 0.728,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.136571,
    "y": -12.262044,
    "z": 13.611756,
    "vx": 0.00003292,
    "vy": 0.00008379,
    "vz": 0.00001702,
    "rarad": 4.46196113371468,
    "decrad": 0.821733847124476,
    "pmrarad": 5.98938820958333e-7,
    "pmdecrad": 0.000004143557083,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83134,
    "lum": 0.594839774069221,
    "ra": 17.043436,
    "dec": 47.081881,
    "pmra": 123.54,
    "pmdec": 854.67,
    "rv": -47.3,
    "Asset Name": "ASTRID 001616",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Headband",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00003292,
    "y_1": 0.00008379,
    "z_1": 0.00001702,
    "Distance in Parsecs_1": 18.5874,
    "HYG Star ID_1": 83134,
    "Hipparcos Catalogue_1": 83389,
    "Henry Draper Catalogue_1": 154345,
    "Gliese Catalog_1": "Gl 651"
  },
  {
    "Astrid #": "ASTRID 001617",
    "HYG Star ID": 118863,
    "Gliese Catalog": "Gl 499B",
    "Distance in Parsecs": 18.5943,
    "mag": 14.9,
    "Absolute Visual Magnitude": 13.553,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -16.668886,
    "y": -4.957865,
    "z": 6.581114,
    "vx": 0.0000026,
    "vy": 0.00000604,
    "vz": 0.00000391,
    "rarad": 3.43069305948915,
    "decrad": 0.36177431324775,
    "pmrarad": -2.71350217006944e-7,
    "pmdecrad": 2.77104955e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 63739,
    "base": "Gl 499",
    "lum": 0.000330217435267076,
    "ra": 13.104282,
    "dec": 20.728141,
    "pmra": -55.97,
    "pmdec": 57.16,
    "rv": -2.5,
    "Asset Name": "ASTRID 001617",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Anxious Laugh",
    "Hair": "Braided Bun",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000026,
    "y_1": 0.00000604,
    "z_1": 0.00000391,
    "Distance in Parsecs_1": 18.5943,
    "HYG Star ID_1": 118863,
    "Gliese Catalog_1": "Gl 499B"
  },
  {
    "Astrid #": "ASTRID 001618",
    "HYG Star ID": 119294,
    "Gliese Catalog": "Gl 765B",
    "Distance in Parsecs": 18.5943,
    "mag": 13,
    "Absolute Visual Magnitude": 11.653,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.859891,
    "y": -10.859319,
    "z": 14.290019,
    "vx": -0.00000901,
    "vy": 0.00001564,
    "vz": 0.00001495,
    "rarad": 5.13319121090045,
    "decrad": 0.87651876036815,
    "pmrarad": -9.89019908333333e-8,
    "pmdecrad": 0.000001256627363,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 96138,
    "base": "Gl 765",
    "lum": 0.00190020300257723,
    "ra": 19.607346,
    "dec": 50.220826,
    "pmra": -20.4,
    "pmdec": 259.2,
    "rv": 0,
    "Asset Name": "ASTRID 001618",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Pixie",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000901,
    "y_1": 0.00001564,
    "z_1": 0.00001495,
    "Distance in Parsecs_1": 18.5943,
    "HYG Star ID_1": 119294,
    "Gliese Catalog_1": "Gl 765B"
  },
  {
    "Astrid #": "ASTRID 001619",
    "HYG Star ID": 117598,
    "Hipparcos Catalogue": 117966,
    "Gliese Catalog": "Gl 912",
    "Distance in Parsecs": 18.5977,
    "mag": 11.14,
    "Absolute Visual Magnitude": 9.793,
    "Color Index": 1.465,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 18.487615,
    "y": -0.349898,
    "z": -1.990007,
    "vx": -0.00000458,
    "vy": -0.00004284,
    "vz": -0.00003505,
    "rarad": 6.26426161969229,
    "decrad": -0.107208115960681,
    "pmrarad": -0.00000230814945175694,
    "pmdecrad": -0.000001895766935,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 117598,
    "lum": 0.0105390144506916,
    "var_min": 11.219,
    "var_max": 11.079,
    "ra": 23.927717,
    "dec": -6.142573,
    "pmra": -476.09,
    "pmdec": -391.03,
    "rv": 0,
    "Asset Name": "ASTRID 001619",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Stunned",
    "Hair": "High Bun",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000458,
    "y_1": -0.00004284,
    "z_1": -0.00003505,
    "Distance in Parsecs_1": 18.5977,
    "HYG Star ID_1": 117598,
    "Hipparcos Catalogue_1": 117966,
    "Gliese Catalog_1": "Gl 912"
  },
  {
    "Astrid #": "ASTRID 001620",
    "HYG Star ID": 90376,
    "Hipparcos Catalogue": 90656,
    "Henry Draper Catalogue": 170493,
    "Gliese Catalog": "Gl 715",
    "Distance in Parsecs": 18.615,
    "mag": 8.04,
    "Absolute Visual Magnitude": 6.691,
    "Spectral Type": "K3V",
    "Color Index": 1.074,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.418273,
    "y": -18.447312,
    "z": -0.590575,
    "vx": 0.0000081,
    "vy": 0.0000576,
    "vz": -0.00001573,
    "rarad": 4.84273651244589,
    "decrad": -0.0317318944701025,
    "pmrarad": 8.33879530555555e-7,
    "pmdecrad": -9.40053726e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 90376,
    "lum": 0.183484760775553,
    "ra": 18.497891,
    "dec": -1.818104,
    "pmra": 172,
    "pmdec": -193.9,
    "rv": -54.3,
    "Asset Name": "ASTRID 001620",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Round Brush",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.0000081,
    "y_1": 0.0000576,
    "z_1": -0.00001573,
    "Distance in Parsecs_1": 18.615,
    "HYG Star ID_1": 90376,
    "Hipparcos Catalogue_1": 90656,
    "Henry Draper Catalogue_1": 170493,
    "Gliese Catalog_1": "Gl 715"
  },
  {
    "Astrid #": "ASTRID 001621",
    "HYG Star ID": 46683,
    "Hipparcos Catalogue": 46816,
    "Henry Draper Catalogue": 82558,
    "Gliese Catalog": "Gl 355",
    "Distance in Parsecs": 18.622,
    "mag": 7.82,
    "Absolute Visual Magnitude": 6.47,
    "Spectral Type": "K0",
    "Color Index": 0.933,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.610219,
    "y": 10.967058,
    "z": -3.612152,
    "vx": 0.00000422,
    "vy": 0.00002486,
    "vz": 9.4e-7,
    "rarad": 2.49768040693783,
    "decrad": -0.195208699864408,
    "pmrarad": -0.00000120354996197916,
    "pmdecrad": 1.70072639e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46683,
    "lum": 0.224905460583578,
    "var": "LQ",
    "var_min": 7.908,
    "var_max": 7.768,
    "ra": 9.540436,
    "dec": -11.184635,
    "pmra": -248.25,
    "pmdec": 35.08,
    "rv": 10.9,
    "Asset Name": "ASTRID 001621",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "High Fade",
    "Outfit": "Astro",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000422,
    "y_1": 0.00002486,
    "z_1": 9.4e-7,
    "Distance in Parsecs_1": 18.622,
    "HYG Star ID_1": 46683,
    "Hipparcos Catalogue_1": 46816,
    "Henry Draper Catalogue_1": 82558,
    "Gliese Catalog_1": "Gl 355"
  },
  {
    "Astrid #": "ASTRID 001622",
    "HYG Star ID": 118288,
    "Gliese Catalog": "GJ 1070",
    "Distance in Parsecs": 18.622,
    "mag": 15.27,
    "Absolute Visual Magnitude": 13.92,
    "Spectral Type": "k-m",
    "Color Index": 1.71,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.969021,
    "y": 13.180373,
    "z": 11.722946,
    "vx": -0.00002839,
    "vy": 0.00004484,
    "vz": -0.00003594,
    "rarad": 1.14552251756944,
    "decrad": 0.68093698685715,
    "pmrarad": 0.00000238247138898611,
    "pmdecrad": -0.000002484422857,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118288,
    "lum": 0.0002355049283896,
    "ra": 4.375574,
    "dec": 39.014815,
    "pmra": 491.42,
    "pmdec": -512.45,
    "rv": 0,
    "Asset Name": "ASTRID 001622",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Side Part",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002839,
    "y_1": 0.00004484,
    "z_1": -0.00003594,
    "Distance in Parsecs_1": 18.622,
    "HYG Star ID_1": 118288,
    "Gliese Catalog_1": "GJ 1070"
  },
  {
    "Astrid #": "ASTRID 001623",
    "HYG Star ID": 119020,
    "Henry Draper Catalogue": 137108,
    "Harvard Revised Catalogue": 5728,
    "Gliese Catalog": "Gl 584B",
    "Distance in Parsecs": 18.622,
    "mag": 5.96,
    "Absolute Visual Magnitude": 4.61,
    "Spectral Type": "G2 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.163052,
    "y": -12.461021,
    "z": 9.391168,
    "vx": 0.00000999,
    "vy": -0.00000701,
    "vz": -0.00001999,
    "rarad": 4.02821031641054,
    "decrad": 0.528593902424,
    "pmrarad": 6.53868210965277e-7,
    "pmdecrad": -9.0325152e-7,
    "bayer": "Eta",
    "flam": 2,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 2,
    "comp_primary": 75083,
    "base": "Gl 584",
    "lum": 1.24738351424294,
    "bf": "2Eta CrB",
    "ra": 15.38663,
    "dec": 30.2862,
    "pmra": 134.87,
    "pmdec": -186.31,
    "rv": -10.6,
    "Asset Name": "ASTRID 001623",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Sweetheart",
    "Outfit": "Super",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": 0.00000999,
    "y_1": -0.00000701,
    "z_1": -0.00001999,
    "Distance in Parsecs_1": 18.622,
    "HYG Star ID_1": 119020,
    "Henry Draper Catalogue_1": 137108,
    "Harvard Revised Catalogue_1": 5728,
    "Gliese Catalog_1": "Gl 584B"
  },
  {
    "Astrid #": "ASTRID 001624",
    "HYG Star ID": 119526,
    "Gliese Catalog": "NN 4294",
    "Distance in Parsecs": 18.622,
    "mag": 15.98,
    "Absolute Visual Magnitude": 14.63,
    "Spectral Type": "m",
    "Color Index": 1.83,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.551483,
    "y": -5.836378,
    "z": -2.158944,
    "vx": 0.00001406,
    "vy": 0.0000598,
    "vz": -0.00004748,
    "rarad": 5.96215769269158,
    "decrad": -0.1161964136649,
    "pmrarad": 0.00000328553383175694,
    "pmdecrad": -0.000002566933298,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119526,
    "lum": 0.000122461619926504,
    "ra": 22.773765,
    "dec": -6.657564,
    "pmra": 677.69,
    "pmdec": -529.47,
    "rv": 0,
    "Asset Name": "ASTRID 001624",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Bandana",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001406,
    "y_1": 0.0000598,
    "z_1": -0.00004748,
    "Distance in Parsecs_1": 18.622,
    "HYG Star ID_1": 119526,
    "Gliese Catalog_1": "NN 4294"
  },
  {
    "Astrid #": "ASTRID 001625",
    "HYG Star ID": 107179,
    "Hipparcos Catalogue": 107522,
    "Henry Draper Catalogue": 206804,
    "Gliese Catalog": "Gl 836.9A",
    "Distance in Parsecs": 18.6289,
    "mag": 8.77,
    "Absolute Visual Magnitude": 7.419,
    "Spectral Type": "K7V",
    "Color Index": 1.322,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.314293,
    "y": -5.472052,
    "z": -15.746832,
    "vx": -0.00005652,
    "vy": 0.00004681,
    "vz": -0.00003764,
    "rarad": 5.70109876309284,
    "decrad": -1.00711400969707,
    "pmrarad": 4.3109632475e-7,
    "pmdecrad": -0.000004389793951,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 107179,
    "base": "Gl 836.9",
    "lum": 0.0938425931237017,
    "ra": 21.776593,
    "dec": -57.703382,
    "pmra": 88.92,
    "pmdec": -905.46,
    "rv": -7,
    "Asset Name": "ASTRID 001625",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Smoulder",
    "Hair": "Good Boy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00005652,
    "y_1": 0.00004681,
    "z_1": -0.00003764,
    "Distance in Parsecs_1": 18.6289,
    "HYG Star ID_1": 107179,
    "Hipparcos Catalogue_1": 107522,
    "Henry Draper Catalogue_1": 206804,
    "Gliese Catalog_1": "Gl 836.9A"
  },
  {
    "Astrid #": "ASTRID 001626",
    "HYG Star ID": 110053,
    "Hipparcos Catalogue": 110400,
    "Gliese Catalog": "NN 4273",
    "Distance in Parsecs": 18.6393,
    "mag": 12.11,
    "Absolute Visual Magnitude": 10.758,
    "Spectral Type": "M5",
    "Color Index": 1.653,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.022688,
    "y": -3.210968,
    "z": -16.964523,
    "vx": 0.00000842,
    "vy": -0.00001102,
    "vz": 0.00000557,
    "rarad": 5.85434135003799,
    "decrad": -1.14364137660482,
    "pmrarad": -3.49938514625e-7,
    "pmdecrad": 7.21645163e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 110053,
    "lum": 0.00433311285211838,
    "var_min": 12.235,
    "var_max": 11.975,
    "ra": 22.361937,
    "dec": -65.525824,
    "pmra": -72.18,
    "pmdec": 148.85,
    "rv": 0,
    "Asset Name": "ASTRID 001626",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Medium Bob",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000842,
    "y_1": -0.00001102,
    "z_1": 0.00000557,
    "Distance in Parsecs_1": 18.6393,
    "HYG Star ID_1": 110053,
    "Hipparcos Catalogue_1": 110400,
    "Gliese Catalog_1": "NN 4273"
  },
  {
    "Astrid #": "ASTRID 001627",
    "HYG Star ID": 118457,
    "Gliese Catalog": "NN 3420",
    "Distance in Parsecs": 18.6567,
    "mag": 16.57,
    "Absolute Visual Magnitude": 15.216,
    "Spectral Type": "DC9",
    "Color Index": 0.96,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.153764,
    "y": 15.273189,
    "z": 9.87666,
    "vx": -0.00003658,
    "vy": 0.00001946,
    "vz": -0.00004545,
    "rarad": 1.83632374282735,
    "decrad": 0.55788069712765,
    "pmrarad": 0.0000016183565470625,
    "pmdecrad": -0.000002872157447,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118457,
    "lum": 0.0000713838553601663,
    "ra": 7.01424,
    "dec": 31.964209,
    "pmra": 333.81,
    "pmdec": -592.42,
    "rv": 0,
    "Asset Name": "ASTRID 001627",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Tiny Crown",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003658,
    "y_1": 0.00001946,
    "z_1": -0.00004545,
    "Distance in Parsecs_1": 18.6567,
    "HYG Star ID_1": 118457,
    "Gliese Catalog_1": "NN 3420"
  },
  {
    "Astrid #": "ASTRID 001628",
    "HYG Star ID": 97893,
    "Hipparcos Catalogue": 98204,
    "Henry Draper Catalogue": 188807,
    "Gliese Catalog": "Gl 773A",
    "Distance in Parsecs": 18.6776,
    "mag": 9.29,
    "Absolute Visual Magnitude": 7.933,
    "Spectral Type": "K4V",
    "Color Index": 1.318,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.930258,
    "y": -15.892831,
    "z": -4.064186,
    "vx": -0.00001759,
    "vy": 0.00001384,
    "vz": -0.00004297,
    "rarad": 5.22432629231793,
    "decrad": -0.219352714407752,
    "pmrarad": -4.57906521284722e-7,
    "pmdecrad": -0.000002486560886,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 97893,
    "base": "Gl 773",
    "lum": 0.0584520840358855,
    "ra": 19.955456,
    "dec": -12.567985,
    "pmra": -94.45,
    "pmdec": -512.89,
    "rv": -10.6,
    "Asset Name": "ASTRID 001628",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Ponytail",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00001759,
    "y_1": 0.00001384,
    "z_1": -0.00004297,
    "Distance in Parsecs_1": 18.6776,
    "HYG Star ID_1": 97893,
    "Hipparcos Catalogue_1": 98204,
    "Henry Draper Catalogue_1": 188807,
    "Gliese Catalog_1": "Gl 773A"
  },
  {
    "Astrid #": "ASTRID 001629",
    "HYG Star ID": 116279,
    "Hipparcos Catalogue": 116645,
    "Gliese Catalog": "NN 4353",
    "Distance in Parsecs": 18.6776,
    "mag": 11.95,
    "Absolute Visual Magnitude": 10.593,
    "Spectral Type": "M3",
    "Color Index": 1.551,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.922174,
    "y": -1.322754,
    "z": -12.380474,
    "vx": -0.00001004,
    "vy": 0.00002006,
    "vz": -0.00001343,
    "rarad": 6.18845603642086,
    "decrad": -0.724620624639133,
    "pmrarad": 0.00000101844809874305,
    "pmdecrad": -9.60415901e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 116279,
    "lum": 0.00504428945952414,
    "var_min": 12.061,
    "var_max": 11.791,
    "ra": 23.638161,
    "dec": -41.517704,
    "pmra": 210.07,
    "pmdec": -198.1,
    "rv": 0,
    "Asset Name": "ASTRID 001629",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Heartbreaker",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00001004,
    "y_1": 0.00002006,
    "z_1": -0.00001343,
    "Distance in Parsecs_1": 18.6776,
    "HYG Star ID_1": 116279,
    "Hipparcos Catalogue_1": 116645,
    "Gliese Catalog_1": "NN 4353"
  },
  {
    "Astrid #": "ASTRID 001630",
    "HYG Star ID": 3842,
    "Hipparcos Catalogue": 3850,
    "Henry Draper Catalogue": 4747,
    "Gliese Catalog": "Gl 36",
    "Distance in Parsecs": 18.6881,
    "mag": 7.15,
    "Absolute Visual Magnitude": 5.792,
    "Spectral Type": "G8/K0V",
    "Color Index": 0.769,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.777142,
    "y": 3.676884,
    "z": -7.365786,
    "vx": -0.00000126,
    "vy": 0.00004765,
    "vz": 0.00000794,
    "rarad": 0.215747324729362,
    "decrad": -0.405134261818528,
    "pmrarad": 0.00000250522621290277,
    "pmdecrad": 5.79449311e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3842,
    "lum": 0.419952334664065,
    "ra": 0.824094,
    "dec": -23.212483,
    "pmra": 516.74,
    "pmdec": 119.52,
    "rv": 5,
    "Asset Name": "ASTRID 001630",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Smitten",
    "Hair": "Medium Bob",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000126,
    "y_1": 0.00004765,
    "z_1": 0.00000794,
    "Distance in Parsecs_1": 18.6881,
    "HYG Star ID_1": 3842,
    "Hipparcos Catalogue_1": 3850,
    "Henry Draper Catalogue_1": 4747,
    "Gliese Catalog_1": "Gl 36"
  },
  {
    "Astrid #": "ASTRID 001631",
    "HYG Star ID": 54214,
    "Hipparcos Catalogue": 54373,
    "Distance in Parsecs": 18.6881,
    "mag": 10.38,
    "Absolute Visual Magnitude": 9.022,
    "Spectral Type": "K5",
    "Color Index": 1.43,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.177317,
    "y": 4.008213,
    "z": -6.174065,
    "vx": 0.00000614,
    "vy": 0.00002024,
    "vz": -0.00000394,
    "rarad": 2.91235175840635,
    "decrad": -0.336699996223481,
    "pmrarad": -0.00000112932498748611,
    "pmdecrad": -2.23111255e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 54214,
    "lum": 0.021438776659735,
    "var_min": 10.438,
    "var_max": 10.328,
    "ra": 11.124364,
    "dec": -19.291489,
    "pmra": -232.94,
    "pmdec": -46.02,
    "rv": 0,
    "Asset Name": "ASTRID 001631",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Side Part",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00000614,
    "y_1": 0.00002024,
    "z_1": -0.00000394,
    "Distance in Parsecs_1": 18.6881,
    "HYG Star ID_1": 54214,
    "Hipparcos Catalogue_1": 54373
  },
  {
    "Astrid #": "ASTRID 001632",
    "HYG Star ID": 117961,
    "Gliese Catalog": "GJ 1003",
    "Distance in Parsecs": 18.6916,
    "mag": 14.18,
    "Absolute Visual Magnitude": 12.822,
    "Spectral Type": "m",
    "Color Index": 1.49,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.300968,
    "y": 0.529093,
    "z": 9.130962,
    "vx": 0.00004621,
    "vy": 0.00013796,
    "vz": -0.00009033,
    "rarad": 0.0323931409536277,
    "decrad": 0.5103769296429,
    "pmrarad": 0.00000729857907255555,
    "pmdecrad": -0.000005539927142,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117961,
    "lum": 0.000647440704568815,
    "ra": 0.123733,
    "dec": 29.242444,
    "pmra": 1505.44,
    "pmdec": -1142.69,
    "rv": 0,
    "Asset Name": "ASTRID 001632",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Shy Smile",
    "Hair": "Bob",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004621,
    "y_1": 0.00013796,
    "z_1": -0.00009033,
    "Distance in Parsecs_1": 18.6916,
    "HYG Star ID_1": 117961,
    "Gliese Catalog_1": "GJ 1003"
  },
  {
    "Astrid #": "ASTRID 001633",
    "HYG Star ID": 78603,
    "Hipparcos Catalogue": 78843,
    "Henry Draper Catalogue": 144253,
    "Gliese Catalog": "Gl 610",
    "Distance in Parsecs": 18.7126,
    "mag": 7.39,
    "Absolute Visual Magnitude": 6.029,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.043,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.388206,
    "y": -15.396967,
    "z": -6.538146,
    "vx": 0.00000919,
    "vy": -0.0000394,
    "vz": -0.00004605,
    "rarad": 4.21354912906601,
    "decrad": -0.356920876057158,
    "pmrarad": 0.00000143863611568055,
    "pmdecrad": -0.000001742177961,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 78603,
    "lum": 0.337598104855425,
    "ra": 16.094572,
    "dec": -20.45006,
    "pmra": 296.74,
    "pmdec": -359.35,
    "rv": 43.4,
    "Asset Name": "ASTRID 001633",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Good Boy",
    "Outfit": "Wizard",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000919,
    "y_1": -0.0000394,
    "z_1": -0.00004605,
    "Distance in Parsecs_1": 18.7126,
    "HYG Star ID_1": 78603,
    "Hipparcos Catalogue_1": 78843,
    "Henry Draper Catalogue_1": 144253,
    "Gliese Catalog_1": "Gl 610"
  },
  {
    "Astrid #": "ASTRID 001634",
    "HYG Star ID": 84019,
    "Hipparcos Catalogue": 84277,
    "Gliese Catalog": "GJ 1212",
    "Distance in Parsecs": 18.7231,
    "mag": 12,
    "Absolute Visual Magnitude": 10.638,
    "Spectral Type": "M1V:",
    "Color Index": 1.548,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.718285,
    "y": -18.144041,
    "z": -2.741822,
    "vx": -0.00003345,
    "vy": 0.00002791,
    "vz": -0.00003456,
    "rarad": 4.51025638159052,
    "decrad": -0.146970190418152,
    "pmrarad": -0.00000204964679728472,
    "pmdecrad": -0.000001987590646,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84019,
    "lum": 0.00483949449301662,
    "var": "V2367",
    "var_min": 12.117,
    "var_max": 11.847,
    "ra": 17.22791,
    "dec": -8.420772,
    "pmra": -422.77,
    "pmdec": -409.97,
    "rv": -15,
    "Asset Name": "ASTRID 001634",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Bucket Hat",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00003345,
    "y_1": 0.00002791,
    "z_1": -0.00003456,
    "Distance in Parsecs_1": 18.7231,
    "HYG Star ID_1": 84019,
    "Hipparcos Catalogue_1": 84277,
    "Gliese Catalog_1": "GJ 1212"
  },
  {
    "Astrid #": "ASTRID 001635",
    "HYG Star ID": 118981,
    "Gliese Catalog": "Wo 9490C",
    "Distance in Parsecs": 18.7266,
    "mag": 10.08,
    "Absolute Visual Magnitude": 8.718,
    "Spectral Type": "K5",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.399736,
    "y": -11.489047,
    "z": 6.251463,
    "vx": 0.0000027,
    "vy": 0.00003038,
    "vz": -0.00002415,
    "rarad": 3.8503755139312,
    "decrad": 0.34038869005725,
    "pmrarad": -0.00000113742137595138,
    "pmdecrad": -8.26398855e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 3,
    "comp_primary": 71678,
    "base": "Wo 9490",
    "lum": 0.0283661241931251,
    "ra": 14.707351,
    "dec": 19.502835,
    "pmra": -234.61,
    "pmdec": -170.46,
    "rv": -28,
    "Asset Name": "ASTRID 001635",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Manbun",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.0000027,
    "y_1": 0.00003038,
    "z_1": -0.00002415,
    "Distance in Parsecs_1": 18.7266,
    "HYG Star ID_1": 118981,
    "Gliese Catalog_1": "Wo 9490C"
  },
  {
    "Astrid #": "ASTRID 001636",
    "HYG Star ID": 119409,
    "Gliese Catalog": "Gl 825.4B",
    "Distance in Parsecs": 18.7266,
    "mag": 9.6,
    "Absolute Visual Magnitude": 8.238,
    "Spectral Type": "dG6",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.842313,
    "y": -10.79912,
    "z": -8.312579,
    "vx": -0.00005696,
    "vy": -0.00001563,
    "vz": -0.00001888,
    "rarad": 5.58401223786482,
    "decrad": -0.4599655680531,
    "pmrarad": -0.00000259632270347916,
    "pmdecrad": -0.000001698981062,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 2,
    "comp_primary": 104972,
    "base": "Gl 825.4",
    "lum": 0.0441367143463439,
    "ra": 21.329356,
    "dec": -26.354086,
    "pmra": -535.53,
    "pmdec": -350.44,
    "rv": -21.2,
    "Asset Name": "ASTRID 001636",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Spiky",
    "Outfit": "Sharky",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00005696,
    "y_1": -0.00001563,
    "z_1": -0.00001888,
    "Distance in Parsecs_1": 18.7266,
    "HYG Star ID_1": 119409,
    "Gliese Catalog_1": "Gl 825.4B"
  },
  {
    "Astrid #": "ASTRID 001637",
    "HYG Star ID": 119390,
    "Gliese Catalog": "Gl 818.1B",
    "Distance in Parsecs": 18.7336,
    "mag": 6.4,
    "Absolute Visual Magnitude": 5.037,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.988268,
    "y": -3.673326,
    "z": -17.931764,
    "vx": 0.00000537,
    "vy": 0.00004887,
    "vz": -0.00000882,
    "rarad": 5.53861131120471,
    "decrad": -1.27716085064505,
    "pmrarad": 0.00000211262409438888,
    "pmdecrad": -0.000001626932901,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 2,
    "comp_primary": 104102,
    "base": "Gl 818.1",
    "lum": 0.841782707660812,
    "ra": 21.155937,
    "dec": -73.175927,
    "pmra": 435.76,
    "pmdec": -335.58,
    "rv": 0,
    "Asset Name": "ASTRID 001637",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Side Part",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00000537,
    "y_1": 0.00004887,
    "z_1": -0.00000882,
    "Distance in Parsecs_1": 18.7336,
    "HYG Star ID_1": 119390,
    "Gliese Catalog_1": "Gl 818.1B"
  },
  {
    "Astrid #": "ASTRID 001638",
    "HYG Star ID": 119391,
    "Gliese Catalog": "Gl 818.1C",
    "Distance in Parsecs": 18.7336,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.137,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.988268,
    "y": -3.673326,
    "z": -17.931764,
    "vx": 0.00000537,
    "vy": 0.00004887,
    "vz": -0.00000882,
    "rarad": 5.53861131120471,
    "decrad": -1.27716085064505,
    "pmrarad": 0.00000211262409438888,
    "pmdecrad": -0.000001626932901,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 104102,
    "base": "Gl 818.1",
    "lum": 0.00121674620397912,
    "ra": 21.155937,
    "dec": -73.175927,
    "pmra": 435.76,
    "pmdec": -335.58,
    "rv": 0,
    "Asset Name": "ASTRID 001638",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Tiny Crown",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000537,
    "y_1": 0.00004887,
    "z_1": -0.00000882,
    "Distance in Parsecs_1": 18.7336,
    "HYG Star ID_1": 119391,
    "Gliese Catalog_1": "Gl 818.1C"
  },
  {
    "Astrid #": "ASTRID 001639",
    "HYG Star ID": 4142,
    "Hipparcos Catalogue": 4151,
    "Henry Draper Catalogue": 5015,
    "Harvard Revised Catalogue": 244,
    "Gliese Catalog": "Gl 41",
    "Distance in Parsecs": 18.7441,
    "mag": 4.8,
    "Absolute Visual Magnitude": 3.436,
    "Spectral Type": "F8V",
    "Color Index": 0.54,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.810315,
    "y": 2.077392,
    "z": 16.413746,
    "vx": -0.00000172,
    "vy": -0.0000068,
    "vz": 0.00002608,
    "rarad": 0.231564421269062,
    "decrad": 1.06681452472555,
    "pmrarad": -3.31854964340277e-7,
    "pmdecrad": 8.22825778e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 4142,
    "lum": 3.67790070422386,
    "ra": 0.884511,
    "dec": 61.12397,
    "pmra": -68.45,
    "pmdec": 169.72,
    "rv": 20.8,
    "Asset Name": "ASTRID 001639",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "High Fade",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000172,
    "y_1": -0.0000068,
    "z_1": 0.00002608,
    "Distance in Parsecs_1": 18.7441,
    "HYG Star ID_1": 4142,
    "Hipparcos Catalogue_1": 4151,
    "Henry Draper Catalogue_1": 5015,
    "Harvard Revised Catalogue_1": 244,
    "Gliese Catalog_1": "Gl 41"
  },
  {
    "Astrid #": "ASTRID 001640",
    "HYG Star ID": 908,
    "Hipparcos Catalogue": 910,
    "Henry Draper Catalogue": 693,
    "Harvard Revised Catalogue": 33,
    "Gliese Catalog": "Gl 10",
    "Distance in Parsecs": 18.7477,
    "mag": 4.89,
    "Absolute Visual Magnitude": 3.525,
    "Spectral Type": "F5V",
    "Color Index": 0.487,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.046958,
    "y": 0.887717,
    "z": -5.000042,
    "vx": 0.00000823,
    "vy": -0.00000726,
    "vz": -0.00002757,
    "rarad": 0.0491498571352085,
    "decrad": -0.269967134452576,
    "pmrarad": -4.08213119027777e-7,
    "pmdecrad": -0.000001305651723,
    "flam": 6,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 908,
    "lum": 3.38844156139202,
    "bf": "6    Cet",
    "ra": 0.187739,
    "dec": -15.467977,
    "pmra": -84.2,
    "pmdec": -269.31,
    "rv": 14.6,
    "Asset Name": "ASTRID 001640",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Grecian",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000823,
    "y_1": -0.00000726,
    "z_1": -0.00002757,
    "Distance in Parsecs_1": 18.7477,
    "HYG Star ID_1": 908,
    "Hipparcos Catalogue_1": 910,
    "Henry Draper Catalogue_1": 693,
    "Harvard Revised Catalogue_1": 33,
    "Gliese Catalog_1": "Gl 10"
  },
  {
    "Astrid #": "ASTRID 001641",
    "HYG Star ID": 19374,
    "Hipparcos Catalogue": 19422,
    "Henry Draper Catalogue": 25665,
    "Gliese Catalog": "Gl 161",
    "Distance in Parsecs": 18.7512,
    "mag": 7.7,
    "Absolute Visual Magnitude": 6.335,
    "Spectral Type": "G5",
    "Color Index": 0.952,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.036887,
    "y": 5.808041,
    "z": 17.568376,
    "vx": 0.0000041,
    "vy": 0.00002218,
    "vz": -0.00001983,
    "rarad": 1.0890098899895,
    "decrad": 1.21372626254532,
    "pmrarad": 3.54398800486111e-7,
    "pmdecrad": -0.000001447023392,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 19374,
    "lum": 0.254683025258504,
    "ra": 4.159711,
    "dec": 69.541392,
    "pmra": 73.1,
    "pmdec": -298.47,
    "rv": -10.8,
    "Asset Name": "ASTRID 001641",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Elegant",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.0000041,
    "y_1": 0.00002218,
    "z_1": -0.00001983,
    "Distance in Parsecs_1": 18.7512,
    "HYG Star ID_1": 19374,
    "Hipparcos Catalogue_1": 19422,
    "Henry Draper Catalogue_1": 25665,
    "Gliese Catalog_1": "Gl 161"
  },
  {
    "Astrid #": "ASTRID 001642",
    "HYG Star ID": 118028,
    "Gliese Catalog": "GJ 1023B",
    "Distance in Parsecs": 18.7617,
    "mag": 10.4,
    "Absolute Visual Magnitude": 9.034,
    "Color Index": 0.64,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 6.521582,
    "y": 1.574682,
    "z": 17.521152,
    "vx": 0.00000428,
    "vy": -0.00000691,
    "vz": -9.7e-7,
    "rarad": 0.236959055083328,
    "decrad": 1.20511024648995,
    "pmrarad": -4.11558333423611e-7,
    "pmdecrad": -1.44930201e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 2,
    "comp_primary": 4248,
    "base": "GJ 1023",
    "lum": 0.0212031311673984,
    "ra": 0.905117,
    "dec": 69.047731,
    "pmra": -84.89,
    "pmdec": -29.89,
    "rv": 0,
    "Asset Name": "ASTRID 001642",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Arrogant",
    "Hair": "Braided Bun",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000428,
    "y_1": -0.00000691,
    "z_1": -9.7e-7,
    "Distance in Parsecs_1": 18.7617,
    "HYG Star ID_1": 118028,
    "Gliese Catalog_1": "GJ 1023B"
  },
  {
    "Astrid #": "ASTRID 001643",
    "HYG Star ID": 25712,
    "Hipparcos Catalogue": 25775,
    "Henry Draper Catalogue": 274255,
    "Distance in Parsecs": 18.7793,
    "mag": 9.72,
    "Absolute Visual Magnitude": 8.352,
    "Spectral Type": "M0V",
    "Color Index": 1.343,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.787558,
    "y": 13.685526,
    "z": -12.734721,
    "vx": -0.00000415,
    "vy": -0.00000851,
    "vz": -0.00000972,
    "rarad": 1.44091448626268,
    "decrad": -0.745209029072558,
    "pmrarad": 1.60715735104166e-7,
    "pmdecrad": -7.04579721e-7,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 25712,
    "lum": 0.0397374505061462,
    "var": "VZ",
    "var_min": 9.793,
    "var_max": 9.653,
    "ra": 5.503888,
    "dec": -42.697332,
    "pmra": 33.15,
    "pmdec": -145.33,
    "rv": 0,
    "Asset Name": "ASTRID 001643",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Charmer",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": -0.00000415,
    "y_1": -0.00000851,
    "z_1": -0.00000972,
    "Distance in Parsecs_1": 18.7793,
    "HYG Star ID_1": 25712,
    "Hipparcos Catalogue_1": 25775,
    "Henry Draper Catalogue_1": 274255
  },
  {
    "Astrid #": "ASTRID 001644",
    "HYG Star ID": 98605,
    "Hipparcos Catalogue": 98921,
    "Henry Draper Catalogue": 190771,
    "Harvard Revised Catalogue": 7683,
    "Gliese Catalog": "GJ 1249",
    "Distance in Parsecs": 18.7899,
    "mag": 6.18,
    "Absolute Visual Magnitude": 4.81,
    "Spectral Type": "G5IV",
    "Color Index": 0.654,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.639772,
    "y": -12.569782,
    "z": 11.691332,
    "vx": 0.00000786,
    "vy": 0.00003326,
    "vz": -0.00000636,
    "rarad": 5.25851271762224,
    "decrad": 0.671575762932951,
    "pmrarad": 0.00000127675682774305,
    "pmdecrad": 5.40906623e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 98605,
    "lum": 1.03752841581801,
    "ra": 20.086039,
    "dec": 38.478457,
    "pmra": 263.35,
    "pmdec": 111.57,
    "rv": -22.5,
    "Asset Name": "ASTRID 001644",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Slick",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00000786,
    "y_1": 0.00003326,
    "z_1": -0.00000636,
    "Distance in Parsecs_1": 18.7899,
    "HYG Star ID_1": 98605,
    "Hipparcos Catalogue_1": 98921,
    "Henry Draper Catalogue_1": 190771,
    "Harvard Revised Catalogue_1": 7683,
    "Gliese Catalog_1": "GJ 1249"
  },
  {
    "Astrid #": "ASTRID 001645",
    "HYG Star ID": 49830,
    "Hipparcos Catalogue": 49971,
    "Distance in Parsecs": 18.7935,
    "mag": 12.47,
    "Absolute Visual Magnitude": 11.1,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.241722,
    "y": 5.722406,
    "z": 13.931023,
    "vx": -0.00000506,
    "vy": -0.00000204,
    "vz": -0.00000325,
    "rarad": 2.67074397074125,
    "decrad": 0.834957748161805,
    "pmrarad": 2.18941858138888e-7,
    "pmdecrad": -2.57581508e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 49830,
    "lum": 0.00316227766016837,
    "ra": 10.201491,
    "dec": 47.839555,
    "pmra": 45.16,
    "pmdec": -53.13,
    "rv": 0,
    "Asset Name": "ASTRID 001645",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Oh No",
    "Hair": "Halo Boy",
    "Outfit": "Warlock",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000506,
    "y_1": -0.00000204,
    "z_1": -0.00000325,
    "Distance in Parsecs_1": 18.7935,
    "HYG Star ID_1": 49830,
    "Hipparcos Catalogue_1": 49971
  },
  {
    "Astrid #": "ASTRID 001646",
    "HYG Star ID": 119031,
    "Gliese Catalog": "NN 3913",
    "Distance in Parsecs": 18.797,
    "mag": 12.69,
    "Absolute Visual Magnitude": 11.32,
    "Spectral Type": "k-m",
    "Color Index": 1.51,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.247492,
    "y": -14.074313,
    "z": 7.087301,
    "vx": -0.00005622,
    "vy": 0.00003584,
    "vz": -0.00001009,
    "rarad": 4.08306663855675,
    "decrad": 0.386602899535599,
    "pmrarad": -0.00000354044886499305,
    "pmdecrad": -5.79769288e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119031,
    "lum": 0.00258226019063459,
    "ra": 15.596166,
    "dec": 22.150714,
    "pmra": -730.27,
    "pmdec": -119.59,
    "rv": 0,
    "Asset Name": "ASTRID 001646",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Lion Mane",
    "Outfit": "Sharky",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005622,
    "y_1": 0.00003584,
    "z_1": -0.00001009,
    "Distance in Parsecs_1": 18.797,
    "HYG Star ID_1": 119031,
    "Gliese Catalog_1": "NN 3913"
  },
  {
    "Astrid #": "ASTRID 001647",
    "HYG Star ID": 63739,
    "Hipparcos Catalogue": 63942,
    "Gliese Catalog": "Gl 499A",
    "Distance in Parsecs": 18.8041,
    "mag": 9.44,
    "Absolute Visual Magnitude": 8.069,
    "Spectral Type": "M0",
    "Color Index": 1.29,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.856941,
    "y": -5.013792,
    "z": 6.655749,
    "vx": 0.00000352,
    "vy": 0.00000582,
    "vz": 0.0000081,
    "rarad": 3.43069185718327,
    "decrad": 0.361793598111658,
    "pmrarad": -2.43521911743055e-7,
    "pmdecrad": 4.97612761e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 63739,
    "base": "Gl 499",
    "lum": 0.0515703406302645,
    "ra": 13.104278,
    "dec": 20.729246,
    "pmra": -50.23,
    "pmdec": 102.64,
    "rv": -1.8,
    "Asset Name": "ASTRID 001647",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Greasy",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00000352,
    "y_1": 0.00000582,
    "z_1": 0.0000081,
    "Distance in Parsecs_1": 18.8041,
    "HYG Star ID_1": 63739,
    "Hipparcos Catalogue_1": 63942,
    "Gliese Catalog_1": "Gl 499A"
  },
  {
    "Astrid #": "ASTRID 001648",
    "HYG Star ID": 46518,
    "Hipparcos Catalogue": 46651,
    "Henry Draper Catalogue": 82434,
    "Harvard Revised Catalogue": 3786,
    "Gliese Catalog": "Gl 351A",
    "Distance in Parsecs": 18.8147,
    "mag": 3.6,
    "Absolute Visual Magnitude": 2.228,
    "Spectral Type": "F2IV",
    "Color Index": 0.371,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.382585,
    "y": 8.679058,
    "z": -12.210922,
    "vx": 4e-7,
    "vy": 0.00001657,
    "vz": -0.00000247,
    "rarad": 2.49015047814506,
    "decrad": -0.706278362208768,
    "pmrarad": -7.13354849569444e-7,
    "pmdecrad": 2.35861855e-7,
    "bayer": "Psi",
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 46518,
    "base": "Gl 351",
    "lum": 11.1892248195524,
    "bf": "Psi Vel",
    "ra": 9.511674,
    "dec": -40.466769,
    "pmra": -147.14,
    "pmdec": 48.65,
    "rv": 8.8,
    "Asset Name": "ASTRID 001648",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Greasy",
    "Outfit": "Wizard",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 4e-7,
    "y_1": 0.00001657,
    "z_1": -0.00000247,
    "Distance in Parsecs_1": 18.8147,
    "HYG Star ID_1": 46518,
    "Hipparcos Catalogue_1": 46651,
    "Henry Draper Catalogue_1": 82434,
    "Harvard Revised Catalogue_1": 3786,
    "Gliese Catalog_1": "Gl 351A"
  },
  {
    "Astrid #": "ASTRID 001649",
    "HYG Star ID": 65138,
    "Hipparcos Catalogue": 65343,
    "Henry Draper Catalogue": 116495,
    "Gliese Catalog": "Gl 509A",
    "Distance in Parsecs": 18.8218,
    "mag": 8.88,
    "Absolute Visual Magnitude": 7.507,
    "Spectral Type": "K5",
    "Color Index": 1.321,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.344417,
    "y": -5.855352,
    "z": 9.192972,
    "vx": 0.00002747,
    "vy": 0.00005622,
    "vz": 0,
    "rarad": 3.50613550330499,
    "decrad": 0.510290300618488,
    "pmrarad": -0.00000227047942877777,
    "pmdecrad": 0.000001186242113,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 65138,
    "base": "Gl 509",
    "lum": 0.0865366342956348,
    "ra": 13.392451,
    "dec": 29.237481,
    "pmra": -468.32,
    "pmdec": 244.68,
    "rv": -39,
    "Asset Name": "ASTRID 001649",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Smitten",
    "Hair": "Wavy",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00002747,
    "y_1": 0.00005622,
    "z_1": 0,
    "Distance in Parsecs_1": 18.8218,
    "HYG Star ID_1": 65138,
    "Hipparcos Catalogue_1": 65343,
    "Henry Draper Catalogue_1": 116495,
    "Gliese Catalog_1": "Gl 509A"
  },
  {
    "Astrid #": "ASTRID 001650",
    "HYG Star ID": 18813,
    "Hipparcos Catalogue": 18859,
    "Henry Draper Catalogue": 25457,
    "Harvard Revised Catalogue": 1249,
    "Gliese Catalog": "Gl 159",
    "Distance in Parsecs": 18.8324,
    "mag": 5.38,
    "Absolute Visual Magnitude": 4.005,
    "Spectral Type": "F5V",
    "Color Index": 0.516,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.229661,
    "y": 16.415565,
    "z": -0.088392,
    "vx": -0.00000321,
    "vy": 0.00002245,
    "vz": -0.00002309,
    "rarad": 1.05859636008499,
    "decrad": -0.00469359241429125,
    "pmrarad": 7.33038285e-7,
    "pmdecrad": -0.000001221875919,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 18813,
    "lum": 2.17770977235315,
    "ra": 4.04354,
    "dec": -0.268923,
    "pmra": 151.2,
    "pmdec": -252.03,
    "rv": 17.7,
    "Asset Name": "ASTRID 001650",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Glad",
    "Hair": "High Fade",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000321,
    "y_1": 0.00002245,
    "z_1": -0.00002309,
    "Distance in Parsecs_1": 18.8324,
    "HYG Star ID_1": 18813,
    "Hipparcos Catalogue_1": 18859,
    "Henry Draper Catalogue_1": 25457,
    "Harvard Revised Catalogue_1": 1249,
    "Gliese Catalog_1": "Gl 159"
  },
  {
    "Astrid #": "ASTRID 001651",
    "HYG Star ID": 94854,
    "Hipparcos Catalogue": 95149,
    "Henry Draper Catalogue": 181321,
    "Harvard Revised Catalogue": 7330,
    "Gliese Catalog": "Gl 755",
    "Distance in Parsecs": 18.8324,
    "mag": 6.48,
    "Absolute Visual Magnitude": 5.105,
    "Spectral Type": "G1/G2V",
    "Color Index": 0.628,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.371773,
    "y": -14.464361,
    "z": -10.797322,
    "vx": 0.00000179,
    "vy": 0.00001586,
    "vz": -0.00000217,
    "rarad": 5.06798131518402,
    "decrad": -0.610577039940992,
    "pmrarad": 3.82421031222222e-7,
    "pmdecrad": -5.28107542e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 94854,
    "lum": 0.790678627999824,
    "ra": 19.358263,
    "dec": -34.983487,
    "pmra": 78.88,
    "pmdec": -108.93,
    "rv": -10.2,
    "Asset Name": "ASTRID 001651",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Bucket Hat",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00000179,
    "y_1": 0.00001586,
    "z_1": -0.00000217,
    "Distance in Parsecs_1": 18.8324,
    "HYG Star ID_1": 94854,
    "Hipparcos Catalogue_1": 95149,
    "Henry Draper Catalogue_1": 181321,
    "Harvard Revised Catalogue_1": 7330,
    "Gliese Catalog_1": "Gl 755"
  },
  {
    "Astrid #": "ASTRID 001652",
    "HYG Star ID": 118377,
    "Gliese Catalog": "Gl 204.2",
    "Distance in Parsecs": 18.8324,
    "mag": 12.02,
    "Absolute Visual Magnitude": 10.645,
    "Spectral Type": "M3.5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.465114,
    "y": 18.636128,
    "z": -1.13014,
    "vx": 0.00002851,
    "vy": -0.00000638,
    "vz": -0.00004306,
    "rarad": 1.43928383324372,
    "decrad": -0.0600465275947499,
    "pmrarad": -0.00000154529512540277,
    "pmdecrad": -0.000002290991895,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118377,
    "lum": 0.00480839348449728,
    "ra": 5.497659,
    "dec": -3.440413,
    "pmra": -318.74,
    "pmdec": -472.55,
    "rv": 0,
    "Asset Name": "ASTRID 001652",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Ram Horns",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002851,
    "y_1": -0.00000638,
    "z_1": -0.00004306,
    "Distance in Parsecs_1": 18.8324,
    "HYG Star ID_1": 118377,
    "Gliese Catalog_1": "Gl 204.2"
  },
  {
    "Astrid #": "ASTRID 001653",
    "HYG Star ID": 119255,
    "Gliese Catalog": "Gl 741",
    "Distance in Parsecs": 18.8324,
    "mag": 14.85,
    "Absolute Visual Magnitude": 13.475,
    "Spectral Type": "M4  :",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.990155,
    "y": -17.613429,
    "z": -4.418685,
    "vx": -0.00005229,
    "vy": -0.00000272,
    "vz": -0.00004826,
    "rarad": 4.98847323951898,
    "decrad": -0.23684007793085,
    "pmrarad": -0.00000271102962029861,
    "pmdecrad": -0.000002636358617,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119255,
    "lum": 0.000354813389233575,
    "ra": 19.054564,
    "dec": -13.569937,
    "pmra": -559.19,
    "pmdec": -543.79,
    "rv": 0,
    "Asset Name": "ASTRID 001653",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Bandana",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005229,
    "y_1": -0.00000272,
    "z_1": -0.00004826,
    "Distance in Parsecs_1": 18.8324,
    "HYG Star ID_1": 119255,
    "Gliese Catalog_1": "Gl 741"
  },
  {
    "Astrid #": "ASTRID 001654",
    "HYG Star ID": 40260,
    "Hipparcos Catalogue": 40375,
    "Henry Draper Catalogue": 68834,
    "Gliese Catalog": "NN 3488",
    "Distance in Parsecs": 18.8359,
    "mag": 8.79,
    "Absolute Visual Magnitude": 7.415,
    "Spectral Type": "K5",
    "Color Index": 1.186,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.168774,
    "y": 15.276507,
    "z": 4.244467,
    "vx": 0.00003326,
    "vy": 0.00001974,
    "vz": 0.00000863,
    "rarad": 2.15809376568207,
    "decrad": 0.22729117515926,
    "pmrarad": -0.00000205066490601388,
    "pmdecrad": 4.70075344e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 40260,
    "lum": 0.094188959652284,
    "ra": 8.243311,
    "dec": 13.022825,
    "pmra": -422.98,
    "pmdec": 96.96,
    "rv": 0,
    "Asset Name": "ASTRID 001654",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Braided Ponytail",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00003326,
    "y_1": 0.00001974,
    "z_1": 0.00000863,
    "Distance in Parsecs_1": 18.8359,
    "HYG Star ID_1": 40260,
    "Hipparcos Catalogue_1": 40375,
    "Henry Draper Catalogue_1": 68834,
    "Gliese Catalog_1": "NN 3488"
  },
  {
    "Astrid #": "ASTRID 001655",
    "HYG Star ID": 85301,
    "Hipparcos Catalogue": 85561,
    "Henry Draper Catalogue": 158233,
    "Gliese Catalog": "NN 4008",
    "Distance in Parsecs": 18.8608,
    "mag": 9.61,
    "Absolute Visual Magnitude": 8.232,
    "Spectral Type": "K5V",
    "Color Index": 1.272,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.318307,
    "y": -17.095595,
    "z": -7.622063,
    "vx": -0.00002566,
    "vy": 0.00000625,
    "vz": -0.00000622,
    "rarad": 4.5776039318143,
    "decrad": -0.416018715137057,
    "pmrarad": -0.00000139282122286805,
    "pmdecrad": -3.60701378e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 85301,
    "lum": 0.0443812980308138,
    "ra": 17.485159,
    "dec": -23.836117,
    "pmra": -287.29,
    "pmdec": -74.4,
    "rv": 0,
    "Asset Name": "ASTRID 001655",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Mortified",
    "Hair": "Halo Boy",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002566,
    "y_1": 0.00000625,
    "z_1": -0.00000622,
    "Distance in Parsecs_1": 18.8608,
    "HYG Star ID_1": 85301,
    "Hipparcos Catalogue_1": 85561,
    "Henry Draper Catalogue_1": 158233,
    "Gliese Catalog_1": "NN 4008"
  },
  {
    "Astrid #": "ASTRID 001656",
    "HYG Star ID": 117986,
    "Gliese Catalog": "NN 3033",
    "Distance in Parsecs": 18.8679,
    "mag": 14.54,
    "Absolute Visual Magnitude": 13.161,
    "Spectral Type": "m",
    "Color Index": 1.67,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.2396,
    "y": 1.748185,
    "z": 9.445472,
    "vx": -0.00000577,
    "vy": 0.00005458,
    "vz": -1.7e-7,
    "rarad": 0.107213975104166,
    "decrad": 0.5243040154001,
    "pmrarad": 0.00000290888208333333,
    "pmdecrad": -1.0151998e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117986,
    "lum": 0.000473805393338825,
    "ra": 0.409527,
    "dec": 30.040407,
    "pmra": 600,
    "pmdec": -2.09,
    "rv": 0,
    "Asset Name": "ASTRID 001656",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Stunned",
    "Hair": "Ninja",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000577,
    "y_1": 0.00005458,
    "z_1": -1.7e-7,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 117986,
    "Gliese Catalog_1": "NN 3033"
  },
  {
    "Astrid #": "ASTRID 001657",
    "HYG Star ID": 117988,
    "Gliese Catalog": "NN 3034",
    "Distance in Parsecs": 18.8679,
    "mag": 14.3,
    "Absolute Visual Magnitude": 12.921,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.276312,
    "y": 1.918288,
    "z": 7.338042,
    "vx": 0.0000189,
    "vy": -0.0000179,
    "vz": -0.00003981,
    "rarad": 0.110587261417676,
    "decrad": 0.399455422190899,
    "pmrarad": -0.00000105345164647916,
    "pmdecrad": -0.000002290376182,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117988,
    "lum": 0.000591017036610243,
    "ra": 0.422412,
    "dec": 22.88711,
    "pmra": -217.29,
    "pmdec": -472.42,
    "rv": 0,
    "Asset Name": "ASTRID 001657",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "High Fade",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000189,
    "y_1": -0.0000179,
    "z_1": -0.00003981,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 117988,
    "Gliese Catalog_1": "NN 3034"
  },
  {
    "Astrid #": "ASTRID 001658",
    "HYG Star ID": 118006,
    "Gliese Catalog": "NN 3047",
    "Distance in Parsecs": 18.8679,
    "mag": 13.83,
    "Absolute Visual Magnitude": 12.451,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.851259,
    "y": 2.861831,
    "z": 9.825741,
    "vx": 0.00001509,
    "vy": -0.00000128,
    "vz": -0.00002397,
    "rarad": 0.17862046756891,
    "decrad": 0.547746684220999,
    "pmrarad": -2.09148621791666e-7,
    "pmdecrad": -0.00000148829558,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118006,
    "lum": 0.000911171233042918,
    "ra": 0.68228,
    "dec": 31.383573,
    "pmra": -43.14,
    "pmdec": -306.98,
    "rv": 0,
    "Asset Name": "ASTRID 001658",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Frizzy",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001509,
    "y_1": -0.00000128,
    "z_1": -0.00002397,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118006,
    "Gliese Catalog_1": "NN 3047"
  },
  {
    "Astrid #": "ASTRID 001659",
    "HYG Star ID": 118185,
    "Gliese Catalog": "NN 3190",
    "Distance in Parsecs": 18.8679,
    "mag": 13.04,
    "Absolute Visual Magnitude": 11.661,
    "Spectral Type": "M3.5",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.747633,
    "y": 10.670433,
    "z": 11.252908,
    "vx": -0.00002821,
    "vy": 0.0000511,
    "vz": -0.00002149,
    "rarad": 0.781757200622288,
    "decrad": 0.63901472612475,
    "pmrarad": 0.00000297559244577777,
    "pmdecrad": -0.000001419277505,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118185,
    "lum": 0.00188625324535106,
    "ra": 2.986093,
    "dec": 36.612847,
    "pmra": 613.76,
    "pmdec": -292.75,
    "rv": 0,
    "Asset Name": "ASTRID 001659",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Skater Helmet",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002821,
    "y_1": 0.0000511,
    "z_1": -0.00002149,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118185,
    "Gliese Catalog_1": "NN 3190"
  },
  {
    "Astrid #": "ASTRID 001660",
    "HYG Star ID": 118334,
    "Gliese Catalog": "NN 3313",
    "Distance in Parsecs": 18.8679,
    "mag": 11.34,
    "Absolute Visual Magnitude": 9.961,
    "Spectral Type": "k",
    "Color Index": 1.48,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.890123,
    "y": 17.910826,
    "z": 0.711623,
    "vx": 0.00002259,
    "vy": -0.00000739,
    "vz": -8.3e-7,
    "rarad": 1.25307884000662,
    "decrad": 0.03772496464275,
    "pmrarad": -0.00000125973986755555,
    "pmdecrad": -4.3987145e-8,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118334,
    "lum": 0.00902817564966256,
    "ra": 4.786409,
    "dec": 2.161481,
    "pmra": -259.84,
    "pmdec": -9.07,
    "rv": 0,
    "Asset Name": "ASTRID 001660",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Innocent",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00002259,
    "y_1": -0.00000739,
    "z_1": -8.3e-7,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118334,
    "Gliese Catalog_1": "NN 3313"
  },
  {
    "Astrid #": "ASTRID 001661",
    "HYG Star ID": 118384,
    "Gliese Catalog": "GJ 1082",
    "Distance in Parsecs": 18.8679,
    "mag": 14.75,
    "Absolute Visual Magnitude": 13.371,
    "Spectral Type": "DA7",
    "Color Index": 0.32,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 1.45675,
    "y": 14.055207,
    "z": 12.503068,
    "vx": 0.00000863,
    "vy": -0.00000247,
    "vz": 0.00000177,
    "rarad": 1.46752801492329,
    "decrad": 0.7243695857314,
    "pmrarad": -4.68281534048611e-7,
    "pmdecrad": 1.25474628e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118384,
    "lum": 0.000390481083749253,
    "ra": 5.605544,
    "dec": 41.50332,
    "pmra": -96.59,
    "pmdec": 25.88,
    "rv": 0,
    "Asset Name": "ASTRID 001661",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Determined Shinobi",
    "Hair": "Headband",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000863,
    "y_1": -0.00000247,
    "z_1": 0.00000177,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118384,
    "Gliese Catalog_1": "GJ 1082"
  },
  {
    "Astrid #": "ASTRID 001662",
    "HYG Star ID": 118445,
    "Gliese Catalog": "NN 3413",
    "Distance in Parsecs": 18.8679,
    "mag": 13.18,
    "Absolute Visual Magnitude": 11.801,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.070524,
    "y": 17.447644,
    "z": 5.916776,
    "vx": -0.00001045,
    "vy": -0.00000695,
    "vz": 0.00001331,
    "rarad": 1.79999445182338,
    "decrad": 0.318970862667149,
    "pmrarad": 6.23276467722222e-7,
    "pmdecrad": 7.42773343e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118445,
    "lum": 0.00165805907510891,
    "ra": 6.875472,
    "dec": 18.275684,
    "pmra": 128.56,
    "pmdec": 153.21,
    "rv": 0,
    "Asset Name": "ASTRID 001662",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Prince",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001045,
    "y_1": -0.00000695,
    "z_1": 0.00001331,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118445,
    "Gliese Catalog_1": "NN 3413"
  },
  {
    "Astrid #": "ASTRID 001663",
    "HYG Star ID": 118518,
    "Henry Draper Catalogue": 66020,
    "Gliese Catalog": "Gl 296",
    "Distance in Parsecs": 18.8679,
    "mag": 9.66,
    "Absolute Visual Magnitude": 8.281,
    "Spectral Type": "K7 V",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.224963,
    "y": 12.509189,
    "z": -12.13789,
    "vx": -0.00002282,
    "vy": -0.00005359,
    "vz": -0.00005042,
    "rarad": 2.0945239253539,
    "decrad": -0.69879998252345,
    "pmrarad": 0.00000246784707813194,
    "pmdecrad": -0.000003239510469,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118518,
    "lum": 0.0424228654922949,
    "ra": 8.000492,
    "dec": -40.03829,
    "pmra": 509.03,
    "pmdec": -668.2,
    "rv": 5.5,
    "Asset Name": "ASTRID 001663",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Round Brush",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002282,
    "y_1": -0.00005359,
    "z_1": -0.00005042,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118518,
    "Henry Draper Catalogue_1": 66020,
    "Gliese Catalog_1": "Gl 296"
  },
  {
    "Astrid #": "ASTRID 001664",
    "HYG Star ID": 118768,
    "Gliese Catalog": "NN 3682",
    "Distance in Parsecs": 18.8679,
    "mag": 13.83,
    "Absolute Visual Magnitude": 12.451,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.012873,
    "y": 1.234906,
    "z": 8.064416,
    "vx": -6e-7,
    "vy": 0.00001784,
    "vz": -0.000004,
    "rarad": 3.06913817552857,
    "decrad": 0.44163106138745,
    "pmrarad": -9.40829428486111e-7,
    "pmdecrad": -2.34572251e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118768,
    "lum": 0.000911171233042918,
    "ra": 11.723244,
    "dec": 25.303596,
    "pmra": -194.06,
    "pmdec": -48.38,
    "rv": 0,
    "Asset Name": "ASTRID 001664",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Bangs",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -6e-7,
    "y_1": 0.00001784,
    "z_1": -0.000004,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118768,
    "Gliese Catalog_1": "NN 3682"
  },
  {
    "Astrid #": "ASTRID 001665",
    "HYG Star ID": 118907,
    "Gliese Catalog": "NN 3796",
    "Distance in Parsecs": 18.8679,
    "mag": 12.12,
    "Absolute Visual Magnitude": 10.741,
    "Spectral Type": "M3",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.125016,
    "y": -7.885187,
    "z": -0.745199,
    "vx": -0.00001047,
    "vy": 0.00002339,
    "vz": -0.00000686,
    "rarad": 3.5731014719959,
    "decrad": -0.0395058593302,
    "pmrarad": -0.00000135806008197222,
    "pmdecrad": -3.63886604e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118907,
    "lum": 0.00440149284286814,
    "ra": 13.648242,
    "dec": -2.263519,
    "pmra": -280.12,
    "pmdec": -75.06,
    "rv": 0,
    "Asset Name": "ASTRID 001665",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Angry",
    "Hair": "Halo Boy",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001047,
    "y_1": 0.00002339,
    "z_1": -0.00000686,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118907,
    "Gliese Catalog_1": "NN 3796"
  },
  {
    "Astrid #": "ASTRID 001666",
    "HYG Star ID": 118934,
    "Gliese Catalog": "NN 3816",
    "Distance in Parsecs": 18.8679,
    "mag": 15.3,
    "Absolute Visual Magnitude": 13.921,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.549801,
    "y": -8.093535,
    "z": -8.876691,
    "vx": -0.00001848,
    "vy": 0.0000412,
    "vz": -0.00000729,
    "rarad": 3.64924845974002,
    "decrad": -0.48981788539895,
    "pmrarad": -0.00000238426519960416,
    "pmdecrad": -4.37587979e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118934,
    "lum": 0.000235288120193816,
    "ra": 13.939102,
    "dec": -28.064498,
    "pmra": -491.79,
    "pmdec": -90.26,
    "rv": 0,
    "Asset Name": "ASTRID 001666",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Worried Vamp",
    "Hair": "Round Brush",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001848,
    "y_1": 0.0000412,
    "z_1": -0.00000729,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 118934,
    "Gliese Catalog_1": "NN 3816"
  },
  {
    "Astrid #": "ASTRID 001667",
    "HYG Star ID": 119089,
    "Gliese Catalog": "GJ 1202",
    "Distance in Parsecs": 18.8679,
    "mag": 12.78,
    "Absolute Visual Magnitude": 11.401,
    "Spectral Type": "k-m",
    "Color Index": 1.57,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.769107,
    "y": -16.666156,
    "z": 5.693518,
    "vx": -0.00003722,
    "vy": -0.00000909,
    "vz": -0.00007079,
    "rarad": 4.32658856763704,
    "decrad": 0.30653481812765,
    "pmrarad": -0.00000164642725916666,
    "pmdecrad": -0.000003935877447,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119089,
    "lum": 0.00239662452940863,
    "ra": 16.526351,
    "dec": 17.563151,
    "pmra": -339.6,
    "pmdec": -811.83,
    "rv": 0,
    "Asset Name": "ASTRID 001667",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Honest Lad",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003722,
    "y_1": -0.00000909,
    "z_1": -0.00007079,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119089,
    "Gliese Catalog_1": "GJ 1202"
  },
  {
    "Astrid #": "ASTRID 001668",
    "HYG Star ID": 119145,
    "Gliese Catalog": "NN 4002",
    "Distance in Parsecs": 18.8679,
    "mag": 13.02,
    "Absolute Visual Magnitude": 11.641,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.239099,
    "y": -17.634386,
    "z": 5.876591,
    "vx": -0.00000191,
    "vy": -0.00000631,
    "vz": -0.00001998,
    "rarad": 4.53073318047307,
    "decrad": 0.31672879260615,
    "pmrarad": -3.89305385486111e-8,
    "pmdecrad": -0.000001114387877,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119145,
    "lum": 0.00192132131140372,
    "ra": 17.306126,
    "dec": 18.147223,
    "pmra": -8.03,
    "pmdec": -229.86,
    "rv": 0,
    "Asset Name": "ASTRID 001668",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "High Fade",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000191,
    "y_1": -0.00000631,
    "z_1": -0.00001998,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119145,
    "Gliese Catalog_1": "NN 4002"
  },
  {
    "Astrid #": "ASTRID 001669",
    "HYG Star ID": 119151,
    "Gliese Catalog": "NN 4005",
    "Distance in Parsecs": 18.8679,
    "mag": 12.19,
    "Absolute Visual Magnitude": 10.811,
    "Spectral Type": "M2",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.920271,
    "y": -18.58511,
    "z": -1.436437,
    "vx": -0.00002117,
    "vy": 0.0000041,
    "vz": -0.00001002,
    "rarad": 4.55653391025215,
    "decrad": -0.0762049806636,
    "pmrarad": -0.00000114241495686111,
    "pmdecrad": -5.32713272e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119151,
    "lum": 0.00412667246322935,
    "ra": 17.404677,
    "dec": -4.366224,
    "pmra": -235.64,
    "pmdec": -109.88,
    "rv": 0,
    "Asset Name": "ASTRID 001669",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Star of Culture",
    "Hair": "Sweetheart",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002117,
    "y_1": 0.0000041,
    "z_1": -0.00001002,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119151,
    "Gliese Catalog_1": "NN 4005"
  },
  {
    "Astrid #": "ASTRID 001670",
    "HYG Star ID": 119154,
    "Gliese Catalog": "NN 4007",
    "Distance in Parsecs": 18.8679,
    "mag": 13.35,
    "Absolute Visual Magnitude": 11.971,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.429412,
    "y": -16.903885,
    "z": -8.022114,
    "vx": 0.00004147,
    "vy": 0.00001269,
    "vz": -0.00003931,
    "rarad": 4.56963640750295,
    "decrad": -0.4391525802818,
    "pmrarad": 0.00000208019005915972,
    "pmdecrad": -0.000002302205636,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119154,
    "lum": 0.00141775112312199,
    "ra": 17.454725,
    "dec": -25.161589,
    "pmra": 429.07,
    "pmdec": -474.86,
    "rv": 0,
    "Asset Name": "ASTRID 001670",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Bandana",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004147,
    "y_1": 0.00001269,
    "z_1": -0.00003931,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119154,
    "Gliese Catalog_1": "NN 4007"
  },
  {
    "Astrid #": "ASTRID 001671",
    "HYG Star ID": 119190,
    "Gliese Catalog": "NN 4036",
    "Distance in Parsecs": 18.8679,
    "mag": 11.36,
    "Absolute Visual Magnitude": 9.981,
    "Spectral Type": "M3",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.161803,
    "y": -14.021505,
    "z": -12.624139,
    "vx": -0.00000836,
    "vy": 0.00002325,
    "vz": -0.00002573,
    "rarad": 4.70085775091461,
    "decrad": -0.73297054738445,
    "pmrarad": -4.57421707604166e-7,
    "pmdecrad": -0.000001834607689,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 119190,
    "lum": 0.00886339287327933,
    "ra": 17.955954,
    "dec": -41.996119,
    "pmra": -94.35,
    "pmdec": -378.42,
    "rv": 0,
    "Asset Name": "ASTRID 001671",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Lion Mane",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000836,
    "y_1": 0.00002325,
    "z_1": -0.00002573,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119190,
    "Gliese Catalog_1": "NN 4036"
  },
  {
    "Astrid #": "ASTRID 001672",
    "HYG Star ID": 119233,
    "Gliese Catalog": "GJ 2139",
    "Distance in Parsecs": 18.8679,
    "mag": 14.18,
    "Absolute Visual Magnitude": 12.801,
    "Spectral Type": "DA5",
    "Color Index": 0.15,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 3.450532,
    "y": -18.19004,
    "z": -3.649138,
    "vx": -0.00001276,
    "vy": -0.00005202,
    "vz": -0.00003307,
    "rarad": 4.89985619662492,
    "decrad": -0.19460278428535,
    "pmrarad": -0.00000117872750153472,
    "pmdecrad": -0.000001220605707,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119233,
    "lum": 0.000660085207002158,
    "ra": 18.716072,
    "dec": -11.149918,
    "pmra": -243.13,
    "pmdec": -251.77,
    "rv": 53,
    "Asset Name": "ASTRID 001672",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Lion Mane",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001276,
    "y_1": -0.00005202,
    "z_1": -0.00003307,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119233,
    "Gliese Catalog_1": "GJ 2139"
  },
  {
    "Astrid #": "ASTRID 001673",
    "HYG Star ID": 119415,
    "Gliese Catalog": "NN 4197",
    "Distance in Parsecs": 18.8679,
    "mag": 12.21,
    "Absolute Visual Magnitude": 10.831,
    "Spectral Type": "M4",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.769456,
    "y": -10.728488,
    "z": -7.162351,
    "vx": -0.00001756,
    "vy": -0.00000894,
    "vz": -0.00002039,
    "rarad": 5.62129079184953,
    "decrad": -0.3893694234439,
    "pmrarad": -9.45823009395833e-7,
    "pmdecrad": -0.000001167988878,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119415,
    "lum": 0.00405135220229263,
    "ra": 21.471749,
    "dec": -22.309225,
    "pmra": -195.09,
    "pmdec": -240.91,
    "rv": 0,
    "Asset Name": "ASTRID 001673",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Chubby Cheeks",
    "Hair": "Afro",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001756,
    "y_1": -0.00000894,
    "z_1": -0.00002039,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119415,
    "Gliese Catalog_1": "NN 4197"
  },
  {
    "Astrid #": "ASTRID 001674",
    "HYG Star ID": 119442,
    "Gliese Catalog": "NN 4222",
    "Distance in Parsecs": 18.8679,
    "mag": 12.5,
    "Absolute Visual Magnitude": 11.121,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.186587,
    "y": -4.597908,
    "z": -16.82884,
    "vx": 0.00004561,
    "vy": 0.00000257,
    "vz": 0.00001877,
    "rarad": 5.71393507284891,
    "decrad": -1.1015949543232,
    "pmrarad": 0.00000141817697836111,
    "pmdecrad": 0.000002200593536,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119442,
    "lum": 0.00310170149947768,
    "ra": 21.825624,
    "dec": -63.116742,
    "pmra": 292.52,
    "pmdec": 453.9,
    "rv": 0,
    "Asset Name": "ASTRID 001674",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Middle Part",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004561,
    "y_1": 0.00000257,
    "z_1": 0.00001877,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119442,
    "Gliese Catalog_1": "NN 4222"
  },
  {
    "Astrid #": "ASTRID 001675",
    "HYG Star ID": 119472,
    "Gliese Catalog": "NN 4257",
    "Distance in Parsecs": 18.8679,
    "mag": 13.6,
    "Absolute Visual Magnitude": 12.221,
    "Spectral Type": "M4",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.582237,
    "y": -8.930977,
    "z": 1.124599,
    "vx": 0.00002282,
    "vy": 0.00003833,
    "vz": -0.00003197,
    "rarad": 5.78914648273292,
    "decrad": 0.0596391255475,
    "pmrarad": 0.00000236225465850694,
    "pmdecrad": -0.00000169744905,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119472,
    "lum": 0.00112615974690786,
    "ra": 22.112911,
    "dec": 3.41707,
    "pmra": 487.25,
    "pmdec": -350.12,
    "rv": 0,
    "Asset Name": "ASTRID 001675",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Undercut",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002282,
    "y_1": 0.00003833,
    "z_1": -0.00003197,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119472,
    "Gliese Catalog_1": "NN 4257"
  },
  {
    "Astrid #": "ASTRID 001676",
    "HYG Star ID": 119593,
    "Gliese Catalog": "NN 4362",
    "Distance in Parsecs": 18.8679,
    "mag": 12.4,
    "Absolute Visual Magnitude": 11.021,
    "Spectral Type": "M3.5:",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.690845,
    "y": -0.831148,
    "z": -8.759143,
    "vx": -0.00001337,
    "vy": -0.0000479,
    "vz": -0.00002095,
    "rarad": 6.23344632107666,
    "decrad": -0.48277087962035,
    "pmrarad": -0.00000257091846661805,
    "pmdecrad": -0.000001253912407,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119593,
    "lum": 0.0034009480651219,
    "ra": 23.810011,
    "dec": -27.660734,
    "pmra": -530.29,
    "pmdec": -258.64,
    "rv": 0,
    "Asset Name": "ASTRID 001676",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Lion Mane",
    "Outfit": "Pirate",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001337,
    "y_1": -0.0000479,
    "z_1": -0.00002095,
    "Distance in Parsecs_1": 18.8679,
    "HYG Star ID_1": 119593,
    "Gliese Catalog_1": "NN 4362"
  },
  {
    "Astrid #": "ASTRID 001677",
    "HYG Star ID": 46504,
    "Hipparcos Catalogue": 46637,
    "Henry Draper Catalogue": 82159,
    "Distance in Parsecs": 18.8715,
    "mag": 8.66,
    "Absolute Visual Magnitude": 7.281,
    "Spectral Type": "G5",
    "Color Index": 0.905,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.745571,
    "y": 11.253749,
    "z": 3.472,
    "vx": 0.00001115,
    "vy": 0.00001494,
    "vz": -0.00000107,
    "rarad": 2.48970012052952,
    "decrad": 0.185035208036181,
    "pmrarad": -9.87710911395833e-7,
    "pmdecrad": -5.7595865e-8,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 46504,
    "lum": 0.106561420215851,
    "var": "GS",
    "var_min": 9.076,
    "var_max": 8.556,
    "ra": 9.509954,
    "dec": 10.601736,
    "pmra": -203.73,
    "pmdec": -11.88,
    "rv": 0,
    "Asset Name": "ASTRID 001677",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Curly",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00001115,
    "y_1": 0.00001494,
    "z_1": -0.00000107,
    "Distance in Parsecs_1": 18.8715,
    "HYG Star ID_1": 46504,
    "Hipparcos Catalogue_1": 46637,
    "Henry Draper Catalogue_1": 82159
  },
  {
    "Astrid #": "ASTRID 001678",
    "HYG Star ID": 12676,
    "Hipparcos Catalogue": 12709,
    "Henry Draper Catalogue": 16909,
    "Gliese Catalog": "Gl 106",
    "Distance in Parsecs": 18.8822,
    "mag": 8.23,
    "Absolute Visual Magnitude": 6.85,
    "Spectral Type": "K5",
    "Color Index": 1.074,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.472383,
    "y": 11.644308,
    "z": 6.281109,
    "vx": -0.00000435,
    "vy": 0.00004883,
    "vz": 0.0000086,
    "rarad": 0.712741903221721,
    "decrad": 0.33910428247145,
    "pmrarad": 0.00000210733962527083,
    "pmdecrad": -7.514612e-8,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 12676,
    "lum": 0.158489319246111,
    "ra": 2.722474,
    "dec": 19.429244,
    "pmra": 434.67,
    "pmdec": -15.5,
    "rv": 29.2,
    "Asset Name": "ASTRID 001678",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Bad Dreams",
    "Hair": "Braided Bun",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000435,
    "y_1": 0.00004883,
    "z_1": 0.0000086,
    "Distance in Parsecs_1": 18.8822,
    "HYG Star ID_1": 12676,
    "Hipparcos Catalogue_1": 12709,
    "Henry Draper Catalogue_1": 16909,
    "Gliese Catalog_1": "Gl 106"
  },
  {
    "Astrid #": "ASTRID 001679",
    "HYG Star ID": 71920,
    "Hipparcos Catalogue": 72146,
    "Henry Draper Catalogue": 130004,
    "Distance in Parsecs": 18.8964,
    "mag": 7.87,
    "Absolute Visual Magnitude": 6.488,
    "Spectral Type": "K0",
    "Color Index": 0.931,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.77293,
    "y": -12.121449,
    "z": 4.522256,
    "vx": -0.00001781,
    "vy": 0.00001275,
    "vz": -0.00002007,
    "rarad": 3.86329983398214,
    "decrad": 0.241663785384252,
    "pmrarad": -0.00000112937346885416,
    "pmdecrad": -0.000001094127514,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 71920,
    "lum": 0.221207577646412,
    "ra": 14.756718,
    "dec": 13.846315,
    "pmra": -232.95,
    "pmdec": -225.68,
    "rv": 0,
    "Asset Name": "ASTRID 001679",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "High Fade",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00001781,
    "y_1": 0.00001275,
    "z_1": -0.00002007,
    "Distance in Parsecs_1": 18.8964,
    "HYG Star ID_1": 71920,
    "Hipparcos Catalogue_1": 72146,
    "Henry Draper Catalogue_1": 130004
  },
  {
    "Astrid #": "ASTRID 001680",
    "HYG Star ID": 74972,
    "Hipparcos Catalogue": 75201,
    "Gliese Catalog": "Gl 583",
    "Distance in Parsecs": 18.8964,
    "mag": 9.46,
    "Absolute Visual Magnitude": 8.078,
    "Spectral Type": "K7V",
    "Color Index": 1.31,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.973394,
    "y": -14.533718,
    "z": -1.573787,
    "vx": -0.00000924,
    "vy": 0.00003124,
    "vz": 3.4e-7,
    "rarad": 4.02328224520059,
    "decrad": -0.0833823072639562,
    "pmrarad": -0.00000142889136070138,
    "pmdecrad": -6.2201595e-8,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 74972,
    "lum": 0.0511446251596304,
    "ra": 15.367806,
    "dec": -4.777454,
    "pmra": -294.73,
    "pmdec": -12.83,
    "rv": -17.8,
    "Asset Name": "ASTRID 001680",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Horns",
    "Outfit": "Tats",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00000924,
    "y_1": 0.00003124,
    "z_1": 3.4e-7,
    "Distance in Parsecs_1": 18.8964,
    "HYG Star ID_1": 74972,
    "Hipparcos Catalogue_1": 75201,
    "Gliese Catalog_1": "Gl 583"
  },
  {
    "Astrid #": "ASTRID 001681",
    "HYG Star ID": 119316,
    "Gliese Catalog": "Gl 773B",
    "Distance in Parsecs": 18.8964,
    "mag": 15.36,
    "Absolute Visual Magnitude": 13.978,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.039643,
    "y": -16.076653,
    "z": -4.110963,
    "vx": -0.00001088,
    "vy": 0.00000565,
    "vz": -0.00004603,
    "rarad": 5.22461468385081,
    "decrad": -0.21930637895025,
    "pmrarad": -3.55222983743055e-7,
    "pmdecrad": -0.000002495879005,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 97893,
    "base": "Gl 773",
    "lum": 0.000223254386161674,
    "ra": 19.956558,
    "dec": -12.56533,
    "pmra": -73.27,
    "pmdec": -514.81,
    "rv": 0,
    "Asset Name": "ASTRID 001681",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Infatuated",
    "Hair": "Wavy",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001088,
    "y_1": 0.00000565,
    "z_1": -0.00004603,
    "Distance in Parsecs_1": 18.8964,
    "HYG Star ID_1": 119316,
    "Gliese Catalog_1": "Gl 773B"
  },
  {
    "Astrid #": "ASTRID 001682",
    "HYG Star ID": 118147,
    "Gliese Catalog": "Gl 100B",
    "Distance in Parsecs": 18.9036,
    "mag": 11.4,
    "Absolute Visual Magnitude": 10.017,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.140011,
    "y": 10.75595,
    "z": -6.458773,
    "vx": -0.00002995,
    "vy": 0.00005107,
    "vz": 0.00001945,
    "rarad": 0.650288126323285,
    "decrad": -0.348692068809399,
    "pmrarad": 0.00000310954646571527,
    "pmdecrad": 0.000001095043812,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 11538,
    "base": "Gl 100",
    "lum": 0.00857432616636179,
    "ra": 2.483918,
    "dec": -19.978584,
    "pmra": 641.39,
    "pmdec": 225.87,
    "rv": 0,
    "Asset Name": "ASTRID 001682",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Stunned",
    "Hair": "Charmer",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002995,
    "y_1": 0.00005107,
    "z_1": 0.00001945,
    "Distance in Parsecs_1": 18.9036,
    "HYG Star ID_1": 118147,
    "Gliese Catalog_1": "Gl 100B"
  },
  {
    "Astrid #": "ASTRID 001683",
    "HYG Star ID": 118148,
    "Gliese Catalog": "Gl 100C",
    "Distance in Parsecs": 18.9036,
    "mag": 12.85,
    "Absolute Visual Magnitude": 11.467,
    "Spectral Type": "M3",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.157466,
    "y": 10.721933,
    "z": -6.477068,
    "vx": -0.00002647,
    "vy": 0.0000491,
    "vz": 0.00002338,
    "rarad": 0.648169108986632,
    "decrad": -0.349722070544599,
    "pmrarad": 0.00000291629973264583,
    "pmdecrad": 0.000001316749108,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 11538,
    "base": "Gl 100",
    "lum": 0.00225527756674524,
    "ra": 2.475824,
    "dec": -20.037599,
    "pmra": 601.53,
    "pmdec": 271.6,
    "rv": 0,
    "Asset Name": "ASTRID 001683",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Little Crown",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002647,
    "y_1": 0.0000491,
    "z_1": 0.00002338,
    "Distance in Parsecs_1": 18.9036,
    "HYG Star ID_1": 118148,
    "Gliese Catalog_1": "Gl 100C"
  },
  {
    "Astrid #": "ASTRID 001684",
    "HYG Star ID": 83150,
    "Hipparcos Catalogue": 83405,
    "Gliese Catalog": "NN 3987",
    "Distance in Parsecs": 18.9286,
    "mag": 10.86,
    "Absolute Visual Magnitude": 9.474,
    "Spectral Type": "M2",
    "Color Index": 1.237,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.647042,
    "y": -18.239866,
    "z": -2.001046,
    "vx": -0.000011,
    "vy": 0.00000359,
    "vz": -0.00000718,
    "rarad": 4.46292229283762,
    "decrad": -0.105913384851358,
    "pmrarad": -6.09847128770833e-7,
    "pmdecrad": -3.81354441e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83150,
    "lum": 0.0141383913909456,
    "var_min": 10.938,
    "var_max": 10.788,
    "ra": 17.047107,
    "dec": -6.06839,
    "pmra": -125.79,
    "pmdec": -78.66,
    "rv": 0,
    "Asset Name": "ASTRID 001684",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Serious Slumber",
    "Hair": "Pixie",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.000011,
    "y_1": 0.00000359,
    "z_1": -0.00000718,
    "Distance in Parsecs_1": 18.9286,
    "HYG Star ID_1": 83150,
    "Hipparcos Catalogue_1": 83405,
    "Gliese Catalog_1": "NN 3987"
  },
  {
    "Astrid #": "ASTRID 001685",
    "HYG Star ID": 42148,
    "Hipparcos Catalogue": 42267,
    "Gliese Catalog": "NN 3508",
    "Distance in Parsecs": 18.9609,
    "mag": 11.8,
    "Absolute Visual Magnitude": 10.411,
    "Spectral Type": "K:",
    "Color Index": 1.563,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.589055,
    "y": 14.167542,
    "z": 4.948766,
    "vx": -0.0000051,
    "vy": 0.0000235,
    "vz": -0.00007921,
    "rarad": 2.25641733972597,
    "decrad": 0.264056397052127,
    "pmrarad": -5.76831317125e-7,
    "pmdecrad": -0.000004328222614,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 42148,
    "lum": 0.00596485649919377,
    "var_min": 11.942,
    "var_max": 11.702,
    "ra": 8.618879,
    "dec": 15.129317,
    "pmra": -118.98,
    "pmdec": -892.76,
    "rv": 0,
    "Asset Name": "ASTRID 001685",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "High Fade",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.0000051,
    "y_1": 0.0000235,
    "z_1": -0.00007921,
    "Distance in Parsecs_1": 18.9609,
    "HYG Star ID_1": 42148,
    "Hipparcos Catalogue_1": 42267,
    "Gliese Catalog_1": "NN 3508"
  },
  {
    "Astrid #": "ASTRID 001686",
    "HYG Star ID": 98365,
    "Hipparcos Catalogue": 98677,
    "Henry Draper Catalogue": 190067,
    "Gliese Catalog": "Gl 775.1",
    "Distance in Parsecs": 18.9717,
    "mag": 7.15,
    "Absolute Visual Magnitude": 5.759,
    "Spectral Type": "G7V",
    "Color Index": 0.714,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.313689,
    "y": -15.722075,
    "z": 5.099387,
    "vx": 0.00000471,
    "vy": -0.00003683,
    "vz": -0.00004606,
    "rarad": 5.24719853363898,
    "decrad": 0.272133047893837,
    "pmrarad": -7.76041258465277e-7,
    "pmdecrad": -0.00000282195499,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 98365,
    "lum": 0.432912374502826,
    "ra": 20.042822,
    "dec": 15.592075,
    "pmra": -160.07,
    "pmdec": -582.07,
    "rv": 20,
    "Asset Name": "ASTRID 001686",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Frizzy",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000471,
    "y_1": -0.00003683,
    "z_1": -0.00004606,
    "Distance in Parsecs_1": 18.9717,
    "HYG Star ID_1": 98365,
    "Hipparcos Catalogue_1": 98677,
    "Henry Draper Catalogue_1": 190067,
    "Gliese Catalog_1": "Gl 775.1"
  },
  {
    "Astrid #": "ASTRID 001687",
    "HYG Star ID": 61302,
    "Hipparcos Catalogue": 61495,
    "Gliese Catalog": "Gl 477",
    "Distance in Parsecs": 18.9861,
    "mag": 11.09,
    "Absolute Visual Magnitude": 9.698,
    "Spectral Type": "M1",
    "Color Index": 1.482,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.040973,
    "y": -2.063944,
    "z": -13.643353,
    "vx": 0.00005257,
    "vy": 0.00001761,
    "vz": -0.00003584,
    "rarad": 3.29855865803086,
    "decrad": -0.801788475269178,
    "pmrarad": -4.83310758145833e-7,
    "pmdecrad": -0.000003382545049,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 61302,
    "lum": 0.0115027054779011,
    "var_min": 11.156,
    "var_max": 11.016,
    "ra": 12.599566,
    "dec": -45.939096,
    "pmra": -99.69,
    "pmdec": -697.7,
    "rv": -12,
    "Asset Name": "ASTRID 001687",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Braid",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00005257,
    "y_1": 0.00001761,
    "z_1": -0.00003584,
    "Distance in Parsecs_1": 18.9861,
    "HYG Star ID_1": 61302,
    "Hipparcos Catalogue_1": 61495,
    "Gliese Catalog_1": "Gl 477"
  },
  {
    "Astrid #": "ASTRID 001688",
    "HYG Star ID": 69601,
    "Hipparcos Catalogue": 69824,
    "Gliese Catalog": "Gl 541.2",
    "Distance in Parsecs": 19.0114,
    "mag": 10.19,
    "Absolute Visual Magnitude": 8.795,
    "Spectral Type": "M0",
    "Color Index": 1.391,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.012056,
    "y": -7.526455,
    "z": 13.546971,
    "vx": 0.00000152,
    "vy": -0.00000404,
    "vz": -0.00000101,
    "rarad": 3.74114028486131,
    "decrad": 0.793155856653544,
    "pmrarad": 2.20784150124999e-7,
    "pmdecrad": -7.5582452e-8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 69601,
    "lum": 0.0264240875732194,
    "var_min": 10.238,
    "var_max": 10.128,
    "ra": 14.290103,
    "dec": 45.444483,
    "pmra": 45.54,
    "pmdec": -15.59,
    "rv": 0,
    "Asset Name": "ASTRID 001688",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Ram Horns",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000152,
    "y_1": -0.00000404,
    "z_1": -0.00000101,
    "Distance in Parsecs_1": 19.0114,
    "HYG Star ID_1": 69601,
    "Hipparcos Catalogue_1": 69824,
    "Gliese Catalog_1": "Gl 541.2"
  },
  {
    "Astrid #": "ASTRID 001689",
    "HYG Star ID": 119531,
    "Gliese Catalog": "GJ 1273",
    "Distance in Parsecs": 19.0114,
    "mag": 14.36,
    "Absolute Visual Magnitude": 12.965,
    "Spectral Type": "DA5",
    "Color Index": 0.19,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 16.721707,
    "y": -5.34487,
    "z": 7.309789,
    "vx": 0.00009742,
    "vy": 0.00001712,
    "vz": 0.00003977,
    "rarad": 5.97380201677261,
    "decrad": 0.39455551639305,
    "pmrarad": 0.00000241873545229166,
    "pmdecrad": 1.60647861e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119531,
    "lum": 0.000567544605408546,
    "ra": 22.818243,
    "dec": 22.606366,
    "pmra": 498.9,
    "pmdec": 33.14,
    "rv": 94,
    "Asset Name": "ASTRID 001689",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Bucket Hat",
    "Outfit": "Astro",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00009742,
    "y_1": 0.00001712,
    "z_1": 0.00003977,
    "Distance in Parsecs_1": 19.0114,
    "HYG Star ID_1": 119531,
    "Gliese Catalog_1": "GJ 1273"
  },
  {
    "Astrid #": "ASTRID 001690",
    "HYG Star ID": 116018,
    "Hipparcos Catalogue": 116384,
    "Gliese Catalog": "Gl 900",
    "Distance in Parsecs": 19.0259,
    "mag": 9.59,
    "Absolute Visual Magnitude": 8.193,
    "Spectral Type": "M0",
    "Color Index": 1.329,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.905338,
    "y": -2.070085,
    "z": 0.533025,
    "vx": -0.0000077,
    "vy": 0.00003267,
    "vz": 0.00000216,
    "rarad": 6.17412237519795,
    "decrad": 0.0280195720396512,
    "pmrarad": 0.00000166276548020138,
    "pmdecrad": 1.29833103e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 116018,
    "lum": 0.0460044666393477,
    "ra": 23.58341,
    "dec": 1.605403,
    "pmra": 342.97,
    "pmdec": 26.78,
    "rv": -10.9,
    "Asset Name": "ASTRID 001690",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "High Bun",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.0000077,
    "y_1": 0.00003267,
    "z_1": 0.00000216,
    "Distance in Parsecs_1": 19.0259,
    "HYG Star ID_1": 116018,
    "Hipparcos Catalogue_1": 116384,
    "Gliese Catalog_1": "Gl 900"
  },
  {
    "Astrid #": "ASTRID 001691",
    "HYG Star ID": 119436,
    "Gliese Catalog": "Gl 836.9B",
    "Distance in Parsecs": 19.0259,
    "mag": 9.6,
    "Absolute Visual Magnitude": 8.203,
    "Spectral Type": "K7 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.491519,
    "y": -5.588361,
    "z": -16.082575,
    "vx": -0.00005405,
    "vy": 0.00004493,
    "vz": -0.00004417,
    "rarad": 5.70111152385937,
    "decrad": -1.0071245537409,
    "pmrarad": 4.10637187430555e-7,
    "pmdecrad": -0.000004343954818,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 2,
    "comp_primary": 107179,
    "base": "Gl 836.9",
    "lum": 0.0455826951540194,
    "ra": 21.776642,
    "dec": -57.703986,
    "pmra": 84.7,
    "pmdec": -896,
    "rv": 0,
    "Asset Name": "ASTRID 001691",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Slick",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00005405,
    "y_1": 0.00004493,
    "z_1": -0.00004417,
    "Distance in Parsecs_1": 19.0259,
    "HYG Star ID_1": 119436,
    "Gliese Catalog_1": "Gl 836.9B"
  },
  {
    "Astrid #": "ASTRID 001692",
    "HYG Star ID": 97819,
    "Hipparcos Catalogue": 98130,
    "Henry Draper Catalogue": 188474,
    "Gliese Catalog": "GJ 1246",
    "Distance in Parsecs": 19.044,
    "mag": 8.42,
    "Absolute Visual Magnitude": 7.021,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.025,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.924295,
    "y": -14.205466,
    "z": -9.903749,
    "vx": 0.00003367,
    "vy": 0.00001697,
    "vz": 0.0000026,
    "rarad": 5.22122406754524,
    "decrad": -0.546904393948928,
    "pmrarad": 0.00000197823374213888,
    "pmdecrad": 1.59891551e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 97819,
    "lum": 0.135394181140507,
    "ra": 19.943607,
    "dec": -31.335314,
    "pmra": 408.04,
    "pmdec": 32.98,
    "rv": 0,
    "Asset Name": "ASTRID 001692",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Bangs",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00003367,
    "y_1": 0.00001697,
    "z_1": 0.0000026,
    "Distance in Parsecs_1": 19.044,
    "HYG Star ID_1": 97819,
    "Hipparcos Catalogue_1": 98130,
    "Henry Draper Catalogue_1": 188474,
    "Gliese Catalog_1": "GJ 1246"
  },
  {
    "Astrid #": "ASTRID 001693",
    "HYG Star ID": 7221,
    "Hipparcos Catalogue": 7235,
    "Henry Draper Catalogue": 9540,
    "Gliese Catalog": "Gl 59A",
    "Distance in Parsecs": 19.0512,
    "mag": 6.97,
    "Absolute Visual Magnitude": 5.57,
    "Spectral Type": "K0V",
    "Color Index": 0.766,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.960311,
    "y": 6.878837,
    "z": -7.802662,
    "vx": -0.00005755,
    "vy": 0.00000254,
    "vz": 0.00000713,
    "rarad": 0.406937577626629,
    "decrad": -0.421985038180925,
    "pmrarad": 0.00000131801447195833,
    "pmdecrad": -7.7327782e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 7221,
    "base": "Gl  59",
    "lum": 0.515228644581756,
    "ra": 1.554387,
    "dec": -24.177962,
    "pmra": 271.86,
    "pmdec": -159.5,
    "rv": -49.1,
    "Asset Name": "ASTRID 001693",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Oh Snap",
    "Hair": "Windy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00005755,
    "y_1": 0.00000254,
    "z_1": 0.00000713,
    "Distance in Parsecs_1": 19.0512,
    "HYG Star ID_1": 7221,
    "Hipparcos Catalogue_1": 7235,
    "Henry Draper Catalogue_1": 9540,
    "Gliese Catalog_1": "Gl 59A"
  },
  {
    "Astrid #": "ASTRID 001694",
    "HYG Star ID": 65939,
    "Hipparcos Catalogue": 66147,
    "Henry Draper Catalogue": 117936,
    "Gliese Catalog": "GJ 2102",
    "Distance in Parsecs": 19.0694,
    "mag": 7.98,
    "Absolute Visual Magnitude": 6.578,
    "Spectral Type": "K0",
    "Color Index": 1.026,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.306819,
    "y": -7.48395,
    "z": 2.847194,
    "vx": -0.00001739,
    "vy": 0.00004349,
    "vz": 0.00000864,
    "rarad": 3.54973815651211,
    "decrad": 0.149867303378902,
    "pmrarad": -0.00000245548432927777,
    "pmdecrad": 4.58100446e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 65939,
    "lum": 0.203610420113196,
    "ra": 13.559001,
    "dec": 8.586764,
    "pmra": -506.48,
    "pmdec": 94.49,
    "rv": 0,
    "Asset Name": "ASTRID 001694",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh Snap",
    "Hair": "Heartbreaker",
    "Outfit": "Elven",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001739,
    "y_1": 0.00004349,
    "z_1": 0.00000864,
    "Distance in Parsecs_1": 19.0694,
    "HYG Star ID_1": 65939,
    "Hipparcos Catalogue_1": 66147,
    "Henry Draper Catalogue_1": 117936,
    "Gliese Catalog_1": "GJ 2102"
  },
  {
    "Astrid #": "ASTRID 001695",
    "HYG Star ID": 77278,
    "Hipparcos Catalogue": 77514,
    "Gliese Catalog": "NN 3920",
    "Distance in Parsecs": 19.073,
    "mag": 12.84,
    "Absolute Visual Magnitude": 11.438,
    "Spectral Type": "M:",
    "Color Index": 2.2,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.459816,
    "y": -10.101385,
    "z": 14.832805,
    "vx": -0.00001783,
    "vy": 0.00003505,
    "vz": 0.0000161,
    "rarad": 4.1434411472681,
    "decrad": 0.890976504944216,
    "pmrarad": -0.00000177756935975694,
    "pmdecrad": 0.000001343079339,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 77278,
    "lum": 0.00231632769598744,
    "var_min": 12.985,
    "var_max": 12.715,
    "ra": 15.826779,
    "dec": 51.049193,
    "pmra": -366.65,
    "pmdec": 277.03,
    "rv": 0,
    "Asset Name": "ASTRID 001695",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Grecian",
    "Outfit": "Star Knight",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001783,
    "y_1": 0.00003505,
    "z_1": 0.0000161,
    "Distance in Parsecs_1": 19.073,
    "HYG Star ID_1": 77278,
    "Hipparcos Catalogue_1": 77514,
    "Gliese Catalog_1": "NN 3920"
  },
  {
    "Astrid #": "ASTRID 001696",
    "HYG Star ID": 94262,
    "Hipparcos Catalogue": 94557,
    "Gliese Catalog": "Wo 9652A",
    "Distance in Parsecs": 19.084,
    "mag": 11.56,
    "Absolute Visual Magnitude": 10.157,
    "Spectral Type": "M:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.763123,
    "y": -17.062539,
    "z": 6.313101,
    "vx": -0.00005838,
    "vy": -0.00000567,
    "vz": 0.00003796,
    "rarad": 5.0381240088803,
    "decrad": 0.33715748243332,
    "pmrarad": -0.00000299338510785416,
    "pmdecrad": 0.000002108163808,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 1,
    "comp_primary": 94262,
    "base": "Wo 9652",
    "lum": 0.00753702576688425,
    "var_min": 11.652,
    "var_max": 11.442,
    "ra": 19.244216,
    "dec": 19.317701,
    "pmra": -617.43,
    "pmdec": 434.84,
    "rv": 0,
    "Asset Name": "ASTRID 001696",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous",
    "Hair": "Halo Boy",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": -0.00005838,
    "y_1": -0.00000567,
    "z_1": 0.00003796,
    "Distance in Parsecs_1": 19.084,
    "HYG Star ID_1": 94262,
    "Hipparcos Catalogue_1": 94557,
    "Gliese Catalog_1": "Wo 9652A"
  },
  {
    "Astrid #": "ASTRID 001697",
    "HYG Star ID": 76083,
    "Hipparcos Catalogue": 76315,
    "Henry Draper Catalogue": 139477,
    "Distance in Parsecs": 19.1058,
    "mag": 8.39,
    "Absolute Visual Magnitude": 6.984,
    "Spectral Type": "K5",
    "Color Index": 1.063,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.622655,
    "y": -7.691821,
    "z": 16.560593,
    "vx": 0.00000516,
    "vy": -0.00001997,
    "vz": -0.00000752,
    "rarad": 4.08115742777855,
    "decrad": 1.04871584643543,
    "pmrarad": 8.34946120652777e-7,
    "pmdecrad": -7.89664522e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 76083,
    "lum": 0.140087698395458,
    "ra": 15.588873,
    "dec": 60.086992,
    "pmra": 172.22,
    "pmdec": -162.88,
    "rv": 0,
    "Asset Name": "ASTRID 001697",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Spiky",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000516,
    "y_1": -0.00001997,
    "z_1": -0.00000752,
    "Distance in Parsecs_1": 19.1058,
    "HYG Star ID_1": 76083,
    "Hipparcos Catalogue_1": 76315,
    "Henry Draper Catalogue_1": 139477
  },
  {
    "Astrid #": "ASTRID 001698",
    "HYG Star ID": 21034,
    "Hipparcos Catalogue": 21086,
    "Distance in Parsecs": 19.1095,
    "mag": 11.47,
    "Absolute Visual Magnitude": 10.064,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.024122,
    "y": 17.201502,
    "z": -4.46576,
    "vx": 0.00001218,
    "vy": -0.00000981,
    "vz": -0.00001864,
    "rarad": 1.18311821109874,
    "decrad": -0.235874330763573,
    "pmrarad": -7.84331572402777e-7,
    "pmdecrad": -0.000001003515837,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 21034,
    "lum": 0.00821107464085087,
    "var_min": 11.537,
    "var_max": 11.317,
    "ra": 4.519179,
    "dec": -13.514604,
    "pmra": -161.78,
    "pmdec": -206.99,
    "rv": 0,
    "Asset Name": "ASTRID 001698",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Prince",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00001218,
    "y_1": -0.00000981,
    "z_1": -0.00001864,
    "Distance in Parsecs_1": 19.1095,
    "HYG Star ID_1": 21034,
    "Hipparcos Catalogue_1": 21086
  },
  {
    "Astrid #": "ASTRID 001699",
    "HYG Star ID": 82846,
    "Hipparcos Catalogue": 83101,
    "Henry Draper Catalogue": 153026,
    "Gliese Catalog": "Gl 646A",
    "Distance in Parsecs": 19.1131,
    "mag": 8.33,
    "Absolute Visual Magnitude": 6.923,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.181,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.882178,
    "y": -14.215427,
    "z": -12.172734,
    "vx": 0.00001209,
    "vy": -0.00005169,
    "vz": -0.00001174,
    "rarad": 4.44579024674467,
    "decrad": -0.690426942134097,
    "pmrarad": 0.00000132305653423611,
    "pmdecrad": 0.000001081813246,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 82846,
    "base": "Gl 646",
    "lum": 0.148183551751643,
    "ra": 16.981668,
    "dec": -39.55855,
    "pmra": 272.9,
    "pmdec": 223.14,
    "rv": 42.5,
    "Asset Name": "ASTRID 001699",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Spiky",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00001209,
    "y_1": -0.00005169,
    "z_1": -0.00001174,
    "Distance in Parsecs_1": 19.1131,
    "HYG Star ID_1": 82846,
    "Hipparcos Catalogue_1": 83101,
    "Henry Draper Catalogue_1": 153026,
    "Gliese Catalog_1": "Gl 646A"
  },
  {
    "Astrid #": "ASTRID 001700",
    "HYG Star ID": 118373,
    "Gliese Catalog": "GJ 1080",
    "Distance in Parsecs": 19.1205,
    "mag": 12.8,
    "Absolute Visual Magnitude": 11.393,
    "Spectral Type": "M5 V",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.638471,
    "y": 18.911634,
    "z": 0.991029,
    "vx": 0.0000344,
    "vy": 6.7e-7,
    "vz": -0.00010382,
    "rarad": 1.43217547465235,
    "decrad": 0.0518538885487,
    "pmrarad": -0.00000177732695291666,
    "pmdecrad": -0.000005437709026,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118373,
    "lum": 0.00241434872971414,
    "ra": 5.470507,
    "dec": 2.971009,
    "pmra": -366.6,
    "pmdec": -1121.61,
    "rv": 0,
    "Asset Name": "ASTRID 001700",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Unimpressed",
    "Hair": "Straw Hat",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000344,
    "y_1": 6.7e-7,
    "z_1": -0.00010382,
    "Distance in Parsecs_1": 19.1205,
    "HYG Star ID_1": 118373,
    "Gliese Catalog_1": "GJ 1080"
  },
  {
    "Astrid #": "ASTRID 001701",
    "HYG Star ID": 119118,
    "Gliese Catalog": "Gl 646B",
    "Distance in Parsecs": 19.1205,
    "mag": 10.4,
    "Absolute Visual Magnitude": 8.993,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -3.883799,
    "y": -14.219952,
    "z": -12.177955,
    "vx": 0.00002145,
    "vy": -0.00001727,
    "vz": 0.00001332,
    "rarad": 4.44574886295853,
    "decrad": -0.69047789291115,
    "pmrarad": 0.00000132009917078472,
    "pmdecrad": 9.03881777e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 2,
    "comp_primary": 82846,
    "base": "Gl 646",
    "lum": 0.0220191221148447,
    "ra": 16.98151,
    "dec": -39.561469,
    "pmra": 272.29,
    "pmdec": 186.44,
    "rv": 0,
    "Asset Name": "ASTRID 001701",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Fire",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00002145,
    "y_1": -0.00001727,
    "z_1": 0.00001332,
    "Distance in Parsecs_1": 19.1205,
    "HYG Star ID_1": 119118,
    "Gliese Catalog_1": "Gl 646B"
  },
  {
    "Astrid #": "ASTRID 001702",
    "HYG Star ID": 115080,
    "Hipparcos Catalogue": 115445,
    "Henry Draper Catalogue": 220339,
    "Gliese Catalog": "Gl 894.5",
    "Distance in Parsecs": 19.1241,
    "mag": 7.8,
    "Absolute Visual Magnitude": 6.392,
    "Spectral Type": "K2V",
    "Color Index": 0.881,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.54467,
    "y": -3.013416,
    "z": -3.571835,
    "vx": 0.00004676,
    "vy": 0.00003476,
    "vz": 0.00001685,
    "rarad": 6.12209795080829,
    "decrad": -0.187871494610838,
    "pmrarad": 0.00000218660666204166,
    "pmdecrad": 0.000001261873047,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 115080,
    "lum": 0.241657345143682,
    "ra": 23.384692,
    "dec": -10.764244,
    "pmra": 451.02,
    "pmdec": 260.28,
    "rv": 35.9,
    "Asset Name": "ASTRID 001702",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Fired Up",
    "Hair": "High Bun",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00004676,
    "y_1": 0.00003476,
    "z_1": 0.00001685,
    "Distance in Parsecs_1": 19.1241,
    "HYG Star ID_1": 115080,
    "Hipparcos Catalogue_1": 115445,
    "Henry Draper Catalogue_1": 220339,
    "Gliese Catalog_1": "Gl 894.5"
  },
  {
    "Astrid #": "ASTRID 001703",
    "HYG Star ID": 33928,
    "Hipparcos Catalogue": 34017,
    "Henry Draper Catalogue": 52711,
    "Harvard Revised Catalogue": 2643,
    "Gliese Catalog": "Gl 262",
    "Distance in Parsecs": 19.1314,
    "mag": 5.93,
    "Absolute Visual Magnitude": 4.521,
    "Spectral Type": "G4V",
    "Color Index": 0.595,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.562639,
    "y": 16.041802,
    "z": 9.373479,
    "vx": -0.00003038,
    "vy": 0.00005402,
    "vz": -0.00005421,
    "rarad": 1.84789972225301,
    "decrad": 0.512028653824635,
    "pmrarad": 7.55000344729166e-7,
    "pmdecrad": -0.000004014305756,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 33928,
    "lum": 1.35394181140507,
    "ra": 7.058457,
    "dec": 29.337081,
    "pmra": 155.73,
    "pmdec": -828.01,
    "rv": 25.4,
    "Asset Name": "ASTRID 001703",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Charmer",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00003038,
    "y_1": 0.00005402,
    "z_1": -0.00005421,
    "Distance in Parsecs_1": 19.1314,
    "HYG Star ID_1": 33928,
    "Hipparcos Catalogue_1": 34017,
    "Henry Draper Catalogue_1": 52711,
    "Harvard Revised Catalogue_1": 2643,
    "Gliese Catalog_1": "Gl 262"
  },
  {
    "Astrid #": "ASTRID 001704",
    "HYG Star ID": 110189,
    "Hipparcos Catalogue": 110534,
    "Gliese Catalog": "Gl 855",
    "Distance in Parsecs": 19.1498,
    "mag": 10.82,
    "Absolute Visual Magnitude": 9.409,
    "Spectral Type": "K",
    "Color Index": 1.47,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.463169,
    "y": -4.235311,
    "z": -16.100266,
    "vx": -0.00001466,
    "vy": 0.00006621,
    "vz": -0.00000171,
    "rarad": 5.86234346060785,
    "decrad": -0.998688373460631,
    "pmrarad": 0.00000284338375509027,
    "pmdecrad": -0.000001823481215,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 110189,
    "lum": 0.0150106673257036,
    "var_min": 10.895,
    "var_max": 10.735,
    "ra": 22.392503,
    "dec": -57.220629,
    "pmra": 586.49,
    "pmdec": -376.12,
    "rv": -20,
    "Asset Name": "ASTRID 001704",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Mohawk",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00001466,
    "y_1": 0.00006621,
    "z_1": -0.00000171,
    "Distance in Parsecs_1": 19.1498,
    "HYG Star ID_1": 110189,
    "Hipparcos Catalogue_1": 110534,
    "Gliese Catalog_1": "Gl 855"
  },
  {
    "Astrid #": "ASTRID 001705",
    "HYG Star ID": 118398,
    "Gliese Catalog": "Gl 220",
    "Distance in Parsecs": 19.1571,
    "mag": 10.81,
    "Absolute Visual Magnitude": 9.398,
    "Spectral Type": "M1.5",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.515402,
    "y": 17.457697,
    "z": 7.871318,
    "vx": -0.00001003,
    "vy": 0.00002317,
    "vz": -0.00005071,
    "rarad": 1.54127930562902,
    "decrad": 0.42342189475715,
    "pmrarad": 5.59232580520833e-7,
    "pmdecrad": -0.000002904004857,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118398,
    "lum": 0.0151635190078728,
    "ra": 5.887253,
    "dec": 24.260288,
    "pmra": 115.35,
    "pmdec": -598.99,
    "rv": 0,
    "Asset Name": "ASTRID 001705",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Wavy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00001003,
    "y_1": 0.00002317,
    "z_1": -0.00005071,
    "Distance in Parsecs_1": 19.1571,
    "HYG Star ID_1": 118398,
    "Gliese Catalog_1": "Gl 220"
  },
  {
    "Astrid #": "ASTRID 001706",
    "HYG Star ID": 15057,
    "Hipparcos Catalogue": 15095,
    "Henry Draper Catalogue": 20280,
    "Gliese Catalog": "Gl 131",
    "Distance in Parsecs": 19.1681,
    "mag": 9.17,
    "Absolute Visual Magnitude": 7.757,
    "Spectral Type": "K5V",
    "Color Index": 1.235,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.330329,
    "y": 12.890659,
    "z": -8.536727,
    "vx": -0.00000341,
    "vy": 0.00002656,
    "vz": 0.00000136,
    "rarad": 0.849728752856497,
    "decrad": -0.46157411284375,
    "pmrarad": 0.00000104855502830555,
    "pmdecrad": 4.74875e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 15057,
    "lum": 0.0687384919590323,
    "ra": 3.245725,
    "dec": -26.446249,
    "pmra": 216.28,
    "pmdec": 97.95,
    "rv": 14.9,
    "Asset Name": "ASTRID 001706",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Firm",
    "Hair": "Charmer",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000341,
    "y_1": 0.00002656,
    "z_1": 0.00000136,
    "Distance in Parsecs_1": 19.1681,
    "HYG Star ID_1": 15057,
    "Hipparcos Catalogue_1": 15095,
    "Henry Draper Catalogue_1": 20280,
    "Gliese Catalog_1": "Gl 131"
  },
  {
    "Astrid #": "ASTRID 001707",
    "HYG Star ID": 103724,
    "Hipparcos Catalogue": 104059,
    "Gliese Catalog": "Gl 817",
    "Distance in Parsecs": 19.1718,
    "mag": 11.45,
    "Absolute Visual Magnitude": 10.037,
    "Spectral Type": "M3",
    "Color Index": 1.443,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.240389,
    "y": -12.687134,
    "z": -5.592005,
    "vx": -0.00013184,
    "vy": 0.0000086,
    "vz": -0.00016695,
    "rarad": 5.51912423211845,
    "decrad": -0.295988029709769,
    "pmrarad": -0.00000443381503415277,
    "pmdecrad": -0.000009869109688,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 103724,
    "lum": 0.00841782707660811,
    "var_min": 11.519,
    "var_max": 11.359,
    "ra": 21.081502,
    "dec": -16.958865,
    "pmra": -914.54,
    "pmdec": -2035.65,
    "rv": -47,
    "Asset Name": "ASTRID 001707",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Braided Ponytail",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00013184,
    "y_1": 0.0000086,
    "z_1": -0.00016695,
    "Distance in Parsecs_1": 19.1718,
    "HYG Star ID_1": 103724,
    "Hipparcos Catalogue_1": 104059,
    "Gliese Catalog_1": "Gl 817"
  },
  {
    "Astrid #": "ASTRID 001708",
    "HYG Star ID": 116830,
    "Hipparcos Catalogue": 117197,
    "Gliese Catalog": "Gl 906",
    "Distance in Parsecs": 19.1791,
    "mag": 9.89,
    "Absolute Visual Magnitude": 8.476,
    "Spectral Type": "M0",
    "Color Index": 1.338,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.436154,
    "y": -0.955945,
    "z": 11.342034,
    "vx": -0.00001845,
    "vy": 0.00003348,
    "vz": -0.00000976,
    "rarad": 6.22133177639749,
    "decrad": 0.632769899679217,
    "pmrarad": 0.00000168278828520833,
    "pmdecrad": 2.21220482e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 116830,
    "lum": 0.03544867444738,
    "ra": 23.763737,
    "dec": 36.255045,
    "pmra": 347.1,
    "pmdec": 45.63,
    "rv": -21.8,
    "Asset Name": "ASTRID 001708",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Greasy",
    "Outfit": "Warlock",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001845,
    "y_1": 0.00003348,
    "z_1": -0.00000976,
    "Distance in Parsecs_1": 19.1791,
    "HYG Star ID_1": 116830,
    "Hipparcos Catalogue_1": 117197,
    "Gliese Catalog_1": "Gl 906"
  },
  {
    "Astrid #": "ASTRID 001709",
    "HYG Star ID": 44769,
    "Hipparcos Catalogue": 44897,
    "Henry Draper Catalogue": 78366,
    "Harvard Revised Catalogue": 3625,
    "Gliese Catalog": "Gl 334.2",
    "Distance in Parsecs": 19.1902,
    "mag": 5.95,
    "Absolute Visual Magnitude": 4.535,
    "Spectral Type": "F9V",
    "Color Index": 0.585,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.691924,
    "y": 10.821992,
    "z": 10.69843,
    "vx": -0.00000902,
    "vy": 0.00003257,
    "vz": 0.00000637,
    "rarad": 2.39481667800609,
    "decrad": 0.591356242593863,
    "pmrarad": -9.26285018069444e-7,
    "pmdecrad": -5.59474987e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 44769,
    "lum": 1.33659551654644,
    "ra": 9.147526,
    "dec": 33.882217,
    "pmra": -191.06,
    "pmdec": -115.4,
    "rv": 26.8,
    "Asset Name": "ASTRID 001709",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Braided Ponytail",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00000902,
    "y_1": 0.00003257,
    "z_1": 0.00000637,
    "Distance in Parsecs_1": 19.1902,
    "HYG Star ID_1": 44769,
    "Hipparcos Catalogue_1": 44897,
    "Henry Draper Catalogue_1": 78366,
    "Harvard Revised Catalogue_1": 3625,
    "Gliese Catalog_1": "Gl 334.2"
  },
  {
    "Astrid #": "ASTRID 001710",
    "HYG Star ID": 97365,
    "Hipparcos Catalogue": 97675,
    "Henry Draper Catalogue": 187691,
    "Harvard Revised Catalogue": 7560,
    "Gliese Catalog": "Gl 768.1A",
    "Distance in Parsecs": 19.1902,
    "mag": 5.12,
    "Absolute Visual Magnitude": 3.705,
    "Spectral Type": "F8V",
    "Color Index": 0.563,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.789994,
    "y": -16.702189,
    "z": 3.469379,
    "vx": 0.00002073,
    "vy": 0.00000858,
    "vz": -0.00001234,
    "rarad": 5.19683712590519,
    "decrad": 0.181788728924791,
    "pmrarad": 0.00000116398916564583,
    "pmdecrad": -6.52122881e-7,
    "bayer": "Omi",
    "flam": 54,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 97365,
    "base": "Gl 768.1",
    "lum": 2.87078058202468,
    "bf": "54Omi Aql",
    "ra": 19.850456,
    "dec": 10.415727,
    "pmra": 240.09,
    "pmdec": -134.51,
    "rv": -0.2,
    "Asset Name": "ASTRID 001710",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Mortified",
    "Hair": "Slick",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00002073,
    "y_1": 0.00000858,
    "z_1": -0.00001234,
    "Distance in Parsecs_1": 19.1902,
    "HYG Star ID_1": 97365,
    "Hipparcos Catalogue_1": 97675,
    "Henry Draper Catalogue_1": 187691,
    "Harvard Revised Catalogue_1": 7560,
    "Gliese Catalog_1": "Gl 768.1A"
  },
  {
    "Astrid #": "ASTRID 001711",
    "HYG Star ID": 118438,
    "Gliese Catalog": "NN 3406A",
    "Distance in Parsecs": 19.1939,
    "mag": 12.44,
    "Absolute Visual Magnitude": 11.024,
    "Spectral Type": "M3.5   J",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.28924,
    "y": 11.822841,
    "z": 14.94609,
    "vx": -0.00002196,
    "vy": 0.00006323,
    "vz": -0.00005336,
    "rarad": 1.76204335682147,
    "decrad": 0.8925744476678,
    "pmrarad": 4.97176429409722e-7,
    "pmdecrad": -0.000004432486644,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118438,
    "base": "NN 3406",
    "lum": 0.00339156386905821,
    "ra": 6.73051,
    "dec": 51.140749,
    "pmra": 102.55,
    "pmdec": -914.27,
    "rv": 0,
    "Asset Name": "ASTRID 001711",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Good Boy",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002196,
    "y_1": 0.00006323,
    "z_1": -0.00005336,
    "Distance in Parsecs_1": 19.1939,
    "HYG Star ID_1": 118438,
    "Gliese Catalog_1": "NN 3406A"
  },
  {
    "Astrid #": "ASTRID 001712",
    "HYG Star ID": 118439,
    "Gliese Catalog": "NN 3407B",
    "Distance in Parsecs": 19.1939,
    "mag": 14.9,
    "Absolute Visual Magnitude": 13.484,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -2.28924,
    "y": 11.822841,
    "z": 14.94609,
    "vx": -0.00002196,
    "vy": 0.00006323,
    "vz": -0.00005336,
    "rarad": 1.76204335682147,
    "decrad": 0.8925744476678,
    "pmrarad": 4.97176429409722e-7,
    "pmdecrad": -0.000004432486644,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118439,
    "base": "NN 3407",
    "lum": 0.000351884388820958,
    "ra": 6.73051,
    "dec": 51.140749,
    "pmra": 102.55,
    "pmdec": -914.27,
    "rv": 0,
    "Asset Name": "ASTRID 001712",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Grecian",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002196,
    "y_1": 0.00006323,
    "z_1": -0.00005336,
    "Distance in Parsecs_1": 19.1939,
    "HYG Star ID_1": 118439,
    "Gliese Catalog_1": "NN 3407B"
  },
  {
    "Astrid #": "ASTRID 001713",
    "HYG Star ID": 14519,
    "Hipparcos Catalogue": 14555,
    "Gliese Catalog": "GJ 1054A",
    "Distance in Parsecs": 19.2012,
    "mag": 10.21,
    "Absolute Visual Magnitude": 8.793,
    "Spectral Type": "Se",
    "Spectral Abbreviation": "S",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": 11.542528,
    "y": 12.370176,
    "z": -9.079367,
    "vx": 0.00001949,
    "vy": -0.00002538,
    "vz": -0.0000098,
    "rarad": 0.819997537275233,
    "decrad": -0.492527127029888,
    "pmrarad": -0.00000164419711623611,
    "pmdecrad": -5.79546273e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 14519,
    "base": "GJ 1054",
    "lum": 0.0264728074001756,
    "var_min": 10.307,
    "var_max": 10.117,
    "ra": 3.13216,
    "dec": -28.219726,
    "pmra": -339.14,
    "pmdec": -119.54,
    "rv": 0,
    "Asset Name": "ASTRID 001713",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined Neko",
    "Hair": "Braided Ponytail",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00001949,
    "y_1": -0.00002538,
    "z_1": -0.0000098,
    "Distance in Parsecs_1": 19.2012,
    "HYG Star ID_1": 14519,
    "Hipparcos Catalogue_1": 14555,
    "Gliese Catalog_1": "GJ 1054A"
  },
  {
    "Astrid #": "ASTRID 001714",
    "HYG Star ID": 25480,
    "Hipparcos Catalogue": 25544,
    "Henry Draper Catalogue": 36435,
    "Gliese Catalog": "Gl 204.1",
    "Distance in Parsecs": 19.2012,
    "mag": 6.99,
    "Absolute Visual Magnitude": 5.573,
    "Spectral Type": "G5V",
    "Color Index": 0.755,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.33341,
    "y": 9.385425,
    "z": -16.698094,
    "vx": 0.00001358,
    "vy": -0.00000294,
    "vz": -0.00001574,
    "rarad": 1.42967485790797,
    "decrad": -1.05445802795971,
    "pmrarad": -7.21839088979166e-7,
    "pmdecrad": -4.50052539e-7,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 25480,
    "lum": 0.513806980232344,
    "ra": 5.460956,
    "dec": -60.415995,
    "pmra": -148.89,
    "pmdec": -92.83,
    "rv": 12.9,
    "Asset Name": "ASTRID 001714",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Braid",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 0.00001358,
    "y_1": -0.00000294,
    "z_1": -0.00001574,
    "Distance in Parsecs_1": 19.2012,
    "HYG Star ID_1": 25480,
    "Hipparcos Catalogue_1": 25544,
    "Henry Draper Catalogue_1": 36435,
    "Gliese Catalog_1": "Gl 204.1"
  },
  {
    "Astrid #": "ASTRID 001715",
    "HYG Star ID": 114784,
    "Hipparcos Catalogue": 115147,
    "Henry Draper Catalogue": 220140,
    "Distance in Parsecs": 19.2049,
    "mag": 7.53,
    "Absolute Visual Magnitude": 6.113,
    "Spectral Type": "G9V",
    "Color Index": 0.893,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.606073,
    "y": -0.644874,
    "z": 18.85213,
    "vx": -0.00000637,
    "vy": 0.00002018,
    "vz": -0.00001559,
    "rarad": 6.10618954908381,
    "decrad": 1.3788715133577,
    "pmrarad": 9.76123864430555e-7,
    "pmdecrad": 3.47126595e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 114784,
    "lum": 0.31246400858061,
    "var": "V368",
    "var_min": 7.556,
    "var_max": 7.486,
    "ra": 23.323926,
    "dec": 79.003518,
    "pmra": 201.34,
    "pmdec": 71.6,
    "rv": -16.8,
    "Asset Name": "ASTRID 001715",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Hachimaki",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000637,
    "y_1": 0.00002018,
    "z_1": -0.00001559,
    "Distance in Parsecs_1": 19.2049,
    "HYG Star ID_1": 114784,
    "Hipparcos Catalogue_1": 115147,
    "Henry Draper Catalogue_1": 220140
  },
  {
    "Astrid #": "ASTRID 001716",
    "HYG Star ID": 91756,
    "Hipparcos Catalogue": 92043,
    "Henry Draper Catalogue": 173667,
    "Harvard Revised Catalogue": 7061,
    "Gliese Catalog": "Gl 725.2",
    "Distance in Parsecs": 19.2086,
    "mag": 4.19,
    "Absolute Visual Magnitude": 2.773,
    "Spectral Type": "F6V",
    "Color Index": 0.483,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.560021,
    "y": -17.631081,
    "z": 6.741603,
    "vx": 0.00000555,
    "vy": -0.00003186,
    "vz": -0.00002126,
    "rarad": 4.91162745560392,
    "decrad": 0.358600718325217,
    "pmrarad": -4.48452654513888e-8,
    "pmdecrad": -0.000001627277118,
    "flam": 110,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 91756,
    "lum": 6.77329514008606,
    "bf": "110    Her",
    "ra": 18.761035,
    "dec": 20.546308,
    "pmra": -9.25,
    "pmdec": -335.65,
    "rv": 22.3,
    "Asset Name": "ASTRID 001716",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Medium Bob",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000555,
    "y_1": -0.00003186,
    "z_1": -0.00002126,
    "Distance in Parsecs_1": 19.2086,
    "HYG Star ID_1": 91756,
    "Hipparcos Catalogue_1": 92043,
    "Henry Draper Catalogue_1": 173667,
    "Harvard Revised Catalogue_1": 7061,
    "Gliese Catalog_1": "Gl 725.2"
  },
  {
    "Astrid #": "ASTRID 001717",
    "HYG Star ID": 81405,
    "Hipparcos Catalogue": 81655,
    "Gliese Catalog": "NN 3968",
    "Distance in Parsecs": 19.2197,
    "mag": 11.45,
    "Absolute Visual Magnitude": 10.031,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -5.244878,
    "y": -14.571181,
    "z": 11.382831,
    "vx": -0.00000894,
    "vy": 0.00001373,
    "vz": 0.00001346,
    "rarad": 4.36688022482081,
    "decrad": 0.633846008696411,
    "pmrarad": -6.79563336034722e-7,
    "pmdecrad": 8.69222447e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81405,
    "lum": 0.00846447448097699,
    "var_min": 11.545,
    "var_max": 11.305,
    "ra": 16.680254,
    "dec": 36.316701,
    "pmra": -140.17,
    "pmdec": 179.29,
    "rv": 0,
    "Asset Name": "ASTRID 001717",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Bandana",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000894,
    "y_1": 0.00001373,
    "z_1": 0.00001346,
    "Distance in Parsecs_1": 19.2197,
    "HYG Star ID_1": 81405,
    "Hipparcos Catalogue_1": 81655,
    "Gliese Catalog_1": "NN 3968"
  },
  {
    "Astrid #": "ASTRID 001718",
    "HYG Star ID": 1856,
    "Hipparcos Catalogue": 1860,
    "Gliese Catalog": "GJ 1010A",
    "Distance in Parsecs": 19.2271,
    "mag": 11.31,
    "Absolute Visual Magnitude": 9.89,
    "Spectral Type": "K",
    "Color Index": 1.462,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.240884,
    "y": 0.436015,
    "z": 18.748497,
    "vx": 0.00000392,
    "vy": -0.00007837,
    "vz": 9.4e-7,
    "rarad": 0.102577420378581,
    "decrad": 1.34720728407392,
    "pmrarad": -0.00000407607101927083,
    "pmdecrad": 2.20008448e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 1856,
    "base": "GJ 1010",
    "lum": 0.00963829023623969,
    "var_min": 11.376,
    "var_max": 11.256,
    "ra": 0.391817,
    "dec": 77.189292,
    "pmra": -840.75,
    "pmdec": 45.38,
    "rv": 0,
    "Asset Name": "ASTRID 001718",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Fedora",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00000392,
    "y_1": -0.00007837,
    "z_1": 9.4e-7,
    "Distance in Parsecs_1": 19.2271,
    "HYG Star ID_1": 1856,
    "Hipparcos Catalogue_1": 1860,
    "Gliese Catalog_1": "GJ 1010A"
  },
  {
    "Astrid #": "ASTRID 001719",
    "HYG Star ID": 117980,
    "Gliese Catalog": "NN 3027",
    "Distance in Parsecs": 19.2308,
    "mag": 13.86,
    "Absolute Visual Magnitude": 12.44,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.951157,
    "y": 1.400559,
    "z": 8.973313,
    "vx": 0.00000153,
    "vy": 0.00003627,
    "vz": -0.00000854,
    "rarad": 0.0824237817308781,
    "decrad": 0.48545581834705,
    "pmrarad": 0.00000187317461756249,
    "pmdecrad": -5.01913059e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117980,
    "lum": 0.000920449571753171,
    "ra": 0.314836,
    "dec": 27.81457,
    "pmra": 386.37,
    "pmdec": -103.53,
    "rv": 0,
    "Asset Name": "ASTRID 001719",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Happy Vamp",
    "Hair": "Bob",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000153,
    "y_1": 0.00003627,
    "z_1": -0.00000854,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 117980,
    "Gliese Catalog_1": "NN 3027"
  },
  {
    "Astrid #": "ASTRID 001720",
    "HYG Star ID": 118142,
    "Gliese Catalog": "NN 3157",
    "Distance in Parsecs": 19.2308,
    "mag": 14.05,
    "Absolute Visual Magnitude": 12.63,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.271983,
    "y": 9.049657,
    "z": 11.71861,
    "vx": -0.00001178,
    "vy": 0.00002179,
    "vz": -0.00000449,
    "rarad": 0.63538719839154,
    "decrad": 0.65526171117815,
    "pmrarad": 0.00000127544783080555,
    "pmdecrad": -2.94456437e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118142,
    "lum": 0.000772680585095701,
    "ra": 2.427,
    "dec": 37.543731,
    "pmra": 263.08,
    "pmdec": -60.74,
    "rv": 0,
    "Asset Name": "ASTRID 001720",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Fedora",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001178,
    "y_1": 0.00002179,
    "z_1": -0.00000449,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118142,
    "Gliese Catalog_1": "NN 3157"
  },
  {
    "Astrid #": "ASTRID 001721",
    "HYG Star ID": 118300,
    "Gliese Catalog": "NN 3284",
    "Distance in Parsecs": 19.2308,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.71,
    "Spectral Type": "dM0",
    "Color Index": 1.4,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 7.402444,
    "y": 16.986847,
    "z": 5.145341,
    "vx": -0.00001053,
    "vy": 0.00000539,
    "vz": -0.00000263,
    "rarad": 1.15983350467445,
    "decrad": 0.2708570282968,
    "pmrarad": 6.14113489159722e-7,
    "pmdecrad": -1.41774064e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118300,
    "lum": 0.00452897579903619,
    "ra": 4.430238,
    "dec": 15.518965,
    "pmra": 126.67,
    "pmdec": -29.24,
    "rv": 0,
    "Asset Name": "ASTRID 001721",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Nervous",
    "Hair": "Pixie",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001053,
    "y_1": 0.00000539,
    "z_1": -0.00000263,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118300,
    "Gliese Catalog_1": "NN 3284"
  },
  {
    "Astrid #": "ASTRID 001722",
    "HYG Star ID": 118369,
    "Gliese Catalog": "NN 3344",
    "Distance in Parsecs": 19.2308,
    "mag": 11.7,
    "Absolute Visual Magnitude": 10.28,
    "Spectral Type": "m",
    "Color Index": 1.4,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.120966,
    "y": 5.761276,
    "z": -18.313242,
    "vx": 0.00001917,
    "vy": 0.00007209,
    "vz": 0.00002384,
    "rarad": 1.37865817712518,
    "decrad": -1.26064378570855,
    "pmrarad": -2.62817496229166e-7,
    "pmdecrad": 0.000004063945829,
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 118369,
    "lum": 0.00672976656284317,
    "ra": 5.266086,
    "dec": -72.229568,
    "pmra": -54.21,
    "pmdec": 838.25,
    "rv": 0,
    "Asset Name": "ASTRID 001722",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Windy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": 0.00001917,
    "y_1": 0.00007209,
    "z_1": 0.00002384,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118369,
    "Gliese Catalog_1": "NN 3344"
  },
  {
    "Astrid #": "ASTRID 001723",
    "HYG Star ID": 118429,
    "Gliese Catalog": "NN 3395",
    "Distance in Parsecs": 19.2308,
    "mag": 11.09,
    "Absolute Visual Magnitude": 9.67,
    "Spectral Type": "M0",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.66961,
    "y": 12.237465,
    "z": 14.740439,
    "vx": -0.00007162,
    "vy": 0.00004056,
    "vz": -0.00004176,
    "rarad": 1.70629854095715,
    "decrad": 0.87337609613955,
    "pmrarad": 0.00000340571914316666,
    "pmdecrad": -0.000003381997209,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 118429,
    "lum": 0.0118032063565172,
    "ra": 6.51758,
    "dec": 50.040764,
    "pmra": 702.48,
    "pmdec": -697.59,
    "rv": 0,
    "Asset Name": "ASTRID 001723",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oof",
    "Hair": "Round Brush",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00007162,
    "y_1": 0.00004056,
    "z_1": -0.00004176,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118429,
    "Gliese Catalog_1": "NN 3395"
  },
  {
    "Astrid #": "ASTRID 001724",
    "HYG Star ID": 118613,
    "Gliese Catalog": "NN 3546",
    "Distance in Parsecs": 19.2308,
    "mag": 9.55,
    "Absolute Visual Magnitude": 8.13,
    "Spectral Type": "K5",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.00757,
    "y": 11.078324,
    "z": 8.825958,
    "vx": 0.00006069,
    "vy": 0.00002881,
    "vz": 0.00005332,
    "rarad": 2.43614237155981,
    "decrad": 0.476811966859,
    "pmrarad": -0.00000318672880365972,
    "pmdecrad": 0.00000312069718,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 118613,
    "lum": 0.0487528490103385,
    "ra": 9.305378,
    "dec": 27.319313,
    "pmra": -657.31,
    "pmdec": 643.69,
    "rv": 0,
    "Asset Name": "ASTRID 001724",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Braided Bun",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00006069,
    "y_1": 0.00002881,
    "z_1": 0.00005332,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118613,
    "Gliese Catalog_1": "NN 3546"
  },
  {
    "Astrid #": "ASTRID 001725",
    "HYG Star ID": 118686,
    "Gliese Catalog": "NN 3604",
    "Distance in Parsecs": 19.2308,
    "mag": 13,
    "Absolute Visual Magnitude": 11.58,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.991862,
    "y": 6.309972,
    "z": 10.259239,
    "vx": 0.00002608,
    "vy": 0.00003654,
    "vz": 0.00001565,
    "rarad": 2.74322643326643,
    "decrad": 0.56270915236465,
    "pmrarad": -0.00000227765467125,
    "pmdecrad": 9.62127293e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118686,
    "lum": 0.00203235701093622,
    "ra": 10.478353,
    "dec": 32.24086,
    "pmra": -469.8,
    "pmdec": 198.45,
    "rv": 0,
    "Asset Name": "ASTRID 001725",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Sweetheart",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002608,
    "y_1": 0.00003654,
    "z_1": 0.00001565,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118686,
    "Gliese Catalog_1": "NN 3604"
  },
  {
    "Astrid #": "ASTRID 001726",
    "HYG Star ID": 118845,
    "Gliese Catalog": "NN 3747",
    "Distance in Parsecs": 19.2308,
    "mag": 12.63,
    "Absolute Visual Magnitude": 11.21,
    "Spectral Type": "M3",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.788493,
    "y": -3.921876,
    "z": -1.197956,
    "vx": -0.00000952,
    "vy": 0.00004563,
    "vz": -1.6e-7,
    "rarad": 3.34737628857986,
    "decrad": -0.0623339819999,
    "pmrarad": -0.00000242406840277777,
    "pmdecrad": -8.459998e-9,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118845,
    "lum": 0.00285759054337494,
    "ra": 12.786036,
    "dec": -3.571474,
    "pmra": -500,
    "pmdec": -1.75,
    "rv": 0,
    "Asset Name": "ASTRID 001726",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Sweetheart",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000952,
    "y_1": 0.00004563,
    "z_1": -1.6e-7,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118845,
    "Gliese Catalog_1": "NN 3747"
  },
  {
    "Astrid #": "ASTRID 001727",
    "HYG Star ID": 118858,
    "Gliese Catalog": "NN 3757",
    "Distance in Parsecs": 19.2308,
    "mag": 16,
    "Absolute Visual Magnitude": 14.58,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.419498,
    "y": -4.883823,
    "z": 2.587283,
    "vx": -0.00001665,
    "vy": 0.0000631,
    "vz": 6.8e-7,
    "rarad": 3.40077465090807,
    "decrad": 0.1349477038421,
    "pmrarad": -0.00000339350183841666,
    "pmdecrad": 3.5536842e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118858,
    "lum": 0.000128233058265602,
    "ra": 12.990002,
    "dec": 7.731934,
    "pmra": -699.96,
    "pmdec": 7.33,
    "rv": 0,
    "Asset Name": "ASTRID 001727",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Owie",
    "Hair": "Good Boy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001665,
    "y_1": 0.0000631,
    "z_1": 6.8e-7,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 118858,
    "Gliese Catalog_1": "NN 3757"
  },
  {
    "Astrid #": "ASTRID 001728",
    "HYG Star ID": 119132,
    "Gliese Catalog": "NN 3990",
    "Distance in Parsecs": 19.2308,
    "mag": 13.99,
    "Absolute Visual Magnitude": 12.57,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.082412,
    "y": -18.367641,
    "z": 3.973328,
    "vx": -0.00003394,
    "vy": 0.00000573,
    "vz": -0.00000839,
    "rarad": 4.49368496117392,
    "decrad": 0.2081117155392,
    "pmrarad": -0.00000178755652157638,
    "pmdecrad": -4.45689216e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119132,
    "lum": 0.000816582371358591,
    "ra": 17.164612,
    "dec": 11.923923,
    "pmra": -368.71,
    "pmdec": -91.93,
    "rv": 0,
    "Asset Name": "ASTRID 001728",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Prince",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003394,
    "y_1": 0.00000573,
    "z_1": -0.00000839,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119132,
    "Gliese Catalog_1": "NN 3990"
  },
  {
    "Astrid #": "ASTRID 001729",
    "HYG Star ID": 119220,
    "Gliese Catalog": "NN 4059",
    "Distance in Parsecs": 19.2308,
    "mag": 12.85,
    "Absolute Visual Magnitude": 11.43,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.167536,
    "y": -18.729898,
    "z": 3.783703,
    "vx": -7.4e-7,
    "vy": -0.00000507,
    "vz": -0.00002464,
    "rarad": 4.82760254679134,
    "decrad": 0.1980442630861,
    "pmrarad": -6.85041730624999e-8,
    "pmdecrad": -0.000001307198278,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119220,
    "lum": 0.002333458062281,
    "ra": 18.440083,
    "dec": 11.3471,
    "pmra": -14.13,
    "pmdec": -269.63,
    "rv": 0,
    "Asset Name": "ASTRID 001729",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Fire",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -7.4e-7,
    "y_1": -0.00000507,
    "z_1": -0.00002464,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119220,
    "Gliese Catalog_1": "NN 4059"
  },
  {
    "Astrid #": "ASTRID 001730",
    "HYG Star ID": 119269,
    "Gliese Catalog": "NN 4104",
    "Distance in Parsecs": 19.2308,
    "mag": 12.7,
    "Absolute Visual Magnitude": 11.28,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.677257,
    "y": -14.185752,
    "z": -12.112447,
    "vx": 0.00003991,
    "vy": 0.00002183,
    "vz": -0.00001016,
    "rarad": 5.03084624504265,
    "decrad": -0.6813552606791,
    "pmrarad": 0.00000232662085298611,
    "pmdecrad": -6.80353582e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119269,
    "lum": 0.00267916832481903,
    "ra": 19.216417,
    "dec": -39.038781,
    "pmra": 479.9,
    "pmdec": -140.33,
    "rv": 0,
    "Asset Name": "ASTRID 001730",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Wavy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003991,
    "y_1": 0.00002183,
    "z_1": -0.00001016,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119269,
    "Gliese Catalog_1": "NN 4104"
  },
  {
    "Astrid #": "ASTRID 001731",
    "HYG Star ID": 119367,
    "Gliese Catalog": "NN 4160A",
    "Distance in Parsecs": 19.2308,
    "mag": 11.27,
    "Absolute Visual Magnitude": 9.85,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.497467,
    "y": -14.310332,
    "z": 2.975127,
    "vx": 0.00001192,
    "vy": 0.00001349,
    "vz": 0.0000148,
    "rarad": 5.43026483633398,
    "decrad": 0.1553302118096,
    "pmrarad": 9.28466679631944e-7,
    "pmdecrad": 7.79076192e-7,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 119367,
    "base": "NN 4160",
    "lum": 0.01,
    "ra": 20.742084,
    "dec": 8.899766,
    "pmra": 191.51,
    "pmdec": 160.7,
    "rv": 0,
    "Asset Name": "ASTRID 001731",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Braid",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00001192,
    "y_1": 0.00001349,
    "z_1": 0.0000148,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119367,
    "Gliese Catalog_1": "NN 4160A"
  },
  {
    "Astrid #": "ASTRID 001732",
    "HYG Star ID": 119368,
    "Gliese Catalog": "NN 4161B",
    "Distance in Parsecs": 19.2308,
    "mag": 12.54,
    "Absolute Visual Magnitude": 11.12,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.496309,
    "y": -14.311114,
    "z": 2.976227,
    "vx": 0.00001192,
    "vy": 0.00001349,
    "vz": 0.0000148,
    "rarad": 5.43019186133398,
    "decrad": 0.1553881208096,
    "pmrarad": 9.28466679631944e-7,
    "pmdecrad": 7.79076192e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119368,
    "base": "NN 4161",
    "lum": 0.00310455958812835,
    "ra": 20.741805,
    "dec": 8.903084,
    "pmra": 191.51,
    "pmdec": 160.7,
    "rv": 0,
    "Asset Name": "ASTRID 001732",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Braided Bun",
    "Outfit": "River Spirit",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001192,
    "y_1": 0.00001349,
    "z_1": 0.0000148,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119368,
    "Gliese Catalog_1": "NN 4161B"
  },
  {
    "Astrid #": "ASTRID 001733",
    "HYG Star ID": 119426,
    "Gliese Catalog": "NN 4211",
    "Distance in Parsecs": 19.2308,
    "mag": 12.57,
    "Absolute Visual Magnitude": 11.15,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.812367,
    "y": -10.941888,
    "z": -0.260334,
    "vx": 0.00001367,
    "vy": 0.00001966,
    "vz": 0.00000379,
    "rarad": 5.67786021707658,
    "decrad": -0.013537786829,
    "pmrarad": 0.00000124500153166666,
    "pmdecrad": 1.9718342e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119426,
    "lum": 0.00301995172040201,
    "ra": 21.687828,
    "dec": -0.775658,
    "pmra": 256.8,
    "pmdec": 40.67,
    "rv": 0,
    "Asset Name": "ASTRID 001733",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Braided Ponytail",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001367,
    "y_1": 0.00001966,
    "z_1": 0.00000379,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119426,
    "Gliese Catalog_1": "NN 4211"
  },
  {
    "Astrid #": "ASTRID 001734",
    "HYG Star ID": 119458,
    "Gliese Catalog": "NN 4240",
    "Distance in Parsecs": 19.2308,
    "mag": 12.9,
    "Absolute Visual Magnitude": 11.48,
    "Spectral Type": "M3.5",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.538072,
    "y": -9.278062,
    "z": 6.504581,
    "vx": 0.00000679,
    "vy": -0.00001666,
    "vz": -0.00003995,
    "rarad": 5.74488940598991,
    "decrad": 0.3450435839559,
    "pmrarad": -5.62820201756944e-7,
    "pmdecrad": -0.000002208020882,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119458,
    "lum": 0.0022284351492703,
    "ra": 21.943861,
    "dec": 19.769541,
    "pmra": -116.09,
    "pmdec": -455.44,
    "rv": 0,
    "Asset Name": "ASTRID 001734",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Warm Smile",
    "Hair": "Bob",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000679,
    "y_1": -0.00001666,
    "z_1": -0.00003995,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119458,
    "Gliese Catalog_1": "NN 4240"
  },
  {
    "Astrid #": "ASTRID 001735",
    "HYG Star ID": 119532,
    "Gliese Catalog": "NN 4300",
    "Distance in Parsecs": 19.2308,
    "mag": 12.55,
    "Absolute Visual Magnitude": 11.13,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.119138,
    "y": -5.023916,
    "z": 9.206374,
    "vx": 0.00000806,
    "vy": 0.00001842,
    "vz": -0.00000405,
    "rarad": 5.98104590833028,
    "decrad": 0.4992083855279,
    "pmrarad": 0.00000103924660563888,
    "pmdecrad": -2.39929442e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119532,
    "lum": 0.0030760968147407,
    "ra": 22.845913,
    "dec": 28.602534,
    "pmra": 214.36,
    "pmdec": -49.49,
    "rv": 0,
    "Asset Name": "ASTRID 001735",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Slick",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000806,
    "y_1": 0.00001842,
    "z_1": -0.00000405,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119532,
    "Gliese Catalog_1": "NN 4300"
  },
  {
    "Astrid #": "ASTRID 001736",
    "HYG Star ID": 119601,
    "Gliese Catalog": "NN 4371",
    "Distance in Parsecs": 19.2308,
    "mag": 15.5,
    "Absolute Visual Magnitude": 14.08,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.591913,
    "y": -0.616765,
    "z": -4.876891,
    "vx": -0.00000477,
    "vy": 0.00004212,
    "vz": -0.00002352,
    "rarad": 6.2500199799893,
    "decrad": -0.2563979890678,
    "pmrarad": 0.00000218107978608333,
    "pmdecrad": -0.000001264321356,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119601,
    "lum": 0.000203235701093622,
    "ra": 23.873318,
    "dec": -14.690523,
    "pmra": 449.88,
    "pmdec": -260.79,
    "rv": 0,
    "Asset Name": "ASTRID 001736",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Elegant",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000477,
    "y_1": 0.00004212,
    "z_1": -0.00002352,
    "Distance in Parsecs_1": 19.2308,
    "HYG Star ID_1": 119601,
    "Gliese Catalog_1": "NN 4371"
  },
  {
    "Astrid #": "ASTRID 001737",
    "HYG Star ID": 15735,
    "Hipparcos Catalogue": 15774,
    "Henry Draper Catalogue": 21209,
    "Gliese Catalog": "Gl 140.1A",
    "Distance in Parsecs": 19.2493,
    "mag": 8.58,
    "Absolute Visual Magnitude": 7.158,
    "Spectral Type": "K3V",
    "Color Index": 1.068,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.817887,
    "y": 9.593053,
    "z": -14.743993,
    "vx": -0.00001099,
    "vy": 0.00002315,
    "vz": 0.00002552,
    "rarad": 0.886998109608444,
    "decrad": -0.872523797022621,
    "pmrarad": 0.00000120224096504166,
    "pmdecrad": 0.000001290283129,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 15735,
    "base": "Gl 140.1",
    "lum": 0.119343837921079,
    "var_min": 8.705,
    "var_max": 8.525,
    "ra": 3.388083,
    "dec": -49.991931,
    "pmra": 247.98,
    "pmdec": 266.14,
    "rv": -12.2,
    "Asset Name": "ASTRID 001737",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Medium Bob",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": -0.00001099,
    "y_1": 0.00002315,
    "z_1": 0.00002552,
    "Distance in Parsecs_1": 19.2493,
    "HYG Star ID_1": 15735,
    "Hipparcos Catalogue_1": 15774,
    "Henry Draper Catalogue_1": 21209,
    "Gliese Catalog_1": "Gl 140.1A"
  },
  {
    "Astrid #": "ASTRID 001738",
    "HYG Star ID": 29727,
    "Hipparcos Catalogue": 29800,
    "Henry Draper Catalogue": 43386,
    "Harvard Revised Catalogue": 2241,
    "Gliese Catalog": "Wo 9207",
    "Distance in Parsecs": 19.2493,
    "mag": 5.04,
    "Absolute Visual Magnitude": 3.618,
    "Spectral Type": "F5IV-V",
    "Color Index": 0.431,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.348406,
    "y": 18.761116,
    "z": 4.091564,
    "vx": -0.00000809,
    "vy": 0.00000443,
    "vz": 0.00001888,
    "rarad": 1.64254524492883,
    "decrad": 0.214189651220585,
    "pmrarad": 4.02734724437499e-7,
    "pmdecrad": 9.03110924e-7,
    "flam": 74,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29727,
    "lum": 3.11028366868514,
    "bf": "74    Ori",
    "ra": 6.274061,
    "dec": 12.272163,
    "pmra": 83.07,
    "pmdec": 186.28,
    "rv": 8.7,
    "Asset Name": "ASTRID 001738",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Goddess",
    "Outfit": "Super",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000809,
    "y_1": 0.00000443,
    "z_1": 0.00001888,
    "Distance in Parsecs_1": 19.2493,
    "HYG Star ID_1": 29727,
    "Hipparcos Catalogue_1": 29800,
    "Henry Draper Catalogue_1": 43386,
    "Harvard Revised Catalogue_1": 2241,
    "Gliese Catalog_1": "Wo 9207"
  },
  {
    "Astrid #": "ASTRID 001739",
    "HYG Star ID": 29787,
    "Hipparcos Catalogue": 29860,
    "Henry Draper Catalogue": 43587,
    "Harvard Revised Catalogue": 2251,
    "Gliese Catalog": "Gl 231.1A",
    "Distance in Parsecs": 19.2493,
    "mag": 5.7,
    "Absolute Visual Magnitude": 4.278,
    "Spectral Type": "G0.5Vb",
    "Color Index": 0.61,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.443333,
    "y": 19.118773,
    "z": 1.711198,
    "vx": 0.00001699,
    "vy": 0.00000967,
    "vz": 0.00001678,
    "rarad": 1.64614641269791,
    "decrad": 0.0890137436605075,
    "pmrarad": -9.18091666868055e-7,
    "pmdecrad": 8.29904058e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29787,
    "base": "Gl 231.1",
    "lum": 1.69355770869151,
    "ra": 6.287816,
    "dec": 5.100112,
    "pmra": -189.37,
    "pmdec": 171.18,
    "rv": 9.6,
    "Asset Name": "ASTRID 001739",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Fedora",
    "Outfit": "Wizard",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00001699,
    "y_1": 0.00000967,
    "z_1": 0.00001678,
    "Distance in Parsecs_1": 19.2493,
    "HYG Star ID_1": 29787,
    "Hipparcos Catalogue_1": 29860,
    "Henry Draper Catalogue_1": 43587,
    "Harvard Revised Catalogue_1": 2251,
    "Gliese Catalog_1": "Gl 231.1A"
  },
  {
    "Astrid #": "ASTRID 001740",
    "HYG Star ID": 63051,
    "Hipparcos Catalogue": 63253,
    "Gliese Catalog": "Gl 490A",
    "Distance in Parsecs": 19.2567,
    "mag": 10.57,
    "Absolute Visual Magnitude": 9.147,
    "Spectral Type": "M0Vvar",
    "Color Index": 1.423,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.235201,
    "y": -3.916767,
    "z": 11.107011,
    "vx": -0.00000974,
    "vy": 0.00002291,
    "vz": -0.00001752,
    "rarad": 3.39323239522718,
    "decrad": 0.614792071900845,
    "pmrarad": -0.00000127825975015277,
    "pmdecrad": -8.55211332e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 63051,
    "base": "Gl 490",
    "lum": 0.0191073298104287,
    "var": "BF",
    "var_min": 10.659,
    "var_max": 10.469,
    "ra": 12.961193,
    "dec": 35.224991,
    "pmra": -263.66,
    "pmdec": -176.4,
    "rv": -6.9,
    "Asset Name": "ASTRID 001740",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Undercut",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000974,
    "y_1": 0.00002291,
    "z_1": -0.00001752,
    "Distance in Parsecs_1": 19.2567,
    "HYG Star ID_1": 63051,
    "Hipparcos Catalogue_1": 63253,
    "Gliese Catalog_1": "Gl 490A"
  },
  {
    "Astrid #": "ASTRID 001741",
    "HYG Star ID": 57188,
    "Hipparcos Catalogue": 57361,
    "Distance in Parsecs": 19.2604,
    "mag": 11.79,
    "Absolute Visual Magnitude": 10.367,
    "Color Index": 1.482,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -18.022114,
    "y": 1.135909,
    "z": -6.698966,
    "vx": -0.00000338,
    "vy": -0.0000148,
    "vz": 0.00000658,
    "rarad": 3.07864685560212,
    "decrad": -0.355234601501771,
    "pmrarad": 7.78125957291666e-7,
    "pmdecrad": 3.64628369e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 57188,
    "lum": 0.0062115502083244,
    "var_min": 11.885,
    "var_max": 11.695,
    "ra": 11.759565,
    "dec": -20.353443,
    "pmra": 160.5,
    "pmdec": 75.21,
    "rv": 0,
    "Asset Name": "ASTRID 001741",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Hurt",
    "Hair": "Braid",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": -0.00000338,
    "y_1": -0.0000148,
    "z_1": 0.00000658,
    "Distance in Parsecs_1": 19.2604,
    "HYG Star ID_1": 57188,
    "Hipparcos Catalogue_1": 57361
  },
  {
    "Astrid #": "ASTRID 001742",
    "HYG Star ID": 118011,
    "Gliese Catalog": "GJ 1019",
    "Distance in Parsecs": 19.2678,
    "mag": 14.52,
    "Absolute Visual Magnitude": 13.096,
    "Spectral Type": "m",
    "Color Index": 1.59,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.636257,
    "y": 3.203036,
    "z": 9.177345,
    "vx": 0.00004827,
    "vy": -0.00000299,
    "vz": -0.0000864,
    "rarad": 0.190210318884372,
    "decrad": 0.4964472852482,
    "pmrarad": -6.26282312541666e-7,
    "pmdecrad": -0.000005100715036,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118011,
    "lum": 0.000503037081084624,
    "ra": 0.72655,
    "dec": 28.444334,
    "pmra": -129.18,
    "pmdec": -1052.1,
    "rv": 0,
    "Asset Name": "ASTRID 001742",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Manbun",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004827,
    "y_1": -0.00000299,
    "z_1": -0.0000864,
    "Distance in Parsecs_1": 19.2678,
    "HYG Star ID_1": 118011,
    "Gliese Catalog_1": "GJ 1019"
  },
  {
    "Astrid #": "ASTRID 001743",
    "HYG Star ID": 119486,
    "Gliese Catalog": "Gl 852.1",
    "Distance in Parsecs": 19.2678,
    "mag": 13.55,
    "Absolute Visual Magnitude": 12.126,
    "Spectral Type": "m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.746047,
    "y": -8.048235,
    "z": 5.103327,
    "vx": 0.00000987,
    "vy": 0.00001728,
    "vz": -0.00000513,
    "rarad": 5.83517175545355,
    "decrad": 0.26806186235455,
    "pmrarad": 0.00000103022907118055,
    "pmdecrad": -2.76052909e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119486,
    "lum": 0.00122913617306016,
    "ra": 22.288714,
    "dec": 15.358813,
    "pmra": 212.5,
    "pmdec": -56.94,
    "rv": 0,
    "Asset Name": "ASTRID 001743",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Braided Bun",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000987,
    "y_1": 0.00001728,
    "z_1": -0.00000513,
    "Distance in Parsecs_1": 19.2678,
    "HYG Star ID_1": 119486,
    "Gliese Catalog_1": "Gl 852.1"
  },
  {
    "Astrid #": "ASTRID 001744",
    "HYG Star ID": 84394,
    "Hipparcos Catalogue": 84652,
    "Gliese Catalog": "NN 4001",
    "Distance in Parsecs": 19.2827,
    "mag": 10.59,
    "Absolute Visual Magnitude": 9.164,
    "Spectral Type": "K7",
    "Color Index": 1.44,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.482345,
    "y": -18.956172,
    "z": -0.59945,
    "vx": 0.00001052,
    "vy": -0.00000173,
    "vz": -0.00000641,
    "rarad": 4.53070955371331,
    "decrad": -0.0310924865183275,
    "pmrarad": 5.52881521305555e-7,
    "pmdecrad": -3.32630666e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84394,
    "lum": 0.018810485289187,
    "ra": 17.306036,
    "dec": -1.781468,
    "pmra": 114.04,
    "pmdec": -68.61,
    "rv": 0,
    "Asset Name": "ASTRID 001744",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Good Boy",
    "Outfit": "Sharky",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00001052,
    "y_1": -0.00000173,
    "z_1": -0.00000641,
    "Distance in Parsecs_1": 19.2827,
    "HYG Star ID_1": 84394,
    "Hipparcos Catalogue_1": 84652,
    "Gliese Catalog_1": "NN 4001"
  },
  {
    "Astrid #": "ASTRID 001745",
    "HYG Star ID": 1716,
    "Hipparcos Catalogue": 1720,
    "Gliese Catalog": "NN 3029",
    "Distance in Parsecs": 19.305,
    "mag": 12.21,
    "Absolute Visual Magnitude": 10.782,
    "Spectral Type": "M6",
    "Color Index": 1.537,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.328384,
    "y": 1.261143,
    "z": -13.908512,
    "vx": -0.00002148,
    "vy": -0.00003034,
    "vz": -0.00002333,
    "rarad": 0.0943455638565088,
    "decrad": -0.804467805117186,
    "pmrarad": -0.00000146001639899305,
    "pmdecrad": -0.000001743099107,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 1716,
    "lum": 0.00423838105774349,
    "var_min": 12.337,
    "var_max": 11.837,
    "ra": 0.360374,
    "dec": -46.09261,
    "pmra": -301.15,
    "pmdec": -359.54,
    "rv": 0,
    "Asset Name": "ASTRID 001745",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Warm Smile",
    "Hair": "Mohawk",
    "Outfit": "Super",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002148,
    "y_1": -0.00003034,
    "z_1": -0.00002333,
    "Distance in Parsecs_1": 19.305,
    "HYG Star ID_1": 1716,
    "Hipparcos Catalogue_1": 1720,
    "Gliese Catalog_1": "NN 3029"
  },
  {
    "Astrid #": "ASTRID 001746",
    "HYG Star ID": 23086,
    "Hipparcos Catalogue": 23140,
    "Gliese Catalog": "GJ 1074",
    "Distance in Parsecs": 19.305,
    "mag": 10.97,
    "Absolute Visual Magnitude": 9.542,
    "Spectral Type": "M0V:",
    "Color Index": 1.432,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.211395,
    "y": 11.73215,
    "z": 14.990885,
    "vx": -0.00003892,
    "vy": 0.00003595,
    "vz": -0.00001979,
    "rarad": 1.30360230392415,
    "decrad": 0.889137502628365,
    "pmrarad": 0.00000243633418889583,
    "pmdecrad": -0.000001627471044,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 23086,
    "lum": 0.0132800588624372,
    "var_min": 11.046,
    "var_max": 10.906,
    "ra": 4.979394,
    "dec": 50.943826,
    "pmra": 502.53,
    "pmdec": -335.69,
    "rv": 0,
    "Asset Name": "ASTRID 001746",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Afro",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00003892,
    "y_1": 0.00003595,
    "z_1": -0.00001979,
    "Distance in Parsecs_1": 19.305,
    "HYG Star ID_1": 23086,
    "Hipparcos Catalogue_1": 23140,
    "Gliese Catalog_1": "GJ 1074"
  },
  {
    "Astrid #": "ASTRID 001747",
    "HYG Star ID": 118333,
    "Gliese Catalog": "NN 3311",
    "Distance in Parsecs": 19.305,
    "mag": 17.29,
    "Absolute Visual Magnitude": 15.862,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.022687,
    "y": 12.076275,
    "z": 14.51429,
    "vx": -0.00007549,
    "vy": 0.00007301,
    "vz": -0.00003978,
    "rarad": 1.24912321169084,
    "decrad": 0.8508497650081,
    "pmrarad": 0.00000490665381679861,
    "pmdecrad": -0.000003125879838,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118333,
    "lum": 0.0000393731353700849,
    "ra": 4.771299,
    "dec": 48.750101,
    "pmra": 1012.07,
    "pmdec": -644.76,
    "rv": 0,
    "Asset Name": "ASTRID 001747",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Round Brush",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00007549,
    "y_1": 0.00007301,
    "z_1": -0.00003978,
    "Distance in Parsecs_1": 19.305,
    "HYG Star ID_1": 118333,
    "Gliese Catalog_1": "NN 3311"
  },
  {
    "Astrid #": "ASTRID 001748",
    "HYG Star ID": 118890,
    "Gliese Catalog": "Gl 513",
    "Distance in Parsecs": 19.305,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.702,
    "Spectral Type": "M5",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.503163,
    "y": -7.192846,
    "z": 3.829398,
    "vx": -0.00004612,
    "vy": -0.00005089,
    "vz": -0.00009996,
    "rarad": 3.53149748138388,
    "decrad": 0.19966593774965,
    "pmrarad": 0.00000153084767772222,
    "pmdecrad": -0.000005713185007,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118890,
    "lum": 0.0045624697707685,
    "ra": 13.489327,
    "dec": 11.440016,
    "pmra": 315.76,
    "pmdec": -1178.43,
    "rv": 40,
    "Asset Name": "ASTRID 001748",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Heartbreaker",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004612,
    "y_1": -0.00005089,
    "z_1": -0.00009996,
    "Distance in Parsecs_1": 19.305,
    "HYG Star ID_1": 118890,
    "Gliese Catalog_1": "Gl 513"
  },
  {
    "Astrid #": "ASTRID 001749",
    "HYG Star ID": 46493,
    "Hipparcos Catalogue": 46626,
    "Henry Draper Catalogue": 82342,
    "Gliese Catalog": "GJ 1126A",
    "Distance in Parsecs": 19.3087,
    "mag": 8.31,
    "Absolute Visual Magnitude": 6.881,
    "Spectral Type": "K3/K4V",
    "Color Index": 0.985,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.996568,
    "y": 9.930506,
    "z": -10.261587,
    "vx": -0.00000869,
    "vy": 0.00001451,
    "vz": 0.00002505,
    "rarad": 2.48913729283982,
    "decrad": -0.560310036871225,
    "pmrarad": -3.23904019979166e-7,
    "pmdecrad": 0.00000153167186,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 46493,
    "base": "GJ 1126",
    "lum": 0.154028114807161,
    "ra": 9.507804,
    "dec": -32.1034,
    "pmra": -66.81,
    "pmdec": 315.93,
    "rv": 0,
    "Asset Name": "ASTRID 001749",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Hurt",
    "Hair": "Headband",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": -0.00000869,
    "y_1": 0.00001451,
    "z_1": 0.00002505,
    "Distance in Parsecs_1": 19.3087,
    "HYG Star ID_1": 46493,
    "Hipparcos Catalogue_1": 46626,
    "Henry Draper Catalogue_1": 82342,
    "Gliese Catalog_1": "GJ 1126A"
  },
  {
    "Astrid #": "ASTRID 001750",
    "HYG Star ID": 99392,
    "Hipparcos Catalogue": 99711,
    "Henry Draper Catalogue": 192263,
    "Distance in Parsecs": 19.3162,
    "mag": 7.79,
    "Absolute Visual Magnitude": 6.36,
    "Spectral Type": "K0",
    "Color Index": 0.938,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.659924,
    "y": -16.105781,
    "z": -0.29224,
    "vx": -0.00000474,
    "vy": -0.00000359,
    "vz": 0.00002456,
    "rarad": 5.29706301276874,
    "decrad": -0.0151298566168675,
    "pmrarad": -3.07226429368055e-7,
    "pmdecrad": 0.000001271472358,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 99392,
    "lum": 0.248885731828238,
    "var": "V1703",
    "var_min": 7.809,
    "var_max": 7.759,
    "ra": 20.23329,
    "dec": -0.866877,
    "pmra": -63.37,
    "pmdec": 262.26,
    "rv": 0,
    "Asset Name": "ASTRID 001750",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Audiophile",
    "Outfit": "Wizard",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00000474,
    "y_1": -0.00000359,
    "z_1": 0.00002456,
    "Distance in Parsecs_1": 19.3162,
    "HYG Star ID_1": 99392,
    "Hipparcos Catalogue_1": 99711,
    "Henry Draper Catalogue_1": 192263
  },
  {
    "Astrid #": "ASTRID 001751",
    "HYG Star ID": 118420,
    "Gliese Catalog": "Gl 231.1B",
    "Distance in Parsecs": 19.3199,
    "mag": 13.42,
    "Absolute Visual Magnitude": 11.99,
    "Spectral Type": "m+",
    "Color Index": 1.41,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.441189,
    "y": 19.189238,
    "z": 1.724017,
    "vx": 0.00002239,
    "vy": 0.00000949,
    "vz": 0.00001515,
    "rarad": 1.64575981088577,
    "decrad": 0.08935201611355,
    "pmrarad": -0.00000119230228459027,
    "pmdecrad": 7.45042271e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 29787,
    "base": "Gl 231.1",
    "lum": 0.0013931568029453,
    "ra": 6.286339,
    "dec": 5.119493,
    "pmra": -245.93,
    "pmdec": 153.68,
    "rv": 8.9,
    "Asset Name": "ASTRID 001751",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Undercut",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002239,
    "y_1": 0.00000949,
    "z_1": 0.00001515,
    "Distance in Parsecs_1": 19.3199,
    "HYG Star ID_1": 118420,
    "Gliese Catalog_1": "Gl 231.1B"
  },
  {
    "Astrid #": "ASTRID 001752",
    "HYG Star ID": 73230,
    "Hipparcos Catalogue": 73457,
    "Henry Draper Catalogue": 132683,
    "Gliese Catalog": "Gl 571.1",
    "Distance in Parsecs": 19.3424,
    "mag": 9.49,
    "Absolute Visual Magnitude": 8.057,
    "Spectral Type": "K7V",
    "Color Index": 1.372,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.377317,
    "y": -13.462048,
    "z": -3.735498,
    "vx": -0.00000504,
    "vy": -0.00000254,
    "vz": -0.00004695,
    "rarad": 3.93014777757304,
    "decrad": -0.194344659900936,
    "pmrarad": -9.21630806736111e-8,
    "pmdecrad": -0.000002328560107,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 73230,
    "lum": 0.0521434785414457,
    "ra": 15.012059,
    "dec": -11.135129,
    "pmra": -19.01,
    "pmdec": -480.3,
    "rv": 14,
    "Asset Name": "ASTRID 001752",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Charmer",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00000504,
    "y_1": -0.00000254,
    "z_1": -0.00004695,
    "Distance in Parsecs_1": 19.3424,
    "HYG Star ID_1": 73230,
    "Hipparcos Catalogue_1": 73457,
    "Henry Draper Catalogue_1": 132683,
    "Gliese Catalog_1": "Gl 571.1"
  },
  {
    "Astrid #": "ASTRID 001753",
    "HYG Star ID": 118284,
    "Gliese Catalog": "Gl 167.2",
    "Distance in Parsecs": 19.3424,
    "mag": 11.8,
    "Absolute Visual Magnitude": 10.367,
    "Spectral Type": "M0",
    "Color Index": 1.38,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.540019,
    "y": 15.659453,
    "z": -8.495108,
    "vx": -0.00002203,
    "vy": 0.00008337,
    "vz": 0.00000602,
    "rarad": 1.12204195266299,
    "decrad": -0.45463267677095,
    "pmrarad": 0.00000289671325995138,
    "pmdecrad": 0.000001768144581,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118284,
    "lum": 0.0062115502083244,
    "ra": 4.285885,
    "dec": -26.048534,
    "pmra": 597.49,
    "pmdec": 364.71,
    "rv": 55,
    "Asset Name": "ASTRID 001753",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Windy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00002203,
    "y_1": 0.00008337,
    "z_1": 0.00000602,
    "Distance in Parsecs_1": 19.3424,
    "HYG Star ID_1": 118284,
    "Gliese Catalog_1": "Gl 167.2"
  },
  {
    "Astrid #": "ASTRID 001754",
    "HYG Star ID": 36114,
    "Hipparcos Catalogue": 36215,
    "Gliese Catalog": "NN 3441",
    "Distance in Parsecs": 19.3498,
    "mag": 11.25,
    "Absolute Visual Magnitude": 9.817,
    "Spectral Type": "M2",
    "Color Index": 1.495,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.680691,
    "y": 16.644583,
    "z": 7.262298,
    "vx": 0.0000211,
    "vy": 0.00001392,
    "vz": -0.0000125,
    "rarad": 1.95248692153134,
    "decrad": 0.384738148225566,
    "pmrarad": -0.00000128005356077083,
    "pmdecrad": -6.96774221e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 36114,
    "lum": 0.0103086074006262,
    "var_min": 11.334,
    "var_max": 11.174,
    "ra": 7.457951,
    "dec": 22.043872,
    "pmra": -264.03,
    "pmdec": -143.72,
    "rv": 0,
    "Asset Name": "ASTRID 001754",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Firm",
    "Hair": "Braid",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.0000211,
    "y_1": 0.00001392,
    "z_1": -0.0000125,
    "Distance in Parsecs_1": 19.3498,
    "HYG Star ID_1": 36114,
    "Hipparcos Catalogue_1": 36215,
    "Gliese Catalog_1": "NN 3441"
  },
  {
    "Astrid #": "ASTRID 001755",
    "HYG Star ID": 119387,
    "Gliese Catalog": "Gl 816.1B",
    "Distance in Parsecs": 19.3611,
    "mag": 13,
    "Absolute Visual Magnitude": 11.565,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.640404,
    "y": -9.418172,
    "z": 13.900103,
    "vx": 0.00001872,
    "vy": 0.00003333,
    "vz": 0.00000961,
    "rarad": 5.50940543385096,
    "decrad": 0.8008380588949,
    "pmrarad": 0.00000190725701930555,
    "pmdecrad": 7.13097898e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 103527,
    "base": "Gl 816.1",
    "lum": 0.00206062991327,
    "ra": 21.044379,
    "dec": 45.884641,
    "pmra": 393.4,
    "pmdec": 147.09,
    "rv": 0,
    "Asset Name": "ASTRID 001755",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Undercut",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001872,
    "y_1": 0.00003333,
    "z_1": 0.00000961,
    "Distance in Parsecs_1": 19.3611,
    "HYG Star ID_1": 119387,
    "Gliese Catalog_1": "Gl 816.1B"
  },
  {
    "Astrid #": "ASTRID 001756",
    "HYG Star ID": 69263,
    "Hipparcos Catalogue": 69485,
    "Gliese Catalog": "Wo 9472",
    "Distance in Parsecs": 19.3648,
    "mag": 10.15,
    "Absolute Visual Magnitude": 8.715,
    "Spectral Type": "M0",
    "Color Index": 1.418,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.051261,
    "y": -10.57581,
    "z": -2.346214,
    "vx": 0.00000445,
    "vy": -0.00000958,
    "vz": 0.00001276,
    "rarad": 3.72418311275186,
    "decrad": -0.12145713853813,
    "pmrarad": 5.397430705625e-7,
    "pmdecrad": 6.63709928e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 69263,
    "lum": 0.0284446110744791,
    "var_min": 10.219,
    "var_max": 10.069,
    "ra": 14.225332,
    "dec": -6.958981,
    "pmra": 111.33,
    "pmdec": 136.9,
    "rv": 0,
    "Asset Name": "ASTRID 001756",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Ponytail",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000445,
    "y_1": -0.00000958,
    "z_1": 0.00001276,
    "Distance in Parsecs_1": 19.3648,
    "HYG Star ID_1": 69263,
    "Hipparcos Catalogue_1": 69485,
    "Gliese Catalog_1": "Wo 9472"
  },
  {
    "Astrid #": "ASTRID 001757",
    "HYG Star ID": 72086,
    "Hipparcos Catalogue": 72312,
    "Henry Draper Catalogue": 130307,
    "Gliese Catalog": "NN 3867",
    "Distance in Parsecs": 19.3723,
    "mag": 7.76,
    "Absolute Visual Magnitude": 6.324,
    "Spectral Type": "G8V",
    "Color Index": 0.893,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.421726,
    "y": -12.902251,
    "z": 0.913653,
    "vx": -0.00002499,
    "vy": 0.00001407,
    "vz": -0.00000702,
    "rarad": 3.87143847088035,
    "decrad": 0.0471801608475062,
    "pmrarad": -0.00000140149938775,
    "pmdecrad": -3.84360285e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 72086,
    "lum": 0.257276429534677,
    "var": "QY",
    "var_min": 7.781,
    "var_max": 7.731,
    "ra": 14.787806,
    "dec": 2.703224,
    "pmra": -289.08,
    "pmdec": -79.28,
    "rv": 8.7,
    "Asset Name": "ASTRID 001757",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Glad",
    "Hair": "Headband",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002499,
    "y_1": 0.00001407,
    "z_1": -0.00000702,
    "Distance in Parsecs_1": 19.3723,
    "HYG Star ID_1": 72086,
    "Hipparcos Catalogue_1": 72312,
    "Henry Draper Catalogue_1": 130307,
    "Gliese Catalog_1": "NN 3867"
  },
  {
    "Astrid #": "ASTRID 001758",
    "HYG Star ID": 119094,
    "Gliese Catalog": "Gl 629.3",
    "Distance in Parsecs": 19.3798,
    "mag": 12.38,
    "Absolute Visual Magnitude": 10.943,
    "Spectral Type": "M1 :",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.513409,
    "y": -11.804501,
    "z": -14.692159,
    "vx": 0.00001062,
    "vy": 0.00004076,
    "vz": -0.00003603,
    "rarad": 4.34719956737429,
    "decrad": -0.8604210312536,
    "pmrarad": -2.39352514090277e-7,
    "pmdecrad": -0.000002850365072,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119094,
    "lum": 0.00365426467750652,
    "ra": 16.605079,
    "dec": -49.298494,
    "pmra": -49.37,
    "pmdec": -587.93,
    "rv": 0,
    "Asset Name": "ASTRID 001758",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001062,
    "y_1": 0.00004076,
    "z_1": -0.00003603,
    "Distance in Parsecs_1": 19.3798,
    "HYG Star ID_1": 119094,
    "Gliese Catalog_1": "Gl 629.3"
  },
  {
    "Astrid #": "ASTRID 001759",
    "HYG Star ID": 85878,
    "Hipparcos Catalogue": 86141,
    "Henry Draper Catalogue": 160964,
    "Gliese Catalog": "Gl 689",
    "Distance in Parsecs": 19.3874,
    "mag": 8.55,
    "Absolute Visual Magnitude": 7.112,
    "Spectral Type": "K5",
    "Color Index": 1.089,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.624492,
    "y": -5.997678,
    "z": 18.425549,
    "vx": 0.00000903,
    "vy": 0.00000343,
    "vz": -0.0000243,
    "rarad": 4.60863195596273,
    "decrad": 1.25451401105052,
    "pmrarad": 4.45252884222222e-7,
    "pmdecrad": -1.76908512e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 85878,
    "lum": 0.124508786391605,
    "ra": 17.603677,
    "dec": 71.878358,
    "pmra": 91.84,
    "pmdec": -36.49,
    "rv": -23.9,
    "Asset Name": "ASTRID 001759",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "High Bun",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000903,
    "y_1": 0.00000343,
    "z_1": -0.0000243,
    "Distance in Parsecs_1": 19.3874,
    "HYG Star ID_1": 85878,
    "Hipparcos Catalogue_1": 86141,
    "Henry Draper Catalogue_1": 160964,
    "Gliese Catalog_1": "Gl 689"
  },
  {
    "Astrid #": "ASTRID 001760",
    "HYG Star ID": 100164,
    "Hipparcos Catalogue": 100490,
    "Gliese Catalog": "Gl 788.1",
    "Distance in Parsecs": 19.3874,
    "mag": 10.6,
    "Absolute Visual Magnitude": 9.162,
    "Spectral Type": "M",
    "Color Index": 1.455,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.943427,
    "y": -8.278841,
    "z": -16.491963,
    "vx": 0.0000169,
    "vy": 0.0000986,
    "vz": 0.00003594,
    "rarad": 5.33500236902524,
    "decrad": -1.01727668516068,
    "pmrarad": 0.00000367440288493055,
    "pmdecrad": -0.000002108018364,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 100164,
    "lum": 0.0188451674172155,
    "var_min": 10.653,
    "var_max": 10.543,
    "ra": 20.378208,
    "dec": -58.285661,
    "pmra": 757.9,
    "pmdec": -434.81,
    "rv": -66,
    "Asset Name": "ASTRID 001760",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bad Dreams",
    "Hair": "Lion Mane",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.0000169,
    "y_1": 0.0000986,
    "z_1": 0.00003594,
    "Distance in Parsecs_1": 19.3874,
    "HYG Star ID_1": 100164,
    "Hipparcos Catalogue_1": 100490,
    "Gliese Catalog_1": "Gl 788.1"
  },
  {
    "Astrid #": "ASTRID 001761",
    "HYG Star ID": 119307,
    "Gliese Catalog": "Gl 768.1B",
    "Distance in Parsecs": 19.3911,
    "mag": 13.1,
    "Absolute Visual Magnitude": 11.662,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.882013,
    "y": -16.877015,
    "z": 3.50551,
    "vx": 0.00001996,
    "vy": 0.00000927,
    "vz": -0.0000127,
    "rarad": 5.19683690999146,
    "decrad": 0.181779349319,
    "pmrarad": 0.00000113363982924305,
    "pmdecrad": -6.5449362e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 97365,
    "base": "Gl 768.1",
    "lum": 0.00188451674172155,
    "ra": 19.850455,
    "dec": 10.41519,
    "pmra": 233.83,
    "pmdec": -135,
    "rv": -1.2,
    "Asset Name": "ASTRID 001761",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Pompadour",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001996,
    "y_1": 0.00000927,
    "z_1": -0.0000127,
    "Distance in Parsecs_1": 19.3911,
    "HYG Star ID_1": 119307,
    "Gliese Catalog_1": "Gl 768.1B"
  },
  {
    "Astrid #": "ASTRID 001762",
    "HYG Star ID": 35398,
    "Hipparcos Catalogue": 35495,
    "Gliese Catalog": "Gl 270",
    "Distance in Parsecs": 19.3949,
    "mag": 10.06,
    "Absolute Visual Magnitude": 8.622,
    "Spectral Type": "M2:",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.541799,
    "y": 15.325462,
    "z": 10.514603,
    "vx": -0.00002415,
    "vy": -0.00005298,
    "vz": -0.00006548,
    "rarad": 1.91777058343834,
    "decrad": 0.572993201354354,
    "pmrarad": 0.00000210016438279861,
    "pmdecrad": -0.000001673673788,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 35398,
    "lum": 0.0309884604201205,
    "var_min": 10.103,
    "var_max": 9.993,
    "ra": 7.325344,
    "dec": 32.830092,
    "pmra": 433.19,
    "pmdec": -345.22,
    "rv": -68.9,
    "Asset Name": "ASTRID 001762",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Audiophile",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00002415,
    "y_1": -0.00005298,
    "z_1": -0.00006548,
    "Distance in Parsecs_1": 19.3949,
    "HYG Star ID_1": 35398,
    "Hipparcos Catalogue_1": 35495,
    "Gliese Catalog_1": "Gl 270"
  },
  {
    "Astrid #": "ASTRID 001763",
    "HYG Star ID": 42311,
    "Hipparcos Catalogue": 42430,
    "Henry Draper Catalogue": 73752,
    "Harvard Revised Catalogue": 3430,
    "Gliese Catalog": "Gl 314A",
    "Distance in Parsecs": 19.3986,
    "mag": 5.05,
    "Absolute Visual Magnitude": 3.611,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.72,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.454706,
    "y": 13.756704,
    "z": -7.474291,
    "vx": -0.00001925,
    "vy": 0.00006255,
    "vz": 0.000018,
    "rarad": 2.26514017908967,
    "decrad": -0.39552433301876,
    "pmrarad": -0.00000130056117945833,
    "pmdecrad": 0.000002055997856,
    "con": "Pyx",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pyxis",
    "FULL CONSTELLATION NAME": "Pyxis",
    "comp": 1,
    "comp_primary": 42311,
    "base": "Gl 314",
    "lum": 3.13040119009623,
    "ra": 8.652198,
    "dec": -22.661875,
    "pmra": -268.26,
    "pmdec": 424.08,
    "rv": 47.7,
    "Asset Name": "ASTRID 001763",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Lion Mane",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pyxis",
    "x_1": -0.00001925,
    "y_1": 0.00006255,
    "z_1": 0.000018,
    "Distance in Parsecs_1": 19.3986,
    "HYG Star ID_1": 42311,
    "Hipparcos Catalogue_1": 42430,
    "Henry Draper Catalogue_1": 73752,
    "Harvard Revised Catalogue_1": 3430,
    "Gliese Catalog_1": "Gl 314A"
  },
  {
    "Astrid #": "ASTRID 001764",
    "HYG Star ID": 114761,
    "Hipparcos Catalogue": 115123,
    "Henry Draper Catalogue": 219764,
    "Gliese Catalog": "GJ 1283",
    "Distance in Parsecs": 19.3986,
    "mag": 8.93,
    "Absolute Visual Magnitude": 7.491,
    "Spectral Type": "K7V",
    "Color Index": 1.154,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.394553,
    "y": -1.695005,
    "z": -16.887125,
    "vx": -0.00000503,
    "vy": -0.00000818,
    "vz": -0.00000198,
    "rarad": 6.10468569628943,
    "decrad": -1.05628480553668,
    "pmrarad": -4.60766922e-7,
    "pmdecrad": -2.06918478e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 114761,
    "lum": 0.0878213279898006,
    "ra": 23.318182,
    "dec": -60.520661,
    "pmra": -95.04,
    "pmdec": -42.68,
    "rv": 0,
    "Asset Name": "ASTRID 001764",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Audiohead",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000503,
    "y_1": -0.00000818,
    "z_1": -0.00000198,
    "Distance in Parsecs_1": 19.3986,
    "HYG Star ID_1": 114761,
    "Hipparcos Catalogue_1": 115123,
    "Henry Draper Catalogue_1": 219764,
    "Gliese Catalog_1": "GJ 1283"
  },
  {
    "Astrid #": "ASTRID 001765",
    "HYG Star ID": 66941,
    "Hipparcos Catalogue": 67153,
    "Henry Draper Catalogue": 119756,
    "Harvard Revised Catalogue": 5168,
    "Gliese Catalog": "Gl 525.1",
    "Distance in Parsecs": 19.4024,
    "mag": 4.23,
    "Absolute Visual Magnitude": 2.791,
    "Spectral Type": "F3V",
    "Color Index": 0.39,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.565089,
    "y": -7.237099,
    "z": -10.579613,
    "vx": 0.00000397,
    "vy": 0.00005048,
    "vz": 5.2e-7,
    "rarad": 3.60274479477014,
    "decrad": -0.576721733706365,
    "pmrarad": -0.00000223911198364583,
    "pmdecrad": -7.08652156e-7,
    "flam": 1,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 66941,
    "lum": 6.66192900223455,
    "bf": "1    Cen",
    "ra": 13.761471,
    "dec": -33.043721,
    "pmra": -461.85,
    "pmdec": -146.17,
    "rv": -21.6,
    "Asset Name": "ASTRID 001765",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Headband",
    "Outfit": "Teen",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000397,
    "y_1": 0.00005048,
    "z_1": 5.2e-7,
    "Distance in Parsecs_1": 19.4024,
    "HYG Star ID_1": 66941,
    "Hipparcos Catalogue_1": 67153,
    "Henry Draper Catalogue_1": 119756,
    "Harvard Revised Catalogue_1": 5168,
    "Gliese Catalog_1": "Gl 525.1"
  },
  {
    "Astrid #": "ASTRID 001766",
    "HYG Star ID": 38481,
    "Hipparcos Catalogue": 38594,
    "Distance in Parsecs": 19.4099,
    "mag": 9.75,
    "Absolute Visual Magnitude": 8.31,
    "Spectral Type": "M",
    "Color Index": 1.385,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.385238,
    "y": 15.414581,
    "z": -8.295946,
    "vx": 0.00002093,
    "vy": 0.00002055,
    "vz": 0.00001704,
    "rarad": 2.06900796054933,
    "decrad": -0.441623710731085,
    "pmrarad": -0.0000014534229329375,
    "pmdecrad": 9.70887876e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 38481,
    "lum": 0.0413047501990161,
    "ra": 7.903028,
    "dec": -25.303175,
    "pmra": -299.79,
    "pmdec": 200.26,
    "rv": 0,
    "Asset Name": "ASTRID 001766",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Scared",
    "Hair": "Afro",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002093,
    "y_1": 0.00002055,
    "z_1": 0.00001704,
    "Distance in Parsecs_1": 19.4099,
    "HYG Star ID_1": 38481,
    "Hipparcos Catalogue_1": 38594
  },
  {
    "Astrid #": "ASTRID 001767",
    "HYG Star ID": 8777,
    "Hipparcos Catalogue": 8796,
    "Henry Draper Catalogue": 11443,
    "Harvard Revised Catalogue": 544,
    "Gliese Catalog": "Gl 78.1",
    "Distance in Parsecs": 19.4175,
    "mag": 3.42,
    "Absolute Visual Magnitude": 1.979,
    "Spectral Type": "F6IV",
    "Color Index": 0.488,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.872602,
    "y": 7.99818,
    "z": 9.584806,
    "vx": -0.00000366,
    "vy": -6.9e-7,
    "vz": -0.00002731,
    "rarad": 0.493412345902788,
    "decrad": 0.516247958590462,
    "pmrarad": 5.82746044027777e-8,
    "pmdecrad": -0.00000113296109,
    "bayer": "Alp",
    "flam": 2,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 8777,
    "lum": 14.0734313831834,
    "var": "Alp",
    "var_min": 3.429,
    "var_max": 3.409,
    "bf": "2Alp Tri",
    "ra": 1.884696,
    "dec": 29.578829,
    "pmra": 12.02,
    "pmdec": -233.69,
    "rv": -16.2,
    "Asset Name": "ASTRID 001767",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Greasy",
    "Outfit": "Astro",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.00000366,
    "y_1": -6.9e-7,
    "z_1": -0.00002731,
    "Distance in Parsecs_1": 19.4175,
    "HYG Star ID_1": 8777,
    "Hipparcos Catalogue_1": 8796,
    "Henry Draper Catalogue_1": 11443,
    "Harvard Revised Catalogue_1": 544,
    "Gliese Catalog_1": "Gl 78.1"
  },
  {
    "Astrid #": "ASTRID 001768",
    "HYG Star ID": 118251,
    "Henry Draper Catalogue": 23232,
    "Gliese Catalog": "Wo 9126",
    "Distance in Parsecs": 19.4175,
    "mag": 8.85,
    "Absolute Visual Magnitude": 7.409,
    "Spectral Type": "K2",
    "Color Index": 1.71,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.829598,
    "y": 14.618526,
    "z": 8.16829,
    "vx": 0.00000262,
    "vy": 9.3e-7,
    "vz": 5e-8,
    "rarad": 0.978830663215542,
    "decrad": 0.434177266740749,
    "pmrarad": -8.53756891458333e-8,
    "pmdecrad": -4.5965185e-8,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118251,
    "lum": 0.094710907947016,
    "ra": 3.738858,
    "dec": 24.876525,
    "pmra": -17.61,
    "pmdec": -9.48,
    "rv": 2,
    "Asset Name": "ASTRID 001768",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Sweetheart",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000262,
    "y_1": 9.3e-7,
    "z_1": 5e-8,
    "Distance in Parsecs_1": 19.4175,
    "HYG Star ID_1": 118251,
    "Henry Draper Catalogue_1": 23232,
    "Gliese Catalog_1": "Wo 9126"
  },
  {
    "Astrid #": "ASTRID 001769",
    "HYG Star ID": 27253,
    "Hipparcos Catalogue": 27321,
    "Henry Draper Catalogue": 39060,
    "Harvard Revised Catalogue": 2020,
    "Gliese Catalog": "Gl 219",
    "Distance in Parsecs": 19.4401,
    "mag": 3.85,
    "Absolute Visual Magnitude": 2.407,
    "Spectral Type": "A3V",
    "Color Index": 0.171,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 0.677437,
    "y": 12.19782,
    "z": -15.122127,
    "vx": 6.1e-7,
    "vy": 0.00001886,
    "vz": -0.00001106,
    "rarad": 1.51531565525856,
    "decrad": -0.891278807158695,
    "pmrarad": 2.25438361458333e-8,
    "pmdecrad": 3.97353292e-7,
    "bayer": "Bet",
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 27253,
    "lum": 9.488553267278,
    "bf": "Bet Pic",
    "ra": 5.788079,
    "dec": -51.066514,
    "pmra": 4.65,
    "pmdec": 81.96,
    "rv": 20,
    "Asset Name": "ASTRID 001769",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Medium Bob",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 6.1e-7,
    "y_1": 0.00001886,
    "z_1": -0.00001106,
    "Distance in Parsecs_1": 19.4401,
    "HYG Star ID_1": 27253,
    "Hipparcos Catalogue_1": 27321,
    "Henry Draper Catalogue_1": 39060,
    "Harvard Revised Catalogue_1": 2020,
    "Gliese Catalog_1": "Gl 219"
  },
  {
    "Astrid #": "ASTRID 001770",
    "HYG Star ID": 98194,
    "Hipparcos Catalogue": 98505,
    "Henry Draper Catalogue": 189733,
    "Gliese Catalog": "NN 4130",
    "Distance in Parsecs": 19.4515,
    "mag": 7.67,
    "Absolute Visual Magnitude": 6.225,
    "Spectral Type": "G5",
    "Color Index": 0.932,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.021016,
    "y": -15.510779,
    "z": 7.50985,
    "vx": 0.00000439,
    "vy": -0.00000801,
    "vz": -0.00002182,
    "rarad": 5.23916667637121,
    "decrad": 0.39637915433957,
    "pmrarad": -1.20718606458333e-8,
    "pmdecrad": -0.000001215961192,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 98194,
    "lum": 0.281838293126445,
    "var": "V452",
    "var_min": 7.703,
    "var_max": 7.643,
    "ra": 20.012143,
    "dec": 22.710853,
    "pmra": -2.49,
    "pmdec": -250.81,
    "rv": 0,
    "Asset Name": "ASTRID 001770",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Afro",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00000439,
    "y_1": -0.00000801,
    "z_1": -0.00002182,
    "Distance in Parsecs_1": 19.4515,
    "HYG Star ID_1": 98194,
    "Hipparcos Catalogue_1": 98505,
    "Henry Draper Catalogue_1": 189733,
    "Gliese Catalog_1": "NN 4130"
  },
  {
    "Astrid #": "ASTRID 001771",
    "HYG Star ID": 103527,
    "Hipparcos Catalogue": 103859,
    "Henry Draper Catalogue": 200560,
    "Gliese Catalog": "Gl 816.1A",
    "Distance in Parsecs": 19.4704,
    "mag": 7.69,
    "Absolute Visual Magnitude": 6.243,
    "Spectral Type": "K2",
    "Color Index": 0.97,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.695032,
    "y": -9.470962,
    "z": 13.978517,
    "vx": 0.00001274,
    "vy": 0.00004064,
    "vz": -6.7e-7,
    "rarad": 5.50947017704757,
    "decrad": 0.800840349932045,
    "pmrarad": 0.00000195040543687499,
    "pmdecrad": 6.87077948e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 103527,
    "base": "Gl 816.1",
    "lum": 0.27720432375777,
    "var": "V2436",
    "var_min": 7.713,
    "var_max": 7.663,
    "ra": 21.044626,
    "dec": 45.884772,
    "pmra": 402.3,
    "pmdec": 141.72,
    "rv": -13.6,
    "Asset Name": "ASTRID 001771",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Owie",
    "Hair": "Braided Ponytail",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001274,
    "y_1": 0.00004064,
    "z_1": -6.7e-7,
    "Distance in Parsecs_1": 19.4704,
    "HYG Star ID_1": 103527,
    "Hipparcos Catalogue_1": 103859,
    "Henry Draper Catalogue_1": 200560,
    "Gliese Catalog_1": "Gl 816.1A"
  },
  {
    "Astrid #": "ASTRID 001772",
    "HYG Star ID": 67406,
    "Hipparcos Catalogue": 67620,
    "Henry Draper Catalogue": 120690,
    "Harvard Revised Catalogue": 5209,
    "Gliese Catalog": "Gl 530",
    "Distance in Parsecs": 19.4742,
    "mag": 6.43,
    "Absolute Visual Magnitude": 4.983,
    "Spectral Type": "G5V",
    "Color Index": 0.703,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.684097,
    "y": -8.28143,
    "z": -8.041904,
    "vx": -0.0000189,
    "vy": 0.00005204,
    "vz": -0.00002194,
    "rarad": 3.62740222725399,
    "decrad": -0.425692219678516,
    "pmrarad": -0.00000281642811445138,
    "pmdecrad": -0.000001187163259,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 67406,
    "lum": 0.88470809287053,
    "ra": 13.855656,
    "dec": -24.390368,
    "pmra": -580.93,
    "pmdec": -244.87,
    "rv": 2.1,
    "Asset Name": "ASTRID 001772",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Neko Ninja",
    "Hair": "Grecian",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.0000189,
    "y_1": 0.00005204,
    "z_1": -0.00002194,
    "Distance in Parsecs_1": 19.4742,
    "HYG Star ID_1": 67406,
    "Hipparcos Catalogue_1": 67620,
    "Henry Draper Catalogue_1": 120690,
    "Harvard Revised Catalogue_1": 5209,
    "Gliese Catalog_1": "Gl 530"
  },
  {
    "Astrid #": "ASTRID 001773",
    "HYG Star ID": 82442,
    "Hipparcos Catalogue": 82694,
    "Gliese Catalog": "Gl 642",
    "Distance in Parsecs": 19.4742,
    "mag": 10.75,
    "Absolute Visual Magnitude": 9.303,
    "Spectral Type": "M1",
    "Color Index": 1.405,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.395659,
    "y": -18.274138,
    "z": 4.02042,
    "vx": -0.00002852,
    "vy": 0.00008506,
    "vz": 0.00001496,
    "rarad": 4.42528514365921,
    "decrad": 0.207950413644538,
    "pmrarad": -0.00000264141037577083,
    "pmdecrad": 0.000001530702233,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82442,
    "lum": 0.0165500762876998,
    "var_min": 10.809,
    "var_max": 10.699,
    "ra": 16.903344,
    "dec": 11.914681,
    "pmra": -544.83,
    "pmdec": 315.73,
    "rv": -67.3,
    "Asset Name": "ASTRID 001773",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Curly",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002852,
    "y_1": 0.00008506,
    "z_1": 0.00001496,
    "Distance in Parsecs_1": 19.4742,
    "HYG Star ID_1": 82442,
    "Hipparcos Catalogue_1": 82694,
    "Gliese Catalog_1": "Gl 642"
  },
  {
    "Astrid #": "ASTRID 001774",
    "HYG Star ID": 15732,
    "Hipparcos Catalogue": 15771,
    "Gliese Catalog": "Gl 140.1B",
    "Distance in Parsecs": 19.4818,
    "mag": 10.24,
    "Absolute Visual Magnitude": 8.792,
    "Spectral Type": "K0",
    "Color Index": 1.386,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.912869,
    "y": 9.707732,
    "z": -14.922561,
    "vx": -0.0000101,
    "vy": 0.00002248,
    "vz": 0.00002529,
    "rarad": 0.886904193895787,
    "decrad": -0.872562247372732,
    "pmrarad": 0.00000113073094715972,
    "pmdecrad": 0.000001268757402,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 2,
    "comp_primary": 15735,
    "base": "Gl 140.1",
    "lum": 0.0264972009887896,
    "ra": 3.387724,
    "dec": -49.994134,
    "pmra": 233.23,
    "pmdec": 261.7,
    "rv": -12,
    "Asset Name": "ASTRID 001774",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Ponytail",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": -0.0000101,
    "y_1": 0.00002248,
    "z_1": 0.00002529,
    "Distance in Parsecs_1": 19.4818,
    "HYG Star ID_1": 15732,
    "Hipparcos Catalogue_1": 15771,
    "Gliese Catalog_1": "Gl 140.1B"
  },
  {
    "Astrid #": "ASTRID 001775",
    "HYG Star ID": 34796,
    "Hipparcos Catalogue": 34890,
    "Henry Draper Catalogue": 56533,
    "Gliese Catalog": "Gl 268.2",
    "Distance in Parsecs": 19.4818,
    "mag": 9.08,
    "Absolute Visual Magnitude": 7.632,
    "Spectral Type": "K5V",
    "Color Index": 1.255,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.741271,
    "y": 8.298775,
    "z": -17.411184,
    "vx": 0.00001147,
    "vy": 0.00004543,
    "vz": 0.00004273,
    "rarad": 1.88984835790155,
    "decrad": -1.10557785618191,
    "pmrarad": -0.00000129052553627083,
    "pmdecrad": 0.000002798150638,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 34796,
    "lum": 0.0771258564989213,
    "ra": 7.218689,
    "dec": -63.344945,
    "pmra": -266.19,
    "pmdec": 577.16,
    "rv": -20,
    "Asset Name": "ASTRID 001775",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Prince",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00001147,
    "y_1": 0.00004543,
    "z_1": 0.00004273,
    "Distance in Parsecs_1": 19.4818,
    "HYG Star ID_1": 34796,
    "Hipparcos Catalogue_1": 34890,
    "Henry Draper Catalogue_1": 56533,
    "Gliese Catalog_1": "Gl 268.2"
  },
  {
    "Astrid #": "ASTRID 001776",
    "HYG Star ID": 39644,
    "Hipparcos Catalogue": 39757,
    "Henry Draper Catalogue": 67523,
    "Harvard Revised Catalogue": 3185,
    "Distance in Parsecs": 19.4818,
    "mag": 2.83,
    "Absolute Visual Magnitude": 1.382,
    "Spectral Type": "F2mF5IIp",
    "Color Index": 0.458,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.379037,
    "y": 15.076242,
    "z": -8.018549,
    "vx": -0.00001692,
    "vy": 0.00004209,
    "vz": -0.00001537,
    "rarad": 2.12731299373849,
    "decrad": -0.424190475504881,
    "pmrarad": -4.03801314534722e-7,
    "pmdecrad": 2.24856585e-7,
    "bayer": "Rho",
    "flam": 15,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 39644,
    "lum": 24.3893373027891,
    "var": "Rho",
    "var_min": 2.875,
    "var_max": 2.775,
    "bf": "15Rho Pup",
    "ra": 8.125737,
    "dec": -24.304324,
    "pmra": -83.29,
    "pmdec": 46.38,
    "rv": 46,
    "Asset Name": "ASTRID 001776",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Sweet Nap",
    "Hair": "Prince",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001692,
    "y_1": 0.00004209,
    "z_1": -0.00001537,
    "Distance in Parsecs_1": 19.4818,
    "HYG Star ID_1": 39644,
    "Hipparcos Catalogue_1": 39757,
    "Henry Draper Catalogue_1": 67523,
    "Harvard Revised Catalogue_1": 3185
  },
  {
    "Astrid #": "ASTRID 001777",
    "HYG Star ID": 15805,
    "Hipparcos Catalogue": 15844,
    "Gliese Catalog": "Gl 140A",
    "Distance in Parsecs": 19.4894,
    "mag": 10.38,
    "Absolute Visual Magnitude": 8.931,
    "Spectral Type": "M0...",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.216811,
    "y": 13.864962,
    "z": 7.860195,
    "vx": -0.00001286,
    "vy": 0.00001673,
    "vz": -0.00001117,
    "rarad": 0.890588290035575,
    "decrad": 0.415127027453978,
    "pmrarad": 0.00000105291835143055,
    "pmdecrad": -6.26233831e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 15805,
    "base": "Gl 140",
    "lum": 0.0233130985741802,
    "var_min": 10.431,
    "var_max": 10.311,
    "ra": 3.401797,
    "dec": 23.785027,
    "pmra": 217.18,
    "pmdec": -129.17,
    "rv": 0,
    "Asset Name": "ASTRID 001777",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Side Part",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00001286,
    "y_1": 0.00001673,
    "z_1": -0.00001117,
    "Distance in Parsecs_1": 19.4894,
    "HYG Star ID_1": 15805,
    "Hipparcos Catalogue_1": 15844,
    "Gliese Catalog_1": "Gl 140A"
  },
  {
    "Astrid #": "ASTRID 001778",
    "HYG Star ID": 119175,
    "Gliese Catalog": "Gl 690.1",
    "Distance in Parsecs": 19.4932,
    "mag": 13.05,
    "Absolute Visual Magnitude": 11.601,
    "Spectral Type": "M2.5",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.421954,
    "y": -18.622491,
    "z": -5.582629,
    "vx": -0.00001079,
    "vy": 0.00001811,
    "vz": -0.00005769,
    "rarad": 4.63618140656916,
    "decrad": -0.29045539782085,
    "pmrarad": -6.22888616777777e-7,
    "pmdecrad": -0.000003089116417,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119175,
    "lum": 0.00199342545649809,
    "ra": 17.708909,
    "dec": -16.641868,
    "pmra": -128.48,
    "pmdec": -637.18,
    "rv": 0,
    "Asset Name": "ASTRID 001778",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Shinobi",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001079,
    "y_1": 0.00001811,
    "z_1": -0.00005769,
    "Distance in Parsecs_1": 19.4932,
    "HYG Star ID_1": 119175,
    "Gliese Catalog_1": "Gl 690.1"
  },
  {
    "Astrid #": "ASTRID 001779",
    "HYG Star ID": 119529,
    "Gliese Catalog": "Gl 874",
    "Distance in Parsecs": 19.4932,
    "mag": 11.92,
    "Absolute Visual Magnitude": 10.471,
    "Spectral Type": "M3",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.853092,
    "y": -4.804677,
    "z": -11.674143,
    "vx": 0.00000766,
    "vy": 0.00007247,
    "vz": -0.00002011,
    "rarad": 5.97028344635353,
    "decrad": -0.64210535228485,
    "pmrarad": 0.00000365830707073611,
    "pmdecrad": -0.000001288285697,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 119529,
    "lum": 0.00564416888175142,
    "ra": 22.804803,
    "dec": -36.789927,
    "pmra": 754.58,
    "pmdec": -265.73,
    "rv": 0,
    "Asset Name": "ASTRID 001779",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Tiny Crown",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00000766,
    "y_1": 0.00007247,
    "z_1": -0.00002011,
    "Distance in Parsecs_1": 19.4932,
    "HYG Star ID_1": 119529,
    "Gliese Catalog_1": "Gl 874"
  },
  {
    "Astrid #": "ASTRID 001780",
    "HYG Star ID": 118073,
    "Gliese Catalog": "Gl 59B",
    "Distance in Parsecs": 19.5046,
    "mag": 12.77,
    "Absolute Visual Magnitude": 11.319,
    "Spectral Type": "m",
    "Color Index": 1.3,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.341675,
    "y": 7.018069,
    "z": -8.007857,
    "vx": -0.0000159,
    "vy": 0.00002225,
    "vz": -0.00001295,
    "rarad": 0.405634250628652,
    "decrad": -0.4230708387796,
    "pmrarad": 0.00000136979257304166,
    "pmdecrad": -7.28335592e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 7221,
    "base": "Gl  59",
    "lum": 0.00258463963576983,
    "ra": 1.549409,
    "dec": -24.240173,
    "pmra": 282.54,
    "pmdec": -150.23,
    "rv": 0,
    "Asset Name": "ASTRID 001780",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Unkempt",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000159,
    "y_1": 0.00002225,
    "z_1": -0.00001295,
    "Distance in Parsecs_1": 19.5046,
    "HYG Star ID_1": 118073,
    "Gliese Catalog_1": "Gl 59B"
  },
  {
    "Astrid #": "ASTRID 001781",
    "HYG Star ID": 44248,
    "Hipparcos Catalogue": 44376,
    "Gliese Catalog": "NN 3528",
    "Distance in Parsecs": 19.5122,
    "mag": 11.74,
    "Absolute Visual Magnitude": 10.288,
    "Spectral Type": "K:",
    "Color Index": 1.427,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.784891,
    "y": 13.507252,
    "z": 2.873473,
    "vx": -0.0000428,
    "vy": -0.00003966,
    "vz": -0.00001886,
    "rarad": 2.36636674809319,
    "decrad": 0.147803026431953,
    "pmrarad": 0.00000298732493684722,
    "pmdecrad": -9.77093491e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 44248,
    "lum": 0.00668036204679246,
    "var_min": 11.864,
    "var_max": 11.644,
    "ra": 9.038855,
    "dec": 8.46849,
    "pmra": 616.18,
    "pmdec": -201.54,
    "rv": 0,
    "Asset Name": "ASTRID 001781",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Lion Mane",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.0000428,
    "y_1": -0.00003966,
    "z_1": -0.00001886,
    "Distance in Parsecs_1": 19.5122,
    "HYG Star ID_1": 44248,
    "Hipparcos Catalogue_1": 44376,
    "Gliese Catalog_1": "NN 3528"
  },
  {
    "Astrid #": "ASTRID 001782",
    "HYG Star ID": 84783,
    "Hipparcos Catalogue": 85042,
    "Henry Draper Catalogue": 157347,
    "Harvard Revised Catalogue": 6465,
    "Distance in Parsecs": 19.5236,
    "mag": 6.28,
    "Absolute Visual Magnitude": 4.827,
    "Spectral Type": "G5IV",
    "Color Index": 0.68,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.147694,
    "y": -19.250686,
    "z": -0.813525,
    "vx": 0.0000105,
    "vy": 0.00003495,
    "vz": -0.00000868,
    "rarad": 4.55031249851458,
    "decrad": -0.04168153197553,
    "pmrarad": 2.42115952069444e-7,
    "pmdecrad": -5.21368632e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84783,
    "lum": 1.02140975198984,
    "ra": 17.380913,
    "dec": -2.388176,
    "pmra": 49.94,
    "pmdec": -107.54,
    "rv": -35,
    "Asset Name": "ASTRID 001782",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Windy",
    "Outfit": "Viking",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.0000105,
    "y_1": 0.00003495,
    "z_1": -0.00000868,
    "Distance in Parsecs_1": 19.5236,
    "HYG Star ID_1": 84783,
    "Hipparcos Catalogue_1": 85042,
    "Henry Draper Catalogue_1": 157347,
    "Harvard Revised Catalogue_1": 6465
  },
  {
    "Astrid #": "ASTRID 001783",
    "HYG Star ID": 100599,
    "Hipparcos Catalogue": 100925,
    "Henry Draper Catalogue": 194640,
    "Gliese Catalog": "Gl 790",
    "Distance in Parsecs": 19.5236,
    "mag": 6.61,
    "Absolute Visual Magnitude": 5.157,
    "Spectral Type": "G6/G8V",
    "Color Index": 0.724,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.069952,
    "y": -13.395188,
    "z": -10.016766,
    "vx": -0.00001687,
    "vy": 0.0000201,
    "vz": -0.00004184,
    "rarad": 5.35701499577258,
    "decrad": -0.538745536926895,
    "pmrarad": -7.21402756666666e-8,
    "pmdecrad": -0.000002528448788,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 100599,
    "lum": 0.753702576688425,
    "ra": 20.46229,
    "dec": -30.867845,
    "pmra": -14.88,
    "pmdec": -521.53,
    "rv": -1,
    "Asset Name": "ASTRID 001783",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Spiky",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00001687,
    "y_1": 0.0000201,
    "z_1": -0.00004184,
    "Distance in Parsecs_1": 19.5236,
    "HYG Star ID_1": 100599,
    "Hipparcos Catalogue_1": 100925,
    "Henry Draper Catalogue_1": 194640,
    "Gliese Catalog_1": "Gl 790"
  },
  {
    "Astrid #": "ASTRID 001784",
    "HYG Star ID": 3254,
    "Hipparcos Catalogue": 3261,
    "Gliese Catalog": "GJ 1016",
    "Distance in Parsecs": 19.5274,
    "mag": 10.58,
    "Absolute Visual Magnitude": 9.127,
    "Color Index": 1.432,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 15.994015,
    "y": 2.928807,
    "z": -10.813553,
    "vx": -0.00000692,
    "vy": -0.00003477,
    "vz": -0.00001966,
    "rarad": 0.181115286908671,
    "decrad": -0.586876736846541,
    "pmrarad": -0.00000168778186611805,
    "pmdecrad": -0.000001209125319,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 3254,
    "lum": 0.0194625615936123,
    "ra": 0.691809,
    "dec": -33.62556,
    "pmra": -348.13,
    "pmdec": -249.4,
    "rv": 0,
    "Asset Name": "ASTRID 001784",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Sweetheart",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00000692,
    "y_1": -0.00003477,
    "z_1": -0.00001966,
    "Distance in Parsecs_1": 19.5274,
    "HYG Star ID_1": 3254,
    "Hipparcos Catalogue_1": 3261,
    "Gliese Catalog_1": "GJ 1016"
  },
  {
    "Astrid #": "ASTRID 001785",
    "HYG Star ID": 119086,
    "Gliese Catalog": "Gl 627B",
    "Distance in Parsecs": 19.5312,
    "mag": 7.85,
    "Absolute Visual Magnitude": 6.396,
    "Spectral Type": "K3 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.175058,
    "y": -17.085835,
    "z": 6.169325,
    "vx": -0.00002541,
    "vy": 0.00002296,
    "vz": 0.00003405,
    "rarad": 4.31481489024168,
    "decrad": 0.32137370791965,
    "pmrarad": -0.00000165447516626388,
    "pmdecrad": 0.000001837458393,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 80481,
    "base": "Gl 627",
    "lum": 0.240768684561244,
    "ra": 16.481379,
    "dec": 18.413357,
    "pmra": -341.26,
    "pmdec": 379,
    "rv": 0,
    "Asset Name": "ASTRID 001785",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Goddess",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00002541,
    "y_1": 0.00002296,
    "z_1": 0.00003405,
    "Distance in Parsecs_1": 19.5312,
    "HYG Star ID_1": 119086,
    "Gliese Catalog_1": "Gl 627B"
  },
  {
    "Astrid #": "ASTRID 001786",
    "HYG Star ID": 82144,
    "Hipparcos Catalogue": 82396,
    "Henry Draper Catalogue": 151680,
    "Harvard Revised Catalogue": 6241,
    "Distance in Parsecs": 19.5351,
    "mag": 2.29,
    "Absolute Visual Magnitude": 0.836,
    "Spectral Type": "K2IIIb",
    "Color Index": 1.144,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.842163,
    "y": -15.39568,
    "z": -11.006616,
    "vx": -0.00005042,
    "vy": 0.00003282,
    "vz": -0.00001829,
    "rarad": 4.407675434439,
    "decrad": -0.598529803311685,
    "pmrarad": -0.00000296623554174305,
    "pmdecrad": -0.000001240492764,
    "bayer": "Eps",
    "flam": 26,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 82144,
    "lum": 40.3273792976113,
    "bf": "26Eps Sco",
    "ra": 16.83608,
    "dec": -34.293232,
    "pmra": -611.83,
    "pmdec": -255.87,
    "rv": -3,
    "Asset Name": "ASTRID 001786",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Ram Horns",
    "Outfit": "Toga",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00005042,
    "y_1": 0.00003282,
    "z_1": -0.00001829,
    "Distance in Parsecs_1": 19.5351,
    "HYG Star ID_1": 82144,
    "Hipparcos Catalogue_1": 82396,
    "Henry Draper Catalogue_1": 151680,
    "Harvard Revised Catalogue_1": 6241
  },
  {
    "Astrid #": "ASTRID 001787",
    "HYG Star ID": 111334,
    "Hipparcos Catalogue": 111685,
    "Gliese Catalog": "NN 4287",
    "Distance in Parsecs": 19.5427,
    "mag": 9.41,
    "Absolute Visual Magnitude": 7.955,
    "Spectral Type": "M2",
    "Color Index": 1.367,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.137206,
    "y": -5.32098,
    "z": 12.399343,
    "vx": 0.00001989,
    "vy": -0.0000053,
    "vz": -0.00002495,
    "rarad": 5.9232039179947,
    "decrad": 0.687328301128683,
    "pmrarad": 1.04913680472222e-7,
    "pmdecrad": -0.000001652099579,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 111334,
    "lum": 0.0572796030985828,
    "var_min": 9.471,
    "var_max": 9.351,
    "ra": 22.624972,
    "dec": 39.381011,
    "pmra": 21.64,
    "pmdec": -340.77,
    "rv": 0,
    "Asset Name": "ASTRID 001787",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Oh Snap",
    "Hair": "High Fade",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": 0.00001989,
    "y_1": -0.0000053,
    "z_1": -0.00002495,
    "Distance in Parsecs_1": 19.5427,
    "HYG Star ID_1": 111334,
    "Hipparcos Catalogue_1": 111685,
    "Gliese Catalog_1": "NN 4287"
  },
  {
    "Astrid #": "ASTRID 001788",
    "HYG Star ID": 11538,
    "Hipparcos Catalogue": 11565,
    "Henry Draper Catalogue": 15468,
    "Gliese Catalog": "Gl 100A",
    "Distance in Parsecs": 19.5465,
    "mag": 8.78,
    "Absolute Visual Magnitude": 7.325,
    "Spectral Type": "K4V",
    "Color Index": 1.12,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.621447,
    "y": 11.121247,
    "z": -6.678687,
    "vx": -0.00001275,
    "vy": 0.00006326,
    "vz": 0.00000858,
    "rarad": 0.650256539254823,
    "decrad": -0.348702208901482,
    "pmrarad": 0.00000297122912265277,
    "pmdecrad": 9.04468402e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 11538,
    "base": "Gl 100",
    "lum": 0.102329299228075,
    "ra": 2.483797,
    "dec": -19.979165,
    "pmra": 612.86,
    "pmdec": 186.56,
    "rv": 23,
    "Asset Name": "ASTRID 001788",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Determined Shinobi",
    "Hair": "Bandana",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00001275,
    "y_1": 0.00006326,
    "z_1": 0.00000858,
    "Distance in Parsecs_1": 19.5465,
    "HYG Star ID_1": 11538,
    "Hipparcos Catalogue_1": 11565,
    "Henry Draper Catalogue_1": 15468,
    "Gliese Catalog_1": "Gl 100A"
  },
  {
    "Astrid #": "ASTRID 001789",
    "HYG Star ID": 31475,
    "Hipparcos Catalogue": 31555,
    "Distance in Parsecs": 19.5503,
    "mag": 10.6,
    "Absolute Visual Magnitude": 9.144,
    "Color Index": 1.459,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -2.362199,
    "y": 14.787459,
    "z": -12.568424,
    "vx": 0.00002075,
    "vy": 0.00000594,
    "vz": 0.00000309,
    "rarad": 1.72920436326419,
    "decrad": -0.698247453281222,
    "pmrarad": -0.00000109596980626388,
    "pmdecrad": 2.06482146e-7,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 31475,
    "lum": 0.0191601983206845,
    "var_min": 10.669,
    "var_max": 10.549,
    "ra": 6.605074,
    "dec": -40.006632,
    "pmra": -226.06,
    "pmdec": 42.59,
    "rv": 0,
    "Asset Name": "ASTRID 001789",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": 0.00002075,
    "y_1": 0.00000594,
    "z_1": 0.00000309,
    "Distance in Parsecs_1": 19.5503,
    "HYG Star ID_1": 31475,
    "Hipparcos Catalogue_1": 31555
  },
  {
    "Astrid #": "ASTRID 001790",
    "HYG Star ID": 73768,
    "Hipparcos Catalogue": 73996,
    "Henry Draper Catalogue": 134083,
    "Harvard Revised Catalogue": 5634,
    "Gliese Catalog": "Gl 578",
    "Distance in Parsecs": 19.5542,
    "mag": 4.93,
    "Absolute Visual Magnitude": 3.474,
    "Spectral Type": "F5V",
    "Color Index": 0.429,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.138752,
    "y": -12.937889,
    "z": 8.223444,
    "vx": 0.00001454,
    "vy": -0.00001011,
    "vz": -0.00001828,
    "rarad": 3.95884706086142,
    "decrad": 0.434049342710071,
    "pmrarad": 8.95984163034722e-7,
    "pmdecrad": -7.92718849e-7,
    "flam": 45,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 73768,
    "lum": 3.55140334982882,
    "bf": "45    Boo",
    "ra": 15.121682,
    "dec": 24.869195,
    "pmra": 184.81,
    "pmdec": -163.51,
    "rv": -9.8,
    "Asset Name": "ASTRID 001790",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Bob",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001454,
    "y_1": -0.00001011,
    "z_1": -0.00001828,
    "Distance in Parsecs_1": 19.5542,
    "HYG Star ID_1": 73768,
    "Hipparcos Catalogue_1": 73996,
    "Henry Draper Catalogue_1": 134083,
    "Harvard Revised Catalogue_1": 5634,
    "Gliese Catalog_1": "Gl 578"
  },
  {
    "Astrid #": "ASTRID 001791",
    "HYG Star ID": 118538,
    "Gliese Catalog": "Gl 308B",
    "Distance in Parsecs": 19.558,
    "mag": 11.56,
    "Absolute Visual Magnitude": 10.103,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.660455,
    "y": 12.776573,
    "z": 11.222752,
    "vx": 0.00006741,
    "vy": 0.00007482,
    "vz": -0.0000271,
    "rarad": 2.21825026288049,
    "decrad": 0.61116139052855,
    "pmrarad": -0.00000505738239008333,
    "pmdecrad": -0.000001692169429,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 2,
    "comp_primary": 41436,
    "base": "Gl 308",
    "lum": 0.00792136454152851,
    "ra": 8.473092,
    "dec": 35.016968,
    "pmra": -1043.16,
    "pmdec": -349.04,
    "rv": 0,
    "Asset Name": "ASTRID 001791",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Wavy",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00006741,
    "y_1": 0.00007482,
    "z_1": -0.0000271,
    "Distance in Parsecs_1": 19.558,
    "HYG Star ID_1": 118538,
    "Gliese Catalog_1": "Gl 308B"
  },
  {
    "Astrid #": "ASTRID 001792",
    "HYG Star ID": 40587,
    "Hipparcos Catalogue": 40702,
    "Henry Draper Catalogue": 71243,
    "Harvard Revised Catalogue": 3318,
    "Gliese Catalog": "Gl 305",
    "Distance in Parsecs": 19.5618,
    "mag": 4.05,
    "Absolute Visual Magnitude": 2.593,
    "Spectral Type": "F5III",
    "Color Index": 0.413,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.515914,
    "y": 3.642744,
    "z": -19.054133,
    "vx": -0.00001245,
    "vy": -3.1e-7,
    "vz": 0.00001544,
    "rarad": 2.17521336354428,
    "decrad": -1.34250241732561,
    "pmrarad": 5.32519346722222e-7,
    "pmdecrad": 5.18362787e-7,
    "bayer": "Alp",
    "con": "Cha",
    "Full Constellation Name Formula 1": "Chamaeleon",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Chamaeleon",
    "comp": 1,
    "comp_primary": 40587,
    "lum": 7.99466002520316,
    "bf": "Alp Cha",
    "ra": 8.308703,
    "dec": -76.919722,
    "pmra": 109.84,
    "pmdec": 106.92,
    "rv": -13.2,
    "Asset Name": "ASTRID 001792",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Prince",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Chamaeleon",
    "x_1": -0.00001245,
    "y_1": -3.1e-7,
    "z_1": 0.00001544,
    "Distance in Parsecs_1": 19.5618,
    "HYG Star ID_1": 40587,
    "Hipparcos Catalogue_1": 40702,
    "Henry Draper Catalogue_1": 71243,
    "Harvard Revised Catalogue_1": 3318,
    "Gliese Catalog_1": "Gl 305"
  },
  {
    "Astrid #": "ASTRID 001793",
    "HYG Star ID": 89547,
    "Hipparcos Catalogue": 89825,
    "Henry Draper Catalogue": 168442,
    "Gliese Catalog": "Gl 710",
    "Distance in Parsecs": 19.5618,
    "mag": 9.66,
    "Absolute Visual Magnitude": 8.203,
    "Spectral Type": "K7V",
    "Color Index": 1.35,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.690966,
    "y": -19.477219,
    "z": -0.661744,
    "vx": -0.00000101,
    "vy": 0.00001355,
    "vz": 6.6e-7,
    "rarad": 4.79898948681288,
    "decrad": -0.0338350686123412,
    "pmrarad": 8.43575804166666e-9,
    "pmdecrad": 9.987161e-9,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 89547,
    "lum": 0.0455826951540194,
    "ra": 18.33079,
    "dec": -1.938607,
    "pmra": 1.74,
    "pmdec": 2.06,
    "rv": -13.3,
    "Asset Name": "ASTRID 001793",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Manbun",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000101,
    "y_1": 0.00001355,
    "z_1": 6.6e-7,
    "Distance in Parsecs_1": 19.5618,
    "HYG Star ID_1": 89547,
    "Hipparcos Catalogue_1": 89825,
    "Henry Draper Catalogue_1": 168442,
    "Gliese Catalog_1": "Gl 710"
  },
  {
    "Astrid #": "ASTRID 001794",
    "HYG Star ID": 45201,
    "Hipparcos Catalogue": 45333,
    "Henry Draper Catalogue": 79028,
    "Harvard Revised Catalogue": 3648,
    "Gliese Catalog": "Gl 337.1",
    "Distance in Parsecs": 19.5695,
    "mag": 5.18,
    "Absolute Visual Magnitude": 3.722,
    "Spectral Type": "F9V",
    "Color Index": 0.605,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.020011,
    "y": 6.192111,
    "z": 17.185384,
    "vx": 0.00000391,
    "vy": -0.00000235,
    "vz": -0.00001456,
    "rarad": 2.41877516320822,
    "decrad": 1.07203912830909,
    "pmrarad": -4.20333461041666e-8,
    "pmdecrad": -1.54364675e-7,
    "flam": 16,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 45201,
    "lum": 2.82618117981015,
    "bf": "16    UMa",
    "ra": 9.239041,
    "dec": 61.423318,
    "pmra": -8.67,
    "pmdec": -31.84,
    "rv": -14.6,
    "Asset Name": "ASTRID 001794",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Slick",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000391,
    "y_1": -0.00000235,
    "z_1": -0.00001456,
    "Distance in Parsecs_1": 19.5695,
    "HYG Star ID_1": 45201,
    "Hipparcos Catalogue_1": 45333,
    "Henry Draper Catalogue_1": 79028,
    "Harvard Revised Catalogue_1": 3648,
    "Gliese Catalog_1": "Gl 337.1"
  },
  {
    "Astrid #": "ASTRID 001795",
    "HYG Star ID": 57786,
    "Hipparcos Catalogue": 57959,
    "Gliese Catalog": "Gl 452A",
    "Distance in Parsecs": 19.5695,
    "mag": 11.89,
    "Absolute Visual Magnitude": 10.432,
    "Spectral Type": "M4",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.3995,
    "y": 0.56999,
    "z": -2.511811,
    "vx": -0.00002036,
    "vy": 0.00001685,
    "vz": -0.00005171,
    "rarad": 3.11221944352158,
    "decrad": -0.128706844500304,
    "pmrarad": -8.303403906875e-7,
    "pmdecrad": -0.000002483942892,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 57786,
    "base": "Gl 452",
    "lum": 0.00585059451950005,
    "var_min": 11.985,
    "var_max": 11.795,
    "ra": 11.887803,
    "dec": -7.374359,
    "pmra": -171.27,
    "pmdec": -512.35,
    "rv": 26.7,
    "Asset Name": "ASTRID 001795",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Wavy",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": -0.00002036,
    "y_1": 0.00001685,
    "z_1": -0.00005171,
    "Distance in Parsecs_1": 19.5695,
    "HYG Star ID_1": 57786,
    "Hipparcos Catalogue_1": 57959,
    "Gliese Catalog_1": "Gl 452A"
  },
  {
    "Astrid #": "ASTRID 001796",
    "HYG Star ID": 119023,
    "Gliese Catalog": "NN 3907",
    "Distance in Parsecs": 19.5695,
    "mag": 14.17,
    "Absolute Visual Magnitude": 12.712,
    "Spectral Type": "m",
    "Color Index": 1.73,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.742948,
    "y": -11.366961,
    "z": 13.316095,
    "vx": 0.00000608,
    "vy": -0.0000515,
    "vz": -0.00003995,
    "rarad": 4.05670760562789,
    "decrad": 0.7483785851528,
    "pmrarad": 0.00000185121255783333,
    "pmdecrad": -0.000002786296944,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119023,
    "lum": 0.000716473282391108,
    "ra": 15.495482,
    "dec": 42.878934,
    "pmra": 381.84,
    "pmdec": -574.72,
    "rv": 0,
    "Asset Name": "ASTRID 001796",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Ninja",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000608,
    "y_1": -0.0000515,
    "z_1": -0.00003995,
    "Distance in Parsecs_1": 19.5695,
    "HYG Star ID_1": 119023,
    "Gliese Catalog_1": "NN 3907"
  },
  {
    "Astrid #": "ASTRID 001797",
    "HYG Star ID": 14131,
    "Hipparcos Catalogue": 14165,
    "Gliese Catalog": "Gl 121.1",
    "Distance in Parsecs": 19.5771,
    "mag": 11.77,
    "Absolute Visual Magnitude": 10.311,
    "Color Index": 1.501,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 13.001051,
    "y": 13.303406,
    "z": -6.10368,
    "vx": -0.00002385,
    "vy": 0.00003006,
    "vz": 0.00001472,
    "rarad": 0.796891183445288,
    "decrad": -0.317062195012221,
    "pmrarad": 0.00000194439374723611,
    "pmdecrad": 7.91312889e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 14131,
    "lum": 0.00654033509448894,
    "var_min": 11.843,
    "var_max": 11.693,
    "ra": 3.0439,
    "dec": -18.166326,
    "pmra": 401.06,
    "pmdec": 163.22,
    "rv": 0,
    "Asset Name": "ASTRID 001797",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Wavy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00002385,
    "y_1": 0.00003006,
    "z_1": 0.00001472,
    "Distance in Parsecs_1": 19.5771,
    "HYG Star ID_1": 14131,
    "Hipparcos Catalogue_1": 14165,
    "Gliese Catalog_1": "Gl 121.1"
  },
  {
    "Astrid #": "ASTRID 001798",
    "HYG Star ID": 1090,
    "Hipparcos Catalogue": 1092,
    "Gliese Catalog": "NN 3014A",
    "Distance in Parsecs": 19.5886,
    "mag": 11.14,
    "Absolute Visual Magnitude": 9.68,
    "Spectral Type": "M0",
    "Color Index": 1.556,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.171508,
    "y": 0.18905,
    "z": 19.329227,
    "vx": -0.00001845,
    "vy": 0.00002277,
    "vz": 0.0000028,
    "rarad": 0.0594834827814916,
    "decrad": 1.40788348789998,
    "pmrarad": 0.00000121620359904166,
    "pmdecrad": 8.82845712e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 1090,
    "base": "NN 3014",
    "lum": 0.0116949939101987,
    "var_min": 11.208,
    "var_max": 11.048,
    "ra": 0.22721,
    "dec": 80.665782,
    "pmra": 250.86,
    "pmdec": 182.1,
    "rv": 0,
    "Asset Name": "ASTRID 001798",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Audiohead",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001845,
    "y_1": 0.00002277,
    "z_1": 0.0000028,
    "Distance in Parsecs_1": 19.5886,
    "HYG Star ID_1": 1090,
    "Hipparcos Catalogue_1": 1092,
    "Gliese Catalog_1": "NN 3014A"
  },
  {
    "Astrid #": "ASTRID 001799",
    "HYG Star ID": 75048,
    "Hipparcos Catalogue": 75277,
    "Henry Draper Catalogue": 136923,
    "Distance in Parsecs": 19.604,
    "mag": 7.16,
    "Absolute Visual Magnitude": 5.698,
    "Spectral Type": "K0",
    "Color Index": 0.804,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.74718,
    "y": -14.349765,
    "z": 6.356183,
    "vx": -0.00000887,
    "vy": 0.00002372,
    "vz": 0.00000342,
    "rarad": 4.02639032239832,
    "decrad": 0.330198145642767,
    "pmrarad": -0.00000111652590631944,
    "pmdecrad": 3.75730602e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 75048,
    "lum": 0.457930953151743,
    "ra": 15.379678,
    "dec": 18.91896,
    "pmra": -230.3,
    "pmdec": 77.5,
    "rv": -10.7,
    "Asset Name": "ASTRID 001799",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Spiky",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000887,
    "y_1": 0.00002372,
    "z_1": 0.00000342,
    "Distance in Parsecs_1": 19.604,
    "HYG Star ID_1": 75048,
    "Hipparcos Catalogue_1": 75277,
    "Henry Draper Catalogue_1": 136923
  },
  {
    "Astrid #": "ASTRID 001800",
    "HYG Star ID": 80139,
    "Hipparcos Catalogue": 80381,
    "Henry Draper Catalogue": 147555,
    "Distance in Parsecs": 19.6078,
    "mag": 9.99,
    "Absolute Visual Magnitude": 8.528,
    "Spectral Type": "K5",
    "Color Index": 1.239,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.744264,
    "y": -12.971031,
    "z": -13.535937,
    "vx": -0.00001688,
    "vy": 0.00001403,
    "vz": -0.00000628,
    "rarad": 4.29549777125213,
    "decrad": -0.761951049448983,
    "pmrarad": -0.00000107677118451388,
    "pmdecrad": -4.43022741e-7,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 80139,
    "lum": 0.0337909187437722,
    "var_min": 10.153,
    "var_max": 9.883,
    "ra": 16.407593,
    "dec": -43.656579,
    "pmra": -222.1,
    "pmdec": -91.38,
    "rv": 0,
    "Asset Name": "ASTRID 001800",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Braided Bun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00001688,
    "y_1": 0.00001403,
    "z_1": -0.00000628,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 80139,
    "Hipparcos Catalogue_1": 80381,
    "Henry Draper Catalogue_1": 147555
  },
  {
    "Astrid #": "ASTRID 001801",
    "HYG Star ID": 90089,
    "Hipparcos Catalogue": 90368,
    "Distance in Parsecs": 19.6078,
    "mag": 9.76,
    "Absolute Visual Magnitude": 8.298,
    "Spectral Type": "F8",
    "Color Index": 0.661,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.541518,
    "y": -13.332687,
    "z": 14.29437,
    "vx": -0.00000315,
    "vy": -2.5e-7,
    "vz": 1.1e-7,
    "rarad": 4.82749800561625,
    "decrad": 0.816881038691821,
    "pmrarad": -1.60958141944444e-7,
    "pmdecrad": 8.193351e-9,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 90089,
    "lum": 0.0417637992950973,
    "ra": 18.439684,
    "dec": 46.803836,
    "pmra": -33.2,
    "pmdec": 1.69,
    "rv": 0,
    "Asset Name": "ASTRID 001801",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Serious Slumber",
    "Hair": "Curly",
    "Outfit": "Astro",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000315,
    "y_1": -2.5e-7,
    "z_1": 1.1e-7,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 90089,
    "Hipparcos Catalogue_1": 90368
  },
  {
    "Astrid #": "ASTRID 001802",
    "HYG Star ID": 118076,
    "Gliese Catalog": "NN 3101",
    "Distance in Parsecs": 19.6078,
    "mag": 13.33,
    "Absolute Visual Magnitude": 11.868,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.990606,
    "y": 7.122419,
    "z": -8.833882,
    "vx": 6.2e-7,
    "vy": 6.7e-7,
    "vz": 0.00000167,
    "rarad": 0.419032472994129,
    "decrad": -0.46735779106895,
    "pmrarad": 1.85198825972222e-8,
    "pmdecrad": 9.5178621e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118076,
    "lum": 0.00155883446770438,
    "ra": 1.600586,
    "dec": -26.777629,
    "pmra": 3.82,
    "pmdec": 19.63,
    "rv": 0,
    "Asset Name": "ASTRID 001802",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Fire",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 6.2e-7,
    "y_1": 6.7e-7,
    "z_1": 0.00000167,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118076,
    "Gliese Catalog_1": "NN 3101"
  },
  {
    "Astrid #": "ASTRID 001803",
    "HYG Star ID": 118343,
    "Gliese Catalog": "NN 3321",
    "Distance in Parsecs": 19.6078,
    "mag": 11.28,
    "Absolute Visual Magnitude": 9.818,
    "Spectral Type": "M1",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.91038,
    "y": 18.939564,
    "z": 1.283333,
    "vx": -0.00004877,
    "vy": 0.00001975,
    "vz": -0.00010441,
    "rarad": 1.31711584472208,
    "decrad": 0.06549694306485,
    "pmrarad": 0.000002660754441625,
    "pmdecrad": -0.000005336678703,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118343,
    "lum": 0.0102991171934069,
    "ra": 5.031012,
    "dec": 3.752698,
    "pmra": 548.82,
    "pmdec": -1100.77,
    "rv": 0,
    "Asset Name": "ASTRID 001803",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Round Brush",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00004877,
    "y_1": 0.00001975,
    "z_1": -0.00010441,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118343,
    "Gliese Catalog_1": "NN 3321"
  },
  {
    "Astrid #": "ASTRID 001804",
    "HYG Star ID": 118381,
    "Gliese Catalog": "NN 3353A",
    "Distance in Parsecs": 19.6078,
    "mag": 12.32,
    "Absolute Visual Magnitude": 10.858,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.162406,
    "y": 19.168944,
    "z": 3.513039,
    "vx": 0.00001119,
    "vy": 0.00000497,
    "vz": -0.00003398,
    "rarad": 1.45846383460004,
    "decrad": 0.1801380075663,
    "pmrarad": -5.38627999097222e-7,
    "pmdecrad": -0.000001761788674,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118381,
    "base": "NN 3353",
    "lum": 0.00395184588928436,
    "ra": 5.570921,
    "dec": 10.321148,
    "pmra": -111.1,
    "pmdec": -363.39,
    "rv": 0,
    "Asset Name": "ASTRID 001804",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Owie",
    "Hair": "Prince",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001119,
    "y_1": 0.00000497,
    "z_1": -0.00003398,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118381,
    "Gliese Catalog_1": "NN 3353A"
  },
  {
    "Astrid #": "ASTRID 001805",
    "HYG Star ID": 118382,
    "Gliese Catalog": "NN 3354B",
    "Distance in Parsecs": 19.6078,
    "mag": 13.62,
    "Absolute Visual Magnitude": 12.158,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.162422,
    "y": 19.169045,
    "z": 3.512477,
    "vx": 0.00001119,
    "vy": 0.00000497,
    "vz": -0.00003398,
    "rarad": 1.45846368960004,
    "decrad": 0.180108918566299,
    "pmrarad": -5.38627999097222e-7,
    "pmdecrad": -0.000001761788674,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118382,
    "base": "NN 3354",
    "lum": 0.00119343837921079,
    "ra": 5.570921,
    "dec": 10.319481,
    "pmra": -111.1,
    "pmdec": -363.39,
    "rv": 0,
    "Asset Name": "ASTRID 001805",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Frizzy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001119,
    "y_1": 0.00000497,
    "z_1": -0.00003398,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118382,
    "Gliese Catalog_1": "NN 3354B"
  },
  {
    "Astrid #": "ASTRID 001806",
    "HYG Star ID": 118413,
    "Henry Draper Catalogue": 41190,
    "Gliese Catalog": "NN 3383",
    "Distance in Parsecs": 19.6078,
    "mag": 7.98,
    "Absolute Visual Magnitude": 6.518,
    "Spectral Type": "G5",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.352863,
    "y": 18.569744,
    "z": 6.285372,
    "vx": -1.2e-7,
    "vy": 3e-7,
    "vz": -8.9e-7,
    "rarad": 1.58979607104007,
    "decrad": 0.3263150411425,
    "pmrarad": 5.72080143055555e-9,
    "pmdecrad": -4.813715e-8,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118413,
    "lum": 0.215179057033975,
    "ra": 6.072574,
    "dec": 18.696475,
    "pmra": 1.18,
    "pmdec": -9.93,
    "rv": 0,
    "Asset Name": "ASTRID 001806",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Honest Lad",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -1.2e-7,
    "y_1": 3e-7,
    "z_1": -8.9e-7,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118413,
    "Henry Draper Catalogue_1": 41190,
    "Gliese Catalog_1": "NN 3383"
  },
  {
    "Astrid #": "ASTRID 001807",
    "HYG Star ID": 118456,
    "Gliese Catalog": "NN 3419",
    "Distance in Parsecs": 19.6078,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.038,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.932536,
    "y": 18.652797,
    "z": -3.494149,
    "vx": 0.00000531,
    "vy": -0.00001119,
    "vz": -0.00006731,
    "rarad": 1.82931837074872,
    "decrad": -0.17915886293185,
    "pmrarad": -1.15725025548611e-7,
    "pmdecrad": -0.000003488738637,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118456,
    "lum": 0.00133290746423133,
    "ra": 6.987481,
    "dec": -10.265047,
    "pmra": -23.87,
    "pmdec": -719.6,
    "rv": 0,
    "Asset Name": "ASTRID 001807",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Braided Ponytail",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000531,
    "y_1": -0.00001119,
    "z_1": -0.00006731,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118456,
    "Gliese Catalog_1": "NN 3419"
  },
  {
    "Astrid #": "ASTRID 001808",
    "HYG Star ID": 118636,
    "Gliese Catalog": "NN 3560",
    "Distance in Parsecs": 19.6078,
    "mag": 11.95,
    "Absolute Visual Magnitude": 10.488,
    "Spectral Type": "M3",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.612486,
    "y": 5.570039,
    "z": 17.189839,
    "vx": 0.00000157,
    "vy": 0.00000101,
    "vz": 3.7e-7,
    "rarad": 2.50991299468261,
    "decrad": 1.06892467470915,
    "pmrarad": -8.88663476458333e-8,
    "pmdecrad": 3.8814183e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 118636,
    "lum": 0.00555648312837126,
    "ra": 9.587161,
    "dec": 61.244872,
    "pmra": -18.33,
    "pmdec": 8.01,
    "rv": 0,
    "Asset Name": "ASTRID 001808",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Bandana",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000157,
    "y_1": 0.00000101,
    "z_1": 3.7e-7,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118636,
    "Gliese Catalog_1": "NN 3560"
  },
  {
    "Astrid #": "ASTRID 001809",
    "HYG Star ID": 118682,
    "Gliese Catalog": "NN 3600A",
    "Distance in Parsecs": 19.6078,
    "mag": 13.52,
    "Absolute Visual Magnitude": 12.058,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.450838,
    "y": 4.976619,
    "z": 15.118777,
    "vx": -0.00002113,
    "vy": 0.00004548,
    "vz": -0.00003096,
    "rarad": 2.73166104973198,
    "decrad": 0.88050303893275,
    "pmrarad": -0.00000169820536025,
    "pmdecrad": -0.000002480161345,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118682,
    "base": "NN 3600",
    "lum": 0.00130857916124509,
    "ra": 10.434177,
    "dec": 50.449108,
    "pmra": -350.28,
    "pmdec": -511.57,
    "rv": 0,
    "Asset Name": "ASTRID 001809",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Mohawk",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002113,
    "y_1": 0.00004548,
    "z_1": -0.00003096,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118682,
    "Gliese Catalog_1": "NN 3600A"
  },
  {
    "Astrid #": "ASTRID 001810",
    "HYG Star ID": 118683,
    "Gliese Catalog": "NN 3601B",
    "Distance in Parsecs": 19.6078,
    "mag": 13.68,
    "Absolute Visual Magnitude": 12.218,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.450395,
    "y": 4.975438,
    "z": 15.119501,
    "vx": -0.00002113,
    "vy": 0.00004548,
    "vz": -0.00003096,
    "rarad": 2.73173366573198,
    "decrad": 0.88056107093275,
    "pmrarad": -0.00000169820536025,
    "pmdecrad": -0.000002480161345,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118683,
    "base": "NN 3601",
    "lum": 0.00112927574420941,
    "ra": 10.434454,
    "dec": 50.452433,
    "pmra": -350.28,
    "pmdec": -511.57,
    "rv": 0,
    "Asset Name": "ASTRID 001810",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Innocent",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002113,
    "y_1": 0.00004548,
    "z_1": -0.00003096,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118683,
    "Gliese Catalog_1": "NN 3601B"
  },
  {
    "Astrid #": "ASTRID 001811",
    "HYG Star ID": 118726,
    "Gliese Catalog": "NN 3639",
    "Distance in Parsecs": 19.6078,
    "mag": 13.67,
    "Absolute Visual Magnitude": 12.208,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.24939,
    "y": 3.861259,
    "z": 11.705238,
    "vx": 0.00000585,
    "vy": 0.0000159,
    "vz": 0.00000238,
    "rarad": 2.89360809996349,
    "decrad": 0.63971702766815,
    "pmrarad": -8.59380730152777e-7,
    "pmdecrad": 1.51533363e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118726,
    "lum": 0.00113972480401974,
    "ra": 11.052769,
    "dec": 36.653086,
    "pmra": -177.26,
    "pmdec": 31.26,
    "rv": 0,
    "Asset Name": "ASTRID 001811",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Ram Horns",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000585,
    "y_1": 0.0000159,
    "z_1": 0.00000238,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118726,
    "Gliese Catalog_1": "NN 3639"
  },
  {
    "Astrid #": "ASTRID 001812",
    "HYG Star ID": 118736,
    "Gliese Catalog": "GJ 1143B",
    "Distance in Parsecs": 19.6078,
    "mag": 13.5,
    "Absolute Visual Magnitude": 12.038,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -17.685478,
    "y": 3.534037,
    "z": -7.694174,
    "vx": 0.00001827,
    "vy": 0.00002156,
    "vz": -0.00003211,
    "rarad": 2.94436888252525,
    "decrad": -0.40324344257855,
    "pmrarad": -0.00000126070949491666,
    "pmdecrad": -0.000001780531571,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 54761,
    "base": "GJ 1143",
    "lum": 0.00133290746423133,
    "ra": 11.246661,
    "dec": -23.104147,
    "pmra": -260.04,
    "pmdec": -367.26,
    "rv": 0,
    "Asset Name": "ASTRID 001812",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Serious Slumber",
    "Hair": "Lion Mane",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001827,
    "y_1": 0.00002156,
    "z_1": -0.00003211,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118736,
    "Gliese Catalog_1": "GJ 1143B"
  },
  {
    "Astrid #": "ASTRID 001813",
    "HYG Star ID": 118810,
    "Gliese Catalog": "NN 3719",
    "Distance in Parsecs": 19.6078,
    "mag": 14.15,
    "Absolute Visual Magnitude": 12.688,
    "Spectral Type": "dM4-5e",
    "Color Index": 1.62,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -16.731959,
    "y": -1.241861,
    "z": 10.143815,
    "vx": 0.00002764,
    "vy": 0.00001016,
    "vz": -0.00002037,
    "rarad": 3.21568117017789,
    "decrad": 0.5437882622372,
    "pmrarad": -4.12576442152777e-7,
    "pmdecrad": -1.42055256e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118810,
    "lum": 0.000732487132665038,
    "ra": 12.282997,
    "dec": 31.156772,
    "pmra": -85.1,
    "pmdec": -29.3,
    "rv": -34,
    "Asset Name": "ASTRID 001813",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Audiohead",
    "Outfit": "Wizard",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002764,
    "y_1": 0.00001016,
    "z_1": -0.00002037,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118810,
    "Gliese Catalog_1": "NN 3719"
  },
  {
    "Astrid #": "ASTRID 001814",
    "HYG Star ID": 118842,
    "Gliese Catalog": "NN 3744",
    "Distance in Parsecs": 19.6078,
    "mag": 15.84,
    "Absolute Visual Magnitude": 14.378,
    "Spectral Type": "m",
    "Color Index": 1.86,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.043549,
    "y": -0.757189,
    "z": 19.17139,
    "vx": 0.0000298,
    "vy": 0.00006937,
    "vz": 0.00000904,
    "rarad": 3.3273089711702,
    "decrad": 1.35941992035705,
    "pmrarad": -0.00000319637659590277,
    "pmdecrad": 0.000002196807141,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118842,
    "lum": 0.000154454298740142,
    "ra": 12.709384,
    "dec": 77.889024,
    "pmra": -659.3,
    "pmdec": 453.12,
    "rv": 0,
    "Asset Name": "ASTRID 001814",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Angry",
    "Hair": "Ponytail",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000298,
    "y_1": 0.00006937,
    "z_1": 0.00000904,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118842,
    "Gliese Catalog_1": "NN 3744"
  },
  {
    "Astrid #": "ASTRID 001815",
    "HYG Star ID": 118914,
    "Gliese Catalog": "NN 3802",
    "Distance in Parsecs": 19.6078,
    "mag": 13.09,
    "Absolute Visual Magnitude": 11.628,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.439186,
    "y": -8.413087,
    "z": 3.092021,
    "vx": -0.00001477,
    "vy": 0.00002659,
    "vz": -0.00001092,
    "rarad": 3.59108212401453,
    "decrad": 0.1583544414782,
    "pmrarad": -0.000001548979709375,
    "pmdecrad": -5.63770436e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118914,
    "lum": 0.00194446441644812,
    "ra": 13.716923,
    "dec": 9.073041,
    "pmra": -319.5,
    "pmdec": -116.29,
    "rv": 0,
    "Asset Name": "ASTRID 001815",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Horns",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001477,
    "y_1": 0.00002659,
    "z_1": -0.00001092,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118914,
    "Gliese Catalog_1": "NN 3802"
  },
  {
    "Astrid #": "ASTRID 001816",
    "HYG Star ID": 118940,
    "Gliese Catalog": "NN 3821",
    "Distance in Parsecs": 19.6078,
    "mag": 13.19,
    "Absolute Visual Magnitude": 11.728,
    "Spectral Type": "M3.5",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.751638,
    "y": -9.323368,
    "z": -7.0304,
    "vx": 0.000035,
    "vy": -0.00003518,
    "vz": -0.00003179,
    "rarad": 3.67602499986118,
    "decrad": -0.36671545434105,
    "pmrarad": 0.00000245315722361111,
    "pmdecrad": -0.000001736946821,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118940,
    "lum": 0.00177337262454255,
    "ra": 14.041381,
    "dec": -21.011248,
    "pmra": 506,
    "pmdec": -358.27,
    "rv": 0,
    "Asset Name": "ASTRID 001816",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Frizzy",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.000035,
    "y_1": -0.00003518,
    "z_1": -0.00003179,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118940,
    "Gliese Catalog_1": "NN 3821"
  },
  {
    "Astrid #": "ASTRID 001817",
    "HYG Star ID": 118978,
    "Gliese Catalog": "NN 3858",
    "Distance in Parsecs": 19.6078,
    "mag": 13.26,
    "Absolute Visual Magnitude": 11.798,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.995531,
    "y": -7.826074,
    "z": 14.943485,
    "vx": -0.00004009,
    "vy": 0.00003867,
    "vz": -0.00000655,
    "rarad": 3.80593159088651,
    "decrad": 0.8665803942171,
    "pmrarad": -0.00000281342226963194,
    "pmdecrad": -5.16355658e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118978,
    "lum": 0.00166264679689353,
    "ra": 14.537588,
    "dec": 49.651399,
    "pmra": -580.31,
    "pmdec": -106.51,
    "rv": 0,
    "Asset Name": "ASTRID 001817",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Elegant",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004009,
    "y_1": 0.00003867,
    "z_1": -0.00000655,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 118978,
    "Gliese Catalog_1": "NN 3858"
  },
  {
    "Astrid #": "ASTRID 001818",
    "HYG Star ID": 119015,
    "Gliese Catalog": "NN 3899",
    "Distance in Parsecs": 19.6078,
    "mag": 14.02,
    "Absolute Visual Magnitude": 12.558,
    "Spectral Type": "DA wk",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -12.76517,
    "y": -14.843938,
    "z": 1.083419,
    "vx": 0.00001365,
    "vy": -0.00001443,
    "vz": -0.0000368,
    "rarad": 4.00214180523023,
    "decrad": 0.05528263517235,
    "pmrarad": 0.00000100802460461111,
    "pmdecrad": -0.000001879976553,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119015,
    "lum": 0.000825657632621326,
    "ra": 15.287056,
    "dec": 3.167462,
    "pmra": 207.92,
    "pmdec": -387.77,
    "rv": 0,
    "Asset Name": "ASTRID 001818",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Spiky",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001365,
    "y_1": -0.00001443,
    "z_1": -0.0000368,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 119015,
    "Gliese Catalog_1": "NN 3899"
  },
  {
    "Astrid #": "ASTRID 001819",
    "HYG Star ID": 119026,
    "Gliese Catalog": "NN 3911",
    "Distance in Parsecs": 19.6078,
    "mag": 13.58,
    "Absolute Visual Magnitude": 12.118,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.081323,
    "y": -10.88757,
    "z": 14.163999,
    "vx": 0.00000901,
    "vy": 0.0000095,
    "vz": 0.00001244,
    "rarad": 4.07386313725727,
    "decrad": 0.807217062797099,
    "pmrarad": 8.02851454999999e-8,
    "pmdecrad": 9.17635942e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119026,
    "lum": 0.00123822623095899,
    "ra": 15.561011,
    "dec": 46.250131,
    "pmra": 16.56,
    "pmdec": 189.28,
    "rv": 0,
    "Asset Name": "ASTRID 001819",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Windy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000901,
    "y_1": 0.0000095,
    "z_1": 0.00001244,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 119026,
    "Gliese Catalog_1": "NN 3911"
  },
  {
    "Astrid #": "ASTRID 001820",
    "HYG Star ID": 119354,
    "Gliese Catalog": "NN 4151",
    "Distance in Parsecs": 19.6078,
    "mag": 13.43,
    "Absolute Visual Magnitude": 11.968,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.265016,
    "y": -7.811276,
    "z": 16.858213,
    "vx": -0.00001668,
    "vy": -0.00001811,
    "vz": -0.00000219,
    "rarad": 5.38843672372854,
    "decrad": 1.034820654816,
    "pmrarad": -0.0000012413654290625,
    "pmdecrad": -2.1888368e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119354,
    "lum": 0.001421673931308,
    "ra": 20.582312,
    "dec": 59.290856,
    "pmra": -256.05,
    "pmdec": -45.15,
    "rv": 0,
    "Asset Name": "ASTRID 001820",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Hachimaki",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001668,
    "y_1": -0.00001811,
    "z_1": -0.00000219,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 119354,
    "Gliese Catalog_1": "NN 4151"
  },
  {
    "Astrid #": "ASTRID 001821",
    "HYG Star ID": 119453,
    "Gliese Catalog": "NN 4236",
    "Distance in Parsecs": 19.6078,
    "mag": 14.41,
    "Absolute Visual Magnitude": 12.948,
    "Spectral Type": "DA6",
    "Color Index": 0.26,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 16.724045,
    "y": -10.237407,
    "z": -0.440815,
    "vx": 0.00009875,
    "vy": -0.00005893,
    "vz": -0.00002726,
    "rarad": 5.73388930315709,
    "decrad": -0.0224769012392,
    "pmrarad": 6.59831419236111e-8,
    "pmdecrad": -0.000001258784784,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119453,
    "lum": 0.000576500913769854,
    "ra": 21.901844,
    "dec": -1.287832,
    "pmra": 13.61,
    "pmdec": -259.64,
    "rv": 113,
    "Asset Name": "ASTRID 001821",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Tiny Crown",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00009875,
    "y_1": -0.00005893,
    "z_1": -0.00002726,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 119453,
    "Gliese Catalog_1": "NN 4236"
  },
  {
    "Astrid #": "ASTRID 001822",
    "HYG Star ID": 119575,
    "Gliese Catalog": "NN 4348",
    "Distance in Parsecs": 19.6078,
    "mag": 12.37,
    "Absolute Visual Magnitude": 10.908,
    "Color Index": 1.44,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.90064,
    "y": -1.338052,
    "z": -14.705405,
    "vx": -8.8e-7,
    "vy": -0.00001137,
    "vz": 2.6e-7,
    "rarad": 6.17984990914764,
    "decrad": -0.8480277200426,
    "pmrarad": -5.81437047090277e-7,
    "pmdecrad": 2.0299148e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119575,
    "lum": 0.00377398352536585,
    "ra": 23.605288,
    "dec": -48.588409,
    "pmra": -119.93,
    "pmdec": 4.19,
    "rv": 0,
    "Asset Name": "ASTRID 001822",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Fedora",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -8.8e-7,
    "y_1": -0.00001137,
    "z_1": 2.6e-7,
    "Distance in Parsecs_1": 19.6078,
    "HYG Star ID_1": 119575,
    "Gliese Catalog_1": "NN 4348"
  },
  {
    "Astrid #": "ASTRID 001823",
    "HYG Star ID": 112618,
    "Hipparcos Catalogue": 112978,
    "Distance in Parsecs": 19.6117,
    "mag": 11.82,
    "Absolute Visual Magnitude": 10.357,
    "Spectral Type": "M:",
    "Color Index": 1.533,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.453149,
    "y": -4.967402,
    "z": -9.446566,
    "vx": -0.00002337,
    "vy": -0.00003344,
    "vz": -0.00002313,
    "rarad": 5.98997783290848,
    "decrad": -0.502570922548507,
    "pmrarad": -0.00000197692474520138,
    "pmdecrad": -0.000001345891258,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 112618,
    "lum": 0.00626902497476165,
    "var_min": 11.94,
    "var_max": 11.71,
    "ra": 22.88003,
    "dec": -28.795193,
    "pmra": -407.77,
    "pmdec": -277.61,
    "rv": 0,
    "Asset Name": "ASTRID 001823",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Oof",
    "Hair": "Lion Mane",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002337,
    "y_1": -0.00003344,
    "z_1": -0.00002313,
    "Distance in Parsecs_1": 19.6117,
    "HYG Star ID_1": 112618,
    "Hipparcos Catalogue_1": 112978
  },
  {
    "Astrid #": "ASTRID 001824",
    "HYG Star ID": 54069,
    "Hipparcos Catalogue": 54227,
    "Gliese Catalog": "NN 3643",
    "Distance in Parsecs": 19.6464,
    "mag": 12.37,
    "Absolute Visual Magnitude": 10.904,
    "Spectral Type": "K:",
    "Color Index": 1.572,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.793974,
    "y": 4.536412,
    "z": 3.491211,
    "vx": -0.00002532,
    "vy": -0.00005452,
    "vz": -0.00006546,
    "rarad": 2.90474670335779,
    "decrad": 0.178651148460198,
    "pmrarad": 0.00000300026946211805,
    "pmdecrad": -0.000003385890263,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 54069,
    "lum": 0.00378791303785156,
    "var_min": 12.511,
    "var_max": 12.151,
    "ra": 11.095315,
    "dec": 10.235957,
    "pmra": 618.85,
    "pmdec": -698.39,
    "rv": 0,
    "Asset Name": "ASTRID 001824",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Afro",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002532,
    "y_1": -0.00005452,
    "z_1": -0.00006546,
    "Distance in Parsecs_1": 19.6464,
    "HYG Star ID_1": 54069,
    "Hipparcos Catalogue_1": 54227,
    "Gliese Catalog_1": "NN 3643"
  },
  {
    "Astrid #": "ASTRID 001825",
    "HYG Star ID": 118127,
    "Gliese Catalog": "Gl 91.3",
    "Distance in Parsecs": 19.6464,
    "mag": 16.2,
    "Absolute Visual Magnitude": 14.734,
    "Spectral Type": "DC9",
    "Color Index": 0.72,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.884617,
    "y": 8.088657,
    "z": 13.390689,
    "vx": -0.00001283,
    "vy": 0.0000854,
    "vz": -0.00004017,
    "rarad": 0.597513483325464,
    "decrad": 0.74992637994025,
    "pmrarad": 0.00000396160650929166,
    "pmdecrad": -0.000002794621195,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118127,
    "lum": 0.000111275614173051,
    "ra": 2.282333,
    "dec": 42.967617,
    "pmra": 817.14,
    "pmdec": -576.43,
    "rv": 0,
    "Asset Name": "ASTRID 001825",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Audiohead",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001283,
    "y_1": 0.0000854,
    "z_1": -0.00004017,
    "Distance in Parsecs_1": 19.6464,
    "HYG Star ID_1": 118127,
    "Gliese Catalog_1": "Gl 91.3"
  },
  {
    "Astrid #": "ASTRID 001826",
    "HYG Star ID": 119279,
    "Gliese Catalog": "Gl 755.1",
    "Distance in Parsecs": 19.6464,
    "mag": 13.01,
    "Absolute Visual Magnitude": 11.544,
    "Spectral Type": "DA5",
    "Color Index": 0.06,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 6.630966,
    "y": -17.813071,
    "z": 4.977566,
    "vx": 0.00001284,
    "vy": -0.00003449,
    "vz": 7.8e-7,
    "rarad": 5.068749014,
    "decrad": 0.2561257113844,
    "pmrarad": 0,
    "pmdecrad": -4.36332312e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119279,
    "lum": 0.00210087396924098,
    "ra": 19.361195,
    "dec": 14.674922,
    "pmra": 0,
    "pmdec": -90,
    "rv": 35,
    "Asset Name": "ASTRID 001826",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Big Laugh",
    "Hair": "Braided Ponytail",
    "Outfit": "River Spirit",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001284,
    "y_1": -0.00003449,
    "z_1": 7.8e-7,
    "Distance in Parsecs_1": 19.6464,
    "HYG Star ID_1": 119279,
    "Gliese Catalog_1": "Gl 755.1"
  },
  {
    "Astrid #": "ASTRID 001827",
    "HYG Star ID": 119410,
    "Gliese Catalog": "GJ 1261",
    "Distance in Parsecs": 19.6464,
    "mag": 12.33,
    "Absolute Visual Magnitude": 10.864,
    "Spectral Type": "DA",
    "Color Index": 0.07,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 8.766917,
    "y": -7.425982,
    "z": 15.936655,
    "vx": -0.00001931,
    "vy": 0.00000276,
    "vz": 0.00001191,
    "rarad": 5.58042742235068,
    "decrad": 0.9461574275211,
    "pmrarad": -5.28252986333333e-7,
    "pmdecrad": 0.000001036730422,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119410,
    "lum": 0.00393006741341852,
    "ra": 21.315663,
    "dec": 54.210827,
    "pmra": -108.96,
    "pmdec": 213.84,
    "rv": 0,
    "Asset Name": "ASTRID 001827",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Little Crown",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001931,
    "y_1": 0.00000276,
    "z_1": 0.00001191,
    "Distance in Parsecs_1": 19.6464,
    "HYG Star ID_1": 119410,
    "Gliese Catalog_1": "GJ 1261"
  },
  {
    "Astrid #": "ASTRID 001828",
    "HYG Star ID": 4463,
    "Hipparcos Catalogue": 4473,
    "Henry Draper Catalogue": 5633,
    "Gliese Catalog": "Gl 45",
    "Distance in Parsecs": 19.658,
    "mag": 9.5,
    "Absolute Visual Magnitude": 8.032,
    "Spectral Type": "K7V",
    "Color Index": 1.299,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.869515,
    "y": 2.266133,
    "z": -17.39647,
    "vx": -0.00000873,
    "vy": 0.00009891,
    "vz": -0.00000428,
    "rarad": 0.250095440989561,
    "decrad": -1.08639060199523,
    "pmrarad": 0.00000498548452125694,
    "pmdecrad": 6.19543402e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 4463,
    "lum": 0.0533580561846033,
    "ra": 0.955294,
    "dec": -62.245596,
    "pmra": 1028.33,
    "pmdec": 127.79,
    "rv": 11,
    "Asset Name": "ASTRID 001828",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Guilty Grin",
    "Hair": "Frizzy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000873,
    "y_1": 0.00009891,
    "z_1": -0.00000428,
    "Distance in Parsecs_1": 19.658,
    "HYG Star ID_1": 4463,
    "Hipparcos Catalogue_1": 4473,
    "Henry Draper Catalogue_1": 5633,
    "Gliese Catalog_1": "Gl 45"
  },
  {
    "Astrid #": "ASTRID 001829",
    "HYG Star ID": 80481,
    "Hipparcos Catalogue": 80725,
    "Henry Draper Catalogue": 148653,
    "Gliese Catalog": "Gl 627A",
    "Distance in Parsecs": 19.658,
    "mag": 6.98,
    "Absolute Visual Magnitude": 5.512,
    "Spectral Type": "K2V",
    "Color Index": 0.848,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.221787,
    "y": -17.196282,
    "z": 6.209502,
    "vx": -0.0000123,
    "vy": 0.00005586,
    "vz": 0.00002321,
    "rarad": 4.3147939952353,
    "decrad": 0.321385881733635,
    "pmrarad": -0.00000167711596514583,
    "pmdecrad": 0.000001871283844,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 80481,
    "base": "Gl 627",
    "lum": 0.543500565129446,
    "ra": 16.481299,
    "dec": 18.414055,
    "pmra": -345.93,
    "pmdec": 385.98,
    "rv": -36.2,
    "Asset Name": "ASTRID 001829",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Greasy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.0000123,
    "y_1": 0.00005586,
    "z_1": 0.00002321,
    "Distance in Parsecs_1": 19.658,
    "HYG Star ID_1": 80481,
    "Hipparcos Catalogue_1": 80725,
    "Henry Draper Catalogue_1": 148653,
    "Gliese Catalog_1": "Gl 627A"
  },
  {
    "Astrid #": "ASTRID 001830",
    "HYG Star ID": 112168,
    "Hipparcos Catalogue": 112527,
    "Henry Draper Catalogue": 216520,
    "Distance in Parsecs": 19.6734,
    "mag": 7.53,
    "Absolute Visual Magnitude": 6.061,
    "Spectral Type": "K2",
    "Color Index": 0.867,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.052763,
    "y": -0.671628,
    "z": 19.55431,
    "vx": -0.00001707,
    "vy": -0.00000969,
    "vz": -0.00001819,
    "rarad": 5.9670330234938,
    "decrad": 1.46078872009896,
    "pmrarad": -7.37886421805555e-7,
    "pmdecrad": 5.66068453e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 112168,
    "lum": 0.327793245300228,
    "ra": 22.792387,
    "dec": 83.697028,
    "pmra": -152.2,
    "pmdec": 116.76,
    "rv": -19.1,
    "Asset Name": "ASTRID 001830",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Sweetheart",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001707,
    "y_1": -0.00000969,
    "z_1": -0.00001819,
    "Distance in Parsecs_1": 19.6734,
    "HYG Star ID_1": 112168,
    "Hipparcos Catalogue_1": 112527,
    "Henry Draper Catalogue_1": 216520
  },
  {
    "Astrid #": "ASTRID 001831",
    "HYG Star ID": 1838,
    "Hipparcos Catalogue": 1842,
    "Gliese Catalog": "NN 3032",
    "Distance in Parsecs": 19.6773,
    "mag": 11.87,
    "Absolute Visual Magnitude": 10.4,
    "Spectral Type": "M:",
    "Color Index": 1.516,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.347494,
    "y": 1.260133,
    "z": -15.269171,
    "vx": -0.00000537,
    "vy": 0.00005201,
    "vz": -5e-8,
    "rarad": 0.101690050482314,
    "decrad": -0.888265678603285,
    "pmrarad": 0.00000265731226449305,
    "pmdecrad": -4.411804e-9,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 1838,
    "lum": 0.00602559586074357,
    "var_min": 11.978,
    "var_max": 11.778,
    "ra": 0.388427,
    "dec": -50.893874,
    "pmra": 548.11,
    "pmdec": -0.91,
    "rv": 0,
    "Asset Name": "ASTRID 001831",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Braided Ponytail",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000537,
    "y_1": 0.00005201,
    "z_1": -5e-8,
    "Distance in Parsecs_1": 19.6773,
    "HYG Star ID_1": 1838,
    "Hipparcos Catalogue_1": 1842,
    "Gliese Catalog_1": "NN 3032"
  },
  {
    "Astrid #": "ASTRID 001832",
    "HYG Star ID": 41436,
    "Hipparcos Catalogue": 41554,
    "Gliese Catalog": "Gl 308A",
    "Distance in Parsecs": 19.6773,
    "mag": 10.76,
    "Absolute Visual Magnitude": 9.29,
    "Spectral Type": "M0V",
    "Color Index": 1.568,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.719224,
    "y": 12.854754,
    "z": 11.291064,
    "vx": 0.00006631,
    "vy": 0.00007272,
    "vz": -0.0000257,
    "rarad": 2.21818685187668,
    "decrad": 0.611152435547232,
    "pmrarad": -0.00000491727123640277,
    "pmdecrad": -0.000001594794602,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 41436,
    "base": "Gl 308",
    "lum": 0.0167494287602643,
    "var_min": 10.816,
    "var_max": 10.706,
    "ra": 8.47285,
    "dec": 35.016455,
    "pmra": -1014.26,
    "pmdec": -328.95,
    "rv": 0,
    "Asset Name": "ASTRID 001832",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Audiohead",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00006631,
    "y_1": 0.00007272,
    "z_1": -0.0000257,
    "Distance in Parsecs_1": 19.6773,
    "HYG Star ID_1": 41436,
    "Hipparcos Catalogue_1": 41554,
    "Gliese Catalog_1": "Gl 308A"
  },
  {
    "Astrid #": "ASTRID 001833",
    "HYG Star ID": 21712,
    "Hipparcos Catalogue": 21765,
    "Gliese Catalog": "Wo 9163A",
    "Distance in Parsecs": 19.685,
    "mag": 10.27,
    "Absolute Visual Magnitude": 8.799,
    "Spectral Type": "M0V...",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.607263,
    "y": 18.274199,
    "z": -3.145941,
    "vx": 0.00000943,
    "vy": -0.00000523,
    "vz": -0.00001059,
    "rarad": 1.22385838491546,
    "decrad": -0.160502347415183,
    "pmrarad": -5.40809660659722e-7,
    "pmdecrad": -5.45076021e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 21712,
    "base": "Wo 9163",
    "lum": 0.0263269167421849,
    "ra": 4.674795,
    "dec": -9.196107,
    "pmra": -111.55,
    "pmdec": -112.43,
    "rv": 0,
    "Asset Name": "ASTRID 001833",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Slick",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000943,
    "y_1": -0.00000523,
    "z_1": -0.00001059,
    "Distance in Parsecs_1": 19.685,
    "HYG Star ID_1": 21712,
    "Hipparcos Catalogue_1": 21765,
    "Gliese Catalog_1": "Wo 9163A"
  },
  {
    "Astrid #": "ASTRID 001834",
    "HYG Star ID": 117968,
    "Gliese Catalog": "NN 3016",
    "Distance in Parsecs": 19.685,
    "mag": 15.9,
    "Absolute Visual Magnitude": 14.429,
    "Spectral Type": "DC8",
    "Color Index": 0.58,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 19.129326,
    "y": 1.188055,
    "z": -4.489619,
    "vx": -0.00001233,
    "vy": -0.00005397,
    "vz": -0.0000669,
    "rarad": 0.0620304230174538,
    "decrad": -0.23009817807625,
    "pmrarad": -0.00000269793965092361,
    "pmdecrad": -0.000003490721525,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117968,
    "lum": 0.000147366917703668,
    "ra": 0.236939,
    "dec": -13.183654,
    "pmra": -556.49,
    "pmdec": -720.01,
    "rv": 0,
    "Asset Name": "ASTRID 001834",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Grecian",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001233,
    "y_1": -0.00005397,
    "z_1": -0.0000669,
    "Distance in Parsecs_1": 19.685,
    "HYG Star ID_1": 117968,
    "Gliese Catalog_1": "NN 3016"
  },
  {
    "Astrid #": "ASTRID 001835",
    "HYG Star ID": 118546,
    "Gliese Catalog": "NN 3504",
    "Distance in Parsecs": 19.685,
    "mag": 18.44,
    "Absolute Visual Magnitude": 16.969,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.149522,
    "y": 15.478864,
    "z": -0.541394,
    "vx": -0.00001146,
    "vy": -0.00001056,
    "vz": -0.0000451,
    "rarad": 2.23626833598546,
    "decrad": -0.0275063520154,
    "pmrarad": 7.89179709208333e-7,
    "pmdecrad": -0.000002292000308,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118546,
    "lum": 0.00001420365124048,
    "ra": 8.541916,
    "dec": -1.575998,
    "pmra": 162.78,
    "pmdec": -472.76,
    "rv": 0,
    "Asset Name": "ASTRID 001835",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Ninja",
    "Outfit": "Tats",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001146,
    "y_1": -0.00001056,
    "z_1": -0.0000451,
    "Distance in Parsecs_1": 19.685,
    "HYG Star ID_1": 118546,
    "Gliese Catalog_1": "NN 3504"
  },
  {
    "Astrid #": "ASTRID 001836",
    "HYG Star ID": 119155,
    "Gliese Catalog": "GJ 1219",
    "Distance in Parsecs": 19.685,
    "mag": 13.69,
    "Absolute Visual Magnitude": 12.219,
    "Spectral Type": "m",
    "Color Index": 1.76,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.680341,
    "y": -18.869957,
    "z": 4.923387,
    "vx": -0.00010477,
    "vy": 0.00000746,
    "vz": -0.00002832,
    "rarad": 4.5712987948653,
    "decrad": 0.2527923548198,
    "pmrarad": -0.00000532330269386805,
    "pmdecrad": -0.000001486283604,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119155,
    "lum": 0.00112823612164899,
    "ra": 17.461075,
    "dec": 14.483935,
    "pmra": -1098.01,
    "pmdec": -306.57,
    "rv": 0,
    "Asset Name": "ASTRID 001836",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Elegant",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00010477,
    "y_1": 0.00000746,
    "z_1": -0.00002832,
    "Distance in Parsecs_1": 19.685,
    "HYG Star ID_1": 119155,
    "Gliese Catalog_1": "GJ 1219"
  },
  {
    "Astrid #": "ASTRID 001837",
    "HYG Star ID": 119202,
    "Gliese Catalog": "Gl 705.2",
    "Distance in Parsecs": 19.685,
    "mag": 12.51,
    "Absolute Visual Magnitude": 11.039,
    "Spectral Type": "K5",
    "Color Index": 1.5,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.408051,
    "y": -11.895251,
    "z": 15.679149,
    "vx": -0.00003751,
    "vy": -0.00000605,
    "vz": -0.00000361,
    "rarad": 4.74674176532961,
    "decrad": 0.9214882778768,
    "pmrarad": -0.00000191535340777083,
    "pmdecrad": -3.03362464e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119202,
    "lum": 0.00334502986826013,
    "ra": 18.131218,
    "dec": 52.797389,
    "pmra": -395.07,
    "pmdec": -62.57,
    "rv": 0,
    "Asset Name": "ASTRID 001837",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Afro",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003751,
    "y_1": -0.00000605,
    "z_1": -0.00000361,
    "Distance in Parsecs_1": 19.685,
    "HYG Star ID_1": 119202,
    "Gliese Catalog_1": "Gl 705.2"
  },
  {
    "Astrid #": "ASTRID 001838",
    "HYG Star ID": 118587,
    "Gliese Catalog": "NN 3524",
    "Distance in Parsecs": 19.7239,
    "mag": 14.13,
    "Absolute Visual Magnitude": 12.655,
    "Spectral Type": "k-m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.290777,
    "y": 9.219984,
    "z": 14.754851,
    "vx": -0.00003834,
    "vy": 0.00007306,
    "vz": -0.00006976,
    "rarad": 2.3600508719178,
    "decrad": 0.84514838713705,
    "pmrarad": -0.00000126032164397222,
    "pmdecrad": -0.000005331457259,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118587,
    "lum": 0.000755092227665434,
    "ra": 9.01473,
    "dec": 48.423436,
    "pmra": -259.96,
    "pmdec": -1099.69,
    "rv": 0,
    "Asset Name": "ASTRID 001838",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Windy",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003834,
    "y_1": 0.00007306,
    "z_1": -0.00006976,
    "Distance in Parsecs_1": 19.7239,
    "HYG Star ID_1": 118587,
    "Gliese Catalog_1": "NN 3524"
  },
  {
    "Astrid #": "ASTRID 001839",
    "HYG Star ID": 10049,
    "Hipparcos Catalogue": 10072,
    "Gliese Catalog": "NN 3139",
    "Distance in Parsecs": 19.7316,
    "mag": 11.78,
    "Absolute Visual Magnitude": 10.304,
    "Color Index": 1.517,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.139393,
    "y": 10.242592,
    "z": -4.893398,
    "vx": -0.00003365,
    "vy": 0.0000374,
    "vz": -0.00003271,
    "rarad": 0.565495783481213,
    "decrad": -0.250613171682555,
    "pmrarad": 0.00000251434071009722,
    "pmdecrad": -0.000001711392292,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10049,
    "lum": 0.00658263841640901,
    "var_min": 11.892,
    "var_max": 11.702,
    "ra": 2.160035,
    "dec": -14.359077,
    "pmra": 518.62,
    "pmdec": -353,
    "rv": 0,
    "Asset Name": "ASTRID 001839",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Manbun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00003365,
    "y_1": 0.0000374,
    "z_1": -0.00003271,
    "Distance in Parsecs_1": 19.7316,
    "HYG Star ID_1": 10049,
    "Hipparcos Catalogue_1": 10072,
    "Gliese Catalog_1": "NN 3139"
  },
  {
    "Astrid #": "ASTRID 001840",
    "HYG Star ID": 41572,
    "Hipparcos Catalogue": 41689,
    "Gliese Catalog": "Gl 308.1",
    "Distance in Parsecs": 19.7355,
    "mag": 10.29,
    "Absolute Visual Magnitude": 8.814,
    "Spectral Type": "M0",
    "Color Index": 1.345,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.689059,
    "y": 7.418227,
    "z": 17.380865,
    "vx": -0.00007704,
    "vy": 0.00001704,
    "vz": -0.00003249,
    "rarad": 2.22500272953557,
    "decrad": 1.07731768139541,
    "pmrarad": 0.00000257251835176388,
    "pmdecrad": -0.000003475726238,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 41572,
    "lum": 0.0259656979027378,
    "var_min": 10.342,
    "var_max": 10.222,
    "ra": 8.498884,
    "dec": 61.725756,
    "pmra": 530.62,
    "pmdec": -716.92,
    "rv": 0,
    "Asset Name": "ASTRID 001840",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Firm",
    "Hair": "Honest Lad",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00007704,
    "y_1": 0.00001704,
    "z_1": -0.00003249,
    "Distance in Parsecs_1": 19.7355,
    "HYG Star ID_1": 41572,
    "Hipparcos Catalogue_1": 41689,
    "Gliese Catalog_1": "Gl 308.1"
  },
  {
    "Astrid #": "ASTRID 001841",
    "HYG Star ID": 107002,
    "Hipparcos Catalogue": 107346,
    "Gliese Catalog": "Gl 836.8",
    "Distance in Parsecs": 19.7394,
    "mag": 9.65,
    "Absolute Visual Magnitude": 8.173,
    "Spectral Type": "K0",
    "Color Index": 1.334,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.255984,
    "y": -8.227971,
    "z": 13.104908,
    "vx": 4.9e-7,
    "vy": -0.00000749,
    "vz": 0.00000948,
    "rarad": 5.69195306694681,
    "decrad": 0.72601272356821,
    "pmrarad": -3.01263221097222e-7,
    "pmdecrad": 2.04979224e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 107002,
    "lum": 0.0468597535308625,
    "var_min": 9.709,
    "var_max": 9.599,
    "ra": 21.741659,
    "dec": 41.597465,
    "pmra": -62.14,
    "pmdec": 42.28,
    "rv": 9.5,
    "Asset Name": "ASTRID 001841",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Heartbreaker",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 4.9e-7,
    "y_1": -0.00000749,
    "z_1": 0.00000948,
    "Distance in Parsecs_1": 19.7394,
    "HYG Star ID_1": 107002,
    "Hipparcos Catalogue_1": 107346,
    "Gliese Catalog_1": "Gl 836.8"
  },
  {
    "Astrid #": "ASTRID 001842",
    "HYG Star ID": 63277,
    "Hipparcos Catalogue": 63480,
    "Distance in Parsecs": 19.7433,
    "mag": 10.6,
    "Absolute Visual Magnitude": 9.123,
    "Spectral Type": "Mp",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.68848,
    "y": -4.23528,
    "z": -11.213022,
    "vx": -0.00000552,
    "vy": 0.00000423,
    "vz": 0.00000613,
    "rarad": 3.40526877560008,
    "decrad": -0.604001649903508,
    "pmrarad": -2.79931419152777e-7,
    "pmdecrad": 3.77039599e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 63277,
    "lum": 0.0195343965639825,
    "var_min": 10.659,
    "var_max": 10.549,
    "ra": 13.007169,
    "dec": -34.606745,
    "pmra": -57.74,
    "pmdec": 77.77,
    "rv": 0,
    "Asset Name": "ASTRID 001842",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Side Part",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000552,
    "y_1": 0.00000423,
    "z_1": 0.00000613,
    "Distance in Parsecs_1": 19.7433,
    "HYG Star ID_1": 63277,
    "Hipparcos Catalogue_1": 63480
  },
  {
    "Astrid #": "ASTRID 001843",
    "HYG Star ID": 31512,
    "Hipparcos Catalogue": 31592,
    "Henry Draper Catalogue": 47205,
    "Harvard Revised Catalogue": 2429,
    "Gliese Catalog": "Gl 239.1",
    "Distance in Parsecs": 19.7511,
    "mag": 3.95,
    "Absolute Visual Magnitude": 2.472,
    "Spectral Type": "K1III+...",
    "Color Index": 1.037,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.971851,
    "y": 18.407811,
    "z": -6.513678,
    "vx": -0.00000617,
    "vy": 7.8e-7,
    "vz": -0.0000077,
    "rarad": 1.730860123689,
    "decrad": -0.336078491665885,
    "pmrarad": 3.02135885722222e-7,
    "pmdecrad": -3.38933244e-7,
    "bayer": "Nu-2",
    "flam": 7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 31512,
    "lum": 8.93716960850197,
    "bf": "7Nu 2CMa",
    "ra": 6.611399,
    "dec": -19.255879,
    "pmra": 62.32,
    "pmdec": -69.91,
    "rv": 4.1,
    "Asset Name": "ASTRID 001843",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Chuckle",
    "Hair": "Windy",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00000617,
    "y_1": 7.8e-7,
    "z_1": -0.0000077,
    "Distance in Parsecs_1": 19.7511,
    "HYG Star ID_1": 31512,
    "Hipparcos Catalogue_1": 31592,
    "Henry Draper Catalogue_1": 47205,
    "Harvard Revised Catalogue_1": 2429,
    "Gliese Catalog_1": "Gl 239.1"
  },
  {
    "Astrid #": "ASTRID 001844",
    "HYG Star ID": 58895,
    "Hipparcos Catalogue": 59072,
    "Henry Draper Catalogue": 105211,
    "Harvard Revised Catalogue": 4616,
    "Gliese Catalog": "Gl 455.2",
    "Distance in Parsecs": 19.755,
    "mag": 4.14,
    "Absolute Visual Magnitude": 2.662,
    "Spectral Type": "F2III",
    "Color Index": 0.353,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.465553,
    "y": -0.254271,
    "z": -17.847491,
    "vx": -6e-7,
    "vy": -0.0000033,
    "vz": -0.00000985,
    "rarad": 3.17161760777442,
    "decrad": -1.12772233037067,
    "pmrarad": 1.66145648326388e-7,
    "pmdecrad": -1.81756648e-7,
    "bayer": "Eta",
    "con": "Cru",
    "Full Constellation Name Formula 1": "Crux",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crux",
    "comp": 1,
    "comp_primary": 58895,
    "lum": 7.50239627907463,
    "bf": "Eta Cru",
    "ra": 12.114687,
    "dec": -64.61373,
    "pmra": 34.27,
    "pmdec": -37.49,
    "rv": 9,
    "Asset Name": "ASTRID 001844",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Innocent",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crux",
    "x_1": -6e-7,
    "y_1": -0.0000033,
    "z_1": -0.00000985,
    "Distance in Parsecs_1": 19.755,
    "HYG Star ID_1": 58895,
    "Hipparcos Catalogue_1": 59072,
    "Henry Draper Catalogue_1": 105211,
    "Harvard Revised Catalogue_1": 4616,
    "Gliese Catalog_1": "Gl 455.2"
  },
  {
    "Astrid #": "ASTRID 001845",
    "HYG Star ID": 36598,
    "Hipparcos Catalogue": 36704,
    "Henry Draper Catalogue": 59747,
    "Distance in Parsecs": 19.7628,
    "mag": 7.68,
    "Absolute Visual Magnitude": 6.201,
    "Spectral Type": "G5",
    "Color Index": 0.863,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.228457,
    "y": 14.495441,
    "z": 11.901658,
    "vx": 0.00001128,
    "vy": -0.00001409,
    "vz": -0.0000121,
    "rarad": 1.97662811169802,
    "decrad": 0.64629270127702,
    "pmrarad": -2.42891653958333e-7,
    "pmdecrad": 4.1403088e-8,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 36598,
    "lum": 0.28813764348397,
    "var": "DX",
    "var_min": 7.72,
    "var_max": 7.65,
    "ra": 7.550163,
    "dec": 37.029844,
    "pmra": -50.1,
    "pmdec": 8.54,
    "rv": -20.7,
    "Asset Name": "ASTRID 001845",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Wavy",
    "Outfit": "River Spirit",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00001128,
    "y_1": -0.00001409,
    "z_1": -0.0000121,
    "Distance in Parsecs_1": 19.7628,
    "HYG Star ID_1": 36598,
    "Hipparcos Catalogue_1": 36704,
    "Henry Draper Catalogue_1": 59747
  },
  {
    "Astrid #": "ASTRID 001846",
    "HYG Star ID": 59116,
    "Hipparcos Catalogue": 59296,
    "Henry Draper Catalogue": 105671,
    "Gliese Catalog": "Gl 456.1A",
    "Distance in Parsecs": 19.7668,
    "mag": 8.46,
    "Absolute Visual Magnitude": 6.98,
    "Spectral Type": "K5V",
    "Color Index": 1.137,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.666537,
    "y": -0.591697,
    "z": -14.268867,
    "vx": 0.0000072,
    "vy": 0.00003701,
    "vz": -0.00000248,
    "rarad": 3.18486815849843,
    "decrad": -0.806488590167938,
    "pmrarad": -0.00000185480017906944,
    "pmdecrad": -4.08019193e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 59116,
    "base": "Gl 456.1",
    "lum": 0.140604752412991,
    "ra": 12.1653,
    "dec": -46.208392,
    "pmra": -382.58,
    "pmdec": -84.16,
    "rv": -4.2,
    "Asset Name": "ASTRID 001846",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Audiophile",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.0000072,
    "y_1": 0.00003701,
    "z_1": -0.00000248,
    "Distance in Parsecs_1": 19.7668,
    "HYG Star ID_1": 59116,
    "Hipparcos Catalogue_1": 59296,
    "Henry Draper Catalogue_1": 105671,
    "Gliese Catalog_1": "Gl 456.1A"
  },
  {
    "Astrid #": "ASTRID 001847",
    "HYG Star ID": 104102,
    "Hipparcos Catalogue": 104440,
    "Henry Draper Catalogue": 200525,
    "Harvard Revised Catalogue": 8061,
    "Gliese Catalog": "Gl 818.1A",
    "Distance in Parsecs": 19.7668,
    "mag": 5.67,
    "Absolute Visual Magnitude": 4.19,
    "Spectral Type": "G3IV",
    "Color Index": 0.59,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.208227,
    "y": -3.877326,
    "z": -18.920359,
    "vx": 0.00000838,
    "vy": 0.00005239,
    "vz": 0.00000192,
    "rarad": 5.53864137332241,
    "decrad": -1.27710921506464,
    "pmrarad": 0.00000223649398977083,
    "pmdecrad": -0.000001392578816,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 104102,
    "base": "Gl 818.1",
    "lum": 1.83653834334834,
    "ra": 21.156052,
    "dec": -73.172968,
    "pmra": 461.31,
    "pmdec": -287.24,
    "rv": -10.1,
    "Asset Name": "ASTRID 001847",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Greasy",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00000838,
    "y_1": 0.00005239,
    "z_1": 0.00000192,
    "Distance in Parsecs_1": 19.7668,
    "HYG Star ID_1": 104102,
    "Hipparcos Catalogue_1": 104440,
    "Henry Draper Catalogue_1": 200525,
    "Harvard Revised Catalogue_1": 8061,
    "Gliese Catalog_1": "Gl 818.1A"
  },
  {
    "Astrid #": "ASTRID 001848",
    "HYG Star ID": 118217,
    "Gliese Catalog": "Gl 140B",
    "Distance in Parsecs": 19.7863,
    "mag": 12,
    "Absolute Visual Magnitude": 10.518,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 11.388284,
    "y": 14.07548,
    "z": 7.980321,
    "vx": -0.00001107,
    "vy": 0.00001476,
    "vz": -0.00001023,
    "rarad": 0.89053506484419,
    "decrad": 0.41514828272575,
    "pmrarad": 9.045168838125e-7,
    "pmdecrad": -5.65205485e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 2,
    "comp_primary": 15805,
    "base": "Gl 140",
    "lum": 0.00540505353708668,
    "ra": 3.401593,
    "dec": 23.786244,
    "pmra": 186.57,
    "pmdec": -116.58,
    "rv": 0,
    "Asset Name": "ASTRID 001848",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Greasy",
    "Outfit": "Emperor",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00001107,
    "y_1": 0.00001476,
    "z_1": -0.00001023,
    "Distance in Parsecs_1": 19.7863,
    "HYG Star ID_1": 118217,
    "Gliese Catalog_1": "Gl 140B"
  },
  {
    "Astrid #": "ASTRID 001849",
    "HYG Star ID": 118218,
    "Gliese Catalog": "Gl 140C",
    "Distance in Parsecs": 19.7863,
    "mag": 11.89,
    "Absolute Visual Magnitude": 10.408,
    "Spectral Type": "m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.38215,
    "y": 14.082549,
    "z": 7.9766,
    "vx": -0.00001108,
    "vy": 0.00001476,
    "vz": -0.00001023,
    "rarad": 0.89104390184419,
    "decrad": 0.41494275772575,
    "pmrarad": 9.045168838125e-7,
    "pmdecrad": -5.65205485e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 3,
    "comp_primary": 15805,
    "base": "Gl 140",
    "lum": 0.00598136079781274,
    "ra": 3.403537,
    "dec": 23.774469,
    "pmra": 186.57,
    "pmdec": -116.58,
    "rv": 0,
    "Asset Name": "ASTRID 001849",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Side Part",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00001108,
    "y_1": 0.00001476,
    "z_1": -0.00001023,
    "Distance in Parsecs_1": 19.7863,
    "HYG Star ID_1": 118218,
    "Gliese Catalog_1": "Gl 140C"
  },
  {
    "Astrid #": "ASTRID 001850",
    "HYG Star ID": 43666,
    "Hipparcos Catalogue": 43790,
    "Gliese Catalog": "Gl 328",
    "Distance in Parsecs": 19.7941,
    "mag": 9.99,
    "Absolute Visual Magnitude": 8.507,
    "Spectral Type": "M1",
    "Color Index": 1.372,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.690794,
    "y": 14.285729,
    "z": 0.53421,
    "vx": -0.00000286,
    "vy": -0.00000338,
    "vz": -0.00010026,
    "rarad": 2.33493218416346,
    "decrad": 0.0269916585439225,
    "pmrarad": 2.22190109798611e-7,
    "pmdecrad": -0.000005062909266,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 43666,
    "lum": 0.0344508546286579,
    "ra": 8.918784,
    "dec": 1.546508,
    "pmra": 45.83,
    "pmdec": -1044.3,
    "rv": -3.1,
    "Asset Name": "ASTRID 001850",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Wavy",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000286,
    "y_1": -0.00000338,
    "z_1": -0.00010026,
    "Distance in Parsecs_1": 19.7941,
    "HYG Star ID_1": 43666,
    "Hipparcos Catalogue_1": 43790,
    "Gliese Catalog_1": "Gl 328"
  },
  {
    "Astrid #": "ASTRID 001851",
    "HYG Star ID": 117997,
    "Gliese Catalog": "NN 3040",
    "Distance in Parsecs": 19.802,
    "mag": 13.52,
    "Absolute Visual Magnitude": 12.036,
    "Spectral Type": "k-m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.310043,
    "y": 0.959658,
    "z": 18.745175,
    "vx": 0.00001915,
    "vy": 0.00005251,
    "vz": -0.00000913,
    "rarad": 0.150667371775039,
    "decrad": 1.24261600122455,
    "pmrarad": 0.00000247715550079861,
    "pmdecrad": -0.000001430195509,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117997,
    "lum": 0.00133536503332718,
    "ra": 0.575507,
    "dec": 71.196652,
    "pmra": 510.95,
    "pmdec": -295,
    "rv": 0,
    "Asset Name": "ASTRID 001851",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Shinobi",
    "Hair": "Lion Mane",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001915,
    "y_1": 0.00005251,
    "z_1": -0.00000913,
    "Distance in Parsecs_1": 19.802,
    "HYG Star ID_1": 117997,
    "Gliese Catalog_1": "NN 3040"
  },
  {
    "Astrid #": "ASTRID 001852",
    "HYG Star ID": 118836,
    "Gliese Catalog": "GJ 1162",
    "Distance in Parsecs": 19.802,
    "mag": 13.52,
    "Absolute Visual Magnitude": 12.036,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.463563,
    "y": -3.325551,
    "z": -1.49319,
    "vx": -0.00000992,
    "vy": 0.00006726,
    "vz": -0.00002059,
    "rarad": 3.3108193602608,
    "decrad": -0.07547771431705,
    "pmrarad": -0.00000343267478380555,
    "pmdecrad": -0.000001042926341,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118836,
    "lum": 0.00133536503332718,
    "ra": 12.646398,
    "dec": -4.324554,
    "pmra": -708.04,
    "pmdec": -215.12,
    "rv": 0,
    "Asset Name": "ASTRID 001852",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Charmer",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000992,
    "y_1": 0.00006726,
    "z_1": -0.00002059,
    "Distance in Parsecs_1": 19.802,
    "HYG Star ID_1": 118836,
    "Gliese Catalog_1": "GJ 1162"
  },
  {
    "Astrid #": "ASTRID 001853",
    "HYG Star ID": 118986,
    "Gliese Catalog": "GJ 1185",
    "Distance in Parsecs": 19.802,
    "mag": 13.28,
    "Absolute Visual Magnitude": 11.796,
    "Spectral Type": "m",
    "Color Index": 1.63,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.697983,
    "y": -13.224805,
    "z": -1.092261,
    "vx": -0.00003512,
    "vy": 0.00003596,
    "vz": 0.00003697,
    "rarad": 3.8742808875893,
    "decrad": -0.0551871173488,
    "pmrarad": -0.00000253644821393055,
    "pmdecrad": 0.000001870033024,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118986,
    "lum": 0.00166571232806881,
    "ra": 14.798663,
    "dec": -3.161989,
    "pmra": -523.18,
    "pmdec": 385.72,
    "rv": 0,
    "Asset Name": "ASTRID 001853",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Slick",
    "Outfit": "Elven",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003512,
    "y_1": 0.00003596,
    "z_1": 0.00003697,
    "Distance in Parsecs_1": 19.802,
    "HYG Star ID_1": 118986,
    "Gliese Catalog_1": "GJ 1185"
  },
  {
    "Astrid #": "ASTRID 001854",
    "HYG Star ID": 104814,
    "Hipparcos Catalogue": 105152,
    "Henry Draper Catalogue": 202751,
    "Gliese Catalog": "Gl 825.3",
    "Distance in Parsecs": 19.8177,
    "mag": 8.15,
    "Absolute Visual Magnitude": 6.665,
    "Spectral Type": "K2",
    "Color Index": 0.99,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.072038,
    "y": -12.867099,
    "z": 0.055887,
    "vx": 0.00000838,
    "vy": 0.00005211,
    "vz": -0.00001803,
    "rarad": 5.57654328963195,
    "decrad": 0.00282009144775875,
    "pmrarad": 0.00000227435793822222,
    "pmdecrad": -9.05777399e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 104814,
    "lum": 0.187931681680326,
    "ra": 21.300826,
    "dec": 0.161579,
    "pmra": 469.12,
    "pmdec": -186.83,
    "rv": -26.9,
    "Asset Name": "ASTRID 001854",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Battle Ready",
    "Hair": "Afro",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000838,
    "y_1": 0.00005211,
    "z_1": -0.00001803,
    "Distance in Parsecs_1": 19.8177,
    "HYG Star ID_1": 104814,
    "Hipparcos Catalogue_1": 105152,
    "Henry Draper Catalogue_1": 202751,
    "Gliese Catalog_1": "Gl 825.3"
  },
  {
    "Astrid #": "ASTRID 001855",
    "HYG Star ID": 114357,
    "Hipparcos Catalogue": 114719,
    "Gliese Catalog": "GJ 2154A",
    "Distance in Parsecs": 19.8334,
    "mag": 10.46,
    "Absolute Visual Magnitude": 8.973,
    "Spectral Type": "K7V",
    "Color Index": 1.449,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.308559,
    "y": -3.701862,
    "z": -6.667582,
    "vx": 0.00001037,
    "vy": 0.00004182,
    "vz": 0.00000524,
    "rarad": 6.08368081296991,
    "decrad": -0.342857340085335,
    "pmrarad": 0.00000217051084784722,
    "pmdecrad": 2.80561676e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 114357,
    "base": "GJ 2154",
    "lum": 0.0224284881586928,
    "var_min": 10.53,
    "var_max": 10.37,
    "ra": 23.237949,
    "dec": -19.644279,
    "pmra": 447.7,
    "pmdec": 57.87,
    "rv": 0,
    "Asset Name": "ASTRID 001855",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Neko Ninja",
    "Hair": "Braided Bun",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001037,
    "y_1": 0.00004182,
    "z_1": 0.00000524,
    "Distance in Parsecs_1": 19.8334,
    "HYG Star ID_1": 114357,
    "Hipparcos Catalogue_1": 114719,
    "Gliese Catalog_1": "GJ 2154A"
  },
  {
    "Astrid #": "ASTRID 001856",
    "HYG Star ID": 68352,
    "Hipparcos Catalogue": 68570,
    "Gliese Catalog": "NN 3822",
    "Distance in Parsecs": 19.857,
    "mag": 10.68,
    "Absolute Visual Magnitude": 9.19,
    "Spectral Type": "M0",
    "Color Index": 1.559,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.609253,
    "y": -9.815551,
    "z": 4.699383,
    "vx": 0.00001139,
    "vy": -0.00000407,
    "vz": -0.00001578,
    "rarad": 3.67534498988763,
    "decrad": 0.238929246699757,
    "pmrarad": 4.68330015416666e-7,
    "pmdecrad": -6.79805742e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 68352,
    "lum": 0.0183653834334834,
    "var_min": 10.745,
    "var_max": 10.605,
    "ra": 14.038784,
    "dec": 13.689637,
    "pmra": 96.6,
    "pmdec": -140.22,
    "rv": -11,
    "Asset Name": "ASTRID 001856",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Fired Up",
    "Hair": "Pixie",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001139,
    "y_1": -0.00000407,
    "z_1": -0.00001578,
    "Distance in Parsecs_1": 19.857,
    "HYG Star ID_1": 68352,
    "Hipparcos Catalogue_1": 68570,
    "Gliese Catalog_1": "NN 3822"
  },
  {
    "Astrid #": "ASTRID 001857",
    "HYG Star ID": 113061,
    "Hipparcos Catalogue": 113421,
    "Henry Draper Catalogue": 217107,
    "Harvard Revised Catalogue": 8734,
    "Distance in Parsecs": 19.857,
    "mag": 6.17,
    "Absolute Visual Magnitude": 4.68,
    "Spectral Type": "G8IV",
    "Color Index": 0.744,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.123967,
    "y": -5.280276,
    "z": -0.829922,
    "vx": -0.00001204,
    "vy": 0.00000272,
    "vz": -0.00000103,
    "rarad": 6.01378946235175,
    "decrad": -0.04180735601178,
    "pmrarad": -2.93312276736111e-8,
    "pmdecrad": -7.7715632e-8,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 113061,
    "lum": 1.16949939101987,
    "ra": 22.970984,
    "dec": -2.395385,
    "pmra": -6.05,
    "pmdec": -16.03,
    "rv": -12,
    "Asset Name": "ASTRID 001857",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Angelic",
    "Outfit": "River Spirit",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001204,
    "y_1": 0.00000272,
    "z_1": -0.00000103,
    "Distance in Parsecs_1": 19.857,
    "HYG Star ID_1": 113061,
    "Hipparcos Catalogue_1": 113421,
    "Henry Draper Catalogue_1": 217107,
    "Harvard Revised Catalogue_1": 8734
  },
  {
    "Astrid #": "ASTRID 001858",
    "HYG Star ID": 83734,
    "Hipparcos Catalogue": 83988,
    "Henry Draper Catalogue": 155674,
    "Gliese Catalog": "Gl 659A",
    "Distance in Parsecs": 19.861,
    "mag": 8.85,
    "Absolute Visual Magnitude": 7.36,
    "Spectral Type": "K0",
    "Color Index": 1.152,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.488009,
    "y": -11.263427,
    "z": 16.168049,
    "vx": 0.00000578,
    "vy": -0.00001225,
    "vz": -0.00000237,
    "rarad": 4.49498462276821,
    "decrad": 0.951106546289072,
    "pmrarad": 4.17230653486111e-7,
    "pmdecrad": -5.08424106e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 83734,
    "base": "Gl 659",
    "lum": 0.0990831944892767,
    "var_min": 8.903,
    "var_max": 8.593,
    "ra": 17.169577,
    "dec": 54.494391,
    "pmra": 86.06,
    "pmdec": -104.87,
    "rv": 4.2,
    "Asset Name": "ASTRID 001858",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Slick",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000578,
    "y_1": -0.00001225,
    "z_1": -0.00000237,
    "Distance in Parsecs_1": 19.861,
    "HYG Star ID_1": 83734,
    "Hipparcos Catalogue_1": 83988,
    "Henry Draper Catalogue_1": 155674,
    "Gliese Catalog_1": "Gl 659A"
  },
  {
    "Astrid #": "ASTRID 001859",
    "HYG Star ID": 119022,
    "Gliese Catalog": "Gl 586C",
    "Distance in Parsecs": 19.8649,
    "mag": 15.41,
    "Absolute Visual Magnitude": 13.92,
    "Spectral Type": "k-m",
    "Color Index": 1.84,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.095489,
    "y": -15.446549,
    "z": -3.1173,
    "vx": 0.00000613,
    "vy": 0.00000109,
    "vz": -0.0000292,
    "rarad": 4.04806633043109,
    "decrad": -0.157576312779,
    "pmrarad": 2.09148621791666e-7,
    "pmdecrad": -0.00000148829558,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 75489,
    "base": "Gl 586",
    "lum": 0.0002355049283896,
    "ra": 15.462474,
    "dec": -9.028458,
    "pmra": 43.14,
    "pmdec": -306.98,
    "rv": 0,
    "Asset Name": "ASTRID 001859",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Naughty Dreams",
    "Hair": "Goddess",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000613,
    "y_1": 0.00000109,
    "z_1": -0.0000292,
    "Distance in Parsecs_1": 19.8649,
    "HYG Star ID_1": 119022,
    "Gliese Catalog_1": "Gl 586C"
  },
  {
    "Astrid #": "ASTRID 001860",
    "HYG Star ID": 118920,
    "Gliese Catalog": "GJ 1178",
    "Distance in Parsecs": 19.8807,
    "mag": 15.08,
    "Absolute Visual Magnitude": 13.588,
    "Spectral Type": "DA7",
    "Color Index": 0.38,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -17.448109,
    "y": -8.833044,
    "z": 3.575344,
    "vx": -0.00003974,
    "vy": 0.00007417,
    "vz": -0.0000106,
    "rarad": 3.61022846234132,
    "decrad": 0.18082375003675,
    "pmrarad": -0.00000423179317346527,
    "pmdecrad": -5.42099265e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118920,
    "lum": 0.000319742230320492,
    "ra": 13.790057,
    "dec": 10.360438,
    "pmra": -872.87,
    "pmdec": -111.82,
    "rv": 0,
    "Asset Name": "ASTRID 001860",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Star of Culture",
    "Hair": "Skater Helmet",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003974,
    "y_1": 0.00007417,
    "z_1": -0.0000106,
    "Distance in Parsecs_1": 19.8807,
    "HYG Star ID_1": 118920,
    "Gliese Catalog_1": "GJ 1178"
  },
  {
    "Astrid #": "ASTRID 001861",
    "HYG Star ID": 24723,
    "Hipparcos Catalogue": 24783,
    "Henry Draper Catalogue": 34751,
    "Gliese Catalog": "Gl 199A",
    "Distance in Parsecs": 19.8886,
    "mag": 9.35,
    "Absolute Visual Magnitude": 7.857,
    "Spectral Type": "K4V",
    "Color Index": 1.298,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.312207,
    "y": 18.219798,
    "z": -7.254962,
    "vx": 0.00001768,
    "vy": 0.0000221,
    "vz": -0.00001381,
    "rarad": 1.3909689766367,
    "decrad": -0.373391567244586,
    "pmrarad": -6.75927233430555e-7,
    "pmdecrad": -1.89513667e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 24723,
    "base": "Gl 199",
    "lum": 0.0626902497476164,
    "ra": 5.31311,
    "dec": -21.393761,
    "pmra": -139.42,
    "pmdec": -39.09,
    "rv": 27.6,
    "Asset Name": "ASTRID 001861",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Greasy",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00001768,
    "y_1": 0.0000221,
    "z_1": -0.00001381,
    "Distance in Parsecs_1": 19.8886,
    "HYG Star ID_1": 24723,
    "Hipparcos Catalogue_1": 24783,
    "Henry Draper Catalogue_1": 34751,
    "Gliese Catalog_1": "Gl 199A"
  },
  {
    "Astrid #": "ASTRID 001862",
    "HYG Star ID": 70634,
    "Hipparcos Catalogue": 70857,
    "Henry Draper Catalogue": 128642,
    "Gliese Catalog": "NN 3859",
    "Distance in Parsecs": 19.8926,
    "mag": 6.88,
    "Absolute Visual Magnitude": 5.387,
    "Spectral Type": "G5",
    "Color Index": 0.774,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.525837,
    "y": -1.927164,
    "z": 19.637253,
    "vx": -0.00001437,
    "vy": -0.00000202,
    "vz": -0.00000205,
    "rarad": 3.79336592928514,
    "decrad": 1.41039799395028,
    "pmrarad": -3.57453126673611e-7,
    "pmdecrad": -6.44462825e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 70634,
    "lum": 0.609817663998785,
    "ra": 14.489591,
    "dec": 80.809852,
    "pmra": -73.73,
    "pmdec": -132.93,
    "rv": 0,
    "Asset Name": "ASTRID 001862",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Hurt",
    "Hair": "Audiophile",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": -0.00001437,
    "y_1": -0.00000202,
    "z_1": -0.00000205,
    "Distance in Parsecs_1": 19.8926,
    "HYG Star ID_1": 70634,
    "Hipparcos Catalogue_1": 70857,
    "Henry Draper Catalogue_1": 128642,
    "Gliese Catalog_1": "NN 3859"
  },
  {
    "Astrid #": "ASTRID 001863",
    "HYG Star ID": 50663,
    "Hipparcos Catalogue": 50808,
    "Gliese Catalog": "Gl 389A",
    "Distance in Parsecs": 19.9005,
    "mag": 10.52,
    "Absolute Visual Magnitude": 9.026,
    "Spectral Type": "M",
    "Color Index": 1.44,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.013244,
    "y": 4.088115,
    "z": -17.265209,
    "vx": 0.00000632,
    "vy": -0.00003932,
    "vz": -0.00004173,
    "rarad": 2.71576114610338,
    "decrad": -1.05028909698489,
    "pmrarad": 0.00000166858324436805,
    "pmdecrad": -0.000002001941131,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 50663,
    "base": "Gl 389",
    "lum": 0.0213599386018979,
    "var_min": 10.59,
    "var_max": 10.41,
    "ra": 10.373443,
    "dec": -60.177133,
    "pmra": 344.17,
    "pmdec": -412.93,
    "rv": 24.7,
    "Asset Name": "ASTRID 001863",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Shinobi",
    "Hair": "Slick",
    "Outfit": "Mecha",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00000632,
    "y_1": -0.00003932,
    "z_1": -0.00004173,
    "Distance in Parsecs_1": 19.9005,
    "HYG Star ID_1": 50663,
    "Hipparcos Catalogue_1": 50808,
    "Gliese Catalog_1": "Gl 389A"
  },
  {
    "Astrid #": "ASTRID 001864",
    "HYG Star ID": 118551,
    "Gliese Catalog": "Gl 314B",
    "Distance in Parsecs": 19.9203,
    "mag": 6.8,
    "Absolute Visual Magnitude": 5.304,
    "Spectral Type": "K0 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.763138,
    "y": 14.126141,
    "z": -7.674575,
    "vx": 0.00000742,
    "vy": 0.00002694,
    "vz": 0.00003819,
    "rarad": 2.26517808583102,
    "decrad": -0.39549387739735,
    "pmrarad": -0.00000115172337952777,
    "pmdecrad": 0.000002077732053,
    "con": "Pyx",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pyxis",
    "FULL CONSTELLATION NAME": "Pyxis",
    "comp": 2,
    "comp_primary": 42311,
    "base": "Gl 314",
    "lum": 0.658263841640901,
    "ra": 8.652343,
    "dec": -22.66013,
    "pmra": -237.56,
    "pmdec": 428.56,
    "rv": 0,
    "Asset Name": "ASTRID 001864",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Angry",
    "Hair": "Spiky",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pyxis",
    "x_1": 0.00000742,
    "y_1": 0.00002694,
    "z_1": 0.00003819,
    "Distance in Parsecs_1": 19.9203,
    "HYG Star ID_1": 118551,
    "Gliese Catalog_1": "Gl 314B"
  },
  {
    "Astrid #": "ASTRID 001865",
    "HYG Star ID": 56659,
    "Hipparcos Catalogue": 56829,
    "Henry Draper Catalogue": 101206,
    "Gliese Catalog": "NN 3678",
    "Distance in Parsecs": 19.9243,
    "mag": 8.22,
    "Absolute Visual Magnitude": 6.723,
    "Spectral Type": "K5V",
    "Color Index": 0.98,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.668165,
    "y": 1.34812,
    "z": 13.4168,
    "vx": 0.00001828,
    "vy": 0.00001097,
    "vz": 0.00004136,
    "rarad": 3.04994421957905,
    "decrad": 0.738777040307236,
    "pmrarad": -6.32390964916666e-7,
    "pmdecrad": 0.000002115920827,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 56659,
    "lum": 0.178155814066289,
    "var": "MV",
    "var_min": 8.254,
    "var_max": 8.204,
    "ra": 11.649929,
    "dec": 42.328806,
    "pmra": -130.44,
    "pmdec": 436.44,
    "rv": 14.8,
    "Asset Name": "ASTRID 001865",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Honest Lad",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001828,
    "y_1": 0.00001097,
    "z_1": 0.00004136,
    "Distance in Parsecs_1": 19.9243,
    "HYG Star ID_1": 56659,
    "Hipparcos Catalogue_1": 56829,
    "Henry Draper Catalogue_1": 101206,
    "Gliese Catalog_1": "NN 3678"
  },
  {
    "Astrid #": "ASTRID 001866",
    "HYG Star ID": 119365,
    "Gliese Catalog": "Gl 800B",
    "Distance in Parsecs": 19.9322,
    "mag": 14,
    "Absolute Visual Magnitude": 12.502,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.305525,
    "y": -14.286238,
    "z": -6.462978,
    "vx": 0.00002916,
    "vy": 0.00006188,
    "vz": -0.00008135,
    "rarad": 5.42342956456665,
    "decrad": -0.33021679321045,
    "pmrarad": 0.00000313461133299999,
    "pmdecrad": -0.000004314444209,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 101904,
    "base": "Gl 800",
    "lum": 0.000869360692509415,
    "ra": 20.715975,
    "dec": -18.920029,
    "pmra": 646.56,
    "pmdec": -889.92,
    "rv": 0,
    "Asset Name": "ASTRID 001866",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Oh No",
    "Hair": "Fire",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002916,
    "y_1": 0.00006188,
    "z_1": -0.00008135,
    "Distance in Parsecs_1": 19.9322,
    "HYG Star ID_1": 119365,
    "Gliese Catalog_1": "Gl 800B"
  },
  {
    "Astrid #": "ASTRID 001867",
    "HYG Star ID": 9025,
    "Hipparcos Catalogue": 9044,
    "Henry Draper Catalogue": 12058,
    "Gliese Catalog": "Gl 81.2",
    "Distance in Parsecs": 19.9362,
    "mag": 8.61,
    "Absolute Visual Magnitude": 7.112,
    "Spectral Type": "K5V",
    "Color Index": 1.143,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.647134,
    "y": 4.819931,
    "z": -17.304553,
    "vx": -0.0000069,
    "vy": 0.00004558,
    "vz": 0.00000877,
    "rarad": 0.508489311124509,
    "decrad": -1.05115312863989,
    "pmrarad": 0.00000216561422967361,
    "pmdecrad": 9.22212583e-7,
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 9025,
    "lum": 0.124508786391605,
    "ra": 1.942286,
    "dec": -60.226638,
    "pmra": 446.69,
    "pmdec": 190.22,
    "rv": 0.4,
    "Asset Name": "ASTRID 001867",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Fedora",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": -0.0000069,
    "y_1": 0.00004558,
    "z_1": 0.00000877,
    "Distance in Parsecs_1": 19.9362,
    "HYG Star ID_1": 9025,
    "Hipparcos Catalogue_1": 9044,
    "Henry Draper Catalogue_1": 12058,
    "Gliese Catalog_1": "Gl 81.2"
  },
  {
    "Astrid #": "ASTRID 001868",
    "HYG Star ID": 107813,
    "Hipparcos Catalogue": 108156,
    "Henry Draper Catalogue": 208313,
    "Gliese Catalog": "Gl 840",
    "Distance in Parsecs": 19.9561,
    "mag": 7.73,
    "Absolute Visual Magnitude": 6.23,
    "Spectral Type": "K0",
    "Color Index": 0.911,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.406602,
    "y": -8.763599,
    "z": 10.671933,
    "vx": 0.00001072,
    "vy": 0.00001733,
    "vz": -0.00002663,
    "rarad": 5.73668035147988,
    "decrad": 0.564239980316821,
    "pmrarad": 0.00000102096912988194,
    "pmdecrad": -0.000001131506649,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 107813,
    "lum": 0.280543363795171,
    "var": "V402",
    "var_min": 7.744,
    "var_max": 7.704,
    "ra": 21.912505,
    "dec": 32.32857,
    "pmra": 210.59,
    "pmdec": -233.39,
    "rv": -13.8,
    "Asset Name": "ASTRID 001868",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Good Boy",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001072,
    "y_1": 0.00001733,
    "z_1": -0.00002663,
    "Distance in Parsecs_1": 19.9561,
    "HYG Star ID_1": 107813,
    "Hipparcos Catalogue_1": 108156,
    "Henry Draper Catalogue_1": 208313,
    "Gliese Catalog_1": "Gl 840"
  },
  {
    "Astrid #": "ASTRID 001869",
    "HYG Star ID": 83954,
    "Hipparcos Catalogue": 84212,
    "Gliese Catalog": "Gl 660.1",
    "Distance in Parsecs": 19.9601,
    "mag": 11.61,
    "Absolute Visual Magnitude": 10.109,
    "Spectral Type": "K",
    "Color Index": 1.49,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.060809,
    "y": -19.461136,
    "z": -1.783142,
    "vx": 0.00001914,
    "vy": 0.00000214,
    "vz": -0.00006699,
    "rarad": 4.50667812057572,
    "decrad": -0.08945460427722,
    "pmrarad": 9.16637225826388e-7,
    "pmdecrad": -0.000003369745968,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83954,
    "lum": 0.00787771019585679,
    "var_min": 11.677,
    "var_max": 11.517,
    "ra": 17.214242,
    "dec": -5.125371,
    "pmra": 189.07,
    "pmdec": -695.06,
    "rv": 0,
    "Asset Name": "ASTRID 001869",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Oh No",
    "Hair": "Ponytail",
    "Outfit": "Gas Punk",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00001914,
    "y_1": 0.00000214,
    "z_1": -0.00006699,
    "Distance in Parsecs_1": 19.9601,
    "HYG Star ID_1": 83954,
    "Hipparcos Catalogue_1": 84212,
    "Gliese Catalog_1": "Gl 660.1"
  },
  {
    "Astrid #": "ASTRID 001870",
    "HYG Star ID": 119239,
    "Gliese Catalog": "NN 4076",
    "Distance in Parsecs": 19.9601,
    "mag": 15.11,
    "Absolute Visual Magnitude": 13.609,
    "Spectral Type": "M1",
    "Color Index": 1.85,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.417273,
    "y": -11.91899,
    "z": 15.827194,
    "vx": -0.00007617,
    "vy": 0.00001581,
    "vz": 0.00002356,
    "rarad": 4.91259847758571,
    "decrad": 0.91562181535045,
    "pmrarad": -0.000003582288285625,
    "pmdecrad": 0.000001936947009,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119239,
    "lum": 0.000313617291651298,
    "ra": 18.764744,
    "dec": 52.461266,
    "pmra": -738.9,
    "pmdec": 399.52,
    "rv": 0,
    "Asset Name": "ASTRID 001870",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Medium Bob",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00007617,
    "y_1": 0.00001581,
    "z_1": 0.00002356,
    "Distance in Parsecs_1": 19.9601,
    "HYG Star ID_1": 119239,
    "Gliese Catalog_1": "NN 4076"
  },
  {
    "Astrid #": "ASTRID 001871",
    "HYG Star ID": 86440,
    "Hipparcos Catalogue": 86707,
    "Gliese Catalog": "NN 4024",
    "Distance in Parsecs": 19.9641,
    "mag": 10.68,
    "Absolute Visual Magnitude": 9.179,
    "Spectral Type": "M2",
    "Color Index": 1.507,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.470811,
    "y": -19.807741,
    "z": 2.013799,
    "vx": 0.00002174,
    "vy": -0.00000221,
    "vz": -0.00000583,
    "rarad": 4.63827063688838,
    "decrad": 0.101042848820732,
    "pmrarad": 0.00000109427295838194,
    "pmdecrad": -2.93506202e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 86440,
    "lum": 0.0185523957554928,
    "ra": 17.716889,
    "dec": 5.789329,
    "pmra": 225.71,
    "pmdec": -60.54,
    "rv": 0,
    "Asset Name": "ASTRID 001871",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Braid",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00002174,
    "y_1": -0.00000221,
    "z_1": -0.00000583,
    "Distance in Parsecs_1": 19.9641,
    "HYG Star ID_1": 86440,
    "Hipparcos Catalogue_1": 86707,
    "Gliese Catalog_1": "NN 4024"
  },
  {
    "Astrid #": "ASTRID 001872",
    "HYG Star ID": 118317,
    "Gliese Catalog": "Gl 171.1B",
    "Distance in Parsecs": 19.9641,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.999,
    "Spectral Type": "dM2",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 6.865798,
    "y": 17.867185,
    "z": 5.673608,
    "vx": -0.00000399,
    "vy": 0.00000711,
    "vz": -0.00001756,
    "rarad": 1.20392394911511,
    "decrad": 0.28816205350495,
    "pmrarad": 3.14062302263888e-7,
    "pmdecrad": -9.17349901e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 21368,
    "base": "Gl 171.1",
    "lum": 0.00138165623116732,
    "ra": 4.598651,
    "dec": 16.510469,
    "pmra": 64.78,
    "pmdec": -189.22,
    "rv": 0,
    "Asset Name": "ASTRID 001872",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Neko Ninja",
    "Hair": "Ram Horns",
    "Outfit": "Toga",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000399,
    "y_1": 0.00000711,
    "z_1": -0.00001756,
    "Distance in Parsecs_1": 19.9641,
    "HYG Star ID_1": 118317,
    "Gliese Catalog_1": "Gl 171.1B"
  },
  {
    "Astrid #": "ASTRID 001873",
    "HYG Star ID": 39790,
    "Hipparcos Catalogue": 39903,
    "Henry Draper Catalogue": 68456,
    "Harvard Revised Catalogue": 3220,
    "Gliese Catalog": "Gl 297.1",
    "Distance in Parsecs": 19.98,
    "mag": 4.74,
    "Absolute Visual Magnitude": 3.237,
    "Spectral Type": "F5V",
    "Color Index": 0.437,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.120016,
    "y": 8.113858,
    "z": -17.525983,
    "vx": 0.000019640000000000002,
    "vy": -0.00000299,
    "vz": -0.00003627,
    "rarad": 2.1337208510176,
    "decrad": -1.06992923036614,
    "pmrarad": -7.51655130333333e-7,
    "pmdecrad": -0.000001443241845,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 39790,
    "lum": 4.41773844889647,
    "ra": 8.150213,
    "dec": -61.302429,
    "pmra": -155.04,
    "pmdec": -297.69,
    "rv": 25,
    "Asset Name": "ASTRID 001873",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Pompadour",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.000019640000000000002,
    "y_1": -0.00000299,
    "z_1": -0.00003627,
    "Distance in Parsecs_1": 19.98,
    "HYG Star ID_1": 39790,
    "Hipparcos Catalogue_1": 39903,
    "Henry Draper Catalogue_1": 68456,
    "Harvard Revised Catalogue_1": 3220,
    "Gliese Catalog_1": "Gl 297.1"
  },
  {
    "Astrid #": "ASTRID 001874",
    "HYG Star ID": 57287,
    "Hipparcos Catalogue": 57459,
    "Gliese Catalog": "Gl 443",
    "Distance in Parsecs": 19.992,
    "mag": 11.68,
    "Absolute Visual Magnitude": 10.176,
    "Spectral Type": "M",
    "Color Index": 1.478,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.363249,
    "y": 1.123663,
    "z": -4.841096,
    "vx": 0.00014121,
    "vy": -0.00007749,
    "vz": -0.00004226,
    "rarad": 3.08362604740202,
    "decrad": -0.244597165998838,
    "pmrarad": 0.00000346050308906944,
    "pmdecrad": -0.000003812914153,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 57287,
    "lum": 0.00740627783668154,
    "var_min": 11.784,
    "var_max": 11.584,
    "ra": 11.778584,
    "dec": -14.014385,
    "pmra": 713.78,
    "pmdec": -786.47,
    "rv": -128,
    "Asset Name": "ASTRID 001874",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Ponytail",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00014121,
    "y_1": -0.00007749,
    "z_1": -0.00004226,
    "Distance in Parsecs_1": 19.992,
    "HYG Star ID_1": 57287,
    "Hipparcos Catalogue_1": 57459,
    "Gliese Catalog_1": "Gl 443"
  },
  {
    "Astrid #": "ASTRID 001875",
    "HYG Star ID": 71629,
    "Hipparcos Catalogue": 71855,
    "Henry Draper Catalogue": 128400,
    "Gliese Catalog": "NN 3863",
    "Distance in Parsecs": 19.996,
    "mag": 6.73,
    "Absolute Visual Magnitude": 5.225,
    "Spectral Type": "G5V",
    "Color Index": 0.707,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.901425,
    "y": -3.328433,
    "z": -19.327194,
    "vx": 0.00000896,
    "vy": -0.0000077,
    "vz": -4.8e-7,
    "rarad": 3.84788786123948,
    "decrad": -1.31143130839543,
    "pmrarad": 5.83570227284722e-7,
    "pmdecrad": -9.4102335e-8,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 71629,
    "lum": 0.707945784384137,
    "ra": 14.697849,
    "dec": -75.139479,
    "pmra": 120.37,
    "pmdec": -19.41,
    "rv": 0,
    "Asset Name": "ASTRID 001875",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Bangs",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": 0.00000896,
    "y_1": -0.0000077,
    "z_1": -4.8e-7,
    "Distance in Parsecs_1": 19.996,
    "HYG Star ID_1": 71629,
    "Hipparcos Catalogue_1": 71855,
    "Henry Draper Catalogue_1": 128400,
    "Gliese Catalog_1": "NN 3863"
  },
  {
    "Astrid #": "ASTRID 001876",
    "HYG Star ID": 117971,
    "Gliese Catalog": "NN 3018A",
    "Distance in Parsecs": 20,
    "mag": 10.95,
    "Absolute Visual Magnitude": 9.445,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.474875,
    "y": 0.520064,
    "z": -18.543349,
    "vx": -0.00001729,
    "vy": 0.00005732,
    "vz": -0.00000537,
    "rarad": 0.0692194261585312,
    "decrad": -1.1867800885476,
    "pmrarad": 0.000002919063170625,
    "pmdecrad": -7.16990952e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 117971,
    "base": "NN 3018",
    "lum": 0.0145211161758774,
    "ra": 0.264399,
    "dec": -67.99749,
    "pmra": 602.1,
    "pmdec": -147.89,
    "rv": 0,
    "Asset Name": "ASTRID 001876",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Hachimaki",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00001729,
    "y_1": 0.00005732,
    "z_1": -0.00000537,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 117971,
    "Gliese Catalog_1": "NN 3018A"
  },
  {
    "Astrid #": "ASTRID 001877",
    "HYG Star ID": 117972,
    "Gliese Catalog": "NN 3019B",
    "Distance in Parsecs": 20,
    "mag": 12.5,
    "Absolute Visual Magnitude": 10.995,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.477366,
    "y": 0.51538,
    "z": -18.542476,
    "vx": -0.00001725,
    "vy": 0.00005733,
    "vz": -0.00000537,
    "rarad": 0.0685731321585312,
    "decrad": -1.1866635485476,
    "pmrarad": 0.000002919063170625,
    "pmdecrad": -7.16990952e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 117972,
    "base": "NN 3019",
    "lum": 0.00348337315036011,
    "ra": 0.26193,
    "dec": -67.990813,
    "pmra": 602.1,
    "pmdec": -147.89,
    "rv": 0,
    "Asset Name": "ASTRID 001877",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Bad Dreams",
    "Hair": "Hachimaki",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001725,
    "y_1": 0.00005733,
    "z_1": -0.00000537,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 117972,
    "Gliese Catalog_1": "NN 3019B"
  },
  {
    "Astrid #": "ASTRID 001878",
    "HYG Star ID": 118378,
    "Gliese Catalog": "NN 3350",
    "Distance in Parsecs": 20,
    "mag": 13,
    "Absolute Visual Magnitude": 11.495,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.17602,
    "y": 17.149069,
    "z": -10.058546,
    "vx": -0.00003651,
    "vy": -0.00001744,
    "vz": -0.00003764,
    "rarad": 1.44456893883245,
    "decrad": -0.5269822659108,
    "pmrarad": 0.00000170135664917361,
    "pmdecrad": -0.000002177618216,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118378,
    "lum": 0.00219785987278482,
    "ra": 5.517847,
    "dec": -30.19386,
    "pmra": 350.93,
    "pmdec": -449.17,
    "rv": 0,
    "Asset Name": "ASTRID 001878",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Bucket Hat",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003651,
    "y_1": -0.00001744,
    "z_1": -0.00003764,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118378,
    "Gliese Catalog_1": "NN 3350"
  },
  {
    "Astrid #": "ASTRID 001879",
    "HYG Star ID": 118523,
    "Gliese Catalog": "NN 3480",
    "Distance in Parsecs": 20,
    "mag": 12.69,
    "Absolute Visual Magnitude": 11.185,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.543558,
    "y": 8.853492,
    "z": 17.055341,
    "vx": 0.00001075,
    "vy": 0.00001331,
    "vz": -0.00000341,
    "rarad": 2.13025457272242,
    "decrad": 1.0212605427492,
    "pmrarad": -8.09105551479166e-7,
    "pmdecrad": -3.26905016e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118523,
    "lum": 0.00292415237784333,
    "ra": 8.136973,
    "dec": 58.513919,
    "pmra": -166.89,
    "pmdec": -67.43,
    "rv": 0,
    "Asset Name": "ASTRID 001879",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Frizzy",
    "Outfit": "Unit 01",
    "Special": "Astral Burst",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001075,
    "y_1": 0.00001331,
    "z_1": -0.00000341,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118523,
    "Gliese Catalog_1": "NN 3480"
  },
  {
    "Astrid #": "ASTRID 001880",
    "HYG Star ID": 118595,
    "Gliese Catalog": "NN 3531",
    "Distance in Parsecs": 20,
    "mag": 13.31,
    "Absolute Visual Magnitude": 11.805,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.157777,
    "y": 13.406853,
    "z": 4.451254,
    "vx": 0.00001366,
    "vy": 0.00002324,
    "vz": -0.00002652,
    "rarad": 2.38343173813883,
    "decrad": 0.22444234170025,
    "pmrarad": -0.00000131345722336111,
    "pmdecrad": -0.000001360105995,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118595,
    "lum": 0.00165196179822901,
    "ra": 9.104039,
    "dec": 12.859599,
    "pmra": -270.92,
    "pmdec": -280.54,
    "rv": 0,
    "Asset Name": "ASTRID 001880",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Tiny Crown",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001366,
    "y_1": 0.00002324,
    "z_1": -0.00002652,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118595,
    "Gliese Catalog_1": "NN 3531"
  },
  {
    "Astrid #": "ASTRID 001881",
    "HYG Star ID": 118614,
    "Gliese Catalog": "NN 3548A",
    "Distance in Parsecs": 20,
    "mag": 11.77,
    "Absolute Visual Magnitude": 10.265,
    "Spectral Type": "k-m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.619019,
    "y": 11.552731,
    "z": 9.00315,
    "vx": 9.6e-7,
    "vy": 0.00002334,
    "vz": -0.00002849,
    "rarad": 2.43810395870034,
    "decrad": 0.46694172057535,
    "pmrarad": -9.21145993055555e-7,
    "pmdecrad": -0.000001595468493,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 118614,
    "base": "NN 3548",
    "lum": 0.00682338694141669,
    "ra": 9.312871,
    "dec": 26.75379,
    "pmra": -190,
    "pmdec": -329.09,
    "rv": 0,
    "Asset Name": "ASTRID 001881",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Good Boy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 9.6e-7,
    "y_1": 0.00002334,
    "z_1": -0.00002849,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118614,
    "Gliese Catalog_1": "NN 3548A"
  },
  {
    "Astrid #": "ASTRID 001882",
    "HYG Star ID": 118615,
    "Gliese Catalog": "NN 3549B",
    "Distance in Parsecs": 20,
    "mag": 16,
    "Absolute Visual Magnitude": 14.495,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.61343,
    "y": 11.556469,
    "z": 9.006807,
    "vx": 9.6e-7,
    "vy": 0.00002334,
    "vz": -0.00002849,
    "rarad": 2.43774184470034,
    "decrad": 0.46714650257535,
    "pmrarad": -9.21145993055555e-7,
    "pmdecrad": -0.000001595468493,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118615,
    "base": "NN 3549",
    "lum": 0.000138675582887188,
    "ra": 9.311488,
    "dec": 26.765523,
    "pmra": -190,
    "pmdec": -329.09,
    "rv": 0,
    "Asset Name": "ASTRID 001882",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Antlers",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 9.6e-7,
    "y_1": 0.00002334,
    "z_1": -0.00002849,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118615,
    "Gliese Catalog_1": "NN 3549B"
  },
  {
    "Astrid #": "ASTRID 001883",
    "HYG Star ID": 118653,
    "Gliese Catalog": "GJ 1130B",
    "Distance in Parsecs": 20,
    "mag": 14.42,
    "Absolute Visual Magnitude": 12.915,
    "Color Index": 1.7,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.479947,
    "y": 8.919387,
    "z": -10.525002,
    "vx": 0.00001337,
    "vy": 0.00000892,
    "vz": -0.00001084,
    "rarad": 2.5895108868651,
    "decrad": -0.5541846044393,
    "pmrarad": -7.30662697965277e-7,
    "pmdecrad": -6.37408786e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 48365,
    "base": "GJ 1130",
    "lum": 0.000594292158615572,
    "ra": 9.891203,
    "dec": -31.752439,
    "pmra": -150.71,
    "pmdec": -131.47,
    "rv": 0,
    "Asset Name": "ASTRID 001883",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Windy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001337,
    "y_1": 0.00000892,
    "z_1": -0.00001084,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118653,
    "Gliese Catalog_1": "GJ 1130B"
  },
  {
    "Astrid #": "ASTRID 001884",
    "HYG Star ID": 118719,
    "Gliese Catalog": "NN 3632",
    "Distance in Parsecs": 20,
    "mag": 13.54,
    "Absolute Visual Magnitude": 12.035,
    "Spectral Type": "M4",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.959381,
    "y": 5.474317,
    "z": -3.251723,
    "vx": 3.1e-7,
    "vy": 0.00002654,
    "vz": 0.00004286,
    "rarad": 2.86049945298671,
    "decrad": -0.16331110134245,
    "pmrarad": -0.00000127952026572222,
    "pmdecrad": 0.000002172193151,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118719,
    "lum": 0.00133659551654644,
    "ra": 10.926303,
    "dec": -9.357037,
    "pmra": -263.92,
    "pmdec": 448.05,
    "rv": 0,
    "Asset Name": "ASTRID 001884",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Halo Boy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 3.1e-7,
    "y_1": 0.00002654,
    "z_1": 0.00004286,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118719,
    "Gliese Catalog_1": "NN 3632"
  },
  {
    "Astrid #": "ASTRID 001885",
    "HYG Star ID": 118728,
    "Gliese Catalog": "GJ 1142A",
    "Distance in Parsecs": 20,
    "mag": 12.56,
    "Absolute Visual Magnitude": 11.055,
    "Spectral Type": "dM6",
    "Color Index": 1.52,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -19.408295,
    "y": 4.471558,
    "z": -1.822979,
    "vx": 0.00000424,
    "vy": 0.00000194,
    "vz": -0.00004045,
    "rarad": 2.91514999247958,
    "decrad": -0.0912757026483,
    "pmrarad": -1.42050408402777e-7,
    "pmdecrad": -0.000002031252966,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118728,
    "base": "GJ 1142",
    "lum": 0.00329609712177457,
    "ra": 11.135053,
    "dec": -5.229713,
    "pmra": -29.3,
    "pmdec": -418.98,
    "rv": 0,
    "Asset Name": "ASTRID 001885",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Side Part",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000424,
    "y_1": 0.00000194,
    "z_1": -0.00004045,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118728,
    "Gliese Catalog_1": "GJ 1142A"
  },
  {
    "Astrid #": "ASTRID 001886",
    "HYG Star ID": 118729,
    "Gliese Catalog": "GJ 1142B",
    "Distance in Parsecs": 20,
    "mag": 12.92,
    "Absolute Visual Magnitude": 11.415,
    "Spectral Type": "DA3",
    "Color Index": 0.05,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -19.409319,
    "y": 4.480687,
    "z": -1.798127,
    "vx": -0.00001109,
    "vy": 0.00000548,
    "vz": -0.00004187,
    "rarad": 2.91471492647957,
    "decrad": -0.0900243836483,
    "pmrarad": -1.42050408402777e-7,
    "pmdecrad": -0.000002031252966,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118728,
    "base": "GJ 1142",
    "lum": 0.00236591969748575,
    "ra": 11.133391,
    "dec": -5.158017,
    "pmra": -29.3,
    "pmdec": -418.98,
    "rv": 15.4,
    "Asset Name": "ASTRID 001886",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Smoulder",
    "Hair": "Innocent",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001109,
    "y_1": 0.00000548,
    "z_1": -0.00004187,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118729,
    "Gliese Catalog_1": "GJ 1142B"
  },
  {
    "Astrid #": "ASTRID 001887",
    "HYG Star ID": 118763,
    "Gliese Catalog": "NN 3675",
    "Distance in Parsecs": 20,
    "mag": 11.77,
    "Absolute Visual Magnitude": 10.265,
    "Spectral Type": "m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.836396,
    "y": 0.269077,
    "z": 19.796021,
    "vx": -0.00000387,
    "vy": -0.00004054,
    "vz": 0,
    "rarad": 3.04639713687291,
    "decrad": 1.427853578,
    "pmrarad": 0.00000203621745833333,
    "pmdecrad": 0,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 118763,
    "lum": 0.00682338694141669,
    "ra": 11.63638,
    "dec": 81.809984,
    "pmra": 420,
    "pmdec": 0,
    "rv": 0,
    "Asset Name": "ASTRID 001887",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Sad",
    "Hair": "Pixie",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000387,
    "y_1": -0.00004054,
    "z_1": 0,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118763,
    "Gliese Catalog_1": "NN 3675"
  },
  {
    "Astrid #": "ASTRID 001888",
    "HYG Star ID": 118765,
    "Gliese Catalog": "GJ 1147",
    "Distance in Parsecs": 20,
    "mag": 13.79,
    "Absolute Visual Magnitude": 12.285,
    "Spectral Type": "k",
    "Color Index": 1.72,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.944039,
    "y": 1.410083,
    "z": -13.219102,
    "vx": -0.00001751,
    "vy": 0.00009485,
    "vz": -0.00001345,
    "rarad": 3.04759080095758,
    "decrad": -0.7220277629168,
    "pmrarad": -0.00000463986084838888,
    "pmdecrad": 3.65161664e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118765,
    "lum": 0.00106169555719872,
    "ra": 11.640939,
    "dec": -41.369144,
    "pmra": -957.04,
    "pmdec": 75.32,
    "rv": 28,
    "Asset Name": "ASTRID 001888",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Undercut",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001751,
    "y_1": 0.00009485,
    "z_1": -0.00001345,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118765,
    "Gliese Catalog_1": "GJ 1147"
  },
  {
    "Astrid #": "ASTRID 001889",
    "HYG Star ID": 118792,
    "Gliese Catalog": "NN 3700",
    "Distance in Parsecs": 20,
    "mag": 12.34,
    "Absolute Visual Magnitude": 10.835,
    "Spectral Type": "M5-6",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.54546,
    "y": -0.142592,
    "z": -4.237296,
    "vx": 0.0000048,
    "vy": 0.00000793,
    "vz": -0.0000224,
    "rarad": 3.14888842950726,
    "decrad": -0.21348266888645,
    "pmrarad": -3.94589854604166e-7,
    "pmdecrad": -0.000001145997729,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118792,
    "lum": 0.00403645392967604,
    "ra": 12.027868,
    "dec": -12.231656,
    "pmra": -81.39,
    "pmdec": -236.38,
    "rv": 0,
    "Asset Name": "ASTRID 001889",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined Neko",
    "Hair": "Undercut",
    "Outfit": "River Spirit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000048,
    "y_1": 0.00000793,
    "z_1": -0.0000224,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118792,
    "Gliese Catalog_1": "NN 3700"
  },
  {
    "Astrid #": "ASTRID 001890",
    "HYG Star ID": 118870,
    "Gliese Catalog": "NN 3766",
    "Distance in Parsecs": 20,
    "mag": 15.89,
    "Absolute Visual Magnitude": 14.385,
    "Spectral Type": "m",
    "Color Index": 1.86,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.721725,
    "y": -5.380996,
    "z": 9.561841,
    "vx": -0.00003372,
    "vy": 0.00003268,
    "vz": -0.00004056,
    "rarad": 3.45293892189239,
    "decrad": 0.49848110532,
    "pmrarad": -0.0000020721421520625,
    "pmdecrad": -0.0000023094536,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118870,
    "lum": 0.000153461698279929,
    "ra": 13.189255,
    "dec": 28.560864,
    "pmra": -427.41,
    "pmdec": -476.36,
    "rv": 0,
    "Asset Name": "ASTRID 001890",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Pixie",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003372,
    "y_1": 0.00003268,
    "z_1": -0.00004056,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118870,
    "Gliese Catalog_1": "NN 3766"
  },
  {
    "Astrid #": "ASTRID 001891",
    "HYG Star ID": 118895,
    "Gliese Catalog": "NN 3790",
    "Distance in Parsecs": 20,
    "mag": 12.26,
    "Absolute Visual Magnitude": 10.755,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.902404,
    "y": -7.160886,
    "z": 7.939172,
    "vx": -0.00000891,
    "vy": 0.00002535,
    "vz": 0.0000039,
    "rarad": 3.54233443938832,
    "decrad": 0.408200813662,
    "pmrarad": -0.00000134075223357638,
    "pmdecrad": 2.1235324e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118895,
    "lum": 0.00434510224171571,
    "ra": 13.530721,
    "dec": 23.388184,
    "pmra": -276.55,
    "pmdec": 43.8,
    "rv": 0,
    "Asset Name": "ASTRID 001891",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Side Part",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000891,
    "y_1": 0.00002535,
    "z_1": 0.0000039,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118895,
    "Gliese Catalog_1": "NN 3790"
  },
  {
    "Astrid #": "ASTRID 001892",
    "HYG Star ID": 118906,
    "Gliese Catalog": "NN 3795",
    "Distance in Parsecs": 20,
    "mag": 12.56,
    "Absolute Visual Magnitude": 11.055,
    "Spectral Type": "M3",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.360924,
    "y": -7.509491,
    "z": 8.71365,
    "vx": -0.00002065,
    "vy": 0.00005384,
    "vz": 0.00000765,
    "rarad": 3.57191292199588,
    "decrad": 0.4507964227171,
    "pmrarad": -0.00000287766008230555,
    "pmdecrad": 4.24934342e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118906,
    "lum": 0.00329609712177457,
    "ra": 13.643702,
    "dec": 25.828732,
    "pmra": -593.56,
    "pmdec": 87.65,
    "rv": 0,
    "Asset Name": "ASTRID 001892",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Honest Lad",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002065,
    "y_1": 0.00005384,
    "z_1": 0.00000765,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118906,
    "Gliese Catalog_1": "NN 3795"
  },
  {
    "Astrid #": "ASTRID 001893",
    "HYG Star ID": 118928,
    "Gliese Catalog": "NN 3811",
    "Distance in Parsecs": 20,
    "mag": 12.9,
    "Absolute Visual Magnitude": 11.395,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.510779,
    "y": -5.548866,
    "z": -16.085199,
    "vx": 0.00004503,
    "vy": -0.00001515,
    "vz": -0.00002421,
    "rarad": 3.62726838742829,
    "decrad": -0.934429049962299,
    "pmrarad": 0.00000172108856597222,
    "pmdecrad": -0.000002036639246,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118928,
    "lum": 0.00240990542868659,
    "ra": 13.855145,
    "dec": -53.538841,
    "pmra": 355,
    "pmdec": -420.09,
    "rv": 0,
    "Asset Name": "ASTRID 001893",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Spiky",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004503,
    "y_1": -0.00001515,
    "z_1": -0.00002421,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118928,
    "Gliese Catalog_1": "NN 3811"
  },
  {
    "Astrid #": "ASTRID 001894",
    "HYG Star ID": 118975,
    "Gliese Catalog": "NN 3853",
    "Distance in Parsecs": 20,
    "mag": 14.66,
    "Absolute Visual Magnitude": 13.155,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.80112,
    "y": -10.589628,
    "z": 9.86858,
    "vx": -0.00003069,
    "vy": 0.00000406,
    "vz": -0.00003855,
    "rarad": 3.7960836063263,
    "decrad": 0.5160276746534,
    "pmrarad": -0.00000109553347395138,
    "pmdecrad": -0.000002216606932,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118975,
    "lum": 0.000476430986805415,
    "ra": 14.499971,
    "dec": 29.566208,
    "pmra": -225.97,
    "pmdec": -457.21,
    "rv": 0,
    "Asset Name": "ASTRID 001894",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Shocked",
    "Hair": "Goddess",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003069,
    "y_1": 0.00000406,
    "z_1": -0.00003855,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 118975,
    "Gliese Catalog_1": "NN 3853"
  },
  {
    "Astrid #": "ASTRID 001895",
    "HYG Star ID": 119215,
    "Gliese Catalog": "GJ 2135",
    "Distance in Parsecs": 20,
    "mag": 15.6,
    "Absolute Visual Magnitude": 14.095,
    "Spectral Type": "DA7",
    "Color Index": 0.42,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 2.040867,
    "y": -19.368516,
    "z": -4.549222,
    "vx": -0.00001804,
    "vy": 0.00001153,
    "vz": -0.00005721,
    "rarad": 4.81737304800343,
    "decrad": -0.2294696255773,
    "pmrarad": -8.36739931270833e-7,
    "pmdecrad": -0.000002937471546,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119215,
    "lum": 0.000200447202736515,
    "ra": 18.40101,
    "dec": -13.147641,
    "pmra": -172.59,
    "pmdec": -605.9,
    "rv": 0,
    "Asset Name": "ASTRID 001895",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Braid",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001804,
    "y_1": 0.00001153,
    "z_1": -0.00005721,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119215,
    "Gliese Catalog_1": "GJ 2135"
  },
  {
    "Astrid #": "ASTRID 001896",
    "HYG Star ID": 119322,
    "Gliese Catalog": "NN 4131",
    "Distance in Parsecs": 20,
    "mag": 14.5,
    "Absolute Visual Magnitude": 12.995,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.771293,
    "y": -14.58169,
    "z": -10.508984,
    "vx": 0.00000722,
    "vy": 0.00004852,
    "vz": -0.00006133,
    "rarad": 5.25391747085644,
    "decrad": -0.5532430166393,
    "pmrarad": 0.00000155959712897916,
    "pmdecrad": -0.000003604012786,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119322,
    "lum": 0.000552077439280757,
    "ra": 20.068486,
    "dec": -31.69849,
    "pmra": 321.69,
    "pmdec": -743.38,
    "rv": 0,
    "Asset Name": "ASTRID 001896",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Wavy",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000722,
    "y_1": 0.00004852,
    "z_1": -0.00006133,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119322,
    "Gliese Catalog_1": "NN 4131"
  },
  {
    "Astrid #": "ASTRID 001897",
    "HYG Star ID": 119372,
    "Gliese Catalog": "NN 4163",
    "Distance in Parsecs": 20,
    "mag": 13.38,
    "Absolute Visual Magnitude": 11.875,
    "Spectral Type": "m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.651176,
    "y": -13.943351,
    "z": 6.747644,
    "vx": -0.00000703,
    "vy": -0.00001451,
    "vz": -0.00001678,
    "rarad": 5.44923966789675,
    "decrad": 0.344134660502449,
    "pmrarad": -7.47922064993055e-7,
    "pmdecrad": -8.91329951e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119372,
    "lum": 0.00154881661891247,
    "ra": 20.814562,
    "dec": 19.717464,
    "pmra": -154.27,
    "pmdec": -183.85,
    "rv": 0,
    "Asset Name": "ASTRID 001897",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Good Boy",
    "Outfit": "Elven",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000703,
    "y_1": -0.00001451,
    "z_1": -0.00001678,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119372,
    "Gliese Catalog_1": "NN 4163"
  },
  {
    "Astrid #": "ASTRID 001898",
    "HYG Star ID": 119375,
    "Gliese Catalog": "NN 4166",
    "Distance in Parsecs": 20,
    "mag": 15.58,
    "Absolute Visual Magnitude": 14.075,
    "Spectral Type": "DC9",
    "Color Index": 0.95,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.110969,
    "y": -13.177197,
    "z": 8.926697,
    "vx": -0.00002042,
    "vy": -0.00003721,
    "vz": -0.00002721,
    "rarad": 5.45566080718706,
    "decrad": 0.46266540173125,
    "pmrarad": -0.00000201071625873611,
    "pmdecrad": -0.000001520705375,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119375,
    "lum": 0.000204173794466952,
    "ra": 20.839089,
    "dec": 26.508775,
    "pmra": -414.74,
    "pmdec": -313.67,
    "rv": 0,
    "Asset Name": "ASTRID 001898",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Good Boy",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002042,
    "y_1": -0.00003721,
    "z_1": -0.00002721,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119375,
    "Gliese Catalog_1": "NN 4166"
  },
  {
    "Astrid #": "ASTRID 001899",
    "HYG Star ID": 119522,
    "Gliese Catalog": "NN 4290",
    "Distance in Parsecs": 20,
    "mag": 13.2,
    "Absolute Visual Magnitude": 11.695,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.396873,
    "y": -4.83076,
    "z": 14.042348,
    "vx": 0.00001763,
    "vy": 0.00004195,
    "vz": -0.00000238,
    "rarad": 5.93706289278231,
    "decrad": 0.7783668011142,
    "pmrarad": 0.00000227251564623611,
    "pmdecrad": -1.66877716e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119522,
    "lum": 0.00182810021614274,
    "ra": 22.67791,
    "dec": 44.597133,
    "pmra": 468.74,
    "pmdec": -34.42,
    "rv": 0,
    "Asset Name": "ASTRID 001899",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Frizzy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001763,
    "y_1": 0.00004195,
    "z_1": -0.00000238,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119522,
    "Gliese Catalog_1": "NN 4290"
  },
  {
    "Astrid #": "ASTRID 001900",
    "HYG Star ID": 119537,
    "Gliese Catalog": "NN 4302",
    "Distance in Parsecs": 20,
    "mag": 13.9,
    "Absolute Visual Magnitude": 12.395,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.108009,
    "y": -5.589602,
    "z": -1.907973,
    "vx": 0.00001948,
    "vy": 0.00005596,
    "vz": 0.00003106,
    "rarad": 5.99859812843903,
    "decrad": -0.0955439640244,
    "pmrarad": 0.00000295910878063888,
    "pmdecrad": 0.000001560159512,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119537,
    "lum": 0.000959400631515932,
    "ra": 22.912957,
    "dec": -5.474266,
    "pmra": 610.36,
    "pmdec": 321.81,
    "rv": 0,
    "Asset Name": "ASTRID 001900",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Goddess",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001948,
    "y_1": 0.00005596,
    "z_1": 0.00003106,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119537,
    "Gliese Catalog_1": "NN 4302"
  },
  {
    "Astrid #": "ASTRID 001901",
    "HYG Star ID": 119545,
    "Gliese Catalog": "NN 4311",
    "Distance in Parsecs": 20,
    "mag": 11.78,
    "Absolute Visual Magnitude": 10.275,
    "Spectral Type": "M2",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.102742,
    "y": -1.448578,
    "z": 18.991003,
    "vx": -0.00000936,
    "vy": 0.000127,
    "vz": 0.00001274,
    "rarad": 6.04946912645859,
    "decrad": 1.25179840216575,
    "pmrarad": 0.00000606884917182638,
    "pmdecrad": 0.000002030603315,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 119545,
    "lum": 0.00676082975391981,
    "ra": 23.10727,
    "dec": 71.722765,
    "pmra": 1251.79,
    "pmdec": 418.84,
    "rv": 0,
    "Asset Name": "ASTRID 001901",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Bucket Hat",
    "Outfit": "Wizard",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000936,
    "y_1": 0.000127,
    "z_1": 0.00001274,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119545,
    "Gliese Catalog_1": "NN 4311"
  },
  {
    "Astrid #": "ASTRID 001902",
    "HYG Star ID": 119550,
    "Gliese Catalog": "NN 4316",
    "Distance in Parsecs": 20,
    "mag": 13,
    "Absolute Visual Magnitude": 11.495,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.976701,
    "y": -4.016854,
    "z": -4.873365,
    "vx": -0.00002074,
    "vy": -0.00001742,
    "vz": -0.00006644,
    "rarad": 6.07459301495072,
    "decrad": -0.2461463387656,
    "pmrarad": -0.00000106688098543055,
    "pmdecrad": -0.000003425315312,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119550,
    "lum": 0.00219785987278482,
    "ra": 23.203236,
    "dec": -14.103146,
    "pmra": -220.06,
    "pmdec": -706.52,
    "rv": 0,
    "Asset Name": "ASTRID 001902",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Shy Smile",
    "Hair": "Innocent",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002074,
    "y_1": -0.00001742,
    "z_1": -0.00006644,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119550,
    "Gliese Catalog_1": "NN 4316"
  },
  {
    "Astrid #": "ASTRID 001903",
    "HYG Star ID": 119592,
    "Gliese Catalog": "NN 4361",
    "Distance in Parsecs": 20,
    "mag": 14.6,
    "Absolute Visual Magnitude": 13.095,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.640183,
    "y": -0.745067,
    "z": -15.4813,
    "vx": -0.00003544,
    "vy": -0.00001743,
    "vz": -0.00002811,
    "rarad": 6.2243372624973,
    "decrad": -0.88523692736605,
    "pmrarad": -9.743300538125e-7,
    "pmdecrad": -0.000002219627321,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119592,
    "lum": 0.000503500608787904,
    "ra": 23.775217,
    "dec": -50.72034,
    "pmra": -200.97,
    "pmdec": -457.83,
    "rv": 0,
    "Asset Name": "ASTRID 001903",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Sweetheart",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003544,
    "y_1": -0.00001743,
    "z_1": -0.00002811,
    "Distance in Parsecs_1": 20,
    "HYG Star ID_1": 119592,
    "Gliese Catalog_1": "NN 4361"
  },
  {
    "Astrid #": "ASTRID 001904",
    "HYG Star ID": 94051,
    "Hipparcos Catalogue": 94346,
    "Henry Draper Catalogue": 180161,
    "Gliese Catalog": "GJ 1233",
    "Distance in Parsecs": 20.016,
    "mag": 7.04,
    "Absolute Visual Magnitude": 5.533,
    "Spectral Type": "G8V",
    "Color Index": 0.804,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.316045,
    "y": -10.17713,
    "z": 16.91333,
    "vx": 0.00000526,
    "vy": 0.00005205,
    "vz": -0.00000154,
    "rarad": 5.02736616421565,
    "decrad": 1.0065659588779,
    "pmrarad": 0.00000105568178940972,
    "pmdecrad": 0.000001979300332,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 94051,
    "lum": 0.533089342236701,
    "var": "MV",
    "var_min": 7.074,
    "var_max": 7.014,
    "ra": 19.203124,
    "dec": 57.671981,
    "pmra": 217.75,
    "pmdec": 408.26,
    "rv": -26.3,
    "Asset Name": "ASTRID 001904",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Spiky",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000526,
    "y_1": 0.00005205,
    "z_1": -0.00000154,
    "Distance in Parsecs_1": 20.016,
    "HYG Star ID_1": 94051,
    "Hipparcos Catalogue_1": 94346,
    "Henry Draper Catalogue_1": 180161,
    "Gliese Catalog_1": "GJ 1233"
  },
  {
    "Astrid #": "ASTRID 001905",
    "HYG Star ID": 52469,
    "Hipparcos Catalogue": 52621,
    "Gliese Catalog": "Gl 401A",
    "Distance in Parsecs": 20.02,
    "mag": 11.03,
    "Absolute Visual Magnitude": 9.523,
    "Spectral Type": "M",
    "Color Index": 1.428,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.929874,
    "y": 6.029521,
    "z": -6.555745,
    "vx": 0.00004075,
    "vy": 0.00017108,
    "vz": -0.00006659,
    "rarad": 2.81719374131411,
    "decrad": -0.333608265885882,
    "pmrarad": -0.00000874933553025,
    "pmdecrad": -0.000002883186958,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 52469,
    "base": "Gl 401",
    "lum": 0.0135145005411755,
    "var_min": 11.098,
    "var_max": 10.908,
    "ra": 10.760887,
    "dec": -19.114346,
    "pmra": -1804.68,
    "pmdec": -594.7,
    "rv": 36,
    "Asset Name": "ASTRID 001905",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Audiophile",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00004075,
    "y_1": 0.00017108,
    "z_1": -0.00006659,
    "Distance in Parsecs_1": 20.02,
    "HYG Star ID_1": 52469,
    "Hipparcos Catalogue_1": 52621,
    "Gliese Catalog_1": "Gl 401A"
  },
  {
    "Astrid #": "ASTRID 001906",
    "HYG Star ID": 1066,
    "Hipparcos Catalogue": 1068,
    "Gliese Catalog": "Gl 11A",
    "Distance in Parsecs": 20.0361,
    "mag": 12.49,
    "Absolute Visual Magnitude": 10.981,
    "Spectral Type": "M6",
    "Color Index": 1.4,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.061621,
    "y": 0.40914,
    "z": 18.745971,
    "vx": 0.00002251,
    "vy": 0.00007106,
    "vz": -0.00001003,
    "rarad": 0.0578181434224958,
    "decrad": 1.20998234061777,
    "pmrarad": 0.00000347616257095138,
    "pmdecrad": -0.000001417643683,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 1066,
    "base": "Gl  11",
    "lum": 0.00352858025828526,
    "var_min": 12.806,
    "var_max": 12.206,
    "ra": 0.220849,
    "dec": 69.326881,
    "pmra": 717.01,
    "pmdec": -292.41,
    "rv": 0,
    "Asset Name": "ASTRID 001906",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Tiny Crown",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002251,
    "y_1": 0.00007106,
    "z_1": -0.00001003,
    "Distance in Parsecs_1": 20.0361,
    "HYG Star ID_1": 1066,
    "Hipparcos Catalogue_1": 1068,
    "Gliese Catalog_1": "Gl 11A"
  },
  {
    "Astrid #": "ASTRID 001907",
    "HYG Star ID": 118679,
    "Gliese Catalog": "Gl 389B",
    "Distance in Parsecs": 20.0481,
    "mag": 12.63,
    "Absolute Visual Magnitude": 11.12,
    "Spectral Type": "m",
    "Color Index": 1.49,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.080952,
    "y": 4.118901,
    "z": -17.392449,
    "vx": 0.00001907,
    "vy": -0.00004543,
    "vz": -0.00002072,
    "rarad": 2.71568450566936,
    "decrad": -1.05022678405595,
    "pmrarad": 0.00000167081338729861,
    "pmdecrad": -0.000002078081119,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 50663,
    "base": "Gl 389",
    "lum": 0.00310455958812835,
    "ra": 10.373151,
    "dec": -60.173562,
    "pmra": 344.63,
    "pmdec": -428.63,
    "rv": 0,
    "Asset Name": "ASTRID 001907",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Stunned",
    "Hair": "Headband",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001907,
    "y_1": -0.00004543,
    "z_1": -0.00002072,
    "Distance in Parsecs_1": 20.0481,
    "HYG Star ID_1": 118679,
    "Gliese Catalog_1": "Gl 389B"
  },
  {
    "Astrid #": "ASTRID 001908",
    "HYG Star ID": 119501,
    "Henry Draper Catalogue": 212698,
    "Harvard Revised Catalogue": 8545,
    "Gliese Catalog": "Gl 859A",
    "Distance in Parsecs": 20.0803,
    "mag": 6.21,
    "Absolute Visual Magnitude": 4.696,
    "Spectral Type": "G3 V",
    "Color Index": 0.62,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.653524,
    "y": -7.623008,
    "z": -5.784521,
    "vx": 0.00000822,
    "vy": 0.00001978,
    "vz": 9e-8,
    "rarad": 5.87555640850486,
    "decrad": -0.292210429,
    "pmrarad": 0.00000106659009722222,
    "pmdecrad": 0,
    "flam": 53,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 119501,
    "base": "Gl 859",
    "lum": 1.15239137719948,
    "bf": "53    Aqr",
    "ra": 22.442972,
    "dec": -16.742424,
    "pmra": 220,
    "pmdec": 0,
    "rv": -0.3,
    "Asset Name": "ASTRID 001908",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Fire",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000822,
    "y_1": 0.00001978,
    "z_1": 9e-8,
    "Distance in Parsecs_1": 20.0803,
    "HYG Star ID_1": 119501,
    "Henry Draper Catalogue_1": 212698,
    "Harvard Revised Catalogue_1": 8545,
    "Gliese Catalog_1": "Gl 859A"
  },
  {
    "Astrid #": "ASTRID 001909",
    "HYG Star ID": 33765,
    "Hipparcos Catalogue": 33852,
    "Henry Draper Catalogue": 51866,
    "Gliese Catalog": "Gl 257.1",
    "Distance in Parsecs": 20.0844,
    "mag": 7.98,
    "Absolute Visual Magnitude": 6.466,
    "Spectral Type": "K3V",
    "Color Index": 0.986,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.544943,
    "y": 12.860379,
    "z": 15.013959,
    "vx": -0.00005551,
    "vy": 0.00000117,
    "vz": -0.0000453,
    "rarad": 1.83975354748733,
    "decrad": 0.844367119281987,
    "pmrarad": 0.00000264931283876388,
    "pmdecrad": -0.00000208911063,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 33765,
    "lum": 0.225735571102144,
    "ra": 7.027341,
    "dec": 48.378672,
    "pmra": 546.46,
    "pmdec": -430.91,
    "rv": -22.8,
    "Asset Name": "ASTRID 001909",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Sweetheart",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00005551,
    "y_1": 0.00000117,
    "z_1": -0.0000453,
    "Distance in Parsecs_1": 20.0844,
    "HYG Star ID_1": 33765,
    "Hipparcos Catalogue_1": 33852,
    "Henry Draper Catalogue_1": 51866,
    "Gliese Catalog_1": "Gl 257.1"
  },
  {
    "Astrid #": "ASTRID 001910",
    "HYG Star ID": 38512,
    "Hipparcos Catalogue": 38625,
    "Henry Draper Catalogue": 64606,
    "Gliese Catalog": "Gl 292.2",
    "Distance in Parsecs": 20.0884,
    "mag": 7.43,
    "Absolute Visual Magnitude": 5.915,
    "Spectral Type": "G8V",
    "Color Index": 0.739,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.626719,
    "y": 17.625595,
    "z": -0.495122,
    "vx": -0.00002842,
    "vy": 0.00010314,
    "vz": -0.00000861,
    "rarad": 2.07070072809945,
    "decrad": -0.0246485540836962,
    "pmrarad": -0.00000121964577617361,
    "pmdecrad": -3.00923851e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 38512,
    "lum": 0.374973002245483,
    "ra": 7.909494,
    "dec": -1.412258,
    "pmra": -251.57,
    "pmdec": -62.07,
    "rv": 102,
    "Asset Name": "ASTRID 001910",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Braided Bun",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00002842,
    "y_1": 0.00010314,
    "z_1": -0.00000861,
    "Distance in Parsecs_1": 20.0884,
    "HYG Star ID_1": 38512,
    "Hipparcos Catalogue_1": 38625,
    "Henry Draper Catalogue_1": 64606,
    "Gliese Catalog_1": "Gl 292.2"
  },
  {
    "Astrid #": "ASTRID 001911",
    "HYG Star ID": 6275,
    "Hipparcos Catalogue": 6290,
    "Distance in Parsecs": 20.0924,
    "mag": 10.36,
    "Absolute Visual Magnitude": 8.845,
    "Spectral Type": "K6V:",
    "Color Index": 1.34,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.181327,
    "y": 3.739902,
    "z": 16.913257,
    "vx": -0.0000237,
    "vy": -0.00003925,
    "vz": 0.00002295,
    "rarad": 0.352039601169072,
    "decrad": 1.00056079588302,
    "pmrarad": -0.00000142695210597916,
    "pmdecrad": 0.000002115872346,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 6275,
    "lum": 0.0252348077248057,
    "var_min": 10.432,
    "var_max": 10.292,
    "ra": 1.344692,
    "dec": 57.327911,
    "pmra": -294.33,
    "pmdec": 436.43,
    "rv": 0,
    "Asset Name": "ASTRID 001911",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Pompadour",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.0000237,
    "y_1": -0.00003925,
    "z_1": 0.00002295,
    "Distance in Parsecs_1": 20.0924,
    "HYG Star ID_1": 6275,
    "Hipparcos Catalogue_1": 6290
  },
  {
    "Astrid #": "ASTRID 001912",
    "HYG Star ID": 109466,
    "Hipparcos Catalogue": 109812,
    "Gliese Catalog": "Gl 851.5",
    "Distance in Parsecs": 20.1045,
    "mag": 10.33,
    "Absolute Visual Magnitude": 8.814,
    "Spectral Type": "M0",
    "Color Index": 1.426,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.92567,
    "y": -7.894876,
    "z": 9.393669,
    "vx": -0.00001734,
    "vy": -0.00001492,
    "vz": 0.00005408,
    "rarad": 5.82295856438562,
    "decrad": 0.486164856224382,
    "pmrarad": -0.000001048070214625,
    "pmdecrad": 0.000002585511358,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 109466,
    "lum": 0.0259656979027378,
    "ra": 22.242063,
    "dec": 27.855194,
    "pmra": -216.18,
    "pmdec": 533.3,
    "rv": 17,
    "Asset Name": "ASTRID 001912",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Braid",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00001734,
    "y_1": -0.00001492,
    "z_1": 0.00005408,
    "Distance in Parsecs_1": 20.1045,
    "HYG Star ID_1": 109466,
    "Hipparcos Catalogue_1": 109812,
    "Gliese Catalog_1": "Gl 851.5"
  },
  {
    "Astrid #": "ASTRID 001913",
    "HYG Star ID": 118799,
    "Gliese Catalog": "Gl 456.1B",
    "Distance in Parsecs": 20.1126,
    "mag": 13.25,
    "Absolute Visual Magnitude": 11.733,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.90953,
    "y": -0.604298,
    "z": -14.514698,
    "vx": 0.00000408,
    "vy": 0.00003741,
    "vz": -0.00000547,
    "rarad": 3.18505135363857,
    "decrad": -0.806214530558599,
    "pmrarad": -0.00000184946722858333,
    "pmdecrad": -3.93111172e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 59116,
    "base": "Gl 456.1",
    "lum": 0.00176522471745255,
    "ra": 12.166,
    "dec": -46.19269,
    "pmra": -381.48,
    "pmdec": -81.08,
    "rv": 0,
    "Asset Name": "ASTRID 001913",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Shocked",
    "Hair": "Spiky",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000408,
    "y_1": 0.00003741,
    "z_1": -0.00000547,
    "Distance in Parsecs_1": 20.1126,
    "HYG Star ID_1": 118799,
    "Gliese Catalog_1": "Gl 456.1B"
  },
  {
    "Astrid #": "ASTRID 001914",
    "HYG Star ID": 118327,
    "Gliese Catalog": "Gl 174.1B",
    "Distance in Parsecs": 20.1329,
    "mag": 12.5,
    "Absolute Visual Magnitude": 10.98,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 5.093689,
    "y": 14.102115,
    "z": -13.435712,
    "vx": 0.00001143,
    "vy": -0.00000921,
    "vz": -0.00000533,
    "rarad": 1.22419031459544,
    "decrad": -0.730646227541149,
    "pmrarad": -6.89308091013888e-7,
    "pmdecrad": -3.55770823e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 21717,
    "base": "Gl 174.1",
    "lum": 0.00353183169791956,
    "ra": 4.676063,
    "dec": -41.862945,
    "pmra": -142.18,
    "pmdec": -73.38,
    "rv": 0,
    "Asset Name": "ASTRID 001914",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Oh Snap",
    "Hair": "Slick",
    "Outfit": "Boxer",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001143,
    "y_1": -0.00000921,
    "z_1": -0.00000533,
    "Distance in Parsecs_1": 20.1329,
    "HYG Star ID_1": 118327,
    "Gliese Catalog_1": "Gl 174.1B"
  },
  {
    "Astrid #": "ASTRID 001915",
    "HYG Star ID": 113245,
    "Hipparcos Catalogue": 113605,
    "Gliese Catalog": "NN 4309B",
    "Distance in Parsecs": 20.1532,
    "mag": 11.6,
    "Absolute Visual Magnitude": 10.078,
    "Spectral Type": "M2",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.800345,
    "y": -4.718856,
    "z": -8.187284,
    "vx": -0.0000084,
    "vy": 0.00002196,
    "vz": -0.00003092,
    "rarad": 6.02404619158714,
    "decrad": -0.418348907733166,
    "pmrarad": 9.46695674020833e-7,
    "pmdecrad": -0.000001679055219,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 2,
    "comp_primary": 113245,
    "base": "NN 4309",
    "lum": 0.00810587682464737,
    "var_min": 11.694,
    "var_max": 11.514,
    "ra": 23.010161,
    "dec": -23.969627,
    "pmra": 195.27,
    "pmdec": -346.33,
    "rv": 0,
    "Asset Name": "ASTRID 001915",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Braided Ponytail",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.0000084,
    "y_1": 0.00002196,
    "z_1": -0.00003092,
    "Distance in Parsecs_1": 20.1532,
    "HYG Star ID_1": 113245,
    "Hipparcos Catalogue_1": 113605,
    "Gliese Catalog_1": "NN 4309B"
  },
  {
    "Astrid #": "ASTRID 001916",
    "HYG Star ID": 26440,
    "Hipparcos Catalogue": 26505,
    "Henry Draper Catalogue": 37008,
    "Gliese Catalog": "NN 3358",
    "Distance in Parsecs": 20.1613,
    "mag": 7.74,
    "Absolute Visual Magnitude": 6.217,
    "Spectral Type": "K2V",
    "Color Index": 0.834,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.193551,
    "y": 12.508582,
    "z": 15.766197,
    "vx": 0.00005003,
    "vy": -0.00004107,
    "vz": -0.00002875,
    "rarad": 1.4756798975217,
    "decrad": 0.897897548586856,
    "pmrarad": -0.00000266371180507638,
    "pmdecrad": 5.12981355e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 26440,
    "lum": 0.28392262394249,
    "ra": 5.636682,
    "dec": 51.44574,
    "pmra": -549.43,
    "pmdec": 105.81,
    "rv": -44,
    "Asset Name": "ASTRID 001916",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Audiophile",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00005003,
    "y_1": -0.00004107,
    "z_1": -0.00002875,
    "Distance in Parsecs_1": 20.1613,
    "HYG Star ID_1": 26440,
    "Hipparcos Catalogue_1": 26505,
    "Henry Draper Catalogue_1": 37008,
    "Gliese Catalog_1": "NN 3358"
  },
  {
    "Astrid #": "ASTRID 001917",
    "HYG Star ID": 118616,
    "Gliese Catalog": "GJ 1122A",
    "Distance in Parsecs": 20.1613,
    "mag": 14.52,
    "Absolute Visual Magnitude": 12.997,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.053299,
    "y": 10.174252,
    "z": 12.557094,
    "vx": 0.00001616,
    "vy": 0.00001832,
    "vz": 6.7e-7,
    "rarad": 2.44054764865095,
    "decrad": 0.67235672775725,
    "pmrarad": -0.00000121130698086805,
    "pmdecrad": 4.2295145e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118616,
    "base": "GJ 1122",
    "lum": 0.000551061411139065,
    "ra": 9.322205,
    "dec": 38.523203,
    "pmra": -249.85,
    "pmdec": 8.72,
    "rv": 0,
    "Asset Name": "ASTRID 001917",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Greasy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001616,
    "y_1": 0.00001832,
    "z_1": 6.7e-7,
    "Distance in Parsecs_1": 20.1613,
    "HYG Star ID_1": 118616,
    "Gliese Catalog_1": "GJ 1122A"
  },
  {
    "Astrid #": "ASTRID 001918",
    "HYG Star ID": 118617,
    "Gliese Catalog": "GJ 1122B",
    "Distance in Parsecs": 20.1613,
    "mag": 14.67,
    "Absolute Visual Magnitude": 13.147,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.053021,
    "y": 10.174015,
    "z": 12.557552,
    "vx": 0.00001616,
    "vy": 0.00001832,
    "vz": 6.7e-7,
    "rarad": 2.44054780165095,
    "decrad": 0.67238581675725,
    "pmrarad": -0.00000121130698086805,
    "pmdecrad": 4.2295145e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118616,
    "base": "GJ 1122",
    "lum": 0.000479954424931944,
    "ra": 9.322206,
    "dec": 38.52487,
    "pmra": -249.85,
    "pmdec": 8.72,
    "rv": 0,
    "Asset Name": "ASTRID 001918",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Shinobi",
    "Outfit": "Viking",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001616,
    "y_1": 0.00001832,
    "z_1": 6.7e-7,
    "Distance in Parsecs_1": 20.1613,
    "HYG Star ID_1": 118617,
    "Gliese Catalog_1": "GJ 1122B"
  },
  {
    "Astrid #": "ASTRID 001919",
    "HYG Star ID": 119454,
    "Gliese Catalog": "NN 4237A",
    "Distance in Parsecs": 20.1613,
    "mag": 10,
    "Absolute Visual Magnitude": 8.477,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.473555,
    "y": -8.728083,
    "z": 10.991578,
    "vx": -6.8e-7,
    "vy": -4e-7,
    "vz": 5.8e-7,
    "rarad": 5.74053637418363,
    "decrad": 0.57660620705875,
    "pmrarad": -3.42763272152777e-8,
    "pmdecrad": 3.4281175e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119454,
    "base": "NN 4237",
    "lum": 0.0354160400426461,
    "ra": 21.927234,
    "dec": 33.037102,
    "pmra": -7.07,
    "pmdec": 7.07,
    "rv": 0,
    "Asset Name": "ASTRID 001919",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Gimme Blood",
    "Hair": "Headband",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -6.8e-7,
    "y_1": -4e-7,
    "z_1": 5.8e-7,
    "Distance in Parsecs_1": 20.1613,
    "HYG Star ID_1": 119454,
    "Gliese Catalog_1": "NN 4237A"
  },
  {
    "Astrid #": "ASTRID 001920",
    "HYG Star ID": 119455,
    "Gliese Catalog": "NN 4238B",
    "Distance in Parsecs": 20.1613,
    "mag": 10.2,
    "Absolute Visual Magnitude": 8.677,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.473555,
    "y": -8.728083,
    "z": 10.991578,
    "vx": -6.8e-7,
    "vy": -4e-7,
    "vz": 5.8e-7,
    "rarad": 5.74053637418363,
    "decrad": 0.57660620705875,
    "pmrarad": -3.42763272152777e-8,
    "pmdecrad": 3.4281175e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 2,
    "comp_primary": 119455,
    "base": "NN 4238",
    "lum": 0.0294577790233945,
    "ra": 21.927234,
    "dec": 33.037102,
    "pmra": -7.07,
    "pmdec": 7.07,
    "rv": 0,
    "Asset Name": "ASTRID 001920",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Knocked Out",
    "Hair": "Tiny Crown",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -6.8e-7,
    "y_1": -4e-7,
    "z_1": 5.8e-7,
    "Distance in Parsecs_1": 20.1613,
    "HYG Star ID_1": 119455,
    "Gliese Catalog_1": "NN 4238B"
  },
  {
    "Astrid #": "ASTRID 001921",
    "HYG Star ID": 15400,
    "Hipparcos Catalogue": 15439,
    "Distance in Parsecs": 20.1654,
    "mag": 11.84,
    "Absolute Visual Magnitude": 10.317,
    "Color Index": 1.54,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.489778,
    "y": 12.387809,
    "z": -11.964536,
    "vx": 0.00000741,
    "vy": -0.0000158,
    "vz": -0.00000986,
    "rarad": 0.868174927888143,
    "decrad": -0.635177040254922,
    "pmrarad": -7.86755640805555e-7,
    "pmdecrad": -6.07229134e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 15400,
    "lum": 0.006504291550788,
    "var_min": 11.962,
    "var_max": 11.712,
    "ra": 3.316184,
    "dec": -36.392964,
    "pmra": -162.28,
    "pmdec": -125.25,
    "rv": 0,
    "Asset Name": "ASTRID 001921",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Grecian",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00000741,
    "y_1": -0.0000158,
    "z_1": -0.00000986,
    "Distance in Parsecs_1": 20.1654,
    "HYG Star ID_1": 15400,
    "Hipparcos Catalogue_1": 15439
  },
  {
    "Astrid #": "ASTRID 001922",
    "HYG Star ID": 21717,
    "Hipparcos Catalogue": 21770,
    "Henry Draper Catalogue": 29875,
    "Harvard Revised Catalogue": 1502,
    "Gliese Catalog": "Gl 174.1A",
    "Distance in Parsecs": 20.1654,
    "mag": 4.44,
    "Absolute Visual Magnitude": 2.917,
    "Spectral Type": "F2V",
    "Color Index": 0.342,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.101798,
    "y": 14.124725,
    "z": -13.457617,
    "vx": 0.00001153,
    "vy": -0.00000871,
    "vz": -0.000006,
    "rarad": 1.2241841699754,
    "decrad": -0.730660317468713,
    "pmrarad": -6.84459954208333e-7,
    "pmdecrad": -3.63367853e-7,
    "bayer": "Alp",
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 21717,
    "base": "Gl 174.1",
    "lum": 5.93198439664972,
    "bf": "Alp Cae",
    "ra": 4.676039,
    "dec": -41.863752,
    "pmra": -141.18,
    "pmdec": -74.95,
    "rv": 0.8,
    "Asset Name": "ASTRID 001922",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Chuckle",
    "Hair": "Wavy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": 0.00001153,
    "y_1": -0.00000871,
    "z_1": -0.000006,
    "Distance in Parsecs_1": 20.1654,
    "HYG Star ID_1": 21717,
    "Hipparcos Catalogue_1": 21770,
    "Henry Draper Catalogue_1": 29875,
    "Harvard Revised Catalogue_1": 1502,
    "Gliese Catalog_1": "Gl 174.1A"
  },
  {
    "Astrid #": "ASTRID 001923",
    "HYG Star ID": 9861,
    "Hipparcos Catalogue": 9884,
    "Henry Draper Catalogue": 12929,
    "Harvard Revised Catalogue": 617,
    "Gliese Catalog": "Gl 84.3",
    "Proper Name": "Hamal",
    "Distance in Parsecs": 20.1776,
    "mag": 2.01,
    "Absolute Visual Magnitude": 0.486,
    "Spectral Type": "K2III",
    "Color Index": 1.151,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.731986,
    "y": 9.751722,
    "z": 8.03361,
    "vx": -0.00001649,
    "vy": 0.00001173,
    "vz": -0.00001894,
    "rarad": 0.554898130994913,
    "decrad": 0.409496533262107,
    "pmrarad": 9.24685132923611e-7,
    "pmdecrad": -7.06712902e-7,
    "bayer": "Alp",
    "flam": 13,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 9861,
    "lum": 55.6672798150948,
    "bf": "13Alp Ari",
    "ra": 2.119555,
    "dec": 23.462423,
    "pmra": 190.73,
    "pmdec": -145.77,
    "rv": -14.4,
    "Asset Name": "ASTRID 001923",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Mischievous",
    "Hair": "Astro Helmet",
    "Outfit": "Astro",
    "Special": "V1 Flight System",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Hamal",
    "Constellation": "Aries",
    "x_1": -0.00001649,
    "y_1": 0.00001173,
    "z_1": -0.00001894,
    "Distance in Parsecs_1": 20.1776,
    "HYG Star ID_1": 9861,
    "Hipparcos Catalogue_1": 9884,
    "Henry Draper Catalogue_1": 12929,
    "Harvard Revised Catalogue_1": 617,
    "Gliese Catalog_1": "Gl 84.3"
  },
  {
    "Astrid #": "ASTRID 001924",
    "HYG Star ID": 1029,
    "Hipparcos Catalogue": 1031,
    "Henry Draper Catalogue": 870,
    "Distance in Parsecs": 20.1898,
    "mag": 7.22,
    "Absolute Visual Magnitude": 5.694,
    "Spectral Type": "K0V",
    "Color Index": 0.775,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.708246,
    "y": 0.600442,
    "z": -17.105583,
    "vx": 0.00000355,
    "vy": -0.0000113,
    "vz": 0.00000182,
    "rarad": 0.0560185705693742,
    "decrad": -1.0107657134402,
    "pmrarad": -5.68734928659722e-7,
    "pmdecrad": 1.7012112e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 1029,
    "lum": 0.459621144666001,
    "ra": 0.213975,
    "dec": -57.912609,
    "pmra": -117.31,
    "pmdec": 35.09,
    "rv": 0,
    "Asset Name": "ASTRID 001924",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Anxious Laugh",
    "Hair": "Innocent",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": 0.00000355,
    "y_1": -0.0000113,
    "z_1": 0.00000182,
    "Distance in Parsecs_1": 20.1898,
    "HYG Star ID_1": 1029,
    "Hipparcos Catalogue_1": 1031,
    "Henry Draper Catalogue_1": 870
  },
  {
    "Astrid #": "ASTRID 001925",
    "HYG Star ID": 113584,
    "Hipparcos Catalogue": 113944,
    "Gliese Catalog": "GJ 1278",
    "Distance in Parsecs": 20.1939,
    "mag": 9.86,
    "Absolute Visual Magnitude": 8.334,
    "Spectral Type": "M1V:",
    "Color Index": 1.392,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.734353,
    "y": -1.910355,
    "z": 18.556039,
    "vx": 0.000017,
    "vy": 0.00002641,
    "vz": 0.00000387,
    "rarad": 6.04101608610594,
    "decrad": 1.16525832881899,
    "pmrarad": 0.00000147155496459027,
    "pmdecrad": -3.87220686e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 113584,
    "lum": 0.0404017335373,
    "ra": 23.074982,
    "dec": 66.764384,
    "pmra": 303.53,
    "pmdec": -79.87,
    "rv": 7.4,
    "Asset Name": "ASTRID 001925",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Side Part",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.000017,
    "y_1": 0.00002641,
    "z_1": 0.00000387,
    "Distance in Parsecs_1": 20.1939,
    "HYG Star ID_1": 113584,
    "Hipparcos Catalogue_1": 113944,
    "Gliese Catalog_1": "GJ 1278"
  },
  {
    "Astrid #": "ASTRID 001926",
    "HYG Star ID": 110432,
    "Hipparcos Catalogue": 110778,
    "Henry Draper Catalogue": 212697,
    "Harvard Revised Catalogue": 8544,
    "Gliese Catalog": "Gl 859B",
    "Distance in Parsecs": 20.202,
    "mag": 5.55,
    "Absolute Visual Magnitude": 4.023,
    "Spectral Type": "G3V",
    "Color Index": 0.618,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.760317,
    "y": -7.669824,
    "z": -5.819492,
    "vx": 0.00001005,
    "vy": 0.00001804,
    "vz": -2.2e-7,
    "rarad": 5.8755251036945,
    "decrad": -0.292205430012066,
    "pmrarad": 0.00000101699365770138,
    "pmdecrad": 1.9198621e-8,
    "flam": 53,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 2,
    "comp_primary": 119501,
    "base": "Gl 859",
    "lum": 2.14190399072212,
    "bf": "53    Aqr",
    "ra": 22.442853,
    "dec": -16.742138,
    "pmra": 209.77,
    "pmdec": 3.96,
    "rv": 2,
    "Asset Name": "ASTRID 001926",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Ram Horns",
    "Outfit": "Sushi Chef",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001005,
    "y_1": 0.00001804,
    "z_1": -2.2e-7,
    "Distance in Parsecs_1": 20.202,
    "HYG Star ID_1": 110432,
    "Hipparcos Catalogue_1": 110778,
    "Henry Draper Catalogue_1": 212697,
    "Harvard Revised Catalogue_1": 8544,
    "Gliese Catalog_1": "Gl 859B"
  },
  {
    "Astrid #": "ASTRID 001927",
    "HYG Star ID": 118402,
    "Gliese Catalog": "NN 3373A",
    "Distance in Parsecs": 20.202,
    "mag": 12.9,
    "Absolute Visual Magnitude": 11.373,
    "Spectral Type": "k-m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.027704,
    "y": 7.518102,
    "z": 18.750953,
    "vx": -0.00003689,
    "vy": 0.00010068,
    "vz": -0.00004039,
    "rarad": 1.57432875538465,
    "decrad": 1.1894731537213,
    "pmrarad": 0.00000180922769309722,
    "pmdecrad": -0.000005376045574,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118402,
    "base": "NN 3373",
    "lum": 0.00245923482384623,
    "ra": 6.013493,
    "dec": 68.151792,
    "pmra": 373.18,
    "pmdec": -1108.89,
    "rv": 0,
    "Asset Name": "ASTRID 001927",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Fedora",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003689,
    "y_1": 0.00010068,
    "z_1": -0.00004039,
    "Distance in Parsecs_1": 20.202,
    "HYG Star ID_1": 118402,
    "Gliese Catalog_1": "NN 3373A"
  },
  {
    "Astrid #": "ASTRID 001928",
    "HYG Star ID": 118403,
    "Gliese Catalog": "NN 3374B",
    "Distance in Parsecs": 20.202,
    "mag": 13.31,
    "Absolute Visual Magnitude": 11.783,
    "Spectral Type": "m",
    "Color Index": 1.59,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.02601,
    "y": 7.522997,
    "z": 18.748991,
    "vx": -0.00003687,
    "vy": 0.00010068,
    "vz": -0.00004041,
    "rarad": 1.57410135238465,
    "decrad": 1.1892124147213,
    "pmrarad": 0.00000180922769309722,
    "pmdecrad": -0.000005376045574,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118403,
    "base": "NN 3374",
    "lum": 0.00168577651782897,
    "ra": 6.012624,
    "dec": 68.136852,
    "pmra": 373.18,
    "pmdec": -1108.89,
    "rv": 0,
    "Asset Name": "ASTRID 001928",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Mohawk",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003687,
    "y_1": 0.00010068,
    "z_1": -0.00004041,
    "Distance in Parsecs_1": 20.202,
    "HYG Star ID_1": 118403,
    "Gliese Catalog_1": "NN 3374B"
  },
  {
    "Astrid #": "ASTRID 001929",
    "HYG Star ID": 47754,
    "Hipparcos Catalogue": 47890,
    "Distance in Parsecs": 20.2102,
    "mag": 12.05,
    "Absolute Visual Magnitude": 10.522,
    "Color Index": 1.57,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -13.077134,
    "y": 8.68283,
    "z": -12.729855,
    "vx": 0.00001899,
    "vy": 0.00000223,
    "vz": -0.00001799,
    "rarad": 2.55546057329335,
    "decrad": -0.681389417249913,
    "pmrarad": -6.11737902125e-7,
    "pmdecrad": -0.000001145857133,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 47754,
    "lum": 0.00538517721997527,
    "var_min": 12.183,
    "var_max": 11.903,
    "ra": 9.76114,
    "dec": -39.040738,
    "pmra": -126.18,
    "pmdec": -236.35,
    "rv": 0,
    "Asset Name": "ASTRID 001929",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Serious Shinobi",
    "Hair": "Curly",
    "Outfit": "Tats",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001899,
    "y_1": 0.00000223,
    "z_1": -0.00001799,
    "Distance in Parsecs_1": 20.2102,
    "HYG Star ID_1": 47754,
    "Hipparcos Catalogue_1": 47890
  },
  {
    "Astrid #": "ASTRID 001930",
    "HYG Star ID": 66043,
    "Hipparcos Catalogue": 66252,
    "Henry Draper Catalogue": 118100,
    "Gliese Catalog": "Gl 517",
    "Distance in Parsecs": 20.2102,
    "mag": 9.24,
    "Absolute Visual Magnitude": 7.712,
    "Spectral Type": "K7V",
    "Color Index": 1.21,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.312533,
    "y": -8.031026,
    "z": -2.932108,
    "vx": 0.00001212,
    "vy": 0.00003606,
    "vz": -0.00000526,
    "rarad": 3.55488716126902,
    "decrad": -0.145596011177355,
    "pmrarad": -0.00000139311211107638,
    "pmdecrad": -4.41277412e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66043,
    "lum": 0.0716473282391108,
    "var": "EQ",
    "var_min": 9.331,
    "var_max": 9.181,
    "ra": 13.578669,
    "dec": -8.342037,
    "pmra": -287.35,
    "pmdec": -91.02,
    "rv": -24,
    "Asset Name": "ASTRID 001930",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Ninja",
    "Outfit": "Boxer",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00001212,
    "y_1": 0.00003606,
    "z_1": -0.00000526,
    "Distance in Parsecs_1": 20.2102,
    "HYG Star ID_1": 66043,
    "Hipparcos Catalogue_1": 66252,
    "Henry Draper Catalogue_1": 118100,
    "Gliese Catalog_1": "Gl 517"
  },
  {
    "Astrid #": "ASTRID 001931",
    "HYG Star ID": 83739,
    "Hipparcos Catalogue": 83996,
    "Gliese Catalog": "Gl 659B",
    "Distance in Parsecs": 20.2184,
    "mag": 9.34,
    "Absolute Visual Magnitude": 7.811,
    "Spectral Type": "K8",
    "Color Index": 1.257,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.531504,
    "y": -11.467639,
    "z": 16.458114,
    "vx": 0.00000624,
    "vy": -0.00001173,
    "vz": -0.00000357,
    "rarad": 4.49511882209585,
    "decrad": 0.951032349229045,
    "pmrarad": 4.262966693125e-7,
    "pmdecrad": -5.09345252e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 83734,
    "base": "Gl 659",
    "lum": 0.0654033509448894,
    "ra": 17.170089,
    "dec": 54.49014,
    "pmra": 87.93,
    "pmdec": -105.06,
    "rv": 2.9,
    "Asset Name": "ASTRID 001931",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Gimme Blood",
    "Hair": "Wavy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000624,
    "y_1": -0.00001173,
    "z_1": -0.00000357,
    "Distance in Parsecs_1": 20.2184,
    "HYG Star ID_1": 83739,
    "Hipparcos Catalogue_1": 83996,
    "Gliese Catalog_1": "Gl 659B"
  },
  {
    "Astrid #": "ASTRID 001932",
    "HYG Star ID": 7631,
    "Hipparcos Catalogue": 7646,
    "Gliese Catalog": "NN 3103",
    "Distance in Parsecs": 20.2265,
    "mag": 11.6,
    "Absolute Visual Magnitude": 10.07,
    "Spectral Type": "K:",
    "Color Index": 1.496,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.385837,
    "y": 8.427299,
    "z": 0.230014,
    "vx": -0.00002112,
    "vy": 0.00004565,
    "vz": 0.00001589,
    "rarad": 0.42978284880145,
    "decrad": 0.011372167203405,
    "pmrarad": 0.00000248694873714583,
    "pmdecrad": 7.85737532e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 7631,
    "lum": 0.00816582371358591,
    "var_min": 11.676,
    "var_max": 11.516,
    "ra": 1.64165,
    "dec": 0.651577,
    "pmra": 512.97,
    "pmdec": 162.07,
    "rv": 0,
    "Asset Name": "ASTRID 001932",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Sweetheart",
    "Outfit": "School Uniform",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002112,
    "y_1": 0.00004565,
    "z_1": 0.00001589,
    "Distance in Parsecs_1": 20.2265,
    "HYG Star ID_1": 7631,
    "Hipparcos Catalogue_1": 7646,
    "Gliese Catalog_1": "NN 3103"
  },
  {
    "Astrid #": "ASTRID 001933",
    "HYG Star ID": 25056,
    "Hipparcos Catalogue": 25119,
    "Henry Draper Catalogue": 35112,
    "Distance in Parsecs": 20.2265,
    "mag": 7.76,
    "Absolute Visual Magnitude": 6.23,
    "Spectral Type": "K3V",
    "Color Index": 0.98,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.280546,
    "y": 19.937537,
    "z": 0.918659,
    "vx": -0.00000514,
    "vy": 0.00000148,
    "vz": -0.00001366,
    "rarad": 1.40771639422888,
    "decrad": 0.04543424606092,
    "pmrarad": 2.62769014861111e-7,
    "pmdecrad": -6.75878752e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 25056,
    "lum": 0.280543363795171,
    "ra": 5.377081,
    "dec": 2.603191,
    "pmra": 54.2,
    "pmdec": -139.41,
    "rv": 0,
    "Asset Name": "ASTRID 001933",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Afro",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000514,
    "y_1": 0.00000148,
    "z_1": -0.00001366,
    "Distance in Parsecs_1": 20.2265,
    "HYG Star ID_1": 25056,
    "Hipparcos Catalogue_1": 25119,
    "Henry Draper Catalogue_1": 35112
  },
  {
    "Astrid #": "ASTRID 001934",
    "HYG Star ID": 71085,
    "Hipparcos Catalogue": 71311,
    "Gliese Catalog": "Gl 558",
    "Distance in Parsecs": 20.2306,
    "mag": 9.54,
    "Absolute Visual Magnitude": 8.01,
    "Spectral Type": "K8",
    "Color Index": 1.283,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.120413,
    "y": -10.526812,
    "z": 11.2383,
    "vx": 0.00000108,
    "vy": 0.00009034,
    "vz": -0.00000966,
    "rarad": 3.81775469746987,
    "decrad": 0.58899148816879,
    "pmrarad": -0.00000344954629988888,
    "pmdecrad": 0.000001178533576,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 71085,
    "lum": 0.054450265284242,
    "var_min": 9.605,
    "var_max": 9.495,
    "ra": 14.582749,
    "dec": 33.746726,
    "pmra": -711.52,
    "pmdec": 243.09,
    "rv": -51.9,
    "Asset Name": "ASTRID 001934",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Shocked",
    "Hair": "Bucket Hat",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000108,
    "y_1": 0.00009034,
    "z_1": -0.00000966,
    "Distance in Parsecs_1": 20.2306,
    "HYG Star ID_1": 71085,
    "Hipparcos Catalogue_1": 71311,
    "Gliese Catalog_1": "Gl 558"
  },
  {
    "Astrid #": "ASTRID 001935",
    "HYG Star ID": 36336,
    "Hipparcos Catalogue": 36439,
    "Henry Draper Catalogue": 58855,
    "Harvard Revised Catalogue": 2849,
    "Gliese Catalog": "Wo 9234",
    "Distance in Parsecs": 20.2388,
    "mag": 5.35,
    "Absolute Visual Magnitude": 3.819,
    "Spectral Type": "F6V",
    "Color Index": 0.47,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.008642,
    "y": 12.101997,
    "z": 15.429016,
    "vx": -0.0000056,
    "vy": -0.0000148,
    "vz": -0.00002602,
    "rarad": 1.96319877130015,
    "decrad": 0.866948021037633,
    "pmrarad": 5.35234303333333e-7,
    "pmdecrad": -3.97595699e-7,
    "flam": 22,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 36336,
    "lum": 2.58463963576984,
    "bf": "22    Lyn",
    "ra": 7.498867,
    "dec": 49.672463,
    "pmra": 110.4,
    "pmdec": -82.01,
    "rv": -26.7,
    "Asset Name": "ASTRID 001935",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Spiky",
    "Outfit": "Warlock",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.0000056,
    "y_1": -0.0000148,
    "z_1": -0.00002602,
    "Distance in Parsecs_1": 20.2388,
    "HYG Star ID_1": 36336,
    "Hipparcos Catalogue_1": 36439,
    "Henry Draper Catalogue_1": 58855,
    "Harvard Revised Catalogue_1": 2849,
    "Gliese Catalog_1": "Wo 9234"
  },
  {
    "Astrid #": "ASTRID 001936",
    "HYG Star ID": 50363,
    "Hipparcos Catalogue": 50505,
    "Henry Draper Catalogue": 89269,
    "Gliese Catalog": "NN 3593",
    "Distance in Parsecs": 20.2388,
    "mag": 6.66,
    "Absolute Visual Magnitude": 5.129,
    "Spectral Type": "G5",
    "Color Index": 0.653,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.153164,
    "y": 6.212852,
    "z": 14.071274,
    "vx": -0.00001559,
    "vy": 5.3e-7,
    "vz": -0.00002701,
    "rarad": 2.70031046768699,
    "decrad": 0.768788262638878,
    "pmrarad": 3.05335656013888e-7,
    "pmdecrad": -0.000001451241271,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 50363,
    "lum": 0.773392578048997,
    "ra": 10.314426,
    "dec": 44.048323,
    "pmra": 62.98,
    "pmdec": -299.34,
    "rv": -8.3,
    "Asset Name": "ASTRID 001936",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Bad Dreams",
    "Hair": "Heartbreaker",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001559,
    "y_1": 5.3e-7,
    "z_1": -0.00002701,
    "Distance in Parsecs_1": 20.2388,
    "HYG Star ID_1": 50363,
    "Hipparcos Catalogue_1": 50505,
    "Henry Draper Catalogue_1": 89269,
    "Gliese Catalog_1": "NN 3593"
  },
  {
    "Astrid #": "ASTRID 001937",
    "HYG Star ID": 118802,
    "Gliese Catalog": "NN 3710",
    "Distance in Parsecs": 20.2429,
    "mag": 15.79,
    "Absolute Visual Magnitude": 14.259,
    "Spectral Type": "DA9",
    "Color Index": 0.58,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -10.891966,
    "y": -0.547048,
    "z": 17.054056,
    "vx": -0.00002688,
    "vy": -0.00004175,
    "vz": -0.0000185,
    "rarad": 3.19169010096492,
    "decrad": 1.00185349648444,
    "pmrarad": 0.00000199369929854861,
    "pmdecrad": -0.000001696770311,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118802,
    "lum": 0.000172345520510916,
    "ra": 12.191358,
    "dec": 57.401977,
    "pmra": 411.23,
    "pmdec": -349.98,
    "rv": 0,
    "Asset Name": "ASTRID 001937",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Braided Bun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002688,
    "y_1": -0.00004175,
    "z_1": -0.0000185,
    "Distance in Parsecs_1": 20.2429,
    "HYG Star ID_1": 118802,
    "Gliese Catalog_1": "NN 3710"
  },
  {
    "Astrid #": "ASTRID 001938",
    "HYG Star ID": 118855,
    "Gliese Catalog": "NN 3756",
    "Distance in Parsecs": 20.2429,
    "mag": 14.4,
    "Absolute Visual Magnitude": 12.869,
    "Spectral Type": "m",
    "Color Index": 1.74,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.382019,
    "y": -3.08398,
    "z": 15.714632,
    "vx": -0.0000521,
    "vy": -0.00003763,
    "vz": -0.00004842,
    "rarad": 3.385660431908,
    "decrad": 0.88878017995755,
    "pmrarad": 0.00000118221816003472,
    "pmdecrad": -0.000003795620849,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118855,
    "lum": 0.000620011864208724,
    "ra": 12.93227,
    "dec": 50.923353,
    "pmra": 243.85,
    "pmdec": -782.9,
    "rv": 0,
    "Asset Name": "ASTRID 001938",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Cute Neko",
    "Hair": "Headband",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000521,
    "y_1": -0.00003763,
    "z_1": -0.00004842,
    "Distance in Parsecs_1": 20.2429,
    "HYG Star ID_1": 118855,
    "Gliese Catalog_1": "NN 3756"
  },
  {
    "Astrid #": "ASTRID 001939",
    "HYG Star ID": 54491,
    "Hipparcos Catalogue": 54651,
    "Henry Draper Catalogue": 97214,
    "Gliese Catalog": "Gl 415",
    "Distance in Parsecs": 20.2511,
    "mag": 9.23,
    "Absolute Visual Magnitude": 7.698,
    "Spectral Type": "K5V",
    "Color Index": 1.083,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.433257,
    "y": 4.203541,
    "z": -3.847119,
    "vx": -0.00003051,
    "vy": 0.00010124,
    "vz": 0.00004935,
    "rarad": 2.92856875677865,
    "decrad": -0.191129039653358,
    "pmrarad": -0.00000456825386777083,
    "pmdecrad": 0.000002873296759,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 54491,
    "lum": 0.0725771650267427,
    "ra": 11.186309,
    "dec": -10.950887,
    "pmra": -942.27,
    "pmdec": 592.66,
    "rv": 40,
    "Asset Name": "ASTRID 001939",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Side Part",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": -0.00003051,
    "y_1": 0.00010124,
    "z_1": 0.00004935,
    "Distance in Parsecs_1": 20.2511,
    "HYG Star ID_1": 54491,
    "Hipparcos Catalogue_1": 54651,
    "Henry Draper Catalogue_1": 97214,
    "Gliese Catalog_1": "Gl 415"
  },
  {
    "Astrid #": "ASTRID 001940",
    "HYG Star ID": 118367,
    "Gliese Catalog": "Gl 199B",
    "Distance in Parsecs": 20.2675,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.966,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.375001,
    "y": 18.56679,
    "z": -7.392916,
    "vx": 0.00001268,
    "vy": -0.0000038,
    "vz": -0.00000374,
    "rarad": 1.39098583657222,
    "decrad": -0.37338259919675,
    "pmrarad": -6.49068555527777e-7,
    "pmdecrad": -1.98443935e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 24723,
    "base": "Gl 199",
    "lum": 0.00142429516497329,
    "ra": 5.313175,
    "dec": -21.393247,
    "pmra": -133.88,
    "pmdec": -40.93,
    "rv": 0,
    "Asset Name": "ASTRID 001940",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Audiophile",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001268,
    "y_1": -0.0000038,
    "z_1": -0.00000374,
    "Distance in Parsecs_1": 20.2675,
    "HYG Star ID_1": 118367,
    "Gliese Catalog_1": "Gl 199B"
  },
  {
    "Astrid #": "ASTRID 001941",
    "HYG Star ID": 99445,
    "Hipparcos Catalogue": 99764,
    "Gliese Catalog": "NN 4140",
    "Distance in Parsecs": 20.2716,
    "mag": 10.19,
    "Absolute Visual Magnitude": 8.656,
    "Spectral Type": "K7V",
    "Color Index": 1.351,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.132754,
    "y": -16.745069,
    "z": -2.569477,
    "vx": -9.8e-7,
    "vy": 0.00000337,
    "vz": -0.00002619,
    "rarad": 5.29912438536153,
    "decrad": -0.127094438878797,
    "pmrarad": 5.15841756111111e-8,
    "pmdecrad": -0.000001302500434,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 99445,
    "lum": 0.0300330887867655,
    "ra": 20.241164,
    "dec": -7.281975,
    "pmra": 10.64,
    "pmdec": -268.66,
    "rv": 0,
    "Asset Name": "ASTRID 001941",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Pompadour",
    "Outfit": "Tats",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -9.8e-7,
    "y_1": 0.00000337,
    "z_1": -0.00002619,
    "Distance in Parsecs_1": 20.2716,
    "HYG Star ID_1": 99445,
    "Hipparcos Catalogue_1": 99764,
    "Gliese Catalog_1": "NN 4140"
  },
  {
    "Astrid #": "ASTRID 001942",
    "HYG Star ID": 117958,
    "Gliese Catalog": "Gl 4.1B",
    "Distance in Parsecs": 20.284,
    "mag": 7.2,
    "Absolute Visual Magnitude": 5.664,
    "Spectral Type": "dG8",
    "Color Index": 0.78,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.612845,
    "y": 0.289466,
    "z": 17.282686,
    "vx": -0.00001425,
    "vy": 0.0000245,
    "vz": -0.00001086,
    "rarad": 0.0272125062027954,
    "decrad": 1.0199268508379,
    "pmrarad": 0.00000122672405590972,
    "pmdecrad": 2.89976758e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 2,
    "comp_primary": 517,
    "base": "Gl   4.1",
    "lum": 0.472498027680194,
    "ra": 0.103944,
    "dec": 58.437504,
    "pmra": 253.03,
    "pmdec": 59.81,
    "rv": -16,
    "Asset Name": "ASTRID 001942",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Innocent",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00001425,
    "y_1": 0.0000245,
    "z_1": -0.00001086,
    "Distance in Parsecs_1": 20.284,
    "HYG Star ID_1": 117958,
    "Gliese Catalog_1": "Gl 4.1B"
  },
  {
    "Astrid #": "ASTRID 001943",
    "HYG Star ID": 118631,
    "Gliese Catalog": "Gl 351.1",
    "Distance in Parsecs": 20.284,
    "mag": 12.2,
    "Absolute Visual Magnitude": 10.664,
    "Spectral Type": "M3",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.172617,
    "y": 11.480076,
    "z": 7.031371,
    "vx": -0.00002299,
    "vy": 0.00001481,
    "vz": -0.00007376,
    "rarad": 2.49386069999923,
    "decrad": 0.3539931981897,
    "pmrarad": 1.01519984708333e-7,
    "pmdecrad": -0.000003877176206,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118631,
    "lum": 0.00472498027680194,
    "ra": 9.525846,
    "dec": 20.282316,
    "pmra": 20.94,
    "pmdec": -799.73,
    "rv": 0,
    "Asset Name": "ASTRID 001943",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Ponytail",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002299,
    "y_1": 0.00001481,
    "z_1": -0.00007376,
    "Distance in Parsecs_1": 20.284,
    "HYG Star ID_1": 118631,
    "Gliese Catalog_1": "Gl 351.1"
  },
  {
    "Astrid #": "ASTRID 001944",
    "HYG Star ID": 118794,
    "Gliese Catalog": "Gl 455",
    "Distance in Parsecs": 20.284,
    "mag": 12.84,
    "Absolute Visual Magnitude": 11.304,
    "Spectral Type": "M3",
    "Color Index": 1.75,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.81014,
    "y": -0.17963,
    "z": 9.706045,
    "vx": -0.0000019,
    "vy": 0.00007763,
    "vz": -0.00000202,
    "rarad": 3.15170464743857,
    "decrad": 0.4989541369533,
    "pmrarad": -0.00000382833122850694,
    "pmdecrad": -1.13620934e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118794,
    "lum": 0.00262059555473334,
    "ra": 12.038625,
    "dec": 28.587966,
    "pmra": -789.65,
    "pmdec": -23.44,
    "rv": 0,
    "Asset Name": "ASTRID 001944",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Elegant",
    "Outfit": "Sharky",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000019,
    "y_1": 0.00007763,
    "z_1": -0.00000202,
    "Distance in Parsecs_1": 20.284,
    "HYG Star ID_1": 118794,
    "Gliese Catalog_1": "Gl 455"
  },
  {
    "Astrid #": "ASTRID 001945",
    "HYG Star ID": 27734,
    "Hipparcos Catalogue": 27803,
    "Gliese Catalog": "Gl 221",
    "Distance in Parsecs": 20.3128,
    "mag": 9.7,
    "Absolute Visual Magnitude": 8.161,
    "Spectral Type": "M0V",
    "Color Index": 1.321,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.616518,
    "y": 20.192503,
    "z": -2.121469,
    "vx": 7e-7,
    "vy": 0.000019,
    "vz": -0.00003632,
    "rarad": 1.54027376451877,
    "decrad": -0.104629775732168,
    "pmrarad": -5.76928279861111e-9,
    "pmdecrad": -0.000001680655105,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 27734,
    "lum": 0.0473805393338825,
    "ra": 5.883412,
    "dec": -5.994845,
    "pmra": -1.19,
    "pmdec": -346.66,
    "rv": 22.2,
    "Asset Name": "ASTRID 001945",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 7e-7,
    "y_1": 0.000019,
    "z_1": -0.00003632,
    "Distance in Parsecs_1": 20.3128,
    "HYG Star ID_1": 27734,
    "Hipparcos Catalogue_1": 27803,
    "Gliese Catalog_1": "Gl 221"
  },
  {
    "Astrid #": "ASTRID 001946",
    "HYG Star ID": 103436,
    "Hipparcos Catalogue": 103768,
    "Henry Draper Catalogue": 199981,
    "Gliese Catalog": "Wo 9714",
    "Distance in Parsecs": 20.3128,
    "mag": 9.37,
    "Absolute Visual Magnitude": 7.831,
    "Spectral Type": "K5V",
    "Color Index": 1.239,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.197566,
    "y": -12.02301,
    "z": -10.921377,
    "vx": 0.00001233,
    "vy": 0.00002163,
    "vz": -0.00001004,
    "rarad": 5.50499204934686,
    "decrad": -0.567659191722716,
    "pmrarad": 0.00000118449678433333,
    "pmdecrad": -5.86139739e-7,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 103436,
    "lum": 0.0642096052567594,
    "ra": 21.027521,
    "dec": -32.524476,
    "pmra": 244.32,
    "pmdec": -120.9,
    "rv": 0,
    "Asset Name": "ASTRID 001946",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Infatuated",
    "Hair": "Sweetheart",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": 0.00001233,
    "y_1": 0.00002163,
    "z_1": -0.00001004,
    "Distance in Parsecs_1": 20.3128,
    "HYG Star ID_1": 103436,
    "Hipparcos Catalogue_1": 103768,
    "Henry Draper Catalogue_1": 199981,
    "Gliese Catalog_1": "Wo 9714"
  },
  {
    "Astrid #": "ASTRID 001947",
    "HYG Star ID": 96915,
    "Hipparcos Catalogue": 97223,
    "Henry Draper Catalogue": 332518,
    "Distance in Parsecs": 20.3169,
    "mag": 9.15,
    "Absolute Visual Magnitude": 7.611,
    "Spectral Type": "K5V",
    "Color Index": 1.175,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.822076,
    "y": -15.758607,
    "z": 10.161588,
    "vx": -0.00000403,
    "vy": -0.00000749,
    "vz": -0.00000852,
    "rarad": 5.17312762042666,
    "decrad": 0.523777159395057,
    "pmrarad": -3.41551237951388e-7,
    "pmdecrad": -4.84183422e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96915,
    "lum": 0.0786321227458418,
    "ra": 19.759892,
    "dec": 30.010221,
    "pmra": -70.45,
    "pmdec": -99.87,
    "rv": 0,
    "Asset Name": "ASTRID 001947",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oh Snap",
    "Hair": "Wavy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000403,
    "y_1": -0.00000749,
    "z_1": -0.00000852,
    "Distance in Parsecs_1": 20.3169,
    "HYG Star ID_1": 96915,
    "Hipparcos Catalogue_1": 97223,
    "Henry Draper Catalogue_1": 332518
  },
  {
    "Astrid #": "ASTRID 001948",
    "HYG Star ID": 113490,
    "Hipparcos Catalogue": 113850,
    "Gliese Catalog": "NN 4310",
    "Distance in Parsecs": 20.3169,
    "mag": 10.65,
    "Absolute Visual Magnitude": 9.111,
    "Spectral Type": "M3",
    "Color Index": 1.643,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.734474,
    "y": -3.213592,
    "z": -15.501046,
    "vx": -0.00000876,
    "vy": -0.00000114,
    "vz": -0.00000696,
    "rarad": 6.03599337982786,
    "decrad": -0.867884544606317,
    "pmrarad": -1.601339586875e-7,
    "pmdecrad": -5.30240722e-7,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 113490,
    "lum": 0.0197514962206075,
    "var_min": 10.714,
    "var_max": 10.564,
    "ra": 23.055796,
    "dec": -49.726122,
    "pmra": -33.03,
    "pmdec": -109.37,
    "rv": 0,
    "Asset Name": "ASTRID 001948",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Slick",
    "Outfit": "Wizard",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00000876,
    "y_1": -0.00000114,
    "z_1": -0.00000696,
    "Distance in Parsecs_1": 20.3169,
    "HYG Star ID_1": 113490,
    "Hipparcos Catalogue_1": 113850,
    "Gliese Catalog_1": "NN 4310"
  },
  {
    "Astrid #": "ASTRID 001949",
    "HYG Star ID": 118582,
    "Gliese Catalog": "GJ 1117",
    "Distance in Parsecs": 20.3252,
    "mag": 15.18,
    "Absolute Visual Magnitude": 13.64,
    "Spectral Type": "DQ6",
    "Color Index": 0.01,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -12.020111,
    "y": 12.099429,
    "z": 11.055972,
    "vx": 0.00002187,
    "vy": 0.00002132,
    "vz": 4.5e-7,
    "rarad": 2.35292037199985,
    "decrad": 0.575141945421,
    "pmrarad": -0.00000150268000288194,
    "pmdecrad": 2.622842e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118582,
    "lum": 0.000304789498962797,
    "ra": 8.987494,
    "dec": 32.953206,
    "pmra": -309.95,
    "pmdec": 5.41,
    "rv": 0,
    "Asset Name": "ASTRID 001949",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Fedora",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002187,
    "y_1": 0.00002132,
    "z_1": 4.5e-7,
    "Distance in Parsecs_1": 20.3252,
    "HYG Star ID_1": 118582,
    "Gliese Catalog_1": "GJ 1117"
  },
  {
    "Astrid #": "ASTRID 001950",
    "HYG Star ID": 81128,
    "Hipparcos Catalogue": 81375,
    "Henry Draper Catalogue": 149806,
    "Distance in Parsecs": 20.3335,
    "mag": 7.09,
    "Absolute Visual Magnitude": 5.549,
    "Spectral Type": "K0",
    "Color Index": 0.828,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.19227,
    "y": -19.018846,
    "z": 0.090262,
    "vx": 0.0000063,
    "vy": -0.00000891,
    "vz": 0.00000756,
    "rarad": 4.35084603056904,
    "decrad": 0.00443910270681125,
    "pmrarad": 4.44865033277777e-7,
    "pmdecrad": 3.70252207e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 81128,
    "lum": 0.525291048450047,
    "ra": 16.619008,
    "dec": 0.254342,
    "pmra": 91.76,
    "pmdec": 76.37,
    "rv": 6,
    "Asset Name": "ASTRID 001950",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Elegant",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.0000063,
    "y_1": -0.00000891,
    "z_1": 0.00000756,
    "Distance in Parsecs_1": 20.3335,
    "HYG Star ID_1": 81128,
    "Hipparcos Catalogue_1": 81375,
    "Henry Draper Catalogue_1": 149806
  },
  {
    "Astrid #": "ASTRID 001951",
    "HYG Star ID": 104549,
    "Hipparcos Catalogue": 104887,
    "Henry Draper Catalogue": 202444,
    "Harvard Revised Catalogue": 8130,
    "Gliese Catalog": "Gl 822.1A",
    "Distance in Parsecs": 20.3417,
    "mag": 3.74,
    "Absolute Visual Magnitude": 2.198,
    "Spectral Type": "F1IV",
    "Color Index": 0.393,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.034413,
    "y": -10.573279,
    "z": 12.536148,
    "vx": -0.00001996,
    "vy": 0.00004323,
    "vz": 0.00001728,
    "rarad": 5.56232510752511,
    "decrad": 0.664016045539213,
    "pmrarad": 9.48974298319444e-7,
    "pmdecrad": 0.000001987833053,
    "bayer": "Tau",
    "flam": 65,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 104549,
    "base": "Gl 822.1",
    "lum": 11.5027054779011,
    "var": "Tau",
    "var_min": 3.748,
    "var_max": 3.738,
    "bf": "65Tau Cyg",
    "ra": 21.246517,
    "dec": 38.045317,
    "pmra": 195.74,
    "pmdec": 410.02,
    "rv": -23.1,
    "Asset Name": "ASTRID 001951",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Pompadour",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00001996,
    "y_1": 0.00004323,
    "z_1": 0.00001728,
    "Distance in Parsecs_1": 20.3417,
    "HYG Star ID_1": 104549,
    "Hipparcos Catalogue_1": 104887,
    "Henry Draper Catalogue_1": 202444,
    "Harvard Revised Catalogue_1": 8130,
    "Gliese Catalog_1": "Gl 822.1A"
  },
  {
    "Astrid #": "ASTRID 001952",
    "HYG Star ID": 45040,
    "Hipparcos Catalogue": 45170,
    "Henry Draper Catalogue": 79096,
    "Harvard Revised Catalogue": 3650,
    "Gliese Catalog": "Gl 337A",
    "Distance in Parsecs": 20.3625,
    "mag": 6.49,
    "Absolute Visual Magnitude": 4.946,
    "Spectral Type": "G9V",
    "Color Index": 0.731,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.634044,
    "y": 13.142774,
    "z": 5.268959,
    "vx": 0.00000235,
    "vy": 0.00006747,
    "vz": 0.00003666,
    "rarad": 2.40983132935478,
    "decrad": 0.261730220708031,
    "pmrarad": -0.00000254270231040972,
    "pmdecrad": 0.000001188423775,
    "bayer": "Pi-1",
    "flam": 81,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 45040,
    "base": "Gl 337",
    "lum": 0.915377008362352,
    "bf": "81Pi 1Cnc",
    "ra": 9.204878,
    "dec": 14.996037,
    "pmra": -524.47,
    "pmdec": 245.13,
    "rv": 50.2,
    "Asset Name": "ASTRID 001952",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Wavy",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00000235,
    "y_1": 0.00006747,
    "z_1": 0.00003666,
    "Distance in Parsecs_1": 20.3625,
    "HYG Star ID_1": 45040,
    "Hipparcos Catalogue_1": 45170,
    "Henry Draper Catalogue_1": 79096,
    "Harvard Revised Catalogue_1": 3650,
    "Gliese Catalog_1": "Gl 337A"
  },
  {
    "Astrid #": "ASTRID 001953",
    "HYG Star ID": 119299,
    "Gliese Catalog": "Gl 765.4B",
    "Distance in Parsecs": 20.3707,
    "mag": 8.54,
    "Absolute Visual Magnitude": 6.995,
    "Spectral Type": "K3 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.540797,
    "y": -15.199254,
    "z": 11.273175,
    "vx": 0.00001196,
    "vy": -0.00002028,
    "vz": -0.00003534,
    "rarad": 5.17293448794206,
    "decrad": 0.586442529173899,
    "pmrarad": 8.36788412638889e-8,
    "pmdecrad": -0.000002083016522,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 96914,
    "base": "Gl 765.4",
    "lum": 0.138675582887188,
    "ra": 19.759154,
    "dec": 33.600682,
    "pmra": 17.26,
    "pmdec": -429.65,
    "rv": 0,
    "Asset Name": "ASTRID 001953",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Happy Vamp",
    "Hair": "High Fade",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001196,
    "y_1": -0.00002028,
    "z_1": -0.00003534,
    "Distance in Parsecs_1": 20.3707,
    "HYG Star ID_1": 119299,
    "Gliese Catalog_1": "Gl 765.4B"
  },
  {
    "Astrid #": "ASTRID 001954",
    "HYG Star ID": 44910,
    "Hipparcos Catalogue": 45038,
    "Henry Draper Catalogue": 78154,
    "Harvard Revised Catalogue": 3616,
    "Gliese Catalog": "Gl 335A",
    "Distance in Parsecs": 20.3791,
    "mag": 4.8,
    "Absolute Visual Magnitude": 3.254,
    "Spectral Type": "F7IV-V",
    "Color Index": 0.489,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.847532,
    "y": 5.339885,
    "z": 18.777611,
    "vx": -0.00000611,
    "vy": 0.00000386,
    "vz": -0.00000622,
    "rarad": 2.40153913538501,
    "decrad": 1.17170965303425,
    "pmrarad": 6.24440020555555e-8,
    "pmdecrad": -4.40453228e-7,
    "bayer": "Sig-2",
    "flam": 13,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 44910,
    "base": "Gl 335",
    "lum": 4.34910607232453,
    "bf": "13Sig2UMa",
    "ra": 9.173204,
    "dec": 67.134018,
    "pmra": 12.88,
    "pmdec": -90.85,
    "rv": -2.9,
    "Asset Name": "ASTRID 001954",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Chuckle",
    "Hair": "Innocent",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000611,
    "y_1": 0.00000386,
    "z_1": -0.00000622,
    "Distance in Parsecs_1": 20.3791,
    "HYG Star ID_1": 44910,
    "Hipparcos Catalogue_1": 45038,
    "Henry Draper Catalogue_1": 78154,
    "Harvard Revised Catalogue_1": 3616,
    "Gliese Catalog_1": "Gl 335A"
  },
  {
    "Astrid #": "ASTRID 001955",
    "HYG Star ID": 4835,
    "Hipparcos Catalogue": 4845,
    "Gliese Catalog": "NN 3072",
    "Distance in Parsecs": 20.3832,
    "mag": 10.06,
    "Absolute Visual Magnitude": 8.514,
    "Spectral Type": "K7V",
    "Color Index": 1.365,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.309282,
    "y": 5.386907,
    "z": -3.687876,
    "vx": -0.00002302,
    "vy": -0.00000424,
    "vz": -0.00001367,
    "rarad": 0.272062754419921,
    "decrad": -0.181930738516328,
    "pmrarad": 1.03362276694444e-7,
    "pmdecrad": -8.66459009e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 4835,
    "lum": 0.0342294562316041,
    "var_min": 10.104,
    "var_max": 9.994,
    "ra": 1.039203,
    "dec": -10.423863,
    "pmra": 21.32,
    "pmdec": -178.72,
    "rv": -20,
    "Asset Name": "ASTRID 001955",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Unkempt",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002302,
    "y_1": -0.00000424,
    "z_1": -0.00001367,
    "Distance in Parsecs_1": 20.3832,
    "HYG Star ID_1": 4835,
    "Hipparcos Catalogue_1": 4845,
    "Gliese Catalog_1": "NN 3072"
  },
  {
    "Astrid #": "ASTRID 001956",
    "HYG Star ID": 8367,
    "Hipparcos Catalogue": 8382,
    "Gliese Catalog": "NN 3114",
    "Distance in Parsecs": 20.3832,
    "mag": 12.24,
    "Absolute Visual Magnitude": 10.694,
    "Spectral Type": "M2",
    "Color Index": 1.695,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.929193,
    "y": 8.632012,
    "z": 7.373305,
    "vx": -0.00000853,
    "vy": 0.00003348,
    "vz": -0.00001961,
    "rarad": 0.471526524597677,
    "decrad": 0.370127626527156,
    "pmrarad": 0.00000165355402027083,
    "pmdecrad": -0.000001032168325,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 8367,
    "lum": 0.00459621144666001,
    "var_min": 12.374,
    "var_max": 12.074,
    "ra": 1.801099,
    "dec": 21.206751,
    "pmra": 341.07,
    "pmdec": -212.9,
    "rv": 0,
    "Asset Name": "ASTRID 001956",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Innocent",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000853,
    "y_1": 0.00003348,
    "z_1": -0.00001961,
    "Distance in Parsecs_1": 20.3832,
    "HYG Star ID_1": 8367,
    "Hipparcos Catalogue_1": 8382,
    "Gliese Catalog_1": "NN 3114"
  },
  {
    "Astrid #": "ASTRID 001957",
    "HYG Star ID": 19207,
    "Hipparcos Catalogue": 19255,
    "Henry Draper Catalogue": 25893,
    "Distance in Parsecs": 20.3832,
    "mag": 7.13,
    "Absolute Visual Magnitude": 5.584,
    "Spectral Type": "G5",
    "Color Index": 0.865,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.559565,
    "y": 14.153726,
    "z": 12.570181,
    "vx": 0.00000141,
    "vy": 0.00003891,
    "vz": -0.00000104,
    "rarad": 1.08023703832181,
    "decrad": 0.66452602935675,
    "pmrarad": 8.38436779152777e-7,
    "pmdecrad": -0.0000010985878,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 19207,
    "lum": 0.508627690306436,
    "var": "V491",
    "var_min": 7.145,
    "var_max": 7.115,
    "ra": 4.126202,
    "dec": 38.074537,
    "pmra": 172.94,
    "pmdec": -226.6,
    "rv": 26.3,
    "Asset Name": "ASTRID 001957",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Gimme Blood",
    "Hair": "Ponytail",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00000141,
    "y_1": 0.00003891,
    "z_1": -0.00000104,
    "Distance in Parsecs_1": 20.3832,
    "HYG Star ID_1": 19207,
    "Hipparcos Catalogue_1": 19255,
    "Henry Draper Catalogue_1": 25893
  },
  {
    "Astrid #": "ASTRID 001958",
    "HYG Star ID": 62278,
    "Hipparcos Catalogue": 62472,
    "Henry Draper Catalogue": 111261,
    "Gliese Catalog": "GJ 1164A",
    "Distance in Parsecs": 20.3832,
    "mag": 8.9,
    "Absolute Visual Magnitude": 7.354,
    "Spectral Type": "K4/K5V",
    "Color Index": 1.149,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.095102,
    "y": -3.860961,
    "z": -8.551907,
    "vx": -0.00001336,
    "vy": 0.00002869,
    "vz": 0.00001531,
    "rarad": 3.35181215922334,
    "decrad": -0.432956817277452,
    "pmrarad": -0.00000151334590385416,
    "pmdecrad": 8.27625434e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 62278,
    "base": "GJ 1164",
    "lum": 0.0996322641954417,
    "var_min": 9.122,
    "var_max": 8.852,
    "ra": 12.802979,
    "dec": -24.806598,
    "pmra": -312.15,
    "pmdec": 170.71,
    "rv": 0,
    "Asset Name": "ASTRID 001958",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "High Bun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001336,
    "y_1": 0.00002869,
    "z_1": 0.00001531,
    "Distance in Parsecs_1": 20.3832,
    "HYG Star ID_1": 62278,
    "Hipparcos Catalogue_1": 62472,
    "Henry Draper Catalogue_1": 111261,
    "Gliese Catalog_1": "GJ 1164A"
  },
  {
    "Astrid #": "ASTRID 001959",
    "HYG Star ID": 99134,
    "Hipparcos Catalogue": 99452,
    "Henry Draper Catalogue": 191785,
    "Gliese Catalog": "Gl 783.2A",
    "Distance in Parsecs": 20.3915,
    "mag": 7.34,
    "Absolute Visual Magnitude": 5.793,
    "Spectral Type": "K1V",
    "Color Index": 0.83,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.600964,
    "y": -16.465053,
    "z": 5.684825,
    "vx": -0.00006606,
    "vy": 0.00002697,
    "vz": 0.00002412,
    "rarad": 5.28442677329175,
    "decrad": 0.282533891845011,
    "pmrarad": -0.00000200819522759722,
    "pmdecrad": 0.000001935182287,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 1,
    "comp_primary": 99134,
    "base": "Gl 783.2",
    "lum": 0.419565722338725,
    "ra": 20.185023,
    "dec": 16.188,
    "pmra": -414.22,
    "pmdec": 399.16,
    "rv": -48.3,
    "Asset Name": "ASTRID 001959",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Battle Ready",
    "Hair": "Medium Bob",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": -0.00006606,
    "y_1": 0.00002697,
    "z_1": 0.00002412,
    "Distance in Parsecs_1": 20.3915,
    "HYG Star ID_1": 99134,
    "Hipparcos Catalogue_1": 99452,
    "Henry Draper Catalogue_1": 191785,
    "Gliese Catalog_1": "Gl 783.2A"
  },
  {
    "Astrid #": "ASTRID 001960",
    "HYG Star ID": 110576,
    "Hipparcos Catalogue": 110922,
    "Distance in Parsecs": 20.4082,
    "mag": 11.97,
    "Absolute Visual Magnitude": 10.421,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.910841,
    "y": -7.144261,
    "z": -8.915023,
    "vx": -0.0000129,
    "vy": 0.00000724,
    "vz": -0.00003028,
    "rarad": 5.88346257816135,
    "decrad": -0.452077579084929,
    "pmrarad": 8.08184405486111e-8,
    "pmdecrad": -0.000001649723992,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 110576,
    "lum": 0.00591017036610243,
    "var_min": 12.083,
    "var_max": 11.793,
    "ra": 22.473172,
    "dec": -25.902137,
    "pmra": 16.67,
    "pmdec": -340.28,
    "rv": 0,
    "Asset Name": "ASTRID 001960",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Angry",
    "Hair": "Grecian",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000129,
    "y_1": 0.00000724,
    "z_1": -0.00003028,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 110576,
    "Hipparcos Catalogue_1": 110922
  },
  {
    "Astrid #": "ASTRID 001961",
    "HYG Star ID": 118003,
    "Gliese Catalog": "NN 3045",
    "Distance in Parsecs": 20.4082,
    "mag": 12.6,
    "Absolute Visual Magnitude": 11.051,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.534575,
    "y": 2.128635,
    "z": 15.963959,
    "vx": 0.00000193,
    "vy": -0.00002656,
    "vz": 0.00000203,
    "rarad": 0.168255508390873,
    "decrad": 0.898241423154649,
    "pmrarad": -0.00000129925218252083,
    "pmdecrad": 1.59523093e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118003,
    "lum": 0.00330826278855697,
    "ra": 0.642689,
    "dec": 51.465443,
    "pmra": -267.99,
    "pmdec": 32.9,
    "rv": 0,
    "Asset Name": "ASTRID 001961",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Mischievous",
    "Hair": "Bangs",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000193,
    "y_1": -0.00002656,
    "z_1": 0.00000203,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118003,
    "Gliese Catalog_1": "NN 3045"
  },
  {
    "Astrid #": "ASTRID 001962",
    "HYG Star ID": 118115,
    "Gliese Catalog": "NN 3137",
    "Distance in Parsecs": 20.4082,
    "mag": 12.89,
    "Absolute Visual Magnitude": 11.341,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.454541,
    "y": 0.337769,
    "z": 20.400342,
    "vx": -0.00002809,
    "vy": -0.00003193,
    "vz": 0.00000116,
    "rarad": 0.639829684828479,
    "decrad": 1.5430441050442,
    "pmrarad": -4.33423430416666e-7,
    "pmdecrad": 0.000002039140884,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118115,
    "lum": 0.00253279476568999,
    "ra": 2.443969,
    "dec": 88.409915,
    "pmra": -89.4,
    "pmdec": 420.6,
    "rv": 0,
    "Asset Name": "ASTRID 001962",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "High Fade",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002809,
    "y_1": -0.00003193,
    "z_1": 0.00000116,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118115,
    "Gliese Catalog_1": "NN 3137"
  },
  {
    "Astrid #": "ASTRID 001963",
    "HYG Star ID": 118116,
    "Gliese Catalog": "Gl 85",
    "Distance in Parsecs": 20.4082,
    "mag": 11.52,
    "Absolute Visual Magnitude": 9.971,
    "Spectral Type": "m",
    "Color Index": 1.53,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.893117,
    "y": 4.281452,
    "z": -18.72378,
    "vx": -0.00007312,
    "vy": 0.00015824,
    "vz": 0.00004597,
    "rarad": 0.55516201874703,
    "decrad": -1.16184778245405,
    "pmrarad": 0.00000847895494060416,
    "pmdecrad": 0.000001848710919,
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 118116,
    "lum": 0.00894540483778868,
    "ra": 2.120563,
    "dec": -66.568974,
    "pmra": 1748.91,
    "pmdec": 381.32,
    "rv": -33,
    "Asset Name": "ASTRID 001963",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Unkempt",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": -0.00007312,
    "y_1": 0.00015824,
    "z_1": 0.00004597,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118116,
    "Gliese Catalog_1": "Gl 85"
  },
  {
    "Astrid #": "ASTRID 001964",
    "HYG Star ID": 118411,
    "Gliese Catalog": "NN 3382",
    "Distance in Parsecs": 20.4082,
    "mag": 13.25,
    "Absolute Visual Magnitude": 11.701,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.198679,
    "y": 19.136208,
    "z": -7.08948,
    "vx": 0.00000334,
    "vy": 0.00001959,
    "vz": 0.00005277,
    "rarad": 1.58117888825918,
    "decrad": -0.35477974980875,
    "pmrarad": -1.73514816270833e-7,
    "pmdecrad": 0.000002757983825,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118411,
    "lum": 0.00181802562377426,
    "ra": 6.039658,
    "dec": -20.327382,
    "pmra": -35.79,
    "pmdec": 568.88,
    "rv": 0,
    "Asset Name": "ASTRID 001964",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mortified",
    "Hair": "Bob",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000334,
    "y_1": 0.00001959,
    "z_1": 0.00005277,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118411,
    "Gliese Catalog_1": "NN 3382"
  },
  {
    "Astrid #": "ASTRID 001965",
    "HYG Star ID": 118544,
    "Gliese Catalog": "NN 3502B",
    "Distance in Parsecs": 20.4082,
    "mag": 12.36,
    "Absolute Visual Magnitude": 10.811,
    "Spectral Type": "M3",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.456195,
    "y": 16.022862,
    "z": -2.146098,
    "vx": 0.00003349,
    "vy": 0.00002496,
    "vz": -0.00000807,
    "rarad": 2.23160550707271,
    "decrad": -0.105353394753999,
    "pmrarad": -0.000002046398545625,
    "pmdecrad": -3.9777508e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118544,
    "base": "NN 3502",
    "lum": 0.00412667246322935,
    "ra": 8.524105,
    "dec": -6.036305,
    "pmra": -422.1,
    "pmdec": -82.05,
    "rv": 0,
    "Asset Name": "ASTRID 001965",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Windy",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003349,
    "y_1": 0.00002496,
    "z_1": -0.00000807,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118544,
    "Gliese Catalog_1": "NN 3502B"
  },
  {
    "Astrid #": "ASTRID 001966",
    "HYG Star ID": 118592,
    "Gliese Catalog": "NN 3527",
    "Distance in Parsecs": 20.4082,
    "mag": 11.82,
    "Absolute Visual Magnitude": 10.271,
    "Spectral Type": "k",
    "Color Index": 1.5,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.495903,
    "y": 14.348676,
    "z": 0.69204,
    "vx": 0.00002568,
    "vy": 0.00002632,
    "vz": -0.00000781,
    "rarad": 2.36129863237746,
    "decrad": 0.0339164234052,
    "pmrarad": -0.00000180205245062499,
    "pmdecrad": -3.83031896e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 118592,
    "lum": 0.00678578350950406,
    "ra": 9.019496,
    "dec": 1.943268,
    "pmra": -371.7,
    "pmdec": -79.01,
    "rv": 0,
    "Asset Name": "ASTRID 001966",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Slick",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00002568,
    "y_1": 0.00002632,
    "z_1": -0.00000781,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118592,
    "Gliese Catalog_1": "NN 3527"
  },
  {
    "Astrid #": "ASTRID 001967",
    "HYG Star ID": 118626,
    "Gliese Catalog": "Gl 346",
    "Distance in Parsecs": 20.4082,
    "mag": 10.52,
    "Absolute Visual Magnitude": 8.971,
    "Spectral Type": "dM0",
    "Color Index": 1.43,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -15.918036,
    "y": 12.341538,
    "z": -3.285906,
    "vx": -5.5e-7,
    "vy": -0.00000192,
    "vz": -0.00000453,
    "rarad": 2.48208399528011,
    "decrad": -0.1617129787387,
    "pmrarad": 9.08056023680555e-8,
    "pmdecrad": -2.24754774e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 118626,
    "lum": 0.0224698410364015,
    "ra": 9.480862,
    "dec": -9.265471,
    "pmra": 18.73,
    "pmdec": -46.36,
    "rv": 0,
    "Asset Name": "ASTRID 001967",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Bring It",
    "Hair": "Bangs",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -5.5e-7,
    "y_1": -0.00000192,
    "z_1": -0.00000453,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118626,
    "Gliese Catalog_1": "Gl 346"
  },
  {
    "Astrid #": "ASTRID 001968",
    "HYG Star ID": 118693,
    "Gliese Catalog": "NN 3610A",
    "Distance in Parsecs": 20.4082,
    "mag": 13.13,
    "Absolute Visual Magnitude": 11.581,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.128931,
    "y": 5.138248,
    "z": 14.755482,
    "vx": 0.00000825,
    "vy": 0.00003921,
    "vz": -0.0000063,
    "rarad": 2.76859276489137,
    "decrad": 0.80816005605955,
    "pmrarad": -0.00000193678217245138,
    "pmdecrad": -4.47138809e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118693,
    "base": "NN 3610",
    "lum": 0.0020304860027168,
    "ra": 10.575245,
    "dec": 46.30416,
    "pmra": -399.49,
    "pmdec": -92.23,
    "rv": 0,
    "Asset Name": "ASTRID 001968",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Braided Bun",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000825,
    "y_1": 0.00003921,
    "z_1": -0.0000063,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118693,
    "Gliese Catalog_1": "NN 3610A"
  },
  {
    "Astrid #": "ASTRID 001969",
    "HYG Star ID": 118694,
    "Gliese Catalog": "NN 3611B",
    "Distance in Parsecs": 20.4082,
    "mag": 15.5,
    "Absolute Visual Magnitude": 13.951,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.126635,
    "y": 5.14173,
    "z": 14.75631,
    "vx": 0.00000827,
    "vy": 0.00003921,
    "vz": -0.0000063,
    "rarad": 2.76830348489137,
    "decrad": 0.808218765059549,
    "pmrarad": -0.00000193678217245138,
    "pmdecrad": -4.47138809e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118694,
    "base": "NN 3611",
    "lum": 0.000228875865706235,
    "ra": 10.57414,
    "dec": 46.307524,
    "pmra": -399.49,
    "pmdec": -92.23,
    "rv": 0,
    "Asset Name": "ASTRID 001969",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Determined Neko",
    "Hair": "Unkempt",
    "Outfit": "Iron Star",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000827,
    "y_1": 0.00003921,
    "z_1": -0.0000063,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118694,
    "Gliese Catalog_1": "NN 3611B"
  },
  {
    "Astrid #": "ASTRID 001970",
    "HYG Star ID": 118724,
    "Gliese Catalog": "NN 3636",
    "Distance in Parsecs": 20.4082,
    "mag": 15.96,
    "Absolute Visual Magnitude": 14.411,
    "Spectral Type": "M5  e",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.295584,
    "y": 5.097835,
    "z": 4.264637,
    "vx": 0.00000544,
    "vy": 0.00003929,
    "vz": -0.00002232,
    "rarad": 2.88329954634977,
    "decrad": 0.2105183641964,
    "pmrarad": -0.00000192941300450694,
    "pmdecrad": -0.000001118436072,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118724,
    "lum": 0.000149830421062265,
    "ra": 11.013393,
    "dec": 12.061814,
    "pmra": -397.97,
    "pmdec": -230.69,
    "rv": 0,
    "Asset Name": "ASTRID 001970",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Halo Boy",
    "Outfit": "Tats",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000544,
    "y_1": 0.00003929,
    "z_1": -0.00002232,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118724,
    "Gliese Catalog_1": "NN 3636"
  },
  {
    "Astrid #": "ASTRID 001971",
    "HYG Star ID": 118961,
    "Gliese Catalog": "NN 3842",
    "Distance in Parsecs": 20.4082,
    "mag": 12.31,
    "Absolute Visual Magnitude": 10.761,
    "Spectral Type": "M0:e:",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.983724,
    "y": -9.094217,
    "z": 12.853512,
    "vx": -0.0000789,
    "vy": 0.00001853,
    "vz": -0.00006655,
    "rarad": 3.75265115257245,
    "decrad": 0.68132260773995,
    "pmrarad": -0.00000296230855093055,
    "pmdecrad": -0.000004199325201,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118961,
    "lum": 0.00432115654470299,
    "ra": 14.334072,
    "dec": 39.03691,
    "pmra": -611.02,
    "pmdec": -866.17,
    "rv": 0,
    "Asset Name": "ASTRID 001971",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Bandana",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000789,
    "y_1": 0.00001853,
    "z_1": -0.00006655,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 118961,
    "Gliese Catalog_1": "NN 3842"
  },
  {
    "Astrid #": "ASTRID 001972",
    "HYG Star ID": 119004,
    "Gliese Catalog": "NN 3888",
    "Distance in Parsecs": 20.4082,
    "mag": 14.7,
    "Absolute Visual Magnitude": 13.151,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.167175,
    "y": -13.749709,
    "z": -7.352934,
    "vx": -0.00004081,
    "vy": 0.00005043,
    "vz": -0.00002126,
    "rarad": 3.94864074974294,
    "decrad": -0.36858210459785,
    "pmrarad": -0.00000315332514106944,
    "pmdecrad": -0.000001116651957,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119004,
    "lum": 0.000478189460665907,
    "ra": 15.082697,
    "dec": -21.118199,
    "pmra": -650.42,
    "pmdec": -230.33,
    "rv": 0,
    "Asset Name": "ASTRID 001972",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Bucket Hat",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004081,
    "y_1": 0.00005043,
    "z_1": -0.00002126,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119004,
    "Gliese Catalog_1": "NN 3888"
  },
  {
    "Astrid #": "ASTRID 001973",
    "HYG Star ID": 119053,
    "Gliese Catalog": "NN 3934",
    "Distance in Parsecs": 20.4082,
    "mag": 13.2,
    "Absolute Visual Magnitude": 11.651,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.072661,
    "y": -16.100509,
    "z": -8.65766,
    "vx": -0.00000738,
    "vy": 0.00003342,
    "vz": -0.00005444,
    "rarad": 4.19924491065312,
    "decrad": -0.43810540712275,
    "pmrarad": -0.00000111904693745833,
    "pmdecrad": -0.000002945902455,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119053,
    "lum": 0.00190370653174207,
    "ra": 16.039934,
    "dec": -25.101591,
    "pmra": -230.82,
    "pmdec": -607.64,
    "rv": 0,
    "Asset Name": "ASTRID 001973",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Skater Helmet",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000738,
    "y_1": 0.00003342,
    "z_1": -0.00005444,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119053,
    "Gliese Catalog_1": "NN 3934"
  },
  {
    "Astrid #": "ASTRID 001974",
    "HYG Star ID": 119204,
    "Gliese Catalog": "NN 4045",
    "Distance in Parsecs": 20.4082,
    "mag": 14.1,
    "Absolute Visual Magnitude": 12.551,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.387089,
    "y": -4.555931,
    "z": -19.889402,
    "vx": -0.00002779,
    "vy": 0.00005318,
    "vz": -0.00001273,
    "rarad": 4.79734577377359,
    "decrad": -1.34483382898015,
    "pmrarad": -0.00000113572452806944,
    "pmdecrad": -0.000002783019603,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119204,
    "lum": 0.000830998041044431,
    "ra": 18.324511,
    "dec": -77.053303,
    "pmra": -234.26,
    "pmdec": -574.04,
    "rv": 0,
    "Asset Name": "ASTRID 001974",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous",
    "Hair": "Prince",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002779,
    "y_1": 0.00005318,
    "z_1": -0.00001273,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119204,
    "Gliese Catalog_1": "NN 4045"
  },
  {
    "Astrid #": "ASTRID 001975",
    "HYG Star ID": 119228,
    "Gliese Catalog": "NN 4067",
    "Distance in Parsecs": 20.4082,
    "mag": 13.12,
    "Absolute Visual Magnitude": 11.571,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.957291,
    "y": -16.776415,
    "z": 11.23837,
    "vx": -0.0000013,
    "vy": 0.00001634,
    "vz": 0.00002474,
    "rarad": 4.88687234978739,
    "decrad": 0.58317766718045,
    "pmrarad": 7.61157478472222e-8,
    "pmdecrad": 0.000001452443609,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119228,
    "lum": 0.00204927385838024,
    "ra": 18.666477,
    "dec": 33.413619,
    "pmra": 15.7,
    "pmdec": 299.59,
    "rv": 0,
    "Asset Name": "ASTRID 001975",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Serious Slumber",
    "Hair": "Braided Bun",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000013,
    "y_1": 0.00001634,
    "z_1": 0.00002474,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119228,
    "Gliese Catalog_1": "NN 4067"
  },
  {
    "Astrid #": "ASTRID 001976",
    "HYG Star ID": 119242,
    "Gliese Catalog": "NN 4081",
    "Distance in Parsecs": 20.4082,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.851,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.243312,
    "y": -9.711599,
    "z": -17.808623,
    "vx": -0.00005174,
    "vy": -0.00004152,
    "vz": 0.00001611,
    "rarad": 4.93955372029999,
    "decrad": -1.06054326104545,
    "pmrarad": -0.00000292861400013194,
    "pmdecrad": 0.000001616679091,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119242,
    "lum": 0.00158343412391457,
    "ra": 18.867705,
    "dec": -60.764653,
    "pmra": -604.07,
    "pmdec": 333.46,
    "rv": 0,
    "Asset Name": "ASTRID 001976",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Angelic",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005174,
    "y_1": -0.00004152,
    "z_1": 0.00001611,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119242,
    "Gliese Catalog_1": "NN 4081"
  },
  {
    "Astrid #": "ASTRID 001977",
    "HYG Star ID": 119330,
    "Gliese Catalog": "NN 4136",
    "Distance in Parsecs": 20.4082,
    "mag": 13.55,
    "Absolute Visual Magnitude": 12.001,
    "Spectral Type": "k-m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.807452,
    "y": -17.30291,
    "z": -0.550385,
    "vx": 0.00002573,
    "vy": 0.00001686,
    "vz": -0.0000249,
    "rarad": 5.27070282059036,
    "decrad": -0.02697207558905,
    "pmrarad": 0.000001507091807375,
    "pmdecrad": -0.000001220411781,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119330,
    "lum": 0.00137911346902359,
    "ra": 20.132602,
    "dec": -1.545386,
    "pmra": 310.86,
    "pmdec": -251.73,
    "rv": 0,
    "Asset Name": "ASTRID 001977",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Elegant",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002573,
    "y_1": 0.00001686,
    "z_1": -0.0000249,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119330,
    "Gliese Catalog_1": "NN 4136"
  },
  {
    "Astrid #": "ASTRID 001978",
    "HYG Star ID": 119355,
    "Gliese Catalog": "NN 4152",
    "Distance in Parsecs": 20.4082,
    "mag": 13.35,
    "Absolute Visual Magnitude": 11.801,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.045459,
    "y": -12.319425,
    "z": 12.799029,
    "vx": 0.00001895,
    "vy": 0.00000266,
    "vz": -0.00001231,
    "rarad": 5.39644829521172,
    "decrad": 0.67789043541675,
    "pmrarad": 8.02124234479166e-7,
    "pmdecrad": -7.74591665e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119355,
    "lum": 0.00165805907510891,
    "ra": 20.612914,
    "dec": 38.840261,
    "pmra": 165.45,
    "pmdec": -159.77,
    "rv": 0,
    "Asset Name": "ASTRID 001978",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Battle Ready",
    "Hair": "Middle Part",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001895,
    "y_1": 0.00000266,
    "z_1": -0.00001231,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119355,
    "Gliese Catalog_1": "NN 4152"
  },
  {
    "Astrid #": "ASTRID 001979",
    "HYG Star ID": 119405,
    "Gliese Catalog": "NN 4189A",
    "Distance in Parsecs": 20.4082,
    "mag": 12.47,
    "Absolute Visual Magnitude": 10.921,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.464529,
    "y": -12.421118,
    "z": 7.279273,
    "vx": 0.00001213,
    "vy": 0.00002964,
    "vz": 0.00002648,
    "rarad": 5.57363987010912,
    "decrad": 0.3647157247729,
    "pmrarad": 0.0000014892021825625,
    "pmdecrad": 0.000001388695458,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119405,
    "base": "NN 4189",
    "lum": 0.00372906539962152,
    "ra": 21.289736,
    "dec": 20.896672,
    "pmra": 307.17,
    "pmdec": 286.44,
    "rv": 0,
    "Asset Name": "ASTRID 001979",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Slick",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001213,
    "y_1": 0.00002964,
    "z_1": 0.00002648,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119405,
    "Gliese Catalog_1": "NN 4189A"
  },
  {
    "Astrid #": "ASTRID 001980",
    "HYG Star ID": 119406,
    "Gliese Catalog": "NN 4190B",
    "Distance in Parsecs": 20.4082,
    "mag": 14,
    "Absolute Visual Magnitude": 12.451,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.464529,
    "y": -12.421118,
    "z": 7.279273,
    "vx": 0.00001213,
    "vy": 0.00002964,
    "vz": 0.00002648,
    "rarad": 5.57363987010912,
    "decrad": 0.3647157247729,
    "pmrarad": 0.0000014892021825625,
    "pmdecrad": 0.000001388695458,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119406,
    "base": "NN 4190",
    "lum": 0.000911171233042918,
    "ra": 21.289736,
    "dec": 20.896672,
    "pmra": 307.17,
    "pmdec": 286.44,
    "rv": 0,
    "Asset Name": "ASTRID 001980",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Lion Mane",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001213,
    "y_1": 0.00002964,
    "z_1": 0.00002648,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119406,
    "Gliese Catalog_1": "NN 4190B"
  },
  {
    "Astrid #": "ASTRID 001981",
    "HYG Star ID": 119412,
    "Gliese Catalog": "NN 4193",
    "Distance in Parsecs": 20.4082,
    "mag": 12.67,
    "Absolute Visual Magnitude": 11.121,
    "Spectral Type": "M7",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.796549,
    "y": -9.365952,
    "z": -13.770077,
    "vx": 0.00000225,
    "vy": -0.00000524,
    "vz": 0.00000549,
    "rarad": 5.61214235574885,
    "decrad": -0.7406022680658,
    "pmrarad": -1.32645023e-7,
    "pmdecrad": 3.64458684e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119412,
    "lum": 0.00310170149947768,
    "ra": 21.436805,
    "dec": -42.433384,
    "pmra": -27.36,
    "pmdec": 75.17,
    "rv": 0,
    "Asset Name": "ASTRID 001981",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Shinobi",
    "Hair": "Sweetheart",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000225,
    "y_1": -0.00000524,
    "z_1": 0.00000549,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119412,
    "Gliese Catalog_1": "NN 4193"
  },
  {
    "Astrid #": "ASTRID 001982",
    "HYG Star ID": 119435,
    "Gliese Catalog": "NN 4217",
    "Distance in Parsecs": 20.4082,
    "mag": 13.54,
    "Absolute Visual Magnitude": 11.991,
    "Spectral Type": "M3.5",
    "Color Index": 1.65,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.899449,
    "y": -11.247139,
    "z": -2.098838,
    "vx": -0.0000111,
    "vy": -0.00001069,
    "vz": -0.00003211,
    "rarad": 5.69596819822332,
    "decrad": -0.10302503507975,
    "pmrarad": -7.37595533597222e-7,
    "pmdecrad": -0.000001581801595,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119435,
    "lum": 0.00139187424883929,
    "ra": 21.756996,
    "dec": -5.9029,
    "pmra": -152.14,
    "pmdec": -326.27,
    "rv": 0,
    "Asset Name": "ASTRID 001982",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Sweet Nap",
    "Hair": "Prince",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000111,
    "y_1": -0.00001069,
    "z_1": -0.00003211,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119435,
    "Gliese Catalog_1": "NN 4217"
  },
  {
    "Astrid #": "ASTRID 001983",
    "HYG Star ID": 119449,
    "Gliese Catalog": "NN 4230B",
    "Distance in Parsecs": 20.4082,
    "mag": 10.5,
    "Absolute Visual Magnitude": 8.951,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.823145,
    "y": -2.321727,
    "z": -19.912001,
    "vx": -0.00000336,
    "vy": 0.00002723,
    "vz": -0.00000382,
    "rarad": 5.73724082872844,
    "decrad": -1.3498307862669,
    "pmrarad": 0.00000105495456888888,
    "pmdecrad": -8.54285338e-7,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 2,
    "comp_primary": 119449,
    "base": "NN 4230",
    "lum": 0.0228875865706236,
    "ra": 21.914646,
    "dec": -77.339607,
    "pmra": 217.6,
    "pmdec": -176.21,
    "rv": 0,
    "Asset Name": "ASTRID 001983",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Neko Dreams",
    "Hair": "Afro",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": -0.00000336,
    "y_1": 0.00002723,
    "z_1": -0.00000382,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119449,
    "Gliese Catalog_1": "NN 4230B"
  },
  {
    "Astrid #": "ASTRID 001984",
    "HYG Star ID": 119469,
    "Gliese Catalog": "NN 4253",
    "Distance in Parsecs": 20.4082,
    "mag": 12.45,
    "Absolute Visual Magnitude": 10.901,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.078278,
    "y": -7.65032,
    "z": -12.63999,
    "vx": 2.7e-7,
    "vy": 0.00006614,
    "vz": -0.00003975,
    "rarad": 5.78537429563466,
    "decrad": -0.667925207882699,
    "pmrarad": 0.00000285395269332638,
    "pmdecrad": -0.000002480917654,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119469,
    "lum": 0.00379839390154506,
    "ra": 22.098502,
    "dec": -38.269295,
    "pmra": 588.67,
    "pmdec": -511.73,
    "rv": 0,
    "Asset Name": "ASTRID 001984",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Prince",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 2.7e-7,
    "y_1": 0.00006614,
    "z_1": -0.00003975,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119469,
    "Gliese Catalog_1": "NN 4253"
  },
  {
    "Astrid #": "ASTRID 001985",
    "HYG Star ID": 119560,
    "Gliese Catalog": "NN 4329",
    "Distance in Parsecs": 20.4082,
    "mag": 12.53,
    "Absolute Visual Magnitude": 10.981,
    "Spectral Type": "dM4",
    "Color Index": 1.55,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 19.934674,
    "y": -3.733897,
    "z": 2.27188,
    "vx": 0.00000573,
    "vy": 0.0000169,
    "vz": -0.00002247,
    "rarad": 6.09802387931726,
    "decrad": 0.11155311646165,
    "pmrarad": 8.65586345263888e-7,
    "pmdecrad": -0.000001107910767,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119560,
    "lum": 0.00352858025828526,
    "ra": 23.292735,
    "dec": 6.391523,
    "pmra": 178.54,
    "pmdec": -228.52,
    "rv": 0,
    "Asset Name": "ASTRID 001985",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Good Boy",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000573,
    "y_1": 0.0000169,
    "z_1": -0.00002247,
    "Distance in Parsecs_1": 20.4082,
    "HYG Star ID_1": 119560,
    "Gliese Catalog_1": "NN 4329"
  },
  {
    "Astrid #": "ASTRID 001986",
    "HYG Star ID": 105371,
    "Hipparcos Catalogue": 105712,
    "Henry Draper Catalogue": 203244,
    "Gliese Catalog": "GJ 1262",
    "Distance in Parsecs": 20.4207,
    "mag": 6.98,
    "Absolute Visual Magnitude": 5.43,
    "Spectral Type": "G5V",
    "Color Index": 0.723,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.90049,
    "y": -4.749317,
    "z": -18.964108,
    "vx": 0.00002427,
    "vy": -0.00000164,
    "vz": -0.0000047,
    "rarad": 5.60545232829824,
    "decrad": -1.19080033527343,
    "pmrarad": 6.83005513166666e-7,
    "pmdecrad": 8.21225893e-7,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 105371,
    "lum": 0.586138164514028,
    "ra": 21.411251,
    "dec": -68.227833,
    "pmra": 140.88,
    "pmdec": 169.39,
    "rv": 11.5,
    "Asset Name": "ASTRID 001986",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Frizzy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00002427,
    "y_1": -0.00000164,
    "z_1": -0.0000047,
    "Distance in Parsecs_1": 20.4207,
    "HYG Star ID_1": 105371,
    "Hipparcos Catalogue_1": 105712,
    "Henry Draper Catalogue_1": 203244,
    "Gliese Catalog_1": "GJ 1262"
  },
  {
    "Astrid #": "ASTRID 001987",
    "HYG Star ID": 18222,
    "Hipparcos Catalogue": 18267,
    "Henry Draper Catalogue": 24496,
    "Gliese Catalog": "NN 3255",
    "Distance in Parsecs": 20.429,
    "mag": 6.81,
    "Absolute Visual Magnitude": 5.259,
    "Spectral Type": "G0",
    "Color Index": 0.719,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.194449,
    "y": 16.712208,
    "z": 5.841869,
    "vx": -0.00000458,
    "vy": 0.00003377,
    "vz": -0.00000922,
    "rarad": 1.02305583906609,
    "decrad": 0.290004841473986,
    "pmrarad": 0.00000105257898185416,
    "pmdecrad": -8.02802973e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 18222,
    "lum": 0.686119875281705,
    "ra": 3.907785,
    "dec": 16.616053,
    "pmra": 217.11,
    "pmdec": -165.59,
    "rv": 22.2,
    "Asset Name": "ASTRID 001987",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "High Bun",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00000458,
    "y_1": 0.00003377,
    "z_1": -0.00000922,
    "Distance in Parsecs_1": 20.429,
    "HYG Star ID_1": 18222,
    "Hipparcos Catalogue_1": 18267,
    "Henry Draper Catalogue_1": 24496,
    "Gliese Catalog_1": "NN 3255"
  },
  {
    "Astrid #": "ASTRID 001988",
    "HYG Star ID": 21368,
    "Hipparcos Catalogue": 21421,
    "Henry Draper Catalogue": 29139,
    "Harvard Revised Catalogue": 1457,
    "Gliese Catalog": "Gl 171.1A",
    "Proper Name": "Aldebaran",
    "Distance in Parsecs": 20.4332,
    "mag": 0.87,
    "Absolute Visual Magnitude": -0.682,
    "Spectral Type": "K5III",
    "Color Index": 1.538,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.02722,
    "y": 18.287604,
    "z": 5.806661,
    "vx": 0.00001528,
    "vy": 0.00005709,
    "vz": -0.00000214,
    "rarad": 1.20393081120275,
    "decrad": 0.288141666122131,
    "pmrarad": 3.04366028652777e-7,
    "pmdecrad": -9.18043185e-7,
    "bayer": "Alp",
    "flam": 87,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 21368,
    "base": "Gl 171.1",
    "lum": 163.230007281899,
    "var": "Alp",
    "var_min": 0.888,
    "var_max": 0.858,
    "bf": "87Alp Tau",
    "ra": 4.598677,
    "dec": 16.509301,
    "pmra": 62.78,
    "pmdec": -189.36,
    "rv": 54.5,
    "Asset Name": "ASTRID 001988",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Determined Shinobi",
    "Hair": "Viking Helm",
    "Outfit": "Viking",
    "Special": "Neko Tail",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Aldebaran",
    "Constellation": "Taurus",
    "x_1": 0.00001528,
    "y_1": 0.00005709,
    "z_1": -0.00000214,
    "Distance in Parsecs_1": 20.4332,
    "HYG Star ID_1": 21368,
    "Hipparcos Catalogue_1": 21421,
    "Henry Draper Catalogue_1": 29139,
    "Harvard Revised Catalogue_1": 1457,
    "Gliese Catalog_1": "Gl 171.1A"
  },
  {
    "Astrid #": "ASTRID 001989",
    "HYG Star ID": 44827,
    "Hipparcos Catalogue": 44955,
    "Gliese Catalog": "Gl 336",
    "Distance in Parsecs": 20.4332,
    "mag": 9.96,
    "Absolute Visual Magnitude": 8.408,
    "Spectral Type": "M2",
    "Color Index": 1.38,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.635562,
    "y": 11.628138,
    "z": 11.074668,
    "vx": -0.0000087,
    "vy": 0.00005295,
    "vz": -0.00004684,
    "rarad": 2.39769313453178,
    "decrad": 0.572805004187117,
    "pmrarad": -0.00000161845350979861,
    "pmdecrad": -0.000003047635758,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 44827,
    "lum": 0.0377398352536585,
    "ra": 9.158513,
    "dec": 32.819309,
    "pmra": -333.83,
    "pmdec": -628.62,
    "rv": 9.9,
    "Asset Name": "ASTRID 001989",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Grecian",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.0000087,
    "y_1": 0.00005295,
    "z_1": -0.00004684,
    "Distance in Parsecs_1": 20.4332,
    "HYG Star ID_1": 44827,
    "Hipparcos Catalogue_1": 44955,
    "Gliese Catalog_1": "Gl 336"
  },
  {
    "Astrid #": "ASTRID 001990",
    "HYG Star ID": 118057,
    "Gliese Catalog": "Gl 55.3B",
    "Distance in Parsecs": 20.4332,
    "mag": 7.2,
    "Absolute Visual Magnitude": 5.648,
    "Spectral Type": "G5",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.965769,
    "y": 2.390353,
    "z": -19.060173,
    "vx": -6.8e-7,
    "vy": 0.00004091,
    "vz": -4.9e-7,
    "rarad": 0.330396629071613,
    "decrad": -1.202085210402,
    "pmrarad": 0.00000190488143227083,
    "pmdecrad": 5.6787196e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 2,
    "comp_primary": 5884,
    "base": "Gl  55.3",
    "lum": 0.47951257408135,
    "ra": 1.262022,
    "dec": -68.874409,
    "pmra": 392.91,
    "pmdec": 117.13,
    "rv": 4.9,
    "Asset Name": "ASTRID 001990",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Frizzy",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -6.8e-7,
    "y_1": 0.00004091,
    "z_1": -4.9e-7,
    "Distance in Parsecs_1": 20.4332,
    "HYG Star ID_1": 118057,
    "Gliese Catalog_1": "Gl 55.3B"
  },
  {
    "Astrid #": "ASTRID 001991",
    "HYG Star ID": 107368,
    "Hipparcos Catalogue": 107711,
    "Gliese Catalog": "NN 4224A",
    "Distance in Parsecs": 20.4499,
    "mag": 11.52,
    "Absolute Visual Magnitude": 9.967,
    "Spectral Type": "M6",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.876807,
    "y": -8.266936,
    "z": -13.566282,
    "vx": 0.00002468,
    "vy": 0.00009586,
    "vz": -0.000035,
    "rarad": 5.71243959135482,
    "decrad": -0.725341678896551,
    "pmrarad": 0.00000459695483765972,
    "pmdecrad": -0.000002287302463,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 107368,
    "base": "NN 4224",
    "lum": 0.00897842170909725,
    "var_min": 11.628,
    "var_max": 11.428,
    "ra": 21.819912,
    "dec": -41.559017,
    "pmra": 948.19,
    "pmdec": -471.79,
    "rv": 0,
    "Asset Name": "ASTRID 001991",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Good Boy",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00002468,
    "y_1": 0.00009586,
    "z_1": -0.000035,
    "Distance in Parsecs_1": 20.4499,
    "HYG Star ID_1": 107368,
    "Hipparcos Catalogue_1": 107711,
    "Gliese Catalog_1": "NN 4224A"
  },
  {
    "Astrid #": "ASTRID 001992",
    "HYG Star ID": 118258,
    "Gliese Catalog": "GJ 1064B",
    "Distance in Parsecs": 20.4499,
    "mag": 8.75,
    "Absolute Visual Magnitude": 7.197,
    "Spectral Type": "K2 V",
    "Color Index": 0.9,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.40605,
    "y": 12.825241,
    "z": 13.533576,
    "vx": 0.00001737,
    "vy": 0.00013506,
    "vz": -0.00005911,
    "rarad": 0.990564670731655,
    "decrad": 0.72309257658375,
    "pmrarad": 0.00000291135463310416,
    "pmdecrad": -0.000006131288325,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 2,
    "comp_primary": 17625,
    "base": "GJ 1064",
    "lum": 0.115133047415581,
    "ra": 3.783678,
    "dec": 41.430153,
    "pmra": 600.51,
    "pmdec": -1264.67,
    "rv": 51.5,
    "Asset Name": "ASTRID 001992",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Maniacal",
    "Hair": "Spiky",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00001737,
    "y_1": 0.00013506,
    "z_1": -0.00005911,
    "Distance in Parsecs_1": 20.4499,
    "HYG Star ID_1": 118258,
    "Gliese Catalog_1": "GJ 1064B"
  },
  {
    "Astrid #": "ASTRID 001993",
    "HYG Star ID": 19843,
    "Hipparcos Catalogue": 19893,
    "Henry Draper Catalogue": 27290,
    "Harvard Revised Catalogue": 1338,
    "Gliese Catalog": "Gl 167.1",
    "Distance in Parsecs": 20.4625,
    "mag": 4.26,
    "Absolute Visual Magnitude": 2.705,
    "Spectral Type": "F4III",
    "Color Index": 0.312,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.584439,
    "y": 11.453148,
    "z": -16.011327,
    "vx": 0.00000433,
    "vy": 0.00003165,
    "vz": -0.00000879,
    "rarad": 1.11712344529978,
    "decrad": -0.898611526742873,
    "pmrarad": 4.87577118534722e-7,
    "pmdecrad": 8.93172243e-7,
    "bayer": "Gam",
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 19843,
    "lum": 7.21107479182899,
    "var": "Gam",
    "var_min": 4.287,
    "var_max": 4.217,
    "bf": "Gam Dor",
    "ra": 4.267097,
    "dec": -51.486648,
    "pmra": 100.57,
    "pmdec": 184.23,
    "rv": 25.2,
    "Asset Name": "ASTRID 001993",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Spiky",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 0.00000433,
    "y_1": 0.00003165,
    "z_1": -0.00000879,
    "Distance in Parsecs_1": 20.4625,
    "HYG Star ID_1": 19843,
    "Hipparcos Catalogue_1": 19893,
    "Henry Draper Catalogue_1": 27290,
    "Harvard Revised Catalogue_1": 1338,
    "Gliese Catalog_1": "Gl 167.1"
  },
  {
    "Astrid #": "ASTRID 001994",
    "HYG Star ID": 118598,
    "Gliese Catalog": "Gl 335B",
    "Distance in Parsecs": 20.4625,
    "mag": 8.44,
    "Absolute Visual Magnitude": 6.885,
    "Spectral Type": "K2 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.871234,
    "y": 5.360634,
    "z": 18.854869,
    "vx": -0.00000517,
    "vy": 0.00000642,
    "vz": -0.00000343,
    "rarad": 2.40162798028126,
    "decrad": 1.17175859906575,
    "pmrarad": -6.14743746944444e-8,
    "pmdecrad": -4.31978685e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 44910,
    "base": "Gl 335",
    "lum": 0.153461698279929,
    "ra": 9.173543,
    "dec": 67.136822,
    "pmra": -12.68,
    "pmdec": -89.1,
    "rv": 0,
    "Asset Name": "ASTRID 001994",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Pixie",
    "Outfit": "River Spirit",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000517,
    "y_1": 0.00000642,
    "z_1": -0.00000343,
    "Distance in Parsecs_1": 20.4625,
    "HYG Star ID_1": 118598,
    "Gliese Catalog_1": "Gl 335B"
  },
  {
    "Astrid #": "ASTRID 001995",
    "HYG Star ID": 48274,
    "Hipparcos Catalogue": 48411,
    "Henry Draper Catalogue": 85488,
    "Gliese Catalog": "Gl 371",
    "Distance in Parsecs": 20.4708,
    "mag": 8.86,
    "Absolute Visual Magnitude": 7.304,
    "Spectral Type": "K5",
    "Color Index": 1.23,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.341891,
    "y": 10.816501,
    "z": 1.150513,
    "vx": 0.00000381,
    "vy": 0.0000474,
    "vz": 0.000003,
    "rarad": 2.58391362711432,
    "decrad": 0.0562317502183587,
    "pmrarad": -0.0000020634155058125,
    "pmdecrad": 8.6539241e-8,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 48274,
    "lum": 0.104327788145996,
    "ra": 9.869823,
    "dec": 3.221842,
    "pmra": -425.61,
    "pmdec": 17.85,
    "rv": 21.5,
    "Asset Name": "ASTRID 001995",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Afro",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": 0.00000381,
    "y_1": 0.0000474,
    "z_1": 0.000003,
    "Distance in Parsecs_1": 20.4708,
    "HYG Star ID_1": 48274,
    "Hipparcos Catalogue_1": 48411,
    "Henry Draper Catalogue_1": 85488,
    "Gliese Catalog_1": "Gl 371"
  },
  {
    "Astrid #": "ASTRID 001996",
    "HYG Star ID": 105192,
    "Hipparcos Catalogue": 105533,
    "Distance in Parsecs": 20.4708,
    "mag": 9.91,
    "Absolute Visual Magnitude": 8.354,
    "Spectral Type": "M0",
    "Color Index": 1.495,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.53687,
    "y": -12.757209,
    "z": 3.861729,
    "vx": -0.00000404,
    "vy": -0.00000397,
    "vz": 0.00000312,
    "rarad": 5.59571473859706,
    "decrad": 0.189782929205663,
    "pmrarad": -2.75131763715277e-7,
    "pmdecrad": 1.54994933e-7,
    "con": "Equ",
    "Full Constellation Name Formula 1": "Equuleus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Equuleus",
    "comp": 1,
    "comp_primary": 105192,
    "lum": 0.0396643187946858,
    "ra": 21.374056,
    "dec": 10.873761,
    "pmra": -56.75,
    "pmdec": 31.97,
    "rv": 0,
    "Asset Name": "ASTRID 001996",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Bandana",
    "Outfit": "Warlock",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Equuleus",
    "x_1": -0.00000404,
    "y_1": -0.00000397,
    "z_1": 0.00000312,
    "Distance in Parsecs_1": 20.4708,
    "HYG Star ID_1": 105192,
    "Hipparcos Catalogue_1": 105533
  },
  {
    "Astrid #": "ASTRID 001997",
    "HYG Star ID": 6056,
    "Hipparcos Catalogue": 6069,
    "Gliese Catalog": "NN 3084",
    "Distance in Parsecs": 20.4792,
    "mag": 11.02,
    "Absolute Visual Magnitude": 9.463,
    "Spectral Type": "M2V:",
    "Color Index": 1.478,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.219847,
    "y": 6.795491,
    "z": 1.953572,
    "vx": 0.00000271,
    "vy": 0.00001024,
    "vz": -0.00006223,
    "rarad": 0.339848373276943,
    "decrad": 0.0955382580995874,
    "pmrarad": 4.27460222145833e-7,
    "pmdecrad": -0.00000305301719,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 6056,
    "lum": 0.0142823608001918,
    "ra": 1.298125,
    "dec": 5.473939,
    "pmra": 88.17,
    "pmdec": -629.73,
    "rv": 0,
    "Asset Name": "ASTRID 001997",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Afro",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00000271,
    "y_1": 0.00001024,
    "z_1": -0.00006223,
    "Distance in Parsecs_1": 20.4792,
    "HYG Star ID_1": 6056,
    "Hipparcos Catalogue_1": 6069,
    "Gliese Catalog_1": "NN 3084"
  },
  {
    "Astrid #": "ASTRID 001998",
    "HYG Star ID": 118606,
    "Gliese Catalog": "Gl 337B",
    "Distance in Parsecs": 20.4792,
    "mag": 7.3,
    "Absolute Visual Magnitude": 5.743,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.718079,
    "y": 13.217168,
    "z": 5.299268,
    "vx": 0.00003967,
    "vy": 0.00003519,
    "vz": 0.00002245,
    "rarad": 2.409875589538,
    "decrad": 0.2617417997754,
    "pmrarad": -0.00000257280923997222,
    "pmdecrad": 0.000001134735508,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 2,
    "comp_primary": 45040,
    "base": "Gl 337",
    "lum": 0.439339245644476,
    "ra": 9.205047,
    "dec": 14.9967,
    "pmra": -530.68,
    "pmdec": 234.06,
    "rv": 0,
    "Asset Name": "ASTRID 001998",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Middle Part",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00003967,
    "y_1": 0.00003519,
    "z_1": 0.00002245,
    "Distance in Parsecs_1": 20.4792,
    "HYG Star ID_1": 118606,
    "Gliese Catalog_1": "Gl 337B"
  },
  {
    "Astrid #": "ASTRID 001999",
    "HYG Star ID": 119335,
    "Gliese Catalog": "Gl 783.2B",
    "Distance in Parsecs": 20.4792,
    "mag": 13.94,
    "Absolute Visual Magnitude": 12.383,
    "Spectral Type": "dM",
    "Color Index": 1.62,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.655289,
    "y": -16.531225,
    "z": 5.707982,
    "vx": -0.00004066,
    "vy": -0.00001344,
    "vz": 0.000037,
    "rarad": 5.28493128202384,
    "decrad": 0.28246203627805,
    "pmrarad": -0.00000202433952315972,
    "pmdecrad": 0.000001881125561,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 99134,
    "base": "Gl 783.2",
    "lum": 0.000970063133783957,
    "ra": 20.18695,
    "dec": 16.183883,
    "pmra": -417.55,
    "pmdec": 388.01,
    "rv": 0,
    "Asset Name": "ASTRID 001999",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Wavy",
    "Outfit": "Emperor",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004066,
    "y_1": -0.00001344,
    "z_1": 0.000037,
    "Distance in Parsecs_1": 20.4792,
    "HYG Star ID_1": 119335,
    "Gliese Catalog_1": "Gl 783.2B"
  },
  {
    "Astrid #": "ASTRID 002000",
    "HYG Star ID": 104996,
    "Hipparcos Catalogue": 105336,
    "Gliese Catalog": "Wo 9732",
    "Distance in Parsecs": 20.4834,
    "mag": 10.9,
    "Absolute Visual Magnitude": 9.343,
    "Color Index": 1.45,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 5.969957,
    "y": -5.002145,
    "z": -18.944863,
    "vx": -0.00002119,
    "vy": -0.00003784,
    "vz": 0.00000331,
    "rarad": 5.58579572736749,
    "decrad": -1.18074252650944,
    "pmrarad": -0.00000208052942873611,
    "pmdecrad": 4.25084635e-7,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 104996,
    "lum": 0.015951443869276,
    "var_min": 10.992,
    "var_max": 10.832,
    "ra": 21.336168,
    "dec": -67.651563,
    "pmra": -429.14,
    "pmdec": 87.68,
    "rv": 0,
    "Asset Name": "ASTRID 002000",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Middle Part",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00002119,
    "y_1": -0.00003784,
    "z_1": 0.00000331,
    "Distance in Parsecs_1": 20.4834,
    "HYG Star ID_1": 104996,
    "Hipparcos Catalogue_1": 105336,
    "Gliese Catalog_1": "Wo 9732"
  },
  {
    "Astrid #": "ASTRID 002001",
    "HYG Star ID": 114977,
    "Hipparcos Catalogue": 115341,
    "Henry Draper Catalogue": 220221,
    "Distance in Parsecs": 20.4834,
    "mag": 8.12,
    "Absolute Visual Magnitude": 6.563,
    "Spectral Type": "K2",
    "Color Index": 1.056,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.238646,
    "y": -2.399285,
    "z": 14.528388,
    "vx": 0.00000821,
    "vy": 0.00001846,
    "vz": -0.000005,
    "rarad": 6.11624483309687,
    "decrad": 0.788470945550747,
    "pmrarad": 9.55325357534722e-7,
    "pmdecrad": -3.46108486e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114977,
    "lum": 0.206442923207399,
    "ra": 23.362334,
    "dec": 45.176057,
    "pmra": 197.05,
    "pmdec": -71.39,
    "rv": 0,
    "Asset Name": "ASTRID 002001",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Grecian",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000821,
    "y_1": 0.00001846,
    "z_1": -0.000005,
    "Distance in Parsecs_1": 20.4834,
    "HYG Star ID_1": 114977,
    "Hipparcos Catalogue_1": 115341,
    "Henry Draper Catalogue_1": 220221
  },
  {
    "Astrid #": "ASTRID 002002",
    "HYG Star ID": 75493,
    "Hipparcos Catalogue": 75722,
    "Henry Draper Catalogue": 137778,
    "Gliese Catalog": "Gl 586B",
    "Distance in Parsecs": 20.4918,
    "mag": 7.57,
    "Absolute Visual Magnitude": 6.012,
    "Spectral Type": "K2V",
    "Color Index": 0.868,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.434009,
    "y": -15.943968,
    "z": -3.331985,
    "vx": 0.00000496,
    "vy": -0.00000681,
    "vz": -0.00003621,
    "rarad": 4.05005176860019,
    "decrad": -0.163325466582967,
    "pmrarad": 3.95268593756944e-7,
    "pmdecrad": -0.000001725209482,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 2,
    "comp_primary": 75489,
    "base": "Gl 586",
    "lum": 0.34292567329756,
    "ra": 15.470058,
    "dec": -9.35786,
    "pmra": 81.53,
    "pmdec": -355.85,
    "rv": 8,
    "Asset Name": "ASTRID 002002",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Prince",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000496,
    "y_1": -0.00000681,
    "z_1": -0.00003621,
    "Distance in Parsecs_1": 20.4918,
    "HYG Star ID_1": 75493,
    "Hipparcos Catalogue_1": 75722,
    "Henry Draper Catalogue_1": 137778,
    "Gliese Catalog_1": "Gl 586B"
  },
  {
    "Astrid #": "ASTRID 002003",
    "HYG Star ID": 108035,
    "Hipparcos Catalogue": 108380,
    "Gliese Catalog": "NN 4241",
    "Distance in Parsecs": 20.4918,
    "mag": 11.02,
    "Absolute Visual Magnitude": 9.462,
    "Spectral Type": "M1V:",
    "Color Index": 1.439,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.453237,
    "y": -10.338546,
    "z": 2.900494,
    "vx": 0.00001803,
    "vy": 0.00003329,
    "vz": 0.00001015,
    "rarad": 5.74840448826447,
    "decrad": 0.142021081715958,
    "pmrarad": 0.00000184631593965972,
    "pmdecrad": 5.00424681e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 108035,
    "lum": 0.0142955214003703,
    "var_min": 11.091,
    "var_max": 10.941,
    "ra": 21.957288,
    "dec": 8.137209,
    "pmra": 380.83,
    "pmdec": 103.22,
    "rv": 0,
    "Asset Name": "ASTRID 002003",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Awkward",
    "Hair": "Prince",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001803,
    "y_1": 0.00003329,
    "z_1": 0.00001015,
    "Distance in Parsecs_1": 20.4918,
    "HYG Star ID_1": 108035,
    "Hipparcos Catalogue_1": 108380,
    "Gliese Catalog_1": "NN 4241"
  },
  {
    "Astrid #": "ASTRID 002004",
    "HYG Star ID": 119416,
    "Gliese Catalog": "Gl 828.5",
    "Distance in Parsecs": 20.4918,
    "mag": 12.8,
    "Absolute Visual Magnitude": 11.242,
    "Spectral Type": "DA4",
    "Color Index": 0.02,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.530787,
    "y": -3.572841,
    "z": 19.663098,
    "vx": 0.00002713,
    "vy": -0.0000148,
    "vz": -4.1e-7,
    "rarad": 5.61542088397231,
    "decrad": 1.2853645278383,
    "pmrarad": 2.52539446201388e-7,
    "pmdecrad": -0.000001432343234,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119416,
    "lum": 0.0027745975598804,
    "ra": 21.449328,
    "dec": 73.645963,
    "pmra": 52.09,
    "pmdec": -295.44,
    "rv": 8,
    "Asset Name": "ASTRID 002004",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Heartbreaker",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002713,
    "y_1": -0.0000148,
    "z_1": -4.1e-7,
    "Distance in Parsecs_1": 20.4918,
    "HYG Star ID_1": 119416,
    "Gliese Catalog_1": "Gl 828.5"
  },
  {
    "Astrid #": "ASTRID 002005",
    "HYG Star ID": 62035,
    "Hipparcos Catalogue": 62229,
    "Henry Draper Catalogue": 110810,
    "Distance in Parsecs": 20.496,
    "mag": 7.82,
    "Absolute Visual Magnitude": 6.262,
    "Spectral Type": "K3V",
    "Color Index": 0.937,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.84061,
    "y": -2.168146,
    "z": -17.258805,
    "vx": 0.00000679,
    "vy": 0.00002177,
    "vz": -0.000007,
    "rarad": 3.33899760767406,
    "decrad": -1.00108595520832,
    "pmrarad": -9.76608678111111e-7,
    "pmdecrad": -6.33166666e-7,
    "con": "Cru",
    "Full Constellation Name Formula 1": "Crux",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crux",
    "comp": 1,
    "comp_primary": 62035,
    "lum": 0.272395544712086,
    "ra": 12.754031,
    "dec": -57.358,
    "pmra": -201.44,
    "pmdec": -130.6,
    "rv": 0,
    "Asset Name": "ASTRID 002005",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Stunned",
    "Hair": "Bucket Hat",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crux",
    "x_1": 0.00000679,
    "y_1": 0.00002177,
    "z_1": -0.000007,
    "Distance in Parsecs_1": 20.496,
    "HYG Star ID_1": 62035,
    "Hipparcos Catalogue_1": 62229,
    "Henry Draper Catalogue_1": 110810
  },
  {
    "Astrid #": "ASTRID 002006",
    "HYG Star ID": 64252,
    "Hipparcos Catalogue": 64457,
    "Henry Draper Catalogue": 114783,
    "Gliese Catalog": "NN 3769",
    "Distance in Parsecs": 20.5002,
    "mag": 7.56,
    "Absolute Visual Magnitude": 6.001,
    "Spectral Type": "K0",
    "Color Index": 0.93,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.461361,
    "y": -6.391962,
    "z": -0.810211,
    "vx": -0.00000432,
    "vy": 0.00001303,
    "vz": 9.6e-7,
    "rarad": 3.45893612236005,
    "decrad": -0.039532398908085,
    "pmrarad": -6.69673136951389e-7,
    "pmdecrad": 4.6639076e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 64252,
    "lum": 0.346417641035248,
    "ra": 13.212163,
    "dec": -2.26504,
    "pmra": -138.13,
    "pmdec": 9.62,
    "rv": 0,
    "Asset Name": "ASTRID 002006",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Arrogant",
    "Hair": "Sweetheart",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000432,
    "y_1": 0.00001303,
    "z_1": 9.6e-7,
    "Distance in Parsecs_1": 20.5002,
    "HYG Star ID_1": 64252,
    "Hipparcos Catalogue_1": 64457,
    "Henry Draper Catalogue_1": 114783,
    "Gliese Catalog_1": "NN 3769"
  },
  {
    "Astrid #": "ASTRID 002007",
    "HYG Star ID": 114562,
    "Hipparcos Catalogue": 114924,
    "Henry Draper Catalogue": 219623,
    "Harvard Revised Catalogue": 8853,
    "Gliese Catalog": "NN 4324",
    "Distance in Parsecs": 20.5044,
    "mag": 5.58,
    "Absolute Visual Magnitude": 4.021,
    "Spectral Type": "F7V",
    "Color Index": 0.556,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.06018,
    "y": -2.305781,
    "z": 16.421224,
    "vx": 0.00000534,
    "vy": 0.00001028,
    "vz": -0.00003479,
    "rarad": 6.09427228044771,
    "decrad": 0.928750354295356,
    "pmrarad": 5.41536881180555e-7,
    "pmdecrad": -0.000001145566245,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 114562,
    "lum": 2.14585315988437,
    "ra": 23.278405,
    "dec": 53.213476,
    "pmra": 111.7,
    "pmdec": -236.29,
    "rv": -25.3,
    "Asset Name": "ASTRID 002007",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Infatuated",
    "Hair": "Angelic",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000534,
    "y_1": 0.00001028,
    "z_1": -0.00003479,
    "Distance in Parsecs_1": 20.5044,
    "HYG Star ID_1": 114562,
    "Hipparcos Catalogue_1": 114924,
    "Henry Draper Catalogue_1": 219623,
    "Harvard Revised Catalogue_1": 8853,
    "Gliese Catalog_1": "NN 4324"
  },
  {
    "Astrid #": "ASTRID 002008",
    "HYG Star ID": 79554,
    "Hipparcos Catalogue": 79796,
    "Gliese Catalog": "Gl 616.2",
    "Distance in Parsecs": 20.5339,
    "mag": 9.87,
    "Absolute Visual Magnitude": 8.308,
    "Spectral Type": "M1.5Ve",
    "Color Index": 1.251,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.078201,
    "y": -10.538756,
    "z": 16.875295,
    "vx": 5e-8,
    "vy": -0.00001913,
    "vz": -0.0000525,
    "rarad": 4.26335605371422,
    "decrad": 0.964629229953978,
    "pmrarad": 4.06710196618055e-7,
    "pmdecrad": -0.000002145833831,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 79554,
    "lum": 0.0413809064816725,
    "var": "CR",
    "var_min": 9.921,
    "var_max": 9.811,
    "ra": 16.284821,
    "dec": 55.269184,
    "pmra": 83.89,
    "pmdec": -442.61,
    "rv": -32.6,
    "Asset Name": "ASTRID 002008",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Infatuated",
    "Hair": "Manbun",
    "Outfit": "Super",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 5e-8,
    "y_1": -0.00001913,
    "z_1": -0.0000525,
    "Distance in Parsecs_1": 20.5339,
    "HYG Star ID_1": 79554,
    "Hipparcos Catalogue_1": 79796,
    "Gliese Catalog_1": "Gl 616.2"
  },
  {
    "Astrid #": "ASTRID 002009",
    "HYG Star ID": 119045,
    "Gliese Catalog": "NN 3925A",
    "Distance in Parsecs": 20.5339,
    "mag": 11.75,
    "Absolute Visual Magnitude": 10.188,
    "Spectral Type": "M3",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.871193,
    "y": -14.350324,
    "z": 11.705178,
    "vx": 0.00000776,
    "vy": -0.0000377,
    "vz": -0.00004032,
    "rarad": 4.15868950559202,
    "decrad": 0.6065565181865,
    "pmrarad": 0.00000128713184050694,
    "pmdecrad": -0.00000239045627,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 119045,
    "base": "NN 3925",
    "lum": 0.00732487132665038,
    "ra": 15.885024,
    "dec": 34.753129,
    "pmra": 265.49,
    "pmdec": -493.07,
    "rv": 0,
    "Asset Name": "ASTRID 002009",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Braided Ponytail",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": 0.00000776,
    "y_1": -0.0000377,
    "z_1": -0.00004032,
    "Distance in Parsecs_1": 20.5339,
    "HYG Star ID_1": 119045,
    "Gliese Catalog_1": "NN 3925A"
  },
  {
    "Astrid #": "ASTRID 002010",
    "HYG Star ID": 119046,
    "Gliese Catalog": "NN 3926B",
    "Distance in Parsecs": 20.5339,
    "mag": 13.18,
    "Absolute Visual Magnitude": 11.618,
    "Spectral Type": "m",
    "Color Index": 1.53,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.872075,
    "y": -14.351779,
    "z": 11.702723,
    "vx": 0.00000776,
    "vy": -0.00003769,
    "vz": -0.00004033,
    "rarad": 4.15869039459202,
    "decrad": 0.6064110741865,
    "pmrarad": 0.00000128713184050694,
    "pmdecrad": -0.00000239045627,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119046,
    "base": "NN 3926",
    "lum": 0.00196245632417368,
    "ra": 15.885027,
    "dec": 34.744795,
    "pmra": 265.49,
    "pmdec": -493.07,
    "rv": 0,
    "Asset Name": "ASTRID 002010",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Slick",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000776,
    "y_1": -0.00003769,
    "z_1": -0.00004033,
    "Distance in Parsecs_1": 20.5339,
    "HYG Star ID_1": 119046,
    "Gliese Catalog_1": "NN 3926B"
  },
  {
    "Astrid #": "ASTRID 002011",
    "HYG Star ID": 114586,
    "Hipparcos Catalogue": 114948,
    "Henry Draper Catalogue": 219482,
    "Harvard Revised Catalogue": 8843,
    "Gliese Catalog": "GJ 1282",
    "Distance in Parsecs": 20.5381,
    "mag": 5.64,
    "Absolute Visual Magnitude": 4.077,
    "Spectral Type": "F7V",
    "Color Index": 0.521,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.472157,
    "y": -1.799996,
    "z": -18.134254,
    "vx": 1.7e-7,
    "vy": 0.00001781,
    "vz": 4e-7,
    "rarad": 6.09538576213068,
    "decrad": -1.08212504304166,
    "pmrarad": 8.53272077777777e-7,
    "pmdecrad": -1.26633333e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 114586,
    "lum": 2.03798038628705,
    "ra": 23.282659,
    "dec": -62.001198,
    "pmra": 176,
    "pmdec": -26.12,
    "rv": -1.8,
    "Asset Name": "ASTRID 002011",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Medium Bob",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": 1.7e-7,
    "y_1": 0.00001781,
    "z_1": 4e-7,
    "Distance in Parsecs_1": 20.5381,
    "HYG Star ID_1": 114586,
    "Hipparcos Catalogue_1": 114948,
    "Henry Draper Catalogue_1": 219482,
    "Harvard Revised Catalogue_1": 8843,
    "Gliese Catalog_1": "GJ 1282"
  },
  {
    "Astrid #": "ASTRID 002012",
    "HYG Star ID": 46211,
    "Hipparcos Catalogue": 46343,
    "Henry Draper Catalogue": 80768,
    "Gliese Catalog": "Gl 342",
    "Distance in Parsecs": 20.5465,
    "mag": 9.02,
    "Absolute Visual Magnitude": 7.456,
    "Spectral Type": "K2",
    "Color Index": 1.203,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.91994,
    "y": 3.09341,
    "z": 19.930414,
    "vx": 0.0000153,
    "vy": 0.00003196,
    "vz": -0.00000817,
    "rarad": 2.47355026572356,
    "decrad": 1.32530284229144,
    "pmrarad": -0.00000168254587836805,
    "pmdecrad": -4.63966692e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 46211,
    "lum": 0.0906984781505791,
    "ra": 9.448266,
    "dec": 75.934259,
    "pmra": -347.05,
    "pmdec": -95.7,
    "rv": -5.9,
    "Asset Name": "ASTRID 002012",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Tiny Crown",
    "Outfit": "Super",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.0000153,
    "y_1": 0.00003196,
    "z_1": -0.00000817,
    "Distance in Parsecs_1": 20.5465,
    "HYG Star ID_1": 46211,
    "Hipparcos Catalogue_1": 46343,
    "Henry Draper Catalogue_1": 80768,
    "Gliese Catalog_1": "Gl 342"
  },
  {
    "Astrid #": "ASTRID 002013",
    "HYG Star ID": 95881,
    "Hipparcos Catalogue": 96183,
    "Henry Draper Catalogue": 184385,
    "Distance in Parsecs": 20.5592,
    "mag": 6.89,
    "Absolute Visual Magnitude": 5.325,
    "Spectral Type": "G5V",
    "Color Index": 0.745,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.565716,
    "y": -17.519843,
    "z": 7.648494,
    "vx": 0.00000486,
    "vy": -0.00001652,
    "vz": -0.00001514,
    "rarad": 5.1200363648517,
    "decrad": 0.381185657714633,
    "pmrarad": -1.01616947444444e-7,
    "pmdecrad": -9.92946899e-7,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 95881,
    "lum": 0.645654229034655,
    "ra": 19.557098,
    "dec": 21.840329,
    "pmra": -20.96,
    "pmdec": -204.81,
    "rv": 10,
    "Asset Name": "ASTRID 002013",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "High Bun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00000486,
    "y_1": -0.00001652,
    "z_1": -0.00001514,
    "Distance in Parsecs_1": 20.5592,
    "HYG Star ID_1": 95881,
    "Hipparcos Catalogue_1": 96183,
    "Henry Draper Catalogue_1": 184385
  },
  {
    "Astrid #": "ASTRID 002014",
    "HYG Star ID": 110303,
    "Hipparcos Catalogue": 110649,
    "Henry Draper Catalogue": 212330,
    "Harvard Revised Catalogue": 8531,
    "Gliese Catalog": "Gl 857",
    "Distance in Parsecs": 20.5634,
    "mag": 5.31,
    "Absolute Visual Magnitude": 3.745,
    "Spectral Type": "F9V",
    "Color Index": 0.665,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.029346,
    "y": -4.416161,
    "z": -17.400173,
    "vx": -0.00001442,
    "vy": 0.00002603,
    "vz": -0.00002435,
    "rarad": 5.86840038993451,
    "decrad": -1.00875519486994,
    "pmrarad": 8.76106802131944e-7,
    "pmdecrad": -0.000001606042279,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 110303,
    "lum": 2.76694164541151,
    "ra": 22.415638,
    "dec": -57.797415,
    "pmra": 180.71,
    "pmdec": -331.27,
    "rv": 7.8,
    "Asset Name": "ASTRID 002014",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Neko",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00001442,
    "y_1": 0.00002603,
    "z_1": -0.00002435,
    "Distance in Parsecs_1": 20.5634,
    "HYG Star ID_1": 110303,
    "Hipparcos Catalogue_1": 110649,
    "Henry Draper Catalogue_1": 212330,
    "Harvard Revised Catalogue_1": 8531,
    "Gliese Catalog_1": "Gl 857"
  },
  {
    "Astrid #": "ASTRID 002015",
    "HYG Star ID": 118781,
    "Gliese Catalog": "Gl 452B",
    "Distance in Parsecs": 20.5634,
    "mag": 19.4,
    "Absolute Visual Magnitude": 17.835,
    "Spectral Type": "f",
    "Spectral Abbreviation": "f",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -20.38451,
    "y": 0.597944,
    "z": -2.639625,
    "vx": 0.00000692,
    "vy": 0.00001639,
    "vz": -0.00004975,
    "rarad": 3.1122681640777,
    "decrad": -0.12872041090995,
    "pmrarad": -8.06778445812499e-7,
    "pmdecrad": -0.000002439558199,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 57786,
    "base": "Gl 452",
    "lum": 0.00000639734835482646,
    "ra": 11.887989,
    "dec": -7.375136,
    "pmra": -166.41,
    "pmdec": -503.19,
    "rv": 0,
    "Asset Name": "ASTRID 002015",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Side Part",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000692,
    "y_1": 0.00001639,
    "z_1": -0.00004975,
    "Distance in Parsecs_1": 20.5634,
    "HYG Star ID_1": 118781,
    "Gliese Catalog_1": "Gl 452B"
  },
  {
    "Astrid #": "ASTRID 002016",
    "HYG Star ID": 3411,
    "Hipparcos Catalogue": 3418,
    "Gliese Catalog": "Gl 30",
    "Distance in Parsecs": 20.5719,
    "mag": 8.73,
    "Absolute Visual Magnitude": 7.164,
    "Spectral Type": "K8",
    "Color Index": 1.13,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.778253,
    "y": 3.227009,
    "z": 11.457179,
    "vx": -0.00000569,
    "vy": -0.00002171,
    "vz": -0.00004927,
    "rarad": 0.190014266496999,
    "decrad": 0.590699126721195,
    "pmrarad": -9.84171771527778e-7,
    "pmdecrad": -0.000001727003292,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 3411,
    "lum": 0.118686138464491,
    "ra": 0.725801,
    "dec": 33.844567,
    "pmra": -203,
    "pmdec": -356.22,
    "rv": -34.7,
    "Asset Name": "ASTRID 002016",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Undercut",
    "Outfit": "Boxer",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00000569,
    "y_1": -0.00002171,
    "z_1": -0.00004927,
    "Distance in Parsecs_1": 20.5719,
    "HYG Star ID_1": 3411,
    "Hipparcos Catalogue_1": 3418,
    "Gliese Catalog_1": "Gl 30"
  },
  {
    "Astrid #": "ASTRID 002017",
    "HYG Star ID": 118259,
    "Henry Draper Catalogue": 23585,
    "Gliese Catalog": "Wo 9131",
    "Distance in Parsecs": 20.5761,
    "mag": 8.38,
    "Absolute Visual Magnitude": 6.813,
    "Spectral Type": "F0 V",
    "Color Index": 0.29,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.301963,
    "y": 15.723549,
    "z": 8.368066,
    "vx": 0.00000178,
    "vy": 0.00000544,
    "vz": -0.00000159,
    "rarad": 0.990778623710057,
    "decrad": 0.41882169955495,
    "pmrarad": 7.23342011388888e-8,
    "pmdecrad": -1.79928901e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118259,
    "lum": 0.163983442762653,
    "ra": 3.784496,
    "dec": 23.996716,
    "pmra": 14.92,
    "pmdec": -37.11,
    "rv": 4.3,
    "Asset Name": "ASTRID 002017",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Tiny Crown",
    "Outfit": "Wizard",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000178,
    "y_1": 0.00000544,
    "z_1": -0.00000159,
    "Distance in Parsecs_1": 20.5761,
    "HYG Star ID_1": 118259,
    "Henry Draper Catalogue_1": 23585,
    "Gliese Catalog_1": "Wo 9131"
  },
  {
    "Astrid #": "ASTRID 002018",
    "HYG Star ID": 118685,
    "Gliese Catalog": "NN 3602",
    "Distance in Parsecs": 20.5761,
    "mag": 13.25,
    "Absolute Visual Magnitude": 11.683,
    "Spectral Type": "m",
    "Color Index": 1.67,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.625482,
    "y": 5.328855,
    "z": 15.348498,
    "vx": -0.00003347,
    "vy": -0.00005236,
    "vz": -0.00000934,
    "rarad": 2.74213050904061,
    "decrad": 0.84194233520515,
    "pmrarad": 0.00000297724081229166,
    "pmdecrad": -6.81875897e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118685,
    "lum": 0.00184841719536965,
    "ra": 10.474167,
    "dec": 48.239742,
    "pmra": 614.1,
    "pmdec": -140.65,
    "rv": 0,
    "Asset Name": "ASTRID 002018",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Lion Mane",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003347,
    "y_1": -0.00005236,
    "z_1": -0.00000934,
    "Distance in Parsecs_1": 20.5761,
    "HYG Star ID_1": 118685,
    "Gliese Catalog_1": "NN 3602"
  },
  {
    "Astrid #": "ASTRID 002019",
    "HYG Star ID": 119200,
    "Gliese Catalog": "Wo 9615B",
    "Distance in Parsecs": 20.5761,
    "mag": 14,
    "Absolute Visual Magnitude": 12.433,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 0.6509,
    "y": -20.279772,
    "z": 3.418048,
    "vx": -0.00000612,
    "vy": 0.00000112,
    "vz": 0.00000779,
    "rarad": 4.74447417475867,
    "decrad": 0.1668910504091,
    "pmrarad": -2.95784826506944e-7,
    "pmdecrad": 3.84108182e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 88494,
    "base": "Wo 9615",
    "lum": 0.000926403100736398,
    "ra": 18.122556,
    "dec": 9.562153,
    "pmra": -61.01,
    "pmdec": 79.23,
    "rv": 0,
    "Asset Name": "ASTRID 002019",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Windy",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000612,
    "y_1": 0.00000112,
    "z_1": 0.00000779,
    "Distance in Parsecs_1": 20.5761,
    "HYG Star ID_1": 119200,
    "Gliese Catalog_1": "Wo 9615B"
  },
  {
    "Astrid #": "ASTRID 002020",
    "HYG Star ID": 93929,
    "Hipparcos Catalogue": 94225,
    "Henry Draper Catalogue": 178445,
    "Gliese Catalog": "Gl 747.3",
    "Distance in Parsecs": 20.5804,
    "mag": 9.36,
    "Absolute Visual Magnitude": 7.793,
    "Spectral Type": "K7V",
    "Color Index": 1.318,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.258806,
    "y": -13.330307,
    "z": -15.090364,
    "vx": -0.00001888,
    "vy": 0.00004121,
    "vz": -0.00004452,
    "rarad": 5.02162390249948,
    "decrad": -0.823073163129471,
    "pmrarad": -2.64514344111111e-7,
    "pmdecrad": -0.000003074300511,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 93929,
    "lum": 0.0664966857124675,
    "ra": 19.18119,
    "dec": -47.158618,
    "pmra": -54.56,
    "pmdec": -634.12,
    "rv": 2,
    "Asset Name": "ASTRID 002020",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Mohawk",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.00001888,
    "y_1": 0.00004121,
    "z_1": -0.00004452,
    "Distance in Parsecs_1": 20.5804,
    "HYG Star ID_1": 93929,
    "Hipparcos Catalogue_1": 94225,
    "Henry Draper Catalogue_1": 178445,
    "Gliese Catalog_1": "Gl 747.3"
  },
  {
    "Astrid #": "ASTRID 002021",
    "HYG Star ID": 75489,
    "Hipparcos Catalogue": 75718,
    "Henry Draper Catalogue": 137763,
    "Gliese Catalog": "Gl 586A",
    "Distance in Parsecs": 20.5846,
    "mag": 6.89,
    "Absolute Visual Magnitude": 5.322,
    "Spectral Type": "K2V",
    "Color Index": 0.788,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.493698,
    "y": -16.014262,
    "z": -3.343604,
    "vx": 0.00000425,
    "vy": -0.00000634,
    "vz": -0.00003714,
    "rarad": 4.04986278359681,
    "decrad": -0.163154589590371,
    "pmrarad": 3.52411064395833e-7,
    "pmdecrad": -0.000001761667471,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 75489,
    "base": "Gl 586",
    "lum": 0.647440704568814,
    "ra": 15.469336,
    "dec": -9.348069,
    "pmra": 72.69,
    "pmdec": -363.37,
    "rv": 8.2,
    "Asset Name": "ASTRID 002021",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Fired Up",
    "Hair": "Innocent",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000425,
    "y_1": -0.00000634,
    "z_1": -0.00003714,
    "Distance in Parsecs_1": 20.5846,
    "HYG Star ID_1": 75489,
    "Hipparcos Catalogue_1": 75718,
    "Henry Draper Catalogue_1": 137763,
    "Gliese Catalog_1": "Gl 586A"
  },
  {
    "Astrid #": "ASTRID 002022",
    "HYG Star ID": 11412,
    "Hipparcos Catalogue": 11439,
    "Distance in Parsecs": 20.6186,
    "mag": 10.89,
    "Absolute Visual Magnitude": 9.319,
    "Color Index": 1.449,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.150991,
    "y": 10.615795,
    "z": -10.591553,
    "vx": -0.00002991,
    "vy": 0.00003354,
    "vz": -0.00000634,
    "rarad": 0.643613353968913,
    "decrad": -0.539479240047515,
    "pmrarad": 0.00000217211073299305,
    "pmdecrad": -3.58519716e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 11412,
    "lum": 0.0163079736102359,
    "var_min": 10.946,
    "var_max": 10.806,
    "ra": 2.458422,
    "dec": -30.909884,
    "pmra": 448.03,
    "pmdec": -73.95,
    "rv": 0,
    "Asset Name": "ASTRID 002022",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Wavy",
    "Outfit": "Wizard",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00002991,
    "y_1": 0.00003354,
    "z_1": -0.00000634,
    "Distance in Parsecs_1": 20.6186,
    "HYG Star ID_1": 11412,
    "Hipparcos Catalogue_1": 11439
  },
  {
    "Astrid #": "ASTRID 002023",
    "HYG Star ID": 118056,
    "Gliese Catalog": "GJ 1034",
    "Distance in Parsecs": 20.6186,
    "mag": 15.04,
    "Absolute Visual Magnitude": 13.469,
    "Spectral Type": "m",
    "Color Index": 1.84,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.75155,
    "y": 6.154703,
    "z": 8.492872,
    "vx": -0.00002865,
    "vy": 0.00017028,
    "vz": -0.00006332,
    "rarad": 0.333703823293097,
    "decrad": 0.424541947219699,
    "pmrarad": 0.00000825928586194444,
    "pmdecrad": -0.000003370555606,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118056,
    "lum": 0.000356779588288562,
    "ra": 1.274655,
    "dec": 24.324462,
    "pmra": 1703.6,
    "pmdec": -695.23,
    "rv": 0,
    "Asset Name": "ASTRID 002023",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Headband",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002865,
    "y_1": 0.00017028,
    "z_1": -0.00006332,
    "Distance in Parsecs_1": 20.6186,
    "HYG Star ID_1": 118056,
    "Gliese Catalog_1": "GJ 1034"
  },
  {
    "Astrid #": "ASTRID 002024",
    "HYG Star ID": 118250,
    "Gliese Catalog": "Wo 9125",
    "Distance in Parsecs": 20.6186,
    "mag": 10.81,
    "Absolute Visual Magnitude": 9.239,
    "Spectral Type": "G0",
    "Color Index": 0.7,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.449856,
    "y": 15.531213,
    "z": 8.643414,
    "vx": 6e-8,
    "vy": 0.0000024,
    "vz": -0.00000438,
    "rarad": 0.978539069744513,
    "decrad": 0.43256913471925,
    "pmrarad": 6.27348902638888e-8,
    "pmdecrad": -2.34145615e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118250,
    "lum": 0.0175549662962123,
    "ra": 3.737744,
    "dec": 24.784386,
    "pmra": 12.94,
    "pmdec": -48.3,
    "rv": 0,
    "Asset Name": "ASTRID 002024",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Guilty Grin",
    "Hair": "Spiky",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 6e-8,
    "y_1": 0.0000024,
    "z_1": -0.00000438,
    "Distance in Parsecs_1": 20.6186,
    "HYG Star ID_1": 118250,
    "Gliese Catalog_1": "Wo 9125"
  },
  {
    "Astrid #": "ASTRID 002025",
    "HYG Star ID": 118372,
    "Gliese Catalog": "GJ 1078",
    "Distance in Parsecs": 20.6186,
    "mag": 15.52,
    "Absolute Visual Magnitude": 13.949,
    "Spectral Type": "m",
    "Color Index": 1.83,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.994267,
    "y": 18.805792,
    "z": 7.905898,
    "vx": -0.00002182,
    "vy": 0.00001494,
    "vz": -0.00002726,
    "rarad": 1.41289646317178,
    "decrad": 0.393512993624,
    "pmrarad": 0.00000115938343568055,
    "pmdecrad": -0.00000143172752,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118372,
    "lum": 0.000229297859182482,
    "ra": 5.396867,
    "dec": 22.546634,
    "pmra": 239.14,
    "pmdec": -295.31,
    "rv": 0,
    "Asset Name": "ASTRID 002025",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Unimpressed",
    "Hair": "Middle Part",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002182,
    "y_1": 0.00001494,
    "z_1": -0.00002726,
    "Distance in Parsecs_1": 20.6186,
    "HYG Star ID_1": 118372,
    "Gliese Catalog_1": "GJ 1078"
  },
  {
    "Astrid #": "ASTRID 002026",
    "HYG Star ID": 14116,
    "Hipparcos Catalogue": 14150,
    "Henry Draper Catalogue": 18803,
    "Gliese Catalog": "Gl 120.2",
    "Distance in Parsecs": 20.6398,
    "mag": 6.62,
    "Absolute Visual Magnitude": 5.046,
    "Spectral Type": "G8V",
    "Color Index": 0.696,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.909469,
    "y": 13.186603,
    "z": 9.244673,
    "vx": -0.00000533,
    "vy": 0.00002805,
    "vz": -0.00001065,
    "rarad": 0.796016369620136,
    "decrad": 0.464418844633601,
    "pmrarad": 0.00000113538515849305,
    "pmdecrad": -8.15699017e-7,
    "flam": 51,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 14116,
    "lum": 0.834833753723649,
    "ra": 3.040559,
    "dec": 26.60924,
    "pmra": 234.19,
    "pmdec": -168.25,
    "rv": 9.6,
    "Asset Name": "ASTRID 002026",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Undercut",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000533,
    "y_1": 0.00002805,
    "z_1": -0.00001065,
    "Distance in Parsecs_1": 20.6398,
    "HYG Star ID_1": 14116,
    "Hipparcos Catalogue_1": 14150,
    "Henry Draper Catalogue_1": 18803,
    "Gliese Catalog_1": "Gl 120.2"
  },
  {
    "Astrid #": "ASTRID 002027",
    "HYG Star ID": 40125,
    "Hipparcos Catalogue": 40239,
    "Gliese Catalog": "Gl 301A",
    "Distance in Parsecs": 20.6398,
    "mag": 9.38,
    "Absolute Visual Magnitude": 7.806,
    "Spectral Type": "M0V",
    "Color Index": 1.393,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.994906,
    "y": 16.747444,
    "z": -4.96426,
    "vx": 0.00001172,
    "vy": 0.00001673,
    "vz": -0.00005455,
    "rarad": 2.15173640097155,
    "decrad": -0.242898155201173,
    "pmrarad": -9.19546107909722e-7,
    "pmdecrad": -0.000002477737277,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 40125,
    "base": "Gl 301",
    "lum": 0.0657052390963553,
    "ra": 8.219028,
    "dec": -13.917039,
    "pmra": -189.67,
    "pmdec": -511.07,
    "rv": 20,
    "Asset Name": "ASTRID 002027",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Infatuated",
    "Hair": "Braid",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001172,
    "y_1": 0.00001673,
    "z_1": -0.00005455,
    "Distance in Parsecs_1": 20.6398,
    "HYG Star ID_1": 40125,
    "Hipparcos Catalogue_1": 40239,
    "Gliese Catalog_1": "Gl 301A"
  },
  {
    "Astrid #": "ASTRID 002028",
    "HYG Star ID": 113242,
    "Hipparcos Catalogue": 113602,
    "Gliese Catalog": "NN 4308A",
    "Distance in Parsecs": 20.6398,
    "mag": 11.56,
    "Absolute Visual Magnitude": 9.986,
    "Spectral Type": "M1",
    "Color Index": 1.526,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.231383,
    "y": -4.837652,
    "z": -8.379448,
    "vx": -0.00000867,
    "vy": 0.00002199,
    "vz": -0.00003156,
    "rarad": 6.02381417578431,
    "decrad": -0.418056332555096,
    "pmrarad": 9.22261064520833e-7,
    "pmdecrad": -0.000001673092011,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113242,
    "base": "NN 4308",
    "lum": 0.00882266928217715,
    "var_min": 11.658,
    "var_max": 11.458,
    "ra": 23.009275,
    "dec": -23.952863,
    "pmra": 190.23,
    "pmdec": -345.1,
    "rv": 0,
    "Asset Name": "ASTRID 002028",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Sweet Nap",
    "Hair": "Halo Boy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000867,
    "y_1": 0.00002199,
    "z_1": -0.00003156,
    "Distance in Parsecs_1": 20.6398,
    "HYG Star ID_1": 113242,
    "Hipparcos Catalogue_1": 113602,
    "Gliese Catalog_1": "NN 4308A"
  },
  {
    "Astrid #": "ASTRID 002029",
    "HYG Star ID": 108544,
    "Hipparcos Catalogue": 108890,
    "Gliese Catalog": "NN 4249",
    "Distance in Parsecs": 20.6526,
    "mag": 12.09,
    "Absolute Visual Magnitude": 10.515,
    "Spectral Type": "M4",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.439326,
    "y": -6.376709,
    "z": -15.96901,
    "vx": -0.00001573,
    "vy": 0.00004904,
    "vz": -0.00003086,
    "rarad": 5.77464129156652,
    "decrad": -0.883903822277552,
    "pmrarad": 0.00000170315045979166,
    "pmdecrad": -0.000002356146006,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 108544,
    "lum": 0.00542000890401623,
    "var_min": 12.197,
    "var_max": 11.807,
    "ra": 22.057505,
    "dec": -50.643959,
    "pmra": 351.3,
    "pmdec": -485.99,
    "rv": 0,
    "Asset Name": "ASTRID 002029",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined Shinobi",
    "Hair": "Elegant",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001573,
    "y_1": 0.00004904,
    "z_1": -0.00003086,
    "Distance in Parsecs_1": 20.6526,
    "HYG Star ID_1": 108544,
    "Hipparcos Catalogue_1": 108890,
    "Gliese Catalog_1": "NN 4249"
  },
  {
    "Astrid #": "ASTRID 002030",
    "HYG Star ID": 7325,
    "Hipparcos Catalogue": 7339,
    "Henry Draper Catalogue": 9407,
    "Gliese Catalog": "Gl 59.1",
    "Distance in Parsecs": 20.6569,
    "mag": 6.52,
    "Absolute Visual Magnitude": 4.945,
    "Spectral Type": "G6V",
    "Color Index": 0.686,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.797516,
    "y": 2.975217,
    "z": 19.27789,
    "vx": -0.00000587,
    "vy": -0.00004401,
    "vz": -0.00002774,
    "rarad": 0.412600048821046,
    "decrad": 1.20337200255871,
    "pmrarad": -0.00000183773473751388,
    "pmdecrad": 5.57778139e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 7325,
    "lum": 0.916220490121999,
    "ra": 1.576016,
    "dec": 68.948137,
    "pmra": -379.06,
    "pmdec": 115.05,
    "rv": -33.4,
    "Asset Name": "ASTRID 002030",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Good Boy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000587,
    "y_1": -0.00004401,
    "z_1": -0.00002774,
    "Distance in Parsecs_1": 20.6569,
    "HYG Star ID_1": 7325,
    "Hipparcos Catalogue_1": 7339,
    "Henry Draper Catalogue_1": 9407,
    "Gliese Catalog_1": "Gl 59.1"
  },
  {
    "Astrid #": "ASTRID 002031",
    "HYG Star ID": 119027,
    "Gliese Catalog": "GJ 1193",
    "Distance in Parsecs": 20.6612,
    "mag": 13.83,
    "Absolute Visual Magnitude": 12.254,
    "Spectral Type": "M3.5",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.874777,
    "y": -16.122711,
    "z": 5.092453,
    "vx": -0.00005653,
    "vy": 0.00003711,
    "vz": -0.00001427,
    "rarad": 4.07757311000455,
    "decrad": 0.24904053761855,
    "pmrarad": -0.00000326841990883333,
    "pmdecrad": -7.12627629e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119027,
    "lum": 0.00109244605322679,
    "ra": 15.575182,
    "dec": 14.268972,
    "pmra": -674.16,
    "pmdec": -146.99,
    "rv": 0,
    "Asset Name": "ASTRID 002031",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Shinobi",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005653,
    "y_1": 0.00003711,
    "z_1": -0.00001427,
    "Distance in Parsecs_1": 20.6612,
    "HYG Star ID_1": 119027,
    "Gliese Catalog_1": "GJ 1193"
  },
  {
    "Astrid #": "ASTRID 002032",
    "HYG Star ID": 49385,
    "Hipparcos Catalogue": 49526,
    "Gliese Catalog": "NN 3585",
    "Distance in Parsecs": 20.6654,
    "mag": 11.32,
    "Absolute Visual Magnitude": 9.744,
    "Spectral Type": "M:",
    "Color Index": 1.492,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.580078,
    "y": 7.317446,
    "z": 13.75119,
    "vx": -0.00001139,
    "vy": 0.00003713,
    "vz": -0.000031,
    "rarad": 2.64736431385961,
    "decrad": 0.72805757129277,
    "pmrarad": -0.00000132024461488888,
    "pmdecrad": -0.000002009795112,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 49385,
    "lum": 0.0110255433210222,
    "var_min": 11.387,
    "var_max": 11.167,
    "ra": 10.112187,
    "dec": 41.714626,
    "pmra": -272.32,
    "pmdec": -414.55,
    "rv": 0,
    "Asset Name": "ASTRID 002032",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "High Fade",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001139,
    "y_1": 0.00003713,
    "z_1": -0.000031,
    "Distance in Parsecs_1": 20.6654,
    "HYG Star ID_1": 49385,
    "Hipparcos Catalogue_1": 49526,
    "Gliese Catalog_1": "NN 3585"
  },
  {
    "Astrid #": "ASTRID 002033",
    "HYG Star ID": 83344,
    "Hipparcos Catalogue": 83601,
    "Henry Draper Catalogue": 154417,
    "Harvard Revised Catalogue": 6349,
    "Gliese Catalog": "Gl 654.1",
    "Distance in Parsecs": 20.6654,
    "mag": 6,
    "Absolute Visual Magnitude": 4.424,
    "Spectral Type": "F9V",
    "Color Index": 0.578,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.886918,
    "y": -20.077518,
    "z": 0.253391,
    "vx": 0.0000022,
    "vy": 0.00001567,
    "vz": -0.00003377,
    "rarad": 4.47362933125984,
    "decrad": 0.012261969232665,
    "pmrarad": -7.59703037430555e-8,
    "pmdecrad": -0.000001624659124,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83344,
    "lum": 1.48047132489762,
    "var": "V2213",
    "var_min": 6.021,
    "var_max": 5.991,
    "ra": 17.088005,
    "dec": 0.702559,
    "pmra": -15.67,
    "pmdec": -335.11,
    "rv": -15.8,
    "Asset Name": "ASTRID 002033",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Undercut",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.0000022,
    "y_1": 0.00001567,
    "z_1": -0.00003377,
    "Distance in Parsecs_1": 20.6654,
    "HYG Star ID_1": 83344,
    "Hipparcos Catalogue_1": 83601,
    "Henry Draper Catalogue_1": 154417,
    "Harvard Revised Catalogue_1": 6349,
    "Gliese Catalog_1": "Gl 654.1"
  },
  {
    "Astrid #": "ASTRID 002034",
    "HYG Star ID": 64202,
    "Hipparcos Catalogue": 64408,
    "Henry Draper Catalogue": 114613,
    "Harvard Revised Catalogue": 4979,
    "Gliese Catalog": "Gl 501.2",
    "Distance in Parsecs": 20.6697,
    "mag": 4.85,
    "Absolute Visual Magnitude": 3.273,
    "Spectral Type": "G3V",
    "Color Index": 0.693,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.531004,
    "y": -5.050305,
    "z": -12.669384,
    "vx": -0.00000307,
    "vy": 0.0000393,
    "vz": 0.00001313,
    "rarad": 3.4559878014374,
    "decrad": -0.659787196779528,
    "pmrarad": -0.00000185412143991666,
    "pmdecrad": 2.27910911e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 64202,
    "lum": 4.2736603149693,
    "ra": 13.200901,
    "dec": -37.803022,
    "pmra": -382.44,
    "pmdec": 47.01,
    "rv": -15,
    "Asset Name": "ASTRID 002034",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Lion Mane",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000307,
    "y_1": 0.0000393,
    "z_1": 0.00001313,
    "Distance in Parsecs_1": 20.6697,
    "HYG Star ID_1": 64202,
    "Hipparcos Catalogue_1": 64408,
    "Henry Draper Catalogue_1": 114613,
    "Harvard Revised Catalogue_1": 4979,
    "Gliese Catalog_1": "Gl 501.2"
  },
  {
    "Astrid #": "ASTRID 002035",
    "HYG Star ID": 38544,
    "Hipparcos Catalogue": 38657,
    "Henry Draper Catalogue": 64468,
    "Gliese Catalog": "Gl 292.1",
    "Distance in Parsecs": 20.6911,
    "mag": 7.76,
    "Absolute Visual Magnitude": 6.181,
    "Spectral Type": "K2",
    "Color Index": 0.95,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.389066,
    "y": 17.131531,
    "z": 6.816949,
    "vx": -0.00000672,
    "vy": -0.00000714,
    "vz": -0.00004935,
    "rarad": 2.07214679905026,
    "decrad": 0.335737466717524,
    "pmrarad": 4.50634316076388e-7,
    "pmdecrad": -0.00000220357514,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 38544,
    "lum": 0.293494522226979,
    "ra": 7.915018,
    "dec": 19.23634,
    "pmra": 92.95,
    "pmdec": -454.52,
    "rv": -18.7,
    "Asset Name": "ASTRID 002035",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Arrogant",
    "Hair": "Lion Mane",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000672,
    "y_1": -0.00000714,
    "z_1": -0.00004935,
    "Distance in Parsecs_1": 20.6911,
    "HYG Star ID_1": 38544,
    "Hipparcos Catalogue_1": 38657,
    "Henry Draper Catalogue_1": 64468,
    "Gliese Catalog_1": "Gl 292.1"
  },
  {
    "Astrid #": "ASTRID 002036",
    "HYG Star ID": 118620,
    "Gliese Catalog": "NN 3552",
    "Distance in Parsecs": 20.7039,
    "mag": 15.56,
    "Absolute Visual Magnitude": 13.98,
    "Spectral Type": "m",
    "Color Index": 1.92,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.183106,
    "y": 11.864201,
    "z": 9.312192,
    "vx": -0.00006816,
    "vy": -0.00005538,
    "vz": -0.00004912,
    "rarad": 2.44496217477952,
    "decrad": 0.4665272765167,
    "pmrarad": 0.0000041643555905,
    "pmdecrad": -0.000002482449666,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118620,
    "lum": 0.00022284351492703,
    "ra": 9.339068,
    "dec": 26.730044,
    "pmra": 858.96,
    "pmdec": -512.04,
    "rv": -7,
    "Asset Name": "ASTRID 002036",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Serious Shinobi",
    "Hair": "High Bun",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006816,
    "y_1": -0.00005538,
    "z_1": -0.00004912,
    "Distance in Parsecs_1": 20.7039,
    "HYG Star ID_1": 118620,
    "Gliese Catalog_1": "NN 3552"
  },
  {
    "Astrid #": "ASTRID 002037",
    "HYG Star ID": 118896,
    "Gliese Catalog": "Wo 9448B",
    "Distance in Parsecs": 20.7039,
    "mag": 11.3,
    "Absolute Visual Magnitude": 9.72,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -16.313813,
    "y": -6.922797,
    "z": 10.70448,
    "vx": -0.00000406,
    "vy": 0.00001324,
    "vz": 0.00000238,
    "rarad": 3.54291980108524,
    "decrad": 0.54337426988205,
    "pmrarad": -6.65358295194444e-7,
    "pmdecrad": 1.34157641e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 2,
    "comp_primary": 65800,
    "base": "Wo 9448",
    "lum": 0.011271974561755,
    "ra": 13.532957,
    "dec": 31.133052,
    "pmra": -137.24,
    "pmdec": 27.67,
    "rv": 0,
    "Asset Name": "ASTRID 002037",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Cute Neko",
    "Hair": "Slick",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000406,
    "y_1": 0.00001324,
    "z_1": 0.00000238,
    "Distance in Parsecs_1": 20.7039,
    "HYG Star ID_1": 118896,
    "Gliese Catalog_1": "Wo 9448B"
  },
  {
    "Astrid #": "ASTRID 002038",
    "HYG Star ID": 118945,
    "Gliese Catalog": "NN 3826",
    "Distance in Parsecs": 20.7039,
    "mag": 11.6,
    "Absolute Visual Magnitude": 10.02,
    "Spectral Type": "M1",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.287604,
    "y": -2.688081,
    "z": 20.07591,
    "vx": 0.0000081,
    "vy": 0.00004883,
    "vz": 0.00000827,
    "rarad": 3.70186425312236,
    "decrad": 1.3238689167086,
    "pmrarad": -0.00000179007755271527,
    "pmdecrad": 0.000001634554172,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 118945,
    "lum": 0.00855066712884683,
    "ra": 14.14008,
    "dec": 75.852102,
    "pmra": -369.23,
    "pmdec": 337.15,
    "rv": 0,
    "Asset Name": "ASTRID 002038",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Spiky",
    "Outfit": "Boxer",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.0000081,
    "y_1": 0.00004883,
    "z_1": 0.00000827,
    "Distance in Parsecs_1": 20.7039,
    "HYG Star ID_1": 118945,
    "Gliese Catalog_1": "NN 3826"
  },
  {
    "Astrid #": "ASTRID 002039",
    "HYG Star ID": 119533,
    "Gliese Catalog": "GJ 1275",
    "Distance in Parsecs": 20.7039,
    "mag": 15.52,
    "Absolute Visual Magnitude": 13.94,
    "Spectral Type": "DA9",
    "Color Index": 0.63,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 17.190487,
    "y": -5.306329,
    "z": 10.246051,
    "vx": 0.00003,
    "vy": 0.00012216,
    "vz": 0.00001301,
    "rarad": 5.98374029389414,
    "decrad": 0.51770253843405,
    "pmrarad": 0.00000606569788290277,
    "pmdecrad": 7.23288681e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119533,
    "lum": 0.000231206479017559,
    "ra": 22.856204,
    "dec": 29.66217,
    "pmra": 1251.14,
    "pmdec": 149.19,
    "rv": 0,
    "Asset Name": "ASTRID 002039",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Wavy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003,
    "y_1": 0.00012216,
    "z_1": 0.00001301,
    "Distance in Parsecs_1": 20.7039,
    "HYG Star ID_1": 119533,
    "Gliese Catalog_1": "GJ 1275"
  },
  {
    "Astrid #": "ASTRID 002040",
    "HYG Star ID": 119556,
    "Gliese Catalog": "Gl 893.3A",
    "Distance in Parsecs": 20.7039,
    "mag": 11.57,
    "Absolute Visual Magnitude": 9.99,
    "Spectral Type": "g-k",
    "Color Index": 0.9,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.396708,
    "y": -2.940311,
    "z": -13.525807,
    "vx": -0.00001625,
    "vy": 0.00000221,
    "vz": -0.00001899,
    "rarad": 6.09448787421235,
    "decrad": -0.7119317306218,
    "pmrarad": -4.22757529444444e-8,
    "pmdecrad": -0.000001211292436,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 119556,
    "base": "Gl 893.3",
    "lum": 0.00879022516830883,
    "ra": 23.279229,
    "dec": -40.790683,
    "pmra": -8.72,
    "pmdec": -249.85,
    "rv": 0,
    "Asset Name": "ASTRID 002040",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Round Brush",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00001625,
    "y_1": 0.00000221,
    "z_1": -0.00001899,
    "Distance in Parsecs_1": 20.7039,
    "HYG Star ID_1": 119556,
    "Gliese Catalog_1": "Gl 893.3A"
  },
  {
    "Astrid #": "ASTRID 002041",
    "HYG Star ID": 119557,
    "Gliese Catalog": "Gl 893.3B",
    "Distance in Parsecs": 20.7039,
    "mag": 17.4,
    "Absolute Visual Magnitude": 15.82,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 15.395763,
    "y": -2.938973,
    "z": -13.527174,
    "vx": -0.00001626,
    "vy": 0.00000221,
    "vz": -0.00001899,
    "rarad": 6.09456044321235,
    "decrad": -0.7120189266218,
    "pmrarad": -4.22757529444444e-8,
    "pmdecrad": -0.000001211292436,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119556,
    "base": "Gl 893.3",
    "lum": 0.000040926065973001,
    "ra": 23.279506,
    "dec": -40.795679,
    "pmra": -8.72,
    "pmdec": -249.85,
    "rv": 0,
    "Asset Name": "ASTRID 002041",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Manbun",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001626,
    "y_1": 0.00000221,
    "z_1": -0.00001899,
    "Distance in Parsecs_1": 20.7039,
    "HYG Star ID_1": 119557,
    "Gliese Catalog_1": "Gl 893.3B"
  },
  {
    "Astrid #": "ASTRID 002042",
    "HYG Star ID": 14553,
    "Hipparcos Catalogue": 14589,
    "Distance in Parsecs": 20.7082,
    "mag": 10.86,
    "Absolute Visual Magnitude": 9.279,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.859874,
    "y": 13.83994,
    "z": -8.479933,
    "vx": -0.00000509,
    "vy": -0.00000436,
    "vz": -0.00001483,
    "rarad": 0.822088485280037,
    "decrad": -0.421901978961282,
    "pmrarad": 3.66034328819444e-8,
    "pmdecrad": -7.85252718e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 14553,
    "lum": 0.01691998602503,
    "ra": 3.140147,
    "dec": -24.173203,
    "pmra": 7.55,
    "pmdec": -161.97,
    "rv": 0,
    "Asset Name": "ASTRID 002042",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "High Fade",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000509,
    "y_1": -0.00000436,
    "z_1": -0.00001483,
    "Distance in Parsecs_1": 20.7082,
    "HYG Star ID_1": 14553,
    "Hipparcos Catalogue_1": 14589
  },
  {
    "Astrid #": "ASTRID 002043",
    "HYG Star ID": 51670,
    "Hipparcos Catalogue": 51819,
    "Henry Draper Catalogue": 90343,
    "Gliese Catalog": "Gl 396",
    "Distance in Parsecs": 20.7297,
    "mag": 7.29,
    "Absolute Visual Magnitude": 5.707,
    "Spectral Type": "K0",
    "Color Index": 0.819,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.88616,
    "y": 0.731709,
    "z": 20.630821,
    "vx": 0.00000295,
    "vy": -0.00000319,
    "vz": 0.00000963,
    "rarad": 2.7715219797301,
    "decrad": 1.47304604864166,
    "pmrarad": 9.19691552013888e-8,
    "pmdecrad": 2.32759048e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 51670,
    "lum": 0.454150712632554,
    "ra": 10.586434,
    "dec": 84.399322,
    "pmra": 18.97,
    "pmdec": 48.01,
    "rv": 9,
    "Asset Name": "ASTRID 002043",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Cute Neko",
    "Hair": "High Bun",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000295,
    "y_1": -0.00000319,
    "z_1": 0.00000963,
    "Distance in Parsecs_1": 20.7297,
    "HYG Star ID_1": 51670,
    "Hipparcos Catalogue_1": 51819,
    "Henry Draper Catalogue_1": 90343,
    "Gliese Catalog_1": "Gl 396"
  },
  {
    "Astrid #": "ASTRID 002044",
    "HYG Star ID": 101876,
    "Hipparcos Catalogue": 102207,
    "Gliese Catalog": "Gl 799.1",
    "Distance in Parsecs": 20.7383,
    "mag": 12.38,
    "Absolute Visual Magnitude": 10.796,
    "Spectral Type": "DAw",
    "Color Index": -0.199,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.687416,
    "y": -14.779264,
    "z": -7.118981,
    "vx": 0.00002485,
    "vy": 0.00002578,
    "vz": -0.00000924,
    "rarad": 5.42177342585962,
    "decrad": -0.350403679914127,
    "pmrarad": 0.00000171895538577777,
    "pmdecrad": -4.74390186e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 101876,
    "lum": 0.00418408019567427,
    "var_min": 12.575,
    "var_max": 12.225,
    "ra": 20.709649,
    "dec": -20.076652,
    "pmra": 354.56,
    "pmdec": -97.85,
    "rv": 0,
    "Asset Name": "ASTRID 002044",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Pompadour",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002485,
    "y_1": 0.00002578,
    "z_1": -0.00000924,
    "Distance in Parsecs_1": 20.7383,
    "HYG Star ID_1": 101876,
    "Hipparcos Catalogue_1": 102207,
    "Gliese Catalog_1": "Gl 799.1"
  },
  {
    "Astrid #": "ASTRID 002045",
    "HYG Star ID": 119561,
    "Gliese Catalog": "Gl 894.2B",
    "Distance in Parsecs": 20.7383,
    "mag": 7.61,
    "Absolute Visual Magnitude": 6.026,
    "Spectral Type": "K2 V",
    "Color Index": 0.91,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.849523,
    "y": -3.579267,
    "z": -4.825376,
    "vx": 0.00001353,
    "vy": 0.00002897,
    "vz": -0.00001113,
    "rarad": 6.10478028398304,
    "decrad": -0.234825799183,
    "pmrarad": 0.00000149055966086805,
    "pmdecrad": -4.3022366e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 2,
    "comp_primary": 114764,
    "base": "Gl 894.2",
    "lum": 0.338532212815354,
    "ra": 23.318543,
    "dec": -13.454527,
    "pmra": 307.45,
    "pmdec": -88.74,
    "rv": 10.3,
    "Asset Name": "ASTRID 002045",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh Snap",
    "Hair": "Audiophile",
    "Outfit": "Mecha",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001353,
    "y_1": 0.00002897,
    "z_1": -0.00001113,
    "Distance in Parsecs_1": 20.7383,
    "HYG Star ID_1": 119561,
    "Gliese Catalog_1": "Gl 894.2B"
  },
  {
    "Astrid #": "ASTRID 002046",
    "HYG Star ID": 10168,
    "Hipparcos Catalogue": 10191,
    "Henry Draper Catalogue": 13513,
    "Distance in Parsecs": 20.7426,
    "mag": 10.15,
    "Absolute Visual Magnitude": 8.566,
    "Spectral Type": "K0",
    "Color Index": 1.43,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.170792,
    "y": 9.11819,
    "z": -12.095566,
    "vx": -0.00000194,
    "vy": -0.00002018,
    "vz": -0.00001749,
    "rarad": 0.571758106421355,
    "decrad": -0.622572317529861,
    "pmrarad": -7.67266130847222e-7,
    "pmdecrad": -0.000001037889127,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 10168,
    "lum": 0.0326287172143084,
    "var_min": 10.271,
    "var_max": 10.081,
    "ra": 2.183955,
    "dec": -35.670766,
    "pmra": -158.26,
    "pmdec": -214.08,
    "rv": 0,
    "Asset Name": "ASTRID 002046",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Bob",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000194,
    "y_1": -0.00002018,
    "z_1": -0.00001749,
    "Distance in Parsecs_1": 20.7426,
    "HYG Star ID_1": 10168,
    "Hipparcos Catalogue_1": 10191,
    "Henry Draper Catalogue_1": 13513
  },
  {
    "Astrid #": "ASTRID 002047",
    "HYG Star ID": 118045,
    "Gliese Catalog": "Gl 53.1B",
    "Distance in Parsecs": 20.7469,
    "mag": 13.6,
    "Absolute Visual Magnitude": 12.015,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.278146,
    "y": 5.555984,
    "z": 8.091618,
    "vx": 0.00001538,
    "vy": 0.00001542,
    "vz": -0.00004532,
    "rarad": 0.295091655099418,
    "decrad": 0.400648686092949,
    "pmrarad": 4.95721988368055e-7,
    "pmdecrad": -0.000002372838141,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 5275,
    "base": "Gl  53.1",
    "lum": 0.00136144468246594,
    "ra": 1.127167,
    "dec": 22.955479,
    "pmra": 102.25,
    "pmdec": -489.43,
    "rv": 0,
    "Asset Name": "ASTRID 002047",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Audiophile",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001538,
    "y_1": 0.00001542,
    "z_1": -0.00004532,
    "Distance in Parsecs_1": 20.7469,
    "HYG Star ID_1": 118045,
    "Gliese Catalog_1": "Gl 53.1B"
  },
  {
    "Astrid #": "ASTRID 002048",
    "HYG Star ID": 27255,
    "Hipparcos Catalogue": 27323,
    "Gliese Catalog": "GJ 1084",
    "Distance in Parsecs": 20.7555,
    "mag": 9.74,
    "Absolute Visual Magnitude": 8.154,
    "Spectral Type": "M0V",
    "Color Index": 1.377,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.761129,
    "y": 13.725101,
    "z": -15.550982,
    "vx": -0.00000387,
    "vy": -0.00002402,
    "vz": -0.00002139,
    "rarad": 1.51539723743837,
    "decrad": -0.846923395780038,
    "pmrarad": 1.22221528868055e-7,
    "pmdecrad": -0.000001556203433,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 27255,
    "lum": 0.0476869998101777,
    "ra": 5.788391,
    "dec": -48.525136,
    "pmra": 25.21,
    "pmdec": -320.99,
    "rv": 0,
    "Asset Name": "ASTRID 002048",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Mohawk",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": -0.00000387,
    "y_1": -0.00002402,
    "z_1": -0.00002139,
    "Distance in Parsecs_1": 20.7555,
    "HYG Star ID_1": 27255,
    "Hipparcos Catalogue_1": 27323,
    "Gliese Catalog_1": "GJ 1084"
  },
  {
    "Astrid #": "ASTRID 002049",
    "HYG Star ID": 108122,
    "Hipparcos Catalogue": 108467,
    "Gliese Catalog": "Gl 842.2",
    "Distance in Parsecs": 20.7684,
    "mag": 10.56,
    "Absolute Visual Magnitude": 8.973,
    "Spectral Type": "M0",
    "Color Index": 0.742,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.458165,
    "y": -2.615365,
    "z": 20.114775,
    "vx": 0.00000583,
    "vy": 0.00002364,
    "vz": -0.00001786,
    "rarad": 5.75261312450611,
    "decrad": 1.31927793271904,
    "pmrarad": 0.00000112394355563194,
    "pmdecrad": 1.09567891e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 108122,
    "lum": 0.0224284881586928,
    "var_min": 10.608,
    "var_max": 10.498,
    "ra": 21.973364,
    "dec": 75.589058,
    "pmra": 231.83,
    "pmdec": 22.6,
    "rv": -18.6,
    "Asset Name": "ASTRID 002049",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Undercut",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00000583,
    "y_1": 0.00002364,
    "z_1": -0.00001786,
    "Distance in Parsecs_1": 20.7684,
    "HYG Star ID_1": 108122,
    "Hipparcos Catalogue_1": 108467,
    "Gliese Catalog_1": "Gl 842.2"
  },
  {
    "Astrid #": "ASTRID 002050",
    "HYG Star ID": 118959,
    "Gliese Catalog": "Gl 544B",
    "Distance in Parsecs": 20.7814,
    "mag": 15.1,
    "Absolute Visual Magnitude": 13.512,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.976094,
    "y": -11.840462,
    "z": -1.866087,
    "vx": -0.00003521,
    "vy": 0.00005248,
    "vz": -0.0000128,
    "rarad": 3.75062501855011,
    "decrad": -0.08991716490075,
    "pmrarad": -0.00000304050899760416,
    "pmdecrad": -6.18598015e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 69793,
    "base": "Gl 544",
    "lum": 0.000342925673297559,
    "ra": 14.326332,
    "dec": -5.151874,
    "pmra": -627.15,
    "pmdec": -127.59,
    "rv": 0,
    "Asset Name": "ASTRID 002050",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Bangs",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003521,
    "y_1": 0.00005248,
    "z_1": -0.0000128,
    "Distance in Parsecs_1": 20.7814,
    "HYG Star ID_1": 118959,
    "Gliese Catalog_1": "Gl 544B"
  },
  {
    "Astrid #": "ASTRID 002051",
    "HYG Star ID": 119107,
    "Gliese Catalog": "GJ 1205",
    "Distance in Parsecs": 20.79,
    "mag": 15.06,
    "Absolute Visual Magnitude": 13.471,
    "Spectral Type": "DC7",
    "Color Index": 0.33,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.162833,
    "y": -11.587188,
    "z": 16.752074,
    "vx": -0.00001692,
    "vy": -0.00001076,
    "vz": -0.00001164,
    "rarad": 4.3675075204543,
    "decrad": 0.9369839203034,
    "pmrarad": -5.90890913861111e-7,
    "pmdecrad": -9.45633932e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119107,
    "lum": 0.000356122980942761,
    "ra": 16.68265,
    "dec": 53.685224,
    "pmra": -121.88,
    "pmdec": -195.05,
    "rv": 0,
    "Asset Name": "ASTRID 002051",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Smoulder",
    "Hair": "Angelic",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001692,
    "y_1": -0.00001076,
    "z_1": -0.00001164,
    "Distance in Parsecs_1": 20.79,
    "HYG Star ID_1": 119107,
    "Gliese Catalog_1": "GJ 1205"
  },
  {
    "Astrid #": "ASTRID 002052",
    "HYG Star ID": 19782,
    "Hipparcos Catalogue": 19832,
    "Gliese Catalog": "GJ 1069",
    "Distance in Parsecs": 20.7943,
    "mag": 9.38,
    "Absolute Visual Magnitude": 7.79,
    "Spectral Type": "K5V",
    "Color Index": 1.209,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.15686,
    "y": 18.600769,
    "z": -1.601946,
    "vx": -0.00000898,
    "vy": 0.00000362,
    "vz": -0.00000926,
    "rarad": 1.11334057564752,
    "decrad": -0.0771141713859375,
    "pmrarad": 4.64645431444444e-7,
    "pmdecrad": -4.4690125e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 19782,
    "lum": 0.0666806769213621,
    "ra": 4.252648,
    "dec": -4.418317,
    "pmra": 95.84,
    "pmdec": -92.18,
    "rv": 0,
    "Asset Name": "ASTRID 002052",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Prince",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000898,
    "y_1": 0.00000362,
    "z_1": -0.00000926,
    "Distance in Parsecs_1": 20.7943,
    "HYG Star ID_1": 19782,
    "Hipparcos Catalogue_1": 19832,
    "Gliese Catalog_1": "GJ 1069"
  },
  {
    "Astrid #": "ASTRID 002053",
    "HYG Star ID": 36448,
    "Hipparcos Catalogue": 36551,
    "Henry Draper Catalogue": 59582,
    "Gliese Catalog": "Gl 276",
    "Distance in Parsecs": 20.8073,
    "mag": 8.94,
    "Absolute Visual Magnitude": 7.349,
    "Spectral Type": "K5",
    "Color Index": 1.107,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.796716,
    "y": 18.56386,
    "z": 5.250002,
    "vx": -0.00003495,
    "vy": 0.00006374,
    "vz": -0.00001137,
    "rarad": 1.96841937610553,
    "decrad": 0.255064930549705,
    "pmrarad": 3.62640633055555e-7,
    "pmdecrad": -0.000001405765748,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 36448,
    "lum": 0.100092145831929,
    "ra": 7.518808,
    "dec": 14.614144,
    "pmra": 74.8,
    "pmdec": -289.96,
    "rv": 65.6,
    "Asset Name": "ASTRID 002053",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Prince",
    "Outfit": "Boxer",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00003495,
    "y_1": 0.00006374,
    "z_1": -0.00001137,
    "Distance in Parsecs_1": 20.8073,
    "HYG Star ID_1": 36448,
    "Hipparcos Catalogue_1": 36551,
    "Henry Draper Catalogue_1": 59582,
    "Gliese Catalog_1": "Gl 276"
  },
  {
    "Astrid #": "ASTRID 002054",
    "HYG Star ID": 29577,
    "Hipparcos Catalogue": 29650,
    "Henry Draper Catalogue": 43042,
    "Harvard Revised Catalogue": 2220,
    "Gliese Catalog": "NN 3390",
    "Distance in Parsecs": 20.816,
    "mag": 5.2,
    "Absolute Visual Magnitude": 3.608,
    "Spectral Type": "F6V",
    "Color Index": 0.43,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.273045,
    "y": 19.622386,
    "z": 6.830848,
    "vx": 0.00000702,
    "vy": 0.00004104,
    "vz": -0.00000533,
    "rarad": 1.63558294489774,
    "decrad": 0.334343091734832,
    "pmrarad": -4.64354543236111e-7,
    "pmdecrad": -8.80373162e-7,
    "flam": 71,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29577,
    "lum": 3.13906276913765,
    "bf": "71    Ori",
    "ra": 6.247467,
    "dec": 19.156448,
    "pmra": -95.78,
    "pmdec": -181.59,
    "rv": 35.7,
    "Asset Name": "ASTRID 002054",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Oof",
    "Hair": "Antlers",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000702,
    "y_1": 0.00004104,
    "z_1": -0.00000533,
    "Distance in Parsecs_1": 20.816,
    "HYG Star ID_1": 29577,
    "Hipparcos Catalogue_1": 29650,
    "Henry Draper Catalogue_1": 43042,
    "Harvard Revised Catalogue_1": 2220,
    "Gliese Catalog_1": "NN 3390"
  },
  {
    "Astrid #": "ASTRID 002055",
    "HYG Star ID": 106974,
    "Hipparcos Catalogue": 107317,
    "Gliese Catalog": "NN 4213",
    "Distance in Parsecs": 20.8247,
    "mag": 12.06,
    "Absolute Visual Magnitude": 10.467,
    "Spectral Type": "M3V:",
    "Color Index": 1.522,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.159521,
    "y": -11.550676,
    "z": 2.408501,
    "vx": -0.00001679,
    "vy": -0.00002843,
    "vz": -0.00001674,
    "rarad": 5.69071801517601,
    "decrad": 0.11591536790222,
    "pmrarad": -0.00000158272274154166,
    "pmdecrad": -8.09154032e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 106974,
    "lum": 0.00566500111919532,
    "var_min": 12.188,
    "var_max": 11.928,
    "ra": 21.736942,
    "dec": 6.641461,
    "pmra": -326.46,
    "pmdec": -166.9,
    "rv": 0,
    "Asset Name": "ASTRID 002055",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Anxious Laugh",
    "Hair": "High Bun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001679,
    "y_1": -0.00002843,
    "z_1": -0.00001674,
    "Distance in Parsecs_1": 20.8247,
    "HYG Star ID_1": 106974,
    "Hipparcos Catalogue_1": 107317,
    "Gliese Catalog_1": "NN 4213"
  },
  {
    "Astrid #": "ASTRID 002056",
    "HYG Star ID": 118110,
    "Gliese Catalog": "NN 3134",
    "Distance in Parsecs": 20.8333,
    "mag": 14.31,
    "Absolute Visual Magnitude": 12.716,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.682724,
    "y": 4.761022,
    "z": 18.770584,
    "vx": 2.2e-7,
    "vy": 0.00002739,
    "vz": -0.00000704,
    "rarad": 0.554709972432886,
    "decrad": 1.12204485921905,
    "pmrarad": 0.00000111196865772222,
    "pmdecrad": -7.78615619e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118110,
    "lum": 0.000713838553601663,
    "ra": 2.118836,
    "dec": 64.288435,
    "pmra": 229.36,
    "pmdec": -160.6,
    "rv": 0,
    "Asset Name": "ASTRID 002056",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Slick",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 2.2e-7,
    "y_1": 0.00002739,
    "z_1": -0.00000704,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118110,
    "Gliese Catalog_1": "NN 3134"
  },
  {
    "Astrid #": "ASTRID 002057",
    "HYG Star ID": 118155,
    "Gliese Catalog": "NN 3166",
    "Distance in Parsecs": 20.8333,
    "mag": 13.71,
    "Absolute Visual Magnitude": 12.116,
    "Spectral Type": "M3  e",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.873342,
    "y": 11.975698,
    "z": 8.328697,
    "vx": -0.00000349,
    "vy": 0.00000469,
    "vz": -0.00003121,
    "rarad": 0.677889458963504,
    "decrad": 0.41128755530895,
    "pmrarad": 2.80319270097222e-7,
    "pmdecrad": -0.000001377733821,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118155,
    "lum": 0.00124050923004222,
    "ra": 2.589347,
    "dec": 23.565041,
    "pmra": 57.82,
    "pmdec": -284.18,
    "rv": -12,
    "Asset Name": "ASTRID 002057",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Bandana",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000349,
    "y_1": 0.00000469,
    "z_1": -0.00003121,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118155,
    "Gliese Catalog_1": "NN 3166"
  },
  {
    "Astrid #": "ASTRID 002058",
    "HYG Star ID": 118160,
    "Gliese Catalog": "NN 3171",
    "Distance in Parsecs": 20.8333,
    "mag": 16,
    "Absolute Visual Magnitude": 14.406,
    "Spectral Type": "M5  e",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.012799,
    "y": 13.090057,
    "z": 2.50341,
    "vx": -0.00002132,
    "vy": 0.00002516,
    "vz": 0.00000483,
    "rarad": 0.685305154137077,
    "decrad": 0.120454920706499,
    "pmrarad": 0.00000158272274154166,
    "pmdecrad": 2.3371413e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118160,
    "lum": 0.000150522006863212,
    "ra": 2.617673,
    "dec": 6.901559,
    "pmra": 326.46,
    "pmdec": 48.21,
    "rv": 0,
    "Asset Name": "ASTRID 002058",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Goddess",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002132,
    "y_1": 0.00002516,
    "z_1": 0.00000483,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118160,
    "Gliese Catalog_1": "NN 3171"
  },
  {
    "Astrid #": "ASTRID 002059",
    "HYG Star ID": 118189,
    "Gliese Catalog": "NN 3196A",
    "Distance in Parsecs": 20.8333,
    "mag": 13.37,
    "Absolute Visual Magnitude": 11.776,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.130842,
    "y": 14.591399,
    "z": -4.629987,
    "vx": -0.00001901,
    "vy": 0.00001537,
    "vz": -0.00000959,
    "rarad": 0.801430133016985,
    "decrad": -0.224111047699,
    "pmrarad": 0.00000116874033971527,
    "pmdecrad": -4.7219398e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118189,
    "base": "NN 3196",
    "lum": 0.00169668023234646,
    "ra": 3.061238,
    "dec": -12.840617,
    "pmra": 241.07,
    "pmdec": -97.4,
    "rv": 0,
    "Asset Name": "ASTRID 002059",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Bandana",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001901,
    "y_1": 0.00001537,
    "z_1": -0.00000959,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118189,
    "Gliese Catalog_1": "NN 3196A"
  },
  {
    "Astrid #": "ASTRID 002060",
    "HYG Star ID": 118190,
    "Gliese Catalog": "NN 3197B",
    "Distance in Parsecs": 20.8333,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.906,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.122675,
    "y": 14.597792,
    "z": -4.634749,
    "vx": -0.00001902,
    "vy": 0.00001536,
    "vz": -0.00000959,
    "rarad": 0.801937939016985,
    "decrad": -0.224345516699,
    "pmrarad": 0.00000116874033971527,
    "pmdecrad": -4.7219398e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118190,
    "base": "NN 3197",
    "lum": 0.00150522006863212,
    "ra": 3.063177,
    "dec": -12.854051,
    "pmra": 241.07,
    "pmdec": -97.4,
    "rv": 0,
    "Asset Name": "ASTRID 002060",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Firm",
    "Hair": "Manbun",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001902,
    "y_1": 0.00001536,
    "z_1": -0.00000959,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118190,
    "Gliese Catalog_1": "NN 3197B"
  },
  {
    "Astrid #": "ASTRID 002061",
    "HYG Star ID": 118195,
    "Gliese Catalog": "NN 3204",
    "Distance in Parsecs": 20.8333,
    "mag": 11.72,
    "Absolute Visual Magnitude": 10.126,
    "Spectral Type": "M3",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.35175,
    "y": 8.053667,
    "z": 17.751524,
    "vx": 0.00000173,
    "vy": 0.00002451,
    "vz": -0.00001183,
    "rarad": 0.830896387584745,
    "decrad": 1.01993597265289,
    "pmrarad": 7.31971694902777e-7,
    "pmdecrad": -0.000001085206942,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 118195,
    "lum": 0.00775532495758591,
    "ra": 3.17379,
    "dec": 58.438027,
    "pmra": 150.98,
    "pmdec": -223.84,
    "rv": 0,
    "Asset Name": "ASTRID 002061",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Bob",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000173,
    "y_1": 0.00002451,
    "z_1": -0.00001183,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118195,
    "Gliese Catalog_1": "NN 3204"
  },
  {
    "Astrid #": "ASTRID 002062",
    "HYG Star ID": 118244,
    "Gliese Catalog": "Wo 9120",
    "Distance in Parsecs": 20.8333,
    "mag": 13.1,
    "Absolute Visual Magnitude": 11.506,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.809276,
    "y": 15.389973,
    "z": 8.962962,
    "vx": -0.00000567,
    "vy": 0.00003432,
    "vz": -0.00005207,
    "rarad": 0.958483078074285,
    "decrad": 0.4447396182117,
    "pmrarad": 0.00000116966148570833,
    "pmdecrad": -0.000002768935766,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118244,
    "lum": 0.00217570495092579,
    "ra": 3.661136,
    "dec": 25.481703,
    "pmra": 241.26,
    "pmdec": -571.13,
    "rv": 0,
    "Asset Name": "ASTRID 002062",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Fedora",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000567,
    "y_1": 0.00003432,
    "z_1": -0.00005207,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118244,
    "Gliese Catalog_1": "Wo 9120"
  },
  {
    "Astrid #": "ASTRID 002063",
    "HYG Star ID": 118355,
    "Gliese Catalog": "NN 3335",
    "Distance in Parsecs": 20.8333,
    "mag": 12.48,
    "Absolute Visual Magnitude": 10.886,
    "Spectral Type": "M3",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.41714,
    "y": 19.587516,
    "z": 5.553783,
    "vx": -0.0000107,
    "vy": 0.00001962,
    "vz": -0.00006065,
    "rarad": 1.34899718433563,
    "decrad": 0.2698449527219,
    "pmrarad": 7.08506712763888e-7,
    "pmdecrad": -0.000003020825562,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118355,
    "lum": 0.00385123482428931,
    "ra": 5.15279,
    "dec": 15.460977,
    "pmra": 146.14,
    "pmdec": -623.09,
    "rv": 0,
    "Asset Name": "ASTRID 002063",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Star of Culture",
    "Hair": "Pirate Hat",
    "Outfit": "Astro",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000107,
    "y_1": 0.00001962,
    "z_1": -0.00006065,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118355,
    "Gliese Catalog_1": "NN 3335"
  },
  {
    "Astrid #": "ASTRID 002064",
    "HYG Star ID": 118363,
    "Gliese Catalog": "NN 3341",
    "Distance in Parsecs": 20.8333,
    "mag": 12.15,
    "Absolute Visual Magnitude": 10.556,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.413662,
    "y": 17.471944,
    "z": -10.821483,
    "vx": -0.00004816,
    "vy": 0.00002287,
    "vz": 0.00002172,
    "rarad": 1.37782624755439,
    "decrad": -0.5461861363794,
    "pmrarad": 0.00000247953108783333,
    "pmdecrad": 0.000001220072412,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118363,
    "lum": 0.00521915265835447,
    "ra": 5.262909,
    "dec": -31.29416,
    "pmra": 511.44,
    "pmdec": 251.66,
    "rv": 0,
    "Asset Name": "ASTRID 002064",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Charmer",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004816,
    "y_1": 0.00002287,
    "z_1": 0.00002172,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118363,
    "Gliese Catalog_1": "NN 3341"
  },
  {
    "Astrid #": "ASTRID 002065",
    "HYG Star ID": 118390,
    "Henry Draper Catalogue": 38114,
    "Gliese Catalog": "NN 3363",
    "Distance in Parsecs": 20.8333,
    "mag": 8.15,
    "Absolute Visual Magnitude": 6.556,
    "Spectral Type": "G5",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.161995,
    "y": 17.551175,
    "z": 11.159651,
    "vx": -0.00000382,
    "vy": -0.00003392,
    "vz": -0.00003102,
    "rarad": 1.50468599400229,
    "decrad": 0.56536314688085,
    "pmrarad": 7.53400459583333e-8,
    "pmdecrad": -3.80462383e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 118390,
    "lum": 0.207778209750426,
    "ra": 5.747477,
    "dec": 32.392922,
    "pmra": 15.54,
    "pmdec": -78.48,
    "rv": -44.4,
    "Asset Name": "ASTRID 002065",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Good Boy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00000382,
    "y_1": -0.00003392,
    "z_1": -0.00003102,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118390,
    "Henry Draper Catalogue_1": 38114,
    "Gliese Catalog_1": "NN 3363"
  },
  {
    "Astrid #": "ASTRID 002066",
    "HYG Star ID": 118452,
    "Henry Draper Catalogue": 51534,
    "Gliese Catalog": "NN 3418",
    "Distance in Parsecs": 20.8333,
    "mag": 8.28,
    "Absolute Visual Magnitude": 6.686,
    "Spectral Type": "G5",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.073679,
    "y": 19.54707,
    "z": 5.118224,
    "vx": 0,
    "vy": 0,
    "vz": 0,
    "rarad": 1.824754168,
    "decrad": 0.2482161,
    "pmrarad": 0,
    "pmdecrad": 0,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 118452,
    "lum": 0.184331687950575,
    "ra": 6.970047,
    "dec": 14.221735,
    "pmra": 0,
    "pmdec": 0,
    "rv": 0,
    "Asset Name": "ASTRID 002066",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Braided Ponytail",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0,
    "y_1": 0,
    "z_1": 0,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118452,
    "Henry Draper Catalogue_1": 51534,
    "Gliese Catalog_1": "NN 3418"
  },
  {
    "Astrid #": "ASTRID 002067",
    "HYG Star ID": 118491,
    "Gliese Catalog": "NN 3456",
    "Distance in Parsecs": 20.8333,
    "mag": 12.8,
    "Absolute Visual Magnitude": 11.206,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.838792,
    "y": 1.617385,
    "z": 20.753479,
    "vx": -0.00000218,
    "vy": 0.00003718,
    "vz": -0.00000298,
    "rarad": 2.0496014881946,
    "decrad": 1.48323066582155,
    "pmrarad": -7.29596107868055e-7,
    "pmdecrad": -0.000001638723569,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118491,
    "lum": 0.00286813771266846,
    "ra": 7.828901,
    "dec": 84.982857,
    "pmra": -150.49,
    "pmdec": -338.01,
    "rv": 0,
    "Asset Name": "ASTRID 002067",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Bucket Hat",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000218,
    "y_1": 0.00003718,
    "z_1": -0.00000298,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118491,
    "Gliese Catalog_1": "NN 3456"
  },
  {
    "Astrid #": "ASTRID 002068",
    "HYG Star ID": 118497,
    "Gliese Catalog": "NN 3461",
    "Distance in Parsecs": 20.8333,
    "mag": 12.71,
    "Absolute Visual Magnitude": 11.116,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.925039,
    "y": 18.735465,
    "z": 1.83097,
    "vx": 0.0000241,
    "vy": 0.00001229,
    "vz": -0.00000824,
    "rarad": 2.01536322114444,
    "decrad": 0.0880002486064499,
    "pmrarad": -0.00000129813711105555,
    "pmdecrad": -3.96887871e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118497,
    "lum": 0.00311601830310546,
    "ra": 7.69812,
    "dec": 5.042043,
    "pmra": -267.76,
    "pmdec": -81.86,
    "rv": 0,
    "Asset Name": "ASTRID 002068",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Audiophile",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000241,
    "y_1": 0.00001229,
    "z_1": -0.00000824,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118497,
    "Gliese Catalog_1": "NN 3461"
  },
  {
    "Astrid #": "ASTRID 002069",
    "HYG Star ID": 118513,
    "Gliese Catalog": "NN 3468A",
    "Distance in Parsecs": 20.8333,
    "mag": 14.24,
    "Absolute Visual Magnitude": 12.646,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.924517,
    "y": 17.450243,
    "z": 5.569503,
    "vx": 0.00000479,
    "vy": 0.00000542,
    "vz": -0.00000844,
    "rarad": 2.08790810820877,
    "decrad": 0.2706279679327,
    "pmrarad": -3.28315824472222e-7,
    "pmdecrad": -4.20241346e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118513,
    "base": "NN 3468",
    "lum": 0.000761377432456149,
    "ra": 7.975222,
    "dec": 15.50584,
    "pmra": -67.72,
    "pmdec": -86.68,
    "rv": 0,
    "Asset Name": "ASTRID 002069",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Greasy",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000479,
    "y_1": 0.00000542,
    "z_1": -0.00000844,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118513,
    "Gliese Catalog_1": "NN 3468A"
  },
  {
    "Astrid #": "ASTRID 002070",
    "HYG Star ID": 118514,
    "Gliese Catalog": "NN 3469B",
    "Distance in Parsecs": 20.8333,
    "mag": 15.09,
    "Absolute Visual Magnitude": 13.496,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.923402,
    "y": 17.451246,
    "z": 5.568342,
    "vx": 0.00000479,
    "vy": 0.00000541,
    "vz": -0.00000844,
    "rarad": 2.08783516620877,
    "decrad": 0.2705700999327,
    "pmrarad": -3.28315824472222e-7,
    "pmdecrad": -4.20241346e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118514,
    "base": "NN 3469",
    "lum": 0.000348016632215003,
    "ra": 7.974943,
    "dec": 15.502525,
    "pmra": -67.72,
    "pmdec": -86.68,
    "rv": 0,
    "Asset Name": "ASTRID 002070",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Angelic",
    "Outfit": "Sweater Friend",
    "Special": "Neko Tail",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000479,
    "y_1": 0.00000541,
    "z_1": -0.00000844,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118514,
    "Gliese Catalog_1": "NN 3469B"
  },
  {
    "Astrid #": "ASTRID 002071",
    "HYG Star ID": 118547,
    "Gliese Catalog": "NN 3505",
    "Distance in Parsecs": 20.8333,
    "mag": 14.75,
    "Absolute Visual Magnitude": 13.156,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.257639,
    "y": 15.490063,
    "z": 6.620771,
    "vx": -0.00000601,
    "vy": 0.00002042,
    "vz": -0.00005889,
    "rarad": 2.24022482718912,
    "decrad": 0.32340571409585,
    "pmrarad": -3.81936217541666e-7,
    "pmdecrad": -0.000002981478083,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118547,
    "lum": 0.000475992379667249,
    "ra": 8.557029,
    "dec": 18.529782,
    "pmra": -78.78,
    "pmdec": -614.97,
    "rv": 0,
    "Asset Name": "ASTRID 002071",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Hurt",
    "Hair": "Undercut",
    "Outfit": "Tats",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000601,
    "y_1": 0.00002042,
    "z_1": -0.00005889,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118547,
    "Gliese Catalog_1": "NN 3505"
  },
  {
    "Astrid #": "ASTRID 002072",
    "HYG Star ID": 118689,
    "Gliese Catalog": "NN 3607",
    "Distance in Parsecs": 20.8333,
    "mag": 12.72,
    "Absolute Visual Magnitude": 11.126,
    "Spectral Type": "M6  e:",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.183381,
    "y": 6.670193,
    "z": 11.297482,
    "vx": -0.00000206,
    "vy": 0.00004646,
    "vz": -0.00003037,
    "rarad": 2.7506642279035,
    "decrad": 0.573148348531699,
    "pmrarad": -0.00000202458193,
    "pmdecrad": -0.000001735269366,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118689,
    "lum": 0.00308745047558744,
    "ra": 10.506763,
    "dec": 32.838981,
    "pmra": -417.6,
    "pmdec": -357.93,
    "rv": 0,
    "Asset Name": "ASTRID 002072",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Spiky",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000206,
    "y_1": 0.00004646,
    "z_1": -0.00003037,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118689,
    "Gliese Catalog_1": "NN 3607"
  },
  {
    "Astrid #": "ASTRID 002073",
    "HYG Star ID": 118900,
    "Gliese Catalog": "GJ 1173",
    "Distance in Parsecs": 20.8333,
    "mag": 10.28,
    "Absolute Visual Magnitude": 8.686,
    "Spectral Type": "K7 V",
    "Color Index": 1.44,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.053763,
    "y": -8.425069,
    "z": -0.141595,
    "vx": -0.00000721,
    "vy": -0.00000937,
    "vz": 0.00002246,
    "rarad": 3.55791909505526,
    "decrad": -0.00679646530615,
    "pmrarad": 2.71641105215277e-7,
    "pmdecrad": 0.000001081473877,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 118900,
    "lum": 0.0292146037387733,
    "ra": 13.59025,
    "dec": -0.389409,
    "pmra": 56.03,
    "pmdec": 223.07,
    "rv": 10,
    "Asset Name": "ASTRID 002073",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "Mohawk",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000721,
    "y_1": -0.00000937,
    "z_1": 0.00002246,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118900,
    "Gliese Catalog_1": "GJ 1173"
  },
  {
    "Astrid #": "ASTRID 002074",
    "HYG Star ID": 118927,
    "Gliese Catalog": "NN 3810",
    "Distance in Parsecs": 20.8333,
    "mag": 12.72,
    "Absolute Visual Magnitude": 11.126,
    "Spectral Type": "M2.5",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.142436,
    "y": -8.992804,
    "z": -7.700179,
    "vx": 0.0000113,
    "vy": 0.00000814,
    "vz": -0.00003468,
    "rarad": 3.62472102800357,
    "decrad": -0.3785883993882,
    "pmrarad": -9.38599285555555e-8,
    "pmdecrad": -0.000001791347764,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118927,
    "lum": 0.00308745047558744,
    "ra": 13.845414,
    "dec": -21.691517,
    "pmra": -19.36,
    "pmdec": -369.49,
    "rv": 0,
    "Asset Name": "ASTRID 002074",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Braid",
    "Outfit": "Emperor",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000113,
    "y_1": 0.00000814,
    "z_1": -0.00003468,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118927,
    "Gliese Catalog_1": "NN 3810"
  },
  {
    "Astrid #": "ASTRID 002075",
    "HYG Star ID": 118931,
    "Gliese Catalog": "NN 3814",
    "Distance in Parsecs": 20.8333,
    "mag": 14.6,
    "Absolute Visual Magnitude": 13.006,
    "Spectral Type": "DA6",
    "Color Index": 0.21,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -18.100697,
    "y": -9.752452,
    "z": -3.3587,
    "vx": 0.00001998,
    "vy": -0.00002637,
    "vz": -0.00003116,
    "rarad": 3.63578658309419,
    "decrad": -0.1619245364086,
    "pmrarad": 0.00000156934188395833,
    "pmdecrad": -0.000001515508172,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118931,
    "lum": 0.000546512374519871,
    "ra": 13.887682,
    "dec": -9.277593,
    "pmra": 323.7,
    "pmdec": -312.6,
    "rv": 0,
    "Asset Name": "ASTRID 002075",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Unkempt",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001998,
    "y_1": -0.00002637,
    "z_1": -0.00003116,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118931,
    "Gliese Catalog_1": "NN 3814"
  },
  {
    "Astrid #": "ASTRID 002076",
    "HYG Star ID": 118937,
    "Gliese Catalog": "NN 3819",
    "Distance in Parsecs": 20.8333,
    "mag": 14.93,
    "Absolute Visual Magnitude": 13.336,
    "Spectral Type": "f-g",
    "Color Index": 0.32,
    "Spectral Abbreviation": "f",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.574718,
    "y": -9.485112,
    "z": -8.32693,
    "vx": -0.00001498,
    "vy": 0.0000305,
    "vz": -0.00000492,
    "rarad": 3.66137558935896,
    "decrad": -0.411182197892599,
    "pmrarad": -0.00000162805282067361,
    "pmdecrad": -2.57857852e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118937,
    "lum": 0.000403273792976112,
    "ra": 13.985425,
    "dec": -23.559005,
    "pmra": -335.81,
    "pmdec": -53.19,
    "rv": 0,
    "Asset Name": "ASTRID 002076",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Pixie",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001498,
    "y_1": 0.0000305,
    "z_1": -0.00000492,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 118937,
    "Gliese Catalog_1": "NN 3819"
  },
  {
    "Astrid #": "ASTRID 002077",
    "HYG Star ID": 119109,
    "Gliese Catalog": "NN 3975",
    "Distance in Parsecs": 20.8333,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.806,
    "Spectral Type": "k",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.165152,
    "y": -19.822207,
    "z": -1.759948,
    "vx": -0.00006817,
    "vy": 0.00002352,
    "vz": -0.00002628,
    "rarad": 4.41085137081402,
    "decrad": -0.08457847006095,
    "pmrarad": -0.00000346016371949305,
    "pmdecrad": -0.000001266241219,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119109,
    "lum": 0.00165044098565227,
    "ra": 16.848211,
    "dec": -4.845989,
    "pmra": -713.71,
    "pmdec": -261.18,
    "rv": 0,
    "Asset Name": "ASTRID 002077",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Braided Ponytail",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006817,
    "y_1": 0.00002352,
    "z_1": -0.00002628,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119109,
    "Gliese Catalog_1": "NN 3975"
  },
  {
    "Astrid #": "ASTRID 002078",
    "HYG Star ID": 119139,
    "Gliese Catalog": "NN 3999A",
    "Distance in Parsecs": 20.8333,
    "mag": 12.83,
    "Absolute Visual Magnitude": 11.236,
    "Spectral Type": "M3",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.738733,
    "y": -20.044735,
    "z": -4.272806,
    "vx": 0.0000011,
    "vy": 0.0000059,
    "vz": -0.00002867,
    "rarad": 4.527988444,
    "decrad": -0.206560802983649,
    "pmrarad": 0,
    "pmdecrad": -0.000001405959673,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119139,
    "base": "NN 3999",
    "lum": 0.00278997299740826,
    "ra": 17.295642,
    "dec": -11.835062,
    "pmra": 0,
    "pmdec": -290,
    "rv": 0,
    "Asset Name": "ASTRID 002078",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Cute Neko",
    "Hair": "Middle Part",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000011,
    "y_1": 0.0000059,
    "z_1": -0.00002867,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119139,
    "Gliese Catalog_1": "NN 3999A"
  },
  {
    "Astrid #": "ASTRID 002079",
    "HYG Star ID": 119140,
    "Gliese Catalog": "NN 4000B",
    "Distance in Parsecs": 20.8333,
    "mag": 12.91,
    "Absolute Visual Magnitude": 11.316,
    "Spectral Type": "M3",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.740319,
    "y": -20.045069,
    "z": -4.269847,
    "vx": 0.0000011,
    "vy": 0.0000059,
    "vz": -0.00002867,
    "rarad": 4.527914984,
    "decrad": -0.20641570598365,
    "pmrarad": 0,
    "pmdecrad": -0.000001405959673,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119140,
    "base": "NN 4000",
    "lum": 0.00259179113461594,
    "ra": 17.295361,
    "dec": -11.826749,
    "pmra": 0,
    "pmdec": -290,
    "rv": 0,
    "Asset Name": "ASTRID 002079",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Unkempt",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000011,
    "y_1": 0.0000059,
    "z_1": -0.00002867,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119140,
    "Gliese Catalog_1": "NN 4000B"
  },
  {
    "Astrid #": "ASTRID 002080",
    "HYG Star ID": 119161,
    "Gliese Catalog": "NN 4012",
    "Distance in Parsecs": 20.8333,
    "mag": 15.8,
    "Absolute Visual Magnitude": 14.206,
    "Spectral Type": "DA8",
    "Color Index": 0.46,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.198321,
    "y": -12.056773,
    "z": -16.947703,
    "vx": -0.00000647,
    "vy": 0.00003604,
    "vz": -0.00002518,
    "rarad": 4.61334168257094,
    "decrad": -0.950129888145349,
    "pmrarad": -4.79868581013888e-7,
    "pmdecrad": -0.000002078502907,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119161,
    "lum": 0.000180967255478879,
    "ra": 17.621667,
    "dec": -54.438433,
    "pmra": -98.98,
    "pmdec": -428.72,
    "rv": 0,
    "Asset Name": "ASTRID 002080",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Braided Bun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000647,
    "y_1": 0.00003604,
    "z_1": -0.00002518,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119161,
    "Gliese Catalog_1": "NN 4012"
  },
  {
    "Astrid #": "ASTRID 002081",
    "HYG Star ID": 119168,
    "Gliese Catalog": "NN 4016",
    "Distance in Parsecs": 20.8333,
    "mag": 12.28,
    "Absolute Visual Magnitude": 10.686,
    "Spectral Type": "M4",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.901132,
    "y": -20.541582,
    "z": 2.907836,
    "vx": -0.00000894,
    "vy": -0.00000235,
    "vz": -0.00002241,
    "rarad": 4.62010165258766,
    "decrad": 0.140033587565799,
    "pmrarad": -4.16988246645833e-7,
    "pmdecrad": -0.000001086268684,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119168,
    "lum": 0.00463020265860308,
    "ra": 17.647488,
    "dec": 8.023334,
    "pmra": -86.01,
    "pmdec": -224.06,
    "rv": 0,
    "Asset Name": "ASTRID 002081",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Maniacal",
    "Hair": "Tiny Crown",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000894,
    "y_1": -0.00000235,
    "z_1": -0.00002241,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119168,
    "Gliese Catalog_1": "NN 4016"
  },
  {
    "Astrid #": "ASTRID 002082",
    "HYG Star ID": 119205,
    "Gliese Catalog": "NN 4046",
    "Distance in Parsecs": 20.8333,
    "mag": 10.84,
    "Absolute Visual Magnitude": 9.246,
    "Spectral Type": "M0",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.351199,
    "y": -19.659531,
    "z": 6.760436,
    "vx": -0.00000365,
    "vy": -0.00001463,
    "vz": -0.0000418,
    "rarad": 4.78101168170941,
    "decrad": 0.3304845714332,
    "pmrarad": -2.229658116875e-7,
    "pmdecrad": -0.000002121491336,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 119205,
    "lum": 0.0174421493156117,
    "ra": 18.262119,
    "dec": 18.935371,
    "pmra": -45.99,
    "pmdec": -437.59,
    "rv": 0,
    "Asset Name": "ASTRID 002082",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Bad Dreams",
    "Hair": "Side Part",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000365,
    "y_1": -0.00001463,
    "z_1": -0.0000418,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119205,
    "Gliese Catalog_1": "NN 4046"
  },
  {
    "Astrid #": "ASTRID 002083",
    "HYG Star ID": 119251,
    "Gliese Catalog": "NN 4088",
    "Distance in Parsecs": 20.8333,
    "mag": 11.2,
    "Absolute Visual Magnitude": 9.606,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.09751,
    "y": -20.012607,
    "z": 2.745425,
    "vx": -0.00001248,
    "vy": -0.00000487,
    "vz": -0.00001229,
    "rarad": 4.96180057156354,
    "decrad": 0.1321650423787,
    "pmrarad": -6.38208729083333e-7,
    "pmdecrad": -5.95152426e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 119251,
    "lum": 0.0125198752062488,
    "ra": 18.952682,
    "dec": 7.572499,
    "pmra": -131.64,
    "pmdec": -122.76,
    "rv": 0,
    "Asset Name": "ASTRID 002083",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Mohawk",
    "Outfit": "Astro",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001248,
    "y_1": -0.00000487,
    "z_1": -0.00001229,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119251,
    "Gliese Catalog_1": "NN 4088"
  },
  {
    "Astrid #": "ASTRID 002084",
    "HYG Star ID": 119291,
    "Gliese Catalog": "NN 4115B",
    "Distance in Parsecs": 20.8333,
    "mag": 12.52,
    "Absolute Visual Magnitude": 10.926,
    "Color Index": 1.52,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.306838,
    "y": -18.858179,
    "z": 3.06462,
    "vx": -0.00000287,
    "vy": -0.00000187,
    "vz": -0.00000371,
    "rarad": 5.12730634306712,
    "decrad": 0.147637701889049,
    "pmrarad": -1.62218657513888e-7,
    "pmdecrad": -1.80142219e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119291,
    "base": "NN 4115",
    "lum": 0.00371193190044139,
    "ra": 19.584868,
    "dec": 8.459017,
    "pmra": -33.46,
    "pmdec": -37.16,
    "rv": 0,
    "Asset Name": "ASTRID 002084",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Afro",
    "Outfit": "Wizard",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000287,
    "y_1": -0.00000187,
    "z_1": -0.00000371,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119291,
    "Gliese Catalog_1": "NN 4115B"
  },
  {
    "Astrid #": "ASTRID 002085",
    "HYG Star ID": 119369,
    "Gliese Catalog": "NN 4162",
    "Distance in Parsecs": 20.8333,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.806,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.005058,
    "y": -13.598716,
    "z": -10.24597,
    "vx": -0.00003916,
    "vy": -0.00000638,
    "vz": -0.00003742,
    "rarad": 5.43563623042188,
    "decrad": -0.514164285170699,
    "pmrarad": -0.00000161181156237499,
    "pmdecrad": -0.000002063003414,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119369,
    "lum": 0.00165044098565227,
    "ra": 20.762601,
    "dec": -29.459444,
    "pmra": -332.46,
    "pmdec": -425.52,
    "rv": 0,
    "Asset Name": "ASTRID 002085",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Pirate Hat",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003916,
    "y_1": -0.00000638,
    "z_1": -0.00003742,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119369,
    "Gliese Catalog_1": "NN 4162"
  },
  {
    "Astrid #": "ASTRID 002086",
    "HYG Star ID": 119373,
    "Gliese Catalog": "NN 4164",
    "Distance in Parsecs": 20.8333,
    "mag": 13.06,
    "Absolute Visual Magnitude": 11.466,
    "Spectral Type": "M3.5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.051255,
    "y": -15.380899,
    "z": -0.128804,
    "vx": 0.00002413,
    "vy": 0.00002222,
    "vz": -0.0000218,
    "rarad": 5.45264125443799,
    "decrad": -0.00618263572905,
    "pmrarad": 0.00000157486875991666,
    "pmdecrad": -0.000001046334581,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119373,
    "lum": 0.00225735571102144,
    "ra": 20.827555,
    "dec": -0.354239,
    "pmra": 324.84,
    "pmdec": -215.82,
    "rv": 0,
    "Asset Name": "ASTRID 002086",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Innocent",
    "Outfit": "Toga",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002413,
    "y_1": 0.00002222,
    "z_1": -0.0000218,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119373,
    "Gliese Catalog_1": "NN 4164"
  },
  {
    "Astrid #": "ASTRID 002087",
    "HYG Star ID": 119473,
    "Gliese Catalog": "NN 4258A",
    "Distance in Parsecs": 20.8333,
    "mag": 11.62,
    "Absolute Visual Magnitude": 10.026,
    "Spectral Type": "M1.5",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.545899,
    "y": -4.107286,
    "z": 18.979357,
    "vx": -0.00003424,
    "vy": -0.00001671,
    "vz": 0.00001,
    "rarad": 5.78482958452984,
    "decrad": 1.14572811157915,
    "pmrarad": -0.00000148992940308333,
    "pmdecrad": 0.000001164071583,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 119473,
    "base": "NN 4258",
    "lum": 0.00850354471999801,
    "ra": 22.096421,
    "dec": 65.645385,
    "pmra": -307.32,
    "pmdec": 240.11,
    "rv": 0,
    "Asset Name": "ASTRID 002087",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "Angelic",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00003424,
    "y_1": -0.00001671,
    "z_1": 0.00001,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119473,
    "Gliese Catalog_1": "NN 4258A"
  },
  {
    "Astrid #": "ASTRID 002088",
    "HYG Star ID": 119481,
    "Gliese Catalog": "NN 4264",
    "Distance in Parsecs": 20.8333,
    "mag": 13.62,
    "Absolute Visual Magnitude": 12.026,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.74741,
    "y": -8.387063,
    "z": 9.121833,
    "vx": 0.00001556,
    "vy": 0.00001261,
    "vz": -0.00001698,
    "rarad": 5.81889490809942,
    "decrad": 0.4532043631335,
    "pmrarad": 8.75621988451389e-7,
    "pmdecrad": -9.0673733e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119481,
    "lum": 0.00134772101385135,
    "ra": 22.226541,
    "dec": 25.966697,
    "pmra": 180.61,
    "pmdec": -187.03,
    "rv": 0,
    "Asset Name": "ASTRID 002088",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Slick",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001556,
    "y_1": 0.00001261,
    "z_1": -0.00001698,
    "Distance in Parsecs_1": 20.8333,
    "HYG Star ID_1": 119481,
    "Gliese Catalog_1": "NN 4264"
  },
  {
    "Astrid #": "ASTRID 002089",
    "HYG Star ID": 33981,
    "Hipparcos Catalogue": 34069,
    "Henry Draper Catalogue": 53706,
    "Harvard Revised Catalogue": 2668,
    "Gliese Catalog": "Gl 264.1B",
    "Distance in Parsecs": 20.8377,
    "mag": 6.83,
    "Absolute Visual Magnitude": 5.236,
    "Spectral Type": "K0V",
    "Color Index": 0.779,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.15763,
    "y": 14.503672,
    "z": -14.373624,
    "vx": -0.00001606,
    "vy": 0.00009377,
    "vz": -0.00003478,
    "rarad": 1.84997221376828,
    "decrad": -0.761161115692226,
    "pmrarad": -4.99455053708333e-7,
    "pmdecrad": 0.000001922092317,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 33977,
    "base": "Gl 264.1",
    "lum": 0.700809531646793,
    "ra": 7.066373,
    "dec": -43.611319,
    "pmra": -103.02,
    "pmdec": 396.46,
    "rv": 90.4,
    "Asset Name": "ASTRID 002089",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Spiky",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001606,
    "y_1": 0.00009377,
    "z_1": -0.00003478,
    "Distance in Parsecs_1": 20.8377,
    "HYG Star ID_1": 33981,
    "Hipparcos Catalogue_1": 34069,
    "Henry Draper Catalogue_1": 53706,
    "Harvard Revised Catalogue_1": 2668,
    "Gliese Catalog_1": "Gl 264.1B"
  },
  {
    "Astrid #": "ASTRID 002090",
    "HYG Star ID": 119303,
    "Henry Draper Catalogue": 225732,
    "Gliese Catalog": "Gl 767.1B",
    "Distance in Parsecs": 20.8594,
    "mag": 8.56,
    "Absolute Visual Magnitude": 6.963,
    "Spectral Type": "dK6",
    "Color Index": 1.04,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 7.772171,
    "y": -15.509804,
    "z": 11.582897,
    "vx": 0.00001444,
    "vy": -0.00002466,
    "vz": -0.00003441,
    "rarad": 5.17692605755806,
    "decrad": 0.58869741555935,
    "pmrarad": 8.93511613263888e-8,
    "pmdecrad": -0.000002131308813,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 96986,
    "base": "Gl 767.1",
    "lum": 0.142823608001918,
    "ra": 19.774401,
    "dec": 33.729877,
    "pmra": 18.43,
    "pmdec": -439.61,
    "rv": 4.5,
    "Asset Name": "ASTRID 002090",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Medium Bob",
    "Outfit": "Mecha",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001444,
    "y_1": -0.00002466,
    "z_1": -0.00003441,
    "Distance in Parsecs_1": 20.8594,
    "HYG Star ID_1": 119303,
    "Henry Draper Catalogue_1": 225732,
    "Gliese Catalog_1": "Gl 767.1B"
  },
  {
    "Astrid #": "ASTRID 002091",
    "HYG Star ID": 1799,
    "Hipparcos Catalogue": 1803,
    "Henry Draper Catalogue": 1835,
    "Harvard Revised Catalogue": 88,
    "Gliese Catalog": "Gl 17.3",
    "Distance in Parsecs": 20.8638,
    "mag": 6.39,
    "Absolute Visual Magnitude": 4.793,
    "Spectral Type": "G3V",
    "Color Index": 0.659,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.290476,
    "y": 2.030904,
    "z": -4.412393,
    "vx": -0.00000527,
    "vy": 0.00003955,
    "vz": 0.00000654,
    "rarad": 0.0997588694053603,
    "decrad": -0.213094894638646,
    "pmrarad": 0.00000191147489832638,
    "pmdecrad": 2.93069869e-7,
    "flam": 9,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 1799,
    "lum": 1.05390144506915,
    "var": "BE",
    "var_min": 6.408,
    "var_max": 6.368,
    "bf": "9    Cet",
    "ra": 0.381051,
    "dec": -12.209438,
    "pmra": 394.27,
    "pmdec": 60.45,
    "rv": -2.6,
    "Asset Name": "ASTRID 002091",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Manbun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000527,
    "y_1": 0.00003955,
    "z_1": 0.00000654,
    "Distance in Parsecs_1": 20.8638,
    "HYG Star ID_1": 1799,
    "Hipparcos Catalogue_1": 1803,
    "Henry Draper Catalogue_1": 1835,
    "Harvard Revised Catalogue_1": 88,
    "Gliese Catalog_1": "Gl 17.3"
  },
  {
    "Astrid #": "ASTRID 002092",
    "HYG Star ID": 22069,
    "Hipparcos Catalogue": 22122,
    "Henry Draper Catalogue": 30501,
    "Gliese Catalog": "Gl 176.3",
    "Distance in Parsecs": 20.8638,
    "mag": 7.58,
    "Absolute Visual Magnitude": 5.983,
    "Spectral Type": "K0V",
    "Color Index": 0.875,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.268602,
    "y": 12.691773,
    "z": -16.000076,
    "vx": 0.00003796,
    "vy": -0.00002859,
    "vz": -0.00003523,
    "rarad": 1.24636326720614,
    "decrad": -0.873960187093968,
    "pmrarad": -0.00000216134786928472,
    "pmdecrad": -0.000001635325025,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 22069,
    "lum": 0.352208635618467,
    "ra": 4.760757,
    "dec": -50.07423,
    "pmra": -445.81,
    "pmdec": -337.31,
    "rv": 17,
    "Asset Name": "ASTRID 002092",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Honest Lad",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00003796,
    "y_1": -0.00002859,
    "z_1": -0.00003523,
    "Distance in Parsecs_1": 20.8638,
    "HYG Star ID_1": 22069,
    "Hipparcos Catalogue_1": 22122,
    "Henry Draper Catalogue_1": 30501,
    "Gliese Catalog_1": "Gl 176.3"
  },
  {
    "Astrid #": "ASTRID 002093",
    "HYG Star ID": 118412,
    "Gliese Catalog": "Gl 226.1",
    "Distance in Parsecs": 20.8768,
    "mag": 13.67,
    "Absolute Visual Magnitude": 12.072,
    "Spectral Type": "M3.5",
    "Color Index": 1.65,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.319801,
    "y": 18.737502,
    "z": 9.200246,
    "vx": -0.00003338,
    "vy": 0.00002401,
    "vz": -0.00005004,
    "rarad": 1.58785310772449,
    "decrad": 0.4563698113743,
    "pmrarad": 0.00000157947448988194,
    "pmdecrad": -0.000002670712514,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118412,
    "lum": 0.00129181403900305,
    "ra": 6.065152,
    "dec": 26.148064,
    "pmra": 325.79,
    "pmdec": -550.87,
    "rv": 0,
    "Asset Name": "ASTRID 002093",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Determined Shinobi",
    "Hair": "Shinobi",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003338,
    "y_1": 0.00002401,
    "z_1": -0.00005004,
    "Distance in Parsecs_1": 20.8768,
    "HYG Star ID_1": 118412,
    "Gliese Catalog_1": "Gl 226.1"
  },
  {
    "Astrid #": "ASTRID 002094",
    "HYG Star ID": 119273,
    "Gliese Catalog": "Wo 9652B",
    "Distance in Parsecs": 20.8768,
    "mag": 13.27,
    "Absolute Visual Magnitude": 11.672,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.305449,
    "y": -18.666863,
    "z": 6.901474,
    "vx": -0.00006079,
    "vy": -0.00000586,
    "vz": 0.00003974,
    "rarad": 5.03815216989251,
    "decrad": 0.3369191470627,
    "pmrarad": -0.00000284886214968055,
    "pmdecrad": 0.000002017101254,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 94262,
    "base": "Wo 9652",
    "lum": 0.00186723938838294,
    "ra": 19.244324,
    "dec": 19.304045,
    "pmra": -587.62,
    "pmdec": 416.06,
    "rv": 0,
    "Asset Name": "ASTRID 002094",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Ponytail",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006079,
    "y_1": -0.00000586,
    "z_1": 0.00003974,
    "Distance in Parsecs_1": 20.8768,
    "HYG Star ID_1": 119273,
    "Gliese Catalog_1": "Wo 9652B"
  },
  {
    "Astrid #": "ASTRID 002095",
    "HYG Star ID": 69793,
    "Hipparcos Catalogue": 70016,
    "Henry Draper Catalogue": 125455,
    "Gliese Catalog": "Gl 544A",
    "Distance in Parsecs": 20.8812,
    "mag": 7.58,
    "Absolute Visual Magnitude": 5.981,
    "Spectral Type": "K1V",
    "Color Index": 0.867,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.057505,
    "y": -11.897385,
    "z": -1.874795,
    "vx": -0.00002807,
    "vy": 0.00005839,
    "vz": -0.00001148,
    "rarad": 3.75063006183297,
    "decrad": -0.0899053130523687,
    "pmrarad": -0.00000306276194554166,
    "pmdecrad": -5.92005985e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 69793,
    "base": "Gl 544",
    "lum": 0.352858025828526,
    "ra": 14.326352,
    "dec": -5.151195,
    "pmra": -631.74,
    "pmdec": -122.11,
    "rv": -9.1,
    "Asset Name": "ASTRID 002095",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Hachimaki",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002807,
    "y_1": 0.00005839,
    "z_1": -0.00001148,
    "Distance in Parsecs_1": 20.8812,
    "HYG Star ID_1": 69793,
    "Hipparcos Catalogue_1": 70016,
    "Henry Draper Catalogue_1": 125455,
    "Gliese Catalog_1": "Gl 544A"
  },
  {
    "Astrid #": "ASTRID 002096",
    "HYG Star ID": 25047,
    "Hipparcos Catalogue": 25110,
    "Henry Draper Catalogue": 33564,
    "Harvard Revised Catalogue": 1686,
    "Gliese Catalog": "Gl 196",
    "Distance in Parsecs": 20.8855,
    "mag": 5.08,
    "Absolute Visual Magnitude": 3.481,
    "Spectral Type": "F6V",
    "Color Index": 0.506,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.634691,
    "y": 3.850421,
    "z": 20.517591,
    "vx": 0.0000049100000000000004,
    "vy": -0.00001912,
    "vz": -0.0000077,
    "rarad": 1.40744306369391,
    "decrad": 1.38284441232747,
    "pmrarad": -3.81063552916666e-7,
    "pmdecrad": 7.81180283e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 25047,
    "lum": 3.52858025828526,
    "ra": 5.376036,
    "dec": 79.231149,
    "pmra": -78.6,
    "pmdec": 161.13,
    "rv": -10.7,
    "Asset Name": "ASTRID 002096",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Good Boy",
    "Outfit": "Astro",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.0000049100000000000004,
    "y_1": -0.00001912,
    "z_1": -0.0000077,
    "Distance in Parsecs_1": 20.8855,
    "HYG Star ID_1": 25047,
    "Hipparcos Catalogue_1": 25110,
    "Henry Draper Catalogue_1": 33564,
    "Harvard Revised Catalogue_1": 1686,
    "Gliese Catalog_1": "Gl 196"
  },
  {
    "Astrid #": "ASTRID 002097",
    "HYG Star ID": 63164,
    "Hipparcos Catalogue": 63366,
    "Henry Draper Catalogue": 112758,
    "Gliese Catalog": "Gl 491A",
    "Distance in Parsecs": 20.8899,
    "mag": 7.54,
    "Absolute Visual Magnitude": 5.94,
    "Spectral Type": "K0V",
    "Color Index": 0.769,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.904135,
    "y": -5.24274,
    "z": -3.567917,
    "vx": -0.00003242,
    "vy": 0.00007808,
    "vz": 0.0000182,
    "rarad": 3.3991428540037,
    "decrad": -0.171637158052696,
    "pmrarad": -0.00000400994243324305,
    "pmdecrad": 9.52222549e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 63164,
    "base": "Gl 491",
    "lum": 0.366437574647833,
    "ra": 12.983769,
    "dec": -9.834085,
    "pmra": -827.11,
    "pmdec": 196.41,
    "rv": 8,
    "Asset Name": "ASTRID 002097",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Guilty Grin",
    "Hair": "Ninja",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00003242,
    "y_1": 0.00007808,
    "z_1": 0.0000182,
    "Distance in Parsecs_1": 20.8899,
    "HYG Star ID_1": 63164,
    "Hipparcos Catalogue_1": 63366,
    "Henry Draper Catalogue_1": 112758,
    "Gliese Catalog_1": "Gl 491A"
  },
  {
    "Astrid #": "ASTRID 002098",
    "HYG Star ID": 43945,
    "Hipparcos Catalogue": 44072,
    "Gliese Catalog": "Gl 330.1",
    "Distance in Parsecs": 20.8986,
    "mag": 9.22,
    "Absolute Visual Magnitude": 7.619,
    "Spectral Type": "M0",
    "Color Index": 1.116,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.754609,
    "y": 13.919271,
    "z": 7.334652,
    "vx": -0.00001757,
    "vy": -0.00007701,
    "vz": -0.00003483,
    "rarad": 2.35024257114866,
    "decrad": 0.35860832056316,
    "pmrarad": 0.00000318847413290972,
    "pmdecrad": -8.48278496e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 43945,
    "lum": 0.0780548691466237,
    "ra": 8.977265,
    "dec": 20.546743,
    "pmra": 657.67,
    "pmdec": -174.97,
    "rv": -50.8,
    "Asset Name": "ASTRID 002098",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Side Part",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00001757,
    "y_1": -0.00007701,
    "z_1": -0.00003483,
    "Distance in Parsecs_1": 20.8986,
    "HYG Star ID_1": 43945,
    "Hipparcos Catalogue_1": 44072,
    "Gliese Catalog_1": "Gl 330.1"
  },
  {
    "Astrid #": "ASTRID 002099",
    "HYG Star ID": 10504,
    "Hipparcos Catalogue": 10529,
    "Distance in Parsecs": 20.9205,
    "mag": 9.91,
    "Absolute Visual Magnitude": 8.307,
    "Spectral Type": "F2",
    "Color Index": 0.461,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.593709,
    "y": 4.434475,
    "z": 19.352668,
    "vx": -0.00000439,
    "vy": 0.00006,
    "vz": -0.00001225,
    "rarad": 0.592016044855447,
    "decrad": 1.18118727251407,
    "pmrarad": 0.00000249746919401388,
    "pmdecrad": -0.000001541998392,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 10504,
    "lum": 0.04141903726221,
    "ra": 2.261335,
    "dec": 67.677046,
    "pmra": 515.14,
    "pmdec": -318.06,
    "rv": 0,
    "Asset Name": "ASTRID 002099",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Mischievous",
    "Hair": "Greasy",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000439,
    "y_1": 0.00006,
    "z_1": -0.00001225,
    "Distance in Parsecs_1": 20.9205,
    "HYG Star ID_1": 10504,
    "Hipparcos Catalogue_1": 10529
  },
  {
    "Astrid #": "ASTRID 002100",
    "HYG Star ID": 117996,
    "Gliese Catalog": "Wo 9015B",
    "Distance in Parsecs": 20.9205,
    "mag": 6,
    "Absolute Visual Magnitude": 4.397,
    "Spectral Type": "A",
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 9.390884,
    "y": 1.350914,
    "z": -18.645473,
    "vx": -0.00000418,
    "vy": 0.00000797,
    "vz": -0.00000153,
    "rarad": 0.142849146059984,
    "decrad": -1.1001021435724,
    "pmrarad": 4.05401199680555e-7,
    "pmdecrad": -1.61331448e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 2,
    "comp_primary": 2572,
    "base": "Wo 9015",
    "lum": 1.51774915586124,
    "ra": 0.545644,
    "dec": -63.03121,
    "pmra": 83.62,
    "pmdec": -33.28,
    "rv": 0,
    "Asset Name": "ASTRID 002100",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Audiophile",
    "Outfit": "Viking",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000418,
    "y_1": 0.00000797,
    "z_1": -0.00000153,
    "Distance in Parsecs_1": 20.9205,
    "HYG Star ID_1": 117996,
    "Gliese Catalog_1": "Wo 9015B"
  },
  {
    "Astrid #": "ASTRID 002101",
    "HYG Star ID": 119398,
    "Gliese Catalog": "Gl 822.1B",
    "Distance in Parsecs": 20.9205,
    "mag": 6.42,
    "Absolute Visual Magnitude": 4.817,
    "Spectral Type": "G0 V",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.377178,
    "y": -10.87404,
    "z": 12.892945,
    "vx": -0.00000976,
    "vy": 0.00002982,
    "vz": 0.00003453,
    "rarad": 5.56233473386818,
    "decrad": 0.66401386629595,
    "pmrarad": 7.62757363618055e-7,
    "pmdecrad": 0.000002095645919,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 104549,
    "base": "Gl 822.1",
    "lum": 1.03086074006262,
    "ra": 21.246554,
    "dec": 38.045192,
    "pmra": 157.33,
    "pmdec": 432.26,
    "rv": 0,
    "Asset Name": "ASTRID 002101",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Side Part",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000976,
    "y_1": 0.00002982,
    "z_1": 0.00003453,
    "Distance in Parsecs_1": 20.9205,
    "HYG Star ID_1": 119398,
    "Gliese Catalog_1": "Gl 822.1B"
  },
  {
    "Astrid #": "ASTRID 002102",
    "HYG Star ID": 119399,
    "Gliese Catalog": "Gl 822.1C",
    "Distance in Parsecs": 20.9205,
    "mag": 12,
    "Absolute Visual Magnitude": 10.397,
    "Spectral Type": "M3",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.380631,
    "y": -10.878635,
    "z": 12.885753,
    "vx": -0.00000987,
    "vy": 0.00002971,
    "vz": 0.00003458,
    "rarad": 5.56226370483385,
    "decrad": 0.6635774308925,
    "pmrarad": 7.55436677041666e-7,
    "pmdecrad": 0.00000209829785,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 3,
    "comp_primary": 104549,
    "base": "Gl 822.1",
    "lum": 0.0060422682204988,
    "ra": 21.246282,
    "dec": 38.020186,
    "pmra": 155.82,
    "pmdec": 432.81,
    "rv": 0,
    "Asset Name": "ASTRID 002102",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Braid",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000987,
    "y_1": 0.00002971,
    "z_1": 0.00003458,
    "Distance in Parsecs_1": 20.9205,
    "HYG Star ID_1": 119399,
    "Gliese Catalog_1": "Gl 822.1C"
  },
  {
    "Astrid #": "ASTRID 002103",
    "HYG Star ID": 106353,
    "Hipparcos Catalogue": 106694,
    "Gliese Catalog": "Gl 834A",
    "Distance in Parsecs": 20.9293,
    "mag": 10.17,
    "Absolute Visual Magnitude": 8.566,
    "Spectral Type": "M0...",
    "Color Index": 1.433,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.100184,
    "y": -9.461811,
    "z": 13.300184,
    "vx": -0.00000409,
    "vy": -0.000023,
    "vz": -0.00001233,
    "rarad": 5.65767438423742,
    "decrad": 0.688632054782525,
    "pmrarad": -0.00000100511572252777,
    "pmdecrad": -7.6333914e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 106353,
    "base": "Gl 834",
    "lum": 0.0326287172143084,
    "var_min": 10.563,
    "var_max": 10.093,
    "ra": 21.610724,
    "dec": 39.45571,
    "pmra": -207.32,
    "pmdec": -157.45,
    "rv": 0,
    "Asset Name": "ASTRID 002103",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oh Snap",
    "Hair": "Halo Boy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000409,
    "y_1": -0.000023,
    "z_1": -0.00001233,
    "Distance in Parsecs_1": 20.9293,
    "HYG Star ID_1": 106353,
    "Hipparcos Catalogue_1": 106694,
    "Gliese Catalog_1": "Gl 834A"
  },
  {
    "Astrid #": "ASTRID 002104",
    "HYG Star ID": 62179,
    "Hipparcos Catalogue": 62374,
    "Gliese Catalog": "NN 3748",
    "Distance in Parsecs": 20.9336,
    "mag": 11.71,
    "Absolute Visual Magnitude": 10.106,
    "Spectral Type": "K:...",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.074867,
    "y": -2.928637,
    "z": 15.216334,
    "vx": -0.00003724,
    "vy": 0.00007118,
    "vz": -0.00002074,
    "rarad": 3.34675580395529,
    "decrad": 0.813776168027552,
    "pmrarad": -0.00000369166225195833,
    "pmdecrad": -0.000001442853994,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 62179,
    "lum": 0.00789950721335816,
    "var_min": 11.796,
    "var_max": 11.626,
    "ra": 12.783666,
    "dec": 46.62594,
    "pmra": -761.46,
    "pmdec": -297.61,
    "rv": 0,
    "Asset Name": "ASTRID 002104",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Bangs",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00003724,
    "y_1": 0.00007118,
    "z_1": -0.00002074,
    "Distance in Parsecs_1": 20.9336,
    "HYG Star ID_1": 62179,
    "Hipparcos Catalogue_1": 62374,
    "Gliese Catalog_1": "NN 3748"
  },
  {
    "Astrid #": "ASTRID 002105",
    "HYG Star ID": 113795,
    "Hipparcos Catalogue": 114156,
    "Henry Draper Catalogue": 218294,
    "Gliese Catalog": "Gl 889A",
    "Distance in Parsecs": 20.938,
    "mag": 9.62,
    "Absolute Visual Magnitude": 8.015,
    "Spectral Type": "K5V",
    "Color Index": 1.276,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.740635,
    "y": -4.402683,
    "z": -8.2348,
    "vx": 0.00001111,
    "vy": 0.00001346,
    "vz": -0.00003131,
    "rarad": 6.05244186080331,
    "decrad": -0.404208621712416,
    "pmrarad": 7.47291807208333e-7,
    "pmdecrad": -0.000001229681419,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113795,
    "base": "Gl 889",
    "lum": 0.0542000890401623,
    "ra": 23.118625,
    "dec": -23.159448,
    "pmra": 154.14,
    "pmdec": -253.64,
    "rv": 19,
    "Asset Name": "ASTRID 002105",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Ninja",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001111,
    "y_1": 0.00001346,
    "z_1": -0.00003131,
    "Distance in Parsecs_1": 20.938,
    "HYG Star ID_1": 113795,
    "Hipparcos Catalogue_1": 114156,
    "Henry Draper Catalogue_1": 218294,
    "Gliese Catalog_1": "Gl 889A"
  },
  {
    "Astrid #": "ASTRID 002106",
    "HYG Star ID": 44135,
    "Hipparcos Catalogue": 44263,
    "Gliese Catalog": "Gl 333.2A",
    "Distance in Parsecs": 20.9424,
    "mag": 12.37,
    "Absolute Visual Magnitude": 10.765,
    "Spectral Type": "M4",
    "Color Index": 1.455,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.80023,
    "y": 14.692285,
    "z": 1.913156,
    "vx": 0.00004317,
    "vy": -0.00000741,
    "vz": -0.00002447,
    "rarad": 2.35985460157264,
    "decrad": 0.09148221714789,
    "pmrarad": -0.00000120107741220833,
    "pmdecrad": -0.000001007297384,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 44135,
    "base": "Gl 333.2",
    "lum": 0.0043052661049171,
    "var_min": 12.706,
    "var_max": 12.196,
    "ra": 9.013981,
    "dec": 5.241545,
    "pmra": -247.74,
    "pmdec": -207.77,
    "rv": -37.1,
    "Asset Name": "ASTRID 002106",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Elegant",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004317,
    "y_1": -0.00000741,
    "z_1": -0.00002447,
    "Distance in Parsecs_1": 20.9424,
    "HYG Star ID_1": 44135,
    "Hipparcos Catalogue_1": 44263,
    "Gliese Catalog_1": "Gl 333.2A"
  },
  {
    "Astrid #": "ASTRID 002107",
    "HYG Star ID": 114054,
    "Hipparcos Catalogue": 114416,
    "Henry Draper Catalogue": 218572,
    "Gliese Catalog": "GJ 1280",
    "Distance in Parsecs": 20.9424,
    "mag": 8.7,
    "Absolute Visual Magnitude": 7.095,
    "Spectral Type": "K3V",
    "Color Index": 1.004,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.383266,
    "y": -1.625247,
    "z": -19.530235,
    "vx": 0.00003096,
    "vy": 1.8e-7,
    "vz": 0.00001169,
    "rarad": 6.06650997909813,
    "decrad": -1.20146540991078,
    "pmrarad": 3.26182644277777e-7,
    "pmdecrad": 0.000001546410196,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 114054,
    "lum": 0.126473634747115,
    "ra": 23.172361,
    "dec": -68.838897,
    "pmra": 67.28,
    "pmdec": 318.97,
    "rv": 0,
    "Asset Name": "ASTRID 002107",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Bandana",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00003096,
    "y_1": 1.8e-7,
    "z_1": 0.00001169,
    "Distance in Parsecs_1": 20.9424,
    "HYG Star ID_1": 114054,
    "Hipparcos Catalogue_1": 114416,
    "Henry Draper Catalogue_1": 218572,
    "Gliese Catalog_1": "GJ 1280"
  },
  {
    "Astrid #": "ASTRID 002108",
    "HYG Star ID": 101710,
    "Hipparcos Catalogue": 102040,
    "Henry Draper Catalogue": 197076,
    "Harvard Revised Catalogue": 7914,
    "Gliese Catalog": "Gl 797A",
    "Distance in Parsecs": 20.9468,
    "mag": 6.43,
    "Absolute Visual Magnitude": 4.824,
    "Spectral Type": "G5V",
    "Color Index": 0.611,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.706773,
    "y": -15.042779,
    "z": 7.141968,
    "vx": -0.00001995,
    "vy": 0.00004236,
    "vz": 0.00001732,
    "rarad": 5.4138031016372,
    "decrad": 0.347940746594565,
    "pmrarad": 5.77558537645833e-7,
    "pmdecrad": 0.000001513782236,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 101710,
    "base": "Gl 797",
    "lum": 1.02423591412156,
    "ra": 20.679205,
    "dec": 19.935536,
    "pmra": 119.13,
    "pmdec": 312.24,
    "rv": -35.8,
    "Asset Name": "ASTRID 002108",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Frizzy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": -0.00001995,
    "y_1": 0.00004236,
    "z_1": 0.00001732,
    "Distance in Parsecs_1": 20.9468,
    "HYG Star ID_1": 101710,
    "Hipparcos Catalogue_1": 102040,
    "Henry Draper Catalogue_1": 197076,
    "Harvard Revised Catalogue_1": 7914,
    "Gliese Catalog_1": "Gl 797A"
  },
  {
    "Astrid #": "ASTRID 002109",
    "HYG Star ID": 67594,
    "Hipparcos Catalogue": 67808,
    "Gliese Catalog": "Gl 533",
    "Distance in Parsecs": 20.9512,
    "mag": 9.78,
    "Absolute Visual Magnitude": 8.174,
    "Spectral Type": "M0",
    "Color Index": 1.369,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.967487,
    "y": -9.700739,
    "z": 4.692558,
    "vx": -0.00002559,
    "vy": 0.00000386,
    "vz": -0.00006259,
    "rarad": 3.63665268131473,
    "decrad": 0.225891266725621,
    "pmrarad": -7.42249744930555e-7,
    "pmdecrad": -0.000003133059929,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 67594,
    "lum": 0.0468166139724364,
    "ra": 13.89099,
    "dec": 12.942616,
    "pmra": -153.1,
    "pmdec": -646.24,
    "rv": 6,
    "Asset Name": "ASTRID 002109",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Sad",
    "Hair": "Slick",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00002559,
    "y_1": 0.00000386,
    "z_1": -0.00006259,
    "Distance in Parsecs_1": 20.9512,
    "HYG Star ID_1": 67594,
    "Hipparcos Catalogue_1": 67808,
    "Gliese Catalog_1": "Gl 533"
  },
  {
    "Astrid #": "ASTRID 002110",
    "HYG Star ID": 5884,
    "Hipparcos Catalogue": 5896,
    "Henry Draper Catalogue": 7788,
    "Harvard Revised Catalogue": 377,
    "Gliese Catalog": "Gl 55.3A",
    "Distance in Parsecs": 20.9556,
    "mag": 4.25,
    "Absolute Visual Magnitude": 2.643,
    "Spectral Type": "F6IV",
    "Color Index": 0.48,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.143194,
    "y": 2.45156,
    "z": -19.547464,
    "vx": -7.4e-7,
    "vy": 0.0000439,
    "vz": 8.5e-7,
    "rarad": 0.330575186778318,
    "decrad": -1.20211170626686,
    "pmrarad": 0.00000199311752213194,
    "pmdecrad": 6.17798073e-7,
    "bayer": "Kap",
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 5884,
    "base": "Gl  55.3",
    "lum": 7.63484105183096,
    "bf": "Kap Tuc",
    "ra": 1.262704,
    "dec": -68.875927,
    "pmra": 411.11,
    "pmdec": 127.43,
    "rv": 4,
    "Asset Name": "ASTRID 002110",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Elegant",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -7.4e-7,
    "y_1": 0.0000439,
    "z_1": 8.5e-7,
    "Distance in Parsecs_1": 20.9556,
    "HYG Star ID_1": 5884,
    "Hipparcos Catalogue_1": 5896,
    "Henry Draper Catalogue_1": 7788,
    "Harvard Revised Catalogue_1": 377,
    "Gliese Catalog_1": "Gl 55.3A"
  },
  {
    "Astrid #": "ASTRID 002111",
    "HYG Star ID": 48054,
    "Hipparcos Catalogue": 48190,
    "Distance in Parsecs": 20.9644,
    "mag": 10.3,
    "Absolute Visual Magnitude": 8.693,
    "Spectral Type": "M2V:",
    "Color Index": 1.453,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.041447,
    "y": 6.430234,
    "z": -17.243187,
    "vx": -0.00000126,
    "vy": -0.00002348,
    "vz": -0.00000802,
    "rarad": 2.57201111196275,
    "decrad": -0.965790000213718,
    "pmrarad": 9.75881457590277e-7,
    "pmdecrad": -6.72824425e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 48054,
    "lum": 0.0290268561049905,
    "var_min": 10.361,
    "var_max": 10.251,
    "ra": 9.824359,
    "dec": -55.335691,
    "pmra": 201.29,
    "pmdec": -138.78,
    "rv": 0,
    "Asset Name": "ASTRID 002111",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Grecian",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000126,
    "y_1": -0.00002348,
    "z_1": -0.00000802,
    "Distance in Parsecs_1": 20.9644,
    "HYG Star ID_1": 48054,
    "Hipparcos Catalogue_1": 48190
  },
  {
    "Astrid #": "ASTRID 002112",
    "HYG Star ID": 83937,
    "Hipparcos Catalogue": 84195,
    "Henry Draper Catalogue": 155712,
    "Distance in Parsecs": 20.9644,
    "mag": 7.95,
    "Absolute Visual Magnitude": 6.343,
    "Spectral Type": "K0",
    "Color Index": 0.941,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.083811,
    "y": -19.474668,
    "z": 6.600445,
    "vx": 0.00000935,
    "vy": -0.0000058,
    "vz": -0.00001134,
    "rarad": 4.50568522162943,
    "decrad": 0.320288740388291,
    "pmrarad": 4.93443364069444e-7,
    "pmdecrad": -5.69898481e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83937,
    "lum": 0.252813347983411,
    "ra": 17.21045,
    "dec": 18.351193,
    "pmra": 101.78,
    "pmdec": -117.55,
    "rv": 0,
    "Asset Name": "ASTRID 002112",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Lion Mane",
    "Outfit": "Tats",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000935,
    "y_1": -0.0000058,
    "z_1": -0.00001134,
    "Distance in Parsecs_1": 20.9644,
    "HYG Star ID_1": 83937,
    "Hipparcos Catalogue_1": 84195,
    "Henry Draper Catalogue_1": 155712
  },
  {
    "Astrid #": "ASTRID 002113",
    "HYG Star ID": 118173,
    "Gliese Catalog": "GJ 1052",
    "Distance in Parsecs": 20.9644,
    "mag": 15.53,
    "Absolute Visual Magnitude": 13.923,
    "Spectral Type": "DA7",
    "Color Index": 0.36,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 15.656323,
    "y": 13.913244,
    "z": -0.898476,
    "vx": -0.00001616,
    "vy": 0.00001517,
    "vz": -0.00004686,
    "rarad": 0.726517863645366,
    "decrad": -0.0428703533029,
    "pmrarad": 0.00000105277290732638,
    "pmdecrad": -0.000002237226058,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118173,
    "lum": 0.000234855102405888,
    "ra": 2.775094,
    "dec": -2.45629,
    "pmra": 217.15,
    "pmdec": -461.46,
    "rv": 0,
    "Asset Name": "ASTRID 002113",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Medium Bob",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001616,
    "y_1": 0.00001517,
    "z_1": -0.00004686,
    "Distance in Parsecs_1": 20.9644,
    "HYG Star ID_1": 118173,
    "Gliese Catalog_1": "GJ 1052"
  },
  {
    "Astrid #": "ASTRID 002114",
    "HYG Star ID": 66892,
    "Hipparcos Catalogue": 67105,
    "Henry Draper Catalogue": 119802,
    "Distance in Parsecs": 20.9864,
    "mag": 8.47,
    "Absolute Visual Magnitude": 6.86,
    "Spectral Type": "K2",
    "Color Index": 1.051,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.588925,
    "y": -9.191788,
    "z": 3.223644,
    "vx": -0.00000438,
    "vy": 0.00000548,
    "vz": -0.0000096,
    "rarad": 3.60081182708395,
    "decrad": 0.154216879503046,
    "pmrarad": -3.26618976590277e-7,
    "pmdecrad": -4.63142509e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 66892,
    "lum": 0.157036280433355,
    "ra": 13.754088,
    "dec": 8.835976,
    "pmra": -67.37,
    "pmdec": -95.53,
    "rv": 0,
    "Asset Name": "ASTRID 002114",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Bob",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000438,
    "y_1": 0.00000548,
    "z_1": -0.0000096,
    "Distance in Parsecs_1": 20.9864,
    "HYG Star ID_1": 66892,
    "Hipparcos Catalogue_1": 67105,
    "Henry Draper Catalogue_1": 119802
  },
  {
    "Astrid #": "ASTRID 002115",
    "HYG Star ID": 119362,
    "Gliese Catalog": "Gl 797B",
    "Distance in Parsecs": 20.9864,
    "mag": 11.88,
    "Absolute Visual Magnitude": 10.27,
    "Spectral Type": "m",
    "Color Index": 1.55,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.732201,
    "y": -15.073986,
    "z": 7.142761,
    "vx": -0.00001919,
    "vy": 0.00004222,
    "vz": 0.00001695,
    "rarad": 5.41376532933359,
    "decrad": 0.3473223322209,
    "pmrarad": 5.99326671902777e-7,
    "pmdecrad": 0.000001483384418,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 2,
    "comp_primary": 101710,
    "base": "Gl 797",
    "lum": 0.00679203632617184,
    "ra": 20.67906,
    "dec": 19.900104,
    "pmra": 123.62,
    "pmdec": 305.97,
    "rv": -35.4,
    "Asset Name": "ASTRID 002115",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Fedora",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": -0.00001919,
    "y_1": 0.00004222,
    "z_1": 0.00001695,
    "Distance in Parsecs_1": 20.9864,
    "HYG Star ID_1": 119362,
    "Gliese Catalog_1": "Gl 797B"
  },
  {
    "Astrid #": "ASTRID 002116",
    "HYG Star ID": 102520,
    "Hipparcos Catalogue": 102851,
    "Henry Draper Catalogue": 198550,
    "Gliese Catalog": "Gl 808.2",
    "Distance in Parsecs": 20.9908,
    "mag": 8.32,
    "Absolute Visual Magnitude": 6.71,
    "Spectral Type": "K5V",
    "Color Index": 1.068,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.367073,
    "y": -13.475509,
    "z": 10.299356,
    "vx": -0.00000127,
    "vy": 0.00000486,
    "vz": -0.00000941,
    "rarad": 5.45492140535386,
    "decrad": 0.512849546786195,
    "pmrarad": 1.12040441576388e-7,
    "pmdecrad": -2.86767292e-7,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 102520,
    "lum": 0.180301774085956,
    "ra": 20.836265,
    "dec": 29.384115,
    "pmra": 23.11,
    "pmdec": -59.15,
    "rv": -8.3,
    "Asset Name": "ASTRID 002116",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Angry",
    "Hair": "Middle Part",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": -0.00000127,
    "y_1": 0.00000486,
    "z_1": -0.00000941,
    "Distance in Parsecs_1": 20.9908,
    "HYG Star ID_1": 102520,
    "Hipparcos Catalogue_1": 102851,
    "Henry Draper Catalogue_1": 198550,
    "Gliese Catalog_1": "Gl 808.2"
  },
  {
    "Astrid #": "ASTRID 002117",
    "HYG Star ID": 19287,
    "Hipparcos Catalogue": 19335,
    "Henry Draper Catalogue": 25998,
    "Harvard Revised Catalogue": 1278,
    "Gliese Catalog": "Gl 161.1",
    "Distance in Parsecs": 20.9952,
    "mag": 5.52,
    "Absolute Visual Magnitude": 3.909,
    "Spectral Type": "F7V",
    "Color Index": 0.52,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.724117,
    "y": 14.620741,
    "z": 12.937551,
    "vx": 0.00000107,
    "vy": 0.00003774,
    "vz": 2e-7,
    "rarad": 1.08476507410683,
    "decrad": 0.663918591437982,
    "pmrarad": 7.94755066534722e-7,
    "pmdecrad": -9.86692802e-7,
    "flam": 50,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 19287,
    "lum": 2.37903044588062,
    "var": "V582",
    "var_min": 5.536,
    "var_max": 5.506,
    "bf": "50    Per",
    "ra": 4.143497,
    "dec": 38.039733,
    "pmra": 163.93,
    "pmdec": -203.52,
    "rv": 26.2,
    "Asset Name": "ASTRID 002117",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Prince",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00000107,
    "y_1": 0.00003774,
    "z_1": 2e-7,
    "Distance in Parsecs_1": 20.9952,
    "HYG Star ID_1": 19287,
    "Hipparcos Catalogue_1": 19335,
    "Henry Draper Catalogue_1": 25998,
    "Harvard Revised Catalogue_1": 1278,
    "Gliese Catalog_1": "Gl 161.1"
  },
  {
    "Astrid #": "ASTRID 002118",
    "HYG Star ID": 60290,
    "Hipparcos Catalogue": 60475,
    "Henry Draper Catalogue": 107888,
    "Gliese Catalog": "Gl 464",
    "Distance in Parsecs": 20.9952,
    "mag": 10.39,
    "Absolute Visual Magnitude": 8.779,
    "Spectral Type": "K5",
    "Color Index": 1.442,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.379951,
    "y": -2.13236,
    "z": 4.572913,
    "vx": -0.000009820000000000001,
    "vy": -0.00000521,
    "vz": -0.00001522,
    "rarad": 3.24584355202695,
    "decrad": 0.219566864390472,
    "pmrarad": 1.98288795347222e-7,
    "pmdecrad": -8.14583946e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 60290,
    "lum": 0.0268163706676072,
    "ra": 12.398209,
    "dec": 12.580255,
    "pmra": 40.9,
    "pmdec": -168.02,
    "rv": 6.6,
    "Asset Name": "ASTRID 002118",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Worried Vamp",
    "Hair": "Curly",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.000009820000000000001,
    "y_1": -0.00000521,
    "z_1": -0.00001522,
    "Distance in Parsecs_1": 20.9952,
    "HYG Star ID_1": 60290,
    "Hipparcos Catalogue_1": 60475,
    "Henry Draper Catalogue_1": 107888,
    "Gliese Catalog_1": "Gl 464"
  },
  {
    "Astrid #": "ASTRID 002119",
    "HYG Star ID": 95811,
    "Hipparcos Catalogue": 96113,
    "Henry Draper Catalogue": 183783,
    "Gliese Catalog": "GJ 1239",
    "Distance in Parsecs": 21.0084,
    "mag": 8.69,
    "Absolute Visual Magnitude": 7.078,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.088,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.721452,
    "y": -15.738338,
    "z": -12.185224,
    "vx": -0.00002333,
    "vy": -0.00002033,
    "vz": 0.00001339,
    "rarad": 5.11601871388035,
    "decrad": -0.618749232643008,
    "pmrarad": -0.00000140149938775,
    "pmdecrad": 7.82440799e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 95811,
    "lum": 0.128469489983119,
    "ra": 19.541752,
    "dec": -35.45172,
    "pmra": -289.08,
    "pmdec": 161.39,
    "rv": 0,
    "Asset Name": "ASTRID 002119",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Pompadour",
    "Outfit": "Wizard",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00002333,
    "y_1": -0.00002033,
    "z_1": 0.00001339,
    "Distance in Parsecs_1": 21.0084,
    "HYG Star ID_1": 95811,
    "Hipparcos Catalogue_1": 96113,
    "Henry Draper Catalogue_1": 183783,
    "Gliese Catalog_1": "GJ 1239"
  },
  {
    "Astrid #": "ASTRID 002120",
    "HYG Star ID": 118857,
    "Gliese Catalog": "Gl 491B",
    "Distance in Parsecs": 21.0084,
    "mag": 12.5,
    "Absolute Visual Magnitude": 10.888,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -20.016972,
    "y": -5.27252,
    "z": -3.58807,
    "vx": -0.0000248,
    "vy": 0.00008051,
    "vz": 0.00001991,
    "rarad": 3.39914807032244,
    "decrad": -0.1716335676955,
    "pmrarad": -0.00000400703355115972,
    "pmdecrad": 9.6200609e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 63164,
    "base": "Gl 491",
    "lum": 0.00384414711759577,
    "ra": 12.983789,
    "dec": -9.833879,
    "pmra": -826.51,
    "pmdec": 198.43,
    "rv": 0,
    "Asset Name": "ASTRID 002120",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Oh Snap",
    "Hair": "Unkempt",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000248,
    "y_1": 0.00008051,
    "z_1": 0.00001991,
    "Distance in Parsecs_1": 21.0084,
    "HYG Star ID_1": 118857,
    "Gliese Catalog_1": "Gl 491B"
  },
  {
    "Astrid #": "ASTRID 002121",
    "HYG Star ID": 119282,
    "Gliese Catalog": "NN 4108",
    "Distance in Parsecs": 21.0084,
    "mag": 15.37,
    "Absolute Visual Magnitude": 13.758,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.458248,
    "y": -17.11983,
    "z": 10.322563,
    "vx": 0.00001272,
    "vy": -0.00003265,
    "vz": -0.00006208,
    "rarad": 5.07312003520903,
    "decrad": 0.5136437586359,
    "pmrarad": 1.77441807083333e-8,
    "pmdecrad": -0.000003393647282,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119282,
    "lum": 0.00027340093785056,
    "ra": 19.377891,
    "dec": 29.42962,
    "pmra": 3.66,
    "pmdec": -699.99,
    "rv": 0,
    "Asset Name": "ASTRID 002121",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Ponytail",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001272,
    "y_1": -0.00003265,
    "z_1": -0.00006208,
    "Distance in Parsecs_1": 21.0084,
    "HYG Star ID_1": 119282,
    "Gliese Catalog_1": "NN 4108"
  },
  {
    "Astrid #": "ASTRID 002122",
    "HYG Star ID": 18279,
    "Hipparcos Catalogue": 18324,
    "Henry Draper Catalogue": 24238,
    "Gliese Catalog": "Gl 155.2",
    "Distance in Parsecs": 21.0128,
    "mag": 7.84,
    "Absolute Visual Magnitude": 6.228,
    "Spectral Type": "K0V",
    "Color Index": 0.831,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.254758,
    "y": 8.665031,
    "z": 18.408165,
    "vx": -0.00001451,
    "vy": 0.00006203,
    "vz": 0.00003087,
    "rarad": 1.02564043324319,
    "decrad": 1.06756218562563,
    "pmrarad": 0.00000212139922200694,
    "pmdecrad": -0.00000118895707,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 18279,
    "lum": 0.281060620033439,
    "ra": 3.917658,
    "dec": 61.166808,
    "pmra": 437.57,
    "pmdec": -245.24,
    "rv": 47.9,
    "Asset Name": "ASTRID 002122",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Mohawk",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001451,
    "y_1": 0.00006203,
    "z_1": 0.00003087,
    "Distance in Parsecs_1": 21.0128,
    "HYG Star ID_1": 18279,
    "Hipparcos Catalogue_1": 18324,
    "Henry Draper Catalogue_1": 24238,
    "Gliese Catalog_1": "Gl 155.2"
  },
  {
    "Astrid #": "ASTRID 002123",
    "HYG Star ID": 43948,
    "Hipparcos Catalogue": 44075,
    "Henry Draper Catalogue": 76932,
    "Harvard Revised Catalogue": 3578,
    "Gliese Catalog": "NN 3523",
    "Distance in Parsecs": 21.0349,
    "mag": 5.8,
    "Absolute Visual Magnitude": 4.185,
    "Spectral Type": "F7/F8IV/V",
    "Color Index": 0.521,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.209667,
    "y": 14.367753,
    "z": -5.845129,
    "vx": -0.00010542,
    "vy": 0.00007116,
    "vz": -0.00001342,
    "rarad": 2.35066232361949,
    "decrad": -0.281569203771282,
    "pmrarad": 0.00000118464222843749,
    "pmdecrad": 0.000001034883282,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 43948,
    "lum": 1.84501541917947,
    "ra": 8.978869,
    "dec": -16.132727,
    "pmra": 244.35,
    "pmdec": 213.46,
    "rv": 120.8,
    "Asset Name": "ASTRID 002123",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Bucket Hat",
    "Outfit": "Sharky",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00010542,
    "y_1": 0.00007116,
    "z_1": -0.00001342,
    "Distance in Parsecs_1": 21.0349,
    "HYG Star ID_1": 43948,
    "Hipparcos Catalogue_1": 44075,
    "Henry Draper Catalogue_1": 76932,
    "Harvard Revised Catalogue_1": 3578,
    "Gliese Catalog_1": "NN 3523"
  },
  {
    "Astrid #": "ASTRID 002124",
    "HYG Star ID": 78290,
    "Hipparcos Catalogue": 78527,
    "Henry Draper Catalogue": 144284,
    "Harvard Revised Catalogue": 5986,
    "Gliese Catalog": "Gl 609.1",
    "Distance in Parsecs": 21.0349,
    "mag": 4.01,
    "Absolute Visual Magnitude": 2.395,
    "Spectral Type": "F8IV-V",
    "Color Index": 0.528,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.406623,
    "y": -9.545392,
    "z": 17.947647,
    "vx": -0.00001201,
    "vy": 0.00004502,
    "vz": 0.00001109,
    "rarad": 4.19704539024746,
    "decrad": 1.02215646440416,
    "pmrarad": -0.00000155174314735416,
    "pmdecrad": 0.000001623931904,
    "bayer": "The",
    "flam": 13,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 78290,
    "lum": 9.59400631515932,
    "bf": "13The Dra",
    "ra": 16.031532,
    "dec": 58.565251,
    "pmra": -320.07,
    "pmdec": 334.96,
    "rv": -7.7,
    "Asset Name": "ASTRID 002124",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Shocked",
    "Hair": "Straw Hat",
    "Outfit": "Warlock",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00001201,
    "y_1": 0.00004502,
    "z_1": 0.00001109,
    "Distance in Parsecs_1": 21.0349,
    "HYG Star ID_1": 78290,
    "Hipparcos Catalogue_1": 78527,
    "Henry Draper Catalogue_1": 144284,
    "Harvard Revised Catalogue_1": 5986,
    "Gliese Catalog_1": "Gl 609.1"
  },
  {
    "Astrid #": "ASTRID 002125",
    "HYG Star ID": 111766,
    "Hipparcos Catalogue": 112120,
    "Gliese Catalog": "GJ 1271",
    "Distance in Parsecs": 21.0438,
    "mag": 11.78,
    "Absolute Visual Magnitude": 10.164,
    "Spectral Type": "M3",
    "Color Index": 1.501,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.919741,
    "y": -6.639883,
    "z": 6.387246,
    "vx": 0.00002236,
    "vy": 0.00011182,
    "vz": 0.00005004,
    "rarad": 5.94566213290195,
    "decrad": 0.308386327432236,
    "pmrarad": 0.00000536616022322916,
    "pmdecrad": 0.000002495820827,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 111766,
    "lum": 0.00748858907521643,
    "var_min": 11.851,
    "var_max": 11.681,
    "ra": 22.710756,
    "dec": 17.669235,
    "pmra": 1106.85,
    "pmdec": 514.8,
    "rv": 0,
    "Asset Name": "ASTRID 002125",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "High Bun",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00002236,
    "y_1": 0.00011182,
    "z_1": 0.00005004,
    "Distance in Parsecs_1": 21.0438,
    "HYG Star ID_1": 111766,
    "Hipparcos Catalogue_1": 112120,
    "Gliese Catalog_1": "GJ 1271"
  },
  {
    "Astrid #": "ASTRID 002126",
    "HYG Star ID": 117998,
    "Gliese Catalog": "Gl 23B",
    "Distance in Parsecs": 21.0482,
    "mag": 6.4,
    "Absolute Visual Magnitude": 4.784,
    "Spectral Type": "G1 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.75943,
    "y": 3.217402,
    "z": -1.319115,
    "vx": 0.00000235,
    "vy": 0.00004264,
    "vz": -0.00000268,
    "rarad": 0.153761554328888,
    "decrad": -0.06271089124505,
    "pmrarad": 0.00000198516657777083,
    "pmdecrad": -1.00564901e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 2756,
    "base": "Gl  23",
    "lum": 1.06267386540246,
    "ra": 0.587326,
    "dec": -3.593069,
    "pmra": 409.47,
    "pmdec": -20.74,
    "rv": 8.8,
    "Asset Name": "ASTRID 002126",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Round Brush",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000235,
    "y_1": 0.00004264,
    "z_1": -0.00000268,
    "Distance in Parsecs_1": 21.0482,
    "HYG Star ID_1": 117998,
    "Gliese Catalog_1": "Gl 23B"
  },
  {
    "Astrid #": "ASTRID 002127",
    "HYG Star ID": 5275,
    "Hipparcos Catalogue": 5286,
    "Henry Draper Catalogue": 6660,
    "Gliese Catalog": "Gl 53.1A",
    "Distance in Parsecs": 21.0526,
    "mag": 8.41,
    "Absolute Visual Magnitude": 6.793,
    "Spectral Type": "K4V",
    "Color Index": 1.122,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.547575,
    "y": 5.637942,
    "z": 8.210699,
    "vx": 0.00002182,
    "vy": 0.0000172,
    "vz": -0.00004354,
    "rarad": 0.295096403092203,
    "decrad": 0.400639914073205,
    "pmrarad": 4.80353394694444e-7,
    "pmdecrad": -0.000002383877348,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 5275,
    "base": "Gl  53.1",
    "lum": 0.167032122581504,
    "ra": 1.127185,
    "dec": 22.954976,
    "pmra": 99.08,
    "pmdec": -491.71,
    "rv": 6.7,
    "Asset Name": "ASTRID 002127",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Angelic",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00002182,
    "y_1": 0.0000172,
    "z_1": -0.00004354,
    "Distance in Parsecs_1": 21.0526,
    "HYG Star ID_1": 5275,
    "Hipparcos Catalogue_1": 5286,
    "Henry Draper Catalogue_1": 6660,
    "Gliese Catalog_1": "Gl 53.1A"
  },
  {
    "Astrid #": "ASTRID 002128",
    "HYG Star ID": 19805,
    "Hipparcos Catalogue": 19855,
    "Henry Draper Catalogue": 26913,
    "Harvard Revised Catalogue": 1321,
    "Distance in Parsecs": 21.0571,
    "mag": 6.94,
    "Absolute Visual Magnitude": 5.323,
    "Spectral Type": "G5IV",
    "Color Index": 0.68,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.223593,
    "y": 18.792356,
    "z": 2.274018,
    "vx": 0.00000628,
    "vy": -0.00001076,
    "vz": -0.00001234,
    "rarad": 1.11452272915795,
    "decrad": 0.108204325767917,
    "pmrarad": -4.92667662180555e-7,
    "pmdecrad": -5.47112238e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 19805,
    "lum": 0.646844664171721,
    "var": "V891",
    "var_min": 6.955,
    "var_max": 6.925,
    "ra": 4.257163,
    "dec": 6.199651,
    "pmra": -101.62,
    "pmdec": -112.85,
    "rv": -8,
    "Asset Name": "ASTRID 002128",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Star Knight",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000628,
    "y_1": -0.00001076,
    "z_1": -0.00001234,
    "Distance in Parsecs_1": 21.0571,
    "HYG Star ID_1": 19805,
    "Hipparcos Catalogue_1": 19855,
    "Henry Draper Catalogue_1": 26913,
    "Harvard Revised Catalogue_1": 1321
  },
  {
    "Astrid #": "ASTRID 002129",
    "HYG Star ID": 58276,
    "Hipparcos Catalogue": 58451,
    "Henry Draper Catalogue": 104067,
    "Gliese Catalog": "GJ 1153",
    "Distance in Parsecs": 21.0659,
    "mag": 7.92,
    "Absolute Visual Magnitude": 6.302,
    "Spectral Type": "K2V",
    "Color Index": 0.974,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.750374,
    "y": 0.0718,
    "z": -7.327016,
    "vx": 0.00002649,
    "vy": -0.00001472,
    "vz": -0.00003626,
    "rarad": 3.13795686801856,
    "decrad": -0.35524047974113,
    "pmrarad": 6.94059265083333e-7,
    "pmdecrad": -0.000002052313272,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 58276,
    "lum": 0.26254273189937,
    "ra": 11.986112,
    "dec": -20.35378,
    "pmra": 143.16,
    "pmdec": -423.32,
    "rv": -12,
    "Asset Name": "ASTRID 002129",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002649,
    "y_1": -0.00001472,
    "z_1": -0.00003626,
    "Distance in Parsecs_1": 21.0659,
    "HYG Star ID_1": 58276,
    "Hipparcos Catalogue_1": 58451,
    "Henry Draper Catalogue_1": 104067,
    "Gliese Catalog_1": "GJ 1153"
  },
  {
    "Astrid #": "ASTRID 002130",
    "HYG Star ID": 81314,
    "Hipparcos Catalogue": 81563,
    "Distance in Parsecs": 21.0704,
    "mag": 11.88,
    "Absolute Visual Magnitude": 10.262,
    "Spectral Type": "K:",
    "Color Index": 1.77,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.604514,
    "y": -12.566191,
    "z": 16.274245,
    "vx": 0.00001986,
    "vy": -0.00004114,
    "vz": -0.00002615,
    "rarad": 4.36115306499164,
    "decrad": 0.88257148098496,
    "pmrarad": 0.00000155702761647222,
    "pmdecrad": -0.000001953944576,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81314,
    "lum": 0.0068422667276595,
    "var_min": 11.958,
    "var_max": 11.758,
    "ra": 16.658378,
    "dec": 50.567621,
    "pmra": 321.16,
    "pmdec": -403.03,
    "rv": 0,
    "Asset Name": "ASTRID 002130",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Gimme Blood",
    "Hair": "Shinobi",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001986,
    "y_1": -0.00004114,
    "z_1": -0.00002615,
    "Distance in Parsecs_1": 21.0704,
    "HYG Star ID_1": 81314,
    "Hipparcos Catalogue_1": 81563
  },
  {
    "Astrid #": "ASTRID 002131",
    "HYG Star ID": 79366,
    "Hipparcos Catalogue": 79607,
    "Henry Draper Catalogue": 146361,
    "Harvard Revised Catalogue": 6063,
    "Gliese Catalog": "Gl 615.2A",
    "Distance in Parsecs": 21.0793,
    "mag": 5.23,
    "Absolute Visual Magnitude": 3.611,
    "Spectral Type": "F8V",
    "Color Index": 0.599,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.763883,
    "y": -15.688492,
    "z": 11.744175,
    "vx": -0.000022,
    "vy": 0.00001693,
    "vz": -0.00001432,
    "rarad": 4.25284998902362,
    "decrad": 0.590944273446406,
    "pmrarad": -0.00000129188301457638,
    "pmdecrad": -4.21206125e-7,
    "bayer": "Sig",
    "flam": 17,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 79366,
    "base": "Gl 615.2",
    "lum": 3.13040119009623,
    "var": "TZ",
    "var_min": 5.374,
    "var_max": 5.204,
    "bf": "17Sig CrB",
    "ra": 16.24469,
    "dec": 33.858613,
    "pmra": -266.47,
    "pmdec": -86.88,
    "rv": -12.2,
    "Asset Name": "ASTRID 002131",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Skater Helmet",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": -0.000022,
    "y_1": 0.00001693,
    "z_1": -0.00001432,
    "Distance in Parsecs_1": 21.0793,
    "HYG Star ID_1": 79366,
    "Hipparcos Catalogue_1": 79607,
    "Henry Draper Catalogue_1": 146361,
    "Harvard Revised Catalogue_1": 6063,
    "Gliese Catalog_1": "Gl 615.2A"
  },
  {
    "Astrid #": "ASTRID 002132",
    "HYG Star ID": 96588,
    "Hipparcos Catalogue": 96895,
    "Henry Draper Catalogue": 186408,
    "Harvard Revised Catalogue": 7503,
    "Gliese Catalog": "Gl 765.1A",
    "Distance in Parsecs": 21.0793,
    "mag": 5.99,
    "Absolute Visual Magnitude": 4.371,
    "Spectral Type": "G2V",
    "Color Index": 0.643,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.75948,
    "y": -12.100007,
    "z": 16.270979,
    "vx": -0.0000159,
    "vy": -0.00000172,
    "vz": -0.00003195,
    "rarad": 5.15664819626813,
    "decrad": 0.881828654392853,
    "pmrarad": -7.16312213020833e-7,
    "pmdecrad": -7.70126531e-7,
    "flam": 16,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96588,
    "base": "Gl 765.1",
    "lum": 1.55453319406078,
    "bf": "16    Cyg",
    "ra": 19.696945,
    "dec": 50.52506,
    "pmra": -147.75,
    "pmdec": -158.85,
    "rv": -27.4,
    "Asset Name": "ASTRID 002132",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Prince",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.0000159,
    "y_1": -0.00000172,
    "z_1": -0.00003195,
    "Distance in Parsecs_1": 21.0793,
    "HYG Star ID_1": 96588,
    "Hipparcos Catalogue_1": 96895,
    "Henry Draper Catalogue_1": 186408,
    "Harvard Revised Catalogue_1": 7503,
    "Gliese Catalog_1": "Gl 765.1A"
  },
  {
    "Astrid #": "ASTRID 002133",
    "HYG Star ID": 1529,
    "Hipparcos Catalogue": 1532,
    "Gliese Catalog": "GJ 1008",
    "Distance in Parsecs": 21.0837,
    "mag": 9.9,
    "Absolute Visual Magnitude": 8.28,
    "Spectral Type": "K7V",
    "Color Index": 1.318,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.693616,
    "y": 1.727935,
    "z": -3.648409,
    "vx": -0.00000501,
    "vy": -0.00000422,
    "vz": -0.0000304,
    "rarad": 0.0833076318704373,
    "decrad": -0.17391950835308,
    "pmrarad": -1.79671950013888e-7,
    "pmdecrad": -0.000001464040352,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 1529,
    "lum": 0.0424619563946313,
    "ra": 0.318212,
    "dec": -9.964854,
    "pmra": -37.06,
    "pmdec": -301.98,
    "rv": 0,
    "Asset Name": "ASTRID 002133",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Ram Horns",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000501,
    "y_1": -0.00000422,
    "z_1": -0.0000304,
    "Distance in Parsecs_1": 21.0837,
    "HYG Star ID_1": 1529,
    "Hipparcos Catalogue_1": 1532,
    "Gliese Catalog_1": "GJ 1008"
  },
  {
    "Astrid #": "ASTRID 002134",
    "HYG Star ID": 65463,
    "Hipparcos Catalogue": 65669,
    "Distance in Parsecs": 21.0837,
    "mag": 11.76,
    "Absolute Visual Magnitude": 10.14,
    "Color Index": 1.515,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -17.428574,
    "y": -7.032706,
    "z": -9.555535,
    "vx": -0.00000301,
    "vy": 0.00002051,
    "vz": -0.0000096,
    "rarad": 3.52512726578206,
    "decrad": -0.47037327080047,
    "pmrarad": -9.55567764375e-7,
    "pmdecrad": -5.10605768e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 65463,
    "lum": 0.00765596606911255,
    "var_min": 11.832,
    "var_max": 11.682,
    "ra": 13.464994,
    "dec": -26.950403,
    "pmra": -197.1,
    "pmdec": -105.32,
    "rv": 0,
    "Asset Name": "ASTRID 002134",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Headband",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000301,
    "y_1": 0.00002051,
    "z_1": -0.0000096,
    "Distance in Parsecs_1": 21.0837,
    "HYG Star ID_1": 65463,
    "Hipparcos Catalogue_1": 65669
  },
  {
    "Astrid #": "ASTRID 002135",
    "HYG Star ID": 102026,
    "Hipparcos Catalogue": 102357,
    "Henry Draper Catalogue": 352860,
    "Gliese Catalog": "Gl 804",
    "Distance in Parsecs": 21.0837,
    "mag": 10.29,
    "Absolute Visual Magnitude": 8.67,
    "Spectral Type": "M2",
    "Color Index": 1.457,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.042414,
    "y": -14.955314,
    "z": 7.124435,
    "vx": 0.00001429,
    "vy": -0.00001887,
    "vz": -0.00005668,
    "rarad": 5.4295698053819,
    "decrad": 0.344697045857565,
    "pmrarad": -7.73277820486111e-8,
    "pmdecrad": -0.000002908930564,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 102026,
    "lum": 0.0296483138952434,
    "var_min": 10.346,
    "var_max": 10.216,
    "ra": 20.739429,
    "dec": 19.749686,
    "pmra": -15.95,
    "pmdec": -600.01,
    "rv": 3,
    "Asset Name": "ASTRID 002135",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Horns",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00001429,
    "y_1": -0.00001887,
    "z_1": -0.00005668,
    "Distance in Parsecs_1": 21.0837,
    "HYG Star ID_1": 102026,
    "Hipparcos Catalogue_1": 102357,
    "Henry Draper Catalogue_1": 352860,
    "Gliese Catalog_1": "Gl 804"
  },
  {
    "Astrid #": "ASTRID 002136",
    "HYG Star ID": 118122,
    "Gliese Catalog": "GJ 1045",
    "Distance in Parsecs": 21.097,
    "mag": 14.44,
    "Absolute Visual Magnitude": 12.819,
    "Spectral Type": "m+",
    "Color Index": 1.62,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.737007,
    "y": 11.183587,
    "z": 6.315331,
    "vx": -0.00000799,
    "vy": 0.00003803,
    "vz": -0.00004616,
    "rarad": 0.589055959651882,
    "decrad": 0.30400855217145,
    "pmrarad": 0.00000170945303763888,
    "pmdecrad": -0.000002293396571,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118122,
    "lum": 0.000649232123143213,
    "ra": 2.250028,
    "dec": 17.418407,
    "pmra": 352.6,
    "pmdec": -473.05,
    "rv": 0,
    "Asset Name": "ASTRID 002136",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Cute Neko",
    "Hair": "Shinobi",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000799,
    "y_1": 0.00003803,
    "z_1": -0.00004616,
    "Distance in Parsecs_1": 21.097,
    "HYG Star ID_1": 118122,
    "Gliese Catalog_1": "GJ 1045"
  },
  {
    "Astrid #": "ASTRID 002137",
    "HYG Star ID": 54745,
    "Hipparcos Catalogue": 54906,
    "Henry Draper Catalogue": 97658,
    "Gliese Catalog": "NN 3651",
    "Distance in Parsecs": 21.1149,
    "mag": 7.76,
    "Absolute Visual Magnitude": 6.137,
    "Spectral Type": "K1V",
    "Color Index": 0.845,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.651678,
    "y": 3.747906,
    "z": 9.160125,
    "vx": 0.00000243,
    "vy": 0.00001055,
    "vz": 0.00000534,
    "rarad": 2.94329191438262,
    "decrad": 0.44873086382275,
    "pmrarad": -5.12641985819444e-7,
    "pmdecrad": 2.339226e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54745,
    "lum": 0.305632828036596,
    "ra": 11.242547,
    "dec": 25.710385,
    "pmra": -105.74,
    "pmdec": 48.25,
    "rv": 2,
    "Asset Name": "ASTRID 002137",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Greasy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000243,
    "y_1": 0.00001055,
    "z_1": 0.00000534,
    "Distance in Parsecs_1": 21.1149,
    "HYG Star ID_1": 54745,
    "Hipparcos Catalogue_1": 54906,
    "Henry Draper Catalogue_1": 97658,
    "Gliese Catalog_1": "NN 3651"
  },
  {
    "Astrid #": "ASTRID 002138",
    "HYG Star ID": 118055,
    "Gliese Catalog": "Gl 55.1B",
    "Distance in Parsecs": 21.1149,
    "mag": 8.2,
    "Absolute Visual Magnitude": 6.577,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.223973,
    "y": 2.452974,
    "z": -19.688478,
    "vx": -0.00000268,
    "vy": 0.00004167,
    "vz": 0.0000042,
    "rarad": 0.327175505902522,
    "decrad": -1.2011210865847,
    "pmrarad": 0.00000190977805044444,
    "pmdecrad": 5.51228306e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 2,
    "comp_primary": 5829,
    "base": "Gl  55.1",
    "lum": 0.203798038628705,
    "ra": 1.249718,
    "dec": -68.819169,
    "pmra": 393.92,
    "pmdec": 113.7,
    "rv": 0,
    "Asset Name": "ASTRID 002138",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Determined Neko",
    "Hair": "Braided Bun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000268,
    "y_1": 0.00004167,
    "z_1": 0.0000042,
    "Distance in Parsecs_1": 21.1149,
    "HYG Star ID_1": 118055,
    "Gliese Catalog_1": "Gl 55.1B"
  },
  {
    "Astrid #": "ASTRID 002139",
    "HYG Star ID": 119544,
    "Gliese Catalog": "Gl 889B",
    "Distance in Parsecs": 21.1149,
    "mag": 13.65,
    "Absolute Visual Magnitude": 12.027,
    "Spectral Type": "k-m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.905579,
    "y": -4.429718,
    "z": -8.294315,
    "vx": -0.00000646,
    "vy": 0.00001776,
    "vz": -0.00002421,
    "rarad": 6.05302646328089,
    "decrad": -0.40369406891895,
    "pmrarad": 7.49085617826388e-7,
    "pmdecrad": -0.000001246698379,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 113795,
    "base": "Gl 889",
    "lum": 0.00134648028838784,
    "ra": 23.120858,
    "dec": -23.129966,
    "pmra": 154.51,
    "pmdec": -257.15,
    "rv": 0,
    "Asset Name": "ASTRID 002139",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Fire",
    "Outfit": "Spartan",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000646,
    "y_1": 0.00001776,
    "z_1": -0.00002421,
    "Distance in Parsecs_1": 21.1149,
    "HYG Star ID_1": 119544,
    "Gliese Catalog_1": "Gl 889B"
  },
  {
    "Astrid #": "ASTRID 002140",
    "HYG Star ID": 114764,
    "Hipparcos Catalogue": 115126,
    "Henry Draper Catalogue": 219834,
    "Harvard Revised Catalogue": 8866,
    "Gliese Catalog": "Gl 894.2A",
    "Distance in Parsecs": 21.1193,
    "mag": 5.2,
    "Absolute Visual Magnitude": 3.577,
    "Spectral Type": "G6/G8IV",
    "Color Index": 0.787,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.213398,
    "y": -3.645056,
    "z": -4.915367,
    "vx": 0.00001386,
    "vy": 0.00002514,
    "vz": -0.00000692,
    "rarad": 6.10477367276678,
    "decrad": -0.234896037439107,
    "pmrarad": 0.00000128785906102777,
    "pmdecrad": -2.17535898e-7,
    "flam": 94,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 114764,
    "base": "Gl 894.2",
    "lum": 3.22998124059562,
    "bf": "94    Aqr",
    "ra": 23.318518,
    "dec": -13.458552,
    "pmra": 265.64,
    "pmdec": -44.87,
    "rv": 10.3,
    "Asset Name": "ASTRID 002140",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Hachimaki",
    "Outfit": "Sharky",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001386,
    "y_1": 0.00002514,
    "z_1": -0.00000692,
    "Distance in Parsecs_1": 21.1193,
    "HYG Star ID_1": 114764,
    "Hipparcos Catalogue_1": 115126,
    "Henry Draper Catalogue_1": 219834,
    "Harvard Revised Catalogue_1": 8866,
    "Gliese Catalog_1": "Gl 894.2A"
  },
  {
    "Astrid #": "ASTRID 002141",
    "HYG Star ID": 96914,
    "Hipparcos Catalogue": 97222,
    "Henry Draper Catalogue": 186858,
    "Gliese Catalog": "Gl 765.4A",
    "Distance in Parsecs": 21.1238,
    "mag": 7.68,
    "Absolute Visual Magnitude": 6.056,
    "Spectral Type": "K3V",
    "Color Index": 1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.820123,
    "y": -15.760637,
    "z": 11.690364,
    "vx": 0.00001398,
    "vy": -0.00002512,
    "vz": -0.00003509,
    "rarad": 5.17297634320192,
    "decrad": 0.58646528149322,
    "pmrarad": 6.44802195138888e-8,
    "pmdecrad": -0.000002135943632,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96914,
    "base": "Gl 765.4",
    "lum": 0.329306270175208,
    "ra": 19.759314,
    "dec": 33.601985,
    "pmra": 13.3,
    "pmdec": -440.57,
    "rv": 4.4,
    "Asset Name": "ASTRID 002141",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "High Bun",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001398,
    "y_1": -0.00002512,
    "z_1": -0.00003509,
    "Distance in Parsecs_1": 21.1238,
    "HYG Star ID_1": 96914,
    "Hipparcos Catalogue_1": 97222,
    "Henry Draper Catalogue_1": 186858,
    "Gliese Catalog_1": "Gl 765.4A"
  },
  {
    "Astrid #": "ASTRID 002142",
    "HYG Star ID": 101969,
    "Hipparcos Catalogue": 102300,
    "Distance in Parsecs": 21.1327,
    "mag": 11.87,
    "Absolute Visual Magnitude": 10.245,
    "Spectral Type": "M0",
    "Color Index": 1.997,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.006985,
    "y": -6.929188,
    "z": 19.039262,
    "vx": 0.0000301,
    "vy": 0.00001381,
    "vz": -0.00000447,
    "rarad": 5.42660165826692,
    "decrad": 1.12192725297285,
    "pmrarad": 0.00000150471622034027,
    "pmdecrad": -4.87431674e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 101969,
    "lum": 0.00695024317588796,
    "var_min": 11.963,
    "var_max": 11.793,
    "ra": 20.728091,
    "dec": 64.281697,
    "pmra": 310.37,
    "pmdec": -100.54,
    "rv": 0,
    "Asset Name": "ASTRID 002142",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Slick",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.0000301,
    "y_1": 0.00001381,
    "z_1": -0.00000447,
    "Distance in Parsecs_1": 21.1327,
    "HYG Star ID_1": 101969,
    "Hipparcos Catalogue_1": 102300
  },
  {
    "Astrid #": "ASTRID 002143",
    "HYG Star ID": 41955,
    "Hipparcos Catalogue": 42074,
    "Henry Draper Catalogue": 72760,
    "Gliese Catalog": "NN 3507",
    "Distance in Parsecs": 21.1372,
    "mag": 7.32,
    "Absolute Visual Magnitude": 5.695,
    "Spectral Type": "G5",
    "Color Index": 0.791,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.195196,
    "y": 16.51049,
    "z": -0.267849,
    "vx": 0.00001553,
    "vy": 0.00001245,
    "vz": 0.0000024,
    "rarad": 2.24504971140983,
    "decrad": -0.0126722544955737,
    "pmrarad": -9.41896018583333e-7,
    "pmdecrad": 1.13543363e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 41955,
    "lum": 0.459198012836868,
    "ra": 8.575458,
    "dec": -0.726067,
    "pmra": -194.28,
    "pmdec": 23.42,
    "rv": 0,
    "Asset Name": "ASTRID 002143",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Scared",
    "Hair": "Honest Lad",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001553,
    "y_1": 0.00001245,
    "z_1": 0.0000024,
    "Distance in Parsecs_1": 21.1372,
    "HYG Star ID_1": 41955,
    "Hipparcos Catalogue_1": 42074,
    "Henry Draper Catalogue_1": 72760,
    "Gliese Catalog_1": "NN 3507"
  },
  {
    "Astrid #": "ASTRID 002144",
    "HYG Star ID": 4839,
    "Hipparcos Catalogue": 4849,
    "Henry Draper Catalogue": 6101,
    "Gliese Catalog": "NN 3071",
    "Distance in Parsecs": 21.1416,
    "mag": 8.16,
    "Absolute Visual Magnitude": 6.534,
    "Spectral Type": "K2",
    "Color Index": 1.008,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.283329,
    "y": 5.664117,
    "z": 1.865213,
    "vx": 0.00000803,
    "vy": 0.00003843,
    "vz": 0.00002435,
    "rarad": 0.272312906479632,
    "decrad": 0.0883389151750875,
    "pmrarad": 0.00000164851195799305,
    "pmdecrad": 0.00000107202001,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 4839,
    "lum": 0.212031311673984,
    "ra": 1.040159,
    "dec": 5.061447,
    "pmra": 340.03,
    "pmdec": 221.12,
    "rv": 19.7,
    "Asset Name": "ASTRID 002144",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Middle Part",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00000803,
    "y_1": 0.00003843,
    "z_1": 0.00002435,
    "Distance in Parsecs_1": 21.1416,
    "HYG Star ID_1": 4839,
    "Hipparcos Catalogue_1": 4849,
    "Henry Draper Catalogue_1": 6101,
    "Gliese Catalog_1": "NN 3071"
  },
  {
    "Astrid #": "ASTRID 002145",
    "HYG Star ID": 119514,
    "Gliese Catalog": "NN 4286",
    "Distance in Parsecs": 21.1416,
    "mag": 10.5,
    "Absolute Visual Magnitude": 8.874,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.773689,
    "y": -7.477623,
    "z": -0.231579,
    "vx": 0.00000503,
    "vy": 0.00001335,
    "vz": -0.0000015,
    "rarad": 5.92164693930437,
    "decrad": -0.01095392013925,
    "pmrarad": 6.750060874375e-7,
    "pmdecrad": -7.0942785e-8,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 119514,
    "lum": 0.0245697082763683,
    "ra": 22.619025,
    "dec": -0.627613,
    "pmra": 139.23,
    "pmdec": -14.63,
    "rv": 0,
    "Asset Name": "ASTRID 002145",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Angelic",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000503,
    "y_1": 0.00001335,
    "z_1": -0.0000015,
    "Distance in Parsecs_1": 21.1416,
    "HYG Star ID_1": 119514,
    "Gliese Catalog_1": "NN 4286"
  },
  {
    "Astrid #": "ASTRID 002146",
    "HYG Star ID": 88225,
    "Hipparcos Catalogue": 88499,
    "Gliese Catalog": "NN 4041",
    "Distance in Parsecs": 21.1595,
    "mag": 10.83,
    "Absolute Visual Magnitude": 9.202,
    "Spectral Type": "M2",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.320826,
    "y": -17.124685,
    "z": 12.424439,
    "vx": 0.0000087,
    "vy": -0.00001568,
    "vz": -0.00002184,
    "rarad": 4.73112070526552,
    "decrad": 0.627570799104122,
    "pmrarad": 3.97401773951388e-7,
    "pmdecrad": -0.000001275302386,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 88225,
    "lum": 0.0181635193117651,
    "var_min": 10.926,
    "var_max": 10.746,
    "ra": 18.07155,
    "dec": 35.957158,
    "pmra": 81.97,
    "pmdec": -263.05,
    "rv": 0,
    "Asset Name": "ASTRID 002146",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Grecian",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.0000087,
    "y_1": -0.00001568,
    "z_1": -0.00002184,
    "Distance in Parsecs_1": 21.1595,
    "HYG Star ID_1": 88225,
    "Hipparcos Catalogue_1": 88499,
    "Gliese Catalog_1": "NN 4041"
  },
  {
    "Astrid #": "ASTRID 002147",
    "HYG Star ID": 65325,
    "Hipparcos Catalogue": 65530,
    "Henry Draper Catalogue": 117043,
    "Harvard Revised Catalogue": 5070,
    "Gliese Catalog": "Gl 511.1",
    "Distance in Parsecs": 21.1685,
    "mag": 6.5,
    "Absolute Visual Magnitude": 4.872,
    "Spectral Type": "G6V",
    "Color Index": 0.739,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.861393,
    "y": -3.49049,
    "z": 18.904652,
    "vx": 0.00001726,
    "vy": 0.00005011,
    "vz": -0.00001804,
    "rarad": 3.5168484901654,
    "decrad": 1.10411753129815,
    "pmrarad": -0.00000190352395396527,
    "pmdecrad": 0.000001069062646,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 65325,
    "lum": 0.979941161095593,
    "ra": 13.433372,
    "dec": 63.261275,
    "pmra": -392.63,
    "pmdec": 220.51,
    "rv": -30.9,
    "Asset Name": "ASTRID 002147",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Fire",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001726,
    "y_1": 0.00005011,
    "z_1": -0.00001804,
    "Distance in Parsecs_1": 21.1685,
    "HYG Star ID_1": 65325,
    "Hipparcos Catalogue_1": 65530,
    "Henry Draper Catalogue_1": 117043,
    "Harvard Revised Catalogue_1": 5070,
    "Gliese Catalog_1": "Gl 511.1"
  },
  {
    "Astrid #": "ASTRID 002148",
    "HYG Star ID": 8028,
    "Hipparcos Catalogue": 8043,
    "Gliese Catalog": "NN 3108",
    "Distance in Parsecs": 21.173,
    "mag": 10.4,
    "Absolute Visual Magnitude": 8.771,
    "Spectral Type": "K0",
    "Color Index": 1.46,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.853387,
    "y": 8.153255,
    "z": 9.888565,
    "vx": -0.00002319,
    "vy": 0.0000516,
    "vz": -0.00000302,
    "rarad": 0.450580819806937,
    "decrad": 0.485936374070987,
    "pmrarad": 0.00000267117793575694,
    "pmdecrad": -1.6124903e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 8028,
    "lum": 0.0270146907295724,
    "ra": 1.721092,
    "dec": 27.842103,
    "pmra": 550.97,
    "pmdec": -33.26,
    "rv": 0,
    "Asset Name": "ASTRID 002148",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Owie",
    "Hair": "Greasy",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00002319,
    "y_1": 0.0000516,
    "z_1": -0.00000302,
    "Distance in Parsecs_1": 21.173,
    "HYG Star ID_1": 8028,
    "Hipparcos Catalogue_1": 8043,
    "Gliese Catalog_1": "NN 3108"
  },
  {
    "Astrid #": "ASTRID 002149",
    "HYG Star ID": 118558,
    "Gliese Catalog": "Gl 318.1",
    "Distance in Parsecs": 21.1864,
    "mag": 14.11,
    "Absolute Visual Magnitude": 12.48,
    "Spectral Type": "M1.5:",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.881821,
    "y": 11.434131,
    "z": 14.84836,
    "vx": 0.00002133,
    "vy": 0.00003905,
    "vz": -0.00001587,
    "rarad": 2.28353851015235,
    "decrad": 0.776579928922,
    "pmrarad": -0.00000196727695295833,
    "pmdecrad": -0.00000105042156,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118558,
    "lum": 0.000887156012037959,
    "ra": 8.722475,
    "dec": 44.494752,
    "pmra": -405.78,
    "pmdec": -216.66,
    "rv": 0,
    "Asset Name": "ASTRID 002149",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Innocent",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002133,
    "y_1": 0.00003905,
    "z_1": -0.00001587,
    "Distance in Parsecs_1": 21.1864,
    "HYG Star ID_1": 118558,
    "Gliese Catalog_1": "Gl 318.1"
  },
  {
    "Astrid #": "ASTRID 002150",
    "HYG Star ID": 106746,
    "Hipparcos Catalogue": 107089,
    "Henry Draper Catalogue": 205478,
    "Harvard Revised Catalogue": 8254,
    "Gliese Catalog": "Gl 835.1",
    "Distance in Parsecs": 21.1999,
    "mag": 3.73,
    "Absolute Visual Magnitude": 2.098,
    "Spectral Type": "K0III",
    "Color Index": 1.008,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.808297,
    "y": -2.630174,
    "z": -20.688835,
    "vx": -0.00000973,
    "vy": 0.00001482,
    "vz": -0.00003973,
    "rarad": 5.6787567581662,
    "decrad": -1.35071111620734,
    "pmrarad": 3.14304709104166e-7,
    "pmdecrad": -0.000001165395125,
    "bayer": "Nu",
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 106746,
    "lum": 12.6124657531753,
    "bf": "Nu  Oct",
    "ra": 21.691253,
    "dec": -77.390046,
    "pmra": 64.83,
    "pmdec": -240.38,
    "rv": 34.4,
    "Asset Name": "ASTRID 002150",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Ponytail",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": -0.00000973,
    "y_1": 0.00001482,
    "z_1": -0.00003973,
    "Distance in Parsecs_1": 21.1999,
    "HYG Star ID_1": 106746,
    "Hipparcos Catalogue_1": 107089,
    "Henry Draper Catalogue_1": 205478,
    "Harvard Revised Catalogue_1": 8254,
    "Gliese Catalog_1": "Gl 835.1"
  },
  {
    "Astrid #": "ASTRID 002151",
    "HYG Star ID": 96594,
    "Hipparcos Catalogue": 96901,
    "Henry Draper Catalogue": 186427,
    "Harvard Revised Catalogue": 7504,
    "Gliese Catalog": "Gl 765.1B",
    "Distance in Parsecs": 21.2134,
    "mag": 6.25,
    "Absolute Visual Magnitude": 4.617,
    "Spectral Type": "G5V",
    "Color Index": 0.661,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.799717,
    "y": -12.177653,
    "z": 16.372712,
    "vx": -0.00001482,
    "vy": -0.0000012,
    "vz": -0.00003287,
    "rarad": 5.15686740077521,
    "decrad": 0.881697107861653,
    "pmrarad": -6.55225689270833e-7,
    "pmdecrad": -7.92815811e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 96588,
    "base": "Gl 765.1",
    "lum": 1.23936720482068,
    "ra": 19.697783,
    "dec": 50.517523,
    "pmra": -135.15,
    "pmdec": -163.53,
    "rv": -28.1,
    "Asset Name": "ASTRID 002151",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Goddess",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00001482,
    "y_1": -0.0000012,
    "z_1": -0.00003287,
    "Distance in Parsecs_1": 21.2134,
    "HYG Star ID_1": 96594,
    "Hipparcos Catalogue_1": 96901,
    "Henry Draper Catalogue_1": 186427,
    "Harvard Revised Catalogue_1": 7504,
    "Gliese Catalog_1": "Gl 765.1B"
  },
  {
    "Astrid #": "ASTRID 002152",
    "HYG Star ID": 69449,
    "Hipparcos Catalogue": 69671,
    "Henry Draper Catalogue": 124580,
    "Harvard Revised Catalogue": 5325,
    "Gliese Catalog": "Gl 540.3",
    "Distance in Parsecs": 21.2179,
    "mag": 6.31,
    "Absolute Visual Magnitude": 4.676,
    "Spectral Type": "F9V",
    "Color Index": 0.596,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.45116,
    "y": -8.370361,
    "z": -15.00354,
    "vx": 0.00001358,
    "vy": -0.00000667,
    "vz": -0.00001247,
    "rarad": 3.73345227455104,
    "decrad": -0.78541135015671,
    "pmrarad": 6.18234405444444e-7,
    "pmdecrad": -6.67103624e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 69449,
    "lum": 1.17381593248322,
    "ra": 14.260737,
    "dec": -45.000756,
    "pmra": 127.52,
    "pmdec": -137.6,
    "rv": 3.4,
    "Asset Name": "ASTRID 002152",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Grecian",
    "Outfit": "Toga",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001358,
    "y_1": -0.00000667,
    "z_1": -0.00001247,
    "Distance in Parsecs_1": 21.2179,
    "HYG Star ID_1": 69449,
    "Hipparcos Catalogue_1": 69671,
    "Henry Draper Catalogue_1": 124580,
    "Harvard Revised Catalogue_1": 5325,
    "Gliese Catalog_1": "Gl 540.3"
  },
  {
    "Astrid #": "ASTRID 002153",
    "HYG Star ID": 96986,
    "Hipparcos Catalogue": 97295,
    "Henry Draper Catalogue": 187013,
    "Harvard Revised Catalogue": 7534,
    "Gliese Catalog": "Gl 767.1A",
    "Distance in Parsecs": 21.2314,
    "mag": 5,
    "Absolute Visual Magnitude": 3.365,
    "Spectral Type": "F5",
    "Color Index": 0.476,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.908325,
    "y": -15.787971,
    "z": 11.788652,
    "vx": 0.0000152,
    "vy": -0.00002506,
    "vz": -0.00003602,
    "rarad": 5.17676271580859,
    "decrad": 0.588657626305733,
    "pmrarad": 1.11749553368055e-7,
    "pmdecrad": -0.000002175165059,
    "flam": 17,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96986,
    "base": "Gl 767.1",
    "lum": 3.92644935399599,
    "bf": "17    Cyg",
    "ra": 19.773777,
    "dec": 33.727598,
    "pmra": 23.05,
    "pmdec": -448.66,
    "rv": 4.2,
    "Asset Name": "ASTRID 002153",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Worried Vamp",
    "Hair": "Fire",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.0000152,
    "y_1": -0.00002506,
    "z_1": -0.00003602,
    "Distance in Parsecs_1": 21.2314,
    "HYG Star ID_1": 96986,
    "Hipparcos Catalogue_1": 97295,
    "Henry Draper Catalogue_1": 187013,
    "Harvard Revised Catalogue_1": 7534,
    "Gliese Catalog_1": "Gl 767.1A"
  },
  {
    "Astrid #": "ASTRID 002154",
    "HYG Star ID": 117989,
    "Gliese Catalog": "NN 3035",
    "Distance in Parsecs": 21.2314,
    "mag": 14.25,
    "Absolute Visual Magnitude": 12.615,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.636876,
    "y": 1.620011,
    "z": 16.192083,
    "vx": 0.00001553,
    "vy": 0.00004242,
    "vz": -0.00001731,
    "rarad": 0.118190356005763,
    "decrad": 0.8673970584353,
    "pmrarad": 0.00000189790011527083,
    "pmdecrad": -0.000001260971294,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117989,
    "lum": 0.000783429642766211,
    "ra": 0.451454,
    "dec": 49.698191,
    "pmra": 391.47,
    "pmdec": -260.09,
    "rv": 0,
    "Asset Name": "ASTRID 002154",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "High Bun",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001553,
    "y_1": 0.00004242,
    "z_1": -0.00001731,
    "Distance in Parsecs_1": 21.2314,
    "HYG Star ID_1": 117989,
    "Gliese Catalog_1": "NN 3035"
  },
  {
    "Astrid #": "ASTRID 002155",
    "HYG Star ID": 118440,
    "Gliese Catalog": "NN 3409B",
    "Distance in Parsecs": 21.2314,
    "mag": 12.17,
    "Absolute Visual Magnitude": 10.535,
    "Spectral Type": "M0.5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.576167,
    "y": 17.534213,
    "z": 11.425181,
    "vx": 0.00005261,
    "vy": 0.00000538,
    "vz": 0.00000822,
    "rarad": 1.77201369380663,
    "decrad": 0.56821285295695,
    "pmrarad": -0.0000024788038673125,
    "pmdecrad": 4.59419139e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118440,
    "base": "NN 3409",
    "lum": 0.00532108259266793,
    "ra": 6.768594,
    "dec": 32.556198,
    "pmra": -511.29,
    "pmdec": 94.76,
    "rv": 0,
    "Asset Name": "ASTRID 002155",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Medium Bob",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005261,
    "y_1": 0.00000538,
    "z_1": 0.00000822,
    "Distance in Parsecs_1": 21.2314,
    "HYG Star ID_1": 118440,
    "Gliese Catalog_1": "NN 3409B"
  },
  {
    "Astrid #": "ASTRID 002156",
    "HYG Star ID": 92129,
    "Hipparcos Catalogue": 92417,
    "Gliese Catalog": "Gl 730",
    "Distance in Parsecs": 21.2404,
    "mag": 10.73,
    "Absolute Visual Magnitude": 9.094,
    "Spectral Type": "M2",
    "Color Index": 1.446,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.59184,
    "y": -20.706514,
    "z": 1.144255,
    "vx": -0.00001796,
    "vy": -0.00000483,
    "vz": -0.00004194,
    "rarad": 4.93061571021605,
    "decrad": 0.05389775048226,
    "pmrarad": -8.74603879722222e-7,
    "pmdecrad": -0.000001973773456,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 92129,
    "lum": 0.0200631906479057,
    "var_min": 10.785,
    "var_max": 10.675,
    "ra": 18.833565,
    "dec": 3.088114,
    "pmra": -180.4,
    "pmdec": -407.12,
    "rv": -1.4,
    "Asset Name": "ASTRID 002156",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Laughing",
    "Hair": "Spiky",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00001796,
    "y_1": -0.00000483,
    "z_1": -0.00004194,
    "Distance in Parsecs_1": 21.2404,
    "HYG Star ID_1": 92129,
    "Hipparcos Catalogue_1": 92417,
    "Gliese Catalog_1": "Gl 730"
  },
  {
    "Astrid #": "ASTRID 002157",
    "HYG Star ID": 98159,
    "Hipparcos Catalogue": 98470,
    "Henry Draper Catalogue": 189245,
    "Harvard Revised Catalogue": 7631,
    "Gliese Catalog": "Gl 773.4",
    "Distance in Parsecs": 21.2495,
    "mag": 5.65,
    "Absolute Visual Magnitude": 4.013,
    "Spectral Type": "F7V",
    "Color Index": 0.498,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.861455,
    "y": -15.296423,
    "z": -11.791191,
    "vx": -1.3e-7,
    "vy": 0.0000271,
    "vz": -0.00002014,
    "rarad": 5.23745928826836,
    "decrad": -0.588236203452298,
    "pmrarad": 6.34087812798611e-7,
    "pmdecrad": -0.000001402565977,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 98159,
    "lum": 2.16172278433547,
    "ra": 20.005621,
    "dec": -33.703452,
    "pmra": 130.79,
    "pmdec": -289.3,
    "rv": -8.2,
    "Asset Name": "ASTRID 002157",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Undercut",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -1.3e-7,
    "y_1": 0.0000271,
    "z_1": -0.00002014,
    "Distance in Parsecs_1": 21.2495,
    "HYG Star ID_1": 98159,
    "Hipparcos Catalogue_1": 98470,
    "Henry Draper Catalogue_1": 189245,
    "Harvard Revised Catalogue_1": 7631,
    "Gliese Catalog_1": "Gl 773.4"
  },
  {
    "Astrid #": "ASTRID 002158",
    "HYG Star ID": 106461,
    "Hipparcos Catalogue": 106803,
    "Gliese Catalog": "NN 4206",
    "Distance in Parsecs": 21.2495,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.993,
    "Spectral Type": "M2",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.659978,
    "y": -5.467456,
    "z": -19.051848,
    "vx": -0.00001799,
    "vy": -0.00000937,
    "vz": -0.00000455,
    "rarad": 5.66328728650412,
    "decrad": -1.11198290627228,
    "pmrarad": -8.50799528006944e-7,
    "pmdecrad": -4.83116832e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 106461,
    "lum": 0.0220191221148447,
    "var_min": 10.719,
    "var_max": 10.549,
    "ra": 21.632164,
    "dec": -63.711927,
    "pmra": -175.49,
    "pmdec": -99.65,
    "rv": 0,
    "Asset Name": "ASTRID 002158",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Braided Ponytail",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00001799,
    "y_1": -0.00000937,
    "z_1": -0.00000455,
    "Distance in Parsecs_1": 21.2495,
    "HYG Star ID_1": 106461,
    "Hipparcos Catalogue_1": 106803,
    "Gliese Catalog_1": "NN 4206"
  },
  {
    "Astrid #": "ASTRID 002159",
    "HYG Star ID": 118667,
    "Gliese Catalog": "Gl 379B",
    "Distance in Parsecs": 21.2495,
    "mag": 10.3,
    "Absolute Visual Magnitude": 8.663,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -4.844555,
    "y": 2.494067,
    "z": 20.539016,
    "vx": 0.00001274,
    "vy": -0.00003189,
    "vz": 0.00000688,
    "rarad": 2.66600678001494,
    "decrad": 1.31147667607355,
    "pmrarad": 0.00000105956029885416,
    "pmdecrad": 0.000001262721471,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 49727,
    "base": "Gl 379",
    "lum": 0.0298400811593021,
    "ra": 10.183396,
    "dec": 75.142078,
    "pmra": 218.55,
    "pmdec": 260.45,
    "rv": 0,
    "Asset Name": "ASTRID 002159",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Pixie",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001274,
    "y_1": -0.00003189,
    "z_1": 0.00000688,
    "Distance in Parsecs_1": 21.2495,
    "HYG Star ID_1": 118667,
    "Gliese Catalog_1": "Gl 379B"
  },
  {
    "Astrid #": "ASTRID 002160",
    "HYG Star ID": 2756,
    "Hipparcos Catalogue": 2762,
    "Henry Draper Catalogue": 3196,
    "Harvard Revised Catalogue": 142,
    "Gliese Catalog": "Gl 23A",
    "Distance in Parsecs": 21.254,
    "mag": 5.2,
    "Absolute Visual Magnitude": 3.563,
    "Spectral Type": "F8V...",
    "Color Index": 0.567,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.961944,
    "y": 3.249574,
    "z": -1.331907,
    "vx": 0.00000463,
    "vy": 0.00004322,
    "vz": -0.00000447,
    "rarad": 0.153798322273612,
    "decrad": -0.0627070001010537,
    "pmrarad": 0.00000197648841288888,
    "pmdecrad": -1.76811549e-7,
    "flam": 13,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 2756,
    "base": "Gl  23",
    "lum": 3.27189983623179,
    "var": "BU",
    "var_min": 5.21,
    "var_max": 5.19,
    "bf": "13    Cet",
    "ra": 0.587466,
    "dec": -3.592846,
    "pmra": 407.68,
    "pmdec": -36.47,
    "rv": 11.2,
    "Asset Name": "ASTRID 002160",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Star of Culture",
    "Hair": "Manbun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000463,
    "y_1": 0.00004322,
    "z_1": -0.00000447,
    "Distance in Parsecs_1": 21.254,
    "HYG Star ID_1": 2756,
    "Hipparcos Catalogue_1": 2762,
    "Henry Draper Catalogue_1": 3196,
    "Harvard Revised Catalogue_1": 142,
    "Gliese Catalog_1": "Gl 23A"
  },
  {
    "Astrid #": "ASTRID 002161",
    "HYG Star ID": 12077,
    "Hipparcos Catalogue": 12110,
    "Henry Draper Catalogue": 16270,
    "Gliese Catalog": "GJ 1048",
    "Distance in Parsecs": 21.2721,
    "mag": 8.34,
    "Absolute Visual Magnitude": 6.701,
    "Spectral Type": "K3V",
    "Color Index": 1.069,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.157256,
    "y": 12.27552,
    "z": -8.48949,
    "vx": -0.00000507,
    "vy": 0.00000711,
    "vz": 0.00000123,
    "rarad": 0.680734543863786,
    "decrad": -0.410524587827598,
    "pmrarad": 4.09813004173611e-7,
    "pmdecrad": 6.3219703e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12077,
    "lum": 0.181802562377426,
    "ra": 2.600214,
    "dec": -23.521326,
    "pmra": 84.53,
    "pmdec": 13.04,
    "rv": 0,
    "Asset Name": "ASTRID 002161",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Bangs",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000507,
    "y_1": 0.00000711,
    "z_1": 0.00000123,
    "Distance in Parsecs_1": 21.2721,
    "HYG Star ID_1": 12077,
    "Hipparcos Catalogue_1": 12110,
    "Henry Draper Catalogue_1": 16270,
    "Gliese Catalog_1": "GJ 1048"
  },
  {
    "Astrid #": "ASTRID 002162",
    "HYG Star ID": 39783,
    "Hipparcos Catalogue": 39896,
    "Gliese Catalog": "GJ 1108A",
    "Distance in Parsecs": 21.2721,
    "mag": 9.9,
    "Absolute Visual Magnitude": 8.261,
    "Spectral Type": "M0.5V:e",
    "Color Index": 1.4,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.535293,
    "y": 15.121293,
    "z": 11.52946,
    "vx": -0.00000569,
    "vy": 0.00001483,
    "vz": -0.00001661,
    "rarad": 2.13340353036408,
    "decrad": 0.572813039116002,
    "pmrarad": -1.45444104166666e-7,
    "pmdecrad": -0.000001053015314,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 39783,
    "base": "GJ 1108",
    "lum": 0.0432115654470299,
    "var": "FP",
    "var_min": 10.038,
    "var_max": 9.798,
    "ra": 8.149001,
    "dec": 32.81977,
    "pmra": -30,
    "pmdec": -217.2,
    "rv": 4,
    "Asset Name": "ASTRID 002162",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Frizzy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00000569,
    "y_1": 0.00001483,
    "z_1": -0.00001661,
    "Distance in Parsecs_1": 21.2721,
    "HYG Star ID_1": 39783,
    "Hipparcos Catalogue_1": 39896,
    "Gliese Catalog_1": "GJ 1108A"
  },
  {
    "Astrid #": "ASTRID 002163",
    "HYG Star ID": 948,
    "Hipparcos Catalogue": 950,
    "Henry Draper Catalogue": 739,
    "Harvard Revised Catalogue": 35,
    "Gliese Catalog": "NN 3013",
    "Distance in Parsecs": 21.2766,
    "mag": 5.24,
    "Absolute Visual Magnitude": 3.6,
    "Spectral Type": "F3/F5V",
    "Color Index": 0.459,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.377471,
    "y": 0.890451,
    "z": -12.244148,
    "vx": -0.00000543,
    "vy": 0.00001718,
    "vz": 0.00001754,
    "rarad": 0.0511953526065131,
    "decrad": -0.613188611588841,
    "pmrarad": 8.19383601506944e-7,
    "pmdecrad": 5.51475561e-7,
    "bayer": "The",
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 948,
    "lum": 3.16227766016837,
    "bf": "The Scl",
    "ra": 0.195552,
    "dec": -35.133119,
    "pmra": 169.01,
    "pmdec": 113.75,
    "rv": -13.5,
    "Asset Name": "ASTRID 002163",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Unkempt",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00000543,
    "y_1": 0.00001718,
    "z_1": 0.00001754,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 948,
    "Hipparcos Catalogue_1": 950,
    "Henry Draper Catalogue_1": 739,
    "Harvard Revised Catalogue_1": 35,
    "Gliese Catalog_1": "NN 3013"
  },
  {
    "Astrid #": "ASTRID 002164",
    "HYG Star ID": 118133,
    "Gliese Catalog": "NN 3150",
    "Distance in Parsecs": 21.2766,
    "mag": 14.17,
    "Absolute Visual Magnitude": 12.53,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.983571,
    "y": 11.091645,
    "z": 8.613626,
    "vx": -0.00001269,
    "vy": 0.00002463,
    "vz": -0.00000816,
    "rarad": 0.606641484941565,
    "decrad": 0.4168042359906,
    "pmrarad": 0.00000129105883131944,
    "pmdecrad": -4.19480188e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118133,
    "lum": 0.000847227414140596,
    "ra": 2.3172,
    "dec": 23.881124,
    "pmra": 266.3,
    "pmdec": -86.52,
    "rv": 0,
    "Asset Name": "ASTRID 002164",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Frizzy",
    "Outfit": "Mecha",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001269,
    "y_1": 0.00002463,
    "z_1": -0.00000816,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118133,
    "Gliese Catalog_1": "NN 3150"
  },
  {
    "Astrid #": "ASTRID 002165",
    "HYG Star ID": 118170,
    "Gliese Catalog": "NN 3179B",
    "Distance in Parsecs": 21.2766,
    "mag": 15,
    "Absolute Visual Magnitude": 13.36,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.29116,
    "y": 9.961318,
    "z": 15.032483,
    "vx": -0.00002135,
    "vy": 0.00003782,
    "vz": -0.00000902,
    "rarad": 0.722864903681236,
    "decrad": 0.7845779559988,
    "pmrarad": 0.00000199675362473611,
    "pmdecrad": -5.99060024e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118170,
    "base": "NN 3179",
    "lum": 0.000394457302075278,
    "ra": 2.761141,
    "dec": 44.953006,
    "pmra": 411.86,
    "pmdec": -123.56,
    "rv": 0,
    "Asset Name": "ASTRID 002165",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Honest Lad",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002135,
    "y_1": 0.00003782,
    "z_1": -0.00000902,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118170,
    "Gliese Catalog_1": "NN 3179B"
  },
  {
    "Astrid #": "ASTRID 002166",
    "HYG Star ID": 118234,
    "Gliese Catalog": "NN 3236",
    "Distance in Parsecs": 21.2766,
    "mag": 14.17,
    "Absolute Visual Magnitude": 12.53,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.411936,
    "y": 6.140323,
    "z": 19.88781,
    "vx": -0.00000792,
    "vy": 0.00001862,
    "vz": -0.00000399,
    "rarad": 0.947675862899319,
    "decrad": 1.20748962735015,
    "pmrarad": 8.13177986395833e-7,
    "pmdecrad": -5.28092997e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118234,
    "lum": 0.000847227414140596,
    "ra": 3.619855,
    "dec": 69.184059,
    "pmra": 167.73,
    "pmdec": -108.93,
    "rv": 0,
    "Asset Name": "ASTRID 002166",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Maniacal",
    "Hair": "Slick",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000792,
    "y_1": 0.00001862,
    "z_1": -0.00000399,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118234,
    "Gliese Catalog_1": "NN 3236"
  },
  {
    "Astrid #": "ASTRID 002167",
    "HYG Star ID": 118388,
    "Gliese Catalog": "NN 3362",
    "Distance in Parsecs": 21.2766,
    "mag": 11.36,
    "Absolute Visual Magnitude": 9.72,
    "Spectral Type": "M3",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.786161,
    "y": 20.683104,
    "z": 4.659673,
    "vx": 0.00000406,
    "vy": 0.00000526,
    "vz": -0.00002491,
    "rarad": 1.48465182366602,
    "decrad": 0.2207941710658,
    "pmrarad": -1.68666679465277e-7,
    "pmdecrad": -0.000001200238684,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118388,
    "lum": 0.011271974561755,
    "ra": 5.670952,
    "dec": 12.650574,
    "pmra": -34.79,
    "pmdec": -247.57,
    "rv": 0,
    "Asset Name": "ASTRID 002167",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Guilty Laugh",
    "Hair": "Antlers",
    "Outfit": "Toga",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000406,
    "y_1": 0.00000526,
    "z_1": -0.00002491,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118388,
    "Gliese Catalog_1": "NN 3362"
  },
  {
    "Astrid #": "ASTRID 002168",
    "HYG Star ID": 118639,
    "Gliese Catalog": "NN 3562",
    "Distance in Parsecs": 21.2766,
    "mag": 14.03,
    "Absolute Visual Magnitude": 12.39,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.117882,
    "y": 9.43736,
    "z": 13.8402,
    "vx": 0.00000545,
    "vy": 0.00001664,
    "vz": -0.00000618,
    "rarad": 2.51795942742138,
    "decrad": 0.7082284276459,
    "pmrarad": -7.84331572402777e-7,
    "pmdecrad": -3.82547082e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118639,
    "lum": 0.000963829023623969,
    "ra": 9.617897,
    "dec": 40.5785,
    "pmra": -161.78,
    "pmdec": -78.91,
    "rv": 0,
    "Asset Name": "ASTRID 002168",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Shinobi",
    "Hair": "Honest Lad",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000545,
    "y_1": 0.00001664,
    "z_1": -0.00000618,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118639,
    "Gliese Catalog_1": "NN 3562"
  },
  {
    "Astrid #": "ASTRID 002169",
    "HYG Star ID": 118660,
    "Gliese Catalog": "NN 3579",
    "Distance in Parsecs": 21.2766,
    "mag": 14.09,
    "Absolute Visual Magnitude": 12.45,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.511333,
    "y": 7.23996,
    "z": 15.612279,
    "vx": -0.00002177,
    "vy": 0.00000284,
    "vz": -0.00001876,
    "rarad": 2.61699361442394,
    "decrad": 0.82386468793245,
    "pmrarad": 3.96868478902777e-7,
    "pmdecrad": -0.000001298161351,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118660,
    "lum": 0.000912010839355909,
    "ra": 9.996179,
    "dec": 47.20397,
    "pmra": 81.86,
    "pmdec": -267.76,
    "rv": 0,
    "Asset Name": "ASTRID 002169",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Medium Bob",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002177,
    "y_1": 0.00000284,
    "z_1": -0.00001876,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118660,
    "Gliese Catalog_1": "NN 3579"
  },
  {
    "Astrid #": "ASTRID 002170",
    "HYG Star ID": 118770,
    "Gliese Catalog": "GJ 1149",
    "Distance in Parsecs": 21.2766,
    "mag": 16.39,
    "Absolute Visual Magnitude": 14.75,
    "Spectral Type": "DC9",
    "Color Index": 0.66,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -9.607968,
    "y": 0.597852,
    "z": 18.974278,
    "vx": -0.00003144,
    "vy": 0.00001666,
    "vz": -0.00001644,
    "rarad": 3.07948990038628,
    "decrad": 1.1012880810404,
    "pmrarad": -6.90132274270833e-7,
    "pmdecrad": -0.000001708139192,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118770,
    "lum": 0.000109647819614318,
    "ra": 11.762785,
    "dec": 63.099159,
    "pmra": -142.35,
    "pmdec": -352.33,
    "rv": 0,
    "Asset Name": "ASTRID 002170",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Scared",
    "Hair": "Unkempt",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003144,
    "y_1": 0.00001666,
    "z_1": -0.00001644,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118770,
    "Gliese Catalog_1": "GJ 1149"
  },
  {
    "Astrid #": "ASTRID 002171",
    "HYG Star ID": 118798,
    "Gliese Catalog": "NN 3705",
    "Distance in Parsecs": 21.2766,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.86,
    "Spectral Type": "DA4",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -21.217031,
    "y": -0.888934,
    "z": -1.319508,
    "vx": 0.0000024,
    "vy": -0.00003156,
    "vz": -0.00001736,
    "rarad": 3.18346523460198,
    "decrad": -0.06205668894455,
    "pmrarad": 0.00000148697203963194,
    "pmdecrad": -8.17458891e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118798,
    "lum": 0.00157036280433355,
    "ra": 12.159941,
    "dec": -3.555586,
    "pmra": 306.71,
    "pmdec": -168.61,
    "rv": 0,
    "Asset Name": "ASTRID 002171",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Middle Part",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000024,
    "y_1": -0.00003156,
    "z_1": -0.00001736,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118798,
    "Gliese Catalog_1": "NN 3705"
  },
  {
    "Astrid #": "ASTRID 002172",
    "HYG Star ID": 118829,
    "Gliese Catalog": "NN 3734",
    "Distance in Parsecs": 21.2766,
    "mag": 12.9,
    "Absolute Visual Magnitude": 11.26,
    "Spectral Type": "M4",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.743904,
    "y": -2.811211,
    "z": 7.414113,
    "vx": 6.5e-7,
    "vy": 0.00004472,
    "vz": 0.0000187,
    "rarad": 3.28303267560589,
    "decrad": 0.35593108314935,
    "pmrarad": -0.00000207674788202777,
    "pmdecrad": 9.37682987e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118829,
    "lum": 0.00272897778280804,
    "ra": 12.540261,
    "dec": 20.393349,
    "pmra": -428.36,
    "pmdec": 193.41,
    "rv": 0,
    "Asset Name": "ASTRID 002172",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Neko Ninja",
    "Hair": "Pompadour",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 6.5e-7,
    "y_1": 0.00004472,
    "z_1": 0.0000187,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118829,
    "Gliese Catalog_1": "NN 3734"
  },
  {
    "Astrid #": "ASTRID 002173",
    "HYG Star ID": 118837,
    "Gliese Catalog": "NN 3739",
    "Distance in Parsecs": 21.2766,
    "mag": 14.81,
    "Absolute Visual Magnitude": 13.17,
    "Spectral Type": "m",
    "Color Index": 1.63,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.914014,
    "y": -3.312095,
    "z": 9.164268,
    "vx": -0.00000927,
    "vy": 0.00001576,
    "vz": -0.00001343,
    "rarad": 3.31495258476824,
    "decrad": 0.44529095693595,
    "pmrarad": -8.04984635194444e-7,
    "pmdecrad": -6.99741281e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118837,
    "lum": 0.000469894108605215,
    "ra": 12.662186,
    "dec": 25.513292,
    "pmra": -166.04,
    "pmdec": -144.33,
    "rv": 0,
    "Asset Name": "ASTRID 002173",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "Wavy",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000927,
    "y_1": 0.00001576,
    "z_1": -0.00001343,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 118837,
    "Gliese Catalog_1": "NN 3739"
  },
  {
    "Astrid #": "ASTRID 002174",
    "HYG Star ID": 119052,
    "Gliese Catalog": "NN 3933",
    "Distance in Parsecs": 21.2766,
    "mag": 13.12,
    "Absolute Visual Magnitude": 11.48,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.057844,
    "y": -14.077252,
    "z": 13.76938,
    "vx": -0.00003435,
    "vy": -8e-7,
    "vz": -0.00002091,
    "rarad": 4.19253105229694,
    "decrad": 0.70385418687105,
    "pmrarad": -0.00000138283406104861,
    "pmdecrad": -0.000001289502579,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119052,
    "lum": 0.0022284351492703,
    "ra": 16.014289,
    "dec": 40.327874,
    "pmra": -285.23,
    "pmdec": -265.98,
    "rv": 0,
    "Asset Name": "ASTRID 002174",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Ponytail",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003435,
    "y_1": -8e-7,
    "z_1": -0.00002091,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119052,
    "Gliese Catalog_1": "NN 3933"
  },
  {
    "Astrid #": "ASTRID 002175",
    "HYG Star ID": 119073,
    "Gliese Catalog": "NN 3946",
    "Distance in Parsecs": 21.2766,
    "mag": 13.53,
    "Absolute Visual Magnitude": 11.89,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.398476,
    "y": -19.002681,
    "z": 1.805672,
    "vx": -0.00003784,
    "vy": 0.00001948,
    "vz": 0.00000809,
    "rarad": 4.25308161763397,
    "decrad": 0.0849687974183,
    "pmrarad": -0.0000020001473205,
    "pmdecrad": 3.81548366e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119073,
    "lum": 0.00152756605823807,
    "ra": 16.245575,
    "dec": 4.868353,
    "pmra": -412.56,
    "pmdec": 78.7,
    "rv": 0,
    "Asset Name": "ASTRID 002175",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Star of Culture",
    "Hair": "Slick",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003784,
    "y_1": 0.00001948,
    "z_1": 0.00000809,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119073,
    "Gliese Catalog_1": "NN 3946"
  },
  {
    "Astrid #": "ASTRID 002176",
    "HYG Star ID": 119123,
    "Gliese Catalog": "NN 3984",
    "Distance in Parsecs": 21.2766,
    "mag": 13.43,
    "Absolute Visual Magnitude": 11.79,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.357957,
    "y": -20.365754,
    "z": 3.036784,
    "vx": -0.00002459,
    "vy": 0.000004,
    "vz": -0.00001655,
    "rarad": 4.45513255254746,
    "decrad": 0.14321793009475,
    "pmrarad": -0.00000116558905079166,
    "pmdecrad": -7.86198105e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119123,
    "lum": 0.00167494287602643,
    "ra": 17.017353,
    "dec": 8.205783,
    "pmra": -240.42,
    "pmdec": -162.16,
    "rv": 0,
    "Asset Name": "ASTRID 002176",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "High Bun",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002459,
    "y_1": 0.000004,
    "z_1": -0.00001655,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119123,
    "Gliese Catalog_1": "NN 3984"
  },
  {
    "Astrid #": "ASTRID 002177",
    "HYG Star ID": 119157,
    "Gliese Catalog": "NN 4009",
    "Distance in Parsecs": 21.2766,
    "mag": 13.35,
    "Absolute Visual Magnitude": 11.71,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.314243,
    "y": -16.731755,
    "z": 12.937788,
    "vx": 7.5e-7,
    "vy": -0.00001204,
    "vz": -0.00001544,
    "rarad": 4.57494515214242,
    "decrad": 0.65363468619085,
    "pmrarad": 1.12282848416666e-7,
    "pmdecrad": -9.14276183e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119157,
    "lum": 0.00180301774085956,
    "ra": 17.475003,
    "dec": 37.450509,
    "pmra": 23.16,
    "pmdec": -188.58,
    "rv": 0,
    "Asset Name": "ASTRID 002177",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Middle Part",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 7.5e-7,
    "y_1": -0.00001204,
    "z_1": -0.00001544,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119157,
    "Gliese Catalog_1": "NN 4009"
  },
  {
    "Astrid #": "ASTRID 002178",
    "HYG Star ID": 119160,
    "Gliese Catalog": "NN 4011",
    "Distance in Parsecs": 21.2766,
    "mag": 12.74,
    "Absolute Visual Magnitude": 11.1,
    "Spectral Type": "m",
    "Color Index": 1.47,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.645049,
    "y": -13.458023,
    "z": 16.397229,
    "vx": -0.00000397,
    "vy": -0.00004195,
    "vz": -0.00003482,
    "rarad": 4.59075524871592,
    "decrad": 0.8798912657363,
    "pmrarad": 5.38143185416666e-8,
    "pmdecrad": -0.000002568945274,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119160,
    "lum": 0.00316227766016837,
    "ra": 17.535393,
    "dec": 50.414056,
    "pmra": 11.1,
    "pmdec": -529.88,
    "rv": 0,
    "Asset Name": "ASTRID 002178",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Ponytail",
    "Outfit": "Astro",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000397,
    "y_1": -0.00004195,
    "z_1": -0.00003482,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119160,
    "Gliese Catalog_1": "NN 4011"
  },
  {
    "Astrid #": "ASTRID 002179",
    "HYG Star ID": 119166,
    "Gliese Catalog": "NN 4015",
    "Distance in Parsecs": 21.2766,
    "mag": 14.08,
    "Absolute Visual Magnitude": 12.44,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.922706,
    "y": -19.619893,
    "z": 8.003543,
    "vx": 0.00000145,
    "vy": -0.00001102,
    "vz": -0.00002666,
    "rarad": 4.6147026727269,
    "decrad": 0.38565534849045,
    "pmrarad": 1.18294538055555e-7,
    "pmdecrad": -0.000001352310191,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119166,
    "lum": 0.000920449571753171,
    "ra": 17.626866,
    "dec": 22.096424,
    "pmra": 24.4,
    "pmdec": -278.93,
    "rv": 0,
    "Asset Name": "ASTRID 002179",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Bob",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000145,
    "y_1": -0.00001102,
    "z_1": -0.00002666,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119166,
    "Gliese Catalog_1": "NN 4015"
  },
  {
    "Astrid #": "ASTRID 002180",
    "HYG Star ID": 119244,
    "Gliese Catalog": "NN 4084",
    "Distance in Parsecs": 21.2766,
    "mag": 12.76,
    "Absolute Visual Magnitude": 11.12,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.328931,
    "y": -18.877693,
    "z": 8.808336,
    "vx": -0.00003649,
    "vy": -0.00001035,
    "vz": -0.00000423,
    "rarad": 4.93781508351197,
    "decrad": 0.4268348776332,
    "pmrarad": -0.00000178042976047222,
    "pmdecrad": -2.18607336e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119244,
    "lum": 0.00310455958812835,
    "ra": 18.861064,
    "dec": 24.455837,
    "pmra": -367.24,
    "pmdec": -45.09,
    "rv": 0,
    "Asset Name": "ASTRID 002180",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Innocent",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003649,
    "y_1": -0.00001035,
    "z_1": -0.00000423,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119244,
    "Gliese Catalog_1": "NN 4084"
  },
  {
    "Astrid #": "ASTRID 002181",
    "HYG Star ID": 119252,
    "Gliese Catalog": "NN 4089",
    "Distance in Parsecs": 21.2766,
    "mag": 13.95,
    "Absolute Visual Magnitude": 12.31,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.57792,
    "y": -14.235247,
    "z": 15.402919,
    "vx": 0.00000153,
    "vy": 0.00002439,
    "vz": 0.00002219,
    "rarad": 4.95862271944436,
    "decrad": 0.80949236796795,
    "pmrarad": 3.48968887263888e-7,
    "pmdecrad": 0.000001511639359,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119252,
    "lum": 0.00103752841581801,
    "ra": 18.940544,
    "dec": 46.380496,
    "pmra": 71.98,
    "pmdec": 311.8,
    "rv": 0,
    "Asset Name": "ASTRID 002181",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Anxious Laugh",
    "Hair": "Medium Bob",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000153,
    "y_1": 0.00002439,
    "z_1": 0.00002219,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119252,
    "Gliese Catalog_1": "NN 4089"
  },
  {
    "Astrid #": "ASTRID 002182",
    "HYG Star ID": 119286,
    "Gliese Catalog": "NN 4111",
    "Distance in Parsecs": 21.2766,
    "mag": 10.71,
    "Absolute Visual Magnitude": 9.07,
    "Spectral Type": "K7",
    "Color Index": 1.41,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.000427,
    "y": -19.536016,
    "z": 2.6516,
    "vx": 0.0000238,
    "vy": 0.00001278,
    "vz": 0.00002237,
    "rarad": 5.10107636986155,
    "decrad": 0.1249500202877,
    "pmrarad": 0.00000126269723100694,
    "pmdecrad": 0.000001059545754,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 119286,
    "lum": 0.0205116217882556,
    "ra": 19.484676,
    "dec": 7.159109,
    "pmra": 260.45,
    "pmdec": 218.55,
    "rv": 0,
    "Asset Name": "ASTRID 002182",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Lion Mane",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.0000238,
    "y_1": 0.00001278,
    "z_1": 0.00002237,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119286,
    "Gliese Catalog_1": "NN 4111"
  },
  {
    "Astrid #": "ASTRID 002183",
    "HYG Star ID": 119389,
    "Gliese Catalog": "NN 4179",
    "Distance in Parsecs": 21.2766,
    "mag": 10.33,
    "Absolute Visual Magnitude": 8.69,
    "Spectral Type": "K7",
    "Color Index": 1.42,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.749231,
    "y": -14.028603,
    "z": -6.192914,
    "vx": -0.000007,
    "vy": 0.00001725,
    "vz": -0.00005577,
    "rarad": 5.52282214906891,
    "decrad": -0.29534179168075,
    "pmrarad": 3.60701378333333e-7,
    "pmdecrad": -0.000002739793615,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 119389,
    "lum": 0.029107171180666,
    "ra": 21.095627,
    "dec": -16.921838,
    "pmra": 74.4,
    "pmdec": -565.12,
    "rv": 0,
    "Asset Name": "ASTRID 002183",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Grecian",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.000007,
    "y_1": 0.00001725,
    "z_1": -0.00005577,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119389,
    "Gliese Catalog_1": "NN 4179"
  },
  {
    "Astrid #": "ASTRID 002184",
    "HYG Star ID": 119437,
    "Gliese Catalog": "NN 4218",
    "Distance in Parsecs": 21.2766,
    "mag": 14.39,
    "Absolute Visual Magnitude": 12.75,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.575683,
    "y": -10.998199,
    "z": 7.548515,
    "vx": 0.00001448,
    "vy": 0.00001988,
    "vz": -0.00000282,
    "rarad": 5.69736439673342,
    "decrad": 0.36267887308475,
    "pmrarad": 0.00000115487466845138,
    "pmdecrad": -1.41798305e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119437,
    "lum": 0.000691830970918936,
    "ra": 21.762329,
    "dec": 20.779969,
    "pmra": 238.21,
    "pmdec": -29.25,
    "rv": 0,
    "Asset Name": "ASTRID 002184",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Spiky",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001448,
    "y_1": 0.00001988,
    "z_1": -0.00000282,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119437,
    "Gliese Catalog_1": "NN 4218"
  },
  {
    "Astrid #": "ASTRID 002185",
    "HYG Star ID": 119483,
    "Gliese Catalog": "NN 4269B",
    "Distance in Parsecs": 21.2766,
    "mag": 14,
    "Absolute Visual Magnitude": 12.36,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.061363,
    "y": -5.391723,
    "z": 17.357109,
    "vx": 0.00000562,
    "vy": 0.00002175,
    "vz": 0.00000317,
    "rarad": 5.82960044158819,
    "decrad": 0.95408348037685,
    "pmrarad": 0.00000103493176388194,
    "pmdecrad": 2.58027537e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119483,
    "base": "NN 4269",
    "lum": 0.000990831944892767,
    "ra": 22.267433,
    "dec": 54.664957,
    "pmra": 213.47,
    "pmdec": 53.22,
    "rv": 0,
    "Asset Name": "ASTRID 002185",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Undercut",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000562,
    "y_1": 0.00002175,
    "z_1": 0.00000317,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119483,
    "Gliese Catalog_1": "NN 4269B"
  },
  {
    "Astrid #": "ASTRID 002186",
    "HYG Star ID": 119530,
    "Gliese Catalog": "NN 4297",
    "Distance in Parsecs": 21.2766,
    "mag": 12.91,
    "Absolute Visual Magnitude": 11.27,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.182527,
    "y": -5.590735,
    "z": 11.233795,
    "vx": 0.00000562,
    "vy": 0.0000488,
    "vz": 0.0000157,
    "rarad": 5.96859574508897,
    "decrad": 0.55622999418485,
    "pmrarad": 0.00000226306177946527,
    "pmdecrad": 8.68703697e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119530,
    "lum": 0.00270395836410884,
    "ra": 22.798356,
    "dec": 31.869631,
    "pmra": 466.79,
    "pmdec": 179.18,
    "rv": 0,
    "Asset Name": "ASTRID 002186",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Shinobi",
    "Hair": "Elegant",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000562,
    "y_1": 0.0000488,
    "z_1": 0.0000157,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119530,
    "Gliese Catalog_1": "NN 4297"
  },
  {
    "Astrid #": "ASTRID 002187",
    "HYG Star ID": 119564,
    "Gliese Catalog": "NN 4336",
    "Distance in Parsecs": 21.2766,
    "mag": 12.63,
    "Absolute Visual Magnitude": 10.99,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.578145,
    "y": -3.025425,
    "z": 4.481125,
    "vx": 0.00000474,
    "vy": 0.00006788,
    "vz": 0.00002414,
    "rarad": 6.13720617437139,
    "decrad": 0.21220183759125,
    "pmrarad": 0.00000318900742795833,
    "pmdecrad": 0.000001160711825,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119564,
    "lum": 0.00349945167028356,
    "ra": 23.442401,
    "dec": 12.15827,
    "pmra": 657.78,
    "pmdec": 239.41,
    "rv": 0,
    "Asset Name": "ASTRID 002187",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Undercut",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000474,
    "y_1": 0.00006788,
    "z_1": 0.00002414,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119564,
    "Gliese Catalog_1": "NN 4336"
  },
  {
    "Astrid #": "ASTRID 002188",
    "HYG Star ID": 119572,
    "Gliese Catalog": "NN 4342",
    "Distance in Parsecs": 21.2766,
    "mag": 13.21,
    "Absolute Visual Magnitude": 11.57,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.308502,
    "y": -1.972297,
    "z": 10.658446,
    "vx": 0.00001169,
    "vy": -0.00002249,
    "vz": -0.00002424,
    "rarad": 6.17588110471234,
    "decrad": 0.52469247781625,
    "pmrarad": -9.92025753152777e-7,
    "pmdecrad": -0.000001316443675,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119572,
    "lum": 0.00205116217882556,
    "ra": 23.590128,
    "dec": 30.062665,
    "pmra": -204.62,
    "pmdec": -271.54,
    "rv": 0,
    "Asset Name": "ASTRID 002188",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Slick",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001169,
    "y_1": -0.00002249,
    "z_1": -0.00002424,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119572,
    "Gliese Catalog_1": "NN 4342"
  },
  {
    "Astrid #": "ASTRID 002189",
    "HYG Star ID": 119594,
    "Gliese Catalog": "NN 4363",
    "Distance in Parsecs": 21.2766,
    "mag": 13.57,
    "Absolute Visual Magnitude": 11.93,
    "Spectral Type": "dM4  :",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 20.924183,
    "y": -0.981973,
    "z": 3.729342,
    "vx": 0.00000631,
    "vy": 0.00000459,
    "vz": -0.00003419,
    "rarad": 6.23628953069168,
    "decrad": 0.17618918579855,
    "pmrarad": 2.29413833638888e-7,
    "pmdecrad": -0.000001632324029,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119594,
    "lum": 0.00147231250243271,
    "ra": 23.820871,
    "dec": 10.094897,
    "pmra": 47.32,
    "pmdec": -336.69,
    "rv": 0,
    "Asset Name": "ASTRID 002189",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Braid",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000631,
    "y_1": 0.00000459,
    "z_1": -0.00003419,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119594,
    "Gliese Catalog_1": "NN 4363"
  },
  {
    "Astrid #": "ASTRID 002190",
    "HYG Star ID": 119596,
    "Gliese Catalog": "NN 4365",
    "Distance in Parsecs": 21.2766,
    "mag": 15.72,
    "Absolute Visual Magnitude": 14.08,
    "Spectral Type": "DA9",
    "Color Index": 0.58,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 18.48792,
    "y": -0.815004,
    "z": 10.498873,
    "vx": 0.00002603,
    "vy": -0.0000114,
    "vz": -0.0000467,
    "rarad": 6.23913437657669,
    "decrad": 0.5160482920901,
    "pmrarad": -4.81468466159722e-7,
    "pmdecrad": -0.000002523998198,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119596,
    "lum": 0.000203235701093622,
    "ra": 23.831738,
    "dec": 29.567389,
    "pmra": -99.31,
    "pmdec": -520.61,
    "rv": 0,
    "Asset Name": "ASTRID 002190",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Honest Lad",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002603,
    "y_1": -0.0000114,
    "z_1": -0.0000467,
    "Distance in Parsecs_1": 21.2766,
    "HYG Star ID_1": 119596,
    "Gliese Catalog_1": "NN 4365"
  },
  {
    "Astrid #": "ASTRID 002191",
    "HYG Star ID": 112510,
    "Hipparcos Catalogue": 112870,
    "Henry Draper Catalogue": 216259,
    "Gliese Catalog": "Wo 9798",
    "Distance in Parsecs": 21.2811,
    "mag": 8.29,
    "Absolute Visual Magnitude": 6.65,
    "Spectral Type": "K0",
    "Color Index": 0.849,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.734431,
    "y": -6.086254,
    "z": 5.137541,
    "vx": 0.00000589,
    "vy": 0.0000419,
    "vz": 0.00001979,
    "rarad": 5.9840322581911,
    "decrad": 0.243822188263892,
    "pmrarad": 0.00000196310755530555,
    "pmdecrad": 9.78887302e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112510,
    "lum": 0.190546071796324,
    "ra": 22.85732,
    "dec": 13.969982,
    "pmra": 404.92,
    "pmdec": 201.91,
    "rv": -1.7,
    "Asset Name": "ASTRID 002191",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Determined Neko",
    "Hair": "Middle Part",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000589,
    "y_1": 0.0000419,
    "z_1": 0.00001979,
    "Distance in Parsecs_1": 21.2811,
    "HYG Star ID_1": 112510,
    "Hipparcos Catalogue_1": 112870,
    "Henry Draper Catalogue_1": 216259,
    "Gliese Catalog_1": "Wo 9798"
  },
  {
    "Astrid #": "ASTRID 002192",
    "HYG Star ID": 14597,
    "Hipparcos Catalogue": 14633,
    "Gliese Catalog": "Gl 122",
    "Distance in Parsecs": 21.2902,
    "mag": 9.82,
    "Absolute Visual Magnitude": 8.179,
    "Spectral Type": "M0",
    "Color Index": 1.321,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.484956,
    "y": 3.772466,
    "z": 20.66178,
    "vx": 0.00002424,
    "vy": 0.00005555,
    "vz": 0.0000216,
    "rarad": 0.824967754413095,
    "decrad": 1.32716861839481,
    "pmrarad": 9.34332925166666e-7,
    "pmdecrad": -0.000002365212021,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 14597,
    "lum": 0.0466015111702183,
    "ra": 3.151145,
    "dec": 76.041161,
    "pmra": 192.72,
    "pmdec": -487.86,
    "rv": 34,
    "Asset Name": "ASTRID 002192",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Anxious Laugh",
    "Hair": "Little Crown",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00002424,
    "y_1": 0.00005555,
    "z_1": 0.0000216,
    "Distance in Parsecs_1": 21.2902,
    "HYG Star ID_1": 14597,
    "Hipparcos Catalogue_1": 14633,
    "Gliese Catalog_1": "Gl 122"
  },
  {
    "Astrid #": "ASTRID 002193",
    "HYG Star ID": 77172,
    "Hipparcos Catalogue": 77408,
    "Henry Draper Catalogue": 141272,
    "Gliese Catalog": "NN 3917",
    "Distance in Parsecs": 21.2902,
    "mag": 7.44,
    "Absolute Visual Magnitude": 5.799,
    "Spectral Type": "G8V",
    "Color Index": 0.801,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.578688,
    "y": -17.856506,
    "z": 0.583954,
    "vx": 2.8e-7,
    "vy": 0.00003386,
    "vz": -0.000018,
    "rarad": 4.13711849280929,
    "decrad": 0.0274320255380299,
    "pmrarad": -8.54193223770833e-7,
    "pmdecrad": -8.08281368e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 77172,
    "lum": 0.417253511231793,
    "var": "V382",
    "var_min": 7.472,
    "var_max": 7.412,
    "ra": 15.802629,
    "dec": 1.571739,
    "pmra": -176.19,
    "pmdec": -166.72,
    "rv": -28.4,
    "Asset Name": "ASTRID 002193",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Stunned",
    "Hair": "Wavy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 2.8e-7,
    "y_1": 0.00003386,
    "z_1": -0.000018,
    "Distance in Parsecs_1": 21.2902,
    "HYG Star ID_1": 77172,
    "Hipparcos Catalogue_1": 77408,
    "Henry Draper Catalogue_1": 141272,
    "Gliese Catalog_1": "NN 3917"
  },
  {
    "Astrid #": "ASTRID 002194",
    "HYG Star ID": 31631,
    "Hipparcos Catalogue": 31711,
    "Henry Draper Catalogue": 48189,
    "Harvard Revised Catalogue": 2468,
    "Gliese Catalog": "NN 3400A",
    "Distance in Parsecs": 21.2947,
    "mag": 6.15,
    "Absolute Visual Magnitude": 4.509,
    "Spectral Type": "G1/G2V",
    "Color Index": 0.624,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.675528,
    "y": 10.010936,
    "z": -18.720319,
    "vx": 0.00000141,
    "vy": 0.00002289,
    "vz": -0.00002546,
    "rarad": 1.73663134454645,
    "decrad": -1.07396020440319,
    "pmrarad": -2.42794691222222e-7,
    "pmdecrad": 3.52411064e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 31631,
    "base": "NN 3400",
    "lum": 1.36898913070073,
    "var": "AK",
    "var_min": 6.169,
    "var_max": 6.139,
    "ra": 6.633443,
    "dec": -61.533387,
    "pmra": -50.08,
    "pmdec": 72.69,
    "rv": 32.3,
    "Asset Name": "ASTRID 002194",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Elegant",
    "Outfit": "Astro",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00000141,
    "y_1": 0.00002289,
    "z_1": -0.00002546,
    "Distance in Parsecs_1": 21.2947,
    "HYG Star ID_1": 31631,
    "Hipparcos Catalogue_1": 31711,
    "Henry Draper Catalogue_1": 48189,
    "Harvard Revised Catalogue_1": 2468,
    "Gliese Catalog_1": "NN 3400A"
  },
  {
    "Astrid #": "ASTRID 002195",
    "HYG Star ID": 110633,
    "Hipparcos Catalogue": 110980,
    "Distance in Parsecs": 21.3174,
    "mag": 10.51,
    "Absolute Visual Magnitude": 8.866,
    "Color Index": 1.431,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.654155,
    "y": -8.231817,
    "z": 0.618788,
    "vx": 0.00000233,
    "vy": 0.00000405,
    "vz": -0.00002031,
    "rarad": 5.88654940616647,
    "decrad": 0.0290314385684512,
    "pmrarad": 2.17390454361111e-7,
    "pmdecrad": -9.53192177e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 110633,
    "lum": 0.0247514131807431,
    "ra": 22.484962,
    "dec": 1.663379,
    "pmra": 44.84,
    "pmdec": -196.61,
    "rv": 0,
    "Asset Name": "ASTRID 002195",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Pompadour",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000233,
    "y_1": 0.00000405,
    "z_1": -0.00002031,
    "Distance in Parsecs_1": 21.3174,
    "HYG Star ID_1": 110633,
    "Hipparcos Catalogue_1": 110980
  },
  {
    "Astrid #": "ASTRID 002196",
    "HYG Star ID": 66013,
    "Hipparcos Catalogue": 66222,
    "Gliese Catalog": "GJ 1172",
    "Distance in Parsecs": 21.322,
    "mag": 9.95,
    "Absolute Visual Magnitude": 8.306,
    "Spectral Type": "M0.5V:",
    "Color Index": 1.385,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.475552,
    "y": -8.504447,
    "z": 1.735012,
    "vx": -0.00000767,
    "vy": -0.00002069,
    "vz": -0.00001159,
    "rarad": 3.55330866753168,
    "decrad": 0.0814614834073837,
    "pmrarad": 7.45546477958333e-7,
    "pmdecrad": -6.00296299e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66013,
    "lum": 0.0414572031786725,
    "ra": 13.572639,
    "dec": 4.667399,
    "pmra": 153.78,
    "pmdec": -123.82,
    "rv": 14,
    "Asset Name": "ASTRID 002196",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Charmer",
    "Outfit": "Sharky",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000767,
    "y_1": -0.00002069,
    "z_1": -0.00001159,
    "Distance in Parsecs_1": 21.322,
    "HYG Star ID_1": 66013,
    "Hipparcos Catalogue_1": 66222,
    "Gliese Catalog_1": "GJ 1172"
  },
  {
    "Astrid #": "ASTRID 002197",
    "HYG Star ID": 119067,
    "Gliese Catalog": "GJ 1198",
    "Distance in Parsecs": 21.322,
    "mag": 14.7,
    "Absolute Visual Magnitude": 13.056,
    "Spectral Type": "M2",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.825371,
    "y": -18.524646,
    "z": -3.863589,
    "vx": -0.0000207,
    "vy": 0.00003856,
    "vz": -0.0001325,
    "rarad": 4.22472385536241,
    "decrad": -0.1822085451667,
    "pmrarad": -0.00000170499275177777,
    "pmdecrad": -0.000006319003334,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119067,
    "lum": 0.000521915265835448,
    "ra": 16.137256,
    "dec": -10.439781,
    "pmra": -351.68,
    "pmdec": -1303.39,
    "rv": 0,
    "Asset Name": "ASTRID 002197",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Windy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000207,
    "y_1": 0.00003856,
    "z_1": -0.0001325,
    "Distance in Parsecs_1": 21.322,
    "HYG Star ID_1": 119067,
    "Gliese Catalog_1": "GJ 1198"
  },
  {
    "Astrid #": "ASTRID 002198",
    "HYG Star ID": 119263,
    "Gliese Catalog": "Gl 747.1",
    "Distance in Parsecs": 21.322,
    "mag": 12.02,
    "Absolute Visual Magnitude": 10.376,
    "Spectral Type": "M3",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.143504,
    "y": -19.682796,
    "z": -5.428869,
    "vx": 0.00001559,
    "vy": 0.00001871,
    "vz": -0.00005022,
    "rarad": 5.01493103773775,
    "decrad": -0.2574480362567,
    "pmrarad": 9.594947551875e-7,
    "pmdecrad": -0.000002435825134,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119263,
    "lum": 0.00616027358327237,
    "ra": 19.155626,
    "dec": -14.750686,
    "pmra": 197.91,
    "pmdec": -502.43,
    "rv": 0,
    "Asset Name": "ASTRID 002198",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Good Boy",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001559,
    "y_1": 0.00001871,
    "z_1": -0.00005022,
    "Distance in Parsecs_1": 21.322,
    "HYG Star ID_1": 119263,
    "Gliese Catalog_1": "Gl 747.1"
  },
  {
    "Astrid #": "ASTRID 002199",
    "HYG Star ID": 19809,
    "Hipparcos Catalogue": 19859,
    "Henry Draper Catalogue": 26923,
    "Harvard Revised Catalogue": 1322,
    "Distance in Parsecs": 21.3311,
    "mag": 6.32,
    "Absolute Visual Magnitude": 4.675,
    "Spectral Type": "G0IV...",
    "Color Index": 0.57,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.339667,
    "y": 19.039395,
    "z": 2.298873,
    "vx": 0.0000071,
    "vy": -0.0000112,
    "vz": -0.00001202,
    "rarad": 1.11474186939367,
    "decrad": 0.107981041663307,
    "pmrarad": -5.30240722423611e-7,
    "pmdecrad": -5.25295622e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 19809,
    "lum": 1.17489755493952,
    "var": "V774",
    "var_min": 6.333,
    "var_max": 6.313,
    "ra": 4.258,
    "dec": 6.186858,
    "pmra": -109.37,
    "pmdec": -108.35,
    "rv": -8,
    "Asset Name": "ASTRID 002199",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Elegant",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.0000071,
    "y_1": -0.0000112,
    "z_1": -0.00001202,
    "Distance in Parsecs_1": 21.3311,
    "HYG Star ID_1": 19809,
    "Hipparcos Catalogue_1": 19859,
    "Henry Draper Catalogue_1": 26923,
    "Harvard Revised Catalogue_1": 1322
  },
  {
    "Astrid #": "ASTRID 002200",
    "HYG Star ID": 114592,
    "Hipparcos Catalogue": 114954,
    "Distance in Parsecs": 21.3402,
    "mag": 10.86,
    "Absolute Visual Magnitude": 9.214,
    "Spectral Type": "K7",
    "Color Index": 1.395,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.241954,
    "y": -3.652877,
    "z": -8.47395,
    "vx": -0.00000387,
    "vy": 0.00003318,
    "vz": -0.0000231,
    "rarad": 6.09557741939502,
    "decrad": -0.4083424188046,
    "pmrarad": 0.00000149375943115972,
    "pmdecrad": -0.00000117940624,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 114592,
    "lum": 0.0179638739906388,
    "ra": 23.283391,
    "dec": -23.396297,
    "pmra": 308.11,
    "pmdec": -243.27,
    "rv": 0,
    "Asset Name": "ASTRID 002200",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Guilty Laugh",
    "Hair": "Goddess",
    "Outfit": "Toga",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000387,
    "y_1": 0.00003318,
    "z_1": -0.0000231,
    "Distance in Parsecs_1": 21.3402,
    "HYG Star ID_1": 114592,
    "Hipparcos Catalogue_1": 114954
  },
  {
    "Astrid #": "ASTRID 002201",
    "HYG Star ID": 26779,
    "Hipparcos Catalogue": 26844,
    "Gliese Catalog": "Wo 9188",
    "Distance in Parsecs": 21.3538,
    "mag": 10.72,
    "Absolute Visual Magnitude": 9.073,
    "Spectral Type": "M0",
    "Color Index": 1.689,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.617424,
    "y": 20.529686,
    "z": 5.648073,
    "vx": -0.00000803,
    "vy": 0.00000184,
    "vz": -0.00000439,
    "rarad": 1.49217395724505,
    "decrad": 0.267685045043125,
    "pmrarad": 3.81742292069444e-7,
    "pmdecrad": -2.133665e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 26779,
    "lum": 0.0204550243110935,
    "var_min": 10.787,
    "var_max": 10.657,
    "ra": 5.699685,
    "dec": 15.337223,
    "pmra": 78.74,
    "pmdec": -44.01,
    "rv": 0,
    "Asset Name": "ASTRID 002201",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Grecian",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000803,
    "y_1": 0.00000184,
    "z_1": -0.00000439,
    "Distance in Parsecs_1": 21.3538,
    "HYG Star ID_1": 26779,
    "Hipparcos Catalogue_1": 26844,
    "Gliese Catalog_1": "Wo 9188"
  },
  {
    "Astrid #": "ASTRID 002202",
    "HYG Star ID": 19898,
    "Hipparcos Catalogue": 19948,
    "Gliese Catalog": "GJ 2033",
    "Distance in Parsecs": 21.3584,
    "mag": 10.91,
    "Absolute Visual Magnitude": 9.262,
    "Color Index": 1.512,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.082138,
    "y": 18.765274,
    "z": -4.643329,
    "vx": 0.00000906,
    "vy": 9e-7,
    "vz": 0.00002135,
    "rarad": 1.12004151310706,
    "decrad": -0.219150610946342,
    "pmrarad": -3.63416334944444e-7,
    "pmdecrad": 0.000001024120418,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 19898,
    "lum": 0.0171869969539773,
    "var_min": 10.986,
    "var_max": 10.766,
    "ra": 4.278243,
    "dec": -12.556405,
    "pmra": -74.96,
    "pmdec": 211.24,
    "rv": 0,
    "Asset Name": "ASTRID 002202",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Hachimaki",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000906,
    "y_1": 9e-7,
    "z_1": 0.00002135,
    "Distance in Parsecs_1": 21.3584,
    "HYG Star ID_1": 19898,
    "Hipparcos Catalogue_1": 19948,
    "Gliese Catalog_1": "GJ 2033"
  },
  {
    "Astrid #": "ASTRID 002203",
    "HYG Star ID": 55459,
    "Hipparcos Catalogue": 55625,
    "Gliese Catalog": "GJ 2085",
    "Distance in Parsecs": 21.363,
    "mag": 11.18,
    "Absolute Visual Magnitude": 9.532,
    "Spectral Type": "M1V:",
    "Color Index": 1.482,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.861539,
    "y": 3.328137,
    "z": 3.18114,
    "vx": -0.00003858,
    "vy": 0.0001114,
    "vz": 0.00002884,
    "rarad": 2.983391697814,
    "decrad": 0.149461117556626,
    "pmrarad": -0.00000486573554215972,
    "pmdecrad": 9.47035043e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 55459,
    "lum": 0.013402937734183,
    "var_min": 11.26,
    "var_max": 11.11,
    "ra": 11.395717,
    "dec": 8.563491,
    "pmra": -1003.63,
    "pmdec": 195.34,
    "rv": 58,
    "Asset Name": "ASTRID 002203",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Braided Bun",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00003858,
    "y_1": 0.0001114,
    "z_1": 0.00002884,
    "Distance in Parsecs_1": 21.363,
    "HYG Star ID_1": 55459,
    "Hipparcos Catalogue_1": 55625,
    "Gliese Catalog_1": "GJ 2085"
  },
  {
    "Astrid #": "ASTRID 002204",
    "HYG Star ID": 50422,
    "Hipparcos Catalogue": 50564,
    "Henry Draper Catalogue": 89449,
    "Harvard Revised Catalogue": 4054,
    "Gliese Catalog": "Gl 388.1",
    "Distance in Parsecs": 21.3675,
    "mag": 4.78,
    "Absolute Visual Magnitude": 3.131,
    "Spectral Type": "F6IV",
    "Color Index": 0.452,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -18.248265,
    "y": 8.534898,
    "z": 7.122411,
    "vx": -0.00000195,
    "vy": 0.00002721,
    "vz": -0.00001888,
    "rarad": 2.70410958754889,
    "decrad": 0.339831559865847,
    "pmrarad": -0.00000111502298390972,
    "pmdecrad": -0.000001040701046,
    "flam": 40,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 50422,
    "lum": 4.87079666492536,
    "bf": "40    Leo",
    "ra": 10.328938,
    "dec": 19.470914,
    "pmra": -229.99,
    "pmdec": -214.66,
    "rv": 6.1,
    "Asset Name": "ASTRID 002204",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Neko Ninja",
    "Hair": "Side Part",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000195,
    "y_1": 0.00002721,
    "z_1": -0.00001888,
    "Distance in Parsecs_1": 21.3675,
    "HYG Star ID_1": 50422,
    "Hipparcos Catalogue_1": 50564,
    "Henry Draper Catalogue_1": 89449,
    "Harvard Revised Catalogue_1": 4054,
    "Gliese Catalog_1": "Gl 388.1"
  },
  {
    "Astrid #": "ASTRID 002205",
    "HYG Star ID": 118040,
    "Gliese Catalog": "GJ 1027",
    "Distance in Parsecs": 21.3675,
    "mag": 13.96,
    "Absolute Visual Magnitude": 12.311,
    "Spectral Type": "DA6",
    "Color Index": 0.31,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 20.466374,
    "y": 5.85275,
    "z": 1.891087,
    "vx": 0.00004916,
    "vy": 0.00004631,
    "vz": 0.00002966,
    "rarad": 0.278534973304252,
    "decrad": 0.08860595532265,
    "pmrarad": 0.00000145172608505555,
    "pmdecrad": 0.000001134226453,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118040,
    "lum": 0.001036573256767,
    "ra": 1.063925,
    "dec": 5.076747,
    "pmra": 299.44,
    "pmdec": 233.95,
    "rv": 61,
    "Asset Name": "ASTRID 002205",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Pompadour",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004916,
    "y_1": 0.00004631,
    "z_1": 0.00002966,
    "Distance in Parsecs_1": 21.3675,
    "HYG Star ID_1": 118040,
    "Gliese Catalog_1": "GJ 1027"
  },
  {
    "Astrid #": "ASTRID 002206",
    "HYG Star ID": 118181,
    "Gliese Catalog": "Gl 120",
    "Distance in Parsecs": 21.3675,
    "mag": 13.03,
    "Absolute Visual Magnitude": 11.381,
    "Spectral Type": "dM4",
    "Color Index": 1.56,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 15.003243,
    "y": 14.682221,
    "z": 4.001538,
    "vx": -0.00008764,
    "vy": 0.00017132,
    "vz": -0.00003195,
    "rarad": 0.774576807703131,
    "decrad": 0.1883620284647,
    "pmrarad": 0.00000860093406263194,
    "pmdecrad": -0.000001969870706,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118181,
    "lum": 0.00244118110605488,
    "ra": 2.958665,
    "dec": 10.792349,
    "pmra": 1774.07,
    "pmdec": -406.31,
    "rv": 49,
    "Asset Name": "ASTRID 002206",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Determined Neko",
    "Hair": "Audiohead",
    "Outfit": "Elven",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00008764,
    "y_1": 0.00017132,
    "z_1": -0.00003195,
    "Distance in Parsecs_1": 21.3675,
    "HYG Star ID_1": 118181,
    "Gliese Catalog_1": "Gl 120"
  },
  {
    "Astrid #": "ASTRID 002207",
    "HYG Star ID": 119331,
    "Gliese Catalog": "GJ 1250",
    "Distance in Parsecs": 21.3675,
    "mag": 14.88,
    "Absolute Visual Magnitude": 13.231,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.484364,
    "y": -15.13234,
    "z": 11.731545,
    "vx": 0.00001554,
    "vy": 0.00003463,
    "vz": 0.00003211,
    "rarad": 5.27223929909202,
    "decrad": 0.5812114716059,
    "pmrarad": 0.00000147708184054861,
    "pmdecrad": 0.000001798232118,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119331,
    "lum": 0.000444221935471054,
    "ra": 20.138471,
    "dec": 33.300964,
    "pmra": 304.67,
    "pmdec": 370.91,
    "rv": 0,
    "Asset Name": "ASTRID 002207",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Worried Vamp",
    "Hair": "Frizzy",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001554,
    "y_1": 0.00003463,
    "z_1": 0.00003211,
    "Distance in Parsecs_1": 21.3675,
    "HYG Star ID_1": 119331,
    "Gliese Catalog_1": "GJ 1250"
  },
  {
    "Astrid #": "ASTRID 002208",
    "HYG Star ID": 119423,
    "Gliese Catalog": "Gl 834B",
    "Distance in Parsecs": 21.3675,
    "mag": 12.3,
    "Absolute Visual Magnitude": 10.651,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 13.374423,
    "y": -9.660103,
    "z": 13.57856,
    "vx": -0.00000424,
    "vy": -0.00002347,
    "vz": -0.00001251,
    "rarad": 5.65767597721961,
    "decrad": 0.688626470359299,
    "pmrarad": -0.00000100671560767361,
    "pmdecrad": -7.58592814e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 106353,
    "base": "Gl 834",
    "lum": 0.00478189460665907,
    "ra": 21.61073,
    "dec": 39.45539,
    "pmra": -207.65,
    "pmdec": -156.47,
    "rv": 0,
    "Asset Name": "ASTRID 002208",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Little Crown",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000424,
    "y_1": -0.00002347,
    "z_1": -0.00001251,
    "Distance in Parsecs_1": 21.3675,
    "HYG Star ID_1": 119423,
    "Gliese Catalog_1": "Gl 834B"
  },
  {
    "Astrid #": "ASTRID 002209",
    "HYG Star ID": 3988,
    "Hipparcos Catalogue": 3998,
    "Henry Draper Catalogue": 4913,
    "Gliese Catalog": "Gl 39",
    "Distance in Parsecs": 21.3721,
    "mag": 9.22,
    "Absolute Visual Magnitude": 7.571,
    "Spectral Type": "K2",
    "Color Index": 1.194,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.733086,
    "y": 4.497969,
    "z": 6.866058,
    "vx": 0.00001356,
    "vy": 0.00000884,
    "vz": -0.00002407,
    "rarad": 0.224111381683608,
    "decrad": 0.327061642601422,
    "pmrarad": 2.62478126652777e-7,
    "pmdecrad": -0.000001295131266,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 3988,
    "lum": 0.0815830617449007,
    "ra": 0.856042,
    "dec": 18.739252,
    "pmra": 54.14,
    "pmdec": -267.14,
    "rv": 6.5,
    "Asset Name": "ASTRID 002209",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Afro",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00001356,
    "y_1": 0.00000884,
    "z_1": -0.00002407,
    "Distance in Parsecs_1": 21.3721,
    "HYG Star ID_1": 3988,
    "Hipparcos Catalogue_1": 3998,
    "Henry Draper Catalogue_1": 4913,
    "Gliese Catalog_1": "Gl 39"
  },
  {
    "Astrid #": "ASTRID 002210",
    "HYG Star ID": 7719,
    "Hipparcos Catalogue": 7734,
    "Henry Draper Catalogue": 10086,
    "Distance in Parsecs": 21.3721,
    "mag": 6.6,
    "Absolute Visual Magnitude": 4.951,
    "Spectral Type": "G5IV",
    "Color Index": 0.69,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.495984,
    "y": 6.264648,
    "z": 15.342117,
    "vx": 0.00000818,
    "vy": 0.00002856,
    "vz": -0.00001402,
    "rarad": 0.434584265763944,
    "decrad": 0.800718155666941,
    "pmrarad": 0.00000105117302218055,
    "pmdecrad": -0.000001109980921,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 7719,
    "lum": 0.911171233042919,
    "ra": 1.65999,
    "dec": 45.877771,
    "pmra": 216.82,
    "pmdec": -228.95,
    "rv": 3.4,
    "Asset Name": "ASTRID 002210",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Owie",
    "Hair": "Charmer",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000818,
    "y_1": 0.00002856,
    "z_1": -0.00001402,
    "Distance in Parsecs_1": 21.3721,
    "HYG Star ID_1": 7719,
    "Hipparcos Catalogue_1": 7734,
    "Henry Draper Catalogue_1": 10086
  },
  {
    "Astrid #": "ASTRID 002211",
    "HYG Star ID": 16542,
    "Hipparcos Catalogue": 16582,
    "Henry Draper Catalogue": 22094,
    "Distance in Parsecs": 21.3721,
    "mag": 8.7,
    "Absolute Visual Magnitude": 7.051,
    "Spectral Type": "K0",
    "Color Index": 0.997,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.737715,
    "y": 17.135288,
    "z": -0.948244,
    "vx": -0.00000522,
    "vy": 0.00000372,
    "vz": -0.00000288,
    "rarad": 0.931557317447885,
    "decrad": -0.0443828794303374,
    "pmrarad": 3.00051186895833e-7,
    "pmdecrad": -1.3502061e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 16542,
    "lum": 0.131704313819984,
    "var_min": 8.739,
    "var_max": 8.629,
    "ra": 3.558287,
    "dec": -2.542952,
    "pmra": 61.89,
    "pmdec": -27.85,
    "rv": 0,
    "Asset Name": "ASTRID 002211",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Frizzy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000522,
    "y_1": 0.00000372,
    "z_1": -0.00000288,
    "Distance in Parsecs_1": 21.3721,
    "HYG Star ID_1": 16542,
    "Hipparcos Catalogue_1": 16582,
    "Henry Draper Catalogue_1": 22094
  },
  {
    "Astrid #": "ASTRID 002212",
    "HYG Star ID": 92627,
    "Hipparcos Catalogue": 92919,
    "Henry Draper Catalogue": 175742,
    "Gliese Catalog": "Wo 9638",
    "Distance in Parsecs": 21.395,
    "mag": 8.16,
    "Absolute Visual Magnitude": 6.508,
    "Spectral Type": "K0V",
    "Color Index": 0.91,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.735229,
    "y": -19.031911,
    "z": 8.550668,
    "vx": 0.00001833,
    "vy": -0.00001748,
    "vz": -0.0000227,
    "rarad": 4.95624185226836,
    "decrad": 0.411141101831756,
    "pmrarad": 6.34087812798611e-7,
    "pmdecrad": -0.000001372362085,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 92627,
    "lum": 0.217170084334799,
    "var": "V775",
    "var_min": 8.254,
    "var_max": 8.094,
    "ra": 18.931449,
    "dec": 23.55665,
    "pmra": 130.79,
    "pmdec": -283.07,
    "rv": 10.3,
    "Asset Name": "ASTRID 002212",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Horns",
    "Outfit": "Suit",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001833,
    "y_1": -0.00001748,
    "z_1": -0.0000227,
    "Distance in Parsecs_1": 21.395,
    "HYG Star ID_1": 92627,
    "Hipparcos Catalogue_1": 92919,
    "Henry Draper Catalogue_1": 175742,
    "Gliese Catalog_1": "Wo 9638"
  },
  {
    "Astrid #": "ASTRID 002213",
    "HYG Star ID": 119496,
    "Gliese Catalog": "Gl 857.1B",
    "Distance in Parsecs": 21.395,
    "mag": 12.4,
    "Absolute Visual Magnitude": 10.748,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 18.077381,
    "y": -7.976716,
    "z": 8.20493,
    "vx": -0.00000655,
    "vy": -0.00001754,
    "vz": -0.00000929,
    "rarad": 5.86763191743924,
    "decrad": 0.39358292081235,
    "pmrarad": -8.73731215097222e-7,
    "pmdecrad": -4.20483753e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 110294,
    "base": "Gl 857.1",
    "lum": 0.00437320665169645,
    "ra": 22.412703,
    "dec": 22.55064,
    "pmra": -180.22,
    "pmdec": -86.73,
    "rv": -2.5,
    "Asset Name": "ASTRID 002213",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Bad Dreams",
    "Hair": "Braided Bun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000655,
    "y_1": -0.00001754,
    "z_1": -0.00000929,
    "Distance in Parsecs_1": 21.395,
    "HYG Star ID_1": 119496,
    "Gliese Catalog_1": "Gl 857.1B"
  },
  {
    "Astrid #": "ASTRID 002214",
    "HYG Star ID": 52311,
    "Hipparcos Catalogue": 52462,
    "Henry Draper Catalogue": 92945,
    "Gliese Catalog": "NN 3615",
    "Distance in Parsecs": 21.3995,
    "mag": 7.72,
    "Absolute Visual Magnitude": 6.068,
    "Spectral Type": "K1V",
    "Color Index": 0.873,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.671612,
    "y": 6.130469,
    "z": -10.395677,
    "vx": 0.00000963,
    "vy": 0.00002031,
    "vz": -0.0000044,
    "rarad": 2.80767406189836,
    "decrad": -0.507267442428056,
    "pmrarad": -0.00000104424018654861,
    "pmdecrad": -2.35134635e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 52311,
    "lum": 0.325686682201669,
    "var": "V419",
    "var_min": 7.753,
    "var_max": 7.693,
    "ra": 10.724525,
    "dec": -29.064284,
    "pmra": -215.39,
    "pmdec": -48.5,
    "rv": 0,
    "Asset Name": "ASTRID 002214",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Pompadour",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000963,
    "y_1": 0.00002031,
    "z_1": -0.0000044,
    "Distance in Parsecs_1": 21.3995,
    "HYG Star ID_1": 52311,
    "Hipparcos Catalogue_1": 52462,
    "Henry Draper Catalogue_1": 92945,
    "Gliese Catalog_1": "NN 3615"
  },
  {
    "Astrid #": "ASTRID 002215",
    "HYG Star ID": 59598,
    "Hipparcos Catalogue": 59780,
    "Henry Draper Catalogue": 106549,
    "Distance in Parsecs": 21.4041,
    "mag": 9.74,
    "Absolute Visual Magnitude": 8.088,
    "Spectral Type": "K5V",
    "Color Index": 1.383,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.287129,
    "y": -1.234745,
    "z": -11.05404,
    "vx": 0.0000041,
    "vy": -0.00001481,
    "vz": -0.00000513,
    "rarad": 3.2090091653462,
    "decrad": -0.54269422655149,
    "pmrarad": 7.03125280909722e-7,
    "pmdecrad": -2.79834456e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 59598,
    "lum": 0.0506757284177282,
    "ra": 12.257512,
    "dec": -31.094089,
    "pmra": 145.03,
    "pmdec": -57.72,
    "rv": 0,
    "Asset Name": "ASTRID 002215",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Medium Bob",
    "Outfit": "Samurai",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.0000041,
    "y_1": -0.00001481,
    "z_1": -0.00000513,
    "Distance in Parsecs_1": 21.4041,
    "HYG Star ID_1": 59598,
    "Hipparcos Catalogue_1": 59780,
    "Henry Draper Catalogue_1": 106549
  },
  {
    "Astrid #": "ASTRID 002216",
    "HYG Star ID": 90872,
    "Hipparcos Catalogue": 91154,
    "Henry Draper Catalogue": 170915,
    "Distance in Parsecs": 21.4087,
    "mag": 9.34,
    "Absolute Visual Magnitude": 7.687,
    "Spectral Type": "K3V",
    "Color Index": 1.152,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.604525,
    "y": -10.179227,
    "z": -18.765428,
    "vx": -0.00000806,
    "vy": 0.00003796,
    "vz": -0.00002128,
    "rarad": 4.86873092048451,
    "decrad": -1.06861107358151,
    "pmrarad": -9.59446273819444e-8,
    "pmdecrad": -0.000002065209316,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 90872,
    "lum": 0.073316208902721,
    "ra": 18.597182,
    "dec": -61.226904,
    "pmra": -19.79,
    "pmdec": -425.98,
    "rv": 0,
    "Asset Name": "ASTRID 002216",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Laughing",
    "Hair": "Prince",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00000806,
    "y_1": 0.00003796,
    "z_1": -0.00002128,
    "Distance in Parsecs_1": 21.4087,
    "HYG Star ID_1": 90872,
    "Hipparcos Catalogue_1": 91154,
    "Henry Draper Catalogue_1": 170915
  },
  {
    "Astrid #": "ASTRID 002217",
    "HYG Star ID": 34741,
    "Hipparcos Catalogue": 34834,
    "Henry Draper Catalogue": 55892,
    "Harvard Revised Catalogue": 2740,
    "Gliese Catalog": "Gl 268.1",
    "Distance in Parsecs": 21.427,
    "mag": 4.49,
    "Absolute Visual Magnitude": 2.835,
    "Spectral Type": "F0IV",
    "Color Index": 0.324,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.57015,
    "y": 13.949318,
    "z": -15.609196,
    "vx": 0.00001063,
    "vy": 0.0000128,
    "vz": 0.00000678,
    "rarad": 1.88740354668568,
    "decrad": -0.816103825840371,
    "pmrarad": -6.57407350833333e-7,
    "pmdecrad": 5.17732529e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 34741,
    "lum": 6.39734835482648,
    "var": "QW",
    "var_min": 4.506,
    "var_max": 4.476,
    "ra": 7.20935,
    "dec": -46.759305,
    "pmra": -135.6,
    "pmdec": 106.79,
    "rv": 1.1,
    "Asset Name": "ASTRID 002217",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Heartbreaker",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001063,
    "y_1": 0.0000128,
    "z_1": 0.00000678,
    "Distance in Parsecs_1": 21.427,
    "HYG Star ID_1": 34741,
    "Hipparcos Catalogue_1": 34834,
    "Henry Draper Catalogue_1": 55892,
    "Harvard Revised Catalogue_1": 2740,
    "Gliese Catalog_1": "Gl 268.1"
  },
  {
    "Astrid #": "ASTRID 002218",
    "HYG Star ID": 61258,
    "Hipparcos Catalogue": 61451,
    "Henry Draper Catalogue": 109524,
    "Gliese Catalog": "GJ 1161A",
    "Distance in Parsecs": 21.427,
    "mag": 7.84,
    "Absolute Visual Magnitude": 6.185,
    "Spectral Type": "K4V",
    "Color Index": 1.024,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.366113,
    "y": -2.716295,
    "z": -12.253823,
    "vx": 0.00000419,
    "vy": 0.00002461,
    "vz": -0.00001139,
    "rarad": 3.29675108905761,
    "decrad": -0.608804298714037,
    "pmrarad": -0.00000110479341524999,
    "pmdecrad": -6.4819589e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 61258,
    "base": "GJ 1161",
    "lum": 0.292415237784333,
    "ra": 12.592662,
    "dec": -34.881917,
    "pmra": -227.88,
    "pmdec": -133.7,
    "rv": 0,
    "Asset Name": "ASTRID 002218",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "High Fade",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000419,
    "y_1": 0.00002461,
    "z_1": -0.00001139,
    "Distance in Parsecs_1": 21.427,
    "HYG Star ID_1": 61258,
    "Hipparcos Catalogue_1": 61451,
    "Henry Draper Catalogue_1": 109524,
    "Gliese Catalog_1": "GJ 1161A"
  },
  {
    "Astrid #": "ASTRID 002219",
    "HYG Star ID": 93671,
    "Hipparcos Catalogue": 93966,
    "Henry Draper Catalogue": 178428,
    "Harvard Revised Catalogue": 7260,
    "Gliese Catalog": "Gl 746",
    "Distance in Parsecs": 21.4316,
    "mag": 6.08,
    "Absolute Visual Magnitude": 4.425,
    "Spectral Type": "G5V",
    "Color Index": 0.705,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.993085,
    "y": -19.616159,
    "z": 6.213567,
    "vx": 0.00001343,
    "vy": -0.00002063,
    "vz": -0.00002588,
    "rarad": 5.00890006325473,
    "decrad": 0.294147058442353,
    "pmrarad": 3.180862558125e-7,
    "pmdecrad": -0.000001476063731,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 1,
    "comp_primary": 93671,
    "lum": 1.4791083881682,
    "ra": 19.132589,
    "dec": 16.853385,
    "pmra": 65.61,
    "pmdec": -304.46,
    "rv": 14.8,
    "Asset Name": "ASTRID 002219",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Shinobi",
    "Hair": "Bob",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": 0.00001343,
    "y_1": -0.00002063,
    "z_1": -0.00002588,
    "Distance in Parsecs_1": 21.4316,
    "HYG Star ID_1": 93671,
    "Hipparcos Catalogue_1": 93966,
    "Henry Draper Catalogue_1": 178428,
    "Harvard Revised Catalogue_1": 7260,
    "Gliese Catalog_1": "Gl 746"
  },
  {
    "Astrid #": "ASTRID 002220",
    "HYG Star ID": 118230,
    "Gliese Catalog": "Gl 143.2B",
    "Distance in Parsecs": 21.4362,
    "mag": 10.75,
    "Absolute Visual Magnitude": 9.094,
    "Spectral Type": "m",
    "Color Index": 1.42,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.953212,
    "y": 7.721406,
    "z": -19.090569,
    "vx": -0.00000938,
    "vy": 0.00005029,
    "vz": 0.00001741,
    "rarad": 0.913882839430723,
    "decrad": -1.0986104288341,
    "pmrarad": 0.00000177950861447916,
    "pmdecrad": 0.000001785743318,
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 2,
    "comp_primary": 16204,
    "base": "Gl 143.2",
    "lum": 0.0200631906479057,
    "ra": 3.490775,
    "dec": -62.945741,
    "pmra": 367.05,
    "pmdec": 368.34,
    "rv": 0,
    "Asset Name": "ASTRID 002220",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Wavy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": -0.00000938,
    "y_1": 0.00005029,
    "z_1": 0.00001741,
    "Distance in Parsecs_1": 21.4362,
    "HYG Star ID_1": 118230,
    "Gliese Catalog_1": "Gl 143.2B"
  },
  {
    "Astrid #": "ASTRID 002221",
    "HYG Star ID": 86219,
    "Hipparcos Catalogue": 86486,
    "Henry Draper Catalogue": 160032,
    "Harvard Revised Catalogue": 6569,
    "Gliese Catalog": "Gl 686.2",
    "Distance in Parsecs": 21.45,
    "mag": 4.76,
    "Absolute Visual Magnitude": 3.103,
    "Spectral Type": "F3IV",
    "Color Index": 0.415,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.192144,
    "y": -13.903682,
    "z": -16.290191,
    "vx": 0.00001179,
    "vy": 0.0000106,
    "vz": -0.00001489,
    "rarad": 4.62685279668242,
    "decrad": -0.862464686974077,
    "pmrarad": 5.05563706083333e-7,
    "pmdecrad": -8.61368466e-7,
    "bayer": "Lam",
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 86219,
    "lum": 4.99804313578239,
    "bf": "Lam Ara",
    "ra": 17.673276,
    "dec": -49.415587,
    "pmra": 104.28,
    "pmdec": -177.67,
    "rv": 3.7,
    "Asset Name": "ASTRID 002221",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Spiky",
    "Outfit": "Samurai",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00001179,
    "y_1": 0.0000106,
    "z_1": -0.00001489,
    "Distance in Parsecs_1": 21.45,
    "HYG Star ID_1": 86219,
    "Hipparcos Catalogue_1": 86486,
    "Henry Draper Catalogue_1": 160032,
    "Harvard Revised Catalogue_1": 6569,
    "Gliese Catalog_1": "Gl 686.2"
  },
  {
    "Astrid #": "ASTRID 002222",
    "HYG Star ID": 117977,
    "Gliese Catalog": "NN 3024",
    "Distance in Parsecs": 21.4592,
    "mag": 12.49,
    "Absolute Visual Magnitude": 10.832,
    "Spectral Type": "M0",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.148403,
    "y": 1.615194,
    "z": -3.260901,
    "vx": -0.00000463,
    "vy": 0.00003756,
    "vz": -0.00001142,
    "rarad": 0.0762252562216885,
    "decrad": -0.15254915564235,
    "pmrarad": 0.00000176176443377083,
    "pmdecrad": -5.38632847e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117977,
    "lum": 0.00404762248687872,
    "ra": 0.291159,
    "dec": -8.740423,
    "pmra": 363.39,
    "pmdec": -111.1,
    "rv": 0,
    "Asset Name": "ASTRID 002222",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Grecian",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000463,
    "y_1": 0.00003756,
    "z_1": -0.00001142,
    "Distance in Parsecs_1": 21.4592,
    "HYG Star ID_1": 117977,
    "Gliese Catalog_1": "NN 3024"
  },
  {
    "Astrid #": "ASTRID 002223",
    "HYG Star ID": 118468,
    "Gliese Catalog": "NN 3437",
    "Distance in Parsecs": 21.4592,
    "mag": 12.8,
    "Absolute Visual Magnitude": 11.142,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.70784,
    "y": 19.074144,
    "z": 7.188823,
    "vx": 0.00003014,
    "vy": 0.00001988,
    "vz": -0.0000246,
    "rarad": 1.90896475649653,
    "decrad": 0.341604785398299,
    "pmrarad": -0.00000163261006927083,
    "pmdecrad": -0.000001216892034,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118468,
    "lum": 0.00304228572748096,
    "ra": 7.291708,
    "dec": 19.572512,
    "pmra": -336.75,
    "pmdec": -251,
    "rv": 0,
    "Asset Name": "ASTRID 002223",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Neko Ninja",
    "Hair": "Braided Ponytail",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003014,
    "y_1": 0.00001988,
    "z_1": -0.0000246,
    "Distance in Parsecs_1": 21.4592,
    "HYG Star ID_1": 118468,
    "Gliese Catalog_1": "NN 3437"
  },
  {
    "Astrid #": "ASTRID 002224",
    "HYG Star ID": 119183,
    "Gliese Catalog": "NN 4030",
    "Distance in Parsecs": 21.4592,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.842,
    "Spectral Type": "M4.5-5",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.835233,
    "y": -19.618541,
    "z": 8.645053,
    "vx": -0.00003429,
    "vy": 0.00009521,
    "vz": 0.00000971,
    "rarad": 4.66984925734582,
    "decrad": 0.4147233621097,
    "pmrarad": -0.00000178479308359722,
    "pmdecrad": 0.000002172862194,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119183,
    "lum": 0.00159661424599341,
    "ra": 17.83751,
    "dec": 23.761898,
    "pmra": -368.14,
    "pmdec": 448.19,
    "rv": -80,
    "Asset Name": "ASTRID 002224",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "High Bun",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003429,
    "y_1": 0.00009521,
    "z_1": 0.00000971,
    "Distance in Parsecs_1": 21.4592,
    "HYG Star ID_1": 119183,
    "Gliese Catalog_1": "NN 4030"
  },
  {
    "Astrid #": "ASTRID 002225",
    "HYG Star ID": 88699,
    "Hipparcos Catalogue": 88976,
    "Distance in Parsecs": 21.4638,
    "mag": 11.38,
    "Absolute Visual Magnitude": 9.721,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 0.769564,
    "y": -18.211798,
    "z": 11.332822,
    "vx": 0.00000521,
    "vy": 0.0000107,
    "vz": 0.00001684,
    "rarad": 4.75461975871311,
    "decrad": 0.556240293934877,
    "pmrarad": 2.63738642222222e-7,
    "pmdecrad": 9.23763986e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 88699,
    "lum": 0.0112615974690786,
    "var_min": 11.451,
    "var_max": 11.281,
    "ra": 18.16131,
    "dec": 31.870221,
    "pmra": 54.4,
    "pmdec": 190.54,
    "rv": 0,
    "Asset Name": "ASTRID 002225",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Angelic",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000521,
    "y_1": 0.0000107,
    "z_1": 0.00001684,
    "Distance in Parsecs_1": 21.4638,
    "HYG Star ID_1": 88699,
    "Hipparcos Catalogue_1": 88976
  },
  {
    "Astrid #": "ASTRID 002226",
    "HYG Star ID": 517,
    "Hipparcos Catalogue": 518,
    "Henry Draper Catalogue": 123,
    "Harvard Revised Catalogue": 5,
    "Gliese Catalog": "Gl 4.1A",
    "Distance in Parsecs": 21.4777,
    "mag": 5.98,
    "Absolute Visual Magnitude": 4.32,
    "Spectral Type": "G5V",
    "Color Index": 0.687,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.23803,
    "y": 0.307211,
    "z": 18.300258,
    "vx": -0.00000849,
    "vy": 0.00002553,
    "vz": -0.00000914,
    "rarad": 0.0273204253073019,
    "decrad": 1.01991339082467,
    "pmrarad": 0.00000119923512022222,
    "pmdecrad": 8.6151391e-8,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 517,
    "base": "Gl   4.1",
    "lum": 1.62929603263972,
    "var": "V640",
    "var_min": 5.994,
    "var_max": 5.964,
    "ra": 0.104356,
    "dec": 58.436733,
    "pmra": 247.36,
    "pmdec": 17.77,
    "rv": -11.6,
    "Asset Name": "ASTRID 002226",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Gimme Blood",
    "Hair": "Round Brush",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000849,
    "y_1": 0.00002553,
    "z_1": -0.00000914,
    "Distance in Parsecs_1": 21.4777,
    "HYG Star ID_1": 517,
    "Hipparcos Catalogue_1": 518,
    "Henry Draper Catalogue_1": 123,
    "Harvard Revised Catalogue_1": 5,
    "Gliese Catalog_1": "Gl 4.1A"
  },
  {
    "Astrid #": "ASTRID 002227",
    "HYG Star ID": 12357,
    "Hipparcos Catalogue": 12390,
    "Henry Draper Catalogue": 16620,
    "Harvard Revised Catalogue": 781,
    "Gliese Catalog": "Gl 105.4A",
    "Distance in Parsecs": 21.4823,
    "mag": 4.83,
    "Absolute Visual Magnitude": 3.17,
    "Spectral Type": "F5V",
    "Color Index": 0.447,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.130169,
    "y": 13.482598,
    "z": -4.419553,
    "vx": -0.00000332,
    "vy": 0.00002057,
    "vz": -0.00002739,
    "rarad": 0.696228712870104,
    "decrad": -0.20720820955307,
    "pmrarad": 8.33928011923611e-7,
    "pmdecrad": -0.000001145663208,
    "bayer": "Eps",
    "flam": 83,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12357,
    "base": "Gl 105.4",
    "lum": 4.69894108605215,
    "bf": "83Eps Cet",
    "ra": 2.659398,
    "dec": -11.872156,
    "pmra": 172.01,
    "pmdec": -236.31,
    "rv": 15.7,
    "Asset Name": "ASTRID 002227",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Bandana",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000332,
    "y_1": 0.00002057,
    "z_1": -0.00002739,
    "Distance in Parsecs_1": 21.4823,
    "HYG Star ID_1": 12357,
    "Hipparcos Catalogue_1": 12390,
    "Henry Draper Catalogue_1": 16620,
    "Harvard Revised Catalogue_1": 781,
    "Gliese Catalog_1": "Gl 105.4A"
  },
  {
    "Astrid #": "ASTRID 002228",
    "HYG Star ID": 86648,
    "Hipparcos Catalogue": 86916,
    "Gliese Catalog": "Gl 694.2",
    "Distance in Parsecs": 21.4915,
    "mag": 10.69,
    "Absolute Visual Magnitude": 9.029,
    "Spectral Type": "M1",
    "Color Index": 1.045,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.925441,
    "y": -14.667668,
    "z": 15.680875,
    "vx": -0.0000034,
    "vy": -0.00000347,
    "vz": 0.00000455,
    "rarad": 4.64937920385033,
    "decrad": 0.8177803459269,
    "pmrarad": -1.47674247097222e-7,
    "pmdecrad": 1.987736e-8,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 86648,
    "lum": 0.0213010003741125,
    "var_min": 10.752,
    "var_max": 10.642,
    "ra": 17.75932,
    "dec": 46.855362,
    "pmra": -30.46,
    "pmdec": 4.1,
    "rv": 5.7,
    "Asset Name": "ASTRID 002228",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Bangs",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.0000034,
    "y_1": -0.00000347,
    "z_1": 0.00000455,
    "Distance in Parsecs_1": 21.4915,
    "HYG Star ID_1": 86648,
    "Hipparcos Catalogue_1": 86916,
    "Gliese Catalog_1": "Gl 694.2"
  },
  {
    "Astrid #": "ASTRID 002229",
    "HYG Star ID": 51354,
    "Hipparcos Catalogue": 51502,
    "Henry Draper Catalogue": 90089,
    "Harvard Revised Catalogue": 4084,
    "Gliese Catalog": "Gl 392.1",
    "Distance in Parsecs": 21.5008,
    "mag": 5.25,
    "Absolute Visual Magnitude": 3.588,
    "Spectral Type": "F2V",
    "Color Index": 0.399,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.577639,
    "y": 1.05352,
    "z": 21.319754,
    "vx": 0.00000456,
    "vy": 0.00000705,
    "vz": 0.00000454,
    "rarad": 2.75361863781698,
    "decrad": 1.44091917666504,
    "pmrarad": -3.84020916368055e-7,
    "pmdecrad": 9.9047434e-8,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 51354,
    "lum": 3.19742230320492,
    "ra": 10.518048,
    "dec": 82.558587,
    "pmra": -79.21,
    "pmdec": 20.43,
    "rv": 4.2,
    "Asset Name": "ASTRID 002229",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Lovestruck",
    "Hair": "Fire",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000456,
    "y_1": 0.00000705,
    "z_1": 0.00000454,
    "Distance in Parsecs_1": 21.5008,
    "HYG Star ID_1": 51354,
    "Hipparcos Catalogue_1": 51502,
    "Henry Draper Catalogue_1": 90089,
    "Harvard Revised Catalogue_1": 4084,
    "Gliese Catalog_1": "Gl 392.1"
  },
  {
    "Astrid #": "ASTRID 002230",
    "HYG Star ID": 118252,
    "Gliese Catalog": "Wo 9127",
    "Distance in Parsecs": 21.5054,
    "mag": 11.09,
    "Absolute Visual Magnitude": 9.427,
    "Spectral Type": "G3",
    "Color Index": 0.86,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.893717,
    "y": 16.20614,
    "z": 9.009451,
    "vx": 6e-8,
    "vy": 0.0000025,
    "vz": -0.00000457,
    "rarad": 0.978974548744513,
    "decrad": 0.432276503719249,
    "pmrarad": 6.27348902638888e-8,
    "pmdecrad": -2.34145615e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118252,
    "lum": 0.0147638627775386,
    "ra": 3.739407,
    "dec": 24.767619,
    "pmra": 12.94,
    "pmdec": -48.3,
    "rv": 0,
    "Asset Name": "ASTRID 002230",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Greasy",
    "Outfit": "Mecha",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 6e-8,
    "y_1": 0.0000025,
    "z_1": -0.00000457,
    "Distance in Parsecs_1": 21.5054,
    "HYG Star ID_1": 118252,
    "Gliese Catalog_1": "Wo 9127"
  },
  {
    "Astrid #": "ASTRID 002231",
    "HYG Star ID": 119191,
    "Gliese Catalog": "NN 4037",
    "Distance in Parsecs": 21.5054,
    "mag": 17.98,
    "Absolute Visual Magnitude": 16.317,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.217407,
    "y": -11.264742,
    "z": 18.317766,
    "vx": 0.00002704,
    "vy": 0.00007359,
    "vz": 0.0000456,
    "rarad": 4.69303753681624,
    "decrad": 1.0193643674761,
    "pmrarad": 0.00000119089632491666,
    "pmdecrad": 0.000004046269522,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119191,
    "lum": 0.0000258940510573923,
    "ra": 17.926083,
    "dec": 58.405276,
    "pmra": 245.64,
    "pmdec": 834.6,
    "rv": 0,
    "Asset Name": "ASTRID 002231",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Scared",
    "Hair": "Spiky",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002704,
    "y_1": 0.00007359,
    "z_1": 0.0000456,
    "Distance in Parsecs_1": 21.5054,
    "HYG Star ID_1": 119191,
    "Gliese Catalog_1": "NN 4037"
  },
  {
    "Astrid #": "ASTRID 002232",
    "HYG Star ID": 52556,
    "Hipparcos Catalogue": 52708,
    "Henry Draper Catalogue": 93380,
    "Gliese Catalog": "NN 3620",
    "Distance in Parsecs": 21.51,
    "mag": 9.37,
    "Absolute Visual Magnitude": 7.707,
    "Spectral Type": "K5V",
    "Color Index": 1.165,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.56574,
    "y": 6.156659,
    "z": -8.949244,
    "vx": 0.00000912,
    "vy": 0.00001242,
    "vz": -0.00001038,
    "rarad": 2.82139094030705,
    "decrad": -0.429097598727378,
    "pmrarad": -6.81793478965277e-7,
    "pmdecrad": -5.30483129e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 52556,
    "lum": 0.0719780372802699,
    "ra": 10.77692,
    "dec": -24.585481,
    "pmra": -140.63,
    "pmdec": -109.42,
    "rv": 0,
    "Asset Name": "ASTRID 002232",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Greasy",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000912,
    "y_1": 0.00001242,
    "z_1": -0.00001038,
    "Distance in Parsecs_1": 21.51,
    "HYG Star ID_1": 52556,
    "Hipparcos Catalogue_1": 52708,
    "Henry Draper Catalogue_1": 93380,
    "Gliese Catalog_1": "NN 3620"
  },
  {
    "Astrid #": "ASTRID 002233",
    "HYG Star ID": 75600,
    "Hipparcos Catalogue": 75829,
    "Henry Draper Catalogue": 139813,
    "Distance in Parsecs": 21.5146,
    "mag": 7.3,
    "Absolute Visual Magnitude": 5.636,
    "Spectral Type": "G5",
    "Color Index": 0.803,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.180347,
    "y": -2.82596,
    "z": 21.216194,
    "vx": -0.00000832,
    "vy": 0.00002634,
    "vz": -0.00002742,
    "rarad": 4.05528924078333,
    "decrad": 1.40412204170892,
    "pmrarad": -0.00000105408190426388,
    "pmdecrad": 5.11623877e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 75600,
    "lum": 0.484841738708446,
    "ra": 15.490064,
    "dec": 80.450267,
    "pmra": -217.42,
    "pmdec": 105.53,
    "rv": -29,
    "Asset Name": "ASTRID 002233",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Sweet Nap",
    "Hair": "Sweetheart",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": -0.00000832,
    "y_1": 0.00002634,
    "z_1": -0.00002742,
    "Distance in Parsecs_1": 21.5146,
    "HYG Star ID_1": 75600,
    "Hipparcos Catalogue_1": 75829,
    "Henry Draper Catalogue_1": 139813
  },
  {
    "Astrid #": "ASTRID 002234",
    "HYG Star ID": 3969,
    "Hipparcos Catalogue": 3979,
    "Henry Draper Catalogue": 4915,
    "Distance in Parsecs": 21.5193,
    "mag": 6.98,
    "Absolute Visual Magnitude": 5.316,
    "Spectral Type": "G0",
    "Color Index": 0.663,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.903818,
    "y": 4.747389,
    "z": -1.890226,
    "vx": -0.00000712,
    "vy": 0.00002644,
    "vz": -0.00001238,
    "rarad": 0.223318329807349,
    "decrad": -0.08795199975826,
    "pmrarad": 0.00000127152083999305,
    "pmdecrad": -5.77800944e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3969,
    "lum": 0.651028498434861,
    "ra": 0.853013,
    "dec": -5.039278,
    "pmra": 262.27,
    "pmdec": -119.18,
    "rv": 0,
    "Asset Name": "ASTRID 002234",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Afro",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000712,
    "y_1": 0.00002644,
    "z_1": -0.00001238,
    "Distance in Parsecs_1": 21.5193,
    "HYG Star ID_1": 3969,
    "Hipparcos Catalogue_1": 3979,
    "Henry Draper Catalogue_1": 4915
  },
  {
    "Astrid #": "ASTRID 002235",
    "HYG Star ID": 111836,
    "Hipparcos Catalogue": 112190,
    "Henry Draper Catalogue": 215152,
    "Gliese Catalog": "NN 4291",
    "Distance in Parsecs": 21.5193,
    "mag": 8.11,
    "Absolute Visual Magnitude": 6.446,
    "Spectral Type": "K0",
    "Color Index": 0.966,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.200238,
    "y": -7.019148,
    "z": -2.399022,
    "vx": -0.00002577,
    "vy": -0.00000813,
    "vz": -0.00002803,
    "rarad": 5.94875861716818,
    "decrad": -0.111715404207166,
    "pmrarad": -7.50152207923611e-7,
    "pmdecrad": -0.000001406880819,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 111836,
    "lum": 0.229932308702122,
    "ra": 22.722584,
    "dec": -6.400821,
    "pmra": -154.73,
    "pmdec": -290.19,
    "rv": -18,
    "Asset Name": "ASTRID 002235",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Tiny Crown",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00002577,
    "y_1": -0.00000813,
    "z_1": -0.00002803,
    "Distance in Parsecs_1": 21.5193,
    "HYG Star ID_1": 111836,
    "Hipparcos Catalogue_1": 112190,
    "Henry Draper Catalogue_1": 215152,
    "Gliese Catalog_1": "NN 4291"
  },
  {
    "Astrid #": "ASTRID 002236",
    "HYG Star ID": 80124,
    "Hipparcos Catalogue": 80366,
    "Henry Draper Catalogue": 147776,
    "Gliese Catalog": "Gl 621",
    "Distance in Parsecs": 21.5239,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.735,
    "Spectral Type": "K2V",
    "Color Index": 0.95,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.480086,
    "y": -19.120669,
    "z": -5.076404,
    "vx": -0.00002259,
    "vy": 0.00000578,
    "vz": -0.00002306,
    "rarad": 4.29495084576186,
    "decrad": -0.238091868493948,
    "pmrarad": -0.00000106814150099999,
    "pmdecrad": -9.98570737e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 80124,
    "lum": 0.176197604641162,
    "var": "V2578",
    "var_min": 8.437,
    "var_max": 8.377,
    "ra": 16.405504,
    "dec": -13.641659,
    "pmra": -220.32,
    "pmdec": -205.97,
    "rv": 9,
    "Asset Name": "ASTRID 002236",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Elegant",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002259,
    "y_1": 0.00000578,
    "z_1": -0.00002306,
    "Distance in Parsecs_1": 21.5239,
    "HYG Star ID_1": 80124,
    "Hipparcos Catalogue_1": 80366,
    "Henry Draper Catalogue_1": 147776,
    "Gliese Catalog_1": "Gl 621"
  },
  {
    "Astrid #": "ASTRID 002237",
    "HYG Star ID": 114967,
    "Hipparcos Catalogue": 115331,
    "Henry Draper Catalogue": 220182,
    "Gliese Catalog": "Gl 894.4",
    "Distance in Parsecs": 21.5239,
    "mag": 7.36,
    "Absolute Visual Magnitude": 5.695,
    "Spectral Type": "K1V",
    "Color Index": 0.801,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.24109,
    "y": -2.577253,
    "z": 14.978214,
    "vx": -0.00000144,
    "vy": 0.00006757,
    "vz": 0.00001956,
    "rarad": 6.11566039148662,
    "decrad": 0.769653248726386,
    "pmrarad": 0.00000308462704253472,
    "pmdecrad": 0.000001062711587,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114967,
    "lum": 0.459198012836868,
    "var": "V453",
    "var_min": 7.397,
    "var_max": 7.337,
    "ra": 23.360102,
    "dec": 44.097883,
    "pmra": 636.25,
    "pmdec": 219.2,
    "rv": 4.4,
    "Asset Name": "ASTRID 002237",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Afro",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00000144,
    "y_1": 0.00006757,
    "z_1": 0.00001956,
    "Distance in Parsecs_1": 21.5239,
    "HYG Star ID_1": 114967,
    "Hipparcos Catalogue_1": 115331,
    "Henry Draper Catalogue_1": 220182,
    "Gliese Catalog_1": "Gl 894.4"
  },
  {
    "Astrid #": "ASTRID 002238",
    "HYG Star ID": 49727,
    "Hipparcos Catalogue": 49868,
    "Gliese Catalog": "Gl 379A",
    "Distance in Parsecs": 21.5285,
    "mag": 9.48,
    "Absolute Visual Magnitude": 7.815,
    "Spectral Type": "M0",
    "Color Index": 1.335,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.907592,
    "y": 2.528246,
    "z": 20.808195,
    "vx": 0.0000244,
    "vy": -0.00003797,
    "vz": -0.00004137,
    "rarad": 2.66585605582598,
    "decrad": 1.31146415081337,
    "pmrarad": 0.00000104889439788194,
    "pmdecrad": 0.000001261921529,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 49727,
    "base": "Gl 379",
    "lum": 0.0651628394060842,
    "ra": 10.18282,
    "dec": 75.141361,
    "pmra": 216.35,
    "pmdec": 260.29,
    "rv": -48.9,
    "Asset Name": "ASTRID 002238",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Curly",
    "Outfit": "Tarnished",
    "Special": "Astral Burst",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.0000244,
    "y_1": -0.00003797,
    "z_1": -0.00004137,
    "Distance in Parsecs_1": 21.5285,
    "HYG Star ID_1": 49727,
    "Hipparcos Catalogue_1": 49868,
    "Gliese Catalog_1": "Gl 379A"
  },
  {
    "Astrid #": "ASTRID 002239",
    "HYG Star ID": 109580,
    "Hipparcos Catalogue": 109926,
    "Henry Draper Catalogue": 211472,
    "Gliese Catalog": "NN 4268A",
    "Distance in Parsecs": 21.5378,
    "mag": 7.5,
    "Absolute Visual Magnitude": 5.834,
    "Spectral Type": "K1V",
    "Color Index": 0.81,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.191308,
    "y": -5.464271,
    "z": 17.571867,
    "vx": 7.1e-7,
    "vy": 0.00002446,
    "vz": -0.00000162,
    "rarad": 5.82896688865293,
    "decrad": 0.954221948665885,
    "pmrarad": 0.00000103493176388194,
    "pmdecrad": 3.38933244e-7,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 109580,
    "base": "NN 4268",
    "lum": 0.404017335373,
    "var": "V447",
    "var_min": 7.525,
    "var_max": 7.475,
    "ra": 22.265013,
    "dec": 54.67289,
    "pmra": 213.47,
    "pmdec": 69.91,
    "rv": -7,
    "Asset Name": "ASTRID 002239",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Blep",
    "Hair": "Fedora",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": 7.1e-7,
    "y_1": 0.00002446,
    "z_1": -0.00000162,
    "Distance in Parsecs_1": 21.5378,
    "HYG Star ID_1": 109580,
    "Hipparcos Catalogue_1": 109926,
    "Henry Draper Catalogue_1": 211472,
    "Gliese Catalog_1": "NN 4268A"
  },
  {
    "Astrid #": "ASTRID 002240",
    "HYG Star ID": 64878,
    "Hipparcos Catalogue": 65083,
    "Distance in Parsecs": 21.5424,
    "mag": 11.6,
    "Absolute Visual Magnitude": 9.934,
    "Color Index": 1.51,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -20.221373,
    "y": -7.40154,
    "z": -0.623112,
    "vx": 0.00000533,
    "vy": -0.00001237,
    "vz": -0.00002619,
    "rarad": 3.49247226042596,
    "decrad": -0.0289289526299087,
    "pmrarad": 6.24391539187499e-7,
    "pmdecrad": -0.000001216300561,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 64878,
    "lum": 0.00925550244763435,
    "var_min": 11.658,
    "var_max": 11.428,
    "ra": 13.340261,
    "dec": -1.657507,
    "pmra": 128.79,
    "pmdec": -250.88,
    "rv": 0,
    "Asset Name": "ASTRID 002240",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Bob",
    "Outfit": "Star Knight",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000533,
    "y_1": -0.00001237,
    "z_1": -0.00002619,
    "Distance in Parsecs_1": 21.5424,
    "HYG Star ID_1": 64878,
    "Hipparcos Catalogue_1": 65083
  },
  {
    "Astrid #": "ASTRID 002241",
    "HYG Star ID": 79338,
    "Hipparcos Catalogue": 79578,
    "Henry Draper Catalogue": 145825,
    "Distance in Parsecs": 21.5517,
    "mag": 6.55,
    "Absolute Visual Magnitude": 4.883,
    "Spectral Type": "G1V",
    "Color Index": 0.646,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.170632,
    "y": -16.423419,
    "z": -11.313173,
    "vx": -0.00000144,
    "vy": 0.00001619,
    "vz": -0.00002246,
    "rarad": 4.25074492449034,
    "decrad": -0.552634960018582,
    "pmrarad": -3.94686817340277e-7,
    "pmdecrad": -0.000001224687838,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 79338,
    "lum": 0.970063133783956,
    "ra": 16.23665,
    "dec": -31.663651,
    "pmra": -81.41,
    "pmdec": -252.61,
    "rv": 0,
    "Asset Name": "ASTRID 002241",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "High Bun",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000144,
    "y_1": 0.00001619,
    "z_1": -0.00002246,
    "Distance in Parsecs_1": 21.5517,
    "HYG Star ID_1": 79338,
    "Hipparcos Catalogue_1": 79578,
    "Henry Draper Catalogue_1": 145825
  },
  {
    "Astrid #": "ASTRID 002242",
    "HYG Star ID": 64675,
    "Hipparcos Catalogue": 64880,
    "Gliese Catalog": "GJ 1170",
    "Distance in Parsecs": 21.5564,
    "mag": 11.28,
    "Absolute Visual Magnitude": 9.612,
    "Spectral Type": "M2V:",
    "Color Index": 1.356,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.377442,
    "y": -5.797407,
    "z": 12.761532,
    "vx": -0.00004002,
    "vy": -0.00000623,
    "vz": -0.00001445,
    "rarad": 3.48181595333913,
    "decrad": 0.63353939517766,
    "pmrarad": -3.46932669805555e-7,
    "pmdecrad": -0.000001633579696,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 64675,
    "lum": 0.0124508786391605,
    "var_min": 11.343,
    "var_max": 11.213,
    "ra": 13.299557,
    "dec": 36.299133,
    "pmra": -71.56,
    "pmdec": -336.95,
    "rv": 23,
    "Asset Name": "ASTRID 002242",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Side Part",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00004002,
    "y_1": -0.00000623,
    "z_1": -0.00001445,
    "Distance in Parsecs_1": 21.5564,
    "HYG Star ID_1": 64675,
    "Hipparcos Catalogue_1": 64880,
    "Gliese Catalog_1": "GJ 1170"
  },
  {
    "Astrid #": "ASTRID 002243",
    "HYG Star ID": 77032,
    "Hipparcos Catalogue": 77268,
    "Henry Draper Catalogue": 329868,
    "Distance in Parsecs": 21.5564,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.962,
    "Spectral Type": "M0:",
    "Color Index": 1.309,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.050424,
    "y": -12.224324,
    "z": -15.825137,
    "vx": -0.0000181,
    "vy": 0.00004915,
    "vz": -0.00002876,
    "rarad": 4.1300292656828,
    "decrad": -0.824380182583796,
    "pmrarad": -0.00000195535053641666,
    "pmdecrad": -0.000001965095291,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 77032,
    "lum": 0.0226568745501291,
    "var_min": 10.695,
    "var_max": 10.535,
    "ra": 15.77555,
    "dec": -47.233505,
    "pmra": -403.32,
    "pmdec": -405.33,
    "rv": 0,
    "Asset Name": "ASTRID 002243",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Braid",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.0000181,
    "y_1": 0.00004915,
    "z_1": -0.00002876,
    "Distance in Parsecs_1": 21.5564,
    "HYG Star ID_1": 77032,
    "Hipparcos Catalogue_1": 77268,
    "Henry Draper Catalogue_1": 329868
  },
  {
    "Astrid #": "ASTRID 002244",
    "HYG Star ID": 109033,
    "Hipparcos Catalogue": 109378,
    "Henry Draper Catalogue": 210277,
    "Gliese Catalog": "Gl 848.4",
    "Distance in Parsecs": 21.561,
    "mag": 6.54,
    "Absolute Visual Magnitude": 4.872,
    "Spectral Type": "G0",
    "Color Index": 0.773,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.937256,
    "y": -9.910918,
    "z": -2.832402,
    "vx": -0.00002055,
    "vy": 0.00002084,
    "vz": -0.00004373,
    "rarad": 5.80102829716392,
    "decrad": -0.131748866902848,
    "pmrarad": 4.14418734138888e-7,
    "pmdecrad": -0.000002180788897,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 109033,
    "lum": 0.979941161095593,
    "ra": 22.158296,
    "dec": -7.548654,
    "pmra": 85.48,
    "pmdec": -449.82,
    "rv": -21.4,
    "Asset Name": "ASTRID 002244",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Manbun",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00002055,
    "y_1": 0.00002084,
    "z_1": -0.00004373,
    "Distance in Parsecs_1": 21.561,
    "HYG Star ID_1": 109033,
    "Hipparcos Catalogue_1": 109378,
    "Henry Draper Catalogue_1": 210277,
    "Gliese Catalog_1": "Gl 848.4"
  },
  {
    "Astrid #": "ASTRID 002245",
    "HYG Star ID": 3527,
    "Hipparcos Catalogue": 3535,
    "Henry Draper Catalogue": 4256,
    "Gliese Catalog": "Gl 31.4",
    "Distance in Parsecs": 21.5657,
    "mag": 8.03,
    "Absolute Visual Magnitude": 6.361,
    "Spectral Type": "K2V",
    "Color Index": 0.983,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 21.139641,
    "y": 4.212759,
    "z": 0.671951,
    "vx": 0.00001276,
    "vy": -0.00000268,
    "vz": -0.00005957,
    "rarad": 0.196705467664496,
    "decrad": 0.0311632236846087,
    "pmrarad": -2.37752628944444e-7,
    "pmdecrad": -0.000002778321759,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3527,
    "lum": 0.248656605130793,
    "ra": 0.75136,
    "dec": 1.785521,
    "pmra": -49.04,
    "pmdec": -573.07,
    "rv": 9.9,
    "Asset Name": "ASTRID 002245",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Prince",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00001276,
    "y_1": -0.00000268,
    "z_1": -0.00005957,
    "Distance in Parsecs_1": 21.5657,
    "HYG Star ID_1": 3527,
    "Hipparcos Catalogue_1": 3535,
    "Henry Draper Catalogue_1": 4256,
    "Gliese Catalog_1": "Gl 31.4"
  },
  {
    "Astrid #": "ASTRID 002246",
    "HYG Star ID": 17502,
    "Hipparcos Catalogue": 17544,
    "Henry Draper Catalogue": 23588,
    "Gliese Catalog": "Gl 154.1A",
    "Distance in Parsecs": 21.5657,
    "mag": 8.21,
    "Absolute Visual Magnitude": 6.541,
    "Spectral Type": "K2V",
    "Color Index": 1.012,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.564603,
    "y": 15.871272,
    "z": -10.078888,
    "vx": -0.00000765,
    "vy": 0.00004906,
    "vz": -0.00000276,
    "rarad": 0.983500911962132,
    "decrad": -0.48629199827554,
    "pmrarad": 0.00000155576710090277,
    "pmdecrad": 6.79854224e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 17502,
    "base": "Gl 154.1",
    "lum": 0.210668692573941,
    "ra": 3.756697,
    "dec": -27.862479,
    "pmra": 320.9,
    "pmdec": 140.23,
    "rv": 32.9,
    "Asset Name": "ASTRID 002246",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Manbun",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000765,
    "y_1": 0.00004906,
    "z_1": -0.00000276,
    "Distance in Parsecs_1": 21.5657,
    "HYG Star ID_1": 17502,
    "Hipparcos Catalogue_1": 17544,
    "Henry Draper Catalogue_1": 23588,
    "Gliese Catalog_1": "Gl 154.1A"
  },
  {
    "Astrid #": "ASTRID 002247",
    "HYG Star ID": 54517,
    "Hipparcos Catalogue": 54677,
    "Henry Draper Catalogue": 97233,
    "Gliese Catalog": "Gl 416",
    "Distance in Parsecs": 21.5703,
    "mag": 9.06,
    "Absolute Visual Magnitude": 7.391,
    "Spectral Type": "K4V",
    "Color Index": 1.203,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.37224,
    "y": 4.37189,
    "z": -5.57965,
    "vx": 0.00000995,
    "vy": -0.00007682,
    "vz": -0.0000578,
    "rarad": 2.93019748987347,
    "decrad": -0.261649072936862,
    "pmrarad": 0.00000338535696858333,
    "pmdecrad": -0.00000289850707,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 54517,
    "lum": 0.0962941712971555,
    "ra": 11.19253,
    "dec": -14.991388,
    "pmra": 698.28,
    "pmdec": -597.86,
    "rv": -9.8,
    "Asset Name": "ASTRID 002247",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Ninja",
    "Outfit": "Astro",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00000995,
    "y_1": -0.00007682,
    "z_1": -0.0000578,
    "Distance in Parsecs_1": 21.5703,
    "HYG Star ID_1": 54517,
    "Hipparcos Catalogue_1": 54677,
    "Henry Draper Catalogue_1": 97233,
    "Gliese Catalog_1": "Gl 416"
  },
  {
    "Astrid #": "ASTRID 002248",
    "HYG Star ID": 55046,
    "Hipparcos Catalogue": 55210,
    "Henry Draper Catalogue": 98281,
    "Gliese Catalog": "Gl 423.1",
    "Distance in Parsecs": 21.5703,
    "mag": 7.29,
    "Absolute Visual Magnitude": 5.621,
    "Spectral Type": "G8V",
    "Color Index": 0.732,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.132565,
    "y": 3.881711,
    "z": -1.905226,
    "vx": -0.00002678,
    "vy": -0.00007959,
    "vz": -0.00001687,
    "rarad": 2.95993366260953,
    "decrad": -0.0884411069049112,
    "pmrarad": 0.00000385378394673611,
    "pmdecrad": -7.30274847e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 55046,
    "lum": 0.491586559156861,
    "ra": 11.306114,
    "dec": -5.067302,
    "pmra": 794.9,
    "pmdec": -150.63,
    "rv": 13.1,
    "Asset Name": "ASTRID 002248",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Glad",
    "Hair": "Tiny Crown",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00002678,
    "y_1": -0.00007959,
    "z_1": -0.00001687,
    "Distance in Parsecs_1": 21.5703,
    "HYG Star ID_1": 55046,
    "Hipparcos Catalogue_1": 55210,
    "Henry Draper Catalogue_1": 98281,
    "Gliese Catalog_1": "Gl 423.1"
  },
  {
    "Astrid #": "ASTRID 002249",
    "HYG Star ID": 59196,
    "Hipparcos Catalogue": 59378,
    "Gliese Catalog": "Wo 9393",
    "Distance in Parsecs": 21.5796,
    "mag": 10.61,
    "Absolute Visual Magnitude": 8.94,
    "Spectral Type": "M2V",
    "Color Index": 1.34,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.253483,
    "y": -0.777026,
    "z": 14.173909,
    "vx": 0.00001593,
    "vy": -5e-8,
    "vz": 0.00001827,
    "rarad": 3.18936291746112,
    "decrad": 0.716593461325017,
    "pmrarad": 3.77669857152777e-8,
    "pmdecrad": 0.000001122780002,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 59196,
    "lum": 0.0231206479017559,
    "ra": 12.182469,
    "dec": 41.057781,
    "pmra": 7.79,
    "pmdec": 231.59,
    "rv": 0,
    "Asset Name": "ASTRID 002249",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Battle Ready",
    "Hair": "Middle Part",
    "Outfit": "Sweater Friend",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00001593,
    "y_1": -5e-8,
    "z_1": 0.00001827,
    "Distance in Parsecs_1": 21.5796,
    "HYG Star ID_1": 59196,
    "Hipparcos Catalogue_1": 59378,
    "Gliese Catalog_1": "Wo 9393"
  },
  {
    "Astrid #": "ASTRID 002250",
    "HYG Star ID": 71688,
    "Hipparcos Catalogue": 71914,
    "Gliese Catalog": "Wo 9490B",
    "Distance in Parsecs": 21.5796,
    "mag": 9.1,
    "Absolute Visual Magnitude": 7.43,
    "Spectral Type": "M0",
    "Color Index": 1.29,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.437601,
    "y": -13.250424,
    "z": 7.196241,
    "vx": -0.00002136,
    "vy": 0.00001666,
    "vz": -0.00001514,
    "rarad": 3.85089864465176,
    "decrad": 0.339986351073623,
    "pmrarad": -0.00000123055408398611,
    "pmdecrad": -7.44334443e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 71678,
    "base": "Wo 9490",
    "lum": 0.0928966386779936,
    "ra": 14.709349,
    "dec": 19.479783,
    "pmra": -253.82,
    "pmdec": -153.53,
    "rv": 0,
    "Asset Name": "ASTRID 002250",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Audiohead",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00002136,
    "y_1": 0.00001666,
    "z_1": -0.00001514,
    "Distance in Parsecs_1": 21.5796,
    "HYG Star ID_1": 71688,
    "Hipparcos Catalogue_1": 71914,
    "Gliese Catalog_1": "Wo 9490B"
  },
  {
    "Astrid #": "ASTRID 002251",
    "HYG Star ID": 110294,
    "Hipparcos Catalogue": 110640,
    "Gliese Catalog": "Gl 857.1A",
    "Distance in Parsecs": 21.5843,
    "mag": 8.83,
    "Absolute Visual Magnitude": 7.159,
    "Spectral Type": "M0",
    "Color Index": 1.18,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.237239,
    "y": -8.047483,
    "z": 8.277724,
    "vx": -0.00001029,
    "vy": -0.0000153,
    "vz": -0.00000901,
    "rarad": 5.86761842961916,
    "decrad": 0.393591465054445,
    "pmrarad": -8.40957810291666e-7,
    "pmdecrad": -3.27879492e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 110294,
    "base": "Gl 857.1",
    "lum": 0.119233968788596,
    "ra": 22.412651,
    "dec": 22.55113,
    "pmra": -173.46,
    "pmdec": -67.63,
    "rv": -6.3,
    "Asset Name": "ASTRID 002251",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Malevolent",
    "Hair": "Windy",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00001029,
    "y_1": -0.0000153,
    "z_1": -0.00000901,
    "Distance in Parsecs_1": 21.5843,
    "HYG Star ID_1": 110294,
    "Hipparcos Catalogue_1": 110640,
    "Gliese Catalog_1": "Gl 857.1A"
  },
  {
    "Astrid #": "ASTRID 002252",
    "HYG Star ID": 3805,
    "Hipparcos Catalogue": 3813,
    "Gliese Catalog": "NN 3059",
    "Distance in Parsecs": 21.5889,
    "mag": 10.76,
    "Absolute Visual Magnitude": 9.089,
    "Spectral Type": "M2",
    "Color Index": 1.426,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.519827,
    "y": 2.937206,
    "z": -16.573103,
    "vx": -0.00002255,
    "vy": 0.0000228,
    "vz": -0.00001435,
    "rarad": 0.213921295666828,
    "decrad": -0.875193849287738,
    "pmrarad": 0.000001254019066125,
    "pmdecrad": -0.000001037404313,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 3805,
    "lum": 0.0201557981282518,
    "var_min": 10.839,
    "var_max": 10.699,
    "ra": 0.817119,
    "dec": -50.144914,
    "pmra": 258.66,
    "pmdec": -213.98,
    "rv": 0,
    "Asset Name": "ASTRID 002252",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "Side Part",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00002255,
    "y_1": 0.0000228,
    "z_1": -0.00001435,
    "Distance in Parsecs_1": 21.5889,
    "HYG Star ID_1": 3805,
    "Hipparcos Catalogue_1": 3813,
    "Gliese Catalog_1": "NN 3059"
  },
  {
    "Astrid #": "ASTRID 002253",
    "HYG Star ID": 65310,
    "Hipparcos Catalogue": 65515,
    "Henry Draper Catalogue": 116956,
    "Distance in Parsecs": 21.5936,
    "mag": 7.29,
    "Absolute Visual Magnitude": 5.618,
    "Spectral Type": "G9IV-V",
    "Color Index": 0.804,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.955587,
    "y": -4.302211,
    "z": 18.103858,
    "vx": -0.0000074,
    "vy": 0.00002154,
    "vz": 6.4e-7,
    "rarad": 3.51579400263176,
    "decrad": 0.994322683541613,
    "pmrarad": -0.00000105398494152777,
    "pmdecrad": 5.4347613e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 65310,
    "lum": 0.492946741312205,
    "var": "NQ",
    "var_min": 7.309,
    "var_max": 7.269,
    "ra": 13.429344,
    "dec": 56.970493,
    "pmra": -217.4,
    "pmdec": 11.21,
    "rv": 0,
    "Asset Name": "ASTRID 002253",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Horns",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.0000074,
    "y_1": 0.00002154,
    "z_1": 6.4e-7,
    "Distance in Parsecs_1": 21.5936,
    "HYG Star ID_1": 65310,
    "Hipparcos Catalogue_1": 65515,
    "Henry Draper Catalogue_1": 116956
  },
  {
    "Astrid #": "ASTRID 002254",
    "HYG Star ID": 77386,
    "Hipparcos Catalogue": 77622,
    "Henry Draper Catalogue": 141795,
    "Harvard Revised Catalogue": 5892,
    "Gliese Catalog": "NN 3921",
    "Distance in Parsecs": 21.5983,
    "mag": 3.71,
    "Absolute Visual Magnitude": 2.038,
    "Spectral Type": "A2m",
    "Color Index": 0.147,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -11.504554,
    "y": -18.201236,
    "z": 1.686207,
    "vx": 0.00001655,
    "vy": 0.00000111,
    "vz": 0.00000573,
    "rarad": 4.14871786506479,
    "decrad": 0.07815113359946,
    "pmrarad": 6.20464548375e-7,
    "pmdecrad": 2.99954224e-7,
    "bayer": "Eps",
    "flam": 37,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 77386,
    "lum": 13.3290746423133,
    "bf": "37Eps Ser",
    "ra": 15.846935,
    "dec": 4.47773,
    "pmra": 127.98,
    "pmdec": 61.87,
    "rv": -9.1,
    "Asset Name": "ASTRID 002254",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Oh No",
    "Hair": "Braid",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00001655,
    "y_1": 0.00000111,
    "z_1": 0.00000573,
    "Distance in Parsecs_1": 21.5983,
    "HYG Star ID_1": 77386,
    "Hipparcos Catalogue_1": 77622,
    "Henry Draper Catalogue_1": 141795,
    "Harvard Revised Catalogue_1": 5892,
    "Gliese Catalog_1": "NN 3921"
  },
  {
    "Astrid #": "ASTRID 002255",
    "HYG Star ID": 118136,
    "Gliese Catalog": "GJ 1047A",
    "Distance in Parsecs": 21.5983,
    "mag": 14.05,
    "Absolute Visual Magnitude": 12.378,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.104929,
    "y": 9.974211,
    "z": 12.963513,
    "vx": -0.00001421,
    "vy": 0.00008152,
    "vz": -0.00004723,
    "rarad": 0.615460988844984,
    "decrad": 0.6437634300269,
    "pmrarad": 0.00000346229689968749,
    "pmdecrad": -0.000002734339462,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118136,
    "base": "GJ 1047",
    "lum": 0.000974540741766305,
    "ra": 2.350888,
    "dec": 36.884928,
    "pmra": 714.15,
    "pmdec": -564,
    "rv": 0,
    "Asset Name": "ASTRID 002255",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Bangs",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001421,
    "y_1": 0.00008152,
    "z_1": -0.00004723,
    "Distance in Parsecs_1": 21.5983,
    "HYG Star ID_1": 118136,
    "Gliese Catalog_1": "GJ 1047A"
  },
  {
    "Astrid #": "ASTRID 002256",
    "HYG Star ID": 118137,
    "Gliese Catalog": "GJ 1047B",
    "Distance in Parsecs": 21.5983,
    "mag": 14.05,
    "Absolute Visual Magnitude": 12.378,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.104929,
    "y": 9.974211,
    "z": 12.963513,
    "vx": -0.00001421,
    "vy": 0.00008152,
    "vz": -0.00004723,
    "rarad": 0.615460988844984,
    "decrad": 0.6437634300269,
    "pmrarad": 0.00000346229689968749,
    "pmdecrad": -0.000002734339462,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118136,
    "base": "GJ 1047",
    "lum": 0.000974540741766305,
    "ra": 2.350888,
    "dec": 36.884928,
    "pmra": 714.15,
    "pmdec": -564,
    "rv": 0,
    "Asset Name": "ASTRID 002256",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Charmer",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001421,
    "y_1": 0.00008152,
    "z_1": -0.00004723,
    "Distance in Parsecs_1": 21.5983,
    "HYG Star ID_1": 118137,
    "Gliese Catalog_1": "GJ 1047B"
  },
  {
    "Astrid #": "ASTRID 002257",
    "HYG Star ID": 118138,
    "Gliese Catalog": "GJ 1047C",
    "Distance in Parsecs": 21.5983,
    "mag": 14.26,
    "Absolute Visual Magnitude": 12.588,
    "Spectral Type": "m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.107308,
    "y": 9.972798,
    "z": 12.962012,
    "vx": -0.0000142,
    "vy": 0.00008152,
    "vz": -0.00004723,
    "rarad": 0.615314736844984,
    "decrad": 0.6436765640269,
    "pmrarad": 0.00000346229689968749,
    "pmdecrad": -0.000002734339462,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 118136,
    "base": "GJ 1047",
    "lum": 0.000803156169922656,
    "ra": 2.350329,
    "dec": 36.87995,
    "pmra": 714.15,
    "pmdec": -564,
    "rv": 0,
    "Asset Name": "ASTRID 002257",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Honest Lad",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000142,
    "y_1": 0.00008152,
    "z_1": -0.00004723,
    "Distance in Parsecs_1": 21.5983,
    "HYG Star ID_1": 118138,
    "Gliese Catalog_1": "GJ 1047C"
  },
  {
    "Astrid #": "ASTRID 002258",
    "HYG Star ID": 15294,
    "Hipparcos Catalogue": 15332,
    "Gliese Catalog": "NN 3215",
    "Distance in Parsecs": 21.6029,
    "mag": 11.84,
    "Absolute Visual Magnitude": 10.167,
    "Spectral Type": "K:",
    "Color Index": 1.46,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.70523,
    "y": 14.843614,
    "z": 9.215722,
    "vx": -0.00005182,
    "vy": 0.00006576,
    "vz": -0.00003448,
    "rarad": 0.862860905823785,
    "decrad": 0.440726369684276,
    "pmrarad": 0.00000380215128975694,
    "pmdecrad": -0.000001764721797,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 15294,
    "lum": 0.00746792589930882,
    "var_min": 11.923,
    "var_max": 11.733,
    "ra": 3.295886,
    "dec": 25.251761,
    "pmra": 784.25,
    "pmdec": -364,
    "rv": 0,
    "Asset Name": "ASTRID 002258",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "High Fade",
    "Outfit": "Iron Star",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00005182,
    "y_1": 0.00006576,
    "z_1": -0.00003448,
    "Distance in Parsecs_1": 21.6029,
    "HYG Star ID_1": 15294,
    "Hipparcos Catalogue_1": 15332,
    "Gliese Catalog_1": "NN 3215"
  },
  {
    "Astrid #": "ASTRID 002259",
    "HYG Star ID": 27220,
    "Hipparcos Catalogue": 27288,
    "Henry Draper Catalogue": 38678,
    "Harvard Revised Catalogue": 1998,
    "Gliese Catalog": "Gl 217.1",
    "Distance in Parsecs": 21.6076,
    "mag": 3.55,
    "Absolute Visual Magnitude": 1.877,
    "Spectral Type": "A2Vann",
    "Color Index": 0.104,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 1.188275,
    "y": 20.854933,
    "z": -5.52761,
    "vx": 0.00000247,
    "vy": 0.00001597,
    "vz": -0.00000438,
    "rarad": 1.51387976246943,
    "decrad": -0.258691829057008,
    "pmrarad": -7.19463501944444e-8,
    "pmdecrad": -5.720801e-9,
    "bayer": "Zet",
    "flam": 14,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 27220,
    "lum": 15.4596621938667,
    "bf": "14Zet Lep",
    "ra": 5.782595,
    "dec": -14.82195,
    "pmra": -14.84,
    "pmdec": -1.18,
    "rv": 16.3,
    "Asset Name": "ASTRID 002259",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Fire",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000247,
    "y_1": 0.00001597,
    "z_1": -0.00000438,
    "Distance in Parsecs_1": 21.6076,
    "HYG Star ID_1": 27220,
    "Hipparcos Catalogue_1": 27288,
    "Henry Draper Catalogue_1": 38678,
    "Harvard Revised Catalogue_1": 1998,
    "Gliese Catalog_1": "Gl 217.1"
  },
  {
    "Astrid #": "ASTRID 002260",
    "HYG Star ID": 7358,
    "Hipparcos Catalogue": 7372,
    "Henry Draper Catalogue": 9770,
    "Gliese Catalog": "Gl 60A",
    "Distance in Parsecs": 21.6263,
    "mag": 7.11,
    "Absolute Visual Magnitude": 5.435,
    "Spectral Type": "K3V",
    "Color Index": 0.909,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.157955,
    "y": 7.551191,
    "z": -10.783979,
    "vx": 0.00002876,
    "vy": 0.00002246,
    "vz": -0.00000866,
    "rarad": 0.414588872557619,
    "decrad": -0.522033785960795,
    "pmrarad": 4.14806585083333e-7,
    "pmdecrad": 4.68233052e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 7358,
    "base": "Gl  60",
    "lum": 0.583445104273744,
    "var": "BB",
    "var_min": 7.287,
    "var_max": 7.087,
    "ra": 1.583613,
    "dec": -29.910333,
    "pmra": 85.56,
    "pmdec": 96.58,
    "rv": 34.2,
    "Asset Name": "ASTRID 002260",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Slick",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00002876,
    "y_1": 0.00002246,
    "z_1": -0.00000866,
    "Distance in Parsecs_1": 21.6263,
    "HYG Star ID_1": 7358,
    "Hipparcos Catalogue_1": 7372,
    "Henry Draper Catalogue_1": 9770,
    "Gliese Catalog_1": "Gl 60A"
  },
  {
    "Astrid #": "ASTRID 002261",
    "HYG Star ID": 3866,
    "Hipparcos Catalogue": 3876,
    "Henry Draper Catalogue": 4635,
    "Distance in Parsecs": 21.631,
    "mag": 7.75,
    "Absolute Visual Magnitude": 6.075,
    "Spectral Type": "K0",
    "Color Index": 0.9,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.06842,
    "y": 1.559744,
    "z": 20.383664,
    "vx": -0.00003733,
    "vy": 0.00003154,
    "vz": -0.0000204,
    "rarad": 0.217152046235598,
    "decrad": 1.22957549959856,
    "pmrarad": 0.00000179579835414583,
    "pmdecrad": 9.76754122e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 3866,
    "lum": 0.323593656929628,
    "ra": 0.82946,
    "dec": 70.449487,
    "pmra": 370.41,
    "pmdec": 201.47,
    "rv": -28.5,
    "Asset Name": "ASTRID 002261",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Upset",
    "Hair": "Wavy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00003733,
    "y_1": 0.00003154,
    "z_1": -0.0000204,
    "Distance in Parsecs_1": 21.631,
    "HYG Star ID_1": 3866,
    "Hipparcos Catalogue_1": 3876,
    "Henry Draper Catalogue_1": 4635
  },
  {
    "Astrid #": "ASTRID 002262",
    "HYG Star ID": 118533,
    "Gliese Catalog": "Gl 301B",
    "Distance in Parsecs": 21.6403,
    "mag": 10.7,
    "Absolute Visual Magnitude": 9.024,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.528541,
    "y": 17.558771,
    "z": -5.204319,
    "vx": 0.00002847,
    "vy": 0.00000387,
    "vz": -0.00005003,
    "rarad": 2.15177727742163,
    "decrad": -0.24287270757055,
    "pmrarad": -0.00000119807156738888,
    "pmdecrad": -0.000002382151411,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 40125,
    "base": "Gl 301",
    "lum": 0.0213993213245594,
    "ra": 8.219184,
    "dec": -13.915581,
    "pmra": -247.12,
    "pmdec": -491.35,
    "rv": 0,
    "Asset Name": "ASTRID 002262",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Ninja",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002847,
    "y_1": 0.00000387,
    "z_1": -0.00005003,
    "Distance in Parsecs_1": 21.6403,
    "HYG Star ID_1": 118533,
    "Gliese Catalog_1": "Gl 301B"
  },
  {
    "Astrid #": "ASTRID 002263",
    "HYG Star ID": 5829,
    "Hipparcos Catalogue": 5842,
    "Henry Draper Catalogue": 7693,
    "Gliese Catalog": "Gl 55.1A",
    "Distance in Parsecs": 21.645,
    "mag": 7.22,
    "Absolute Visual Magnitude": 5.543,
    "Spectral Type": "K2V",
    "Color Index": 1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.405478,
    "y": 2.514417,
    "z": -20.182731,
    "vx": -0.00000701,
    "vy": 0.00004378,
    "vz": 0.00000288,
    "rarad": 0.327290191973842,
    "decrad": -1.20111656211008,
    "pmrarad": 0.00000201953986772222,
    "pmdecrad": 3.6821599e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 5829,
    "base": "Gl  55.1",
    "lum": 0.528201949769996,
    "ra": 1.250156,
    "dec": -68.81891,
    "pmra": 416.56,
    "pmdec": 75.95,
    "rv": 0,
    "Asset Name": "ASTRID 002263",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Frizzy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000701,
    "y_1": 0.00004378,
    "z_1": 0.00000288,
    "Distance in Parsecs_1": 21.645,
    "HYG Star ID_1": 5829,
    "Hipparcos Catalogue_1": 5842,
    "Henry Draper Catalogue_1": 7693,
    "Gliese Catalog_1": "Gl 55.1A"
  },
  {
    "Astrid #": "ASTRID 002264",
    "HYG Star ID": 118350,
    "Gliese Catalog": "NN 3329",
    "Distance in Parsecs": 21.645,
    "mag": 15.97,
    "Absolute Visual Magnitude": 14.293,
    "Spectral Type": "DC9?",
    "Color Index": 0.74,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.832633,
    "y": 20.083757,
    "z": -6.46486,
    "vx": -0.00001385,
    "vy": 0.00002446,
    "vz": 0.00006558,
    "rarad": 1.33465937893888,
    "decrad": -0.30330586564,
    "pmrarad": 8.86578777631944e-7,
    "pmdecrad": 0.0000031752872,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118350,
    "lum": 0.000167032122581504,
    "ra": 5.098023,
    "dec": -17.378146,
    "pmra": 182.87,
    "pmdec": 654.95,
    "rv": 0,
    "Asset Name": "ASTRID 002264",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Horns",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001385,
    "y_1": 0.00002446,
    "z_1": 0.00006558,
    "Distance in Parsecs_1": 21.645,
    "HYG Star ID_1": 118350,
    "Gliese Catalog_1": "NN 3329"
  },
  {
    "Astrid #": "ASTRID 002265",
    "HYG Star ID": 16204,
    "Hipparcos Catalogue": 16245,
    "Henry Draper Catalogue": 22001,
    "Harvard Revised Catalogue": 1083,
    "Gliese Catalog": "Gl 143.2A",
    "Distance in Parsecs": 21.6826,
    "mag": 4.71,
    "Absolute Visual Magnitude": 3.029,
    "Spectral Type": "F5IV-V",
    "Color Index": 0.41,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.026531,
    "y": 7.809946,
    "z": -19.30869,
    "vx": -0.00000713,
    "vy": 0.00005667,
    "vz": 0.00000691,
    "rarad": 0.913564224439529,
    "decrad": -1.09846707622717,
    "pmrarad": 0.00000185707880336805,
    "pmdecrad": 0.000001808888323,
    "bayer": "Kap",
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 16204,
    "base": "Gl 143.2",
    "lum": 5.35056938173137,
    "bf": "Kap Ret",
    "ra": 3.489558,
    "dec": -62.937527,
    "pmra": 383.05,
    "pmdec": 373.11,
    "rv": 12,
    "Asset Name": "ASTRID 002265",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Lovestruck",
    "Hair": "Braided Bun",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": -0.00000713,
    "y_1": 0.00005667,
    "z_1": 0.00000691,
    "Distance in Parsecs_1": 21.6826,
    "HYG Star ID_1": 16204,
    "Hipparcos Catalogue_1": 16245,
    "Henry Draper Catalogue_1": 22001,
    "Harvard Revised Catalogue_1": 1083,
    "Gliese Catalog_1": "Gl 143.2A"
  },
  {
    "Astrid #": "ASTRID 002266",
    "HYG Star ID": 119075,
    "Henry Draper Catalogue": 146362,
    "Harvard Revised Catalogue": 6064,
    "Gliese Catalog": "Gl 615.2B",
    "Distance in Parsecs": 21.6873,
    "mag": 6.72,
    "Absolute Visual Magnitude": 5.039,
    "Spectral Type": "G1 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.988329,
    "y": -16.140612,
    "z": 12.081973,
    "vx": -0.0000219,
    "vy": 0.00001941,
    "vz": -0.00001516,
    "rarad": 4.25282607109863,
    "decrad": 0.59091127652725,
    "pmrarad": -0.00000130225802734027,
    "pmdecrad": -3.83269455e-7,
    "bayer": "Sig",
    "flam": 17,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 2,
    "comp_primary": 79366,
    "base": "Gl 615.2",
    "lum": 0.840233513907691,
    "bf": "17Sig CrB",
    "ra": 16.244599,
    "dec": 33.856722,
    "pmra": -268.61,
    "pmdec": -79.06,
    "rv": -14.5,
    "Asset Name": "ASTRID 002266",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "High Fade",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": -0.0000219,
    "y_1": 0.00001941,
    "z_1": -0.00001516,
    "Distance in Parsecs_1": 21.6873,
    "HYG Star ID_1": 119075,
    "Henry Draper Catalogue_1": 146362,
    "Harvard Revised Catalogue_1": 6064,
    "Gliese Catalog_1": "Gl 615.2B"
  },
  {
    "Astrid #": "ASTRID 002267",
    "HYG Star ID": 63539,
    "Hipparcos Catalogue": 63742,
    "Henry Draper Catalogue": 113449,
    "Distance in Parsecs": 21.692,
    "mag": 7.69,
    "Absolute Visual Magnitude": 6.009,
    "Spectral Type": "G5V",
    "Color Index": 0.847,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -20.771601,
    "y": -5.939253,
    "z": -1.951603,
    "vx": -0.00000349,
    "vy": 0.00001976,
    "vz": -0.00002299,
    "rarad": 3.42009308588101,
    "decrad": -0.0900906195738062,
    "pmrarad": -9.20127884326388e-7,
    "pmdecrad": -0.000001064408435,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 63539,
    "lum": 0.343874522229126,
    "var": "PX",
    "var_min": 7.731,
    "var_max": 7.661,
    "ra": 13.063793,
    "dec": -5.161812,
    "pmra": -189.79,
    "pmdec": -219.55,
    "rv": 0,
    "Asset Name": "ASTRID 002267",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Frizzy",
    "Outfit": "Boxer",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000349,
    "y_1": 0.00001976,
    "z_1": -0.00002299,
    "Distance in Parsecs_1": 21.692,
    "HYG Star ID_1": 63539,
    "Hipparcos Catalogue_1": 63742,
    "Henry Draper Catalogue_1": 113449
  },
  {
    "Astrid #": "ASTRID 002268",
    "HYG Star ID": 33473,
    "Hipparcos Catalogue": 33560,
    "Henry Draper Catalogue": 51849,
    "Gliese Catalog": "Gl 256",
    "Distance in Parsecs": 21.7014,
    "mag": 9.14,
    "Absolute Visual Magnitude": 7.458,
    "Spectral Type": "K4V",
    "Color Index": 1.162,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.333265,
    "y": 20.462213,
    "z": -4.87872,
    "vx": -0.00000278,
    "vy": -0.00001222,
    "vz": -0.0000132,
    "rarad": 1.8257634354995,
    "decrad": -0.226750099918972,
    "pmrarad": 2.66114229256944e-7,
    "pmdecrad": -7.07876454e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 33473,
    "lum": 0.0905315591648484,
    "ra": 6.973903,
    "dec": -12.991824,
    "pmra": 54.89,
    "pmdec": -146.01,
    "rv": -7.7,
    "Asset Name": "ASTRID 002268",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Medium Bob",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00000278,
    "y_1": -0.00001222,
    "z_1": -0.0000132,
    "Distance in Parsecs_1": 21.7014,
    "HYG Star ID_1": 33473,
    "Hipparcos Catalogue_1": 33560,
    "Henry Draper Catalogue_1": 51849,
    "Gliese Catalog_1": "Gl 256"
  },
  {
    "Astrid #": "ASTRID 002269",
    "HYG Star ID": 14551,
    "Hipparcos Catalogue": 14587,
    "Henry Draper Catalogue": 19819,
    "Gliese Catalog": "GJ 1056",
    "Distance in Parsecs": 21.7108,
    "mag": 9.27,
    "Absolute Visual Magnitude": 7.587,
    "Spectral Type": "K5V",
    "Color Index": 1.207,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.35182,
    "y": 7.909694,
    "z": -18.83471,
    "vx": -0.000008,
    "vy": 0.00002394,
    "vz": 0.00000693,
    "rarad": 0.821927217358826,
    "decrad": -1.05020909031245,
    "pmrarad": 0.00000102072672304166,
    "pmdecrad": 6.42135719e-7,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 14551,
    "lum": 0.0803896244857125,
    "ra": 3.139531,
    "dec": -60.172548,
    "pmra": 210.54,
    "pmdec": 132.45,
    "rv": 0,
    "Asset Name": "ASTRID 002269",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "High Fade",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": -0.000008,
    "y_1": 0.00002394,
    "z_1": 0.00000693,
    "Distance in Parsecs_1": 21.7108,
    "HYG Star ID_1": 14551,
    "Hipparcos Catalogue_1": 14587,
    "Henry Draper Catalogue_1": 19819,
    "Gliese Catalog_1": "GJ 1056"
  },
  {
    "Astrid #": "ASTRID 002270",
    "HYG Star ID": 111365,
    "Hipparcos Catalogue": 111715,
    "Distance in Parsecs": 21.7391,
    "mag": 12.09,
    "Absolute Visual Magnitude": 10.404,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.285792,
    "y": -4.607021,
    "z": -17.332718,
    "vx": -0.00000227,
    "vy": -7.5e-7,
    "vz": -0.00000141,
    "rarad": 5.924425825619,
    "decrad": -0.922818838205295,
    "pmrarad": -6.88435426388888e-8,
    "pmdecrad": -1.07337748e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 111365,
    "lum": 0.00600343758732564,
    "ra": 22.62964,
    "dec": -52.873625,
    "pmra": -14.2,
    "pmdec": -22.14,
    "rv": 0,
    "Asset Name": "ASTRID 002270",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Undercut",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000227,
    "y_1": -7.5e-7,
    "z_1": -0.00000141,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 111365,
    "Hipparcos Catalogue_1": 111715
  },
  {
    "Astrid #": "ASTRID 002271",
    "HYG Star ID": 118024,
    "Gliese Catalog": "GJ 1022",
    "Distance in Parsecs": 21.7391,
    "mag": 12.16,
    "Absolute Visual Magnitude": 10.474,
    "Spectral Type": "M6",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.286052,
    "y": 2.260177,
    "z": -19.017697,
    "vx": -0.00003292,
    "vy": 0.00011225,
    "vz": -0.00000216,
    "rarad": 0.216009161094239,
    "decrad": -1.0650628803192,
    "pmrarad": 0.00000536834188479166,
    "pmdecrad": -3.75386384e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118024,
    "lum": 0.00562859499282729,
    "ra": 0.825094,
    "dec": -61.023608,
    "pmra": 1107.3,
    "pmdec": -77.43,
    "rv": -2,
    "Asset Name": "ASTRID 002271",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Slick",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003292,
    "y_1": 0.00011225,
    "z_1": -0.00000216,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118024,
    "Gliese Catalog_1": "GJ 1022"
  },
  {
    "Astrid #": "ASTRID 002272",
    "HYG Star ID": 118065,
    "Gliese Catalog": "NN 3092B",
    "Distance in Parsecs": 21.7391,
    "mag": 14.85,
    "Absolute Visual Magnitude": 13.164,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.153033,
    "y": 6.801746,
    "z": -9.838298,
    "vx": -0.00000786,
    "vy": -0.00001039,
    "vz": -0.00002168,
    "rarad": 0.358500148787515,
    "decrad": -0.46963670074285,
    "pmrarad": -3.20704249687499e-7,
    "pmdecrad": -0.000001118474857,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118065,
    "base": "NN 3092",
    "lum": 0.000472498027680194,
    "ra": 1.36937,
    "dec": -26.908201,
    "pmra": -66.15,
    "pmdec": -230.7,
    "rv": 0,
    "Asset Name": "ASTRID 002272",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Oh No",
    "Hair": "Heartbreaker",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000786,
    "y_1": -0.00001039,
    "z_1": -0.00002168,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118065,
    "Gliese Catalog_1": "NN 3092B"
  },
  {
    "Astrid #": "ASTRID 002273",
    "HYG Star ID": 118108,
    "Gliese Catalog": "NN 3132",
    "Distance in Parsecs": 21.7391,
    "mag": 13.9,
    "Absolute Visual Magnitude": 12.214,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.601606,
    "y": 11.227608,
    "z": -0.713828,
    "vx": 0.00002976,
    "vy": -0.00005326,
    "vz": -0.00006267,
    "rarad": 0.543049765281873,
    "decrad": -0.0328420495542,
    "pmrarad": -0.00000280537436253472,
    "pmdecrad": -0.000002884811084,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118108,
    "lum": 0.00113344382297103,
    "ra": 2.074297,
    "dec": -1.881711,
    "pmra": -578.65,
    "pmdec": -595.03,
    "rv": 0,
    "Asset Name": "ASTRID 002273",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Straw Hat",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002976,
    "y_1": -0.00005326,
    "z_1": -0.00006267,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118108,
    "Gliese Catalog_1": "NN 3132"
  },
  {
    "Astrid #": "ASTRID 002274",
    "HYG Star ID": 118286,
    "Gliese Catalog": "NN 3272",
    "Distance in Parsecs": 21.7391,
    "mag": 13.2,
    "Absolute Visual Magnitude": 11.514,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.133039,
    "y": 12.861284,
    "z": -16.418333,
    "vx": 0.00001266,
    "vy": 0.00003787,
    "vz": 0.00003438,
    "rarad": 1.12582518077195,
    "decrad": -0.8560268778457,
    "pmrarad": 2.23935439048611e-7,
    "pmdecrad": 0.000002413703086,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118286,
    "lum": 0.00215973268068939,
    "ra": 4.300335,
    "dec": -49.046727,
    "pmra": 46.19,
    "pmdec": 497.86,
    "rv": 0,
    "Asset Name": "ASTRID 002274",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Headband",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001266,
    "y_1": 0.00003787,
    "z_1": 0.00003438,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118286,
    "Gliese Catalog_1": "NN 3272"
  },
  {
    "Astrid #": "ASTRID 002275",
    "HYG Star ID": 118375,
    "Gliese Catalog": "GJ 2043B",
    "Distance in Parsecs": 21.7391,
    "mag": 14.68,
    "Absolute Visual Magnitude": 12.994,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.785016,
    "y": 20.754494,
    "z": 5.838077,
    "vx": 0.00000965,
    "vy": 0.00000354,
    "vz": -0.00001719,
    "rarad": 1.43740541998595,
    "decrad": 0.271889385703649,
    "pmrarad": -4.18200280847222e-7,
    "pmdecrad": -8.20745927e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 25652,
    "base": "GJ 2043",
    "lum": 0.000552586155630078,
    "ra": 5.490484,
    "dec": 15.578114,
    "pmra": -86.26,
    "pmdec": -169.29,
    "rv": 0,
    "Asset Name": "ASTRID 002275",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Goddess",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000965,
    "y_1": 0.00000354,
    "z_1": -0.00001719,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118375,
    "Gliese Catalog_1": "GJ 2043B"
  },
  {
    "Astrid #": "ASTRID 002276",
    "HYG Star ID": 118433,
    "Gliese Catalog": "NN 3399",
    "Distance in Parsecs": 21.7391,
    "mag": 11.93,
    "Absolute Visual Magnitude": 10.244,
    "Spectral Type": "k-m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.322579,
    "y": 18.797251,
    "z": 10.402514,
    "vx": -0.00000489,
    "vy": 0.00001187,
    "vz": -0.00002301,
    "rarad": 1.74574710109073,
    "decrad": 0.49896426638735,
    "pmrarad": 1.26681814729166e-7,
    "pmdecrad": -0.000001205392253,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 118433,
    "lum": 0.00695664753528352,
    "ra": 6.668263,
    "dec": 28.588547,
    "pmra": 26.13,
    "pmdec": -248.63,
    "rv": 0,
    "Asset Name": "ASTRID 002276",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Windy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000489,
    "y_1": 0.00001187,
    "z_1": -0.00002301,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118433,
    "Gliese Catalog_1": "NN 3399"
  },
  {
    "Astrid #": "ASTRID 002277",
    "HYG Star ID": 118434,
    "Gliese Catalog": "NN 3401B",
    "Distance in Parsecs": 21.7391,
    "mag": 8.3,
    "Absolute Visual Magnitude": 6.614,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.710276,
    "y": 10.219947,
    "z": -19.110627,
    "vx": 0.00000271,
    "vy": 0.00000615,
    "vz": 0.00000305,
    "rarad": 1.73661599476059,
    "decrad": -1.07394991205495,
    "pmrarad": -1.69684788194444e-7,
    "pmdecrad": 2.93898901e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 2,
    "comp_primary": 118434,
    "base": "NN 3401",
    "lum": 0.196969961489992,
    "ra": 6.633384,
    "dec": -61.532797,
    "pmra": -35,
    "pmdec": 60.62,
    "rv": 0,
    "Asset Name": "ASTRID 002277",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Braided Bun",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00000271,
    "y_1": 0.00000615,
    "z_1": 0.00000305,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118434,
    "Gliese Catalog_1": "NN 3401B"
  },
  {
    "Astrid #": "ASTRID 002278",
    "HYG Star ID": 118470,
    "Gliese Catalog": "Wo 9231B",
    "Distance in Parsecs": 21.7391,
    "mag": 10,
    "Absolute Visual Magnitude": 8.314,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.964027,
    "y": 19.641369,
    "z": 6.189297,
    "vx": 0.00000416,
    "vy": 0.00000271,
    "vz": -0.00000392,
    "rarad": 1.91152745646298,
    "decrad": 0.28870185391775,
    "pmrarad": -2.21850740222222e-7,
    "pmdecrad": -1.88141645e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 2,
    "comp_primary": 35253,
    "base": "Wo 9231",
    "lum": 0.041152857843551,
    "ra": 7.301497,
    "dec": 16.541398,
    "pmra": -45.76,
    "pmdec": -38.81,
    "rv": 0,
    "Asset Name": "ASTRID 002278",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Malevolent",
    "Hair": "Sweetheart",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00000416,
    "y_1": 0.00000271,
    "z_1": -0.00000392,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118470,
    "Gliese Catalog_1": "Wo 9231B"
  },
  {
    "Astrid #": "ASTRID 002279",
    "HYG Star ID": 118483,
    "Gliese Catalog": "NN 3450B",
    "Distance in Parsecs": 21.7391,
    "mag": 8.6,
    "Absolute Visual Magnitude": 6.914,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -8.400694,
    "y": 19.767538,
    "z": -3.355778,
    "vx": 0.0000097,
    "vy": 0.00000136,
    "vz": -0.00001625,
    "rarad": 1.97264540783422,
    "decrad": -0.15498570240575,
    "pmrarad": -4.350233155625e-7,
    "pmdecrad": -7.56508115e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 2,
    "comp_primary": 118483,
    "base": "NN 3450",
    "lum": 0.14941699574067,
    "ra": 7.53495,
    "dec": -8.880027,
    "pmra": -89.73,
    "pmdec": -156.04,
    "rv": 0,
    "Asset Name": "ASTRID 002279",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Afro",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.0000097,
    "y_1": 0.00000136,
    "z_1": -0.00001625,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118483,
    "Gliese Catalog_1": "NN 3450B"
  },
  {
    "Astrid #": "ASTRID 002280",
    "HYG Star ID": 118506,
    "Gliese Catalog": "NN 3464",
    "Distance in Parsecs": 21.7391,
    "mag": 8.6,
    "Absolute Visual Magnitude": 6.914,
    "Spectral Type": "K4",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.23851,
    "y": 17.23046,
    "z": 9.505243,
    "vx": -0.00000616,
    "vy": 0.00000436,
    "vz": -0.00001389,
    "rarad": 2.06296108389854,
    "decrad": 0.45252945582315,
    "pmrarad": 1.548979709375e-7,
    "pmdecrad": -7.10523537e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 118506,
    "lum": 0.14941699574067,
    "ra": 7.879931,
    "dec": 25.928028,
    "pmra": 31.95,
    "pmdec": -146.56,
    "rv": 0,
    "Asset Name": "ASTRID 002280",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Determined Neko",
    "Hair": "Charmer",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000616,
    "y_1": 0.00000436,
    "z_1": -0.00001389,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118506,
    "Gliese Catalog_1": "NN 3464"
  },
  {
    "Astrid #": "ASTRID 002281",
    "HYG Star ID": 118566,
    "Gliese Catalog": "NN 3515",
    "Distance in Parsecs": 21.7391,
    "mag": 15.4,
    "Absolute Visual Magnitude": 13.714,
    "Spectral Type": "m",
    "Color Index": 1.78,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.895791,
    "y": 9.635125,
    "z": 17.33833,
    "vx": 0.00001261,
    "vy": 0.00002235,
    "vz": -0.00000595,
    "rarad": 2.31635525651849,
    "decrad": 0.92324667835705,
    "pmrarad": -0.00000112374963015972,
    "pmdecrad": -4.54032859e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118566,
    "lum": 0.000284708215979928,
    "ra": 8.847825,
    "dec": 52.898138,
    "pmra": -231.79,
    "pmdec": -93.65,
    "rv": 0,
    "Asset Name": "ASTRID 002281",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "High Bun",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001261,
    "y_1": 0.00002235,
    "z_1": -0.00000595,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118566,
    "Gliese Catalog_1": "NN 3515"
  },
  {
    "Astrid #": "ASTRID 002282",
    "HYG Star ID": 118657,
    "Gliese Catalog": "NN 3576",
    "Distance in Parsecs": 21.7391,
    "mag": 14.66,
    "Absolute Visual Magnitude": 12.974,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.333545,
    "y": 10.801868,
    "z": 4.44851,
    "vx": 0.00002014,
    "vy": 0.00004094,
    "vz": -0.00001638,
    "rarad": 2.60916481454298,
    "decrad": 0.20608746282475,
    "pmrarad": -0.00000209298914032638,
    "pmdecrad": -7.70063505e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118657,
    "lum": 0.000562859499282729,
    "ra": 9.966275,
    "dec": 11.807942,
    "pmra": -431.71,
    "pmdec": -158.84,
    "rv": 0,
    "Asset Name": "ASTRID 002282",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Sweetheart",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002014,
    "y_1": 0.00004094,
    "z_1": -0.00001638,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118657,
    "Gliese Catalog_1": "NN 3576"
  },
  {
    "Astrid #": "ASTRID 002283",
    "HYG Star ID": 118661,
    "Gliese Catalog": "GJ 2077",
    "Distance in Parsecs": 21.7391,
    "mag": 15.37,
    "Absolute Visual Magnitude": 13.684,
    "Spectral Type": "DC7",
    "Color Index": 0.36,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -18.288252,
    "y": 10.365349,
    "z": 5.511503,
    "vx": 0.00013707,
    "vy": -0.00004021,
    "vz": -0.00003411,
    "rarad": 2.62596316510871,
    "decrad": 0.2564135668055,
    "pmrarad": -0.00000149923782575,
    "pmdecrad": 1.0483611e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118661,
    "lum": 0.000292684686237878,
    "ra": 10.03044,
    "dec": 14.691415,
    "pmra": -309.24,
    "pmdec": 21.62,
    "rv": -140,
    "Asset Name": "ASTRID 002283",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Frizzy",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00013707,
    "y_1": -0.00004021,
    "z_1": -0.00003411,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118661,
    "Gliese Catalog_1": "GJ 2077"
  },
  {
    "Astrid #": "ASTRID 002284",
    "HYG Star ID": 118663,
    "Gliese Catalog": "NN 3583",
    "Distance in Parsecs": 21.7391,
    "mag": 13.17,
    "Absolute Visual Magnitude": 11.484,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.890044,
    "y": 10.519232,
    "z": 2.258428,
    "vx": 0.00003125,
    "vy": 0.00005923,
    "vz": -0.0000144,
    "rarad": 2.63349374808623,
    "decrad": 0.10407562575405,
    "pmrarad": -0.00000308011827530555,
    "pmdecrad": -6.65944919e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118663,
    "lum": 0.00222024039534507,
    "ra": 10.059205,
    "dec": 5.963094,
    "pmra": -635.32,
    "pmdec": -137.36,
    "rv": 0,
    "Asset Name": "ASTRID 002284",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Bring It",
    "Hair": "Undercut",
    "Outfit": "Astro",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003125,
    "y_1": 0.00005923,
    "z_1": -0.0000144,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118663,
    "Gliese Catalog_1": "NN 3583"
  },
  {
    "Astrid #": "ASTRID 002285",
    "HYG Star ID": 118717,
    "Gliese Catalog": "NN 3631",
    "Distance in Parsecs": 21.7391,
    "mag": 14.4,
    "Absolute Visual Magnitude": 12.714,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.685196,
    "y": 6.299807,
    "z": 2.241326,
    "vx": 0.00001751,
    "vy": 0.0000625,
    "vz": -0.00001396,
    "rarad": 2.84596180813996,
    "decrad": 0.10328467240775,
    "pmrarad": -0.00000298533720075694,
    "pmdecrad": -6.45451845e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118717,
    "lum": 0.000715154704659281,
    "ra": 10.870773,
    "dec": 5.917776,
    "pmra": -615.77,
    "pmdec": -133.13,
    "rv": 0,
    "Asset Name": "ASTRID 002285",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Braid",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001751,
    "y_1": 0.0000625,
    "z_1": -0.00001396,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118717,
    "Gliese Catalog_1": "NN 3631"
  },
  {
    "Astrid #": "ASTRID 002286",
    "HYG Star ID": 118730,
    "Gliese Catalog": "NN 3646",
    "Distance in Parsecs": 21.7391,
    "mag": 14.52,
    "Absolute Visual Magnitude": 12.834,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.227024,
    "y": 3.097669,
    "z": 16.142636,
    "vx": -0.00003156,
    "vy": 0.00003871,
    "vz": -0.00003523,
    "rarad": 2.92724263280447,
    "decrad": 0.83688791723315,
    "pmrarad": -0.00000143136391047222,
    "pmdecrad": -0.000002420335337,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118730,
    "lum": 0.000640324324468947,
    "ra": 11.181243,
    "dec": 47.950146,
    "pmra": -295.24,
    "pmdec": -499.23,
    "rv": 0,
    "Asset Name": "ASTRID 002286",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Prince",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003156,
    "y_1": 0.00003871,
    "z_1": -0.00003523,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118730,
    "Gliese Catalog_1": "NN 3646"
  },
  {
    "Astrid #": "ASTRID 002287",
    "HYG Star ID": 118808,
    "Gliese Catalog": "GJ 1155A",
    "Distance in Parsecs": 21.7391,
    "mag": 13.28,
    "Absolute Visual Magnitude": 11.594,
    "Spectral Type": "sdM3",
    "Color Index": 1.62,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": -21.651491,
    "y": -1.595139,
    "z": 1.126088,
    "vx": -0.00000865,
    "vy": 0.0000677,
    "vz": 0.00002846,
    "rarad": 3.21513337864825,
    "decrad": 0.05182271845955,
    "pmrarad": -0.00000313538703488888,
    "pmdecrad": 0.000001298709191,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118808,
    "base": "GJ 1155",
    "lum": 0.00200631906479057,
    "ra": 12.280905,
    "dec": 2.969223,
    "pmra": -646.72,
    "pmdec": 267.88,
    "rv": 5,
    "Asset Name": "ASTRID 002287",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Prince",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000865,
    "y_1": 0.0000677,
    "z_1": 0.00002846,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118808,
    "Gliese Catalog_1": "GJ 1155A"
  },
  {
    "Astrid #": "ASTRID 002288",
    "HYG Star ID": 118809,
    "Gliese Catalog": "GJ 1155B",
    "Distance in Parsecs": 21.7391,
    "mag": 15.32,
    "Absolute Visual Magnitude": 13.634,
    "Spectral Type": "DA s",
    "Color Index": 0.38,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -21.651236,
    "y": -1.59512,
    "z": 1.126075,
    "vx": -0.00000356,
    "vy": 0.00006808,
    "vz": 0.00002819,
    "rarad": 3.21513337864825,
    "decrad": 0.05182271845955,
    "pmrarad": -0.00000313538703488888,
    "pmdecrad": 0.000001298709191,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118808,
    "base": "GJ 1155",
    "lum": 0.000306478490537002,
    "ra": 12.280905,
    "dec": 2.969223,
    "pmra": -646.72,
    "pmdec": 267.88,
    "rv": 0,
    "Asset Name": "ASTRID 002288",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Ponytail",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000356,
    "y_1": 0.00006808,
    "z_1": 0.00002819,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118809,
    "Gliese Catalog_1": "GJ 1155B"
  },
  {
    "Astrid #": "ASTRID 002289",
    "HYG Star ID": 118824,
    "Gliese Catalog": "NN 3730",
    "Distance in Parsecs": 21.7391,
    "mag": 14.18,
    "Absolute Visual Magnitude": 12.494,
    "Spectral Type": "dM5",
    "Color Index": 1.6,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -19.846316,
    "y": -2.565296,
    "z": 8.493024,
    "vx": -0.00000269,
    "vy": 0.00001877,
    "vz": -6.1e-7,
    "rarad": 3.2701417434335,
    "decrad": 0.40136980544265,
    "pmrarad": -8.72131329951388e-7,
    "pmdecrad": -3.0451147e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118824,
    "lum": 0.000875790036306367,
    "ra": 12.491021,
    "dec": 22.996796,
    "pmra": -179.89,
    "pmdec": -6.28,
    "rv": 0,
    "Asset Name": "ASTRID 002289",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Ram Horns",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000269,
    "y_1": 0.00001877,
    "z_1": -6.1e-7,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118824,
    "Gliese Catalog_1": "NN 3730"
  },
  {
    "Astrid #": "ASTRID 002290",
    "HYG Star ID": 118844,
    "Gliese Catalog": "NN 3745",
    "Distance in Parsecs": 21.7391,
    "mag": 14.42,
    "Absolute Visual Magnitude": 12.734,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.845717,
    "y": -2.601269,
    "z": 17.343858,
    "vx": -0.0000012,
    "vy": 0.00002614,
    "vz": 0.00000303,
    "rarad": 3.34143088693732,
    "decrad": 0.92366828429105,
    "pmrarad": -0.00000118978125345138,
    "pmdecrad": 2.31265821e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118844,
    "lum": 0.000702101660240313,
    "ra": 12.763326,
    "dec": 52.922294,
    "pmra": -245.41,
    "pmdec": 47.7,
    "rv": 0,
    "Asset Name": "ASTRID 002290",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Infatuated",
    "Hair": "Afro",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000012,
    "y_1": 0.00002614,
    "z_1": 0.00000303,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118844,
    "Gliese Catalog_1": "NN 3745"
  },
  {
    "Astrid #": "ASTRID 002291",
    "HYG Star ID": 118875,
    "Gliese Catalog": "NN 3772",
    "Distance in Parsecs": 21.7391,
    "mag": 13.48,
    "Absolute Visual Magnitude": 11.794,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.565114,
    "y": -6.890556,
    "z": 1.47811,
    "vx": -0.00002396,
    "vy": 0.00007196,
    "vz": 0.00000225,
    "rarad": 3.46489717164518,
    "decrad": 0.0680456725677,
    "pmrarad": -0.00000348910709622222,
    "pmdecrad": 1.03551354e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118875,
    "lum": 0.00166878351136538,
    "ra": 13.234932,
    "dec": 3.89873,
    "pmra": -719.68,
    "pmdec": 21.36,
    "rv": 0,
    "Asset Name": "ASTRID 002291",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous",
    "Hair": "Bandana",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002396,
    "y_1": 0.00007196,
    "z_1": 0.00000225,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118875,
    "Gliese Catalog_1": "NN 3772"
  },
  {
    "Astrid #": "ASTRID 002292",
    "HYG Star ID": 118891,
    "Gliese Catalog": "NN 3787",
    "Distance in Parsecs": 21.7391,
    "mag": 13.1,
    "Absolute Visual Magnitude": 11.414,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.339552,
    "y": -5.137691,
    "z": -17.144329,
    "vx": 0.00000601,
    "vy": 0.00006709,
    "vz": -0.00002445,
    "rarad": 3.53620801817824,
    "decrad": -0.908594402646349,
    "pmrarad": -0.00000274273643500694,
    "pmdecrad": -0.000001829172927,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118891,
    "lum": 0.00236809979387379,
    "ra": 13.50732,
    "dec": -52.058625,
    "pmra": -565.73,
    "pmdec": -377.29,
    "rv": 0,
    "Asset Name": "ASTRID 002292",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Malevolent",
    "Hair": "Shinobi",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000601,
    "y_1": 0.00006709,
    "z_1": -0.00002445,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 118891,
    "Gliese Catalog_1": "NN 3787"
  },
  {
    "Astrid #": "ASTRID 002293",
    "HYG Star ID": 119095,
    "Gliese Catalog": "NN 3964",
    "Distance in Parsecs": 21.7391,
    "mag": 12.68,
    "Absolute Visual Magnitude": 10.994,
    "Spectral Type": "M3.5",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.710306,
    "y": -17.413812,
    "z": -11.149863,
    "vx": -0.00006637,
    "vy": 0.00007362,
    "vz": -0.0000751,
    "rarad": 4.34461415768678,
    "decrad": -0.53855307588945,
    "pmrarad": -0.00000406632626429166,
    "pmdecrad": -0.000004023977789,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119095,
    "lum": 0.00348658293352872,
    "ra": 16.595204,
    "dec": -30.856818,
    "pmra": -838.74,
    "pmdec": -830,
    "rv": 0,
    "Asset Name": "ASTRID 002293",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Angelic",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006637,
    "y_1": 0.00007362,
    "z_1": -0.0000751,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 119095,
    "Gliese Catalog_1": "NN 3964"
  },
  {
    "Astrid #": "ASTRID 002294",
    "HYG Star ID": 119189,
    "Henry Draper Catalogue": 163489,
    "Gliese Catalog": "NN 4035",
    "Distance in Parsecs": 21.7391,
    "mag": 8,
    "Absolute Visual Magnitude": 6.314,
    "Spectral Type": "dK8",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -0.35915,
    "y": -21.658843,
    "z": 1.831379,
    "vx": 1.6e-7,
    "vy": 9e-8,
    "vz": 0.00000104,
    "rarad": 4.69580833676212,
    "decrad": 0.08434349940365,
    "pmrarad": 7.17524247222222e-9,
    "pmdecrad": 4.7948073e-8,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 119189,
    "lum": 0.259656979027378,
    "ra": 17.936667,
    "dec": 4.832527,
    "pmra": 1.48,
    "pmdec": 9.89,
    "rv": 0,
    "Asset Name": "ASTRID 002294",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Smoulder",
    "Hair": "Honest Lad",
    "Outfit": "Astro",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 1.6e-7,
    "y_1": 9e-8,
    "z_1": 0.00000104,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 119189,
    "Henry Draper Catalogue_1": 163489,
    "Gliese Catalog_1": "NN 4035"
  },
  {
    "Astrid #": "ASTRID 002295",
    "HYG Star ID": 119310,
    "Gliese Catalog": "NN 4127",
    "Distance in Parsecs": 21.7391,
    "mag": 11.63,
    "Absolute Visual Magnitude": 9.944,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.554143,
    "y": -15.829218,
    "z": 12.200449,
    "vx": -0.00000597,
    "vy": 0.0000129,
    "vz": 0.00002092,
    "rarad": 5.20783315936932,
    "decrad": 0.59586084400785,
    "pmrarad": 4.06273864305555e-8,
    "pmdecrad": 0.000001162840157,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 119310,
    "lum": 0.00917064749114932,
    "ra": 19.892457,
    "dec": 34.140312,
    "pmra": 8.38,
    "pmdec": 239.85,
    "rv": 0,
    "Asset Name": "ASTRID 002295",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Windy",
    "Outfit": "Toga",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000597,
    "y_1": 0.0000129,
    "z_1": 0.00002092,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 119310,
    "Gliese Catalog_1": "NN 4127"
  },
  {
    "Astrid #": "ASTRID 002296",
    "HYG Star ID": 119443,
    "Gliese Catalog": "NN 4223",
    "Distance in Parsecs": 21.7391,
    "mag": 13,
    "Absolute Visual Magnitude": 11.314,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.655081,
    "y": -7.579392,
    "z": 16.712283,
    "vx": 0.00000996,
    "vy": 0.00008057,
    "vz": 0.00002962,
    "rarad": 5.70649903237586,
    "decrad": 0.87690949299025,
    "pmrarad": 0.00000335660751732638,
    "pmdecrad": 0.000002130159805,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119443,
    "lum": 0.00259656979027378,
    "ra": 21.797221,
    "dec": 50.243213,
    "pmra": 692.35,
    "pmdec": 439.38,
    "rv": 0,
    "Asset Name": "ASTRID 002296",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Spiky",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000996,
    "y_1": 0.00008057,
    "z_1": 0.00002962,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 119443,
    "Gliese Catalog_1": "NN 4223"
  },
  {
    "Astrid #": "ASTRID 002297",
    "HYG Star ID": 119493,
    "Gliese Catalog": "NN 4275",
    "Distance in Parsecs": 21.7391,
    "mag": 14.06,
    "Absolute Visual Magnitude": 12.374,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.490272,
    "y": -7.893737,
    "z": 10.216054,
    "vx": 0.0000177,
    "vy": 0.00003456,
    "vz": -0.0000036,
    "rarad": 5.85922105244501,
    "decrad": 0.4892218209155,
    "pmrarad": 0.00000178396890034027,
    "pmdecrad": -1.8750169e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119493,
    "lum": 0.000978137704322984,
    "ra": 22.380576,
    "dec": 28.030346,
    "pmra": 367.97,
    "pmdec": -38.67,
    "rv": 0,
    "Asset Name": "ASTRID 002297",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Hachimaki",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000177,
    "y_1": 0.00003456,
    "z_1": -0.0000036,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 119493,
    "Gliese Catalog_1": "NN 4275"
  },
  {
    "Astrid #": "ASTRID 002298",
    "HYG Star ID": 119558,
    "Gliese Catalog": "NN 4325",
    "Distance in Parsecs": 21.7391,
    "mag": 13.7,
    "Absolute Visual Magnitude": 12.014,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.21114,
    "y": -2.647838,
    "z": -16.236407,
    "vx": -0.00005076,
    "vy": 0.0000416,
    "vz": -0.00005123,
    "rarad": 6.09893968329851,
    "decrad": -0.84335130779565,
    "pmrarad": 0.00000145332597020138,
    "pmdecrad": -0.000003543575913,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119558,
    "lum": 0.00136269919699486,
    "ra": 23.296234,
    "dec": -48.320471,
    "pmra": 299.77,
    "pmdec": -730.91,
    "rv": 0,
    "Asset Name": "ASTRID 002298",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Angelic",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005076,
    "y_1": 0.0000416,
    "z_1": -0.00005123,
    "Distance in Parsecs_1": 21.7391,
    "HYG Star ID_1": 119558,
    "Gliese Catalog_1": "NN 4325"
  },
  {
    "Astrid #": "ASTRID 002299",
    "HYG Star ID": 94073,
    "Hipparcos Catalogue": 94368,
    "Gliese Catalog": "Gl 747.4",
    "Distance in Parsecs": 21.7439,
    "mag": 11.31,
    "Absolute Visual Magnitude": 9.623,
    "Spectral Type": "M2",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.791401,
    "y": -11.596218,
    "z": -17.998617,
    "vx": -0.00003246,
    "vy": 0.00000494,
    "vz": -0.00001002,
    "rarad": 5.02839417654404,
    "decrad": -0.975094223696051,
    "pmrarad": -0.00000134850925246527,
    "pmdecrad": -8.21565263e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 94073,
    "lum": 0.0123253709820334,
    "var_min": 11.376,
    "var_max": 11.216,
    "ra": 19.207051,
    "dec": -55.868784,
    "pmra": -278.15,
    "pmdec": -169.46,
    "rv": 0,
    "Asset Name": "ASTRID 002299",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Oh No",
    "Hair": "Grecian",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.00003246,
    "y_1": 0.00000494,
    "z_1": -0.00001002,
    "Distance in Parsecs_1": 21.7439,
    "HYG Star ID_1": 94073,
    "Hipparcos Catalogue_1": 94368,
    "Gliese Catalog_1": "Gl 747.4"
  },
  {
    "Astrid #": "ASTRID 002300",
    "HYG Star ID": 685,
    "Hipparcos Catalogue": 687,
    "Gliese Catalog": "NN 3008",
    "Distance in Parsecs": 21.7486,
    "mag": 10.73,
    "Absolute Visual Magnitude": 9.043,
    "Spectral Type": "M0",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.736513,
    "y": 0.765325,
    "z": 6.51252,
    "vx": 0.00000237,
    "vy": -0.00000968,
    "vz": -0.0000064,
    "rarad": 0.0368906053424251,
    "decrad": 0.304111401163448,
    "pmrarad": -4.48646579986111e-7,
    "pmdecrad": -3.08438463e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 685,
    "lum": 0.0210280983703774,
    "var_min": 10.802,
    "var_max": 10.682,
    "ra": 0.140912,
    "dec": 17.4243,
    "pmra": -92.54,
    "pmdec": -63.62,
    "rv": 0,
    "Asset Name": "ASTRID 002300",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Manbun",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000237,
    "y_1": -0.00000968,
    "z_1": -0.0000064,
    "Distance in Parsecs_1": 21.7486,
    "HYG Star ID_1": 685,
    "Hipparcos Catalogue_1": 687,
    "Gliese Catalog_1": "NN 3008"
  },
  {
    "Astrid #": "ASTRID 002301",
    "HYG Star ID": 69739,
    "Hipparcos Catalogue": 69962,
    "Henry Draper Catalogue": 125354,
    "Gliese Catalog": "Gl 542.2",
    "Distance in Parsecs": 21.7533,
    "mag": 9.1,
    "Absolute Visual Magnitude": 7.412,
    "Spectral Type": "K7V",
    "Color Index": 1.29,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.75656,
    "y": -12.314875,
    "z": -2.501647,
    "vx": -0.00000402,
    "vy": -0.00000212,
    "vz": -0.00004639,
    "rarad": 3.74796951468241,
    "decrad": -0.115255361078177,
    "pmrarad": -2.50648672847222e-8,
    "pmdecrad": -0.000002094637506,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 69739,
    "lum": 0.0944495732507706,
    "ra": 14.316189,
    "dec": -6.603646,
    "pmra": -5.17,
    "pmdec": -432.05,
    "rv": 9.6,
    "Asset Name": "ASTRID 002301",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Malevolent",
    "Hair": "Afro",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000402,
    "y_1": -0.00000212,
    "z_1": -0.00004639,
    "Distance in Parsecs_1": 21.7533,
    "HYG Star ID_1": 69739,
    "Hipparcos Catalogue_1": 69962,
    "Henry Draper Catalogue_1": 125354,
    "Gliese Catalog_1": "Gl 542.2"
  },
  {
    "Astrid #": "ASTRID 002302",
    "HYG Star ID": 12411,
    "Hipparcos Catalogue": 12444,
    "Henry Draper Catalogue": 16673,
    "Harvard Revised Catalogue": 784,
    "Gliese Catalog": "NN 3175",
    "Distance in Parsecs": 21.7581,
    "mag": 5.79,
    "Absolute Visual Magnitude": 4.102,
    "Spectral Type": "F6V",
    "Color Index": 0.524,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.428845,
    "y": 13.810748,
    "z": -3.573465,
    "vx": 0.00000526,
    "vy": -0.00001465,
    "vz": -0.00000752,
    "rarad": 0.699035118785268,
    "decrad": -0.164983792123993,
    "pmrarad": -6.71224540729166e-7,
    "pmdecrad": -3.81499885e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12411,
    "lum": 1.99159028905761,
    "ra": 2.670117,
    "dec": -9.452875,
    "pmra": -138.45,
    "pmdec": -78.69,
    "rv": -4,
    "Asset Name": "ASTRID 002302",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Ponytail",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000526,
    "y_1": -0.00001465,
    "z_1": -0.00000752,
    "Distance in Parsecs_1": 21.7581,
    "HYG Star ID_1": 12411,
    "Hipparcos Catalogue_1": 12444,
    "Henry Draper Catalogue_1": 16673,
    "Harvard Revised Catalogue_1": 784,
    "Gliese Catalog_1": "NN 3175"
  },
  {
    "Astrid #": "ASTRID 002303",
    "HYG Star ID": 118257,
    "Gliese Catalog": "Gl 154.1B",
    "Distance in Parsecs": 21.7628,
    "mag": 11.6,
    "Absolute Visual Magnitude": 9.911,
    "Spectral Type": "M3 V",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.660747,
    "y": 16.016511,
    "z": -10.170511,
    "vx": -0.00002394,
    "vy": 0.00002542,
    "vz": 0.00001493,
    "rarad": 0.983515512509715,
    "decrad": -0.4862735834219,
    "pmrarad": 0.00000156333019431944,
    "pmdecrad": 7.76031562e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 2,
    "comp_primary": 17502,
    "base": "Gl 154.1",
    "lum": 0.00945366045957965,
    "ra": 3.756753,
    "dec": -27.861424,
    "pmra": 322.46,
    "pmdec": 160.07,
    "rv": 0,
    "Asset Name": "ASTRID 002303",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Bandana",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00002394,
    "y_1": 0.00002542,
    "z_1": 0.00001493,
    "Distance in Parsecs_1": 21.7628,
    "HYG Star ID_1": 118257,
    "Gliese Catalog_1": "Gl 154.1B"
  },
  {
    "Astrid #": "ASTRID 002304",
    "HYG Star ID": 60864,
    "Hipparcos Catalogue": 61053,
    "Henry Draper Catalogue": 108954,
    "Harvard Revised Catalogue": 4767,
    "Distance in Parsecs": 21.777,
    "mag": 6.2,
    "Absolute Visual Magnitude": 4.51,
    "Spectral Type": "F9V",
    "Color Index": 0.568,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.964113,
    "y": -1.754868,
    "z": 17.409243,
    "vx": 0.00002829,
    "vy": 0.00000176,
    "vz": -0.00000562,
    "rarad": 3.27613779851649,
    "decrad": 0.92636156589998,
    "pmrarad": 9.43447422361111e-8,
    "pmdecrad": 8.82845712e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 60864,
    "lum": 1.36772882559584,
    "ra": 12.513925,
    "dec": 53.076608,
    "pmra": 19.46,
    "pmdec": 182.1,
    "rv": -21,
    "Asset Name": "ASTRID 002304",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Scared",
    "Hair": "Angelic",
    "Outfit": "Warlock",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00002829,
    "y_1": 0.00000176,
    "z_1": -0.00000562,
    "Distance in Parsecs_1": 21.777,
    "HYG Star ID_1": 60864,
    "Hipparcos Catalogue_1": 61053,
    "Henry Draper Catalogue_1": 108954,
    "Harvard Revised Catalogue_1": 4767
  },
  {
    "Astrid #": "ASTRID 002305",
    "HYG Star ID": 40005,
    "Hipparcos Catalogue": 40118,
    "Henry Draper Catalogue": 68017,
    "Gliese Catalog": "Wo 9256",
    "Distance in Parsecs": 21.7865,
    "mag": 6.78,
    "Absolute Visual Magnitude": 5.089,
    "Spectral Type": "G4V",
    "Color Index": 0.679,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.988433,
    "y": 15.433259,
    "z": 11.692273,
    "vx": 0.00000664,
    "vy": 0.00007928,
    "vz": -0.00004065,
    "rarad": 2.14520554097873,
    "decrad": 0.566483778599537,
    "pmrarad": -0.00000223348814495138,
    "pmdecrad": -0.00000312530291,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 40005,
    "lum": 0.802416776308584,
    "ra": 8.194082,
    "dec": 32.45713,
    "pmra": -460.69,
    "pmdec": -644.64,
    "rv": 30.6,
    "Asset Name": "ASTRID 002305",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Bob",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00000664,
    "y_1": 0.00007928,
    "z_1": -0.00004065,
    "Distance in Parsecs_1": 21.7865,
    "HYG Star ID_1": 40005,
    "Hipparcos Catalogue_1": 40118,
    "Henry Draper Catalogue_1": 68017,
    "Gliese Catalog_1": "Wo 9256"
  },
  {
    "Astrid #": "ASTRID 002306",
    "HYG Star ID": 61528,
    "Hipparcos Catalogue": 61722,
    "Gliese Catalog": "NN 3738",
    "Distance in Parsecs": 21.7865,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.439,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.631991,
    "y": -2.519384,
    "z": 15.943938,
    "vx": -0.00000231,
    "vy": -0.00004185,
    "vz": -0.00000873,
    "rarad": 3.31209630724638,
    "decrad": 0.820998199884593,
    "pmrarad": 0.00000187511387228472,
    "pmdecrad": -5.88127475e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 61528,
    "lum": 0.00581299567725387,
    "var_min": 12.339,
    "var_max": 11.959,
    "ra": 12.651276,
    "dec": 47.039732,
    "pmra": 386.77,
    "pmdec": -121.31,
    "rv": 0,
    "Asset Name": "ASTRID 002306",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Shinobi",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000231,
    "y_1": -0.00004185,
    "z_1": -0.00000873,
    "Distance in Parsecs_1": 21.7865,
    "HYG Star ID_1": 61528,
    "Hipparcos Catalogue_1": 61722,
    "Gliese Catalog_1": "NN 3738"
  },
  {
    "Astrid #": "ASTRID 002307",
    "HYG Star ID": 118599,
    "Gliese Catalog": "GJ 1121",
    "Distance in Parsecs": 21.7865,
    "mag": 14.55,
    "Absolute Visual Magnitude": 12.859,
    "Spectral Type": "m",
    "Color Index": 1.82,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.257081,
    "y": 11.290368,
    "z": 14.033644,
    "vx": 0.00001071,
    "vy": 0.00006963,
    "vz": -0.00004664,
    "rarad": 2.39726644590847,
    "decrad": 0.6999036633512,
    "pmrarad": -0.00000268446183060416,
    "pmdecrad": -0.000002799352976,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118599,
    "lum": 0.00062574876335444,
    "ra": 9.156883,
    "dec": 40.101526,
    "pmra": -553.71,
    "pmdec": -577.41,
    "rv": 0,
    "Asset Name": "ASTRID 002307",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Grecian",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001071,
    "y_1": 0.00006963,
    "z_1": -0.00004664,
    "Distance in Parsecs_1": 21.7865,
    "HYG Star ID_1": 118599,
    "Gliese Catalog_1": "GJ 1121"
  },
  {
    "Astrid #": "ASTRID 002308",
    "HYG Star ID": 118822,
    "Gliese Catalog": "GJ 1159A",
    "Distance in Parsecs": 21.7865,
    "mag": 14.21,
    "Absolute Visual Magnitude": 12.519,
    "Spectral Type": "m",
    "Color Index": 1.55,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.840153,
    "y": -1.646811,
    "z": 17.523415,
    "vx": -0.00000731,
    "vy": 0.00012952,
    "vz": 0.00000686,
    "rarad": 3.26935382210777,
    "decrad": 0.9345377405215,
    "pmrarad": -0.00000593959784459027,
    "pmdecrad": 5.3009043e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118822,
    "base": "GJ 1159",
    "lum": 0.000855854621220822,
    "ra": 12.488012,
    "dec": 53.545068,
    "pmra": -1225.13,
    "pmdec": 109.34,
    "rv": 0,
    "Asset Name": "ASTRID 002308",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Side Part",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000731,
    "y_1": 0.00012952,
    "z_1": 0.00000686,
    "Distance in Parsecs_1": 21.7865,
    "HYG Star ID_1": 118822,
    "Gliese Catalog_1": "GJ 1159A"
  },
  {
    "Astrid #": "ASTRID 002309",
    "HYG Star ID": 118823,
    "Gliese Catalog": "GJ 1159B",
    "Distance in Parsecs": 21.7865,
    "mag": 18,
    "Absolute Visual Magnitude": 16.309,
    "Spectral Type": "M5.5-6",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.835956,
    "y": -1.646268,
    "z": 17.520885,
    "vx": 0.00004633,
    "vy": 0.00013643,
    "vz": -0.00006635,
    "rarad": 3.26935367910777,
    "decrad": 0.9346250065215,
    "pmrarad": -0.00000593959784459027,
    "pmdecrad": 5.3009043e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118822,
    "base": "GJ 1159",
    "lum": 0.000026085550118689,
    "ra": 12.488011,
    "dec": 53.550068,
    "pmra": -1225.13,
    "pmdec": 109.34,
    "rv": -89,
    "Asset Name": "ASTRID 002309",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Undercut",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004633,
    "y_1": 0.00013643,
    "z_1": -0.00006635,
    "Distance in Parsecs_1": 21.7865,
    "HYG Star ID_1": 118823,
    "Gliese Catalog_1": "GJ 1159B"
  },
  {
    "Astrid #": "ASTRID 002310",
    "HYG Star ID": 87052,
    "Hipparcos Catalogue": 87322,
    "Henry Draper Catalogue": 162283,
    "Gliese Catalog": "Gl 696",
    "Distance in Parsecs": 21.7912,
    "mag": 10.17,
    "Absolute Visual Magnitude": 8.479,
    "Spectral Type": "M0V",
    "Color Index": 1.419,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.89163,
    "y": -21.651233,
    "z": -2.296795,
    "vx": -0.00000159,
    "vy": 0.0000282,
    "vz": -0.00001098,
    "rarad": 4.67123076692781,
    "decrad": -0.10559739214529,
    "pmrarad": -1.26293963784722e-7,
    "pmdecrad": -6.37045176e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 87052,
    "lum": 0.0353508613361997,
    "ra": 17.842787,
    "dec": -6.050285,
    "pmra": -26.05,
    "pmdec": -131.4,
    "rv": -26.2,
    "Asset Name": "ASTRID 002310",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Guilty Grin",
    "Hair": "Prince",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000159,
    "y_1": 0.0000282,
    "z_1": -0.00001098,
    "Distance in Parsecs_1": 21.7912,
    "HYG Star ID_1": 87052,
    "Hipparcos Catalogue_1": 87322,
    "Henry Draper Catalogue_1": 162283,
    "Gliese Catalog_1": "Gl 696"
  },
  {
    "Astrid #": "ASTRID 002311",
    "HYG Star ID": 51375,
    "Hipparcos Catalogue": 51523,
    "Henry Draper Catalogue": 91324,
    "Harvard Revised Catalogue": 4134,
    "Gliese Catalog": "Gl 397.2",
    "Distance in Parsecs": 21.8103,
    "mag": 4.89,
    "Absolute Visual Magnitude": 3.197,
    "Spectral Type": "F6V",
    "Color Index": 0.5,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.954013,
    "y": 4.868385,
    "z": -17.581178,
    "vx": -0.00001161,
    "vy": 0.00005253,
    "vz": -0.00000421,
    "rarad": 2.75485608769952,
    "decrad": -0.937512048456068,
    "pmrarad": -0.00000203020576869444,
    "pmdecrad": 0.000001015490735,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 51375,
    "lum": 4.58352917438188,
    "ra": 10.522775,
    "dec": -53.715484,
    "pmra": -418.76,
    "pmdec": 209.46,
    "rv": 21,
    "Asset Name": "ASTRID 002311",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Fire",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00001161,
    "y_1": 0.00005253,
    "z_1": -0.00000421,
    "Distance in Parsecs_1": 21.8103,
    "HYG Star ID_1": 51375,
    "Hipparcos Catalogue_1": 51523,
    "Henry Draper Catalogue_1": 91324,
    "Harvard Revised Catalogue_1": 4134,
    "Gliese Catalog_1": "Gl 397.2"
  },
  {
    "Astrid #": "ASTRID 002312",
    "HYG Star ID": 119033,
    "Gliese Catalog": "Gl 593B",
    "Distance in Parsecs": 21.8103,
    "mag": 7.6,
    "Absolute Visual Magnitude": 5.907,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.847314,
    "y": -13.55703,
    "z": 13.961611,
    "vx": -0.00003671,
    "vy": 0.00003155,
    "vz": 0.00000475,
    "rarad": 4.08422182203098,
    "decrad": 0.6946783714374,
    "pmrarad": -0.00000221205938027083,
    "pmdecrad": 2.83368748e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 76148,
    "base": "Gl 593",
    "lum": 0.377746109388219,
    "ra": 15.600578,
    "dec": 39.802139,
    "pmra": -456.27,
    "pmdec": 58.45,
    "rv": 0,
    "Asset Name": "ASTRID 002312",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Good Boy",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00003671,
    "y_1": 0.00003155,
    "z_1": 0.00000475,
    "Distance in Parsecs_1": 21.8103,
    "HYG Star ID_1": 119033,
    "Gliese Catalog_1": "Gl 593B"
  },
  {
    "Astrid #": "ASTRID 002313",
    "HYG Star ID": 36411,
    "Hipparcos Catalogue": 36515,
    "Henry Draper Catalogue": 59967,
    "Harvard Revised Catalogue": 2882,
    "Gliese Catalog": "NN 3446",
    "Distance in Parsecs": 21.815,
    "mag": 6.66,
    "Absolute Visual Magnitude": 4.966,
    "Spectral Type": "G3V",
    "Color Index": 0.641,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.686855,
    "y": 16.003383,
    "z": -13.231602,
    "vx": 0.00000454,
    "vy": 0.00001322,
    "vz": -8.1e-7,
    "rarad": 1.96658786657047,
    "decrad": -0.6516947847396,
    "pmrarad": -4.25763374263888e-7,
    "pmdecrad": 2.6082976e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 36411,
    "lum": 0.898669495047525,
    "ra": 7.511812,
    "dec": -37.339361,
    "pmra": -87.82,
    "pmdec": 53.8,
    "rv": 8.6,
    "Asset Name": "ASTRID 002313",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Round Brush",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000454,
    "y_1": 0.00001322,
    "z_1": -8.1e-7,
    "Distance in Parsecs_1": 21.815,
    "HYG Star ID_1": 36411,
    "Hipparcos Catalogue_1": 36515,
    "Henry Draper Catalogue_1": 59967,
    "Harvard Revised Catalogue_1": 2882,
    "Gliese Catalog_1": "NN 3446"
  },
  {
    "Astrid #": "ASTRID 002314",
    "HYG Star ID": 119509,
    "Gliese Catalog": "Gl 863.1A",
    "Distance in Parsecs": 21.8341,
    "mag": 10.84,
    "Absolute Visual Magnitude": 9.144,
    "Spectral Type": "dM1   J",
    "Color Index": 1.36,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 11.977896,
    "y": -4.781996,
    "z": 17.617776,
    "vx": 0.00004317,
    "vy": 0.00013172,
    "vz": 0.00000392,
    "rarad": 5.90311908246803,
    "decrad": 0.93887906744945,
    "pmrarad": 0.00000633636936075694,
    "pmdecrad": 4.31968989e-7,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 119509,
    "base": "Gl 863.1",
    "lum": 0.0191601983206845,
    "ra": 22.548254,
    "dec": 53.793808,
    "pmra": 1306.97,
    "pmdec": 89.1,
    "rv": -2,
    "Asset Name": "ASTRID 002314",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Heartbreaker",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": 0.00004317,
    "y_1": 0.00013172,
    "z_1": 0.00000392,
    "Distance in Parsecs_1": 21.8341,
    "HYG Star ID_1": 119509,
    "Gliese Catalog_1": "Gl 863.1A"
  },
  {
    "Astrid #": "ASTRID 002315",
    "HYG Star ID": 119510,
    "Gliese Catalog": "Gl 863.1B",
    "Distance in Parsecs": 21.8341,
    "mag": 10.84,
    "Absolute Visual Magnitude": 9.144,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 11.977952,
    "y": -4.782018,
    "z": 17.617859,
    "vx": 0.0000443,
    "vy": 0.00013127,
    "vz": 0.00000557,
    "rarad": 5.90311908246803,
    "decrad": 0.93887906744945,
    "pmrarad": 0.00000633636936075694,
    "pmdecrad": 4.31968989e-7,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 2,
    "comp_primary": 119509,
    "base": "Gl 863.1",
    "lum": 0.0191601983206845,
    "ra": 22.548254,
    "dec": 53.793808,
    "pmra": 1306.97,
    "pmdec": 89.1,
    "rv": 0,
    "Asset Name": "ASTRID 002315",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Straw Hat",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": 0.0000443,
    "y_1": 0.00013127,
    "z_1": 0.00000557,
    "Distance in Parsecs_1": 21.8341,
    "HYG Star ID_1": 119510,
    "Gliese Catalog_1": "Gl 863.1B"
  },
  {
    "Astrid #": "ASTRID 002316",
    "HYG Star ID": 75580,
    "Hipparcos Catalogue": 75809,
    "Henry Draper Catalogue": 139777,
    "Harvard Revised Catalogue": 5829,
    "Distance in Parsecs": 21.8484,
    "mag": 6.57,
    "Absolute Visual Magnitude": 4.873,
    "Spectral Type": "G8IV-V+...",
    "Color Index": 0.665,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.21716,
    "y": -2.868318,
    "z": 21.545395,
    "vx": -0.00001054,
    "vy": 0.00002537,
    "vz": -0.00001223,
    "rarad": 4.05438842947378,
    "decrad": 1.40409298715661,
    "pmrarad": -0.00000109194585271527,
    "pmdecrad": 5.22532184e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 75580,
    "lum": 0.97903901744805,
    "ra": 15.486623,
    "dec": 80.448602,
    "pmra": -225.23,
    "pmdec": 107.78,
    "rv": -14,
    "Asset Name": "ASTRID 002316",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Manbun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": -0.00001054,
    "y_1": 0.00002537,
    "z_1": -0.00001223,
    "Distance in Parsecs_1": 21.8484,
    "HYG Star ID_1": 75580,
    "Hipparcos Catalogue_1": 75809,
    "Henry Draper Catalogue_1": 139777,
    "Harvard Revised Catalogue_1": 5829
  },
  {
    "Astrid #": "ASTRID 002317",
    "HYG Star ID": 89986,
    "Hipparcos Catalogue": 90265,
    "Henry Draper Catalogue": 336187,
    "Distance in Parsecs": 21.8484,
    "mag": 10.85,
    "Absolute Visual Magnitude": 9.153,
    "Spectral Type": "K5",
    "Color Index": 1.605,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.168963,
    "y": -19.741066,
    "z": 9.107057,
    "vx": -0.00000221,
    "vy": -0.0000201,
    "vz": -0.00004304,
    "rarad": 4.82182081779295,
    "decrad": 0.42995456117965,
    "pmrarad": -2.01052233326388e-7,
    "pmdecrad": -0.00000216740804,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 89986,
    "lum": 0.0190020300257723,
    "var_min": 10.914,
    "var_max": 10.774,
    "ra": 18.417999,
    "dec": 24.634582,
    "pmra": -41.47,
    "pmdec": -447.06,
    "rv": 0,
    "Asset Name": "ASTRID 002317",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Ponytail",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000221,
    "y_1": -0.0000201,
    "z_1": -0.00004304,
    "Distance in Parsecs_1": 21.8484,
    "HYG Star ID_1": 89986,
    "Hipparcos Catalogue_1": 90265,
    "Henry Draper Catalogue_1": 336187
  },
  {
    "Astrid #": "ASTRID 002318",
    "HYG Star ID": 27141,
    "Hipparcos Catalogue": 27207,
    "Henry Draper Catalogue": 38230,
    "Gliese Catalog": "Gl 217",
    "Distance in Parsecs": 21.8531,
    "mag": 7.34,
    "Absolute Visual Magnitude": 5.642,
    "Spectral Type": "K0V",
    "Color Index": 0.833,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.059066,
    "y": 17.354633,
    "z": 13.237921,
    "vx": -0.00005084,
    "vy": 0.00001395,
    "vz": -0.00005963,
    "rarad": 1.50984162812296,
    "decrad": 0.650739879915871,
    "pmrarad": 0.00000236104262430555,
    "pmdecrad": -0.000002471095329,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 27141,
    "lum": 0.482169793900617,
    "ra": 5.76717,
    "dec": 37.284649,
    "pmra": 487,
    "pmdec": -509.7,
    "rv": -26.9,
    "Asset Name": "ASTRID 002318",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Pixie",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00005084,
    "y_1": 0.00001395,
    "z_1": -0.00005963,
    "Distance in Parsecs_1": 21.8531,
    "HYG Star ID_1": 27141,
    "Hipparcos Catalogue_1": 27207,
    "Henry Draper Catalogue_1": 38230,
    "Gliese Catalog_1": "Gl 217"
  },
  {
    "Astrid #": "ASTRID 002319",
    "HYG Star ID": 32228,
    "Hipparcos Catalogue": 32313,
    "Gliese Catalog": "Wo 9216",
    "Distance in Parsecs": 21.8627,
    "mag": 10.92,
    "Absolute Visual Magnitude": 9.221,
    "Spectral Type": "M0:V:",
    "Color Index": 1.364,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.319031,
    "y": 6.667459,
    "z": 20.779272,
    "vx": 0.00000237,
    "vy": 0.000053,
    "vz": -0.00002977,
    "rarad": 1.76611679172597,
    "decrad": 1.25467581309749,
    "pmrarad": -5.76831317125e-7,
    "pmdecrad": -0.000002663760286,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 32228,
    "lum": 0.01784842916498,
    "var_min": 11.003,
    "var_max": 10.833,
    "ra": 6.746069,
    "dec": 71.887629,
    "pmra": -118.98,
    "pmdec": -549.44,
    "rv": -12,
    "Asset Name": "ASTRID 002319",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Little Crown",
    "Outfit": "Spartan",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000237,
    "y_1": 0.000053,
    "z_1": -0.00002977,
    "Distance in Parsecs_1": 21.8627,
    "HYG Star ID_1": 32228,
    "Hipparcos Catalogue_1": 32313,
    "Gliese Catalog_1": "Wo 9216"
  },
  {
    "Astrid #": "ASTRID 002320",
    "HYG Star ID": 30430,
    "Hipparcos Catalogue": 30503,
    "Henry Draper Catalogue": 45184,
    "Harvard Revised Catalogue": 2318,
    "Gliese Catalog": "NN 3394",
    "Distance in Parsecs": 21.8818,
    "mag": 6.37,
    "Absolute Visual Magnitude": 4.67,
    "Spectral Type": "G2V",
    "Color Index": 0.626,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.065584,
    "y": 19.067234,
    "z": -10.534962,
    "vx": 0.00001847,
    "vy": -0.00000817,
    "vz": -0.00000905,
    "rarad": 1.67870809271409,
    "decrad": -0.502307720390053,
    "pmrarad": -7.99118389659722e-7,
    "pmdecrad": -5.85073149e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 30430,
    "lum": 1.18032063565172,
    "ra": 6.412193,
    "dec": -28.780112,
    "pmra": -164.83,
    "pmdec": -120.68,
    "rv": -4.4,
    "Asset Name": "ASTRID 002320",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Bangs",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00001847,
    "y_1": -0.00000817,
    "z_1": -0.00000905,
    "Distance in Parsecs_1": 21.8818,
    "HYG Star ID_1": 30430,
    "Hipparcos Catalogue_1": 30503,
    "Henry Draper Catalogue_1": 45184,
    "Harvard Revised Catalogue_1": 2318,
    "Gliese Catalog_1": "NN 3394"
  },
  {
    "Astrid #": "ASTRID 002321",
    "HYG Star ID": 118904,
    "Gliese Catalog": "Gl 520B",
    "Distance in Parsecs": 21.901,
    "mag": 11,
    "Absolute Visual Magnitude": 9.298,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -13.303506,
    "y": -6.052351,
    "z": 16.310719,
    "vx": -0.0000167,
    "vy": 0.00001609,
    "vz": -0.00000765,
    "rarad": 3.56857496501887,
    "decrad": 0.84015673139425,
    "pmrarad": -9.84559622472222e-7,
    "pmdecrad": -5.23492115e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 66283,
    "base": "Gl 520",
    "lum": 0.0166264679689353,
    "ra": 13.630952,
    "dec": 48.137435,
    "pmra": -203.08,
    "pmdec": -107.98,
    "rv": 0,
    "Asset Name": "ASTRID 002321",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Round Brush",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.0000167,
    "y_1": 0.00001609,
    "z_1": -0.00000765,
    "Distance in Parsecs_1": 21.901,
    "HYG Star ID_1": 118904,
    "Gliese Catalog_1": "Gl 520B"
  },
  {
    "Astrid #": "ASTRID 002322",
    "HYG Star ID": 118905,
    "Gliese Catalog": "Gl 520C",
    "Distance in Parsecs": 21.901,
    "mag": 14.46,
    "Absolute Visual Magnitude": 12.758,
    "Spectral Type": "m",
    "Color Index": 1.66,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.308343,
    "y": -6.041771,
    "z": 16.310696,
    "vx": -0.00001669,
    "vy": 0.0000161,
    "vz": -0.00000765,
    "rarad": 3.56777902201887,
    "decrad": 0.840155155394249,
    "pmrarad": -9.84559622472222e-7,
    "pmdecrad": -5.23492115e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 66283,
    "base": "Gl 520",
    "lum": 0.000686752106148818,
    "ra": 13.627912,
    "dec": 48.137345,
    "pmra": -203.08,
    "pmdec": -107.98,
    "rv": 0,
    "Asset Name": "ASTRID 002322",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Innocent",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001669,
    "y_1": 0.0000161,
    "z_1": -0.00000765,
    "Distance in Parsecs_1": 21.901,
    "HYG Star ID_1": 118905,
    "Gliese Catalog_1": "Gl 520C"
  },
  {
    "Astrid #": "ASTRID 002323",
    "HYG Star ID": 17625,
    "Hipparcos Catalogue": 17666,
    "Henry Draper Catalogue": 23439,
    "Gliese Catalog": "GJ 1064A",
    "Distance in Parsecs": 21.9058,
    "mag": 7.67,
    "Absolute Visual Magnitude": 5.967,
    "Spectral Type": "K1V",
    "Color Index": 0.796,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.003724,
    "y": 13.73757,
    "z": 14.494677,
    "vx": 0.00001542,
    "vy": 0.00013955,
    "vz": -0.00006516,
    "rarad": 0.990619726600184,
    "decrad": 0.723042167260937,
    "pmrarad": 0.00000290384002105555,
    "pmdecrad": -0.00000601139875,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 17625,
    "base": "GJ 1064",
    "lum": 0.35743740626448,
    "var": "V580",
    "var_min": 7.954,
    "var_max": 7.634,
    "ra": 3.783889,
    "dec": 41.427265,
    "pmra": 598.96,
    "pmdec": -1239.94,
    "rv": 49.6,
    "Asset Name": "ASTRID 002323",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Goddess",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00001542,
    "y_1": 0.00013955,
    "z_1": -0.00006516,
    "Distance in Parsecs_1": 21.9058,
    "HYG Star ID_1": 17625,
    "Hipparcos Catalogue_1": 17666,
    "Henry Draper Catalogue_1": 23439,
    "Gliese Catalog_1": "GJ 1064A"
  },
  {
    "Astrid #": "ASTRID 002324",
    "HYG Star ID": 21935,
    "Hipparcos Catalogue": 21988,
    "Henry Draper Catalogue": 29883,
    "Gliese Catalog": "Gl 176.2",
    "Distance in Parsecs": 21.925,
    "mag": 8,
    "Absolute Visual Magnitude": 6.295,
    "Spectral Type": "K5III",
    "Color Index": 0.907,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.353568,
    "y": 18.345574,
    "z": 10.187464,
    "vx": 0.00000397,
    "vy": 0.0000289,
    "vz": -0.00001688,
    "rarad": 1.23739711529091,
    "decrad": 0.483236296415311,
    "pmrarad": 2.604903905625e-7,
    "pmdecrad": -0.000001288295393,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 21935,
    "lum": 0.264240875732194,
    "ra": 4.726509,
    "dec": 27.6874,
    "pmra": 53.73,
    "pmdec": -265.73,
    "rv": 17.1,
    "Asset Name": "ASTRID 002324",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Honest Lad",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000397,
    "y_1": 0.0000289,
    "z_1": -0.00001688,
    "Distance in Parsecs_1": 21.925,
    "HYG Star ID_1": 21935,
    "Hipparcos Catalogue_1": 21988,
    "Henry Draper Catalogue_1": 29883,
    "Gliese Catalog_1": "Gl 176.2"
  },
  {
    "Astrid #": "ASTRID 002325",
    "HYG Star ID": 54585,
    "Hipparcos Catalogue": 54745,
    "Henry Draper Catalogue": 97334,
    "Harvard Revised Catalogue": 4345,
    "Gliese Catalog": "Gl 417",
    "Distance in Parsecs": 21.925,
    "mag": 6.41,
    "Absolute Visual Magnitude": 4.705,
    "Spectral Type": "G0V",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.399524,
    "y": 3.65562,
    "z": 12.82955,
    "vx": -0.00000118,
    "vy": 0.00002724,
    "vz": -0.00001496,
    "rarad": 2.93450821221147,
    "decrad": 0.625073618400257,
    "pmrarad": -0.00000120500440302083,
    "pmdecrad": -7.33668542e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 54585,
    "lum": 1.14287833478977,
    "var": "MN",
    "var_min": 6.429,
    "var_max": 6.399,
    "ra": 11.208996,
    "dec": 35.81408,
    "pmra": -248.55,
    "pmdec": -151.33,
    "rv": -3.2,
    "Asset Name": "ASTRID 002325",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Oof",
    "Hair": "Manbun",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000118,
    "y_1": 0.00002724,
    "z_1": -0.00001496,
    "Distance in Parsecs_1": 21.925,
    "HYG Star ID_1": 54585,
    "Hipparcos Catalogue_1": 54745,
    "Henry Draper Catalogue_1": 97334,
    "Harvard Revised Catalogue_1": 4345,
    "Gliese Catalog_1": "Gl 417"
  },
  {
    "Astrid #": "ASTRID 002326",
    "HYG Star ID": 119066,
    "Gliese Catalog": "GJ 1197",
    "Distance in Parsecs": 21.9298,
    "mag": 13.33,
    "Absolute Visual Magnitude": 11.625,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.244092,
    "y": -17.246665,
    "z": 9.900275,
    "vx": 0.00002374,
    "vy": -0.0000362,
    "vz": -0.00004087,
    "rarad": 4.22035278435083,
    "decrad": 0.4683930848616,
    "pmrarad": 0.00000173422701671527,
    "pmdecrad": -0.000002088882768,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119066,
    "lum": 0.00194984459975804,
    "ra": 16.12056,
    "dec": 26.836947,
    "pmra": 357.71,
    "pmdec": -430.86,
    "rv": 0,
    "Asset Name": "ASTRID 002326",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Prince",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002374,
    "y_1": -0.0000362,
    "z_1": -0.00004087,
    "Distance in Parsecs_1": 21.9298,
    "HYG Star ID_1": 119066,
    "Gliese Catalog_1": "GJ 1197"
  },
  {
    "Astrid #": "ASTRID 002327",
    "HYG Star ID": 47068,
    "Hipparcos Catalogue": 47201,
    "Gliese Catalog": "GJ 1127",
    "Distance in Parsecs": 21.9443,
    "mag": 9.43,
    "Absolute Visual Magnitude": 7.723,
    "Spectral Type": "M0V:",
    "Color Index": 1.273,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.440076,
    "y": 11.814587,
    "z": 8.466241,
    "vx": 0.00003161,
    "vy": -0.00000317,
    "vz": -0.00003201,
    "rarad": 2.51846276933318,
    "decrad": 0.396087495814321,
    "pmrarad": -7.23390492756944e-7,
    "pmdecrad": -8.62192649e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 47068,
    "lum": 0.0709251070555853,
    "ra": 9.619819,
    "dec": 22.694142,
    "pmra": -149.21,
    "pmdec": -177.84,
    "rv": -36.9,
    "Asset Name": "ASTRID 002327",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Frizzy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00003161,
    "y_1": -0.00000317,
    "z_1": -0.00003201,
    "Distance in Parsecs_1": 21.9443,
    "HYG Star ID_1": 47068,
    "Hipparcos Catalogue_1": 47201,
    "Gliese Catalog_1": "GJ 1127"
  },
  {
    "Astrid #": "ASTRID 002328",
    "HYG Star ID": 98513,
    "Hipparcos Catalogue": 98828,
    "Henry Draper Catalogue": 190470,
    "Gliese Catalog": "Gl 779.1",
    "Distance in Parsecs": 21.9491,
    "mag": 7.82,
    "Absolute Visual Magnitude": 6.113,
    "Spectral Type": "K3V",
    "Color Index": 0.924,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.190948,
    "y": -16.932555,
    "z": 9.549536,
    "vx": -0.00000906,
    "vy": -6.9e-7,
    "vz": -0.00000659,
    "rarad": 5.25417190683845,
    "decrad": 0.450124419876475,
    "pmrarad": -3.69961319631944e-7,
    "pmdecrad": -1.8927126e-7,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 98513,
    "lum": 0.31246400858061,
    "ra": 20.069458,
    "dec": 25.79023,
    "pmra": -76.31,
    "pmdec": -39.04,
    "rv": -6.4,
    "Asset Name": "ASTRID 002328",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Pixie",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": -0.00000906,
    "y_1": -6.9e-7,
    "z_1": -0.00000659,
    "Distance in Parsecs_1": 21.9491,
    "HYG Star ID_1": 98513,
    "Hipparcos Catalogue_1": 98828,
    "Henry Draper Catalogue_1": 190470,
    "Gliese Catalog_1": "Gl 779.1"
  },
  {
    "Astrid #": "ASTRID 002329",
    "HYG Star ID": 11046,
    "Hipparcos Catalogue": 11072,
    "Henry Draper Catalogue": 14802,
    "Harvard Revised Catalogue": 695,
    "Gliese Catalog": "Gl 97",
    "Distance in Parsecs": 21.9635,
    "mag": 5.19,
    "Absolute Visual Magnitude": 3.481,
    "Spectral Type": "G2V",
    "Color Index": 0.608,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.330638,
    "y": 11.706947,
    "z": -8.869059,
    "vx": 0.0000016,
    "vy": 0.000027,
    "vz": -0.00000803,
    "rarad": 0.621957971399025,
    "decrad": -0.41567330522905,
    "pmrarad": 9.56731317208333e-7,
    "pmdecrad": -2.128332e-8,
    "bayer": "Kap",
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 11046,
    "lum": 3.52858025828526,
    "bf": "Kap For",
    "ra": 2.375704,
    "dec": -23.816326,
    "pmra": 197.34,
    "pmdec": -4.39,
    "rv": 18.4,
    "Asset Name": "ASTRID 002329",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Good Boy",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.0000016,
    "y_1": 0.000027,
    "z_1": -0.00000803,
    "Distance in Parsecs_1": 21.9635,
    "HYG Star ID_1": 11046,
    "Hipparcos Catalogue_1": 11072,
    "Henry Draper Catalogue_1": 14802,
    "Harvard Revised Catalogue_1": 695,
    "Gliese Catalog_1": "Gl 97"
  },
  {
    "Astrid #": "ASTRID 002330",
    "HYG Star ID": 103057,
    "Hipparcos Catalogue": 103389,
    "Henry Draper Catalogue": 199260,
    "Harvard Revised Catalogue": 8013,
    "Gliese Catalog": "Gl 811",
    "Distance in Parsecs": 21.9684,
    "mag": 5.7,
    "Absolute Visual Magnitude": 3.991,
    "Spectral Type": "F7V",
    "Color Index": 0.507,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.729882,
    "y": -14.12012,
    "z": -9.732257,
    "vx": -0.00000517,
    "vy": 0.00001984,
    "vz": 0.00000108,
    "rarad": 5.4837754619832,
    "decrad": -0.458958369923075,
    "pmrarad": 4.60912366104166e-7,
    "pmdecrad": -3.1541978e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 103057,
    "lum": 2.20597202174732,
    "ra": 20.946479,
    "dec": -26.296378,
    "pmra": 95.07,
    "pmdec": -65.06,
    "rv": -16.1,
    "Asset Name": "ASTRID 002330",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "High Fade",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.00000517,
    "y_1": 0.00001984,
    "z_1": 0.00000108,
    "Distance in Parsecs_1": 21.9684,
    "HYG Star ID_1": 103057,
    "Hipparcos Catalogue_1": 103389,
    "Henry Draper Catalogue_1": 199260,
    "Harvard Revised Catalogue_1": 8013,
    "Gliese Catalog_1": "Gl 811"
  },
  {
    "Astrid #": "ASTRID 002331",
    "HYG Star ID": 117640,
    "Hipparcos Catalogue": 118008,
    "Henry Draper Catalogue": 224228,
    "Gliese Catalog": "NN 4377",
    "Distance in Parsecs": 21.9684,
    "mag": 8.24,
    "Absolute Visual Magnitude": 6.531,
    "Spectral Type": "K3V",
    "Color Index": 0.973,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.057646,
    "y": -0.284499,
    "z": -13.840751,
    "vx": -0.00001205,
    "vy": 0.00002213,
    "vz": -0.00001531,
    "rarad": 6.26650564552447,
    "decrad": -0.681591807890932,
    "pmrarad": 9.98231368263889e-7,
    "pmdecrad": -8.97244678e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 117640,
    "lum": 0.212617985986252,
    "ra": 23.936288,
    "dec": -39.052334,
    "pmra": 205.9,
    "pmdec": -185.07,
    "rv": 0,
    "Asset Name": "ASTRID 002331",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Neko Ninja",
    "Hair": "Braid",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00001205,
    "y_1": 0.00002213,
    "z_1": -0.00001531,
    "Distance in Parsecs_1": 21.9684,
    "HYG Star ID_1": 117640,
    "Hipparcos Catalogue_1": 118008,
    "Henry Draper Catalogue_1": 224228,
    "Gliese Catalog_1": "NN 4377"
  },
  {
    "Astrid #": "ASTRID 002332",
    "HYG Star ID": 18366,
    "Hipparcos Catalogue": 18413,
    "Henry Draper Catalogue": 24409,
    "Gliese Catalog": "NN 3257",
    "Distance in Parsecs": 21.9829,
    "mag": 6.53,
    "Absolute Visual Magnitude": 4.82,
    "Spectral Type": "G0",
    "Color Index": 0.698,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.714208,
    "y": 9.528113,
    "z": 18.968679,
    "vx": 0.00001843,
    "vy": -0.00002813,
    "vz": 0.00000858,
    "rarad": 1.03059388683476,
    "decrad": 1.0409473105451,
    "pmrarad": -0.00000137716174098611,
    "pmdecrad": 7.72405155e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 18366,
    "lum": 1.02801629812647,
    "ra": 3.936579,
    "dec": 59.641888,
    "pmra": -284.06,
    "pmdec": 159.32,
    "rv": 0,
    "Asset Name": "ASTRID 002332",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Angelic",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00001843,
    "y_1": -0.00002813,
    "z_1": 0.00000858,
    "Distance in Parsecs_1": 21.9829,
    "HYG Star ID_1": 18366,
    "Hipparcos Catalogue_1": 18413,
    "Henry Draper Catalogue_1": 24409,
    "Gliese Catalog_1": "NN 3257"
  },
  {
    "Astrid #": "ASTRID 002333",
    "HYG Star ID": 38120,
    "Hipparcos Catalogue": 38228,
    "Henry Draper Catalogue": 63433,
    "Distance in Parsecs": 22.0022,
    "mag": 6.9,
    "Absolute Visual Magnitude": 5.188,
    "Spectral Type": "G5IV",
    "Color Index": 0.682,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.016455,
    "y": 17.335649,
    "z": 10.112776,
    "vx": 0.00000845,
    "vy": -0.0000141,
    "vz": -0.00000991,
    "rarad": 2.05040275090707,
    "decrad": 0.477577608733033,
    "pmrarad": -4.50391909236111e-8,
    "pmdecrad": -5.7401939e-8,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 38120,
    "lum": 0.732487132665039,
    "var": "V377",
    "var_min": 6.918,
    "var_max": 6.888,
    "ra": 7.831962,
    "dec": 27.363181,
    "pmra": -9.29,
    "pmdec": -11.84,
    "rv": -18.7,
    "Asset Name": "ASTRID 002333",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00000845,
    "y_1": -0.0000141,
    "z_1": -0.00000991,
    "Distance in Parsecs_1": 22.0022,
    "HYG Star ID_1": 38120,
    "Hipparcos Catalogue_1": 38228,
    "Henry Draper Catalogue_1": 63433
  },
  {
    "Astrid #": "ASTRID 002334",
    "HYG Star ID": 58824,
    "Hipparcos Catalogue": 59000,
    "Henry Draper Catalogue": 105065,
    "Gliese Catalog": "Gl 455.1",
    "Distance in Parsecs": 22.0022,
    "mag": 9.99,
    "Absolute Visual Magnitude": 8.278,
    "Spectral Type": "K5V",
    "Color Index": 1.336,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.812267,
    "y": -0.530839,
    "z": -7.117904,
    "vx": 0.00001098,
    "vy": 0.00000232,
    "vz": -0.00003228,
    "rarad": 3.16709322317934,
    "decrad": -0.329435332419873,
    "pmrarad": -9.25509316180555e-8,
    "pmdecrad": -0.000001550676557,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 58824,
    "lum": 0.0425402462944067,
    "var_min": 10.034,
    "var_max": 9.934,
    "ra": 12.097405,
    "dec": -18.875254,
    "pmra": -19.09,
    "pmdec": -319.85,
    "rv": 0,
    "Asset Name": "ASTRID 002334",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Bob",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001098,
    "y_1": 0.00000232,
    "z_1": -0.00003228,
    "Distance in Parsecs_1": 22.0022,
    "HYG Star ID_1": 58824,
    "Hipparcos Catalogue_1": 59000,
    "Henry Draper Catalogue_1": 105065,
    "Gliese Catalog_1": "Gl 455.1"
  },
  {
    "Astrid #": "ASTRID 002335",
    "HYG Star ID": 9217,
    "Hipparcos Catalogue": 9236,
    "Henry Draper Catalogue": 12311,
    "Harvard Revised Catalogue": 591,
    "Gliese Catalog": "Gl 83",
    "Distance in Parsecs": 22.0119,
    "mag": 2.86,
    "Absolute Visual Magnitude": 1.147,
    "Spectral Type": "F0V",
    "Color Index": 0.29,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.103629,
    "y": 5.191033,
    "z": -19.357346,
    "vx": -0.00000526,
    "vy": 0.00002925,
    "vz": -0.00001231,
    "rarad": 0.518219158261073,
    "decrad": -1.07459675971822,
    "pmrarad": 0.00000127282983693055,
    "pmdecrad": 1.30317917e-7,
    "bayer": "Alp",
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 9217,
    "lum": 30.2830769426577,
    "bf": "Alp Hyi",
    "ra": 1.979451,
    "dec": -61.569859,
    "pmra": 262.54,
    "pmdec": 26.88,
    "rv": 15.2,
    "Asset Name": "ASTRID 002335",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Halo Boy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": -0.00000526,
    "y_1": 0.00002925,
    "z_1": -0.00001231,
    "Distance in Parsecs_1": 22.0119,
    "HYG Star ID_1": 9217,
    "Hipparcos Catalogue_1": 9236,
    "Henry Draper Catalogue_1": 12311,
    "Harvard Revised Catalogue_1": 591,
    "Gliese Catalog_1": "Gl 83"
  },
  {
    "Astrid #": "ASTRID 002336",
    "HYG Star ID": 110605,
    "Hipparcos Catalogue": 110951,
    "Gliese Catalog": "Wo 9784",
    "Distance in Parsecs": 22.0167,
    "mag": 10.73,
    "Absolute Visual Magnitude": 9.016,
    "Color Index": 1.359,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.197261,
    "y": -8.073174,
    "z": 7.143115,
    "vx": 0.00001097,
    "vy": 0.00001462,
    "vz": -0.00001295,
    "rarad": 5.88509982643346,
    "decrad": 0.330420428057272,
    "pmrarad": 8.05420967506944e-7,
    "pmdecrad": -6.21822026e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 110605,
    "lum": 0.0215575796803862,
    "var_min": 10.817,
    "var_max": 10.697,
    "ra": 22.479425,
    "dec": 18.931696,
    "pmra": 166.13,
    "pmdec": -128.26,
    "rv": 0,
    "Asset Name": "ASTRID 002336",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Oof",
    "Hair": "Shinobi",
    "Outfit": "Viking",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001097,
    "y_1": 0.00001462,
    "z_1": -0.00001295,
    "Distance in Parsecs_1": 22.0167,
    "HYG Star ID_1": 110605,
    "Hipparcos Catalogue_1": 110951,
    "Gliese Catalog_1": "Wo 9784"
  },
  {
    "Astrid #": "ASTRID 002337",
    "HYG Star ID": 106679,
    "Hipparcos Catalogue": 107022,
    "Henry Draper Catalogue": 205536,
    "Distance in Parsecs": 22.0216,
    "mag": 7.07,
    "Absolute Visual Magnitude": 5.356,
    "Spectral Type": "G8V",
    "Color Index": 0.755,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.957241,
    "y": -3.455125,
    "z": -21.176373,
    "vx": 0.00000936,
    "vy": -0.00002462,
    "vz": 0.00000621,
    "rarad": 5.6745008466357,
    "decrad": -1.29284010609992,
    "pmrarad": -6.74327348284722e-7,
    "pmdecrad": 0.000001027417151,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 106679,
    "lum": 0.627480161997851,
    "ra": 21.674997,
    "dec": -74.074282,
    "pmra": -139.09,
    "pmdec": 211.92,
    "rv": 0,
    "Asset Name": "ASTRID 002337",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Blep",
    "Hair": "Side Part",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00000936,
    "y_1": -0.00002462,
    "z_1": 0.00000621,
    "Distance in Parsecs_1": 22.0216,
    "HYG Star ID_1": 106679,
    "Hipparcos Catalogue_1": 107022,
    "Henry Draper Catalogue_1": 205536
  },
  {
    "Astrid #": "ASTRID 002338",
    "HYG Star ID": 79311,
    "Hipparcos Catalogue": 79551,
    "Gliese Catalog": "Gl 615.2C",
    "Distance in Parsecs": 22.0264,
    "mag": 12.23,
    "Absolute Visual Magnitude": 10.515,
    "Spectral Type": "M",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.174006,
    "y": -16.383386,
    "z": 12.244697,
    "vx": -0.00002733,
    "vy": 0.00000814,
    "vz": -0.00000735,
    "rarad": 4.24960764652832,
    "decrad": 0.589457350403827,
    "pmrarad": -0.00000127525390533333,
    "pmdecrad": -4.01668134e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 79366,
    "base": "Gl 615.2",
    "lum": 0.00542000890401623,
    "var_min": 12.359,
    "var_max": 12.099,
    "ra": 16.232306,
    "dec": 33.773418,
    "pmra": -263.04,
    "pmdec": -82.85,
    "rv": 0,
    "Asset Name": "ASTRID 002338",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Tiny Crown",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002733,
    "y_1": 0.00000814,
    "z_1": -0.00000735,
    "Distance in Parsecs_1": 22.0264,
    "HYG Star ID_1": 79311,
    "Hipparcos Catalogue_1": 79551,
    "Gliese Catalog_1": "Gl 615.2C"
  },
  {
    "Astrid #": "ASTRID 002339",
    "HYG Star ID": 118542,
    "Gliese Catalog": "GJ 1112",
    "Distance in Parsecs": 22.0264,
    "mag": 15.72,
    "Absolute Visual Magnitude": 14.005,
    "Spectral Type": "DA7",
    "Color Index": 0.3,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -11.325631,
    "y": 14.672145,
    "z": 11.898739,
    "vx": 0.0000034,
    "vy": 0.00002097,
    "vz": -0.00005668,
    "rarad": 2.22818197567865,
    "decrad": 0.57070577650555,
    "pmrarad": -7.04046426902777e-7,
    "pmdecrad": -0.000002521549889,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118542,
    "lum": 0.000217770977235315,
    "ra": 8.511028,
    "dec": 32.699032,
    "pmra": -145.22,
    "pmdec": -520.11,
    "rv": -18,
    "Asset Name": "ASTRID 002339",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Good Boy",
    "Outfit": "Boxer",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000034,
    "y_1": 0.00002097,
    "z_1": -0.00005668,
    "Distance in Parsecs_1": 22.0264,
    "HYG Star ID_1": 118542,
    "Gliese Catalog_1": "GJ 1112"
  },
  {
    "Astrid #": "ASTRID 002340",
    "HYG Star ID": 69192,
    "Hipparcos Catalogue": 69414,
    "Henry Draper Catalogue": 124292,
    "Gliese Catalog": "NN 3830",
    "Distance in Parsecs": 22.0507,
    "mag": 7.05,
    "Absolute Visual Magnitude": 5.333,
    "Spectral Type": "G0",
    "Color Index": 0.733,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -18.422924,
    "y": -12.050342,
    "z": -1.27706,
    "vx": -0.00003448,
    "vy": -0.00000203,
    "vz": -0.00003623,
    "rarad": 3.72084123248942,
    "decrad": -0.0579464025644125,
    "pmrarad": -7.79144066020833e-7,
    "pmdecrad": -0.00000156177879,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 69192,
    "lum": 0.640914356644776,
    "ra": 14.212567,
    "dec": -3.320084,
    "pmra": -160.71,
    "pmdec": -322.14,
    "rv": 31.3,
    "Asset Name": "ASTRID 002340",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Manbun",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00003448,
    "y_1": -0.00000203,
    "z_1": -0.00003623,
    "Distance in Parsecs_1": 22.0507,
    "HYG Star ID_1": 69192,
    "Hipparcos Catalogue_1": 69414,
    "Henry Draper Catalogue_1": 124292,
    "Gliese Catalog_1": "NN 3830"
  },
  {
    "Astrid #": "ASTRID 002341",
    "HYG Star ID": 101056,
    "Hipparcos Catalogue": 101382,
    "Henry Draper Catalogue": 195987,
    "Gliese Catalog": "Gl 793.1",
    "Distance in Parsecs": 22.0507,
    "mag": 7.08,
    "Absolute Visual Magnitude": 5.363,
    "Spectral Type": "G9V",
    "Color Index": 0.796,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.153317,
    "y": -12.895535,
    "z": 14.725703,
    "vx": -0.00003605,
    "vy": 0.00001867,
    "vz": 0.00003186,
    "rarad": 5.37937188553839,
    "decrad": 0.731266397318018,
    "pmrarad": -7.6062418342361e-7,
    "pmdecrad": 0.000002195236345,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 101056,
    "lum": 0.623447661105006,
    "ra": 20.547687,
    "dec": 41.898478,
    "pmra": -156.89,
    "pmdec": 452.8,
    "rv": -6.1,
    "Asset Name": "ASTRID 002341",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Innocent",
    "Outfit": "Viking",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00003605,
    "y_1": 0.00001867,
    "z_1": 0.00003186,
    "Distance in Parsecs_1": 22.0507,
    "HYG Star ID_1": 101056,
    "Hipparcos Catalogue_1": 101382,
    "Henry Draper Catalogue_1": 195987,
    "Gliese Catalog_1": "Gl 793.1"
  },
  {
    "Astrid #": "ASTRID 002342",
    "HYG Star ID": 109475,
    "Hipparcos Catalogue": 109821,
    "Henry Draper Catalogue": 210918,
    "Harvard Revised Catalogue": 8477,
    "Gliese Catalog": "Gl 851.2",
    "Distance in Parsecs": 22.0507,
    "mag": 6.23,
    "Absolute Visual Magnitude": 4.513,
    "Spectral Type": "G5V",
    "Color Index": 0.648,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.827393,
    "y": -7.3407,
    "z": -14.576985,
    "vx": -0.00003597,
    "vy": 0.00008584,
    "vz": -0.00005074,
    "rarad": 5.82347590208131,
    "decrad": -0.72224632156056,
    "pmrarad": 0.00000276503786431249,
    "pmdecrad": -0.000003835264064,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 109475,
    "lum": 1.36395486750663,
    "ra": 22.244039,
    "dec": -41.381666,
    "pmra": 570.33,
    "pmdec": -791.08,
    "rv": -18.8,
    "Asset Name": "ASTRID 002342",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Curly",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00003597,
    "y_1": 0.00008584,
    "z_1": -0.00005074,
    "Distance in Parsecs_1": 22.0507,
    "HYG Star ID_1": 109475,
    "Hipparcos Catalogue_1": 109821,
    "Henry Draper Catalogue_1": 210918,
    "Harvard Revised Catalogue_1": 8477,
    "Gliese Catalog_1": "Gl 851.2"
  },
  {
    "Astrid #": "ASTRID 002343",
    "HYG Star ID": 3490,
    "Hipparcos Catalogue": 3497,
    "Henry Draper Catalogue": 4308,
    "Gliese Catalog": "Gl 31.5",
    "Distance in Parsecs": 22.0556,
    "mag": 6.55,
    "Absolute Visual Magnitude": 4.832,
    "Spectral Type": "G3V",
    "Color Index": 0.655,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.922183,
    "y": 1.760752,
    "z": -20.094339,
    "vx": -0.00003379,
    "vy": 0.00001054,
    "vz": -0.00012376,
    "rarad": 0.194832297155437,
    "decrad": -1.14580031564825,
    "pmrarad": 7.65617764333333e-7,
    "pmdecrad": -0.000003599159801,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 3490,
    "lum": 1.01671680046637,
    "ra": 0.744205,
    "dec": -65.649522,
    "pmra": 157.92,
    "pmdec": -742.38,
    "rv": 97.7,
    "Asset Name": "ASTRID 002343",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Fedora",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00003379,
    "y_1": 0.00001054,
    "z_1": -0.00012376,
    "Distance in Parsecs_1": 22.0556,
    "HYG Star ID_1": 3490,
    "Hipparcos Catalogue_1": 3497,
    "Henry Draper Catalogue_1": 4308,
    "Gliese Catalog_1": "Gl 31.5"
  },
  {
    "Astrid #": "ASTRID 002344",
    "HYG Star ID": 119005,
    "Gliese Catalog": "Gl 575.1",
    "Distance in Parsecs": 22.0751,
    "mag": 12.75,
    "Absolute Visual Magnitude": 11.03,
    "Spectral Type": "k-m",
    "Color Index": 1.53,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.327232,
    "y": -13.845777,
    "z": 10.862292,
    "vx": 0.00001224,
    "vy": -0.00002394,
    "vz": -0.0000155,
    "rarad": 3.9460630257449,
    "decrad": 0.51445541677345,
    "pmrarad": 0.00000115167489815972,
    "pmdecrad": -8.06424531e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119005,
    "lum": 0.00337287308658868,
    "ra": 15.07285,
    "dec": 29.476124,
    "pmra": 237.55,
    "pmdec": -166.34,
    "rv": 0,
    "Asset Name": "ASTRID 002344",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Ponytail",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001224,
    "y_1": -0.00002394,
    "z_1": -0.0000155,
    "Distance in Parsecs_1": 22.0751,
    "HYG Star ID_1": 119005,
    "Gliese Catalog_1": "Gl 575.1"
  },
  {
    "Astrid #": "ASTRID 002345",
    "HYG Star ID": 16201,
    "Hipparcos Catalogue": 16242,
    "Gliese Catalog": "Gl 143.1",
    "Distance in Parsecs": 22.0848,
    "mag": 9.95,
    "Absolute Visual Magnitude": 8.23,
    "Spectral Type": "M0V",
    "Color Index": 1.403,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.216177,
    "y": 17.119784,
    "z": -4.470351,
    "vx": -0.00000919,
    "vy": -0.00000123,
    "vz": -0.00003188,
    "rarad": 0.913374063602476,
    "decrad": -0.203825956437591,
    "pmrarad": 2.95154568722222e-7,
    "pmdecrad": -0.000001474221439,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 16201,
    "lum": 0.0444631267469108,
    "var_min": 9.997,
    "var_max": 9.897,
    "ra": 3.488832,
    "dec": -11.678367,
    "pmra": 60.88,
    "pmdec": -304.08,
    "rv": 0,
    "Asset Name": "ASTRID 002345",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Oh Snap",
    "Hair": "Bangs",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000919,
    "y_1": -0.00000123,
    "z_1": -0.00003188,
    "Distance in Parsecs_1": 22.0848,
    "HYG Star ID_1": 16201,
    "Hipparcos Catalogue_1": 16242,
    "Gliese Catalog_1": "Gl 143.1"
  },
  {
    "Astrid #": "ASTRID 002346",
    "HYG Star ID": 119370,
    "Gliese Catalog": "Gl 806.1B",
    "Distance in Parsecs": 22.0946,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.679,
    "Spectral Type": "M4",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.148403,
    "y": -13.718493,
    "z": 12.344658,
    "vx": 0.00001552,
    "vy": 0.00003965,
    "vz": 0.00002881,
    "rarad": 5.4371450243567,
    "decrad": 0.592839664417349,
    "pmrarad": 0.00000171570713411805,
    "pmdecrad": 0.000001572168347,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 102157,
    "base": "Gl 806.1",
    "lum": 0.00185523957554928,
    "ra": 20.768364,
    "dec": 33.967211,
    "pmra": 353.89,
    "pmdec": 324.28,
    "rv": 0,
    "Asset Name": "ASTRID 002346",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Spiky",
    "Outfit": "Tats",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001552,
    "y_1": 0.00003965,
    "z_1": 0.00002881,
    "Distance in Parsecs_1": 22.0946,
    "HYG Star ID_1": 119370,
    "Gliese Catalog_1": "Gl 806.1B"
  },
  {
    "Astrid #": "ASTRID 002347",
    "HYG Star ID": 116570,
    "Hipparcos Catalogue": 116936,
    "Gliese Catalog": "Wo 9835",
    "Distance in Parsecs": 22.1043,
    "mag": 10.3,
    "Absolute Visual Magnitude": 8.578,
    "Spectral Type": "M0",
    "Color Index": 1.343,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.015432,
    "y": -1.715539,
    "z": -0.993811,
    "vx": 0.00002262,
    "vy": -0.00002247,
    "vz": -0.00002888,
    "rarad": 6.2054181119806,
    "decrad": -0.0449747516353,
    "pmrarad": -9.33945074222222e-7,
    "pmdecrad": -0.00000125595832,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 116570,
    "lum": 0.0322700768751553,
    "var_min": 10.346,
    "var_max": 10.236,
    "ra": 23.702951,
    "dec": -2.576863,
    "pmra": -192.64,
    "pmdec": -259.06,
    "rv": 25,
    "Asset Name": "ASTRID 002347",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Straw Hat",
    "Outfit": "Wizard",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00002262,
    "y_1": -0.00002247,
    "z_1": -0.00002888,
    "Distance in Parsecs_1": 22.1043,
    "HYG Star ID_1": 116570,
    "Hipparcos Catalogue_1": 116936,
    "Gliese Catalog_1": "Wo 9835"
  },
  {
    "Astrid #": "ASTRID 002348",
    "HYG Star ID": 5204,
    "Hipparcos Catalogue": 5215,
    "Gliese Catalog": "GJ 1030",
    "Distance in Parsecs": 22.1092,
    "mag": 11.45,
    "Absolute Visual Magnitude": 9.727,
    "Spectral Type": "M3",
    "Color Index": 1.473,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.431674,
    "y": 6.119284,
    "z": 5.823896,
    "vx": 0.00001031,
    "vy": -0.00000945,
    "vz": -0.00002623,
    "rarad": 0.29099809370487,
    "decrad": 0.266560604014948,
    "pmrarad": -5.43233729062499e-7,
    "pmdecrad": -0.000001229826863,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 5204,
    "lum": 0.0111995352238488,
    "var_min": 11.534,
    "var_max": 11.374,
    "ra": 1.111531,
    "dec": 15.272798,
    "pmra": -112.05,
    "pmdec": -253.67,
    "rv": 0,
    "Asset Name": "ASTRID 002348",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Round Brush",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00001031,
    "y_1": -0.00000945,
    "z_1": -0.00002623,
    "Distance in Parsecs_1": 22.1092,
    "HYG Star ID_1": 5204,
    "Hipparcos Catalogue_1": 5215,
    "Gliese Catalog_1": "GJ 1030"
  },
  {
    "Astrid #": "ASTRID 002349",
    "HYG Star ID": 75024,
    "Hipparcos Catalogue": 75253,
    "Henry Draper Catalogue": 136713,
    "Gliese Catalog": "GJ 1191",
    "Distance in Parsecs": 22.1141,
    "mag": 7.97,
    "Absolute Visual Magnitude": 6.247,
    "Spectral Type": "K2V",
    "Color Index": 0.97,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.778665,
    "y": -16.806029,
    "z": -4.091096,
    "vx": 0.00000114,
    "vy": 0.00001115,
    "vz": -0.00002032,
    "rarad": 4.02565248260008,
    "decrad": -0.186071701201852,
    "pmrarad": -2.79931419152777e-7,
    "pmdecrad": -9.81165926e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 75024,
    "lum": 0.276184944207529,
    "ra": 15.37686,
    "dec": -10.661123,
    "pmra": -57.74,
    "pmdec": -202.38,
    "rv": -5.3,
    "Asset Name": "ASTRID 002349",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Audiophile",
    "Outfit": "Toga",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000114,
    "y_1": 0.00001115,
    "z_1": -0.00002032,
    "Distance in Parsecs_1": 22.1141,
    "HYG Star ID_1": 75024,
    "Hipparcos Catalogue_1": 75253,
    "Henry Draper Catalogue_1": 136713,
    "Gliese Catalog_1": "GJ 1191"
  },
  {
    "Astrid #": "ASTRID 002350",
    "HYG Star ID": 88075,
    "Hipparcos Catalogue": 88348,
    "Henry Draper Catalogue": 164922,
    "Gliese Catalog": "Gl 700.2",
    "Distance in Parsecs": 22.119,
    "mag": 7.01,
    "Absolute Visual Magnitude": 5.286,
    "Spectral Type": "K0V",
    "Color Index": 0.799,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.217521,
    "y": -19.826132,
    "z": 9.804873,
    "vx": 0.00004231,
    "vy": -0.00004715,
    "vz": -0.00004852,
    "rarad": 4.72335808593418,
    "decrad": 0.459248510349842,
    "pmrarad": 0.00000188970676406944,
    "pmdecrad": -0.000002920760018,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 88075,
    "lum": 0.669267906935842,
    "ra": 18.041899,
    "dec": 26.313001,
    "pmra": 389.78,
    "pmdec": -602.45,
    "rv": 20.7,
    "Asset Name": "ASTRID 002350",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Goddess",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00004231,
    "y_1": -0.00004715,
    "z_1": -0.00004852,
    "Distance in Parsecs_1": 22.119,
    "HYG Star ID_1": 88075,
    "Hipparcos Catalogue_1": 88348,
    "Henry Draper Catalogue_1": 164922,
    "Gliese Catalog_1": "Gl 700.2"
  },
  {
    "Astrid #": "ASTRID 002351",
    "HYG Star ID": 6084,
    "Hipparcos Catalogue": 6097,
    "Gliese Catalog": "Gl 56.1",
    "Distance in Parsecs": 22.1239,
    "mag": 11.78,
    "Absolute Visual Magnitude": 10.056,
    "Spectral Type": "M",
    "Color Index": 1.44,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.320171,
    "y": 7.222386,
    "z": -4.939101,
    "vx": -0.00002158,
    "vy": 0.00001218,
    "vz": -0.000071,
    "rarad": 0.341503286256765,
    "decrad": -0.225144617931762,
    "pmrarad": 8.45515058888888e-7,
    "pmdecrad": -0.00000329256363,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 6084,
    "lum": 0.00827179951864134,
    "var_min": 11.866,
    "var_max": 11.686,
    "ra": 1.304446,
    "dec": -12.899836,
    "pmra": 174.4,
    "pmdec": -679.14,
    "rv": 0,
    "Asset Name": "ASTRID 002351",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Spiky",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002158,
    "y_1": 0.00001218,
    "z_1": -0.000071,
    "Distance in Parsecs_1": 22.1239,
    "HYG Star ID_1": 6084,
    "Hipparcos Catalogue_1": 6097,
    "Gliese Catalog_1": "Gl 56.1"
  },
  {
    "Astrid #": "ASTRID 002352",
    "HYG Star ID": 118228,
    "Gliese Catalog": "NN 3231B",
    "Distance in Parsecs": 22.1239,
    "mag": 10.75,
    "Absolute Visual Magnitude": 9.026,
    "Spectral Type": "dK6",
    "Color Index": 1.42,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.611993,
    "y": 16.511445,
    "z": 7.605135,
    "vx": 0.00000332,
    "vy": 0.00003277,
    "vz": 0.00000398,
    "rarad": 0.918497966292121,
    "decrad": 0.35088657686735,
    "pmrarad": 7.797258424375e-7,
    "pmdecrad": -2.66962653e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 2,
    "comp_primary": 118228,
    "base": "NN 3231",
    "lum": 0.0213599386018979,
    "ra": 3.508404,
    "dec": 20.10432,
    "pmra": 160.83,
    "pmdec": -55.06,
    "rv": 27.1,
    "Asset Name": "ASTRID 002352",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Elegant",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000332,
    "y_1": 0.00003277,
    "z_1": 0.00000398,
    "Distance in Parsecs_1": 22.1239,
    "HYG Star ID_1": 118228,
    "Gliese Catalog_1": "NN 3231B"
  },
  {
    "Astrid #": "ASTRID 002353",
    "HYG Star ID": 118379,
    "Gliese Catalog": "NN 3351",
    "Distance in Parsecs": 22.1239,
    "mag": 18.4,
    "Absolute Visual Magnitude": 16.676,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.385679,
    "y": 3.996744,
    "z": 21.751693,
    "vx": -0.00007176,
    "vy": 0.00008814,
    "vz": -0.00011057,
    "rarad": 1.47378078108452,
    "decrad": 1.38825380998745,
    "pmrarad": 0.00000361506169043055,
    "pmdecrad": -0.000004496220251,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118379,
    "lum": 0.0000186037288059505,
    "ra": 5.629428,
    "dec": 79.541084,
    "pmra": 745.66,
    "pmdec": -927.41,
    "rv": -92,
    "Asset Name": "ASTRID 002353",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Bucket Hat",
    "Outfit": "Tats",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00007176,
    "y_1": 0.00008814,
    "z_1": -0.00011057,
    "Distance in Parsecs_1": 22.1239,
    "HYG Star ID_1": 118379,
    "Gliese Catalog_1": "NN 3351"
  },
  {
    "Astrid #": "ASTRID 002354",
    "HYG Star ID": 66283,
    "Hipparcos Catalogue": 66492,
    "Gliese Catalog": "Gl 520A",
    "Distance in Parsecs": 22.1386,
    "mag": 9.77,
    "Absolute Visual Magnitude": 8.044,
    "Spectral Type": "M0...",
    "Color Index": 1.384,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.447452,
    "y": -6.117998,
    "z": 16.487651,
    "vx": -0.00000314,
    "vy": 0.00002619,
    "vz": -0.00003129,
    "rarad": 3.5685651802588,
    "decrad": 0.840167946514643,
    "pmrarad": -0.00000113562756533333,
    "pmdecrad": -6.74569755e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 66283,
    "base": "Gl 520",
    "lum": 0.0527715681764811,
    "var_min": 9.827,
    "var_max": 9.717,
    "ra": 13.630915,
    "dec": 48.138077,
    "pmra": -234.24,
    "pmdec": -139.14,
    "rv": -28,
    "Asset Name": "ASTRID 002354",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Oof",
    "Hair": "Audiohead",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000314,
    "y_1": 0.00002619,
    "z_1": -0.00003129,
    "Distance in Parsecs_1": 22.1386,
    "HYG Star ID_1": 66283,
    "Hipparcos Catalogue_1": 66492,
    "Gliese Catalog_1": "Gl 520A"
  },
  {
    "Astrid #": "ASTRID 002355",
    "HYG Star ID": 103127,
    "Hipparcos Catalogue": 103458,
    "Henry Draper Catalogue": 199288,
    "Gliese Catalog": "Gl 812.1",
    "Distance in Parsecs": 22.1386,
    "mag": 6.52,
    "Absolute Visual Magnitude": 4.794,
    "Spectral Type": "G0V",
    "Color Index": 0.587,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.121254,
    "y": -11.34993,
    "z": -15.414623,
    "vx": -0.00009547,
    "vy": 0.00001838,
    "vz": -0.00006832,
    "rarad": 5.4876196674487,
    "decrad": -0.770202829392471,
    "pmrarad": -0.00000249926300463194,
    "pmdecrad": -0.000004729987711,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 103127,
    "lum": 1.05293121284343,
    "ra": 20.961163,
    "dec": -44.129371,
    "pmra": -515.51,
    "pmdec": -975.63,
    "rv": -9.6,
    "Asset Name": "ASTRID 002355",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Arrogant",
    "Hair": "Audiophile",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": -0.00009547,
    "y_1": 0.00001838,
    "z_1": -0.00006832,
    "Distance in Parsecs_1": 22.1386,
    "HYG Star ID_1": 103127,
    "Hipparcos Catalogue_1": 103458,
    "Henry Draper Catalogue_1": 199288,
    "Gliese Catalog_1": "Gl 812.1"
  },
  {
    "Astrid #": "ASTRID 002356",
    "HYG Star ID": 119167,
    "Gliese Catalog": "Gl 686.1B",
    "Distance in Parsecs": 22.1386,
    "mag": 10.22,
    "Absolute Visual Magnitude": 8.494,
    "Color Index": 1.38,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.970235,
    "y": -20.290382,
    "z": 8.633603,
    "vx": -0.00001688,
    "vy": -0.00000691,
    "vz": -0.00001669,
    "rarad": 4.61559368367597,
    "decrad": 0.4006082430299,
    "pmrarad": -7.28626480506944e-7,
    "pmdecrad": -8.44099402e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 86017,
    "base": "Gl 686.1",
    "lum": 0.0348658293352872,
    "ra": 17.630269,
    "dec": 22.953162,
    "pmra": -150.29,
    "pmdec": -174.11,
    "rv": 1.3,
    "Asset Name": "ASTRID 002356",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Neko Ninja",
    "Hair": "Spiky",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001688,
    "y_1": -0.00000691,
    "z_1": -0.00001669,
    "Distance in Parsecs_1": 22.1386,
    "HYG Star ID_1": 119167,
    "Gliese Catalog_1": "Gl 686.1B"
  },
  {
    "Astrid #": "ASTRID 002357",
    "HYG Star ID": 54544,
    "Hipparcos Catalogue": 54704,
    "Henry Draper Catalogue": 97343,
    "Gliese Catalog": "NN 3648",
    "Distance in Parsecs": 22.1435,
    "mag": 7.05,
    "Absolute Visual Magnitude": 5.324,
    "Spectral Type": "G8/K0V",
    "Color Index": 0.76,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -19.44563,
    "y": 4.131536,
    "z": -9.754741,
    "vx": -0.00005401,
    "vy": -0.00001837,
    "vz": -0.00003174,
    "rarad": 2.93223843959611,
    "decrad": -0.456170896531391,
    "pmrarad": 0.00000131835384153472,
    "pmdecrad": -3.11832159e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 54544,
    "lum": 0.646249172495387,
    "ra": 11.200326,
    "dec": -26.136667,
    "pmra": 271.93,
    "pmdec": -64.32,
    "rv": 56.7,
    "Asset Name": "ASTRID 002357",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Hurt",
    "Hair": "Elegant",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00005401,
    "y_1": -0.00001837,
    "z_1": -0.00003174,
    "Distance in Parsecs_1": 22.1435,
    "HYG Star ID_1": 54544,
    "Hipparcos Catalogue_1": 54704,
    "Henry Draper Catalogue_1": 97343,
    "Gliese Catalog_1": "NN 3648"
  },
  {
    "Astrid #": "ASTRID 002358",
    "HYG Star ID": 86017,
    "Hipparcos Catalogue": 86282,
    "Gliese Catalog": "Gl 686.1A",
    "Distance in Parsecs": 22.1582,
    "mag": 9.29,
    "Absolute Visual Magnitude": 7.562,
    "Spectral Type": "M0...",
    "Color Index": 1.36,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.972276,
    "y": -20.307923,
    "z": 8.642096,
    "vx": -0.00001736,
    "vy": -0.00000832,
    "vz": -0.00001276,
    "rarad": 4.61557459643679,
    "decrad": 0.400650436746352,
    "pmrarad": -7.43607223236111e-7,
    "pmdecrad": -7.05743274e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 86017,
    "base": "Gl 686.1",
    "lum": 0.0822621393883109,
    "var_min": 9.357,
    "var_max": 9.247,
    "ra": 17.630196,
    "dec": 22.955579,
    "pmra": -153.38,
    "pmdec": -145.57,
    "rv": 4.1,
    "Asset Name": "ASTRID 002358",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Curly",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001736,
    "y_1": -0.00000832,
    "z_1": -0.00001276,
    "Distance in Parsecs_1": 22.1582,
    "HYG Star ID_1": 86017,
    "Hipparcos Catalogue_1": 86282,
    "Gliese Catalog_1": "Gl 686.1A"
  },
  {
    "Astrid #": "ASTRID 002359",
    "HYG Star ID": 1833,
    "Hipparcos Catalogue": 1837,
    "Henry Draper Catalogue": 1910,
    "Gliese Catalog": "GJ 2004",
    "Distance in Parsecs": 22.168,
    "mag": 8.74,
    "Absolute Visual Magnitude": 7.011,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.075,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.460733,
    "y": 1.880543,
    "z": -12.127866,
    "vx": 0.00000776,
    "vy": -0.00000295,
    "vz": 0.00001136,
    "rarad": 0.10151733280237,
    "decrad": -0.578882521899661,
    "pmrarad": -1.67794014840277e-7,
    "pmdecrad": 6.12125753e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 1833,
    "lum": 0.136646968074046,
    "ra": 0.387768,
    "dec": -33.167525,
    "pmra": -34.61,
    "pmdec": 126.26,
    "rv": 0,
    "Asset Name": "ASTRID 002359",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Halo Boy",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00000776,
    "y_1": -0.00000295,
    "z_1": 0.00001136,
    "Distance in Parsecs_1": 22.168,
    "HYG Star ID_1": 1833,
    "Hipparcos Catalogue_1": 1837,
    "Henry Draper Catalogue_1": 1910,
    "Gliese Catalog_1": "GJ 2004"
  },
  {
    "Astrid #": "ASTRID 002360",
    "HYG Star ID": 118144,
    "Gliese Catalog": "NN 3158",
    "Distance in Parsecs": 22.1729,
    "mag": 17.46,
    "Absolute Visual Magnitude": 15.731,
    "Spectral Type": "m",
    "Color Index": 2.06,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.67531,
    "y": 13.330749,
    "z": 1.229662,
    "vx": 0.00000449,
    "vy": 0.0000024,
    "vz": -0.00009015,
    "rarad": 0.64618480815786,
    "decrad": 0.0554863690112,
    "pmrarad": -3.55368427847222e-8,
    "pmdecrad": -0.000004072279776,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118144,
    "lum": 0.0000444221935471054,
    "ra": 2.468244,
    "dec": 3.179135,
    "pmra": -7.33,
    "pmdec": -839.97,
    "rv": 0,
    "Asset Name": "ASTRID 002360",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Innocent",
    "Outfit": "River Spirit",
    "Special": "Faerie",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000449,
    "y_1": 0.0000024,
    "z_1": -0.00009015,
    "Distance in Parsecs_1": 22.1729,
    "HYG Star ID_1": 118144,
    "Gliese Catalog_1": "NN 3158"
  },
  {
    "Astrid #": "ASTRID 002361",
    "HYG Star ID": 118246,
    "Gliese Catalog": "NN 3245",
    "Distance in Parsecs": 22.1729,
    "mag": 12.91,
    "Absolute Visual Magnitude": 11.181,
    "Spectral Type": "M0.5",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.220887,
    "y": 17.86546,
    "z": 4.807584,
    "vx": -0.00004827,
    "vy": 0.00007205,
    "vz": -0.00014477,
    "rarad": 0.970850329660325,
    "decrad": 0.218558353101399,
    "pmrarad": 0.00000363193320651388,
    "pmdecrad": -0.000006689177972,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118246,
    "lum": 0.00293494522226979,
    "ra": 3.708375,
    "dec": 12.522471,
    "pmra": 749.14,
    "pmdec": -1379.74,
    "rv": 0,
    "Asset Name": "ASTRID 002361",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Frizzy",
    "Outfit": "Painter",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004827,
    "y_1": 0.00007205,
    "z_1": -0.00014477,
    "Distance in Parsecs_1": 22.1729,
    "HYG Star ID_1": 118246,
    "Gliese Catalog_1": "NN 3245"
  },
  {
    "Astrid #": "ASTRID 002362",
    "HYG Star ID": 118262,
    "Henry Draper Catalogue": 23713,
    "Gliese Catalog": "Wo 9132",
    "Distance in Parsecs": 22.1729,
    "mag": 9.24,
    "Absolute Visual Magnitude": 7.511,
    "Spectral Type": "F6 V",
    "Color Index": 0.54,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.012067,
    "y": 16.974427,
    "z": 9.069554,
    "vx": 0.00000369,
    "vy": 0.00000754,
    "vz": -8.4e-7,
    "rarad": 0.995303950079888,
    "decrad": 0.4213919788284,
    "pmrarad": 4.52815977638888e-8,
    "pmdecrad": -1.88563432e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118262,
    "lum": 0.08621840810727,
    "ra": 3.801781,
    "dec": 24.143982,
    "pmra": 9.34,
    "pmdec": -38.89,
    "rv": 7.1,
    "Asset Name": "ASTRID 002362",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Battle Ready",
    "Hair": "Neko",
    "Outfit": "River Spirit",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000369,
    "y_1": 0.00000754,
    "z_1": -8.4e-7,
    "Distance in Parsecs_1": 22.1729,
    "HYG Star ID_1": 118262,
    "Henry Draper Catalogue_1": 23713,
    "Gliese Catalog_1": "Wo 9132"
  },
  {
    "Astrid #": "ASTRID 002363",
    "HYG Star ID": 118909,
    "Gliese Catalog": "NN 3797",
    "Distance in Parsecs": 22.1729,
    "mag": 15.3,
    "Absolute Visual Magnitude": 13.571,
    "Spectral Type": "m",
    "Color Index": 1.73,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.643266,
    "y": -6.383581,
    "z": 16.271099,
    "vx": -0.00002123,
    "vy": -0.0000463,
    "vz": -0.00003595,
    "rarad": 3.57919084127247,
    "decrad": 0.82394014914465,
    "pmrarad": 0.00000148590544953472,
    "pmdecrad": -0.000002387237107,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118909,
    "lum": 0.000324788018763536,
    "ra": 13.671502,
    "dec": 47.208293,
    "pmra": 306.49,
    "pmdec": -492.4,
    "rv": 0,
    "Asset Name": "ASTRID 002363",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Skater Helmet",
    "Outfit": "Sharky",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002123,
    "y_1": -0.0000463,
    "z_1": -0.00003595,
    "Distance in Parsecs_1": 22.1729,
    "HYG Star ID_1": 118909,
    "Gliese Catalog_1": "NN 3797"
  },
  {
    "Astrid #": "ASTRID 002364",
    "HYG Star ID": 119590,
    "Gliese Catalog": "GJ 1290",
    "Distance in Parsecs": 22.1729,
    "mag": 13.3,
    "Absolute Visual Magnitude": 11.571,
    "Spectral Type": "m",
    "Color Index": 1.59,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.567761,
    "y": -1.406588,
    "z": 8.162489,
    "vx": -1.4e-7,
    "vy": 0.00004443,
    "vz": 0.00000801,
    "rarad": 6.2148960604921,
    "decrad": 0.37699595924175,
    "pmrarad": 0.00000199878984219444,
    "pmdecrad": 3.88524835e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119590,
    "lum": 0.00204927385838024,
    "ra": 23.739154,
    "dec": 21.600277,
    "pmra": 412.28,
    "pmdec": 80.14,
    "rv": 0,
    "Asset Name": "ASTRID 002364",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Honest Lad",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -1.4e-7,
    "y_1": 0.00004443,
    "z_1": 0.00000801,
    "Distance in Parsecs_1": 22.1729,
    "HYG Star ID_1": 119590,
    "Gliese Catalog_1": "GJ 1290"
  },
  {
    "Astrid #": "ASTRID 002365",
    "HYG Star ID": 1320,
    "Hipparcos Catalogue": 1322,
    "Distance in Parsecs": 22.2025,
    "mag": 12.38,
    "Absolute Visual Magnitude": 10.648,
    "Spectral Type": "M4",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.154227,
    "y": 1.027597,
    "z": -17.07492,
    "vx": 0.00002001,
    "vy": 0.00003716,
    "vz": 0.00001882,
    "rarad": 0.0724649617498914,
    "decrad": -0.877359744136278,
    "pmrarad": 0.00000160419998759027,
    "pmdecrad": 0.000001326498711,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 1320,
    "lum": 0.0047951257408135,
    "var_min": 12.516,
    "var_max": 12.176,
    "ra": 0.276796,
    "dec": -50.26901,
    "pmra": 330.89,
    "pmdec": 273.61,
    "rv": 0,
    "Asset Name": "ASTRID 002365",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Chuckle",
    "Hair": "Afro",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002001,
    "y_1": 0.00003716,
    "z_1": 0.00001882,
    "Distance in Parsecs_1": 22.2025,
    "HYG Star ID_1": 1320,
    "Hipparcos Catalogue_1": 1322
  },
  {
    "Astrid #": "ASTRID 002366",
    "HYG Star ID": 98105,
    "Hipparcos Catalogue": 98416,
    "Henry Draper Catalogue": 189340,
    "Harvard Revised Catalogue": 7637,
    "Gliese Catalog": "Gl 773.3",
    "Distance in Parsecs": 22.2025,
    "mag": 5.87,
    "Absolute Visual Magnitude": 4.138,
    "Spectral Type": "F8V",
    "Color Index": 0.598,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.916697,
    "y": -18.948529,
    "z": -3.839532,
    "vx": -0.00001147,
    "vy": -0.00003329,
    "vz": -0.00004693,
    "rarad": 5.23506748541805,
    "decrad": -0.173804035380873,
    "pmrarad": -0.00000119618079403472,
    "pmdecrad": -0.000001902214957,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 98105,
    "lum": 1.92663745952408,
    "ra": 19.996485,
    "dec": -9.958238,
    "pmra": -246.73,
    "pmdec": -392.36,
    "rv": 30.2,
    "Asset Name": "ASTRID 002366",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Pompadour",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001147,
    "y_1": -0.00003329,
    "z_1": -0.00004693,
    "Distance in Parsecs_1": 22.2025,
    "HYG Star ID_1": 98105,
    "Hipparcos Catalogue_1": 98416,
    "Henry Draper Catalogue_1": 189340,
    "Harvard Revised Catalogue_1": 7637,
    "Gliese Catalog_1": "Gl 773.3"
  },
  {
    "Astrid #": "ASTRID 002367",
    "HYG Star ID": 78470,
    "Hipparcos Catalogue": 78709,
    "Henry Draper Catalogue": 144287,
    "Gliese Catalog": "Gl 609.2",
    "Distance in Parsecs": 22.2173,
    "mag": 7.1,
    "Absolute Visual Magnitude": 5.367,
    "Spectral Type": "G8V",
    "Color Index": 0.771,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.736708,
    "y": -17.576679,
    "z": 9.47872,
    "vx": -0.00000894,
    "vy": 0.00009251,
    "vz": 0.00004683,
    "rarad": 4.20651571694309,
    "decrad": 0.44078016630271,
    "pmrarad": -0.0000023697207891875,
    "pmdecrad": 0.000003377406024,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 78470,
    "lum": 0.621155020832441,
    "ra": 16.067706,
    "dec": 25.254843,
    "pmra": -488.79,
    "pmdec": 696.64,
    "rv": -48.2,
    "Asset Name": "ASTRID 002367",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Pompadour",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000894,
    "y_1": 0.00009251,
    "z_1": 0.00004683,
    "Distance in Parsecs_1": 22.2173,
    "HYG Star ID_1": 78470,
    "Hipparcos Catalogue_1": 78709,
    "Henry Draper Catalogue_1": 144287,
    "Gliese Catalog_1": "Gl 609.2"
  },
  {
    "Astrid #": "ASTRID 002368",
    "HYG Star ID": 59092,
    "Hipparcos Catalogue": 59272,
    "Henry Draper Catalogue": 105590,
    "Gliese Catalog": "Wo 9390A",
    "Distance in Parsecs": 22.2222,
    "mag": 6.81,
    "Absolute Visual Magnitude": 5.076,
    "Spectral Type": "G0...",
    "Color Index": 0.666,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.7295,
    "y": -0.898921,
    "z": -4.566021,
    "vx": 0.00000105,
    "vy": -0.00003456,
    "vz": -0.00001811,
    "rarad": 3.18293748365898,
    "decrad": -0.206944804440496,
    "pmrarad": 0.000001555961026375,
    "pmdecrad": -7.94221771e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 59092,
    "base": "Wo 9390",
    "lum": 0.812082216249983,
    "var_min": 6.897,
    "var_max": 6.787,
    "ra": 12.157926,
    "dec": -11.857064,
    "pmra": 320.94,
    "pmdec": -163.82,
    "rv": 4,
    "Asset Name": "ASTRID 002368",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Charmer",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000105,
    "y_1": -0.00003456,
    "z_1": -0.00001811,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 59092,
    "Hipparcos Catalogue_1": 59272,
    "Henry Draper Catalogue_1": 105590,
    "Gliese Catalog_1": "Wo 9390A"
  },
  {
    "Astrid #": "ASTRID 002369",
    "HYG Star ID": 108738,
    "Hipparcos Catalogue": 109084,
    "Gliese Catalog": "NN 4254",
    "Distance in Parsecs": 22.2222,
    "mag": 10.12,
    "Absolute Visual Magnitude": 8.386,
    "Spectral Type": "M0",
    "Color Index": 1.408,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.101981,
    "y": -10.387186,
    "z": -4.587689,
    "vx": -0.00001708,
    "vy": -0.00002327,
    "vz": -0.00001842,
    "rarad": 5.78513392917218,
    "decrad": -0.207941537407333,
    "pmrarad": -0.000001287180321875,
    "pmdecrad": -8.47017981e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 108738,
    "lum": 0.0385123482428931,
    "ra": 22.097584,
    "dec": -11.914172,
    "pmra": -265.5,
    "pmdec": -174.71,
    "rv": 0,
    "Asset Name": "ASTRID 002369",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Unkempt",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00001708,
    "y_1": -0.00002327,
    "z_1": -0.00001842,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 108738,
    "Hipparcos Catalogue_1": 109084,
    "Gliese Catalog_1": "NN 4254"
  },
  {
    "Astrid #": "ASTRID 002370",
    "HYG Star ID": 117975,
    "Gliese Catalog": "NN 3022",
    "Distance in Parsecs": 22.2222,
    "mag": 13.77,
    "Absolute Visual Magnitude": 12.036,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.816361,
    "y": 1.542357,
    "z": 7.624068,
    "vx": -0.00000282,
    "vy": 0.00002557,
    "vz": 0.00000254,
    "rarad": 0.0739546600887059,
    "decrad": 0.35019760101795,
    "pmrarad": 0.00000115720177411805,
    "pmdecrad": 1.21620359e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117975,
    "lum": 0.00133536503332718,
    "ra": 0.282486,
    "dec": 20.064845,
    "pmra": 238.69,
    "pmdec": 25.09,
    "rv": 0,
    "Asset Name": "ASTRID 002370",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Wavy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000282,
    "y_1": 0.00002557,
    "z_1": 0.00000254,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 117975,
    "Gliese Catalog_1": "NN 3022"
  },
  {
    "Astrid #": "ASTRID 002371",
    "HYG Star ID": 117982,
    "Gliese Catalog": "NN 3030",
    "Distance in Parsecs": 22.2222,
    "mag": 12.84,
    "Absolute Visual Magnitude": 11.106,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.450867,
    "y": 1.38849,
    "z": 16.824705,
    "vx": 2.4e-7,
    "vy": 0.0000236,
    "vz": -0.00000215,
    "rarad": 0.0957614507542229,
    "decrad": 0.8588817589874,
    "pmrarad": 0.00000105621508445833,
    "pmdecrad": -1.48440252e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117982,
    "lum": 0.00314485046547695,
    "ra": 0.365782,
    "dec": 49.2103,
    "pmra": 217.86,
    "pmdec": -30.62,
    "rv": 0,
    "Asset Name": "ASTRID 002371",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Afro",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 2.4e-7,
    "y_1": 0.0000236,
    "z_1": -0.00000215,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 117982,
    "Gliese Catalog_1": "NN 3030"
  },
  {
    "Astrid #": "ASTRID 002372",
    "HYG Star ID": 118032,
    "Gliese Catalog": "NN 3068",
    "Distance in Parsecs": 22.2222,
    "mag": 13.28,
    "Absolute Visual Magnitude": 11.546,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.396797,
    "y": 2.281342,
    "z": 20.447872,
    "vx": 0.00001325,
    "vy": -0.00002577,
    "vz": -0.00000256,
    "rarad": 0.265387301608459,
    "decrad": 1.16847656417814,
    "pmrarad": -0.00000127544783080555,
    "pmdecrad": -2.94456437e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118032,
    "lum": 0.00209700757854464,
    "ra": 1.013705,
    "dec": 66.948776,
    "pmra": -263.08,
    "pmdec": -60.74,
    "rv": 0,
    "Asset Name": "ASTRID 002372",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Braided Bun",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001325,
    "y_1": -0.00002577,
    "z_1": -0.00000256,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118032,
    "Gliese Catalog_1": "NN 3068"
  },
  {
    "Astrid #": "ASTRID 002373",
    "HYG Star ID": 118068,
    "Gliese Catalog": "NN 3096",
    "Distance in Parsecs": 22.2222,
    "mag": 13.05,
    "Absolute Visual Magnitude": 11.316,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.715424,
    "y": 7.070047,
    "z": -9.673341,
    "vx": -9.9e-7,
    "vy": 0.00001037,
    "vz": 0.00000565,
    "rarad": 0.361190761710061,
    "decrad": -0.4503723808629,
    "pmrarad": 4.52234201222222e-7,
    "pmdecrad": 2.82602742e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118068,
    "lum": 0.00259179113461594,
    "ra": 1.379647,
    "dec": -25.804437,
    "pmra": 93.28,
    "pmdec": 58.29,
    "rv": 0,
    "Asset Name": "ASTRID 002373",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Knocked Out",
    "Hair": "Pixie",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -9.9e-7,
    "y_1": 0.00001037,
    "z_1": 0.00000565,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118068,
    "Gliese Catalog_1": "NN 3096"
  },
  {
    "Astrid #": "ASTRID 002374",
    "HYG Star ID": 118085,
    "Gliese Catalog": "NN 3111",
    "Distance in Parsecs": 22.2222,
    "mag": 13.28,
    "Absolute Visual Magnitude": 11.546,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.273625,
    "y": 8.896173,
    "z": -8.986599,
    "vx": -0.00000155,
    "vy": 0.00000395,
    "vz": 7.5e-7,
    "rarad": 0.453056395316617,
    "decrad": -0.416319795951,
    "pmrarad": 1.90386332354166e-7,
    "pmdecrad": 3.700098e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118085,
    "lum": 0.00209700757854464,
    "ra": 1.730548,
    "dec": -23.853367,
    "pmra": 39.27,
    "pmdec": 7.63,
    "rv": 0,
    "Asset Name": "ASTRID 002374",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Cute Neko",
    "Hair": "Braid",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000155,
    "y_1": 0.00000395,
    "z_1": 7.5e-7,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118085,
    "Gliese Catalog_1": "NN 3111"
  },
  {
    "Astrid #": "ASTRID 002375",
    "HYG Star ID": 118124,
    "Gliese Catalog": "NN 3144",
    "Distance in Parsecs": 22.2222,
    "mag": 14.54,
    "Absolute Visual Magnitude": 12.806,
    "Spectral Type": "DA6",
    "Color Index": 0.23,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 14.131543,
    "y": 9.554765,
    "z": 14.241915,
    "vx": 0.00001542,
    "vy": -0.00001075,
    "vz": -0.00000809,
    "rarad": 0.594537711621992,
    "decrad": 0.69565290279385,
    "pmrarad": -7.89567560152777e-7,
    "pmdecrad": -4.74424123e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118124,
    "lum": 0.000657052390963552,
    "ra": 2.270967,
    "dec": 39.857975,
    "pmra": -162.86,
    "pmdec": -97.86,
    "rv": 0,
    "Asset Name": "ASTRID 002375",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Pixie",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001542,
    "y_1": -0.00001075,
    "z_1": -0.00000809,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118124,
    "Gliese Catalog_1": "NN 3144"
  },
  {
    "Astrid #": "ASTRID 002376",
    "HYG Star ID": 118132,
    "Gliese Catalog": "Gl 92.2",
    "Distance in Parsecs": 22.2222,
    "mag": 13.49,
    "Absolute Visual Magnitude": 11.756,
    "Spectral Type": "M3",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.106089,
    "y": 9.024315,
    "z": 15.511878,
    "vx": -0.00002291,
    "vy": 0.00005124,
    "vz": -0.00001044,
    "rarad": 0.602956879113095,
    "decrad": 0.77264992750755,
    "pmrarad": 0.00000248428226190277,
    "pmdecrad": -6.56369849e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118132,
    "lum": 0.00172822387294976,
    "ra": 2.303126,
    "dec": 44.26958,
    "pmra": 512.42,
    "pmdec": -135.39,
    "rv": 0,
    "Asset Name": "ASTRID 002376",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Good Boy",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002291,
    "y_1": 0.00005124,
    "z_1": -0.00001044,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118132,
    "Gliese Catalog_1": "Gl 92.2"
  },
  {
    "Astrid #": "ASTRID 002377",
    "HYG Star ID": 118163,
    "Gliese Catalog": "NN 3174",
    "Distance in Parsecs": 22.2222,
    "mag": 14.27,
    "Absolute Visual Magnitude": 12.536,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.92236,
    "y": 14.110547,
    "z": 2.890046,
    "vx": -0.00002807,
    "vy": 0.00003618,
    "vz": -0.00001224,
    "rarad": 0.695036559849818,
    "decrad": 0.1304215990856,
    "pmrarad": 0.00000205953699636805,
    "pmdecrad": -5.55698288e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118163,
    "lum": 0.000842558375339825,
    "ra": 2.654844,
    "dec": 7.472607,
    "pmra": 424.81,
    "pmdec": -114.62,
    "rv": 0,
    "Asset Name": "ASTRID 002377",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Neko Ninja",
    "Hair": "Braided Ponytail",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002807,
    "y_1": 0.00003618,
    "z_1": -0.00001224,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118163,
    "Gliese Catalog_1": "NN 3174"
  },
  {
    "Astrid #": "ASTRID 002378",
    "HYG Star ID": 118169,
    "Gliese Catalog": "NN 3176",
    "Distance in Parsecs": 22.2222,
    "mag": 13.1,
    "Absolute Visual Magnitude": 11.366,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.098333,
    "y": 11.279298,
    "z": -13.965575,
    "vx": -0.0000443,
    "vy": 0.00003775,
    "vz": -0.00001107,
    "rarad": 0.710880237587955,
    "decrad": -0.67956089900375,
    "pmrarad": 0.00000258837175911805,
    "pmdecrad": -6.40560075e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118169,
    "lum": 0.00247514131807431,
    "ra": 2.715362,
    "dec": -38.935971,
    "pmra": 533.89,
    "pmdec": -132.12,
    "rv": 0,
    "Asset Name": "ASTRID 002378",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Windy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000443,
    "y_1": 0.00003775,
    "z_1": -0.00001107,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118169,
    "Gliese Catalog_1": "NN 3176"
  },
  {
    "Astrid #": "ASTRID 002379",
    "HYG Star ID": 118183,
    "Gliese Catalog": "NN 3189",
    "Distance in Parsecs": 22.2222,
    "mag": 12.67,
    "Absolute Visual Magnitude": 10.936,
    "Spectral Type": "M2.5",
    "Color Index": 1.73,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.443812,
    "y": 15.198549,
    "z": -4.956021,
    "vx": 0.00006213,
    "vy": 0.00010846,
    "vz": 0.0000354,
    "rarad": 0.777392359277964,
    "decrad": -0.2248562223062,
    "pmrarad": 0.00000151814555929166,
    "pmdecrad": 0.000002761493876,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118183,
    "lum": 0.00367790070422386,
    "ra": 2.96942,
    "dec": -12.883313,
    "pmra": 313.14,
    "pmdec": 569.6,
    "rv": 107,
    "Asset Name": "ASTRID 002379",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Shinobi",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00006213,
    "y_1": 0.00010846,
    "z_1": 0.0000354,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118183,
    "Gliese Catalog_1": "NN 3189"
  },
  {
    "Astrid #": "ASTRID 002380",
    "HYG Star ID": 118188,
    "Gliese Catalog": "NN 3195B",
    "Distance in Parsecs": 22.2222,
    "mag": 12.6,
    "Absolute Visual Magnitude": 10.866,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.285524,
    "y": 7.591877,
    "z": 19.573215,
    "vx": -0.00001066,
    "vy": 0.00010022,
    "vz": -0.00003488,
    "rarad": 0.80579423027186,
    "decrad": 1.07753970626625,
    "pmrarad": 0.00000347000543720833,
    "pmdecrad": -0.000003315994675,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118188,
    "base": "NN 3195",
    "lum": 0.0039228346253952,
    "ra": 3.077907,
    "dec": 61.738477,
    "pmra": 715.74,
    "pmdec": -683.97,
    "rv": 0,
    "Asset Name": "ASTRID 002380",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Pompadour",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001066,
    "y_1": 0.00010022,
    "z_1": -0.00003488,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118188,
    "Gliese Catalog_1": "NN 3195B"
  },
  {
    "Astrid #": "ASTRID 002381",
    "HYG Star ID": 118212,
    "Gliese Catalog": "NN 3220B",
    "Distance in Parsecs": 22.2222,
    "mag": 18,
    "Absolute Visual Magnitude": 16.266,
    "Spectral Type": "a",
    "Spectral Abbreviation": "a",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 12.524441,
    "y": 15.302696,
    "z": 10.138642,
    "vx": -0.00001291,
    "vy": 0.00001553,
    "vz": -0.00000749,
    "rarad": 0.884902603099295,
    "decrad": 0.4737644468751,
    "pmrarad": 8.92541985902777e-7,
    "pmdecrad": -3.78862498e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118212,
    "base": "NN 3220",
    "lum": 0.0000271393848764158,
    "ra": 3.380079,
    "dec": 27.144703,
    "pmra": 184.1,
    "pmdec": -78.15,
    "rv": 0,
    "Asset Name": "ASTRID 002381",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Wavy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001291,
    "y_1": 0.00001553,
    "z_1": -0.00000749,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118212,
    "Gliese Catalog_1": "NN 3220B"
  },
  {
    "Astrid #": "ASTRID 002382",
    "HYG Star ID": 118304,
    "Gliese Catalog": "NN 3287",
    "Distance in Parsecs": 22.2222,
    "mag": 14.45,
    "Absolute Visual Magnitude": 12.716,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.409463,
    "y": 10.347832,
    "z": 19.165207,
    "vx": -0.00000372,
    "vy": 0.0000219,
    "vz": -0.00001097,
    "rarad": 1.16794740878675,
    "decrad": 1.04006116495519,
    "pmrarad": 5.406157351875e-7,
    "pmdecrad": -9.75260896e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118304,
    "lum": 0.000713838553601663,
    "ra": 4.46123,
    "dec": 59.591115,
    "pmra": 111.51,
    "pmdec": -201.16,
    "rv": 0,
    "Asset Name": "ASTRID 002382",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Oh Snap",
    "Hair": "Hachimaki",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000372,
    "y_1": 0.0000219,
    "z_1": -0.00001097,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118304,
    "Gliese Catalog_1": "NN 3287"
  },
  {
    "Astrid #": "ASTRID 002383",
    "HYG Star ID": 118332,
    "Gliese Catalog": "NN 3310",
    "Distance in Parsecs": 22.2222,
    "mag": 13.45,
    "Absolute Visual Magnitude": 11.716,
    "Spectral Type": "k-m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.227676,
    "y": 18.245856,
    "z": 11.051289,
    "vx": 0.00001927,
    "vy": 0.0000061,
    "vz": -0.00002092,
    "rarad": 1.24188067041525,
    "decrad": 0.5204936876529,
    "pmrarad": -7.31971694902777e-7,
    "pmdecrad": -0.000001085206942,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118332,
    "lum": 0.00179308137708044,
    "ra": 4.743635,
    "dec": 29.822092,
    "pmra": -150.98,
    "pmdec": -223.84,
    "rv": 0,
    "Asset Name": "ASTRID 002383",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Happy Vamp",
    "Hair": "Shinobi",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001927,
    "y_1": 0.0000061,
    "z_1": -0.00002092,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118332,
    "Gliese Catalog_1": "NN 3310"
  },
  {
    "Astrid #": "ASTRID 002384",
    "HYG Star ID": 118396,
    "Gliese Catalog": "NN 3368",
    "Distance in Parsecs": 22.2222,
    "mag": 14.19,
    "Absolute Visual Magnitude": 12.456,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.114053,
    "y": 21.990385,
    "z": 3.00133,
    "vx": -0.00000733,
    "vy": 0.00000417,
    "vz": -0.00002784,
    "rarad": 1.52017854938999,
    "decrad": 0.1354740165385,
    "pmrarad": 3.38787799972222e-7,
    "pmdecrad": -0.00000126438923,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118396,
    "lum": 0.000906984781505792,
    "ra": 5.806654,
    "dec": 7.762089,
    "pmra": 69.88,
    "pmdec": -260.8,
    "rv": 0,
    "Asset Name": "ASTRID 002384",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Angelic",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000733,
    "y_1": 0.00000417,
    "z_1": -0.00002784,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118396,
    "Gliese Catalog_1": "NN 3368"
  },
  {
    "Astrid #": "ASTRID 002385",
    "HYG Star ID": 118410,
    "Gliese Catalog": "NN 3381",
    "Distance in Parsecs": 22.2222,
    "mag": 13.94,
    "Absolute Visual Magnitude": 12.206,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.227904,
    "y": 14.923773,
    "z": 16.463756,
    "vx": 0.00000769,
    "vy": 0.0000468,
    "vz": -0.00004231,
    "rarad": 1.58607562399425,
    "decrad": 0.8343643343589,
    "pmrarad": -3.783001149375e-7,
    "pmdecrad": -0.000002835272822,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118410,
    "lum": 0.00114182618954822,
    "ra": 6.058363,
    "dec": 47.805555,
    "pmra": -78.03,
    "pmdec": -584.82,
    "rv": 0,
    "Asset Name": "ASTRID 002385",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Innocent",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000769,
    "y_1": 0.0000468,
    "z_1": -0.00004231,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118410,
    "Gliese Catalog_1": "NN 3381"
  },
  {
    "Astrid #": "ASTRID 002386",
    "HYG Star ID": 118531,
    "Gliese Catalog": "NN 3486",
    "Distance in Parsecs": 22.2222,
    "mag": 12.55,
    "Absolute Visual Magnitude": 10.816,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.356735,
    "y": 2.241127,
    "z": -22.067234,
    "vx": 0.00002396,
    "vy": 0.00006101,
    "vz": 0.00000471,
    "rarad": 2.11604050763371,
    "decrad": -1.45263027167435,
    "pmrarad": -0.00000234620732568055,
    "pmdecrad": 0.000001800326513,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118531,
    "lum": 0.00410771212553387,
    "ra": 8.082679,
    "dec": -83.229584,
    "pmra": -483.94,
    "pmdec": 371.34,
    "rv": 0,
    "Asset Name": "ASTRID 002386",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Slick",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002396,
    "y_1": 0.00006101,
    "z_1": 0.00000471,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118531,
    "Gliese Catalog_1": "NN 3486"
  },
  {
    "Astrid #": "ASTRID 002387",
    "HYG Star ID": 118561,
    "Gliese Catalog": "NN 3513",
    "Distance in Parsecs": 22.2222,
    "mag": 14,
    "Absolute Visual Magnitude": 12.266,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.366458,
    "y": 16.472093,
    "z": -4.012628,
    "vx": -0.00001868,
    "vy": -0.00002953,
    "vz": -0.00005438,
    "rarad": 2.28801972689409,
    "decrad": -0.18156436655375,
    "pmrarad": 0.00000150689788190277,
    "pmdecrad": -0.000002488151075,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118561,
    "lum": 0.00108043837237122,
    "ra": 8.739592,
    "dec": -10.402872,
    "pmra": 310.82,
    "pmdec": -513.22,
    "rv": 0,
    "Asset Name": "ASTRID 002387",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Charmer",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001868,
    "y_1": -0.00002953,
    "z_1": -0.00005438,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118561,
    "Gliese Catalog_1": "NN 3513"
  },
  {
    "Astrid #": "ASTRID 002388",
    "HYG Star ID": 118630,
    "Gliese Catalog": "GJ 1126B",
    "Distance in Parsecs": 22.2222,
    "mag": 13.08,
    "Absolute Visual Magnitude": 11.346,
    "Spectral Type": "g",
    "Color Index": 1.46,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.9578,
    "y": 11.429527,
    "z": -11.809162,
    "vx": -0.00001255,
    "vy": 0.00001968,
    "vz": 0.00003493,
    "rarad": 2.48912023408294,
    "decrad": -0.56026746241975,
    "pmrarad": -3.60798341069444e-7,
    "pmdecrad": 0.000001856031605,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 46493,
    "base": "GJ 1126",
    "lum": 0.0025211576308074,
    "ra": 9.507739,
    "dec": -32.100961,
    "pmra": -74.42,
    "pmdec": 382.83,
    "rv": 0,
    "Asset Name": "ASTRID 002388",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Afro",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001255,
    "y_1": 0.00001968,
    "z_1": 0.00003493,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118630,
    "Gliese Catalog_1": "GJ 1126B"
  },
  {
    "Astrid #": "ASTRID 002389",
    "HYG Star ID": 118658,
    "Gliese Catalog": "NN 3577A",
    "Distance in Parsecs": 22.2222,
    "mag": 13.89,
    "Absolute Visual Magnitude": 12.156,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.856962,
    "y": 8.054705,
    "z": 15.392612,
    "vx": -0.00000954,
    "vy": 0.00001447,
    "vz": -0.00001616,
    "rarad": 2.61506206382209,
    "decrad": 0.76518192398355,
    "pmrarad": -3.47223558013888e-7,
    "pmdecrad": -0.000001008480329,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118658,
    "base": "NN 3577",
    "lum": 0.00119563879998812,
    "ra": 9.988801,
    "dec": 43.841695,
    "pmra": -71.62,
    "pmdec": -208.01,
    "rv": 0,
    "Asset Name": "ASTRID 002389",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Halo Boy",
    "Outfit": "Wizard",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000954,
    "y_1": 0.00001447,
    "z_1": -0.00001616,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118658,
    "Gliese Catalog_1": "NN 3577A"
  },
  {
    "Astrid #": "ASTRID 002390",
    "HYG Star ID": 118659,
    "Gliese Catalog": "NN 3578B",
    "Distance in Parsecs": 22.2222,
    "mag": 14.16,
    "Absolute Visual Magnitude": 12.426,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.858133,
    "y": 8.052701,
    "z": 15.392606,
    "vx": -0.00000954,
    "vy": 0.00001447,
    "vz": -0.00001616,
    "rarad": 2.61520692182209,
    "decrad": 0.76518155998355,
    "pmrarad": -3.47223558013888e-7,
    "pmdecrad": -0.000001008480329,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118659,
    "base": "NN 3578",
    "lum": 0.000932395137540632,
    "ra": 9.989355,
    "dec": 43.841674,
    "pmra": -71.62,
    "pmdec": -208.01,
    "rv": 0,
    "Asset Name": "ASTRID 002390",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Einstein Tongue",
    "Hair": "Ram Horns",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000954,
    "y_1": 0.00001447,
    "z_1": -0.00001616,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118659,
    "Gliese Catalog_1": "NN 3578B"
  },
  {
    "Astrid #": "ASTRID 002391",
    "HYG Star ID": 118670,
    "Gliese Catalog": "NN 3588",
    "Distance in Parsecs": 22.2222,
    "mag": 12.95,
    "Absolute Visual Magnitude": 11.216,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.107676,
    "y": 8.116293,
    "z": -12.980553,
    "vx": 0.0000122,
    "vy": 0.00005283,
    "vz": 0.00001788,
    "rarad": 2.67487547859951,
    "decrad": -0.623802174529,
    "pmrarad": -0.00000237044800970833,
    "pmdecrad": 9.9158942e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118670,
    "lum": 0.00284184246806358,
    "ra": 10.217272,
    "dec": -35.741232,
    "pmra": -488.94,
    "pmdec": 204.53,
    "rv": 0,
    "Asset Name": "ASTRID 002391",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Bangs",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000122,
    "y_1": 0.00005283,
    "z_1": 0.00001788,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118670,
    "Gliese Catalog_1": "NN 3588"
  },
  {
    "Astrid #": "ASTRID 002392",
    "HYG Star ID": 118680,
    "Gliese Catalog": "NN 3598",
    "Distance in Parsecs": 22.2222,
    "mag": 12.46,
    "Absolute Visual Magnitude": 10.726,
    "Spectral Type": "M0",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.873104,
    "y": 8.814281,
    "z": 4.603737,
    "vx": 0.00000361,
    "vy": 0.0000031,
    "vz": 0.00000967,
    "rarad": 2.72413393623927,
    "decrad": 0.20867967516165,
    "pmrarad": -1.93295214437499e-7,
    "pmdecrad": 4.44603233e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118680,
    "lum": 0.00446272370762252,
    "ra": 10.405425,
    "dec": 11.956465,
    "pmra": -39.87,
    "pmdec": 91.71,
    "rv": 0,
    "Asset Name": "ASTRID 002392",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Bob",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000361,
    "y_1": 0.0000031,
    "z_1": 0.00000967,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118680,
    "Gliese Catalog_1": "NN 3598"
  },
  {
    "Astrid #": "ASTRID 002393",
    "HYG Star ID": 118714,
    "Gliese Catalog": "NN 3629",
    "Distance in Parsecs": 22.2222,
    "mag": 13.46,
    "Absolute Visual Magnitude": 11.726,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.150375,
    "y": 5.296613,
    "z": 13.101018,
    "vx": 0.00000475,
    "vy": 0.00001875,
    "vz": -0.00000137,
    "rarad": 2.84206181902946,
    "decrad": 0.6304971551215,
    "pmrarad": -8.69319410604166e-7,
    "pmdecrad": -7.605757e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118714,
    "lum": 0.00177664230820361,
    "ra": 10.855876,
    "dec": 36.124826,
    "pmra": -179.31,
    "pmdec": -15.69,
    "rv": 0,
    "Asset Name": "ASTRID 002393",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Horns",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000475,
    "y_1": 0.00001875,
    "z_1": -0.00000137,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118714,
    "Gliese Catalog_1": "NN 3629"
  },
  {
    "Astrid #": "ASTRID 002394",
    "HYG Star ID": 118787,
    "Gliese Catalog": "NN 3694",
    "Distance in Parsecs": 22.2222,
    "mag": 14.3,
    "Absolute Visual Magnitude": 12.566,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.019299,
    "y": 0.39167,
    "z": -7.201518,
    "vx": 0.00000961,
    "vy": -0.00005583,
    "vz": -0.00003113,
    "rarad": 3.12295388101266,
    "decrad": -0.33002708786675,
    "pmrarad": 0.00000250382025322916,
    "pmdecrad": -0.000001480737335,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118787,
    "lum": 0.000819596320481844,
    "ra": 11.928805,
    "dec": -18.909159,
    "pmra": 516.45,
    "pmdec": -305.42,
    "rv": 0,
    "Asset Name": "ASTRID 002394",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Ram Horns",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000961,
    "y_1": -0.00005583,
    "z_1": -0.00003113,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118787,
    "Gliese Catalog_1": "NN 3694"
  },
  {
    "Astrid #": "ASTRID 002395",
    "HYG Star ID": 118803,
    "Gliese Catalog": "NN 3713",
    "Distance in Parsecs": 22.2222,
    "mag": 12.91,
    "Absolute Visual Magnitude": 11.176,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.611612,
    "y": -0.77801,
    "z": 16.724882,
    "vx": -0.00002204,
    "vy": -0.00002454,
    "vz": -0.00002039,
    "rarad": 3.19476119444194,
    "decrad": 0.852032728094199,
    "pmrarad": 0.00000105034883892361,
    "pmdecrad": -0.000001393878116,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118803,
    "lum": 0.00294849231389436,
    "ra": 12.203089,
    "dec": 48.817879,
    "pmra": 216.65,
    "pmdec": -287.51,
    "rv": 0,
    "Asset Name": "ASTRID 002395",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Pirate Hat",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002204,
    "y_1": -0.00002454,
    "z_1": -0.00002039,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118803,
    "Gliese Catalog_1": "NN 3713"
  },
  {
    "Astrid #": "ASTRID 002396",
    "HYG Star ID": 118973,
    "Gliese Catalog": "NN 3851",
    "Distance in Parsecs": 22.2222,
    "mag": 16.99,
    "Absolute Visual Magnitude": 15.256,
    "Spectral Type": "M5.5:",
    "Color Index": 2.22,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.3281,
    "y": -9.329106,
    "z": 15.957989,
    "vx": -0.00001425,
    "vy": 0.00011024,
    "vz": -0.00004337,
    "rarad": 3.78948523619316,
    "decrad": 0.8012445081383,
    "pmrarad": -0.00000434247613673611,
    "pmdecrad": 4.25782766e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118973,
    "lum": 0.0000688018316143036,
    "ra": 14.474767,
    "dec": 45.907929,
    "pmra": -895.7,
    "pmdec": 87.82,
    "rv": -68,
    "Asset Name": "ASTRID 002396",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Einstein Tongue",
    "Hair": "Braided Ponytail",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001425,
    "y_1": 0.00011024,
    "z_1": -0.00004337,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 118973,
    "Gliese Catalog_1": "NN 3851"
  },
  {
    "Astrid #": "ASTRID 002397",
    "HYG Star ID": 119017,
    "Gliese Catalog": "NN 3902",
    "Distance in Parsecs": 22.2222,
    "mag": 13.44,
    "Absolute Visual Magnitude": 11.706,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.404743,
    "y": -6.399599,
    "z": 20.583004,
    "vx": -0.00001302,
    "vy": 0.00006239,
    "vz": 0.00001599,
    "rarad": 4.01125766180497,
    "decrad": 1.1843018116028,
    "pmrarad": -0.00000225884390044444,
    "pmdecrad": 0.000001908852056,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119017,
    "lum": 0.00180967255478879,
    "ra": 15.321876,
    "dec": 67.855495,
    "pmra": -465.92,
    "pmdec": 393.73,
    "rv": 0,
    "Asset Name": "ASTRID 002397",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Undercut",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001302,
    "y_1": 0.00006239,
    "z_1": 0.00001599,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119017,
    "Gliese Catalog_1": "NN 3902"
  },
  {
    "Astrid #": "ASTRID 002398",
    "HYG Star ID": 119040,
    "Gliese Catalog": "NN 3915",
    "Distance in Parsecs": 22.2222,
    "mag": 11.65,
    "Absolute Visual Magnitude": 9.916,
    "Spectral Type": "M2-3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.380341,
    "y": -9.824867,
    "z": -18.883575,
    "vx": -0.00003598,
    "vy": 0.00005046,
    "vz": -0.00001411,
    "rarad": 4.13654680197942,
    "decrad": -1.0155331815256,
    "pmrarad": -0.00000259448041149305,
    "pmdecrad": -0.000001204330512,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 119040,
    "lum": 0.0094102248352675,
    "ra": 15.800445,
    "dec": -58.185765,
    "pmra": -535.15,
    "pmdec": -248.41,
    "rv": 0,
    "Asset Name": "ASTRID 002398",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Pompadour",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00003598,
    "y_1": 0.00005046,
    "z_1": -0.00001411,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119040,
    "Gliese Catalog_1": "NN 3915"
  },
  {
    "Astrid #": "ASTRID 002399",
    "HYG Star ID": 119055,
    "Gliese Catalog": "NN 3935A",
    "Distance in Parsecs": 22.2222,
    "mag": 13.37,
    "Absolute Visual Magnitude": 11.636,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.441067,
    "y": -16.667119,
    "z": 11.264972,
    "vx": -0.00000824,
    "vy": 0.00001279,
    "vz": 0.00001202,
    "rarad": 4.19700986744869,
    "decrad": 0.53161284308005,
    "pmrarad": -6.06211026166666e-7,
    "pmdecrad": 6.27741601e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119055,
    "base": "NN 3935",
    "lum": 0.00193018972763457,
    "ra": 16.031397,
    "dec": 30.459172,
    "pmra": -125.04,
    "pmdec": 129.48,
    "rv": 0,
    "Asset Name": "ASTRID 002399",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Innocent",
    "Outfit": "Astro",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000824,
    "y_1": 0.00001279,
    "z_1": 0.00001202,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119055,
    "Gliese Catalog_1": "NN 3935A"
  },
  {
    "Astrid #": "ASTRID 002400",
    "HYG Star ID": 119056,
    "Gliese Catalog": "NN 3936B",
    "Distance in Parsecs": 22.2222,
    "mag": 15.17,
    "Absolute Visual Magnitude": 13.436,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.450603,
    "y": -16.661368,
    "z": 11.265482,
    "vx": -0.00000823,
    "vy": 0.0000128,
    "vz": 0.00001202,
    "rarad": 4.19642876244869,
    "decrad": 0.53163948508005,
    "pmrarad": -6.06211026166666e-7,
    "pmdecrad": 6.27741601e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119056,
    "base": "NN 3936",
    "lum": 0.000367790070422386,
    "ra": 16.029177,
    "dec": 30.460699,
    "pmra": -125.04,
    "pmdec": 129.48,
    "rv": 0,
    "Asset Name": "ASTRID 002400",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Ponytail",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000823,
    "y_1": 0.0000128,
    "z_1": 0.00001202,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119056,
    "Gliese Catalog_1": "NN 3936B"
  },
  {
    "Astrid #": "ASTRID 002401",
    "HYG Star ID": 119111,
    "Gliese Catalog": "NN 3979",
    "Distance in Parsecs": 22.2222,
    "mag": 13.44,
    "Absolute Visual Magnitude": 11.706,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.906334,
    "y": -16.278707,
    "z": 14.30936,
    "vx": -0.00000862,
    "vy": -0.00001229,
    "vz": -0.00001693,
    "rarad": 4.41965538351735,
    "decrad": 0.69961326321095,
    "pmrarad": -2.11669652930555e-7,
    "pmdecrad": -9.95855781e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119111,
    "lum": 0.00180967255478879,
    "ra": 16.88184,
    "dec": 40.084887,
    "pmra": -43.66,
    "pmdec": -205.41,
    "rv": 0,
    "Asset Name": "ASTRID 002401",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Braided Ponytail",
    "Outfit": "Toga",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000862,
    "y_1": -0.00001229,
    "z_1": -0.00001693,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119111,
    "Gliese Catalog_1": "NN 3979"
  },
  {
    "Astrid #": "ASTRID 002402",
    "HYG Star ID": 119124,
    "Gliese Catalog": "NN 3986",
    "Distance in Parsecs": 22.2222,
    "mag": 12.67,
    "Absolute Visual Magnitude": 10.936,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.27378,
    "y": -4.552978,
    "z": 21.713453,
    "vx": 0.00002547,
    "vy": 0.00000815,
    "vz": 0.0000032,
    "rarad": 4.43940898424171,
    "decrad": 1.35640643299145,
    "pmrarad": 0.00000100482483431944,
    "pmdecrad": 6.77759829e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119124,
    "lum": 0.00367790070422386,
    "ra": 16.957293,
    "dec": 77.716364,
    "pmra": 207.26,
    "pmdec": 139.8,
    "rv": 0,
    "Asset Name": "ASTRID 002402",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Determined Shinobi",
    "Hair": "Lion Mane",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002547,
    "y_1": 0.00000815,
    "z_1": 0.0000032,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119124,
    "Gliese Catalog_1": "NN 3986"
  },
  {
    "Astrid #": "ASTRID 002403",
    "HYG Star ID": 119170,
    "Gliese Catalog": "NN 4019B",
    "Distance in Parsecs": 22.2222,
    "mag": 12.72,
    "Absolute Visual Magnitude": 10.986,
    "Spectral Type": "M5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.754093,
    "y": -19.583431,
    "z": 10.355606,
    "vx": -5e-7,
    "vy": -0.00000953,
    "vz": -0.00001811,
    "rarad": 4.62305702736664,
    "decrad": 0.48476757973015,
    "pmrarad": 1.60473328263888e-8,
    "pmdecrad": -9.21005397e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119170,
    "base": "NN 4019",
    "lum": 0.0035123679046568,
    "ra": 17.658777,
    "dec": 27.775136,
    "pmra": 3.31,
    "pmdec": -189.97,
    "rv": 0,
    "Asset Name": "ASTRID 002403",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Frizzy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -5e-7,
    "y_1": -0.00000953,
    "z_1": -0.00001811,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119170,
    "Gliese Catalog_1": "NN 4019B"
  },
  {
    "Astrid #": "ASTRID 002404",
    "HYG Star ID": 119219,
    "Henry Draper Catalogue": 336196,
    "Gliese Catalog": "NN 4057",
    "Distance in Parsecs": 22.2222,
    "mag": 10.79,
    "Absolute Visual Magnitude": 9.056,
    "Spectral Type": "M0",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.20486,
    "y": -20.079338,
    "z": 9.262017,
    "vx": -0.00000578,
    "vy": -0.00001932,
    "vz": -0.0000405,
    "rarad": 4.82175995826913,
    "decrad": 0.42991238492515,
    "pmrarad": -3.53574617229166e-7,
    "pmdecrad": -0.000002005281497,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 119219,
    "lum": 0.0207778209750426,
    "ra": 18.417766,
    "dec": 24.632165,
    "pmra": -72.93,
    "pmdec": -413.62,
    "rv": 0,
    "Asset Name": "ASTRID 002404",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Manbun",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000578,
    "y_1": -0.00001932,
    "z_1": -0.0000405,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119219,
    "Henry Draper Catalogue_1": 336196,
    "Gliese Catalog_1": "NN 4057"
  },
  {
    "Astrid #": "ASTRID 002405",
    "HYG Star ID": 119240,
    "Gliese Catalog": "NN 4077",
    "Distance in Parsecs": 22.2222,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.396,
    "Spectral Type": "k",
    "Color Index": 1.51,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.472896,
    "y": -21.035925,
    "z": -5.595464,
    "vx": -0.00002508,
    "vy": 0.00000136,
    "vz": -0.00002516,
    "rarad": 4.92190179691652,
    "decrad": -0.25453576992125,
    "pmrarad": -0.00000109112166945833,
    "pmdecrad": -0.000001170078425,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119240,
    "lum": 0.006047835918818,
    "ra": 18.80028,
    "dec": -14.583825,
    "pmra": -225.06,
    "pmdec": -241.35,
    "rv": 0,
    "Asset Name": "ASTRID 002405",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Braided Ponytail",
    "Outfit": "Viking",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002508,
    "y_1": 0.00000136,
    "z_1": -0.00002516,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119240,
    "Gliese Catalog_1": "NN 4077"
  },
  {
    "Astrid #": "ASTRID 002406",
    "HYG Star ID": 119333,
    "Gliese Catalog": "NN 4137",
    "Distance in Parsecs": 22.2222,
    "mag": 14.98,
    "Absolute Visual Magnitude": 13.246,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.837358,
    "y": -16.860218,
    "z": -9.597442,
    "vx": -0.0000029,
    "vy": 0.00004331,
    "vz": -0.0000794,
    "rarad": 5.28366450433959,
    "decrad": -0.4465820921438,
    "pmrarad": 9.43786791937499e-7,
    "pmdecrad": -0.000003961562876,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119333,
    "lum": 0.000438126982022492,
    "ra": 20.182112,
    "dec": -25.587269,
    "pmra": 194.67,
    "pmdec": -817.13,
    "rv": 0,
    "Asset Name": "ASTRID 002406",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Angelic",
    "Outfit": "Warlock",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000029,
    "y_1": 0.00004331,
    "z_1": -0.0000794,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119333,
    "Gliese Catalog_1": "NN 4137"
  },
  {
    "Astrid #": "ASTRID 002407",
    "HYG Star ID": 119340,
    "Gliese Catalog": "NN 4144",
    "Distance in Parsecs": 22.2222,
    "mag": 12.92,
    "Absolute Visual Magnitude": 11.186,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.594825,
    "y": -18.044957,
    "z": 3.09453,
    "vx": 0.00000883,
    "vy": 0.00000325,
    "vz": -0.00001695,
    "rarad": 5.321750512378,
    "decrad": 0.139708037280899,
    "pmrarad": 4.09667560069444e-7,
    "pmdecrad": -7.70514382e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119340,
    "lum": 0.00292146037387733,
    "ra": 20.32759,
    "dec": 8.004681,
    "pmra": 84.5,
    "pmdec": -158.93,
    "rv": 0,
    "Asset Name": "ASTRID 002407",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Curly",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000883,
    "y_1": 0.00000325,
    "z_1": -0.00001695,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119340,
    "Gliese Catalog_1": "NN 4144"
  },
  {
    "Astrid #": "ASTRID 002408",
    "HYG Star ID": 119350,
    "Gliese Catalog": "NN 4148",
    "Distance in Parsecs": 22.2222,
    "mag": 12.93,
    "Absolute Visual Magnitude": 11.196,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.67917,
    "y": -15.980355,
    "z": 8.814366,
    "vx": 0.00002042,
    "vy": 0.00002067,
    "vz": 0.0000081,
    "rarad": 5.38310042285555,
    "decrad": 0.40786119939355,
    "pmrarad": 0.00000129813711105555,
    "pmdecrad": 3.96887871e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119350,
    "lum": 0.00289467626417602,
    "ra": 20.561929,
    "dec": 23.368725,
    "pmra": 267.76,
    "pmdec": 81.86,
    "rv": 0,
    "Asset Name": "ASTRID 002408",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Ninja",
    "Hair": "Manbun",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002042,
    "y_1": 0.00002067,
    "z_1": 0.0000081,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119350,
    "Gliese Catalog_1": "NN 4148"
  },
  {
    "Astrid #": "ASTRID 002409",
    "HYG Star ID": 119407,
    "Gliese Catalog": "NN 4191",
    "Distance in Parsecs": 22.2222,
    "mag": 14.28,
    "Absolute Visual Magnitude": 12.546,
    "Spectral Type": "DA6",
    "Color Index": 0.26,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.540029,
    "y": -8.041445,
    "z": -18.388834,
    "vx": 0.00001608,
    "vy": 0.00004437,
    "vz": -0.00001107,
    "rarad": 5.58274036345203,
    "decrad": -0.97463739296635,
    "pmrarad": 0.00000199306904076388,
    "pmdecrad": -8.87359327e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119407,
    "lum": 0.000834833753723649,
    "ra": 21.324497,
    "dec": -55.842609,
    "pmra": 411.1,
    "pmdec": -183.03,
    "rv": 0,
    "Asset Name": "ASTRID 002409",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Fired Up",
    "Hair": "Pompadour",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001608,
    "y_1": 0.00004437,
    "z_1": -0.00001107,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119407,
    "Gliese Catalog_1": "NN 4191"
  },
  {
    "Astrid #": "ASTRID 002410",
    "HYG Star ID": 119490,
    "Gliese Catalog": "NN 4272",
    "Distance in Parsecs": 22.2222,
    "mag": 14.8,
    "Absolute Visual Magnitude": 13.066,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.696186,
    "y": -8.30864,
    "z": -10.565874,
    "vx": 0.00002773,
    "vy": 0.00008875,
    "vz": -0.00002339,
    "rarad": 5.84419272266505,
    "decrad": -0.495492311432199,
    "pmrarad": 0.0000041455933010625,
    "pmdecrad": -0.000001196568644,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119490,
    "lum": 0.000517130317916962,
    "ra": 22.323172,
    "dec": -28.389618,
    "pmra": 855.09,
    "pmdec": -246.81,
    "rv": 0,
    "Asset Name": "ASTRID 002410",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Malevolent",
    "Hair": "Fedora",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002773,
    "y_1": 0.00008875,
    "z_1": -0.00002339,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119490,
    "Gliese Catalog_1": "NN 4272"
  },
  {
    "Astrid #": "ASTRID 002411",
    "HYG Star ID": 119511,
    "Gliese Catalog": "NN 4283",
    "Distance in Parsecs": 22.2222,
    "mag": 12.68,
    "Absolute Visual Magnitude": 10.946,
    "Spectral Type": "dM4  :",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 20.651301,
    "y": -8.055698,
    "z": 1.567052,
    "vx": -0.00000537,
    "vy": -0.00001051,
    "vz": 0.00001672,
    "rarad": 5.91125824650254,
    "decrad": 0.0705759797158,
    "pmrarad": -5.28349949069444e-7,
    "pmdecrad": 7.54554316e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119511,
    "lum": 0.0036441815078886,
    "ra": 22.579343,
    "dec": 4.043706,
    "pmra": -108.98,
    "pmdec": 155.64,
    "rv": 0,
    "Asset Name": "ASTRID 002411",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Pirate Hat",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000537,
    "y_1": -0.00001051,
    "z_1": 0.00001672,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119511,
    "Gliese Catalog_1": "NN 4283"
  },
  {
    "Astrid #": "ASTRID 002412",
    "HYG Star ID": 119539,
    "Gliese Catalog": "NN 4305B",
    "Distance in Parsecs": 22.2222,
    "mag": 15.7,
    "Absolute Visual Magnitude": 13.966,
    "Spectral Type": "DB",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 21.251849,
    "y": -6.100611,
    "z": 2.228819,
    "vx": 0.00001312,
    "vy": 0.00003509,
    "vz": -0.00002903,
    "rarad": 6.00363913429966,
    "decrad": 0.10046584116865,
    "pmrarad": 0.00000168094599322222,
    "pmdecrad": -0.000001313316627,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119539,
    "base": "NN 4305",
    "lum": 0.000225735571102144,
    "ra": 22.932212,
    "dec": 5.756269,
    "pmra": 346.72,
    "pmdec": -270.89,
    "rv": 0,
    "Asset Name": "ASTRID 002412",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "Spiky",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001312,
    "y_1": 0.00003509,
    "z_1": -0.00002903,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119539,
    "Gliese Catalog_1": "NN 4305B"
  },
  {
    "Astrid #": "ASTRID 002413",
    "HYG Star ID": 119573,
    "Gliese Catalog": "NN 4344",
    "Distance in Parsecs": 22.2222,
    "mag": 13.34,
    "Absolute Visual Magnitude": 11.606,
    "Spectral Type": "DA",
    "Color Index": 0.13,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 21.208579,
    "y": -2.26617,
    "z": -6.235931,
    "vx": -0.00000311,
    "vy": 0.00000821,
    "vz": -0.00001356,
    "rarad": 6.17673687982542,
    "decrad": -0.28443706383815,
    "pmrarad": 3.525565085e-7,
    "pmdecrad": -6.36036763e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119573,
    "lum": 0.00198426649848464,
    "ra": 23.593397,
    "dec": -16.297043,
    "pmra": 72.72,
    "pmdec": -131.19,
    "rv": 0,
    "Asset Name": "ASTRID 002413",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Owie",
    "Hair": "Little Crown",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000311,
    "y_1": 0.00000821,
    "z_1": -0.00001356,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119573,
    "Gliese Catalog_1": "NN 4344"
  },
  {
    "Astrid #": "ASTRID 002414",
    "HYG Star ID": 119599,
    "Gliese Catalog": "NN 4369",
    "Distance in Parsecs": 22.2222,
    "mag": 14.18,
    "Absolute Visual Magnitude": 12.446,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.379131,
    "y": -0.690947,
    "z": 13.831651,
    "vx": 0.00001368,
    "vy": -0.00001063,
    "vz": -0.00001772,
    "rarad": 6.24345525484009,
    "decrad": 0.671837136686799,
    "pmrarad": -4.53543198159722e-7,
    "pmdecrad": -0.000001018666264,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119599,
    "lum": 0.000915377008362351,
    "ra": 23.848242,
    "dec": 38.493432,
    "pmra": -93.55,
    "pmdec": -210.12,
    "rv": 0,
    "Asset Name": "ASTRID 002414",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Stunned",
    "Hair": "Curly",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001368,
    "y_1": -0.00001063,
    "z_1": -0.00001772,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119599,
    "Gliese Catalog_1": "NN 4369"
  },
  {
    "Astrid #": "ASTRID 002415",
    "HYG Star ID": 119604,
    "Gliese Catalog": "NN 4373",
    "Distance in Parsecs": 22.2222,
    "mag": 13.52,
    "Absolute Visual Magnitude": 11.786,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.772733,
    "y": -0.350679,
    "z": 17.436027,
    "vx": -0.00000462,
    "vy": 0.0000273,
    "vz": 0.0000042,
    "rarad": 6.25769153539766,
    "decrad": 0.9020861591478,
    "pmrarad": 0.00000122308795330555,
    "pmdecrad": 3.04942956e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119604,
    "lum": 0.00168112497447695,
    "ra": 23.902621,
    "dec": 51.68573,
    "pmra": 252.28,
    "pmdec": 62.9,
    "rv": 0,
    "Asset Name": "ASTRID 002415",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Prince",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000462,
    "y_1": 0.0000273,
    "z_1": 0.0000042,
    "Distance in Parsecs_1": 22.2222,
    "HYG Star ID_1": 119604,
    "Gliese Catalog_1": "NN 4373"
  },
  {
    "Astrid #": "ASTRID 002416",
    "HYG Star ID": 69479,
    "Hipparcos Catalogue": 69701,
    "Henry Draper Catalogue": 124850,
    "Harvard Revised Catalogue": 5338,
    "Gliese Catalog": "Wo 9473",
    "Distance in Parsecs": 22.237,
    "mag": 4.07,
    "Absolute Visual Magnitude": 2.335,
    "Spectral Type": "F7V",
    "Color Index": 0.511,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -18.333625,
    "y": -12.367873,
    "z": -2.324624,
    "vx": -0.00000995,
    "vy": -0.00000335,
    "vz": -0.00004657,
    "rarad": 3.73506784983626,
    "decrad": -0.104729295115365,
    "pmrarad": -1.25275855055555e-7,
    "pmdecrad": -0.000002035441756,
    "bayer": "Iot",
    "flam": 99,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 69479,
    "lum": 10.1391138573667,
    "bf": "99Iot Vir",
    "ra": 14.266908,
    "dec": -6.000547,
    "pmra": -25.84,
    "pmdec": -419.84,
    "rv": 14.6,
    "Asset Name": "ASTRID 002416",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Lovestruck",
    "Hair": "Pixie",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000995,
    "y_1": -0.00000335,
    "z_1": -0.00004657,
    "Distance in Parsecs_1": 22.237,
    "HYG Star ID_1": 69479,
    "Hipparcos Catalogue_1": 69701,
    "Henry Draper Catalogue_1": 124850,
    "Harvard Revised Catalogue_1": 5338,
    "Gliese Catalog_1": "Wo 9473"
  },
  {
    "Astrid #": "ASTRID 002417",
    "HYG Star ID": 106967,
    "Hipparcos Catalogue": 107310,
    "Henry Draper Catalogue": 206826,
    "Harvard Revised Catalogue": 8309,
    "Gliese Catalog": "Gl 836.6A",
    "Distance in Parsecs": 22.237,
    "mag": 4.49,
    "Absolute Visual Magnitude": 2.755,
    "Spectral Type": "F6V",
    "Color Index": 0.512,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.170769,
    "y": -10.892651,
    "z": 10.693308,
    "vx": 0.00003787,
    "vy": 0.00000833,
    "vz": -0.00001517,
    "rarad": 5.69039558851022,
    "decrad": 0.501653568102847,
    "pmrarad": 0.00000126211545459027,
    "pmdecrad": -0.000001176788246,
    "bayer": "Mu-1",
    "flam": 78,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 106967,
    "base": "Gl 836.6",
    "lum": 6.88652296344276,
    "bf": "78Mu 1Cyg",
    "ra": 21.73571,
    "dec": 28.742632,
    "pmra": 260.33,
    "pmdec": -242.73,
    "rv": 15.8,
    "Asset Name": "ASTRID 002417",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Round Brush",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00003787,
    "y_1": 0.00000833,
    "z_1": -0.00001517,
    "Distance in Parsecs_1": 22.237,
    "HYG Star ID_1": 106967,
    "Hipparcos Catalogue_1": 107310,
    "Henry Draper Catalogue_1": 206826,
    "Harvard Revised Catalogue_1": 8309,
    "Gliese Catalog_1": "Gl 836.6A"
  },
  {
    "Astrid #": "ASTRID 002418",
    "HYG Star ID": 118126,
    "Gliese Catalog": "Gl 91.2B",
    "Distance in Parsecs": 22.242,
    "mag": 9.1,
    "Absolute Visual Magnitude": 7.364,
    "Spectral Type": "K",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.524825,
    "y": 11.794997,
    "z": -6.961689,
    "vx": -0.00000122,
    "vy": -0.00000902,
    "vz": -0.00001835,
    "rarad": 0.59240622270431,
    "decrad": -0.31834745721465,
    "pmrarad": -3.05965913798611e-7,
    "pmdecrad": -8.68844293e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 10518,
    "base": "Gl  91.2",
    "lum": 0.0987188301068396,
    "ra": 2.262825,
    "dec": -18.239966,
    "pmra": -63.11,
    "pmdec": -179.21,
    "rv": 0,
    "Asset Name": "ASTRID 002418",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Medium Bob",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000122,
    "y_1": -0.00000902,
    "z_1": -0.00001835,
    "Distance in Parsecs_1": 22.242,
    "HYG Star ID_1": 118126,
    "Gliese Catalog_1": "Gl 91.2B"
  },
  {
    "Astrid #": "ASTRID 002419",
    "HYG Star ID": 41366,
    "Hipparcos Catalogue": 41484,
    "Henry Draper Catalogue": 71148,
    "Harvard Revised Catalogue": 3309,
    "Gliese Catalog": "Gl 307.1",
    "Distance in Parsecs": 22.2519,
    "mag": 6.32,
    "Absolute Visual Magnitude": 4.583,
    "Spectral Type": "G5V",
    "Color Index": 0.624,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.339604,
    "y": 12.437706,
    "z": 15.912562,
    "vx": -9.5e-7,
    "vy": 0.00000468,
    "vz": -0.00004983,
    "rarad": 2.21488028384568,
    "decrad": 0.796794931797665,
    "pmrarad": -9.20176365694444e-8,
    "pmdecrad": -0.000001704023124,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 41366,
    "lum": 1.27879226293584,
    "ra": 8.460219,
    "dec": 45.652987,
    "pmra": -18.98,
    "pmdec": -351.48,
    "rv": -31.9,
    "Asset Name": "ASTRID 002419",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Knocked Out",
    "Hair": "Bob",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -9.5e-7,
    "y_1": 0.00000468,
    "z_1": -0.00004983,
    "Distance in Parsecs_1": 22.2519,
    "HYG Star ID_1": 41366,
    "Hipparcos Catalogue_1": 41484,
    "Henry Draper Catalogue_1": 71148,
    "Harvard Revised Catalogue_1": 3309,
    "Gliese Catalog_1": "Gl 307.1"
  },
  {
    "Astrid #": "ASTRID 002420",
    "HYG Star ID": 96395,
    "Hipparcos Catalogue": 96702,
    "Gliese Catalog": "NN 4120",
    "Distance in Parsecs": 22.2519,
    "mag": 10.94,
    "Absolute Visual Magnitude": 9.203,
    "Spectral Type": "M:",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.913602,
    "y": -6.280566,
    "z": 21.147399,
    "vx": 5.1e-7,
    "vy": -0.00004991,
    "vz": -0.00001489,
    "rarad": 5.14676607415565,
    "decrad": 1.25440281929066,
    "pmrarad": -9.23182210513888e-7,
    "pmdecrad": -0.000002151166781,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 96395,
    "lum": 0.0181467977939692,
    "var_min": 10.984,
    "var_max": 10.874,
    "ra": 19.659198,
    "dec": 71.871987,
    "pmra": -190.42,
    "pmdec": -443.71,
    "rv": 0,
    "Asset Name": "ASTRID 002420",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Pixie",
    "Outfit": "Emperor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 5.1e-7,
    "y_1": -0.00004991,
    "z_1": -0.00001489,
    "Distance in Parsecs_1": 22.2519,
    "HYG Star ID_1": 96395,
    "Hipparcos Catalogue_1": 96702,
    "Gliese Catalog_1": "NN 4120"
  },
  {
    "Astrid #": "ASTRID 002421",
    "HYG Star ID": 118346,
    "Gliese Catalog": "Gl 186.1B",
    "Distance in Parsecs": 22.2519,
    "mag": 10.6,
    "Absolute Visual Magnitude": 8.863,
    "Spectral Type": "m",
    "Color Index": 1.36,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.086082,
    "y": 12.02237,
    "z": -18.468508,
    "vx": 0.0000205,
    "vy": 0.00005174,
    "vz": 0.00003709,
    "rarad": 1.31953892261268,
    "decrad": -0.9790618072151,
    "pmrarad": -3.14207746368055e-7,
    "pmdecrad": 0.000002989375698,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 2,
    "comp_primary": 23382,
    "base": "Gl 186.1",
    "lum": 0.0248198984350709,
    "ra": 5.040267,
    "dec": -56.096109,
    "pmra": -64.81,
    "pmdec": 616.6,
    "rv": 0,
    "Asset Name": "ASTRID 002421",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Mortified",
    "Hair": "Prince",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.0000205,
    "y_1": 0.00005174,
    "z_1": 0.00003709,
    "Distance in Parsecs_1": 22.2519,
    "HYG Star ID_1": 118346,
    "Gliese Catalog_1": "Gl 186.1B"
  },
  {
    "Astrid #": "ASTRID 002422",
    "HYG Star ID": 34324,
    "Hipparcos Catalogue": 34414,
    "Henry Draper Catalogue": 53927,
    "Gliese Catalog": "NN 3428",
    "Distance in Parsecs": 22.2568,
    "mag": 8.32,
    "Absolute Visual Magnitude": 6.583,
    "Spectral Type": "G5",
    "Color Index": 0.907,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.650564,
    "y": 18.46181,
    "z": 11.072769,
    "vx": 0.00000613,
    "vy": 0.00003794,
    "vz": -0.00001697,
    "rarad": 1.86781153996361,
    "decrad": 0.520710121505759,
    "pmrarad": -7.62175587201388e-7,
    "pmdecrad": -0.000001432285056,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 34324,
    "lum": 0.202674915209896,
    "ra": 7.134515,
    "dec": 29.834492,
    "pmra": -157.21,
    "pmdec": -295.43,
    "rv": 21,
    "Asset Name": "ASTRID 002422",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Honest Lad",
    "Outfit": "Elven",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00000613,
    "y_1": 0.00003794,
    "z_1": -0.00001697,
    "Distance in Parsecs_1": 22.2568,
    "HYG Star ID_1": 34324,
    "Hipparcos Catalogue_1": 34414,
    "Henry Draper Catalogue_1": 53927,
    "Gliese Catalog_1": "NN 3428"
  },
  {
    "Astrid #": "ASTRID 002423",
    "HYG Star ID": 20172,
    "Hipparcos Catalogue": 20222,
    "Distance in Parsecs": 22.2668,
    "mag": 11.46,
    "Absolute Visual Magnitude": 9.722,
    "Spectral Type": "M3V",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.566264,
    "y": 16.224955,
    "z": 13.240577,
    "vx": -0.00000859,
    "vy": 0.00003621,
    "vz": -0.00003946,
    "rarad": 1.1344393960151,
    "decrad": 0.636809255657497,
    "pmrarad": 0.00000103725886954861,
    "pmdecrad": -0.000002204496286,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 20172,
    "lum": 0.0112512299296576,
    "var_min": 11.557,
    "var_max": 11.397,
    "ra": 4.333239,
    "dec": 36.486483,
    "pmra": 213.95,
    "pmdec": -454.71,
    "rv": 0,
    "Asset Name": "ASTRID 002423",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "High Fade",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00000859,
    "y_1": 0.00003621,
    "z_1": -0.00003946,
    "Distance in Parsecs_1": 22.2668,
    "HYG Star ID_1": 20172,
    "Hipparcos Catalogue_1": 20222
  },
  {
    "Astrid #": "ASTRID 002424",
    "HYG Star ID": 85393,
    "Hipparcos Catalogue": 85653,
    "Henry Draper Catalogue": 159062,
    "Gliese Catalog": "NN 4010",
    "Distance in Parsecs": 22.2668,
    "mag": 7.22,
    "Absolute Visual Magnitude": 5.482,
    "Spectral Type": "G5",
    "Color Index": 0.737,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.949341,
    "y": -14.944634,
    "z": 16.391106,
    "vx": 0.00001945,
    "vy": 0.00000362,
    "vz": 0.00000561,
    "rarad": 4.58268023343885,
    "decrad": 0.827324520796353,
    "pmrarad": 8.45078726576388e-7,
    "pmdecrad": 3.72433869e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 85393,
    "lum": 0.558727438994019,
    "ra": 17.504549,
    "dec": 47.402203,
    "pmra": 174.31,
    "pmdec": 76.82,
    "rv": 0,
    "Asset Name": "ASTRID 002424",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Slick",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001945,
    "y_1": 0.00000362,
    "z_1": 0.00000561,
    "Distance in Parsecs_1": 22.2668,
    "HYG Star ID_1": 85393,
    "Hipparcos Catalogue_1": 85653,
    "Henry Draper Catalogue_1": 159062,
    "Gliese Catalog_1": "NN 4010"
  },
  {
    "Astrid #": "ASTRID 002425",
    "HYG Star ID": 118117,
    "Gliese Catalog": "Gl 85.1",
    "Distance in Parsecs": 22.2717,
    "mag": 13.74,
    "Absolute Visual Magnitude": 12.001,
    "Spectral Type": "M3",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.302449,
    "y": 9.755343,
    "z": 12.911119,
    "vx": 0.00004249,
    "vy": -0.00001196,
    "vz": -0.00004131,
    "rarad": 0.567555895913715,
    "decrad": 0.61837223341545,
    "pmrarad": -0.00000147868172569444,
    "pmdecrad": -0.000002276951691,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118117,
    "lum": 0.00137911346902359,
    "ra": 2.167904,
    "dec": 35.430119,
    "pmra": -305,
    "pmdec": -469.65,
    "rv": 0,
    "Asset Name": "ASTRID 002425",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Nervous Grin",
    "Hair": "Spiky",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004249,
    "y_1": -0.00001196,
    "z_1": -0.00004131,
    "Distance in Parsecs_1": 22.2717,
    "HYG Star ID_1": 118117,
    "Gliese Catalog_1": "Gl 85.1"
  },
  {
    "Astrid #": "ASTRID 002426",
    "HYG Star ID": 118368,
    "Gliese Catalog": "Gl 200.1",
    "Distance in Parsecs": 22.2717,
    "mag": 13.19,
    "Absolute Visual Magnitude": 11.451,
    "Spectral Type": "k",
    "Color Index": 1.16,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.410049,
    "y": 12.969099,
    "z": -17.944993,
    "vx": -0.00003086,
    "vy": -0.00003489,
    "vz": -0.00002937,
    "rarad": 1.38702500004633,
    "decrad": -0.936908128009549,
    "pmrarad": 0.00000107614092672916,
    "pmdecrad": -0.000002226080191,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118368,
    "lum": 0.00228875865706235,
    "ra": 5.298045,
    "dec": -53.680882,
    "pmra": 221.97,
    "pmdec": -459.16,
    "rv": 0,
    "Asset Name": "ASTRID 002426",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Undercut",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003086,
    "y_1": -0.00003489,
    "z_1": -0.00002937,
    "Distance in Parsecs_1": 22.2717,
    "HYG Star ID_1": 118368,
    "Gliese Catalog_1": "Gl 200.1"
  },
  {
    "Astrid #": "ASTRID 002427",
    "HYG Star ID": 118426,
    "Gliese Catalog": "NN 3393",
    "Distance in Parsecs": 22.2717,
    "mag": 14.55,
    "Absolute Visual Magnitude": 12.811,
    "Spectral Type": "m",
    "Color Index": 1.76,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.397003,
    "y": 12.318787,
    "z": 18.502013,
    "vx": -0.00001867,
    "vy": 0.00004437,
    "vz": -0.00003094,
    "rarad": 1.68369398490659,
    "decrad": 0.9804373756274,
    "pmrarad": 6.08538131833333e-7,
    "pmdecrad": -0.000002496407452,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118426,
    "lum": 0.000654033509448894,
    "ra": 6.431237,
    "dec": 56.174924,
    "pmra": 125.52,
    "pmdec": -514.92,
    "rv": 0,
    "Asset Name": "ASTRID 002427",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Greasy",
    "Outfit": "Samurai",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001867,
    "y_1": 0.00004437,
    "z_1": -0.00003094,
    "Distance in Parsecs_1": 22.2717,
    "HYG Star ID_1": 118426,
    "Gliese Catalog_1": "NN 3393"
  },
  {
    "Astrid #": "ASTRID 002428",
    "HYG Star ID": 98005,
    "Hipparcos Catalogue": 98316,
    "Henry Draper Catalogue": 188559,
    "Gliese Catalog": "GJ 1247",
    "Distance in Parsecs": 22.2816,
    "mag": 8.54,
    "Absolute Visual Magnitude": 6.8,
    "Spectral Type": "K3V",
    "Color Index": 1.05,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.329874,
    "y": -11.125661,
    "z": -18.237929,
    "vx": 0.00000492,
    "vy": 0.00001521,
    "vz": -0.00000757,
    "rarad": 5.22965640654629,
    "decrad": -0.958829570658672,
    "pmrarad": 5.29319576430555e-7,
    "pmdecrad": -5.91618134e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 98005,
    "lum": 0.165958690743756,
    "ra": 19.975816,
    "dec": -54.936888,
    "pmra": 109.18,
    "pmdec": -122.03,
    "rv": 0,
    "Asset Name": "ASTRID 002428",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Pixie",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00000492,
    "y_1": 0.00001521,
    "z_1": -0.00000757,
    "Distance in Parsecs_1": 22.2816,
    "HYG Star ID_1": 98005,
    "Hipparcos Catalogue_1": 98316,
    "Henry Draper Catalogue_1": 188559,
    "Gliese Catalog_1": "GJ 1247"
  },
  {
    "Astrid #": "ASTRID 002429",
    "HYG Star ID": 113891,
    "Hipparcos Catalogue": 114252,
    "Gliese Catalog": "Gl 890",
    "Distance in Parsecs": 22.2816,
    "mag": 10.87,
    "Absolute Visual Magnitude": 9.13,
    "Spectral Type": "Me",
    "Color Index": 1.397,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.93688,
    "y": -4.802321,
    "z": -5.92075,
    "vx": 0.0000037,
    "vy": 0.00001041,
    "vz": -0.00000306,
    "rarad": 6.0577138945694,
    "decrad": -0.268954319471901,
    "pmrarad": 4.92522218076388e-7,
    "pmdecrad": -1.17082503e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113891,
    "lum": 0.0194088587759277,
    "var": "HK",
    "var_min": 10.952,
    "var_max": 10.782,
    "ra": 23.138763,
    "dec": -15.409947,
    "pmra": 101.59,
    "pmdec": -24.15,
    "rv": 2,
    "Asset Name": "ASTRID 002429",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Windy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.0000037,
    "y_1": 0.00001041,
    "z_1": -0.00000306,
    "Distance in Parsecs_1": 22.2816,
    "HYG Star ID_1": 113891,
    "Hipparcos Catalogue_1": 114252,
    "Gliese Catalog_1": "Gl 890"
  },
  {
    "Astrid #": "ASTRID 002430",
    "HYG Star ID": 10313,
    "Hipparcos Catalogue": 10337,
    "Gliese Catalog": "GJ 1044",
    "Distance in Parsecs": 22.2866,
    "mag": 9.84,
    "Absolute Visual Magnitude": 8.1,
    "Spectral Type": "K7V",
    "Color Index": 1.346,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.366959,
    "y": 11.408259,
    "z": -8.058097,
    "vx": -0.00002062,
    "vy": 0.00003529,
    "vz": 0.00000553,
    "rarad": 0.581205532304032,
    "decrad": -0.369947916803652,
    "pmrarad": 0.00000183157760377083,
    "pmdecrad": 2.66308154e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10313,
    "lum": 0.0501187233627272,
    "ra": 2.220042,
    "dec": -21.196454,
    "pmra": 377.79,
    "pmdec": 54.93,
    "rv": 0,
    "Asset Name": "ASTRID 002430",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Undercut",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002062,
    "y_1": 0.00003529,
    "z_1": 0.00000553,
    "Distance in Parsecs_1": 22.2866,
    "HYG Star ID_1": 10313,
    "Hipparcos Catalogue_1": 10337,
    "Gliese Catalog_1": "GJ 1044"
  },
  {
    "Astrid #": "ASTRID 002431",
    "HYG Star ID": 39837,
    "Hipparcos Catalogue": 39950,
    "Gliese Catalog": "NN 3484",
    "Distance in Parsecs": 22.2916,
    "mag": 11.84,
    "Absolute Visual Magnitude": 10.099,
    "Color Index": 1.53,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -11.0757,
    "y": 17.466674,
    "z": 8.316225,
    "vx": 0.00002765,
    "vy": 0.00002057,
    "vz": -0.00000637,
    "rarad": 2.13591714005933,
    "decrad": 0.382310776860292,
    "pmrarad": -0.00000154170750416666,
    "pmdecrad": -3.08244538e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 39837,
    "lum": 0.00795060175084419,
    "var_min": 11.972,
    "var_max": 11.722,
    "ra": 8.158603,
    "dec": 21.904794,
    "pmra": -318,
    "pmdec": -63.58,
    "rv": 0,
    "Asset Name": "ASTRID 002431",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Ponytail",
    "Outfit": "Force Sensitive",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00002765,
    "y_1": 0.00002057,
    "z_1": -0.00000637,
    "Distance in Parsecs_1": 22.2916,
    "HYG Star ID_1": 39837,
    "Hipparcos Catalogue_1": 39950,
    "Gliese Catalog_1": "NN 3484"
  },
  {
    "Astrid #": "ASTRID 002432",
    "HYG Star ID": 102157,
    "Hipparcos Catalogue": 102488,
    "Henry Draper Catalogue": 197989,
    "Harvard Revised Catalogue": 7949,
    "Gliese Catalog": "Gl 806.1A",
    "Proper Name": "Gienah",
    "Distance in Parsecs": 22.2916,
    "mag": 2.48,
    "Absolute Visual Magnitude": 0.739,
    "Spectral Type": "K0III",
    "Color Index": 1.021,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.262597,
    "y": -13.834612,
    "z": 12.455654,
    "vx": 0.00000944,
    "vy": 0.00004737,
    "vz": 0.00002337,
    "rarad": 5.43761981873354,
    "decrad": 0.59289281587296,
    "pmrarad": 0.00000172671240466666,
    "pmdecrad": 0.000001601242624,
    "bayer": "Eps",
    "flam": 53,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 102157,
    "base": "Gl 806.1",
    "lum": 44.0960816450637,
    "bf": "53Eps Cyg",
    "ra": 20.770178,
    "dec": 33.970256,
    "pmra": 356.16,
    "pmdec": 330.28,
    "rv": -10.9,
    "Asset Name": "ASTRID 002432",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Glad",
    "Hair": "Antlers",
    "Outfit": "Elven",
    "Special": "Leaves of Flight",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "Gienah",
    "Constellation": "Cygnus",
    "x_1": 0.00000944,
    "y_1": 0.00004737,
    "z_1": 0.00002337,
    "Distance in Parsecs_1": 22.2916,
    "HYG Star ID_1": 102157,
    "Hipparcos Catalogue_1": 102488,
    "Henry Draper Catalogue_1": 197989,
    "Harvard Revised Catalogue_1": 7949,
    "Gliese Catalog_1": "Gl 806.1A"
  },
  {
    "Astrid #": "ASTRID 002433",
    "HYG Star ID": 76148,
    "Hipparcos Catalogue": 76382,
    "Henry Draper Catalogue": 139341,
    "Gliese Catalog": "Gl 593A",
    "Distance in Parsecs": 22.3065,
    "mag": 6.78,
    "Absolute Visual Magnitude": 5.038,
    "Spectral Type": "K2V",
    "Color Index": 0.906,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.07042,
    "y": -13.865457,
    "z": 14.278959,
    "vx": -0.00000984,
    "vy": 0.00007524,
    "vz": -0.00004204,
    "rarad": 4.08423822204506,
    "decrad": 0.694684209431335,
    "pmrarad": -0.00000233908056457638,
    "pmdecrad": 1.33420724e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 76148,
    "base": "Gl 593",
    "lum": 0.841007754069232,
    "ra": 15.600641,
    "dec": 39.802473,
    "pmra": -482.47,
    "pmdec": 27.52,
    "rv": -67.7,
    "Asset Name": "ASTRID 002433",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Smitten",
    "Hair": "Straw Hat",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000984,
    "y_1": 0.00007524,
    "z_1": -0.00004204,
    "Distance in Parsecs_1": 22.3065,
    "HYG Star ID_1": 76148,
    "Hipparcos Catalogue_1": 76382,
    "Henry Draper Catalogue_1": 139341,
    "Gliese Catalog_1": "Gl 593A"
  },
  {
    "Astrid #": "ASTRID 002434",
    "HYG Star ID": 101933,
    "Hipparcos Catalogue": 102264,
    "Henry Draper Catalogue": 197214,
    "Gliese Catalog": "NN 4157",
    "Distance in Parsecs": 22.3065,
    "mag": 6.95,
    "Absolute Visual Magnitude": 5.208,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.671,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.699588,
    "y": -14.703969,
    "z": -10.958366,
    "vx": -0.0000223,
    "vy": 0.00001862,
    "vz": -0.0000096,
    "rarad": 5.42477434698108,
    "decrad": -0.513544083519438,
    "pmrarad": -2.11087876513888e-7,
    "pmdecrad": -0.000001006230793,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 101933,
    "lum": 0.719117735782509,
    "ra": 20.721112,
    "dec": -29.423909,
    "pmra": -43.54,
    "pmdec": -207.55,
    "rv": -19.8,
    "Asset Name": "ASTRID 002434",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Neko",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": -0.0000223,
    "y_1": 0.00001862,
    "z_1": -0.0000096,
    "Distance in Parsecs_1": 22.3065,
    "HYG Star ID_1": 101933,
    "Hipparcos Catalogue_1": 102264,
    "Henry Draper Catalogue_1": 197214,
    "Gliese Catalog_1": "NN 4157"
  },
  {
    "Astrid #": "ASTRID 002435",
    "HYG Star ID": 29737,
    "Hipparcos Catalogue": 29810,
    "Henry Draper Catalogue": 254229,
    "Distance in Parsecs": 22.3164,
    "mag": 9.35,
    "Absolute Visual Magnitude": 7.607,
    "Spectral Type": "K2",
    "Color Index": 1.106,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.466502,
    "y": 20.138508,
    "z": 9.504164,
    "vx": -0.0000096,
    "vy": 0.00005946,
    "vz": -0.0000218,
    "rarad": 1.6434885735186,
    "decrad": 0.439928752577462,
    "pmrarad": 2.35716411486111e-7,
    "pmdecrad": -0.00000202884829,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 29737,
    "lum": 0.0789223484789972,
    "ra": 6.277664,
    "dec": 25.206061,
    "pmra": 48.62,
    "pmdec": -418.48,
    "rv": 44,
    "Asset Name": "ASTRID 002435",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Pompadour",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.0000096,
    "y_1": 0.00005946,
    "z_1": -0.0000218,
    "Distance in Parsecs_1": 22.3164,
    "HYG Star ID_1": 29737,
    "Hipparcos Catalogue_1": 29810,
    "Henry Draper Catalogue_1": 254229
  },
  {
    "Astrid #": "ASTRID 002436",
    "HYG Star ID": 118424,
    "Gliese Catalog": "NN 3392",
    "Distance in Parsecs": 22.3214,
    "mag": 16.37,
    "Absolute Visual Magnitude": 14.626,
    "Spectral Type": "DA9",
    "Color Index": 0.55,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.00944,
    "y": 22.075136,
    "z": 2.625915,
    "vx": -0.0000549,
    "vy": -0.00000462,
    "vz": -0.00000315,
    "rarad": 1.66157260144272,
    "decrad": 0.1179141576624,
    "pmrarad": 0.00000246842885454861,
    "pmdecrad": -1.42326752e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118424,
    "lum": 0.000122913617306016,
    "ra": 6.34674,
    "dec": 6.755984,
    "pmra": 509.15,
    "pmdec": -29.36,
    "rv": 0,
    "Asset Name": "ASTRID 002436",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Lion Mane",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000549,
    "y_1": -0.00000462,
    "z_1": -0.00000315,
    "Distance in Parsecs_1": 22.3214,
    "HYG Star ID_1": 118424,
    "Gliese Catalog_1": "NN 3392"
  },
  {
    "Astrid #": "ASTRID 002437",
    "HYG Star ID": 118539,
    "Gliese Catalog": "GJ 1110",
    "Distance in Parsecs": 22.3214,
    "mag": 13.08,
    "Absolute Visual Magnitude": 11.336,
    "Spectral Type": "g-k",
    "Color Index": 1.48,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.631375,
    "y": 16.730264,
    "z": 7.68868,
    "vx": -0.00007425,
    "vy": 0.0001433,
    "vz": -0.000012,
    "rarad": 2.21749464163092,
    "decrad": 0.3515370041719,
    "pmrarad": -0.00000121416738158333,
    "pmdecrad": -0.000002960456562,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118539,
    "lum": 0.00254448561514663,
    "ra": 8.470206,
    "dec": 20.141587,
    "pmra": -250.44,
    "pmdec": -610.64,
    "rv": 142,
    "Asset Name": "ASTRID 002437",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Angry",
    "Hair": "Good Boy",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00007425,
    "y_1": 0.0001433,
    "z_1": -0.000012,
    "Distance in Parsecs_1": 22.3214,
    "HYG Star ID_1": 118539,
    "Gliese Catalog_1": "GJ 1110"
  },
  {
    "Astrid #": "ASTRID 002438",
    "HYG Star ID": 118911,
    "Gliese Catalog": "NN 3798",
    "Distance in Parsecs": 22.3214,
    "mag": 15.83,
    "Absolute Visual Magnitude": 14.086,
    "Spectral Type": "m",
    "Color Index": 1.75,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.471577,
    "y": -8.257544,
    "z": 11.17148,
    "vx": -0.00006841,
    "vy": 0.00015757,
    "vz": 0.00000961,
    "rarad": 3.58316293091466,
    "decrad": 0.52415647839625,
    "pmrarad": -0.00000769244170663889,
    "pmdecrad": 4.97447925e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118911,
    "lum": 0.000202115676696075,
    "ra": 13.686674,
    "dec": 30.031954,
    "pmra": -1586.68,
    "pmdec": 102.61,
    "rv": 0,
    "Asset Name": "ASTRID 002438",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Pirate Hat",
    "Outfit": "River Spirit",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006841,
    "y_1": 0.00015757,
    "z_1": 0.00000961,
    "Distance in Parsecs_1": 22.3214,
    "HYG Star ID_1": 118911,
    "Gliese Catalog_1": "NN 3798"
  },
  {
    "Astrid #": "ASTRID 002439",
    "HYG Star ID": 31795,
    "Hipparcos Catalogue": 31878,
    "Distance in Parsecs": 22.3514,
    "mag": 9.71,
    "Absolute Visual Magnitude": 7.963,
    "Spectral Type": "M1V",
    "Color Index": 1.257,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.845656,
    "y": 10.51184,
    "z": -19.638734,
    "vx": 0.00000193,
    "vy": 0.00000767,
    "vz": 0.00000392,
    "rarad": 1.74460488839359,
    "decrad": -1.07299701535221,
    "pmrarad": -1.44183588597222e-7,
    "pmdecrad": 3.67731176e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 31795,
    "lum": 0.0568591024698854,
    "ra": 6.6639,
    "dec": -61.4782,
    "pmra": -29.74,
    "pmdec": 75.85,
    "rv": 0,
    "Asset Name": "ASTRID 002439",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Windy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00000193,
    "y_1": 0.00000767,
    "z_1": 0.00000392,
    "Distance in Parsecs_1": 22.3514,
    "HYG Star ID_1": 31795,
    "Hipparcos Catalogue_1": 31878
  },
  {
    "Astrid #": "ASTRID 002440",
    "HYG Star ID": 50776,
    "Hipparcos Catalogue": 50921,
    "Henry Draper Catalogue": 90156,
    "Gliese Catalog": "NN 3597",
    "Distance in Parsecs": 22.3514,
    "mag": 6.92,
    "Absolute Visual Magnitude": 5.173,
    "Spectral Type": "G5V",
    "Color Index": 0.659,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.743498,
    "y": 7.907221,
    "z": -11.055732,
    "vx": -0.0000032,
    "vy": 0.00000594,
    "vz": 0.00000939,
    "rarad": 2.72237025014608,
    "decrad": -0.517412088758232,
    "pmrarad": -1.84326161347222e-7,
    "pmdecrad": 4.83456202e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 50776,
    "lum": 0.742677043714697,
    "ra": 10.398688,
    "dec": -29.645529,
    "pmra": -38.02,
    "pmdec": 99.72,
    "rv": 0,
    "Asset Name": "ASTRID 002440",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Honest Lad",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.0000032,
    "y_1": 0.00000594,
    "z_1": 0.00000939,
    "Distance in Parsecs_1": 22.3514,
    "HYG Star ID_1": 50776,
    "Hipparcos Catalogue_1": 50921,
    "Henry Draper Catalogue_1": 90156,
    "Gliese Catalog_1": "NN 3597"
  },
  {
    "Astrid #": "ASTRID 002441",
    "HYG Star ID": 59568,
    "Hipparcos Catalogue": 59750,
    "Henry Draper Catalogue": 106516,
    "Harvard Revised Catalogue": 4657,
    "Distance in Parsecs": 22.3514,
    "mag": 6.11,
    "Absolute Visual Magnitude": 4.363,
    "Spectral Type": "F5V",
    "Color Index": 0.47,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.942219,
    "y": -1.455089,
    "z": -4.001252,
    "vx": 0.00001179,
    "vy": -0.00000269,
    "vz": -0.00010939,
    "rarad": 3.20781025220566,
    "decrad": -0.179985339916736,
    "pmrarad": 1.54994933673611e-7,
    "pmdecrad": -0.000004908447627,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 59568,
    "lum": 1.56602972068604,
    "ra": 12.252933,
    "dec": -10.3124,
    "pmra": 31.97,
    "pmdec": -1012.44,
    "rv": 8,
    "Asset Name": "ASTRID 002441",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Side Part",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00001179,
    "y_1": -0.00000269,
    "z_1": -0.00010939,
    "Distance in Parsecs_1": 22.3514,
    "HYG Star ID_1": 59568,
    "Hipparcos Catalogue_1": 59750,
    "Henry Draper Catalogue_1": 106516,
    "Harvard Revised Catalogue_1": 4657
  },
  {
    "Astrid #": "ASTRID 002442",
    "HYG Star ID": 118560,
    "Gliese Catalog": "Gl 319.1B",
    "Distance in Parsecs": 22.3564,
    "mag": 12.68,
    "Absolute Visual Magnitude": 10.933,
    "Spectral Type": "M1 :",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.63545,
    "y": 12.439548,
    "z": -15.230019,
    "vx": 0.00002772,
    "vy": 0.00001567,
    "vz": -0.00000656,
    "rarad": 2.2781947017154,
    "decrad": -0.749451804864,
    "pmrarad": -0.00000139810569198611,
    "pmdecrad": -4.0089728e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 42581,
    "base": "Gl 319.1",
    "lum": 0.00368807717226154,
    "ra": 8.702063,
    "dec": -42.940425,
    "pmra": -288.38,
    "pmdec": -82.69,
    "rv": 0,
    "Asset Name": "ASTRID 002442",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "Bob",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002772,
    "y_1": 0.00001567,
    "z_1": -0.00000656,
    "Distance in Parsecs_1": 22.3564,
    "HYG Star ID_1": 118560,
    "Gliese Catalog_1": "Gl 319.1B"
  },
  {
    "Astrid #": "ASTRID 002443",
    "HYG Star ID": 65984,
    "Hipparcos Catalogue": 66193,
    "Henry Draper Catalogue": 118096,
    "Distance in Parsecs": 22.3614,
    "mag": 9.19,
    "Absolute Visual Magnitude": 7.443,
    "Spectral Type": "K5IV",
    "Color Index": 1.08,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.151998,
    "y": -7.462704,
    "z": 12.253557,
    "vx": 0.00000568,
    "vy": 0.00003303,
    "vz": 0.00003012,
    "rarad": 3.55198265381741,
    "decrad": 0.579944857888027,
    "pmrarad": -0.00000125304943876388,
    "pmdecrad": 0.000001577244346,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 65984,
    "lum": 0.0917909786057336,
    "ra": 13.567574,
    "dec": 33.228393,
    "pmra": -258.46,
    "pmdec": 325.33,
    "rv": 1.1,
    "Asset Name": "ASTRID 002443",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Braided Bun",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00000568,
    "y_1": 0.00003303,
    "z_1": 0.00003012,
    "Distance in Parsecs_1": 22.3614,
    "HYG Star ID_1": 65984,
    "Hipparcos Catalogue_1": 66193,
    "Henry Draper Catalogue_1": 118096
  },
  {
    "Astrid #": "ASTRID 002444",
    "HYG Star ID": 6351,
    "Hipparcos Catalogue": 6365,
    "Gliese Catalog": "NN 3090",
    "Distance in Parsecs": 22.3664,
    "mag": 11.48,
    "Absolute Visual Magnitude": 9.732,
    "Spectral Type": "M3",
    "Color Index": 1.627,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.369757,
    "y": 5.355233,
    "z": -16.281506,
    "vx": -0.00000151,
    "vy": -0.00001398,
    "vz": -0.00000593,
    "rarad": 0.356732003534838,
    "decrad": -0.815319719787306,
    "pmrarad": -5.619960185e-7,
    "pmdecrad": -3.86832835e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 6351,
    "lum": 0.0111480780336384,
    "var_min": 11.554,
    "var_max": 11.364,
    "ra": 1.362616,
    "dec": -46.714379,
    "pmra": -115.92,
    "pmdec": -79.79,
    "rv": 0,
    "Asset Name": "ASTRID 002444",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Owie",
    "Hair": "Honest Lad",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000151,
    "y_1": -0.00001398,
    "z_1": -0.00000593,
    "Distance in Parsecs_1": 22.3664,
    "HYG Star ID_1": 6351,
    "Hipparcos Catalogue_1": 6365,
    "Gliese Catalog_1": "NN 3090"
  },
  {
    "Astrid #": "ASTRID 002445",
    "HYG Star ID": 63692,
    "Hipparcos Catalogue": 63894,
    "Henry Draper Catalogue": 113827,
    "Gliese Catalog": "Gl 498",
    "Distance in Parsecs": 22.3664,
    "mag": 9.26,
    "Absolute Visual Magnitude": 7.512,
    "Spectral Type": "K0",
    "Color Index": 1.168,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.941173,
    "y": -4.109069,
    "z": 17.000406,
    "vx": 0.00001524,
    "vy": -0.00000277,
    "vz": 0.00000739,
    "rarad": 3.42821852256191,
    "decrad": 0.863448215145658,
    "pmrarad": 3.11492789756944e-7,
    "pmdecrad": 6.84702361e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 63692,
    "lum": 0.0861390345772427,
    "var_min": 9.337,
    "var_max": 9.217,
    "ra": 13.09483,
    "dec": 49.471939,
    "pmra": 64.25,
    "pmdec": 141.23,
    "rv": -3.3,
    "Asset Name": "ASTRID 002445",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Lion Mane",
    "Outfit": "Wizard",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00001524,
    "y_1": -0.00000277,
    "z_1": 0.00000739,
    "Distance in Parsecs_1": 22.3664,
    "HYG Star ID_1": 63692,
    "Hipparcos Catalogue_1": 63894,
    "Henry Draper Catalogue_1": 113827,
    "Gliese Catalog_1": "Gl 498"
  },
  {
    "Astrid #": "ASTRID 002446",
    "HYG Star ID": 118175,
    "Gliese Catalog": "Gl 113C",
    "Distance in Parsecs": 22.3664,
    "mag": 16.5,
    "Absolute Visual Magnitude": 14.752,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.791325,
    "y": 13.335699,
    "z": 10.179965,
    "vx": -0.00001554,
    "vy": 0.00002524,
    "vz": -0.00001049,
    "rarad": 0.733684386351213,
    "decrad": 0.47253551913295,
    "pmrarad": 0.00000130356702427777,
    "pmdecrad": -5.26677341e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 13048,
    "base": "Gl 113",
    "lum": 0.000109446026781507,
    "ra": 2.802468,
    "dec": 27.074291,
    "pmra": 268.88,
    "pmdec": -108.64,
    "rv": 0,
    "Asset Name": "ASTRID 002446",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Fire",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001554,
    "y_1": 0.00002524,
    "z_1": -0.00001049,
    "Distance in Parsecs_1": 22.3664,
    "HYG Star ID_1": 118175,
    "Gliese Catalog_1": "Gl 113C"
  },
  {
    "Astrid #": "ASTRID 002447",
    "HYG Star ID": 118723,
    "Gliese Catalog": "NN 3635",
    "Distance in Parsecs": 22.3714,
    "mag": 15.36,
    "Absolute Visual Magnitude": 13.612,
    "Spectral Type": "m",
    "Color Index": 1.75,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.64656,
    "y": 5.074355,
    "z": 11.271037,
    "vx": -0.00000213,
    "vy": 0.00006089,
    "vz": -0.00003092,
    "rarad": 2.87591379791357,
    "decrad": 0.52800905383555,
    "pmrarad": -0.00000260146172849305,
    "pmdecrad": -0.000001600423289,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118723,
    "lum": 0.000312751931140798,
    "ra": 10.985182,
    "dec": 30.25269,
    "pmra": -536.59,
    "pmdec": -330.11,
    "rv": 0,
    "Asset Name": "ASTRID 002447",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Round Brush",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000213,
    "y_1": 0.00006089,
    "z_1": -0.00003092,
    "Distance in Parsecs_1": 22.3714,
    "HYG Star ID_1": 118723,
    "Gliese Catalog_1": "NN 3635"
  },
  {
    "Astrid #": "ASTRID 002448",
    "HYG Star ID": 76141,
    "Hipparcos Catalogue": 76375,
    "Henry Draper Catalogue": 139323,
    "Gliese Catalog": "Gl 591",
    "Distance in Parsecs": 22.3764,
    "mag": 7.65,
    "Absolute Visual Magnitude": 5.901,
    "Spectral Type": "K3V",
    "Color Index": 0.946,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.103496,
    "y": -13.898968,
    "z": 14.33231,
    "vx": -0.00000704,
    "vy": 0.00007313,
    "vz": -0.00003878,
    "rarad": 4.08382647870043,
    "decrad": 0.695184144361991,
    "pmrarad": -0.00000217637709338194,
    "pmdecrad": 2.43812799e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 76141,
    "lum": 0.379839390154506,
    "ra": 15.599068,
    "dec": 39.831117,
    "pmra": -448.91,
    "pmdec": 50.29,
    "rv": -65.6,
    "Asset Name": "ASTRID 002448",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Bob",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000704,
    "y_1": 0.00007313,
    "z_1": -0.00003878,
    "Distance in Parsecs_1": 22.3764,
    "HYG Star ID_1": 76141,
    "Hipparcos Catalogue_1": 76375,
    "Henry Draper Catalogue_1": 139323,
    "Gliese Catalog_1": "Gl 591"
  },
  {
    "Astrid #": "ASTRID 002449",
    "HYG Star ID": 118199,
    "Gliese Catalog": "Gl 128B",
    "Distance in Parsecs": 22.3764,
    "mag": 11.5,
    "Absolute Visual Magnitude": 9.751,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.913666,
    "y": 16.675376,
    "z": -0.466532,
    "vx": -0.00001546,
    "vy": 0.00001363,
    "vz": -0.00000677,
    "rarad": 0.841110102875584,
    "decrad": -0.02085076618565,
    "pmrarad": 9.210975116875e-7,
    "pmdecrad": -3.02843713e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 14917,
    "base": "Gl 128",
    "lum": 0.0109546876733399,
    "ra": 3.212804,
    "dec": -1.194661,
    "pmra": 189.99,
    "pmdec": -62.47,
    "rv": 0,
    "Asset Name": "ASTRID 002449",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Braid",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00001546,
    "y_1": 0.00001363,
    "z_1": -0.00000677,
    "Distance in Parsecs_1": 22.3764,
    "HYG Star ID_1": 118199,
    "Gliese Catalog_1": "Gl 128B"
  },
  {
    "Astrid #": "ASTRID 002450",
    "HYG Star ID": 39922,
    "Hipparcos Catalogue": 40035,
    "Henry Draper Catalogue": 68146,
    "Harvard Revised Catalogue": 3202,
    "Gliese Catalog": "Gl 297.2A",
    "Distance in Parsecs": 22.3814,
    "mag": 5.53,
    "Absolute Visual Magnitude": 3.781,
    "Spectral Type": "F7V",
    "Color Index": 0.488,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.73163,
    "y": 18.297808,
    "z": -5.338482,
    "vx": 0.00000439,
    "vy": 0.00004352,
    "vz": -0.00000197,
    "rarad": 2.14092487694486,
    "decrad": -0.240841600145362,
    "pmrarad": -0.000001214749158,
    "pmdecrad": 2.7964053e-7,
    "flam": 18,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 39922,
    "base": "Gl 297.2",
    "lum": 2.67670185562588,
    "bf": "18    Pup",
    "ra": 8.177731,
    "dec": -13.799207,
    "pmra": -250.56,
    "pmdec": 57.68,
    "rv": 33,
    "Asset Name": "ASTRID 002450",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Medium Bob",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000439,
    "y_1": 0.00004352,
    "z_1": -0.00000197,
    "Distance in Parsecs_1": 22.3814,
    "HYG Star ID_1": 39922,
    "Hipparcos Catalogue_1": 40035,
    "Henry Draper Catalogue_1": 68146,
    "Harvard Revised Catalogue_1": 3202,
    "Gliese Catalog_1": "Gl 297.2A"
  },
  {
    "Astrid #": "ASTRID 002451",
    "HYG Star ID": 119433,
    "Henry Draper Catalogue": 206827,
    "Harvard Revised Catalogue": 8310,
    "Gliese Catalog": "Gl 836.6B",
    "Distance in Parsecs": 22.4014,
    "mag": 6.09,
    "Absolute Visual Magnitude": 4.339,
    "Spectral Type": "G2 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.290512,
    "y": -10.974411,
    "z": 10.773027,
    "vx": 0.00004039,
    "vy": 0.00000247,
    "vz": -0.00000857,
    "rarad": 5.69034292147882,
    "decrad": 0.50166133958825,
    "pmrarad": 0.00000109916957655555,
    "pmdecrad": -0.000001024988235,
    "bayer": "Mu-2",
    "flam": 78,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 106967,
    "base": "Gl 836.6",
    "lum": 1.60103195466733,
    "bf": "78Mu 2Cyg",
    "ra": 21.735509,
    "dec": 28.743078,
    "pmra": 226.72,
    "pmdec": -211.42,
    "rv": 23.5,
    "Asset Name": "ASTRID 002451",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Audiohead",
    "Outfit": "Tats",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00004039,
    "y_1": 0.00000247,
    "z_1": -0.00000857,
    "Distance in Parsecs_1": 22.4014,
    "HYG Star ID_1": 119433,
    "Henry Draper Catalogue_1": 206827,
    "Harvard Revised Catalogue_1": 8310,
    "Gliese Catalog_1": "Gl 836.6B"
  },
  {
    "Astrid #": "ASTRID 002452",
    "HYG Star ID": 21231,
    "Hipparcos Catalogue": 21284,
    "Henry Draper Catalogue": 29220,
    "Gliese Catalog": "GJ 1071",
    "Distance in Parsecs": 22.4165,
    "mag": 8.81,
    "Absolute Visual Magnitude": 7.057,
    "Spectral Type": "K5V",
    "Color Index": 1.106,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.955662,
    "y": 15.123255,
    "z": -15.43751,
    "vx": -0.0000081,
    "vy": -0.00000301,
    "vz": -0.00000607,
    "rarad": 1.19563810803171,
    "decrad": -0.759649404553224,
    "pmrarad": 2.87203624361111e-7,
    "pmdecrad": -3.7354894e-7,
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 21231,
    "lum": 0.130978496239968,
    "ra": 4.567001,
    "dec": -43.524705,
    "pmra": 59.24,
    "pmdec": -77.05,
    "rv": 0,
    "Asset Name": "ASTRID 002452",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Little Crown",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": -0.0000081,
    "y_1": -0.00000301,
    "z_1": -0.00000607,
    "Distance in Parsecs_1": 22.4165,
    "HYG Star ID_1": 21231,
    "Hipparcos Catalogue_1": 21284,
    "Henry Draper Catalogue_1": 29220,
    "Gliese Catalog_1": "GJ 1071"
  },
  {
    "Astrid #": "ASTRID 002453",
    "HYG Star ID": 102539,
    "Hipparcos Catalogue": 102870,
    "Gliese Catalog": "NN 4167",
    "Distance in Parsecs": 22.4165,
    "mag": 9.69,
    "Absolute Visual Magnitude": 7.937,
    "Spectral Type": "K5",
    "Color Index": 1.16,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.159242,
    "y": -9.947414,
    "z": 17.878946,
    "vx": -0.00000286,
    "vy": 0.00004932,
    "vz": 0.00002891,
    "rarad": 5.45655128112265,
    "decrad": 0.923272211172105,
    "pmrarad": 0.00000139669973231249,
    "pmdecrad": 0.000002138076812,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 102539,
    "lum": 0.058237134794375,
    "var_min": 9.745,
    "var_max": 9.635,
    "ra": 20.842491,
    "dec": 52.899601,
    "pmra": 288.09,
    "pmdec": 441.01,
    "rv": 0,
    "Asset Name": "ASTRID 002453",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Owie",
    "Hair": "Sweetheart",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000286,
    "y_1": 0.00004932,
    "z_1": 0.00002891,
    "Distance in Parsecs_1": 22.4165,
    "HYG Star ID_1": 102539,
    "Hipparcos Catalogue_1": 102870,
    "Gliese Catalog_1": "NN 4167"
  },
  {
    "Astrid #": "ASTRID 002454",
    "HYG Star ID": 102924,
    "Hipparcos Catalogue": 103256,
    "Gliese Catalog": "GJ 1259",
    "Distance in Parsecs": 22.4165,
    "mag": 8.82,
    "Absolute Visual Magnitude": 7.067,
    "Spectral Type": "K3V",
    "Color Index": 1.054,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.1008,
    "y": -15.758753,
    "z": 5.10989,
    "vx": 0.00000921,
    "vy": 0.00007676,
    "vz": 0.00003005,
    "rarad": 5.47646882141625,
    "decrad": 0.229977706200053,
    "pmrarad": 0.00000266599042937499,
    "pmdecrad": 0.000001804137149,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 102924,
    "lum": 0.129777678168578,
    "ra": 20.91857,
    "dec": 13.176752,
    "pmra": 549.9,
    "pmdec": 372.13,
    "rv": -40,
    "Asset Name": "ASTRID 002454",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Afro",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00000921,
    "y_1": 0.00007676,
    "z_1": 0.00003005,
    "Distance in Parsecs_1": 22.4165,
    "HYG Star ID_1": 102924,
    "Hipparcos Catalogue_1": 103256,
    "Gliese Catalog_1": "GJ 1259"
  },
  {
    "Astrid #": "ASTRID 002455",
    "HYG Star ID": 94893,
    "Hipparcos Catalogue": 95187,
    "Gliese Catalog": "Gl 756",
    "Distance in Parsecs": 22.4215,
    "mag": 11.52,
    "Absolute Visual Magnitude": 9.767,
    "Spectral Type": "M1",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.878161,
    "y": -18.43148,
    "z": 10.755594,
    "vx": 0.00007382,
    "vy": 0.00007162,
    "vz": 0.00001029,
    "rarad": 5.06955321917884,
    "decrad": 0.500319473646728,
    "pmrarad": 0.00000420173472527083,
    "pmdecrad": 0.000001286016769,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 94893,
    "lum": 0.0107944370998445,
    "var_min": 11.639,
    "var_max": 11.329,
    "ra": 19.364267,
    "dec": 28.666194,
    "pmra": 866.67,
    "pmdec": 265.26,
    "rv": -30.6,
    "Asset Name": "ASTRID 002455",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Frizzy",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00007382,
    "y_1": 0.00007162,
    "z_1": 0.00001029,
    "Distance in Parsecs_1": 22.4215,
    "HYG Star ID_1": 94893,
    "Hipparcos Catalogue_1": 95187,
    "Gliese Catalog_1": "Gl 756"
  },
  {
    "Astrid #": "ASTRID 002456",
    "HYG Star ID": 119394,
    "Gliese Catalog": "Wo 9721B",
    "Distance in Parsecs": 22.4215,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.647,
    "Color Index": 1.63,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.399139,
    "y": -15.191955,
    "z": -1.731014,
    "vx": -0.00000357,
    "vy": -0.00000397,
    "vz": 0.00000103,
    "rarad": 5.53598137867227,
    "decrad": -0.07728021143875,
    "pmrarad": -2.37946554416666e-7,
    "pmdecrad": 4.6251225e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 104045,
    "base": "Wo 9721",
    "lum": 0.00191073298104287,
    "ra": 21.145891,
    "dec": -4.42783,
    "pmra": -49.08,
    "pmdec": 9.54,
    "rv": 0,
    "Asset Name": "ASTRID 002456",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Bangs",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000357,
    "y_1": -0.00000397,
    "z_1": 0.00000103,
    "Distance in Parsecs_1": 22.4215,
    "HYG Star ID_1": 119394,
    "Gliese Catalog_1": "Wo 9721B"
  },
  {
    "Astrid #": "ASTRID 002457",
    "HYG Star ID": 80572,
    "Hipparcos Catalogue": 80817,
    "Gliese Catalog": "GJ 2121",
    "Distance in Parsecs": 22.4316,
    "mag": 12.27,
    "Absolute Visual Magnitude": 10.516,
    "Color Index": 1.474,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -8.285506,
    "y": -20.056967,
    "z": -5.678484,
    "vx": -0.00004756,
    "vy": 0.00002576,
    "vz": -0.00002158,
    "rarad": 4.32064259093066,
    "decrad": -0.255931429556995,
    "pmrarad": -0.00000239817935223611,
    "pmdecrad": -9.94692228e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 80572,
    "lum": 0.00541501918953489,
    "var_min": 12.41,
    "var_max": 12.08,
    "ra": 16.503639,
    "dec": -14.663791,
    "pmra": -494.66,
    "pmdec": -205.17,
    "rv": 0,
    "Asset Name": "ASTRID 002457",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Ninja",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004756,
    "y_1": 0.00002576,
    "z_1": -0.00002158,
    "Distance in Parsecs_1": 22.4316,
    "HYG Star ID_1": 80572,
    "Hipparcos Catalogue_1": 80817,
    "Gliese Catalog_1": "GJ 2121"
  },
  {
    "Astrid #": "ASTRID 002458",
    "HYG Star ID": 24414,
    "Hipparcos Catalogue": 24472,
    "Gliese Catalog": "NN 3340",
    "Distance in Parsecs": 22.4517,
    "mag": 11.54,
    "Absolute Visual Magnitude": 9.784,
    "Spectral Type": "M:",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.331378,
    "y": 21.842054,
    "z": -2.871007,
    "vx": -0.00002921,
    "vy": -3.3e-7,
    "vz": -0.00004659,
    "rarad": 1.37503147807898,
    "decrad": -0.12822590683707,
    "pmrarad": 0.00000127326616924305,
    "pmdecrad": -0.000002092552808,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 24414,
    "lum": 0.0106267386540246,
    "var_min": 11.649,
    "var_max": 11.439,
    "ra": 5.252233,
    "dec": -7.346803,
    "pmra": 262.63,
    "pmdec": -431.62,
    "rv": 0,
    "Asset Name": "ASTRID 002458",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Undercut",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00002921,
    "y_1": -3.3e-7,
    "z_1": -0.00004659,
    "Distance in Parsecs_1": 22.4517,
    "HYG Star ID_1": 24414,
    "Hipparcos Catalogue_1": 24472,
    "Gliese Catalog_1": "NN 3340"
  },
  {
    "Astrid #": "ASTRID 002459",
    "HYG Star ID": 81272,
    "Hipparcos Catalogue": 81520,
    "Henry Draper Catalogue": 149612,
    "Distance in Parsecs": 22.4517,
    "mag": 7.01,
    "Absolute Visual Magnitude": 5.254,
    "Spectral Type": "G3V",
    "Color Index": 0.616,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.084865,
    "y": -11.082789,
    "z": -19.093472,
    "vx": -0.00001228,
    "vy": 0.00003813,
    "vz": -0.00000808,
    "rarad": 4.35926964081248,
    "decrad": -1.01679746582753,
    "pmrarad": -0.00000110047857349305,
    "pmdecrad": -0.000001384094576,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 81272,
    "lum": 0.689286860731844,
    "ra": 16.651183,
    "dec": -58.258203,
    "pmra": -226.99,
    "pmdec": -285.49,
    "rv": -9.5,
    "Asset Name": "ASTRID 002459",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Bandana",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -0.00001228,
    "y_1": 0.00003813,
    "z_1": -0.00000808,
    "Distance in Parsecs_1": 22.4517,
    "HYG Star ID_1": 81272,
    "Hipparcos Catalogue_1": 81520,
    "Henry Draper Catalogue_1": 149612
  },
  {
    "Astrid #": "ASTRID 002460",
    "HYG Star ID": 95819,
    "Hipparcos Catalogue": 96121,
    "Gliese Catalog": "Gl 761.2",
    "Distance in Parsecs": 22.4618,
    "mag": 10.42,
    "Absolute Visual Magnitude": 8.663,
    "Spectral Type": "M1",
    "Color Index": 1.406,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.832887,
    "y": -20.650473,
    "z": 0.226396,
    "vx": 0.00000273,
    "vy": 0.00005447,
    "vz": 0.00000204,
    "rarad": 5.11657215663383,
    "decrad": 0.0100794894443987,
    "pmrarad": 0.00000106532958165277,
    "pmdecrad": 1.12622217e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 95819,
    "lum": 0.0298400811593021,
    "ra": 19.543866,
    "dec": 0.577512,
    "pmra": 219.74,
    "pmdec": 23.23,
    "rv": -47.9,
    "Asset Name": "ASTRID 002460",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Fire",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000273,
    "y_1": 0.00005447,
    "z_1": 0.00000204,
    "Distance in Parsecs_1": 22.4618,
    "HYG Star ID_1": 95819,
    "Hipparcos Catalogue_1": 96121,
    "Gliese Catalog_1": "Gl 761.2"
  },
  {
    "Astrid #": "ASTRID 002461",
    "HYG Star ID": 114497,
    "Hipparcos Catalogue": 114859,
    "Gliese Catalog": "NN 4317",
    "Distance in Parsecs": 22.4618,
    "mag": 9.96,
    "Absolute Visual Magnitude": 8.203,
    "Spectral Type": "M",
    "Color Index": 1.05,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.30744,
    "y": -0.643556,
    "z": -22.207637,
    "vx": 0.00002283,
    "vy": 0.0000515,
    "vz": 0.00000191,
    "rarad": 6.09088684270651,
    "decrad": -1.42021909659788,
    "pmrarad": 0.00000244510931651388,
    "pmdecrad": 5.65874527e-7,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 114497,
    "lum": 0.0455826951540194,
    "var_min": 10.002,
    "var_max": 9.902,
    "ra": 23.265474,
    "dec": -81.37256,
    "pmra": 504.34,
    "pmdec": 116.72,
    "rv": 0,
    "Asset Name": "ASTRID 002461",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Shocked",
    "Hair": "Good Boy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": 0.00002283,
    "y_1": 0.0000515,
    "z_1": 0.00000191,
    "Distance in Parsecs_1": 22.4618,
    "HYG Star ID_1": 114497,
    "Hipparcos Catalogue_1": 114859,
    "Gliese Catalog_1": "NN 4317"
  },
  {
    "Astrid #": "ASTRID 002462",
    "HYG Star ID": 13738,
    "Hipparcos Catalogue": 13772,
    "Henry Draper Catalogue": 18455,
    "Gliese Catalog": "Gl 120.1A",
    "Distance in Parsecs": 22.4669,
    "mag": 7.33,
    "Absolute Visual Magnitude": 5.572,
    "Spectral Type": "K1/K2V",
    "Color Index": 0.863,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.573986,
    "y": 14.227768,
    "z": -9.484275,
    "vx": 0.00003006,
    "vy": 0.00003394,
    "vz": -0.00002549,
    "rarad": 0.773377898544362,
    "decrad": -0.43580010534217,
    "pmrarad": 1.46462212895833e-7,
    "pmdecrad": -1.78896248e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 13738,
    "base": "Gl 120.1",
    "lum": 0.514280431948791,
    "ra": 2.954086,
    "dec": -24.969507,
    "pmra": 30.21,
    "pmdec": -36.9,
    "rv": 50.6,
    "Asset Name": "ASTRID 002462",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Audiohead",
    "Outfit": "Bomber Jacket",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00003006,
    "y_1": 0.00003394,
    "z_1": -0.00002549,
    "Distance in Parsecs_1": 22.4669,
    "HYG Star ID_1": 13738,
    "Hipparcos Catalogue_1": 13772,
    "Henry Draper Catalogue_1": 18455,
    "Gliese Catalog_1": "Gl 120.1A"
  },
  {
    "Astrid #": "ASTRID 002463",
    "HYG Star ID": 77489,
    "Hipparcos Catalogue": 77725,
    "Gliese Catalog": "Gl 600",
    "Distance in Parsecs": 22.4669,
    "mag": 9.34,
    "Absolute Visual Magnitude": 7.582,
    "Spectral Type": "M2",
    "Color Index": 1.385,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.680641,
    "y": -18.717868,
    "z": 4.238558,
    "vx": -0.00002692,
    "vy": 0.00001185,
    "vz": -0.00002563,
    "rarad": 4.15448274281402,
    "decrad": 0.18979537248348,
    "pmrarad": -0.000001295906968125,
    "pmdecrad": -0.000001155601888,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 77489,
    "lum": 0.0807606861328975,
    "ra": 15.868955,
    "dec": 10.874474,
    "pmra": -267.3,
    "pmdec": -238.36,
    "rv": -0.7,
    "Asset Name": "ASTRID 002463",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Braided Bun",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00002692,
    "y_1": 0.00001185,
    "z_1": -0.00002563,
    "Distance in Parsecs_1": 22.4669,
    "HYG Star ID_1": 77489,
    "Hipparcos Catalogue_1": 77725,
    "Gliese Catalog_1": "Gl 600"
  },
  {
    "Astrid #": "ASTRID 002464",
    "HYG Star ID": 118044,
    "Gliese Catalog": "Gl 52.2",
    "Distance in Parsecs": 22.4719,
    "mag": 13.35,
    "Absolute Visual Magnitude": 11.592,
    "Spectral Type": "M3",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.776837,
    "y": 5.417602,
    "z": 12.63408,
    "vx": -0.00007288,
    "vy": 0.00013395,
    "vz": 0.00004521,
    "rarad": 0.295745100170568,
    "decrad": 0.59706396193525,
    "pmrarad": 0.00000664718341136111,
    "pmdecrad": 0.000002432518705,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118044,
    "lum": 0.00201001824711369,
    "ra": 1.129663,
    "dec": 34.209245,
    "pmra": 1371.08,
    "pmdec": 501.74,
    "rv": 0,
    "Asset Name": "ASTRID 002464",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Prince",
    "Outfit": "Spartan",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00007288,
    "y_1": 0.00013395,
    "z_1": 0.00004521,
    "Distance in Parsecs_1": 22.4719,
    "HYG Star ID_1": 118044,
    "Gliese Catalog_1": "Gl 52.2"
  },
  {
    "Astrid #": "ASTRID 002465",
    "HYG Star ID": 118249,
    "Gliese Catalog": "Wo 9124",
    "Distance in Parsecs": 22.4719,
    "mag": 10.66,
    "Absolute Visual Magnitude": 8.902,
    "Spectral Type": "F8",
    "Color Index": 0.66,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.387585,
    "y": 16.924976,
    "z": 9.42626,
    "vx": 6e-8,
    "vy": 0.00000262,
    "vz": -0.00000478,
    "rarad": 0.978540475744513,
    "decrad": 0.43286002371925,
    "pmrarad": 6.27348902638888e-8,
    "pmdecrad": -2.34145615e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118249,
    "lum": 0.0239441817286338,
    "ra": 3.737749,
    "dec": 24.801052,
    "pmra": 12.94,
    "pmdec": -48.3,
    "rv": 0,
    "Asset Name": "ASTRID 002465",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Serious Mad",
    "Hair": "Slick",
    "Outfit": "Warlock",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 6e-8,
    "y_1": 0.00000262,
    "z_1": -0.00000478,
    "Distance in Parsecs_1": 22.4719,
    "HYG Star ID_1": 118249,
    "Gliese Catalog_1": "Wo 9124"
  },
  {
    "Astrid #": "ASTRID 002466",
    "HYG Star ID": 119489,
    "Gliese Catalog": "GJ 1266",
    "Distance in Parsecs": 22.4719,
    "mag": 12.12,
    "Absolute Visual Magnitude": 10.362,
    "Spectral Type": "k",
    "Color Index": 1.47,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.600764,
    "y": -3.205907,
    "z": 21.240018,
    "vx": 0.0000481,
    "vy": 0.00008791,
    "vz": -0.00000166,
    "rarad": 5.83059395325298,
    "decrad": 1.23814918717364,
    "pmrarad": 0.00000445456505968055,
    "pmdecrad": -2.25656527e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119489,
    "lum": 0.00624022142138976,
    "ra": 22.271228,
    "dec": 70.940723,
    "pmra": 918.82,
    "pmdec": -46.55,
    "rv": 0,
    "Asset Name": "ASTRID 002466",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Little Crown",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000481,
    "y_1": 0.00008791,
    "z_1": -0.00000166,
    "Distance in Parsecs_1": 22.4719,
    "HYG Star ID_1": 119489,
    "Gliese Catalog_1": "GJ 1266"
  },
  {
    "Astrid #": "ASTRID 002467",
    "HYG Star ID": 118182,
    "Gliese Catalog": "Gl 120.1B",
    "Distance in Parsecs": 22.477,
    "mag": 8.2,
    "Absolute Visual Magnitude": 6.441,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.581228,
    "y": 14.23336,
    "z": -9.487611,
    "vx": -0.00000162,
    "vy": 8.7e-7,
    "vz": -0.00000117,
    "rarad": 0.773325681750128,
    "decrad": -0.43576399988435,
    "pmrarad": 7.80550025694444e-8,
    "pmdecrad": -5.7537687e-8,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 2,
    "comp_primary": 13738,
    "base": "Gl 120.1",
    "lum": 0.230993628017318,
    "ra": 2.953887,
    "dec": -24.967438,
    "pmra": 16.1,
    "pmdec": -11.87,
    "rv": 0,
    "Asset Name": "ASTRID 002467",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Anxious Laugh",
    "Hair": "Medium Bob",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000162,
    "y_1": 8.7e-7,
    "z_1": -0.00000117,
    "Distance in Parsecs_1": 22.477,
    "HYG Star ID_1": 118182,
    "Gliese Catalog_1": "Gl 120.1B"
  },
  {
    "Astrid #": "ASTRID 002468",
    "HYG Star ID": 23382,
    "Hipparcos Catalogue": 23437,
    "Henry Draper Catalogue": 32778,
    "Gliese Catalog": "Gl 186.1A",
    "Distance in Parsecs": 22.482,
    "mag": 7.02,
    "Absolute Visual Magnitude": 5.261,
    "Spectral Type": "G5V",
    "Color Index": 0.636,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.126091,
    "y": 12.14986,
    "z": -18.656097,
    "vx": 0.0000217,
    "vy": 0.00006447,
    "vz": 0.00004204,
    "rarad": 1.31896572995659,
    "decrad": -0.978790005168002,
    "pmrarad": -2.203478178125e-7,
    "pmdecrad": 0.000003547866514,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 23382,
    "base": "Gl 186.1",
    "lum": 0.684857159125855,
    "var_min": 7.397,
    "var_max": 6.997,
    "ra": 5.038078,
    "dec": -56.080536,
    "pmra": -45.45,
    "pmdec": 731.8,
    "rv": 2.9,
    "Asset Name": "ASTRID 002468",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Bucket Hat",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 0.0000217,
    "y_1": 0.00006447,
    "z_1": 0.00004204,
    "Distance in Parsecs_1": 22.482,
    "HYG Star ID_1": 23382,
    "Hipparcos Catalogue_1": 23437,
    "Henry Draper Catalogue_1": 32778,
    "Gliese Catalog_1": "Gl 186.1A"
  },
  {
    "Astrid #": "ASTRID 002469",
    "HYG Star ID": 7540,
    "Hipparcos Catalogue": 7554,
    "Gliese Catalog": "NN 3102",
    "Distance in Parsecs": 22.4871,
    "mag": 10.39,
    "Absolute Visual Magnitude": 8.63,
    "Spectral Type": "M:",
    "Color Index": 1.416,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.389027,
    "y": 6.05572,
    "z": -17.021512,
    "vx": -0.0000138,
    "vy": 0.00005328,
    "vz": 0.0000081,
    "rarad": 0.424745317393955,
    "decrad": -0.858626327588841,
    "pmrarad": 0.0000024120450235,
    "pmdecrad": 5.51475561e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 7540,
    "lum": 0.030760968147407,
    "var_min": 10.437,
    "var_max": 10.317,
    "ra": 1.622408,
    "dec": -49.195665,
    "pmra": 497.52,
    "pmdec": 113.75,
    "rv": 0,
    "Asset Name": "ASTRID 002469",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Bob",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.0000138,
    "y_1": 0.00005328,
    "z_1": 0.0000081,
    "Distance in Parsecs_1": 22.4871,
    "HYG Star ID_1": 7540,
    "Hipparcos Catalogue_1": 7554,
    "Gliese Catalog_1": "NN 3102"
  },
  {
    "Astrid #": "ASTRID 002470",
    "HYG Star ID": 118529,
    "Gliese Catalog": "Gl 297.2B",
    "Distance in Parsecs": 22.4871,
    "mag": 11.82,
    "Absolute Visual Magnitude": 10.06,
    "Spectral Type": "M3",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.779465,
    "y": 18.389328,
    "z": -5.36895,
    "vx": 0.00000242,
    "vy": 0.00004367,
    "vz": -0.00000312,
    "rarad": 2.14050828099424,
    "decrad": -0.2410662833835,
    "pmrarad": -0.00000113810011510416,
    "pmdecrad": 2.4191233e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 39922,
    "base": "Gl 297.2",
    "lum": 0.00824138115013001,
    "ra": 8.176139,
    "dec": -13.812081,
    "pmra": -234.75,
    "pmdec": 49.9,
    "rv": 34.4,
    "Asset Name": "ASTRID 002470",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Mohawk",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000242,
    "y_1": 0.00004367,
    "z_1": -0.00000312,
    "Distance in Parsecs_1": 22.4871,
    "HYG Star ID_1": 118529,
    "Gliese Catalog_1": "Gl 297.2B"
  },
  {
    "Astrid #": "ASTRID 002471",
    "HYG Star ID": 111630,
    "Hipparcos Catalogue": 111983,
    "Henry Draper Catalogue": 214731,
    "Distance in Parsecs": 22.4921,
    "mag": 9.14,
    "Absolute Visual Magnitude": 7.38,
    "Spectral Type": "K5V",
    "Color Index": 1.044,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.650966,
    "y": -5.261799,
    "z": -16.234445,
    "vx": 0.00000227,
    "vy": 0.00001102,
    "vz": -0.00000152,
    "rarad": 5.93838629090106,
    "decrad": -0.806376961787114,
    "pmrarad": 4.95188693319444e-7,
    "pmdecrad": -9.7689956e-8,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 111630,
    "lum": 0.0972747223776964,
    "ra": 22.682965,
    "dec": -46.201997,
    "pmra": 102.14,
    "pmdec": -20.15,
    "rv": 0,
    "Asset Name": "ASTRID 002471",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Medium Bob",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00000227,
    "y_1": 0.00001102,
    "z_1": -0.00000152,
    "Distance in Parsecs_1": 22.4921,
    "HYG Star ID_1": 111630,
    "Hipparcos Catalogue_1": 111983,
    "Henry Draper Catalogue_1": 214731
  },
  {
    "Astrid #": "ASTRID 002472",
    "HYG Star ID": 117414,
    "Hipparcos Catalogue": 117779,
    "Gliese Catalog": "Gl 910",
    "Distance in Parsecs": 22.5175,
    "mag": 9.75,
    "Absolute Visual Magnitude": 7.987,
    "Color Index": 1.357,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.682003,
    "y": -0.589024,
    "z": 10.922918,
    "vx": -2.8e-7,
    "vy": -0.00000775,
    "vz": 0.00000283,
    "rarad": 6.25326763070131,
    "decrad": 0.506460929445405,
    "pmrarad": -3.44605564138888e-7,
    "pmdecrad": 1.11022332e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 117414,
    "lum": 0.0556160319597592,
    "var_min": 9.792,
    "var_max": 9.602,
    "ra": 23.885723,
    "dec": 29.018074,
    "pmra": -71.08,
    "pmdec": 22.9,
    "rv": 1.3,
    "Asset Name": "ASTRID 002472",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Honest Lad",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -2.8e-7,
    "y_1": -0.00000775,
    "z_1": 0.00000283,
    "Distance in Parsecs_1": 22.5175,
    "HYG Star ID_1": 117414,
    "Hipparcos Catalogue_1": 117779,
    "Gliese Catalog_1": "Gl 910"
  },
  {
    "Astrid #": "ASTRID 002473",
    "HYG Star ID": 119337,
    "Gliese Catalog": "Gl 784.2A",
    "Distance in Parsecs": 22.5225,
    "mag": 13.19,
    "Absolute Visual Magnitude": 11.427,
    "Spectral Type": "dM5",
    "Color Index": 1.54,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.344316,
    "y": -18.654952,
    "z": 2.621759,
    "vx": -0.00001815,
    "vy": -0.00002087,
    "vz": -0.00006298,
    "rarad": 5.29695809721808,
    "decrad": 0.116670696652199,
    "pmrarad": -0.00000118357563834027,
    "pmdecrad": -0.000002815666956,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119337,
    "base": "Gl 784.2",
    "lum": 0.00233991456105509,
    "ra": 20.23289,
    "dec": 6.684739,
    "pmra": -244.13,
    "pmdec": -580.77,
    "rv": 0,
    "Asset Name": "ASTRID 002473",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Battle Ready",
    "Hair": "Horns",
    "Outfit": "Warlock",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001815,
    "y_1": -0.00002087,
    "z_1": -0.00006298,
    "Distance in Parsecs_1": 22.5225,
    "HYG Star ID_1": 119337,
    "Gliese Catalog_1": "Gl 784.2A"
  },
  {
    "Astrid #": "ASTRID 002474",
    "HYG Star ID": 119338,
    "Gliese Catalog": "Gl 784.2B",
    "Distance in Parsecs": 22.5225,
    "mag": 15.72,
    "Absolute Visual Magnitude": 13.957,
    "Spectral Type": "DA7",
    "Color Index": 0.37,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.33958,
    "y": -18.656715,
    "z": 2.6315,
    "vx": -0.00001814,
    "vy": -0.00002089,
    "vz": -0.00006297,
    "rarad": 5.29673805921808,
    "decrad": 0.1171061386522,
    "pmrarad": -0.00000118357563834027,
    "pmdecrad": -0.000002815666956,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119337,
    "base": "Gl 784.2",
    "lum": 0.000227614539314164,
    "ra": 20.232049,
    "dec": 6.709687,
    "pmra": -244.13,
    "pmdec": -580.77,
    "rv": 0,
    "Asset Name": "ASTRID 002474",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Nervous",
    "Hair": "Elegant",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001814,
    "y_1": -0.00002089,
    "z_1": -0.00006297,
    "Distance in Parsecs_1": 22.5225,
    "HYG Star ID_1": 119338,
    "Gliese Catalog_1": "Gl 784.2B"
  },
  {
    "Astrid #": "ASTRID 002475",
    "HYG Star ID": 119477,
    "Gliese Catalog": "NN 4260",
    "Distance in Parsecs": 22.5225,
    "mag": 12.57,
    "Absolute Visual Magnitude": 10.807,
    "Spectral Type": "k-m",
    "Color Index": 1.49,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.059478,
    "y": -7.864256,
    "z": 14.786095,
    "vx": 0.00000497,
    "vy": 0.00004507,
    "vz": 0.00001892,
    "rarad": 5.8018950534787,
    "decrad": 0.7161737595171,
    "pmrarad": 0.00000187588957417361,
    "pmdecrad": 0.000001113830342,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119477,
    "lum": 0.004141903726221,
    "ra": 22.161607,
    "dec": 41.033734,
    "pmra": 386.93,
    "pmdec": 229.74,
    "rv": 0,
    "Asset Name": "ASTRID 002475",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Braid",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000497,
    "y_1": 0.00004507,
    "z_1": 0.00001892,
    "Distance in Parsecs_1": 22.5225,
    "HYG Star ID_1": 119477,
    "Gliese Catalog_1": "NN 4260"
  },
  {
    "Astrid #": "ASTRID 002476",
    "HYG Star ID": 56032,
    "Hipparcos Catalogue": 56199,
    "Gliese Catalog": "Gl 430",
    "Distance in Parsecs": 22.5276,
    "mag": 9.98,
    "Absolute Visual Magnitude": 8.216,
    "Spectral Type": "M0",
    "Color Index": 1.315,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.091957,
    "y": 1.274096,
    "z": 20.100234,
    "vx": 0.00000427,
    "vy": -0.00000786,
    "vz": -0.00000423,
    "rarad": 3.01600492285802,
    "decrad": 1.10230689374152,
    "pmrarad": 3.22498060305555e-7,
    "pmdecrad": 1.22027603e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 56032,
    "lum": 0.0450401678168087,
    "ra": 11.52029,
    "dec": 63.157533,
    "pmra": 66.52,
    "pmdec": 25.17,
    "rv": -6,
    "Asset Name": "ASTRID 002476",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Fedora",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000427,
    "y_1": -0.00000786,
    "z_1": -0.00000423,
    "Distance in Parsecs_1": 22.5276,
    "HYG Star ID_1": 56032,
    "Hipparcos Catalogue_1": 56199,
    "Gliese Catalog_1": "Gl 430"
  },
  {
    "Astrid #": "ASTRID 002477",
    "HYG Star ID": 83885,
    "Hipparcos Catalogue": 84143,
    "Henry Draper Catalogue": 155203,
    "Harvard Revised Catalogue": 6380,
    "Gliese Catalog": "Gl 657",
    "Distance in Parsecs": 22.5276,
    "mag": 3.32,
    "Absolute Visual Magnitude": 1.556,
    "Spectral Type": "F3p",
    "Color Index": 0.441,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.401345,
    "y": -16.05484,
    "z": -15.432267,
    "vx": 0.00001098,
    "vy": 0.00004022,
    "vz": -0.00000395,
    "rarad": 4.50361768769054,
    "decrad": -0.754666212276241,
    "pmrarad": 1.06707491090277e-7,
    "pmdecrad": -0.000001393402999,
    "bayer": "Eta",
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 83885,
    "lum": 20.7778209750426,
    "bf": "Eta Sco",
    "ra": 17.202552,
    "dec": -43.239189,
    "pmra": 22.01,
    "pmdec": -287.41,
    "rv": -27,
    "Asset Name": "ASTRID 002477",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Undercut",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00001098,
    "y_1": 0.00004022,
    "z_1": -0.00000395,
    "Distance in Parsecs_1": 22.5276,
    "HYG Star ID_1": 83885,
    "Hipparcos Catalogue_1": 84143,
    "Henry Draper Catalogue_1": 155203,
    "Harvard Revised Catalogue_1": 6380,
    "Gliese Catalog_1": "Gl 657"
  },
  {
    "Astrid #": "ASTRID 002478",
    "HYG Star ID": 8848,
    "Hipparcos Catalogue": 8867,
    "Henry Draper Catalogue": 11373,
    "Gliese Catalog": "NN 3122",
    "Distance in Parsecs": 22.5327,
    "mag": 8.47,
    "Absolute Visual Magnitude": 6.706,
    "Spectral Type": "G5",
    "Color Index": 1.01,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.996647,
    "y": 4.346431,
    "z": 20.612732,
    "vx": 0.00001457,
    "vy": 0.00000233,
    "vz": -0.00000614,
    "rarad": 0.497866392015616,
    "decrad": 1.15499249654516,
    "pmrarad": -2.17826786673611e-7,
    "pmdecrad": -6.74909124e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 8848,
    "lum": 0.180967255478879,
    "ra": 1.90171,
    "dec": 66.176195,
    "pmra": -44.93,
    "pmdec": -139.21,
    "rv": 0,
    "Asset Name": "ASTRID 002478",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Ninja",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001457,
    "y_1": 0.00000233,
    "z_1": -0.00000614,
    "Distance in Parsecs_1": 22.5327,
    "HYG Star ID_1": 8848,
    "Hipparcos Catalogue_1": 8867,
    "Henry Draper Catalogue_1": 11373,
    "Gliese Catalog_1": "NN 3122"
  },
  {
    "Astrid #": "ASTRID 002479",
    "HYG Star ID": 118109,
    "Gliese Catalog": "Gl 84.1B",
    "Distance in Parsecs": 22.5378,
    "mag": 12.8,
    "Absolute Visual Magnitude": 11.035,
    "Spectral Type": "M3.5",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.985587,
    "y": 10.348655,
    "z": -10.599416,
    "vx": -0.00000171,
    "vy": 0.00004355,
    "vz": 0.00003977,
    "rarad": 0.547189963904178,
    "decrad": -0.48962507554115,
    "pmrarad": 0.00000168981808357638,
    "pmdecrad": 0.000001999609177,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 9727,
    "base": "Gl  84.1",
    "lum": 0.00335737614242954,
    "ra": 2.090112,
    "dec": -28.05345,
    "pmra": 348.55,
    "pmdec": 412.45,
    "rv": 0,
    "Asset Name": "ASTRID 002479",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Oof",
    "Hair": "Windy",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000171,
    "y_1": 0.00004355,
    "z_1": 0.00003977,
    "Distance in Parsecs_1": 22.5378,
    "HYG Star ID_1": 118109,
    "Gliese Catalog_1": "Gl 84.1B"
  },
  {
    "Astrid #": "ASTRID 002480",
    "HYG Star ID": 44771,
    "Hipparcos Catalogue": 44899,
    "Distance in Parsecs": 22.553,
    "mag": 10.31,
    "Absolute Visual Magnitude": 8.544,
    "Spectral Type": "K7V",
    "Color Index": 1.316,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.584933,
    "y": 12.57225,
    "z": -12.885104,
    "vx": -0.00000657,
    "vy": -0.00002981,
    "vz": -0.00002216,
    "rarad": 2.39488822526636,
    "decrad": -0.608119983490392,
    "pmrarad": 0.00000116791615645833,
    "pmdecrad": -0.000001197198902,
    "con": "Pyx",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pyxis",
    "FULL CONSTELLATION NAME": "Pyxis",
    "comp": 1,
    "comp_primary": 44771,
    "lum": 0.033296608520688,
    "var_min": 10.373,
    "var_max": 10.243,
    "ra": 9.147799,
    "dec": -34.842708,
    "pmra": 240.9,
    "pmdec": -246.94,
    "rv": 0,
    "Asset Name": "ASTRID 002480",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Maniacal",
    "Hair": "Middle Part",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pyxis",
    "x_1": -0.00000657,
    "y_1": -0.00002981,
    "z_1": -0.00002216,
    "Distance in Parsecs_1": 22.553,
    "HYG Star ID_1": 44771,
    "Hipparcos Catalogue_1": 44899
  },
  {
    "Astrid #": "ASTRID 002481",
    "HYG Star ID": 49403,
    "Hipparcos Catalogue": 49544,
    "Gliese Catalog": "Gl 378.2",
    "Distance in Parsecs": 22.5581,
    "mag": 9.95,
    "Absolute Visual Magnitude": 8.183,
    "Spectral Type": "M0",
    "Color Index": 1.347,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.842076,
    "y": 10.667368,
    "z": 1.1666,
    "vx": 0.00001672,
    "vy": -9.4e-7,
    "vz": -0.00001168,
    "rarad": 2.64830896740958,
    "decrad": 0.0517387456666862,
    "pmrarad": -3.14353190472222e-7,
    "pmdecrad": -4.82438093e-7,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 49403,
    "lum": 0.0464301407281803,
    "ra": 10.115795,
    "dec": 2.964412,
    "pmra": -64.84,
    "pmdec": -99.51,
    "rv": -15.4,
    "Asset Name": "ASTRID 002481",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Side Part",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": 0.00001672,
    "y_1": -9.4e-7,
    "z_1": -0.00001168,
    "Distance in Parsecs_1": 22.5581,
    "HYG Star ID_1": 49403,
    "Hipparcos Catalogue_1": 49544,
    "Gliese Catalog_1": "Gl 378.2"
  },
  {
    "Astrid #": "ASTRID 002482",
    "HYG Star ID": 8470,
    "Hipparcos Catalogue": 8486,
    "Henry Draper Catalogue": 11131,
    "Gliese Catalog": "Wo 9061B",
    "Distance in Parsecs": 22.5632,
    "mag": 6.72,
    "Absolute Visual Magnitude": 4.953,
    "Spectral Type": "G0",
    "Color Index": 0.654,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.692779,
    "y": 10.184806,
    "z": -4.190606,
    "vx": 0.00000212,
    "vy": -0.00001401,
    "vz": -0.00001031,
    "rarad": 0.477300874464393,
    "decrad": -0.186812362239755,
    "pmrarad": -5.94575497833333e-7,
    "pmdecrad": -4.86655972e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 8481,
    "base": "Wo 9061",
    "lum": 0.909494338555294,
    "var": "EZ",
    "var_min": 6.739,
    "var_max": 6.699,
    "ra": 1.823155,
    "dec": -10.70356,
    "pmra": -122.64,
    "pmdec": -100.38,
    "rv": -2.5,
    "Asset Name": "ASTRID 002482",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Battle Ready",
    "Hair": "Middle Part",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000212,
    "y_1": -0.00001401,
    "z_1": -0.00001031,
    "Distance in Parsecs_1": 22.5632,
    "HYG Star ID_1": 8470,
    "Hipparcos Catalogue_1": 8486,
    "Henry Draper Catalogue_1": 11131,
    "Gliese Catalog_1": "Wo 9061B"
  },
  {
    "Astrid #": "ASTRID 002483",
    "HYG Star ID": 17454,
    "Hipparcos Catalogue": 17496,
    "Gliese Catalog": "GJ 1063",
    "Distance in Parsecs": 22.5632,
    "mag": 9.13,
    "Absolute Visual Magnitude": 7.363,
    "Spectral Type": "K8V:",
    "Color Index": 1.179,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.277382,
    "y": 18.348756,
    "z": 4.660484,
    "vx": 0.0000153,
    "vy": 0.00008494,
    "vz": 0.0000308,
    "rarad": 0.981102090402424,
    "decrad": 0.208043252858216,
    "pmrarad": 0.0000015302174199375,
    "pmdecrad": 6.11640939e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 17454,
    "lum": 0.0988097953941127,
    "ra": 3.747534,
    "dec": 11.92,
    "pmra": 315.63,
    "pmdec": 126.16,
    "rv": 81.9,
    "Asset Name": "ASTRID 002483",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Unkempt",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.0000153,
    "y_1": 0.00008494,
    "z_1": 0.0000308,
    "Distance in Parsecs_1": 22.5632,
    "HYG Star ID_1": 17454,
    "Hipparcos Catalogue_1": 17496,
    "Gliese Catalog_1": "GJ 1063"
  },
  {
    "Astrid #": "ASTRID 002484",
    "HYG Star ID": 118443,
    "Gliese Catalog": "GJ 1091",
    "Distance in Parsecs": 22.5734,
    "mag": 13.34,
    "Absolute Visual Magnitude": 11.572,
    "Color Index": 1.35,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -3.804758,
    "y": 17.586639,
    "z": 13.630565,
    "vx": 0.00001462,
    "vy": 0.00000432,
    "vz": -0.00000149,
    "rarad": 1.78386498614547,
    "decrad": 0.6483011322969,
    "pmrarad": -6.736970905e-7,
    "pmdecrad": -8.2714062e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118443,
    "lum": 0.00204738727634163,
    "ra": 6.813862,
    "dec": 37.144919,
    "pmra": -138.96,
    "pmdec": -17.06,
    "rv": 0,
    "Asset Name": "ASTRID 002484",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "High Fade",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001462,
    "y_1": 0.00000432,
    "z_1": -0.00000149,
    "Distance in Parsecs_1": 22.5734,
    "HYG Star ID_1": 118443,
    "Gliese Catalog_1": "GJ 1091"
  },
  {
    "Astrid #": "ASTRID 002485",
    "HYG Star ID": 119083,
    "Gliese Catalog": "GJ 1201",
    "Distance in Parsecs": 22.5734,
    "mag": 16.13,
    "Absolute Visual Magnitude": 14.362,
    "Spectral Type": "DA s",
    "Color Index": 0.38,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -8.716579,
    "y": -20.507158,
    "z": 3.610559,
    "vx": -0.00001232,
    "vy": -0.00000334,
    "vz": -0.00004869,
    "rarad": 4.31047578586867,
    "decrad": 0.16063744348065,
    "pmrarad": -4.446226264375e-7,
    "pmdecrad": -0.000002185370387,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119083,
    "lum": 0.000156747275180043,
    "ra": 16.464805,
    "dec": 9.203848,
    "pmra": -91.71,
    "pmdec": -450.76,
    "rv": 0,
    "Asset Name": "ASTRID 002485",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Mohawk",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001232,
    "y_1": -0.00000334,
    "z_1": -0.00004869,
    "Distance in Parsecs_1": 22.5734,
    "HYG Star ID_1": 119083,
    "Gliese Catalog_1": "GJ 1201"
  },
  {
    "Astrid #": "ASTRID 002486",
    "HYG Star ID": 5400,
    "Hipparcos Catalogue": 5410,
    "Gliese Catalog": "GJ 1032",
    "Distance in Parsecs": 22.5785,
    "mag": 12.38,
    "Absolute Visual Magnitude": 10.612,
    "Spectral Type": "M2V:",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.586256,
    "y": 6.101222,
    "z": -9.430926,
    "vx": -0.00000872,
    "vy": 0.00003152,
    "vz": 0.00000228,
    "rarad": 0.30197708851471,
    "decrad": -0.430906900554595,
    "pmrarad": 0.00000144794453834722,
    "pmdecrad": 1.11022332e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 5400,
    "lum": 0.00495678406594117,
    "var_min": 12.474,
    "var_max": 12.264,
    "ra": 1.153468,
    "dec": -24.689147,
    "pmra": 298.66,
    "pmdec": 22.9,
    "rv": 0,
    "Asset Name": "ASTRID 002486",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Headband",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000872,
    "y_1": 0.00003152,
    "z_1": 0.00000228,
    "Distance in Parsecs_1": 22.5785,
    "HYG Star ID_1": 5400,
    "Hipparcos Catalogue_1": 5410,
    "Gliese Catalog_1": "GJ 1032"
  },
  {
    "Astrid #": "ASTRID 002487",
    "HYG Star ID": 14917,
    "Hipparcos Catalogue": 14954,
    "Henry Draper Catalogue": 19994,
    "Harvard Revised Catalogue": 962,
    "Gliese Catalog": "Gl 128A",
    "Distance in Parsecs": 22.5785,
    "mag": 5.07,
    "Absolute Visual Magnitude": 3.302,
    "Spectral Type": "F8V",
    "Color Index": 0.575,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.048052,
    "y": 16.82648,
    "z": -0.471316,
    "vx": -0.0000028,
    "vy": 0.00002863,
    "vz": -0.00000799,
    "rarad": 0.841134993532145,
    "decrad": -0.0208759038194712,
    "pmrarad": 9.37775102298611e-7,
    "pmdecrad": -3.35636511e-7,
    "flam": 94,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 14917,
    "base": "Gl 128",
    "lum": 4.16102188517454,
    "bf": "94    Cet",
    "ra": 3.212899,
    "dec": -1.196101,
    "pmra": 193.43,
    "pmdec": -69.23,
    "rv": 19.2,
    "Asset Name": "ASTRID 002487",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Side Part",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.0000028,
    "y_1": 0.00002863,
    "z_1": -0.00000799,
    "Distance in Parsecs_1": 22.5785,
    "HYG Star ID_1": 14917,
    "Hipparcos Catalogue_1": 14954,
    "Henry Draper Catalogue_1": 19994,
    "Harvard Revised Catalogue_1": 962,
    "Gliese Catalog_1": "Gl 128A"
  },
  {
    "Astrid #": "ASTRID 002488",
    "HYG Star ID": 54267,
    "Hipparcos Catalogue": 54426,
    "Henry Draper Catalogue": 96612,
    "Distance in Parsecs": 22.5785,
    "mag": 8.35,
    "Absolute Visual Magnitude": 6.582,
    "Spectral Type": "K0",
    "Color Index": 0.942,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.238804,
    "y": 3.96139,
    "z": 14.032809,
    "vx": 0.00000792,
    "vy": 0.00002213,
    "vz": 0.00000349,
    "rarad": 2.91572151298288,
    "decrad": 0.670671238421658,
    "pmrarad": -0.00000103405909925694,
    "pmdecrad": 1.97076761e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 54267,
    "lum": 0.202861671696629,
    "ra": 11.137236,
    "dec": 38.426631,
    "pmra": -213.29,
    "pmdec": 40.65,
    "rv": 0,
    "Asset Name": "ASTRID 002488",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "Bangs",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000792,
    "y_1": 0.00002213,
    "z_1": 0.00000349,
    "Distance in Parsecs_1": 22.5785,
    "HYG Star ID_1": 54267,
    "Hipparcos Catalogue_1": 54426,
    "Henry Draper Catalogue_1": 96612
  },
  {
    "Astrid #": "ASTRID 002489",
    "HYG Star ID": 12497,
    "Hipparcos Catalogue": 12530,
    "Henry Draper Catalogue": 16765,
    "Harvard Revised Catalogue": 790,
    "Distance in Parsecs": 22.5887,
    "mag": 5.72,
    "Absolute Visual Magnitude": 3.951,
    "Spectral Type": "F7IV",
    "Color Index": 0.511,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.224348,
    "y": 14.611613,
    "z": -0.274253,
    "vx": -0.0000094,
    "vy": 0.00002347,
    "vz": -0.00001382,
    "rarad": 0.703513024696881,
    "decrad": -0.0121414353759837,
    "pmrarad": 0.00000106145107220833,
    "pmdecrad": -6.07471541e-7,
    "flam": 84,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12497,
    "lum": 2.28875865706236,
    "bf": "84    Cet",
    "ra": 2.687222,
    "dec": -0.695653,
    "pmra": 218.94,
    "pmdec": -125.3,
    "rv": 8,
    "Asset Name": "ASTRID 002489",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Star of Culture",
    "Hair": "Neko",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.0000094,
    "y_1": 0.00002347,
    "z_1": -0.00001382,
    "Distance in Parsecs_1": 22.5887,
    "HYG Star ID_1": 12497,
    "Hipparcos Catalogue_1": 12530,
    "Henry Draper Catalogue_1": 16765,
    "Harvard Revised Catalogue_1": 790
  },
  {
    "Astrid #": "ASTRID 002490",
    "HYG Star ID": 1346,
    "Hipparcos Catalogue": 1349,
    "Henry Draper Catalogue": 1273,
    "Gliese Catalog": "Gl 13",
    "Distance in Parsecs": 22.5989,
    "mag": 6.84,
    "Absolute Visual Magnitude": 5.07,
    "Spectral Type": "G2V",
    "Color Index": 0.655,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.672666,
    "y": 1.009951,
    "z": -17.965041,
    "vx": 0.00000446,
    "vy": 0.00003493,
    "vz": 0.00002376,
    "rarad": 0.0737238441317985,
    "decrad": -0.918935899131538,
    "pmrarad": 0.00000152687220554166,
    "pmdecrad": 8.84784967e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 1346,
    "lum": 0.816582371358591,
    "ra": 0.281604,
    "dec": -52.651149,
    "pmra": 314.94,
    "pmdec": 182.5,
    "rv": -14.3,
    "Asset Name": "ASTRID 002490",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Mohawk",
    "Outfit": "Super",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": 0.00000446,
    "y_1": 0.00003493,
    "z_1": 0.00002376,
    "Distance in Parsecs_1": 22.5989,
    "HYG Star ID_1": 1346,
    "Hipparcos Catalogue_1": 1349,
    "Henry Draper Catalogue_1": 1273,
    "Gliese Catalog_1": "Gl 13"
  },
  {
    "Astrid #": "ASTRID 002491",
    "HYG Star ID": 78499,
    "Hipparcos Catalogue": 78739,
    "Henry Draper Catalogue": 144088,
    "Gliese Catalog": "Wo 9541B",
    "Distance in Parsecs": 22.5989,
    "mag": 8.02,
    "Absolute Visual Magnitude": 6.25,
    "Spectral Type": "K2V",
    "Color Index": 0.85,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.700345,
    "y": -19.392696,
    "z": -4.486073,
    "vx": 0.00000829,
    "vy": 0.00002818,
    "vz": 0.0000032,
    "rarad": 4.20818645387372,
    "decrad": -0.199838134383373,
    "pmrarad": -2.81385860194444e-7,
    "pmdecrad": -1.13300957e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 2,
    "comp_primary": 78498,
    "base": "Wo 9541",
    "lum": 0.275422870333816,
    "ra": 16.074088,
    "dec": -11.449882,
    "pmra": -58.04,
    "pmdec": -23.37,
    "rv": -28.1,
    "Asset Name": "ASTRID 002491",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Infatuated",
    "Hair": "Good Boy",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000829,
    "y_1": 0.00002818,
    "z_1": 0.0000032,
    "Distance in Parsecs_1": 22.5989,
    "HYG Star ID_1": 78499,
    "Hipparcos Catalogue_1": 78739,
    "Henry Draper Catalogue_1": 144088,
    "Gliese Catalog_1": "Wo 9541B"
  },
  {
    "Astrid #": "ASTRID 002492",
    "HYG Star ID": 15061,
    "Hipparcos Catalogue": 15099,
    "Henry Draper Catalogue": 20165,
    "Gliese Catalog": "Wo 9112",
    "Distance in Parsecs": 22.6501,
    "mag": 7.83,
    "Absolute Visual Magnitude": 6.055,
    "Spectral Type": "K0",
    "Color Index": 0.861,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.766658,
    "y": 16.806628,
    "z": 3.535726,
    "vx": -0.00004285,
    "vy": 0.00001797,
    "vz": -0.00004734,
    "rarad": 0.84991893111162,
    "decrad": 0.156744388202589,
    "pmrarad": 0.00000194492704228472,
    "pmdecrad": -0.000001962719704,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 15061,
    "lum": 0.329609712177457,
    "ra": 3.246451,
    "dec": 8.980792,
    "pmra": 401.17,
    "pmdec": -404.84,
    "rv": -21.5,
    "Asset Name": "ASTRID 002492",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Neko Ninja",
    "Hair": "Fedora",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00004285,
    "y_1": 0.00001797,
    "z_1": -0.00004734,
    "Distance in Parsecs_1": 22.6501,
    "HYG Star ID_1": 15061,
    "Hipparcos Catalogue_1": 15099,
    "Henry Draper Catalogue_1": 20165,
    "Gliese Catalog_1": "Wo 9112"
  },
  {
    "Astrid #": "ASTRID 002493",
    "HYG Star ID": 86455,
    "Hipparcos Catalogue": 86722,
    "Henry Draper Catalogue": 161198,
    "Gliese Catalog": "Gl 692.1",
    "Distance in Parsecs": 22.6501,
    "mag": 7.51,
    "Absolute Visual Magnitude": 5.735,
    "Spectral Type": "K0V",
    "Color Index": 0.752,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.536715,
    "y": -21.002248,
    "z": 8.341523,
    "vx": -0.00001701,
    "vy": -0.00004715,
    "vz": -0.00005408,
    "rarad": 4.63935049182958,
    "decrad": 0.377151848645226,
    "pmrarad": -5.97048047604166e-7,
    "pmdecrad": -0.000003005069117,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 86455,
    "lum": 0.442588372362626,
    "ra": 17.721014,
    "dec": 21.609209,
    "pmra": -123.15,
    "pmdec": -619.84,
    "rv": 24.4,
    "Asset Name": "ASTRID 002493",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Braid",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001701,
    "y_1": -0.00004715,
    "z_1": -0.00005408,
    "Distance in Parsecs_1": 22.6501,
    "HYG Star ID_1": 86455,
    "Hipparcos Catalogue_1": 86722,
    "Henry Draper Catalogue_1": 161198,
    "Gliese Catalog_1": "Gl 692.1"
  },
  {
    "Astrid #": "ASTRID 002494",
    "HYG Star ID": 5651,
    "Hipparcos Catalogue": 5663,
    "Henry Draper Catalogue": 7279,
    "Distance in Parsecs": 22.6552,
    "mag": 9.55,
    "Absolute Visual Magnitude": 7.774,
    "Spectral Type": "K4V",
    "Color Index": 1.267,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.468689,
    "y": 6.398048,
    "z": -9.658842,
    "vx": -0.0000035,
    "vy": 0.00001369,
    "vz": 0.00000202,
    "rarad": 0.317513447881701,
    "decrad": -0.440443918425492,
    "pmrarad": 6.22500765833333e-7,
    "pmdecrad": 9.8465658e-8,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 5651,
    "lum": 0.067670595767419,
    "ra": 1.212812,
    "dec": -25.235578,
    "pmra": 128.4,
    "pmdec": 20.31,
    "rv": 0,
    "Asset Name": "ASTRID 002494",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Charmer",
    "Hair": "Shinobi",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.0000035,
    "y_1": 0.00001369,
    "z_1": 0.00000202,
    "Distance in Parsecs_1": 22.6552,
    "HYG Star ID_1": 5651,
    "Hipparcos Catalogue_1": 5663,
    "Henry Draper Catalogue_1": 7279
  },
  {
    "Astrid #": "ASTRID 002495",
    "HYG Star ID": 36109,
    "Hipparcos Catalogue": 36210,
    "Henry Draper Catalogue": 59468,
    "Gliese Catalog": "Gl 275",
    "Distance in Parsecs": 22.6757,
    "mag": 6.72,
    "Absolute Visual Magnitude": 4.942,
    "Spectral Type": "G5IV-V",
    "Color Index": 0.694,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.266275,
    "y": 13.129322,
    "z": -17.722189,
    "vx": 0.00002838,
    "vy": 0.00001349,
    "vz": -0.00000289,
    "rarad": 1.95226470223248,
    "decrad": -0.897144631780348,
    "pmrarad": -0.000001383173430625,
    "pmdecrad": -1.1974897e-8,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 36109,
    "lum": 0.918755601666062,
    "ra": 7.457102,
    "dec": -51.402601,
    "pmra": -285.3,
    "pmdec": -2.47,
    "rv": 3.4,
    "Asset Name": "ASTRID 002495",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Slick",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00002838,
    "y_1": 0.00001349,
    "z_1": -0.00000289,
    "Distance in Parsecs_1": 22.6757,
    "HYG Star ID_1": 36109,
    "Hipparcos Catalogue_1": 36210,
    "Henry Draper Catalogue_1": 59468,
    "Gliese Catalog_1": "Gl 275"
  },
  {
    "Astrid #": "ASTRID 002496",
    "HYG Star ID": 76835,
    "Hipparcos Catalogue": 77070,
    "Henry Draper Catalogue": 140573,
    "Harvard Revised Catalogue": 5854,
    "Gliese Catalog": "Gl 596.2",
    "Proper Name": "Unukalhai",
    "Distance in Parsecs": 22.6757,
    "mag": 2.63,
    "Absolute Visual Magnitude": 0.852,
    "Spectral Type": "K2III",
    "Color Index": 1.167,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.5786,
    "y": -18.695648,
    "z": 2.537718,
    "vx": 0.00001105,
    "vy": -0.00001009,
    "vz": 0.00000513,
    "rarad": 4.12014598643839,
    "decrad": 0.112148346471632,
    "pmrarad": 6.52850102236111e-7,
    "pmdecrad": 2.13996758e-7,
    "bayer": "Alp",
    "flam": 24,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 76835,
    "lum": 39.7374505061463,
    "bf": "24Alp Ser",
    "ra": 15.737798,
    "dec": 6.425627,
    "pmra": 134.66,
    "pmdec": 44.14,
    "rv": 2.7,
    "Asset Name": "ASTRID 002496",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Unimpressed",
    "Hair": "Nemes",
    "Outfit": "Pharaoh",
    "Special": "Overburner",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Unukalhai",
    "Constellation": "Serpens",
    "x_1": 0.00001105,
    "y_1": -0.00001009,
    "z_1": 0.00000513,
    "Distance in Parsecs_1": 22.6757,
    "HYG Star ID_1": 76835,
    "Hipparcos Catalogue_1": 77070,
    "Henry Draper Catalogue_1": 140573,
    "Harvard Revised Catalogue_1": 5854,
    "Gliese Catalog_1": "Gl 596.2"
  },
  {
    "Astrid #": "ASTRID 002497",
    "HYG Star ID": 111099,
    "Hipparcos Catalogue": 111449,
    "Henry Draper Catalogue": 213845,
    "Harvard Revised Catalogue": 8592,
    "Gliese Catalog": "Gl 863.2",
    "Distance in Parsecs": 22.6809,
    "mag": 5.21,
    "Absolute Visual Magnitude": 3.432,
    "Spectral Type": "F7V",
    "Color Index": 0.446,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.762752,
    "y": -7.715712,
    "z": -8.020157,
    "vx": -1.9e-7,
    "vy": 0.00002623,
    "vz": -0.00001356,
    "rarad": 5.91096678353726,
    "decrad": -0.361426546099055,
    "pmrarad": 0.00000107434711611111,
    "pmdecrad": -7.10639892e-7,
    "bayer": "Ups",
    "flam": 59,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 111099,
    "lum": 3.69147558165412,
    "bf": "59Ups Aqr",
    "ra": 22.57823,
    "dec": -20.708216,
    "pmra": 221.6,
    "pmdec": -146.58,
    "rv": -4.2,
    "Asset Name": "ASTRID 002497",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Curly",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -1.9e-7,
    "y_1": 0.00002623,
    "z_1": -0.00001356,
    "Distance in Parsecs_1": 22.6809,
    "HYG Star ID_1": 111099,
    "Hipparcos Catalogue_1": 111449,
    "Henry Draper Catalogue_1": 213845,
    "Harvard Revised Catalogue_1": 8592,
    "Gliese Catalog_1": "Gl 863.2"
  },
  {
    "Astrid #": "ASTRID 002498",
    "HYG Star ID": 40556,
    "Hipparcos Catalogue": 40671,
    "Gliese Catalog": "Gl 301.1",
    "Distance in Parsecs": 22.6912,
    "mag": 8.82,
    "Absolute Visual Magnitude": 7.041,
    "Spectral Type": "K4V",
    "Color Index": 1.137,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.075206,
    "y": 16.087491,
    "z": 11.551092,
    "vx": -0.0000064,
    "vy": 0.00006399,
    "vz": -0.00007194,
    "rarad": 2.17372082491928,
    "decrad": 0.534084984326328,
    "pmrarad": -0.00000136698065369444,
    "pmdecrad": -0.000003992876991,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 40556,
    "lum": 0.132922958831568,
    "ra": 8.303002,
    "dec": 30.600816,
    "pmra": -281.96,
    "pmdec": -823.59,
    "rv": 11.6,
    "Asset Name": "ASTRID 002498",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Slick",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.0000064,
    "y_1": 0.00006399,
    "z_1": -0.00007194,
    "Distance in Parsecs_1": 22.6912,
    "HYG Star ID_1": 40556,
    "Hipparcos Catalogue_1": 40671,
    "Gliese Catalog_1": "Gl 301.1"
  },
  {
    "Astrid #": "ASTRID 002499",
    "HYG Star ID": 53172,
    "Hipparcos Catalogue": 53327,
    "Gliese Catalog": "GJ 1139",
    "Distance in Parsecs": 22.6912,
    "mag": 9.48,
    "Absolute Visual Magnitude": 7.701,
    "Spectral Type": "K8V:",
    "Color Index": 1.097,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.242142,
    "y": 1.541806,
    "z": 22.023248,
    "vx": 0.00003371,
    "vy": 0.00003967,
    "vz": -0.00002046,
    "rarad": 2.85559900037754,
    "decrad": 1.32759780202258,
    "pmrarad": -0.00000209652828019444,
    "pmdecrad": 6.87659724e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 53172,
    "lum": 0.0723769037074528,
    "ra": 10.907585,
    "dec": 76.065751,
    "pmra": -432.44,
    "pmdec": 141.84,
    "rv": -24.4,
    "Asset Name": "ASTRID 002499",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Middle Part",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00003371,
    "y_1": 0.00003967,
    "z_1": -0.00002046,
    "Distance in Parsecs_1": 22.6912,
    "HYG Star ID_1": 53172,
    "Hipparcos Catalogue_1": 53327,
    "Gliese Catalog_1": "GJ 1139"
  },
  {
    "Astrid #": "ASTRID 002500",
    "HYG Star ID": 66040,
    "Hipparcos Catalogue": 66249,
    "Henry Draper Catalogue": 118098,
    "Harvard Revised Catalogue": 5107,
    "Gliese Catalog": "NN 3792",
    "Distance in Parsecs": 22.7118,
    "mag": 3.38,
    "Absolute Visual Magnitude": 1.599,
    "Spectral Type": "A3V",
    "Color Index": 0.114,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -20.799365,
    "y": -9.118725,
    "z": -0.236175,
    "vx": -2e-8,
    "vy": 0.00003352,
    "vz": 0.00000549,
    "rarad": 3.55476968115235,
    "decrad": -0.0103990230266737,
    "pmrarad": -0.00000135209687370138,
    "pmdecrad": 2.35425523e-7,
    "bayer": "Zet",
    "flam": 79,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66040,
    "lum": 19.9710086602818,
    "bf": "79Zet Vir",
    "ra": 13.57822,
    "dec": -0.59582,
    "pmra": -278.89,
    "pmdec": 48.56,
    "rv": -13.2,
    "Asset Name": "ASTRID 002500",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Bandana",
    "Outfit": "Sweater Buddy",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -2e-8,
    "y_1": 0.00003352,
    "z_1": 0.00000549,
    "Distance in Parsecs_1": 22.7118,
    "HYG Star ID_1": 66040,
    "Hipparcos Catalogue_1": 66249,
    "Henry Draper Catalogue_1": 118098,
    "Harvard Revised Catalogue_1": 5107,
    "Gliese Catalog_1": "NN 3792"
  },
  {
    "Astrid #": "ASTRID 002501",
    "HYG Star ID": 10392,
    "Hipparcos Catalogue": 10416,
    "Henry Draper Catalogue": 13789,
    "Distance in Parsecs": 22.7169,
    "mag": 8.55,
    "Absolute Visual Magnitude": 6.768,
    "Spectral Type": "K2",
    "Color Index": 1.055,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.892828,
    "y": 12.53172,
    "z": -1.440335,
    "vx": -5.3e-7,
    "vy": -0.00000195,
    "vz": -0.00002393,
    "rarad": 0.585672159824575,
    "decrad": -0.0634462700945925,
    "pmrarad": -5.84200485069444e-8,
    "pmdecrad": -0.000001055439382,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10392,
    "lum": 0.170922800523433,
    "ra": 2.237103,
    "dec": -3.635204,
    "pmra": -12.05,
    "pmdec": -217.7,
    "rv": 0,
    "Asset Name": "ASTRID 002501",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Mohawk",
    "Outfit": "Astro",
    "Special": "V1 Flight System",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -5.3e-7,
    "y_1": -0.00000195,
    "z_1": -0.00002393,
    "Distance in Parsecs_1": 22.7169,
    "HYG Star ID_1": 10392,
    "Hipparcos Catalogue_1": 10416,
    "Henry Draper Catalogue_1": 13789
  },
  {
    "Astrid #": "ASTRID 002502",
    "HYG Star ID": 89212,
    "Hipparcos Catalogue": 89490,
    "Henry Draper Catalogue": 348274,
    "Distance in Parsecs": 22.7169,
    "mag": 10.84,
    "Absolute Visual Magnitude": 9.058,
    "Spectral Type": "M2",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.473289,
    "y": -21.436574,
    "z": 7.372939,
    "vx": -0.00000304,
    "vy": -0.00001559,
    "vz": -0.00004472,
    "rarad": 4.78100898311356,
    "decrad": 0.330543788610625,
    "pmrarad": -1.80787021479166e-7,
    "pmdecrad": -0.0000020816445,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 89212,
    "lum": 0.0207395820445383,
    "var_min": 10.891,
    "var_max": 10.771,
    "ra": 18.262109,
    "dec": 18.938764,
    "pmra": -37.29,
    "pmdec": -429.37,
    "rv": 0,
    "Asset Name": "ASTRID 002502",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Antlers",
    "Outfit": "Star Knight",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000304,
    "y_1": -0.00001559,
    "z_1": -0.00004472,
    "Distance in Parsecs_1": 22.7169,
    "HYG Star ID_1": 89212,
    "Hipparcos Catalogue_1": 89490,
    "Henry Draper Catalogue_1": 348274
  },
  {
    "Astrid #": "ASTRID 002503",
    "HYG Star ID": 70725,
    "Hipparcos Catalogue": 70950,
    "Henry Draper Catalogue": 127506,
    "Gliese Catalog": "Gl 554",
    "Distance in Parsecs": 22.7221,
    "mag": 8.68,
    "Absolute Visual Magnitude": 6.898,
    "Spectral Type": "K3V",
    "Color Index": 1.031,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.647221,
    "y": -11.315518,
    "z": 13.179792,
    "vx": -0.00001439,
    "vy": 0.00005578,
    "vz": 0.00001075,
    "rarad": 3.79936664879691,
    "decrad": 0.618784689484541,
    "pmrarad": -0.00000232996606738194,
    "pmdecrad": 9.65312519e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 70725,
    "lum": 0.151635190078728,
    "ra": 14.512512,
    "dec": 35.453751,
    "pmra": -480.59,
    "pmdec": 199.11,
    "rv": -12,
    "Asset Name": "ASTRID 002503",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Unkempt",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00001439,
    "y_1": 0.00005578,
    "z_1": 0.00001075,
    "Distance in Parsecs_1": 22.7221,
    "HYG Star ID_1": 70725,
    "Hipparcos Catalogue_1": 70950,
    "Henry Draper Catalogue_1": 127506,
    "Gliese Catalog_1": "Gl 554"
  },
  {
    "Astrid #": "ASTRID 002504",
    "HYG Star ID": 118675,
    "Gliese Catalog": "Gl 387B",
    "Distance in Parsecs": 22.7221,
    "mag": 11.4,
    "Absolute Visual Magnitude": 9.618,
    "Spectral Type": "M1",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.835318,
    "y": 9.059771,
    "z": 8.918207,
    "vx": -0.00001739,
    "vy": 0.00005797,
    "vz": 0.00000447,
    "rarad": 2.69326956791166,
    "decrad": 0.403300833273299,
    "pmrarad": -0.00000196776176663888,
    "pmdecrad": -5.23574534e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 2,
    "comp_primary": 50242,
    "base": "Gl 387",
    "lum": 0.0123822623095899,
    "ra": 10.287532,
    "dec": 23.107436,
    "pmra": -405.88,
    "pmdec": -108,
    "rv": 38.4,
    "Asset Name": "ASTRID 002504",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Spiky",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00001739,
    "y_1": 0.00005797,
    "z_1": 0.00000447,
    "Distance in Parsecs_1": 22.7221,
    "HYG Star ID_1": 118675,
    "Gliese Catalog_1": "Gl 387B"
  },
  {
    "Astrid #": "ASTRID 002505",
    "HYG Star ID": 11823,
    "Hipparcos Catalogue": 11852,
    "Henry Draper Catalogue": 16348,
    "Gliese Catalog": "NN 3167",
    "Distance in Parsecs": 22.7273,
    "mag": 8.84,
    "Absolute Visual Magnitude": 7.057,
    "Spectral Type": "K4V",
    "Color Index": 1.045,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.311651,
    "y": 4.186698,
    "z": -21.697651,
    "vx": 0.00000669,
    "vy": 0.00002504,
    "vz": 0.00000647,
    "rarad": 0.667496523570876,
    "decrad": -1.26863495932834,
    "pmrarad": 6.83150957270833e-7,
    "pmdecrad": 9.56876761e-7,
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 11823,
    "lum": 0.130978496239968,
    "ra": 2.549649,
    "dec": -72.687429,
    "pmra": 140.91,
    "pmdec": 197.37,
    "rv": 0,
    "Asset Name": "ASTRID 002505",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Windy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": 0.00000669,
    "y_1": 0.00002504,
    "z_1": 0.00000647,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 11823,
    "Hipparcos Catalogue_1": 11852,
    "Henry Draper Catalogue_1": 16348,
    "Gliese Catalog_1": "NN 3167"
  },
  {
    "Astrid #": "ASTRID 002506",
    "HYG Star ID": 117962,
    "Gliese Catalog": "NN 3007",
    "Distance in Parsecs": 22.7273,
    "mag": 13.07,
    "Absolute Visual Magnitude": 11.287,
    "Spectral Type": "M3.5",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.492151,
    "y": 0.783215,
    "z": 3.165422,
    "vx": 0.00000752,
    "vy": -0.00003988,
    "vz": -0.0000435,
    "rarad": 0.034808505821318,
    "decrad": 0.13973265963635,
    "pmrarad": -0.00000176530357363888,
    "pmdecrad": -0.000001933267273,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117962,
    "lum": 0.00266195065115183,
    "ra": 0.132959,
    "dec": 8.006092,
    "pmra": -364.12,
    "pmdec": -398.76,
    "rv": 0,
    "Asset Name": "ASTRID 002506",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "High Bun",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000752,
    "y_1": -0.00003988,
    "z_1": -0.0000435,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 117962,
    "Gliese Catalog_1": "NN 3007"
  },
  {
    "Astrid #": "ASTRID 002507",
    "HYG Star ID": 118038,
    "Gliese Catalog": "NN 3073",
    "Distance in Parsecs": 22.7273,
    "mag": 13.64,
    "Absolute Visual Magnitude": 11.857,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.60876,
    "y": 5.267657,
    "z": 11.937171,
    "vx": -0.00000903,
    "vy": 0.0000271,
    "vz": 0.00000212,
    "rarad": 0.2758459917957,
    "decrad": 0.552991114939,
    "pmrarad": 0.00000125571591400694,
    "pmdecrad": 1.0985878e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118038,
    "lum": 0.00157470787728984,
    "ra": 1.053654,
    "dec": 31.684057,
    "pmra": 259.01,
    "pmdec": 22.66,
    "rv": 0,
    "Asset Name": "ASTRID 002507",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Einstein Tongue",
    "Hair": "Innocent",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000903,
    "y_1": 0.0000271,
    "z_1": 0.00000212,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118038,
    "Gliese Catalog_1": "NN 3073"
  },
  {
    "Astrid #": "ASTRID 002508",
    "HYG Star ID": 118125,
    "Gliese Catalog": "NN 3145",
    "Distance in Parsecs": 22.7273,
    "mag": 13,
    "Absolute Visual Magnitude": 11.217,
    "Spectral Type": "M3",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.392746,
    "y": 12.384539,
    "z": -4.986007,
    "vx": -0.00002639,
    "vy": 0.0000483,
    "vz": 0.00002259,
    "rarad": 0.592604169858627,
    "decrad": -0.2211831010203,
    "pmrarad": 0.00000241165717255555,
    "pmdecrad": 0.000001018719594,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118125,
    "lum": 0.0028392262394249,
    "ra": 2.263581,
    "dec": -12.672858,
    "pmra": 497.44,
    "pmdec": 210.13,
    "rv": 0,
    "Asset Name": "ASTRID 002508",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Medium Bob",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002639,
    "y_1": 0.0000483,
    "z_1": 0.00002259,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118125,
    "Gliese Catalog_1": "NN 3145"
  },
  {
    "Astrid #": "ASTRID 002509",
    "HYG Star ID": 118177,
    "Gliese Catalog": "NN 3184",
    "Distance in Parsecs": 22.7273,
    "mag": 13.19,
    "Absolute Visual Magnitude": 11.407,
    "Spectral Type": "dM3",
    "Color Index": 1.58,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 16.533676,
    "y": 15.397597,
    "z": 2.466117,
    "vx": -0.0000341,
    "vy": 0.00003857,
    "vz": -0.00001209,
    "rarad": 0.74983361808323,
    "decrad": 0.1087230997095,
    "pmrarad": 0.00000226466166461111,
    "pmdecrad": -5.3534581e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118177,
    "lum": 0.00238341682067315,
    "ra": 2.864153,
    "dec": 6.229375,
    "pmra": 467.12,
    "pmdec": -110.42,
    "rv": 0,
    "Asset Name": "ASTRID 002509",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Pirate Hat",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000341,
    "y_1": 0.00003857,
    "z_1": -0.00001209,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118177,
    "Gliese Catalog_1": "NN 3184"
  },
  {
    "Astrid #": "ASTRID 002510",
    "HYG Star ID": 118421,
    "Gliese Catalog": "Wo 9209B",
    "Distance in Parsecs": 22.7273,
    "mag": 14.09,
    "Absolute Visual Magnitude": 12.307,
    "Spectral Type": "DB4",
    "Color Index": -0.09,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -0.827164,
    "y": 11.604365,
    "z": -19.526333,
    "vx": 0.00000675,
    "vy": -0.0000094,
    "vz": -0.00005349,
    "rarad": 1.64196911835157,
    "decrad": -1.03347006485285,
    "pmrarad": -2.66792968409722e-7,
    "pmdecrad": -0.000001577477057,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 29715,
    "base": "Wo 9209",
    "lum": 0.00104039917705135,
    "ra": 6.27186,
    "dec": -59.213473,
    "pmra": -55.03,
    "pmdec": -325.38,
    "rv": 40,
    "Asset Name": "ASTRID 002510",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Windy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000675,
    "y_1": -0.0000094,
    "z_1": -0.00005349,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118421,
    "Gliese Catalog_1": "Wo 9209B"
  },
  {
    "Astrid #": "ASTRID 002511",
    "HYG Star ID": 118463,
    "Gliese Catalog": "NN 3427",
    "Distance in Parsecs": 22.7273,
    "mag": 12.54,
    "Absolute Visual Magnitude": 10.757,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.339478,
    "y": 7.469731,
    "z": 21.336826,
    "vx": 0.00001065,
    "vy": 0.00003425,
    "vz": -0.00001082,
    "rarad": 1.87439911210131,
    "decrad": 1.21918565041675,
    "pmrarad": -8.97777973652777e-7,
    "pmdecrad": -0.000001382431665,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118463,
    "lum": 0.00433710563500441,
    "ra": 7.159677,
    "dec": 69.854192,
    "pmra": -185.18,
    "pmdec": -285.15,
    "rv": 0,
    "Asset Name": "ASTRID 002511",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Wavy",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001065,
    "y_1": 0.00003425,
    "z_1": -0.00001082,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118463,
    "Gliese Catalog_1": "NN 3427"
  },
  {
    "Astrid #": "ASTRID 002512",
    "HYG Star ID": 118474,
    "Gliese Catalog": "NN 3440",
    "Distance in Parsecs": 22.7273,
    "mag": 11.75,
    "Absolute Visual Magnitude": 9.967,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.897337,
    "y": 2.619948,
    "z": -22.557943,
    "vx": -0.00001469,
    "vy": 0.00007179,
    "vz": 0.00000891,
    "rarad": 1.90092440990568,
    "decrad": -1.4486411329286,
    "pmrarad": -4.12721886256944e-7,
    "pmdecrad": 0.000003221921428,
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 118474,
    "lum": 0.00897842170909725,
    "ra": 7.260996,
    "dec": -83.001023,
    "pmra": -85.13,
    "pmdec": 664.57,
    "rv": 0,
    "Asset Name": "ASTRID 002512",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Curly",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": -0.00001469,
    "y_1": 0.00007179,
    "z_1": 0.00000891,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118474,
    "Gliese Catalog_1": "NN 3440"
  },
  {
    "Astrid #": "ASTRID 002513",
    "HYG Star ID": 118603,
    "Henry Draper Catalogue": 78727,
    "Gliese Catalog": "NN 3538",
    "Distance in Parsecs": 22.7273,
    "mag": 8.38,
    "Absolute Visual Magnitude": 6.597,
    "Spectral Type": "K4",
    "Color Index": 1.01,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.680289,
    "y": 15.298611,
    "z": 2.061708,
    "vx": 0.00000466,
    "vy": 0.00000573,
    "vz": -0.00000481,
    "rarad": 2.39937360028611,
    "decrad": 0.0908398993684,
    "pmrarad": -3.24534277763888e-7,
    "pmdecrad": -2.12372632e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 118603,
    "lum": 0.200078305215239,
    "ra": 9.164932,
    "dec": 5.204743,
    "pmra": -66.94,
    "pmdec": -43.8,
    "rv": 0,
    "Asset Name": "ASTRID 002513",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Oof",
    "Hair": "Braided Bun",
    "Outfit": "Teen",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000466,
    "y_1": 0.00000573,
    "z_1": -0.00000481,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118603,
    "Henry Draper Catalogue_1": 78727,
    "Gliese Catalog_1": "NN 3538"
  },
  {
    "Astrid #": "ASTRID 002514",
    "HYG Star ID": 118697,
    "Gliese Catalog": "NN 3613",
    "Distance in Parsecs": 22.7273,
    "mag": 13.49,
    "Absolute Visual Magnitude": 11.707,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.109265,
    "y": 5.239989,
    "z": 17.029425,
    "vx": 0.00000138,
    "vy": 0.00002237,
    "vz": -0.00000574,
    "rarad": 2.78601788496412,
    "decrad": 0.8469950235811,
    "pmrarad": -9.43980717409722e-7,
    "pmdecrad": -3.81388378e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118697,
    "lum": 0.00180800655211,
    "ra": 10.641804,
    "dec": 48.52924,
    "pmra": -194.71,
    "pmdec": -78.67,
    "rv": 0,
    "Asset Name": "ASTRID 002514",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Fired Up",
    "Hair": "Angelic",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000138,
    "y_1": 0.00002237,
    "z_1": -0.00000574,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118697,
    "Gliese Catalog_1": "NN 3613"
  },
  {
    "Astrid #": "ASTRID 002515",
    "HYG Star ID": 118701,
    "Gliese Catalog": "NN 3616A",
    "Distance in Parsecs": 22.7273,
    "mag": 13.55,
    "Absolute Visual Magnitude": 11.767,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.165232,
    "y": 6.176413,
    "z": 12.182219,
    "vx": 0.00000882,
    "vy": 0.00002126,
    "vz": 0.00000238,
    "rarad": 2.81385190414228,
    "decrad": 0.56571194767585,
    "pmrarad": -0.00000101049715438194,
    "pmdecrad": 1.24073517e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118701,
    "base": "NN 3616",
    "lum": 0.00171080298759932,
    "ra": 10.748123,
    "dec": 32.412907,
    "pmra": -208.43,
    "pmdec": 25.59,
    "rv": 0,
    "Asset Name": "ASTRID 002515",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Charmer",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000882,
    "y_1": 0.00002126,
    "z_1": 0.00000238,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118701,
    "Gliese Catalog_1": "NN 3616A"
  },
  {
    "Astrid #": "ASTRID 002516",
    "HYG Star ID": 118702,
    "Gliese Catalog": "NN 3617B",
    "Distance in Parsecs": 22.7273,
    "mag": 15.5,
    "Absolute Visual Magnitude": 13.717,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.167135,
    "y": 6.174125,
    "z": 12.18054,
    "vx": 0.00000882,
    "vy": 0.00002126,
    "vz": 0.00000238,
    "rarad": 2.81399672314228,
    "decrad": 0.56562444567585,
    "pmrarad": -0.00000101049715438194,
    "pmdecrad": 1.24073517e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118702,
    "base": "NN 3617",
    "lum": 0.00028392262394249,
    "ra": 10.748676,
    "dec": 32.407894,
    "pmra": -208.43,
    "pmdec": 25.59,
    "rv": 0,
    "Asset Name": "ASTRID 002516",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Upset",
    "Hair": "Audiophile",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000882,
    "y_1": 0.00002126,
    "z_1": 0.00000238,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118702,
    "Gliese Catalog_1": "NN 3617B"
  },
  {
    "Astrid #": "ASTRID 002517",
    "HYG Star ID": 118752,
    "Gliese Catalog": "NN 3660A",
    "Distance in Parsecs": 22.7273,
    "mag": 12.15,
    "Absolute Visual Magnitude": 10.367,
    "Spectral Type": "M2.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.570007,
    "y": 0.693016,
    "z": 22.252302,
    "vx": -0.00001545,
    "vy": 0.00007198,
    "vz": -0.0000054,
    "rarad": 2.99168836933227,
    "decrad": 1.36598861819735,
    "pmrarad": -0.00000303047335441666,
    "pmdecrad": -0.000001169356053,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118752,
    "base": "NN 3660",
    "lum": 0.0062115502083244,
    "ra": 11.427408,
    "dec": 78.265383,
    "pmra": -625.08,
    "pmdec": -241.2,
    "rv": 0,
    "Asset Name": "ASTRID 002517",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Windy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001545,
    "y_1": 0.00007198,
    "z_1": -0.0000054,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118752,
    "Gliese Catalog_1": "NN 3660A"
  },
  {
    "Astrid #": "ASTRID 002518",
    "HYG Star ID": 118753,
    "Gliese Catalog": "NN 3661B",
    "Distance in Parsecs": 22.7273,
    "mag": 12.65,
    "Absolute Visual Magnitude": 10.867,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.562363,
    "y": 0.695808,
    "z": 22.253784,
    "vx": -0.00001539,
    "vy": 0.00007199,
    "vz": -0.00000539,
    "rarad": 2.99084285233227,
    "decrad": 1.36630929319735,
    "pmrarad": -0.00000303047335441666,
    "pmdecrad": -0.000001169356053,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118753,
    "base": "NN 3661",
    "lum": 0.00391922322454976,
    "ra": 11.424178,
    "dec": 78.283756,
    "pmra": -625.08,
    "pmdec": -241.2,
    "rv": 0,
    "Asset Name": "ASTRID 002518",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Halo Boy",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001539,
    "y_1": 0.00007199,
    "z_1": -0.00000539,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118753,
    "Gliese Catalog_1": "NN 3661B"
  },
  {
    "Astrid #": "ASTRID 002519",
    "HYG Star ID": 118776,
    "Gliese Catalog": "NN 3688",
    "Distance in Parsecs": 22.7273,
    "mag": 12.9,
    "Absolute Visual Magnitude": 11.117,
    "Spectral Type": "k",
    "Color Index": 1.46,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.258829,
    "y": 1.135657,
    "z": -4.448035,
    "vx": 0.00000498,
    "vy": 0.00009129,
    "vz": -0.00000172,
    "rarad": 3.09062031068359,
    "decrad": -0.19698478226815,
    "pmrarad": -0.00000402322632809027,
    "pmdecrad": -7.7245363e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118776,
    "lum": 0.00311314966544701,
    "ra": 11.8053,
    "dec": -11.286397,
    "pmra": -829.85,
    "pmdec": -15.93,
    "rv": 0,
    "Asset Name": "ASTRID 002519",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Determined Neko",
    "Hair": "Lion Mane",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000498,
    "y_1": 0.00009129,
    "z_1": -0.00000172,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118776,
    "Gliese Catalog_1": "NN 3688"
  },
  {
    "Astrid #": "ASTRID 002520",
    "HYG Star ID": 118797,
    "Gliese Catalog": "Wo 9390B",
    "Distance in Parsecs": 22.7273,
    "mag": 9.2,
    "Absolute Visual Magnitude": 7.417,
    "Spectral Type": "K5",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.223519,
    "y": -0.921902,
    "z": -4.669255,
    "vx": 0.00000241,
    "vy": -0.00003372,
    "vz": -0.00001877,
    "rarad": 3.18305041860198,
    "decrad": -0.20691908094455,
    "pmrarad": 0.00000148697203963194,
    "pmdecrad": -8.17458891e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 59092,
    "base": "Wo 9390",
    "lum": 0.0940156168803569,
    "ra": 12.158357,
    "dec": -11.85559,
    "pmra": 306.71,
    "pmdec": -168.61,
    "rv": 2.8,
    "Asset Name": "ASTRID 002520",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Medium Bob",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000241,
    "y_1": -0.00003372,
    "z_1": -0.00001877,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118797,
    "Gliese Catalog_1": "Wo 9390B"
  },
  {
    "Astrid #": "ASTRID 002521",
    "HYG Star ID": 118840,
    "Gliese Catalog": "NN 3741A",
    "Distance in Parsecs": 22.7273,
    "mag": 11.66,
    "Absolute Visual Magnitude": 9.877,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.887719,
    "y": -2.734336,
    "z": 16.953153,
    "vx": -0.00001163,
    "vy": -0.00002352,
    "vz": -0.00001401,
    "rarad": 3.32320898031394,
    "decrad": 0.8419418711709,
    "pmrarad": 9.25606278916666e-7,
    "pmdecrad": -9.25596582e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 118840,
    "base": "NN 3741",
    "lum": 0.00975438740440298,
    "ra": 12.693723,
    "dec": 48.239716,
    "pmra": 190.92,
    "pmdec": -190.92,
    "rv": 0,
    "Asset Name": "ASTRID 002521",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Windy",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00001163,
    "y_1": -0.00002352,
    "z_1": -0.00001401,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118840,
    "Gliese Catalog_1": "NN 3741A"
  },
  {
    "Astrid #": "ASTRID 002522",
    "HYG Star ID": 118841,
    "Gliese Catalog": "NN 3742B",
    "Distance in Parsecs": 22.7273,
    "mag": 14.8,
    "Absolute Visual Magnitude": 13.017,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.88752,
    "y": -2.735412,
    "z": 16.953155,
    "vx": -0.00001163,
    "vy": -0.00002352,
    "vz": -0.00001401,
    "rarad": 3.32328130931394,
    "decrad": 0.8419419311709,
    "pmrarad": 9.25606278916666e-7,
    "pmdecrad": -9.25596582e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118841,
    "base": "NN 3742",
    "lum": 0.000541003406863467,
    "ra": 12.694,
    "dec": 48.239719,
    "pmra": 190.92,
    "pmdec": -190.92,
    "rv": 0,
    "Asset Name": "ASTRID 002522",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Afro",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001163,
    "y_1": -0.00002352,
    "z_1": -0.00001401,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118841,
    "Gliese Catalog_1": "NN 3742B"
  },
  {
    "Astrid #": "ASTRID 002523",
    "HYG Star ID": 118879,
    "Gliese Catalog": "NN 3774",
    "Distance in Parsecs": 22.7273,
    "mag": 11.69,
    "Absolute Visual Magnitude": 9.907,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.729491,
    "y": -6.879138,
    "z": 8.941746,
    "vx": 0.00000637,
    "vy": -0.00002806,
    "vz": -0.00000754,
    "rarad": 3.47707917627132,
    "decrad": 0.40436644059815,
    "pmrarad": 0.00000125828542651388,
    "pmdecrad": -3.60808037e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 118879,
    "lum": 0.009488553267278,
    "ra": 13.281464,
    "dec": 23.16849,
    "pmra": 259.54,
    "pmdec": -74.42,
    "rv": 0,
    "Asset Name": "ASTRID 002523",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Braided Bun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": 0.00000637,
    "y_1": -0.00002806,
    "z_1": -0.00000754,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118879,
    "Gliese Catalog_1": "NN 3774"
  },
  {
    "Astrid #": "ASTRID 002524",
    "HYG Star ID": 118883,
    "Gliese Catalog": "NN 3778",
    "Distance in Parsecs": 22.7273,
    "mag": 11.9,
    "Absolute Visual Magnitude": 10.117,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.256243,
    "y": -7.904057,
    "z": 1.492719,
    "vx": -0.0000194,
    "vy": 0.00005141,
    "vz": -0.00000393,
    "rarad": 3.49759589686061,
    "decrad": 0.0657268504403,
    "pmrarad": -0.00000241786278766666,
    "pmdecrad": -1.73311194e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 118883,
    "lum": 0.00781987840389495,
    "ra": 13.359832,
    "dec": 3.765871,
    "pmra": -498.72,
    "pmdec": -35.75,
    "rv": 0,
    "Asset Name": "ASTRID 002524",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Happy Vamp",
    "Hair": "Unkempt",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.0000194,
    "y_1": 0.00005141,
    "z_1": -0.00000393,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118883,
    "Gliese Catalog_1": "NN 3778"
  },
  {
    "Astrid #": "ASTRID 002525",
    "HYG Star ID": 118970,
    "Gliese Catalog": "GJ 2108",
    "Distance in Parsecs": 22.7273,
    "mag": 13.75,
    "Absolute Visual Magnitude": 11.967,
    "Spectral Type": "DA6",
    "Color Index": 0.25,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -2.694032,
    "y": -2.106806,
    "z": -22.471505,
    "vx": 0.0000127,
    "vy": 0.0000395,
    "vz": -0.00006523,
    "rarad": 3.80556711013092,
    "decrad": -1.4197623635402,
    "pmrarad": -0.00000102421738154166,
    "pmdecrad": -0.000001926290804,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118970,
    "lum": 0.00142298394457933,
    "ra": 14.536196,
    "dec": -81.346391,
    "pmra": -211.26,
    "pmdec": -397.33,
    "rv": 58,
    "Asset Name": "ASTRID 002525",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Undercut",
    "Outfit": "Warlock",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000127,
    "y_1": 0.0000395,
    "z_1": -0.00006523,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 118970,
    "Gliese Catalog_1": "GJ 2108"
  },
  {
    "Astrid #": "ASTRID 002526",
    "HYG Star ID": 119087,
    "Gliese Catalog": "NN 3957",
    "Distance in Parsecs": 22.7273,
    "mag": 9.58,
    "Absolute Visual Magnitude": 7.797,
    "Spectral Type": "K5",
    "Color Index": 1.25,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.610233,
    "y": -20.973612,
    "z": -1.581657,
    "vx": -0.0000151,
    "vy": 0.00000636,
    "vz": -0.00000218,
    "rarad": 4.32284079195098,
    "decrad": -0.06964911086685,
    "pmrarad": -7.2082098025e-7,
    "pmdecrad": -9.6177337e-8,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 119087,
    "lum": 0.0662521535902582,
    "ra": 16.512036,
    "dec": -3.9906,
    "pmra": -148.68,
    "pmdec": -19.84,
    "rv": 0,
    "Asset Name": "ASTRID 002526",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Good Boy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.0000151,
    "y_1": 0.00000636,
    "z_1": -0.00000218,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119087,
    "Gliese Catalog_1": "NN 3957"
  },
  {
    "Astrid #": "ASTRID 002527",
    "HYG Star ID": 119225,
    "Gliese Catalog": "NN 4064",
    "Distance in Parsecs": 22.7273,
    "mag": 11.78,
    "Absolute Visual Magnitude": 9.997,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.611916,
    "y": -16.823619,
    "z": 15.055695,
    "vx": 0.00004158,
    "vy": 0.000004,
    "vz": -0.00000273,
    "rarad": 4.86638156510938,
    "decrad": 0.7240842687279,
    "pmrarad": 0.00000183526218774305,
    "pmdecrad": -1.60565442e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 119225,
    "lum": 0.00873373479142319,
    "ra": 18.588208,
    "dec": 41.486973,
    "pmra": 378.55,
    "pmdec": -33.12,
    "rv": 0,
    "Asset Name": "ASTRID 002527",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Bangs",
    "Outfit": "River Spirit",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00004158,
    "y_1": 0.000004,
    "z_1": -0.00000273,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119225,
    "Gliese Catalog_1": "NN 4064"
  },
  {
    "Astrid #": "ASTRID 002528",
    "HYG Star ID": 119361,
    "Gliese Catalog": "NN 4155",
    "Distance in Parsecs": 22.7273,
    "mag": 11.94,
    "Absolute Visual Magnitude": 10.157,
    "Spectral Type": "M0  e",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.457453,
    "y": -17.076625,
    "z": -3.987627,
    "vx": 0.00011952,
    "vy": 0.00008427,
    "vz": 0.00007213,
    "rarad": 5.41491387690014,
    "decrad": -0.1763683023905,
    "pmrarad": 0.00000641015800293749,
    "pmdecrad": 0.00000322397219,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 119361,
    "lum": 0.00753702576688425,
    "ra": 20.683447,
    "dec": -10.105159,
    "pmra": 1322.19,
    "pmdec": 664.99,
    "rv": 0,
    "Asset Name": "ASTRID 002528",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "High Fade",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00011952,
    "y_1": 0.00008427,
    "z_1": 0.00007213,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119361,
    "Gliese Catalog_1": "NN 4155"
  },
  {
    "Astrid #": "ASTRID 002529",
    "HYG Star ID": 119462,
    "Gliese Catalog": "NN 4246",
    "Distance in Parsecs": 22.7273,
    "mag": 12.76,
    "Absolute Visual Magnitude": 10.977,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.636069,
    "y": -8.504291,
    "z": 15.16551,
    "vx": 0.00003025,
    "vy": -0.00000222,
    "vz": -0.00003043,
    "rarad": 5.75680602887239,
    "decrad": 0.7305530253941,
    "pmrarad": 5.84297447805555e-7,
    "pmdecrad": -0.000001798232118,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119462,
    "lum": 0.00354160400426461,
    "ra": 21.989379,
    "dec": 41.857605,
    "pmra": 120.52,
    "pmdec": -370.91,
    "rv": 0,
    "Asset Name": "ASTRID 002529",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003025,
    "y_1": -0.00000222,
    "z_1": -0.00003043,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119462,
    "Gliese Catalog_1": "NN 4246"
  },
  {
    "Astrid #": "ASTRID 002530",
    "HYG Star ID": 119467,
    "Gliese Catalog": "NN 4251",
    "Distance in Parsecs": 22.7273,
    "mag": 13.41,
    "Absolute Visual Magnitude": 11.627,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.595241,
    "y": -4.238788,
    "z": 20.997028,
    "vx": 0.00005142,
    "vy": 0.00003186,
    "vz": -0.00001216,
    "rarad": 5.7739685417074,
    "decrad": 1.17806738777675,
    "pmrarad": 0.00000232715414803472,
    "pmdecrad": -0.000001398304465,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119467,
    "lum": 0.00194625615936123,
    "ra": 22.054935,
    "dec": 67.498289,
    "pmra": 480.01,
    "pmdec": -288.42,
    "rv": 0,
    "Asset Name": "ASTRID 002530",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Goddess",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005142,
    "y_1": 0.00003186,
    "z_1": -0.00001216,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119467,
    "Gliese Catalog_1": "NN 4251"
  },
  {
    "Astrid #": "ASTRID 002531",
    "HYG Star ID": 119471,
    "Gliese Catalog": "NN 4256",
    "Distance in Parsecs": 22.7273,
    "mag": 12.69,
    "Absolute Visual Magnitude": 10.907,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.45668,
    "y": -8.391461,
    "z": 14.394603,
    "vx": 0.00003148,
    "vy": -0.00001032,
    "vz": -0.0000398,
    "rarad": 5.78580421581751,
    "decrad": 0.6858897098824,
    "pmrarad": 2.61896350236111e-7,
    "pmdecrad": -0.000002263522352,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119471,
    "lum": 0.00377746109388218,
    "ra": 22.100144,
    "dec": 39.298586,
    "pmra": 54.02,
    "pmdec": -466.88,
    "rv": 0,
    "Asset Name": "ASTRID 002531",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Mohawk",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003148,
    "y_1": -0.00001032,
    "z_1": -0.0000398,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119471,
    "Gliese Catalog_1": "NN 4256"
  },
  {
    "Astrid #": "ASTRID 002532",
    "HYG Star ID": 119551,
    "Gliese Catalog": "GJ 2154B",
    "Distance in Parsecs": 22.7273,
    "mag": 13.8,
    "Absolute Visual Magnitude": 12.017,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.978257,
    "y": -4.248512,
    "z": -7.641534,
    "vx": 0.0000115,
    "vy": 0.00004798,
    "vz": 0.00000488,
    "rarad": 6.08336137333322,
    "decrad": -0.3429078619095,
    "pmrarad": 0.00000216968666459027,
    "pmdecrad": 2.2804181e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 114357,
    "base": "GJ 2154",
    "lum": 0.0013589391171008,
    "ra": 23.236729,
    "dec": -19.647173,
    "pmra": 447.53,
    "pmdec": 47.04,
    "rv": 0,
    "Asset Name": "ASTRID 002532",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Fired Up",
    "Hair": "Braided Bun",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000115,
    "y_1": 0.00004798,
    "z_1": 0.00000488,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119551,
    "Gliese Catalog_1": "GJ 2154B"
  },
  {
    "Astrid #": "ASTRID 002533",
    "HYG Star ID": 119570,
    "Gliese Catalog": "NN 4341",
    "Distance in Parsecs": 22.7273,
    "mag": 12.92,
    "Absolute Visual Magnitude": 11.137,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.434478,
    "y": -2.231717,
    "z": 9.694416,
    "vx": -0.00000832,
    "vy": 0.00000702,
    "vz": 0.00001916,
    "rarad": 6.1744017318891,
    "decrad": 0.44067911119985,
    "pmrarad": 2.67277782090277e-7,
    "pmdecrad": 9.32063997e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119570,
    "lum": 0.00305632828036596,
    "ra": 23.584477,
    "dec": 25.249053,
    "pmra": 55.13,
    "pmdec": 192.25,
    "rv": 0,
    "Asset Name": "ASTRID 002533",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Pixie",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000832,
    "y_1": 0.00000702,
    "z_1": 0.00001916,
    "Distance in Parsecs_1": 22.7273,
    "HYG Star ID_1": 119570,
    "Gliese Catalog_1": "NN 4341"
  },
  {
    "Astrid #": "ASTRID 002534",
    "HYG Star ID": 13224,
    "Hipparcos Catalogue": 13258,
    "Henry Draper Catalogue": 17660,
    "Gliese Catalog": "Gl 114",
    "Distance in Parsecs": 22.7324,
    "mag": 8.87,
    "Absolute Visual Magnitude": 7.087,
    "Spectral Type": "K5",
    "Color Index": 1.194,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.094129,
    "y": 14.827182,
    "z": 6.155124,
    "vx": -0.00003477,
    "vy": 0.00001884,
    "vz": -0.00004889,
    "rarad": 0.744447257269033,
    "decrad": 0.274189728326023,
    "pmrarad": 0.00000164608788959027,
    "pmdecrad": -0.000001918019883,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13224,
    "lum": 0.127408968591911,
    "ra": 2.843579,
    "dec": 15.709914,
    "pmra": 339.53,
    "pmdec": -395.62,
    "rv": -25,
    "Asset Name": "ASTRID 002534",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Fire",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00003477,
    "y_1": 0.00001884,
    "z_1": -0.00004889,
    "Distance in Parsecs_1": 22.7324,
    "HYG Star ID_1": 13224,
    "Hipparcos Catalogue_1": 13258,
    "Henry Draper Catalogue_1": 17660,
    "Gliese Catalog_1": "Gl 114"
  },
  {
    "Astrid #": "ASTRID 002535",
    "HYG Star ID": 109191,
    "Hipparcos Catalogue": 109537,
    "Distance in Parsecs": 22.7376,
    "mag": 11.11,
    "Absolute Visual Magnitude": 9.326,
    "Spectral Type": "M2",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.261935,
    "y": -7.83662,
    "z": 14.921768,
    "vx": -0.00002533,
    "vy": -0.00001268,
    "vz": 0.00001924,
    "rarad": 5.80881886766164,
    "decrad": 0.715850789992355,
    "pmrarad": -0.00000100506724115972,
    "pmdecrad": 0.000001121713412,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 109191,
    "lum": 0.0162031704273999,
    "var_min": 11.179,
    "var_max": 11.019,
    "ra": 22.188054,
    "dec": 41.015229,
    "pmra": -207.31,
    "pmdec": 231.37,
    "rv": 0,
    "Asset Name": "ASTRID 002535",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Bob",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": -0.00002533,
    "y_1": -0.00001268,
    "z_1": 0.00001924,
    "Distance in Parsecs_1": 22.7376,
    "HYG Star ID_1": 109191,
    "Hipparcos Catalogue_1": 109537
  },
  {
    "Astrid #": "ASTRID 002536",
    "HYG Star ID": 48310,
    "Hipparcos Catalogue": 48447,
    "Gliese Catalog": "NN 3570",
    "Distance in Parsecs": 22.748,
    "mag": 10.57,
    "Absolute Visual Magnitude": 8.785,
    "Spectral Type": "K4",
    "Color Index": 1.429,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.296916,
    "y": 11.981773,
    "z": 1.242148,
    "vx": -0.00000804,
    "vy": 0.00001262,
    "vz": -0.00000312,
    "rarad": 2.5859352022094,
    "decrad": 0.0546316005002275,
    "pmrarad": -2.85118925534722e-7,
    "pmdecrad": -1.69199974e-7,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 48310,
    "lum": 0.0266685866452147,
    "var_min": 10.637,
    "var_max": 10.477,
    "ra": 9.877545,
    "dec": 3.13016,
    "pmra": -58.81,
    "pmdec": -34.9,
    "rv": 13,
    "Asset Name": "ASTRID 002536",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Infatuated",
    "Hair": "Medium Bob",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": -0.00000804,
    "y_1": 0.00001262,
    "z_1": -0.00000312,
    "Distance in Parsecs_1": 22.748,
    "HYG Star ID_1": 48310,
    "Hipparcos Catalogue_1": 48447,
    "Gliese Catalog_1": "NN 3570"
  },
  {
    "Astrid #": "ASTRID 002537",
    "HYG Star ID": 9807,
    "Hipparcos Catalogue": 9829,
    "Henry Draper Catalogue": 12846,
    "Distance in Parsecs": 22.7739,
    "mag": 6.89,
    "Absolute Visual Magnitude": 5.103,
    "Spectral Type": "G2V",
    "Color Index": 0.662,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.668943,
    "y": 10.881068,
    "z": 9.384099,
    "vx": 0.00000533,
    "vy": 0.00000411,
    "vz": -0.0000148,
    "rarad": 0.551978069223025,
    "decrad": 0.424708299720862,
    "pmrarad": 3.08826314513888e-8,
    "pmdecrad": -7.1340333e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 9807,
    "lum": 0.79213645415285,
    "ra": 2.108401,
    "dec": 24.333993,
    "pmra": 6.37,
    "pmdec": -147.15,
    "rv": 0,
    "Asset Name": "ASTRID 002537",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Bad Dreams",
    "Hair": "Mohawk",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00000533,
    "y_1": 0.00000411,
    "z_1": -0.0000148,
    "Distance in Parsecs_1": 22.7739,
    "HYG Star ID_1": 9807,
    "Hipparcos Catalogue_1": 9829,
    "Henry Draper Catalogue_1": 12846
  },
  {
    "Astrid #": "ASTRID 002538",
    "HYG Star ID": 118030,
    "Gliese Catalog": "NN 3067",
    "Distance in Parsecs": 22.779,
    "mag": 10.96,
    "Absolute Visual Magnitude": 9.172,
    "Color Index": 0.96,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.482997,
    "y": 4.887238,
    "z": -10.742932,
    "vx": -0.0000088,
    "vy": 0.00001045,
    "vz": -0.00001121,
    "rarad": 0.245771437096228,
    "decrad": -0.49112203757285,
    "pmrarad": 5.38821924569444e-7,
    "pmdecrad": -5.57991457e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 118030,
    "lum": 0.0186723938838294,
    "ra": 0.938778,
    "dec": -28.13922,
    "pmra": 111.14,
    "pmdec": -115.09,
    "rv": 0,
    "Asset Name": "ASTRID 002538",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Curly",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.0000088,
    "y_1": 0.00001045,
    "z_1": -0.00001121,
    "Distance in Parsecs_1": 22.779,
    "HYG Star ID_1": 118030,
    "Gliese Catalog_1": "NN 3067"
  },
  {
    "Astrid #": "ASTRID 002539",
    "HYG Star ID": 118650,
    "Gliese Catalog": "NN 3569",
    "Distance in Parsecs": 22.779,
    "mag": 15.2,
    "Absolute Visual Magnitude": 13.412,
    "Spectral Type": "DQ6",
    "Color Index": 0.13,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -11.502705,
    "y": 7.308389,
    "z": 18.252618,
    "vx": 0.00001364,
    "vy": 0.00002522,
    "vz": -0.0000015,
    "rarad": 2.57561833920429,
    "decrad": 0.929450767061,
    "pmrarad": -0.00000125571591400694,
    "pmdecrad": -1.0985878e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118650,
    "lum": 0.000376010523668495,
    "ra": 9.838137,
    "dec": 53.253606,
    "pmra": -259.01,
    "pmdec": -22.66,
    "rv": 0,
    "Asset Name": "ASTRID 002539",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Firm",
    "Hair": "High Fade",
    "Outfit": "Sharky",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001364,
    "y_1": 0.00002522,
    "z_1": -0.0000015,
    "Distance in Parsecs_1": 22.779,
    "HYG Star ID_1": 118650,
    "Gliese Catalog_1": "NN 3569"
  },
  {
    "Astrid #": "ASTRID 002540",
    "HYG Star ID": 1276,
    "Hipparcos Catalogue": 1276,
    "Gliese Catalog": "NN 3020",
    "Distance in Parsecs": 22.7842,
    "mag": 11.58,
    "Absolute Visual Magnitude": 9.792,
    "Spectral Type": "M5",
    "Color Index": 1.647,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.131259,
    "y": 1.060284,
    "z": -17.001193,
    "vx": -0.00001925,
    "vy": -0.00000647,
    "vz": -0.00001754,
    "rarad": 0.0699591010196365,
    "decrad": -0.842310766879703,
    "pmrarad": -2.24226327256944e-7,
    "pmdecrad": -0.000001156329109,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 1276,
    "lum": 0.0105487257132343,
    "var_min": 11.71,
    "var_max": 11.47,
    "ra": 0.267224,
    "dec": -48.260852,
    "pmra": -46.25,
    "pmdec": -238.51,
    "rv": 0,
    "Asset Name": "ASTRID 002540",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Windy",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00001925,
    "y_1": -0.00000647,
    "z_1": -0.00001754,
    "Distance in Parsecs_1": 22.7842,
    "HYG Star ID_1": 1276,
    "Hipparcos Catalogue_1": 1276,
    "Gliese Catalog_1": "NN 3020"
  },
  {
    "Astrid #": "ASTRID 002541",
    "HYG Star ID": 50242,
    "Hipparcos Catalogue": 50384,
    "Henry Draper Catalogue": 89125,
    "Harvard Revised Catalogue": 4039,
    "Gliese Catalog": "Gl 387A",
    "Distance in Parsecs": 22.805,
    "mag": 5.81,
    "Absolute Visual Magnitude": 4.02,
    "Spectral Type": "F8Vw",
    "Color Index": 0.5,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -18.90255,
    "y": 9.092886,
    "z": 8.949656,
    "vx": -0.00001578,
    "vy": 0.00005829,
    "vz": 0.00000517,
    "rarad": 2.69322916474347,
    "decrad": 0.403279610811225,
    "pmrarad": -0.00000200368646036805,
    "pmdecrad": -4.7133586e-7,
    "flam": 39,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 50242,
    "base": "Gl 387",
    "lum": 2.14783047413053,
    "bf": "39    Leo",
    "ra": 10.287378,
    "dec": 23.10622,
    "pmra": -413.29,
    "pmdec": -97.22,
    "rv": 37.5,
    "Asset Name": "ASTRID 002541",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Ram Horns",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00001578,
    "y_1": 0.00005829,
    "z_1": 0.00000517,
    "Distance in Parsecs_1": 22.805,
    "HYG Star ID_1": 50242,
    "Hipparcos Catalogue_1": 50384,
    "Henry Draper Catalogue_1": 89125,
    "Harvard Revised Catalogue_1": 4039,
    "Gliese Catalog_1": "Gl 387A"
  },
  {
    "Astrid #": "ASTRID 002542",
    "HYG Star ID": 56116,
    "Hipparcos Catalogue": 56284,
    "Distance in Parsecs": 22.805,
    "mag": 11.45,
    "Absolute Visual Magnitude": 9.66,
    "Color Index": 1.474,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -21.653236,
    "y": 2.628319,
    "z": -6.655624,
    "vx": 0.00000523,
    "vy": -0.00001953,
    "vz": -0.00002474,
    "rarad": 3.02080095930135,
    "decrad": -0.296159822560105,
    "pmrarad": 8.22777297270833e-7,
    "pmdecrad": -0.000001134464012,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 56116,
    "lum": 0.0119124200802737,
    "var_min": 11.542,
    "var_max": 11.352,
    "ra": 11.53861,
    "dec": -16.968708,
    "pmra": 169.71,
    "pmdec": -234,
    "rv": 0,
    "Asset Name": "ASTRID 002542",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Braid",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00000523,
    "y_1": -0.00001953,
    "z_1": -0.00002474,
    "Distance in Parsecs_1": 22.805,
    "HYG Star ID_1": 56116,
    "Hipparcos Catalogue_1": 56284
  },
  {
    "Astrid #": "ASTRID 002543",
    "HYG Star ID": 53423,
    "Hipparcos Catalogue": 53580,
    "Gliese Catalog": "Gl 406.1",
    "Distance in Parsecs": 22.8206,
    "mag": 10.28,
    "Absolute Visual Magnitude": 8.488,
    "Spectral Type": "M0",
    "Color Index": 1.352,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.66316,
    "y": 2.138337,
    "z": 21.388926,
    "vx": 0.00002193,
    "vy": 0.00006624,
    "vz": 0.00000888,
    "rarad": 2.86952327464585,
    "decrad": 1.21469000164706,
    "pmrarad": -0.0000030543261875,
    "pmdecrad": 2.72416807e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 53423,
    "lum": 0.0350590383932612,
    "var_min": 10.334,
    "var_max": 10.234,
    "ra": 10.960772,
    "dec": 69.596611,
    "pmra": -630,
    "pmdec": 56.19,
    "rv": 7,
    "Asset Name": "ASTRID 002543",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "High Bun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00002193,
    "y_1": 0.00006624,
    "z_1": 0.00000888,
    "Distance in Parsecs_1": 22.8206,
    "HYG Star ID_1": 53423,
    "Hipparcos Catalogue_1": 53580,
    "Gliese Catalog_1": "Gl 406.1"
  },
  {
    "Astrid #": "ASTRID 002544",
    "HYG Star ID": 89196,
    "Hipparcos Catalogue": 89474,
    "Henry Draper Catalogue": 168009,
    "Harvard Revised Catalogue": 6847,
    "Gliese Catalog": "Gl 708.4",
    "Distance in Parsecs": 22.8206,
    "mag": 6.3,
    "Absolute Visual Magnitude": 4.508,
    "Spectral Type": "G2V",
    "Color Index": 0.641,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.089365,
    "y": -16.040183,
    "z": 16.195033,
    "vx": -0.00001106,
    "vy": 0.00003712,
    "vz": -0.00005602,
    "rarad": 4.78020101041625,
    "decrad": 0.789051447015062,
    "pmrarad": -3.73209571291666e-7,
    "pmdecrad": -5.5462685e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 89196,
    "lum": 1.37025059712424,
    "ra": 18.259023,
    "dec": 45.209318,
    "pmra": -76.98,
    "pmdec": -114.4,
    "rv": -64.9,
    "Asset Name": "ASTRID 002544",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Battle Ready",
    "Hair": "Shinobi",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00001106,
    "y_1": 0.00003712,
    "z_1": -0.00005602,
    "Distance in Parsecs_1": 22.8206,
    "HYG Star ID_1": 89196,
    "Hipparcos Catalogue_1": 89474,
    "Henry Draper Catalogue_1": 168009,
    "Harvard Revised Catalogue_1": 6847,
    "Gliese Catalog_1": "Gl 708.4"
  },
  {
    "Astrid #": "ASTRID 002545",
    "HYG Star ID": 118268,
    "Gliese Catalog": "Gl 157.1",
    "Distance in Parsecs": 22.8311,
    "mag": 12.62,
    "Absolute Visual Magnitude": 10.827,
    "Spectral Type": "dM4",
    "Color Index": 1.49,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 10.262925,
    "y": 17.755945,
    "z": 10.043775,
    "vx": -0.0000215,
    "vy": 0.00012519,
    "vz": 0.00001927,
    "rarad": 1.04669073388722,
    "decrad": 0.455402477393,
    "pmrarad": 0.00000356027774452777,
    "pmdecrad": -0.00000112255214,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118268,
    "lum": 0.00406630546340428,
    "ra": 3.998064,
    "dec": 26.09264,
    "pmra": 734.36,
    "pmdec": -231.54,
    "rv": 94,
    "Asset Name": "ASTRID 002545",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "High Bun",
    "Outfit": "Star Knight",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000215,
    "y_1": 0.00012519,
    "z_1": 0.00001927,
    "Distance in Parsecs_1": 22.8311,
    "HYG Star ID_1": 118268,
    "Gliese Catalog_1": "Gl 157.1"
  },
  {
    "Astrid #": "ASTRID 002546",
    "HYG Star ID": 86347,
    "Hipparcos Catalogue": 86614,
    "Henry Draper Catalogue": 162003,
    "Harvard Revised Catalogue": 6636,
    "Gliese Catalog": "Gl 694.1A",
    "Distance in Parsecs": 22.8363,
    "mag": 4.57,
    "Absolute Visual Magnitude": 2.777,
    "Spectral Type": "F5IV-V",
    "Color Index": 0.434,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.551088,
    "y": -6.978598,
    "z": 21.736754,
    "vx": 0.00000104,
    "vy": -0.00002429,
    "vz": -0.00002249,
    "rarad": 4.63358172740384,
    "decrad": 1.25923486803367,
    "pmrarad": 1.28960439027777e-7,
    "pmdecrad": -0.000001307881866,
    "bayer": "Psi-1",
    "flam": 31,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 86347,
    "base": "Gl 694.1",
    "lum": 6.74838730870756,
    "bf": "31Psi1Dra",
    "ra": 17.698978,
    "dec": 72.148843,
    "pmra": 26.6,
    "pmdec": -269.77,
    "rv": -13.7,
    "Asset Name": "ASTRID 002546",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Afro",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000104,
    "y_1": -0.00002429,
    "z_1": -0.00002249,
    "Distance in Parsecs_1": 22.8363,
    "HYG Star ID_1": 86347,
    "Hipparcos Catalogue_1": 86614,
    "Henry Draper Catalogue_1": 162003,
    "Harvard Revised Catalogue_1": 6636,
    "Gliese Catalog_1": "Gl 694.1A"
  },
  {
    "Astrid #": "ASTRID 002547",
    "HYG Star ID": 49934,
    "Hipparcos Catalogue": 50075,
    "Henry Draper Catalogue": 88742,
    "Harvard Revised Catalogue": 4013,
    "Gliese Catalog": "Gl 385.1",
    "Distance in Parsecs": 22.8467,
    "mag": 6.38,
    "Absolute Visual Magnitude": 4.586,
    "Spectral Type": "G0V",
    "Color Index": 0.592,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.119843,
    "y": 8.590536,
    "z": -12.454016,
    "vx": -0.00001722,
    "vy": 0.00005446,
    "vz": -0.00001738,
    "rarad": 2.67651851121711,
    "decrad": -0.576512309499129,
    "pmrarad": -0.0000017928894720625,
    "pmdecrad": 3.12171528e-7,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 49934,
    "lum": 1.27526370645972,
    "ra": 10.223548,
    "dec": -33.031722,
    "pmra": -369.81,
    "pmdec": 64.39,
    "rv": 41.9,
    "Asset Name": "ASTRID 002547",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Honest Lad",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": -0.00001722,
    "y_1": 0.00005446,
    "z_1": -0.00001738,
    "Distance in Parsecs_1": 22.8467,
    "HYG Star ID_1": 49934,
    "Hipparcos Catalogue_1": 50075,
    "Henry Draper Catalogue_1": 88742,
    "Harvard Revised Catalogue_1": 4013,
    "Gliese Catalog_1": "Gl 385.1"
  },
  {
    "Astrid #": "ASTRID 002548",
    "HYG Star ID": 55986,
    "Hipparcos Catalogue": 56153,
    "Henry Draper Catalogue": 304391,
    "Gliese Catalog": "Gl 429.4",
    "Distance in Parsecs": 22.8571,
    "mag": 8.34,
    "Absolute Visual Magnitude": 6.545,
    "Spectral Type": "K4V",
    "Color Index": 1.046,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.301997,
    "y": 1.587729,
    "z": -19.198591,
    "vx": 0.00000593,
    "vy": 0.00006123,
    "vz": 0.00000455,
    "rarad": 3.01325931262933,
    "decrad": -0.99717417702202,
    "pmrarad": -0.00000269032807613888,
    "pmdecrad": 1.79768912e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 55986,
    "lum": 0.209893988362352,
    "ra": 11.509803,
    "dec": -57.133872,
    "pmra": -554.92,
    "pmdec": 37.08,
    "rv": -2.7,
    "Asset Name": "ASTRID 002548",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Shocked",
    "Hair": "Fire",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000593,
    "y_1": 0.00006123,
    "z_1": 0.00000455,
    "Distance in Parsecs_1": 22.8571,
    "HYG Star ID_1": 55986,
    "Hipparcos Catalogue_1": 56153,
    "Henry Draper Catalogue_1": 304391,
    "Gliese Catalog_1": "Gl 429.4"
  },
  {
    "Astrid #": "ASTRID 002549",
    "HYG Star ID": 42581,
    "Hipparcos Catalogue": 42697,
    "Henry Draper Catalogue": 74385,
    "Gliese Catalog": "Gl 319.1A",
    "Distance in Parsecs": 22.8676,
    "mag": 8.11,
    "Absolute Visual Magnitude": 6.314,
    "Spectral Type": "K2V",
    "Color Index": 0.904,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.881089,
    "y": 12.726026,
    "z": -15.575198,
    "vx": 0.00001568,
    "vy": 0.0000276,
    "vz": -0.00002324,
    "rarad": 2.27820545512321,
    "decrad": -0.749260036401013,
    "pmrarad": -0.00000130570020447222,
    "pmdecrad": -4.22902973e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 42581,
    "base": "Gl 319.1",
    "lum": 0.259656979027378,
    "ra": 8.702104,
    "dec": -42.929438,
    "pmra": -269.32,
    "pmdec": -87.23,
    "rv": 23.2,
    "Asset Name": "ASTRID 002549",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Little Crown",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00001568,
    "y_1": 0.0000276,
    "z_1": -0.00002324,
    "Distance in Parsecs_1": 22.8676,
    "HYG Star ID_1": 42581,
    "Hipparcos Catalogue_1": 42697,
    "Henry Draper Catalogue_1": 74385,
    "Gliese Catalog_1": "Gl 319.1A"
  },
  {
    "Astrid #": "ASTRID 002550",
    "HYG Star ID": 80369,
    "Hipparcos Catalogue": 80612,
    "Distance in Parsecs": 22.8728,
    "mag": 10.82,
    "Absolute Visual Magnitude": 9.023,
    "Spectral Type": "M1V:",
    "Color Index": 1.448,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.84148,
    "y": -20.716978,
    "z": -3.974928,
    "vx": -0.00001857,
    "vy": 0.00001474,
    "vz": -0.0000355,
    "rarad": 4.30901631990074,
    "decrad": -0.174670868706947,
    "pmrarad": -9.99782772041666e-7,
    "pmdecrad": -0.000001576080794,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 80369,
    "lum": 0.0214190399072212,
    "var_min": 10.897,
    "var_max": 10.767,
    "ra": 16.45923,
    "dec": -10.007904,
    "pmra": -206.22,
    "pmdec": -325.09,
    "rv": 0,
    "Asset Name": "ASTRID 002550",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Charmer",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001857,
    "y_1": 0.00001474,
    "z_1": -0.0000355,
    "Distance in Parsecs_1": 22.8728,
    "HYG Star ID_1": 80369,
    "Hipparcos Catalogue_1": 80612
  },
  {
    "Astrid #": "ASTRID 002551",
    "HYG Star ID": 16404,
    "Hipparcos Catalogue": 16445,
    "Gliese Catalog": "Gl 143.3",
    "Distance in Parsecs": 22.8781,
    "mag": 12.26,
    "Absolute Visual Magnitude": 10.463,
    "Spectral Type": "M3",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.357052,
    "y": 17.690953,
    "z": 5.659223,
    "vx": 0.0000078,
    "vy": 0.0000173,
    "vz": -0.00007249,
    "rarad": 0.92408908518758,
    "decrad": 0.249958947357323,
    "pmrarad": 1.83792866298611e-7,
    "pmdecrad": -0.000003270359163,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 16404,
    "lum": 0.00568591024698855,
    "var_min": 12.374,
    "var_max": 12.114,
    "ra": 3.52976,
    "dec": 14.321593,
    "pmra": 37.91,
    "pmdec": -674.56,
    "rv": 0,
    "Asset Name": "ASTRID 002551",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Mortified",
    "Hair": "Honest Lad",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000078,
    "y_1": 0.0000173,
    "z_1": -0.00007249,
    "Distance in Parsecs_1": 22.8781,
    "HYG Star ID_1": 16404,
    "Hipparcos Catalogue_1": 16445,
    "Gliese Catalog_1": "Gl 143.3"
  },
  {
    "Astrid #": "ASTRID 002552",
    "HYG Star ID": 118179,
    "Gliese Catalog": "Gl 118.2B",
    "Distance in Parsecs": 22.8781,
    "mag": 9.8,
    "Absolute Visual Magnitude": 8.003,
    "Spectral Type": "dM0",
    "Color Index": 1.4,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 14.702758,
    "y": 14.154131,
    "z": 10.342151,
    "vx": 0.00000405,
    "vy": 0.00004445,
    "vz": -0.00000549,
    "rarad": 0.766380687961729,
    "decrad": 0.4690368714955,
    "pmrarad": 0.00000127699923458333,
    "pmdecrad": -8.8095009e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 2,
    "comp_primary": 13608,
    "base": "Gl 118.2",
    "lum": 0.054802453217685,
    "ra": 2.927359,
    "dec": 26.873833,
    "pmra": 263.4,
    "pmdec": -181.71,
    "rv": 27,
    "Asset Name": "ASTRID 002552",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Grecian",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00000405,
    "y_1": 0.00004445,
    "z_1": -0.00000549,
    "Distance in Parsecs_1": 22.8781,
    "HYG Star ID_1": 118179,
    "Gliese Catalog_1": "Gl 118.2B"
  },
  {
    "Astrid #": "ASTRID 002553",
    "HYG Star ID": 118180,
    "Gliese Catalog": "Gl 118.2C",
    "Distance in Parsecs": 22.8781,
    "mag": 13.86,
    "Absolute Visual Magnitude": 12.063,
    "Spectral Type": "m",
    "Color Index": 1.58,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.705185,
    "y": 14.150263,
    "z": 10.34094,
    "vx": -0.00001353,
    "vy": 0.00002733,
    "vz": -0.00001816,
    "rarad": 0.766161705105042,
    "decrad": 0.469008062678949,
    "pmrarad": 0.00000127084210084027,
    "pmdecrad": -8.89846421e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 13608,
    "base": "Gl 118.2",
    "lum": 0.00130256678613668,
    "ra": 2.926522,
    "dec": 26.872183,
    "pmra": 262.13,
    "pmdec": -183.54,
    "rv": 0,
    "Asset Name": "ASTRID 002553",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Prince",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001353,
    "y_1": 0.00002733,
    "z_1": -0.00001816,
    "Distance in Parsecs_1": 22.8781,
    "HYG Star ID_1": 118180,
    "Gliese Catalog_1": "Gl 118.2C"
  },
  {
    "Astrid #": "ASTRID 002554",
    "HYG Star ID": 78494,
    "Hipparcos Catalogue": 78734,
    "Distance in Parsecs": 22.899,
    "mag": 10.08,
    "Absolute Visual Magnitude": 8.281,
    "Color Index": 1.345,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.275903,
    "y": -16.800841,
    "z": -12.492139,
    "vx": -0.00002097,
    "vy": 0.00001627,
    "vz": -0.00000631,
    "rarad": 4.20792959011325,
    "decrad": -0.577023889126634,
    "pmrarad": -0.00000114512991347222,
    "pmdecrad": -3.29043044e-7,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 78494,
    "lum": 0.0424228654922949,
    "ra": 16.073107,
    "dec": -33.061034,
    "pmra": -236.2,
    "pmdec": -67.87,
    "rv": 0,
    "Asset Name": "ASTRID 002554",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "High Bun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00002097,
    "y_1": 0.00001627,
    "z_1": -0.00000631,
    "Distance in Parsecs_1": 22.899,
    "HYG Star ID_1": 78494,
    "Hipparcos Catalogue_1": 78734
  },
  {
    "Astrid #": "ASTRID 002555",
    "HYG Star ID": 109181,
    "Hipparcos Catalogue": 109527,
    "Henry Draper Catalogue": 210667,
    "Gliese Catalog": "Gl 850",
    "Distance in Parsecs": 22.899,
    "mag": 7.23,
    "Absolute Visual Magnitude": 5.431,
    "Spectral Type": "K0",
    "Color Index": 0.812,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.423151,
    "y": -8.440484,
    "z": 13.542343,
    "vx": 0.00000248,
    "vy": 0.0000019,
    "vz": -0.00003347,
    "rarad": 5.80844917864998,
    "decrad": 0.63279232694387,
    "pmrarad": 1.23045712125e-7,
    "pmdecrad": -0.000001213149272,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 109181,
    "lum": 0.585598559849287,
    "var": "V446",
    "var_min": 7.251,
    "var_max": 7.211,
    "ra": 22.186642,
    "dec": 36.25633,
    "pmra": 25.38,
    "pmdec": -250.23,
    "rv": -18.3,
    "Asset Name": "ASTRID 002555",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "Greasy",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": 0.00000248,
    "y_1": 0.0000019,
    "z_1": -0.00003347,
    "Distance in Parsecs_1": 22.899,
    "HYG Star ID_1": 109181,
    "Hipparcos Catalogue_1": 109527,
    "Henry Draper Catalogue_1": 210667,
    "Gliese Catalog_1": "Gl 850"
  },
  {
    "Astrid #": "ASTRID 002556",
    "HYG Star ID": 81016,
    "Hipparcos Catalogue": 81262,
    "Henry Draper Catalogue": 149192,
    "Distance in Parsecs": 22.9043,
    "mag": 8.78,
    "Absolute Visual Magnitude": 6.98,
    "Spectral Type": "K4V",
    "Color Index": 1.096,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.861612,
    "y": -12.63845,
    "z": -18.472718,
    "vx": -0.00000478,
    "vy": 0.00002448,
    "vz": -0.00001549,
    "rarad": 4.34517247915119,
    "decrad": -0.938237639281441,
    "pmrarad": -5.78382720902777e-7,
    "pmdecrad": -0.000001143917879,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 81016,
    "lum": 0.140604752412991,
    "ra": 16.597336,
    "dec": -53.757057,
    "pmra": -119.3,
    "pmdec": -235.95,
    "rv": 0,
    "Asset Name": "ASTRID 002556",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Audiohead",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -0.00000478,
    "y_1": 0.00002448,
    "z_1": -0.00001549,
    "Distance in Parsecs_1": 22.9043,
    "HYG Star ID_1": 81016,
    "Hipparcos Catalogue_1": 81262,
    "Henry Draper Catalogue_1": 149192
  },
  {
    "Astrid #": "ASTRID 002557",
    "HYG Star ID": 51103,
    "Hipparcos Catalogue": 51248,
    "Henry Draper Catalogue": 90508,
    "Harvard Revised Catalogue": 4098,
    "Gliese Catalog": "Gl 392A",
    "Distance in Parsecs": 22.9095,
    "mag": 6.42,
    "Absolute Visual Magnitude": 4.62,
    "Spectral Type": "G1V",
    "Color Index": 0.61,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.896453,
    "y": 5.894065,
    "z": 17.233427,
    "vx": -0.00006709,
    "vy": 0.00001874,
    "vz": -0.00006975,
    "rarad": 2.74044747545163,
    "decrad": 0.851457149685296,
    "pmrarad": 3.90565901055555e-7,
    "pmdecrad": -0.000004274893109,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 51103,
    "base": "Gl 392",
    "lum": 1.23594743344451,
    "ra": 10.467738,
    "dec": 48.784901,
    "pmra": 80.56,
    "pmdec": -881.76,
    "rv": -6.8,
    "Asset Name": "ASTRID 002557",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Side Part",
    "Outfit": "Astro",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00006709,
    "y_1": 0.00001874,
    "z_1": -0.00006975,
    "Distance in Parsecs_1": 22.9095,
    "HYG Star ID_1": 51103,
    "Hipparcos Catalogue_1": 51248,
    "Henry Draper Catalogue_1": 90508,
    "Harvard Revised Catalogue_1": 4098,
    "Gliese Catalog_1": "Gl 392A"
  },
  {
    "Astrid #": "ASTRID 002558",
    "HYG Star ID": 58513,
    "Hipparcos Catalogue": 58688,
    "Distance in Parsecs": 22.92,
    "mag": 10.52,
    "Absolute Visual Magnitude": 8.719,
    "Spectral Type": "M0V",
    "Color Index": 1.419,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.058386,
    "y": -0.174864,
    "z": -14.113487,
    "vx": 0.00001695,
    "vy": 0.00001482,
    "vz": -0.00002187,
    "rarad": 3.15127714806832,
    "decrad": -0.663365018542378,
    "pmrarad": -6.39420763284722e-7,
    "pmdecrad": -0.000001210919129,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 58513,
    "lum": 0.0283400100550909,
    "var_min": 10.576,
    "var_max": 10.466,
    "ra": 12.036992,
    "dec": -38.008016,
    "pmra": -131.89,
    "pmdec": -249.77,
    "rv": 0,
    "Asset Name": "ASTRID 002558",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Grecian",
    "Outfit": "Warlock",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001695,
    "y_1": 0.00001482,
    "z_1": -0.00002187,
    "Distance in Parsecs_1": 22.92,
    "HYG Star ID_1": 58513,
    "Hipparcos Catalogue_1": 58688
  },
  {
    "Astrid #": "ASTRID 002559",
    "HYG Star ID": 89071,
    "Hipparcos Catalogue": 89348,
    "Henry Draper Catalogue": 168151,
    "Harvard Revised Catalogue": 6850,
    "Gliese Catalog": "Gl 708.1",
    "Distance in Parsecs": 22.92,
    "mag": 4.99,
    "Absolute Visual Magnitude": 3.189,
    "Spectral Type": "F5V",
    "Color Index": 0.44,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.600206,
    "y": -9.886041,
    "z": 20.669241,
    "vx": 0.00003781,
    "vy": 0.0000219,
    "vz": -0.00003156,
    "rarad": 4.7730074301413,
    "decrad": 1.12394468528414,
    "pmrarad": 0.00000170470186356944,
    "pmdecrad": 1.74775331e-7,
    "flam": 36,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 89071,
    "lum": 4.61742659477295,
    "bf": "36    Dra",
    "ra": 18.231545,
    "dec": 64.397287,
    "pmra": 351.62,
    "pmdec": 36.05,
    "rv": -36.1,
    "Asset Name": "ASTRID 002559",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Pompadour",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00003781,
    "y_1": 0.0000219,
    "z_1": -0.00003156,
    "Distance in Parsecs_1": 22.92,
    "HYG Star ID_1": 89071,
    "Hipparcos Catalogue_1": 89348,
    "Henry Draper Catalogue_1": 168151,
    "Harvard Revised Catalogue_1": 6850,
    "Gliese Catalog_1": "Gl 708.1"
  },
  {
    "Astrid #": "ASTRID 002560",
    "HYG Star ID": 10663,
    "Hipparcos Catalogue": 10688,
    "Gliese Catalog": "Gl 93",
    "Distance in Parsecs": 22.9305,
    "mag": 11.48,
    "Absolute Visual Magnitude": 9.678,
    "Spectral Type": "M",
    "Color Index": 1.446,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.125008,
    "y": 7.61526,
    "z": -18.548581,
    "vx": -1.6e-7,
    "vy": 0.00006237,
    "vz": 0.00002551,
    "rarad": 0.600245326375403,
    "decrad": -0.942286152309949,
    "pmrarad": 0.00000224861433178472,
    "pmdecrad": 0.00000189242172,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 10663,
    "lum": 0.011716556739113,
    "var_min": 11.607,
    "var_max": 11.287,
    "ra": 2.292768,
    "dec": -53.98902,
    "pmra": 463.81,
    "pmdec": 390.34,
    "rv": 0,
    "Asset Name": "ASTRID 002560",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Neko",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": -1.6e-7,
    "y_1": 0.00006237,
    "z_1": 0.00002551,
    "Distance in Parsecs_1": 22.9305,
    "HYG Star ID_1": 10663,
    "Hipparcos Catalogue_1": 10688,
    "Gliese Catalog_1": "Gl 93"
  },
  {
    "Astrid #": "ASTRID 002561",
    "HYG Star ID": 40659,
    "Hipparcos Catalogue": 40774,
    "Distance in Parsecs": 22.9305,
    "mag": 8.35,
    "Absolute Visual Magnitude": 6.548,
    "Spectral Type": "G5",
    "Color Index": 0.901,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.092825,
    "y": 18.81751,
    "z": 0.535781,
    "vx": 0.00001494,
    "vy": 0.00001056,
    "vz": -0.00000589,
    "rarad": 2.17868367389517,
    "decrad": 0.023367579252345,
    "pmrarad": -7.97954836826388e-7,
    "pmdecrad": -2.56999732e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 40659,
    "lum": 0.209314830344744,
    "var": "V397",
    "var_min": 8.371,
    "var_max": 8.321,
    "ra": 8.321959,
    "dec": 1.338864,
    "pmra": -164.59,
    "pmdec": -53.01,
    "rv": 0,
    "Asset Name": "ASTRID 002561",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Infatuated",
    "Hair": "Audiophile",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001494,
    "y_1": 0.00001056,
    "z_1": -0.00000589,
    "Distance in Parsecs_1": 22.9305,
    "HYG Star ID_1": 40659,
    "Hipparcos Catalogue_1": 40774
  },
  {
    "Astrid #": "ASTRID 002562",
    "HYG Star ID": 2709,
    "Hipparcos Catalogue": 2715,
    "Distance in Parsecs": 22.9358,
    "mag": 9.27,
    "Absolute Visual Magnitude": 7.467,
    "Spectral Type": "G0",
    "Color Index": 0.57,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.605004,
    "y": 3.430002,
    "z": -1.816537,
    "vx": -0.00000174,
    "vy": 0.00001192,
    "vz": 8.7e-7,
    "rarad": 0.150587727185124,
    "decrad": -0.0792840018420287,
    "pmrarad": 5.25392585618055e-7,
    "pmdecrad": 3.7960911e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 2709,
    "lum": 0.0897842170909726,
    "var_min": 10.021,
    "var_max": 8.121,
    "ra": 0.575203,
    "dec": -4.542639,
    "pmra": 108.37,
    "pmdec": 7.83,
    "rv": 0,
    "Asset Name": "ASTRID 002562",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Nervous",
    "Hair": "Slick",
    "Outfit": "Astro",
    "Special": "V1 Flight System",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000174,
    "y_1": 0.00001192,
    "z_1": 8.7e-7,
    "Distance in Parsecs_1": 22.9358,
    "HYG Star ID_1": 2709,
    "Hipparcos Catalogue_1": 2715
  },
  {
    "Astrid #": "ASTRID 002563",
    "HYG Star ID": 119515,
    "Gliese Catalog": "Wo 9791",
    "Distance in Parsecs": 22.9358,
    "mag": 14,
    "Absolute Visual Magnitude": 12.197,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.956318,
    "y": -3.324294,
    "z": -20.851484,
    "vx": 0.00001636,
    "vy": 0.00009276,
    "vz": -0.00000778,
    "rarad": 5.92749601965152,
    "decrad": -1.14117584628839,
    "pmrarad": 0.00000403961303049305,
    "pmdecrad": -8.14525768e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119515,
    "lum": 0.00115133047415581,
    "ra": 22.641367,
    "dec": -65.38456,
    "pmra": 833.23,
    "pmdec": -168.01,
    "rv": 0,
    "Asset Name": "ASTRID 002563",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Mischievous",
    "Hair": "Heartbreaker",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001636,
    "y_1": 0.00009276,
    "z_1": -0.00000778,
    "Distance in Parsecs_1": 22.9358,
    "HYG Star ID_1": 119515,
    "Gliese Catalog_1": "Wo 9791"
  },
  {
    "Astrid #": "ASTRID 002564",
    "HYG Star ID": 90677,
    "Hipparcos Catalogue": 90959,
    "Henry Draper Catalogue": 171314,
    "Gliese Catalog": "Gl 718",
    "Distance in Parsecs": 22.9727,
    "mag": 8.9,
    "Absolute Visual Magnitude": 7.094,
    "Spectral Type": "K4V",
    "Color Index": 1.181,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.076769,
    "y": -21.028825,
    "z": 8.722551,
    "vx": -0.00001128,
    "vy": -0.00005897,
    "vz": -0.00003368,
    "rarad": 4.85767076153866,
    "decrad": 0.389457171865351,
    "pmrarad": -8.57538438166666e-7,
    "pmdecrad": -0.000002294186817,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 90677,
    "lum": 0.126590174930024,
    "var": "V774",
    "var_min": 8.93,
    "var_max": 8.86,
    "ra": 18.554936,
    "dec": 22.314252,
    "pmra": -176.88,
    "pmdec": -473.21,
    "rv": 38.8,
    "Asset Name": "ASTRID 002564",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Round Brush",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001128,
    "y_1": -0.00005897,
    "z_1": -0.00003368,
    "Distance in Parsecs_1": 22.9727,
    "HYG Star ID_1": 90677,
    "Hipparcos Catalogue_1": 90959,
    "Henry Draper Catalogue_1": 171314,
    "Gliese Catalog_1": "Gl 718"
  },
  {
    "Astrid #": "ASTRID 002565",
    "HYG Star ID": 72377,
    "Hipparcos Catalogue": 72603,
    "Henry Draper Catalogue": 130819,
    "Harvard Revised Catalogue": 5530,
    "Gliese Catalog": "Gl 563.4",
    "Distance in Parsecs": 22.9779,
    "mag": 5.15,
    "Absolute Visual Magnitude": 3.343,
    "Spectral Type": "F3V",
    "Color Index": 0.401,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.24013,
    "y": -14.971057,
    "z": -6.332445,
    "vx": 0.00000807,
    "vy": 0.00002803,
    "vz": 2.6e-7,
    "rarad": 3.88635259468668,
    "decrad": -0.279204452788581,
    "pmrarad": -6.59007235979166e-7,
    "pmdecrad": -2.88318695e-7,
    "bayer": "Alp-1",
    "flam": 8,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 72377,
    "lum": 4.00682154182211,
    "bf": "8Alp1Lib",
    "ra": 14.844773,
    "dec": -15.997237,
    "pmra": -135.93,
    "pmdec": -59.47,
    "rv": -23.5,
    "Asset Name": "ASTRID 002565",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Stunned",
    "Hair": "Headband",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000807,
    "y_1": 0.00002803,
    "z_1": 2.6e-7,
    "Distance in Parsecs_1": 22.9779,
    "HYG Star ID_1": 72377,
    "Hipparcos Catalogue_1": 72603,
    "Henry Draper Catalogue_1": 130819,
    "Harvard Revised Catalogue_1": 5530,
    "Gliese Catalog_1": "Gl 563.4"
  },
  {
    "Astrid #": "ASTRID 002566",
    "HYG Star ID": 119271,
    "Henry Draper Catalogue": 179958,
    "Harvard Revised Catalogue": 7294,
    "Gliese Catalog": "Wo 9648A",
    "Distance in Parsecs": 22.9885,
    "mag": 6.57,
    "Absolute Visual Magnitude": 4.762,
    "Spectral Type": "G6 V",
    "Color Index": 0.65,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.585018,
    "y": -14.09286,
    "z": 17.571183,
    "vx": -0.00004839,
    "vy": 0.00006741,
    "vz": 0.00001346,
    "rarad": 5.02696153850394,
    "decrad": 0.8701324259951,
    "pmrarad": -0.00000109436992111805,
    "pmdecrad": 0.000003006799902,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 119271,
    "base": "Wo 9648",
    "lum": 1.08442619576707,
    "ra": 19.201579,
    "dec": 49.854916,
    "pmra": -225.73,
    "pmdec": 620.2,
    "rv": -39.8,
    "Asset Name": "ASTRID 002566",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Horns",
    "Outfit": "Boxer",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00004839,
    "y_1": 0.00006741,
    "z_1": 0.00001346,
    "Distance in Parsecs_1": 22.9885,
    "HYG Star ID_1": 119271,
    "Henry Draper Catalogue_1": 179958,
    "Harvard Revised Catalogue_1": 7294,
    "Gliese Catalog_1": "Wo 9648A"
  },
  {
    "Astrid #": "ASTRID 002567",
    "HYG Star ID": 15322,
    "Hipparcos Catalogue": 15360,
    "Distance in Parsecs": 22.9938,
    "mag": 11.01,
    "Absolute Visual Magnitude": 9.202,
    "Color Index": 1.451,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.875359,
    "y": 15.083948,
    "z": -11.636773,
    "vx": 0.00000281,
    "vy": -0.00002665,
    "vz": -0.00003145,
    "rarad": 0.864228644591658,
    "decrad": -0.530637260549336,
    "pmrarad": -8.45418096152777e-7,
    "pmdecrad": -0.000001585777067,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 15322,
    "lum": 0.0181635193117651,
    "var_min": 11.108,
    "var_max": 10.938,
    "ra": 3.30111,
    "dec": -30.403275,
    "pmra": -174.38,
    "pmdec": -327.09,
    "rv": 0,
    "Asset Name": "ASTRID 002567",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Prince",
    "Outfit": "Football",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00000281,
    "y_1": -0.00002665,
    "z_1": -0.00003145,
    "Distance in Parsecs_1": 22.9938,
    "HYG Star ID_1": 15322,
    "Hipparcos Catalogue_1": 15360
  },
  {
    "Astrid #": "ASTRID 002568",
    "HYG Star ID": 49235,
    "Hipparcos Catalogue": 49376,
    "Distance in Parsecs": 22.9938,
    "mag": 11.99,
    "Absolute Visual Magnitude": 10.182,
    "Color Index": 0.938,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -17.256859,
    "y": 9.482809,
    "z": -11.874005,
    "vx": 0.00002689,
    "vy": 0.00001824,
    "vz": -0.00002451,
    "rarad": 2.63912793800251,
    "decrad": -0.542642050763396,
    "pmrarad": -0.00000125828542651388,
    "pmdecrad": -0.000001245001531,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 49235,
    "lum": 0.0073654621147023,
    "var_min": 12.087,
    "var_max": 11.877,
    "ra": 10.080726,
    "dec": -31.091099,
    "pmra": -259.54,
    "pmdec": -256.8,
    "rv": 0,
    "Asset Name": "ASTRID 002568",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Shinobi",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": 0.00002689,
    "y_1": 0.00001824,
    "z_1": -0.00002451,
    "Distance in Parsecs_1": 22.9938,
    "HYG Star ID_1": 49235,
    "Hipparcos Catalogue_1": 49376
  },
  {
    "Astrid #": "ASTRID 002569",
    "HYG Star ID": 117892,
    "Hipparcos Catalogue": 118261,
    "Henry Draper Catalogue": 224607,
    "Distance in Parsecs": 22.9938,
    "mag": 8.69,
    "Absolute Visual Magnitude": 6.882,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.042,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.657991,
    "y": -0.06956,
    "z": -10.097398,
    "vx": 5.4e-7,
    "vy": -0.00002588,
    "vz": 0.00000129,
    "rarad": 6.27981921364646,
    "decrad": -0.454636376190773,
    "pmrarad": -0.00000112564040351388,
    "pmdecrad": 6.2492483e-8,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 117892,
    "lum": 0.153886314981995,
    "ra": 23.987142,
    "dec": -26.048746,
    "pmra": -232.18,
    "pmdec": 12.89,
    "rv": 0,
    "Asset Name": "ASTRID 002569",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Angelic",
    "Outfit": "Football",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 5.4e-7,
    "y_1": -0.00002588,
    "z_1": 0.00000129,
    "Distance in Parsecs_1": 22.9938,
    "HYG Star ID_1": 117892,
    "Hipparcos Catalogue_1": 118261,
    "Henry Draper Catalogue_1": 224607
  },
  {
    "Astrid #": "ASTRID 002570",
    "HYG Star ID": 105564,
    "Hipparcos Catalogue": 105905,
    "Henry Draper Catalogue": 203850,
    "Gliese Catalog": "Gl 827.1",
    "Distance in Parsecs": 23.0044,
    "mag": 8.65,
    "Absolute Visual Magnitude": 6.841,
    "Spectral Type": "K3V",
    "Color Index": 0.924,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.068417,
    "y": -7.939135,
    "z": -19.09929,
    "vx": 0.00003769,
    "vy": 0.00006432,
    "vz": 0.00004302,
    "rarad": 5.61546212889247,
    "decrad": -0.97957066666522,
    "pmrarad": 0.00000321038771127083,
    "pmdecrad": 6.73066832e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 105564,
    "lum": 0.159808545947309,
    "ra": 21.449485,
    "dec": -56.125265,
    "pmra": 662.19,
    "pmdec": 138.83,
    "rv": -40.5,
    "Asset Name": "ASTRID 002570",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Angry",
    "Hair": "Unkempt",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00003769,
    "y_1": 0.00006432,
    "z_1": 0.00004302,
    "Distance in Parsecs_1": 23.0044,
    "HYG Star ID_1": 105564,
    "Hipparcos Catalogue_1": 105905,
    "Henry Draper Catalogue_1": 203850,
    "Gliese Catalog_1": "Gl 827.1"
  },
  {
    "Astrid #": "ASTRID 002571",
    "HYG Star ID": 76035,
    "Hipparcos Catalogue": 76267,
    "Henry Draper Catalogue": 139006,
    "Harvard Revised Catalogue": 5793,
    "Proper Name": "Alphekka",
    "Distance in Parsecs": 23.0097,
    "mag": 2.22,
    "Absolute Visual Magnitude": 0.41,
    "Spectral Type": "A0V",
    "Color Index": 0.032,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -12.176093,
    "y": -16.558746,
    "z": 10.343974,
    "vx": 0.00000708,
    "vy": -0.00001304,
    "vz": -0.00000799,
    "rarad": 4.0783442886637,
    "decrad": 0.466259350848143,
    "pmrarad": 5.83618708652777e-7,
    "pmdecrad": -4.33617355e-7,
    "bayer": "Alp",
    "flam": 5,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 76035,
    "lum": 59.7035286583836,
    "var": "Alp",
    "var_min": 2.289,
    "var_max": 2.209,
    "bf": "5Alp CrB",
    "ra": 15.578128,
    "dec": 26.714693,
    "pmra": 120.38,
    "pmdec": -89.44,
    "rv": 2,
    "Asset Name": "ASTRID 002571",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Maniacal",
    "Hair": "Spartan Helmet",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Alphekka",
    "Constellation": "Corona Borealis",
    "x_1": 0.00000708,
    "y_1": -0.00001304,
    "z_1": -0.00000799,
    "Distance in Parsecs_1": 23.0097,
    "HYG Star ID_1": 76035,
    "Hipparcos Catalogue_1": 76267,
    "Henry Draper Catalogue_1": 139006,
    "Harvard Revised Catalogue_1": 5793
  },
  {
    "Astrid #": "ASTRID 002572",
    "HYG Star ID": 12928,
    "Hipparcos Catalogue": 12961,
    "Distance in Parsecs": 23.015,
    "mag": 10.25,
    "Absolute Visual Magnitude": 8.44,
    "Spectral Type": "M0",
    "Color Index": 1.389,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.812912,
    "y": 14.078244,
    "z": -9.024692,
    "vx": -0.00001709,
    "vy": 0.0000285,
    "vz": 0.00001451,
    "rarad": 0.72742929641174,
    "decrad": -0.402937374794313,
    "pmrarad": 0.00000141890419888194,
    "pmdecrad": 6.85623507e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 12928,
    "lum": 0.0366437574647833,
    "ra": 2.778575,
    "dec": -23.086611,
    "pmra": 292.67,
    "pmdec": 141.42,
    "rv": 0,
    "Asset Name": "ASTRID 002572",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Honest Lad",
    "Outfit": "River Spirit",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001709,
    "y_1": 0.0000285,
    "z_1": 0.00001451,
    "Distance in Parsecs_1": 23.015,
    "HYG Star ID_1": 12928,
    "Hipparcos Catalogue_1": 12961
  },
  {
    "Astrid #": "ASTRID 002573",
    "HYG Star ID": 116124,
    "Hipparcos Catalogue": 116491,
    "Gliese Catalog": "NN 4347",
    "Distance in Parsecs": 23.0256,
    "mag": 10.09,
    "Absolute Visual Magnitude": 8.279,
    "Spectral Type": "M0",
    "Color Index": 1.37,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.149365,
    "y": -1.57191,
    "z": -17.268587,
    "vx": -0.00000354,
    "vy": -0.00001373,
    "vz": -0.00000185,
    "rarad": 6.17979721247343,
    "decrad": -0.848021829196256,
    "pmrarad": -6.08974464145833e-7,
    "pmdecrad": -1.21736715e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 116124,
    "lum": 0.0425010833176046,
    "var_min": 10.158,
    "var_max": 10.028,
    "ra": 23.605087,
    "dec": -48.588072,
    "pmra": -125.61,
    "pmdec": -25.11,
    "rv": 0,
    "Asset Name": "ASTRID 002573",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Shinobi",
    "Hair": "Sweetheart",
    "Outfit": "Toga",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000354,
    "y_1": -0.00001373,
    "z_1": -0.00000185,
    "Distance in Parsecs_1": 23.0256,
    "HYG Star ID_1": 116124,
    "Hipparcos Catalogue_1": 116491,
    "Gliese Catalog_1": "NN 4347"
  },
  {
    "Astrid #": "ASTRID 002574",
    "HYG Star ID": 46285,
    "Hipparcos Catalogue": 46417,
    "Gliese Catalog": "Gl 341.1",
    "Distance in Parsecs": 23.0362,
    "mag": 9.27,
    "Absolute Visual Magnitude": 7.458,
    "Spectral Type": "K8",
    "Color Index": 1.205,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.970328,
    "y": 2.322005,
    "z": 22.725385,
    "vx": -0.00003568,
    "vy": 0.00002676,
    "vz": -0.00002958,
    "rarad": 2.47808769694536,
    "decrad": 1.40639030918001,
    "pmrarad": 3.87366130763888e-8,
    "pmdecrad": -0.000002119750855,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 46285,
    "lum": 0.0905315591648484,
    "ra": 9.465598,
    "dec": 80.580229,
    "pmra": 7.99,
    "pmdec": -437.23,
    "rv": -21.4,
    "Asset Name": "ASTRID 002574",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Neko Dreams",
    "Hair": "Medium Bob",
    "Outfit": "Unit 01",
    "Special": "Neko Tail",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00003568,
    "y_1": 0.00002676,
    "z_1": -0.00002958,
    "Distance in Parsecs_1": 23.0362,
    "HYG Star ID_1": 46285,
    "Hipparcos Catalogue_1": 46417,
    "Gliese Catalog_1": "Gl 341.1"
  },
  {
    "Astrid #": "ASTRID 002575",
    "HYG Star ID": 84121,
    "Hipparcos Catalogue": 84379,
    "Henry Draper Catalogue": 156164,
    "Harvard Revised Catalogue": 6410,
    "Gliese Catalog": "NN 3995A",
    "Distance in Parsecs": 23.0362,
    "mag": 3.12,
    "Absolute Visual Magnitude": 1.308,
    "Spectral Type": "A3IVv SB",
    "Color Index": 0.08,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -4.075476,
    "y": -20.503689,
    "z": 9.676738,
    "vx": 0.00000348,
    "vy": 0.00002962,
    "vz": -0.00003316,
    "rarad": 4.51617846621589,
    "decrad": 0.433525895025653,
    "pmrarad": -1.02489612069444e-7,
    "pmdecrad": -7.64454211e-7,
    "bayer": "Del",
    "flam": 65,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 84121,
    "base": "NN 3995",
    "lum": 26.1095868658593,
    "bf": "65Del Her",
    "ra": 17.250531,
    "dec": 24.839204,
    "pmra": -21.14,
    "pmdec": -157.68,
    "rv": -40,
    "Asset Name": "ASTRID 002575",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Frizzy",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000348,
    "y_1": 0.00002962,
    "z_1": -0.00003316,
    "Distance in Parsecs_1": 23.0362,
    "HYG Star ID_1": 84121,
    "Hipparcos Catalogue_1": 84379,
    "Henry Draper Catalogue_1": 156164,
    "Harvard Revised Catalogue_1": 6410,
    "Gliese Catalog_1": "NN 3995A"
  },
  {
    "Astrid #": "ASTRID 002576",
    "HYG Star ID": 55552,
    "Hipparcos Catalogue": 55718,
    "Gliese Catalog": "NN 3662",
    "Distance in Parsecs": 23.0415,
    "mag": 10.23,
    "Absolute Visual Magnitude": 8.417,
    "Spectral Type": "M0",
    "Color Index": 1.36,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.444468,
    "y": 2.685244,
    "z": 14.811899,
    "vx": -0.00000243,
    "vy": 0.0000129,
    "vz": 0.00000116,
    "rarad": 2.98886189076188,
    "decrad": 0.698193199628055,
    "pmrarad": -5.37512927631944e-7,
    "pmdecrad": -8.3242508e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 55552,
    "lum": 0.0374282912240805,
    "ra": 11.416611,
    "dec": 40.003524,
    "pmra": -110.87,
    "pmdec": -17.17,
    "rv": 4,
    "Asset Name": "ASTRID 002576",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Unimpressed",
    "Hair": "Headband",
    "Outfit": "Spartan",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000243,
    "y_1": 0.0000129,
    "z_1": 0.00000116,
    "Distance in Parsecs_1": 23.0415,
    "HYG Star ID_1": 55552,
    "Hipparcos Catalogue_1": 55718,
    "Gliese Catalog_1": "NN 3662"
  },
  {
    "Astrid #": "ASTRID 002577",
    "HYG Star ID": 107684,
    "Hipparcos Catalogue": 108028,
    "Henry Draper Catalogue": 208038,
    "Distance in Parsecs": 23.0415,
    "mag": 8.18,
    "Absolute Visual Magnitude": 6.367,
    "Spectral Type": "K0",
    "Color Index": 0.937,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.304915,
    "y": -11.317574,
    "z": 8.231242,
    "vx": 0.00000309,
    "vy": -0.00000271,
    "vz": -0.00001061,
    "rarad": 5.72943254607912,
    "decrad": 0.365306442764261,
    "pmrarad": -2.94766717777777e-8,
    "pmdecrad": -4.93055513e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 107684,
    "lum": 0.247286267818702,
    "ra": 21.88482,
    "dec": 20.930517,
    "pmra": -6.08,
    "pmdec": -101.7,
    "rv": 0,
    "Asset Name": "ASTRID 002577",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Good Boy",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000309,
    "y_1": -0.00000271,
    "z_1": -0.00001061,
    "Distance in Parsecs_1": 23.0415,
    "HYG Star ID_1": 107684,
    "Hipparcos Catalogue_1": 108028,
    "Henry Draper Catalogue_1": 208038
  },
  {
    "Astrid #": "ASTRID 002578",
    "HYG Star ID": 54761,
    "Hipparcos Catalogue": 54922,
    "Henry Draper Catalogue": 97782,
    "Gliese Catalog": "GJ 1143A",
    "Distance in Parsecs": 23.0468,
    "mag": 9.02,
    "Absolute Visual Magnitude": 7.207,
    "Spectral Type": "K4V",
    "Color Index": 1.108,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.787284,
    "y": 4.15344,
    "z": -9.043936,
    "vx": 0.00002218,
    "vy": 0.00002923,
    "vz": -0.00003755,
    "rarad": 2.94438399550575,
    "decrad": -0.403256932826456,
    "pmrarad": -0.00000143228505646527,
    "pmdecrad": -0.000001771751595,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 54761,
    "base": "GJ 1143",
    "lum": 0.114077501292212,
    "ra": 11.246718,
    "dec": -23.10492,
    "pmra": -295.43,
    "pmdec": -365.45,
    "rv": 0,
    "Asset Name": "ASTRID 002578",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Slick",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00002218,
    "y_1": 0.00002923,
    "z_1": -0.00003755,
    "Distance in Parsecs_1": 23.0468,
    "HYG Star ID_1": 54761,
    "Hipparcos Catalogue_1": 54922,
    "Henry Draper Catalogue_1": 97782,
    "Gliese Catalog_1": "GJ 1143A"
  },
  {
    "Astrid #": "ASTRID 002579",
    "HYG Star ID": 89527,
    "Hipparcos Catalogue": 89805,
    "Henry Draper Catalogue": 167425,
    "Harvard Revised Catalogue": 6828,
    "Distance in Parsecs": 23.0468,
    "mag": 6.17,
    "Absolute Visual Magnitude": 4.357,
    "Spectral Type": "F9V",
    "Color Index": 0.584,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.869508,
    "y": -10.10671,
    "z": -20.694284,
    "vx": 0.00000195,
    "vy": 0.00002797,
    "vz": -0.00001358,
    "rarad": 4.79820834876035,
    "decrad": -1.11503085703777,
    "pmrarad": 1.88544040368055e-7,
    "pmdecrad": -0.00000133886146,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 89527,
    "lum": 1.57470787728984,
    "ra": 18.327806,
    "dec": -63.886562,
    "pmra": 38.89,
    "pmdec": -276.16,
    "rv": 0,
    "Asset Name": "ASTRID 002579",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Wavy",
    "Outfit": "Toga",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00000195,
    "y_1": 0.00002797,
    "z_1": -0.00001358,
    "Distance in Parsecs_1": 23.0468,
    "HYG Star ID_1": 89527,
    "Hipparcos Catalogue_1": 89805,
    "Henry Draper Catalogue_1": 167425,
    "Harvard Revised Catalogue_1": 6828
  },
  {
    "Astrid #": "ASTRID 002580",
    "HYG Star ID": 110366,
    "Hipparcos Catalogue": 110712,
    "Henry Draper Catalogue": 212168,
    "Harvard Revised Catalogue": 8526,
    "Distance in Parsecs": 23.0468,
    "mag": 6.12,
    "Absolute Visual Magnitude": 4.307,
    "Spectral Type": "G3IV",
    "Color Index": 0.599,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.463128,
    "y": -2.379614,
    "z": -22.263253,
    "vx": 0.00000724,
    "vy": 0.00000398,
    "vz": -0.00001347,
    "rarad": 5.87238277976108,
    "decrad": -1.30927076058346,
    "pmrarad": 2.83858409965277e-7,
    "pmdecrad": 6.0504747e-8,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 110366,
    "lum": 1.64892157315083,
    "ra": 22.43085,
    "dec": -75.015689,
    "pmra": 58.55,
    "pmdec": 12.48,
    "rv": 14,
    "Asset Name": "ASTRID 002580",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Infatuated",
    "Hair": "Windy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": 0.00000724,
    "y_1": 0.00000398,
    "z_1": -0.00001347,
    "Distance in Parsecs_1": 23.0468,
    "HYG Star ID_1": 110366,
    "Hipparcos Catalogue_1": 110712,
    "Henry Draper Catalogue_1": 212168,
    "Harvard Revised Catalogue_1": 8526
  },
  {
    "Astrid #": "ASTRID 002581",
    "HYG Star ID": 114634,
    "Hipparcos Catalogue": 114996,
    "Henry Draper Catalogue": 219571,
    "Harvard Revised Catalogue": 8848,
    "Gliese Catalog": "Wo 9818",
    "Distance in Parsecs": 23.0574,
    "mag": 3.99,
    "Absolute Visual Magnitude": 2.176,
    "Spectral Type": "F1III",
    "Color Index": 0.41,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.929297,
    "y": -2.241691,
    "z": -19.60403,
    "vx": 0.00001645,
    "vy": -0.00000706,
    "vz": -0.00001132,
    "rarad": 6.09743802622758,
    "decrad": -1.01640530169692,
    "pmrarad": -1.69345418618055e-7,
    "pmdecrad": 3.85863208e-7,
    "bayer": "Gam",
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 114634,
    "lum": 11.7381593248322,
    "bf": "Gam Tuc",
    "ra": 23.290498,
    "dec": -58.235734,
    "pmra": -34.93,
    "pmdec": 79.59,
    "rv": 18.4,
    "Asset Name": "ASTRID 002581",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Lion Mane",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": 0.00001645,
    "y_1": -0.00000706,
    "z_1": -0.00001132,
    "Distance in Parsecs_1": 23.0574,
    "HYG Star ID_1": 114634,
    "Hipparcos Catalogue_1": 114996,
    "Henry Draper Catalogue_1": 219571,
    "Harvard Revised Catalogue_1": 8848,
    "Gliese Catalog_1": "Wo 9818"
  },
  {
    "Astrid #": "ASTRID 002582",
    "HYG Star ID": 86353,
    "Hipparcos Catalogue": 86620,
    "Henry Draper Catalogue": 162004,
    "Harvard Revised Catalogue": 6637,
    "Gliese Catalog": "Gl 694.1B",
    "Distance in Parsecs": 23.0627,
    "mag": 5.81,
    "Absolute Visual Magnitude": 3.995,
    "Spectral Type": "G0V",
    "Color Index": 0.53,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.555414,
    "y": -7.044781,
    "z": 21.953273,
    "vx": 0.00000188,
    "vy": -0.00002593,
    "vz": -0.00001998,
    "rarad": 4.63370798946926,
    "decrad": 1.25937567113627,
    "pmrarad": 1.69539344090277e-7,
    "pmdecrad": -0.000001326498711,
    "bayer": "Psi-1",
    "flam": 31,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 86347,
    "base": "Gl 694.1",
    "lum": 2.19785987278482,
    "bf": "31Psi1Dra",
    "ra": 17.699461,
    "dec": 72.156911,
    "pmra": 34.97,
    "pmdec": -273.61,
    "rv": -10.9,
    "Asset Name": "ASTRID 002582",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Shy Smile",
    "Hair": "Ram Horns",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000188,
    "y_1": -0.00002593,
    "z_1": -0.00001998,
    "Distance in Parsecs_1": 23.0627,
    "HYG Star ID_1": 86353,
    "Hipparcos Catalogue_1": 86620,
    "Henry Draper Catalogue_1": 162004,
    "Harvard Revised Catalogue_1": 6637,
    "Gliese Catalog_1": "Gl 694.1B"
  },
  {
    "Astrid #": "ASTRID 002583",
    "HYG Star ID": 12853,
    "Hipparcos Catalogue": 12886,
    "Gliese Catalog": "NN 3178A",
    "Distance in Parsecs": 23.084,
    "mag": 10.8,
    "Absolute Visual Magnitude": 8.983,
    "Spectral Type": "K5V:",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.252009,
    "y": 10.807423,
    "z": 16.308248,
    "vx": -0.00002287,
    "vy": 0.00004097,
    "vz": -0.00000997,
    "rarad": 0.722826586933914,
    "decrad": 0.784503788443933,
    "pmrarad": 0.0000019866210188125,
    "pmdecrad": -6.10234979e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 12853,
    "base": "NN 3178",
    "lum": 0.022222862543282,
    "var_min": 10.85,
    "var_max": 10.74,
    "ra": 2.760994,
    "dec": 44.948756,
    "pmra": 409.77,
    "pmdec": -125.87,
    "rv": 0,
    "Asset Name": "ASTRID 002583",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Antlers",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00002287,
    "y_1": 0.00004097,
    "z_1": -0.00000997,
    "Distance in Parsecs_1": 23.084,
    "HYG Star ID_1": 12853,
    "Hipparcos Catalogue_1": 12886,
    "Gliese Catalog_1": "NN 3178A"
  },
  {
    "Astrid #": "ASTRID 002584",
    "HYG Star ID": 50014,
    "Hipparcos Catalogue": 50156,
    "Gliese Catalog": "GJ 2079",
    "Distance in Parsecs": 23.084,
    "mag": 10.01,
    "Absolute Visual Magnitude": 8.193,
    "Spectral Type": "M0Ve",
    "Color Index": 1.247,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.290293,
    "y": 9.58422,
    "z": 8.300755,
    "vx": -0.00000699,
    "vy": 0.00002153,
    "vz": -0.00001237,
    "rarad": 2.68047539671281,
    "decrad": 0.367825992166176,
    "pmrarad": -7.00604249770833e-7,
    "pmdecrad": -7.46952437e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 50014,
    "lum": 0.0460044666393477,
    "var_min": 10.075,
    "var_max": 9.945,
    "ra": 10.238662,
    "dec": 21.074877,
    "pmra": -144.51,
    "pmdec": -154.07,
    "rv": 10.1,
    "Asset Name": "ASTRID 002584",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Frizzy",
    "Outfit": "Boxer",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000699,
    "y_1": 0.00002153,
    "z_1": -0.00001237,
    "Distance in Parsecs_1": 23.084,
    "HYG Star ID_1": 50014,
    "Hipparcos Catalogue_1": 50156,
    "Gliese Catalog_1": "GJ 2079"
  },
  {
    "Astrid #": "ASTRID 002585",
    "HYG Star ID": 43302,
    "Hipparcos Catalogue": 43422,
    "Gliese Catalog": "Gl 323A",
    "Distance in Parsecs": 23.0947,
    "mag": 9.08,
    "Absolute Visual Magnitude": 7.262,
    "Color Index": 0.746,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -15.507481,
    "y": 16.819422,
    "z": 3.160075,
    "vx": 0.00001858,
    "vy": 0.00000377,
    "vz": -0.00000367,
    "rarad": 2.3156332532896,
    "decrad": 0.137262314486526,
    "pmrarad": -7.02252616284722e-7,
    "pmdecrad": -9.9144397e-8,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 43302,
    "base": "Gl 323",
    "lum": 0.108442619576707,
    "var": "HP",
    "var_min": 9.123,
    "var_max": 9.023,
    "ra": 8.845067,
    "dec": 7.864551,
    "pmra": -144.85,
    "pmdec": -20.45,
    "rv": -10,
    "Asset Name": "ASTRID 002585",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Unkempt",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00001858,
    "y_1": 0.00000377,
    "z_1": -0.00000367,
    "Distance in Parsecs_1": 23.0947,
    "HYG Star ID_1": 43302,
    "Hipparcos Catalogue_1": 43422,
    "Gliese Catalog_1": "Gl 323A"
  },
  {
    "Astrid #": "ASTRID 002586",
    "HYG Star ID": 49984,
    "Hipparcos Catalogue": 50125,
    "Henry Draper Catalogue": 233719,
    "Gliese Catalog": "Gl 383.1",
    "Distance in Parsecs": 23.0947,
    "mag": 9.53,
    "Absolute Visual Magnitude": 7.712,
    "Spectral Type": "K5",
    "Color Index": 1.112,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.578788,
    "y": 6.274542,
    "z": 18.323731,
    "vx": -0.00005102,
    "vy": 0.00001464,
    "vz": -0.000072,
    "rarad": 2.67888767291878,
    "decrad": 0.916415220742687,
    "pmrarad": 4.18733575895833e-7,
    "pmdecrad": -0.00000369088655,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 49984,
    "lum": 0.0716473282391108,
    "ra": 10.232597,
    "dec": 52.506724,
    "pmra": 86.37,
    "pmdec": -761.3,
    "rv": -24.8,
    "Asset Name": "ASTRID 002586",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Curly",
    "Outfit": "Emperor",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00005102,
    "y_1": 0.00001464,
    "z_1": -0.000072,
    "Distance in Parsecs_1": 23.0947,
    "HYG Star ID_1": 49984,
    "Hipparcos Catalogue_1": 50125,
    "Henry Draper Catalogue_1": 233719,
    "Gliese Catalog_1": "Gl 383.1"
  },
  {
    "Astrid #": "ASTRID 002587",
    "HYG Star ID": 117974,
    "Gliese Catalog": "Wo 9006",
    "Distance in Parsecs": 23.0947,
    "mag": 14.8,
    "Absolute Visual Magnitude": 12.982,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.152197,
    "y": 1.583799,
    "z": 6.335371,
    "vx": -0.00000611,
    "vy": 0.00002226,
    "vz": 0.00001582,
    "rarad": 0.0713728477834461,
    "decrad": 0.27788400071555,
    "pmrarad": 9.80535668923611e-7,
    "pmdecrad": 7.12414311e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117974,
    "lum": 0.00055872743899402,
    "ra": 0.272624,
    "dec": 15.92158,
    "pmra": 202.25,
    "pmdec": 146.95,
    "rv": 0,
    "Asset Name": "ASTRID 002587",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Fire",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000611,
    "y_1": 0.00002226,
    "z_1": 0.00001582,
    "Distance in Parsecs_1": 23.0947,
    "HYG Star ID_1": 117974,
    "Gliese Catalog_1": "Wo 9006"
  },
  {
    "Astrid #": "ASTRID 002588",
    "HYG Star ID": 118007,
    "Gliese Catalog": "GJ 1015A",
    "Distance in Parsecs": 23.0947,
    "mag": 14.02,
    "Absolute Visual Magnitude": 12.202,
    "Spectral Type": "m",
    "Color Index": 1.55,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.759689,
    "y": 2.326221,
    "z": 19.108746,
    "vx": 8e-7,
    "vy": 0.00003438,
    "vz": -0.00000472,
    "rarad": 0.180272762553487,
    "decrad": 0.97447703669905,
    "pmrarad": 0.00000145827106974305,
    "pmdecrad": -3.63586019e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118007,
    "base": "GJ 1015",
    "lum": 0.0011460405911366,
    "ra": 0.688591,
    "dec": 55.833421,
    "pmra": 300.79,
    "pmdec": -75,
    "rv": 0,
    "Asset Name": "ASTRID 002588",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Hachimaki",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 8e-7,
    "y_1": 0.00003438,
    "z_1": -0.00000472,
    "Distance in Parsecs_1": 23.0947,
    "HYG Star ID_1": 118007,
    "Gliese Catalog_1": "GJ 1015A"
  },
  {
    "Astrid #": "ASTRID 002589",
    "HYG Star ID": 118008,
    "Gliese Catalog": "GJ 1015B",
    "Distance in Parsecs": 23.0947,
    "mag": 14.08,
    "Absolute Visual Magnitude": 12.262,
    "Spectral Type": "DQ5",
    "Color Index": 0.02,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 12.758973,
    "y": 2.327057,
    "z": 19.109123,
    "vx": 8e-7,
    "vy": 0.00003438,
    "vz": -0.00000472,
    "rarad": 0.180346064553487,
    "decrad": 0.974506066699049,
    "pmrarad": 0.00000145827106974305,
    "pmdecrad": -3.63586019e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118007,
    "base": "GJ 1015",
    "lum": 0.00108442619576707,
    "ra": 0.688871,
    "dec": 55.835085,
    "pmra": 300.79,
    "pmdec": -75,
    "rv": 0,
    "Asset Name": "ASTRID 002589",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Fire",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 8e-7,
    "y_1": 0.00003438,
    "z_1": -0.00000472,
    "Distance in Parsecs_1": 23.0947,
    "HYG Star ID_1": 118008,
    "Gliese Catalog_1": "GJ 1015B"
  },
  {
    "Astrid #": "ASTRID 002590",
    "HYG Star ID": 96744,
    "Hipparcos Catalogue": 97051,
    "Distance in Parsecs": 23.1054,
    "mag": 10,
    "Absolute Visual Magnitude": 8.181,
    "Spectral Type": "M0",
    "Color Index": 1.26,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.920533,
    "y": -20.470911,
    "z": 4.047754,
    "vx": 0.00001825,
    "vy": 0.00001106,
    "vz": 0.0000112,
    "rarad": 5.16365375972529,
    "decrad": 0.176095199448346,
    "pmrarad": 9.19740033381944e-7,
    "pmdecrad": 4.92279811e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 96744,
    "lum": 0.0465157470302166,
    "var_min": 10.055,
    "var_max": 9.945,
    "ra": 19.723704,
    "dec": 10.089512,
    "pmra": 189.71,
    "pmdec": 101.54,
    "rv": 0,
    "Asset Name": "ASTRID 002590",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Heartbreaker",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00001825,
    "y_1": 0.00001106,
    "z_1": 0.0000112,
    "Distance in Parsecs_1": 23.1054,
    "HYG Star ID_1": 96744,
    "Hipparcos Catalogue_1": 97051
  },
  {
    "Astrid #": "ASTRID 002591",
    "HYG Star ID": 19117,
    "Hipparcos Catalogue": 19165,
    "Gliese Catalog": "Gl 160.2",
    "Distance in Parsecs": 23.1214,
    "mag": 9.7,
    "Absolute Visual Magnitude": 7.88,
    "Spectral Type": "K7",
    "Color Index": 1.206,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.261867,
    "y": 19.014743,
    "z": -8.230695,
    "vx": -0.0000072,
    "vy": -0.00000105,
    "vz": -0.00009186,
    "rarad": 1.07591106944751,
    "decrad": -0.363955615837848,
    "pmrarad": 2.52394002097222e-7,
    "pmdecrad": -0.000003779752897,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 19117,
    "lum": 0.0613762005164793,
    "ra": 4.109678,
    "dec": -20.853121,
    "pmra": 52.06,
    "pmdec": -779.63,
    "rv": 28,
    "Asset Name": "ASTRID 002591",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Bad Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.0000072,
    "y_1": -0.00000105,
    "z_1": -0.00009186,
    "Distance in Parsecs_1": 23.1214,
    "HYG Star ID_1": 19117,
    "Hipparcos Catalogue_1": 19165,
    "Gliese Catalog_1": "Gl 160.2"
  },
  {
    "Astrid #": "ASTRID 002592",
    "HYG Star ID": 10518,
    "Hipparcos Catalogue": 10542,
    "Henry Draper Catalogue": 14001,
    "Gliese Catalog": "Gl 91.2A",
    "Distance in Parsecs": 23.1481,
    "mag": 7.94,
    "Absolute Visual Magnitude": 6.117,
    "Spectral Type": "K3V",
    "Color Index": 1.033,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.238996,
    "y": 12.275632,
    "z": -7.244624,
    "vx": 0.00000333,
    "vy": -0.0000035,
    "vz": -0.00001486,
    "rarad": 0.592404246644397,
    "decrad": -0.318316186745196,
    "pmrarad": -2.05754926027777e-7,
    "pmdecrad": -5.98599451e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10518,
    "base": "Gl  91.2",
    "lum": 0.311314966544702,
    "ra": 2.262818,
    "dec": -18.238174,
    "pmra": -42.44,
    "pmdec": -123.47,
    "rv": 5.3,
    "Asset Name": "ASTRID 002592",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Goddess",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000333,
    "y_1": -0.0000035,
    "z_1": -0.00001486,
    "Distance in Parsecs_1": 23.1481,
    "HYG Star ID_1": 10518,
    "Hipparcos Catalogue_1": 10542,
    "Henry Draper Catalogue_1": 14001,
    "Gliese Catalog_1": "Gl 91.2A"
  },
  {
    "Astrid #": "ASTRID 002593",
    "HYG Star ID": 119466,
    "Gliese Catalog": "NN 4250",
    "Distance in Parsecs": 23.1481,
    "mag": 10.34,
    "Absolute Visual Magnitude": 8.517,
    "Color Index": 0.73,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 20.215728,
    "y": -11.257825,
    "z": 0.648253,
    "vx": 0.00000132,
    "vy": 0.00000226,
    "vz": -0.00000212,
    "rarad": 5.77507186250344,
    "decrad": 0.0280082746013,
    "pmrarad": 1.13010068937499e-7,
    "pmdecrad": -9.1527974e-8,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 119466,
    "lum": 0.0341350072949311,
    "ra": 22.05915,
    "dec": 1.604756,
    "pmra": 23.31,
    "pmdec": -18.88,
    "rv": 0,
    "Asset Name": "ASTRID 002593",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Battle Ready",
    "Hair": "Spiky",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000132,
    "y_1": 0.00000226,
    "z_1": -0.00000212,
    "Distance in Parsecs_1": 23.1481,
    "HYG Star ID_1": 119466,
    "Gliese Catalog_1": "NN 4250"
  },
  {
    "Astrid #": "ASTRID 002594",
    "HYG Star ID": 85939,
    "Hipparcos Catalogue": 86201,
    "Henry Draper Catalogue": 160922,
    "Harvard Revised Catalogue": 6596,
    "Gliese Catalog": "NN 4017",
    "Distance in Parsecs": 23.1642,
    "mag": 4.77,
    "Absolute Visual Magnitude": 2.946,
    "Spectral Type": "F5V",
    "Color Index": 0.43,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.842597,
    "y": -8.350132,
    "z": 21.590264,
    "vx": 0.00000406,
    "vy": 0.00003873,
    "vz": -6.6e-7,
    "rarad": 4.6118209718444,
    "decrad": 1.2000529553253,
    "pmrarad": 6.49650331944444e-9,
    "pmdecrad": 0.000001556494321,
    "bayer": "Ome",
    "flam": 28,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 85939,
    "lum": 5.77563846394533,
    "bf": "28Ome Dra",
    "ra": 17.615859,
    "dec": 68.75797,
    "pmra": 1.34,
    "pmdec": 321.05,
    "rv": -14.4,
    "Asset Name": "ASTRID 002594",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Curly",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000406,
    "y_1": 0.00003873,
    "z_1": -6.6e-7,
    "Distance in Parsecs_1": 23.1642,
    "HYG Star ID_1": 85939,
    "Hipparcos Catalogue_1": 86201,
    "Henry Draper Catalogue_1": 160922,
    "Harvard Revised Catalogue_1": 6596,
    "Gliese Catalog_1": "NN 4017"
  },
  {
    "Astrid #": "ASTRID 002595",
    "HYG Star ID": 8481,
    "Hipparcos Catalogue": 8497,
    "Henry Draper Catalogue": 11171,
    "Harvard Revised Catalogue": 531,
    "Gliese Catalog": "Wo 9061A",
    "Distance in Parsecs": 23.1857,
    "mag": 4.66,
    "Absolute Visual Magnitude": 2.834,
    "Spectral Type": "F3III",
    "Color Index": 0.333,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.228295,
    "y": 10.483677,
    "z": -4.299405,
    "vx": 0.00000515,
    "vy": -0.00001618,
    "vz": -0.00001026,
    "rarad": 0.478155072483759,
    "decrad": -0.186513045530484,
    "pmrarad": -7.21887570347222e-7,
    "pmdecrad": -4.58003484e-7,
    "bayer": "Chi",
    "flam": 53,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 8481,
    "base": "Wo 9061",
    "lum": 6.40324324468946,
    "bf": "53Chi Cet",
    "ra": 1.826418,
    "dec": -10.68641,
    "pmra": -148.9,
    "pmdec": -94.47,
    "rv": -0.9,
    "Asset Name": "ASTRID 002595",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Audiohead",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000515,
    "y_1": -0.00001618,
    "z_1": -0.00001026,
    "Distance in Parsecs_1": 23.1857,
    "HYG Star ID_1": 8481,
    "Hipparcos Catalogue_1": 8497,
    "Henry Draper Catalogue_1": 11171,
    "Harvard Revised Catalogue_1": 531,
    "Gliese Catalog_1": "Wo 9061A"
  },
  {
    "Astrid #": "ASTRID 002596",
    "HYG Star ID": 45506,
    "Hipparcos Catalogue": 45637,
    "Henry Draper Catalogue": 80545,
    "Gliese Catalog": "NN 3551",
    "Distance in Parsecs": 23.1857,
    "mag": 9.52,
    "Absolute Visual Magnitude": 7.694,
    "Spectral Type": "K7V",
    "Color Index": 1.185,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.408272,
    "y": 8.024922,
    "z": -19.613048,
    "vx": -0.00003784,
    "vy": 0.00004496,
    "vz": 0.00000753,
    "rarad": 2.43538323641286,
    "decrad": -1.00825710477078,
    "pmrarad": -4.16067100652777e-7,
    "pmdecrad": 0.000002288369053,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 45506,
    "lum": 0.0728450423292329,
    "ra": 9.302479,
    "dec": -57.768877,
    "pmra": -85.82,
    "pmdec": 472.01,
    "rv": 24,
    "Asset Name": "ASTRID 002596",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Sweet Nap",
    "Hair": "High Bun",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00003784,
    "y_1": 0.00004496,
    "z_1": 0.00000753,
    "Distance in Parsecs_1": 23.1857,
    "HYG Star ID_1": 45506,
    "Hipparcos Catalogue_1": 45637,
    "Henry Draper Catalogue_1": 80545,
    "Gliese Catalog_1": "NN 3551"
  },
  {
    "Astrid #": "ASTRID 002597",
    "HYG Star ID": 1604,
    "Hipparcos Catalogue": 1608,
    "Gliese Catalog": "GJ 2003",
    "Distance in Parsecs": 23.1965,
    "mag": 11.67,
    "Absolute Visual Magnitude": 9.843,
    "Color Index": 1.49,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 22.090079,
    "y": 1.946194,
    "z": -6.80576,
    "vx": -0.00000165,
    "vy": 0.00001519,
    "vz": -0.00000102,
    "rarad": 0.087875443919619,
    "decrad": -0.297777240880305,
    "pmrarad": 6.58619385034722e-7,
    "pmdecrad": -4.5814892e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 1604,
    "lum": 0.0100646806643832,
    "var_min": 11.768,
    "var_max": 11.588,
    "ra": 0.335659,
    "dec": -17.061379,
    "pmra": 135.85,
    "pmdec": -9.45,
    "rv": 0,
    "Asset Name": "ASTRID 002597",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Curly",
    "Outfit": "Flame Soul",
    "Special": "Astral Burst",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000165,
    "y_1": 0.00001519,
    "z_1": -0.00000102,
    "Distance in Parsecs_1": 23.1965,
    "HYG Star ID_1": 1604,
    "Hipparcos Catalogue_1": 1608,
    "Gliese Catalog_1": "GJ 2003"
  },
  {
    "Astrid #": "ASTRID 002598",
    "HYG Star ID": 5931,
    "Hipparcos Catalogue": 5944,
    "Henry Draper Catalogue": 7590,
    "Distance in Parsecs": 23.1965,
    "mag": 6.59,
    "Absolute Visual Magnitude": 4.763,
    "Spectral Type": "G0",
    "Color Index": 0.594,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.044587,
    "y": 5.562801,
    "z": 15.802029,
    "vx": 0.00000635,
    "vy": -0.00001118,
    "vz": -0.00000251,
    "rarad": 0.333741638736391,
    "decrad": 0.749434251577703,
    "pmrarad": -5.45172983784722e-7,
    "pmdecrad": -1.47819691e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 5931,
    "lum": 1.08342786215013,
    "var": "V445",
    "var_min": 6.603,
    "var_max": 6.573,
    "ra": 1.274799,
    "dec": 42.93942,
    "pmra": -112.45,
    "pmdec": -30.49,
    "rv": 0,
    "Asset Name": "ASTRID 002598",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Oof",
    "Hair": "Undercut",
    "Outfit": "Super",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000635,
    "y_1": -0.00001118,
    "z_1": -0.00000251,
    "Distance in Parsecs_1": 23.1965,
    "HYG Star ID_1": 5931,
    "Hipparcos Catalogue_1": 5944,
    "Henry Draper Catalogue_1": 7590
  },
  {
    "Astrid #": "ASTRID 002599",
    "HYG Star ID": 107429,
    "Hipparcos Catalogue": 107772,
    "Distance in Parsecs": 23.1965,
    "mag": 10.56,
    "Absolute Visual Magnitude": 8.733,
    "Spectral Type": "M0",
    "Color Index": 1.328,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.709762,
    "y": -9.371473,
    "z": -15.293005,
    "vx": -1.9e-7,
    "vy": -0.00003239,
    "vz": 0.00001966,
    "rarad": 5.71594116843952,
    "decrad": -0.719861728799053,
    "pmrarad": -0.00000118212119729861,
    "pmdecrad": 0.000001127337251,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 107429,
    "lum": 0.0279769263785464,
    "var_min": 10.619,
    "var_max": 10.499,
    "ra": 21.833287,
    "dec": -41.245039,
    "pmra": -243.83,
    "pmdec": 232.53,
    "rv": 0,
    "Asset Name": "ASTRID 002599",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Maniacal",
    "Hair": "Ponytail",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -1.9e-7,
    "y_1": -0.00003239,
    "z_1": 0.00001966,
    "Distance in Parsecs_1": 23.1965,
    "HYG Star ID_1": 107429,
    "Hipparcos Catalogue_1": 107772
  },
  {
    "Astrid #": "ASTRID 002600",
    "HYG Star ID": 1383,
    "Hipparcos Catalogue": 1386,
    "Gliese Catalog": "NN 3023",
    "Distance in Parsecs": 23.2019,
    "mag": 11.54,
    "Absolute Visual Magnitude": 9.712,
    "Spectral Type": "M:",
    "Color Index": 1.505,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.198863,
    "y": 1.531057,
    "z": 11.313264,
    "vx": -0.00002933,
    "vy": 0.00007574,
    "vz": 0.00004212,
    "rarad": 0.0756502385619162,
    "decrad": 0.509339547299832,
    "pmrarad": 0.00000335069279042361,
    "pmdecrad": 0.000002079462838,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 1383,
    "lum": 0.0113553362784193,
    "var_min": 11.6,
    "var_max": 11.46,
    "ra": 0.288963,
    "dec": 29.183006,
    "pmra": 691.13,
    "pmdec": 428.92,
    "rv": 0,
    "Asset Name": "ASTRID 002600",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Shocked",
    "Hair": "Braid",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00002933,
    "y_1": 0.00007574,
    "z_1": 0.00004212,
    "Distance in Parsecs_1": 23.2019,
    "HYG Star ID_1": 1383,
    "Hipparcos Catalogue_1": 1386,
    "Gliese Catalog_1": "NN 3023"
  },
  {
    "Astrid #": "ASTRID 002601",
    "HYG Star ID": 117994,
    "Gliese Catalog": "NN 3037",
    "Distance in Parsecs": 23.2019,
    "mag": 17.34,
    "Absolute Visual Magnitude": 15.512,
    "Spectral Type": "g-k",
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.94449,
    "y": 3.240435,
    "z": -1.173931,
    "vx": -0.0000091,
    "vy": 0.00007057,
    "vz": 0.0000168,
    "rarad": 0.140301291294954,
    "decrad": -0.0506179484203999,
    "pmrarad": 0.00000306678589909027,
    "pmdecrad": 7.24951592e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117994,
    "lum": 0.0000543500565129446,
    "ra": 0.535911,
    "dec": -2.900195,
    "pmra": 632.57,
    "pmdec": 149.53,
    "rv": 0,
    "Asset Name": "ASTRID 002601",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Shinobi",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000091,
    "y_1": 0.00007057,
    "z_1": 0.0000168,
    "Distance in Parsecs_1": 23.2019,
    "HYG Star ID_1": 117994,
    "Gliese Catalog_1": "NN 3037"
  },
  {
    "Astrid #": "ASTRID 002602",
    "HYG Star ID": 118316,
    "Gliese Catalog": "NN 3301A",
    "Distance in Parsecs": 23.2019,
    "mag": 11.2,
    "Absolute Visual Magnitude": 9.372,
    "Spectral Type": "K",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.980064,
    "y": 20.767181,
    "z": 6.595384,
    "vx": 0.00001063,
    "vy": 0.0000538,
    "vz": 0.00001205,
    "rarad": 1.20392846030641,
    "decrad": 0.288199713104399,
    "pmrarad": 4.04286128215277e-7,
    "pmdecrad": -1.64157912e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118316,
    "base": "NN 3301",
    "lum": 0.0155310207523284,
    "ra": 4.598668,
    "dec": 16.512627,
    "pmra": 83.39,
    "pmdec": -33.86,
    "rv": 54,
    "Asset Name": "ASTRID 002602",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Innocent",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00001063,
    "y_1": 0.0000538,
    "z_1": 0.00001205,
    "Distance in Parsecs_1": 23.2019,
    "HYG Star ID_1": 118316,
    "Gliese Catalog_1": "NN 3301A"
  },
  {
    "Astrid #": "ASTRID 002603",
    "HYG Star ID": 119174,
    "Gliese Catalog": "NN 4023",
    "Distance in Parsecs": 23.2019,
    "mag": 13.52,
    "Absolute Visual Magnitude": 11.692,
    "Spectral Type": "M3",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.780018,
    "y": -22.853682,
    "z": -3.556775,
    "vx": -0.00008558,
    "vy": 0.00010815,
    "vz": -0.00003861,
    "rarad": 4.6346607849817,
    "decrad": -0.15393501309145,
    "pmrarad": -0.00000403874036586805,
    "pmdecrad": -0.000002313021829,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119174,
    "lum": 0.00183315842867085,
    "ra": 17.7031,
    "dec": -8.819827,
    "pmra": -833.05,
    "pmdec": -477.1,
    "rv": -92,
    "Asset Name": "ASTRID 002603",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Unkempt",
    "Outfit": "Spartan",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00008558,
    "y_1": 0.00010815,
    "z_1": -0.00003861,
    "Distance in Parsecs_1": 23.2019,
    "HYG Star ID_1": 119174,
    "Gliese Catalog_1": "NN 4023"
  },
  {
    "Astrid #": "ASTRID 002604",
    "HYG Star ID": 54882,
    "Hipparcos Catalogue": 55052,
    "Distance in Parsecs": 23.2072,
    "mag": 10.35,
    "Absolute Visual Magnitude": 8.522,
    "Color Index": 1.226,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.695139,
    "y": 3.818256,
    "z": -11.666043,
    "vx": 0.00000175,
    "vy": 0.00003479,
    "vz": 0.00000843,
    "rarad": 2.95010218908519,
    "decrad": -0.52670852135486,
    "pmrarad": -0.00000148639026321527,
    "pmdecrad": 4.20188016e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 54882,
    "lum": 0.0339781711827083,
    "var_min": 10.394,
    "var_max": 10.274,
    "ra": 11.26856,
    "dec": -30.178175,
    "pmra": -306.59,
    "pmdec": 86.67,
    "rv": 0,
    "Asset Name": "ASTRID 002604",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Oh Snap",
    "Hair": "High Fade",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000175,
    "y_1": 0.00003479,
    "z_1": 0.00000843,
    "Distance in Parsecs_1": 23.2072,
    "HYG Star ID_1": 54882,
    "Hipparcos Catalogue_1": 55052
  },
  {
    "Astrid #": "ASTRID 002605",
    "HYG Star ID": 96403,
    "Hipparcos Catalogue": 96710,
    "Distance in Parsecs": 23.218,
    "mag": 10.49,
    "Absolute Visual Magnitude": 8.661,
    "Color Index": 1.391,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.729498,
    "y": -18.805478,
    "z": -10.451095,
    "vx": 0.00003209,
    "vy": 0.00002962,
    "vz": -0.0000265,
    "rarad": 5.14698657496597,
    "decrad": -0.466909796469656,
    "pmrarad": 0.00000179085325460416,
    "pmdecrad": -0.000001278453675,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 96403,
    "lum": 0.0298950992781458,
    "ra": 19.660041,
    "dec": -26.751961,
    "pmra": 369.39,
    "pmdec": -263.7,
    "rv": 0,
    "Asset Name": "ASTRID 002605",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Audiophile",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00003209,
    "y_1": 0.00002962,
    "z_1": -0.0000265,
    "Distance in Parsecs_1": 23.218,
    "HYG Star ID_1": 96403,
    "Hipparcos Catalogue_1": 96710
  },
  {
    "Astrid #": "ASTRID 002606",
    "HYG Star ID": 107749,
    "Hipparcos Catalogue": 108092,
    "Gliese Catalog": "Gl 839",
    "Distance in Parsecs": 23.218,
    "mag": 10.3,
    "Absolute Visual Magnitude": 8.471,
    "Spectral Type": "M2",
    "Color Index": 1.333,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.761789,
    "y": -9.04751,
    "z": 15.468929,
    "vx": 0.00001423,
    "vy": 0.00004202,
    "vz": -0.00006268,
    "rarad": 5.73332841170814,
    "decrad": 0.729181617117901,
    "pmrarad": 0.00000186362378805555,
    "pmdecrad": -0.000001731415097,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 107749,
    "lum": 0.0356122980942761,
    "var_min": 10.362,
    "var_max": 10.252,
    "ra": 21.899701,
    "dec": 41.779029,
    "pmra": 384.4,
    "pmdec": -357.13,
    "rv": -48,
    "Asset Name": "ASTRID 002606",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Pixie",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001423,
    "y_1": 0.00004202,
    "z_1": -0.00006268,
    "Distance in Parsecs_1": 23.218,
    "HYG Star ID_1": 107749,
    "Hipparcos Catalogue_1": 108092,
    "Gliese Catalog_1": "Gl 839"
  },
  {
    "Astrid #": "ASTRID 002607",
    "HYG Star ID": 82082,
    "Hipparcos Catalogue": 82333,
    "Gliese Catalog": "NN 3974",
    "Distance in Parsecs": 23.2342,
    "mag": 9.67,
    "Absolute Visual Magnitude": 7.839,
    "Spectral Type": "M0",
    "Color Index": 1.264,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.452465,
    "y": -17.139459,
    "z": 14.708788,
    "vx": -0.00000175,
    "vy": -0.00004187,
    "vz": -0.00000744,
    "rarad": 4.40438793691413,
    "decrad": 0.685499678635591,
    "pmrarad": 4.74390186423611e-7,
    "pmdecrad": -0.000001349527361,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 82082,
    "lum": 0.0637382301438346,
    "var_min": 9.738,
    "var_max": 9.598,
    "ra": 16.823523,
    "dec": 39.276238,
    "pmra": 97.85,
    "pmdec": -278.36,
    "rv": 26,
    "Asset Name": "ASTRID 002607",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Frizzy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000175,
    "y_1": -0.00004187,
    "z_1": -0.00000744,
    "Distance in Parsecs_1": 23.2342,
    "HYG Star ID_1": 82082,
    "Hipparcos Catalogue_1": 82333,
    "Gliese Catalog_1": "NN 3974"
  },
  {
    "Astrid #": "ASTRID 002608",
    "HYG Star ID": 9727,
    "Hipparcos Catalogue": 9749,
    "Gliese Catalog": "Gl 84.1A",
    "Distance in Parsecs": 23.2396,
    "mag": 10.88,
    "Absolute Visual Magnitude": 9.049,
    "Spectral Type": "K",
    "Color Index": 1.4,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.512544,
    "y": 10.668136,
    "z": -10.935296,
    "vx": -8.2e-7,
    "vy": 0.00004436,
    "vz": 0.00004196,
    "rarad": 0.54713377105542,
    "decrad": -0.489909223164304,
    "pmrarad": 0.00000164846347662499,
    "pmdecrad": 0.000002046495508,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 9727,
    "base": "Gl  84.1",
    "lum": 0.0209122133015528,
    "var_min": 10.926,
    "var_max": 10.816,
    "ra": 2.089897,
    "dec": -28.069731,
    "pmra": 340.02,
    "pmdec": 422.12,
    "rv": 0,
    "Asset Name": "ASTRID 002608",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Angelic",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -8.2e-7,
    "y_1": 0.00004436,
    "z_1": 0.00004196,
    "Distance in Parsecs_1": 23.2396,
    "HYG Star ID_1": 9727,
    "Hipparcos Catalogue_1": 9749,
    "Gliese Catalog_1": "Gl 84.1A"
  },
  {
    "Astrid #": "ASTRID 002609",
    "HYG Star ID": 72396,
    "Hipparcos Catalogue": 72622,
    "Henry Draper Catalogue": 130841,
    "Harvard Revised Catalogue": 5531,
    "Gliese Catalog": "Gl 564.1",
    "Proper Name": "Zubenelgenubi",
    "Distance in Parsecs": 23.2396,
    "mag": 2.75,
    "Absolute Visual Magnitude": 0.919,
    "Spectral Type": "A3IV",
    "Color Index": 0.147,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -16.408581,
    "y": -15.151818,
    "z": -6.421874,
    "vx": 0.00002722,
    "vy": 0.00004135,
    "vz": 0.00000573,
    "rarad": 3.88719116950368,
    "decrad": -0.279981846062591,
    "pmrarad": -5.12399578979166e-7,
    "pmdecrad": -3.34521439e-7,
    "bayer": "Alp-2",
    "flam": 9,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 72396,
    "lum": 37.3594092258799,
    "bf": "9Alp2Lib",
    "ra": 14.847977,
    "dec": -16.041778,
    "pmra": -105.69,
    "pmdec": -69,
    "rv": -46.7,
    "Asset Name": "ASTRID 002609",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Glad",
    "Hair": "Hachimaki",
    "Outfit": "Sushi Chef",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "Zubenelgenubi",
    "Constellation": "Libra",
    "x_1": 0.00002722,
    "y_1": 0.00004135,
    "z_1": 0.00000573,
    "Distance in Parsecs_1": 23.2396,
    "HYG Star ID_1": 72396,
    "Hipparcos Catalogue_1": 72622,
    "Henry Draper Catalogue_1": 130841,
    "Harvard Revised Catalogue_1": 5531,
    "Gliese Catalog_1": "Gl 564.1"
  },
  {
    "Astrid #": "ASTRID 002610",
    "HYG Star ID": 106058,
    "Hipparcos Catalogue": 106400,
    "Henry Draper Catalogue": 205434,
    "Distance in Parsecs": 23.2396,
    "mag": 9.34,
    "Absolute Visual Magnitude": 7.509,
    "Spectral Type": "K",
    "Color Index": 1.163,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.741748,
    "y": -6.526715,
    "z": 20.519816,
    "vx": 0.00000951,
    "vy": 0.00004505,
    "vz": 0.00001028,
    "rarad": 5.64183676502198,
    "decrad": 1.08214691443858,
    "pmrarad": 0.00000179817394118055,
    "pmdecrad": 9.41992981e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 106058,
    "lum": 0.0863773746529183,
    "var": "V430",
    "var_min": 9.393,
    "var_max": 9.283,
    "ra": 21.550229,
    "dec": 62.002451,
    "pmra": 370.9,
    "pmdec": 194.3,
    "rv": 0,
    "Asset Name": "ASTRID 002610",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Lovestruck",
    "Hair": "Greasy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00000951,
    "y_1": 0.00004505,
    "z_1": 0.00001028,
    "Distance in Parsecs_1": 23.2396,
    "HYG Star ID_1": 106058,
    "Hipparcos Catalogue_1": 106400,
    "Henry Draper Catalogue_1": 205434
  },
  {
    "Astrid #": "ASTRID 002611",
    "HYG Star ID": 1496,
    "Hipparcos Catalogue": 1499,
    "Henry Draper Catalogue": 1461,
    "Harvard Revised Catalogue": 72,
    "Gliese Catalog": "Gl 16.1",
    "Distance in Parsecs": 23.245,
    "mag": 6.47,
    "Absolute Visual Magnitude": 4.638,
    "Spectral Type": "G0V",
    "Color Index": 0.674,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.939133,
    "y": 1.875641,
    "z": -3.256358,
    "vx": -0.0000166,
    "vy": 0.00004586,
    "vz": -0.0000146,
    "rarad": 0.0815843396371593,
    "decrad": -0.140551389136611,
    "pmrarad": 0.00000202487281820833,
    "pmdecrad": -6.99101327e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 1496,
    "lum": 1.21562605523737,
    "ra": 0.311629,
    "dec": -8.053001,
    "pmra": 417.66,
    "pmdec": -144.2,
    "rv": -10.4,
    "Asset Name": "ASTRID 002611",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Goddess",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.0000166,
    "y_1": 0.00004586,
    "z_1": -0.0000146,
    "Distance in Parsecs_1": 23.245,
    "HYG Star ID_1": 1496,
    "Hipparcos Catalogue_1": 1499,
    "Henry Draper Catalogue_1": 1461,
    "Harvard Revised Catalogue_1": 72,
    "Gliese Catalog_1": "Gl 16.1"
  },
  {
    "Astrid #": "ASTRID 002612",
    "HYG Star ID": 26625,
    "Hipparcos Catalogue": 26690,
    "Distance in Parsecs": 23.2504,
    "mag": 8.33,
    "Absolute Visual Magnitude": 6.498,
    "Spectral Type": "F0",
    "Color Index": 0.448,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.924616,
    "y": 22.338191,
    "z": 6.154852,
    "vx": -0.0000015,
    "vy": 9.8e-7,
    "vz": -0.00000307,
    "rarad": 1.48485044019991,
    "decrad": 0.267913820328555,
    "pmrarad": 6.76799898055555e-8,
    "pmdecrad": -1.37105308e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 26625,
    "lum": 0.219179534384412,
    "ra": 5.671711,
    "dec": 15.350331,
    "pmra": 13.96,
    "pmdec": -28.28,
    "rv": 0,
    "Asset Name": "ASTRID 002612",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Knocked Out",
    "Hair": "Good Boy",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.0000015,
    "y_1": 9.8e-7,
    "z_1": -0.00000307,
    "Distance in Parsecs_1": 23.2504,
    "HYG Star ID_1": 26625,
    "Hipparcos Catalogue_1": 26690
  },
  {
    "Astrid #": "ASTRID 002613",
    "HYG Star ID": 56639,
    "Hipparcos Catalogue": 56809,
    "Henry Draper Catalogue": 101177,
    "Harvard Revised Catalogue": 4486,
    "Gliese Catalog": "Gl 433.2A",
    "Distance in Parsecs": 23.2504,
    "mag": 6.29,
    "Absolute Visual Magnitude": 4.458,
    "Spectral Type": "G0V",
    "Color Index": 0.566,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.338772,
    "y": 1.519416,
    "z": 16.471486,
    "vx": 0.00001981,
    "vy": 0.00006538,
    "vz": -0.00001136,
    "rarad": 3.0488753263237,
    "decrad": 0.78729037083371,
    "pmrarad": -0.00000287916300471527,
    "pmdecrad": 7.1752424e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 56639,
    "base": "Gl 433.2",
    "lum": 1.43482851823258,
    "ra": 11.645846,
    "dec": 45.108416,
    "pmra": -593.87,
    "pmdec": 14.8,
    "rv": -17.3,
    "Asset Name": "ASTRID 002613",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Neko",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001981,
    "y_1": 0.00006538,
    "z_1": -0.00001136,
    "Distance in Parsecs_1": 23.2504,
    "HYG Star ID_1": 56639,
    "Hipparcos Catalogue_1": 56809,
    "Henry Draper Catalogue_1": 101177,
    "Harvard Revised Catalogue_1": 4486,
    "Gliese Catalog_1": "Gl 433.2A"
  },
  {
    "Astrid #": "ASTRID 002614",
    "HYG Star ID": 118022,
    "Gliese Catalog": "NN 3057",
    "Distance in Parsecs": 23.2558,
    "mag": 12.38,
    "Absolute Visual Magnitude": 10.547,
    "Spectral Type": "M4",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.250375,
    "y": 4.37492,
    "z": 10.564781,
    "vx": 0.00001715,
    "vy": -0.00001363,
    "vz": -0.00002723,
    "rarad": 0.212775788675974,
    "decrad": 0.4715704497486,
    "pmrarad": -7.28626480506944e-7,
    "pmdecrad": -0.000001314485028,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118022,
    "lum": 0.00526259562080317,
    "ra": 0.812744,
    "dec": 27.018997,
    "pmra": -150.29,
    "pmdec": -271.13,
    "rv": 0,
    "Asset Name": "ASTRID 002614",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Audiohead",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001715,
    "y_1": -0.00001363,
    "z_1": -0.00002723,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118022,
    "Gliese Catalog_1": "NN 3057"
  },
  {
    "Astrid #": "ASTRID 002615",
    "HYG Star ID": 118029,
    "Gliese Catalog": "NN 3064",
    "Distance in Parsecs": 23.2558,
    "mag": 15.25,
    "Absolute Visual Magnitude": 13.417,
    "Spectral Type": "DA7",
    "Color Index": 0.35,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 22.119247,
    "y": 5.498089,
    "z": -4.61976,
    "vx": 0.00001212,
    "vy": -0.00000786,
    "vz": 0.00004863,
    "rarad": 0.243628877416023,
    "decrad": -0.19998009075075,
    "pmrarad": -4.53591679527777e-7,
    "pmdecrad": 0.000002133984985,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118029,
    "lum": 0.000374282912240805,
    "ra": 0.930594,
    "dec": -11.458015,
    "pmra": -93.56,
    "pmdec": 440.17,
    "rv": 0,
    "Asset Name": "ASTRID 002615",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Medium Bob",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001212,
    "y_1": -0.00000786,
    "z_1": 0.00004863,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118029,
    "Gliese Catalog_1": "NN 3064"
  },
  {
    "Astrid #": "ASTRID 002616",
    "HYG Star ID": 118053,
    "Gliese Catalog": "NN 3081",
    "Distance in Parsecs": 23.2558,
    "mag": 13.85,
    "Absolute Visual Magnitude": 12.017,
    "Spectral Type": "dM2.5",
    "Color Index": 1.66,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 17.622072,
    "y": 5.908683,
    "z": -13.977922,
    "vx": -2.2e-7,
    "vy": 0.00007695,
    "vz": 0.00003222,
    "rarad": 0.323480217847405,
    "decrad": -0.64481551452655,
    "pmrarad": 0.00000314081694811111,
    "pmdecrad": 0.000001733829469,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118053,
    "lum": 0.0013589391171008,
    "ra": 1.235603,
    "dec": -36.945208,
    "pmra": 647.84,
    "pmdec": 357.63,
    "rv": 0,
    "Asset Name": "ASTRID 002616",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Determined",
    "Hair": "Windy",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -2.2e-7,
    "y_1": 0.00007695,
    "z_1": 0.00003222,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118053,
    "Gliese Catalog_1": "NN 3081"
  },
  {
    "Astrid #": "ASTRID 002617",
    "HYG Star ID": 118145,
    "Gliese Catalog": "NN 3159",
    "Distance in Parsecs": 23.2558,
    "mag": 13.05,
    "Absolute Visual Magnitude": 11.217,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.549311,
    "y": 14.014726,
    "z": 0.585428,
    "vx": -0.00001803,
    "vy": 0.00002437,
    "vz": -0.00001224,
    "rarad": 0.647036536351213,
    "decrad": 0.0251760491329499,
    "pmrarad": 0.00000130356702427777,
    "pmdecrad": -5.26677341e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118145,
    "lum": 0.0028392262394249,
    "ra": 2.471498,
    "dec": 1.442481,
    "pmra": 268.88,
    "pmdec": -108.64,
    "rv": 0,
    "Asset Name": "ASTRID 002617",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous",
    "Hair": "Wavy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001803,
    "y_1": 0.00002437,
    "z_1": -0.00001224,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118145,
    "Gliese Catalog_1": "NN 3159"
  },
  {
    "Astrid #": "ASTRID 002618",
    "HYG Star ID": 118184,
    "Gliese Catalog": "GJ 2028",
    "Distance in Parsecs": 23.2558,
    "mag": 14.08,
    "Absolute Visual Magnitude": 12.247,
    "Spectral Type": "DA6",
    "Color Index": 0.23,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 5.612907,
    "y": 5.438371,
    "z": -21.909042,
    "vx": -0.00003352,
    "vy": 0.00007323,
    "vz": -0.00010661,
    "rarad": 0.769283698127269,
    "decrad": -1.2281469008281,
    "pmrarad": 0.00000326546254538194,
    "pmdecrad": -4.53116562e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118184,
    "lum": 0.00109951206687934,
    "ra": 2.938447,
    "dec": -70.367634,
    "pmra": 673.55,
    "pmdec": -93.46,
    "rv": 107,
    "Asset Name": "ASTRID 002618",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Neko Dreams",
    "Hair": "Charmer",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003352,
    "y_1": 0.00007323,
    "z_1": -0.00010661,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118184,
    "Gliese Catalog_1": "GJ 2028"
  },
  {
    "Astrid #": "ASTRID 002619",
    "HYG Star ID": 118224,
    "Gliese Catalog": "NN 3228A",
    "Distance in Parsecs": 23.2558,
    "mag": 14.28,
    "Absolute Visual Magnitude": 12.447,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.739271,
    "y": 17.687484,
    "z": -6.26239,
    "vx": -0.00001797,
    "vy": 0.00001508,
    "vz": 0.00000317,
    "rarad": 0.910373656926504,
    "decrad": -0.2726484014177,
    "pmrarad": 0.00000100821853008333,
    "pmdecrad": 1.41691646e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118224,
    "base": "NN 3228",
    "lum": 0.000914534303120459,
    "ra": 3.477371,
    "dec": -15.621603,
    "pmra": 207.96,
    "pmdec": 29.23,
    "rv": 0,
    "Asset Name": "ASTRID 002619",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Serious Slumber",
    "Hair": "Lion Mane",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001797,
    "y_1": 0.00001508,
    "z_1": 0.00000317,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118224,
    "Gliese Catalog_1": "NN 3228A"
  },
  {
    "Astrid #": "ASTRID 002620",
    "HYG Star ID": 118225,
    "Gliese Catalog": "NN 3229B",
    "Distance in Parsecs": 23.2558,
    "mag": 14.37,
    "Absolute Visual Magnitude": 12.537,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.739052,
    "y": 17.687193,
    "z": -6.263693,
    "vx": -0.00001797,
    "vy": 0.00001508,
    "vz": 0.00000317,
    "rarad": 0.910373417926504,
    "decrad": -0.2727065794177,
    "pmrarad": 0.00000100821853008333,
    "pmdecrad": 1.41691646e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118225,
    "base": "NN 3229",
    "lum": 0.000841782707660811,
    "ra": 3.47737,
    "dec": -15.624936,
    "pmra": 207.96,
    "pmdec": 29.23,
    "rv": 0,
    "Asset Name": "ASTRID 002620",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Horns",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001797,
    "y_1": 0.00001508,
    "z_1": 0.00000317,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118225,
    "Gliese Catalog_1": "NN 3229B"
  },
  {
    "Astrid #": "ASTRID 002621",
    "HYG Star ID": 118238,
    "Gliese Catalog": "NN 3239A",
    "Distance in Parsecs": 23.2558,
    "mag": 12.74,
    "Absolute Visual Magnitude": 10.907,
    "Spectral Type": "g-k",
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.890017,
    "y": 17.994758,
    "z": 7.132206,
    "vx": -0.00001583,
    "vy": 0.00001241,
    "vz": -0.00000269,
    "rarad": 0.949198503019279,
    "decrad": 0.31170826246625,
    "pmrarad": 8.64180385590277e-7,
    "pmdecrad": -1.21450675e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118238,
    "base": "NN 3239",
    "lum": 0.00377746109388218,
    "ra": 3.625671,
    "dec": 17.859568,
    "pmra": 178.25,
    "pmdec": -25.05,
    "rv": 0,
    "Asset Name": "ASTRID 002621",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Good Boy",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001583,
    "y_1": 0.00001241,
    "z_1": -0.00000269,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118238,
    "Gliese Catalog_1": "NN 3239A"
  },
  {
    "Astrid #": "ASTRID 002622",
    "HYG Star ID": 118239,
    "Gliese Catalog": "NN 3240B",
    "Distance in Parsecs": 23.2558,
    "mag": 13.29,
    "Absolute Visual Magnitude": 11.457,
    "Spectral Type": "g-k",
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.888954,
    "y": 17.996033,
    "z": 7.130912,
    "vx": -0.00001583,
    "vy": 0.00001241,
    "vz": -0.00000269,
    "rarad": 0.949271042019279,
    "decrad": 0.31164980046625,
    "pmrarad": 8.64180385590277e-7,
    "pmdecrad": -1.21450675e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118239,
    "base": "NN 3240",
    "lum": 0.00227614539314164,
    "ra": 3.625948,
    "dec": 17.856218,
    "pmra": 178.25,
    "pmdec": -25.05,
    "rv": 0,
    "Asset Name": "ASTRID 002622",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Braid",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001583,
    "y_1": 0.00001241,
    "z_1": -0.00000269,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118239,
    "Gliese Catalog_1": "NN 3240B"
  },
  {
    "Astrid #": "ASTRID 002623",
    "HYG Star ID": 118271,
    "Gliese Catalog": "NN 3260",
    "Distance in Parsecs": 23.2558,
    "mag": 11.47,
    "Absolute Visual Magnitude": 9.637,
    "Spectral Type": "M0",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.644665,
    "y": 18.557463,
    "z": -9.118331,
    "vx": 0.00002512,
    "vy": -0.00003368,
    "vz": -0.00003925,
    "rarad": 1.05001779325317,
    "decrad": -0.4029007174452,
    "pmrarad": -0.00000165767493655555,
    "pmdecrad": -0.000001834568904,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118271,
    "lum": 0.0121674620397912,
    "ra": 4.010773,
    "dec": -23.084511,
    "pmra": -341.92,
    "pmdec": -378.41,
    "rv": 0,
    "Asset Name": "ASTRID 002623",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous Grin",
    "Hair": "Wavy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00002512,
    "y_1": -0.00003368,
    "z_1": -0.00003925,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118271,
    "Gliese Catalog_1": "NN 3260"
  },
  {
    "Astrid #": "ASTRID 002624",
    "HYG Star ID": 118275,
    "Gliese Catalog": "NN 3265",
    "Distance in Parsecs": 23.2558,
    "mag": 13.45,
    "Absolute Visual Magnitude": 11.617,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.89811,
    "y": 13.424658,
    "z": 17.692567,
    "vx": 0.00000697,
    "vy": 0.00004141,
    "vz": -0.00003413,
    "rarad": 1.09612428946006,
    "decrad": 0.86451531027035,
    "pmrarad": 5.47209201243055e-7,
    "pmdecrad": -0.000002261854593,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118275,
    "lum": 0.00196426464587985,
    "ra": 4.186886,
    "dec": 49.533079,
    "pmra": 112.87,
    "pmdec": -466.54,
    "rv": 0,
    "Asset Name": "ASTRID 002624",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Prince",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000697,
    "y_1": 0.00004141,
    "z_1": -0.00003413,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118275,
    "Gliese Catalog_1": "NN 3265"
  },
  {
    "Astrid #": "ASTRID 002625",
    "HYG Star ID": 118305,
    "Gliese Catalog": "NN 3288",
    "Distance in Parsecs": 23.2558,
    "mag": 17.11,
    "Absolute Visual Magnitude": 15.277,
    "Spectral Type": "DC9",
    "Color Index": 1.1,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 9.212398,
    "y": 21.27368,
    "z": 1.842427,
    "vx": -0.000063,
    "vy": 0.00003287,
    "vz": -0.00006429,
    "rarad": 1.16213329375641,
    "decrad": 0.079307465743,
    "pmrarad": 0.00000304797512828472,
    "pmdecrad": -0.00000277342514,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118305,
    "lum": 0.0000674838730870757,
    "ra": 4.439022,
    "dec": 4.543983,
    "pmra": 628.69,
    "pmdec": -572.06,
    "rv": 0,
    "Asset Name": "ASTRID 002625",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Windy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.000063,
    "y_1": 0.00003287,
    "z_1": -0.00006429,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118305,
    "Gliese Catalog_1": "NN 3288"
  },
  {
    "Astrid #": "ASTRID 002626",
    "HYG Star ID": 118308,
    "Gliese Catalog": "NN 3291",
    "Distance in Parsecs": 23.2558,
    "mag": 13.85,
    "Absolute Visual Magnitude": 12.017,
    "Spectral Type": "k",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.905415,
    "y": 20.951625,
    "z": 4.749234,
    "vx": -0.00002763,
    "vy": 0.00002375,
    "vz": -0.00005293,
    "rarad": 1.16888472076239,
    "decrad": 0.2056639228926,
    "pmrarad": 0.00000149293524790277,
    "pmdecrad": -0.000002325462148,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118308,
    "lum": 0.0013589391171008,
    "ra": 4.464811,
    "dec": 11.783675,
    "pmra": 307.94,
    "pmdec": -479.66,
    "rv": 0,
    "Asset Name": "ASTRID 002626",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Determined Neko",
    "Hair": "Goddess",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002763,
    "y_1": 0.00002375,
    "z_1": -0.00005293,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118308,
    "Gliese Catalog_1": "NN 3291"
  },
  {
    "Astrid #": "ASTRID 002627",
    "HYG Star ID": 118315,
    "Gliese Catalog": "NN 3297",
    "Distance in Parsecs": 23.2558,
    "mag": 13.13,
    "Absolute Visual Magnitude": 11.297,
    "Spectral Type": "g-k",
    "Color Index": 1.57,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.97568,
    "y": 17.770219,
    "z": 13.281244,
    "vx": -0.00001177,
    "vy": 0.00002398,
    "vz": -0.0000259,
    "rarad": 1.19672051368415,
    "decrad": 0.60783775723375,
    "pmrarad": 8.477936831875e-7,
    "pmdecrad": -0.000001356775325,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118315,
    "lum": 0.00263754574088934,
    "ra": 4.571136,
    "dec": 34.826538,
    "pmra": 174.87,
    "pmdec": -279.86,
    "rv": 0,
    "Asset Name": "ASTRID 002627",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Bob",
    "Outfit": "Painter",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001177,
    "y_1": 0.00002398,
    "z_1": -0.0000259,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118315,
    "Gliese Catalog_1": "NN 3297"
  },
  {
    "Astrid #": "ASTRID 002628",
    "HYG Star ID": 118357,
    "Gliese Catalog": "NN 3337",
    "Distance in Parsecs": 23.2558,
    "mag": 14.2,
    "Absolute Visual Magnitude": 12.367,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.679396,
    "y": 21.534862,
    "z": 7.428676,
    "vx": 0.00002979,
    "vy": 0.00001718,
    "vz": -0.00006852,
    "rarad": 1.35683163756698,
    "decrad": 0.32513133543435,
    "pmrarad": -0.00000109504866027083,
    "pmdecrad": -0.000003109551313,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118357,
    "lum": 0.000984464363980377,
    "ra": 5.182715,
    "dec": 18.628653,
    "pmra": -225.87,
    "pmdec": -641.39,
    "rv": 0,
    "Asset Name": "ASTRID 002628",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Undercut",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002979,
    "y_1": 0.00001718,
    "z_1": -0.00006852,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118357,
    "Gliese Catalog_1": "NN 3337"
  },
  {
    "Astrid #": "ASTRID 002629",
    "HYG Star ID": 118370,
    "Gliese Catalog": "NN 3345",
    "Distance in Parsecs": 23.2558,
    "mag": 13.01,
    "Absolute Visual Magnitude": 11.177,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.206431,
    "y": 12.895364,
    "z": 19.226896,
    "vx": -0.00001132,
    "vy": 0.00002405,
    "vz": -0.00001483,
    "rarad": 1.40130927792343,
    "decrad": 0.9733182729782,
    "pmrarad": 6.5449846875e-7,
    "pmdecrad": -0.000001133620436,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118370,
    "lum": 0.00294577790233945,
    "ra": 5.352607,
    "dec": 55.767029,
    "pmra": 135,
    "pmdec": -233.83,
    "rv": 0,
    "Asset Name": "ASTRID 002629",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "High Fade",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001132,
    "y_1": 0.00002405,
    "z_1": -0.00001483,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118370,
    "Gliese Catalog_1": "NN 3345"
  },
  {
    "Astrid #": "ASTRID 002630",
    "HYG Star ID": 118480,
    "Gliese Catalog": "NN 3445",
    "Distance in Parsecs": 23.2558,
    "mag": 13.08,
    "Absolute Visual Magnitude": 11.247,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.03535,
    "y": 16.584077,
    "z": 14.707293,
    "vx": -0.00002352,
    "vy": 0.00000864,
    "vz": -0.00002099,
    "rarad": 1.97199736969321,
    "decrad": 0.6846655425475,
    "pmrarad": 7.86173864388888e-7,
    "pmdecrad": -0.00000116558905,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118480,
    "lum": 0.00276184944207529,
    "ra": 7.532475,
    "dec": 39.228446,
    "pmra": 162.16,
    "pmdec": -240.42,
    "rv": 0,
    "Asset Name": "ASTRID 002630",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Fired Up",
    "Hair": "Charmer",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002352,
    "y_1": 0.00000864,
    "z_1": -0.00002099,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118480,
    "Gliese Catalog_1": "NN 3445"
  },
  {
    "Astrid #": "ASTRID 002631",
    "HYG Star ID": 118515,
    "Gliese Catalog": "NN 3470",
    "Distance in Parsecs": 23.2558,
    "mag": 12.33,
    "Absolute Visual Magnitude": 10.497,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.134861,
    "y": 19.461351,
    "z": 6.172756,
    "vx": 0.00001632,
    "vy": 0.00001095,
    "vz": -0.00000506,
    "rarad": 2.09048959072268,
    "decrad": 0.26864852199255,
    "pmrarad": -8.42945546381944e-7,
    "pmdecrad": -2.25860149e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118515,
    "lum": 0.00551061411139065,
    "ra": 7.985082,
    "dec": 15.392426,
    "pmra": -173.87,
    "pmdec": -46.59,
    "rv": 0,
    "Asset Name": "ASTRID 002631",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Heartbreaker",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001632,
    "y_1": 0.00001095,
    "z_1": -0.00000506,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118515,
    "Gliese Catalog_1": "NN 3470"
  },
  {
    "Astrid #": "ASTRID 002632",
    "HYG Star ID": 118527,
    "Gliese Catalog": "NN 3483",
    "Distance in Parsecs": 23.2558,
    "mag": 13.92,
    "Absolute Visual Magnitude": 12.087,
    "Spectral Type": "DQ5",
    "Color Index": 0.05,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -4.914249,
    "y": 7.951662,
    "z": -21.294446,
    "vx": -0.00001983,
    "vy": -0.00004736,
    "vz": -0.00001311,
    "rarad": 2.12422354122143,
    "decrad": -1.1571512578433,
    "pmrarad": 0.00000179560442867361,
    "pmdecrad": -0.000001402856866,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118527,
    "lum": 0.00127408968591911,
    "ra": 8.113936,
    "dec": -66.299883,
    "pmra": 370.37,
    "pmdec": -289.36,
    "rv": 0,
    "Asset Name": "ASTRID 002632",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Undercut",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001983,
    "y_1": -0.00004736,
    "z_1": -0.00001311,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118527,
    "Gliese Catalog_1": "NN 3483"
  },
  {
    "Astrid #": "ASTRID 002633",
    "HYG Star ID": 118536,
    "Gliese Catalog": "NN 3496",
    "Distance in Parsecs": 23.2558,
    "mag": 13.42,
    "Absolute Visual Magnitude": 11.587,
    "Spectral Type": "m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.398048,
    "y": 1.781768,
    "z": 23.145259,
    "vx": -0.00001089,
    "vy": -0.00003786,
    "vz": 0.00000226,
    "rarad": 2.23546585349682,
    "decrad": 1.4732560769048,
    "pmrarad": 0.00000137274993649305,
    "pmdecrad": 9.97378096e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118536,
    "lum": 0.00201929606979811,
    "ra": 8.538851,
    "dec": 84.411355,
    "pmra": 283.15,
    "pmdec": 205.72,
    "rv": 0,
    "Asset Name": "ASTRID 002633",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Ponytail",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001089,
    "y_1": -0.00003786,
    "z_1": 0.00000226,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118536,
    "Gliese Catalog_1": "NN 3496"
  },
  {
    "Astrid #": "ASTRID 002634",
    "HYG Star ID": 118567,
    "Henry Draper Catalogue": 75404,
    "Gliese Catalog": "NN 3516",
    "Distance in Parsecs": 23.2558,
    "mag": 7.6,
    "Absolute Visual Magnitude": 5.767,
    "Spectral Type": "G0",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.958998,
    "y": 14.018482,
    "z": 13.280767,
    "vx": 0.00000302,
    "vy": 0.00000539,
    "vz": -0.00000274,
    "rarad": 2.31694452763514,
    "decrad": 0.6078127640309,
    "pmrarad": -2.52927297145833e-7,
    "pmdecrad": -1.43679382e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 118567,
    "lum": 0.42973428115368,
    "ra": 8.850076,
    "dec": 34.825106,
    "pmra": -52.17,
    "pmdec": -29.64,
    "rv": 0,
    "Asset Name": "ASTRID 002634",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Ponytail",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000302,
    "y_1": 0.00000539,
    "z_1": -0.00000274,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118567,
    "Henry Draper Catalogue_1": 75404,
    "Gliese Catalog_1": "NN 3516"
  },
  {
    "Astrid #": "ASTRID 002635",
    "HYG Star ID": 118596,
    "Gliese Catalog": "NN 3532",
    "Distance in Parsecs": 23.2558,
    "mag": 12.96,
    "Absolute Visual Magnitude": 11.127,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.778354,
    "y": 6.27681,
    "z": 21.342161,
    "vx": -0.00003202,
    "vy": -0.0000124,
    "vz": -0.00000652,
    "rarad": 2.39449225117625,
    "decrad": 1.1622856410781,
    "pmrarad": 0.00000132698352504861,
    "pmdecrad": -7.05578438e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118596,
    "lum": 0.00308460813775713,
    "ra": 9.146287,
    "dec": 66.594062,
    "pmra": 273.71,
    "pmdec": -145.54,
    "rv": 0,
    "Asset Name": "ASTRID 002635",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Mohawk",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003202,
    "y_1": -0.0000124,
    "z_1": -0.00000652,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118596,
    "Gliese Catalog_1": "NN 3532"
  },
  {
    "Astrid #": "ASTRID 002636",
    "HYG Star ID": 118643,
    "Gliese Catalog": "NN 3565",
    "Distance in Parsecs": 23.2558,
    "mag": 16.41,
    "Absolute Visual Magnitude": 14.577,
    "Spectral Type": "DA9",
    "Color Index": 0.56,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -19.121315,
    "y": 12.924,
    "z": -2.859679,
    "vx": 0.00000863,
    "vy": 0.00000198,
    "vz": -0.00004881,
    "rarad": 2.54722856357516,
    "decrad": -0.12327828734795,
    "pmrarad": -2.78428496743055e-7,
    "pmdecrad": -0.000002114926959,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118643,
    "lum": 0.000128587869263439,
    "ra": 9.729696,
    "dec": -7.063326,
    "pmra": -57.43,
    "pmdec": -436.24,
    "rv": 0,
    "Asset Name": "ASTRID 002636",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "High Bun",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000863,
    "y_1": 0.00000198,
    "z_1": -0.00004881,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118643,
    "Gliese Catalog_1": "NN 3565"
  },
  {
    "Astrid #": "ASTRID 002637",
    "HYG Star ID": 118691,
    "Gliese Catalog": "NN 3608A",
    "Distance in Parsecs": 23.2558,
    "mag": 10.68,
    "Absolute Visual Magnitude": 8.847,
    "Spectral Type": "K7 V",
    "Color Index": 1.38,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.008288,
    "y": 8.179863,
    "z": -8.57849,
    "vx": 0.00000254,
    "vy": -0.00003111,
    "vz": -0.00003561,
    "rarad": 2.75349823997665,
    "decrad": -0.377798720692999,
    "pmrarad": 0.0000011968595331875,
    "pmdecrad": -0.00000164733386,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 118691,
    "base": "NN 3608",
    "lum": 0.0251883662784173,
    "ra": 10.517589,
    "dec": -21.646272,
    "pmra": 246.87,
    "pmdec": -339.79,
    "rv": 0,
    "Asset Name": "ASTRID 002637",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Braid",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000254,
    "y_1": -0.00003111,
    "z_1": -0.00003561,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118691,
    "Gliese Catalog_1": "NN 3608A"
  },
  {
    "Astrid #": "ASTRID 002638",
    "HYG Star ID": 118692,
    "Gliese Catalog": "NN 3609C",
    "Distance in Parsecs": 23.2558,
    "mag": 13.13,
    "Absolute Visual Magnitude": 11.297,
    "Color Index": 1.57,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -20.008288,
    "y": 8.179863,
    "z": -8.57849,
    "vx": 0.00000254,
    "vy": -0.00003111,
    "vz": -0.00003561,
    "rarad": 2.75349823997665,
    "decrad": -0.377798720692999,
    "pmrarad": 0.0000011968595331875,
    "pmdecrad": -0.00000164733386,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 118692,
    "base": "NN 3609",
    "lum": 0.00263754574088934,
    "ra": 10.517589,
    "dec": -21.646272,
    "pmra": 246.87,
    "pmdec": -339.79,
    "rv": 0,
    "Asset Name": "ASTRID 002638",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Loopy",
    "Hair": "Braid",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000254,
    "y_1": -0.00003111,
    "z_1": -0.00003561,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118692,
    "Gliese Catalog_1": "NN 3609C"
  },
  {
    "Astrid #": "ASTRID 002639",
    "HYG Star ID": 118706,
    "Gliese Catalog": "NN 3619",
    "Distance in Parsecs": 23.2558,
    "mag": 13.55,
    "Absolute Visual Magnitude": 11.717,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.741033,
    "y": 7.267137,
    "z": 3.917707,
    "vx": -0.00000112,
    "vy": 0.0000123,
    "vz": -0.00002905,
    "rarad": 2.81900953589764,
    "decrad": 0.16926864436715,
    "pmrarad": -4.86462047069444e-7,
    "pmdecrad": -0.000001267312657,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118706,
    "lum": 0.00179143064840602,
    "ra": 10.767823,
    "dec": 9.698379,
    "pmra": -100.34,
    "pmdec": -261.4,
    "rv": 0,
    "Asset Name": "ASTRID 002639",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Determined Neko",
    "Hair": "Medium Bob",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000112,
    "y_1": 0.0000123,
    "z_1": -0.00002905,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118706,
    "Gliese Catalog_1": "NN 3619"
  },
  {
    "Astrid #": "ASTRID 002640",
    "HYG Star ID": 118833,
    "Gliese Catalog": "NN 3736",
    "Distance in Parsecs": 23.2558,
    "mag": 13.1,
    "Absolute Visual Magnitude": 11.267,
    "Spectral Type": "M3: V",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -22.896401,
    "y": -3.665266,
    "z": -1.775621,
    "vx": -0.00000743,
    "vy": 0.00005377,
    "vz": -0.00001521,
    "rarad": 3.30032672146743,
    "decrad": -0.07642609964545,
    "pmrarad": -0.00000233365065135416,
    "pmdecrad": -6.55952909e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118833,
    "lum": 0.0027114400086883,
    "ra": 12.606319,
    "dec": -4.378893,
    "pmra": -481.35,
    "pmdec": -135.3,
    "rv": 0,
    "Asset Name": "ASTRID 002640",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Ninja",
    "Outfit": "Warlock",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000743,
    "y_1": 0.00005377,
    "z_1": -0.00001521,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118833,
    "Gliese Catalog_1": "NN 3736"
  },
  {
    "Astrid #": "ASTRID 002641",
    "HYG Star ID": 118876,
    "Gliese Catalog": "NN 3773",
    "Distance in Parsecs": 23.2558,
    "mag": 13.05,
    "Absolute Visual Magnitude": 11.217,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.377648,
    "y": -2.203163,
    "z": -22.255424,
    "vx": 4.1e-7,
    "vy": 0.00005608,
    "vz": -0.00000567,
    "rarad": 3.47448704373632,
    "decrad": -1.27642189458735,
    "pmrarad": -0.00000227348527359722,
    "pmdecrad": -8.40991747e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118876,
    "lum": 0.0028392262394249,
    "ra": 13.271563,
    "dec": -73.133587,
    "pmra": -468.94,
    "pmdec": -173.47,
    "rv": 0,
    "Asset Name": "ASTRID 002641",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Chuckle",
    "Hair": "Ponytail",
    "Outfit": "Wizard",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 4.1e-7,
    "y_1": 0.00005608,
    "z_1": -0.00000567,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118876,
    "Gliese Catalog_1": "NN 3773"
  },
  {
    "Astrid #": "ASTRID 002642",
    "HYG Star ID": 118912,
    "Gliese Catalog": "NN 3799",
    "Distance in Parsecs": 23.2558,
    "mag": 12.09,
    "Absolute Visual Magnitude": 10.257,
    "Spectral Type": "k-m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.311267,
    "y": -9.63775,
    "z": 5.949657,
    "vx": -0.00001015,
    "vy": 0.00002545,
    "vz": 0.00000659,
    "rarad": 3.58463714437275,
    "decrad": 0.2587118127657,
    "pmrarad": -0.00000117601254492361,
    "pmdecrad": 2.93215314e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118912,
    "lum": 0.00687384919590322,
    "ra": 13.692305,
    "dec": 14.823095,
    "pmra": -242.57,
    "pmdec": 60.48,
    "rv": 0,
    "Asset Name": "ASTRID 002642",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Braided Bun",
    "Outfit": "Iron Star",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001015,
    "y_1": 0.00002545,
    "z_1": 0.00000659,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118912,
    "Gliese Catalog_1": "NN 3799"
  },
  {
    "Astrid #": "ASTRID 002643",
    "HYG Star ID": 118951,
    "Gliese Catalog": "NN 3835",
    "Distance in Parsecs": 23.2558,
    "mag": 12.37,
    "Absolute Visual Magnitude": 10.537,
    "Spectral Type": "M2",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.019278,
    "y": -12.705882,
    "z": 4.202365,
    "vx": -0.00000523,
    "vy": -0.00000428,
    "vz": -0.00003658,
    "rarad": 3.7305542372634,
    "decrad": 0.181699931105499,
    "pmrarad": 2.7925268e-8,
    "pmdecrad": -0.00000159963789,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118951,
    "lum": 0.00531128981855012,
    "ra": 14.249668,
    "dec": 10.410639,
    "pmra": 5.76,
    "pmdec": -329.95,
    "rv": 0,
    "Asset Name": "ASTRID 002643",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Slick",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000523,
    "y_1": -0.00000428,
    "z_1": -0.00003658,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118951,
    "Gliese Catalog_1": "NN 3835"
  },
  {
    "Astrid #": "ASTRID 002644",
    "HYG Star ID": 118963,
    "Gliese Catalog": "NN 3844",
    "Distance in Parsecs": 23.2558,
    "mag": 12.62,
    "Absolute Visual Magnitude": 10.787,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.828767,
    "y": -11.899938,
    "z": 10.771087,
    "vx": -0.00001287,
    "vy": 0.00002802,
    "vz": 0.00001086,
    "rarad": 3.75708875464878,
    "decrad": 0.481554008867049,
    "pmrarad": -0.00000130356702427777,
    "pmdecrad": 5.26677341e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118963,
    "lum": 0.00421890746567797,
    "ra": 14.351022,
    "dec": 27.591012,
    "pmra": -268.88,
    "pmdec": 108.64,
    "rv": 0,
    "Asset Name": "ASTRID 002644",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Round Brush",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001287,
    "y_1": 0.00002802,
    "z_1": 0.00001086,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118963,
    "Gliese Catalog_1": "NN 3844"
  },
  {
    "Astrid #": "ASTRID 002645",
    "HYG Star ID": 118964,
    "Gliese Catalog": "NN 3845",
    "Distance in Parsecs": 23.2558,
    "mag": 15,
    "Absolute Visual Magnitude": 13.167,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.457162,
    "y": -12.581327,
    "z": -8.819864,
    "vx": -0.00000169,
    "vy": 0.00003583,
    "vz": -0.00004778,
    "rarad": 3.76607989133638,
    "decrad": -0.38899036008925,
    "pmrarad": -0.00000129251327236111,
    "pmdecrad": -0.000002220761785,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118964,
    "lum": 0.000471194269420275,
    "ra": 14.385366,
    "dec": -22.287506,
    "pmra": -266.6,
    "pmdec": -458.06,
    "rv": 0,
    "Asset Name": "ASTRID 002645",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Wavy",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000169,
    "y_1": 0.00003583,
    "z_1": -0.00004778,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118964,
    "Gliese Catalog_1": "NN 3845"
  },
  {
    "Astrid #": "ASTRID 002646",
    "HYG Star ID": 118987,
    "Gliese Catalog": "NN 3869B",
    "Distance in Parsecs": 23.2558,
    "mag": 8.9,
    "Absolute Visual Magnitude": 7.067,
    "Spectral Type": "k",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.829704,
    "y": -15.561938,
    "z": 3.926758,
    "vx": -0.00001567,
    "vy": 0.00001837,
    "vz": 0.00000566,
    "rarad": 3.88787286451211,
    "decrad": 0.169663521870299,
    "pmrarad": -0.00000103754975775694,
    "pmdecrad": 2.47177406e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 118987,
    "base": "NN 3869",
    "lum": 0.129777678168578,
    "ra": 14.85058,
    "dec": 9.721004,
    "pmra": -214.01,
    "pmdec": 50.98,
    "rv": 0,
    "Asset Name": "ASTRID 002646",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Pixie",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00001567,
    "y_1": 0.00001837,
    "z_1": 0.00000566,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 118987,
    "Gliese Catalog_1": "NN 3869B"
  },
  {
    "Astrid #": "ASTRID 002647",
    "HYG Star ID": 119001,
    "Gliese Catalog": "NN 3886",
    "Distance in Parsecs": 23.2558,
    "mag": 12.05,
    "Absolute Visual Magnitude": 10.217,
    "Spectral Type": "M2",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.325371,
    "y": -13.464854,
    "z": 13.489419,
    "vx": 0.00000162,
    "vy": -0.0000263,
    "vz": -0.00002464,
    "rarad": 3.93218763366412,
    "decrad": 0.61878434630665,
    "pmrarad": 8.44933282472222e-7,
    "pmdecrad": -0.000001301113867,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119001,
    "lum": 0.00713181386679739,
    "ra": 15.01985,
    "dec": 35.453731,
    "pmra": 174.28,
    "pmdec": -268.37,
    "rv": 0,
    "Asset Name": "ASTRID 002647",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Halo Boy",
    "Outfit": "Boxer",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000162,
    "y_1": -0.0000263,
    "z_1": -0.00002464,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119001,
    "Gliese Catalog_1": "NN 3886"
  },
  {
    "Astrid #": "ASTRID 002648",
    "HYG Star ID": 119024,
    "Gliese Catalog": "NN 3908",
    "Distance in Parsecs": 23.2558,
    "mag": 13.7,
    "Absolute Visual Magnitude": 11.867,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.505348,
    "y": -16.539727,
    "z": -10.53024,
    "vx": -0.00001934,
    "vy": 0.00004263,
    "vz": -0.00004402,
    "rarad": 4.06501907844025,
    "decrad": -0.4699039090459,
    "pmrarad": -0.000001768891194875,
    "pmdecrad": -0.000002123100918,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119024,
    "lum": 0.00156027086869305,
    "ra": 15.527229,
    "dec": -26.923511,
    "pmra": -364.86,
    "pmdec": -437.92,
    "rv": 0,
    "Asset Name": "ASTRID 002648",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Horns",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001934,
    "y_1": 0.00004263,
    "z_1": -0.00004402,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119024,
    "Gliese Catalog_1": "NN 3908"
  },
  {
    "Astrid #": "ASTRID 002649",
    "HYG Star ID": 119065,
    "Gliese Catalog": "NN 3939",
    "Distance in Parsecs": 23.2558,
    "mag": 13.46,
    "Absolute Visual Magnitude": 11.627,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.905735,
    "y": -20.397476,
    "z": 2.416648,
    "vx": -0.00003061,
    "vy": 0.0000134,
    "vz": -0.00002497,
    "rarad": 4.22139898377869,
    "decrad": 0.1041039020265,
    "pmrarad": -0.00000143262442604166,
    "pmdecrad": -0.00000107953947,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119065,
    "lum": 0.00194625615936123,
    "ra": 16.124556,
    "dec": 5.964714,
    "pmra": -295.5,
    "pmdec": -222.67,
    "rv": 0,
    "Asset Name": "ASTRID 002649",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Bring It",
    "Hair": "Audiophile",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003061,
    "y_1": 0.0000134,
    "z_1": -0.00002497,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119065,
    "Gliese Catalog_1": "NN 3939"
  },
  {
    "Astrid #": "ASTRID 002650",
    "HYG Star ID": 119105,
    "Gliese Catalog": "NN 3969",
    "Distance in Parsecs": 23.2558,
    "mag": 12.06,
    "Absolute Visual Magnitude": 10.227,
    "Spectral Type": "g-k",
    "Color Index": 1.5,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.745072,
    "y": -21.800842,
    "z": -2.359949,
    "vx": 0.00000288,
    "vy": 0.00000164,
    "vz": -0.00002458,
    "rarad": 4.37103157893912,
    "decrad": -0.1016528673679,
    "pmrarad": 9.29387825625e-8,
    "pmdecrad": -0.000001062527358,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119105,
    "lum": 0.00706642900425207,
    "ra": 16.696111,
    "dec": -5.82428,
    "pmra": 19.17,
    "pmdec": -219.16,
    "rv": 0,
    "Asset Name": "ASTRID 002650",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Nervous Grin",
    "Hair": "Unkempt",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000288,
    "y_1": 0.00000164,
    "z_1": -0.00002458,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119105,
    "Gliese Catalog_1": "NN 3969"
  },
  {
    "Astrid #": "ASTRID 002651",
    "HYG Star ID": 119116,
    "Gliese Catalog": "GJ 1208",
    "Distance in Parsecs": 23.2558,
    "mag": 14.06,
    "Absolute Visual Magnitude": 12.227,
    "Spectral Type": "DA6",
    "Color Index": 0.25,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -5.861838,
    "y": -20.839346,
    "z": 8.50307,
    "vx": -0.00001487,
    "vy": -0.00006568,
    "vz": -0.00004251,
    "rarad": 4.43818666597881,
    "decrad": 0.3742721905108,
    "pmrarad": 1.49419576347222e-7,
    "pmdecrad": -0.000002759389784,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119116,
    "lum": 0.00111995352238488,
    "ra": 16.952624,
    "dec": 21.444217,
    "pmra": 30.82,
    "pmdec": -569.16,
    "rv": 46,
    "Asset Name": "ASTRID 002651",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Wavy",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001487,
    "y_1": -0.00006568,
    "z_1": -0.00004251,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119116,
    "Gliese Catalog_1": "GJ 1208"
  },
  {
    "Astrid #": "ASTRID 002652",
    "HYG Star ID": 119127,
    "Henry Draper Catalogue": 154906,
    "Harvard Revised Catalogue": 6370,
    "Gliese Catalog": "Wo 9584A",
    "Distance in Parsecs": 23.2558,
    "mag": 5.63,
    "Absolute Visual Magnitude": 3.797,
    "Spectral Type": "F7 V",
    "Color Index": 0.47,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.193886,
    "y": -13.13167,
    "z": 18.925026,
    "vx": -0.00000459,
    "vy": 0.00001737,
    "vz": -0.00000669,
    "rarad": 4.47381476908013,
    "decrad": 0.95066502064635,
    "pmrarad": -3.68458397222222e-7,
    "pmdecrad": 3.85552927e-7,
    "bayer": "Mu",
    "flam": 21,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 119127,
    "base": "Wo 9584",
    "lum": 2.63754574088934,
    "bf": "21Mu  Dra",
    "ra": 17.088714,
    "dec": 54.469093,
    "pmra": -76,
    "pmdec": 79.53,
    "rv": -14.3,
    "Asset Name": "ASTRID 002652",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Horns",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000459,
    "y_1": 0.00001737,
    "z_1": -0.00000669,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119127,
    "Henry Draper Catalogue_1": 154906,
    "Harvard Revised Catalogue_1": 6370,
    "Gliese Catalog_1": "Wo 9584A"
  },
  {
    "Astrid #": "ASTRID 002653",
    "HYG Star ID": 119128,
    "Gliese Catalog": "Wo 9584C",
    "Distance in Parsecs": 23.2558,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.667,
    "Spectral Type": "M3 :",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.193987,
    "y": -13.132083,
    "z": 18.925621,
    "vx": -0.0000066,
    "vy": 0.00000911,
    "vz": 0.00000521,
    "rarad": 4.47381476908013,
    "decrad": 0.95066502064635,
    "pmrarad": -3.68458397222222e-7,
    "pmdecrad": 3.85552927e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 119127,
    "base": "Wo 9584",
    "lum": 0.00187585817379928,
    "ra": 17.088714,
    "dec": 54.469093,
    "pmra": -76,
    "pmdec": 79.53,
    "rv": 0,
    "Asset Name": "ASTRID 002653",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Sweetheart",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000066,
    "y_1": 0.00000911,
    "z_1": 0.00000521,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119128,
    "Gliese Catalog_1": "Wo 9584C"
  },
  {
    "Astrid #": "ASTRID 002654",
    "HYG Star ID": 119185,
    "Gliese Catalog": "NN 4031",
    "Distance in Parsecs": 23.2558,
    "mag": 13.71,
    "Absolute Visual Magnitude": 11.877,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.833457,
    "y": -22.473434,
    "z": 5.923034,
    "vx": -0.00002597,
    "vy": 0.00000435,
    "vz": 0.00001287,
    "rarad": 4.67532158203138,
    "decrad": 0.2575277539762,
    "pmrarad": -0.000001123119372375,
    "pmdecrad": 5.72259524e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119185,
    "lum": 0.00154596621938667,
    "ra": 17.858413,
    "dec": 14.755253,
    "pmra": -231.66,
    "pmdec": 118.04,
    "rv": 0,
    "Asset Name": "ASTRID 002654",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Braid",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002597,
    "y_1": 0.00000435,
    "z_1": 0.00001287,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119185,
    "Gliese Catalog_1": "NN 4031"
  },
  {
    "Astrid #": "ASTRID 002655",
    "HYG Star ID": 119343,
    "Gliese Catalog": "NN 4146",
    "Distance in Parsecs": 23.2558,
    "mag": 12.27,
    "Absolute Visual Magnitude": 10.437,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.335373,
    "y": -16.529675,
    "z": 10.744333,
    "vx": -0.00000872,
    "vy": 0.00001169,
    "vz": 0.000028,
    "rarad": 5.353489767,
    "decrad": 0.48025639791525,
    "pmrarad": 0,
    "pmdecrad": 0.000001357478305,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119343,
    "lum": 0.00582371347943751,
    "ra": 20.448825,
    "dec": 27.516665,
    "pmra": 0,
    "pmdec": 280,
    "rv": 0,
    "Asset Name": "ASTRID 002655",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "High Fade",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000872,
    "y_1": 0.00001169,
    "z_1": 0.000028,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119343,
    "Gliese Catalog_1": "NN 4146"
  },
  {
    "Astrid #": "ASTRID 002656",
    "HYG Star ID": 119353,
    "Gliese Catalog": "NN 4150",
    "Distance in Parsecs": 23.2558,
    "mag": 13.08,
    "Absolute Visual Magnitude": 11.247,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.273889,
    "y": -7.887008,
    "z": 20.958665,
    "vx": -0.00002896,
    "vy": -0.00003844,
    "vz": -0.00000579,
    "rarad": 5.38449345855664,
    "decrad": 1.1225837909717,
    "pmrarad": -0.00000200392886720833,
    "pmdecrad": -5.74620566e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119353,
    "lum": 0.00276184944207529,
    "ra": 20.56725,
    "dec": 64.319313,
    "pmra": -413.34,
    "pmdec": -118.52,
    "rv": 0,
    "Asset Name": "ASTRID 002656",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Prince",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002896,
    "y_1": -0.00003844,
    "z_1": -0.00000579,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119353,
    "Gliese Catalog_1": "NN 4150"
  },
  {
    "Astrid #": "ASTRID 002657",
    "HYG Star ID": 119356,
    "Gliese Catalog": "NN 4153",
    "Distance in Parsecs": 23.2558,
    "mag": 11.44,
    "Absolute Visual Magnitude": 9.607,
    "Spectral Type": "M2",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.672975,
    "y": -16.683636,
    "z": 8.691277,
    "vx": 0.00000313,
    "vy": -0.00001246,
    "vz": -0.00002883,
    "rarad": 5.39893487717942,
    "decrad": 0.38302189128345,
    "pmrarad": -2.35716411486111e-7,
    "pmdecrad": -0.000001336854331,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 119356,
    "lum": 0.0125083492837406,
    "ra": 20.622412,
    "dec": 21.945538,
    "pmra": -48.62,
    "pmdec": -275.75,
    "rv": 0,
    "Asset Name": "ASTRID 002657",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Loopy",
    "Hair": "Bucket Hat",
    "Outfit": "Boxer",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00000313,
    "y_1": -0.00001246,
    "z_1": -0.00002883,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119356,
    "Gliese Catalog_1": "NN 4153"
  },
  {
    "Astrid #": "ASTRID 002658",
    "HYG Star ID": 119377,
    "Gliese Catalog": "NN 4170",
    "Distance in Parsecs": 23.2558,
    "mag": 11.7,
    "Absolute Visual Magnitude": 9.867,
    "Spectral Type": "M3",
    "Color Index": 1.54,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.640458,
    "y": -6.04833,
    "z": 21.735574,
    "vx": 0.00001476,
    "vy": 0.00002122,
    "vz": 0.00000207,
    "rarad": 5.4628085823729,
    "decrad": 1.2072178566451,
    "pmrarad": 0.00000108646745812499,
    "pmdecrad": 2.50832902e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 119377,
    "lum": 0.00984464363980377,
    "ra": 20.866392,
    "dec": 69.168488,
    "pmra": 224.1,
    "pmdec": 51.74,
    "rv": 0,
    "Asset Name": "ASTRID 002658",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Serious Mad",
    "Hair": "Innocent",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001476,
    "y_1": 0.00002122,
    "z_1": 0.00000207,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119377,
    "Gliese Catalog_1": "NN 4170"
  },
  {
    "Astrid #": "ASTRID 002659",
    "HYG Star ID": 119429,
    "Gliese Catalog": "NN 4212",
    "Distance in Parsecs": 23.2558,
    "mag": 13.97,
    "Absolute Visual Magnitude": 12.137,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.970022,
    "y": -11.66572,
    "z": 10.805628,
    "vx": -0.00001633,
    "vy": -0.00002906,
    "vz": -0.00000571,
    "rarad": 5.6809539816096,
    "decrad": 0.483230596147649,
    "pmrarad": -0.00000142772780786805,
    "pmdecrad": -2.77517047e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119429,
    "lum": 0.00121674620397912,
    "ra": 21.699646,
    "dec": 27.687074,
    "pmra": -294.49,
    "pmdec": -57.24,
    "rv": 0,
    "Asset Name": "ASTRID 002659",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Greasy",
    "Outfit": "Spartan",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001633,
    "y_1": -0.00002906,
    "z_1": -0.00000571,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119429,
    "Gliese Catalog_1": "NN 4212"
  },
  {
    "Astrid #": "ASTRID 002660",
    "HYG Star ID": 119487,
    "Gliese Catalog": "NN 4271",
    "Distance in Parsecs": 23.2558,
    "mag": 14.2,
    "Absolute Visual Magnitude": 12.367,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.937097,
    "y": -8.087722,
    "z": -13.731561,
    "vx": -0.00002652,
    "vy": 0.00002742,
    "vz": -0.00004888,
    "rarad": 5.83767989524783,
    "decrad": -0.63162554943995,
    "pmrarad": 5.72564956736111e-7,
    "pmdecrad": -0.000002604268799,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119487,
    "lum": 0.000984464363980377,
    "ra": 22.298295,
    "dec": -36.189478,
    "pmra": 118.1,
    "pmdec": -537.17,
    "rv": 0,
    "Asset Name": "ASTRID 002660",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "High Bun",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002652,
    "y_1": 0.00002742,
    "z_1": -0.00004888,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119487,
    "Gliese Catalog_1": "NN 4271"
  },
  {
    "Astrid #": "ASTRID 002661",
    "HYG Star ID": 119547,
    "Gliese Catalog": "NN 4314",
    "Distance in Parsecs": 23.2558,
    "mag": 12.66,
    "Absolute Visual Magnitude": 10.827,
    "Spectral Type": "k-m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 22.683389,
    "y": -5.064914,
    "z": -0.801703,
    "vx": 0.00000518,
    "vy": 0.00002943,
    "vz": -0.00003959,
    "rarad": 6.0635015293313,
    "decrad": -0.0344800995212499,
    "pmrarad": 0.00000128378662611111,
    "pmdecrad": -0.000001703630425,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119547,
    "lum": 0.00406630546340428,
    "ra": 23.16087,
    "dec": -1.975564,
    "pmra": 264.8,
    "pmdec": -351.4,
    "rv": 0,
    "Asset Name": "ASTRID 002661",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "High Bun",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000518,
    "y_1": 0.00002943,
    "z_1": -0.00003959,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119547,
    "Gliese Catalog_1": "NN 4314"
  },
  {
    "Astrid #": "ASTRID 002662",
    "HYG Star ID": 119605,
    "Gliese Catalog": "NN 4374",
    "Distance in Parsecs": 23.2558,
    "mag": 13.01,
    "Absolute Visual Magnitude": 11.177,
    "Spectral Type": "M3:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.01352,
    "y": -0.558489,
    "z": 3.301243,
    "vx": -6e-8,
    "vy": -0.0000323,
    "vz": -0.00000506,
    "rarad": 6.25892292140874,
    "decrad": 0.1424346512137,
    "pmrarad": -0.00000138865182521527,
    "pmdecrad": -2.19935726e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119605,
    "lum": 0.00294577790233945,
    "ra": 23.907325,
    "dec": 8.160904,
    "pmra": -286.43,
    "pmdec": -45.37,
    "rv": 0,
    "Asset Name": "ASTRID 002662",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Braid",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -6e-8,
    "y_1": -0.0000323,
    "z_1": -0.00000506,
    "Distance in Parsecs_1": 23.2558,
    "HYG Star ID_1": 119605,
    "Gliese Catalog_1": "NN 4374"
  },
  {
    "Astrid #": "ASTRID 002663",
    "HYG Star ID": 115315,
    "Hipparcos Catalogue": 115680,
    "Gliese Catalog": "GJ 2155",
    "Distance in Parsecs": 23.2666,
    "mag": 10.56,
    "Absolute Visual Magnitude": 8.726,
    "Spectral Type": "sdM:",
    "Color Index": 1.461,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": 22.73741,
    "y": -3.377226,
    "z": 3.597109,
    "vx": 0.00000495,
    "vy": 0.00005847,
    "vz": 0.00002359,
    "rarad": 6.1357313650855,
    "decrad": 0.155226633294773,
    "pmrarad": 0.00000251715262944444,
    "pmdecrad": 0.000001026205117,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 115315,
    "lum": 0.0281578829971031,
    "var_min": 10.606,
    "var_max": 10.496,
    "ra": 23.436767,
    "dec": 8.893831,
    "pmra": 519.2,
    "pmdec": 211.67,
    "rv": 0,
    "Asset Name": "ASTRID 002663",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Bangs",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000495,
    "y_1": 0.00005847,
    "z_1": 0.00002359,
    "Distance in Parsecs_1": 23.2666,
    "HYG Star ID_1": 115315,
    "Hipparcos Catalogue_1": 115680,
    "Gliese Catalog_1": "GJ 2155"
  },
  {
    "Astrid #": "ASTRID 002664",
    "HYG Star ID": 39667,
    "Hipparcos Catalogue": 39780,
    "Henry Draper Catalogue": 67228,
    "Harvard Revised Catalogue": 3176,
    "Distance in Parsecs": 23.2883,
    "mag": 5.3,
    "Absolute Visual Magnitude": 3.464,
    "Spectral Type": "G2IV",
    "Color Index": 0.642,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.456683,
    "y": 18.376558,
    "z": 8.566004,
    "vx": 0.00001442,
    "vy": -0.00002806,
    "vz": -0.0000206,
    "rarad": 2.12827300808122,
    "decrad": 0.376673746022926,
    "pmrarad": 1.11894997472222e-7,
    "pmdecrad": -3.25940237e-7,
    "bayer": "Mu-2",
    "flam": 10,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 39667,
    "lum": 3.58426408043714,
    "bf": "10Mu 2Cnc",
    "ra": 8.129404,
    "dec": 21.581816,
    "pmra": 23.08,
    "pmdec": -67.23,
    "rv": -36,
    "Asset Name": "ASTRID 002664",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Owie",
    "Hair": "Grecian",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00001442,
    "y_1": -0.00002806,
    "z_1": -0.0000206,
    "Distance in Parsecs_1": 23.2883,
    "HYG Star ID_1": 39667,
    "Hipparcos Catalogue_1": 39780,
    "Henry Draper Catalogue_1": 67228,
    "Harvard Revised Catalogue_1": 3176
  },
  {
    "Astrid #": "ASTRID 002665",
    "HYG Star ID": 118766,
    "Gliese Catalog": "Gl 433.2B",
    "Distance in Parsecs": 23.2883,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.564,
    "Spectral Type": "K2 V",
    "Color Index": 0.96,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.364859,
    "y": 1.522971,
    "z": 16.497632,
    "vx": 0.00001867,
    "vy": 0.00006401,
    "vz": -0.00001466,
    "rarad": 3.04885499654552,
    "decrad": 0.78728256972655,
    "pmrarad": -0.0000028112890894375,
    "pmdecrad": -5.8885469e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 56639,
    "base": "Gl 433.2",
    "lum": 0.206252869784657,
    "ra": 11.645768,
    "dec": 45.107969,
    "pmra": -579.87,
    "pmdec": -12.15,
    "rv": -18.9,
    "Asset Name": "ASTRID 002665",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Worried Vamp",
    "Hair": "Tiny Crown",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001867,
    "y_1": 0.00006401,
    "z_1": -0.00001466,
    "Distance in Parsecs_1": 23.2883,
    "HYG Star ID_1": 118766,
    "Gliese Catalog_1": "Gl 433.2B"
  },
  {
    "Astrid #": "ASTRID 002666",
    "HYG Star ID": 67547,
    "Hipparcos Catalogue": 67761,
    "Distance in Parsecs": 23.2937,
    "mag": 11.79,
    "Absolute Visual Magnitude": 9.954,
    "Color Index": 1.496,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.482146,
    "y": -10.456209,
    "z": -7.328721,
    "vx": -0.00001126,
    "vy": 0.0000302,
    "vz": -0.00001318,
    "rarad": 3.63417370343705,
    "decrad": -0.320058889837748,
    "pmrarad": -0.00000137115005134722,
    "pmdecrad": -5.95981457e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 67547,
    "lum": 0.00908657048958145,
    "var_min": 11.876,
    "var_max": 11.606,
    "ra": 13.881521,
    "dec": -18.338024,
    "pmra": -282.82,
    "pmdec": -122.93,
    "rv": 0,
    "Asset Name": "ASTRID 002666",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Shinobi",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001126,
    "y_1": 0.0000302,
    "z_1": -0.00001318,
    "Distance in Parsecs_1": 23.2937,
    "HYG Star ID_1": 67547,
    "Hipparcos Catalogue_1": 67761
  },
  {
    "Astrid #": "ASTRID 002667",
    "HYG Star ID": 15934,
    "Hipparcos Catalogue": 15973,
    "Distance in Parsecs": 23.2992,
    "mag": 11.26,
    "Absolute Visual Magnitude": 9.423,
    "Spectral Type": "A5",
    "Color Index": 1.439,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 10.629605,
    "y": 13.32282,
    "z": -15.886053,
    "vx": -0.00003481,
    "vy": 0.00001481,
    "vz": -0.00001087,
    "rarad": 0.897363008320287,
    "decrad": -0.750259039205317,
    "pmrarad": 0.00000156449374715277,
    "pmdecrad": -6.37966322e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 15934,
    "lum": 0.0148183551751643,
    "var_min": 11.358,
    "var_max": 11.178,
    "ra": 3.427674,
    "dec": -42.986676,
    "pmra": 322.7,
    "pmdec": -131.59,
    "rv": 0,
    "Asset Name": "ASTRID 002667",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Guilty Grin",
    "Hair": "Straw Hat",
    "Outfit": "Elven",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00003481,
    "y_1": 0.00001481,
    "z_1": -0.00001087,
    "Distance in Parsecs_1": 23.2992,
    "HYG Star ID_1": 15934,
    "Hipparcos Catalogue_1": 15973
  },
  {
    "Astrid #": "ASTRID 002668",
    "HYG Star ID": 3355,
    "Hipparcos Catalogue": 3362,
    "Gliese Catalog": "Gl 29.1",
    "Distance in Parsecs": 23.3046,
    "mag": 10.37,
    "Absolute Visual Magnitude": 8.533,
    "Spectral Type": "M1Ve+...",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.631359,
    "y": 3.520785,
    "z": 13.549196,
    "vx": -0.00001077,
    "vy": 0.00002843,
    "vz": 0.00000655,
    "rarad": 0.186765970193005,
    "decrad": 0.620443318954962,
    "pmrarad": 0.00000128470777210416,
    "pmdecrad": 3.6113771e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 3355,
    "lum": 0.0336356635748362,
    "var": "FF",
    "var_min": 10.426,
    "var_max": 10.286,
    "ra": 0.713393,
    "dec": 35.548784,
    "pmra": 264.99,
    "pmdec": 74.49,
    "rv": -0.5,
    "Asset Name": "ASTRID 002668",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Audiohead",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001077,
    "y_1": 0.00002843,
    "z_1": 0.00000655,
    "Distance in Parsecs_1": 23.3046,
    "HYG Star ID_1": 3355,
    "Hipparcos Catalogue_1": 3362,
    "Gliese Catalog_1": "Gl 29.1"
  },
  {
    "Astrid #": "ASTRID 002669",
    "HYG Star ID": 12460,
    "Hipparcos Catalogue": 12493,
    "Gliese Catalog": "Gl 105.5",
    "Distance in Parsecs": 23.3046,
    "mag": 9.51,
    "Absolute Visual Magnitude": 7.673,
    "Spectral Type": "M0",
    "Color Index": 1.185,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.802213,
    "y": 15.03266,
    "z": 0.487531,
    "vx": 0.00004077,
    "vy": 0.00007608,
    "vz": 0.00002777,
    "rarad": 0.70124941135436,
    "decrad": 0.0209208326301862,
    "pmrarad": 0.00000136552621265277,
    "pmdecrad": 0.000001119386307,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12460,
    "lum": 0.0742677043714697,
    "ra": 2.678575,
    "dec": 1.198675,
    "pmra": 281.66,
    "pmdec": 230.89,
    "rv": 79,
    "Asset Name": "ASTRID 002669",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Audiohead",
    "Outfit": "Boxer",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00004077,
    "y_1": 0.00007608,
    "z_1": 0.00002777,
    "Distance in Parsecs_1": 23.3046,
    "HYG Star ID_1": 12460,
    "Hipparcos Catalogue_1": 12493,
    "Gliese Catalog_1": "Gl 105.5"
  },
  {
    "Astrid #": "ASTRID 002670",
    "HYG Star ID": 119427,
    "Gliese Catalog": "Wo 9747",
    "Distance in Parsecs": 23.31,
    "mag": 14.1,
    "Absolute Visual Magnitude": 12.262,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.239228,
    "y": -7.833074,
    "z": 18.859449,
    "vx": 0.00001789,
    "vy": 0.00004218,
    "vz": 0.00000686,
    "rarad": 5.67444811433794,
    "decrad": 0.9425699942621,
    "pmrarad": 0.00000192354675897222,
    "pmdecrad": 5.01045242e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119427,
    "lum": 0.00108442619576707,
    "ra": 21.674795,
    "dec": 54.005283,
    "pmra": 396.76,
    "pmdec": 103.35,
    "rv": 0,
    "Asset Name": "ASTRID 002670",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Lovestruck",
    "Hair": "Ram Horns",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001789,
    "y_1": 0.00004218,
    "z_1": 0.00000686,
    "Distance in Parsecs_1": 23.31,
    "HYG Star ID_1": 119427,
    "Gliese Catalog_1": "Wo 9747"
  },
  {
    "Astrid #": "ASTRID 002671",
    "HYG Star ID": 33718,
    "Hipparcos Catalogue": 33805,
    "Distance in Parsecs": 23.3155,
    "mag": 10.93,
    "Absolute Visual Magnitude": 9.092,
    "Spectral Type": "M0",
    "Color Index": 1.476,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.086882,
    "y": 11.3025,
    "z": 20.157807,
    "vx": 0.00001839,
    "vy": 0.00000401,
    "vz": 5.7e-7,
    "rarad": 1.83741618420335,
    "decrad": 1.04428769193932,
    "pmrarad": -8.06148188027777e-7,
    "pmdecrad": 4.8335923e-8,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 33718,
    "lum": 0.0201001824711369,
    "var_min": 10.997,
    "var_max": 10.857,
    "ra": 7.018413,
    "dec": 59.833277,
    "pmra": -166.28,
    "pmdec": 9.97,
    "rv": 0,
    "Asset Name": "ASTRID 002671",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Slick",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00001839,
    "y_1": 0.00000401,
    "z_1": 5.7e-7,
    "Distance in Parsecs_1": 23.3155,
    "HYG Star ID_1": 33718,
    "Hipparcos Catalogue_1": 33805
  },
  {
    "Astrid #": "ASTRID 002672",
    "HYG Star ID": 56075,
    "Hipparcos Catalogue": 56242,
    "Henry Draper Catalogue": 100180,
    "Harvard Revised Catalogue": 4437,
    "Gliese Catalog": "NN 3669A",
    "Distance in Parsecs": 23.3263,
    "mag": 6.27,
    "Absolute Visual Magnitude": 4.431,
    "Spectral Type": "G0V",
    "Color Index": 0.57,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -22.425551,
    "y": 2.778437,
    "z": 5.787008,
    "vx": 0.00000343,
    "vy": 0.00003693,
    "vz": -0.00002174,
    "rarad": 3.01832522793791,
    "decrad": 0.25070790769591,
    "pmrarad": -0.00000158960709580555,
    "pmdecrad": -9.14891896e-7,
    "flam": 88,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 56075,
    "base": "NN 3669",
    "lum": 1.47095707679697,
    "bf": "88    Leo",
    "ra": 11.529153,
    "dec": 14.364505,
    "pmra": -327.88,
    "pmdec": -188.71,
    "rv": -4.2,
    "Asset Name": "ASTRID 002672",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Bandana",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000343,
    "y_1": 0.00003693,
    "z_1": -0.00002174,
    "Distance in Parsecs_1": 23.3263,
    "HYG Star ID_1": 56075,
    "Hipparcos Catalogue_1": 56242,
    "Henry Draper Catalogue_1": 100180,
    "Harvard Revised Catalogue_1": 4437,
    "Gliese Catalog_1": "NN 3669A"
  },
  {
    "Astrid #": "ASTRID 002673",
    "HYG Star ID": 116247,
    "Hipparcos Catalogue": 116613,
    "Henry Draper Catalogue": 222143,
    "Harvard Revised Catalogue": 8964,
    "Gliese Catalog": "NN 4351",
    "Distance in Parsecs": 23.3318,
    "mag": 6.58,
    "Absolute Visual Magnitude": 4.74,
    "Spectral Type": "G5",
    "Color Index": 0.665,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.07459,
    "y": -1.54959,
    "z": 16.839803,
    "vx": 0.00000436,
    "vy": 0.00004018,
    "vz": -0.00000131,
    "rarad": 6.18707550836634,
    "decrad": 0.806332280548926,
    "pmrarad": 0.00000173238472472916,
    "pmdecrad": -5.3765837e-8,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 116247,
    "lum": 1.10662378397766,
    "var": "V454",
    "var_min": 6.603,
    "var_max": 6.563,
    "ra": 23.632888,
    "dec": 46.199437,
    "pmra": 357.33,
    "pmdec": -11.09,
    "rv": -0.6,
    "Asset Name": "ASTRID 002673",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Manbun",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000436,
    "y_1": 0.00004018,
    "z_1": -0.00000131,
    "Distance in Parsecs_1": 23.3318,
    "HYG Star ID_1": 116247,
    "Hipparcos Catalogue_1": 116613,
    "Henry Draper Catalogue_1": 222143,
    "Harvard Revised Catalogue_1": 8964,
    "Gliese Catalog_1": "NN 4351"
  },
  {
    "Astrid #": "ASTRID 002674",
    "HYG Star ID": 111958,
    "Hipparcos Catalogue": 112312,
    "Distance in Parsecs": 23.3427,
    "mag": 11.7,
    "Absolute Visual Magnitude": 9.859,
    "Spectral Type": "M3",
    "Color Index": 1.3,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.484162,
    "y": -6.277579,
    "z": -12.798805,
    "vx": -3.2e-7,
    "vy": 0.00002199,
    "vz": -0.00001125,
    "rarad": 5.9557865681696,
    "decrad": -0.580330228607692,
    "pmrarad": 8.87790811833333e-7,
    "pmdecrad": -5.76298022e-7,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 111958,
    "lum": 0.00991744955231413,
    "var": "WW",
    "var_min": 11.814,
    "var_max": 11.464,
    "ra": 22.749429,
    "dec": -33.250473,
    "pmra": 183.12,
    "pmdec": -118.87,
    "rv": 0,
    "Asset Name": "ASTRID 002674",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Afro",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -3.2e-7,
    "y_1": 0.00002199,
    "z_1": -0.00001125,
    "Distance in Parsecs_1": 23.3427,
    "HYG Star ID_1": 111958,
    "Hipparcos Catalogue_1": 112312
  },
  {
    "Astrid #": "ASTRID 002675",
    "HYG Star ID": 7999,
    "Hipparcos Catalogue": 8014,
    "Gliese Catalog": "NN 3110",
    "Distance in Parsecs": 23.3536,
    "mag": 11.19,
    "Absolute Visual Magnitude": 9.348,
    "Spectral Type": "M:",
    "Color Index": 1.381,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.583944,
    "y": 7.510911,
    "z": -15.688134,
    "vx": -0.00004129,
    "vy": 0.00006163,
    "vz": -0.00001151,
    "rarad": 0.449105960751585,
    "decrad": -0.736589020460863,
    "pmrarad": 0.00000314522875260416,
    "pmdecrad": -6.65309813e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 7999,
    "lum": 0.0158781536420561,
    "var_min": 11.261,
    "var_max": 11.111,
    "ra": 1.715458,
    "dec": -42.203442,
    "pmra": 648.75,
    "pmdec": -137.23,
    "rv": 0,
    "Asset Name": "ASTRID 002675",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Little Crown",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00004129,
    "y_1": 0.00006163,
    "z_1": -0.00001151,
    "Distance in Parsecs_1": 23.3536,
    "HYG Star ID_1": 7999,
    "Hipparcos Catalogue_1": 8014,
    "Gliese Catalog_1": "NN 3110"
  },
  {
    "Astrid #": "ASTRID 002676",
    "HYG Star ID": 15634,
    "Hipparcos Catalogue": 15673,
    "Henry Draper Catalogue": 232781,
    "Gliese Catalog": "NN 3217",
    "Distance in Parsecs": 23.359,
    "mag": 9.05,
    "Absolute Visual Magnitude": 7.208,
    "Spectral Type": "K5",
    "Color Index": 0.99,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.083893,
    "y": 11.011085,
    "z": 18.490045,
    "vx": 0.00004241,
    "vy": -0.00000387,
    "vz": -0.00001853,
    "rarad": 0.881018294309865,
    "decrad": 0.91335723287607,
    "pmrarad": -0.00000150636458685416,
    "pmdecrad": -0.000001298185592,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 15634,
    "lum": 0.113972480401974,
    "ra": 3.365242,
    "dec": 52.331515,
    "pmra": -310.71,
    "pmdec": -267.77,
    "rv": 0,
    "Asset Name": "ASTRID 002676",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Happy Vamp",
    "Hair": "High Fade",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00004241,
    "y_1": -0.00000387,
    "z_1": -0.00001853,
    "Distance in Parsecs_1": 23.359,
    "HYG Star ID_1": 15634,
    "Hipparcos Catalogue_1": 15673,
    "Henry Draper Catalogue_1": 232781,
    "Gliese Catalog_1": "NN 3217"
  },
  {
    "Astrid #": "ASTRID 002677",
    "HYG Star ID": 45707,
    "Hipparcos Catalogue": 45839,
    "Henry Draper Catalogue": 80632,
    "Gliese Catalog": "Gl 340.3",
    "Distance in Parsecs": 23.3645,
    "mag": 9.1,
    "Absolute Visual Magnitude": 7.257,
    "Spectral Type": "K2",
    "Color Index": 1.163,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.856382,
    "y": 14.88548,
    "z": -2.342487,
    "vx": -0.00000106,
    "vy": 0.0000547,
    "vz": -0.00001673,
    "rarad": 2.44668390053571,
    "decrad": -0.100425727618743,
    "pmrarad": -0.00000176942448992361,
    "pmdecrad": -5.58699285e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 45707,
    "lum": 0.108943167965913,
    "ra": 9.345644,
    "dec": -5.75397,
    "pmra": -364.97,
    "pmdec": -115.24,
    "rv": 36.5,
    "Asset Name": "ASTRID 002677",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Braided Ponytail",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000106,
    "y_1": 0.0000547,
    "z_1": -0.00001673,
    "Distance in Parsecs_1": 23.3645,
    "HYG Star ID_1": 45707,
    "Hipparcos Catalogue_1": 45839,
    "Henry Draper Catalogue_1": 80632,
    "Gliese Catalog_1": "Gl 340.3"
  },
  {
    "Astrid #": "ASTRID 002678",
    "HYG Star ID": 119512,
    "Gliese Catalog": "NN 4284",
    "Distance in Parsecs": 23.3645,
    "mag": 14.83,
    "Absolute Visual Magnitude": 12.987,
    "Spectral Type": "dM4",
    "Color Index": 1.7,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 21.768867,
    "y": -8.474699,
    "z": -0.442525,
    "vx": 0.00004596,
    "vy": 0.0001166,
    "vz": 0.00002595,
    "rarad": 5.9119337640169,
    "decrad": -0.018941193715,
    "pmrarad": 0.00000536456033808333,
    "pmdecrad": 0.0000011109457,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119512,
    "lum": 0.000556160319597592,
    "ra": 22.581924,
    "dec": -1.08525,
    "pmra": 1106.52,
    "pmdec": 229.15,
    "rv": 0,
    "Asset Name": "ASTRID 002678",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Worried Vamp",
    "Hair": "Frizzy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004596,
    "y_1": 0.0001166,
    "z_1": 0.00002595,
    "Distance in Parsecs_1": 23.3645,
    "HYG Star ID_1": 119512,
    "Gliese Catalog_1": "NN 4284"
  },
  {
    "Astrid #": "ASTRID 002679",
    "HYG Star ID": 56766,
    "Hipparcos Catalogue": 56936,
    "Distance in Parsecs": 23.3699,
    "mag": 12.2,
    "Absolute Visual Magnitude": 10.357,
    "Spectral Type": "K4",
    "Color Index": 1.447,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.000789,
    "y": 0.774075,
    "z": 21.553163,
    "vx": -0.00033123,
    "vy": -0.00000137,
    "vz": -0.00013822,
    "rarad": 3.05578531765476,
    "decrad": 1.17389087737247,
    "pmrarad": 0.000001273217687875,
    "pmdecrad": -0.000015306586003,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 56766,
    "lum": 0.00626902497476165,
    "var_min": 12.319,
    "var_max": 11.989,
    "ra": 11.67224,
    "dec": 67.258993,
    "pmra": 262.62,
    "pmdec": -3157.21,
    "rv": 0,
    "Asset Name": "ASTRID 002679",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Side Part",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00033123,
    "y_1": -0.00000137,
    "z_1": -0.00013822,
    "Distance in Parsecs_1": 23.3699,
    "HYG Star ID_1": 56766,
    "Hipparcos Catalogue_1": 56936
  },
  {
    "Astrid #": "ASTRID 002680",
    "HYG Star ID": 5995,
    "Hipparcos Catalogue": 6008,
    "Distance in Parsecs": 23.3754,
    "mag": 10.8,
    "Absolute Visual Magnitude": 8.956,
    "Spectral Type": "M1",
    "Color Index": 1.447,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.471215,
    "y": 7.525644,
    "z": -5.362918,
    "vx": -0.00001321,
    "vy": 0.00003935,
    "vz": 0.00000233,
    "rarad": 0.33711908030869,
    "decrad": -0.231487613064321,
    "pmrarad": 0.00000177592099324305,
    "pmdecrad": 1.02392649e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 5995,
    "lum": 0.0227824276625008,
    "var_min": 10.86,
    "var_max": 10.74,
    "ra": 1.2877,
    "dec": -13.263263,
    "pmra": 366.31,
    "pmdec": 21.12,
    "rv": 0,
    "Asset Name": "ASTRID 002680",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Pompadour",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00001321,
    "y_1": 0.00003935,
    "z_1": 0.00000233,
    "Distance in Parsecs_1": 23.3754,
    "HYG Star ID_1": 5995,
    "Hipparcos Catalogue_1": 6008
  },
  {
    "Astrid #": "ASTRID 002681",
    "HYG Star ID": 61753,
    "Hipparcos Catalogue": 61946,
    "Henry Draper Catalogue": 110463,
    "Gliese Catalog": "NN 3743",
    "Distance in Parsecs": 23.3754,
    "mag": 8.27,
    "Absolute Visual Magnitude": 6.426,
    "Spectral Type": "K3V",
    "Color Index": 0.955,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.946552,
    "y": -2.38443,
    "z": 19.316002,
    "vx": 0.00000566,
    "vy": -0.00001296,
    "vz": -0.0000056,
    "rarad": 3.32372255944807,
    "decrad": 0.972579037043585,
    "pmrarad": 5.89194065979166e-7,
    "pmdecrad": -2.1137876e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 61753,
    "lum": 0.234207069479382,
    "var": "NP",
    "var_min": 8.305,
    "var_max": 8.245,
    "ra": 12.695685,
    "dec": 55.724674,
    "pmra": 121.53,
    "pmdec": -4.36,
    "rv": -6.3,
    "Asset Name": "ASTRID 002681",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Undercut",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000566,
    "y_1": -0.00001296,
    "z_1": -0.0000056,
    "Distance in Parsecs_1": 23.3754,
    "HYG Star ID_1": 61753,
    "Hipparcos Catalogue_1": 61946,
    "Henry Draper Catalogue_1": 110463,
    "Gliese Catalog_1": "NN 3743"
  },
  {
    "Astrid #": "ASTRID 002682",
    "HYG Star ID": 5786,
    "Hipparcos Catalogue": 5799,
    "Henry Draper Catalogue": 7439,
    "Harvard Revised Catalogue": 366,
    "Gliese Catalog": "Gl 54.2A",
    "Distance in Parsecs": 23.3863,
    "mag": 5.14,
    "Absolute Visual Magnitude": 3.295,
    "Spectral Type": "F5V",
    "Color Index": 0.448,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 21.953382,
    "y": 7.3882,
    "z": -3.223573,
    "vx": 0.00001999,
    "vy": 0.00002152,
    "vz": 0.00002818,
    "rarad": 0.324634083229439,
    "decrad": -0.138279394724127,
    "pmrarad": 5.99569078743055e-7,
    "pmdecrad": 0.000001345745814,
    "flam": 37,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 5786,
    "base": "Gl  54.2",
    "lum": 4.18793565117918,
    "bf": "37    Cet",
    "ra": 1.240011,
    "dec": -7.922826,
    "pmra": 123.67,
    "pmdec": 277.58,
    "rv": 21.2,
    "Asset Name": "ASTRID 002682",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Ponytail",
    "Outfit": "Toga",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00001999,
    "y_1": 0.00002152,
    "z_1": 0.00002818,
    "Distance in Parsecs_1": 23.3863,
    "HYG Star ID_1": 5786,
    "Hipparcos Catalogue_1": 5799,
    "Henry Draper Catalogue_1": 7439,
    "Harvard Revised Catalogue_1": 366,
    "Gliese Catalog_1": "Gl 54.2A"
  },
  {
    "Astrid #": "ASTRID 002683",
    "HYG Star ID": 69135,
    "Hipparcos Catalogue": 69357,
    "Henry Draper Catalogue": 124106,
    "Gliese Catalog": "NN 3827",
    "Distance in Parsecs": 23.3863,
    "mag": 7.93,
    "Absolute Visual Magnitude": 6.085,
    "Spectral Type": "K1V",
    "Color Index": 0.865,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.152715,
    "y": -12.410552,
    "z": -5.106265,
    "vx": -0.00001851,
    "vy": 0.00002279,
    "vz": -0.00002155,
    "rarad": 3.71654588996597,
    "decrad": -0.220116844180656,
    "pmrarad": -0.0000012483467460625,
    "pmdecrad": -8.70192075e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 69135,
    "lum": 0.320626932450546,
    "ra": 14.19616,
    "dec": -12.611766,
    "pmra": -257.49,
    "pmdec": -179.49,
    "rv": 7.6,
    "Asset Name": "ASTRID 002683",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "High Bun",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001851,
    "y_1": 0.00002279,
    "z_1": -0.00002155,
    "Distance in Parsecs_1": 23.3863,
    "HYG Star ID_1": 69135,
    "Hipparcos Catalogue_1": 69357,
    "Henry Draper Catalogue_1": 124106,
    "Gliese Catalog_1": "NN 3827"
  },
  {
    "Astrid #": "ASTRID 002684",
    "HYG Star ID": 72778,
    "Hipparcos Catalogue": 73005,
    "Henry Draper Catalogue": 132142,
    "Gliese Catalog": "Gl 569.1",
    "Distance in Parsecs": 23.3863,
    "mag": 7.77,
    "Absolute Visual Magnitude": 5.925,
    "Spectral Type": "K1V",
    "Color Index": 0.785,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.998046,
    "y": -9.586447,
    "z": 18.842826,
    "vx": -0.00003833,
    "vy": 0.00011576,
    "vz": 0.00002028,
    "rarad": 3.90600566456293,
    "decrad": 0.936898803844933,
    "pmrarad": -0.00000470724994998611,
    "pmdecrad": 0.000002321239421,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72778,
    "lum": 0.371535229097172,
    "ra": 14.919843,
    "dec": 53.680347,
    "pmra": -970.94,
    "pmdec": 478.79,
    "rv": -14.4,
    "Asset Name": "ASTRID 002684",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Manbun",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00003833,
    "y_1": 0.00011576,
    "z_1": 0.00002028,
    "Distance in Parsecs_1": 23.3863,
    "HYG Star ID_1": 72778,
    "Hipparcos Catalogue_1": 73005,
    "Henry Draper Catalogue_1": 132142,
    "Gliese Catalog_1": "Gl 569.1"
  },
  {
    "Astrid #": "ASTRID 002685",
    "HYG Star ID": 31051,
    "Hipparcos Catalogue": 31126,
    "Gliese Catalog": "Gl 237",
    "Distance in Parsecs": 23.4028,
    "mag": 10.58,
    "Absolute Visual Magnitude": 8.734,
    "Color Index": 1.432,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -2.350409,
    "y": 16.8028,
    "z": -16.119673,
    "vx": 0.00002842,
    "vy": 0.00002203,
    "vz": -0.00002127,
    "rarad": 1.70978095417081,
    "decrad": -0.759812320643951,
    "pmrarad": -0.00000133323762152777,
    "pmdecrad": -1.32645023e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 31051,
    "lum": 0.0279511705399184,
    "var_min": 10.623,
    "var_max": 10.513,
    "ra": 6.530882,
    "dec": -43.534039,
    "pmra": -275,
    "pmdec": -27.36,
    "rv": 27,
    "Asset Name": "ASTRID 002685",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Lion Mane",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002842,
    "y_1": 0.00002203,
    "z_1": -0.00002127,
    "Distance in Parsecs_1": 23.4028,
    "HYG Star ID_1": 31051,
    "Hipparcos Catalogue_1": 31126,
    "Gliese Catalog_1": "Gl 237"
  },
  {
    "Astrid #": "ASTRID 002686",
    "HYG Star ID": 59434,
    "Hipparcos Catalogue": 59616,
    "Distance in Parsecs": 23.4137,
    "mag": 11.46,
    "Absolute Visual Magnitude": 9.613,
    "Color Index": 1.491,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -21.020992,
    "y": -1.244143,
    "z": -10.235788,
    "vx": 0.00001888,
    "vy": -0.00002931,
    "vz": -0.00003522,
    "rarad": 3.20070820418496,
    "decrad": -0.452450655009826,
    "pmrarad": 0.00000129750685327083,
    "pmdecrad": -0.000001672801123,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 59434,
    "lum": 0.012439416235583,
    "var_min": 11.552,
    "var_max": 11.402,
    "ra": 12.225805,
    "dec": -25.923513,
    "pmra": 267.63,
    "pmdec": -345.04,
    "rv": 0,
    "Asset Name": "ASTRID 002686",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Anxious Laugh",
    "Hair": "Honest Lad",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001888,
    "y_1": -0.00002931,
    "z_1": -0.00003522,
    "Distance in Parsecs_1": 23.4137,
    "HYG Star ID_1": 59434,
    "Hipparcos Catalogue_1": 59616
  },
  {
    "Astrid #": "ASTRID 002687",
    "HYG Star ID": 119297,
    "Gliese Catalog": "GJ 1242",
    "Distance in Parsecs": 23.4192,
    "mag": 12.88,
    "Absolute Visual Magnitude": 11.032,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.057081,
    "y": -21.110557,
    "z": 1.287806,
    "vx": -0.00002551,
    "vy": -0.00001535,
    "vz": -0.00005229,
    "rarad": 5.15697950566282,
    "decrad": 0.0550170596334999,
    "pmrarad": -0.00000126526674351388,
    "pmdecrad": -0.00000223638733,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119297,
    "lum": 0.00336666574368622,
    "ra": 19.698211,
    "dec": 3.152245,
    "pmra": -260.98,
    "pmdec": -461.29,
    "rv": 0,
    "Asset Name": "ASTRID 002687",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Windy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002551,
    "y_1": -0.00001535,
    "z_1": -0.00005229,
    "Distance in Parsecs_1": 23.4192,
    "HYG Star ID_1": 119297,
    "Gliese Catalog_1": "GJ 1242"
  },
  {
    "Astrid #": "ASTRID 002688",
    "HYG Star ID": 98821,
    "Hipparcos Catalogue": 99137,
    "Henry Draper Catalogue": 190422,
    "Harvard Revised Catalogue": 7674,
    "Distance in Parsecs": 23.4302,
    "mag": 6.26,
    "Absolute Visual Magnitude": 4.411,
    "Spectral Type": "F8V",
    "Color Index": 0.53,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.098135,
    "y": -11.405312,
    "z": -19.196747,
    "vx": 0.00000766,
    "vy": -0.00000818,
    "vz": -0.00000853,
    "rarad": 5.26908232679013,
    "decrad": -0.960210553504243,
    "pmrarad": 9.28903011944444e-8,
    "pmdecrad": 1.75599515e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 98821,
    "lum": 1.49830421062265,
    "ra": 20.126412,
    "dec": -55.016012,
    "pmra": 19.16,
    "pmdec": 36.22,
    "rv": 13,
    "Asset Name": "ASTRID 002688",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Awkward",
    "Hair": "Side Part",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00000766,
    "y_1": -0.00000818,
    "z_1": -0.00000853,
    "Distance in Parsecs_1": 23.4302,
    "HYG Star ID_1": 98821,
    "Hipparcos Catalogue_1": 99137,
    "Henry Draper Catalogue_1": 190422,
    "Harvard Revised Catalogue_1": 7674
  },
  {
    "Astrid #": "ASTRID 002689",
    "HYG Star ID": 736,
    "Hipparcos Catalogue": 738,
    "Gliese Catalog": "Gl 7",
    "Distance in Parsecs": 23.4467,
    "mag": 11.73,
    "Absolute Visual Magnitude": 9.88,
    "Color Index": 1.493,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 20.852074,
    "y": 0.825903,
    "z": -10.689089,
    "vx": 0.00000142,
    "vy": 0.00007924,
    "vz": 0.00000889,
    "rarad": 0.0395833819710535,
    "decrad": -0.473370620025207,
    "pmrarad": 0.00000337473954897916,
    "pmdecrad": 4.26054262e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 736,
    "lum": 0.00972747223776964,
    "var_min": 11.807,
    "var_max": 11.657,
    "ra": 0.151197,
    "dec": -27.122139,
    "pmra": 696.09,
    "pmdec": 87.88,
    "rv": 0,
    "Asset Name": "ASTRID 002689",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Innocent",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00000142,
    "y_1": 0.00007924,
    "z_1": 0.00000889,
    "Distance in Parsecs_1": 23.4467,
    "HYG Star ID_1": 736,
    "Hipparcos Catalogue_1": 738,
    "Gliese Catalog_1": "Gl 7"
  },
  {
    "Astrid #": "ASTRID 002690",
    "HYG Star ID": 51321,
    "Hipparcos Catalogue": 51468,
    "Henry Draper Catalogue": 90875,
    "Gliese Catalog": "NN 3606",
    "Distance in Parsecs": 23.4467,
    "mag": 8.74,
    "Absolute Visual Magnitude": 6.89,
    "Spectral Type": "K5",
    "Color Index": 1.15,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.927739,
    "y": 4.482729,
    "z": 20.254318,
    "vx": -0.0000072,
    "vy": 0.00005681,
    "vz": -0.00001645,
    "rarad": 2.75232917681985,
    "decrad": 1.04285339926902,
    "pmrarad": -0.00000212527773145138,
    "pmdecrad": -0.000001393112111,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 51321,
    "lum": 0.152756605823807,
    "ra": 10.513123,
    "dec": 59.751098,
    "pmra": -438.37,
    "pmdec": -287.35,
    "rv": 0,
    "Asset Name": "ASTRID 002690",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Shy Smile",
    "Hair": "High Fade",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.0000072,
    "y_1": 0.00005681,
    "z_1": -0.00001645,
    "Distance in Parsecs_1": 23.4467,
    "HYG Star ID_1": 51321,
    "Hipparcos Catalogue_1": 51468,
    "Henry Draper Catalogue_1": 90875,
    "Gliese Catalog_1": "NN 3606"
  },
  {
    "Astrid #": "ASTRID 002691",
    "HYG Star ID": 3802,
    "Hipparcos Catalogue": 3810,
    "Henry Draper Catalogue": 4676,
    "Harvard Revised Catalogue": 225,
    "Gliese Catalog": "Gl 34.1",
    "Distance in Parsecs": 23.4522,
    "mag": 5.07,
    "Absolute Visual Magnitude": 3.219,
    "Spectral Type": "F8V...",
    "Color Index": 0.502,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 21.92421,
    "y": 4.758056,
    "z": 6.833532,
    "vx": 0.00001021,
    "vy": 0.00000195,
    "vz": -0.00002079,
    "rarad": 0.21370890015861,
    "decrad": 0.295670010159318,
    "pmrarad": -1.12961587569444e-8,
    "pmdecrad": -9.77238935e-7,
    "flam": 64,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 3802,
    "lum": 4.49158890105931,
    "bf": "64    Psc",
    "ra": 0.816308,
    "dec": 16.940644,
    "pmra": -2.33,
    "pmdec": -201.57,
    "rv": 3.8,
    "Asset Name": "ASTRID 002691",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Fedora",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00001021,
    "y_1": 0.00000195,
    "z_1": -0.00002079,
    "Distance in Parsecs_1": 23.4522,
    "HYG Star ID_1": 3802,
    "Hipparcos Catalogue_1": 3810,
    "Henry Draper Catalogue_1": 4676,
    "Harvard Revised Catalogue_1": 225,
    "Gliese Catalog_1": "Gl 34.1"
  },
  {
    "Astrid #": "ASTRID 002692",
    "HYG Star ID": 108222,
    "Hipparcos Catalogue": 108567,
    "Gliese Catalog": "NN 4244",
    "Distance in Parsecs": 23.4522,
    "mag": 9.53,
    "Absolute Visual Magnitude": 7.679,
    "Spectral Type": "K7",
    "Color Index": 1.202,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.855773,
    "y": -5.716933,
    "z": -20.498442,
    "vx": -0.0000087,
    "vy": 0.00000375,
    "vz": -0.00000523,
    "rarad": 5.75755755787857,
    "decrad": -1.06348101389366,
    "pmrarad": -4.78995916388888e-8,
    "pmdecrad": -4.58730704e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 108222,
    "lum": 0.0738584178120796,
    "var_min": 9.59,
    "var_max": 9.48,
    "ra": 21.99225,
    "dec": -60.932974,
    "pmra": -9.88,
    "pmdec": -94.62,
    "rv": 0,
    "Asset Name": "ASTRID 002692",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "High Bun",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.0000087,
    "y_1": 0.00000375,
    "z_1": -0.00000523,
    "Distance in Parsecs_1": 23.4522,
    "HYG Star ID_1": 108222,
    "Hipparcos Catalogue_1": 108567,
    "Gliese Catalog_1": "NN 4244"
  },
  {
    "Astrid #": "ASTRID 002693",
    "HYG Star ID": 70085,
    "Hipparcos Catalogue": 70308,
    "Distance in Parsecs": 23.4687,
    "mag": 10.25,
    "Absolute Visual Magnitude": 8.398,
    "Spectral Type": "M1V:",
    "Color Index": 1.429,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.014527,
    "y": -5.054691,
    "z": -21.818029,
    "vx": 0.00002439,
    "vy": 0.00000932,
    "vz": -0.00001,
    "rarad": 3.76600870718429,
    "decrad": -1.19350249912182,
    "pmrarad": 2.85506776479166e-7,
    "pmdecrad": -0.000001156813923,
    "con": "Cir",
    "Full Constellation Name Formula 1": "Circinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Circinus",
    "comp": 1,
    "comp_primary": 70085,
    "lum": 0.0380890376498135,
    "var_min": 10.306,
    "var_max": 10.186,
    "ra": 14.385094,
    "dec": -68.382656,
    "pmra": 58.89,
    "pmdec": -238.61,
    "rv": 0,
    "Asset Name": "ASTRID 002693",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Oh No",
    "Hair": "Side Part",
    "Outfit": "Sharky",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Circinus",
    "x_1": 0.00002439,
    "y_1": 0.00000932,
    "z_1": -0.00001,
    "Distance in Parsecs_1": 23.4687,
    "HYG Star ID_1": 70085,
    "Hipparcos Catalogue_1": 70308
  },
  {
    "Astrid #": "ASTRID 002694",
    "HYG Star ID": 13608,
    "Hipparcos Catalogue": 13642,
    "Henry Draper Catalogue": 18143,
    "Gliese Catalog": "Gl 118.2A",
    "Distance in Parsecs": 23.4907,
    "mag": 7.52,
    "Absolute Visual Magnitude": 5.666,
    "Spectral Type": "K2",
    "Color Index": 0.953,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.095318,
    "y": 14.533015,
    "z": 10.618342,
    "vx": 0.00000725,
    "vy": 0.00004851,
    "vz": -0.00000475,
    "rarad": 0.766420536896888,
    "decrad": 0.469026111886037,
    "pmrarad": 0.00000127370250155555,
    "pmdecrad": -9.2812731e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13608,
    "base": "Gl 118.2",
    "lum": 0.471628455299837,
    "ra": 2.927511,
    "dec": 26.873217,
    "pmra": 262.72,
    "pmdec": -191.44,
    "rv": 31.8,
    "Asset Name": "ASTRID 002694",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Straw Hat",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00000725,
    "y_1": 0.00004851,
    "z_1": -0.00000475,
    "Distance in Parsecs_1": 23.4907,
    "HYG Star ID_1": 13608,
    "Hipparcos Catalogue_1": 13642,
    "Henry Draper Catalogue_1": 18143,
    "Gliese Catalog_1": "Gl 118.2A"
  },
  {
    "Astrid #": "ASTRID 002695",
    "HYG Star ID": 29359,
    "Hipparcos Catalogue": 29432,
    "Henry Draper Catalogue": 42618,
    "Gliese Catalog": "NN 3387",
    "Distance in Parsecs": 23.5018,
    "mag": 6.85,
    "Absolute Visual Magnitude": 4.994,
    "Spectral Type": "G4V",
    "Color Index": 0.642,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.222316,
    "y": 23.3048,
    "z": 2.775757,
    "vx": -0.00001974,
    "vy": -0.00005091,
    "vz": -0.000035,
    "rarad": 1.62319733737152,
    "decrad": 0.118386983892881,
    "pmrarad": 9.52271031347222e-7,
    "pmdecrad": -0.000001228469385,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29359,
    "lum": 0.875790036306368,
    "ra": 6.200157,
    "dec": 6.783075,
    "pmra": 196.42,
    "pmdec": -253.39,
    "rv": -52.4,
    "Asset Name": "ASTRID 002695",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Undercut",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00001974,
    "y_1": -0.00005091,
    "z_1": -0.000035,
    "Distance in Parsecs_1": 23.5018,
    "HYG Star ID_1": 29359,
    "Hipparcos Catalogue_1": 29432,
    "Henry Draper Catalogue_1": 42618,
    "Gliese Catalog_1": "NN 3387"
  },
  {
    "Astrid #": "ASTRID 002696",
    "HYG Star ID": 78674,
    "Hipparcos Catalogue": 78913,
    "Henry Draper Catalogue": 144872,
    "Gliese Catalog": "Gl 612",
    "Distance in Parsecs": 23.5018,
    "mag": 8.58,
    "Absolute Visual Magnitude": 6.724,
    "Spectral Type": "K3V",
    "Color Index": 0.963,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.725434,
    "y": -16.153069,
    "z": 14.672761,
    "vx": -0.00000397,
    "vy": -0.00006307,
    "vz": -0.00003232,
    "rarad": 4.21711987795882,
    "decrad": 0.674259781693118,
    "pmrarad": 0.00000112685243771527,
    "pmdecrad": -0.000002598892215,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 78674,
    "lum": 0.177991802039533,
    "var": "AT",
    "var_min": 8.614,
    "var_max": 8.544,
    "ra": 16.108211,
    "dec": 38.63224,
    "pmra": 232.43,
    "pmdec": -536.06,
    "rv": 24.1,
    "Asset Name": "ASTRID 002696",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Greasy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": -0.00000397,
    "y_1": -0.00006307,
    "z_1": -0.00003232,
    "Distance in Parsecs_1": 23.5018,
    "HYG Star ID_1": 78674,
    "Hipparcos Catalogue_1": 78913,
    "Henry Draper Catalogue_1": 144872,
    "Gliese Catalog_1": "Gl 612"
  },
  {
    "Astrid #": "ASTRID 002697",
    "HYG Star ID": 105571,
    "Hipparcos Catalogue": 105911,
    "Henry Draper Catalogue": 203985,
    "Gliese Catalog": "Gl 828A",
    "Distance in Parsecs": 23.5073,
    "mag": 7.49,
    "Absolute Visual Magnitude": 5.634,
    "Spectral Type": "K0V",
    "Color Index": 0.876,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.098251,
    "y": -10.323778,
    "z": -16.566594,
    "vx": 0.00003495,
    "vy": 0.00001077,
    "vz": 0.00000902,
    "rarad": 5.6156888281655,
    "decrad": -0.782057055380696,
    "pmrarad": 0.00000128024748624305,
    "pmdecrad": 8.95499349e-7,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 105571,
    "base": "Gl 828",
    "lum": 0.485735673287853,
    "ra": 21.450351,
    "dec": -44.808569,
    "pmra": 264.07,
    "pmdec": 184.71,
    "rv": 8.2,
    "Asset Name": "ASTRID 002697",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Audiohead",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": 0.00003495,
    "y_1": 0.00001077,
    "z_1": 0.00000902,
    "Distance in Parsecs_1": 23.5073,
    "HYG Star ID_1": 105571,
    "Hipparcos Catalogue_1": 105911,
    "Henry Draper Catalogue_1": 203985,
    "Gliese Catalog_1": "Gl 828A"
  },
  {
    "Astrid #": "ASTRID 002698",
    "HYG Star ID": 83199,
    "Hipparcos Catalogue": 83454,
    "Gliese Catalog": "Wo 9582B",
    "Distance in Parsecs": 23.5183,
    "mag": 10.34,
    "Absolute Visual Magnitude": 8.483,
    "Spectral Type": "K0",
    "Color Index": 1.369,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.913872,
    "y": -11.542938,
    "z": 20.28176,
    "vx": -0.00002508,
    "vy": 0.00007081,
    "vz": -0.00004952,
    "rarad": 4.46513245053671,
    "decrad": 1.04000328598706,
    "pmrarad": -0.00000177102437506944,
    "pmdecrad": 0.000001226627093,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 83195,
    "base": "Wo 9582",
    "lum": 0.0352208635618467,
    "ra": 17.05555,
    "dec": 59.587799,
    "pmra": -365.3,
    "pmdec": 253.01,
    "rv": -72.7,
    "Asset Name": "ASTRID 002698",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Bandana",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00002508,
    "y_1": 0.00007081,
    "z_1": -0.00004952,
    "Distance in Parsecs_1": 23.5183,
    "HYG Star ID_1": 83199,
    "Hipparcos Catalogue_1": 83454,
    "Gliese Catalog_1": "Wo 9582B"
  },
  {
    "Astrid #": "ASTRID 002699",
    "HYG Star ID": 119413,
    "Gliese Catalog": "Gl 828B",
    "Distance in Parsecs": 23.5183,
    "mag": 14.1,
    "Absolute Visual Magnitude": 12.243,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.100449,
    "y": -10.338398,
    "z": -16.571247,
    "vx": 0.0000292,
    "vy": 0.00001337,
    "vz": 0.00001474,
    "rarad": 5.61506205821147,
    "decrad": -0.781874412322449,
    "pmrarad": 0.00000121586422946527,
    "pmdecrad": 8.83393551e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 105571,
    "base": "Gl 828",
    "lum": 0.00110357028996401,
    "ra": 21.447957,
    "dec": -44.798104,
    "pmra": 250.79,
    "pmdec": 182.21,
    "rv": 0,
    "Asset Name": "ASTRID 002699",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000292,
    "y_1": 0.00001337,
    "z_1": 0.00001474,
    "Distance in Parsecs_1": 23.5183,
    "HYG Star ID_1": 119413,
    "Gliese Catalog_1": "Gl 828B"
  },
  {
    "Astrid #": "ASTRID 002700",
    "HYG Star ID": 91322,
    "Hipparcos Catalogue": 91605,
    "Distance in Parsecs": 23.5405,
    "mag": 8.54,
    "Absolute Visual Magnitude": 6.681,
    "Spectral Type": "K3V",
    "Color Index": 0.865,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.562997,
    "y": -19.745584,
    "z": 12.311463,
    "vx": 0.00001861,
    "vy": -0.00004743,
    "vz": -0.00008145,
    "rarad": 4.89091252806936,
    "decrad": 0.550356017368538,
    "pmrarad": 4.20236498305555e-7,
    "pmdecrad": -0.000004060072167,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 91322,
    "lum": 0.185182524363817,
    "ra": 18.68191,
    "dec": 31.533077,
    "pmra": 86.68,
    "pmdec": -837.45,
    "rv": 0,
    "Asset Name": "ASTRID 002700",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Blep",
    "Hair": "Ninja",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00001861,
    "y_1": -0.00004743,
    "z_1": -0.00008145,
    "Distance in Parsecs_1": 23.5405,
    "HYG Star ID_1": 91322,
    "Hipparcos Catalogue_1": 91605
  },
  {
    "Astrid #": "ASTRID 002701",
    "HYG Star ID": 72648,
    "Hipparcos Catalogue": 72875,
    "Henry Draper Catalogue": 131582,
    "Distance in Parsecs": 23.546,
    "mag": 8.65,
    "Absolute Visual Magnitude": 6.79,
    "Spectral Type": "K3V",
    "Color Index": 0.934,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.701174,
    "y": -14.859916,
    "z": 9.330455,
    "vx": -0.00004393,
    "vy": 0.00008795,
    "vz": -0.00001204,
    "rarad": 3.89947378957045,
    "decrad": 0.407450141144538,
    "pmrarad": -0.00000399559194829861,
    "pmdecrad": 1.1102233e-8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72648,
    "lum": 0.167494287602643,
    "ra": 14.894893,
    "dec": 23.345173,
    "pmra": -824.15,
    "pmdec": 2.29,
    "rv": -30.3,
    "Asset Name": "ASTRID 002701",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Honest Lad",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00004393,
    "y_1": 0.00008795,
    "z_1": -0.00001204,
    "Distance in Parsecs_1": 23.546,
    "HYG Star ID_1": 72648,
    "Hipparcos Catalogue_1": 72875,
    "Henry Draper Catalogue_1": 131582
  },
  {
    "Astrid #": "ASTRID 002702",
    "HYG Star ID": 119523,
    "Gliese Catalog": "Gl 871B",
    "Distance in Parsecs": 23.546,
    "mag": 11.1,
    "Absolute Visual Magnitude": 9.24,
    "Spectral Type": "M1",
    "Color Index": 1.41,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.09186,
    "y": -5.297662,
    "z": -17.279603,
    "vx": -0.00002492,
    "vy": 0.00001216,
    "vz": -0.0000255,
    "rarad": 5.94559190444882,
    "decrad": -0.823995396672199,
    "pmrarad": 1.36668976548611e-7,
    "pmdecrad": -0.000001594033444,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 2,
    "comp_primary": 111763,
    "base": "Gl 871",
    "lum": 0.0175388050184176,
    "ra": 22.710488,
    "dec": -47.211459,
    "pmra": 28.19,
    "pmdec": -328.79,
    "rv": 0,
    "Asset Name": "ASTRID 002702",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Undercut",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00002492,
    "y_1": 0.00001216,
    "z_1": -0.0000255,
    "Distance in Parsecs_1": 23.546,
    "HYG Star ID_1": 119523,
    "Gliese Catalog_1": "Gl 871B"
  },
  {
    "Astrid #": "ASTRID 002703",
    "HYG Star ID": 28195,
    "Hipparcos Catalogue": 28267,
    "Henry Draper Catalogue": 40397,
    "Gliese Catalog": "NN 3376A",
    "Distance in Parsecs": 23.5516,
    "mag": 6.99,
    "Absolute Visual Magnitude": 5.13,
    "Spectral Type": "G0",
    "Color Index": 0.72,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.168086,
    "y": 23.473456,
    "z": -1.909572,
    "vx": -0.00000815,
    "vy": -0.00000182,
    "vz": -0.00002314,
    "rarad": 1.56363578466186,
    "decrad": -0.0811694629262075,
    "pmrarad": 3.45332784659722e-7,
    "pmdecrad": -9.85820138e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 28195,
    "base": "NN 3376",
    "lum": 0.772680585095702,
    "ra": 5.972649,
    "dec": -4.650668,
    "pmra": 71.23,
    "pmdec": -203.34,
    "rv": 0,
    "Asset Name": "ASTRID 002703",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Fedora",
    "Outfit": "Tats",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00000815,
    "y_1": -0.00000182,
    "z_1": -0.00002314,
    "Distance in Parsecs_1": 23.5516,
    "HYG Star ID_1": 28195,
    "Hipparcos Catalogue_1": 28267,
    "Henry Draper Catalogue_1": 40397,
    "Gliese Catalog_1": "NN 3376A"
  },
  {
    "Astrid #": "ASTRID 002704",
    "HYG Star ID": 87902,
    "Hipparcos Catalogue": 88175,
    "Henry Draper Catalogue": 164259,
    "Harvard Revised Catalogue": 6710,
    "Gliese Catalog": "Gl 699.2",
    "Distance in Parsecs": 23.5516,
    "mag": 4.62,
    "Absolute Visual Magnitude": 2.76,
    "Spectral Type": "F3V",
    "Color Index": 0.39,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.049583,
    "y": -23.502332,
    "z": -1.515823,
    "vx": 0.00001751,
    "vy": 0.00004415,
    "vz": -0.00000224,
    "rarad": 4.71449866707594,
    "decrad": -0.0644073457737825,
    "pmrarad": 7.47437251312499e-7,
    "pmdecrad": -2.15402718e-7,
    "bayer": "Zet",
    "flam": 57,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 87902,
    "lum": 6.85488226452661,
    "bf": "57Zet Ser",
    "ra": 18.008058,
    "dec": -3.690269,
    "pmra": 154.17,
    "pmdec": -44.43,
    "rv": -42.9,
    "Asset Name": "ASTRID 002704",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Fired Up",
    "Hair": "Braided Bun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00001751,
    "y_1": 0.00004415,
    "z_1": -0.00000224,
    "Distance in Parsecs_1": 23.5516,
    "HYG Star ID_1": 87902,
    "Hipparcos Catalogue_1": 88175,
    "Henry Draper Catalogue_1": 164259,
    "Harvard Revised Catalogue_1": 6710,
    "Gliese Catalog_1": "Gl 699.2"
  },
  {
    "Astrid #": "ASTRID 002705",
    "HYG Star ID": 17708,
    "Hipparcos Catalogue": 17750,
    "Distance in Parsecs": 23.5571,
    "mag": 9.41,
    "Absolute Visual Magnitude": 7.549,
    "Spectral Type": "M2...",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.664389,
    "y": 7.184175,
    "z": 21.944659,
    "vx": -0.0000328,
    "vy": -0.00003767,
    "vz": 0.0000193,
    "rarad": 0.994937811419379,
    "decrad": 1.19865755476608,
    "pmrarad": 2.97190786180555e-7,
    "pmdecrad": 0.000002253801838,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 17708,
    "lum": 0.0832530206749241,
    "var_min": 9.989,
    "var_max": 9.129,
    "ra": 3.800382,
    "dec": 68.678019,
    "pmra": 61.3,
    "pmdec": 464.88,
    "rv": 0,
    "Asset Name": "ASTRID 002705",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Fire",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.0000328,
    "y_1": -0.00003767,
    "z_1": 0.0000193,
    "Distance in Parsecs_1": 23.5571,
    "HYG Star ID_1": 17708,
    "Hipparcos Catalogue_1": 17750
  },
  {
    "Astrid #": "ASTRID 002706",
    "HYG Star ID": 97795,
    "Hipparcos Catalogue": 98106,
    "Henry Draper Catalogue": 187456,
    "Gliese Catalog": "GJ 1244",
    "Distance in Parsecs": 23.5571,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.539,
    "Spectral Type": "K5V",
    "Color Index": 1.058,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.46739,
    "y": -6.238047,
    "z": -22.449965,
    "vx": -0.00001712,
    "vy": 0.0000279,
    "vz": -0.0000104,
    "rarad": 5.21971015552242,
    "decrad": -1.26299526172128,
    "pmrarad": -5.98260081805555e-8,
    "pmdecrad": -0.000001456768147,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 97795,
    "lum": 0.211057116288434,
    "ra": 19.937824,
    "dec": -72.364298,
    "pmra": -12.34,
    "pmdec": -300.48,
    "rv": 0,
    "Asset Name": "ASTRID 002706",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Angry",
    "Hair": "Halo Boy",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00001712,
    "y_1": 0.0000279,
    "z_1": -0.0000104,
    "Distance in Parsecs_1": 23.5571,
    "HYG Star ID_1": 97795,
    "Hipparcos Catalogue_1": 98106,
    "Henry Draper Catalogue_1": 187456,
    "Gliese Catalog_1": "GJ 1244"
  },
  {
    "Astrid #": "ASTRID 002707",
    "HYG Star ID": 118684,
    "Gliese Catalog": "Gl 392B",
    "Distance in Parsecs": 23.5571,
    "mag": 12.5,
    "Absolute Visual Magnitude": 10.639,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.288972,
    "y": 6.060337,
    "z": 17.721021,
    "vx": -0.00007276,
    "vy": 0.00002135,
    "vz": -0.00006594,
    "rarad": 2.74045217860464,
    "decrad": 0.851482714801099,
    "pmrarad": 3.71852092986111e-7,
    "pmdecrad": -0.000004250123978,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 51103,
    "base": "Gl 392",
    "lum": 0.00483503920591621,
    "ra": 10.467756,
    "dec": 48.786366,
    "pmra": 76.7,
    "pmdec": -876.65,
    "rv": 0,
    "Asset Name": "ASTRID 002707",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Skater Helmet",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00007276,
    "y_1": 0.00002135,
    "z_1": -0.00006594,
    "Distance in Parsecs_1": 23.5571,
    "HYG Star ID_1": 118684,
    "Gliese Catalog_1": "Gl 392B"
  },
  {
    "Astrid #": "ASTRID 002708",
    "HYG Star ID": 27853,
    "Hipparcos Catalogue": 27922,
    "Henry Draper Catalogue": 39855,
    "Distance in Parsecs": 23.5682,
    "mag": 7.51,
    "Absolute Visual Magnitude": 5.648,
    "Spectral Type": "G6V",
    "Color Index": 0.7,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.532175,
    "y": 22.18178,
    "z": -7.946412,
    "vx": -0.0000106,
    "vy": -7.6e-7,
    "vz": -0.00000282,
    "rarad": 1.54680911450599,
    "decrad": -0.343905710283786,
    "pmrarad": 4.48743542722222e-7,
    "pmdecrad": -1.27118147e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 27853,
    "lum": 0.47951257408135,
    "ra": 5.908376,
    "dec": -19.704346,
    "pmra": 92.56,
    "pmdec": -26.22,
    "rv": 0,
    "Asset Name": "ASTRID 002708",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "High Bun",
    "Outfit": "Emperor",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.0000106,
    "y_1": -7.6e-7,
    "z_1": -0.00000282,
    "Distance in Parsecs_1": 23.5682,
    "HYG Star ID_1": 27853,
    "Hipparcos Catalogue_1": 27922,
    "Henry Draper Catalogue_1": 39855
  },
  {
    "Astrid #": "ASTRID 002709",
    "HYG Star ID": 28081,
    "Hipparcos Catalogue": 28153,
    "Distance in Parsecs": 23.5682,
    "mag": 10.59,
    "Absolute Visual Magnitude": 8.728,
    "Spectral Type": "M:",
    "Color Index": 1.404,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.215169,
    "y": 16.092602,
    "z": -17.217489,
    "vx": -0.00000709,
    "vy": 0.00004091,
    "vz": 0.00003814,
    "rarad": 1.55742513428123,
    "decrad": -0.819110921276765,
    "pmrarad": 3.24146426819444e-7,
    "pmdecrad": 0.000002370254084,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 28081,
    "lum": 0.0281060620033439,
    "var_min": 10.651,
    "var_max": 10.521,
    "ra": 5.948926,
    "dec": -46.931599,
    "pmra": 66.86,
    "pmdec": 488.9,
    "rv": 0,
    "Asset Name": "ASTRID 002709",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Ninja",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": -0.00000709,
    "y_1": 0.00004091,
    "z_1": 0.00003814,
    "Distance in Parsecs_1": 23.5682,
    "HYG Star ID_1": 28081,
    "Hipparcos Catalogue_1": 28153
  },
  {
    "Astrid #": "ASTRID 002710",
    "HYG Star ID": 66377,
    "Hipparcos Catalogue": 66587,
    "Distance in Parsecs": 23.5682,
    "mag": 10.73,
    "Absolute Visual Magnitude": 8.868,
    "Spectral Type": "K5",
    "Color Index": 1.419,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.277556,
    "y": -9.80663,
    "z": -2.5604,
    "vx": 0.00000673,
    "vy": -0.00000915,
    "vz": -0.00002086,
    "rarad": 3.57346637040038,
    "decrad": -0.108852707501261,
    "pmrarad": 4.72160043493055e-7,
    "pmdecrad": -8.90457287e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66377,
    "lum": 0.0247058613603842,
    "var_min": 10.777,
    "var_max": 10.667,
    "ra": 13.649636,
    "dec": -6.236801,
    "pmra": 97.39,
    "pmdec": -183.67,
    "rv": 0,
    "Asset Name": "ASTRID 002710",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Round Brush",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000673,
    "y_1": -0.00000915,
    "z_1": -0.00002086,
    "Distance in Parsecs_1": 23.5682,
    "HYG Star ID_1": 66377,
    "Hipparcos Catalogue_1": 66587
  },
  {
    "Astrid #": "ASTRID 002711",
    "HYG Star ID": 70625,
    "Hipparcos Catalogue": 70849,
    "Gliese Catalog": "Gl 550.3",
    "Distance in Parsecs": 23.5738,
    "mag": 10.36,
    "Absolute Visual Magnitude": 8.498,
    "Spectral Type": "K5",
    "Color Index": 1.427,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.912144,
    "y": -9.846161,
    "z": -17.089579,
    "vx": 0.00001013,
    "vy": 0.00001444,
    "vz": -0.00001597,
    "rarad": 3.79307863077852,
    "decrad": -0.8109465721093,
    "pmrarad": -2.26311026083333e-7,
    "pmdecrad": -9.8378392e-7,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 70625,
    "lum": 0.0347376151972652,
    "ra": 14.488493,
    "dec": -46.463816,
    "pmra": -46.68,
    "pmdec": -202.92,
    "rv": 0,
    "Asset Name": "ASTRID 002711",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Fire",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": 0.00001013,
    "y_1": 0.00001444,
    "z_1": -0.00001597,
    "Distance in Parsecs_1": 23.5738,
    "HYG Star ID_1": 70625,
    "Hipparcos Catalogue_1": 70849,
    "Gliese Catalog_1": "Gl 550.3"
  },
  {
    "Astrid #": "ASTRID 002712",
    "HYG Star ID": 79252,
    "Hipparcos Catalogue": 79492,
    "Henry Draper Catalogue": 145958,
    "Gliese Catalog": "Gl 615.1A",
    "Distance in Parsecs": 23.5849,
    "mag": 6.68,
    "Absolute Visual Magnitude": 4.817,
    "Spectral Type": "G8V",
    "Color Index": 0.764,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.293638,
    "y": -20.490568,
    "z": 5.516595,
    "vx": 0.00000563,
    "vy": -0.0000355,
    "vz": -0.00004221,
    "rarad": 4.24685507847175,
    "decrad": 0.2360891332448,
    "pmrarad": 8.89196771506944e-7,
    "pmdecrad": -0.00000202991488,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 79252,
    "base": "Gl 615.1",
    "lum": 1.03086074006262,
    "ra": 16.221791,
    "dec": 13.526911,
    "pmra": 183.41,
    "pmdec": -418.7,
    "rv": 18.1,
    "Asset Name": "ASTRID 002712",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Sweet Nap",
    "Hair": "Side Part",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000563,
    "y_1": -0.0000355,
    "z_1": -0.00004221,
    "Distance in Parsecs_1": 23.5849,
    "HYG Star ID_1": 79252,
    "Hipparcos Catalogue_1": 79492,
    "Henry Draper Catalogue_1": 145958,
    "Gliese Catalog_1": "Gl 615.1A"
  },
  {
    "Astrid #": "ASTRID 002713",
    "HYG Star ID": 118391,
    "Gliese Catalog": "NN 3364",
    "Distance in Parsecs": 23.5849,
    "mag": 16.47,
    "Absolute Visual Magnitude": 14.607,
    "Spectral Type": "m",
    "Color Index": 1.69,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 0.438461,
    "y": 8.152218,
    "z": 22.12683,
    "vx": -0.00008105,
    "vy": 0.00006172,
    "vz": -0.00002111,
    "rarad": 1.51672629377824,
    "decrad": 1.21732811319485,
    "pmrarad": 0.00000357375556484722,
    "pmdecrad": -0.000002586956103,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118391,
    "lum": 0.000125083492837406,
    "ra": 5.793468,
    "dec": 69.747763,
    "pmra": 737.14,
    "pmdec": -533.6,
    "rv": 0,
    "Asset Name": "ASTRID 002713",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Charmer",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00008105,
    "y_1": 0.00006172,
    "z_1": -0.00002111,
    "Distance in Parsecs_1": 23.5849,
    "HYG Star ID_1": 118391,
    "Gliese Catalog_1": "NN 3364"
  },
  {
    "Astrid #": "ASTRID 002714",
    "HYG Star ID": 119158,
    "Gliese Catalog": "Gl 677B",
    "Distance in Parsecs": 23.5849,
    "mag": 9.93,
    "Absolute Visual Magnitude": 8.067,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -2.7421,
    "y": -20.365742,
    "z": 11.574321,
    "vx": -0.00002392,
    "vy": -0.00001265,
    "vz": -0.00002791,
    "rarad": 4.57855800329069,
    "decrad": 0.51295187811855,
    "pmrarad": -9.33654186013888e-7,
    "pmdecrad": -0.000001358457629,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 85322,
    "base": "Gl 677",
    "lum": 0.0516654242566949,
    "ra": 17.488803,
    "dec": 29.389978,
    "pmra": -192.58,
    "pmdec": -280.2,
    "rv": 0,
    "Asset Name": "ASTRID 002714",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Owie",
    "Hair": "Ram Horns",
    "Outfit": "Viking",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00002392,
    "y_1": -0.00001265,
    "z_1": -0.00002791,
    "Distance in Parsecs_1": 23.5849,
    "HYG Star ID_1": 119158,
    "Gliese Catalog_1": "Gl 677B"
  },
  {
    "Astrid #": "ASTRID 002715",
    "HYG Star ID": 119381,
    "Gliese Catalog": "NN 4171",
    "Distance in Parsecs": 23.5849,
    "mag": 9,
    "Absolute Visual Magnitude": 7.137,
    "Spectral Type": "A2",
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 12.042845,
    "y": -12.376642,
    "z": 16.063503,
    "vx": 8e-8,
    "vy": -8.7e-7,
    "vz": -7.3e-7,
    "rarad": 5.48411916759903,
    "decrad": 0.7492539427889,
    "pmrarad": -2.33680194027777e-8,
    "pmdecrad": -4.2484222e-8,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 119381,
    "lum": 0.121674620397913,
    "ra": 20.947792,
    "dec": 42.929089,
    "pmra": -4.82,
    "pmdec": -8.76,
    "rv": 0,
    "Asset Name": "ASTRID 002715",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Bob",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 8e-8,
    "y_1": -8.7e-7,
    "z_1": -7.3e-7,
    "Distance in Parsecs_1": 23.5849,
    "HYG Star ID_1": 119381,
    "Gliese Catalog_1": "NN 4171"
  },
  {
    "Astrid #": "ASTRID 002716",
    "HYG Star ID": 119534,
    "Gliese Catalog": "Wo 9800B",
    "Distance in Parsecs": 23.5849,
    "mag": 8.6,
    "Absolute Visual Magnitude": 6.737,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 18.956112,
    "y": -5.7477,
    "z": -12.803057,
    "vx": 0.00001221,
    "vy": -0.00000679,
    "vz": -0.0000109,
    "rarad": 5.98878655178317,
    "decrad": -0.57380279788655,
    "pmrarad": -1.25324336423611e-7,
    "pmdecrad": -7.3817731e-8,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 112588,
    "base": "Wo 9800",
    "lum": 0.175873335413557,
    "ra": 22.87548,
    "dec": -32.876479,
    "pmra": -25.85,
    "pmdec": -15.23,
    "rv": 17,
    "Asset Name": "ASTRID 002716",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Little Crown",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001221,
    "y_1": -0.00000679,
    "z_1": -0.0000109,
    "Distance in Parsecs_1": 23.5849,
    "HYG Star ID_1": 119534,
    "Gliese Catalog_1": "Wo 9800B"
  },
  {
    "Astrid #": "ASTRID 002717",
    "HYG Star ID": 99232,
    "Hipparcos Catalogue": 99550,
    "Distance in Parsecs": 23.6072,
    "mag": 11.3,
    "Absolute Visual Magnitude": 9.435,
    "Color Index": 1.424,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.546507,
    "y": -19.290874,
    "z": -5.267562,
    "vx": 0.00001605,
    "vy": 0.00001629,
    "vz": -0.00002143,
    "rarad": 5.28903502734502,
    "decrad": -0.225028048839315,
    "pmrarad": 9.46210860340277e-7,
    "pmdecrad": -9.31181636e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 99232,
    "lum": 0.014655478409559,
    "var_min": 11.379,
    "var_max": 11.179,
    "ra": 20.202626,
    "dec": -12.893157,
    "pmra": 195.17,
    "pmdec": -192.07,
    "rv": 0,
    "Asset Name": "ASTRID 002717",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Wavy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00001605,
    "y_1": 0.00001629,
    "z_1": -0.00002143,
    "Distance in Parsecs_1": 23.6072,
    "HYG Star ID_1": 99232,
    "Hipparcos Catalogue_1": 99550
  },
  {
    "Astrid #": "ASTRID 002718",
    "HYG Star ID": 19185,
    "Hipparcos Catalogue": 19233,
    "Henry Draper Catalogue": 26491,
    "Harvard Revised Catalogue": 1294,
    "Gliese Catalog": "Gl 162.1",
    "Distance in Parsecs": 23.6295,
    "mag": 6.37,
    "Absolute Visual Magnitude": 4.503,
    "Spectral Type": "G3V",
    "Color Index": 0.636,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.849705,
    "y": 9.05975,
    "z": -21.278304,
    "vx": 0.00000339,
    "vy": 0.00005146,
    "vz": -0.00000855,
    "rarad": 1.07929713252474,
    "decrad": -1.12089042923768,
    "pmrarad": 9.01317113520833e-7,
    "pmdecrad": 0.00000163265855,
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 19185,
    "lum": 1.37657538650941,
    "ra": 4.122611,
    "dec": -64.222291,
    "pmra": 185.91,
    "pmdec": 336.76,
    "rv": 27.5,
    "Asset Name": "ASTRID 002718",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Windy",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": 0.00000339,
    "y_1": 0.00005146,
    "z_1": -0.00000855,
    "Distance in Parsecs_1": 23.6295,
    "HYG Star ID_1": 19185,
    "Hipparcos Catalogue_1": 19233,
    "Henry Draper Catalogue_1": 26491,
    "Harvard Revised Catalogue_1": 1294,
    "Gliese Catalog_1": "Gl 162.1"
  },
  {
    "Astrid #": "ASTRID 002719",
    "HYG Star ID": 5944,
    "Hipparcos Catalogue": 5957,
    "Gliese Catalog": "Gl 55.2",
    "Distance in Parsecs": 23.6351,
    "mag": 10.1,
    "Absolute Visual Magnitude": 8.232,
    "Spectral Type": "K5",
    "Color Index": 1.353,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.17845,
    "y": 7.012664,
    "z": 10.112268,
    "vx": -0.00003748,
    "vy": 0.00003892,
    "vz": -0.00002391,
    "rarad": 0.334472804574484,
    "decrad": 0.4421176363776,
    "pmrarad": 0.00000207640851245138,
    "pmdecrad": -5.0464256e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 5944,
    "lum": 0.0443812980308138,
    "ra": 1.277592,
    "dec": 25.331475,
    "pmra": 428.29,
    "pmdec": -104.09,
    "rv": -30,
    "Asset Name": "ASTRID 002719",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Braid",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00003748,
    "y_1": 0.00003892,
    "z_1": -0.00002391,
    "Distance in Parsecs_1": 23.6351,
    "HYG Star ID_1": 5944,
    "Hipparcos Catalogue_1": 5957,
    "Gliese Catalog_1": "Gl 55.2"
  },
  {
    "Astrid #": "ASTRID 002720",
    "HYG Star ID": 111763,
    "Hipparcos Catalogue": 112117,
    "Henry Draper Catalogue": 214953,
    "Harvard Revised Catalogue": 8635,
    "Gliese Catalog": "Gl 871A",
    "Distance in Parsecs": 23.6351,
    "mag": 5.99,
    "Absolute Visual Magnitude": 4.122,
    "Spectral Type": "G0",
    "Color Index": 0.584,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.148896,
    "y": -5.318822,
    "z": -17.344922,
    "vx": -0.00001472,
    "vy": 0.00000591,
    "vz": -0.00003878,
    "rarad": 5.94552814289036,
    "decrad": -0.823984133657333,
    "pmrarad": 2.98160413541666e-8,
    "pmdecrad": -0.000001606817981,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 111763,
    "base": "Gl 871",
    "lum": 1.95523966962088,
    "ra": 22.710245,
    "dec": -47.210813,
    "pmra": 6.15,
    "pmdec": -331.43,
    "rv": 17.3,
    "Asset Name": "ASTRID 002720",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Frizzy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00001472,
    "y_1": 0.00000591,
    "z_1": -0.00003878,
    "Distance in Parsecs_1": 23.6351,
    "HYG Star ID_1": 111763,
    "Hipparcos Catalogue_1": 112117,
    "Henry Draper Catalogue_1": 214953,
    "Harvard Revised Catalogue_1": 8635,
    "Gliese Catalog_1": "Gl 871A"
  },
  {
    "Astrid #": "ASTRID 002721",
    "HYG Star ID": 66628,
    "Hipparcos Catalogue": 66840,
    "Henry Draper Catalogue": 119217,
    "Gliese Catalog": "Gl 522",
    "Distance in Parsecs": 23.6407,
    "mag": 9.76,
    "Absolute Visual Magnitude": 7.892,
    "Spectral Type": "M0V",
    "Color Index": 1.288,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.341253,
    "y": -10.171046,
    "z": -0.053287,
    "vx": -0.0000502,
    "vy": -0.00000352,
    "vz": -0.00004936,
    "rarad": 3.58633814751993,
    "decrad": -0.00225398347890875,
    "pmrarad": -7.79483435597222e-7,
    "pmdecrad": -0.000002083826161,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66628,
    "lum": 0.0607015806341043,
    "ra": 13.698803,
    "dec": -0.129144,
    "pmra": -160.78,
    "pmdec": -429.82,
    "rv": 45.9,
    "Asset Name": "ASTRID 002721",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Shocked",
    "Hair": "Honest Lad",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.0000502,
    "y_1": -0.00000352,
    "z_1": -0.00004936,
    "Distance in Parsecs_1": 23.6407,
    "HYG Star ID_1": 66628,
    "Hipparcos Catalogue_1": 66840,
    "Henry Draper Catalogue_1": 119217,
    "Gliese Catalog_1": "Gl 522"
  },
  {
    "Astrid #": "ASTRID 002722",
    "HYG Star ID": 119012,
    "Gliese Catalog": "NN 3895",
    "Distance in Parsecs": 23.6407,
    "mag": 13.45,
    "Absolute Visual Magnitude": 11.582,
    "Spectral Type": "m",
    "Color Index": 1.51,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.051186,
    "y": -16.706652,
    "z": 7.286433,
    "vx": -0.00000827,
    "vy": 0.00005744,
    "vz": -0.00007776,
    "rarad": 3.97907632477065,
    "decrad": 0.31335743349215,
    "pmrarad": -0.0000018862645869375,
    "pmdecrad": -0.000002644450157,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119012,
    "lum": 0.00202861671696629,
    "ra": 15.198952,
    "dec": 17.954058,
    "pmra": -389.07,
    "pmdec": -545.46,
    "rv": -58,
    "Asset Name": "ASTRID 002722",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Hachimaki",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000827,
    "y_1": 0.00005744,
    "z_1": -0.00007776,
    "Distance in Parsecs_1": 23.6407,
    "HYG Star ID_1": 119012,
    "Gliese Catalog_1": "NN 3895"
  },
  {
    "Astrid #": "ASTRID 002723",
    "HYG Star ID": 118074,
    "Gliese Catalog": "Gl 60B",
    "Distance in Parsecs": 23.6463,
    "mag": 8,
    "Absolute Visual Magnitude": 6.131,
    "Spectral Type": "K4 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.760586,
    "y": 8.2564,
    "z": -11.790665,
    "vx": -9.3e-7,
    "vy": 0.00001849,
    "vz": 0.00001147,
    "rarad": 0.414579038888471,
    "decrad": -0.52201317300825,
    "pmrarad": 7.31777769430555e-7,
    "pmdecrad": 5.59479835e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 2,
    "comp_primary": 7358,
    "base": "Gl  60",
    "lum": 0.307326492920429,
    "ra": 1.583575,
    "dec": -29.909152,
    "pmra": 150.94,
    "pmdec": 115.4,
    "rv": 0,
    "Asset Name": "ASTRID 002723",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Guilty Grin",
    "Hair": "Audiophile",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -9.3e-7,
    "y_1": 0.00001849,
    "z_1": 0.00001147,
    "Distance in Parsecs_1": 23.6463,
    "HYG Star ID_1": 118074,
    "Gliese Catalog_1": "Gl 60B"
  },
  {
    "Astrid #": "ASTRID 002724",
    "HYG Star ID": 118075,
    "Gliese Catalog": "Gl 60C",
    "Distance in Parsecs": 23.6463,
    "mag": 10.4,
    "Absolute Visual Magnitude": 8.531,
    "Spectral Type": "M2 V",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.760586,
    "y": 8.2564,
    "z": -11.790665,
    "vx": -9.3e-7,
    "vy": 0.00001849,
    "vz": 0.00001147,
    "rarad": 0.414579038888471,
    "decrad": -0.52201317300825,
    "pmrarad": 7.31777769430555e-7,
    "pmdecrad": 5.59479835e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 3,
    "comp_primary": 7358,
    "base": "Gl  60",
    "lum": 0.0336976798584403,
    "ra": 1.583575,
    "dec": -29.909152,
    "pmra": 150.94,
    "pmdec": 115.4,
    "rv": 0,
    "Asset Name": "ASTRID 002724",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Ninja",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -9.3e-7,
    "y_1": 0.00001849,
    "z_1": 0.00001147,
    "Distance in Parsecs_1": 23.6463,
    "HYG Star ID_1": 118075,
    "Gliese Catalog_1": "Gl 60C"
  },
  {
    "Astrid #": "ASTRID 002725",
    "HYG Star ID": 98999,
    "Hipparcos Catalogue": 99316,
    "Henry Draper Catalogue": 191499,
    "Distance in Parsecs": 23.663,
    "mag": 7.56,
    "Absolute Visual Magnitude": 5.69,
    "Spectral Type": "K0...",
    "Color Index": 0.81,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.135234,
    "y": -19.127284,
    "z": 6.841555,
    "vx": -0.00001985,
    "vy": 0.00003211,
    "vz": 0.00000966,
    "rarad": 5.27775228777633,
    "decrad": 0.293315948222228,
    "pmrarad": 1.83744384930555e-8,
    "pmdecrad": 8.52253969e-7,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 1,
    "comp_primary": 98999,
    "lum": 0.461317574560379,
    "ra": 20.159529,
    "dec": 16.805766,
    "pmra": 3.79,
    "pmdec": 175.79,
    "rv": -32.6,
    "Asset Name": "ASTRID 002725",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Hachimaki",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": -0.00001985,
    "y_1": 0.00003211,
    "z_1": 0.00000966,
    "Distance in Parsecs_1": 23.663,
    "HYG Star ID_1": 98999,
    "Hipparcos Catalogue_1": 99316,
    "Henry Draper Catalogue_1": 191499
  },
  {
    "Astrid #": "ASTRID 002726",
    "HYG Star ID": 23731,
    "Hipparcos Catalogue": 23786,
    "Henry Draper Catalogue": 32850,
    "Gliese Catalog": "NN 3330",
    "Distance in Parsecs": 23.6742,
    "mag": 7.74,
    "Absolute Visual Magnitude": 5.869,
    "Spectral Type": "K0V",
    "Color Index": 0.804,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.283492,
    "y": 22.308835,
    "z": 5.906108,
    "vx": -0.00002257,
    "vy": 0.00004546,
    "vz": -0.00001828,
    "rarad": 1.33824664419976,
    "decrad": 0.252134357394943,
    "pmrarad": 0.00000137042283082638,
    "pmdecrad": -0.000001159383435,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 23731,
    "lum": 0.39120103938735,
    "ra": 5.111726,
    "dec": 14.446235,
    "pmra": 282.67,
    "pmdec": -239.14,
    "rv": 32.5,
    "Asset Name": "ASTRID 002726",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Pixie",
    "Outfit": "Super",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00002257,
    "y_1": 0.00004546,
    "z_1": -0.00001828,
    "Distance in Parsecs_1": 23.6742,
    "HYG Star ID_1": 23731,
    "Hipparcos Catalogue_1": 23786,
    "Henry Draper Catalogue_1": 32850,
    "Gliese Catalog_1": "NN 3330"
  },
  {
    "Astrid #": "ASTRID 002727",
    "HYG Star ID": 55476,
    "Hipparcos Catalogue": 55642,
    "Henry Draper Catalogue": 99028,
    "Harvard Revised Catalogue": 4399,
    "Distance in Parsecs": 23.6742,
    "mag": 4,
    "Absolute Visual Magnitude": 2.129,
    "Spectral Type": "F2IV SB",
    "Color Index": 0.423,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -22.987695,
    "y": 3.648687,
    "z": 4.326252,
    "vx": 0.00000579,
    "vy": -0.00001727,
    "vz": -0.00001065,
    "rarad": 2.98418219578348,
    "decrad": 0.183774593630873,
    "pmrarad": 6.82375255381944e-7,
    "pmdecrad": -3.77185043e-7,
    "bayer": "Iot",
    "flam": 78,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 55476,
    "lum": 12.257446320498,
    "bf": "78Iot Leo",
    "ra": 11.398736,
    "dec": 10.529509,
    "pmra": 140.75,
    "pmdec": -77.8,
    "rv": -10,
    "Asset Name": "ASTRID 002727",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Round Brush",
    "Outfit": "Turtle School",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000579,
    "y_1": -0.00001727,
    "z_1": -0.00001065,
    "Distance in Parsecs_1": 23.6742,
    "HYG Star ID_1": 55476,
    "Hipparcos Catalogue_1": 55642,
    "Henry Draper Catalogue_1": 99028,
    "Harvard Revised Catalogue_1": 4399
  },
  {
    "Astrid #": "ASTRID 002728",
    "HYG Star ID": 71517,
    "Hipparcos Catalogue": 71743,
    "Henry Draper Catalogue": 128987,
    "Distance in Parsecs": 23.6798,
    "mag": 7.24,
    "Absolute Visual Magnitude": 5.368,
    "Spectral Type": "G6V",
    "Color Index": 0.71,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.385587,
    "y": -14.655385,
    "z": -6.610141,
    "vx": -0.00000668,
    "vy": 0.00001117,
    "vz": -0.00000719,
    "rarad": 3.8419866453712,
    "decrad": -0.282905481286258,
    "pmrarad": -5.42700434013888e-7,
    "pmdecrad": -3.16147001e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 71517,
    "lum": 0.620583179299061,
    "var": "KU",
    "var_min": 7.256,
    "var_max": 7.226,
    "ra": 14.675308,
    "dec": -16.20929,
    "pmra": -111.94,
    "pmdec": -65.21,
    "rv": 0,
    "Asset Name": "ASTRID 002728",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Ponytail",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00000668,
    "y_1": 0.00001117,
    "z_1": -0.00000719,
    "Distance in Parsecs_1": 23.6798,
    "HYG Star ID_1": 71517,
    "Hipparcos Catalogue_1": 71743,
    "Henry Draper Catalogue_1": 128987
  },
  {
    "Astrid #": "ASTRID 002729",
    "HYG Star ID": 114017,
    "Hipparcos Catalogue": 114379,
    "Henry Draper Catalogue": 218738,
    "Gliese Catalog": "NN 4315A",
    "Distance in Parsecs": 23.6798,
    "mag": 7.91,
    "Absolute Visual Magnitude": 6.038,
    "Spectral Type": "K0Ve",
    "Color Index": 0.9,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.481071,
    "y": -3.435187,
    "z": 17.585959,
    "vx": -0.00000192,
    "vy": 0.00001772,
    "vz": -0.00000421,
    "rarad": 6.06482422646123,
    "decrad": 0.837031517871266,
    "pmrarad": 7.12966998624999e-7,
    "pmdecrad": 6.0213859e-8,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114017,
    "base": "NN 4315",
    "lum": 0.334811217386053,
    "var": "KZ",
    "ra": 23.165922,
    "dec": 47.958373,
    "pmra": 147.06,
    "pmdec": 12.42,
    "rv": -6.8,
    "Asset Name": "ASTRID 002729",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Ram Horns",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00000192,
    "y_1": 0.00001772,
    "z_1": -0.00000421,
    "Distance in Parsecs_1": 23.6798,
    "HYG Star ID_1": 114017,
    "Hipparcos Catalogue_1": 114379,
    "Henry Draper Catalogue_1": 218738,
    "Gliese Catalog_1": "NN 4315A"
  },
  {
    "Astrid #": "ASTRID 002730",
    "HYG Star ID": 3002,
    "Hipparcos Catalogue": 3008,
    "Gliese Catalog": "NN 3044",
    "Distance in Parsecs": 23.6967,
    "mag": 10.48,
    "Absolute Visual Magnitude": 8.607,
    "Spectral Type": "M0",
    "Color Index": 1.424,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.279408,
    "y": 2.40588,
    "z": 18.7575,
    "vx": 0.00001365,
    "vy": -0.00000632,
    "vz": -0.00000958,
    "rarad": 0.166920290528266,
    "decrad": 0.913367325082393,
    "pmrarad": -3.58568198138888e-7,
    "pmdecrad": -6.61819155e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 3002,
    "lum": 0.0314195528464107,
    "var_min": 10.558,
    "var_max": 10.428,
    "ra": 0.637589,
    "dec": 52.332093,
    "pmra": -73.96,
    "pmdec": -136.51,
    "rv": 0,
    "Asset Name": "ASTRID 002730",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Hachimaki",
    "Outfit": "Tats",
    "Special": "Astral Burst",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001365,
    "y_1": -0.00000632,
    "z_1": -0.00000958,
    "Distance in Parsecs_1": 23.6967,
    "HYG Star ID_1": 3002,
    "Hipparcos Catalogue_1": 3008,
    "Gliese Catalog_1": "NN 3044"
  },
  {
    "Astrid #": "ASTRID 002731",
    "HYG Star ID": 107282,
    "Hipparcos Catalogue": 107625,
    "Henry Draper Catalogue": 207144,
    "Gliese Catalog": "Wo 9754",
    "Distance in Parsecs": 23.6967,
    "mag": 8.62,
    "Absolute Visual Magnitude": 6.747,
    "Spectral Type": "K3V",
    "Color Index": 0.96,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.166903,
    "y": -9.849431,
    "z": -15.312886,
    "vx": -0.000019640000000000002,
    "vy": 0.00002747,
    "vz": -0.00002636,
    "rarad": 5.70722853085447,
    "decrad": -0.70260113386766,
    "pmrarad": 5.21126225229166e-7,
    "pmdecrad": -0.000001706156304,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 107282,
    "lum": 0.174260918982592,
    "ra": 21.800007,
    "dec": -40.25608,
    "pmra": 107.49,
    "pmdec": -351.92,
    "rv": -6.8,
    "Asset Name": "ASTRID 002731",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Fedora",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.000019640000000000002,
    "y_1": 0.00002747,
    "z_1": -0.00002636,
    "Distance in Parsecs_1": 23.6967,
    "HYG Star ID_1": 107282,
    "Hipparcos Catalogue_1": 107625,
    "Henry Draper Catalogue_1": 207144,
    "Gliese Catalog_1": "Wo 9754"
  },
  {
    "Astrid #": "ASTRID 002732",
    "HYG Star ID": 119574,
    "Gliese Catalog": "NN 4345",
    "Distance in Parsecs": 23.6967,
    "mag": 16.1,
    "Absolute Visual Magnitude": 14.227,
    "Spectral Type": "m",
    "Color Index": 1.78,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.426392,
    "y": -2.493755,
    "z": 2.553225,
    "vx": 0.00000323,
    "vy": 0.00006336,
    "vz": 0.00003237,
    "rarad": 6.177133234828,
    "decrad": 0.1079555630985,
    "pmrarad": 0.00000267345656005555,
    "pmdecrad": 0.00000137396197,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119574,
    "lum": 0.000177500671350065,
    "ra": 23.594911,
    "dec": 6.185398,
    "pmra": 551.44,
    "pmdec": 283.4,
    "rv": 0,
    "Asset Name": "ASTRID 002732",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Fire",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000323,
    "y_1": 0.00006336,
    "z_1": 0.00003237,
    "Distance in Parsecs_1": 23.6967,
    "HYG Star ID_1": 119574,
    "Gliese Catalog_1": "NN 4345"
  },
  {
    "Astrid #": "ASTRID 002733",
    "HYG Star ID": 38954,
    "Hipparcos Catalogue": 39064,
    "Henry Draper Catalogue": 65430,
    "Gliese Catalog": "NN 3471",
    "Distance in Parsecs": 23.7248,
    "mag": 7.68,
    "Absolute Visual Magnitude": 5.804,
    "Spectral Type": "K0V",
    "Color Index": 0.833,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.049511,
    "y": 19.222371,
    "z": 8.441737,
    "vx": -0.00001566,
    "vy": -0.0000144,
    "vz": -0.00006877,
    "rarad": 2.09249899132921,
    "decrad": 0.363794459597178,
    "pmrarad": 8.74894767930555e-7,
    "pmdecrad": -0.000002639131751,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 38954,
    "lum": 0.415336405489073,
    "ra": 7.992757,
    "dec": 20.843887,
    "pmra": 180.46,
    "pmdec": -544.36,
    "rv": -28.2,
    "Asset Name": "ASTRID 002733",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Pixie",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00001566,
    "y_1": -0.0000144,
    "z_1": -0.00006877,
    "Distance in Parsecs_1": 23.7248,
    "HYG Star ID_1": 38954,
    "Hipparcos Catalogue_1": 39064,
    "Henry Draper Catalogue_1": 65430,
    "Gliese Catalog_1": "NN 3471"
  },
  {
    "Astrid #": "ASTRID 002734",
    "HYG Star ID": 6903,
    "Hipparcos Catalogue": 6917,
    "Henry Draper Catalogue": 8997,
    "Gliese Catalog": "Gl 58.2",
    "Distance in Parsecs": 23.7361,
    "mag": 7.74,
    "Absolute Visual Magnitude": 5.863,
    "Spectral Type": "K2V",
    "Color Index": 0.966,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.405682,
    "y": 8.356668,
    "z": 8.785299,
    "vx": -0.00000264,
    "vy": 0.00005557,
    "vz": -0.0000155,
    "rarad": 0.388690338248951,
    "decrad": 0.379140766078546,
    "pmrarad": 0.00000220871416587499,
    "pmdecrad": -8.96905309e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 6903,
    "lum": 0.393368880673202,
    "var": "EO",
    "var_min": 7.771,
    "var_max": 7.711,
    "ra": 1.484688,
    "dec": 21.723166,
    "pmra": 455.58,
    "pmdec": -185,
    "rv": 11.3,
    "Asset Name": "ASTRID 002734",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Big Laugh",
    "Hair": "Braided Ponytail",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000264,
    "y_1": 0.00005557,
    "z_1": -0.0000155,
    "Distance in Parsecs_1": 23.7361,
    "HYG Star ID_1": 6903,
    "Hipparcos Catalogue_1": 6917,
    "Henry Draper Catalogue_1": 8997,
    "Gliese Catalog_1": "Gl 58.2"
  },
  {
    "Astrid #": "ASTRID 002735",
    "HYG Star ID": 70650,
    "Hipparcos Catalogue": 70873,
    "Henry Draper Catalogue": 127334,
    "Harvard Revised Catalogue": 5423,
    "Gliese Catalog": "NN 3852",
    "Distance in Parsecs": 23.7417,
    "mag": 6.36,
    "Absolute Visual Magnitude": 4.482,
    "Spectral Type": "G5V",
    "Color Index": 0.702,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.060506,
    "y": -10.751378,
    "z": 15.823354,
    "vx": -0.00000244,
    "vy": -0.0000252,
    "vz": -0.00001868,
    "rarad": 3.79440225535799,
    "decrad": 0.729476261309148,
    "pmrarad": 7.80840913902777e-7,
    "pmdecrad": -0.000001071050383,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 70650,
    "lum": 1.40345987292117,
    "ra": 14.493549,
    "dec": 41.795911,
    "pmra": 161.06,
    "pmdec": -220.92,
    "rv": 0.4,
    "Asset Name": "ASTRID 002735",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Side Part",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000244,
    "y_1": -0.0000252,
    "z_1": -0.00001868,
    "Distance in Parsecs_1": 23.7417,
    "HYG Star ID_1": 70650,
    "Hipparcos Catalogue_1": 70873,
    "Henry Draper Catalogue_1": 127334,
    "Harvard Revised Catalogue_1": 5423,
    "Gliese Catalog_1": "NN 3852"
  },
  {
    "Astrid #": "ASTRID 002736",
    "HYG Star ID": 119047,
    "Gliese Catalog": "NN 3927",
    "Distance in Parsecs": 23.753,
    "mag": 15.95,
    "Absolute Visual Magnitude": 14.071,
    "Spectral Type": "m",
    "Color Index": 1.76,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.227987,
    "y": -16.86903,
    "z": 13.229858,
    "vx": 0.00002527,
    "vy": -0.00005156,
    "vz": -0.00004619,
    "rarad": 4.16731651178409,
    "decrad": 0.5907406201025,
    "pmrarad": 0.00000203563568191666,
    "pmdecrad": -0.00000234171795,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119047,
    "lum": 0.000204927385838024,
    "ra": 15.917977,
    "dec": 33.846944,
    "pmra": 419.88,
    "pmdec": -483.01,
    "rv": 0,
    "Asset Name": "ASTRID 002736",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "High Fade",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002527,
    "y_1": -0.00005156,
    "z_1": -0.00004619,
    "Distance in Parsecs_1": 23.753,
    "HYG Star ID_1": 119047,
    "Gliese Catalog_1": "NN 3927"
  },
  {
    "Astrid #": "ASTRID 002737",
    "HYG Star ID": 119461,
    "Gliese Catalog": "Wo 9763",
    "Distance in Parsecs": 23.753,
    "mag": 14.17,
    "Absolute Visual Magnitude": 12.291,
    "Spectral Type": "M2 :",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.48225,
    "y": -11.90828,
    "z": -1.695676,
    "vx": -0.00000107,
    "vy": 0.00000532,
    "vz": -0.00005037,
    "rarad": 5.75655821653739,
    "decrad": -0.0714486712767999,
    "pmrarad": 1.71090747868055e-7,
    "pmdecrad": -0.000002126305536,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119461,
    "lum": 0.00105584459243007,
    "ra": 21.988433,
    "dec": -4.093707,
    "pmra": 35.29,
    "pmdec": -438.58,
    "rv": 0,
    "Asset Name": "ASTRID 002737",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Angry",
    "Hair": "Shinobi",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000107,
    "y_1": 0.00000532,
    "z_1": -0.00005037,
    "Distance in Parsecs_1": 23.753,
    "HYG Star ID_1": 119461,
    "Gliese Catalog_1": "Wo 9763"
  },
  {
    "Astrid #": "ASTRID 002738",
    "HYG Star ID": 85322,
    "Hipparcos Catalogue": 85582,
    "Gliese Catalog": "Gl 677A",
    "Distance in Parsecs": 23.7586,
    "mag": 8.99,
    "Absolute Visual Magnitude": 7.111,
    "Spectral Type": "K2",
    "Color Index": 1.143,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.761524,
    "y": -20.51537,
    "z": 11.660231,
    "vx": -0.00002352,
    "vy": -0.00000527,
    "vz": -0.00003277,
    "rarad": 4.5785869412948,
    "decrad": 0.512985872318005,
    "pmrarad": -9.51737736298611e-7,
    "pmdecrad": -0.000001374592228,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 85322,
    "base": "Gl 677",
    "lum": 0.124623516048651,
    "ra": 17.488914,
    "dec": 29.391925,
    "pmra": -196.31,
    "pmdec": -283.53,
    "rv": -8.6,
    "Asset Name": "ASTRID 002738",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Elegant",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00002352,
    "y_1": -0.00000527,
    "z_1": -0.00003277,
    "Distance in Parsecs_1": 23.7586,
    "HYG Star ID_1": 85322,
    "Hipparcos Catalogue_1": 85582,
    "Gliese Catalog_1": "Gl 677A"
  },
  {
    "Astrid #": "ASTRID 002739",
    "HYG Star ID": 3749,
    "Hipparcos Catalogue": 3757,
    "Distance in Parsecs": 23.7699,
    "mag": 12.05,
    "Absolute Visual Magnitude": 10.17,
    "Color Index": 1.506,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 23.152354,
    "y": 4.944657,
    "z": -2.127669,
    "vx": -0.00000567,
    "vy": 0.00002041,
    "vz": -0.00001429,
    "rarad": 0.210409266138082,
    "decrad": -0.08963104043903,
    "pmrarad": 8.89730066555555e-7,
    "pmdecrad": -6.03593032e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 3749,
    "lum": 0.00744731973905988,
    "var_min": 12.175,
    "var_max": 11.945,
    "ra": 0.803704,
    "dec": -5.13548,
    "pmra": 183.52,
    "pmdec": -124.5,
    "rv": 0,
    "Asset Name": "ASTRID 002739",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Big Laugh",
    "Hair": "Elegant",
    "Outfit": "Toga",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000567,
    "y_1": 0.00002041,
    "z_1": -0.00001429,
    "Distance in Parsecs_1": 23.7699,
    "HYG Star ID_1": 3749,
    "Hipparcos Catalogue_1": 3757
  },
  {
    "Astrid #": "ASTRID 002740",
    "HYG Star ID": 30241,
    "Hipparcos Catalogue": 30314,
    "Henry Draper Catalogue": 45270,
    "Distance in Parsecs": 23.7812,
    "mag": 6.53,
    "Absolute Visual Magnitude": 4.649,
    "Spectral Type": "G1V",
    "Color Index": 0.614,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.158575,
    "y": 11.754962,
    "z": -20.64035,
    "vx": 6.6e-7,
    "vy": 0.00000652,
    "vz": 0.00000367,
    "rarad": 1.66904001103416,
    "decrad": -1.05101375483179,
    "pmrarad": -5.43960949583333e-8,
    "pmdecrad": 3.11104938e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 30241,
    "lum": 1.20337227118268,
    "ra": 6.375263,
    "dec": -60.218652,
    "pmra": -11.22,
    "pmdec": 64.17,
    "rv": 0,
    "Asset Name": "ASTRID 002740",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Owie",
    "Hair": "Headband",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 6.6e-7,
    "y_1": 0.00000652,
    "z_1": 0.00000367,
    "Distance in Parsecs_1": 23.7812,
    "HYG Star ID_1": 30241,
    "Hipparcos Catalogue_1": 30314,
    "Henry Draper Catalogue_1": 45270
  },
  {
    "Astrid #": "ASTRID 002741",
    "HYG Star ID": 2069,
    "Hipparcos Catalogue": 2072,
    "Henry Draper Catalogue": 2262,
    "Harvard Revised Catalogue": 100,
    "Gliese Catalog": "Gl 20",
    "Distance in Parsecs": 23.8095,
    "mag": 3.93,
    "Absolute Visual Magnitude": 2.046,
    "Spectral Type": "A7V",
    "Color Index": 0.175,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 17.106924,
    "y": 1.964466,
    "z": -16.443574,
    "vx": 0.00000951,
    "vy": 0.00001342,
    "vz": -0.00000524,
    "rarad": 0.114332017585854,
    "decrad": -0.762356840312103,
    "pmrarad": 5.14581240541666e-7,
    "pmdecrad": 1.59164331e-7,
    "bayer": "Kap",
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 2069,
    "lum": 13.2312233311337,
    "bf": "Kap Phe",
    "ra": 0.436716,
    "dec": -43.679829,
    "pmra": 106.14,
    "pmdec": 32.83,
    "rv": 11.3,
    "Asset Name": "ASTRID 002741",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Pompadour",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": 0.00000951,
    "y_1": 0.00001342,
    "z_1": -0.00000524,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 2069,
    "Hipparcos Catalogue_1": 2072,
    "Henry Draper Catalogue_1": 2262,
    "Harvard Revised Catalogue_1": 100,
    "Gliese Catalog_1": "Gl 20"
  },
  {
    "Astrid #": "ASTRID 002742",
    "HYG Star ID": 116050,
    "Hipparcos Catalogue": 116416,
    "Henry Draper Catalogue": 221851,
    "Gliese Catalog": "NN 4343",
    "Distance in Parsecs": 23.8095,
    "mag": 7.9,
    "Absolute Visual Magnitude": 6.016,
    "Spectral Type": "G5",
    "Color Index": 0.845,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.256983,
    "y": -2.180329,
    "z": 12.320125,
    "vx": -0.0000013,
    "vy": -0.00002269,
    "vz": -0.00003726,
    "rarad": 6.17596608217475,
    "decrad": 0.543867387796212,
    "pmrarad": -9.53580028284722e-7,
    "pmdecrad": -0.00000136402329,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 116050,
    "lum": 0.341664612813822,
    "var": "V406",
    "var_min": 7.927,
    "var_max": 7.877,
    "ra": 23.590453,
    "dec": 31.161306,
    "pmra": -196.69,
    "pmdec": -281.35,
    "rv": -17.9,
    "Asset Name": "ASTRID 002742",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Good Boy",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.0000013,
    "y_1": -0.00002269,
    "z_1": -0.00003726,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 116050,
    "Hipparcos Catalogue_1": 116416,
    "Henry Draper Catalogue_1": 221851,
    "Gliese Catalog_1": "NN 4343"
  },
  {
    "Astrid #": "ASTRID 002743",
    "HYG Star ID": 117983,
    "Gliese Catalog": "NN 3031",
    "Distance in Parsecs": 23.8095,
    "mag": 16.5,
    "Absolute Visual Magnitude": 14.616,
    "Spectral Type": "DC9",
    "Color Index": 0.72,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 17.440217,
    "y": 1.698587,
    "z": 16.119737,
    "vx": 0.00002283,
    "vy": -0.00001799,
    "vz": -0.0000228,
    "rarad": 0.0971038023358763,
    "decrad": 0.74371903330665,
    "pmrarad": -8.44933282472222e-7,
    "pmdecrad": -0.000001301113867,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117983,
    "lum": 0.000124050923004222,
    "ra": 0.370909,
    "dec": 42.611962,
    "pmra": -174.28,
    "pmdec": -268.37,
    "rv": 0,
    "Asset Name": "ASTRID 002743",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Greasy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002283,
    "y_1": -0.00001799,
    "z_1": -0.0000228,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 117983,
    "Gliese Catalog_1": "NN 3031"
  },
  {
    "Astrid #": "ASTRID 002744",
    "HYG Star ID": 118027,
    "Gliese Catalog": "NN 3063",
    "Distance in Parsecs": 23.8095,
    "mag": 11.92,
    "Absolute Visual Magnitude": 10.036,
    "Spectral Type": "M3",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.435475,
    "y": 4.06098,
    "z": -15.69729,
    "vx": -0.00000245,
    "vy": -0.00000371,
    "vz": -0.00000368,
    "rarad": 0.228837066218732,
    "decrad": -0.7198698715348,
    "pmrarad": -1.28475625347222e-7,
    "pmdecrad": -2.05570696e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 118027,
    "lum": 0.00842558375339825,
    "ra": 0.874093,
    "dec": -41.245505,
    "pmra": -26.5,
    "pmdec": -42.4,
    "rv": 0,
    "Asset Name": "ASTRID 002744",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Slick",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000245,
    "y_1": -0.00000371,
    "z_1": -0.00000368,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118027,
    "Gliese Catalog_1": "NN 3063"
  },
  {
    "Astrid #": "ASTRID 002745",
    "HYG Star ID": 118102,
    "Gliese Catalog": "NN 3127",
    "Distance in Parsecs": 23.8095,
    "mag": 14.1,
    "Absolute Visual Magnitude": 12.216,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.243431,
    "y": 11.780545,
    "z": -4.279551,
    "vx": 0.00001395,
    "vy": -0.00003911,
    "vz": -0.00004173,
    "rarad": 0.527038726669072,
    "decrad": -0.18072351890805,
    "pmrarad": -0.00000171444661854861,
    "pmdecrad": -0.000001781598161,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118102,
    "lum": 0.00113135786411956,
    "ra": 2.01314,
    "dec": -10.354695,
    "pmra": -353.63,
    "pmdec": -367.48,
    "rv": 0,
    "Asset Name": "ASTRID 002745",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Afro",
    "Outfit": "Spartan",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001395,
    "y_1": -0.00003911,
    "z_1": -0.00004173,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118102,
    "Gliese Catalog_1": "NN 3127"
  },
  {
    "Astrid #": "ASTRID 002746",
    "HYG Star ID": 118156,
    "Gliese Catalog": "NN 3168",
    "Distance in Parsecs": 23.8095,
    "mag": 13.22,
    "Absolute Visual Magnitude": 11.336,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.469592,
    "y": 8.512071,
    "z": 19.616947,
    "vx": 0.00003625,
    "vy": -0.00000537,
    "vz": -0.00001701,
    "rarad": 0.682677832742075,
    "decrad": 0.96828067398085,
    "pmrarad": -0.00000113538515849305,
    "pmdecrad": -0.000001261000383,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118156,
    "lum": 0.00254448561514663,
    "ra": 2.607637,
    "dec": 55.478396,
    "pmra": -234.19,
    "pmdec": -260.1,
    "rv": 0,
    "Asset Name": "ASTRID 002746",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Good Boy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003625,
    "y_1": -0.00000537,
    "z_1": -0.00001701,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118156,
    "Gliese Catalog_1": "NN 3168"
  },
  {
    "Astrid #": "ASTRID 002747",
    "HYG Star ID": 118237,
    "Gliese Catalog": "NN 3238",
    "Distance in Parsecs": 23.8095,
    "mag": 13.05,
    "Absolute Visual Magnitude": 11.166,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.555309,
    "y": 14.543019,
    "z": -15.619806,
    "vx": -7.1e-7,
    "vy": -0.00004368,
    "vz": -0.00004116,
    "rarad": 0.942981659417676,
    "decrad": -0.7155498448091,
    "pmrarad": -0.00000105345164647916,
    "pmdecrad": -0.000002290376182,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118237,
    "lum": 0.0029757743773728,
    "ra": 3.601925,
    "dec": -40.997986,
    "pmra": -217.29,
    "pmdec": -472.42,
    "rv": 0,
    "Asset Name": "ASTRID 002747",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Ponytail",
    "Outfit": "Viking",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -7.1e-7,
    "y_1": -0.00004368,
    "z_1": -0.00004116,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118237,
    "Gliese Catalog_1": "NN 3238"
  },
  {
    "Astrid #": "ASTRID 002748",
    "HYG Star ID": 118243,
    "Gliese Catalog": "Wo 9119B",
    "Distance in Parsecs": 23.8095,
    "mag": 12.87,
    "Absolute Visual Magnitude": 10.986,
    "Color Index": 1.49,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 11.40636,
    "y": 16.257549,
    "z": 13.133138,
    "vx": 0.00000286,
    "vy": -0.00000195,
    "vz": -8e-8,
    "rarad": 0.95899615021886,
    "decrad": 0.58427207671065,
    "pmrarad": -1.45395622798611e-7,
    "pmdecrad": -3.805787e-9,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 17061,
    "base": "Wo 9119",
    "lum": 0.0035123679046568,
    "ra": 3.663095,
    "dec": 33.476324,
    "pmra": -29.99,
    "pmdec": -0.79,
    "rv": 0,
    "Asset Name": "ASTRID 002748",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Braided Ponytail",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000286,
    "y_1": -0.00000195,
    "z_1": -8e-8,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118243,
    "Gliese Catalog_1": "Wo 9119B"
  },
  {
    "Astrid #": "ASTRID 002749",
    "HYG Star ID": 118287,
    "Gliese Catalog": "NN 3274",
    "Distance in Parsecs": 23.8095,
    "mag": 13.03,
    "Absolute Visual Magnitude": 11.146,
    "Spectral Type": "k-m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.212608,
    "y": 20.174516,
    "z": 8.660775,
    "vx": -0.00000891,
    "vy": 0.00001416,
    "vz": -0.00002351,
    "rarad": 1.14242734570904,
    "decrad": 0.37229366277475,
    "pmrarad": 5.87594180833333e-7,
    "pmdecrad": -0.000001060064505,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118287,
    "lum": 0.00303109815358404,
    "ra": 4.363751,
    "dec": 21.330856,
    "pmra": 121.2,
    "pmdec": -218.65,
    "rv": 0,
    "Asset Name": "ASTRID 002749",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Manbun",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000891,
    "y_1": 0.00001416,
    "z_1": -0.00002351,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118287,
    "Gliese Catalog_1": "NN 3274"
  },
  {
    "Astrid #": "ASTRID 002750",
    "HYG Star ID": 118292,
    "Gliese Catalog": "NN 3276",
    "Distance in Parsecs": 23.8095,
    "mag": 17,
    "Absolute Visual Magnitude": 15.116,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.491734,
    "y": 20.886147,
    "z": 6.369311,
    "vx": -0.00000629,
    "vy": 0.00000386,
    "vz": -0.00000329,
    "rarad": 1.14424640128994,
    "decrad": 0.270809280878449,
    "pmrarad": 3.07565798944444e-7,
    "pmdecrad": -1.43422431e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118292,
    "lum": 0.0000782708409590612,
    "ra": 4.370699,
    "dec": 15.516229,
    "pmra": 63.44,
    "pmdec": -29.58,
    "rv": 0,
    "Asset Name": "ASTRID 002750",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Audiophile",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000629,
    "y_1": 0.00000386,
    "z_1": -0.00000329,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118292,
    "Gliese Catalog_1": "NN 3276"
  },
  {
    "Astrid #": "ASTRID 002751",
    "HYG Star ID": 118298,
    "Gliese Catalog": "NN 3282A",
    "Distance in Parsecs": 23.8095,
    "mag": 12.63,
    "Absolute Visual Magnitude": 10.746,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.471788,
    "y": 21.588435,
    "z": 3.334211,
    "vx": -0.00001482,
    "vy": 0.00000633,
    "vz": 0.00000112,
    "rarad": 1.15734224353931,
    "decrad": 0.14049876110275,
    "pmrarad": 6.77090786263888e-7,
    "pmdecrad": 4.7342055e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118298,
    "base": "NN 3282",
    "lum": 0.00438126982022492,
    "ra": 4.420722,
    "dec": 8.049986,
    "pmra": 139.66,
    "pmdec": 9.77,
    "rv": 0,
    "Asset Name": "ASTRID 002751",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Awkward",
    "Hair": "Ponytail",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001482,
    "y_1": 0.00000633,
    "z_1": 0.00000112,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118298,
    "Gliese Catalog_1": "NN 3282A"
  },
  {
    "Astrid #": "ASTRID 002752",
    "HYG Star ID": 118299,
    "Gliese Catalog": "NN 3283B",
    "Distance in Parsecs": 23.8095,
    "mag": 14.97,
    "Absolute Visual Magnitude": 13.086,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.468187,
    "y": 21.588852,
    "z": 3.341739,
    "vx": -0.00001483,
    "vy": 0.00000633,
    "vz": 0.00000112,
    "rarad": 1.15748917353931,
    "decrad": 0.14081809410275,
    "pmrarad": 6.77090786263888e-7,
    "pmdecrad": 4.7342055e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118299,
    "base": "NN 3283",
    "lum": 0.000507691625888248,
    "ra": 4.421283,
    "dec": 8.068282,
    "pmra": 139.66,
    "pmdec": 9.77,
    "rv": 0,
    "Asset Name": "ASTRID 002752",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Fire",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001483,
    "y_1": 0.00000633,
    "z_1": 0.00000112,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118299,
    "Gliese Catalog_1": "NN 3283B"
  },
  {
    "Astrid #": "ASTRID 002753",
    "HYG Star ID": 118395,
    "Gliese Catalog": "NN 3367",
    "Distance in Parsecs": 23.8095,
    "mag": 10.99,
    "Absolute Visual Magnitude": 9.106,
    "Spectral Type": "M0",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.31949,
    "y": 23.77291,
    "z": -0.004778,
    "vx": 0.00000229,
    "vy": -1.3e-7,
    "vz": -2.3e-7,
    "rarad": 1.51534930410387,
    "decrad": -0.0002006649357,
    "pmrarad": -9.64779224305555e-8,
    "pmdecrad": -9.458714e-9,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 118395,
    "lum": 0.0198426649848464,
    "ra": 5.788208,
    "dec": -0.011497,
    "pmra": -19.9,
    "pmdec": -1.95,
    "rv": 0,
    "Asset Name": "ASTRID 002753",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Windy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000229,
    "y_1": -1.3e-7,
    "z_1": -2.3e-7,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118395,
    "Gliese Catalog_1": "NN 3367"
  },
  {
    "Astrid #": "ASTRID 002754",
    "HYG Star ID": 118423,
    "Gliese Catalog": "NN 3391",
    "Distance in Parsecs": 23.8095,
    "mag": 12.27,
    "Absolute Visual Magnitude": 10.386,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.577202,
    "y": 16.983082,
    "z": 16.612634,
    "vx": -0.0000216,
    "vy": 0.00001787,
    "vz": -0.00002032,
    "rarad": 1.663383219945,
    "decrad": 0.77222564873105,
    "pmrarad": 8.34218900131944e-7,
    "pmdecrad": -0.000001191405379,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118423,
    "lum": 0.0061037958555853,
    "ra": 6.353656,
    "dec": 44.245271,
    "pmra": 172.07,
    "pmdec": -245.75,
    "rv": 0,
    "Asset Name": "ASTRID 002754",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "High Fade",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000216,
    "y_1": 0.00001787,
    "z_1": -0.00002032,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118423,
    "Gliese Catalog_1": "NN 3391"
  },
  {
    "Astrid #": "ASTRID 002755",
    "HYG Star ID": 118431,
    "Gliese Catalog": "NN 3397",
    "Distance in Parsecs": 23.8095,
    "mag": 13,
    "Absolute Visual Magnitude": 11.116,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.147657,
    "y": 8.081625,
    "z": -22.366549,
    "vx": -0.00003409,
    "vy": 0.00006832,
    "vz": 0.00002642,
    "rarad": 1.71176409902387,
    "decrad": -1.22086421298775,
    "pmrarad": 0.00000101486047750694,
    "pmdecrad": 0.000003238400245,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118431,
    "lum": 0.00311601830310546,
    "ra": 6.538457,
    "dec": -69.950367,
    "pmra": 209.33,
    "pmdec": 667.97,
    "rv": 0,
    "Asset Name": "ASTRID 002755",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Round Brush",
    "Outfit": "Sharky",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003409,
    "y_1": 0.00006832,
    "z_1": 0.00002642,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118431,
    "Gliese Catalog_1": "NN 3397"
  },
  {
    "Astrid #": "ASTRID 002756",
    "HYG Star ID": 118473,
    "Gliese Catalog": "NN 3439",
    "Distance in Parsecs": 23.8095,
    "mag": 13.34,
    "Absolute Visual Magnitude": 11.456,
    "Spectral Type": "k-m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.231031,
    "y": 19.15943,
    "z": 12.145811,
    "vx": 0.00003063,
    "vy": 0.00004862,
    "vz": -0.00005843,
    "rarad": 1.9316970332122,
    "decrad": 0.535329633455349,
    "pmrarad": -0.00000192485575590972,
    "pmdecrad": -0.000002853690893,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118473,
    "lum": 0.00227824276625007,
    "ra": 7.378539,
    "dec": 30.672129,
    "pmra": -397.03,
    "pmdec": -588.62,
    "rv": 0,
    "Asset Name": "ASTRID 002756",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Little Crown",
    "Outfit": "Super",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003063,
    "y_1": 0.00004862,
    "z_1": -0.00005843,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118473,
    "Gliese Catalog_1": "NN 3439"
  },
  {
    "Astrid #": "ASTRID 002757",
    "HYG Star ID": 118602,
    "Gliese Catalog": "NN 3537",
    "Distance in Parsecs": 23.8095,
    "mag": 13.35,
    "Absolute Visual Magnitude": 11.466,
    "Spectral Type": "M4  e",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.408001,
    "y": 16.004077,
    "z": 2.779088,
    "vx": 0.00004442,
    "vy": 0.00004015,
    "vz": 0.0000471,
    "rarad": 2.39818913601885,
    "decrad": 0.11698847803195,
    "pmrarad": -0.00000250415962280555,
    "pmdecrad": 0.000001991900639,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118602,
    "lum": 0.00225735571102144,
    "ra": 9.160408,
    "dec": 6.702946,
    "pmra": -516.52,
    "pmdec": 410.86,
    "rv": 0,
    "Asset Name": "ASTRID 002757",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Braid",
    "Outfit": "Toga",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004442,
    "y_1": 0.00004015,
    "z_1": 0.0000471,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118602,
    "Gliese Catalog_1": "NN 3537"
  },
  {
    "Astrid #": "ASTRID 002758",
    "HYG Star ID": 118664,
    "Gliese Catalog": "NN 3584",
    "Distance in Parsecs": 23.8095,
    "mag": 12.66,
    "Absolute Visual Magnitude": 10.776,
    "Spectral Type": "M0",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.751879,
    "y": 11.442127,
    "z": 2.308586,
    "vx": 0.00003405,
    "vy": 0.00006496,
    "vz": -0.00001578,
    "rarad": 2.63769286408623,
    "decrad": 0.09711326375405,
    "pmrarad": -0.00000308011827530555,
    "pmdecrad": -6.65944919e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118664,
    "lum": 0.0042618680542416,
    "ra": 10.075245,
    "dec": 5.56418,
    "pmra": -635.32,
    "pmdec": -137.36,
    "rv": 0,
    "Asset Name": "ASTRID 002758",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Mischievous",
    "Hair": "Heartbreaker",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003405,
    "y_1": 0.00006496,
    "z_1": -0.00001578,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118664,
    "Gliese Catalog_1": "NN 3584"
  },
  {
    "Astrid #": "ASTRID 002759",
    "HYG Star ID": 118669,
    "Gliese Catalog": "NN 3587",
    "Distance in Parsecs": 23.8095,
    "mag": 14.5,
    "Absolute Visual Magnitude": 12.616,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.301707,
    "y": 8.840244,
    "z": 13.762024,
    "vx": -0.00002176,
    "vy": -0.00000703,
    "vz": -0.00002284,
    "rarad": 2.66921867795763,
    "decrad": 0.61628251049255,
    "pmrarad": 6.78739152777777e-7,
    "pmdecrad": -0.000001175610149,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118669,
    "lum": 0.000782708409590611,
    "ra": 10.195664,
    "dec": 35.310387,
    "pmra": 140,
    "pmdec": -242.49,
    "rv": 0,
    "Asset Name": "ASTRID 002759",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Shinobi",
    "Outfit": "Toga",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002176,
    "y_1": -0.00000703,
    "z_1": -0.00002284,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118669,
    "Gliese Catalog_1": "NN 3587"
  },
  {
    "Astrid #": "ASTRID 002760",
    "HYG Star ID": 118708,
    "Gliese Catalog": "NN 3623",
    "Distance in Parsecs": 23.8095,
    "mag": 13.45,
    "Absolute Visual Magnitude": 11.566,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.407492,
    "y": 6.899069,
    "z": 7.811173,
    "vx": -0.00001085,
    "vy": -0.00002402,
    "vz": -0.00000852,
    "rarad": 2.82982545396019,
    "decrad": 0.334259343299149,
    "pmrarad": 0.00000110013920391666,
    "pmdecrad": -3.78814017e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118708,
    "lum": 0.00205873287673352,
    "ra": 10.809137,
    "dec": 19.15165,
    "pmra": 226.92,
    "pmdec": -78.14,
    "rv": 0,
    "Asset Name": "ASTRID 002760",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Middle Part",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001085,
    "y_1": -0.00002402,
    "z_1": -0.00000852,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118708,
    "Gliese Catalog_1": "NN 3623"
  },
  {
    "Astrid #": "ASTRID 002761",
    "HYG Star ID": 118712,
    "Gliese Catalog": "NN 3626",
    "Distance in Parsecs": 23.8095,
    "mag": 13.07,
    "Absolute Visual Magnitude": 11.186,
    "Spectral Type": "m",
    "Color Index": 1.52,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.034441,
    "y": 5.960937,
    "z": 13.001907,
    "vx": -0.00004016,
    "vy": 9.8e-7,
    "vz": -0.00005922,
    "rarad": 2.83809677266411,
    "decrad": 0.5776785093261,
    "pmrarad": 4.65033282388888e-7,
    "pmdecrad": -0.000002969653478,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118712,
    "lum": 0.00292146037387733,
    "ra": 10.840731,
    "dec": 33.09854,
    "pmra": 95.92,
    "pmdec": -612.53,
    "rv": 0,
    "Asset Name": "ASTRID 002761",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Grecian",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004016,
    "y_1": 9.8e-7,
    "z_1": -0.00005922,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118712,
    "Gliese Catalog_1": "NN 3626"
  },
  {
    "Astrid #": "ASTRID 002762",
    "HYG Star ID": 118739,
    "Gliese Catalog": "NN 3653",
    "Distance in Parsecs": 23.8095,
    "mag": 11.22,
    "Absolute Visual Magnitude": 9.336,
    "Spectral Type": "M0",
    "Color Index": 1.41,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.297459,
    "y": 2.58945,
    "z": 19.579699,
    "vx": -0.00007174,
    "vy": -0.0000024,
    "vz": -0.00004839,
    "rarad": 2.9492413481525,
    "decrad": 0.9655257730974,
    "pmrarad": 6.75103050173611e-7,
    "pmdecrad": -0.000003572878052,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 118739,
    "lum": 0.0160546188680097,
    "ra": 11.265272,
    "dec": 55.320552,
    "pmra": 139.25,
    "pmdec": -736.96,
    "rv": 0,
    "Asset Name": "ASTRID 002762",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Grecian",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00007174,
    "y_1": -0.0000024,
    "z_1": -0.00004839,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118739,
    "Gliese Catalog_1": "NN 3653"
  },
  {
    "Astrid #": "ASTRID 002763",
    "HYG Star ID": 118764,
    "Gliese Catalog": "NN 3676",
    "Distance in Parsecs": 23.8095,
    "mag": 12.57,
    "Absolute Visual Magnitude": 10.686,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.306318,
    "y": 1.204174,
    "z": 20.346912,
    "vx": -0.00001457,
    "vy": 0.00003858,
    "vz": -0.00001109,
    "rarad": 3.04412495857616,
    "decrad": 1.02472447773454,
    "pmrarad": -0.00000155348847660416,
    "pmdecrad": -8.96905309e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118764,
    "lum": 0.00463020265860308,
    "ra": 11.627701,
    "dec": 58.712388,
    "pmra": -320.43,
    "pmdec": -185,
    "rv": 0,
    "Asset Name": "ASTRID 002763",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Mohawk",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001457,
    "y_1": 0.00003858,
    "z_1": -0.00001109,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118764,
    "Gliese Catalog_1": "NN 3676"
  },
  {
    "Astrid #": "ASTRID 002764",
    "HYG Star ID": 118789,
    "Gliese Catalog": "NN 3697",
    "Distance in Parsecs": 23.8095,
    "mag": 12.07,
    "Absolute Visual Magnitude": 10.186,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.50873,
    "y": 0.076128,
    "z": 16.134771,
    "vx": 0.00000663,
    "vy": 0.00003565,
    "vz": 0.00000703,
    "rarad": 3.13727169204567,
    "decrad": 0.7445773896193,
    "pmrarad": -0.00000149855908659722,
    "pmdecrad": 4.01532386e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118789,
    "lum": 0.00733837667333537,
    "ra": 11.983495,
    "dec": 42.661142,
    "pmra": -309.1,
    "pmdec": 82.82,
    "rv": 0,
    "Asset Name": "ASTRID 002764",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Chubby Cheeks",
    "Hair": "Shinobi",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000663,
    "y_1": 0.00003565,
    "z_1": 0.00000703,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118789,
    "Gliese Catalog_1": "NN 3697"
  },
  {
    "Astrid #": "ASTRID 002765",
    "HYG Star ID": 118817,
    "Gliese Catalog": "NN 3726C",
    "Distance in Parsecs": 23.8095,
    "mag": 14.88,
    "Absolute Visual Magnitude": 12.996,
    "Spectral Type": "dM5  e",
    "Color Index": 1.73,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -21.064656,
    "y": -2.488994,
    "z": 10.815612,
    "vx": -0.00000925,
    "vy": -0.00001149,
    "vz": -0.00002067,
    "rarad": 3.25920435056406,
    "decrad": 0.47153705007325,
    "pmrarad": 4.33811281361111e-7,
    "pmdecrad": -9.74378535e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 118817,
    "base": "NN 3726",
    "lum": 0.000551569191260802,
    "ra": 12.449244,
    "dec": 27.017083,
    "pmra": 89.48,
    "pmdec": -200.98,
    "rv": 0,
    "Asset Name": "ASTRID 002765",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Maniacal",
    "Hair": "Medium Bob",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000925,
    "y_1": -0.00001149,
    "z_1": -0.00002067,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118817,
    "Gliese Catalog_1": "NN 3726C"
  },
  {
    "Astrid #": "ASTRID 002766",
    "HYG Star ID": 118899,
    "Gliese Catalog": "NN 3793",
    "Distance in Parsecs": 23.8095,
    "mag": 13.77,
    "Absolute Visual Magnitude": 11.886,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.460732,
    "y": -8.983495,
    "z": 8.218732,
    "vx": -0.00000401,
    "vy": -0.00000801,
    "vz": -0.00001874,
    "rarad": 3.55531120980334,
    "decrad": 0.352438107071649,
    "pmrarad": 2.40516066923611e-7,
    "pmdecrad": -8.38858567e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118899,
    "lum": 0.00153320419903491,
    "ra": 13.580288,
    "dec": 20.193216,
    "pmra": 49.61,
    "pmdec": -173.03,
    "rv": 0,
    "Asset Name": "ASTRID 002766",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Angelic",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000401,
    "y_1": -0.00000801,
    "z_1": -0.00001874,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118899,
    "Gliese Catalog_1": "NN 3793"
  },
  {
    "Astrid #": "ASTRID 002767",
    "HYG Star ID": 118903,
    "Gliese Catalog": "NN 3794",
    "Distance in Parsecs": 23.8095,
    "mag": 12.66,
    "Absolute Visual Magnitude": 10.776,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.990852,
    "y": -8.997335,
    "z": 9.290108,
    "vx": -0.00000246,
    "vy": -0.00001277,
    "vz": -0.00001766,
    "rarad": 3.56450505409405,
    "decrad": 0.40083241655855,
    "pmrarad": 4.46561881159722e-7,
    "pmdecrad": -8.05648829e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118903,
    "lum": 0.0042618680542416,
    "ra": 13.615406,
    "dec": 22.966006,
    "pmra": 92.11,
    "pmdec": -166.18,
    "rv": 0,
    "Asset Name": "ASTRID 002767",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Neko Ninja",
    "Hair": "Bucket Hat",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000246,
    "y_1": -0.00001277,
    "z_1": -0.00001766,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118903,
    "Gliese Catalog_1": "NN 3794"
  },
  {
    "Astrid #": "ASTRID 002768",
    "HYG Star ID": 118917,
    "Gliese Catalog": "NN 3805",
    "Distance in Parsecs": 23.8095,
    "mag": 13.3,
    "Absolute Visual Magnitude": 11.416,
    "Spectral Type": "DA3",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -11.602001,
    "y": -5.786592,
    "z": 19.970008,
    "vx": 0.00000663,
    "vy": 0.00002571,
    "vz": 0.0000113,
    "rarad": 3.60428146562811,
    "decrad": 0.9949673349917,
    "pmrarad": -8.41927437652777e-7,
    "pmdecrad": 8.71859834e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118917,
    "lum": 0.00236374160811629,
    "ra": 13.767341,
    "dec": 57.007429,
    "pmra": -173.66,
    "pmdec": 179.83,
    "rv": 0,
    "Asset Name": "ASTRID 002768",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Star of Culture",
    "Hair": "Sweetheart",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000663,
    "y_1": 0.00002571,
    "z_1": 0.0000113,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118917,
    "Gliese Catalog_1": "NN 3805"
  },
  {
    "Astrid #": "ASTRID 002769",
    "HYG Star ID": 118941,
    "Gliese Catalog": "NN 3823",
    "Distance in Parsecs": 23.8095,
    "mag": 12.15,
    "Absolute Visual Magnitude": 10.266,
    "Spectral Type": "M0.5",
    "Color Index": 1.7,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.626078,
    "y": -11.05645,
    "z": -9.885164,
    "vx": -0.00003777,
    "vy": 0.0000257,
    "vz": 0.0000424,
    "rarad": 3.67730200629962,
    "decrad": -0.42813766769385,
    "pmrarad": -0.00000173815400752777,
    "pmdecrad": 0.000001957726123,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118941,
    "lum": 0.00681710526305853,
    "ra": 14.046259,
    "dec": -24.530481,
    "pmra": -358.52,
    "pmdec": 403.81,
    "rv": 0,
    "Asset Name": "ASTRID 002769",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Heartbreaker",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003777,
    "y_1": 0.0000257,
    "z_1": 0.0000424,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118941,
    "Gliese Catalog_1": "NN 3823"
  },
  {
    "Astrid #": "ASTRID 002770",
    "HYG Star ID": 118950,
    "Gliese Catalog": "NN 3834",
    "Distance in Parsecs": 23.8095,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.616,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.190564,
    "y": -12.089537,
    "z": 9.478332,
    "vx": -0.00002904,
    "vy": 0.00000712,
    "vz": -0.00004664,
    "rarad": 3.72817066898403,
    "decrad": 0.40943415845395,
    "pmrarad": -9.24200319243055e-7,
    "pmdecrad": -0.000002135710921,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118950,
    "lum": 0.00196607463387909,
    "ra": 14.240563,
    "dec": 23.458849,
    "pmra": -190.63,
    "pmdec": -440.52,
    "rv": 0,
    "Asset Name": "ASTRID 002770",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Goddess",
    "Outfit": "Toga",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002904,
    "y_1": 0.00000712,
    "z_1": -0.00004664,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118950,
    "Gliese Catalog_1": "NN 3834"
  },
  {
    "Astrid #": "ASTRID 002771",
    "HYG Star ID": 118965,
    "Gliese Catalog": "NN 3847",
    "Distance in Parsecs": 23.8095,
    "mag": 14.7,
    "Absolute Visual Magnitude": 12.816,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.9829,
    "y": -13.247903,
    "z": -8.246253,
    "vx": -0.00004268,
    "vy": 0.00004675,
    "vz": 0.00001794,
    "rarad": 3.77653240915744,
    "decrad": -0.3536699891866,
    "pmrarad": -0.00000264407685101388,
    "pmdecrad": 8.03336268e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118965,
    "lum": 0.00065102849843486,
    "ra": 14.425291,
    "dec": -20.263798,
    "pmra": -545.38,
    "pmdec": 165.7,
    "rv": 0,
    "Asset Name": "ASTRID 002771",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Lion Mane",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004268,
    "y_1": 0.00004675,
    "z_1": 0.00001794,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 118965,
    "Gliese Catalog_1": "NN 3847"
  },
  {
    "Astrid #": "ASTRID 002772",
    "HYG Star ID": 119068,
    "Gliese Catalog": "NN 3941",
    "Distance in Parsecs": 23.8095,
    "mag": 12.35,
    "Absolute Visual Magnitude": 10.466,
    "Spectral Type": "M1.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.58386,
    "y": -4.729557,
    "z": 23.191534,
    "vx": -0.00002675,
    "vy": 0.00002194,
    "vz": 0.0000015,
    "rarad": 4.2126180446096,
    "decrad": 1.34246474585235,
    "pmrarad": -0.00000142772780786805,
    "pmdecrad": 2.77517047e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119068,
    "lum": 0.00567022118160543,
    "ra": 16.091016,
    "dec": 76.917564,
    "pmra": -294.49,
    "pmdec": 57.24,
    "rv": 0,
    "Asset Name": "ASTRID 002772",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Stunned",
    "Hair": "Little Crown",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002675,
    "y_1": 0.00002194,
    "z_1": 0.0000015,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119068,
    "Gliese Catalog_1": "NN 3941"
  },
  {
    "Astrid #": "ASTRID 002773",
    "HYG Star ID": 119076,
    "Gliese Catalog": "NN 3947",
    "Distance in Parsecs": 23.8095,
    "mag": 11.99,
    "Absolute Visual Magnitude": 10.106,
    "Spectral Type": "M2",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.540041,
    "y": -19.459597,
    "z": 9.859208,
    "vx": -0.00000209,
    "vy": 0.00001381,
    "vz": 0.00002525,
    "rarad": 4.25657448574463,
    "decrad": 0.4269397369998,
    "pmrarad": -3.34085107270833e-7,
    "pmdecrad": 0.000001165079996,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 119076,
    "lum": 0.00789950721335816,
    "ra": 16.258917,
    "dec": 24.461845,
    "pmra": -68.91,
    "pmdec": 240.31,
    "rv": 0,
    "Asset Name": "ASTRID 002773",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Windy",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000209,
    "y_1": 0.00001381,
    "z_1": 0.00002525,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119076,
    "Gliese Catalog_1": "NN 3947"
  },
  {
    "Astrid #": "ASTRID 002774",
    "HYG Star ID": 119117,
    "Gliese Catalog": "NN 3980",
    "Distance in Parsecs": 23.8095,
    "mag": 14.35,
    "Absolute Visual Magnitude": 12.466,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.248308,
    "y": -22.295707,
    "z": 5.545484,
    "vx": 0.00001573,
    "vy": -0.00000848,
    "vz": -0.00001637,
    "rarad": 4.43915012297729,
    "decrad": 0.235069453175,
    "pmrarad": 7.32359545847222e-7,
    "pmdecrad": -7.072365e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119117,
    "lum": 0.000898669495047525,
    "ra": 16.956304,
    "dec": 13.468488,
    "pmra": 151.06,
    "pmdec": -145.88,
    "rv": 0,
    "Asset Name": "ASTRID 002774",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Middle Part",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001573,
    "y_1": -0.00000848,
    "z_1": -0.00001637,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119117,
    "Gliese Catalog_1": "NN 3980"
  },
  {
    "Astrid #": "ASTRID 002775",
    "HYG Star ID": 119238,
    "Gliese Catalog": "NN 4075",
    "Distance in Parsecs": 23.8095,
    "mag": 12.8,
    "Absolute Visual Magnitude": 10.916,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.38914,
    "y": -11.061189,
    "z": -20.948375,
    "vx": -0.0000286,
    "vy": 0.00004755,
    "vz": -0.00002838,
    "rarad": 4.92515570873059,
    "decrad": -1.07550996563555,
    "pmrarad": -7.52285388118055e-7,
    "pmdecrad": -0.000002507572711,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119238,
    "lum": 0.00374627798344059,
    "ra": 18.812709,
    "dec": -61.622182,
    "pmra": -155.17,
    "pmdec": -517.22,
    "rv": 0,
    "Asset Name": "ASTRID 002775",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Frizzy",
    "Outfit": "Astro",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000286,
    "y_1": 0.00004755,
    "z_1": -0.00002838,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119238,
    "Gliese Catalog_1": "NN 4075"
  },
  {
    "Astrid #": "ASTRID 002776",
    "HYG Star ID": 119254,
    "Gliese Catalog": "NN 4091B",
    "Distance in Parsecs": 23.8095,
    "mag": 12.04,
    "Absolute Visual Magnitude": 10.156,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.362652,
    "y": -13.412143,
    "z": 19.382964,
    "vx": 0.00000856,
    "vy": -0.00002884,
    "vz": -0.00002143,
    "rarad": 4.9580403966844,
    "decrad": 0.95115241208045,
    "pmrarad": 5.41536881180555e-8,
    "pmdecrad": -0.000001550458391,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119254,
    "base": "NN 4091",
    "lum": 0.00754397082197989,
    "ra": 18.938319,
    "dec": 54.497019,
    "pmra": 11.17,
    "pmdec": -319.81,
    "rv": 0,
    "Asset Name": "ASTRID 002776",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Sweetheart",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000856,
    "y_1": -0.00002884,
    "z_1": -0.00002143,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119254,
    "Gliese Catalog_1": "NN 4091B"
  },
  {
    "Astrid #": "ASTRID 002777",
    "HYG Star ID": 119257,
    "Gliese Catalog": "NN 4093",
    "Distance in Parsecs": 23.8095,
    "mag": 12.54,
    "Absolute Visual Magnitude": 10.656,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.146992,
    "y": -7.68149,
    "z": 22.433846,
    "vx": -0.0000031,
    "vy": 0.00002155,
    "vz": 0.00000768,
    "rarad": 4.98492406845469,
    "decrad": 1.22920401593945,
    "pmrarad": 1.18149093951388e-7,
    "pmdecrad": 9.62398789e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119257,
    "lum": 0.00475992379667249,
    "ra": 19.041007,
    "dec": 70.428202,
    "pmra": 24.37,
    "pmdec": 198.51,
    "rv": 0,
    "Asset Name": "ASTRID 002777",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Afro",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000031,
    "y_1": 0.00002155,
    "z_1": 0.00000768,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119257,
    "Gliese Catalog_1": "NN 4093"
  },
  {
    "Astrid #": "ASTRID 002778",
    "HYG Star ID": 119306,
    "Gliese Catalog": "NN 4125",
    "Distance in Parsecs": 23.8095,
    "mag": 12.88,
    "Absolute Visual Magnitude": 10.996,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.367322,
    "y": -17.941471,
    "z": 12.539905,
    "vx": 0.0000077,
    "vy": 0.00002601,
    "vz": 0.00003147,
    "rarad": 5.19355639797397,
    "decrad": 0.5546861819029,
    "pmrarad": 7.923794795e-7,
    "pmdecrad": 0.000001555098058,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119306,
    "lum": 0.00348016632215003,
    "ra": 19.837924,
    "dec": 31.781177,
    "pmra": 163.44,
    "pmdec": 320.76,
    "rv": 0,
    "Asset Name": "ASTRID 002778",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Sweet Nap",
    "Hair": "Elegant",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000077,
    "y_1": 0.00002601,
    "z_1": 0.00003147,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119306,
    "Gliese Catalog_1": "NN 4125"
  },
  {
    "Astrid #": "ASTRID 002779",
    "HYG Star ID": 119342,
    "Gliese Catalog": "GJ 1252",
    "Distance in Parsecs": 23.8095,
    "mag": 12.23,
    "Absolute Visual Magnitude": 10.346,
    "Spectral Type": "m",
    "Color Index": 1.45,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.907372,
    "y": -10.518674,
    "z": -19.84283,
    "vx": -0.00003038,
    "vy": 0.00011887,
    "vz": -0.00008254,
    "rarad": 5.35692653544068,
    "decrad": -0.9852113761614,
    "pmrarad": 0.00000197934881360416,
    "pmdecrad": -0.000005881483228,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119342,
    "lum": 0.00633286164452197,
    "ra": 20.461952,
    "dec": -56.448454,
    "pmra": 408.27,
    "pmdec": -1213.14,
    "rv": 6,
    "Asset Name": "ASTRID 002779",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Frizzy",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003038,
    "y_1": 0.00011887,
    "z_1": -0.00008254,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119342,
    "Gliese Catalog_1": "GJ 1252"
  },
  {
    "Astrid #": "ASTRID 002780",
    "HYG Star ID": 119403,
    "Gliese Catalog": "NN 4187A",
    "Distance in Parsecs": 23.8095,
    "mag": 12.11,
    "Absolute Visual Magnitude": 10.226,
    "Spectral Type": "M1",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.861241,
    "y": -15.305993,
    "z": -3.687135,
    "vx": 0.00002928,
    "vy": 0.00004646,
    "vz": -0.00005111,
    "rarad": 5.57467521532378,
    "decrad": -0.15548554835095,
    "pmrarad": 0.00000228206647574305,
    "pmdecrad": -0.000002173187019,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119403,
    "base": "NN 4187",
    "lum": 0.00707294042404574,
    "ra": 21.293691,
    "dec": -8.908666,
    "pmra": 470.71,
    "pmdec": -448.25,
    "rv": 0,
    "Asset Name": "ASTRID 002780",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Bob",
    "Outfit": "Elven",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002928,
    "y_1": 0.00004646,
    "z_1": -0.00005111,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119403,
    "Gliese Catalog_1": "NN 4187A"
  },
  {
    "Astrid #": "ASTRID 002781",
    "HYG Star ID": 119404,
    "Gliese Catalog": "NN 4188B",
    "Distance in Parsecs": 23.8095,
    "mag": 13.33,
    "Absolute Visual Magnitude": 11.446,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 17.864019,
    "y": -15.3016,
    "z": -3.691908,
    "vx": 0.00002926,
    "vy": 0.00004647,
    "vz": -0.00005111,
    "rarad": 5.57489392132378,
    "decrad": -0.15568847135095,
    "pmrarad": 0.00000228206647574305,
    "pmdecrad": -0.000002173187019,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119404,
    "base": "NN 4188",
    "lum": 0.00229932308702122,
    "ra": 21.294526,
    "dec": -8.920292,
    "pmra": 470.71,
    "pmdec": -448.25,
    "rv": 0,
    "Asset Name": "ASTRID 002781",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Little Crown",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002926,
    "y_1": 0.00004647,
    "z_1": -0.00005111,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119404,
    "Gliese Catalog_1": "NN 4188B"
  },
  {
    "Astrid #": "ASTRID 002782",
    "HYG Star ID": 119459,
    "Gliese Catalog": "NN 4242A",
    "Distance in Parsecs": 23.8095,
    "mag": 14.65,
    "Absolute Visual Magnitude": 12.766,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.344753,
    "y": -10.131683,
    "z": -12.782834,
    "vx": -0.0000419,
    "vy": -0.00002717,
    "vz": -0.00003533,
    "rarad": 5.75452999511725,
    "decrad": -0.566734007383299,
    "pmrarad": -0.00000187307765482638,
    "pmdecrad": -0.000001758947666,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119459,
    "base": "NN 4242",
    "lum": 0.000681710526305853,
    "ra": 21.980685,
    "dec": -32.471467,
    "pmra": -386.35,
    "pmdec": -362.81,
    "rv": 0,
    "Asset Name": "ASTRID 002782",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Ponytail",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000419,
    "y_1": -0.00002717,
    "z_1": -0.00003533,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119459,
    "Gliese Catalog_1": "NN 4242A"
  },
  {
    "Astrid #": "ASTRID 002783",
    "HYG Star ID": 119460,
    "Gliese Catalog": "NN 4243B",
    "Distance in Parsecs": 23.8095,
    "mag": 15.8,
    "Absolute Visual Magnitude": 13.916,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.349775,
    "y": -10.136347,
    "z": -12.772317,
    "vx": -0.00004189,
    "vy": -0.00002718,
    "vz": -0.00003534,
    "rarad": 5.75445561611725,
    "decrad": -0.5662105903833,
    "pmrarad": -0.00000187307765482638,
    "pmdecrad": -0.000001758947666,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119460,
    "base": "NN 4243",
    "lum": 0.000236374160811629,
    "ra": 21.980401,
    "dec": -32.441477,
    "pmra": -386.35,
    "pmdec": -362.81,
    "rv": 0,
    "Asset Name": "ASTRID 002783",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Slick",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004189,
    "y_1": -0.00002718,
    "z_1": -0.00003534,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119460,
    "Gliese Catalog_1": "NN 4243B"
  },
  {
    "Astrid #": "ASTRID 002784",
    "HYG Star ID": 119563,
    "Gliese Catalog": "NN 4335",
    "Distance in Parsecs": 23.8095,
    "mag": 15.75,
    "Absolute Visual Magnitude": 13.866,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.11267,
    "y": -2.359343,
    "z": -17.369733,
    "vx": -0.00002537,
    "vy": 0.00004555,
    "vz": -0.00002973,
    "rarad": 6.13775064309292,
    "decrad": -0.8176338359014,
    "pmrarad": 0.00000173854185847222,
    "pmdecrad": -0.000001825658028,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119563,
    "lum": 0.000247514131807431,
    "ra": 23.444481,
    "dec": -46.846968,
    "pmra": 358.6,
    "pmdec": -376.57,
    "rv": 0,
    "Asset Name": "ASTRID 002784",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Tiny Crown",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002537,
    "y_1": 0.00004555,
    "z_1": -0.00002973,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119563,
    "Gliese Catalog_1": "NN 4335"
  },
  {
    "Astrid #": "ASTRID 002785",
    "HYG Star ID": 119579,
    "Gliese Catalog": "NN 4354",
    "Distance in Parsecs": 23.8095,
    "mag": 13.58,
    "Absolute Visual Magnitude": 11.696,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.382707,
    "y": -1.71467,
    "z": 15.034236,
    "vx": 0.00003515,
    "vy": -0.00003057,
    "vz": -0.00004645,
    "rarad": 6.19019468746132,
    "decrad": 0.6834069952757,
    "pmrarad": -0.00000114159077360416,
    "pmdecrad": -0.000002516614486,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119579,
    "lum": 0.00182641724877435,
    "ra": 23.644802,
    "dec": 39.156337,
    "pmra": -235.47,
    "pmdec": -519.09,
    "rv": 0,
    "Asset Name": "ASTRID 002785",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Infatuated",
    "Hair": "Charmer",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003515,
    "y_1": -0.00003057,
    "z_1": -0.00004645,
    "Distance in Parsecs_1": 23.8095,
    "HYG Star ID_1": 119579,
    "Gliese Catalog_1": "NN 4354"
  },
  {
    "Astrid #": "ASTRID 002786",
    "HYG Star ID": 46600,
    "Hipparcos Catalogue": 46733,
    "Henry Draper Catalogue": 81937,
    "Harvard Revised Catalogue": 3757,
    "Gliese Catalog": "NN 3559",
    "Distance in Parsecs": 23.8152,
    "mag": 3.65,
    "Absolute Visual Magnitude": 1.766,
    "Spectral Type": "F0IV",
    "Color Index": 0.36,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.603035,
    "y": 6.510645,
    "z": 21.231076,
    "vx": -0.00000145,
    "vy": -0.00001448,
    "vz": -0.00000808,
    "rarad": 2.49375787539974,
    "decrad": 1.10063710043334,
    "pmrarad": 5.214171134375e-7,
    "pmdecrad": 1.30220954e-7,
    "flam": 23,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 46600,
    "lum": 17.1237942124492,
    "bf": "23    UMa",
    "ra": 9.525453,
    "dec": 63.061861,
    "pmra": 107.55,
    "pmdec": 26.86,
    "rv": -10.4,
    "Asset Name": "ASTRID 002786",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Honest Lad",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000145,
    "y_1": -0.00001448,
    "z_1": -0.00000808,
    "Distance in Parsecs_1": 23.8152,
    "HYG Star ID_1": 46600,
    "Hipparcos Catalogue_1": 46733,
    "Henry Draper Catalogue_1": 81937,
    "Harvard Revised Catalogue_1": 3757,
    "Gliese Catalog_1": "NN 3559"
  },
  {
    "Astrid #": "ASTRID 002787",
    "HYG Star ID": 100824,
    "Hipparcos Catalogue": 101150,
    "Henry Draper Catalogue": 340345,
    "Distance in Parsecs": 23.8152,
    "mag": 9.69,
    "Absolute Visual Magnitude": 7.806,
    "Spectral Type": "M2",
    "Color Index": 1.34,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.948773,
    "y": -16.848106,
    "z": 10.75368,
    "vx": -0.00000131,
    "vy": -0.00002926,
    "vz": -0.00000553,
    "rarad": 5.36766359825534,
    "decrad": 0.468495059518031,
    "pmrarad": -7.92427960868055e-7,
    "pmdecrad": -6.31712225e-7,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 100824,
    "lum": 0.0657052390963553,
    "var_min": 9.748,
    "var_max": 9.638,
    "ra": 20.502965,
    "dec": 26.84279,
    "pmra": -163.45,
    "pmdec": -130.3,
    "rv": 17.1,
    "Asset Name": "ASTRID 002787",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Unkempt",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": -0.00000131,
    "y_1": -0.00002926,
    "z_1": -0.00000553,
    "Distance in Parsecs_1": 23.8152,
    "HYG Star ID_1": 100824,
    "Hipparcos Catalogue_1": 101150,
    "Henry Draper Catalogue_1": 340345
  },
  {
    "Astrid #": "ASTRID 002788",
    "HYG Star ID": 92779,
    "Hipparcos Catalogue": 93072,
    "Distance in Parsecs": 23.8209,
    "mag": 10.67,
    "Absolute Visual Magnitude": 8.785,
    "Color Index": 1.437,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 5.594393,
    "y": -21.810777,
    "z": -7.773546,
    "vx": 0.000012,
    "vy": -0.0000014,
    "vz": 0.00001257,
    "rarad": 4.96347254158146,
    "decrad": -0.332421615774952,
    "pmrarad": 4.73323596326388e-7,
    "pmdecrad": 5.58311434e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 92779,
    "lum": 0.0266685866452147,
    "var_min": 10.724,
    "var_max": 10.614,
    "ra": 18.959069,
    "dec": -19.046356,
    "pmra": 97.63,
    "pmdec": 115.16,
    "rv": 0,
    "Asset Name": "ASTRID 002788",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Bangs",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.000012,
    "y_1": -0.0000014,
    "z_1": 0.00001257,
    "Distance in Parsecs_1": 23.8209,
    "HYG Star ID_1": 92779,
    "Hipparcos Catalogue_1": 93072
  },
  {
    "Astrid #": "ASTRID 002789",
    "HYG Star ID": 52218,
    "Hipparcos Catalogue": 52369,
    "Henry Draper Catalogue": 92719,
    "Distance in Parsecs": 23.8265,
    "mag": 6.79,
    "Absolute Visual Magnitude": 4.905,
    "Spectral Type": "G2/G3V",
    "Color Index": 0.622,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.820149,
    "y": 7.703139,
    "z": -5.678455,
    "vx": -0.00000459,
    "vy": -0.00002722,
    "vz": -0.00001929,
    "rarad": 2.80222191476769,
    "decrad": -0.240640991445887,
    "pmrarad": 0.00000114168773634027,
    "pmdecrad": -8.3387953e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 52218,
    "lum": 0.95060479365628,
    "ra": 10.703699,
    "dec": -13.787713,
    "pmra": 235.49,
    "pmdec": -172,
    "rv": 0,
    "Asset Name": "ASTRID 002789",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Bangs",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000459,
    "y_1": -0.00002722,
    "z_1": -0.00001929,
    "Distance in Parsecs_1": 23.8265,
    "HYG Star ID_1": 52218,
    "Hipparcos Catalogue_1": 52369,
    "Henry Draper Catalogue_1": 92719
  },
  {
    "Astrid #": "ASTRID 002790",
    "HYG Star ID": 101789,
    "Hipparcos Catalogue": 102119,
    "Henry Draper Catalogue": 196998,
    "Gliese Catalog": "GJ 1257",
    "Distance in Parsecs": 23.8265,
    "mag": 9.83,
    "Absolute Visual Magnitude": 7.945,
    "Spectral Type": "K5V",
    "Color Index": 1.121,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.292499,
    "y": -16.778178,
    "z": -9.049509,
    "vx": 0.00000573,
    "vy": 0.00011069,
    "vz": -0.00003381,
    "rarad": 5.41795409020467,
    "decrad": -0.389598646065773,
    "pmrarad": 0.00000319579481948611,
    "pmdecrad": -0.000002596807517,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 101789,
    "lum": 0.0578096047405717,
    "var": "DG",
    "var_min": 9.881,
    "var_max": 9.761,
    "ra": 20.69506,
    "dec": -22.322358,
    "pmra": 659.18,
    "pmdec": -535.63,
    "rv": -60.3,
    "Asset Name": "ASTRID 002790",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Braided Bun",
    "Outfit": "Warlock",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000573,
    "y_1": 0.00011069,
    "z_1": -0.00003381,
    "Distance in Parsecs_1": 23.8265,
    "HYG Star ID_1": 101789,
    "Hipparcos Catalogue_1": 102119,
    "Henry Draper Catalogue_1": 196998,
    "Gliese Catalog_1": "GJ 1257"
  },
  {
    "Astrid #": "ASTRID 002791",
    "HYG Star ID": 62310,
    "Hipparcos Catalogue": 62505,
    "Henry Draper Catalogue": 111312,
    "Gliese Catalog": "GJ 1165",
    "Distance in Parsecs": 23.8322,
    "mag": 7.93,
    "Absolute Visual Magnitude": 6.044,
    "Spectral Type": "K2V",
    "Color Index": 0.946,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.428288,
    "y": -4.822383,
    "z": -6.456802,
    "vx": 5.7e-7,
    "vy": -0.0000093,
    "vz": 0.00000497,
    "rarad": 3.3533815199694,
    "decrad": -0.274356559469346,
    "pmrarad": 3.86396503402777e-7,
    "pmdecrad": 2.16517789e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 62310,
    "lum": 0.33296608520688,
    "var": "VZ",
    "var_min": 7.951,
    "var_max": 7.911,
    "ra": 12.808974,
    "dec": -15.719473,
    "pmra": 79.7,
    "pmdec": 44.66,
    "rv": 0,
    "Asset Name": "ASTRID 002791",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Heartbreaker",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 5.7e-7,
    "y_1": -0.0000093,
    "z_1": 0.00000497,
    "Distance in Parsecs_1": 23.8322,
    "HYG Star ID_1": 62310,
    "Hipparcos Catalogue_1": 62505,
    "Henry Draper Catalogue_1": 111312,
    "Gliese Catalog_1": "GJ 1165"
  },
  {
    "Astrid #": "ASTRID 002792",
    "HYG Star ID": 104099,
    "Hipparcos Catalogue": 104436,
    "Henry Draper Catalogue": 199509,
    "Distance in Parsecs": 23.8379,
    "mag": 6.98,
    "Absolute Visual Magnitude": 5.094,
    "Spectral Type": "G3V",
    "Color Index": 0.619,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.431319,
    "y": -2.240697,
    "z": -23.607487,
    "vx": 0.00001667,
    "vy": 0.00002625,
    "vz": -7.7e-7,
    "rarad": 5.53849546755719,
    "decrad": -1.43164575064066,
    "pmrarad": 0.00000128383510747916,
    "pmdecrad": -2.34358933e-7,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 104099,
    "lum": 0.798730006111313,
    "ra": 21.155494,
    "dec": -82.027259,
    "pmra": 264.81,
    "pmdec": -48.34,
    "rv": 0,
    "Asset Name": "ASTRID 002792",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Braided Ponytail",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": 0.00001667,
    "y_1": 0.00002625,
    "z_1": -7.7e-7,
    "Distance in Parsecs_1": 23.8379,
    "HYG Star ID_1": 104099,
    "Hipparcos Catalogue_1": 104436,
    "Henry Draper Catalogue_1": 199509
  },
  {
    "Astrid #": "ASTRID 002793",
    "HYG Star ID": 92892,
    "Hipparcos Catalogue": 93185,
    "Henry Draper Catalogue": 176377,
    "Gliese Catalog": "Wo 9639",
    "Distance in Parsecs": 23.8436,
    "mag": 6.8,
    "Absolute Visual Magnitude": 4.913,
    "Spectral Type": "G0",
    "Color Index": 0.606,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.234601,
    "y": -19.935375,
    "z": 11.986666,
    "vx": -0.00000545,
    "vy": 0.00004637,
    "vz": -8.6e-7,
    "rarad": 4.96917040419233,
    "decrad": 0.526751627710212,
    "pmrarad": 2.727076953125e-7,
    "pmdecrad": 9.4373831e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 92892,
    "lum": 0.943626220276726,
    "ra": 18.980833,
    "dec": 30.180645,
    "pmra": 56.25,
    "pmdec": 194.66,
    "rv": -39.5,
    "Asset Name": "ASTRID 002793",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Spiky",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000545,
    "y_1": 0.00004637,
    "z_1": -8.6e-7,
    "Distance in Parsecs_1": 23.8436,
    "HYG Star ID_1": 92892,
    "Hipparcos Catalogue_1": 93185,
    "Henry Draper Catalogue_1": 176377,
    "Gliese Catalog_1": "Wo 9639"
  },
  {
    "Astrid #": "ASTRID 002794",
    "HYG Star ID": 73014,
    "Hipparcos Catalogue": 73241,
    "Henry Draper Catalogue": 131923,
    "Harvard Revised Catalogue": 5566,
    "Gliese Catalog": "Gl 570.1",
    "Distance in Parsecs": 23.8493,
    "mag": 6.34,
    "Absolute Visual Magnitude": 4.453,
    "Spectral Type": "G5V",
    "Color Index": 0.708,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.183686,
    "y": -11.004257,
    "z": -17.962086,
    "vx": 0.00000178,
    "vy": 0.00000426,
    "vz": -0.00005437,
    "rarad": 3.91890448474408,
    "decrad": -0.852820428912888,
    "pmrarad": -7.50006763819444e-8,
    "pmdecrad": -0.000001634161473,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 73014,
    "lum": 1.44145138575821,
    "ra": 14.969112,
    "dec": -48.863011,
    "pmra": -15.47,
    "pmdec": -337.07,
    "rv": 37.3,
    "Asset Name": "ASTRID 002794",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Arrogant",
    "Hair": "Little Crown",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": 0.00000178,
    "y_1": 0.00000426,
    "z_1": -0.00005437,
    "Distance in Parsecs_1": 23.8493,
    "HYG Star ID_1": 73014,
    "Hipparcos Catalogue_1": 73241,
    "Henry Draper Catalogue_1": 131923,
    "Harvard Revised Catalogue_1": 5566,
    "Gliese Catalog_1": "Gl 570.1"
  },
  {
    "Astrid #": "ASTRID 002795",
    "HYG Star ID": 25951,
    "Hipparcos Catalogue": 26016,
    "Distance in Parsecs": 23.855,
    "mag": 8.6,
    "Absolute Visual Magnitude": 6.712,
    "Spectral Type": "B8",
    "Color Index": -0.1,
    "Spectral Abbreviation": "B",
    "Star Type": "Teal",
    "Color In Star Map": "Teal",
    "x": 2.79296,
    "y": 23.6801,
    "z": -0.716424,
    "vx": -1.3e-7,
    "vy": 2e-8,
    "vz": 1.4e-7,
    "rarad": 1.45339327666331,
    "decrad": -0.03003695382193,
    "pmrarad": 5.33295048611111e-9,
    "pmdecrad": 5.963208e-9,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 25951,
    "lum": 0.179969951657735,
    "var_min": 8.65,
    "var_max": 8.24,
    "ra": 5.551553,
    "dec": -1.720991,
    "pmra": 1.1,
    "pmdec": 1.23,
    "rv": 0,
    "Asset Name": "ASTRID 002795",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Angelic",
    "Outfit": "Emperor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -1.3e-7,
    "y_1": 2e-8,
    "z_1": 1.4e-7,
    "Distance in Parsecs_1": 23.855,
    "HYG Star ID_1": 25951,
    "Hipparcos Catalogue_1": 26016
  },
  {
    "Astrid #": "ASTRID 002796",
    "HYG Star ID": 118036,
    "Gliese Catalog": "NN 3070",
    "Distance in Parsecs": 23.8663,
    "mag": 11.84,
    "Absolute Visual Magnitude": 9.951,
    "Color Index": 1.23,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 20.714835,
    "y": 5.735927,
    "z": -10.372801,
    "vx": 0.00000249,
    "vy": 0.0000103,
    "vz": 0.00001067,
    "rarad": 0.270129134819427,
    "decrad": -0.4496176276009,
    "pmrarad": 3.87996388548611e-7,
    "pmdecrad": 4.96647982e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 118036,
    "lum": 0.00911171233042918,
    "ra": 1.031817,
    "dec": -25.761192,
    "pmra": 80.03,
    "pmdec": 102.44,
    "rv": 0,
    "Asset Name": "ASTRID 002796",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Battle Ready",
    "Hair": "Side Part",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00000249,
    "y_1": 0.0000103,
    "z_1": 0.00001067,
    "Distance in Parsecs_1": 23.8663,
    "HYG Star ID_1": 118036,
    "Gliese Catalog_1": "NN 3070"
  },
  {
    "Astrid #": "ASTRID 002797",
    "HYG Star ID": 119408,
    "Gliese Catalog": "Wo 9733B",
    "Distance in Parsecs": 23.8663,
    "mag": 6.9,
    "Absolute Visual Magnitude": 5.011,
    "Spectral Type": "A7 V",
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 10.881885,
    "y": -9.142213,
    "z": -19.172564,
    "vx": -0.00000136,
    "vy": 0.00001563,
    "vz": 9.4e-7,
    "rarad": 5.58443266527157,
    "decrad": -0.93289020989475,
    "pmrarad": 4.64645431444444e-7,
    "pmdecrad": -3.50117895e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 2,
    "comp_primary": 104979,
    "base": "Wo 9733",
    "lum": 0.862184081072701,
    "ra": 21.330962,
    "dec": -53.450672,
    "pmra": 95.84,
    "pmdec": -72.22,
    "rv": -7.2,
    "Asset Name": "ASTRID 002797",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Middle Part",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00000136,
    "y_1": 0.00001563,
    "z_1": 9.4e-7,
    "Distance in Parsecs_1": 23.8663,
    "HYG Star ID_1": 119408,
    "Gliese Catalog_1": "Wo 9733B"
  },
  {
    "Astrid #": "ASTRID 002798",
    "HYG Star ID": 6330,
    "Hipparcos Catalogue": 6344,
    "Gliese Catalog": "Gl 56.4",
    "Distance in Parsecs": 23.872,
    "mag": 9.67,
    "Absolute Visual Magnitude": 7.781,
    "Spectral Type": "K8",
    "Color Index": 1.292,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.827456,
    "y": 1.421808,
    "z": 23.520108,
    "vx": -0.00000505,
    "vy": 0.00003303,
    "vz": -0.00001571,
    "rarad": 0.355619473745041,
    "decrad": 1.39891276424622,
    "pmrarad": 0.00000137071371903472,
    "pmdecrad": -3.9197186e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 6330,
    "lum": 0.0672357107234318,
    "ra": 1.358366,
    "dec": 80.151797,
    "pmra": 282.73,
    "pmdec": -80.85,
    "rv": -14,
    "Asset Name": "ASTRID 002798",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Chubby Cheeks",
    "Hair": "Good Boy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000505,
    "y_1": 0.00003303,
    "z_1": -0.00001571,
    "Distance in Parsecs_1": 23.872,
    "HYG Star ID_1": 6330,
    "Hipparcos Catalogue_1": 6344,
    "Gliese Catalog_1": "Gl 56.4"
  },
  {
    "Astrid #": "ASTRID 002799",
    "HYG Star ID": 34134,
    "Hipparcos Catalogue": 34222,
    "Gliese Catalog": "Gl 265A",
    "Distance in Parsecs": 23.872,
    "mag": 10.14,
    "Absolute Visual Magnitude": 8.251,
    "Spectral Type": "M0V",
    "Color Index": 1.34,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.989253,
    "y": 20.315679,
    "z": 11.011977,
    "vx": 0.00001057,
    "vy": -0.00001547,
    "vz": -0.0000218,
    "rarad": 1.85748459742438,
    "decrad": 0.479456444868242,
    "pmrarad": -2.41437212916666e-7,
    "pmdecrad": -4.65615058e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 34134,
    "base": "Gl 265",
    "lum": 0.0436113971393064,
    "ra": 7.095069,
    "dec": 27.470831,
    "pmra": -49.8,
    "pmdec": -96.04,
    "rv": -25.3,
    "Asset Name": "ASTRID 002799",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Pompadour",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00001057,
    "y_1": -0.00001547,
    "z_1": -0.0000218,
    "Distance in Parsecs_1": 23.872,
    "HYG Star ID_1": 34134,
    "Hipparcos Catalogue_1": 34222,
    "Gliese Catalog_1": "Gl 265A"
  },
  {
    "Astrid #": "ASTRID 002800",
    "HYG Star ID": 112555,
    "Hipparcos Catalogue": 112915,
    "Henry Draper Catalogue": 216448,
    "Distance in Parsecs": 23.8777,
    "mag": 8.01,
    "Absolute Visual Magnitude": 6.12,
    "Spectral Type": "K5III",
    "Color Index": 1.043,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.196029,
    "y": -3.728165,
    "z": 20.186647,
    "vx": 0.00001556,
    "vy": -0.00001602,
    "vz": -0.0000166,
    "rarad": 5.9865229332834,
    "decrad": 1.00735012454725,
    "pmrarad": -4.51167611125e-7,
    "pmdecrad": -0.000001064166028,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 112555,
    "lum": 0.310455958812835,
    "ra": 22.866833,
    "dec": 57.716911,
    "pmra": -93.06,
    "pmdec": -219.5,
    "rv": -3.5,
    "Asset Name": "ASTRID 002800",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Sweetheart",
    "Outfit": "River Spirit",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001556,
    "y_1": -0.00001602,
    "z_1": -0.0000166,
    "Distance in Parsecs_1": 23.8777,
    "HYG Star ID_1": 112555,
    "Hipparcos Catalogue_1": 112915,
    "Henry Draper Catalogue_1": 216448
  },
  {
    "Astrid #": "ASTRID 002801",
    "HYG Star ID": 88348,
    "Hipparcos Catalogue": 88622,
    "Henry Draper Catalogue": 165401,
    "Gliese Catalog": "Gl 702.2",
    "Distance in Parsecs": 23.912,
    "mag": 6.8,
    "Absolute Visual Magnitude": 4.907,
    "Spectral Type": "G0V",
    "Color Index": 0.61,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.58479,
    "y": -23.824802,
    "z": 1.941412,
    "vx": -0.00000649,
    "vy": 0.00011957,
    "vz": -0.00004721,
    "rarad": 4.73692949736609,
    "decrad": 0.0812829968292837,
    "pmrarad": -1.48643874458333e-7,
    "pmdecrad": -0.000001561390939,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 88348,
    "lum": 0.9488553267278,
    "ra": 18.093738,
    "dec": 4.657173,
    "pmra": -30.66,
    "pmdec": -322.06,
    "rv": -120.4,
    "Asset Name": "ASTRID 002801",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Braid",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000649,
    "y_1": 0.00011957,
    "z_1": -0.00004721,
    "Distance in Parsecs_1": 23.912,
    "HYG Star ID_1": 88348,
    "Hipparcos Catalogue_1": 88622,
    "Henry Draper Catalogue_1": 165401,
    "Gliese Catalog_1": "Gl 702.2"
  },
  {
    "Astrid #": "ASTRID 002802",
    "HYG Star ID": 85548,
    "Hipparcos Catalogue": 85810,
    "Henry Draper Catalogue": 159222,
    "Harvard Revised Catalogue": 6538,
    "Gliese Catalog": "Gl 679",
    "Distance in Parsecs": 23.9177,
    "mag": 6.52,
    "Absolute Visual Magnitude": 4.626,
    "Spectral Type": "G5V",
    "Color Index": 0.639,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.407304,
    "y": -19.617622,
    "z": 13.468034,
    "vx": -0.00002173,
    "vy": 0.00005107,
    "vz": -0.00002394,
    "rarad": 4.59029024476012,
    "decrad": 0.598144346081683,
    "pmrarad": -0.00000116185598545138,
    "pmdecrad": 3.04123621e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 85548,
    "lum": 1.22913617306016,
    "ra": 17.533617,
    "dec": 34.271147,
    "pmra": -239.65,
    "pmdec": 62.73,
    "rv": -52,
    "Asset Name": "ASTRID 002802",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Medium Bob",
    "Outfit": "Emperor",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00002173,
    "y_1": 0.00005107,
    "z_1": -0.00002394,
    "Distance in Parsecs_1": 23.9177,
    "HYG Star ID_1": 85548,
    "Hipparcos Catalogue_1": 85810,
    "Henry Draper Catalogue_1": 159222,
    "Harvard Revised Catalogue_1": 6538,
    "Gliese Catalog_1": "Gl 679"
  },
  {
    "Astrid #": "ASTRID 002803",
    "HYG Star ID": 117192,
    "Hipparcos Catalogue": 117559,
    "Gliese Catalog": "Gl 908.1",
    "Distance in Parsecs": 23.9234,
    "mag": 9.35,
    "Absolute Visual Magnitude": 7.456,
    "Spectral Type": "M0",
    "Color Index": 1.226,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.626239,
    "y": -0.858695,
    "z": 12.089196,
    "vx": -0.00000204,
    "vy": 0.00002953,
    "vz": -7e-7,
    "rarad": 6.24157643956084,
    "decrad": 0.529764229790771,
    "pmrarad": 0.00000122977838209722,
    "pmdecrad": 4.3633231e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 117192,
    "lum": 0.0906984781505791,
    "ra": 23.841066,
    "dec": 30.353255,
    "pmra": 253.66,
    "pmdec": 9,
    "rv": -3.1,
    "Asset Name": "ASTRID 002803",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Lion Mane",
    "Outfit": "River Spirit",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000204,
    "y_1": 0.00002953,
    "z_1": -7e-7,
    "Distance in Parsecs_1": 23.9234,
    "HYG Star ID_1": 117192,
    "Hipparcos Catalogue_1": 117559,
    "Gliese Catalog_1": "Gl 908.1"
  },
  {
    "Astrid #": "ASTRID 002804",
    "HYG Star ID": 84867,
    "Hipparcos Catalogue": 85126,
    "Gliese Catalog": "Gl 672.1",
    "Distance in Parsecs": 23.9349,
    "mag": 11.64,
    "Absolute Visual Magnitude": 9.745,
    "Spectral Type": "M",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.182115,
    "y": -19.989712,
    "z": -12.773606,
    "vx": -0.00001339,
    "vy": 0.00004074,
    "vz": -0.00006043,
    "rarad": 4.55452722745451,
    "decrad": -0.562947525548926,
    "pmrarad": -8.20062340659722e-7,
    "pmdecrad": -0.000002985434163,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 84867,
    "lum": 0.0110153930954141,
    "var_min": 11.72,
    "var_max": 11.51,
    "ra": 17.397013,
    "dec": -32.254517,
    "pmra": -169.15,
    "pmdec": -615.79,
    "rv": 0,
    "Asset Name": "ASTRID 002804",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Lion Mane",
    "Outfit": "Spartan",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00001339,
    "y_1": 0.00004074,
    "z_1": -0.00006043,
    "Distance in Parsecs_1": 23.9349,
    "HYG Star ID_1": 84867,
    "Hipparcos Catalogue_1": 85126,
    "Gliese Catalog_1": "Gl 672.1"
  },
  {
    "Astrid #": "ASTRID 002805",
    "HYG Star ID": 79987,
    "Hipparcos Catalogue": 80229,
    "Gliese Catalog": "Gl 618.4",
    "Distance in Parsecs": 23.9406,
    "mag": 11.82,
    "Absolute Visual Magnitude": 9.924,
    "Spectral Type": "M3",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.515392,
    "y": -14.410309,
    "z": -17.973452,
    "vx": -0.00004492,
    "vy": 0.00006653,
    "vz": -0.00003706,
    "rarad": 4.28777458827811,
    "decrad": -0.849199668861376,
    "pmrarad": -0.00000285482535795138,
    "pmdecrad": -0.000002343298443,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 79987,
    "lum": 0.00934114255736479,
    "var_min": 11.923,
    "var_max": 11.693,
    "ra": 16.378092,
    "dec": -48.655557,
    "pmra": -588.85,
    "pmdec": -483.34,
    "rv": 0,
    "Asset Name": "ASTRID 002805",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Battle Ready",
    "Hair": "Spiky",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00004492,
    "y_1": 0.00006653,
    "z_1": -0.00003706,
    "Distance in Parsecs_1": 23.9406,
    "HYG Star ID_1": 79987,
    "Hipparcos Catalogue_1": 80229,
    "Gliese Catalog_1": "Gl 618.4"
  },
  {
    "Astrid #": "ASTRID 002806",
    "HYG Star ID": 98715,
    "Hipparcos Catalogue": 99031,
    "Henry Draper Catalogue": 191026,
    "Harvard Revised Catalogue": 7689,
    "Distance in Parsecs": 23.9464,
    "mag": 5.38,
    "Absolute Visual Magnitude": 3.484,
    "Spectral Type": "K0IV",
    "Color Index": 0.85,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.15194,
    "y": -16.507748,
    "z": 14.065856,
    "vx": -0.00002076,
    "vy": -0.00001597,
    "vz": -0.00006122,
    "rarad": 5.26375291341074,
    "decrad": 0.62783800749322,
    "pmrarad": -0.00000108806734327083,
    "pmdecrad": -0.000002135943632,
    "flam": 27,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 98715,
    "lum": 3.51884388820958,
    "var": "V2008",
    "var_min": 5.401,
    "var_max": 5.361,
    "bf": "27    Cyg",
    "ra": 20.106055,
    "dec": 35.972468,
    "pmra": -224.43,
    "pmdec": -440.57,
    "rv": -33,
    "Asset Name": "ASTRID 002806",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Windy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00002076,
    "y_1": -0.00001597,
    "z_1": -0.00006122,
    "Distance in Parsecs_1": 23.9464,
    "HYG Star ID_1": 98715,
    "Hipparcos Catalogue_1": 99031,
    "Henry Draper Catalogue_1": 191026,
    "Harvard Revised Catalogue_1": 7689
  },
  {
    "Astrid #": "ASTRID 002807",
    "HYG Star ID": 7562,
    "Hipparcos Catalogue": 7576,
    "Henry Draper Catalogue": 10008,
    "Distance in Parsecs": 23.9521,
    "mag": 7.66,
    "Absolute Visual Magnitude": 5.763,
    "Spectral Type": "G5",
    "Color Index": 0.797,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 21.661505,
    "y": 9.82508,
    "z": -2.819595,
    "vx": -0.00000942,
    "vy": 0.00001753,
    "vz": -0.00001127,
    "rarad": 0.425821459600483,
    "decrad": -0.117991650823587,
    "pmrarad": 8.28982912381944e-7,
    "pmdecrad": -4.7380841e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 7562,
    "lum": 0.431320400699416,
    "var": "EX",
    "var_min": 7.689,
    "var_max": 7.639,
    "ra": 1.626518,
    "dec": -6.760424,
    "pmra": 170.99,
    "pmdec": -97.73,
    "rv": 0,
    "Asset Name": "ASTRID 002807",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Spiky",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000942,
    "y_1": 0.00001753,
    "z_1": -0.00001127,
    "Distance in Parsecs_1": 23.9521,
    "HYG Star ID_1": 7562,
    "Hipparcos Catalogue_1": 7576,
    "Henry Draper Catalogue_1": 10008
  },
  {
    "Astrid #": "ASTRID 002808",
    "HYG Star ID": 51126,
    "Hipparcos Catalogue": 51271,
    "Henry Draper Catalogue": 90884,
    "Distance in Parsecs": 23.9636,
    "mag": 8.9,
    "Absolute Visual Magnitude": 7.002,
    "Spectral Type": "K4V",
    "Color Index": 1.045,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.679791,
    "y": 5.779097,
    "z": -18.807431,
    "vx": 0.00000138,
    "vy": -0.00000452,
    "vz": -0.00000239,
    "rarad": 2.74187872896555,
    "decrad": -0.902427068959525,
    "pmrarad": 1.51310349701388e-7,
    "pmdecrad": -1.6090966e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 51126,
    "lum": 0.137784384335145,
    "ra": 10.473205,
    "dec": -51.705262,
    "pmra": 31.21,
    "pmdec": -33.19,
    "rv": 0,
    "Asset Name": "ASTRID 002808",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Honest Lad",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000138,
    "y_1": -0.00000452,
    "z_1": -0.00000239,
    "Distance in Parsecs_1": 23.9636,
    "HYG Star ID_1": 51126,
    "Hipparcos Catalogue_1": 51271,
    "Henry Draper Catalogue_1": 90884
  },
  {
    "Astrid #": "ASTRID 002809",
    "HYG Star ID": 35774,
    "Hipparcos Catalogue": 35872,
    "Henry Draper Catalogue": 57901,
    "Gliese Catalog": "GJ 2057",
    "Distance in Parsecs": 23.9693,
    "mag": 8.19,
    "Absolute Visual Magnitude": 6.292,
    "Spectral Type": "G5",
    "Color Index": 0.959,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.350467,
    "y": 21.815017,
    "z": 5.377614,
    "vx": -0.00002697,
    "vy": 0.00004296,
    "vz": -0.00003929,
    "rarad": 1.93637481340905,
    "decrad": 0.226277007386966,
    "pmrarad": 4.10103892381944e-7,
    "pmdecrad": -0.000002063270061,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 35774,
    "lum": 0.264972009887896,
    "ra": 7.396407,
    "dec": 12.964718,
    "pmra": 84.59,
    "pmdec": -425.58,
    "rv": 38.8,
    "Asset Name": "ASTRID 002809",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Wavy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00002697,
    "y_1": 0.00004296,
    "z_1": -0.00003929,
    "Distance in Parsecs_1": 23.9693,
    "HYG Star ID_1": 35774,
    "Hipparcos Catalogue_1": 35872,
    "Henry Draper Catalogue_1": 57901,
    "Gliese Catalog_1": "GJ 2057"
  },
  {
    "Astrid #": "ASTRID 002810",
    "HYG Star ID": 90217,
    "Hipparcos Catalogue": 90496,
    "Henry Draper Catalogue": 169916,
    "Harvard Revised Catalogue": 6913,
    "Gliese Catalog": "Gl 713.1",
    "Proper Name": "Kaus Borealis",
    "Distance in Parsecs": 23.9693,
    "mag": 2.82,
    "Absolute Visual Magnitude": 0.922,
    "Spectral Type": "K1IIIb",
    "Color Index": 1.025,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.635486,
    "y": -21.487046,
    "z": -10.289309,
    "vx": -0.00001116,
    "vy": 0.0000482,
    "vz": -5.8e-7,
    "rarad": 4.83443427510616,
    "decrad": -0.443692367644793,
    "pmrarad": -2.17245010256944e-7,
    "pmdecrad": -9.03159405e-7,
    "bayer": "Lam",
    "flam": 22,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 90217,
    "lum": 37.2563238466603,
    "bf": "22Lam Sgr",
    "ra": 18.466179,
    "dec": -25.4217,
    "pmra": -44.81,
    "pmdec": -186.29,
    "rv": -43.2,
    "Asset Name": "ASTRID 002810",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Blep",
    "Hair": "Astral Band",
    "Outfit": "Star Knight",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Kaus Borealis",
    "Constellation": "Sagittarius",
    "x_1": -0.00001116,
    "y_1": 0.0000482,
    "z_1": -5.8e-7,
    "Distance in Parsecs_1": 23.9693,
    "HYG Star ID_1": 90217,
    "Hipparcos Catalogue_1": 90496,
    "Henry Draper Catalogue_1": 169916,
    "Harvard Revised Catalogue_1": 6913,
    "Gliese Catalog_1": "Gl 713.1"
  },
  {
    "Astrid #": "ASTRID 002811",
    "HYG Star ID": 42214,
    "Hipparcos Catalogue": 42333,
    "Henry Draper Catalogue": 73350,
    "Gliese Catalog": "Wo 9273",
    "Distance in Parsecs": 23.9751,
    "mag": 6.74,
    "Absolute Visual Magnitude": 4.841,
    "Spectral Type": "G0",
    "Color Index": 0.655,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.129575,
    "y": 18.380061,
    "z": -2.841607,
    "vx": 0.0000264,
    "vy": 0.0000225,
    "vz": 0.000005,
    "rarad": 2.25949556724216,
    "decrad": -0.118802553465321,
    "pmrarad": -0.00000144682946688194,
    "pmdecrad": 2.10118249e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 42214,
    "lum": 1.00832375776155,
    "var": "V401",
    "var_min": 6.75,
    "var_max": 6.73,
    "ra": 8.630637,
    "dec": -6.806885,
    "pmra": -298.43,
    "pmdec": 43.34,
    "rv": 0,
    "Asset Name": "ASTRID 002811",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Infatuated",
    "Hair": "Side Part",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.0000264,
    "y_1": 0.0000225,
    "z_1": 0.000005,
    "Distance in Parsecs_1": 23.9751,
    "HYG Star ID_1": 42214,
    "Hipparcos Catalogue_1": 42333,
    "Henry Draper Catalogue_1": 73350,
    "Gliese Catalog_1": "Wo 9273"
  },
  {
    "Astrid #": "ASTRID 002812",
    "HYG Star ID": 4171,
    "Hipparcos Catalogue": 4189,
    "Distance in Parsecs": 23.9808,
    "mag": 11.92,
    "Absolute Visual Magnitude": 10.021,
    "Color Index": 1.49,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 17.545655,
    "y": 4.163588,
    "z": -15.808014,
    "vx": -0.00001524,
    "vy": -0.00000182,
    "vz": -0.00001739,
    "rarad": 0.232990484893743,
    "decrad": -0.719747226121357,
    "pmrarad": 7.26735707152777e-8,
    "pmdecrad": -9.64585298e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 4171,
    "lum": 0.00854279529904847,
    "var_min": 12.044,
    "var_max": 11.764,
    "ra": 0.889958,
    "dec": -41.238478,
    "pmra": 14.99,
    "pmdec": -198.96,
    "rv": 0,
    "Asset Name": "ASTRID 002812",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Windy",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00001524,
    "y_1": -0.00000182,
    "z_1": -0.00001739,
    "Distance in Parsecs_1": 23.9808,
    "HYG Star ID_1": 4171,
    "Hipparcos Catalogue_1": 4189
  },
  {
    "Astrid #": "ASTRID 002813",
    "HYG Star ID": 119395,
    "Gliese Catalog": "Wo 9722",
    "Distance in Parsecs": 23.9808,
    "mag": 13.3,
    "Absolute Visual Magnitude": 11.401,
    "Spectral Type": "sdM1",
    "Color Index": 1.55,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": 8.836399,
    "y": -8.248376,
    "z": 20.695984,
    "vx": -0.00004296,
    "vy": -0.00011948,
    "vz": -0.00033707,
    "rarad": 5.53243086752661,
    "decrad": 1.0421723382962,
    "pmrarad": -0.00000486592946763194,
    "pmdecrad": -0.000008887774076,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119395,
    "lum": 0.00239662452940863,
    "ra": 21.132329,
    "dec": 59.712077,
    "pmra": -1003.67,
    "pmdec": -1833.23,
    "rv": -260,
    "Asset Name": "ASTRID 002813",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Nervous Grin",
    "Hair": "Wavy",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004296,
    "y_1": -0.00011948,
    "z_1": -0.00033707,
    "Distance in Parsecs_1": 23.9808,
    "HYG Star ID_1": 119395,
    "Gliese Catalog_1": "Wo 9722"
  },
  {
    "Astrid #": "ASTRID 002814",
    "HYG Star ID": 3137,
    "Hipparcos Catalogue": 3143,
    "Gliese Catalog": "Gl 27.1",
    "Distance in Parsecs": 23.9866,
    "mag": 11.41,
    "Absolute Visual Magnitude": 9.51,
    "Spectral Type": "M4",
    "Color Index": 1.476,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.91995,
    "y": 2.981951,
    "z": -16.738586,
    "vx": -0.00002748,
    "vy": 0.00005216,
    "vz": -0.00001849,
    "rarad": 0.174439263953935,
    "decrad": -0.772364362929955,
    "pmrarad": 0.00000234068044972222,
    "pmdecrad": -0.000001076334852,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 3137,
    "lum": 0.0136772882559584,
    "var_min": 11.479,
    "var_max": 11.329,
    "ra": 0.666309,
    "dec": -44.253218,
    "pmra": 482.8,
    "pmdec": -222.01,
    "rv": 0,
    "Asset Name": "ASTRID 002814",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Middle Part",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00002748,
    "y_1": 0.00005216,
    "z_1": -0.00001849,
    "Distance in Parsecs_1": 23.9866,
    "HYG Star ID_1": 3137,
    "Hipparcos Catalogue_1": 3143,
    "Gliese Catalog_1": "Gl 27.1"
  },
  {
    "Astrid #": "ASTRID 002815",
    "HYG Star ID": 109634,
    "Hipparcos Catalogue": 109980,
    "Distance in Parsecs": 23.9981,
    "mag": 9.23,
    "Absolute Visual Magnitude": 7.329,
    "Spectral Type": "K5",
    "Color Index": 1.17,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.97023,
    "y": -3.86456,
    "z": 22.303574,
    "vx": 0.0000201,
    "vy": 0.00003912,
    "vz": -4e-7,
    "rarad": 5.83168396693984,
    "decrad": 1.19275444408917,
    "pmrarad": 0.00000183245026839583,
    "pmdecrad": -4.5475523e-8,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 109634,
    "lum": 0.10195299775626,
    "ra": 22.275392,
    "dec": 68.339796,
    "pmra": 377.97,
    "pmdec": -9.38,
    "rv": 0,
    "Asset Name": "ASTRID 002815",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Ninja",
    "Outfit": "Sharky",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.0000201,
    "y_1": 0.00003912,
    "z_1": -4e-7,
    "Distance in Parsecs_1": 23.9981,
    "HYG Star ID_1": 109634,
    "Hipparcos Catalogue_1": 109980
  },
  {
    "Astrid #": "ASTRID 002816",
    "HYG Star ID": 5017,
    "Hipparcos Catalogue": 5027,
    "Henry Draper Catalogue": 6378,
    "Gliese Catalog": "Wo 9036",
    "Distance in Parsecs": 24.0038,
    "mag": 9.78,
    "Absolute Visual Magnitude": 7.879,
    "Spectral Type": "K4/K5V",
    "Color Index": 1.194,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.797464,
    "y": 6.003127,
    "z": -10.373599,
    "vx": -0.00001172,
    "vy": -0.00000752,
    "vz": -0.00003448,
    "rarad": 0.28100917831612,
    "decrad": -0.446891520035225,
    "pmrarad": -1.65563871909722e-7,
    "pmdecrad": -0.00000153588974,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 5017,
    "lum": 0.0614327561270521,
    "ra": 1.073376,
    "dec": -25.604998,
    "pmra": -34.15,
    "pmdec": -316.8,
    "rv": 2.8,
    "Asset Name": "ASTRID 002816",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Serious Shinobi",
    "Hair": "Audiophile",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00001172,
    "y_1": -0.00000752,
    "z_1": -0.00003448,
    "Distance in Parsecs_1": 24.0038,
    "HYG Star ID_1": 5017,
    "Hipparcos Catalogue_1": 5027,
    "Henry Draper Catalogue_1": 6378,
    "Gliese Catalog_1": "Wo 9036"
  },
  {
    "Astrid #": "ASTRID 002817",
    "HYG Star ID": 47261,
    "Hipparcos Catalogue": 47395,
    "Distance in Parsecs": 24.0096,
    "mag": 11.77,
    "Absolute Visual Magnitude": 9.868,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -16.692995,
    "y": 11.753283,
    "z": 12.635868,
    "vx": 0.00000235,
    "vy": 0.00002544,
    "vz": -0.00002056,
    "rarad": 2.5281319482462,
    "decrad": 0.554224423117377,
    "pmrarad": -9.22600434097222e-7,
    "pmdecrad": -0.000001006958014,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 47261,
    "lum": 0.00983558056226956,
    "var_min": 11.878,
    "var_max": 11.678,
    "ra": 9.656753,
    "dec": 31.75472,
    "pmra": -190.3,
    "pmdec": -207.7,
    "rv": 0,
    "Asset Name": "ASTRID 002817",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Guilty Grin",
    "Hair": "Sweetheart",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000235,
    "y_1": 0.00002544,
    "z_1": -0.00002056,
    "Distance in Parsecs_1": 24.0096,
    "HYG Star ID_1": 47261,
    "Hipparcos Catalogue_1": 47395
  },
  {
    "Astrid #": "ASTRID 002818",
    "HYG Star ID": 100031,
    "Hipparcos Catalogue": 100356,
    "Gliese Catalog": "NN 4145",
    "Distance in Parsecs": 24.0096,
    "mag": 10.21,
    "Absolute Visual Magnitude": 8.308,
    "Spectral Type": "M0",
    "Color Index": 1.594,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.580624,
    "y": -12.120876,
    "z": -18.865793,
    "vx": 0.00000115,
    "vy": -0.00000971,
    "vz": 0.00000676,
    "rarad": 5.32841242178893,
    "decrad": -0.90392455080118,
    "pmrarad": -1.94652692743055e-7,
    "pmdecrad": 4.55337008e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 100031,
    "lum": 0.0413809064816725,
    "ra": 20.353036,
    "dec": -51.791062,
    "pmra": -40.15,
    "pmdec": 93.92,
    "rv": 0,
    "Asset Name": "ASTRID 002818",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Pixie",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00000115,
    "y_1": -0.00000971,
    "z_1": 0.00000676,
    "Distance in Parsecs_1": 24.0096,
    "HYG Star ID_1": 100031,
    "Hipparcos Catalogue_1": 100356,
    "Gliese Catalog_1": "NN 4145"
  },
  {
    "Astrid #": "ASTRID 002819",
    "HYG Star ID": 43434,
    "Hipparcos Catalogue": 43557,
    "Henry Draper Catalogue": 75767,
    "Distance in Parsecs": 24.0154,
    "mag": 6.57,
    "Absolute Visual Magnitude": 4.668,
    "Spectral Type": "G0",
    "Color Index": 0.64,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.237294,
    "y": 17.370809,
    "z": 3.368416,
    "vx": -0.00001807,
    "vy": -0.00000678,
    "vz": -0.00002664,
    "rarad": 2.3224798029579,
    "decrad": 0.140724506929162,
    "pmrarad": 7.42395189034722e-7,
    "pmdecrad": -0.00000114149381,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 43434,
    "lum": 1.1824968703831,
    "ra": 8.871219,
    "dec": 8.06292,
    "pmra": 153.13,
    "pmdec": -235.45,
    "rv": 3.5,
    "Asset Name": "ASTRID 002819",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Smitten",
    "Hair": "Braided Bun",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00001807,
    "y_1": -0.00000678,
    "z_1": -0.00002664,
    "Distance in Parsecs_1": 24.0154,
    "HYG Star ID_1": 43434,
    "Hipparcos Catalogue_1": 43557,
    "Henry Draper Catalogue_1": 75767
  },
  {
    "Astrid #": "ASTRID 002820",
    "HYG Star ID": 8260,
    "Hipparcos Catalogue": 8275,
    "Henry Draper Catalogue": 10853,
    "Gliese Catalog": "Gl 74",
    "Distance in Parsecs": 24.0211,
    "mag": 8.91,
    "Absolute Visual Magnitude": 7.007,
    "Spectral Type": "K5",
    "Color Index": 1.044,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.96549,
    "y": 10.526819,
    "z": 5.163041,
    "vx": 0.0000195,
    "vy": 0.00001413,
    "vz": -0.00000378,
    "rarad": 0.465327904353225,
    "decrad": 0.216626278502777,
    "pmrarad": 1.61297511520833e-7,
    "pmdecrad": -3.66228254e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 8260,
    "lum": 0.137151322593648,
    "ra": 1.777422,
    "dec": 12.411771,
    "pmra": 33.27,
    "pmdec": -75.54,
    "rv": 21.9,
    "Asset Name": "ASTRID 002820",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Nervous Grin",
    "Hair": "Frizzy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.0000195,
    "y_1": 0.00001413,
    "z_1": -0.00000378,
    "Distance in Parsecs_1": 24.0211,
    "HYG Star ID_1": 8260,
    "Hipparcos Catalogue_1": 8275,
    "Henry Draper Catalogue_1": 10853,
    "Gliese Catalog_1": "Gl 74"
  },
  {
    "Astrid #": "ASTRID 002821",
    "HYG Star ID": 114526,
    "Hipparcos Catalogue": 114886,
    "Henry Draper Catalogue": 219538,
    "Gliese Catalog": "NN 4320",
    "Distance in Parsecs": 24.0211,
    "mag": 8.07,
    "Absolute Visual Magnitude": 6.167,
    "Spectral Type": "K2V",
    "Color Index": 0.871,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.286628,
    "y": -3.915528,
    "z": 12.253102,
    "vx": 0.00001046,
    "vy": 0.00004059,
    "vz": 0.00001369,
    "rarad": 6.09251708339082,
    "decrad": 0.535296098271911,
    "pmrarad": 0.00000174164466602777,
    "pmdecrad": 4.35459647e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 114526,
    "lum": 0.297303484967702,
    "ra": 23.271701,
    "dec": 30.670207,
    "pmra": 359.24,
    "pmdec": 89.82,
    "rv": 9,
    "Asset Name": "ASTRID 002821",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Shinobi",
    "Hair": "Spiky",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001046,
    "y_1": 0.00004059,
    "z_1": 0.00001369,
    "Distance in Parsecs_1": 24.0211,
    "HYG Star ID_1": 114526,
    "Hipparcos Catalogue_1": 114886,
    "Henry Draper Catalogue_1": 219538,
    "Gliese Catalog_1": "NN 4320"
  },
  {
    "Astrid #": "ASTRID 002822",
    "HYG Star ID": 49225,
    "Hipparcos Catalogue": 49366,
    "Henry Draper Catalogue": 87424,
    "Distance in Parsecs": 24.0327,
    "mag": 8.15,
    "Absolute Visual Magnitude": 6.246,
    "Spectral Type": "K0",
    "Color Index": 0.891,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.611701,
    "y": 11.35157,
    "z": -4.885725,
    "vx": 0.00001117,
    "vy": 0.00001903,
    "vz": -0.0000029,
    "rarad": 2.63818606012212,
    "decrad": -0.204721931619458,
    "pmrarad": -9.18043185499999e-7,
    "pmdecrad": -1.23385081e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 49225,
    "lum": 0.276439437122033,
    "var": "V417",
    "var_min": 8.181,
    "var_max": 8.121,
    "ra": 10.077128,
    "dec": -11.729703,
    "pmra": -189.36,
    "pmdec": -25.45,
    "rv": 0,
    "Asset Name": "ASTRID 002822",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Pixie",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001117,
    "y_1": 0.00001903,
    "z_1": -0.0000029,
    "Distance in Parsecs_1": 24.0327,
    "HYG Star ID_1": 49225,
    "Hipparcos Catalogue_1": 49366,
    "Henry Draper Catalogue_1": 87424
  },
  {
    "Astrid #": "ASTRID 002823",
    "HYG Star ID": 63055,
    "Hipparcos Catalogue": 63257,
    "Henry Draper Catalogue": 112575,
    "Gliese Catalog": "Gl 489",
    "Distance in Parsecs": 24.0327,
    "mag": 9.11,
    "Absolute Visual Magnitude": 7.206,
    "Spectral Type": "K4V",
    "Color Index": 1.118,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.536618,
    "y": -5.800322,
    "z": -6.002499,
    "vx": -0.00001573,
    "vy": 0.00003792,
    "vz": 0.00000153,
    "rarad": 3.39349919478962,
    "decrad": -0.252435823955326,
    "pmrarad": -0.00000169122404325,
    "pmdecrad": 1.21833677e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 63055,
    "lum": 0.114182618954822,
    "ra": 12.962212,
    "dec": -14.463507,
    "pmra": -348.84,
    "pmdec": 25.13,
    "rv": 5.1,
    "Asset Name": "ASTRID 002823",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Fedora",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001573,
    "y_1": 0.00003792,
    "z_1": 0.00000153,
    "Distance in Parsecs_1": 24.0327,
    "HYG Star ID_1": 63055,
    "Hipparcos Catalogue_1": 63257,
    "Henry Draper Catalogue_1": 112575,
    "Gliese Catalog_1": "Gl 489"
  },
  {
    "Astrid #": "ASTRID 002824",
    "HYG Star ID": 119396,
    "Gliese Catalog": "GJ 2152",
    "Distance in Parsecs": 24.0385,
    "mag": 16.17,
    "Absolute Visual Magnitude": 14.265,
    "Spectral Type": "f",
    "Color Index": 0.45,
    "Spectral Abbreviation": "f",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.816808,
    "y": -15.834521,
    "z": 3.111073,
    "vx": 0.0000248,
    "vy": 0.0000307,
    "vz": 0.00001423,
    "rarad": 5.55662511180999,
    "decrad": 0.129784456827,
    "pmrarad": 0.00000164007619995138,
    "pmdecrad": 5.9693654e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119396,
    "lum": 0.000171395730750842,
    "ra": 21.224744,
    "dec": 7.436102,
    "pmra": 338.29,
    "pmdec": 123.13,
    "rv": 0,
    "Asset Name": "ASTRID 002824",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Prince",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000248,
    "y_1": 0.0000307,
    "z_1": 0.00001423,
    "Distance in Parsecs_1": 24.0385,
    "HYG Star ID_1": 119396,
    "Gliese Catalog_1": "GJ 2152"
  },
  {
    "Astrid #": "ASTRID 002825",
    "HYG Star ID": 17165,
    "Hipparcos Catalogue": 17207,
    "Gliese Catalog": "Gl 148",
    "Distance in Parsecs": 24.0442,
    "mag": 9.61,
    "Absolute Visual Magnitude": 7.705,
    "Spectral Type": "Mp",
    "Color Index": 1.36,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.662831,
    "y": 19.727104,
    "z": 1.514515,
    "vx": 0.00000742,
    "vy": 0.00000219,
    "vz": -0.0000273,
    "rarad": 0.965059619732931,
    "decrad": 0.0630304374489425,
    "pmrarad": -2.01973379319444e-7,
    "pmdecrad": -0.000001148862978,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 17165,
    "lum": 0.0721107479182899,
    "ra": 3.686256,
    "dec": 3.611378,
    "pmra": -41.66,
    "pmdec": -236.97,
    "rv": 4.2,
    "Asset Name": "ASTRID 002825",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Elegant",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00000742,
    "y_1": 0.00000219,
    "z_1": -0.0000273,
    "Distance in Parsecs_1": 24.0442,
    "HYG Star ID_1": 17165,
    "Hipparcos Catalogue_1": 17207,
    "Gliese Catalog_1": "Gl 148"
  },
  {
    "Astrid #": "ASTRID 002826",
    "HYG Star ID": 62317,
    "Hipparcos Catalogue": 62512,
    "Henry Draper Catalogue": 111456,
    "Harvard Revised Catalogue": 4867,
    "Gliese Catalog": "Wo 9417",
    "Distance in Parsecs": 24.0442,
    "mag": 5.83,
    "Absolute Visual Magnitude": 3.925,
    "Spectral Type": "F5V",
    "Color Index": 0.467,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.638309,
    "y": -2.50873,
    "z": 20.889576,
    "vx": 0.00000556,
    "vy": -0.00001165,
    "vz": -0.00001243,
    "rarad": 3.35389737258083,
    "decrad": 1.05277950191137,
    "pmrarad": 5.22580666270833e-7,
    "pmdecrad": -1.48352986e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 62317,
    "lum": 2.34422881531992,
    "ra": 12.810944,
    "dec": 60.319822,
    "pmra": 107.79,
    "pmdec": -30.6,
    "rv": -12,
    "Asset Name": "ASTRID 002826",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Bangs",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000556,
    "y_1": -0.00001165,
    "z_1": -0.00001243,
    "Distance in Parsecs_1": 24.0442,
    "HYG Star ID_1": 62317,
    "Hipparcos Catalogue_1": 62512,
    "Henry Draper Catalogue_1": 111456,
    "Harvard Revised Catalogue_1": 4867,
    "Gliese Catalog_1": "Wo 9417"
  },
  {
    "Astrid #": "ASTRID 002827",
    "HYG Star ID": 83195,
    "Hipparcos Catalogue": 83451,
    "Henry Draper Catalogue": 154712,
    "Gliese Catalog": "Wo 9582A",
    "Distance in Parsecs": 24.0442,
    "mag": 8.61,
    "Absolute Visual Magnitude": 6.705,
    "Spectral Type": "K4V...",
    "Color Index": 1.019,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.980203,
    "y": -11.801673,
    "z": 20.734777,
    "vx": -0.00002513,
    "vy": 0.00007038,
    "vz": -0.00005024,
    "rarad": 4.4650512051474,
    "decrad": 1.03996070472008,
    "pmrarad": -0.00000173015458179861,
    "pmdecrad": 0.000001169225153,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 83195,
    "base": "Wo 9582",
    "lum": 0.18113400926196,
    "var_min": 8.718,
    "var_max": 8.578,
    "ra": 17.055239,
    "dec": 59.585359,
    "pmra": -356.87,
    "pmdec": 241.17,
    "rv": -73.1,
    "Asset Name": "ASTRID 002827",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Grecian",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00002513,
    "y_1": 0.00007038,
    "z_1": -0.00005024,
    "Distance in Parsecs_1": 24.0442,
    "HYG Star ID_1": 83195,
    "Hipparcos Catalogue_1": 83451,
    "Henry Draper Catalogue_1": 154712,
    "Gliese Catalog_1": "Wo 9582A"
  },
  {
    "Astrid #": "ASTRID 002828",
    "HYG Star ID": 102435,
    "Hipparcos Catalogue": 102766,
    "Henry Draper Catalogue": 198425,
    "Distance in Parsecs": 24.05,
    "mag": 8.25,
    "Absolute Visual Magnitude": 6.344,
    "Spectral Type": "K2",
    "Color Index": 0.939,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.68829,
    "y": -15.033938,
    "z": 12.845776,
    "vx": -0.00000266,
    "vy": -0.00002498,
    "vz": -0.0000264,
    "rarad": 5.45097224036936,
    "decrad": 0.563475829755008,
    "pmrarad": -7.80986358006944e-7,
    "pmdecrad": -0.000001298427999,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 102435,
    "lum": 0.252580605483195,
    "var": "V2431",
    "var_min": 8.283,
    "var_max": 8.223,
    "ra": 20.82118,
    "dec": 32.284787,
    "pmra": -161.09,
    "pmdec": -267.82,
    "rv": 0,
    "Asset Name": "ASTRID 002828",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Hachimaki",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000266,
    "y_1": -0.00002498,
    "z_1": -0.0000264,
    "Distance in Parsecs_1": 24.05,
    "HYG Star ID_1": 102435,
    "Hipparcos Catalogue_1": 102766,
    "Henry Draper Catalogue_1": 198425
  },
  {
    "Astrid #": "ASTRID 002829",
    "HYG Star ID": 75312,
    "Hipparcos Catalogue": 75542,
    "Henry Draper Catalogue": 137303,
    "Gliese Catalog": "NN 3905",
    "Distance in Parsecs": 24.0964,
    "mag": 8.82,
    "Absolute Visual Magnitude": 6.91,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.045,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.401809,
    "y": -16.844618,
    "z": -10.82895,
    "vx": -0.00004718,
    "vy": 0.00009424,
    "vz": 0.00002103,
    "rarad": 4.04033249173445,
    "decrad": -0.466103830242482,
    "pmrarad": -0.00000396723034798611,
    "pmdecrad": -4.8141998e-8,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 75312,
    "lum": 0.149968483550237,
    "ra": 15.432933,
    "dec": -26.705782,
    "pmra": -818.3,
    "pmdec": -9.93,
    "rv": -48,
    "Asset Name": "ASTRID 002829",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Glad",
    "Hair": "Windy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00004718,
    "y_1": 0.00009424,
    "z_1": 0.00002103,
    "Distance in Parsecs_1": 24.0964,
    "HYG Star ID_1": 75312,
    "Hipparcos Catalogue_1": 75542,
    "Henry Draper Catalogue_1": 137303,
    "Gliese Catalog_1": "NN 3905"
  },
  {
    "Astrid #": "ASTRID 002830",
    "HYG Star ID": 118465,
    "Gliese Catalog": "NN 3430A",
    "Distance in Parsecs": 24.0964,
    "mag": 14.67,
    "Absolute Visual Magnitude": 12.76,
    "Spectral Type": "sdM6",
    "Color Index": 1.65,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": -5.752879,
    "y": 18.184779,
    "z": 14.725987,
    "vx": 0.00001983,
    "vy": 0.00002693,
    "vz": -0.0000255,
    "rarad": 1.87720576905715,
    "decrad": 0.65748503401125,
    "pmrarad": -0.00000112185885680555,
    "pmdecrad": -0.000001336999775,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118465,
    "base": "NN 3430",
    "lum": 0.000685488226452661,
    "ra": 7.170398,
    "dec": 37.671118,
    "pmra": -231.4,
    "pmdec": -275.78,
    "rv": 0,
    "Asset Name": "ASTRID 002830",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Medium Bob",
    "Outfit": "Iron Star",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001983,
    "y_1": 0.00002693,
    "z_1": -0.0000255,
    "Distance in Parsecs_1": 24.0964,
    "HYG Star ID_1": 118465,
    "Gliese Catalog_1": "NN 3430A"
  },
  {
    "Astrid #": "ASTRID 002831",
    "HYG Star ID": 118466,
    "Gliese Catalog": "NN 3431B",
    "Distance in Parsecs": 24.0964,
    "mag": 15.68,
    "Absolute Visual Magnitude": 13.77,
    "Spectral Type": "DQ8",
    "Color Index": 0.3,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -5.754201,
    "y": 18.184366,
    "z": 14.725981,
    "vx": 0.00001983,
    "vy": 0.00002693,
    "vz": -0.0000255,
    "rarad": 1.87727841305715,
    "decrad": 0.65748469601125,
    "pmrarad": -0.00000112185885680555,
    "pmdecrad": -0.000001336999775,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118466,
    "base": "NN 3431",
    "lum": 0.000270395836410884,
    "ra": 7.170675,
    "dec": 37.671098,
    "pmra": -231.4,
    "pmdec": -275.78,
    "rv": 0,
    "Asset Name": "ASTRID 002831",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Fedora",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001983,
    "y_1": 0.00002693,
    "z_1": -0.0000255,
    "Distance in Parsecs_1": 24.0964,
    "HYG Star ID_1": 118466,
    "Gliese Catalog_1": "NN 3431B"
  },
  {
    "Astrid #": "ASTRID 002832",
    "HYG Star ID": 118550,
    "Gliese Catalog": "NN 3509",
    "Distance in Parsecs": 24.0964,
    "mag": 14.68,
    "Absolute Visual Magnitude": 12.77,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.920407,
    "y": 6.989165,
    "z": 22.287595,
    "vx": 0.00003714,
    "vy": 0.00007426,
    "vz": -0.00001341,
    "rarad": 2.27385318988919,
    "decrad": 1.18086351892225,
    "pmrarad": -0.00000316874221611111,
    "pmdecrad": -0.000001464161555,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118550,
    "lum": 0.000679203632617184,
    "ra": 8.685479,
    "dec": 67.658496,
    "pmra": -653.6,
    "pmdec": -302,
    "rv": 0,
    "Asset Name": "ASTRID 002832",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Side Part",
    "Outfit": "Elven",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003714,
    "y_1": 0.00007426,
    "z_1": -0.00001341,
    "Distance in Parsecs_1": 24.0964,
    "HYG Star ID_1": 118550,
    "Gliese Catalog_1": "NN 3509"
  },
  {
    "Astrid #": "ASTRID 002833",
    "HYG Star ID": 103212,
    "Hipparcos Catalogue": 103544,
    "Gliese Catalog": "NN 4173",
    "Distance in Parsecs": 24.1022,
    "mag": 11.21,
    "Absolute Visual Magnitude": 9.3,
    "Spectral Type": "M2",
    "Color Index": 1.741,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.003196,
    "y": -14.163107,
    "z": 13.573243,
    "vx": 0.00003094,
    "vy": 0.00001724,
    "vz": -0.00001393,
    "rarad": 5.49210719568638,
    "decrad": 0.598197292742327,
    "pmrarad": 0.00000141584987269444,
    "pmdecrad": -6.99343734e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 103212,
    "lum": 0.0165958690743755,
    "var_min": 11.28,
    "var_max": 11.14,
    "ra": 20.978304,
    "dec": 34.27418,
    "pmra": 292.04,
    "pmdec": -144.25,
    "rv": 0,
    "Asset Name": "ASTRID 002833",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Serious Shinobi",
    "Hair": "Ninja",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00003094,
    "y_1": 0.00001724,
    "z_1": -0.00001393,
    "Distance in Parsecs_1": 24.1022,
    "HYG Star ID_1": 103212,
    "Hipparcos Catalogue_1": 103544,
    "Gliese Catalog_1": "NN 4173"
  },
  {
    "Astrid #": "ASTRID 002834",
    "HYG Star ID": 111535,
    "Hipparcos Catalogue": 111888,
    "Henry Draper Catalogue": 214683,
    "Distance in Parsecs": 24.1022,
    "mag": 8.48,
    "Absolute Visual Magnitude": 6.57,
    "Spectral Type": "K2",
    "Color Index": 0.938,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 22.584714,
    "y": -8.237341,
    "z": 1.730007,
    "vx": 0.00000631,
    "vy": 0.00001996,
    "vz": 0.00001262,
    "rarad": 5.93344796997006,
    "decrad": 0.0718397656398487,
    "pmrarad": 8.67768006826388e-7,
    "pmdecrad": 5.25101697e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 111535,
    "lum": 0.205116217882556,
    "var": "V403",
    "var_min": 8.506,
    "var_max": 8.446,
    "ra": 22.664102,
    "dec": 4.116115,
    "pmra": 178.99,
    "pmdec": 108.31,
    "rv": 0,
    "Asset Name": "ASTRID 002834",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Ninja",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000631,
    "y_1": 0.00001996,
    "z_1": 0.00001262,
    "Distance in Parsecs_1": 24.1022,
    "HYG Star ID_1": 111535,
    "Hipparcos Catalogue_1": 111888,
    "Henry Draper Catalogue_1": 214683
  },
  {
    "Astrid #": "ASTRID 002835",
    "HYG Star ID": 96093,
    "Hipparcos Catalogue": 96395,
    "Henry Draper Catalogue": 185414,
    "Distance in Parsecs": 24.108,
    "mag": 6.73,
    "Absolute Visual Magnitude": 4.819,
    "Spectral Type": "G0",
    "Color Index": 0.636,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.338967,
    "y": -12.001819,
    "z": 20.214861,
    "vx": 0.00000443,
    "vy": -0.00000988,
    "vz": -0.00002631,
    "rarad": 5.13094898160529,
    "decrad": 0.994556687185815,
    "pmrarad": 1.21203420138888e-9,
    "pmdecrad": -9.71178764e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 96093,
    "lum": 1.02896357229675,
    "ra": 19.598781,
    "dec": 56.983901,
    "pmra": 0.25,
    "pmdec": -200.32,
    "rv": -15.8,
    "Asset Name": "ASTRID 002835",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Tiny Crown",
    "Outfit": "Mecha",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00000443,
    "y_1": -0.00000988,
    "z_1": -0.00002631,
    "Distance in Parsecs_1": 24.108,
    "HYG Star ID_1": 96093,
    "Hipparcos Catalogue_1": 96395,
    "Henry Draper Catalogue_1": 185414
  },
  {
    "Astrid #": "ASTRID 002836",
    "HYG Star ID": 63845,
    "Hipparcos Catalogue": 64048,
    "Gliese Catalog": "Wo 9427",
    "Distance in Parsecs": 24.1138,
    "mag": 9.28,
    "Absolute Visual Magnitude": 7.369,
    "Spectral Type": "K3III",
    "Color Index": 1.178,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.037297,
    "y": -5.782557,
    "z": 13.624034,
    "vx": 0.00000394,
    "vy": 0.0000174,
    "vz": -0.00000557,
    "rarad": 3.43648638897978,
    "decrad": 0.600422660838372,
    "pmrarad": -6.43202309993055e-7,
    "pmdecrad": 1.6095814e-8,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 63845,
    "lum": 0.0982652582829531,
    "ra": 13.126411,
    "dec": 34.401684,
    "pmra": -132.67,
    "pmdec": 3.32,
    "rv": -10.2,
    "Asset Name": "ASTRID 002836",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Honest Lad",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00000394,
    "y_1": 0.0000174,
    "z_1": -0.00000557,
    "Distance in Parsecs_1": 24.1138,
    "HYG Star ID_1": 63845,
    "Hipparcos Catalogue_1": 64048,
    "Gliese Catalog_1": "Wo 9427"
  },
  {
    "Astrid #": "ASTRID 002837",
    "HYG Star ID": 48365,
    "Hipparcos Catalogue": 48502,
    "Gliese Catalog": "GJ 1130A",
    "Distance in Parsecs": 24.1255,
    "mag": 10.19,
    "Absolute Visual Magnitude": 8.278,
    "Spectral Type": "M0V",
    "Color Index": 1.375,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.466702,
    "y": 10.75948,
    "z": -12.69597,
    "vx": 0.00001588,
    "vy": 0.00001179,
    "vz": -0.00001186,
    "rarad": 2.58949294659942,
    "decrad": -0.554180812000382,
    "pmrarad": -7.61302922576388e-7,
    "pmdecrad": -5.78285758e-7,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 48365,
    "base": "GJ 1130",
    "lum": 0.0425402462944067,
    "var_min": 10.253,
    "var_max": 10.133,
    "ra": 9.891134,
    "dec": -31.752222,
    "pmra": -157.03,
    "pmdec": -119.28,
    "rv": 0,
    "Asset Name": "ASTRID 002837",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Headband",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": 0.00001588,
    "y_1": 0.00001179,
    "z_1": -0.00001186,
    "Distance in Parsecs_1": 24.1255,
    "HYG Star ID_1": 48365,
    "Hipparcos Catalogue_1": 48502,
    "Gliese Catalog_1": "GJ 1130A"
  },
  {
    "Astrid #": "ASTRID 002838",
    "HYG Star ID": 12126,
    "Hipparcos Catalogue": 12158,
    "Henry Draper Catalogue": 16287,
    "Gliese Catalog": "Wo 9087",
    "Distance in Parsecs": 24.1313,
    "mag": 8.1,
    "Absolute Visual Magnitude": 6.187,
    "Spectral Type": "K0",
    "Color Index": 0.944,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.679022,
    "y": 15.220134,
    "z": -1.328605,
    "vx": -0.00001645,
    "vy": 0.00003532,
    "vz": 0.00000612,
    "rarad": 0.683715409652951,
    "decrad": -0.0550850310212825,
    "pmrarad": 0.00000156556033725,
    "pmdecrad": 2.75083282e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12126,
    "lum": 0.29187708481971397,
    "var": "FT",
    "var_min": 8.119,
    "var_max": 8.079,
    "ra": 2.6116,
    "dec": -3.15614,
    "pmra": 322.92,
    "pmdec": 56.74,
    "rv": 9,
    "Asset Name": "ASTRID 002838",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Serious Shinobi",
    "Hair": "Pixie",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00001645,
    "y_1": 0.00003532,
    "z_1": 0.00000612,
    "Distance in Parsecs_1": 24.1313,
    "HYG Star ID_1": 12126,
    "Hipparcos Catalogue_1": 12158,
    "Henry Draper Catalogue_1": 16287,
    "Gliese Catalog_1": "Wo 9087"
  },
  {
    "Astrid #": "ASTRID 002839",
    "HYG Star ID": 66674,
    "Hipparcos Catalogue": 66886,
    "Henry Draper Catalogue": 119291,
    "Gliese Catalog": "GJ 1176",
    "Distance in Parsecs": 24.1313,
    "mag": 9.24,
    "Absolute Visual Magnitude": 7.327,
    "Spectral Type": "K7V",
    "Color Index": 1.186,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.751424,
    "y": -10.425495,
    "z": -0.710106,
    "vx": -0.00001402,
    "vy": 0.00003049,
    "vz": -0.00001812,
    "rarad": 3.58854498347962,
    "decrad": -0.0294309993160562,
    "pmrarad": -0.00000139068804267361,
    "pmdecrad": -7.51121835e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66674,
    "lum": 0.102140975198984,
    "ra": 13.707232,
    "dec": -1.686272,
    "pmra": -286.85,
    "pmdec": -154.93,
    "rv": 0,
    "Asset Name": "ASTRID 002839",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Neko Dreams",
    "Hair": "Bangs",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001402,
    "y_1": 0.00003049,
    "z_1": -0.00001812,
    "Distance in Parsecs_1": 24.1313,
    "HYG Star ID_1": 66674,
    "Hipparcos Catalogue_1": 66886,
    "Henry Draper Catalogue_1": 119291,
    "Gliese Catalog_1": "GJ 1176"
  },
  {
    "Astrid #": "ASTRID 002840",
    "HYG Star ID": 56461,
    "Hipparcos Catalogue": 56630,
    "Gliese Catalog": "NN 3674",
    "Distance in Parsecs": 24.1429,
    "mag": 10.01,
    "Absolute Visual Magnitude": 8.096,
    "Spectral Type": "M0",
    "Color Index": 1.29,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.614842,
    "y": 1.900552,
    "z": 15.255721,
    "vx": -0.00000193,
    "vy": -0.0000516,
    "vz": -0.0000639,
    "rarad": 3.03984101920463,
    "decrad": 0.684005443506752,
    "pmrarad": 0.00000213453767274999,
    "pmdecrad": -0.000001964513514,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 56461,
    "lum": 0.0503037081084624,
    "ra": 11.611337,
    "dec": 39.190625,
    "pmra": 440.28,
    "pmdec": -405.21,
    "rv": -42,
    "Asset Name": "ASTRID 002840",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Elegant",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000193,
    "y_1": -0.0000516,
    "z_1": -0.0000639,
    "Distance in Parsecs_1": 24.1429,
    "HYG Star ID_1": 56461,
    "Hipparcos Catalogue_1": 56630,
    "Gliese Catalog_1": "NN 3674"
  },
  {
    "Astrid #": "ASTRID 002841",
    "HYG Star ID": 67628,
    "Hipparcos Catalogue": 67842,
    "Gliese Catalog": "Gl 534.2",
    "Distance in Parsecs": 24.1488,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.716,
    "Spectral Type": "M0",
    "Color Index": 1.421,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.105339,
    "y": -2.22405,
    "z": 23.693128,
    "vx": -0.0000024,
    "vy": 0.00003256,
    "vz": 0.00000264,
    "rarad": 3.63812162028959,
    "decrad": 1.37622515928105,
    "pmrarad": -0.00000123288118965277,
    "pmdecrad": 5.65632121e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 67628,
    "lum": 0.0284184246806359,
    "var_min": 10.7,
    "var_max": 10.58,
    "ra": 13.896601,
    "dec": 78.851893,
    "pmra": -254.3,
    "pmdec": 116.67,
    "rv": 0,
    "Asset Name": "ASTRID 002841",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Bob",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": -0.0000024,
    "y_1": 0.00003256,
    "z_1": 0.00000264,
    "Distance in Parsecs_1": 24.1488,
    "HYG Star ID_1": 67628,
    "Hipparcos Catalogue_1": 67842,
    "Gliese Catalog_1": "Gl 534.2"
  },
  {
    "Astrid #": "ASTRID 002842",
    "HYG Star ID": 17426,
    "Hipparcos Catalogue": 17468,
    "Distance in Parsecs": 24.1546,
    "mag": 9.67,
    "Absolute Visual Magnitude": 7.755,
    "Spectral Type": "A2",
    "Color Index": 0.88,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 11.390005,
    "y": 16.980388,
    "z": 12.859974,
    "vx": 2.2e-7,
    "vy": 5.6e-7,
    "vz": -9.3e-7,
    "rarad": 0.97995549551174,
    "decrad": 0.561436436968112,
    "pmrarad": 5.42991322222222e-9,
    "pmdecrad": -4.571793e-8,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 17426,
    "lum": 0.0688652296344275,
    "var_min": 10.065,
    "var_max": 8.695,
    "ra": 3.743154,
    "dec": 32.167938,
    "pmra": 1.12,
    "pmdec": -9.43,
    "rv": 0,
    "Asset Name": "ASTRID 002842",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "Audiohead",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 2.2e-7,
    "y_1": 5.6e-7,
    "z_1": -9.3e-7,
    "Distance in Parsecs_1": 24.1546,
    "HYG Star ID_1": 17426,
    "Hipparcos Catalogue_1": 17468
  },
  {
    "Astrid #": "ASTRID 002843",
    "HYG Star ID": 43673,
    "Hipparcos Catalogue": 43797,
    "Henry Draper Catalogue": 76653,
    "Harvard Revised Catalogue": 3570,
    "Gliese Catalog": "NN 3519",
    "Distance in Parsecs": 24.1546,
    "mag": 5.7,
    "Absolute Visual Magnitude": 3.785,
    "Spectral Type": "F6V",
    "Color Index": 0.481,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.597319,
    "y": 10.008309,
    "z": -19.777994,
    "vx": 0.00000482,
    "vy": -0.00000912,
    "vz": -0.00000446,
    "rarad": 2.3352339058656,
    "decrad": -0.959333629813165,
    "pmrarad": 1.17470354798611e-7,
    "pmdecrad": -4.42150076e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 43673,
    "lum": 2.66685866452147,
    "ra": 8.919936,
    "dec": -54.965768,
    "pmra": 24.23,
    "pmdec": -91.2,
    "rv": -2,
    "Asset Name": "ASTRID 002843",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Frizzy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000482,
    "y_1": -0.00000912,
    "z_1": -0.00000446,
    "Distance in Parsecs_1": 24.1546,
    "HYG Star ID_1": 43673,
    "Hipparcos Catalogue_1": 43797,
    "Henry Draper Catalogue_1": 76653,
    "Harvard Revised Catalogue_1": 3570,
    "Gliese Catalog_1": "NN 3519"
  },
  {
    "Astrid #": "ASTRID 002844",
    "HYG Star ID": 118887,
    "Henry Draper Catalogue": 116657,
    "Harvard Revised Catalogue": 5055,
    "Gliese Catalog": "NN 3784B",
    "Distance in Parsecs": 24.1546,
    "mag": 3.95,
    "Absolute Visual Magnitude": 2.035,
    "Spectral Type": "A1 m",
    "Color Index": 0.13,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -12.960884,
    "y": -4.971592,
    "z": 19.766651,
    "vx": 0.00000682,
    "vy": -0.00001064,
    "vz": -0.000009820000000000001,
    "rarad": 3.50784906309929,
    "decrad": 0.95854304522465,
    "pmrarad": 5.12641985819444e-7,
    "pmdecrad": -1.46995507e-7,
    "bayer": "Zet",
    "flam": 79,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 118887,
    "base": "NN 3784",
    "lum": 13.3659551654644,
    "bf": "79Zet UMa",
    "ra": 13.398996,
    "dec": 54.920471,
    "pmra": 105.74,
    "pmdec": -30.32,
    "rv": -9.3,
    "Asset Name": "ASTRID 002844",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Middle Part",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000682,
    "y_1": -0.00001064,
    "z_1": -0.000009820000000000001,
    "Distance in Parsecs_1": 24.1546,
    "HYG Star ID_1": 118887,
    "Henry Draper Catalogue_1": 116657,
    "Harvard Revised Catalogue_1": 5055,
    "Gliese Catalog_1": "NN 3784B"
  },
  {
    "Astrid #": "ASTRID 002845",
    "HYG Star ID": 102002,
    "Hipparcos Catalogue": 102333,
    "Henry Draper Catalogue": 197157,
    "Harvard Revised Catalogue": 7920,
    "Gliese Catalog": "NN 4158",
    "Distance in Parsecs": 24.1721,
    "mag": 4.51,
    "Absolute Visual Magnitude": 2.593,
    "Spectral Type": "A6:var",
    "Color Index": 0.278,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 9.78249,
    "y": -11.249611,
    "z": -19.027319,
    "vx": 0.00000984,
    "vy": 0.00001645,
    "vz": -0.00000259,
    "rarad": 5.42813958207192,
    "decrad": -0.906191891473007,
    "pmrarad": 7.53836791895833e-7,
    "pmdecrad": -2.60054058e-7,
    "bayer": "Eta",
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 102002,
    "lum": 7.99466002520316,
    "bf": "Eta Ind",
    "ra": 20.733966,
    "dec": -51.920971,
    "pmra": 155.49,
    "pmdec": -53.64,
    "rv": -1.6,
    "Asset Name": "ASTRID 002845",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Frizzy",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00000984,
    "y_1": 0.00001645,
    "z_1": -0.00000259,
    "Distance in Parsecs_1": 24.1721,
    "HYG Star ID_1": 102002,
    "Hipparcos Catalogue_1": 102333,
    "Henry Draper Catalogue_1": 197157,
    "Harvard Revised Catalogue_1": 7920,
    "Gliese Catalog_1": "NN 4158"
  },
  {
    "Astrid #": "ASTRID 002846",
    "HYG Star ID": 58146,
    "Hipparcos Catalogue": 58321,
    "Gliese Catalog": "NN 3695",
    "Distance in Parsecs": 24.1838,
    "mag": 11.81,
    "Absolute Visual Magnitude": 9.892,
    "Spectral Type": "M2:",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -23.66898,
    "y": 0.253375,
    "z": 4.956952,
    "vx": 0.0000066,
    "vy": 0.00008004,
    "vz": 0.00002743,
    "rarad": 3.13088872844448,
    "decrad": 0.206433014090298,
    "pmrarad": -0.0000033126349165,
    "pmdecrad": 0.000001158753177,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 58146,
    "lum": 0.00962055219186214,
    "var_min": 11.911,
    "var_max": 11.731,
    "ra": 11.959114,
    "dec": 11.82774,
    "pmra": -683.28,
    "pmdec": 239.01,
    "rv": 0,
    "Asset Name": "ASTRID 002846",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Side Part",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.0000066,
    "y_1": 0.00008004,
    "z_1": 0.00002743,
    "Distance in Parsecs_1": 24.1838,
    "HYG Star ID_1": 58146,
    "Hipparcos Catalogue_1": 58321,
    "Gliese Catalog_1": "NN 3695"
  },
  {
    "Astrid #": "ASTRID 002847",
    "HYG Star ID": 12590,
    "Hipparcos Catalogue": 12623,
    "Henry Draper Catalogue": 16739,
    "Harvard Revised Catalogue": 788,
    "Gliese Catalog": "Gl 105.6",
    "Distance in Parsecs": 24.1896,
    "mag": 4.91,
    "Absolute Visual Magnitude": 2.992,
    "Spectral Type": "F9V",
    "Color Index": 0.582,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.037312,
    "y": 12.015355,
    "z": 15.611278,
    "vx": -0.00000177,
    "vy": -0.00000411,
    "vz": -0.00003138,
    "rarad": 0.707943287627041,
    "decrad": 0.701516658039992,
    "pmrarad": -8.16426238055555e-8,
    "pmdecrad": -8.83766858e-7,
    "flam": 12,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 12590,
    "lum": 5.536049950434,
    "bf": "12    Per",
    "ra": 2.704144,
    "dec": 40.193944,
    "pmra": -16.84,
    "pmdec": -182.29,
    "rv": -22.8,
    "Asset Name": "ASTRID 002847",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Pixie",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00000177,
    "y_1": -0.00000411,
    "z_1": -0.00003138,
    "Distance in Parsecs_1": 24.1896,
    "HYG Star ID_1": 12590,
    "Hipparcos Catalogue_1": 12623,
    "Henry Draper Catalogue_1": 16739,
    "Harvard Revised Catalogue_1": 788,
    "Gliese Catalog_1": "Gl 105.6"
  },
  {
    "Astrid #": "ASTRID 002848",
    "HYG Star ID": 15093,
    "Hipparcos Catalogue": 15131,
    "Henry Draper Catalogue": 20407,
    "Gliese Catalog": "Gl 132",
    "Distance in Parsecs": 24.1896,
    "mag": 6.75,
    "Absolute Visual Magnitude": 4.832,
    "Spectral Type": "G3V",
    "Color Index": 0.586,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.140358,
    "y": 12.715058,
    "z": -17.301971,
    "vx": 0.0000214,
    "vy": 8.4e-7,
    "vz": 0.00000868,
    "rarad": 0.851315100646119,
    "decrad": -0.797001512310077,
    "pmrarad": -6.42669014944444e-7,
    "pmdecrad": 6.86593134e-7,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 15093,
    "lum": 1.01671680046637,
    "ra": 3.251784,
    "dec": -45.664823,
    "pmra": -132.56,
    "pmdec": 141.62,
    "rv": 4,
    "Asset Name": "ASTRID 002848",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Firm",
    "Hair": "Unkempt",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": 0.0000214,
    "y_1": 8.4e-7,
    "z_1": 0.00000868,
    "Distance in Parsecs_1": 24.1896,
    "HYG Star ID_1": 15093,
    "Hipparcos Catalogue_1": 15131,
    "Henry Draper Catalogue_1": 20407,
    "Gliese Catalog_1": "Gl 132"
  },
  {
    "Astrid #": "ASTRID 002849",
    "HYG Star ID": 62828,
    "Hipparcos Catalogue": 63028,
    "Henry Draper Catalogue": 112311,
    "Distance in Parsecs": 24.1955,
    "mag": 9.85,
    "Absolute Visual Magnitude": 7.931,
    "Spectral Type": "G5",
    "Color Index": 1.169,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.398801,
    "y": -3.03134,
    "z": 20.55475,
    "vx": 6.2e-7,
    "vy": -5.1e-7,
    "vz": -0.00001415,
    "rarad": 3.38137530361973,
    "decrad": 1.01509672221342,
    "pmrarad": 2.64708269583333e-8,
    "pmdecrad": -2.91518466e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 62828,
    "lum": 0.0585598559849287,
    "ra": 12.915902,
    "dec": 58.160758,
    "pmra": 5.46,
    "pmdec": -60.13,
    "rv": -12,
    "Asset Name": "ASTRID 002849",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Angelic",
    "Outfit": "Samurai",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 6.2e-7,
    "y_1": -5.1e-7,
    "z_1": -0.00001415,
    "Distance in Parsecs_1": 24.1955,
    "HYG Star ID_1": 62828,
    "Hipparcos Catalogue_1": 63028,
    "Henry Draper Catalogue_1": 112311
  },
  {
    "Astrid #": "ASTRID 002850",
    "HYG Star ID": 72161,
    "Hipparcos Catalogue": 72387,
    "Gliese Catalog": "Gl 563.1",
    "Distance in Parsecs": 24.2014,
    "mag": 9.73,
    "Absolute Visual Magnitude": 7.811,
    "Spectral Type": "M2",
    "Color Index": 1.308,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.080531,
    "y": -12.680812,
    "z": 15.054487,
    "vx": -0.00000407,
    "vy": 0.00000665,
    "vz": -0.0000204,
    "rarad": 3.87473542547162,
    "decrad": 0.671362489330913,
    "pmrarad": -3.16631814770833e-7,
    "pmdecrad": -6.23276467e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72161,
    "lum": 0.0654033509448894,
    "var_min": 9.784,
    "var_max": 9.664,
    "ra": 14.800399,
    "dec": 38.466237,
    "pmra": -65.31,
    "pmdec": -128.56,
    "rv": -13.5,
    "Asset Name": "ASTRID 002850",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Stunned",
    "Hair": "Frizzy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000407,
    "y_1": 0.00000665,
    "z_1": -0.0000204,
    "Distance in Parsecs_1": 24.2014,
    "HYG Star ID_1": 72161,
    "Hipparcos Catalogue_1": 72387,
    "Gliese Catalog_1": "Gl 563.1"
  },
  {
    "Astrid #": "ASTRID 002851",
    "HYG Star ID": 74045,
    "Hipparcos Catalogue": 74273,
    "Henry Draper Catalogue": 134060,
    "Harvard Revised Catalogue": 5632,
    "Distance in Parsecs": 24.2014,
    "mag": 6.29,
    "Absolute Visual Magnitude": 4.371,
    "Spectral Type": "G3IV",
    "Color Index": 0.623,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.793424,
    "y": -8.560788,
    "z": -21.253227,
    "vx": -0.00002778,
    "vy": 0.00000172,
    "vz": -0.00003476,
    "rarad": 3.9738864651996,
    "decrad": -1.07202171808688,
    "pmrarad": -8.96662902187499e-7,
    "pmdecrad": -5.4638501e-8,
    "con": "Cir",
    "Full Constellation Name Formula 1": "Circinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Circinus",
    "comp": 1,
    "comp_primary": 74045,
    "lum": 1.55453319406078,
    "ra": 15.179128,
    "dec": -61.42232,
    "pmra": -184.95,
    "pmdec": -11.27,
    "rv": 38,
    "Asset Name": "ASTRID 002851",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Bangs",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Circinus",
    "x_1": -0.00002778,
    "y_1": 0.00000172,
    "z_1": -0.00003476,
    "Distance in Parsecs_1": 24.2014,
    "HYG Star ID_1": 74045,
    "Hipparcos Catalogue_1": 74273,
    "Henry Draper Catalogue_1": 134060,
    "Harvard Revised Catalogue_1": 5632
  },
  {
    "Astrid #": "ASTRID 002852",
    "HYG Star ID": 92567,
    "Hipparcos Catalogue": 92858,
    "Henry Draper Catalogue": 175073,
    "Gliese Catalog": "NN 4087",
    "Distance in Parsecs": 24.2072,
    "mag": 7.98,
    "Absolute Visual Magnitude": 6.06,
    "Spectral Type": "K1V",
    "Color Index": 0.857,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.59029,
    "y": -18.648474,
    "z": -14.735723,
    "vx": 0.0000053,
    "vy": 0.00004814,
    "vz": -0.00001795,
    "rarad": 4.9537376962647,
    "decrad": -0.654469702972638,
    "pmrarad": 6.88144538180555e-7,
    "pmdecrad": -0.000001731996873,
    "con": "CrA",
    "Full Constellation Name Formula 1": "Corona Australis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Australis",
    "comp": 1,
    "comp_primary": 92567,
    "lum": 0.328095293113119,
    "ra": 18.921884,
    "dec": -37.498352,
    "pmra": 141.94,
    "pmdec": -357.25,
    "rv": -24.6,
    "Asset Name": "ASTRID 002852",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Greasy",
    "Outfit": "Tats",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Corona Australis",
    "x_1": 0.0000053,
    "y_1": 0.00004814,
    "z_1": -0.00001795,
    "Distance in Parsecs_1": 24.2072,
    "HYG Star ID_1": 92567,
    "Hipparcos Catalogue_1": 92858,
    "Henry Draper Catalogue_1": 175073,
    "Gliese Catalog_1": "NN 4087"
  },
  {
    "Astrid #": "ASTRID 002853",
    "HYG Star ID": 87513,
    "Hipparcos Catalogue": 87784,
    "Distance in Parsecs": 24.2131,
    "mag": 10.97,
    "Absolute Visual Magnitude": 9.05,
    "Color Index": 1.015,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -0.143751,
    "y": -8.070415,
    "z": -22.828096,
    "vx": 6.5e-7,
    "vy": 0.00000321,
    "vz": -0.00000114,
    "rarad": 4.69457828565125,
    "decrad": -1.23093044930525,
    "pmrarad": 2.45315722361111e-8,
    "pmdecrad": -1.41177743e-7,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 87513,
    "lum": 0.0208929613085403,
    "ra": 17.931968,
    "dec": -70.52712,
    "pmra": 5.06,
    "pmdec": -29.12,
    "rv": 0,
    "Asset Name": "ASTRID 002853",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Side Part",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": 6.5e-7,
    "y_1": 0.00000321,
    "z_1": -0.00000114,
    "Distance in Parsecs_1": 24.2131,
    "HYG Star ID_1": 87513,
    "Hipparcos Catalogue_1": 87784
  },
  {
    "Astrid #": "ASTRID 002854",
    "HYG Star ID": 14250,
    "Hipparcos Catalogue": 14286,
    "Henry Draper Catalogue": 18757,
    "Gliese Catalog": "NN 3194A",
    "Distance in Parsecs": 24.2307,
    "mag": 6.64,
    "Absolute Visual Magnitude": 4.718,
    "Spectral Type": "G4V",
    "Color Index": 0.634,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.972592,
    "y": 8.267447,
    "z": 21.335781,
    "vx": -0.00000989,
    "vy": 0.00011174,
    "vz": -0.00003518,
    "rarad": 0.803518292531496,
    "decrad": 1.07696990371775,
    "pmrarad": 0.00000349560359954166,
    "pmdecrad": -0.000003361746542,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 14250,
    "base": "NN 3194",
    "lum": 1.12927574420941,
    "ra": 3.069214,
    "dec": 61.70583,
    "pmra": 721.02,
    "pmdec": -693.41,
    "rv": 3.8,
    "Asset Name": "ASTRID 002854",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Ninja",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000989,
    "y_1": 0.00011174,
    "z_1": -0.00003518,
    "Distance in Parsecs_1": 24.2307,
    "HYG Star ID_1": 14250,
    "Hipparcos Catalogue_1": 14286,
    "Henry Draper Catalogue_1": 18757,
    "Gliese Catalog_1": "NN 3194A"
  },
  {
    "Astrid #": "ASTRID 002855",
    "HYG Star ID": 74033,
    "Hipparcos Catalogue": 74261,
    "Distance in Parsecs": 24.2365,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.708,
    "Spectral Type": "M0",
    "Color Index": 1.406,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.945638,
    "y": -13.104533,
    "z": -16.522132,
    "vx": -0.00001247,
    "vy": 0.00004295,
    "vz": -0.00002505,
    "rarad": 3.9732258414131,
    "decrad": -0.750089930839966,
    "pmrarad": -0.00000157423850213194,
    "pmdecrad": -0.000001412553139,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 74033,
    "lum": 0.0286285927087212,
    "var_min": 10.685,
    "var_max": 10.575,
    "ra": 15.176605,
    "dec": -42.976987,
    "pmra": -324.71,
    "pmdec": -291.36,
    "rv": 0,
    "Asset Name": "ASTRID 002855",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Charmer",
    "Hair": "Unkempt",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00001247,
    "y_1": 0.00004295,
    "z_1": -0.00002505,
    "Distance in Parsecs_1": 24.2365,
    "HYG Star ID_1": 74033,
    "Hipparcos Catalogue_1": 74261
  },
  {
    "Astrid #": "ASTRID 002856",
    "HYG Star ID": 101770,
    "Hipparcos Catalogue": 102101,
    "Gliese Catalog": "NN 4156",
    "Distance in Parsecs": 24.2365,
    "mag": 10.27,
    "Absolute Visual Magnitude": 8.348,
    "Spectral Type": "M0",
    "Color Index": 1.36,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.450472,
    "y": -9.940748,
    "z": 20.424706,
    "vx": -0.00001313,
    "vy": 0.00003308,
    "vz": -0.00000759,
    "rarad": 5.41692836691614,
    "decrad": 1.002339109612,
    "pmrarad": 4.71190416131944e-7,
    "pmdecrad": 0.0000010036128,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 101770,
    "lum": 0.0398841186909052,
    "ra": 20.691142,
    "dec": 57.429801,
    "pmra": 97.19,
    "pmdec": 207.01,
    "rv": -24,
    "Asset Name": "ASTRID 002856",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Mohawk",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001313,
    "y_1": 0.00003308,
    "z_1": -0.00000759,
    "Distance in Parsecs_1": 24.2365,
    "HYG Star ID_1": 101770,
    "Hipparcos Catalogue_1": 102101,
    "Gliese Catalog_1": "NN 4156"
  },
  {
    "Astrid #": "ASTRID 002857",
    "HYG Star ID": 46416,
    "Hipparcos Catalogue": 46549,
    "Gliese Catalog": "Wo 9299",
    "Distance in Parsecs": 24.2483,
    "mag": 9.74,
    "Absolute Visual Magnitude": 7.817,
    "Spectral Type": "M0:",
    "Color Index": 1.305,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.126259,
    "y": 14.731302,
    "z": -2.270465,
    "vx": 0.00003419,
    "vy": 0.00004488,
    "vz": 0.00000315,
    "rarad": 2.48528003394647,
    "decrad": -0.0937713491729562,
    "pmrarad": -0.00000232686325982638,
    "pmdecrad": 1.30608805e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46416,
    "lum": 0.06504291550788,
    "ra": 9.49307,
    "dec": -5.372703,
    "pmra": -479.95,
    "pmdec": 26.94,
    "rv": 0,
    "Asset Name": "ASTRID 002857",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Medium Bob",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00003419,
    "y_1": 0.00004488,
    "z_1": 0.00000315,
    "Distance in Parsecs_1": 24.2483,
    "HYG Star ID_1": 46416,
    "Hipparcos Catalogue_1": 46549,
    "Gliese Catalog_1": "Wo 9299"
  },
  {
    "Astrid #": "ASTRID 002858",
    "HYG Star ID": 40795,
    "Hipparcos Catalogue": 40910,
    "Gliese Catalog": "NN 3494",
    "Distance in Parsecs": 24.2601,
    "mag": 9.75,
    "Absolute Visual Magnitude": 7.826,
    "Spectral Type": "M0",
    "Color Index": 1.279,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.575014,
    "y": 19.222046,
    "z": 5.898384,
    "vx": -0.00000514,
    "vy": 0.00002376,
    "vz": -0.00002615,
    "rarad": 2.18568543067043,
    "decrad": 0.245591079767435,
    "pmrarad": -3.91923379361111e-7,
    "pmdecrad": -0.000001269969436,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 40795,
    "lum": 0.0645059833284831,
    "ra": 8.348703,
    "dec": 14.071332,
    "pmra": -80.84,
    "pmdec": -261.95,
    "rv": 15,
    "Asset Name": "ASTRID 002858",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Heartbreaker",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00000514,
    "y_1": 0.00002376,
    "z_1": -0.00002615,
    "Distance in Parsecs_1": 24.2601,
    "HYG Star ID_1": 40795,
    "Hipparcos Catalogue_1": 40910,
    "Gliese Catalog_1": "NN 3494"
  },
  {
    "Astrid #": "ASTRID 002859",
    "HYG Star ID": 117518,
    "Hipparcos Catalogue": 117886,
    "Gliese Catalog": "Gl 911",
    "Distance in Parsecs": 24.2601,
    "mag": 10.8,
    "Absolute Visual Magnitude": 8.876,
    "Spectral Type": "M0",
    "Color Index": 1.431,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.523453,
    "y": -0.513908,
    "z": -8.999458,
    "vx": 0.00002258,
    "vy": -0.00000556,
    "vz": 0.0000072,
    "rarad": 6.26037282728222,
    "decrad": -0.38003685472461,
    "pmrarad": -2.08082031694444e-7,
    "pmdecrad": 6.22888616e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 117518,
    "lum": 0.0245244908607201,
    "var_min": 10.863,
    "var_max": 10.693,
    "ra": 23.912863,
    "dec": -21.774508,
    "pmra": -42.92,
    "pmdec": 128.48,
    "rv": 18,
    "Asset Name": "ASTRID 002859",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Bangs",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00002258,
    "y_1": -0.00000556,
    "z_1": 0.0000072,
    "Distance in Parsecs_1": 24.2601,
    "HYG Star ID_1": 117518,
    "Hipparcos Catalogue_1": 117886,
    "Gliese Catalog_1": "Gl 911"
  },
  {
    "Astrid #": "ASTRID 002860",
    "HYG Star ID": 118656,
    "Gliese Catalog": "NN 3575",
    "Distance in Parsecs": 24.2718,
    "mag": 15.09,
    "Absolute Visual Magnitude": 13.164,
    "Spectral Type": "DA6",
    "Color Index": 0.25,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -19.018566,
    "y": 11.223778,
    "z": 10.087076,
    "vx": -0.00009694,
    "vy": 0.00009145,
    "vz": 0.00002072,
    "rarad": 2.60845441702921,
    "decrad": 0.42847014725965,
    "pmrarad": -0.00000121537941578472,
    "pmdecrad": -0.000001448414807,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118656,
    "lum": 0.000472498027680194,
    "ra": 9.963562,
    "dec": 24.549531,
    "pmra": -250.69,
    "pmdec": -298.76,
    "rv": 124,
    "Asset Name": "ASTRID 002860",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Awkward",
    "Hair": "Afro",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00009694,
    "y_1": 0.00009145,
    "z_1": 0.00002072,
    "Distance in Parsecs_1": 24.2718,
    "HYG Star ID_1": 118656,
    "Gliese Catalog_1": "NN 3575"
  },
  {
    "Astrid #": "ASTRID 002861",
    "HYG Star ID": 24814,
    "Hipparcos Catalogue": 24874,
    "Henry Draper Catalogue": 34865,
    "Gliese Catalog": "NN 3346",
    "Distance in Parsecs": 24.3013,
    "mag": 8.72,
    "Absolute Visual Magnitude": 6.792,
    "Spectral Type": "K3V",
    "Color Index": 1.003,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.060354,
    "y": 23.023266,
    "z": -6.632942,
    "vx": -0.00001904,
    "vy": 0.00001004,
    "vz": 0.00002318,
    "rarad": 1.39623231289459,
    "decrad": -0.276453957016783,
    "pmrarad": 8.43187953222222e-7,
    "pmdecrad": 9.91540939e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 24814,
    "lum": 0.167186035720446,
    "ra": 5.333215,
    "dec": -15.839645,
    "pmra": 173.92,
    "pmdec": 204.52,
    "rv": 0,
    "Asset Name": "ASTRID 002861",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Malevolent",
    "Hair": "Curly",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.00001904,
    "y_1": 0.00001004,
    "z_1": 0.00002318,
    "Distance in Parsecs_1": 24.3013,
    "HYG Star ID_1": 24814,
    "Hipparcos Catalogue_1": 24874,
    "Henry Draper Catalogue_1": 34865,
    "Gliese Catalog_1": "NN 3346"
  },
  {
    "Astrid #": "ASTRID 002862",
    "HYG Star ID": 114095,
    "Hipparcos Catalogue": 114456,
    "Henry Draper Catalogue": 218868,
    "Distance in Parsecs": 24.3013,
    "mag": 6.98,
    "Absolute Visual Magnitude": 5.052,
    "Spectral Type": "K0",
    "Color Index": 0.75,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.638751,
    "y": -3.6252,
    "z": 17.33636,
    "vx": -7.2e-7,
    "vy": -0.00001031,
    "vz": -0.00004663,
    "rarad": 6.06866250414324,
    "decrad": 0.794339027144957,
    "pmrarad": -4.21012200194444e-7,
    "pmdecrad": -0.000001388554862,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114095,
    "lum": 0.830233015924953,
    "ra": 23.180583,
    "dec": 45.512274,
    "pmra": -86.84,
    "pmdec": -286.41,
    "rv": -31.5,
    "Asset Name": "ASTRID 002862",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Spiky",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -7.2e-7,
    "y_1": -0.00001031,
    "z_1": -0.00004663,
    "Distance in Parsecs_1": 24.3013,
    "HYG Star ID_1": 114095,
    "Hipparcos Catalogue_1": 114456,
    "Henry Draper Catalogue_1": 218868
  },
  {
    "Astrid #": "ASTRID 002863",
    "HYG Star ID": 49528,
    "Hipparcos Catalogue": 49669,
    "Henry Draper Catalogue": 87901,
    "Harvard Revised Catalogue": 3982,
    "Proper Name": "Regulus",
    "Distance in Parsecs": 24.3132,
    "mag": 1.36,
    "Absolute Visual Magnitude": -0.569,
    "Spectral Type": "B7V",
    "Color Index": -0.087,
    "Spectral Abbreviation": "B",
    "Star Type": "Teal",
    "Color In Star Map": "Teal",
    "x": -21.018857,
    "y": 11.132218,
    "z": 5.041397,
    "vx": 0.00000856,
    "vy": 0.00002873,
    "vz": 0.00000184,
    "rarad": 2.65452317215345,
    "decrad": 0.208867165288071,
    "pmrarad": -0.00000120912531930555,
    "pmdecrad": 2.3804351e-8,
    "bayer": "Alp",
    "flam": 32,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 49528,
    "lum": 147.095707679697,
    "bf": "32Alp Leo",
    "ra": 10.139532,
    "dec": 11.967207,
    "pmra": -249.4,
    "pmdec": 4.91,
    "rv": 6,
    "Asset Name": "ASTRID 002863",
    "Astrium Color": "Teal",
    "Background": "Hazy Void",
    "Expression": "Gimme Blood",
    "Hair": "Male Elf",
    "Outfit": "Elven",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Regulus",
    "Constellation": "Leo",
    "x_1": 0.00000856,
    "y_1": 0.00002873,
    "z_1": 0.00000184,
    "Distance in Parsecs_1": 24.3132,
    "HYG Star ID_1": 49528,
    "Hipparcos Catalogue_1": 49669,
    "Henry Draper Catalogue_1": 87901,
    "Harvard Revised Catalogue_1": 3982
  },
  {
    "Astrid #": "ASTRID 002864",
    "HYG Star ID": 118916,
    "Gliese Catalog": "NN 3803A",
    "Distance in Parsecs": 24.3309,
    "mag": 12.3,
    "Absolute Visual Magnitude": 10.369,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.545077,
    "y": -6.640244,
    "z": 19.090069,
    "vx": -0.00003951,
    "vy": 0.00007782,
    "vz": -9.4e-7,
    "rarad": 3.59750606760294,
    "decrad": 0.9020536885581,
    "pmrarad": -0.00000358708794106249,
    "pmdecrad": -6.2608838e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118916,
    "base": "NN 3803",
    "lum": 0.00620011864208724,
    "ra": 13.741461,
    "dec": 51.683869,
    "pmra": -739.89,
    "pmdec": -12.91,
    "rv": 0,
    "Asset Name": "ASTRID 002864",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Horns",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003951,
    "y_1": 0.00007782,
    "z_1": -9.4e-7,
    "Distance in Parsecs_1": 24.3309,
    "HYG Star ID_1": 118916,
    "Gliese Catalog_1": "NN 3803A"
  },
  {
    "Astrid #": "ASTRID 002865",
    "HYG Star ID": 119058,
    "Henry Draper Catalogue": 144070,
    "Harvard Revised Catalogue": 5978,
    "Gliese Catalog": "Wo 9540A",
    "Distance in Parsecs": 24.3309,
    "mag": 4.84,
    "Absolute Visual Magnitude": 2.909,
    "Spectral Type": "F6 IV-V",
    "Color Index": 0.45,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.530023,
    "y": -20.879358,
    "z": -4.798287,
    "vx": 0.00000962,
    "vy": 0.00003245,
    "vz": 0.00000266,
    "rarad": 4.20784235746643,
    "decrad": -0.1985244135134,
    "pmrarad": -2.98790671326388e-7,
    "pmdecrad": -1.60890268e-7,
    "bayer": "Xi",
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 119058,
    "base": "Wo 9540",
    "lum": 5.97585429715572,
    "bf": "Xi  Sco",
    "ra": 16.072774,
    "dec": -11.374611,
    "pmra": -61.63,
    "pmdec": -33.19,
    "rv": -32.2,
    "Asset Name": "ASTRID 002865",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Heartbreaker",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000962,
    "y_1": 0.00003245,
    "z_1": 0.00000266,
    "Distance in Parsecs_1": 24.3309,
    "HYG Star ID_1": 119058,
    "Henry Draper Catalogue_1": 144070,
    "Harvard Revised Catalogue_1": 5978,
    "Gliese Catalog_1": "Wo 9540A"
  },
  {
    "Astrid #": "ASTRID 002866",
    "HYG Star ID": 119059,
    "Henry Draper Catalogue": 144069,
    "Harvard Revised Catalogue": 5977,
    "Gliese Catalog": "Wo 9540B",
    "Distance in Parsecs": 24.3309,
    "mag": 5,
    "Absolute Visual Magnitude": 3.069,
    "Spectral Type": "F6 IV-V",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.530091,
    "y": -20.87948,
    "z": -4.798316,
    "vx": 0.00000826,
    "vy": 0.00002999,
    "vz": 0.00000209,
    "rarad": 4.20784235746643,
    "decrad": -0.1985244135134,
    "pmrarad": -2.98790671326388e-7,
    "pmdecrad": -1.60890268e-7,
    "bayer": "Xi",
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 2,
    "comp_primary": 119058,
    "base": "Wo 9540",
    "lum": 5.15703406302645,
    "bf": "Xi  Sco",
    "ra": 16.072774,
    "dec": -11.374611,
    "pmra": -61.63,
    "pmdec": -33.19,
    "rv": -29.4,
    "Asset Name": "ASTRID 002866",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Braided Ponytail",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000826,
    "y_1": 0.00002999,
    "z_1": 0.00000209,
    "Distance in Parsecs_1": 24.3309,
    "HYG Star ID_1": 119059,
    "Henry Draper Catalogue_1": 144069,
    "Harvard Revised Catalogue_1": 5977,
    "Gliese Catalog_1": "Wo 9540B"
  },
  {
    "Astrid #": "ASTRID 002867",
    "HYG Star ID": 119060,
    "Gliese Catalog": "Wo 9540C",
    "Distance in Parsecs": 24.3309,
    "mag": 7.3,
    "Absolute Visual Magnitude": 5.369,
    "Spectral Type": "G8 V",
    "Color Index": 0.75,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.530152,
    "y": -20.879516,
    "z": -4.79752,
    "vx": 0.00000843,
    "vy": 0.0000317,
    "vz": 0.00000412,
    "rarad": 4.20784083420303,
    "decrad": -0.198491871670349,
    "pmrarad": -3.26715939326388e-7,
    "pmdecrad": -9.1833407e-8,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 3,
    "comp_primary": 119058,
    "base": "Wo 9540",
    "lum": 0.620011864208724,
    "ra": 16.072768,
    "dec": -11.372747,
    "pmra": -67.39,
    "pmdec": -18.94,
    "rv": -31.3,
    "Asset Name": "ASTRID 002867",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Hurt",
    "Hair": "Bangs",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000843,
    "y_1": 0.0000317,
    "z_1": 0.00000412,
    "Distance in Parsecs_1": 24.3309,
    "HYG Star ID_1": 119060,
    "Gliese Catalog_1": "Wo 9540C"
  },
  {
    "Astrid #": "ASTRID 002868",
    "HYG Star ID": 119502,
    "Gliese Catalog": "Wo 9782B",
    "Distance in Parsecs": 24.3309,
    "mag": 11.7,
    "Absolute Visual Magnitude": 9.769,
    "Spectral Type": "K4:",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 22.273746,
    "y": -9.612433,
    "z": 1.863882,
    "vx": 0.00001329,
    "vy": 0.0000321,
    "vz": 0.00000673,
    "rarad": 5.87577215239039,
    "decrad": 0.07668065185235,
    "pmrarad": 0.00000142772780786805,
    "pmdecrad": 2.77517047e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 2,
    "comp_primary": 110439,
    "base": "Wo 9782",
    "lum": 0.0107745713145636,
    "ra": 22.443796,
    "dec": 4.393478,
    "pmra": 294.49,
    "pmdec": 57.24,
    "rv": 0,
    "Asset Name": "ASTRID 002868",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Braided Bun",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001329,
    "y_1": 0.0000321,
    "z_1": 0.00000673,
    "Distance in Parsecs_1": 24.3309,
    "HYG Star ID_1": 119502,
    "Gliese Catalog_1": "Wo 9782B"
  },
  {
    "Astrid #": "ASTRID 002869",
    "HYG Star ID": 57996,
    "Hipparcos Catalogue": 58170,
    "Distance in Parsecs": 24.3368,
    "mag": 12.01,
    "Absolute Visual Magnitude": 10.079,
    "Color Index": 1.477,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.100935,
    "y": 0.348479,
    "z": -15.076892,
    "vx": 0.0000202,
    "vy": -0.00007358,
    "vz": -0.00002729,
    "rarad": 3.12334339995907,
    "decrad": -0.668118397678338,
    "pmrarad": 0.00000300788103690277,
    "pmdecrad": -0.000001428648953,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 57996,
    "lum": 0.00809841447325787,
    "var_min": 12.099,
    "var_max": 11.909,
    "ra": 11.930293,
    "dec": -38.280364,
    "pmra": 620.42,
    "pmdec": -294.68,
    "rv": 0,
    "Asset Name": "ASTRID 002869",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Slick",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000202,
    "y_1": -0.00007358,
    "z_1": -0.00002729,
    "Distance in Parsecs_1": 24.3368,
    "HYG Star ID_1": 57996,
    "Hipparcos Catalogue_1": 58170
  },
  {
    "Astrid #": "ASTRID 002870",
    "HYG Star ID": 18072,
    "Hipparcos Catalogue": 18115,
    "Distance in Parsecs": 24.3487,
    "mag": 11.44,
    "Absolute Visual Magnitude": 9.508,
    "Color Index": 1.523,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 11.855377,
    "y": 19.043892,
    "z": -9.467807,
    "vx": -0.00003911,
    "vy": 0.00003502,
    "vz": 0.00002147,
    "rarad": 1.01397422815063,
    "decrad": -0.399374808055706,
    "pmrarad": 0.00000212401721588194,
    "pmdecrad": 9.57022205e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 18072,
    "lum": 0.0137025059712424,
    "var_min": 11.551,
    "var_max": 11.321,
    "ra": 3.873096,
    "dec": -22.882491,
    "pmra": 438.11,
    "pmdec": 197.4,
    "rv": 0,
    "Asset Name": "ASTRID 002870",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Greasy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00003911,
    "y_1": 0.00003502,
    "z_1": 0.00002147,
    "Distance in Parsecs_1": 24.3487,
    "HYG Star ID_1": 18072,
    "Hipparcos Catalogue_1": 18115
  },
  {
    "Astrid #": "ASTRID 002871",
    "HYG Star ID": 10697,
    "Hipparcos Catalogue": 10723,
    "Henry Draper Catalogue": 14214,
    "Harvard Revised Catalogue": 672,
    "Distance in Parsecs": 24.3546,
    "mag": 5.6,
    "Absolute Visual Magnitude": 3.667,
    "Spectral Type": "G0.5IV",
    "Color Index": 0.588,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.060574,
    "y": 13.790344,
    "z": 0.747076,
    "vx": -0.00000284,
    "vy": 0.00005048,
    "vz": 0.00004465,
    "rarad": 0.602243292733907,
    "decrad": 0.03067943005149,
    "pmrarad": 0.00000177436958946527,
    "pmdecrad": 0.000001799434456,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10697,
    "lum": 2.97303484967702,
    "ra": 2.3004,
    "dec": 1.757802,
    "pmra": 365.99,
    "pmdec": 371.16,
    "rv": 27,
    "Asset Name": "ASTRID 002871",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Goddess",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000284,
    "y_1": 0.00005048,
    "z_1": 0.00004465,
    "Distance in Parsecs_1": 24.3546,
    "HYG Star ID_1": 10697,
    "Hipparcos Catalogue_1": 10723,
    "Henry Draper Catalogue_1": 14214,
    "Harvard Revised Catalogue_1": 672
  },
  {
    "Astrid #": "ASTRID 002872",
    "HYG Star ID": 87308,
    "Hipparcos Catalogue": 87579,
    "Gliese Catalog": "Gl 697",
    "Distance in Parsecs": 24.3546,
    "mag": 8.5,
    "Absolute Visual Magnitude": 6.567,
    "Spectral Type": "K0",
    "Color Index": 0.94,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.643456,
    "y": -22.677829,
    "z": 8.856814,
    "vx": -0.00000803,
    "vy": 0.0000146,
    "vz": 0.00000166,
    "rarad": 4.68402299588441,
    "decrad": 0.372196553431435,
    "pmrarad": -3.46641781597222e-7,
    "pmdecrad": 2.77992164e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 87308,
    "lum": 0.205683758662788,
    "ra": 17.89165,
    "dec": 21.325292,
    "pmra": -71.5,
    "pmdec": 57.34,
    "rv": -12.5,
    "Asset Name": "ASTRID 002872",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Little Crown",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000803,
    "y_1": 0.0000146,
    "z_1": 0.00000166,
    "Distance in Parsecs_1": 24.3546,
    "HYG Star ID_1": 87308,
    "Hipparcos Catalogue_1": 87579,
    "Gliese Catalog_1": "Gl 697"
  },
  {
    "Astrid #": "ASTRID 002873",
    "HYG Star ID": 119071,
    "Gliese Catalog": "Gl 615.1B",
    "Distance in Parsecs": 24.3605,
    "mag": 7.5,
    "Absolute Visual Magnitude": 5.567,
    "Spectral Type": "G8 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.63351,
    "y": -21.164717,
    "z": 5.698159,
    "vx": 0.00000437,
    "vy": -0.00003812,
    "vz": -0.00004247,
    "rarad": 4.24680852941757,
    "decrad": 0.23608655225295,
    "pmrarad": 8.62968351388888e-7,
    "pmdecrad": -0.000002003734941,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 79252,
    "base": "Gl 615.1",
    "lum": 0.516654242566949,
    "ra": 16.221614,
    "dec": 13.526763,
    "pmra": 178,
    "pmdec": -413.3,
    "rv": 20.8,
    "Asset Name": "ASTRID 002873",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Wavy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000437,
    "y_1": -0.00003812,
    "z_1": -0.00004247,
    "Distance in Parsecs_1": 24.3605,
    "HYG Star ID_1": 119071,
    "Gliese Catalog_1": "Gl 615.1B"
  },
  {
    "Astrid #": "ASTRID 002874",
    "HYG Star ID": 32280,
    "Hipparcos Catalogue": 32366,
    "Henry Draper Catalogue": 49095,
    "Harvard Revised Catalogue": 2500,
    "Gliese Catalog": "Gl 245.1",
    "Distance in Parsecs": 24.3784,
    "mag": 5.92,
    "Absolute Visual Magnitude": 3.985,
    "Spectral Type": "F7V",
    "Color Index": 0.491,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.076302,
    "y": 20.315775,
    "z": -12.844197,
    "vx": 0.000022,
    "vy": 0.00001347,
    "vz": -0.00004779,
    "rarad": 1.76881561847251,
    "decrad": -0.554904063558652,
    "pmrarad": -9.93431712826389e-7,
    "pmdecrad": -0.000001474463846,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 32280,
    "lum": 2.21819641980021,
    "ra": 6.756378,
    "dec": -31.793661,
    "pmra": -204.91,
    "pmdec": -304.13,
    "rv": 32,
    "Asset Name": "ASTRID 002874",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Neko",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.000022,
    "y_1": 0.00001347,
    "z_1": -0.00004779,
    "Distance in Parsecs_1": 24.3784,
    "HYG Star ID_1": 32280,
    "Hipparcos Catalogue_1": 32366,
    "Henry Draper Catalogue_1": 49095,
    "Harvard Revised Catalogue_1": 2500,
    "Gliese Catalog_1": "Gl 245.1"
  },
  {
    "Astrid #": "ASTRID 002875",
    "HYG Star ID": 118054,
    "Henry Draper Catalogue": 7438,
    "Gliese Catalog": "Gl 54.2B",
    "Distance in Parsecs": 24.3843,
    "mag": 7.85,
    "Absolute Visual Magnitude": 5.914,
    "Spectral Type": "K1 V",
    "Color Index": 0.78,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 22.892911,
    "y": 7.700849,
    "z": -3.355702,
    "vx": 0.00002062,
    "vy": 0.00002327,
    "vz": 0.00002978,
    "rarad": 0.324494929720144,
    "decrad": -0.1380489905862,
    "pmrarad": 6.35154402895833e-7,
    "pmdecrad": 0.000001362108276,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 5786,
    "base": "Gl  54.2",
    "lum": 0.375318524237913,
    "ra": 1.239479,
    "dec": -7.909625,
    "pmra": 131.01,
    "pmdec": 280.95,
    "rv": 22.1,
    "Asset Name": "ASTRID 002875",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Sweetheart",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00002062,
    "y_1": 0.00002327,
    "z_1": 0.00002978,
    "Distance in Parsecs_1": 24.3843,
    "HYG Star ID_1": 118054,
    "Henry Draper Catalogue_1": 7438,
    "Gliese Catalog_1": "Gl 54.2B"
  },
  {
    "Astrid #": "ASTRID 002876",
    "HYG Star ID": 13942,
    "Hipparcos Catalogue": 13976,
    "Henry Draper Catalogue": 18632,
    "Distance in Parsecs": 24.3902,
    "mag": 7.97,
    "Absolute Visual Magnitude": 6.034,
    "Spectral Type": "G5",
    "Color Index": 0.926,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.085459,
    "y": 17.09245,
    "z": 3.288936,
    "vx": -0.0000278,
    "vy": 0.00002735,
    "vz": 0.0000023,
    "rarad": 0.785602598086574,
    "decrad": 0.135258681424937,
    "pmrarad": 0.00000159886703710416,
    "pmdecrad": 9.536285e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 13942,
    "lum": 0.336046982460699,
    "var": "BZ",
    "var_min": 7.987,
    "var_max": 7.947,
    "ra": 3.000781,
    "dec": 7.749752,
    "pmra": 329.79,
    "pmdec": 19.67,
    "rv": 0,
    "Asset Name": "ASTRID 002876",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Medium Bob",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.0000278,
    "y_1": 0.00002735,
    "z_1": 0.0000023,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 13942,
    "Hipparcos Catalogue_1": 13976,
    "Henry Draper Catalogue_1": 18632
  },
  {
    "Astrid #": "ASTRID 002877",
    "HYG Star ID": 77672,
    "Hipparcos Catalogue": 77908,
    "Henry Draper Catalogue": 142288,
    "Gliese Catalog": "GJ 1196",
    "Distance in Parsecs": 24.3902,
    "mag": 9.2,
    "Absolute Visual Magnitude": 7.264,
    "Spectral Type": "K5V",
    "Color Index": 1.234,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.401404,
    "y": -18.722649,
    "z": -10.693561,
    "vx": -0.00002582,
    "vy": 0.00000972,
    "vz": 0.00001052,
    "rarad": 4.16540603581951,
    "decrad": -0.453858652149916,
    "pmrarad": -0.00000111167776951388,
    "pmdecrad": 4.79868581e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 77672,
    "lum": 0.108243044760913,
    "ra": 15.910679,
    "dec": -26.004185,
    "pmra": -229.3,
    "pmdec": 98.98,
    "rv": 0,
    "Asset Name": "ASTRID 002877",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Audiohead",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00002582,
    "y_1": 0.00000972,
    "z_1": 0.00001052,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 77672,
    "Hipparcos Catalogue_1": 77908,
    "Henry Draper Catalogue_1": 142288,
    "Gliese Catalog_1": "GJ 1196"
  },
  {
    "Astrid #": "ASTRID 002878",
    "HYG Star ID": 118062,
    "Gliese Catalog": "NN 3085",
    "Distance in Parsecs": 24.3902,
    "mag": 13.46,
    "Absolute Visual Magnitude": 11.524,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.548702,
    "y": 7.415838,
    "z": -10.846108,
    "vx": 0.00000114,
    "vy": -1.5e-7,
    "vz": 0.00000206,
    "rarad": 0.346344066169218,
    "decrad": -0.460829495217949,
    "pmrarad": -2.18166156249999e-8,
    "pmdecrad": 9.4475641e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118062,
    "lum": 0.00213993213245593,
    "ra": 1.322937,
    "dec": -26.403585,
    "pmra": -4.5,
    "pmdec": 19.49,
    "rv": 0,
    "Asset Name": "ASTRID 002878",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Serious Mad",
    "Hair": "Braid",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000114,
    "y_1": -1.5e-7,
    "z_1": 0.00000206,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118062,
    "Gliese Catalog_1": "NN 3085"
  },
  {
    "Astrid #": "ASTRID 002879",
    "HYG Star ID": 118081,
    "Gliese Catalog": "NN 3104",
    "Distance in Parsecs": 24.3902,
    "mag": 12.83,
    "Absolute Visual Magnitude": 10.894,
    "Spectral Type": "M5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.040337,
    "y": 10.221523,
    "z": 2.150786,
    "vx": -0.0000056,
    "vy": 0.00000876,
    "vz": 0.0000158,
    "rarad": 0.434241145332061,
    "decrad": 0.08829708484665,
    "pmrarad": 4.22466641236111e-7,
    "pmdecrad": 6.50556933e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118081,
    "lum": 0.00382296216565919,
    "ra": 1.658679,
    "dec": 5.05905,
    "pmra": 87.14,
    "pmdec": 134.19,
    "rv": 0,
    "Asset Name": "ASTRID 002879",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Medium Bob",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000056,
    "y_1": 0.00000876,
    "z_1": 0.0000158,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118081,
    "Gliese Catalog_1": "NN 3104"
  },
  {
    "Astrid #": "ASTRID 002880",
    "HYG Star ID": 118097,
    "Gliese Catalog": "NN 3123",
    "Distance in Parsecs": 24.3902,
    "mag": 13.01,
    "Absolute Visual Magnitude": 11.074,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.254344,
    "y": 8.810302,
    "z": 16.869066,
    "vx": 0.00001058,
    "vy": 0.00002169,
    "vz": -0.0000209,
    "rarad": 0.523745577044544,
    "decrad": 0.763747563319,
    "pmrarad": 5.53220890881944e-7,
    "pmdecrad": -0.00000118635362,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118097,
    "lum": 0.00323891834996877,
    "ra": 2.000561,
    "dec": 43.759512,
    "pmra": 114.11,
    "pmdec": -244.7,
    "rv": 0,
    "Asset Name": "ASTRID 002880",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Bob",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001058,
    "y_1": 0.00002169,
    "z_1": -0.0000209,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118097,
    "Gliese Catalog_1": "NN 3123"
  },
  {
    "Astrid #": "ASTRID 002881",
    "HYG Star ID": 118131,
    "Henry Draper Catalogue": 14039,
    "Gliese Catalog": "Gl 92.1",
    "Distance in Parsecs": 24.3902,
    "mag": 8.27,
    "Absolute Visual Magnitude": 6.334,
    "Spectral Type": "K1 V",
    "Color Index": 0.92,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.063263,
    "y": 7.63129,
    "z": 20.353316,
    "vx": -0.00000392,
    "vy": 0.00004591,
    "vz": -0.00001116,
    "rarad": 0.603771598867297,
    "decrad": 0.987191789982,
    "pmrarad": 0.00000164099734594444,
    "pmdecrad": -0.00000103338036,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 118131,
    "lum": 0.254917705050912,
    "ra": 2.306238,
    "dec": 56.561923,
    "pmra": 338.48,
    "pmdec": -213.15,
    "rv": 3.2,
    "Asset Name": "ASTRID 002881",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Infatuated",
    "Hair": "Pixie",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00000392,
    "y_1": 0.00004591,
    "z_1": -0.00001116,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118131,
    "Henry Draper Catalogue_1": 14039,
    "Gliese Catalog_1": "Gl 92.1"
  },
  {
    "Astrid #": "ASTRID 002882",
    "HYG Star ID": 118139,
    "Gliese Catalog": "NN 3153",
    "Distance in Parsecs": 24.3902,
    "mag": 14.78,
    "Absolute Visual Magnitude": 12.844,
    "Spectral Type": "m",
    "Color Index": 1.69,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.905602,
    "y": 14.037292,
    "z": 1.266212,
    "vx": -0.00000741,
    "vy": 0.00001317,
    "vz": -0.00002946,
    "rarad": 0.614202691757536,
    "decrad": 0.0519381420991,
    "pmrarad": 6.16295150722222e-7,
    "pmdecrad": -0.000001209518018,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118139,
    "lum": 0.000634453795775714,
    "ra": 2.346081,
    "dec": 2.975836,
    "pmra": 127.12,
    "pmdec": -249.48,
    "rv": 0,
    "Asset Name": "ASTRID 002882",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Sweetheart",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000741,
    "y_1": 0.00001317,
    "z_1": -0.00002946,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118139,
    "Gliese Catalog_1": "NN 3153"
  },
  {
    "Astrid #": "ASTRID 002883",
    "HYG Star ID": 118153,
    "Gliese Catalog": "NN 3164",
    "Distance in Parsecs": 24.3902,
    "mag": 13.62,
    "Absolute Visual Magnitude": 11.684,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.234442,
    "y": 11.321169,
    "z": 16.250958,
    "vx": -0.00002632,
    "vy": 0.00002263,
    "vz": 0.00000729,
    "rarad": 0.671866970284599,
    "decrad": 0.729223087864,
    "pmrarad": 0.00000139810569198611,
    "pmdecrad": 4.0089728e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118153,
    "lum": 0.0018467155239867,
    "ra": 2.566343,
    "dec": 41.781405,
    "pmra": 288.38,
    "pmdec": 82.69,
    "rv": 0,
    "Asset Name": "ASTRID 002883",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Innocent",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002632,
    "y_1": 0.00002263,
    "z_1": 0.00000729,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118153,
    "Gliese Catalog_1": "NN 3164"
  },
  {
    "Astrid #": "ASTRID 002884",
    "HYG Star ID": 118158,
    "Gliese Catalog": "NN 3169A",
    "Distance in Parsecs": 24.3902,
    "mag": 13.63,
    "Absolute Visual Magnitude": 11.694,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.016844,
    "y": 13.061448,
    "z": 12.951493,
    "vx": 0.00002708,
    "vy": -0.0000275,
    "vz": -0.00000574,
    "rarad": 0.684124319200978,
    "decrad": 0.55979459736085,
    "pmrarad": -0.0000015755959804375,
    "pmdecrad": -2.77812783e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118158,
    "base": "NN 3169",
    "lum": 0.00182978473429541,
    "ra": 2.613162,
    "dec": 32.073868,
    "pmra": -324.99,
    "pmdec": -57.3,
    "rv": 0,
    "Asset Name": "ASTRID 002884",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Middle Part",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002708,
    "y_1": -0.0000275,
    "z_1": -0.00000574,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118158,
    "Gliese Catalog_1": "NN 3169A"
  },
  {
    "Astrid #": "ASTRID 002885",
    "HYG Star ID": 118159,
    "Gliese Catalog": "NN 3170B",
    "Distance in Parsecs": 24.3902,
    "mag": 13.9,
    "Absolute Visual Magnitude": 11.964,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.016844,
    "y": 13.061448,
    "z": 12.951493,
    "vx": 0.00002708,
    "vy": -0.0000275,
    "vz": -0.00000574,
    "rarad": 0.684124319200978,
    "decrad": 0.55979459736085,
    "pmrarad": -0.0000015755959804375,
    "pmdecrad": -2.77812783e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118159,
    "base": "NN 3170",
    "lum": 0.00142692123157936,
    "ra": 2.613162,
    "dec": 32.073868,
    "pmra": -324.99,
    "pmdec": -57.3,
    "rv": 0,
    "Asset Name": "ASTRID 002885",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Determined Neko",
    "Hair": "Innocent",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002708,
    "y_1": -0.0000275,
    "z_1": -0.00000574,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118159,
    "Gliese Catalog_1": "NN 3170B"
  },
  {
    "Astrid #": "ASTRID 002886",
    "HYG Star ID": 118260,
    "Gliese Catalog": "NN 3249",
    "Distance in Parsecs": 24.3902,
    "mag": 12.7,
    "Absolute Visual Magnitude": 10.764,
    "Spectral Type": "M2.5",
    "Color Index": 1.53,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.135495,
    "y": 19.98818,
    "z": -4.776328,
    "vx": -0.00005195,
    "vy": 0.00003711,
    "vz": 0.00001239,
    "rarad": 0.989399396338466,
    "decrad": -0.1971035851621,
    "pmrarad": 0.00000261566676933333,
    "pmdecrad": 5.17916758e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118260,
    "lum": 0.00430923322818626,
    "ra": 3.779227,
    "dec": -11.293204,
    "pmra": 539.52,
    "pmdec": 106.83,
    "rv": 0,
    "Asset Name": "ASTRID 002886",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Spiky",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005195,
    "y_1": 0.00003711,
    "z_1": 0.00001239,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118260,
    "Gliese Catalog_1": "NN 3249"
  },
  {
    "Astrid #": "ASTRID 002887",
    "HYG Star ID": 118320,
    "Gliese Catalog": "NN 3303",
    "Distance in Parsecs": 24.3902,
    "mag": 13.7,
    "Absolute Visual Magnitude": 11.764,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.559801,
    "y": 19.935156,
    "z": -11.84571,
    "vx": -0.00004993,
    "vy": 0.00002914,
    "vz": 0.00001716,
    "rarad": 1.20831465200245,
    "decrad": -0.50713518601065,
    "pmrarad": 0.00000233782004900694,
    "pmdecrad": 8.04979787e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118320,
    "lum": 0.00171553664772834,
    "ra": 4.615422,
    "dec": -29.056706,
    "pmra": 482.21,
    "pmdec": 166.04,
    "rv": 0,
    "Asset Name": "ASTRID 002887",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Pirate Hat",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004993,
    "y_1": 0.00002914,
    "z_1": 0.00001716,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118320,
    "Gliese Catalog_1": "NN 3303"
  },
  {
    "Astrid #": "ASTRID 002888",
    "HYG Star ID": 118324,
    "Gliese Catalog": "Wo 9160B",
    "Distance in Parsecs": 24.3902,
    "mag": 7.3,
    "Absolute Visual Magnitude": 5.364,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.260197,
    "y": 22.143786,
    "z": -6.025263,
    "vx": 0.00000716,
    "vy": -0.00000737,
    "vz": -0.00001728,
    "rarad": 1.21375845806303,
    "decrad": -0.24962050276985,
    "pmrarad": -3.80578739236111e-7,
    "pmdecrad": -7.31055397e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 2,
    "comp_primary": 21541,
    "base": "Wo 9160",
    "lum": 0.622873708944028,
    "ra": 4.636216,
    "dec": -14.302201,
    "pmra": -78.5,
    "pmdec": -150.79,
    "rv": 0,
    "Asset Name": "ASTRID 002888",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Greasy",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000716,
    "y_1": -0.00000737,
    "z_1": -0.00001728,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118324,
    "Gliese Catalog_1": "Wo 9160B"
  },
  {
    "Astrid #": "ASTRID 002889",
    "HYG Star ID": 118335,
    "Gliese Catalog": "NN 3315",
    "Distance in Parsecs": 24.3902,
    "mag": 11.79,
    "Absolute Visual Magnitude": 9.854,
    "Spectral Type": "k-m",
    "Color Index": 1.47,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.078702,
    "y": 16.1693,
    "z": 17.539737,
    "vx": -0.00001698,
    "vy": 0.00002619,
    "vz": -0.00001922,
    "rarad": 1.26643704170317,
    "decrad": 0.802550234738099,
    "pmrarad": 9.86014063513889e-7,
    "pmdecrad": -0.000001134265238,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 118335,
    "lum": 0.00996322641954418,
    "ra": 4.837433,
    "dec": 45.982741,
    "pmra": 203.38,
    "pmdec": -233.96,
    "rv": 0,
    "Asset Name": "ASTRID 002889",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Lovestruck",
    "Hair": "Spiky",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00001698,
    "y_1": 0.00002619,
    "z_1": -0.00001922,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118335,
    "Gliese Catalog_1": "NN 3315"
  },
  {
    "Astrid #": "ASTRID 002890",
    "HYG Star ID": 118414,
    "Gliese Catalog": "NN 3384",
    "Distance in Parsecs": 24.3902,
    "mag": 12.44,
    "Absolute Visual Magnitude": 10.504,
    "Spectral Type": "M3",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.639286,
    "y": 20.318099,
    "z": 13.477687,
    "vx": -0.00001738,
    "vy": 0.00002603,
    "vz": -0.00004006,
    "rarad": 1.60224301853357,
    "decrad": 0.58546399916615,
    "pmrarad": 6.78690671409722e-7,
    "pmdecrad": -0.000001971116677,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118414,
    "lum": 0.00547520015303767,
    "ra": 6.120118,
    "dec": 33.544616,
    "pmra": 139.99,
    "pmdec": -406.57,
    "rv": 0,
    "Asset Name": "ASTRID 002890",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Manbun",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001738,
    "y_1": 0.00002603,
    "z_1": -0.00004006,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118414,
    "Gliese Catalog_1": "NN 3384"
  },
  {
    "Astrid #": "ASTRID 002891",
    "HYG Star ID": 118464,
    "Gliese Catalog": "NN 3429",
    "Distance in Parsecs": 24.3902,
    "mag": 11.36,
    "Absolute Visual Magnitude": 9.424,
    "Spectral Type": "M0",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.188165,
    "y": 20.035788,
    "z": 12.456149,
    "vx": 0.00001178,
    "vy": 0.00002285,
    "vz": -0.0000309,
    "rarad": 1.87036356898263,
    "decrad": 0.5360022974117,
    "pmrarad": -7.38080347277777e-7,
    "pmdecrad": -0.000001473891766,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 118464,
    "lum": 0.0148047132489762,
    "ra": 7.144263,
    "dec": 30.710669,
    "pmra": -152.24,
    "pmdec": -304.01,
    "rv": 0,
    "Asset Name": "ASTRID 002891",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Good Boy",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00001178,
    "y_1": 0.00002285,
    "z_1": -0.0000309,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118464,
    "Gliese Catalog_1": "NN 3429"
  },
  {
    "Astrid #": "ASTRID 002892",
    "HYG Star ID": 118519,
    "Gliese Catalog": "NN 3473A",
    "Distance in Parsecs": 24.3902,
    "mag": 13.7,
    "Absolute Visual Magnitude": 11.764,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.392658,
    "y": 20.959088,
    "z": 1.421456,
    "vx": 0.00004131,
    "vy": 0.00002729,
    "vz": -0.00004216,
    "rarad": 2.10477845861125,
    "decrad": 0.0583128362463,
    "pmrarad": -0.00000202758777481944,
    "pmdecrad": -0.000001731735074,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118519,
    "base": "NN 3473",
    "lum": 0.00171553664772834,
    "ra": 8.039661,
    "dec": 3.341079,
    "pmra": -418.22,
    "pmdec": -357.2,
    "rv": 0,
    "Asset Name": "ASTRID 002892",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Neko Ninja",
    "Hair": "Curly",
    "Outfit": "Pirate",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004131,
    "y_1": 0.00002729,
    "z_1": -0.00004216,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118519,
    "Gliese Catalog_1": "NN 3473A"
  },
  {
    "Astrid #": "ASTRID 002893",
    "HYG Star ID": 118520,
    "Gliese Catalog": "NN 3474B",
    "Distance in Parsecs": 24.3902,
    "mag": 17,
    "Absolute Visual Magnitude": 15.064,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.38972,
    "y": 20.961111,
    "z": 1.417221,
    "vx": 0.00004132,
    "vy": 0.00002728,
    "vz": -0.00004216,
    "rarad": 2.10463229961125,
    "decrad": 0.0581389152463,
    "pmrarad": -0.00000202758777481944,
    "pmdecrad": -0.000001731735074,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118520,
    "base": "NN 3474",
    "lum": 0.0000821107464085087,
    "ra": 8.039103,
    "dec": 3.331114,
    "pmra": -418.22,
    "pmdec": -357.2,
    "rv": 0,
    "Asset Name": "ASTRID 002893",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Bob",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004132,
    "y_1": 0.00002728,
    "z_1": -0.00004216,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118520,
    "Gliese Catalog_1": "NN 3474B"
  },
  {
    "Astrid #": "ASTRID 002894",
    "HYG Star ID": 118577,
    "Gliese Catalog": "NN 3521",
    "Distance in Parsecs": 24.3902,
    "mag": 14.5,
    "Absolute Visual Magnitude": 12.564,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.333061,
    "y": 5.556563,
    "z": -23.142276,
    "vx": -0.00001773,
    "vy": 0.00005859,
    "vz": 0.00001815,
    "rarad": 2.33579640354439,
    "decrad": -1.24952589658165,
    "pmrarad": -0.00000113940911204166,
    "pmdecrad": 0.000002357028367,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118577,
    "lum": 0.000821107464085087,
    "ra": 8.922085,
    "dec": -71.59256,
    "pmra": -235.02,
    "pmdec": 486.17,
    "rv": 0,
    "Asset Name": "ASTRID 002894",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Oof",
    "Hair": "Slick",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001773,
    "y_1": 0.00005859,
    "z_1": 0.00001815,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118577,
    "Gliese Catalog_1": "NN 3521"
  },
  {
    "Astrid #": "ASTRID 002895",
    "HYG Star ID": 118593,
    "Gliese Catalog": "NN 3529",
    "Distance in Parsecs": 24.3902,
    "mag": 16.97,
    "Absolute Visual Magnitude": 15.034,
    "Spectral Type": "DC9",
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -5.090366,
    "y": 4.848504,
    "z": 23.355128,
    "vx": -0.00001065,
    "vy": 0.00006349,
    "vz": -0.00001549,
    "rarad": 2.38072020529273,
    "decrad": 1.2784211736109,
    "pmrarad": -0.00000158427414531944,
    "pmdecrad": -0.000002204767782,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118593,
    "lum": 0.0000844111855592767,
    "ra": 9.093681,
    "dec": 73.248138,
    "pmra": -326.78,
    "pmdec": -454.77,
    "rv": 0,
    "Asset Name": "ASTRID 002895",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Braided Bun",
    "Outfit": "River Spirit",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001065,
    "y_1": 0.00006349,
    "z_1": -0.00001549,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118593,
    "Gliese Catalog_1": "NN 3529"
  },
  {
    "Astrid #": "ASTRID 002896",
    "HYG Star ID": 118749,
    "Gliese Catalog": "NN 3658",
    "Distance in Parsecs": 24.3902,
    "mag": 12.74,
    "Absolute Visual Magnitude": 10.804,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.082512,
    "y": 2.755599,
    "z": 17.189425,
    "vx": -0.00000415,
    "vy": 0.00003509,
    "vz": -0.00000974,
    "rarad": 2.98168782800189,
    "decrad": 0.7820956297785,
    "pmrarad": -0.00000139349996202083,
    "pmdecrad": -5.6300443e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118749,
    "lum": 0.00415336405489073,
    "ra": 11.389209,
    "dec": 44.810779,
    "pmra": -287.43,
    "pmdec": -116.13,
    "rv": 0,
    "Asset Name": "ASTRID 002896",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Braided Bun",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000415,
    "y_1": 0.00003509,
    "z_1": -0.00000974,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118749,
    "Gliese Catalog_1": "NN 3658"
  },
  {
    "Astrid #": "ASTRID 002897",
    "HYG Star ID": 118758,
    "Gliese Catalog": "NN 3670B",
    "Distance in Parsecs": 24.3902,
    "mag": 9.22,
    "Absolute Visual Magnitude": 7.284,
    "Spectral Type": "K6 IV",
    "Color Index": 1.14,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.447846,
    "y": 2.905246,
    "z": 6.050968,
    "vx": 0.0000094,
    "vy": 0.00003808,
    "vz": -0.00002431,
    "rarad": 3.01832101933867,
    "decrad": 0.2507138040843,
    "pmrarad": -0.00000159707322648611,
    "pmdecrad": -9.18358314e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 2,
    "comp_primary": 118758,
    "base": "NN 3670",
    "lum": 0.106267386540246,
    "ra": 11.529137,
    "dec": 14.364843,
    "pmra": -329.42,
    "pmdec": -189.43,
    "rv": -10.3,
    "Asset Name": "ASTRID 002897",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "High Bun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.0000094,
    "y_1": 0.00003808,
    "z_1": -0.00002431,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118758,
    "Gliese Catalog_1": "NN 3670B"
  },
  {
    "Astrid #": "ASTRID 002898",
    "HYG Star ID": 118806,
    "Gliese Catalog": "NN 3717",
    "Distance in Parsecs": 24.3902,
    "mag": 12.16,
    "Absolute Visual Magnitude": 10.224,
    "Spectral Type": "m",
    "Color Index": 1.48,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -22.134178,
    "y": -1.39605,
    "z": 10.149438,
    "vx": -0.00001738,
    "vy": -0.00000481,
    "vz": -0.00003856,
    "rarad": 3.20458063772664,
    "decrad": 0.4291826146349,
    "pmrarad": 1.52134532958333e-7,
    "pmdecrad": -0.000001738687302,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118806,
    "lum": 0.00708598126916768,
    "ra": 12.240596,
    "dec": 24.590352,
    "pmra": 31.38,
    "pmdec": -358.63,
    "rv": 0,
    "Asset Name": "ASTRID 002898",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Honest Lad",
    "Outfit": "Tats",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001738,
    "y_1": -0.00000481,
    "z_1": -0.00003856,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118806,
    "Gliese Catalog_1": "NN 3717"
  },
  {
    "Astrid #": "ASTRID 002899",
    "HYG Star ID": 118828,
    "Gliese Catalog": "NN 3733",
    "Distance in Parsecs": 24.3902,
    "mag": 13.22,
    "Absolute Visual Magnitude": 11.284,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.567639,
    "y": -2.921974,
    "z": 12.77952,
    "vx": 0.00000473,
    "vy": 0.00005183,
    "vz": 0.00001948,
    "rarad": 3.28273292260589,
    "decrad": 0.55149513314935,
    "pmrarad": -0.00000207674788202777,
    "pmdecrad": 9.37682987e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118828,
    "lum": 0.00266931606362428,
    "ra": 12.539116,
    "dec": 31.598344,
    "pmra": -428.36,
    "pmdec": 193.41,
    "rv": 0,
    "Asset Name": "ASTRID 002899",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Headband",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000473,
    "y_1": 0.00005183,
    "z_1": 0.00001948,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118828,
    "Gliese Catalog_1": "NN 3733"
  },
  {
    "Astrid #": "ASTRID 002900",
    "HYG Star ID": 118846,
    "Gliese Catalog": "NN 3749",
    "Distance in Parsecs": 24.3902,
    "mag": 13.65,
    "Absolute Visual Magnitude": 11.714,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.19951,
    "y": -3.455712,
    "z": 17.902956,
    "vx": -0.00004123,
    "vy": -0.00003111,
    "vz": -0.0000433,
    "rarad": 3.35174329757492,
    "decrad": 0.8242262107481,
    "pmrarad": 8.95111498409722e-7,
    "pmdecrad": -0.000002614445038,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118846,
    "lum": 0.00179638739906388,
    "ra": 12.802716,
    "dec": 47.224683,
    "pmra": 184.63,
    "pmdec": -539.27,
    "rv": 0,
    "Asset Name": "ASTRID 002900",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Undercut",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004123,
    "y_1": -0.00003111,
    "z_1": -0.0000433,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118846,
    "Gliese Catalog_1": "NN 3749"
  },
  {
    "Astrid #": "ASTRID 002901",
    "HYG Star ID": 118871,
    "Gliese Catalog": "NN 3767",
    "Distance in Parsecs": 24.3902,
    "mag": 15.75,
    "Absolute Visual Magnitude": 13.814,
    "Spectral Type": "m",
    "Color Index": 1.79,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.971252,
    "y": -6.791132,
    "z": 10.438822,
    "vx": -0.00002464,
    "vy": 0.00004768,
    "vz": -0.00001847,
    "rarad": 3.45477802436878,
    "decrad": 0.44227034358285,
    "pmrarad": -0.00000217109262426388,
    "pmdecrad": -8.37748343e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118871,
    "lum": 0.000259656979027378,
    "ra": 13.19628,
    "dec": 25.340224,
    "pmra": -447.82,
    "pmdec": -172.8,
    "rv": 0,
    "Asset Name": "ASTRID 002901",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Audiophile",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002464,
    "y_1": 0.00004768,
    "z_1": -0.00001847,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118871,
    "Gliese Catalog_1": "NN 3767"
  },
  {
    "Astrid #": "ASTRID 002902",
    "HYG Star ID": 118994,
    "Gliese Catalog": "NN 3875",
    "Distance in Parsecs": 24.3902,
    "mag": 14.67,
    "Absolute Visual Magnitude": 12.734,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.271743,
    "y": -12.696373,
    "z": 16.048203,
    "vx": -0.00001069,
    "vy": 0.00002624,
    "vz": 0.00001192,
    "rarad": 3.90485534821157,
    "decrad": 0.71812981945755,
    "pmrarad": -0.00000108045576848611,
    "pmdecrad": 6.49209151e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118994,
    "lum": 0.000702101660240313,
    "ra": 14.915449,
    "dec": 41.145808,
    "pmra": -222.86,
    "pmdec": 133.91,
    "rv": 0,
    "Asset Name": "ASTRID 002902",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Neko Dreams",
    "Hair": "Round Brush",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001069,
    "y_1": 0.00002624,
    "z_1": 0.00001192,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 118994,
    "Gliese Catalog_1": "NN 3875"
  },
  {
    "Astrid #": "ASTRID 002903",
    "HYG Star ID": 119062,
    "Gliese Catalog": "Wo 9543B",
    "Distance in Parsecs": 24.3902,
    "mag": 9,
    "Absolute Visual Magnitude": 7.064,
    "Spectral Type": "K1 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.803307,
    "y": -17.988824,
    "z": -13.23553,
    "vx": -0.00002955,
    "vy": 0.00003281,
    "vz": -0.00002272,
    "rarad": 4.21343474461458,
    "decrad": -0.573597993079849,
    "pmrarad": -0.00000170770770838888,
    "pmdecrad": -0.000001109001597,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 2,
    "comp_primary": 78602,
    "base": "Wo 9543",
    "lum": 0.130136763010746,
    "ra": 16.094135,
    "dec": -32.864744,
    "pmra": -352.24,
    "pmdec": -228.75,
    "rv": 0,
    "Asset Name": "ASTRID 002903",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Frizzy",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00002955,
    "y_1": 0.00003281,
    "z_1": -0.00002272,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119062,
    "Gliese Catalog_1": "Wo 9543B"
  },
  {
    "Astrid #": "ASTRID 002904",
    "HYG Star ID": 119063,
    "Gliese Catalog": "Wo 9543C",
    "Distance in Parsecs": 24.3902,
    "mag": 11.1,
    "Absolute Visual Magnitude": 9.164,
    "Spectral Type": "K5",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.803307,
    "y": -17.988824,
    "z": -13.23553,
    "vx": -0.00002955,
    "vy": 0.00003281,
    "vz": -0.00002272,
    "rarad": 4.21343474461458,
    "decrad": -0.573597993079849,
    "pmrarad": -0.00000170770770838888,
    "pmdecrad": -0.000001109001597,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 3,
    "comp_primary": 78602,
    "base": "Wo 9543",
    "lum": 0.018810485289187,
    "ra": 16.094135,
    "dec": -32.864744,
    "pmra": -352.24,
    "pmdec": -228.75,
    "rv": 0,
    "Asset Name": "ASTRID 002904",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Charmer",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00002955,
    "y_1": 0.00003281,
    "z_1": -0.00002272,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119063,
    "Gliese Catalog_1": "Wo 9543C"
  },
  {
    "Astrid #": "ASTRID 002905",
    "HYG Star ID": 119084,
    "Gliese Catalog": "NN 3955",
    "Distance in Parsecs": 24.3902,
    "mag": 13.18,
    "Absolute Visual Magnitude": 11.244,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.176579,
    "y": -21.629655,
    "z": 6.544484,
    "vx": -0.000002,
    "vy": -0.00000948,
    "vz": -0.00003412,
    "rarad": 4.31114586178739,
    "decrad": 0.27165312181955,
    "pmrarad": 7.61157478472222e-8,
    "pmdecrad": -0.000001452443609,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119084,
    "lum": 0.00276949126680968,
    "ra": 16.467364,
    "dec": 15.564577,
    "pmra": 15.7,
    "pmdec": -299.59,
    "rv": 0,
    "Asset Name": "ASTRID 002905",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chubby Cheeks",
    "Hair": "Mohawk",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.000002,
    "y_1": -0.00000948,
    "z_1": -0.00003412,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119084,
    "Gliese Catalog_1": "NN 3955"
  },
  {
    "Astrid #": "ASTRID 002906",
    "HYG Star ID": 119088,
    "Gliese Catalog": "NN 3958",
    "Distance in Parsecs": 24.3902,
    "mag": 14.58,
    "Absolute Visual Magnitude": 12.644,
    "Spectral Type": "DA6",
    "Color Index": 0.22,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -0.314493,
    "y": -1.049521,
    "z": -24.365579,
    "vx": 0.00000318,
    "vy": 0.00000633,
    "vz": -3.1e-7,
    "rarad": 4.42119638187927,
    "decrad": -1.52586041433535,
    "pmrarad": 5.05175855138888e-8,
    "pmdecrad": -2.86466707e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119088,
    "lum": 0.00076278123406916,
    "ra": 16.887726,
    "dec": -87.425362,
    "pmra": 10.42,
    "pmdec": -59.09,
    "rv": 0,
    "Asset Name": "ASTRID 002906",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Heartbreaker",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000318,
    "y_1": 0.00000633,
    "z_1": -3.1e-7,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119088,
    "Gliese Catalog_1": "NN 3958"
  },
  {
    "Astrid #": "ASTRID 002907",
    "HYG Star ID": 119171,
    "Gliese Catalog": "NN 4020A",
    "Distance in Parsecs": 24.3902,
    "mag": 10.28,
    "Absolute Visual Magnitude": 8.344,
    "Spectral Type": "dK8",
    "Color Index": 1.23,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -1.091645,
    "y": -11.685225,
    "z": 21.380965,
    "vx": -0.00000419,
    "vy": 0.00000511,
    "vz": 0.00000258,
    "rarad": 4.61924862725931,
    "decrad": 1.0687946583926,
    "pmrarad": -1.90434813722222e-7,
    "pmdecrad": 2.19867852e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 119171,
    "base": "NN 4020",
    "lum": 0.0400313282178023,
    "ra": 17.64423,
    "dec": 61.237423,
    "pmra": -39.28,
    "pmdec": 45.35,
    "rv": 0,
    "Asset Name": "ASTRID 002907",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Heartbreaker",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000419,
    "y_1": 0.00000511,
    "z_1": 0.00000258,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119171,
    "Gliese Catalog_1": "NN 4020A"
  },
  {
    "Astrid #": "ASTRID 002908",
    "HYG Star ID": 119172,
    "Gliese Catalog": "NN 4021B",
    "Distance in Parsecs": 24.3902,
    "mag": 14.7,
    "Absolute Visual Magnitude": 12.764,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.090896,
    "y": -11.686538,
    "z": 21.380287,
    "vx": -0.00000419,
    "vy": 0.00000511,
    "vz": 0.00000258,
    "rarad": 4.61932250525931,
    "decrad": 1.0687368323926,
    "pmrarad": -1.90434813722222e-7,
    "pmdecrad": 2.19867852e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119172,
    "base": "NN 4021",
    "lum": 0.000682967440807964,
    "ra": 17.644512,
    "dec": 61.23411,
    "pmra": -39.28,
    "pmdec": 45.35,
    "rv": 0,
    "Asset Name": "ASTRID 002908",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Manbun",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000419,
    "y_1": 0.00000511,
    "z_1": 0.00000258,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119172,
    "Gliese Catalog_1": "NN 4021B"
  },
  {
    "Astrid #": "ASTRID 002909",
    "HYG Star ID": 119177,
    "Gliese Catalog": "NN 4025",
    "Distance in Parsecs": 24.3902,
    "mag": 11.48,
    "Absolute Visual Magnitude": 9.544,
    "Spectral Type": "g",
    "Color Index": 1.45,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.566763,
    "y": -24.101074,
    "z": -3.400782,
    "vx": -0.00002315,
    "vy": 0.00000586,
    "vz": -0.00003086,
    "rarad": 4.64747276500152,
    "decrad": -0.1398880840803,
    "pmrarad": -9.62839969583333e-7,
    "pmdecrad": -0.000001277721606,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 119177,
    "lum": 0.0132556186071985,
    "ra": 17.752038,
    "dec": -8.014997,
    "pmra": -198.6,
    "pmdec": -263.55,
    "rv": 0,
    "Asset Name": "ASTRID 002909",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Prince",
    "Outfit": "Unit 01",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002315,
    "y_1": 0.00000586,
    "z_1": -0.00003086,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119177,
    "Gliese Catalog_1": "NN 4025"
  },
  {
    "Astrid #": "ASTRID 002910",
    "HYG Star ID": 119237,
    "Gliese Catalog": "NN 4074",
    "Distance in Parsecs": 24.3902,
    "mag": 12.65,
    "Absolute Visual Magnitude": 10.714,
    "Spectral Type": "k",
    "Color Index": 1.53,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.254255,
    "y": -20.917096,
    "z": -11.800774,
    "vx": 0.0000304,
    "vy": 0.00002454,
    "vz": -0.00003254,
    "rarad": 4.91302853722998,
    "decrad": -0.5050287535879,
    "pmrarad": 0.00000142176459959722,
    "pmdecrad": -0.000001524651758,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119237,
    "lum": 0.00451232113344336,
    "ra": 18.766387,
    "dec": -28.936016,
    "pmra": 293.26,
    "pmdec": -314.48,
    "rv": 0,
    "Asset Name": "ASTRID 002910",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Shocked",
    "Hair": "Little Crown",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000304,
    "y_1": 0.00002454,
    "z_1": -0.00003254,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119237,
    "Gliese Catalog_1": "NN 4074"
  },
  {
    "Astrid #": "ASTRID 002911",
    "HYG Star ID": 119267,
    "Gliese Catalog": "NN 4100",
    "Distance in Parsecs": 24.3902,
    "mag": 12.18,
    "Absolute Visual Magnitude": 10.244,
    "Spectral Type": "g",
    "Color Index": 1.5,
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.396662,
    "y": -23.232394,
    "z": 0.653536,
    "vx": -0.00001864,
    "vy": -0.0000066,
    "vz": -0.00002354,
    "rarad": 5.02061909996885,
    "decrad": 0.02679821858725,
    "pmrarad": -8.10220622944444e-7,
    "pmdecrad": -9.65608255e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119267,
    "lum": 0.00695664753528352,
    "ra": 19.177352,
    "dec": 1.535425,
    "pmra": -167.12,
    "pmdec": -199.17,
    "rv": 0,
    "Asset Name": "ASTRID 002911",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Einstein Tongue",
    "Hair": "Bucket Hat",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001864,
    "y_1": -0.0000066,
    "z_1": -0.00002354,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119267,
    "Gliese Catalog_1": "NN 4100"
  },
  {
    "Astrid #": "ASTRID 002912",
    "HYG Star ID": 119298,
    "Gliese Catalog": "NN 4121",
    "Distance in Parsecs": 24.3902,
    "mag": 13.9,
    "Absolute Visual Magnitude": 11.964,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.565614,
    "y": -7.053165,
    "z": -23.074252,
    "vx": -0.00002917,
    "vy": 0.00006905,
    "vz": -0.00002563,
    "rarad": 5.18043211067177,
    "decrad": -1.2408077979406,
    "pmrarad": 2.09633435472222e-7,
    "pmdecrad": -0.000003241478812,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119298,
    "lum": 0.00142692123157936,
    "ra": 19.787793,
    "dec": -71.09305,
    "pmra": 43.24,
    "pmdec": -668.6,
    "rv": 0,
    "Asset Name": "ASTRID 002912",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Heartbreaker",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002917,
    "y_1": 0.00006905,
    "z_1": -0.00002563,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119298,
    "Gliese Catalog_1": "NN 4121"
  },
  {
    "Astrid #": "ASTRID 002913",
    "HYG Star ID": 119311,
    "Gliese Catalog": "NN 4128",
    "Distance in Parsecs": 24.3902,
    "mag": 12.43,
    "Absolute Visual Magnitude": 10.494,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.81238,
    "y": -18.062922,
    "z": 13.127449,
    "vx": 0.00001541,
    "vy": 0.00001976,
    "vz": 0.00001568,
    "rarad": 5.21001339322499,
    "decrad": 0.56833124086815,
    "pmrarad": 9.41944499951388e-7,
    "pmdecrad": 7.62757363e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119311,
    "lum": 0.00552586155630078,
    "ra": 19.900785,
    "dec": 32.562981,
    "pmra": 194.29,
    "pmdec": 157.33,
    "rv": 0,
    "Asset Name": "ASTRID 002913",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Grecian",
    "Outfit": "Tats",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001541,
    "y_1": 0.00001976,
    "z_1": 0.00001568,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119311,
    "Gliese Catalog_1": "NN 4128"
  },
  {
    "Astrid #": "ASTRID 002914",
    "HYG Star ID": 119336,
    "Henry Draper Catalogue": 192020,
    "Gliese Catalog": "NN 4138",
    "Distance in Parsecs": 24.3902,
    "mag": 7.95,
    "Absolute Visual Magnitude": 6.014,
    "Spectral Type": "G8 V",
    "Color Index": 0.86,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.370014,
    "y": -16.056918,
    "z": 15.149426,
    "vx": -0.00000385,
    "vy": 0.00001331,
    "vz": 0.00000686,
    "rarad": 5.28582382426238,
    "decrad": 0.6701906154502,
    "pmrarad": 1.63285247611111e-7,
    "pmdecrad": 5.58389004e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 119336,
    "lum": 0.342294562316041,
    "ra": 20.19036,
    "dec": 38.399094,
    "pmra": 33.68,
    "pmdec": 115.18,
    "rv": -6,
    "Asset Name": "ASTRID 002914",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Bucket Hat",
    "Outfit": "Boxer",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000385,
    "y_1": 0.00001331,
    "z_1": 0.00000686,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119336,
    "Henry Draper Catalogue_1": 192020,
    "Gliese Catalog_1": "NN 4138"
  },
  {
    "Astrid #": "ASTRID 002915",
    "HYG Star ID": 119441,
    "Gliese Catalog": "NN 4221",
    "Distance in Parsecs": 24.3902,
    "mag": 13.87,
    "Absolute Visual Magnitude": 11.934,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.459099,
    "y": -13.263816,
    "z": 0.61505,
    "vx": 0.0000148,
    "vy": 0.00002274,
    "vz": -0.0000019,
    "rarad": 5.70799981282543,
    "decrad": 0.0252197660671,
    "pmrarad": 0.00000111235650866666,
    "pmdecrad": -7.7778658e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119441,
    "lum": 0.00146689828220628,
    "ra": 21.802953,
    "dec": 1.444986,
    "pmra": 229.44,
    "pmdec": -16.04,
    "rv": 0,
    "Asset Name": "ASTRID 002915",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Manbun",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000148,
    "y_1": 0.00002274,
    "z_1": -0.0000019,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119441,
    "Gliese Catalog_1": "NN 4221"
  },
  {
    "Astrid #": "ASTRID 002916",
    "HYG Star ID": 119445,
    "Henry Draper Catalogue": 207491,
    "Gliese Catalog": "Gl 838.1A",
    "Distance in Parsecs": 24.3902,
    "mag": 8.65,
    "Absolute Visual Magnitude": 6.714,
    "Spectral Type": "K3 V",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.421863,
    "y": -13.110683,
    "z": 2.431804,
    "vx": 0.00002652,
    "vy": 0.00006019,
    "vz": -0.00000376,
    "rarad": 5.71245922740686,
    "decrad": 0.0998722601281,
    "pmrarad": 0.00000266414813738888,
    "pmdecrad": -1.11657438e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119445,
    "base": "Gl 838.1",
    "lum": 0.179638739906388,
    "ra": 21.819987,
    "dec": 5.722259,
    "pmra": 549.52,
    "pmdec": -23.03,
    "rv": -10.3,
    "Asset Name": "ASTRID 002916",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Innocent",
    "Outfit": "Football",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00002652,
    "y_1": 0.00006019,
    "z_1": -0.00000376,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119445,
    "Henry Draper Catalogue_1": 207491,
    "Gliese Catalog_1": "Gl 838.1A"
  },
  {
    "Astrid #": "ASTRID 002917",
    "HYG Star ID": 119446,
    "Gliese Catalog": "Gl 838.1B",
    "Distance in Parsecs": 24.3902,
    "mag": 14.9,
    "Absolute Visual Magnitude": 12.964,
    "Spectral Type": "m",
    "Color Index": 1.75,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.44764,
    "y": -13.07712,
    "z": 2.401006,
    "vx": 0.00003192,
    "vy": 0.00004976,
    "vz": -7.2e-7,
    "rarad": 5.71419666372386,
    "decrad": 0.09860110837905,
    "pmrarad": 0.00000242387447730555,
    "pmdecrad": -2.9612419e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119445,
    "base": "Gl 838.1",
    "lum": 0.000568067574106771,
    "ra": 21.826623,
    "dec": 5.649427,
    "pmra": 499.96,
    "pmdec": -6.11,
    "rv": 0,
    "Asset Name": "ASTRID 002917",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Side Part",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003192,
    "y_1": 0.00004976,
    "z_1": -7.2e-7,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119446,
    "Gliese Catalog_1": "Gl 838.1B"
  },
  {
    "Astrid #": "ASTRID 002918",
    "HYG Star ID": 119452,
    "Gliese Catalog": "NN 4235",
    "Distance in Parsecs": 24.3902,
    "mag": 15.35,
    "Absolute Visual Magnitude": 13.414,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 15.070387,
    "y": -9.210107,
    "z": -16.820798,
    "vx": 0.00003759,
    "vy": 0.00006016,
    "vz": 7.2e-7,
    "rarad": 5.73455994255975,
    "decrad": -0.7610110723579,
    "pmrarad": 0.000002908591195125,
    "pmdecrad": 4.0612842e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119452,
    "lum": 0.000375318524237913,
    "ra": 21.904405,
    "dec": -43.602723,
    "pmra": 599.94,
    "pmdec": 8.38,
    "rv": 0,
    "Asset Name": "ASTRID 002918",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Maniacal",
    "Hair": "Mohawk",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003759,
    "y_1": 0.00006016,
    "z_1": 7.2e-7,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119452,
    "Gliese Catalog_1": "NN 4235"
  },
  {
    "Astrid #": "ASTRID 002919",
    "HYG Star ID": 119468,
    "Gliese Catalog": "NN 4252",
    "Distance in Parsecs": 24.3902,
    "mag": 13.6,
    "Absolute Visual Magnitude": 11.664,
    "Spectral Type": "M3",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.303114,
    "y": -11.674406,
    "z": 2.183448,
    "vx": 0.00002508,
    "vy": 0.00004859,
    "vz": 0.0000152,
    "rarad": 5.78186767053039,
    "decrad": 0.0896415571173,
    "pmrarad": 0.00000224139060794444,
    "pmdecrad": 6.25802346e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119468,
    "lum": 0.0018810485289187,
    "ra": 22.085108,
    "dec": 5.136083,
    "pmra": 462.32,
    "pmdec": 129.08,
    "rv": 0,
    "Asset Name": "ASTRID 002919",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Maniacal",
    "Hair": "Ram Horns",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002508,
    "y_1": 0.00004859,
    "z_1": 0.0000152,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119468,
    "Gliese Catalog_1": "NN 4252"
  },
  {
    "Astrid #": "ASTRID 002920",
    "HYG Star ID": 119479,
    "Gliese Catalog": "NN 4263",
    "Distance in Parsecs": 24.3902,
    "mag": 13.34,
    "Absolute Visual Magnitude": 11.404,
    "Spectral Type": "M3.5",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.084427,
    "y": -10.572135,
    "z": -6.20957,
    "vx": -0.00002324,
    "vy": -0.00002946,
    "vz": -0.00002878,
    "rarad": 5.81840561640963,
    "decrad": -0.25742665158905,
    "pmrarad": -0.000001507091807375,
    "pmdecrad": -0.000001220411781,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119479,
    "lum": 0.00239001155148472,
    "ra": 22.224672,
    "dec": -14.749461,
    "pmra": -310.86,
    "pmdec": -251.73,
    "rv": 0,
    "Asset Name": "ASTRID 002920",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Gimme Blood",
    "Hair": "Shinobi",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002324,
    "y_1": -0.00002946,
    "z_1": -0.00002878,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119479,
    "Gliese Catalog_1": "NN 4263"
  },
  {
    "Astrid #": "ASTRID 002921",
    "HYG Star ID": 119535,
    "Gliese Catalog": "Wo 9801B",
    "Distance in Parsecs": 24.3902,
    "mag": 13.52,
    "Absolute Visual Magnitude": 11.584,
    "Spectral Type": "M3:",
    "Color Index": 1.66,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.992333,
    "y": -6.974667,
    "z": 4.193866,
    "vx": 0.00001684,
    "vy": 0.00005887,
    "vz": 0.00000559,
    "rarad": 5.98865834941375,
    "decrad": 0.17280759428535,
    "pmrarad": 0.00000251026827518055,
    "pmdecrad": 2.32865707e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 112575,
    "base": "Wo 9801",
    "lum": 0.00202488330653056,
    "ra": 22.87499,
    "dec": 9.901146,
    "pmra": 517.78,
    "pmdec": 48.03,
    "rv": 0,
    "Asset Name": "ASTRID 002921",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Side Part",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001684,
    "y_1": 0.00005887,
    "z_1": 0.00000559,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119535,
    "Gliese Catalog_1": "Wo 9801B"
  },
  {
    "Astrid #": "ASTRID 002922",
    "HYG Star ID": 119581,
    "Gliese Catalog": "NN 4356A",
    "Distance in Parsecs": 24.3902,
    "mag": 14.24,
    "Absolute Visual Magnitude": 12.304,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.670502,
    "y": -2.091412,
    "z": 8.749642,
    "vx": -0.00000421,
    "vy": 0.00003261,
    "vz": 0.00001872,
    "rarad": 6.19118839536831,
    "decrad": 0.3669133568975,
    "pmrarad": 0.00000131568736629166,
    "pmdecrad": 8.2211795e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119581,
    "base": "NN 4356",
    "lum": 0.00104327788145996,
    "ra": 23.648598,
    "dec": 21.022587,
    "pmra": 271.38,
    "pmdec": 169.57,
    "rv": 0,
    "Asset Name": "ASTRID 002922",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Unkempt",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000421,
    "y_1": 0.00003261,
    "z_1": 0.00001872,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119581,
    "Gliese Catalog_1": "NN 4356A"
  },
  {
    "Astrid #": "ASTRID 002923",
    "HYG Star ID": 119582,
    "Gliese Catalog": "NN 4357B",
    "Distance in Parsecs": 24.3902,
    "mag": 17.9,
    "Absolute Visual Magnitude": 15.964,
    "Spectral Type": "g",
    "Spectral Abbreviation": "g",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.670907,
    "y": -2.089784,
    "z": 8.74898,
    "vx": -0.00000421,
    "vy": 0.00003261,
    "vz": 0.00001872,
    "rarad": 6.19126126836831,
    "decrad": 0.3668843038975,
    "pmrarad": 0.00000131568736629166,
    "pmdecrad": 8.2211795e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119582,
    "base": "NN 4357",
    "lum": 0.0000358426408043713,
    "ra": 23.648876,
    "dec": 21.020922,
    "pmra": 271.38,
    "pmdec": 169.57,
    "rv": 0,
    "Asset Name": "ASTRID 002923",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Undercut",
    "Outfit": "Elven",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000421,
    "y_1": 0.00003261,
    "z_1": 0.00001872,
    "Distance in Parsecs_1": 24.3902,
    "HYG Star ID_1": 119582,
    "Gliese Catalog_1": "NN 4357B"
  },
  {
    "Astrid #": "ASTRID 002924",
    "HYG Star ID": 76367,
    "Hipparcos Catalogue": 76602,
    "Henry Draper Catalogue": 139460,
    "Harvard Revised Catalogue": 5815,
    "Distance in Parsecs": 24.3962,
    "mag": 6.56,
    "Absolute Visual Magnitude": 4.623,
    "Spectral Type": "F6IV-V",
    "Color Index": 0.52,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.94332,
    "y": -19.668423,
    "z": -3.730054,
    "vx": -0.00000196,
    "vy": -0.00000303,
    "vz": -0.00000346,
    "rarad": 4.09570210057176,
    "decrad": -0.153496713138232,
    "pmrarad": 6.35105921527777e-9,
    "pmdecrad": -1.17615798e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 76367,
    "lum": 1.23253709820334,
    "ra": 15.64443,
    "dec": -8.794714,
    "pmra": 1.31,
    "pmdec": -24.26,
    "rv": 4,
    "Asset Name": "ASTRID 002924",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Honest Lad",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00000196,
    "y_1": -0.00000303,
    "z_1": -0.00000346,
    "Distance in Parsecs_1": 24.3962,
    "HYG Star ID_1": 76367,
    "Hipparcos Catalogue_1": 76602,
    "Henry Draper Catalogue_1": 139460,
    "Harvard Revised Catalogue_1": 5815
  },
  {
    "Astrid #": "ASTRID 002925",
    "HYG Star ID": 101019,
    "Hipparcos Catalogue": 101345,
    "Henry Draper Catalogue": 195564,
    "Harvard Revised Catalogue": 7845,
    "Distance in Parsecs": 24.4021,
    "mag": 5.66,
    "Absolute Visual Magnitude": 3.723,
    "Spectral Type": "G3V",
    "Color Index": 0.689,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.834494,
    "y": -18.919995,
    "z": -4.175886,
    "vx": 0.00003555,
    "vy": 0.00001362,
    "vz": 0.00001079,
    "rarad": 5.377337087336,
    "decrad": -0.171973986383637,
    "pmrarad": 0.00000149123840002083,
    "pmdecrad": 5.1424187e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 101019,
    "lum": 2.82357936911029,
    "ra": 20.539915,
    "dec": -9.853384,
    "pmra": 307.59,
    "pmdec": 106.07,
    "rv": 9,
    "Asset Name": "ASTRID 002925",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Fire",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00003555,
    "y_1": 0.00001362,
    "z_1": 0.00001079,
    "Distance in Parsecs_1": 24.4021,
    "HYG Star ID_1": 101019,
    "Hipparcos Catalogue_1": 101345,
    "Henry Draper Catalogue_1": 195564,
    "Harvard Revised Catalogue_1": 7845
  },
  {
    "Astrid #": "ASTRID 002926",
    "HYG Star ID": 12673,
    "Hipparcos Catalogue": 12706,
    "Henry Draper Catalogue": 16970,
    "Harvard Revised Catalogue": 804,
    "Gliese Catalog": "Gl 106.1A",
    "Distance in Parsecs": 24.4081,
    "mag": 3.47,
    "Absolute Visual Magnitude": 1.532,
    "Spectral Type": "A3V",
    "Color Index": 0.093,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 18.440324,
    "y": 15.931398,
    "z": 1.377729,
    "vx": 0.00000812,
    "vy": -0.00001588,
    "vz": -0.00001746,
    "rarad": 0.712533510264116,
    "decrad": 0.0564756864727112,
    "pmrarad": -7.099126724375e-7,
    "pmdecrad": -7.04288833e-7,
    "bayer": "Gam",
    "flam": 86,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12673,
    "base": "Gl 106.1",
    "lum": 21.2422247738869,
    "bf": "86Gam Cet",
    "ra": 2.721678,
    "dec": 3.235818,
    "pmra": -146.43,
    "pmdec": -145.27,
    "rv": -5.1,
    "Asset Name": "ASTRID 002926",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Mohawk",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000812,
    "y_1": -0.00001588,
    "z_1": -0.00001746,
    "Distance in Parsecs_1": 24.4081,
    "HYG Star ID_1": 12673,
    "Hipparcos Catalogue_1": 12706,
    "Henry Draper Catalogue_1": 16970,
    "Harvard Revised Catalogue_1": 804,
    "Gliese Catalog_1": "Gl 106.1A"
  },
  {
    "Astrid #": "ASTRID 002927",
    "HYG Star ID": 35845,
    "Hipparcos Catalogue": 35943,
    "Distance in Parsecs": 24.4141,
    "mag": 10.34,
    "Absolute Visual Magnitude": 8.402,
    "Spectral Type": "M0V:",
    "Color Index": 1.365,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.380089,
    "y": 21.669385,
    "z": -7.500677,
    "vx": 0.00000598,
    "vy": 6.5e-7,
    "vz": -0.00000479,
    "rarad": 1.93980657396764,
    "decrad": -0.312278010446142,
    "pmrarad": -2.37946554416666e-7,
    "pmdecrad": -2.06336702e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 35845,
    "lum": 0.0379489706207635,
    "ra": 7.409515,
    "dec": -17.892212,
    "pmra": -49.08,
    "pmdec": -42.56,
    "rv": 0,
    "Asset Name": "ASTRID 002927",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Shinobi",
    "Hair": "Bandana",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00000598,
    "y_1": 6.5e-7,
    "z_1": -0.00000479,
    "Distance in Parsecs_1": 24.4141,
    "HYG Star ID_1": 35845,
    "Hipparcos Catalogue_1": 35943
  },
  {
    "Astrid #": "ASTRID 002928",
    "HYG Star ID": 64345,
    "Hipparcos Catalogue": 64550,
    "Henry Draper Catalogue": 114853,
    "Distance in Parsecs": 24.42,
    "mag": 6.93,
    "Absolute Visual Magnitude": 4.991,
    "Spectral Type": "G2V",
    "Color Index": 0.643,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.325121,
    "y": -5.452048,
    "z": -17.323452,
    "vx": 0.00000469,
    "vy": 0.0000153,
    "vz": -0.00000923,
    "rarad": 3.46391514569884,
    "decrad": -0.788640977905448,
    "pmrarad": -5.33634418187499e-7,
    "pmdecrad": -5.36446337e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 64345,
    "lum": 0.878213279898006,
    "ra": 13.231181,
    "dec": -45.1858,
    "pmra": -110.07,
    "pmdec": -110.65,
    "rv": 0,
    "Asset Name": "ASTRID 002928",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Pixie",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000469,
    "y_1": 0.0000153,
    "z_1": -0.00000923,
    "Distance in Parsecs_1": 24.42,
    "HYG Star ID_1": 64345,
    "Hipparcos Catalogue_1": 64550,
    "Henry Draper Catalogue_1": 114853
  },
  {
    "Astrid #": "ASTRID 002929",
    "HYG Star ID": 104846,
    "Hipparcos Catalogue": 105184,
    "Henry Draper Catalogue": 202628,
    "Gliese Catalog": "Gl 825.2",
    "Distance in Parsecs": 24.42,
    "mag": 6.75,
    "Absolute Visual Magnitude": 4.811,
    "Spectral Type": "G5V",
    "Color Index": 0.637,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.529293,
    "y": -11.508786,
    "z": -16.758494,
    "vx": 0.00002599,
    "vy": 0.00001551,
    "vz": -0.00000562,
    "rarad": 5.57830620989917,
    "decrad": -0.756332307582097,
    "pmrarad": 0.00000117358847652083,
    "pmdecrad": 1.06562046e-7,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 104846,
    "lum": 1.036573256767,
    "ra": 21.30756,
    "dec": -43.334649,
    "pmra": 242.07,
    "pmdec": 21.98,
    "rv": 10.7,
    "Asset Name": "ASTRID 002929",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Serious Shinobi",
    "Hair": "Hachimaki",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": 0.00002599,
    "y_1": 0.00001551,
    "z_1": -0.00000562,
    "Distance in Parsecs_1": 24.42,
    "HYG Star ID_1": 104846,
    "Hipparcos Catalogue_1": 105184,
    "Henry Draper Catalogue_1": 202628,
    "Gliese Catalog_1": "Gl 825.2"
  },
  {
    "Astrid #": "ASTRID 002930",
    "HYG Star ID": 93156,
    "Hipparcos Catalogue": 93449,
    "Distance in Parsecs": 24.432,
    "mag": 11.57,
    "Absolute Visual Magnitude": 9.63,
    "Spectral Type": "A5IIevar",
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 5.209019,
    "y": -18.816853,
    "z": -14.687233,
    "vx": -0.00000296,
    "vy": -0.00000456,
    "vz": 0.00000479,
    "rarad": 4.98245361240767,
    "decrad": -0.644936107760067,
    "pmrarad": -1.66581980638888e-7,
    "pmdecrad": 2.45170278e-7,
    "con": "CrA",
    "Full Constellation Name Formula 1": "Corona Australis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Australis",
    "comp": 1,
    "comp_primary": 93156,
    "lum": 0.0122461619926504,
    "var": "R",
    "var_min": 12.259,
    "var_max": 10.589,
    "ra": 19.031571,
    "dec": -36.952117,
    "pmra": -34.36,
    "pmdec": 50.57,
    "rv": 0,
    "Asset Name": "ASTRID 002930",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Windy",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Australis",
    "x_1": -0.00000296,
    "y_1": -0.00000456,
    "z_1": 0.00000479,
    "Distance in Parsecs_1": 24.432,
    "HYG Star ID_1": 93156,
    "Hipparcos Catalogue_1": 93449
  },
  {
    "Astrid #": "ASTRID 002931",
    "HYG Star ID": 106629,
    "Hipparcos Catalogue": 106972,
    "Gliese Catalog": "NN 4210",
    "Distance in Parsecs": 24.432,
    "mag": 11.62,
    "Absolute Visual Magnitude": 9.68,
    "Spectral Type": "M3:",
    "Color Index": 1.515,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.729026,
    "y": -12.424801,
    "z": 11.323806,
    "vx": 0.00002944,
    "vy": 0.00001997,
    "vz": -0.00002418,
    "rarad": 5.67190974559949,
    "decrad": 0.481921387004626,
    "pmrarad": 0.00000136121137089583,
    "pmdecrad": -0.000001116913757,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 106629,
    "lum": 0.0116949939101987,
    "var_min": 11.706,
    "var_max": 11.486,
    "ra": 21.665099,
    "dec": 27.612062,
    "pmra": 280.77,
    "pmdec": -230.38,
    "rv": 0,
    "Asset Name": "ASTRID 002931",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Middle Part",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00002944,
    "y_1": 0.00001997,
    "z_1": -0.00002418,
    "Distance in Parsecs_1": 24.432,
    "HYG Star ID_1": 106629,
    "Hipparcos Catalogue_1": 106972,
    "Gliese Catalog_1": "NN 4210"
  },
  {
    "Astrid #": "ASTRID 002932",
    "HYG Star ID": 4444,
    "Hipparcos Catalogue": 4454,
    "Henry Draper Catalogue": 5351,
    "Gliese Catalog": "NN 3066",
    "Distance in Parsecs": 24.4499,
    "mag": 9.12,
    "Absolute Visual Magnitude": 7.179,
    "Spectral Type": "K4V",
    "Color Index": 1.025,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.474835,
    "y": 2.155228,
    "z": 22.832174,
    "vx": -0.00001326,
    "vy": 0.0000841,
    "vz": -0.00005712,
    "rarad": 0.248975743185401,
    "decrad": 1.20503557389563,
    "pmrarad": 0.00000346767833154166,
    "pmdecrad": -0.000001136354785,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 4444,
    "lum": 0.117057703596313,
    "ra": 0.951017,
    "dec": 69.043453,
    "pmra": 715.26,
    "pmdec": -234.39,
    "rv": -49.4,
    "Asset Name": "ASTRID 002932",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Honest Lad",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00001326,
    "y_1": 0.0000841,
    "z_1": -0.00005712,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 4444,
    "Hipparcos Catalogue_1": 4454,
    "Henry Draper Catalogue_1": 5351,
    "Gliese Catalog_1": "NN 3066"
  },
  {
    "Astrid #": "ASTRID 002933",
    "HYG Star ID": 53754,
    "Hipparcos Catalogue": 53910,
    "Henry Draper Catalogue": 95418,
    "Harvard Revised Catalogue": 4295,
    "Gliese Catalog": "Wo 9343",
    "Proper Name": "Merak",
    "Distance in Parsecs": 24.4499,
    "mag": 2.34,
    "Absolute Visual Magnitude": 0.399,
    "Spectral Type": "A1V",
    "Color Index": 0.033,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -13.103033,
    "y": 3.398358,
    "z": 20.360601,
    "vx": 0.00000737,
    "vy": -0.00001191,
    "vz": -0.00000801,
    "rarad": 2.88782456911495,
    "decrad": 0.984058986291181,
    "pmrarad": 3.95898851541666e-7,
    "pmdecrad": 1.63576135e-7,
    "bayer": "Bet",
    "flam": 48,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 53754,
    "lum": 60.3114819617817,
    "bf": "48Bet UMa",
    "ra": 11.030677,
    "dec": 56.382427,
    "pmra": 81.66,
    "pmdec": 33.74,
    "rv": -12,
    "Asset Name": "ASTRID 002933",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Serious Shinobi",
    "Hair": "Shinobi",
    "Outfit": "Sage Mode",
    "Special": "Neko Tail",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Merak",
    "Constellation": "Ursa Major",
    "x_1": 0.00000737,
    "y_1": -0.00001191,
    "z_1": -0.00000801,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 53754,
    "Hipparcos Catalogue_1": 53910,
    "Henry Draper Catalogue_1": 95418,
    "Harvard Revised Catalogue_1": 4295,
    "Gliese Catalog_1": "Wo 9343"
  },
  {
    "Astrid #": "ASTRID 002934",
    "HYG Star ID": 94042,
    "Hipparcos Catalogue": 94336,
    "Henry Draper Catalogue": 179957,
    "Harvard Revised Catalogue": 7293,
    "Gliese Catalog": "Wo 9648B",
    "Distance in Parsecs": 24.4499,
    "mag": 5.85,
    "Absolute Visual Magnitude": 3.909,
    "Spectral Type": "G4V+...",
    "Color Index": 0.666,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.876505,
    "y": -14.989681,
    "z": 18.689803,
    "vx": -0.00004901,
    "vy": 0.00007211,
    "vz": 0.0000155,
    "rarad": 5.02691882680618,
    "decrad": 0.870147083825946,
    "pmrarad": -9.93965007875e-7,
    "pmdecrad": 0.000003026837251,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 119271,
    "base": "Wo 9648",
    "lum": 2.37903044588062,
    "ra": 19.201416,
    "dec": 49.855755,
    "pmra": -205.02,
    "pmdec": 624.33,
    "rv": -41.2,
    "Asset Name": "ASTRID 002934",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Sweetheart",
    "Outfit": "Sharky",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00004901,
    "y_1": 0.00007211,
    "z_1": 0.0000155,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 94042,
    "Hipparcos Catalogue_1": 94336,
    "Henry Draper Catalogue_1": 179957,
    "Harvard Revised Catalogue_1": 7293,
    "Gliese Catalog_1": "Wo 9648B"
  },
  {
    "Astrid #": "ASTRID 002935",
    "HYG Star ID": 118562,
    "Gliese Catalog": "Gl 321.3B",
    "Distance in Parsecs": 24.4499,
    "mag": 5,
    "Absolute Visual Magnitude": 3.059,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.299716,
    "y": 10.632594,
    "z": -19.956473,
    "vx": 0.00000302,
    "vy": -0.00000727,
    "vz": -0.00000528,
    "rarad": 2.28941987572128,
    "decrad": -0.954836024112349,
    "pmrarad": 1.02974425749999e-7,
    "pmdecrad": -3.73922247e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 2,
    "comp_primary": 42794,
    "base": "Gl 321.3",
    "lum": 5.20475151170522,
    "ra": 8.74494,
    "dec": -54.708074,
    "pmra": 21.24,
    "pmdec": -77.13,
    "rv": 0,
    "Asset Name": "ASTRID 002935",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Straw Hat",
    "Outfit": "Mecha",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000302,
    "y_1": -0.00000727,
    "z_1": -0.00000528,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 118562,
    "Gliese Catalog_1": "Gl 321.3B"
  },
  {
    "Astrid #": "ASTRID 002936",
    "HYG Star ID": 118563,
    "Gliese Catalog": "Gl 321.3C",
    "Distance in Parsecs": 24.4499,
    "mag": 11,
    "Absolute Visual Magnitude": 9.059,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.307446,
    "y": 10.63041,
    "z": -19.954033,
    "vx": 0.00000293,
    "vy": -0.00000732,
    "vz": -0.00000527,
    "rarad": 2.28993329907082,
    "decrad": -0.954663307086,
    "pmrarad": 1.069014165625e-7,
    "pmdecrad": -3.7282172e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 3,
    "comp_primary": 42794,
    "base": "Gl 321.3",
    "lum": 0.02072048897759,
    "ra": 8.746901,
    "dec": -54.698178,
    "pmra": 22.05,
    "pmdec": -76.9,
    "rv": 0,
    "Asset Name": "ASTRID 002936",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Shinobi",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000293,
    "y_1": -0.00000732,
    "z_1": -0.00000527,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 118563,
    "Gliese Catalog_1": "Gl 321.3C"
  },
  {
    "Astrid #": "ASTRID 002937",
    "HYG Star ID": 118564,
    "Gliese Catalog": "Gl 321.3D",
    "Distance in Parsecs": 24.4499,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.559,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.307446,
    "y": 10.63041,
    "z": -19.954033,
    "vx": 0.00000293,
    "vy": -0.00000732,
    "vz": -0.00000527,
    "rarad": 2.28993329907082,
    "decrad": -0.954663307086,
    "pmrarad": 1.069014165625e-7,
    "pmdecrad": -3.7282172e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118564,
    "lum": 0.002072048897759,
    "ra": 8.746901,
    "dec": -54.698178,
    "pmra": 22.05,
    "pmdec": -76.9,
    "rv": 0,
    "Asset Name": "ASTRID 002937",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Tiny Crown",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000293,
    "y_1": -0.00000732,
    "z_1": -0.00000527,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 118564,
    "Gliese Catalog_1": "Gl 321.3D"
  },
  {
    "Astrid #": "ASTRID 002938",
    "HYG Star ID": 118709,
    "Gliese Catalog": "NN 3624",
    "Distance in Parsecs": 24.4499,
    "mag": 19.11,
    "Absolute Visual Magnitude": 17.169,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -23.197749,
    "y": 7.419388,
    "z": 2.148199,
    "vx": 0.0000084,
    "vy": 0.00004331,
    "vz": -0.00005877,
    "rarad": 2.83204223888742,
    "decrad": 0.0879746859649,
    "pmrarad": -0.00000179216225154166,
    "pmdecrad": -0.000002413140702,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118709,
    "lum": 0.0000118140825192088,
    "ra": 10.817605,
    "dec": 5.040578,
    "pmra": -369.66,
    "pmdec": -497.75,
    "rv": 0,
    "Asset Name": "ASTRID 002938",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Lovestruck",
    "Hair": "Medium Bob",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000084,
    "y_1": 0.00004331,
    "z_1": -0.00005877,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 118709,
    "Gliese Catalog_1": "NN 3624"
  },
  {
    "Astrid #": "ASTRID 002939",
    "HYG Star ID": 118998,
    "Gliese Catalog": "NN 3882",
    "Distance in Parsecs": 24.4499,
    "mag": 14.73,
    "Absolute Visual Magnitude": 12.789,
    "Spectral Type": "m",
    "Color Index": 1.68,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.893977,
    "y": -16.505812,
    "z": 6.320546,
    "vx": -0.00004171,
    "vy": 0.00005135,
    "vz": 0.00002266,
    "rarad": 3.9153742928541,
    "decrad": 0.2614795511922,
    "pmrarad": -0.00000269464291789583,
    "pmdecrad": 9.59523844e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118998,
    "lum": 0.000667421203858476,
    "ra": 14.955628,
    "dec": 14.981675,
    "pmra": -555.81,
    "pmdec": 197.92,
    "rv": 0,
    "Asset Name": "ASTRID 002939",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Round Brush",
    "Outfit": "Boxer",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004171,
    "y_1": 0.00005135,
    "z_1": 0.00002266,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 118998,
    "Gliese Catalog_1": "NN 3882"
  },
  {
    "Astrid #": "ASTRID 002940",
    "HYG Star ID": 119078,
    "Gliese Catalog": "Wo 9557B",
    "Distance in Parsecs": 24.4499,
    "mag": 11,
    "Absolute Visual Magnitude": 9.059,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.955846,
    "y": -17.044462,
    "z": 15.620129,
    "vx": -0.0000139,
    "vy": 0.00000664,
    "vz": 1.7e-7,
    "rarad": 4.27568626753483,
    "decrad": 0.6930190659684,
    "pmrarad": -6.30209303354166e-7,
    "pmdecrad": 8.799368e-9,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 2,
    "comp_primary": 79766,
    "base": "Wo 9557",
    "lum": 0.02072048897759,
    "ra": 16.331919,
    "dec": 39.707068,
    "pmra": -129.99,
    "pmdec": 1.82,
    "rv": 0,
    "Asset Name": "ASTRID 002940",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Innocent",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.0000139,
    "y_1": 0.00000664,
    "z_1": 1.7e-7,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 119078,
    "Gliese Catalog_1": "Wo 9557B"
  },
  {
    "Astrid #": "ASTRID 002941",
    "HYG Star ID": 119552,
    "Gliese Catalog": "Gl 893.1",
    "Distance in Parsecs": 24.4499,
    "mag": 15.42,
    "Absolute Visual Magnitude": 13.479,
    "Spectral Type": "DQ6",
    "Color Index": 0.22,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 23.811588,
    "y": -4.799112,
    "z": -2.788264,
    "vx": -0.00000992,
    "vy": -0.00003855,
    "vz": -0.00001841,
    "rarad": 6.08430490071424,
    "decrad": -0.1142885436728,
    "pmrarad": -0.00000162572571500694,
    "pmdecrad": -7.58093456e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119552,
    "lum": 0.000353508613361997,
    "ra": 23.240333,
    "dec": -6.548251,
    "pmra": -335.33,
    "pmdec": -156.37,
    "rv": 0,
    "Asset Name": "ASTRID 002941",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Determined",
    "Hair": "Curly",
    "Outfit": "Spartan",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000992,
    "y_1": -0.00003855,
    "z_1": -0.00001841,
    "Distance in Parsecs_1": 24.4499,
    "HYG Star ID_1": 119552,
    "Gliese Catalog_1": "Gl 893.1"
  },
  {
    "Astrid #": "ASTRID 002942",
    "HYG Star ID": 112884,
    "Hipparcos Catalogue": 113244,
    "Gliese Catalog": "NN 4304A",
    "Distance in Parsecs": 24.4559,
    "mag": 11.23,
    "Absolute Visual Magnitude": 9.288,
    "Spectral Type": "M0V:",
    "Color Index": 1.433,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.388476,
    "y": -6.712392,
    "z": 2.452353,
    "vx": 0.00001475,
    "vy": 0.00003975,
    "vz": -0.00003182,
    "rarad": 6.00370126524729,
    "decrad": 0.100445345306316,
    "pmrarad": 0.00000172894254759722,
    "pmdecrad": -0.000001307736421,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 112884,
    "base": "NN 4304",
    "lum": 0.0167803107829095,
    "var_min": 11.286,
    "var_max": 11.136,
    "ra": 22.93245,
    "dec": 5.755094,
    "pmra": 356.62,
    "pmdec": -269.74,
    "rv": 0,
    "Asset Name": "ASTRID 002942",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Wavy",
    "Outfit": "Warlock",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00001475,
    "y_1": 0.00003975,
    "z_1": -0.00003182,
    "Distance in Parsecs_1": 24.4559,
    "HYG Star ID_1": 112884,
    "Hipparcos Catalogue_1": 113244,
    "Gliese Catalog_1": "NN 4304A"
  },
  {
    "Astrid #": "ASTRID 002943",
    "HYG Star ID": 28995,
    "Hipparcos Catalogue": 29067,
    "Gliese Catalog": "Gl 226.2",
    "Distance in Parsecs": 24.4678,
    "mag": 9.75,
    "Absolute Visual Magnitude": 7.807,
    "Spectral Type": "K8",
    "Color Index": 1.25,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.317036,
    "y": 9.169504,
    "z": 22.682452,
    "vx": 0.00000528,
    "vy": 0.00001315,
    "vz": -0.00000348,
    "rarad": 1.60536097520775,
    "decrad": 1.18641928167536,
    "pmrarad": -2.34261970444444e-7,
    "pmdecrad": -5.44494244e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 28995,
    "lum": 0.0656447501951699,
    "var_min": 9.827,
    "var_max": 9.687,
    "ra": 6.132027,
    "dec": 67.976818,
    "pmra": -48.32,
    "pmdec": -112.31,
    "rv": 1.6,
    "Asset Name": "ASTRID 002943",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Wavy",
    "Outfit": "Rider",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000528,
    "y_1": 0.00001315,
    "z_1": -0.00000348,
    "Distance in Parsecs_1": 24.4678,
    "HYG Star ID_1": 28995,
    "Hipparcos Catalogue_1": 29067,
    "Gliese Catalog_1": "Gl 226.2"
  },
  {
    "Astrid #": "ASTRID 002944",
    "HYG Star ID": 17116,
    "Hipparcos Catalogue": 17157,
    "Distance in Parsecs": 24.4738,
    "mag": 9.59,
    "Absolute Visual Magnitude": 7.646,
    "Spectral Type": "K7",
    "Color Index": 1.267,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.377873,
    "y": 13.454096,
    "z": -18.166168,
    "vx": -0.00000455,
    "vy": 0.00001378,
    "vz": 0.00000786,
    "rarad": 0.962066139338343,
    "decrad": -0.836451631392038,
    "pmrarad": 4.74438667791666e-7,
    "pmdecrad": 4.79383767e-7,
    "con": "Hor",
    "Full Constellation Name Formula 1": "Horologium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Horologium",
    "comp": 1,
    "comp_primary": 17116,
    "lum": 0.0761377432456151,
    "ra": 3.674822,
    "dec": -47.925148,
    "pmra": 97.86,
    "pmdec": 98.88,
    "rv": 0,
    "Asset Name": "ASTRID 002944",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "Pirate Hat",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Horologium",
    "x_1": -0.00000455,
    "y_1": 0.00001378,
    "z_1": 0.00000786,
    "Distance in Parsecs_1": 24.4738,
    "HYG Star ID_1": 17116,
    "Hipparcos Catalogue_1": 17157
  },
  {
    "Astrid #": "ASTRID 002945",
    "HYG Star ID": 84349,
    "Hipparcos Catalogue": 84607,
    "Henry Draper Catalogue": 156668,
    "Distance in Parsecs": 24.4738,
    "mag": 8.43,
    "Absolute Visual Magnitude": 6.486,
    "Spectral Type": "K2",
    "Color Index": 1.015,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.921987,
    "y": -20.994858,
    "z": 11.94993,
    "vx": -0.00000612,
    "vy": 0.0000139,
    "vz": 0.0000224,
    "rarad": 4.52771102506851,
    "decrad": 0.510111329522826,
    "pmrarad": -3.50277884201388e-7,
    "pmdecrad": 0.000001049088323,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 84349,
    "lum": 0.221615432595971,
    "ra": 17.294582,
    "dec": 29.227226,
    "pmra": -72.25,
    "pmdec": 216.39,
    "rv": 0,
    "Asset Name": "ASTRID 002945",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Side Part",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000612,
    "y_1": 0.0000139,
    "z_1": 0.0000224,
    "Distance in Parsecs_1": 24.4738,
    "HYG Star ID_1": 84349,
    "Hipparcos Catalogue_1": 84607,
    "Henry Draper Catalogue_1": 156668
  },
  {
    "Astrid #": "ASTRID 002946",
    "HYG Star ID": 13354,
    "Hipparcos Catalogue": 13388,
    "Henry Draper Catalogue": 17970,
    "Gliese Catalog": "NN 3187",
    "Distance in Parsecs": 24.4918,
    "mag": 8.09,
    "Absolute Visual Magnitude": 6.145,
    "Spectral Type": "K1V",
    "Color Index": 0.827,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.925578,
    "y": 13.958334,
    "z": -13.50063,
    "vx": 0.00000336,
    "vy": 0.00007335,
    "vz": -0.00003345,
    "rarad": 0.751919742563429,
    "decrad": -0.58382373033029,
    "pmrarad": 0.00000209400724905555,
    "pmdecrad": 4.3390824e-8,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 13354,
    "lum": 0.303389118419427,
    "ra": 2.872122,
    "dec": -33.450636,
    "pmra": 431.92,
    "pmdec": 8.95,
    "rv": 60.9,
    "Asset Name": "ASTRID 002946",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Shy Smile",
    "Hair": "Unkempt",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00000336,
    "y_1": 0.00007335,
    "z_1": -0.00003345,
    "Distance in Parsecs_1": 24.4918,
    "HYG Star ID_1": 13354,
    "Hipparcos Catalogue_1": 13388,
    "Henry Draper Catalogue_1": 17970,
    "Gliese Catalog_1": "NN 3187"
  },
  {
    "Astrid #": "ASTRID 002947",
    "HYG Star ID": 72967,
    "Hipparcos Catalogue": 73194,
    "Distance in Parsecs": 24.5038,
    "mag": 11.43,
    "Absolute Visual Magnitude": 9.484,
    "Spectral Type": "M0",
    "Color Index": 1.408,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.403932,
    "y": -17.036427,
    "z": -2.701763,
    "vx": 0.00000369,
    "vy": 2.1e-7,
    "vz": -0.00002509,
    "rarad": 3.91632046908926,
    "decrad": -0.110483555201215,
    "pmrarad": 9.90959163055555e-8,
    "pmdecrad": -0.000001030422996,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 72967,
    "lum": 0.0140087698395458,
    "var_min": 11.525,
    "var_max": 11.305,
    "ra": 14.959242,
    "dec": -6.330241,
    "pmra": 20.44,
    "pmdec": -212.54,
    "rv": 0,
    "Asset Name": "ASTRID 002947",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined Neko",
    "Hair": "Fire",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000369,
    "y_1": 2.1e-7,
    "z_1": -0.00002509,
    "Distance in Parsecs_1": 24.5038,
    "HYG Star ID_1": 72967,
    "Hipparcos Catalogue_1": 73194
  },
  {
    "Astrid #": "ASTRID 002948",
    "HYG Star ID": 113706,
    "Hipparcos Catalogue": 114066,
    "Gliese Catalog": "Wo 9809",
    "Distance in Parsecs": 24.5038,
    "mag": 10.87,
    "Absolute Visual Magnitude": 8.924,
    "Spectral Type": "M0",
    "Color Index": 1.44,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.473419,
    "y": -2.510545,
    "z": 22.010017,
    "vx": 0.0000108,
    "vy": 0.0000183,
    "vz": -0.00000305,
    "rarad": 6.0479087794489,
    "decrad": 1.1157228532084,
    "pmrarad": 8.29079875118055e-7,
    "pmdecrad": -2.8351904e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 113706,
    "lum": 0.0234638892446413,
    "var_min": 10.963,
    "var_max": 10.793,
    "ra": 23.10131,
    "dec": 63.926211,
    "pmra": 171.01,
    "pmdec": -58.48,
    "rv": 0,
    "Asset Name": "ASTRID 002948",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Unkempt",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.0000108,
    "y_1": 0.0000183,
    "z_1": -0.00000305,
    "Distance in Parsecs_1": 24.5038,
    "HYG Star ID_1": 113706,
    "Hipparcos Catalogue_1": 114066,
    "Gliese Catalog_1": "Wo 9809"
  },
  {
    "Astrid #": "ASTRID 002949",
    "HYG Star ID": 73135,
    "Hipparcos Catalogue": 73362,
    "Gliese Catalog": "Gl 571",
    "Distance in Parsecs": 24.5158,
    "mag": 10.17,
    "Absolute Visual Magnitude": 8.223,
    "Spectral Type": "K7V",
    "Color Index": 1.318,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.475577,
    "y": -12.424412,
    "z": -17.059232,
    "vx": -0.00000471,
    "vy": 0.00004,
    "vz": -0.00002231,
    "rarad": 3.92494041514468,
    "decrad": -0.769598638963677,
    "pmrarad": -0.00000129164060773611,
    "pmdecrad": -0.000001360338706,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 73135,
    "lum": 0.0447507172047655,
    "ra": 14.992168,
    "dec": -44.094754,
    "pmra": -266.42,
    "pmdec": -280.59,
    "rv": -2.3,
    "Asset Name": "ASTRID 002949",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Ninja",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00000471,
    "y_1": 0.00004,
    "z_1": -0.00002231,
    "Distance in Parsecs_1": 24.5158,
    "HYG Star ID_1": 73135,
    "Hipparcos Catalogue_1": 73362,
    "Gliese Catalog_1": "Gl 571"
  },
  {
    "Astrid #": "ASTRID 002950",
    "HYG Star ID": 57320,
    "Hipparcos Catalogue": 57493,
    "Distance in Parsecs": 24.5218,
    "mag": 9.51,
    "Absolute Visual Magnitude": 7.562,
    "Spectral Type": "M0V:p",
    "Color Index": 1.252,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.416833,
    "y": 0.871496,
    "z": 19.049421,
    "vx": -0.00001043,
    "vy": -0.0000068,
    "vz": -0.00000813,
    "rarad": 3.08512236365948,
    "decrad": 0.889625783209583,
    "pmrarad": 3.00875370152777e-7,
    "pmdecrad": -5.26604619e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 57320,
    "lum": 0.0822621393883109,
    "ra": 11.784299,
    "dec": 50.971803,
    "pmra": 62.06,
    "pmdec": -108.62,
    "rv": 0,
    "Asset Name": "ASTRID 002950",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Side Part",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001043,
    "y_1": -0.0000068,
    "z_1": -0.00000813,
    "Distance in Parsecs_1": 24.5218,
    "HYG Star ID_1": 57320,
    "Hipparcos Catalogue_1": 57493
  },
  {
    "Astrid #": "ASTRID 002951",
    "HYG Star ID": 72754,
    "Hipparcos Catalogue": 72981,
    "Gliese Catalog": "NN 3874",
    "Distance in Parsecs": 24.5218,
    "mag": 11.28,
    "Absolute Visual Magnitude": 9.332,
    "Spectral Type": "K:",
    "Color Index": 1.377,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.455496,
    "y": -16.694151,
    "z": 4.234345,
    "vx": -0.00003198,
    "vy": 0.00002159,
    "vz": -0.00004671,
    "rarad": 3.9047003544205,
    "decrad": 0.173546630063442,
    "pmrarad": -0.00000153778051335416,
    "pmdecrad": -0.000001934164178,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72754,
    "lum": 0.0161138753572,
    "var_min": 11.36,
    "var_max": 11.19,
    "ra": 14.914857,
    "dec": 9.943489,
    "pmra": -317.19,
    "pmdec": -398.95,
    "rv": 0,
    "Asset Name": "ASTRID 002951",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Hachimaki",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00003198,
    "y_1": 0.00002159,
    "z_1": -0.00004671,
    "Distance in Parsecs_1": 24.5218,
    "HYG Star ID_1": 72754,
    "Hipparcos Catalogue_1": 72981,
    "Gliese Catalog_1": "NN 3874"
  },
  {
    "Astrid #": "ASTRID 002952",
    "HYG Star ID": 59099,
    "Hipparcos Catalogue": 59280,
    "Henry Draper Catalogue": 105631,
    "Gliese Catalog": "NN 3706",
    "Distance in Parsecs": 24.5278,
    "mag": 7.46,
    "Absolute Visual Magnitude": 5.512,
    "Spectral Type": "K0V",
    "Color Index": 0.794,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.703357,
    "y": -0.785614,
    "z": 15.848675,
    "vx": -0.00000598,
    "vy": 0.00003712,
    "vz": -0.00000426,
    "rarad": 3.1835760448957,
    "decrad": 0.702530893095748,
    "pmrarad": -0.00000152241191968055,
    "pmdecrad": -2.48903343e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 59099,
    "lum": 0.543500565129446,
    "ra": 12.160365,
    "dec": 40.252055,
    "pmra": -314.02,
    "pmdec": -51.34,
    "rv": 0.6,
    "Asset Name": "ASTRID 002952",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Bob",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000598,
    "y_1": 0.00003712,
    "z_1": -0.00000426,
    "Distance in Parsecs_1": 24.5278,
    "HYG Star ID_1": 59099,
    "Hipparcos Catalogue_1": 59280,
    "Henry Draper Catalogue_1": 105631,
    "Gliese Catalog_1": "NN 3706"
  },
  {
    "Astrid #": "ASTRID 002953",
    "HYG Star ID": 80679,
    "Hipparcos Catalogue": 80925,
    "Henry Draper Catalogue": 148704,
    "Gliese Catalog": "Gl 629",
    "Distance in Parsecs": 24.5278,
    "mag": 7.24,
    "Absolute Visual Magnitude": 5.292,
    "Spectral Type": "K0V",
    "Color Index": 0.858,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.177758,
    "y": -17.654687,
    "z": -15.439644,
    "vx": -0.00002261,
    "vy": 0.00007954,
    "vz": 0.00000177,
    "rarad": 4.3262421426066,
    "decrad": -0.680892655651305,
    "pmrarad": -0.00000207524495961805,
    "pmdecrad": -0.000001616417292,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 80679,
    "lum": 0.665579596367229,
    "ra": 16.525028,
    "dec": -39.012275,
    "pmra": -428.05,
    "pmdec": -333.41,
    "rv": -50.6,
    "Asset Name": "ASTRID 002953",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Middle Part",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00002261,
    "y_1": 0.00007954,
    "z_1": 0.00000177,
    "Distance in Parsecs_1": 24.5278,
    "HYG Star ID_1": 80679,
    "Hipparcos Catalogue_1": 80925,
    "Henry Draper Catalogue_1": 148704,
    "Gliese Catalog_1": "Gl 629"
  },
  {
    "Astrid #": "ASTRID 002954",
    "HYG Star ID": 117794,
    "Hipparcos Catalogue": 118162,
    "Henry Draper Catalogue": 224465,
    "Gliese Catalog": "NN 4382",
    "Distance in Parsecs": 24.5278,
    "mag": 6.72,
    "Absolute Visual Magnitude": 4.772,
    "Spectral Type": "G5",
    "Color Index": 0.694,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.618329,
    "y": -0.128558,
    "z": 18.911976,
    "vx": -0.00002465,
    "vy": -0.00000524,
    "vz": 0.00001608,
    "rarad": 6.27495536138181,
    "decrad": 0.880478058039687,
    "pmrarad": -2.21899221590277e-7,
    "pmdecrad": 0.00000119109025,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 117794,
    "lum": 1.07448411664455,
    "ra": 23.968564,
    "dec": 50.447677,
    "pmra": -45.77,
    "pmdec": 245.68,
    "rv": -3.2,
    "Asset Name": "ASTRID 002954",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Lovestruck",
    "Hair": "Charmer",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00002465,
    "y_1": -0.00000524,
    "z_1": 0.00001608,
    "Distance in Parsecs_1": 24.5278,
    "HYG Star ID_1": 117794,
    "Hipparcos Catalogue_1": 118162,
    "Henry Draper Catalogue_1": 224465,
    "Gliese Catalog_1": "NN 4382"
  },
  {
    "Astrid #": "ASTRID 002955",
    "HYG Star ID": 118589,
    "Gliese Catalog": "Gl 333.2B",
    "Distance in Parsecs": 24.5278,
    "mag": 12.68,
    "Absolute Visual Magnitude": 10.732,
    "Spectral Type": "dM4",
    "Color Index": 1.47,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -17.333104,
    "y": 17.206472,
    "z": 2.240759,
    "vx": 0.00004451,
    "vy": -0.00000568,
    "vz": -0.00002629,
    "rarad": 2.35986096948403,
    "decrad": 0.09149048123455,
    "pmrarad": -0.00000111415031928472,
    "pmdecrad": -9.34895309e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 44135,
    "base": "Gl 333.2",
    "lum": 0.00443812980308138,
    "ra": 9.014005,
    "dec": 5.242018,
    "pmra": -229.81,
    "pmdec": -192.84,
    "rv": -37,
    "Asset Name": "ASTRID 002955",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Braided Ponytail",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004451,
    "y_1": -0.00000568,
    "z_1": -0.00002629,
    "Distance in Parsecs_1": 24.5278,
    "HYG Star ID_1": 118589,
    "Gliese Catalog_1": "Gl 333.2B"
  },
  {
    "Astrid #": "ASTRID 002956",
    "HYG Star ID": 36721,
    "Hipparcos Catalogue": 36827,
    "Henry Draper Catalogue": 60491,
    "Distance in Parsecs": 24.5519,
    "mag": 8.16,
    "Absolute Visual Magnitude": 6.21,
    "Spectral Type": "K2V",
    "Color Index": 0.9,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.761725,
    "y": 22.334117,
    "z": -2.948175,
    "vx": 0.00000937,
    "vy": 0.00000342,
    "vz": -0.0000051,
    "rarad": 1.98285154919423,
    "decrad": -0.120369731474651,
    "pmrarad": -4.05692087888888e-7,
    "pmdecrad": -2.09197103e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 36721,
    "lum": 0.285759054337494,
    "var": "V867",
    "var_min": 8.196,
    "var_max": 8.146,
    "ra": 7.573935,
    "dec": -6.896678,
    "pmra": -83.68,
    "pmdec": -43.15,
    "rv": 0,
    "Asset Name": "ASTRID 002956",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Undercut",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00000937,
    "y_1": 0.00000342,
    "z_1": -0.0000051,
    "Distance in Parsecs_1": 24.5519,
    "HYG Star ID_1": 36721,
    "Hipparcos Catalogue_1": 36827,
    "Henry Draper Catalogue_1": 60491
  },
  {
    "Astrid #": "ASTRID 002957",
    "HYG Star ID": 75480,
    "Hipparcos Catalogue": 75710,
    "Gliese Catalog": "Gl 587.1",
    "Distance in Parsecs": 24.5519,
    "mag": 11.07,
    "Absolute Visual Magnitude": 9.12,
    "Spectral Type": "M0",
    "Color Index": 1.61,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -13.608403,
    "y": -17.421383,
    "z": 10.681879,
    "vx": 0.0000066,
    "vy": -0.00000905,
    "vz": -0.00000636,
    "rarad": 4.04925871711833,
    "decrad": 0.450119816605115,
    "pmrarad": 4.38756380902777e-7,
    "pmdecrad": -2.87930844e-7,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 75480,
    "lum": 0.0195884467350599,
    "var_min": 11.165,
    "var_max": 10.975,
    "ra": 15.467029,
    "dec": 25.789966,
    "pmra": 90.5,
    "pmdec": -59.39,
    "rv": 0,
    "Asset Name": "ASTRID 002957",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Naughty Dreams",
    "Hair": "Unkempt",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": 0.0000066,
    "y_1": -0.00000905,
    "z_1": -0.00000636,
    "Distance in Parsecs_1": 24.5519,
    "HYG Star ID_1": 75480,
    "Hipparcos Catalogue_1": 75710,
    "Gliese Catalog_1": "Gl 587.1"
  },
  {
    "Astrid #": "ASTRID 002958",
    "HYG Star ID": 64483,
    "Hipparcos Catalogue": 64690,
    "Henry Draper Catalogue": 113283,
    "Distance in Parsecs": 24.57,
    "mag": 7.11,
    "Absolute Visual Magnitude": 5.158,
    "Spectral Type": "G5IV-V",
    "Color Index": 0.71,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.989584,
    "y": -0.338043,
    "z": -24.547701,
    "vx": 0.00000914,
    "vy": 0.00003099,
    "vz": 0.0000032,
    "rarad": 3.47097668106803,
    "decrad": -1.52822219707192,
    "pmrarad": -0.00000107313508190972,
    "pmdecrad": -7.53836791e-7,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 64483,
    "lum": 0.753008710547588,
    "ra": 13.258154,
    "dec": -87.560682,
    "pmra": -221.35,
    "pmdec": -155.49,
    "rv": -3.9,
    "Asset Name": "ASTRID 002958",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Ram Horns",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": 0.00000914,
    "y_1": 0.00003099,
    "z_1": 0.0000032,
    "Distance in Parsecs_1": 24.57,
    "HYG Star ID_1": 64483,
    "Hipparcos Catalogue_1": 64690,
    "Henry Draper Catalogue_1": 113283
  },
  {
    "Astrid #": "ASTRID 002959",
    "HYG Star ID": 73405,
    "Hipparcos Catalogue": 73633,
    "Henry Draper Catalogue": 132899,
    "Distance in Parsecs": 24.57,
    "mag": 8.98,
    "Absolute Visual Magnitude": 7.028,
    "Spectral Type": "K3V",
    "Color Index": 1.145,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.736674,
    "y": -13.086202,
    "z": -16.438168,
    "vx": 0.00002064,
    "vy": 0.00000243,
    "vz": -0.00001793,
    "rarad": 3.94052394451376,
    "decrad": -0.732908418565036,
    "pmrarad": 5.32858716298611e-7,
    "pmdecrad": -9.81893147e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 73405,
    "lum": 0.134524070514863,
    "ra": 15.051693,
    "dec": -41.992559,
    "pmra": 109.91,
    "pmdec": -202.53,
    "rv": 0,
    "Asset Name": "ASTRID 002959",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Awkward",
    "Hair": "Afro",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00002064,
    "y_1": 0.00000243,
    "z_1": -0.00001793,
    "Distance in Parsecs_1": 24.57,
    "HYG Star ID_1": 73405,
    "Hipparcos Catalogue_1": 73633,
    "Henry Draper Catalogue_1": 132899
  },
  {
    "Astrid #": "ASTRID 002960",
    "HYG Star ID": 118020,
    "Gliese Catalog": "Wo 9029B",
    "Distance in Parsecs": 24.57,
    "mag": 15.5,
    "Absolute Visual Magnitude": 13.548,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.213836,
    "y": 4.04025,
    "z": -14.771249,
    "vx": -0.00002037,
    "vy": -0.00002509,
    "vz": -0.00003337,
    "rarad": 0.207268980126586,
    "decrad": -0.644989973627299,
    "pmrarad": -8.28837468277777e-7,
    "pmdecrad": -0.000001699412546,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 3696,
    "base": "Wo 9029",
    "lum": 0.000331741649694538,
    "ra": 0.791709,
    "dec": -36.955203,
    "pmra": -170.96,
    "pmdec": -350.53,
    "rv": 0,
    "Asset Name": "ASTRID 002960",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Lion Mane",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002037,
    "y_1": -0.00002509,
    "z_1": -0.00003337,
    "Distance in Parsecs_1": 24.57,
    "HYG Star ID_1": 118020,
    "Gliese Catalog_1": "Wo 9029B"
  },
  {
    "Astrid #": "ASTRID 002961",
    "HYG Star ID": 118192,
    "Gliese Catalog": "NN 3201",
    "Distance in Parsecs": 24.57,
    "mag": 14.7,
    "Absolute Visual Magnitude": 12.748,
    "Spectral Type": "m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.225737,
    "y": 13.154631,
    "z": 16.768181,
    "vx": -0.00000772,
    "vy": 0.0000538,
    "vz": -0.00003657,
    "rarad": 0.821949083428298,
    "decrad": 0.7511307430377,
    "pmrarad": 0.00000172108856597222,
    "pmdecrad": -0.000002036639246,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118192,
    "lum": 0.000693106545149936,
    "ra": 3.139614,
    "dec": 43.036621,
    "pmra": 355,
    "pmdec": -420.09,
    "rv": 0,
    "Asset Name": "ASTRID 002961",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Antlers",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000772,
    "y_1": 0.0000538,
    "z_1": -0.00003657,
    "Distance in Parsecs_1": 24.57,
    "HYG Star ID_1": 118192,
    "Gliese Catalog_1": "NN 3201"
  },
  {
    "Astrid #": "ASTRID 002962",
    "HYG Star ID": 118484,
    "Gliese Catalog": "NN 3451",
    "Distance in Parsecs": 24.57,
    "mag": 16.46,
    "Absolute Visual Magnitude": 14.508,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.047069,
    "y": 20.837807,
    "z": 9.360624,
    "vx": -0.0001118,
    "vy": -0.00001902,
    "vz": -0.00006562,
    "rarad": 1.98038677821265,
    "decrad": 0.39085356885445,
    "pmrarad": 0.00000448268425315277,
    "pmdecrad": -0.000002888902911,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118484,
    "lum": 0.000137025059712424,
    "ra": 7.56452,
    "dec": 22.39426,
    "pmra": 924.62,
    "pmdec": -595.88,
    "rv": 0,
    "Asset Name": "ASTRID 002962",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Spiky",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0001118,
    "y_1": -0.00001902,
    "z_1": -0.00006562,
    "Distance in Parsecs_1": 24.57,
    "HYG Star ID_1": 118484,
    "Gliese Catalog_1": "NN 3451"
  },
  {
    "Astrid #": "ASTRID 002963",
    "HYG Star ID": 119079,
    "Gliese Catalog": "NN 3951B",
    "Distance in Parsecs": 24.57,
    "mag": 14.51,
    "Absolute Visual Magnitude": 12.558,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.599964,
    "y": -5.474153,
    "z": 23.810895,
    "vx": 0.00000306,
    "vy": 0.00002996,
    "vz": 0.00000722,
    "rarad": 4.26904190226097,
    "decrad": 1.32157350984315,
    "pmrarad": -4.10394780590277e-7,
    "pmdecrad": 0.000001191836863,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119079,
    "base": "NN 3951",
    "lum": 0.000825657632621326,
    "ra": 16.306539,
    "dec": 75.720584,
    "pmra": -84.65,
    "pmdec": 245.83,
    "rv": 0,
    "Asset Name": "ASTRID 002963",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Braided Bun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000306,
    "y_1": 0.00002996,
    "z_1": 0.00000722,
    "Distance in Parsecs_1": 24.57,
    "HYG Star ID_1": 119079,
    "Gliese Catalog_1": "NN 3951B"
  },
  {
    "Astrid #": "ASTRID 002964",
    "HYG Star ID": 30789,
    "Hipparcos Catalogue": 30862,
    "Henry Draper Catalogue": 45391,
    "Distance in Parsecs": 24.5761,
    "mag": 7.15,
    "Absolute Visual Magnitude": 5.197,
    "Spectral Type": "G0",
    "Color Index": 0.613,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.473872,
    "y": 19.605279,
    "z": 14.611502,
    "vx": 0.00003298,
    "vy": 0.00001664,
    "vz": -0.00002345,
    "rarad": 1.69631995222307,
    "decrad": 0.636695845064016,
    "pmrarad": -0.00000141643164911111,
    "pmdecrad": -0.000001066735541,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 30789,
    "lum": 0.726440418592475,
    "ra": 6.479465,
    "dec": 36.479985,
    "pmra": -292.16,
    "pmdec": -220.03,
    "rv": -3.9,
    "Asset Name": "ASTRID 002964",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "Manbun",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00003298,
    "y_1": 0.00001664,
    "z_1": -0.00002345,
    "Distance in Parsecs_1": 24.5761,
    "HYG Star ID_1": 30789,
    "Hipparcos Catalogue_1": 30862,
    "Henry Draper Catalogue_1": 45391
  },
  {
    "Astrid #": "ASTRID 002965",
    "HYG Star ID": 114208,
    "Hipparcos Catalogue": 114570,
    "Henry Draper Catalogue": 219080,
    "Harvard Revised Catalogue": 8830,
    "Gliese Catalog": "Gl 891.1",
    "Distance in Parsecs": 24.5881,
    "mag": 4.53,
    "Absolute Visual Magnitude": 2.576,
    "Spectral Type": "F0V",
    "Color Index": 0.302,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.657659,
    "y": -3.288881,
    "z": 18.670848,
    "vx": 0.00000103,
    "vy": 0.00001072,
    "vz": 0.00001611,
    "rarad": 6.07614381857507,
    "decrad": 0.862300985193797,
    "pmrarad": 4.35265722402777e-7,
    "pmdecrad": 4.63336434e-7,
    "flam": 7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114208,
    "lum": 8.12082216249983,
    "bf": "7    And",
    "ra": 23.20916,
    "dec": 49.406207,
    "pmra": 89.78,
    "pmdec": 95.57,
    "rv": 11.2,
    "Asset Name": "ASTRID 002965",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Sweetheart",
    "Outfit": "Toga",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000103,
    "y_1": 0.00001072,
    "z_1": 0.00001611,
    "Distance in Parsecs_1": 24.5881,
    "HYG Star ID_1": 114208,
    "Hipparcos Catalogue_1": 114570,
    "Henry Draper Catalogue_1": 219080,
    "Harvard Revised Catalogue_1": 8830,
    "Gliese Catalog_1": "Gl 891.1"
  },
  {
    "Astrid #": "ASTRID 002966",
    "HYG Star ID": 6400,
    "Hipparcos Catalogue": 6414,
    "Distance in Parsecs": 24.5942,
    "mag": 10.3,
    "Absolute Visual Magnitude": 8.346,
    "Color Index": 1.295,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.261042,
    "y": 7.23826,
    "z": -13.47199,
    "vx": -0.00003306,
    "vy": 0.00003289,
    "vz": -0.0000296,
    "rarad": 0.359467097765062,
    "decrad": -0.57969767921758,
    "pmrarad": 0.00000172477314994444,
    "pmdecrad": -0.000001438539152,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 6400,
    "lum": 0.0399576556618805,
    "ra": 1.373063,
    "dec": -33.21423,
    "pmra": 355.76,
    "pmdec": -296.72,
    "rv": 0,
    "Asset Name": "ASTRID 002966",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Manbun",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00003306,
    "y_1": 0.00003289,
    "z_1": -0.0000296,
    "Distance in Parsecs_1": 24.5942,
    "HYG Star ID_1": 6400,
    "Hipparcos Catalogue_1": 6414
  },
  {
    "Astrid #": "ASTRID 002967",
    "HYG Star ID": 60678,
    "Hipparcos Catalogue": 60866,
    "Henry Draper Catalogue": 108581,
    "Gliese Catalog": "Gl 468",
    "Distance in Parsecs": 24.5942,
    "mag": 9.24,
    "Absolute Visual Magnitude": 7.286,
    "Spectral Type": "K4V",
    "Color Index": 1.218,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.170096,
    "y": -2.898984,
    "z": -7.721295,
    "vx": 0.0000065,
    "vy": -0.00001983,
    "vz": -0.00002085,
    "rarad": 3.26606291096165,
    "decrad": -0.319347917886547,
    "pmrarad": 8.32909903194444e-7,
    "pmdecrad": -8.55987034e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60678,
    "lum": 0.106071814963531,
    "ra": 12.475441,
    "dec": -18.297288,
    "pmra": 171.8,
    "pmdec": -176.56,
    "rv": 2.7,
    "Asset Name": "ASTRID 002967",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Curly",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000065,
    "y_1": -0.00001983,
    "z_1": -0.00002085,
    "Distance in Parsecs_1": 24.5942,
    "HYG Star ID_1": 60678,
    "Hipparcos Catalogue_1": 60866,
    "Henry Draper Catalogue_1": 108581,
    "Gliese Catalog_1": "Gl 468"
  },
  {
    "Astrid #": "ASTRID 002968",
    "HYG Star ID": 33286,
    "Hipparcos Catalogue": 33373,
    "Gliese Catalog": "NN 3415A",
    "Distance in Parsecs": 24.6002,
    "mag": 9.08,
    "Absolute Visual Magnitude": 7.125,
    "Spectral Type": "K5V",
    "Color Index": 1.117,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.591439,
    "y": 18.256142,
    "z": 15.837431,
    "vx": -0.00003125,
    "vy": 0.00006779,
    "vz": -0.00000634,
    "rarad": 1.81718575195141,
    "decrad": 0.699428982087466,
    "pmrarad": 5.59765875569444e-7,
    "pmdecrad": -0.000002117132861,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 33286,
    "base": "NN 3415",
    "lum": 0.123026877081238,
    "ra": 6.941138,
    "dec": 40.074329,
    "pmra": 115.46,
    "pmdec": -436.69,
    "rv": 50.9,
    "Asset Name": "ASTRID 002968",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Tiny Crown",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00003125,
    "y_1": 0.00006779,
    "z_1": -0.00000634,
    "Distance in Parsecs_1": 24.6002,
    "HYG Star ID_1": 33286,
    "Hipparcos Catalogue_1": 33373,
    "Gliese Catalog_1": "NN 3415A"
  },
  {
    "Astrid #": "ASTRID 002969",
    "HYG Star ID": 51326,
    "Hipparcos Catalogue": 51473,
    "Distance in Parsecs": 24.6184,
    "mag": 11.22,
    "Absolute Visual Magnitude": 9.264,
    "Color Index": 1.446,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -18.480522,
    "y": 7.574973,
    "z": -14.392904,
    "vx": 0.00001416,
    "vy": 0.00003728,
    "vz": 0.00000143,
    "rarad": 2.75259324377491,
    "decrad": -0.624436419742072,
    "pmrarad": -0.00000161956858126388,
    "pmdecrad": 7.1800906e-8,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 51326,
    "lum": 0.0171553664772834,
    "var_min": 11.308,
    "var_max": 11.118,
    "ra": 10.514132,
    "dec": -35.777571,
    "pmra": -334.06,
    "pmdec": 14.81,
    "rv": 0,
    "Asset Name": "ASTRID 002969",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Serious Slumber",
    "Hair": "Honest Lad",
    "Outfit": "Tats",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": 0.00001416,
    "y_1": 0.00003728,
    "z_1": 0.00000143,
    "Distance in Parsecs_1": 24.6184,
    "HYG Star ID_1": 51326,
    "Hipparcos Catalogue_1": 51473
  },
  {
    "Astrid #": "ASTRID 002970",
    "HYG Star ID": 33450,
    "Hipparcos Catalogue": 33537,
    "Henry Draper Catalogue": 51419,
    "Distance in Parsecs": 24.6305,
    "mag": 6.94,
    "Absolute Visual Magnitude": 4.983,
    "Spectral Type": "G5V",
    "Color Index": 0.62,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.71737,
    "y": 22.029756,
    "z": 9.416107,
    "vx": -0.00000441,
    "vy": -0.00000579,
    "vz": 0.00001087,
    "rarad": 1.82472337639955,
    "decrad": 0.392278230760541,
    "pmrarad": 2.32274234354166e-7,
    "pmdecrad": 4.77686919e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 33450,
    "lum": 0.88470809287053,
    "ra": 6.96993,
    "dec": 22.475887,
    "pmra": 47.91,
    "pmdec": 98.53,
    "rv": 0,
    "Asset Name": "ASTRID 002970",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Hurt",
    "Hair": "Audiohead",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000441,
    "y_1": -0.00000579,
    "z_1": 0.00001087,
    "Distance in Parsecs_1": 24.6305,
    "HYG Star ID_1": 33450,
    "Hipparcos Catalogue_1": 33537,
    "Henry Draper Catalogue_1": 51419
  },
  {
    "Astrid #": "ASTRID 002971",
    "HYG Star ID": 118161,
    "Gliese Catalog": "NN 3172",
    "Distance in Parsecs": 24.6305,
    "mag": 15.16,
    "Absolute Visual Magnitude": 13.203,
    "Spectral Type": "m",
    "Color Index": 1.67,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.039862,
    "y": 15.624393,
    "z": 0.153317,
    "vx": -0.00003902,
    "vy": 0.00004774,
    "vz": -0.00001885,
    "rarad": 0.687185186892319,
    "decrad": 0.0062247208081,
    "pmrarad": 0.00000250357784638888,
    "pmdecrad": -7.65423838e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118161,
    "lum": 0.000455826951540194,
    "ra": 2.624854,
    "dec": 0.35665,
    "pmra": 516.4,
    "pmdec": -157.88,
    "rv": 0,
    "Asset Name": "ASTRID 002971",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Windy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003902,
    "y_1": 0.00004774,
    "z_1": -0.00001885,
    "Distance in Parsecs_1": 24.6305,
    "HYG Star ID_1": 118161,
    "Gliese Catalog_1": "NN 3172"
  },
  {
    "Astrid #": "ASTRID 002972",
    "HYG Star ID": 13048,
    "Hipparcos Catalogue": 13081,
    "Henry Draper Catalogue": 17382,
    "Gliese Catalog": "Gl 113A",
    "Distance in Parsecs": 24.6366,
    "mag": 7.56,
    "Absolute Visual Magnitude": 5.602,
    "Spectral Type": "K1V",
    "Color Index": 0.82,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.293382,
    "y": 14.690255,
    "z": 11.211114,
    "vx": -0.00000846,
    "vy": 0.00003485,
    "vz": -0.00000842,
    "rarad": 0.733701800407624,
    "decrad": 0.472436751692085,
    "pmrarad": 0.00000128073229992361,
    "pmdecrad": -6.19349476e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13048,
    "base": "Gl 113",
    "lum": 0.500264862421005,
    "var": "BC",
    "var_min": 7.576,
    "var_max": 7.546,
    "ra": 2.802534,
    "dec": 27.068632,
    "pmra": 264.17,
    "pmdec": -127.75,
    "rv": 11.1,
    "Asset Name": "ASTRID 002972",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Curly",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000846,
    "y_1": 0.00003485,
    "z_1": -0.00000842,
    "Distance in Parsecs_1": 24.6366,
    "HYG Star ID_1": 13048,
    "Hipparcos Catalogue_1": 13081,
    "Henry Draper Catalogue_1": 17382,
    "Gliese Catalog_1": "Gl 113A"
  },
  {
    "Astrid #": "ASTRID 002973",
    "HYG Star ID": 66569,
    "Hipparcos Catalogue": 66781,
    "Henry Draper Catalogue": 119332,
    "Distance in Parsecs": 24.6366,
    "mag": 7.77,
    "Absolute Visual Magnitude": 5.812,
    "Spectral Type": "K0IV-V",
    "Color Index": 0.83,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.219251,
    "y": -5.77754,
    "z": 20.597865,
    "vx": 0.00000547,
    "vy": 0.00000496,
    "vz": 0.00000464,
    "rarad": 3.58326366884266,
    "decrad": 0.990075944996535,
    "pmrarad": -8.72179811319444e-8,
    "pmdecrad": 3.43199604e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 66569,
    "lum": 0.412287340722886,
    "ra": 13.687059,
    "dec": 56.727173,
    "pmra": -17.99,
    "pmdec": 70.79,
    "rv": 0,
    "Asset Name": "ASTRID 002973",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Bandana",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000547,
    "y_1": 0.00000496,
    "z_1": 0.00000464,
    "Distance in Parsecs_1": 24.6366,
    "HYG Star ID_1": 66569,
    "Hipparcos Catalogue_1": 66781,
    "Henry Draper Catalogue_1": 119332
  },
  {
    "Astrid #": "ASTRID 002974",
    "HYG Star ID": 34581,
    "Hipparcos Catalogue": 34673,
    "Gliese Catalog": "NN 3433",
    "Distance in Parsecs": 24.6427,
    "mag": 9.96,
    "Absolute Visual Magnitude": 8.002,
    "Color Index": 1.311,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.258351,
    "y": 22.734401,
    "z": -6.142152,
    "vx": 0.00005091,
    "vy": 0.00002573,
    "vz": 0.00003509,
    "rarad": 1.87983512667822,
    "decrad": -0.251904001923571,
    "pmrarad": -0.00000228575105971527,
    "pmdecrad": 0.000001470294449,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 34581,
    "lum": 0.0548529513941202,
    "ra": 7.180441,
    "dec": -14.433036,
    "pmra": -471.47,
    "pmdec": 303.27,
    "rv": 0,
    "Asset Name": "ASTRID 002974",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Prince",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00005091,
    "y_1": 0.00002573,
    "z_1": 0.00003509,
    "Distance in Parsecs_1": 24.6427,
    "HYG Star ID_1": 34581,
    "Hipparcos Catalogue_1": 34673,
    "Gliese Catalog_1": "NN 3433"
  },
  {
    "Astrid #": "ASTRID 002975",
    "HYG Star ID": 99560,
    "Hipparcos Catalogue": 99880,
    "Gliese Catalog": "Gl 786.1",
    "Distance in Parsecs": 24.6427,
    "mag": 9.98,
    "Absolute Visual Magnitude": 8.022,
    "Spectral Type": "M0",
    "Color Index": 1.266,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.073495,
    "y": -14.951845,
    "z": 16.799692,
    "vx": 0.00000356,
    "vy": -0.00000347,
    "vz": -0.00000523,
    "rarad": 5.30526497873175,
    "decrad": 0.750126064212425,
    "pmrarad": 4.11122001111111e-8,
    "pmdecrad": -2.8991858e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 99560,
    "lum": 0.0538517721997527,
    "ra": 20.264619,
    "dec": 42.979058,
    "pmra": 8.48,
    "pmdec": -59.8,
    "rv": 0,
    "Asset Name": "ASTRID 002975",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Chuckle",
    "Hair": "Greasy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00000356,
    "y_1": -0.00000347,
    "z_1": -0.00000523,
    "Distance in Parsecs_1": 24.6427,
    "HYG Star ID_1": 99560,
    "Hipparcos Catalogue_1": 99880,
    "Gliese Catalog_1": "Gl 786.1"
  },
  {
    "Astrid #": "ASTRID 002976",
    "HYG Star ID": 60805,
    "Hipparcos Catalogue": 60994,
    "Henry Draper Catalogue": 108799,
    "Harvard Revised Catalogue": 4758,
    "Gliese Catalog": "Gl 469.2A",
    "Distance in Parsecs": 24.6488,
    "mag": 6.37,
    "Absolute Visual Magnitude": 4.411,
    "Spectral Type": "G1/G2V",
    "Color Index": 0.591,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -23.772227,
    "y": -3.13807,
    "z": -5.709459,
    "vx": -0.00000448,
    "vy": 0.00003094,
    "vz": -0.00000675,
    "rarad": 3.27283988046327,
    "decrad": -0.233755157532028,
    "pmrarad": -0.00000126817562559722,
    "pmdecrad": -2.62575089e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60805,
    "base": "Gl 469.2",
    "lum": 1.49830421062265,
    "ra": 12.501327,
    "dec": -13.393184,
    "pmra": -261.58,
    "pmdec": -54.16,
    "rv": 1.9,
    "Asset Name": "ASTRID 002976",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Slick",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000448,
    "y_1": 0.00003094,
    "z_1": -0.00000675,
    "Distance in Parsecs_1": 24.6488,
    "HYG Star ID_1": 60805,
    "Hipparcos Catalogue_1": 60994,
    "Henry Draper Catalogue_1": 108799,
    "Harvard Revised Catalogue_1": 4758,
    "Gliese Catalog_1": "Gl 469.2A"
  },
  {
    "Astrid #": "ASTRID 002977",
    "HYG Star ID": 118773,
    "Gliese Catalog": "Gl 444B",
    "Distance in Parsecs": 24.6488,
    "mag": 14.2,
    "Absolute Visual Magnitude": 12.241,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -24.087436,
    "y": 1.360685,
    "z": -5.05048,
    "vx": 0.00000271,
    "vy": 0.0000241,
    "vz": -0.00000644,
    "rarad": 3.08516427825381,
    "decrad": -0.206359108678199,
    "pmrarad": -9.82474923645833e-7,
    "pmdecrad": -2.66933564e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 57321,
    "base": "Gl 444",
    "lum": 0.0011056050150387,
    "ra": 11.784459,
    "dec": -11.823506,
    "pmra": -202.65,
    "pmdec": -55.06,
    "rv": 0,
    "Asset Name": "ASTRID 002977",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Bandana",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000271,
    "y_1": 0.0000241,
    "z_1": -0.00000644,
    "Distance in Parsecs_1": 24.6488,
    "HYG Star ID_1": 118773,
    "Gliese Catalog_1": "Gl 444B"
  },
  {
    "Astrid #": "ASTRID 002978",
    "HYG Star ID": 34856,
    "Hipparcos Catalogue": 34950,
    "Henry Draper Catalogue": 55458,
    "Distance in Parsecs": 24.6548,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.44,
    "Spectral Type": "K1V",
    "Color Index": 0.827,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.078709,
    "y": 21.191311,
    "z": 10.423801,
    "vx": 0.00006066,
    "vy": -0.00002757,
    "vz": -0.00003289,
    "rarad": 1.8931829771526,
    "decrad": 0.436530863604008,
    "pmrarad": -0.0000019796397018125,
    "pmdecrad": -4.30902399e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 34856,
    "lum": 0.231206479017559,
    "ra": 7.231426,
    "dec": 25.011376,
    "pmra": -408.33,
    "pmdec": -88.88,
    "rv": -53.8,
    "Asset Name": "ASTRID 002978",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Battle Ready",
    "Hair": "Braid",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00006066,
    "y_1": -0.00002757,
    "z_1": -0.00003289,
    "Distance in Parsecs_1": 24.6548,
    "HYG Star ID_1": 34856,
    "Hipparcos Catalogue_1": 34950,
    "Henry Draper Catalogue_1": 55458
  },
  {
    "Astrid #": "ASTRID 002979",
    "HYG Star ID": 17294,
    "Hipparcos Catalogue": 17336,
    "Henry Draper Catalogue": 23052,
    "Distance in Parsecs": 24.6609,
    "mag": 7.07,
    "Absolute Visual Magnitude": 5.11,
    "Spectral Type": "G0",
    "Color Index": 0.659,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.28457,
    "y": 19.440674,
    "z": 7.330867,
    "vx": 0.00001368,
    "vy": -0.00000726,
    "vz": 0.00000409,
    "rarad": 0.971340275422456,
    "decrad": 0.301828433913567,
    "pmrarad": -6.24294576451389e-7,
    "pmdecrad": 1.37590122e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 17294,
    "lum": 0.787045789695098,
    "ra": 3.710247,
    "dec": 17.293495,
    "pmra": -128.77,
    "pmdec": 28.38,
    "rv": 2.8,
    "Asset Name": "ASTRID 002979",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Unkempt",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00001368,
    "y_1": -0.00000726,
    "z_1": 0.00000409,
    "Distance in Parsecs_1": 24.6609,
    "HYG Star ID_1": 17294,
    "Hipparcos Catalogue_1": 17336,
    "Henry Draper Catalogue_1": 23052
  },
  {
    "Astrid #": "ASTRID 002980",
    "HYG Star ID": 101654,
    "Hipparcos Catalogue": 101983,
    "Henry Draper Catalogue": 196378,
    "Harvard Revised Catalogue": 7875,
    "Gliese Catalog": "Gl 794.2",
    "Distance in Parsecs": 24.6609,
    "mag": 5.11,
    "Absolute Visual Magnitude": 3.15,
    "Spectral Type": "F8V",
    "Color Index": 0.544,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.795677,
    "y": -9.286908,
    "z": -21.473863,
    "vx": -0.00001965,
    "vy": 0.00008159,
    "vz": -0.00000531,
    "rarad": 5.41069876674413,
    "decrad": -1.0567775282369,
    "pmrarad": 0.00000151688504372222,
    "pmdecrad": -0.00000275854136,
    "bayer": "Phi-2",
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 101654,
    "lum": 4.78630092322638,
    "bf": "Phi2Pav",
    "ra": 20.667347,
    "dec": -60.548892,
    "pmra": 312.88,
    "pmdec": -568.99,
    "rv": -31.6,
    "Asset Name": "ASTRID 002980",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Mischievous",
    "Hair": "Braid",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00001965,
    "y_1": 0.00008159,
    "z_1": -0.00000531,
    "Distance in Parsecs_1": 24.6609,
    "HYG Star ID_1": 101654,
    "Hipparcos Catalogue_1": 101983,
    "Henry Draper Catalogue_1": 196378,
    "Harvard Revised Catalogue_1": 7875,
    "Gliese Catalog_1": "Gl 794.2"
  },
  {
    "Astrid #": "ASTRID 002981",
    "HYG Star ID": 37498,
    "Hipparcos Catalogue": 37606,
    "Henry Draper Catalogue": 62644,
    "Harvard Revised Catalogue": 2998,
    "Gliese Catalog": "Gl 284",
    "Distance in Parsecs": 24.6731,
    "mag": 5.04,
    "Absolute Visual Magnitude": 3.079,
    "Spectral Type": "G5IV",
    "Color Index": 0.765,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.553389,
    "y": 15.668249,
    "z": -17.499329,
    "vx": 0.00002,
    "vy": -0.00002006,
    "vz": -0.00006697,
    "rarad": 2.02000885132771,
    "decrad": -0.788419640314653,
    "pmrarad": -3.76991118e-7,
    "pmdecrad": -0.000002683007389,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 37498,
    "lum": 5.10975409054675,
    "ra": 7.715865,
    "dec": -45.173118,
    "pmra": -77.76,
    "pmdec": -553.41,
    "rv": 28,
    "Asset Name": "ASTRID 002981",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Undercut",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002,
    "y_1": -0.00002006,
    "z_1": -0.00006697,
    "Distance in Parsecs_1": 24.6731,
    "HYG Star ID_1": 37498,
    "Hipparcos Catalogue_1": 37606,
    "Henry Draper Catalogue_1": 62644,
    "Harvard Revised Catalogue_1": 2998,
    "Gliese Catalog_1": "Gl 284"
  },
  {
    "Astrid #": "ASTRID 002982",
    "HYG Star ID": 59592,
    "Hipparcos Catalogue": 59774,
    "Henry Draper Catalogue": 106591,
    "Harvard Revised Catalogue": 4660,
    "Gliese Catalog": "Gl 459",
    "Proper Name": "Megrez",
    "Distance in Parsecs": 24.6853,
    "mag": 3.32,
    "Absolute Visual Magnitude": 1.358,
    "Spectral Type": "A3Vvar",
    "Color Index": 0.077,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -13.402309,
    "y": -0.903455,
    "z": 20.710384,
    "vx": 0.00000906,
    "vy": -0.00001181,
    "vz": -0.00001099,
    "rarad": 3.20889759913916,
    "decrad": 0.995406944309545,
    "pmrarad": 5.02073047583333e-7,
    "pmdecrad": 3.7863948e-8,
    "bayer": "Del",
    "flam": 69,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 59592,
    "lum": 24.9344618809783,
    "bf": "69Del UMa",
    "ra": 12.257086,
    "dec": 57.032617,
    "pmra": 103.56,
    "pmdec": 7.81,
    "rv": -13.4,
    "Asset Name": "ASTRID 002982",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Laughing",
    "Hair": "Audiophile",
    "Outfit": "School Uniform",
    "Special": "Faerie",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "Megrez",
    "Constellation": "Ursa Major",
    "x_1": 0.00000906,
    "y_1": -0.00001181,
    "z_1": -0.00001099,
    "Distance in Parsecs_1": 24.6853,
    "HYG Star ID_1": 59592,
    "Hipparcos Catalogue_1": 59774,
    "Henry Draper Catalogue_1": 106591,
    "Harvard Revised Catalogue_1": 4660,
    "Gliese Catalog_1": "Gl 459"
  },
  {
    "Astrid #": "ASTRID 002983",
    "HYG Star ID": 60125,
    "Hipparcos Catalogue": 60310,
    "Henry Draper Catalogue": 107576,
    "Distance in Parsecs": 24.6853,
    "mag": 8.54,
    "Absolute Visual Magnitude": 6.578,
    "Spectral Type": "K4V",
    "Color Index": 1.064,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.527805,
    "y": -1.783816,
    "z": -16.214268,
    "vx": 0.00000857,
    "vy": -6.1e-7,
    "vz": -0.00000973,
    "rarad": 3.23757445981224,
    "decrad": -0.716618922580827,
    "pmrarad": 5.76928279861111e-8,
    "pmdecrad": -5.22580666e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 60125,
    "lum": 0.203610420113196,
    "ra": 12.366623,
    "dec": -41.05924,
    "pmra": 11.9,
    "pmdec": -107.79,
    "rv": 0,
    "Asset Name": "ASTRID 002983",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Warm Smile",
    "Hair": "Hachimaki",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000857,
    "y_1": -6.1e-7,
    "z_1": -0.00000973,
    "Distance in Parsecs_1": 24.6853,
    "HYG Star ID_1": 60125,
    "Hipparcos Catalogue_1": 60310,
    "Henry Draper Catalogue_1": 107576
  },
  {
    "Astrid #": "ASTRID 002984",
    "HYG Star ID": 63679,
    "Hipparcos Catalogue": 63882,
    "Gliese Catalog": "NN 3760",
    "Distance in Parsecs": 24.6914,
    "mag": 11.74,
    "Absolute Visual Magnitude": 9.777,
    "Spectral Type": "M4",
    "Color Index": 1.637,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.885635,
    "y": -5.54921,
    "z": 14.906519,
    "vx": -0.00002429,
    "vy": 0.00003077,
    "vz": -0.00001932,
    "rarad": 3.42738474159191,
    "decrad": 0.648150491131816,
    "pmrarad": -0.00000147296092426388,
    "pmdecrad": -9.81699221e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 63679,
    "lum": 0.0106954731056616,
    "var_min": 11.908,
    "var_max": 11.648,
    "ra": 13.091645,
    "dec": 37.136288,
    "pmra": -303.82,
    "pmdec": -202.49,
    "rv": 0,
    "Asset Name": "ASTRID 002984",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Unkempt",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00002429,
    "y_1": 0.00003077,
    "z_1": -0.00001932,
    "Distance in Parsecs_1": 24.6914,
    "HYG Star ID_1": 63679,
    "Hipparcos Catalogue_1": 63882,
    "Gliese Catalog_1": "NN 3760"
  },
  {
    "Astrid #": "ASTRID 002985",
    "HYG Star ID": 42794,
    "Hipparcos Catalogue": 42913,
    "Henry Draper Catalogue": 74956,
    "Harvard Revised Catalogue": 3485,
    "Gliese Catalog": "Gl 321.3A",
    "Distance in Parsecs": 24.6975,
    "mag": 1.93,
    "Absolute Visual Magnitude": -0.033,
    "Spectral Type": "A1V",
    "Color Index": 0.043,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -9.394033,
    "y": 10.739813,
    "z": -20.158771,
    "vx": 0.00000325,
    "vy": -0.00000895,
    "vz": -0.00000904,
    "rarad": 2.28945121088205,
    "decrad": -0.954849056743452,
    "pmrarad": 1.39529377263888e-7,
    "pmdecrad": -5.04884966e-7,
    "bayer": "Del",
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 42794,
    "base": "Gl 321.3",
    "lum": 89.7842170909726,
    "var": "Del",
    "var_min": 1.939,
    "var_max": 1.929,
    "bf": "Del Vel",
    "ra": 8.745059,
    "dec": -54.708821,
    "pmra": 28.78,
    "pmdec": -104.14,
    "rv": 2.2,
    "Asset Name": "ASTRID 002985",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Good Boy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000325,
    "y_1": -0.00000895,
    "z_1": -0.00000904,
    "Distance in Parsecs_1": 24.6975,
    "HYG Star ID_1": 42794,
    "Hipparcos Catalogue_1": 42913,
    "Henry Draper Catalogue_1": 74956,
    "Harvard Revised Catalogue_1": 3485,
    "Gliese Catalog_1": "Gl 321.3A"
  },
  {
    "Astrid #": "ASTRID 002986",
    "HYG Star ID": 113469,
    "Hipparcos Catalogue": 113829,
    "Henry Draper Catalogue": 217813,
    "Distance in Parsecs": 24.7158,
    "mag": 6.65,
    "Absolute Visual Magnitude": 4.685,
    "Spectral Type": "G5V",
    "Color Index": 0.62,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.378448,
    "y": -5.674781,
    "z": 8.82455,
    "vx": -0.0000023,
    "vy": -0.00001396,
    "vz": -0.00000315,
    "rarad": 6.03483814772195,
    "decrad": 0.36509800369173,
    "pmrarad": -5.70431776541666e-7,
    "pmdecrad": -1.36378088e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 113469,
    "lum": 1.16412602941049,
    "var": "MT",
    "var_min": 6.676,
    "var_max": 6.636,
    "ra": 23.051384,
    "dec": 20.918575,
    "pmra": -117.66,
    "pmdec": -28.13,
    "rv": 0,
    "Asset Name": "ASTRID 002986",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Manbun",
    "Outfit": "River Spirit",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.0000023,
    "y_1": -0.00001396,
    "z_1": -0.00000315,
    "Distance in Parsecs_1": 24.7158,
    "HYG Star ID_1": 113469,
    "Hipparcos Catalogue_1": 113829,
    "Henry Draper Catalogue_1": 217813
  },
  {
    "Astrid #": "ASTRID 002987",
    "HYG Star ID": 44987,
    "Hipparcos Catalogue": 45116,
    "Gliese Catalog": "Gl 336.1",
    "Distance in Parsecs": 24.7341,
    "mag": 10.94,
    "Absolute Visual Magnitude": 8.974,
    "Spectral Type": "M0",
    "Color Index": 1.402,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.601231,
    "y": 11.394722,
    "z": 17.976233,
    "vx": 0.00002579,
    "vy": 0.00003371,
    "vz": -0.00000329,
    "rarad": 2.40643868880168,
    "decrad": 0.813621392273181,
    "pmrarad": -0.000001710422665,
    "pmdecrad": -1.93683065e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 44987,
    "lum": 0.0224078402678605,
    "var_min": 11.018,
    "var_max": 10.878,
    "ra": 9.191919,
    "dec": 46.617072,
    "pmra": -352.8,
    "pmdec": -39.95,
    "rv": 0,
    "Asset Name": "ASTRID 002987",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Undercut",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00002579,
    "y_1": 0.00003371,
    "z_1": -0.00000329,
    "Distance in Parsecs_1": 24.7341,
    "HYG Star ID_1": 44987,
    "Hipparcos Catalogue_1": 45116,
    "Gliese Catalog_1": "Gl 336.1"
  },
  {
    "Astrid #": "ASTRID 002988",
    "HYG Star ID": 63559,
    "Hipparcos Catalogue": 63762,
    "Henry Draper Catalogue": 115675,
    "Distance in Parsecs": 24.7402,
    "mag": 8.77,
    "Absolute Visual Magnitude": 6.803,
    "Spectral Type": "K0",
    "Color Index": 1.102,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.196618,
    "y": -0.34379,
    "z": 24.708853,
    "vx": 0.00001762,
    "vy": 0.00001752,
    "vz": 0.0000011,
    "rarad": 3.42143831551611,
    "decrad": 1.52045111762835,
    "pmrarad": -4.83941015930555e-7,
    "pmdecrad": 8.81100383e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 63559,
    "lum": 0.165500762876998,
    "ra": 13.068932,
    "dec": 87.115432,
    "pmra": -99.82,
    "pmdec": 181.74,
    "rv": 0,
    "Asset Name": "ASTRID 002988",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Manbun",
    "Outfit": "Boxer",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00001762,
    "y_1": 0.00001752,
    "z_1": 0.0000011,
    "Distance in Parsecs_1": 24.7402,
    "HYG Star ID_1": 63559,
    "Hipparcos Catalogue_1": 63762,
    "Henry Draper Catalogue_1": 115675
  },
  {
    "Astrid #": "ASTRID 002989",
    "HYG Star ID": 97759,
    "Hipparcos Catalogue": 98070,
    "Gliese Catalog": "Wo 9674",
    "Distance in Parsecs": 24.7402,
    "mag": 12.28,
    "Absolute Visual Magnitude": 10.313,
    "Spectral Type": "M2",
    "Color Index": 1.928,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.496701,
    "y": -13.541107,
    "z": 19.300658,
    "vx": 0.00003376,
    "vy": 0.00005537,
    "vz": 0.00002573,
    "rarad": 5.21799950117522,
    "decrad": 0.894879133773732,
    "pmrarad": 0.00000227784859672222,
    "pmdecrad": 0.000001662716998,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 97759,
    "lum": 0.00652829844158613,
    "var_min": 12.407,
    "var_max": 12.157,
    "ra": 19.93129,
    "dec": 51.272798,
    "pmra": 469.84,
    "pmdec": 342.96,
    "rv": 0,
    "Asset Name": "ASTRID 002989",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Pompadour",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003376,
    "y_1": 0.00005537,
    "z_1": 0.00002573,
    "Distance in Parsecs_1": 24.7402,
    "HYG Star ID_1": 97759,
    "Hipparcos Catalogue_1": 98070,
    "Gliese Catalog_1": "Wo 9674"
  },
  {
    "Astrid #": "ASTRID 002990",
    "HYG Star ID": 118319,
    "Gliese Catalog": "NN 3302",
    "Distance in Parsecs": 24.7525,
    "mag": 14.3,
    "Absolute Visual Magnitude": 12.332,
    "Spectral Type": "k-m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.635404,
    "y": 22.691536,
    "z": 4.817702,
    "vx": -0.00006303,
    "vy": 0.0000158,
    "vz": 0.00003863,
    "rarad": 1.20716089985429,
    "decrad": 0.1958852986516,
    "pmrarad": 0.00000260703708581944,
    "pmdecrad": 0.000001591333032,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118319,
    "lum": 0.00101671680046637,
    "ra": 4.611015,
    "dec": 11.223401,
    "pmra": 537.74,
    "pmdec": 328.24,
    "rv": 0,
    "Asset Name": "ASTRID 002990",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Fire",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006303,
    "y_1": 0.0000158,
    "z_1": 0.00003863,
    "Distance in Parsecs_1": 24.7525,
    "HYG Star ID_1": 118319,
    "Gliese Catalog_1": "NN 3302"
  },
  {
    "Astrid #": "ASTRID 002991",
    "HYG Star ID": 118482,
    "Gliese Catalog": "NN 3448B",
    "Distance in Parsecs": 24.7525,
    "mag": 12.95,
    "Absolute Visual Magnitude": 10.982,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.236565,
    "y": 21.750059,
    "z": 7.369331,
    "vx": 0.00002329,
    "vy": 0.00001545,
    "vz": -0.00001639,
    "rarad": 1.97238877410578,
    "decrad": 0.30230415891415,
    "pmrarad": -0.00000111007788436805,
    "pmdecrad": -6.93661717e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118482,
    "base": "NN 3448",
    "lum": 0.00352533181195886,
    "ra": 7.53397,
    "dec": 17.320752,
    "pmra": -228.97,
    "pmdec": -143.08,
    "rv": 0,
    "Asset Name": "ASTRID 002991",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Windy",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002329,
    "y_1": 0.00001545,
    "z_1": -0.00001639,
    "Distance in Parsecs_1": 24.7525,
    "HYG Star ID_1": 118482,
    "Gliese Catalog_1": "NN 3448B"
  },
  {
    "Astrid #": "ASTRID 002992",
    "HYG Star ID": 119235,
    "Gliese Catalog": "NN 4072",
    "Distance in Parsecs": 24.7525,
    "mag": 14.92,
    "Absolute Visual Magnitude": 12.952,
    "Spectral Type": "DA6",
    "Color Index": 0.14,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.648793,
    "y": -24.242194,
    "z": 1.87282,
    "vx": -0.00000528,
    "vy": -0.00004809,
    "vz": 0.00001244,
    "rarad": 4.90185349703585,
    "decrad": 0.07572702567865,
    "pmrarad": -5.75619282923611e-7,
    "pmdecrad": 3.59673573e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119235,
    "lum": 0.000574380913496317,
    "ra": 18.723701,
    "dec": 4.338839,
    "pmra": -118.73,
    "pmdec": 74.19,
    "rv": 46,
    "Asset Name": "ASTRID 002992",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Greasy",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000528,
    "y_1": -0.00004809,
    "z_1": 0.00001244,
    "Distance in Parsecs_1": 24.7525,
    "HYG Star ID_1": 119235,
    "Gliese Catalog_1": "NN 4072"
  },
  {
    "Astrid #": "ASTRID 002993",
    "HYG Star ID": 119358,
    "Gliese Catalog": "GJ 1255C",
    "Distance in Parsecs": 24.7525,
    "mag": 10.4,
    "Absolute Visual Magnitude": 8.432,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.902295,
    "y": -4.75991,
    "z": 23.97502,
    "vx": -0.00001036,
    "vy": 0.00007548,
    "vz": 0.00001669,
    "rarad": 5.39885921470994,
    "decrad": 1.31949586591025,
    "pmrarad": 0.00000160885419892361,
    "pmdecrad": 0.000002709638205,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 3,
    "comp_primary": 101423,
    "base": "GJ 1255",
    "lum": 0.0369147558165412,
    "ra": 20.622123,
    "dec": 75.601544,
    "pmra": 331.85,
    "pmdec": 558.9,
    "rv": 0,
    "Asset Name": "ASTRID 002993",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Bob",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001036,
    "y_1": 0.00007548,
    "z_1": 0.00001669,
    "Distance in Parsecs_1": 24.7525,
    "HYG Star ID_1": 119358,
    "Gliese Catalog_1": "GJ 1255C"
  },
  {
    "Astrid #": "ASTRID 002994",
    "HYG Star ID": 114696,
    "Hipparcos Catalogue": 115058,
    "Gliese Catalog": "Gl 894.1",
    "Distance in Parsecs": 24.7586,
    "mag": 10.92,
    "Absolute Visual Magnitude": 8.951,
    "Spectral Type": "M1",
    "Color Index": 1.434,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.826159,
    "y": -3.095846,
    "z": 17.89642,
    "vx": -0.0000084,
    "vy": 0.00004199,
    "vz": 0.0000071,
    "rarad": 6.10122396950576,
    "decrad": 0.807900564554422,
    "pmrarad": 0.00000160691494420138,
    "pmdecrad": 6.61091934e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114696,
    "lum": 0.0228875865706236,
    "var_min": 10.99,
    "var_max": 10.86,
    "ra": 23.304959,
    "dec": 46.289293,
    "pmra": 331.45,
    "pmdec": 136.36,
    "rv": -5.7,
    "Asset Name": "ASTRID 002994",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Heartbreaker",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.0000084,
    "y_1": 0.00004199,
    "z_1": 0.0000071,
    "Distance in Parsecs_1": 24.7586,
    "HYG Star ID_1": 114696,
    "Hipparcos Catalogue_1": 115058,
    "Gliese Catalog_1": "Gl 894.1"
  },
  {
    "Astrid #": "ASTRID 002995",
    "HYG Star ID": 73025,
    "Hipparcos Catalogue": 73252,
    "Gliese Catalog": "NN 3883",
    "Distance in Parsecs": 24.7647,
    "mag": 10.2,
    "Absolute Visual Magnitude": 8.231,
    "Spectral Type": "M0p",
    "Color Index": 1.565,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -8.932665,
    "y": -8.798147,
    "z": 21.356275,
    "vx": -0.00000403,
    "vy": 0.00001402,
    "vz": 0.00000409,
    "rarad": 3.91940868768879,
    "decrad": 1.03992761309813,
    "pmrarad": -5.17635566729166e-7,
    "pmdecrad": 3.26182644e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 73025,
    "lum": 0.0444221935471054,
    "ra": 14.971038,
    "dec": 59.583463,
    "pmra": -106.77,
    "pmdec": 67.28,
    "rv": 0,
    "Asset Name": "ASTRID 002995",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Nervous",
    "Hair": "Medium Bob",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000403,
    "y_1": 0.00001402,
    "z_1": 0.00000409,
    "Distance in Parsecs_1": 24.7647,
    "HYG Star ID_1": 73025,
    "Hipparcos Catalogue_1": 73252,
    "Gliese Catalog_1": "NN 3883"
  },
  {
    "Astrid #": "ASTRID 002996",
    "HYG Star ID": 4433,
    "Hipparcos Catalogue": 4443,
    "Distance in Parsecs": 24.7709,
    "mag": 11.13,
    "Absolute Visual Magnitude": 9.16,
    "Color Index": 1.43,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 23.523444,
    "y": 5.95676,
    "z": -4.976153,
    "vx": 3.4e-7,
    "vy": -0.00002491,
    "vz": -0.0000282,
    "rarad": 0.248013279523495,
    "decrad": -0.2022633359062,
    "pmrarad": -9.78111600520833e-7,
    "pmdecrad": -0.00000116238928,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 4433,
    "lum": 0.0188799134909629,
    "var_min": 11.172,
    "var_max": 11.052,
    "ra": 0.947341,
    "dec": -11.588835,
    "pmra": -201.75,
    "pmdec": -239.76,
    "rv": 0,
    "Asset Name": "ASTRID 002996",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Shinobi",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 3.4e-7,
    "y_1": -0.00002491,
    "z_1": -0.0000282,
    "Distance in Parsecs_1": 24.7709,
    "HYG Star ID_1": 4433,
    "Hipparcos Catalogue_1": 4443
  },
  {
    "Astrid #": "ASTRID 002997",
    "HYG Star ID": 21170,
    "Hipparcos Catalogue": 21223,
    "Henry Draper Catalogue": 29086,
    "Gliese Catalog": "NN 3298",
    "Distance in Parsecs": 24.777,
    "mag": 8.74,
    "Absolute Visual Magnitude": 6.77,
    "Spectral Type": "K4V",
    "Color Index": 0.99,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.325216,
    "y": 18.397104,
    "z": -14.892531,
    "vx": 0.0000031,
    "vy": 0.00000206,
    "vz": 0.00000408,
    "rarad": 1.19186677284166,
    "decrad": -0.64483018979598,
    "pmrarad": -8.56180959861111e-8,
    "pmdecrad": 2.05851888e-7,
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 21170,
    "lum": 0.170608238900312,
    "ra": 4.552596,
    "dec": -36.946048,
    "pmra": -17.66,
    "pmdec": 42.46,
    "rv": 0,
    "Asset Name": "ASTRID 002997",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Charmer",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": 0.0000031,
    "y_1": 0.00000206,
    "z_1": 0.00000408,
    "Distance in Parsecs_1": 24.777,
    "HYG Star ID_1": 21170,
    "Hipparcos Catalogue_1": 21223,
    "Henry Draper Catalogue_1": 29086,
    "Gliese Catalog_1": "NN 3298"
  },
  {
    "Astrid #": "ASTRID 002998",
    "HYG Star ID": 93576,
    "Hipparcos Catalogue": 93871,
    "Henry Draper Catalogue": 178126,
    "Distance in Parsecs": 24.777,
    "mag": 9.23,
    "Absolute Visual Magnitude": 7.26,
    "Spectral Type": "K5V",
    "Color Index": 1.056,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.081173,
    "y": -23.515508,
    "z": 3.283736,
    "vx": -0.00003007,
    "vy": -0.00003439,
    "vz": -0.0000888,
    "rarad": 5.00488024376925,
    "decrad": 0.132922121282935,
    "pmrarad": -0.00000156231208559027,
    "pmdecrad": -0.000003682596236,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 93576,
    "lum": 0.108642562361706,
    "ra": 19.117234,
    "dec": 7.615877,
    "pmra": -322.25,
    "pmdec": -759.59,
    "rv": 12,
    "Asset Name": "ASTRID 002998",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Little Crown",
    "Outfit": "Suit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00003007,
    "y_1": -0.00003439,
    "z_1": -0.0000888,
    "Distance in Parsecs_1": 24.777,
    "HYG Star ID_1": 93576,
    "Hipparcos Catalogue_1": 93871,
    "Henry Draper Catalogue_1": 178126
  },
  {
    "Astrid #": "ASTRID 002999",
    "HYG Star ID": 110428,
    "Hipparcos Catalogue": 110774,
    "Gliese Catalog": "NN 4280",
    "Distance in Parsecs": 24.777,
    "mag": 9.52,
    "Absolute Visual Magnitude": 7.55,
    "Spectral Type": "K7",
    "Color Index": 1.102,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.01402,
    "y": -4.325954,
    "z": 22.246466,
    "vx": -0.00001134,
    "vy": 0.00003044,
    "vz": 0.00001102,
    "rarad": 5.87539866546608,
    "decrad": 1.11490196769852,
    "pmrarad": 9.46453267180555e-7,
    "pmdecrad": 0.000001010594117,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 110428,
    "lum": 0.083176377110267,
    "ra": 22.44237,
    "dec": 63.879177,
    "pmra": 195.22,
    "pmdec": 208.45,
    "rv": 0,
    "Asset Name": "ASTRID 002999",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Mohawk",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001134,
    "y_1": 0.00003044,
    "z_1": 0.00001102,
    "Distance in Parsecs_1": 24.777,
    "HYG Star ID_1": 110428,
    "Hipparcos Catalogue_1": 110774,
    "Gliese Catalog_1": "NN 4280"
  },
  {
    "Astrid #": "ASTRID 003000",
    "HYG Star ID": 65122,
    "Hipparcos Catalogue": 65327,
    "Henry Draper Catalogue": 238224,
    "Gliese Catalog": "Gl 509.1",
    "Distance in Parsecs": 24.7831,
    "mag": 9.69,
    "Absolute Visual Magnitude": 7.719,
    "Spectral Type": "M0V",
    "Color Index": 1.262,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.305397,
    "y": -4.685954,
    "z": 20.99567,
    "vx": 0.00000834,
    "vy": -0.00001321,
    "vz": -0.00000603,
    "rarad": 3.50543793527841,
    "decrad": 1.01065291652983,
    "pmrarad": 6.18088961340277e-7,
    "pmdecrad": -2.3368019e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 65122,
    "lum": 0.0711868867262279,
    "ra": 13.389787,
    "dec": 57.906147,
    "pmra": 127.49,
    "pmdec": -4.82,
    "rv": -6.6,
    "Asset Name": "ASTRID 003000",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Prince",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000834,
    "y_1": -0.00001321,
    "z_1": -0.00000603,
    "Distance in Parsecs_1": 24.7831,
    "HYG Star ID_1": 65122,
    "Hipparcos Catalogue_1": 65327,
    "Henry Draper Catalogue_1": 238224,
    "Gliese Catalog_1": "Gl 509.1"
  },
  {
    "Astrid #": "ASTRID 003001",
    "HYG Star ID": 90027,
    "Hipparcos Catalogue": 90306,
    "Gliese Catalog": "NN 4058",
    "Distance in Parsecs": 24.7831,
    "mag": 11.28,
    "Absolute Visual Magnitude": 9.309,
    "Spectral Type": "M:",
    "Color Index": 1.442,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.160662,
    "y": -19.314356,
    "z": 15.378207,
    "vx": -0.00000301,
    "vy": -0.0000561,
    "vz": -0.00007003,
    "rarad": 4.82379484562785,
    "decrad": 0.669395248051603,
    "pmrarad": -3.72385388034722e-7,
    "pmdecrad": -0.000003603765531,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 90027,
    "lum": 0.016458869432193,
    "var_min": 11.355,
    "var_max": 11.175,
    "ra": 18.425539,
    "dec": 38.353523,
    "pmra": -76.81,
    "pmdec": -743.33,
    "rv": 0,
    "Asset Name": "ASTRID 003001",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Pixie",
    "Outfit": "Unit 01",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000301,
    "y_1": -0.0000561,
    "z_1": -0.00007003,
    "Distance in Parsecs_1": 24.7831,
    "HYG Star ID_1": 90027,
    "Hipparcos Catalogue_1": 90306,
    "Gliese Catalog_1": "NN 4058"
  },
  {
    "Astrid #": "ASTRID 003002",
    "HYG Star ID": 112841,
    "Hipparcos Catalogue": 113201,
    "Gliese Catalog": "NN 4303",
    "Distance in Parsecs": 24.7893,
    "mag": 11.6,
    "Absolute Visual Magnitude": 9.629,
    "Spectral Type": "M4",
    "Color Index": 1.653,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.561208,
    "y": -4.212978,
    "z": -19.614573,
    "vx": -0.00000599,
    "vy": 0.00001925,
    "vz": -0.00000858,
    "rarad": 6.00154329581601,
    "decrad": -0.912853057371598,
    "pmrarad": 6.78836115513889e-7,
    "pmdecrad": -5.66213897e-7,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 112841,
    "lum": 0.012257446320498,
    "var_min": 11.692,
    "var_max": 11.342,
    "ra": 22.924207,
    "dec": -52.302628,
    "pmra": 140.02,
    "pmdec": -116.79,
    "rv": 0,
    "Asset Name": "ASTRID 003002",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Middle Part",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00000599,
    "y_1": 0.00001925,
    "z_1": -0.00000858,
    "Distance in Parsecs_1": 24.7893,
    "HYG Star ID_1": 112841,
    "Hipparcos Catalogue_1": 113201,
    "Gliese Catalog_1": "NN 4303"
  },
  {
    "Astrid #": "ASTRID 003003",
    "HYG Star ID": 1594,
    "Hipparcos Catalogue": 1598,
    "Henry Draper Catalogue": 1562,
    "Distance in Parsecs": 24.7954,
    "mag": 6.97,
    "Absolute Visual Magnitude": 4.998,
    "Spectral Type": "G0",
    "Color Index": 0.64,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.404193,
    "y": 1.698228,
    "z": 15.343049,
    "vx": 0.00002916,
    "vy": -0.00001445,
    "vz": -0.0000204,
    "rarad": 0.0872978042775478,
    "decrad": 0.667193938205353,
    "pmrarad": -6.83053994534722e-7,
    "pmdecrad": -0.000001339976531,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 1594,
    "lum": 0.872569442770115,
    "ra": 0.333453,
    "dec": 38.227397,
    "pmra": -140.89,
    "pmdec": -276.39,
    "rv": 9,
    "Asset Name": "ASTRID 003003",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Smoulder",
    "Hair": "Manbun",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00002916,
    "y_1": -0.00001445,
    "z_1": -0.0000204,
    "Distance in Parsecs_1": 24.7954,
    "HYG Star ID_1": 1594,
    "Hipparcos Catalogue_1": 1598,
    "Henry Draper Catalogue_1": 1562
  },
  {
    "Astrid #": "ASTRID 003004",
    "HYG Star ID": 22371,
    "Hipparcos Catalogue": 22424,
    "Distance in Parsecs": 24.7954,
    "mag": 10.88,
    "Absolute Visual Magnitude": 8.908,
    "Spectral Type": "K7",
    "Color Index": 1.421,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.254394,
    "y": 22.850007,
    "z": -6.329518,
    "vx": 0.000021,
    "vy": -0.00001588,
    "vz": -0.00003328,
    "rarad": 1.26338230871965,
    "decrad": -0.258126803037137,
    "pmrarad": -0.000001000946324875,
    "pmdecrad": -0.00000138811853,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 22371,
    "lum": 0.0238122262327683,
    "var_min": 10.938,
    "var_max": 10.808,
    "ra": 4.825765,
    "dec": -14.789576,
    "pmra": -206.46,
    "pmdec": -286.32,
    "rv": 0,
    "Asset Name": "ASTRID 003004",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Arrogant",
    "Hair": "Braided Ponytail",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.000021,
    "y_1": -0.00001588,
    "z_1": -0.00003328,
    "Distance in Parsecs_1": 24.7954,
    "HYG Star ID_1": 22371,
    "Hipparcos Catalogue_1": 22424
  },
  {
    "Astrid #": "ASTRID 003005",
    "HYG Star ID": 86222,
    "Hipparcos Catalogue": 86489,
    "Distance in Parsecs": 24.7954,
    "mag": 10.66,
    "Absolute Visual Magnitude": 8.688,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.523466,
    "y": -17.811596,
    "z": -17.182491,
    "vx": -0.00000633,
    "vy": -0.00000214,
    "vz": 0.00000278,
    "rarad": 4.62706522294316,
    "decrad": -0.765601651128005,
    "pmrarad": -2.47206495715277e-7,
    "pmdecrad": 1.55528228e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 86222,
    "lum": 0.0291608379852122,
    "ra": 17.674087,
    "dec": -43.865743,
    "pmra": -50.99,
    "pmdec": 32.08,
    "rv": 0,
    "Asset Name": "ASTRID 003005",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Good Boy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000633,
    "y_1": -0.00000214,
    "z_1": 0.00000278,
    "Distance in Parsecs_1": 24.7954,
    "HYG Star ID_1": 86222,
    "Hipparcos Catalogue_1": 86489
  },
  {
    "Astrid #": "ASTRID 003006",
    "HYG Star ID": 11140,
    "Hipparcos Catalogue": 11167,
    "Distance in Parsecs": 24.8016,
    "mag": 11.06,
    "Absolute Visual Magnitude": 9.088,
    "Color Index": 0.303,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 13.086201,
    "y": 9.485195,
    "z": 18.812278,
    "vx": 4e-7,
    "vy": -6.9e-7,
    "vz": 7e-8,
    "rarad": 0.627193057020099,
    "decrad": 0.86102458133065,
    "pmrarad": -3.19977029166666e-8,
    "pmdecrad": 4.26636e-9,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 11140,
    "lum": 0.0201743708561192,
    "ra": 2.395701,
    "dec": 49.333075,
    "pmra": -6.6,
    "pmdec": 0.88,
    "rv": 0,
    "Asset Name": "ASTRID 003006",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Awkward",
    "Hair": "Pirate Hat",
    "Outfit": "Toga",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 4e-7,
    "y_1": -6.9e-7,
    "z_1": 7e-8,
    "Distance in Parsecs_1": 24.8016,
    "HYG Star ID_1": 11140,
    "Hipparcos Catalogue_1": 11167
  },
  {
    "Astrid #": "ASTRID 003007",
    "HYG Star ID": 68119,
    "Hipparcos Catalogue": 68337,
    "Henry Draper Catalogue": 122120,
    "Gliese Catalog": "Gl 535",
    "Distance in Parsecs": 24.8016,
    "mag": 9.05,
    "Absolute Visual Magnitude": 7.078,
    "Spectral Type": "K",
    "Color Index": 1.16,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.823617,
    "y": -11.367268,
    "z": 9.638635,
    "vx": 0.00003783,
    "vy": 0.0000441,
    "vz": -0.00002124,
    "rarad": 3.66223967461349,
    "decrad": 0.399152514502053,
    "pmrarad": -7.83701314618055e-7,
    "pmdecrad": 6.9085949e-8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 68119,
    "lum": 0.128469489983119,
    "ra": 13.988725,
    "dec": 22.869754,
    "pmra": -161.65,
    "pmdec": 14.25,
    "rv": -57.4,
    "Asset Name": "ASTRID 003007",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Heartbreaker",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00003783,
    "y_1": 0.0000441,
    "z_1": -0.00002124,
    "Distance in Parsecs_1": 24.8016,
    "HYG Star ID_1": 68119,
    "Hipparcos Catalogue_1": 68337,
    "Henry Draper Catalogue_1": 122120,
    "Gliese Catalog_1": "Gl 535"
  },
  {
    "Astrid #": "ASTRID 003008",
    "HYG Star ID": 105889,
    "Hipparcos Catalogue": 106231,
    "Gliese Catalog": "NN 4199",
    "Distance in Parsecs": 24.8016,
    "mag": 9.23,
    "Absolute Visual Magnitude": 7.258,
    "Spectral Type": "K8",
    "Color Index": 1.05,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.128823,
    "y": -13.781913,
    "z": 9.824145,
    "vx": -0.00000195,
    "vy": 0.00002173,
    "vz": -0.00002531,
    "rarad": 5.63317426898567,
    "decrad": 0.407279241138036,
    "pmrarad": 6.49941220152777e-7,
    "pmdecrad": -7.02155653e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 105889,
    "lum": 0.108842873794359,
    "var": "LO",
    "var_min": 9.3,
    "var_max": 9.16,
    "ra": 21.517141,
    "dec": 23.335382,
    "pmra": 134.06,
    "pmdec": -144.83,
    "rv": -23,
    "Asset Name": "ASTRID 003008",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Mortified",
    "Hair": "Lion Mane",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000195,
    "y_1": 0.00002173,
    "z_1": -0.00002531,
    "Distance in Parsecs_1": 24.8016,
    "HYG Star ID_1": 105889,
    "Hipparcos Catalogue_1": 106231,
    "Gliese Catalog_1": "NN 4199"
  },
  {
    "Astrid #": "ASTRID 003009",
    "HYG Star ID": 118330,
    "Gliese Catalog": "NN 3308",
    "Distance in Parsecs": 24.8139,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.427,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.692634,
    "y": 22.164047,
    "z": 8.081339,
    "vx": -0.00000199,
    "vy": -4e-7,
    "vz": 0.00000298,
    "rarad": 1.23672758059545,
    "decrad": 0.331728638270649,
    "pmrarad": 7.04919091527777e-8,
    "pmdecrad": 1.27205413e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118330,
    "lum": 0.00233991456105509,
    "ra": 4.723951,
    "dec": 19.006651,
    "pmra": 14.54,
    "pmdec": 26.24,
    "rv": 0,
    "Asset Name": "ASTRID 003009",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Infatuated",
    "Hair": "Manbun",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000199,
    "y_1": -4e-7,
    "z_1": 0.00000298,
    "Distance in Parsecs_1": 24.8139,
    "HYG Star ID_1": 118330,
    "Gliese Catalog_1": "NN 3308"
  },
  {
    "Astrid #": "ASTRID 003010",
    "HYG Star ID": 119414,
    "Gliese Catalog": "NN 4195",
    "Distance in Parsecs": 24.8139,
    "mag": 11.6,
    "Absolute Visual Magnitude": 9.627,
    "Spectral Type": "M0.5",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.380556,
    "y": -15.171871,
    "z": 3.15246,
    "vx": -0.00000845,
    "vy": -0.00002604,
    "vz": -0.00007324,
    "rarad": 5.61899626211382,
    "decrad": 0.12738835701555,
    "pmrarad": -0.00000103633772355555,
    "pmdecrad": -0.000002975999689,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119414,
    "lum": 0.0122800461798878,
    "ra": 21.462985,
    "dec": 7.298815,
    "pmra": -213.76,
    "pmdec": -613.84,
    "rv": 0,
    "Asset Name": "ASTRID 003010",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Spiky",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000845,
    "y_1": -0.00002604,
    "z_1": -0.00007324,
    "Distance in Parsecs_1": 24.8139,
    "HYG Star ID_1": 119414,
    "Gliese Catalog_1": "NN 4195"
  },
  {
    "Astrid #": "ASTRID 003011",
    "HYG Star ID": 119562,
    "Gliese Catalog": "NN 4334",
    "Distance in Parsecs": 24.8139,
    "mag": 14.59,
    "Absolute Visual Magnitude": 12.617,
    "Spectral Type": "m",
    "Color Index": 1.66,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.720244,
    "y": -2.220532,
    "z": 19.852287,
    "vx": -0.00001459,
    "vy": 0.00012544,
    "vz": 0.00002489,
    "rarad": 6.13330158731258,
    "decrad": 0.92737364869625,
    "pmrarad": 0.00000491072625171527,
    "pmdecrad": 0.000001671753925,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119562,
    "lum": 0.000781987840389495,
    "ra": 23.427486,
    "dec": 53.134596,
    "pmra": 1012.91,
    "pmdec": 344.82,
    "rv": 0,
    "Asset Name": "ASTRID 003011",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Pirate Hat",
    "Outfit": "Viking",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001459,
    "y_1": 0.00012544,
    "z_1": 0.00002489,
    "Distance in Parsecs_1": 24.8139,
    "HYG Star ID_1": 119562,
    "Gliese Catalog_1": "NN 4334"
  },
  {
    "Astrid #": "ASTRID 003012",
    "HYG Star ID": 88669,
    "Hipparcos Catalogue": 88945,
    "Henry Draper Catalogue": 166435,
    "Distance in Parsecs": 24.8201,
    "mag": 6.84,
    "Absolute Visual Magnitude": 4.866,
    "Spectral Type": "G0",
    "Color Index": 0.633,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.8777,
    "y": -21.487266,
    "z": 12.39187,
    "vx": 0.00000792,
    "vy": 0.00001585,
    "vz": -5.3e-7,
    "rarad": 4.75321329399553,
    "decrad": 0.522755978179267,
    "pmrarad": 3.44799489611111e-7,
    "pmdecrad": 2.93506202e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 88669,
    "lum": 0.985371506858618,
    "ra": 18.155937,
    "dec": 29.951711,
    "pmra": 71.12,
    "pmdec": 60.54,
    "rv": -13.4,
    "Asset Name": "ASTRID 003012",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Sweetheart",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000792,
    "y_1": 0.00001585,
    "z_1": -5.3e-7,
    "Distance in Parsecs_1": 24.8201,
    "HYG Star ID_1": 88669,
    "Hipparcos Catalogue_1": 88945,
    "Henry Draper Catalogue_1": 166435
  },
  {
    "Astrid #": "ASTRID 003013",
    "HYG Star ID": 36020,
    "Hipparcos Catalogue": 36121,
    "Henry Draper Catalogue": 58760,
    "Distance in Parsecs": 24.8262,
    "mag": 9.21,
    "Absolute Visual Magnitude": 7.235,
    "Spectral Type": "K4V",
    "Color Index": 1.16,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.799266,
    "y": 22.212314,
    "z": -6.747312,
    "vx": -0.00000403,
    "vy": -0.00000501,
    "vz": -0.00001124,
    "rarad": 1.94797371658566,
    "decrad": -0.275244147977165,
    "pmrarad": 2.25438361458333e-7,
    "pmdecrad": -4.70511676e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 36020,
    "lum": 0.111173172728159,
    "ra": 7.440712,
    "dec": -15.770328,
    "pmra": 46.5,
    "pmdec": -97.05,
    "rv": 0,
    "Asset Name": "ASTRID 003013",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Battle Ready",
    "Hair": "Braided Bun",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00000403,
    "y_1": -0.00000501,
    "z_1": -0.00001124,
    "Distance in Parsecs_1": 24.8262,
    "HYG Star ID_1": 36020,
    "Hipparcos Catalogue_1": 36121,
    "Henry Draper Catalogue_1": 58760
  },
  {
    "Astrid #": "ASTRID 003014",
    "HYG Star ID": 114016,
    "Hipparcos Catalogue": 114378,
    "Henry Draper Catalogue": 218687,
    "Distance in Parsecs": 24.8262,
    "mag": 6.54,
    "Absolute Visual Magnitude": 4.565,
    "Spectral Type": "G0V",
    "Color Index": 0.607,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 23.472353,
    "y": -5.208888,
    "z": 6.185167,
    "vx": -6.4e-7,
    "vy": -0.00001484,
    "vz": -0.00001007,
    "rarad": 6.06480846855192,
    "decrad": 0.25179081053594,
    "pmrarad": -5.89194065979166e-7,
    "pmdecrad": -4.19024464e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 114016,
    "lum": 1.30016957803329,
    "ra": 23.165862,
    "dec": 14.426551,
    "pmra": -121.53,
    "pmdec": -86.43,
    "rv": 0,
    "Asset Name": "ASTRID 003014",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Braided Bun",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -6.4e-7,
    "y_1": -0.00001484,
    "z_1": -0.00001007,
    "Distance in Parsecs_1": 24.8262,
    "HYG Star ID_1": 114016,
    "Hipparcos Catalogue_1": 114378,
    "Henry Draper Catalogue_1": 218687
  },
  {
    "Astrid #": "ASTRID 003015",
    "HYG Star ID": 42299,
    "Hipparcos Catalogue": 42418,
    "Henry Draper Catalogue": 73512,
    "Distance in Parsecs": 24.8324,
    "mag": 7.9,
    "Absolute Visual Magnitude": 5.925,
    "Spectral Type": "K0",
    "Color Index": 0.897,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.762293,
    "y": 18.951394,
    "z": 3.007147,
    "vx": 0.00000577,
    "vy": 0.00001037,
    "vz": -0.00003512,
    "rarad": 2.26458296994671,
    "decrad": 0.121395637834406,
    "pmrarad": -4.45834660638888e-7,
    "pmdecrad": -0.000001424818925,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 42299,
    "lum": 0.371535229097172,
    "ra": 8.65007,
    "dec": 6.955458,
    "pmra": -91.96,
    "pmdec": -293.89,
    "rv": 0,
    "Asset Name": "ASTRID 003015",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Heartbreaker",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": 0.00000577,
    "y_1": 0.00001037,
    "z_1": -0.00003512,
    "Distance in Parsecs_1": 24.8324,
    "HYG Star ID_1": 42299,
    "Hipparcos Catalogue_1": 42418,
    "Henry Draper Catalogue_1": 73512
  },
  {
    "Astrid #": "ASTRID 003016",
    "HYG Star ID": 23461,
    "Hipparcos Catalogue": 23516,
    "Gliese Catalog": "Gl 186",
    "Distance in Parsecs": 24.8571,
    "mag": 9.28,
    "Absolute Visual Magnitude": 7.303,
    "Spectral Type": "K5V",
    "Color Index": 1.281,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.586743,
    "y": 22.145521,
    "z": -9.812676,
    "vx": -0.00000194,
    "vy": 0.0001149,
    "vz": -0.00002705,
    "rarad": 1.32367747578752,
    "decrad": -0.405793375508892,
    "pmrarad": 0.00000120660428816666,
    "pmdecrad": 7.61884698e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 23461,
    "lum": 0.104423921854317,
    "ra": 5.056076,
    "dec": -23.250248,
    "pmra": 248.88,
    "pmdec": 157.15,
    "rv": 110.1,
    "Asset Name": "ASTRID 003016",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Audiohead",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.00000194,
    "y_1": 0.0001149,
    "z_1": -0.00002705,
    "Distance in Parsecs_1": 24.8571,
    "HYG Star ID_1": 23461,
    "Hipparcos Catalogue_1": 23516,
    "Gliese Catalog_1": "Gl 186"
  },
  {
    "Astrid #": "ASTRID 003017",
    "HYG Star ID": 67814,
    "Hipparcos Catalogue": 68030,
    "Henry Draper Catalogue": 121560,
    "Harvard Revised Catalogue": 5243,
    "Distance in Parsecs": 24.8633,
    "mag": 6.16,
    "Absolute Visual Magnitude": 4.182,
    "Spectral Type": "F6V",
    "Color Index": 0.518,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.103192,
    "y": -11.677623,
    "z": 6.038736,
    "vx": -0.00000546,
    "vy": 0.00003707,
    "vz": -0.00000215,
    "rarad": 3.64701082073481,
    "decrad": 0.245332258275007,
    "pmrarad": -0.00000141100173588888,
    "pmdecrad": 4.4602858e-8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 67814,
    "lum": 1.85012043477185,
    "ra": 13.930555,
    "dec": 14.056503,
    "pmra": -291.04,
    "pmdec": 9.2,
    "rv": -13,
    "Asset Name": "ASTRID 003017",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Greasy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000546,
    "y_1": 0.00003707,
    "z_1": -0.00000215,
    "Distance in Parsecs_1": 24.8633,
    "HYG Star ID_1": 67814,
    "Hipparcos Catalogue_1": 68030,
    "Henry Draper Catalogue_1": 121560,
    "Harvard Revised Catalogue_1": 5243
  },
  {
    "Astrid #": "ASTRID 003018",
    "HYG Star ID": 28288,
    "Hipparcos Catalogue": 28360,
    "Henry Draper Catalogue": 40183,
    "Harvard Revised Catalogue": 2088,
    "Gliese Catalog": "NN 3375",
    "Proper Name": "Menkalinan",
    "Distance in Parsecs": 24.8694,
    "mag": 1.9,
    "Absolute Visual Magnitude": -0.078,
    "Spectral Type": "A2V",
    "Color Index": 0.077,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 0.036195,
    "y": 17.601288,
    "z": 17.569058,
    "vx": 0.00000677,
    "vy": -0.00001398,
    "vz": -0.00001409,
    "rarad": 1.56874094502027,
    "decrad": 0.78448069066935,
    "pmrarad": -2.73483397201388e-7,
    "pmdecrad": -4.26636e-9,
    "bayer": "Bet",
    "flam": 34,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 28288,
    "lum": 93.583654358353,
    "var": "Bet",
    "var_min": 1.967,
    "var_max": 1.897,
    "bf": "34Bet Aur",
    "ra": 5.992149,
    "dec": 44.947433,
    "pmra": -56.41,
    "pmdec": -0.88,
    "rv": -19.4,
    "Asset Name": "ASTRID 003018",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Glad",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Spec Ops",
    "Special": "V1 Flight System",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Menkalinan",
    "Constellation": "Auriga",
    "x_1": 0.00000677,
    "y_1": -0.00001398,
    "z_1": -0.00001409,
    "Distance in Parsecs_1": 24.8694,
    "HYG Star ID_1": 28288,
    "Hipparcos Catalogue_1": 28360,
    "Henry Draper Catalogue_1": 40183,
    "Harvard Revised Catalogue_1": 2088,
    "Gliese Catalog_1": "NN 3375"
  },
  {
    "Astrid #": "ASTRID 003019",
    "HYG Star ID": 118850,
    "Gliese Catalog": "NN 3754",
    "Distance in Parsecs": 24.8756,
    "mag": 17.82,
    "Absolute Visual Magnitude": 15.841,
    "Spectral Type": "DC9+",
    "Color Index": 1.5,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -14.002434,
    "y": -3.113049,
    "z": 20.323295,
    "vx": -0.00012743,
    "vy": 0.00000507,
    "vz": -0.00008696,
    "rarad": 3.3604047489497,
    "decrad": 0.956184127741399,
    "pmrarad": -0.00000131142100590277,
    "pmdecrad": -0.000006065465172,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118850,
    "lum": 0.0000401420918204321,
    "ra": 12.835801,
    "dec": 54.785315,
    "pmra": -270.5,
    "pmdec": -1251.09,
    "rv": 0,
    "Asset Name": "ASTRID 003019",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Charmer",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00012743,
    "y_1": 0.00000507,
    "z_1": -0.00008696,
    "Distance in Parsecs_1": 24.8756,
    "HYG Star ID_1": 118850,
    "Gliese Catalog_1": "NN 3754"
  },
  {
    "Astrid #": "ASTRID 003020",
    "HYG Star ID": 119176,
    "Gliese Catalog": "Wo 9600",
    "Distance in Parsecs": 24.8756,
    "mag": 12.15,
    "Absolute Visual Magnitude": 10.171,
    "Spectral Type": "M2",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.717983,
    "y": -23.523884,
    "z": -7.903854,
    "vx": -0.00001192,
    "vy": 0.00002077,
    "vz": -0.00005926,
    "rarad": 4.63948842760004,
    "decrad": -0.323339973284799,
    "pmrarad": -5.38627999097222e-7,
    "pmdecrad": -0.000002512425696,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119176,
    "lum": 0.00744046366191919,
    "ra": 17.72154,
    "dec": -18.526016,
    "pmra": -111.1,
    "pmdec": -518.23,
    "rv": 0,
    "Asset Name": "ASTRID 003020",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Einstein Tongue",
    "Hair": "Pompadour",
    "Outfit": "Samurai",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001192,
    "y_1": 0.00002077,
    "z_1": -0.00005926,
    "Distance in Parsecs_1": 24.8756,
    "HYG Star ID_1": 119176,
    "Gliese Catalog_1": "Wo 9600"
  },
  {
    "Astrid #": "ASTRID 003021",
    "HYG Star ID": 119542,
    "Gliese Catalog": "NN 4307",
    "Distance in Parsecs": 24.8756,
    "mag": 14.68,
    "Absolute Visual Magnitude": 12.701,
    "Spectral Type": "m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.859721,
    "y": -2.532204,
    "z": 23.106034,
    "vx": -0.00001401,
    "vy": 0.00008686,
    "vz": 0.00001491,
    "rarad": 6.00452437014207,
    "decrad": 1.19133244279985,
    "pmrarad": 0.0000032022428414375,
    "pmdecrad": 0.000001617575997,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119542,
    "lum": 0.000723769037074528,
    "ra": 22.935594,
    "dec": 68.258321,
    "pmra": 660.51,
    "pmdec": 333.65,
    "rv": 0,
    "Asset Name": "ASTRID 003021",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Fedora",
    "Outfit": "Tats",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001401,
    "y_1": 0.00008686,
    "z_1": 0.00001491,
    "Distance in Parsecs_1": 24.8756,
    "HYG Star ID_1": 119542,
    "Gliese Catalog_1": "NN 4307"
  },
  {
    "Astrid #": "ASTRID 003022",
    "HYG Star ID": 59566,
    "Hipparcos Catalogue": 59748,
    "Gliese Catalog": "Gl 458.2",
    "Distance in Parsecs": 24.8818,
    "mag": 10.55,
    "Absolute Visual Magnitude": 8.571,
    "Spectral Type": "M2V",
    "Color Index": 1.412,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.375455,
    "y": -1.083879,
    "z": 18.702042,
    "vx": 0.00000629,
    "vy": 0.00002948,
    "vz": -0.0000151,
    "rarad": 3.20769077139895,
    "decrad": 0.85054476264075,
    "pmrarad": -0.00000116578297626388,
    "pmdecrad": -1.516982e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 59566,
    "lum": 0.0324788018763537,
    "var_min": 10.625,
    "var_max": 10.485,
    "ra": 12.252476,
    "dec": 48.732625,
    "pmra": -240.46,
    "pmdec": -31.29,
    "rv": -16.4,
    "Asset Name": "ASTRID 003022",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Wavy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00000629,
    "y_1": 0.00002948,
    "z_1": -0.0000151,
    "Distance in Parsecs_1": 24.8818,
    "HYG Star ID_1": 59566,
    "Hipparcos Catalogue_1": 59748,
    "Gliese Catalog_1": "Gl 458.2"
  },
  {
    "Astrid #": "ASTRID 003023",
    "HYG Star ID": 116131,
    "Hipparcos Catalogue": 116497,
    "Gliese Catalog": "NN 4349",
    "Distance in Parsecs": 24.888,
    "mag": 11.72,
    "Absolute Visual Magnitude": 9.74,
    "Spectral Type": "M:",
    "Color Index": 1.461,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.023836,
    "y": -1.447567,
    "z": 20.509732,
    "vx": 0.00001548,
    "vy": 0.00005807,
    "vz": -0.00000649,
    "rarad": 6.18031173538052,
    "decrad": 0.96857823780419,
    "pmrarad": 0.00000238518634559722,
    "pmdecrad": -4.60136664e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 116131,
    "lum": 0.0110662378397766,
    "var_min": 11.812,
    "var_max": 11.632,
    "ra": 23.607052,
    "dec": 55.495445,
    "pmra": 491.98,
    "pmdec": -94.91,
    "rv": 0,
    "Asset Name": "ASTRID 003023",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Bucket Hat",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00001548,
    "y_1": 0.00005807,
    "z_1": -0.00000649,
    "Distance in Parsecs_1": 24.888,
    "HYG Star ID_1": 116131,
    "Hipparcos Catalogue_1": 116497,
    "Gliese Catalog_1": "NN 4349"
  },
  {
    "Astrid #": "ASTRID 003024",
    "HYG Star ID": 75569,
    "Hipparcos Catalogue": 75798,
    "Distance in Parsecs": 24.8942,
    "mag": 10.53,
    "Absolute Visual Magnitude": 8.55,
    "Color Index": 1.423,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.534901,
    "y": -18.792324,
    "z": -7.436829,
    "vx": -0.00002119,
    "vy": 0.00001535,
    "vz": 0.00000261,
    "rarad": 4.05404916390238,
    "decrad": -0.303369398311466,
    "pmrarad": -0.00000105063972713194,
    "pmdecrad": 1.09907261e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 75569,
    "lum": 0.033113112148259,
    "var_min": 10.583,
    "var_max": 10.473,
    "ra": 15.485327,
    "dec": -17.381786,
    "pmra": -216.71,
    "pmdec": 22.67,
    "rv": 0,
    "Asset Name": "ASTRID 003024",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Ninja",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00002119,
    "y_1": 0.00001535,
    "z_1": 0.00000261,
    "Distance in Parsecs_1": 24.8942,
    "HYG Star ID_1": 75569,
    "Hipparcos Catalogue_1": 75798
  },
  {
    "Astrid #": "ASTRID 003025",
    "HYG Star ID": 47704,
    "Hipparcos Catalogue": 47839,
    "Henry Draper Catalogue": 84627,
    "Distance in Parsecs": 24.9004,
    "mag": 7.92,
    "Absolute Visual Magnitude": 5.939,
    "Spectral Type": "F8/G0V",
    "Color Index": 0.53,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.45254,
    "y": 8.983521,
    "z": -18.930278,
    "vx": -0.00000629,
    "vy": 0.00000741,
    "vz": 0.00000799,
    "rarad": 2.5528107082189,
    "decrad": -0.863682358976868,
    "pmrarad": -1.07289267506944e-7,
    "pmdecrad": 4.93831215e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 47704,
    "lum": 0.366775231599494,
    "var_min": 8.206,
    "var_max": 7.686,
    "ra": 9.751019,
    "dec": -49.485354,
    "pmra": -22.13,
    "pmdec": 101.86,
    "rv": 0,
    "Asset Name": "ASTRID 003025",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Halo Boy",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000629,
    "y_1": 0.00000741,
    "z_1": 0.00000799,
    "Distance in Parsecs_1": 24.9004,
    "HYG Star ID_1": 47704,
    "Hipparcos Catalogue_1": 47839,
    "Henry Draper Catalogue_1": 84627
  },
  {
    "Astrid #": "ASTRID 003026",
    "HYG Star ID": 72267,
    "Hipparcos Catalogue": 72493,
    "Henry Draper Catalogue": 130042,
    "Distance in Parsecs": 24.9314,
    "mag": 7.26,
    "Absolute Visual Magnitude": 5.276,
    "Spectral Type": "K0V",
    "Color Index": 0.836,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.129594,
    "y": -6.498545,
    "z": -22.9894,
    "vx": 0.0000177,
    "vy": 0.00003367,
    "vz": -0.00001501,
    "rarad": 3.88072627005398,
    "decrad": -1.17348906038634,
    "pmrarad": -5.201081165e-7,
    "pmdecrad": -0.000001555815582,
    "con": "Cir",
    "Full Constellation Name Formula 1": "Circinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Circinus",
    "comp": 1,
    "comp_primary": 72267,
    "lum": 0.6754605666335,
    "ra": 14.823282,
    "dec": -67.23597,
    "pmra": -107.28,
    "pmdec": -320.91,
    "rv": 0,
    "Asset Name": "ASTRID 003026",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Bad Dreams",
    "Hair": "Innocent",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Circinus",
    "x_1": 0.0000177,
    "y_1": 0.00003367,
    "z_1": -0.00001501,
    "Distance in Parsecs_1": 24.9314,
    "HYG Star ID_1": 72267,
    "Hipparcos Catalogue_1": 72493,
    "Henry Draper Catalogue_1": 130042
  },
  {
    "Astrid #": "ASTRID 003027",
    "HYG Star ID": 45831,
    "Hipparcos Catalogue": 45963,
    "Henry Draper Catalogue": 80715,
    "Gliese Catalog": "GJ 1124",
    "Distance in Parsecs": 24.9377,
    "mag": 7.69,
    "Absolute Visual Magnitude": 5.706,
    "Spectral Type": "K2V",
    "Color Index": 0.987,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.719753,
    "y": 12.087455,
    "z": 16.096335,
    "vx": 0.00003051,
    "vy": 0.00002835,
    "vz": -0.00006152,
    "rarad": 2.45407886097911,
    "decrad": 0.701640866274506,
    "pmrarad": -0.00000165520238678472,
    "pmdecrad": -0.000001739511485,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 45831,
    "lum": 0.454569193584924,
    "var": "BF",
    "var_min": 7.726,
    "var_max": 7.616,
    "ra": 9.373891,
    "dec": 40.20106,
    "pmra": -341.41,
    "pmdec": -358.8,
    "rv": -43,
    "Asset Name": "ASTRID 003027",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Serious Shinobi",
    "Hair": "Mohawk",
    "Outfit": "Boxer",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00003051,
    "y_1": 0.00002835,
    "z_1": -0.00006152,
    "Distance in Parsecs_1": 24.9377,
    "HYG Star ID_1": 45831,
    "Hipparcos Catalogue_1": 45963,
    "Henry Draper Catalogue_1": 80715,
    "Gliese Catalog_1": "GJ 1124"
  },
  {
    "Astrid #": "ASTRID 003028",
    "HYG Star ID": 3163,
    "Hipparcos Catalogue": 3170,
    "Henry Draper Catalogue": 3823,
    "Harvard Revised Catalogue": 176,
    "Gliese Catalog": "Gl 29",
    "Distance in Parsecs": 24.9563,
    "mag": 5.89,
    "Absolute Visual Magnitude": 3.904,
    "Spectral Type": "G1V",
    "Color Index": 0.564,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.486498,
    "y": 2.225749,
    "z": -21.493045,
    "vx": 0.00002747,
    "vy": 0.00011343,
    "vz": 0.00002592,
    "rarad": 0.176363429946505,
    "decrad": -1.03767859998549,
    "pmrarad": 0.000004282165314875,
    "pmdecrad": 0.000002147773086,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 3163,
    "lum": 2.39001155148472,
    "ra": 0.673659,
    "dec": -59.454604,
    "pmra": 883.26,
    "pmdec": 443.01,
    "rv": 1.5,
    "Asset Name": "ASTRID 003028",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Straw Hat",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": 0.00002747,
    "y_1": 0.00011343,
    "z_1": 0.00002592,
    "Distance in Parsecs_1": 24.9563,
    "HYG Star ID_1": 3163,
    "Hipparcos Catalogue_1": 3170,
    "Henry Draper Catalogue_1": 3823,
    "Harvard Revised Catalogue_1": 176,
    "Gliese Catalog_1": "Gl 29"
  },
  {
    "Astrid #": "ASTRID 003029",
    "HYG Star ID": 2534,
    "Hipparcos Catalogue": 2540,
    "Gliese Catalog": "NN 3038",
    "Distance in Parsecs": 24.9688,
    "mag": 9.65,
    "Absolute Visual Magnitude": 7.663,
    "Spectral Type": "M0",
    "Color Index": 1.367,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.188406,
    "y": 1.585223,
    "z": -22.26539,
    "vx": -0.00001978,
    "vy": 0.000062,
    "vz": -0.00000553,
    "rarad": 0.140720430931283,
    "decrad": -1.10115018802614,
    "pmrarad": 0.00000256970643241666,
    "pmdecrad": -4.89031559e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 2534,
    "lum": 0.0749548949791958,
    "ra": 0.537512,
    "dec": -63.091258,
    "pmra": 530.04,
    "pmdec": -100.87,
    "rv": 0,
    "Asset Name": "ASTRID 003029",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Sweetheart",
    "Outfit": "Turtle School",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00001978,
    "y_1": 0.000062,
    "z_1": -0.00000553,
    "Distance in Parsecs_1": 24.9688,
    "HYG Star ID_1": 2534,
    "Hipparcos Catalogue_1": 2540,
    "Gliese Catalog_1": "NN 3038"
  },
  {
    "Astrid #": "ASTRID 003030",
    "HYG Star ID": 86156,
    "Hipparcos Catalogue": 86423,
    "Gliese Catalog": "NN 4018A",
    "Distance in Parsecs": 24.9688,
    "mag": 11.07,
    "Absolute Visual Magnitude": 9.083,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -1.972545,
    "y": -22.006371,
    "z": 11.630551,
    "vx": 0.00000127,
    "vy": -0.00001138,
    "vz": -0.00002131,
    "rarad": 4.6229925859122,
    "decrad": 0.484542241030216,
    "pmrarad": 9.15328228888888e-8,
    "pmdecrad": -9.64682261e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 86156,
    "base": "NN 4018",
    "lum": 0.0202674915209896,
    "var_min": 11.17,
    "var_max": 10.95,
    "ra": 17.658531,
    "dec": 27.762225,
    "pmra": 18.88,
    "pmdec": -198.98,
    "rv": 0,
    "Asset Name": "ASTRID 003030",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Horns",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000127,
    "y_1": -0.00001138,
    "z_1": -0.00002131,
    "Distance in Parsecs_1": 24.9688,
    "HYG Star ID_1": 86156,
    "Hipparcos Catalogue_1": 86423,
    "Gliese Catalog_1": "NN 4018A"
  },
  {
    "Astrid #": "ASTRID 003031",
    "HYG Star ID": 102001,
    "Hipparcos Catalogue": 102332,
    "Distance in Parsecs": 24.9688,
    "mag": 9.84,
    "Absolute Visual Magnitude": 7.853,
    "Spectral Type": "K7V",
    "Color Index": 1.191,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.257058,
    "y": -17.549589,
    "z": -9.092585,
    "vx": -0.0000048,
    "vy": 0.00001132,
    "vz": -0.00002991,
    "rarad": 5.42802038087401,
    "decrad": -0.372728400767781,
    "pmrarad": 1.52328458430555e-7,
    "pmdecrad": -0.000001286259175,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 102001,
    "lum": 0.0629216351287985,
    "ra": 20.733511,
    "dec": -21.355764,
    "pmra": 31.42,
    "pmdec": -265.31,
    "rv": 0,
    "Asset Name": "ASTRID 003031",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Ninja",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.0000048,
    "y_1": 0.00001132,
    "z_1": -0.00002991,
    "Distance in Parsecs_1": 24.9688,
    "HYG Star ID_1": 102001,
    "Hipparcos Catalogue_1": 102332
  },
  {
    "Astrid #": "ASTRID 003032",
    "HYG Star ID": 86074,
    "Hipparcos Catalogue": 86340,
    "Gliese Catalog": "Gl 690A",
    "Distance in Parsecs": 24.975,
    "mag": 9.1,
    "Absolute Visual Magnitude": 7.112,
    "Spectral Type": "M0",
    "Color Index": 1.117,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.746452,
    "y": -7.959035,
    "z": 23.661083,
    "vx": -0.00001157,
    "vy": 0.00003755,
    "vz": 0.0000114,
    "rarad": 4.61888690880208,
    "decrad": 1.24498379101274,
    "pmrarad": -6.01508333465277e-7,
    "pmdecrad": 0.000001523430028,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 86074,
    "base": "Gl 690",
    "lum": 0.124508786391605,
    "ra": 17.642848,
    "dec": 71.332317,
    "pmra": -124.07,
    "pmdec": 314.23,
    "rv": -0.8,
    "Asset Name": "ASTRID 003032",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Greasy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00001157,
    "y_1": 0.00003755,
    "z_1": 0.0000114,
    "Distance in Parsecs_1": 24.975,
    "HYG Star ID_1": 86074,
    "Hipparcos Catalogue_1": 86340,
    "Gliese Catalog_1": "Gl 690A"
  },
  {
    "Astrid #": "ASTRID 003033",
    "HYG Star ID": 9250,
    "Hipparcos Catalogue": 9269,
    "Henry Draper Catalogue": 12051,
    "Gliese Catalog": "Gl 82.1",
    "Distance in Parsecs": 24.9813,
    "mag": 7.14,
    "Absolute Visual Magnitude": 5.152,
    "Spectral Type": "G5",
    "Color Index": 0.773,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.141117,
    "y": 10.380114,
    "z": 13.682198,
    "vx": -0.00002068,
    "vy": 0.00002209,
    "vz": -0.00005563,
    "rarad": 0.519715792562486,
    "decrad": 0.579618265498533,
    "pmrarad": 0.00000117892142700694,
    "pmdecrad": -0.000001710228739,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 9250,
    "lum": 0.757181509714705,
    "ra": 1.985168,
    "dec": 33.20968,
    "pmra": 243.17,
    "pmdec": -352.76,
    "rv": -35.5,
    "Asset Name": "ASTRID 003033",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Cute Neko",
    "Hair": "Manbun",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.00002068,
    "y_1": 0.00002209,
    "z_1": -0.00005563,
    "Distance in Parsecs_1": 24.9813,
    "HYG Star ID_1": 9250,
    "Hipparcos Catalogue_1": 9269,
    "Henry Draper Catalogue_1": 12051,
    "Gliese Catalog_1": "Gl 82.1"
  },
  {
    "Astrid #": "ASTRID 003034",
    "HYG Star ID": 103802,
    "Hipparcos Catalogue": 104137,
    "Gliese Catalog": "NN 4180",
    "Distance in Parsecs": 24.9813,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.142,
    "Color Index": 1.548,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 18.062204,
    "y": -17.149407,
    "z": 1.928718,
    "vx": 0.00001245,
    "vy": 0.0000108,
    "vz": -0.00002051,
    "rarad": 5.52370449837535,
    "decrad": 0.0772833799112587,
    "pmrarad": 6.56728611680555e-7,
    "pmdecrad": -8.23552999e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 103802,
    "lum": 0.00764187623963467,
    "var_min": 12.248,
    "var_max": 12.008,
    "ra": 21.098997,
    "dec": 4.428011,
    "pmra": 135.46,
    "pmdec": -169.87,
    "rv": 0,
    "Asset Name": "ASTRID 003034",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Sweetheart",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001245,
    "y_1": 0.0000108,
    "z_1": -0.00002051,
    "Distance in Parsecs_1": 24.9813,
    "HYG Star ID_1": 103802,
    "Hipparcos Catalogue_1": 104137,
    "Gliese Catalog_1": "NN 4180"
  },
  {
    "Astrid #": "ASTRID 003035",
    "HYG Star ID": 114023,
    "Hipparcos Catalogue": 114385,
    "Henry Draper Catalogue": 218739,
    "Distance in Parsecs": 24.9813,
    "mag": 7.11,
    "Absolute Visual Magnitude": 5.122,
    "Spectral Type": "G5",
    "Color Index": 0.658,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.33202,
    "y": -3.622125,
    "z": 18.552832,
    "vx": -5.4e-7,
    "vy": 0.00001925,
    "vz": -0.00000541,
    "rarad": 6.06493405662122,
    "decrad": 0.837049648912478,
    "pmrarad": 7.47728139520833e-7,
    "pmdecrad": -5.381431e-9,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114023,
    "lum": 0.778394932626725,
    "var_min": 7.26,
    "var_max": 7.03,
    "ra": 23.166342,
    "dec": 47.959412,
    "pmra": 154.23,
    "pmdec": -1.11,
    "rv": -7,
    "Asset Name": "ASTRID 003035",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Side Part",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -5.4e-7,
    "y_1": 0.00001925,
    "z_1": -0.00000541,
    "Distance in Parsecs_1": 24.9813,
    "HYG Star ID_1": 114023,
    "Hipparcos Catalogue_1": 114385,
    "Henry Draper Catalogue_1": 218739
  },
  {
    "Astrid #": "ASTRID 003036",
    "HYG Star ID": 118827,
    "Gliese Catalog": "Gl 469.2B",
    "Distance in Parsecs": 24.9813,
    "mag": 9.2,
    "Absolute Visual Magnitude": 7.212,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -24.092869,
    "y": -3.180208,
    "z": -5.78665,
    "vx": -0.00000279,
    "vy": 0.00002977,
    "vz": -0.00000476,
    "rarad": 3.27283349180843,
    "decrad": -0.2337624382363,
    "pmrarad": -0.00000119608383129861,
    "pmdecrad": -1.95864726e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 60805,
    "base": "Gl 469.2",
    "lum": 0.113553362784193,
    "ra": 12.501303,
    "dec": -13.393601,
    "pmra": -246.71,
    "pmdec": -40.4,
    "rv": 0,
    "Asset Name": "ASTRID 003036",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Middle Part",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000279,
    "y_1": 0.00002977,
    "z_1": -0.00000476,
    "Distance in Parsecs_1": 24.9813,
    "HYG Star ID_1": 118827,
    "Gliese Catalog_1": "Gl 469.2B"
  },
  {
    "Astrid #": "ASTRID 003037",
    "HYG Star ID": 30183,
    "Hipparcos Catalogue": 30256,
    "Gliese Catalog": "GJ 2049",
    "Distance in Parsecs": 25,
    "mag": 10.76,
    "Absolute Visual Magnitude": 8.77,
    "Color Index": 1.44,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -2.199819,
    "y": 22.954352,
    "z": -9.657045,
    "vx": 0.00007515,
    "vy": 0.00002035,
    "vz": 0.00003124,
    "rarad": 1.66634133408012,
    "decrad": -0.396597050123852,
    "pmrarad": -0.00000307027655759027,
    "pmdecrad": 0.000001354957274,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 30183,
    "lum": 0.0270395836410884,
    "var_min": 10.855,
    "var_max": 10.665,
    "ra": 6.364955,
    "dec": -22.723337,
    "pmra": -633.29,
    "pmdec": 279.48,
    "rv": 0,
    "Asset Name": "ASTRID 003037",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Star of Culture",
    "Hair": "Horns",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00007515,
    "y_1": 0.00002035,
    "z_1": 0.00003124,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 30183,
    "Hipparcos Catalogue_1": 30256,
    "Gliese Catalog_1": "GJ 2049"
  },
  {
    "Astrid #": "ASTRID 003038",
    "HYG Star ID": 118018,
    "Gliese Catalog": "NN 3055",
    "Distance in Parsecs": 25,
    "mag": 13.98,
    "Absolute Visual Magnitude": 11.99,
    "Spectral Type": "M3 :",
    "Color Index": 1.64,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.975471,
    "y": 4.562595,
    "z": -11.011875,
    "vx": 0.00000494,
    "vy": 0.00002637,
    "vz": 0.00002078,
    "rarad": 0.204708048072752,
    "decrad": -0.4561276992322,
    "pmrarad": 9.92801455041666e-7,
    "pmdecrad": 9.25795356e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118018,
    "lum": 0.0013931568029453,
    "ra": 0.781927,
    "dec": -26.134192,
    "pmra": 204.78,
    "pmdec": 190.96,
    "rv": 0,
    "Asset Name": "ASTRID 003038",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Owie",
    "Hair": "Bangs",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000494,
    "y_1": 0.00002637,
    "z_1": 0.00002078,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118018,
    "Gliese Catalog_1": "NN 3055"
  },
  {
    "Astrid #": "ASTRID 003039",
    "HYG Star ID": 118059,
    "Gliese Catalog": "NN 3083",
    "Distance in Parsecs": 25,
    "mag": 11.56,
    "Absolute Visual Magnitude": 9.57,
    "Spectral Type": "M0.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.681854,
    "y": 7.30676,
    "z": 11.994673,
    "vx": 0.00002933,
    "vy": -0.00003595,
    "vz": -0.00002866,
    "rarad": 0.339617884543242,
    "decrad": 0.50041181981215,
    "pmrarad": -0.00000174692913514583,
    "pmdecrad": -0.000001306863757,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 118059,
    "lum": 0.0129419584144998,
    "ra": 1.297245,
    "dec": 28.671485,
    "pmra": -360.33,
    "pmdec": -269.56,
    "rv": 0,
    "Asset Name": "ASTRID 003039",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Windy",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00002933,
    "y_1": -0.00003595,
    "z_1": -0.00002866,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118059,
    "Gliese Catalog_1": "NN 3083"
  },
  {
    "Astrid #": "ASTRID 003040",
    "HYG Star ID": 118064,
    "Gliese Catalog": "NN 3088",
    "Distance in Parsecs": 25,
    "mag": 14.2,
    "Absolute Visual Magnitude": 12.21,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.852819,
    "y": 7.575023,
    "z": -11.522975,
    "vx": -0.00001786,
    "vy": 0.00004076,
    "vz": -0.00000554,
    "rarad": 0.348428482866277,
    "decrad": -0.4790304864978,
    "pmrarad": 0.00000177635732555555,
    "pmdecrad": -2.49649956e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118064,
    "lum": 0.00113762728582342,
    "ra": 1.330899,
    "dec": -27.446425,
    "pmra": 366.4,
    "pmdec": -51.49,
    "rv": 0,
    "Asset Name": "ASTRID 003040",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Manbun",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001786,
    "y_1": 0.00004076,
    "z_1": -0.00000554,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118064,
    "Gliese Catalog_1": "NN 3088"
  },
  {
    "Astrid #": "ASTRID 003041",
    "HYG Star ID": 118098,
    "Gliese Catalog": "NN 3124",
    "Distance in Parsecs": 25,
    "mag": 13.93,
    "Absolute Visual Magnitude": 11.94,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.362246,
    "y": 10.035963,
    "z": 14.927555,
    "vx": 0.00001165,
    "vy": 0.00001616,
    "vz": -0.00002441,
    "rarad": 0.524107158556282,
    "decrad": 0.63988373194615,
    "pmrarad": 3.26231125645833e-7,
    "pmdecrad": -0.000001217561077,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118098,
    "lum": 0.00145881426027534,
    "ra": 2.001942,
    "dec": 36.662637,
    "pmra": 67.29,
    "pmdec": -251.14,
    "rv": 0,
    "Asset Name": "ASTRID 003041",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Audiohead",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001165,
    "y_1": 0.00001616,
    "z_1": -0.00002441,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118098,
    "Gliese Catalog_1": "NN 3124"
  },
  {
    "Astrid #": "ASTRID 003042",
    "HYG Star ID": 118134,
    "Gliese Catalog": "NN 3151A",
    "Distance in Parsecs": 25,
    "mag": 12.68,
    "Absolute Visual Magnitude": 10.69,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.162425,
    "y": 11.361043,
    "z": 15.32001,
    "vx": -0.00001988,
    "vy": 0.00003692,
    "vz": -0.0000064,
    "rarad": 0.612667172718114,
    "decrad": 0.659599510344,
    "pmrarad": 0.00000166567436228472,
    "pmdecrad": -3.2377312e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118134,
    "base": "NN 3151",
    "lum": 0.00461317574560379,
    "ra": 2.340216,
    "dec": 37.792268,
    "pmra": 343.57,
    "pmdec": -66.78,
    "rv": 0,
    "Asset Name": "ASTRID 003042",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Infatuated",
    "Hair": "Afro",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001988,
    "y_1": 0.00003692,
    "z_1": -0.0000064,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118134,
    "Gliese Catalog_1": "NN 3151A"
  },
  {
    "Astrid #": "ASTRID 003043",
    "HYG Star ID": 118135,
    "Gliese Catalog": "NN 3152B",
    "Distance in Parsecs": 25,
    "mag": 16.7,
    "Absolute Visual Magnitude": 14.71,
    "Spectral Type": "k",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.162425,
    "y": 11.361043,
    "z": 15.32001,
    "vx": -0.00001988,
    "vy": 0.00003692,
    "vz": -0.0000064,
    "rarad": 0.612667172718114,
    "decrad": 0.659599510344,
    "pmrarad": 0.00000166567436228472,
    "pmdecrad": -3.2377312e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118135,
    "base": "NN 3152",
    "lum": 0.000113762728582342,
    "ra": 2.340216,
    "dec": 37.792268,
    "pmra": 343.57,
    "pmdec": -66.78,
    "rv": 0,
    "Asset Name": "ASTRID 003043",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Bangs",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001988,
    "y_1": 0.00003692,
    "z_1": -0.0000064,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118135,
    "Gliese Catalog_1": "NN 3152B"
  },
  {
    "Astrid #": "ASTRID 003044",
    "HYG Star ID": 118151,
    "Henry Draper Catalogue": 15833,
    "Gliese Catalog": "NN 3163",
    "Distance in Parsecs": 25,
    "mag": 8.89,
    "Absolute Visual Magnitude": 6.9,
    "Spectral Type": "G5",
    "Color Index": 1.15,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.448034,
    "y": 15.32967,
    "z": 3.431507,
    "vx": -9.2e-7,
    "vy": 3.9e-7,
    "vz": 0.0000035,
    "rarad": 0.667527385753318,
    "decrad": 0.13769497212955,
    "pmrarad": 3.49550663680555e-8,
    "pmdecrad": 1.41182591e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 118151,
    "lum": 0.15135612484362,
    "ra": 2.549767,
    "dec": 7.889341,
    "pmra": 7.21,
    "pmdec": 29.12,
    "rv": 0,
    "Asset Name": "ASTRID 003044",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Innocent",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -9.2e-7,
    "y_1": 3.9e-7,
    "z_1": 0.0000035,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118151,
    "Henry Draper Catalogue_1": 15833,
    "Gliese Catalog_1": "NN 3163"
  },
  {
    "Astrid #": "ASTRID 003045",
    "HYG Star ID": 118186,
    "Gliese Catalog": "NN 3191",
    "Distance in Parsecs": 25,
    "mag": 13.41,
    "Absolute Visual Magnitude": 11.42,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.075788,
    "y": 14.98054,
    "z": 13.164499,
    "vx": -0.00001448,
    "vy": -0.00000466,
    "vz": 0.00002189,
    "rarad": 0.782226747645485,
    "decrad": 0.554572562180749,
    "pmrarad": 2.76052909708333e-7,
    "pmdecrad": 0.000001030243615,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118186,
    "lum": 0.002355049283896,
    "ra": 2.987886,
    "dec": 31.774667,
    "pmra": 56.94,
    "pmdec": 212.5,
    "rv": 0,
    "Asset Name": "ASTRID 003045",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Blep",
    "Hair": "Heartbreaker",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001448,
    "y_1": -0.00000466,
    "z_1": 0.00002189,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118186,
    "Gliese Catalog_1": "NN 3191"
  },
  {
    "Astrid #": "ASTRID 003046",
    "HYG Star ID": 118206,
    "Gliese Catalog": "NN 3212",
    "Distance in Parsecs": 25,
    "mag": 11.63,
    "Absolute Visual Magnitude": 9.64,
    "Spectral Type": "M0",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.278433,
    "y": 18.503202,
    "z": -4.200491,
    "vx": -0.00004162,
    "vy": 0.00002598,
    "vz": -0.00004689,
    "rarad": 0.849273653140143,
    "decrad": -0.16882039586645,
    "pmrarad": 0.000001936442802875,
    "pmdecrad": -0.000001902937329,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 118206,
    "lum": 0.0121338885046497,
    "ra": 3.243986,
    "dec": -9.672696,
    "pmra": 399.42,
    "pmdec": -392.51,
    "rv": 0,
    "Asset Name": "ASTRID 003046",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Malevolent",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00004162,
    "y_1": 0.00002598,
    "z_1": -0.00004689,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118206,
    "Gliese Catalog_1": "NN 3212"
  },
  {
    "Astrid #": "ASTRID 003047",
    "HYG Star ID": 118209,
    "Gliese Catalog": "NN 3214",
    "Distance in Parsecs": 25,
    "mag": 13.33,
    "Absolute Visual Magnitude": 11.34,
    "Spectral Type": "M3.5",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.940242,
    "y": 9.353483,
    "z": 21.782216,
    "vx": -0.00001796,
    "vy": 0.00006689,
    "vz": -0.00002216,
    "rarad": 0.866818583544435,
    "decrad": 1.05782196487955,
    "pmrarad": 0.00000227969088870833,
    "pmdecrad": -0.000001806842409,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118209,
    "lum": 0.0025351286304979,
    "ra": 3.311003,
    "dec": 60.608734,
    "pmra": 470.22,
    "pmdec": -372.69,
    "rv": 0,
    "Asset Name": "ASTRID 003047",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Awkward",
    "Hair": "Braided Ponytail",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001796,
    "y_1": 0.00006689,
    "z_1": -0.00002216,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118209,
    "Gliese Catalog_1": "NN 3214"
  },
  {
    "Astrid #": "ASTRID 003048",
    "HYG Star ID": 118222,
    "Gliese Catalog": "NN 3226",
    "Distance in Parsecs": 25,
    "mag": 10.45,
    "Absolute Visual Magnitude": 8.46,
    "Spectral Type": "K7",
    "Color Index": 1.39,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.191066,
    "y": 19.380742,
    "z": 4.3149,
    "vx": -0.00002377,
    "vy": 0.00001327,
    "vz": 0.0000241,
    "rarad": 0.905996851653789,
    "decrad": 0.17346463066825,
    "pmrarad": 0.00000107575307578472,
    "pmdecrad": 9.78853365e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118222,
    "lum": 0.0359749335155741,
    "ra": 3.460653,
    "dec": 9.938791,
    "pmra": 221.89,
    "pmdec": 201.9,
    "rv": 0,
    "Asset Name": "ASTRID 003048",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Braid",
    "Outfit": "Astro",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00002377,
    "y_1": 0.00001327,
    "z_1": 0.0000241,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118222,
    "Gliese Catalog_1": "NN 3226"
  },
  {
    "Astrid #": "ASTRID 003049",
    "HYG Star ID": 118280,
    "Gliese Catalog": "NN 3268",
    "Distance in Parsecs": 25,
    "mag": 13.87,
    "Absolute Visual Magnitude": 11.88,
    "Spectral Type": "m",
    "Color Index": 1.56,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.653738,
    "y": 13.154995,
    "z": -20.190936,
    "vx": -0.00002829,
    "vy": 0.00008489,
    "vz": 0.00004596,
    "rarad": 1.10243457178027,
    "decrad": -0.94013452919315,
    "pmrarad": 0.00000254323560545833,
    "pmdecrad": 0.000003118336137,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118280,
    "lum": 0.00154170045294955,
    "ra": 4.21099,
    "dec": -53.865741,
    "pmra": 524.58,
    "pmdec": 643.2,
    "rv": 0,
    "Asset Name": "ASTRID 003049",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002829,
    "y_1": 0.00008489,
    "z_1": 0.00004596,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118280,
    "Gliese Catalog_1": "NN 3268"
  },
  {
    "Astrid #": "ASTRID 003050",
    "HYG Star ID": 118302,
    "Gliese Catalog": "Wo 9155B",
    "Distance in Parsecs": 25,
    "mag": 7.3,
    "Absolute Visual Magnitude": 5.31,
    "Spectral Type": "G6 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.516438,
    "y": 12.419925,
    "z": -20.983445,
    "vx": -0.0000031,
    "vy": -0.00000102,
    "vz": 0.00000309,
    "rarad": 1.15280519101646,
    "decrad": -0.9960755476733,
    "pmrarad": 9.67203292708333e-8,
    "pmdecrad": -6.593466e-9,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 2,
    "comp_primary": 20500,
    "base": "Wo 9155",
    "lum": 0.654636174067274,
    "ra": 4.403391,
    "dec": -57.070925,
    "pmra": 19.95,
    "pmdec": -1.36,
    "rv": -3.7,
    "Asset Name": "ASTRID 003050",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Oof",
    "Hair": "Little Crown",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.0000031,
    "y_1": -0.00000102,
    "z_1": 0.00000309,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118302,
    "Gliese Catalog_1": "Wo 9155B"
  },
  {
    "Astrid #": "ASTRID 003051",
    "HYG Star ID": 118359,
    "Gliese Catalog": "NN 3339",
    "Distance in Parsecs": 25,
    "mag": 15.89,
    "Absolute Visual Magnitude": 13.9,
    "Spectral Type": "DA8",
    "Color Index": 0.42,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 4.93021,
    "y": 24.260419,
    "z": 3.482107,
    "vx": 0.00002596,
    "vy": -4.6e-7,
    "vz": -0.00003355,
    "rarad": 1.37030675184723,
    "decrad": 0.1397386319552,
    "pmrarad": -0.00000102116305535416,
    "pmdecrad": -0.000001355160896,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118359,
    "lum": 0.000239883291901948,
    "ra": 5.234186,
    "dec": 8.006434,
    "pmra": -210.63,
    "pmdec": -279.52,
    "rv": 0,
    "Asset Name": "ASTRID 003051",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Braided Ponytail",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002596,
    "y_1": -4.6e-7,
    "z_1": -0.00003355,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118359,
    "Gliese Catalog_1": "NN 3339"
  },
  {
    "Astrid #": "ASTRID 003052",
    "HYG Star ID": 118386,
    "Gliese Catalog": "NN 3361B",
    "Distance in Parsecs": 25,
    "mag": 9.7,
    "Absolute Visual Magnitude": 7.71,
    "Spectral Type": "K5 V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.649117,
    "y": 17.255206,
    "z": -18.015904,
    "vx": 0.00001555,
    "vy": -0.00003539,
    "vz": -0.00005165,
    "rarad": 1.47553027873633,
    "decrad": -0.80469079724375,
    "pmrarad": -7.53885273263888e-7,
    "pmdecrad": -0.000002405664875,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 2,
    "comp_primary": 118386,
    "base": "NN 3361",
    "lum": 0.0717794291271361,
    "ra": 5.636111,
    "dec": -46.105386,
    "pmra": -155.5,
    "pmdec": -496.2,
    "rv": 13.5,
    "Asset Name": "ASTRID 003052",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Frizzy",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00001555,
    "y_1": -0.00003539,
    "z_1": -0.00005165,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118386,
    "Gliese Catalog_1": "NN 3361B"
  },
  {
    "Astrid #": "ASTRID 003053",
    "HYG Star ID": 118404,
    "Gliese Catalog": "NN 3377C",
    "Distance in Parsecs": 25,
    "mag": 16.3,
    "Absolute Visual Magnitude": 14.31,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.185722,
    "y": 24.917625,
    "z": -2.019282,
    "vx": -0.00001,
    "vy": -0.00000203,
    "vz": -0.00002594,
    "rarad": 1.56334297817177,
    "decrad": -0.08085938132395,
    "pmrarad": 3.99583435513888e-7,
    "pmdecrad": -0.000001041006479,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 118404,
    "base": "NN 3377",
    "lum": 0.000164437172321492,
    "ra": 5.97153,
    "dec": -4.632901,
    "pmra": 82.42,
    "pmdec": -214.72,
    "rv": 0,
    "Asset Name": "ASTRID 003053",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Scared",
    "Hair": "Braided Bun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001,
    "y_1": -0.00000203,
    "z_1": -0.00002594,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118404,
    "Gliese Catalog_1": "NN 3377C"
  },
  {
    "Astrid #": "ASTRID 003054",
    "HYG Star ID": 118435,
    "Gliese Catalog": "NN 3402",
    "Distance in Parsecs": 25,
    "mag": 13.77,
    "Absolute Visual Magnitude": 11.78,
    "Spectral Type": "M3",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.3302,
    "y": 23.666923,
    "z": 6.791619,
    "vx": -0.00000587,
    "vy": 0.00001093,
    "vz": -0.00004183,
    "rarad": 1.75175868072664,
    "decrad": 0.2751224496349,
    "pmrarad": 1.52134532958333e-7,
    "pmdecrad": -0.000001738687302,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118435,
    "lum": 0.00169044093164326,
    "ra": 6.691225,
    "dec": 15.763355,
    "pmra": 31.38,
    "pmdec": -358.63,
    "rv": 0,
    "Asset Name": "ASTRID 003054",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Braided Ponytail",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000587,
    "y_1": 0.00001093,
    "z_1": -0.00004183,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118435,
    "Gliese Catalog_1": "NN 3402"
  },
  {
    "Astrid #": "ASTRID 003055",
    "HYG Star ID": 118461,
    "Gliese Catalog": "NN 3424",
    "Distance in Parsecs": 25,
    "mag": 11.62,
    "Absolute Visual Magnitude": 9.63,
    "Spectral Type": "M1",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.323106,
    "y": 21.757725,
    "z": 10.565024,
    "vx": 0.00001761,
    "vy": 0.0000191,
    "vz": -0.00002879,
    "rarad": 1.85362493464853,
    "decrad": 0.4363132293798,
    "pmrarad": -8.89827029291666e-7,
    "pmdecrad": -0.000001270832404,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 118461,
    "lum": 0.0122461619926504,
    "ra": 7.080326,
    "dec": 24.998907,
    "pmra": -183.54,
    "pmdec": -262.13,
    "rv": 0,
    "Asset Name": "ASTRID 003055",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Scared",
    "Hair": "Manbun",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00001761,
    "y_1": 0.0000191,
    "z_1": -0.00002879,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118461,
    "Gliese Catalog_1": "NN 3424"
  },
  {
    "Astrid #": "ASTRID 003056",
    "HYG Star ID": 118507,
    "Gliese Catalog": "NN 3465",
    "Distance in Parsecs": 25,
    "mag": 13.56,
    "Absolute Visual Magnitude": 11.57,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -11.719123,
    "y": 21.911542,
    "z": 2.747087,
    "vx": -0.00000624,
    "vy": -9e-8,
    "vz": -0.00002592,
    "rarad": 2.06192421668887,
    "decrad": 0.11010579998725,
    "pmrarad": 2.21753777486111e-7,
    "pmdecrad": -0.000001043280255,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118507,
    "lum": 0.00205116217882556,
    "ra": 7.87597,
    "dec": 6.308598,
    "pmra": 45.74,
    "pmdec": -215.19,
    "rv": 0,
    "Asset Name": "ASTRID 003056",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Happy Vamp",
    "Hair": "Bucket Hat",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000624,
    "y_1": -9e-8,
    "z_1": -0.00002592,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118507,
    "Gliese Catalog_1": "NN 3465"
  },
  {
    "Astrid #": "ASTRID 003057",
    "HYG Star ID": 118552,
    "Gliese Catalog": "NN 3510A",
    "Distance in Parsecs": 25,
    "mag": 12.93,
    "Absolute Visual Magnitude": 10.94,
    "Spectral Type": "m+",
    "Color Index": 1.58,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.858417,
    "y": 18.931908,
    "z": 3.885033,
    "vx": 0.00001931,
    "vy": 0.00001091,
    "vz": 0.00002568,
    "rarad": 2.26808051561688,
    "decrad": 0.15603371432795,
    "pmrarad": -8.72567662263888e-7,
    "pmdecrad": 0.000001039886559,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118552,
    "base": "NN 3510",
    "lum": 0.00366437574647833,
    "ra": 8.663429,
    "dec": 8.940073,
    "pmra": -179.98,
    "pmdec": 214.49,
    "rv": 0,
    "Asset Name": "ASTRID 003057",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Arrogant",
    "Hair": "Bangs",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001931,
    "y_1": 0.00001091,
    "z_1": 0.00002568,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118552,
    "Gliese Catalog_1": "NN 3510A"
  },
  {
    "Astrid #": "ASTRID 003058",
    "HYG Star ID": 118553,
    "Gliese Catalog": "NN 3511B",
    "Distance in Parsecs": 25,
    "mag": 12.96,
    "Absolute Visual Magnitude": 10.97,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.858417,
    "y": 18.931908,
    "z": 3.885033,
    "vx": 0.00001931,
    "vy": 0.00001091,
    "vz": 0.00002568,
    "rarad": 2.26808051561688,
    "decrad": 0.15603371432795,
    "pmrarad": -8.72567662263888e-7,
    "pmdecrad": 0.000001039886559,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118553,
    "base": "NN 3511",
    "lum": 0.00356451133426243,
    "ra": 8.663429,
    "dec": 8.940073,
    "pmra": -179.98,
    "pmdec": 214.49,
    "rv": 0,
    "Asset Name": "ASTRID 003058",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Neko Ninja",
    "Hair": "Fire",
    "Outfit": "Spartan",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001931,
    "y_1": 0.00001091,
    "z_1": 0.00002568,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118553,
    "Gliese Catalog_1": "NN 3511B"
  },
  {
    "Astrid #": "ASTRID 003059",
    "HYG Star ID": 118638,
    "Gliese Catalog": "NN 3561",
    "Distance in Parsecs": 25,
    "mag": 14,
    "Absolute Visual Magnitude": 12.01,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.115785,
    "y": 14.603419,
    "z": -2.663717,
    "vx": 0.00005379,
    "vy": 0.00006691,
    "vz": -0.00003957,
    "rarad": 2.51365112958457,
    "decrad": -0.10675137010315,
    "pmrarad": -0.0000034300083085625,
    "pmdecrad": -0.000001592162063,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118638,
    "lum": 0.00136772882559584,
    "ra": 9.60144,
    "dec": -6.116403,
    "pmra": -707.49,
    "pmdec": -328.41,
    "rv": 0,
    "Asset Name": "ASTRID 003059",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Good Boy",
    "Outfit": "Spartan",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005379,
    "y_1": 0.00006691,
    "z_1": -0.00003957,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118638,
    "Gliese Catalog_1": "NN 3561"
  },
  {
    "Astrid #": "ASTRID 003060",
    "HYG Star ID": 118713,
    "Gliese Catalog": "NN 3628B",
    "Distance in Parsecs": 25,
    "mag": 14.1,
    "Absolute Visual Magnitude": 12.11,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.774025,
    "y": 4.61205,
    "z": 19.633064,
    "vx": 0.00000816,
    "vy": 0.00002281,
    "vz": 7.9e-7,
    "rarad": 2.83903558950585,
    "decrad": 0.90321696927235,
    "pmrarad": -9.68269882805555e-7,
    "pmdecrad": 5.0745447e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118713,
    "base": "NN 3628",
    "lum": 0.00124738351424294,
    "ra": 10.844317,
    "dec": 51.75052,
    "pmra": -199.72,
    "pmdec": 10.47,
    "rv": 0,
    "Asset Name": "ASTRID 003060",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Ram Horns",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000816,
    "y_1": 0.00002281,
    "z_1": 7.9e-7,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118713,
    "Gliese Catalog_1": "NN 3628B"
  },
  {
    "Astrid #": "ASTRID 003061",
    "HYG Star ID": 118807,
    "Gliese Catalog": "NN 3718",
    "Distance in Parsecs": 25,
    "mag": 11.89,
    "Absolute Visual Magnitude": 9.9,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.332842,
    "y": -1.306997,
    "z": 15.796614,
    "vx": 0.00000161,
    "vy": 0.00003719,
    "vz": 0.00000506,
    "rarad": 3.20911637761573,
    "decrad": 0.6839564707602,
    "pmrarad": -0.00000148008768536805,
    "pmdecrad": 2.60975204e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 118807,
    "lum": 0.00954992586021435,
    "ra": 12.257922,
    "dec": 39.187819,
    "pmra": -305.29,
    "pmdec": 53.83,
    "rv": 0,
    "Asset Name": "ASTRID 003061",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "High Fade",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00000161,
    "y_1": 0.00003719,
    "z_1": 0.00000506,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118807,
    "Gliese Catalog_1": "NN 3718"
  },
  {
    "Astrid #": "ASTRID 003062",
    "HYG Star ID": 118851,
    "Gliese Catalog": "NN 3755",
    "Distance in Parsecs": 25,
    "mag": 14.14,
    "Absolute Visual Magnitude": 12.15,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.749634,
    "y": -4.879524,
    "z": 11.320057,
    "vx": -0.00001454,
    "vy": 0.00002035,
    "vz": -0.00001916,
    "rarad": 3.36229359933932,
    "decrad": 0.4699057566615,
    "pmrarad": -9.21873213576388e-7,
    "pmdecrad": -8.5966677e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118851,
    "lum": 0.00120226443461741,
    "ra": 12.843016,
    "dec": 26.923617,
    "pmra": -190.15,
    "pmdec": -177.32,
    "rv": 0,
    "Asset Name": "ASTRID 003062",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Fedora",
    "Outfit": "Astro",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001454,
    "y_1": 0.00002035,
    "z_1": -0.00001916,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118851,
    "Gliese Catalog_1": "NN 3755"
  },
  {
    "Astrid #": "ASTRID 003063",
    "HYG Star ID": 118852,
    "Gliese Catalog": "GJ 1166A",
    "Distance in Parsecs": 25,
    "mag": 12.97,
    "Absolute Visual Magnitude": 10.98,
    "Spectral Type": "dM4",
    "Color Index": 1.6,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -22.583264,
    "y": -5.151916,
    "z": 9.407576,
    "vx": -0.00001873,
    "vy": 0.00001617,
    "vz": 0.00001553,
    "rarad": 3.36588725261918,
    "decrad": 0.38578700508955,
    "pmrarad": -7.97227616305555e-7,
    "pmdecrad": 3.54941791e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118852,
    "base": "GJ 1166",
    "lum": 0.00353183169791956,
    "ra": 12.856742,
    "dec": 22.103967,
    "pmra": -164.44,
    "pmdec": 73.21,
    "rv": 19,
    "Asset Name": "ASTRID 003063",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Arrogant",
    "Hair": "High Bun",
    "Outfit": "River Spirit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001873,
    "y_1": 0.00001617,
    "z_1": 0.00001553,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118852,
    "Gliese Catalog_1": "GJ 1166A"
  },
  {
    "Astrid #": "ASTRID 003064",
    "HYG Star ID": 118853,
    "Gliese Catalog": "GJ 1166B",
    "Distance in Parsecs": 25,
    "mag": 14.28,
    "Absolute Visual Magnitude": 12.29,
    "Spectral Type": "dM3-3.5",
    "Color Index": 1.72,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -22.579072,
    "y": -5.159573,
    "z": 9.412898,
    "vx": -0.00001504,
    "vy": 0.00001701,
    "vz": 0.000014,
    "rarad": 3.36624987161918,
    "decrad": 0.38602009008955,
    "pmrarad": -7.97227616305555e-7,
    "pmdecrad": 3.54941791e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118852,
    "base": "GJ 1166",
    "lum": 0.00105681750921365,
    "ra": 12.858127,
    "dec": 22.117322,
    "pmra": -164.44,
    "pmdec": 73.21,
    "rv": 15,
    "Asset Name": "ASTRID 003064",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Wavy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001504,
    "y_1": 0.00001701,
    "z_1": 0.000014,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118853,
    "Gliese Catalog_1": "GJ 1166B"
  },
  {
    "Astrid #": "ASTRID 003065",
    "HYG Star ID": 118866,
    "Gliese Catalog": "NN 3763",
    "Distance in Parsecs": 25,
    "mag": 12.99,
    "Absolute Visual Magnitude": 11,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -22.912988,
    "y": -7.096694,
    "z": 7.04499,
    "vx": -0.00002377,
    "vy": 0.00005798,
    "vz": -0.00001888,
    "rarad": 3.44195144605318,
    "decrad": 0.2856691787349,
    "pmrarad": -0.00000249683893622916,
    "pmdecrad": -7.87245302e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118866,
    "lum": 0.00346736850452531,
    "ra": 13.147286,
    "dec": 16.367638,
    "pmra": -515.01,
    "pmdec": -162.38,
    "rv": 0,
    "Asset Name": "ASTRID 003065",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Infatuated",
    "Hair": "Pompadour",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002377,
    "y_1": 0.00005798,
    "z_1": -0.00001888,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118866,
    "Gliese Catalog_1": "NN 3763"
  },
  {
    "Astrid #": "ASTRID 003066",
    "HYG Star ID": 118949,
    "Gliese Catalog": "NN 3832C",
    "Distance in Parsecs": 25,
    "mag": 13.96,
    "Absolute Visual Magnitude": 11.97,
    "Spectral Type": "m",
    "Color Index": 1.6,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -20.086334,
    "y": -13.331022,
    "z": -6.619897,
    "vx": 4.6e-7,
    "vy": 0.00001075,
    "vz": -0.00002306,
    "rarad": 3.72752967534073,
    "decrad": -0.2679922563538,
    "pmrarad": -3.48193185374999e-7,
    "pmdecrad": -9.56707076e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 118949,
    "base": "NN 3832",
    "lum": 0.00141905752168908,
    "ra": 14.238115,
    "dec": -15.354825,
    "pmra": -71.82,
    "pmdec": -197.34,
    "rv": 0,
    "Asset Name": "ASTRID 003066",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Worried Vamp",
    "Hair": "Fire",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 4.6e-7,
    "y_1": 0.00001075,
    "z_1": -0.00002306,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118949,
    "Gliese Catalog_1": "NN 3832C"
  },
  {
    "Astrid #": "ASTRID 003067",
    "HYG Star ID": 118982,
    "Henry Draper Catalogue": 129715,
    "Gliese Catalog": "NN 3864A",
    "Distance in Parsecs": 25,
    "mag": 9.32,
    "Absolute Visual Magnitude": 7.33,
    "Spectral Type": "K2/3 V",
    "Color Index": 1.17,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.423856,
    "y": -15.223752,
    "z": -9.468191,
    "vx": 0.00000138,
    "vy": 0.00002188,
    "vz": -0.00003772,
    "rarad": 3.8597056246895,
    "decrad": -0.38842114957165,
    "pmrarad": -6.226462099375e-7,
    "pmdecrad": -0.000001630491433,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 118982,
    "base": "NN 3864",
    "lum": 0.101859138805411,
    "ra": 14.742989,
    "dec": -22.254893,
    "pmra": -128.43,
    "pmdec": -336.31,
    "rv": 0,
    "Asset Name": "ASTRID 003067",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Spiky",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000138,
    "y_1": 0.00002188,
    "z_1": -0.00003772,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118982,
    "Henry Draper Catalogue_1": 129715,
    "Gliese Catalog_1": "NN 3864A"
  },
  {
    "Astrid #": "ASTRID 003068",
    "HYG Star ID": 118983,
    "Gliese Catalog": "NN 3865B",
    "Distance in Parsecs": 25,
    "mag": 16.3,
    "Absolute Visual Magnitude": 14.31,
    "Spectral Type": "k-m",
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.419293,
    "y": -15.231127,
    "z": -9.464726,
    "vx": 0.00000279,
    "vy": 0.00001935,
    "vz": -0.00003627,
    "rarad": 3.86007491173736,
    "decrad": -0.38827141843345,
    "pmrarad": -5.09345252791666e-7,
    "pmdecrad": -0.000001567688669,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118983,
    "base": "NN 3865",
    "lum": 0.000164437172321492,
    "ra": 14.7444,
    "dec": -22.246314,
    "pmra": -105.06,
    "pmdec": -323.36,
    "rv": 0,
    "Asset Name": "ASTRID 003068",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Straw Hat",
    "Outfit": "Astro",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000279,
    "y_1": 0.00001935,
    "z_1": -0.00003627,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 118983,
    "Gliese Catalog_1": "NN 3865B"
  },
  {
    "Astrid #": "ASTRID 003069",
    "HYG Star ID": 119036,
    "Gliese Catalog": "NN 3914",
    "Distance in Parsecs": 25,
    "mag": 13.1,
    "Absolute Visual Magnitude": 11.11,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.043375,
    "y": -17.743065,
    "z": -12.85071,
    "vx": -0.00002433,
    "vy": 0.00004242,
    "vz": -0.00003578,
    "rarad": 4.11607171646309,
    "decrad": -0.53987456485905,
    "pmrarad": -0.00000175837073800694,
    "pmdecrad": -0.000001668617181,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119036,
    "lum": 0.00313328572431558,
    "ra": 15.722236,
    "dec": -30.932534,
    "pmra": -362.69,
    "pmdec": -344.18,
    "rv": 0,
    "Asset Name": "ASTRID 003069",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Braided Ponytail",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002433,
    "y_1": 0.00004242,
    "z_1": -0.00003578,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119036,
    "Gliese Catalog_1": "NN 3914"
  },
  {
    "Astrid #": "ASTRID 003070",
    "HYG Star ID": 119050,
    "Gliese Catalog": "NN 3932",
    "Distance in Parsecs": 25,
    "mag": 13.99,
    "Absolute Visual Magnitude": 12,
    "Spectral Type": "m",
    "Color Index": 1.58,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.088483,
    "y": -20.905047,
    "z": 6.468976,
    "vx": -0.00005488,
    "vy": 0.00000523,
    "vz": -0.00008558,
    "rarad": 4.18811418625865,
    "decrad": 0.261737251843899,
    "pmrarad": -0.00000200533482688194,
    "pmdecrad": -0.000003544463122,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119050,
    "lum": 0.00138038426460288,
    "ra": 15.997418,
    "dec": 14.99644,
    "pmra": -413.63,
    "pmdec": -731.1,
    "rv": 0,
    "Asset Name": "ASTRID 003070",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Pirate Hat",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005488,
    "y_1": 0.00000523,
    "z_1": -0.00008558,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119050,
    "Gliese Catalog_1": "NN 3932"
  },
  {
    "Astrid #": "ASTRID 003071",
    "HYG Star ID": 119121,
    "Gliese Catalog": "NN 3982",
    "Distance in Parsecs": 25,
    "mag": 8.84,
    "Absolute Visual Magnitude": 6.85,
    "Spectral Type": "K3 V",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.966769,
    "y": -11.526153,
    "z": -21.985133,
    "vx": -0.00000132,
    "vy": 0.00002151,
    "vz": -0.0000111,
    "rarad": 4.46047757652922,
    "decrad": -1.0746115994709,
    "pmrarad": -2.65629415576388e-7,
    "pmdecrad": -9.32529418e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 119121,
    "lum": 0.158489319246111,
    "ra": 17.037769,
    "dec": -61.570709,
    "pmra": -54.79,
    "pmdec": -192.35,
    "rv": 0,
    "Asset Name": "ASTRID 003071",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Neko",
    "Outfit": "Mecha",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -0.00000132,
    "y_1": 0.00002151,
    "z_1": -0.0000111,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119121,
    "Gliese Catalog_1": "NN 3982"
  },
  {
    "Astrid #": "ASTRID 003072",
    "HYG Star ID": 119136,
    "Gliese Catalog": "NN 3994",
    "Distance in Parsecs": 25,
    "mag": 12.35,
    "Absolute Visual Magnitude": 10.36,
    "Spectral Type": "m",
    "Color Index": 1.52,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.379773,
    "y": -21.855107,
    "z": 11.321305,
    "vx": -0.00003121,
    "vy": 0.00001521,
    "vz": 0.00001729,
    "rarad": 4.51461656079235,
    "decrad": 0.469961727851999,
    "pmrarad": -0.00000134356415292361,
    "pmdecrad": 7.7569704e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119136,
    "lum": 0.00625172692775685,
    "ra": 17.244565,
    "dec": 26.926824,
    "pmra": -277.13,
    "pmdec": 160,
    "rv": 0,
    "Asset Name": "ASTRID 003072",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Middle Part",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003121,
    "y_1": 0.00001521,
    "z_1": 0.00001729,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119136,
    "Gliese Catalog_1": "NN 3994"
  },
  {
    "Astrid #": "ASTRID 003073",
    "HYG Star ID": 119245,
    "Gliese Catalog": "NN 4086B",
    "Distance in Parsecs": 25,
    "mag": 12.3,
    "Absolute Visual Magnitude": 10.31,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.407853,
    "y": -14.163419,
    "z": -20.317089,
    "vx": 0.00002082,
    "vy": 0.00001154,
    "vz": -0.00000455,
    "rarad": 4.94847736588706,
    "decrad": -0.94874278284805,
    "pmrarad": 9.17897741395833e-7,
    "pmdecrad": -3.12476961e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119245,
    "base": "NN 4086",
    "lum": 0.00654636174067274,
    "ra": 18.901791,
    "dec": -54.358957,
    "pmra": 189.33,
    "pmdec": -64.45,
    "rv": 0,
    "Asset Name": "ASTRID 003073",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Greasy",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002082,
    "y_1": 0.00001154,
    "z_1": -0.00000455,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119245,
    "Gliese Catalog_1": "NN 4086B"
  },
  {
    "Astrid #": "ASTRID 003074",
    "HYG Star ID": 119258,
    "Gliese Catalog": "NN 4094A",
    "Distance in Parsecs": 25,
    "mag": 10.6,
    "Absolute Visual Magnitude": 8.61,
    "Spectral Type": "M0",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.988735,
    "y": -10.545956,
    "z": 22.468874,
    "vx": -0.00000305,
    "vy": 0.00002161,
    "vz": 0.00001055,
    "rarad": 4.98854115745469,
    "decrad": 1.11692150793944,
    "pmrarad": 1.18149093951388e-7,
    "pmdecrad": 9.62398789e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 119258,
    "base": "NN 4094",
    "lum": 0.0313328572431558,
    "ra": 19.054824,
    "dec": 63.994888,
    "pmra": 24.37,
    "pmdec": 198.51,
    "rv": 0,
    "Asset Name": "ASTRID 003074",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Glad",
    "Hair": "Headband",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000305,
    "y_1": 0.00002161,
    "z_1": 0.00001055,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119258,
    "Gliese Catalog_1": "NN 4094A"
  },
  {
    "Astrid #": "ASTRID 003075",
    "HYG Star ID": 119317,
    "Gliese Catalog": "Wo 9677B",
    "Distance in Parsecs": 25,
    "mag": 13.5,
    "Absolute Visual Magnitude": 11.51,
    "Spectral Type": "m",
    "Color Index": 1.48,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.233535,
    "y": -11.200289,
    "z": 21.463844,
    "vx": -0.00003745,
    "vy": -0.0000338,
    "vz": -0.00000675,
    "rarad": 5.22034141496896,
    "decrad": 1.03244226140685,
    "pmrarad": -0.00000196684062064583,
    "pmdecrad": -5.27011863e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 97814,
    "base": "Wo 9677",
    "lum": 0.00216770410481969,
    "ra": 19.940235,
    "dec": 59.154584,
    "pmra": -405.69,
    "pmdec": -108.7,
    "rv": 0,
    "Asset Name": "ASTRID 003075",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Pixie",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003745,
    "y_1": -0.0000338,
    "z_1": -0.00000675,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119317,
    "Gliese Catalog_1": "Wo 9677B"
  },
  {
    "Astrid #": "ASTRID 003076",
    "HYG Star ID": 119383,
    "Gliese Catalog": "NN 4175",
    "Distance in Parsecs": 25,
    "mag": 14.1,
    "Absolute Visual Magnitude": 12.11,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.635039,
    "y": -16.431643,
    "z": 8.847405,
    "vx": -0.00001956,
    "vy": -0.00004316,
    "vz": -0.00004336,
    "rarad": 5.50394430289878,
    "decrad": 0.361733638626599,
    "pmrarad": -0.00000177844202438194,
    "pmdecrad": -0.000001854567468,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119383,
    "lum": 0.00124738351424294,
    "ra": 21.023519,
    "dec": 20.725811,
    "pmra": -366.83,
    "pmdec": -382.53,
    "rv": 0,
    "Asset Name": "ASTRID 003076",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Greasy",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001956,
    "y_1": -0.00004316,
    "z_1": -0.00004336,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119383,
    "Gliese Catalog_1": "NN 4175"
  },
  {
    "Astrid #": "ASTRID 003077",
    "HYG Star ID": 119411,
    "Gliese Catalog": "NN 4192",
    "Distance in Parsecs": 25,
    "mag": 13.64,
    "Absolute Visual Magnitude": 11.65,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.236495,
    "y": -15.533842,
    "z": 3.69554,
    "vx": -0.00001181,
    "vy": -0.0000194,
    "vz": -0.00002005,
    "rarad": 5.60387665523314,
    "decrad": 0.14836534069895,
    "pmrarad": -9.00735337104166e-7,
    "pmdecrad": -8.11006021e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119411,
    "lum": 0.00190546071796324,
    "ra": 21.405232,
    "dec": 8.500708,
    "pmra": -185.79,
    "pmdec": -167.28,
    "rv": 0,
    "Asset Name": "ASTRID 003077",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Honest Lad",
    "Outfit": "Samurai",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001181,
    "y_1": -0.0000194,
    "z_1": -0.00002005,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119411,
    "Gliese Catalog_1": "NN 4192"
  },
  {
    "Astrid #": "ASTRID 003078",
    "HYG Star ID": 119440,
    "Gliese Catalog": "NN 4220",
    "Distance in Parsecs": 25,
    "mag": 11.79,
    "Absolute Visual Magnitude": 9.8,
    "Spectral Type": "M1.5",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.855722,
    "y": -13.550114,
    "z": 2.536392,
    "vx": 0.00002403,
    "vy": 0.00003089,
    "vz": -0.00003255,
    "rarad": 5.70701550597679,
    "decrad": 0.10163057042535,
    "pmrarad": 0.00000155983953581944,
    "pmdecrad": -0.000001308851493,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119440,
    "lum": 0.0104712854805089,
    "ra": 21.799193,
    "dec": 5.823003,
    "pmra": 321.74,
    "pmdec": -269.97,
    "rv": 0,
    "Asset Name": "ASTRID 003078",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Elegant",
    "Outfit": "Elven",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00002403,
    "y_1": 0.00003089,
    "z_1": -0.00003255,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119440,
    "Gliese Catalog_1": "NN 4220"
  },
  {
    "Astrid #": "ASTRID 003079",
    "HYG Star ID": 119470,
    "Gliese Catalog": "NN 4255",
    "Distance in Parsecs": 25,
    "mag": 15.87,
    "Absolute Visual Magnitude": 13.88,
    "Spectral Type": "m",
    "Color Index": 1.8,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 4.436886,
    "y": -2.471109,
    "z": 24.478719,
    "vx": 0.00000784,
    "vy": 0.00003047,
    "vz": 0.00000166,
    "rarad": 5.77478566805386,
    "decrad": 1.36622789104105,
    "pmrarad": 0.00000121756107734722,
    "pmdecrad": 3.26240821e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119470,
    "lum": 0.000244343055269396,
    "ra": 22.058056,
    "dec": 78.279092,
    "pmra": 251.14,
    "pmdec": 67.29,
    "rv": 0,
    "Asset Name": "ASTRID 003079",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Neko",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000784,
    "y_1": 0.00003047,
    "z_1": 0.00000166,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119470,
    "Gliese Catalog_1": "NN 4255"
  },
  {
    "Astrid #": "ASTRID 003080",
    "HYG Star ID": 119549,
    "Gliese Catalog": "GJ 1281",
    "Distance in Parsecs": 25,
    "mag": 12.47,
    "Absolute Visual Magnitude": 10.48,
    "Spectral Type": "M3",
    "Color Index": 1.49,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.064146,
    "y": -5.039164,
    "z": -8.233631,
    "vx": -0.00000237,
    "vy": 0.00000544,
    "vz": -0.00018096,
    "rarad": 6.06808021543121,
    "decrad": -0.3355708574198,
    "pmrarad": 1.92228624340277e-7,
    "pmdecrad": -0.000006881668396,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119549,
    "lum": 0.00559757601495109,
    "ra": 23.178359,
    "dec": -19.226794,
    "pmra": 39.65,
    "pmdec": -1419.45,
    "rv": 55,
    "Asset Name": "ASTRID 003080",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Blep",
    "Hair": "Audiohead",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000237,
    "y_1": 0.00000544,
    "z_1": -0.00018096,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119549,
    "Gliese Catalog_1": "GJ 1281"
  },
  {
    "Astrid #": "ASTRID 003081",
    "HYG Star ID": 119595,
    "Gliese Catalog": "NN 4364",
    "Distance in Parsecs": 25,
    "mag": 11.39,
    "Absolute Visual Magnitude": 9.4,
    "Spectral Type": "M1",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 24.709421,
    "y": -1.119754,
    "z": 3.631897,
    "vx": 0.00000618,
    "vy": 0.00000546,
    "vz": -0.00004037,
    "rarad": 6.23789929669168,
    "decrad": 0.14579181279855,
    "pmrarad": 2.29413833638888e-7,
    "pmdecrad": -0.000001632324029,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 119595,
    "lum": 0.015135612484362,
    "ra": 23.82702,
    "dec": 8.353256,
    "pmra": 47.32,
    "pmdec": -336.69,
    "rv": 0,
    "Asset Name": "ASTRID 003081",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Serious Shinobi",
    "Hair": "Braid",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000618,
    "y_1": 0.00000546,
    "z_1": -0.00004037,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119595,
    "Gliese Catalog_1": "NN 4364"
  },
  {
    "Astrid #": "ASTRID 003082",
    "HYG Star ID": 119606,
    "Gliese Catalog": "NN 4376",
    "Distance in Parsecs": 25,
    "mag": 13.95,
    "Absolute Visual Magnitude": 11.96,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 24.934594,
    "y": -0.49774,
    "z": -1.737326,
    "vx": 0.0000014,
    "vy": 0.00006297,
    "vz": 0.00000197,
    "rarad": 6.26322587153116,
    "decrad": -0.0695490587690999,
    "pmrarad": 0.00000251977062331944,
    "pmdecrad": 7.9184618e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119606,
    "lum": 0.00143218789927354,
    "ra": 23.923761,
    "dec": -3.984868,
    "pmra": 519.74,
    "pmdec": 16.33,
    "rv": 0,
    "Asset Name": "ASTRID 003082",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Good Boy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000014,
    "y_1": 0.00006297,
    "z_1": 0.00000197,
    "Distance in Parsecs_1": 25,
    "HYG Star ID_1": 119606,
    "Gliese Catalog_1": "NN 4376"
  },
  {
    "Astrid #": "ASTRID 003083",
    "HYG Star ID": 39598,
    "Hipparcos Catalogue": 39710,
    "Henry Draper Catalogue": 67458,
    "Gliese Catalog": "Gl 296.1",
    "Distance in Parsecs": 25.0063,
    "mag": 6.8,
    "Absolute Visual Magnitude": 4.81,
    "Spectral Type": "G2V",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.46431,
    "y": 18.524546,
    "z": -12.276725,
    "vx": -0.00001565,
    "vy": -0.00005295,
    "vz": -0.00002862,
    "rarad": 2.1249742168143,
    "decrad": -0.513178055374376,
    "pmrarad": 0.00000164637877779861,
    "pmdecrad": -0.000001719585643,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 39598,
    "lum": 1.03752841581801,
    "ra": 8.116804,
    "dec": -29.402937,
    "pmra": 339.59,
    "pmdec": -354.69,
    "rv": -17.6,
    "Asset Name": "ASTRID 003083",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Pompadour",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001565,
    "y_1": -0.00005295,
    "z_1": -0.00002862,
    "Distance in Parsecs_1": 25.0063,
    "HYG Star ID_1": 39598,
    "Hipparcos Catalogue_1": 39710,
    "Henry Draper Catalogue_1": 67458,
    "Gliese Catalog_1": "Gl 296.1"
  },
  {
    "Astrid #": "ASTRID 003084",
    "HYG Star ID": 95279,
    "Hipparcos Catalogue": 95575,
    "Henry Draper Catalogue": 183255,
    "Gliese Catalog": "GJ 1237",
    "Distance in Parsecs": 25.0125,
    "mag": 8.01,
    "Absolute Visual Magnitude": 6.019,
    "Spectral Type": "K3V",
    "Color Index": 0.929,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.986211,
    "y": -15.113073,
    "z": 19.009371,
    "vx": 0.00001133,
    "vy": 0.00012194,
    "vz": 0.00000551,
    "rarad": 5.08951394054707,
    "decrad": 0.863332539462056,
    "pmrarad": 0.00000221651966613194,
    "pmdecrad": 0.000003461424235,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 95279,
    "lum": 0.340721861659357,
    "ra": 19.440511,
    "dec": 49.465311,
    "pmra": 457.19,
    "pmdec": 713.97,
    "rv": -65.3,
    "Asset Name": "ASTRID 003084",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Battle Ready",
    "Hair": "Sweetheart",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001133,
    "y_1": 0.00012194,
    "z_1": 0.00000551,
    "Distance in Parsecs_1": 25.0125,
    "HYG Star ID_1": 95279,
    "Hipparcos Catalogue_1": 95575,
    "Henry Draper Catalogue_1": 183255,
    "Gliese Catalog_1": "GJ 1237"
  },
  {
    "Astrid #": "ASTRID 003085",
    "HYG Star ID": 81564,
    "Hipparcos Catalogue": 81813,
    "Henry Draper Catalogue": 151541,
    "Gliese Catalog": "Gl 637.1",
    "Distance in Parsecs": 25.0188,
    "mag": 7.56,
    "Absolute Visual Magnitude": 5.569,
    "Spectral Type": "K1V",
    "Color Index": 0.769,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.090018,
    "y": -8.804361,
    "z": 23.21379,
    "vx": -0.00001769,
    "vy": 0.000053,
    "vz": 0.00002921,
    "rarad": 4.3748753319508,
    "decrad": 1.18860707845543,
    "pmrarad": -0.00000136891990841666,
    "pmdecrad": 0.000002072966335,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 81564,
    "lum": 0.515703406302645,
    "ra": 16.710793,
    "dec": 68.102169,
    "pmra": -282.36,
    "pmdec": 427.58,
    "rv": 10.4,
    "Asset Name": "ASTRID 003085",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Spiky",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00001769,
    "y_1": 0.000053,
    "z_1": 0.00002921,
    "Distance in Parsecs_1": 25.0188,
    "HYG Star ID_1": 81564,
    "Hipparcos Catalogue_1": 81813,
    "Henry Draper Catalogue_1": 151541,
    "Gliese Catalog_1": "Gl 637.1"
  },
  {
    "Astrid #": "ASTRID 003086",
    "HYG Star ID": 24726,
    "Hipparcos Catalogue": 24786,
    "Henry Draper Catalogue": 34721,
    "Harvard Revised Catalogue": 1747,
    "Gliese Catalog": "Gl 198",
    "Distance in Parsecs": 25.025,
    "mag": 5.96,
    "Absolute Visual Magnitude": 3.968,
    "Spectral Type": "G0V",
    "Color Index": 0.572,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.248232,
    "y": 23.400421,
    "z": -7.787264,
    "vx": -0.00003854,
    "vy": 0.00004916,
    "vz": -0.00000576,
    "rarad": 1.39120634116086,
    "decrad": -0.316429072732205,
    "pmrarad": 0.00000186653267013888,
    "pmdecrad": 2.97287748e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 24726,
    "lum": 2.25320133562948,
    "ra": 5.314017,
    "dec": -18.13005,
    "pmra": 385,
    "pmdec": 61.32,
    "rv": 40.3,
    "Asset Name": "ASTRID 003086",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Prince",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.00003854,
    "y_1": 0.00004916,
    "z_1": -0.00000576,
    "Distance in Parsecs_1": 25.025,
    "HYG Star ID_1": 24726,
    "Hipparcos Catalogue_1": 24786,
    "Henry Draper Catalogue_1": 34721,
    "Harvard Revised Catalogue_1": 1747,
    "Gliese Catalog_1": "Gl 198"
  },
  {
    "Astrid #": "ASTRID 003087",
    "HYG Star ID": 35530,
    "Hipparcos Catalogue": 35628,
    "Henry Draper Catalogue": 56168,
    "Distance in Parsecs": 25.025,
    "mag": 8.38,
    "Absolute Visual Magnitude": 6.388,
    "Spectral Type": "K0",
    "Color Index": 0.888,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.296385,
    "y": 8.921789,
    "z": 23.146969,
    "vx": 0.00001194,
    "vy": -0.00000762,
    "vz": -0.00000509,
    "rarad": 1.92471990476033,
    "decrad": 1.18092178615012,
    "pmrarad": -3.42084533e-7,
    "pmdecrad": 3.39902871e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 35530,
    "lum": 0.242549285710942,
    "ra": 7.351888,
    "dec": 67.661834,
    "pmra": -70.56,
    "pmdec": 70.11,
    "rv": -8.8,
    "Asset Name": "ASTRID 003087",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Oof",
    "Hair": "Good Boy",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00001194,
    "y_1": -0.00000762,
    "z_1": -0.00000509,
    "Distance in Parsecs_1": 25.025,
    "HYG Star ID_1": 35530,
    "Hipparcos Catalogue_1": 35628,
    "Henry Draper Catalogue_1": 56168
  },
  {
    "Astrid #": "ASTRID 003088",
    "HYG Star ID": 90097,
    "Hipparcos Catalogue": 90376,
    "Gliese Catalog": "NN 4061",
    "Distance in Parsecs": 25.0376,
    "mag": 9.24,
    "Absolute Visual Magnitude": 7.247,
    "Spectral Type": "K5",
    "Color Index": 1.166,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.529806,
    "y": -4.565028,
    "z": 24.611971,
    "vx": -0.00000297,
    "vy": 0.00004776,
    "vz": -0.00001917,
    "rarad": 4.82792565239041,
    "decrad": 1.38619721263696,
    "pmrarad": 1.02101761125e-7,
    "pmdecrad": 0.000001735729939,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 90097,
    "lum": 0.109951206687934,
    "ra": 18.441318,
    "dec": 79.42325,
    "pmra": 21.06,
    "pmdec": 358.02,
    "rv": -27,
    "Asset Name": "ASTRID 003088",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Bucket Hat",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000297,
    "y_1": 0.00004776,
    "z_1": -0.00001917,
    "Distance in Parsecs_1": 25.0376,
    "HYG Star ID_1": 90097,
    "Hipparcos Catalogue_1": 90376,
    "Gliese Catalog_1": "NN 4061"
  },
  {
    "Astrid #": "ASTRID 003089",
    "HYG Star ID": 87497,
    "Hipparcos Catalogue": 87768,
    "Gliese Catalog": "Gl 698",
    "Distance in Parsecs": 25.0438,
    "mag": 9.22,
    "Absolute Visual Magnitude": 7.226,
    "Spectral Type": "K5",
    "Color Index": 1.18,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.440561,
    "y": -23.745244,
    "z": 7.946592,
    "vx": -0.00000509,
    "vy": 0.00002619,
    "vz": -0.00001485,
    "rarad": 4.6938375681702,
    "decrad": 0.322892611691001,
    "pmrarad": -2.22723404847222e-7,
    "pmdecrad": -2.31692457e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 87497,
    "lum": 0.112098551287531,
    "var_min": 9.271,
    "var_max": 9.161,
    "ra": 17.929139,
    "dec": 18.500384,
    "pmra": -45.94,
    "pmdec": -47.79,
    "rv": -28.8,
    "Asset Name": "ASTRID 003089",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Braided Ponytail",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000509,
    "y_1": 0.00002619,
    "z_1": -0.00001485,
    "Distance in Parsecs_1": 25.0438,
    "HYG Star ID_1": 87497,
    "Hipparcos Catalogue_1": 87768,
    "Gliese Catalog_1": "Gl 698"
  },
  {
    "Astrid #": "ASTRID 003090",
    "HYG Star ID": 101895,
    "Hipparcos Catalogue": 102226,
    "Henry Draper Catalogue": 197396,
    "Distance in Parsecs": 25.0438,
    "mag": 8.26,
    "Absolute Visual Magnitude": 6.266,
    "Spectral Type": "K0",
    "Color Index": 0.889,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.263893,
    "y": -17.742363,
    "z": 8.911455,
    "vx": 0.00000243,
    "vy": -0.00001798,
    "vz": -0.00003996,
    "rarad": 5.42283712776298,
    "decrad": 0.363807149254407,
    "pmrarad": -3.94541373236111e-7,
    "pmdecrad": -0.000001707513782,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 101895,
    "lum": 0.271393848764158,
    "ra": 20.713712,
    "dec": 20.844614,
    "pmra": -81.38,
    "pmdec": -352.2,
    "rv": 0,
    "Asset Name": "ASTRID 003090",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Shinobi",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00000243,
    "y_1": -0.00001798,
    "z_1": -0.00003996,
    "Distance in Parsecs_1": 25.0438,
    "HYG Star ID_1": 101895,
    "Hipparcos Catalogue_1": 102226,
    "Henry Draper Catalogue_1": 197396
  },
  {
    "Astrid #": "ASTRID 003091",
    "HYG Star ID": 65272,
    "Hipparcos Catalogue": 65477,
    "Henry Draper Catalogue": 116842,
    "Harvard Revised Catalogue": 5062,
    "Gliese Catalog": "NN 3785",
    "Proper Name": "Alcor",
    "Distance in Parsecs": 25.0564,
    "mag": 3.99,
    "Absolute Visual Magnitude": 1.995,
    "Spectral Type": "A5V SB",
    "Color Index": 0.169,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -13.393436,
    "y": -5.223608,
    "z": 20.521915,
    "vx": 0.00000861,
    "vy": -0.00001233,
    "vz": -0.00000864,
    "rarad": 3.51345583039106,
    "decrad": 0.959720908384926,
    "pmrarad": 5.83473264548611e-7,
    "pmdecrad": -8.2127437e-8,
    "flam": 80,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 65272,
    "lum": 13.8675582887188,
    "bf": "80    UMa",
    "ra": 13.420413,
    "dec": 54.987958,
    "pmra": 120.35,
    "pmdec": -16.94,
    "rv": -8.9,
    "Asset Name": "ASTRID 003091",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Seeing Red",
    "Hair": "Bucket Hat",
    "Outfit": "Stars and Loathing",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "Alcor",
    "Constellation": "Ursa Major",
    "x_1": 0.00000861,
    "y_1": -0.00001233,
    "z_1": -0.00000864,
    "Distance in Parsecs_1": 25.0564,
    "HYG Star ID_1": 65272,
    "Hipparcos Catalogue_1": 65477,
    "Henry Draper Catalogue_1": 116842,
    "Harvard Revised Catalogue_1": 5062,
    "Gliese Catalog_1": "NN 3785"
  },
  {
    "Astrid #": "ASTRID 003092",
    "HYG Star ID": 112095,
    "Hipparcos Catalogue": 112452,
    "Gliese Catalog": "NN 4293",
    "Distance in Parsecs": 25.0627,
    "mag": 11.51,
    "Absolute Visual Magnitude": 9.515,
    "Spectral Type": "M3",
    "Color Index": 1.633,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.690338,
    "y": -3.537967,
    "z": -22.390588,
    "vx": -0.00000415,
    "vy": 0.00002652,
    "vz": -0.00000617,
    "rarad": 5.96357111761364,
    "decrad": -1.10481903271631,
    "pmrarad": 9.52755845027777e-7,
    "pmdecrad": -5.48081865e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 112095,
    "lum": 0.0136144468246594,
    "var_min": 11.623,
    "var_max": 11.393,
    "ra": 22.779164,
    "dec": -63.301468,
    "pmra": 196.52,
    "pmdec": -113.05,
    "rv": 0,
    "Asset Name": "ASTRID 003092",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Medium Bob",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000415,
    "y_1": 0.00002652,
    "z_1": -0.00000617,
    "Distance in Parsecs_1": 25.0627,
    "HYG Star ID_1": 112095,
    "Hipparcos Catalogue_1": 112452,
    "Gliese Catalog_1": "NN 4293"
  },
  {
    "Astrid #": "ASTRID 003093",
    "HYG Star ID": 51784,
    "Hipparcos Catalogue": 51933,
    "Henry Draper Catalogue": 91889,
    "Harvard Revised Catalogue": 4158,
    "Gliese Catalog": "Gl 398.1",
    "Distance in Parsecs": 25.0752,
    "mag": 5.71,
    "Absolute Visual Magnitude": 3.714,
    "Spectral Type": "F7V",
    "Color Index": 0.528,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -22.898993,
    "y": 8.728283,
    "z": -5.311883,
    "vx": 0.00001034,
    "vy": -0.00003887,
    "vz": -0.00007853,
    "rarad": 2.77742819466719,
    "decrad": -0.213455880194981,
    "pmrarad": 0.00000130167625092361,
    "pmdecrad": -0.000003259450855,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 51784,
    "lum": 2.84708215979928,
    "ra": 10.608994,
    "dec": -12.230121,
    "pmra": 268.49,
    "pmdec": -672.31,
    "rv": -6.2,
    "Asset Name": "ASTRID 003093",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Infatuated",
    "Hair": "Heartbreaker",
    "Outfit": "Spartan",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001034,
    "y_1": -0.00003887,
    "z_1": -0.00007853,
    "Distance in Parsecs_1": 25.0752,
    "HYG Star ID_1": 51784,
    "Hipparcos Catalogue_1": 51933,
    "Henry Draper Catalogue_1": 91889,
    "Harvard Revised Catalogue_1": 4158,
    "Gliese Catalog_1": "Gl 398.1"
  },
  {
    "Astrid #": "ASTRID 003094",
    "HYG Star ID": 14103,
    "Hipparcos Catalogue": 14137,
    "Distance in Parsecs": 25.0815,
    "mag": 9.73,
    "Absolute Visual Magnitude": 7.733,
    "Color Index": 0.788,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 13.171745,
    "y": 13.437374,
    "z": 16.583839,
    "vx": -0.00000636,
    "vy": -0.00000356,
    "vz": 0.00000793,
    "rarad": 0.795380214645594,
    "decrad": 0.722414641523081,
    "pmrarad": 8.17880679097222e-8,
    "pmdecrad": 4.21545495e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 14103,
    "lum": 0.0702748617656134,
    "ra": 3.038129,
    "dec": 41.39131,
    "pmra": 16.87,
    "pmdec": 86.95,
    "rv": 0,
    "Asset Name": "ASTRID 003094",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Pompadour",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00000636,
    "y_1": -0.00000356,
    "z_1": 0.00000793,
    "Distance in Parsecs_1": 25.0815,
    "HYG Star ID_1": 14103,
    "Hipparcos Catalogue_1": 14137
  },
  {
    "Astrid #": "ASTRID 003095",
    "HYG Star ID": 40054,
    "Hipparcos Catalogue": 40167,
    "Henry Draper Catalogue": 68255,
    "Harvard Revised Catalogue": 3210,
    "Distance in Parsecs": 25.0815,
    "mag": 4.67,
    "Absolute Visual Magnitude": 2.673,
    "Spectral Type": "G0V",
    "Color Index": 0.531,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.035978,
    "y": 20.033032,
    "z": 7.603791,
    "vx": -7e-8,
    "vy": -0.0000062,
    "vz": -0.0000209,
    "rarad": 2.14768038909566,
    "decrad": 0.308011702944521,
    "pmrarad": 1.37153790229166e-7,
    "pmdecrad": -7.31777769e-7,
    "bayer": "Zet-2",
    "flam": 16,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 40054,
    "lum": 7.42677043714697,
    "bf": "16Zet2Cnc",
    "ra": 8.203535,
    "dec": 17.647771,
    "pmra": 28.29,
    "pmdec": -150.94,
    "rv": -11,
    "Asset Name": "ASTRID 003095",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Knocked Out",
    "Hair": "Middle Part",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -7e-8,
    "y_1": -0.0000062,
    "z_1": -0.0000209,
    "Distance in Parsecs_1": 25.0815,
    "HYG Star ID_1": 40054,
    "Hipparcos Catalogue_1": 40167,
    "Henry Draper Catalogue_1": 68255,
    "Harvard Revised Catalogue_1": 3210
  },
  {
    "Astrid #": "ASTRID 003096",
    "HYG Star ID": 74697,
    "Hipparcos Catalogue": 74926,
    "Gliese Catalog": "Wo 9518",
    "Distance in Parsecs": 25.0878,
    "mag": 10.35,
    "Absolute Visual Magnitude": 8.353,
    "Color Index": 1.214,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -15.387736,
    "y": -18.121998,
    "z": -8.01302,
    "vx": 0.00005043,
    "vy": -0.00002474,
    "vz": -0.00004088,
    "rarad": 4.00840546151519,
    "decrad": -0.325095257495437,
    "pmrarad": 0.00000217080173605555,
    "pmdecrad": -0.00000171982805,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 74697,
    "lum": 0.0397008678112183,
    "ra": 15.310981,
    "dec": -18.626586,
    "pmra": 447.76,
    "pmdec": -354.74,
    "rv": 0,
    "Asset Name": "ASTRID 003096",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Neko",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00005043,
    "y_1": -0.00002474,
    "z_1": -0.00004088,
    "Distance in Parsecs_1": 25.0878,
    "HYG Star ID_1": 74697,
    "Hipparcos Catalogue_1": 74926,
    "Gliese Catalog_1": "Wo 9518"
  },
  {
    "Astrid #": "ASTRID 003097",
    "HYG Star ID": 117942,
    "Hipparcos Catalogue": 118310,
    "Henry Draper Catalogue": 224660,
    "Distance in Parsecs": 25.0878,
    "mag": 8.85,
    "Absolute Visual Magnitude": 6.853,
    "Spectral Type": "K5",
    "Color Index": 1.187,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.918283,
    "y": -0.022134,
    "z": 2.911423,
    "vx": 0.00000238,
    "vy": -0.00000741,
    "vz": -0.00002044,
    "rarad": 6.28229707882173,
    "decrad": 0.116311432606098,
    "pmrarad": -2.95106087354166e-7,
    "pmdecrad": -8.20159303e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 117942,
    "lum": 0.158052001528425,
    "ra": 23.996607,
    "dec": 6.664154,
    "pmra": -60.87,
    "pmdec": -169.17,
    "rv": 0,
    "Asset Name": "ASTRID 003097",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Ninja",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00000238,
    "y_1": -0.00000741,
    "z_1": -0.00002044,
    "Distance in Parsecs_1": 25.0878,
    "HYG Star ID_1": 117942,
    "Hipparcos Catalogue_1": 118310,
    "Henry Draper Catalogue_1": 224660
  },
  {
    "Astrid #": "ASTRID 003098",
    "HYG Star ID": 86475,
    "Hipparcos Catalogue": 86742,
    "Henry Draper Catalogue": 161096,
    "Harvard Revised Catalogue": 6603,
    "Proper Name": "Cebalrai",
    "Distance in Parsecs": 25.0941,
    "mag": 2.76,
    "Absolute Visual Magnitude": 0.762,
    "Spectral Type": "K2III",
    "Color Index": 1.168,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.802333,
    "y": -24.949291,
    "z": 1.998237,
    "vx": -0.00000394,
    "vy": 0.00001409,
    "vz": 0.00001828,
    "rarad": 4.64027438272991,
    "decrad": 0.079714471486085,
    "pmrarad": -1.97173723881944e-7,
    "pmdecrad": 7.69884124e-7,
    "bayer": "Bet",
    "flam": 60,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 86475,
    "lum": 43.1717844470922,
    "bf": "60Bet Oph",
    "ra": 17.724543,
    "dec": 4.567303,
    "pmra": -40.67,
    "pmdec": 158.8,
    "rv": -12,
    "Asset Name": "ASTRID 003098",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Arrogant",
    "Hair": "Pirate Hat",
    "Outfit": "Pirate",
    "Special": "Neko Tail",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Cebalrai",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000394,
    "y_1": 0.00001409,
    "z_1": 0.00001828,
    "Distance in Parsecs_1": 25.0941,
    "HYG Star ID_1": 86475,
    "Hipparcos Catalogue_1": 86742,
    "Henry Draper Catalogue_1": 161096,
    "Harvard Revised Catalogue_1": 6603
  },
  {
    "Astrid #": "ASTRID 003099",
    "HYG Star ID": 117910,
    "Hipparcos Catalogue": 118278,
    "Henry Draper Catalogue": 224619,
    "Distance in Parsecs": 25.0941,
    "mag": 7.47,
    "Absolute Visual Magnitude": 5.472,
    "Spectral Type": "G8V",
    "Color Index": 0.741,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 23.57566,
    "y": -0.054124,
    "z": -8.597041,
    "vx": 0.00000954,
    "vy": 0.0000616,
    "vz": -0.00004021,
    "rarad": 6.28088817030578,
    "decrad": -0.349671719710503,
    "pmrarad": 0.00000245592066159027,
    "pmdecrad": -0.000001377452629,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 117910,
    "lum": 0.563897280334949,
    "ra": 23.991226,
    "dec": -20.034714,
    "pmra": 506.57,
    "pmdec": -284.12,
    "rv": 22.1,
    "Asset Name": "ASTRID 003099",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Slick",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000954,
    "y_1": 0.0000616,
    "z_1": -0.00004021,
    "Distance in Parsecs_1": 25.0941,
    "HYG Star ID_1": 117910,
    "Hipparcos Catalogue_1": 118278,
    "Henry Draper Catalogue_1": 224619
  },
  {
    "Astrid #": "ASTRID 003100",
    "HYG Star ID": 60910,
    "Hipparcos Catalogue": 61100,
    "Henry Draper Catalogue": 109011,
    "Gliese Catalog": "GJ 1160",
    "Distance in Parsecs": 25.1004,
    "mag": 8.08,
    "Absolute Visual Magnitude": 6.082,
    "Spectral Type": "K2V",
    "Color Index": 0.941,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.220515,
    "y": -1.955254,
    "z": 20.590817,
    "vx": 0.00000324,
    "vy": -0.0000118,
    "vz": -0.00000512,
    "rarad": 3.27822806539334,
    "decrad": 0.962004754919145,
    "pmrarad": 4.83359239513888e-7,
    "pmdecrad": -6.4237812e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 60910,
    "lum": 0.321514082483269,
    "var": "NO",
    "var_min": 8.104,
    "var_max": 8.054,
    "ra": 12.521909,
    "dec": 55.118812,
    "pmra": 99.7,
    "pmdec": -13.25,
    "rv": -5,
    "Asset Name": "ASTRID 003100",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Knocked Out",
    "Hair": "Side Part",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000324,
    "y_1": -0.0000118,
    "z_1": -0.00000512,
    "Distance in Parsecs_1": 25.1004,
    "HYG Star ID_1": 60910,
    "Hipparcos Catalogue_1": 61100,
    "Henry Draper Catalogue_1": 109011,
    "Gliese Catalog_1": "GJ 1160"
  },
  {
    "Astrid #": "ASTRID 003101",
    "HYG Star ID": 66879,
    "Hipparcos Catalogue": 67092,
    "Gliese Catalog": "Gl 524.1",
    "Distance in Parsecs": 25.1004,
    "mag": 10.54,
    "Absolute Visual Magnitude": 8.542,
    "Spectral Type": "M2",
    "Color Index": 1.344,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -22.434446,
    "y": -11.074277,
    "z": -2.02191,
    "vx": -0.00001189,
    "vy": 0.00001583,
    "vz": -0.00001193,
    "rarad": 3.6001299702748,
    "decrad": -0.0806401521642825,
    "pmrarad": -7.75168593840277e-7,
    "pmdecrad": -4.62075918e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66879,
    "lum": 0.0333579996662048,
    "var_min": 10.587,
    "var_max": 10.477,
    "ra": 13.751484,
    "dec": -4.62034,
    "pmra": -159.89,
    "pmdec": -95.31,
    "rv": 4.5,
    "Asset Name": "ASTRID 003101",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Little Crown",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001189,
    "y_1": 0.00001583,
    "z_1": -0.00001193,
    "Distance in Parsecs_1": 25.1004,
    "HYG Star ID_1": 66879,
    "Hipparcos Catalogue_1": 67092,
    "Gliese Catalog_1": "Gl 524.1"
  },
  {
    "Astrid #": "ASTRID 003102",
    "HYG Star ID": 72873,
    "Hipparcos Catalogue": 73100,
    "Henry Draper Catalogue": 132254,
    "Harvard Revised Catalogue": 5581,
    "Gliese Catalog": "NN 3880",
    "Distance in Parsecs": 25.1067,
    "mag": 5.63,
    "Absolute Visual Magnitude": 3.631,
    "Spectral Type": "F7V",
    "Color Index": 0.533,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.679364,
    "y": -11.316511,
    "z": 19.127682,
    "vx": 0.00000191,
    "vy": -0.00001692,
    "vz": -0.00003032,
    "rarad": 3.91121048784442,
    "decrad": 0.866179835719933,
    "pmrarad": 5.371250766875e-7,
    "pmdecrad": -0.000001097860579,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72873,
    "lum": 3.07326492920429,
    "ra": 14.939724,
    "dec": 49.628449,
    "pmra": 110.79,
    "pmdec": -226.45,
    "rv": -16,
    "Asset Name": "ASTRID 003102",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Spiky",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000191,
    "y_1": -0.00001692,
    "z_1": -0.00003032,
    "Distance in Parsecs_1": 25.1067,
    "HYG Star ID_1": 72873,
    "Hipparcos Catalogue_1": 73100,
    "Henry Draper Catalogue_1": 132254,
    "Harvard Revised Catalogue_1": 5581,
    "Gliese Catalog_1": "NN 3880"
  },
  {
    "Astrid #": "ASTRID 003103",
    "HYG Star ID": 26308,
    "Hipparcos Catalogue": 26373,
    "Henry Draper Catalogue": 37572,
    "Distance in Parsecs": 25.113,
    "mag": 7.95,
    "Absolute Visual Magnitude": 5.951,
    "Spectral Type": "K0V",
    "Color Index": 0.845,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.688527,
    "y": 16.730384,
    "z": -18.652236,
    "vx": -0.00000307,
    "vy": 3.1e-7,
    "vz": 0,
    "rarad": 1.47021062068049,
    "decrad": -0.837141717696843,
    "pmrarad": 1.22706342548611e-7,
    "pmdecrad": -1.93925e-10,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 26308,
    "lum": 0.362743801476457,
    "var": "UY",
    "var_min": 8.054,
    "var_max": 7.884,
    "ra": 5.615791,
    "dec": -47.964687,
    "pmra": 25.31,
    "pmdec": -0.04,
    "rv": 0,
    "Asset Name": "ASTRID 003103",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Knocked Out",
    "Hair": "Braid",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": -0.00000307,
    "y_1": 3.1e-7,
    "z_1": 0,
    "Distance in Parsecs_1": 25.113,
    "HYG Star ID_1": 26308,
    "Hipparcos Catalogue_1": 26373,
    "Henry Draper Catalogue_1": 37572
  },
  {
    "Astrid #": "ASTRID 003104",
    "HYG Star ID": 95956,
    "Hipparcos Catalogue": 96258,
    "Henry Draper Catalogue": 184960,
    "Harvard Revised Catalogue": 7451,
    "Gliese Catalog": "NN 4116",
    "Distance in Parsecs": 25.113,
    "mag": 5.71,
    "Absolute Visual Magnitude": 3.711,
    "Spectral Type": "F7V",
    "Color Index": 0.475,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.290433,
    "y": -14.410257,
    "z": 19.58157,
    "vx": 0.00001079,
    "vy": -0.00001506,
    "vz": -0.00001402,
    "rarad": 5.12397972460037,
    "decrad": 0.894247710875992,
    "pmrarad": 1.53782899472222e-7,
    "pmdecrad": -9.12128458e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 95956,
    "lum": 2.8549598169004,
    "ra": 19.572161,
    "dec": 51.23662,
    "pmra": 31.72,
    "pmdec": -188.14,
    "rv": 0.4,
    "Asset Name": "ASTRID 003104",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Heartbreaker",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001079,
    "y_1": -0.00001506,
    "z_1": -0.00001402,
    "Distance in Parsecs_1": 25.113,
    "HYG Star ID_1": 95956,
    "Hipparcos Catalogue_1": 96258,
    "Henry Draper Catalogue_1": 184960,
    "Harvard Revised Catalogue_1": 7451,
    "Gliese Catalog_1": "NN 4116"
  },
  {
    "Astrid #": "ASTRID 003105",
    "HYG Star ID": 105334,
    "Hipparcos Catalogue": 105675,
    "Henry Draper Catalogue": 203413,
    "Distance in Parsecs": 25.113,
    "mag": 8.68,
    "Absolute Visual Magnitude": 6.681,
    "Spectral Type": "K3V",
    "Color Index": 1.063,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.150446,
    "y": -8.201193,
    "z": -21.456273,
    "vx": -0.00000939,
    "vy": -0.00000839,
    "vz": -0.00000124,
    "rarad": 5.6036111826442,
    "decrad": -1.02437400360703,
    "pmrarad": -4.94897805111111e-7,
    "pmdecrad": -9.4926518e-8,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 105334,
    "lum": 0.185182524363817,
    "ra": 21.404218,
    "dec": -58.692307,
    "pmra": -102.08,
    "pmdec": -19.58,
    "rv": 0,
    "Asset Name": "ASTRID 003105",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Elegant",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00000939,
    "y_1": -0.00000839,
    "z_1": -0.00000124,
    "Distance in Parsecs_1": 25.113,
    "HYG Star ID_1": 105334,
    "Hipparcos Catalogue_1": 105675,
    "Henry Draper Catalogue_1": 203413
  },
  {
    "Astrid #": "ASTRID 003106",
    "HYG Star ID": 42053,
    "Hipparcos Catalogue": 42172,
    "Henry Draper Catalogue": 72945,
    "Harvard Revised Catalogue": 3395,
    "Gliese Catalog": "Gl 310.1A",
    "Distance in Parsecs": 25.1256,
    "mag": 5.91,
    "Absolute Visual Magnitude": 3.909,
    "Spectral Type": "F8V",
    "Color Index": 0.53,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.694035,
    "y": 19.406549,
    "z": 2.8967,
    "vx": -0.00000539,
    "vy": 0.00003276,
    "vz": -0.00001271,
    "rarad": 2.25081842489527,
    "decrad": 0.115544630823191,
    "pmrarad": -6.53383397284722e-7,
    "pmdecrad": -6.35105921e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 42053,
    "base": "Gl 310.1",
    "lum": 2.37903044588062,
    "ra": 8.597493,
    "dec": 6.62022,
    "pmra": -134.77,
    "pmdec": -131,
    "rv": 26.6,
    "Asset Name": "ASTRID 003106",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Charmer",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00000539,
    "y_1": 0.00003276,
    "z_1": -0.00001271,
    "Distance in Parsecs_1": 25.1256,
    "HYG Star ID_1": 42053,
    "Hipparcos Catalogue_1": 42172,
    "Henry Draper Catalogue_1": 72945,
    "Harvard Revised Catalogue_1": 3395,
    "Gliese Catalog_1": "Gl 310.1A"
  },
  {
    "Astrid #": "ASTRID 003107",
    "HYG Star ID": 30031,
    "Hipparcos Catalogue": 30104,
    "Henry Draper Catalogue": 44594,
    "Harvard Revised Catalogue": 2290,
    "Distance in Parsecs": 25.1319,
    "mag": 6.61,
    "Absolute Visual Magnitude": 4.609,
    "Spectral Type": "G4V",
    "Color Index": 0.657,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.451878,
    "y": 16.510189,
    "z": -18.89299,
    "vx": -0.00002993,
    "vy": 0.00001342,
    "vz": -0.0000676,
    "rarad": 1.65850383652657,
    "decrad": -0.850692551489117,
    "pmrarad": 0.00000113960303751388,
    "pmdecrad": -0.000001295713042,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 30031,
    "lum": 1.24853292615949,
    "ra": 6.335018,
    "dec": -48.741093,
    "pmra": 235.06,
    "pmdec": -267.26,
    "rv": 60,
    "Asset Name": "ASTRID 003107",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Serious Mad",
    "Hair": "Pompadour",
    "Outfit": "Astro",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002993,
    "y_1": 0.00001342,
    "z_1": -0.0000676,
    "Distance in Parsecs_1": 25.1319,
    "HYG Star ID_1": 30031,
    "Hipparcos Catalogue_1": 30104,
    "Henry Draper Catalogue_1": 44594,
    "Harvard Revised Catalogue_1": 2290
  },
  {
    "Astrid #": "ASTRID 003108",
    "HYG Star ID": 29476,
    "Hipparcos Catalogue": 29548,
    "Gliese Catalog": "NN 3386",
    "Distance in Parsecs": 25.1383,
    "mag": 9.67,
    "Absolute Visual Magnitude": 7.668,
    "Spectral Type": "M0",
    "Color Index": 1.21,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.6671,
    "y": 11.238489,
    "z": 22.476511,
    "vx": -0.00002254,
    "vy": 0.00002919,
    "vz": 0.00000521,
    "rarad": 1.63007634099618,
    "decrad": 1.10643653783468,
    "pmrarad": 8.26170993034722e-7,
    "pmdecrad": -9.91104607e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 29476,
    "lum": 0.0746105085193062,
    "ra": 6.226433,
    "dec": 63.394144,
    "pmra": 170.41,
    "pmdec": -204.43,
    "rv": 17.9,
    "Asset Name": "ASTRID 003108",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Bob",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00002254,
    "y_1": 0.00002919,
    "z_1": 0.00000521,
    "Distance in Parsecs_1": 25.1383,
    "HYG Star ID_1": 29476,
    "Hipparcos Catalogue_1": 29548,
    "Gliese Catalog_1": "NN 3386"
  },
  {
    "Astrid #": "ASTRID 003109",
    "HYG Star ID": 118166,
    "Gliese Catalog": "Gl 106.1B",
    "Distance in Parsecs": 25.1383,
    "mag": 6.3,
    "Absolute Visual Magnitude": 4.298,
    "Spectral Type": "dF3",
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 18.992203,
    "y": 16.406796,
    "z": 1.419598,
    "vx": 0.00000225,
    "vy": -0.00002063,
    "vz": -0.00001808,
    "rarad": 0.712491516409129,
    "decrad": 0.05650303566425,
    "pmrarad": -6.79611817402777e-7,
    "pmdecrad": -6.91586715e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 12673,
    "base": "Gl 106.1",
    "lum": 1.66264679689353,
    "ra": 2.721517,
    "dec": 3.237385,
    "pmra": -140.18,
    "pmdec": -142.65,
    "rv": -12.5,
    "Asset Name": "ASTRID 003109",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Oh Snap",
    "Hair": "Shinobi",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000225,
    "y_1": -0.00002063,
    "z_1": -0.00001808,
    "Distance in Parsecs_1": 25.1383,
    "HYG Star ID_1": 118166,
    "Gliese Catalog_1": "Gl 106.1B"
  },
  {
    "Astrid #": "ASTRID 003110",
    "HYG Star ID": 118167,
    "Gliese Catalog": "Gl 106.1C",
    "Distance in Parsecs": 25.1383,
    "mag": 10.16,
    "Absolute Visual Magnitude": 8.158,
    "Spectral Type": "K5",
    "Color Index": 1.36,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.047045,
    "y": 16.338686,
    "z": 1.480391,
    "vx": 0.00001159,
    "vy": -0.00001173,
    "vz": -0.00001957,
    "rarad": 0.70900898850063,
    "decrad": 0.05892391025405,
    "pmrarad": -6.54449987381944e-7,
    "pmdecrad": -7.79914919e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 3,
    "comp_primary": 12673,
    "base": "Gl 106.1",
    "lum": 0.0475116376377561,
    "ra": 2.708215,
    "dec": 3.376091,
    "pmra": -134.99,
    "pmdec": -160.87,
    "rv": 0,
    "Asset Name": "ASTRID 003110",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Antlers",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00001159,
    "y_1": -0.00001173,
    "z_1": -0.00001957,
    "Distance in Parsecs_1": 25.1383,
    "HYG Star ID_1": 118167,
    "Gliese Catalog_1": "Gl 106.1C"
  },
  {
    "Astrid #": "ASTRID 003111",
    "HYG Star ID": 6612,
    "Hipparcos Catalogue": 6626,
    "Gliese Catalog": "GJ 1038",
    "Distance in Parsecs": 25.1446,
    "mag": 9.8,
    "Absolute Visual Magnitude": 7.798,
    "Color Index": 1.42,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.686306,
    "y": 7.658571,
    "z": -13.639889,
    "vx": -0.00000117,
    "vy": 0.00002997,
    "vz": 0.00001514,
    "rarad": 0.371012346291493,
    "decrad": -0.573360220026022,
    "pmrarad": 0.0000011279190278125,
    "pmdecrad": 7.16797026e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 6612,
    "lum": 0.066191161194112,
    "ra": 1.417163,
    "dec": -32.851121,
    "pmra": 232.65,
    "pmdec": 147.85,
    "rv": 0,
    "Asset Name": "ASTRID 003111",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Fire",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00000117,
    "y_1": 0.00002997,
    "z_1": 0.00001514,
    "Distance in Parsecs_1": 25.1446,
    "HYG Star ID_1": 6612,
    "Hipparcos Catalogue_1": 6626,
    "Gliese Catalog_1": "GJ 1038"
  },
  {
    "Astrid #": "ASTRID 003112",
    "HYG Star ID": 111891,
    "Hipparcos Catalogue": 112245,
    "Henry Draper Catalogue": 215500,
    "Distance in Parsecs": 25.1446,
    "mag": 7.5,
    "Absolute Visual Magnitude": 5.498,
    "Spectral Type": "G8V",
    "Color Index": 0.719,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.210121,
    "y": -3.510789,
    "z": 22.708127,
    "vx": 0.00001482,
    "vy": 0.00000142,
    "vz": -0.00005604,
    "rarad": 5.95199359096729,
    "decrad": 1.12697078669489,
    "pmrarad": 2.45024834152777e-7,
    "pmdecrad": -0.000001443920584,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 111891,
    "lum": 0.550554098484794,
    "ra": 22.734941,
    "dec": 64.57067,
    "pmra": 50.54,
    "pmdec": -297.83,
    "rv": -43.8,
    "Asset Name": "ASTRID 003112",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "High Fade",
    "Outfit": "Football",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001482,
    "y_1": 0.00000142,
    "z_1": -0.00005604,
    "Distance in Parsecs_1": 25.1446,
    "HYG Star ID_1": 111891,
    "Hipparcos Catalogue_1": 112245,
    "Henry Draper Catalogue_1": 215500
  },
  {
    "Astrid #": "ASTRID 003113",
    "HYG Star ID": 36531,
    "Hipparcos Catalogue": 36635,
    "Gliese Catalog": "Gl 275.1",
    "Distance in Parsecs": 25.1572,
    "mag": 10.84,
    "Absolute Visual Magnitude": 8.837,
    "Spectral Type": "M0",
    "Color Index": 1.494,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.58452,
    "y": 8.441158,
    "z": 23.426152,
    "vx": 0.0000138,
    "vy": 0.00002195,
    "vz": -0.00000141,
    "rarad": 1.97238161065269,
    "decrad": 1.19766218799358,
    "pmrarad": -8.46096835305555e-7,
    "pmdecrad": -5.68686447e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 36531,
    "lum": 0.0254214313620493,
    "var_min": 10.916,
    "var_max": 10.776,
    "ra": 7.533943,
    "dec": 68.620989,
    "pmra": -174.52,
    "pmdec": -117.3,
    "rv": 4,
    "Asset Name": "ASTRID 003113",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Nervous Grin",
    "Hair": "Tiny Crown",
    "Outfit": "Toga",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.0000138,
    "y_1": 0.00002195,
    "z_1": -0.00000141,
    "Distance in Parsecs_1": 25.1572,
    "HYG Star ID_1": 36531,
    "Hipparcos Catalogue_1": 36635,
    "Gliese Catalog_1": "Gl 275.1"
  },
  {
    "Astrid #": "ASTRID 003114",
    "HYG Star ID": 22734,
    "Hipparcos Catalogue": 22787,
    "Henry Draper Catalogue": 31392,
    "Gliese Catalog": "NN 3319",
    "Distance in Parsecs": 25.1636,
    "mag": 7.6,
    "Absolute Visual Magnitude": 5.596,
    "Spectral Type": "K0V",
    "Color Index": 0.792,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.819249,
    "y": 19.667559,
    "z": -14.578417,
    "vx": -0.00001156,
    "vy": 0.00001505,
    "vz": 0.00001568,
    "rarad": 1.2831218738287,
    "decrad": -0.617925395056166,
    "pmrarad": 6.10380423819444e-7,
    "pmdecrad": 7.64793581e-7,
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 22734,
    "lum": 0.503037081084624,
    "ra": 4.901165,
    "dec": -35.404517,
    "pmra": 125.9,
    "pmdec": 157.75,
    "rv": 0,
    "Asset Name": "ASTRID 003114",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Unkempt",
    "Outfit": "Samurai",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": -0.00001156,
    "y_1": 0.00001505,
    "z_1": 0.00001568,
    "Distance in Parsecs_1": 25.1636,
    "HYG Star ID_1": 22734,
    "Hipparcos Catalogue_1": 22787,
    "Henry Draper Catalogue_1": 31392,
    "Gliese Catalog_1": "NN 3319"
  },
  {
    "Astrid #": "ASTRID 003115",
    "HYG Star ID": 34476,
    "Hipparcos Catalogue": 34567,
    "Henry Draper Catalogue": 54371,
    "Distance in Parsecs": 25.1699,
    "mag": 7.09,
    "Absolute Visual Magnitude": 5.086,
    "Spectral Type": "G8V",
    "Color Index": 0.7,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.779706,
    "y": 21.637431,
    "z": 10.926572,
    "vx": 0.0000061,
    "vy": 0.00003075,
    "vz": -0.00001045,
    "rarad": 1.87443982437485,
    "decrad": 0.449049632169463,
    "pmrarad": -5.96757159395833e-7,
    "pmdecrad": -8.48666347e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 34476,
    "lum": 0.804637001739799,
    "ra": 7.159833,
    "dec": 25.728649,
    "pmra": -123.09,
    "pmdec": -175.05,
    "rv": 19.8,
    "Asset Name": "ASTRID 003115",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "High Bun",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.0000061,
    "y_1": 0.00003075,
    "z_1": -0.00001045,
    "Distance in Parsecs_1": 25.1699,
    "HYG Star ID_1": 34476,
    "Hipparcos Catalogue_1": 34567,
    "Henry Draper Catalogue_1": 54371
  },
  {
    "Astrid #": "ASTRID 003116",
    "HYG Star ID": 71974,
    "Hipparcos Catalogue": 72200,
    "Henry Draper Catalogue": 130215,
    "Distance in Parsecs": 25.1699,
    "mag": 7.98,
    "Absolute Visual Magnitude": 5.976,
    "Spectral Type": "K2V",
    "Color Index": 0.87,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.71587,
    "y": -14.795613,
    "z": 11.626897,
    "vx": 0.00001275,
    "vy": 0.00000828,
    "vz": -0.00001364,
    "rarad": 3.86612763482266,
    "decrad": 0.480180998977068,
    "pmrarad": 8.93511613263888e-8,
    "pmdecrad": -2.04688335e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 71974,
    "lum": 0.354486744473799,
    "var": "HO",
    "var_min": 8.007,
    "var_max": 7.957,
    "ra": 14.76752,
    "dec": 27.512345,
    "pmra": 18.43,
    "pmdec": -42.22,
    "rv": -19.2,
    "Asset Name": "ASTRID 003116",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Neko",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001275,
    "y_1": 0.00000828,
    "z_1": -0.00001364,
    "Distance in Parsecs_1": 25.1699,
    "HYG Star ID_1": 71974,
    "Hipparcos Catalogue_1": 72200,
    "Henry Draper Catalogue_1": 130215
  },
  {
    "Astrid #": "ASTRID 003117",
    "HYG Star ID": 63204,
    "Hipparcos Catalogue": 63406,
    "Henry Draper Catalogue": 112914,
    "Distance in Parsecs": 25.1826,
    "mag": 8.6,
    "Absolute Visual Magnitude": 6.594,
    "Spectral Type": "G9V",
    "Color Index": 0.94,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -18.090098,
    "y": -4.808869,
    "z": 16.846256,
    "vx": -0.00000914,
    "vy": 0.00002736,
    "vz": 0.00003148,
    "rarad": 3.40141609811225,
    "decrad": 0.73280730105256,
    "pmrarad": -0.00000114353002832638,
    "pmdecrad": 8.81148864e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 63204,
    "lum": 0.200631906479057,
    "ra": 12.992452,
    "dec": 41.986766,
    "pmra": -235.87,
    "pmdec": 181.75,
    "rv": 21.9,
    "Asset Name": "ASTRID 003117",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Round Brush",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000914,
    "y_1": 0.00002736,
    "z_1": 0.00003148,
    "Distance in Parsecs_1": 25.1826,
    "HYG Star ID_1": 63204,
    "Hipparcos Catalogue_1": 63406,
    "Henry Draper Catalogue_1": 112914
  },
  {
    "Astrid #": "ASTRID 003118",
    "HYG Star ID": 118489,
    "Gliese Catalog": "Wo 9238",
    "Distance in Parsecs": 25.1889,
    "mag": 13.85,
    "Absolute Visual Magnitude": 11.844,
    "Spectral Type": "M3",
    "Color Index": 1.47,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.194744,
    "y": 20.186953,
    "z": 11.934164,
    "vx": -0.00005068,
    "vy": -0.00000303,
    "vz": -0.00003391,
    "rarad": 1.99818492570216,
    "decrad": 0.49358567815425,
    "pmrarad": 0.00000188117404329166,
    "pmdecrad": -0.000001528796915,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118489,
    "lum": 0.00159367588102876,
    "ra": 7.632504,
    "dec": 28.280376,
    "pmra": 388.02,
    "pmdec": -315.34,
    "rv": 0,
    "Asset Name": "ASTRID 003118",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Round Brush",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005068,
    "y_1": -0.00000303,
    "z_1": -0.00003391,
    "Distance in Parsecs_1": 25.1889,
    "HYG Star ID_1": 118489,
    "Gliese Catalog_1": "Wo 9238"
  },
  {
    "Astrid #": "ASTRID 003119",
    "HYG Star ID": 118848,
    "Gliese Catalog": "NN 3751",
    "Distance in Parsecs": 25.1889,
    "mag": 13.07,
    "Absolute Visual Magnitude": 11.064,
    "Spectral Type": "M3.5",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -24.26652,
    "y": -5.331391,
    "z": 4.146439,
    "vx": -0.00001028,
    "vy": 0.00005118,
    "vz": 0.00000568,
    "rarad": 3.35785967231646,
    "decrad": 0.165366397966749,
    "pmrarad": -0.00000207209367069444,
    "pmdecrad": 2.28759335e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118848,
    "lum": 0.00326888769247271,
    "ra": 12.826079,
    "dec": 9.474797,
    "pmra": -427.4,
    "pmdec": 47.19,
    "rv": 0,
    "Asset Name": "ASTRID 003119",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Bob",
    "Outfit": "Viking",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001028,
    "y_1": 0.00005118,
    "z_1": 0.00000568,
    "Distance in Parsecs_1": 25.1889,
    "HYG Star ID_1": 118848,
    "Gliese Catalog_1": "NN 3751"
  },
  {
    "Astrid #": "ASTRID 003120",
    "HYG Star ID": 118946,
    "Gliese Catalog": "NN 3828A",
    "Distance in Parsecs": 25.1889,
    "mag": 12.97,
    "Absolute Visual Magnitude": 10.964,
    "Spectral Type": "M3.5",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.111636,
    "y": -13.733175,
    "z": -0.257092,
    "vx": -0.00000821,
    "vy": 0.00009453,
    "vz": 0.00003109,
    "rarad": 3.71832111541471,
    "decrad": -0.0102076671536,
    "pmrarad": -0.00000332359170568055,
    "pmdecrad": 0.000001216256928,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118946,
    "base": "NN 3828",
    "lum": 0.00358426408043713,
    "ra": 14.20294,
    "dec": -0.584856,
    "pmra": -685.54,
    "pmdec": 250.87,
    "rv": -44,
    "Asset Name": "ASTRID 003120",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Goddess",
    "Outfit": "Super",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000821,
    "y_1": 0.00009453,
    "z_1": 0.00003109,
    "Distance in Parsecs_1": 25.1889,
    "HYG Star ID_1": 118946,
    "Gliese Catalog_1": "NN 3828A"
  },
  {
    "Astrid #": "ASTRID 003121",
    "HYG Star ID": 118947,
    "Gliese Catalog": "NN 3829B",
    "Distance in Parsecs": 25.1889,
    "mag": 21,
    "Absolute Visual Magnitude": 18.994,
    "Spectral Type": "m+",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -21.112509,
    "y": -13.735931,
    "z": -0.258576,
    "vx": -0.00004593,
    "vy": 0.00006999,
    "vz": 0.00003063,
    "rarad": 3.71839399141471,
    "decrad": -0.0102656551536,
    "pmrarad": -0.00000332359170568055,
    "pmdecrad": 0.000001216256928,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118947,
    "base": "NN 3829",
    "lum": 0.00000219988510904924,
    "ra": 14.203219,
    "dec": -0.588179,
    "pmra": -685.54,
    "pmdec": 250.87,
    "rv": 0,
    "Asset Name": "ASTRID 003121",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Manbun",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004593,
    "y_1": 0.00006999,
    "z_1": 0.00003063,
    "Distance in Parsecs_1": 25.1889,
    "HYG Star ID_1": 118947,
    "Gliese Catalog_1": "NN 3829B"
  },
  {
    "Astrid #": "ASTRID 003122",
    "HYG Star ID": 119476,
    "Gliese Catalog": "NN 4259",
    "Distance in Parsecs": 25.1889,
    "mag": 15.66,
    "Absolute Visual Magnitude": 13.654,
    "Spectral Type": "DA6",
    "Color Index": 0.23,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 21.621362,
    "y": -11.28034,
    "z": 6.304865,
    "vx": 0.00000764,
    "vy": 0.00003069,
    "vz": 0.00002874,
    "rarad": 5.80230948877017,
    "decrad": 0.25299354437505,
    "pmrarad": 0.00000122061540353472,
    "pmdecrad": 0.000001178727501,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119476,
    "lum": 0.000300884627664572,
    "ra": 22.16319,
    "dec": 14.495462,
    "pmra": 251.77,
    "pmdec": 243.13,
    "rv": 0,
    "Asset Name": "ASTRID 003122",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "High Fade",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000764,
    "y_1": 0.00003069,
    "z_1": 0.00002874,
    "Distance in Parsecs_1": 25.1889,
    "HYG Star ID_1": 119476,
    "Gliese Catalog_1": "NN 4259"
  },
  {
    "Astrid #": "ASTRID 003123",
    "HYG Star ID": 119586,
    "Gliese Catalog": "Wo 9836B",
    "Distance in Parsecs": 25.1889,
    "mag": 11,
    "Absolute Visual Magnitude": 8.994,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 24.312365,
    "y": -1.836058,
    "z": -6.326016,
    "vx": -9.6e-7,
    "vy": 0.00001142,
    "vz": -0.000007,
    "rarad": 6.20780812484188,
    "decrad": -0.25386093199895,
    "pmrarad": 4.49276837770833e-7,
    "pmdecrad": -2.87319979e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 2,
    "comp_primary": 116605,
    "base": "Wo 9836",
    "lum": 0.0219988510904924,
    "ra": 23.71208,
    "dec": -14.54516,
    "pmra": 92.67,
    "pmdec": -59.26,
    "rv": 0,
    "Asset Name": "ASTRID 003123",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Angelic",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -9.6e-7,
    "y_1": 0.00001142,
    "z_1": -0.000007,
    "Distance in Parsecs_1": 25.1889,
    "HYG Star ID_1": 119586,
    "Gliese Catalog_1": "Wo 9836B"
  },
  {
    "Astrid #": "ASTRID 003124",
    "HYG Star ID": 109720,
    "Hipparcos Catalogue": 110066,
    "Henry Draper Catalogue": 211369,
    "Gliese Catalog": "NN 4270",
    "Distance in Parsecs": 25.2016,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.393,
    "Spectral Type": "K3V",
    "Color Index": 0.961,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.016808,
    "y": -7.19025,
    "z": -18.918679,
    "vx": 0.00000208,
    "vy": 0.00002667,
    "vz": -0.00000848,
    "rarad": 5.83662571268107,
    "decrad": -0.849111278892016,
    "pmrarad": 9.90134979798611e-7,
    "pmdecrad": -5.09587659e-7,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 109720,
    "lum": 0.241434872971415,
    "ra": 22.294268,
    "dec": -48.650493,
    "pmra": 204.23,
    "pmdec": -105.11,
    "rv": 0,
    "Asset Name": "ASTRID 003124",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Windy",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00000208,
    "y_1": 0.00002667,
    "z_1": -0.00000848,
    "Distance in Parsecs_1": 25.2016,
    "HYG Star ID_1": 109720,
    "Hipparcos Catalogue_1": 110066,
    "Henry Draper Catalogue_1": 211369,
    "Gliese Catalog_1": "NN 4270"
  },
  {
    "Astrid #": "ASTRID 003125",
    "HYG Star ID": 51109,
    "Hipparcos Catalogue": 51254,
    "Henry Draper Catalogue": 90663,
    "Distance in Parsecs": 25.208,
    "mag": 8.53,
    "Absolute Visual Magnitude": 6.522,
    "Spectral Type": "K0",
    "Color Index": 0.92,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.051561,
    "y": 9.764152,
    "z": 2.956407,
    "vx": -0.00001806,
    "vy": 0.00000421,
    "vz": 0.00004235,
    "rarad": 2.74092642198163,
    "decrad": 0.117550088187167,
    "pmrarad": 1.25712187368055e-7,
    "pmdecrad": 0.000001583449962,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 51109,
    "lum": 0.21438776659735,
    "ra": 10.469568,
    "dec": 6.735124,
    "pmra": 25.93,
    "pmdec": 326.61,
    "rv": 22.6,
    "Asset Name": "ASTRID 003125",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Sweet Nap",
    "Hair": "Frizzy",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00001806,
    "y_1": 0.00000421,
    "z_1": 0.00004235,
    "Distance in Parsecs_1": 25.208,
    "HYG Star ID_1": 51109,
    "Hipparcos Catalogue_1": 51254,
    "Henry Draper Catalogue_1": 90663
  },
  {
    "Astrid #": "ASTRID 003126",
    "HYG Star ID": 6692,
    "Hipparcos Catalogue": 6706,
    "Henry Draper Catalogue": 8723,
    "Harvard Revised Catalogue": 413,
    "Distance in Parsecs": 25.2143,
    "mag": 5.35,
    "Absolute Visual Magnitude": 3.342,
    "Spectral Type": "F2V:var",
    "Color Index": 0.395,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.148856,
    "y": 8.753076,
    "z": 8.280622,
    "vx": -0.00000726,
    "vy": -0.00000639,
    "vz": -0.00000185,
    "rarad": 0.376355743111919,
    "decrad": 0.334620555999353,
    "pmrarad": -1.29930066388888e-7,
    "pmdecrad": 4.9257069e-8,
    "bayer": "Rho",
    "flam": 93,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 6692,
    "lum": 4.01051366086575,
    "bf": "93Rho Psc",
    "ra": 1.437573,
    "dec": 19.172346,
    "pmra": -26.8,
    "pmdec": 10.16,
    "rv": -9,
    "Asset Name": "ASTRID 003126",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Big Laugh",
    "Hair": "Tiny Crown",
    "Outfit": "Elven",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000726,
    "y_1": -0.00000639,
    "z_1": -0.00000185,
    "Distance in Parsecs_1": 25.2143,
    "HYG Star ID_1": 6692,
    "Hipparcos Catalogue_1": 6706,
    "Henry Draper Catalogue_1": 8723,
    "Harvard Revised Catalogue_1": 413
  },
  {
    "Astrid #": "ASTRID 003127",
    "HYG Star ID": 71678,
    "Hipparcos Catalogue": 71904,
    "Gliese Catalog": "Wo 9490A",
    "Distance in Parsecs": 25.2143,
    "mag": 10.08,
    "Absolute Visual Magnitude": 8.072,
    "Spectral Type": "M0",
    "Color Index": 1.34,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.043278,
    "y": -15.4701,
    "z": 8.418083,
    "vx": -0.00000524,
    "vy": 0.00003751,
    "vz": -0.00003023,
    "rarad": 3.85036120458232,
    "decrad": 0.340400747145977,
    "pmrarad": -0.00000126473344846527,
    "pmdecrad": -8.59526174e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 71678,
    "base": "Wo 9490",
    "lum": 0.0514280431948792,
    "ra": 14.707296,
    "dec": 19.503526,
    "pmra": -260.87,
    "pmdec": -177.29,
    "rv": -28.7,
    "Asset Name": "ASTRID 003127",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Neko",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000524,
    "y_1": 0.00003751,
    "z_1": -0.00003023,
    "Distance in Parsecs_1": 25.2143,
    "HYG Star ID_1": 71678,
    "Hipparcos Catalogue_1": 71904,
    "Gliese Catalog_1": "Wo 9490A"
  },
  {
    "Astrid #": "ASTRID 003128",
    "HYG Star ID": 15403,
    "Hipparcos Catalogue": 15442,
    "Henry Draper Catalogue": 20619,
    "Gliese Catalog": "Gl 135",
    "Distance in Parsecs": 25.2207,
    "mag": 7.05,
    "Absolute Visual Magnitude": 5.041,
    "Spectral Type": "G0",
    "Color Index": 0.655,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.273122,
    "y": 19.227956,
    "z": -1.251026,
    "vx": -0.00000903,
    "vy": 0.00003694,
    "vz": -0.00001357,
    "rarad": 0.868438966627905,
    "decrad": -0.0496231093262787,
    "pmrarad": 0.00000121950033206944,
    "pmdecrad": -4.93637289e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 15403,
    "lum": 0.838687171248163,
    "ra": 3.317193,
    "dec": -2.843195,
    "pmra": 251.54,
    "pmdec": -101.82,
    "rv": 22.5,
    "Asset Name": "ASTRID 003128",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Medium Bob",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000903,
    "y_1": 0.00003694,
    "z_1": -0.00001357,
    "Distance in Parsecs_1": 25.2207,
    "HYG Star ID_1": 15403,
    "Hipparcos Catalogue_1": 15442,
    "Henry Draper Catalogue_1": 20619,
    "Gliese Catalog_1": "Gl 135"
  },
  {
    "Astrid #": "ASTRID 003129",
    "HYG Star ID": 83964,
    "Hipparcos Catalogue": 84223,
    "Gliese Catalog": "GJ 1213",
    "Distance in Parsecs": 25.227,
    "mag": 10.07,
    "Absolute Visual Magnitude": 8.061,
    "Spectral Type": "M1V:",
    "Color Index": 1.28,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.804297,
    "y": -18.257189,
    "z": 16.988489,
    "vx": -0.00012914,
    "vy": -0.00001115,
    "vz": -0.00002465,
    "rarad": 4.50697121057654,
    "decrad": 0.738828463533071,
    "pmrarad": -0.00000492187696636805,
    "pmdecrad": -0.000001716967649,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83964,
    "lum": 0.0519517283011067,
    "var_min": 10.132,
    "var_max": 10.022,
    "ra": 17.215362,
    "dec": 42.331753,
    "pmra": -1015.21,
    "pmdec": -354.15,
    "rv": 10.7,
    "Asset Name": "ASTRID 003129",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Upset",
    "Hair": "Little Crown",
    "Outfit": "Astro",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00012914,
    "y_1": -0.00001115,
    "z_1": -0.00002465,
    "Distance in Parsecs_1": 25.227,
    "HYG Star ID_1": 83964,
    "Hipparcos Catalogue_1": 84223,
    "Gliese Catalog_1": "GJ 1213"
  },
  {
    "Astrid #": "ASTRID 003130",
    "HYG Star ID": 118910,
    "Gliese Catalog": "Gl 521.2B",
    "Distance in Parsecs": 25.227,
    "mag": 10.46,
    "Absolute Visual Magnitude": 8.451,
    "Color Index": 1.36,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.52532,
    "y": -6.805249,
    "z": 19.469722,
    "vx": 0.00000559,
    "vy": 0.0000214,
    "vz": -0.00000637,
    "rarad": 3.57975126505665,
    "decrad": 0.88167090784035,
    "pmrarad": -6.74278866916666e-7,
    "pmdecrad": 2.72416807e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 66493,
    "base": "Gl 521.2",
    "lum": 0.0362743801476457,
    "ra": 13.673643,
    "dec": 50.516022,
    "pmra": -139.08,
    "pmdec": 56.19,
    "rv": -13.6,
    "Asset Name": "ASTRID 003130",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Middle Part",
    "Outfit": "Wizard",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000559,
    "y_1": 0.0000214,
    "z_1": -0.00000637,
    "Distance in Parsecs_1": 25.227,
    "HYG Star ID_1": 118910,
    "Gliese Catalog_1": "Gl 521.2B"
  },
  {
    "Astrid #": "ASTRID 003131",
    "HYG Star ID": 51779,
    "Hipparcos Catalogue": 51928,
    "Distance in Parsecs": 25.2398,
    "mag": 11.98,
    "Absolute Visual Magnitude": 9.97,
    "Color Index": 1.464,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -20.733267,
    "y": 7.909624,
    "z": -12.02568,
    "vx": 0.00001004,
    "vy": 0.00002524,
    "vz": -7.1e-7,
    "rarad": 2.77714179449425,
    "decrad": -0.49662051140411,
    "pmrarad": -0.00000107628637083333,
    "pmdecrad": -3.2046184e-8,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 51779,
    "lum": 0.00895364765549592,
    "var_min": 12.089,
    "var_max": 11.879,
    "ra": 10.6079,
    "dec": -28.454259,
    "pmra": -222,
    "pmdec": -6.61,
    "rv": 0,
    "Asset Name": "ASTRID 003131",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Awkward",
    "Hair": "Afro",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001004,
    "y_1": 0.00002524,
    "z_1": -7.1e-7,
    "Distance in Parsecs_1": 25.2398,
    "HYG Star ID_1": 51779,
    "Hipparcos Catalogue_1": 51928
  },
  {
    "Astrid #": "ASTRID 003132",
    "HYG Star ID": 55539,
    "Hipparcos Catalogue": 55705,
    "Henry Draper Catalogue": 99211,
    "Harvard Revised Catalogue": 4405,
    "Distance in Parsecs": 25.2398,
    "mag": 4.06,
    "Absolute Visual Magnitude": 2.05,
    "Spectral Type": "A9V",
    "Color Index": 0.216,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -23.765377,
    "y": 3.670362,
    "z": -7.667025,
    "vx": 7.3e-7,
    "vy": 0.0000119,
    "vz": 6e-8,
    "rarad": 2.98836194771967,
    "decrad": -0.30864419240375,
    "pmrarad": -4.70317751506944e-7,
    "pmdecrad": 1.5611e-8,
    "bayer": "Gam",
    "flam": 15,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 55539,
    "lum": 13.182567385564,
    "bf": "15Gam Crt",
    "ra": 11.414702,
    "dec": -17.68401,
    "pmra": -97.01,
    "pmdec": 3.22,
    "rv": 1,
    "Asset Name": "ASTRID 003132",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Shinobi",
    "Outfit": "Toga",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 7.3e-7,
    "y_1": 0.0000119,
    "z_1": 6e-8,
    "Distance in Parsecs_1": 25.2398,
    "HYG Star ID_1": 55539,
    "Hipparcos Catalogue_1": 55705,
    "Henry Draper Catalogue_1": 99211,
    "Harvard Revised Catalogue_1": 4405
  },
  {
    "Astrid #": "ASTRID 003133",
    "HYG Star ID": 105338,
    "Hipparcos Catalogue": 105679,
    "Gliese Catalog": "Gl 826.2",
    "Distance in Parsecs": 25.2398,
    "mag": 12.45,
    "Absolute Visual Magnitude": 10.44,
    "Spectral Type": "M5",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.468588,
    "y": -10.876911,
    "z": -18.366749,
    "vx": 0.00005309,
    "vy": 0.00007302,
    "vz": -0.00000431,
    "rarad": 5.60382746430116,
    "decrad": -0.814948001873738,
    "pmrarad": 0.00000357283441885416,
    "pmdecrad": -2.49242713e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 105338,
    "lum": 0.00580764417521311,
    "var_min": 12.642,
    "var_max": 12.242,
    "ra": 21.405044,
    "dec": -46.693081,
    "pmra": 736.95,
    "pmdec": -51.41,
    "rv": 0,
    "Asset Name": "ASTRID 003133",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shocked",
    "Hair": "Braided Bun",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005309,
    "y_1": 0.00007302,
    "z_1": -0.00000431,
    "Distance in Parsecs_1": 25.2398,
    "HYG Star ID_1": 105338,
    "Hipparcos Catalogue_1": 105679,
    "Gliese Catalog_1": "Gl 826.2"
  },
  {
    "Astrid #": "ASTRID 003134",
    "HYG Star ID": 32679,
    "Hipparcos Catalogue": 32765,
    "Henry Draper Catalogue": 50223,
    "Harvard Revised Catalogue": 2548,
    "Gliese Catalog": "Gl 249.1",
    "Distance in Parsecs": 25.2589,
    "mag": 5.14,
    "Absolute Visual Magnitude": 3.128,
    "Spectral Type": "F5III",
    "Color Index": 0.46,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.748711,
    "y": 16.940726,
    "z": -18.357017,
    "vx": -0.00001018,
    "vy": 0.00004633,
    "vz": 0.00001655,
    "rarad": 1.78857099524412,
    "decrad": -0.813577552352251,
    "pmrarad": -2.71495661111111e-9,
    "pmdecrad": 0.000001815045457,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 32679,
    "lum": 4.88427378422924,
    "ra": 6.831838,
    "dec": -46.61456,
    "pmra": -0.56,
    "pmdec": 374.38,
    "rv": 20.1,
    "Asset Name": "ASTRID 003134",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Hurt",
    "Hair": "Bob",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001018,
    "y_1": 0.00004633,
    "z_1": 0.00001655,
    "Distance in Parsecs_1": 25.2589,
    "HYG Star ID_1": 32679,
    "Hipparcos Catalogue_1": 32765,
    "Henry Draper Catalogue_1": 50223,
    "Harvard Revised Catalogue_1": 2548,
    "Gliese Catalog_1": "Gl 249.1"
  },
  {
    "Astrid #": "ASTRID 003135",
    "HYG Star ID": 78498,
    "Hipparcos Catalogue": 78738,
    "Henry Draper Catalogue": 144087,
    "Gliese Catalog": "Wo 9541A",
    "Distance in Parsecs": 25.2589,
    "mag": 7.44,
    "Absolute Visual Magnitude": 5.428,
    "Spectral Type": "G8V comp",
    "Color Index": 0.75,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.961101,
    "y": -21.674656,
    "z": -5.013889,
    "vx": 0.00000809,
    "vy": 0.00003218,
    "vz": 0.00000388,
    "rarad": 4.2081286347258,
    "decrad": -0.199829384875651,
    "pmrarad": -3.35345622840277e-7,
    "pmdecrad": -1.01471503e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 78498,
    "base": "Wo 9541",
    "lum": 0.587218865971303,
    "var_min": 7.72,
    "var_max": 7.39,
    "ra": 16.073867,
    "dec": -11.44938,
    "pmra": -69.17,
    "pmdec": -20.93,
    "rv": -31.5,
    "Asset Name": "ASTRID 003135",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Windy",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000809,
    "y_1": 0.00003218,
    "z_1": 0.00000388,
    "Distance in Parsecs_1": 25.2589,
    "HYG Star ID_1": 78498,
    "Hipparcos Catalogue_1": 78738,
    "Henry Draper Catalogue_1": 144087,
    "Gliese Catalog_1": "Wo 9541A"
  },
  {
    "Astrid #": "ASTRID 003136",
    "HYG Star ID": 114579,
    "Hipparcos Catalogue": 114941,
    "Gliese Catalog": "NN 4323",
    "Distance in Parsecs": 25.2589,
    "mag": 10.51,
    "Absolute Visual Magnitude": 8.498,
    "Spectral Type": "K7V:",
    "Color Index": 1.5,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.690203,
    "y": -4.702784,
    "z": 2.506944,
    "vx": -0.00001006,
    "vy": 0.00000528,
    "vz": -0.0000044,
    "rarad": 6.09496818979653,
    "decrad": 0.0994140200213825,
    "pmrarad": 1.30948175118055e-7,
    "pmdecrad": -1.30511842e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 114579,
    "lum": 0.0347376151972652,
    "var_min": 10.569,
    "var_max": 10.399,
    "ra": 23.281064,
    "dec": 5.696004,
    "pmra": 27.01,
    "pmdec": -26.92,
    "rv": -11,
    "Asset Name": "ASTRID 003136",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Knocked Out",
    "Hair": "Bob",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001006,
    "y_1": 0.00000528,
    "z_1": -0.0000044,
    "Distance in Parsecs_1": 25.2589,
    "HYG Star ID_1": 114579,
    "Hipparcos Catalogue_1": 114941,
    "Gliese Catalog_1": "NN 4323"
  },
  {
    "Astrid #": "ASTRID 003137",
    "HYG Star ID": 36821,
    "Hipparcos Catalogue": 36927,
    "Henry Draper Catalogue": 61214,
    "Distance in Parsecs": 25.2717,
    "mag": 8.78,
    "Absolute Visual Magnitude": 6.767,
    "Spectral Type": "K3IV-V",
    "Color Index": 1.049,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.231632,
    "y": 14.087815,
    "z": -20.033948,
    "vx": 0.00000268,
    "vy": 0.00002872,
    "vz": 0.00001936,
    "rarad": 1.98726670934973,
    "decrad": -0.915294890183611,
    "pmrarad": -5.56760030749999e-7,
    "pmdecrad": 0.000001257121873,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 36821,
    "lum": 0.171080298759932,
    "ra": 7.5908,
    "dec": -52.442534,
    "pmra": -114.84,
    "pmdec": 259.3,
    "rv": 0,
    "Asset Name": "ASTRID 003137",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Ram Horns",
    "Outfit": "Scrubs",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00000268,
    "y_1": 0.00002872,
    "z_1": 0.00001936,
    "Distance in Parsecs_1": 25.2717,
    "HYG Star ID_1": 36821,
    "Hipparcos Catalogue_1": 36927,
    "Henry Draper Catalogue_1": 61214
  },
  {
    "Astrid #": "ASTRID 003138",
    "HYG Star ID": 42797,
    "Hipparcos Catalogue": 42916,
    "Henry Draper Catalogue": 74842,
    "Gliese Catalog": "Gl 321.2",
    "Distance in Parsecs": 25.2717,
    "mag": 7.2,
    "Absolute Visual Magnitude": 5.187,
    "Spectral Type": "G5V",
    "Color Index": 0.743,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.243363,
    "y": 13.991697,
    "z": -17.117055,
    "vx": 0.00001331,
    "vy": -0.00001522,
    "vz": -0.00002951,
    "rarad": 2.28965176027263,
    "decrad": -0.74411350172594,
    "pmrarad": 1.45444104166666e-10,
    "pmdecrad": -0.000001401111536,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 42797,
    "lum": 0.73316208902721,
    "ra": 8.745825,
    "dec": -42.634563,
    "pmra": 0.03,
    "pmdec": -289,
    "rv": 5,
    "Asset Name": "ASTRID 003138",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Einstein Tongue",
    "Hair": "Spiky",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00001331,
    "y_1": -0.00001522,
    "z_1": -0.00002951,
    "Distance in Parsecs_1": 25.2717,
    "HYG Star ID_1": 42797,
    "Hipparcos Catalogue_1": 42916,
    "Henry Draper Catalogue_1": 74842,
    "Gliese Catalog_1": "Gl 321.2"
  },
  {
    "Astrid #": "ASTRID 003139",
    "HYG Star ID": 43114,
    "Hipparcos Catalogue": 43233,
    "Distance in Parsecs": 25.2845,
    "mag": 10.35,
    "Absolute Visual Magnitude": 8.336,
    "Spectral Type": "M0",
    "Color Index": 1.296,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.846416,
    "y": 18.638422,
    "z": 2.848413,
    "vx": -0.00002354,
    "vy": -0.00001314,
    "vz": -0.0000532,
    "rarad": 2.30573669168043,
    "decrad": 0.112894151300073,
    "pmrarad": 0.00000103939204974305,
    "pmdecrad": -0.000002117908563,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 43114,
    "lum": 0.0403273792976112,
    "ra": 8.807265,
    "dec": 6.468358,
    "pmra": 214.39,
    "pmdec": -436.85,
    "rv": 0,
    "Asset Name": "ASTRID 003139",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Braided Bun",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00002354,
    "y_1": -0.00001314,
    "z_1": -0.0000532,
    "Distance in Parsecs_1": 25.2845,
    "HYG Star ID_1": 43114,
    "Hipparcos Catalogue_1": 43233
  },
  {
    "Astrid #": "ASTRID 003140",
    "HYG Star ID": 36689,
    "Hipparcos Catalogue": 36795,
    "Henry Draper Catalogue": 60532,
    "Harvard Revised Catalogue": 2906,
    "Gliese Catalog": "Gl 279",
    "Distance in Parsecs": 25.2972,
    "mag": 4.44,
    "Absolute Visual Magnitude": 2.425,
    "Spectral Type": "F6V",
    "Color Index": 0.521,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.338236,
    "y": 21.462897,
    "z": -9.597779,
    "vx": -0.00001936,
    "vy": 0.0000569,
    "vz": -0.00001835,
    "rarad": 1.98118012603206,
    "decrad": -0.389139776991138,
    "pmrarad": -1.95767764208333e-7,
    "pmdecrad": 2.27086727e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 36689,
    "lum": 9.33254300796991,
    "ra": 7.567551,
    "dec": -22.296067,
    "pmra": -40.38,
    "pmdec": 46.84,
    "rv": 61,
    "Asset Name": "ASTRID 003140",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Bad Dreams",
    "Hair": "Lion Mane",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001936,
    "y_1": 0.0000569,
    "z_1": -0.00001835,
    "Distance in Parsecs_1": 25.2972,
    "HYG Star ID_1": 36689,
    "Hipparcos Catalogue_1": 36795,
    "Henry Draper Catalogue_1": 60532,
    "Harvard Revised Catalogue_1": 2906,
    "Gliese Catalog_1": "Gl 279"
  },
  {
    "Astrid #": "ASTRID 003141",
    "HYG Star ID": 63910,
    "Hipparcos Catalogue": 64113,
    "Gliese Catalog": "Wo 9428",
    "Distance in Parsecs": 25.2972,
    "mag": 11.83,
    "Absolute Visual Magnitude": 9.815,
    "Spectral Type": "M0",
    "Color Index": 1.575,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -23.125462,
    "y": -7.115697,
    "z": 7.38432,
    "vx": -0.00000502,
    "vy": 0.00001185,
    "vz": -0.00000432,
    "rarad": 3.44009850707545,
    "decrad": 0.296215541324161,
    "pmrarad": -5.06048519763888e-7,
    "pmdecrad": -1.78362953e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 63910,
    "lum": 0.0103276140576139,
    "var_min": 11.963,
    "var_max": 11.663,
    "ra": 13.140208,
    "dec": 16.9719,
    "pmra": -104.38,
    "pmdec": -36.79,
    "rv": 0,
    "Asset Name": "ASTRID 003141",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Mohawk",
    "Outfit": "Basketball",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00000502,
    "y_1": 0.00001185,
    "z_1": -0.00000432,
    "Distance in Parsecs_1": 25.2972,
    "HYG Star ID_1": 63910,
    "Hipparcos Catalogue_1": 64113,
    "Gliese Catalog_1": "Wo 9428"
  },
  {
    "Astrid #": "ASTRID 003142",
    "HYG Star ID": 64327,
    "Hipparcos Catalogue": 64532,
    "Henry Draper Catalogue": 115043,
    "Gliese Catalog": "Gl 503.2",
    "Distance in Parsecs": 25.2972,
    "mag": 6.82,
    "Absolute Visual Magnitude": 4.805,
    "Spectral Type": "G1V",
    "Color Index": 0.603,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.175433,
    "y": -4.383956,
    "z": 21.145524,
    "vx": 0.00000733,
    "vy": -0.00001202,
    "vz": -0.00000881,
    "rarad": 3.46280267335616,
    "decrad": 0.989746032508997,
    "pmrarad": 5.42554989909722e-7,
    "pmdecrad": -8.6684686e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 64327,
    "lum": 1.0423174293933,
    "ra": 13.226932,
    "dec": 56.70827,
    "pmra": 111.91,
    "pmdec": -17.88,
    "rv": -8.9,
    "Asset Name": "ASTRID 003142",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Neko Ninja",
    "Hair": "Curly",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000733,
    "y_1": -0.00001202,
    "z_1": -0.00000881,
    "Distance in Parsecs_1": 25.2972,
    "HYG Star ID_1": 64327,
    "Hipparcos Catalogue_1": 64532,
    "Henry Draper Catalogue_1": 115043,
    "Gliese Catalog_1": "Gl 503.2"
  },
  {
    "Astrid #": "ASTRID 003143",
    "HYG Star ID": 73403,
    "Hipparcos Catalogue": 73631,
    "Gliese Catalog": "Gl 574",
    "Distance in Parsecs": 25.3036,
    "mag": 9.87,
    "Absolute Visual Magnitude": 7.854,
    "Spectral Type": "K5V",
    "Color Index": 1.188,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.196489,
    "y": -12.526544,
    "z": -18.291364,
    "vx": 0.0000064,
    "vy": 0.0000592,
    "vz": 0.00004743,
    "rarad": 3.94034579248729,
    "decrad": -0.807979576184467,
    "pmrarad": -0.00000145114430863888,
    "pmdecrad": -4.4021082e-8,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 73403,
    "lum": 0.0628637088413049,
    "var_min": 9.937,
    "var_max": 9.807,
    "ra": 15.051012,
    "dec": -46.29382,
    "pmra": -299.32,
    "pmdec": -9.08,
    "rv": -65.2,
    "Asset Name": "ASTRID 003143",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Greasy",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": 0.0000064,
    "y_1": 0.0000592,
    "z_1": 0.00004743,
    "Distance in Parsecs_1": 25.3036,
    "HYG Star ID_1": 73403,
    "Hipparcos Catalogue_1": 73631,
    "Gliese Catalog_1": "Gl 574"
  },
  {
    "Astrid #": "ASTRID 003144",
    "HYG Star ID": 62757,
    "Hipparcos Catalogue": 62956,
    "Henry Draper Catalogue": 112185,
    "Harvard Revised Catalogue": 4905,
    "Proper Name": "Alioth",
    "Distance in Parsecs": 25.31,
    "mag": 1.76,
    "Absolute Visual Magnitude": -0.256,
    "Spectral Type": "A0p",
    "Color Index": -0.022,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -13.775959,
    "y": -3.309169,
    "z": 20.972944,
    "vx": 0.00000732,
    "vy": -0.00001234,
    "vz": -0.00000824,
    "rarad": 3.37733559014455,
    "decrad": 0.976683126633446,
    "pmrarad": 5.41730806652777e-7,
    "pmdecrad": -4.3584749e-8,
    "bayer": "Eps",
    "flam": 77,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 62757,
    "lum": 110.255433210222,
    "var": "Eps",
    "var_min": 1.776,
    "var_max": 1.746,
    "bf": "77Eps UMa",
    "ra": 12.900472,
    "dec": 55.959821,
    "pmra": 111.74,
    "pmdec": -8.99,
    "rv": -9,
    "Asset Name": "ASTRID 003144",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Chuckle",
    "Hair": "Nemes",
    "Outfit": "Pharaoh",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Alioth",
    "Constellation": "Ursa Major",
    "x_1": 0.00000732,
    "y_1": -0.00001234,
    "z_1": -0.00000824,
    "Distance in Parsecs_1": 25.31,
    "HYG Star ID_1": 62757,
    "Hipparcos Catalogue_1": 62956,
    "Henry Draper Catalogue_1": 112185,
    "Harvard Revised Catalogue_1": 4905
  },
  {
    "Astrid #": "ASTRID 003145",
    "HYG Star ID": 119584,
    "Gliese Catalog": "Gl 904.1B",
    "Distance in Parsecs": 25.31,
    "mag": 11.7,
    "Absolute Visual Magnitude": 9.684,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 23.645014,
    "y": -1.980116,
    "z": 8.808435,
    "vx": -5.4e-7,
    "vy": 0.00002718,
    "vz": 0.00000756,
    "rarad": 6.19963322089166,
    "decrad": 0.35546031606865,
    "pmrarad": 0.00000106857783331249,
    "pmdecrad": 3.18561373e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 2,
    "comp_primary": 116472,
    "base": "Gl 904.1",
    "lum": 0.01165198723025,
    "ra": 23.680855,
    "dec": 20.366376,
    "pmra": 220.41,
    "pmdec": 65.71,
    "rv": 0,
    "Asset Name": "ASTRID 003145",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Glad",
    "Hair": "Horns",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -5.4e-7,
    "y_1": 0.00002718,
    "z_1": 0.00000756,
    "Distance in Parsecs_1": 25.31,
    "HYG Star ID_1": 119584,
    "Gliese Catalog_1": "Gl 904.1B"
  },
  {
    "Astrid #": "ASTRID 003146",
    "HYG Star ID": 17416,
    "Hipparcos Catalogue": 17458,
    "Henry Draper Catalogue": 278968,
    "Distance in Parsecs": 25.3165,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.613,
    "Spectral Type": "M0",
    "Color Index": 1.38,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 11.561683,
    "y": 17.22439,
    "z": 14.511262,
    "vx": -0.00001017,
    "vy": 0.00002852,
    "vz": -0.00001147,
    "rarad": 0.979633038699162,
    "decrad": 0.610396251216787,
    "pmrarad": 9.61337047173611e-7,
    "pmdecrad": -7.7928951e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 17416,
    "lum": 0.0312464008580611,
    "var_min": 10.699,
    "var_max": 10.569,
    "ra": 3.741923,
    "dec": 34.973129,
    "pmra": 198.29,
    "pmdec": -160.74,
    "rv": 8,
    "Asset Name": "ASTRID 003146",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Fire",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00001017,
    "y_1": 0.00002852,
    "z_1": -0.00001147,
    "Distance in Parsecs_1": 25.3165,
    "HYG Star ID_1": 17416,
    "Hipparcos Catalogue_1": 17458,
    "Henry Draper Catalogue_1": 278968
  },
  {
    "Astrid #": "ASTRID 003147",
    "HYG Star ID": 118374,
    "Gliese Catalog": "NN 3348B",
    "Distance in Parsecs": 25.3165,
    "mag": 13.98,
    "Absolute Visual Magnitude": 11.963,
    "Spectral Type": "m",
    "Color Index": 1.65,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.338566,
    "y": 24.486679,
    "z": 5.493788,
    "vx": -0.00001196,
    "vy": 0.00000714,
    "vz": -0.00002455,
    "rarad": 1.43528910712226,
    "decrad": 0.21874455832385,
    "pmrarad": 5.06242445236111e-7,
    "pmdecrad": -9.93533523e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118374,
    "base": "NN 3348",
    "lum": 0.00142823608001918,
    "ra": 5.482401,
    "dec": 12.53314,
    "pmra": 104.42,
    "pmdec": -204.93,
    "rv": 0,
    "Asset Name": "ASTRID 003147",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Horns",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001196,
    "y_1": 0.00000714,
    "z_1": -0.00002455,
    "Distance in Parsecs_1": 25.3165,
    "HYG Star ID_1": 118374,
    "Gliese Catalog_1": "NN 3348B"
  },
  {
    "Astrid #": "ASTRID 003148",
    "HYG Star ID": 58628,
    "Hipparcos Catalogue": 58803,
    "Henry Draper Catalogue": 104731,
    "Harvard Revised Catalogue": 4600,
    "Gliese Catalog": "NN 3701",
    "Distance in Parsecs": 25.3229,
    "mag": 5.15,
    "Absolute Visual Magnitude": 3.132,
    "Spectral Type": "F6V",
    "Color Index": 0.417,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -18.687552,
    "y": -0.298421,
    "z": -17.086639,
    "vx": -0.00001955,
    "vy": -0.00004005,
    "vz": -0.00003689,
    "rarad": 3.157555395772,
    "decrad": -0.740614000389685,
    "pmrarad": 0.00000156900251438194,
    "pmdecrad": -5.37415964e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 58628,
    "lum": 4.86631256073198,
    "ra": 12.060973,
    "dec": -42.434056,
    "pmra": 323.63,
    "pmdec": -110.85,
    "rv": 38.9,
    "Asset Name": "ASTRID 003148",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Antlers",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00001955,
    "y_1": -0.00004005,
    "z_1": -0.00003689,
    "Distance in Parsecs_1": 25.3229,
    "HYG Star ID_1": 58628,
    "Hipparcos Catalogue_1": 58803,
    "Henry Draper Catalogue_1": 104731,
    "Harvard Revised Catalogue_1": 4600,
    "Gliese Catalog_1": "NN 3701"
  },
  {
    "Astrid #": "ASTRID 003149",
    "HYG Star ID": 66493,
    "Hipparcos Catalogue": 66704,
    "Henry Draper Catalogue": 119124,
    "Harvard Revised Catalogue": 5148,
    "Gliese Catalog": "Gl 521.2A",
    "Distance in Parsecs": 25.3293,
    "mag": 6.31,
    "Absolute Visual Magnitude": 4.292,
    "Spectral Type": "F7.7V",
    "Color Index": 0.537,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.584318,
    "y": -6.830801,
    "z": 19.550086,
    "vx": 0.00000554,
    "vy": 0.00001951,
    "vz": -0.00000496,
    "rarad": 3.57961754580409,
    "decrad": 0.881730089490456,
    "pmrarad": -6.04708103756944e-7,
    "pmdecrad": 2.80513195e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 66493,
    "base": "Gl 521.2",
    "lum": 1.67186035720446,
    "ra": 13.673132,
    "dec": 50.519413,
    "pmra": -124.73,
    "pmdec": 57.86,
    "rv": -12,
    "Asset Name": "ASTRID 003149",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Sad",
    "Hair": "Spiky",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000554,
    "y_1": 0.00001951,
    "z_1": -0.00000496,
    "Distance in Parsecs_1": 25.3293,
    "HYG Star ID_1": 66493,
    "Hipparcos Catalogue_1": 66704,
    "Henry Draper Catalogue_1": 119124,
    "Harvard Revised Catalogue_1": 5148,
    "Gliese Catalog_1": "Gl 521.2A"
  },
  {
    "Astrid #": "ASTRID 003150",
    "HYG Star ID": 74377,
    "Hipparcos Catalogue": 74605,
    "Henry Draper Catalogue": 136064,
    "Harvard Revised Catalogue": 5691,
    "Gliese Catalog": "Gl 580.2",
    "Distance in Parsecs": 25.3421,
    "mag": 5.15,
    "Absolute Visual Magnitude": 3.131,
    "Spectral Type": "F9IV",
    "Color Index": 0.55,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.447054,
    "y": -7.328138,
    "z": 23.386622,
    "vx": 0.00000357,
    "vy": -0.00003714,
    "vz": -0.00006396,
    "rarad": 3.99085042171935,
    "decrad": 1.1754220461644,
    "pmrarad": 0.00000107391078379861,
    "pmdecrad": -0.000001901924068,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 74377,
    "lum": 4.87079666492536,
    "ra": 15.243926,
    "dec": 67.346722,
    "pmra": 221.51,
    "pmdec": -392.3,
    "rv": -48.1,
    "Asset Name": "ASTRID 003150",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Neko Dreams",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Super",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00000357,
    "y_1": -0.00003714,
    "z_1": -0.00006396,
    "Distance in Parsecs_1": 25.3421,
    "HYG Star ID_1": 74377,
    "Hipparcos Catalogue_1": 74605,
    "Henry Draper Catalogue_1": 136064,
    "Harvard Revised Catalogue_1": 5691,
    "Gliese Catalog_1": "Gl 580.2"
  },
  {
    "Astrid #": "ASTRID 003151",
    "HYG Star ID": 10899,
    "Hipparcos Catalogue": 10925,
    "Henry Draper Catalogue": 14629,
    "Gliese Catalog": "NN 3154",
    "Distance in Parsecs": 25.3485,
    "mag": 8.67,
    "Absolute Visual Magnitude": 6.65,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.031,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.093842,
    "y": 11.344068,
    "z": -15.963922,
    "vx": -0.00001679,
    "vy": 0.00002021,
    "vz": -0.00000256,
    "rarad": 0.613984415229727,
    "decrad": -0.681267108857556,
    "pmrarad": 0.00000103328339736805,
    "pmdecrad": -1.30269435e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 10899,
    "lum": 0.190546071796324,
    "ra": 2.345248,
    "dec": -39.03373,
    "pmra": 213.13,
    "pmdec": -26.87,
    "rv": 0,
    "Asset Name": "ASTRID 003151",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Braided Bun",
    "Outfit": "Viking",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00001679,
    "y_1": 0.00002021,
    "z_1": -0.00000256,
    "Distance in Parsecs_1": 25.3485,
    "HYG Star ID_1": 10899,
    "Hipparcos Catalogue_1": 10925,
    "Henry Draper Catalogue_1": 14629,
    "Gliese Catalog_1": "NN 3154"
  },
  {
    "Astrid #": "ASTRID 003152",
    "HYG Star ID": 57321,
    "Hipparcos Catalogue": 57494,
    "Henry Draper Catalogue": 102392,
    "Gliese Catalog": "Gl 444A",
    "Distance in Parsecs": 25.3614,
    "mag": 9.03,
    "Absolute Visual Magnitude": 7.009,
    "Spectral Type": "K2",
    "Color Index": 1.126,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.783937,
    "y": 1.400401,
    "z": -5.196767,
    "vx": -0.00002003,
    "vy": 0.00002654,
    "vz": -0.00001213,
    "rarad": 3.08514847865862,
    "decrad": -0.206368569663503,
    "pmrarad": -0.00000100026758572222,
    "pmdecrad": -2.94475829e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 57321,
    "base": "Gl 444",
    "lum": 0.136898913070073,
    "ra": 11.784399,
    "dec": -11.824048,
    "pmra": -206.32,
    "pmdec": -60.74,
    "rv": 23,
    "Asset Name": "ASTRID 003152",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Braided Ponytail",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": -0.00002003,
    "y_1": 0.00002654,
    "z_1": -0.00001213,
    "Distance in Parsecs_1": 25.3614,
    "HYG Star ID_1": 57321,
    "Hipparcos Catalogue_1": 57494,
    "Henry Draper Catalogue_1": 102392,
    "Gliese Catalog_1": "Gl 444A"
  },
  {
    "Astrid #": "ASTRID 003153",
    "HYG Star ID": 54643,
    "Hipparcos Catalogue": 54803,
    "Gliese Catalog": "NN 3650",
    "Distance in Parsecs": 25.3678,
    "mag": 10.29,
    "Absolute Visual Magnitude": 8.269,
    "Spectral Type": "M0:",
    "Color Index": 1.424,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -24.839791,
    "y": 5.147706,
    "z": 0.105747,
    "vx": 0.00001025,
    "vy": 0.00004994,
    "vz": -0.00002383,
    "rarad": 2.93724889586855,
    "decrad": 0.00416857519622875,
    "pmrarad": -0.00000200984359411111,
    "pmdecrad": -9.39520431e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54643,
    "lum": 0.0428943409996781,
    "var_min": 10.363,
    "var_max": 10.233,
    "ra": 11.219464,
    "dec": 0.238842,
    "pmra": -414.56,
    "pmdec": -193.79,
    "rv": 0,
    "Asset Name": "ASTRID 003153",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Battle Ready",
    "Hair": "Curly",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00001025,
    "y_1": 0.00004994,
    "z_1": -0.00002383,
    "Distance in Parsecs_1": 25.3678,
    "HYG Star ID_1": 54643,
    "Hipparcos Catalogue_1": 54803,
    "Gliese Catalog_1": "NN 3650"
  },
  {
    "Astrid #": "ASTRID 003154",
    "HYG Star ID": 67439,
    "Hipparcos Catalogue": 67655,
    "Henry Draper Catalogue": 120559,
    "Distance in Parsecs": 25.3678,
    "mag": 7.97,
    "Absolute Visual Magnitude": 5.949,
    "Spectral Type": "G5V",
    "Color Index": 0.663,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.06506,
    "y": -6.393067,
    "z": -21.379766,
    "vx": 0.00001054,
    "vy": 0.0000557,
    "vz": -0.00003886,
    "rarad": 3.62887275712694,
    "decrad": -1.00244128410695,
    "pmrarad": -0.00000174581406368055,
    "pmdecrad": -0.000002000583652,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 67439,
    "lum": 0.363412616064222,
    "ra": 13.861273,
    "dec": -57.435655,
    "pmra": -360.1,
    "pmdec": -412.65,
    "rv": 13.4,
    "Asset Name": "ASTRID 003154",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Charmer",
    "Hair": "Round Brush",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001054,
    "y_1": 0.0000557,
    "z_1": -0.00003886,
    "Distance in Parsecs_1": 25.3678,
    "HYG Star ID_1": 67439,
    "Hipparcos Catalogue_1": 67655,
    "Henry Draper Catalogue_1": 120559
  },
  {
    "Astrid #": "ASTRID 003155",
    "HYG Star ID": 60594,
    "Hipparcos Catalogue": 60783,
    "Distance in Parsecs": 25.3743,
    "mag": 12.08,
    "Absolute Visual Magnitude": 10.058,
    "Color Index": 0.7,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -20.001489,
    "y": -2.410732,
    "z": 15.426727,
    "vx": 0.0000046,
    "vy": 0.00002873,
    "vz": 0.00001045,
    "rarad": 3.26154416041979,
    "decrad": 0.653497013492291,
    "pmrarad": -0.00000110246630958333,
    "pmdecrad": 5.18799119e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60594,
    "lum": 0.00825657632621326,
    "var_min": 12.195,
    "var_max": 11.765,
    "ra": 12.458181,
    "dec": 37.442621,
    "pmra": -227.4,
    "pmdec": 107.01,
    "rv": 0,
    "Asset Name": "ASTRID 003155",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Braid",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000046,
    "y_1": 0.00002873,
    "z_1": 0.00001045,
    "Distance in Parsecs_1": 25.3743,
    "HYG Star ID_1": 60594,
    "Hipparcos Catalogue_1": 60783
  },
  {
    "Astrid #": "ASTRID 003156",
    "HYG Star ID": 59909,
    "Hipparcos Catalogue": 60093,
    "Gliese Catalog": "Gl 459.3",
    "Distance in Parsecs": 25.3807,
    "mag": 10.62,
    "Absolute Visual Magnitude": 8.597,
    "Spectral Type": "M2V",
    "Color Index": 1.439,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -22.249838,
    "y": -1.888072,
    "z": 12.064798,
    "vx": -0.00000142,
    "vy": 0.00008014,
    "vz": 0.00000778,
    "rarad": 3.22625137037349,
    "decrad": 0.495365706009967,
    "pmrarad": -0.00000315138588634722,
    "pmdecrad": 3.70058282e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 59909,
    "lum": 0.0317102743894789,
    "ra": 12.323372,
    "dec": 28.382364,
    "pmra": -650.02,
    "pmdec": 76.33,
    "rv": -1,
    "Asset Name": "ASTRID 003156",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Ponytail",
    "Outfit": "Sharky",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00000142,
    "y_1": 0.00008014,
    "z_1": 0.00000778,
    "Distance in Parsecs_1": 25.3807,
    "HYG Star ID_1": 59909,
    "Hipparcos Catalogue_1": 60093,
    "Gliese Catalog_1": "Gl 459.3"
  },
  {
    "Astrid #": "ASTRID 003157",
    "HYG Star ID": 74746,
    "Hipparcos Catalogue": 74975,
    "Henry Draper Catalogue": 136202,
    "Harvard Revised Catalogue": 5694,
    "Distance in Parsecs": 25.3807,
    "mag": 5.04,
    "Absolute Visual Magnitude": 3.017,
    "Spectral Type": "F8III-IV",
    "Color Index": 0.54,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.365124,
    "y": -19.384937,
    "z": 0.781926,
    "vx": -0.00000178,
    "vy": -0.00007329,
    "vz": -0.00006146,
    "rarad": 4.01126095328758,
    "decrad": 0.030812175776355,
    "pmrarad": 0.00000180951858130555,
    "pmdecrad": -0.000002490196988,
    "flam": 5,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 74746,
    "lum": 5.41003406863467,
    "var": "MQ",
    "var_min": 5.05,
    "var_max": 5.03,
    "bf": "5    Ser",
    "ra": 15.321888,
    "dec": 1.765408,
    "pmra": 373.24,
    "pmdec": -513.64,
    "rv": 54,
    "Asset Name": "ASTRID 003157",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Prince",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000178,
    "y_1": -0.00007329,
    "z_1": -0.00006146,
    "Distance in Parsecs_1": 25.3807,
    "HYG Star ID_1": 74746,
    "Hipparcos Catalogue_1": 74975,
    "Henry Draper Catalogue_1": 136202,
    "Harvard Revised Catalogue_1": 5694
  },
  {
    "Astrid #": "ASTRID 003158",
    "HYG Star ID": 98367,
    "Hipparcos Catalogue": 98679,
    "Henry Draper Catalogue": 189484,
    "Gliese Catalog": "Gl 773.6",
    "Distance in Parsecs": 25.3807,
    "mag": 8.69,
    "Absolute Visual Magnitude": 6.667,
    "Spectral Type": "K5V...",
    "Color Index": 1.124,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.307083,
    "y": -14.020453,
    "z": -19.45785,
    "vx": 0.00003256,
    "vy": 0.00004232,
    "vz": -0.00004234,
    "rarad": 5.24726414189398,
    "decrad": -0.873582987284473,
    "pmrarad": 0.00000195370216990277,
    "pmdecrad": -0.000001669746797,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 98367,
    "lum": 0.187585817379928,
    "var_min": 8.793,
    "var_max": 8.633,
    "ra": 20.043073,
    "dec": -50.052618,
    "pmra": 402.98,
    "pmdec": -344.41,
    "rv": 19.3,
    "Asset Name": "ASTRID 003158",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Shy Smile",
    "Hair": "Undercut",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.00003256,
    "y_1": 0.00004232,
    "z_1": -0.00004234,
    "Distance in Parsecs_1": 25.3807,
    "HYG Star ID_1": 98367,
    "Hipparcos Catalogue_1": 98679,
    "Henry Draper Catalogue_1": 189484,
    "Gliese Catalog_1": "Gl 773.6"
  },
  {
    "Astrid #": "ASTRID 003159",
    "HYG Star ID": 119162,
    "Gliese Catalog": "Wo 9593B",
    "Distance in Parsecs": 25.3807,
    "mag": 10.2,
    "Absolute Visual Magnitude": 8.177,
    "Spectral Type": "G5",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.622365,
    "y": -16.433846,
    "z": -19.273728,
    "vx": -0.00000133,
    "vy": 0.00002063,
    "vz": -0.00001748,
    "rarad": 4.61399043895801,
    "decrad": -0.8623676665645,
    "pmrarad": -1.31820839743055e-7,
    "pmdecrad": -0.00000105841129,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 2,
    "comp_primary": 85985,
    "base": "Wo 9593",
    "lum": 0.0466874334392018,
    "ra": 17.624145,
    "dec": -49.410028,
    "pmra": -27.19,
    "pmdec": -218.31,
    "rv": 0,
    "Asset Name": "ASTRID 003159",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Unkempt",
    "Outfit": "Super",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -0.00000133,
    "y_1": 0.00002063,
    "z_1": -0.00001748,
    "Distance in Parsecs_1": 25.3807,
    "HYG Star ID_1": 119162,
    "Gliese Catalog_1": "Wo 9593B"
  },
  {
    "Astrid #": "ASTRID 003160",
    "HYG Star ID": 87502,
    "Hipparcos Catalogue": 87773,
    "Distance in Parsecs": 25.3872,
    "mag": 10.16,
    "Absolute Visual Magnitude": 8.137,
    "Spectral Type": "K7",
    "Color Index": 1.185,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.385159,
    "y": -21.185914,
    "z": 13.982798,
    "vx": 9.6e-7,
    "vy": -0.00001595,
    "vz": -0.00002415,
    "rarad": 4.69421094527199,
    "decrad": 0.583300153473426,
    "pmrarad": 4.94025140486111e-8,
    "pmdecrad": -0.000001139603037,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 87502,
    "lum": 0.0484395388547839,
    "var_min": 10.215,
    "var_max": 10.085,
    "ra": 17.930565,
    "dec": 33.420637,
    "pmra": 10.19,
    "pmdec": -235.06,
    "rv": 0,
    "Asset Name": "ASTRID 003160",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Angelic",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 9.6e-7,
    "y_1": -0.00001595,
    "z_1": -0.00002415,
    "Distance in Parsecs_1": 25.3872,
    "HYG Star ID_1": 87502,
    "Hipparcos Catalogue_1": 87773
  },
  {
    "Astrid #": "ASTRID 003161",
    "HYG Star ID": 94686,
    "Hipparcos Catalogue": 94981,
    "Henry Draper Catalogue": 181655,
    "Harvard Revised Catalogue": 7345,
    "Gliese Catalog": "Gl 754.2",
    "Distance in Parsecs": 25.3872,
    "mag": 6.29,
    "Absolute Visual Magnitude": 4.267,
    "Spectral Type": "G8V",
    "Color Index": 0.676,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.875262,
    "y": -18.979773,
    "z": 15.39512,
    "vx": -0.00000204,
    "vy": -0.00001683,
    "vz": -0.0000168,
    "rarad": 5.05992817324996,
    "decrad": 0.651540825527247,
    "pmrarad": -3.01457146569444e-7,
    "pmdecrad": -8.87596886e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 94686,
    "lum": 1.71080298759932,
    "ra": 19.327502,
    "dec": 37.330539,
    "pmra": -62.18,
    "pmdec": -183.08,
    "rv": 1.8,
    "Asset Name": "ASTRID 003161",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Round Brush",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000204,
    "y_1": -0.00001683,
    "z_1": -0.0000168,
    "Distance in Parsecs_1": 25.3872,
    "HYG Star ID_1": 94686,
    "Hipparcos Catalogue_1": 94981,
    "Henry Draper Catalogue_1": 181655,
    "Harvard Revised Catalogue_1": 7345,
    "Gliese Catalog_1": "Gl 754.2"
  },
  {
    "Astrid #": "ASTRID 003162",
    "HYG Star ID": 21223,
    "Hipparcos Catalogue": 21276,
    "Henry Draper Catalogue": 28495,
    "Gliese Catalog": "NN 3295",
    "Distance in Parsecs": 25.4001,
    "mag": 7.75,
    "Absolute Visual Magnitude": 5.726,
    "Spectral Type": "G0",
    "Color Index": 0.759,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.992371,
    "y": 10.122846,
    "z": 22.951123,
    "vx": 0.0000065,
    "vy": -0.00000955,
    "vz": 0.00000308,
    "rarad": 1.19513806723616,
    "decrad": 1.12806277851896,
    "pmrarad": -3.75973009270833e-7,
    "pmdecrad": 2.83373596e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 21223,
    "lum": 0.446272370762252,
    "var": "MS",
    "var_min": 7.788,
    "var_max": 7.728,
    "ra": 4.565091,
    "dec": 64.633236,
    "pmra": -77.55,
    "pmdec": 58.45,
    "rv": 0,
    "Asset Name": "ASTRID 003162",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Warm Smile",
    "Hair": "Sweetheart",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.0000065,
    "y_1": -0.00000955,
    "z_1": 0.00000308,
    "Distance in Parsecs_1": 25.4001,
    "HYG Star ID_1": 21223,
    "Hipparcos Catalogue_1": 21276,
    "Henry Draper Catalogue_1": 28495,
    "Gliese Catalog_1": "NN 3295"
  },
  {
    "Astrid #": "ASTRID 003163",
    "HYG Star ID": 32852,
    "Hipparcos Catalogue": 32939,
    "Distance in Parsecs": 25.4001,
    "mag": 10.06,
    "Absolute Visual Magnitude": 8.036,
    "Spectral Type": "M0V",
    "Color Index": 1.086,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.095466,
    "y": 17.84197,
    "z": -17.608417,
    "vx": -0.00000616,
    "vy": -0.00000379,
    "vz": -0.0000024,
    "rarad": 1.79642796201225,
    "decrad": -0.765977790614436,
    "pmrarad": 2.69944257333333e-7,
    "pmdecrad": -1.31384507e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 32852,
    "lum": 0.0531618395073962,
    "ra": 6.861849,
    "dec": -43.887295,
    "pmra": 55.68,
    "pmdec": -27.1,
    "rv": 0,
    "Asset Name": "ASTRID 003163",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Ram Horns",
    "Outfit": "Scientist",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000616,
    "y_1": -0.00000379,
    "z_1": -0.0000024,
    "Distance in Parsecs_1": 25.4001,
    "HYG Star ID_1": 32852,
    "Hipparcos Catalogue_1": 32939
  },
  {
    "Astrid #": "ASTRID 003164",
    "HYG Star ID": 110823,
    "Hipparcos Catalogue": 111170,
    "Henry Draper Catalogue": 213429,
    "Harvard Revised Catalogue": 8581,
    "Gliese Catalog": "Gl 862.1",
    "Distance in Parsecs": 25.413,
    "mag": 6.15,
    "Absolute Visual Magnitude": 4.125,
    "Spectral Type": "F7V",
    "Color Index": 0.565,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 23.379623,
    "y": -9.52853,
    "z": -2.901126,
    "vx": -0.00000298,
    "vy": 0.00002259,
    "vz": -0.00001217,
    "rarad": 5.8961811979653,
    "decrad": -0.114408974214633,
    "pmrarad": 7.788531778125e-7,
    "pmdecrad": -5.26653101e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 110823,
    "lum": 1.94984459975804,
    "ra": 22.521753,
    "dec": -6.555151,
    "pmra": 160.65,
    "pmdec": -108.63,
    "rv": -9.6,
    "Asset Name": "ASTRID 003164",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Infatuated",
    "Hair": "Middle Part",
    "Outfit": "Emperor",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000298,
    "y_1": 0.00002259,
    "z_1": -0.00001217,
    "Distance in Parsecs_1": 25.413,
    "HYG Star ID_1": 110823,
    "Hipparcos Catalogue_1": 111170,
    "Henry Draper Catalogue_1": 213429,
    "Harvard Revised Catalogue_1": 8581,
    "Gliese Catalog_1": "Gl 862.1"
  },
  {
    "Astrid #": "ASTRID 003165",
    "HYG Star ID": 55342,
    "Hipparcos Catalogue": 55507,
    "Gliese Catalog": "NN 3656",
    "Distance in Parsecs": 25.4194,
    "mag": 9.8,
    "Absolute Visual Magnitude": 7.774,
    "Spectral Type": "M0",
    "Color Index": 1.337,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.12873,
    "y": 2.859003,
    "z": 18.562829,
    "vx": -0.00000816,
    "vy": 0.00002604,
    "vz": -0.00001154,
    "rarad": 2.97620845896515,
    "decrad": 0.818705768205467,
    "pmrarad": -9.57603981833333e-7,
    "pmdecrad": -6.64776518e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 55342,
    "lum": 0.067670595767419,
    "ra": 11.368279,
    "dec": 46.908385,
    "pmra": -197.52,
    "pmdec": -137.12,
    "rv": 0,
    "Asset Name": "ASTRID 003165",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Unkempt",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00000816,
    "y_1": 0.00002604,
    "z_1": -0.00001154,
    "Distance in Parsecs_1": 25.4194,
    "HYG Star ID_1": 55342,
    "Hipparcos Catalogue_1": 55507,
    "Gliese Catalog_1": "NN 3656"
  },
  {
    "Astrid #": "ASTRID 003166",
    "HYG Star ID": 16171,
    "Hipparcos Catalogue": 16209,
    "Distance in Parsecs": 25.4323,
    "mag": 11.1,
    "Absolute Visual Magnitude": 9.073,
    "Spectral Type": "K4:",
    "Color Index": 1.314,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.379977,
    "y": 15.972494,
    "z": 15.440773,
    "vx": -0.00006028,
    "vy": 0.00014767,
    "vz": -0.00010441,
    "rarad": 0.911423362921781,
    "decrad": 0.65244675206398,
    "pmrarad": 0.00000543107677505555,
    "pmdecrad": -0.000005167192688,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 16171,
    "lum": 0.0204550243110935,
    "ra": 3.481381,
    "dec": 37.382445,
    "pmra": 1120.24,
    "pmdec": -1065.81,
    "rv": 0,
    "Asset Name": "ASTRID 003166",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Spiky",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00006028,
    "y_1": 0.00014767,
    "z_1": -0.00010441,
    "Distance in Parsecs_1": 25.4323,
    "HYG Star ID_1": 16171,
    "Hipparcos Catalogue_1": 16209
  },
  {
    "Astrid #": "ASTRID 003167",
    "HYG Star ID": 114341,
    "Hipparcos Catalogue": 114703,
    "Henry Draper Catalogue": 219175,
    "Distance in Parsecs": 25.4323,
    "mag": 8.2,
    "Absolute Visual Magnitude": 6.173,
    "Spectral Type": "G3V",
    "Color Index": 0.651,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 24.622095,
    "y": -4.995232,
    "z": -3.948342,
    "vx": -0.00001457,
    "vy": 0.00007426,
    "vz": -0.00000233,
    "rarad": 6.08302561318291,
    "decrad": -0.155881148346923,
    "pmrarad": 0.000002748020904125,
    "pmdecrad": -2.67811077e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 114341,
    "lum": 0.295665056508294,
    "ra": 23.235446,
    "dec": -8.931332,
    "pmra": 566.82,
    "pmdec": -55.24,
    "rv": -27.7,
    "Asset Name": "ASTRID 003167",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Knocked Out",
    "Hair": "Sweetheart",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00001457,
    "y_1": 0.00007426,
    "z_1": -0.00000233,
    "Distance in Parsecs_1": 25.4323,
    "HYG Star ID_1": 114341,
    "Hipparcos Catalogue_1": 114703,
    "Henry Draper Catalogue_1": 219175
  },
  {
    "Astrid #": "ASTRID 003168",
    "HYG Star ID": 117175,
    "Hipparcos Catalogue": 117542,
    "Henry Draper Catalogue": 223515,
    "Gliese Catalog": "GJ 1291A",
    "Distance in Parsecs": 25.4323,
    "mag": 7.88,
    "Absolute Visual Magnitude": 5.853,
    "Spectral Type": "K1V",
    "Color Index": 0.839,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 22.136541,
    "y": -0.94247,
    "z": -12.485479,
    "vx": 0.00000204,
    "vy": 0.00002274,
    "vz": 0.0000019,
    "rarad": 6.24063454434566,
    "decrad": -0.513156952993242,
    "pmrarad": 8.96953790395833e-7,
    "pmdecrad": 8.5715058e-8,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 117175,
    "base": "GJ 1291",
    "lum": 0.397008678112184,
    "ra": 23.837468,
    "dec": -29.401728,
    "pmra": 185.01,
    "pmdec": 17.68,
    "rv": 0,
    "Asset Name": "ASTRID 003168",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Curly",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00000204,
    "y_1": 0.00002274,
    "z_1": 0.0000019,
    "Distance in Parsecs_1": 25.4323,
    "HYG Star ID_1": 117175,
    "Hipparcos Catalogue_1": 117542,
    "Henry Draper Catalogue_1": 223515,
    "Gliese Catalog_1": "GJ 1291A"
  },
  {
    "Astrid #": "ASTRID 003169",
    "HYG Star ID": 63300,
    "Hipparcos Catalogue": 63503,
    "Henry Draper Catalogue": 113139,
    "Harvard Revised Catalogue": 4931,
    "Distance in Parsecs": 25.4453,
    "mag": 4.93,
    "Absolute Visual Magnitude": 2.902,
    "Spectral Type": "F2V",
    "Color Index": 0.368,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.601724,
    "y": -3.690939,
    "z": 21.18558,
    "vx": 0.00000923,
    "vy": -0.00001134,
    "vz": -0.00000833,
    "rarad": 3.40656627776091,
    "decrad": 0.983778181264593,
    "pmrarad": 5.2534410425e-7,
    "pmdecrad": 1.2944525e-8,
    "flam": 78,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 63300,
    "lum": 6.01450651977548,
    "bf": "78    UMa",
    "ra": 13.012125,
    "dec": 56.366338,
    "pmra": 108.36,
    "pmdec": 2.67,
    "rv": -10,
    "Asset Name": "ASTRID 003169",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Ram Horns",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000923,
    "y_1": -0.00001134,
    "z_1": -0.00000833,
    "Distance in Parsecs_1": 25.4453,
    "HYG Star ID_1": 63300,
    "Hipparcos Catalogue_1": 63503,
    "Henry Draper Catalogue_1": 113139,
    "Harvard Revised Catalogue_1": 4931
  },
  {
    "Astrid #": "ASTRID 003170",
    "HYG Star ID": 23886,
    "Hipparcos Catalogue": 23941,
    "Henry Draper Catalogue": 33256,
    "Harvard Revised Catalogue": 1673,
    "Gliese Catalog": "Gl 189.2",
    "Distance in Parsecs": 25.4582,
    "mag": 5.11,
    "Absolute Visual Magnitude": 3.081,
    "Spectral Type": "F2V",
    "Color Index": 0.455,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.630937,
    "y": 24.74883,
    "z": -1.978036,
    "vx": -0.00000431,
    "vy": 0.00001201,
    "vz": 0.00000115,
    "rarad": 1.34708141546698,
    "decrad": -0.07777548898822,
    "pmrarad": 2.69653369125e-7,
    "pmdecrad": 7.7715632e-8,
    "flam": 68,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 23886,
    "lum": 5.10035023959446,
    "bf": "68    Eri",
    "ra": 5.145472,
    "dec": -4.456207,
    "pmra": 55.62,
    "pmdec": 16.03,
    "rv": 10.4,
    "Asset Name": "ASTRID 003170",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Greasy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00000431,
    "y_1": 0.00001201,
    "z_1": 0.00000115,
    "Distance in Parsecs_1": 25.4582,
    "HYG Star ID_1": 23886,
    "Hipparcos Catalogue_1": 23941,
    "Henry Draper Catalogue_1": 33256,
    "Harvard Revised Catalogue_1": 1673,
    "Gliese Catalog_1": "Gl 189.2"
  },
  {
    "Astrid #": "ASTRID 003171",
    "HYG Star ID": 93452,
    "Hipparcos Catalogue": 93747,
    "Henry Draper Catalogue": 177724,
    "Harvard Revised Catalogue": 7235,
    "Gliese Catalog": "NN 4095A",
    "Distance in Parsecs": 25.4582,
    "mag": 2.99,
    "Absolute Visual Magnitude": 0.961,
    "Spectral Type": "A0Vn",
    "Color Index": 0.014,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 6.95881,
    "y": -23.716532,
    "z": 6.099966,
    "vx": -0.00000703,
    "vy": 0.00002087,
    "vz": -0.00001755,
    "rarad": 4.99779454535477,
    "decrad": 0.241963338835717,
    "pmrarad": -3.41308831111111e-8,
    "pmdecrad": -4.62075918e-7,
    "bayer": "Zet",
    "flam": 17,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 93452,
    "base": "NN 4095",
    "lum": 35.9418146314714,
    "bf": "17Zet Aql",
    "ra": 19.090169,
    "dec": 13.863478,
    "pmra": -7.04,
    "pmdec": -95.31,
    "rv": -25,
    "Asset Name": "ASTRID 003171",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Innocent",
    "Outfit": "Tarnished",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00000703,
    "y_1": 0.00002087,
    "z_1": -0.00001755,
    "Distance in Parsecs_1": 25.4582,
    "HYG Star ID_1": 93452,
    "Hipparcos Catalogue_1": 93747,
    "Henry Draper Catalogue_1": 177724,
    "Harvard Revised Catalogue_1": 7235,
    "Gliese Catalog_1": "NN 4095A"
  },
  {
    "Astrid #": "ASTRID 003172",
    "HYG Star ID": 2705,
    "Hipparcos Catalogue": 2711,
    "Henry Draper Catalogue": 3158,
    "Harvard Revised Catalogue": 140,
    "Gliese Catalog": "Gl 22.2",
    "Distance in Parsecs": 25.4842,
    "mag": 5.57,
    "Absolute Visual Magnitude": 3.539,
    "Spectral Type": "F3IV-V",
    "Color Index": 0.472,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.383147,
    "y": 2.330868,
    "z": -20.183809,
    "vx": 0.00002096,
    "vy": 0.00003164,
    "vz": -0.00002531,
    "rarad": 0.150370868548687,
    "decrad": -0.914082860086063,
    "pmrarad": 0.00000110440556430555,
    "pmdecrad": 1.85247307e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 2705,
    "lum": 3.34502986826013,
    "ra": 0.574374,
    "dec": -52.37309,
    "pmra": 227.8,
    "pmdec": 38.21,
    "rv": 34.8,
    "Asset Name": "ASTRID 003172",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Pixie",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": 0.00002096,
    "y_1": 0.00003164,
    "z_1": -0.00002531,
    "Distance in Parsecs_1": 25.4842,
    "HYG Star ID_1": 2705,
    "Hipparcos Catalogue_1": 2711,
    "Henry Draper Catalogue_1": 3158,
    "Harvard Revised Catalogue_1": 140,
    "Gliese Catalog_1": "Gl 22.2"
  },
  {
    "Astrid #": "ASTRID 003173",
    "HYG Star ID": 60185,
    "Hipparcos Catalogue": 60370,
    "Henry Draper Catalogue": 107692,
    "Distance in Parsecs": 25.4907,
    "mag": 6.7,
    "Absolute Visual Magnitude": 4.668,
    "Spectral Type": "G3IV/V",
    "Color Index": 0.639,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -19.662904,
    "y": -1.958008,
    "z": -16.103174,
    "vx": 0.00001359,
    "vy": -0.00002025,
    "vz": -0.00001413,
    "rarad": 3.24084209001565,
    "decrad": -0.683779524126557,
    "pmrarad": 8.43430360062499e-7,
    "pmdecrad": -7.15100178e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 60185,
    "lum": 1.1824968703831,
    "ra": 12.379105,
    "dec": -39.177681,
    "pmra": 173.97,
    "pmdec": -147.5,
    "rv": 0,
    "Asset Name": "ASTRID 003173",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Sweet Nap",
    "Hair": "Good Boy",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001359,
    "y_1": -0.00002025,
    "z_1": -0.00001413,
    "Distance in Parsecs_1": 25.4907,
    "HYG Star ID_1": 60185,
    "Hipparcos Catalogue_1": 60370,
    "Henry Draper Catalogue_1": 107692
  },
  {
    "Astrid #": "ASTRID 003174",
    "HYG Star ID": 98889,
    "Hipparcos Catalogue": 99205,
    "Distance in Parsecs": 25.4907,
    "mag": 9.81,
    "Absolute Visual Magnitude": 7.778,
    "Spectral Type": "K8",
    "Color Index": 1.169,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.45437,
    "y": -21.446834,
    "z": 2.964625,
    "vx": 0.00001645,
    "vy": 0.00001321,
    "vz": 0.00002095,
    "rarad": 5.27266623836238,
    "decrad": 0.116566015570965,
    "pmrarad": 8.22098558118055e-7,
    "pmdecrad": 8.27722396e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 98889,
    "lum": 0.0674217467576243,
    "ra": 20.140101,
    "dec": 6.678741,
    "pmra": 169.57,
    "pmdec": 170.73,
    "rv": 0,
    "Asset Name": "ASTRID 003174",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Shinobi",
    "Hair": "Audiophile",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00001645,
    "y_1": 0.00001321,
    "z_1": 0.00002095,
    "Distance in Parsecs_1": 25.4907,
    "HYG Star ID_1": 98889,
    "Hipparcos Catalogue_1": 99205
  },
  {
    "Astrid #": "ASTRID 003175",
    "HYG Star ID": 4897,
    "Hipparcos Catalogue": 4907,
    "Henry Draper Catalogue": 5996,
    "Distance in Parsecs": 25.4972,
    "mag": 7.67,
    "Absolute Visual Magnitude": 5.638,
    "Spectral Type": "G5",
    "Color Index": 0.756,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.703894,
    "y": 2.452854,
    "z": 23.839545,
    "vx": 0.00000209,
    "vy": 0.00002934,
    "vz": -0.00002544,
    "rarad": 0.274670080257595,
    "decrad": 1.20824012254278,
    "pmrarad": 0.00000108540086802777,
    "pmdecrad": -7.19366539e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 4897,
    "lum": 0.483949449301662,
    "var": "V966",
    "var_min": 7.702,
    "var_max": 7.642,
    "ra": 1.049162,
    "dec": 69.22706,
    "pmra": 223.88,
    "pmdec": -148.38,
    "rv": -19.8,
    "Asset Name": "ASTRID 003175",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Neko Ninja",
    "Hair": "Middle Part",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000209,
    "y_1": 0.00002934,
    "z_1": -0.00002544,
    "Distance in Parsecs_1": 25.4972,
    "HYG Star ID_1": 4897,
    "Hipparcos Catalogue_1": 4907,
    "Henry Draper Catalogue_1": 5996
  },
  {
    "Astrid #": "ASTRID 003176",
    "HYG Star ID": 26436,
    "Hipparcos Catalogue": 26501,
    "Henry Draper Catalogue": 37706,
    "Gliese Catalog": "NN 3360A",
    "Distance in Parsecs": 25.4972,
    "mag": 7.33,
    "Absolute Visual Magnitude": 5.298,
    "Spectral Type": "G5",
    "Color Index": 0.769,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.68234,
    "y": 17.597708,
    "z": -18.373972,
    "vx": 0.00001244,
    "vy": -0.00003468,
    "vz": -0.00004826,
    "rarad": 1.47548843775009,
    "decrad": -0.804702148553475,
    "pmrarad": -6.15228560625e-7,
    "pmdecrad": -0.00000225457754,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 26436,
    "base": "NN 3360",
    "lum": 0.66191161194112,
    "ra": 5.635951,
    "dec": -46.106037,
    "pmra": -126.9,
    "pmdec": -465.04,
    "rv": 11.4,
    "Asset Name": "ASTRID 003176",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Windy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00001244,
    "y_1": -0.00003468,
    "z_1": -0.00004826,
    "Distance in Parsecs_1": 25.4972,
    "HYG Star ID_1": 26436,
    "Hipparcos Catalogue_1": 26501,
    "Henry Draper Catalogue_1": 37706,
    "Gliese Catalog_1": "NN 3360A"
  },
  {
    "Astrid #": "ASTRID 003177",
    "HYG Star ID": 85081,
    "Hipparcos Catalogue": 85340,
    "Henry Draper Catalogue": 157792,
    "Harvard Revised Catalogue": 6486,
    "Gliese Catalog": "Gl 673.1",
    "Distance in Parsecs": 25.4972,
    "mag": 4.16,
    "Absolute Visual Magnitude": 2.128,
    "Spectral Type": "A3IV:m",
    "Color Index": 0.283,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -3.40098,
    "y": -23.010733,
    "z": -10.441731,
    "vx": 0.0000057,
    "vy": 0.00004026,
    "vz": 0.00000231,
    "rarad": 4.56565159643024,
    "decrad": -0.421938747550675,
    "pmrarad": -9.55082950694444e-9,
    "pmdecrad": -5.7057722e-7,
    "flam": 44,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 85081,
    "lum": 12.2687410463833,
    "bf": "44    Oph",
    "ra": 17.439504,
    "dec": -24.175309,
    "pmra": -1.97,
    "pmdec": -117.69,
    "rv": -37.2,
    "Asset Name": "ASTRID 003177",
    "Astrium Color": "White",
    "Background": "Velvet Nothingness",
    "Expression": "Guilty Laugh",
    "Hair": "Sweetheart",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.0000057,
    "y_1": 0.00004026,
    "z_1": 0.00000231,
    "Distance in Parsecs_1": 25.4972,
    "HYG Star ID_1": 85081,
    "Hipparcos Catalogue_1": 85340,
    "Henry Draper Catalogue_1": 157792,
    "Harvard Revised Catalogue_1": 6486,
    "Gliese Catalog_1": "Gl 673.1"
  },
  {
    "Astrid #": "ASTRID 003178",
    "HYG Star ID": 43544,
    "Hipparcos Catalogue": 43667,
    "Gliese Catalog": "GJ 1115",
    "Distance in Parsecs": 25.5037,
    "mag": 9.27,
    "Absolute Visual Magnitude": 7.237,
    "Spectral Type": "M0V:",
    "Color Index": 1.139,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.327754,
    "y": 15.126356,
    "z": 14.709451,
    "vx": -0.00002216,
    "vy": 0.00005874,
    "vz": -0.00000272,
    "rarad": 2.32908958799266,
    "decrad": 0.614742862392102,
    "pmrarad": -9.53143695972222e-7,
    "pmdecrad": -0.000001396069474,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 43544,
    "lum": 0.110968572677412,
    "ra": 8.896467,
    "dec": 35.222172,
    "pmra": -196.6,
    "pmdec": -287.96,
    "rv": 44.7,
    "Asset Name": "ASTRID 003178",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Curly",
    "Outfit": "Boxer",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00002216,
    "y_1": 0.00005874,
    "z_1": -0.00000272,
    "Distance in Parsecs_1": 25.5037,
    "HYG Star ID_1": 43544,
    "Hipparcos Catalogue_1": 43667,
    "Gliese Catalog_1": "GJ 1115"
  },
  {
    "Astrid #": "ASTRID 003179",
    "HYG Star ID": 57828,
    "Hipparcos Catalogue": 58001,
    "Henry Draper Catalogue": 103287,
    "Harvard Revised Catalogue": 4554,
    "Proper Name": "Phad",
    "Distance in Parsecs": 25.5037,
    "mag": 2.41,
    "Absolute Visual Magnitude": 0.377,
    "Spectral Type": "A0V SB",
    "Color Index": 0.044,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -15.094867,
    "y": 0.406425,
    "z": 20.552678,
    "vx": 0.00000862,
    "vy": -0.00001356,
    "vz": -0.0000099,
    "rarad": 3.11467124530819,
    "decrad": 0.937150353420552,
    "pmrarad": 5.22435222166666e-7,
    "pmdecrad": 5.4105206e-8,
    "bayer": "Gam",
    "flam": 64,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 57828,
    "lum": 61.5460237371316,
    "bf": "64Gam UMa",
    "ra": 11.897168,
    "dec": 53.69476,
    "pmra": 107.76,
    "pmdec": 11.16,
    "rv": -13,
    "Asset Name": "ASTRID 003179",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Guilty Grin",
    "Hair": "Astro Helmet",
    "Outfit": "Astro",
    "Special": "V1 Flight System",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "Phad",
    "Constellation": "Ursa Major",
    "x_1": 0.00000862,
    "y_1": -0.00001356,
    "z_1": -0.0000099,
    "Distance in Parsecs_1": 25.5037,
    "HYG Star ID_1": 57828,
    "Hipparcos Catalogue_1": 58001,
    "Henry Draper Catalogue_1": 103287,
    "Harvard Revised Catalogue_1": 4554
  },
  {
    "Astrid #": "ASTRID 003180",
    "HYG Star ID": 55788,
    "Hipparcos Catalogue": 55955,
    "Gliese Catalog": "Gl 429.2",
    "Distance in Parsecs": 25.5102,
    "mag": 12.4,
    "Absolute Visual Magnitude": 10.366,
    "Spectral Type": "M",
    "Color Index": 1.4,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -24.938337,
    "y": 3.503972,
    "z": -4.070851,
    "vx": 0.00000742,
    "vy": -0.00006801,
    "vz": -0.00010401,
    "rarad": 3.00200072309428,
    "decrad": -0.160262547890394,
    "pmrarad": 0.000002599667917875,
    "pmdecrad": -0.000004130273188,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 55788,
    "lum": 0.0062172738929396,
    "var_min": 12.542,
    "var_max": 12.172,
    "ra": 11.466798,
    "dec": -9.182368,
    "pmra": 536.22,
    "pmdec": -851.93,
    "rv": 0,
    "Asset Name": "ASTRID 003180",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Manbun",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000742,
    "y_1": -0.00006801,
    "z_1": -0.00010401,
    "Distance in Parsecs_1": 25.5102,
    "HYG Star ID_1": 55788,
    "Hipparcos Catalogue_1": 55955,
    "Gliese Catalog_1": "Gl 429.2"
  },
  {
    "Astrid #": "ASTRID 003181",
    "HYG Star ID": 118393,
    "Gliese Catalog": "NN 3365",
    "Distance in Parsecs": 25.5102,
    "mag": 12.79,
    "Absolute Visual Magnitude": 10.756,
    "Spectral Type": "M4",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.068096,
    "y": 18.281405,
    "z": 17.76006,
    "vx": 0.00007413,
    "vy": 0.00002536,
    "vz": -0.00003054,
    "rarad": 1.5124933139641,
    "decrad": 0.77008249732145,
    "pmrarad": -0.00000284348071782638,
    "pmdecrad": -0.000001668253571,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118393,
    "lum": 0.00434110209707344,
    "ra": 5.777299,
    "dec": 44.122477,
    "pmra": -586.51,
    "pmdec": -344.1,
    "rv": 0,
    "Asset Name": "ASTRID 003181",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Scared",
    "Hair": "Frizzy",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00007413,
    "y_1": 0.00002536,
    "z_1": -0.00003054,
    "Distance in Parsecs_1": 25.5102,
    "HYG Star ID_1": 118393,
    "Gliese Catalog_1": "NN 3365"
  },
  {
    "Astrid #": "ASTRID 003182",
    "HYG Star ID": 28703,
    "Hipparcos Catalogue": 28774,
    "Henry Draper Catalogue": 250962,
    "Distance in Parsecs": 25.5232,
    "mag": 9.58,
    "Absolute Visual Magnitude": 7.545,
    "Spectral Type": "A0",
    "Color Index": 0.095,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -0.437538,
    "y": 22.046102,
    "z": 12.853469,
    "vx": 3e-7,
    "vy": 4.4e-7,
    "vz": -7.5e-7,
    "rarad": 1.59064025464385,
    "decrad": 0.527760069051626,
    "pmrarad": -1.19264165416666e-8,
    "pmdecrad": -3.3936957e-8,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 28703,
    "lum": 0.0835603018231247,
    "ra": 6.075798,
    "dec": 30.238425,
    "pmra": -2.46,
    "pmdec": -7,
    "rv": 0,
    "Asset Name": "ASTRID 003182",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Lovestruck",
    "Hair": "Little Crown",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 3e-7,
    "y_1": 4.4e-7,
    "z_1": -7.5e-7,
    "Distance in Parsecs_1": 25.5232,
    "HYG Star ID_1": 28703,
    "Hipparcos Catalogue_1": 28774,
    "Henry Draper Catalogue_1": 250962
  },
  {
    "Astrid #": "ASTRID 003183",
    "HYG Star ID": 40057,
    "Hipparcos Catalogue": 40170,
    "Distance in Parsecs": 25.5232,
    "mag": 10.2,
    "Absolute Visual Magnitude": 8.165,
    "Spectral Type": "K5",
    "Color Index": 1.319,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.589876,
    "y": 13.196905,
    "z": 20.087047,
    "vx": 0.00000161,
    "vy": 0.00002514,
    "vz": -0.00001583,
    "rarad": 2.14780776349189,
    "decrad": 0.905949545964797,
    "pmrarad": -5.90115211972222e-7,
    "pmdecrad": -0.000001005261166,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 40057,
    "lum": 0.047206304126359,
    "ra": 8.204021,
    "dec": 51.907085,
    "pmra": -121.72,
    "pmdec": -207.35,
    "rv": 0,
    "Asset Name": "ASTRID 003183",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Medium Bob",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000161,
    "y_1": 0.00002514,
    "z_1": -0.00001583,
    "Distance in Parsecs_1": 25.5232,
    "HYG Star ID_1": 40057,
    "Hipparcos Catalogue_1": 40170
  },
  {
    "Astrid #": "ASTRID 003184",
    "HYG Star ID": 8175,
    "Hipparcos Catalogue": 8190,
    "Distance in Parsecs": 25.5297,
    "mag": 11.42,
    "Absolute Visual Magnitude": 9.385,
    "Spectral Type": "M:",
    "Color Index": 1.33,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 15.740581,
    "y": 7.788339,
    "z": 18.529476,
    "vx": -0.00003898,
    "vy": 0.00003528,
    "vz": 0.00001829,
    "rarad": 0.459466184584437,
    "decrad": 0.812197746921545,
    "pmrarad": 0.00000191583822145138,
    "pmdecrad": 0.000001041476748,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 8175,
    "lum": 0.0153461698279929,
    "var_min": 11.513,
    "var_max": 11.333,
    "ra": 1.755032,
    "dec": 46.535503,
    "pmra": 395.17,
    "pmdec": 214.82,
    "rv": 0,
    "Asset Name": "ASTRID 003184",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Audiophile",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00003898,
    "y_1": 0.00003528,
    "z_1": 0.00001829,
    "Distance in Parsecs_1": 25.5297,
    "HYG Star ID_1": 8175,
    "Hipparcos Catalogue_1": 8190
  },
  {
    "Astrid #": "ASTRID 003185",
    "HYG Star ID": 26048,
    "Hipparcos Catalogue": 26113,
    "Gliese Catalog": "NN 3352",
    "Distance in Parsecs": 25.5297,
    "mag": 11.07,
    "Absolute Visual Magnitude": 9.035,
    "Spectral Type": "M3",
    "Color Index": 1.499,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.800286,
    "y": 15.889912,
    "z": 19.900634,
    "vx": 0.00000878,
    "vy": 0.00002059,
    "vz": -0.00001723,
    "rarad": 1.45798130927884,
    "decrad": 0.893881808919477,
    "pmrarad": -2.50939561055555e-7,
    "pmdecrad": -0.000001077837774,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 26048,
    "lum": 0.021183611352485,
    "var_min": 11.154,
    "var_max": 10.994,
    "ra": 5.569078,
    "dec": 51.215655,
    "pmra": -51.76,
    "pmdec": -222.32,
    "rv": 0,
    "Asset Name": "ASTRID 003185",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Slick",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000878,
    "y_1": 0.00002059,
    "z_1": -0.00001723,
    "Distance in Parsecs_1": 25.5297,
    "HYG Star ID_1": 26048,
    "Hipparcos Catalogue_1": 26113,
    "Gliese Catalog_1": "NN 3352"
  },
  {
    "Astrid #": "ASTRID 003186",
    "HYG Star ID": 895,
    "Hipparcos Catalogue": 897,
    "Gliese Catalog": "NN 3011",
    "Distance in Parsecs": 25.5428,
    "mag": 10.86,
    "Absolute Visual Magnitude": 8.824,
    "Spectral Type": "M2",
    "Color Index": 1.418,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 25.383037,
    "y": 1.227767,
    "z": -2.574146,
    "vx": -0.00000669,
    "vy": 0.0000314,
    "vz": 0.00000179,
    "rarad": 0.0483318368874768,
    "decrad": -0.100949314576428,
    "pmrarad": 0.00000124044428306944,
    "pmdecrad": 4.9305551e-8,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 895,
    "lum": 0.0257276429534677,
    "var_min": 10.926,
    "var_max": 10.796,
    "ra": 0.184614,
    "dec": -5.78397,
    "pmra": 255.86,
    "pmdec": 10.17,
    "rv": -5.2,
    "Asset Name": "ASTRID 003186",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Heartbreaker",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000669,
    "y_1": 0.0000314,
    "z_1": 0.00000179,
    "Distance in Parsecs_1": 25.5428,
    "HYG Star ID_1": 895,
    "Hipparcos Catalogue_1": 897,
    "Gliese Catalog_1": "NN 3011"
  },
  {
    "Astrid #": "ASTRID 003187",
    "HYG Star ID": 27009,
    "Hipparcos Catalogue": 27075,
    "Henry Draper Catalogue": 38382,
    "Harvard Revised Catalogue": 1980,
    "Distance in Parsecs": 25.5428,
    "mag": 6.34,
    "Absolute Visual Magnitude": 4.304,
    "Spectral Type": "F8/G0V",
    "Color Index": 0.58,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.623586,
    "y": 23.928324,
    "z": -8.789271,
    "vx": 0.00000483,
    "vy": 0.00003783,
    "vz": -0.00000907,
    "rarad": 1.50304813423736,
    "decrad": -0.351274966294655,
    "pmrarad": -8.84300153333333e-8,
    "pmdecrad": 1.79623468e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 27009,
    "lum": 1.65348401217115,
    "ra": 5.741221,
    "dec": -20.126573,
    "pmra": -18.24,
    "pmdec": 37.05,
    "rv": 38,
    "Asset Name": "ASTRID 003187",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Serious Slumber",
    "Hair": "Frizzy",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00000483,
    "y_1": 0.00003783,
    "z_1": -0.00000907,
    "Distance in Parsecs_1": 25.5428,
    "HYG Star ID_1": 27009,
    "Hipparcos Catalogue_1": 27075,
    "Henry Draper Catalogue_1": 38382,
    "Harvard Revised Catalogue_1": 1980
  },
  {
    "Astrid #": "ASTRID 003188",
    "HYG Star ID": 17107,
    "Hipparcos Catalogue": 17147,
    "Henry Draper Catalogue": 22879,
    "Gliese Catalog": "Gl 147.1",
    "Distance in Parsecs": 25.5624,
    "mag": 6.68,
    "Absolute Visual Magnitude": 4.642,
    "Spectral Type": "F9V",
    "Color Index": 0.554,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.605935,
    "y": 20.930839,
    "z": -1.434556,
    "vx": -6.4e-7,
    "vy": 0.00014842,
    "vz": -0.00003342,
    "rarad": 0.961535617626968,
    "decrad": -0.0561469185593675,
    "pmrarad": 0.0000033436145106875,
    "pmdecrad": -0.000001039149642,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17107,
    "lum": 1.21115576298273,
    "ra": 3.672796,
    "dec": -3.216981,
    "pmra": 689.67,
    "pmdec": -214.34,
    "rv": 120.3,
    "Asset Name": "ASTRID 003188",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Wavy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -6.4e-7,
    "y_1": 0.00014842,
    "z_1": -0.00003342,
    "Distance in Parsecs_1": 25.5624,
    "HYG Star ID_1": 17107,
    "Hipparcos Catalogue_1": 17147,
    "Henry Draper Catalogue_1": 22879,
    "Gliese Catalog_1": "Gl 147.1"
  },
  {
    "Astrid #": "ASTRID 003189",
    "HYG Star ID": 76001,
    "Hipparcos Catalogue": 76233,
    "Henry Draper Catalogue": 138763,
    "Harvard Revised Catalogue": 5779,
    "Distance in Parsecs": 25.5624,
    "mag": 6.52,
    "Absolute Visual Magnitude": 4.482,
    "Spectral Type": "F7V",
    "Color Index": 0.577,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.098887,
    "y": -20.469684,
    "z": -2.536639,
    "vx": 0.00001374,
    "vy": 0.00003784,
    "vz": 0.00000452,
    "rarad": 4.07685707167408,
    "decrad": -0.0993981390466037,
    "pmrarad": -4.45980104743055e-7,
    "pmdecrad": 2.5937531e-8,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 76001,
    "lum": 1.40345987292117,
    "ra": 15.572447,
    "dec": -5.695094,
    "pmra": -91.99,
    "pmdec": 5.35,
    "rv": -38,
    "Asset Name": "ASTRID 003189",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Prince",
    "Outfit": "Astro",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00001374,
    "y_1": 0.00003784,
    "z_1": 0.00000452,
    "Distance in Parsecs_1": 25.5624,
    "HYG Star ID_1": 76001,
    "Hipparcos Catalogue_1": 76233,
    "Henry Draper Catalogue_1": 138763,
    "Harvard Revised Catalogue_1": 5779
  },
  {
    "Astrid #": "ASTRID 003190",
    "HYG Star ID": 117578,
    "Hipparcos Catalogue": 117946,
    "Henry Draper Catalogue": 224129,
    "Distance in Parsecs": 25.5754,
    "mag": 8.77,
    "Absolute Visual Magnitude": 6.731,
    "Spectral Type": "K0",
    "Color Index": 1.02,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 23.675963,
    "y": -0.4708,
    "z": 9.660653,
    "vx": 0.00000745,
    "vy": 0.00002525,
    "vz": -0.00001702,
    "rarad": 6.26330207728536,
    "decrad": 0.387345863784906,
    "pmrarad": 9.92946899145833e-7,
    "pmdecrad": -7.18881725e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 117578,
    "lum": 0.176847937828179,
    "ra": 23.924052,
    "dec": 22.193283,
    "pmra": 204.81,
    "pmdec": -148.28,
    "rv": 0,
    "Asset Name": "ASTRID 003190",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Upset",
    "Hair": "Charmer",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000745,
    "y_1": 0.00002525,
    "z_1": -0.00001702,
    "Distance in Parsecs_1": 25.5754,
    "HYG Star ID_1": 117578,
    "Hipparcos Catalogue_1": 117946,
    "Henry Draper Catalogue_1": 224129
  },
  {
    "Astrid #": "ASTRID 003191",
    "HYG Star ID": 119006,
    "Gliese Catalog": "NN 3889",
    "Distance in Parsecs": 25.5754,
    "mag": 15.9,
    "Absolute Visual Magnitude": 13.861,
    "Spectral Type": "DA7",
    "Color Index": 0.39,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -17.479193,
    "y": -18.389484,
    "z": -3.225802,
    "vx": -0.00001778,
    "vy": 0.0000172,
    "vz": -0.00000172,
    "rarad": 3.95236418541129,
    "decrad": -0.126465907817799,
    "pmrarad": -9.67251774076388e-7,
    "pmdecrad": -6.7636356e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119006,
    "lum": 0.000248656605130793,
    "ra": 15.096919,
    "dec": -7.245963,
    "pmra": -199.51,
    "pmdec": -13.95,
    "rv": 0,
    "Asset Name": "ASTRID 003191",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Braided Bun",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001778,
    "y_1": 0.0000172,
    "z_1": -0.00000172,
    "Distance in Parsecs_1": 25.5754,
    "HYG Star ID_1": 119006,
    "Gliese Catalog_1": "NN 3889"
  },
  {
    "Astrid #": "ASTRID 003192",
    "HYG Star ID": 119499,
    "Gliese Catalog": "NN 4277A",
    "Distance in Parsecs": 25.5754,
    "mag": 13.68,
    "Absolute Visual Magnitude": 11.641,
    "Spectral Type": "dM",
    "Color Index": 1.59,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 23.432098,
    "y": -10.160837,
    "z": 1.339887,
    "vx": -0.00002172,
    "vy": -0.00005728,
    "vz": -0.00005425,
    "rarad": 5.87402874810398,
    "decrad": 0.0524136695043,
    "pmrarad": -0.00000239279792038194,
    "pmdecrad": -0.000002124409914,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119499,
    "base": "NN 4277",
    "lum": 0.00192132131140372,
    "ra": 22.437137,
    "dec": 3.003082,
    "pmra": -493.55,
    "pmdec": -438.19,
    "rv": 0,
    "Asset Name": "ASTRID 003192",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Pompadour",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002172,
    "y_1": -0.00005728,
    "z_1": -0.00005425,
    "Distance in Parsecs_1": 25.5754,
    "HYG Star ID_1": 119499,
    "Gliese Catalog_1": "NN 4277A"
  },
  {
    "Astrid #": "ASTRID 003193",
    "HYG Star ID": 119500,
    "Gliese Catalog": "NN 4278B",
    "Distance in Parsecs": 25.5754,
    "mag": 17.7,
    "Absolute Visual Magnitude": 15.661,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.432171,
    "y": -10.160865,
    "z": 1.338401,
    "vx": -0.00002172,
    "vy": -0.00005728,
    "vz": -0.00005425,
    "rarad": 5.87402886310398,
    "decrad": 0.0523554915043,
    "pmrarad": -0.00000239279792038194,
    "pmdecrad": -0.000002124409914,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 119500,
    "base": "NN 4278",
    "lum": 0.0000473805393338825,
    "ra": 22.437138,
    "dec": 2.999749,
    "pmra": -493.55,
    "pmdec": -438.19,
    "rv": 0,
    "Asset Name": "ASTRID 003193",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Pompadour",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002172,
    "y_1": -0.00005728,
    "z_1": -0.00005425,
    "Distance in Parsecs_1": 25.5754,
    "HYG Star ID_1": 119500,
    "Gliese Catalog_1": "NN 4278B"
  },
  {
    "Astrid #": "ASTRID 003194",
    "HYG Star ID": 117427,
    "Hipparcos Catalogue": 117795,
    "Distance in Parsecs": 25.5885,
    "mag": 10.47,
    "Absolute Visual Magnitude": 8.43,
    "Spectral Type": "K8",
    "Color Index": 1.21,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.81005,
    "y": -0.372874,
    "z": 22.148023,
    "vx": -0.00000297,
    "vy": 0.00001061,
    "vz": 0.0000019,
    "rarad": 6.25408196189329,
    "decrad": 1.04623946739177,
    "pmrarad": 4.11073519743055e-7,
    "pmdecrad": 1.4815906e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 117427,
    "lum": 0.0369828179780266,
    "var_min": 10.532,
    "var_max": 10.402,
    "ra": 23.888833,
    "dec": 59.945106,
    "pmra": 84.79,
    "pmdec": 30.56,
    "rv": 0,
    "Asset Name": "ASTRID 003194",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Good Boy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000297,
    "y_1": 0.00001061,
    "z_1": 0.0000019,
    "Distance in Parsecs_1": 25.5885,
    "HYG Star ID_1": 117427,
    "Hipparcos Catalogue_1": 117795
  },
  {
    "Astrid #": "ASTRID 003195",
    "HYG Star ID": 117447,
    "Hipparcos Catalogue": 117815,
    "Henry Draper Catalogue": 223913,
    "Distance in Parsecs": 25.5951,
    "mag": 6.64,
    "Absolute Visual Magnitude": 4.599,
    "Spectral Type": "G0V",
    "Color Index": 0.589,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.428049,
    "y": -0.288337,
    "z": -23.372672,
    "vx": 0.00000954,
    "vy": -0.00000548,
    "vz": 0.00000432,
    "rarad": 6.25554479803708,
    "decrad": -1.1509946176845,
    "pmrarad": -2.03767189937499e-7,
    "pmdecrad": 4.14321771e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 117447,
    "lum": 1.26008545908823,
    "ra": 23.894421,
    "dec": -65.947134,
    "pmra": -42.03,
    "pmdec": 85.46,
    "rv": 0,
    "Asset Name": "ASTRID 003195",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Mortified",
    "Hair": "Bob",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": 0.00000954,
    "y_1": -0.00000548,
    "z_1": 0.00000432,
    "Distance in Parsecs_1": 25.5951,
    "HYG Star ID_1": 117447,
    "Hipparcos Catalogue_1": 117815,
    "Henry Draper Catalogue_1": 223913
  },
  {
    "Astrid #": "ASTRID 003196",
    "HYG Star ID": 9694,
    "Hipparcos Catalogue": 9716,
    "Henry Draper Catalogue": 12786,
    "Gliese Catalog": "NN 3133",
    "Distance in Parsecs": 25.6016,
    "mag": 7.79,
    "Absolute Visual Magnitude": 5.749,
    "Spectral Type": "K0V",
    "Color Index": 0.832,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 21.07343,
    "y": 12.786259,
    "z": -6.918385,
    "vx": -0.0000026,
    "vy": 0.00000136,
    "vz": -0.0000054,
    "rarad": 0.545366374180816,
    "decrad": -0.27363453031704,
    "pmrarad": 9.80778075763888e-8,
    "pmdecrad": -2.18893376e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 9694,
    "lum": 0.436918063385288,
    "var": "FN",
    "var_min": 7.821,
    "var_max": 7.771,
    "ra": 2.083146,
    "dec": -15.678104,
    "pmra": 20.23,
    "pmdec": -45.15,
    "rv": 0,
    "Asset Name": "ASTRID 003196",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Ponytail",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.0000026,
    "y_1": 0.00000136,
    "z_1": -0.0000054,
    "Distance in Parsecs_1": 25.6016,
    "HYG Star ID_1": 9694,
    "Hipparcos Catalogue_1": 9716,
    "Henry Draper Catalogue_1": 12786,
    "Gliese Catalog_1": "NN 3133"
  },
  {
    "Astrid #": "ASTRID 003197",
    "HYG Star ID": 8526,
    "Hipparcos Catalogue": 8543,
    "Henry Draper Catalogue": 11130,
    "Distance in Parsecs": 25.6148,
    "mag": 8.06,
    "Absolute Visual Magnitude": 6.018,
    "Spectral Type": "K1V",
    "Color Index": 0.758,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.775766,
    "y": 10.30902,
    "z": 12.599384,
    "vx": -0.00002323,
    "vy": -0.00001713,
    "vz": -0.00002521,
    "rarad": 0.480538811048509,
    "decrad": 0.514253837830786,
    "pmrarad": -1.73708741743055e-7,
    "pmdecrad": -3.09505053e-7,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 8526,
    "lum": 0.341035822653349,
    "ra": 1.835523,
    "dec": 29.464575,
    "pmra": -35.83,
    "pmdec": -63.84,
    "rv": -36.4,
    "Asset Name": "ASTRID 003197",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Antlers",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.00002323,
    "y_1": -0.00001713,
    "z_1": -0.00002521,
    "Distance in Parsecs_1": 25.6148,
    "HYG Star ID_1": 8526,
    "Hipparcos Catalogue_1": 8543,
    "Henry Draper Catalogue_1": 11130
  },
  {
    "Astrid #": "ASTRID 003198",
    "HYG Star ID": 28836,
    "Hipparcos Catalogue": 28908,
    "Henry Draper Catalogue": 41330,
    "Harvard Revised Catalogue": 2141,
    "Gliese Catalog": "Gl 226.3",
    "Distance in Parsecs": 25.6213,
    "mag": 6.12,
    "Absolute Visual Magnitude": 4.077,
    "Spectral Type": "G0V",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.559736,
    "y": 20.8802,
    "z": 14.837391,
    "vx": 0.00001519,
    "vy": 0.00000931,
    "vz": -0.00003867,
    "rarad": 1.59759814159047,
    "decrad": 0.617632582020517,
    "pmrarad": -6.02332516722222e-7,
    "pmdecrad": -0.000001431654798,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 28836,
    "lum": 2.03798038628705,
    "ra": 6.102375,
    "dec": 35.38774,
    "pmra": -124.24,
    "pmdec": -295.3,
    "rv": -14.8,
    "Asset Name": "ASTRID 003198",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "Innocent",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00001519,
    "y_1": 0.00000931,
    "z_1": -0.00003867,
    "Distance in Parsecs_1": 25.6213,
    "HYG Star ID_1": 28836,
    "Hipparcos Catalogue_1": 28908,
    "Henry Draper Catalogue_1": 41330,
    "Harvard Revised Catalogue_1": 2141,
    "Gliese Catalog_1": "Gl 226.3"
  },
  {
    "Astrid #": "ASTRID 003199",
    "HYG Star ID": 111579,
    "Hipparcos Catalogue": 111932,
    "Distance in Parsecs": 25.6213,
    "mag": 11.76,
    "Absolute Visual Magnitude": 9.717,
    "Spectral Type": "M0V:",
    "Color Index": 1.433,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.860922,
    "y": -8.643048,
    "z": 3.522094,
    "vx": 0.00001076,
    "vy": 0.00002082,
    "vz": -0.00002179,
    "rarad": 5.93566040100596,
    "decrad": 0.137904098357578,
    "pmrarad": 9.07086396319444e-7,
    "pmdecrad": -8.58701991e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 111579,
    "lum": 0.0113031632473869,
    "var": "QQ",
    "var_min": 11.998,
    "var_max": 11.428,
    "ra": 22.672553,
    "dec": 7.901323,
    "pmra": 187.1,
    "pmdec": -177.12,
    "rv": 0,
    "Asset Name": "ASTRID 003199",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Innocent",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001076,
    "y_1": 0.00002082,
    "z_1": -0.00002179,
    "Distance in Parsecs_1": 25.6213,
    "HYG Star ID_1": 111579,
    "Hipparcos Catalogue_1": 111932
  },
  {
    "Astrid #": "ASTRID 003200",
    "HYG Star ID": 7571,
    "Hipparcos Catalogue": 7585,
    "Henry Draper Catalogue": 9986,
    "Distance in Parsecs": 25.6279,
    "mag": 6.77,
    "Absolute Visual Magnitude": 4.726,
    "Spectral Type": "G5V",
    "Color Index": 0.648,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.818563,
    "y": 10.360726,
    "z": 5.362628,
    "vx": -0.00000648,
    "vy": 0.00001387,
    "vz": 8e-7,
    "rarad": 0.426215149291476,
    "decrad": 0.210807533555683,
    "pmrarad": 5.97290454444444e-7,
    "pmdecrad": 3.1949221e-8,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 7571,
    "lum": 1.12098551287531,
    "ra": 1.628022,
    "dec": 12.078382,
    "pmra": 123.2,
    "pmdec": 6.59,
    "rv": 0,
    "Asset Name": "ASTRID 003200",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Medium Bob",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000648,
    "y_1": 0.00001387,
    "z_1": 8e-7,
    "Distance in Parsecs_1": 25.6279,
    "HYG Star ID_1": 7571,
    "Hipparcos Catalogue_1": 7585,
    "Henry Draper Catalogue_1": 9986
  },
  {
    "Astrid #": "ASTRID 003201",
    "HYG Star ID": 33115,
    "Hipparcos Catalogue": 33202,
    "Henry Draper Catalogue": 50635,
    "Harvard Revised Catalogue": 2564,
    "Gliese Catalog": "Wo 9220A",
    "Distance in Parsecs": 25.6279,
    "mag": 4.73,
    "Absolute Visual Magnitude": 2.686,
    "Spectral Type": "F0Vp",
    "Color Index": 0.321,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.893353,
    "y": 24.247254,
    "z": 5.842527,
    "vx": -0.00001247,
    "vy": 0.0000144,
    "vz": -0.00000596,
    "rarad": 1.80922537354382,
    "decrad": 0.229996472055082,
    "pmrarad": 3.40290722381944e-7,
    "pmdecrad": -3.77136562e-7,
    "flam": 38,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 33115,
    "base": "Wo 9220",
    "lum": 7.33837667333537,
    "bf": "38    Gem",
    "ra": 6.910732,
    "dec": 13.177827,
    "pmra": 70.19,
    "pmdec": -77.79,
    "rv": 14.8,
    "Asset Name": "ASTRID 003201",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Horns",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00001247,
    "y_1": 0.0000144,
    "z_1": -0.00000596,
    "Distance in Parsecs_1": 25.6279,
    "HYG Star ID_1": 33115,
    "Hipparcos Catalogue_1": 33202,
    "Henry Draper Catalogue_1": 50635,
    "Harvard Revised Catalogue_1": 2564,
    "Gliese Catalog_1": "Wo 9220A"
  },
  {
    "Astrid #": "ASTRID 003202",
    "HYG Star ID": 58199,
    "Hipparcos Catalogue": 58374,
    "Henry Draper Catalogue": 103949,
    "Distance in Parsecs": 25.6279,
    "mag": 8.7,
    "Absolute Visual Magnitude": 6.656,
    "Spectral Type": "K3V",
    "Color Index": 0.985,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.425352,
    "y": 0.184489,
    "z": -10.392695,
    "vx": 0.00000288,
    "vy": 0.00002141,
    "vz": -0.00000611,
    "rarad": 3.13371788591929,
    "decrad": -0.417550592836182,
    "pmrarad": -8.36352080326388e-7,
    "pmdecrad": -2.60781278e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 58199,
    "lum": 0.189495979474354,
    "ra": 11.969921,
    "dec": -23.923887,
    "pmra": -172.51,
    "pmdec": -53.79,
    "rv": 0,
    "Asset Name": "ASTRID 003202",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Unkempt",
    "Outfit": "Unit 01",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000288,
    "y_1": 0.00002141,
    "z_1": -0.00000611,
    "Distance in Parsecs_1": 25.6279,
    "HYG Star ID_1": 58199,
    "Hipparcos Catalogue_1": 58374,
    "Henry Draper Catalogue_1": 103949
  },
  {
    "Astrid #": "ASTRID 003203",
    "HYG Star ID": 26305,
    "Hipparcos Catalogue": 26369,
    "Distance in Parsecs": 25.6345,
    "mag": 9.84,
    "Absolute Visual Magnitude": 7.796,
    "Color Index": 1.171,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 1.725848,
    "y": 17.078035,
    "z": -19.039164,
    "vx": -4.7e-7,
    "vy": 0.00000208,
    "vz": 0.00000182,
    "rarad": 1.47008152892288,
    "decrad": -0.837118027248428,
    "pmrarad": 2.62769014861111e-8,
    "pmdecrad": 1.06028751e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 26305,
    "lum": 0.0663132021883552,
    "ra": 5.615298,
    "dec": -47.96333,
    "pmra": 5.42,
    "pmdec": 21.87,
    "rv": 0,
    "Asset Name": "ASTRID 003203",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Halo Boy",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": -4.7e-7,
    "y_1": 0.00000208,
    "z_1": 0.00000182,
    "Distance in Parsecs_1": 25.6345,
    "HYG Star ID_1": 26305,
    "Hipparcos Catalogue_1": 26369
  },
  {
    "Astrid #": "ASTRID 003204",
    "HYG Star ID": 75443,
    "Hipparcos Catalogue": 75672,
    "Gliese Catalog": "NN 3906",
    "Distance in Parsecs": 25.6345,
    "mag": 9.9,
    "Absolute Visual Magnitude": 7.856,
    "Spectral Type": "K5",
    "Color Index": 1.554,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.544871,
    "y": -19.831104,
    "z": 4.712947,
    "vx": -0.00004524,
    "vy": 0.00002784,
    "vz": -0.00003207,
    "rarad": 4.04756565471761,
    "decrad": 0.184903553163143,
    "pmrarad": -0.00000205900370131944,
    "pmdecrad": -0.000001272781355,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 75443,
    "lum": 0.0627480161997851,
    "var_min": 9.946,
    "var_max": 9.826,
    "ra": 15.460562,
    "dec": 10.594193,
    "pmra": -424.7,
    "pmdec": -262.53,
    "rv": 0,
    "Asset Name": "ASTRID 003204",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Medium Bob",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00004524,
    "y_1": 0.00002784,
    "z_1": -0.00003207,
    "Distance in Parsecs_1": 25.6345,
    "HYG Star ID_1": 75443,
    "Hipparcos Catalogue_1": 75672,
    "Gliese Catalog_1": "NN 3906"
  },
  {
    "Astrid #": "ASTRID 003205",
    "HYG Star ID": 117959,
    "Gliese Catalog": "Gl 4.2B",
    "Distance in Parsecs": 25.641,
    "mag": 11.5,
    "Absolute Visual Magnitude": 9.455,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.790086,
    "y": 0.463341,
    "z": -19.373673,
    "vx": -0.00000577,
    "vy": 0.00007304,
    "vz": -0.00000327,
    "rarad": 0.0275139859383927,
    "decrad": -0.85652997354405,
    "pmrarad": 0.00000285375876785416,
    "pmdecrad": -1.94550881e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 2,
    "comp_primary": 521,
    "base": "Gl   4.2",
    "lum": 0.0143879857825584,
    "ra": 0.105096,
    "dec": -49.075553,
    "pmra": 588.63,
    "pmdec": -40.13,
    "rv": 0,
    "Asset Name": "ASTRID 003205",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Mortified",
    "Hair": "Frizzy",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000577,
    "y_1": 0.00007304,
    "z_1": -0.00000327,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 117959,
    "Gliese Catalog_1": "Gl 4.2B"
  },
  {
    "Astrid #": "ASTRID 003206",
    "HYG Star ID": 117978,
    "Gliese Catalog": "NN 3026",
    "Distance in Parsecs": 25.641,
    "mag": 13.2,
    "Absolute Visual Magnitude": 11.155,
    "Spectral Type": "m",
    "Color Index": 1.58,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 24.323778,
    "y": 1.90526,
    "z": -7.885725,
    "vx": 7.1e-7,
    "vy": 0.00002599,
    "vz": 0.00000847,
    "rarad": 0.0781669350468461,
    "decrad": -0.3126103848525,
    "pmrarad": 0.00000100846093692361,
    "pmdecrad": 3.4724295e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 117978,
    "lum": 0.00300607630262822,
    "ra": 0.298576,
    "dec": -17.911256,
    "pmra": 208.01,
    "pmdec": 71.62,
    "rv": 0,
    "Asset Name": "ASTRID 003206",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Naughty Dreams",
    "Hair": "Middle Part",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 7.1e-7,
    "y_1": 0.00002599,
    "z_1": 0.00000847,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 117978,
    "Gliese Catalog_1": "NN 3026"
  },
  {
    "Astrid #": "ASTRID 003207",
    "HYG Star ID": 118005,
    "Gliese Catalog": "NN 3046",
    "Distance in Parsecs": 25.641,
    "mag": 12.83,
    "Absolute Visual Magnitude": 10.785,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.418608,
    "y": 2.163005,
    "z": 22.328467,
    "vx": 0.0000297,
    "vy": -0.00001367,
    "vz": -0.00001519,
    "rarad": 0.172482013114336,
    "decrad": 1.0568498149925,
    "pmrarad": -7.24117713277777e-7,
    "pmdecrad": -0.00000120514015,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118005,
    "lum": 0.00422668614265602,
    "ra": 0.658833,
    "dec": 60.553034,
    "pmra": -149.36,
    "pmdec": -248.58,
    "rv": 0,
    "Asset Name": "ASTRID 003207",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Windy",
    "Outfit": "Astro",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000297,
    "y_1": -0.00001367,
    "z_1": -0.00001519,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118005,
    "Gliese Catalog_1": "NN 3046"
  },
  {
    "Astrid #": "ASTRID 003208",
    "HYG Star ID": 118089,
    "Gliese Catalog": "NN 3116",
    "Distance in Parsecs": 25.641,
    "mag": 10.79,
    "Absolute Visual Magnitude": 8.745,
    "Spectral Type": "M0",
    "Color Index": 1.38,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.570903,
    "y": 11.285703,
    "z": 8.049217,
    "vx": -0.00001168,
    "vy": 0.00002777,
    "vz": -0.00000764,
    "rarad": 0.48202472640379,
    "decrad": 0.319318728307849,
    "pmrarad": 0.00000117072807580555,
    "pmdecrad": -3.13693843e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 118089,
    "lum": 0.0276694164541151,
    "ra": 1.841199,
    "dec": 18.295615,
    "pmra": 241.48,
    "pmdec": -64.7,
    "rv": 0,
    "Asset Name": "ASTRID 003208",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Grecian",
    "Outfit": "Toga",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00001168,
    "y_1": 0.00002777,
    "z_1": -0.00000764,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118089,
    "Gliese Catalog_1": "NN 3116"
  },
  {
    "Astrid #": "ASTRID 003209",
    "HYG Star ID": 118141,
    "Gliese Catalog": "NN 3156",
    "Distance in Parsecs": 25.641,
    "mag": 11.62,
    "Absolute Visual Magnitude": 9.575,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.603041,
    "y": 13.610859,
    "z": 11.230861,
    "vx": 0.00001595,
    "vy": -0.00001008,
    "vz": -0.00001421,
    "rarad": 0.631655207154152,
    "decrad": 0.4533772118488,
    "pmrarad": -6.84556916944444e-7,
    "pmdecrad": -6.16363024e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 118141,
    "lum": 0.0128824955169313,
    "ra": 2.412745,
    "dec": 25.976601,
    "pmra": -141.2,
    "pmdec": -127.13,
    "rv": 0,
    "Asset Name": "ASTRID 003209",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Frizzy",
    "Outfit": "River Spirit",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00001595,
    "y_1": -0.00001008,
    "z_1": -0.00001421,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118141,
    "Gliese Catalog_1": "NN 3156"
  },
  {
    "Astrid #": "ASTRID 003210",
    "HYG Star ID": 118154,
    "Gliese Catalog": "NN 3165",
    "Distance in Parsecs": 25.641,
    "mag": 13.76,
    "Absolute Visual Magnitude": 11.715,
    "Spectral Type": "M3.5",
    "Color Index": 1.63,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.397236,
    "y": 15.399167,
    "z": 6.638807,
    "vx": -0.00003405,
    "vy": 0.00004096,
    "vz": 0.0000045,
    "rarad": 0.670997229394101,
    "decrad": 0.2618974104387,
    "pmrarad": 0.00000207674788202777,
    "pmdecrad": 1.81688774e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118154,
    "lum": 0.00179473362683252,
    "ra": 2.563021,
    "dec": 15.005616,
    "pmra": 428.36,
    "pmdec": 37.48,
    "rv": 0,
    "Asset Name": "ASTRID 003210",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Pixie",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003405,
    "y_1": 0.00004096,
    "z_1": 0.0000045,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118154,
    "Gliese Catalog_1": "NN 3165"
  },
  {
    "Astrid #": "ASTRID 003211",
    "HYG Star ID": 118296,
    "Gliese Catalog": "NN 3280",
    "Distance in Parsecs": 25.641,
    "mag": 12.44,
    "Absolute Visual Magnitude": 10.395,
    "Spectral Type": "k-m",
    "Color Index": 1.51,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.730148,
    "y": 19.797874,
    "z": 13.758255,
    "vx": -0.00002258,
    "vy": 0.0000201,
    "vz": -0.00001459,
    "rarad": 1.15547180763911,
    "decrad": 0.5663701179048,
    "pmrarad": 0.00000112205278227777,
    "pmdecrad": -6.74181904e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118296,
    "lum": 0.00605340874753913,
    "ra": 4.413577,
    "dec": 32.450617,
    "pmra": 231.44,
    "pmdec": -139.06,
    "rv": 0,
    "Asset Name": "ASTRID 003211",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Knocked Out",
    "Hair": "Heartbreaker",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002258,
    "y_1": 0.0000201,
    "z_1": -0.00001459,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118296,
    "Gliese Catalog_1": "NN 3280"
  },
  {
    "Astrid #": "ASTRID 003212",
    "HYG Star ID": 118336,
    "Gliese Catalog": "NN 3316",
    "Distance in Parsecs": 25.641,
    "mag": 12.84,
    "Absolute Visual Magnitude": 10.795,
    "Spectral Type": "m",
    "Color Index": 1.54,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.841642,
    "y": 21.981662,
    "z": 11.289791,
    "vx": -0.00006347,
    "vy": 0.00003281,
    "vz": -0.00002539,
    "rarad": 1.26904128342346,
    "decrad": 0.4559353361109,
    "pmrarad": 0.00000274394846920833,
    "pmdecrad": -0.000001103057782,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118336,
    "lum": 0.00418793565117918,
    "ra": 4.847381,
    "dec": 26.123170000000002,
    "pmra": 565.98,
    "pmdec": -227.52,
    "rv": 0,
    "Asset Name": "ASTRID 003212",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Afro",
    "Outfit": "Iron Star",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00006347,
    "y_1": 0.00003281,
    "z_1": -0.00002539,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118336,
    "Gliese Catalog_1": "NN 3316"
  },
  {
    "Astrid #": "ASTRID 003213",
    "HYG Star ID": 118364,
    "Gliese Catalog": "NN 3342A",
    "Distance in Parsecs": 25.641,
    "mag": 13.44,
    "Absolute Visual Magnitude": 11.395,
    "Spectral Type": "m",
    "Color Index": 1.62,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.267886,
    "y": 13.089259,
    "z": 21.931458,
    "vx": -0.00001258,
    "vy": 0.00005501,
    "vz": -0.00003152,
    "rarad": 1.39919688449503,
    "decrad": 1.02618307333765,
    "pmrarad": 8.49829900645833e-7,
    "pmdecrad": -0.000002373473247,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118364,
    "base": "NN 3342",
    "lum": 0.00240990542868659,
    "ra": 5.344538,
    "dec": 58.795959,
    "pmra": 175.29,
    "pmdec": -489.56,
    "rv": 0,
    "Asset Name": "ASTRID 003213",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Nervous",
    "Hair": "Slick",
    "Outfit": "Unit 01",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001258,
    "y_1": 0.00005501,
    "z_1": -0.00003152,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118364,
    "Gliese Catalog_1": "NN 3342A"
  },
  {
    "Astrid #": "ASTRID 003214",
    "HYG Star ID": 118365,
    "Gliese Catalog": "NN 3343B",
    "Distance in Parsecs": 25.641,
    "mag": 14.03,
    "Absolute Visual Magnitude": 11.985,
    "Spectral Type": "m",
    "Color Index": 1.64,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.269186,
    "y": 13.090969,
    "z": 21.930304,
    "vx": -0.00001257,
    "vy": 0.00005501,
    "vz": -0.00003153,
    "rarad": 1.39912250549503,
    "decrad": 1.02609615433765,
    "pmrarad": 8.49829900645833e-7,
    "pmdecrad": -0.000002373473247,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 118365,
    "base": "NN 3343",
    "lum": 0.00139958732257261,
    "ra": 5.344254,
    "dec": 58.790979,
    "pmra": 175.29,
    "pmdec": -489.56,
    "rv": 0,
    "Asset Name": "ASTRID 003214",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Lion Mane",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001257,
    "y_1": 0.00005501,
    "z_1": -0.00003153,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118365,
    "Gliese Catalog_1": "NN 3343B"
  },
  {
    "Astrid #": "ASTRID 003215",
    "HYG Star ID": 118448,
    "Gliese Catalog": "Wo 9220B",
    "Distance in Parsecs": 25.641,
    "mag": 7.68,
    "Absolute Visual Magnitude": 5.635,
    "Spectral Type": "G6 V",
    "Color Index": 0.72,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.895769,
    "y": 24.260589,
    "z": 5.846633,
    "vx": -0.00001355,
    "vy": 0.00002179,
    "vz": -0.00000404,
    "rarad": 1.80919291636154,
    "decrad": 0.23003210420795,
    "pmrarad": 3.12947230798611e-7,
    "pmdecrad": -3.70295841e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 2,
    "comp_primary": 33115,
    "base": "Wo 9220",
    "lum": 0.485288500162121,
    "ra": 6.910608,
    "dec": 13.179869,
    "pmra": 64.55,
    "pmdec": -76.38,
    "rv": 22.3,
    "Asset Name": "ASTRID 003215",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Greasy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00001355,
    "y_1": 0.00002179,
    "z_1": -0.00000404,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118448,
    "Gliese Catalog_1": "Wo 9220B"
  },
  {
    "Astrid #": "ASTRID 003216",
    "HYG Star ID": 118601,
    "Gliese Catalog": "NN 3536",
    "Distance in Parsecs": 25.641,
    "mag": 11.92,
    "Absolute Visual Magnitude": 9.875,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.965494,
    "y": 1.696378,
    "z": 25.50774,
    "vx": 0.00008265,
    "vy": 0.00001357,
    "vz": -0.00002402,
    "rarad": 2.43066531901885,
    "decrad": 1.46936023903195,
    "pmrarad": -0.00000250415962280555,
    "pmdecrad": 0.000001991900639,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 118601,
    "lum": 0.0097723722095581,
    "ra": 9.284458,
    "dec": 84.18814,
    "pmra": -516.52,
    "pmdec": 410.86,
    "rv": -28.7,
    "Asset Name": "ASTRID 003216",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Round Brush",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00008265,
    "y_1": 0.00001357,
    "z_1": -0.00002402,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118601,
    "Gliese Catalog_1": "NN 3536"
  },
  {
    "Astrid #": "ASTRID 003217",
    "HYG Star ID": 118677,
    "Gliese Catalog": "NN 3595",
    "Distance in Parsecs": 25.641,
    "mag": 13.16,
    "Absolute Visual Magnitude": 11.115,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -15.174478,
    "y": 7.024094,
    "z": 19.438574,
    "vx": 0.00000947,
    "vy": 0.00004539,
    "vz": -0.00000901,
    "rarad": 2.70811930077831,
    "decrad": 0.86040256135765,
    "pmrarad": -0.00000176176443377083,
    "pmdecrad": -5.38632847e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118677,
    "lum": 0.00311888958409393,
    "ra": 10.344254,
    "dec": 49.297435,
    "pmra": -363.39,
    "pmdec": -111.1,
    "rv": 0,
    "Asset Name": "ASTRID 003217",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mortified",
    "Hair": "Lion Mane",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000947,
    "y_1": 0.00004539,
    "z_1": -0.00000901,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118677,
    "Gliese Catalog_1": "NN 3595"
  },
  {
    "Astrid #": "ASTRID 003218",
    "HYG Star ID": 118815,
    "Gliese Catalog": "NN 3724",
    "Distance in Parsecs": 25.641,
    "mag": 16.58,
    "Absolute Visual Magnitude": 14.535,
    "Spectral Type": "m",
    "Color Index": 1.88,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -23.394295,
    "y": -2.564435,
    "z": 10.177992,
    "vx": -0.00001621,
    "vy": 0.00001256,
    "vz": -0.00003408,
    "rarad": 3.25077719678355,
    "decrad": 0.408182796168549,
    "pmrarad": -5.55984328861111e-7,
    "pmdecrad": -0.000001448356629,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118815,
    "lum": 0.000133659551654644,
    "ra": 12.417054,
    "dec": 23.387151,
    "pmra": -114.68,
    "pmdec": -298.75,
    "rv": 0,
    "Asset Name": "ASTRID 003218",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Ponytail",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001621,
    "y_1": 0.00001256,
    "z_1": -0.00003408,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118815,
    "Gliese Catalog_1": "NN 3724"
  },
  {
    "Astrid #": "ASTRID 003219",
    "HYG Star ID": 118936,
    "Gliese Catalog": "NN 3818",
    "Distance in Parsecs": 25.641,
    "mag": 14.05,
    "Absolute Visual Magnitude": 12.005,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -22.276893,
    "y": -12.69644,
    "z": -0.035997,
    "vx": -0.00002299,
    "vy": 0.00004047,
    "vz": -0.00004837,
    "rarad": 3.65961411818272,
    "decrad": -0.0014038557743,
    "pmrarad": -0.00000181533634547222,
    "pmdecrad": -0.000001886395486,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118936,
    "lum": 0.00137404197501251,
    "ra": 13.978696,
    "dec": -0.080435,
    "pmra": -374.44,
    "pmdec": -389.1,
    "rv": 0,
    "Asset Name": "ASTRID 003219",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Sweetheart",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002299,
    "y_1": 0.00004047,
    "z_1": -0.00004837,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118936,
    "Gliese Catalog_1": "NN 3818"
  },
  {
    "Astrid #": "ASTRID 003220",
    "HYG Star ID": 118989,
    "Gliese Catalog": "NN 3870",
    "Distance in Parsecs": 25.641,
    "mag": 12.58,
    "Absolute Visual Magnitude": 10.535,
    "Spectral Type": "M2",
    "Color Index": 1.58,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.712242,
    "y": -17.530181,
    "z": 0.074803,
    "vx": 0.00000787,
    "vy": -0.00000856,
    "vz": -0.00003804,
    "rarad": 3.89438698158397,
    "decrad": 0.002917308386,
    "pmrarad": 4.53591679527777e-7,
    "pmdecrad": -0.00000148361228,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118989,
    "lum": 0.00532108259266793,
    "ra": 14.875463,
    "dec": 0.167149,
    "pmra": 93.56,
    "pmdec": -306.02,
    "rv": 0,
    "Asset Name": "ASTRID 003220",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Nervous Grin",
    "Hair": "Mohawk",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000787,
    "y_1": -0.00000856,
    "z_1": -0.00003804,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 118989,
    "Gliese Catalog_1": "NN 3870"
  },
  {
    "Astrid #": "ASTRID 003221",
    "HYG Star ID": 119122,
    "Gliese Catalog": "NN 3983",
    "Distance in Parsecs": 25.641,
    "mag": 13.22,
    "Absolute Visual Magnitude": 11.175,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.963691,
    "y": -22.391487,
    "z": 10.978006,
    "vx": -0.00000937,
    "vy": 0.0000109,
    "vz": 0.00001715,
    "rarad": 4.4520964708115,
    "decrad": 0.44243652191935,
    "pmrarad": -4.62463769881944e-7,
    "pmdecrad": 7.40058387e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119122,
    "lum": 0.00295120922666638,
    "ra": 17.005756,
    "dec": 25.349745,
    "pmra": -95.39,
    "pmdec": 152.65,
    "rv": 0,
    "Asset Name": "ASTRID 003221",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Goddess",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000937,
    "y_1": 0.0000109,
    "z_1": 0.00001715,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 119122,
    "Gliese Catalog_1": "NN 3983"
  },
  {
    "Astrid #": "ASTRID 003222",
    "HYG Star ID": 119288,
    "Gliese Catalog": "NN 4112",
    "Distance in Parsecs": 25.641,
    "mag": 12.8,
    "Absolute Visual Magnitude": 10.755,
    "Spectral Type": "M4",
    "Color Index": 1.55,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.234212,
    "y": -14.337281,
    "z": -20.323332,
    "vx": -0.00001032,
    "vy": 0.00002237,
    "vz": -0.00001895,
    "rarad": 5.12255288510617,
    "decrad": -0.9150789752562,
    "pmrarad": -2.11378764722222e-8,
    "pmdecrad": -0.000001211845124,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119288,
    "lum": 0.00434510224171571,
    "ra": 19.566711,
    "dec": -52.430163,
    "pmra": -4.36,
    "pmdec": -249.96,
    "rv": 0,
    "Asset Name": "ASTRID 003222",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Frizzy",
    "Outfit": "Spartan",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001032,
    "y_1": 0.00002237,
    "z_1": -0.00001895,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 119288,
    "Gliese Catalog_1": "NN 4112"
  },
  {
    "Astrid #": "ASTRID 003223",
    "HYG Star ID": 119304,
    "Gliese Catalog": "NN 4123",
    "Distance in Parsecs": 25.641,
    "mag": 15.21,
    "Absolute Visual Magnitude": 13.165,
    "Spectral Type": "k",
    "Color Index": 1.72,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.681572,
    "y": -22.530172,
    "z": 3.659667,
    "vx": -0.00002271,
    "vy": -0.00001395,
    "vz": -0.00001341,
    "rarad": 5.19071607872127,
    "decrad": 0.14321621107795,
    "pmrarad": -0.0000010367255745,
    "pmdecrad": -5.28238441e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119304,
    "lum": 0.00047206304126359,
    "ra": 19.827075,
    "dec": 8.205684,
    "pmra": -213.84,
    "pmdec": -108.96,
    "rv": 0,
    "Asset Name": "ASTRID 003223",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Pixie",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002271,
    "y_1": -0.00001395,
    "z_1": -0.00001341,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 119304,
    "Gliese Catalog_1": "NN 4123"
  },
  {
    "Astrid #": "ASTRID 003224",
    "HYG Star ID": 119364,
    "Gliese Catalog": "GJ 2149",
    "Distance in Parsecs": 25.641,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.355,
    "Spectral Type": "DA3",
    "Color Index": 0.1,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 6.287288,
    "y": -7.211845,
    "z": -23.789077,
    "vx": 0.00000153,
    "vy": 0.00002862,
    "vz": -0.00000827,
    "rarad": 5.42933851511448,
    "decrad": -1.18840481322955,
    "pmrarad": 7.78562289604166e-7,
    "pmdecrad": -8.64684591e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119364,
    "lum": 0.00250034536169642,
    "ra": 20.738545,
    "dec": -68.09058,
    "pmra": 160.59,
    "pmdec": -178.35,
    "rv": 0,
    "Asset Name": "ASTRID 003224",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "High Fade",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000153,
    "y_1": 0.00002862,
    "z_1": -0.00000827,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 119364,
    "Gliese Catalog_1": "GJ 2149"
  },
  {
    "Astrid #": "ASTRID 003225",
    "HYG Star ID": 119451,
    "Gliese Catalog": "NN 4232",
    "Distance in Parsecs": 25.641,
    "mag": 14.06,
    "Absolute Visual Magnitude": 12.015,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.31244,
    "y": -12.045787,
    "z": 11.806336,
    "vx": 0.00005489,
    "vy": 0.00000699,
    "vz": -0.00008262,
    "rarad": 5.72548975425333,
    "decrad": 0.4784993043649,
    "pmrarad": 0.00000136460506665972,
    "pmdecrad": -0.000003630512702,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119451,
    "lum": 0.00136144468246594,
    "ra": 21.86976,
    "dec": 27.415991,
    "pmra": 281.47,
    "pmdec": -748.85,
    "rv": 0,
    "Asset Name": "ASTRID 003225",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "High Fade",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005489,
    "y_1": 0.00000699,
    "z_1": -0.00008262,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 119451,
    "Gliese Catalog_1": "NN 4232"
  },
  {
    "Astrid #": "ASTRID 003226",
    "HYG Star ID": 119603,
    "Gliese Catalog": "NN 4372",
    "Distance in Parsecs": 25.641,
    "mag": 13.3,
    "Absolute Visual Magnitude": 11.255,
    "Spectral Type": "M4",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.183393,
    "y": -0.493876,
    "z": -17.006304,
    "vx": -0.000015,
    "vy": 0.00006136,
    "vz": -0.00001871,
    "rarad": 6.25740605761819,
    "decrad": -0.72514842768205,
    "pmrarad": 0.00000237733236397222,
    "pmdecrad": -9.75013641e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119603,
    "lum": 0.00274157417192787,
    "ra": 23.901531,
    "dec": -41.547944,
    "pmra": 490.36,
    "pmdec": -201.11,
    "rv": 0,
    "Asset Name": "ASTRID 003226",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Afro",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.000015,
    "y_1": 0.00006136,
    "z_1": -0.00001871,
    "Distance in Parsecs_1": 25.641,
    "HYG Star ID_1": 119603,
    "Gliese Catalog_1": "NN 4372"
  },
  {
    "Astrid #": "ASTRID 003227",
    "HYG Star ID": 18137,
    "Hipparcos Catalogue": 18180,
    "Gliese Catalog": "Gl 155.3",
    "Distance in Parsecs": 25.6476,
    "mag": 12.13,
    "Absolute Visual Magnitude": 10.085,
    "Spectral Type": "K:",
    "Color Index": 1.45,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.743871,
    "y": 17.41761,
    "z": -15.458484,
    "vx": -0.00002812,
    "vy": -0.00014241,
    "vz": -0.00006633,
    "rarad": 1.01809110421464,
    "decrad": -0.646931078635613,
    "pmrarad": -0.00000198191832611111,
    "pmdecrad": -0.000005259501213,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 18137,
    "lum": 0.00805378441199065,
    "var_min": 12.242,
    "var_max": 11.952,
    "ra": 3.888822,
    "dec": -37.06642,
    "pmra": -408.8,
    "pmdec": -1084.85,
    "rv": -67,
    "Asset Name": "ASTRID 003227",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Lion Mane",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002812,
    "y_1": -0.00014241,
    "z_1": -0.00006633,
    "Distance in Parsecs_1": 25.6476,
    "HYG Star ID_1": 18137,
    "Hipparcos Catalogue_1": 18180,
    "Gliese Catalog_1": "Gl 155.3"
  },
  {
    "Astrid #": "ASTRID 003228",
    "HYG Star ID": 106570,
    "Hipparcos Catalogue": 106913,
    "Henry Draper Catalogue": 205905,
    "Gliese Catalog": "Gl 836.1",
    "Distance in Parsecs": 25.6476,
    "mag": 6.75,
    "Absolute Visual Magnitude": 4.705,
    "Spectral Type": "G2V",
    "Color Index": 0.623,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.620484,
    "y": -13.139056,
    "z": -11.765802,
    "vx": 0.00001059,
    "vy": 0.00005104,
    "vz": -0.000001,
    "rarad": 5.6686929022229,
    "decrad": -0.476589583744736,
    "pmrarad": 0.00000186425404584027,
    "pmdecrad": -4.06370827e-7,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 106570,
    "lum": 1.14287833478977,
    "ra": 21.652812,
    "dec": -27.306572,
    "pmra": 384.53,
    "pmdec": -83.82,
    "rv": -17.6,
    "Asset Name": "ASTRID 003228",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Curly",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001059,
    "y_1": 0.00005104,
    "z_1": -0.000001,
    "Distance in Parsecs_1": 25.6476,
    "HYG Star ID_1": 106570,
    "Hipparcos Catalogue_1": 106913,
    "Henry Draper Catalogue_1": 205905,
    "Gliese Catalog_1": "Gl 836.1"
  },
  {
    "Astrid #": "ASTRID 003229",
    "HYG Star ID": 54241,
    "Hipparcos Catalogue": 54400,
    "Henry Draper Catalogue": 96700,
    "Harvard Revised Catalogue": 4328,
    "Gliese Catalog": "Gl 412.2",
    "Distance in Parsecs": 25.6542,
    "mag": 6.51,
    "Absolute Visual Magnitude": 4.464,
    "Spectral Type": "G1/G2V",
    "Color Index": 0.606,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.607655,
    "y": 4.997746,
    "z": -12.894788,
    "vx": 0.00001126,
    "vy": 0.00006196,
    "vz": -0.00002069,
    "rarad": 2.91429789835853,
    "decrad": -0.52664554599271,
    "pmrarad": -0.00000245218759625,
    "pmdecrad": -6.38742024e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 54241,
    "lum": 1.42692123157936,
    "ra": 11.131798,
    "dec": -30.174567,
    "pmra": -505.8,
    "pmdec": -131.75,
    "rv": 12.7,
    "Asset Name": "ASTRID 003229",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Charmer",
    "Hair": "Fire",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001126,
    "y_1": 0.00006196,
    "z_1": -0.00002069,
    "Distance in Parsecs_1": 25.6542,
    "HYG Star ID_1": 54241,
    "Hipparcos Catalogue_1": 54400,
    "Henry Draper Catalogue_1": 96700,
    "Harvard Revised Catalogue_1": 4328,
    "Gliese Catalog_1": "Gl 412.2"
  },
  {
    "Astrid #": "ASTRID 003230",
    "HYG Star ID": 92260,
    "Hipparcos Catalogue": 92549,
    "Henry Draper Catalogue": 175225,
    "Harvard Revised Catalogue": 7123,
    "Gliese Catalog": "Gl 732.1",
    "Distance in Parsecs": 25.6674,
    "mag": 5.51,
    "Absolute Visual Magnitude": 3.463,
    "Spectral Type": "G9IVa",
    "Color Index": 0.843,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.449372,
    "y": -15.066097,
    "z": 20.492175,
    "vx": -0.00001356,
    "vy": 0.0000229,
    "vz": 0.00001707,
    "rarad": 4.93746106668322,
    "decrad": 0.924590211374362,
    "pmrarad": -3.15807631513888e-7,
    "pmdecrad": 0.00000118895707,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 92260,
    "lum": 3.58756683039261,
    "ra": 18.859712,
    "dec": 52.975117,
    "pmra": -65.14,
    "pmdec": 245.24,
    "rv": -1.6,
    "Asset Name": "ASTRID 003230",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Side Part",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00001356,
    "y_1": 0.0000229,
    "z_1": 0.00001707,
    "Distance in Parsecs_1": 25.6674,
    "HYG Star ID_1": 92260,
    "Hipparcos Catalogue_1": 92549,
    "Henry Draper Catalogue_1": 175225,
    "Harvard Revised Catalogue_1": 7123,
    "Gliese Catalog_1": "Gl 732.1"
  },
  {
    "Astrid #": "ASTRID 003231",
    "HYG Star ID": 113192,
    "Hipparcos Catalogue": 113552,
    "Gliese Catalog": "Gl 883",
    "Distance in Parsecs": 25.6674,
    "mag": 10.57,
    "Absolute Visual Magnitude": 8.523,
    "Spectral Type": "M2",
    "Color Index": 1.402,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 24.302054,
    "y": -6.523759,
    "z": -5.065273,
    "vx": -0.00001388,
    "vy": 0.00003199,
    "vz": -0.00000412,
    "rarad": 6.02092341351378,
    "decrad": -0.198647942553862,
    "pmrarad": 0.00000106348728966666,
    "pmdecrad": -3.2429187e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113192,
    "lum": 0.0339468905380078,
    "var_min": 10.625,
    "var_max": 10.515,
    "ra": 22.998233,
    "dec": -11.381689,
    "pmra": 219.36,
    "pmdec": -66.89,
    "rv": -20,
    "Asset Name": "ASTRID 003231",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Charmer",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00001388,
    "y_1": 0.00003199,
    "z_1": -0.00000412,
    "Distance in Parsecs_1": 25.6674,
    "HYG Star ID_1": 113192,
    "Hipparcos Catalogue_1": 113552,
    "Gliese Catalog_1": "Gl 883"
  },
  {
    "Astrid #": "ASTRID 003232",
    "HYG Star ID": 117096,
    "Hipparcos Catalogue": 117463,
    "Henry Draper Catalogue": 223374,
    "Distance in Parsecs": 25.6805,
    "mag": 8.38,
    "Absolute Visual Magnitude": 6.332,
    "Spectral Type": "G5",
    "Color Index": 0.929,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.611502,
    "y": -1.228047,
    "z": 1.425096,
    "vx": 4e-7,
    "vy": 0.00000802,
    "vz": -2.3e-7,
    "rarad": 6.23527297389457,
    "decrad": 0.0555218403692125,
    "pmrarad": 3.12559379854166e-7,
    "pmdecrad": -8.87209e-9,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 117096,
    "lum": 0.255387713577932,
    "ra": 23.816988,
    "dec": 3.181167,
    "pmra": 64.47,
    "pmdec": -1.83,
    "rv": 0,
    "Asset Name": "ASTRID 003232",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Sweetheart",
    "Outfit": "Viking",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 4e-7,
    "y_1": 0.00000802,
    "z_1": -2.3e-7,
    "Distance in Parsecs_1": 25.6805,
    "HYG Star ID_1": 117096,
    "Hipparcos Catalogue_1": 117463,
    "Henry Draper Catalogue_1": 223374
  },
  {
    "Astrid #": "ASTRID 003233",
    "HYG Star ID": 81102,
    "Hipparcos Catalogue": 81348,
    "Henry Draper Catalogue": 149957,
    "Gliese Catalog": "Gl 632.1",
    "Distance in Parsecs": 25.6871,
    "mag": 9.49,
    "Absolute Visual Magnitude": 7.441,
    "Spectral Type": "K5",
    "Color Index": 1.2,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.809219,
    "y": -20.562733,
    "z": 13.266969,
    "vx": 0.0000326,
    "vy": -0.00003354,
    "vz": -0.00005319,
    "rarad": 4.34943591147862,
    "decrad": 0.542741614212174,
    "pmrarad": 0.00000165011184313888,
    "pmdecrad": -0.00000217094718,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81102,
    "lum": 0.0919602196658617,
    "var_min": 9.533,
    "var_max": 9.433,
    "ra": 16.613621,
    "dec": 31.096804,
    "pmra": 340.36,
    "pmdec": -447.79,
    "rv": -10.3,
    "Asset Name": "ASTRID 003233",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Sweet Nap",
    "Hair": "High Fade",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.0000326,
    "y_1": -0.00003354,
    "z_1": -0.00005319,
    "Distance in Parsecs_1": 25.6871,
    "HYG Star ID_1": 81102,
    "Hipparcos Catalogue_1": 81348,
    "Henry Draper Catalogue_1": 149957,
    "Gliese Catalog_1": "Gl 632.1"
  },
  {
    "Astrid #": "ASTRID 003234",
    "HYG Star ID": 45462,
    "Hipparcos Catalogue": 45593,
    "Gliese Catalog": "Gl 338.1A",
    "Distance in Parsecs": 25.7069,
    "mag": 10.12,
    "Absolute Visual Magnitude": 8.07,
    "Spectral Type": "K5",
    "Color Index": 1.32,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.307958,
    "y": 3.695366,
    "z": 25.072468,
    "vx": 0.00008718,
    "vy": 0.00010045,
    "vz": -0.00000401,
    "rarad": 2.4327476022806,
    "decrad": 1.34817434439671,
    "pmrarad": -0.00000517436793120138,
    "pmdecrad": -4.411804e-9,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 45462,
    "base": "Gl 338.1",
    "lum": 0.0515228644581756,
    "var_min": 10.173,
    "var_max": 10.063,
    "ra": 9.292411,
    "dec": 77.2447,
    "pmra": -1067.29,
    "pmdec": -0.91,
    "rv": -4,
    "Asset Name": "ASTRID 003234",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Unkempt",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00008718,
    "y_1": 0.00010045,
    "z_1": -0.00000401,
    "Distance in Parsecs_1": 25.7069,
    "HYG Star ID_1": 45462,
    "Hipparcos Catalogue_1": 45593,
    "Gliese Catalog_1": "Gl 338.1A"
  },
  {
    "Astrid #": "ASTRID 003235",
    "HYG Star ID": 46067,
    "Hipparcos Catalogue": 46199,
    "Henry Draper Catalogue": 81105,
    "Distance in Parsecs": 25.7069,
    "mag": 9.09,
    "Absolute Visual Magnitude": 7.04,
    "Spectral Type": "K0",
    "Color Index": 1.102,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.910812,
    "y": 11.146668,
    "z": 18.522577,
    "vx": 0.00001937,
    "vy": 0.00002133,
    "vz": 0.00000171,
    "rarad": 2.46606671873299,
    "decrad": 0.80456543966706,
    "pmrarad": -0.00000111865908651388,
    "pmdecrad": 9.5847664e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 46067,
    "lum": 0.133045441797809,
    "ra": 9.419681,
    "dec": 46.098204,
    "pmra": -230.74,
    "pmdec": 19.77,
    "rv": 0,
    "Asset Name": "ASTRID 003235",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Lion Mane",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001937,
    "y_1": 0.00002133,
    "z_1": 0.00000171,
    "Distance in Parsecs_1": 25.7069,
    "HYG Star ID_1": 46067,
    "Hipparcos Catalogue_1": 46199,
    "Henry Draper Catalogue_1": 81105
  },
  {
    "Astrid #": "ASTRID 003236",
    "HYG Star ID": 118269,
    "Gliese Catalog": "Wo 9141",
    "Distance in Parsecs": 25.7069,
    "mag": 13.65,
    "Absolute Visual Magnitude": 11.6,
    "Spectral Type": "M3.5",
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 7.95329,
    "y": 13.931981,
    "z": 20.087056,
    "vx": 0.00000132,
    "vy": 0.0000868,
    "vz": -0.0000607,
    "rarad": 1.05202553760376,
    "decrad": 0.89688642607035,
    "pmrarad": 0.00000162999207539583,
    "pmdecrad": -0.000003784838593,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118269,
    "lum": 0.00199526231496887,
    "ra": 4.018442,
    "dec": 51.387807,
    "pmra": 336.21,
    "pmdec": -780.68,
    "rv": 0,
    "Asset Name": "ASTRID 003236",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Serious Mad",
    "Hair": "Pixie",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000132,
    "y_1": 0.0000868,
    "z_1": -0.0000607,
    "Distance in Parsecs_1": 25.7069,
    "HYG Star ID_1": 118269,
    "Gliese Catalog_1": "Wo 9141"
  },
  {
    "Astrid #": "ASTRID 003237",
    "HYG Star ID": 521,
    "Hipparcos Catalogue": 522,
    "Henry Draper Catalogue": 142,
    "Harvard Revised Catalogue": 6,
    "Gliese Catalog": "Gl 4.2A",
    "Distance in Parsecs": 25.7136,
    "mag": 5.7,
    "Absolute Visual Magnitude": 3.649,
    "Spectral Type": "G1IV...",
    "Color Index": 0.519,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.837769,
    "y": 0.46441,
    "z": -19.42844,
    "vx": -0.00000399,
    "vy": 0.00007162,
    "vz": -0.00000527,
    "rarad": 0.0275615650967543,
    "decrad": -0.85652366430261,
    "pmrarad": 0.00000278869677192361,
    "pmdecrad": -1.93634584e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 521,
    "base": "Gl   4.2",
    "lum": 3.02273448003865,
    "ra": 0.105277,
    "dec": -49.075191,
    "pmra": 575.21,
    "pmdec": -39.94,
    "rv": 2.6,
    "Asset Name": "ASTRID 003237",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Serious Slumber",
    "Hair": "Heartbreaker",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000399,
    "y_1": 0.00007162,
    "z_1": -0.00000527,
    "Distance in Parsecs_1": 25.7136,
    "HYG Star ID_1": 521,
    "Hipparcos Catalogue_1": 522,
    "Henry Draper Catalogue_1": 142,
    "Harvard Revised Catalogue_1": 6,
    "Gliese Catalog_1": "Gl 4.2A"
  },
  {
    "Astrid #": "ASTRID 003238",
    "HYG Star ID": 39383,
    "Hipparcos Catalogue": 39494,
    "Gliese Catalog": "NN 3475",
    "Distance in Parsecs": 25.7136,
    "mag": 9.83,
    "Absolute Visual Magnitude": 7.779,
    "Spectral Type": "M0",
    "Color Index": 1.27,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.558923,
    "y": 15.849303,
    "z": 17.849773,
    "vx": -0.00001532,
    "vy": -0.00001027,
    "vz": -0.0000035,
    "rarad": 2.11349930654977,
    "decrad": 0.767276041531477,
    "pmrarad": 7.16748545333333e-7,
    "pmdecrad": -7.4224974e-8,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 39383,
    "lum": 0.0673596776222942,
    "ra": 8.072973,
    "dec": 43.961679,
    "pmra": 147.84,
    "pmdec": -15.31,
    "rv": -3,
    "Asset Name": "ASTRID 003238",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Greasy",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00001532,
    "y_1": -0.00001027,
    "z_1": -0.0000035,
    "Distance in Parsecs_1": 25.7136,
    "HYG Star ID_1": 39383,
    "Hipparcos Catalogue_1": 39494,
    "Gliese Catalog_1": "NN 3475"
  },
  {
    "Astrid #": "ASTRID 003239",
    "HYG Star ID": 29688,
    "Hipparcos Catalogue": 29761,
    "Henry Draper Catalogue": 42250,
    "Gliese Catalog": "Wo 9205",
    "Distance in Parsecs": 25.7268,
    "mag": 7.43,
    "Absolute Visual Magnitude": 5.378,
    "Spectral Type": "G5",
    "Color Index": 0.776,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.592384,
    "y": 8.447828,
    "z": 24.293271,
    "vx": -0.0000026,
    "vy": 0.00006056,
    "vz": 0.00000596,
    "rarad": 1.64080549488862,
    "decrad": 1.23537154644022,
    "pmrarad": -6.38984430972222e-8,
    "pmdecrad": -0.000002148063974,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 29688,
    "lum": 0.614893638512626,
    "ra": 6.267415,
    "dec": 70.781576,
    "pmra": -13.18,
    "pmdec": -443.07,
    "rv": 25,
    "Asset Name": "ASTRID 003239",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Sweetheart",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.0000026,
    "y_1": 0.00006056,
    "z_1": 0.00000596,
    "Distance in Parsecs_1": 25.7268,
    "HYG Star ID_1": 29688,
    "Hipparcos Catalogue_1": 29761,
    "Henry Draper Catalogue_1": 42250,
    "Gliese Catalog_1": "Wo 9205"
  },
  {
    "Astrid #": "ASTRID 003240",
    "HYG Star ID": 12795,
    "Hipparcos Catalogue": 12828,
    "Henry Draper Catalogue": 17094,
    "Harvard Revised Catalogue": 813,
    "Gliese Catalog": "Wo 9099",
    "Distance in Parsecs": 25.7732,
    "mag": 4.27,
    "Absolute Visual Magnitude": 2.214,
    "Spectral Type": "F1III-IV",
    "Color Index": 0.311,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.080598,
    "y": 16.72475,
    "z": 4.526074,
    "vx": 3e-8,
    "vy": 0.00004741,
    "vz": 0.00000172,
    "rarad": 0.719696675252762,
    "decrad": 0.176525146395617,
    "pmrarad": 0.00000138254317284027,
    "pmdecrad": -1.47383358e-7,
    "bayer": "Mu",
    "flam": 87,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12795,
    "lum": 11.3344382297103,
    "bf": "87Mu  Cet",
    "ra": 2.749039,
    "dec": 10.114146,
    "pmra": 285.17,
    "pmdec": -30.4,
    "rv": 30.4,
    "Asset Name": "ASTRID 003240",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Prince",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 3e-8,
    "y_1": 0.00004741,
    "z_1": 0.00000172,
    "Distance in Parsecs_1": 25.7732,
    "HYG Star ID_1": 12795,
    "Hipparcos Catalogue_1": 12828,
    "Henry Draper Catalogue_1": 17094,
    "Harvard Revised Catalogue_1": 813,
    "Gliese Catalog_1": "Wo 9099"
  },
  {
    "Astrid #": "ASTRID 003241",
    "HYG Star ID": 15758,
    "Hipparcos Catalogue": 15797,
    "Distance in Parsecs": 25.7732,
    "mag": 8.98,
    "Absolute Visual Magnitude": 6.924,
    "Spectral Type": "K2",
    "Color Index": 0.98,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.703577,
    "y": 14.395875,
    "z": 17.889742,
    "vx": 0.00000662,
    "vy": 0.00003205,
    "vz": -0.00003012,
    "rarad": 0.888187934238483,
    "decrad": 0.767199355444266,
    "pmrarad": 5.85170112430555e-7,
    "pmdecrad": -0.000001623834941,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 15758,
    "lum": 0.148047132489762,
    "ra": 3.392628,
    "dec": 43.957285,
    "pmra": 120.7,
    "pmdec": -334.94,
    "rv": 0,
    "Asset Name": "ASTRID 003241",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Hurt",
    "Hair": "Horns",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00000662,
    "y_1": 0.00003205,
    "z_1": -0.00003012,
    "Distance in Parsecs_1": 25.7732,
    "HYG Star ID_1": 15758,
    "Hipparcos Catalogue_1": 15797
  },
  {
    "Astrid #": "ASTRID 003242",
    "HYG Star ID": 78903,
    "Hipparcos Catalogue": 79143,
    "Henry Draper Catalogue": 144009,
    "Gliese Catalog": "Gl 611.1",
    "Distance in Parsecs": 25.7732,
    "mag": 7.23,
    "Absolute Visual Magnitude": 5.174,
    "Spectral Type": "G8V",
    "Color Index": 0.714,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.885942,
    "y": -7.40354,
    "z": -24.379433,
    "vx": -0.00000447,
    "vy": 0.00004519,
    "vz": -0.0000408,
    "rarad": 4.22905660636588,
    "decrad": -1.24039863231782,
    "pmrarad": -9.68415326909722e-7,
    "pmdecrad": -0.000001906093466,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 78903,
    "lum": 0.741993327789943,
    "ra": 16.153806,
    "dec": -71.069607,
    "pmra": -199.75,
    "pmdec": -393.16,
    "rv": 25.7,
    "Asset Name": "ASTRID 003242",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Maniacal",
    "Hair": "Pixie",
    "Outfit": "Pirate",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": -0.00000447,
    "y_1": 0.00004519,
    "z_1": -0.0000408,
    "Distance in Parsecs_1": 25.7732,
    "HYG Star ID_1": 78903,
    "Hipparcos Catalogue_1": 79143,
    "Henry Draper Catalogue_1": 144009,
    "Gliese Catalog_1": "Gl 611.1"
  },
  {
    "Astrid #": "ASTRID 003243",
    "HYG Star ID": 12154,
    "Hipparcos Catalogue": 12186,
    "Henry Draper Catalogue": 16417,
    "Harvard Revised Catalogue": 772,
    "Gliese Catalog": "Gl 105.1",
    "Distance in Parsecs": 25.7798,
    "mag": 5.78,
    "Absolute Visual Magnitude": 3.724,
    "Spectral Type": "G1V",
    "Color Index": 0.653,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.438611,
    "y": 13.428135,
    "z": -14.630792,
    "vx": -0.00000612,
    "vy": -0.00000794,
    "vz": -0.00003272,
    "rarad": 0.684940660964729,
    "decrad": -0.603499545117532,
    "pmrarad": -8.85754594374999e-8,
    "pmdecrad": -0.000001260127718,
    "bayer": "Lam-2",
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 12154,
    "lum": 2.82097995366342,
    "bf": "Lam2For",
    "ra": 2.616281,
    "dec": -34.577977,
    "pmra": -18.27,
    "pmdec": -259.92,
    "rv": 10.3,
    "Asset Name": "ASTRID 003243",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Windy",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000612,
    "y_1": -0.00000794,
    "z_1": -0.00003272,
    "Distance in Parsecs_1": 25.7798,
    "HYG Star ID_1": 12154,
    "Hipparcos Catalogue_1": 12186,
    "Henry Draper Catalogue_1": 16417,
    "Harvard Revised Catalogue_1": 772,
    "Gliese Catalog_1": "Gl 105.1"
  },
  {
    "Astrid #": "ASTRID 003244",
    "HYG Star ID": 89868,
    "Hipparcos Catalogue": 90147,
    "Distance in Parsecs": 25.7798,
    "mag": 11.16,
    "Absolute Visual Magnitude": 9.104,
    "Color Index": 1.383,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 2.662008,
    "y": -25.537352,
    "z": -2.314187,
    "vx": 0.00003561,
    "vy": 0.0000083,
    "vz": -0.00005062,
    "rarad": 4.81625357017665,
    "decrad": -0.0898884680039887,
    "pmrarad": 0.00000140722018918055,
    "pmdecrad": -0.000001971543313,
    "con": "Sct",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scutum",
    "FULL CONSTELLATION NAME": "Scutum",
    "comp": 1,
    "comp_primary": 89868,
    "lum": 0.0198792502104188,
    "var_min": 11.225,
    "var_max": 11.095,
    "ra": 18.396734,
    "dec": -5.15023,
    "pmra": 290.26,
    "pmdec": -406.66,
    "rv": 0,
    "Asset Name": "ASTRID 003244",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Headband",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scutum",
    "x_1": 0.00003561,
    "y_1": 0.0000083,
    "z_1": -0.00005062,
    "Distance in Parsecs_1": 25.7798,
    "HYG Star ID_1": 89868,
    "Hipparcos Catalogue_1": 90147
  },
  {
    "Astrid #": "ASTRID 003245",
    "HYG Star ID": 6391,
    "Hipparcos Catalogue": 6405,
    "Henry Draper Catalogue": 8262,
    "Distance in Parsecs": 25.7865,
    "mag": 6.96,
    "Absolute Visual Magnitude": 4.903,
    "Spectral Type": "G3V",
    "Color Index": 0.627,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.869656,
    "y": 8.584517,
    "z": 8.26012,
    "vx": -0.00002209,
    "vy": 0.00006589,
    "vz": 3.4e-7,
    "rarad": 0.359091176520232,
    "decrad": 0.326074699496302,
    "pmrarad": 0.00000269343088369444,
    "pmdecrad": -1.8228994e-8,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 6391,
    "lum": 0.952357486191919,
    "ra": 1.371627,
    "dec": 18.682704,
    "pmra": 555.56,
    "pmdec": -3.76,
    "rv": 2.4,
    "Asset Name": "ASTRID 003245",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Afro",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00002209,
    "y_1": 0.00006589,
    "z_1": 3.4e-7,
    "Distance in Parsecs_1": 25.7865,
    "HYG Star ID_1": 6391,
    "Hipparcos Catalogue_1": 6405,
    "Henry Draper Catalogue_1": 8262
  },
  {
    "Astrid #": "ASTRID 003246",
    "HYG Star ID": 119131,
    "Gliese Catalog": "Gl 656.1B",
    "Distance in Parsecs": 25.7931,
    "mag": 3.4,
    "Absolute Visual Magnitude": 1.342,
    "Spectral Type": "A3 V",
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -5.334832,
    "y": -24.247587,
    "z": -6.991291,
    "vx": 0.00000382,
    "vy": -0.00000407,
    "vz": 0.00001119,
    "rarad": 4.49582406299579,
    "decrad": -0.27448655173925,
    "pmrarad": 1.784599158125e-7,
    "pmdecrad": 4.50765215e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 2,
    "comp_primary": 83755,
    "base": "Gl 656.1",
    "lum": 25.3046304946139,
    "ra": 17.172783,
    "dec": -15.726921,
    "pmra": 36.81,
    "pmdec": 92.98,
    "rv": 0,
    "Asset Name": "ASTRID 003246",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Audiophile",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00000382,
    "y_1": -0.00000407,
    "z_1": 0.00001119,
    "Distance in Parsecs_1": 25.7931,
    "HYG Star ID_1": 119131,
    "Gliese Catalog_1": "Gl 656.1B"
  },
  {
    "Astrid #": "ASTRID 003247",
    "HYG Star ID": 32192,
    "Hipparcos Catalogue": 32277,
    "Distance in Parsecs": 25.8065,
    "mag": 9.78,
    "Absolute Visual Magnitude": 7.721,
    "Spectral Type": "K",
    "Color Index": 1.262,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.764802,
    "y": 19.222143,
    "z": 16.80211,
    "vx": -0.00002311,
    "vy": -0.00000768,
    "vz": 0.0000036,
    "rarad": 1.76420300910893,
    "decrad": 0.709007225460212,
    "pmrarad": 9.36126735784722e-7,
    "pmdecrad": 1.8393831e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 32192,
    "lum": 0.0710558763369473,
    "ra": 6.738759,
    "dec": 40.623122,
    "pmra": 193.09,
    "pmdec": 37.94,
    "rv": 0,
    "Asset Name": "ASTRID 003247",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Frizzy",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00002311,
    "y_1": -0.00000768,
    "z_1": 0.0000036,
    "Distance in Parsecs_1": 25.8065,
    "HYG Star ID_1": 32192,
    "Hipparcos Catalogue_1": 32277
  },
  {
    "Astrid #": "ASTRID 003248",
    "HYG Star ID": 75037,
    "Hipparcos Catalogue": 75266,
    "Henry Draper Catalogue": 136834,
    "Gliese Catalog": "GJ 1192",
    "Distance in Parsecs": 25.8131,
    "mag": 8.28,
    "Absolute Visual Magnitude": 6.221,
    "Spectral Type": "K3V",
    "Color Index": 0.992,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.352282,
    "y": -19.962429,
    "z": 0.639054,
    "vx": -0.000019,
    "vy": 0.0000486,
    "vz": -0.00004618,
    "rarad": 4.02607802007696,
    "decrad": 0.0247597061990337,
    "pmrarad": -0.00000176273406113194,
    "pmdecrad": -0.000001764091539,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 75037,
    "lum": 0.282878538797013,
    "ra": 15.378485,
    "dec": 1.418627,
    "pmra": -363.59,
    "pmdec": -363.87,
    "rv": -26.1,
    "Asset Name": "ASTRID 003248",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Shinobi",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.000019,
    "y_1": 0.0000486,
    "z_1": -0.00004618,
    "Distance in Parsecs_1": 25.8131,
    "HYG Star ID_1": 75037,
    "Hipparcos Catalogue_1": 75266,
    "Henry Draper Catalogue_1": 136834,
    "Gliese Catalog_1": "GJ 1192"
  },
  {
    "Astrid #": "ASTRID 003249",
    "HYG Star ID": 60908,
    "Hipparcos Catalogue": 61099,
    "Henry Draper Catalogue": 108984,
    "Distance in Parsecs": 25.8198,
    "mag": 7.91,
    "Absolute Visual Magnitude": 5.85,
    "Spectral Type": "K0",
    "Color Index": 0.863,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.003251,
    "y": -3.299232,
    "z": 8.923065,
    "vx": -0.00000757,
    "vy": 0.00000117,
    "vz": -0.00001992,
    "rarad": 3.27818618690168,
    "decrad": 0.352867469364982,
    "pmrarad": -8.46969499930555e-8,
    "pmdecrad": -8.22244002e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 60908,
    "lum": 0.398107170553497,
    "ra": 12.521749,
    "dec": 20.217817,
    "pmra": -17.47,
    "pmdec": -169.6,
    "rv": 0,
    "Asset Name": "ASTRID 003249",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Fedora",
    "Outfit": "Sharky",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00000757,
    "y_1": 0.00000117,
    "z_1": -0.00001992,
    "Distance in Parsecs_1": 25.8198,
    "HYG Star ID_1": 60908,
    "Hipparcos Catalogue_1": 61099,
    "Henry Draper Catalogue_1": 108984
  },
  {
    "Astrid #": "ASTRID 003250",
    "HYG Star ID": 118146,
    "Gliese Catalog": "Gl 99B",
    "Distance in Parsecs": 25.8331,
    "mag": 10.5,
    "Absolute Visual Magnitude": 8.439,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 17.402211,
    "y": 13.205121,
    "z": 13.789014,
    "vx": -0.00003714,
    "vy": 0.00003461,
    "vz": 0.00001374,
    "rarad": 0.64910346398828,
    "decrad": 0.56305621325655,
    "pmrarad": 0.00000193653976561111,
    "pmdecrad": 6.29225131e-7,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 2,
    "comp_primary": 11515,
    "base": "Gl  99",
    "lum": 0.0366775231599494,
    "ra": 2.479393,
    "dec": 32.260745,
    "pmra": 399.44,
    "pmdec": 129.79,
    "rv": 0,
    "Asset Name": "ASTRID 003250",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Determined",
    "Hair": "Round Brush",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.00003714,
    "y_1": 0.00003461,
    "z_1": 0.00001374,
    "Distance in Parsecs_1": 25.8331,
    "HYG Star ID_1": 118146,
    "Gliese Catalog_1": "Gl 99B"
  },
  {
    "Astrid #": "ASTRID 003251",
    "HYG Star ID": 103318,
    "Hipparcos Catalogue": 103650,
    "Distance in Parsecs": 25.8465,
    "mag": 9.32,
    "Absolute Visual Magnitude": 7.258,
    "Spectral Type": "K2",
    "Color Index": 1.12,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.051501,
    "y": -6.048474,
    "z": 24.389278,
    "vx": 0.00001733,
    "vy": 0.00000946,
    "vz": -0.00000195,
    "rarad": 5.49802434423657,
    "decrad": 1.23340109808268,
    "pmrarad": 7.32941322263889e-7,
    "pmdecrad": -2.28104836e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 103318,
    "lum": 0.108842873794359,
    "ra": 21.000906,
    "dec": 70.668677,
    "pmra": 151.18,
    "pmdec": -47.05,
    "rv": 0,
    "Asset Name": "ASTRID 003251",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Lovestruck",
    "Hair": "Hachimaki",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001733,
    "y_1": 0.00000946,
    "z_1": -0.00000195,
    "Distance in Parsecs_1": 25.8465,
    "HYG Star ID_1": 103318,
    "Hipparcos Catalogue_1": 103650
  },
  {
    "Astrid #": "ASTRID 003252",
    "HYG Star ID": 23146,
    "Hipparcos Catalogue": 23200,
    "Gliese Catalog": "Gl 182",
    "Distance in Parsecs": 25.8799,
    "mag": 10.05,
    "Absolute Visual Magnitude": 7.985,
    "Spectral Type": "M0.5Ve",
    "Color Index": 1.394,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.740727,
    "y": 24.973816,
    "z": 0.805473,
    "vx": 4.4e-7,
    "vy": 0.00001953,
    "vz": -0.0000112,
    "rarad": 1.30716672394747,
    "decrad": 0.0311283419527537,
    "pmrarad": 1.80108282326388e-7,
    "pmdecrad": -4.55433971e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 23146,
    "lum": 0.0557185748931929,
    "var": "V1005",
    "var_min": 10.109,
    "var_max": 9.949,
    "ra": 4.993009,
    "dec": 1.783523,
    "pmra": 37.15,
    "pmdec": -93.94,
    "rv": 18.2,
    "Asset Name": "ASTRID 003252",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Spiky",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 4.4e-7,
    "y_1": 0.00001953,
    "z_1": -0.0000112,
    "Distance in Parsecs_1": 25.8799,
    "HYG Star ID_1": 23146,
    "Hipparcos Catalogue_1": 23200,
    "Gliese Catalog_1": "Gl 182"
  },
  {
    "Astrid #": "ASTRID 003253",
    "HYG Star ID": 17449,
    "Hipparcos Catalogue": 17491,
    "Henry Draper Catalogue": 23140,
    "Gliese Catalog": "Gl 150.2",
    "Distance in Parsecs": 25.8866,
    "mag": 7.72,
    "Absolute Visual Magnitude": 5.655,
    "Spectral Type": "K2",
    "Color Index": 0.864,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.996182,
    "y": 14.934075,
    "z": 18.632638,
    "vx": -0.00001695,
    "vy": 0.00003943,
    "vz": 0.00000634,
    "rarad": 0.980930271004612,
    "decrad": 0.803477058361526,
    "pmrarad": 0.00000139165767003472,
    "pmdecrad": -4.79044397e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 17449,
    "lum": 0.476430986805415,
    "ra": 3.746878,
    "dec": 46.035844,
    "pmra": 287.05,
    "pmdec": -98.81,
    "rv": 20.3,
    "Asset Name": "ASTRID 003253",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "High Bun",
    "Outfit": "Viking",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00001695,
    "y_1": 0.00003943,
    "z_1": 0.00000634,
    "Distance in Parsecs_1": 25.8866,
    "HYG Star ID_1": 17449,
    "Hipparcos Catalogue_1": 17491,
    "Henry Draper Catalogue_1": 23140,
    "Gliese Catalog_1": "Gl 150.2"
  },
  {
    "Astrid #": "ASTRID 003254",
    "HYG Star ID": 22235,
    "Hipparcos Catalogue": 22288,
    "Henry Draper Catalogue": 30523,
    "Gliese Catalog": "NN 3314",
    "Distance in Parsecs": 25.8866,
    "mag": 9.53,
    "Absolute Visual Magnitude": 7.465,
    "Spectral Type": "K7V",
    "Color Index": 1.135,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.852184,
    "y": 24.173364,
    "z": -4.909964,
    "vx": -0.00001059,
    "vy": 7.2e-7,
    "vz": -0.0000134,
    "rarad": 1.25671999411394,
    "decrad": -0.190828110305173,
    "pmrarad": 3.974987366875e-7,
    "pmdecrad": -5.27234877e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 22235,
    "lum": 0.0899497581530035,
    "ra": 4.800317,
    "dec": -10.933645,
    "pmra": 81.99,
    "pmdec": -108.75,
    "rv": 0,
    "Asset Name": "ASTRID 003254",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Bad Dreams",
    "Hair": "Slick",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001059,
    "y_1": 7.2e-7,
    "z_1": -0.0000134,
    "Distance in Parsecs_1": 25.8866,
    "HYG Star ID_1": 22235,
    "Hipparcos Catalogue_1": 22288,
    "Henry Draper Catalogue_1": 30523,
    "Gliese Catalog_1": "NN 3314"
  },
  {
    "Astrid #": "ASTRID 003255",
    "HYG Star ID": 33007,
    "Hipparcos Catalogue": 33094,
    "Henry Draper Catalogue": 50806,
    "Harvard Revised Catalogue": 2576,
    "Gliese Catalog": "Wo 9219",
    "Distance in Parsecs": 25.8933,
    "mag": 6.05,
    "Absolute Visual Magnitude": 3.984,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.708,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.268365,
    "y": 22.128964,
    "z": -12.371325,
    "vx": -0.0000434,
    "vy": 0.00002901,
    "vz": -0.00008396,
    "rarad": 1.80451919098716,
    "decrad": -0.498113378623816,
    "pmrarad": 0.00000137119853271527,
    "pmdecrad": -0.000002142585579,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 33007,
    "lum": 2.22024039534507,
    "ra": 6.892756,
    "dec": -28.539794,
    "pmra": 282.83,
    "pmdec": -441.94,
    "rv": 72.1,
    "Asset Name": "ASTRID 003255",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Halo Boy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.0000434,
    "y_1": 0.00002901,
    "z_1": -0.00008396,
    "Distance in Parsecs_1": 25.8933,
    "HYG Star ID_1": 33007,
    "Hipparcos Catalogue_1": 33094,
    "Henry Draper Catalogue_1": 50806,
    "Harvard Revised Catalogue_1": 2576,
    "Gliese Catalog_1": "Wo 9219"
  },
  {
    "Astrid #": "ASTRID 003256",
    "HYG Star ID": 115640,
    "Hipparcos Catalogue": 116005,
    "Henry Draper Catalogue": 221239,
    "Gliese Catalog": "NN 4339",
    "Distance in Parsecs": 25.8933,
    "mag": 8.34,
    "Absolute Visual Magnitude": 6.274,
    "Spectral Type": "K0",
    "Color Index": 0.889,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 21.843215,
    "y": -2.853516,
    "z": 13.608365,
    "vx": -9e-7,
    "vy": 0.00002423,
    "vz": -0.00002189,
    "rarad": 6.15328325308646,
    "decrad": 0.553371003188378,
    "pmrarad": 9.23667024194444e-7,
    "pmdecrad": -6.37578471e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 115640,
    "lum": 0.269401494106366,
    "ra": 23.503811,
    "dec": 31.705823,
    "pmra": 190.52,
    "pmdec": -131.51,
    "rv": -14.6,
    "Asset Name": "ASTRID 003256",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Smoulder",
    "Hair": "Middle Part",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -9e-7,
    "y_1": 0.00002423,
    "z_1": -0.00002189,
    "Distance in Parsecs_1": 25.8933,
    "HYG Star ID_1": 115640,
    "Hipparcos Catalogue_1": 116005,
    "Henry Draper Catalogue_1": 221239,
    "Gliese Catalog_1": "NN 4339"
  },
  {
    "Astrid #": "ASTRID 003257",
    "HYG Star ID": 27014,
    "Hipparcos Catalogue": 27080,
    "Henry Draper Catalogue": 39194,
    "Gliese Catalog": "Gl 217.2",
    "Distance in Parsecs": 25.9,
    "mag": 8.09,
    "Absolute Visual Magnitude": 6.024,
    "Spectral Type": "K0V",
    "Color Index": 0.76,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.5933,
    "y": 8.777321,
    "z": -24.360276,
    "vx": 0.0000489,
    "vy": 0.00014817,
    "vz": 0.00003956,
    "rarad": 1.50332984857747,
    "decrad": -1.22423625377368,
    "pmrarad": -0.00000149821971702083,
    "pmdecrad": 0.000006007568722,
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 27014,
    "lum": 0.339156386905821,
    "ra": 5.742297,
    "dec": -70.14357,
    "pmra": -309.03,
    "pmdec": 1239.15,
    "rv": 13.8,
    "Asset Name": "ASTRID 003257",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Antlers",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": 0.0000489,
    "y_1": 0.00014817,
    "z_1": 0.00003956,
    "Distance in Parsecs_1": 25.9,
    "HYG Star ID_1": 27014,
    "Hipparcos Catalogue_1": 27080,
    "Henry Draper Catalogue_1": 39194,
    "Gliese Catalog_1": "Gl 217.2"
  },
  {
    "Astrid #": "ASTRID 003258",
    "HYG Star ID": 92366,
    "Hipparcos Catalogue": 92656,
    "Gliese Catalog": "NN 4082",
    "Distance in Parsecs": 25.9067,
    "mag": 12.11,
    "Absolute Visual Magnitude": 10.043,
    "Spectral Type": "M2",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.215293,
    "y": -13.689058,
    "z": -21.758417,
    "vx": -0.00004854,
    "vy": 0.00007192,
    "vz": -0.00005243,
    "rarad": 4.94309650665615,
    "decrad": -0.997054796870013,
    "pmrarad": -0.00000118929643977083,
    "pmdecrad": -0.000003728556573,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 92366,
    "lum": 0.00837143674435155,
    "var_min": 12.205,
    "var_max": 12.005,
    "ra": 18.881238,
    "dec": -57.127032,
    "pmra": -245.31,
    "pmdec": -769.07,
    "rv": 0,
    "Asset Name": "ASTRID 003258",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Bandana",
    "Outfit": "Toga",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004854,
    "y_1": 0.00007192,
    "z_1": -0.00005243,
    "Distance in Parsecs_1": 25.9067,
    "HYG Star ID_1": 92366,
    "Hipparcos Catalogue_1": 92656,
    "Gliese Catalog_1": "NN 4082"
  },
  {
    "Astrid #": "ASTRID 003259",
    "HYG Star ID": 5925,
    "Hipparcos Catalogue": 5938,
    "Henry Draper Catalogue": 7661,
    "Distance in Parsecs": 25.9134,
    "mag": 7.55,
    "Absolute Visual Magnitude": 5.482,
    "Spectral Type": "K0V",
    "Color Index": 0.753,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 23.942972,
    "y": 8.291378,
    "z": -5.430603,
    "vx": -0.00000563,
    "vy": 0.00001589,
    "vz": -5.7e-7,
    "rarad": 0.333371965076219,
    "decrad": -0.211132472561712,
    "pmrarad": 6.50522996569444e-7,
    "pmdecrad": -2.234991e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 5925,
    "lum": 0.558727438994019,
    "var": "EW",
    "var_min": 7.581,
    "var_max": 7.531,
    "ra": 1.273387,
    "dec": -12.097,
    "pmra": 134.18,
    "pmdec": -4.61,
    "rv": 0,
    "Asset Name": "ASTRID 003259",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Neko Dreams",
    "Hair": "High Bun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000563,
    "y_1": 0.00001589,
    "z_1": -5.7e-7,
    "Distance in Parsecs_1": 25.9134,
    "HYG Star ID_1": 5925,
    "Hipparcos Catalogue_1": 5938,
    "Henry Draper Catalogue_1": 7661
  },
  {
    "Astrid #": "ASTRID 003260",
    "HYG Star ID": 12893,
    "Hipparcos Catalogue": 12926,
    "Henry Draper Catalogue": 17190,
    "Gliese Catalog": "Gl 112",
    "Distance in Parsecs": 25.9134,
    "mag": 7.89,
    "Absolute Visual Magnitude": 5.822,
    "Spectral Type": "K1IV",
    "Color Index": 0.84,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.478405,
    "y": 15.498079,
    "z": 11.217216,
    "vx": -0.00000379,
    "vy": 0.00003672,
    "vz": -0.00001056,
    "rarad": 0.725416420212371,
    "decrad": 0.447675185062563,
    "pmrarad": 0.00000115739569959027,
    "pmdecrad": -7.26735707e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 12893,
    "lum": 0.408507467663124,
    "ra": 2.770887,
    "dec": 25.649899,
    "pmra": 238.73,
    "pmdec": -149.9,
    "rv": 14.5,
    "Asset Name": "ASTRID 003260",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Audiohead",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000379,
    "y_1": 0.00003672,
    "z_1": -0.00001056,
    "Distance in Parsecs_1": 25.9134,
    "HYG Star ID_1": 12893,
    "Hipparcos Catalogue_1": 12926,
    "Henry Draper Catalogue_1": 17190,
    "Gliese Catalog_1": "Gl 112"
  },
  {
    "Astrid #": "ASTRID 003261",
    "HYG Star ID": 18797,
    "Hipparcos Catalogue": 18844,
    "Henry Draper Catalogue": 25874,
    "Distance in Parsecs": 25.9134,
    "mag": 6.74,
    "Absolute Visual Magnitude": 4.672,
    "Spectral Type": "G5IV-V",
    "Color Index": 0.667,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.095556,
    "y": 10.823331,
    "z": -22.742447,
    "vx": -0.00002336,
    "vy": 0.00004499,
    "vz": -0.00002214,
    "rarad": 1.05786981303193,
    "decrad": -1.07088061648422,
    "pmrarad": 0.00000163760365018055,
    "pmdecrad": 5.29658946e-7,
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 18797,
    "lum": 1.17814840600292,
    "ra": 4.040765,
    "dec": -61.35694,
    "pmra": 337.78,
    "pmdec": 109.25,
    "rv": 32,
    "Asset Name": "ASTRID 003261",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Manbun",
    "Outfit": "Astro",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": -0.00002336,
    "y_1": 0.00004499,
    "z_1": -0.00002214,
    "Distance in Parsecs_1": 25.9134,
    "HYG Star ID_1": 18797,
    "Hipparcos Catalogue_1": 18844,
    "Henry Draper Catalogue_1": 25874
  },
  {
    "Astrid #": "ASTRID 003262",
    "HYG Star ID": 52854,
    "Hipparcos Catalogue": 53008,
    "Henry Draper Catalogue": 93811,
    "Gliese Catalog": "NN 3627A",
    "Distance in Parsecs": 25.9202,
    "mag": 8.31,
    "Absolute Visual Magnitude": 6.242,
    "Spectral Type": "G5",
    "Color Index": 0.937,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.301545,
    "y": 4.775283,
    "z": 20.369491,
    "vx": 0.00001008,
    "vy": 0.00002198,
    "vz": 0.00000242,
    "rarad": 2.83909681836927,
    "decrad": 0.904075775996075,
    "pmrarad": -9.25557797548611e-7,
    "pmdecrad": 1.5097098e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 52854,
    "base": "NN 3627",
    "lum": 0.277459755988041,
    "var": "LZ",
    "var_min": 8.35,
    "var_max": 8.28,
    "ra": 10.844551,
    "dec": 51.799726,
    "pmra": -190.91,
    "pmdec": 31.14,
    "rv": 0,
    "Asset Name": "ASTRID 003262",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Innocent",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001008,
    "y_1": 0.00002198,
    "z_1": 0.00000242,
    "Distance in Parsecs_1": 25.9202,
    "HYG Star ID_1": 52854,
    "Hipparcos Catalogue_1": 53008,
    "Henry Draper Catalogue_1": 93811,
    "Gliese Catalog_1": "NN 3627A"
  },
  {
    "Astrid #": "ASTRID 003263",
    "HYG Star ID": 57102,
    "Hipparcos Catalogue": 57274,
    "Gliese Catalog": "Gl 439",
    "Distance in Parsecs": 25.9202,
    "mag": 8.97,
    "Absolute Visual Magnitude": 6.902,
    "Spectral Type": "K8",
    "Color Index": 1.111,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.178022,
    "y": 1.484459,
    "z": 13.334233,
    "vx": -0.00004935,
    "vy": 0.00000657,
    "vz": -0.00002595,
    "rarad": 3.07475873574572,
    "decrad": 0.540341611310318,
    "pmrarad": -1.25857631472222e-7,
    "pmdecrad": -0.000001844764535,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 57102,
    "lum": 0.151077573192499,
    "ra": 11.744713,
    "dec": 30.959294,
    "pmra": -25.96,
    "pmdec": -380.51,
    "rv": 28.6,
    "Asset Name": "ASTRID 003263",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Bob",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00004935,
    "y_1": 0.00000657,
    "z_1": -0.00002595,
    "Distance in Parsecs_1": 25.9202,
    "HYG Star ID_1": 57102,
    "Hipparcos Catalogue_1": 57274,
    "Gliese Catalog_1": "Gl 439"
  },
  {
    "Astrid #": "ASTRID 003264",
    "HYG Star ID": 83589,
    "Hipparcos Catalogue": 83846,
    "Henry Draper Catalogue": 154590,
    "Gliese Catalog": "Gl 654.3",
    "Distance in Parsecs": 25.9336,
    "mag": 8.3,
    "Absolute Visual Magnitude": 6.231,
    "Spectral Type": "K5V",
    "Color Index": 1.051,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.344072,
    "y": -18.861894,
    "z": -17.259801,
    "vx": -0.00001628,
    "vy": 0.00004587,
    "vz": -0.00001668,
    "rarad": 4.4860296955731,
    "decrad": -0.728220531673047,
    "pmrarad": -0.00000100884878786805,
    "pmdecrad": -0.000001533562634,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 83589,
    "lum": 0.280285092764668,
    "ra": 17.135371,
    "dec": -41.723963,
    "pmra": -208.09,
    "pmdec": -316.32,
    "rv": -19.1,
    "Asset Name": "ASTRID 003264",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Sad",
    "Hair": "Bangs",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00001628,
    "y_1": 0.00004587,
    "z_1": -0.00001668,
    "Distance in Parsecs_1": 25.9336,
    "HYG Star ID_1": 83589,
    "Hipparcos Catalogue_1": 83846,
    "Henry Draper Catalogue_1": 154590,
    "Gliese Catalog_1": "Gl 654.3"
  },
  {
    "Astrid #": "ASTRID 003265",
    "HYG Star ID": 118247,
    "Gliese Catalog": "Gl 149B",
    "Distance in Parsecs": 25.9336,
    "mag": 15.6,
    "Absolute Visual Magnitude": 13.531,
    "Spectral Type": "m",
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 13.304391,
    "y": 19.499467,
    "z": -10.73851,
    "vx": -0.00001521,
    "vy": -0.00001343,
    "vz": -0.00004325,
    "rarad": 0.972050974399695,
    "decrad": -0.42692865790825,
    "pmrarad": 1.92567993916666e-7,
    "pmdecrad": -0.000001832198165,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 17304,
    "base": "Gl 149",
    "lum": 0.000336976798584403,
    "ra": 3.712961,
    "dec": -24.46121,
    "pmra": 39.72,
    "pmdec": -377.92,
    "rv": 0,
    "Asset Name": "ASTRID 003265",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Pixie",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001521,
    "y_1": -0.00001343,
    "z_1": -0.00004325,
    "Distance in Parsecs_1": 25.9336,
    "HYG Star ID_1": 118247,
    "Gliese Catalog_1": "Gl 149B"
  },
  {
    "Astrid #": "ASTRID 003266",
    "HYG Star ID": 6328,
    "Hipparcos Catalogue": 6342,
    "Distance in Parsecs": 25.9471,
    "mag": 10.7,
    "Absolute Visual Magnitude": 8.63,
    "Spectral Type": "M2",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 22.163722,
    "y": 8.230473,
    "z": 10.690218,
    "vx": -0.0000153,
    "vy": 0.0000402,
    "vz": 7.7e-7,
    "rarad": 0.355564406352542,
    "decrad": 0.424648438343076,
    "pmrarad": 0.00000165786886202777,
    "pmdecrad": 3.2724923e-8,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 6328,
    "lum": 0.030760968147407,
    "var_min": 10.755,
    "var_max": 10.645,
    "ra": 1.358156,
    "dec": 24.330563,
    "pmra": 341.96,
    "pmdec": 6.75,
    "rv": 0,
    "Asset Name": "ASTRID 003266",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Neko Dreams",
    "Hair": "Greasy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.0000153,
    "y_1": 0.0000402,
    "z_1": 7.7e-7,
    "Distance in Parsecs_1": 25.9471,
    "HYG Star ID_1": 6328,
    "Hipparcos Catalogue_1": 6342
  },
  {
    "Astrid #": "ASTRID 003267",
    "HYG Star ID": 71818,
    "Hipparcos Catalogue": 72044,
    "Distance in Parsecs": 25.9471,
    "mag": 9.89,
    "Absolute Visual Magnitude": 7.82,
    "Spectral Type": "M0",
    "Color Index": 1.25,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -18.119025,
    "y": -15.778392,
    "z": 9.797717,
    "vx": 0.00000457,
    "vy": 0.00000321,
    "vz": 0.00001363,
    "rarad": 3.85804974956171,
    "decrad": 0.387206872704261,
    "pmrarad": 2.23499106736111e-8,
    "pmdecrad": 5.67280487e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 71818,
    "lum": 0.0648634433548237,
    "ra": 14.736665,
    "dec": 22.18532,
    "pmra": 4.61,
    "pmdec": 117.01,
    "rv": 0,
    "Asset Name": "ASTRID 003267",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Glad",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000457,
    "y_1": 0.00000321,
    "z_1": 0.00001363,
    "Distance in Parsecs_1": 25.9471,
    "HYG Star ID_1": 71818,
    "Hipparcos Catalogue_1": 72044
  },
  {
    "Astrid #": "ASTRID 003268",
    "HYG Star ID": 79801,
    "Hipparcos Catalogue": 80043,
    "Henry Draper Catalogue": 146800,
    "Distance in Parsecs": 25.9471,
    "mag": 8.91,
    "Absolute Visual Magnitude": 6.84,
    "Spectral Type": "K3V",
    "Color Index": 0.941,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.284848,
    "y": -15.675859,
    "z": -19.350736,
    "vx": -0.00001522,
    "vy": 0.00008754,
    "vz": -0.00006684,
    "rarad": 4.27737206971231,
    "decrad": -0.841699099058866,
    "pmrarad": -0.00000195409002084722,
    "pmdecrad": -0.000003813835299,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 79801,
    "lum": 0.159955802861466,
    "ra": 16.338358,
    "dec": -48.225806,
    "pmra": -403.06,
    "pmdec": -786.66,
    "rv": 1.2,
    "Asset Name": "ASTRID 003268",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Mortified",
    "Hair": "Afro",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00001522,
    "y_1": 0.00008754,
    "z_1": -0.00006684,
    "Distance in Parsecs_1": 25.9471,
    "HYG Star ID_1": 79801,
    "Hipparcos Catalogue_1": 80043,
    "Henry Draper Catalogue_1": 146800
  },
  {
    "Astrid #": "ASTRID 003269",
    "HYG Star ID": 19285,
    "Hipparcos Catalogue": 19333,
    "Distance in Parsecs": 25.9538,
    "mag": 9.71,
    "Absolute Visual Magnitude": 7.639,
    "Spectral Type": "K4V",
    "Color Index": 1.211,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.149014,
    "y": 11.637438,
    "z": -22.368714,
    "vx": 0.00000322,
    "vy": -0.00001631,
    "vz": -0.0000076,
    "rarad": 1.08470513570798,
    "decrad": -1.03893900521299,
    "pmrarad": -4.03461944958333e-7,
    "pmdecrad": -5.77510056e-7,
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 19285,
    "lum": 0.0766302072273918,
    "ra": 4.143268,
    "dec": -59.52682,
    "pmra": -83.22,
    "pmdec": -119.12,
    "rv": 0,
    "Asset Name": "ASTRID 003269",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Lion Mane",
    "Outfit": "Boxer",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": 0.00000322,
    "y_1": -0.00001631,
    "z_1": -0.0000076,
    "Distance in Parsecs_1": 25.9538,
    "HYG Star ID_1": 19285,
    "Hipparcos Catalogue_1": 19333
  },
  {
    "Astrid #": "ASTRID 003270",
    "HYG Star ID": 2076,
    "Hipparcos Catalogue": 2081,
    "Henry Draper Catalogue": 2261,
    "Harvard Revised Catalogue": 99,
    "Proper Name": "Ankaa",
    "Distance in Parsecs": 25.974,
    "mag": 2.4,
    "Absolute Visual Magnitude": 0.327,
    "Spectral Type": "K0III...",
    "Color Index": 1.083,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.083654,
    "y": 2.198282,
    "z": -17.483284,
    "vx": 0.00002323,
    "vy": 0.00003218,
    "vz": -0.00008456,
    "rarad": 0.114682902957825,
    "decrad": -0.738378668832116,
    "pmrarad": 0.00000112845232286111,
    "pmdecrad": -0.000001714495099,
    "bayer": "Alp",
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 2076,
    "lum": 64.4465984741688,
    "bf": "Alp Phe",
    "ra": 0.438056,
    "dec": -42.305981,
    "pmra": 232.76,
    "pmdec": -353.64,
    "rv": 75,
    "Asset Name": "ASTRID 003270",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Warm Smile",
    "Hair": "Antlers",
    "Outfit": "Wizard",
    "Special": "Ascendant Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Ankaa",
    "Constellation": "Phoenix",
    "x_1": 0.00002323,
    "y_1": 0.00003218,
    "z_1": -0.00008456,
    "Distance in Parsecs_1": 25.974,
    "HYG Star ID_1": 2076,
    "Hipparcos Catalogue_1": 2081,
    "Henry Draper Catalogue_1": 2261,
    "Harvard Revised Catalogue_1": 99
  },
  {
    "Astrid #": "ASTRID 003271",
    "HYG Star ID": 56320,
    "Hipparcos Catalogue": 56489,
    "Distance in Parsecs": 25.974,
    "mag": 10.38,
    "Absolute Visual Magnitude": 8.307,
    "Spectral Type": "K7V",
    "Color Index": 1.153,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.131182,
    "y": 2.770026,
    "z": -5.949732,
    "vx": 0.00000593,
    "vy": 0.00003525,
    "vz": -0.00000866,
    "rarad": 3.0318134391359,
    "decrad": -0.231116948784933,
    "pmrarad": -0.00000137415589616666,
    "pmdecrad": -3.42375421e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 56320,
    "lum": 0.04141903726221,
    "ra": 11.580674,
    "dec": -13.242026,
    "pmra": -283.44,
    "pmdec": -70.62,
    "rv": 0,
    "Asset Name": "ASTRID 003271",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Straw Hat",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00000593,
    "y_1": 0.00003525,
    "z_1": -0.00000866,
    "Distance in Parsecs_1": 25.974,
    "HYG Star ID_1": 56320,
    "Hipparcos Catalogue_1": 56489
  },
  {
    "Astrid #": "ASTRID 003272",
    "HYG Star ID": 92955,
    "Hipparcos Catalogue": 93248,
    "Gliese Catalog": "NN 4092",
    "Distance in Parsecs": 25.974,
    "mag": 10.86,
    "Absolute Visual Magnitude": 8.787,
    "Spectral Type": "M2",
    "Color Index": 1.365,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 6.618686,
    "y": -24.855898,
    "z": 3.60916,
    "vx": 0.00004511,
    "vy": 0.00000873,
    "vz": -0.00002258,
    "rarad": 4.97263234358836,
    "decrad": 0.139403906521218,
    "pmrarad": 0.00000176486724132638,
    "pmdecrad": -8.77997575e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 92955,
    "lum": 0.0266195065115183,
    "ra": 18.994056,
    "dec": 7.987255,
    "pmra": 364.03,
    "pmdec": -181.1,
    "rv": 0,
    "Asset Name": "ASTRID 003272",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Windy",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00004511,
    "y_1": 0.00000873,
    "z_1": -0.00002258,
    "Distance in Parsecs_1": 25.974,
    "HYG Star ID_1": 92955,
    "Hipparcos Catalogue_1": 93248,
    "Gliese Catalog_1": "NN 4092"
  },
  {
    "Astrid #": "ASTRID 003273",
    "HYG Star ID": 90582,
    "Hipparcos Catalogue": 90864,
    "Henry Draper Catalogue": 171067,
    "Gliese Catalog": "GJ 1229",
    "Distance in Parsecs": 25.9808,
    "mag": 7.2,
    "Absolute Visual Magnitude": 5.127,
    "Spectral Type": "G8V",
    "Color Index": 0.692,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.53137,
    "y": -24.98901,
    "z": 6.169255,
    "vx": 0.00000725,
    "vy": 0.00005019,
    "vz": 0.00000404,
    "rarad": 4.85277615871733,
    "decrad": 0.239748292837748,
    "pmrarad": 5.46481980722222e-7,
    "pmdecrad": 5.95981457e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 90582,
    "lum": 0.774818532775619,
    "ra": 18.53624,
    "dec": 13.736565,
    "pmra": 112.72,
    "pmdec": 122.93,
    "rv": -45.3,
    "Asset Name": "ASTRID 003273",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Serious Shinobi",
    "Hair": "Manbun",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000725,
    "y_1": 0.00005019,
    "z_1": 0.00000404,
    "Distance in Parsecs_1": 25.9808,
    "HYG Star ID_1": 90582,
    "Hipparcos Catalogue_1": 90864,
    "Henry Draper Catalogue_1": 171067,
    "Gliese Catalog_1": "GJ 1229"
  },
  {
    "Astrid #": "ASTRID 003274",
    "HYG Star ID": 97755,
    "Hipparcos Catalogue": 98066,
    "Henry Draper Catalogue": 188376,
    "Harvard Revised Catalogue": 7597,
    "Distance in Parsecs": 25.9875,
    "mag": 4.7,
    "Absolute Visual Magnitude": 2.626,
    "Spectral Type": "G3/G5III",
    "Color Index": 0.748,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.280499,
    "y": -20.384256,
    "z": -11.514028,
    "vx": 0.00001517,
    "vy": 0.00002565,
    "vz": 0.00001792,
    "rarad": 5.21783225222735,
    "decrad": -0.459012976862942,
    "pmrarad": 9.88825982861111e-7,
    "pmdecrad": 3.60701378e-7,
    "bayer": "Ome",
    "flam": 58,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 97755,
    "lum": 7.7553249575859,
    "bf": "58Ome Sgr",
    "ra": 19.930651,
    "dec": -26.299506,
    "pmra": 203.96,
    "pmdec": 74.4,
    "rv": -21,
    "Asset Name": "ASTRID 003274",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Sad",
    "Hair": "Fedora",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00001517,
    "y_1": 0.00002565,
    "z_1": 0.00001792,
    "Distance in Parsecs_1": 25.9875,
    "HYG Star ID_1": 97755,
    "Hipparcos Catalogue_1": 98066,
    "Henry Draper Catalogue_1": 188376,
    "Harvard Revised Catalogue_1": 7597
  },
  {
    "Astrid #": "ASTRID 003275",
    "HYG Star ID": 11530,
    "Hipparcos Catalogue": 11558,
    "Gliese Catalog": "NN 3160",
    "Distance in Parsecs": 26.0078,
    "mag": 12,
    "Absolute Visual Magnitude": 9.924,
    "Spectral Type": "M4",
    "Color Index": 1.5,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.249096,
    "y": 15.385416,
    "z": 5.446905,
    "vx": -0.00000199,
    "vy": -9.4e-7,
    "vz": 0.00001006,
    "rarad": 0.649748469837369,
    "decrad": 0.21099559072125,
    "pmrarad": 1.76956993402777e-8,
    "pmdecrad": 3.95511e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 11530,
    "lum": 0.00934114255736479,
    "var_min": 12.074,
    "var_max": 11.904,
    "ra": 2.481856,
    "dec": 12.089157,
    "pmra": 3.65,
    "pmdec": 81.58,
    "rv": 0,
    "Asset Name": "ASTRID 003275",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Greasy",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000199,
    "y_1": -9.4e-7,
    "z_1": 0.00001006,
    "Distance in Parsecs_1": 26.0078,
    "HYG Star ID_1": 11530,
    "Hipparcos Catalogue_1": 11558,
    "Gliese Catalog_1": "NN 3160"
  },
  {
    "Astrid #": "ASTRID 003276",
    "HYG Star ID": 47998,
    "Hipparcos Catalogue": 48133,
    "Henry Draper Catalogue": 85228,
    "Gliese Catalog": "Gl 368.1A",
    "Distance in Parsecs": 26.0078,
    "mag": 7.91,
    "Absolute Visual Magnitude": 5.834,
    "Spectral Type": "K1V",
    "Color Index": 0.894,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.272499,
    "y": 8.555249,
    "z": -20.665339,
    "vx": -0.00000626,
    "vy": 0.00003852,
    "vz": 0.0000143,
    "rarad": 2.56904008536679,
    "decrad": -0.918317386802072,
    "pmrarad": -0.00000111458665159722,
    "pmdecrad": 0.000001132136906,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 47998,
    "base": "Gl 368.1",
    "lum": 0.404017335373,
    "ra": 9.81301,
    "dec": -52.615711,
    "pmra": -229.9,
    "pmdec": 233.52,
    "rv": 4.4,
    "Asset Name": "ASTRID 003276",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Charmer",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000626,
    "y_1": 0.00003852,
    "z_1": 0.0000143,
    "Distance in Parsecs_1": 26.0078,
    "HYG Star ID_1": 47998,
    "Hipparcos Catalogue_1": 48133,
    "Henry Draper Catalogue_1": 85228,
    "Gliese Catalog_1": "Gl 368.1A"
  },
  {
    "Astrid #": "ASTRID 003277",
    "HYG Star ID": 43727,
    "Hipparcos Catalogue": 43852,
    "Henry Draper Catalogue": 76218,
    "Distance in Parsecs": 26.0213,
    "mag": 7.69,
    "Absolute Visual Magnitude": 5.613,
    "Spectral Type": "G5",
    "Color Index": 0.771,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.582514,
    "y": 15.110122,
    "z": 15.366931,
    "vx": 0.0000015,
    "vy": 0.00000271,
    "vz": -0.00000124,
    "rarad": 2.33842758710186,
    "decrad": 0.631742687188761,
    "pmrarad": -1.13931214930555e-7,
    "pmdecrad": -5.9292713e-8,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 43727,
    "lum": 0.495222080088521,
    "ra": 8.932135,
    "dec": 36.19619,
    "pmra": -23.5,
    "pmdec": -12.23,
    "rv": 0,
    "Asset Name": "ASTRID 003277",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Middle Part",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.0000015,
    "y_1": 0.00000271,
    "z_1": -0.00000124,
    "Distance in Parsecs_1": 26.0213,
    "HYG Star ID_1": 43727,
    "Hipparcos Catalogue_1": 43852,
    "Henry Draper Catalogue_1": 76218
  },
  {
    "Astrid #": "ASTRID 003278",
    "HYG Star ID": 116472,
    "Hipparcos Catalogue": 116838,
    "Henry Draper Catalogue": 222474,
    "Gliese Catalog": "Gl 904.1A",
    "Distance in Parsecs": 26.0213,
    "mag": 8.27,
    "Absolute Visual Magnitude": 6.193,
    "Spectral Type": "K2",
    "Color Index": 1.113,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.309493,
    "y": -2.035192,
    "z": 9.055714,
    "vx": -0.00001804,
    "vy": 0.00002974,
    "vz": 4.6e-7,
    "rarad": 6.19965939907851,
    "decrad": 0.355451639060218,
    "pmrarad": 0.00000108103754490277,
    "pmdecrad": 2.90064025e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 116472,
    "base": "Gl 904.1",
    "lum": 0.290268561049905,
    "ra": 23.680955,
    "dec": 20.365879,
    "pmra": 222.98,
    "pmdec": 59.83,
    "rv": -18.6,
    "Asset Name": "ASTRID 003278",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Round Brush",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00001804,
    "y_1": 0.00002974,
    "z_1": 4.6e-7,
    "Distance in Parsecs_1": 26.0213,
    "HYG Star ID_1": 116472,
    "Hipparcos Catalogue_1": 116838,
    "Henry Draper Catalogue_1": 222474,
    "Gliese Catalog_1": "Gl 904.1A"
  },
  {
    "Astrid #": "ASTRID 003279",
    "HYG Star ID": 71255,
    "Hipparcos Catalogue": 71481,
    "Henry Draper Catalogue": 128356,
    "Distance in Parsecs": 26.0349,
    "mag": 8.29,
    "Absolute Visual Magnitude": 6.212,
    "Spectral Type": "K3V",
    "Color Index": 0.685,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.145875,
    "y": -14.836557,
    "z": -11.332246,
    "vx": 0.0000041,
    "vy": 0.00000681,
    "vz": -0.00001547,
    "rarad": 3.82698982382219,
    "decrad": -0.450339671494394,
    "pmrarad": -1.02877463013888e-7,
    "pmdecrad": -6.60170788e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 71255,
    "lum": 0.285233151229901,
    "ra": 14.618024,
    "dec": -25.802563,
    "pmra": -21.22,
    "pmdec": -136.17,
    "rv": 0,
    "Asset Name": "ASTRID 003279",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Happy Vamp",
    "Hair": "Greasy",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.0000041,
    "y_1": 0.00000681,
    "z_1": -0.00001547,
    "Distance in Parsecs_1": 26.0349,
    "HYG Star ID_1": 71255,
    "Hipparcos Catalogue_1": 71481,
    "Henry Draper Catalogue_1": 128356
  },
  {
    "Astrid #": "ASTRID 003280",
    "HYG Star ID": 79461,
    "Hipparcos Catalogue": 79702,
    "Henry Draper Catalogue": 146413,
    "Distance in Parsecs": 26.0417,
    "mag": 8.7,
    "Absolute Visual Magnitude": 6.622,
    "Spectral Type": "K4V",
    "Color Index": 1.116,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.326926,
    "y": -23.211036,
    "z": 3.334703,
    "vx": 0.0000147,
    "vy": -0.00001956,
    "vz": -0.00005902,
    "rarad": 4.25838936605018,
    "decrad": 0.128404840325103,
    "pmrarad": 8.36691449902777e-7,
    "pmdecrad": -0.000002302477131,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 79461,
    "lum": 0.195523966962088,
    "ra": 16.265849,
    "dec": 7.357055,
    "pmra": 172.58,
    "pmdec": -474.92,
    "rv": 3.4,
    "Asset Name": "ASTRID 003280",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Arrogant",
    "Hair": "Tiny Crown",
    "Outfit": "Rider",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.0000147,
    "y_1": -0.00001956,
    "z_1": -0.00005902,
    "Distance in Parsecs_1": 26.0417,
    "HYG Star ID_1": 79461,
    "Hipparcos Catalogue_1": 79702,
    "Henry Draper Catalogue_1": 146413
  },
  {
    "Astrid #": "ASTRID 003281",
    "HYG Star ID": 119309,
    "Gliese Catalog": "Gl 769",
    "Distance in Parsecs": 26.0417,
    "mag": 12.53,
    "Absolute Visual Magnitude": 10.452,
    "Spectral Type": "M3",
    "Color Index": 1.56,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.345965,
    "y": -15.369573,
    "z": -19.296576,
    "vx": -0.0000521,
    "vy": 0.00006536,
    "vz": -0.00010915,
    "rarad": 5.20987013946553,
    "decrad": -0.83448566409465,
    "pmrarad": -5.6025068925e-7,
    "pmdecrad": -0.000005157161893,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119309,
    "lum": 0.00574380913496317,
    "ra": 19.900238,
    "dec": -47.812507,
    "pmra": -115.56,
    "pmdec": -1063.74,
    "rv": 25,
    "Asset Name": "ASTRID 003281",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Bangs",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000521,
    "y_1": 0.00006536,
    "z_1": -0.00010915,
    "Distance in Parsecs_1": 26.0417,
    "HYG Star ID_1": 119309,
    "Gliese Catalog_1": "Gl 769"
  },
  {
    "Astrid #": "ASTRID 003282",
    "HYG Star ID": 14693,
    "Hipparcos Catalogue": 14729,
    "Distance in Parsecs": 26.0552,
    "mag": 9.38,
    "Absolute Visual Magnitude": 7.301,
    "Spectral Type": "K5",
    "Color Index": 1.15,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.194025,
    "y": 18.805563,
    "z": 5.439646,
    "vx": -0.00002012,
    "vy": 0.00002388,
    "vz": -0.00001898,
    "rarad": 0.830133523556456,
    "decrad": 0.210321053983075,
    "pmrarad": 0.00000118852073788194,
    "pmdecrad": -7.4491622e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 14693,
    "lum": 0.1046164551022,
    "ra": 3.170876,
    "dec": 12.050509,
    "pmra": 245.15,
    "pmdec": -153.65,
    "rv": 0,
    "Asset Name": "ASTRID 003282",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Pompadour",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00002012,
    "y_1": 0.00002388,
    "z_1": -0.00001898,
    "Distance in Parsecs_1": 26.0552,
    "HYG Star ID_1": 14693,
    "Hipparcos Catalogue_1": 14729
  },
  {
    "Astrid #": "ASTRID 003283",
    "HYG Star ID": 113524,
    "Hipparcos Catalogue": 113884,
    "Henry Draper Catalogue": 217924,
    "Distance in Parsecs": 26.062,
    "mag": 7.22,
    "Absolute Visual Magnitude": 5.14,
    "Spectral Type": "G0V",
    "Color Index": 0.631,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 23.508308,
    "y": -5.878378,
    "z": 9.593328,
    "vx": 0.00000245,
    "vy": -0.00000124,
    "vz": -0.00000676,
    "rarad": 6.03815459037825,
    "decrad": 0.376960828356963,
    "pmrarad": -2.32710566666666e-8,
    "pmdecrad": -2.78816347e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 113524,
    "lum": 0.765596606911256,
    "ra": 23.064052,
    "dec": 21.598265,
    "pmra": -4.8,
    "pmdec": -57.51,
    "rv": 0,
    "Asset Name": "ASTRID 003283",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Star of Culture",
    "Hair": "Sweetheart",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000245,
    "y_1": -0.00000124,
    "z_1": -0.00000676,
    "Distance in Parsecs_1": 26.062,
    "HYG Star ID_1": 113524,
    "Hipparcos Catalogue_1": 113884,
    "Henry Draper Catalogue_1": 217924
  },
  {
    "Astrid #": "ASTRID 003284",
    "HYG Star ID": 13735,
    "Hipparcos Catalogue": 13769,
    "Henry Draper Catalogue": 18445,
    "Gliese Catalog": "Gl 120.1C",
    "Distance in Parsecs": 26.0756,
    "mag": 7.84,
    "Absolute Visual Magnitude": 5.759,
    "Spectral Type": "K2V",
    "Color Index": 0.96,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.915907,
    "y": 16.510396,
    "z": -11.009949,
    "vx": 0.0000303,
    "vy": 0.0000323,
    "vz": -0.00002515,
    "rarad": 0.773267169983282,
    "decrad": -0.435898076385746,
    "pmrarad": 7.48552322777777e-8,
    "pmdecrad": -1.57758371e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 3,
    "comp_primary": 13738,
    "base": "Gl 120.1",
    "lum": 0.432912374502826,
    "ra": 2.953663,
    "dec": -24.97512,
    "pmra": 15.44,
    "pmdec": -32.54,
    "rv": 49.6,
    "Asset Name": "ASTRID 003284",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Ponytail",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.0000303,
    "y_1": 0.0000323,
    "z_1": -0.00002515,
    "Distance in Parsecs_1": 26.0756,
    "HYG Star ID_1": 13735,
    "Hipparcos Catalogue_1": 13769,
    "Henry Draper Catalogue_1": 18445,
    "Gliese Catalog_1": "Gl 120.1C"
  },
  {
    "Astrid #": "ASTRID 003285",
    "HYG Star ID": 23428,
    "Hipparcos Catalogue": 23482,
    "Henry Draper Catalogue": 32743,
    "Harvard Revised Catalogue": 1649,
    "Gliese Catalog": "Gl 187",
    "Distance in Parsecs": 26.0756,
    "mag": 5.37,
    "Absolute Visual Magnitude": 3.289,
    "Spectral Type": "F2V",
    "Color Index": 0.421,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.211792,
    "y": 16.526961,
    "z": -19.724788,
    "vx": 0.00000956,
    "vy": 0.00001502,
    "vz": -0.00001431,
    "rarad": 1.32126512816417,
    "decrad": -0.857853885991867,
    "pmrarad": -2.13124093972222e-7,
    "pmdecrad": 1.31772358e-7,
    "bayer": "Eta-1",
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 23428,
    "lum": 4.21114310436318,
    "bf": "Eta1Pic",
    "ra": 5.046861,
    "dec": -49.151407,
    "pmra": -43.96,
    "pmdec": 27.18,
    "rv": 21.4,
    "Asset Name": "ASTRID 003285",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Chuckle",
    "Hair": "Bandana",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00000956,
    "y_1": 0.00001502,
    "z_1": -0.00001431,
    "Distance in Parsecs_1": 26.0756,
    "HYG Star ID_1": 23428,
    "Hipparcos Catalogue_1": 23482,
    "Henry Draper Catalogue_1": 32743,
    "Harvard Revised Catalogue_1": 1649,
    "Gliese Catalog_1": "Gl 187"
  },
  {
    "Astrid #": "ASTRID 003286",
    "HYG Star ID": 69766,
    "Hipparcos Catalogue": 69989,
    "Henry Draper Catalogue": 125451,
    "Harvard Revised Catalogue": 5365,
    "Gliese Catalog": "NN 3841",
    "Distance in Parsecs": 26.096,
    "mag": 5.41,
    "Absolute Visual Magnitude": 3.327,
    "Spectral Type": "F5IV",
    "Color Index": 0.385,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -20.874592,
    "y": -14.517858,
    "z": 5.872224,
    "vx": 0.00000935,
    "vy": -0.000009820000000000001,
    "vz": -0.00000466,
    "rarad": 3.74927837982897,
    "decrad": 0.226967832189038,
    "pmrarad": 5.13466169076388e-7,
    "pmdecrad": -1.56206967e-7,
    "flam": 18,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 69766,
    "lum": 4.06630546340428,
    "bf": "18    Boo",
    "ra": 14.321188,
    "dec": 13.004299,
    "pmra": 105.91,
    "pmdec": -32.22,
    "rv": -3,
    "Asset Name": "ASTRID 003286",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Charmer",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000935,
    "y_1": -0.000009820000000000001,
    "z_1": -0.00000466,
    "Distance in Parsecs_1": 26.096,
    "HYG Star ID_1": 69766,
    "Hipparcos Catalogue_1": 69989,
    "Henry Draper Catalogue_1": 125451,
    "Harvard Revised Catalogue_1": 5365,
    "Gliese Catalog_1": "NN 3841"
  },
  {
    "Astrid #": "ASTRID 003287",
    "HYG Star ID": 81770,
    "Hipparcos Catalogue": 82020,
    "Henry Draper Catalogue": 151613,
    "Harvard Revised Catalogue": 6237,
    "Gliese Catalog": "Wo 9578",
    "Distance in Parsecs": 26.1097,
    "mag": 4.84,
    "Absolute Visual Magnitude": 2.756,
    "Spectral Type": "F2V",
    "Color Index": 0.375,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.580191,
    "y": -13.550466,
    "z": 21.84311,
    "vx": 0.00000617,
    "vy": 0.00000786,
    "vz": 0.00000177,
    "rarad": 4.38643455910169,
    "decrad": 0.991030362786937,
    "pmrarad": 1.27554479354166e-7,
    "pmdecrad": 3.3917565e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 81770,
    "lum": 6.88018316143037,
    "ra": 16.754946,
    "dec": 56.781857,
    "pmra": 26.31,
    "pmdec": 69.96,
    "rv": -3.6,
    "Asset Name": "ASTRID 003287",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Nervous Grin",
    "Hair": "Bob",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000617,
    "y_1": 0.00000786,
    "z_1": 0.00000177,
    "Distance in Parsecs_1": 26.1097,
    "HYG Star ID_1": 81770,
    "Hipparcos Catalogue_1": 82020,
    "Henry Draper Catalogue_1": 151613,
    "Harvard Revised Catalogue_1": 6237,
    "Gliese Catalog_1": "Wo 9578"
  },
  {
    "Astrid #": "ASTRID 003288",
    "HYG Star ID": 24275,
    "Hipparcos Catalogue": 24332,
    "Henry Draper Catalogue": 33632,
    "Distance in Parsecs": 26.1165,
    "mag": 6.48,
    "Absolute Visual Magnitude": 4.395,
    "Spectral Type": "F8",
    "Color Index": 0.542,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.202759,
    "y": 20.334907,
    "z": 15.839821,
    "vx": 0.00002005,
    "vy": 0.00000645,
    "vz": -0.0000136,
    "rarad": 1.36699040010751,
    "decrad": 0.651658972896282,
    "pmrarad": -7.01816283972222e-7,
    "pmdecrad": -6.54983282e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 24275,
    "lum": 1.52054752973249,
    "ra": 5.221519,
    "dec": 37.337309,
    "pmra": -144.76,
    "pmdec": -135.1,
    "rv": 0,
    "Asset Name": "ASTRID 003288",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Honest Lad",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00002005,
    "y_1": 0.00000645,
    "z_1": -0.0000136,
    "Distance in Parsecs_1": 26.1165,
    "HYG Star ID_1": 24275,
    "Hipparcos Catalogue_1": 24332,
    "Henry Draper Catalogue_1": 33632
  },
  {
    "Astrid #": "ASTRID 003289",
    "HYG Star ID": 66368,
    "Hipparcos Catalogue": 66578,
    "Distance in Parsecs": 26.1165,
    "mag": 12.79,
    "Absolute Visual Magnitude": 10.705,
    "Spectral Type": "DA:",
    "Color Index": -0.112,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": -8.003288,
    "y": -3.682846,
    "z": 24.585679,
    "vx": -0.0000242,
    "vy": 0.00004514,
    "vz": -0.00000112,
    "rarad": 3.57290256132063,
    "decrad": 1.2267125136857,
    "pmrarad": -0.00000195767764208333,
    "pmdecrad": -1.26778777e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 66368,
    "lum": 0.00454988060150048,
    "var_min": 12.991,
    "var_max": 12.591,
    "ra": 13.647482,
    "dec": 70.28545,
    "pmra": -403.8,
    "pmdec": -26.15,
    "rv": 0,
    "Asset Name": "ASTRID 003289",
    "Astrium Color": "White",
    "Background": "Volatile Expanse",
    "Expression": "Malevolent",
    "Hair": "Hachimaki",
    "Outfit": "Wizard",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000242,
    "y_1": 0.00004514,
    "z_1": -0.00000112,
    "Distance in Parsecs_1": 26.1165,
    "HYG Star ID_1": 66368,
    "Hipparcos Catalogue_1": 66578
  },
  {
    "Astrid #": "ASTRID 003290",
    "HYG Star ID": 115740,
    "Hipparcos Catalogue": 116106,
    "Henry Draper Catalogue": 221356,
    "Harvard Revised Catalogue": 8931,
    "Gliese Catalog": "Wo 9829",
    "Distance in Parsecs": 26.1165,
    "mag": 6.5,
    "Absolute Visual Magnitude": 4.415,
    "Spectral Type": "F8V",
    "Color Index": 0.531,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.849148,
    "y": -3.228267,
    "z": -1.861529,
    "vx": -0.00001218,
    "vy": 0.00002432,
    "vz": -0.00002339,
    "rarad": 6.15893987054685,
    "decrad": -0.0713386843825725,
    "pmrarad": 8.661196403125e-7,
    "pmdecrad": -9.34672294e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 115740,
    "lum": 1.49279440957899,
    "ra": 23.525417,
    "dec": -4.087406,
    "pmra": 178.65,
    "pmdec": -192.79,
    "rv": -13.1,
    "Asset Name": "ASTRID 003290",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Stunned",
    "Hair": "Halo Boy",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00001218,
    "y_1": 0.00002432,
    "z_1": -0.00002339,
    "Distance in Parsecs_1": 26.1165,
    "HYG Star ID_1": 115740,
    "Hipparcos Catalogue_1": 116106,
    "Henry Draper Catalogue_1": 221356,
    "Harvard Revised Catalogue_1": 8931,
    "Gliese Catalog_1": "Wo 9829"
  },
  {
    "Astrid #": "ASTRID 003291",
    "HYG Star ID": 59018,
    "Hipparcos Catalogue": 59198,
    "Gliese Catalog": "Gl 456",
    "Distance in Parsecs": 26.1233,
    "mag": 11.25,
    "Absolute Visual Magnitude": 9.165,
    "Spectral Type": "M1",
    "Color Index": 1.409,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -26.104998,
    "y": -0.953811,
    "z": -0.220048,
    "vx": -0.00000937,
    "vy": 0.00012173,
    "vz": -0.00001145,
    "rarad": 3.17811389881245,
    "decrad": -0.0084235248772175,
    "pmrarad": -0.00000467030714752777,
    "pmdecrad": -4.36671682e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 59018,
    "lum": 0.0187931681680326,
    "var_min": 11.36,
    "var_max": 11.18,
    "ra": 12.139501,
    "dec": -0.482632,
    "pmra": -963.32,
    "pmdec": -90.07,
    "rv": 4.9,
    "Asset Name": "ASTRID 003291",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Smitten",
    "Hair": "Curly",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000937,
    "y_1": 0.00012173,
    "z_1": -0.00001145,
    "Distance in Parsecs_1": 26.1233,
    "HYG Star ID_1": 59018,
    "Hipparcos Catalogue_1": 59198,
    "Gliese Catalog_1": "Gl 456"
  },
  {
    "Astrid #": "ASTRID 003292",
    "HYG Star ID": 63947,
    "Hipparcos Catalogue": 64150,
    "Henry Draper Catalogue": 114174,
    "Gliese Catalog": "Wo 9429",
    "Distance in Parsecs": 26.1301,
    "mag": 6.78,
    "Absolute Visual Magnitude": 4.694,
    "Spectral Type": "G5IV",
    "Color Index": 0.667,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -24.857048,
    "y": -7.700531,
    "z": 2.37155,
    "vx": -0.00003153,
    "vy": -0.00002098,
    "vz": -0.00008191,
    "rarad": 3.44200911843957,
    "decrad": 0.0908835014948275,
    "pmrarad": 4.09764522805555e-7,
    "pmdecrad": -0.000003248057734,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 63947,
    "lum": 1.15451611692143,
    "ra": 13.147506,
    "dec": 5.207241,
    "pmra": 84.52,
    "pmdec": -669.96,
    "rv": 28.1,
    "Asset Name": "ASTRID 003292",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chuckle",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00003153,
    "y_1": -0.00002098,
    "z_1": -0.00008191,
    "Distance in Parsecs_1": 26.1301,
    "HYG Star ID_1": 63947,
    "Hipparcos Catalogue_1": 64150,
    "Henry Draper Catalogue_1": 114174,
    "Gliese Catalog_1": "Wo 9429"
  },
  {
    "Astrid #": "ASTRID 003293",
    "HYG Star ID": 19606,
    "Hipparcos Catalogue": 19655,
    "Gliese Catalog": "GJ 1067",
    "Distance in Parsecs": 26.137,
    "mag": 9.73,
    "Absolute Visual Magnitude": 7.644,
    "Spectral Type": "M0V:",
    "Color Index": 1.216,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.994932,
    "y": 7.9003,
    "z": 24.592042,
    "vx": 0.00000953,
    "vy": -0.00000908,
    "vz": 0.00000137,
    "rarad": 1.10263243678774,
    "decrad": 1.22524843953933,
    "pmrarad": -4.82195686680555e-7,
    "pmdecrad": 1.54461638e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 19606,
    "lum": 0.076278123406916,
    "var_min": 9.794,
    "var_max": 9.684,
    "ra": 4.211746,
    "dec": 70.201564,
    "pmra": -99.46,
    "pmdec": 31.86,
    "rv": 0,
    "Asset Name": "ASTRID 003293",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Audiophile",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000953,
    "y_1": -0.00000908,
    "z_1": 0.00000137,
    "Distance in Parsecs_1": 26.137,
    "HYG Star ID_1": 19606,
    "Hipparcos Catalogue_1": 19655,
    "Gliese Catalog_1": "GJ 1067"
  },
  {
    "Astrid #": "ASTRID 003294",
    "HYG Star ID": 98401,
    "Hipparcos Catalogue": 120148,
    "Distance in Parsecs": 26.137,
    "mag": 10.57,
    "Absolute Visual Magnitude": 8.484,
    "Color Index": 1.375,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 12.551392,
    "y": -21.093765,
    "z": 8.981003,
    "vx": 0.00001939,
    "vy": 0.00001084,
    "vz": -0.00000162,
    "rarad": 5.24914448731478,
    "decrad": 0.350761045950663,
    "pmrarad": 8.49635975173611e-7,
    "pmdecrad": -6.6177067e-8,
    "con": "Sge",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagitta",
    "FULL CONSTELLATION NAME": "Sagitta",
    "comp": 1,
    "comp_primary": 98401,
    "lum": 0.0351884388820958,
    "var_min": 10.641,
    "var_max": 10.501,
    "ra": 20.050255,
    "dec": 20.097128,
    "pmra": 175.25,
    "pmdec": -13.65,
    "rv": 0,
    "Asset Name": "ASTRID 003294",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "High Fade",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagitta",
    "x_1": 0.00001939,
    "y_1": 0.00001084,
    "z_1": -0.00000162,
    "Distance in Parsecs_1": 26.137,
    "HYG Star ID_1": 98401,
    "Hipparcos Catalogue_1": 120148
  },
  {
    "Astrid #": "ASTRID 003295",
    "HYG Star ID": 99380,
    "Hipparcos Catalogue": 99699,
    "Distance in Parsecs": 26.1506,
    "mag": 11.3,
    "Absolute Visual Magnitude": 9.213,
    "Spectral Type": "M0V:",
    "Color Index": 1.365,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.028557,
    "y": -21.222296,
    "z": 6.055379,
    "vx": 0.00004444,
    "vy": 0.00003009,
    "vz": 0.0000025,
    "rarad": 5.29647804116534,
    "decrad": 0.23367885260502,
    "pmrarad": 0.00000205236175389583,
    "pmdecrad": 9.8126288e-8,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 99380,
    "lum": 0.0179804269517743,
    "var_min": 11.364,
    "var_max": 11.214,
    "ra": 20.231056,
    "dec": 13.388812,
    "pmra": 423.33,
    "pmdec": 20.24,
    "rv": 0,
    "Asset Name": "ASTRID 003295",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Oh No",
    "Hair": "Medium Bob",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00004444,
    "y_1": 0.00003009,
    "z_1": 0.0000025,
    "Distance in Parsecs_1": 26.1506,
    "HYG Star ID_1": 99380,
    "Hipparcos Catalogue_1": 99699
  },
  {
    "Astrid #": "ASTRID 003296",
    "HYG Star ID": 86847,
    "Hipparcos Catalogue": 87116,
    "Henry Draper Catalogue": 161612,
    "Gliese Catalog": "Gl 695.1",
    "Distance in Parsecs": 26.1575,
    "mag": 7.2,
    "Absolute Visual Magnitude": 5.112,
    "Spectral Type": "G6/G8V",
    "Color Index": 0.748,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.138541,
    "y": -21.650833,
    "z": -14.634243,
    "vx": -0.00002398,
    "vy": 0.00003939,
    "vz": -0.0000608,
    "rarad": 4.65985271471563,
    "decrad": -0.593741345217438,
    "pmrarad": -9.94546784291666e-7,
    "pmdecrad": -0.000002741281993,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 86847,
    "lum": 0.785597332260989,
    "ra": 17.799326,
    "dec": -34.018873,
    "pmra": -205.14,
    "pmdec": -565.43,
    "rv": 2.4,
    "Asset Name": "ASTRID 003296",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Wavy",
    "Outfit": "Tracksuit",
    "Special": "Faerie",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00002398,
    "y_1": 0.00003939,
    "z_1": -0.0000608,
    "Distance in Parsecs_1": 26.1575,
    "HYG Star ID_1": 86847,
    "Hipparcos Catalogue_1": 87116,
    "Henry Draper Catalogue_1": 161612,
    "Gliese Catalog_1": "Gl 695.1"
  },
  {
    "Astrid #": "ASTRID 003297",
    "HYG Star ID": 11515,
    "Hipparcos Catalogue": 11542,
    "Gliese Catalog": "Gl 99A",
    "Distance in Parsecs": 26.1712,
    "mag": 9.57,
    "Absolute Visual Magnitude": 7.481,
    "Spectral Type": "M0p",
    "Color Index": 1.33,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.629734,
    "y": 13.378641,
    "z": 13.968951,
    "vx": -0.00004605,
    "vy": 0.00003601,
    "vz": 0.00000504,
    "rarad": 0.649149061067497,
    "decrad": 0.563034306706542,
    "pmrarad": 0.00000215989342824305,
    "pmdecrad": 4.67166462e-7,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 11515,
    "base": "Gl  99",
    "lum": 0.0886339287327933,
    "ra": 2.479567,
    "dec": 32.259489,
    "pmra": 445.51,
    "pmdec": 96.36,
    "rv": -9.7,
    "Asset Name": "ASTRID 003297",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Headband",
    "Outfit": "Astro",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.00004605,
    "y_1": 0.00003601,
    "z_1": 0.00000504,
    "Distance in Parsecs_1": 26.1712,
    "HYG Star ID_1": 11515,
    "Hipparcos Catalogue_1": 11542,
    "Gliese Catalog_1": "Gl 99A"
  },
  {
    "Astrid #": "ASTRID 003298",
    "HYG Star ID": 61136,
    "Hipparcos Catalogue": 61329,
    "Henry Draper Catalogue": 109333,
    "Gliese Catalog": "NN 3735",
    "Distance in Parsecs": 26.1712,
    "mag": 9.1,
    "Absolute Visual Magnitude": 7.011,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.103,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.043669,
    "y": -3.742327,
    "z": -6.614068,
    "vx": -0.00001805,
    "vy": 0.00006176,
    "vz": -0.00000587,
    "rarad": 3.28992780689375,
    "decrad": -0.255492876340795,
    "pmrarad": -0.00000243589785658333,
    "pmdecrad": -1.32838948e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 61136,
    "lum": 0.136646968074046,
    "ra": 12.566599,
    "dec": -14.638664,
    "pmra": -502.44,
    "pmdec": -27.4,
    "rv": 9.7,
    "Asset Name": "ASTRID 003298",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Lion Mane",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001805,
    "y_1": 0.00006176,
    "z_1": -0.00000587,
    "Distance in Parsecs_1": 26.1712,
    "HYG Star ID_1": 61136,
    "Hipparcos Catalogue_1": 61329,
    "Henry Draper Catalogue_1": 109333,
    "Gliese Catalog_1": "NN 3735"
  },
  {
    "Astrid #": "ASTRID 003299",
    "HYG Star ID": 10315,
    "Hipparcos Catalogue": 10339,
    "Henry Draper Catalogue": 13531,
    "Distance in Parsecs": 26.1849,
    "mag": 7.35,
    "Absolute Visual Magnitude": 5.26,
    "Spectral Type": "G0",
    "Color Index": 0.7,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.638945,
    "y": 10.932058,
    "z": 17.008369,
    "vx": 0.00000256,
    "vy": 0.00001051,
    "vz": -0.00000926,
    "rarad": 0.58129098909576,
    "decrad": 0.706990788807208,
    "pmrarad": 2.81725229770833e-7,
    "pmdecrad": -4.64936319e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 10315,
    "lum": 0.685488226452661,
    "var": "V451",
    "var_min": 7.374,
    "var_max": 7.334,
    "ra": 2.220368,
    "dec": 40.507588,
    "pmra": 58.11,
    "pmdec": -95.9,
    "rv": 0,
    "Asset Name": "ASTRID 003299",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Charmer",
    "Hair": "Afro",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000256,
    "y_1": 0.00001051,
    "z_1": -0.00000926,
    "Distance in Parsecs_1": 26.1849,
    "HYG Star ID_1": 10315,
    "Hipparcos Catalogue_1": 10339,
    "Henry Draper Catalogue_1": 13531
  },
  {
    "Astrid #": "ASTRID 003300",
    "HYG Star ID": 109511,
    "Hipparcos Catalogue": 109857,
    "Henry Draper Catalogue": 211336,
    "Harvard Revised Catalogue": 8494,
    "Gliese Catalog": "NN 4266",
    "Distance in Parsecs": 26.1986,
    "mag": 4.18,
    "Absolute Visual Magnitude": 2.089,
    "Spectral Type": "F0IV",
    "Color Index": 0.278,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.783283,
    "y": -6.301488,
    "z": 21.982833,
    "vx": 0.00002128,
    "vy": 0.00005697,
    "vz": 0.00000225,
    "rarad": 5.82517868057933,
    "decrad": 0.99559840405985,
    "pmrarad": 0.00000230989478100694,
    "pmdecrad": 2.4240684e-7,
    "bayer": "Eps",
    "flam": 23,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 109511,
    "lum": 12.7174488628806,
    "var": "Eps",
    "var_min": 4.192,
    "var_max": 4.162,
    "bf": "23Eps Cep",
    "ra": 22.250544,
    "dec": 57.043587,
    "pmra": 476.45,
    "pmdec": 50,
    "rv": -1.4,
    "Asset Name": "ASTRID 003300",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Determined Shinobi",
    "Hair": "Round Brush",
    "Outfit": "Scrubs",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00002128,
    "y_1": 0.00005697,
    "z_1": 0.00000225,
    "Distance in Parsecs_1": 26.1986,
    "HYG Star ID_1": 109511,
    "Hipparcos Catalogue_1": 109857,
    "Henry Draper Catalogue_1": 211336,
    "Harvard Revised Catalogue_1": 8494,
    "Gliese Catalog_1": "NN 4266"
  },
  {
    "Astrid #": "ASTRID 003301",
    "HYG Star ID": 112558,
    "Hipparcos Catalogue": 112918,
    "Gliese Catalog": "NN 4301",
    "Distance in Parsecs": 26.1986,
    "mag": 9.79,
    "Absolute Visual Magnitude": 7.699,
    "Spectral Type": "K8",
    "Color Index": 1.2,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 22.992238,
    "y": -7.024826,
    "z": 10.410259,
    "vx": 0.0000086,
    "vy": 0.00001502,
    "vz": -0.00001967,
    "rarad": 5.98666209762551,
    "decrad": 0.408638090620906,
    "pmrarad": 6.44414344194444e-7,
    "pmdecrad": -7.47243325e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112558,
    "lum": 0.0725103497617198,
    "ra": 22.867365,
    "dec": 23.413238,
    "pmra": 132.92,
    "pmdec": -154.13,
    "rv": -4.2,
    "Asset Name": "ASTRID 003301",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Elegant",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.0000086,
    "y_1": 0.00001502,
    "z_1": -0.00001967,
    "Distance in Parsecs_1": 26.1986,
    "HYG Star ID_1": 112558,
    "Hipparcos Catalogue_1": 112918,
    "Gliese Catalog_1": "NN 4301"
  },
  {
    "Astrid #": "ASTRID 003302",
    "HYG Star ID": 74272,
    "Hipparcos Catalogue": 74500,
    "Henry Draper Catalogue": 134987,
    "Harvard Revised Catalogue": 5657,
    "Gliese Catalog": "Gl 579.4",
    "Distance in Parsecs": 26.2055,
    "mag": 6.47,
    "Absolute Visual Magnitude": 4.378,
    "Spectral Type": "G5V",
    "Color Index": 0.691,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.737957,
    "y": -17.707061,
    "z": -11.203013,
    "vx": -0.00003856,
    "vy": 0.00003292,
    "vz": -0.00001103,
    "rarad": 3.98580057751816,
    "decrad": -0.441731434831897,
    "pmrarad": -0.00000193445506678472,
    "pmdecrad": -3.64095074e-7,
    "flam": 23,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 74272,
    "lum": 1.54454298740142,
    "bf": "23    Lib",
    "ra": 15.224637,
    "dec": -25.309347,
    "pmra": -399.01,
    "pmdec": -75.1,
    "rv": 5.5,
    "Asset Name": "ASTRID 003302",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Hurt",
    "Hair": "Hachimaki",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00003856,
    "y_1": 0.00003292,
    "z_1": -0.00001103,
    "Distance in Parsecs_1": 26.2055,
    "HYG Star ID_1": 74272,
    "Hipparcos Catalogue_1": 74500,
    "Henry Draper Catalogue_1": 134987,
    "Harvard Revised Catalogue_1": 5657,
    "Gliese Catalog_1": "Gl 579.4"
  },
  {
    "Astrid #": "ASTRID 003303",
    "HYG Star ID": 118651,
    "Gliese Catalog": "Gl 368.1B",
    "Distance in Parsecs": 26.2192,
    "mag": 12,
    "Absolute Visual Magnitude": 9.907,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -13.380689,
    "y": 8.625053,
    "z": -20.832957,
    "vx": -0.00000231,
    "vy": 0.00004051,
    "vz": 0.00001825,
    "rarad": 2.56907078091486,
    "decrad": -0.918296942448199,
    "pmrarad": -0.00000125106170267361,
    "pmdecrad": 0.000001146371036,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 2,
    "comp_primary": 47998,
    "base": "Gl 368.1",
    "lum": 0.009488553267278,
    "ra": 9.813128,
    "dec": -52.614539,
    "pmra": -258.05,
    "pmdec": 236.46,
    "rv": 0,
    "Asset Name": "ASTRID 003303",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Audiohead",
    "Outfit": "Spartan",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000231,
    "y_1": 0.00004051,
    "z_1": 0.00001825,
    "Distance in Parsecs_1": 26.2192,
    "HYG Star ID_1": 118651,
    "Gliese Catalog_1": "Gl 368.1B"
  },
  {
    "Astrid #": "ASTRID 003304",
    "HYG Star ID": 87475,
    "Hipparcos Catalogue": 87745,
    "Gliese Catalog": "Gl 697.1",
    "Distance in Parsecs": 26.2261,
    "mag": 10.12,
    "Absolute Visual Magnitude": 8.026,
    "Spectral Type": "M0",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.523742,
    "y": -26.164598,
    "z": 1.717331,
    "vx": -0.00001002,
    "vy": -0.00000263,
    "vz": 0.00000368,
    "rarad": 4.69237447732972,
    "decrad": 0.0655285640376387,
    "pmrarad": -3.80190888291666e-7,
    "pmdecrad": 1.33032873e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 87475,
    "lum": 0.0536537399519851,
    "ra": 17.92355,
    "dec": 3.75451,
    "pmra": -78.42,
    "pmdec": 27.44,
    "rv": 3,
    "Asset Name": "ASTRID 003304",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Medium Bob",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001002,
    "y_1": -0.00000263,
    "z_1": 0.00000368,
    "Distance in Parsecs_1": 26.2261,
    "HYG Star ID_1": 87475,
    "Hipparcos Catalogue_1": 87745,
    "Gliese Catalog_1": "Gl 697.1"
  },
  {
    "Astrid #": "ASTRID 003305",
    "HYG Star ID": 78005,
    "Hipparcos Catalogue": 78241,
    "Henry Draper Catalogue": 143291,
    "Gliese Catalog": "Wo 9533",
    "Distance in Parsecs": 26.2329,
    "mag": 8.02,
    "Absolute Visual Magnitude": 5.926,
    "Spectral Type": "K0V",
    "Color Index": 0.757,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.737082,
    "y": -20.032156,
    "z": 12.210074,
    "vx": -0.00004432,
    "vy": 0.0001195,
    "vz": 1e-7,
    "rarad": 4.18239693860481,
    "decrad": 0.48415424564381,
    "pmrarad": -0.00000376084516417361,
    "pmdecrad": 0.000001435387864,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 78005,
    "lum": 0.37119319004414,
    "ra": 15.97558,
    "dec": 27.739995,
    "pmra": -775.73,
    "pmdec": 296.07,
    "rv": -69.8,
    "Asset Name": "ASTRID 003305",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Guilty Laugh",
    "Hair": "Mohawk",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": -0.00004432,
    "y_1": 0.0001195,
    "z_1": 1e-7,
    "Distance in Parsecs_1": 26.2329,
    "HYG Star ID_1": 78005,
    "Hipparcos Catalogue_1": 78241,
    "Henry Draper Catalogue_1": 143291,
    "Gliese Catalog_1": "Wo 9533"
  },
  {
    "Astrid #": "ASTRID 003306",
    "HYG Star ID": 32337,
    "Hipparcos Catalogue": 32423,
    "Henry Draper Catalogue": 263175,
    "Gliese Catalog": "NN 3408A",
    "Distance in Parsecs": 26.2398,
    "mag": 8.8,
    "Absolute Visual Magnitude": 6.705,
    "Spectral Type": "K3V",
    "Color Index": 0.964,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.417274,
    "y": 21.670836,
    "z": 14.119967,
    "vx": 0.00006394,
    "vy": -0.00002423,
    "vz": -0.00000838,
    "rarad": 1.771880164687,
    "decrad": 0.568203753153231,
    "pmrarad": -0.00000220323577128472,
    "pmdecrad": 4.79674655e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 32337,
    "base": "NN 3408",
    "lum": 0.18113400926196,
    "ra": 6.768084,
    "dec": 32.555677,
    "pmra": -454.45,
    "pmdec": 98.94,
    "rv": -34.5,
    "Asset Name": "ASTRID 003306",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Angelic",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.00006394,
    "y_1": -0.00002423,
    "z_1": -0.00000838,
    "Distance in Parsecs_1": 26.2398,
    "HYG Star ID_1": 32337,
    "Hipparcos Catalogue_1": 32423,
    "Henry Draper Catalogue_1": 263175,
    "Gliese Catalog_1": "NN 3408A"
  },
  {
    "Astrid #": "ASTRID 003307",
    "HYG Star ID": 42054,
    "Hipparcos Catalogue": 42173,
    "Henry Draper Catalogue": 72946,
    "Harvard Revised Catalogue": 3396,
    "Gliese Catalog": "Gl 310.1B",
    "Distance in Parsecs": 26.2398,
    "mag": 7.25,
    "Absolute Visual Magnitude": 5.155,
    "Spectral Type": "G5V",
    "Color Index": 0.71,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.390351,
    "y": 20.266688,
    "z": 3.02632,
    "vx": -0.00000795,
    "vy": 0.00003424,
    "vz": -0.0000134,
    "rarad": 2.25084001703415,
    "decrad": 0.115589241557591,
    "pmrarad": -5.85024668326388e-7,
    "pmdecrad": -6.46450561e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 2,
    "comp_primary": 42053,
    "base": "Gl 310.1",
    "lum": 0.755092227665433,
    "ra": 8.597576,
    "dec": 6.622776,
    "pmra": -120.67,
    "pmdec": -133.34,
    "rv": 29.2,
    "Asset Name": "ASTRID 003307",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "High Fade",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00000795,
    "y_1": 0.00003424,
    "z_1": -0.0000134,
    "Distance in Parsecs_1": 26.2398,
    "HYG Star ID_1": 42054,
    "Hipparcos Catalogue_1": 42173,
    "Henry Draper Catalogue_1": 72946,
    "Harvard Revised Catalogue_1": 3396,
    "Gliese Catalog_1": "Gl 310.1B"
  },
  {
    "Astrid #": "ASTRID 003308",
    "HYG Star ID": 97111,
    "Hipparcos Catalogue": 97420,
    "Henry Draper Catalogue": 187237,
    "Distance in Parsecs": 26.2398,
    "mag": 6.87,
    "Absolute Visual Magnitude": 4.775,
    "Spectral Type": "G2III",
    "Color Index": 0.66,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.532129,
    "y": -20.66719,
    "z": 12.265907,
    "vx": -0.00002111,
    "vy": 0.00003898,
    "vz": 0.00000855,
    "rarad": 5.18369205778401,
    "decrad": 0.486415060535252,
    "pmrarad": -4.20818274722222e-8,
    "pmdecrad": 0.000001077546886,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 97111,
    "lum": 1.0715193052376,
    "ra": 19.800245,
    "dec": 27.86953,
    "pmra": -8.68,
    "pmdec": 222.26,
    "rv": -34.4,
    "Asset Name": "ASTRID 003308",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Slick",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": -0.00002111,
    "y_1": 0.00003898,
    "z_1": 0.00000855,
    "Distance in Parsecs_1": 26.2398,
    "HYG Star ID_1": 97111,
    "Hipparcos Catalogue_1": 97420,
    "Henry Draper Catalogue_1": 187237
  },
  {
    "Astrid #": "ASTRID 003309",
    "HYG Star ID": 19791,
    "Hipparcos Catalogue": 19841,
    "Henry Draper Catalogue": 26018,
    "Gliese Catalog": "NN 3267",
    "Distance in Parsecs": 26.2467,
    "mag": 8.19,
    "Absolute Visual Magnitude": 6.095,
    "Spectral Type": "G5",
    "Color Index": 0.84,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.746166,
    "y": 5.582705,
    "z": 25.498748,
    "vx": 0.00000941,
    "vy": 0.0000314,
    "vz": 0.00000306,
    "rarad": 1.11363903644614,
    "decrad": 1.33147697712833,
    "pmrarad": 2.06336702444444e-7,
    "pmdecrad": -0.00000116912819,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 19791,
    "lum": 0.317687407064977,
    "ra": 4.253788,
    "dec": 76.288011,
    "pmra": 42.56,
    "pmdec": -241.15,
    "rv": 10.4,
    "Asset Name": "ASTRID 003309",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Battle Ready",
    "Hair": "Greasy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000941,
    "y_1": 0.0000314,
    "z_1": 0.00000306,
    "Distance in Parsecs_1": 26.2467,
    "HYG Star ID_1": 19791,
    "Hipparcos Catalogue_1": 19841,
    "Henry Draper Catalogue_1": 26018,
    "Gliese Catalog_1": "NN 3267"
  },
  {
    "Astrid #": "ASTRID 003310",
    "HYG Star ID": 27821,
    "Hipparcos Catalogue": 27890,
    "Henry Draper Catalogue": 40409,
    "Harvard Revised Catalogue": 2102,
    "Gliese Catalog": "Gl 224.1",
    "Distance in Parsecs": 26.2467,
    "mag": 4.65,
    "Absolute Visual Magnitude": 2.555,
    "Spectral Type": "K1III/IV",
    "Color Index": 1.022,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.305734,
    "y": 11.875322,
    "z": -23.404792,
    "vx": -0.0000133,
    "vy": 0.00007705,
    "vz": 0.00001013,
    "rarad": 1.54505054620997,
    "decrad": -1.10112171641298,
    "pmrarad": 5.82309711715277e-7,
    "pmdecrad": 0.000002780067088,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 27821,
    "lum": 8.27942163712333,
    "ra": 5.901658,
    "dec": -63.089627,
    "pmra": 120.11,
    "pmdec": 573.43,
    "rv": 25.1,
    "Asset Name": "ASTRID 003310",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Blep",
    "Hair": "Braided Bun",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.0000133,
    "y_1": 0.00007705,
    "z_1": 0.00001013,
    "Distance in Parsecs_1": 26.2467,
    "HYG Star ID_1": 27821,
    "Hipparcos Catalogue_1": 27890,
    "Henry Draper Catalogue_1": 40409,
    "Harvard Revised Catalogue_1": 2102,
    "Gliese Catalog_1": "Gl 224.1"
  },
  {
    "Astrid #": "ASTRID 003311",
    "HYG Star ID": 34251,
    "Hipparcos Catalogue": 34341,
    "Gliese Catalog": "Gl 266",
    "Distance in Parsecs": 26.2467,
    "mag": 9.84,
    "Absolute Visual Magnitude": 7.745,
    "Spectral Type": "M0V",
    "Color Index": 1.27,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.567492,
    "y": 25.082491,
    "z": 1.578282,
    "vx": -0.00000189,
    "vy": 0.00000159,
    "vz": -0.00003439,
    "rarad": 1.86381582636153,
    "decrad": 0.0601688948851175,
    "pmrarad": 5.15841756111111e-8,
    "pmdecrad": -0.000001312584558,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 34251,
    "lum": 0.0695024317588796,
    "ra": 7.119252,
    "dec": 3.447424,
    "pmra": 10.64,
    "pmdec": -270.74,
    "rv": 0,
    "Asset Name": "ASTRID 003311",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Angelic",
    "Outfit": "Viking",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00000189,
    "y_1": 0.00000159,
    "z_1": -0.00003439,
    "Distance in Parsecs_1": 26.2467,
    "HYG Star ID_1": 34251,
    "Hipparcos Catalogue_1": 34341,
    "Gliese Catalog_1": "Gl 266"
  },
  {
    "Astrid #": "ASTRID 003312",
    "HYG Star ID": 41014,
    "Hipparcos Catalogue": 41130,
    "Gliese Catalog": "Gl 305.1",
    "Distance in Parsecs": 26.2467,
    "mag": 9.52,
    "Absolute Visual Magnitude": 7.425,
    "Spectral Type": "M0",
    "Color Index": 1.178,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.277347,
    "y": 19.738754,
    "z": 9.76808,
    "vx": -0.0000224,
    "vy": -0.00003394,
    "vz": -0.00003919,
    "rarad": 2.19699854008949,
    "decrad": 0.381343291166891,
    "pmrarad": 0.000001449495942125,
    "pmdecrad": -0.000001182266641,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 41014,
    "lum": 0.0933254300796991,
    "ra": 8.391916,
    "dec": 21.849361,
    "pmra": 298.98,
    "pmdec": -243.86,
    "rv": -27.3,
    "Asset Name": "ASTRID 003312",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Greasy",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.0000224,
    "y_1": -0.00003394,
    "z_1": -0.00003919,
    "Distance in Parsecs_1": 26.2467,
    "HYG Star ID_1": 41014,
    "Hipparcos Catalogue_1": 41130,
    "Gliese Catalog_1": "Gl 305.1"
  },
  {
    "Astrid #": "ASTRID 003313",
    "HYG Star ID": 118450,
    "Gliese Catalog": "NN 3416B",
    "Distance in Parsecs": 26.2467,
    "mag": 11.1,
    "Absolute Visual Magnitude": 9.005,
    "Spectral Type": "M1",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -4.898862,
    "y": 19.478955,
    "z": 16.89965,
    "vx": -0.00003389,
    "vy": 0.00007022,
    "vz": -0.00000941,
    "rarad": 1.81717248672614,
    "decrad": 0.6994708766798,
    "pmrarad": 5.99714522847222e-7,
    "pmdecrad": -0.000002147986404,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 2,
    "comp_primary": 118450,
    "base": "NN 3416",
    "lum": 0.0217770977235315,
    "ra": 6.941088,
    "dec": 40.076729,
    "pmra": 123.7,
    "pmdec": -443.05,
    "rv": 51.2,
    "Asset Name": "ASTRID 003313",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Audiophile",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00003389,
    "y_1": 0.00007022,
    "z_1": -0.00000941,
    "Distance in Parsecs_1": 26.2467,
    "HYG Star ID_1": 118450,
    "Gliese Catalog_1": "NN 3416B"
  },
  {
    "Astrid #": "ASTRID 003314",
    "HYG Star ID": 119447,
    "Gliese Catalog": "NN 4227",
    "Distance in Parsecs": 26.2467,
    "mag": 13.42,
    "Absolute Visual Magnitude": 11.325,
    "Spectral Type": "m",
    "Color Index": 1.5,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.658769,
    "y": -13.628956,
    "z": 5.834255,
    "vx": 0.00003763,
    "vy": 0.00006982,
    "vz": 0.00002344,
    "rarad": 5.72152597108617,
    "decrad": 0.2241577792269,
    "pmrarad": 0.00000301520172347916,
    "pmdecrad": 9.16084538e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119447,
    "lum": 0.00257039578276886,
    "ra": 21.854619,
    "dec": 12.843295,
    "pmra": 621.93,
    "pmdec": 188.96,
    "rv": 0,
    "Asset Name": "ASTRID 003314",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Fired Up",
    "Hair": "Round Brush",
    "Outfit": "Wizard",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003763,
    "y_1": 0.00006982,
    "z_1": 0.00002344,
    "Distance in Parsecs_1": 26.2467,
    "HYG Star ID_1": 119447,
    "Gliese Catalog_1": "NN 4227"
  },
  {
    "Astrid #": "ASTRID 003315",
    "HYG Star ID": 100185,
    "Hipparcos Catalogue": 100511,
    "Henry Draper Catalogue": 194012,
    "Harvard Revised Catalogue": 7793,
    "Distance in Parsecs": 26.2536,
    "mag": 6.16,
    "Absolute Visual Magnitude": 4.064,
    "Spectral Type": "F8V",
    "Color Index": 0.498,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.835217,
    "y": -20.631552,
    "z": 6.596046,
    "vx": 0.00000945,
    "vy": 0.00000409,
    "vz": -3.3e-7,
    "rarad": 5.33578915612299,
    "decrad": 0.253964565566383,
    "pmrarad": 3.83099770374999e-7,
    "pmdecrad": -3.3306699e-8,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 100185,
    "lum": 2.06252869784657,
    "ra": 20.381213,
    "dec": 14.551098,
    "pmra": 79.02,
    "pmdec": -6.87,
    "rv": 2,
    "Asset Name": "ASTRID 003315",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Windy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00000945,
    "y_1": 0.00000409,
    "z_1": -3.3e-7,
    "Distance in Parsecs_1": 26.2536,
    "HYG Star ID_1": 100185,
    "Hipparcos Catalogue_1": 100511,
    "Henry Draper Catalogue_1": 194012,
    "Harvard Revised Catalogue_1": 7793
  },
  {
    "Astrid #": "ASTRID 003316",
    "HYG Star ID": 34622,
    "Hipparcos Catalogue": 34712,
    "Distance in Parsecs": 26.2605,
    "mag": 11.16,
    "Absolute Visual Magnitude": 9.063,
    "Spectral Type": "M:",
    "Color Index": 1.44,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.126297,
    "y": 9.718516,
    "z": -24.194846,
    "vx": 0.00001204,
    "vy": -0.00007779,
    "vz": -0.0000328,
    "rarad": 1.88202010082559,
    "decrad": -1.17151310419361,
    "pmrarad": 4.70608639715277e-7,
    "pmdecrad": -0.000003213393556,
    "con": "Vol",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Volans",
    "FULL CONSTELLATION NAME": "Volans",
    "comp": 1,
    "comp_primary": 34622,
    "lum": 0.0206442923207398,
    "var_min": 11.225,
    "var_max": 11.085,
    "ra": 7.188787,
    "dec": -67.12275700000001,
    "pmra": 97.07,
    "pmdec": -662.81,
    "rv": 0,
    "Asset Name": "ASTRID 003316",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Spiky",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Volans",
    "x_1": 0.00001204,
    "y_1": -0.00007779,
    "z_1": -0.0000328,
    "Distance in Parsecs_1": 26.2605,
    "HYG Star ID_1": 34622,
    "Hipparcos Catalogue_1": 34712
  },
  {
    "Astrid #": "ASTRID 003317",
    "HYG Star ID": 94355,
    "Hipparcos Catalogue": 94650,
    "Henry Draper Catalogue": 180263,
    "Distance in Parsecs": 26.2674,
    "mag": 8.06,
    "Absolute Visual Magnitude": 5.963,
    "Spectral Type": "K0",
    "Color Index": 0.908,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.334358,
    "y": -24.348138,
    "z": 5.261456,
    "vx": 0.00002359,
    "vy": 0.00000341,
    "vz": -0.0000216,
    "rarad": 5.04218721365183,
    "decrad": 0.201667857466582,
    "pmrarad": 8.91960209486111e-7,
    "pmdecrad": -8.39260962e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 94355,
    "lum": 0.358756683039261,
    "var": "V1688",
    "var_min": 8.085,
    "var_max": 8.035,
    "ra": 19.259736,
    "dec": 11.554717,
    "pmra": 183.98,
    "pmdec": -173.11,
    "rv": 0,
    "Asset Name": "ASTRID 003317",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Sweetheart",
    "Outfit": "Warlock",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00002359,
    "y_1": 0.00000341,
    "z_1": -0.0000216,
    "Distance in Parsecs_1": 26.2674,
    "HYG Star ID_1": 94355,
    "Hipparcos Catalogue_1": 94650,
    "Henry Draper Catalogue_1": 180263
  },
  {
    "Astrid #": "ASTRID 003318",
    "HYG Star ID": 53998,
    "Hipparcos Catalogue": 54155,
    "Henry Draper Catalogue": 96064,
    "Distance in Parsecs": 26.2743,
    "mag": 7.64,
    "Absolute Visual Magnitude": 5.542,
    "Spectral Type": "G5",
    "Color Index": 0.77,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -25.443691,
    "y": 6.262377,
    "z": -1.933928,
    "vx": 0.00000637,
    "vy": 0.00002178,
    "vz": -0.00001323,
    "rarad": 2.90026260187534,
    "decrad": -0.0736719193192437,
    "pmrarad": -8.62871388652777e-7,
    "pmdecrad": -5.04836485e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 53998,
    "lum": 0.52868866585088,
    "var": "HH",
    "var_min": 7.698,
    "var_max": 7.608,
    "ra": 11.078187,
    "dec": -4.22109,
    "pmra": -177.98,
    "pmdec": -104.13,
    "rv": 0,
    "Asset Name": "ASTRID 003318",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Guilty Laugh",
    "Hair": "Honest Lad",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000637,
    "y_1": 0.00002178,
    "z_1": -0.00001323,
    "Distance in Parsecs_1": 26.2743,
    "HYG Star ID_1": 53998,
    "Hipparcos Catalogue_1": 54155,
    "Henry Draper Catalogue_1": 96064
  },
  {
    "Astrid #": "ASTRID 003319",
    "HYG Star ID": 57941,
    "Hipparcos Catalogue": 58114,
    "Gliese Catalog": "Wo 9381",
    "Distance in Parsecs": 26.2812,
    "mag": 11.47,
    "Absolute Visual Magnitude": 9.372,
    "Spectral Type": "M2:",
    "Color Index": 1.419,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -26.271448,
    "y": 0.559457,
    "z": 0.446636,
    "vx": 0.00000207,
    "vy": 0.0000939,
    "vz": 0.00000416,
    "rarad": 3.12030062924511,
    "decrad": 0.0169953359005037,
    "pmrarad": -0.00000357414341579166,
    "pmdecrad": 1.58388629e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 57941,
    "lum": 0.0155310207523284,
    "var_min": 11.553,
    "var_max": 11.383,
    "ra": 11.91867,
    "dec": 0.973761,
    "pmra": -737.22,
    "pmdec": 32.67,
    "rv": 0,
    "Asset Name": "ASTRID 003319",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Good Boy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000207,
    "y_1": 0.0000939,
    "z_1": 0.00000416,
    "Distance in Parsecs_1": 26.2812,
    "HYG Star ID_1": 57941,
    "Hipparcos Catalogue_1": 58114,
    "Gliese Catalog_1": "Wo 9381"
  },
  {
    "Astrid #": "ASTRID 003320",
    "HYG Star ID": 23728,
    "Hipparcos Catalogue": 23783,
    "Henry Draper Catalogue": 32537,
    "Harvard Revised Catalogue": 1637,
    "Gliese Catalog": "Gl 187.2A",
    "Distance in Parsecs": 26.2881,
    "mag": 4.98,
    "Absolute Visual Magnitude": 2.881,
    "Spectral Type": "F0V",
    "Color Index": 0.343,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.765141,
    "y": 15.889624,
    "z": 20.601167,
    "vx": 0.00000773,
    "vy": 0.00001641,
    "vz": -0.00001329,
    "rarad": 1.33813238463471,
    "decrad": 0.900550104832776,
    "pmrarad": -1.42098889770833e-7,
    "pmdecrad": -8.43333397e-7,
    "flam": 9,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 23728,
    "base": "Gl 187.2",
    "lum": 6.13196969715682,
    "var": "V398",
    "var_min": 5.002,
    "var_max": 4.942,
    "bf": "9    Aur",
    "ra": 5.111289,
    "dec": 51.59772,
    "pmra": -29.31,
    "pmdec": -173.95,
    "rv": 0.6,
    "Asset Name": "ASTRID 003320",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Determined Neko",
    "Hair": "Middle Part",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000773,
    "y_1": 0.00001641,
    "z_1": -0.00001329,
    "Distance in Parsecs_1": 26.2881,
    "HYG Star ID_1": 23728,
    "Hipparcos Catalogue_1": 23783,
    "Henry Draper Catalogue_1": 32537,
    "Harvard Revised Catalogue_1": 1637,
    "Gliese Catalog_1": "Gl 187.2A"
  },
  {
    "Astrid #": "ASTRID 003321",
    "HYG Star ID": 56113,
    "Hipparcos Catalogue": 56280,
    "Henry Draper Catalogue": 100286,
    "Harvard Revised Catalogue": 4443,
    "Distance in Parsecs": 26.295,
    "mag": 4.93,
    "Absolute Visual Magnitude": 2.831,
    "Spectral Type": "F8V",
    "Color Index": 0.54,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -22.772217,
    "y": 2.768505,
    "z": -12.852752,
    "vx": -0.00001748,
    "vy": 0.00000473,
    "vz": 0.00001106,
    "rarad": 3.02061265200127,
    "decrad": -0.510701184379152,
    "pmrarad": -9.85141398888888e-8,
    "pmdecrad": 7.00070954e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 56113,
    "lum": 6.42096052567594,
    "ra": 11.53789,
    "dec": -29.261022,
    "pmra": -20.32,
    "pmdec": 144.4,
    "rv": 10,
    "Asset Name": "ASTRID 003321",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Fire",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00001748,
    "y_1": 0.00000473,
    "z_1": 0.00001106,
    "Distance in Parsecs_1": 26.295,
    "HYG Star ID_1": 56113,
    "Hipparcos Catalogue_1": 56280,
    "Henry Draper Catalogue_1": 100286,
    "Harvard Revised Catalogue_1": 4443
  },
  {
    "Astrid #": "ASTRID 003322",
    "HYG Star ID": 48372,
    "Hipparcos Catalogue": 48509,
    "Distance in Parsecs": 26.3019,
    "mag": 12.02,
    "Absolute Visual Magnitude": 9.92,
    "Color Index": 1.51,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.171984,
    "y": 8.722188,
    "z": 20.368315,
    "vx": 0.00003696,
    "vy": 0.00005652,
    "vz": 0.00000151,
    "rarad": 2.58990119467301,
    "decrad": 0.885773666549017,
    "pmrarad": -0.00000256689451306944,
    "pmdecrad": 9.0805602e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 48372,
    "lum": 0.0093756200692588,
    "var_min": 12.125,
    "var_max": 11.875,
    "ra": 9.892694,
    "dec": 50.751093,
    "pmra": -529.46,
    "pmdec": 18.73,
    "rv": 0,
    "Asset Name": "ASTRID 003322",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Greasy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003696,
    "y_1": 0.00005652,
    "z_1": 0.00000151,
    "Distance in Parsecs_1": 26.3019,
    "HYG Star ID_1": 48372,
    "Hipparcos Catalogue_1": 48509
  },
  {
    "Astrid #": "ASTRID 003323",
    "HYG Star ID": 74752,
    "Hipparcos Catalogue": 74981,
    "Distance in Parsecs": 26.3019,
    "mag": 10.25,
    "Absolute Visual Magnitude": 8.15,
    "Spectral Type": "K7",
    "Color Index": 1.26,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.806825,
    "y": -17.545185,
    "z": 12.83411,
    "vx": 0.00000193,
    "vy": 0.00003052,
    "vz": 0.00004395,
    "rarad": 4.01143305240463,
    "decrad": 0.509743884554918,
    "pmrarad": -6.92410898569444e-7,
    "pmdecrad": 0.000001914577705,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 74752,
    "lum": 0.0478630092322638,
    "var_min": 10.334,
    "var_max": 10.184,
    "ra": 15.322546,
    "dec": 29.206173,
    "pmra": -142.82,
    "pmdec": 394.91,
    "rv": 0,
    "Asset Name": "ASTRID 003323",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Braided Ponytail",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": 0.00000193,
    "y_1": 0.00003052,
    "z_1": 0.00004395,
    "Distance in Parsecs_1": 26.3019,
    "HYG Star ID_1": 74752,
    "Hipparcos Catalogue_1": 74981
  },
  {
    "Astrid #": "ASTRID 003324",
    "HYG Star ID": 65173,
    "Hipparcos Catalogue": 65378,
    "Henry Draper Catalogue": 116656,
    "Harvard Revised Catalogue": 5054,
    "Gliese Catalog": "NN 3783A",
    "Proper Name": "Mizar",
    "Distance in Parsecs": 26.3089,
    "mag": 2.23,
    "Absolute Visual Magnitude": 0.129,
    "Spectral Type": "A2V",
    "Color Index": 0.057,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -14.115808,
    "y": -5.413302,
    "z": 21.531272,
    "vx": 0.00000646,
    "vy": -0.00001408,
    "vz": -0.0000063,
    "rarad": 3.50778385472171,
    "decrad": 0.958628404309453,
    "pmrarad": 5.87739624937499e-7,
    "pmdecrad": -1.06707491e-7,
    "bayer": "Zet",
    "flam": 79,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 65173,
    "base": "NN 3783",
    "lum": 77.3392578048997,
    "var_min": 2.336,
    "var_max": 2.196,
    "bf": "79Zet UMa",
    "ra": 13.398747,
    "dec": 54.925362,
    "pmra": 121.23,
    "pmdec": -22.01,
    "rv": -5.6,
    "Asset Name": "ASTRID 003324",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Owie",
    "Hair": "Female Elf",
    "Outfit": "Elven",
    "Special": "Leaves of Flight",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Mizar",
    "Constellation": "Ursa Major",
    "x_1": 0.00000646,
    "y_1": -0.00001408,
    "z_1": -0.0000063,
    "Distance in Parsecs_1": 26.3089,
    "HYG Star ID_1": 65173,
    "Hipparcos Catalogue_1": 65378,
    "Henry Draper Catalogue_1": 116656,
    "Harvard Revised Catalogue_1": 5054,
    "Gliese Catalog_1": "NN 3783A"
  },
  {
    "Astrid #": "ASTRID 003325",
    "HYG Star ID": 118358,
    "Henry Draper Catalogue": 241814,
    "Gliese Catalog": "NN 3338",
    "Distance in Parsecs": 26.3158,
    "mag": 9.47,
    "Absolute Visual Magnitude": 7.369,
    "Spectral Type": "K4",
    "Color Index": 1.17,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.903736,
    "y": 24.256258,
    "z": 8.950343,
    "vx": -0.0000269,
    "vy": 0.00001586,
    "vz": -0.00002824,
    "rarad": 1.37131754870215,
    "decrad": 0.3470369622172,
    "pmrarad": 0.000001121374043125,
    "pmdecrad": -0.000001141115656,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 118358,
    "lum": 0.0982652582829531,
    "ra": 5.238047,
    "dec": 19.883753,
    "pmra": 231.3,
    "pmdec": -235.37,
    "rv": 0,
    "Asset Name": "ASTRID 003325",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Fire",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.0000269,
    "y_1": 0.00001586,
    "z_1": -0.00002824,
    "Distance in Parsecs_1": 26.3158,
    "HYG Star ID_1": 118358,
    "Henry Draper Catalogue_1": 241814,
    "Gliese Catalog_1": "NN 3338"
  },
  {
    "Astrid #": "ASTRID 003326",
    "HYG Star ID": 10974,
    "Hipparcos Catalogue": 11000,
    "Henry Draper Catalogue": 14635,
    "Gliese Catalog": "NN 3155",
    "Distance in Parsecs": 26.3227,
    "mag": 9.07,
    "Absolute Visual Magnitude": 6.968,
    "Spectral Type": "K0",
    "Color Index": 1.079,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 21.292612,
    "y": 15.151497,
    "z": -3.15299,
    "vx": -0.00002127,
    "vy": 0.00003125,
    "vz": 0.00000648,
    "rarad": 0.618458612310141,
    "decrad": -0.120070470579984,
    "pmrarad": 0.00000143592115906944,
    "pmdecrad": 2.47933716e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10974,
    "lum": 0.1421673931308,
    "ra": 2.362338,
    "dec": -6.879531,
    "pmra": 296.18,
    "pmdec": 51.14,
    "rv": 0,
    "Asset Name": "ASTRID 003326",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Mischievous",
    "Hair": "Ponytail",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002127,
    "y_1": 0.00003125,
    "z_1": 0.00000648,
    "Distance in Parsecs_1": 26.3227,
    "HYG Star ID_1": 10974,
    "Hipparcos Catalogue_1": 11000,
    "Henry Draper Catalogue_1": 14635,
    "Gliese Catalog_1": "NN 3155"
  },
  {
    "Astrid #": "ASTRID 003327",
    "HYG Star ID": 17304,
    "Hipparcos Catalogue": 17346,
    "Gliese Catalog": "Gl 149A",
    "Distance in Parsecs": 26.3366,
    "mag": 9.2,
    "Absolute Visual Magnitude": 7.097,
    "Spectral Type": "K5V",
    "Color Index": 1.128,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.513559,
    "y": 19.799855,
    "z": -10.9076,
    "vx": -0.00000284,
    "vy": 0.00000134,
    "vz": -0.0000522,
    "rarad": 0.971906573410876,
    "decrad": -0.427017924195031,
    "pmrarad": 1.17761243006944e-7,
    "pmdecrad": -0.000001812136575,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 17304,
    "base": "Gl 149",
    "lum": 0.126240876146704,
    "ra": 3.71241,
    "dec": -24.466325,
    "pmra": 24.29,
    "pmdec": -373.78,
    "rv": 20.7,
    "Asset Name": "ASTRID 003327",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Fire",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000284,
    "y_1": 0.00000134,
    "z_1": -0.0000522,
    "Distance in Parsecs_1": 26.3366,
    "HYG Star ID_1": 17304,
    "Hipparcos Catalogue_1": 17346,
    "Gliese Catalog_1": "Gl 149A"
  },
  {
    "Astrid #": "ASTRID 003328",
    "HYG Star ID": 50636,
    "Hipparcos Catalogue": 50782,
    "Henry Draper Catalogue": 89813,
    "Distance in Parsecs": 26.3366,
    "mag": 7.78,
    "Absolute Visual Magnitude": 5.677,
    "Spectral Type": "G5",
    "Color Index": 0.75,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -23.507125,
    "y": 10.693234,
    "z": 5.165143,
    "vx": 0.00000618,
    "vy": -0.0000058,
    "vz": -0.00004383,
    "rarad": 2.71467686341992,
    "decrad": 0.197400960654223,
    "pmrarad": 1.03362276694444e-7,
    "pmdecrad": -0.000001572153803,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 50636,
    "lum": 0.466874334392018,
    "ra": 10.369302,
    "dec": 11.310242,
    "pmra": 21.32,
    "pmdec": -324.28,
    "rv": -16.1,
    "Asset Name": "ASTRID 003328",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Einstein Tongue",
    "Hair": "Honest Lad",
    "Outfit": "Iron Star",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000618,
    "y_1": -0.0000058,
    "z_1": -0.00004383,
    "Distance in Parsecs_1": 26.3366,
    "HYG Star ID_1": 50636,
    "Hipparcos Catalogue_1": 50782,
    "Henry Draper Catalogue_1": 89813
  },
  {
    "Astrid #": "ASTRID 003329",
    "HYG Star ID": 67388,
    "Hipparcos Catalogue": 67601,
    "Henry Draper Catalogue": 120491,
    "Distance in Parsecs": 26.3366,
    "mag": 9.53,
    "Absolute Visual Magnitude": 7.427,
    "Spectral Type": "K3V",
    "Color Index": 1.112,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.783801,
    "y": -7.259539,
    "z": -21.234934,
    "vx": -0.00000662,
    "vy": 0.00002812,
    "vz": -0.00000532,
    "rarad": 3.62635573263678,
    "decrad": -0.937852830028053,
    "pmrarad": -0.00000106198436725694,
    "pmdecrad": -3.41260349e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 67388,
    "lum": 0.0931536765238574,
    "ra": 13.851659,
    "dec": -53.735009,
    "pmra": -219.05,
    "pmdec": -70.39,
    "rv": 0,
    "Asset Name": "ASTRID 003329",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Unkempt",
    "Outfit": "Tats",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000662,
    "y_1": 0.00002812,
    "z_1": -0.00000532,
    "Distance in Parsecs_1": 26.3366,
    "HYG Star ID_1": 67388,
    "Hipparcos Catalogue_1": 67601,
    "Henry Draper Catalogue_1": 120491
  },
  {
    "Astrid #": "ASTRID 003330",
    "HYG Star ID": 7733,
    "Hipparcos Catalogue": 7748,
    "Distance in Parsecs": 26.3435,
    "mag": 9.81,
    "Absolute Visual Magnitude": 7.707,
    "Color Index": 1.148,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 6.700648,
    "y": 3.116108,
    "z": 25.28579,
    "vx": 0.00000399,
    "vy": 0.00002375,
    "vz": -0.00000398,
    "rarad": 0.435278417071929,
    "decrad": 1.28646449676289,
    "pmrarad": 7.53836791895833e-7,
    "pmdecrad": -5.39112812e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 7733,
    "lum": 0.0719780372802699,
    "ra": 1.662641,
    "dec": 73.708986,
    "pmra": 155.49,
    "pmdec": -111.2,
    "rv": 0,
    "Asset Name": "ASTRID 003330",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Middle Part",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000399,
    "y_1": 0.00002375,
    "z_1": -0.00000398,
    "Distance in Parsecs_1": 26.3435,
    "HYG Star ID_1": 7733,
    "Hipparcos Catalogue_1": 7748
  },
  {
    "Astrid #": "ASTRID 003331",
    "HYG Star ID": 75457,
    "Hipparcos Catalogue": 75686,
    "Gliese Catalog": "Gl 585.1",
    "Distance in Parsecs": 26.3435,
    "mag": 10.2,
    "Absolute Visual Magnitude": 8.097,
    "Spectral Type": "M0",
    "Color Index": 1.334,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.228092,
    "y": -20.717225,
    "z": 1.193989,
    "vx": -0.00000539,
    "vy": 0.00000409,
    "vz": -0.00000224,
    "rarad": 4.04790558310076,
    "decrad": 0.0453394006595737,
    "pmrarad": -2.56902769326388e-7,
    "pmdecrad": -8.5181763e-8,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 75457,
    "lum": 0.0502573980109591,
    "var_min": 10.244,
    "var_max": 10.114,
    "ra": 15.46186,
    "dec": 2.597756,
    "pmra": -52.99,
    "pmdec": -17.57,
    "rv": 0,
    "Asset Name": "ASTRID 003331",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Audiohead",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000539,
    "y_1": 0.00000409,
    "z_1": -0.00000224,
    "Distance in Parsecs_1": 26.3435,
    "HYG Star ID_1": 75457,
    "Hipparcos Catalogue_1": 75686,
    "Gliese Catalog_1": "Gl 585.1"
  },
  {
    "Astrid #": "ASTRID 003332",
    "HYG Star ID": 86189,
    "Hipparcos Catalogue": 86456,
    "Henry Draper Catalogue": 161284,
    "Gliese Catalog": "Wo 9599",
    "Distance in Parsecs": 26.3435,
    "mag": 8.39,
    "Absolute Visual Magnitude": 6.287,
    "Spectral Type": "K0",
    "Color Index": 0.93,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.973737,
    "y": -11.089865,
    "z": 23.875572,
    "vx": -0.00000132,
    "vy": 0.00001599,
    "vz": -0.00000176,
    "rarad": 4.62481065112535,
    "decrad": 1.13449255346378,
    "pmrarad": -1.03071388486111e-7,
    "pmdecrad": 5.15938718e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 86189,
    "lum": 0.266195065115183,
    "var": "MS",
    "var_min": 8.429,
    "var_max": 8.359,
    "ra": 17.665475,
    "dec": 65.001635,
    "pmra": -21.26,
    "pmdec": 106.42,
    "rv": -8.1,
    "Asset Name": "ASTRID 003332",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Headband",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000132,
    "y_1": 0.00001599,
    "z_1": -0.00000176,
    "Distance in Parsecs_1": 26.3435,
    "HYG Star ID_1": 86189,
    "Hipparcos Catalogue_1": 86456,
    "Henry Draper Catalogue_1": 161284,
    "Gliese Catalog_1": "Wo 9599"
  },
  {
    "Astrid #": "ASTRID 003333",
    "HYG Star ID": 15368,
    "Hipparcos Catalogue": 15406,
    "Gliese Catalog": "NN 3216",
    "Distance in Parsecs": 26.3505,
    "mag": 11.33,
    "Absolute Visual Magnitude": 9.226,
    "Color Index": 1.474,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.359466,
    "y": 16.90795,
    "z": 14.22237,
    "vx": -0.00001534,
    "vy": 0.00002119,
    "vz": -0.0000097,
    "rarad": 0.866722892576092,
    "decrad": 0.570126020335398,
    "pmrarad": 9.64294410625e-7,
    "pmdecrad": -4.37447383e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 15368,
    "lum": 0.0177664230820361,
    "var_min": 11.39,
    "var_max": 11.25,
    "ra": 3.310638,
    "dec": 32.665815,
    "pmra": 198.9,
    "pmdec": -90.23,
    "rv": 0,
    "Asset Name": "ASTRID 003333",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "High Fade",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.00001534,
    "y_1": 0.00002119,
    "z_1": -0.0000097,
    "Distance in Parsecs_1": 26.3505,
    "HYG Star ID_1": 15368,
    "Hipparcos Catalogue_1": 15406,
    "Gliese Catalog_1": "NN 3216"
  },
  {
    "Astrid #": "ASTRID 003334",
    "HYG Star ID": 41684,
    "Hipparcos Catalogue": 41802,
    "Gliese Catalog": "NN 3501A",
    "Distance in Parsecs": 26.3505,
    "mag": 11.19,
    "Absolute Visual Magnitude": 9.086,
    "Color Index": 1.387,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -16.075372,
    "y": 20.694425,
    "z": -2.769843,
    "vx": 0.00004789,
    "vy": 0.00003638,
    "vz": -0.00000612,
    "rarad": 2.23122834452464,
    "decrad": -0.105309907125906,
    "pmrarad": -0.0000022824543266875,
    "pmdecrad": -2.33728675e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 41684,
    "base": "NN 3501",
    "lum": 0.0202115676696075,
    "var_min": 11.255,
    "var_max": 11.125,
    "ra": 8.522664,
    "dec": -6.033813,
    "pmra": -470.79,
    "pmdec": -48.21,
    "rv": 0,
    "Asset Name": "ASTRID 003334",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Happy Vamp",
    "Hair": "Fire",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00004789,
    "y_1": 0.00003638,
    "z_1": -0.00000612,
    "Distance in Parsecs_1": 26.3505,
    "HYG Star ID_1": 41684,
    "Hipparcos Catalogue_1": 41802,
    "Gliese Catalog_1": "NN 3501A"
  },
  {
    "Astrid #": "ASTRID 003335",
    "HYG Star ID": 57693,
    "Hipparcos Catalogue": 57866,
    "Henry Draper Catalogue": 103072,
    "Distance in Parsecs": 26.3505,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.296,
    "Spectral Type": "K2",
    "Color Index": 0.86,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.93661,
    "y": 0.855667,
    "z": 8.47234,
    "vx": -0.00000918,
    "vy": -0.00000369,
    "vz": -0.00003747,
    "rarad": 3.10729237475295,
    "decrad": 0.327339771733443,
    "pmrarad": 1.52086051590277e-7,
    "pmdecrad": -0.000001457059035,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 57693,
    "lum": 0.26399761293548,
    "ra": 11.868983,
    "dec": 18.755187,
    "pmra": 31.37,
    "pmdec": -300.54,
    "rv": -3.4,
    "Asset Name": "ASTRID 003335",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oh Snap",
    "Hair": "Fire",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000918,
    "y_1": -0.00000369,
    "z_1": -0.00003747,
    "Distance in Parsecs_1": 26.3505,
    "HYG Star ID_1": 57693,
    "Hipparcos Catalogue_1": 57866,
    "Henry Draper Catalogue_1": 103072
  },
  {
    "Astrid #": "ASTRID 003336",
    "HYG Star ID": 60781,
    "Hipparcos Catalogue": 60970,
    "Distance in Parsecs": 26.3505,
    "mag": 11.91,
    "Absolute Visual Magnitude": 9.806,
    "Color Index": 0.8,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -21.016709,
    "y": -2.759192,
    "z": 15.65355,
    "vx": -0.00000806,
    "vy": -0.00001935,
    "vz": -0.00001424,
    "rarad": 3.27213029280998,
    "decrad": 0.636085885967361,
    "pmrarad": 6.88435426388888e-7,
    "pmdecrad": -6.71660873e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 60781,
    "lum": 0.0104135786152843,
    "var_min": 12.011,
    "var_max": 11.771,
    "ra": 12.498617,
    "dec": 36.445037,
    "pmra": 142,
    "pmdec": -138.54,
    "rv": 0,
    "Asset Name": "ASTRID 003336",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Braid",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00000806,
    "y_1": -0.00001935,
    "z_1": -0.00001424,
    "Distance in Parsecs_1": 26.3505,
    "HYG Star ID_1": 60781,
    "Hipparcos Catalogue_1": 60970
  },
  {
    "Astrid #": "ASTRID 003337",
    "HYG Star ID": 91206,
    "Hipparcos Catalogue": 91489,
    "Distance in Parsecs": 26.3505,
    "mag": 10.85,
    "Absolute Visual Magnitude": 8.746,
    "Color Index": 0.678,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.910556,
    "y": -22.443983,
    "z": 13.240997,
    "vx": -0.00000978,
    "vy": -0.00001284,
    "vz": -0.00001887,
    "rarad": 4.8848941721003,
    "decrad": 0.526482276065851,
    "pmrarad": -4.49131393666666e-7,
    "pmdecrad": -8.28449617e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 91206,
    "lum": 0.0276439437122033,
    "var_min": 10.919,
    "var_max": 10.769,
    "ra": 18.658921,
    "dec": 30.165212,
    "pmra": -92.64,
    "pmdec": -170.88,
    "rv": 0,
    "Asset Name": "ASTRID 003337",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Gimme Blood",
    "Hair": "Unkempt",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000978,
    "y_1": -0.00001284,
    "z_1": -0.00001887,
    "Distance in Parsecs_1": 26.3505,
    "HYG Star ID_1": 91206,
    "Hipparcos Catalogue_1": 91489
  },
  {
    "Astrid #": "ASTRID 003338",
    "HYG Star ID": 972,
    "Hipparcos Catalogue": 974,
    "Distance in Parsecs": 26.3644,
    "mag": 8.73,
    "Absolute Visual Magnitude": 6.625,
    "Spectral Type": "K0",
    "Color Index": 1.045,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 23.43762,
    "y": 1.235191,
    "z": 12.00974,
    "vx": 0.00000289,
    "vy": 0.00003643,
    "vz": -0.00000938,
    "rarad": 0.0526510175609393,
    "decrad": 0.472965963372308,
    "pmrarad": 0.00000137434982163888,
    "pmdecrad": -3.99728879e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 972,
    "lum": 0.194984459975804,
    "ra": 0.201112,
    "dec": 27.098954,
    "pmra": 283.48,
    "pmdec": -82.45,
    "rv": 0,
    "Asset Name": "ASTRID 003338",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Pompadour",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000289,
    "y_1": 0.00003643,
    "z_1": -0.00000938,
    "Distance in Parsecs_1": 26.3644,
    "HYG Star ID_1": 972,
    "Hipparcos Catalogue_1": 974
  },
  {
    "Astrid #": "ASTRID 003339",
    "HYG Star ID": 61598,
    "Hipparcos Catalogue": 61792,
    "Henry Draper Catalogue": 109952,
    "Gliese Catalog": "GJ 2096",
    "Distance in Parsecs": 26.3644,
    "mag": 9.05,
    "Absolute Visual Magnitude": 6.945,
    "Spectral Type": "K5V",
    "Color Index": 1.154,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.468312,
    "y": -0.960571,
    "z": -25.773396,
    "vx": -0.00006787,
    "vy": 0.00009887,
    "vz": -0.00001471,
    "rarad": 3.3156156726609,
    "decrad": -1.35862123950858,
    "pmrarad": -0.00000413958161142361,
    "pmdecrad": 0.00000172622759,
    "con": "Cha",
    "Full Constellation Name Formula 1": "Chamaeleon",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Chamaeleon",
    "comp": 1,
    "comp_primary": 61598,
    "lum": 0.145211161758774,
    "ra": 12.664719,
    "dec": -77.843263,
    "pmra": -853.85,
    "pmdec": 356.06,
    "rv": 24.3,
    "Asset Name": "ASTRID 003339",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Smoulder",
    "Hair": "Little Crown",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Chamaeleon",
    "x_1": -0.00006787,
    "y_1": 0.00009887,
    "z_1": -0.00001471,
    "Distance in Parsecs_1": 26.3644,
    "HYG Star ID_1": 61598,
    "Hipparcos Catalogue_1": 61792,
    "Henry Draper Catalogue_1": 109952,
    "Gliese Catalog_1": "GJ 2096"
  },
  {
    "Astrid #": "ASTRID 003340",
    "HYG Star ID": 114848,
    "Hipparcos Catalogue": 115211,
    "Gliese Catalog": "NN 4332",
    "Distance in Parsecs": 26.3713,
    "mag": 11.03,
    "Absolute Visual Magnitude": 8.924,
    "Spectral Type": "M2",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.960983,
    "y": -2.278056,
    "z": -22.853202,
    "vx": -0.00002109,
    "vy": -0.00003749,
    "vz": -0.00000823,
    "rarad": 6.10921328866363,
    "decrad": -1.04833520578914,
    "pmrarad": -0.00000153889558481944,
    "pmdecrad": -6.25118759e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 114848,
    "lum": 0.0234638892446413,
    "var_min": 11.117,
    "var_max": 10.947,
    "ra": 23.335476,
    "dec": -60.065183,
    "pmra": -317.42,
    "pmdec": -128.94,
    "rv": 0,
    "Asset Name": "ASTRID 003340",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Oh Snap",
    "Hair": "Prince",
    "Outfit": "Unit 01",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00002109,
    "y_1": -0.00003749,
    "z_1": -0.00000823,
    "Distance in Parsecs_1": 26.3713,
    "HYG Star ID_1": 114848,
    "Hipparcos Catalogue_1": 115211,
    "Gliese Catalog_1": "NN 4332"
  },
  {
    "Astrid #": "ASTRID 003341",
    "HYG Star ID": 50993,
    "Hipparcos Catalogue": 51138,
    "Henry Draper Catalogue": 307727,
    "Distance in Parsecs": 26.3783,
    "mag": 9.5,
    "Absolute Visual Magnitude": 7.394,
    "Spectral Type": "K5",
    "Color Index": 1.23,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -11.035391,
    "y": 4.753013,
    "z": -23.482838,
    "vx": 0.00005104,
    "vy": 0.00001782,
    "vz": -0.00002038,
    "rarad": 2.73491314420397,
    "decrad": -1.09785689563157,
    "pmrarad": -0.00000138603383134027,
    "pmdecrad": -0.00000169607218,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 50993,
    "lum": 0.096028467920938,
    "ra": 10.446599,
    "dec": -62.902567,
    "pmra": -285.89,
    "pmdec": -349.84,
    "rv": 0,
    "Asset Name": "ASTRID 003341",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Halo Boy",
    "Outfit": "Painter",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": 0.00005104,
    "y_1": 0.00001782,
    "z_1": -0.00002038,
    "Distance in Parsecs_1": 26.3783,
    "HYG Star ID_1": 50993,
    "Hipparcos Catalogue_1": 51138,
    "Henry Draper Catalogue_1": 307727
  },
  {
    "Astrid #": "ASTRID 003342",
    "HYG Star ID": 79766,
    "Hipparcos Catalogue": 80008,
    "Henry Draper Catalogue": 147365,
    "Harvard Revised Catalogue": 6091,
    "Gliese Catalog": "Wo 9557A",
    "Distance in Parsecs": 26.3783,
    "mag": 5.48,
    "Absolute Visual Magnitude": 3.374,
    "Spectral Type": "F3IV-V",
    "Color Index": 0.41,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.582573,
    "y": -18.388426,
    "z": 16.852484,
    "vx": -0.00000542,
    "vy": 0.00002749,
    "vz": -0.00001919,
    "rarad": 4.27570507781816,
    "decrad": 0.693045537371225,
    "pmrarad": -6.27106495798611e-7,
    "pmdecrad": -1.207186e-8,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 79766,
    "base": "Wo 9557",
    "lum": 3.89403633879717,
    "ra": 16.33199,
    "dec": 39.708584,
    "pmra": -129.35,
    "pmdec": -2.49,
    "rv": -29,
    "Asset Name": "ASTRID 003342",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Medium Bob",
    "Outfit": "School Uniform",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000542,
    "y_1": 0.00002749,
    "z_1": -0.00001919,
    "Distance in Parsecs_1": 26.3783,
    "HYG Star ID_1": 79766,
    "Hipparcos Catalogue_1": 80008,
    "Henry Draper Catalogue_1": 147365,
    "Harvard Revised Catalogue_1": 6091,
    "Gliese Catalog_1": "Wo 9557A"
  },
  {
    "Astrid #": "ASTRID 003343",
    "HYG Star ID": 400,
    "Hipparcos Catalogue": 400,
    "Henry Draper Catalogue": 225261,
    "Gliese Catalog": "NN 3003",
    "Distance in Parsecs": 26.3922,
    "mag": 7.82,
    "Absolute Visual Magnitude": 5.713,
    "Spectral Type": "G9V",
    "Color Index": 0.755,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 24.239722,
    "y": 0.522425,
    "z": 10.426465,
    "vx": -6.8e-7,
    "vy": 0.00004889,
    "vz": -8.8e-7,
    "rarad": 0.0215476392604521,
    "decrad": 0.406131645962083,
    "pmrarad": 0.00000185271548024305,
    "pmdecrad": -3.6118619e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 400,
    "lum": 0.451647904929111,
    "ra": 0.082306,
    "dec": 23.269629,
    "pmra": 382.15,
    "pmdec": -7.45,
    "rv": 0,
    "Asset Name": "ASTRID 003343",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Mortified",
    "Hair": "Pompadour",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -6.8e-7,
    "y_1": 0.00004889,
    "z_1": -8.8e-7,
    "Distance in Parsecs_1": 26.3922,
    "HYG Star ID_1": 400,
    "Hipparcos Catalogue_1": 400,
    "Henry Draper Catalogue_1": 225261,
    "Gliese Catalog_1": "NN 3003"
  },
  {
    "Astrid #": "ASTRID 003344",
    "HYG Star ID": 63264,
    "Hipparcos Catalogue": 63467,
    "Henry Draper Catalogue": 112943,
    "Gliese Catalog": "Gl 493",
    "Distance in Parsecs": 26.3922,
    "mag": 9.78,
    "Absolute Visual Magnitude": 7.673,
    "Spectral Type": "K7V",
    "Color Index": 1.185,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.45593,
    "y": -6.854628,
    "z": -1.245437,
    "vx": -0.00001253,
    "vy": 0.00010329,
    "vz": 0.00000188,
    "rarad": 3.40462802936058,
    "decrad": -0.04720733302814,
    "pmrarad": -0.00000390270164710416,
    "pmdecrad": 4.4796784e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 63264,
    "lum": 0.0742677043714697,
    "ra": 13.004721,
    "dec": -2.704781,
    "pmra": -804.99,
    "pmdec": 9.24,
    "rv": -14.5,
    "Asset Name": "ASTRID 003344",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Elegant",
    "Outfit": "Mecha",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001253,
    "y_1": 0.00010329,
    "z_1": 0.00000188,
    "Distance in Parsecs_1": 26.3922,
    "HYG Star ID_1": 63264,
    "Hipparcos Catalogue_1": 63467,
    "Henry Draper Catalogue_1": 112943,
    "Gliese Catalog_1": "Gl 493"
  },
  {
    "Astrid #": "ASTRID 003345",
    "HYG Star ID": 105425,
    "Hipparcos Catalogue": 105766,
    "Henry Draper Catalogue": 204521,
    "Gliese Catalog": "NN 4194",
    "Distance in Parsecs": 26.4061,
    "mag": 7.26,
    "Absolute Visual Magnitude": 5.151,
    "Spectral Type": "G5",
    "Color Index": 0.619,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.888672,
    "y": -5.514922,
    "z": 24.888028,
    "vx": -4.3e-7,
    "vy": 0.0000071,
    "vz": 0.00000169,
    "rarad": 5.60808832860174,
    "decrad": 1.23006512345519,
    "pmrarad": 1.99840199125e-7,
    "pmdecrad": 1.91937736e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 105425,
    "lum": 0.757879220916049,
    "ra": 21.421319,
    "dec": 70.47754,
    "pmra": 41.22,
    "pmdec": 39.59,
    "rv": 0,
    "Asset Name": "ASTRID 003345",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Oh Snap",
    "Hair": "Hachimaki",
    "Outfit": "Star Knight",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -4.3e-7,
    "y_1": 0.0000071,
    "z_1": 0.00000169,
    "Distance in Parsecs_1": 26.4061,
    "HYG Star ID_1": 105425,
    "Hipparcos Catalogue_1": 105766,
    "Henry Draper Catalogue_1": 204521,
    "Gliese Catalog_1": "NN 4194"
  },
  {
    "Astrid #": "ASTRID 003346",
    "HYG Star ID": 116218,
    "Hipparcos Catalogue": 116584,
    "Henry Draper Catalogue": 222107,
    "Harvard Revised Catalogue": 8961,
    "Gliese Catalog": "Wo 9832",
    "Distance in Parsecs": 26.4061,
    "mag": 3.81,
    "Absolute Visual Magnitude": 1.701,
    "Spectral Type": "G8III-IV",
    "Color Index": 0.984,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.103691,
    "y": -1.77795,
    "z": 19.141071,
    "vx": 0.00004568,
    "vy": 0.00001599,
    "vz": -0.00003212,
    "rarad": 6.18528691330299,
    "decrad": 0.810847712162292,
    "pmrarad": 7.71920342180555e-7,
    "pmdecrad": -0.000002043295738,
    "bayer": "Lam",
    "flam": 16,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 116218,
    "lum": 18.1802562377426,
    "var": "Lam",
    "var_min": 3.951,
    "var_max": 3.741,
    "bf": "16Lam And",
    "ra": 23.626056,
    "dec": 46.458152,
    "pmra": 159.22,
    "pmdec": -421.46,
    "rv": 6.8,
    "Asset Name": "ASTRID 003346",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Big Laugh",
    "Hair": "Greasy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00004568,
    "y_1": 0.00001599,
    "z_1": -0.00003212,
    "Distance in Parsecs_1": 26.4061,
    "HYG Star ID_1": 116218,
    "Hipparcos Catalogue_1": 116584,
    "Henry Draper Catalogue_1": 222107,
    "Harvard Revised Catalogue_1": 8961,
    "Gliese Catalog_1": "Wo 9832"
  },
  {
    "Astrid #": "ASTRID 003347",
    "HYG Star ID": 93246,
    "Hipparcos Catalogue": 93540,
    "Henry Draper Catalogue": 176986,
    "Distance in Parsecs": 26.4131,
    "mag": 8.42,
    "Absolute Visual Magnitude": 6.311,
    "Spectral Type": "K0",
    "Color Index": 0.939,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.047463,
    "y": -24.94763,
    "z": -5.059733,
    "vx": -0.00001721,
    "vy": 0.00000116,
    "vz": -0.00002969,
    "rarad": 4.98770553144694,
    "decrad": -0.192752870432008,
    "pmrarad": -6.15034635152777e-7,
    "pmdecrad": -0.000001145420801,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 93246,
    "lum": 0.260375429893873,
    "ra": 19.051632,
    "dec": -11.043926,
    "pmra": -126.86,
    "pmdec": -236.26,
    "rv": 0,
    "Asset Name": "ASTRID 003347",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Star of Culture",
    "Hair": "Side Part",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001721,
    "y_1": 0.00000116,
    "z_1": -0.00002969,
    "Distance in Parsecs_1": 26.4131,
    "HYG Star ID_1": 93246,
    "Hipparcos Catalogue_1": 93540,
    "Henry Draper Catalogue_1": 176986
  },
  {
    "Astrid #": "ASTRID 003348",
    "HYG Star ID": 22283,
    "Hipparcos Catalogue": 22336,
    "Henry Draper Catalogue": 30562,
    "Harvard Revised Catalogue": 1536,
    "Distance in Parsecs": 26.4201,
    "mag": 5.77,
    "Absolute Visual Magnitude": 3.66,
    "Spectral Type": "F8V",
    "Color Index": 0.631,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.058285,
    "y": 25.025977,
    "z": -2.612198,
    "vx": -0.00001444,
    "vy": 0.0000858,
    "vz": -0.00003976,
    "rarad": 1.25928303325989,
    "decrad": -0.0990307607559925,
    "pmrarad": 0.00000151591541636111,
    "pmdecrad": -0.000001208543542,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 22283,
    "lum": 2.99226463660818,
    "ra": 4.810107,
    "dec": -5.674045,
    "pmra": 312.68,
    "pmdec": -249.28,
    "rv": 79,
    "Asset Name": "ASTRID 003348",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Braid",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001444,
    "y_1": 0.0000858,
    "z_1": -0.00003976,
    "Distance in Parsecs_1": 26.4201,
    "HYG Star ID_1": 22283,
    "Hipparcos Catalogue_1": 22336,
    "Henry Draper Catalogue_1": 30562,
    "Harvard Revised Catalogue_1": 1536
  },
  {
    "Astrid #": "ASTRID 003349",
    "HYG Star ID": 44016,
    "Hipparcos Catalogue": 44143,
    "Henry Draper Catalogue": 77370,
    "Harvard Revised Catalogue": 3598,
    "Gliese Catalog": "Gl 333.1",
    "Distance in Parsecs": 26.4201,
    "mag": 5.17,
    "Absolute Visual Magnitude": 3.06,
    "Spectral Type": "F3V",
    "Color Index": 0.417,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.573451,
    "y": 9.623453,
    "z": -22.66639,
    "vx": -0.00001021,
    "vy": 0.00004197,
    "vz": 0.00000854,
    "rarad": 2.35359679025899,
    "decrad": -1.03120530855509,
    "pmrarad": -8.4648468625e-7,
    "pmdecrad": 0.000001366107989,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 44016,
    "lum": 5.19995996533515,
    "ra": 8.990078,
    "dec": -59.083712,
    "pmra": -174.6,
    "pmdec": 281.78,
    "rv": 11.4,
    "Asset Name": "ASTRID 003349",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Skater Helmet",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00001021,
    "y_1": 0.00004197,
    "z_1": 0.00000854,
    "Distance in Parsecs_1": 26.4201,
    "HYG Star ID_1": 44016,
    "Hipparcos Catalogue_1": 44143,
    "Henry Draper Catalogue_1": 77370,
    "Harvard Revised Catalogue_1": 3598,
    "Gliese Catalog_1": "Gl 333.1"
  },
  {
    "Astrid #": "ASTRID 003350",
    "HYG Star ID": 5100,
    "Hipparcos Catalogue": 5110,
    "Henry Draper Catalogue": 6440,
    "Distance in Parsecs": 26.434,
    "mag": 8.66,
    "Absolute Visual Magnitude": 6.549,
    "Spectral Type": "K2V",
    "Color Index": 1.197,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.452324,
    "y": 7.184951,
    "z": 7.015276,
    "vx": 0.00000108,
    "vy": 0.00000117,
    "vz": -0.00002578,
    "rarad": 0.285791360919873,
    "decrad": 0.268607244235788,
    "pmrarad": 3.10765569236111e-8,
    "pmdecrad": -9.54258767e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 5100,
    "lum": 0.209122133015528,
    "ra": 1.091643,
    "dec": 15.390061,
    "pmra": 6.41,
    "pmdec": -196.83,
    "rv": -5.4,
    "Asset Name": "ASTRID 003350",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Headband",
    "Outfit": "Astro",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00000108,
    "y_1": 0.00000117,
    "z_1": -0.00002578,
    "Distance in Parsecs_1": 26.434,
    "HYG Star ID_1": 5100,
    "Hipparcos Catalogue_1": 5110,
    "Henry Draper Catalogue_1": 6440
  },
  {
    "Astrid #": "ASTRID 003351",
    "HYG Star ID": 107033,
    "Hipparcos Catalogue": 107376,
    "Distance in Parsecs": 26.462,
    "mag": 11.43,
    "Absolute Visual Magnitude": 9.317,
    "Spectral Type": "K:",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.746125,
    "y": -10.531179,
    "z": 18.476776,
    "vx": 0.00003853,
    "vy": -0.00004747,
    "vz": -0.00005989,
    "rarad": 5.69370210473127,
    "decrad": 0.772933280198437,
    "pmrarad": -6.81744997597222e-7,
    "pmdecrad": -0.00000316214875,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 107033,
    "lum": 0.0163380416930068,
    "var_min": 11.555,
    "var_max": 11.265,
    "ra": 21.74834,
    "dec": 44.285815,
    "pmra": -140.62,
    "pmdec": -652.24,
    "rv": 0,
    "Asset Name": "ASTRID 003351",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Manbun",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00003853,
    "y_1": -0.00004747,
    "z_1": -0.00005989,
    "Distance in Parsecs_1": 26.462,
    "HYG Star ID_1": 107033,
    "Hipparcos Catalogue_1": 107376
  },
  {
    "Astrid #": "ASTRID 003352",
    "HYG Star ID": 6117,
    "Hipparcos Catalogue": 6130,
    "Henry Draper Catalogue": 7895,
    "Gliese Catalog": "Gl 56.3A",
    "Distance in Parsecs": 26.4901,
    "mag": 8,
    "Absolute Visual Magnitude": 5.885,
    "Spectral Type": "K1V",
    "Color Index": 0.78,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.94142,
    "y": 8.916204,
    "z": -0.401098,
    "vx": -0.00000157,
    "vy": 0.00005824,
    "vz": -0.0000324,
    "rarad": 0.343328044444379,
    "decrad": -0.01514189251086,
    "pmrarad": 0.00000209056507192361,
    "pmdecrad": -0.000001212858384,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 6117,
    "base": "Gl  56.3",
    "lum": 0.385478357665771,
    "ra": 1.311417,
    "dec": -0.867567,
    "pmra": 431.21,
    "pmdec": -250.17,
    "rv": 18.2,
    "Asset Name": "ASTRID 003352",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Serious Shinobi",
    "Hair": "Braid",
    "Outfit": "Tarnished",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000157,
    "y_1": 0.00005824,
    "z_1": -0.0000324,
    "Distance in Parsecs_1": 26.4901,
    "HYG Star ID_1": 6117,
    "Hipparcos Catalogue_1": 6130,
    "Henry Draper Catalogue_1": 7895,
    "Gliese Catalog_1": "Gl 56.3A"
  },
  {
    "Astrid #": "ASTRID 003353",
    "HYG Star ID": 74425,
    "Hipparcos Catalogue": 74653,
    "Henry Draper Catalogue": 134606,
    "Distance in Parsecs": 26.4971,
    "mag": 6.86,
    "Absolute Visual Magnitude": 4.744,
    "Spectral Type": "G5IV",
    "Color Index": 0.74,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.818943,
    "y": -6.649896,
    "z": -24.980294,
    "vx": -0.00000404,
    "vy": 0.00003008,
    "vz": -0.00000707,
    "rarad": 3.99354982111746,
    "decrad": -1.23079963792172,
    "pmrarad": -8.62386574972222e-7,
    "pmdecrad": -7.99991054e-7,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 74425,
    "lum": 1.10255433210222,
    "ra": 15.254237,
    "dec": -70.519625,
    "pmra": -177.88,
    "pmdec": -165.01,
    "rv": 0,
    "Asset Name": "ASTRID 003353",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Fire",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": -0.00000404,
    "y_1": 0.00003008,
    "z_1": -0.00000707,
    "Distance in Parsecs_1": 26.4971,
    "HYG Star ID_1": 74425,
    "Hipparcos Catalogue_1": 74653,
    "Henry Draper Catalogue_1": 134606
  },
  {
    "Astrid #": "ASTRID 003354",
    "HYG Star ID": 3196,
    "Hipparcos Catalogue": 3203,
    "Henry Draper Catalogue": 3821,
    "Distance in Parsecs": 26.5041,
    "mag": 7.02,
    "Absolute Visual Magnitude": 4.903,
    "Spectral Type": "G0...",
    "Color Index": 0.62,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.877906,
    "y": 4.655165,
    "z": -3.336817,
    "vx": 0.00000612,
    "vy": 0.00000118,
    "vz": -0.00001265,
    "rarad": 0.177985955149566,
    "decrad": -0.126232794357437,
    "pmrarad": 3.10280755555555e-9,
    "pmdecrad": -4.4404085e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3196,
    "lum": 0.952357486191919,
    "ra": 0.679856,
    "dec": -7.232606,
    "pmra": 0.64,
    "pmdec": -91.59,
    "rv": 7.6,
    "Asset Name": "ASTRID 003354",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Middle Part",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000612,
    "y_1": 0.00000118,
    "z_1": -0.00001265,
    "Distance in Parsecs_1": 26.5041,
    "HYG Star ID_1": 3196,
    "Hipparcos Catalogue_1": 3203,
    "Henry Draper Catalogue_1": 3821
  },
  {
    "Astrid #": "ASTRID 003355",
    "HYG Star ID": 92692,
    "Hipparcos Catalogue": 92984,
    "Henry Draper Catalogue": 175726,
    "Distance in Parsecs": 26.5041,
    "mag": 6.71,
    "Absolute Visual Magnitude": 4.593,
    "Spectral Type": "G5",
    "Color Index": 0.583,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.463469,
    "y": -25.628215,
    "z": 1.971158,
    "vx": -2e-8,
    "vy": -8.4e-7,
    "vz": -0.0000109,
    "rarad": 4.9594383507629,
    "decrad": 0.0744405210771937,
    "pmrarad": -8.48423940972222e-9,
    "pmdecrad": -4.12334035e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 92692,
    "lum": 1.26706822499854,
    "ra": 18.943659,
    "dec": 4.265128,
    "pmra": -1.75,
    "pmdec": -85.05,
    "rv": 0,
    "Asset Name": "ASTRID 003355",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Fired Up",
    "Hair": "Curly",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -2e-8,
    "y_1": -8.4e-7,
    "z_1": -0.0000109,
    "Distance in Parsecs_1": 26.5041,
    "HYG Star ID_1": 92692,
    "Hipparcos Catalogue_1": 92984,
    "Henry Draper Catalogue_1": 175726
  },
  {
    "Astrid #": "ASTRID 003356",
    "HYG Star ID": 71509,
    "Hipparcos Catalogue": 71735,
    "Henry Draper Catalogue": 128674,
    "Distance in Parsecs": 26.5182,
    "mag": 7.39,
    "Absolute Visual Magnitude": 5.272,
    "Spectral Type": "G5V",
    "Color Index": 0.672,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.036101,
    "y": -9.299109,
    "z": -22.24772,
    "vx": 0.00004534,
    "vy": -0.00002823,
    "vz": -0.00004933,
    "rarad": 3.84176563885707,
    "decrad": -0.995353535218317,
    "pmrarad": 0.00000191598366555555,
    "pmdecrad": -0.000001533853522,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 71509,
    "lum": 0.677953644924194,
    "ra": 14.674464,
    "dec": -57.029557,
    "pmra": 395.2,
    "pmdec": -316.38,
    "rv": 31.7,
    "Asset Name": "ASTRID 003356",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Side Part",
    "Outfit": "Football",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00004534,
    "y_1": -0.00002823,
    "z_1": -0.00004933,
    "Distance in Parsecs_1": 26.5182,
    "HYG Star ID_1": 71509,
    "Hipparcos Catalogue_1": 71735,
    "Henry Draper Catalogue_1": 128674
  },
  {
    "Astrid #": "ASTRID 003357",
    "HYG Star ID": 51665,
    "Hipparcos Catalogue": 51814,
    "Henry Draper Catalogue": 91480,
    "Harvard Revised Catalogue": 4141,
    "Distance in Parsecs": 26.5252,
    "mag": 5.16,
    "Absolute Visual Magnitude": 3.042,
    "Spectral Type": "F1V",
    "Color Index": 0.349,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.438117,
    "y": 5.214896,
    "z": 22.266643,
    "vx": 0.00000586,
    "vy": -0.00001133,
    "vz": -0.00000599,
    "rarad": 2.77141272595158,
    "decrad": 0.996279954250615,
    "pmrarad": 3.18280181284722e-7,
    "pmdecrad": 1.79914356e-7,
    "flam": 37,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 51665,
    "lum": 5.2868866585088,
    "bf": "37    UMa",
    "ra": 10.586017,
    "dec": 57.082637,
    "pmra": 65.65,
    "pmdec": 37.11,
    "rv": -10,
    "Asset Name": "ASTRID 003357",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Uwu",
    "Hair": "Headband",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000586,
    "y_1": -0.00001133,
    "z_1": -0.00000599,
    "Distance in Parsecs_1": 26.5252,
    "HYG Star ID_1": 51665,
    "Hipparcos Catalogue_1": 51814,
    "Henry Draper Catalogue_1": 91480,
    "Harvard Revised Catalogue_1": 4141
  },
  {
    "Astrid #": "ASTRID 003358",
    "HYG Star ID": 22662,
    "Hipparcos Catalogue": 22715,
    "Henry Draper Catalogue": 30973,
    "Gliese Catalog": "GJ 2035",
    "Distance in Parsecs": 26.5393,
    "mag": 8.78,
    "Absolute Visual Magnitude": 6.661,
    "Spectral Type": "K5V",
    "Color Index": 1.019,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.071678,
    "y": 23.525938,
    "z": 10.042711,
    "vx": -0.00001657,
    "vy": 0.00001156,
    "vz": -0.0000154,
    "rarad": 1.27879721209102,
    "decrad": 0.388076933515012,
    "pmrarad": 7.23438974125e-7,
    "pmdecrad": -6.2691257e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 22662,
    "lum": 0.188625324535106,
    "var_min": 8.808,
    "var_max": 8.698,
    "ra": 4.884646,
    "dec": 22.23517,
    "pmra": 149.22,
    "pmdec": -129.31,
    "rv": 0,
    "Asset Name": "ASTRID 003358",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Sweetheart",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00001657,
    "y_1": 0.00001156,
    "z_1": -0.0000154,
    "Distance in Parsecs_1": 26.5393,
    "HYG Star ID_1": 22662,
    "Hipparcos Catalogue_1": 22715,
    "Henry Draper Catalogue_1": 30973,
    "Gliese Catalog_1": "GJ 2035"
  },
  {
    "Astrid #": "ASTRID 003359",
    "HYG Star ID": 109410,
    "Hipparcos Catalogue": 109756,
    "Gliese Catalog": "NN 4265",
    "Distance in Parsecs": 26.5463,
    "mag": 11.82,
    "Absolute Visual Magnitude": 9.7,
    "Spectral Type": "M2V:",
    "Color Index": 1.456,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.650799,
    "y": -11.806182,
    "z": 2.441269,
    "vx": 0.00001074,
    "vy": 0.00002042,
    "vz": -0.00000531,
    "rarad": 5.82018792069641,
    "decrad": 0.0920927774898137,
    "pmrarad": 8.69222447868055e-7,
    "pmdecrad": -2.00858307e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 109410,
    "lum": 0.0114815362149688,
    "var_min": 11.92,
    "var_max": 11.73,
    "ra": 22.23148,
    "dec": 5.276527,
    "pmra": 179.29,
    "pmdec": -41.43,
    "rv": 0,
    "Asset Name": "ASTRID 003359",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Braided Bun",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001074,
    "y_1": 0.00002042,
    "z_1": -0.00000531,
    "Distance in Parsecs_1": 26.5463,
    "HYG Star ID_1": 109410,
    "Hipparcos Catalogue_1": 109756,
    "Gliese Catalog_1": "NN 4265"
  },
  {
    "Astrid #": "ASTRID 003360",
    "HYG Star ID": 33235,
    "Hipparcos Catalogue": 33322,
    "Distance in Parsecs": 26.5604,
    "mag": 10.23,
    "Absolute Visual Magnitude": 8.109,
    "Spectral Type": "K4",
    "Color Index": 1.352,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.618303,
    "y": 14.547715,
    "z": 21.925482,
    "vx": -0.000009820000000000001,
    "vy": -0.00000721,
    "vz": 0.00000316,
    "rarad": 1.81456676127895,
    "decrad": 0.971078921897853,
    "pmrarad": 4.24260451854166e-7,
    "pmdecrad": 2.10845469e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 33235,
    "lum": 0.0497049910576234,
    "ra": 6.931134,
    "dec": 55.638724,
    "pmra": 87.51,
    "pmdec": 43.49,
    "rv": 0,
    "Asset Name": "ASTRID 003360",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Sweetheart",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.000009820000000000001,
    "y_1": -0.00000721,
    "z_1": 0.00000316,
    "Distance in Parsecs_1": 26.5604,
    "HYG Star ID_1": 33235,
    "Hipparcos Catalogue_1": 33322
  },
  {
    "Astrid #": "ASTRID 003361",
    "HYG Star ID": 66003,
    "Hipparcos Catalogue": 66212,
    "Henry Draper Catalogue": 118036,
    "Distance in Parsecs": 26.5604,
    "mag": 7.37,
    "Absolute Visual Magnitude": 5.249,
    "Spectral Type": "K4III",
    "Color Index": 1.521,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.344561,
    "y": -10.619558,
    "z": -0.145455,
    "vx": -0.00000849,
    "vy": 0.00002505,
    "vz": 0.00000327,
    "rarad": 3.55292729780517,
    "decrad": -0.0054764475921425,
    "pmrarad": -9.92365122729166e-7,
    "pmdecrad": 1.22560898e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 66003,
    "lum": 0.692468464322636,
    "ra": 13.571183,
    "dec": -0.313777,
    "pmra": -204.69,
    "pmdec": 25.28,
    "rv": -2.2,
    "Asset Name": "ASTRID 003361",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Bucket Hat",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000849,
    "y_1": 0.00002505,
    "z_1": 0.00000327,
    "Distance in Parsecs_1": 26.5604,
    "HYG Star ID_1": 66003,
    "Hipparcos Catalogue_1": 66212,
    "Henry Draper Catalogue_1": 118036
  },
  {
    "Astrid #": "ASTRID 003362",
    "HYG Star ID": 27849,
    "Hipparcos Catalogue": 27918,
    "Henry Draper Catalogue": 39715,
    "Gliese Catalog": "Gl 223",
    "Distance in Parsecs": 26.5675,
    "mag": 8.84,
    "Absolute Visual Magnitude": 6.718,
    "Spectral Type": "K3V",
    "Color Index": 1.014,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.639805,
    "y": 26.540848,
    "z": 0.993087,
    "vx": -0.00001116,
    "vy": -0.00003935,
    "vz": -0.0000848,
    "rarad": 1.54669455403144,
    "decrad": 0.0373889943929587,
    "pmrarad": 3.84117879104166e-7,
    "pmdecrad": -0.000003134126519,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 27849,
    "lum": 0.178978143940896,
    "ra": 5.907938,
    "dec": 2.142232,
    "pmra": 79.23,
    "pmdec": -646.46,
    "rv": -41.8,
    "Asset Name": "ASTRID 003362",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Greasy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00001116,
    "y_1": -0.00003935,
    "z_1": -0.0000848,
    "Distance in Parsecs_1": 26.5675,
    "HYG Star ID_1": 27849,
    "Hipparcos Catalogue_1": 27918,
    "Henry Draper Catalogue_1": 39715,
    "Gliese Catalog_1": "Gl 223"
  },
  {
    "Astrid #": "ASTRID 003363",
    "HYG Star ID": 28693,
    "Hipparcos Catalogue": 28764,
    "Henry Draper Catalogue": 41700,
    "Harvard Revised Catalogue": 2157,
    "Gliese Catalog": "Wo 9200",
    "Distance in Parsecs": 26.5675,
    "mag": 6.35,
    "Absolute Visual Magnitude": 4.228,
    "Spectral Type": "G0IV-V",
    "Color Index": 0.517,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.366477,
    "y": 18.77064,
    "z": -18.79822,
    "vx": 0.00000977,
    "vy": 0.00004129,
    "vz": 0.00000375,
    "rarad": 1.59031924106814,
    "decrad": -0.786037013538107,
    "pmrarad": -3.97935069e-7,
    "pmdecrad": 0.000001194338502,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 28693,
    "lum": 1.77337262454255,
    "ra": 6.074572,
    "dec": -45.036603,
    "pmra": -82.08,
    "pmdec": 246.35,
    "rv": 25.8,
    "Asset Name": "ASTRID 003363",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Headband",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000977,
    "y_1": 0.00004129,
    "z_1": 0.00000375,
    "Distance in Parsecs_1": 26.5675,
    "HYG Star ID_1": 28693,
    "Hipparcos Catalogue_1": 28764,
    "Henry Draper Catalogue_1": 41700,
    "Harvard Revised Catalogue_1": 2157,
    "Gliese Catalog_1": "Wo 9200"
  },
  {
    "Astrid #": "ASTRID 003364",
    "HYG Star ID": 27998,
    "Hipparcos Catalogue": 28066,
    "Henry Draper Catalogue": 39881,
    "Harvard Revised Catalogue": 2067,
    "Gliese Catalog": "Gl 224",
    "Distance in Parsecs": 26.6028,
    "mag": 6.6,
    "Absolute Visual Magnitude": 4.475,
    "Spectral Type": "G5IV",
    "Color Index": 0.65,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.444193,
    "y": 25.817177,
    "z": 6.401967,
    "vx": -0.00004784,
    "vy": 0.00001614,
    "vz": -0.00006049,
    "rarad": 1.55359219737913,
    "decrad": 0.24303556192802,
    "pmrarad": 0.00000180850047257638,
    "pmdecrad": -0.000002345722512,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 27998,
    "lum": 1.41253754462275,
    "ra": 5.934285,
    "dec": 13.924912,
    "pmra": 373.03,
    "pmdec": -483.84,
    "rv": 0.3,
    "Asset Name": "ASTRID 003364",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Prince",
    "Outfit": "Tats",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00004784,
    "y_1": 0.00001614,
    "z_1": -0.00006049,
    "Distance in Parsecs_1": 26.6028,
    "HYG Star ID_1": 27998,
    "Hipparcos Catalogue_1": 28066,
    "Henry Draper Catalogue_1": 39881,
    "Harvard Revised Catalogue_1": 2067,
    "Gliese Catalog_1": "Gl 224"
  },
  {
    "Astrid #": "ASTRID 003365",
    "HYG Star ID": 42821,
    "Hipparcos Catalogue": 42940,
    "Henry Draper Catalogue": 74377,
    "Gliese Catalog": "Gl 321",
    "Distance in Parsecs": 26.6028,
    "mag": 8.52,
    "Absolute Visual Magnitude": 6.395,
    "Spectral Type": "K3V",
    "Color Index": 0.94,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.113285,
    "y": 14.930048,
    "z": 17.687078,
    "vx": 0.00000302,
    "vy": 0.00005195,
    "vz": -0.00007945,
    "rarad": 2.29150482471169,
    "decrad": 0.727310733861758,
    "pmrarad": -0.00000137420437753472,
    "pmdecrad": -0.000003156815799,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 42821,
    "lum": 0.240990542868659,
    "ra": 8.752904,
    "dec": 41.671835,
    "pmra": -283.45,
    "pmdec": -651.14,
    "rv": -24.6,
    "Asset Name": "ASTRID 003365",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Mohawk",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000302,
    "y_1": 0.00005195,
    "z_1": -0.00007945,
    "Distance in Parsecs_1": 26.6028,
    "HYG Star ID_1": 42821,
    "Hipparcos Catalogue_1": 42940,
    "Henry Draper Catalogue_1": 74377,
    "Gliese Catalog_1": "Gl 321"
  },
  {
    "Astrid #": "ASTRID 003366",
    "HYG Star ID": 99596,
    "Hipparcos Catalogue": 99916,
    "Distance in Parsecs": 26.6028,
    "mag": 11.16,
    "Absolute Visual Magnitude": 9.035,
    "Color Index": 1.417,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 14.901653,
    "y": -22.016524,
    "z": -0.960419,
    "vx": 0.00003095,
    "vy": 0.00002114,
    "vz": -0.00000436,
    "rarad": 5.30740127314513,
    "decrad": -0.0361100149575125,
    "pmrarad": 0.00000140915944390277,
    "pmdecrad": -1.6410943e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 99596,
    "lum": 0.021183611352485,
    "ra": 20.27278,
    "dec": -2.068951,
    "pmra": 290.66,
    "pmdec": -33.85,
    "rv": 0,
    "Asset Name": "ASTRID 003366",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Mortified",
    "Hair": "Shinobi",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00003095,
    "y_1": 0.00002114,
    "z_1": -0.00000436,
    "Distance in Parsecs_1": 26.6028,
    "HYG Star ID_1": 99596,
    "Hipparcos Catalogue_1": 99916
  },
  {
    "Astrid #": "ASTRID 003367",
    "HYG Star ID": 60541,
    "Hipparcos Catalogue": 60729,
    "Henry Draper Catalogue": 108309,
    "Harvard Revised Catalogue": 4734,
    "Distance in Parsecs": 26.6099,
    "mag": 6.25,
    "Absolute Visual Magnitude": 4.125,
    "Spectral Type": "G5IV-V",
    "Color Index": 0.674,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.368795,
    "y": -2.040546,
    "z": -20.056475,
    "vx": -0.00002204,
    "vy": 0.0000808,
    "vz": -0.00002984,
    "rarad": 3.25855423016728,
    "decrad": -0.853696225031441,
    "pmrarad": -0.00000311255231053472,
    "pmdecrad": -3.84117879e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 60541,
    "lum": 1.94984459975804,
    "ra": 12.44676,
    "dec": -48.913191,
    "pmra": -642.01,
    "pmdec": -79.23,
    "rv": 30,
    "Asset Name": "ASTRID 003367",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Knocked Out",
    "Hair": "Tiny Crown",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00002204,
    "y_1": 0.0000808,
    "z_1": -0.00002984,
    "Distance in Parsecs_1": 26.6099,
    "HYG Star ID_1": 60541,
    "Hipparcos Catalogue_1": 60729,
    "Henry Draper Catalogue_1": 108309,
    "Harvard Revised Catalogue_1": 4734
  },
  {
    "Astrid #": "ASTRID 003368",
    "HYG Star ID": 70851,
    "Hipparcos Catalogue": 71075,
    "Henry Draper Catalogue": 127762,
    "Harvard Revised Catalogue": 5435,
    "Distance in Parsecs": 26.6099,
    "mag": 3.04,
    "Absolute Visual Magnitude": 0.915,
    "Spectral Type": "A7IIIvar",
    "Color Index": 0.191,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -16.449442,
    "y": -12.860715,
    "z": 16.49506,
    "vx": 0.00002378,
    "vy": 0.00003751,
    "vz": -0.00000808,
    "rarad": 3.80515885823068,
    "decrad": 0.66860513750719,
    "pmrarad": -5.60202207881944e-7,
    "pmdecrad": 7.36286536e-7,
    "bayer": "Gam",
    "flam": 27,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 70851,
    "lum": 37.4973002245483,
    "var": "Gam",
    "var_min": 3.052,
    "var_max": 3.032,
    "bf": "27Gam Boo",
    "ra": 14.534636,
    "dec": 38.308253,
    "pmra": -115.55,
    "pmdec": 151.87,
    "rv": -37,
    "Asset Name": "ASTRID 003368",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Unkempt",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00002378,
    "y_1": 0.00003751,
    "z_1": -0.00000808,
    "Distance in Parsecs_1": 26.6099,
    "HYG Star ID_1": 70851,
    "Hipparcos Catalogue_1": 71075,
    "Henry Draper Catalogue_1": 127762,
    "Harvard Revised Catalogue_1": 5435
  },
  {
    "Astrid #": "ASTRID 003369",
    "HYG Star ID": 31073,
    "Hipparcos Catalogue": 31148,
    "Gliese Catalog": "Gl 236",
    "Distance in Parsecs": 26.617,
    "mag": 11.42,
    "Absolute Visual Magnitude": 9.294,
    "Spectral Type": "M",
    "Color Index": 1.36,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.314828,
    "y": 23.477033,
    "z": -12.097908,
    "vx": -0.00006355,
    "vy": 0.00008053,
    "vz": -0.00008059,
    "rarad": 1.71106152699257,
    "decrad": -0.471811755680528,
    "pmrarad": 0.00000194148486515277,
    "pmdecrad": -0.000001183963489,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 31073,
    "lum": 0.0166878351136538,
    "var_min": 11.522,
    "var_max": 11.342,
    "ra": 6.535774,
    "dec": -27.032822,
    "pmra": 400.46,
    "pmdec": -244.21,
    "rv": 113,
    "Asset Name": "ASTRID 003369",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Uwu",
    "Hair": "Little Crown",
    "Outfit": "Scientist",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00006355,
    "y_1": 0.00008053,
    "z_1": -0.00008059,
    "Distance in Parsecs_1": 26.617,
    "HYG Star ID_1": 31073,
    "Hipparcos Catalogue_1": 31148,
    "Gliese Catalog_1": "Gl 236"
  },
  {
    "Astrid #": "ASTRID 003370",
    "HYG Star ID": 41094,
    "Hipparcos Catalogue": 41211,
    "Henry Draper Catalogue": 70958,
    "Harvard Revised Catalogue": 3297,
    "Gliese Catalog": "Gl 306",
    "Distance in Parsecs": 26.617,
    "mag": 5.61,
    "Absolute Visual Magnitude": 3.484,
    "Spectral Type": "F3V",
    "Color Index": 0.478,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.666609,
    "y": 21.448174,
    "z": -1.741453,
    "vx": -0.00002183,
    "vy": 0.00007604,
    "vz": -0.00000821,
    "rarad": 2.20166121012742,
    "decrad": -0.0654714927395137,
    "pmrarad": -0.00000102295686597222,
    "pmdecrad": -1.25227373e-7,
    "flam": 1,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 41094,
    "lum": 3.51884388820958,
    "bf": "1    Hya",
    "ra": 8.409726,
    "dec": -3.75124,
    "pmra": -211,
    "pmdec": -25.83,
    "rv": 73,
    "Asset Name": "ASTRID 003370",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Uwu",
    "Hair": "High Fade",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00002183,
    "y_1": 0.00007604,
    "z_1": -0.00000821,
    "Distance in Parsecs_1": 26.617,
    "HYG Star ID_1": 41094,
    "Hipparcos Catalogue_1": 41211,
    "Henry Draper Catalogue_1": 70958,
    "Harvard Revised Catalogue_1": 3297,
    "Gliese Catalog_1": "Gl 306"
  },
  {
    "Astrid #": "ASTRID 003371",
    "HYG Star ID": 97456,
    "Hipparcos Catalogue": 97767,
    "Henry Draper Catalogue": 187923,
    "Harvard Revised Catalogue": 7569,
    "Gliese Catalog": "NN 4126",
    "Distance in Parsecs": 26.617,
    "mag": 6.16,
    "Absolute Visual Magnitude": 4.034,
    "Spectral Type": "G0V",
    "Color Index": 0.642,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.245128,
    "y": -23.015855,
    "z": 5.364946,
    "vx": -0.00004443,
    "vy": -0.00001133,
    "vz": -0.00004614,
    "rarad": 5.20133147193014,
    "decrad": 0.202952619711878,
    "pmrarad": -0.00000167372226938194,
    "pmdecrad": -0.000001615690071,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 97456,
    "lum": 2.12031311673984,
    "ra": 19.867623,
    "dec": 11.628329,
    "pmra": -345.23,
    "pmdec": -333.26,
    "rv": -19.5,
    "Asset Name": "ASTRID 003371",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Windy",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00004443,
    "y_1": -0.00001133,
    "z_1": -0.00004614,
    "Distance in Parsecs_1": 26.617,
    "HYG Star ID_1": 97456,
    "Hipparcos Catalogue_1": 97767,
    "Henry Draper Catalogue_1": 187923,
    "Harvard Revised Catalogue_1": 7569,
    "Gliese Catalog_1": "NN 4126"
  },
  {
    "Astrid #": "ASTRID 003372",
    "HYG Star ID": 107693,
    "Hipparcos Catalogue": 108036,
    "Henry Draper Catalogue": 207958,
    "Harvard Revised Catalogue": 8351,
    "Gliese Catalog": "Gl 838.5",
    "Distance in Parsecs": 26.617,
    "mag": 5.08,
    "Absolute Visual Magnitude": 2.954,
    "Spectral Type": "F3IV",
    "Color Index": 0.378,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.021087,
    "y": -13.587736,
    "z": -6.236952,
    "vx": 0.00000337,
    "vy": 0.00004538,
    "vz": 0.00000687,
    "rarad": 5.73033527110731,
    "decrad": -0.236522972102237,
    "pmrarad": 0.00000151761226424305,
    "pmdecrad": 6.627403e-8,
    "bayer": "Mu",
    "flam": 51,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 107693,
    "lum": 5.7332383865384,
    "bf": "51Mu  Cap",
    "ra": 21.888268,
    "dec": -13.551768,
    "pmra": 313.03,
    "pmdec": 13.67,
    "rv": -21.5,
    "Asset Name": "ASTRID 003372",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Windy",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000337,
    "y_1": 0.00004538,
    "z_1": 0.00000687,
    "Distance in Parsecs_1": 26.617,
    "HYG Star ID_1": 107693,
    "Hipparcos Catalogue_1": 108036,
    "Henry Draper Catalogue_1": 207958,
    "Harvard Revised Catalogue_1": 8351,
    "Gliese Catalog_1": "Gl 838.5"
  },
  {
    "Astrid #": "ASTRID 003373",
    "HYG Star ID": 19880,
    "Hipparcos Catalogue": 19930,
    "Henry Draper Catalogue": 26900,
    "Distance in Parsecs": 26.6241,
    "mag": 8.25,
    "Absolute Visual Magnitude": 6.124,
    "Spectral Type": "K2",
    "Color Index": 0.918,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.336082,
    "y": 19.25773,
    "z": 15.837302,
    "vx": 0.00001082,
    "vy": 0.00001424,
    "vz": -0.00002369,
    "rarad": 1.1193855274771,
    "decrad": 0.637076970543867,
    "pmrarad": -1.32402616159722e-7,
    "pmdecrad": -0.000001107023558,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 19880,
    "lum": 0.309314301094896,
    "ra": 4.275738,
    "dec": 36.501822,
    "pmra": -27.31,
    "pmdec": -228.34,
    "rv": 0,
    "Asset Name": "ASTRID 003373",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Lovestruck",
    "Hair": "Shinobi",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00001082,
    "y_1": 0.00001424,
    "z_1": -0.00002369,
    "Distance in Parsecs_1": 26.6241,
    "HYG Star ID_1": 19880,
    "Hipparcos Catalogue_1": 19930,
    "Henry Draper Catalogue_1": 26900
  },
  {
    "Astrid #": "ASTRID 003374",
    "HYG Star ID": 26110,
    "Hipparcos Catalogue": 26175,
    "Henry Draper Catalogue": 37065,
    "Distance in Parsecs": 26.6241,
    "mag": 8.8,
    "Absolute Visual Magnitude": 6.674,
    "Spectral Type": "K2V",
    "Color Index": 0.923,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.678773,
    "y": 24.274054,
    "z": -10.603017,
    "vx": -0.0000461,
    "vy": -0.00004346,
    "vz": -0.00004001,
    "rarad": 1.46088447406172,
    "decrad": -0.409611029559403,
    "pmrarad": 0.00000154194991100694,
    "pmdecrad": -0.000002100406789,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 26110,
    "lum": 0.186380297234619,
    "ra": 5.580168,
    "dec": -23.468983,
    "pmra": 318.05,
    "pmdec": -433.24,
    "rv": -27.7,
    "Asset Name": "ASTRID 003374",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Bob",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.0000461,
    "y_1": -0.00004346,
    "z_1": -0.00004001,
    "Distance in Parsecs_1": 26.6241,
    "HYG Star ID_1": 26110,
    "Hipparcos Catalogue_1": 26175,
    "Henry Draper Catalogue_1": 37065
  },
  {
    "Astrid #": "ASTRID 003375",
    "HYG Star ID": 76368,
    "Hipparcos Catalogue": 76603,
    "Henry Draper Catalogue": 139461,
    "Harvard Revised Catalogue": 5816,
    "Distance in Parsecs": 26.6241,
    "mag": 6.45,
    "Absolute Visual Magnitude": 4.324,
    "Spectral Type": "F6V",
    "Color Index": 0.505,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.216546,
    "y": -21.464879,
    "z": -4.069202,
    "vx": 0.00000207,
    "vy": -8.1e-7,
    "vz": -0.00000348,
    "rarad": 4.09571190928241,
    "decrad": -0.153440447250255,
    "pmrarad": 8.10608473888888e-8,
    "pmdecrad": -1.32257172e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 76368,
    "lum": 1.62330452776545,
    "var_min": 6.635,
    "var_max": 6.415,
    "ra": 15.644467,
    "dec": -8.79149,
    "pmra": 16.72,
    "pmdec": -27.28,
    "rv": 0,
    "Asset Name": "ASTRID 003375",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Afro",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000207,
    "y_1": -8.1e-7,
    "z_1": -0.00000348,
    "Distance in Parsecs_1": 26.6241,
    "HYG Star ID_1": 76368,
    "Hipparcos Catalogue_1": 76603,
    "Henry Draper Catalogue_1": 139461,
    "Harvard Revised Catalogue_1": 5816
  },
  {
    "Astrid #": "ASTRID 003376",
    "HYG Star ID": 82892,
    "Hipparcos Catalogue": 83147,
    "Distance in Parsecs": 26.6241,
    "mag": 10.38,
    "Absolute Visual Magnitude": 8.254,
    "Spectral Type": "M1V:",
    "Color Index": 1.356,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.22407,
    "y": -23.049176,
    "z": -11.783002,
    "vx": 0.00001943,
    "vy": 0.00001247,
    "vz": -0.00003465,
    "rarad": 4.44864449963418,
    "decrad": -0.458461544633756,
    "pmrarad": 5.82358193083333e-7,
    "pmdecrad": -0.000001451386715,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82892,
    "lum": 0.0434910607232453,
    "var_min": 10.414,
    "var_max": 10.294,
    "ra": 16.99257,
    "dec": -26.267912,
    "pmra": 120.12,
    "pmdec": -299.37,
    "rv": 0,
    "Asset Name": "ASTRID 003376",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Gimme Blood",
    "Hair": "Ninja",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00001943,
    "y_1": 0.00001247,
    "z_1": -0.00003465,
    "Distance in Parsecs_1": 26.6241,
    "HYG Star ID_1": 82892,
    "Hipparcos Catalogue_1": 83147
  },
  {
    "Astrid #": "ASTRID 003377",
    "HYG Star ID": 112032,
    "Hipparcos Catalogue": 112388,
    "Distance in Parsecs": 26.6241,
    "mag": 11.98,
    "Absolute Visual Magnitude": 9.854,
    "Spectral Type": "M1V:",
    "Color Index": 1.459,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 25.228839,
    "y": -8.469476,
    "z": 0.785068,
    "vx": 0.00000846,
    "vy": 0.00002465,
    "vz": -0.00000597,
    "rarad": 5.95930075341547,
    "decrad": 0.0294913914743687,
    "pmrarad": 9.78790339673611e-7,
    "pmdecrad": -2.24517215e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 112032,
    "lum": 0.00996322641954418,
    "var_min": 12.074,
    "var_max": 11.854,
    "ra": 22.762852,
    "dec": 1.689732,
    "pmra": 201.89,
    "pmdec": -46.31,
    "rv": 0,
    "Asset Name": "ASTRID 003377",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Bandana",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000846,
    "y_1": 0.00002465,
    "z_1": -0.00000597,
    "Distance in Parsecs_1": 26.6241,
    "HYG Star ID_1": 112032,
    "Hipparcos Catalogue_1": 112388
  },
  {
    "Astrid #": "ASTRID 003378",
    "HYG Star ID": 52319,
    "Hipparcos Catalogue": 52470,
    "Henry Draper Catalogue": 92786,
    "Distance in Parsecs": 26.6312,
    "mag": 8.02,
    "Absolute Visual Magnitude": 5.893,
    "Spectral Type": "G5",
    "Color Index": 0.749,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.767644,
    "y": 5.809288,
    "z": 19.857079,
    "vx": 0.00005146,
    "vy": 0.00002737,
    "vz": -0.00000913,
    "rarad": 2.80808357779538,
    "decrad": 0.841495242839952,
    "pmrarad": -0.00000160390909938194,
    "pmdecrad": 8.81924566e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 52319,
    "lum": 0.382648486595109,
    "ra": 10.726089,
    "dec": 48.214126,
    "pmra": -330.83,
    "pmdec": 181.91,
    "rv": -32.5,
    "Asset Name": "ASTRID 003378",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Good Boy",
    "Outfit": "Suit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00005146,
    "y_1": 0.00002737,
    "z_1": -0.00000913,
    "Distance in Parsecs_1": 26.6312,
    "HYG Star ID_1": 52319,
    "Hipparcos Catalogue_1": 52470,
    "Henry Draper Catalogue_1": 92786
  },
  {
    "Astrid #": "ASTRID 003379",
    "HYG Star ID": 60776,
    "Hipparcos Catalogue": 60965,
    "Henry Draper Catalogue": 108767,
    "Harvard Revised Catalogue": 4757,
    "Proper Name": "Algorab",
    "Distance in Parsecs": 26.6312,
    "mag": 2.94,
    "Absolute Visual Magnitude": 0.813,
    "Spectral Type": "B9.5V",
    "Color Index": -0.012,
    "Spectral Abbreviation": "B",
    "Star Type": "Teal",
    "Color In Star Map": "Teal",
    "x": -25.316094,
    "y": -3.317674,
    "z": -7.57057,
    "vx": -0.0000072,
    "vy": 0.00002639,
    "vz": -0.00001986,
    "rarad": 3.27190043482125,
    "decrad": -0.288248674272748,
    "pmrarad": -0.0000010179632850625,
    "pmdecrad": -6.75345457e-7,
    "bayer": "Del",
    "flam": 7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60776,
    "lum": 41.1907784867737,
    "bf": "7Del Crv",
    "ra": 12.497739,
    "dec": -16.515432,
    "pmra": -209.97,
    "pmdec": -139.3,
    "rv": 9,
    "Asset Name": "ASTRID 003379",
    "Astrium Color": "Teal",
    "Background": "Hazy Void",
    "Expression": "Oni Mask",
    "Hair": "Manbun",
    "Outfit": "Samurai",
    "Special": "Overburner",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Algorab",
    "Constellation": "None",
    "x_1": -0.0000072,
    "y_1": 0.00002639,
    "z_1": -0.00001986,
    "Distance in Parsecs_1": 26.6312,
    "HYG Star ID_1": 60776,
    "Hipparcos Catalogue_1": 60965,
    "Henry Draper Catalogue_1": 108767,
    "Harvard Revised Catalogue_1": 4757
  },
  {
    "Astrid #": "ASTRID 003380",
    "HYG Star ID": 88494,
    "Hipparcos Catalogue": 88771,
    "Henry Draper Catalogue": 165777,
    "Harvard Revised Catalogue": 6771,
    "Gliese Catalog": "Wo 9615A",
    "Distance in Parsecs": 26.6312,
    "mag": 3.71,
    "Absolute Visual Magnitude": 1.583,
    "Spectral Type": "A4IVs",
    "Color Index": 0.159,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 0.842022,
    "y": -26.247342,
    "z": 4.424647,
    "vx": -0.00000878,
    "vy": 0.00002554,
    "vz": 0.00000609,
    "rarad": 4.74445828885426,
    "decrad": 0.16692062239361,
    "pmrarad": -2.98645227222222e-7,
    "pmdecrad": 3.86444984e-7,
    "flam": 72,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 88494,
    "base": "Wo 9615",
    "lum": 20.2674915209896,
    "bf": "72    Oph",
    "ra": 18.122496,
    "dec": 9.563847,
    "pmra": -61.6,
    "pmdec": 79.71,
    "rv": -23.9,
    "Asset Name": "ASTRID 003380",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Infatuated",
    "Hair": "Medium Bob",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000878,
    "y_1": 0.00002554,
    "z_1": 0.00000609,
    "Distance in Parsecs_1": 26.6312,
    "HYG Star ID_1": 88494,
    "Hipparcos Catalogue_1": 88771,
    "Henry Draper Catalogue_1": 165777,
    "Harvard Revised Catalogue_1": 6771,
    "Gliese Catalog_1": "Wo 9615A"
  },
  {
    "Astrid #": "ASTRID 003381",
    "HYG Star ID": 54300,
    "Hipparcos Catalogue": 54459,
    "Henry Draper Catalogue": 96692,
    "Distance in Parsecs": 26.6383,
    "mag": 9.75,
    "Absolute Visual Magnitude": 7.622,
    "Spectral Type": "K0",
    "Color Index": 1.101,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.993025,
    "y": 5.709318,
    "z": 7.238847,
    "vx": -0.00011698,
    "vy": 0.00000589,
    "vz": -0.00001787,
    "rarad": 2.91700951500587,
    "decrad": 0.275196819902552,
    "pmrarad": 7.62514956777777e-7,
    "pmdecrad": -0.000001822753994,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54300,
    "lum": 0.0778394932626725,
    "ra": 11.142156,
    "dec": 15.767616,
    "pmra": 157.28,
    "pmdec": -375.97,
    "rv": 103.8,
    "Asset Name": "ASTRID 003381",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Ponytail",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00011698,
    "y_1": 0.00000589,
    "z_1": -0.00001787,
    "Distance in Parsecs_1": 26.6383,
    "HYG Star ID_1": 54300,
    "Hipparcos Catalogue_1": 54459,
    "Henry Draper Catalogue_1": 96692
  },
  {
    "Astrid #": "ASTRID 003382",
    "HYG Star ID": 81496,
    "Hipparcos Catalogue": 81746,
    "Henry Draper Catalogue": 150248,
    "Distance in Parsecs": 26.6383,
    "mag": 7.03,
    "Absolute Visual Magnitude": 4.902,
    "Spectral Type": "G3V",
    "Color Index": 0.653,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.260252,
    "y": -17.636829,
    "z": -18.957372,
    "vx": -0.00000438,
    "vy": -0.00003947,
    "vz": -0.00005654,
    "rarad": 4.37130694508909,
    "decrad": -0.791833674499963,
    "pmrarad": 3.40581610590277e-7,
    "pmdecrad": -4.58342853e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 81496,
    "lum": 0.953235043920514,
    "ra": 16.697163,
    "dec": -45.368728,
    "pmra": 70.25,
    "pmdec": -94.54,
    "rv": 65.9,
    "Asset Name": "ASTRID 003382",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Braided Ponytail",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000438,
    "y_1": -0.00003947,
    "z_1": -0.00005654,
    "Distance in Parsecs_1": 26.6383,
    "HYG Star ID_1": 81496,
    "Hipparcos Catalogue_1": 81746,
    "Henry Draper Catalogue_1": 150248
  },
  {
    "Astrid #": "ASTRID 003383",
    "HYG Star ID": 117329,
    "Hipparcos Catalogue": 117697,
    "Distance in Parsecs": 26.6383,
    "mag": 11.69,
    "Absolute Visual Magnitude": 9.562,
    "Color Index": 1.474,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 25.071687,
    "y": -0.856274,
    "z": -8.959708,
    "vx": 0.00000157,
    "vy": -0.00002617,
    "vz": 0.00000689,
    "rarad": 6.24904606697922,
    "decrad": -0.343035002839187,
    "pmrarad": -9.80002373875e-7,
    "pmdecrad": 2.7464695e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 117329,
    "lum": 0.0130376704713821,
    "var_min": 11.757,
    "var_max": 11.627,
    "ra": 23.869598,
    "dec": -19.654458,
    "pmra": -202.14,
    "pmdec": 56.65,
    "rv": 0,
    "Asset Name": "ASTRID 003383",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Mohawk",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000157,
    "y_1": -0.00002617,
    "z_1": 0.00000689,
    "Distance in Parsecs_1": 26.6383,
    "HYG Star ID_1": 117329,
    "Hipparcos Catalogue_1": 117697
  },
  {
    "Astrid #": "ASTRID 003384",
    "HYG Star ID": 11624,
    "Hipparcos Catalogue": 11650,
    "Gliese Catalog": "Gl 100.1",
    "Distance in Parsecs": 26.6525,
    "mag": 12.79,
    "Absolute Visual Magnitude": 10.661,
    "Spectral Type": "DA",
    "Color Index": -0.056,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 21.036334,
    "y": 16.182189,
    "z": 2.445294,
    "vx": 0.00001884,
    "vy": 0.00002609,
    "vz": -3.5e-7,
    "rarad": 0.65570738726817,
    "decrad": 0.0918755424385562,
    "pmrarad": 3.44944933715277e-7,
    "pmdecrad": -1.19264165e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 11624,
    "lum": 0.00473805393338825,
    "var_min": 12.972,
    "var_max": 12.592,
    "ra": 2.504618,
    "dec": 5.264081,
    "pmra": 71.15,
    "pmdec": -24.6,
    "rv": 30,
    "Asset Name": "ASTRID 003384",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Gimme Blood",
    "Hair": "Bangs",
    "Outfit": "School Uniform",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001884,
    "y_1": 0.00002609,
    "z_1": -3.5e-7,
    "Distance in Parsecs_1": 26.6525,
    "HYG Star ID_1": 11624,
    "Hipparcos Catalogue_1": 11650,
    "Gliese Catalog_1": "Gl 100.1"
  },
  {
    "Astrid #": "ASTRID 003385",
    "HYG Star ID": 107625,
    "Hipparcos Catalogue": 107968,
    "Gliese Catalog": "Gl 838.4",
    "Distance in Parsecs": 26.6596,
    "mag": 12.74,
    "Absolute Visual Magnitude": 10.611,
    "Spectral Type": "DA:",
    "Color Index": -0.011,
    "Spectral Abbreviation": "D",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 22.614994,
    "y": -14.073437,
    "z": 1.111166,
    "vx": 0.00000296,
    "vy": 0.00000166,
    "vz": -0.00003912,
    "rarad": 5.72652587368753,
    "decrad": 0.0416918232257212,
    "pmrarad": 1.11507146527777e-7,
    "pmdecrad": -0.000001468888489,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 107625,
    "lum": 0.00496135153585568,
    "var_min": 12.97,
    "var_max": 12.56,
    "ra": 21.873718,
    "dec": 2.388766,
    "pmra": 23,
    "pmdec": -302.98,
    "rv": 0,
    "Asset Name": "ASTRID 003385",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "Wavy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000296,
    "y_1": 0.00000166,
    "z_1": -0.00003912,
    "Distance in Parsecs_1": 26.6596,
    "HYG Star ID_1": 107625,
    "Hipparcos Catalogue_1": 107968,
    "Gliese Catalog_1": "Gl 838.4"
  },
  {
    "Astrid #": "ASTRID 003386",
    "HYG Star ID": 38009,
    "Hipparcos Catalogue": 38117,
    "Henry Draper Catalogue": 233453,
    "Distance in Parsecs": 26.6667,
    "mag": 8.86,
    "Absolute Visual Magnitude": 6.73,
    "Spectral Type": "K4V",
    "Color Index": 1.068,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.216409,
    "y": 14.062832,
    "z": 21.477269,
    "vx": -0.00001076,
    "vy": 0.00005633,
    "vz": -0.0000405,
    "rarad": 2.04491593171354,
    "decrad": 0.936344222124731,
    "pmrarad": -6.05289880173611e-7,
    "pmdecrad": -0.000002562385745,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 38009,
    "lum": 0.177010895831741,
    "ra": 7.811003,
    "dec": 53.648572,
    "pmra": -124.85,
    "pmdec": -528.53,
    "rv": 0,
    "Asset Name": "ASTRID 003386",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Lion Mane",
    "Outfit": "River Spirit",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00001076,
    "y_1": 0.00005633,
    "z_1": -0.0000405,
    "Distance in Parsecs_1": 26.6667,
    "HYG Star ID_1": 38009,
    "Hipparcos Catalogue_1": 38117,
    "Henry Draper Catalogue_1": 233453
  },
  {
    "Astrid #": "ASTRID 003387",
    "HYG Star ID": 40323,
    "Hipparcos Catalogue": 40438,
    "Henry Draper Catalogue": 69655,
    "Distance in Parsecs": 26.6738,
    "mag": 6.63,
    "Absolute Visual Magnitude": 4.5,
    "Spectral Type": "G1V",
    "Color Index": 0.579,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.136284,
    "y": 13.619289,
    "z": -21.036513,
    "vx": 0.00000383,
    "vy": -0.00000445,
    "vz": -0.00000454,
    "rarad": 2.16167873692553,
    "decrad": -0.908623633795616,
    "pmrarad": -2.617993875e-8,
    "pmdecrad": -2.77119499e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 40323,
    "lum": 1.38038426460288,
    "ra": 8.257005,
    "dec": -52.060299,
    "pmra": -5.4,
    "pmdec": -57.16,
    "rv": 0,
    "Asset Name": "ASTRID 003387",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Unimpressed",
    "Hair": "Lion Mane",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000383,
    "y_1": -0.00000445,
    "z_1": -0.00000454,
    "Distance in Parsecs_1": 26.6738,
    "HYG Star ID_1": 40323,
    "Hipparcos Catalogue_1": 40438,
    "Henry Draper Catalogue_1": 69655
  },
  {
    "Astrid #": "ASTRID 003388",
    "HYG Star ID": 15285,
    "Hipparcos Catalogue": 15323,
    "Henry Draper Catalogue": 20367,
    "Distance in Parsecs": 26.6809,
    "mag": 6.4,
    "Absolute Visual Magnitude": 4.269,
    "Spectral Type": "G0",
    "Color Index": 0.574,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.858261,
    "y": 17.345756,
    "z": 13.792382,
    "vx": 0.00001561,
    "vy": -0.00000227,
    "vz": -0.00000347,
    "rarad": 0.862487623798796,
    "decrad": 0.543269468839187,
    "pmrarad": -4.99794423284722e-7,
    "pmdecrad": -2.7464695e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 15285,
    "lum": 1.70765447281387,
    "ra": 3.29446,
    "dec": 31.127048,
    "pmra": -103.09,
    "pmdec": -56.65,
    "rv": 5.3,
    "Asset Name": "ASTRID 003388",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Horns",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00001561,
    "y_1": -0.00000227,
    "z_1": -0.00000347,
    "Distance in Parsecs_1": 26.6809,
    "HYG Star ID_1": 15285,
    "Hipparcos Catalogue_1": 15323,
    "Henry Draper Catalogue_1": 20367
  },
  {
    "Astrid #": "ASTRID 003389",
    "HYG Star ID": 24396,
    "Hipparcos Catalogue": 24454,
    "Henry Draper Catalogue": 290054,
    "Distance in Parsecs": 26.688,
    "mag": 9.97,
    "Absolute Visual Magnitude": 7.838,
    "Spectral Type": "K2III",
    "Color Index": 1.189,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.22881,
    "y": 26.168948,
    "z": 0.308331,
    "vx": -0.00002974,
    "vy": 0.00000662,
    "vz": -0.00005771,
    "rarad": 1.37358373691926,
    "decrad": 0.0115534356008487,
    "pmrarad": 0.00000114159077360416,
    "pmdecrad": -0.000002162559903,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 24396,
    "lum": 0.0637969622662578,
    "ra": 5.246703,
    "dec": 0.661963,
    "pmra": 235.47,
    "pmdec": -446.06,
    "rv": 0,
    "Asset Name": "ASTRID 003389",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Spiky",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00002974,
    "y_1": 0.00000662,
    "z_1": -0.00005771,
    "Distance in Parsecs_1": 26.688,
    "HYG Star ID_1": 24396,
    "Hipparcos Catalogue_1": 24454,
    "Henry Draper Catalogue_1": 290054
  },
  {
    "Astrid #": "ASTRID 003390",
    "HYG Star ID": 34692,
    "Hipparcos Catalogue": 34785,
    "Distance in Parsecs": 26.688,
    "mag": 10.37,
    "Absolute Visual Magnitude": 8.238,
    "Spectral Type": "M0V:",
    "Color Index": 1.33,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -7.488965,
    "y": 23.021263,
    "z": -11.233262,
    "vx": 0.0000141,
    "vy": 0.00000414,
    "vz": -9.3e-7,
    "rarad": 1.88530584064886,
    "decrad": -0.434448936551667,
    "pmrarad": -5.50554415638888e-7,
    "pmdecrad": -3.8348762e-8,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 34692,
    "lum": 0.0441367143463439,
    "var_min": 10.433,
    "var_max": 10.293,
    "ra": 7.201338,
    "dec": -24.89209,
    "pmra": -113.56,
    "pmdec": -7.91,
    "rv": 0,
    "Asset Name": "ASTRID 003390",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Charmer",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.0000141,
    "y_1": 0.00000414,
    "z_1": -9.3e-7,
    "Distance in Parsecs_1": 26.688,
    "HYG Star ID_1": 34692,
    "Hipparcos Catalogue_1": 34785
  },
  {
    "Astrid #": "ASTRID 003391",
    "HYG Star ID": 77173,
    "Hipparcos Catalogue": 77409,
    "Henry Draper Catalogue": 142474,
    "Gliese Catalog": "NN 3922",
    "Distance in Parsecs": 26.688,
    "mag": 9.24,
    "Absolute Visual Magnitude": 7.108,
    "Spectral Type": "K5",
    "Color Index": 1.252,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.900998,
    "y": -6.016543,
    "z": 25.706417,
    "vx": -0.00000446,
    "vy": -0.00003343,
    "vz": -0.00003717,
    "rarad": 4.13714155159928,
    "decrad": 1.29877130171398,
    "pmrarad": 5.41439918444444e-7,
    "pmdecrad": -0.000001474318402,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 77173,
    "lum": 0.124968339721121,
    "var_min": 9.307,
    "var_max": 9.207,
    "ra": 15.802717,
    "dec": 74.414114,
    "pmra": 111.68,
    "pmdec": -304.1,
    "rv": -27,
    "Asset Name": "ASTRID 003391",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Halo Boy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": -0.00000446,
    "y_1": -0.00003343,
    "z_1": -0.00003717,
    "Distance in Parsecs_1": 26.688,
    "HYG Star ID_1": 77173,
    "Hipparcos Catalogue_1": 77409,
    "Henry Draper Catalogue_1": 142474,
    "Gliese Catalog_1": "NN 3922"
  },
  {
    "Astrid #": "ASTRID 003392",
    "HYG Star ID": 91662,
    "Hipparcos Catalogue": 91949,
    "Henry Draper Catalogue": 173701,
    "Gliese Catalog": "Gl 725.1",
    "Distance in Parsecs": 26.688,
    "mag": 7.54,
    "Absolute Visual Magnitude": 5.408,
    "Spectral Type": "K0",
    "Color Index": 0.843,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.721575,
    "y": -18.888174,
    "z": 18.482825,
    "vx": -0.00001982,
    "vy": 0.00002952,
    "vz": -0.0000317,
    "rarad": 4.90693082144466,
    "decrad": 0.765034995942991,
    "pmrarad": -5.14920610118055e-7,
    "pmdecrad": -5.720801e-9,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 91662,
    "lum": 0.598136079781273,
    "ra": 18.743095,
    "dec": 43.833276,
    "pmra": -106.21,
    "pmdec": -1.18,
    "rv": -44.6,
    "Asset Name": "ASTRID 003392",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Laughing",
    "Hair": "Braid",
    "Outfit": "Viking",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00001982,
    "y_1": 0.00002952,
    "z_1": -0.0000317,
    "Distance in Parsecs_1": 26.688,
    "HYG Star ID_1": 91662,
    "Hipparcos Catalogue_1": 91949,
    "Henry Draper Catalogue_1": 173701,
    "Gliese Catalog_1": "Gl 725.1"
  },
  {
    "Astrid #": "ASTRID 003393",
    "HYG Star ID": 11755,
    "Hipparcos Catalogue": 11783,
    "Henry Draper Catalogue": 15798,
    "Harvard Revised Catalogue": 740,
    "Distance in Parsecs": 26.6951,
    "mag": 4.74,
    "Absolute Visual Magnitude": 2.608,
    "Spectral Type": "F5V",
    "Color Index": 0.454,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.289578,
    "y": 15.864382,
    "z": -7.019183,
    "vx": -0.00002003,
    "vy": -0.00002895,
    "vz": -0.00001054,
    "rarad": 0.663605452276734,
    "decrad": -0.26606980312857,
    "pmrarad": -3.92311230305555e-7,
    "pmdecrad": -7.11900408e-7,
    "bayer": "Sig",
    "flam": 76,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 11755,
    "lum": 7.88496917745377,
    "bf": "76Sig Cet",
    "ra": 2.534786,
    "dec": -15.244677,
    "pmra": -80.92,
    "pmdec": -146.84,
    "rv": -29,
    "Asset Name": "ASTRID 003393",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Worried Vamp",
    "Hair": "Braided Bun",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002003,
    "y_1": -0.00002895,
    "z_1": -0.00001054,
    "Distance in Parsecs_1": 26.6951,
    "HYG Star ID_1": 11755,
    "Hipparcos Catalogue_1": 11783,
    "Henry Draper Catalogue_1": 15798,
    "Harvard Revised Catalogue_1": 740
  },
  {
    "Astrid #": "ASTRID 003394",
    "HYG Star ID": 37910,
    "Hipparcos Catalogue": 38018,
    "Henry Draper Catalogue": 61994,
    "Gliese Catalog": "Gl 285.1",
    "Distance in Parsecs": 26.7023,
    "mag": 7.06,
    "Absolute Visual Magnitude": 4.927,
    "Spectral Type": "G0",
    "Color Index": 0.712,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.087777,
    "y": 8.065325,
    "z": 25.124542,
    "vx": 0.00000597,
    "vy": 0.00001498,
    "vz": -0.0000284,
    "rarad": 2.03990352358755,
    "decrad": 1.22533747531071,
    "pmrarad": -4.52961421743055e-7,
    "pmdecrad": -7.35850204e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 37910,
    "lum": 0.931536765238574,
    "ra": 7.791858,
    "dec": 70.206666,
    "pmra": -93.43,
    "pmdec": -151.78,
    "rv": -22.6,
    "Asset Name": "ASTRID 003394",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Round Brush",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000597,
    "y_1": 0.00001498,
    "z_1": -0.0000284,
    "Distance in Parsecs_1": 26.7023,
    "HYG Star ID_1": 37910,
    "Hipparcos Catalogue_1": 38018,
    "Henry Draper Catalogue_1": 61994,
    "Gliese Catalog_1": "Gl 285.1"
  },
  {
    "Astrid #": "ASTRID 003395",
    "HYG Star ID": 13631,
    "Hipparcos Catalogue": 13665,
    "Henry Draper Catalogue": 17948,
    "Harvard Revised Catalogue": 860,
    "Gliese Catalog": "NN 3188",
    "Distance in Parsecs": 26.7094,
    "mag": 5.59,
    "Absolute Visual Magnitude": 3.457,
    "Spectral Type": "F4V",
    "Color Index": 0.445,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.163556,
    "y": 8.845178,
    "z": 23.477607,
    "vx": -0.00000532,
    "vy": 0.00002097,
    "vz": 0.00002815,
    "rarad": 0.767714173043055,
    "decrad": 1.07374641226971,
    "pmrarad": 7.03319206381944e-7,
    "pmdecrad": 1.49516539e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 13631,
    "lum": 3.60744733864191,
    "ra": 2.932452,
    "dec": 61.521138,
    "pmra": 145.07,
    "pmdec": 30.84,
    "rv": 29.2,
    "Asset Name": "ASTRID 003395",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Slick",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000532,
    "y_1": 0.00002097,
    "z_1": 0.00002815,
    "Distance in Parsecs_1": 26.7094,
    "HYG Star ID_1": 13631,
    "Hipparcos Catalogue_1": 13665,
    "Henry Draper Catalogue_1": 17948,
    "Harvard Revised Catalogue_1": 860,
    "Gliese Catalog_1": "NN 3188"
  },
  {
    "Astrid #": "ASTRID 003396",
    "HYG Star ID": 97882,
    "Hipparcos Catalogue": 98192,
    "Henry Draper Catalogue": 189087,
    "Gliese Catalog": "Gl 773.2",
    "Distance in Parsecs": 26.7165,
    "mag": 7.91,
    "Absolute Visual Magnitude": 5.776,
    "Spectral Type": "K1V",
    "Color Index": 0.797,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.344923,
    "y": -20.211528,
    "z": 13.286989,
    "vx": -0.00000814,
    "vy": 0.00004299,
    "vz": 0.00001148,
    "rarad": 5.22387294506607,
    "decrad": 0.520527253038312,
    "pmrarad": 5.21950408486111e-7,
    "pmdecrad": 0.00000114503295,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 97882,
    "lum": 0.42618680542416,
    "ra": 19.953725,
    "dec": 29.824015,
    "pmra": 107.66,
    "pmdec": 236.18,
    "rv": -29.6,
    "Asset Name": "ASTRID 003396",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Battle Ready",
    "Hair": "Tiny Crown",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000814,
    "y_1": 0.00004299,
    "z_1": 0.00001148,
    "Distance in Parsecs_1": 26.7165,
    "HYG Star ID_1": 97882,
    "Hipparcos Catalogue_1": 98192,
    "Henry Draper Catalogue_1": 189087,
    "Gliese Catalog_1": "Gl 773.2"
  },
  {
    "Astrid #": "ASTRID 003397",
    "HYG Star ID": 27559,
    "Hipparcos Catalogue": 27628,
    "Henry Draper Catalogue": 39425,
    "Harvard Revised Catalogue": 2040,
    "Distance in Parsecs": 26.7308,
    "mag": 3.12,
    "Absolute Visual Magnitude": 0.985,
    "Spectral Type": "K1.5III",
    "Color Index": 1.146,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.855327,
    "y": 21.672805,
    "z": -15.624858,
    "vx": -0.0000031,
    "vy": 0.00010471,
    "vz": -0.00001066,
    "rarad": 1.53135081455752,
    "decrad": -0.624274752989982,
    "pmrarad": 2.70235145541666e-7,
    "pmdecrad": 0.000001961944002,
    "bayer": "Bet",
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 27559,
    "lum": 35.1560440528298,
    "bf": "Bet Col",
    "ra": 5.849329,
    "dec": -35.768309,
    "pmra": 55.74,
    "pmdec": 404.68,
    "rv": 89,
    "Asset Name": "ASTRID 003397",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Manbun",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": -0.0000031,
    "y_1": 0.00010471,
    "z_1": -0.00001066,
    "Distance in Parsecs_1": 26.7308,
    "HYG Star ID_1": 27559,
    "Hipparcos Catalogue_1": 27628,
    "Henry Draper Catalogue_1": 39425,
    "Harvard Revised Catalogue_1": 2040
  },
  {
    "Astrid #": "ASTRID 003398",
    "HYG Star ID": 56633,
    "Hipparcos Catalogue": 56802,
    "Henry Draper Catalogue": 101198,
    "Harvard Revised Catalogue": 4488,
    "Gliese Catalog": "NN 3677A",
    "Distance in Parsecs": 26.7308,
    "mag": 5.48,
    "Absolute Visual Magnitude": 3.345,
    "Spectral Type": "F7V",
    "Color Index": 0.52,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -25.911468,
    "y": 2.418907,
    "z": -6.104835,
    "vx": 0.00001868,
    "vy": -0.00001471,
    "vz": 0.00002148,
    "rarad": 3.04850952399964,
    "decrad": -0.230417370717575,
    "pmrarad": 4.82971388569444e-7,
    "pmdecrad": 6.1168942e-7,
    "bayer": "Iot",
    "flam": 24,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 56633,
    "base": "NN 3677",
    "lum": 3.99944749761097,
    "bf": "24Iot Crt",
    "ra": 11.644449,
    "dec": -13.201943,
    "pmra": 99.62,
    "pmdec": 126.17,
    "rv": -23.8,
    "Asset Name": "ASTRID 003398",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Awkward",
    "Hair": "Prince",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00001868,
    "y_1": -0.00001471,
    "z_1": 0.00002148,
    "Distance in Parsecs_1": 26.7308,
    "HYG Star ID_1": 56633,
    "Hipparcos Catalogue_1": 56802,
    "Henry Draper Catalogue_1": 101198,
    "Harvard Revised Catalogue_1": 4488,
    "Gliese Catalog_1": "NN 3677A"
  },
  {
    "Astrid #": "ASTRID 003399",
    "HYG Star ID": 62744,
    "Hipparcos Catalogue": 62942,
    "Henry Draper Catalogue": 112099,
    "Distance in Parsecs": 26.7308,
    "mag": 8.24,
    "Absolute Visual Magnitude": 6.105,
    "Spectral Type": "G5",
    "Color Index": 0.853,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -25.813893,
    "y": -6.18625,
    "z": 3.147845,
    "vx": -0.00000558,
    "vy": 0.00002938,
    "vz": 0.00001197,
    "rarad": 3.37680491348986,
    "decrad": 0.118034851171317,
    "pmrarad": -0.00000111754401504861,
    "pmdecrad": 4.50876722e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 62744,
    "lum": 0.314774831410131,
    "ra": 12.898445,
    "dec": 6.762899,
    "pmra": -230.51,
    "pmdec": 93,
    "rv": 0,
    "Asset Name": "ASTRID 003399",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Bring It",
    "Hair": "Greasy",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000558,
    "y_1": 0.00002938,
    "z_1": 0.00001197,
    "Distance in Parsecs_1": 26.7308,
    "HYG Star ID_1": 62744,
    "Hipparcos Catalogue_1": 62942,
    "Henry Draper Catalogue_1": 112099
  },
  {
    "Astrid #": "ASTRID 003400",
    "HYG Star ID": 118944,
    "Gliese Catalog": "NN 3825",
    "Distance in Parsecs": 26.738,
    "mag": 14.54,
    "Absolute Visual Magnitude": 12.404,
    "Spectral Type": "m",
    "Color Index": 1.7,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.769689,
    "y": -10.989602,
    "z": 16.684947,
    "vx": -0.00004253,
    "vy": -0.00008128,
    "vz": -0.00009877,
    "rarad": 3.69544098281204,
    "decrad": 0.6738719343984,
    "pmrarad": 0.0000017492562408125,
    "pmdecrad": -0.000004728892032,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118944,
    "lum": 0.000951480736351758,
    "ra": 14.115545,
    "dec": 38.610018,
    "pmra": 360.81,
    "pmdec": -975.4,
    "rv": 0,
    "Asset Name": "ASTRID 003400",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Gimme Blood",
    "Hair": "Greasy",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004253,
    "y_1": -0.00008128,
    "z_1": -0.00009877,
    "Distance in Parsecs_1": 26.738,
    "HYG Star ID_1": 118944,
    "Gliese Catalog_1": "NN 3825"
  },
  {
    "Astrid #": "ASTRID 003401",
    "HYG Star ID": 3497,
    "Hipparcos Catalogue": 3505,
    "Henry Draper Catalogue": 4247,
    "Harvard Revised Catalogue": 197,
    "Gliese Catalog": "Gl 31.3",
    "Distance in Parsecs": 26.7451,
    "mag": 5.22,
    "Absolute Visual Magnitude": 3.084,
    "Spectral Type": "F0V",
    "Color Index": 0.35,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 24.325675,
    "y": 4.809987,
    "z": -10.02159,
    "vx": 0.00001781,
    "vy": -0.00000521,
    "vz": 0.0000047,
    "rarad": 0.195214986231511,
    "decrad": -0.384079514519866,
    "pmrarad": -3.20316398743055e-7,
    "pmdecrad": 3.93426301e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3497,
    "lum": 5.08627690306436,
    "ra": 0.745666,
    "dec": -22.006135,
    "pmra": -66.07,
    "pmdec": 81.15,
    "rv": 13.2,
    "Asset Name": "ASTRID 003401",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Unkempt",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00001781,
    "y_1": -0.00000521,
    "z_1": 0.0000047,
    "Distance in Parsecs_1": 26.7451,
    "HYG Star ID_1": 3497,
    "Hipparcos Catalogue_1": 3505,
    "Henry Draper Catalogue_1": 4247,
    "Harvard Revised Catalogue_1": 197,
    "Gliese Catalog_1": "Gl 31.3"
  },
  {
    "Astrid #": "ASTRID 003402",
    "HYG Star ID": 27343,
    "Hipparcos Catalogue": 27397,
    "Gliese Catalog": "NN 3369",
    "Distance in Parsecs": 26.7451,
    "mag": 10.98,
    "Absolute Visual Magnitude": 8.844,
    "Color Index": 1.384,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 1.340719,
    "y": 26.207382,
    "z": -5.164877,
    "vx": 0.00002964,
    "vy": 0.00000788,
    "vz": 0.00004765,
    "rarad": 1.51968299932221,
    "decrad": -0.194335806443806,
    "pmrarad": -0.00000109184888997916,
    "pmdecrad": 0.000001816063565,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 27343,
    "lum": 0.0252580605483196,
    "var_min": 11.043,
    "var_max": 10.923,
    "ra": 5.804761,
    "dec": -11.134622,
    "pmra": -225.21,
    "pmdec": 374.59,
    "rv": 0,
    "Asset Name": "ASTRID 003402",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Frizzy",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 0.00002964,
    "y_1": 0.00000788,
    "z_1": 0.00004765,
    "Distance in Parsecs_1": 26.7451,
    "HYG Star ID_1": 27343,
    "Hipparcos Catalogue_1": 27397,
    "Gliese Catalog_1": "NN 3369"
  },
  {
    "Astrid #": "ASTRID 003403",
    "HYG Star ID": 36747,
    "Hipparcos Catalogue": 36853,
    "Gliese Catalog": "NN 3453",
    "Distance in Parsecs": 26.7451,
    "mag": 11.53,
    "Absolute Visual Magnitude": 9.394,
    "Color Index": 1.498,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.928797,
    "y": 22.658228,
    "z": 10.164844,
    "vx": -0.00002205,
    "vy": -0.00000454,
    "vz": -0.00001142,
    "rarad": 1.98379214224031,
    "decrad": 0.389865240805196,
    "pmrarad": 8.23456036423611e-7,
    "pmdecrad": -4.61736549e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 36747,
    "lum": 0.0152194865090365,
    "var_min": 11.61,
    "var_max": 11.45,
    "ra": 7.577528,
    "dec": 22.337633,
    "pmra": 169.85,
    "pmdec": -95.24,
    "rv": 0,
    "Asset Name": "ASTRID 003403",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Serious Mad",
    "Hair": "Pompadour",
    "Outfit": "Elven",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00002205,
    "y_1": -0.00000454,
    "z_1": -0.00001142,
    "Distance in Parsecs_1": 26.7451,
    "HYG Star ID_1": 36747,
    "Hipparcos Catalogue_1": 36853,
    "Gliese Catalog_1": "NN 3453"
  },
  {
    "Astrid #": "ASTRID 003404",
    "HYG Star ID": 81138,
    "Hipparcos Catalogue": 81385,
    "Henry Draper Catalogue": 149621,
    "Distance in Parsecs": 26.7451,
    "mag": 9.18,
    "Absolute Visual Magnitude": 7.044,
    "Spectral Type": "K4V",
    "Color Index": 1.363,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.908723,
    "y": -20.949622,
    "z": -14.624151,
    "vx": 0.00001699,
    "vy": -0.00000399,
    "vz": -0.00000347,
    "rarad": 4.35141714795717,
    "decrad": -0.578534394902516,
    "pmrarad": 6.470808194375e-7,
    "pmdecrad": -1.55188859e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 81138,
    "lum": 0.132556186071985,
    "ra": 16.621189,
    "dec": -33.147579,
    "pmra": 133.47,
    "pmdec": -32.01,
    "rv": 0,
    "Asset Name": "ASTRID 003404",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Middle Part",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00001699,
    "y_1": -0.00000399,
    "z_1": -0.00000347,
    "Distance in Parsecs_1": 26.7451,
    "HYG Star ID_1": 81138,
    "Hipparcos Catalogue_1": 81385,
    "Henry Draper Catalogue_1": 149621
  },
  {
    "Astrid #": "ASTRID 003405",
    "HYG Star ID": 112777,
    "Hipparcos Catalogue": 113137,
    "Henry Draper Catalogue": 216437,
    "Harvard Revised Catalogue": 8701,
    "Distance in Parsecs": 26.7451,
    "mag": 6.04,
    "Absolute Visual Magnitude": 3.904,
    "Spectral Type": "G4IV-V",
    "Color Index": 0.66,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.747053,
    "y": -2.563699,
    "z": -25.143894,
    "vx": 0.00000598,
    "vy": -0.00000759,
    "vz": 0.00000612,
    "rarad": 5.99808081882849,
    "decrad": -1.22301693476837,
    "pmrarad": -2.09391028631944e-7,
    "pmdecrad": 3.54883614e-7,
    "bayer": "Rho",
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 112777,
    "lum": 2.39001155148472,
    "bf": "Rho Ind",
    "ra": 22.910981,
    "dec": -70.073709,
    "pmra": -43.19,
    "pmdec": 73.2,
    "rv": -3,
    "Asset Name": "ASTRID 003405",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Tiny Crown",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00000598,
    "y_1": -0.00000759,
    "z_1": 0.00000612,
    "Distance in Parsecs_1": 26.7451,
    "HYG Star ID_1": 112777,
    "Hipparcos Catalogue_1": 113137,
    "Henry Draper Catalogue_1": 216437,
    "Harvard Revised Catalogue_1": 8701
  },
  {
    "Astrid #": "ASTRID 003406",
    "HYG Star ID": 95172,
    "Hipparcos Catalogue": 95467,
    "Henry Draper Catalogue": 181433,
    "Gliese Catalog": "Gl 756.1",
    "Distance in Parsecs": 26.7594,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.263,
    "Spectral Type": "K5V",
    "Color Index": 1.006,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.879135,
    "y": -9.954675,
    "z": -24.534474,
    "vx": -0.00001219,
    "vy": -0.00005149,
    "vz": -0.00002331,
    "rarad": 5.08398190770549,
    "decrad": -1.16009936374908,
    "pmrarad": -0.000001123119372375,
    "pmdecrad": 0.000001144257248,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 95172,
    "lum": 0.272144774645436,
    "ra": 19.41938,
    "dec": -66.468797,
    "pmra": -231.66,
    "pmdec": 236.02,
    "rv": 37.9,
    "Asset Name": "ASTRID 003406",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Braided Bun",
    "Outfit": "Samurai",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00001219,
    "y_1": -0.00005149,
    "z_1": -0.00002331,
    "Distance in Parsecs_1": 26.7594,
    "HYG Star ID_1": 95172,
    "Hipparcos Catalogue_1": 95467,
    "Henry Draper Catalogue_1": 181433,
    "Gliese Catalog_1": "Gl 756.1"
  },
  {
    "Astrid #": "ASTRID 003407",
    "HYG Star ID": 28892,
    "Hipparcos Catalogue": 28964,
    "Henry Draper Catalogue": 42505,
    "Distance in Parsecs": 26.7666,
    "mag": 9.4,
    "Absolute Visual Magnitude": 7.262,
    "Spectral Type": "K5V",
    "Color Index": 1.107,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.360693,
    "y": 12.27841,
    "z": -23.781536,
    "vx": -0.00000901,
    "vy": 0.00001997,
    "vz": 0.00001044,
    "rarad": 1.60016085728726,
    "decrad": -1.09401797232204,
    "pmrarad": 3.14547115944444e-7,
    "pmdecrad": 8.50363195e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 28892,
    "lum": 0.108442619576707,
    "ra": 6.112164,
    "dec": -62.682613,
    "pmra": 64.88,
    "pmdec": 175.4,
    "rv": 0,
    "Asset Name": "ASTRID 003407",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Lion Mane",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": -0.00000901,
    "y_1": 0.00001997,
    "z_1": 0.00001044,
    "Distance in Parsecs_1": 26.7666,
    "HYG Star ID_1": 28892,
    "Hipparcos Catalogue_1": 28964,
    "Henry Draper Catalogue_1": 42505
  },
  {
    "Astrid #": "ASTRID 003408",
    "HYG Star ID": 48926,
    "Hipparcos Catalogue": 49066,
    "Distance in Parsecs": 26.7738,
    "mag": 11.93,
    "Absolute Visual Magnitude": 9.791,
    "Spectral Type": "M:",
    "Color Index": 1.42,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.633218,
    "y": 11.251093,
    "z": 14.310347,
    "vx": 0.00002512,
    "vy": 0.00013644,
    "vz": -0.0000728,
    "rarad": 2.62122228301016,
    "decrad": 0.563905025596796,
    "pmrarad": -0.00000488857026651388,
    "pmdecrad": -0.000003217417509,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 48926,
    "lum": 0.0105584459243007,
    "var_min": 12.047,
    "var_max": 11.837,
    "ra": 10.012332,
    "dec": 32.309378,
    "pmra": -1008.34,
    "pmdec": -663.64,
    "rv": 0,
    "Asset Name": "ASTRID 003408",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Audiophile",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": 0.00002512,
    "y_1": 0.00013644,
    "z_1": -0.0000728,
    "Distance in Parsecs_1": 26.7738,
    "HYG Star ID_1": 48926,
    "Hipparcos Catalogue_1": 49066
  },
  {
    "Astrid #": "ASTRID 003409",
    "HYG Star ID": 67542,
    "Hipparcos Catalogue": 67756,
    "Gliese Catalog": "Gl 533.1",
    "Distance in Parsecs": 26.7738,
    "mag": 11.92,
    "Absolute Visual Magnitude": 9.781,
    "Spectral Type": "M1",
    "Color Index": 1.57,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.738294,
    "y": -5.223494,
    "z": 24.386307,
    "vx": -0.00003043,
    "vy": 0.00006509,
    "vz": -0.00005716,
    "rarad": 3.6339577158349,
    "decrad": 1.14532241613565,
    "pmrarad": -0.00000267990458200694,
    "pmdecrad": -7.46613068e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 67542,
    "lum": 0.0106561420215851,
    "var_min": 12.027,
    "var_max": 11.797,
    "ra": 13.880696,
    "dec": 65.622141,
    "pmra": -552.77,
    "pmdec": -154,
    "rv": -52.5,
    "Asset Name": "ASTRID 003409",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Smoulder",
    "Hair": "Innocent",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00003043,
    "y_1": 0.00006509,
    "z_1": -0.00005716,
    "Distance in Parsecs_1": 26.7738,
    "HYG Star ID_1": 67542,
    "Hipparcos Catalogue_1": 67756,
    "Gliese Catalog_1": "Gl 533.1"
  },
  {
    "Astrid #": "ASTRID 003410",
    "HYG Star ID": 23254,
    "Hipparcos Catalogue": 23309,
    "Distance in Parsecs": 26.7809,
    "mag": 10.02,
    "Absolute Visual Magnitude": 7.881,
    "Color Index": 1.297,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 3.701019,
    "y": 14.004205,
    "z": -22.52557,
    "vx": -0.00000244,
    "vy": 0.00000887,
    "vz": 0.00000511,
    "rarad": 1.31242304689146,
    "decrad": -0.999324458736858,
    "pmrarad": 1.7278759575e-7,
    "pmdecrad": 3.52944359e-7,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 23254,
    "lum": 0.0613196969715682,
    "var_min": 10.119,
    "var_max": 9.919,
    "ra": 5.013087,
    "dec": -57.257074,
    "pmra": 35.64,
    "pmdec": 72.8,
    "rv": 0,
    "Asset Name": "ASTRID 003410",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Pixie",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.00000244,
    "y_1": 0.00000887,
    "z_1": 0.00000511,
    "Distance in Parsecs_1": 26.7809,
    "HYG Star ID_1": 23254,
    "Hipparcos Catalogue_1": 23309
  },
  {
    "Astrid #": "ASTRID 003411",
    "HYG Star ID": 106800,
    "Hipparcos Catalogue": 107143,
    "Henry Draper Catalogue": 206276,
    "Gliese Catalog": "Gl 836.3",
    "Distance in Parsecs": 26.7881,
    "mag": 8.82,
    "Absolute Visual Magnitude": 6.68,
    "Spectral Type": "K5V",
    "Color Index": 1.054,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.6387,
    "y": -11.417357,
    "z": -17.618957,
    "vx": 0.00003362,
    "vy": 0.00001453,
    "vz": -0.00006754,
    "rarad": 5.68178282078815,
    "decrad": -0.717765486683369,
    "pmrarad": 0.00000115734721822222,
    "pmdecrad": -0.000001420649528,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 106800,
    "lum": 0.185353162341481,
    "ra": 21.702812,
    "dec": -41.124933,
    "pmra": 238.72,
    "pmdec": -293.03,
    "rv": 57.8,
    "Asset Name": "ASTRID 003411",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Serious Slumber",
    "Hair": "Manbun",
    "Outfit": "Wizard",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00003362,
    "y_1": 0.00001453,
    "z_1": -0.00006754,
    "Distance in Parsecs_1": 26.7881,
    "HYG Star ID_1": 106800,
    "Hipparcos Catalogue_1": 107143,
    "Henry Draper Catalogue_1": 206276,
    "Gliese Catalog_1": "Gl 836.3"
  },
  {
    "Astrid #": "ASTRID 003412",
    "HYG Star ID": 13567,
    "Hipparcos Catalogue": 13601,
    "Henry Draper Catalogue": 18144,
    "Distance in Parsecs": 26.7953,
    "mag": 7.41,
    "Absolute Visual Magnitude": 5.27,
    "Spectral Type": "G5",
    "Color Index": 0.749,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.554556,
    "y": 17.807186,
    "z": 7.524673,
    "vx": -0.00001708,
    "vy": 0.00002029,
    "vz": -0.00000589,
    "rarad": 0.76484696174311,
    "decrad": 0.284649042022657,
    "pmrarad": 9.878563555e-7,
    "pmdecrad": -2.29025982e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13567,
    "lum": 0.679203632617184,
    "ra": 2.9215,
    "dec": 16.309189,
    "pmra": 203.76,
    "pmdec": -47.24,
    "rv": 0,
    "Asset Name": "ASTRID 003412",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Serious Slumber",
    "Hair": "Elegant",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00001708,
    "y_1": 0.00002029,
    "z_1": -0.00000589,
    "Distance in Parsecs_1": 26.7953,
    "HYG Star ID_1": 13567,
    "Hipparcos Catalogue_1": 13601,
    "Henry Draper Catalogue_1": 18144
  },
  {
    "Astrid #": "ASTRID 003413",
    "HYG Star ID": 80317,
    "Hipparcos Catalogue": 80559,
    "Henry Draper Catalogue": 330856,
    "Distance in Parsecs": 26.7953,
    "mag": 10.69,
    "Absolute Visual Magnitude": 8.55,
    "Spectral Type": "M1",
    "Color Index": 1.44,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -6.865589,
    "y": -15.949456,
    "z": -20.407515,
    "vx": 0.00002129,
    "vy": -0.00000147,
    "vz": -0.00000601,
    "rarad": 4.30589993555817,
    "decrad": -0.865790819146105,
    "pmrarad": 7.51606648965277e-7,
    "pmdecrad": -3.46302412e-7,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 80317,
    "lum": 0.033113112148259,
    "var_min": 10.773,
    "var_max": 10.633,
    "ra": 16.447326,
    "dec": -49.60616,
    "pmra": 155.03,
    "pmdec": -71.43,
    "rv": 0,
    "Asset Name": "ASTRID 003413",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Honest Lad",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": 0.00002129,
    "y_1": -0.00000147,
    "z_1": -0.00000601,
    "Distance in Parsecs_1": 26.7953,
    "HYG Star ID_1": 80317,
    "Hipparcos Catalogue_1": 80559,
    "Henry Draper Catalogue_1": 330856
  },
  {
    "Astrid #": "ASTRID 003414",
    "HYG Star ID": 88810,
    "Hipparcos Catalogue": 89087,
    "Gliese Catalog": "NN 4043",
    "Distance in Parsecs": 26.8025,
    "mag": 10,
    "Absolute Visual Magnitude": 7.859,
    "Spectral Type": "K7",
    "Color Index": 1.27,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.822409,
    "y": -17.219041,
    "z": 20.523213,
    "vx": 3.5e-7,
    "vy": -0.0000227,
    "vz": -0.00001906,
    "rarad": 4.76011447647181,
    "decrad": 0.872160217421808,
    "pmrarad": -2.74889356875e-8,
    "pmdecrad": -0.000001105666079,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 88810,
    "lum": 0.062574876335444,
    "ra": 18.182298,
    "dec": 49.9711,
    "pmra": -5.67,
    "pmdec": -228.06,
    "rv": 0,
    "Asset Name": "ASTRID 003414",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Blep",
    "Hair": "Skater Helmet",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 3.5e-7,
    "y_1": -0.0000227,
    "z_1": -0.00001906,
    "Distance in Parsecs_1": 26.8025,
    "HYG Star ID_1": 88810,
    "Hipparcos Catalogue_1": 89087,
    "Gliese Catalog_1": "NN 4043"
  },
  {
    "Astrid #": "ASTRID 003415",
    "HYG Star ID": 4213,
    "Hipparcos Catalogue": 4223,
    "Distance in Parsecs": 26.8097,
    "mag": 11.11,
    "Absolute Visual Magnitude": 8.969,
    "Spectral Type": "M2",
    "Color Index": 1.435,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.129278,
    "y": 4.343046,
    "z": 19.267258,
    "vx": -0.00001554,
    "vy": 0.00003687,
    "vz": 0.00000631,
    "rarad": 0.235123029317543,
    "decrad": 0.801884012847971,
    "pmrarad": 0.00000147237914784722,
    "pmdecrad": 3.38496911e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 4213,
    "lum": 0.0225112701591287,
    "var_min": 11.18,
    "var_max": 11.05,
    "ra": 0.898104,
    "dec": 45.94457,
    "pmra": 303.7,
    "pmdec": 69.82,
    "rv": 0,
    "Asset Name": "ASTRID 003415",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Round Brush",
    "Outfit": "Warlock",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001554,
    "y_1": 0.00003687,
    "z_1": 0.00000631,
    "Distance in Parsecs_1": 26.8097,
    "HYG Star ID_1": 4213,
    "Hipparcos Catalogue_1": 4223
  },
  {
    "Astrid #": "ASTRID 003416",
    "HYG Star ID": 117966,
    "Gliese Catalog": "Gl 11B",
    "Distance in Parsecs": 26.8097,
    "mag": 13.4,
    "Absolute Visual Magnitude": 11.259,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.448915,
    "y": 0.546364,
    "z": 25.083452,
    "vx": 0.00003263,
    "vy": 0.00009351,
    "vz": -0.00001431,
    "rarad": 0.0574459985674187,
    "decrad": 1.20998593000455,
    "pmrarad": 0.000003412991348375,
    "pmdecrad": -0.000001512419909,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 1066,
    "base": "Gl  11",
    "lum": 0.00273149242208917,
    "ra": 0.219428,
    "dec": 69.327087,
    "pmra": 703.98,
    "pmdec": -311.96,
    "rv": 0,
    "Asset Name": "ASTRID 003416",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Fire",
    "Outfit": "River Spirit",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003263,
    "y_1": 0.00009351,
    "z_1": -0.00001431,
    "Distance in Parsecs_1": 26.8097,
    "HYG Star ID_1": 117966,
    "Gliese Catalog_1": "Gl 11B"
  },
  {
    "Astrid #": "ASTRID 003417",
    "HYG Star ID": 64058,
    "Hipparcos Catalogue": 64262,
    "Distance in Parsecs": 26.8168,
    "mag": 9.31,
    "Absolute Visual Magnitude": 7.168,
    "Spectral Type": "K5",
    "Color Index": 1.022,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.212382,
    "y": -7.983662,
    "z": 4.44271,
    "vx": 0.00000407,
    "vy": -0.00002369,
    "vz": -0.00001949,
    "rarad": 3.44825954871487,
    "decrad": 0.166436282281208,
    "pmrarad": 8.88081700041666e-7,
    "pmdecrad": -7.37110719e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 64058,
    "lum": 0.11824968703831,
    "ra": 13.171381,
    "dec": 9.536097,
    "pmra": 183.18,
    "pmdec": -152.04,
    "rv": 0,
    "Asset Name": "ASTRID 003417",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Hachimaki",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00000407,
    "y_1": -0.00002369,
    "z_1": -0.00001949,
    "Distance in Parsecs_1": 26.8168,
    "HYG Star ID_1": 64058,
    "Hipparcos Catalogue_1": 64262
  },
  {
    "Astrid #": "ASTRID 003418",
    "HYG Star ID": 64270,
    "Hipparcos Catalogue": 64475,
    "Henry Draper Catalogue": 114747,
    "Distance in Parsecs": 26.824,
    "mag": 8.03,
    "Absolute Visual Magnitude": 5.887,
    "Spectral Type": "K1IV/V",
    "Color Index": 0.923,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.904824,
    "y": -7.213069,
    "z": -13.699536,
    "vx": -0.00001795,
    "vy": 0.0000222,
    "vz": 0.00001702,
    "rarad": 3.4597024386556,
    "decrad": -0.536021287221181,
    "pmrarad": -9.95467930284722e-7,
    "pmdecrad": 7.38031865e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 64270,
    "lum": 0.384768933893788,
    "ra": 13.21509,
    "dec": -30.711757,
    "pmra": -205.33,
    "pmdec": 152.23,
    "rv": 0,
    "Asset Name": "ASTRID 003418",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Audiophile",
    "Outfit": "Painter",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00001795,
    "y_1": 0.0000222,
    "z_1": 0.00001702,
    "Distance in Parsecs_1": 26.824,
    "HYG Star ID_1": 64270,
    "Hipparcos Catalogue_1": 64475,
    "Henry Draper Catalogue_1": 114747
  },
  {
    "Astrid #": "ASTRID 003419",
    "HYG Star ID": 89757,
    "Hipparcos Catalogue": 90035,
    "Distance in Parsecs": 26.824,
    "mag": 10.12,
    "Absolute Visual Magnitude": 7.977,
    "Spectral Type": "K7V",
    "Color Index": 1.258,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.603246,
    "y": -26.685418,
    "z": 0.799078,
    "vx": 0.00001099,
    "vy": 0.000001,
    "vz": -0.00000233,
    "rarad": 4.80963441601435,
    "decrad": 0.0297940571153012,
    "pmrarad": 4.11315926583333e-7,
    "pmdecrad": -8.7072537e-8,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 89757,
    "lum": 0.05613064076752,
    "var_min": 10.176,
    "var_max": 10.056,
    "ra": 18.37145,
    "dec": 1.707074,
    "pmra": 84.84,
    "pmdec": -17.96,
    "rv": 0,
    "Asset Name": "ASTRID 003419",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oh No",
    "Hair": "Middle Part",
    "Outfit": "Rider",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00001099,
    "y_1": 0.000001,
    "z_1": -0.00000233,
    "Distance in Parsecs_1": 26.824,
    "HYG Star ID_1": 89757,
    "Hipparcos Catalogue_1": 90035
  },
  {
    "Astrid #": "ASTRID 003420",
    "HYG Star ID": 118939,
    "Gliese Catalog": "Gl 536.1B",
    "Distance in Parsecs": 26.824,
    "mag": 11.45,
    "Absolute Visual Magnitude": 9.307,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -22.273982,
    "y": -13.117495,
    "z": 7.165505,
    "vx": 0.00000216,
    "vy": -0.00001661,
    "vz": -7.1e-7,
    "rarad": 3.6738209240587,
    "decrad": 0.270410784538799,
    "pmrarad": 5.74601174194444e-7,
    "pmdecrad": -9.1009224e-8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 68333,
    "base": "Gl 536.1",
    "lum": 0.0164892157315083,
    "ra": 14.032962,
    "dec": 15.493397,
    "pmra": 118.52,
    "pmdec": -18.77,
    "rv": 6,
    "Asset Name": "ASTRID 003420",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Fedora",
    "Outfit": "Spartan",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000216,
    "y_1": -0.00001661,
    "z_1": -7.1e-7,
    "Distance in Parsecs_1": 26.824,
    "HYG Star ID_1": 118939,
    "Gliese Catalog_1": "Gl 536.1B"
  },
  {
    "Astrid #": "ASTRID 003421",
    "HYG Star ID": 68415,
    "Hipparcos Catalogue": 68634,
    "Henry Draper Catalogue": 122676,
    "Gliese Catalog": "NN 3824",
    "Distance in Parsecs": 26.8312,
    "mag": 7.12,
    "Absolute Visual Magnitude": 4.977,
    "Spectral Type": "G5",
    "Color Index": 0.74,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -22.27878,
    "y": -13.247604,
    "z": 6.933272,
    "vx": -0.00000377,
    "vy": 0.00000656,
    "vz": 4.1e-7,
    "rarad": 3.67805346935896,
    "decrad": 0.261369045020458,
    "pmrarad": -2.82016117979166e-7,
    "pmdecrad": 1.5659481e-8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 68415,
    "lum": 0.889610704409228,
    "ra": 14.049129,
    "dec": 14.975343,
    "pmra": -58.17,
    "pmdec": 3.23,
    "rv": 0,
    "Asset Name": "ASTRID 003421",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Worried Vamp",
    "Hair": "Spiky",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000377,
    "y_1": 0.00000656,
    "z_1": 4.1e-7,
    "Distance in Parsecs_1": 26.8312,
    "HYG Star ID_1": 68415,
    "Hipparcos Catalogue_1": 68634,
    "Henry Draper Catalogue_1": 122676,
    "Gliese Catalog_1": "NN 3824"
  },
  {
    "Astrid #": "ASTRID 003422",
    "HYG Star ID": 51837,
    "Hipparcos Catalogue": 51986,
    "Henry Draper Catalogue": 92139,
    "Harvard Revised Catalogue": 4167,
    "Distance in Parsecs": 26.8384,
    "mag": 3.84,
    "Absolute Visual Magnitude": 1.696,
    "Spectral Type": "A3m+...",
    "Color Index": 0.3,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -16.728402,
    "y": 6.3126,
    "z": -20.015507,
    "vx": -0.00000593,
    "vy": 0.00002052,
    "vz": -0.00001463,
    "rarad": 2.78075891946101,
    "decrad": -0.84169585402549,
    "pmrarad": -6.37433027194444e-7,
    "pmdecrad": -7.660056e-9,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 51837,
    "lum": 18.2641724877435,
    "ra": 10.621717,
    "dec": -48.22562,
    "pmra": -131.48,
    "pmdec": -1.58,
    "rv": 19,
    "Asset Name": "ASTRID 003422",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Serious Mad",
    "Hair": "Audiohead",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.00000593,
    "y_1": 0.00002052,
    "z_1": -0.00001463,
    "Distance in Parsecs_1": 26.8384,
    "HYG Star ID_1": 51837,
    "Hipparcos Catalogue_1": 51986,
    "Henry Draper Catalogue_1": 92139,
    "Harvard Revised Catalogue_1": 4167
  },
  {
    "Astrid #": "ASTRID 003423",
    "HYG Star ID": 10297,
    "Hipparcos Catalogue": 10321,
    "Henry Draper Catalogue": 13507,
    "Distance in Parsecs": 26.8456,
    "mag": 7.19,
    "Absolute Visual Magnitude": 5.046,
    "Spectral Type": "G0",
    "Color Index": 0.672,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.032715,
    "y": 11.158294,
    "z": 17.494724,
    "vx": 0.00000349,
    "vy": 0.00001151,
    "vz": -0.00001074,
    "rarad": 0.579957980152773,
    "decrad": 0.709796429422191,
    "pmrarad": 2.87446031201388e-7,
    "pmdecrad": -5.27380321e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 10297,
    "lum": 0.834833753723649,
    "var": "V450",
    "var_min": 7.211,
    "var_max": 7.171,
    "ra": 2.215276,
    "dec": 40.66834,
    "pmra": 59.29,
    "pmdec": -108.78,
    "rv": 0,
    "Asset Name": "ASTRID 003423",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Fire",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000349,
    "y_1": 0.00001151,
    "z_1": -0.00001074,
    "Distance in Parsecs_1": 26.8456,
    "HYG Star ID_1": 10297,
    "Hipparcos Catalogue_1": 10321,
    "Henry Draper Catalogue_1": 13507
  },
  {
    "Astrid #": "ASTRID 003424",
    "HYG Star ID": 10288,
    "Hipparcos Catalogue": 10312,
    "Gliese Catalog": "Gl 88",
    "Distance in Parsecs": 26.8528,
    "mag": 11.1,
    "Absolute Visual Magnitude": 8.955,
    "Color Index": 1.455,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 21.404556,
    "y": 14.013446,
    "z": -8.158306,
    "vx": -9e-8,
    "vy": 0.00007687,
    "vz": 0.00001432,
    "rarad": 0.57966730003982,
    "decrad": -0.3086915448907,
    "pmrarad": 0.00000239691883666666,
    "pmdecrad": 9.8378392e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10288,
    "lum": 0.0228034207200041,
    "var_min": 11.171,
    "var_max": 11.031,
    "ra": 2.214166,
    "dec": -17.686723,
    "pmra": 494.4,
    "pmdec": 202.92,
    "rv": 34.9,
    "Asset Name": "ASTRID 003424",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Elegant",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -9e-8,
    "y_1": 0.00007687,
    "z_1": 0.00001432,
    "Distance in Parsecs_1": 26.8528,
    "HYG Star ID_1": 10288,
    "Hipparcos Catalogue_1": 10312,
    "Gliese Catalog_1": "Gl 88"
  },
  {
    "Astrid #": "ASTRID 003425",
    "HYG Star ID": 100935,
    "Hipparcos Catalogue": 101262,
    "Gliese Catalog": "Gl 791.3",
    "Distance in Parsecs": 26.8528,
    "mag": 9.22,
    "Absolute Visual Magnitude": 7.075,
    "Spectral Type": "K5",
    "Color Index": 1.141,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.706029,
    "y": -17.616549,
    "z": 14.928562,
    "vx": -1.3e-7,
    "vy": 0.00003029,
    "vz": -0.00001344,
    "rarad": 5.37358188390052,
    "decrad": 0.589500150464506,
    "pmrarad": 6.89017202805555e-7,
    "pmdecrad": 8.0624515e-8,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 100935,
    "lum": 0.128824955169313,
    "ra": 20.525571,
    "dec": 33.775871,
    "pmra": 142.12,
    "pmdec": 16.63,
    "rv": -26.8,
    "Asset Name": "ASTRID 003425",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Determined Shinobi",
    "Hair": "Bucket Hat",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -1.3e-7,
    "y_1": 0.00003029,
    "z_1": -0.00001344,
    "Distance in Parsecs_1": 26.8528,
    "HYG Star ID_1": 100935,
    "Hipparcos Catalogue_1": 101262,
    "Gliese Catalog_1": "Gl 791.3"
  },
  {
    "Astrid #": "ASTRID 003426",
    "HYG Star ID": 9066,
    "Hipparcos Catalogue": 9085,
    "Henry Draper Catalogue": 12042,
    "Harvard Revised Catalogue": 573,
    "Gliese Catalog": "Gl 81.3",
    "Distance in Parsecs": 26.8673,
    "mag": 6.1,
    "Absolute Visual Magnitude": 3.954,
    "Spectral Type": "F8V",
    "Color Index": 0.487,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.507368,
    "y": 8.124495,
    "z": -21.104087,
    "vx": 0.00000216,
    "vy": 0.00005328,
    "vz": 0.00001705,
    "rarad": 0.510498592786166,
    "decrad": -0.903491397850311,
    "pmrarad": 0.00000169117556188194,
    "pmdecrad": 0.000001208931393,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 9066,
    "lum": 2.2824433121735,
    "ra": 1.949961,
    "dec": -51.766244,
    "pmra": 348.83,
    "pmdec": 249.36,
    "rv": 3.8,
    "Asset Name": "ASTRID 003426",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Oh Snap",
    "Hair": "Greasy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000216,
    "y_1": 0.00005328,
    "z_1": 0.00001705,
    "Distance in Parsecs_1": 26.8673,
    "HYG Star ID_1": 9066,
    "Hipparcos Catalogue_1": 9085,
    "Henry Draper Catalogue_1": 12042,
    "Harvard Revised Catalogue_1": 573,
    "Gliese Catalog_1": "Gl 81.3"
  },
  {
    "Astrid #": "ASTRID 003427",
    "HYG Star ID": 34703,
    "Hipparcos Catalogue": 34796,
    "Gliese Catalog": "NN 3432",
    "Distance in Parsecs": 26.8745,
    "mag": 11.25,
    "Absolute Visual Magnitude": 9.103,
    "Color Index": 1.47,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -5.094508,
    "y": 15.635272,
    "z": 21.256129,
    "vx": 0.00000903,
    "vy": 0.00003124,
    "vz": -0.00002082,
    "rarad": 1.88578639539659,
    "decrad": 0.912344382128606,
    "pmrarad": -6.79611817402777e-7,
    "pmdecrad": -0.000001266042445,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 34703,
    "lum": 0.0198975681109065,
    "var_min": 11.313,
    "var_max": 11.163,
    "ra": 7.203173,
    "dec": 52.273483,
    "pmra": -140.18,
    "pmdec": -261.14,
    "rv": 0,
    "Asset Name": "ASTRID 003427",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "Side Part",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000903,
    "y_1": 0.00003124,
    "z_1": -0.00002082,
    "Distance in Parsecs_1": 26.8745,
    "HYG Star ID_1": 34703,
    "Hipparcos Catalogue_1": 34796,
    "Gliese Catalog_1": "NN 3432"
  },
  {
    "Astrid #": "ASTRID 003428",
    "HYG Star ID": 67770,
    "Hipparcos Catalogue": 67986,
    "Henry Draper Catalogue": 120744,
    "Distance in Parsecs": 26.8817,
    "mag": 8.33,
    "Absolute Visual Magnitude": 6.183,
    "Spectral Type": "K2V",
    "Color Index": 0.913,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.588812,
    "y": -3.077146,
    "z": -26.113639,
    "vx": -0.00000642,
    "vy": 0.00002215,
    "vz": -0.00000124,
    "rarad": 3.64491251182875,
    "decrad": -1.33117580384788,
    "pmrarad": -8.36933856743055e-7,
    "pmdecrad": -1.93925472e-7,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 67770,
    "lum": 0.292954382976943,
    "ra": 13.92254,
    "dec": -76.270755,
    "pmra": -172.63,
    "pmdec": -40,
    "rv": 0,
    "Asset Name": "ASTRID 003428",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Einstein Tongue",
    "Hair": "Unkempt",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": -0.00000642,
    "y_1": 0.00002215,
    "z_1": -0.00000124,
    "Distance in Parsecs_1": 26.8817,
    "HYG Star ID_1": 67770,
    "Hipparcos Catalogue_1": 67986,
    "Henry Draper Catalogue_1": 120744
  },
  {
    "Astrid #": "ASTRID 003429",
    "HYG Star ID": 73318,
    "Hipparcos Catalogue": 73547,
    "Henry Draper Catalogue": 132648,
    "Distance in Parsecs": 26.8817,
    "mag": 7.74,
    "Absolute Visual Magnitude": 5.593,
    "Spectral Type": "G6V",
    "Color Index": 0.721,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.854566,
    "y": -13.068095,
    "z": -19.662422,
    "vx": 0.00000254,
    "vy": 0.00000728,
    "vz": -0.0000065,
    "rarad": 3.93522832228898,
    "decrad": -0.820435168262134,
    "pmrarad": -1.22366972972222e-7,
    "pmdecrad": -3.54544244e-7,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 73318,
    "lum": 0.504428945952413,
    "ra": 15.031465,
    "dec": -47.007473,
    "pmra": -25.24,
    "pmdec": -73.13,
    "rv": 0,
    "Asset Name": "ASTRID 003429",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Audiophile",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": 0.00000254,
    "y_1": 0.00000728,
    "z_1": -0.0000065,
    "Distance in Parsecs_1": 26.8817,
    "HYG Star ID_1": 73318,
    "Hipparcos Catalogue_1": 73547,
    "Henry Draper Catalogue_1": 132648
  },
  {
    "Astrid #": "ASTRID 003430",
    "HYG Star ID": 101214,
    "Hipparcos Catalogue": 101540,
    "Gliese Catalog": "NN 4149",
    "Distance in Parsecs": 26.8817,
    "mag": 11.96,
    "Absolute Visual Magnitude": 9.813,
    "Spectral Type": "M:",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 16.771356,
    "y": -20.949541,
    "z": 1.569761,
    "vx": 0.00003392,
    "vy": 0.00002295,
    "vz": -0.00005612,
    "rarad": 5.38747100471261,
    "decrad": 0.058428384647175,
    "pmrarad": 0.00000151882429844444,
    "pmdecrad": -0.00000209158318,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 101214,
    "lum": 0.0103466557584243,
    "var_min": 12.037,
    "var_max": 11.847,
    "ra": 20.578623,
    "dec": 3.3477,
    "pmra": 313.28,
    "pmdec": -431.42,
    "rv": 0,
    "Asset Name": "ASTRID 003430",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Bring It",
    "Hair": "Horns",
    "Outfit": "Wizard",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00003392,
    "y_1": 0.00002295,
    "z_1": -0.00005612,
    "Distance in Parsecs_1": 26.8817,
    "HYG Star ID_1": 101214,
    "Hipparcos Catalogue_1": 101540,
    "Gliese Catalog_1": "NN 4149"
  },
  {
    "Astrid #": "ASTRID 003431",
    "HYG Star ID": 28719,
    "Hipparcos Catalogue": 28790,
    "Henry Draper Catalogue": 41742,
    "Harvard Revised Catalogue": 2158,
    "Distance in Parsecs": 26.8962,
    "mag": 5.93,
    "Absolute Visual Magnitude": 3.782,
    "Spectral Type": "F4V",
    "Color Index": 0.493,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.38684,
    "y": 18.988498,
    "z": -19.044838,
    "vx": 0.00000955,
    "vy": 0.00004318,
    "vz": 0.00000386,
    "rarad": 1.59116725215266,
    "decrad": -0.786775779138022,
    "pmrarad": -3.87753981708333e-7,
    "pmdecrad": 0.000001232784226,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 28719,
    "lum": 2.67423765708899,
    "ra": 6.077811,
    "dec": -45.078932,
    "pmra": -79.98,
    "pmdec": 254.28,
    "rv": 27,
    "Asset Name": "ASTRID 003431",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Neko Ninja",
    "Hair": "Heartbreaker",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000955,
    "y_1": 0.00004318,
    "z_1": 0.00000386,
    "Distance in Parsecs_1": 26.8962,
    "HYG Star ID_1": 28719,
    "Hipparcos Catalogue_1": 28790,
    "Henry Draper Catalogue_1": 41742,
    "Harvard Revised Catalogue_1": 2158
  },
  {
    "Astrid #": "ASTRID 003432",
    "HYG Star ID": 119080,
    "Gliese Catalog": "Gl 624.1B",
    "Distance in Parsecs": 26.8962,
    "mag": 8.8,
    "Absolute Visual Magnitude": 6.652,
    "Spectral Type": "K2",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.218863,
    "y": -11.718524,
    "z": 23.639907,
    "vx": -1.1e-7,
    "vy": 0.00000935,
    "vz": 0.00000461,
    "rarad": 4.29341531706699,
    "decrad": 1.07361651728495,
    "pmrarad": -1.452986600625e-7,
    "pmdecrad": 3.59605699e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 80089,
    "base": "Gl 624.1",
    "lum": 0.19019539604423,
    "ra": 16.399638,
    "dec": 61.513695,
    "pmra": -29.97,
    "pmdec": 74.17,
    "rv": 0,
    "Asset Name": "ASTRID 003432",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Knocked Out",
    "Hair": "Braided Bun",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -1.1e-7,
    "y_1": 0.00000935,
    "z_1": 0.00000461,
    "Distance in Parsecs_1": 26.8962,
    "HYG Star ID_1": 119080,
    "Gliese Catalog_1": "Gl 624.1B"
  },
  {
    "Astrid #": "ASTRID 003433",
    "HYG Star ID": 72938,
    "Hipparcos Catalogue": 73165,
    "Henry Draper Catalogue": 132052,
    "Harvard Revised Catalogue": 5570,
    "Gliese Catalog": "Wo 9503",
    "Distance in Parsecs": 26.9034,
    "mag": 4.47,
    "Absolute Visual Magnitude": 2.321,
    "Spectral Type": "F0V",
    "Color Index": 0.318,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -19.200694,
    "y": -18.734445,
    "z": -2.038952,
    "vx": -0.00002366,
    "vy": -0.00000526,
    "vz": -0.00002178,
    "rarad": 3.91470076493429,
    "decrad": -0.07586007262021,
    "pmrarad": -4.742932236875e-7,
    "pmdecrad": -7.49328024e-7,
    "flam": 16,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 72938,
    "lum": 10.2706989602628,
    "bf": "16    Lib",
    "ra": 14.953055,
    "dec": -4.346462,
    "pmra": -97.83,
    "pmdec": -154.56,
    "rv": 21.7,
    "Asset Name": "ASTRID 003433",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Owie",
    "Hair": "Windy",
    "Outfit": "Warlock",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00002366,
    "y_1": -0.00000526,
    "z_1": -0.00002178,
    "Distance in Parsecs_1": 26.9034,
    "HYG Star ID_1": 72938,
    "Hipparcos Catalogue_1": 73165,
    "Henry Draper Catalogue_1": 132052,
    "Harvard Revised Catalogue_1": 5570,
    "Gliese Catalog_1": "Wo 9503"
  },
  {
    "Astrid #": "ASTRID 003434",
    "HYG Star ID": 84767,
    "Hipparcos Catalogue": 85026,
    "Gliese Catalog": "GJ 1217",
    "Distance in Parsecs": 26.9034,
    "mag": 10.89,
    "Absolute Visual Magnitude": 8.741,
    "Spectral Type": "K5V",
    "Color Index": 1.363,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.209616,
    "y": -25.647847,
    "z": -6.944999,
    "vx": -0.0000033,
    "vy": 0.00006429,
    "vz": -0.00001357,
    "rarad": 4.54970825998591,
    "decrad": -0.261107403193542,
    "pmrarad": -5.08230181326388e-7,
    "pmdecrad": -0.000001090879262,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 84767,
    "lum": 0.0277715423588466,
    "var_min": 10.945,
    "var_max": 10.815,
    "ra": 17.378605,
    "dec": -14.960352,
    "pmra": -104.83,
    "pmdec": -225.01,
    "rv": -56,
    "Asset Name": "ASTRID 003434",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Braided Ponytail",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.0000033,
    "y_1": 0.00006429,
    "z_1": -0.00001357,
    "Distance in Parsecs_1": 26.9034,
    "HYG Star ID_1": 84767,
    "Hipparcos Catalogue_1": 85026,
    "Gliese Catalog_1": "GJ 1217"
  },
  {
    "Astrid #": "ASTRID 003435",
    "HYG Star ID": 106011,
    "Hipparcos Catalogue": 106353,
    "Henry Draper Catalogue": 204941,
    "Distance in Parsecs": 26.9034,
    "mag": 8.45,
    "Absolute Visual Magnitude": 6.301,
    "Spectral Type": "K1/K2V",
    "Color Index": 0.878,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.090724,
    "y": -15.085661,
    "z": -9.622777,
    "vx": 0.00000409,
    "vy": -0.00004849,
    "vz": -0.00002982,
    "rarad": 5.63912401327241,
    "decrad": -0.36577602207484,
    "pmrarad": -0.00000135025458171527,
    "pmdecrad": -6.04465696e-7,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 106011,
    "lum": 0.262784654083849,
    "ra": 21.539867,
    "dec": -20.957422,
    "pmra": -278.51,
    "pmdec": -124.68,
    "rv": 40,
    "Asset Name": "ASTRID 003435",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Wavy",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000409,
    "y_1": -0.00004849,
    "z_1": -0.00002982,
    "Distance in Parsecs_1": 26.9034,
    "HYG Star ID_1": 106011,
    "Hipparcos Catalogue_1": 106353,
    "Henry Draper Catalogue_1": 204941
  },
  {
    "Astrid #": "ASTRID 003436",
    "HYG Star ID": 66781,
    "Hipparcos Catalogue": 66993,
    "Distance in Parsecs": 26.9107,
    "mag": 11.8,
    "Absolute Visual Magnitude": 9.65,
    "Color Index": 1.44,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.723825,
    "y": -9.596468,
    "z": -15.59052,
    "vx": -0.00002316,
    "vy": -0.00001824,
    "vz": 0.00004052,
    "rarad": 3.5944154639143,
    "decrad": -0.617922152281809,
    "pmrarad": 2.32904492138888e-7,
    "pmdecrad": 0.000001847624936,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 66781,
    "lum": 0.0120226443461741,
    "var_min": 11.919,
    "var_max": 11.709,
    "ra": 13.729656,
    "dec": -35.404331,
    "pmra": 48.04,
    "pmdec": 381.1,
    "rv": 0,
    "Asset Name": "ASTRID 003436",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Determined Neko",
    "Hair": "Braid",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00002316,
    "y_1": -0.00001824,
    "z_1": 0.00004052,
    "Distance in Parsecs_1": 26.9107,
    "HYG Star ID_1": 66781,
    "Hipparcos Catalogue_1": 66993
  },
  {
    "Astrid #": "ASTRID 003437",
    "HYG Star ID": 31580,
    "Hipparcos Catalogue": 31660,
    "Henry Draper Catalogue": 47127,
    "Distance in Parsecs": 26.9179,
    "mag": 6.84,
    "Absolute Visual Magnitude": 4.69,
    "Spectral Type": "G5",
    "Color Index": 0.725,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.293529,
    "y": 25.959712,
    "z": 5.679591,
    "vx": -1.8e-7,
    "vy": 0.00006152,
    "vz": -0.0000249,
    "rarad": 1.73470461523013,
    "decrad": 0.212590810533631,
    "pmrarad": -3.66373698395833e-7,
    "pmdecrad": -0.000001380167585,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 31580,
    "lum": 1.15877735615512,
    "ra": 6.626084,
    "dec": 12.180556,
    "pmra": -75.57,
    "pmdec": -284.68,
    "rv": 52.9,
    "Asset Name": "ASTRID 003437",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Mortified",
    "Hair": "Good Boy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -1.8e-7,
    "y_1": 0.00006152,
    "z_1": -0.0000249,
    "Distance in Parsecs_1": 26.9179,
    "HYG Star ID_1": 31580,
    "Hipparcos Catalogue_1": 31660,
    "Henry Draper Catalogue_1": 47127
  },
  {
    "Astrid #": "ASTRID 003438",
    "HYG Star ID": 48987,
    "Hipparcos Catalogue": 49127,
    "Henry Draper Catalogue": 86972,
    "Gliese Catalog": "NN 3581",
    "Distance in Parsecs": 26.9324,
    "mag": 8.65,
    "Absolute Visual Magnitude": 6.499,
    "Spectral Type": "K3V",
    "Color Index": 1.016,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.5753,
    "y": 12.821746,
    "z": -7.163296,
    "vx": 0.00001541,
    "vy": 0.00002916,
    "vz": 0.00000363,
    "rarad": 2.62506969522353,
    "decrad": -0.269213323572681,
    "pmrarad": -0.00000122420302477083,
    "pmdecrad": 1.39820265e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 48987,
    "lum": 0.21897775550985699,
    "ra": 10.027028,
    "dec": -15.424787,
    "pmra": -252.51,
    "pmdec": 28.84,
    "rv": 0,
    "Asset Name": "ASTRID 003438",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Gimme Blood",
    "Hair": "Undercut",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001541,
    "y_1": 0.00002916,
    "z_1": 0.00000363,
    "Distance in Parsecs_1": 26.9324,
    "HYG Star ID_1": 48987,
    "Hipparcos Catalogue_1": 49127,
    "Henry Draper Catalogue_1": 86972,
    "Gliese Catalog_1": "NN 3581"
  },
  {
    "Astrid #": "ASTRID 003439",
    "HYG Star ID": 58118,
    "Hipparcos Catalogue": 58293,
    "Henry Draper Catalogue": 103836,
    "Distance in Parsecs": 26.9324,
    "mag": 8.93,
    "Absolute Visual Magnitude": 6.779,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.024,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.175758,
    "y": 0.287832,
    "z": -11.866089,
    "vx": -0.00000785,
    "vy": 0.00004583,
    "vz": 0.00001711,
    "rarad": 3.12968910143046,
    "decrad": -0.456253437505236,
    "pmrarad": -0.00000169835080435416,
    "pmdecrad": 7.07827973e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 58118,
    "lum": 0.1691998602503,
    "ra": 11.954532,
    "dec": -26.141396,
    "pmra": -350.31,
    "pmdec": 146,
    "rv": 0,
    "Asset Name": "ASTRID 003439",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Fire",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000785,
    "y_1": 0.00004583,
    "z_1": 0.00001711,
    "Distance in Parsecs_1": 26.9324,
    "HYG Star ID_1": 58118,
    "Hipparcos Catalogue_1": 58293,
    "Henry Draper Catalogue_1": 103836
  },
  {
    "Astrid #": "ASTRID 003440",
    "HYG Star ID": 104395,
    "Hipparcos Catalogue": 104733,
    "Henry Draper Catalogue": 202108,
    "Distance in Parsecs": 26.9324,
    "mag": 7.32,
    "Absolute Visual Magnitude": 5.169,
    "Spectral Type": "G0",
    "Color Index": 0.666,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.254821,
    "y": -15.405844,
    "z": 13.794381,
    "vx": -0.00000758,
    "vy": 0.00000345,
    "vz": 0.00001194,
    "rarad": 5.55433864811831,
    "decrad": 0.537727453574798,
    "pmrarad": -9.18721924652777e-8,
    "pmdecrad": 5.32179977e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 104395,
    "lum": 0.74541821337899,
    "ra": 21.216011,
    "dec": 30.809514,
    "pmra": -18.95,
    "pmdec": 109.77,
    "rv": -0.7,
    "Asset Name": "ASTRID 003440",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Fire",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000758,
    "y_1": 0.00000345,
    "z_1": 0.00001194,
    "Distance in Parsecs_1": 26.9324,
    "HYG Star ID_1": 104395,
    "Hipparcos Catalogue_1": 104733,
    "Henry Draper Catalogue_1": 202108
  },
  {
    "Astrid #": "ASTRID 003441",
    "HYG Star ID": 82369,
    "Hipparcos Catalogue": 82621,
    "Henry Draper Catalogue": 152311,
    "Harvard Revised Catalogue": 6269,
    "Distance in Parsecs": 26.9397,
    "mag": 5.86,
    "Absolute Visual Magnitude": 3.708,
    "Spectral Type": "G5IV",
    "Color Index": 0.685,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.231854,
    "y": -24.189495,
    "z": -9.397228,
    "vx": -0.00000224,
    "vy": 0.00001897,
    "vz": 0.00000273,
    "rarad": 4.42188060144951,
    "decrad": -0.356318650401828,
    "pmrarad": -2.814343415625e-7,
    "pmdecrad": -1.32160209e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82369,
    "lum": 2.86285927087212,
    "ra": 16.89034,
    "dec": -20.415555,
    "pmra": -58.05,
    "pmdec": -27.26,
    "rv": -17,
    "Asset Name": "ASTRID 003441",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Chubby Cheeks",
    "Hair": "Manbun",
    "Outfit": "Basketball",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000224,
    "y_1": 0.00001897,
    "z_1": 0.00000273,
    "Distance in Parsecs_1": 26.9397,
    "HYG Star ID_1": 82369,
    "Hipparcos Catalogue_1": 82621,
    "Henry Draper Catalogue_1": 152311,
    "Harvard Revised Catalogue_1": 6269
  },
  {
    "Astrid #": "ASTRID 003442",
    "HYG Star ID": 106727,
    "Hipparcos Catalogue": 107070,
    "Henry Draper Catalogue": 206374,
    "Distance in Parsecs": 26.9397,
    "mag": 7.45,
    "Absolute Visual Magnitude": 5.298,
    "Spectral Type": "G8V",
    "Color Index": 0.686,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.7718,
    "y": -13.702992,
    "z": 12.125625,
    "vx": -0.00000213,
    "vy": 0.00005597,
    "vz": -0.00003029,
    "rarad": 5.67713226577373,
    "decrad": 0.466887196732105,
    "pmrarad": 0.00000166271699883333,
    "pmdecrad": -4.41859188e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 106727,
    "lum": 0.66191161194112,
    "ra": 21.685048,
    "dec": 26.750666,
    "pmra": 342.96,
    "pmdec": -91.14,
    "rv": -42.7,
    "Asset Name": "ASTRID 003442",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Angelic",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000213,
    "y_1": 0.00005597,
    "z_1": -0.00003029,
    "Distance in Parsecs_1": 26.9397,
    "HYG Star ID_1": 106727,
    "Hipparcos Catalogue_1": 107070,
    "Henry Draper Catalogue_1": 206374
  },
  {
    "Astrid #": "ASTRID 003443",
    "HYG Star ID": 37387,
    "Hipparcos Catalogue": 37494,
    "Gliese Catalog": "NN 3460",
    "Distance in Parsecs": 26.9614,
    "mag": 9.73,
    "Absolute Visual Magnitude": 7.576,
    "Spectral Type": "K4",
    "Color Index": 1.19,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.563796,
    "y": 15.902059,
    "z": 20.416519,
    "vx": 0.00000538,
    "vy": 0.00000776,
    "vz": 0.00000621,
    "rarad": 2.01477534188779,
    "decrad": 0.859089303200541,
    "pmrarad": -3.03784252236111e-7,
    "pmdecrad": 1.8422919e-8,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 37387,
    "lum": 0.0812082216249983,
    "ra": 7.695875,
    "dec": 49.222191,
    "pmra": -62.66,
    "pmdec": 3.8,
    "rv": 7.6,
    "Asset Name": "ASTRID 003443",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Uwu",
    "Hair": "Fire",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000538,
    "y_1": 0.00000776,
    "z_1": 0.00000621,
    "Distance in Parsecs_1": 26.9614,
    "HYG Star ID_1": 37387,
    "Hipparcos Catalogue_1": 37494,
    "Gliese Catalog_1": "NN 3460"
  },
  {
    "Astrid #": "ASTRID 003444",
    "HYG Star ID": 80297,
    "Hipparcos Catalogue": 80539,
    "Distance in Parsecs": 26.9614,
    "mag": 10.53,
    "Absolute Visual Magnitude": 8.376,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -10.293518,
    "y": -23.831991,
    "z": 7.27989,
    "vx": 0.00000471,
    "vy": 0.00000814,
    "vz": 0.00003332,
    "rarad": 4.30467149591384,
    "decrad": 0.273405019587698,
    "pmrarad": 4.06758677986111e-8,
    "pmdecrad": 0.000001283495737,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 80297,
    "lum": 0.0388686986137302,
    "var_min": 10.575,
    "var_max": 10.465,
    "ra": 16.442634,
    "dec": 15.664954,
    "pmra": 8.39,
    "pmdec": 264.74,
    "rv": 0,
    "Asset Name": "ASTRID 003444",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Middle Part",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000471,
    "y_1": 0.00000814,
    "z_1": 0.00003332,
    "Distance in Parsecs_1": 26.9614,
    "HYG Star ID_1": 80297,
    "Hipparcos Catalogue_1": 80539
  },
  {
    "Astrid #": "ASTRID 003445",
    "HYG Star ID": 61288,
    "Hipparcos Catalogue": 61481,
    "Henry Draper Catalogue": 109647,
    "Distance in Parsecs": 26.9687,
    "mag": 8.52,
    "Absolute Visual Magnitude": 6.366,
    "Spectral Type": "K0",
    "Color Index": 0.95,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.684532,
    "y": -2.631736,
    "z": 21.024061,
    "vx": 0.00000149,
    "vy": -0.00001472,
    "vz": -6.6e-7,
    "rarad": 3.29803588659526,
    "decrad": 0.893983220085157,
    "pmrarad": 5.47839459027777e-7,
    "pmdecrad": -3.9075982e-8,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 61288,
    "lum": 0.247514131807431,
    "var": "DO",
    "var_min": 8.559,
    "var_max": 8.479,
    "ra": 12.597569,
    "dec": 51.221465,
    "pmra": 113,
    "pmdec": -8.06,
    "rv": 0,
    "Asset Name": "ASTRID 003445",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Unkempt",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": 0.00000149,
    "y_1": -0.00001472,
    "z_1": -6.6e-7,
    "Distance in Parsecs_1": 26.9687,
    "HYG Star ID_1": 61288,
    "Hipparcos Catalogue_1": 61481,
    "Henry Draper Catalogue_1": 109647
  },
  {
    "Astrid #": "ASTRID 003446",
    "HYG Star ID": 18054,
    "Hipparcos Catalogue": 18097,
    "Henry Draper Catalogue": 24206,
    "Distance in Parsecs": 26.976,
    "mag": 7.57,
    "Absolute Visual Magnitude": 5.415,
    "Spectral Type": "G0",
    "Color Index": 0.684,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.181945,
    "y": 21.114644,
    "z": 10.397903,
    "vx": -0.00001006,
    "vy": 0.000035,
    "vz": -0.00003815,
    "rarad": 1.01269731818778,
    "decrad": 0.395694558492873,
    "pmrarad": 0.00000100356431875,
    "pmdecrad": -0.000001652972243,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 18054,
    "lum": 0.594292158615572,
    "ra": 3.868219,
    "dec": 22.671628,
    "pmra": 207,
    "pmdec": -340.95,
    "rv": 7.6,
    "Asset Name": "ASTRID 003446",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Braided Bun",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00001006,
    "y_1": 0.000035,
    "z_1": -0.00003815,
    "Distance in Parsecs_1": 26.976,
    "HYG Star ID_1": 18054,
    "Hipparcos Catalogue_1": 18097,
    "Henry Draper Catalogue_1": 24206
  },
  {
    "Astrid #": "ASTRID 003447",
    "HYG Star ID": 10481,
    "Hipparcos Catalogue": 10505,
    "Henry Draper Catalogue": 13825,
    "Distance in Parsecs": 26.9833,
    "mag": 6.8,
    "Absolute Visual Magnitude": 4.645,
    "Spectral Type": "G8IV",
    "Color Index": 0.69,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.428374,
    "y": 13.702325,
    "z": 11.091688,
    "vx": -0.00002619,
    "vy": 0.000055,
    "vz": -0.00002368,
    "rarad": 0.590822102869692,
    "decrad": 0.42361402210267,
    "pmrarad": 0.00000223358510768749,
    "pmdecrad": -9.35302552e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 10481,
    "lum": 1.20781383510678,
    "ra": 2.256774,
    "dec": 24.271296,
    "pmra": 460.71,
    "pmdec": -192.92,
    "rv": -1.6,
    "Asset Name": "ASTRID 003447",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Prince",
    "Outfit": "Skater",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00002619,
    "y_1": 0.000055,
    "z_1": -0.00002368,
    "Distance in Parsecs_1": 26.9833,
    "HYG Star ID_1": 10481,
    "Hipparcos Catalogue_1": 10505,
    "Henry Draper Catalogue_1": 13825
  },
  {
    "Astrid #": "ASTRID 003448",
    "HYG Star ID": 18418,
    "Hipparcos Catalogue": 18465,
    "Gliese Catalog": "Gl 156.1A",
    "Distance in Parsecs": 26.9833,
    "mag": 10.86,
    "Absolute Visual Magnitude": 8.705,
    "Spectral Type": "M1",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.207347,
    "y": 13.766584,
    "z": 21.707526,
    "vx": -0.00001437,
    "vy": 0.00005477,
    "vz": -0.00003565,
    "rarad": 1.03318224039301,
    "decrad": 0.934801804690851,
    "pmrarad": 0.00000149695920145138,
    "pmdecrad": -0.000001968149617,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 18418,
    "base": "Gl 156.1",
    "lum": 0.0287078058202468,
    "var_min": 11.651,
    "var_max": 10.781,
    "ra": 3.946465,
    "dec": 53.560198,
    "pmra": 308.77,
    "pmdec": -405.96,
    "rv": -5,
    "Asset Name": "ASTRID 003448",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Shinobi",
    "Hair": "Elegant",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00001437,
    "y_1": 0.00005477,
    "z_1": -0.00003565,
    "Distance in Parsecs_1": 26.9833,
    "HYG Star ID_1": 18418,
    "Hipparcos Catalogue_1": 18465,
    "Gliese Catalog_1": "Gl 156.1A"
  },
  {
    "Astrid #": "ASTRID 003449",
    "HYG Star ID": 74327,
    "Hipparcos Catalogue": 74555,
    "Gliese Catalog": "GJ 1190",
    "Distance in Parsecs": 26.9978,
    "mag": 9.84,
    "Absolute Visual Magnitude": 7.683,
    "Spectral Type": "K4V",
    "Color Index": 1.117,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.849865,
    "y": -20.174548,
    "z": -1.788244,
    "vx": 0.00000158,
    "vy": 0.00014716,
    "vz": 0.00002703,
    "rarad": 3.98805148042318,
    "decrad": -0.06628763600153,
    "pmrarad": -0.00000356818020752083,
    "pmdecrad": 7.26056968e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 74327,
    "lum": 0.0735868139651044,
    "ra": 15.233235,
    "dec": -3.798002,
    "pmra": -735.99,
    "pmdec": 149.76,
    "rv": -110.3,
    "Asset Name": "ASTRID 003449",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Loopy",
    "Hair": "Audiophile",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000158,
    "y_1": 0.00014716,
    "z_1": 0.00002703,
    "Distance in Parsecs_1": 26.9978,
    "HYG Star ID_1": 74327,
    "Hipparcos Catalogue_1": 74555,
    "Gliese Catalog_1": "GJ 1190"
  },
  {
    "Astrid #": "ASTRID 003450",
    "HYG Star ID": 115590,
    "Hipparcos Catalogue": 115955,
    "Gliese Catalog": "Gl 895.3",
    "Distance in Parsecs": 26.9978,
    "mag": 10.19,
    "Absolute Visual Magnitude": 8.033,
    "Spectral Type": "K7V",
    "Color Index": 1.316,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.238102,
    "y": -2.435591,
    "z": -19.756488,
    "vx": -0.00000576,
    "vy": 0.00003154,
    "vz": -0.00000362,
    "rarad": 6.15042204441154,
    "decrad": -0.820933502107542,
    "pmrarad": 0.00000112976131979861,
    "pmdecrad": -3.59440862e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 115590,
    "lum": 0.0533089342236701,
    "var_min": 10.248,
    "var_max": 10.118,
    "ra": 23.492882,
    "dec": -47.036025,
    "pmra": 233.03,
    "pmdec": -74.14,
    "rv": -4,
    "Asset Name": "ASTRID 003450",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Audiophile",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000576,
    "y_1": 0.00003154,
    "z_1": -0.00000362,
    "Distance in Parsecs_1": 26.9978,
    "HYG Star ID_1": 115590,
    "Hipparcos Catalogue_1": 115955,
    "Gliese Catalog_1": "Gl 895.3"
  },
  {
    "Astrid #": "ASTRID 003451",
    "HYG Star ID": 105067,
    "Hipparcos Catalogue": 105406,
    "Henry Draper Catalogue": 203454,
    "Harvard Revised Catalogue": 8170,
    "Distance in Parsecs": 27.0124,
    "mag": 6.39,
    "Absolute Visual Magnitude": 4.232,
    "Spectral Type": "F8V",
    "Color Index": 0.533,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.830095,
    "y": -13.162978,
    "z": 17.487549,
    "vx": 0.00001264,
    "vy": -0.00001372,
    "vz": -0.00002019,
    "rarad": 5.58952072566358,
    "decrad": 0.704154246363516,
    "pmrarad": -9.15813042569444e-8,
    "pmdecrad": -0.000001012872741,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 105067,
    "lum": 1.76685129845388,
    "ra": 21.350396,
    "dec": 40.345066,
    "pmra": -18.89,
    "pmdec": -208.92,
    "rv": 1,
    "Asset Name": "ASTRID 003451",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Oh No",
    "Hair": "Round Brush",
    "Outfit": "Astro",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001264,
    "y_1": -0.00001372,
    "z_1": -0.00002019,
    "Distance in Parsecs_1": 27.0124,
    "HYG Star ID_1": 105067,
    "Hipparcos Catalogue_1": 105406,
    "Henry Draper Catalogue_1": 203454,
    "Harvard Revised Catalogue_1": 8170
  },
  {
    "Astrid #": "ASTRID 003452",
    "HYG Star ID": 116077,
    "Hipparcos Catalogue": 116443,
    "Gliese Catalog": "NN 4346",
    "Distance in Parsecs": 27.0124,
    "mag": 11.17,
    "Absolute Visual Magnitude": 9.012,
    "Spectral Type": "M:",
    "Color Index": 1.372,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.971896,
    "y": -2.121934,
    "z": 18.063513,
    "vx": -0.0000035,
    "vy": 0.00009272,
    "vz": 0.00001476,
    "rarad": 6.17732617113706,
    "decrad": 0.732475008477683,
    "pmrarad": 0.000003399901379,
    "pmdecrad": 7.35026021e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 116077,
    "lum": 0.021637147217791,
    "var_min": 11.25,
    "var_max": 11.1,
    "ra": 23.595648,
    "dec": 41.967727,
    "pmra": 701.28,
    "pmdec": 151.61,
    "rv": 0,
    "Asset Name": "ASTRID 003452",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Bangs",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.0000035,
    "y_1": 0.00009272,
    "z_1": 0.00001476,
    "Distance in Parsecs_1": 27.0124,
    "HYG Star ID_1": 116077,
    "Hipparcos Catalogue_1": 116443,
    "Gliese Catalog_1": "NN 4346"
  },
  {
    "Astrid #": "ASTRID 003453",
    "HYG Star ID": 57214,
    "Hipparcos Catalogue": 57387,
    "Gliese Catalog": "Gl 441",
    "Distance in Parsecs": 27.0197,
    "mag": 8.8,
    "Absolute Visual Magnitude": 6.642,
    "Spectral Type": "K8",
    "Color Index": 1.167,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.290913,
    "y": 0.509427,
    "z": 25.710957,
    "vx": 0.00001536,
    "vy": -0.00000321,
    "vz": -0.00002196,
    "rarad": 3.08022413376564,
    "decrad": 1.25830716929831,
    "pmrarad": 8.36303598958333e-8,
    "pmdecrad": 2.96948379e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 57214,
    "lum": 0.191955252376137,
    "ra": 11.76559,
    "dec": 72.09569,
    "pmra": 17.25,
    "pmdec": 61.25,
    "rv": -25.1,
    "Asset Name": "ASTRID 003453",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Curly",
    "Outfit": "River Spirit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001536,
    "y_1": -0.00000321,
    "z_1": -0.00002196,
    "Distance in Parsecs_1": 27.0197,
    "HYG Star ID_1": 57214,
    "Hipparcos Catalogue_1": 57387,
    "Gliese Catalog_1": "Gl 441"
  },
  {
    "Astrid #": "ASTRID 003454",
    "HYG Star ID": 81669,
    "Hipparcos Catalogue": 81919,
    "Henry Draper Catalogue": 151188,
    "Distance in Parsecs": 27.027,
    "mag": 8.3,
    "Absolute Visual Magnitude": 6.141,
    "Spectral Type": "K5",
    "Color Index": 1.052,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.390297,
    "y": -18.542388,
    "z": 18.595687,
    "vx": -0.00000926,
    "vy": 0.00000229,
    "vz": -0.00001056,
    "rarad": 4.38050562746321,
    "decrad": 0.758788176585653,
    "pmrarad": -3.51489918402777e-7,
    "pmdecrad": -3.05190211e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81669,
    "lum": 0.304508906697422,
    "ra": 16.732299,
    "dec": 43.47536,
    "pmra": -72.5,
    "pmdec": -62.95,
    "rv": -6.5,
    "Asset Name": "ASTRID 003454",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Middle Part",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000926,
    "y_1": 0.00000229,
    "z_1": -0.00001056,
    "Distance in Parsecs_1": 27.027,
    "HYG Star ID_1": 81669,
    "Hipparcos Catalogue_1": 81919,
    "Henry Draper Catalogue_1": 151188
  },
  {
    "Astrid #": "ASTRID 003455",
    "HYG Star ID": 118954,
    "Gliese Catalog": "Wo 9474B",
    "Distance in Parsecs": 27.027,
    "mag": 8.23,
    "Absolute Visual Magnitude": 6.071,
    "Spectral Type": "K1 V",
    "Color Index": 0.82,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.97735,
    "y": -9.445857,
    "z": 21.11423,
    "vx": 0.0000065,
    "vy": 0.0000283,
    "vz": -0.00001026,
    "rarad": 3.73591198008575,
    "decrad": 0.896678677465899,
    "pmrarad": -7.33038285e-7,
    "pmdecrad": 3.76729318e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 2,
    "comp_primary": 69491,
    "base": "Wo 9474",
    "lum": 0.324788018763537,
    "ra": 14.270133,
    "dec": 51.375904,
    "pmra": -151.2,
    "pmdec": 77.71,
    "rv": -20.8,
    "Asset Name": "ASTRID 003455",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Nervous Grin",
    "Hair": "Little Crown",
    "Outfit": "Astro",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.0000065,
    "y_1": 0.0000283,
    "z_1": -0.00001026,
    "Distance in Parsecs_1": 27.027,
    "HYG Star ID_1": 118954,
    "Gliese Catalog_1": "Wo 9474B"
  },
  {
    "Astrid #": "ASTRID 003456",
    "HYG Star ID": 118164,
    "Gliese Catalog": "Gl 105.4B",
    "Distance in Parsecs": 27.0343,
    "mag": 5.6,
    "Absolute Visual Magnitude": 3.44,
    "Spectral Type": "F5 V",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.29923,
    "y": 16.967967,
    "z": -5.561404,
    "vx": -0.00000377,
    "vy": 0.00002108,
    "vz": -0.000033,
    "rarad": 0.696245754682498,
    "decrad": -0.2071892152145,
    "pmrarad": 6.87853649972222e-7,
    "pmdecrad": -0.00000111370429,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 12357,
    "base": "Gl 105.4",
    "lum": 3.66437574647833,
    "ra": 2.659463,
    "dec": -11.871068,
    "pmra": 141.88,
    "pmdec": -229.72,
    "rv": 16.8,
    "Asset Name": "ASTRID 003456",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Unimpressed",
    "Hair": "Pixie",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000377,
    "y_1": 0.00002108,
    "z_1": -0.000033,
    "Distance in Parsecs_1": 27.0343,
    "HYG Star ID_1": 118164,
    "Gliese Catalog_1": "Gl 105.4B"
  },
  {
    "Astrid #": "ASTRID 003457",
    "HYG Star ID": 25423,
    "Hipparcos Catalogue": 25486,
    "Henry Draper Catalogue": 35850,
    "Harvard Revised Catalogue": 1817,
    "Distance in Parsecs": 27.0416,
    "mag": 6.3,
    "Absolute Visual Magnitude": 4.14,
    "Spectral Type": "F7V:",
    "Color Index": 0.553,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.787819,
    "y": 26.188005,
    "z": -5.576571,
    "vx": 3e-7,
    "vy": 0.00001782,
    "vz": -0.00001033,
    "rarad": 1.42715303122037,
    "decrad": -0.20771099836501,
    "pmrarad": 8.33394716875e-8,
    "pmdecrad": -2.39013144e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 25423,
    "lum": 1.92309172891015,
    "var": "AF",
    "var_min": 6.319,
    "var_max": 6.269,
    "ra": 5.451323,
    "dec": -11.900964,
    "pmra": 17.19,
    "pmdec": -49.3,
    "rv": 19,
    "Asset Name": "ASTRID 003457",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Guilty Laugh",
    "Hair": "Audiohead",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": 3e-7,
    "y_1": 0.00001782,
    "z_1": -0.00001033,
    "Distance in Parsecs_1": 27.0416,
    "HYG Star ID_1": 25423,
    "Hipparcos Catalogue_1": 25486,
    "Henry Draper Catalogue_1": 35850,
    "Harvard Revised Catalogue_1": 1817
  },
  {
    "Astrid #": "ASTRID 003458",
    "HYG Star ID": 93213,
    "Hipparcos Catalogue": 93506,
    "Henry Draper Catalogue": 176687,
    "Harvard Revised Catalogue": 7194,
    "Distance in Parsecs": 27.0416,
    "mag": 2.6,
    "Absolute Visual Magnitude": 0.44,
    "Spectral Type": "A3IV",
    "Color Index": 0.062,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 6.326274,
    "y": -22.577554,
    "z": -13.471863,
    "vx": 0.00000355,
    "vy": -0.00001951,
    "vz": -0.00001079,
    "rarad": 4.98558501186112,
    "decrad": -0.521506218738425,
    "pmrarad": -6.83587289583333e-8,
    "pmdecrad": 1.774418e-8,
    "bayer": "Zet",
    "flam": 38,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 93213,
    "lum": 58.0764417521311,
    "bf": "38Zet Sgr",
    "ra": 19.043532,
    "dec": -29.880105,
    "pmra": -14.1,
    "pmdec": 3.66,
    "rv": 22,
    "Asset Name": "ASTRID 003458",
    "Astrium Color": "White",
    "Background": "Burning Cosmos",
    "Expression": "Happy Vamp",
    "Hair": "Tiny Crown",
    "Outfit": "School Uniform",
    "Special": "Neko Tail",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00000355,
    "y_1": -0.00001951,
    "z_1": -0.00001079,
    "Distance in Parsecs_1": 27.0416,
    "HYG Star ID_1": 93213,
    "Hipparcos Catalogue_1": 93506,
    "Henry Draper Catalogue_1": 176687,
    "Harvard Revised Catalogue_1": 7194
  },
  {
    "Astrid #": "ASTRID 003459",
    "HYG Star ID": 5509,
    "Hipparcos Catalogue": 5521,
    "Henry Draper Catalogue": 6963,
    "Distance in Parsecs": 27.0783,
    "mag": 7.66,
    "Absolute Visual Magnitude": 5.497,
    "Spectral Type": "K0",
    "Color Index": 0.73,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.88992,
    "y": 6.019319,
    "z": 18.443781,
    "vx": 0.00002315,
    "vy": -0.00001377,
    "vz": -0.00001922,
    "rarad": 0.308482442346253,
    "decrad": 0.749301806881652,
    "pmrarad": -7.44188999652777e-7,
    "pmdecrad": -9.69384954e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 5509,
    "lum": 0.551061411139065,
    "var": "V443",
    "var_min": 7.687,
    "var_max": 7.647,
    "ra": 1.178316,
    "dec": 42.931831,
    "pmra": -153.5,
    "pmdec": -199.95,
    "rv": 0,
    "Asset Name": "ASTRID 003459",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Goddess",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00002315,
    "y_1": -0.00001377,
    "z_1": -0.00001922,
    "Distance in Parsecs_1": 27.0783,
    "HYG Star ID_1": 5509,
    "Hipparcos Catalogue_1": 5521,
    "Henry Draper Catalogue_1": 6963
  },
  {
    "Astrid #": "ASTRID 003460",
    "HYG Star ID": 105544,
    "Hipparcos Catalogue": 105885,
    "Gliese Catalog": "Gl 828.1",
    "Distance in Parsecs": 27.0783,
    "mag": 10.5,
    "Absolute Visual Magnitude": 8.337,
    "Spectral Type": "M1",
    "Color Index": 1.369,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.19843,
    "y": -16.755406,
    "z": 1.764939,
    "vx": -0.00000677,
    "vy": -0.00000157,
    "vz": -0.00000726,
    "rarad": 5.61432065603507,
    "decrad": 0.0652254351312925,
    "pmrarad": -2.00567419645833e-7,
    "pmdecrad": -2.57242138e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 105544,
    "lum": 0.0402902535083295,
    "var_min": 10.554,
    "var_max": 10.294,
    "ra": 21.445125,
    "dec": 3.737142,
    "pmra": -41.37,
    "pmdec": -53.06,
    "rv": -4.7,
    "Asset Name": "ASTRID 003460",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Nervous Grin",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": -0.00000677,
    "y_1": -0.00000157,
    "z_1": -0.00000726,
    "Distance in Parsecs_1": 27.0783,
    "HYG Star ID_1": 105544,
    "Hipparcos Catalogue_1": 105885,
    "Gliese Catalog_1": "Gl 828.1"
  },
  {
    "Astrid #": "ASTRID 003461",
    "HYG Star ID": 2420,
    "Hipparcos Catalogue": 2426,
    "Distance in Parsecs": 27.0856,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.466,
    "Color Index": 1.359,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.680802,
    "y": 1.042799,
    "z": 25.952795,
    "vx": -0.00002128,
    "vy": 0.00002909,
    "vz": 0.00000513,
    "rarad": 0.134916219659059,
    "decrad": 1.2805620829176,
    "pmrarad": 0.00000116990389254861,
    "pmdecrad": 6.61819155e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 2420,
    "lum": 0.035776676993611,
    "var_min": 10.693,
    "var_max": 10.573,
    "ra": 0.515342,
    "dec": 73.370803,
    "pmra": 241.31,
    "pmdec": 136.51,
    "rv": 0,
    "Asset Name": "ASTRID 003461",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Medium Bob",
    "Outfit": "Turtle School",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00002128,
    "y_1": 0.00002909,
    "z_1": 0.00000513,
    "Distance in Parsecs_1": 27.0856,
    "HYG Star ID_1": 2420,
    "Hipparcos Catalogue_1": 2426
  },
  {
    "Astrid #": "ASTRID 003462",
    "HYG Star ID": 21219,
    "Hipparcos Catalogue": 21272,
    "Henry Draper Catalogue": 28946,
    "Gliese Catalog": "Wo 9158",
    "Distance in Parsecs": 27.0856,
    "mag": 7.92,
    "Absolute Visual Magnitude": 5.756,
    "Spectral Type": "K0",
    "Color Index": 0.779,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.90046,
    "y": 25.082402,
    "z": 2.541891,
    "vx": -0.00000508,
    "vy": -0.00004509,
    "vz": -0.00004096,
    "rarad": 1.1948522359712,
    "decrad": 0.0939863030323062,
    "pmrarad": -4.36574719340277e-7,
    "pmdecrad": -0.000001353939165,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 21219,
    "lum": 0.434110209707345,
    "ra": 4.563999,
    "dec": 5.385018,
    "pmra": -90.05,
    "pmdec": -279.27,
    "rv": -46.4,
    "Asset Name": "ASTRID 003462",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Halo Boy",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00000508,
    "y_1": -0.00004509,
    "z_1": -0.00004096,
    "Distance in Parsecs_1": 27.0856,
    "HYG Star ID_1": 21219,
    "Hipparcos Catalogue_1": 21272,
    "Henry Draper Catalogue_1": 28946,
    "Gliese Catalog_1": "Wo 9158"
  },
  {
    "Astrid #": "ASTRID 003463",
    "HYG Star ID": 35170,
    "Hipparcos Catalogue": 35265,
    "Henry Draper Catalogue": 56124,
    "Distance in Parsecs": 27.0856,
    "mag": 6.93,
    "Absolute Visual Magnitude": 4.766,
    "Spectral Type": "G0",
    "Color Index": 0.631,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.4963,
    "y": 21.418393,
    "z": 14.788463,
    "vx": -0.00000328,
    "vy": 0.00003253,
    "vz": -0.00000643,
    "rarad": 1.90746591211684,
    "decrad": 0.57756530322446,
    "pmrarad": -2.82500931659722e-7,
    "pmdecrad": -8.39745776e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 35170,
    "lum": 1.08043837237122,
    "ra": 7.285983,
    "dec": 33.092054,
    "pmra": -58.27,
    "pmdec": -173.21,
    "rv": 22.6,
    "Asset Name": "ASTRID 003463",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "High Fade",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00000328,
    "y_1": 0.00003253,
    "z_1": -0.00000643,
    "Distance in Parsecs_1": 27.0856,
    "HYG Star ID_1": 35170,
    "Hipparcos Catalogue_1": 35265,
    "Henry Draper Catalogue_1": 56124
  },
  {
    "Astrid #": "ASTRID 003464",
    "HYG Star ID": 104045,
    "Hipparcos Catalogue": 104383,
    "Gliese Catalog": "Wo 9721A",
    "Distance in Parsecs": 27.0856,
    "mag": 9.45,
    "Absolute Visual Magnitude": 7.286,
    "Spectral Type": "M1",
    "Color Index": 1.15,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.810919,
    "y": -18.351883,
    "z": -2.090682,
    "vx": 0.00000126,
    "vy": -0.00001465,
    "vz": -0.0000052,
    "rarad": 5.53600043013859,
    "decrad": -0.0772645027174025,
    "pmrarad": -3.65355589666666e-7,
    "pmdecrad": -1.60424846e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 104045,
    "base": "Wo 9721",
    "lum": 0.106071814963531,
    "ra": 21.145964,
    "dec": -4.42693,
    "pmra": -75.36,
    "pmdec": -33.09,
    "rv": 11,
    "Asset Name": "ASTRID 003464",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Tiny Crown",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000126,
    "y_1": -0.00001465,
    "z_1": -0.0000052,
    "Distance in Parsecs_1": 27.0856,
    "HYG Star ID_1": 104045,
    "Hipparcos Catalogue_1": 104383,
    "Gliese Catalog_1": "Wo 9721A"
  },
  {
    "Astrid #": "ASTRID 003465",
    "HYG Star ID": 83755,
    "Hipparcos Catalogue": 84012,
    "Henry Draper Catalogue": 155125,
    "Harvard Revised Catalogue": 6378,
    "Gliese Catalog": "Gl 656.1A",
    "Distance in Parsecs": 27.0929,
    "mag": 2.43,
    "Absolute Visual Magnitude": 0.266,
    "Spectral Type": "A2.5Va",
    "Color Index": 0.059,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -5.602493,
    "y": -25.470005,
    "z": -7.342684,
    "vx": 0.00000504,
    "vy": -0.00000225,
    "vz": 0.00001301,
    "rarad": 4.49587261805647,
    "decrad": -0.274451457570108,
    "pmrarad": 1.99549310916666e-7,
    "pmdecrad": 4.73420559e-7,
    "bayer": "Eta",
    "flam": 35,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83755,
    "base": "Gl 656.1",
    "lum": 68.1710526305853,
    "bf": "35Eta Oph",
    "ra": 17.172968,
    "dec": -15.72491,
    "pmra": 41.16,
    "pmdec": 97.65,
    "rv": -2.4,
    "Asset Name": "ASTRID 003465",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Bucket Hat",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00000504,
    "y_1": -0.00000225,
    "z_1": 0.00001301,
    "Distance in Parsecs_1": 27.0929,
    "HYG Star ID_1": 83755,
    "Hipparcos Catalogue_1": 84012,
    "Henry Draper Catalogue_1": 155125,
    "Harvard Revised Catalogue_1": 6378,
    "Gliese Catalog_1": "Gl 656.1A"
  },
  {
    "Astrid #": "ASTRID 003466",
    "HYG Star ID": 112137,
    "Hipparcos Catalogue": 112496,
    "Distance in Parsecs": 27.0929,
    "mag": 9.02,
    "Absolute Visual Magnitude": 6.856,
    "Spectral Type": "G0",
    "Color Index": 1.048,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 24.424832,
    "y": -8.027308,
    "z": 8.544891,
    "vx": 0.00000693,
    "vy": 0.00003258,
    "vz": 0.00001079,
    "rarad": 5.96565186180798,
    "decrad": 0.320869989251443,
    "pmrarad": 0.00000122226377004861,
    "pmdecrad": 4.19800165e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112137,
    "lum": 0.157615890496383,
    "ra": 22.787112,
    "dec": 18.384496,
    "pmra": 252.11,
    "pmdec": 86.59,
    "rv": 0,
    "Asset Name": "ASTRID 003466",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Fired Up",
    "Hair": "Bangs",
    "Outfit": "School Uniform",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000693,
    "y_1": 0.00003258,
    "z_1": 0.00001079,
    "Distance in Parsecs_1": 27.0929,
    "HYG Star ID_1": 112137,
    "Hipparcos Catalogue_1": 112496
  },
  {
    "Astrid #": "ASTRID 003467",
    "HYG Star ID": 106217,
    "Hipparcos Catalogue": 106559,
    "Henry Draper Catalogue": 205289,
    "Harvard Revised Catalogue": 8245,
    "Distance in Parsecs": 27.1003,
    "mag": 5.7,
    "Absolute Visual Magnitude": 3.535,
    "Spectral Type": "F5V",
    "Color Index": 0.423,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.516105,
    "y": -15.06357,
    "z": -9.306314,
    "vx": 0.00000401,
    "vy": -0.00000767,
    "vz": 0.00000337,
    "rarad": 5.64985273863371,
    "decrad": -0.350536706498852,
    "pmrarad": -1.40499004625e-7,
    "pmdecrad": 2.15257274e-7,
    "flam": 37,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 106217,
    "lum": 3.35737614242954,
    "bf": "37    Cap",
    "ra": 21.580848,
    "dec": -20.084274,
    "pmra": -28.98,
    "pmdec": 44.4,
    "rv": 6,
    "Asset Name": "ASTRID 003467",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Prince",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000401,
    "y_1": -0.00000767,
    "z_1": 0.00000337,
    "Distance in Parsecs_1": 27.1003,
    "HYG Star ID_1": 106217,
    "Hipparcos Catalogue_1": 106559,
    "Henry Draper Catalogue_1": 205289,
    "Harvard Revised Catalogue_1": 8245
  },
  {
    "Astrid #": "ASTRID 003468",
    "HYG Star ID": 116453,
    "Hipparcos Catalogue": 116819,
    "Henry Draper Catalogue": 222422,
    "Distance in Parsecs": 27.1003,
    "mag": 7.59,
    "Absolute Visual Magnitude": 5.425,
    "Spectral Type": "G5V",
    "Color Index": 0.731,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.534085,
    "y": -2.163133,
    "z": -8.818028,
    "vx": 0.00000155,
    "vy": 0.00003984,
    "vz": -0.00000528,
    "rarad": 6.19867083868273,
    "decrad": -0.331418759628237,
    "pmrarad": 0.00000146990659807638,
    "pmdecrad": -2.0590037e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 116453,
    "lum": 0.588843655355588,
    "ra": 23.677179,
    "dec": -18.988896,
    "pmra": 303.19,
    "pmdec": -42.47,
    "rv": 0,
    "Asset Name": "ASTRID 003468",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Braided Ponytail",
    "Outfit": "Pirate",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000155,
    "y_1": 0.00003984,
    "z_1": -0.00000528,
    "Distance in Parsecs_1": 27.1003,
    "HYG Star ID_1": 116453,
    "Hipparcos Catalogue_1": 116819,
    "Henry Draper Catalogue_1": 222422
  },
  {
    "Astrid #": "ASTRID 003469",
    "HYG Star ID": 78715,
    "Hipparcos Catalogue": 78955,
    "Henry Draper Catalogue": 144585,
    "Harvard Revised Catalogue": 5996,
    "Distance in Parsecs": 27.1076,
    "mag": 6.32,
    "Absolute Visual Magnitude": 4.155,
    "Spectral Type": "G5V",
    "Color Index": 0.66,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.439823,
    "y": -23.165251,
    "z": -6.590609,
    "vx": -0.00002291,
    "vy": 0.00002989,
    "vz": 0.00000548,
    "rarad": 4.2195788577349,
    "decrad": -0.245590367702512,
    "pmrarad": -0.00000126643029634722,
    "pmdecrad": 5.706257e-8,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 78715,
    "lum": 1.89670592121114,
    "ra": 16.117604,
    "dec": -14.071292,
    "pmra": -261.22,
    "pmdec": 11.77,
    "rv": -16,
    "Asset Name": "ASTRID 003469",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Manbun",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00002291,
    "y_1": 0.00002989,
    "z_1": 0.00000548,
    "Distance in Parsecs_1": 27.1076,
    "HYG Star ID_1": 78715,
    "Hipparcos Catalogue_1": 78955,
    "Henry Draper Catalogue_1": 144585,
    "Harvard Revised Catalogue_1": 5996
  },
  {
    "Astrid #": "ASTRID 003470",
    "HYG Star ID": 26397,
    "Hipparcos Catalogue": 26462,
    "Distance in Parsecs": 27.115,
    "mag": 10.47,
    "Absolute Visual Magnitude": 8.304,
    "Spectral Type": "G5",
    "Color Index": 0.721,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.523058,
    "y": 15.646094,
    "z": -22.093059,
    "vx": 0.00000173,
    "vy": 0.00000891,
    "vz": 0.00000643,
    "rarad": 1.47375797056537,
    "decrad": -0.95236868462046,
    "pmrarad": -3.17068147083333e-8,
    "pmdecrad": 4.08843376e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 26397,
    "lum": 0.0415336405489073,
    "ra": 5.629341,
    "dec": -54.566706,
    "pmra": -6.54,
    "pmdec": 84.33,
    "rv": 0,
    "Asset Name": "ASTRID 003470",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Charmer",
    "Hair": "Ram Horns",
    "Outfit": "Scrubs",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00000173,
    "y_1": 0.00000891,
    "z_1": 0.00000643,
    "Distance in Parsecs_1": 27.115,
    "HYG Star ID_1": 26397,
    "Hipparcos Catalogue_1": 26462
  },
  {
    "Astrid #": "ASTRID 003471",
    "HYG Star ID": 24012,
    "Hipparcos Catalogue": 24069,
    "Distance in Parsecs": 27.1297,
    "mag": 12.27,
    "Absolute Visual Magnitude": 10.103,
    "Spectral Type": "M0V:",
    "Color Index": 1.383,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.757069,
    "y": 26.130795,
    "z": 4.478652,
    "vx": -0.00000562,
    "vy": 0.00000905,
    "vz": -0.00004557,
    "rarad": 1.35394327064605,
    "decrad": 0.165842156281901,
    "pmrarad": 2.74016692249999e-7,
    "pmdecrad": -0.000001703053497,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 24012,
    "lum": 0.00792136454152851,
    "var_min": 12.39,
    "var_max": 12.15,
    "ra": 5.171682,
    "dec": 9.502056,
    "pmra": 56.52,
    "pmdec": -351.28,
    "rv": 0,
    "Asset Name": "ASTRID 003471",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Medium Bob",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000562,
    "y_1": 0.00000905,
    "z_1": -0.00004557,
    "Distance in Parsecs_1": 27.1297,
    "HYG Star ID_1": 24012,
    "Hipparcos Catalogue_1": 24069
  },
  {
    "Astrid #": "ASTRID 003472",
    "HYG Star ID": 32237,
    "Hipparcos Catalogue": 32322,
    "Henry Draper Catalogue": 48938,
    "Harvard Revised Catalogue": 2493,
    "Gliese Catalog": "Gl 243",
    "Distance in Parsecs": 27.137,
    "mag": 6.43,
    "Absolute Visual Magnitude": 4.262,
    "Spectral Type": "F7V",
    "Color Index": 0.55,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.688864,
    "y": 23.644824,
    "z": -12.463857,
    "vx": -5.2e-7,
    "vy": 0.00000679,
    "vz": 0.00004003,
    "rarad": 1.766560627837,
    "decrad": -0.477202100834406,
    "pmrarad": -2.99614854583333e-8,
    "pmdecrad": 0.000001424818925,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 32237,
    "lum": 1.71869969539773,
    "ra": 6.747765,
    "dec": -27.341666,
    "pmra": -6.18,
    "pmdec": 293.89,
    "rv": -12.1,
    "Asset Name": "ASTRID 003472",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Determined Shinobi",
    "Hair": "Fire",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -5.2e-7,
    "y_1": 0.00000679,
    "z_1": 0.00004003,
    "Distance in Parsecs_1": 27.137,
    "HYG Star ID_1": 32237,
    "Hipparcos Catalogue_1": 32322,
    "Henry Draper Catalogue_1": 48938,
    "Harvard Revised Catalogue_1": 2493,
    "Gliese Catalog_1": "Gl 243"
  },
  {
    "Astrid #": "ASTRID 003473",
    "HYG Star ID": 65434,
    "Hipparcos Catalogue": 65640,
    "Henry Draper Catalogue": 117099,
    "Distance in Parsecs": 27.1444,
    "mag": 8.76,
    "Absolute Visual Magnitude": 6.592,
    "Spectral Type": "K0",
    "Color Index": 0.999,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.451333,
    "y": -8.206135,
    "z": 15.849945,
    "vx": -0.00003695,
    "vy": 0.00002525,
    "vz": -0.00003461,
    "rarad": 3.52318051622444,
    "decrad": 0.623539392229008,
    "pmrarad": -0.00000137037434945833,
    "pmdecrad": -0.000001570602399,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 65434,
    "lum": 0.201001824711369,
    "ra": 13.457558,
    "dec": 35.726176,
    "pmra": -282.66,
    "pmdec": -323.96,
    "rv": 0,
    "Asset Name": "ASTRID 003473",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Undercut",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00003695,
    "y_1": 0.00002525,
    "z_1": -0.00003461,
    "Distance in Parsecs_1": 27.1444,
    "HYG Star ID_1": 65434,
    "Hipparcos Catalogue_1": 65640,
    "Henry Draper Catalogue_1": 117099
  },
  {
    "Astrid #": "ASTRID 003474",
    "HYG Star ID": 77282,
    "Hipparcos Catalogue": 77518,
    "Henry Draper Catalogue": 329879,
    "Distance in Parsecs": 27.1444,
    "mag": 11.15,
    "Absolute Visual Magnitude": 8.982,
    "Spectral Type": "M0",
    "Color Index": 1.415,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.857004,
    "y": -15.418739,
    "z": -20.047953,
    "vx": 0.00002712,
    "vy": 0.00002805,
    "vz": -0.00003491,
    "rarad": 4.14358036306323,
    "decrad": -0.830942080316821,
    "pmrarad": 2.85264369638888e-7,
    "pmdecrad": -0.000001907693351,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 77282,
    "lum": 0.0222433399848511,
    "var_min": 11.223,
    "var_max": 11.083,
    "ra": 15.827311,
    "dec": -47.609474,
    "pmra": 58.84,
    "pmdec": -393.49,
    "rv": 0,
    "Asset Name": "ASTRID 003474",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Determined",
    "Hair": "Lion Mane",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": 0.00002712,
    "y_1": 0.00002805,
    "z_1": -0.00003491,
    "Distance in Parsecs_1": 27.1444,
    "HYG Star ID_1": 77282,
    "Hipparcos Catalogue_1": 77518,
    "Henry Draper Catalogue_1": 329879
  },
  {
    "Astrid #": "ASTRID 003475",
    "HYG Star ID": 10008,
    "Hipparcos Catalogue": 10031,
    "Henry Draper Catalogue": 12800,
    "Distance in Parsecs": 27.1518,
    "mag": 6.57,
    "Absolute Visual Magnitude": 4.401,
    "Spectral Type": "F8",
    "Color Index": 0.551,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.263752,
    "y": 4.589191,
    "z": 25.756505,
    "vx": 0.00000365,
    "vy": 0.0000503,
    "vz": -0.00000988,
    "rarad": 0.563441350758202,
    "decrad": 1.24881809345698,
    "pmrarad": 0.00000149448665168055,
    "pmdecrad": -0.000001161662059,
    "flam": 54,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 10008,
    "lum": 1.51216784879176,
    "ra": 2.152187,
    "dec": 71.552006,
    "pmra": 308.26,
    "pmdec": -239.61,
    "rv": 0.1,
    "Asset Name": "ASTRID 003475",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Awkward",
    "Hair": "Good Boy",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000365,
    "y_1": 0.0000503,
    "z_1": -0.00000988,
    "Distance in Parsecs_1": 27.1518,
    "HYG Star ID_1": 10008,
    "Hipparcos Catalogue_1": 10031,
    "Henry Draper Catalogue_1": 12800
  },
  {
    "Astrid #": "ASTRID 003476",
    "HYG Star ID": 60893,
    "Hipparcos Catalogue": 61084,
    "Henry Draper Catalogue": 108903,
    "Harvard Revised Catalogue": 4763,
    "Proper Name": "Gacrux",
    "Distance in Parsecs": 27.1518,
    "mag": 1.59,
    "Absolute Visual Magnitude": -0.579,
    "Spectral Type": "M4III",
    "Color Index": 1.6,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -14.606901,
    "y": -1.998688,
    "z": -22.800748,
    "vx": 0.00001789,
    "vy": -0.00000126,
    "vz": -0.00003693,
    "rarad": 3.27757892924824,
    "decrad": -0.996813589195008,
    "pmrarad": 1.35456942347222e-7,
    "pmdecrad": -0.000001281508001,
    "bayer": "Gam",
    "con": "Cru",
    "Full Constellation Name Formula 1": "Crux",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crux",
    "comp": 1,
    "comp_primary": 60893,
    "lum": 148.456767505227,
    "bf": "Gam Cru",
    "ra": 12.519429,
    "dec": -57.113212,
    "pmra": 27.94,
    "pmdec": -264.33,
    "rv": 21,
    "Asset Name": "ASTRID 003476",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Serious Mad",
    "Hair": "Astral Band",
    "Outfit": "Star Knight",
    "Special": "Astral Burst",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Gacrux",
    "Constellation": "Crux",
    "x_1": 0.00001789,
    "y_1": -0.00000126,
    "z_1": -0.00003693,
    "Distance in Parsecs_1": 27.1518,
    "HYG Star ID_1": 60893,
    "Hipparcos Catalogue_1": 61084,
    "Henry Draper Catalogue_1": 108903,
    "Harvard Revised Catalogue_1": 4763
  },
  {
    "Astrid #": "ASTRID 003477",
    "HYG Star ID": 101546,
    "Hipparcos Catalogue": 101875,
    "Henry Draper Catalogue": 196850,
    "Gliese Catalog": "Gl 794.3",
    "Distance in Parsecs": 27.1592,
    "mag": 6.76,
    "Absolute Visual Magnitude": 4.59,
    "Spectral Type": "G0",
    "Color Index": 0.61,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.542147,
    "y": -16.330459,
    "z": 16.956949,
    "vx": 0.00001958,
    "vy": 0.00001781,
    "vz": -0.00003354,
    "rarad": 5.40471433829742,
    "decrad": 0.674309576527885,
    "pmrarad": 9.73748277395833e-7,
    "pmdecrad": -9.36853956e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 101546,
    "lum": 1.27057410520854,
    "ra": 20.644488,
    "dec": 38.635093,
    "pmra": 200.85,
    "pmdec": -193.24,
    "rv": -21.4,
    "Asset Name": "ASTRID 003477",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Worried Vamp",
    "Hair": "Neko",
    "Outfit": "Viking",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001958,
    "y_1": 0.00001781,
    "z_1": -0.00003354,
    "Distance in Parsecs_1": 27.1592,
    "HYG Star ID_1": 101546,
    "Hipparcos Catalogue_1": 101875,
    "Henry Draper Catalogue_1": 196850,
    "Gliese Catalog_1": "Gl 794.3"
  },
  {
    "Astrid #": "ASTRID 003478",
    "HYG Star ID": 45955,
    "Hipparcos Catalogue": 46087,
    "Gliese Catalog": "NN 3555",
    "Distance in Parsecs": 27.1665,
    "mag": 11.6,
    "Absolute Visual Magnitude": 9.43,
    "Color Index": 1.484,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -21.103045,
    "y": 17.107774,
    "z": 0.065512,
    "vx": 0.00001718,
    "vy": 0.00002131,
    "vz": -0.00002967,
    "rarad": 2.46037455275419,
    "decrad": 0.00241151365588124,
    "pmrarad": -0.00000100768523503472,
    "pmdecrad": -0.000001092382185,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 45955,
    "lum": 0.0147231250243271,
    "var_min": 11.693,
    "var_max": 11.503,
    "ra": 9.397939,
    "dec": 0.13817,
    "pmra": -207.85,
    "pmdec": -225.32,
    "rv": 0,
    "Asset Name": "ASTRID 003478",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Unkempt",
    "Outfit": "Basketball",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001718,
    "y_1": 0.00002131,
    "z_1": -0.00002967,
    "Distance in Parsecs_1": 27.1665,
    "HYG Star ID_1": 45955,
    "Hipparcos Catalogue_1": 46087,
    "Gliese Catalog_1": "NN 3555"
  },
  {
    "Astrid #": "ASTRID 003479",
    "HYG Star ID": 93216,
    "Hipparcos Catalogue": 93509,
    "Henry Draper Catalogue": 177082,
    "Distance in Parsecs": 27.1665,
    "mag": 6.88,
    "Absolute Visual Magnitude": 4.71,
    "Spectral Type": "G2V",
    "Color Index": 0.629,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.096665,
    "y": -25.31742,
    "z": 6.832711,
    "vx": 0.00002196,
    "vy": 0.00000259,
    "vz": 6.1e-7,
    "rarad": 4.98568266167659,
    "decrad": 0.254242265188123,
    "pmrarad": 8.04305896041666e-7,
    "pmdecrad": -1.0035643e-8,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 93216,
    "lum": 1.13762728582343,
    "ra": 19.043905,
    "dec": 14.567009,
    "pmra": 165.9,
    "pmdec": -2.07,
    "rv": 3.4,
    "Asset Name": "ASTRID 003479",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Slick",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00002196,
    "y_1": 0.00000259,
    "z_1": 6.1e-7,
    "Distance in Parsecs_1": 27.1665,
    "HYG Star ID_1": 93216,
    "Hipparcos Catalogue_1": 93509,
    "Henry Draper Catalogue_1": 177082
  },
  {
    "Astrid #": "ASTRID 003480",
    "HYG Star ID": 14112,
    "Hipparcos Catalogue": 14146,
    "Henry Draper Catalogue": 18978,
    "Harvard Revised Catalogue": 919,
    "Gliese Catalog": "Gl 121",
    "Distance in Parsecs": 27.1739,
    "mag": 4.08,
    "Absolute Visual Magnitude": 1.909,
    "Spectral Type": "A4V",
    "Color Index": 0.163,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 17.419759,
    "y": 17.787178,
    "z": -10.889644,
    "vx": 0.00000525,
    "vy": -0.00002212,
    "vz": -0.00000271,
    "rarad": 0.795834354202078,
    "decrad": -0.412324813405945,
    "pmrarad": -7.07634048138888e-7,
    "pmdecrad": -2.70332108e-7,
    "bayer": "Tau-3",
    "flam": 11,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 14112,
    "lum": 15.0106673257036,
    "bf": "11Tau3Eri",
    "ra": 3.039863,
    "dec": -23.624472,
    "pmra": -145.96,
    "pmdec": -55.76,
    "rv": -9.8,
    "Asset Name": "ASTRID 003480",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Slick",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000525,
    "y_1": -0.00002212,
    "z_1": -0.00000271,
    "Distance in Parsecs_1": 27.1739,
    "HYG Star ID_1": 14112,
    "Hipparcos Catalogue_1": 14146,
    "Henry Draper Catalogue_1": 18978,
    "Harvard Revised Catalogue_1": 919,
    "Gliese Catalog_1": "Gl 121"
  },
  {
    "Astrid #": "ASTRID 003481",
    "HYG Star ID": 79716,
    "Hipparcos Catalogue": 79958,
    "Henry Draper Catalogue": 146464,
    "Distance in Parsecs": 27.1739,
    "mag": 9.25,
    "Absolute Visual Magnitude": 7.079,
    "Spectral Type": "K5III",
    "Color Index": 1.062,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.548679,
    "y": -13.926775,
    "z": -22.395994,
    "vx": -3.7e-7,
    "vy": -0.00000194,
    "vz": 0.00000132,
    "rarad": 4.27284596692791,
    "decrad": -0.968740397690086,
    "pmrarad": 1.82774757569444e-8,
    "pmdecrad": 8.5521133e-8,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 79716,
    "lum": 0.128351219683949,
    "var": "V371",
    "var_min": 9.316,
    "var_max": 9.176,
    "ra": 16.321069,
    "dec": -55.504736,
    "pmra": 3.77,
    "pmdec": 17.64,
    "rv": 0,
    "Asset Name": "ASTRID 003481",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Elegant",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -3.7e-7,
    "y_1": -0.00000194,
    "z_1": 0.00000132,
    "Distance in Parsecs_1": 27.1739,
    "HYG Star ID_1": 79716,
    "Hipparcos Catalogue_1": 79958,
    "Henry Draper Catalogue_1": 146464
  },
  {
    "Astrid #": "ASTRID 003482",
    "HYG Star ID": 60665,
    "Hipparcos Catalogue": 60853,
    "Henry Draper Catalogue": 108564,
    "Distance in Parsecs": 27.1887,
    "mag": 9.45,
    "Absolute Visual Magnitude": 7.278,
    "Spectral Type": "K2V",
    "Color Index": 0.964,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.815357,
    "y": -3.206168,
    "z": -7.909043,
    "vx": -0.00009018,
    "vy": 0.00006319,
    "vz": -0.00001851,
    "rarad": 3.26515716776647,
    "decrad": -0.295153452321788,
    "pmrarad": -0.00000271568383163194,
    "pmdecrad": 2.22820367e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60665,
    "lum": 0.106856267459996,
    "ra": 12.471982,
    "dec": -16.911047,
    "pmra": -560.15,
    "pmdec": 45.96,
    "rv": 81.7,
    "Asset Name": "ASTRID 003482",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Audiohead",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00009018,
    "y_1": 0.00006319,
    "z_1": -0.00001851,
    "Distance in Parsecs_1": 27.1887,
    "HYG Star ID_1": 60665,
    "Hipparcos Catalogue_1": 60853,
    "Henry Draper Catalogue_1": 108564
  },
  {
    "Astrid #": "ASTRID 003483",
    "HYG Star ID": 97494,
    "Hipparcos Catalogue": 97805,
    "Henry Draper Catalogue": 187760,
    "Distance in Parsecs": 27.1961,
    "mag": 9.45,
    "Absolute Visual Magnitude": 7.277,
    "Spectral Type": "K4V",
    "Color Index": 1.155,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.716278,
    "y": -21.919938,
    "z": -11.03961,
    "vx": 0.00000118,
    "vy": 0.00000319,
    "vz": -0.00000507,
    "rarad": 5.20325661115331,
    "decrad": -0.4179920893566,
    "pmrarad": 9.36175217152777e-8,
    "pmdecrad": -2.0415504e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 97494,
    "lum": 0.106954731056615,
    "ra": 19.874976,
    "dec": -23.949183,
    "pmra": 19.31,
    "pmdec": -42.11,
    "rv": 0,
    "Asset Name": "ASTRID 003483",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Bad Dreams",
    "Hair": "Braid",
    "Outfit": "Iron Star",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00000118,
    "y_1": 0.00000319,
    "z_1": -0.00000507,
    "Distance in Parsecs_1": 27.1961,
    "HYG Star ID_1": 97494,
    "Hipparcos Catalogue_1": 97805,
    "Henry Draper Catalogue_1": 187760
  },
  {
    "Astrid #": "ASTRID 003484",
    "HYG Star ID": 60571,
    "Hipparcos Catalogue": 60759,
    "Henry Draper Catalogue": 108421,
    "Gliese Catalog": "NN 3725A",
    "Distance in Parsecs": 27.2109,
    "mag": 8.24,
    "Absolute Visual Magnitude": 6.066,
    "Spectral Type": "K2V",
    "Color Index": 1.042,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.068901,
    "y": -2.873088,
    "z": 12.363937,
    "vx": -0.00001424,
    "vy": -0.00001401,
    "vz": -0.00002873,
    "rarad": 3.26039930517233,
    "decrad": 0.471669655877813,
    "pmrarad": 4.49276837770833e-7,
    "pmdecrad": -0.000001204471107,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 60571,
    "base": "NN 3725",
    "lum": 0.326287172143084,
    "ra": 12.453808,
    "dec": 27.024681,
    "pmra": 92.67,
    "pmdec": -248.44,
    "rv": 1,
    "Asset Name": "ASTRID 003484",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Cute Neko",
    "Hair": "Braided Bun",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00001424,
    "y_1": -0.00001401,
    "z_1": -0.00002873,
    "Distance in Parsecs_1": 27.2109,
    "HYG Star ID_1": 60571,
    "Hipparcos Catalogue_1": 60759,
    "Henry Draper Catalogue_1": 108421,
    "Gliese Catalog_1": "NN 3725A"
  },
  {
    "Astrid #": "ASTRID 003485",
    "HYG Star ID": 1441,
    "Hipparcos Catalogue": 1444,
    "Henry Draper Catalogue": 1388,
    "Gliese Catalog": "Wo 9008",
    "Distance in Parsecs": 27.2183,
    "mag": 6.51,
    "Absolute Visual Magnitude": 4.336,
    "Spectral Type": "G2V",
    "Color Index": 0.599,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 26.389981,
    "y": 2.074756,
    "z": -6.333552,
    "vx": 0.00002384,
    "vy": 0.00005442,
    "vz": -0.00000679,
    "rarad": 0.0784572323668043,
    "decrad": -0.234845225452717,
    "pmrarad": 0.00000192461334906944,
    "pmdecrad": -2.908882e-9,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 1441,
    "lum": 1.60546188680097,
    "ra": 0.299685,
    "dec": -13.45564,
    "pmra": 396.98,
    "pmdec": -0.6,
    "rv": 28.2,
    "Asset Name": "ASTRID 003485",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Battle Ready",
    "Hair": "Elegant",
    "Outfit": "Samurai",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00002384,
    "y_1": 0.00005442,
    "z_1": -0.00000679,
    "Distance in Parsecs_1": 27.2183,
    "HYG Star ID_1": 1441,
    "Hipparcos Catalogue_1": 1444,
    "Henry Draper Catalogue_1": 1388,
    "Gliese Catalog_1": "Wo 9008"
  },
  {
    "Astrid #": "ASTRID 003486",
    "HYG Star ID": 55613,
    "Hipparcos Catalogue": 55779,
    "Henry Draper Catalogue": 99453,
    "Harvard Revised Catalogue": 4413,
    "Gliese Catalog": "NN 3663",
    "Distance in Parsecs": 27.2183,
    "mag": 5.18,
    "Absolute Visual Magnitude": 3.006,
    "Spectral Type": "F7V",
    "Color Index": 0.495,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.810087,
    "y": 1.779831,
    "z": -24.457871,
    "vx": 0.00001608,
    "vy": 0.00003953,
    "vz": 8e-7,
    "rarad": 2.99203107862407,
    "decrad": -1.11653031743682,
    "pmrarad": -0.00000152415724893055,
    "pmdecrad": -3.17649923e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 55613,
    "lum": 5.46512374519872,
    "ra": 11.428717,
    "dec": -63.972475,
    "pmra": -314.38,
    "pmdec": -65.52,
    "rv": -5,
    "Asset Name": "ASTRID 003486",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Owie",
    "Hair": "Fire",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001608,
    "y_1": 0.00003953,
    "z_1": 8e-7,
    "Distance in Parsecs_1": 27.2183,
    "HYG Star ID_1": 55613,
    "Hipparcos Catalogue_1": 55779,
    "Henry Draper Catalogue_1": 99453,
    "Harvard Revised Catalogue_1": 4413,
    "Gliese Catalog_1": "NN 3663"
  },
  {
    "Astrid #": "ASTRID 003487",
    "HYG Star ID": 118607,
    "Gliese Catalog": "Gl 338.1B",
    "Distance in Parsecs": 27.2183,
    "mag": 11,
    "Absolute Visual Magnitude": 8.826,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -4.562149,
    "y": 3.910395,
    "z": 26.546779,
    "vx": 0.00008914,
    "vy": 0.00010606,
    "vz": -2.7e-7,
    "rarad": 2.43386700951398,
    "decrad": 1.34820355606855,
    "pmrarad": -0.00000509034972036111,
    "pmdecrad": -4.4418629e-8,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 2,
    "comp_primary": 45462,
    "base": "Gl 338.1",
    "lum": 0.0256802945066734,
    "ra": 9.296687,
    "dec": 77.246374,
    "pmra": -1049.96,
    "pmdec": -9.16,
    "rv": 0,
    "Asset Name": "ASTRID 003487",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Oof",
    "Hair": "Sweetheart",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00008914,
    "y_1": 0.00010606,
    "z_1": -2.7e-7,
    "Distance in Parsecs_1": 27.2183,
    "HYG Star ID_1": 118607,
    "Gliese Catalog_1": "Gl 338.1B"
  },
  {
    "Astrid #": "ASTRID 003488",
    "HYG Star ID": 56276,
    "Hipparcos Catalogue": 56445,
    "Henry Draper Catalogue": 100563,
    "Harvard Revised Catalogue": 4455,
    "Gliese Catalog": "Wo 9367",
    "Distance in Parsecs": 27.2257,
    "mag": 5.76,
    "Absolute Visual Magnitude": 3.585,
    "Spectral Type": "F5V",
    "Color Index": 0.48,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -27.017021,
    "y": 3.034523,
    "z": 1.453434,
    "vx": -0.00000109,
    "vy": 0.00002424,
    "vz": -0.00001337,
    "rarad": 3.02974250046219,
    "decrad": 0.053409963039915,
    "pmrarad": -8.80518606625e-7,
    "pmdecrad": -4.97709724e-7,
    "flam": 89,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 56276,
    "lum": 3.20626932450546,
    "bf": "89    Leo",
    "ra": 11.572764,
    "dec": 3.060165,
    "pmra": -181.62,
    "pmdec": -102.66,
    "rv": 3,
    "Asset Name": "ASTRID 003488",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Serious Slumber",
    "Hair": "Fire",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000109,
    "y_1": 0.00002424,
    "z_1": -0.00001337,
    "Distance in Parsecs_1": 27.2257,
    "HYG Star ID_1": 56276,
    "Hipparcos Catalogue_1": 56445,
    "Henry Draper Catalogue_1": 100563,
    "Harvard Revised Catalogue_1": 4455,
    "Gliese Catalog_1": "Wo 9367"
  },
  {
    "Astrid #": "ASTRID 003489",
    "HYG Star ID": 83176,
    "Hipparcos Catalogue": 83431,
    "Henry Draper Catalogue": 153580,
    "Harvard Revised Catalogue": 6314,
    "Gliese Catalog": "NN 3985",
    "Distance in Parsecs": 27.2257,
    "mag": 5.27,
    "Absolute Visual Magnitude": 3.095,
    "Spectral Type": "F6V",
    "Color Index": 0.498,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.001009,
    "y": -15.795945,
    "z": -21.811057,
    "vx": 0.00000338,
    "vy": 0.00001273,
    "vz": -0.00001878,
    "rarad": 4.46431296420858,
    "decrad": -0.929161415739627,
    "pmrarad": 5.62383869444444e-9,
    "pmdecrad": -8.00427386e-7,
    "bayer": "Eps-2",
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 83176,
    "lum": 5.03500608787904,
    "bf": "Eps2Ara",
    "ra": 17.052419,
    "dec": -53.237028,
    "pmra": 1.16,
    "pmdec": -165.1,
    "rv": 7,
    "Asset Name": "ASTRID 003489",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Heartbreaker",
    "Outfit": "Skater",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00000338,
    "y_1": 0.00001273,
    "z_1": -0.00001878,
    "Distance in Parsecs_1": 27.2257,
    "HYG Star ID_1": 83176,
    "Hipparcos Catalogue_1": 83431,
    "Henry Draper Catalogue_1": 153580,
    "Harvard Revised Catalogue_1": 6314,
    "Gliese Catalog_1": "NN 3985"
  },
  {
    "Astrid #": "ASTRID 003490",
    "HYG Star ID": 84229,
    "Hipparcos Catalogue": 84487,
    "Gliese Catalog": "NN 3996",
    "Distance in Parsecs": 27.2257,
    "mag": 10.47,
    "Absolute Visual Magnitude": 8.295,
    "Spectral Type": "M0",
    "Color Index": 1.429,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -5.039593,
    "y": -26.131692,
    "z": -5.743357,
    "vx": 0.00001464,
    "vy": -0.00002358,
    "vz": -0.00000737,
    "rarad": 4.5218741104743,
    "decrad": -0.212548851963181,
    "pmrarad": 6.92168491729166e-7,
    "pmdecrad": -1.06852935e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84229,
    "lum": 0.0418793565117918,
    "var_min": 10.523,
    "var_max": 10.393,
    "ra": 17.272287,
    "dec": -12.178152,
    "pmra": 142.77,
    "pmdec": -22.04,
    "rv": 21,
    "Asset Name": "ASTRID 003490",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Einstein Tongue",
    "Hair": "Bangs",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00001464,
    "y_1": -0.00002358,
    "z_1": -0.00000737,
    "Distance in Parsecs_1": 27.2257,
    "HYG Star ID_1": 84229,
    "Hipparcos Catalogue_1": 84487,
    "Gliese Catalog_1": "NN 3996"
  },
  {
    "Astrid #": "ASTRID 003491",
    "HYG Star ID": 96690,
    "Hipparcos Catalogue": 96998,
    "Gliese Catalog": "NN 4119",
    "Distance in Parsecs": 27.2257,
    "mag": 10.03,
    "Absolute Visual Magnitude": 7.855,
    "Spectral Type": "M1",
    "Color Index": 1.347,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.342665,
    "y": -17.319517,
    "z": -19.278822,
    "vx": -0.00001919,
    "vy": 0.00001644,
    "vz": -0.00002307,
    "rarad": 5.16128420984046,
    "decrad": -0.786819596064171,
    "pmrarad": -3.73161089923611e-7,
    "pmdecrad": -0.000001200350191,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 96690,
    "lum": 0.0628058358813317,
    "ra": 19.714653,
    "dec": -45.081442,
    "pmra": -76.97,
    "pmdec": -247.59,
    "rv": 0,
    "Asset Name": "ASTRID 003491",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Innocent",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00001919,
    "y_1": 0.00001644,
    "z_1": -0.00002307,
    "Distance in Parsecs_1": 27.2257,
    "HYG Star ID_1": 96690,
    "Hipparcos Catalogue_1": 96998,
    "Gliese Catalog_1": "NN 4119"
  },
  {
    "Astrid #": "ASTRID 003492",
    "HYG Star ID": 103350,
    "Hipparcos Catalogue": 103682,
    "Henry Draper Catalogue": 199960,
    "Harvard Revised Catalogue": 8041,
    "Distance in Parsecs": 27.2257,
    "mag": 6.21,
    "Absolute Visual Magnitude": 4.035,
    "Spectral Type": "G1V",
    "Color Index": 0.635,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.232955,
    "y": -19.138526,
    "z": -2.245151,
    "vx": -0.00000892,
    "vy": 0.00001774,
    "vz": -0.00001682,
    "rarad": 5.50024805094582,
    "decrad": -0.0825586329410837,
    "pmrarad": 2.30965237416666e-7,
    "pmdecrad": -6.72678981e-7,
    "flam": 11,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 103350,
    "lum": 2.1183611352485,
    "bf": "11    Aqr",
    "ra": 21.0094,
    "dec": -4.730261,
    "pmra": 47.64,
    "pmdec": -138.75,
    "rv": -17,
    "Asset Name": "ASTRID 003492",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Guilty Laugh",
    "Hair": "Heartbreaker",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000892,
    "y_1": 0.00001774,
    "z_1": -0.00001682,
    "Distance in Parsecs_1": 27.2257,
    "HYG Star ID_1": 103350,
    "Hipparcos Catalogue_1": 103682,
    "Henry Draper Catalogue_1": 199960,
    "Harvard Revised Catalogue_1": 8041
  },
  {
    "Astrid #": "ASTRID 003493",
    "HYG Star ID": 1949,
    "Hipparcos Catalogue": 1954,
    "Henry Draper Catalogue": 2071,
    "Distance in Parsecs": 27.2331,
    "mag": 7.27,
    "Absolute Visual Magnitude": 5.095,
    "Spectral Type": "G8IV+...",
    "Color Index": 0.681,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.920389,
    "y": 1.723121,
    "z": -22.027569,
    "vx": -0.00000594,
    "vy": 0.0000272,
    "vz": -0.00000217,
    "rarad": 0.107807663149233,
    "decrad": -0.942198503824025,
    "pmrarad": 0.00000101670276949305,
    "pmdecrad": -1.3540846e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 1949,
    "lum": 0.797994687267976,
    "ra": 0.411795,
    "dec": -53.983998,
    "pmra": 209.71,
    "pmdec": -27.93,
    "rv": 0,
    "Asset Name": "ASTRID 003493",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Unkempt",
    "Outfit": "Viking",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000594,
    "y_1": 0.0000272,
    "z_1": -0.00000217,
    "Distance in Parsecs_1": 27.2331,
    "HYG Star ID_1": 1949,
    "Hipparcos Catalogue_1": 1954,
    "Henry Draper Catalogue_1": 2071
  },
  {
    "Astrid #": "ASTRID 003494",
    "HYG Star ID": 95822,
    "Hipparcos Catalogue": 96124,
    "Henry Draper Catalogue": 183877,
    "Distance in Parsecs": 27.2331,
    "mag": 7.14,
    "Absolute Visual Magnitude": 4.965,
    "Spectral Type": "K0+...",
    "Color Index": 0.675,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.458298,
    "y": -22.101898,
    "z": -12.793307,
    "vx": -0.00002395,
    "vy": 0.00008001,
    "vz": -0.00006777,
    "rarad": 5.11674728105455,
    "decrad": -0.489037876296503,
    "pmrarad": 3.47320520749999e-7,
    "pmdecrad": -0.000003628491029,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 95822,
    "lum": 0.899497581530035,
    "ra": 19.544535,
    "dec": -28.019806,
    "pmra": 71.64,
    "pmdec": -748.43,
    "rv": -40.5,
    "Asset Name": "ASTRID 003494",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Loopy",
    "Hair": "Slick",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00002395,
    "y_1": 0.00008001,
    "z_1": -0.00006777,
    "Distance in Parsecs_1": 27.2331,
    "HYG Star ID_1": 95822,
    "Hipparcos Catalogue_1": 96124,
    "Henry Draper Catalogue_1": 183877
  },
  {
    "Astrid #": "ASTRID 003495",
    "HYG Star ID": 36296,
    "Hipparcos Catalogue": 36399,
    "Henry Draper Catalogue": 59380,
    "Harvard Revised Catalogue": 2866,
    "Gliese Catalog": "NN 3443",
    "Distance in Parsecs": 27.2405,
    "mag": 5.86,
    "Absolute Visual Magnitude": 3.684,
    "Spectral Type": "F8V",
    "Color Index": 0.487,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.271744,
    "y": 24.974509,
    "z": -3.579727,
    "vx": -0.00001054,
    "vy": 0.000008,
    "vz": 0.00001526,
    "rarad": 1.96099664814838,
    "decrad": -0.131792746898663,
    "pmrarad": 2.46188386986111e-7,
    "pmdecrad": 6.10525867e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 36296,
    "lum": 2.92684686237878,
    "ra": 7.490455,
    "dec": -7.551168,
    "pmra": 50.78,
    "pmdec": 125.93,
    "rv": 9.1,
    "Asset Name": "ASTRID 003495",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Bucket Hat",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00001054,
    "y_1": 0.000008,
    "z_1": 0.00001526,
    "Distance in Parsecs_1": 27.2405,
    "HYG Star ID_1": 36296,
    "Hipparcos Catalogue_1": 36399,
    "Henry Draper Catalogue_1": 59380,
    "Harvard Revised Catalogue_1": 2866,
    "Gliese Catalog_1": "NN 3443"
  },
  {
    "Astrid #": "ASTRID 003496",
    "HYG Star ID": 74205,
    "Hipparcos Catalogue": 74432,
    "Henry Draper Catalogue": 135101,
    "Harvard Revised Catalogue": 5659,
    "Gliese Catalog": "NN 3896A",
    "Distance in Parsecs": 27.2405,
    "mag": 6.69,
    "Absolute Visual Magnitude": 4.514,
    "Spectral Type": "G5V",
    "Color Index": 0.68,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.143838,
    "y": -19.161662,
    "z": 8.996996,
    "vx": -0.00002587,
    "vy": 0.00008899,
    "vz": 0.0000232,
    "rarad": 3.98251421723718,
    "decrad": 0.33660467978798,
    "pmrarad": -0.00000288614432171527,
    "pmdecrad": 0.000001398832912,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 74205,
    "base": "NN 3896",
    "lum": 1.36269919699486,
    "var_min": 6.792,
    "var_max": 6.532,
    "ra": 15.212084,
    "dec": 19.286028,
    "pmra": -595.31,
    "pmdec": 288.53,
    "rv": -37.8,
    "Asset Name": "ASTRID 003496",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Oof",
    "Hair": "Headband",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00002587,
    "y_1": 0.00008899,
    "z_1": 0.0000232,
    "Distance in Parsecs_1": 27.2405,
    "HYG Star ID_1": 74205,
    "Hipparcos Catalogue_1": 74432,
    "Henry Draper Catalogue_1": 135101,
    "Harvard Revised Catalogue_1": 5659,
    "Gliese Catalog_1": "NN 3896A"
  },
  {
    "Astrid #": "ASTRID 003497",
    "HYG Star ID": 110404,
    "Hipparcos Catalogue": 110750,
    "Henry Draper Catalogue": 212658,
    "Distance in Parsecs": 27.2405,
    "mag": 9.25,
    "Absolute Visual Magnitude": 7.074,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.135,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 23.603371,
    "y": -10.235436,
    "z": -8.953297,
    "vx": 0.00001136,
    "vy": 0.00002909,
    "vz": -0.00000331,
    "rarad": 5.87401620367413,
    "decrad": -0.33490129228278,
    "pmrarad": 0.00000114590561536111,
    "pmdecrad": -1.28718032e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 110404,
    "lum": 0.128943661995987,
    "ra": 22.437089,
    "dec": -19.188431,
    "pmra": 236.36,
    "pmdec": -26.55,
    "rv": 0,
    "Asset Name": "ASTRID 003497",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Horns",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00001136,
    "y_1": 0.00002909,
    "z_1": -0.00000331,
    "Distance in Parsecs_1": 27.2405,
    "HYG Star ID_1": 110404,
    "Hipparcos Catalogue_1": 110750,
    "Henry Draper Catalogue_1": 212658
  },
  {
    "Astrid #": "ASTRID 003498",
    "HYG Star ID": 42482,
    "Hipparcos Catalogue": 42601,
    "Distance in Parsecs": 27.248,
    "mag": 10.53,
    "Absolute Visual Magnitude": 8.353,
    "Spectral Type": "M1V:",
    "Color Index": 1.36,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.174031,
    "y": 14.385151,
    "z": -19.680293,
    "vx": 0.00000747,
    "vy": -0.00002126,
    "vz": -0.00002016,
    "rarad": 2.27313230378456,
    "decrad": -0.80707254835818,
    "pmrarad": 2.94718236409722e-7,
    "pmdecrad": -0.000001069983792,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 42482,
    "lum": 0.0397008678112183,
    "var_min": 10.587,
    "var_max": 10.457,
    "ra": 8.682726,
    "dec": -46.241851,
    "pmra": 60.79,
    "pmdec": -220.7,
    "rv": 0,
    "Asset Name": "ASTRID 003498",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Smitten",
    "Hair": "Goddess",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000747,
    "y_1": -0.00002126,
    "z_1": -0.00002016,
    "Distance in Parsecs_1": 27.248,
    "HYG Star ID_1": 42482,
    "Hipparcos Catalogue_1": 42601
  },
  {
    "Astrid #": "ASTRID 003499",
    "HYG Star ID": 82138,
    "Hipparcos Catalogue": 82389,
    "Henry Draper Catalogue": 151995,
    "Gliese Catalog": "Gl 640",
    "Distance in Parsecs": 27.2628,
    "mag": 8.85,
    "Absolute Visual Magnitude": 6.672,
    "Spectral Type": "K0",
    "Color Index": 1.02,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.746599,
    "y": -24.602039,
    "z": 8.831071,
    "vx": -0.00000509,
    "vy": 0.00000333,
    "vz": -0.00001034,
    "rarad": 4.40734081316518,
    "decrad": 0.329874538637271,
    "pmrarad": -2.14723979118055e-7,
    "pmdecrad": -3.39127169e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 82138,
    "lum": 0.186723938838294,
    "ra": 16.834802,
    "dec": 18.900419,
    "pmra": -44.29,
    "pmdec": -69.95,
    "rv": -4.8,
    "Asset Name": "ASTRID 003499",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Round Brush",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000509,
    "y_1": 0.00000333,
    "z_1": -0.00001034,
    "Distance in Parsecs_1": 27.2628,
    "HYG Star ID_1": 82138,
    "Hipparcos Catalogue_1": 82389,
    "Henry Draper Catalogue_1": 151995,
    "Gliese Catalog_1": "Gl 640"
  },
  {
    "Astrid #": "ASTRID 003500",
    "HYG Star ID": 10934,
    "Hipparcos Catalogue": 10960,
    "Distance in Parsecs": 27.2702,
    "mag": 10.31,
    "Absolute Visual Magnitude": 8.132,
    "Spectral Type": "K8",
    "Color Index": 1.322,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.219278,
    "y": 9.360262,
    "z": 21.93855,
    "vx": 0.00002461,
    "vy": -0.00002709,
    "vz": -0.00000327,
    "rarad": 0.616133932806627,
    "decrad": 0.934813045429786,
    "pmrarad": -0.00000133236495690277,
    "pmdecrad": -2.01779453e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 10934,
    "lum": 0.0486631256073198,
    "ra": 2.353458,
    "dec": 53.560842,
    "pmra": -274.82,
    "pmdec": -41.62,
    "rv": 0,
    "Asset Name": "ASTRID 003500",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Bucket Hat",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00002461,
    "y_1": -0.00002709,
    "z_1": -0.00000327,
    "Distance in Parsecs_1": 27.2702,
    "HYG Star ID_1": 10934,
    "Hipparcos Catalogue_1": 10960
  },
  {
    "Astrid #": "ASTRID 003501",
    "HYG Star ID": 79938,
    "Hipparcos Catalogue": 80179,
    "Henry Draper Catalogue": 147449,
    "Harvard Revised Catalogue": 6093,
    "Gliese Catalog": "NN 3952",
    "Distance in Parsecs": 27.2702,
    "mag": 4.82,
    "Absolute Visual Magnitude": 2.642,
    "Spectral Type": "F0V",
    "Color Index": 0.338,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.298928,
    "y": -24.814028,
    "z": 0.489742,
    "vx": 5.6e-7,
    "vy": 0.00005099,
    "vz": 0.00000552,
    "rarad": 4.28509957586904,
    "decrad": 0.0179601190353574,
    "pmrarad": -7.56357823034722e-7,
    "pmdecrad": 2.33146898e-7,
    "bayer": "Sig",
    "flam": 50,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 79938,
    "lum": 7.64187623963467,
    "bf": "50Sig Ser",
    "ra": 16.367875,
    "dec": 1.029039,
    "pmra": -156.01,
    "pmdec": 48.09,
    "rv": -45.5,
    "Asset Name": "ASTRID 003501",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Side Part",
    "Outfit": "Suit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 5.6e-7,
    "y_1": 0.00005099,
    "z_1": 0.00000552,
    "Distance in Parsecs_1": 27.2702,
    "HYG Star ID_1": 79938,
    "Hipparcos Catalogue_1": 80179,
    "Henry Draper Catalogue_1": 147449,
    "Harvard Revised Catalogue_1": 6093,
    "Gliese Catalog_1": "NN 3952"
  },
  {
    "Astrid #": "ASTRID 003502",
    "HYG Star ID": 112575,
    "Hipparcos Catalogue": 112935,
    "Henry Draper Catalogue": 216385,
    "Harvard Revised Catalogue": 8697,
    "Gliese Catalog": "Wo 9801A",
    "Distance in Parsecs": 27.2777,
    "mag": 5.16,
    "Absolute Visual Magnitude": 2.981,
    "Spectral Type": "F7IV",
    "Color Index": 0.487,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.716183,
    "y": -7.81302,
    "z": 4.65967,
    "vx": 0.00002894,
    "vy": 0.00006333,
    "vz": 0.00000753,
    "rarad": 5.98822981292589,
    "decrad": 0.171664728774788,
    "pmrarad": 0.00000253004867334722,
    "pmdecrad": 2.13802833e-7,
    "bayer": "Sig",
    "flam": 49,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112575,
    "base": "Wo 9801",
    "lum": 5.592422830409,
    "bf": "49Sig Peg",
    "ra": 22.873353,
    "dec": 9.835664,
    "pmra": 521.86,
    "pmdec": 44.1,
    "rv": 10.2,
    "Asset Name": "ASTRID 003502",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Good Boy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00002894,
    "y_1": 0.00006333,
    "z_1": 0.00000753,
    "Distance in Parsecs_1": 27.2777,
    "HYG Star ID_1": 112575,
    "Hipparcos Catalogue_1": 112935,
    "Henry Draper Catalogue_1": 216385,
    "Harvard Revised Catalogue_1": 8697,
    "Gliese Catalog_1": "Wo 9801A"
  },
  {
    "Astrid #": "ASTRID 003503",
    "HYG Star ID": 90697,
    "Hipparcos Catalogue": 90979,
    "Gliese Catalog": "Gl 717",
    "Distance in Parsecs": 27.2851,
    "mag": 10.03,
    "Absolute Visual Magnitude": 7.85,
    "Spectral Type": "K7V",
    "Color Index": 1.263,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.890088,
    "y": -26.438967,
    "z": -5.50309,
    "vx": -0.00005052,
    "vy": 0.00008372,
    "vz": -0.00001353,
    "rarad": 4.85847571014294,
    "decrad": -0.203087193366958,
    "pmrarad": -0.0000013853550921875,
    "pmdecrad": -0.000001152499081,
    "con": "Sct",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scutum",
    "FULL CONSTELLATION NAME": "Scutum",
    "comp": 1,
    "comp_primary": 90697,
    "lum": 0.0630957344480193,
    "ra": 18.55801,
    "dec": -11.636039,
    "pmra": -285.75,
    "pmdec": -237.72,
    "rv": -83.7,
    "Asset Name": "ASTRID 003503",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Loopy",
    "Hair": "Honest Lad",
    "Outfit": "River Spirit",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scutum",
    "x_1": -0.00005052,
    "y_1": 0.00008372,
    "z_1": -0.00001353,
    "Distance in Parsecs_1": 27.2851,
    "HYG Star ID_1": 90697,
    "Hipparcos Catalogue_1": 90979,
    "Gliese Catalog_1": "Gl 717"
  },
  {
    "Astrid #": "ASTRID 003504",
    "HYG Star ID": 70716,
    "Hipparcos Catalogue": 70940,
    "Distance in Parsecs": 27.2926,
    "mag": 10.15,
    "Absolute Visual Magnitude": 7.97,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -10.51877,
    "y": -8.114786,
    "z": -23.840968,
    "vx": -0.00000473,
    "vy": 0.00001247,
    "vz": -0.00000216,
    "rarad": 3.79869017605133,
    "decrad": -1.06241240326167,
    "pmrarad": -4.67651276263888e-7,
    "pmdecrad": -1.6231562e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 70716,
    "lum": 0.0564936974812302,
    "ra": 14.509928,
    "dec": -60.871747,
    "pmra": -96.46,
    "pmdec": -33.48,
    "rv": 0,
    "Asset Name": "ASTRID 003504",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Mohawk",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000473,
    "y_1": 0.00001247,
    "z_1": -0.00000216,
    "Distance in Parsecs_1": 27.2926,
    "HYG Star ID_1": 70716,
    "Hipparcos Catalogue_1": 70940
  },
  {
    "Astrid #": "ASTRID 003505",
    "HYG Star ID": 102100,
    "Hipparcos Catalogue": 102431,
    "Henry Draper Catalogue": 198084,
    "Harvard Revised Catalogue": 7955,
    "Gliese Catalog": "Wo 9706",
    "Distance in Parsecs": 27.2926,
    "mag": 4.52,
    "Absolute Visual Magnitude": 2.34,
    "Spectral Type": "F8IV-V",
    "Color Index": 0.535,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.664508,
    "y": -10.986162,
    "z": 23.038483,
    "vx": -6.1e-7,
    "vy": -0.00001192,
    "vz": -0.00004468,
    "rarad": 5.43387608158564,
    "decrad": 1.00495579026283,
    "pmrarad": -3.05190211909722e-7,
    "pmdecrad": -0.000001142027105,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 102100,
    "lum": 10.0925288607668,
    "ra": 20.755878,
    "dec": 57.579725,
    "pmra": -62.95,
    "pmdec": -235.56,
    "rv": -32.4,
    "Asset Name": "ASTRID 003505",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Lion Mane",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -6.1e-7,
    "y_1": -0.00001192,
    "z_1": -0.00004468,
    "Distance in Parsecs_1": 27.2926,
    "HYG Star ID_1": 102100,
    "Hipparcos Catalogue_1": 102431,
    "Henry Draper Catalogue_1": 198084,
    "Harvard Revised Catalogue_1": 7955,
    "Gliese Catalog_1": "Wo 9706"
  },
  {
    "Astrid #": "ASTRID 003506",
    "HYG Star ID": 14442,
    "Hipparcos Catalogue": 14478,
    "Gliese Catalog": "NN 3199A",
    "Distance in Parsecs": 27.3,
    "mag": 9.66,
    "Absolute Visual Magnitude": 7.479,
    "Spectral Type": "K6",
    "Color Index": 1.19,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.262856,
    "y": 15.142747,
    "z": 17.678609,
    "vx": 0.00002108,
    "vy": -0.00005171,
    "vz": -0.00005342,
    "rarad": 0.815316845623512,
    "decrad": 0.704402858621443,
    "pmrarad": -0.0000018609573128125,
    "pmdecrad": -9.41071835e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 14442,
    "base": "NN 3199",
    "lum": 0.0887973489325766,
    "var": "V568",
    "var_min": 9.78,
    "var_max": 9.62,
    "ra": 3.114281,
    "dec": 40.359311,
    "pmra": -383.85,
    "pmdec": -194.11,
    "rv": -51.1,
    "Asset Name": "ASTRID 003506",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh No",
    "Hair": "Fedora",
    "Outfit": "Astro",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00002108,
    "y_1": -0.00005171,
    "z_1": -0.00005342,
    "Distance in Parsecs_1": 27.3,
    "HYG Star ID_1": 14442,
    "Hipparcos Catalogue_1": 14478,
    "Gliese Catalog_1": "NN 3199A"
  },
  {
    "Astrid #": "ASTRID 003507",
    "HYG Star ID": 93788,
    "Hipparcos Catalogue": 94083,
    "Henry Draper Catalogue": 180777,
    "Harvard Revised Catalogue": 7312,
    "Gliese Catalog": "Gl 748.1",
    "Distance in Parsecs": 27.3,
    "mag": 5.11,
    "Absolute Visual Magnitude": 2.929,
    "Spectral Type": "A9V",
    "Color Index": 0.308,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 1.885917,
    "y": -6.058273,
    "z": 26.552421,
    "vx": 0.00001116,
    "vy": -0.00001287,
    "vz": -0.00000289,
    "rarad": 5.0141693892064,
    "decrad": 1.33623283794059,
    "pmrarad": 2.50309303270833e-7,
    "pmdecrad": -5.80806789e-7,
    "flam": 59,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 93788,
    "lum": 5.86678266401976,
    "bf": "59    Dra",
    "ra": 19.152716,
    "dec": 76.560502,
    "pmra": 51.63,
    "pmdec": -119.8,
    "rv": 0.8,
    "Asset Name": "ASTRID 003507",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Hachimaki",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001116,
    "y_1": -0.00001287,
    "z_1": -0.00000289,
    "Distance in Parsecs_1": 27.3,
    "HYG Star ID_1": 93788,
    "Hipparcos Catalogue_1": 94083,
    "Henry Draper Catalogue_1": 180777,
    "Harvard Revised Catalogue_1": 7312,
    "Gliese Catalog_1": "Gl 748.1"
  },
  {
    "Astrid #": "ASTRID 003508",
    "HYG Star ID": 61914,
    "Hipparcos Catalogue": 62107,
    "Henry Draper Catalogue": 110619,
    "Distance in Parsecs": 27.3075,
    "mag": 7.53,
    "Absolute Visual Magnitude": 5.349,
    "Spectral Type": "G5V",
    "Color Index": 0.664,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.212016,
    "y": -4.095549,
    "z": -16.702203,
    "vx": 0.00002183,
    "vy": 0.00008875,
    "vz": -0.00000552,
    "rarad": 3.33232957675787,
    "decrad": -0.658131323846072,
    "pmrarad": -0.00000303968481434722,
    "pmdecrad": -0.000001016896694,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 61914,
    "lum": 0.631538745374385,
    "ra": 12.728561,
    "dec": -37.708147,
    "pmra": -626.98,
    "pmdec": -209.75,
    "rv": -26.3,
    "Asset Name": "ASTRID 003508",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Fired Up",
    "Hair": "Sweetheart",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00002183,
    "y_1": 0.00008875,
    "z_1": -0.00000552,
    "Distance in Parsecs_1": 27.3075,
    "HYG Star ID_1": 61914,
    "Hipparcos Catalogue_1": 62107,
    "Henry Draper Catalogue_1": 110619
  },
  {
    "Astrid #": "ASTRID 003509",
    "HYG Star ID": 21439,
    "Hipparcos Catalogue": 21492,
    "Distance in Parsecs": 27.3149,
    "mag": 12.35,
    "Absolute Visual Magnitude": 10.168,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 4.932527,
    "y": 13.014757,
    "z": 23.50298,
    "vx": -0.00000976,
    "vy": 0.00002066,
    "vz": -0.00000939,
    "rarad": 1.20852280271267,
    "decrad": 1.03614310296937,
    "pmrarad": 6.0213859125e-7,
    "pmdecrad": -6.74860643e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 21439,
    "lum": 0.00746105085193063,
    "var_min": 12.606,
    "var_max": 12.166,
    "ra": 4.616217,
    "dec": 59.366627,
    "pmra": 124.2,
    "pmdec": -139.2,
    "rv": 0,
    "Asset Name": "ASTRID 003509",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Upset",
    "Hair": "Innocent",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000976,
    "y_1": 0.00002066,
    "z_1": -0.00000939,
    "Distance in Parsecs_1": 27.3149,
    "HYG Star ID_1": 21439,
    "Hipparcos Catalogue_1": 21492
  },
  {
    "Astrid #": "ASTRID 003510",
    "HYG Star ID": 72942,
    "Hipparcos Catalogue": 73169,
    "Distance in Parsecs": 27.3149,
    "mag": 11.82,
    "Absolute Visual Magnitude": 9.638,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.429493,
    "y": -18.962226,
    "z": -3.005418,
    "vx": -0.00002337,
    "vy": 0.00002551,
    "vz": -0.00000985,
    "rarad": 3.91482042628017,
    "decrad": -0.110251766650808,
    "pmrarad": -0.00000126613940813888,
    "pmdecrad": -3.62931521e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 72942,
    "lum": 0.0121562605523737,
    "var_min": 11.914,
    "var_max": 11.444,
    "ra": 14.953513,
    "dec": -6.316961,
    "pmra": -261.16,
    "pmdec": -74.86,
    "rv": 0,
    "Asset Name": "ASTRID 003510",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Braided Ponytail",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00002337,
    "y_1": 0.00002551,
    "z_1": -0.00000985,
    "Distance in Parsecs_1": 27.3149,
    "HYG Star ID_1": 72942,
    "Hipparcos Catalogue_1": 73169
  },
  {
    "Astrid #": "ASTRID 003511",
    "HYG Star ID": 102286,
    "Hipparcos Catalogue": 102616,
    "Distance in Parsecs": 27.3299,
    "mag": 10.76,
    "Absolute Visual Magnitude": 8.577,
    "Spectral Type": "K6",
    "Color Index": 1.365,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.964462,
    "y": -6.642729,
    "z": 25.830656,
    "vx": -0.00001177,
    "vy": 0.0000797,
    "vz": 0.00002322,
    "rarad": 5.44400962903796,
    "decrad": 1.2380312291543,
    "pmrarad": 0.00000162800433930555,
    "pmdecrad": 0.000002600589063,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 102286,
    "lum": 0.0322998124059562,
    "var_min": 10.836,
    "var_max": 10.686,
    "ra": 20.794585,
    "dec": 70.933964,
    "pmra": 335.8,
    "pmdec": 536.41,
    "rv": 0,
    "Asset Name": "ASTRID 003511",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "High Fade",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001177,
    "y_1": 0.0000797,
    "z_1": 0.00002322,
    "Distance in Parsecs_1": 27.3299,
    "HYG Star ID_1": 102286,
    "Hipparcos Catalogue_1": 102616
  },
  {
    "Astrid #": "ASTRID 003512",
    "HYG Star ID": 10654,
    "Hipparcos Catalogue": 10679,
    "Distance in Parsecs": 27.3373,
    "mag": 7.75,
    "Absolute Visual Magnitude": 5.566,
    "Spectral Type": "G2V",
    "Color Index": 0.622,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.78841,
    "y": 13.52563,
    "z": 13.145499,
    "vx": -0.00000379,
    "vy": 0.00001316,
    "vz": -0.00000783,
    "rarad": 0.59957421464049,
    "decrad": 0.501638835387107,
    "pmrarad": 4.75844627465277e-7,
    "pmdecrad": -3.26812902e-7,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 10654,
    "lum": 0.517130317916962,
    "ra": 2.290205,
    "dec": 28.741788,
    "pmra": 98.15,
    "pmdec": -67.41,
    "rv": 0,
    "Asset Name": "ASTRID 003512",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "High Bun",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.00000379,
    "y_1": 0.00001316,
    "z_1": -0.00000783,
    "Distance in Parsecs_1": 27.3373,
    "HYG Star ID_1": 10654,
    "Hipparcos Catalogue_1": 10679
  },
  {
    "Astrid #": "ASTRID 003513",
    "HYG Star ID": 64016,
    "Hipparcos Catalogue": 64219,
    "Henry Draper Catalogue": 114260,
    "Gliese Catalog": "Gl 500",
    "Distance in Parsecs": 27.3373,
    "mag": 7.36,
    "Absolute Visual Magnitude": 5.176,
    "Spectral Type": "G6V",
    "Color Index": 0.718,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -24.150159,
    "y": -7.580821,
    "z": -10.325835,
    "vx": 0.00003281,
    "vy": -0.00000855,
    "vz": -0.00003688,
    "rarad": 3.44575525270801,
    "decrad": -0.387333905814298,
    "pmrarad": 6.57795201777777e-7,
    "pmdecrad": -0.000001646378777,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 64016,
    "lum": 0.740627783668154,
    "ra": 13.161816,
    "dec": -22.192598,
    "pmra": 135.68,
    "pmdec": -339.59,
    "rv": -12.4,
    "Asset Name": "ASTRID 003513",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Upset",
    "Hair": "Shinobi",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 0.00003281,
    "y_1": -0.00000855,
    "z_1": -0.00003688,
    "Distance in Parsecs_1": 27.3373,
    "HYG Star ID_1": 64016,
    "Hipparcos Catalogue_1": 64219,
    "Henry Draper Catalogue_1": 114260,
    "Gliese Catalog_1": "Gl 500"
  },
  {
    "Astrid #": "ASTRID 003514",
    "HYG Star ID": 21907,
    "Hipparcos Catalogue": 21960,
    "Henry Draper Catalogue": 30278,
    "Distance in Parsecs": 27.3448,
    "mag": 7.61,
    "Absolute Visual Magnitude": 5.426,
    "Spectral Type": "G8V",
    "Color Index": 0.746,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.208364,
    "y": 14.962284,
    "z": -22.287689,
    "vx": -0.00001337,
    "vy": -0.00001806,
    "vz": -0.00001525,
    "rarad": 1.2358140435736,
    "decrad": -0.952835038607606,
    "pmrarad": 2.44636983208333e-7,
    "pmdecrad": -9.62355155e-7,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 21907,
    "lum": 0.588301559988882,
    "ra": 4.720462,
    "dec": -54.593426,
    "pmra": 50.46,
    "pmdec": -198.5,
    "rv": 0,
    "Asset Name": "ASTRID 003514",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Stunned",
    "Hair": "Little Crown",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.00001337,
    "y_1": -0.00001806,
    "z_1": -0.00001525,
    "Distance in Parsecs_1": 27.3448,
    "HYG Star ID_1": 21907,
    "Hipparcos Catalogue_1": 21960,
    "Henry Draper Catalogue_1": 30278
  },
  {
    "Astrid #": "ASTRID 003515",
    "HYG Star ID": 8498,
    "Hipparcos Catalogue": 8514,
    "Henry Draper Catalogue": 11262,
    "Harvard Revised Catalogue": 535,
    "Distance in Parsecs": 27.3523,
    "mag": 6.37,
    "Absolute Visual Magnitude": 4.185,
    "Spectral Type": "F6V",
    "Color Index": 0.523,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.021213,
    "y": 9.882247,
    "z": -16.990989,
    "vx": 0.00003616,
    "vy": 0.0000191,
    "vz": 0.00001536,
    "rarad": 0.479155695084513,
    "decrad": -0.670255241526596,
    "pmrarad": 1.01810872916666e-8,
    "pmdecrad": 0.000001368968389,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 8498,
    "lum": 1.84501541917947,
    "ra": 1.83024,
    "dec": -38.402797,
    "pmra": 2.1,
    "pmdec": 282.37,
    "rv": 22,
    "Asset Name": "ASTRID 003515",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Little Crown",
    "Outfit": "Star Knight",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00003616,
    "y_1": 0.0000191,
    "z_1": 0.00001536,
    "Distance in Parsecs_1": 27.3523,
    "HYG Star ID_1": 8498,
    "Hipparcos Catalogue_1": 8514,
    "Henry Draper Catalogue_1": 11262,
    "Harvard Revised Catalogue_1": 535
  },
  {
    "Astrid #": "ASTRID 003516",
    "HYG Star ID": 107819,
    "Hipparcos Catalogue": 108162,
    "Henry Draper Catalogue": 207496,
    "Gliese Catalog": "NN 4229A",
    "Distance in Parsecs": 27.3523,
    "mag": 8.23,
    "Absolute Visual Magnitude": 6.045,
    "Spectral Type": "K3IV",
    "Color Index": 1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.123725,
    "y": -3.113592,
    "z": -26.687003,
    "vx": -0.00000719,
    "vy": 0.00003908,
    "vz": 0.00000517,
    "rarad": 5.73710310562178,
    "decrad": -1.34980264203949,
    "pmrarad": 0.00000108452820340277,
    "pmdecrad": -9.01947371e-7,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 107819,
    "base": "NN 4229",
    "lum": 0.332659553294004,
    "ra": 21.91412,
    "dec": -77.337995,
    "pmra": 223.7,
    "pmdec": -186.04,
    "rv": -10.6,
    "Asset Name": "ASTRID 003516",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Determined Shinobi",
    "Hair": "High Fade",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": -0.00000719,
    "y_1": 0.00003908,
    "z_1": 0.00000517,
    "Distance in Parsecs_1": 27.3523,
    "HYG Star ID_1": 107819,
    "Hipparcos Catalogue_1": 108162,
    "Henry Draper Catalogue_1": 207496,
    "Gliese Catalog_1": "NN 4229A"
  },
  {
    "Astrid #": "ASTRID 003517",
    "HYG Star ID": 50800,
    "Hipparcos Catalogue": 50944,
    "Gliese Catalog": "Gl 389.1",
    "Distance in Parsecs": 27.3673,
    "mag": 9.98,
    "Absolute Visual Magnitude": 7.794,
    "Spectral Type": "K4.5",
    "Color Index": 1.22,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.60196,
    "y": 10.921478,
    "z": -4.943078,
    "vx": -0.00000971,
    "vy": -0.0000365,
    "vz": -0.0000323,
    "rarad": 2.72380019356797,
    "decrad": -0.181616613791536,
    "pmrarad": 0.00000136315062561805,
    "pmdecrad": -0.000001200204747,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 50800,
    "lum": 0.0664354681976005,
    "ra": 10.40415,
    "dec": -10.405865,
    "pmra": 281.17,
    "pmdec": -247.56,
    "rv": 0,
    "Asset Name": "ASTRID 003517",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Innocent",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": -0.00000971,
    "y_1": -0.0000365,
    "z_1": -0.0000323,
    "Distance in Parsecs_1": 27.3673,
    "HYG Star ID_1": 50800,
    "Hipparcos Catalogue_1": 50944,
    "Gliese Catalog_1": "Gl 389.1"
  },
  {
    "Astrid #": "ASTRID 003518",
    "HYG Star ID": 7587,
    "Hipparcos Catalogue": 7601,
    "Henry Draper Catalogue": 10800,
    "Harvard Revised Catalogue": 512,
    "Gliese Catalog": "Gl 67.1",
    "Distance in Parsecs": 27.3823,
    "mag": 5.88,
    "Absolute Visual Magnitude": 3.693,
    "Spectral Type": "G2V",
    "Color Index": 0.62,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.047844,
    "y": 1.387795,
    "z": -27.176786,
    "vx": 0.00000836,
    "vy": 0.00002164,
    "vz": -0.00000352,
    "rarad": 0.427245792112399,
    "decrad": -1.44818686476152,
    "pmrarad": 5.92927131319444e-7,
    "pmdecrad": 5.85170112e-7,
    "con": "Oct",
    "Full Constellation Name Formula 1": "Octans",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Octans",
    "comp": 1,
    "comp_primary": 7587,
    "lum": 2.90268561049905,
    "ra": 1.631959,
    "dec": -82.974995,
    "pmra": 122.3,
    "pmdec": 120.7,
    "rv": 5.4,
    "Asset Name": "ASTRID 003518",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Good Boy",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Octans",
    "x_1": 0.00000836,
    "y_1": 0.00002164,
    "z_1": -0.00000352,
    "Distance in Parsecs_1": 27.3823,
    "HYG Star ID_1": 7587,
    "Hipparcos Catalogue_1": 7601,
    "Henry Draper Catalogue_1": 10800,
    "Harvard Revised Catalogue_1": 512,
    "Gliese Catalog_1": "Gl 67.1"
  },
  {
    "Astrid #": "ASTRID 003519",
    "HYG Star ID": 83021,
    "Hipparcos Catalogue": 83276,
    "Henry Draper Catalogue": 153631,
    "Gliese Catalog": "Gl 650",
    "Distance in Parsecs": 27.3823,
    "mag": 7.12,
    "Absolute Visual Magnitude": 4.933,
    "Spectral Type": "G2V",
    "Color Index": 0.608,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.757095,
    "y": -25.747034,
    "z": -6.423637,
    "vx": -0.0000204,
    "vy": -0.00006996,
    "vz": -0.00006173,
    "rarad": 4.45573525162101,
    "decrad": -0.236791074742482,
    "pmrarad": -7.20433129305555e-8,
    "pmdecrad": -0.000001567741998,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 83021,
    "lum": 0.926403100736399,
    "ra": 17.019655,
    "dec": -13.567129,
    "pmra": -14.86,
    "pmdec": -323.37,
    "rv": 83.4,
    "Asset Name": "ASTRID 003519",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Laugh",
    "Hair": "Audiohead",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.0000204,
    "y_1": -0.00006996,
    "z_1": -0.00006173,
    "Distance in Parsecs_1": 27.3823,
    "HYG Star ID_1": 83021,
    "Hipparcos Catalogue_1": 83276,
    "Henry Draper Catalogue_1": 153631,
    "Gliese Catalog_1": "Gl 650"
  },
  {
    "Astrid #": "ASTRID 003520",
    "HYG Star ID": 103962,
    "Hipparcos Catalogue": 104299,
    "Henry Draper Catalogue": 200505,
    "Distance in Parsecs": 27.3823,
    "mag": 8.55,
    "Absolute Visual Magnitude": 6.363,
    "Spectral Type": "K1V",
    "Color Index": 0.84,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.520553,
    "y": -7.027438,
    "z": -25.374135,
    "vx": 0.00000905,
    "vy": 0.00002996,
    "vz": -0.00000562,
    "rarad": 5.5316550665379,
    "decrad": -1.18543308708151,
    "pmrarad": 0.00000102509004616666,
    "pmdecrad": -5.45609316e-7,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 103962,
    "lum": 0.248198984350709,
    "ra": 21.129366,
    "dec": -67.920313,
    "pmra": 211.44,
    "pmdec": -112.54,
    "rv": 0,
    "Asset Name": "ASTRID 003520",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Maniacal",
    "Hair": "Skater Helmet",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00000905,
    "y_1": 0.00002996,
    "z_1": -0.00000562,
    "Distance in Parsecs_1": 27.3823,
    "HYG Star ID_1": 103962,
    "Hipparcos Catalogue_1": 104299,
    "Henry Draper Catalogue_1": 200505
  },
  {
    "Astrid #": "ASTRID 003521",
    "HYG Star ID": 95197,
    "Hipparcos Catalogue": 95492,
    "Henry Draper Catalogue": 182807,
    "Harvard Revised Catalogue": 7386,
    "Distance in Parsecs": 27.3898,
    "mag": 6.19,
    "Absolute Visual Magnitude": 4.002,
    "Spectral Type": "F7V",
    "Color Index": 0.528,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.046729,
    "y": -23.135242,
    "z": 11.53761,
    "vx": -0.00001041,
    "vy": -0.00003799,
    "vz": -0.00007772,
    "rarad": 5.08514535596388,
    "decrad": 0.434809938830968,
    "pmrarad": -8.59089841944444e-7,
    "pmdecrad": -0.000003059562175,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 95197,
    "lum": 2.18373532760217,
    "ra": 19.423824,
    "dec": 24.912774,
    "pmra": -177.2,
    "pmdec": -631.08,
    "rv": -4,
    "Asset Name": "ASTRID 003521",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Hurt",
    "Hair": "Undercut",
    "Outfit": "Skater",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": -0.00001041,
    "y_1": -0.00003799,
    "z_1": -0.00007772,
    "Distance in Parsecs_1": 27.3898,
    "HYG Star ID_1": 95197,
    "Hipparcos Catalogue_1": 95492,
    "Henry Draper Catalogue_1": 182807,
    "Harvard Revised Catalogue_1": 7386
  },
  {
    "Astrid #": "ASTRID 003522",
    "HYG Star ID": 23820,
    "Hipparcos Catalogue": 23875,
    "Henry Draper Catalogue": 33111,
    "Harvard Revised Catalogue": 1666,
    "Gliese Catalog": "Wo 9175",
    "Proper Name": "Cursa",
    "Distance in Parsecs": 27.3973,
    "mag": 2.78,
    "Absolute Visual Magnitude": 0.591,
    "Spectral Type": "A3IIIvar",
    "Color Index": 0.161,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 6.156194,
    "y": 26.585876,
    "z": -2.429001,
    "vx": 0.00000848,
    "vy": -0.00001249,
    "vz": -0.00000915,
    "rarad": 1.34324795349637,
    "decrad": -0.0887752322551,
    "pmrarad": -4.04286128215277e-7,
    "pmdecrad": -3.6574344e-7,
    "bayer": "Bet",
    "flam": 67,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 23820,
    "lum": 50.5358994753167,
    "bf": "67Bet Eri",
    "ra": 5.130829,
    "dec": -5.086446,
    "pmra": -83.39,
    "pmdec": -75.44,
    "rv": -9.2,
    "Asset Name": "ASTRID 003522",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Laughing",
    "Hair": "Tiny Crown",
    "Outfit": "Garb",
    "Special": "Astral Burst",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Cursa",
    "Constellation": "Eridanus",
    "x_1": 0.00000848,
    "y_1": -0.00001249,
    "z_1": -0.00000915,
    "Distance in Parsecs_1": 27.3973,
    "HYG Star ID_1": 23820,
    "Hipparcos Catalogue_1": 23875,
    "Henry Draper Catalogue_1": 33111,
    "Harvard Revised Catalogue_1": 1666,
    "Gliese Catalog_1": "Wo 9175"
  },
  {
    "Astrid #": "ASTRID 003523",
    "HYG Star ID": 55821,
    "Hipparcos Catalogue": 55988,
    "Distance in Parsecs": 27.3973,
    "mag": 10.21,
    "Absolute Visual Magnitude": 8.021,
    "Spectral Type": "M0:",
    "Color Index": 1.193,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -26.905068,
    "y": 3.725916,
    "z": 3.584259,
    "vx": -0.00001601,
    "vy": 0.0000388,
    "vz": -0.00016047,
    "rarad": 3.00398420152796,
    "decrad": 0.131201328438738,
    "pmrarad": -0.00000132291109013194,
    "pmdecrad": -0.000005908521287,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 55821,
    "lum": 0.053901394363255,
    "ra": 11.474374,
    "dec": 7.517282,
    "pmra": -272.87,
    "pmdec": -1218.72,
    "rv": 0,
    "Asset Name": "ASTRID 003523",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Worried Vamp",
    "Hair": "Goddess",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00001601,
    "y_1": 0.0000388,
    "z_1": -0.00016047,
    "Distance in Parsecs_1": 27.3973,
    "HYG Star ID_1": 55821,
    "Hipparcos Catalogue_1": 55988
  },
  {
    "Astrid #": "ASTRID 003524",
    "HYG Star ID": 81881,
    "Hipparcos Catalogue": 82132,
    "Gliese Catalog": "Wo 9577",
    "Distance in Parsecs": 27.3973,
    "mag": 10.59,
    "Absolute Visual Magnitude": 8.401,
    "Color Index": 1.389,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.406827,
    "y": -22.380482,
    "z": -13.96016,
    "vx": -0.00002152,
    "vy": -0.00002141,
    "vz": -0.00004056,
    "rarad": 4.39278540713246,
    "decrad": -0.534647716607146,
    "pmrarad": -5.00327718333333e-7,
    "pmdecrad": -7.70126531e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 81881,
    "lum": 0.0379839390154507,
    "var_min": 10.648,
    "var_max": 10.538,
    "ra": 16.779204,
    "dec": -30.633058,
    "pmra": -103.2,
    "pmdec": -158.85,
    "rv": 43,
    "Asset Name": "ASTRID 003524",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Serious Shinobi",
    "Hair": "Good Boy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00002152,
    "y_1": -0.00002141,
    "z_1": -0.00004056,
    "Distance in Parsecs_1": 27.3973,
    "HYG Star ID_1": 81881,
    "Hipparcos Catalogue_1": 82132,
    "Gliese Catalog_1": "Wo 9577"
  },
  {
    "Astrid #": "ASTRID 003525",
    "HYG Star ID": 28826,
    "Hipparcos Catalogue": 28898,
    "Henry Draper Catalogue": 42286,
    "Gliese Catalog": "Wo 9202",
    "Distance in Parsecs": 27.4048,
    "mag": 8.45,
    "Absolute Visual Magnitude": 6.261,
    "Spectral Type": "K0V",
    "Color Index": 0.844,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.367171,
    "y": 13.88645,
    "z": -23.623446,
    "vx": 0.00003176,
    "vy": -0.00007105,
    "vz": -0.0000724,
    "rarad": 1.59724042059384,
    "decrad": -1.0392245461325,
    "pmrarad": -0.00000109010356072916,
    "pmdecrad": -0.000003600129429,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 28826,
    "lum": 0.2726465458529,
    "ra": 6.101009,
    "dec": -59.54318,
    "pmra": -224.85,
    "pmdec": -742.58,
    "rv": 25.4,
    "Asset Name": "ASTRID 003525",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Glad",
    "Hair": "Good Boy",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00003176,
    "y_1": -0.00007105,
    "z_1": -0.0000724,
    "Distance in Parsecs_1": 27.4048,
    "HYG Star ID_1": 28826,
    "Hipparcos Catalogue_1": 28898,
    "Henry Draper Catalogue_1": 42286,
    "Gliese Catalog_1": "Wo 9202"
  },
  {
    "Astrid #": "ASTRID 003526",
    "HYG Star ID": 42172,
    "Hipparcos Catalogue": 42291,
    "Henry Draper Catalogue": 73524,
    "Harvard Revised Catalogue": 3421,
    "Gliese Catalog": "Gl 312",
    "Distance in Parsecs": 27.4048,
    "mag": 6.53,
    "Absolute Visual Magnitude": 4.341,
    "Spectral Type": "G1V",
    "Color Index": 0.598,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.277366,
    "y": 16.202546,
    "z": -17.669504,
    "vx": 0.00003033,
    "vy": 0.0000269,
    "vz": 0.00000425,
    "rarad": 2.2572959913528,
    "decrad": -0.700708952431152,
    "pmrarad": -0.00000147824539338194,
    "pmdecrad": 1.55722154e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 42172,
    "lum": 1.59808545947309,
    "ra": 8.622236,
    "dec": -40.147666,
    "pmra": -304.91,
    "pmdec": 32.12,
    "rv": -1.5,
    "Asset Name": "ASTRID 003526",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Anxious Laugh",
    "Hair": "Lion Mane",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00003033,
    "y_1": 0.0000269,
    "z_1": 0.00000425,
    "Distance in Parsecs_1": 27.4048,
    "HYG Star ID_1": 42172,
    "Hipparcos Catalogue_1": 42291,
    "Henry Draper Catalogue_1": 73524,
    "Harvard Revised Catalogue_1": 3421,
    "Gliese Catalog_1": "Gl 312"
  },
  {
    "Astrid #": "ASTRID 003527",
    "HYG Star ID": 94406,
    "Hipparcos Catalogue": 94701,
    "Distance in Parsecs": 27.4198,
    "mag": 10.78,
    "Absolute Visual Magnitude": 8.59,
    "Spectral Type": "K7",
    "Color Index": 1.6,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.093404,
    "y": -17.64744,
    "z": 20.081925,
    "vx": -0.00001082,
    "vy": -0.00002366,
    "vz": -0.00001751,
    "rarad": 5.04486026459312,
    "decrad": 0.821822164346796,
    "pmrarad": -6.54789356958333e-7,
    "pmdecrad": -9.38017509e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 94406,
    "lum": 0.0319153785510076,
    "var_min": 10.834,
    "var_max": 10.724,
    "ra": 19.269947,
    "dec": 47.086942,
    "pmra": -135.06,
    "pmdec": -193.48,
    "rv": 0,
    "Asset Name": "ASTRID 003527",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Braid",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00001082,
    "y_1": -0.00002366,
    "z_1": -0.00001751,
    "Distance in Parsecs_1": 27.4198,
    "HYG Star ID_1": 94406,
    "Hipparcos Catalogue_1": 94701
  },
  {
    "Astrid #": "ASTRID 003528",
    "HYG Star ID": 13032,
    "Hipparcos Catalogue": 13065,
    "Distance in Parsecs": 27.4273,
    "mag": 11.07,
    "Absolute Visual Magnitude": 8.879,
    "Spectral Type": "K8",
    "Color Index": 1.462,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.881126,
    "y": 16.090521,
    "z": 13.176391,
    "vx": 0.00001415,
    "vy": -0.00001703,
    "vz": 0.0000016,
    "rarad": 0.732739087385447,
    "decrad": 0.501123852018815,
    "pmrarad": -8.06584520340277e-7,
    "pmdecrad": 6.6516436e-8,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13032,
    "lum": 0.0244568207210437,
    "var_min": 11.137,
    "var_max": 10.997,
    "ra": 2.798857,
    "dec": 28.712282,
    "pmra": -166.37,
    "pmdec": 13.72,
    "rv": 0,
    "Asset Name": "ASTRID 003528",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Nervous",
    "Hair": "Frizzy",
    "Outfit": "Elven",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00001415,
    "y_1": -0.00001703,
    "z_1": 0.0000016,
    "Distance in Parsecs_1": 27.4273,
    "HYG Star ID_1": 13032,
    "Hipparcos Catalogue_1": 13065
  },
  {
    "Astrid #": "ASTRID 003529",
    "HYG Star ID": 83351,
    "Hipparcos Catalogue": 83608,
    "Henry Draper Catalogue": 154905,
    "Harvard Revised Catalogue": 6369,
    "Gliese Catalog": "Wo 9584B",
    "Distance in Parsecs": 27.4348,
    "mag": 4.91,
    "Absolute Visual Magnitude": 2.718,
    "Spectral Type": "F5",
    "Color Index": 0.471,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.766782,
    "y": -15.491692,
    "z": 22.326644,
    "vx": -0.00000432,
    "vy": 0.00001937,
    "vz": -0.00000802,
    "rarad": 4.47387109920099,
    "decrad": 0.95068158522961,
    "pmrarad": -3.19977029166666e-7,
    "pmdecrad": 3.58083384e-7,
    "bayer": "Mu",
    "flam": 21,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 2,
    "comp_primary": 119127,
    "base": "Wo 9584",
    "lum": 7.12524824752266,
    "bf": "21Mu  Dra",
    "ra": 17.088929,
    "dec": 54.470042,
    "pmra": -66,
    "pmdec": 73.86,
    "rv": -16.5,
    "Asset Name": "ASTRID 003529",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Straw Hat",
    "Outfit": "Painter",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000432,
    "y_1": 0.00001937,
    "z_1": -0.00000802,
    "Distance in Parsecs_1": 27.4348,
    "HYG Star ID_1": 83351,
    "Hipparcos Catalogue_1": 83608,
    "Henry Draper Catalogue_1": 154905,
    "Harvard Revised Catalogue_1": 6369,
    "Gliese Catalog_1": "Wo 9584B"
  },
  {
    "Astrid #": "ASTRID 003530",
    "HYG Star ID": 668,
    "Hipparcos Catalogue": 669,
    "Henry Draper Catalogue": 361,
    "Distance in Parsecs": 27.4424,
    "mag": 7.04,
    "Absolute Visual Magnitude": 4.848,
    "Spectral Type": "G2/G3V",
    "Color Index": 0.624,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 26.511674,
    "y": 0.957394,
    "z": -7.021385,
    "vx": -3e-7,
    "vy": -0.00000167,
    "vz": -0.00000138,
    "rarad": 0.0360965287045206,
    "decrad": -0.258736191755227,
    "pmrarad": -6.02623404930555e-8,
    "pmdecrad": -5.1972026e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 668,
    "lum": 1.00184376572402,
    "ra": 0.137879,
    "dec": -14.824492,
    "pmra": -12.43,
    "pmdec": -10.72,
    "rv": 0,
    "Asset Name": "ASTRID 003530",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Braid",
    "Outfit": "Garb",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -3e-7,
    "y_1": -0.00000167,
    "z_1": -0.00000138,
    "Distance in Parsecs_1": 27.4424,
    "HYG Star ID_1": 668,
    "Hipparcos Catalogue_1": 669,
    "Henry Draper Catalogue_1": 361
  },
  {
    "Astrid #": "ASTRID 003531",
    "HYG Star ID": 45704,
    "Hipparcos Catalogue": 45836,
    "Henry Draper Catalogue": 80290,
    "Harvard Revised Catalogue": 3697,
    "Distance in Parsecs": 27.4499,
    "mag": 6.14,
    "Absolute Visual Magnitude": 3.947,
    "Spectral Type": "F3V",
    "Color Index": 0.436,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.192255,
    "y": 10.998263,
    "z": 21.412512,
    "vx": 0.00001848,
    "vy": -0.00000939,
    "vz": 0.00000572,
    "rarad": 2.44664428443919,
    "decrad": 0.894761642920985,
    "pmrarad": -1.68521235361111e-7,
    "pmdecrad": 7.04676684e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 45704,
    "lum": 2.29720630715835,
    "ra": 9.345493,
    "dec": 51.266066,
    "pmra": -34.76,
    "pmdec": 145.35,
    "rv": -8,
    "Asset Name": "ASTRID 003531",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Spiky",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001848,
    "y_1": -0.00000939,
    "z_1": 0.00000572,
    "Distance in Parsecs_1": 27.4499,
    "HYG Star ID_1": 45704,
    "Hipparcos Catalogue_1": 45836,
    "Henry Draper Catalogue_1": 80290,
    "Harvard Revised Catalogue_1": 3697
  },
  {
    "Astrid #": "ASTRID 003532",
    "HYG Star ID": 107632,
    "Hipparcos Catalogue": 107975,
    "Henry Draper Catalogue": 207978,
    "Harvard Revised Catalogue": 8354,
    "Gliese Catalog": "NN 4234",
    "Distance in Parsecs": 27.4499,
    "mag": 5.52,
    "Absolute Visual Magnitude": 3.327,
    "Spectral Type": "F6IVwvar",
    "Color Index": 0.426,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.428477,
    "y": -12.703413,
    "z": 13.221459,
    "vx": 0.00001378,
    "vy": -0.00001776,
    "vz": 0.00000198,
    "rarad": 5.72685591814836,
    "decrad": 0.502542047435077,
    "pmrarad": -2.84440186381944e-7,
    "pmdecrad": -3.06693134e-7,
    "flam": 15,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 107632,
    "lum": 4.06630546340428,
    "bf": "15    Peg",
    "ra": 21.874978,
    "dec": 28.793538,
    "pmra": -58.67,
    "pmdec": -63.26,
    "rv": 19,
    "Asset Name": "ASTRID 003532",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Audiophile",
    "Outfit": "Iron Star",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001378,
    "y_1": -0.00001776,
    "z_1": 0.00000198,
    "Distance in Parsecs_1": 27.4499,
    "HYG Star ID_1": 107632,
    "Hipparcos Catalogue_1": 107975,
    "Henry Draper Catalogue_1": 207978,
    "Harvard Revised Catalogue_1": 8354,
    "Gliese Catalog_1": "NN 4234"
  },
  {
    "Astrid #": "ASTRID 003533",
    "HYG Star ID": 59890,
    "Hipparcos Catalogue": 60074,
    "Henry Draper Catalogue": 107146,
    "Distance in Parsecs": 27.4574,
    "mag": 7.04,
    "Absolute Visual Magnitude": 4.847,
    "Spectral Type": "G2V",
    "Color Index": 0.604,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -26.228737,
    "y": -2.191927,
    "z": 7.820527,
    "vx": -0.00001361,
    "vy": 0.00002232,
    "vz": -0.00001711,
    "rarad": 3.22496890771673,
    "decrad": 0.288822263784906,
    "pmrarad": -8.51575229895833e-7,
    "pmdecrad": -7.18881725e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 59890,
    "lum": 1.00276692299658,
    "ra": 12.318474,
    "dec": 16.548297,
    "pmra": -175.65,
    "pmdec": -148.28,
    "rv": 6.2,
    "Asset Name": "ASTRID 003533",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Heartbreaker",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00001361,
    "y_1": 0.00002232,
    "z_1": -0.00001711,
    "Distance in Parsecs_1": 27.4574,
    "HYG Star ID_1": 59890,
    "Hipparcos Catalogue_1": 60074,
    "Henry Draper Catalogue_1": 107146
  },
  {
    "Astrid #": "ASTRID 003534",
    "HYG Star ID": 82106,
    "Hipparcos Catalogue": 82357,
    "Distance in Parsecs": 27.4574,
    "mag": 10.33,
    "Absolute Visual Magnitude": 8.137,
    "Spectral Type": "K7",
    "Color Index": 1.33,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.493429,
    "y": -11.030994,
    "z": -24.90024,
    "vx": 0.0000133,
    "vy": 0.00000916,
    "vz": -0.00000592,
    "rarad": 4.40568522453075,
    "decrad": -1.1357916846784,
    "pmrarad": 3.61089229277777e-7,
    "pmdecrad": -5.11963246e-7,
    "con": "TrA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum Australe",
    "FULL CONSTELLATION NAME": "Triangulum Australe",
    "comp": 1,
    "comp_primary": 82106,
    "lum": 0.0484395388547839,
    "var_min": 10.413,
    "var_max": 10.273,
    "ra": 16.828478,
    "dec": -65.07607,
    "pmra": 74.48,
    "pmdec": -105.6,
    "rv": 0,
    "Asset Name": "ASTRID 003534",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Bangs",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum Australe",
    "x_1": 0.0000133,
    "y_1": 0.00000916,
    "z_1": -0.00000592,
    "Distance in Parsecs_1": 27.4574,
    "HYG Star ID_1": 82106,
    "Hipparcos Catalogue_1": 82357
  },
  {
    "Astrid #": "ASTRID 003535",
    "HYG Star ID": 85009,
    "Hipparcos Catalogue": 85268,
    "Henry Draper Catalogue": 158259,
    "Distance in Parsecs": 27.4574,
    "mag": 6.46,
    "Absolute Visual Magnitude": 4.267,
    "Spectral Type": "G0",
    "Color Index": 0.619,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.497187,
    "y": -16.41542,
    "z": 21.867941,
    "vx": -0.00001277,
    "vy": -0.00000348,
    "vz": -0.00000407,
    "rarad": 4.56142452791318,
    "decrad": 0.92137124291165,
    "pmrarad": -4.40695635625e-7,
    "pmdecrad": -2.4526724e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 85009,
    "lum": 1.71080298759932,
    "ra": 17.423358,
    "dec": 52.790684,
    "pmra": -90.9,
    "pmdec": -50.59,
    "rv": 0,
    "Asset Name": "ASTRID 003535",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Star of Culture",
    "Hair": "Side Part",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00001277,
    "y_1": -0.00000348,
    "z_1": -0.00000407,
    "Distance in Parsecs_1": 27.4574,
    "HYG Star ID_1": 85009,
    "Hipparcos Catalogue_1": 85268,
    "Henry Draper Catalogue_1": 158259
  },
  {
    "Astrid #": "ASTRID 003536",
    "HYG Star ID": 96025,
    "Hipparcos Catalogue": 96339,
    "Gliese Catalog": "NN 4114A",
    "Distance in Parsecs": 27.4574,
    "mag": 10.38,
    "Absolute Visual Magnitude": 8.187,
    "Color Index": 1.46,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 10.949305,
    "y": -24.853395,
    "z": 4.039832,
    "vx": -0.00001541,
    "vy": 0.0000148,
    "vz": -0.00000967,
    "rarad": 5.12736137664064,
    "decrad": 0.147667976364367,
    "pmrarad": -2.96269640187499e-7,
    "pmdecrad": -2.42358358e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 96025,
    "base": "NN 4114",
    "lum": 0.046259400476616,
    "var_min": 10.435,
    "var_max": 10.335,
    "ra": 19.585078,
    "dec": 8.460752,
    "pmra": -61.11,
    "pmdec": -49.99,
    "rv": -20.5,
    "Asset Name": "ASTRID 003536",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Prince",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001541,
    "y_1": 0.0000148,
    "z_1": -0.00000967,
    "Distance in Parsecs_1": 27.4574,
    "HYG Star ID_1": 96025,
    "Hipparcos Catalogue_1": 96339,
    "Gliese Catalog_1": "NN 4114A"
  },
  {
    "Astrid #": "ASTRID 003537",
    "HYG Star ID": 12716,
    "Hipparcos Catalogue": 12749,
    "Gliese Catalog": "GJ 1051",
    "Distance in Parsecs": 27.465,
    "mag": 11.92,
    "Absolute Visual Magnitude": 9.726,
    "Spectral Type": "M2",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 20.491288,
    "y": 17.795067,
    "z": -4.215678,
    "vx": -0.00006097,
    "vy": 0.00005596,
    "vz": -0.00010019,
    "rarad": 0.715091418907085,
    "decrad": -0.154101628581565,
    "pmrarad": 0.00000299416080974305,
    "pmdecrad": -0.000003657095036,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 12716,
    "lum": 0.0112098551287531,
    "var_min": 12.024,
    "var_max": 11.774,
    "ra": 2.731448,
    "dec": -8.829373,
    "pmra": 617.59,
    "pmdec": -754.33,
    "rv": 6,
    "Asset Name": "ASTRID 003537",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Honest Lad",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00006097,
    "y_1": 0.00005596,
    "z_1": -0.00010019,
    "Distance in Parsecs_1": 27.465,
    "HYG Star ID_1": 12716,
    "Hipparcos Catalogue_1": 12749,
    "Gliese Catalog_1": "GJ 1051"
  },
  {
    "Astrid #": "ASTRID 003538",
    "HYG Star ID": 43527,
    "Hipparcos Catalogue": 43650,
    "Distance in Parsecs": 27.4725,
    "mag": 12.28,
    "Absolute Visual Magnitude": 10.086,
    "Color Index": 0.45,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -12.524406,
    "y": 13.257222,
    "z": -20.545647,
    "vx": 0.00000117,
    "vy": 0.00000188,
    "vz": 5e-7,
    "rarad": 2.32777850329136,
    "decrad": -0.844836026592879,
    "pmrarad": -7.79095584652777e-8,
    "pmdecrad": 2.7246528e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 43527,
    "lum": 0.00804637001739799,
    "ra": 8.891459,
    "dec": -48.405539,
    "pmra": -16.07,
    "pmdec": 5.62,
    "rv": 0,
    "Asset Name": "ASTRID 003538",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Angelic",
    "Outfit": "Toga",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000117,
    "y_1": 0.00000188,
    "z_1": 5e-7,
    "Distance in Parsecs_1": 27.4725,
    "HYG Star ID_1": 43527,
    "Hipparcos Catalogue_1": 43650
  },
  {
    "Astrid #": "ASTRID 003539",
    "HYG Star ID": 46874,
    "Hipparcos Catalogue": 47007,
    "Henry Draper Catalogue": 82943,
    "Distance in Parsecs": 27.4725,
    "mag": 6.54,
    "Absolute Visual Magnitude": 4.346,
    "Spectral Type": "G0",
    "Color Index": 0.623,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.649733,
    "y": 15.89668,
    "z": -5.772597,
    "vx": 0.00000374,
    "vy": -0.00000314,
    "vz": -0.00002266,
    "rarad": 2.50823710296244,
    "decrad": -0.211700510409346,
    "pmrarad": 1.15385655972222e-8,
    "pmdecrad": -8.43818211e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 46874,
    "lum": 1.59074292375021,
    "ra": 9.58076,
    "dec": -12.129546,
    "pmra": 2.38,
    "pmdec": -174.05,
    "rv": 0,
    "Asset Name": "ASTRID 003539",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Pompadour",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000374,
    "y_1": -0.00000314,
    "z_1": -0.00002266,
    "Distance in Parsecs_1": 27.4725,
    "HYG Star ID_1": 46874,
    "Hipparcos Catalogue_1": 47007,
    "Henry Draper Catalogue_1": 82943
  },
  {
    "Astrid #": "ASTRID 003540",
    "HYG Star ID": 114093,
    "Hipparcos Catalogue": 114455,
    "Henry Draper Catalogue": 218760,
    "Distance in Parsecs": 27.4725,
    "mag": 8.65,
    "Absolute Visual Magnitude": 6.456,
    "Spectral Type": "K3V",
    "Color Index": 0.966,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 23.265305,
    "y": -5.071211,
    "z": -13.702068,
    "vx": 0.00001108,
    "vy": 0.00005884,
    "vz": -0.00000297,
    "rarad": 6.06856617966174,
    "decrad": -0.522162649800547,
    "pmrarad": 0.00000217870419904861,
    "pmdecrad": -1.24548634e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 114093,
    "lum": 0.227824276625007,
    "ra": 23.180215,
    "dec": -29.917716,
    "pmra": 449.39,
    "pmdec": -25.69,
    "rv": 0,
    "Asset Name": "ASTRID 003540",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Headband",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": 0.00001108,
    "y_1": 0.00005884,
    "z_1": -0.00000297,
    "Distance in Parsecs_1": 27.4725,
    "HYG Star ID_1": 114093,
    "Hipparcos Catalogue_1": 114455,
    "Henry Draper Catalogue_1": 218760
  },
  {
    "Astrid #": "ASTRID 003541",
    "HYG Star ID": 72668,
    "Hipparcos Catalogue": 72894,
    "Distance in Parsecs": 27.4801,
    "mag": 12.05,
    "Absolute Visual Magnitude": 9.855,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.003547,
    "y": -18.013992,
    "z": 8.337697,
    "vx": -0.0000133,
    "vy": 0.00000124,
    "vz": -0.00002764,
    "rarad": 3.90026527132068,
    "decrad": 0.308267808632772,
    "pmrarad": -3.65791921979166e-7,
    "pmdecrad": -0.000001055584826,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 72668,
    "lum": 0.00995405417351526,
    "var_min": 12.161,
    "var_max": 11.831,
    "ra": 14.897916,
    "dec": 17.662444,
    "pmra": -75.45,
    "pmdec": -217.73,
    "rv": 0,
    "Asset Name": "ASTRID 003541",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Bob",
    "Outfit": "River Spirit",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000133,
    "y_1": 0.00000124,
    "z_1": -0.00002764,
    "Distance in Parsecs_1": 27.4801,
    "HYG Star ID_1": 72668,
    "Hipparcos Catalogue_1": 72894
  },
  {
    "Astrid #": "ASTRID 003542",
    "HYG Star ID": 40168,
    "Hipparcos Catalogue": 40283,
    "Henry Draper Catalogue": 68978,
    "Distance in Parsecs": 27.4952,
    "mag": 6.7,
    "Absolute Visual Magnitude": 4.504,
    "Spectral Type": "G2V",
    "Color Index": 0.618,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.870676,
    "y": 19.524045,
    "z": -14.46262,
    "vx": 0.00000798,
    "vy": 0.00006891,
    "vz": -0.00000896,
    "rarad": 2.15362955526116,
    "decrad": -0.55388689034324,
    "pmrarad": -0.00000162179872419444,
    "pmdecrad": 7.39389344e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 40168,
    "lum": 1.37530809742145,
    "ra": 8.226259,
    "dec": -31.735381,
    "pmra": -334.52,
    "pmdec": 152.51,
    "rv": 48.8,
    "Asset Name": "ASTRID 003542",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Curly",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000798,
    "y_1": 0.00006891,
    "z_1": -0.00000896,
    "Distance in Parsecs_1": 27.4952,
    "HYG Star ID_1": 40168,
    "Hipparcos Catalogue_1": 40283,
    "Henry Draper Catalogue_1": 68978
  },
  {
    "Astrid #": "ASTRID 003543",
    "HYG Star ID": 54850,
    "Hipparcos Catalogue": 55013,
    "Henry Draper Catalogue": 97998,
    "Distance in Parsecs": 27.4952,
    "mag": 7.36,
    "Absolute Visual Magnitude": 5.164,
    "Spectral Type": "G5V",
    "Color Index": 0.626,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -20.876011,
    "y": 4.075192,
    "z": -17.42391,
    "vx": -0.00004533,
    "vy": 0.00003296,
    "vz": -0.00000899,
    "rarad": 2.94880988969225,
    "decrad": -0.686324619273705,
    "pmrarad": -8.60835171194444e-7,
    "pmdecrad": 9.18140148e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 54850,
    "lum": 0.748858907521643,
    "ra": 11.263624,
    "dec": -39.323504,
    "pmra": -177.56,
    "pmdec": 189.38,
    "rv": 44,
    "Asset Name": "ASTRID 003543",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Nervous Grin",
    "Hair": "Heartbreaker",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00004533,
    "y_1": 0.00003296,
    "z_1": -0.00000899,
    "Distance in Parsecs_1": 27.4952,
    "HYG Star ID_1": 54850,
    "Hipparcos Catalogue_1": 55013,
    "Henry Draper Catalogue_1": 97998
  },
  {
    "Astrid #": "ASTRID 003544",
    "HYG Star ID": 54868,
    "Hipparcos Catalogue": 55031,
    "Distance in Parsecs": 27.5028,
    "mag": 12.3,
    "Absolute Visual Magnitude": 10.103,
    "Spectral Type": "Fp",
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.820754,
    "y": 3.468845,
    "z": -20.658942,
    "vx": 0.00000466,
    "vy": 0.0000085,
    "vz": -0.00000259,
    "rarad": 2.94934613590789,
    "decrad": -0.849814164304245,
    "pmrarad": -3.35781955152777e-7,
    "pmdecrad": -1.42777628e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 54868,
    "lum": 0.00792136454152851,
    "var": "V902",
    "var_min": 12.635,
    "var_max": 12.085,
    "ra": 11.265672,
    "dec": -48.690765,
    "pmra": -69.26,
    "pmdec": -29.45,
    "rv": 0,
    "Asset Name": "ASTRID 003544",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Braided Ponytail",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000466,
    "y_1": 0.0000085,
    "z_1": -0.00000259,
    "Distance in Parsecs_1": 27.5028,
    "HYG Star ID_1": 54868,
    "Hipparcos Catalogue_1": 55031
  },
  {
    "Astrid #": "ASTRID 003545",
    "HYG Star ID": 72839,
    "Hipparcos Catalogue": 73066,
    "Henry Draper Catalogue": 131719,
    "Distance in Parsecs": 27.5028,
    "mag": 9,
    "Absolute Visual Magnitude": 6.803,
    "Spectral Type": "K3V",
    "Color Index": 1.005,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.613709,
    "y": -16.997032,
    "z": -12.540421,
    "vx": -0.00000854,
    "vy": 0.00001083,
    "vz": -0.00000268,
    "rarad": 3.90917577431355,
    "decrad": -0.473460558143263,
    "pmrarad": -4.991641655e-7,
    "pmdecrad": -1.09616373e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 72839,
    "lum": 0.165500762876998,
    "ra": 14.931952,
    "dec": -27.127292,
    "pmra": -102.96,
    "pmdec": -22.61,
    "rv": 0,
    "Asset Name": "ASTRID 003545",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Lion Mane",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000854,
    "y_1": 0.00001083,
    "z_1": -0.00000268,
    "Distance in Parsecs_1": 27.5028,
    "HYG Star ID_1": 72839,
    "Hipparcos Catalogue_1": 73066,
    "Henry Draper Catalogue_1": 131719
  },
  {
    "Astrid #": "ASTRID 003546",
    "HYG Star ID": 101970,
    "Hipparcos Catalogue": 102301,
    "Gliese Catalog": "NN 4159",
    "Distance in Parsecs": 27.5028,
    "mag": 11.46,
    "Absolute Visual Magnitude": 9.263,
    "Color Index": 1.449,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 18.015176,
    "y": -20.781004,
    "z": -0.085544,
    "vx": 0.0000366,
    "vy": 0.00003158,
    "vz": 0.00003386,
    "rarad": 5.42661632207762,
    "decrad": -0.003110354015955,
    "pmrarad": 0.00000175783744295833,
    "pmdecrad": 0.000001231426748,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 101970,
    "lum": 0.0171711744324445,
    "var_min": 11.523,
    "var_max": 11.393,
    "ra": 20.728147,
    "dec": -0.17821,
    "pmra": 362.58,
    "pmdec": 254,
    "rv": 0,
    "Asset Name": "ASTRID 003546",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Oh No",
    "Hair": "Side Part",
    "Outfit": "Boxer",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.0000366,
    "y_1": 0.00003158,
    "z_1": 0.00003386,
    "Distance in Parsecs_1": 27.5028,
    "HYG Star ID_1": 101970,
    "Hipparcos Catalogue_1": 102301,
    "Gliese Catalog_1": "NN 4159"
  },
  {
    "Astrid #": "ASTRID 003547",
    "HYG Star ID": 4057,
    "Hipparcos Catalogue": 4067,
    "Distance in Parsecs": 27.5103,
    "mag": 11.8,
    "Absolute Visual Magnitude": 9.603,
    "Spectral Type": "K7",
    "Color Index": 1.465,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 26.612295,
    "y": 6.163022,
    "z": 3.258762,
    "vx": -7.9e-7,
    "vy": 0.00000506,
    "vz": -0.00000315,
    "rarad": 0.227573726307634,
    "decrad": 0.118734861951301,
    "pmrarad": 1.85635158284722e-7,
    "pmdecrad": -1.15434137e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 4057,
    "lum": 0.0125545167368713,
    "var_min": 11.885,
    "var_max": 11.685,
    "ra": 0.869268,
    "dec": 6.803006,
    "pmra": 38.29,
    "pmdec": -23.81,
    "rv": 0,
    "Asset Name": "ASTRID 003547",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Chubby Cheeks",
    "Hair": "Unkempt",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -7.9e-7,
    "y_1": 0.00000506,
    "z_1": -0.00000315,
    "Distance in Parsecs_1": 27.5103,
    "HYG Star ID_1": 4057,
    "Hipparcos Catalogue_1": 4067
  },
  {
    "Astrid #": "ASTRID 003548",
    "HYG Star ID": 66742,
    "Hipparcos Catalogue": 66954,
    "Distance in Parsecs": 27.5103,
    "mag": 9.23,
    "Absolute Visual Magnitude": 7.033,
    "Spectral Type": "K8",
    "Color Index": 1.101,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.172949,
    "y": -9.287624,
    "z": 17.405594,
    "vx": -9.2e-7,
    "vy": -0.00001336,
    "vz": -0.00000815,
    "rarad": 3.59269192043153,
    "decrad": 0.685026709815978,
    "pmrarad": 4.22563603972222e-7,
    "pmdecrad": -3.82421031e-7,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 66742,
    "lum": 0.133905988554673,
    "ra": 13.723072,
    "dec": 39.249139,
    "pmra": 87.16,
    "pmdec": -78.88,
    "rv": 0,
    "Asset Name": "ASTRID 003548",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Pompadour",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -9.2e-7,
    "y_1": -0.00001336,
    "z_1": -0.00000815,
    "Distance in Parsecs_1": 27.5103,
    "HYG Star ID_1": 66742,
    "Hipparcos Catalogue_1": 66954
  },
  {
    "Astrid #": "ASTRID 003549",
    "HYG Star ID": 17936,
    "Hipparcos Catalogue": 17978,
    "Henry Draper Catalogue": 24331,
    "Distance in Parsecs": 27.5179,
    "mag": 8.61,
    "Absolute Visual Magnitude": 6.412,
    "Spectral Type": "K2V",
    "Color Index": 0.913,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.84541,
    "y": 17.12123,
    "z": -18.614159,
    "vx": 0.00001728,
    "vy": 0.00007645,
    "vz": 0.00004501,
    "rarad": 1.0061420541569,
    "decrad": -0.74290872583636,
    "pmrarad": 9.56246503527777e-7,
    "pmdecrad": 0.000003019904416,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17936,
    "lum": 0.23724660151048,
    "ra": 3.84318,
    "dec": -42.565535,
    "pmra": 197.24,
    "pmdec": 622.9,
    "rv": 23.4,
    "Asset Name": "ASTRID 003549",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Sweetheart",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00001728,
    "y_1": 0.00007645,
    "z_1": 0.00004501,
    "Distance in Parsecs_1": 27.5179,
    "HYG Star ID_1": 17936,
    "Hipparcos Catalogue_1": 17978,
    "Henry Draper Catalogue_1": 24331
  },
  {
    "Astrid #": "ASTRID 003550",
    "HYG Star ID": 58705,
    "Hipparcos Catalogue": 58880,
    "Distance in Parsecs": 27.5179,
    "mag": 11.77,
    "Absolute Visual Magnitude": 9.572,
    "Color Index": 1.492,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -21.598889,
    "y": -0.434483,
    "z": -17.04506,
    "vx": 0.00002028,
    "vy": -0.00008503,
    "vz": -0.00002353,
    "rarad": 3.16169847714089,
    "decrad": -0.668000101891392,
    "pmrarad": 0.00000310435895933333,
    "pmdecrad": -0.000001089473302,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 58705,
    "lum": 0.0129181403900305,
    "var_min": 11.854,
    "var_max": 11.704,
    "ra": 12.076799,
    "dec": -38.273587,
    "pmra": 640.32,
    "pmdec": -224.72,
    "rv": 0,
    "Asset Name": "ASTRID 003550",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Fedora",
    "Outfit": "Skater",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00002028,
    "y_1": -0.00008503,
    "z_1": -0.00002353,
    "Distance in Parsecs_1": 27.5179,
    "HYG Star ID_1": 58705,
    "Hipparcos Catalogue_1": 58880
  },
  {
    "Astrid #": "ASTRID 003551",
    "HYG Star ID": 118759,
    "Gliese Catalog": "Gl 431.1B",
    "Distance in Parsecs": 27.5179,
    "mag": 7.1,
    "Absolute Visual Magnitude": 4.902,
    "Spectral Type": "G3 V",
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.209398,
    "y": 1.600598,
    "z": 24.087023,
    "vx": -0.0000081,
    "vy": 0.00000106,
    "vz": -0.00000452,
    "rarad": 3.02100951013182,
    "decrad": 1.06610136624845,
    "pmrarad": -2.95736345138888e-9,
    "pmdecrad": -3.39355031e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 2,
    "comp_primary": 56121,
    "base": "Gl 431.1",
    "lum": 0.953235043920514,
    "ra": 11.539406,
    "dec": 61.083109,
    "pmra": -0.61,
    "pmdec": -70,
    "rv": 0,
    "Asset Name": "ASTRID 003551",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Gimme Blood",
    "Hair": "Bucket Hat",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.0000081,
    "y_1": 0.00000106,
    "z_1": -0.00000452,
    "Distance in Parsecs_1": 27.5179,
    "HYG Star ID_1": 118759,
    "Gliese Catalog_1": "Gl 431.1B"
  },
  {
    "Astrid #": "ASTRID 003552",
    "HYG Star ID": 32636,
    "Hipparcos Catalogue": 32723,
    "Gliese Catalog": "NN 3411",
    "Distance in Parsecs": 27.533,
    "mag": 10.18,
    "Absolute Visual Magnitude": 7.981,
    "Spectral Type": "K5",
    "Color Index": 1.33,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.834509,
    "y": 21.989736,
    "z": 15.847559,
    "vx": -0.00002568,
    "vy": 0.00001874,
    "vz": -0.00003383,
    "rarad": 1.78720489751962,
    "decrad": 0.61331835385313,
    "pmrarad": 7.64745099708333e-7,
    "pmdecrad": -0.000001502873928,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 32636,
    "lum": 0.0559242283040899,
    "ra": 6.82662,
    "dec": 35.140553,
    "pmra": 157.74,
    "pmdec": -309.99,
    "rv": 0,
    "Asset Name": "ASTRID 003552",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Heartbreaker",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00002568,
    "y_1": 0.00001874,
    "z_1": -0.00003383,
    "Distance in Parsecs_1": 27.533,
    "HYG Star ID_1": 32636,
    "Hipparcos Catalogue_1": 32723,
    "Gliese Catalog_1": "NN 3411"
  },
  {
    "Astrid #": "ASTRID 003553",
    "HYG Star ID": 29994,
    "Hipparcos Catalogue": 30067,
    "Henry Draper Catalogue": 43947,
    "Distance in Parsecs": 27.5406,
    "mag": 6.61,
    "Absolute Visual Magnitude": 4.41,
    "Spectral Type": "F8V",
    "Color Index": 0.562,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.269189,
    "y": 26.3749,
    "z": 7.597446,
    "vx": -0.00000133,
    "vy": 0.00004265,
    "vz": 0.00001023,
    "rarad": 1.65662091447747,
    "decrad": 0.279483918680041,
    "pmrarad": -8.47454313611111e-8,
    "pmdecrad": -6.9522281e-8,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29994,
    "lum": 1.49968483550237,
    "ra": 6.327826,
    "dec": 16.013249,
    "pmra": -17.48,
    "pmdec": -14.34,
    "rv": 42.8,
    "Asset Name": "ASTRID 003553",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Nervous",
    "Hair": "Unkempt",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000133,
    "y_1": 0.00004265,
    "z_1": 0.00001023,
    "Distance in Parsecs_1": 27.5406,
    "HYG Star ID_1": 29994,
    "Hipparcos Catalogue_1": 30067,
    "Henry Draper Catalogue_1": 43947
  },
  {
    "Astrid #": "ASTRID 003554",
    "HYG Star ID": 94350,
    "Hipparcos Catalogue": 94645,
    "Henry Draper Catalogue": 179949,
    "Harvard Revised Catalogue": 7291,
    "Distance in Parsecs": 27.5482,
    "mag": 6.25,
    "Absolute Visual Magnitude": 4.05,
    "Spectral Type": "F8V",
    "Color Index": 0.548,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.135614,
    "y": -23.777829,
    "z": -11.28349,
    "vx": 0.00000485,
    "vy": 0.00003318,
    "vz": -0.00000151,
    "rarad": 5.04205413210499,
    "decrad": -0.42200930490207,
    "pmrarad": 5.56469142541666e-7,
    "pmdecrad": -4.93588808e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 94350,
    "lum": 2.08929613085403,
    "var": "V5652",
    "var_min": 6.258,
    "var_max": 6.238,
    "ra": 19.259228,
    "dec": -24.179352,
    "pmra": 114.78,
    "pmdec": -101.81,
    "rv": -26,
    "Asset Name": "ASTRID 003554",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Medium Bob",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00000485,
    "y_1": 0.00003318,
    "z_1": -0.00000151,
    "Distance in Parsecs_1": 27.5482,
    "HYG Star ID_1": 94350,
    "Hipparcos Catalogue_1": 94645,
    "Henry Draper Catalogue_1": 179949,
    "Harvard Revised Catalogue_1": 7291
  },
  {
    "Astrid #": "ASTRID 003555",
    "HYG Star ID": 88685,
    "Hipparcos Catalogue": 88962,
    "Gliese Catalog": "NN 4042",
    "Distance in Parsecs": 27.5558,
    "mag": 10.5,
    "Absolute Visual Magnitude": 8.299,
    "Spectral Type": "K7V",
    "Color Index": 1.372,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.123174,
    "y": -26.92631,
    "z": -5.74746,
    "vx": 0.00000835,
    "vy": 0.00000831,
    "vz": -0.0000265,
    "rarad": 4.75407762022623,
    "decrad": -0.210118180431917,
    "pmrarad": 3.15225855097222e-7,
    "pmdecrad": -0.000001000849362,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 88685,
    "lum": 0.0417253511231793,
    "var_min": 10.556,
    "var_max": 10.446,
    "ra": 18.159239,
    "dec": -12.038885,
    "pmra": 65.02,
    "pmdec": -206.44,
    "rv": -2.2,
    "Asset Name": "ASTRID 003555",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Slick",
    "Outfit": "Scientist",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00000835,
    "y_1": 0.00000831,
    "z_1": -0.0000265,
    "Distance in Parsecs_1": 27.5558,
    "HYG Star ID_1": 88685,
    "Hipparcos Catalogue_1": 88962,
    "Gliese Catalog_1": "NN 4042"
  },
  {
    "Astrid #": "ASTRID 003556",
    "HYG Star ID": 116532,
    "Hipparcos Catalogue": 116898,
    "Distance in Parsecs": 27.5558,
    "mag": 11.6,
    "Absolute Visual Magnitude": 9.399,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.93242,
    "y": -1.352957,
    "z": -21.697576,
    "vx": -0.00002661,
    "vy": -0.00000142,
    "vz": -0.00002068,
    "rarad": 6.20345204880776,
    "decrad": -0.90658797050785,
    "pmrarad": -1.28136255770833e-7,
    "pmdecrad": -0.00000121765804,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 116532,
    "lum": 0.0151495593204034,
    "var_min": 11.726,
    "var_max": 11.346,
    "ra": 23.695441,
    "dec": -51.943664,
    "pmra": -26.43,
    "pmdec": -251.16,
    "rv": 0,
    "Asset Name": "ASTRID 003556",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Maniacal",
    "Hair": "Bucket Hat",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00002661,
    "y_1": -0.00000142,
    "z_1": -0.00002068,
    "Distance in Parsecs_1": 27.5558,
    "HYG Star ID_1": 116532,
    "Hipparcos Catalogue_1": 116898
  },
  {
    "Astrid #": "ASTRID 003557",
    "HYG Star ID": 111625,
    "Hipparcos Catalogue": 111978,
    "Henry Draper Catalogue": 214759,
    "Gliese Catalog": "Gl 869",
    "Distance in Parsecs": 27.5634,
    "mag": 7.41,
    "Absolute Visual Magnitude": 5.208,
    "Spectral Type": "G8/K0V",
    "Color Index": 0.789,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 21.999532,
    "y": -7.907363,
    "z": -14.602221,
    "vx": -2.6e-7,
    "vy": 0.00004886,
    "vz": 0.00001369,
    "rarad": 5.93812893956012,
    "decrad": -0.558331878031796,
    "pmrarad": 0.00000166509258586805,
    "pmdecrad": 9.8853509e-8,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 111625,
    "lum": 0.719117735782509,
    "ra": 22.681982,
    "dec": -31.99006,
    "pmra": 343.45,
    "pmdec": 20.39,
    "rv": -21,
    "Asset Name": "ASTRID 003557",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Braided Ponytail",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -2.6e-7,
    "y_1": 0.00004886,
    "z_1": 0.00001369,
    "Distance in Parsecs_1": 27.5634,
    "HYG Star ID_1": 111625,
    "Hipparcos Catalogue_1": 111978,
    "Henry Draper Catalogue_1": 214759,
    "Gliese Catalog_1": "Gl 869"
  },
  {
    "Astrid #": "ASTRID 003558",
    "HYG Star ID": 14540,
    "Hipparcos Catalogue": 14576,
    "Henry Draper Catalogue": 19356,
    "Harvard Revised Catalogue": 936,
    "Proper Name": "Algol",
    "Distance in Parsecs": 27.571,
    "mag": 2.09,
    "Absolute Visual Magnitude": -0.112,
    "Spectral Type": "B8V",
    "Color Index": -0.003,
    "Spectral Abbreviation": "B",
    "Star Type": "Teal",
    "Color In Star Map": "Teal",
    "x": 14.189428,
    "y": 15.238793,
    "z": 18.072114,
    "vx": 0.00000196,
    "vy": 0.00000257,
    "vz": 0.00000254,
    "rarad": 0.821041501386661,
    "decrad": 0.714810897913476,
    "pmrarad": 1.15870469652777e-8,
    "pmdecrad": -6.981317e-9,
    "bayer": "Bet",
    "flam": 26,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 14540,
    "lum": 96.5606098541549,
    "var": "Bet",
    "var_min": 2.213,
    "var_max": 2.073,
    "bf": "26Bet Per",
    "ra": 3.136148,
    "dec": 40.955648,
    "pmra": 2.39,
    "pmdec": -1.44,
    "rv": 4,
    "Asset Name": "ASTRID 003558",
    "Astrium Color": "Teal",
    "Background": "Burning Cosmos",
    "Expression": "Fired Up",
    "Hair": "Spartan Helmet",
    "Outfit": "Spartan",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Algol",
    "Constellation": "Perseus",
    "x_1": 0.00000196,
    "y_1": 0.00000257,
    "z_1": 0.00000254,
    "Distance in Parsecs_1": 27.571,
    "HYG Star ID_1": 14540,
    "Hipparcos Catalogue_1": 14576,
    "Henry Draper Catalogue_1": 19356,
    "Harvard Revised Catalogue_1": 936
  },
  {
    "Astrid #": "ASTRID 003559",
    "HYG Star ID": 21779,
    "Hipparcos Catalogue": 21832,
    "Henry Draper Catalogue": 29587,
    "Distance in Parsecs": 27.571,
    "mag": 7.29,
    "Absolute Visual Magnitude": 5.088,
    "Spectral Type": "G2V",
    "Color Index": 0.633,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.860145,
    "y": 19.266932,
    "z": 18.491602,
    "vx": -0.0000263,
    "vy": 0.00013971,
    "vz": 0.0000359,
    "rarad": 1.22872697488267,
    "decrad": 0.73510598302717,
    "pmrarad": 0.00000259884373461805,
    "pmdecrad": -0.000002021139752,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 21779,
    "lum": 0.803156169922656,
    "ra": 4.693391,
    "dec": 42.11847,
    "pmra": 536.05,
    "pmdec": -416.89,
    "rv": 112.6,
    "Asset Name": "ASTRID 003559",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Tiny Crown",
    "Outfit": "Painter",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": -0.0000263,
    "y_1": 0.00013971,
    "z_1": 0.0000359,
    "Distance in Parsecs_1": 27.571,
    "HYG Star ID_1": 21779,
    "Hipparcos Catalogue_1": 21832,
    "Henry Draper Catalogue_1": 29587
  },
  {
    "Astrid #": "ASTRID 003560",
    "HYG Star ID": 54130,
    "Hipparcos Catalogue": 54288,
    "Distance in Parsecs": 27.571,
    "mag": 9.62,
    "Absolute Visual Magnitude": 7.418,
    "Spectral Type": "K5",
    "Color Index": 1.27,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.766778,
    "y": 5.187386,
    "z": -16.107714,
    "vx": -0.00000113,
    "vy": -0.0000152,
    "vz": -0.00000337,
    "rarad": 2.90763882462678,
    "decrad": -0.623926922602378,
    "pmrarad": 5.45900204305555e-7,
    "pmdecrad": -1.50583129e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 54130,
    "lum": 0.0939290651618398,
    "ra": 11.106362,
    "dec": -35.748379,
    "pmra": 112.6,
    "pmdec": -31.06,
    "rv": 0,
    "Asset Name": "ASTRID 003560",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Determined",
    "Hair": "Sweetheart",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000113,
    "y_1": -0.0000152,
    "z_1": -0.00000337,
    "Distance in Parsecs_1": 27.571,
    "HYG Star ID_1": 54130,
    "Hipparcos Catalogue_1": 54288
  },
  {
    "Astrid #": "ASTRID 003561",
    "HYG Star ID": 79976,
    "Hipparcos Catalogue": 80218,
    "Henry Draper Catalogue": 147512,
    "Distance in Parsecs": 27.571,
    "mag": 7.34,
    "Absolute Visual Magnitude": 5.138,
    "Spectral Type": "G0",
    "Color Index": 0.718,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -11.342511,
    "y": -25.046639,
    "z": -2.042883,
    "vx": -0.00000898,
    "vy": 0.00000598,
    "vz": -0.00002339,
    "rarad": 4.28716290287902,
    "decrad": -0.074163306768505,
    "pmrarad": -3.86299540666666e-7,
    "pmdecrad": -8.50944972e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 79976,
    "lum": 0.767008187693509,
    "ra": 16.375756,
    "dec": -4.249244,
    "pmra": -79.68,
    "pmdec": -175.52,
    "rv": 0,
    "Asset Name": "ASTRID 003561",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Blep",
    "Hair": "Goddess",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000898,
    "y_1": 0.00000598,
    "z_1": -0.00002339,
    "Distance in Parsecs_1": 27.571,
    "HYG Star ID_1": 79976,
    "Hipparcos Catalogue_1": 80218,
    "Henry Draper Catalogue_1": 147512
  },
  {
    "Astrid #": "ASTRID 003562",
    "HYG Star ID": 49057,
    "Hipparcos Catalogue": 49197,
    "Henry Draper Catalogue": 86856,
    "Gliese Catalog": "Gl 378.1",
    "Distance in Parsecs": 27.5786,
    "mag": 9.09,
    "Absolute Visual Magnitude": 6.887,
    "Spectral Type": "K0",
    "Color Index": 1.078,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.11731,
    "y": 9.637977,
    "z": 19.357242,
    "vx": -0.00001004,
    "vy": 0.00004913,
    "vz": 0.00001548,
    "rarad": 2.62878698295381,
    "decrad": 0.778041778243843,
    "pmrarad": -0.00000137371956385416,
    "pmdecrad": -4.36429275e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 49057,
    "lum": 0.153179271589341,
    "ra": 10.041227,
    "dec": 44.57851,
    "pmra": -283.35,
    "pmdec": -90.02,
    "rv": 33.5,
    "Asset Name": "ASTRID 003562",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Awkward",
    "Hair": "Skater Helmet",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001004,
    "y_1": 0.00004913,
    "z_1": 0.00001548,
    "Distance in Parsecs_1": 27.5786,
    "HYG Star ID_1": 49057,
    "Hipparcos Catalogue_1": 49197,
    "Henry Draper Catalogue_1": 86856,
    "Gliese Catalog_1": "Gl 378.1"
  },
  {
    "Astrid #": "ASTRID 003563",
    "HYG Star ID": 113049,
    "Hipparcos Catalogue": 113409,
    "Henry Draper Catalogue": 217065,
    "Distance in Parsecs": 27.5786,
    "mag": 10.13,
    "Absolute Visual Magnitude": 7.927,
    "Spectral Type": "K2V:",
    "Color Index": 1.233,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 25.829039,
    "y": -7.150357,
    "z": -6.504793,
    "vx": -0.00000873,
    "vy": 0.00000739,
    "vz": -0.00004278,
    "rarad": 6.01311476452727,
    "decrad": -0.238107360179126,
    "pmrarad": 1.73660260374999e-7,
    "pmdecrad": -0.000001596249043,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113049,
    "lum": 0.0587759963680599,
    "ra": 22.968407,
    "dec": -13.642547,
    "pmra": 35.82,
    "pmdec": -329.25,
    "rv": 0,
    "Asset Name": "ASTRID 003563",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Guilty Laugh",
    "Hair": "Greasy",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000873,
    "y_1": 0.00000739,
    "z_1": -0.00004278,
    "Distance in Parsecs_1": 27.5786,
    "HYG Star ID_1": 113049,
    "Hipparcos Catalogue_1": 113409,
    "Henry Draper Catalogue_1": 217065
  },
  {
    "Astrid #": "ASTRID 003564",
    "HYG Star ID": 101423,
    "Hipparcos Catalogue": 101750,
    "Henry Draper Catalogue": 197433,
    "Gliese Catalog": "GJ 1255A",
    "Distance in Parsecs": 27.5862,
    "mag": 7.46,
    "Absolute Visual Magnitude": 5.257,
    "Spectral Type": "K0Vvar",
    "Color Index": 0.859,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.348805,
    "y": -5.305683,
    "z": 26.719508,
    "vx": -0.0000136,
    "vy": 0.00008183,
    "vz": 0.00001002,
    "rarad": 5.39895786563412,
    "decrad": 1.31947597081159,
    "pmrarad": 0.00000149904390027777,
    "pmdecrad": 0.000002615521325,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 101423,
    "base": "GJ 1255",
    "lum": 0.687384919590323,
    "var": "VW",
    "var_min": 7.662,
    "var_max": 7.322,
    "ra": 20.6225,
    "dec": 75.600404,
    "pmra": 309.2,
    "pmdec": 539.49,
    "rv": -8,
    "Asset Name": "ASTRID 003564",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Shocked",
    "Hair": "Ninja",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.0000136,
    "y_1": 0.00008183,
    "z_1": 0.00001002,
    "Distance in Parsecs_1": 27.5862,
    "HYG Star ID_1": 101423,
    "Hipparcos Catalogue_1": 101750,
    "Henry Draper Catalogue_1": 197433,
    "Gliese Catalog_1": "GJ 1255A"
  },
  {
    "Astrid #": "ASTRID 003565",
    "HYG Star ID": 53386,
    "Hipparcos Catalogue": 53541,
    "Henry Draper Catalogue": 94818,
    "Distance in Parsecs": 27.5938,
    "mag": 8.94,
    "Absolute Visual Magnitude": 6.736,
    "Spectral Type": "K0",
    "Color Index": 1.019,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.247316,
    "y": 6.535624,
    "z": 13.351617,
    "vx": -0.00002394,
    "vy": -0.00004238,
    "vz": -0.00002093,
    "rarad": 2.8675300641963,
    "decrad": 0.505063420665757,
    "pmrarad": 0.00000171362243529166,
    "pmdecrad": -8.66895342e-7,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 53386,
    "lum": 0.176035395361616,
    "ra": 10.953158,
    "dec": 28.938002,
    "pmra": 353.46,
    "pmdec": -178.81,
    "rv": 0,
    "Asset Name": "ASTRID 003565",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Laughing",
    "Hair": "Curly",
    "Outfit": "Painter",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": -0.00002394,
    "y_1": -0.00004238,
    "z_1": -0.00002093,
    "Distance in Parsecs_1": 27.5938,
    "HYG Star ID_1": 53386,
    "Hipparcos Catalogue_1": 53541,
    "Henry Draper Catalogue_1": 94818
  },
  {
    "Astrid #": "ASTRID 003566",
    "HYG Star ID": 19157,
    "Hipparcos Catalogue": 19205,
    "Henry Draper Catalogue": 25867,
    "Harvard Revised Catalogue": 1269,
    "Distance in Parsecs": 27.6014,
    "mag": 5.21,
    "Absolute Visual Magnitude": 3.005,
    "Spectral Type": "F1V",
    "Color Index": 0.359,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.425468,
    "y": 21.265517,
    "z": 13.382011,
    "vx": 0.00001474,
    "vy": 8.6e-7,
    "vz": 0.00000504,
    "rarad": 1.07777444613484,
    "decrad": 0.506168162863862,
    "pmrarad": -4.55870303826388e-7,
    "pmdecrad": 2.375587e-8,
    "bayer": "Psi",
    "flam": 42,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 19157,
    "lum": 5.47015962893971,
    "bf": "42Psi Tau",
    "ra": 4.116795,
    "dec": 29.001299,
    "pmra": -94.03,
    "pmdec": 4.9,
    "rv": 9,
    "Asset Name": "ASTRID 003566",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shy Smile",
    "Hair": "Slick",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00001474,
    "y_1": 8.6e-7,
    "z_1": 0.00000504,
    "Distance in Parsecs_1": 27.6014,
    "HYG Star ID_1": 19157,
    "Hipparcos Catalogue_1": 19205,
    "Henry Draper Catalogue_1": 25867,
    "Harvard Revised Catalogue_1": 1269
  },
  {
    "Astrid #": "ASTRID 003567",
    "HYG Star ID": 94327,
    "Hipparcos Catalogue": 94622,
    "Henry Draper Catalogue": 338030,
    "Gliese Catalog": "Gl 751",
    "Distance in Parsecs": 27.6014,
    "mag": 9.71,
    "Absolute Visual Magnitude": 7.505,
    "Spectral Type": "M0",
    "Color Index": 1.304,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.079943,
    "y": -23.696019,
    "z": 11.619633,
    "vx": 0.00000529,
    "vy": 0.00008356,
    "vz": -0.00000357,
    "rarad": 5.04100745812082,
    "decrad": 0.434535819120082,
    "pmrarad": 0.00000115841380831944,
    "pmdecrad": 0.000001063099438,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 94327,
    "lum": 0.0866961875758216,
    "ra": 19.25523,
    "dec": 24.897068,
    "pmra": 238.94,
    "pmdec": 219.28,
    "rv": -70.1,
    "Asset Name": "ASTRID 003567",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Mortified",
    "Hair": "Bandana",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.00000529,
    "y_1": 0.00008356,
    "z_1": -0.00000357,
    "Distance in Parsecs_1": 27.6014,
    "HYG Star ID_1": 94327,
    "Hipparcos Catalogue_1": 94622,
    "Henry Draper Catalogue_1": 338030,
    "Gliese Catalog_1": "Gl 751"
  },
  {
    "Astrid #": "ASTRID 003568",
    "HYG Star ID": 83108,
    "Hipparcos Catalogue": 83363,
    "Henry Draper Catalogue": 153284,
    "Distance in Parsecs": 27.6167,
    "mag": 8.74,
    "Absolute Visual Magnitude": 6.534,
    "Spectral Type": "K3V",
    "Color Index": 1.035,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.277011,
    "y": -12.733582,
    "z": -24.285782,
    "vx": -4.5e-7,
    "vy": 0.000023,
    "vz": -0.000012,
    "rarad": 4.46050509023124,
    "decrad": -1.07457429666927,
    "pmrarad": -2.23402144e-7,
    "pmdecrad": -9.12419346e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 83108,
    "lum": 0.212031311673984,
    "ra": 17.037874,
    "dec": -61.568572,
    "pmra": -46.08,
    "pmdec": -188.2,
    "rv": 0,
    "Asset Name": "ASTRID 003568",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Heartbreaker",
    "Outfit": "Basketball",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": -4.5e-7,
    "y_1": 0.000023,
    "z_1": -0.000012,
    "Distance in Parsecs_1": 27.6167,
    "HYG Star ID_1": 83108,
    "Hipparcos Catalogue_1": 83363,
    "Henry Draper Catalogue_1": 153284
  },
  {
    "Astrid #": "ASTRID 003569",
    "HYG Star ID": 90346,
    "Hipparcos Catalogue": 90626,
    "Henry Draper Catalogue": 170510,
    "Distance in Parsecs": 27.6243,
    "mag": 8.65,
    "Absolute Visual Magnitude": 6.444,
    "Spectral Type": "K2",
    "Color Index": 0.976,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.505439,
    "y": -27.053337,
    "z": 4.350956,
    "vx": 0.0000249,
    "vy": 0.00000478,
    "vz": 0.00000964,
    "rarad": 4.84124601118203,
    "decrad": 0.158163268353681,
    "pmrarad": 9.16249374881944e-7,
    "pmdecrad": 3.53526135e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 90346,
    "lum": 0.230356250011753,
    "ra": 18.492198,
    "dec": 9.062088,
    "pmra": 188.99,
    "pmdec": 72.92,
    "rv": 0,
    "Asset Name": "ASTRID 003569",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Ponytail",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.0000249,
    "y_1": 0.00000478,
    "z_1": 0.00000964,
    "Distance in Parsecs_1": 27.6243,
    "HYG Star ID_1": 90346,
    "Hipparcos Catalogue_1": 90626,
    "Henry Draper Catalogue_1": 170510
  },
  {
    "Astrid #": "ASTRID 003570",
    "HYG Star ID": 64690,
    "Hipparcos Catalogue": 64895,
    "Distance in Parsecs": 27.6319,
    "mag": 10.88,
    "Absolute Visual Magnitude": 8.673,
    "Spectral Type": "K6",
    "Color Index": 1.344,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.181333,
    "y": -8.929284,
    "z": -7.049129,
    "vx": -0.00000736,
    "vy": 0.00004463,
    "vz": -0.00003024,
    "rarad": 3.48235948534567,
    "decrad": -0.25795977934951,
    "pmrarad": -0.00000161161763690277,
    "pmdecrad": -0.000001132039944,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 64690,
    "lum": 0.0295665056508294,
    "var_min": 10.94,
    "var_max": 10.8,
    "ra": 13.301633,
    "dec": -14.780007,
    "pmra": -332.42,
    "pmdec": -233.5,
    "rv": 0,
    "Asset Name": "ASTRID 003570",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Mischievous",
    "Hair": "Antlers",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000736,
    "y_1": 0.00004463,
    "z_1": -0.00003024,
    "Distance in Parsecs_1": 27.6319,
    "HYG Star ID_1": 64690,
    "Hipparcos Catalogue_1": 64895
  },
  {
    "Astrid #": "ASTRID 003571",
    "HYG Star ID": 98498,
    "Hipparcos Catalogue": 98813,
    "Henry Draper Catalogue": 189931,
    "Distance in Parsecs": 27.6396,
    "mag": 6.91,
    "Absolute Visual Magnitude": 4.702,
    "Spectral Type": "G1V",
    "Color Index": 0.614,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 11.240055,
    "y": -18.698344,
    "z": -16.969438,
    "vx": -0.00002324,
    "vy": 0.00005899,
    "vz": -0.00001726,
    "rarad": 5.25363487691339,
    "decrad": -0.661069466060268,
    "pmrarad": 3.79075816826388e-7,
    "pmdecrad": -0.000001881949745,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 98498,
    "lum": 1.1460405911366,
    "ra": 20.067407,
    "dec": -37.87649,
    "pmra": 78.19,
    "pmdec": -388.18,
    "rv": -37.9,
    "Asset Name": "ASTRID 003571",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Sad",
    "Hair": "Elegant",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00002324,
    "y_1": 0.00005899,
    "z_1": -0.00001726,
    "Distance in Parsecs_1": 27.6396,
    "HYG Star ID_1": 98498,
    "Hipparcos Catalogue_1": 98813,
    "Henry Draper Catalogue_1": 189931
  },
  {
    "Astrid #": "ASTRID 003572",
    "HYG Star ID": 90187,
    "Hipparcos Catalogue": 90466,
    "Distance in Parsecs": 27.6472,
    "mag": 9.84,
    "Absolute Visual Magnitude": 7.632,
    "Spectral Type": "K7",
    "Color Index": 1.171,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.087229,
    "y": -17.250527,
    "z": -21.504197,
    "vx": -0.00001421,
    "vy": -0.00001741,
    "vz": 0.00001259,
    "rarad": 4.83280178082254,
    "decrad": -0.891169618424741,
    "pmrarad": -5.85848851583333e-7,
    "pmdecrad": 7.24408601e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 90187,
    "lum": 0.0771258564989213,
    "ra": 18.459943,
    "dec": -51.060258,
    "pmra": -120.84,
    "pmdec": 149.42,
    "rv": 0,
    "Asset Name": "ASTRID 003572",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Guilty Grin",
    "Hair": "Braided Ponytail",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": -0.00001421,
    "y_1": -0.00001741,
    "z_1": 0.00001259,
    "Distance in Parsecs_1": 27.6472,
    "HYG Star ID_1": 90187,
    "Hipparcos Catalogue_1": 90466
  },
  {
    "Astrid #": "ASTRID 003573",
    "HYG Star ID": 114068,
    "Hipparcos Catalogue": 114430,
    "Henry Draper Catalogue": 218804,
    "Harvard Revised Catalogue": 8825,
    "Distance in Parsecs": 27.6472,
    "mag": 5.91,
    "Absolute Visual Magnitude": 3.702,
    "Spectral Type": "F5IV",
    "Color Index": 0.45,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.573117,
    "y": -4.298653,
    "z": 19.046287,
    "vx": -0.00001822,
    "vy": -0.00002374,
    "vz": -0.00005047,
    "rarad": 6.06700049928238,
    "decrad": 0.759990216238438,
    "pmrarad": -9.80196299347222e-7,
    "pmdecrad": -0.000001006715607,
    "flam": 6,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 114068,
    "lum": 2.87872381083525,
    "bf": "6    And",
    "ra": 23.174235,
    "dec": 43.544232,
    "pmra": -202.18,
    "pmdec": -207.65,
    "rv": -43,
    "Asset Name": "ASTRID 003573",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Grecian",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001822,
    "y_1": -0.00002374,
    "z_1": -0.00005047,
    "Distance in Parsecs_1": 27.6472,
    "HYG Star ID_1": 114068,
    "Hipparcos Catalogue_1": 114430,
    "Henry Draper Catalogue_1": 218804,
    "Harvard Revised Catalogue_1": 8825
  },
  {
    "Astrid #": "ASTRID 003574",
    "HYG Star ID": 13364,
    "Hipparcos Catalogue": 13398,
    "Gliese Catalog": "NN 3186",
    "Distance in Parsecs": 27.6549,
    "mag": 10.86,
    "Absolute Visual Magnitude": 8.651,
    "Color Index": 1.26,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 17.994449,
    "y": 16.841432,
    "z": 12.544301,
    "vx": 0.00000937,
    "vy": 0.00001059,
    "vz": -0.00002767,
    "rarad": 0.752311645818274,
    "decrad": 0.470802210947617,
    "pmrarad": 4.80935171111111e-8,
    "pmdecrad": -0.000001122634558,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13364,
    "lum": 0.0301717152260177,
    "var_min": 10.927,
    "var_max": 10.807,
    "ra": 2.873619,
    "dec": 26.97498,
    "pmra": 9.92,
    "pmdec": -231.56,
    "rv": 0,
    "Asset Name": "ASTRID 003574",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Smitten",
    "Hair": "Wavy",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00000937,
    "y_1": 0.00001059,
    "z_1": -0.00002767,
    "Distance in Parsecs_1": 27.6549,
    "HYG Star ID_1": 13364,
    "Hipparcos Catalogue_1": 13398,
    "Gliese Catalog_1": "NN 3186"
  },
  {
    "Astrid #": "ASTRID 003575",
    "HYG Star ID": 34645,
    "Hipparcos Catalogue": 34739,
    "Henry Draper Catalogue": 55720,
    "Distance in Parsecs": 27.6549,
    "mag": 7.5,
    "Absolute Visual Magnitude": 5.291,
    "Spectral Type": "G6V",
    "Color Index": 0.705,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.523562,
    "y": 17.119715,
    "z": -21.005764,
    "vx": -0.00004257,
    "vy": 0.00013453,
    "vz": 0.00000382,
    "rarad": 1.88289538874545,
    "decrad": -0.862615089460822,
    "pmrarad": -2.89433767291666e-8,
    "pmdecrad": 0.000003965775906,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 34645,
    "lum": 0.666192900223454,
    "ra": 7.192131,
    "dec": -49.424204,
    "pmra": -5.97,
    "pmdec": 818,
    "rv": 86.9,
    "Asset Name": "ASTRID 003575",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Goddess",
    "Outfit": "Basketball",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00004257,
    "y_1": 0.00013453,
    "z_1": 0.00000382,
    "Distance in Parsecs_1": 27.6549,
    "HYG Star ID_1": 34645,
    "Hipparcos Catalogue_1": 34739,
    "Henry Draper Catalogue_1": 55720
  },
  {
    "Astrid #": "ASTRID 003576",
    "HYG Star ID": 53482,
    "Hipparcos Catalogue": 53637,
    "Distance in Parsecs": 27.6549,
    "mag": 10.52,
    "Absolute Visual Magnitude": 8.311,
    "Spectral Type": "M0",
    "Color Index": 1.367,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.740387,
    "y": 4.886896,
    "z": 20.644381,
    "vx": -0.00001165,
    "vy": -0.0000049100000000000004,
    "vz": -0.00000885,
    "rarad": 2.87279084512527,
    "decrad": 0.842786186696201,
    "pmrarad": 2.82985745340277e-7,
    "pmdecrad": -4.81177577e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 53482,
    "lum": 0.0412667246322935,
    "var_min": 10.566,
    "var_max": 10.456,
    "ra": 10.973253,
    "dec": 48.288092,
    "pmra": 58.37,
    "pmdec": -99.25,
    "rv": 0,
    "Asset Name": "ASTRID 003576",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Laughing",
    "Hair": "Honest Lad",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001165,
    "y_1": -0.0000049100000000000004,
    "z_1": -0.00000885,
    "Distance in Parsecs_1": 27.6549,
    "HYG Star ID_1": 53482,
    "Hipparcos Catalogue_1": 53637
  },
  {
    "Astrid #": "ASTRID 003577",
    "HYG Star ID": 118061,
    "Gliese Catalog": "Gl 56.3B",
    "Distance in Parsecs": 27.6549,
    "mag": 10.7,
    "Absolute Visual Magnitude": 8.491,
    "Spectral Type": "M0",
    "Color Index": 1.41,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 26.038909,
    "y": 9.305423,
    "z": -0.421733,
    "vx": -0.00001973,
    "vy": 0.00005364,
    "vz": -0.00003502,
    "rarad": 0.343221925884147,
    "decrad": -0.0152504425446,
    "pmrarad": 0.00000206685768294444,
    "pmdecrad": -0.000001266570892,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 6117,
    "base": "Gl  56.3",
    "lum": 0.0349623004002701,
    "ra": 1.311011,
    "dec": -0.873786,
    "pmra": 426.32,
    "pmdec": -261.25,
    "rv": 0,
    "Asset Name": "ASTRID 003577",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Skater Helmet",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00001973,
    "y_1": 0.00005364,
    "z_1": -0.00003502,
    "Distance in Parsecs_1": 27.6549,
    "HYG Star ID_1": 118061,
    "Gliese Catalog_1": "Gl 56.3B"
  },
  {
    "Astrid #": "ASTRID 003578",
    "HYG Star ID": 37691,
    "Hipparcos Catalogue": 37798,
    "Gliese Catalog": "Gl 287",
    "Distance in Parsecs": 27.6625,
    "mag": 10.19,
    "Absolute Visual Magnitude": 7.981,
    "Spectral Type": "M0",
    "Color Index": 1.349,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.228343,
    "y": 24.791264,
    "z": 1.031686,
    "vx": 0.00000139,
    "vy": -0.00002211,
    "vz": -0.00002811,
    "rarad": 2.02903104383655,
    "decrad": 0.037304388832685,
    "pmrarad": 3.08438463569444e-7,
    "pmdecrad": -9.87904836e-7,
    "con": "CMi",
    "Full Constellation Name Formula 1": "Canis Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Minor",
    "comp": 1,
    "comp_primary": 37691,
    "lum": 0.0559242283040899,
    "ra": 7.750328,
    "dec": 2.137384,
    "pmra": 63.62,
    "pmdec": -203.77,
    "rv": -21,
    "Asset Name": "ASTRID 003578",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Malevolent",
    "Hair": "Curly",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Minor",
    "x_1": 0.00000139,
    "y_1": -0.00002211,
    "z_1": -0.00002811,
    "Distance in Parsecs_1": 27.6625,
    "HYG Star ID_1": 37691,
    "Hipparcos Catalogue_1": 37798,
    "Gliese Catalog_1": "Gl 287"
  },
  {
    "Astrid #": "ASTRID 003579",
    "HYG Star ID": 23797,
    "Hipparcos Catalogue": 23852,
    "Henry Draper Catalogue": 33021,
    "Harvard Revised Catalogue": 1662,
    "Gliese Catalog": "NN 3334",
    "Distance in Parsecs": 27.6702,
    "mag": 6.15,
    "Absolute Visual Magnitude": 3.94,
    "Spectral Type": "G1IV",
    "Color Index": 0.625,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.181471,
    "y": 26.583543,
    "z": 4.553426,
    "vx": -0.00000243,
    "vy": -0.00001376,
    "vz": -0.00005432,
    "rarad": 1.34232640801708,
    "decrad": 0.165313745255313,
    "pmrarad": -2.71980474791666e-8,
    "pmdecrad": -0.000001853685107,
    "flam": 13,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 23797,
    "lum": 2.31206479017559,
    "bf": "13    Ori",
    "ra": 5.127309,
    "dec": 9.47178,
    "pmra": -5.61,
    "pmdec": -382.35,
    "rv": -22.2,
    "Asset Name": "ASTRID 003579",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Prince",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000243,
    "y_1": -0.00001376,
    "z_1": -0.00005432,
    "Distance in Parsecs_1": 27.6702,
    "HYG Star ID_1": 23797,
    "Hipparcos Catalogue_1": 23852,
    "Henry Draper Catalogue_1": 33021,
    "Harvard Revised Catalogue_1": 1662,
    "Gliese Catalog_1": "NN 3334"
  },
  {
    "Astrid #": "ASTRID 003580",
    "HYG Star ID": 20500,
    "Hipparcos Catalogue": 20552,
    "Henry Draper Catalogue": 28255,
    "Harvard Revised Catalogue": 1405,
    "Gliese Catalog": "Wo 9155A",
    "Distance in Parsecs": 27.7008,
    "mag": 6.28,
    "Absolute Visual Magnitude": 4.068,
    "Spectral Type": "G4V+...",
    "Color Index": 0.659,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.112415,
    "y": 13.761577,
    "z": -23.250573,
    "vx": 0.00000826,
    "vy": -0.00001592,
    "vz": -9.2e-7,
    "rarad": 1.15280878062072,
    "decrad": -0.996082731714852,
    "pmrarad": -5.05757631555555e-7,
    "pmdecrad": -3.57453126e-7,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 20500,
    "base": "Wo 9155",
    "lum": 2.0549440413453,
    "ra": 4.403405,
    "dec": -57.071337,
    "pmra": -104.32,
    "pmdec": -73.73,
    "rv": -5.2,
    "Asset Name": "ASTRID 003580",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Ninja",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 0.00000826,
    "y_1": -0.00001592,
    "z_1": -9.2e-7,
    "Distance in Parsecs_1": 27.7008,
    "HYG Star ID_1": 20500,
    "Hipparcos Catalogue_1": 20552,
    "Henry Draper Catalogue_1": 28255,
    "Harvard Revised Catalogue_1": 1405,
    "Gliese Catalog_1": "Wo 9155A"
  },
  {
    "Astrid #": "ASTRID 003581",
    "HYG Star ID": 99254,
    "Hipparcos Catalogue": 99572,
    "Henry Draper Catalogue": 191862,
    "Harvard Revised Catalogue": 7715,
    "Gliese Catalog": "NN 4139",
    "Distance in Parsecs": 27.7008,
    "mag": 5.84,
    "Absolute Visual Magnitude": 3.628,
    "Spectral Type": "F5V",
    "Color Index": 0.476,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 14.765323,
    "y": -22.643225,
    "z": -6.051042,
    "vx": 0.00003092,
    "vy": 9e-8,
    "vz": -0.00003068,
    "rarad": 5.29022880089633,
    "decrad": -0.220216874586141,
    "pmrarad": 9.36902437673611e-7,
    "pmdecrad": -9.48295559e-7,
    "bayer": "Xi-2",
    "flam": 2,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 99254,
    "lum": 3.08176841661151,
    "bf": "2Xi 2Cap",
    "ra": 20.207186,
    "dec": -12.617497,
    "pmra": 193.25,
    "pmdec": -195.6,
    "rv": 22.6,
    "Asset Name": "ASTRID 003581",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Slick",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00003092,
    "y_1": 9e-8,
    "z_1": -0.00003068,
    "Distance in Parsecs_1": 27.7008,
    "HYG Star ID_1": 99254,
    "Hipparcos Catalogue_1": 99572,
    "Henry Draper Catalogue_1": 191862,
    "Harvard Revised Catalogue_1": 7715,
    "Gliese Catalog_1": "NN 4139"
  },
  {
    "Astrid #": "ASTRID 003582",
    "HYG Star ID": 117512,
    "Hipparcos Catalogue": 117880,
    "Henry Draper Catalogue": 224022,
    "Harvard Revised Catalogue": 9046,
    "Distance in Parsecs": 27.7008,
    "mag": 6.03,
    "Absolute Visual Magnitude": 3.818,
    "Spectral Type": "F8IV",
    "Color Index": 0.572,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 21.120666,
    "y": -0.493707,
    "z": -17.91656,
    "vx": -0.00000399,
    "vy": 0.00004945,
    "vz": 0.00000974,
    "rarad": 6.25980914324464,
    "decrad": -0.703368775729286,
    "pmrarad": 0.00000178139938783333,
    "pmdecrad": 1.47916653e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 117512,
    "lum": 2.5870212734646,
    "ra": 23.91071,
    "dec": -40.300062,
    "pmra": 367.44,
    "pmdec": 30.51,
    "rv": -10,
    "Asset Name": "ASTRID 003582",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Mischievous",
    "Hair": "Prince",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000399,
    "y_1": 0.00004945,
    "z_1": 0.00000974,
    "Distance in Parsecs_1": 27.7008,
    "HYG Star ID_1": 117512,
    "Hipparcos Catalogue_1": 117880,
    "Henry Draper Catalogue_1": 224022,
    "Harvard Revised Catalogue_1": 9046
  },
  {
    "Astrid #": "ASTRID 003583",
    "HYG Star ID": 13308,
    "Hipparcos Catalogue": 13342,
    "Distance in Parsecs": 27.7085,
    "mag": 10.01,
    "Absolute Visual Magnitude": 7.797,
    "Spectral Type": "M0",
    "Color Index": 1.19,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 19.939107,
    "y": 18.547099,
    "z": 5.11841,
    "vx": 0.00001355,
    "vy": -0.00000954,
    "vz": -0.00001824,
    "rarad": 0.749244998913092,
    "decrad": 0.185790496238998,
    "pmrarad": -5.85267075166666e-7,
    "pmdecrad": -6.69915543e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13308,
    "lum": 0.0662521535902582,
    "ra": 2.861905,
    "dec": 10.645011,
    "pmra": -120.72,
    "pmdec": -138.18,
    "rv": 0,
    "Asset Name": "ASTRID 003583",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Heartbreaker",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00001355,
    "y_1": -0.00000954,
    "z_1": -0.00001824,
    "Distance in Parsecs_1": 27.7085,
    "HYG Star ID_1": 13308,
    "Hipparcos Catalogue_1": 13342
  },
  {
    "Astrid #": "ASTRID 003584",
    "HYG Star ID": 97129,
    "Hipparcos Catalogue": 97438,
    "Henry Draper Catalogue": 187748,
    "Distance in Parsecs": 27.7085,
    "mag": 6.64,
    "Absolute Visual Magnitude": 4.427,
    "Spectral Type": "G0",
    "Color Index": 0.592,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.413203,
    "y": -12.551741,
    "z": 23.85548,
    "vx": -0.00000505,
    "vy": 0.00001454,
    "vz": 0.00000497,
    "rarad": 5.18475062013385,
    "decrad": 1.03712528249366,
    "pmrarad": 7.63581546875e-8,
    "pmdecrad": 5.64856419e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 97129,
    "lum": 1.47638627775386,
    "ra": 19.804289,
    "dec": 59.422902,
    "pmra": 15.75,
    "pmdec": 116.51,
    "rv": -3.4,
    "Asset Name": "ASTRID 003584",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Grecian",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000505,
    "y_1": 0.00001454,
    "z_1": 0.00000497,
    "Distance in Parsecs_1": 27.7085,
    "HYG Star ID_1": 97129,
    "Hipparcos Catalogue_1": 97438,
    "Henry Draper Catalogue_1": 187748
  },
  {
    "Astrid #": "ASTRID 003585",
    "HYG Star ID": 68333,
    "Hipparcos Catalogue": 68551,
    "Gliese Catalog": "Gl 536.1A",
    "Distance in Parsecs": 27.7162,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.416,
    "Spectral Type": "M2...",
    "Color Index": 1.424,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -23.014284,
    "y": -13.553625,
    "z": 7.404359,
    "vx": 0.00001558,
    "vy": -0.00000722,
    "vz": -0.00000499,
    "rarad": 3.67382678931623,
    "decrad": 0.2704343831408,
    "pmrarad": 5.09636141e-7,
    "pmdecrad": -7.941248e-8,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 68333,
    "base": "Gl 536.1",
    "lum": 0.0374627798344059,
    "var_min": 10.694,
    "var_max": 10.574,
    "ra": 14.032985,
    "dec": 15.494749,
    "pmra": 105.12,
    "pmdec": -16.38,
    "rv": -10.5,
    "Asset Name": "ASTRID 003585",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "Medium Bob",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00001558,
    "y_1": -0.00000722,
    "z_1": -0.00000499,
    "Distance in Parsecs_1": 27.7162,
    "HYG Star ID_1": 68333,
    "Hipparcos Catalogue_1": 68551,
    "Gliese Catalog_1": "Gl 536.1A"
  },
  {
    "Astrid #": "ASTRID 003586",
    "HYG Star ID": 49668,
    "Hipparcos Catalogue": 49809,
    "Henry Draper Catalogue": 88215,
    "Harvard Revised Catalogue": 3991,
    "Distance in Parsecs": 27.7393,
    "mag": 5.3,
    "Absolute Visual Magnitude": 3.085,
    "Spectral Type": "F2/F3IV/V",
    "Color Index": 0.368,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -23.997614,
    "y": 12.479314,
    "z": -6.153163,
    "vx": -0.00000987,
    "vy": 0.00002367,
    "vz": -0.00001953,
    "rarad": 2.6620553068876,
    "decrad": -0.22368005754629,
    "pmrarad": -5.92927131319444e-7,
    "pmdecrad": -5.29319576e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 49668,
    "lum": 5.0815944256056,
    "ra": 10.168302,
    "dec": -12.815923,
    "pmra": -122.3,
    "pmdec": -109.18,
    "rv": 23,
    "Asset Name": "ASTRID 003586",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Manbun",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000987,
    "y_1": 0.00002367,
    "z_1": -0.00001953,
    "Distance in Parsecs_1": 27.7393,
    "HYG Star ID_1": 49668,
    "Hipparcos Catalogue_1": 49809,
    "Henry Draper Catalogue_1": 88215,
    "Harvard Revised Catalogue_1": 3991
  },
  {
    "Astrid #": "ASTRID 003587",
    "HYG Star ID": 102992,
    "Hipparcos Catalogue": 103324,
    "Distance in Parsecs": 27.7469,
    "mag": 11.47,
    "Absolute Visual Magnitude": 9.254,
    "Spectral Type": "M2",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.653051,
    "y": -9.996202,
    "z": -24.016349,
    "vx": 0.00002418,
    "vy": 0.00007417,
    "vz": -0.00002116,
    "rarad": 5.48030340737924,
    "decrad": -1.04624893795271,
    "pmrarad": 0.00000248370048548611,
    "pmdecrad": -0.000001522508882,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 102992,
    "lum": 0.0173141031289015,
    "var_min": 11.548,
    "var_max": 11.368,
    "ra": 20.933217,
    "dec": -59.945648,
    "pmra": 512.3,
    "pmdec": -314.04,
    "rv": 0,
    "Asset Name": "ASTRID 003587",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Fire",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00002418,
    "y_1": 0.00007417,
    "z_1": -0.00002116,
    "Distance in Parsecs_1": 27.7469,
    "HYG Star ID_1": 102992,
    "Hipparcos Catalogue_1": 103324
  },
  {
    "Astrid #": "ASTRID 003588",
    "HYG Star ID": 112973,
    "Hipparcos Catalogue": 113333,
    "Distance in Parsecs": 27.7546,
    "mag": 9.93,
    "Absolute Visual Magnitude": 7.713,
    "Spectral Type": "M0p",
    "Color Index": 1.18,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.588919,
    "y": -6.639138,
    "z": 13.030831,
    "vx": 0.00001639,
    "vy": 0.00004453,
    "vz": -0.00000698,
    "rarad": 6.00882908259603,
    "decrad": 0.488726295482041,
    "pmrarad": 0.00000170441097536111,
    "pmdecrad": -2.84973481e-7,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112973,
    "lum": 0.0715813689911473,
    "var_min": 10.04,
    "var_max": 9.89,
    "ra": 22.952036,
    "dec": 28.001954,
    "pmra": 351.56,
    "pmdec": -58.78,
    "rv": 0,
    "Asset Name": "ASTRID 003588",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Wavy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001639,
    "y_1": 0.00004453,
    "z_1": -0.00000698,
    "Distance in Parsecs_1": 27.7546,
    "HYG Star ID_1": 112973,
    "Hipparcos Catalogue_1": 113333
  },
  {
    "Astrid #": "ASTRID 003589",
    "HYG Star ID": 23429,
    "Hipparcos Catalogue": 23484,
    "Henry Draper Catalogue": 31675,
    "Harvard Revised Catalogue": 1594,
    "Distance in Parsecs": 27.7624,
    "mag": 6.2,
    "Absolute Visual Magnitude": 3.983,
    "Spectral Type": "F6V:",
    "Color Index": 0.509,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.696981,
    "y": 10.588526,
    "z": 25.521927,
    "vx": 0.00000311,
    "vy": 0.00004906,
    "vz": -0.00000177,
    "rarad": 1.32138720540028,
    "decrad": 1.16628091368738,
    "pmrarad": 3.27588603951388e-7,
    "pmdecrad": -0.000001624950013,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 23429,
    "lum": 2.2222862543282,
    "ra": 5.047327,
    "dec": 66.822974,
    "pmra": 67.57,
    "pmdec": -335.17,
    "rv": 17,
    "Asset Name": "ASTRID 003589",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Guilty Grin",
    "Hair": "Pompadour",
    "Outfit": "River Spirit",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00000311,
    "y_1": 0.00004906,
    "z_1": -0.00000177,
    "Distance in Parsecs_1": 27.7624,
    "HYG Star ID_1": 23429,
    "Hipparcos Catalogue_1": 23484,
    "Henry Draper Catalogue_1": 31675,
    "Harvard Revised Catalogue_1": 1594
  },
  {
    "Astrid #": "ASTRID 003590",
    "HYG Star ID": 31009,
    "Hipparcos Catalogue": 31083,
    "Henry Draper Catalogue": 46090,
    "Distance in Parsecs": 27.7778,
    "mag": 7.14,
    "Absolute Visual Magnitude": 4.922,
    "Spectral Type": "G0",
    "Color Index": 0.71,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.783982,
    "y": 27.482671,
    "z": 1.410831,
    "vx": 0.00001482,
    "vy": 0.00000196,
    "vz": 0.00000157,
    "rarad": 1.70762214009555,
    "decrad": 0.0508117232069387,
    "pmrarad": -5.38046222680555e-7,
    "pmdecrad": 5.6480793e-8,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 31009,
    "lum": 0.93583654358353,
    "ra": 6.522636,
    "dec": 2.911297,
    "pmra": -110.98,
    "pmdec": 11.65,
    "rv": 0,
    "Asset Name": "ASTRID 003590",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Knocked Out",
    "Hair": "Prince",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00001482,
    "y_1": 0.00000196,
    "z_1": 0.00000157,
    "Distance in Parsecs_1": 27.7778,
    "HYG Star ID_1": 31009,
    "Hipparcos Catalogue_1": 31083,
    "Henry Draper Catalogue_1": 46090
  },
  {
    "Astrid #": "ASTRID 003591",
    "HYG Star ID": 119296,
    "Gliese Catalog": "Wo 9664B",
    "Distance in Parsecs": 27.7778,
    "mag": 8.7,
    "Absolute Visual Magnitude": 6.482,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 6.18928,
    "y": -12.893098,
    "z": -23.813169,
    "vx": 0.00001108,
    "vy": 0.00002907,
    "vz": -0.00001286,
    "rarad": 5.15990803481049,
    "decrad": -1.029950285772,
    "pmrarad": 8.12596209979166e-7,
    "pmdecrad": -8.9929544e-7,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 2,
    "comp_primary": 96648,
    "base": "Wo 9664",
    "lum": 0.222433399848511,
    "ra": 19.709397,
    "dec": -59.011804,
    "pmra": 167.61,
    "pmdec": -185.49,
    "rv": 0,
    "Asset Name": "ASTRID 003591",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Shocked",
    "Hair": "Undercut",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 0.00001108,
    "y_1": 0.00002907,
    "z_1": -0.00001286,
    "Distance in Parsecs_1": 27.7778,
    "HYG Star ID_1": 119296,
    "Gliese Catalog_1": "Wo 9664B"
  },
  {
    "Astrid #": "ASTRID 003592",
    "HYG Star ID": 71598,
    "Hipparcos Catalogue": 71824,
    "Distance in Parsecs": 27.7855,
    "mag": 12.03,
    "Absolute Visual Magnitude": 9.811,
    "Color Index": 1.494,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -21.029119,
    "y": -17.889343,
    "z": 3.12755,
    "vx": -0.00002799,
    "vy": 0.00002491,
    "vz": -0.00004567,
    "rarad": 3.84648950838442,
    "decrad": 0.112799588342297,
    "pmrarad": -0.0000013356132085625,
    "pmdecrad": -0.000001654475166,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 71598,
    "lum": 0.01036573256767,
    "var_min": 12.141,
    "var_max": 11.891,
    "ra": 14.692508,
    "dec": 6.46294,
    "pmra": -275.49,
    "pmdec": -341.26,
    "rv": 0,
    "Asset Name": "ASTRID 003592",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Manbun",
    "Outfit": "Iron Star",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002799,
    "y_1": 0.00002491,
    "z_1": -0.00004567,
    "Distance in Parsecs_1": 27.7855,
    "HYG Star ID_1": 71598,
    "Hipparcos Catalogue_1": 71824
  },
  {
    "Astrid #": "ASTRID 003593",
    "HYG Star ID": 91963,
    "Hipparcos Catalogue": 92250,
    "Henry Draper Catalogue": 173872,
    "Distance in Parsecs": 27.7855,
    "mag": 8.45,
    "Absolute Visual Magnitude": 6.231,
    "Spectral Type": "K0",
    "Color Index": 0.889,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.689471,
    "y": -26.752668,
    "z": -4.89476,
    "vx": 0.00000648,
    "vy": 0.00000694,
    "vz": -0.00003038,
    "rarad": 4.92193630124834,
    "decrad": -0.177086499393086,
    "pmrarad": 2.80028381888888e-7,
    "pmdecrad": -0.000001110902067,
    "con": "Sct",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scutum",
    "FULL CONSTELLATION NAME": "Scutum",
    "comp": 1,
    "comp_primary": 91963,
    "lum": 0.280285092764668,
    "ra": 18.800412,
    "dec": -10.146309,
    "pmra": 57.76,
    "pmdec": -229.14,
    "rv": 0,
    "Asset Name": "ASTRID 003593",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Sad",
    "Hair": "Bangs",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scutum",
    "x_1": 0.00000648,
    "y_1": 0.00000694,
    "z_1": -0.00003038,
    "Distance in Parsecs_1": 27.7855,
    "HYG Star ID_1": 91963,
    "Hipparcos Catalogue_1": 92250,
    "Henry Draper Catalogue_1": 173872
  },
  {
    "Astrid #": "ASTRID 003594",
    "HYG Star ID": 101285,
    "Hipparcos Catalogue": 101612,
    "Henry Draper Catalogue": 195627,
    "Harvard Revised Catalogue": 7848,
    "Distance in Parsecs": 27.7932,
    "mag": 4.75,
    "Absolute Visual Magnitude": 2.53,
    "Spectral Type": "F1III",
    "Color Index": 0.291,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.571694,
    "y": -10.624832,
    "z": -24.209317,
    "vx": -0.00001263,
    "vy": 0.00003071,
    "vz": 0.00000554,
    "rarad": 5.39123544784697,
    "decrad": -1.05735098952574,
    "pmrarad": 3.40096796909722e-7,
    "pmdecrad": -8.99717228e-7,
    "bayer": "Phi-1",
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 101285,
    "lum": 8.47227414140596,
    "bf": "Phi1Pav",
    "ra": 20.593003,
    "dec": -60.581749,
    "pmra": 70.15,
    "pmdec": -185.58,
    "rv": -20,
    "Asset Name": "ASTRID 003594",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Greasy",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00001263,
    "y_1": 0.00003071,
    "z_1": 0.00000554,
    "Distance in Parsecs_1": 27.7932,
    "HYG Star ID_1": 101285,
    "Hipparcos Catalogue_1": 101612,
    "Henry Draper Catalogue_1": 195627,
    "Harvard Revised Catalogue_1": 7848
  },
  {
    "Astrid #": "ASTRID 003595",
    "HYG Star ID": 31059,
    "Hipparcos Catalogue": 31134,
    "Henry Draper Catalogue": 47252,
    "Distance in Parsecs": 27.8009,
    "mag": 8.27,
    "Absolute Visual Magnitude": 6.05,
    "Spectral Type": "G8/K0V",
    "Color Index": 0.781,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.402475,
    "y": 9.996355,
    "z": -25.90361,
    "vx": 0.00001537,
    "vy": -0.00005421,
    "vz": -0.00002395,
    "rarad": 1.71018933217386,
    "decrad": -1.19921338135556,
    "pmrarad": -2.76780130229166e-7,
    "pmdecrad": -0.000002183697779,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 31059,
    "lum": 0.331131121482591,
    "ra": 6.532442,
    "dec": -68.709865,
    "pmra": -57.09,
    "pmdec": -450.42,
    "rv": 2,
    "Asset Name": "ASTRID 003595",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Determined",
    "Hair": "High Bun",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": 0.00001537,
    "y_1": -0.00005421,
    "z_1": -0.00002395,
    "Distance in Parsecs_1": 27.8009,
    "HYG Star ID_1": 31059,
    "Hipparcos Catalogue_1": 31134,
    "Henry Draper Catalogue_1": 47252
  },
  {
    "Astrid #": "ASTRID 003596",
    "HYG Star ID": 83540,
    "Hipparcos Catalogue": 83797,
    "Henry Draper Catalogue": 154387,
    "Distance in Parsecs": 27.8319,
    "mag": 9.42,
    "Absolute Visual Magnitude": 7.197,
    "Spectral Type": "K4V",
    "Color Index": 1.111,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.206656,
    "y": -18.049639,
    "z": -20.763652,
    "vx": 0.00000422,
    "vy": 0.00000283,
    "vz": -0.00000331,
    "rarad": 4.48341535922476,
    "decrad": -0.842092103493743,
    "pmrarad": 1.24597115902777e-7,
    "pmdecrad": -1.78799285e-7,
    "con": "Ara",
    "Full Constellation Name Formula 1": "Ara",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ara",
    "comp": 1,
    "comp_primary": 83540,
    "lum": 0.115133047415581,
    "ra": 17.125385,
    "dec": -48.248323,
    "pmra": 25.7,
    "pmdec": -36.88,
    "rv": 0,
    "Asset Name": "ASTRID 003596",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Good Boy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ara",
    "x_1": 0.00000422,
    "y_1": 0.00000283,
    "z_1": -0.00000331,
    "Distance in Parsecs_1": 27.8319,
    "HYG Star ID_1": 83540,
    "Hipparcos Catalogue_1": 83797,
    "Henry Draper Catalogue_1": 154387
  },
  {
    "Astrid #": "ASTRID 003597",
    "HYG Star ID": 108749,
    "Hipparcos Catalogue": 109095,
    "Distance in Parsecs": 27.8319,
    "mag": 11.14,
    "Absolute Visual Magnitude": 8.917,
    "Spectral Type": "M0",
    "Color Index": 1.39,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 24.205185,
    "y": -13.148871,
    "z": 3.9788,
    "vx": 3.5e-7,
    "vy": 0.00000113,
    "vz": 0.0000016,
    "rarad": 5.78555822009493,
    "decrad": 0.143449730933297,
    "pmrarad": 4.18394206319444e-8,
    "pmdecrad": 5.7935234e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 108749,
    "lum": 0.0236156552391771,
    "var_min": 11.233,
    "var_max": 10.873,
    "ra": 22.099205,
    "dec": 8.219064,
    "pmra": 8.63,
    "pmdec": 11.95,
    "rv": 0,
    "Asset Name": "ASTRID 003597",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Elegant",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 3.5e-7,
    "y_1": 0.00000113,
    "z_1": 0.0000016,
    "Distance in Parsecs_1": 27.8319,
    "HYG Star ID_1": 108749,
    "Hipparcos Catalogue_1": 109095
  },
  {
    "Astrid #": "ASTRID 003598",
    "HYG Star ID": 8417,
    "Hipparcos Catalogue": 8433,
    "Henry Draper Catalogue": 11007,
    "Harvard Revised Catalogue": 523,
    "Gliese Catalog": "NN 3115",
    "Distance in Parsecs": 27.8552,
    "mag": 5.78,
    "Absolute Visual Magnitude": 3.555,
    "Spectral Type": "F8V",
    "Color Index": 0.572,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.855444,
    "y": 10.705904,
    "z": 15.044369,
    "vx": -0.0000293,
    "vy": -0.00004035,
    "vz": 0.00001916,
    "rarad": 0.474262788113816,
    "decrad": 0.570551792942941,
    "pmrarad": -8.08329849590277e-7,
    "pmdecrad": 0.000001441593479,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 8417,
    "lum": 3.29609712177457,
    "ra": 1.81155,
    "dec": 32.69021,
    "pmra": -166.73,
    "pmdec": 297.35,
    "rv": -26.5,
    "Asset Name": "ASTRID 003598",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Prince",
    "Outfit": "Samurai",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -0.0000293,
    "y_1": -0.00004035,
    "z_1": 0.00001916,
    "Distance in Parsecs_1": 27.8552,
    "HYG Star ID_1": 8417,
    "Hipparcos Catalogue_1": 8433,
    "Henry Draper Catalogue_1": 11007,
    "Harvard Revised Catalogue_1": 523,
    "Gliese Catalog_1": "NN 3115"
  },
  {
    "Astrid #": "ASTRID 003599",
    "HYG Star ID": 52370,
    "Hipparcos Catalogue": 52521,
    "Henry Draper Catalogue": 93083,
    "Gliese Catalog": "GJ 1137",
    "Distance in Parsecs": 27.8552,
    "mag": 8.3,
    "Absolute Visual Magnitude": 6.075,
    "Spectral Type": "K3V",
    "Color Index": 0.945,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.954758,
    "y": 7.522298,
    "z": -15.405733,
    "vx": -0.00001887,
    "vy": 0.00001972,
    "vz": -0.00004059,
    "rarad": 2.81150185661606,
    "decrad": -0.586029585691297,
    "pmrarad": -4.50101021027777e-7,
    "pmdecrad": -7.32650434e-7,
    "con": "Ant",
    "Full Constellation Name Formula 1": "Antlia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Antlia",
    "comp": 1,
    "comp_primary": 52370,
    "lum": 0.323593656929628,
    "ra": 10.739146,
    "dec": -33.577022,
    "pmra": -92.84,
    "pmdec": -151.12,
    "rv": 41.7,
    "Asset Name": "ASTRID 003599",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Honest Lad",
    "Outfit": "Emperor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Antlia",
    "x_1": -0.00001887,
    "y_1": 0.00001972,
    "z_1": -0.00004059,
    "Distance in Parsecs_1": 27.8552,
    "HYG Star ID_1": 52370,
    "Hipparcos Catalogue_1": 52521,
    "Henry Draper Catalogue_1": 93083,
    "Gliese Catalog_1": "GJ 1137"
  },
  {
    "Astrid #": "ASTRID 003600",
    "HYG Star ID": 19811,
    "Hipparcos Catalogue": 19861,
    "Henry Draper Catalogue": 26581,
    "Gliese Catalog": "Gl 165.1",
    "Distance in Parsecs": 27.8629,
    "mag": 8.71,
    "Absolute Visual Magnitude": 6.485,
    "Spectral Type": "K0",
    "Color Index": 1.012,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.404172,
    "y": 13.063904,
    "z": 23.762852,
    "vx": -0.00000631,
    "vy": 0.00004421,
    "vz": 0.00000366,
    "rarad": 1.11499699719107,
    "decrad": 1.02140629415154,
    "pmrarad": 9.01850408569444e-7,
    "pmdecrad": -0.000001061354109,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 19811,
    "lum": 0.221819641980021,
    "ra": 4.258975,
    "dec": 58.52227,
    "pmra": 186.02,
    "pmdec": -218.92,
    "rv": 21.9,
    "Asset Name": "ASTRID 003600",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Unkempt",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000631,
    "y_1": 0.00004421,
    "z_1": 0.00000366,
    "Distance in Parsecs_1": 27.8629,
    "HYG Star ID_1": 19811,
    "Hipparcos Catalogue_1": 19861,
    "Henry Draper Catalogue_1": 26581,
    "Gliese Catalog_1": "Gl 165.1"
  },
  {
    "Astrid #": "ASTRID 003601",
    "HYG Star ID": 63411,
    "Hipparcos Catalogue": 63613,
    "Henry Draper Catalogue": 112985,
    "Harvard Revised Catalogue": 4923,
    "Distance in Parsecs": 27.8707,
    "mag": 3.61,
    "Absolute Visual Magnitude": 1.384,
    "Spectral Type": "K2III",
    "Color Index": 1.19,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.497455,
    "y": -2.367379,
    "z": -26.438289,
    "vx": 8.9e-7,
    "vy": -0.00003672,
    "vz": -0.00003689,
    "rarad": 3.41327723637911,
    "decrad": -1.24876309756546,
    "pmrarad": 0.00000127787189920833,
    "pmdecrad": -1.12864624e-7,
    "bayer": "Del",
    "con": "Mus",
    "Full Constellation Name Formula 1": "Musca",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Musca",
    "comp": 1,
    "comp_primary": 63411,
    "lum": 24.344451836922,
    "bf": "Del Mus",
    "ra": 13.037759,
    "dec": -71.548855,
    "pmra": 263.58,
    "pmdec": -23.28,
    "rv": 37,
    "Asset Name": "ASTRID 003601",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Naughty Dreams",
    "Hair": "Horns",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Musca",
    "x_1": 8.9e-7,
    "y_1": -0.00003672,
    "z_1": -0.00003689,
    "Distance in Parsecs_1": 27.8707,
    "HYG Star ID_1": 63411,
    "Hipparcos Catalogue_1": 63613,
    "Henry Draper Catalogue_1": 112985,
    "Harvard Revised Catalogue_1": 4923
  },
  {
    "Astrid #": "ASTRID 003602",
    "HYG Star ID": 97339,
    "Hipparcos Catalogue": 97650,
    "Henry Draper Catalogue": 187532,
    "Harvard Revised Catalogue": 7553,
    "Distance in Parsecs": 27.8707,
    "mag": 5.38,
    "Absolute Visual Magnitude": 3.154,
    "Spectral Type": "F0V",
    "Color Index": 0.402,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.72542,
    "y": -24.243563,
    "z": -5.205022,
    "vx": -6.9e-7,
    "vy": -0.00000808,
    "vz": 0.00000308,
    "rarad": 5.19575675467427,
    "decrad": -0.187858716309093,
    "pmrarad": -1.56837225659722e-7,
    "pmdecrad": 1.54364675e-7,
    "flam": 51,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 97339,
    "lum": 4.76869998101777,
    "bf": "51    Aql",
    "ra": 19.846329,
    "dec": -10.763512,
    "pmra": -32.35,
    "pmdec": 31.84,
    "rv": 6,
    "Asset Name": "ASTRID 003602",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Bob",
    "Outfit": "Basketball",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -6.9e-7,
    "y_1": -0.00000808,
    "z_1": 0.00000308,
    "Distance in Parsecs_1": 27.8707,
    "HYG Star ID_1": 97339,
    "Hipparcos Catalogue_1": 97650,
    "Henry Draper Catalogue_1": 187532,
    "Harvard Revised Catalogue_1": 7553
  },
  {
    "Astrid #": "ASTRID 003603",
    "HYG Star ID": 105622,
    "Hipparcos Catalogue": 105963,
    "Gliese Catalog": "NN 4196",
    "Distance in Parsecs": 27.8707,
    "mag": 11.06,
    "Absolute Visual Magnitude": 8.834,
    "Spectral Type": "M0:",
    "Color Index": 1.785,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.174383,
    "y": -14.257032,
    "z": 15.595023,
    "vx": -0.00001227,
    "vy": -0.00003799,
    "vz": -0.00002043,
    "rarad": 5.61799598802627,
    "decrad": 0.593841583101026,
    "pmrarad": -0.0000013443398548125,
    "pmdecrad": -8.84445597e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 105622,
    "lum": 0.0254917705050911,
    "var_min": 11.134,
    "var_max": 10.964,
    "ra": 21.459164,
    "dec": 34.024616,
    "pmra": -277.29,
    "pmdec": -182.43,
    "rv": 0,
    "Asset Name": "ASTRID 003603",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Scared",
    "Hair": "Middle Part",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00001227,
    "y_1": -0.00003799,
    "z_1": -0.00002043,
    "Distance in Parsecs_1": 27.8707,
    "HYG Star ID_1": 105622,
    "Hipparcos Catalogue_1": 105963,
    "Gliese Catalog_1": "NN 4196"
  },
  {
    "Astrid #": "ASTRID 003604",
    "HYG Star ID": 11731,
    "Hipparcos Catalogue": 11759,
    "Henry Draper Catalogue": 15767,
    "Distance in Parsecs": 27.8862,
    "mag": 8.67,
    "Absolute Visual Magnitude": 6.443,
    "Spectral Type": "K2V",
    "Color Index": 0.935,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 21.219567,
    "y": 16.535026,
    "z": -7.345955,
    "vx": 0.00000292,
    "vy": -0.0000107,
    "vz": -0.00001565,
    "rarad": 0.661950517291171,
    "decrad": -0.266572118816275,
    "pmrarad": -3.67052437548611e-7,
    "pmdecrad": -5.8192186e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 11731,
    "lum": 0.230568513694728,
    "ra": 2.528465,
    "dec": -15.273457,
    "pmra": -75.71,
    "pmdec": -120.03,
    "rv": 0,
    "Asset Name": "ASTRID 003604",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Battle Ready",
    "Hair": "Pompadour",
    "Outfit": "Samurai",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00000292,
    "y_1": -0.0000107,
    "z_1": -0.00001565,
    "Distance in Parsecs_1": 27.8862,
    "HYG Star ID_1": 11731,
    "Hipparcos Catalogue_1": 11759,
    "Henry Draper Catalogue_1": 15767
  },
  {
    "Astrid #": "ASTRID 003605",
    "HYG Star ID": 58138,
    "Hipparcos Catalogue": 58314,
    "Henry Draper Catalogue": 103847,
    "Distance in Parsecs": 27.894,
    "mag": 8.07,
    "Absolute Visual Magnitude": 5.842,
    "Spectral Type": "G5",
    "Color Index": 0.83,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -26.212883,
    "y": 0.287983,
    "z": 9.532944,
    "vx": 0.00000304,
    "vy": 0.00005204,
    "vz": 0.00000679,
    "rarad": 3.13060781714228,
    "decrad": 0.348784865988762,
    "pmrarad": -0.00000186672659561111,
    "pmdecrad": 2.5908443e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 58138,
    "lum": 0.401051366086575,
    "var": "GR",
    "var_min": 8.101,
    "var_max": 8.041,
    "ra": 11.958041,
    "dec": 19.983901,
    "pmra": -385.04,
    "pmdec": 53.44,
    "rv": 0,
    "Asset Name": "ASTRID 003605",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Gimme Blood",
    "Hair": "Fedora",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000304,
    "y_1": 0.00005204,
    "z_1": 0.00000679,
    "Distance in Parsecs_1": 27.894,
    "HYG Star ID_1": 58138,
    "Hipparcos Catalogue_1": 58314,
    "Henry Draper Catalogue_1": 103847
  },
  {
    "Astrid #": "ASTRID 003606",
    "HYG Star ID": 71304,
    "Hipparcos Catalogue": 71530,
    "Henry Draper Catalogue": 128020,
    "Harvard Revised Catalogue": 5443,
    "Gliese Catalog": "Gl 558.1",
    "Distance in Parsecs": 27.894,
    "mag": 6.03,
    "Absolute Visual Magnitude": 3.802,
    "Spectral Type": "F7V",
    "Color Index": 0.506,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.093175,
    "y": -6.657924,
    "z": -25.8502,
    "vx": 0.00000621,
    "vy": 0.00006609,
    "vz": 0.00001414,
    "rarad": 3.83002275039391,
    "decrad": -1.1856372730522,
    "pmrarad": -0.00000168841212390277,
    "pmdecrad": -0.000001364120252,
    "con": "Cir",
    "Full Constellation Name Formula 1": "Circinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Circinus",
    "comp": 1,
    "comp_primary": 71304,
    "lum": 2.62542731899369,
    "ra": 14.629609,
    "dec": -67.932012,
    "pmra": -348.26,
    "pmdec": -281.37,
    "rv": -30,
    "Asset Name": "ASTRID 003606",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Prince",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Circinus",
    "x_1": 0.00000621,
    "y_1": 0.00006609,
    "z_1": 0.00001414,
    "Distance in Parsecs_1": 27.894,
    "HYG Star ID_1": 71304,
    "Hipparcos Catalogue_1": 71530,
    "Henry Draper Catalogue_1": 128020,
    "Harvard Revised Catalogue_1": 5443,
    "Gliese Catalog_1": "Gl 558.1"
  },
  {
    "Astrid #": "ASTRID 003607",
    "HYG Star ID": 96168,
    "Hipparcos Catalogue": 96471,
    "Henry Draper Catalogue": 184860,
    "Gliese Catalog": "Gl 764.1A",
    "Distance in Parsecs": 27.894,
    "mag": 8.38,
    "Absolute Visual Magnitude": 6.152,
    "Spectral Type": "K2V",
    "Color Index": 1.011,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.240924,
    "y": -25.02368,
    "z": -5.056307,
    "vx": -0.00001097,
    "vy": -0.00007256,
    "vz": -0.00004896,
    "rarad": 5.13458717756176,
    "decrad": -0.182272280531058,
    "pmrarad": -0.00000142496436988888,
    "pmdecrad": -0.000001325432121,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 96168,
    "base": "Gl 764.1",
    "lum": 0.301439388427946,
    "ra": 19.612678,
    "dec": -10.443432,
    "pmra": -293.92,
    "pmdec": -273.39,
    "rv": 68,
    "Asset Name": "ASTRID 003607",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Lovestruck",
    "Hair": "Halo Boy",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001097,
    "y_1": -0.00007256,
    "z_1": -0.00004896,
    "Distance in Parsecs_1": 27.894,
    "HYG Star ID_1": 96168,
    "Hipparcos Catalogue_1": 96471,
    "Henry Draper Catalogue_1": 184860,
    "Gliese Catalog_1": "Gl 764.1A"
  },
  {
    "Astrid #": "ASTRID 003608",
    "HYG Star ID": 5249,
    "Hipparcos Catalogue": 5260,
    "Gliese Catalog": "NN 3074",
    "Distance in Parsecs": 27.9096,
    "mag": 10.66,
    "Absolute Visual Magnitude": 8.431,
    "Color Index": 1.396,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 22.550884,
    "y": 6.811355,
    "z": -14.966924,
    "vx": -0.00001094,
    "vy": -0.0000053,
    "vz": -0.00001889,
    "rarad": 0.293330897285333,
    "decrad": -0.566005029738621,
    "pmrarad": -6.83102475902777e-8,
    "pmdecrad": -8.02027271e-7,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 5249,
    "lum": 0.0369487712253932,
    "ra": 1.120441,
    "dec": -32.429699,
    "pmra": -14.09,
    "pmdec": -165.43,
    "rv": 0,
    "Asset Name": "ASTRID 003608",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Big Laugh",
    "Hair": "Braided Ponytail",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00001094,
    "y_1": -0.0000053,
    "z_1": -0.00001889,
    "Distance in Parsecs_1": 27.9096,
    "HYG Star ID_1": 5249,
    "Hipparcos Catalogue_1": 5260,
    "Gliese Catalog_1": "NN 3074"
  },
  {
    "Astrid #": "ASTRID 003609",
    "HYG Star ID": 36536,
    "Hipparcos Catalogue": 36640,
    "Henry Draper Catalogue": 59984,
    "Harvard Revised Catalogue": 2883,
    "Gliese Catalog": "NN 3449A",
    "Distance in Parsecs": 27.9174,
    "mag": 5.9,
    "Absolute Visual Magnitude": 3.671,
    "Spectral Type": "F5V",
    "Color Index": 0.54,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.78824,
    "y": 25.385917,
    "z": -4.310198,
    "vx": -0.00000888,
    "vy": 0.00005281,
    "vz": -0.00003108,
    "rarad": 1.97264117676563,
    "decrad": -0.155008374398923,
    "pmrarad": -4.46998213472222e-7,
    "pmdecrad": -8.12159877e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 36536,
    "base": "NN 3449",
    "lum": 2.96210193605276,
    "ra": 7.534934,
    "dec": -8.881326,
    "pmra": -92.2,
    "pmdec": -167.52,
    "rv": 55,
    "Asset Name": "ASTRID 003609",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Shinobi",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": -0.00000888,
    "y_1": 0.00005281,
    "z_1": -0.00003108,
    "Distance in Parsecs_1": 27.9174,
    "HYG Star ID_1": 36536,
    "Hipparcos Catalogue_1": 36640,
    "Henry Draper Catalogue_1": 59984,
    "Harvard Revised Catalogue_1": 2883,
    "Gliese Catalog_1": "NN 3449A"
  },
  {
    "Astrid #": "ASTRID 003610",
    "HYG Star ID": 30403,
    "Hipparcos Catalogue": 30476,
    "Henry Draper Catalogue": 45289,
    "Distance in Parsecs": 27.9252,
    "mag": 6.67,
    "Absolute Visual Magnitude": 4.44,
    "Spectral Type": "G5V",
    "Color Index": 0.673,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.176185,
    "y": 20.358171,
    "z": -18.990838,
    "vx": -0.00000135,
    "vy": 0.00011114,
    "vz": 0.00004094,
    "rarad": 1.67728805044777,
    "decrad": -0.747830258580331,
    "pmrarad": -3.75148826013888e-7,
    "pmdecrad": 0.000003770105105,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 30403,
    "lum": 1.45881426027534,
    "ra": 6.406768,
    "dec": -42.847518,
    "pmra": -77.38,
    "pmdec": 777.64,
    "rv": 52.1,
    "Asset Name": "ASTRID 003610",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Chuckle",
    "Hair": "Goddess",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": -0.00000135,
    "y_1": 0.00011114,
    "z_1": 0.00004094,
    "Distance in Parsecs_1": 27.9252,
    "HYG Star ID_1": 30403,
    "Hipparcos Catalogue_1": 30476,
    "Henry Draper Catalogue_1": 45289
  },
  {
    "Astrid #": "ASTRID 003611",
    "HYG Star ID": 1004,
    "Hipparcos Catalogue": 1006,
    "Distance in Parsecs": 27.9408,
    "mag": 11.67,
    "Absolute Visual Magnitude": 9.439,
    "Color Index": 1.48,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 25.919321,
    "y": 1.421723,
    "z": 10.337108,
    "vx": 0.00001309,
    "vy": 0.00002637,
    "vz": -0.00003646,
    "rarad": 0.0547963086967858,
    "decrad": 0.378970974306585,
    "pmrarad": 9.16879632666666e-7,
    "pmdecrad": -0.000001404650676,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 1004,
    "lum": 0.0146015849681178,
    "var_min": 11.766,
    "var_max": 11.566,
    "ra": 0.209306,
    "dec": 21.713437,
    "pmra": 189.12,
    "pmdec": -289.73,
    "rv": 0,
    "Asset Name": "ASTRID 003611",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Braid",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001309,
    "y_1": 0.00002637,
    "z_1": -0.00003646,
    "Distance in Parsecs_1": 27.9408,
    "HYG Star ID_1": 1004,
    "Hipparcos Catalogue_1": 1006
  },
  {
    "Astrid #": "ASTRID 003612",
    "HYG Star ID": 26588,
    "Hipparcos Catalogue": 26653,
    "Henry Draper Catalogue": 37216,
    "Distance in Parsecs": 27.9486,
    "mag": 7.85,
    "Absolute Visual Magnitude": 5.618,
    "Spectral Type": "G5",
    "Color Index": 0.764,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.478776,
    "y": 16.794819,
    "z": 22.290615,
    "vx": 0.00000306,
    "vy": 0.0000151,
    "vz": -0.00001158,
    "rarad": 1.48297362825079,
    "decrad": 0.923235336613233,
    "pmrarad": -6.15713374305555e-8,
    "pmdecrad": -6.86787059e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 26588,
    "lum": 0.492946741312205,
    "ra": 5.664542,
    "dec": 52.897488,
    "pmra": -12.7,
    "pmdec": -141.66,
    "rv": 0,
    "Asset Name": "ASTRID 003612",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Honest Lad",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000306,
    "y_1": 0.0000151,
    "z_1": -0.00001158,
    "Distance in Parsecs_1": 27.9486,
    "HYG Star ID_1": 26588,
    "Hipparcos Catalogue_1": 26653,
    "Henry Draper Catalogue_1": 37216
  },
  {
    "Astrid #": "ASTRID 003613",
    "HYG Star ID": 113735,
    "Hipparcos Catalogue": 114096,
    "Henry Draper Catalogue": 218261,
    "Harvard Revised Catalogue": 8792,
    "Distance in Parsecs": 27.9486,
    "mag": 6.44,
    "Absolute Visual Magnitude": 4.208,
    "Spectral Type": "F7V",
    "Color Index": 0.544,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.565988,
    "y": -6.075187,
    "z": 9.518094,
    "vx": 0.00000411,
    "vy": 0.00003902,
    "vz": -0.00000114,
    "rarad": 6.049883848792,
    "decrad": 0.347509938500678,
    "pmrarad": 0.00000139243337192361,
    "pmdecrad": 2.3028649e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 113735,
    "lum": 1.80634208316997,
    "ra": 23.108854,
    "dec": 19.910853,
    "pmra": 287.21,
    "pmdec": 4.75,
    "rv": -5,
    "Asset Name": "ASTRID 003613",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "Bob",
    "Outfit": "Samurai",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000411,
    "y_1": 0.00003902,
    "z_1": -0.00000114,
    "Distance in Parsecs_1": 27.9486,
    "HYG Star ID_1": 113735,
    "Hipparcos Catalogue_1": 114096,
    "Henry Draper Catalogue_1": 218261,
    "Harvard Revised Catalogue_1": 8792
  },
  {
    "Astrid #": "ASTRID 003614",
    "HYG Star ID": 49748,
    "Hipparcos Catalogue": 49889,
    "Gliese Catalog": "NN 3591",
    "Distance in Parsecs": 27.9564,
    "mag": 11.07,
    "Absolute Visual Magnitude": 8.838,
    "Spectral Type": "K:",
    "Color Index": 1.42,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.087645,
    "y": 1.587627,
    "z": -27.739975,
    "vx": -0.00001147,
    "vy": 0.00007455,
    "vz": 0.00000554,
    "rarad": 2.6667948664023,
    "decrad": -1.44628491654823,
    "pmrarad": -0.00000218418259363888,
    "pmdecrad": 0.000001596394487,
    "con": "Cha",
    "Full Constellation Name Formula 1": "Chamaeleon",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Chamaeleon",
    "comp": 1,
    "comp_primary": 49748,
    "lum": 0.0253980281377281,
    "var_min": 11.144,
    "var_max": 10.974,
    "ra": 10.186406,
    "dec": -82.866022,
    "pmra": -450.52,
    "pmdec": 329.28,
    "rv": 0,
    "Asset Name": "ASTRID 003614",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Scared",
    "Hair": "Angelic",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Chamaeleon",
    "x_1": -0.00001147,
    "y_1": 0.00007455,
    "z_1": 0.00000554,
    "Distance in Parsecs_1": 27.9564,
    "HYG Star ID_1": 49748,
    "Hipparcos Catalogue_1": 49889,
    "Gliese Catalog_1": "NN 3591"
  },
  {
    "Astrid #": "ASTRID 003615",
    "HYG Star ID": 69947,
    "Hipparcos Catalogue": 70170,
    "Henry Draper Catalogue": 125595,
    "Gliese Catalog": "Gl 545.1",
    "Distance in Parsecs": 27.9564,
    "mag": 9.03,
    "Absolute Visual Magnitude": 6.798,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.107,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.367028,
    "y": -12.317763,
    "z": -18.116868,
    "vx": -0.00004118,
    "vy": 0.00006411,
    "vz": -0.00000901,
    "rarad": 3.75851479228625,
    "decrad": -0.705007378491831,
    "pmrarad": -0.00000272305299957638,
    "pmdecrad": -3.26570495e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 69947,
    "lum": 0.166264679689353,
    "ra": 14.356469,
    "dec": -40.393947,
    "pmra": -561.67,
    "pmdec": -67.36,
    "rv": 3.1,
    "Asset Name": "ASTRID 003615",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Determined Shinobi",
    "Hair": "Audiophile",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00004118,
    "y_1": 0.00006411,
    "z_1": -0.00000901,
    "Distance in Parsecs_1": 27.9564,
    "HYG Star ID_1": 69947,
    "Hipparcos Catalogue_1": 70170,
    "Henry Draper Catalogue_1": 125595,
    "Gliese Catalog_1": "Gl 545.1"
  },
  {
    "Astrid #": "ASTRID 003616",
    "HYG Star ID": 59937,
    "Hipparcos Catalogue": 60121,
    "Distance in Parsecs": 27.9642,
    "mag": 11.12,
    "Absolute Visual Magnitude": 8.887,
    "Spectral Type": "M0",
    "Color Index": 1.562,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -16.852135,
    "y": -1.459632,
    "z": 22.268172,
    "vx": -0.0000152,
    "vy": 0.00002175,
    "vz": -0.00001008,
    "rarad": 3.22799577148604,
    "decrad": 0.921170191162251,
    "pmrarad": -8.22001595381944e-7,
    "pmdecrad": -5.95981457e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 59937,
    "lum": 0.0242772784768099,
    "var_min": 11.21,
    "var_max": 11.05,
    "ra": 12.330036,
    "dec": 52.779164,
    "pmra": -169.55,
    "pmdec": -122.93,
    "rv": 0,
    "Asset Name": "ASTRID 003616",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Elegant",
    "Outfit": "Samurai",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.0000152,
    "y_1": 0.00002175,
    "z_1": -0.00001008,
    "Distance in Parsecs_1": 27.9642,
    "HYG Star ID_1": 59937,
    "Hipparcos Catalogue_1": 60121
  },
  {
    "Astrid #": "ASTRID 003617",
    "HYG Star ID": 93631,
    "Hipparcos Catalogue": 93926,
    "Henry Draper Catalogue": 178450,
    "Distance in Parsecs": 27.9642,
    "mag": 7.78,
    "Absolute Visual Magnitude": 5.547,
    "Spectral Type": "G8V SB",
    "Color Index": 0.763,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.015901,
    "y": -23.113875,
    "z": 14.08944,
    "vx": 0.0000073,
    "vy": 0.00002822,
    "vz": 0.00000166,
    "rarad": 5.00708618347722,
    "decrad": 0.528040501655931,
    "pmrarad": 5.42797396749999e-7,
    "pmdecrad": 4.99503535e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 93631,
    "lum": 0.526259562080318,
    "var": "V478",
    "var_min": 7.84,
    "var_max": 7.73,
    "ra": 19.12566,
    "dec": 30.254492,
    "pmra": 111.96,
    "pmdec": 103.03,
    "rv": -20.2,
    "Asset Name": "ASTRID 003617",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Slick",
    "Outfit": "Suit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.0000073,
    "y_1": 0.00002822,
    "z_1": 0.00000166,
    "Distance in Parsecs_1": 27.9642,
    "HYG Star ID_1": 93631,
    "Hipparcos Catalogue_1": 93926,
    "Henry Draper Catalogue_1": 178450
  },
  {
    "Astrid #": "ASTRID 003618",
    "HYG Star ID": 114354,
    "Hipparcos Catalogue": 114716,
    "Distance in Parsecs": 27.972,
    "mag": 11.99,
    "Absolute Visual Magnitude": 9.756,
    "Spectral Type": "M2",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.99321,
    "y": -3.032943,
    "z": -23.418747,
    "vx": -0.00003967,
    "vy": -0.00004929,
    "vz": -0.00001902,
    "rarad": 6.08360548474648,
    "decrad": -0.992181652643341,
    "pmrarad": -0.00000200848611580555,
    "pmdecrad": -0.000001243159239,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 114354,
    "lum": 0.0109043554554365,
    "var": "DQ",
    "var_min": 12.116,
    "var_max": 11.796,
    "ra": 23.237661,
    "dec": -56.847821,
    "pmra": -414.28,
    "pmdec": -256.42,
    "rv": 0,
    "Asset Name": "ASTRID 003618",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Medium Bob",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00003967,
    "y_1": -0.00004929,
    "z_1": -0.00001902,
    "Distance in Parsecs_1": 27.972,
    "HYG Star ID_1": 114354,
    "Hipparcos Catalogue_1": 114716
  },
  {
    "Astrid #": "ASTRID 003619",
    "HYG Star ID": 56121,
    "Hipparcos Catalogue": 56290,
    "Henry Draper Catalogue": 100203,
    "Harvard Revised Catalogue": 4439,
    "Gliese Catalog": "Gl 431.1A",
    "Distance in Parsecs": 27.9877,
    "mag": 5.46,
    "Absolute Visual Magnitude": 3.225,
    "Spectral Type": "F6V",
    "Color Index": 0.515,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.434827,
    "y": 1.629025,
    "z": 24.497736,
    "vx": 0.00001253,
    "vy": 4.2e-7,
    "vz": -0.00004912,
    "rarad": 3.02092848919478,
    "decrad": 1.06609109547152,
    "pmrarad": -6.88920240069444e-8,
    "pmdecrad": -4.61203254e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 56121,
    "base": "Gl 431.1",
    "lum": 4.46683592150963,
    "ra": 11.539097,
    "dec": 61.08252,
    "pmra": -14.21,
    "pmdec": -95.13,
    "rv": -47.9,
    "Asset Name": "ASTRID 003619",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Slick",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001253,
    "y_1": 4.2e-7,
    "z_1": -0.00004912,
    "Distance in Parsecs_1": 27.9877,
    "HYG Star ID_1": 56121,
    "Hipparcos Catalogue_1": 56290,
    "Henry Draper Catalogue_1": 100203,
    "Harvard Revised Catalogue_1": 4439,
    "Gliese Catalog_1": "Gl 431.1A"
  },
  {
    "Astrid #": "ASTRID 003620",
    "HYG Star ID": 59314,
    "Hipparcos Catalogue": 59496,
    "Henry Draper Catalogue": 238087,
    "Gliese Catalog": "Gl 457",
    "Distance in Parsecs": 28.0112,
    "mag": 10.05,
    "Absolute Visual Magnitude": 7.813,
    "Spectral Type": "K5",
    "Color Index": 1.277,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.437474,
    "y": -0.762131,
    "z": 23.991629,
    "vx": 0.00001099,
    "vy": -0.00001328,
    "vz": -0.00001172,
    "rarad": 3.19432802626525,
    "decrad": 1.02846053145344,
    "pmrarad": 4.94316028694444e-7,
    "pmdecrad": 9.8223251e-8,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 59314,
    "lum": 0.0652829844158614,
    "var_min": 10.109,
    "var_max": 9.999,
    "ra": 12.201434,
    "dec": 58.926448,
    "pmra": 101.96,
    "pmdec": 20.26,
    "rv": -15,
    "Asset Name": "ASTRID 003620",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Fired Up",
    "Hair": "Elegant",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001099,
    "y_1": -0.00001328,
    "z_1": -0.00001172,
    "Distance in Parsecs_1": 28.0112,
    "HYG Star ID_1": 59314,
    "Hipparcos Catalogue_1": 59496,
    "Henry Draper Catalogue_1": 238087,
    "Gliese Catalog_1": "Gl 457"
  },
  {
    "Astrid #": "ASTRID 003621",
    "HYG Star ID": 103341,
    "Hipparcos Catalogue": 103673,
    "Henry Draper Catalogue": 199623,
    "Harvard Revised Catalogue": 8027,
    "Distance in Parsecs": 28.0112,
    "mag": 5.76,
    "Absolute Visual Magnitude": 3.523,
    "Spectral Type": "F5IV-V",
    "Color Index": 0.48,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.412736,
    "y": -12.374014,
    "z": -21.850025,
    "vx": -0.00000896,
    "vy": -0.00000803,
    "vz": 0.00002962,
    "rarad": 5.49935157299669,
    "decrad": -0.894748483525066,
    "pmrarad": -4.289146631875e-7,
    "pmdecrad": 6.42911421e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 103341,
    "lum": 3.39468905380078,
    "ra": 21.005976,
    "dec": -51.265312,
    "pmra": -88.47,
    "pmdec": 132.61,
    "rv": -23,
    "Asset Name": "ASTRID 003621",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Sad",
    "Hair": "Middle Part",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00000896,
    "y_1": -0.00000803,
    "z_1": 0.00002962,
    "Distance in Parsecs_1": 28.0112,
    "HYG Star ID_1": 103341,
    "Hipparcos Catalogue_1": 103673,
    "Henry Draper Catalogue_1": 199623,
    "Harvard Revised Catalogue_1": 8027
  },
  {
    "Astrid #": "ASTRID 003622",
    "HYG Star ID": 40609,
    "Hipparcos Catalogue": 40724,
    "Gliese Catalog": "NN 3493",
    "Distance in Parsecs": 28.0191,
    "mag": 9.82,
    "Absolute Visual Magnitude": 7.583,
    "Spectral Type": "K3V",
    "Color Index": 1.132,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.386987,
    "y": 22.234017,
    "z": -7.347562,
    "vx": -0.00003484,
    "vy": 0.00003944,
    "vz": -0.00002222,
    "rarad": 2.17616475080028,
    "decrad": -0.265331769584015,
    "pmrarad": 2.21462889277777e-7,
    "pmdecrad": -2.76295316e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 40609,
    "lum": 0.0806863370363818,
    "ra": 8.312337,
    "dec": -15.202391,
    "pmra": 45.68,
    "pmdec": -56.99,
    "rv": 55,
    "Asset Name": "ASTRID 003622",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Star of Culture",
    "Hair": "High Fade",
    "Outfit": "Warlock",
    "Astral Crystal": "Icicle",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003484,
    "y_1": 0.00003944,
    "z_1": -0.00002222,
    "Distance in Parsecs_1": 28.0191,
    "HYG Star ID_1": 40609,
    "Hipparcos Catalogue_1": 40724,
    "Gliese Catalog_1": "NN 3493"
  },
  {
    "Astrid #": "ASTRID 003623",
    "HYG Star ID": 95432,
    "Hipparcos Catalogue": 95730,
    "Henry Draper Catalogue": 231512,
    "Distance in Parsecs": 28.0191,
    "mag": 9.17,
    "Absolute Visual Magnitude": 6.933,
    "Spectral Type": "K2",
    "Color Index": 1.08,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.274245,
    "y": -25.347903,
    "z": 6.081551,
    "vx": -0.00001251,
    "vy": 0.00001176,
    "vz": -0.000009,
    "rarad": 5.09748107303973,
    "decrad": 0.218792791333208,
    "pmrarad": -2.56224030173611e-7,
    "pmdecrad": -1.92761919e-7,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 95432,
    "lum": 0.146824996783198,
    "ra": 19.470943,
    "dec": 12.535904,
    "pmra": -52.85,
    "pmdec": -39.76,
    "rv": -16.8,
    "Asset Name": "ASTRID 003623",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Bob",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001251,
    "y_1": 0.00001176,
    "z_1": -0.000009,
    "Distance in Parsecs_1": 28.0191,
    "HYG Star ID_1": 95432,
    "Hipparcos Catalogue_1": 95730,
    "Henry Draper Catalogue_1": 231512
  },
  {
    "Astrid #": "ASTRID 003624",
    "HYG Star ID": 67096,
    "Hipparcos Catalogue": 67309,
    "Distance in Parsecs": 28.0269,
    "mag": 11.58,
    "Absolute Visual Magnitude": 9.342,
    "Spectral Type": "M0",
    "Color Index": 1.51,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -23.255974,
    "y": -11.812039,
    "z": 10.253903,
    "vx": 9.4e-7,
    "vy": -0.00001101,
    "vz": -0.00001055,
    "rarad": 3.61155133240668,
    "decrad": 0.374555975375318,
    "pmrarad": 3.65646477875e-7,
    "pmdecrad": -4.04528535e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 67096,
    "lum": 0.0159661424599341,
    "var_min": 11.67,
    "var_max": 11.48,
    "ra": 13.79511,
    "dec": 21.460477,
    "pmra": 75.42,
    "pmdec": -83.44,
    "rv": 0,
    "Asset Name": "ASTRID 003624",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Lion Mane",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 9.4e-7,
    "y_1": -0.00001101,
    "z_1": -0.00001055,
    "Distance in Parsecs_1": 28.0269,
    "HYG Star ID_1": 67096,
    "Hipparcos Catalogue_1": 67309
  },
  {
    "Astrid #": "ASTRID 003625",
    "HYG Star ID": 9766,
    "Hipparcos Catalogue": 9788,
    "Distance in Parsecs": 28.0348,
    "mag": 9.83,
    "Absolute Visual Magnitude": 7.592,
    "Spectral Type": "K6",
    "Color Index": 1.17,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.545468,
    "y": 12.568283,
    "z": 14.34824,
    "vx": 0.00001783,
    "vy": -0.00001644,
    "vz": -0.00001113,
    "rarad": 0.549001219553102,
    "decrad": 0.537279902835717,
    "pmrarad": -8.32279645409722e-7,
    "pmdecrad": -4.62075918e-7,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 9766,
    "lum": 0.0800202677119332,
    "ra": 2.09703,
    "dec": 30.783871,
    "pmra": -171.67,
    "pmdec": -95.31,
    "rv": 0,
    "Asset Name": "ASTRID 003625",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Headband",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": 0.00001783,
    "y_1": -0.00001644,
    "z_1": -0.00001113,
    "Distance in Parsecs_1": 28.0348,
    "HYG Star ID_1": 9766,
    "Hipparcos Catalogue_1": 9788
  },
  {
    "Astrid #": "ASTRID 003626",
    "HYG Star ID": 84729,
    "Hipparcos Catalogue": 84988,
    "Henry Draper Catalogue": 155918,
    "Gliese Catalog": "Gl 667.1",
    "Distance in Parsecs": 28.0348,
    "mag": 7,
    "Absolute Visual Magnitude": 4.762,
    "Spectral Type": "G2V",
    "Color Index": 0.607,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.163984,
    "y": -6.995215,
    "z": -27.123637,
    "vx": -0.00012537,
    "vy": 0.00003917,
    "vz": -0.00006699,
    "rarad": 4.54762247273333,
    "decrad": -1.3150732916829,
    "pmrarad": -0.00000464083047575,
    "pmdecrad": -0.000001228420903,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 84729,
    "lum": 1.08442619576707,
    "ra": 17.370638,
    "dec": -75.348149,
    "pmra": -957.24,
    "pmdec": -253.38,
    "rv": 58.9,
    "Asset Name": "ASTRID 003626",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Naughty Dreams",
    "Hair": "Elegant",
    "Outfit": "Skater",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": -0.00012537,
    "y_1": 0.00003917,
    "z_1": -0.00006699,
    "Distance in Parsecs_1": 28.0348,
    "HYG Star ID_1": 84729,
    "Hipparcos Catalogue_1": 84988,
    "Henry Draper Catalogue_1": 155918,
    "Gliese Catalog_1": "Gl 667.1"
  },
  {
    "Astrid #": "ASTRID 003627",
    "HYG Star ID": 59420,
    "Hipparcos Catalogue": 59602,
    "Gliese Catalog": "Wo 9397",
    "Distance in Parsecs": 28.0426,
    "mag": 11.91,
    "Absolute Visual Magnitude": 9.671,
    "Spectral Type": "K:",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -26.815131,
    "y": -1.562705,
    "z": 8.05569,
    "vx": -0.00002191,
    "vy": 0.0000678,
    "vz": -0.00005977,
    "rarad": 3.199804719272,
    "decrad": 0.2913714615495,
    "pmrarad": -0.00000245916891325,
    "pmdecrad": -0.0000022255372,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 59420,
    "lum": 0.01179234020653,
    "var": "IV",
    "var_min": 12.018,
    "var_max": 11.798,
    "ra": 12.222354,
    "dec": 16.694355,
    "pmra": -507.24,
    "pmdec": -459.05,
    "rv": 0,
    "Asset Name": "ASTRID 003627",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Lovestruck",
    "Hair": "Windy",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00002191,
    "y_1": 0.0000678,
    "z_1": -0.00005977,
    "Distance in Parsecs_1": 28.0426,
    "HYG Star ID_1": 59420,
    "Hipparcos Catalogue_1": 59602,
    "Gliese Catalog_1": "Wo 9397"
  },
  {
    "Astrid #": "ASTRID 003628",
    "HYG Star ID": 82745,
    "Hipparcos Catalogue": 83000,
    "Henry Draper Catalogue": 153210,
    "Harvard Revised Catalogue": 6299,
    "Distance in Parsecs": 28.0426,
    "mag": 3.19,
    "Absolute Visual Magnitude": 0.951,
    "Spectral Type": "K2IIIvar",
    "Color Index": 1.16,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.432411,
    "y": -26.650562,
    "z": 4.567947,
    "vx": -0.00002325,
    "vy": 0.00006492,
    "vz": -0.00001063,
    "rarad": 4.44041574531662,
    "decrad": 0.163625187514388,
    "pmrarad": -0.00000142064952813194,
    "pmdecrad": -4.7026927e-8,
    "bayer": "Kap",
    "flam": 27,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 82745,
    "lum": 36.2743801476457,
    "var": "Kap",
    "var_min": 3.199,
    "var_max": 3.189,
    "bf": "27Kap Oph",
    "ra": 16.961139,
    "dec": 9.375033,
    "pmra": -293.03,
    "pmdec": -9.7,
    "rv": -56,
    "Asset Name": "ASTRID 003628",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Nervous",
    "Hair": "High Bun",
    "Outfit": "Football",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002325,
    "y_1": 0.00006492,
    "z_1": -0.00001063,
    "Distance in Parsecs_1": 28.0426,
    "HYG Star ID_1": 82745,
    "Hipparcos Catalogue_1": 83000,
    "Henry Draper Catalogue_1": 153210,
    "Harvard Revised Catalogue_1": 6299
  },
  {
    "Astrid #": "ASTRID 003629",
    "HYG Star ID": 99038,
    "Hipparcos Catalogue": 99355,
    "Henry Draper Catalogue": 192139,
    "Distance in Parsecs": 28.0426,
    "mag": 8.73,
    "Absolute Visual Magnitude": 6.491,
    "Spectral Type": "G5",
    "Color Index": 0.906,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.19509,
    "y": -11.294947,
    "z": 24.638479,
    "vx": -0.00002184,
    "vy": -9e-7,
    "vz": 0.00003472,
    "rarad": 5.27959062290833,
    "decrad": 1.07292690135206,
    "pmrarad": -6.74181904180555e-7,
    "pmdecrad": 9.35011664e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 99038,
    "lum": 0.220597202174732,
    "ra": 20.166551,
    "dec": 61.474183,
    "pmra": -139.06,
    "pmdec": 192.86,
    "rv": 24.7,
    "Asset Name": "ASTRID 003629",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "High Bun",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00002184,
    "y_1": -9e-7,
    "z_1": 0.00003472,
    "Distance in Parsecs_1": 28.0426,
    "HYG Star ID_1": 99038,
    "Hipparcos Catalogue_1": 99355,
    "Henry Draper Catalogue_1": 192139
  },
  {
    "Astrid #": "ASTRID 003630",
    "HYG Star ID": 42372,
    "Hipparcos Catalogue": 42491,
    "Distance in Parsecs": 28.0505,
    "mag": 8.41,
    "Absolute Visual Magnitude": 6.17,
    "Spectral Type": "G5",
    "Color Index": 0.81,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.915315,
    "y": 21.399014,
    "z": 2.820328,
    "vx": -0.00002012,
    "vy": -0.00001149,
    "vz": -0.00004067,
    "rarad": 2.26781385003474,
    "decrad": 0.100714853036591,
    "pmrarad": 8.13032542291666e-7,
    "pmdecrad": -0.000001457252961,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 42372,
    "lum": 0.296483138952434,
    "ra": 8.662411,
    "dec": 5.770536,
    "pmra": 167.7,
    "pmdec": -300.58,
    "rv": 0,
    "Asset Name": "ASTRID 003630",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Neko Ninja",
    "Hair": "Lion Mane",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00002012,
    "y_1": -0.00001149,
    "z_1": -0.00004067,
    "Distance in Parsecs_1": 28.0505,
    "HYG Star ID_1": 42372,
    "Hipparcos Catalogue_1": 42491
  },
  {
    "Astrid #": "ASTRID 003631",
    "HYG Star ID": 114938,
    "Hipparcos Catalogue": 115302,
    "Distance in Parsecs": 28.0505,
    "mag": 11.76,
    "Absolute Visual Magnitude": 9.52,
    "Color Index": 1.429,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 27.63849,
    "y": -4.725909,
    "z": -0.781177,
    "vx": -0.00000447,
    "vy": -0.00001836,
    "vz": -0.0000472,
    "rarad": 6.11383301699787,
    "decrad": -0.027852567063825,
    "pmrarad": -6.72000242618055e-7,
    "pmdecrad": -0.00000168332158,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 114938,
    "lum": 0.0135518941235103,
    "var_min": 11.854,
    "var_max": 11.664,
    "ra": 23.353122,
    "dec": -1.595835,
    "pmra": -138.61,
    "pmdec": -347.21,
    "rv": 0,
    "Asset Name": "ASTRID 003631",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Sweet Nap",
    "Hair": "Heartbreaker",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000447,
    "y_1": -0.00001836,
    "z_1": -0.0000472,
    "Distance in Parsecs_1": 28.0505,
    "HYG Star ID_1": 114938,
    "Hipparcos Catalogue_1": 115302
  },
  {
    "Astrid #": "ASTRID 003632",
    "HYG Star ID": 5358,
    "Hipparcos Catalogue": 5369,
    "Gliese Catalog": "Gl 53.2",
    "Distance in Parsecs": 28.0584,
    "mag": 10.56,
    "Absolute Visual Magnitude": 8.32,
    "Spectral Type": "K6",
    "Color Index": 1.256,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 25.603088,
    "y": 7.909889,
    "z": 8.316926,
    "vx": -0.00000863,
    "vy": -0.0000114,
    "vz": -0.00008679,
    "rarad": 0.299640979186925,
    "decrad": 0.300940153475657,
    "pmrarad": -2.97578637125e-7,
    "pmdecrad": -0.000002831602782,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 5358,
    "lum": 0.040926065973001,
    "ra": 1.144544,
    "dec": 17.242601,
    "pmra": -61.38,
    "pmdec": -584.06,
    "rv": -36,
    "Asset Name": "ASTRID 003632",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Ninja",
    "Outfit": "Painter",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00000863,
    "y_1": -0.0000114,
    "z_1": -0.00008679,
    "Distance in Parsecs_1": 28.0584,
    "HYG Star ID_1": 5358,
    "Hipparcos Catalogue_1": 5369,
    "Gliese Catalog_1": "Gl 53.2"
  },
  {
    "Astrid #": "ASTRID 003633",
    "HYG Star ID": 30152,
    "Hipparcos Catalogue": 30225,
    "Henry Draper Catalogue": 44573,
    "Distance in Parsecs": 28.0662,
    "mag": 8.48,
    "Absolute Visual Magnitude": 6.239,
    "Spectral Type": "K2V",
    "Color Index": 0.915,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.439804,
    "y": 25.868135,
    "z": -10.611247,
    "vx": -0.00000226,
    "vy": -0.00001262,
    "vz": -0.00003026,
    "rarad": 1.6648350092868,
    "decrad": -0.387720629419951,
    "pmrarad": 1.22318491604166e-7,
    "pmdecrad": -0.000001164619423,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 30152,
    "lum": 0.278227465767512,
    "var": "V353",
    "var_min": 8.515,
    "var_max": 8.445,
    "ra": 6.359201,
    "dec": -22.214756,
    "pmra": 25.23,
    "pmdec": -240.22,
    "rv": 0,
    "Asset Name": "ASTRID 003633",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Fired Up",
    "Hair": "Spiky",
    "Outfit": "Tats",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00000226,
    "y_1": -0.00001262,
    "z_1": -0.00003026,
    "Distance in Parsecs_1": 28.0662,
    "HYG Star ID_1": 30152,
    "Hipparcos Catalogue_1": 30225,
    "Henry Draper Catalogue_1": 44573
  },
  {
    "Astrid #": "ASTRID 003634",
    "HYG Star ID": 36955,
    "Hipparcos Catalogue": 37061,
    "Henry Draper Catalogue": 60272,
    "Distance in Parsecs": 28.0662,
    "mag": 7.97,
    "Absolute Visual Magnitude": 5.729,
    "Spectral Type": "G9V",
    "Color Index": 0.797,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.183393,
    "y": 13.73144,
    "z": 23.683851,
    "vx": -0.00001783,
    "vy": 0.00001554,
    "vz": -0.00001367,
    "rarad": 1.99390462820311,
    "decrad": 1.0044308273277,
    "pmrarad": 3.52023213451388e-7,
    "pmdecrad": -9.07619691e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 36955,
    "lum": 0.445040976649536,
    "ra": 7.616155,
    "dec": 57.549647,
    "pmra": 72.61,
    "pmdec": -187.21,
    "rv": 0,
    "Asset Name": "ASTRID 003634",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Arrogant",
    "Hair": "Greasy",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00001783,
    "y_1": 0.00001554,
    "z_1": -0.00001367,
    "Distance in Parsecs_1": 28.0662,
    "HYG Star ID_1": 36955,
    "Hipparcos Catalogue_1": 37061,
    "Henry Draper Catalogue_1": 60272
  },
  {
    "Astrid #": "ASTRID 003635",
    "HYG Star ID": 40837,
    "Hipparcos Catalogue": 40952,
    "Henry Draper Catalogue": 70642,
    "Gliese Catalog": "Gl 304",
    "Distance in Parsecs": 28.0662,
    "mag": 7.17,
    "Absolute Visual Magnitude": 4.929,
    "Spectral Type": "G8:III...",
    "Color Index": 0.692,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.498212,
    "y": 17.608009,
    "z": -17.930099,
    "vx": -0.00001084,
    "vy": 0.00006277,
    "vz": -0.00000781,
    "rarad": 2.1880735685245,
    "decrad": -0.692990123777948,
    "pmrarad": -9.79711485666666e-7,
    "pmdecrad": 0.000001093739663,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 40837,
    "lum": 0.92982239058538,
    "ra": 8.357825,
    "dec": -39.705409,
    "pmra": -202.08,
    "pmdec": 225.6,
    "rv": 48.1,
    "Asset Name": "ASTRID 003635",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Determined",
    "Hair": "Fire",
    "Outfit": "Turtle School",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00001084,
    "y_1": 0.00006277,
    "z_1": -0.00000781,
    "Distance in Parsecs_1": 28.0662,
    "HYG Star ID_1": 40837,
    "Hipparcos Catalogue_1": 40952,
    "Henry Draper Catalogue_1": 70642,
    "Gliese Catalog_1": "Gl 304"
  },
  {
    "Astrid #": "ASTRID 003636",
    "HYG Star ID": 112133,
    "Hipparcos Catalogue": 112491,
    "Henry Draper Catalogue": 215641,
    "Distance in Parsecs": 28.0741,
    "mag": 7.58,
    "Absolute Visual Magnitude": 5.338,
    "Spectral Type": "G6V",
    "Color Index": 0.744,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.447503,
    "y": -7.385186,
    "z": -15.156639,
    "vx": 0.00000412,
    "vy": 0.00003412,
    "vz": -0.00001052,
    "rarad": 5.96533954560944,
    "decrad": -0.570294298386943,
    "pmrarad": 0.00000120064107989583,
    "pmdecrad": -4.45301365e-7,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 112133,
    "lum": 0.637969622662578,
    "ra": 22.785919,
    "dec": -32.675456,
    "pmra": 247.65,
    "pmdec": -91.85,
    "rv": 0,
    "Asset Name": "ASTRID 003636",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Honest Lad",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000412,
    "y_1": 0.00003412,
    "z_1": -0.00001052,
    "Distance in Parsecs_1": 28.0741,
    "HYG Star ID_1": 112133,
    "Hipparcos Catalogue_1": 112491,
    "Henry Draper Catalogue_1": 215641
  },
  {
    "Astrid #": "ASTRID 003637",
    "HYG Star ID": 112403,
    "Hipparcos Catalogue": 112763,
    "Henry Draper Catalogue": 216054,
    "Gliese Catalog": "Wo 9797",
    "Distance in Parsecs": 28.0741,
    "mag": 7.77,
    "Absolute Visual Magnitude": 5.528,
    "Spectral Type": "G5V",
    "Color Index": 0.741,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 20.061135,
    "y": -6.30837,
    "z": -18.598959,
    "vx": -0.00001711,
    "vy": -0.00004674,
    "vz": -0.00003595,
    "rarad": 5.97852347399144,
    "decrad": -0.724138966151241,
    "pmrarad": -0.00000177131526327777,
    "pmdecrad": -0.000001013502999,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 112403,
    "lum": 0.535549970840113,
    "ra": 22.836278,
    "dec": -41.490107,
    "pmra": -365.36,
    "pmdec": -209.05,
    "rv": 21.6,
    "Asset Name": "ASTRID 003637",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Bad Dreams",
    "Hair": "Frizzy",
    "Outfit": "Spartan",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00001711,
    "y_1": -0.00004674,
    "z_1": -0.00003595,
    "Distance in Parsecs_1": 28.0741,
    "HYG Star ID_1": 112403,
    "Hipparcos Catalogue_1": 112763,
    "Henry Draper Catalogue_1": 216054,
    "Gliese Catalog_1": "Wo 9797"
  },
  {
    "Astrid #": "ASTRID 003638",
    "HYG Star ID": 914,
    "Hipparcos Catalogue": 916,
    "Gliese Catalog": "NN 3012",
    "Distance in Parsecs": 28.082,
    "mag": 9.5,
    "Absolute Visual Magnitude": 7.258,
    "Spectral Type": "K7",
    "Color Index": 1.254,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.7174,
    "y": 0.729614,
    "z": 23.905324,
    "vx": -0.00000411,
    "vy": 0.00003162,
    "vz": 0.00000157,
    "rarad": 0.0495253735010869,
    "decrad": 1.01839815087263,
    "pmrarad": 0.00000113194298136111,
    "pmdecrad": 1.06271158e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 914,
    "lum": 0.108842873794359,
    "ra": 0.189173,
    "dec": 58.349916,
    "pmra": 233.48,
    "pmdec": 21.92,
    "rv": 0,
    "Asset Name": "ASTRID 003638",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Neko Ninja",
    "Hair": "Slick",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000411,
    "y_1": 0.00003162,
    "z_1": 0.00000157,
    "Distance in Parsecs_1": 28.082,
    "HYG Star ID_1": 914,
    "Hipparcos Catalogue_1": 916,
    "Gliese Catalog_1": "NN 3012"
  },
  {
    "Astrid #": "ASTRID 003639",
    "HYG Star ID": 91081,
    "Hipparcos Catalogue": 91364,
    "Henry Draper Catalogue": 172393,
    "Gliese Catalog": "Gl 722.1",
    "Distance in Parsecs": 28.082,
    "mag": 8.32,
    "Absolute Visual Magnitude": 6.078,
    "Spectral Type": "G5",
    "Color Index": 0.822,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.411097,
    "y": -20.365963,
    "z": 19.031746,
    "vx": 0.00004267,
    "vy": -0.00001219,
    "vz": 0.00002926,
    "rarad": 4.87833424152777,
    "decrad": 0.744648408201738,
    "pmrarad": 0.00000142714603145138,
    "pmdecrad": 3.05965913e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 91081,
    "lum": 0.322700768751553,
    "ra": 18.633864,
    "dec": 42.665211,
    "pmra": 294.37,
    "pmdec": 63.11,
    "rv": 33.1,
    "Asset Name": "ASTRID 003639",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Scared",
    "Hair": "Audiohead",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": 0.00004267,
    "y_1": -0.00001219,
    "z_1": 0.00002926,
    "Distance in Parsecs_1": 28.082,
    "HYG Star ID_1": 91081,
    "Hipparcos Catalogue_1": 91364,
    "Henry Draper Catalogue_1": 172393,
    "Gliese Catalog_1": "Gl 722.1"
  },
  {
    "Astrid #": "ASTRID 003640",
    "HYG Star ID": 21274,
    "Hipparcos Catalogue": 21327,
    "Henry Draper Catalogue": 29231,
    "Gliese Catalog": "NN 3300",
    "Distance in Parsecs": 28.0978,
    "mag": 7.61,
    "Absolute Visual Magnitude": 5.367,
    "Spectral Type": "G8V",
    "Color Index": 0.776,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.307634,
    "y": 21.26455,
    "z": -16.379515,
    "vx": 0.00000611,
    "vy": -0.00002161,
    "vz": -0.00002496,
    "rarad": 1.19835112966668,
    "decrad": -0.622350442404146,
    "pmrarad": -4.82438093520833e-7,
    "pmdecrad": -0.000001093303331,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 21274,
    "lum": 0.621155020832441,
    "ra": 4.577364,
    "dec": -35.658054,
    "pmra": -99.51,
    "pmdec": -225.51,
    "rv": 0,
    "Asset Name": "ASTRID 003640",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined Neko",
    "Hair": "Curly",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000611,
    "y_1": -0.00002161,
    "z_1": -0.00002496,
    "Distance in Parsecs_1": 28.0978,
    "HYG Star ID_1": 21274,
    "Hipparcos Catalogue_1": 21327,
    "Henry Draper Catalogue_1": 29231,
    "Gliese Catalog_1": "NN 3300"
  },
  {
    "Astrid #": "ASTRID 003641",
    "HYG Star ID": 63977,
    "Hipparcos Catalogue": 64180,
    "Distance in Parsecs": 28.0978,
    "mag": 10.57,
    "Absolute Visual Magnitude": 8.327,
    "Spectral Type": "M0",
    "Color Index": 0.879,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -10.008837,
    "y": -3.118817,
    "z": -26.068804,
    "vx": -0.00000336,
    "vy": -0.00001636,
    "vz": 0.00000325,
    "rarad": 3.44365545276392,
    "decrad": -1.18844003813393,
    "pmrarad": 5.205444488125e-7,
    "pmdecrad": 3.09698979e-7,
    "con": "Mus",
    "Full Constellation Name Formula 1": "Musca",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Musca",
    "comp": 1,
    "comp_primary": 63977,
    "lum": 0.0406630546340428,
    "var_min": 10.647,
    "var_max": 10.507,
    "ra": 13.153795,
    "dec": -68.092598,
    "pmra": 107.37,
    "pmdec": 63.88,
    "rv": 0,
    "Asset Name": "ASTRID 003641",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Upset",
    "Hair": "Heartbreaker",
    "Outfit": "Star Knight",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Musca",
    "x_1": -0.00000336,
    "y_1": -0.00001636,
    "z_1": 0.00000325,
    "Distance in Parsecs_1": 28.0978,
    "HYG Star ID_1": 63977,
    "Hipparcos Catalogue_1": 64180
  },
  {
    "Astrid #": "ASTRID 003642",
    "HYG Star ID": 81917,
    "Hipparcos Catalogue": 82169,
    "Distance in Parsecs": 28.0978,
    "mag": 10.76,
    "Absolute Visual Magnitude": 8.517,
    "Spectral Type": "K6",
    "Color Index": 1.502,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.763226,
    "y": -26.689929,
    "z": -0.582997,
    "vx": -0.00000219,
    "vy": 0.00000144,
    "vz": -0.00003297,
    "rarad": 4.39514403896573,
    "decrad": -0.0207503720202262,
    "pmrarad": -9.01753445833333e-8,
    "pmdecrad": -0.000001173830883,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 81917,
    "lum": 0.0341350072949311,
    "var_min": 10.827,
    "var_max": 10.687,
    "ra": 16.788214,
    "dec": -1.188909,
    "pmra": -18.6,
    "pmdec": -242.12,
    "rv": 0,
    "Asset Name": "ASTRID 003642",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Star of Culture",
    "Hair": "Tiny Crown",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00000219,
    "y_1": 0.00000144,
    "z_1": -0.00003297,
    "Distance in Parsecs_1": 28.0978,
    "HYG Star ID_1": 81917,
    "Hipparcos Catalogue_1": 82169
  },
  {
    "Astrid #": "ASTRID 003643",
    "HYG Star ID": 8194,
    "Hipparcos Catalogue": 8209,
    "Henry Draper Catalogue": 10830,
    "Harvard Revised Catalogue": 514,
    "Distance in Parsecs": 28.1136,
    "mag": 5.29,
    "Absolute Visual Magnitude": 3.045,
    "Spectral Type": "F2IV",
    "Color Index": 0.395,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.810411,
    "y": 11.328887,
    "z": -11.904768,
    "vx": -9.6e-7,
    "vy": 0.00002379,
    "vz": -0.00001543,
    "rarad": 0.460966722332505,
    "decrad": -0.437250528446233,
    "pmrarad": 7.73180857749999e-7,
    "pmdecrad": -3.50908141e-7,
    "bayer": "Eps",
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 8194,
    "lum": 5.27229861422822,
    "bf": "Eps Scl",
    "ra": 1.760763,
    "dec": -25.05261,
    "pmra": 159.48,
    "pmdec": -72.38,
    "rv": 15,
    "Asset Name": "ASTRID 003643",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Determined Shinobi",
    "Hair": "Bangs",
    "Outfit": "Sailor",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -9.6e-7,
    "y_1": 0.00002379,
    "z_1": -0.00001543,
    "Distance in Parsecs_1": 28.1136,
    "HYG Star ID_1": 8194,
    "Hipparcos Catalogue_1": 8209,
    "Henry Draper Catalogue_1": 10830,
    "Harvard Revised Catalogue_1": 514
  },
  {
    "Astrid #": "ASTRID 003644",
    "HYG Star ID": 45606,
    "Hipparcos Catalogue": 45737,
    "Henry Draper Catalogue": 80367,
    "Gliese Catalog": "Gl 340.2",
    "Distance in Parsecs": 28.1452,
    "mag": 8.15,
    "Absolute Visual Magnitude": 5.903,
    "Spectral Type": "K0",
    "Color Index": 0.86,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.516415,
    "y": 18.139072,
    "z": 0.440649,
    "vx": -0.00002526,
    "vy": 0.00004581,
    "vz": -0.00001633,
    "rarad": 2.44115655790331,
    "decrad": 0.0156566781997987,
    "pmrarad": -6.66182478451388e-7,
    "pmdecrad": -6.07520023e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 45606,
    "lum": 0.379140344183306,
    "ra": 9.324531,
    "dec": 0.897062,
    "pmra": -137.41,
    "pmdec": -125.31,
    "rv": 47.5,
    "Asset Name": "ASTRID 003644",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Hachimaki",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00002526,
    "y_1": 0.00004581,
    "z_1": -0.00001633,
    "Distance in Parsecs_1": 28.1452,
    "HYG Star ID_1": 45606,
    "Hipparcos Catalogue_1": 45737,
    "Henry Draper Catalogue_1": 80367,
    "Gliese Catalog_1": "Gl 340.2"
  },
  {
    "Astrid #": "ASTRID 003645",
    "HYG Star ID": 48693,
    "Hipparcos Catalogue": 48833,
    "Henry Draper Catalogue": 86146,
    "Harvard Revised Catalogue": 3928,
    "Gliese Catalog": "NN 3574",
    "Distance in Parsecs": 28.1452,
    "mag": 5.11,
    "Absolute Visual Magnitude": 2.863,
    "Spectral Type": "F6Vs",
    "Color Index": 0.481,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -18.271887,
    "y": 10.796902,
    "z": 18.485477,
    "vx": 0.000012,
    "vy": 0.00001144,
    "vz": -0.00000868,
    "rarad": 2.60789105526521,
    "decrad": 0.71655598101937,
    "pmrarad": -5.66941118041666e-7,
    "pmdecrad": -1.27312072e-7,
    "flam": 19,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 48693,
    "lum": 6.23447661105005,
    "bf": "19    LMi",
    "ra": 9.96141,
    "dec": 41.055633,
    "pmra": -116.94,
    "pmdec": -26.26,
    "rv": -8.9,
    "Asset Name": "ASTRID 003645",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Elegant",
    "Outfit": "Painter",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": 0.000012,
    "y_1": 0.00001144,
    "z_1": -0.00000868,
    "Distance in Parsecs_1": 28.1452,
    "HYG Star ID_1": 48693,
    "Hipparcos Catalogue_1": 48833,
    "Henry Draper Catalogue_1": 86146,
    "Harvard Revised Catalogue_1": 3928,
    "Gliese Catalog_1": "NN 3574"
  },
  {
    "Astrid #": "ASTRID 003646",
    "HYG Star ID": 25598,
    "Hipparcos Catalogue": 25662,
    "Henry Draper Catalogue": 35956,
    "Gliese Catalog": "NN 3347A",
    "Distance in Parsecs": 28.169,
    "mag": 6.71,
    "Absolute Visual Magnitude": 4.461,
    "Spectral Type": "G0V",
    "Color Index": 0.582,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.724437,
    "y": 27.242526,
    "z": 6.121296,
    "vx": -0.00000898,
    "vy": 0.00001643,
    "vz": -0.0000272,
    "rarad": 1.43492447990334,
    "decrad": 0.219053178210567,
    "pmrarad": 3.95074668284722e-7,
    "pmdecrad": -0.000001058833078,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 25598,
    "base": "NN 3347",
    "lum": 1.43086941274933,
    "ra": 5.481008,
    "dec": 12.550823,
    "pmra": 81.49,
    "pmdec": -218.4,
    "rv": 8.6,
    "Asset Name": "ASTRID 003646",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Charmer",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00000898,
    "y_1": 0.00001643,
    "z_1": -0.0000272,
    "Distance in Parsecs_1": 28.169,
    "HYG Star ID_1": 25598,
    "Hipparcos Catalogue_1": 25662,
    "Henry Draper Catalogue_1": 35956,
    "Gliese Catalog_1": "NN 3347A"
  },
  {
    "Astrid #": "ASTRID 003647",
    "HYG Star ID": 106695,
    "Hipparcos Catalogue": 107038,
    "Henry Draper Catalogue": 207897,
    "Distance in Parsecs": 28.169,
    "mag": 8.37,
    "Absolute Visual Magnitude": 6.121,
    "Spectral Type": "K0",
    "Color Index": 0.868,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.283532,
    "y": -1.58789,
    "z": 28.031294,
    "vx": 0.00001955,
    "vy": 0.00004371,
    "vz": -0.00000559,
    "rarad": 5.67544310679923,
    "decrad": 1.47189698572353,
    "pmrarad": 0.00000167037705498611,
    "pmdecrad": 2.95396975e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 106695,
    "lum": 0.310170149947768,
    "ra": 21.678596,
    "dec": 84.333485,
    "pmra": 344.54,
    "pmdec": 60.93,
    "rv": -6.3,
    "Asset Name": "ASTRID 003647",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Braided Ponytail",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00001955,
    "y_1": 0.00004371,
    "z_1": -0.00000559,
    "Distance in Parsecs_1": 28.169,
    "HYG Star ID_1": 106695,
    "Hipparcos Catalogue_1": 107038,
    "Henry Draper Catalogue_1": 207897
  },
  {
    "Astrid #": "ASTRID 003648",
    "HYG Star ID": 110614,
    "Hipparcos Catalogue": 110960,
    "Henry Draper Catalogue": 213051,
    "Harvard Revised Catalogue": 8558,
    "Distance in Parsecs": 28.169,
    "mag": 3.65,
    "Absolute Visual Magnitude": 1.401,
    "Spectral Type": "F3III-IV",
    "Color Index": 0.406,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.969719,
    "y": -10.912416,
    "z": -0.009819,
    "vx": 0.00003747,
    "vy": 0.0000126,
    "vz": 0.00000511,
    "rarad": 5.88538928502342,
    "decrad": -0.0003485764777475,
    "pmrarad": 9.27545533638888e-7,
    "pmdecrad": 1.81659686e-7,
    "bayer": "Zet-1",
    "flam": 55,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 110614,
    "lum": 23.9662452940863,
    "bf": "55Zet1Aqr",
    "ra": 22.480531,
    "dec": -0.019972,
    "pmra": 191.32,
    "pmdec": 37.47,
    "rv": 29,
    "Asset Name": "ASTRID 003648",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Happy Vamp",
    "Hair": "Fedora",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00003747,
    "y_1": 0.0000126,
    "z_1": 0.00000511,
    "Distance in Parsecs_1": 28.169,
    "HYG Star ID_1": 110614,
    "Hipparcos Catalogue_1": 110960,
    "Henry Draper Catalogue_1": 213051,
    "Harvard Revised Catalogue_1": 8558
  },
  {
    "Astrid #": "ASTRID 003649",
    "HYG Star ID": 27178,
    "Hipparcos Catalogue": 27244,
    "Henry Draper Catalogue": 38973,
    "Distance in Parsecs": 28.177,
    "mag": 6.63,
    "Absolute Visual Magnitude": 4.381,
    "Spectral Type": "G2V",
    "Color Index": 0.594,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.995675,
    "y": 16.84167,
    "z": -22.5679,
    "vx": -0.00000612,
    "vy": -0.00001505,
    "vz": -0.0000115,
    "rarad": 1.51174423318657,
    "decrad": -0.928852449268723,
    "pmrarad": 1.85392751444444e-7,
    "pmdecrad": -6.81744997e-7,
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 27178,
    "lum": 1.54028114807161,
    "ra": 5.774438,
    "dec": -53.219325,
    "pmra": 38.24,
    "pmdec": -140.62,
    "rv": 0,
    "Asset Name": "ASTRID 003649",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Pompadour",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": -0.00000612,
    "y_1": -0.00001505,
    "z_1": -0.0000115,
    "Distance in Parsecs_1": 28.177,
    "HYG Star ID_1": 27178,
    "Hipparcos Catalogue_1": 27244,
    "Henry Draper Catalogue_1": 38973
  },
  {
    "Astrid #": "ASTRID 003650",
    "HYG Star ID": 1081,
    "Hipparcos Catalogue": 1083,
    "Distance in Parsecs": 28.1849,
    "mag": 10.77,
    "Absolute Visual Magnitude": 8.52,
    "Color Index": 1.444,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 22.521034,
    "y": 1.326619,
    "z": -16.894723,
    "vx": -0.0000244,
    "vy": -0.00003008,
    "vz": -0.00003489,
    "rarad": 0.0588399887887295,
    "decrad": -0.64278210936185,
    "pmrarad": -0.00000101442414519444,
    "pmdecrad": -0.00000154655564,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 1081,
    "lum": 0.0340408189701,
    "var_min": 10.849,
    "var_max": 10.689,
    "ra": 0.224752,
    "dec": -36.828702,
    "pmra": -209.24,
    "pmdec": -319,
    "rv": 0,
    "Asset Name": "ASTRID 003650",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Naughty Dreams",
    "Hair": "Middle Part",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.0000244,
    "y_1": -0.00003008,
    "z_1": -0.00003489,
    "Distance in Parsecs_1": 28.1849,
    "HYG Star ID_1": 1081,
    "Hipparcos Catalogue_1": 1083
  },
  {
    "Astrid #": "ASTRID 003651",
    "HYG Star ID": 20748,
    "Hipparcos Catalogue": 20800,
    "Henry Draper Catalogue": 28005,
    "Distance in Parsecs": 28.1849,
    "mag": 6.72,
    "Absolute Visual Magnitude": 4.47,
    "Spectral Type": "G0",
    "Color Index": 0.711,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.576881,
    "y": 17.723397,
    "z": 20.564051,
    "vx": 0.00001214,
    "vy": 0.00005708,
    "vz": 7.2e-7,
    "rarad": 1.16680227690033,
    "decrad": 0.817741806283736,
    "pmrarad": 3.99874323722222e-7,
    "pmdecrad": -0.000001464767573,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 20748,
    "lum": 1.41905752168909,
    "ra": 4.456856,
    "dec": 46.853154,
    "pmra": 82.48,
    "pmdec": -302.13,
    "rv": 38.8,
    "Asset Name": "ASTRID 003651",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Angry",
    "Hair": "Curly",
    "Outfit": "School Uniform",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00001214,
    "y_1": 0.00005708,
    "z_1": 7.2e-7,
    "Distance in Parsecs_1": 28.1849,
    "HYG Star ID_1": 20748,
    "Hipparcos Catalogue_1": 20800,
    "Henry Draper Catalogue_1": 28005
  },
  {
    "Astrid #": "ASTRID 003652",
    "HYG Star ID": 29249,
    "Hipparcos Catalogue": 29322,
    "Gliese Catalog": "Wo 9206",
    "Distance in Parsecs": 28.1849,
    "mag": 11.29,
    "Absolute Visual Magnitude": 9.04,
    "Spectral Type": "K:",
    "Color Index": 1.42,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.566975,
    "y": 11.806029,
    "z": -25.586809,
    "vx": -0.00001922,
    "vy": -0.00009485,
    "vz": -0.00004334,
    "rarad": 1.61877345365247,
    "decrad": -1.13805547454501,
    "pmrarad": 8.42703139541666e-7,
    "pmdecrad": -0.000003667033716,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 29249,
    "lum": 0.0210862814993328,
    "var_min": 11.358,
    "var_max": 11.228,
    "ra": 6.183259,
    "dec": -65.205776,
    "pmra": 173.82,
    "pmdec": -756.38,
    "rv": 0,
    "Asset Name": "ASTRID 003652",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Horns",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.00001922,
    "y_1": -0.00009485,
    "z_1": -0.00004334,
    "Distance in Parsecs_1": 28.1849,
    "HYG Star ID_1": 29249,
    "Hipparcos Catalogue_1": 29322,
    "Gliese Catalog_1": "Wo 9206"
  },
  {
    "Astrid #": "ASTRID 003653",
    "HYG Star ID": 114619,
    "Hipparcos Catalogue": 114980,
    "Henry Draper Catalogue": 219495,
    "Gliese Catalog": "NN 4322B",
    "Distance in Parsecs": 28.1849,
    "mag": 9.02,
    "Absolute Visual Magnitude": 6.77,
    "Spectral Type": "K5V",
    "Color Index": 1.13,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.849889,
    "y": -2.045732,
    "z": -25.932933,
    "vx": -0.00001522,
    "vy": 0.00005214,
    "vz": -0.00008429,
    "rarad": 6.09680120864802,
    "decrad": -1.16828673851673,
    "pmrarad": 0.00000171813120252083,
    "pmdecrad": -0.000001975373341,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 2,
    "comp_primary": 114619,
    "base": "NN 4322",
    "lum": 0.170608238900312,
    "ra": 23.288065,
    "dec": -66.937899,
    "pmra": 354.39,
    "pmdec": -407.45,
    "rv": 66.4,
    "Asset Name": "ASTRID 003653",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Serious Mad",
    "Hair": "Goddess",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00001522,
    "y_1": 0.00005214,
    "z_1": -0.00008429,
    "Distance in Parsecs_1": 28.1849,
    "HYG Star ID_1": 114619,
    "Hipparcos Catalogue_1": 114980,
    "Henry Draper Catalogue_1": 219495,
    "Gliese Catalog_1": "NN 4322B"
  },
  {
    "Astrid #": "ASTRID 003654",
    "HYG Star ID": 54259,
    "Hipparcos Catalogue": 54418,
    "Gliese Catalog": "Gl 412.3",
    "Distance in Parsecs": 28.2008,
    "mag": 9.32,
    "Absolute Visual Magnitude": 7.069,
    "Spectral Type": "K7V",
    "Color Index": 1.237,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.203687,
    "y": 5.57615,
    "z": -13.355925,
    "vx": 0.00000482,
    "vy": 0.00006951,
    "vz": -0.00001189,
    "rarad": 2.9151621471694,
    "decrad": -0.493372282518227,
    "pmrarad": -0.00000244055206791666,
    "pmdecrad": -1.88059226e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 54259,
    "lum": 0.129538838897488,
    "ra": 11.135099,
    "dec": -28.26815,
    "pmra": -503.4,
    "pmdec": -38.79,
    "rv": 14.9,
    "Asset Name": "ASTRID 003654",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Honest Lad",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000482,
    "y_1": 0.00006951,
    "z_1": -0.00001189,
    "Distance in Parsecs_1": 28.2008,
    "HYG Star ID_1": 54259,
    "Hipparcos Catalogue_1": 54418,
    "Gliese Catalog_1": "Gl 412.3"
  },
  {
    "Astrid #": "ASTRID 003655",
    "HYG Star ID": 117043,
    "Hipparcos Catalogue": 117410,
    "Gliese Catalog": "Gl 907.1A",
    "Distance in Parsecs": 28.2008,
    "mag": 9.59,
    "Absolute Visual Magnitude": 7.339,
    "Spectral Type": "K8",
    "Color Index": 1.244,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 27.444353,
    "y": -1.386884,
    "z": -6.337777,
    "vx": -0.00000109,
    "vy": 0.00003203,
    "vz": 0.00000373,
    "rarad": 6.23269361801584,
    "decrad": -0.226673902067339,
    "pmrarad": 0.00000113257323914583,
    "pmdecrad": 1.07192304e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 117043,
    "base": "Gl 907.1",
    "lum": 0.101018287054483,
    "ra": 23.807136,
    "dec": -12.987458,
    "pmra": 233.61,
    "pmdec": 22.11,
    "rv": -3.4,
    "Asset Name": "ASTRID 003655",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Hachimaki",
    "Outfit": "Garb",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000109,
    "y_1": 0.00003203,
    "z_1": 0.00000373,
    "Distance in Parsecs_1": 28.2008,
    "HYG Star ID_1": 117043,
    "Hipparcos Catalogue_1": 117410,
    "Gliese Catalog_1": "Gl 907.1A"
  },
  {
    "Astrid #": "ASTRID 003656",
    "HYG Star ID": 63535,
    "Hipparcos Catalogue": 63738,
    "Henry Draper Catalogue": 113415,
    "Harvard Revised Catalogue": 4935,
    "Gliese Catalog": "Gl 496A",
    "Distance in Parsecs": 28.2087,
    "mag": 5.58,
    "Absolute Visual Magnitude": 3.328,
    "Spectral Type": "F7V",
    "Color Index": 0.554,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -25.392435,
    "y": -7.253425,
    "z": -9.917473,
    "vx": -0.00001948,
    "vy": -0.00002578,
    "vz": -0.00000894,
    "rarad": 3.41983519632473,
    "decrad": -0.359249795955048,
    "pmrarad": 6.89065684173611e-7,
    "pmdecrad": 2.4919423e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 63535,
    "base": "Gl 496",
    "lum": 4.06256198186843,
    "ra": 13.062808,
    "dec": -20.583497,
    "pmra": 142.13,
    "pmdec": 5.14,
    "rv": 26.7,
    "Asset Name": "ASTRID 003656",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Braid",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00001948,
    "y_1": -0.00002578,
    "z_1": -0.00000894,
    "Distance in Parsecs_1": 28.2087,
    "HYG Star ID_1": 63535,
    "Hipparcos Catalogue_1": 63738,
    "Henry Draper Catalogue_1": 113415,
    "Harvard Revised Catalogue_1": 4935,
    "Gliese Catalog_1": "Gl 496A"
  },
  {
    "Astrid #": "ASTRID 003657",
    "HYG Star ID": 22723,
    "Hipparcos Catalogue": 22776,
    "Henry Draper Catalogue": 31000,
    "Distance in Parsecs": 28.2167,
    "mag": 7.77,
    "Absolute Visual Magnitude": 5.517,
    "Spectral Type": "G5",
    "Color Index": 0.747,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.426579,
    "y": 21.673834,
    "z": 16.885679,
    "vx": -0.00000108,
    "vy": -6.7e-7,
    "vz": 0.00000127,
    "rarad": 1.28254140173878,
    "decrad": 0.64153823423746,
    "pmrarad": 2.99130040902777e-8,
    "pmdecrad": 5.6141424e-8,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 22723,
    "lum": 0.541003406863467,
    "var": "V536",
    "var_min": 7.81,
    "var_max": 7.75,
    "ra": 4.898947,
    "dec": 36.757433,
    "pmra": 6.17,
    "pmdec": 11.58,
    "rv": 0,
    "Asset Name": "ASTRID 003657",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Hachimaki",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00000108,
    "y_1": -6.7e-7,
    "z_1": 0.00000127,
    "Distance in Parsecs_1": 28.2167,
    "HYG Star ID_1": 22723,
    "Hipparcos Catalogue_1": 22776,
    "Henry Draper Catalogue_1": 31000
  },
  {
    "Astrid #": "ASTRID 003658",
    "HYG Star ID": 41702,
    "Hipparcos Catalogue": 41820,
    "Henry Draper Catalogue": 71974,
    "Distance in Parsecs": 28.2247,
    "mag": 7.26,
    "Absolute Visual Magnitude": 5.007,
    "Spectral Type": "G5",
    "Color Index": 0.73,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.206974,
    "y": 18.252338,
    "z": 16.175322,
    "vx": 0.00000908,
    "vy": -0.00000803,
    "vz": -0.00000669,
    "rarad": 2.23220516883764,
    "decrad": 0.610275733424577,
    "pmrarad": -7.92185554027777e-8,
    "pmdecrad": 4.7705666e-8,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 41702,
    "lum": 0.865366342956348,
    "ra": 8.526396,
    "dec": 34.966224,
    "pmra": -16.34,
    "pmdec": 9.84,
    "rv": -13.3,
    "Asset Name": "ASTRID 003658",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Teen",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000908,
    "y_1": -0.00000803,
    "z_1": -0.00000669,
    "Distance in Parsecs_1": 28.2247,
    "HYG Star ID_1": 41702,
    "Hipparcos Catalogue_1": 41820,
    "Henry Draper Catalogue_1": 71974
  },
  {
    "Astrid #": "ASTRID 003659",
    "HYG Star ID": 78826,
    "Hipparcos Catalogue": 79066,
    "Henry Draper Catalogue": 144840,
    "Distance in Parsecs": 28.2247,
    "mag": 8.7,
    "Absolute Visual Magnitude": 6.447,
    "Spectral Type": "K3V",
    "Color Index": 0.963,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.860757,
    "y": -24.291824,
    "z": -6.414198,
    "vx": -0.00000331,
    "vy": 0.0000118,
    "vz": -0.00003805,
    "rarad": 4.22547762291529,
    "decrad": -0.229257770797027,
    "pmrarad": -2.99323966375e-7,
    "pmdecrad": -0.000001384433946,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 78826,
    "lum": 0.229720630715835,
    "ra": 16.140136,
    "dec": -13.135503,
    "pmra": -61.74,
    "pmdec": -285.56,
    "rv": 0,
    "Asset Name": "ASTRID 003659",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Sad",
    "Hair": "Pompadour",
    "Outfit": "Boxer",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00000331,
    "y_1": 0.0000118,
    "z_1": -0.00003805,
    "Distance in Parsecs_1": 28.2247,
    "HYG Star ID_1": 78826,
    "Hipparcos Catalogue_1": 79066,
    "Henry Draper Catalogue_1": 144840
  },
  {
    "Astrid #": "ASTRID 003660",
    "HYG Star ID": 80656,
    "Hipparcos Catalogue": 80902,
    "Henry Draper Catalogue": 150706,
    "Gliese Catalog": "Gl 632",
    "Distance in Parsecs": 28.2247,
    "mag": 7.01,
    "Absolute Visual Magnitude": 4.757,
    "Spectral Type": "G0",
    "Color Index": 0.607,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.888492,
    "y": -4.632985,
    "z": 27.777585,
    "vx": 0.00000883,
    "vy": -0.00001308,
    "vz": -0.00001914,
    "rarad": 4.32531320522331,
    "decrad": 1.3925942762073,
    "pmrarad": 4.64596950076388e-7,
    "pmdecrad": -4.26490594e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 80656,
    "lum": 1.08943167965913,
    "ra": 16.521479,
    "dec": 79.789775,
    "pmra": 95.83,
    "pmdec": -87.97,
    "rv": -16.9,
    "Asset Name": "ASTRID 003660",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Warm Smile",
    "Hair": "Horns",
    "Outfit": "Spartan",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00000883,
    "y_1": -0.00001308,
    "z_1": -0.00001914,
    "Distance in Parsecs_1": 28.2247,
    "HYG Star ID_1": 80656,
    "Hipparcos Catalogue_1": 80902,
    "Henry Draper Catalogue_1": 150706,
    "Gliese Catalog_1": "Gl 632"
  },
  {
    "Astrid #": "ASTRID 003661",
    "HYG Star ID": 80089,
    "Hipparcos Catalogue": 80331,
    "Henry Draper Catalogue": 148387,
    "Harvard Revised Catalogue": 6132,
    "Gliese Catalog": "Gl 624.1A",
    "Distance in Parsecs": 28.2326,
    "mag": 2.73,
    "Absolute Visual Magnitude": 0.476,
    "Spectral Type": "G8III",
    "Color Index": 0.91,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.477258,
    "y": -12.300885,
    "z": 24.814526,
    "vx": 0.00000337,
    "vy": 0.00001328,
    "vz": -0.00000862,
    "rarad": 4.29347337068807,
    "decrad": 1.07362555443344,
    "pmrarad": -8.23213629583333e-8,
    "pmdecrad": 2.74792394e-7,
    "bayer": "Eta",
    "flam": 14,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 80089,
    "base": "Gl 624.1",
    "lum": 56.1823628134227,
    "bf": "14Eta Dra",
    "ra": 16.39986,
    "dec": 61.514213,
    "pmra": -16.98,
    "pmdec": 56.68,
    "rv": -13.7,
    "Asset Name": "ASTRID 003661",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Braided Bun",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000337,
    "y_1": 0.00001328,
    "z_1": -0.00000862,
    "Distance in Parsecs_1": 28.2326,
    "HYG Star ID_1": 80089,
    "Hipparcos Catalogue_1": 80331,
    "Henry Draper Catalogue_1": 148387,
    "Harvard Revised Catalogue_1": 6132,
    "Gliese Catalog_1": "Gl 624.1A"
  },
  {
    "Astrid #": "ASTRID 003662",
    "HYG Star ID": 100637,
    "Hipparcos Catalogue": 100963,
    "Henry Draper Catalogue": 195034,
    "Distance in Parsecs": 28.2326,
    "mag": 7.09,
    "Absolute Visual Magnitude": 4.836,
    "Spectral Type": "G5",
    "Color Index": 0.642,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.757169,
    "y": -20.873131,
    "z": 10.635025,
    "vx": 0.000005,
    "vy": -0.00001197,
    "vz": -0.00003089,
    "rarad": 5.3590200799808,
    "decrad": 0.386223789226918,
    "pmrarad": -1.14173621770833e-7,
    "pmdecrad": -0.000001181345495,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 100637,
    "lum": 1.0129779687601,
    "ra": 20.469949,
    "dec": 22.128993,
    "pmra": -23.55,
    "pmdec": -243.67,
    "rv": 0,
    "Asset Name": "ASTRID 003662",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Ram Horns",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": 0.000005,
    "y_1": -0.00001197,
    "z_1": -0.00003089,
    "Distance in Parsecs_1": 28.2326,
    "HYG Star ID_1": 100637,
    "Hipparcos Catalogue_1": 100963,
    "Henry Draper Catalogue_1": 195034
  },
  {
    "Astrid #": "ASTRID 003663",
    "HYG Star ID": 49451,
    "Hipparcos Catalogue": 49593,
    "Henry Draper Catalogue": 87696,
    "Harvard Revised Catalogue": 3974,
    "Gliese Catalog": "Gl 378.3",
    "Distance in Parsecs": 28.2406,
    "mag": 4.49,
    "Absolute Visual Magnitude": 2.236,
    "Spectral Type": "A7V",
    "Color Index": 0.19,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -20.337129,
    "y": 10.878482,
    "z": 16.2967,
    "vx": 0.00000972,
    "vy": -0.00001311,
    "vz": -0.00001032,
    "rarad": 2.65041008420841,
    "decrad": 0.615135941149561,
    "pmrarad": 2.47109532979166e-7,
    "pmdecrad": 3.102807e-9,
    "flam": 21,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 49451,
    "lum": 11.1070825591886,
    "bf": "21    LMi",
    "ra": 10.123821,
    "dec": 35.244693,
    "pmra": 50.97,
    "pmdec": 0.64,
    "rv": -17.6,
    "Asset Name": "ASTRID 003663",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Fire",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": 0.00000972,
    "y_1": -0.00001311,
    "z_1": -0.00001032,
    "Distance in Parsecs_1": 28.2406,
    "HYG Star ID_1": 49451,
    "Hipparcos Catalogue_1": 49593,
    "Henry Draper Catalogue_1": 87696,
    "Harvard Revised Catalogue_1": 3974,
    "Gliese Catalog_1": "Gl 378.3"
  },
  {
    "Astrid #": "ASTRID 003664",
    "HYG Star ID": 99401,
    "Hipparcos Catalogue": 99720,
    "Henry Draper Catalogue": 192249,
    "Distance in Parsecs": 28.2406,
    "mag": 9.52,
    "Absolute Visual Magnitude": 7.266,
    "Spectral Type": "F8",
    "Color Index": 0.585,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.540667,
    "y": -23.459468,
    "z": -2.381712,
    "vx": 0.00000441,
    "vy": 0.00000289,
    "vz": 3.2e-7,
    "rarad": 5.29746496994345,
    "decrad": -0.0844367393101912,
    "pmrarad": 1.86507822909722e-7,
    "pmdecrad": 1.1393121e-8,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 99401,
    "lum": 0.108043837237122,
    "var": "V1477",
    "var_min": 9.636,
    "var_max": 9.196,
    "ra": 20.234826,
    "dec": -4.837869,
    "pmra": 38.47,
    "pmdec": 2.35,
    "rv": 0,
    "Asset Name": "ASTRID 003664",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Serious Slumber",
    "Hair": "Manbun",
    "Outfit": "Warlock",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000441,
    "y_1": 0.00000289,
    "z_1": 3.2e-7,
    "Distance in Parsecs_1": 28.2406,
    "HYG Star ID_1": 99401,
    "Hipparcos Catalogue_1": 99720,
    "Henry Draper Catalogue_1": 192249
  },
  {
    "Astrid #": "ASTRID 003665",
    "HYG Star ID": 20092,
    "Hipparcos Catalogue": 20142,
    "Distance in Parsecs": 28.2486,
    "mag": 10.62,
    "Absolute Visual Magnitude": 8.365,
    "Spectral Type": "M0",
    "Color Index": 1.654,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.007727,
    "y": 25.487667,
    "z": -2.043705,
    "vx": -0.00001225,
    "vy": 0.00000562,
    "vz": -0.00000185,
    "rarad": 1.13051921624578,
    "decrad": -0.07241038165564,
    "pmrarad": 4.77056661666666e-7,
    "pmdecrad": -6.5789216e-8,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 20092,
    "lum": 0.0392644935399599,
    "ra": 4.318265,
    "dec": -4.148809,
    "pmra": 98.4,
    "pmdec": -13.57,
    "rv": 0,
    "Asset Name": "ASTRID 003665",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Hachimaki",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001225,
    "y_1": 0.00000562,
    "z_1": -0.00000185,
    "Distance in Parsecs_1": 28.2486,
    "HYG Star ID_1": 20092,
    "Hipparcos Catalogue_1": 20142
  },
  {
    "Astrid #": "ASTRID 003666",
    "HYG Star ID": 87623,
    "Hipparcos Catalogue": 87895,
    "Henry Draper Catalogue": 163840,
    "Harvard Revised Catalogue": 6697,
    "Gliese Catalog": "NN 4039A",
    "Distance in Parsecs": 28.2486,
    "mag": 6.32,
    "Absolute Visual Magnitude": 4.065,
    "Spectral Type": "G2V",
    "Color Index": 0.642,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.310919,
    "y": -25.805102,
    "z": 11.487786,
    "vx": -0.000002,
    "vy": 0.00003039,
    "vz": -0.00000247,
    "rarad": 4.70034086996171,
    "decrad": 0.418807271442217,
    "pmrarad": -8.3775804e-8,
    "pmdecrad": 3.57307682e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 87623,
    "base": "NN 4039",
    "lum": 2.06062991326999,
    "ra": 17.95398,
    "dec": 23.995889,
    "pmra": -17.28,
    "pmdec": 73.7,
    "rv": -28.1,
    "Asset Name": "ASTRID 003666",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Oh Snap",
    "Hair": "Spiky",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.000002,
    "y_1": 0.00003039,
    "z_1": -0.00000247,
    "Distance in Parsecs_1": 28.2486,
    "HYG Star ID_1": 87623,
    "Hipparcos Catalogue_1": 87895,
    "Henry Draper Catalogue_1": 163840,
    "Harvard Revised Catalogue_1": 6697,
    "Gliese Catalog_1": "NN 4039A"
  },
  {
    "Astrid #": "ASTRID 003667",
    "HYG Star ID": 24186,
    "Hipparcos Catalogue": 24243,
    "Henry Draper Catalogue": 277656,
    "Distance in Parsecs": 28.2566,
    "mag": 8.89,
    "Absolute Visual Magnitude": 6.634,
    "Spectral Type": "K2",
    "Color Index": 1.01,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.377504,
    "y": 20.723285,
    "z": 18.703432,
    "vx": 0.00001071,
    "vy": 0.00004144,
    "vz": -0.00004842,
    "rarad": 1.36262083510324,
    "decrad": 0.723368979890841,
    "pmrarad": -6.78739152777777e-8,
    "pmdecrad": -0.000002286526761,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 24186,
    "lum": 0.193374854529525,
    "ra": 5.204828,
    "dec": 41.44599,
    "pmra": -14,
    "pmdec": -471.63,
    "rv": 0,
    "Asset Name": "ASTRID 003667",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Heartbreaker",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00001071,
    "y_1": 0.00004144,
    "z_1": -0.00004842,
    "Distance in Parsecs_1": 28.2566,
    "HYG Star ID_1": 24186,
    "Hipparcos Catalogue_1": 24243,
    "Henry Draper Catalogue_1": 277656
  },
  {
    "Astrid #": "ASTRID 003668",
    "HYG Star ID": 24153,
    "Hipparcos Catalogue": 24210,
    "Henry Draper Catalogue": 33725,
    "Distance in Parsecs": 28.2646,
    "mag": 8.04,
    "Absolute Visual Magnitude": 5.784,
    "Spectral Type": "K1V",
    "Color Index": 0.799,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.813793,
    "y": 27.295595,
    "z": -4.47669,
    "vx": 0.00000835,
    "vy": -0.00000838,
    "vz": -0.00007641,
    "rarad": 1.36093854067481,
    "decrad": -0.159054558118488,
    "pmrarad": -3.50665735145833e-7,
    "pmdecrad": -0.000002705842113,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 24153,
    "lum": 0.423058085776523,
    "ra": 5.198402,
    "dec": -9.113155,
    "pmra": -72.33,
    "pmdec": -558.12,
    "rv": 5.6,
    "Asset Name": "ASTRID 003668",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Sweet Nap",
    "Hair": "Honest Lad",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.00000835,
    "y_1": -0.00000838,
    "z_1": -0.00007641,
    "Distance in Parsecs_1": 28.2646,
    "HYG Star ID_1": 24153,
    "Hipparcos Catalogue_1": 24210,
    "Henry Draper Catalogue_1": 33725
  },
  {
    "Astrid #": "ASTRID 003669",
    "HYG Star ID": 36147,
    "Hipparcos Catalogue": 36249,
    "Henry Draper Catalogue": 58781,
    "Distance in Parsecs": 28.2725,
    "mag": 7.24,
    "Absolute Visual Magnitude": 4.983,
    "Spectral Type": "G5",
    "Color Index": 0.734,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.994735,
    "y": 24.785667,
    "z": 9.225522,
    "vx": -0.00001605,
    "vy": -0.00000313,
    "vz": -0.00000898,
    "rarad": 1.95409805591587,
    "decrad": 0.332394383635258,
    "pmrarad": 5.68104670875e-7,
    "pmdecrad": -3.35927399e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 36147,
    "lum": 0.88470809287053,
    "ra": 7.464105,
    "dec": 19.044795,
    "pmra": 117.18,
    "pmdec": -69.29,
    "rv": 0,
    "Asset Name": "ASTRID 003669",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Manbun",
    "Outfit": "Sailor",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00001605,
    "y_1": -0.00000313,
    "z_1": -0.00000898,
    "Distance in Parsecs_1": 28.2725,
    "HYG Star ID_1": 36147,
    "Hipparcos Catalogue_1": 36249,
    "Henry Draper Catalogue_1": 58781
  },
  {
    "Astrid #": "ASTRID 003670",
    "HYG Star ID": 89944,
    "Hipparcos Catalogue": 90223,
    "Henry Draper Catalogue": 168871,
    "Distance in Parsecs": 28.2805,
    "mag": 6.45,
    "Absolute Visual Magnitude": 4.193,
    "Spectral Type": "G2V",
    "Color Index": 0.593,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 1.957735,
    "y": -18.204482,
    "z": -21.5538,
    "vx": 0.000005,
    "vy": -0.00000413,
    "vz": -0.00003726,
    "rarad": 4.81951782917213,
    "decrad": -0.866606216815368,
    "pmrarad": 1.601339586875e-7,
    "pmdecrad": -7.28093185e-7,
    "con": "Tel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Telescopium",
    "FULL CONSTELLATION NAME": "Telescopium",
    "comp": 1,
    "comp_primary": 89944,
    "lum": 1.83147080466135,
    "ra": 18.409202,
    "dec": -49.652879,
    "pmra": 33.03,
    "pmdec": -150.18,
    "rv": 30.7,
    "Asset Name": "ASTRID 003670",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Shocked",
    "Hair": "Good Boy",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Telescopium",
    "x_1": 0.000005,
    "y_1": -0.00000413,
    "z_1": -0.00003726,
    "Distance in Parsecs_1": 28.2805,
    "HYG Star ID_1": 89944,
    "Hipparcos Catalogue_1": 90223,
    "Henry Draper Catalogue_1": 168871
  },
  {
    "Astrid #": "ASTRID 003671",
    "HYG Star ID": 43843,
    "Hipparcos Catalogue": 43969,
    "Distance in Parsecs": 28.2885,
    "mag": 11.55,
    "Absolute Visual Magnitude": 9.292,
    "Color Index": 1.447,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -18.617191,
    "y": 19.071652,
    "z": -9.482169,
    "vx": 0.00001776,
    "vy": -0.0000027,
    "vz": -0.0000403,
    "rarad": 2.34413702742228,
    "decrad": -0.34181238201978,
    "pmrarad": -3.82808882166666e-7,
    "pmdecrad": -0.000001512230832,
    "con": "Pyx",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pyxis",
    "FULL CONSTELLATION NAME": "Pyxis",
    "comp": 1,
    "comp_primary": 43843,
    "lum": 0.0167186035720446,
    "var_min": 11.626,
    "var_max": 11.466,
    "ra": 8.953944,
    "dec": -19.584407,
    "pmra": -78.96,
    "pmdec": -311.92,
    "rv": 0,
    "Asset Name": "ASTRID 003671",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Hurt",
    "Hair": "Good Boy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pyxis",
    "x_1": 0.00001776,
    "y_1": -0.0000027,
    "z_1": -0.0000403,
    "Distance in Parsecs_1": 28.2885,
    "HYG Star ID_1": 43843,
    "Hipparcos Catalogue_1": 43969
  },
  {
    "Astrid #": "ASTRID 003672",
    "HYG Star ID": 44398,
    "Hipparcos Catalogue": 44526,
    "Henry Draper Catalogue": 77825,
    "Distance in Parsecs": 28.2965,
    "mag": 8.77,
    "Absolute Visual Magnitude": 6.511,
    "Spectral Type": "K2V",
    "Color Index": 0.96,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -19.603088,
    "y": 18.873554,
    "z": -7.758855,
    "vx": 0.00001112,
    "vy": 0.00000993,
    "vz": -0.00000395,
    "rarad": 2.37515284223908,
    "decrad": -0.277756036817996,
    "pmrarad": -5.2534410425e-7,
    "pmdecrad": -1.45007771e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 44398,
    "lum": 0.216570849471007,
    "var": "V405",
    "var_min": 8.811,
    "var_max": 8.741,
    "ra": 9.072416,
    "dec": -15.914249,
    "pmra": -108.36,
    "pmdec": -29.91,
    "rv": 0,
    "Asset Name": "ASTRID 003672",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Fired Up",
    "Hair": "Headband",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00001112,
    "y_1": 0.00000993,
    "z_1": -0.00000395,
    "Distance in Parsecs_1": 28.2965,
    "HYG Star ID_1": 44398,
    "Hipparcos Catalogue_1": 44526,
    "Henry Draper Catalogue_1": 77825
  },
  {
    "Astrid #": "ASTRID 003673",
    "HYG Star ID": 109081,
    "Hipparcos Catalogue": 109427,
    "Henry Draper Catalogue": 210418,
    "Harvard Revised Catalogue": 8450,
    "Gliese Catalog": "Wo 9771",
    "Distance in Parsecs": 28.2965,
    "mag": 3.52,
    "Absolute Visual Magnitude": 1.261,
    "Spectral Type": "A2V",
    "Color Index": 0.086,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 24.963846,
    "y": -12.967714,
    "z": 3.054948,
    "vx": 0.00001029,
    "vy": 0.00003824,
    "vz": 0.00000339,
    "rarad": 5.80409127308071,
    "decrad": 0.10817314881398,
    "pmrarad": 0.00000136698065369444,
    "pmdecrad": 1.51407312e-7,
    "bayer": "The",
    "flam": 26,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 109081,
    "lum": 27.26465458529,
    "bf": "26The Peg",
    "ra": 22.169996,
    "dec": 6.197865,
    "pmra": 281.96,
    "pmdec": 31.23,
    "rv": -7.9,
    "Asset Name": "ASTRID 003673",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Side Part",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001029,
    "y_1": 0.00003824,
    "z_1": 0.00000339,
    "Distance in Parsecs_1": 28.2965,
    "HYG Star ID_1": 109081,
    "Hipparcos Catalogue_1": 109427,
    "Henry Draper Catalogue_1": 210418,
    "Harvard Revised Catalogue_1": 8450,
    "Gliese Catalog_1": "Wo 9771"
  },
  {
    "Astrid #": "ASTRID 003674",
    "HYG Star ID": 45727,
    "Hipparcos Catalogue": 45859,
    "Henry Draper Catalogue": 80355,
    "Distance in Parsecs": 28.3126,
    "mag": 7.82,
    "Absolute Visual Magnitude": 5.56,
    "Spectral Type": "G5",
    "Color Index": 0.72,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -13.61092,
    "y": 11.315047,
    "z": 22.097871,
    "vx": 0.0000149,
    "vy": -0.00000574,
    "vz": 0.00001212,
    "rarad": 2.44804397450725,
    "decrad": 0.895458825145658,
    "pmrarad": -1.80399170534722e-7,
    "pmdecrad": 6.84702361e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 45727,
    "lum": 0.519995996533515,
    "ra": 9.350839,
    "dec": 51.306011,
    "pmra": -37.21,
    "pmdec": 141.23,
    "rv": 0,
    "Asset Name": "ASTRID 003674",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Medium Bob",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.0000149,
    "y_1": -0.00000574,
    "z_1": 0.00001212,
    "Distance in Parsecs_1": 28.3126,
    "HYG Star ID_1": 45727,
    "Hipparcos Catalogue_1": 45859,
    "Henry Draper Catalogue_1": 80355
  },
  {
    "Astrid #": "ASTRID 003675",
    "HYG Star ID": 78897,
    "Hipparcos Catalogue": 79137,
    "Henry Draper Catalogue": 145148,
    "Harvard Revised Catalogue": 6014,
    "Distance in Parsecs": 28.3126,
    "mag": 5.93,
    "Absolute Visual Magnitude": 3.67,
    "Spectral Type": "K1+...",
    "Color Index": 0.988,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.080818,
    "y": -24.91183,
    "z": 3.145509,
    "vx": 0.00002797,
    "vy": -0.00002296,
    "vz": -0.0001023,
    "rarad": 4.22887560588044,
    "decrad": 0.111329230940596,
    "pmrarad": 0.00000125164347909027,
    "pmdecrad": -0.000003620006789,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 78897,
    "lum": 2.96483138952434,
    "ra": 16.153115,
    "dec": 6.378695,
    "pmra": 258.17,
    "pmdec": -746.68,
    "rv": -4,
    "Asset Name": "ASTRID 003675",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Ninja",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00002797,
    "y_1": -0.00002296,
    "z_1": -0.0001023,
    "Distance in Parsecs_1": 28.3126,
    "HYG Star ID_1": 78897,
    "Hipparcos Catalogue_1": 79137,
    "Henry Draper Catalogue_1": 145148,
    "Harvard Revised Catalogue_1": 6014
  },
  {
    "Astrid #": "ASTRID 003676",
    "HYG Star ID": 33761,
    "Hipparcos Catalogue": 33848,
    "Henry Draper Catalogue": 52456,
    "Distance in Parsecs": 28.3206,
    "mag": 8.16,
    "Absolute Visual Magnitude": 5.899,
    "Spectral Type": "K2V",
    "Color Index": 0.863,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.464952,
    "y": 27.104706,
    "z": 3.415518,
    "vx": 0.00000435,
    "vy": 7.2e-7,
    "vz": 0.00000377,
    "rarad": 1.83954521291539,
    "decrad": 0.120896191097666,
    "pmrarad": -1.54752526833333e-7,
    "pmdecrad": 1.33954019e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 33761,
    "lum": 0.380539725002708,
    "ra": 7.026545,
    "dec": 6.926842,
    "pmra": -31.92,
    "pmdec": 27.63,
    "rv": 0,
    "Asset Name": "ASTRID 003676",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Chubby Cheeks",
    "Hair": "Ponytail",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00000435,
    "y_1": 7.2e-7,
    "z_1": 0.00000377,
    "Distance in Parsecs_1": 28.3206,
    "HYG Star ID_1": 33761,
    "Hipparcos Catalogue_1": 33848,
    "Henry Draper Catalogue_1": 52456
  },
  {
    "Astrid #": "ASTRID 003677",
    "HYG Star ID": 61186,
    "Hipparcos Catalogue": 61379,
    "Henry Draper Catalogue": 109409,
    "Harvard Revised Catalogue": 4788,
    "Distance in Parsecs": 28.3206,
    "mag": 5.76,
    "Absolute Visual Magnitude": 3.499,
    "Spectral Type": "G4IV",
    "Color Index": 0.683,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -19.909271,
    "y": -3.038241,
    "z": -19.911191,
    "vx": 0.00000517,
    "vy": 0.00001132,
    "vz": -0.00003308,
    "rarad": 3.29302994392799,
    "decrad": -0.779691246523463,
    "pmrarad": -3.67779658069444e-7,
    "pmdecrad": -9.99831253e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 61186,
    "lum": 3.47056354007987,
    "ra": 12.578448,
    "dec": -44.673018,
    "pmra": -75.86,
    "pmdec": -206.23,
    "rv": 18,
    "Asset Name": "ASTRID 003677",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Frizzy",
    "Outfit": "Skater",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00000517,
    "y_1": 0.00001132,
    "z_1": -0.00003308,
    "Distance in Parsecs_1": 28.3206,
    "HYG Star ID_1": 61186,
    "Hipparcos Catalogue_1": 61379,
    "Henry Draper Catalogue_1": 109409,
    "Harvard Revised Catalogue_1": 4788
  },
  {
    "Astrid #": "ASTRID 003678",
    "HYG Star ID": 81577,
    "Hipparcos Catalogue": 81827,
    "Distance in Parsecs": 28.3206,
    "mag": 11.5,
    "Absolute Visual Magnitude": 9.239,
    "Color Index": 1.437,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -7.82292,
    "y": -22.357772,
    "z": -15.5238,
    "vx": -0.00001034,
    "vy": 0.00002564,
    "vz": -0.00003172,
    "rarad": 4.37580689846503,
    "decrad": -0.580144916583045,
    "pmrarad": -6.43832567777777e-7,
    "pmdecrad": -0.000001339152348,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 81577,
    "lum": 0.0175549662962123,
    "var_min": 11.619,
    "var_max": 11.219,
    "ra": 16.714351,
    "dec": -33.239855,
    "pmra": -132.8,
    "pmdec": -276.22,
    "rv": 0,
    "Asset Name": "ASTRID 003678",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Little Crown",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00001034,
    "y_1": 0.00002564,
    "z_1": -0.00003172,
    "Distance in Parsecs_1": 28.3206,
    "HYG Star ID_1": 81577,
    "Hipparcos Catalogue_1": 81827
  },
  {
    "Astrid #": "ASTRID 003679",
    "HYG Star ID": 30906,
    "Hipparcos Catalogue": 30979,
    "Henry Draper Catalogue": 45977,
    "Distance in Parsecs": 28.3286,
    "mag": 9.11,
    "Absolute Visual Magnitude": 6.849,
    "Spectral Type": "K4V",
    "Color Index": 1.12,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.633964,
    "y": 27.489887,
    "z": -5.797414,
    "vx": 0.00002093,
    "vy": 0.00000501,
    "vz": 0.00001064,
    "rarad": 1.70222716823222,
    "decrad": -0.206104912210681,
    "pmrarad": -7.55630602513889e-7,
    "pmdecrad": 3.83633065e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 30906,
    "lum": 0.15863536054785,
    "ra": 6.502029,
    "dec": -11.808942,
    "pmra": -155.86,
    "pmdec": 79.13,
    "rv": 0,
    "Asset Name": "ASTRID 003679",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Charmer",
    "Hair": "Ninja",
    "Outfit": "Suit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00002093,
    "y_1": 0.00000501,
    "z_1": 0.00001064,
    "Distance in Parsecs_1": 28.3286,
    "HYG Star ID_1": 30906,
    "Hipparcos Catalogue_1": 30979,
    "Henry Draper Catalogue_1": 45977
  },
  {
    "Astrid #": "ASTRID 003680",
    "HYG Star ID": 118232,
    "Gliese Catalog": "NN 3234",
    "Distance in Parsecs": 28.3286,
    "mag": 16.67,
    "Absolute Visual Magnitude": 14.409,
    "Spectral Type": "m",
    "Color Index": 1.94,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.759249,
    "y": 20.119235,
    "z": 13.412328,
    "vx": -0.00003831,
    "vy": 0.00005027,
    "vz": -0.00003324,
    "rarad": 0.937866814923709,
    "decrad": 0.4932095717516,
    "pmrarad": 0.00000214025847418055,
    "pmdecrad": -0.000001332204968,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118232,
    "lum": 0.000150106673257036,
    "ra": 3.582387,
    "dec": 28.258827,
    "pmra": 441.46,
    "pmdec": -274.79,
    "rv": 0,
    "Asset Name": "ASTRID 003680",
    "Astrium Color": "Red",
    "Background": "Volatile Expanse",
    "Expression": "Einstein Tongue",
    "Hair": "Side Part",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00003831,
    "y_1": 0.00005027,
    "z_1": -0.00003324,
    "Distance in Parsecs_1": 28.3286,
    "HYG Star ID_1": 118232,
    "Gliese Catalog_1": "NN 3234"
  },
  {
    "Astrid #": "ASTRID 003681",
    "HYG Star ID": 39902,
    "Hipparcos Catalogue": 40015,
    "Henry Draper Catalogue": 66751,
    "Gliese Catalog": "Gl 296.2",
    "Distance in Parsecs": 28.3447,
    "mag": 6.54,
    "Absolute Visual Magnitude": 4.278,
    "Spectral Type": "F8",
    "Color Index": 0.567,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.289739,
    "y": 8.276016,
    "z": 26.588364,
    "vx": -0.00000783,
    "vy": -0.00002892,
    "vz": -0.00000793,
    "rarad": 2.13950391308435,
    "decrad": 1.2169321746117,
    "pmrarad": 7.82295354944444e-7,
    "pmdecrad": 5.69898481e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 39902,
    "lum": 1.69355770869151,
    "ra": 8.172303,
    "dec": 69.725078,
    "pmra": 161.36,
    "pmdec": 117.55,
    "rv": -14.1,
    "Asset Name": "ASTRID 003681",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Pompadour",
    "Outfit": "Teen",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000783,
    "y_1": -0.00002892,
    "z_1": -0.00000793,
    "Distance in Parsecs_1": 28.3447,
    "HYG Star ID_1": 39902,
    "Hipparcos Catalogue_1": 40015,
    "Henry Draper Catalogue_1": 66751,
    "Gliese Catalog_1": "Gl 296.2"
  },
  {
    "Astrid #": "ASTRID 003682",
    "HYG Star ID": 36183,
    "Hipparcos Catalogue": 36285,
    "Henry Draper Catalogue": 58595,
    "Distance in Parsecs": 28.3527,
    "mag": 7.39,
    "Absolute Visual Magnitude": 5.127,
    "Spectral Type": "G5",
    "Color Index": 0.702,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.072857,
    "y": 19.936792,
    "z": 18.472514,
    "vx": -0.0000157,
    "vy": 0.00000503,
    "vz": 0.00000278,
    "rarad": 1.95553783484067,
    "decrad": 0.709591310529742,
    "pmrarad": 4.46610362527777e-7,
    "pmdecrad": -1.67939458e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 36183,
    "lum": 0.774818532775619,
    "ra": 7.469604,
    "dec": 40.656587,
    "pmra": 92.12,
    "pmdec": -34.64,
    "rv": 9.6,
    "Asset Name": "ASTRID 003682",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Heartbreaker",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.0000157,
    "y_1": 0.00000503,
    "z_1": 0.00000278,
    "Distance in Parsecs_1": 28.3527,
    "HYG Star ID_1": 36183,
    "Hipparcos Catalogue_1": 36285,
    "Henry Draper Catalogue_1": 58595
  },
  {
    "Astrid #": "ASTRID 003683",
    "HYG Star ID": 43287,
    "Hipparcos Catalogue": 43410,
    "Henry Draper Catalogue": 75332,
    "Harvard Revised Catalogue": 3499,
    "Distance in Parsecs": 28.3527,
    "mag": 6.22,
    "Absolute Visual Magnitude": 3.957,
    "Spectral Type": "F7Vn",
    "Color Index": 0.549,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.053401,
    "y": 17.437003,
    "z": 15.560121,
    "vx": -9.5e-7,
    "vy": 0.00001366,
    "vz": -0.00000696,
    "rarad": 2.31490512555312,
    "decrad": 0.580933756349828,
    "pmrarad": -3.01651072041666e-7,
    "pmdecrad": -4.12188591e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 43287,
    "lum": 2.27614539314164,
    "ra": 8.842286,
    "dec": 33.285052,
    "pmra": -62.22,
    "pmdec": -85.02,
    "rv": 5,
    "Asset Name": "ASTRID 003683",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Charmer",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -9.5e-7,
    "y_1": 0.00001366,
    "z_1": -0.00000696,
    "Distance in Parsecs_1": 28.3527,
    "HYG Star ID_1": 43287,
    "Hipparcos Catalogue_1": 43410,
    "Henry Draper Catalogue_1": 75332,
    "Harvard Revised Catalogue_1": 3499
  },
  {
    "Astrid #": "ASTRID 003684",
    "HYG Star ID": 62211,
    "Hipparcos Catalogue": 62406,
    "Gliese Catalog": "Gl 485",
    "Distance in Parsecs": 28.3527,
    "mag": 9.86,
    "Absolute Visual Magnitude": 7.597,
    "Spectral Type": "K4V",
    "Color Index": 1.274,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.733021,
    "y": -4.972707,
    "z": 14.693219,
    "vx": -0.00001486,
    "vy": -0.00000682,
    "vz": -0.00002947,
    "rarad": 3.34813151676802,
    "decrad": 0.544780342286946,
    "pmrarad": 1.28087774402777e-7,
    "pmdecrad": -0.000001180424349,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 62211,
    "lum": 0.0796526079783778,
    "ra": 12.78892,
    "dec": 31.213614,
    "pmra": 26.42,
    "pmdec": -243.48,
    "rv": -1.6,
    "Asset Name": "ASTRID 003684",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Bangs",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00001486,
    "y_1": -0.00000682,
    "z_1": -0.00002947,
    "Distance in Parsecs_1": 28.3527,
    "HYG Star ID_1": 62211,
    "Hipparcos Catalogue_1": 62406,
    "Gliese Catalog_1": "Gl 485"
  },
  {
    "Astrid #": "ASTRID 003685",
    "HYG Star ID": 12690,
    "Hipparcos Catalogue": 12723,
    "Henry Draper Catalogue": 17155,
    "Distance in Parsecs": 28.3607,
    "mag": 9.04,
    "Absolute Visual Magnitude": 6.776,
    "Spectral Type": "K5V",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.770008,
    "y": 12.790825,
    "z": -20.556959,
    "vx": -0.00002776,
    "vy": -0.00001086,
    "vz": -0.00007566,
    "rarad": 0.713708404567302,
    "decrad": -0.810790067000354,
    "pmrarad": 3.51150548826388e-7,
    "pmdecrad": -0.000002556228612,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 12690,
    "lum": 0.169668023234646,
    "ra": 2.726165,
    "dec": -46.454849,
    "pmra": 72.43,
    "pmdec": -527.26,
    "rv": 34.7,
    "Asset Name": "ASTRID 003685",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Braided Bun",
    "Outfit": "Sailor",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00002776,
    "y_1": -0.00001086,
    "z_1": -0.00007566,
    "Distance in Parsecs_1": 28.3607,
    "HYG Star ID_1": 12690,
    "Hipparcos Catalogue_1": 12723,
    "Henry Draper Catalogue_1": 17155
  },
  {
    "Astrid #": "ASTRID 003686",
    "HYG Star ID": 26477,
    "Hipparcos Catalogue": 26542,
    "Distance in Parsecs": 28.3607,
    "mag": 9.55,
    "Absolute Visual Magnitude": 7.286,
    "Spectral Type": "K5V",
    "Color Index": 1.043,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.066356,
    "y": 22.142956,
    "z": -17.599688,
    "vx": -0.00001086,
    "vy": -0.00002066,
    "vz": -0.00002727,
    "rarad": 1.47774615807566,
    "decrad": -0.669464339593367,
    "pmrarad": 3.13722932687499e-7,
    "pmdecrad": -0.000001226239242,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 26477,
    "lum": 0.106071814963531,
    "ra": 5.644575,
    "dec": -38.357481,
    "pmra": 64.71,
    "pmdec": -252.93,
    "rv": 0,
    "Asset Name": "ASTRID 003686",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Smoulder",
    "Hair": "High Fade",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": -0.00001086,
    "y_1": -0.00002066,
    "z_1": -0.00002727,
    "Distance in Parsecs_1": 28.3607,
    "HYG Star ID_1": 26477,
    "Hipparcos Catalogue_1": 26542
  },
  {
    "Astrid #": "ASTRID 003687",
    "HYG Star ID": 87921,
    "Hipparcos Catalogue": 88194,
    "Henry Draper Catalogue": 164595,
    "Distance in Parsecs": 28.3607,
    "mag": 7.07,
    "Absolute Visual Magnitude": 4.806,
    "Spectral Type": "G2V",
    "Color Index": 0.635,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 0.069768,
    "y": -24.666305,
    "z": 13.996483,
    "vx": -0.00001918,
    "vy": 0.0000053,
    "vz": 0.00002436,
    "rarad": 4.71521833333355,
    "decrad": 0.516127406290288,
    "pmrarad": -6.75733307958333e-7,
    "pmdecrad": 8.40376033e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 87921,
    "lum": 1.04135786152843,
    "ra": 18.010807,
    "dec": 29.571922,
    "pmra": -139.38,
    "pmdec": 173.34,
    "rv": 7.2,
    "Asset Name": "ASTRID 003687",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Serious Mad",
    "Hair": "Round Brush",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001918,
    "y_1": 0.0000053,
    "z_1": 0.00002436,
    "Distance in Parsecs_1": 28.3607,
    "HYG Star ID_1": 87921,
    "Hipparcos Catalogue_1": 88194,
    "Henry Draper Catalogue_1": 164595
  },
  {
    "Astrid #": "ASTRID 003688",
    "HYG Star ID": 24148,
    "Hipparcos Catalogue": 24205,
    "Henry Draper Catalogue": 33636,
    "Distance in Parsecs": 28.3688,
    "mag": 7,
    "Absolute Visual Magnitude": 4.736,
    "Spectral Type": "G0",
    "Color Index": 0.588,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.908053,
    "y": 27.661135,
    "z": 2.178173,
    "vx": -0.0000244,
    "vy": 0.00000482,
    "vz": -0.00001897,
    "rarad": 1.36037133402506,
    "decrad": 0.0768562617212225,
    "pmrarad": 8.76688578548611e-7,
    "pmdecrad": -6.65746146e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 24148,
    "lum": 1.11070825591886,
    "ra": 5.196236,
    "dec": 4.403539,
    "pmra": 180.83,
    "pmdec": -137.32,
    "rv": -1.8,
    "Asset Name": "ASTRID 003688",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Good Boy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.0000244,
    "y_1": 0.00000482,
    "z_1": -0.00001897,
    "Distance in Parsecs_1": 28.3688,
    "HYG Star ID_1": 24148,
    "Hipparcos Catalogue_1": 24205,
    "Henry Draper Catalogue_1": 33636
  },
  {
    "Astrid #": "ASTRID 003689",
    "HYG Star ID": 28683,
    "Hipparcos Catalogue": 28754,
    "Gliese Catalog": "Wo 9201",
    "Distance in Parsecs": 28.3688,
    "mag": 12.14,
    "Absolute Visual Magnitude": 9.876,
    "Spectral Type": "K",
    "Color Index": 1.458,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.305656,
    "y": 16.141617,
    "z": -23.326951,
    "vx": -0.00009419,
    "vy": 0.00004036,
    "vz": 0.0000217,
    "rarad": 1.58970813547703,
    "decrad": -0.965394170676972,
    "pmrarad": 0.00000329285451833333,
    "pmdecrad": 0.000001656608346,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 28683,
    "lum": 0.00976337566582641,
    "var_min": 12.274,
    "var_max": 12.024,
    "ra": 6.072238,
    "dec": -55.313012,
    "pmra": 679.2,
    "pmdec": 341.7,
    "rv": 6,
    "Asset Name": "ASTRID 003689",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Spiky",
    "Outfit": "Garb",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00009419,
    "y_1": 0.00004036,
    "z_1": 0.0000217,
    "Distance in Parsecs_1": 28.3688,
    "HYG Star ID_1": 28683,
    "Hipparcos Catalogue_1": 28754,
    "Gliese Catalog_1": "Wo 9201"
  },
  {
    "Astrid #": "ASTRID 003690",
    "HYG Star ID": 118325,
    "Gliese Catalog": "Gl 173.1B",
    "Distance in Parsecs": 28.401,
    "mag": 14.19,
    "Absolute Visual Magnitude": 11.923,
    "Spectral Type": "k-m",
    "Color Index": 1.57,
    "Spectral Abbreviation": "k",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.601857,
    "y": 26.282279,
    "z": 4.864459,
    "vx": 0.00000204,
    "vy": 0.00000804,
    "vz": -0.00004747,
    "rarad": 1.22052518110579,
    "decrad": 0.1721264236959,
    "pmrarad": 2.96221158819444e-8,
    "pmdecrad": -0.000001696586082,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 21657,
    "base": "Gl 173.1",
    "lum": 0.00148183551751643,
    "ra": 4.662063,
    "dec": 9.862118,
    "pmra": 6.11,
    "pmdec": -349.95,
    "rv": 0,
    "Asset Name": "ASTRID 003690",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Shy Smile",
    "Hair": "Ninja",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000204,
    "y_1": 0.00000804,
    "z_1": -0.00004747,
    "Distance in Parsecs_1": 28.401,
    "HYG Star ID_1": 118325,
    "Gliese Catalog_1": "Gl 173.1B"
  },
  {
    "Astrid #": "ASTRID 003691",
    "HYG Star ID": 97329,
    "Hipparcos Catalogue": 97640,
    "Henry Draper Catalogue": 226099,
    "Distance in Parsecs": 28.4091,
    "mag": 8,
    "Absolute Visual Magnitude": 5.733,
    "Spectral Type": "G5",
    "Color Index": 0.787,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.81351,
    "y": -20.622454,
    "z": 16.274501,
    "vx": 0.00003147,
    "vy": 0.00003444,
    "vz": 0.00002273,
    "rarad": 5.19532816272704,
    "decrad": 0.609993620659601,
    "pmrarad": 0.00000154408309120138,
    "pmdecrad": 9.76075383e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 97329,
    "lum": 0.443404401633441,
    "ra": 19.844692,
    "dec": 34.95006,
    "pmra": 318.49,
    "pmdec": 201.33,
    "rv": 0,
    "Asset Name": "ASTRID 003691",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Lion Mane",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00003147,
    "y_1": 0.00003444,
    "z_1": 0.00002273,
    "Distance in Parsecs_1": 28.4091,
    "HYG Star ID_1": 97329,
    "Hipparcos Catalogue_1": 97640,
    "Henry Draper Catalogue_1": 226099
  },
  {
    "Astrid #": "ASTRID 003692",
    "HYG Star ID": 6326,
    "Hipparcos Catalogue": 6339,
    "Distance in Parsecs": 28.4172,
    "mag": 8.46,
    "Absolute Visual Magnitude": 6.192,
    "Spectral Type": "K2V",
    "Color Index": 0.916,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 22.753214,
    "y": 8.447225,
    "z": 14.78083,
    "vx": -0.00001911,
    "vy": 0.00007075,
    "vz": -0.000011,
    "rarad": 0.355478781204924,
    "decrad": 0.547011035345443,
    "pmrarad": 0.000002568251991375,
    "pmdecrad": -4.53446235e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 6326,
    "lum": 0.290536031430314,
    "var": "EN",
    "var_min": 8.484,
    "var_max": 8.434,
    "ra": 1.357829,
    "dec": 31.341424,
    "pmra": 529.74,
    "pmdec": -93.53,
    "rv": 0,
    "Asset Name": "ASTRID 003692",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Nervous",
    "Hair": "Charmer",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001911,
    "y_1": 0.00007075,
    "z_1": -0.000011,
    "Distance in Parsecs_1": 28.4172,
    "HYG Star ID_1": 6326,
    "Hipparcos Catalogue_1": 6339
  },
  {
    "Astrid #": "ASTRID 003693",
    "HYG Star ID": 92280,
    "Hipparcos Catalogue": 92569,
    "Henry Draper Catalogue": 174719,
    "Distance in Parsecs": 28.4172,
    "mag": 7.51,
    "Absolute Visual Magnitude": 5.242,
    "Spectral Type": "G6V",
    "Color Index": 0.701,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.360219,
    "y": -27.655341,
    "z": 1.502598,
    "vx": -0.00000457,
    "vy": 0.00001677,
    "vz": -0.00002021,
    "rarad": 4.93843986577295,
    "decrad": 0.0529013166682299,
    "pmrarad": -2.44830908680555e-8,
    "pmdecrad": -6.77866488e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 92280,
    "lum": 0.69694739635632,
    "ra": 18.863451,
    "dec": 3.031022,
    "pmra": -5.05,
    "pmdec": -139.82,
    "rv": -18,
    "Asset Name": "ASTRID 003693",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Round Brush",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": -0.00000457,
    "y_1": 0.00001677,
    "z_1": -0.00002021,
    "Distance in Parsecs_1": 28.4172,
    "HYG Star ID_1": 92280,
    "Hipparcos Catalogue_1": 92569,
    "Henry Draper Catalogue_1": 174719
  },
  {
    "Astrid #": "ASTRID 003694",
    "HYG Star ID": 34332,
    "Hipparcos Catalogue": 34423,
    "Henry Draper Catalogue": 54359,
    "Distance in Parsecs": 28.4333,
    "mag": 8.85,
    "Absolute Visual Magnitude": 6.581,
    "Spectral Type": "K0",
    "Color Index": 0.952,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -8.205777,
    "y": 26.775059,
    "z": -4.922136,
    "vx": 0.00001823,
    "vy": 0.00003421,
    "vz": -8.6e-7,
    "rarad": 1.86817927654597,
    "decrad": -0.17398668360119,
    "pmrarad": -9.65651888930555e-7,
    "pmdecrad": 1.36959864e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 34332,
    "lum": 0.20304860027168,
    "ra": 7.135919,
    "dec": -9.968703,
    "pmra": -199.18,
    "pmdec": 28.25,
    "rv": 26.5,
    "Asset Name": "ASTRID 003694",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Oh No",
    "Hair": "Shinobi",
    "Outfit": "Garb",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00001823,
    "y_1": 0.00003421,
    "z_1": -8.6e-7,
    "Distance in Parsecs_1": 28.4333,
    "HYG Star ID_1": 34332,
    "Hipparcos Catalogue_1": 34423,
    "Henry Draper Catalogue_1": 54359
  },
  {
    "Astrid #": "ASTRID 003695",
    "HYG Star ID": 107677,
    "Hipparcos Catalogue": 108020,
    "Distance in Parsecs": 28.4333,
    "mag": 9.84,
    "Absolute Visual Magnitude": 7.571,
    "Spectral Type": "K7",
    "Color Index": 1.18,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.51783,
    "y": -6.505754,
    "z": -25.602792,
    "vx": 0.0000055,
    "vy": 0.00001321,
    "vz": -0.0000011,
    "rarad": 5.72923534574848,
    "decrad": -1.12080517445947,
    "pmrarad": 4.96885541201388e-7,
    "pmdecrad": -8.862394e-8,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 107677,
    "lum": 0.0815830617449007,
    "var_min": 9.926,
    "var_max": 9.776,
    "ra": 21.884067,
    "dec": -64.217406,
    "pmra": 102.49,
    "pmdec": -18.28,
    "rv": 0,
    "Asset Name": "ASTRID 003695",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Frizzy",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.0000055,
    "y_1": 0.00001321,
    "z_1": -0.0000011,
    "Distance in Parsecs_1": 28.4333,
    "HYG Star ID_1": 107677,
    "Hipparcos Catalogue_1": 108020
  },
  {
    "Astrid #": "ASTRID 003696",
    "HYG Star ID": 116702,
    "Hipparcos Catalogue": 117069,
    "Gliese Catalog": "NN 4359",
    "Distance in Parsecs": 28.4414,
    "mag": 11.3,
    "Absolute Visual Magnitude": 9.03,
    "Spectral Type": "M1",
    "Color Index": 1.4,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 12.106493,
    "y": -0.84702,
    "z": 25.722143,
    "vx": -0.00000606,
    "vy": 0.00007547,
    "vz": 0.00000534,
    "rarad": 6.21330413528578,
    "decrad": 1.12995003645099,
    "pmrarad": 0.00000263248980404861,
    "pmdecrad": 4.39822971e-7,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 116702,
    "lum": 0.0212813904598271,
    "var_min": 11.369,
    "var_max": 11.209,
    "ra": 23.733074,
    "dec": 64.741368,
    "pmra": 542.99,
    "pmdec": 90.72,
    "rv": 0,
    "Asset Name": "ASTRID 003696",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Smoulder",
    "Hair": "Shinobi",
    "Outfit": "Star Knight",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00000606,
    "y_1": 0.00007547,
    "z_1": 0.00000534,
    "Distance in Parsecs_1": 28.4414,
    "HYG Star ID_1": 116702,
    "Hipparcos Catalogue_1": 117069,
    "Gliese Catalog_1": "NN 4359"
  },
  {
    "Astrid #": "ASTRID 003697",
    "HYG Star ID": 68185,
    "Hipparcos Catalogue": 68403,
    "Distance in Parsecs": 28.4495,
    "mag": 10.27,
    "Absolute Visual Magnitude": 8,
    "Spectral Type": "M0",
    "Color Index": 1.297,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -17.47091,
    "y": -10.105466,
    "z": 20.050459,
    "vx": -0.00001425,
    "vy": 0.00002227,
    "vz": -0.0000012,
    "rarad": 3.66599422934076,
    "decrad": 0.782104093612978,
    "pmrarad": -9.28418198263888e-7,
    "pmdecrad": -5.9244231e-8,
    "con": "CVn",
    "Full Constellation Name Formula 1": "Canes Venatici",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canes Venatici",
    "comp": 1,
    "comp_primary": 68185,
    "lum": 0.0549540873857624,
    "var_min": 10.343,
    "var_max": 10.223,
    "ra": 14.003066,
    "dec": 44.811264,
    "pmra": -191.5,
    "pmdec": -12.22,
    "rv": 0,
    "Asset Name": "ASTRID 003697",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Guilty Laugh",
    "Hair": "Hachimaki",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canes Venatici",
    "x_1": -0.00001425,
    "y_1": 0.00002227,
    "z_1": -0.0000012,
    "Distance in Parsecs_1": 28.4495,
    "HYG Star ID_1": 68185,
    "Hipparcos Catalogue_1": 68403
  },
  {
    "Astrid #": "ASTRID 003698",
    "HYG Star ID": 30039,
    "Hipparcos Catalogue": 30112,
    "Henry Draper Catalogue": 288595,
    "Distance in Parsecs": 28.4576,
    "mag": 9.83,
    "Absolute Visual Magnitude": 7.559,
    "Spectral Type": "K7V",
    "Color Index": 1.273,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.505516,
    "y": 28.324888,
    "z": 1.121659,
    "vx": 0.0000169,
    "vy": 0.00000203,
    "vz": -0.00001348,
    "rarad": 1.65902305719376,
    "decrad": 0.0394253226311425,
    "pmrarad": -5.97920712229166e-7,
    "pmdecrad": -4.74099298e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 30039,
    "lum": 0.0824897523935329,
    "ra": 6.337001,
    "dec": 2.258905,
    "pmra": -123.33,
    "pmdec": -97.79,
    "rv": 0,
    "Asset Name": "ASTRID 003698",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Uwu",
    "Hair": "Middle Part",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": 0.0000169,
    "y_1": 0.00000203,
    "z_1": -0.00001348,
    "Distance in Parsecs_1": 28.4576,
    "HYG Star ID_1": 30039,
    "Hipparcos Catalogue_1": 30112,
    "Henry Draper Catalogue_1": 288595
  },
  {
    "Astrid #": "ASTRID 003699",
    "HYG Star ID": 74006,
    "Hipparcos Catalogue": 74234,
    "Henry Draper Catalogue": 134440,
    "Gliese Catalog": "Gl 579.2B",
    "Distance in Parsecs": 28.4576,
    "mag": 9.44,
    "Absolute Visual Magnitude": 7.169,
    "Spectral Type": "K0V:",
    "Color Index": 0.85,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.420289,
    "y": -20.140316,
    "z": -8.065516,
    "vx": -0.0002124,
    "vy": -0.00002754,
    "vz": -0.00055793,
    "rarad": 3.97156890844379,
    "decrad": -0.287332193877931,
    "pmrarad": -0.00000485526356665972,
    "pmdecrad": -0.000017175300335,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 2,
    "comp_primary": 74007,
    "base": "Gl 579.2",
    "lum": 0.118140825192088,
    "ra": 15.170276,
    "dec": -16.462922,
    "pmra": -1001.47,
    "pmdec": -3542.66,
    "rv": 308,
    "Asset Name": "ASTRID 003699",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Braided Ponytail",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.0002124,
    "y_1": -0.00002754,
    "z_1": -0.00055793,
    "Distance in Parsecs_1": 28.4576,
    "HYG Star ID_1": 74006,
    "Hipparcos Catalogue_1": 74234,
    "Henry Draper Catalogue_1": 134440,
    "Gliese Catalog_1": "Gl 579.2B"
  },
  {
    "Astrid #": "ASTRID 003700",
    "HYG Star ID": 50086,
    "Hipparcos Catalogue": 50226,
    "Distance in Parsecs": 28.4657,
    "mag": 10.52,
    "Absolute Visual Magnitude": 8.248,
    "Color Index": 1.348,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.697402,
    "y": 9.692531,
    "z": 18.120797,
    "vx": 0.00001208,
    "vy": -0.0000102,
    "vz": 0.00001859,
    "rarad": 2.68430754279452,
    "decrad": 0.690060140134698,
    "pmrarad": 1.34147945409722e-7,
    "pmdecrad": 8.46872537e-7,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 50086,
    "lum": 0.0437320665169645,
    "var_min": 10.576,
    "var_max": 10.466,
    "ra": 10.2533,
    "dec": 39.537534,
    "pmra": 27.67,
    "pmdec": 174.68,
    "rv": 0,
    "Asset Name": "ASTRID 003700",
    "Astrium Color": "Green",
    "Background": "Burning Cosmos",
    "Expression": "Oh Snap",
    "Hair": "Tiny Crown",
    "Outfit": "Skater",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": 0.00001208,
    "y_1": -0.0000102,
    "z_1": 0.00001859,
    "Distance in Parsecs_1": 28.4657,
    "HYG Star ID_1": 50086,
    "Hipparcos Catalogue_1": 50226
  },
  {
    "Astrid #": "ASTRID 003701",
    "HYG Star ID": 42026,
    "Hipparcos Catalogue": 42145,
    "Henry Draper Catalogue": 72614,
    "Distance in Parsecs": 28.4819,
    "mag": 8.74,
    "Absolute Visual Magnitude": 6.467,
    "Spectral Type": "K2V",
    "Color Index": 0.923,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.33616,
    "y": 16.547687,
    "z": 18.962312,
    "vx": -0.00003592,
    "vy": 0.00010336,
    "vz": -0.00002575,
    "rarad": 2.24914223705614,
    "decrad": 0.728505020512376,
    "pmrarad": -0.00000129542215444444,
    "pmdecrad": -0.000003083027157,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 42026,
    "lum": 0.225527756674524,
    "ra": 8.591091,
    "dec": 41.740263,
    "pmra": -267.2,
    "pmdec": -635.92,
    "rv": 58.4,
    "Asset Name": "ASTRID 003701",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Smitten",
    "Hair": "Lion Mane",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00003592,
    "y_1": 0.00010336,
    "z_1": -0.00002575,
    "Distance in Parsecs_1": 28.4819,
    "HYG Star ID_1": 42026,
    "Hipparcos Catalogue_1": 42145,
    "Henry Draper Catalogue_1": 72614
  },
  {
    "Astrid #": "ASTRID 003702",
    "HYG Star ID": 68870,
    "Hipparcos Catalogue": 69090,
    "Henry Draper Catalogue": 122862,
    "Harvard Revised Catalogue": 5279,
    "Gliese Catalog": "Gl 539.1",
    "Distance in Parsecs": 28.4981,
    "mag": 6.02,
    "Absolute Visual Magnitude": 3.746,
    "Spectral Type": "G1V",
    "Color Index": 0.581,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.308201,
    "y": -3.959151,
    "z": -27.507493,
    "vx": -0.00003316,
    "vy": 0.00001871,
    "vz": 0.00002833,
    "rarad": 3.70210264149553,
    "decrad": -1.30638392003396,
    "pmrarad": -0.00000117480051072222,
    "pmdecrad": 8.74167547e-7,
    "con": "Aps",
    "Full Constellation Name Formula 1": "Apus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Apus",
    "comp": 1,
    "comp_primary": 68870,
    "lum": 2.76439437122033,
    "ra": 14.14099,
    "dec": -74.850285,
    "pmra": -242.32,
    "pmdec": 180.31,
    "rv": -22.1,
    "Asset Name": "ASTRID 003702",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Curly",
    "Outfit": "Teen",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Apus",
    "x_1": -0.00003316,
    "y_1": 0.00001871,
    "z_1": 0.00002833,
    "Distance in Parsecs_1": 28.4981,
    "HYG Star ID_1": 68870,
    "Hipparcos Catalogue_1": 69090,
    "Henry Draper Catalogue_1": 122862,
    "Harvard Revised Catalogue_1": 5279,
    "Gliese Catalog_1": "Gl 539.1"
  },
  {
    "Astrid #": "ASTRID 003703",
    "HYG Star ID": 117125,
    "Hipparcos Catalogue": 117492,
    "Distance in Parsecs": 28.4981,
    "mag": 9.38,
    "Absolute Visual Magnitude": 7.106,
    "Spectral Type": "M0",
    "Color Index": 1.12,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 23.128853,
    "y": -1.06216,
    "z": 16.615346,
    "vx": 0.00000865,
    "vy": 0.0000323,
    "vz": -0.00000998,
    "rarad": 6.2372916312194,
    "decrad": 0.622457415755582,
    "pmrarad": 0.00000114619650356944,
    "pmdecrad": -4.30999362e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 117125,
    "lum": 0.125198752062488,
    "ra": 23.824699,
    "dec": 35.664183,
    "pmra": 236.42,
    "pmdec": -88.9,
    "rv": 0,
    "Asset Name": "ASTRID 003703",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Charmer",
    "Outfit": "Rider",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000865,
    "y_1": 0.0000323,
    "z_1": -0.00000998,
    "Distance in Parsecs_1": 28.4981,
    "HYG Star ID_1": 117125,
    "Hipparcos Catalogue_1": 117492
  },
  {
    "Astrid #": "ASTRID 003704",
    "HYG Star ID": 83804,
    "Hipparcos Catalogue": 84062,
    "Henry Draper Catalogue": 155902,
    "Gliese Catalog": "NN 3993",
    "Distance in Parsecs": 28.5063,
    "mag": 6.98,
    "Absolute Visual Magnitude": 4.705,
    "Spectral Type": "G5",
    "Color Index": 0.705,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.314929,
    "y": -15.312868,
    "z": 23.814627,
    "vx": -0.00000205,
    "vy": -0.00000745,
    "vz": -0.00000507,
    "rarad": 4.49919877449428,
    "decrad": 0.988889716688251,
    "pmrarad": -1.50292240972222e-8,
    "pmdecrad": -3.23807057e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 83804,
    "lum": 1.14287833478977,
    "ra": 17.185673,
    "dec": 56.659207,
    "pmra": -3.1,
    "pmdec": -66.79,
    "rv": 0,
    "Asset Name": "ASTRID 003704",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Honest Lad",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000205,
    "y_1": -0.00000745,
    "z_1": -0.00000507,
    "Distance in Parsecs_1": 28.5063,
    "HYG Star ID_1": 83804,
    "Hipparcos Catalogue_1": 84062,
    "Henry Draper Catalogue_1": 155902,
    "Gliese Catalog_1": "NN 3993"
  },
  {
    "Astrid #": "ASTRID 003705",
    "HYG Star ID": 54354,
    "Hipparcos Catalogue": 54513,
    "Distance in Parsecs": 28.5225,
    "mag": 11.22,
    "Absolute Visual Magnitude": 8.944,
    "Color Index": 1.446,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -27.734895,
    "y": 6.249818,
    "z": -2.290931,
    "vx": 0.0000112,
    "vy": 0.00004441,
    "vz": -0.00001446,
    "rarad": 2.91995342891744,
    "decrad": -0.0804067312562062,
    "pmrarad": -0.0000016052665776875,
    "pmdecrad": -5.08714995e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54354,
    "lum": 0.0230356250011753,
    "var_min": 11.279,
    "var_max": 11.159,
    "ra": 11.153401,
    "dec": -4.606966,
    "pmra": -331.11,
    "pmdec": -104.93,
    "rv": 0,
    "Asset Name": "ASTRID 003705",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Sweetheart",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.0000112,
    "y_1": 0.00004441,
    "z_1": -0.00001446,
    "Distance in Parsecs_1": 28.5225,
    "HYG Star ID_1": 54354,
    "Hipparcos Catalogue_1": 54513
  },
  {
    "Astrid #": "ASTRID 003706",
    "HYG Star ID": 79595,
    "Hipparcos Catalogue": 79837,
    "Henry Draper Catalogue": 146946,
    "Distance in Parsecs": 28.5225,
    "mag": 6.84,
    "Absolute Visual Magnitude": 4.564,
    "Spectral Type": "G0",
    "Color Index": 0.605,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.457772,
    "y": -21.867708,
    "z": 15.0318,
    "vx": 0.00004124,
    "vy": 0.00003633,
    "vz": 0.00001945,
    "rarad": 4.26630820825301,
    "decrad": 0.555091209442982,
    "pmrarad": 7.55000344729166e-7,
    "pmdecrad": 0.000001513879198,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 79595,
    "lum": 1.30136763010746,
    "ra": 16.296097,
    "dec": 31.804384,
    "pmra": 155.73,
    "pmdec": 312.26,
    "rv": -32,
    "Asset Name": "ASTRID 003706",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Upset",
    "Hair": "Fire",
    "Outfit": "Rider",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": 0.00004124,
    "y_1": 0.00003633,
    "z_1": 0.00001945,
    "Distance in Parsecs_1": 28.5225,
    "HYG Star ID_1": 79595,
    "Hipparcos Catalogue_1": 79837,
    "Henry Draper Catalogue_1": 146946
  },
  {
    "Astrid #": "ASTRID 003707",
    "HYG Star ID": 89634,
    "Hipparcos Catalogue": 89912,
    "Henry Draper Catalogue": 168874,
    "Distance in Parsecs": 28.547,
    "mag": 6.99,
    "Absolute Visual Magnitude": 4.712,
    "Spectral Type": "G2IV",
    "Color Index": 0.636,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.296609,
    "y": -25.209986,
    "z": 13.194869,
    "vx": 0.00000359,
    "vy": 0.00002291,
    "vz": 0.00000486,
    "rarad": 4.80323719905747,
    "decrad": 0.480494927676389,
    "pmrarad": 1.97949425770833e-7,
    "pmdecrad": 5.15163016e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 89634,
    "lum": 1.13553362784193,
    "ra": 18.347015,
    "dec": 27.530331,
    "pmra": 40.83,
    "pmdec": 106.26,
    "rv": -17.3,
    "Asset Name": "ASTRID 003707",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Undercut",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000359,
    "y_1": 0.00002291,
    "z_1": 0.00000486,
    "Distance in Parsecs_1": 28.547,
    "HYG Star ID_1": 89634,
    "Hipparcos Catalogue_1": 89912,
    "Henry Draper Catalogue_1": 168874
  },
  {
    "Astrid #": "ASTRID 003708",
    "HYG Star ID": 91737,
    "Hipparcos Catalogue": 92024,
    "Henry Draper Catalogue": 172555,
    "Harvard Revised Catalogue": 7012,
    "Distance in Parsecs": 28.547,
    "mag": 4.78,
    "Absolute Visual Magnitude": 2.502,
    "Spectral Type": "A7V",
    "Color Index": 0.199,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 2.388256,
    "y": -11.885013,
    "z": -25.845211,
    "vx": 9.3e-7,
    "vy": 0.00001831,
    "vz": -0.00001059,
    "rarad": 4.9106929379005,
    "decrad": -1.13221706088041,
    "pmrarad": 1.583886294375e-7,
    "pmdecrad": -7.21014905e-7,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 91737,
    "lum": 8.69360692509416,
    "ra": 18.757465,
    "dec": -64.871259,
    "pmra": 32.67,
    "pmdec": -148.72,
    "rv": 2,
    "Asset Name": "ASTRID 003708",
    "Astrium Color": "White",
    "Background": "Hazy Void",
    "Expression": "Scared",
    "Hair": "Fedora",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": 9.3e-7,
    "y_1": 0.00001831,
    "z_1": -0.00001059,
    "Distance in Parsecs_1": 28.547,
    "HYG Star ID_1": 91737,
    "Hipparcos Catalogue_1": 92024,
    "Henry Draper Catalogue_1": 172555,
    "Harvard Revised Catalogue_1": 7012
  },
  {
    "Astrid #": "ASTRID 003709",
    "HYG Star ID": 75134,
    "Hipparcos Catalogue": 75363,
    "Henry Draper Catalogue": 136894,
    "Distance in Parsecs": 28.5551,
    "mag": 7.52,
    "Absolute Visual Magnitude": 5.242,
    "Spectral Type": "G8/K0V",
    "Color Index": 0.721,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.964491,
    "y": -19.721622,
    "z": -13.099097,
    "vx": 0.00000146,
    "vy": 0.0000025,
    "vz": -0.00000554,
    "rarad": 4.03188711937322,
    "decrad": -0.476566061499135,
    "pmrarad": -1.52716309375e-8,
    "pmdecrad": -2.18457044e-7,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 75134,
    "lum": 0.69694739635632,
    "ra": 15.400674,
    "dec": -27.305224,
    "pmra": -3.15,
    "pmdec": -45.06,
    "rv": 0,
    "Asset Name": "ASTRID 003709",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Frizzy",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000146,
    "y_1": 0.0000025,
    "z_1": -0.00000554,
    "Distance in Parsecs_1": 28.5551,
    "HYG Star ID_1": 75134,
    "Hipparcos Catalogue_1": 75363,
    "Henry Draper Catalogue_1": 136894
  },
  {
    "Astrid #": "ASTRID 003710",
    "HYG Star ID": 113961,
    "Hipparcos Catalogue": 114322,
    "Henry Draper Catalogue": 218566,
    "Gliese Catalog": "NN 4313",
    "Distance in Parsecs": 28.5551,
    "mag": 8.59,
    "Absolute Visual Magnitude": 6.312,
    "Spectral Type": "K3V",
    "Color Index": 1.012,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 27.833867,
    "y": -6.27534,
    "z": -1.126403,
    "vx": -0.00002204,
    "vy": 0.00009467,
    "vz": -0.00001166,
    "rarad": 6.06143592255971,
    "decrad": -0.039457387191785,
    "pmrarad": 0.00000306474968163194,
    "pmdecrad": -4.66536204e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 113961,
    "lum": 0.260135725665313,
    "ra": 23.15298,
    "dec": -2.260742,
    "pmra": 632.15,
    "pmdec": -96.23,
    "rv": -40.9,
    "Asset Name": "ASTRID 003710",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Round Brush",
    "Outfit": "Pirate",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00002204,
    "y_1": 0.00009467,
    "z_1": -0.00001166,
    "Distance in Parsecs_1": 28.5551,
    "HYG Star ID_1": 113961,
    "Hipparcos Catalogue_1": 114322,
    "Henry Draper Catalogue_1": 218566,
    "Gliese Catalog_1": "NN 4313"
  },
  {
    "Astrid #": "ASTRID 003711",
    "HYG Star ID": 110940,
    "Hipparcos Catalogue": 111288,
    "Distance in Parsecs": 28.5714,
    "mag": 11.01,
    "Absolute Visual Magnitude": 8.73,
    "Color Index": 1.373,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 24.762927,
    "y": -9.917043,
    "z": -10.235948,
    "vx": 0.00000646,
    "vy": 0.00004875,
    "vz": -0.00003162,
    "rarad": 5.90226463970969,
    "decrad": -0.36640194898267,
    "pmrarad": 0.00000166819539342361,
    "pmdecrad": -0.000001185369448,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 110940,
    "lum": 0.0280543363795171,
    "var_min": 11.078,
    "var_max": 10.958,
    "ra": 22.54499,
    "dec": -20.993285,
    "pmra": 344.09,
    "pmdec": -244.5,
    "rv": 0,
    "Asset Name": "ASTRID 003711",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Chuckle",
    "Hair": "Elegant",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000646,
    "y_1": 0.00004875,
    "z_1": -0.00003162,
    "Distance in Parsecs_1": 28.5714,
    "HYG Star ID_1": 110940,
    "Hipparcos Catalogue_1": 111288
  },
  {
    "Astrid #": "ASTRID 003712",
    "HYG Star ID": 118427,
    "Gliese Catalog": "Wo 9212B",
    "Distance in Parsecs": 28.5714,
    "mag": 11.57,
    "Absolute Visual Magnitude": 9.29,
    "Spectral Type": "K7",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.042148,
    "y": 25.530756,
    "z": -12.459965,
    "vx": 0.00002723,
    "vy": -0.00001027,
    "vz": -0.00002769,
    "rarad": 1.6893983519409,
    "decrad": -0.45125940016435,
    "pmrarad": -9.03741181923611e-7,
    "pmdecrad": -0.000001077023287,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 2,
    "comp_primary": 30639,
    "base": "Wo 9212",
    "lum": 0.0167494287602643,
    "ra": 6.453026,
    "dec": -25.855259,
    "pmra": -186.41,
    "pmdec": -222.15,
    "rv": 0,
    "Asset Name": "ASTRID 003712",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Prince",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00002723,
    "y_1": -0.00001027,
    "z_1": -0.00002769,
    "Distance in Parsecs_1": 28.5714,
    "HYG Star ID_1": 118427,
    "Gliese Catalog_1": "Wo 9212B"
  },
  {
    "Astrid #": "ASTRID 003713",
    "HYG Star ID": 116792,
    "Hipparcos Catalogue": 117159,
    "Henry Draper Catalogue": 222935,
    "Distance in Parsecs": 28.5878,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.119,
    "Spectral Type": "K2V",
    "Color Index": 0.859,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.814685,
    "y": -1.608435,
    "z": 14.104405,
    "vx": 0.00005659,
    "vy": 0.0001281,
    "vz": 0.00002679,
    "rarad": 6.21845197935282,
    "decrad": 0.515952014343722,
    "pmrarad": 0.00000460015460795138,
    "pmdecrad": -1.6532146e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 116792,
    "lum": 0.310742031038858,
    "ra": 23.752737,
    "dec": 29.561873,
    "pmra": 948.85,
    "pmdec": -3.41,
    "rv": 53.9,
    "Asset Name": "ASTRID 003713",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Audiohead",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00005659,
    "y_1": 0.0001281,
    "z_1": 0.00002679,
    "Distance in Parsecs_1": 28.5878,
    "HYG Star ID_1": 116792,
    "Hipparcos Catalogue_1": 117159,
    "Henry Draper Catalogue_1": 222935
  },
  {
    "Astrid #": "ASTRID 003714",
    "HYG Star ID": 37941,
    "Hipparcos Catalogue": 38048,
    "Henry Draper Catalogue": 63336,
    "Harvard Revised Catalogue": 3029,
    "Distance in Parsecs": 28.5959,
    "mag": 5.48,
    "Absolute Visual Magnitude": 3.198,
    "Spectral Type": "F5+...",
    "Color Index": 0.478,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.683589,
    "y": 24.907633,
    "z": -6.039515,
    "vx": -3.5e-7,
    "vy": 0.00003266,
    "vz": 0.00000465,
    "rarad": 2.0417967697733,
    "decrad": -0.212802840023563,
    "pmrarad": -5.074544794375e-7,
    "pmdecrad": 3.75197307e-7,
    "flam": 5,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 37941,
    "lum": 4.57930953151743,
    "bf": "5    Pup",
    "ra": 7.799089,
    "dec": -12.192705,
    "pmra": -104.67,
    "pmdec": 77.39,
    "rv": 27,
    "Asset Name": "ASTRID 003714",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Charmer",
    "Outfit": "Spartan",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -3.5e-7,
    "y_1": 0.00003266,
    "z_1": 0.00000465,
    "Distance in Parsecs_1": 28.5959,
    "HYG Star ID_1": 37941,
    "Hipparcos Catalogue_1": 38048,
    "Henry Draper Catalogue_1": 63336,
    "Harvard Revised Catalogue_1": 3029
  },
  {
    "Astrid #": "ASTRID 003715",
    "HYG Star ID": 79272,
    "Hipparcos Catalogue": 79512,
    "Distance in Parsecs": 28.6041,
    "mag": 9.62,
    "Absolute Visual Magnitude": 7.338,
    "Spectral Type": "M0",
    "Color Index": 1.178,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -9.148806,
    "y": -18.248741,
    "z": 20.036898,
    "vx": 0.00000339,
    "vy": 0.00004063,
    "vz": 0.00003855,
    "rarad": 4.2476726018484,
    "decrad": 0.776084478601513,
    "pmrarad": -5.30531610631944e-7,
    "pmdecrad": 0.000001888640173,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 79272,
    "lum": 0.10111137119549,
    "ra": 16.224914,
    "dec": 44.466365,
    "pmra": -109.43,
    "pmdec": 389.56,
    "rv": 0,
    "Asset Name": "ASTRID 003715",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Pompadour",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000339,
    "y_1": 0.00004063,
    "z_1": 0.00003855,
    "Distance in Parsecs_1": 28.6041,
    "HYG Star ID_1": 79272,
    "Hipparcos Catalogue_1": 79512
  },
  {
    "Astrid #": "ASTRID 003716",
    "HYG Star ID": 28563,
    "Hipparcos Catalogue": 28634,
    "Henry Draper Catalogue": 40650,
    "Distance in Parsecs": 28.6123,
    "mag": 6.49,
    "Absolute Visual Magnitude": 4.207,
    "Spectral Type": "F5",
    "Color Index": 0.567,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.230766,
    "y": 19.21456,
    "z": 21.199319,
    "vx": -0.00000436,
    "vy": -0.00000957,
    "vz": 0.00000862,
    "rarad": 1.58280502923518,
    "decrad": 0.834433693354418,
    "pmrarad": 1.56255449243055e-7,
    "pmdecrad": 4.48840505e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 28563,
    "lum": 1.80800655211,
    "ra": 6.04587,
    "dec": 47.809529,
    "pmra": 32.23,
    "pmdec": 92.58,
    "rv": 0,
    "Asset Name": "ASTRID 003716",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Heartbreaker",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00000436,
    "y_1": -0.00000957,
    "z_1": 0.00000862,
    "Distance in Parsecs_1": 28.6123,
    "HYG Star ID_1": 28563,
    "Hipparcos Catalogue_1": 28634,
    "Henry Draper Catalogue_1": 40650
  },
  {
    "Astrid #": "ASTRID 003717",
    "HYG Star ID": 6799,
    "Hipparcos Catalogue": 6813,
    "Henry Draper Catalogue": 8799,
    "Harvard Revised Catalogue": 417,
    "Distance in Parsecs": 28.6205,
    "mag": 4.83,
    "Absolute Visual Magnitude": 2.547,
    "Spectral Type": "F5IV",
    "Color Index": 0.421,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.641784,
    "y": 7.499275,
    "z": 20.380957,
    "vx": -0.00000114,
    "vy": 0.00005293,
    "vz": -0.00000264,
    "rarad": 0.382466605943134,
    "decrad": 0.792496191514743,
    "pmrarad": 0.00000173073635821527,
    "pmdecrad": -5.29998315e-7,
    "bayer": "Ome",
    "flam": 48,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 6799,
    "lum": 8.34065197408662,
    "bf": "48Ome And",
    "ra": 1.460915,
    "dec": 45.406687,
    "pmra": 356.99,
    "pmdec": -109.32,
    "rv": 11,
    "Asset Name": "ASTRID 003717",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Greasy",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00000114,
    "y_1": 0.00005293,
    "z_1": -0.00000264,
    "Distance in Parsecs_1": 28.6205,
    "HYG Star ID_1": 6799,
    "Hipparcos Catalogue_1": 6813,
    "Henry Draper Catalogue_1": 8799,
    "Harvard Revised Catalogue_1": 417
  },
  {
    "Astrid #": "ASTRID 003718",
    "HYG Star ID": 10686,
    "Hipparcos Catalogue": 10712,
    "Distance in Parsecs": 28.6205,
    "mag": 11.62,
    "Absolute Visual Magnitude": 9.337,
    "Spectral Type": "K:...",
    "Color Index": 1.52,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.177573,
    "y": 13.173788,
    "z": -16.667484,
    "vx": -0.00001463,
    "vy": 0.00006086,
    "vz": 0.00003126,
    "rarad": 0.601900936474528,
    "decrad": -0.621630910844182,
    "pmrarad": 0.00000204256851754861,
    "pmdecrad": 0.000001343903522,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 10686,
    "lum": 0.0160398388250841,
    "var_min": 11.706,
    "var_max": 11.536,
    "ra": 2.299092,
    "dec": -35.616828,
    "pmra": 421.31,
    "pmdec": 277.2,
    "rv": 0,
    "Asset Name": "ASTRID 003718",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Serious Slumber",
    "Hair": "Innocent",
    "Outfit": "Tarnished",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00001463,
    "y_1": 0.00006086,
    "z_1": 0.00003126,
    "Distance in Parsecs_1": 28.6205,
    "HYG Star ID_1": 10686,
    "Hipparcos Catalogue_1": 10712
  },
  {
    "Astrid #": "ASTRID 003719",
    "HYG Star ID": 40591,
    "Hipparcos Catalogue": 40706,
    "Henry Draper Catalogue": 70060,
    "Harvard Revised Catalogue": 3270,
    "Gliese Catalog": "GJ 1109",
    "Distance in Parsecs": 28.6287,
    "mag": 4.44,
    "Absolute Visual Magnitude": 2.156,
    "Spectral Type": "A4m...",
    "Color Index": 0.222,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -13.053888,
    "y": 18.895299,
    "z": -17.092944,
    "vx": 0.00000559,
    "vy": 0.00001908,
    "vz": 0.00000809,
    "rarad": 2.1753586147934,
    "decrad": -0.639825280579156,
    "pmrarad": -5.39452182354166e-7,
    "pmdecrad": 4.87819525e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 40591,
    "lum": 11.9563879998812,
    "ra": 8.309258,
    "dec": -36.659288,
    "pmra": -111.27,
    "pmdec": 100.62,
    "rv": 5.1,
    "Asset Name": "ASTRID 003719",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Pixie",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000559,
    "y_1": 0.00001908,
    "z_1": 0.00000809,
    "Distance in Parsecs_1": 28.6287,
    "HYG Star ID_1": 40591,
    "Hipparcos Catalogue_1": 40706,
    "Henry Draper Catalogue_1": 70060,
    "Harvard Revised Catalogue_1": 3270,
    "Gliese Catalog_1": "GJ 1109"
  },
  {
    "Astrid #": "ASTRID 003720",
    "HYG Star ID": 52996,
    "Hipparcos Catalogue": 53150,
    "Distance in Parsecs": 28.6369,
    "mag": 11.97,
    "Absolute Visual Magnitude": 9.685,
    "Color Index": 1.472,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -25.281292,
    "y": 7.676319,
    "z": -11.045471,
    "vx": 8.6e-7,
    "vy": -0.00001873,
    "vz": -0.00001499,
    "rarad": 2.84680264521838,
    "decrad": -0.395974671280052,
    "pmrarad": 6.17167815347222e-7,
    "pmdecrad": -5.67232006e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 52996,
    "lum": 0.0116412602941049,
    "var_min": 12.116,
    "var_max": 11.736,
    "ra": 10.873985,
    "dec": -22.687677,
    "pmra": 127.3,
    "pmdec": -117,
    "rv": 0,
    "Asset Name": "ASTRID 003720",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Anxious Laugh",
    "Hair": "Slick",
    "Outfit": "Super",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 8.6e-7,
    "y_1": -0.00001873,
    "z_1": -0.00001499,
    "Distance in Parsecs_1": 28.6369,
    "HYG Star ID_1": 52996,
    "Hipparcos Catalogue_1": 53150
  },
  {
    "Astrid #": "ASTRID 003721",
    "HYG Star ID": 101253,
    "Hipparcos Catalogue": 101579,
    "Henry Draper Catalogue": 196124,
    "Distance in Parsecs": 28.6369,
    "mag": 8.91,
    "Absolute Visual Magnitude": 6.625,
    "Spectral Type": "K2",
    "Color Index": 0.96,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.84243,
    "y": -22.189176,
    "z": 3.056465,
    "vx": 0.00002176,
    "vy": 0.00005952,
    "vz": -0.00003507,
    "rarad": 5.38962795505223,
    "decrad": 0.106936572809467,
    "pmrarad": 0.00000189474882634722,
    "pmdecrad": -0.000001095678918,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 101253,
    "lum": 0.194984459975804,
    "ra": 20.586862,
    "dec": 6.127014,
    "pmra": 390.82,
    "pmdec": -226,
    "rv": -35.5,
    "Asset Name": "ASTRID 003721",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Greasy",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00002176,
    "y_1": 0.00005952,
    "z_1": -0.00003507,
    "Distance in Parsecs_1": 28.6369,
    "HYG Star ID_1": 101253,
    "Hipparcos Catalogue_1": 101579,
    "Henry Draper Catalogue_1": 196124
  },
  {
    "Astrid #": "ASTRID 003722",
    "HYG Star ID": 10277,
    "Hipparcos Catalogue": 10301,
    "Henry Draper Catalogue": 13808,
    "Distance in Parsecs": 28.6451,
    "mag": 8.36,
    "Absolute Visual Magnitude": 6.075,
    "Spectral Type": "K2V",
    "Color Index": 0.87,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.178701,
    "y": 9.270811,
    "z": -23.098881,
    "vx": 0.00001111,
    "vy": -0.00001061,
    "vz": 0.00000256,
    "rarad": 0.579083451388653,
    "decrad": -0.93800784573129,
    "pmrarad": -5.22241296694444e-7,
    "pmdecrad": 1.51116424e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 10277,
    "lum": 0.323593656929628,
    "ra": 2.211936,
    "dec": -53.743891,
    "pmra": -107.72,
    "pmdec": 31.17,
    "rv": 0,
    "Asset Name": "ASTRID 003722",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Upset",
    "Hair": "Afro",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00001111,
    "y_1": -0.00001061,
    "z_1": 0.00000256,
    "Distance in Parsecs_1": 28.6451,
    "HYG Star ID_1": 10277,
    "Hipparcos Catalogue_1": 10301,
    "Henry Draper Catalogue_1": 13808
  },
  {
    "Astrid #": "ASTRID 003723",
    "HYG Star ID": 15050,
    "Hipparcos Catalogue": 15087,
    "Gliese Catalog": "Wo 9111",
    "Distance in Parsecs": 28.6451,
    "mag": 10.3,
    "Absolute Visual Magnitude": 8.015,
    "Spectral Type": "K5:",
    "Color Index": 1.589,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.56202,
    "y": 13.133718,
    "z": 22.679037,
    "vx": 0.0000345,
    "vy": -0.00001671,
    "vz": -0.00007896,
    "rarad": 0.848962106234221,
    "decrad": 0.913649631478243,
    "pmrarad": -0.00000128945894617361,
    "pmdecrad": -0.000001967373915,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 15050,
    "lum": 0.0542000890401623,
    "var_min": 10.353,
    "var_max": 10.243,
    "ra": 3.242796,
    "dec": 52.348268,
    "pmra": -265.97,
    "pmdec": -405.8,
    "rv": -55,
    "Asset Name": "ASTRID 003723",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Elegant",
    "Outfit": "Toga",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.0000345,
    "y_1": -0.00001671,
    "z_1": -0.00007896,
    "Distance in Parsecs_1": 28.6451,
    "HYG Star ID_1": 15050,
    "Hipparcos Catalogue_1": 15087,
    "Gliese Catalog_1": "Wo 9111"
  },
  {
    "Astrid #": "ASTRID 003724",
    "HYG Star ID": 79535,
    "Hipparcos Catalogue": 79777,
    "Henry Draper Catalogue": 146124,
    "Distance in Parsecs": 28.6533,
    "mag": 7.63,
    "Absolute Visual Magnitude": 5.344,
    "Spectral Type": "G6V",
    "Color Index": 0.76,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.033775,
    "y": -16.634392,
    "z": -21.903586,
    "vx": -0.00000104,
    "vy": 0.00000435,
    "vz": -0.00000292,
    "rarad": 4.26246510615285,
    "decrad": -0.870164485052077,
    "pmrarad": -9.8611102625e-8,
    "pmdecrad": -1.58291666e-7,
    "con": "Nor",
    "Full Constellation Name Formula 1": "Norma",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Norma",
    "comp": 1,
    "comp_primary": 79535,
    "lum": 0.634453795775713,
    "ra": 16.281417,
    "dec": -49.856752,
    "pmra": -20.34,
    "pmdec": -32.65,
    "rv": 0,
    "Asset Name": "ASTRID 003724",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Nervous Grin",
    "Hair": "High Fade",
    "Outfit": "Super",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Norma",
    "x_1": -0.00000104,
    "y_1": 0.00000435,
    "z_1": -0.00000292,
    "Distance in Parsecs_1": 28.6533,
    "HYG Star ID_1": 79535,
    "Hipparcos Catalogue_1": 79777,
    "Henry Draper Catalogue_1": 146124
  },
  {
    "Astrid #": "ASTRID 003725",
    "HYG Star ID": 101603,
    "Hipparcos Catalogue": 101932,
    "Henry Draper Catalogue": 196794,
    "Distance in Parsecs": 28.6533,
    "mag": 8.52,
    "Absolute Visual Magnitude": 6.234,
    "Spectral Type": "K2V",
    "Color Index": 0.91,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.073923,
    "y": -21.660785,
    "z": 5.012804,
    "vx": -6.2e-7,
    "vy": 0.0000695,
    "vz": -0.0000067,
    "rarad": 5.40776067133737,
    "decrad": 0.175854718332385,
    "pmrarad": 0.00000153729569967361,
    "pmdecrad": 9.7980844e-8,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 101603,
    "lum": 0.279511705399184,
    "ra": 20.656124,
    "dec": 10.075733,
    "pmra": 317.09,
    "pmdec": 20.21,
    "rv": -52.9,
    "Asset Name": "ASTRID 003725",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Good Boy",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": -6.2e-7,
    "y_1": 0.0000695,
    "z_1": -0.0000067,
    "Distance in Parsecs_1": 28.6533,
    "HYG Star ID_1": 101603,
    "Hipparcos Catalogue_1": 101932,
    "Henry Draper Catalogue_1": 196794
  },
  {
    "Astrid #": "ASTRID 003726",
    "HYG Star ID": 118862,
    "Gliese Catalog": "Gl 496B",
    "Distance in Parsecs": 28.6615,
    "mag": 6.4,
    "Absolute Visual Magnitude": 4.114,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -25.801368,
    "y": -7.369314,
    "z": -10.077017,
    "vx": -0.00002112,
    "vy": -0.00002616,
    "vz": -0.00000826,
    "rarad": 3.41980022269691,
    "decrad": -0.3592472785753,
    "pmrarad": 6.75393938381944e-7,
    "pmdecrad": 6.7408494e-8,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 2,
    "comp_primary": 63535,
    "base": "Gl 496",
    "lum": 1.96969961489992,
    "ra": 13.062675,
    "dec": -20.583353,
    "pmra": 139.31,
    "pmdec": 13.9,
    "rv": 28,
    "Asset Name": "ASTRID 003726",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Charmer",
    "Outfit": "Sailor",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002112,
    "y_1": -0.00002616,
    "z_1": -0.00000826,
    "Distance in Parsecs_1": 28.6615,
    "HYG Star ID_1": 118862,
    "Gliese Catalog_1": "Gl 496B"
  },
  {
    "Astrid #": "ASTRID 003727",
    "HYG Star ID": 21808,
    "Hipparcos Catalogue": 21861,
    "Henry Draper Catalogue": 29992,
    "Harvard Revised Catalogue": 1503,
    "Gliese Catalog": "Gl 176.1",
    "Distance in Parsecs": 28.6697,
    "mag": 5.04,
    "Absolute Visual Magnitude": 2.753,
    "Spectral Type": "F3V",
    "Color Index": 0.391,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.623137,
    "y": 21.544405,
    "z": -17.311611,
    "vx": 0.00000655,
    "vy": 0.00003805,
    "vz": 0.00000485,
    "rarad": 1.23070973055414,
    "decrad": -0.648290274770007,
    "pmrarad": 2.27377616180555e-7,
    "pmdecrad": 9.36369142e-7,
    "bayer": "Bet",
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 21808,
    "lum": 6.89922009844047,
    "bf": "Bet Cae",
    "ra": 4.700965,
    "dec": -37.144297,
    "pmra": 46.9,
    "pmdec": 193.14,
    "rv": 26.8,
    "Asset Name": "ASTRID 003727",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Horns",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": 0.00000655,
    "y_1": 0.00003805,
    "z_1": 0.00000485,
    "Distance in Parsecs_1": 28.6697,
    "HYG Star ID_1": 21808,
    "Hipparcos Catalogue_1": 21861,
    "Henry Draper Catalogue_1": 29992,
    "Harvard Revised Catalogue_1": 1503,
    "Gliese Catalog_1": "Gl 176.1"
  },
  {
    "Astrid #": "ASTRID 003728",
    "HYG Star ID": 60419,
    "Hipparcos Catalogue": 60605,
    "Henry Draper Catalogue": 108153,
    "Distance in Parsecs": 28.6697,
    "mag": 8.73,
    "Absolute Visual Magnitude": 6.443,
    "Spectral Type": "K0V",
    "Color Index": 0.897,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.200731,
    "y": -2.683376,
    "z": 15.134877,
    "vx": -0.00000381,
    "vy": 0.00004908,
    "vz": -0.00004,
    "rarad": 3.25202415532045,
    "decrad": 0.556136158725593,
    "pmrarad": -0.00000171619194779861,
    "pmdecrad": -0.000001155117075,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 60419,
    "lum": 0.230568513694728,
    "ra": 12.421817,
    "dec": 31.864255,
    "pmra": -353.99,
    "pmdec": -238.26,
    "rv": -22,
    "Asset Name": "ASTRID 003728",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oh Snap",
    "Hair": "Side Part",
    "Outfit": "School Uniform",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00000381,
    "y_1": 0.00004908,
    "z_1": -0.00004,
    "Distance in Parsecs_1": 28.6697,
    "HYG Star ID_1": 60419,
    "Hipparcos Catalogue_1": 60605,
    "Henry Draper Catalogue_1": 108153
  },
  {
    "Astrid #": "ASTRID 003729",
    "HYG Star ID": 113337,
    "Hipparcos Catalogue": 113697,
    "Henry Draper Catalogue": 217486,
    "Distance in Parsecs": 28.6779,
    "mag": 8.93,
    "Absolute Visual Magnitude": 6.642,
    "Spectral Type": "K5V",
    "Color Index": 1.024,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 19.628127,
    "y": -5.109959,
    "z": -20.274292,
    "vx": 0.00000475,
    "vy": 0.00001376,
    "vz": 0.00000113,
    "rarad": 6.02849830334875,
    "decrad": -0.785198706156236,
    "pmrarad": 5.06097001131944e-7,
    "pmdecrad": 5.5753573e-8,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 113337,
    "lum": 0.191955252376137,
    "ra": 23.027167,
    "dec": -44.988572,
    "pmra": 104.39,
    "pmdec": 11.5,
    "rv": 0,
    "Asset Name": "ASTRID 003729",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Shocked",
    "Hair": "Honest Lad",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00000475,
    "y_1": 0.00001376,
    "z_1": 0.00000113,
    "Distance in Parsecs_1": 28.6779,
    "HYG Star ID_1": 113337,
    "Hipparcos Catalogue_1": 113697,
    "Henry Draper Catalogue_1": 217486
  },
  {
    "Astrid #": "ASTRID 003730",
    "HYG Star ID": 11125,
    "Hipparcos Catalogue": 11152,
    "Distance in Parsecs": 28.6862,
    "mag": 11.09,
    "Absolute Visual Magnitude": 8.802,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 21.44207,
    "y": 15.499245,
    "z": 11.086439,
    "vx": -0.00000261,
    "vy": 0.00001402,
    "vz": -0.00001455,
    "rarad": 0.625892389141814,
    "decrad": 0.396804249587835,
    "pmrarad": 4.49616207347222e-7,
    "pmdecrad": -5.49875676e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 11125,
    "lum": 0.026254273189937,
    "var_min": 11.15,
    "var_max": 10.98,
    "ra": 2.390733,
    "dec": 22.735209,
    "pmra": 92.74,
    "pmdec": -113.42,
    "rv": 0,
    "Asset Name": "ASTRID 003730",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Audiohead",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000261,
    "y_1": 0.00001402,
    "z_1": -0.00001455,
    "Distance in Parsecs_1": 28.6862,
    "HYG Star ID_1": 11125,
    "Hipparcos Catalogue_1": 11152
  },
  {
    "Astrid #": "ASTRID 003731",
    "HYG Star ID": 110271,
    "Hipparcos Catalogue": 110618,
    "Henry Draper Catalogue": 211998,
    "Harvard Revised Catalogue": 8515,
    "Gliese Catalog": "Gl 855.1A",
    "Distance in Parsecs": 28.7026,
    "mag": 5.28,
    "Absolute Visual Magnitude": 2.99,
    "Spectral Type": "A3V: + F9V",
    "Color Index": 0.66,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 8.001105,
    "y": -3.536637,
    "z": -27.337277,
    "vx": -0.00000122,
    "vy": 0.0001987,
    "vz": -0.00005314,
    "rarad": 5.86686249748809,
    "decrad": -1.26109484597735,
    "pmrarad": 0.00000631445578239583,
    "pmdecrad": -0.000003268225983,
    "bayer": "Nu",
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 110271,
    "base": "Gl 855.1",
    "lum": 5.5462571295791,
    "bf": "Nu  Ind",
    "ra": 22.409764,
    "dec": -72.255412,
    "pmra": 1302.45,
    "pmdec": -674.12,
    "rv": 25.2,
    "Asset Name": "ASTRID 003731",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Braided Ponytail",
    "Outfit": "Painter",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00000122,
    "y_1": 0.0001987,
    "z_1": -0.00005314,
    "Distance in Parsecs_1": 28.7026,
    "HYG Star ID_1": 110271,
    "Hipparcos Catalogue_1": 110618,
    "Henry Draper Catalogue_1": 211998,
    "Harvard Revised Catalogue_1": 8515,
    "Gliese Catalog_1": "Gl 855.1A"
  },
  {
    "Astrid #": "ASTRID 003732",
    "HYG Star ID": 3869,
    "Hipparcos Catalogue": 3879,
    "Henry Draper Catalogue": 4838,
    "Distance in Parsecs": 28.7109,
    "mag": 9.5,
    "Absolute Visual Magnitude": 7.21,
    "Spectral Type": "K5V",
    "Color Index": 1.157,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.243199,
    "y": 3.586553,
    "z": -23.401087,
    "vx": 0.00000345,
    "vy": 0.00001335,
    "vz": 0.00000444,
    "rarad": 0.217313708488547,
    "decrad": -0.952831618167833,
    "pmrarad": 4.28284405402777e-7,
    "pmdecrad": 2.67180819e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 3869,
    "lum": 0.113762728582343,
    "ra": 0.830077,
    "dec": -54.59323,
    "pmra": 88.34,
    "pmdec": 55.11,
    "rv": 0,
    "Asset Name": "ASTRID 003732",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Bad Dreams",
    "Hair": "Side Part",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": 0.00000345,
    "y_1": 0.00001335,
    "z_1": 0.00000444,
    "Distance in Parsecs_1": 28.7109,
    "HYG Star ID_1": 3869,
    "Hipparcos Catalogue_1": 3879,
    "Henry Draper Catalogue_1": 4838
  },
  {
    "Astrid #": "ASTRID 003733",
    "HYG Star ID": 73675,
    "Hipparcos Catalogue": 73903,
    "Distance in Parsecs": 28.7522,
    "mag": 12.79,
    "Absolute Visual Magnitude": 10.497,
    "Spectral Type": "K5",
    "Color Index": 1.613,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.700921,
    "y": -16.579776,
    "z": -17.472296,
    "vx": 0.00002204,
    "vy": 0.00010537,
    "vz": -0.0001198,
    "rarad": 3.9542139388394,
    "decrad": -0.653143043643654,
    "pmrarad": -0.00000196344692488194,
    "pmdecrad": -0.000005246702132,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 73675,
    "lum": 0.00551061411139065,
    "var_min": 12.991,
    "var_max": 12.531,
    "ra": 15.103985,
    "dec": -37.42234,
    "pmra": -404.99,
    "pmdec": -1082.21,
    "rv": 0,
    "Asset Name": "ASTRID 003733",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Arrogant",
    "Hair": "Ram Horns",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002204,
    "y_1": 0.00010537,
    "z_1": -0.0001198,
    "Distance in Parsecs_1": 28.7522,
    "HYG Star ID_1": 73675,
    "Hipparcos Catalogue_1": 73903
  },
  {
    "Astrid #": "ASTRID 003734",
    "HYG Star ID": 91983,
    "Hipparcos Catalogue": 92270,
    "Henry Draper Catalogue": 174160,
    "Harvard Revised Catalogue": 7079,
    "Distance in Parsecs": 28.7522,
    "mag": 6.19,
    "Absolute Visual Magnitude": 3.897,
    "Spectral Type": "F8V",
    "Color Index": 0.492,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.512254,
    "y": -25.781855,
    "z": 11.471699,
    "vx": 0.00000349,
    "vy": 5.5e-7,
    "vz": -9.5e-7,
    "rarad": 4.92302122625628,
    "decrad": 0.410409785384288,
    "pmrarad": 1.22657861180555e-7,
    "pmdecrad": -3.2870367e-8,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 91983,
    "lum": 2.40547030498809,
    "ra": 18.804556,
    "dec": 23.514749,
    "pmra": 25.3,
    "pmdec": -6.78,
    "rv": -0.2,
    "Asset Name": "ASTRID 003734",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Glad",
    "Hair": "High Fade",
    "Outfit": "Tats",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000349,
    "y_1": 5.5e-7,
    "z_1": -9.5e-7,
    "Distance in Parsecs_1": 28.7522,
    "HYG Star ID_1": 91983,
    "Hipparcos Catalogue_1": 92270,
    "Henry Draper Catalogue_1": 174160,
    "Harvard Revised Catalogue_1": 7079
  },
  {
    "Astrid #": "ASTRID 003735",
    "HYG Star ID": 108145,
    "Hipparcos Catalogue": 108490,
    "Henry Draper Catalogue": 208906,
    "Distance in Parsecs": 28.7522,
    "mag": 6.95,
    "Absolute Visual Magnitude": 4.657,
    "Spectral Type": "F8V-VI",
    "Color Index": 0.501,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 21.532636,
    "y": -12.597716,
    "z": 14.294632,
    "vx": 0.00000408,
    "vy": -0.0000608,
    "vz": -0.00004245,
    "rarad": 5.75383166455618,
    "decrad": 0.520328547691588,
    "pmrarad": -0.00000175376500804166,
    "pmdecrad": -0.000001872835247,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 108145,
    "lum": 1.19453808293388,
    "ra": 21.978018,
    "dec": 29.81263,
    "pmra": -361.74,
    "pmdec": -386.3,
    "rv": 8.4,
    "Asset Name": "ASTRID 003735",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Smoulder",
    "Hair": "Braided Ponytail",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000408,
    "y_1": -0.0000608,
    "z_1": -0.00004245,
    "Distance in Parsecs_1": 28.7522,
    "HYG Star ID_1": 108145,
    "Hipparcos Catalogue_1": 108490,
    "Henry Draper Catalogue_1": 208906
  },
  {
    "Astrid #": "ASTRID 003736",
    "HYG Star ID": 3637,
    "Hipparcos Catalogue": 3645,
    "Henry Draper Catalogue": 4449,
    "Distance in Parsecs": 28.7604,
    "mag": 7.57,
    "Absolute Visual Magnitude": 5.276,
    "Spectral Type": "G5",
    "Color Index": 0.822,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 28.082199,
    "y": 5.799658,
    "z": -2.220032,
    "vx": 0.00002302,
    "vy": 0.00001028,
    "vz": -0.00003969,
    "rarad": 0.203661019942445,
    "decrad": -0.0772668055105912,
    "pmrarad": 1.88107708055555e-7,
    "pmdecrad": -0.000001309772639,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3637,
    "lum": 0.6754605666335,
    "ra": 0.777928,
    "dec": -4.427062,
    "pmra": 38.8,
    "pmdec": -270.16,
    "rv": 27,
    "Asset Name": "ASTRID 003736",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Warm Smile",
    "Hair": "Middle Part",
    "Outfit": "Sharky",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.00002302,
    "y_1": 0.00001028,
    "z_1": -0.00003969,
    "Distance in Parsecs_1": 28.7604,
    "HYG Star ID_1": 3637,
    "Hipparcos Catalogue_1": 3645,
    "Henry Draper Catalogue_1": 4449
  },
  {
    "Astrid #": "ASTRID 003737",
    "HYG Star ID": 50684,
    "Hipparcos Catalogue": 50829,
    "Henry Draper Catalogue": 89906,
    "Gliese Catalog": "NN 3596",
    "Distance in Parsecs": 28.7687,
    "mag": 7.26,
    "Absolute Visual Magnitude": 4.965,
    "Spectral Type": "G5",
    "Color Index": 0.6,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -25.281884,
    "y": 11.4245,
    "z": 7.612833,
    "vx": -0.00000529,
    "vy": 0.00004251,
    "vz": -0.00000637,
    "rarad": 2.71717282713161,
    "decrad": 0.267810357232792,
    "pmrarad": -0.00000127084210084027,
    "pmdecrad": -4.18830538e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 50684,
    "lum": 0.899497581530035,
    "ra": 10.378836,
    "dec": 15.344403,
    "pmra": -262.13,
    "pmdec": -86.39,
    "rv": 19.4,
    "Asset Name": "ASTRID 003737",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Side Part",
    "Outfit": "Samurai",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": -0.00000529,
    "y_1": 0.00004251,
    "z_1": -0.00000637,
    "Distance in Parsecs_1": 28.7687,
    "HYG Star ID_1": 50684,
    "Hipparcos Catalogue_1": 50829,
    "Henry Draper Catalogue_1": 89906,
    "Gliese Catalog_1": "NN 3596"
  },
  {
    "Astrid #": "ASTRID 003738",
    "HYG Star ID": 93725,
    "Hipparcos Catalogue": 94020,
    "Henry Draper Catalogue": 178076,
    "Gliese Catalog": "NN 4097",
    "Distance in Parsecs": 28.7687,
    "mag": 7.75,
    "Absolute Visual Magnitude": 5.455,
    "Spectral Type": "K0V",
    "Color Index": 0.787,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.266244,
    "y": -23.572139,
    "z": -14.805203,
    "vx": 0.0000044,
    "vy": 0.00000571,
    "vz": -0.00000693,
    "rarad": 5.01140211187136,
    "decrad": -0.540574725732578,
    "pmrarad": 2.04785298666666e-7,
    "pmdecrad": -2.80998009e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 93725,
    "lum": 0.572796030985829,
    "ra": 19.142146,
    "dec": -30.97265,
    "pmra": 42.24,
    "pmdec": -57.96,
    "rv": 0,
    "Asset Name": "ASTRID 003738",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Grecian",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.0000044,
    "y_1": 0.00000571,
    "z_1": -0.00000693,
    "Distance in Parsecs_1": 28.7687,
    "HYG Star ID_1": 93725,
    "Hipparcos Catalogue_1": 94020,
    "Henry Draper Catalogue_1": 178076,
    "Gliese Catalog_1": "NN 4097"
  },
  {
    "Astrid #": "ASTRID 003739",
    "HYG Star ID": 2784,
    "Hipparcos Catalogue": 2790,
    "Henry Draper Catalogue": 3277,
    "Distance in Parsecs": 28.7853,
    "mag": 7.45,
    "Absolute Visual Magnitude": 5.154,
    "Spectral Type": "G8V",
    "Color Index": 0.726,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 21.866489,
    "y": 3.421359,
    "z": -18.405011,
    "vx": -0.0000171,
    "vy": 0.00001372,
    "vz": -0.00001776,
    "rarad": 0.155205968100977,
    "decrad": -0.693703665677587,
    "pmrarad": 5.62868683125e-7,
    "pmdecrad": -8.0270601e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 2784,
    "lum": 0.755788013680451,
    "ra": 0.592843,
    "dec": -39.746292,
    "pmra": 116.1,
    "pmdec": -165.57,
    "rv": 0,
    "Asset Name": "ASTRID 003739",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Sweet Nap",
    "Hair": "Pompadour",
    "Outfit": "Sailor",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.0000171,
    "y_1": 0.00001372,
    "z_1": -0.00001776,
    "Distance in Parsecs_1": 28.7853,
    "HYG Star ID_1": 2784,
    "Hipparcos Catalogue_1": 2790,
    "Henry Draper Catalogue_1": 3277
  },
  {
    "Astrid #": "ASTRID 003740",
    "HYG Star ID": 9582,
    "Hipparcos Catalogue": 9603,
    "Distance in Parsecs": 28.7853,
    "mag": 11.16,
    "Absolute Visual Magnitude": 8.864,
    "Spectral Type": "K7",
    "Color Index": 1.427,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.615133,
    "y": 14.717845,
    "z": -2.464503,
    "vx": -0.00000903,
    "vy": 0.00001073,
    "vz": -0.00002612,
    "rarad": 0.538887629606959,
    "decrad": -0.08572164551871,
    "pmrarad": 4.80983652479166e-7,
    "pmdecrad": -9.10916424e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 9582,
    "lum": 0.0247970489879869,
    "var_min": 11.243,
    "var_max": 11.103,
    "ra": 2.058399,
    "dec": -4.911489,
    "pmra": 99.21,
    "pmdec": -187.89,
    "rv": 0,
    "Asset Name": "ASTRID 003740",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Shinobi",
    "Outfit": "Skater",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000903,
    "y_1": 0.00001073,
    "z_1": -0.00002612,
    "Distance in Parsecs_1": 28.7853,
    "HYG Star ID_1": 9582,
    "Hipparcos Catalogue_1": 9603
  },
  {
    "Astrid #": "ASTRID 003741",
    "HYG Star ID": 29135,
    "Hipparcos Catalogue": 29208,
    "Henry Draper Catalogue": 42182,
    "Distance in Parsecs": 28.7936,
    "mag": 8.45,
    "Absolute Visual Magnitude": 6.154,
    "Spectral Type": "G0",
    "Color Index": 0.895,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.199673,
    "y": 28.627654,
    "z": 2.84422,
    "vx": -0.0000084,
    "vy": -9.9e-7,
    "vz": 0.00000638,
    "rarad": 1.6126778742403,
    "decrad": 0.0989409369813675,
    "pmrarad": 2.92827463055555e-7,
    "pmdecrad": 2.22626442e-7,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 29135,
    "lum": 0.300884627664572,
    "ra": 6.159976,
    "dec": 5.668898,
    "pmra": 60.4,
    "pmdec": 45.92,
    "rv": 0,
    "Asset Name": "ASTRID 003741",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Star of Culture",
    "Hair": "Heartbreaker",
    "Outfit": "Teen",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.0000084,
    "y_1": -9.9e-7,
    "z_1": 0.00000638,
    "Distance in Parsecs_1": 28.7936,
    "HYG Star ID_1": 29135,
    "Hipparcos Catalogue_1": 29208,
    "Henry Draper Catalogue_1": 42182
  },
  {
    "Astrid #": "ASTRID 003742",
    "HYG Star ID": 73817,
    "Hipparcos Catalogue": 74045,
    "Henry Draper Catalogue": 135363,
    "Distance in Parsecs": 28.7936,
    "mag": 8.72,
    "Absolute Visual Magnitude": 6.424,
    "Spectral Type": "G5",
    "Color Index": 0.952,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.685242,
    "y": -5.02157,
    "z": 27.962533,
    "vx": 0.00000237,
    "vy": 0.00002952,
    "vz": 0.00000464,
    "rarad": 3.96164346691674,
    "decrad": 1.32995389275707,
    "pmrarad": -6.39323800548611e-7,
    "pmdecrad": 8.20886523e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 73817,
    "lum": 0.234638892446412,
    "ra": 15.132363,
    "dec": 76.200745,
    "pmra": -131.87,
    "pmdec": 169.32,
    "rv": -1,
    "Asset Name": "ASTRID 003742",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Curly",
    "Outfit": "Super",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00000237,
    "y_1": 0.00002952,
    "z_1": 0.00000464,
    "Distance in Parsecs_1": 28.7936,
    "HYG Star ID_1": 73817,
    "Hipparcos Catalogue_1": 74045,
    "Henry Draper Catalogue_1": 135363
  },
  {
    "Astrid #": "ASTRID 003743",
    "HYG Star ID": 114625,
    "Hipparcos Catalogue": 114986,
    "Henry Draper Catalogue": 219509,
    "Gliese Catalog": "NN 4321A",
    "Distance in Parsecs": 28.7936,
    "mag": 8.71,
    "Absolute Visual Magnitude": 6.414,
    "Spectral Type": "K5v...",
    "Color Index": 1.049,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.09318,
    "y": -2.088726,
    "z": -26.489336,
    "vx": -0.00001665,
    "vy": 0.0000549,
    "vz": -0.00008668,
    "rarad": 6.09705115255684,
    "decrad": -1.16796227939302,
    "pmrarad": 0.00000176680649604861,
    "pmdecrad": -0.000002027587774,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 114625,
    "base": "NN 4321",
    "lum": 0.236809979387379,
    "ra": 23.28902,
    "dec": -66.919309,
    "pmra": 364.43,
    "pmdec": -418.22,
    "rv": 67.8,
    "Asset Name": "ASTRID 003743",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Cute Neko",
    "Hair": "Innocent",
    "Outfit": "Turtle School",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00001665,
    "y_1": 0.0000549,
    "z_1": -0.00008668,
    "Distance in Parsecs_1": 28.7936,
    "HYG Star ID_1": 114625,
    "Hipparcos Catalogue_1": 114986,
    "Henry Draper Catalogue_1": 219509,
    "Gliese Catalog_1": "NN 4321A"
  },
  {
    "Astrid #": "ASTRID 003744",
    "HYG Star ID": 95004,
    "Hipparcos Catalogue": 95299,
    "Henry Draper Catalogue": 182085,
    "Distance in Parsecs": 28.8018,
    "mag": 9.66,
    "Absolute Visual Magnitude": 7.363,
    "Spectral Type": "K5V",
    "Color Index": 1.094,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.16892,
    "y": -26.743716,
    "z": -3.303092,
    "vx": -0.0000239,
    "vy": -0.00000549,
    "vz": -0.00002912,
    "rarad": 5.07574213425698,
    "decrad": -0.114936397178743,
    "pmrarad": -8.43284915958333e-7,
    "pmdecrad": -0.000001017963285,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 95004,
    "lum": 0.0988097953941127,
    "ra": 19.387907,
    "dec": -6.58537,
    "pmra": -173.94,
    "pmdec": -209.97,
    "rv": 0,
    "Asset Name": "ASTRID 003744",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Sad",
    "Hair": "Heartbreaker",
    "Outfit": "Rider",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.0000239,
    "y_1": -0.00000549,
    "z_1": -0.00002912,
    "Distance in Parsecs_1": 28.8018,
    "HYG Star ID_1": 95004,
    "Hipparcos Catalogue_1": 95299,
    "Henry Draper Catalogue_1": 182085
  },
  {
    "Astrid #": "ASTRID 003745",
    "HYG Star ID": 71893,
    "Hipparcos Catalogue": 72119,
    "Henry Draper Catalogue": 129642,
    "Distance in Parsecs": 28.8101,
    "mag": 8.4,
    "Absolute Visual Magnitude": 6.102,
    "Spectral Type": "K3V",
    "Color Index": 0.936,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -13.938695,
    "y": -12.241387,
    "z": -22.042702,
    "vx": -0.00002735,
    "vy": 0.00009907,
    "vz": -0.00003158,
    "rarad": 3.86226492295595,
    "decrad": -0.871203423323856,
    "pmrarad": -0.00000321029074853472,
    "pmdecrad": -0.000001896494155,
    "con": "Lup",
    "Full Constellation Name Formula 1": "Lupus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lupus",
    "comp": 1,
    "comp_primary": 71893,
    "lum": 0.315645789129906,
    "ra": 14.752765,
    "dec": -49.916279,
    "pmra": -662.17,
    "pmdec": -391.18,
    "rv": -4.6,
    "Asset Name": "ASTRID 003745",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Spiky",
    "Outfit": "River Spirit",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lupus",
    "x_1": -0.00002735,
    "y_1": 0.00009907,
    "z_1": -0.00003158,
    "Distance in Parsecs_1": 28.8101,
    "HYG Star ID_1": 71893,
    "Hipparcos Catalogue_1": 72119,
    "Henry Draper Catalogue_1": 129642
  },
  {
    "Astrid #": "ASTRID 003746",
    "HYG Star ID": 116780,
    "Hipparcos Catalogue": 117147,
    "Henry Draper Catalogue": 222898,
    "Distance in Parsecs": 28.8101,
    "mag": 8.99,
    "Absolute Visual Magnitude": 6.692,
    "Spectral Type": "K3V",
    "Color Index": 0.997,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.625932,
    "y": -0.960096,
    "z": -24.802867,
    "vx": -0.00000609,
    "vy": 0.00005666,
    "vz": -0.00000578,
    "rarad": 6.21761948235172,
    "decrad": -1.03705316166122,
    "pmrarad": 0.00000194861162625694,
    "pmdecrad": -3.94589854e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 116780,
    "lum": 0.183315842867085,
    "ra": 23.749557,
    "dec": -59.418769,
    "pmra": 401.93,
    "pmdec": -81.39,
    "rv": 0,
    "Asset Name": "ASTRID 003746",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Big Laugh",
    "Hair": "Mohawk",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000609,
    "y_1": 0.00005666,
    "z_1": -0.00000578,
    "Distance in Parsecs_1": 28.8101,
    "HYG Star ID_1": 116780,
    "Hipparcos Catalogue_1": 117147,
    "Henry Draper Catalogue_1": 222898
  },
  {
    "Astrid #": "ASTRID 003747",
    "HYG Star ID": 44773,
    "Hipparcos Catalogue": 44901,
    "Henry Draper Catalogue": 78209,
    "Harvard Revised Catalogue": 3619,
    "Gliese Catalog": "NN 3534",
    "Distance in Parsecs": 28.8184,
    "mag": 4.46,
    "Absolute Visual Magnitude": 2.162,
    "Spectral Type": "Am",
    "Color Index": 0.288,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -13.136539,
    "y": 12.157018,
    "z": 22.586243,
    "vx": 0.00001051,
    "vy": 0.0000165,
    "vz": -0.0000029,
    "rarad": 2.39490481693483,
    "decrad": 0.900671021159523,
    "pmrarad": -6.68121733173611e-7,
    "pmdecrad": -1.57467483e-7,
    "flam": 15,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 44773,
    "lum": 11.8904967898509,
    "bf": "15    UMa",
    "ra": 9.147863,
    "dec": 51.604648,
    "pmra": -137.81,
    "pmdec": -32.48,
    "rv": -0.1,
    "Asset Name": "ASTRID 003747",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Neko Ninja",
    "Hair": "High Bun",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00001051,
    "y_1": 0.0000165,
    "z_1": -0.0000029,
    "Distance in Parsecs_1": 28.8184,
    "HYG Star ID_1": 44773,
    "Hipparcos Catalogue_1": 44901,
    "Henry Draper Catalogue_1": 78209,
    "Harvard Revised Catalogue_1": 3619,
    "Gliese Catalog_1": "NN 3534"
  },
  {
    "Astrid #": "ASTRID 003748",
    "HYG Star ID": 106065,
    "Hipparcos Catalogue": 106407,
    "Henry Draper Catalogue": 205286,
    "Distance in Parsecs": 28.8184,
    "mag": 7.78,
    "Absolute Visual Magnitude": 5.482,
    "Spectral Type": "K2",
    "Color Index": 0.81,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.481644,
    "y": -13.036662,
    "z": 18.839791,
    "vx": 0.00002163,
    "vy": 0.00000795,
    "vz": -0.00001457,
    "rarad": 5.64241791948837,
    "decrad": 0.712518626237992,
    "pmrarad": 6.697700996875e-7,
    "pmdecrad": -6.68315658e-7,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 106065,
    "lum": 0.558727438994019,
    "ra": 21.552449,
    "dec": 40.82431,
    "pmra": 138.15,
    "pmdec": -137.85,
    "rv": 0,
    "Asset Name": "ASTRID 003748",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Prince",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00002163,
    "y_1": 0.00000795,
    "z_1": -0.00001457,
    "Distance in Parsecs_1": 28.8184,
    "HYG Star ID_1": 106065,
    "Hipparcos Catalogue_1": 106407,
    "Henry Draper Catalogue_1": 205286
  },
  {
    "Astrid #": "ASTRID 003749",
    "HYG Star ID": 118416,
    "Gliese Catalog": "NN 3385",
    "Distance in Parsecs": 28.8184,
    "mag": 11.55,
    "Absolute Visual Magnitude": 9.252,
    "Spectral Type": "M1.5",
    "Color Index": 1.46,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.21855,
    "y": 25.887465,
    "z": 12.603748,
    "vx": -0.00002594,
    "vy": 0.00003133,
    "vz": -0.00006683,
    "rarad": 1.61782789338043,
    "decrad": 0.4526505734395,
    "pmrarad": 8.47987608659722e-7,
    "pmdecrad": -0.00000257913121,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 118416,
    "lum": 0.0173460262787928,
    "ra": 6.179647,
    "dec": 25.934967,
    "pmra": 174.91,
    "pmdec": -531.98,
    "rv": 0,
    "Asset Name": "ASTRID 003749",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Fire",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00002594,
    "y_1": 0.00003133,
    "z_1": -0.00006683,
    "Distance in Parsecs_1": 28.8184,
    "HYG Star ID_1": 118416,
    "Gliese Catalog_1": "NN 3385"
  },
  {
    "Astrid #": "ASTRID 003750",
    "HYG Star ID": 74007,
    "Hipparcos Catalogue": 74235,
    "Henry Draper Catalogue": 134439,
    "Gliese Catalog": "Gl 579.2A",
    "Distance in Parsecs": 28.86,
    "mag": 9.07,
    "Absolute Visual Magnitude": 6.769,
    "Spectral Type": "K0/K1V",
    "Color Index": 0.77,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -18.688503,
    "y": -20.43393,
    "z": -8.139165,
    "vx": -0.00020373,
    "vy": -0.0000157,
    "vz": -0.00056041,
    "rarad": 3.97157755616311,
    "decrad": -0.285874541483228,
    "pmrarad": -0.00000484260992959722,
    "pmdecrad": -0.000017176512369,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 74007,
    "base": "Gl 579.2",
    "lum": 0.170765447281387,
    "ra": 15.170309,
    "dec": -16.379405,
    "pmra": -998.86,
    "pmdec": -3542.91,
    "rv": 294.3,
    "Asset Name": "ASTRID 003750",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Bring It",
    "Hair": "Braided Ponytail",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00020373,
    "y_1": -0.0000157,
    "z_1": -0.00056041,
    "Distance in Parsecs_1": 28.86,
    "HYG Star ID_1": 74007,
    "Hipparcos Catalogue_1": 74235,
    "Henry Draper Catalogue_1": 134439,
    "Gliese Catalog_1": "Gl 579.2A"
  },
  {
    "Astrid #": "ASTRID 003751",
    "HYG Star ID": 10282,
    "Hipparcos Catalogue": 10306,
    "Henry Draper Catalogue": 13555,
    "Harvard Revised Catalogue": 646,
    "Gliese Catalog": "GJ 1043",
    "Distance in Parsecs": 28.8684,
    "mag": 5.23,
    "Absolute Visual Magnitude": 2.928,
    "Spectral Type": "F5V",
    "Color Index": 0.457,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.519531,
    "y": 14.736568,
    "z": 10.444706,
    "vx": -0.00000784,
    "vy": 0.00002205,
    "vz": 0.00000275,
    "rarad": 0.579455028292944,
    "decrad": 0.370201665654265,
    "pmrarad": 7.87919193638888e-7,
    "pmdecrad": 1.9731916e-8,
    "bayer": "Eta",
    "flam": 17,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 10282,
    "lum": 5.87218865971303,
    "bf": "17Eta Ari",
    "ra": 2.213355,
    "dec": 21.210993,
    "pmra": 162.52,
    "pmdec": 4.07,
    "rv": 6,
    "Asset Name": "ASTRID 003751",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Honest Lad",
    "Outfit": "Teen",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": -0.00000784,
    "y_1": 0.00002205,
    "z_1": 0.00000275,
    "Distance in Parsecs_1": 28.8684,
    "HYG Star ID_1": 10282,
    "Hipparcos Catalogue_1": 10306,
    "Henry Draper Catalogue_1": 13555,
    "Harvard Revised Catalogue_1": 646,
    "Gliese Catalog_1": "GJ 1043"
  },
  {
    "Astrid #": "ASTRID 003752",
    "HYG Star ID": 55748,
    "Hipparcos Catalogue": 55915,
    "Gliese Catalog": "NN 3664",
    "Distance in Parsecs": 28.8684,
    "mag": 10.65,
    "Absolute Visual Magnitude": 8.348,
    "Spectral Type": "K7",
    "Color Index": 1.4,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -28.512347,
    "y": 4.052473,
    "z": 2.002016,
    "vx": 0.00000177,
    "vy": 0.00001175,
    "vz": 0.00000141,
    "rarad": 3.00040781816773,
    "decrad": 0.0694054728783012,
    "pmrarad": -4.11752258895833e-7,
    "pmdecrad": 4.9014663e-8,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 55748,
    "lum": 0.0398841186909052,
    "var_min": 10.701,
    "var_max": 10.591,
    "ra": 11.460714,
    "dec": 3.976641,
    "pmra": -84.93,
    "pmdec": 10.11,
    "rv": 0,
    "Asset Name": "ASTRID 003752",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Windy",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000177,
    "y_1": 0.00001175,
    "z_1": 0.00000141,
    "Distance in Parsecs_1": 28.8684,
    "HYG Star ID_1": 55748,
    "Hipparcos Catalogue_1": 55915,
    "Gliese Catalog_1": "NN 3664"
  },
  {
    "Astrid #": "ASTRID 003753",
    "HYG Star ID": 1764,
    "Hipparcos Catalogue": 1768,
    "Henry Draper Catalogue": 1815,
    "Gliese Catalog": "Gl 17.2",
    "Distance in Parsecs": 28.8767,
    "mag": 8.3,
    "Absolute Visual Magnitude": 5.997,
    "Spectral Type": "K1V",
    "Color Index": 0.888,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 25.599184,
    "y": 2.509291,
    "z": -13.124375,
    "vx": -0.00002786,
    "vy": 0.00002721,
    "vz": -0.00005004,
    "rarad": 0.0977090540486396,
    "decrad": -0.471807424115633,
    "pmrarad": 0.00000103211984453472,
    "pmdecrad": -0.000001938527501,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 1764,
    "lum": 0.347696244617812,
    "ra": 0.373221,
    "dec": -27.032574,
    "pmra": 212.89,
    "pmdec": -399.85,
    "rv": 0.4,
    "Asset Name": "ASTRID 003753",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Anxious Laugh",
    "Hair": "Angelic",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00002786,
    "y_1": 0.00002721,
    "z_1": -0.00005004,
    "Distance in Parsecs_1": 28.8767,
    "HYG Star ID_1": 1764,
    "Hipparcos Catalogue_1": 1768,
    "Henry Draper Catalogue_1": 1815,
    "Gliese Catalog_1": "Gl 17.2"
  },
  {
    "Astrid #": "ASTRID 003754",
    "HYG Star ID": 108178,
    "Hipparcos Catalogue": 108523,
    "Gliese Catalog": "NN 4245",
    "Distance in Parsecs": 28.8767,
    "mag": 11.87,
    "Absolute Visual Magnitude": 9.567,
    "Spectral Type": "M4",
    "Color Index": 1.653,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.093544,
    "y": -9.954844,
    "z": -21.037481,
    "vx": -0.00001275,
    "vy": 0.0000078,
    "vz": -0.00001406,
    "rarad": 5.75582652899296,
    "decrad": -0.816170472674846,
    "pmrarad": 1.11991960208333e-8,
    "pmdecrad": -7.10591411e-7,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 108178,
    "lum": 0.0129777678168578,
    "var_min": 12.02,
    "var_max": 11.7,
    "ra": 21.985638,
    "dec": -46.763123,
    "pmra": 2.31,
    "pmdec": -146.57,
    "rv": 0,
    "Asset Name": "ASTRID 003754",
    "Astrium Color": "Red",
    "Background": "Burning Cosmos",
    "Expression": "Scared",
    "Hair": "Innocent",
    "Outfit": "Scrubs",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00001275,
    "y_1": 0.0000078,
    "z_1": -0.00001406,
    "Distance in Parsecs_1": 28.8767,
    "HYG Star ID_1": 108178,
    "Hipparcos Catalogue_1": 108523,
    "Gliese Catalog_1": "NN 4245"
  },
  {
    "Astrid #": "ASTRID 003755",
    "HYG Star ID": 3178,
    "Hipparcos Catalogue": 3185,
    "Henry Draper Catalogue": 3795,
    "Harvard Revised Catalogue": 173,
    "Gliese Catalog": "Gl 27.2",
    "Distance in Parsecs": 28.885,
    "mag": 6.14,
    "Absolute Visual Magnitude": 3.837,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.718,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 26.014694,
    "y": 4.651118,
    "z": -11.658473,
    "vx": -0.00008229,
    "vy": 0.00007569,
    "vz": -0.00002574,
    "rarad": 0.176916353246512,
    "decrad": -0.415473464772457,
    "pmrarad": 0.0000030809424585625,
    "pmdecrad": -0.000001761231138,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3178,
    "lum": 2.54214313620493,
    "ra": 0.675771,
    "dec": -23.804876,
    "pmra": 635.49,
    "pmdec": -363.28,
    "rv": -50.4,
    "Asset Name": "ASTRID 003755",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Slick",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00008229,
    "y_1": 0.00007569,
    "z_1": -0.00002574,
    "Distance in Parsecs_1": 28.885,
    "HYG Star ID_1": 3178,
    "Hipparcos Catalogue_1": 3185,
    "Henry Draper Catalogue_1": 3795,
    "Harvard Revised Catalogue_1": 173,
    "Gliese Catalog_1": "Gl 27.2"
  },
  {
    "Astrid #": "ASTRID 003756",
    "HYG Star ID": 91043,
    "Hipparcos Catalogue": 91326,
    "Distance in Parsecs": 28.885,
    "mag": 10.78,
    "Absolute Visual Magnitude": 8.477,
    "Spectral Type": "K7",
    "Color Index": 1.36,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.883376,
    "y": -23.430726,
    "z": 16.439699,
    "vx": -0.00004819,
    "vy": -0.00001804,
    "vz": -0.00001433,
    "rarad": 4.87663788391634,
    "decrad": 0.605463330409396,
    "pmrarad": -0.00000174823813208333,
    "pmdecrad": -6.03496069e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 91043,
    "lum": 0.0354160400426461,
    "var_min": 10.834,
    "var_max": 10.704,
    "ra": 18.627385,
    "dec": 34.690493,
    "pmra": -360.6,
    "pmdec": -124.48,
    "rv": 0,
    "Asset Name": "ASTRID 003756",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Sweetheart",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00004819,
    "y_1": -0.00001804,
    "z_1": -0.00001433,
    "Distance in Parsecs_1": 28.885,
    "HYG Star ID_1": 91043,
    "Hipparcos Catalogue_1": 91326
  },
  {
    "Astrid #": "ASTRID 003757",
    "HYG Star ID": 64091,
    "Hipparcos Catalogue": 64295,
    "Henry Draper Catalogue": 114386,
    "Distance in Parsecs": 28.8934,
    "mag": 8.73,
    "Absolute Visual Magnitude": 6.426,
    "Spectral Type": "K3V",
    "Color Index": 0.982,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.536841,
    "y": -7.17766,
    "z": -16.595196,
    "vx": 0.00001904,
    "vy": 0.00002639,
    "vz": -0.00003728,
    "rarad": 3.44992258511793,
    "decrad": -0.611821376131156,
    "pmrarad": -6.70157950631944e-7,
    "pmdecrad": -0.000001576129275,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 64091,
    "lum": 0.234207069479382,
    "ra": 13.177734,
    "dec": -35.054783,
    "pmra": -138.23,
    "pmdec": -325.1,
    "rv": 0,
    "Asset Name": "ASTRID 003757",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Tiny Crown",
    "Outfit": "Toga",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.00001904,
    "y_1": 0.00002639,
    "z_1": -0.00003728,
    "Distance in Parsecs_1": 28.8934,
    "HYG Star ID_1": 64091,
    "Hipparcos Catalogue_1": 64295,
    "Henry Draper Catalogue_1": 114386
  },
  {
    "Astrid #": "ASTRID 003758",
    "HYG Star ID": 82939,
    "Hipparcos Catalogue": 83193,
    "Henry Draper Catalogue": 152653,
    "Distance in Parsecs": 28.8934,
    "mag": 9.35,
    "Absolute Visual Magnitude": 7.046,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.164,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.702195,
    "y": -10.112174,
    "z": -26.930849,
    "vx": 0.00001062,
    "vy": -0.00003808,
    "vz": 0.00001323,
    "rarad": 4.45125751932071,
    "decrad": -1.20010230240023,
    "pmrarad": 6.95465224756944e-7,
    "pmdecrad": 0.000001264297116,
    "con": "TrA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum Australe",
    "FULL CONSTELLATION NAME": "Triangulum Australe",
    "comp": 1,
    "comp_primary": 82939,
    "lum": 0.132312233311337,
    "var_min": 9.414,
    "var_max": 9.304,
    "ra": 17.002551,
    "dec": -68.760797,
    "pmra": 143.45,
    "pmdec": 260.78,
    "rv": 0,
    "Asset Name": "ASTRID 003758",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Knocked Out",
    "Hair": "Good Boy",
    "Outfit": "Tats",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum Australe",
    "x_1": 0.00001062,
    "y_1": -0.00003808,
    "z_1": 0.00001323,
    "Distance in Parsecs_1": 28.8934,
    "HYG Star ID_1": 82939,
    "Hipparcos Catalogue_1": 83193,
    "Henry Draper Catalogue_1": 152653
  },
  {
    "Astrid #": "ASTRID 003759",
    "HYG Star ID": 90076,
    "Hipparcos Catalogue": 90355,
    "Henry Draper Catalogue": 169822,
    "Distance in Parsecs": 28.8934,
    "mag": 7.83,
    "Absolute Visual Magnitude": 5.526,
    "Spectral Type": "G7V",
    "Color Index": 0.699,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.253309,
    "y": -28.368878,
    "z": 4.409075,
    "vx": -0.00002851,
    "vy": 0.00000926,
    "vz": -0.00006685,
    "rarad": 4.82656908901714,
    "decrad": 0.153197590849637,
    "pmrarad": -9.43883754673611e-7,
    "pmdecrad": -0.00000222093147,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 90076,
    "lum": 0.536537399519851,
    "ra": 18.436136,
    "dec": 8.777575,
    "pmra": -194.69,
    "pmdec": -458.1,
    "rv": -22,
    "Asset Name": "ASTRID 003759",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Greasy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00002851,
    "y_1": 0.00000926,
    "z_1": -0.00006685,
    "Distance in Parsecs_1": 28.8934,
    "HYG Star ID_1": 90076,
    "Hipparcos Catalogue_1": 90355,
    "Henry Draper Catalogue_1": 169822
  },
  {
    "Astrid #": "ASTRID 003760",
    "HYG Star ID": 91874,
    "Hipparcos Catalogue": 92161,
    "Henry Draper Catalogue": 173880,
    "Harvard Revised Catalogue": 7069,
    "Distance in Parsecs": 28.8934,
    "mag": 4.34,
    "Absolute Visual Magnitude": 2.036,
    "Spectral Type": "A5III",
    "Color Index": 0.148,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 5.592547,
    "y": -26.87472,
    "z": 9.015438,
    "vx": 0.00000152,
    "vy": 0.00005029,
    "vz": 0.00000148,
    "rarad": 4.91755760435104,
    "decrad": 0.317327367546658,
    "pmrarad": 4.05982976097222e-7,
    "pmdecrad": 5.76976761e-7,
    "flam": 111,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 91874,
    "lum": 13.3536503332718,
    "bf": "111    Her",
    "ra": 18.783686,
    "dec": 18.181519,
    "pmra": 83.74,
    "pmdec": 119.01,
    "rv": -45,
    "Asset Name": "ASTRID 003760",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Round Brush",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000152,
    "y_1": 0.00005029,
    "z_1": 0.00000148,
    "Distance in Parsecs_1": 28.8934,
    "HYG Star ID_1": 91874,
    "Hipparcos Catalogue_1": 92161,
    "Henry Draper Catalogue_1": 173880,
    "Harvard Revised Catalogue_1": 7069
  },
  {
    "Astrid #": "ASTRID 003761",
    "HYG Star ID": 2736,
    "Hipparcos Catalogue": 2742,
    "Henry Draper Catalogue": 3141,
    "Distance in Parsecs": 28.9017,
    "mag": 8.02,
    "Absolute Visual Magnitude": 5.715,
    "Spectral Type": "K0IV",
    "Color Index": 0.87,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.994646,
    "y": 3.23274,
    "z": 19.598036,
    "vx": 0.00001111,
    "vy": -0.00002212,
    "vz": -0.00000674,
    "rarad": 0.152781923420988,
    "decrad": 0.745164390613793,
    "pmrarad": -8.14923315645833e-7,
    "pmdecrad": -3.49986995e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 2736,
    "lum": 0.45081670454146,
    "ra": 0.583584,
    "dec": 42.694775,
    "pmra": -168.09,
    "pmdec": -72.19,
    "rv": 1,
    "Asset Name": "ASTRID 003761",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Braid",
    "Outfit": "Sailor",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00001111,
    "y_1": -0.00002212,
    "z_1": -0.00000674,
    "Distance in Parsecs_1": 28.9017,
    "HYG Star ID_1": 2736,
    "Hipparcos Catalogue_1": 2742,
    "Henry Draper Catalogue_1": 3141
  },
  {
    "Astrid #": "ASTRID 003762",
    "HYG Star ID": 119491,
    "Gliese Catalog": "Gl 855.1B",
    "Distance in Parsecs": 28.9017,
    "mag": 6.1,
    "Absolute Visual Magnitude": 3.795,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.056444,
    "y": -3.559517,
    "z": -27.52693,
    "vx": -0.00000915,
    "vy": 0.00020369,
    "vz": -0.00002909,
    "rarad": 5.86643233823896,
    "decrad": -1.2611221649665,
    "pmrarad": 0.00000631576477933333,
    "pmdecrad": -0.00000330179933,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 2,
    "comp_primary": 110271,
    "base": "Gl 855.1",
    "lum": 2.64240875732194,
    "ra": 22.408121,
    "dec": -72.256978,
    "pmra": 1302.72,
    "pmdec": -681.04,
    "rv": 0,
    "Asset Name": "ASTRID 003762",
    "Astrium Color": "Green",
    "Background": "Hazy Void",
    "Expression": "Malevolent",
    "Hair": "Goddess",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00000915,
    "y_1": 0.00020369,
    "z_1": -0.00002909,
    "Distance in Parsecs_1": 28.9017,
    "HYG Star ID_1": 119491,
    "Gliese Catalog_1": "Gl 855.1B"
  },
  {
    "Astrid #": "ASTRID 003763",
    "HYG Star ID": 13479,
    "Hipparcos Catalogue": 13513,
    "Henry Draper Catalogue": 18168,
    "Gliese Catalog": "Gl 118.1A",
    "Distance in Parsecs": 28.9184,
    "mag": 8.23,
    "Absolute Visual Magnitude": 5.924,
    "Spectral Type": "K1V",
    "Color Index": 0.933,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.987729,
    "y": 16.127295,
    "z": -16.958896,
    "vx": -0.00005686,
    "vy": 0.00004574,
    "vz": -0.00002305,
    "rarad": 0.759415676519769,
    "decrad": -0.626654997167223,
    "pmrarad": 0.00000250120225935416,
    "pmdecrad": -8.43333397e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 13479,
    "base": "Gl 118.1",
    "lum": 0.371877583324935,
    "ra": 2.900754,
    "dec": -35.904687,
    "pmra": 515.91,
    "pmdec": -173.95,
    "rv": 5.5,
    "Asset Name": "ASTRID 003763",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Tiny Crown",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00005686,
    "y_1": 0.00004574,
    "z_1": -0.00002305,
    "Distance in Parsecs_1": 28.9184,
    "HYG Star ID_1": 13479,
    "Hipparcos Catalogue_1": 13513,
    "Henry Draper Catalogue_1": 18168,
    "Gliese Catalog_1": "Gl 118.1A"
  },
  {
    "Astrid #": "ASTRID 003764",
    "HYG Star ID": 20182,
    "Hipparcos Catalogue": 20232,
    "Distance in Parsecs": 28.9184,
    "mag": 9.78,
    "Absolute Visual Magnitude": 7.474,
    "Spectral Type": "K5V",
    "Color Index": 1.209,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.798583,
    "y": 25.35308,
    "z": -7.368082,
    "vx": -0.00002078,
    "vy": 0.00001419,
    "vz": 0.00001554,
    "rarad": 1.1352335752182,
    "decrad": -0.257629210833973,
    "pmrarad": 8.58653509631944e-7,
    "pmdecrad": 5.55790403e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 20182,
    "lum": 0.0892072188725268,
    "ra": 4.336273,
    "dec": -14.761066,
    "pmra": 177.11,
    "pmdec": 114.64,
    "rv": 0,
    "Asset Name": "ASTRID 003764",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Determined",
    "Hair": "Wavy",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00002078,
    "y_1": 0.00001419,
    "z_1": 0.00001554,
    "Distance in Parsecs_1": 28.9184,
    "HYG Star ID_1": 20182,
    "Hipparcos Catalogue_1": 20232
  },
  {
    "Astrid #": "ASTRID 003765",
    "HYG Star ID": 53949,
    "Hipparcos Catalogue": 54105,
    "Gliese Catalog": "NN 3640",
    "Distance in Parsecs": 28.9184,
    "mag": 10.74,
    "Absolute Visual Magnitude": 8.434,
    "Color Index": 1.401,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -21.499439,
    "y": 5.334022,
    "z": 18.590217,
    "vx": 0.00000159,
    "vy": 0.00002786,
    "vz": -0.00000616,
    "rarad": 2.89840506183806,
    "decrad": 0.698214229992773,
    "pmrarad": -9.48247077798611e-7,
    "pmdecrad": -2.77943683e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 53949,
    "lum": 0.0368468189147868,
    "var_min": 10.797,
    "var_max": 10.687,
    "ra": 11.071092,
    "dec": 40.004729,
    "pmra": -195.59,
    "pmdec": -57.33,
    "rv": 0,
    "Asset Name": "ASTRID 003765",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Owie",
    "Hair": "Undercut",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000159,
    "y_1": 0.00002786,
    "z_1": -0.00000616,
    "Distance in Parsecs_1": 28.9184,
    "HYG Star ID_1": 53949,
    "Hipparcos Catalogue_1": 54105,
    "Gliese Catalog_1": "NN 3640"
  },
  {
    "Astrid #": "ASTRID 003766",
    "HYG Star ID": 3371,
    "Hipparcos Catalogue": 3378,
    "Distance in Parsecs": 28.9268,
    "mag": 11.55,
    "Absolute Visual Magnitude": 9.243,
    "Spectral Type": "M1",
    "Color Index": 1.44,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 26.226641,
    "y": 4.97288,
    "z": 11.144215,
    "vx": -0.00001143,
    "vy": 0.00005501,
    "vz": 0.00000234,
    "rarad": 0.187385805355749,
    "decrad": 0.395484942672082,
    "pmrarad": 0.00000194221208567361,
    "pmdecrad": 8.7848238e-8,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 3371,
    "lum": 0.017490410399663,
    "var_min": 11.622,
    "var_max": 11.472,
    "ra": 0.715761,
    "dec": 22.659618,
    "pmra": 400.61,
    "pmdec": 18.12,
    "rv": 0,
    "Asset Name": "ASTRID 003766",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Frizzy",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": -0.00001143,
    "y_1": 0.00005501,
    "z_1": 0.00000234,
    "Distance in Parsecs_1": 28.9268,
    "HYG Star ID_1": 3371,
    "Hipparcos Catalogue_1": 3378
  },
  {
    "Astrid #": "ASTRID 003767",
    "HYG Star ID": 36726,
    "Hipparcos Catalogue": 36832,
    "Henry Draper Catalogue": 61033,
    "Distance in Parsecs": 28.9268,
    "mag": 7.59,
    "Absolute Visual Magnitude": 5.283,
    "Spectral Type": "G5/G6V",
    "Color Index": 0.724,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.97933,
    "y": 15.962292,
    "z": -23.092291,
    "vx": -0.00000805,
    "vy": 0.00003063,
    "vz": 0.00002361,
    "rarad": 1.98298831992443,
    "decrad": -0.924468780851217,
    "pmrarad": -1.69151493145833e-7,
    "pmdecrad": 0.000001355102718,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 36726,
    "lum": 0.67111971969842,
    "ra": 7.574457,
    "dec": -52.968159,
    "pmra": -34.89,
    "pmdec": 279.51,
    "rv": 0,
    "Asset Name": "ASTRID 003767",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Smoulder",
    "Hair": "Braided Ponytail",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00000805,
    "y_1": 0.00003063,
    "z_1": 0.00002361,
    "Distance in Parsecs_1": 28.9268,
    "HYG Star ID_1": 36726,
    "Hipparcos Catalogue_1": 36832,
    "Henry Draper Catalogue_1": 61033
  },
  {
    "Astrid #": "ASTRID 003768",
    "HYG Star ID": 37870,
    "Hipparcos Catalogue": 37978,
    "Gliese Catalog": "GJ 1100",
    "Distance in Parsecs": 28.9268,
    "mag": 11.5,
    "Absolute Visual Magnitude": 9.193,
    "Spectral Type": "M1",
    "Color Index": 1.38,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.642155,
    "y": 25.06757,
    "z": -6.967972,
    "vx": -0.00000724,
    "vy": -0.00002256,
    "vz": -0.00006803,
    "rarad": 2.03789632418448,
    "decrad": -0.243275469235342,
    "pmrarad": 5.746496555625e-7,
    "pmdecrad": -0.000002423341182,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 37870,
    "lum": 0.0183147080466135,
    "var_min": 11.594,
    "var_max": 11.424,
    "ra": 7.784191,
    "dec": -13.938658,
    "pmra": 118.53,
    "pmdec": -499.85,
    "rv": 0,
    "Asset Name": "ASTRID 003768",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Malevolent",
    "Hair": "Pixie",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000724,
    "y_1": -0.00002256,
    "z_1": -0.00006803,
    "Distance in Parsecs_1": 28.9268,
    "HYG Star ID_1": 37870,
    "Hipparcos Catalogue_1": 37978,
    "Gliese Catalog_1": "GJ 1100"
  },
  {
    "Astrid #": "ASTRID 003769",
    "HYG Star ID": 75987,
    "Hipparcos Catalogue": 76219,
    "Henry Draper Catalogue": 138716,
    "Harvard Revised Catalogue": 5777,
    "Distance in Parsecs": 28.9268,
    "mag": 4.61,
    "Absolute Visual Magnitude": 2.303,
    "Spectral Type": "K1IV",
    "Color Index": 1,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -16.923971,
    "y": -22.908696,
    "z": -5.055242,
    "vx": 0.00000916,
    "vy": -0.00005973,
    "vz": -0.0000409,
    "rarad": 4.07612177876624,
    "decrad": -0.175659161650645,
    "pmrarad": 0.00000148168757051388,
    "pmdecrad": -0.000001135045788,
    "flam": 37,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 75987,
    "lum": 10.4423921854317,
    "bf": "37    Lib",
    "ra": 15.569638,
    "dec": -10.064529,
    "pmra": 305.62,
    "pmdec": -234.12,
    "rv": 48,
    "Asset Name": "ASTRID 003769",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oof",
    "Hair": "Medium Bob",
    "Outfit": "Tarnished",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": 0.00000916,
    "y_1": -0.00005973,
    "z_1": -0.0000409,
    "Distance in Parsecs_1": 28.9268,
    "HYG Star ID_1": 75987,
    "Hipparcos Catalogue_1": 76219,
    "Henry Draper Catalogue_1": 138716,
    "Harvard Revised Catalogue_1": 5777
  },
  {
    "Astrid #": "ASTRID 003770",
    "HYG Star ID": 117913,
    "Hipparcos Catalogue": 118281,
    "Henry Draper Catalogue": 224635,
    "Harvard Revised Catalogue": 9074,
    "Gliese Catalog": "NN 4384A",
    "Distance in Parsecs": 28.9268,
    "mag": 5.81,
    "Absolute Visual Magnitude": 3.503,
    "Spectral Type": "F8",
    "Color Index": 0.539,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 24.058963,
    "y": -0.053726,
    "z": 16.059864,
    "vx": 0.00000169,
    "vy": -0.00000828,
    "vz": -0.00001656,
    "rarad": 6.28095268887673,
    "decrad": 0.588592703198523,
    "pmrarad": -2.86185515631944e-7,
    "pmdecrad": -5.09005883e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 117913,
    "base": "NN 4384",
    "lum": 3.45780103532305,
    "ra": 23.991472,
    "dec": 33.723878,
    "pmra": -59.03,
    "pmdec": -104.99,
    "rv": -7.6,
    "Asset Name": "ASTRID 003770",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Lovestruck",
    "Hair": "Round Brush",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000169,
    "y_1": -0.00000828,
    "z_1": -0.00001656,
    "Distance in Parsecs_1": 28.9268,
    "HYG Star ID_1": 117913,
    "Hipparcos Catalogue_1": 118281,
    "Henry Draper Catalogue_1": 224635,
    "Harvard Revised Catalogue_1": 9074,
    "Gliese Catalog_1": "NN 4384A"
  },
  {
    "Astrid #": "ASTRID 003771",
    "HYG Star ID": 19940,
    "Hipparcos Catalogue": 19990,
    "Henry Draper Catalogue": 27045,
    "Harvard Revised Catalogue": 1329,
    "Distance in Parsecs": 28.9436,
    "mag": 4.93,
    "Absolute Visual Magnitude": 2.622,
    "Spectral Type": "A3m",
    "Color Index": 0.259,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 11.744304,
    "y": 24.419494,
    "z": 10.17349,
    "vx": 0.00001312,
    "vy": 0.00001405,
    "vz": -0.00000232,
    "rarad": 1.12251303582146,
    "decrad": 0.359164167217447,
    "pmrarad": -1.98191832611111e-7,
    "pmdecrad": -2.97918006e-7,
    "bayer": "Ome-2",
    "flam": 50,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 19940,
    "lum": 7.78394932626726,
    "bf": "50Ome2Tau",
    "ra": 4.287684,
    "dec": 20.578591,
    "pmra": -40.88,
    "pmdec": -61.45,
    "rv": 16,
    "Asset Name": "ASTRID 003771",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Glad",
    "Hair": "Middle Part",
    "Outfit": "Iron Star",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": 0.00001312,
    "y_1": 0.00001405,
    "z_1": -0.00000232,
    "Distance in Parsecs_1": 28.9436,
    "HYG Star ID_1": 19940,
    "Hipparcos Catalogue_1": 19990,
    "Henry Draper Catalogue_1": 27045,
    "Harvard Revised Catalogue_1": 1329
  },
  {
    "Astrid #": "ASTRID 003772",
    "HYG Star ID": 22445,
    "Hipparcos Catalogue": 22498,
    "Distance in Parsecs": 28.9519,
    "mag": 9.99,
    "Absolute Visual Magnitude": 7.682,
    "Spectral Type": "K7",
    "Color Index": 1.33,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.884681,
    "y": 12.399479,
    "z": 25.872276,
    "vx": -0.00002208,
    "vy": 0.00003248,
    "vz": -0.00001225,
    "rarad": 1.26717696296851,
    "decrad": 1.10536852307718,
    "pmrarad": 0.00000106319640145833,
    "pmdecrad": -9.42962608e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 22445,
    "lum": 0.073654621147023,
    "var": "DP",
    "var_min": 10.448,
    "var_max": 9.908,
    "ra": 4.840259,
    "dec": 63.332951,
    "pmra": 219.3,
    "pmdec": -194.5,
    "rv": 0,
    "Asset Name": "ASTRID 003772",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Innocent",
    "Outfit": "Astro",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00002208,
    "y_1": 0.00003248,
    "z_1": -0.00001225,
    "Distance in Parsecs_1": 28.9519,
    "HYG Star ID_1": 22445,
    "Hipparcos Catalogue_1": 22498
  },
  {
    "Astrid #": "ASTRID 003773",
    "HYG Star ID": 109470,
    "Hipparcos Catalogue": 109816,
    "Henry Draper Catalogue": 210975,
    "Distance in Parsecs": 28.9519,
    "mag": 9.52,
    "Absolute Visual Magnitude": 7.212,
    "Spectral Type": "K3/K4V",
    "Color Index": 1.07,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 21.926342,
    "y": -10.86287,
    "z": -15.474046,
    "vx": -0.00000794,
    "vy": 0.00000333,
    "vz": -0.0000136,
    "rarad": 5.82320397952681,
    "decrad": -0.563885603893391,
    "pmrarad": -1.85683639652777e-8,
    "pmdecrad": -5.55644959e-7,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 109470,
    "lum": 0.113553362784193,
    "ra": 22.243001,
    "dec": -32.308265,
    "pmra": -3.83,
    "pmdec": -114.61,
    "rv": 0,
    "Asset Name": "ASTRID 003773",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Malevolent",
    "Hair": "Curly",
    "Outfit": "Scrubs",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000794,
    "y_1": 0.00000333,
    "z_1": -0.0000136,
    "Distance in Parsecs_1": 28.9519,
    "HYG Star ID_1": 109470,
    "Hipparcos Catalogue_1": 109816,
    "Henry Draper Catalogue_1": 210975
  },
  {
    "Astrid #": "ASTRID 003774",
    "HYG Star ID": 33632,
    "Hipparcos Catalogue": 33719,
    "Henry Draper Catalogue": 52265,
    "Harvard Revised Catalogue": 2622,
    "Distance in Parsecs": 28.9603,
    "mag": 6.29,
    "Absolute Visual Magnitude": 3.981,
    "Spectral Type": "G0III-IV",
    "Color Index": 0.572,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.499264,
    "y": 27.84151,
    "z": -2.708925,
    "vx": 0.00000112,
    "vy": 0.00005834,
    "vz": 0.00000606,
    "rarad": 1.83390736732222,
    "decrad": -0.09367464645682,
    "pmrarad": -5.61220316611111e-7,
    "pmdecrad": 3.89547792e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 33632,
    "lum": 2.2263836295529,
    "ra": 7.00501,
    "dec": -5.367162,
    "pmra": -115.76,
    "pmdec": 80.35,
    "rv": 54,
    "Asset Name": "ASTRID 003774",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Good Boy",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 0.00000112,
    "y_1": 0.00005834,
    "z_1": 0.00000606,
    "Distance in Parsecs_1": 28.9603,
    "HYG Star ID_1": 33632,
    "Hipparcos Catalogue_1": 33719,
    "Henry Draper Catalogue_1": 52265,
    "Harvard Revised Catalogue_1": 2622
  },
  {
    "Astrid #": "ASTRID 003775",
    "HYG Star ID": 375,
    "Hipparcos Catalogue": 375,
    "Distance in Parsecs": 28.9687,
    "mag": 9.08,
    "Absolute Visual Magnitude": 6.77,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 23.934179,
    "y": 0.487833,
    "z": 16.312653,
    "vx": 0.00000438,
    "vy": 0.00001742,
    "vz": -0.00000695,
    "rarad": 0.0203783527757157,
    "decrad": 0.598148070546085,
    "pmrarad": 5.98260081805555e-7,
    "pmdecrad": -2.90451876e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 375,
    "lum": 0.170608238900312,
    "var_min": 9.523,
    "var_max": 7.603,
    "ra": 0.07784,
    "dec": 34.27136,
    "pmra": 123.4,
    "pmdec": -59.91,
    "rv": 0,
    "Asset Name": "ASTRID 003775",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Determined Shinobi",
    "Hair": "Wavy",
    "Outfit": "Tarnished",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000438,
    "y_1": 0.00001742,
    "z_1": -0.00000695,
    "Distance in Parsecs_1": 28.9687,
    "HYG Star ID_1": 375,
    "Hipparcos Catalogue_1": 375
  },
  {
    "Astrid #": "ASTRID 003776",
    "HYG Star ID": 7064,
    "Hipparcos Catalogue": 7078,
    "Henry Draper Catalogue": 9021,
    "Harvard Revised Catalogue": 427,
    "Distance in Parsecs": 28.9771,
    "mag": 5.82,
    "Absolute Visual Magnitude": 3.51,
    "Spectral Type": "F6V",
    "Color Index": 0.489,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.019863,
    "y": 3.792957,
    "z": 27.275089,
    "vx": 0.00000337,
    "vy": 0.00002237,
    "vz": 0.00000121,
    "rarad": 0.39805133218723,
    "decrad": 1.22634868298902,
    "pmrarad": 6.66764254868055e-7,
    "pmdecrad": -3.68458397e-7,
    "flam": 38,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 7064,
    "lum": 3.43557947899874,
    "bf": "38    Cas",
    "ra": 1.520444,
    "dec": 70.264604,
    "pmra": 137.53,
    "pmdec": -76,
    "rv": 5,
    "Asset Name": "ASTRID 003776",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Pompadour",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00000337,
    "y_1": 0.00002237,
    "z_1": 0.00000121,
    "Distance in Parsecs_1": 28.9771,
    "HYG Star ID_1": 7064,
    "Hipparcos Catalogue_1": 7078,
    "Henry Draper Catalogue_1": 9021,
    "Harvard Revised Catalogue_1": 427
  },
  {
    "Astrid #": "ASTRID 003777",
    "HYG Star ID": 10346,
    "Hipparcos Catalogue": 10370,
    "Distance in Parsecs": 28.9855,
    "mag": 11.83,
    "Absolute Visual Magnitude": 9.519,
    "Color Index": 1.456,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 19.770679,
    "y": 13.039325,
    "z": -16.710938,
    "vx": -0.0000147,
    "vy": -0.00003384,
    "vz": -0.0000438,
    "rarad": 0.583045816255071,
    "decrad": -0.614472411262453,
    "pmrarad": -6.95513706124999e-7,
    "pmdecrad": -0.000001849515709,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 10346,
    "lum": 0.0135643816290926,
    "var_min": 11.926,
    "var_max": 11.626,
    "ra": 2.227071,
    "dec": -35.206676,
    "pmra": -143.46,
    "pmdec": -381.49,
    "rv": 0,
    "Asset Name": "ASTRID 003777",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Undercut",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.0000147,
    "y_1": -0.00003384,
    "z_1": -0.0000438,
    "Distance in Parsecs_1": 28.9855,
    "HYG Star ID_1": 10346,
    "Hipparcos Catalogue_1": 10370
  },
  {
    "Astrid #": "ASTRID 003778",
    "HYG Star ID": 59634,
    "Hipparcos Catalogue": 59816,
    "Distance in Parsecs": 28.9855,
    "mag": 9.45,
    "Absolute Visual Magnitude": 7.139,
    "Spectral Type": "K8",
    "Color Index": 1.215,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -28.775155,
    "y": -2.008615,
    "z": 2.848705,
    "vx": -0.00000432,
    "vy": 0.00004806,
    "vz": -0.0000097,
    "rarad": 3.21128350237884,
    "decrad": 0.0984392528173537,
    "pmrarad": -0.00000166441384671527,
    "pmdecrad": -3.36363731e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 59634,
    "lum": 0.121450693771577,
    "ra": 12.266199,
    "dec": 5.640154,
    "pmra": -343.31,
    "pmdec": -69.38,
    "rv": 0,
    "Asset Name": "ASTRID 003778",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Curly",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000432,
    "y_1": 0.00004806,
    "z_1": -0.0000097,
    "Distance in Parsecs_1": 28.9855,
    "HYG Star ID_1": 59634,
    "Hipparcos Catalogue_1": 59816
  },
  {
    "Astrid #": "ASTRID 003779",
    "HYG Star ID": 13576,
    "Hipparcos Catalogue": 13610,
    "Distance in Parsecs": 28.9939,
    "mag": 11.38,
    "Absolute Visual Magnitude": 9.068,
    "Color Index": 1.4,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 18.91634,
    "y": 18.16972,
    "z": -12.356358,
    "vx": -0.00003536,
    "vy": 0.00002748,
    "vz": -0.00001373,
    "rarad": 0.765267419979217,
    "decrad": -0.440255895216637,
    "pmrarad": 0.00000152856905342361,
    "pmdecrad": -5.2345333e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 13576,
    "lum": 0.020549440413453,
    "var_min": 11.482,
    "var_max": 11.302,
    "ra": 2.923106,
    "dec": -25.224805,
    "pmra": 315.29,
    "pmdec": -107.97,
    "rv": 0,
    "Asset Name": "ASTRID 003779",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Straw Hat",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00003536,
    "y_1": 0.00002748,
    "z_1": -0.00001373,
    "Distance in Parsecs_1": 28.9939,
    "HYG Star ID_1": 13576,
    "Hipparcos Catalogue_1": 13610
  },
  {
    "Astrid #": "ASTRID 003780",
    "HYG Star ID": 54024,
    "Hipparcos Catalogue": 54182,
    "Henry Draper Catalogue": 96097,
    "Harvard Revised Catalogue": 4310,
    "Distance in Parsecs": 28.9939,
    "mag": 4.62,
    "Absolute Visual Magnitude": 2.308,
    "Spectral Type": "F2III-IVvar",
    "Color Index": 0.332,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -27.933018,
    "y": 6.832954,
    "z": 3.702177,
    "vx": 0.00000575,
    "vy": 0.00004843,
    "vz": -0.00000595,
    "rarad": 2.90168470771552,
    "decrad": 0.128037481235265,
    "pmrarad": -0.00000166974679720138,
    "pmdecrad": -2.29801684e-7,
    "bayer": "Chi",
    "flam": 63,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54024,
    "lum": 10.394413751488,
    "bf": "63Chi Leo",
    "ra": 11.083619,
    "dec": 7.336007,
    "pmra": -344.41,
    "pmdec": -47.4,
    "rv": 5,
    "Asset Name": "ASTRID 003780",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Maniacal",
    "Hair": "Round Brush",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000575,
    "y_1": 0.00004843,
    "z_1": -0.00000595,
    "Distance in Parsecs_1": 28.9939,
    "HYG Star ID_1": 54024,
    "Hipparcos Catalogue_1": 54182,
    "Henry Draper Catalogue_1": 96097,
    "Harvard Revised Catalogue_1": 4310
  },
  {
    "Astrid #": "ASTRID 003781",
    "HYG Star ID": 1543,
    "Hipparcos Catalogue": 1546,
    "Distance in Parsecs": 29.0107,
    "mag": 12.46,
    "Absolute Visual Magnitude": 10.147,
    "Spectral Type": "K:...",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 27.180398,
    "y": 2.292952,
    "z": -9.878717,
    "vx": -0.0000113,
    "vy": 0.00007316,
    "vz": -0.00001411,
    "rarad": 0.0841598350674181,
    "decrad": -0.347469660765932,
    "pmrarad": 0.00000254595056206944,
    "pmdecrad": -5.17344678e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 1543,
    "lum": 0.00760676500766226,
    "var_min": 12.613,
    "var_max": 12.273,
    "ra": 0.321467,
    "dec": -19.908545,
    "pmra": 525.14,
    "pmdec": -106.71,
    "rv": 0,
    "Asset Name": "ASTRID 003781",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Nervous Grin",
    "Hair": "Charmer",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000113,
    "y_1": 0.00007316,
    "z_1": -0.00001411,
    "Distance in Parsecs_1": 29.0107,
    "HYG Star ID_1": 1543,
    "Hipparcos Catalogue_1": 1546
  },
  {
    "Astrid #": "ASTRID 003782",
    "HYG Star ID": 20252,
    "Hipparcos Catalogue": 20302,
    "Distance in Parsecs": 29.0276,
    "mag": 10.69,
    "Absolute Visual Magnitude": 8.376,
    "Color Index": 1.385,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 9.848867,
    "y": 21.365997,
    "z": -17.00281,
    "vx": -0.00003086,
    "vy": 0.00001454,
    "vz": 4e-7,
    "rarad": 1.13886323289816,
    "decrad": -0.625800557950026,
    "pmrarad": 0.00000117518836166666,
    "pmdecrad": 1.6919997e-8,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 20252,
    "lum": 0.0388686986137302,
    "var_min": 10.756,
    "var_max": 10.626,
    "ra": 4.350137,
    "dec": -35.855731,
    "pmra": 242.4,
    "pmdec": 3.49,
    "rv": 0,
    "Asset Name": "ASTRID 003782",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Scared",
    "Hair": "Curly",
    "Outfit": "Sailor",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00003086,
    "y_1": 0.00001454,
    "z_1": 4e-7,
    "Distance in Parsecs_1": 29.0276,
    "HYG Star ID_1": 20252,
    "Hipparcos Catalogue_1": 20302
  },
  {
    "Astrid #": "ASTRID 003783",
    "HYG Star ID": 26769,
    "Hipparcos Catalogue": 26834,
    "Henry Draper Catalogue": 37986,
    "Distance in Parsecs": 29.0444,
    "mag": 7.36,
    "Absolute Visual Magnitude": 5.045,
    "Spectral Type": "G8/K0IV",
    "Color Index": 0.801,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 2.207368,
    "y": 27.883593,
    "z": -7.825671,
    "vx": -0.00002992,
    "vy": 0.00005716,
    "vz": -0.00003253,
    "rarad": 1.49179699252996,
    "decrad": -0.272804431004398,
    "pmrarad": 0.000001182460566875,
    "pmdecrad": -5.71886217e-7,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 26769,
    "lum": 0.835603018231247,
    "ra": 5.698245,
    "dec": -15.630543,
    "pmra": 243.9,
    "pmdec": -117.96,
    "rv": 60,
    "Asset Name": "ASTRID 003783",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Side Part",
    "Outfit": "Garb",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.00002992,
    "y_1": 0.00005716,
    "z_1": -0.00003253,
    "Distance in Parsecs_1": 29.0444,
    "HYG Star ID_1": 26769,
    "Hipparcos Catalogue_1": 26834,
    "Henry Draper Catalogue_1": 37986
  },
  {
    "Astrid #": "ASTRID 003784",
    "HYG Star ID": 60022,
    "Hipparcos Catalogue": 60207,
    "Henry Draper Catalogue": 107388,
    "Distance in Parsecs": 29.0444,
    "mag": 8.99,
    "Absolute Visual Magnitude": 6.675,
    "Spectral Type": "K3V",
    "Color Index": 1.009,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -27.198594,
    "y": -2.472934,
    "z": -9.884243,
    "vx": -0.00000165,
    "vy": 0.00005046,
    "vz": -0.00000809,
    "rarad": 3.2322657329083,
    "decrad": -0.347251785086715,
    "pmrarad": -0.00000173543905091666,
    "pmdecrad": -2.96124196e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 60022,
    "lum": 0.186208713666286,
    "ra": 12.346346,
    "dec": -19.896062,
    "pmra": -357.96,
    "pmdec": -61.08,
    "rv": 0,
    "Asset Name": "ASTRID 003784",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Chuckle",
    "Hair": "Braid",
    "Outfit": "River Spirit",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000165,
    "y_1": 0.00005046,
    "z_1": -0.00000809,
    "Distance in Parsecs_1": 29.0444,
    "HYG Star ID_1": 60022,
    "Hipparcos Catalogue_1": 60207,
    "Henry Draper Catalogue_1": 107388
  },
  {
    "Astrid #": "ASTRID 003785",
    "HYG Star ID": 8025,
    "Hipparcos Catalogue": 8039,
    "Henry Draper Catalogue": 10611,
    "Distance in Parsecs": 29.0529,
    "mag": 8.23,
    "Absolute Visual Magnitude": 5.914,
    "Spectral Type": "K0V",
    "Color Index": 0.853,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.31473,
    "y": 11.759278,
    "z": -10.704406,
    "vx": -0.00000354,
    "vy": 0.00000619,
    "vz": -0.00000124,
    "rarad": 0.450464078543086,
    "decrad": -0.377336158577157,
    "pmrarad": 2.44976352784722e-7,
    "pmdecrad": -4.6008818e-8,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 8025,
    "lum": 0.375318524237913,
    "var_min": 8.318,
    "var_max": 8.188,
    "ra": 1.720646,
    "dec": -21.619769,
    "pmra": 50.53,
    "pmdec": -9.49,
    "rv": 0,
    "Asset Name": "ASTRID 003785",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Charmer",
    "Hair": "Shinobi",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000354,
    "y_1": 0.00000619,
    "z_1": -0.00000124,
    "Distance in Parsecs_1": 29.0529,
    "HYG Star ID_1": 8025,
    "Hipparcos Catalogue_1": 8039,
    "Henry Draper Catalogue_1": 10611
  },
  {
    "Astrid #": "ASTRID 003786",
    "HYG Star ID": 67559,
    "Hipparcos Catalogue": 67773,
    "Henry Draper Catalogue": 121131,
    "Distance in Parsecs": 29.0529,
    "mag": 8.37,
    "Absolute Visual Magnitude": 6.054,
    "Spectral Type": "K1V",
    "Color Index": 0.815,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.632734,
    "y": -12.172253,
    "z": 13.553082,
    "vx": -0.00003658,
    "vy": -0.00005805,
    "vz": -0.00003145,
    "rarad": 3.63503232939044,
    "decrad": 0.485319877749234,
    "pmrarad": 0.00000116335890786111,
    "pmdecrad": -0.000001916371516,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 67559,
    "lum": 0.329913433788864,
    "ra": 13.884801,
    "dec": 27.806781,
    "pmra": 239.96,
    "pmdec": -395.28,
    "rv": 37.3,
    "Asset Name": "ASTRID 003786",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Cute Neko",
    "Hair": "Pompadour",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00003658,
    "y_1": -0.00005805,
    "z_1": -0.00003145,
    "Distance in Parsecs_1": 29.0529,
    "HYG Star ID_1": 67559,
    "Hipparcos Catalogue_1": 67773,
    "Henry Draper Catalogue_1": 121131
  },
  {
    "Astrid #": "ASTRID 003787",
    "HYG Star ID": 4280,
    "Hipparcos Catalogue": 4290,
    "Henry Draper Catalogue": 5294,
    "Gliese Catalog": "Gl 42.1",
    "Distance in Parsecs": 29.0613,
    "mag": 7.41,
    "Absolute Visual Magnitude": 5.093,
    "Spectral Type": "G5",
    "Color Index": 0.652,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 25.768118,
    "y": 6.303969,
    "z": 11.866707,
    "vx": 0.00000841,
    "vy": -0.00002465,
    "vz": -0.0000267,
    "rarad": 0.239930454560775,
    "decrad": 0.420629069910898,
    "pmrarad": -8.92735911375e-7,
    "pmdecrad": -8.71210183e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 4280,
    "lum": 0.799466002520315,
    "ra": 0.916467,
    "dec": 24.10027,
    "pmra": -184.14,
    "pmdec": -179.7,
    "rv": -8.6,
    "Asset Name": "ASTRID 003787",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Charmer",
    "Hair": "Bangs",
    "Outfit": "Emperor",
    "Astral Crystal": "Shuriken",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.00000841,
    "y_1": -0.00002465,
    "z_1": -0.0000267,
    "Distance in Parsecs_1": 29.0613,
    "HYG Star ID_1": 4280,
    "Hipparcos Catalogue_1": 4290,
    "Henry Draper Catalogue_1": 5294,
    "Gliese Catalog_1": "Gl 42.1"
  },
  {
    "Astrid #": "ASTRID 003788",
    "HYG Star ID": 24361,
    "Hipparcos Catalogue": 24419,
    "Henry Draper Catalogue": 34101,
    "Gliese Catalog": "Gl 193",
    "Distance in Parsecs": 29.0613,
    "mag": 7.43,
    "Absolute Visual Magnitude": 5.113,
    "Spectral Type": "G8V",
    "Color Index": 0.719,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.53434,
    "y": 27.406704,
    "z": -7.925894,
    "vx": -0.00002312,
    "vy": 0.00002859,
    "vz": -0.00003954,
    "rarad": 1.37154171320587,
    "decrad": -0.276226820497705,
    "pmrarad": 9.74766386124999e-7,
    "pmdecrad": -0.000001089085452,
    "con": "Lep",
    "Full Constellation Name Formula 1": "Lepus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lepus",
    "comp": 1,
    "comp_primary": 24361,
    "lum": 0.784874103488729,
    "ra": 5.238903,
    "dec": -15.826631,
    "pmra": 201.06,
    "pmdec": -224.64,
    "rv": 32.6,
    "Asset Name": "ASTRID 003788",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Shy Smile",
    "Hair": "Undercut",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lepus",
    "x_1": -0.00002312,
    "y_1": 0.00002859,
    "z_1": -0.00003954,
    "Distance in Parsecs_1": 29.0613,
    "HYG Star ID_1": 24361,
    "Hipparcos Catalogue_1": 24419,
    "Henry Draper Catalogue_1": 34101,
    "Gliese Catalog_1": "Gl 193"
  },
  {
    "Astrid #": "ASTRID 003789",
    "HYG Star ID": 69491,
    "Hipparcos Catalogue": 69713,
    "Henry Draper Catalogue": 125161,
    "Harvard Revised Catalogue": 5350,
    "Gliese Catalog": "Wo 9474A",
    "Distance in Parsecs": 29.0698,
    "mag": 4.75,
    "Absolute Visual Magnitude": 2.433,
    "Spectral Type": "A9V",
    "Color Index": 0.236,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -15.038822,
    "y": -10.159621,
    "z": 22.708137,
    "vx": 0.00000622,
    "vy": 0.00002971,
    "vz": -0.00000707,
    "rarad": 3.73573050666886,
    "decrad": 0.896527312303438,
    "pmrarad": -7.27123558097222e-7,
    "pmdecrad": 4.33520393e-7,
    "bayer": "Iot",
    "flam": 21,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 69491,
    "base": "Wo 9474",
    "lum": 9.26403100736399,
    "var": "Iot",
    "var_min": 4.762,
    "var_max": 4.742,
    "bf": "21Iot Boo",
    "ra": 14.269439,
    "dec": 51.367231,
    "pmra": -149.98,
    "pmdec": 89.42,
    "rv": -18.7,
    "Asset Name": "ASTRID 003789",
    "Astrium Color": "White",
    "Background": "Silken Darkness",
    "Expression": "Maniacal",
    "Hair": "Angelic",
    "Outfit": "Suit",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000622,
    "y_1": 0.00002971,
    "z_1": -0.00000707,
    "Distance in Parsecs_1": 29.0698,
    "HYG Star ID_1": 69491,
    "Hipparcos Catalogue_1": 69713,
    "Henry Draper Catalogue_1": 125161,
    "Harvard Revised Catalogue_1": 5350,
    "Gliese Catalog_1": "Wo 9474A"
  },
  {
    "Astrid #": "ASTRID 003790",
    "HYG Star ID": 53075,
    "Hipparcos Catalogue": 53229,
    "Henry Draper Catalogue": 94264,
    "Harvard Revised Catalogue": 4247,
    "Distance in Parsecs": 29.0867,
    "mag": 3.79,
    "Absolute Visual Magnitude": 1.472,
    "Spectral Type": "K0III-IV",
    "Color Index": 1.04,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.041794,
    "y": 6.900625,
    "z": 16.355475,
    "vx": -0.00003843,
    "vy": -0.0000021,
    "vz": -0.00002415,
    "rarad": 2.85060945068809,
    "decrad": 0.597162152992377,
    "pmrarad": 4.48307210409722e-7,
    "pmdecrad": -0.000001386858014,
    "flam": 46,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 53075,
    "lum": 22.4491550756959,
    "bf": "46    LMi",
    "ra": 10.888526,
    "dec": 34.214871,
    "pmra": 92.47,
    "pmdec": -286.06,
    "rv": 16,
    "Asset Name": "ASTRID 003790",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Side Part",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": -0.00003843,
    "y_1": -0.0000021,
    "z_1": -0.00002415,
    "Distance in Parsecs_1": 29.0867,
    "HYG Star ID_1": 53075,
    "Hipparcos Catalogue_1": 53229,
    "Henry Draper Catalogue_1": 94264,
    "Harvard Revised Catalogue_1": 4247
  },
  {
    "Astrid #": "ASTRID 003791",
    "HYG Star ID": 113395,
    "Hipparcos Catalogue": 113755,
    "Henry Draper Catalogue": 240166,
    "Distance in Parsecs": 29.0867,
    "mag": 8.66,
    "Absolute Visual Magnitude": 6.342,
    "Spectral Type": "K2V",
    "Color Index": 1.019,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.820102,
    "y": -3.818201,
    "z": 24.735035,
    "vx": 0.00002496,
    "vy": -0.0000301,
    "vz": -0.0000196,
    "rarad": 6.03103842485863,
    "decrad": 1.01672589131974,
    "pmrarad": -7.88016156375e-7,
    "pmdecrad": -0.000001280877744,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 113395,
    "lum": 0.253046304946139,
    "ra": 23.03687,
    "dec": 58.254102,
    "pmra": -162.54,
    "pmdec": -264.2,
    "rv": 0,
    "Asset Name": "ASTRID 003791",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Manbun",
    "Outfit": "Tats",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": 0.00002496,
    "y_1": -0.0000301,
    "z_1": -0.0000196,
    "Distance in Parsecs_1": 29.0867,
    "HYG Star ID_1": 113395,
    "Hipparcos Catalogue_1": 113755,
    "Henry Draper Catalogue_1": 240166
  },
  {
    "Astrid #": "ASTRID 003792",
    "HYG Star ID": 95031,
    "Hipparcos Catalogue": 95326,
    "Distance in Parsecs": 29.0951,
    "mag": 12.39,
    "Absolute Visual Magnitude": 10.071,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 8.049249,
    "y": -21.065062,
    "z": -18.384711,
    "vx": 0.00001272,
    "vy": 0.00000692,
    "vz": -0.00000236,
    "rarad": 5.07738046393258,
    "decrad": -0.683980797146273,
    "pmrarad": 4.93249438597222e-7,
    "pmdecrad": -1.04816717e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 95031,
    "lum": 0.00815830617449007,
    "ra": 19.394165,
    "dec": -39.189213,
    "pmra": 101.74,
    "pmdec": -21.62,
    "rv": 0,
    "Asset Name": "ASTRID 003792",
    "Astrium Color": "Green",
    "Background": "Silken Darkness",
    "Expression": "Shocked",
    "Hair": "Grecian",
    "Outfit": "Elven",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001272,
    "y_1": 0.00000692,
    "z_1": -0.00000236,
    "Distance in Parsecs_1": 29.0951,
    "HYG Star ID_1": 95031,
    "Hipparcos Catalogue_1": 95326
  },
  {
    "Astrid #": "ASTRID 003793",
    "HYG Star ID": 105781,
    "Hipparcos Catalogue": 106122,
    "Henry Draper Catalogue": 204814,
    "Distance in Parsecs": 29.1036,
    "mag": 7.93,
    "Absolute Visual Magnitude": 5.61,
    "Spectral Type": "G8V",
    "Color Index": 0.759,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 16.057576,
    "y": -12.345887,
    "z": 20.897559,
    "vx": -0.00004023,
    "vy": 0.00010685,
    "vz": -0.00002873,
    "rarad": 5.62772026961002,
    "decrad": 0.801009952958208,
    "pmrarad": 0.00000206806971714583,
    "pmdecrad": 0.000001706738081,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 105781,
    "lum": 0.496592321450335,
    "ra": 21.496308,
    "dec": 45.89449,
    "pmra": 426.57,
    "pmdec": 352.04,
    "rv": -86.2,
    "Asset Name": "ASTRID 003793",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Unimpressed",
    "Hair": "Manbun",
    "Outfit": "Suit",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00004023,
    "y_1": 0.00010685,
    "z_1": -0.00002873,
    "Distance in Parsecs_1": 29.1036,
    "HYG Star ID_1": 105781,
    "Hipparcos Catalogue_1": 106122,
    "Henry Draper Catalogue_1": 204814
  },
  {
    "Astrid #": "ASTRID 003794",
    "HYG Star ID": 13378,
    "Hipparcos Catalogue": 13412,
    "Distance in Parsecs": 29.1206,
    "mag": 12.08,
    "Absolute Visual Magnitude": 9.759,
    "Spectral Type": "M1",
    "Color Index": 1.456,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.202777,
    "y": 19.891572,
    "z": 1.666437,
    "vx": -0.00000986,
    "vy": 0.00001059,
    "vz": -9.6e-7,
    "rarad": 0.753501786596909,
    "decrad": 0.05725663296008,
    "pmrarad": 4.969825039375e-7,
    "pmdecrad": -3.2918848e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 13378,
    "lum": 0.0108742671954624,
    "var_min": 12.186,
    "var_max": 11.986,
    "ra": 2.878165,
    "dec": 3.280563,
    "pmra": 102.51,
    "pmdec": -6.79,
    "rv": 0,
    "Asset Name": "ASTRID 003794",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Little Crown",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00000986,
    "y_1": 0.00001059,
    "z_1": -9.6e-7,
    "Distance in Parsecs_1": 29.1206,
    "HYG Star ID_1": 13378,
    "Hipparcos Catalogue_1": 13412
  },
  {
    "Astrid #": "ASTRID 003795",
    "HYG Star ID": 57926,
    "Hipparcos Catalogue": 58099,
    "Gliese Catalog": "Gl 452.4",
    "Distance in Parsecs": 29.1206,
    "mag": 10.49,
    "Absolute Visual Magnitude": 8.169,
    "Spectral Type": "M0V",
    "Color Index": 1.361,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -25.527614,
    "y": 0.561736,
    "z": 14.00111,
    "vx": -0.00001622,
    "vy": -0.00002691,
    "vz": -0.00004337,
    "rarad": 3.11959002480578,
    "decrad": 0.501565115497627,
    "pmrarad": 9.36320661256944e-7,
    "pmdecrad": -0.000001564057414,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 57926,
    "lum": 0.0470327096440777,
    "var_min": 10.553,
    "var_max": 10.443,
    "ra": 11.915956,
    "dec": 28.737564,
    "pmra": 193.13,
    "pmdec": -322.61,
    "rv": -7,
    "Asset Name": "ASTRID 003795",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Naughty Dreams",
    "Hair": "Pompadour",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001622,
    "y_1": -0.00002691,
    "z_1": -0.00004337,
    "Distance in Parsecs_1": 29.1206,
    "HYG Star ID_1": 57926,
    "Hipparcos Catalogue_1": 58099,
    "Gliese Catalog_1": "Gl 452.4"
  },
  {
    "Astrid #": "ASTRID 003796",
    "HYG Star ID": 53099,
    "Hipparcos Catalogue": 53253,
    "Henry Draper Catalogue": 94510,
    "Harvard Revised Catalogue": 4257,
    "Gliese Catalog": "Gl 404.1",
    "Distance in Parsecs": 29.129,
    "mag": 3.78,
    "Absolute Visual Magnitude": 1.458,
    "Spectral Type": "K0III-IV...",
    "Color Index": 0.945,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.436596,
    "y": 4.310989,
    "z": -24.92996,
    "vx": -0.0000118,
    "vy": -0.00000823,
    "vz": -0.00000427,
    "rarad": 2.85140349909046,
    "decrad": -1.02718160442073,
    "pmrarad": 3.86638910243055e-7,
    "pmdecrad": 1.87380487e-7,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 53099,
    "lum": 22.7404995089589,
    "ra": 10.891559,
    "dec": -58.853171,
    "pmra": 79.75,
    "pmdec": 38.65,
    "rv": 8.1,
    "Asset Name": "ASTRID 003796",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Bad Dreams",
    "Hair": "Undercut",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.0000118,
    "y_1": -0.00000823,
    "z_1": -0.00000427,
    "Distance in Parsecs_1": 29.129,
    "HYG Star ID_1": 53099,
    "Hipparcos Catalogue_1": 53253,
    "Henry Draper Catalogue_1": 94510,
    "Harvard Revised Catalogue_1": 4257,
    "Gliese Catalog_1": "Gl 404.1"
  },
  {
    "Astrid #": "ASTRID 003797",
    "HYG Star ID": 94300,
    "Hipparcos Catalogue": 94595,
    "Distance in Parsecs": 29.129,
    "mag": 11.28,
    "Absolute Visual Magnitude": 8.958,
    "Color Index": 1.416,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 7.906709,
    "y": -23.28406,
    "z": -15.615221,
    "vx": 0.00004842,
    "vy": 0.0000227,
    "vz": -0.00000933,
    "rarad": 5.03974445491435,
    "decrad": -0.565776279579721,
    "pmrarad": 0.00000182479021224305,
    "pmdecrad": -3.79609111e-7,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 94300,
    "lum": 0.0227404995089589,
    "var_min": 11.353,
    "var_max": 11.223,
    "ra": 19.250406,
    "dec": -32.416593,
    "pmra": 376.39,
    "pmdec": -78.3,
    "rv": 0,
    "Asset Name": "ASTRID 003797",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Angry",
    "Hair": "Heartbreaker",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": 0.00004842,
    "y_1": 0.0000227,
    "z_1": -0.00000933,
    "Distance in Parsecs_1": 29.129,
    "HYG Star ID_1": 94300,
    "Hipparcos Catalogue_1": 94595
  },
  {
    "Astrid #": "ASTRID 003798",
    "HYG Star ID": 4458,
    "Hipparcos Catalogue": 4468,
    "Distance in Parsecs": 29.1375,
    "mag": 10.77,
    "Absolute Visual Magnitude": 8.448,
    "Spectral Type": "M0",
    "Color Index": 1.3,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.542461,
    "y": 4.477319,
    "z": -22.830016,
    "vx": -0.00001863,
    "vy": 0.00001032,
    "vz": -0.00001229,
    "rarad": 0.249889936927562,
    "decrad": -0.90032180051285,
    "pmrarad": 5.01248864326388e-7,
    "pmdecrad": -6.7903004e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 4458,
    "lum": 0.0363747485642632,
    "var_min": 10.846,
    "var_max": 10.706,
    "ra": 0.954509,
    "dec": -51.584639,
    "pmra": 103.39,
    "pmdec": -140.06,
    "rv": 0,
    "Asset Name": "ASTRID 003798",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Mohawk",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00001863,
    "y_1": 0.00001032,
    "z_1": -0.00001229,
    "Distance in Parsecs_1": 29.1375,
    "HYG Star ID_1": 4458,
    "Hipparcos Catalogue_1": 4468
  },
  {
    "Astrid #": "ASTRID 003799",
    "HYG Star ID": 15686,
    "Hipparcos Catalogue": 15725,
    "Henry Draper Catalogue": 21021,
    "Distance in Parsecs": 29.146,
    "mag": 9.28,
    "Absolute Visual Magnitude": 6.957,
    "Spectral Type": "K4V",
    "Color Index": 1.061,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 15.974725,
    "y": 19.480742,
    "z": -14.655996,
    "vx": 0.00000487,
    "vy": 0.00001763,
    "vz": 0.00002874,
    "rarad": 0.883962482840209,
    "decrad": -0.526889993443911,
    "pmrarad": 2.543817381875e-7,
    "pmdecrad": 0.000001140863553,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 15686,
    "lum": 0.143615065290747,
    "ra": 3.376488,
    "dec": -30.188573,
    "pmra": 52.47,
    "pmdec": 235.32,
    "rv": 0,
    "Asset Name": "ASTRID 003799",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Stunned",
    "Hair": "Honest Lad",
    "Outfit": "Turtle School",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": 0.00000487,
    "y_1": 0.00001763,
    "z_1": 0.00002874,
    "Distance in Parsecs_1": 29.146,
    "HYG Star ID_1": 15686,
    "Hipparcos Catalogue_1": 15725,
    "Henry Draper Catalogue_1": 21021
  },
  {
    "Astrid #": "ASTRID 003800",
    "HYG Star ID": 43057,
    "Hipparcos Catalogue": 43177,
    "Henry Draper Catalogue": 75289,
    "Harvard Revised Catalogue": 3497,
    "Distance in Parsecs": 29.146,
    "mag": 6.35,
    "Absolute Visual Magnitude": 4.027,
    "Spectral Type": "G0Ia0:",
    "Color Index": 0.578,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.52996,
    "y": 16.18351,
    "z": -19.402857,
    "vx": 0.00000932,
    "vy": -0.00000605,
    "vz": -0.00003354,
    "rarad": 2.30240877936546,
    "decrad": -0.728444441458136,
    "pmrarad": -9.93868045138889e-8,
    "pmdecrad": -0.000001103823787,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 43057,
    "lum": 2.13402744284897,
    "ra": 8.794554,
    "dec": -41.736792,
    "pmra": -20.5,
    "pmdec": -227.68,
    "rv": 14,
    "Asset Name": "ASTRID 003800",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Lion Mane",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": 0.00000932,
    "y_1": -0.00000605,
    "z_1": -0.00003354,
    "Distance in Parsecs_1": 29.146,
    "HYG Star ID_1": 43057,
    "Hipparcos Catalogue_1": 43177,
    "Henry Draper Catalogue_1": 75289,
    "Harvard Revised Catalogue_1": 3497
  },
  {
    "Astrid #": "ASTRID 003801",
    "HYG Star ID": 38428,
    "Hipparcos Catalogue": 38541,
    "Henry Draper Catalogue": 64090,
    "Gliese Catalog": "GJ 1104",
    "Distance in Parsecs": 29.1545,
    "mag": 8.27,
    "Absolute Visual Magnitude": 5.946,
    "Spectral Type": "sdG2",
    "Color Index": 0.621,
    "Spectral Abbreviation": "s",
    "Star Type": "Red Dwarf",
    "Color In Star Map": "Red",
    "x": -11.929445,
    "y": 22.074095,
    "z": 14.841998,
    "vx": -0.00005209,
    "vy": -0.0001132,
    "vz": -0.00034553,
    "rarad": 2.06625555194391,
    "decrad": 0.53415924219296,
    "pmrarad": 0.00000341793644791666,
    "pmdecrad": -0.000008894149376,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 38428,
    "lum": 0.36441815078886,
    "ra": 7.892515,
    "dec": 30.60507,
    "pmra": 705,
    "pmdec": -1834.55,
    "rv": -235,
    "Asset Name": "ASTRID 003801",
    "Astrium Color": "Red",
    "Background": "Hazy Void",
    "Expression": "Laughing",
    "Hair": "Elegant",
    "Outfit": "Teen",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": -0.00005209,
    "y_1": -0.0001132,
    "z_1": -0.00034553,
    "Distance in Parsecs_1": 29.1545,
    "HYG Star ID_1": 38428,
    "Hipparcos Catalogue_1": 38541,
    "Henry Draper Catalogue_1": 64090,
    "Gliese Catalog_1": "GJ 1104"
  },
  {
    "Astrid #": "ASTRID 003802",
    "HYG Star ID": 53846,
    "Hipparcos Catalogue": 54002,
    "Henry Draper Catalogue": 95743,
    "Distance in Parsecs": 29.163,
    "mag": 9.03,
    "Absolute Visual Magnitude": 6.706,
    "Spectral Type": "K3V",
    "Color Index": 0.971,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -27.886628,
    "y": 7.103638,
    "z": -4.728096,
    "vx": 0.00000833,
    "vy": 0.00002739,
    "vz": -0.00000798,
    "rarad": 2.89216473534341,
    "decrad": -0.162845313340895,
    "pmrarad": -9.808750385e-7,
    "pmdecrad": -2.77410388e-7,
    "con": "Crt",
    "Full Constellation Name Formula 1": "Crater",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Crater",
    "comp": 1,
    "comp_primary": 53846,
    "lum": 0.180967255478879,
    "var": "AB",
    "var_min": 9.059,
    "var_max": 8.999,
    "ra": 11.047256,
    "dec": -9.330349,
    "pmra": -202.32,
    "pmdec": -57.22,
    "rv": 0,
    "Asset Name": "ASTRID 003802",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Anxious Laugh",
    "Hair": "Curly",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Crater",
    "x_1": 0.00000833,
    "y_1": 0.00002739,
    "z_1": -0.00000798,
    "Distance in Parsecs_1": 29.163,
    "HYG Star ID_1": 53846,
    "Hipparcos Catalogue_1": 54002,
    "Henry Draper Catalogue_1": 95743
  },
  {
    "Astrid #": "ASTRID 003803",
    "HYG Star ID": 15973,
    "Hipparcos Catalogue": 16012,
    "Henry Draper Catalogue": 21411,
    "Gliese Catalog": "Gl 141.2",
    "Distance in Parsecs": 29.1715,
    "mag": 7.88,
    "Absolute Visual Magnitude": 5.555,
    "Spectral Type": "G8V",
    "Color Index": 0.716,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.612139,
    "y": 19.659752,
    "z": -14.857398,
    "vx": -0.00000278,
    "vy": 0.00004594,
    "vz": 0.00001711,
    "rarad": 0.89965153479245,
    "decrad": -0.534381592177673,
    "pmrarad": 0.00000105403342289583,
    "pmdecrad": 0.000001102951123,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 15973,
    "lum": 0.522396188999119,
    "ra": 3.436416,
    "dec": -30.61781,
    "pmra": 217.41,
    "pmdec": 227.5,
    "rv": 20.3,
    "Asset Name": "ASTRID 003803",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Worried Vamp",
    "Hair": "Skater Helmet",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00000278,
    "y_1": 0.00004594,
    "z_1": 0.00001711,
    "Distance in Parsecs_1": 29.1715,
    "HYG Star ID_1": 15973,
    "Hipparcos Catalogue_1": 16012,
    "Henry Draper Catalogue_1": 21411,
    "Gliese Catalog_1": "Gl 141.2"
  },
  {
    "Astrid #": "ASTRID 003804",
    "HYG Star ID": 82336,
    "Hipparcos Catalogue": 82587,
    "Henry Draper Catalogue": 152598,
    "Harvard Revised Catalogue": 6279,
    "Distance in Parsecs": 29.1886,
    "mag": 5.34,
    "Absolute Visual Magnitude": 3.014,
    "Spectral Type": "F0V",
    "Color Index": 0.319,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -7.160243,
    "y": -23.778713,
    "z": 15.338404,
    "vx": -0.0000072,
    "vy": 0.00002083,
    "vz": -0.00001388,
    "rarad": 4.41990576130788,
    "decrad": 0.553298585597533,
    "pmrarad": -4.41907669826388e-7,
    "pmdecrad": -8.2903139e-8,
    "flam": 53,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 82336,
    "lum": 5.4250032163115,
    "bf": "53    Her",
    "ra": 16.882796,
    "dec": 31.701674,
    "pmra": -91.15,
    "pmdec": -17.1,
    "rv": -22,
    "Asset Name": "ASTRID 003804",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Angry",
    "Hair": "High Fade",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.0000072,
    "y_1": 0.00002083,
    "z_1": -0.00001388,
    "Distance in Parsecs_1": 29.1886,
    "HYG Star ID_1": 82336,
    "Hipparcos Catalogue_1": 82587,
    "Henry Draper Catalogue_1": 152598,
    "Harvard Revised Catalogue_1": 6279
  },
  {
    "Astrid #": "ASTRID 003805",
    "HYG Star ID": 263,
    "Hipparcos Catalogue": 263,
    "Distance in Parsecs": 29.2056,
    "mag": 12.04,
    "Absolute Visual Magnitude": 9.713,
    "Spectral Type": "M1",
    "Color Index": 1.473,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 29.10489,
    "y": 0.421232,
    "z": 2.386423,
    "vx": 0.00000104,
    "vy": -0.00000345,
    "vz": -0.00001205,
    "rarad": 0.0144718918017321,
    "decrad": 0.081802327805565,
    "pmrarad": -1.18536944895833e-7,
    "pmdecrad": -4.14079364e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 263,
    "lum": 0.0113448824421116,
    "var_min": 12.154,
    "var_max": 11.854,
    "ra": 0.055279,
    "dec": 4.686928,
    "pmra": -24.45,
    "pmdec": -85.41,
    "rv": 0,
    "Asset Name": "ASTRID 003805",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Maniacal",
    "Hair": "High Bun",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000104,
    "y_1": -0.00000345,
    "z_1": -0.00001205,
    "Distance in Parsecs_1": 29.2056,
    "HYG Star ID_1": 263,
    "Hipparcos Catalogue_1": 263
  },
  {
    "Astrid #": "ASTRID 003806",
    "HYG Star ID": 48457,
    "Hipparcos Catalogue": 48594,
    "Henry Draper Catalogue": 86140,
    "Distance in Parsecs": 29.2056,
    "mag": 9,
    "Absolute Visual Magnitude": 6.673,
    "Spectral Type": "K2V",
    "Color Index": 0.953,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -12.945964,
    "y": 7.887965,
    "z": -24.962651,
    "vx": -0.00000536,
    "vy": 0.00006524,
    "vz": 0.00005809,
    "rarad": 2.59437823995654,
    "decrad": -1.02502849835672,
    "pmrarad": -0.00000181223353791666,
    "pmdecrad": 0.000002159844946,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 48457,
    "lum": 0.186552038910059,
    "ra": 9.909795,
    "dec": -58.729807,
    "pmra": -373.8,
    "pmdec": 445.5,
    "rv": -29,
    "Asset Name": "ASTRID 003806",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Anxious Laugh",
    "Hair": "Wavy",
    "Outfit": "Scientist",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00000536,
    "y_1": 0.00006524,
    "z_1": 0.00005809,
    "Distance in Parsecs_1": 29.2056,
    "HYG Star ID_1": 48457,
    "Hipparcos Catalogue_1": 48594,
    "Henry Draper Catalogue_1": 86140
  },
  {
    "Astrid #": "ASTRID 003807",
    "HYG Star ID": 57000,
    "Hipparcos Catalogue": 57172,
    "Henry Draper Catalogue": 101930,
    "Gliese Catalog": "NN 3683",
    "Distance in Parsecs": 29.2056,
    "mag": 8.21,
    "Absolute Visual Magnitude": 5.883,
    "Spectral Type": "K1V",
    "Color Index": 0.908,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -15.433557,
    "y": 1.112935,
    "z": -24.769614,
    "vx": -0.00004177,
    "vy": 8.8e-7,
    "vz": 0.00002607,
    "rarad": 3.06960535831795,
    "decrad": -1.01241116305824,
    "pmrarad": 7.27220520833333e-8,
    "pmdecrad": 0.000001684679058,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 57000,
    "lum": 0.386189089448989,
    "ra": 11.725029,
    "dec": -58.006887,
    "pmra": 15,
    "pmdec": 347.49,
    "rv": 0,
    "Asset Name": "ASTRID 003807",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Grecian",
    "Outfit": "Super",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00004177,
    "y_1": 8.8e-7,
    "z_1": 0.00002607,
    "Distance in Parsecs_1": 29.2056,
    "HYG Star ID_1": 57000,
    "Hipparcos Catalogue_1": 57172,
    "Henry Draper Catalogue_1": 101930,
    "Gliese Catalog_1": "NN 3683"
  },
  {
    "Astrid #": "ASTRID 003808",
    "HYG Star ID": 73254,
    "Hipparcos Catalogue": 73481,
    "Distance in Parsecs": 29.2227,
    "mag": 9.74,
    "Absolute Visual Magnitude": 7.411,
    "Spectral Type": "K5",
    "Color Index": 1.552,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.233454,
    "y": -5.280124,
    "z": 28.261235,
    "vx": -0.00004001,
    "vy": 0.00001907,
    "vz": -0.00000385,
    "rarad": 3.93146651817208,
    "decrad": 1.31356763265827,
    "pmrarad": -0.00000143175176141666,
    "pmdecrad": -5.17296197e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 73254,
    "lum": 0.0945366045957965,
    "ra": 15.017096,
    "dec": 75.261881,
    "pmra": -295.32,
    "pmdec": -106.7,
    "rv": 0,
    "Asset Name": "ASTRID 003808",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Smoulder",
    "Hair": "Bangs",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": -0.00004001,
    "y_1": 0.00001907,
    "z_1": -0.00000385,
    "Distance in Parsecs_1": 29.2227,
    "HYG Star ID_1": 73254,
    "Hipparcos Catalogue_1": 73481
  },
  {
    "Astrid #": "ASTRID 003809",
    "HYG Star ID": 83088,
    "Hipparcos Catalogue": 83343,
    "Distance in Parsecs": 29.2312,
    "mag": 8.77,
    "Absolute Visual Magnitude": 6.441,
    "Spectral Type": "K2",
    "Color Index": 1.007,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.740612,
    "y": -26.062654,
    "z": 11.391458,
    "vx": -0.00001655,
    "vy": 0.00000907,
    "vz": 0.00001096,
    "rarad": 4.45930406942346,
    "decrad": 0.400308023228972,
    "pmrarad": -6.25894461597222e-7,
    "pmdecrad": 4.07340454e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 83088,
    "lum": 0.230993628017318,
    "ra": 17.033287,
    "dec": 22.93596,
    "pmra": -129.1,
    "pmdec": 84.02,
    "rv": 0,
    "Asset Name": "ASTRID 003809",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Laugh",
    "Hair": "Little Crown",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001655,
    "y_1": 0.00000907,
    "z_1": 0.00001096,
    "Distance in Parsecs_1": 29.2312,
    "HYG Star ID_1": 83088,
    "Hipparcos Catalogue_1": 83343
  },
  {
    "Astrid #": "ASTRID 003810",
    "HYG Star ID": 2738,
    "Hipparcos Catalogue": 2743,
    "Henry Draper Catalogue": 3222,
    "Distance in Parsecs": 29.2398,
    "mag": 8.55,
    "Absolute Visual Magnitude": 6.22,
    "Spectral Type": "K3V",
    "Color Index": 0.854,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.806339,
    "y": 1.973764,
    "z": -26.211994,
    "vx": -0.0000855,
    "vy": 0.00011327,
    "vz": -0.00003496,
    "rarad": 0.152873750799953,
    "decrad": -1.11168969376563,
    "pmrarad": 0.00000427426285188194,
    "pmdecrad": -0.000002592201787,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 2738,
    "lum": 0.283139199579937,
    "ra": 0.583935,
    "dec": -63.695128,
    "pmra": 881.63,
    "pmdec": -534.68,
    "rv": 1.5,
    "Asset Name": "ASTRID 003810",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Guilty Laugh",
    "Hair": "Afro",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.0000855,
    "y_1": 0.00011327,
    "z_1": -0.00003496,
    "Distance in Parsecs_1": 29.2398,
    "HYG Star ID_1": 2738,
    "Hipparcos Catalogue_1": 2743,
    "Henry Draper Catalogue_1": 3222
  },
  {
    "Astrid #": "ASTRID 003811",
    "HYG Star ID": 114524,
    "Hipparcos Catalogue": 114885,
    "Henry Draper Catalogue": 219437,
    "Distance in Parsecs": 29.2569,
    "mag": 9.22,
    "Absolute Visual Magnitude": 6.889,
    "Spectral Type": "K3V",
    "Color Index": 1.007,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.472163,
    "y": -3.951508,
    "z": -20.524175,
    "vx": 0.00002598,
    "vy": 0.00006629,
    "vz": 0.00001461,
    "rarad": 6.09250314264813,
    "decrad": -0.777522454479108,
    "pmrarad": 0.00000239333121543055,
    "pmdecrad": 6.66230959e-7,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 114524,
    "lum": 0.15289736466907,
    "ra": 23.271648,
    "dec": -44.548755,
    "pmra": 493.66,
    "pmdec": 137.42,
    "rv": -1,
    "Asset Name": "ASTRID 003811",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh Snap",
    "Hair": "Medium Bob",
    "Outfit": "Rider",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": 0.00002598,
    "y_1": 0.00006629,
    "z_1": 0.00001461,
    "Distance in Parsecs_1": 29.2569,
    "HYG Star ID_1": 114524,
    "Hipparcos Catalogue_1": 114885,
    "Henry Draper Catalogue_1": 219437
  },
  {
    "Astrid #": "ASTRID 003812",
    "HYG Star ID": 39501,
    "Hipparcos Catalogue": 39612,
    "Gliese Catalog": "GJ 1107",
    "Distance in Parsecs": 29.2654,
    "mag": 10.16,
    "Absolute Visual Magnitude": 7.828,
    "Spectral Type": "M0V:p",
    "Color Index": 1.311,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -12.638445,
    "y": 20.684358,
    "z": 16.397809,
    "vx": -0.00002507,
    "vy": 0.00000101,
    "vz": -0.00002954,
    "rarad": 2.1192742140065,
    "decrad": 0.594765646874526,
    "pmrarad": 7.13257886833333e-7,
    "pmdecrad": -0.000001102757197,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 39501,
    "lum": 0.064387268290146,
    "ra": 8.095031,
    "dec": 34.077561,
    "pmra": 147.12,
    "pmdec": -227.46,
    "rv": -4.9,
    "Asset Name": "ASTRID 003812",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Firm",
    "Hair": "Wavy",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00002507,
    "y_1": 0.00000101,
    "z_1": -0.00002954,
    "Distance in Parsecs_1": 29.2654,
    "HYG Star ID_1": 39501,
    "Hipparcos Catalogue_1": 39612,
    "Gliese Catalog_1": "GJ 1107"
  },
  {
    "Astrid #": "ASTRID 003813",
    "HYG Star ID": 5213,
    "Hipparcos Catalogue": 5224,
    "Henry Draper Catalogue": 6673,
    "Gliese Catalog": "Gl 52.1",
    "Distance in Parsecs": 29.274,
    "mag": 8.84,
    "Absolute Visual Magnitude": 6.508,
    "Spectral Type": "K1V",
    "Color Index": 0.925,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.649981,
    "y": 5.294449,
    "z": -22.746837,
    "vx": -0.00001346,
    "vy": 0.00006848,
    "vz": -0.00000596,
    "rarad": 0.291416243630683,
    "decrad": -0.889933712365833,
    "pmrarad": 0.00000237287207811111,
    "pmdecrad": 5.1729619e-8,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 5213,
    "lum": 0.217170084334799,
    "ra": 1.113128,
    "dec": -50.989446,
    "pmra": 489.44,
    "pmdec": 10.67,
    "rv": 8.7,
    "Asset Name": "ASTRID 003813",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Audiophile",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00001346,
    "y_1": 0.00006848,
    "z_1": -0.00000596,
    "Distance in Parsecs_1": 29.274,
    "HYG Star ID_1": 5213,
    "Hipparcos Catalogue_1": 5224,
    "Henry Draper Catalogue_1": 6673,
    "Gliese Catalog_1": "Gl 52.1"
  },
  {
    "Astrid #": "ASTRID 003814",
    "HYG Star ID": 5751,
    "Hipparcos Catalogue": 5763,
    "Distance in Parsecs": 29.274,
    "mag": 9.82,
    "Absolute Visual Magnitude": 7.488,
    "Spectral Type": "K5",
    "Color Index": 1.22,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 26.619501,
    "y": 8.904319,
    "z": 8.31158,
    "vx": 0.00000159,
    "vy": 0.00000251,
    "vz": -0.0000078,
    "rarad": 0.322803418232436,
    "decrad": 0.287883648265417,
    "pmrarad": 6.41408499375e-8,
    "pmdecrad": -2.77798238e-7,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 5751,
    "lum": 0.0880643228418064,
    "ra": 1.233018,
    "dec": 16.494518,
    "pmra": 13.23,
    "pmdec": -57.3,
    "rv": 0,
    "Asset Name": "ASTRID 003814",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Mortified",
    "Hair": "Undercut",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00000159,
    "y_1": 0.00000251,
    "z_1": -0.0000078,
    "Distance in Parsecs_1": 29.274,
    "HYG Star ID_1": 5751,
    "Hipparcos Catalogue_1": 5763
  },
  {
    "Astrid #": "ASTRID 003815",
    "HYG Star ID": 5031,
    "Hipparcos Catalogue": 5041,
    "Distance in Parsecs": 29.2826,
    "mag": 10.02,
    "Absolute Visual Magnitude": 7.687,
    "Spectral Type": "K5",
    "Color Index": 1.28,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 25.256362,
    "y": 7.306694,
    "z": 12.891821,
    "vx": -0.00001333,
    "vy": 0.00004304,
    "vz": 0.00000172,
    "rarad": 0.281611094215307,
    "decrad": 0.455883035261943,
    "pmrarad": 0.00000153865317797916,
    "pmdecrad": 6.5401365e-8,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 5031,
    "lum": 0.073316208902721,
    "var_min": 10.095,
    "var_max": 9.975,
    "ra": 1.075675,
    "dec": 26.120174,
    "pmra": 317.37,
    "pmdec": 13.49,
    "rv": 0,
    "Asset Name": "ASTRID 003815",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Bandana",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": -0.00001333,
    "y_1": 0.00004304,
    "z_1": 0.00000172,
    "Distance in Parsecs_1": 29.2826,
    "HYG Star ID_1": 5031,
    "Hipparcos Catalogue_1": 5041
  },
  {
    "Astrid #": "ASTRID 003816",
    "HYG Star ID": 22068,
    "Hipparcos Catalogue": 22121,
    "Henry Draper Catalogue": 30440,
    "Distance in Parsecs": 29.2912,
    "mag": 8.89,
    "Absolute Visual Magnitude": 6.556,
    "Spectral Type": "K3V",
    "Color Index": 0.955,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.727722,
    "y": 20.005899,
    "z": -20.309508,
    "vx": -0.00001816,
    "vy": 0.00000827,
    "vz": 0.00000214,
    "rarad": 1.24638763421071,
    "decrad": -0.766149165669618,
    "pmrarad": 6.776240813125e-7,
    "pmdecrad": 1.01180615e-7,
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 22068,
    "lum": 0.207778209750426,
    "ra": 4.76085,
    "dec": -43.897114,
    "pmra": 139.77,
    "pmdec": 20.87,
    "rv": 0,
    "Asset Name": "ASTRID 003816",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Hachimaki",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": -0.00001816,
    "y_1": 0.00000827,
    "z_1": 0.00000214,
    "Distance in Parsecs_1": 29.2912,
    "HYG Star ID_1": 22068,
    "Hipparcos Catalogue_1": 22121,
    "Henry Draper Catalogue_1": 30440
  },
  {
    "Astrid #": "ASTRID 003817",
    "HYG Star ID": 62876,
    "Hipparcos Catalogue": 63076,
    "Henry Draper Catalogue": 112429,
    "Harvard Revised Catalogue": 4916,
    "Distance in Parsecs": 29.2912,
    "mag": 5.23,
    "Absolute Visual Magnitude": 2.896,
    "Spectral Type": "A5n",
    "Color Index": 0.303,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -11.820552,
    "y": -2.918495,
    "z": 26.640872,
    "vx": -0.00000768,
    "vy": -0.00000122,
    "vz": 0.00000658,
    "rarad": 3.38365181058985,
    "decrad": 1.14211682024403,
    "pmrarad": -2.24468734097222e-8,
    "pmdecrad": -1.47286396e-7,
    "flam": 8,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 62876,
    "lum": 6.047835918818,
    "var": "IR",
    "var_min": 5.253,
    "var_max": 5.193,
    "bf": "8    Dra",
    "ra": 12.924598,
    "dec": 65.438474,
    "pmra": -4.63,
    "pmdec": -30.38,
    "rv": 9,
    "Asset Name": "ASTRID 003817",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Oh Snap",
    "Hair": "Medium Bob",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000768,
    "y_1": -0.00000122,
    "z_1": 0.00000658,
    "Distance in Parsecs_1": 29.2912,
    "HYG Star ID_1": 62876,
    "Hipparcos Catalogue_1": 63076,
    "Henry Draper Catalogue_1": 112429,
    "Harvard Revised Catalogue_1": 4916
  },
  {
    "Astrid #": "ASTRID 003818",
    "HYG Star ID": 49288,
    "Hipparcos Catalogue": 49429,
    "Henry Draper Catalogue": 87445,
    "Distance in Parsecs": 29.2997,
    "mag": 9.12,
    "Absolute Visual Magnitude": 6.786,
    "Spectral Type": "K0",
    "Color Index": 1.097,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -23.015647,
    "y": 12.569127,
    "z": 13.067878,
    "vx": 0.00000776,
    "vy": 0.00002588,
    "vz": -0.00001123,
    "rarad": 2.64174010141522,
    "decrad": 0.462299307208308,
    "pmrarad": -9.02238259513888e-7,
    "pmdecrad": -4.28090479e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 49288,
    "lum": 0.168112497447695,
    "ra": 10.090704,
    "dec": 26.487799,
    "pmra": -186.1,
    "pmdec": -88.3,
    "rv": 0,
    "Asset Name": "ASTRID 003818",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Angry",
    "Hair": "Lion Mane",
    "Outfit": "Force Sensitive",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000776,
    "y_1": 0.00002588,
    "z_1": -0.00001123,
    "Distance in Parsecs_1": 29.2997,
    "HYG Star ID_1": 49288,
    "Hipparcos Catalogue_1": 49429,
    "Henry Draper Catalogue_1": 87445
  },
  {
    "Astrid #": "ASTRID 003819",
    "HYG Star ID": 51295,
    "Hipparcos Catalogue": 51443,
    "Distance in Parsecs": 29.2997,
    "mag": 9.64,
    "Absolute Visual Magnitude": 7.306,
    "Spectral Type": "K5V",
    "Color Index": 1.163,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.247813,
    "y": 10.410765,
    "z": -10.613027,
    "vx": 0.00002649,
    "vy": 0.00005769,
    "vz": -0.00000642,
    "rarad": 2.75049242896603,
    "decrad": -0.370651808700691,
    "pmrarad": -0.00000216503245325694,
    "pmdecrad": -2.35280079e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 51295,
    "lum": 0.104135786152843,
    "ra": 10.506107,
    "dec": -21.236784,
    "pmra": -446.57,
    "pmdec": -48.53,
    "rv": 0,
    "Asset Name": "ASTRID 003819",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Manbun",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00002649,
    "y_1": 0.00005769,
    "z_1": -0.00000642,
    "Distance in Parsecs_1": 29.2997,
    "HYG Star ID_1": 51295,
    "Hipparcos Catalogue_1": 51443
  },
  {
    "Astrid #": "ASTRID 003820",
    "HYG Star ID": 71025,
    "Hipparcos Catalogue": 71251,
    "Henry Draper Catalogue": 128332,
    "Harvard Revised Catalogue": 5451,
    "Distance in Parsecs": 29.2997,
    "mag": 6.46,
    "Absolute Visual Magnitude": 4.126,
    "Spectral Type": "F7V",
    "Color Index": 0.526,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.45518,
    "y": -9.930882,
    "z": 24.590791,
    "vx": 0.00000713,
    "vy": -0.00003377,
    "vz": -0.00003684,
    "rarad": 3.81469541930821,
    "decrad": 0.995976646344323,
    "pmrarad": 0.00000105306379553472,
    "pmdecrad": -0.000001127046363,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 71025,
    "lum": 1.94804955329076,
    "ra": 14.571063,
    "dec": 57.065258,
    "pmra": 217.21,
    "pmdec": -232.47,
    "rv": -22,
    "Asset Name": "ASTRID 003820",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Naughty Dreams",
    "Hair": "Charmer",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000713,
    "y_1": -0.00003377,
    "z_1": -0.00003684,
    "Distance in Parsecs_1": 29.2997,
    "HYG Star ID_1": 71025,
    "Hipparcos Catalogue_1": 71251,
    "Henry Draper Catalogue_1": 128332,
    "Harvard Revised Catalogue_1": 5451
  },
  {
    "Astrid #": "ASTRID 003821",
    "HYG Star ID": 84748,
    "Hipparcos Catalogue": 85007,
    "Henry Draper Catalogue": 157466,
    "Distance in Parsecs": 29.3083,
    "mag": 6.88,
    "Absolute Visual Magnitude": 4.545,
    "Spectral Type": "F8V",
    "Color Index": 0.526,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.33564,
    "y": -26.232704,
    "z": 12.330417,
    "vx": 0.00000282,
    "vy": -0.00003754,
    "vz": -0.00000813,
    "rarad": 4.54859327553642,
    "decrad": 0.434228270275817,
    "pmrarad": 3.03832733604166e-7,
    "pmdecrad": -7.76768478e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 84748,
    "lum": 1.32434153519466,
    "ra": 17.374346,
    "dec": 24.879447,
    "pmra": 62.67,
    "pmdec": -160.22,
    "rv": 29.1,
    "Asset Name": "ASTRID 003821",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Malevolent",
    "Hair": "Curly",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000282,
    "y_1": -0.00003754,
    "z_1": -0.00000813,
    "Distance in Parsecs_1": 29.3083,
    "HYG Star ID_1": 84748,
    "Hipparcos Catalogue_1": 85007,
    "Henry Draper Catalogue_1": 157466
  },
  {
    "Astrid #": "ASTRID 003822",
    "HYG Star ID": 109461,
    "Hipparcos Catalogue": 109807,
    "Distance in Parsecs": 29.3083,
    "mag": 10.4,
    "Absolute Visual Magnitude": 8.065,
    "Spectral Type": "K7",
    "Color Index": 1.252,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 26.225101,
    "y": -13.011725,
    "z": 1.384034,
    "vx": 0.00001332,
    "vy": 0.00002715,
    "vz": 0.00000281,
    "rarad": 5.82261808407915,
    "decrad": 0.0472408260912775,
    "pmrarad": 0.00000103178047495833,
    "pmdecrad": 9.5896146e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 109461,
    "lum": 0.0517606831950567,
    "ra": 22.240763,
    "dec": 2.7067,
    "pmra": 212.82,
    "pmdec": 19.78,
    "rv": 0,
    "Asset Name": "ASTRID 003822",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00001332,
    "y_1": 0.00002715,
    "z_1": 0.00000281,
    "Distance in Parsecs_1": 29.3083,
    "HYG Star ID_1": 109461,
    "Hipparcos Catalogue_1": 109807
  },
  {
    "Astrid #": "ASTRID 003823",
    "HYG Star ID": 112264,
    "Hipparcos Catalogue": 112610,
    "Distance in Parsecs": 29.3083,
    "mag": 10.95,
    "Absolute Visual Magnitude": 8.615,
    "Spectral Type": "M",
    "Color Index": 1.59,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 25.339917,
    "y": -8.161068,
    "z": 12.258139,
    "vx": 0.00000714,
    "vy": 0.00002395,
    "vz": 0.00000119,
    "rarad": 5.97161068249486,
    "decrad": 0.431515718547642,
    "pmrarad": 8.52399413152777e-7,
    "pmdecrad": 4.4748302e-8,
    "con": "Peg",
    "Full Constellation Name Formula 1": "Pegasus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pegasus",
    "comp": 1,
    "comp_primary": 112264,
    "lum": 0.0311888958409393,
    "var_min": 11.009,
    "var_max": 10.869,
    "ra": 22.809873,
    "dec": 24.724029,
    "pmra": 175.82,
    "pmdec": 9.23,
    "rv": 0,
    "Asset Name": "ASTRID 003823",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Slick",
    "Outfit": "Garb",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pegasus",
    "x_1": 0.00000714,
    "y_1": 0.00002395,
    "z_1": 0.00000119,
    "Distance in Parsecs_1": 29.3083,
    "HYG Star ID_1": 112264,
    "Hipparcos Catalogue_1": 112610
  },
  {
    "Astrid #": "ASTRID 003824",
    "HYG Star ID": 59525,
    "Hipparcos Catalogue": 59707,
    "Henry Draper Catalogue": 106453,
    "Distance in Parsecs": 29.3169,
    "mag": 7.47,
    "Absolute Visual Magnitude": 5.134,
    "Spectral Type": "K0/K1V+...",
    "Color Index": 0.711,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -26.563795,
    "y": -1.706393,
    "z": -12.285505,
    "vx": -7.5e-7,
    "vy": 0.0000416,
    "vz": -0.00000417,
    "rarad": 3.20574329976934,
    "decrad": -0.43240849735624,
    "pmrarad": -0.00000141774064604861,
    "pmdecrad": -1.56497856e-7,
    "con": "Crv",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 59525,
    "lum": 0.769839161929434,
    "ra": 12.245037,
    "dec": -24.775182,
    "pmra": -292.43,
    "pmdec": -32.28,
    "rv": 0,
    "Asset Name": "ASTRID 003824",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Oh Snap",
    "Hair": "Pompadour",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -7.5e-7,
    "y_1": 0.0000416,
    "z_1": -0.00000417,
    "Distance in Parsecs_1": 29.3169,
    "HYG Star ID_1": 59525,
    "Hipparcos Catalogue_1": 59707,
    "Henry Draper Catalogue_1": 106453
  },
  {
    "Astrid #": "ASTRID 003825",
    "HYG Star ID": 95122,
    "Hipparcos Catalogue": 95417,
    "Gliese Catalog": "Gl 757",
    "Distance in Parsecs": 29.3169,
    "mag": 10.91,
    "Absolute Visual Magnitude": 8.574,
    "Spectral Type": "K4",
    "Color Index": 1.391,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.800054,
    "y": -25.341269,
    "z": -11.011797,
    "vx": -0.0000398,
    "vy": 0.00001056,
    "vz": -0.00005973,
    "rarad": 5.08139840616826,
    "decrad": -0.385057673588641,
    "pmrarad": -0.00000113620934175,
    "pmdecrad": -0.000002198581559,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 95122,
    "lum": 0.0323891834996877,
    "var_min": 10.976,
    "var_max": 10.806,
    "ra": 19.409512,
    "dec": -22.06218,
    "pmra": -234.36,
    "pmdec": -453.49,
    "rv": 0,
    "Asset Name": "ASTRID 003825",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Headband",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.0000398,
    "y_1": 0.00001056,
    "z_1": -0.00005973,
    "Distance in Parsecs_1": 29.3169,
    "HYG Star ID_1": 95122,
    "Hipparcos Catalogue_1": 95417,
    "Gliese Catalog_1": "Gl 757"
  },
  {
    "Astrid #": "ASTRID 003826",
    "HYG Star ID": 13857,
    "Hipparcos Catalogue": 13891,
    "Henry Draper Catalogue": 18450,
    "Distance in Parsecs": 29.3255,
    "mag": 8.21,
    "Absolute Visual Magnitude": 5.874,
    "Spectral Type": "K2V",
    "Color Index": 0.871,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.604052,
    "y": 18.42211,
    "z": 13.210496,
    "vx": 0.00001715,
    "vy": 0.00001377,
    "vz": 0.00003089,
    "rarad": 0.780484400867151,
    "decrad": 0.467295777226422,
    "pmrarad": -7.79580398333333e-8,
    "pmdecrad": 6.04368734e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 13857,
    "lum": 0.389403633879717,
    "ra": 2.981231,
    "dec": 26.774076,
    "pmra": -16.08,
    "pmdec": 124.66,
    "rv": 32.7,
    "Asset Name": "ASTRID 003826",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Naughty Dreams",
    "Hair": "Fedora",
    "Outfit": "Floral Polo",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00001715,
    "y_1": 0.00001377,
    "z_1": 0.00003089,
    "Distance in Parsecs_1": 29.3255,
    "HYG Star ID_1": 13857,
    "Hipparcos Catalogue_1": 13891,
    "Henry Draper Catalogue_1": 18450
  },
  {
    "Astrid #": "ASTRID 003827",
    "HYG Star ID": 30271,
    "Hipparcos Catalogue": 30344,
    "Henry Draper Catalogue": 44821,
    "Distance in Parsecs": 29.3255,
    "mag": 7.37,
    "Absolute Visual Magnitude": 5.034,
    "Spectral Type": "K0/1V (+G)",
    "Color Index": 0.663,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -2.667836,
    "y": 26.539408,
    "z": -12.18718,
    "vx": 4e-7,
    "vy": 2.8e-7,
    "vz": 5.3e-7,
    "rarad": 1.67098338316062,
    "decrad": -0.428583736497308,
    "pmrarad": -1.44959290486111e-8,
    "pmdecrad": 1.9828879e-8,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 30271,
    "lum": 0.844111855592768,
    "ra": 6.382686,
    "dec": -24.556039,
    "pmra": -2.99,
    "pmdec": 4.09,
    "rv": 0,
    "Asset Name": "ASTRID 003827",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Scared",
    "Hair": "Bob",
    "Outfit": "Warlock",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 4e-7,
    "y_1": 2.8e-7,
    "z_1": 5.3e-7,
    "Distance in Parsecs_1": 29.3255,
    "HYG Star ID_1": 30271,
    "Hipparcos Catalogue_1": 30344,
    "Henry Draper Catalogue_1": 44821
  },
  {
    "Astrid #": "ASTRID 003828",
    "HYG Star ID": 111624,
    "Hipparcos Catalogue": 111977,
    "Henry Draper Catalogue": 215065,
    "Distance in Parsecs": 29.3255,
    "mag": 7.46,
    "Absolute Visual Magnitude": 5.124,
    "Spectral Type": "G5",
    "Color Index": 0.635,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.99363,
    "y": -3.951887,
    "z": 26.89764,
    "vx": -0.00005518,
    "vy": 0.00005266,
    "vz": -0.000022,
    "rarad": 5.93808453142927,
    "decrad": 1.16105288084766,
    "pmrarad": 0.000001053306202375,
    "pmdecrad": 0.000001882725447,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 111624,
    "lum": 0.776962395992138,
    "ra": 22.681812,
    "dec": 66.52343,
    "pmra": 217.26,
    "pmdec": 388.34,
    "rv": -46.9,
    "Asset Name": "ASTRID 003828",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bad Dreams",
    "Hair": "Slick",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00005518,
    "y_1": 0.00005266,
    "z_1": -0.000022,
    "Distance in Parsecs_1": 29.3255,
    "HYG Star ID_1": 111624,
    "Hipparcos Catalogue_1": 111977,
    "Henry Draper Catalogue_1": 215065
  },
  {
    "Astrid #": "ASTRID 003829",
    "HYG Star ID": 118376,
    "Gliese Catalog": "NN 3349",
    "Distance in Parsecs": 29.3255,
    "mag": 12.15,
    "Absolute Visual Magnitude": 9.814,
    "Spectral Type": "M3",
    "Color Index": 1.48,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 3.257141,
    "y": 24.632075,
    "z": 15.576806,
    "vx": 0.00003771,
    "vy": 0.00004562,
    "vz": -0.00008,
    "rarad": 1.43933702920864,
    "decrad": 0.5599800512254,
    "pmrarad": -0.0000010711958271875,
    "pmdecrad": -0.000003220195492,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 118376,
    "lum": 0.0103371305235058,
    "ra": 5.497862,
    "dec": 32.084494,
    "pmra": -220.95,
    "pmdec": -664.21,
    "rv": 0,
    "Asset Name": "ASTRID 003829",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Arrogant",
    "Hair": "Afro",
    "Outfit": "River Spirit",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003771,
    "y_1": 0.00004562,
    "z_1": -0.00008,
    "Distance in Parsecs_1": 29.3255,
    "HYG Star ID_1": 118376,
    "Gliese Catalog_1": "NN 3349"
  },
  {
    "Astrid #": "ASTRID 003830",
    "HYG Star ID": 81550,
    "Hipparcos Catalogue": 81800,
    "Henry Draper Catalogue": 151044,
    "Distance in Parsecs": 29.3341,
    "mag": 6.48,
    "Absolute Visual Magnitude": 4.143,
    "Spectral Type": "F8V",
    "Color Index": 0.541,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.266412,
    "y": -17.810197,
    "z": 22.450208,
    "vx": 0.00001674,
    "vy": -0.0000096,
    "vz": -0.00001965,
    "rarad": 4.37406919426032,
    "decrad": 0.871555309267598,
    "pmrarad": 6.46886893965277e-7,
    "pmdecrad": -5.22483703e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 81550,
    "lum": 1.91778536448265,
    "ra": 16.707714,
    "dec": 49.936441,
    "pmra": 133.43,
    "pmdec": -107.77,
    "rv": -12.5,
    "Asset Name": "ASTRID 003830",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Big Laugh",
    "Hair": "Fire",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00001674,
    "y_1": -0.0000096,
    "z_1": -0.00001965,
    "Distance in Parsecs_1": 29.3341,
    "HYG Star ID_1": 81550,
    "Hipparcos Catalogue_1": 81800,
    "Henry Draper Catalogue_1": 151044
  },
  {
    "Astrid #": "ASTRID 003831",
    "HYG Star ID": 107577,
    "Hipparcos Catalogue": 107920,
    "Henry Draper Catalogue": 207966,
    "Gliese Catalog": "Gl 838.3A",
    "Distance in Parsecs": 29.3341,
    "mag": 7.85,
    "Absolute Visual Magnitude": 5.513,
    "Spectral Type": "G5",
    "Color Index": 0.798,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.380722,
    "y": -11.498693,
    "z": 19.758672,
    "vx": -0.00000481,
    "vy": -0.00002638,
    "vz": -0.00004975,
    "rarad": 5.72416846195785,
    "decrad": 0.739040098354823,
    "pmrarad": -8.49490531069444e-7,
    "pmdecrad": -0.000001481445163,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 1,
    "comp_primary": 107577,
    "base": "Gl 838.3",
    "lum": 0.543000213065737,
    "ra": 21.864713,
    "dec": 42.343879,
    "pmra": -175.22,
    "pmdec": -305.57,
    "rv": -25.6,
    "Asset Name": "ASTRID 003831",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Bob",
    "Outfit": "Football",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": -0.00000481,
    "y_1": -0.00002638,
    "z_1": -0.00004975,
    "Distance in Parsecs_1": 29.3341,
    "HYG Star ID_1": 107577,
    "Hipparcos Catalogue_1": 107920,
    "Henry Draper Catalogue_1": 207966,
    "Gliese Catalog_1": "Gl 838.3A"
  },
  {
    "Astrid #": "ASTRID 003832",
    "HYG Star ID": 62339,
    "Hipparcos Catalogue": 62534,
    "Henry Draper Catalogue": 111232,
    "Distance in Parsecs": 29.3427,
    "mag": 7.59,
    "Absolute Visual Magnitude": 5.252,
    "Spectral Type": "G5V",
    "Color Index": 0.701,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -10.545819,
    "y": -2.283101,
    "z": -27.287742,
    "vx": -0.00005131,
    "vy": -0.00001516,
    "vz": -0.0000913,
    "rarad": 3.3547938081577,
    "decrad": -1.19424417504055,
    "pmrarad": 1.34875165930555e-7,
    "pmdecrad": 5.46966794e-7,
    "con": "Mus",
    "Full Constellation Name Formula 1": "Musca",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Musca",
    "comp": 1,
    "comp_primary": 62339,
    "lum": 0.690557744219683,
    "ra": 12.814368,
    "dec": -68.425151,
    "pmra": 27.82,
    "pmdec": 112.82,
    "rv": 102.2,
    "Asset Name": "ASTRID 003832",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Smoulder",
    "Hair": "Ponytail",
    "Outfit": "Viking",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Musca",
    "x_1": -0.00005131,
    "y_1": -0.00001516,
    "z_1": -0.0000913,
    "Distance in Parsecs_1": 29.3427,
    "HYG Star ID_1": 62339,
    "Hipparcos Catalogue_1": 62534,
    "Henry Draper Catalogue_1": 111232
  },
  {
    "Astrid #": "ASTRID 003833",
    "HYG Star ID": 6749,
    "Hipparcos Catalogue": 6762,
    "Henry Draper Catalogue": 8828,
    "Distance in Parsecs": 29.3513,
    "mag": 7.96,
    "Absolute Visual Magnitude": 5.622,
    "Spectral Type": "G5",
    "Color Index": 0.738,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 27.26047,
    "y": 10.879379,
    "z": -0.080728,
    "vx": -0.00001254,
    "vy": 0.00004072,
    "vz": -0.00005102,
    "rarad": 0.379721585606259,
    "decrad": -0.00275043027177,
    "pmrarad": 0.00000144692642961805,
    "pmdecrad": -0.000001738202488,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 6749,
    "lum": 0.491133999646997,
    "ra": 1.45043,
    "dec": -0.157588,
    "pmra": 298.45,
    "pmdec": -358.53,
    "rv": 3.5,
    "Asset Name": "ASTRID 003833",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Lovestruck",
    "Hair": "Mohawk",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00001254,
    "y_1": 0.00004072,
    "z_1": -0.00005102,
    "Distance in Parsecs_1": 29.3513,
    "HYG Star ID_1": 6749,
    "Hipparcos Catalogue_1": 6762,
    "Henry Draper Catalogue_1": 8828
  },
  {
    "Astrid #": "ASTRID 003834",
    "HYG Star ID": 114337,
    "Hipparcos Catalogue": 114699,
    "Henry Draper Catalogue": 219077,
    "Harvard Revised Catalogue": 8829,
    "Distance in Parsecs": 29.3513,
    "mag": 6.12,
    "Absolute Visual Magnitude": 3.782,
    "Spectral Type": "G5IV",
    "Color Index": 0.787,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 13.192886,
    "y": -2.677543,
    "z": -26.081865,
    "vx": -0.0000511,
    "vy": 0.0000797,
    "vz": -0.00000411,
    "rarad": 6.08292751527316,
    "decrad": -1.09432150343295,
    "pmrarad": 0.00000231488836191666,
    "pmdecrad": -0.000002060506623,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 114337,
    "lum": 2.67423765708899,
    "ra": 23.235072,
    "dec": -62.700004,
    "pmra": 477.48,
    "pmdec": -425.01,
    "rv": -26,
    "Asset Name": "ASTRID 003834",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Sweet Nap",
    "Hair": "Curly",
    "Outfit": "Boxer",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.0000511,
    "y_1": 0.0000797,
    "z_1": -0.00000411,
    "Distance in Parsecs_1": 29.3513,
    "HYG Star ID_1": 114337,
    "Hipparcos Catalogue_1": 114699,
    "Henry Draper Catalogue_1": 219077,
    "Harvard Revised Catalogue_1": 8829
  },
  {
    "Astrid #": "ASTRID 003835",
    "HYG Star ID": 108990,
    "Hipparcos Catalogue": 109335,
    "Henry Draper Catalogue": 210604,
    "Distance in Parsecs": 29.36,
    "mag": 10.11,
    "Absolute Visual Magnitude": 7.771,
    "Spectral Type": "K0",
    "Color Index": 0.401,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.849799,
    "y": -5.182224,
    "z": 27.168651,
    "vx": 0.00000128,
    "vy": -0.00000136,
    "vz": -7.2e-7,
    "rarad": 5.79885720813412,
    "decrad": 1.18199146855596,
    "pmrarad": -2.05561000555555e-8,
    "pmdecrad": -6.4965033e-8,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 108990,
    "lum": 0.0678578350950407,
    "ra": 22.150003,
    "dec": 67.723123,
    "pmra": -4.24,
    "pmdec": -13.4,
    "rv": 0,
    "Asset Name": "ASTRID 003835",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Chuckle",
    "Hair": "Fire",
    "Outfit": "Tats",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00000128,
    "y_1": -0.00000136,
    "z_1": -7.2e-7,
    "Distance in Parsecs_1": 29.36,
    "HYG Star ID_1": 108990,
    "Hipparcos Catalogue_1": 109335,
    "Henry Draper Catalogue_1": 210604
  },
  {
    "Astrid #": "ASTRID 003836",
    "HYG Star ID": 89171,
    "Hipparcos Catalogue": 89449,
    "Henry Draper Catalogue": 348282,
    "Gliese Catalog": "Gl 708",
    "Distance in Parsecs": 29.3686,
    "mag": 10.06,
    "Absolute Visual Magnitude": 7.721,
    "Spectral Type": "M1",
    "Color Index": 1.323,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 1.858508,
    "y": -27.788874,
    "z": 9.318798,
    "vx": 0.00000812,
    "vy": 0.0000016,
    "vz": 0.00000864,
    "rarad": 4.77916896915779,
    "decrad": 0.322885878605488,
    "pmrarad": 2.79446605472222e-7,
    "pmdecrad": 2.90354913e-7,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 89171,
    "lum": 0.0710558763369473,
    "var_min": 10.127,
    "var_max": 10.017,
    "ra": 18.255081,
    "dec": 18.499998,
    "pmra": 57.64,
    "pmdec": 59.89,
    "rv": 1.7,
    "Asset Name": "ASTRID 003836",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Unkempt",
    "Outfit": "Scientist",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": 0.00000812,
    "y_1": 0.0000016,
    "z_1": 0.00000864,
    "Distance in Parsecs_1": 29.3686,
    "HYG Star ID_1": 89171,
    "Hipparcos Catalogue_1": 89449,
    "Henry Draper Catalogue_1": 348282,
    "Gliese Catalog_1": "Gl 708"
  },
  {
    "Astrid #": "ASTRID 003837",
    "HYG Star ID": 13136,
    "Hipparcos Catalogue": 13169,
    "Henry Draper Catalogue": 17379,
    "Distance in Parsecs": 29.3858,
    "mag": 8.29,
    "Absolute Visual Magnitude": 5.949,
    "Spectral Type": "K0",
    "Color Index": 0.845,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 13.066196,
    "y": 11.9035,
    "z": 23.475657,
    "vx": 0.0000133,
    "vy": 0.00002017,
    "vz": -0.00001763,
    "rarad": 0.738866330206036,
    "decrad": 0.925426819990287,
    "pmrarad": 2.02652118472222e-7,
    "pmdecrad": -9.9760111e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 13136,
    "lum": 0.363412616064222,
    "ra": 2.822261,
    "dec": 53.023051,
    "pmra": 41.8,
    "pmdec": -205.77,
    "rv": 0,
    "Asset Name": "ASTRID 003837",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Unkempt",
    "Outfit": "Warlock",
    "Astral Crystal": "Glass",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.0000133,
    "y_1": 0.00002017,
    "z_1": -0.00001763,
    "Distance in Parsecs_1": 29.3858,
    "HYG Star ID_1": 13136,
    "Hipparcos Catalogue_1": 13169,
    "Henry Draper Catalogue_1": 17379
  },
  {
    "Astrid #": "ASTRID 003838",
    "HYG Star ID": 113536,
    "Hipparcos Catalogue": 113896,
    "Henry Draper Catalogue": 217877,
    "Harvard Revised Catalogue": 8772,
    "Distance in Parsecs": 29.3858,
    "mag": 6.68,
    "Absolute Visual Magnitude": 4.339,
    "Spectral Type": "F8V",
    "Color Index": 0.581,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 28.411604,
    "y": -7.089795,
    "z": -2.456303,
    "vx": -0.00000239,
    "vy": 0.00004747,
    "vz": 0.00000659,
    "rarad": 6.03864083069452,
    "decrad": -0.0836861154187262,
    "pmrarad": 0.00000154762223106944,
    "pmdecrad": 1.84180717e-7,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 113536,
    "lum": 1.60103195466733,
    "ra": 23.065909,
    "dec": -4.794861,
    "pmra": 319.22,
    "pmdec": 37.99,
    "rv": -14,
    "Asset Name": "ASTRID 003838",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Oh No",
    "Hair": "Spiky",
    "Outfit": "Pirate",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": -0.00000239,
    "y_1": 0.00004747,
    "z_1": 0.00000659,
    "Distance in Parsecs_1": 29.3858,
    "HYG Star ID_1": 113536,
    "Hipparcos Catalogue_1": 113896,
    "Henry Draper Catalogue_1": 217877,
    "Harvard Revised Catalogue_1": 8772
  },
  {
    "Astrid #": "ASTRID 003839",
    "HYG Star ID": 39395,
    "Hipparcos Catalogue": 39506,
    "Henry Draper Catalogue": 66573,
    "Distance in Parsecs": 29.4031,
    "mag": 7.26,
    "Absolute Visual Magnitude": 4.918,
    "Spectral Type": "G0",
    "Color Index": 0.632,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -14.999981,
    "y": 24.841875,
    "z": 4.735409,
    "vx": -0.00003258,
    "vy": -0.0000201,
    "vz": 0.00000226,
    "rarad": 2.11401858490931,
    "decrad": 0.161755828132841,
    "pmrarad": 0.00000130216106460416,
    "pmdecrad": 7.7958039e-8,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 39395,
    "lum": 0.939290651618398,
    "ra": 8.074956,
    "dec": 9.267926,
    "pmra": 268.59,
    "pmdec": 16.08,
    "rv": 0,
    "Asset Name": "ASTRID 003839",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Prince",
    "Outfit": "Painter",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00003258,
    "y_1": -0.0000201,
    "z_1": 0.00000226,
    "Distance in Parsecs_1": 29.4031,
    "HYG Star ID_1": 39395,
    "Hipparcos Catalogue_1": 39506,
    "Henry Draper Catalogue_1": 66573
  },
  {
    "Astrid #": "ASTRID 003840",
    "HYG Star ID": 118600,
    "Gliese Catalog": "Wo 9287B",
    "Distance in Parsecs": 29.4118,
    "mag": 13,
    "Absolute Visual Magnitude": 10.657,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -19.407572,
    "y": 18.002038,
    "z": -12.819,
    "vx": 0.00003248,
    "vy": 0.00003402,
    "vz": -0.0000014,
    "rarad": 2.39375718537594,
    "decrad": -0.4509774861732,
    "pmrarad": -0.00000159901248120833,
    "pmdecrad": -5.3043464e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 44746,
    "base": "Wo 9287",
    "lum": 0.00475554176315207,
    "ra": 9.143479,
    "dec": -25.839107,
    "pmra": -329.82,
    "pmdec": -10.94,
    "rv": 0,
    "Asset Name": "ASTRID 003840",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Serious Mad",
    "Hair": "Bangs",
    "Outfit": "Iron Star",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003248,
    "y_1": 0.00003402,
    "z_1": -0.0000014,
    "Distance in Parsecs_1": 29.4118,
    "HYG Star ID_1": 118600,
    "Gliese Catalog_1": "Wo 9287B"
  },
  {
    "Astrid #": "ASTRID 003841",
    "HYG Star ID": 118923,
    "Henry Draper Catalogue": 120237,
    "Harvard Revised Catalogue": 5189,
    "Gliese Catalog": "NN 3806A",
    "Distance in Parsecs": 29.4118,
    "mag": 6.57,
    "Absolute Visual Magnitude": 4.227,
    "Spectral Type": "G3 IV-V",
    "Color Index": 0.55,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.237013,
    "y": -10.927526,
    "z": -17.165168,
    "vx": -0.0000231,
    "vy": 0.0000714,
    "vz": -0.00002303,
    "rarad": 3.61684229946309,
    "decrad": -0.6231690185796,
    "pmrarad": -0.00000251817073817361,
    "pmdecrad": -8.76911592e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 118923,
    "base": "NN 3806",
    "lum": 1.77500671350065,
    "ra": 13.81532,
    "dec": -35.704955,
    "pmra": -519.41,
    "pmdec": -180.88,
    "rv": 3.5,
    "Asset Name": "ASTRID 003841",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Unimpressed",
    "Hair": "Manbun",
    "Outfit": "Rider",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.0000231,
    "y_1": 0.0000714,
    "z_1": -0.00002303,
    "Distance in Parsecs_1": 29.4118,
    "HYG Star ID_1": 118923,
    "Henry Draper Catalogue_1": 120237,
    "Harvard Revised Catalogue_1": 5189,
    "Gliese Catalog_1": "NN 3806A"
  },
  {
    "Astrid #": "ASTRID 003842",
    "HYG Star ID": 8341,
    "Hipparcos Catalogue": 8356,
    "Distance in Parsecs": 29.4204,
    "mag": 10.39,
    "Absolute Visual Magnitude": 8.047,
    "Color Index": 0.214,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 23.056724,
    "y": 11.709331,
    "z": 14.029931,
    "vx": -9.8e-7,
    "vy": 0.00000112,
    "vz": 6.8e-7,
    "rarad": 0.469906872302514,
    "decrad": 0.497098992619727,
    "pmrarad": 4.90631444722222e-8,
    "pmdecrad": 2.6470826e-8,
    "con": "Tri",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Triangulum",
    "FULL CONSTELLATION NAME": "Triangulum",
    "comp": 1,
    "comp_primary": 8341,
    "lum": 0.0526259562080317,
    "ra": 1.794912,
    "dec": 28.481674,
    "pmra": 10.12,
    "pmdec": 5.46,
    "rv": 0,
    "Asset Name": "ASTRID 003842",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Fired Up",
    "Hair": "Pixie",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Triangulum",
    "x_1": -9.8e-7,
    "y_1": 0.00000112,
    "z_1": 6.8e-7,
    "Distance in Parsecs_1": 29.4204,
    "HYG Star ID_1": 8341,
    "Hipparcos Catalogue_1": 8356
  },
  {
    "Astrid #": "ASTRID 003843",
    "HYG Star ID": 105450,
    "Hipparcos Catalogue": 105791,
    "Henry Draper Catalogue": 204079,
    "Distance in Parsecs": 29.4204,
    "mag": 8.28,
    "Absolute Visual Magnitude": 5.937,
    "Spectral Type": "K1V",
    "Color Index": 0.828,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 20.439554,
    "y": -16.333721,
    "z": 13.452743,
    "vx": -0.00002897,
    "vy": -0.00000409,
    "vz": -0.00003677,
    "rarad": 5.60898005233318,
    "decrad": 0.474915990593035,
    "pmrarad": -7.23390492756944e-7,
    "pmdecrad": -7.99360796e-7,
    "con": "Vul",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vulpecula",
    "FULL CONSTELLATION NAME": "Vulpecula",
    "comp": 1,
    "comp_primary": 105450,
    "lum": 0.367451479199939,
    "ra": 21.424726,
    "dec": 27.210682,
    "pmra": -149.21,
    "pmdec": -164.88,
    "rv": -33.9,
    "Asset Name": "ASTRID 003843",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Hurt",
    "Hair": "Innocent",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vulpecula",
    "x_1": -0.00002897,
    "y_1": -0.00000409,
    "z_1": -0.00003677,
    "Distance in Parsecs_1": 29.4204,
    "HYG Star ID_1": 105450,
    "Hipparcos Catalogue_1": 105791,
    "Henry Draper Catalogue_1": 204079
  },
  {
    "Astrid #": "ASTRID 003844",
    "HYG Star ID": 113500,
    "Hipparcos Catalogue": 113860,
    "Henry Draper Catalogue": 217792,
    "Harvard Revised Catalogue": 8767,
    "Gliese Catalog": "Gl 886.2",
    "Distance in Parsecs": 29.4204,
    "mag": 5.12,
    "Absolute Visual Magnitude": 2.777,
    "Spectral Type": "A9V",
    "Color Index": 0.305,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 23.442366,
    "y": -5.899524,
    "z": -16.769254,
    "vx": 0.00000437,
    "vy": 0.0000099,
    "vz": 0.00001339,
    "rarad": 6.03664348310553,
    "decrad": -0.606491649529911,
    "pmrarad": 3.62640633055555e-7,
    "pmdecrad": 4.09425153e-7,
    "bayer": "Pi",
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 113500,
    "lum": 6.74838730870756,
    "bf": "Pi  PsA",
    "ra": 23.05828,
    "dec": -34.749412,
    "pmra": 74.8,
    "pmdec": 84.45,
    "rv": -6,
    "Asset Name": "ASTRID 003844",
    "Astrium Color": "White",
    "Background": "Depth",
    "Expression": "Oof",
    "Hair": "Sweetheart",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000437,
    "y_1": 0.0000099,
    "z_1": 0.00001339,
    "Distance in Parsecs_1": 29.4204,
    "HYG Star ID_1": 113500,
    "Hipparcos Catalogue_1": 113860,
    "Henry Draper Catalogue_1": 217792,
    "Harvard Revised Catalogue_1": 8767,
    "Gliese Catalog_1": "Gl 886.2"
  },
  {
    "Astrid #": "ASTRID 003845",
    "HYG Star ID": 21494,
    "Hipparcos Catalogue": 21547,
    "Henry Draper Catalogue": 29391,
    "Harvard Revised Catalogue": 1474,
    "Distance in Parsecs": 29.4291,
    "mag": 5.22,
    "Absolute Visual Magnitude": 2.876,
    "Spectral Type": "F0V",
    "Color Index": 0.283,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.344537,
    "y": 27.521998,
    "z": -1.270113,
    "vx": 0.00000163,
    "vy": 0.00002189,
    "vz": -0.00001008,
    "rarad": 1.21126808968625,
    "decrad": -0.0431715607134862,
    "pmrarad": 2.10021286416666e-7,
    "pmdecrad": -3.11395827e-7,
    "flam": 51,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 21494,
    "lum": 6.16027358327237,
    "bf": "51    Eri",
    "ra": 4.626703,
    "dec": -2.473548,
    "pmra": 43.32,
    "pmdec": -64.23,
    "rv": 21,
    "Asset Name": "ASTRID 003845",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Oh No",
    "Hair": "Afro",
    "Outfit": "Suit",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000163,
    "y_1": 0.00002189,
    "z_1": -0.00001008,
    "Distance in Parsecs_1": 29.4291,
    "HYG Star ID_1": 21494,
    "Hipparcos Catalogue_1": 21547,
    "Henry Draper Catalogue_1": 29391,
    "Harvard Revised Catalogue_1": 1474
  },
  {
    "Astrid #": "ASTRID 003846",
    "HYG Star ID": 65396,
    "Hipparcos Catalogue": 65602,
    "Henry Draper Catalogue": 116920,
    "Distance in Parsecs": 29.4291,
    "mag": 8.72,
    "Absolute Visual Magnitude": 6.376,
    "Spectral Type": "K3V",
    "Color Index": 0.911,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.912047,
    "y": -9.944997,
    "z": -12.105998,
    "vx": -0.00000845,
    "vy": 0.00004843,
    "vz": -0.000005,
    "rarad": 3.52141432245129,
    "decrad": -0.423948207086715,
    "pmrarad": -0.00000163503413767361,
    "pmdecrad": -2.96124196e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 65396,
    "lum": 0.245244908607201,
    "ra": 13.450812,
    "dec": -24.290443,
    "pmra": -337.25,
    "pmdec": -61.08,
    "rv": -7,
    "Asset Name": "ASTRID 003846",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Sweet Nap",
    "Hair": "Windy",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000845,
    "y_1": 0.00004843,
    "z_1": -0.000005,
    "Distance in Parsecs_1": 29.4291,
    "HYG Star ID_1": 65396,
    "Hipparcos Catalogue_1": 65602,
    "Henry Draper Catalogue_1": 116920
  },
  {
    "Astrid #": "ASTRID 003847",
    "HYG Star ID": 74586,
    "Hipparcos Catalogue": 74815,
    "Distance in Parsecs": 29.4291,
    "mag": 11.08,
    "Absolute Visual Magnitude": 8.736,
    "Color Index": 1.366,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -16.93164,
    "y": -19.711464,
    "z": -13.814835,
    "vx": -0.00000722,
    "vy": 0.00000568,
    "vz": 7.3e-7,
    "rarad": 4.00270830162018,
    "decrad": -0.488642515684426,
    "pmrarad": -3.11929122069444e-7,
    "pmdecrad": 2.8264637e-8,
    "con": "Lib",
    "Full Constellation Name Formula 1": "Libra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Libra",
    "comp": 1,
    "comp_primary": 74586,
    "lum": 0.0278997299740826,
    "var_min": 11.179,
    "var_max": 10.999,
    "ra": 15.289219,
    "dec": -27.997154,
    "pmra": -64.34,
    "pmdec": 5.83,
    "rv": 0,
    "Asset Name": "ASTRID 003847",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Good Boy",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Libra",
    "x_1": -0.00000722,
    "y_1": 0.00000568,
    "z_1": 7.3e-7,
    "Distance in Parsecs_1": 29.4291,
    "HYG Star ID_1": 74586,
    "Hipparcos Catalogue_1": 74815
  },
  {
    "Astrid #": "ASTRID 003848",
    "HYG Star ID": 77554,
    "Hipparcos Catalogue": 77790,
    "Henry Draper Catalogue": 142626,
    "Distance in Parsecs": 29.4291,
    "mag": 8.28,
    "Absolute Visual Magnitude": 5.936,
    "Spectral Type": "K0",
    "Color Index": 0.835,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.125814,
    "y": -14.75789,
    "z": 23.769648,
    "vx": 0.00000314,
    "vy": -0.00002602,
    "vz": -0.00001495,
    "rarad": 4.1585692653176,
    "decrad": 0.940227018904861,
    "pmrarad": 5.55693440652777e-7,
    "pmdecrad": -8.61610873e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 77554,
    "lum": 0.367790070422386,
    "ra": 15.884565,
    "dec": 53.87104,
    "pmra": 114.62,
    "pmdec": -177.72,
    "rv": 0,
    "Asset Name": "ASTRID 003848",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Hachimaki",
    "Outfit": "Scientist",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000314,
    "y_1": -0.00002602,
    "z_1": -0.00001495,
    "Distance in Parsecs_1": 29.4291,
    "HYG Star ID_1": 77554,
    "Hipparcos Catalogue_1": 77790,
    "Henry Draper Catalogue_1": 142626
  },
  {
    "Astrid #": "ASTRID 003849",
    "HYG Star ID": 87060,
    "Hipparcos Catalogue": 87330,
    "Henry Draper Catalogue": 162020,
    "Distance in Parsecs": 29.4291,
    "mag": 9.1,
    "Absolute Visual Magnitude": 6.756,
    "Spectral Type": "K2V:",
    "Color Index": 0.964,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.916221,
    "y": -22.419832,
    "z": -19.041628,
    "vx": 0.00000309,
    "vy": 0.0000022,
    "vz": -0.00000274,
    "rarad": 4.67154490342092,
    "decrad": -0.703687936014161,
    "pmrarad": 1.01762391548611e-7,
    "pmdecrad": -1.22173047e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 87060,
    "lum": 0.172822387294976,
    "ra": 17.843987,
    "dec": -40.318349,
    "pmra": 20.99,
    "pmdec": -25.2,
    "rv": 0,
    "Asset Name": "ASTRID 003849",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Blep",
    "Hair": "Tiny Crown",
    "Outfit": "Scrubs",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": 0.00000309,
    "y_1": 0.0000022,
    "z_1": -0.00000274,
    "Distance in Parsecs_1": 29.4291,
    "HYG Star ID_1": 87060,
    "Hipparcos Catalogue_1": 87330,
    "Henry Draper Catalogue_1": 162020
  },
  {
    "Astrid #": "ASTRID 003850",
    "HYG Star ID": 72407,
    "Hipparcos Catalogue": 72634,
    "Henry Draper Catalogue": 131023,
    "Gliese Catalog": "NN 3868A",
    "Distance in Parsecs": 29.4377,
    "mag": 7.4,
    "Absolute Visual Magnitude": 5.055,
    "Spectral Type": "K0...",
    "Color Index": 0.76,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.302669,
    "y": -19.698634,
    "z": 4.971874,
    "vx": 0.00000642,
    "vy": 0.00004797,
    "vz": 0.00000198,
    "rarad": 3.88788933999192,
    "decrad": 0.169710100945186,
    "pmrarad": -0.00000104845806556944,
    "pmdecrad": 2.80222307e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 72407,
    "base": "NN 3868",
    "lum": 0.827942163712334,
    "ra": 14.850643,
    "dec": 9.723673,
    "pmra": -216.26,
    "pmdec": 57.8,
    "rv": -35.6,
    "Asset Name": "ASTRID 003850",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Oh Snap",
    "Hair": "Audiohead",
    "Outfit": "Astro",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000642,
    "y_1": 0.00004797,
    "z_1": 0.00000198,
    "Distance in Parsecs_1": 29.4377,
    "HYG Star ID_1": 72407,
    "Hipparcos Catalogue_1": 72634,
    "Henry Draper Catalogue_1": 131023,
    "Gliese Catalog_1": "NN 3868A"
  },
  {
    "Astrid #": "ASTRID 003851",
    "HYG Star ID": 80439,
    "Hipparcos Catalogue": 80683,
    "Henry Draper Catalogue": 148303,
    "Distance in Parsecs": 29.4377,
    "mag": 8.7,
    "Absolute Visual Magnitude": 6.355,
    "Spectral Type": "K3V",
    "Color Index": 0.98,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -9.809875,
    "y": -23.210412,
    "z": -15.21911,
    "vx": -6.9e-7,
    "vy": 0.00001308,
    "vz": -0.0000195,
    "rarad": 4.31251098078893,
    "decrad": -0.543335319695682,
    "pmrarad": -1.94652692743055e-7,
    "pmdecrad": -7.73908078e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 80439,
    "lum": 0.250034536169642,
    "ra": 16.472579,
    "dec": -31.130821,
    "pmra": -40.15,
    "pmdec": -159.63,
    "rv": 0,
    "Asset Name": "ASTRID 003851",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Mohawk",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -6.9e-7,
    "y_1": 0.00001308,
    "z_1": -0.0000195,
    "Distance in Parsecs_1": 29.4377,
    "HYG Star ID_1": 80439,
    "Hipparcos Catalogue_1": 80683,
    "Henry Draper Catalogue_1": 148303
  },
  {
    "Astrid #": "ASTRID 003852",
    "HYG Star ID": 15274,
    "Hipparcos Catalogue": 15312,
    "Distance in Parsecs": 29.4551,
    "mag": 11.58,
    "Absolute Visual Magnitude": 9.234,
    "Color Index": 1.463,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.973604,
    "y": 19.800843,
    "z": -13.690374,
    "vx": -0.00003842,
    "vy": 0.00002821,
    "vz": -0.00000683,
    "rarad": 0.862125924319649,
    "decrad": -0.483395026593062,
    "pmrarad": 0.00000161375081709722,
    "pmdecrad": -2.6189635e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 15274,
    "lum": 0.0176359963389799,
    "var_min": 11.656,
    "var_max": 11.506,
    "ra": 3.293078,
    "dec": -27.696495,
    "pmra": 332.86,
    "pmdec": -54.02,
    "rv": 0,
    "Asset Name": "ASTRID 003852",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Shocked",
    "Hair": "Unkempt",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00003842,
    "y_1": 0.00002821,
    "z_1": -0.00000683,
    "Distance in Parsecs_1": 29.4551,
    "HYG Star ID_1": 15274,
    "Hipparcos Catalogue_1": 15312
  },
  {
    "Astrid #": "ASTRID 003853",
    "HYG Star ID": 31958,
    "Hipparcos Catalogue": 32043,
    "Distance in Parsecs": 29.4638,
    "mag": 9.63,
    "Absolute Visual Magnitude": 7.284,
    "Spectral Type": "K5",
    "Color Index": 0.984,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -4.333537,
    "y": 23.575912,
    "z": -17.132201,
    "vx": -0.0000288,
    "vy": -0.000005,
    "vz": 4.1e-7,
    "rarad": 1.75257725092218,
    "decrad": -0.620529597101599,
    "pmrarad": 9.92219678625e-7,
    "pmdecrad": 1.701696e-8,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 31958,
    "lum": 0.106267386540246,
    "ra": 6.694352,
    "dec": -35.553727,
    "pmra": 204.66,
    "pmdec": 3.51,
    "rv": 0,
    "Asset Name": "ASTRID 003853",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Good Boy",
    "Outfit": "Rider",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.0000288,
    "y_1": -0.000005,
    "z_1": 4.1e-7,
    "Distance in Parsecs_1": 29.4638,
    "HYG Star ID_1": 31958,
    "Hipparcos Catalogue_1": 32043
  },
  {
    "Astrid #": "ASTRID 003854",
    "HYG Star ID": 67195,
    "Hipparcos Catalogue": 67408,
    "Henry Draper Catalogue": 120237,
    "Harvard Revised Catalogue": 5189,
    "Gliese Catalog": "NN 3807B",
    "Distance in Parsecs": 29.4724,
    "mag": 6.56,
    "Absolute Visual Magnitude": 4.213,
    "Spectral Type": "G0V",
    "Color Index": 0.561,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.280619,
    "y": -10.950581,
    "z": -17.200124,
    "vx": -0.00002352,
    "vy": 0.00007255,
    "vz": -0.00002323,
    "rarad": 3.61684129239233,
    "decrad": -0.62315549099052,
    "pmrarad": -0.00000255424087600694,
    "pmdecrad": -8.83427488e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 2,
    "comp_primary": 67195,
    "base": "NN 3807",
    "lum": 1.79804269517743,
    "ra": 13.815316,
    "dec": -35.70418,
    "pmra": -526.85,
    "pmdec": -182.22,
    "rv": 3.5,
    "Asset Name": "ASTRID 003854",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Shy Smile",
    "Hair": "Heartbreaker",
    "Outfit": "Suit",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00002352,
    "y_1": 0.00007255,
    "z_1": -0.00002323,
    "Distance in Parsecs_1": 29.4724,
    "HYG Star ID_1": 67195,
    "Hipparcos Catalogue_1": 67408,
    "Henry Draper Catalogue_1": 120237,
    "Harvard Revised Catalogue_1": 5189,
    "Gliese Catalog_1": "NN 3807B"
  },
  {
    "Astrid #": "ASTRID 003855",
    "HYG Star ID": 111216,
    "Hipparcos Catalogue": 111566,
    "Distance in Parsecs": 29.4724,
    "mag": 9.62,
    "Absolute Visual Magnitude": 7.273,
    "Spectral Type": "M2",
    "Color Index": 1.103,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.786386,
    "y": -4.133699,
    "z": -27.114365,
    "vx": -0.00003377,
    "vy": -0.0000194,
    "vz": -0.00001048,
    "rarad": 5.91723020312842,
    "decrad": -1.16805944573333,
    "pmrarad": -0.00000102455675111805,
    "pmdecrad": -9.06940952e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 111216,
    "lum": 0.107349493580523,
    "var_min": 9.682,
    "var_max": 9.562,
    "ra": 22.602154,
    "dec": -66.924876,
    "pmra": -211.33,
    "pmdec": -187.07,
    "rv": 0,
    "Asset Name": "ASTRID 003855",
    "Astrium Color": "Red",
    "Background": "Silken Darkness",
    "Expression": "Malevolent",
    "Hair": "High Bun",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00003377,
    "y_1": -0.0000194,
    "z_1": -0.00001048,
    "Distance in Parsecs_1": 29.4724,
    "HYG Star ID_1": 111216,
    "Hipparcos Catalogue_1": 111566
  },
  {
    "Astrid #": "ASTRID 003856",
    "HYG Star ID": 119450,
    "Gliese Catalog": "Gl 838.3B",
    "Distance in Parsecs": 29.4724,
    "mag": 11.45,
    "Absolute Visual Magnitude": 9.103,
    "Spectral Type": "M1",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 18.467112,
    "y": -11.553657,
    "z": 19.851982,
    "vx": 0.00001209,
    "vy": -0.0000349,
    "vz": -0.00003155,
    "rarad": 5.7241439053383,
    "decrad": 0.73904004256335,
    "pmrarad": -7.86513233965277e-7,
    "pmdecrad": -0.000001448608733,
    "con": "Cyg",
    "Full Constellation Name Formula 1": "Cygnus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cygnus",
    "comp": 2,
    "comp_primary": 107577,
    "base": "Gl 838.3",
    "lum": 0.0198975681109065,
    "ra": 21.864619,
    "dec": 42.343875,
    "pmra": -162.23,
    "pmdec": -298.8,
    "rv": 0,
    "Asset Name": "ASTRID 003856",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined Shinobi",
    "Hair": "Frizzy",
    "Outfit": "Scrubs",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cygnus",
    "x_1": 0.00001209,
    "y_1": -0.0000349,
    "z_1": -0.00003155,
    "Distance in Parsecs_1": 29.4724,
    "HYG Star ID_1": 119450,
    "Gliese Catalog_1": "Gl 838.3B"
  },
  {
    "Astrid #": "ASTRID 003857",
    "HYG Star ID": 85958,
    "Hipparcos Catalogue": 86221,
    "Distance in Parsecs": 29.4898,
    "mag": 9.25,
    "Absolute Visual Magnitude": 6.902,
    "Spectral Type": "M0",
    "Color Index": 1.16,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -2.590896,
    "y": -25.933824,
    "z": 13.797546,
    "vx": -0.00001211,
    "vy": 0.00001801,
    "vz": 0.00003158,
    "rarad": 4.61281578105233,
    "decrad": 0.486885088450832,
    "pmrarad": -4.69251161409722e-7,
    "pmdecrad": 0.000001211937238,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 85958,
    "lum": 0.151077573192499,
    "ra": 17.619658,
    "dec": 27.896461,
    "pmra": -96.79,
    "pmdec": 249.98,
    "rv": 0,
    "Asset Name": "ASTRID 003857",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Halo Boy",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00001211,
    "y_1": 0.00001801,
    "z_1": 0.00003158,
    "Distance in Parsecs_1": 29.4898,
    "HYG Star ID_1": 85958,
    "Hipparcos Catalogue_1": 86221
  },
  {
    "Astrid #": "ASTRID 003858",
    "HYG Star ID": 47701,
    "Hipparcos Catalogue": 47836,
    "Henry Draper Catalogue": 84612,
    "Distance in Parsecs": 29.4985,
    "mag": 8.04,
    "Absolute Visual Magnitude": 5.691,
    "Spectral Type": "G0/G1IV/V",
    "Color Index": 0.517,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.934697,
    "y": 10.644125,
    "z": -22.426536,
    "vx": -0.0000076,
    "vy": 0.00000867,
    "vz": 0.00000951,
    "rarad": 2.55267899897276,
    "decrad": -0.86371358906943,
    "pmrarad": -1.01374540604166e-7,
    "pmdecrad": 4.96449208e-7,
    "con": "Vel",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Vela",
    "FULL CONSTELLATION NAME": "Vela",
    "comp": 1,
    "comp_primary": 47701,
    "lum": 0.460892880980895,
    "ra": 9.750516,
    "dec": -49.487143,
    "pmra": -20.91,
    "pmdec": 102.4,
    "rv": 0,
    "Asset Name": "ASTRID 003858",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Upset",
    "Hair": "Greasy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Vela",
    "x_1": -0.0000076,
    "y_1": 0.00000867,
    "z_1": 0.00000951,
    "Distance in Parsecs_1": 29.4985,
    "HYG Star ID_1": 47701,
    "Hipparcos Catalogue_1": 47836,
    "Henry Draper Catalogue_1": 84612
  },
  {
    "Astrid #": "ASTRID 003859",
    "HYG Star ID": 90331,
    "Hipparcos Catalogue": 90611,
    "Henry Draper Catalogue": 170209,
    "Distance in Parsecs": 29.4985,
    "mag": 9.37,
    "Absolute Visual Magnitude": 7.021,
    "Spectral Type": "K2:V",
    "Color Index": 1.211,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 3.329697,
    "y": -25.83875,
    "z": -13.835954,
    "vx": -0.00001376,
    "vy": 0.00002861,
    "vz": -0.00005674,
    "rarad": 4.84054756130359,
    "decrad": -0.488202652874348,
    "pmrarad": -3.385938745e-7,
    "pmdecrad": -0.000002177928497,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 90331,
    "lum": 0.135394181140507,
    "ra": 18.48953,
    "dec": -27.971952,
    "pmra": -69.84,
    "pmdec": -449.23,
    "rv": 0,
    "Asset Name": "ASTRID 003859",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Serious Slumber",
    "Hair": "Braid",
    "Outfit": "Suit",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sagittarius",
    "x_1": -0.00001376,
    "y_1": 0.00002861,
    "z_1": -0.00005674,
    "Distance in Parsecs_1": 29.4985,
    "HYG Star ID_1": 90331,
    "Hipparcos Catalogue_1": 90611,
    "Henry Draper Catalogue_1": 170209
  },
  {
    "Astrid #": "ASTRID 003860",
    "HYG Star ID": 7262,
    "Hipparcos Catalogue": 7276,
    "Henry Draper Catalogue": 9562,
    "Harvard Revised Catalogue": 448,
    "Distance in Parsecs": 29.5159,
    "mag": 5.75,
    "Absolute Visual Magnitude": 3.4,
    "Spectral Type": "G2IV",
    "Color Index": 0.639,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 26.879068,
    "y": 11.647482,
    "z": -3.610024,
    "vx": -0.00002534,
    "vy": 0.00001659,
    "vz": -0.0000097,
    "rarad": 0.408904185916062,
    "decrad": -0.122615327903344,
    "pmrarad": 8.57247549958333e-7,
    "pmdecrad": -3.95074668e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 7262,
    "lum": 3.80189396320561,
    "ra": 1.561899,
    "dec": -7.025341,
    "pmra": 176.82,
    "pmdec": -81.49,
    "rv": -15,
    "Asset Name": "ASTRID 003860",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Guilty Grin",
    "Hair": "Good Boy",
    "Outfit": "Samurai",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00002534,
    "y_1": 0.00001659,
    "z_1": -0.0000097,
    "Distance in Parsecs_1": 29.5159,
    "HYG Star ID_1": 7262,
    "Hipparcos Catalogue_1": 7276,
    "Henry Draper Catalogue_1": 9562,
    "Harvard Revised Catalogue_1": 448
  },
  {
    "Astrid #": "ASTRID 003861",
    "HYG Star ID": 3413,
    "Hipparcos Catalogue": 3419,
    "Henry Draper Catalogue": 4128,
    "Harvard Revised Catalogue": 188,
    "Gliese Catalog": "Gl 31",
    "Proper Name": "Diphda",
    "Distance in Parsecs": 29.5334,
    "mag": 2.04,
    "Absolute Visual Magnitude": -0.312,
    "Spectral Type": "K0III",
    "Color Index": 1.019,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 27.583638,
    "y": 5.310463,
    "z": -9.119792,
    "vx": 0.00000782,
    "vy": 0.00003545,
    "vz": 2.5e-7,
    "rarad": 0.19019464223046,
    "decrad": -0.313925470402652,
    "pmrarad": 0.00000112859776696527,
    "pmdecrad": 1.58582554e-7,
    "bayer": "Bet",
    "flam": 16,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 3413,
    "lum": 116.091387012618,
    "bf": "16Bet Cet",
    "ra": 0.72649,
    "dec": -17.986605,
    "pmra": 232.79,
    "pmdec": 32.71,
    "rv": 13.3,
    "Asset Name": "ASTRID 003861",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Laughing",
    "Hair": "Female Elf",
    "Outfit": "Elven",
    "Special": "Faerie",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "Perfect",
    "Proper Name_1": "Diphda",
    "Constellation": "Cetus",
    "x_1": 0.00000782,
    "y_1": 0.00003545,
    "z_1": 2.5e-7,
    "Distance in Parsecs_1": 29.5334,
    "HYG Star ID_1": 3413,
    "Hipparcos Catalogue_1": 3419,
    "Henry Draper Catalogue_1": 4128,
    "Harvard Revised Catalogue_1": 188,
    "Gliese Catalog_1": "Gl 31"
  },
  {
    "Astrid #": "ASTRID 003862",
    "HYG Star ID": 70967,
    "Hipparcos Catalogue": 71192,
    "Henry Draper Catalogue": 128385,
    "Distance in Parsecs": 29.5334,
    "mag": 6.6,
    "Absolute Visual Magnitude": 4.248,
    "Spectral Type": "F5",
    "Color Index": 0.504,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.636807,
    "y": -7.637521,
    "z": 26.851852,
    "vx": 0.00000951,
    "vy": -0.00000258,
    "vz": 0.00000268,
    "rarad": 3.81176273298274,
    "decrad": 1.14136548525701,
    "pmrarad": 2.68683741763888e-7,
    "pmdecrad": 2.1797223e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 70967,
    "lum": 1.7410049263526,
    "ra": 14.559861,
    "dec": 65.395425,
    "pmra": 55.42,
    "pmdec": 44.96,
    "rv": 0,
    "Asset Name": "ASTRID 003862",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Oh No",
    "Hair": "Hachimaki",
    "Outfit": "Mecha",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00000951,
    "y_1": -0.00000258,
    "z_1": 0.00000268,
    "Distance in Parsecs_1": 29.5334,
    "HYG Star ID_1": 70967,
    "Hipparcos Catalogue_1": 71192,
    "Henry Draper Catalogue_1": 128385
  },
  {
    "Astrid #": "ASTRID 003863",
    "HYG Star ID": 17914,
    "Hipparcos Catalogue": 17956,
    "Distance in Parsecs": 29.5421,
    "mag": 9.85,
    "Absolute Visual Magnitude": 7.498,
    "Spectral Type": "K7",
    "Color Index": 1.223,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 14.487403,
    "y": 22.81225,
    "z": -11.935329,
    "vx": -0.00001689,
    "vy": -0.00000534,
    "vz": -0.00003071,
    "rarad": 1.00498723403043,
    "decrad": -0.415897233649647,
    "pmrarad": 3.8571776425e-7,
    "pmdecrad": -0.000001136645674,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17914,
    "lum": 0.0872569442770115,
    "ra": 3.838768,
    "dec": -23.829156,
    "pmra": 79.56,
    "pmdec": -234.45,
    "rv": 0,
    "Asset Name": "ASTRID 003863",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Arrogant",
    "Hair": "Horns",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001689,
    "y_1": -0.00000534,
    "z_1": -0.00003071,
    "Distance in Parsecs_1": 29.5421,
    "HYG Star ID_1": 17914,
    "Hipparcos Catalogue_1": 17956
  },
  {
    "Astrid #": "ASTRID 003864",
    "HYG Star ID": 19253,
    "Hipparcos Catalogue": 19301,
    "Henry Draper Catalogue": 25918,
    "Distance in Parsecs": 29.5421,
    "mag": 7.7,
    "Absolute Visual Magnitude": 5.348,
    "Spectral Type": "G5",
    "Color Index": 0.725,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.845284,
    "y": 18.562701,
    "z": 20.76613,
    "vx": 0.00004416,
    "vy": -0.00000797,
    "vz": -0.00001381,
    "rarad": 1.0831463111211,
    "decrad": 0.77951347426147,
    "pmrarad": -0.00000144707187372222,
    "pmdecrad": -6.57455832e-7,
    "con": "Per",
    "Full Constellation Name Formula 1": "Perseus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Perseus",
    "comp": 1,
    "comp_primary": 19253,
    "lum": 0.632120682005268,
    "ra": 4.137314,
    "dec": 44.662832,
    "pmra": -298.48,
    "pmdec": -135.61,
    "rv": 0,
    "Asset Name": "ASTRID 003864",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Guilty Grin",
    "Hair": "Horns",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Perseus",
    "x_1": 0.00004416,
    "y_1": -0.00000797,
    "z_1": -0.00001381,
    "Distance in Parsecs_1": 29.5421,
    "HYG Star ID_1": 19253,
    "Hipparcos Catalogue_1": 19301,
    "Henry Draper Catalogue_1": 25918
  },
  {
    "Astrid #": "ASTRID 003865",
    "HYG Star ID": 103488,
    "Hipparcos Catalogue": 103820,
    "Henry Draper Catalogue": 199933,
    "Distance in Parsecs": 29.5421,
    "mag": 9.36,
    "Absolute Visual Magnitude": 7.008,
    "Spectral Type": "K5V",
    "Color Index": 1.046,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.904728,
    "y": -12.647829,
    "z": -23.371694,
    "vx": 0.00001204,
    "vy": -1e-7,
    "vz": 0.0000067,
    "rarad": 5.50783899212527,
    "decrad": -0.912657179930005,
    "pmrarad": 2.82985745340277e-7,
    "pmdecrad": 3.70979428e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 103488,
    "lum": 0.137025059712424,
    "ra": 21.038395,
    "dec": -52.291405,
    "pmra": 58.37,
    "pmdec": 76.52,
    "rv": 0,
    "Asset Name": "ASTRID 003865",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Malevolent",
    "Hair": "High Fade",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00001204,
    "y_1": -1e-7,
    "z_1": 0.0000067,
    "Distance in Parsecs_1": 29.5421,
    "HYG Star ID_1": 103488,
    "Hipparcos Catalogue_1": 103820,
    "Henry Draper Catalogue_1": 199933
  },
  {
    "Astrid #": "ASTRID 003866",
    "HYG Star ID": 113629,
    "Hipparcos Catalogue": 113989,
    "Henry Draper Catalogue": 218209,
    "Gliese Catalog": "Wo 9808",
    "Distance in Parsecs": 29.5421,
    "mag": 7.49,
    "Absolute Visual Magnitude": 5.138,
    "Spectral Type": "G6V",
    "Color Index": 0.646,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 10.556651,
    "y": -2.578241,
    "z": 27.470635,
    "vx": -0.00000751,
    "vy": 0.00008914,
    "vz": -0.00000876,
    "rarad": 6.04360232583289,
    "decrad": 1.19410300314237,
    "pmrarad": 0.00000287106661625,
    "pmdecrad": 7.86416271e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 113629,
    "lum": 0.767008187693509,
    "ra": 23.08486,
    "dec": 68.417062,
    "pmra": 592.2,
    "pmdec": 162.21,
    "rv": -18.2,
    "Asset Name": "ASTRID 003866",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Laughing",
    "Hair": "Charmer",
    "Outfit": "Garb",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000751,
    "y_1": 0.00008914,
    "z_1": -0.00000876,
    "Distance in Parsecs_1": 29.5421,
    "HYG Star ID_1": 113629,
    "Hipparcos Catalogue_1": 113989,
    "Henry Draper Catalogue_1": 218209,
    "Gliese Catalog_1": "Wo 9808"
  },
  {
    "Astrid #": "ASTRID 003867",
    "HYG Star ID": 8180,
    "Hipparcos Catalogue": 8195,
    "Distance in Parsecs": 29.5508,
    "mag": 11.39,
    "Absolute Visual Magnitude": 9.037,
    "Color Index": 1.412,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 20.300322,
    "y": 10.05018,
    "z": -18.97737,
    "vx": -0.00000673,
    "vy": 0.0000412,
    "vz": 0.00001461,
    "rarad": 0.459696182272861,
    "decrad": -0.697358101011314,
    "pmrarad": 0.00000135054546992361,
    "pmdecrad": 6.45141564e-7,
    "con": "Phe",
    "Full Constellation Name Formula 1": "Phoenix",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Phoenix",
    "comp": 1,
    "comp_primary": 8180,
    "lum": 0.0211446256165258,
    "var_min": 11.46,
    "var_max": 11.3,
    "ra": 1.75591,
    "dec": -39.955676,
    "pmra": 278.57,
    "pmdec": 133.07,
    "rv": 0,
    "Asset Name": "ASTRID 003867",
    "Astrium Color": "Green",
    "Background": "Supple Emptiness",
    "Expression": "Star of Culture",
    "Hair": "Grecian",
    "Outfit": "Spacesuit",
    "Astral Crystal": "Blade",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Phoenix",
    "x_1": -0.00000673,
    "y_1": 0.0000412,
    "z_1": 0.00001461,
    "Distance in Parsecs_1": 29.5508,
    "HYG Star ID_1": 8180,
    "Hipparcos Catalogue_1": 8195
  },
  {
    "Astrid #": "ASTRID 003868",
    "HYG Star ID": 6376,
    "Hipparcos Catalogue": 6390,
    "Henry Draper Catalogue": 8326,
    "Gliese Catalog": "NN 3091A",
    "Distance in Parsecs": 29.5596,
    "mag": 8.76,
    "Absolute Visual Magnitude": 6.407,
    "Spectral Type": "K2V",
    "Color Index": 0.97,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.688175,
    "y": 9.246112,
    "z": -13.370615,
    "vx": -0.00001076,
    "vy": -0.0000131,
    "vz": -0.00002893,
    "rarad": 0.358346421271438,
    "decrad": -0.469373190734796,
    "pmrarad": -2.87397549833333e-7,
    "pmdecrad": -0.000001097569691,
    "con": "Scl",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sculptor",
    "FULL CONSTELLATION NAME": "Sculptor",
    "comp": 1,
    "comp_primary": 6376,
    "base": "NN 3091",
    "lum": 0.238341682067315,
    "ra": 1.368783,
    "dec": -26.893103,
    "pmra": -59.28,
    "pmdec": -226.39,
    "rv": 0,
    "Asset Name": "ASTRID 003868",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Naughty Dreams",
    "Hair": "Headband",
    "Outfit": "Sharky",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sculptor",
    "x_1": -0.00001076,
    "y_1": -0.0000131,
    "z_1": -0.00002893,
    "Distance in Parsecs_1": 29.5596,
    "HYG Star ID_1": 6376,
    "Hipparcos Catalogue_1": 6390,
    "Henry Draper Catalogue_1": 8326,
    "Gliese Catalog_1": "NN 3091A"
  },
  {
    "Astrid #": "ASTRID 003869",
    "HYG Star ID": 87337,
    "Hipparcos Catalogue": 87607,
    "Distance in Parsecs": 29.5596,
    "mag": 11.19,
    "Absolute Visual Magnitude": 8.837,
    "Spectral Type": "M0",
    "Color Index": 1.35,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.571491,
    "y": -21.15163,
    "z": -20.641025,
    "vx": -0.00001957,
    "vy": 0.00001682,
    "vz": -0.00001669,
    "rarad": 4.68537911633456,
    "decrad": -0.772998829625601,
    "pmrarad": -6.77333193104166e-7,
    "pmdecrad": -7.88985783e-7,
    "con": "Sco",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Scorpius",
    "FULL CONSTELLATION NAME": "Scorpius",
    "comp": 1,
    "comp_primary": 87337,
    "lum": 0.0254214313620493,
    "var_min": 11.268,
    "var_max": 11.088,
    "ra": 17.89683,
    "dec": -44.289571,
    "pmra": -139.71,
    "pmdec": -162.74,
    "rv": 0,
    "Asset Name": "ASTRID 003869",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Chubby Cheeks",
    "Hair": "Lion Mane",
    "Outfit": "Scrubs",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Scorpius",
    "x_1": -0.00001957,
    "y_1": 0.00001682,
    "z_1": -0.00001669,
    "Distance in Parsecs_1": 29.5596,
    "HYG Star ID_1": 87337,
    "Hipparcos Catalogue_1": 87607
  },
  {
    "Astrid #": "ASTRID 003870",
    "HYG Star ID": 118178,
    "Gliese Catalog": "Gl 118.1B",
    "Distance in Parsecs": 29.5596,
    "mag": 13.1,
    "Absolute Visual Magnitude": 10.747,
    "Spectral Type": "M3 :",
    "Color Index": 1.52,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 17.362774,
    "y": 16.482645,
    "z": -17.338584,
    "vx": -0.00005936,
    "vy": 0.00004395,
    "vz": -0.00001768,
    "rarad": 0.759370777408398,
    "decrad": -0.62680925080415,
    "pmrarad": 0.00000246110816797222,
    "pmdecrad": -7.38376083e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 13479,
    "base": "Gl 118.1",
    "lum": 0.00437723637934765,
    "ra": 2.900583,
    "dec": -35.913525,
    "pmra": 507.64,
    "pmdec": -152.3,
    "rv": 0,
    "Asset Name": "ASTRID 003870",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Cute Neko",
    "Hair": "Bangs",
    "Outfit": "Football",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00005936,
    "y_1": 0.00004395,
    "z_1": -0.00001768,
    "Distance in Parsecs_1": 29.5596,
    "HYG Star ID_1": 118178,
    "Gliese Catalog_1": "Gl 118.1B"
  },
  {
    "Astrid #": "ASTRID 003871",
    "HYG Star ID": 81432,
    "Hipparcos Catalogue": 81681,
    "Henry Draper Catalogue": 150433,
    "Gliese Catalog": "Gl 634.1",
    "Distance in Parsecs": 29.5683,
    "mag": 7.21,
    "Absolute Visual Magnitude": 4.856,
    "Spectral Type": "G0",
    "Color Index": 0.631,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.962475,
    "y": -27.799997,
    "z": -1.473909,
    "vx": 0.00001134,
    "vy": 0.00004282,
    "vz": -0.00006154,
    "rarad": 4.36828385259515,
    "decrad": -0.0498688925262412,
    "pmrarad": -1.27360553881944e-7,
    "pmdecrad": -0.000002153202999,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 81432,
    "lum": 0.994489037154792,
    "ra": 16.685615,
    "dec": -2.857277,
    "pmra": -26.27,
    "pmdec": -444.13,
    "rv": -40.1,
    "Asset Name": "ASTRID 003871",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Scared",
    "Hair": "Slick",
    "Outfit": "Sailor",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": 0.00001134,
    "y_1": 0.00004282,
    "z_1": -0.00006154,
    "Distance in Parsecs_1": 29.5683,
    "HYG Star ID_1": 81432,
    "Hipparcos Catalogue_1": 81681,
    "Henry Draper Catalogue_1": 150433,
    "Gliese Catalog_1": "Gl 634.1"
  },
  {
    "Astrid #": "ASTRID 003872",
    "HYG Star ID": 94462,
    "Hipparcos Catalogue": 94757,
    "Henry Draper Catalogue": 180409,
    "Distance in Parsecs": 29.577,
    "mag": 6.93,
    "Absolute Visual Magnitude": 4.575,
    "Spectral Type": "G0",
    "Color Index": 0.57,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 9.557324,
    "y": -27.41784,
    "z": -5.629155,
    "vx": 0.00000146,
    "vy": 0.00006285,
    "vz": 0.00001285,
    "rarad": 5.04779851519801,
    "decrad": -0.191493599727005,
    "pmrarad": 7.46079773006944e-7,
    "pmdecrad": 4.7802628e-8,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 1,
    "comp_primary": 94462,
    "lum": 1.28824955169313,
    "ra": 19.28117,
    "dec": -10.971775,
    "pmra": 153.89,
    "pmdec": 9.86,
    "rv": -58.9,
    "Asset Name": "ASTRID 003872",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Elegant",
    "Outfit": "Tarnished",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": 0.00000146,
    "y_1": 0.00006285,
    "z_1": 0.00001285,
    "Distance in Parsecs_1": 29.577,
    "HYG Star ID_1": 94462,
    "Hipparcos Catalogue_1": 94757,
    "Henry Draper Catalogue_1": 180409
  },
  {
    "Astrid #": "ASTRID 003873",
    "HYG Star ID": 33215,
    "Hipparcos Catalogue": 33302,
    "Henry Draper Catalogue": 51199,
    "Harvard Revised Catalogue": 2590,
    "Distance in Parsecs": 29.5858,
    "mag": 4.66,
    "Absolute Visual Magnitude": 2.305,
    "Spectral Type": "F2IV/V",
    "Color Index": 0.374,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.675726,
    "y": 26.963324,
    "z": -10.185168,
    "vx": -0.00000947,
    "vy": 0.00000759,
    "vz": 0.00000254,
    "rarad": 1.81350100232846,
    "decrad": -0.351448168757417,
    "pmrarad": 2.48951824965277e-7,
    "pmdecrad": 1.92713438e-7,
    "bayer": "Pi",
    "flam": 19,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 33215,
    "lum": 10.423174293933,
    "bf": "19Pi  CMa",
    "ra": 6.927064,
    "dec": -20.136497,
    "pmra": 51.35,
    "pmdec": 39.75,
    "rv": 8,
    "Asset Name": "ASTRID 003873",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "High Bun",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": -0.00000947,
    "y_1": 0.00000759,
    "z_1": 0.00000254,
    "Distance in Parsecs_1": 29.5858,
    "HYG Star ID_1": 33215,
    "Hipparcos Catalogue_1": 33302,
    "Henry Draper Catalogue_1": 51199,
    "Harvard Revised Catalogue_1": 2590
  },
  {
    "Astrid #": "ASTRID 003874",
    "HYG Star ID": 77982,
    "Hipparcos Catalogue": 78217,
    "Henry Draper Catalogue": 144061,
    "Distance in Parsecs": 29.5946,
    "mag": 7.26,
    "Absolute Visual Magnitude": 4.904,
    "Spectral Type": "G5",
    "Color Index": 0.654,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.903536,
    "y": -8.353737,
    "z": 27.964369,
    "vx": 0.0000116,
    "vy": 0.00003667,
    "vz": 0.00000303,
    "rarad": 4.18159786545453,
    "decrad": 1.23734148331771,
    "pmrarad": -2.89433767291666e-7,
    "pmdecrad": 0.000001230893453,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 77982,
    "lum": 0.951480736351758,
    "ra": 15.972527,
    "dec": 70.894445,
    "pmra": -59.7,
    "pmdec": 253.89,
    "rv": -9.2,
    "Asset Name": "ASTRID 003874",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Headband",
    "Outfit": "Spartan",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.0000116,
    "y_1": 0.00003667,
    "z_1": 0.00000303,
    "Distance in Parsecs_1": 29.5946,
    "HYG Star ID_1": 77982,
    "Hipparcos Catalogue_1": 78217,
    "Henry Draper Catalogue_1": 144061
  },
  {
    "Astrid #": "ASTRID 003875",
    "HYG Star ID": 108573,
    "Hipparcos Catalogue": 108917,
    "Henry Draper Catalogue": 209790,
    "Harvard Revised Catalogue": 8417,
    "Distance in Parsecs": 29.5946,
    "mag": 4.26,
    "Absolute Visual Magnitude": 1.904,
    "Spectral Type": "Am",
    "Color Index": 0.379,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 11.085515,
    "y": -6.15803,
    "z": 26.739992,
    "vx": 0.00000253,
    "vy": 0.00003379,
    "vz": -6e-8,
    "rarad": 5.77611529964991,
    "decrad": 1.12797088578868,
    "pmrarad": 0.00000103973141931944,
    "pmdecrad": 4.32890135e-7,
    "bayer": "Xi",
    "flam": 17,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 108573,
    "lum": 15.0799534180178,
    "bf": "17Xi  Cep",
    "ra": 22.063135,
    "dec": 64.627971,
    "pmra": 214.46,
    "pmdec": 89.29,
    "rv": -6,
    "Asset Name": "ASTRID 003875",
    "Astrium Color": "White",
    "Background": "Diesel",
    "Expression": "Battle Ready",
    "Hair": "Audiophile",
    "Outfit": "Garb",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": 0.00000253,
    "y_1": 0.00003379,
    "z_1": -6e-8,
    "Distance in Parsecs_1": 29.5946,
    "HYG Star ID_1": 108573,
    "Hipparcos Catalogue_1": 108917,
    "Henry Draper Catalogue_1": 209790,
    "Harvard Revised Catalogue_1": 8417
  },
  {
    "Astrid #": "ASTRID 003876",
    "HYG Star ID": 32521,
    "Hipparcos Catalogue": 32607,
    "Henry Draper Catalogue": 50241,
    "Harvard Revised Catalogue": 2550,
    "Gliese Catalog": "Gl 248",
    "Distance in Parsecs": 29.6033,
    "mag": 3.24,
    "Absolute Visual Magnitude": 0.883,
    "Spectral Type": "A7IV",
    "Color Index": 0.225,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -2.906455,
    "y": 13.618019,
    "z": -26.124095,
    "vx": 0.00000113,
    "vy": 0.00004172,
    "vz": -0.00000225,
    "rarad": 1.78107193481433,
    "decrad": -1.08108122479767,
    "pmrarad": -3.31564076131944e-7,
    "pmdecrad": 0.000001173394551,
    "bayer": "Alp",
    "con": "Pic",
    "Full Constellation Name Formula 1": "Pictor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pictor",
    "comp": 1,
    "comp_primary": 32521,
    "lum": 38.6189089448989,
    "bf": "Alp Pic",
    "ra": 6.803194,
    "dec": -61.941391,
    "pmra": -68.39,
    "pmdec": 242.03,
    "rv": 20.6,
    "Asset Name": "ASTRID 003876",
    "Astrium Color": "White",
    "Background": "Haze",
    "Expression": "Lovestruck",
    "Hair": "Greasy",
    "Outfit": "Suit",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pictor",
    "x_1": 0.00000113,
    "y_1": 0.00004172,
    "z_1": -0.00000225,
    "Distance in Parsecs_1": 29.6033,
    "HYG Star ID_1": 32521,
    "Hipparcos Catalogue_1": 32607,
    "Henry Draper Catalogue_1": 50241,
    "Harvard Revised Catalogue_1": 2550,
    "Gliese Catalog_1": "Gl 248"
  },
  {
    "Astrid #": "ASTRID 003877",
    "HYG Star ID": 38217,
    "Hipparcos Catalogue": 38325,
    "Henry Draper Catalogue": 63332,
    "Harvard Revised Catalogue": 3028,
    "Distance in Parsecs": 29.6033,
    "mag": 6.03,
    "Absolute Visual Magnitude": 3.673,
    "Spectral Type": "F6V",
    "Color Index": 0.496,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.083066,
    "y": 15.347911,
    "z": 23.988747,
    "vx": 0.00000856,
    "vy": -0.00000385,
    "vz": 0.00000282,
    "rarad": 2.05554167442575,
    "decrad": 0.944733465201378,
    "pmrarad": -1.95379913263888e-7,
    "pmdecrad": 2.58308729e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 38217,
    "lum": 2.95665056508294,
    "ra": 7.851591,
    "dec": 54.12924,
    "pmra": -40.3,
    "pmdec": 53.28,
    "rv": -2,
    "Asset Name": "ASTRID 003877",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Firm",
    "Hair": "Bucket Hat",
    "Outfit": "Demon Lord",
    "Special": "Demon Lord Wings",
    "Astral Crystal": "Ring",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 0.00000856,
    "y_1": -0.00000385,
    "z_1": 0.00000282,
    "Distance in Parsecs_1": 29.6033,
    "HYG Star ID_1": 38217,
    "Hipparcos Catalogue_1": 38325,
    "Henry Draper Catalogue_1": 63332,
    "Harvard Revised Catalogue_1": 3028
  },
  {
    "Astrid #": "ASTRID 003878",
    "HYG Star ID": 13046,
    "Hipparcos Catalogue": 13079,
    "Distance in Parsecs": 29.6209,
    "mag": 10.78,
    "Absolute Visual Magnitude": 8.422,
    "Spectral Type": "M0",
    "Color Index": 1.417,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 21.541105,
    "y": 19.414233,
    "z": -6.038715,
    "vx": 0.00000143,
    "vy": -0.00001118,
    "vz": -0.00003085,
    "rarad": 0.733513349560154,
    "decrad": -0.20530596418011,
    "pmrarad": -3.128502680625e-7,
    "pmdecrad": -0.000001064020584,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 13046,
    "lum": 0.0372563238466603,
    "var_min": 10.838,
    "var_max": 10.718,
    "ra": 2.801815,
    "dec": -11.763165,
    "pmra": -64.53,
    "pmdec": -219.47,
    "rv": 0,
    "Asset Name": "ASTRID 003878",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Chubby Cheeks",
    "Hair": "Bucket Hat",
    "Outfit": "Basketball",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00000143,
    "y_1": -0.00001118,
    "z_1": -0.00003085,
    "Distance in Parsecs_1": 29.6209,
    "HYG Star ID_1": 13046,
    "Hipparcos Catalogue_1": 13079
  },
  {
    "Astrid #": "ASTRID 003879",
    "HYG Star ID": 21380,
    "Hipparcos Catalogue": 21433,
    "Henry Draper Catalogue": 237287,
    "Gliese Catalog": "Gl 171",
    "Distance in Parsecs": 29.6384,
    "mag": 8.32,
    "Absolute Visual Magnitude": 5.961,
    "Spectral Type": "K2V",
    "Color Index": 0.902,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.022453,
    "y": 15.710228,
    "z": 24.400412,
    "vx": -0.00005016,
    "vy": 0.00008876,
    "vz": 0.00001735,
    "rarad": 1.20471112090954,
    "decrad": 0.967126226863543,
    "pmrarad": 0.00000265256109042361,
    "pmdecrad": -0.000001471215595,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 21380,
    "lum": 0.359418146314714,
    "ra": 4.601658,
    "dec": 55.412251,
    "pmra": 547.13,
    "pmdec": -303.46,
    "rv": 50,
    "Asset Name": "ASTRID 003879",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Laughing",
    "Hair": "Unkempt",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00005016,
    "y_1": 0.00008876,
    "z_1": 0.00001735,
    "Distance in Parsecs_1": 29.6384,
    "HYG Star ID_1": 21380,
    "Hipparcos Catalogue_1": 21433,
    "Henry Draper Catalogue_1": 237287,
    "Gliese Catalog_1": "Gl 171"
  },
  {
    "Astrid #": "ASTRID 003880",
    "HYG Star ID": 46501,
    "Hipparcos Catalogue": 46634,
    "Distance in Parsecs": 29.6384,
    "mag": 8.67,
    "Absolute Visual Magnitude": 6.311,
    "Spectral Type": "G5",
    "Color Index": 0.878,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -23.157537,
    "y": 17.675942,
    "z": 5.452,
    "vx": 0.00001684,
    "vy": 0.00002253,
    "vz": -0.00000153,
    "rarad": 2.48963949705067,
    "decrad": 0.185004078154232,
    "pmrarad": -9.490227796875e-7,
    "pmdecrad": -5.2553802e-8,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 46501,
    "lum": 0.260375429893873,
    "var": "GS",
    "ra": 9.509722,
    "dec": 10.599953,
    "pmra": -195.75,
    "pmdec": -10.84,
    "rv": 0,
    "Asset Name": "ASTRID 003880",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Star of Culture",
    "Hair": "Innocent",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00001684,
    "y_1": 0.00002253,
    "z_1": -0.00000153,
    "Distance in Parsecs_1": 29.6384,
    "HYG Star ID_1": 46501,
    "Hipparcos Catalogue_1": 46634
  },
  {
    "Astrid #": "ASTRID 003881",
    "HYG Star ID": 57197,
    "Hipparcos Catalogue": 57370,
    "Henry Draper Catalogue": 102195,
    "Distance in Parsecs": 29.6384,
    "mag": 8.07,
    "Absolute Visual Magnitude": 5.711,
    "Spectral Type": "K0",
    "Color Index": 0.835,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -29.544905,
    "y": 1.845236,
    "z": 1.458929,
    "vx": 9.2e-7,
    "vy": 0.00002734,
    "vz": -0.00001599,
    "rarad": 3.07921837239777,
    "decrad": 0.0492441735818062,
    "pmrarad": -9.22697396833333e-7,
    "pmdecrad": -5.40276365e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 57197,
    "lum": 0.452480637855548,
    "ra": 11.761748,
    "dec": 2.821483,
    "pmra": -190.32,
    "pmdec": -111.44,
    "rv": 0,
    "Asset Name": "ASTRID 003881",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Uwu",
    "Hair": "Manbun",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": 9.2e-7,
    "y_1": 0.00002734,
    "z_1": -0.00001599,
    "Distance in Parsecs_1": 29.6384,
    "HYG Star ID_1": 57197,
    "Hipparcos Catalogue_1": 57370,
    "Henry Draper Catalogue_1": 102195
  },
  {
    "Astrid #": "ASTRID 003882",
    "HYG Star ID": 94928,
    "Hipparcos Catalogue": 95223,
    "Distance in Parsecs": 29.6472,
    "mag": 9.95,
    "Absolute Visual Magnitude": 7.59,
    "Spectral Type": "K6",
    "Color Index": 1.603,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.656027,
    "y": -23.039466,
    "z": 16.529146,
    "vx": -0.00000425,
    "vy": 0.00006077,
    "vz": 0.00008694,
    "rarad": 5.07177591423747,
    "decrad": 0.59140512190207,
    "pmrarad": 5.86769997576388e-7,
    "pmdecrad": 0.000003532788808,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 94928,
    "lum": 0.0801678063387679,
    "var_min": 10.006,
    "var_max": 9.886,
    "ra": 19.372757,
    "dec": 33.885017,
    "pmra": 121.03,
    "pmdec": 728.69,
    "rv": 0,
    "Asset Name": "ASTRID 003882",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Happy Vamp",
    "Hair": "Audiohead",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000425,
    "y_1": 0.00006077,
    "z_1": 0.00008694,
    "Distance in Parsecs_1": 29.6472,
    "HYG Star ID_1": 94928,
    "Hipparcos Catalogue_1": 95223
  },
  {
    "Astrid #": "ASTRID 003883",
    "HYG Star ID": 110122,
    "Hipparcos Catalogue": 110468,
    "Henry Draper Catalogue": 212038,
    "Distance in Parsecs": 29.6472,
    "mag": 8.75,
    "Absolute Visual Magnitude": 6.39,
    "Spectral Type": "K0V",
    "Color Index": 0.833,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 17.068238,
    "y": -7.724746,
    "z": -22.977376,
    "vx": -0.00009784,
    "vy": 0.00007034,
    "vz": -0.00009581,
    "rarad": 5.85818374155754,
    "decrad": -0.886757747625468,
    "pmrarad": 8.00863718909722e-7,
    "pmdecrad": -0.000005130928625,
    "con": "Gru",
    "Full Constellation Name Formula 1": "Grus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Grus",
    "comp": 1,
    "comp_primary": 110122,
    "lum": 0.242102904673617,
    "ra": 22.376614,
    "dec": -50.807476,
    "pmra": 165.19,
    "pmdec": -1058.33,
    "rv": -0.4,
    "Asset Name": "ASTRID 003883",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Einstein Tongue",
    "Hair": "Braid",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Grus",
    "x_1": -0.00009784,
    "y_1": 0.00007034,
    "z_1": -0.00009581,
    "Distance in Parsecs_1": 29.6472,
    "HYG Star ID_1": 110122,
    "Hipparcos Catalogue_1": 110468,
    "Henry Draper Catalogue_1": 212038
  },
  {
    "Astrid #": "ASTRID 003884",
    "HYG Star ID": 11003,
    "Hipparcos Catalogue": 11029,
    "Henry Draper Catalogue": 14691,
    "Harvard Revised Catalogue": 692,
    "Distance in Parsecs": 29.656,
    "mag": 5.43,
    "Absolute Visual Magnitude": 3.069,
    "Spectral Type": "F0V",
    "Color Index": 0.364,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 23.715737,
    "y": 16.920257,
    "z": -5.545577,
    "vx": -0.00000479,
    "vy": 0.00002368,
    "vz": -0.00001386,
    "rarad": 0.619703151684263,
    "decrad": -0.188103445871826,
    "pmrarad": 7.43849630076388e-7,
    "pmdecrad": -3.97013923e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 11003,
    "lum": 5.15703406302645,
    "ra": 2.367092,
    "dec": -10.777534,
    "pmra": 153.43,
    "pmdec": -81.89,
    "rv": 12,
    "Asset Name": "ASTRID 003884",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Oh Snap",
    "Hair": "Good Boy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000479,
    "y_1": 0.00002368,
    "z_1": -0.00001386,
    "Distance in Parsecs_1": 29.656,
    "HYG Star ID_1": 11003,
    "Hipparcos Catalogue_1": 11029,
    "Henry Draper Catalogue_1": 14691,
    "Harvard Revised Catalogue_1": 692
  },
  {
    "Astrid #": "ASTRID 003885",
    "HYG Star ID": 14587,
    "Hipparcos Catalogue": 14623,
    "Henry Draper Catalogue": 19632,
    "Distance in Parsecs": 29.656,
    "mag": 7.29,
    "Absolute Visual Magnitude": 4.929,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.678,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.27194,
    "y": 19.7447,
    "z": -12.480438,
    "vx": -0.00001823,
    "vy": 0.00002811,
    "vz": 0.00001778,
    "rarad": 0.824117696372625,
    "decrad": -0.434371403142421,
    "pmrarad": 0.00000109524258574305,
    "pmdecrad": 6.60898009e-7,
    "con": "For",
    "Full Constellation Name Formula 1": "Fornax",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Fornax",
    "comp": 1,
    "comp_primary": 14587,
    "lum": 0.92982239058538,
    "ra": 3.147898,
    "dec": -24.887648,
    "pmra": 225.91,
    "pmdec": 136.32,
    "rv": 0,
    "Asset Name": "ASTRID 003885",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Nervous",
    "Hair": "Elegant",
    "Outfit": "Tracksuit",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Fornax",
    "x_1": -0.00001823,
    "y_1": 0.00002811,
    "z_1": 0.00001778,
    "Distance in Parsecs_1": 29.656,
    "HYG Star ID_1": 14587,
    "Hipparcos Catalogue_1": 14623,
    "Henry Draper Catalogue_1": 19632
  },
  {
    "Astrid #": "ASTRID 003886",
    "HYG Star ID": 112070,
    "Hipparcos Catalogue": 112426,
    "Henry Draper Catalogue": 215704,
    "Gliese Catalog": "Gl 871.2",
    "Distance in Parsecs": 29.6736,
    "mag": 7.87,
    "Absolute Visual Magnitude": 5.508,
    "Spectral Type": "K0",
    "Color Index": 0.803,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 18.017673,
    "y": -5.998954,
    "z": 22.800692,
    "vx": -0.00002033,
    "vy": 0.0000435,
    "vz": -0.00004011,
    "rarad": 5.96177547723183,
    "decrad": 0.876329224157611,
    "pmrarad": 0.00000117465506661805,
    "pmdecrad": -9.696273e-9,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 112070,
    "lum": 0.545506588170373,
    "ra": 22.772305,
    "dec": 50.209966,
    "pmra": 242.29,
    "pmdec": -2,
    "rv": -50.8,
    "Asset Name": "ASTRID 003886",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Happy Vamp",
    "Hair": "Braid",
    "Outfit": "Samurai",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": -0.00002033,
    "y_1": 0.0000435,
    "z_1": -0.00004011,
    "Distance in Parsecs_1": 29.6736,
    "HYG Star ID_1": 112070,
    "Hipparcos Catalogue_1": 112426,
    "Henry Draper Catalogue_1": 215704,
    "Gliese Catalog_1": "Gl 871.2"
  },
  {
    "Astrid #": "ASTRID 003887",
    "HYG Star ID": 32765,
    "Hipparcos Catalogue": 32851,
    "Henry Draper Catalogue": 49933,
    "Harvard Revised Catalogue": 2530,
    "Distance in Parsecs": 29.6824,
    "mag": 5.78,
    "Absolute Visual Magnitude": 3.418,
    "Spectral Type": "F2V",
    "Color Index": 0.396,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.5291,
    "y": 28.953916,
    "z": -0.280201,
    "vx": 3.3e-7,
    "vy": -0.00001591,
    "vz": -0.00002677,
    "rarad": 1.79258635535687,
    "decrad": -0.00944017073333,
    "pmrarad": 1.07240786138888e-7,
    "pmdecrad": -9.06940952e-7,
    "con": "Mon",
    "Full Constellation Name Formula 1": "Monoceros",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Monoceros",
    "comp": 1,
    "comp_primary": 32765,
    "lum": 3.73938343643151,
    "ra": 6.847176,
    "dec": -0.540882,
    "pmra": 22.12,
    "pmdec": -187.07,
    "rv": -15,
    "Asset Name": "ASTRID 003887",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Serious Shinobi",
    "Hair": "Prince",
    "Outfit": "Warlock",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Monoceros",
    "x_1": 3.3e-7,
    "y_1": -0.00001591,
    "z_1": -0.00002677,
    "Distance in Parsecs_1": 29.6824,
    "HYG Star ID_1": 32765,
    "Hipparcos Catalogue_1": 32851,
    "Henry Draper Catalogue_1": 49933,
    "Harvard Revised Catalogue_1": 2530
  },
  {
    "Astrid #": "ASTRID 003888",
    "HYG Star ID": 88361,
    "Hipparcos Catalogue": 88635,
    "Henry Draper Catalogue": 165135,
    "Harvard Revised Catalogue": 6746,
    "Proper Name": "Nash",
    "Distance in Parsecs": 29.7,
    "mag": 2.98,
    "Absolute Visual Magnitude": 0.616,
    "Spectral Type": "K0III",
    "Color Index": 0.981,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 0.648969,
    "y": -25.602281,
    "z": -15.040072,
    "vx": -0.00000787,
    "vy": -0.00000637,
    "vz": -0.00003393,
    "rarad": 4.73773203401826,
    "decrad": -0.531000563719897,
    "pmrarad": -2.70283626909722e-7,
    "pmdecrad": -8.80082274e-7,
    "bayer": "Gam-2",
    "flam": 10,
    "con": "Sgr",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sagittarius",
    "FULL CONSTELLATION NAME": "Sagittarius",
    "comp": 1,
    "comp_primary": 88361,
    "lum": 49.3855619617607,
    "bf": "10Gam2Sgr",
    "ra": 18.096803,
    "dec": -30.424091,
    "pmra": -55.75,
    "pmdec": -181.53,
    "rv": 22,
    "Asset Name": "ASTRID 003888",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Oh no",
    "Hair": "Skater Helmet",
    "Outfit": "Skater",
    "Special": "V1 Flight System",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "Perfect",
    "Proper Name_1": "Nash",
    "Constellation": "Sagittarius",
    "x_1": -0.00000787,
    "y_1": -0.00000637,
    "z_1": -0.00003393,
    "Distance in Parsecs_1": 29.7,
    "HYG Star ID_1": 88361,
    "Hipparcos Catalogue_1": 88635,
    "Henry Draper Catalogue_1": 165135,
    "Harvard Revised Catalogue_1": 6746
  },
  {
    "Astrid #": "ASTRID 003889",
    "HYG Star ID": 96350,
    "Hipparcos Catalogue": 96656,
    "Henry Draper Catalogue": 186922,
    "Gliese Catalog": "Gl 765.2",
    "Distance in Parsecs": 29.7,
    "mag": 8.04,
    "Absolute Visual Magnitude": 5.676,
    "Spectral Type": "K0",
    "Color Index": 0.881,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 2.922088,
    "y": -6.33078,
    "z": 28.869891,
    "vx": 0.00001201,
    "vy": 0.00002834,
    "vz": 0.000001,
    "rarad": 5.144799572306,
    "decrad": 1.33381592593304,
    "pmrarad": 7.67120686743055e-7,
    "pmdecrad": 6.85478062e-7,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 96350,
    "lum": 0.467304539631509,
    "ra": 19.651687,
    "dec": 76.422023,
    "pmra": 158.23,
    "pmdec": 141.39,
    "rv": -3.8,
    "Asset Name": "ASTRID 003889",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Wavy",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001201,
    "y_1": 0.00002834,
    "z_1": 0.000001,
    "Distance in Parsecs_1": 29.7,
    "HYG Star ID_1": 96350,
    "Hipparcos Catalogue_1": 96656,
    "Henry Draper Catalogue_1": 186922,
    "Gliese Catalog_1": "Gl 765.2"
  },
  {
    "Astrid #": "ASTRID 003890",
    "HYG Star ID": 15163,
    "Hipparcos Catalogue": 15201,
    "Henry Draper Catalogue": 21024,
    "Harvard Revised Catalogue": 1025,
    "Distance in Parsecs": 29.7089,
    "mag": 5.51,
    "Absolute Visual Magnitude": 3.146,
    "Spectral Type": "F4III",
    "Color Index": 0.438,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 4.256475,
    "y": 4.894832,
    "z": -28.992273,
    "vx": -0.0000038,
    "vy": 0.00002047,
    "vz": -0.00001701,
    "rarad": 0.855024006079505,
    "decrad": -1.35068332788579,
    "pmrarad": 5.48809086388889e-7,
    "pmdecrad": 3.00584481e-7,
    "bayer": "Iot",
    "con": "Hyi",
    "Full Constellation Name Formula 1": "Hydrus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydrus",
    "comp": 1,
    "comp_primary": 15163,
    "lum": 4.8039668292968,
    "bf": "Iot Hyi",
    "ra": 3.265951,
    "dec": -77.388454,
    "pmra": 113.2,
    "pmdec": 62,
    "rv": 19,
    "Asset Name": "ASTRID 003890",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Mortified",
    "Hair": "Bob",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydrus",
    "x_1": -0.0000038,
    "y_1": 0.00002047,
    "z_1": -0.00001701,
    "Distance in Parsecs_1": 29.7089,
    "HYG Star ID_1": 15163,
    "Hipparcos Catalogue_1": 15201,
    "Henry Draper Catalogue_1": 21024,
    "Harvard Revised Catalogue_1": 1025
  },
  {
    "Astrid #": "ASTRID 003891",
    "HYG Star ID": 30820,
    "Hipparcos Catalogue": 30893,
    "Henry Draper Catalogue": 257886,
    "Distance in Parsecs": 29.7089,
    "mag": 8.59,
    "Absolute Visual Magnitude": 6.226,
    "Spectral Type": "K2V",
    "Color Index": 0.906,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.350833,
    "y": 26.255391,
    "z": 13.491464,
    "vx": 0.0000374,
    "vy": -0.0000117,
    "vz": -0.00007604,
    "rarad": 1.69773595038954,
    "decrad": 0.471393802730042,
    "pmrarad": -0.00000119904119474999,
    "pmdecrad": -0.000002030545138,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 30820,
    "lum": 0.28157882997103,
    "ra": 6.484874,
    "dec": 27.008875,
    "pmra": -247.32,
    "pmdec": -418.83,
    "rv": -48,
    "Asset Name": "ASTRID 003891",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Middle Part",
    "Outfit": "Sailor",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.0000374,
    "y_1": -0.0000117,
    "z_1": -0.00007604,
    "Distance in Parsecs_1": 29.7089,
    "HYG Star ID_1": 30820,
    "Hipparcos Catalogue_1": 30893,
    "Henry Draper Catalogue_1": 257886
  },
  {
    "Astrid #": "ASTRID 003892",
    "HYG Star ID": 104471,
    "Hipparcos Catalogue": 104809,
    "Henry Draper Catalogue": 201989,
    "Distance in Parsecs": 29.7265,
    "mag": 7.38,
    "Absolute Visual Magnitude": 5.014,
    "Spectral Type": "G3/G5V",
    "Color Index": 0.689,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 19.348326,
    "y": -17.113437,
    "z": -14.71181,
    "vx": 0.00002012,
    "vy": 0.00002678,
    "vz": -0.0000047,
    "rarad": 5.55900313399135,
    "decrad": -0.517726156794887,
    "pmrarad": 0.00000112331329784722,
    "pmdecrad": -1.8180513e-7,
    "con": "Mic",
    "Full Constellation Name Formula 1": "Microscopium",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Microscopium",
    "comp": 1,
    "comp_primary": 104471,
    "lum": 0.859805066661174,
    "ra": 21.233828,
    "dec": -29.663524,
    "pmra": 231.7,
    "pmdec": -37.5,
    "rv": 0,
    "Asset Name": "ASTRID 003892",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Shy Smile",
    "Hair": "Middle Part",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Microscopium",
    "x_1": 0.00002012,
    "y_1": 0.00002678,
    "z_1": -0.0000047,
    "Distance in Parsecs_1": 29.7265,
    "HYG Star ID_1": 104471,
    "Hipparcos Catalogue_1": 104809,
    "Henry Draper Catalogue_1": 201989
  },
  {
    "Astrid #": "ASTRID 003893",
    "HYG Star ID": 36997,
    "Hipparcos Catalogue": 37103,
    "Distance in Parsecs": 29.7354,
    "mag": 10.47,
    "Absolute Visual Magnitude": 8.104,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -9.548693,
    "y": 21.088637,
    "z": 18.662419,
    "vx": 1.7e-7,
    "vy": -4e-8,
    "vz": 1.3e-7,
    "rarad": 1.99596676354828,
    "decrad": 0.678487463208582,
    "pmrarad": -4.50876722916666e-9,
    "pmdecrad": 5.623838e-9,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 36997,
    "lum": 0.049934418872135,
    "var_min": 10.932,
    "var_max": 8.892,
    "ra": 7.624031,
    "dec": 38.874468,
    "pmra": -0.93,
    "pmdec": 1.16,
    "rv": 0,
    "Asset Name": "ASTRID 003893",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Mischievous",
    "Hair": "Ninja",
    "Outfit": "Garb",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": 1.7e-7,
    "y_1": -4e-8,
    "z_1": 1.3e-7,
    "Distance in Parsecs_1": 29.7354,
    "HYG Star ID_1": 36997,
    "Hipparcos Catalogue_1": 37103
  },
  {
    "Astrid #": "ASTRID 003894",
    "HYG Star ID": 79580,
    "Hipparcos Catalogue": 79822,
    "Henry Draper Catalogue": 148048,
    "Harvard Revised Catalogue": 6116,
    "Gliese Catalog": "NN 3950A",
    "Distance in Parsecs": 29.7354,
    "mag": 4.95,
    "Absolute Visual Magnitude": 2.584,
    "Spectral Type": "F5V",
    "Color Index": 0.393,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.16421,
    "y": -6.597182,
    "z": 28.821068,
    "vx": 0.00000492,
    "vy": 0.00004024,
    "vz": -2.7e-7,
    "rarad": 4.26518097463753,
    "decrad": 1.32217994018459,
    "pmrarad": -4.36041424291666e-7,
    "pmdecrad": 0.000001249849668,
    "bayer": "Eta",
    "flam": 21,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 79580,
    "base": "NN 3950",
    "lum": 8.0612056386389,
    "bf": "21Eta UMi",
    "ra": 16.291791,
    "dec": 75.75533,
    "pmra": -89.94,
    "pmdec": 257.8,
    "rv": -9.5,
    "Asset Name": "ASTRID 003894",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Owie",
    "Hair": "Fire",
    "Outfit": "Astro",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00000492,
    "y_1": 0.00004024,
    "z_1": -2.7e-7,
    "Distance in Parsecs_1": 29.7354,
    "HYG Star ID_1": 79580,
    "Hipparcos Catalogue_1": 79822,
    "Henry Draper Catalogue_1": 148048,
    "Harvard Revised Catalogue_1": 6116,
    "Gliese Catalog_1": "NN 3950A"
  },
  {
    "Astrid #": "ASTRID 003895",
    "HYG Star ID": 676,
    "Hipparcos Catalogue": 677,
    "Henry Draper Catalogue": 358,
    "Harvard Revised Catalogue": 15,
    "Proper Name": "Alpheratz",
    "Distance in Parsecs": 29.7442,
    "mag": 2.07,
    "Absolute Visual Magnitude": -0.297,
    "Spectral Type": "B9p",
    "Color Index": -0.038,
    "Spectral Abbreviation": "B",
    "Star Type": "Teal",
    "Color In Star Map": "Teal",
    "x": 25.974572,
    "y": 0.951042,
    "z": 14.461264,
    "vx": -2e-8,
    "vy": 0.00001958,
    "vz": -0.0000265,
    "rarad": 0.0365971627080155,
    "decrad": 0.507723818465944,
    "pmrarad": 6.57795201777777e-7,
    "pmdecrad": -7.90003892e-7,
    "bayer": "Alp",
    "flam": 21,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 676,
    "lum": 114.498553469018,
    "var": "Alp",
    "var_min": 2.083,
    "var_max": 2.063,
    "bf": "21Alp And",
    "ra": 0.139791,
    "dec": 29.090432,
    "pmra": 135.68,
    "pmdec": -162.95,
    "rv": -12,
    "Asset Name": "ASTRID 003895",
    "Astrium Color": "Teal",
    "Background": "Velvet Nothingness",
    "Expression": "Bring It",
    "Hair": "Mech Head",
    "Outfit": "Mecha",
    "Special": "Mech Energy",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "Perfect",
    "Proper Name_1": "Alpheratz",
    "Constellation": "Andromeda",
    "x_1": -2e-8,
    "y_1": 0.00001958,
    "z_1": -0.0000265,
    "Distance in Parsecs_1": 29.7442,
    "HYG Star ID_1": 676,
    "Hipparcos Catalogue_1": 677,
    "Henry Draper Catalogue_1": 358,
    "Harvard Revised Catalogue_1": 15
  },
  {
    "Astrid #": "ASTRID 003896",
    "HYG Star ID": 32884,
    "Hipparcos Catalogue": 32971,
    "Henry Draper Catalogue": 50255,
    "Distance in Parsecs": 29.7619,
    "mag": 7.41,
    "Absolute Visual Magnitude": 5.042,
    "Spectral Type": "G5",
    "Color Index": 0.721,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.572292,
    "y": 28.445241,
    "z": -5.783078,
    "vx": 0.00000794,
    "vy": 0.00000223,
    "vz": 0.00000196,
    "rarad": 1.79786241847701,
    "decrad": -0.195555452193792,
    "pmrarad": -2.76974055701388e-7,
    "pmdecrad": 6.7292138e-8,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 32884,
    "lum": 0.837915067438409,
    "ra": 6.867329,
    "dec": -11.204502,
    "pmra": -57.13,
    "pmdec": 13.88,
    "rv": 0,
    "Asset Name": "ASTRID 003896",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Oof",
    "Hair": "Lion Mane",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00000794,
    "y_1": 0.00000223,
    "z_1": 0.00000196,
    "Distance in Parsecs_1": 29.7619,
    "HYG Star ID_1": 32884,
    "Hipparcos Catalogue_1": 32971,
    "Henry Draper Catalogue_1": 50255
  },
  {
    "Astrid #": "ASTRID 003897",
    "HYG Star ID": 48813,
    "Hipparcos Catalogue": 48953,
    "Distance in Parsecs": 29.7619,
    "mag": 9.88,
    "Absolute Visual Magnitude": 7.512,
    "Spectral Type": "K4V",
    "Color Index": 1.09,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.392074,
    "y": 14.780123,
    "z": -4.749866,
    "vx": 0.0000064,
    "vy": 0.00000723,
    "vz": -0.00001169,
    "rarad": 2.61445667332104,
    "decrad": -0.160280921780271,
    "pmrarad": -3.18134737180555e-7,
    "pmdecrad": -3.98032031e-7,
    "con": "Sex",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Sextans",
    "FULL CONSTELLATION NAME": "Sextans",
    "comp": 1,
    "comp_primary": 48813,
    "lum": 0.0861390345772427,
    "var_min": 9.926,
    "var_max": 9.826,
    "ra": 9.986489,
    "dec": -9.18342,
    "pmra": -65.62,
    "pmdec": -82.1,
    "rv": 0,
    "Asset Name": "ASTRID 003897",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Awkward",
    "Hair": "Prince",
    "Outfit": "Spartan",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Sextans",
    "x_1": 0.0000064,
    "y_1": 0.00000723,
    "z_1": -0.00001169,
    "Distance in Parsecs_1": 29.7619,
    "HYG Star ID_1": 48813,
    "Hipparcos Catalogue_1": 48953
  },
  {
    "Astrid #": "ASTRID 003898",
    "HYG Star ID": 22296,
    "Hipparcos Catalogue": 22349,
    "Henry Draper Catalogue": 30455,
    "Distance in Parsecs": 29.7796,
    "mag": 6.95,
    "Absolute Visual Magnitude": 4.58,
    "Spectral Type": "G2V...",
    "Color Index": 0.618,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.634016,
    "y": 26.852487,
    "z": 9.552523,
    "vx": -0.00000487,
    "vy": 0.00007452,
    "vz": -0.00003514,
    "rarad": 1.25970106539061,
    "decrad": 0.326541161081259,
    "pmrarad": 9.21873213576388e-7,
    "pmdecrad": -0.000001866047856,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 22296,
    "lum": 1.28233058265602,
    "ra": 4.811704,
    "dec": 18.70943,
    "pmra": 190.15,
    "pmdec": -384.9,
    "rv": 53.3,
    "Asset Name": "ASTRID 003898",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Fired Up",
    "Hair": "Ninja",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00000487,
    "y_1": 0.00007452,
    "z_1": -0.00003514,
    "Distance in Parsecs_1": 29.7796,
    "HYG Star ID_1": 22296,
    "Hipparcos Catalogue_1": 22349,
    "Henry Draper Catalogue_1": 30455
  },
  {
    "Astrid #": "ASTRID 003899",
    "HYG Star ID": 50628,
    "Hipparcos Catalogue": 50773,
    "Distance in Parsecs": 29.7974,
    "mag": 10.63,
    "Absolute Visual Magnitude": 8.259,
    "Spectral Type": "K4",
    "Color Index": 1.461,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -21.680071,
    "y": 9.870294,
    "z": 17.900751,
    "vx": -0.00002957,
    "vy": -0.00001085,
    "vz": -0.00002983,
    "rarad": 2.71436290377581,
    "decrad": 0.644437368332175,
    "pmrarad": 7.42831521347222e-7,
    "pmdecrad": -0.00000125241918,
    "con": "LMi",
    "Full Constellation Name Formula 1": "Leo Minor",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo Minor",
    "comp": 1,
    "comp_primary": 50628,
    "lum": 0.0432912374502826,
    "var_min": 10.678,
    "var_max": 10.548,
    "ra": 10.368103,
    "dec": 36.923541,
    "pmra": 153.22,
    "pmdec": -258.33,
    "rv": 0,
    "Asset Name": "ASTRID 003899",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Owie",
    "Hair": "Elegant",
    "Outfit": "Gas Punk",
    "Special": "Tiny Devil Wings",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo Minor",
    "x_1": -0.00002957,
    "y_1": -0.00001085,
    "z_1": -0.00002983,
    "Distance in Parsecs_1": 29.7974,
    "HYG Star ID_1": 50628,
    "Hipparcos Catalogue_1": 50773
  },
  {
    "Astrid #": "ASTRID 003900",
    "HYG Star ID": 108899,
    "Hipparcos Catalogue": 109245,
    "Henry Draper Catalogue": 209635,
    "Distance in Parsecs": 29.7974,
    "mag": 6.67,
    "Absolute Visual Magnitude": 4.299,
    "Spectral Type": "F7/F8V",
    "Color Index": 0.544,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 8.872705,
    "y": -4.723236,
    "z": -28.050867,
    "vx": 0.00000632,
    "vy": 0.00000869,
    "vz": 5.4e-7,
    "rarad": 5.79400080301801,
    "decrad": -1.22671699936684,
    "pmrarad": 3.57259201201388e-7,
    "pmdecrad": 5.3329504e-8,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 108899,
    "lum": 1.66111614760001,
    "ra": 22.131453,
    "dec": -70.285707,
    "pmra": 73.69,
    "pmdec": 11,
    "rv": 0,
    "Asset Name": "ASTRID 003900",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Bring It",
    "Hair": "Audiophile",
    "Outfit": "School Uniform",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00000632,
    "y_1": 0.00000869,
    "z_1": 5.4e-7,
    "Distance in Parsecs_1": 29.7974,
    "HYG Star ID_1": 108899,
    "Hipparcos Catalogue_1": 109245,
    "Henry Draper Catalogue_1": 209635
  },
  {
    "Astrid #": "ASTRID 003901",
    "HYG Star ID": 119465,
    "Gliese Catalog": "Gl 847B",
    "Distance in Parsecs": 29.7974,
    "mag": 13.7,
    "Absolute Visual Magnitude": 11.329,
    "Spectral Type": "m",
    "Color Index": 1.61,
    "Spectral Abbreviation": "m",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 8.948204,
    "y": -4.95379,
    "z": -27.987045,
    "vx": 0.00003439,
    "vy": 0.00008416,
    "vz": -0.00000392,
    "rarad": 5.77728708454737,
    "decrad": -1.2204226552017,
    "pmrarad": 0.00000303023094757638,
    "pmdecrad": -3.82804034e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 2,
    "comp_primary": 108584,
    "base": "Gl 847",
    "lum": 0.00256094351715676,
    "ra": 22.067611,
    "dec": -69.925067,
    "pmra": 625.03,
    "pmdec": -78.96,
    "rv": 0,
    "Asset Name": "ASTRID 003901",
    "Astrium Color": "Red",
    "Background": "Supple Emptiness",
    "Expression": "Smitten",
    "Hair": "Elegant",
    "Outfit": "Football",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00003439,
    "y_1": 0.00008416,
    "z_1": -0.00000392,
    "Distance in Parsecs_1": 29.7974,
    "HYG Star ID_1": 119465,
    "Gliese Catalog_1": "Gl 847B"
  },
  {
    "Astrid #": "ASTRID 003902",
    "HYG Star ID": 24647,
    "Hipparcos Catalogue": 24710,
    "Henry Draper Catalogue": 34659,
    "Distance in Parsecs": 29.824,
    "mag": 9.28,
    "Absolute Visual Magnitude": 6.907,
    "Spectral Type": "K3V",
    "Color Index": 1.015,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 4.820577,
    "y": 26.013564,
    "z": -13.766898,
    "vx": 0.00000543,
    "vy": -0.00000844,
    "vz": -0.00001405,
    "rarad": 1.38756500202365,
    "decrad": -0.47980329939371,
    "pmrarad": -2.30625867840277e-7,
    "pmdecrad": -5.31016424e-7,
    "con": "Col",
    "Full Constellation Name Formula 1": "Columba",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Columba",
    "comp": 1,
    "comp_primary": 24647,
    "lum": 0.150383434796135,
    "var": "VW",
    "var_min": 10.183,
    "var_max": 9.093,
    "ra": 5.300108,
    "dec": -27.490704,
    "pmra": -47.57,
    "pmdec": -109.53,
    "rv": 0,
    "Asset Name": "ASTRID 003902",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Curly",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Columba",
    "x_1": 0.00000543,
    "y_1": -0.00000844,
    "z_1": -0.00001405,
    "Distance in Parsecs_1": 29.824,
    "HYG Star ID_1": 24647,
    "Hipparcos Catalogue_1": 24710,
    "Henry Draper Catalogue_1": 34659
  },
  {
    "Astrid #": "ASTRID 003903",
    "HYG Star ID": 35839,
    "Hipparcos Catalogue": 35937,
    "Distance in Parsecs": 29.824,
    "mag": 10.12,
    "Absolute Visual Magnitude": 7.747,
    "Spectral Type": "M...",
    "Color Index": 1.274,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -1.832609,
    "y": 4.742035,
    "z": -29.387508,
    "vx": -0.00002697,
    "vy": -9.6e-7,
    "vz": 0.00000153,
    "rarad": 1.93954012740231,
    "decrad": -1.39949871558263,
    "pmrarad": 8.55017407027777e-7,
    "pmdecrad": 3.00390556e-7,
    "con": "Men",
    "Full Constellation Name Formula 1": "Mensa",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Mensa",
    "comp": 1,
    "comp_primary": 35839,
    "lum": 0.0693745213942123,
    "ra": 7.408498,
    "dec": -80.18537,
    "pmra": 176.36,
    "pmdec": 61.96,
    "rv": 0,
    "Asset Name": "ASTRID 003903",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Laughing",
    "Hair": "Ninja",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Mensa",
    "x_1": -0.00002697,
    "y_1": -9.6e-7,
    "z_1": 0.00000153,
    "Distance in Parsecs_1": 29.824,
    "HYG Star ID_1": 35839,
    "Hipparcos Catalogue_1": 35937
  },
  {
    "Astrid #": "ASTRID 003904",
    "HYG Star ID": 108116,
    "Hipparcos Catalogue": 108461,
    "Henry Draper Catalogue": 209943,
    "Distance in Parsecs": 29.824,
    "mag": 7.45,
    "Absolute Visual Magnitude": 5.077,
    "Spectral Type": "F5",
    "Color Index": 0.698,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.191774,
    "y": -1.873928,
    "z": 29.593291,
    "vx": -0.00000655,
    "vy": -0.00001831,
    "vz": -0.00001797,
    "rarad": 5.75233597776617,
    "decrad": 1.44637285469839,
    "pmrarad": -6.40826722958333e-7,
    "pmdecrad": -1.95234469e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 108116,
    "lum": 0.811334605228263,
    "var": "V376",
    "ra": 21.972305,
    "dec": 82.87106,
    "pmra": -132.18,
    "pmdec": -40.27,
    "rv": -17,
    "Asset Name": "ASTRID 003904",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Firm",
    "Hair": "Prince",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000655,
    "y_1": -0.00001831,
    "z_1": -0.00001797,
    "Distance in Parsecs_1": 29.824,
    "HYG Star ID_1": 108116,
    "Hipparcos Catalogue_1": 108461,
    "Henry Draper Catalogue_1": 209943
  },
  {
    "Astrid #": "ASTRID 003905",
    "HYG Star ID": 60628,
    "Hipparcos Catalogue": 60816,
    "Henry Draper Catalogue": 108510,
    "Distance in Parsecs": 29.8329,
    "mag": 6.75,
    "Absolute Visual Magnitude": 4.377,
    "Spectral Type": "G0",
    "Color Index": 0.584,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -29.272723,
    "y": -3.584496,
    "z": -4.501223,
    "vx": -0.00000315,
    "vy": 0.00003228,
    "vz": -0.00000523,
    "rarad": 3.2634379097434,
    "decrad": -0.151459567343167,
    "pmrarad": -0.00000108700075317361,
    "pmdecrad": -1.77296362e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 60628,
    "lum": 1.54596621938667,
    "ra": 12.465415,
    "dec": -8.677994,
    "pmra": -224.21,
    "pmdec": -36.57,
    "rv": 0,
    "Asset Name": "ASTRID 003905",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Elven",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000315,
    "y_1": 0.00003228,
    "z_1": -0.00000523,
    "Distance in Parsecs_1": 29.8329,
    "HYG Star ID_1": 60628,
    "Hipparcos Catalogue_1": 60816,
    "Henry Draper Catalogue_1": 108510
  },
  {
    "Astrid #": "ASTRID 003906",
    "HYG Star ID": 73713,
    "Hipparcos Catalogue": 73941,
    "Henry Draper Catalogue": 134044,
    "Harvard Revised Catalogue": 5630,
    "Distance in Parsecs": 29.8329,
    "mag": 6.35,
    "Absolute Visual Magnitude": 3.977,
    "Spectral Type": "F8V",
    "Color Index": 0.537,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.472493,
    "y": -17.447476,
    "z": 17.726867,
    "vx": -0.00000156,
    "vy": 0.0000119,
    "vz": 0.00000166,
    "rarad": 3.95572721589281,
    "decrad": 0.636278323119508,
    "pmrarad": -3.11783677965277e-7,
    "pmdecrad": 1.95670801e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 73713,
    "lum": 2.23460105773122,
    "ra": 15.109765,
    "dec": 36.456063,
    "pmra": -64.31,
    "pmdec": 40.36,
    "rv": -5,
    "Asset Name": "ASTRID 003906",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Bring It",
    "Hair": "Round Brush",
    "Outfit": "Basketball",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": -0.00000156,
    "y_1": 0.0000119,
    "z_1": 0.00000166,
    "Distance in Parsecs_1": 29.8329,
    "HYG Star ID_1": 73713,
    "Hipparcos Catalogue_1": 73941,
    "Henry Draper Catalogue_1": 134044,
    "Harvard Revised Catalogue_1": 5630
  },
  {
    "Astrid #": "ASTRID 003907",
    "HYG Star ID": 21831,
    "Hipparcos Catalogue": 21884,
    "Distance in Parsecs": 29.8507,
    "mag": 11.37,
    "Absolute Visual Magnitude": 8.995,
    "Spectral Type": "M:",
    "Color Index": 1.43,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 5.305858,
    "y": 15.038941,
    "z": 25.233755,
    "vx": 0.00003217,
    "vy": 0.00005763,
    "vz": -0.00004111,
    "rarad": 1.23162500762885,
    "decrad": 1.00718649925583,
    "pmrarad": -3.73985273180555e-7,
    "pmdecrad": -0.00000257814219,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 21831,
    "lum": 0.0219785987278482,
    "var_min": 11.439,
    "var_max": 11.309,
    "ra": 4.704461,
    "dec": 57.707536,
    "pmra": -77.14,
    "pmdec": -531.78,
    "rv": 0,
    "Asset Name": "ASTRID 003907",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Battle Ready",
    "Hair": "Lion Mane",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": 0.00003217,
    "y_1": 0.00005763,
    "z_1": -0.00004111,
    "Distance in Parsecs_1": 29.8507,
    "HYG Star ID_1": 21831,
    "Hipparcos Catalogue_1": 21884
  },
  {
    "Astrid #": "ASTRID 003908",
    "HYG Star ID": 37815,
    "Hipparcos Catalogue": 37923,
    "Henry Draper Catalogue": 63608,
    "Distance in Parsecs": 29.8507,
    "mag": 8.27,
    "Absolute Visual Magnitude": 5.895,
    "Spectral Type": "K0IV/V",
    "Color Index": 0.787,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -6.714542,
    "y": 13.425777,
    "z": -25.801701,
    "vx": -0.00000214,
    "vy": 0.00001967,
    "vz": 0.00001079,
    "rarad": 2.03454441859944,
    "decrad": -1.04387289551227,
    "pmrarad": -2.30674349208333e-7,
    "pmdecrad": 7.19027169e-7,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 37815,
    "lum": 0.381944270840046,
    "ra": 7.771387,
    "dec": -59.809511,
    "pmra": -47.58,
    "pmdec": 148.31,
    "rv": 0,
    "Asset Name": "ASTRID 003908",
    "Astrium Color": "Orange",
    "Background": "Supple Emptiness",
    "Expression": "Shy Smile",
    "Hair": "Heartbreaker",
    "Outfit": "Pirate",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -0.00000214,
    "y_1": 0.00001967,
    "z_1": 0.00001079,
    "Distance in Parsecs_1": 29.8507,
    "HYG Star ID_1": 37815,
    "Hipparcos Catalogue_1": 37923,
    "Henry Draper Catalogue_1": 63608
  },
  {
    "Astrid #": "ASTRID 003909",
    "HYG Star ID": 17398,
    "Hipparcos Catalogue": 17440,
    "Henry Draper Catalogue": 23817,
    "Harvard Revised Catalogue": 1175,
    "Gliese Catalog": "Gl 154.2",
    "Distance in Parsecs": 29.8597,
    "mag": 3.84,
    "Absolute Visual Magnitude": 1.465,
    "Spectral Type": "K0IV SB",
    "Color Index": 1.133,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.098485,
    "y": 10.543736,
    "z": -27.019807,
    "vx": -0.00001892,
    "vy": 0.00005183,
    "vz": -0.00004216,
    "rarad": 0.978237756272957,
    "decrad": -1.13109382760337,
    "pmrarad": 0.00000149511690946527,
    "pmdecrad": 3.81645329e-7,
    "bayer": "Bet",
    "con": "Ret",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Reticulum",
    "FULL CONSTELLATION NAME": "Reticulum",
    "comp": 1,
    "comp_primary": 17398,
    "lum": 22.5943577022097,
    "bf": "Bet Ret",
    "ra": 3.736593,
    "dec": -64.806903,
    "pmra": 308.39,
    "pmdec": 78.72,
    "rv": 50.8,
    "Asset Name": "ASTRID 003909",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Nervous Grin",
    "Hair": "Bob",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Reticulum",
    "x_1": -0.00001892,
    "y_1": 0.00005183,
    "z_1": -0.00004216,
    "Distance in Parsecs_1": 29.8597,
    "HYG Star ID_1": 17398,
    "Hipparcos Catalogue_1": 17440,
    "Henry Draper Catalogue_1": 23817,
    "Harvard Revised Catalogue_1": 1175,
    "Gliese Catalog_1": "Gl 154.2"
  },
  {
    "Astrid #": "ASTRID 003910",
    "HYG Star ID": 76097,
    "Hipparcos Catalogue": 76330,
    "Henry Draper Catalogue": 139194,
    "Distance in Parsecs": 29.8597,
    "mag": 8.61,
    "Absolute Visual Magnitude": 6.235,
    "Spectral Type": "K0",
    "Color Index": 0.866,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.203557,
    "y": -19.460573,
    "z": 17.639352,
    "vx": 0.00000926,
    "vy": -0.00001663,
    "vz": -0.00001089,
    "rarad": 4.08189920881829,
    "decrad": 0.631977033071802,
    "pmrarad": 5.78722090479166e-7,
    "pmdecrad": -4.51991794e-7,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 76097,
    "lum": 0.279254384123733,
    "var": "AN",
    "var_min": 8.645,
    "var_max": 8.575,
    "ra": 15.591706,
    "dec": 36.209617,
    "pmra": 119.37,
    "pmdec": -93.23,
    "rv": 0,
    "Asset Name": "ASTRID 003910",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Stunned",
    "Hair": "Bucket Hat",
    "Outfit": "Scientist",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": 0.00000926,
    "y_1": -0.00001663,
    "z_1": -0.00001089,
    "Distance in Parsecs_1": 29.8597,
    "HYG Star ID_1": 76097,
    "Hipparcos Catalogue_1": 76330,
    "Henry Draper Catalogue_1": 139194
  },
  {
    "Astrid #": "ASTRID 003911",
    "HYG Star ID": 57,
    "Hipparcos Catalogue": 57,
    "Henry Draper Catalogue": 224789,
    "Distance in Parsecs": 29.8686,
    "mag": 8.27,
    "Absolute Visual Magnitude": 5.894,
    "Spectral Type": "K2V",
    "Color Index": 0.863,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 10.374188,
    "y": 0.030419,
    "z": -28.00908,
    "vx": -0.00000916,
    "vy": -0.00000596,
    "vz": -0.0000034,
    "rarad": 0.00293539700355691,
    "decrad": -1.2160749962487,
    "pmrarad": -1.98628164923611e-7,
    "pmdecrad": -3.27685566e-7,
    "con": "Tuc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Tucana",
    "FULL CONSTELLATION NAME": "Tucana",
    "comp": 1,
    "comp_primary": 57,
    "lum": 0.382296216565919,
    "ra": 0.011212,
    "dec": -69.675965,
    "pmra": -40.97,
    "pmdec": -67.59,
    "rv": 0,
    "Asset Name": "ASTRID 003911",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Angry",
    "Hair": "Bangs",
    "Outfit": "Iron Star",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Tucana",
    "x_1": -0.00000916,
    "y_1": -0.00000596,
    "z_1": -0.0000034,
    "Distance in Parsecs_1": 29.8686,
    "HYG Star ID_1": 57,
    "Hipparcos Catalogue_1": 57,
    "Henry Draper Catalogue_1": 224789
  },
  {
    "Astrid #": "ASTRID 003912",
    "HYG Star ID": 22378,
    "Hipparcos Catalogue": 22431,
    "Henry Draper Catalogue": 31027,
    "Distance in Parsecs": 29.8686,
    "mag": 7.64,
    "Absolute Visual Magnitude": 5.264,
    "Spectral Type": "K4V",
    "Color Index": 0.846,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 5.322312,
    "y": 16.782527,
    "z": -24.127848,
    "vx": -0.00001047,
    "vy": 0.00003178,
    "vz": 0.00001979,
    "rarad": 1.26369117404268,
    "decrad": -0.940409852524406,
    "pmrarad": 6.55662021583333e-7,
    "pmdecrad": 0.000001124282925,
    "con": "Dor",
    "Full Constellation Name Formula 1": "Dorado",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Dorado",
    "comp": 1,
    "comp_primary": 22378,
    "lum": 0.682967440807963,
    "ra": 4.826945,
    "dec": -53.881516,
    "pmra": 135.24,
    "pmdec": 231.9,
    "rv": 0,
    "Asset Name": "ASTRID 003912",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Charmer",
    "Hair": "Braid",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Dorado",
    "x_1": -0.00001047,
    "y_1": 0.00003178,
    "z_1": 0.00001979,
    "Distance in Parsecs_1": 29.8686,
    "HYG Star ID_1": 22378,
    "Hipparcos Catalogue_1": 22431,
    "Henry Draper Catalogue_1": 31027
  },
  {
    "Astrid #": "ASTRID 003913",
    "HYG Star ID": 30639,
    "Hipparcos Catalogue": 30711,
    "Henry Draper Catalogue": 45588,
    "Harvard Revised Catalogue": 2349,
    "Gliese Catalog": "Wo 9212A",
    "Distance in Parsecs": 29.8686,
    "mag": 6.05,
    "Absolute Visual Magnitude": 3.674,
    "Spectral Type": "F8/G0V",
    "Color Index": 0.545,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -3.181316,
    "y": 26.689787,
    "z": -13.026393,
    "vx": 0.00002237,
    "vy": 0.00002212,
    "vz": -0.00004409,
    "rarad": 1.68943340491628,
    "decrad": -0.451280990766952,
    "pmrarad": -8.31552424888888e-7,
    "pmdecrad": -0.000001046373366,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 30639,
    "base": "Wo 9212",
    "lum": 2.95392864296027,
    "ra": 6.45316,
    "dec": -25.856496,
    "pmra": -171.52,
    "pmdec": -215.83,
    "rv": 35.8,
    "Asset Name": "ASTRID 003913",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Serious Shinobi",
    "Hair": "Bob",
    "Outfit": "Unit 01",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00002237,
    "y_1": 0.00002212,
    "z_1": -0.00004409,
    "Distance in Parsecs_1": 29.8686,
    "HYG Star ID_1": 30639,
    "Hipparcos Catalogue_1": 30711,
    "Henry Draper Catalogue_1": 45588,
    "Harvard Revised Catalogue_1": 2349,
    "Gliese Catalog_1": "Wo 9212A"
  },
  {
    "Astrid #": "ASTRID 003914",
    "HYG Star ID": 33317,
    "Hipparcos Catalogue": 33404,
    "Distance in Parsecs": 29.8686,
    "mag": 12.84,
    "Absolute Visual Magnitude": 10.464,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -6.839961,
    "y": 27.005477,
    "z": -10.772762,
    "vx": -2e-8,
    "vy": 2.2e-7,
    "vz": 5.5e-7,
    "rarad": 1.8188601453947,
    "decrad": -0.3689880860731,
    "pmrarad": -1.21203420138888e-9,
    "pmdecrad": 1.987736e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 33317,
    "lum": 0.00568067574106771,
    "ra": 6.947534,
    "dec": -21.14146,
    "pmra": -0.25,
    "pmdec": 4.1,
    "rv": 0,
    "Asset Name": "ASTRID 003914",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Shinobi",
    "Outfit": "Super",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -2e-8,
    "y_1": 2.2e-7,
    "z_1": 5.5e-7,
    "Distance in Parsecs_1": 29.8686,
    "HYG Star ID_1": 33317,
    "Hipparcos Catalogue_1": 33404
  },
  {
    "Astrid #": "ASTRID 003915",
    "HYG Star ID": 94081,
    "Hipparcos Catalogue": 94376,
    "Henry Draper Catalogue": 180711,
    "Harvard Revised Catalogue": 7310,
    "Distance in Parsecs": 29.8686,
    "mag": 3.07,
    "Absolute Visual Magnitude": 0.694,
    "Spectral Type": "G9III",
    "Color Index": 0.99,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.534238,
    "y": -10.788301,
    "z": 27.627312,
    "vx": 0.00001218,
    "vy": 0.00000677,
    "vz": 0.00002873,
    "rarad": 5.0289633473789,
    "decrad": 1.18091667047648,
    "pmrarad": 4.58100446756944e-7,
    "pmdecrad": 4.47483027e-7,
    "bayer": "Del",
    "flam": 57,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 94081,
    "lum": 45.9621144666001,
    "bf": "57Del Dra",
    "ra": 19.209225,
    "dec": 67.661541,
    "pmra": 94.49,
    "pmdec": 92.3,
    "rv": 25,
    "Asset Name": "ASTRID 003915",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Einstein Tongue",
    "Hair": "Slick",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00001218,
    "y_1": 0.00000677,
    "z_1": 0.00002873,
    "Distance in Parsecs_1": 29.8686,
    "HYG Star ID_1": 94081,
    "Hipparcos Catalogue_1": 94376,
    "Henry Draper Catalogue_1": 180711,
    "Harvard Revised Catalogue_1": 7310
  },
  {
    "Astrid #": "ASTRID 003916",
    "HYG Star ID": 5972,
    "Hipparcos Catalogue": 5985,
    "Henry Draper Catalogue": 7727,
    "Distance in Parsecs": 29.8775,
    "mag": 6.52,
    "Absolute Visual Magnitude": 4.143,
    "Spectral Type": "F8",
    "Color Index": 0.563,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 28.185489,
    "y": 9.840528,
    "z": -1.188182,
    "vx": -0.00000493,
    "vy": 0.00003802,
    "vz": -0.00002071,
    "rarad": 0.335903607483731,
    "decrad": -0.03977884566242,
    "pmrarad": 0.00000125605528358333,
    "pmdecrad": -6.82132848e-7,
    "flam": 40,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 5972,
    "lum": 1.91778536448265,
    "ra": 1.283057,
    "dec": -2.27916,
    "pmra": 259.08,
    "pmdec": -140.7,
    "rv": 8.5,
    "Asset Name": "ASTRID 003916",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Happy Vamp",
    "Hair": "Ponytail",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00000493,
    "y_1": 0.00003802,
    "z_1": -0.00002071,
    "Distance in Parsecs_1": 29.8775,
    "HYG Star ID_1": 5972,
    "Hipparcos Catalogue_1": 5985,
    "Henry Draper Catalogue_1": 7727
  },
  {
    "Astrid #": "ASTRID 003917",
    "HYG Star ID": 21680,
    "Hipparcos Catalogue": 21733,
    "Distance in Parsecs": 29.8864,
    "mag": 11.04,
    "Absolute Visual Magnitude": 8.663,
    "Spectral Type": "Kp",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 8.752562,
    "y": 24.054493,
    "z": -15.426306,
    "vx": 0.00000194,
    "vy": 0.00001171,
    "vz": 0.00001936,
    "rarad": 1.2218242410148,
    "decrad": -0.542366992293266,
    "pmrarad": 7.29159775555555e-8,
    "pmdecrad": 7.56309341e-7,
    "con": "Cae",
    "Full Constellation Name Formula 1": "Caelum",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Caelum",
    "comp": 1,
    "comp_primary": 21680,
    "lum": 0.0298400811593021,
    "var_min": 11.141,
    "var_max": 10.931,
    "ra": 4.667025,
    "dec": -31.07534,
    "pmra": 15.04,
    "pmdec": 156,
    "rv": 0,
    "Asset Name": "ASTRID 003917",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Oof",
    "Hair": "Middle Part",
    "Outfit": "Unit 01",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Caelum",
    "x_1": 0.00000194,
    "y_1": 0.00001171,
    "z_1": 0.00001936,
    "Distance in Parsecs_1": 29.8864,
    "HYG Star ID_1": 21680,
    "Hipparcos Catalogue_1": 21733
  },
  {
    "Astrid #": "ASTRID 003918",
    "HYG Star ID": 4957,
    "Hipparcos Catalogue": 4967,
    "Distance in Parsecs": 29.8954,
    "mag": 10.89,
    "Absolute Visual Magnitude": 8.512,
    "Color Index": 1.137,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 21.743924,
    "y": 6.200971,
    "z": 19.557216,
    "vx": 0.0000104,
    "vy": 0.00002133,
    "vz": -0.00001832,
    "rarad": 0.277805576174436,
    "decrad": 0.713108665872704,
    "pmrarad": 5.90648507020833e-7,
    "pmdecrad": -8.10414548e-7,
    "con": "And",
    "Full Constellation Name Formula 1": "Andromeda",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Andromeda",
    "comp": 1,
    "comp_primary": 4957,
    "lum": 0.0342925673297559,
    "var_min": 10.991,
    "var_max": 10.821,
    "ra": 1.061139,
    "dec": 40.858117,
    "pmra": 121.83,
    "pmdec": -167.16,
    "rv": 0,
    "Asset Name": "ASTRID 003918",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Loopy",
    "Hair": "Braided Ponytail",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Andromeda",
    "x_1": 0.0000104,
    "y_1": 0.00002133,
    "z_1": -0.00001832,
    "Distance in Parsecs_1": 29.8954,
    "HYG Star ID_1": 4957,
    "Hipparcos Catalogue_1": 4967
  },
  {
    "Astrid #": "ASTRID 003919",
    "HYG Star ID": 10014,
    "Hipparcos Catalogue": 10037,
    "Gliese Catalog": "NN 3138",
    "Distance in Parsecs": 29.9043,
    "mag": 10.89,
    "Absolute Visual Magnitude": 8.511,
    "Spectral Type": "K7",
    "Color Index": 1.386,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.25728,
    "y": 15.332104,
    "z": -8.412973,
    "vx": -0.00003733,
    "vy": 0.00006511,
    "vz": 0.00001102,
    "rarad": 0.563660553788385,
    "decrad": -0.285179677241193,
    "pmrarad": 0.00000250774724404166,
    "pmdecrad": 3.83972435e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 10014,
    "lum": 0.034324166501212,
    "var_min": 10.97,
    "var_max": 10.82,
    "ra": 2.153025,
    "dec": -16.339592,
    "pmra": 517.26,
    "pmdec": 79.2,
    "rv": 0,
    "Asset Name": "ASTRID 003919",
    "Astrium Color": "Orange",
    "Background": "Velvet Nothingness",
    "Expression": "Stunned",
    "Hair": "Afro",
    "Outfit": "Mugiwara",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00003733,
    "y_1": 0.00006511,
    "z_1": 0.00001102,
    "Distance in Parsecs_1": 29.9043,
    "HYG Star ID_1": 10014,
    "Hipparcos Catalogue_1": 10037,
    "Gliese Catalog_1": "NN 3138"
  },
  {
    "Astrid #": "ASTRID 003920",
    "HYG Star ID": 17323,
    "Hipparcos Catalogue": 17365,
    "Distance in Parsecs": 29.9043,
    "mag": 10.87,
    "Absolute Visual Magnitude": 8.491,
    "Spectral Type": "K5",
    "Color Index": 1.339,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 16.395021,
    "y": 24.102657,
    "z": -6.673256,
    "vx": 0.00002925,
    "vy": -0.00001867,
    "vz": 0.00000443,
    "rarad": 0.973472007607546,
    "decrad": -0.225048614519688,
    "pmrarad": -0.00000116015913756944,
    "pmdecrad": 1.51940607e-7,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 17323,
    "lum": 0.0349623004002701,
    "var_min": 10.922,
    "var_max": 10.782,
    "ra": 3.718389,
    "dec": -12.894336,
    "pmra": -239.3,
    "pmdec": 31.34,
    "rv": 0,
    "Asset Name": "ASTRID 003920",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Honest Lad",
    "Outfit": "Skater",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": 0.00002925,
    "y_1": -0.00001867,
    "z_1": 0.00000443,
    "Distance in Parsecs_1": 29.9043,
    "HYG Star ID_1": 17323,
    "Hipparcos Catalogue_1": 17365
  },
  {
    "Astrid #": "ASTRID 003921",
    "HYG Star ID": 108109,
    "Hipparcos Catalogue": 108456,
    "Henry Draper Catalogue": 209942,
    "Harvard Revised Catalogue": 8423,
    "Distance in Parsecs": 29.9043,
    "mag": 6.92,
    "Absolute Visual Magnitude": 4.541,
    "Spectral Type": "F6IV-V",
    "Color Index": 0.517,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 3.200133,
    "y": -1.880973,
    "z": 29.672824,
    "vx": -0.00000267,
    "vy": -0.00002015,
    "vz": -0.00002366,
    "rarad": 5.75183843372661,
    "decrad": 1.44634537043873,
    "pmrarad": -6.26088387069444e-7,
    "pmdecrad": -3.60749859e-7,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 108109,
    "lum": 1.32922958831568,
    "var_min": 7.216,
    "var_max": 6.856,
    "ra": 21.970404,
    "dec": 82.869485,
    "pmra": -129.14,
    "pmdec": -74.41,
    "rv": -22,
    "Asset Name": "ASTRID 003921",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Stunned",
    "Hair": "Skater Helmet",
    "Outfit": "Sharky",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00000267,
    "y_1": -0.00002015,
    "z_1": -0.00002366,
    "Distance in Parsecs_1": 29.9043,
    "HYG Star ID_1": 108109,
    "Hipparcos Catalogue_1": 108456,
    "Henry Draper Catalogue_1": 209942,
    "Harvard Revised Catalogue_1": 8423
  },
  {
    "Astrid #": "ASTRID 003922",
    "HYG Star ID": 33125,
    "Hipparcos Catalogue": 33212,
    "Henry Draper Catalogue": 50554,
    "Distance in Parsecs": 29.9133,
    "mag": 6.84,
    "Absolute Visual Magnitude": 4.461,
    "Spectral Type": "F8",
    "Color Index": 0.582,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -6.449732,
    "y": 26.501202,
    "z": 12.283842,
    "vx": 0.0000039,
    "vy": 0.00000685,
    "vz": -0.00001274,
    "rarad": 1.80953026911356,
    "decrad": 0.423164825293457,
    "pmrarad": -1.80787021479166e-7,
    "pmdecrad": -4.67166462e-7,
    "con": "Gem",
    "Full Constellation Name Formula 1": "Gemini",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Gemini",
    "comp": 1,
    "comp_primary": 33125,
    "lum": 1.43086941274933,
    "ra": 6.911896,
    "dec": 24.245559,
    "pmra": -37.29,
    "pmdec": -96.36,
    "rv": 0,
    "Asset Name": "ASTRID 003922",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Awkward",
    "Hair": "Charmer",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Gemini",
    "x_1": 0.0000039,
    "y_1": 0.00000685,
    "z_1": -0.00001274,
    "Distance in Parsecs_1": 29.9133,
    "HYG Star ID_1": 33125,
    "Hipparcos Catalogue_1": 33212,
    "Henry Draper Catalogue_1": 50554
  },
  {
    "Astrid #": "ASTRID 003923",
    "HYG Star ID": 36059,
    "Hipparcos Catalogue": 36160,
    "Henry Draper Catalogue": 59100,
    "Distance in Parsecs": 29.9133,
    "mag": 8.17,
    "Absolute Visual Magnitude": 5.791,
    "Spectral Type": "G5V",
    "Color Index": 0.634,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -9.151497,
    "y": 22.949901,
    "z": -16.863941,
    "vx": 0.0000189,
    "vy": 0.00007532,
    "vz": -0.00002586,
    "rarad": 1.95023585807578,
    "decrad": -0.598918661933292,
    "pmrarad": -0.00000151964848170138,
    "pmdecrad": 4.72693338e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 36059,
    "lum": 0.420339303236549,
    "ra": 7.449352,
    "dec": -34.315512,
    "pmra": -313.45,
    "pmdec": 97.5,
    "rv": 65.1,
    "Asset Name": "ASTRID 003923",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Determined Neko",
    "Hair": "Pirate Hat",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.0000189,
    "y_1": 0.00007532,
    "z_1": -0.00002586,
    "Distance in Parsecs_1": 29.9133,
    "HYG Star ID_1": 36059,
    "Hipparcos Catalogue_1": 36160,
    "Henry Draper Catalogue_1": 59100
  },
  {
    "Astrid #": "ASTRID 003924",
    "HYG Star ID": 60158,
    "Hipparcos Catalogue": 60343,
    "Gliese Catalog": "NN 3721",
    "Distance in Parsecs": 29.9133,
    "mag": 11.35,
    "Absolute Visual Magnitude": 8.971,
    "Spectral Type": "M:",
    "Color Index": 1.35,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -26.94435,
    "y": -2.636523,
    "z": 12.722274,
    "vx": -0.00002211,
    "vy": 0.00009875,
    "vz": -0.00002636,
    "rarad": 3.23913589292734,
    "decrad": 0.439298797702576,
    "pmrarad": -0.00000335772258879166,
    "pmdecrad": -9.73748277e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 60158,
    "lum": 0.0224698410364015,
    "var_min": 11.43,
    "var_max": 11.28,
    "ra": 12.372588,
    "dec": 25.169967,
    "pmra": -692.58,
    "pmdec": -200.85,
    "rv": 0,
    "Asset Name": "ASTRID 003924",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Pompadour",
    "Outfit": "Teen",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00002211,
    "y_1": 0.00009875,
    "z_1": -0.00002636,
    "Distance in Parsecs_1": 29.9133,
    "HYG Star ID_1": 60158,
    "Hipparcos Catalogue_1": 60343,
    "Gliese Catalog_1": "NN 3721"
  },
  {
    "Astrid #": "ASTRID 003925",
    "HYG Star ID": 60168,
    "Hipparcos Catalogue": 60353,
    "Henry Draper Catalogue": 107705,
    "Harvard Revised Catalogue": 4708,
    "Distance in Parsecs": 29.9222,
    "mag": 6.46,
    "Absolute Visual Magnitude": 4.08,
    "Spectral Type": "F8V",
    "Color Index": 0.567,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -29.650155,
    "y": -2.924725,
    "z": 2.766766,
    "vx": -0.00000815,
    "vy": 0.00002348,
    "vz": -0.0000072,
    "rarad": 3.2399157159012,
    "decrad": 0.0925974723133787,
    "pmrarad": -8.07554147701388e-7,
    "pmdecrad": -2.57678471e-7,
    "flam": 17,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 60168,
    "lum": 2.03235701093622,
    "bf": "17    Vir",
    "ra": 12.375566,
    "dec": 5.305444,
    "pmra": -166.57,
    "pmdec": -53.15,
    "rv": 5,
    "Asset Name": "ASTRID 003925",
    "Astrium Color": "Yellow",
    "Background": "Supple Emptiness",
    "Expression": "Chubby Cheeks",
    "Hair": "Headband",
    "Outfit": "Tats",
    "Astral Crystal": "Shuriken",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00000815,
    "y_1": 0.00002348,
    "z_1": -0.0000072,
    "Distance in Parsecs_1": 29.9222,
    "HYG Star ID_1": 60168,
    "Hipparcos Catalogue_1": 60353,
    "Henry Draper Catalogue_1": 107705,
    "Harvard Revised Catalogue_1": 4708
  },
  {
    "Astrid #": "ASTRID 003926",
    "HYG Star ID": 22585,
    "Hipparcos Catalogue": 22638,
    "Distance in Parsecs": 29.9312,
    "mag": 9.26,
    "Absolute Visual Magnitude": 6.879,
    "Spectral Type": "K5",
    "Color Index": 1.12,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 6.171052,
    "y": 20.254873,
    "z": 21.155022,
    "vx": 0.00000639,
    "vy": -0.00001116,
    "vz": 0.00000882,
    "rarad": 1.27506179756015,
    "decrad": 0.784947852677673,
    "pmrarad": -3.128502680625e-7,
    "pmdecrad": 4.16648877e-7,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 22585,
    "lum": 0.154312106565779,
    "ra": 4.870377,
    "dec": 44.974199,
    "pmra": -64.53,
    "pmdec": 85.94,
    "rv": 0,
    "Asset Name": "ASTRID 003926",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Determined",
    "Hair": "Windy",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00000639,
    "y_1": -0.00001116,
    "z_1": 0.00000882,
    "Distance in Parsecs_1": 29.9312,
    "HYG Star ID_1": 22585,
    "Hipparcos Catalogue_1": 22638
  },
  {
    "Astrid #": "ASTRID 003927",
    "HYG Star ID": 11814,
    "Hipparcos Catalogue": 11843,
    "Henry Draper Catalogue": 15814,
    "Harvard Revised Catalogue": 741,
    "Gliese Catalog": "NN 3161",
    "Distance in Parsecs": 29.9401,
    "mag": 6,
    "Absolute Visual Magnitude": 3.619,
    "Spectral Type": "F8V",
    "Color Index": 0.572,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.715311,
    "y": 17.891618,
    "z": 7.766529,
    "vx": 0.00000713,
    "vy": 0.00000275,
    "vz": 0.00000751,
    "rarad": 0.667162346319869,
    "decrad": 0.262402471917952,
    "pmrarad": -7.504915775e-8,
    "pmdecrad": 1.78847766e-7,
    "flam": 29,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 11814,
    "lum": 3.10742031038858,
    "bf": "29    Ari",
    "ra": 2.548372,
    "dec": 15.034554,
    "pmra": -15.48,
    "pmdec": 36.89,
    "rv": 8.8,
    "Asset Name": "ASTRID 003927",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Pixie",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00000713,
    "y_1": 0.00000275,
    "z_1": 0.00000751,
    "Distance in Parsecs_1": 29.9401,
    "HYG Star ID_1": 11814,
    "Hipparcos Catalogue_1": 11843,
    "Henry Draper Catalogue_1": 15814,
    "Harvard Revised Catalogue_1": 741,
    "Gliese Catalog_1": "NN 3161"
  },
  {
    "Astrid #": "ASTRID 003928",
    "HYG Star ID": 88683,
    "Hipparcos Catalogue": 88961,
    "Henry Draper Catalogue": 166184,
    "Distance in Parsecs": 29.9401,
    "mag": 8.93,
    "Absolute Visual Magnitude": 6.549,
    "Spectral Type": "K3V",
    "Color Index": 1.02,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 1.245573,
    "y": -29.913691,
    "z": -0.170942,
    "vx": 0.00001152,
    "vy": 4.9e-7,
    "vz": -0.00000189,
    "rarad": 4.75400384051568,
    "decrad": -0.00570948632398374,
    "pmrarad": 3.85087506465277e-7,
    "pmdecrad": -6.3122741e-8,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 88683,
    "lum": 0.209122133015528,
    "ra": 18.158957,
    "dec": -0.327129,
    "pmra": 79.43,
    "pmdec": -13.02,
    "rv": 0,
    "Asset Name": "ASTRID 003928",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Glad",
    "Hair": "Medium Bob",
    "Outfit": "Viking",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00001152,
    "y_1": 4.9e-7,
    "z_1": -0.00000189,
    "Distance in Parsecs_1": 29.9401,
    "HYG Star ID_1": 88683,
    "Hipparcos Catalogue_1": 88961,
    "Henry Draper Catalogue_1": 166184
  },
  {
    "Astrid #": "ASTRID 003929",
    "HYG Star ID": 37620,
    "Hipparcos Catalogue": 37727,
    "Distance in Parsecs": 29.9491,
    "mag": 7.55,
    "Absolute Visual Magnitude": 5.168,
    "Spectral Type": "G0",
    "Color Index": 0.7,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.377383,
    "y": 17.124074,
    "z": -23.098357,
    "vx": 0.00000748,
    "vy": 0.00002146,
    "vz": 0.0000132,
    "rarad": 2.02578281249025,
    "decrad": -0.880808593132007,
    "pmrarad": -5.39258256881944e-7,
    "pmdecrad": 6.92556342e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 37620,
    "lum": 0.746105085193062,
    "ra": 7.73792,
    "dec": -50.466615,
    "pmra": -111.23,
    "pmdec": 142.85,
    "rv": 0,
    "Asset Name": "ASTRID 003929",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Warm Smile",
    "Hair": "Mohawk",
    "Outfit": "Unit 01",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000748,
    "y_1": 0.00002146,
    "z_1": 0.0000132,
    "Distance in Parsecs_1": 29.9491,
    "HYG Star ID_1": 37620,
    "Hipparcos Catalogue_1": 37727
  },
  {
    "Astrid #": "ASTRID 003930",
    "HYG Star ID": 65215,
    "Hipparcos Catalogue": 65420,
    "Henry Draper Catalogue": 116568,
    "Harvard Revised Catalogue": 5050,
    "Distance in Parsecs": 29.967,
    "mag": 5.76,
    "Absolute Visual Magnitude": 3.377,
    "Spectral Type": "F3V",
    "Color Index": 0.415,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -27.837241,
    "y": -10.762966,
    "z": -2.697251,
    "vx": -0.0000046,
    "vy": -0.00002634,
    "vz": -0.00000653,
    "rarad": 3.51052823109541,
    "decrad": -0.0901289803441737,
    "pmrarad": 7.64696618340277e-7,
    "pmdecrad": -1.75696477e-7,
    "flam": 66,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 65215,
    "lum": 3.88329157004954,
    "bf": "66    Vir",
    "ra": 13.40923,
    "dec": -5.16401,
    "pmra": 157.73,
    "pmdec": -36.24,
    "rv": 14,
    "Asset Name": "ASTRID 003930",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Shocked",
    "Hair": "Undercut",
    "Outfit": "Bomber Jacket",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.0000046,
    "y_1": -0.00002634,
    "z_1": -0.00000653,
    "Distance in Parsecs_1": 29.967,
    "HYG Star ID_1": 65215,
    "Hipparcos Catalogue_1": 65420,
    "Henry Draper Catalogue_1": 116568,
    "Harvard Revised Catalogue_1": 5050
  },
  {
    "Astrid #": "ASTRID 003931",
    "HYG Star ID": 60827,
    "Hipparcos Catalogue": 61016,
    "Henry Draper Catalogue": 108847,
    "Distance in Parsecs": 29.976,
    "mag": 9.08,
    "Absolute Visual Magnitude": 6.696,
    "Spectral Type": "G9V",
    "Color Index": 1.015,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -25.471909,
    "y": -3.386631,
    "z": 15.436312,
    "vx": -0.00000504,
    "vy": 0.00000453,
    "vz": 0.00001711,
    "rarad": 3.27377323347171,
    "decrad": 0.540953765073373,
    "pmrarad": -1.72060375229166e-7,
    "pmdecrad": 4.13836957e-7,
    "con": "Com",
    "Full Constellation Name Formula 1": "Coma Berenices",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Coma Berenices",
    "comp": 1,
    "comp_primary": 60827,
    "lum": 0.182641724877435,
    "ra": 12.504893,
    "dec": 30.994368,
    "pmra": -35.49,
    "pmdec": 85.36,
    "rv": 12.3,
    "Asset Name": "ASTRID 003931",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Neko Dreams",
    "Hair": "Tiny Crown",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Five Pointed",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Coma Berenices",
    "x_1": -0.00000504,
    "y_1": 0.00000453,
    "z_1": 0.00001711,
    "Distance in Parsecs_1": 29.976,
    "HYG Star ID_1": 60827,
    "Hipparcos Catalogue_1": 61016,
    "Henry Draper Catalogue_1": 108847
  },
  {
    "Astrid #": "ASTRID 003932",
    "HYG Star ID": 87193,
    "Hipparcos Catalogue": 87464,
    "Henry Draper Catalogue": 162598,
    "Distance in Parsecs": 29.985,
    "mag": 9.94,
    "Absolute Visual Magnitude": 7.555,
    "Spectral Type": "K5V",
    "Color Index": 1.151,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -1.001487,
    "y": -29.707455,
    "z": -3.945168,
    "vx": -0.00003318,
    "vy": -0.00000564,
    "vz": 0.00005091,
    "rarad": 4.67869017287251,
    "decrad": -0.131953977166877,
    "pmrarad": -0.0000010995574275,
    "pmdecrad": 0.000001712895214,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 87193,
    "lum": 0.0827942163712334,
    "ra": 17.87128,
    "dec": -7.560406,
    "pmra": -226.8,
    "pmdec": 353.31,
    "rv": 0,
    "Asset Name": "ASTRID 003932",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Malevolent",
    "Hair": "Pompadour",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00003318,
    "y_1": -0.00000564,
    "z_1": 0.00005091,
    "Distance in Parsecs_1": 29.985,
    "HYG Star ID_1": 87193,
    "Hipparcos Catalogue_1": 87464,
    "Henry Draper Catalogue_1": 162598
  },
  {
    "Astrid #": "ASTRID 003933",
    "HYG Star ID": 93761,
    "Hipparcos Catalogue": 94056,
    "Gliese Catalog": "Gl 747.2",
    "Distance in Parsecs": 29.985,
    "mag": 9.42,
    "Absolute Visual Magnitude": 7.035,
    "Spectral Type": "K8",
    "Color Index": 1.238,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 7.355793,
    "y": -23.726457,
    "z": 16.794457,
    "vx": -0.00000961,
    "vy": -0.00000792,
    "vz": 0.00001346,
    "rarad": 5.01301808469592,
    "decrad": 0.59449853633687,
    "pmrarad": -3.84263323208333e-7,
    "pmdecrad": 2.83809928e-7,
    "con": "Lyr",
    "Full Constellation Name Formula 1": "Lyra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lyra",
    "comp": 1,
    "comp_primary": 93761,
    "lum": 0.133659551654644,
    "ra": 19.148319,
    "dec": 34.062257,
    "pmra": -79.26,
    "pmdec": 58.54,
    "rv": 11.2,
    "Asset Name": "ASTRID 003933",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Upset",
    "Hair": "Frizzy",
    "Outfit": "Unit 01",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lyra",
    "x_1": -0.00000961,
    "y_1": -0.00000792,
    "z_1": 0.00001346,
    "Distance in Parsecs_1": 29.985,
    "HYG Star ID_1": 93761,
    "Hipparcos Catalogue_1": 94056,
    "Gliese Catalog_1": "Gl 747.2"
  },
  {
    "Astrid #": "ASTRID 003934",
    "HYG Star ID": 111335,
    "Hipparcos Catalogue": 111686,
    "Distance in Parsecs": 29.994,
    "mag": 9.77,
    "Absolute Visual Magnitude": 7.385,
    "Spectral Type": "M2",
    "Color Index": 1.223,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 10.92175,
    "y": -4.110756,
    "z": -27.630728,
    "vx": -0.00003524,
    "vy": -0.00002013,
    "vz": -0.00001093,
    "rarad": 5.92321738213948,
    "decrad": -1.17117528159317,
    "pmrarad": -0.00000104215548772222,
    "pmdecrad": -9.37096363e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 111335,
    "lum": 0.0968277856261249,
    "var_min": 9.822,
    "var_max": 9.702,
    "ra": 22.625024,
    "dec": -67.103401,
    "pmra": -214.96,
    "pmdec": -193.29,
    "rv": 0,
    "Asset Name": "ASTRID 003934",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Serious Mad",
    "Hair": "Ninja",
    "Outfit": "Turtle School",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00003524,
    "y_1": -0.00002013,
    "z_1": -0.00001093,
    "Distance in Parsecs_1": 29.994,
    "HYG Star ID_1": 111335,
    "Hipparcos Catalogue_1": 111686
  },
  {
    "Astrid #": "ASTRID 003935",
    "HYG Star ID": 23376,
    "Hipparcos Catalogue": 23431,
    "Henry Draper Catalogue": 32237,
    "Distance in Parsecs": 30.003,
    "mag": 8.19,
    "Absolute Visual Magnitude": 5.804,
    "Spectral Type": "G5",
    "Color Index": 0.72,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 7.266208,
    "y": 28.179436,
    "z": 7.299756,
    "vx": -0.00001559,
    "vy": -0.00001017,
    "vz": -0.00006419,
    "rarad": 1.31843841349457,
    "decrad": 0.245769659597292,
    "pmrarad": 4.18685094527777e-7,
    "pmdecrad": -0.000001963931738,
    "con": "Ori",
    "Full Constellation Name Formula 1": "Orion",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Orion",
    "comp": 1,
    "comp_primary": 23376,
    "lum": 0.415336405489073,
    "ra": 5.036064,
    "dec": 14.081564,
    "pmra": 86.36,
    "pmdec": -405.09,
    "rv": -28.3,
    "Asset Name": "ASTRID 003935",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Bob",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Five Pointed",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Orion",
    "x_1": -0.00001559,
    "y_1": -0.00001017,
    "z_1": -0.00006419,
    "Distance in Parsecs_1": 30.003,
    "HYG Star ID_1": 23376,
    "Hipparcos Catalogue_1": 23431,
    "Henry Draper Catalogue_1": 32237
  },
  {
    "Astrid #": "ASTRID 003936",
    "HYG Star ID": 28724,
    "Hipparcos Catalogue": 28796,
    "Henry Draper Catalogue": 41824,
    "Harvard Revised Catalogue": 2162,
    "Distance in Parsecs": 30.003,
    "mag": 6.6,
    "Absolute Visual Magnitude": 4.214,
    "Spectral Type": "G6V",
    "Color Index": 0.712,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -0.414784,
    "y": 19.892749,
    "z": -22.456599,
    "vx": 0.00001509,
    "vy": 0.00001338,
    "vz": -0.00001985,
    "rarad": 1.59164657304895,
    "decrad": -0.845756954413886,
    "pmrarad": -5.12108690770833e-7,
    "pmdecrad": -1.12961587e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 28724,
    "lum": 1.79638739906389,
    "var": "V575",
    "var_min": 6.631,
    "var_max": 6.561,
    "ra": 6.079642,
    "dec": -48.458304,
    "pmra": -105.63,
    "pmdec": -23.3,
    "rv": 23,
    "Asset Name": "ASTRID 003936",
    "Astrium Color": "Yellow",
    "Background": "Velvet Nothingness",
    "Expression": "Malevolent",
    "Hair": "Slick",
    "Outfit": "Iron Star",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00001509,
    "y_1": 0.00001338,
    "z_1": -0.00001985,
    "Distance in Parsecs_1": 30.003,
    "HYG Star ID_1": 28724,
    "Hipparcos Catalogue_1": 28796,
    "Henry Draper Catalogue_1": 41824,
    "Harvard Revised Catalogue_1": 2162
  },
  {
    "Astrid #": "ASTRID 003937",
    "HYG Star ID": 38870,
    "Hipparcos Catalogue": 38980,
    "Henry Draper Catalogue": 65721,
    "Gliese Catalog": "Wo 9247",
    "Distance in Parsecs": 30.003,
    "mag": 7.95,
    "Absolute Visual Magnitude": 5.564,
    "Spectral Type": "G6V",
    "Color Index": 0.739,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -12.167955,
    "y": 21.372745,
    "z": -17.18544,
    "vx": 0.00002725,
    "vy": 0.00005833,
    "vz": 0.00001093,
    "rarad": 2.08835565119099,
    "decrad": -0.609901464300478,
    "pmrarad": -0.00000175129245827083,
    "pmdecrad": 0.000001008994231,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 38870,
    "lum": 0.518083785071992,
    "ra": 7.976931,
    "dec": -34.94478,
    "pmra": -361.23,
    "pmdec": 208.12,
    "rv": 23.7,
    "Asset Name": "ASTRID 003937",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Happy Vamp",
    "Hair": "Bangs",
    "Outfit": "Toga",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00002725,
    "y_1": 0.00005833,
    "z_1": 0.00001093,
    "Distance in Parsecs_1": 30.003,
    "HYG Star ID_1": 38870,
    "Hipparcos Catalogue_1": 38980,
    "Henry Draper Catalogue_1": 65721,
    "Gliese Catalog_1": "Wo 9247"
  },
  {
    "Astrid #": "ASTRID 003938",
    "HYG Star ID": 54382,
    "Hipparcos Catalogue": 54541,
    "Henry Draper Catalogue": 96937,
    "Gliese Catalog": "NN 3644",
    "Distance in Parsecs": 30.003,
    "mag": 7.69,
    "Absolute Visual Magnitude": 5.304,
    "Spectral Type": "G5",
    "Color Index": 0.777,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -29.255601,
    "y": 6.529975,
    "z": 1.285827,
    "vx": 0.00000135,
    "vy": 0.00004104,
    "vz": 0.00000614,
    "rarad": 2.92198808151151,
    "decrad": 0.0428696518783925,
    "pmrarad": -0.00000134497011259722,
    "pmdecrad": 1.93586102e-7,
    "con": "Leo",
    "Full Constellation Name Formula 1": "Leo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Leo",
    "comp": 1,
    "comp_primary": 54382,
    "lum": 0.658263841640901,
    "ra": 11.161172,
    "dec": 2.45625,
    "pmra": -277.42,
    "pmdec": 39.93,
    "rv": 7.7,
    "Asset Name": "ASTRID 003938",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Naughty Dreams",
    "Hair": "Round Brush",
    "Outfit": "Football",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Leo",
    "x_1": 0.00000135,
    "y_1": 0.00004104,
    "z_1": 0.00000614,
    "Distance in Parsecs_1": 30.003,
    "HYG Star ID_1": 54382,
    "Hipparcos Catalogue_1": 54541,
    "Henry Draper Catalogue_1": 96937,
    "Gliese Catalog_1": "NN 3644"
  },
  {
    "Astrid #": "ASTRID 003939",
    "HYG Star ID": 55960,
    "Hipparcos Catalogue": 56128,
    "Distance in Parsecs": 30.012,
    "mag": 9.88,
    "Absolute Visual Magnitude": 7.494,
    "Color Index": 0.74,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -24.526318,
    "y": 3.195128,
    "z": -16.999148,
    "vx": 0.00000242,
    "vy": 0.0000576,
    "vz": 0.00000733,
    "rarad": 3.01205231665667,
    "decrad": -0.602145219792232,
    "pmrarad": -0.000001913753522625,
    "pmdecrad": 2.96366602e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 55960,
    "lum": 0.0875790036306368,
    "ra": 11.505192,
    "dec": -34.50038,
    "pmra": -394.74,
    "pmdec": 61.13,
    "rv": 0,
    "Asset Name": "ASTRID 003939",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Star of Culture",
    "Hair": "Bangs",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Leaf",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000242,
    "y_1": 0.0000576,
    "z_1": 0.00000733,
    "Distance in Parsecs_1": 30.012,
    "HYG Star ID_1": 55960,
    "Hipparcos Catalogue_1": 56128
  },
  {
    "Astrid #": "ASTRID 003940",
    "HYG Star ID": 84045,
    "Hipparcos Catalogue": 84303,
    "Henry Draper Catalogue": 155802,
    "Distance in Parsecs": 30.012,
    "mag": 8.49,
    "Absolute Visual Magnitude": 6.104,
    "Spectral Type": "K3V",
    "Color Index": 0.925,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -5.902106,
    "y": -29.097186,
    "z": -4.386235,
    "vx": -0.0000148,
    "vy": 0.00000149,
    "vz": 0.00001005,
    "rarad": 4.5122631306432,
    "decrad": -0.146674739152028,
    "pmrarad": -4.93297919965277e-7,
    "pmdecrad": 3.38496911e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 84045,
    "lum": 0.315064883297239,
    "ra": 17.235576,
    "dec": -8.403844,
    "pmra": -101.75,
    "pmdec": 69.82,
    "rv": 0,
    "Asset Name": "ASTRID 003940",
    "Astrium Color": "Orange",
    "Background": "Volatile Expanse",
    "Expression": "Worried Vamp",
    "Hair": "Honest Lad",
    "Outfit": "Tarnished",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.0000148,
    "y_1": 0.00000149,
    "z_1": 0.00001005,
    "Distance in Parsecs_1": 30.012,
    "HYG Star ID_1": 84045,
    "Hipparcos Catalogue_1": 84303,
    "Henry Draper Catalogue_1": 155802
  },
  {
    "Astrid #": "ASTRID 003941",
    "HYG Star ID": 39181,
    "Hipparcos Catalogue": 39293,
    "Distance in Parsecs": 30.021,
    "mag": 11.34,
    "Absolute Visual Magnitude": 8.953,
    "Color Index": 1.478,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.563712,
    "y": 24.710318,
    "z": -8.863347,
    "vx": 0.00004025,
    "vy": 0.0000306,
    "vz": 0.00001919,
    "rarad": 2.10336941041438,
    "decrad": -0.299704871874808,
    "pmrarad": -0.00000167275264202083,
    "pmdecrad": 6.69042879e-7,
    "con": "Pup",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 39181,
    "lum": 0.0228454648855182,
    "var_min": 11.409,
    "var_max": 11.239,
    "ra": 8.034279,
    "dec": -17.171824,
    "pmra": -345.03,
    "pmdec": 138,
    "rv": 0,
    "Asset Name": "ASTRID 003941",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Serious Slumber",
    "Hair": "Antlers",
    "Outfit": "Elven",
    "Astral Crystal": "Snowflake",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00004025,
    "y_1": 0.0000306,
    "z_1": 0.00001919,
    "Distance in Parsecs_1": 30.021,
    "HYG Star ID_1": 39181,
    "Hipparcos Catalogue_1": 39293
  },
  {
    "Astrid #": "ASTRID 003942",
    "HYG Star ID": 86273,
    "Hipparcos Catalogue": 86540,
    "Henry Draper Catalogue": 161897,
    "Distance in Parsecs": 30.03,
    "mag": 7.6,
    "Absolute Visual Magnitude": 5.212,
    "Spectral Type": "K0",
    "Color Index": 0.72,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -0.746663,
    "y": -9.039173,
    "z": 28.627392,
    "vx": -0.00001385,
    "vy": 0.0000478,
    "vz": -0.00000351,
    "rarad": 4.62998498955292,
    "decrad": 1.26397342542818,
    "pmrarad": -5.90793951124999e-7,
    "pmdecrad": 0.000001440963221,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 86273,
    "lum": 0.716473282391108,
    "ra": 17.68524,
    "dec": 72.420343,
    "pmra": -121.86,
    "pmdec": 297.22,
    "rv": -17,
    "Asset Name": "ASTRID 003942",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Warm Smile",
    "Hair": "Audiohead",
    "Outfit": "Spartan",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00001385,
    "y_1": 0.0000478,
    "z_1": -0.00000351,
    "Distance in Parsecs_1": 30.03,
    "HYG Star ID_1": 86273,
    "Hipparcos Catalogue_1": 86540,
    "Henry Draper Catalogue_1": 161897
  },
  {
    "Astrid #": "ASTRID 003943",
    "HYG Star ID": 107415,
    "Hipparcos Catalogue": 107758,
    "Gliese Catalog": "Wo 9757",
    "Distance in Parsecs": 30.03,
    "mag": 10.85,
    "Absolute Visual Magnitude": 8.462,
    "Spectral Type": "K5",
    "Color Index": 1.367,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 24.786484,
    "y": -15.826397,
    "z": -6.080803,
    "vx": -3.6e-7,
    "vy": -0.0000523,
    "vz": -0.00005223,
    "rarad": 5.7149307651687,
    "decrad": -0.203898607138828,
    "pmrarad": -0.00000147460929077777,
    "pmdecrad": -0.000001515673009,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 107415,
    "lum": 0.0359087262369451,
    "var_min": 10.93,
    "var_max": 10.79,
    "ra": 21.829428,
    "dec": -11.68253,
    "pmra": -304.16,
    "pmdec": -312.63,
    "rv": 37,
    "Asset Name": "ASTRID 003943",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Neko Dreams",
    "Hair": "Innocent",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -3.6e-7,
    "y_1": -0.0000523,
    "z_1": -0.00005223,
    "Distance in Parsecs_1": 30.03,
    "HYG Star ID_1": 107415,
    "Hipparcos Catalogue_1": 107758,
    "Gliese Catalog_1": "Wo 9757"
  },
  {
    "Astrid #": "ASTRID 003944",
    "HYG Star ID": 31990,
    "Hipparcos Catalogue": 32076,
    "Henry Draper Catalogue": 48286,
    "Distance in Parsecs": 30.0571,
    "mag": 6.97,
    "Absolute Visual Magnitude": 4.58,
    "Spectral Type": "F7V",
    "Color Index": 0.563,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -5.296259,
    "y": 28.51583,
    "z": -7.888368,
    "vx": 0.00002572,
    "vy": -2.3e-7,
    "vz": -0.00001812,
    "rarad": 1.75443457325497,
    "decrad": -0.265556255971236,
    "pmrarad": -8.40085145666666e-7,
    "pmdecrad": -6.24682427e-7,
    "con": "CMa",
    "Full Constellation Name Formula 1": "Canis Major",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Canis Major",
    "comp": 1,
    "comp_primary": 31990,
    "lum": 1.28233058265602,
    "ra": 6.701446,
    "dec": -15.215253,
    "pmra": -173.28,
    "pmdec": -128.85,
    "rv": 0,
    "Asset Name": "ASTRID 003944",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Unimpressed",
    "Hair": "Unkempt",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Canis Major",
    "x_1": 0.00002572,
    "y_1": -2.3e-7,
    "z_1": -0.00001812,
    "Distance in Parsecs_1": 30.0571,
    "HYG Star ID_1": 31990,
    "Hipparcos Catalogue_1": 32076,
    "Henry Draper Catalogue_1": 48286
  },
  {
    "Astrid #": "ASTRID 003945",
    "HYG Star ID": 39573,
    "Hipparcos Catalogue": 39685,
    "Gliese Catalog": "NN 3477",
    "Distance in Parsecs": 30.0571,
    "mag": 11.48,
    "Absolute Visual Magnitude": 9.09,
    "Color Index": 1.492,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -8.844181,
    "y": 14.346811,
    "z": 24.88732,
    "vx": -0.00002331,
    "vy": -0.00000214,
    "vz": -0.00000705,
    "rarad": 2.12322343525867,
    "decrad": 0.975533917050697,
    "pmrarad": 6.97743849055555e-7,
    "pmdecrad": -4.18394206e-7,
    "con": "Lyn",
    "Full Constellation Name Formula 1": "Lynx",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lynx",
    "comp": 1,
    "comp_primary": 39573,
    "lum": 0.0201372424986238,
    "var_min": 11.559,
    "var_max": 11.389,
    "ra": 8.110116,
    "dec": 55.893976,
    "pmra": 143.92,
    "pmdec": -86.3,
    "rv": 0,
    "Asset Name": "ASTRID 003945",
    "Astrium Color": "Green",
    "Background": "Velvet Nothingness",
    "Expression": "Nervous Grin",
    "Hair": "Prince",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lynx",
    "x_1": -0.00002331,
    "y_1": -0.00000214,
    "z_1": -0.00000705,
    "Distance in Parsecs_1": 30.0571,
    "HYG Star ID_1": 39573,
    "Hipparcos Catalogue_1": 39685,
    "Gliese Catalog_1": "NN 3477"
  },
  {
    "Astrid #": "ASTRID 003946",
    "HYG Star ID": 66029,
    "Hipparcos Catalogue": 66238,
    "Henry Draper Catalogue": 117939,
    "Gliese Catalog": "Wo 9450",
    "Distance in Parsecs": 30.0571,
    "mag": 7.29,
    "Absolute Visual Magnitude": 4.9,
    "Spectral Type": "G3V",
    "Color Index": 0.669,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -21.427773,
    "y": -9.377619,
    "z": -18.878161,
    "vx": -0.00000198,
    "vy": -0.00007181,
    "vz": -0.00009822,
    "rarad": 3.55411397212652,
    "decrad": -0.679058928531441,
    "pmrarad": 0.00000216241445938194,
    "pmdecrad": -0.000001903717879,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 66029,
    "lum": 0.954992586021435,
    "ra": 13.575715,
    "dec": -38.907211,
    "pmra": 446.03,
    "pmdec": -392.67,
    "rv": 83.6,
    "Asset Name": "ASTRID 003946",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Chubby Cheeks",
    "Hair": "Audiophile",
    "Outfit": "Scrubs",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000198,
    "y_1": -0.00007181,
    "z_1": -0.00009822,
    "Distance in Parsecs_1": 30.0571,
    "HYG Star ID_1": 66029,
    "Hipparcos Catalogue_1": 66238,
    "Henry Draper Catalogue_1": 117939,
    "Gliese Catalog_1": "Wo 9450"
  },
  {
    "Astrid #": "ASTRID 003947",
    "HYG Star ID": 74168,
    "Hipparcos Catalogue": 74396,
    "Henry Draper Catalogue": 135144,
    "Distance in Parsecs": 30.0571,
    "mag": 8.58,
    "Absolute Visual Magnitude": 6.19,
    "Spectral Type": "G5",
    "Color Index": 0.86,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -15.461936,
    "y": -17.216907,
    "z": 19.181655,
    "vx": 0.00000105,
    "vy": -0.00002013,
    "vz": -0.00001723,
    "rarad": 3.98064164570252,
    "decrad": 0.692123978376771,
    "pmrarad": 4.73566003166666e-7,
    "pmdecrad": -7.44528369e-7,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 74168,
    "lum": 0.29107171180666,
    "ra": 15.204931,
    "dec": 39.655783,
    "pmra": 97.68,
    "pmdec": -153.57,
    "rv": 0,
    "Asset Name": "ASTRID 003947",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Smitten",
    "Hair": "Round Brush",
    "Outfit": "Unit 01",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000105,
    "y_1": -0.00002013,
    "z_1": -0.00001723,
    "Distance in Parsecs_1": 30.0571,
    "HYG Star ID_1": 74168,
    "Hipparcos Catalogue_1": 74396,
    "Henry Draper Catalogue_1": 135144
  },
  {
    "Astrid #": "ASTRID 003948",
    "HYG Star ID": 69081,
    "Hipparcos Catalogue": 69303,
    "Distance in Parsecs": 30.0661,
    "mag": 10.31,
    "Absolute Visual Magnitude": 7.92,
    "Spectral Type": "K8",
    "Color Index": 1.26,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -7.244425,
    "y": -4.663956,
    "z": 28.805141,
    "vx": 0.00001298,
    "vy": -1.1e-7,
    "vz": 0.00000325,
    "rarad": 3.71359128169968,
    "decrad": 1.28015559424806,
    "pmrarad": 2.36879964319444e-7,
    "pmdecrad": 3.76942636e-7,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 69081,
    "lum": 0.0591561634175473,
    "var_min": 10.372,
    "var_max": 10.252,
    "ra": 14.184874,
    "dec": 73.347513,
    "pmra": 48.86,
    "pmdec": 77.75,
    "rv": 0,
    "Asset Name": "ASTRID 003948",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Maniacal",
    "Hair": "Afro",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00001298,
    "y_1": -1.1e-7,
    "z_1": 0.00000325,
    "Distance in Parsecs_1": 30.0661,
    "HYG Star ID_1": 69081,
    "Hipparcos Catalogue_1": 69303
  },
  {
    "Astrid #": "ASTRID 003949",
    "HYG Star ID": 85106,
    "Hipparcos Catalogue": 85365,
    "Henry Draper Catalogue": 157950,
    "Harvard Revised Catalogue": 6493,
    "Distance in Parsecs": 30.0752,
    "mag": 4.53,
    "Absolute Visual Magnitude": 2.139,
    "Spectral Type": "F3V",
    "Color Index": 0.385,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -4.346256,
    "y": -29.639799,
    "z": -2.666506,
    "vx": -0.00001318,
    "vy": 0.00000208,
    "vz": -0.00000624,
    "rarad": 4.56679081818783,
    "decrad": -0.08877784350617,
    "pmrarad": -4.437499618125e-7,
    "pmdecrad": -2.07257848e-7,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 85106,
    "lum": 12.1450693771577,
    "ra": 17.443856,
    "dec": -5.086596,
    "pmra": -91.53,
    "pmdec": -42.75,
    "rv": 0.4,
    "Asset Name": "ASTRID 003949",
    "Astrium Color": "Yellow",
    "Background": "Volatile Expanse",
    "Expression": "Oof",
    "Hair": "Spec Ops Goggles",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001318,
    "y_1": 0.00000208,
    "z_1": -0.00000624,
    "Distance in Parsecs_1": 30.0752,
    "HYG Star ID_1": 85106,
    "Hipparcos Catalogue_1": 85365,
    "Henry Draper Catalogue_1": 157950,
    "Harvard Revised Catalogue_1": 6493
  },
  {
    "Astrid #": "ASTRID 003950",
    "HYG Star ID": 16051,
    "Hipparcos Catalogue": 16090,
    "Distance in Parsecs": 30.0842,
    "mag": 11.78,
    "Absolute Visual Magnitude": 9.388,
    "Color Index": 1.476,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 16.515753,
    "y": 20.995144,
    "z": 13.838096,
    "vx": 0.00001376,
    "vy": -0.00000601,
    "vz": -0.0000073,
    "rarad": 0.904251585576138,
    "decrad": 0.477971383989763,
    "pmrarad": -4.83019869937499e-7,
    "pmdecrad": -2.73144027e-7,
    "con": "Ari",
    "Full Constellation Name Formula 1": "Aries",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aries",
    "comp": 1,
    "comp_primary": 16051,
    "lum": 0.0153038253217743,
    "var": "CK",
    "var_min": 11.866,
    "var_max": 11.616,
    "ra": 3.453987,
    "dec": 27.385743,
    "pmra": -99.63,
    "pmdec": -56.34,
    "rv": 0,
    "Asset Name": "ASTRID 003950",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Medium Bob",
    "Outfit": "Spec Ops",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aries",
    "x_1": 0.00001376,
    "y_1": -0.00000601,
    "z_1": -0.0000073,
    "Distance in Parsecs_1": 30.0842,
    "HYG Star ID_1": 16051,
    "Hipparcos Catalogue_1": 16090
  },
  {
    "Astrid #": "ASTRID 003951",
    "HYG Star ID": 64236,
    "Hipparcos Catalogue": 64441,
    "Distance in Parsecs": 30.1205,
    "mag": 9.52,
    "Absolute Visual Magnitude": 7.126,
    "Color Index": 1.04,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -14.229096,
    "y": -4.656869,
    "z": -26.136008,
    "vx": 0.0000283,
    "vy": -0.00002199,
    "vz": -0.00001149,
    "rarad": 3.45787478674411,
    "decrad": -1.05058655985127,
    "pmrarad": 9.86256470354166e-7,
    "pmdecrad": -7.67411574e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 64236,
    "lum": 0.122913617306016,
    "var_min": 9.577,
    "var_max": 9.467,
    "ra": 13.208109,
    "dec": -60.194176,
    "pmra": 203.43,
    "pmdec": -158.29,
    "rv": 0,
    "Asset Name": "ASTRID 003951",
    "Astrium Color": "Green",
    "Background": "Depth",
    "Expression": "Battle Ready",
    "Hair": "Bandana",
    "Outfit": "Teen",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": 0.0000283,
    "y_1": -0.00002199,
    "z_1": -0.00001149,
    "Distance in Parsecs_1": 30.1205,
    "HYG Star ID_1": 64236,
    "Hipparcos Catalogue_1": 64441
  },
  {
    "Astrid #": "ASTRID 003952",
    "HYG Star ID": 101587,
    "Hipparcos Catalogue": 101916,
    "Henry Draper Catalogue": 196755,
    "Harvard Revised Catalogue": 7896,
    "Distance in Parsecs": 30.1205,
    "mag": 5.07,
    "Absolute Visual Magnitude": 2.676,
    "Spectral Type": "G5IV+...",
    "Color Index": 0.702,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 18.975174,
    "y": -22.788914,
    "z": 5.274911,
    "vx": 0.00000246,
    "vy": 0.00007088,
    "vz": -0.00000628,
    "rarad": 5.40672321865094,
    "decrad": 0.176037453814617,
    "pmrarad": 0.00000156876010754166,
    "pmdecrad": 1.02150242e-7,
    "bayer": "Kap",
    "flam": 7,
    "con": "Del",
    "Full Constellation Name Formula 1": "Delphinus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Delphinus",
    "comp": 1,
    "comp_primary": 101587,
    "lum": 7.40627783668154,
    "bf": "7Kap Del",
    "ra": 20.652161,
    "dec": 10.086203,
    "pmra": 323.58,
    "pmdec": 21.07,
    "rv": -52,
    "Asset Name": "ASTRID 003952",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Firm",
    "Hair": "Hachimaki",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Spike",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Delphinus",
    "x_1": 0.00000246,
    "y_1": 0.00007088,
    "z_1": -0.00000628,
    "Distance in Parsecs_1": 30.1205,
    "HYG Star ID_1": 101587,
    "Hipparcos Catalogue_1": 101916,
    "Henry Draper Catalogue_1": 196755,
    "Harvard Revised Catalogue_1": 7896
  },
  {
    "Astrid #": "ASTRID 003953",
    "HYG Star ID": 115162,
    "Hipparcos Catalogue": 115527,
    "Henry Draper Catalogue": 220476,
    "Distance in Parsecs": 30.1205,
    "mag": 7.62,
    "Absolute Visual Magnitude": 5.226,
    "Spectral Type": "G5",
    "Color Index": 0.682,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 29.493852,
    "y": -4.657435,
    "z": -3.957968,
    "vx": 0.00000319,
    "vy": 0.00002079,
    "vz": -7.1e-7,
    "rarad": 6.12656645692501,
    "decrad": -0.131785593136497,
    "pmrarad": 6.98277144104166e-7,
    "pmdecrad": -2.3901314e-8,
    "con": "Aqr",
    "Full Constellation Name Formula 1": "Aquarius",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquarius",
    "comp": 1,
    "comp_primary": 115162,
    "lum": 0.707294042404575,
    "var": "NX",
    "var_min": 7.655,
    "var_max": 7.585,
    "ra": 23.40176,
    "dec": -7.550758,
    "pmra": 144.03,
    "pmdec": -4.93,
    "rv": 0,
    "Asset Name": "ASTRID 003953",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Scared",
    "Hair": "Ponytail",
    "Outfit": "Unit 01",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquarius",
    "x_1": 0.00000319,
    "y_1": 0.00002079,
    "z_1": -7.1e-7,
    "Distance in Parsecs_1": 30.1205,
    "HYG Star ID_1": 115162,
    "Hipparcos Catalogue_1": 115527,
    "Henry Draper Catalogue_1": 220476
  },
  {
    "Astrid #": "ASTRID 003954",
    "HYG Star ID": 6024,
    "Hipparcos Catalogue": 6037,
    "Henry Draper Catalogue": 7808,
    "Gliese Catalog": "Gl 56",
    "Distance in Parsecs": 30.1296,
    "mag": 9.75,
    "Absolute Visual Magnitude": 7.355,
    "Spectral Type": "K3V",
    "Color Index": 1.008,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 27.386211,
    "y": 9.639787,
    "z": -8.053447,
    "vx": -0.00003925,
    "vy": 0.00002942,
    "vz": -0.00006458,
    "rarad": 0.338449842422428,
    "decrad": -0.270583987792938,
    "pmrarad": 0.00000135364827747916,
    "pmdecrad": -0.000002307519193,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 1,
    "comp_primary": 6024,
    "lum": 0.0995405417351525,
    "ra": 1.292783,
    "dec": -15.503321,
    "pmra": 279.21,
    "pmdec": -475.96,
    "rv": -8.8,
    "Asset Name": "ASTRID 003954",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Glad",
    "Hair": "Bangs",
    "Outfit": "Samurai",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": -0.00003925,
    "y_1": 0.00002942,
    "z_1": -0.00006458,
    "Distance in Parsecs_1": 30.1296,
    "HYG Star ID_1": 6024,
    "Hipparcos Catalogue_1": 6037,
    "Henry Draper Catalogue_1": 7808,
    "Gliese Catalog_1": "Gl 56"
  },
  {
    "Astrid #": "ASTRID 003955",
    "HYG Star ID": 108463,
    "Hipparcos Catalogue": 108809,
    "Henry Draper Catalogue": 209253,
    "Distance in Parsecs": 30.1386,
    "mag": 6.63,
    "Absolute Visual Magnitude": 4.234,
    "Spectral Type": "F6/F7V",
    "Color Index": 0.504,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 22.24296,
    "y": -12.514167,
    "z": -16.030645,
    "vx": 1.4e-7,
    "vy": -0.00000334,
    "vz": 0.0000028,
    "rarad": 5.77071054321086,
    "decrad": -0.560839711584101,
    "pmrarad": -9.44901863402777e-8,
    "pmdecrad": 1.09761817e-7,
    "con": "PsA",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 108463,
    "lum": 1.76359963389799,
    "ra": 22.042491,
    "dec": -32.133748,
    "pmra": -19.49,
    "pmdec": 22.64,
    "rv": 0,
    "Asset Name": "ASTRID 003955",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Happy Vamp",
    "Hair": "Elegant",
    "Outfit": "Archangel",
    "Special": "Archangel Wings",
    "Astral Crystal": "Five Pointed",
    "Tether": "Fate Giver",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 1.4e-7,
    "y_1": -0.00000334,
    "z_1": 0.0000028,
    "Distance in Parsecs_1": 30.1386,
    "HYG Star ID_1": 108463,
    "Hipparcos Catalogue_1": 108809,
    "Henry Draper Catalogue_1": 209253
  },
  {
    "Astrid #": "ASTRID 003956",
    "HYG Star ID": 59250,
    "Hipparcos Catalogue": 59432,
    "Henry Draper Catalogue": 105963,
    "Gliese Catalog": "NN 3711A",
    "Distance in Parsecs": 30.1477,
    "mag": 7.96,
    "Absolute Visual Magnitude": 5.564,
    "Spectral Type": "K0",
    "Color Index": 0.88,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -17.943273,
    "y": -0.898194,
    "z": 24.209881,
    "vx": -0.00001825,
    "vy": 0.00002311,
    "vz": -0.00000719,
    "rarad": 3.19161304143944,
    "decrad": 0.932381437374858,
    "pmrarad": -7.96064063472222e-7,
    "pmdecrad": -5.96757159e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 59250,
    "base": "NN 3711",
    "lum": 0.518083785071992,
    "var": "MZ",
    "var_min": 8.287,
    "var_max": 7.897,
    "ra": 12.191064,
    "dec": 53.421521,
    "pmra": -164.2,
    "pmdec": -123.09,
    "rv": 4.3,
    "Asset Name": "ASTRID 003956",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Big Laugh",
    "Hair": "Bangs",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": -0.00001825,
    "y_1": 0.00002311,
    "z_1": -0.00000719,
    "Distance in Parsecs_1": 30.1477,
    "HYG Star ID_1": 59250,
    "Hipparcos Catalogue_1": 59432,
    "Henry Draper Catalogue_1": 105963,
    "Gliese Catalog_1": "NN 3711A"
  },
  {
    "Astrid #": "ASTRID 003957",
    "HYG Star ID": 72350,
    "Hipparcos Catalogue": 72577,
    "Henry Draper Catalogue": 130871,
    "Gliese Catalog": "Gl 563.3",
    "Distance in Parsecs": 30.1477,
    "mag": 9.08,
    "Absolute Visual Magnitude": 6.684,
    "Spectral Type": "K2V",
    "Color Index": 0.957,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -22.039124,
    "y": -20.257007,
    "z": 3.577374,
    "vx": -0.00003888,
    "vy": 0.00008756,
    "vz": -0.00001348,
    "rarad": 3.88488164261872,
    "decrad": 0.118942956506165,
    "pmrarad": -0.0000030111292885625,
    "pmdecrad": -3.23370724e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 72350,
    "lum": 0.18467155239867,
    "ra": 14.839155,
    "dec": 6.814929,
    "pmra": -621.09,
    "pmdec": -66.7,
    "rv": -31.3,
    "Asset Name": "ASTRID 003957",
    "Astrium Color": "Orange",
    "Background": "Burning Cosmos",
    "Expression": "Determined",
    "Hair": "Sweetheart",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00003888,
    "y_1": 0.00008756,
    "z_1": -0.00001348,
    "Distance in Parsecs_1": 30.1477,
    "HYG Star ID_1": 72350,
    "Hipparcos Catalogue_1": 72577,
    "Henry Draper Catalogue_1": 130871,
    "Gliese Catalog_1": "Gl 563.3"
  },
  {
    "Astrid #": "ASTRID 003958",
    "HYG Star ID": 101445,
    "Hipparcos Catalogue": 101772,
    "Henry Draper Catalogue": 196171,
    "Harvard Revised Catalogue": 7869,
    "Gliese Catalog": "Gl 794.1",
    "Distance in Parsecs": 30.1477,
    "mag": 3.11,
    "Absolute Visual Magnitude": 0.714,
    "Spectral Type": "K0III",
    "Color Index": 0.998,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.976855,
    "y": -15.802893,
    "z": -22.152943,
    "vx": 0.00000949,
    "vy": -2.2e-7,
    "vz": 0.00000753,
    "rarad": 5.39990459012289,
    "decrad": -0.825392413231517,
    "pmrarad": 2.38528330833333e-7,
    "pmdecrad": 3.20316398e-7,
    "bayer": "Alp",
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 101445,
    "lum": 45.1232113344337,
    "bf": "Alp Ind",
    "ra": 20.626116,
    "dec": -47.291502,
    "pmra": 49.2,
    "pmdec": 66.07,
    "rv": -1.3,
    "Asset Name": "ASTRID 003958",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Round Brush",
    "Outfit": "Scientist",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00000949,
    "y_1": -2.2e-7,
    "z_1": 0.00000753,
    "Distance in Parsecs_1": 30.1477,
    "HYG Star ID_1": 101445,
    "Hipparcos Catalogue_1": 101772,
    "Henry Draper Catalogue_1": 196171,
    "Harvard Revised Catalogue_1": 7869,
    "Gliese Catalog_1": "Gl 794.1"
  },
  {
    "Astrid #": "ASTRID 003959",
    "HYG Star ID": 37528,
    "Hipparcos Catalogue": 37635,
    "Henry Draper Catalogue": 62848,
    "Distance in Parsecs": 30.1568,
    "mag": 6.69,
    "Absolute Visual Magnitude": 4.293,
    "Spectral Type": "G0V",
    "Color Index": 0.55,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -8.062493,
    "y": 16.648757,
    "z": -23.816962,
    "vx": -1.6e-7,
    "vy": 0.00002101,
    "vz": 0.00001474,
    "rarad": 2.02178259346056,
    "decrad": -0.910435355379421,
    "pmrarad": -2.99033078166666e-7,
    "pmdecrad": 7.96985209e-7,
    "con": "Car",
    "Full Constellation Name Formula 1": "Carina",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Carina",
    "comp": 1,
    "comp_primary": 37528,
    "lum": 1.67032122581504,
    "ra": 7.722641,
    "dec": -52.164103,
    "pmra": -61.68,
    "pmdec": 164.39,
    "rv": 0,
    "Asset Name": "ASTRID 003959",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Uwu",
    "Hair": "Charmer",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Carina",
    "x_1": -1.6e-7,
    "y_1": 0.00002101,
    "z_1": 0.00001474,
    "Distance in Parsecs_1": 30.1568,
    "HYG Star ID_1": 37528,
    "Hipparcos Catalogue_1": 37635,
    "Henry Draper Catalogue_1": 62848
  },
  {
    "Astrid #": "ASTRID 003960",
    "HYG Star ID": 20954,
    "Hipparcos Catalogue": 21006,
    "Distance in Parsecs": 30.175,
    "mag": 10.45,
    "Absolute Visual Magnitude": 8.052,
    "Spectral Type": "K7",
    "Color Index": 1.364,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 11.511861,
    "y": 27.888016,
    "z": 0.516036,
    "vx": -0.00001111,
    "vy": 0.00000477,
    "vz": -0.00000979,
    "rarad": 1.1793139548393,
    "decrad": 0.0171022843250762,
    "pmrarad": 4.00553062875e-7,
    "pmdecrad": -3.24534277e-7,
    "con": "Tau",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Taurus",
    "FULL CONSTELLATION NAME": "Taurus",
    "comp": 1,
    "comp_primary": 20954,
    "lum": 0.052384161902779,
    "ra": 4.504647,
    "dec": 0.979889,
    "pmra": 82.62,
    "pmdec": -66.94,
    "rv": 0,
    "Asset Name": "ASTRID 003960",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Hurt",
    "Hair": "Grecian",
    "Outfit": "Sushi Chef",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Taurus",
    "x_1": -0.00001111,
    "y_1": 0.00000477,
    "z_1": -0.00000979,
    "Distance in Parsecs_1": 30.175,
    "HYG Star ID_1": 20954,
    "Hipparcos Catalogue_1": 21006
  },
  {
    "Astrid #": "ASTRID 003961",
    "HYG Star ID": 69341,
    "Hipparcos Catalogue": 69562,
    "Henry Draper Catalogue": 124498,
    "Gliese Catalog": "NN 3831A",
    "Distance in Parsecs": 30.175,
    "mag": 10.39,
    "Absolute Visual Magnitude": 7.992,
    "Spectral Type": "K4V",
    "Color Index": 1.27,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -24.239197,
    "y": -16.09778,
    "z": -7.990834,
    "vx": -0.0000106,
    "vy": 0.00002806,
    "vz": -0.00002438,
    "rarad": 3.72783170800271,
    "decrad": -0.268013505352328,
    "pmrarad": -9.69142547430555e-7,
    "pmdecrad": -8.38097409e-7,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 69341,
    "base": "NN 3831",
    "lum": 0.05536049950434,
    "var": "MV",
    "var_min": 11.95,
    "var_max": 10.27,
    "ra": 14.239268,
    "dec": -15.356043,
    "pmra": -199.9,
    "pmdec": -172.87,
    "rv": 0,
    "Asset Name": "ASTRID 003961",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Audiohead",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.0000106,
    "y_1": 0.00002806,
    "z_1": -0.00002438,
    "Distance in Parsecs_1": 30.175,
    "HYG Star ID_1": 69341,
    "Hipparcos Catalogue_1": 69562,
    "Henry Draper Catalogue_1": 124498,
    "Gliese Catalog_1": "NN 3831A"
  },
  {
    "Astrid #": "ASTRID 003962",
    "HYG Star ID": 103304,
    "Hipparcos Catalogue": 103636,
    "Distance in Parsecs": 30.175,
    "mag": 11.67,
    "Absolute Visual Magnitude": 9.272,
    "Color Index": 1.437,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 11.059344,
    "y": -11.07326,
    "z": -25.799311,
    "vx": -0.0000182,
    "vy": 0.00004335,
    "vz": -0.00002641,
    "rarad": 5.49715364878174,
    "decrad": -1.02553079439447,
    "pmrarad": 5.88660770930555e-7,
    "pmdecrad": -0.00000168758794,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 103304,
    "lum": 0.0170294256187754,
    "var_min": 11.754,
    "var_max": 11.554,
    "ra": 20.99758,
    "dec": -58.758586,
    "pmra": 121.42,
    "pmdec": -348.09,
    "rv": 0,
    "Asset Name": "ASTRID 003962",
    "Astrium Color": "Green",
    "Background": "Diesel",
    "Expression": "Upset",
    "Hair": "Goddess",
    "Outfit": "Fanny Pack",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.0000182,
    "y_1": 0.00004335,
    "z_1": -0.00002641,
    "Distance in Parsecs_1": 30.175,
    "HYG Star ID_1": 103304,
    "Hipparcos Catalogue_1": 103636
  },
  {
    "Astrid #": "ASTRID 003963",
    "HYG Star ID": 105101,
    "Hipparcos Catalogue": 105441,
    "Henry Draper Catalogue": 202746,
    "Distance in Parsecs": 30.175,
    "mag": 8.97,
    "Absolute Visual Magnitude": 6.572,
    "Spectral Type": "K2V(p)",
    "Color Index": 1.01,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 9.109668,
    "y": -7.549021,
    "z": -27.758905,
    "vx": -0.00000167,
    "vy": 0.00001953,
    "vz": -0.00000586,
    "rarad": 5.59119187164853,
    "decrad": -1.16790342462842,
    "pmrarad": 4.63045546298611e-7,
    "pmdecrad": -4.95043249e-7,
    "con": "Pav",
    "Full Constellation Name Formula 1": "Pavo",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Pavo",
    "comp": 1,
    "comp_primary": 105101,
    "lum": 0.204738727634163,
    "var": "V390",
    "var_min": 9.063,
    "var_max": 8.893,
    "ra": 21.35678,
    "dec": -66.915937,
    "pmra": 95.51,
    "pmdec": -102.11,
    "rv": 0,
    "Asset Name": "ASTRID 003963",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Anxious Laugh",
    "Hair": "Good Boy",
    "Outfit": "Pirate",
    "Astral Crystal": "Shuriken",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pavo",
    "x_1": -0.00000167,
    "y_1": 0.00001953,
    "z_1": -0.00000586,
    "Distance in Parsecs_1": 30.175,
    "HYG Star ID_1": 105101,
    "Hipparcos Catalogue_1": 105441,
    "Henry Draper Catalogue_1": 202746
  },
  {
    "Astrid #": "ASTRID 003964",
    "HYG Star ID": 117585,
    "Hipparcos Catalogue": 117953,
    "Henry Draper Catalogue": 224156,
    "Distance in Parsecs": 30.175,
    "mag": 7.72,
    "Absolute Visual Magnitude": 5.322,
    "Spectral Type": "G5",
    "Color Index": 0.751,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 30.113101,
    "y": -0.586045,
    "z": 1.842908,
    "vx": 0.00001714,
    "vy": -0.00003078,
    "vz": -0.00004194,
    "rarad": 6.26372631114889,
    "decrad": 0.0611117777123387,
    "pmrarad": -0.00000100889726923611,
    "pmdecrad": -0.000001423461447,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 117585,
    "lum": 0.647440704568814,
    "ra": 23.925672,
    "dec": 3.501447,
    "pmra": -208.1,
    "pmdec": -293.61,
    "rv": 14.8,
    "Asset Name": "ASTRID 003964",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Uwu",
    "Hair": "Side Part",
    "Outfit": "Scrubs",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00001714,
    "y_1": -0.00003078,
    "z_1": -0.00004194,
    "Distance in Parsecs_1": 30.175,
    "HYG Star ID_1": 117585,
    "Hipparcos Catalogue_1": 117953,
    "Henry Draper Catalogue_1": 224156
  },
  {
    "Astrid #": "ASTRID 003965",
    "HYG Star ID": 22543,
    "Hipparcos Catalogue": 22596,
    "Henry Draper Catalogue": 30649,
    "Gliese Catalog": "Wo 9168",
    "Distance in Parsecs": 30.1841,
    "mag": 6.94,
    "Absolute Visual Magnitude": 4.541,
    "Spectral Type": "G1V-VI",
    "Color Index": 0.586,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.172784,
    "y": 20.104257,
    "z": 21.652009,
    "vx": -0.00002978,
    "vy": 0.00009048,
    "vz": -0.00003859,
    "rarad": 1.27288765418799,
    "decrad": 0.799956949265791,
    "pmrarad": 0.00000182333577120138,
    "pmdecrad": -0.000002738712481,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 22543,
    "lum": 1.32922958831568,
    "ra": 4.862073,
    "dec": 45.834157,
    "pmra": 376.09,
    "pmdec": -564.9,
    "rv": 25.9,
    "Asset Name": "ASTRID 003965",
    "Astrium Color": "Yellow",
    "Background": "Burning Cosmos",
    "Expression": "Arrogant",
    "Hair": "Pixie",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Five Pointed",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": -0.00002978,
    "y_1": 0.00009048,
    "z_1": -0.00003859,
    "Distance in Parsecs_1": 30.1841,
    "HYG Star ID_1": 22543,
    "Hipparcos Catalogue_1": 22596,
    "Henry Draper Catalogue_1": 30649,
    "Gliese Catalog_1": "Wo 9168"
  },
  {
    "Astrid #": "ASTRID 003966",
    "HYG Star ID": 86498,
    "Hipparcos Catalogue": 86765,
    "Henry Draper Catalogue": 161098,
    "Distance in Parsecs": 30.1841,
    "mag": 7.67,
    "Absolute Visual Magnitude": 5.271,
    "Spectral Type": "G5",
    "Color Index": 0.676,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -2.135016,
    "y": -30.03778,
    "z": -2.062372,
    "vx": -0.00001559,
    "vy": 0.00000354,
    "vz": -0.00003544,
    "rarad": 4.64143062720756,
    "decrad": -0.0683797221697875,
    "pmrarad": -5.23404849527777e-7,
    "pmdecrad": -0.00000117693369,
    "con": "Oph",
    "Full Constellation Name Formula 1": "Ophiuchus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Ophiuchus",
    "comp": 1,
    "comp_primary": 86498,
    "lum": 0.678578350950407,
    "ra": 17.728959,
    "dec": -3.917869,
    "pmra": -107.96,
    "pmdec": -242.76,
    "rv": 0,
    "Asset Name": "ASTRID 003966",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Loopy",
    "Hair": "Halo Boy",
    "Outfit": "Tats",
    "Special": "Tiny Angel Wings",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ophiuchus",
    "x_1": -0.00001559,
    "y_1": 0.00000354,
    "z_1": -0.00003544,
    "Distance in Parsecs_1": 30.1841,
    "HYG Star ID_1": 86498,
    "Hipparcos Catalogue_1": 86765,
    "Henry Draper Catalogue_1": 161098
  },
  {
    "Astrid #": "ASTRID 003967",
    "HYG Star ID": 92881,
    "Hipparcos Catalogue": 93174,
    "Henry Draper Catalogue": 175813,
    "Harvard Revised Catalogue": 7152,
    "Distance in Parsecs": 30.1841,
    "mag": 4.83,
    "Absolute Visual Magnitude": 2.431,
    "Spectral Type": "F3IV/V",
    "Color Index": 0.396,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 6.100734,
    "y": -23.286509,
    "z": -18.210673,
    "vx": -0.00001003,
    "vy": -0.00003808,
    "vz": -0.00004621,
    "rarad": 4.96861761979669,
    "decrad": -0.647645555421212,
    "pmrarad": -6.41166092534722e-7,
    "pmdecrad": -5.3547671e-7,
    "bayer": "Eps",
    "con": "CrA",
    "Full Constellation Name Formula 1": "Corona Australis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Australis",
    "comp": 1,
    "comp_primary": 92881,
    "lum": 9.28111171020168,
    "var": "Eps",
    "var_min": 4.99,
    "var_max": 4.73,
    "bf": "Eps CrA",
    "ra": 18.978721,
    "dec": -37.107357,
    "pmra": -132.25,
    "pmdec": -110.45,
    "rv": 54,
    "Asset Name": "ASTRID 003967",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Smoulder",
    "Hair": "Unkempt",
    "Outfit": "Boxer",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "Great",
    "Proper Name_1": "None",
    "Constellation": "Corona Australis",
    "x_1": -0.00001003,
    "y_1": -0.00003808,
    "z_1": -0.00004621,
    "Distance in Parsecs_1": 30.1841,
    "HYG Star ID_1": 92881,
    "Hipparcos Catalogue_1": 93174,
    "Henry Draper Catalogue_1": 175813,
    "Harvard Revised Catalogue_1": 7152
  },
  {
    "Astrid #": "ASTRID 003968",
    "HYG Star ID": 108584,
    "Hipparcos Catalogue": 108930,
    "Gliese Catalog": "Gl 847A",
    "Distance in Parsecs": 30.1841,
    "mag": 10.98,
    "Absolute Visual Magnitude": 8.581,
    "Spectral Type": "M",
    "Color Index": 1.34,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 9.063785,
    "y": -5.01912,
    "z": -28.350154,
    "vx": 0.0000323,
    "vy": 0.0000867,
    "vz": 0.00000488,
    "rarad": 5.77741261497451,
    "decrad": -1.22042072106898,
    "pmrarad": 0.00000303153994451388,
    "pmdecrad": -3.72579313e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 108584,
    "base": "Gl 847",
    "lum": 0.032181034430934397,
    "var_min": 11.072,
    "var_max": 10.892,
    "ra": 22.068091,
    "dec": -69.924957,
    "pmra": 625.3,
    "pmdec": -76.85,
    "rv": -9.1,
    "Asset Name": "ASTRID 003968",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Firm",
    "Hair": "Angelic",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Leaf",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.0000323,
    "y_1": 0.0000867,
    "z_1": 0.00000488,
    "Distance in Parsecs_1": 30.1841,
    "HYG Star ID_1": 108584,
    "Hipparcos Catalogue_1": 108930,
    "Gliese Catalog_1": "Gl 847A"
  },
  {
    "Astrid #": "ASTRID 003969",
    "HYG Star ID": 57933,
    "Hipparcos Catalogue": 58106,
    "Henry Draper Catalogue": 103493,
    "Gliese Catalog": "Gl 452.5A",
    "Distance in Parsecs": 30.2024,
    "mag": 6.7,
    "Absolute Visual Magnitude": 4.3,
    "Spectral Type": "G5V",
    "Color Index": 0.646,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -16.843279,
    "y": 0.365879,
    "z": -25.067563,
    "vx": -0.00000582,
    "vy": -0.00003049,
    "vz": -0.0000595,
    "rarad": 3.11986650315124,
    "decrad": -0.979059654762198,
    "pmrarad": 0.00000101350299920138,
    "pmdecrad": -9.57458537e-7,
    "con": "Cen",
    "Full Constellation Name Formula 1": "Centaurus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Centaurus",
    "comp": 1,
    "comp_primary": 57933,
    "base": "Gl 452.5",
    "lum": 1.65958690743756,
    "ra": 11.917012,
    "dec": -56.095986,
    "pmra": 209.05,
    "pmdec": -197.49,
    "rv": 51.1,
    "Asset Name": "ASTRID 003969",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Blep",
    "Hair": "Slick",
    "Outfit": "Basketball",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Centaurus",
    "x_1": -0.00000582,
    "y_1": -0.00003049,
    "z_1": -0.0000595,
    "Distance in Parsecs_1": 30.2024,
    "HYG Star ID_1": 57933,
    "Hipparcos Catalogue_1": 58106,
    "Henry Draper Catalogue_1": 103493,
    "Gliese Catalog_1": "Gl 452.5A"
  },
  {
    "Astrid #": "ASTRID 003970",
    "HYG Star ID": 108816,
    "Hipparcos Catalogue": 109162,
    "Henry Draper Catalogue": 210144,
    "Distance in Parsecs": 30.2024,
    "mag": 7.8,
    "Absolute Visual Magnitude": 5.4,
    "Spectral Type": "G8V",
    "Color Index": 0.788,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 15.95127,
    "y": -8.598151,
    "z": 24.161834,
    "vx": -0.00002124,
    "vy": -0.00007695,
    "vz": -0.0000595,
    "rarad": 5.78882135683061,
    "decrad": 0.927304748432846,
    "pmrarad": -0.00000257659078668055,
    "pmdecrad": -0.000001653893389,
    "con": "Lac",
    "Full Constellation Name Formula 1": "Lacerta",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Lacerta",
    "comp": 1,
    "comp_primary": 108816,
    "lum": 0.602559586074357,
    "ra": 22.111669,
    "dec": 53.130648,
    "pmra": -531.46,
    "pmdec": -341.14,
    "rv": -36.1,
    "Asset Name": "ASTRID 003970",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Angry",
    "Hair": "Mohawk",
    "Outfit": "Spartan",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Lacerta",
    "x_1": -0.00002124,
    "y_1": -0.00007695,
    "z_1": -0.0000595,
    "Distance in Parsecs_1": 30.2024,
    "HYG Star ID_1": 108816,
    "Hipparcos Catalogue_1": 109162,
    "Henry Draper Catalogue_1": 210144
  },
  {
    "Astrid #": "ASTRID 003971",
    "HYG Star ID": 14629,
    "Hipparcos Catalogue": 14665,
    "Distance in Parsecs": 30.2206,
    "mag": 11.98,
    "Absolute Visual Magnitude": 9.578,
    "Color Index": 1.459,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 15.095803,
    "y": 16.397084,
    "z": -20.40924,
    "vx": -0.00001228,
    "vy": 0.00005595,
    "vz": 0.00003587,
    "rarad": 0.826689685054686,
    "decrad": -0.741428297586792,
    "pmrarad": 0.00000155314910702777,
    "pmdecrad": 0.000001609532938,
    "con": "Eri",
    "Full Constellation Name Formula 1": "Eridanus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Eridanus",
    "comp": 1,
    "comp_primary": 14629,
    "lum": 0.0128469489983119,
    "var_min": 12.085,
    "var_max": 11.835,
    "ra": 3.157722,
    "dec": -42.480712,
    "pmra": 320.36,
    "pmdec": 331.99,
    "rv": 0,
    "Asset Name": "ASTRID 003971",
    "Astrium Color": "Green",
    "Background": "Haze",
    "Expression": "Nervous Grin",
    "Hair": "Charmer",
    "Outfit": "Boxer",
    "Astral Crystal": "Snowflake",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Eridanus",
    "x_1": -0.00001228,
    "y_1": 0.00005595,
    "z_1": 0.00003587,
    "Distance in Parsecs_1": 30.2206,
    "HYG Star ID_1": 14629,
    "Hipparcos Catalogue_1": 14665
  },
  {
    "Astrid #": "ASTRID 003972",
    "HYG Star ID": 118069,
    "Gliese Catalog": "Gl 57.1B",
    "Distance in Parsecs": 30.2206,
    "mag": 10.38,
    "Absolute Visual Magnitude": 7.978,
    "Spectral Type": "dK7   J",
    "Color Index": 1.38,
    "Spectral Abbreviation": "d",
    "Star Type": "White Dwarf",
    "Color In Star Map": "White",
    "x": 27.542823,
    "y": 10.436996,
    "z": -6.776937,
    "vx": 0.000029,
    "vy": 0.00008444,
    "vz": -0.00001805,
    "rarad": 0.362214897871132,
    "decrad": -0.22614958930825,
    "pmrarad": 0.00000227309742265277,
    "pmdecrad": -1.58946165e-7,
    "con": "Cet",
    "Full Constellation Name Formula 1": "Cetus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cetus",
    "comp": 2,
    "comp_primary": 6442,
    "base": "Gl  57.1",
    "lum": 0.0560789663374509,
    "ra": 1.383559,
    "dec": -12.957417,
    "pmra": 468.86,
    "pmdec": -32.78,
    "rv": 58.3,
    "Asset Name": "ASTRID 003972",
    "Astrium Color": "White",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Medium Bob",
    "Outfit": "Unit 01",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cetus",
    "x_1": 0.000029,
    "y_1": 0.00008444,
    "z_1": -0.00001805,
    "Distance in Parsecs_1": 30.2206,
    "HYG Star ID_1": 118069,
    "Gliese Catalog_1": "Gl 57.1B"
  },
  {
    "Astrid #": "ASTRID 003973",
    "HYG Star ID": 118070,
    "Gliese Catalog": "Gl 57.1C",
    "Distance in Parsecs": 30.2206,
    "mag": 13.1,
    "Absolute Visual Magnitude": 10.698,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 27.540106,
    "y": 10.435967,
    "z": -6.776269,
    "vx": -0.00002534,
    "vy": 0.00006385,
    "vz": -0.00000468,
    "rarad": 0.362214897871132,
    "decrad": -0.22614958930825,
    "pmrarad": 0.00000227309742265277,
    "pmdecrad": -1.58946165e-7,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 3,
    "comp_primary": 6442,
    "base": "Gl  57.1",
    "lum": 0.00457930953151743,
    "ra": 1.383559,
    "dec": -12.957417,
    "pmra": 468.86,
    "pmdec": -32.78,
    "rv": 0,
    "Asset Name": "ASTRID 003973",
    "Astrium Color": "Green",
    "Background": "Midnight",
    "Expression": "Mischievous",
    "Hair": "Slick",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": -0.00002534,
    "y_1": 0.00006385,
    "z_1": -0.00000468,
    "Distance in Parsecs_1": 30.2206,
    "HYG Star ID_1": 118070,
    "Gliese Catalog_1": "Gl 57.1C"
  },
  {
    "Astrid #": "ASTRID 003974",
    "HYG Star ID": 65369,
    "Hipparcos Catalogue": 65574,
    "Henry Draper Catalogue": 116858,
    "Gliese Catalog": "Gl 511",
    "Distance in Parsecs": 30.2297,
    "mag": 8.77,
    "Absolute Visual Magnitude": 6.368,
    "Spectral Type": "K3V",
    "Color Index": 0.92,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -25.606453,
    "y": -10.171837,
    "z": -12.436727,
    "vx": -0.00000477,
    "vy": 0.00005107,
    "vz": -0.00000485,
    "rarad": 3.51971647799254,
    "decrad": -0.423999309057568,
    "pmrarad": -0.00000162834370888194,
    "pmdecrad": -3.42520865e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 65369,
    "lum": 0.247058613603842,
    "ra": 13.444327,
    "dec": -24.293371,
    "pmra": -335.87,
    "pmdec": -70.65,
    "rv": -10.9,
    "Asset Name": "ASTRID 003974",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Chuckle",
    "Hair": "Pixie",
    "Outfit": "Stars and Loathing",
    "Astral Crystal": "Icicle",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000477,
    "y_1": 0.00005107,
    "z_1": -0.00000485,
    "Distance in Parsecs_1": 30.2297,
    "HYG Star ID_1": 65369,
    "Hipparcos Catalogue_1": 65574,
    "Henry Draper Catalogue_1": 116858,
    "Gliese Catalog_1": "Gl 511"
  },
  {
    "Astrid #": "ASTRID 003975",
    "HYG Star ID": 85177,
    "Hipparcos Catalogue": 85436,
    "Henry Draper Catalogue": 158332,
    "Distance in Parsecs": 30.2297,
    "mag": 7.69,
    "Absolute Visual Magnitude": 5.288,
    "Spectral Type": "K1IV",
    "Color Index": 0.82,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.80478,
    "y": -26.714018,
    "z": 13.627422,
    "vx": -0.00000904,
    "vy": 0.00004179,
    "vz": 0.00002495,
    "rarad": 4.57091466200537,
    "decrad": 0.467660282828177,
    "pmrarad": -4.90970814298611e-7,
    "pmdecrad": 0.000001334837506,
    "con": "Her",
    "Full Constellation Name Formula 1": "Hercules",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hercules",
    "comp": 1,
    "comp_primary": 85177,
    "lum": 0.668036204679246,
    "ra": 17.459608,
    "dec": 26.79496,
    "pmra": -101.27,
    "pmdec": 275.33,
    "rv": -24,
    "Asset Name": "ASTRID 003975",
    "Astrium Color": "Orange",
    "Background": "Hazy Void",
    "Expression": "Nervous",
    "Hair": "Braided Bun",
    "Outfit": "Knight of the Dark",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hercules",
    "x_1": -0.00000904,
    "y_1": 0.00004179,
    "z_1": 0.00002495,
    "Distance in Parsecs_1": 30.2297,
    "HYG Star ID_1": 85177,
    "Hipparcos Catalogue_1": 85436,
    "Henry Draper Catalogue_1": 158332
  },
  {
    "Astrid #": "ASTRID 003976",
    "HYG Star ID": 61679,
    "Hipparcos Catalogue": 61872,
    "Distance in Parsecs": 30.2389,
    "mag": 10.41,
    "Absolute Visual Magnitude": 8.007,
    "Spectral Type": "G0",
    "Color Index": 1.299,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -24.854801,
    "y": -4.467879,
    "z": -16.633339,
    "vx": -0.00000872,
    "vy": 0.00002606,
    "vz": 0.00000602,
    "rarad": 3.31945386871637,
    "decrad": -0.582441244452895,
    "pmrarad": -8.99232414694444e-7,
    "pmdecrad": 2.38576812e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 61679,
    "lum": 0.0546009249754273,
    "ra": 12.67938,
    "dec": -33.371425,
    "pmra": -185.48,
    "pmdec": 49.21,
    "rv": 0,
    "Asset Name": "ASTRID 003976",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Malevolent",
    "Hair": "Braid",
    "Outfit": "Toga",
    "Astral Crystal": "Snowflake",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000872,
    "y_1": 0.00002606,
    "z_1": 0.00000602,
    "Distance in Parsecs_1": 30.2389,
    "HYG Star ID_1": 61679,
    "Hipparcos Catalogue_1": 61872
  },
  {
    "Astrid #": "ASTRID 003977",
    "HYG Star ID": 21518,
    "Hipparcos Catalogue": 21571,
    "Henry Draper Catalogue": 29021,
    "Distance in Parsecs": 30.248,
    "mag": 7.76,
    "Absolute Visual Magnitude": 5.357,
    "Spectral Type": "G5",
    "Color Index": 0.71,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.195751,
    "y": 13.872736,
    "z": 26.372199,
    "vx": -0.00000931,
    "vy": 4.8e-7,
    "vz": 0.00000158,
    "rarad": 1.21243544557296,
    "decrad": 1.05899939281159,
    "pmrarad": 2.93894053152777e-7,
    "pmdecrad": 1.06949897e-7,
    "con": "Cam",
    "Full Constellation Name Formula 1": "Camelopardalis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Camelopardalis",
    "comp": 1,
    "comp_primary": 21518,
    "lum": 0.626902497476164,
    "ra": 4.631162,
    "dec": 60.676196,
    "pmra": 60.62,
    "pmdec": 22.06,
    "rv": 0,
    "Asset Name": "ASTRID 003977",
    "Astrium Color": "Yellow",
    "Background": "Hazy Void",
    "Expression": "Sweet Nap",
    "Hair": "Braided Ponytail",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Ring",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Camelopardalis",
    "x_1": -0.00000931,
    "y_1": 4.8e-7,
    "z_1": 0.00000158,
    "Distance in Parsecs_1": 30.248,
    "HYG Star ID_1": 21518,
    "Hipparcos Catalogue_1": 21571,
    "Henry Draper Catalogue_1": 29021
  },
  {
    "Astrid #": "ASTRID 003978",
    "HYG Star ID": 75882,
    "Hipparcos Catalogue": 76114,
    "Henry Draper Catalogue": 138573,
    "Distance in Parsecs": 30.248,
    "mag": 7.22,
    "Absolute Visual Magnitude": 4.817,
    "Spectral Type": "G5IV-V",
    "Color Index": 0.656,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -17.795616,
    "y": -23.77222,
    "z": 5.755106,
    "vx": 0.00002179,
    "vy": 0.0000306,
    "vz": 0.00001642,
    "rarad": 4.0697916786549,
    "decrad": 0.191432936819753,
    "pmrarad": -2.95251531458333e-8,
    "pmdecrad": 7.69350829e-7,
    "con": "Ser",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Serpens",
    "FULL CONSTELLATION NAME": "Serpens",
    "comp": 1,
    "comp_primary": 75882,
    "lum": 1.03086074006262,
    "ra": 15.545459,
    "dec": 10.968299,
    "pmra": -6.09,
    "pmdec": 158.69,
    "rv": -33,
    "Asset Name": "ASTRID 003978",
    "Astrium Color": "Yellow",
    "Background": "Abyss",
    "Expression": "Uwu",
    "Hair": "Halo Boy",
    "Outfit": "Gas Punk",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Serpens",
    "x_1": 0.00002179,
    "y_1": 0.0000306,
    "z_1": 0.00001642,
    "Distance in Parsecs_1": 30.248,
    "HYG Star ID_1": 75882,
    "Hipparcos Catalogue_1": 76114,
    "Henry Draper Catalogue_1": 138573
  },
  {
    "Astrid #": "ASTRID 003979",
    "HYG Star ID": 83945,
    "Hipparcos Catalogue": 84203,
    "Distance in Parsecs": 30.248,
    "mag": 9.92,
    "Absolute Visual Magnitude": 7.517,
    "Spectral Type": "K4",
    "Color Index": 1.12,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -3.183263,
    "y": -15.219422,
    "z": 25.945666,
    "vx": -0.00003665,
    "vy": 0.00005361,
    "vz": 0.00002695,
    "rarad": 4.50621625200333,
    "decrad": 1.03090521697163,
    "pmrarad": -0.00000154902819074305,
    "pmdecrad": 0.000001733596758,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 83945,
    "lum": 0.0857432616636179,
    "ra": 17.212478,
    "dec": 59.066518,
    "pmra": -319.51,
    "pmdec": 357.58,
    "rv": 0,
    "Asset Name": "ASTRID 003979",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Unimpressed",
    "Hair": "Bob",
    "Outfit": "Sage Mode",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00003665,
    "y_1": 0.00005361,
    "z_1": 0.00002695,
    "Distance in Parsecs_1": 30.248,
    "HYG Star ID_1": 83945,
    "Hipparcos Catalogue_1": 84203
  },
  {
    "Astrid #": "ASTRID 003980",
    "HYG Star ID": 85557,
    "Hipparcos Catalogue": 85819,
    "Henry Draper Catalogue": 159541,
    "Harvard Revised Catalogue": 6554,
    "Distance in Parsecs": 30.248,
    "mag": 4.89,
    "Absolute Visual Magnitude": 2.487,
    "Spectral Type": "Am...",
    "Color Index": 0.251,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -2.091471,
    "y": -17.142584,
    "z": 24.833263,
    "vx": 0.00002347,
    "vy": 0.00001254,
    "vz": -0.00000805,
    "rarad": 4.59097984282039,
    "decrad": 0.963146726925725,
    "pmrarad": 7.20093759729166e-7,
    "pmdecrad": 2.6296294e-7,
    "bayer": "Nu-1",
    "flam": 24,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 85557,
    "lum": 8.81454704447221,
    "bf": "24Nu 1Dra",
    "ra": 17.536251,
    "dec": 55.184243,
    "pmra": 148.53,
    "pmdec": 54.24,
    "rv": -15,
    "Asset Name": "ASTRID 003980",
    "Astrium Color": "White",
    "Background": "Supple Emptiness",
    "Expression": "Awkward",
    "Hair": "Pirate Hat",
    "Outfit": "Turtle School",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": 0.00002347,
    "y_1": 0.00001254,
    "z_1": -0.00000805,
    "Distance in Parsecs_1": 30.248,
    "HYG Star ID_1": 85557,
    "Hipparcos Catalogue_1": 85819,
    "Henry Draper Catalogue_1": 159541,
    "Harvard Revised Catalogue_1": 6554
  },
  {
    "Astrid #": "ASTRID 003981",
    "HYG Star ID": 87186,
    "Hipparcos Catalogue": 87457,
    "Distance in Parsecs": 30.248,
    "mag": 11.15,
    "Absolute Visual Magnitude": 8.747,
    "Spectral Type": "M3",
    "Color Index": 1.37,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -0.438904,
    "y": -12.886296,
    "z": 27.362241,
    "vx": -0.00000177,
    "vy": -0.00004602,
    "vz": -0.0000217,
    "rarad": 4.67834240045874,
    "decrad": 1.13043267193617,
    "pmrarad": -6.69042879166666e-9,
    "pmdecrad": -0.00000168332158,
    "con": "Dra",
    "Full Constellation Name Formula 1": "Draco",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Draco",
    "comp": 1,
    "comp_primary": 87186,
    "lum": 0.0276184944207529,
    "var_min": 11.207,
    "var_max": 11.067,
    "ra": 17.869952,
    "dec": 64.769021,
    "pmra": -1.38,
    "pmdec": -347.21,
    "rv": 0,
    "Asset Name": "ASTRID 003981",
    "Astrium Color": "Red",
    "Background": "Velvet Nothingness",
    "Expression": "Stunned",
    "Hair": "Middle Part",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Leaf",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Draco",
    "x_1": -0.00000177,
    "y_1": -0.00004602,
    "z_1": -0.0000217,
    "Distance in Parsecs_1": 30.248,
    "HYG Star ID_1": 87186,
    "Hipparcos Catalogue_1": 87457
  },
  {
    "Astrid #": "ASTRID 003982",
    "HYG Star ID": 38882,
    "Hipparcos Catalogue": 38992,
    "Henry Draper Catalogue": 65371,
    "Distance in Parsecs": 30.2572,
    "mag": 8.12,
    "Absolute Visual Magnitude": 5.716,
    "Spectral Type": "K0",
    "Color Index": 0.789,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -14.762171,
    "y": 25.870419,
    "z": 5.32164,
    "vx": -0.00007707,
    "vy": 0.00000238,
    "vz": 0.00001074,
    "rarad": 2.08933219127106,
    "decrad": 0.176797620624481,
    "pmrarad": 0.00000217351669266666,
    "pmdecrad": 1.15385655e-7,
    "con": "Cnc",
    "Full Constellation Name Formula 1": "Cancer",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cancer",
    "comp": 1,
    "comp_primary": 38882,
    "lum": 0.450401678168087,
    "ra": 7.980661,
    "dec": 10.129757,
    "pmra": 448.32,
    "pmdec": 23.8,
    "rv": 40.6,
    "Asset Name": "ASTRID 003982",
    "Astrium Color": "Orange",
    "Background": "Depth",
    "Expression": "Serious Slumber",
    "Hair": "Greasy",
    "Outfit": "School Uniform",
    "Astral Crystal": "Spike",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cancer",
    "x_1": -0.00007707,
    "y_1": 0.00000238,
    "z_1": 0.00001074,
    "Distance in Parsecs_1": 30.2572,
    "HYG Star ID_1": 38882,
    "Hipparcos Catalogue_1": 38992,
    "Henry Draper Catalogue_1": 65371
  },
  {
    "Astrid #": "ASTRID 003983",
    "HYG Star ID": 100701,
    "Hipparcos Catalogue": 101027,
    "Henry Draper Catalogue": 194943,
    "Harvard Revised Catalogue": 7822,
    "Gliese Catalog": "Gl 791.1A",
    "Distance in Parsecs": 30.2663,
    "mag": 4.77,
    "Absolute Visual Magnitude": 2.365,
    "Spectral Type": "F3V",
    "Color Index": 0.386,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 17.427785,
    "y": -22.947733,
    "z": -9.2592,
    "vx": 0.00000878,
    "vy": -0.0000154,
    "vz": -0.00000683,
    "rarad": 5.36191430516929,
    "decrad": -0.310907486946361,
    "pmrarad": -7.65520801597222e-8,
    "pmdecrad": -3.7136727e-8,
    "bayer": "Rho",
    "flam": 11,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 1,
    "comp_primary": 100701,
    "base": "Gl 791.1",
    "lum": 9.8627948563121,
    "bf": "11Rho Cap",
    "ra": 20.481004,
    "dec": -17.813687,
    "pmra": -15.79,
    "pmdec": -7.66,
    "rv": 18.4,
    "Asset Name": "ASTRID 003983",
    "Astrium Color": "Yellow",
    "Background": "Depth",
    "Expression": "Determined Neko",
    "Hair": "Pirate Hat",
    "Outfit": "River Spirit",
    "Astral Crystal": "Spike",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": 0.00000878,
    "y_1": -0.0000154,
    "z_1": -0.00000683,
    "Distance in Parsecs_1": 30.2663,
    "HYG Star ID_1": 100701,
    "Hipparcos Catalogue_1": 101027,
    "Henry Draper Catalogue_1": 194943,
    "Harvard Revised Catalogue_1": 7822,
    "Gliese Catalog_1": "Gl 791.1A"
  },
  {
    "Astrid #": "ASTRID 003984",
    "HYG Star ID": 119345,
    "Gliese Catalog": "Gl 791.1B",
    "Distance in Parsecs": 30.2663,
    "mag": 10,
    "Absolute Visual Magnitude": 7.595,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": 17.428305,
    "y": -22.94695,
    "z": -9.259621,
    "vx": -0.0000018,
    "vy": -4.5e-7,
    "vz": -0.00000227,
    "rarad": 5.36194522865658,
    "decrad": -0.31092387886815,
    "pmrarad": -5.62868683124999e-8,
    "pmdecrad": -7.8937363e-8,
    "con": "Cap",
    "Full Constellation Name Formula 1": "Capricornus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Capricornus",
    "comp": 2,
    "comp_primary": 100701,
    "base": "Gl 791.1",
    "lum": 0.0797994687267976,
    "ra": 20.481122,
    "dec": -17.814626,
    "pmra": -11.61,
    "pmdec": -16.28,
    "rv": 0,
    "Asset Name": "ASTRID 003984",
    "Astrium Color": "Green",
    "Background": "Volatile Expanse",
    "Expression": "Awkward",
    "Hair": "Skater Helmet",
    "Outfit": "Sweater Buddy",
    "Astral Crystal": "Glass",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Capricornus",
    "x_1": -0.0000018,
    "y_1": -4.5e-7,
    "z_1": -0.00000227,
    "Distance in Parsecs_1": 30.2663,
    "HYG Star ID_1": 119345,
    "Gliese Catalog_1": "Gl 791.1B"
  },
  {
    "Astrid #": "ASTRID 003985",
    "HYG Star ID": 721,
    "Hipparcos Catalogue": 723,
    "Henry Draper Catalogue": 404,
    "Distance in Parsecs": 30.2847,
    "mag": 8.57,
    "Absolute Visual Magnitude": 6.164,
    "Spectral Type": "G5",
    "Color Index": 0.885,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 12.087555,
    "y": 0.472486,
    "z": 27.763257,
    "vx": -0.00002617,
    "vy": 0.00002466,
    "vz": -0.00005562,
    "rarad": 0.039057554073668,
    "decrad": 1.15988756383033,
    "pmrarad": 8.47551276347222e-7,
    "pmdecrad": 2.8894895e-8,
    "con": "Cas",
    "Full Constellation Name Formula 1": "Cassiopeia",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cassiopeia",
    "comp": 1,
    "comp_primary": 721,
    "lum": 0.298126100817224,
    "var": "V740",
    "var_min": 8.631,
    "var_max": 8.521,
    "ra": 0.149189,
    "dec": 66.456662,
    "pmra": 174.82,
    "pmdec": 5.96,
    "rv": -59.7,
    "Asset Name": "ASTRID 003985",
    "Astrium Color": "Yellow",
    "Background": "Diesel",
    "Expression": "Shy Smile",
    "Hair": "Slick",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cassiopeia",
    "x_1": -0.00002617,
    "y_1": 0.00002466,
    "z_1": -0.00005562,
    "Distance in Parsecs_1": 30.2847,
    "HYG Star ID_1": 721,
    "Hipparcos Catalogue_1": 723,
    "Henry Draper Catalogue_1": 404
  },
  {
    "Astrid #": "ASTRID 003986",
    "HYG Star ID": 42388,
    "Hipparcos Catalogue": 42507,
    "Gliese Catalog": "Gl 316",
    "Distance in Parsecs": 30.2847,
    "mag": 9.9,
    "Absolute Visual Magnitude": 7.494,
    "Spectral Type": "M0",
    "Color Index": 1.361,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -19.342838,
    "y": 23.050984,
    "z": -3.415635,
    "vx": -0.00000765,
    "vy": -0.00001752,
    "vz": -0.00002051,
    "rarad": 2.26894749888736,
    "decrad": -0.113024873496776,
    "pmrarad": 5.65244270159722e-7,
    "pmdecrad": -7.04628203e-7,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 42388,
    "lum": 0.0875790036306368,
    "ra": 8.666741,
    "dec": -6.475848,
    "pmra": 116.59,
    "pmdec": -145.34,
    "rv": -6,
    "Asset Name": "ASTRID 003986",
    "Astrium Color": "Red",
    "Background": "Abyss",
    "Expression": "Determined",
    "Hair": "Sweetheart",
    "Outfit": "River Spirit",
    "Astral Crystal": "Leaf",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": -0.00000765,
    "y_1": -0.00001752,
    "z_1": -0.00002051,
    "Distance in Parsecs_1": 30.2847,
    "HYG Star ID_1": 42388,
    "Hipparcos Catalogue_1": 42507,
    "Gliese Catalog_1": "Gl 316"
  },
  {
    "Astrid #": "ASTRID 003987",
    "HYG Star ID": 71971,
    "Hipparcos Catalogue": 72197,
    "Henry Draper Catalogue": 129926,
    "Harvard Revised Catalogue": 5497,
    "Gliese Catalog": "Gl 561.1A",
    "Distance in Parsecs": 30.2847,
    "mag": 5.15,
    "Absolute Visual Magnitude": 2.744,
    "Spectral Type": "F0V + G/K",
    "Color Index": 0.315,
    "Spectral Abbreviation": "F",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -20.481798,
    "y": -18.120976,
    "z": -13.01071,
    "vx": 0.00000404,
    "vy": 0.00003327,
    "vz": -0.0000058,
    "rarad": 3.86591079018864,
    "decrad": -0.444067273879958,
    "pmrarad": -7.34492726041666e-7,
    "pmdecrad": -5.28786281e-7,
    "flam": 54,
    "con": "Hya",
    "Full Constellation Name Formula 1": "Hydra",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Hydra",
    "comp": 1,
    "comp_primary": 71971,
    "base": "Gl 561.1",
    "lum": 6.95664753528352,
    "bf": "54    Hya",
    "ra": 14.766691,
    "dec": -25.443181,
    "pmra": -151.5,
    "pmdec": -109.07,
    "rv": -19.7,
    "Asset Name": "ASTRID 003987",
    "Astrium Color": "Yellow",
    "Background": "Haze",
    "Expression": "Sad",
    "Hair": "Heartbreaker",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Hydra",
    "x_1": 0.00000404,
    "y_1": 0.00003327,
    "z_1": -0.0000058,
    "Distance in Parsecs_1": 30.2847,
    "HYG Star ID_1": 71971,
    "Hipparcos Catalogue_1": 72197,
    "Henry Draper Catalogue_1": 129926,
    "Harvard Revised Catalogue_1": 5497,
    "Gliese Catalog_1": "Gl 561.1A"
  },
  {
    "Astrid #": "ASTRID 003988",
    "HYG Star ID": 104979,
    "Hipparcos Catalogue": 105319,
    "Henry Draper Catalogue": 202730,
    "Harvard Revised Catalogue": 8140,
    "Gliese Catalog": "Wo 9733A",
    "Distance in Parsecs": 30.2847,
    "mag": 4.39,
    "Absolute Visual Magnitude": 1.984,
    "Spectral Type": "A5V",
    "Color Index": 0.191,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": 13.809188,
    "y": -11.601,
    "z": -24.32855,
    "vx": -0.00000268,
    "vy": 0.00002284,
    "vz": 0.00000604,
    "rarad": 5.58446792676392,
    "decrad": -0.932868491310742,
    "pmrarad": 5.205444488125e-7,
    "pmdecrad": -3.25406942e-7,
    "bayer": "The",
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 104979,
    "base": "Wo 9733",
    "lum": 14.0087698395458,
    "bf": "The Ind",
    "ra": 21.331096,
    "dec": -53.449427,
    "pmra": 107.37,
    "pmdec": -67.12,
    "rv": -14.5,
    "Asset Name": "ASTRID 003988",
    "Astrium Color": "White",
    "Background": "Abyss",
    "Expression": "Serious Mad",
    "Hair": "Bangs",
    "Outfit": "Flame Soul",
    "Special": "Astral Burst",
    "Astral Crystal": "Icicle",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": -0.00000268,
    "y_1": 0.00002284,
    "z_1": 0.00000604,
    "Distance in Parsecs_1": 30.2847,
    "HYG Star ID_1": 104979,
    "Hipparcos Catalogue_1": 105319,
    "Henry Draper Catalogue_1": 202730,
    "Harvard Revised Catalogue_1": 8140,
    "Gliese Catalog_1": "Wo 9733A"
  },
  {
    "Astrid #": "ASTRID 003989",
    "HYG Star ID": 31067,
    "Hipparcos Catalogue": 31142,
    "Henry Draper Catalogue": 258728,
    "Distance in Parsecs": 30.303,
    "mag": 9.86,
    "Absolute Visual Magnitude": 7.453,
    "Spectral Type": "M0",
    "Color Index": 1.237,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": -3.478644,
    "y": 24.722687,
    "z": 17.174408,
    "vx": 0.00003178,
    "vy": 0.0000249,
    "vz": -0.00002706,
    "rarad": 1.71058731839091,
    "decrad": 0.602562839336282,
    "pmrarad": -0.00000115298389509722,
    "pmdecrad": -0.000001114247282,
    "con": "Aur",
    "Full Constellation Name Formula 1": "Auriga",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Auriga",
    "comp": 1,
    "comp_primary": 31067,
    "lum": 0.0909494338555294,
    "ra": 6.533962,
    "dec": 34.524308,
    "pmra": -237.82,
    "pmdec": -229.83,
    "rv": 1.3,
    "Asset Name": "ASTRID 003989",
    "Astrium Color": "Red",
    "Background": "Depth",
    "Expression": "Mischievous",
    "Hair": "Pompadour",
    "Outfit": "Pharaoh",
    "Astral Crystal": "Blade",
    "Tether": "Fate Giver",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Auriga",
    "x_1": 0.00003178,
    "y_1": 0.0000249,
    "z_1": -0.00002706,
    "Distance in Parsecs_1": 30.303,
    "HYG Star ID_1": 31067,
    "Hipparcos Catalogue_1": 31142,
    "Henry Draper Catalogue_1": 258728
  },
  {
    "Astrid #": "ASTRID 003990",
    "HYG Star ID": 56887,
    "Hipparcos Catalogue": 57058,
    "Gliese Catalog": "Gl 435.1",
    "Distance in Parsecs": 30.303,
    "mag": 9.59,
    "Absolute Visual Magnitude": 7.183,
    "Spectral Type": "K8",
    "Color Index": 1.247,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -30.086441,
    "y": 2.390774,
    "z": 2.715212,
    "vx": -0.00002812,
    "vy": -0.00003031,
    "vz": -0.00006801,
    "rarad": 3.06229574729715,
    "decrad": 0.0897219046943075,
    "pmrarad": 0.00000107066253213888,
    "pmdecrad": -0.000002311349222,
    "con": "Vir",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Virgo",
    "FULL CONSTELLATION NAME": "Virgo",
    "comp": 1,
    "comp_primary": 56887,
    "lum": 0.116627240508196,
    "ra": 11.697108,
    "dec": 5.140686,
    "pmra": 220.84,
    "pmdec": -476.75,
    "rv": 19,
    "Asset Name": "ASTRID 003990",
    "Astrium Color": "Orange",
    "Background": "Diesel",
    "Expression": "Serious Mad",
    "Hair": "Pixie",
    "Outfit": "Tarnished",
    "Astral Crystal": "Blade",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Virgo",
    "x_1": -0.00002812,
    "y_1": -0.00003031,
    "z_1": -0.00006801,
    "Distance in Parsecs_1": 30.303,
    "HYG Star ID_1": 56887,
    "Hipparcos Catalogue_1": 57058,
    "Gliese Catalog_1": "Gl 435.1"
  },
  {
    "Astrid #": "ASTRID 003991",
    "HYG Star ID": 119209,
    "Gliese Catalog": "NN 4051",
    "Distance in Parsecs": 30.303,
    "mag": 12.57,
    "Absolute Visual Magnitude": 10.163,
    "Spectral Type": "M2.5",
    "Color Index": 1.45,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 2.543816,
    "y": -30.041509,
    "z": -3.051,
    "vx": 0.00005223,
    "vy": 0.00001018,
    "vz": -0.00005684,
    "rarad": 4.79686369870318,
    "decrad": -0.1008539942024,
    "pmrarad": 0.00000174581406368055,
    "pmdecrad": -0.000001885324048,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 119209,
    "lum": 0.00749548949791957,
    "ra": 18.32267,
    "dec": -5.778508,
    "pmra": 360.1,
    "pmdec": -388.88,
    "rv": 0,
    "Asset Name": "ASTRID 003991",
    "Astrium Color": "Red",
    "Background": "Diesel",
    "Expression": "Warm Smile",
    "Hair": "Undercut",
    "Outfit": "Scrubs",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00005223,
    "y_1": 0.00001018,
    "z_1": -0.00005684,
    "Distance in Parsecs_1": 30.303,
    "HYG Star ID_1": 119209,
    "Gliese Catalog_1": "NN 4051"
  },
  {
    "Astrid #": "ASTRID 003992",
    "HYG Star ID": 73285,
    "Hipparcos Catalogue": 73512,
    "Henry Draper Catalogue": 132950,
    "Distance in Parsecs": 30.3122,
    "mag": 9.13,
    "Absolute Visual Magnitude": 6.722,
    "Spectral Type": "K2",
    "Color Index": 1.043,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -20.48177,
    "y": -20.751564,
    "z": 8.288474,
    "vx": 0.0000043,
    "vy": -0.00001679,
    "vz": -0.00003366,
    "rarad": 3.93353365451038,
    "decrad": 0.276964345297359,
    "pmrarad": 4.900011869375e-7,
    "pmdecrad": -0.000001148766016,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 73285,
    "lum": 0.178319977223291,
    "ra": 15.024992,
    "dec": 15.868888,
    "pmra": 101.07,
    "pmdec": -236.95,
    "rv": -0.6,
    "Asset Name": "ASTRID 003992",
    "Astrium Color": "Orange",
    "Background": "Haze",
    "Expression": "Loopy",
    "Hair": "Good Boy",
    "Outfit": "Scientist",
    "Astral Crystal": "Icicle",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.0000043,
    "y_1": -0.00001679,
    "z_1": -0.00003366,
    "Distance in Parsecs_1": 30.3122,
    "HYG Star ID_1": 73285,
    "Hipparcos Catalogue_1": 73512,
    "Henry Draper Catalogue_1": 132950
  },
  {
    "Astrid #": "ASTRID 003993",
    "HYG Star ID": 79457,
    "Hipparcos Catalogue": 79698,
    "Gliese Catalog": "NN 3948",
    "Distance in Parsecs": 30.3214,
    "mag": 9.65,
    "Absolute Visual Magnitude": 7.241,
    "Spectral Type": "K5",
    "Color Index": 1.227,
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": -10.786011,
    "y": -22.093783,
    "z": 17.745817,
    "vx": -0.00001987,
    "vy": 0.00002545,
    "vz": 0.00001961,
    "rarad": 4.25823419163149,
    "decrad": 0.625197168983753,
    "pmrarad": -9.57070686784722e-7,
    "pmdecrad": 7.97712429e-7,
    "con": "CrB",
    "Full Constellation Name Formula 1": "Corona Borealis",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Corona Borealis",
    "comp": 1,
    "comp_primary": 79457,
    "lum": 0.11056050150387,
    "ra": 16.265256,
    "dec": 35.821159,
    "pmra": -197.41,
    "pmdec": 164.54,
    "rv": 0,
    "Asset Name": "ASTRID 003993",
    "Astrium Color": "Orange",
    "Background": "Midnight",
    "Expression": "Guilty Grin",
    "Hair": "High Fade",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Ring",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Corona Borealis",
    "x_1": -0.00001987,
    "y_1": 0.00002545,
    "z_1": 0.00001961,
    "Distance in Parsecs_1": 30.3214,
    "HYG Star ID_1": 79457,
    "Hipparcos Catalogue_1": 79698,
    "Gliese Catalog_1": "NN 3948"
  },
  {
    "Astrid #": "ASTRID 003994",
    "HYG Star ID": 115387,
    "Hipparcos Catalogue": 115752,
    "Gliese Catalog": "Wo 9827",
    "Distance in Parsecs": 30.3214,
    "mag": 10.37,
    "Absolute Visual Magnitude": 7.961,
    "Spectral Type": "M0",
    "Color Index": 1.272,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 30.001821,
    "y": -4.339283,
    "z": -0.680655,
    "vx": 0.00003591,
    "vy": 0.00005042,
    "vz": 0.00003108,
    "rarad": 6.13954739488987,
    "decrad": -0.0224497026267437,
    "pmrarad": 0.00000181553027094444,
    "pmdecrad": 0.000001045985515,
    "con": "Psc",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Pisces",
    "FULL CONSTELLATION NAME": "Pisces",
    "comp": 1,
    "comp_primary": 115387,
    "lum": 0.0569639373341183,
    "ra": 23.451344,
    "dec": -1.286273,
    "pmra": 374.48,
    "pmdec": 215.75,
    "rv": 27,
    "Asset Name": "ASTRID 003994",
    "Astrium Color": "Red",
    "Background": "Haze",
    "Expression": "Mischievous",
    "Hair": "Good Boy",
    "Outfit": "Lord Commander",
    "Astral Crystal": "Shuriken",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Pisces",
    "x_1": 0.00003591,
    "y_1": 0.00005042,
    "z_1": 0.00003108,
    "Distance in Parsecs_1": 30.3214,
    "HYG Star ID_1": 115387,
    "Hipparcos Catalogue_1": 115752,
    "Gliese Catalog_1": "Wo 9827"
  },
  {
    "Astrid #": "ASTRID 003995",
    "HYG Star ID": 119293,
    "Gliese Catalog": "Gl 764.1B",
    "Distance in Parsecs": 30.3214,
    "mag": 10.2,
    "Absolute Visual Magnitude": 7.791,
    "Spectral Type": "K7",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.221239,
    "y": -27.203329,
    "z": -5.497293,
    "vx": -0.00001108,
    "vy": -0.00007287,
    "vz": -0.00005108,
    "rarad": 5.13462450903571,
    "decrad": -0.18228766404815,
    "pmrarad": -0.00000131849928563888,
    "pmdecrad": -0.000001286680963,
    "con": "Aql",
    "Full Constellation Name Formula 1": "Aquila",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Aquila",
    "comp": 2,
    "comp_primary": 96168,
    "base": "Gl 764.1",
    "lum": 0.0666192900223454,
    "ra": 19.612821,
    "dec": -10.444314,
    "pmra": -271.96,
    "pmdec": -265.4,
    "rv": 68.6,
    "Asset Name": "ASTRID 003995",
    "Astrium Color": "Orange",
    "Background": "Silken Darkness",
    "Expression": "Shy Smile",
    "Hair": "Shinobi",
    "Outfit": "Sweater Friend",
    "Astral Crystal": "Snowflake",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Aquila",
    "x_1": -0.00001108,
    "y_1": -0.00007287,
    "z_1": -0.00005108,
    "Distance in Parsecs_1": 30.3214,
    "HYG Star ID_1": 119293,
    "Gliese Catalog_1": "Gl 764.1B"
  },
  {
    "Astrid #": "ASTRID 003996",
    "HYG Star ID": 100991,
    "Hipparcos Catalogue": 101317,
    "Henry Draper Catalogue": 195266,
    "Distance in Parsecs": 30.3306,
    "mag": 9.96,
    "Absolute Visual Magnitude": 7.551,
    "Spectral Type": "K5/M0V",
    "Spectral Abbreviation": "K",
    "Star Type": "Orange",
    "Color In Star Map": "Orange",
    "x": 12.577328,
    "y": -16.076919,
    "z": -22.434099,
    "vx": 0.00000103,
    "vy": 0.00001916,
    "vz": -0.00001315,
    "rarad": 5.37625556458713,
    "decrad": -0.832553588201292,
    "pmrarad": 4.16067100652777e-7,
    "pmdecrad": -6.44365862e-7,
    "con": "Ind",
    "Full Constellation Name Formula 1": "Indus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Indus",
    "comp": 1,
    "comp_primary": 100991,
    "lum": 0.0830998041044431,
    "ra": 20.535784,
    "dec": -47.701807,
    "pmra": 85.82,
    "pmdec": -132.91,
    "rv": 0,
    "Asset Name": "ASTRID 003996",
    "Astrium Color": "Orange",
    "Background": "Abyss",
    "Expression": "Smitten",
    "Hair": "Ponytail",
    "Outfit": "Pirate",
    "Astral Crystal": "Ring",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Indus",
    "x_1": 0.00000103,
    "y_1": 0.00001916,
    "z_1": -0.00001315,
    "Distance in Parsecs_1": 30.3306,
    "HYG Star ID_1": 100991,
    "Hipparcos Catalogue_1": 101317,
    "Henry Draper Catalogue_1": 195266
  },
  {
    "Astrid #": "ASTRID 003997",
    "HYG Star ID": 102639,
    "Hipparcos Catalogue": 102970,
    "Henry Draper Catalogue": 199476,
    "Gliese Catalog": "GJ 1255-",
    "Distance in Parsecs": 30.3306,
    "mag": 7.81,
    "Absolute Visual Magnitude": 5.401,
    "Spectral Type": "G8V",
    "Color Index": 0.685,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": 5.42392,
    "y": -5.829224,
    "z": 29.266539,
    "vx": -0.00001774,
    "vy": 0.00010602,
    "vz": -0.00000728,
    "rarad": 5.4617377265308,
    "decrad": 1.30516293261049,
    "pmrarad": 0.00000195297494938194,
    "pmdecrad": 0.000002790926914,
    "con": "Cep",
    "Full Constellation Name Formula 1": "Cepheus",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Cepheus",
    "comp": 1,
    "comp_primary": 102639,
    "lum": 0.602004863684459,
    "ra": 20.862301,
    "dec": 74.780328,
    "pmra": 402.83,
    "pmdec": 575.67,
    "rv": -29.9,
    "Asset Name": "ASTRID 003997",
    "Astrium Color": "Yellow",
    "Background": "Midnight",
    "Expression": "Warm Smile",
    "Hair": "Afro",
    "Outfit": "Flame Soul",
    "Astral Crystal": "Spike",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Cepheus",
    "x_1": -0.00001774,
    "y_1": 0.00010602,
    "z_1": -0.00000728,
    "Distance in Parsecs_1": 30.3306,
    "HYG Star ID_1": 102639,
    "Hipparcos Catalogue_1": 102970,
    "Henry Draper Catalogue_1": 199476,
    "Gliese Catalog_1": "GJ 1255-"
  },
  {
    "Astrid #": "ASTRID 003998",
    "HYG Star ID": 57819,
    "Hipparcos Catalogue": 57992,
    "Henry Draper Catalogue": 103126,
    "Distance in Parsecs": 30.3398,
    "mag": 8.28,
    "Absolute Visual Magnitude": 5.87,
    "Spectral Type": "G5",
    "Color Index": 0.72,
    "Spectral Abbreviation": "G",
    "Star Type": "Yellow",
    "Color In Star Map": "Yellow",
    "x": -1.993938,
    "y": 0.055142,
    "z": 30.274246,
    "vx": 0.00003563,
    "vy": 0.00003179,
    "vz": 0.00001233,
    "rarad": 3.11407903395099,
    "decrad": 1.50500371011378,
    "pmrarad": -0.00000107977702933333,
    "pmdecrad": 0.000001169613004,
    "con": "UMi",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Minor",
    "FULL CONSTELLATION NAME": "Ursa Minor",
    "comp": 1,
    "comp_primary": 57819,
    "lum": 0.390840895792401,
    "ra": 11.894906,
    "dec": 86.230361,
    "pmra": -222.72,
    "pmdec": 241.25,
    "rv": 9.8,
    "Asset Name": "ASTRID 003998",
    "Astrium Color": "Yellow",
    "Background": "Silken Darkness",
    "Expression": "Owie",
    "Hair": "Undercut",
    "Outfit": "Denim Vest",
    "Astral Crystal": "Blade",
    "Tether": "Soother",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Minor",
    "x_1": 0.00003563,
    "y_1": 0.00003179,
    "z_1": 0.00001233,
    "Distance in Parsecs_1": 30.3398,
    "HYG Star ID_1": 57819,
    "Hipparcos Catalogue_1": 57992,
    "Henry Draper Catalogue_1": 103126
  },
  {
    "Astrid #": "ASTRID 003999",
    "HYG Star ID": 109584,
    "Hipparcos Catalogue": 109930,
    "Distance in Parsecs": 30.3398,
    "mag": 13.52,
    "Absolute Visual Magnitude": 11.11,
    "Spectral Type": "M2",
    "Color Index": 1.41,
    "Spectral Abbreviation": "M",
    "Star Type": "Red",
    "Color In Star Map": "Red",
    "x": 14.721934,
    "y": -7.187375,
    "z": -25.536439,
    "vx": 0.00000346,
    "vy": 0.00000547,
    "vz": 4.6e-7,
    "rarad": 5.82901362365473,
    "decrad": -1.00038919850233,
    "pmrarad": 2.11960541138888e-7,
    "pmdecrad": 2.7828305e-8,
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "",
    "comp": 1,
    "comp_primary": 109584,
    "lum": 0.00313328572431558,
    "var_min": 14.245,
    "var_max": 12.295,
    "ra": 22.265192,
    "dec": -57.318079,
    "pmra": 43.72,
    "pmdec": 5.74,
    "rv": 0,
    "Asset Name": "ASTRID 003999",
    "Astrium Color": "Red",
    "Background": "Midnight",
    "Expression": "Anxious Laugh",
    "Hair": "Unkempt",
    "Outfit": "Turtle School",
    "Astral Crystal": "Glass",
    "Tether": "Enkindler",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "None",
    "x_1": 0.00000346,
    "y_1": 0.00000547,
    "z_1": 4.6e-7,
    "Distance in Parsecs_1": 30.3398,
    "HYG Star ID_1": 109584,
    "Hipparcos Catalogue_1": 109930
  },
  {
    "Astrid #": "ASTRID 004000",
    "HYG Star ID": 48759,
    "Hipparcos Catalogue": 48899,
    "Distance in Parsecs": 30.349,
    "mag": 10.38,
    "Absolute Visual Magnitude": 7.969,
    "Color Index": 1.322,
    "Spectral Abbreviation": "",
    "Star Type": "Unknown",
    "Color In Star Map": "Green",
    "x": -10.20767,
    "y": 5.982773,
    "z": 27.94766,
    "vx": 0.00000371,
    "vy": 0.0000333,
    "vz": -0.00000577,
    "rarad": 2.61146599654258,
    "decrad": 1.17032105700336,
    "pmrarad": -0.00000100850941829166,
    "pmdecrad": -4.87771044e-7,
    "con": "UMa",
    "Full Constellation Name Formula 1": "",
    "Full Constellation Name Formula 2": "Ursa Major",
    "FULL CONSTELLATION NAME": "Ursa Major",
    "comp": 1,
    "comp_primary": 48759,
    "lum": 0.056545754068762,
    "var_min": 10.468,
    "var_max": 10.278,
    "ra": 9.975065,
    "dec": 67.054457,
    "pmra": -208.02,
    "pmdec": -100.61,
    "rv": 0,
    "Asset Name": "ASTRID 004000",
    "Astrium Color": "Green",
    "Background": "Abyss",
    "Expression": "Bring It",
    "Hair": "Frizzy",
    "Outfit": "Astro",
    "Special": "V1 Flight System",
    "Astral Crystal": "Glass",
    "Tether": "Illuminator",
    "Combination": "",
    "Proper Name_1": "None",
    "Constellation": "Ursa Major",
    "x_1": 0.00000371,
    "y_1": 0.0000333,
    "z_1": -0.00000577,
    "Distance in Parsecs_1": 30.349,
    "HYG Star ID_1": 48759,
    "Hipparcos Catalogue_1": 48899
  },
  {
    "Astrid #": "ASTRID 004001",
    "HYG Star ID": 69510,
    "Hipparcos Catalogue": 69732,
    "Henry Draper Catalogue": 125162,
    "Harvard Revised Catalogue": 5351,
    "Gliese Catalog": "NN 3837",
    "Distance in Parsecs": 30.3582,
    "mag": 4.18,
    "Absolute Visual Magnitude": 1.769,
    "Spectral Type": "A0sh",
    "Color Index": 0.087,
    "Spectral Abbreviation": "A",
    "Star Type": "White",
    "Color In Star Map": "White",
    "x": -17.435524,
    "y": -11.802923,
    "z": 21.870286,
    "vx": 0.00000326,
    "vy": 0.00003551,
    "vz": 0.00001026,
    "rarad": 3.73668207541924,
    "decrad": 0.804392674394538,
    "pmrarad": -9.08637800097222e-7,
    "pmdecrad": 7.70902233e-7,
    "bayer": "Lam",
    "flam": 19,
    "con": "Boo",
    "Full Constellation Name Formula 1": "Boötes",
    "Full Constellation Name Formula 2": "",
    "FULL CONSTELLATION NAME": "Boötes",
    "comp": 1,
    "comp_primary": 69510,
    "lum": 17.0765447281387,
    "bf": "19Lam Boo",
    "ra": 14.273074,
    "dec": 46.088305,
    "pmra": -187.42,
    "pmdec": 159.01,
    "rv": -8.1,
    "Asset Name": "ASTRID 004001",
    "Astrium Color": "White",
    "Proper Name_1": "None",
    "Constellation": "Boötes",
    "x_1": 0.00000326,
    "y_1": 0.00003551,
    "z_1": 0.00001026,
    "Distance in Parsecs_1": 30.3582,
    "HYG Star ID_1": 69510,
    "Hipparcos Catalogue_1": 69732,
    "Henry Draper Catalogue_1": 125162,
    "Harvard Revised Catalogue_1": 5351,
    "Gliese Catalog_1": "NN 3837"
  }
]