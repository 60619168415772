import { useState, useEffect, ActivityIndicator } from "react";
import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Mint } from "../../components/mint";
import { Ooze } from "../../components/ooze";
import { Merge } from "../../components/merge";
import axios from 'axios';
import env from "react-dotenv";
import { Roadmap } from "../../components/roadmap";
import { Team } from "../../components/Team";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import "./index.css";
import { nfts } from './mynfts';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useNavigate } from 'react-router-dom';


import { useMediaQuery } from 'react-responsive'
import Checkbox from "react-custom-checkbox";

// require("bootstrap/less/bootstrap.less");
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useTranslation } from 'react-i18next';
import { useCardano } from '@cardano-foundation/cardano-connect-with-wallet';
import { ConnectWalletList, ConnectWalletButton } from '@cardano-foundation/cardano-connect-with-wallet';



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}


// const onConnect = async () => {
//   alert('Successfully connected!')
//   // const cardano = await CardanoSerializationLib();
//   // const walletContent = await cardano.wallet.getContent('stake1uyyqy78c6lpa0fdzj2wnar6vz3322fnzr3t8e6z0eza7aush6p3su');
//   // console.log('walletContent', walletContent);
// };


const StarRegistry = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [openNav, setOpenNav] = useState(false)
  const [pageItemCount, setPageItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [userStars, setUserStars] = useState([])
  const [selectedStar, setSelectedStar] = useState(10000000)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const navigate = useNavigate();

  const { t, i18n } = useTranslation(['common']);

  const {
    isEnabled,
    isConnected,
    enabledWallet,
    accountBalance,
    stakeAddress,
    signMessage,
    connect,
    disconnect
  } = useCardano();

  useEffect(() => {
    setLandingPageData(JsonData);
    getAllStars()
    // console.log('stakeAddress', stakeAddress)

    // function checkValue() {
    //   console.log('sesegseg', stakeAddress)
    //   if (stakeAddress !== null) {
    //     getAllStars(stakeAddress);
    //   } else {
    //     setTimeout(checkValue, 1000);
    //   }
    // }

    // checkValue();

  }, []);

  function toggleMenu() {
    let d;
    let c;
    if (!openNav) {
      setOpenNav(true)
      d = document.getElementById("navbar-collapse-1");
      d.classList.add("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.remove("collapsed");
    } else {
      setOpenNav(false)
      d = document.getElementById("navbar-collapse-1");
      d.classList.remove("in");
      c = document.getElementsByClassName("navbar-toggle");
      c[0].classList.add("collapsed");
    }
  }

  const checkBlockchain = async () => {
    // CHECK BLOCKCHAIN
    const userId = window.localStorage.getItem('userId')
    if (userId) {
      axios.get("https://api.adastrium.online/api/v1/astrids/checkblockchain/" + userId).then((res) => {
        setUserStars(res.data.stars)
      }).catch(() => {
      })
    }
  }

  const getAllStars = async () => {
    // Get NFTS
    const userId = window.localStorage.getItem('userId')
    if (userId) {
      axios.get("https://api.adastrium.online/api/v1/astrids/getallstars/" + userId).then((res) => {
        setUserStars(res.data.stars)
      }).catch(() => {
      })
    }
  }
  const handleStarMap = async (val) => {
    const result = val.replace(/\D/g, "");
    navigate('/demo?astridId=' + result);
    window.location.reload()
  }

  const handleEdit = async (token) => {
    // EDIT NFTS
    // NotificationManager.success('Saving Changes...', '', 5000);
    setIsLoading(true)
    const userId = window.localStorage.getItem('userId')
    let apiHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }
    const payload = {
      "tokenId": token,
      "ownerId": userId,
      "description": description,
      "name": name
    }
    axios.post("https://api.adastrium.online/api/v1/astrids/edit/", payload, apiHeader).then((res) => {
      const temp = []
      userStars.map((item) => {
        // 
        if (item.asset_id === res.data.asset_id) {
          temp.push(res.data)
        } else {
          temp.push(item)
        }
      })
      // console.log(userStars, temp)
      setUserStars(temp)
      setName("")
      setDescription("")
      NotificationManager.success('Save success!', '', 5000);
      setSelectedStar(1000000)
      setIsLoading(false)

    }).catch(() => {
      NotificationManager.error('Something went wrong', '', 5000);
      setIsLoading(false)

    })
  }

  const onConnect = async (res, e) => {
    // alert('sesegseg')
    // console.log(res, e, stakeAddress)
    NotificationManager.success('Wallet connected!', '', 5000);
    localStorage.setItem('userId', stakeAddress)
    setTimeout(getAllStars, 2000);

  };

  const processAddressId = () => {
    if (!stakeAddress) {
      return "Click here to connect wallet"
    }
    localStorage.setItem('userId', stakeAddress)

    // setStakeId(stakeAddress)
    // if (userStars) {
    //   getAllStars(stakeAddress)
    // }
    let str = stakeAddress ? stakeAddress : "";
    let newStr = str.replace(/^stake1/, "");
    let firstThree = newStr.substring(0, 3);
    let lastThree = newStr.substring(newStr.length - 4);
    let masked = "****";

    return firstThree + masked + lastThree;
  }

  return (
    <div className='intro'>
      <NotificationContainer />

      <div className="main-page-wrapper">
        {/* ===================================================
				Loading Transition
		  	==================================================== */}
        <div id="loader-wrapper">
          <div id="loader">
            <ul>
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
        {/* 
        =============================================
          Theme Header
        ============================================== 
        */}
        {/* <div className="background-alert" style={{ height: 30, width: '100%' }}>
          <p className=" fadeInUp animated" data-wow-delay="0.2s" style={{ "-webkit-text-transform": "uppercase", "text-transform": "uppercase", 'text-align': 'center', 'color': 'white' }}>
            {t('alert')}</p>
        </div> */}
        <header className="theme-menu-wrapper full-width-menu">
          <div className="header-wrapper">
            <div className="clearfix" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Logo */}
              <div className="float-left tran4s" style={{ "display": "flex", maxWidth: '150px', margin: 15, transform: 'translateY(-5px)' }}>
                <a href="/"><img style={{
                  minWidth: '5rem',
                  maxWidth: '5rem'
                }} src="images/elements/alogo.png" alt="Logo" /></a>
              </div>

              {/* ============================ Theme Menu ========================= */}
              <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper" style={{ justifyContent: 'flex-end' }}>
                {/* Brand and toggle get grouped for better mobile display */}
                <div className="navbar-header">
                  <button onClick={() => toggleMenu()} type="button" className="navbar-toggle collapsed" >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                {!openNav &&

                  <div className="collapse navbar-collapse" id="navbar-collapse-1">
                    <ul className="nav">
                      <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                      </li>
                      <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                      </li>
                      <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                      </li>
                      <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                      </li>
                      <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                      </li>
                      <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s" style={{ "font-size": "15px", color: 'white', fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                      </li>

                    </ul>
                  </div>
                }
              </nav> {/* /.theme-main-menu */}
              <div className="float-left tran4s" style={{ "display": "flex" }}>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/ig.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/twitter.png'} /></a></li>
                <li className="menu-list" style={{ listStyle: 'none', marginRight: '9px' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ marginRight: '1rem', minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/discord.png'} /></a></li>
              </div>

            </div> {/* /.clearfix */}
          </div>


          {openNav &&

            <div className="navbar-collapse" id="navbar-collapse-1" style={{
              width: '100%',
              position: 'absolute',
              zIndex: '200',
              backgroundColor: 'white',
            }}>
              <ul className="nav" style={{
                flexDirection: 'column',
                display: 'flex'
              }}>
                <li id="astridstarsli" className=" menu-list"><a href="#astridstars" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('astrids')}</a>
                </li>
                <li id="starmapli" className=" menu-list"><a href="#starmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starmap')}</a>
                </li>
                <li id="starregistryli" className=" menu-list"><a href="#starregistry" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('starregistry')}</a>
                </li>

                <li id="theteamli" className=" menu-list"><a href="#team" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>THE TEAM</a>
                </li>
                <li id="whitepaperli" className=" menu-list"><a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>WHITEPAPER</a>
                </li>
                <li id="roadmapli" className=" menu-list"><a href="#roadmap" className="tran3s limenu" style={{ "font-size": "15px", fontFamily: 'montserrat' }}>{t('roadmap')}</a>
                </li>
              </ul>
            </div>
          }

        </header> {/* /.theme-menu-wrapper */}




        <div className="about-bg-starregistry" id="mint">
          <div className="row" style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 50 }}>


            <div className="container" >
              <div className="row" style={{
                alignItems: 'flex-start',
                'display': 'flex',
                'flex-direction': 'column',
                padding: 10
              }}>
                {/* <img
                  className="mx-auto d-block"
                  src="images/elements/futureofadastra.png"
                /> */}
                <h2 style={{ color: '#FDA750' }}>STAR DASHBOARD</h2>

                <div className="w-100" style={{ backgroundColor: '#ffffff42', padding: 10, borderRadius: 20 }}>
                  <div className="col-md-12 col-lg-12 col-xl-12 wow fadeInUp animated" style={{ paddingLeft: 20, display: 'flex', flexDirection: 'row' }}>
                    <img style={{ minWidth: '3rem' }} src={'img/icons/discord.png'} />
                    <div style={{ marginLeft: 0, marginTop: 5, marginBottom: 5 }}>
                      <div style={{ padding: 10, color: 'white' }}>
                        {processAddressId()}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row" style={{ paddingLeft: 20 }}>
                    <div style={{ padding: 10, color: 'white' }}>
                      Role
                    </div>
                    <div style={{ padding: 10, color: 'white' }}>
                      Star Whale
                    </div>
                  </div> */}
                  <div className="row" style={{ paddingLeft: 20 }}>
                    <div style={{ padding: 10, color: 'white' }}>
                      Stars
                    </div>
                    <div style={{ padding: 10, color: 'white' }}>
                      {userStars && userStars.length}
                    </div>
                  </div>
                </div>

                <div className="w-100" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div className="w-100" style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                    <a href="/demo" className="hvr-trim-two p-bg-color button-one-outline-isolated" style={{ textTransform: 'uppercase', marginBottom: 15 }}>Star map</a>
                  </div>
                  <ConnectWalletButton
                    // onSignMessage={() => alert('segseg')}
                    onConnect={onConnect}
                    onDisconnect={() => {
                      localStorage.setItem('userId', null)
                      setUserStars([])
                    }}
                    customCSS={`
                    font-family: Helvetica Light,sans-serif;
                    font-size: 0.875rem;
                    font-weight: 700;
                    width: 164px;
                    & > span { padding: 5px 16px; }
                `}
                  />
                </div>



                {/* <h2 style={{ color: '#FDA750', width: '100%', textAlign: 'end' }}>YOUR STARS</h2> */}

                <Table style={{ border: 'none' }}>
                  <Thead style={{ border: 'none' }}>
                    <Tr style={{ backgroundColor: '#ffffff2e', padding: 10 }}>
                      <Th style={{ border: 'none', padding: 10, width: "15%" }}>
                        <div className="w-10" style={{ marginLeft: 25, marginTop: 10 }}>
                          <a onClick={() => checkBlockchain()} className="hvr-trim-two" style={{ textTransform: 'uppercase', marginBottom: 15 }}><img style={{ minWidth: '3rem', maxWidth: '3rem' }} src={'img/icons/refresh.png'} />
                          </a>
                        </div>
                      </Th>
                      <Th style={{ border: 'none', padding: 0, width: "15%" }}><label style={{ fontSize: 20 }}>ASTRID # </label></Th>
                      <Th style={{ border: 'none', padding: 0, width: "15%" }}><label style={{ fontSize: 20 }}>CUSTOM NAME </label></Th>
                      <Th style={{ border: 'none', padding: 0, width: "15%" }}><label style={{ fontSize: 20 }}>SPECTRAL TYPE </label></Th>
                      <Th style={{ border: 'none', padding: 0, width: "15%" }}><label style={{ fontSize: 20 }}>HYG STAR ID </label></Th>
                      <Th style={{ border: 'none', padding: 0, width: "15%" }}><label style={{ fontSize: 20 }}>ACTION </label></Th>
                      {/* <Th style={{ border: 'none', padding: 10, width: "15%" }}>
                        <div style={{ marginTop: 10, marginRight: 25, width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <a className="hvr-trim-two p-bg-color button-one-outline-isolated" style={{ textTransform: 'uppercase', marginBottom: 15 }}>Edit</a>
                        </div>
                      </Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {userStars &&
                      <>
                        {userStars.map((val, key) => {
                          const str = val['Astrid #'];
                          const num = str.match(/\d+/)[0];
                          const link = "https://api.adastrium.online/api/v1/astrids/" + num
                          return (
                            <>
                              <Tr className="wow fadeInUp animated" key={key}>
                                <Td style={{ textAlign: 'none' }}>
                                  <div className="image" style={{ padding: 20 }}>
                                    <img style={{ width: 100, height: 100, borderRadius: 100, border: 1, border: 'solid' }} src={link} alt />
                                  </div> {/* /.image */}
                                </Td>
                                <Td style={{ textAlign: 'start' }}><label style={{ fontSize: 20 }}>{val['Astrid #']}</label> </Td>
                                <Td style={{ textAlign: 'start' }}>
                                  <label style={{ fontSize: 20 }}>{val.name === "" ? "Click edit to set name" : val.name}</label>
                                </Td>
                                <Td style={{ textAlign: 'start' }}><label style={{ fontSize: 20 }}>{val['Spectral Type']} </label></Td>
                                <Td style={{ textAlign: 'start' }}><label style={{ fontSize: 20 }}>{val['HYG Star ID']} </label></Td>
                                <Td style={{ textAlign: 'start' }}>
                                  {/* <Checkbox
                                    checked={true}
                                    icon={<img src={'img/icons/star.png'} style={{ width: 24 }} alt="" />}
                                    borderColor="white"
                                    borderRadius={10}
                                    size={40}
                                  /> */}
                                  <a onClick={() => {
                                    if (val['Astrid #'] !== selectedStar) {
                                      setName(val.name)
                                      setDescription(val.description)
                                      setSelectedStar(val['Astrid #'])
                                    } else {
                                      setSelectedStar(10000000)
                                    }
                                  }} className="hvr-trim-two p-bg-color button-one-outline-isolated" style={{ textTransform: 'uppercase', marginBottom: 15 }}>{val['Astrid #'] !== selectedStar ? 'Edit' : 'Close'} </a>

                                </Td>
                              </Tr>
                              {val['Astrid #'] === selectedStar &&
                                <>
                                  <Tr className="wow fadeInUp animated" key={key}>
                                    <Td colspan="2">
                                      <label style={{ textAlign: 'left' }}>Name</label>
                                      <input
                                        style={{
                                          'font-size': '20px',
                                          'border-radius': '1rem',
                                          'border-color': 'white',
                                          color: 'white',
                                          'border-width': '1px',
                                          'padding': '1rem',
                                          'background-color': 'transparent',
                                          'border-style': 'solid',
                                          width: '100%',
                                          // marginBottom: 10
                                        }}
                                        placeholder="Input name"
                                        value={name}
                                        onChange={(e) => {
                                          setName(e.target.value)
                                          // refreshData(e.target.value)
                                        }}

                                        type="text"
                                        name="Sort Store"
                                      />
                                    </Td>
                                    <Td colspan="3" style={{ textAlign: 'none' }}>
                                      <label style={{ textAlign: 'left' }}>Description</label>
                                      <input
                                        style={{
                                          'font-size': '20px',
                                          'border-radius': '1rem',
                                          'border-color': 'white',
                                          color: 'white',
                                          'border-width': '1px',
                                          'padding': '1rem',
                                          'border-style': 'solid',
                                          'background-color': 'transparent',
                                          width: '100%',
                                          // marginBottom: 10
                                        }}
                                        placeholder="Input description or dedication"
                                        value={description}
                                        onChange={(e) => {
                                          setDescription(e.target.value)
                                          // refreshData(e.target.value)
                                        }}

                                        type="text"
                                        name="Sort Store"
                                      />
                                    </Td>
                                    <Td >
                                      <div style={{ marginTop: 35, marginRight: 25, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <a onClick={() => {
                                          if (name === "") {
                                            alert("Name Required")
                                            return
                                          }
                                          if (description === "") {
                                            alert("Description Required")
                                            return
                                          }
                                          handleEdit(val.asset_id,)
                                        }} className="hvr-trim-two p-bg-color button-one-outline-isolated" style={{ height: 50, textTransform: 'uppercase', marginBottom: 15, marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{isLoading ? <Dots color={'white'} size={20} /> : "Save"}</a>

                                      </div>

                                    </Td>
                                  </Tr>
                                  <Tr className="wow fadeInUp animated" key={key}>
                                    <Td colspan="2" style={{ textAlign: 'start' }}><label style={{ fontSize: 15 }}>Coordinates {val.x}x {val.y}y {val.z}z </label> </Td>
                                    <Td style={{ textAlign: 'start' }}><label style={{ fontSize: 15 }}>Gliese Catalog {val['Gliese Catalog']} </label> </Td>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td style={{ textAlign: 'start' }}>
                                      <a onClick={() => { handleStarMap(val['Astrid #']) }} >
                                        <label style={{ fontSize: 15, textAlign: 'right', width: '100%', cursor: 'pointer' }}>View in Starmap</label>
                                      </a>
                                    </Td>

                                  </Tr>
                                </>

                              }
                            </>
                          )
                        })}
                      </>
                    }
                  </Tbody>
                </Table>
                {userStars.length === 0 &&
                  <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <label style={{ textAlign: 'center', color: 'white', fontSize: 15, opacity: 0.5 }}>No stars available</label>
                    </div>
                  </>
                }
              </div>
            </div> {/* /.container */}
          </div>

        </div> {/* /.our-portfolio */}


        {/* 
			=============================================
				Footer
			============================================== 
			*/}
        <footer className="bg-one">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="footer-logo">
                  <a href="index.html"><img src="images/logo/gs-logo.png" style={{ "max-width": "200px", "margin-bottom": "30px", }} alt="Logo" /></a>
                  {/* <h4 style={{ color: 'white' }}>{t('more_links')}</h4> */}
                  <ul style={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
                    <a href="#astridstars" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ASTRIDS </h5>
                    </a>
                    <a href="#starmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR MAP </h5>
                    </a>
                    <a href="#starregistry" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        STAR REGISTRY </h5>
                    </a>
                    <a href="#roadmap" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        ROAD MAP</h5> </a>
                    <a href="#team" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        TEAM </h5></a>
                    <a href="https://adastrium.gitbook.io/ad-astrium-whitepaper/" >
                      <h5 className="wow fadeInUp animated" style={{ "max-width": "500px", "margin-bottom": "5px", "margin-top": "5px", color: 'white' }}>
                        WHITEPAPER  </h5></a>
                    {/* <li><a style={{ color: 'white' }} href="/astrids" className="tran3s">ASTRIDS</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">STAR REGISTRY</a></li>
                    <li><a style={{ color: 'white' }} href="/roadmap" className="tran3s">ROAD MAP</a></li>
                    <li><a style={{ color: 'white' }} href="" className="tran3s">TEAM</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="float-right tran4s" style={{ "display": "flex" }}>
                  {/* <a><img src="images/logo/logo.png" alt="Logo" /></a> */}
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://www.instagram.com/adastriumcnft/" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/ig.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://twitter.com/AdAstriumCNFT" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/twitter.png'} /></a></li>
                  <li className="menu-list" style={{ listStyle: 'none' }}><a href="https://discord.gg/adastrium" className="tran3s" style={{ "font-size": "15px", "font-family": "Pixelated", "margin": "0 0px", "padding-bottom": "0px", "display": "flex", "align-items": "center", "color": "white" }}><img style={{ height: '30px', marginRight: '1rem' }} src={'img/icons/discord.png'} /></a></li>
                </div>
              </div>
            </div> {/* /.row */}
            {/* <div className="bottom-footer clearfix">
              <p className="text-center">© 2022 <a href="#" className="tran3s p-color">Ad Astrium</a>. All rights
                reserved</p>
            </div> */}
          </div> {/* /.container */}
        </footer>
        {/* Sign-Up Modal */}
        <div className="modal fade signUpModal theme-modal-box" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <h3 style={{ color: 'white' }}>Login with Social Networks</h3>
                <ul style={{ color: 'white' }} className="clearfix">
                  <li className="float-left"><a href="#"><i className="fa fa-facebook" aria-hidden="true" />
                    facebook</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-google-plus" aria-hidden="true" />
                    Google</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-twitter" aria-hidden="true" />
                    Twitter</a></li>
                  <li className="float-left"><a href="#"><i className="fa fa-linkedin" aria-hidden="true" />
                    Linkedin</a></li>
                </ul>
                <form action="#">
                  <h6><span>or</span></h6>
                  <div className="wrapper">
                    <input type="text" placeholder="Username or Email" />
                    <input type="password" placeholder="Password" />
                    <ul className="clearfix">
                      <li className="float-left">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember Me</label>
                      </li>
                      <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                    </ul>
                    <button className="p-bg-color hvr-trim-two">Login</button>
                  </div>
                </form>
              </div> {/* /.modal-body */}
            </div> {/* /.modal-content */}
          </div> {/* /.modal-dialog */}
        </div> {/* /.signUpModal */}
        {/* Scroll Top Button */}
        <button className="scroll-top tran3s">
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
        <div id="svag-shape">
          <svg height={0} width={0}>
            <defs>
              <clipPath id="shape-one">
                <path fillRule="evenodd" fill="rgb(168, 168, 168)" d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* Js File_________________________________ */}
        {/* j Query */}
        {/* Bootstrap Select JS */}
        {/* Bootstrap JS */}
        {/* Vendor js _________ */}
        {/* Camera Slider */}
        {/* Mega menu  */}
        {/* WOW js */}
        {/* owl.carousel */}
        {/* js count to */}
        {/* Fancybox */}
        {/* Theme js */}

      </div>

    </div>
  );
};

export default StarRegistry;
